import { css } from "@styled-system/css";
import { stringToColor } from "~/apps/shared/utils/string-to-color";

import { theme } from "@skin/v2";

export const styles = {
  root: ({ name }: { name: string }) =>
    css({
      ".MuiAvatar": {
        "&-root": {
          backgroundColor: stringToColor(name),
          fontFamily: theme.fonts.inter,
          fontSize: "0.75rem",
          fontWeight: "bold",
          height: "36px",
          width: "36px",
        },
      },
    }),
};
