import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = ({
  details,
  primary,
}: {
  details: string;
  primary: string;
}) => ({
  arms: {
    left: css({
      backgroundColor: details,
      borderRadius: "8px",
      bottom: "-2px",
      height: "6px",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, 0)",
      transition: "background-color .15s ease-in-out",
      width: "75%",
      "@media screen and (max-width: 992px)": {
        bottom: "unset",
        height: "75%",
        left: "-2px",
        top: "50%",
        transform: "translate(0, -50%)",
        width: "6px",
      },
    }),
    right: css({
      backgroundColor: details,
      borderRadius: "8px",
      height: "6px",
      left: "50%",
      position: "absolute",
      top: "-2px",
      transform: "translate(-50%, 0)",
      transition: "background-color .15s ease-in-out",
      width: "75%",
      "@media screen and (max-width: 992px)": {
        height: "75%",
        left: "unset",
        right: "-2px",
        top: "50%",
        transform: "translate(0, -50%)",
        width: "6px",
      },
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: primary,
    border: "none",
    borderRadius: "4px",
    color: theme.colors.white,
    cursor: "pointer",
    display: "flex",
    fontFamily: theme.fonts.inter,
    fontSize: "0.75rem",
    fontWeight: 600,
    height: "32px",
    justifyContent: "center",
    position: "relative",
    transition: "background-color .15s ease-in-out",
    width: "32px",
    "&::after": {
      backgroundColor: details,
      borderRadius: "8px",
      content: "''",
      height: "75%",
      position: "absolute",
      right: "-3px",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "6px",
    },
    "@media screen and (max-width: 992px)": {
      height: "32px",
      width: "32px",
      "&::after": {
        bottom: "-3px",
        height: "6px",
        left: "50%",
        right: "unset",
        top: "unset",
        transform: "translate(-50%, 0)",
        width: "75%",
      },
    },
  }),
});
