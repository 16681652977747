import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  buttons: {
    root: css({
      display: "flex",
      gap: "16px",
    }),
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "24px",
    justifyContent: "center",
    minHeight: "100dvh",
    padding: "24px",
    width: "100%",
  }),
};
