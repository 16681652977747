import React, { useState, useMemo } from "react";
import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@shared/inputs";

const initialState = {
  anchorEl: null,
  isFilterSelected: false,
  travelerToken: "",
  wasFilterApplied: false
};

const TravelerFilter = ({ options = [], onApply, containerStyles = {} }) => {
  const [state, setState] = useState(initialState);
  const { travelerToken, isFilterSelected, anchorEl, wasFilterApplied } = state;

  const selectedTraveler = useMemo(() => {
    return travelerToken
      ? options.find(traveler => traveler.value === travelerToken)
      : null;
  }, [travelerToken, options]);

  const handleApply = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      isFilterSelected: !!travelerToken,
      wasFilterApplied: true
    }));
    onApply(travelerToken);
  };

  const handleClear = e => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      travelerToken: "",
      isFilterSelected: false,
      wasFilterApplied: false
    }));
    onApply("");
  };

  const handleClick = e => {
    const { target } = e;
    setState(prevState => ({
      ...prevState,
      anchorEl: target,
      isFilterSelected: true
    }));
  };

  const handleChange = (option, event) => {
    setState(prevState => ({
      ...prevState,
      travelerToken: option ? option.value : null,
      isFilterSelected: true,
      wasFilterApplied: false
    }));
    if (event.action === "clear") {
      onApply("");
    }
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      travelerToken: wasFilterApplied ? travelerToken : "",
      isFilterSelected: wasFilterApplied ? wasFilterApplied : false
    }));
  };

  return (
    <FilterButtonPopover
      anchorEl={anchorEl}
      isFilterSelected={isFilterSelected}
      isOpen={!!anchorEl}
      applyEnabled
      clearEnabled
      showClearButton={!!state.travelerToken}
      onApplyClick={handleApply}
      onClearClick={handleClear}
      onButtonClick={handleClick}
      onPopoverClose={handleClose}
      unselectedButtonText={"Viajante"}
      selectedButtonText={
        selectedTraveler ? selectedTraveler.label : "Viajante"
      }
      containerStyle={containerStyles}
    >
      <div>
        <FormControl style={{ width: "240px" }}>
          <div>
            <Select
              placeholder="Buscar viajante"
              value={selectedTraveler}
              options={options}
              onChange={handleChange}
            />
          </div>
        </FormControl>
      </div>
    </FilterButtonPopover>
  );
};

export default TravelerFilter;
