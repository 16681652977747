import * as busApi from "~/apps/corporate/apis/bus.api";
import {
  BusTripDetailsContentResponse,
  BusTripsResponse,
} from "~/apps/corporate/dtos/bus.dto";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const addBusOfferToTravel = async (busOfferData: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await busApi.addBusOfferToTravel(busOfferData);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.BUS_OFFER_INSERTION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.BUS_OFFER_INSERTION_ERROR;
    }
  }

  return result;
};

export const getBusTripDetailsResult = async (
  allTripsDetailsRequests: {
    busToken: string;
    tripId: string;
  }[],
) => {
  const result: { data?: BusTripDetailsContentResponse[]; error?: Error } = {};

  try {
    result.data = await busApi.getAllTripsDetails(allTripsDetailsRequests);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUS_DETAILS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUS_DETAILS_ERROR;
    }
  }

  return result;
};

export const getBusTripsResult = async (busSearchToken: string) => {
  const result: { data?: BusTripsResponse; error?: Error } = {};

  try {
    result.data = await busApi.getBusTrips(busSearchToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUS_LIST_ERROR;
    }
  }

  return result;
};
