import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "8px",
  }),
};
