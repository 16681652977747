import React from "react";
import { css } from "@styled-system/css";
import { Flex, Text, Paragraph } from "@toolkit";
import { StOutlinedButton } from "@shared/buttons";
import DoneAllIcon from "@material-ui/icons/DoneAll";

export interface SuccessMessageProps {
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const styles = {
  successIcon: css({
    width: "3rem",
    height: "3rem",
    color: "success"
  })
};

export const SuccessMessage: React.FC<SuccessMessageProps> = ({
  handleClose
}) => {
  return (
    <Flex flexDirection="column" px="xlarge" py="large">
      <Flex alignItems="center">
        <DoneAllIcon css={styles.successIcon} />
        <Text ml="medium" fontWeight="bold">
          Solicitação enviada!
        </Text>
      </Flex>
      <Paragraph mt="medium" mb="3rem" fontSize={[14, 16]} lineHeight={1.5}>
        Seu pedido de adiantamento já foi enviado para que seus aprovadores
        possam revisá-lo, agora basta aguardar sua resposta 😉
      </Paragraph>
      <StOutlinedButton onClick={handleClose} css={css({ width: "100%" })}>
        Fechar
      </StOutlinedButton>
    </Flex>
  );
};

export default SuccessMessage;
