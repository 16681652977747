import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    text: css({
      color: theme.colors.gray[300],
      lineHeight: "1.125",
      maxWidth: "384px",
      textAlign: "center",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "1.5rem",
      fontWeight: 700,
    }),
  },
  navigate: {
    root: css({
      paddingBottom: "24px",
    }),
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
    ["@media (max-width: 767px)"]: {
      padding: "0 2rem",
    },
  }),
};
