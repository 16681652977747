import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    root: css({}),
  },
  right: {
    name: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "24px",
      textAlign: "right",
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "12px",
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    display: "flex",
    gap: "32px",
    justifyContent: "space-between",
    padding: "24px 80px",
    position: "sticky",
    top: 0,
    zIndex: 2,
    "@media (max-width: 1280px)": {
      padding: "24px",
    },
  }),
};
