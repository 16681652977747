export interface Budget {
  budgetToken: string;
  costCenterName: string;
  costCenterToken: string;
  description: string;
  plannedValue: number;
  initialDate: string;
  endDate: string;
  creatorToken: string;
  createdAt: string;
  modifierToken: string;
  modifiedAt: string;
  warningValue: number;
  active: boolean;
}

export interface BudgetListItem {
  original: number;
  totalSpent: number;
  balance: number;
  budgetToken: string;
  costCenterName: string;
  costCenterToken: string;
  plannedValue: number | null;
  availableBalance: number | null;
  month: number;
  year: number;
  creatorToken: string;
  createdAt: string;
  modifierToken: string;
  modifiedAt: string;
  warningValue: string;
}

export interface Operation {
  operationToken: string;
  costCenterToken: string;
  originCostCenterToken?: string;
  destinationCostCenterToken?: string;
  value: number;
  type: number;
  operatorToken: string;
  expenseToken: string;
  createdAt: string;
}

export interface BudgetLimitItem {
  costCenterToken: string;
  vaultExtrapolated: boolean;
  budgetExtrapolated: boolean;
}

export interface BudgetResult {
  budget_token: string;
  cost_center_token: string;
  billing_profile_token: string;
  timeframe: TimeframeType;
  year: number;
  semester: number;
  trimester: number;
  month: number;
  action: BudgetActionType;
  value: number;
  timesViolated: number;
  created_at: Date;
  updated_at: Date;
}

export interface DeleteBudgetResult {
  result: string;
}

export enum TimeframeType {
  YEAR = "YEAR",
  TRIMESTER = "TRIMESTER",
  MONTH = "MONTH",
  SEMESTER = "SEMESTER"
}

export enum BudgetActionType {
  NOTIFY_FIRST = "NOTIFY_FIRST",
  NOTIFY_EACH = "NOTIFY_EACH",
  NONE = "NONE",
  PREVENT = "PREVENT"
}

export enum BudgetTarget {
  COST_CENTER = "COST_CENTER",
  BILLING_PROFILE = "BILLING_PROFILE"
}

export interface RegisterBudgetDto {
  cost_center_token?: string;
  billing_profile_token?: string;
  timeframe: TimeframeType;
  year: number;
  semester?: number;
  trimester?: number;
  month?: number;
  action: BudgetActionType;
  value: number;
}

export interface UpdateBudgetDto {
  cost_center_token?: string;
  billing_profile_token?: string;
  timeframe: TimeframeType;
  year: number;
  semester?: number;
  trimester?: number;
  month?: number;
  action: BudgetActionType;
  value: number;
}
