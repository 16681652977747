import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  warningDiv: css({
    padding: "0 0 1rem 0",
    fontSize: "14px",
    ["@media (max-width: 767px)"]: {
      fontSize: 12
    }
  }),
  warningMessage: css({
    lineHeight: "20px",
    ["@media (max-width: 767px)"]: {
      lineHeight: "15px"
    }
  })
};

const GeneralWarning = (props: { title: string; message: string }) => {
  const { title, message } = props;
  return (
    <div className={styles.warningDiv}>
      <div
        style={{
          color: defaultTheme.secondaryColor,
          fontWeight: 600,
          padding: "0 0 0.5rem 0"
        }}
      >
        {title}
      </div>
      <div className={styles.warningMessage}>{message}</div>
    </div>
  );
};

export { GeneralWarning };
