import React from "react";

import { BusTripDetailsContentSeat } from "~/apps/corporate/dtos/bus.dto";
import { Icon } from "~/apps/shared/components/icon/icon";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  selectedSeats: BusTripDetailsContentSeat[];
  updateSeats: (selectedSeats: BusTripDetailsContentSeat[]) => void;
};

export const SelectedSeatsList: React.FC<Props> = ({
  selectedSeats,
  updateSeats,
}) => {
  const handleRemove = (seatId: string) => () => {
    const updatedSeats = selectedSeats.filter((seat) => seat.id !== seatId);
    updateSeats(updatedSeats);
  };

  return (
    <div css={styles.root}>
      <span css={styles.label}>Assento selecionado:</span>
      {selectedSeats.length > 0 ? (
        <ul css={styles.list.root}>
          {selectedSeats.map((seat) => (
            <SelectedSeatsListItem
              handleRemove={handleRemove}
              key={seat.id}
              seat={seat}
            />
          ))}
        </ul>
      ) : (
        <span css={styles.empty}>(clique no assento desejado)</span>
      )}
    </div>
  );
};

type SelectedSeatsListItemProps = {
  handleRemove: (seatId: string) => () => void;
  seat: BusTripDetailsContentSeat;
};

const SelectedSeatsListItem: React.FC<SelectedSeatsListItemProps> = ({
  handleRemove,
  seat,
}) => {
  return (
    <li css={styles.list.item.root}>
      <div css={styles.list.item.left.root}>
        <div
          css={styles.list.item.left.square({
            color: "#FFC82C",
          })}
        />
        <span css={styles.list.item.left.text}>
          Assento <strong>{seat.name}</strong>
        </span>
      </div>
      <div css={styles.list.item.right.root}>
        <span css={styles.list.item.right.price}>
          <strong>{toCurrency(seat.details.price)}</strong>
        </span>
        <button
          css={styles.list.item.right.button}
          onClick={handleRemove(seat.id)}
        >
          <Icon size={18} use="x-circle-outline" />
        </button>
      </div>
    </li>
  );
};
