import React, { useMemo } from "react";

import { Breadcrumbs } from "~/apps/shared/components/breadcrumbs/breadcrumbs";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { useItinerary } from "../../itinerary.context";

export const ItineraryHeaderBreadcrumbs: React.FC = () => {
  const {
    location: { pathname },
  } = useLocation();

  const { travelToken } = useItinerary();

  const routes = useMemo(() => {
    const isApprovalRequest = pathname.includes("approval-request");

    if (isApprovalRequest) {
      return [
        {
          name: "Plano de Viagem",
          url: `/travels/${travelToken}/itinerary/travel-plan`,
        },
        {
          name: "Pendências Administrativas",
          url: `/travels/${travelToken}/itinerary/pendencies`,
        },
        {
          name: "Solicitar Aprovação",
          url: `/travels/${travelToken}/itinerary/approval-request`,
        },
      ];
    }

    return [
      {
        name: "Plano de Viagem",
        url: `/travels/${travelToken}/itinerary/travel-plan`,
      },
      {
        name: "Pendências Administrativas",
        url: `/travels/${travelToken}/itinerary/pendencies`,
      },
      {
        name: "Finalização",
        url: "",
      },
    ];
  }, [pathname, travelToken]);

  return <Breadcrumbs routes={routes} />;
};
