import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

const getColors = ({
  checked,
  variant,
}: {
  checked: boolean;
  variant: "blue" | "default" | "green" | "pink";
}) => {
  switch (variant) {
    case "pink": {
      return {
        backgroundColor: checked ? theme.colors.pink[500] : theme.colors.white,
        borderColor: checked ? theme.colors.pink[500] : theme.colors.gray[500],
        color: checked ? theme.colors.white : theme.colors.pink[700],
        ":hover": {
          backgroundColor: checked
            ? theme.colors.pink[700]
            : theme.colors.white,
          borderColor: checked
            ? theme.colors.pink[700]
            : theme.colors.gray[700],
        },
      };
    }
    default: {
      return {
        backgroundColor: checked ? theme.colors.gray[500] : theme.colors.white,
        borderColor: checked ? theme.colors.gray[500] : theme.colors.gray[500],
        color: checked ? theme.colors.white : theme.colors.gray[700],
        ":hover": {
          backgroundColor: checked
            ? theme.colors.gray[700]
            : theme.colors.white,
          borderColor: theme.colors.gray[700],
        },
      };
    }
  }
};

export const styles = {
  checkbox: ({
    checked,
    variant,
  }: {
    checked: boolean;
    variant: "blue" | "default" | "green" | "pink";
  }) =>
    css({
      alignItems: "center",
      border: "1px solid",
      borderRadius: "4px",
      display: "flex",
      flexShrink: 0,
      height: "18px",
      justifyContent: "center",
      width: "18px",
      ...getColors({ checked, variant }),
    }),
  icon: ({ checked }: { checked: boolean }) =>
    css({
      opacity: checked ? 1 : 0,
      "& > svg": {
        strokeWidth: "3px",
      },
    }),
  input: css({
    height: "0",
    width: "0",
    opacity: "0",
    zIndex: -1,
  }),
  root: ({ disabled = false }: { disabled?: boolean }) =>
    css({
      cursor: "pointer",
      display: "flex",
      flexShrink: 0,
      ...(disabled && {
        cursor: "default",
        opacity: 0.5,
      }),
    }),
};
