import { UpdatePasswordResponse } from "~/apps/corporate/dtos/password.dto";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

import errors, { ERROR } from "../constants/errors";

export const redefinePassword = async (token: string, password: string) => {
  return await api
    .request({
      data: {
        password,
      },
      method: "POST",
      url: `/auth/password/reset/${token}`,
    })
    .then(({ data }) => {
      if (data.code === 200) {
        return {
          message:
            "Sua senha foi redefinida com sucesso, você será redirecionado para a tela de login.",
          success: true,
        };
      }
    })
    .catch((error) => {
      if (error.response) {
        const { data, status } = error.response;

        if (status === 403 && data.message === "Expired reset token") {
          throw {
            description: "Token de reset de senha expirado.",
            title: "Token expirado",
          };
        }

        if (status === 403 && data.message === "Invalid reset token") {
          throw {
            description: "Token de reset de senha inválido.",
            title: "Token inválido",
          };
        }

        throw { description: "Erro inesperado", title: "title" };
      } else {
        throw { description: "Erro inesperado", title: "title" };
      }
    });
};

export const resetPassword = async (email: string) => {
  return await api
    .request({
      data: {
        email,
      },
      method: "POST",
      url: "/auth/password/forgot",
    })
    .then(({ data }) => {
      if (data.code === 200) {
        return {
          message:
            "Instruções para redifinir sua senha foram enviadas para o seu email. Lembre-se de checar também a caixa de spam.",
          success: true,
        };
      }
    })
    .catch(handleError);
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  return await api
    .request<UpdatePasswordResponse>({
      data: {
        new_password: newPassword,
        old_password: oldPassword,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: "/booking/user/change/password",
    })
    .then(({ data }) => {
      if (!data.result) {
        throw ERROR.UNEXPECTED;
      }

      return {
        message: "Senha alterada com sucesso.",
        success: true,
      };
    })
    .catch(handleError);
};

const handleError = (error: any) => {
  if (error.response) {
    const { data, status } = error.response;

    if (status === 400 && data.type === errors.ERROR_FINDING_USER) {
      throw {
        description: "Nenhum usuário encontrado com as informações enviadas. ",
        title: "Error",
      };
    } else if (
      status === 400 &&
      data.type === errors.EMAIL_PASSWORD_INCORRECT
    ) {
      throw {
        description: "Senha atual incorreta.",
        title: "Error",
      };
    } else {
      throw { description: "Erro inesperado", title: "title" };
    }
  }
};
