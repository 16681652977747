import React, { useState } from "react";

import { EXPENSE_STATUS, EXPENSES_CATEGORIES } from "@constants";

import { Expense, ExpensePolicy } from "@models/expense.model";

import { ExpenseItemPresentational } from "./ExpenseItem.presentational";

interface Props {
  expense: Expense;
  policyInfo?: ExpensePolicy;
  editable?: boolean;
  onClick: () => void;
  onInputChange: (expenseToken: string) => (value: number) => void;
  handleOpenPolicyInfo: (expense: Expense) => () => void;
}

const ExpenseItemContainer = ({
  expense,
  policyInfo,
  editable,
  onClick,
  onInputChange,
  handleOpenPolicyInfo
}: Props) => {
  const [touched, setTouched] = useState(false);
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) =>
    setTouched(true);

  const areParticipantsVisible =
    expense.expenseCategory === EXPENSES_CATEGORIES.BREAKFAST ||
    expense.expenseCategory === EXPENSES_CATEGORIES.LUNCH ||
    expense.expenseCategory === EXPENSES_CATEGORIES.DINNER ||
    expense.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
    expense.expenseCategory === EXPENSES_CATEGORIES.APARTMENT;

  const wasCreatedManually = expense.status === EXPENSE_STATUS.CREATED_MANUAL;

  const isRefundable = wasCreatedManually && !expense.canceled;

  const isDisabled = !wasCreatedManually || expense.canceled;

  const showApprovalInput = wasCreatedManually && editable && !expense.canceled;

  const getErrors = () => {
    const { value, approvedValue } = expense;

    if (touched && !approvedValue && approvedValue !== 0) {
      return "Preencha o valor a ser aprovado";
    } else if (touched && !!approvedValue && approvedValue > value) {
      return "O valor aprovado deve ser igual ou inferior ao valor da despesa";
    }

    return null;
  };

  return (
    <ExpenseItemPresentational
      expense={expense}
      policyInfo={policyInfo}
      showApprovalInput={showApprovalInput}
      disabled={isDisabled}
      refundable={isRefundable}
      participantsVisible={areParticipantsVisible}
      error={getErrors()}
      onClick={onClick}
      onInputChange={onInputChange(expense.expenseToken)}
      onInputBlur={handleBlur}
      handleOutOfPolicyClick={handleOpenPolicyInfo(expense)}
    />
  );
};

export { ExpenseItemContainer as ExpenseItem };
