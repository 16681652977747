import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";

import { TravelPlanPDFServiceCancelationInfo } from "../../travel-plan-pdf-service-cancelation-info/travel-plan-pdf-service-cancelation-info";
import { TravelPlanPDFServiceCancelationUntil } from "../../travel-plan-pdf-service-cancelation-until/travel-plan-pdf-service-cancelation-until";
import { TravelPlanPDFServiceCanceledBy } from "../../travel-plan-pdf-service-canceled-by/travel-plan-pdf-service-canceled-by";
import { styles } from "./styles";
import { TravelPlanPDFHotelSegment } from "./travel-plan-pdf-hotel-segment/travel-plan-pdf-hotel-segment";

type Props = {
  servicePresenter: HotelItineraryServicePresenter;
  traveler: {
    name: string;
  };
};

export const TravelPlanPDFHotelJourney: React.FC<Props> = ({
  servicePresenter,
  traveler,
}) => {
  const badgeStyles = styles.badge();
  const rootStyles = styles.root();

  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();

  return (
    <View style={rootStyles.root}>
      {servicePresenter.showReservation() && traveler.name ? (
        <View style={badgeStyles.root}>
          <PDFIcon size={14} use="person" />
          <View>
            <Text>Reservado em nome de {traveler.name}.</Text>
          </View>
        </View>
      ) : null}
      <View style={rootStyles.card}>
        <TravelPlanPDFHotelSegment
          amenities={servicePresenter.getAmenities()}
          hotel={{
            address: servicePresenter.getAddress(),
            favorites: [],
            mainPictureUrl: servicePresenter.getMainImageURL(),
            name: servicePresenter.getHotelName(),
            stars: servicePresenter.getStars(),
          }}
        />
      </View>
      <View style={badgeStyles.root}>
        <PDFIcon size={14} use="arrow-right-end-on-rectangle" />
        <View>
          <Text>Check-in: {servicePresenter.getFormattedCheckIn()}</Text>
        </View>
      </View>
      <View style={badgeStyles.root}>
        <PDFIcon size={14} use="arrow-right-start-on-rectangle" />
        <View>
          <Text>Check-out: {servicePresenter.getFormattedCheckOut()}</Text>
        </View>
      </View>
      {formattedFreeCancelationUntil ? (
        <TravelPlanPDFServiceCancelationUntil
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          message="(horário local do hotel)"
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <TravelPlanPDFServiceCancelationInfo
          cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
          cancelType={cancelInfo.type}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage ? (
        <TravelPlanPDFServiceCanceledBy
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      ) : null}
    </View>
  );
};
