import moment, { Moment } from "moment";
import * as yup from "yup";

export const hotelFormSchema = yup
  .object()
  .shape({
    endDate: yup
      .mixed<Moment>()
      .required("Preencha a data de check-out")
      .nullable(true),
    location: yup
      .object()
      .required("Preencha o local da hospedagem")
      .nullable(true)
      .shape({
        latitude: yup.number().required(),
        longitude: yup.number().required(),
        search: yup.string().required("Preencha o local da hospedagem"),
      }),
    startDate: yup
      .mixed<Moment>()
      .required("Preencha a data de check-in")
      .test("", "Data inicial inferior a hoje", (startDate) => {
        if (!startDate) {
          return false;
        }

        const today = moment().startOf("day");

        if (startDate.isBefore(today)) {
          return false;
        }

        return true;
      })
      .nullable(true),
    totalGuests: yup
      .number()
      .required("Preencha o número de hóspedes")
      .test("", "Número de hóspedes deve ser pelo menos 1", (totalGuests) => {
        if (!totalGuests || totalGuests < 1) {
          return false;
        }

        return true;
      }),
  })
  .test(
    "endDate",
    "Tempo de hospedagem deve ser inferior a 31 dias",
    (values) => {
      if (
        values.startDate &&
        values.endDate &&
        values.endDate.diff(values.startDate, "days") > 31
      ) {
        return false;
      }

      return true;
    },
  )
  .test("endDate", "Check-in e check-out devem ser diferentes", (values) => {
    if (
      values.endDate &&
      values.startDate &&
      values.endDate.isSame(values.startDate)
    ) {
      return false;
    }

    return true;
  });

export type HotelFormSchema = yup.InferType<typeof hotelFormSchema>;
