import React, { useCallback, useMemo, useState } from "react";

import { ButtonWithPopover } from "~/apps/shared/components/button-with-popover/button-with-popover";
import { MonthPicker } from "~/apps/shared/components/month-picker/month-picker";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { PeriodRange } from "../../../analytics.types";
import { useReportDownload } from "../analytics-report-download.context";
import { getPeriodFilterText } from "../anayltics-report-download.helper";
import { styles } from "./styles";

type Props = {};

export const ReportDownloadActions: React.FC<Props> = () => {
  const { width } = useWindowSize();

  const {
    dateFilters,
    onPeriodFilterClose,
    onPeriodFilterOpen,
    onPeriodRangeApply,
  } = useReportDownload();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleApplyPeriodRange = useCallback(
    (periodRange: PeriodRange) => {
      onPeriodRangeApply(periodRange);
      onPeriodFilterClose();
      setIsPopoverOpen(false);
    },
    [onPeriodRangeApply, onPeriodFilterClose, setIsPopoverOpen],
  );

  const handleClosePopover = useCallback(() => {
    onPeriodFilterClose();
    setIsPopoverOpen(false);
  }, [onPeriodFilterClose]);

  const handleOpenPopover = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onPeriodFilterOpen(e);
      setIsPopoverOpen(true);
    },
    [onPeriodFilterOpen],
  );

  const periodFilterText = useMemo(() => getPeriodFilterText(dateFilters), [
    dateFilters,
  ]);

  return (
    <div css={styles.root}>
      <span css={styles.label}>Selecione um período:</span>
      <ButtonWithPopover
        css={styles.button}
        label={periodFilterText}
        onClose={handleClosePopover}
        onOpen={handleOpenPopover}
        open={isPopoverOpen}
      >
        <MonthPicker
          endMonth={dateFilters.endMonth - 1}
          endYear={dateFilters.endYear}
          onRangeApply={handleApplyPeriodRange}
          orientation={width >= 992 ? "horizontal" : "vertical"}
          startMonth={dateFilters.startMonth - 1}
          startYear={dateFilters.startYear}
        />
      </ButtonWithPopover>
    </div>
  );
};
