import { useQuery } from "react-query";

import * as costCenterApi from "~/apps/corporate/apis/cost-center.api";

export const useUserCostCenters = (userToken?: string, enabled?: boolean) => {
  const {
    data: costCenters,
    error: errorCostCenters,
    isLoading: isLoadingCostCenters,
  } = useQuery(
    ["users", userToken, "cost-centers"],
    async () => {
      if (!userToken) {
        return;
      }

      return await costCenterApi.getUserCostCenters(userToken);
    },
    {
      enabled: (enabled === undefined ? true : enabled) && !!userToken,
    },
  );

  return {
    costCenters,
    errorCostCenters,
    isLoadingCostCenters,
  };
};
