import React, { ReactNode, useState, useEffect } from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";
import { Row } from "../../../shared/layout/Row";
import Collapse from "@material-ui/core/Collapse";

const styles = {
  clickableSpan: css({
    color: defaultTheme.primaryColor,
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 14
  }),
  sectionTitleDiv: css({
    padding: "1.5rem 0",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  sectionTitle: css({
    fontSize: 20,
    color: defaultTheme.textColor,
    fontWeight: "bold"
  }),
  sectionContent: css({
    paddingTop: "1rem",
    fontSize: 15
  })
};

interface FormSectionProps {
  title: string;
  children: ReactNode;
  defaultCollapsed: boolean;
}

const FormSection = ({
  title,
  children,
  defaultCollapsed
}: FormSectionProps) => {
  const [isCollapsed, setCollapse] = useState(true);

  const toogleCollapse = () => setCollapse(prevCollapse => !prevCollapse);

  useEffect(() => {
    setCollapse(defaultCollapsed);
  }, []);

  return (
    <div>
      <Row className={styles.sectionTitleDiv}>
        <span className={styles.sectionTitle}>{title}</span>
        <div
          className={styles.clickableSpan}
          style={{ paddingLeft: "1rem", fontSize: 14, alignSelf: "center" }}
          onClick={toogleCollapse}
        >
          {isCollapsed ? "Ver mais" : "Ver menos"}
        </div>
      </Row>
      <Collapse in={!isCollapsed}>
        <div className={styles.sectionContent}>{children}</div>
      </Collapse>
    </div>
  );
};

FormSection.defaultProps = {
  defaultCollapsed: true
} as Partial<FormSectionProps>;

export { FormSection };
