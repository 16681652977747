enum Level {
  INFO = "info",
  ERROR = "error",
}

class Logger {
  public error(message: Error | string) {
    console.error({
      level: Level.ERROR,
      message,
      timestamp: new Date().toISOString(),
    });
  }

  public info(message: string) {
    console.info({
      level: Level.INFO,
      message,
      timestamp: new Date().toISOString(),
    });
  }
}

export const logger = new Logger();
