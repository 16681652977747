import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  controls: {
    right: css({
      alignItems: "center",
      display: "flex",
      flexShrink: 0,
      gap: "16px",
      "@media screen and (max-width: 640px)": {
        flexWrap: "wrap",
      },
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      padding: "0 80px",
      "@media (max-width: 1280px)": {
        padding: "0 24px",
      },
      "@media (max-width: 992px)": {
        padding: "0 16px",
      },
      "@media screen and (max-width: 768px)": {
        alignItems: "flex-start",
        flexDirection: "column",
      },
    }),
  },
  layout: css({
    backgroundColor: theme.colors.background.gray,
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "16px",
    margin: "0 auto",
    maxWidth: "1440px",
    overflow: "hidden",
    padding: "16px 0 64px 0",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "16px 0 0 0",
    },
  }),
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.5rem",
    fontWeight: 600,
    padding: "12px 80px",
    "@media (max-width: 1280px)": {
      padding: "12px 24px",
    },
    "@media (max-width: 992px)": {
      padding: "12px 16px",
    },
  }),
};
