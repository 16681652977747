import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Row } from "../../shared/layout/Row";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";
import clsx from "classnames";

const styles = {
  root: (hasBorder: boolean) =>
    css({
      alignItems: "center",
      padding: ".5rem 0",
      justifyContent: "space-between",
      maxHeight: 41,
      borderBottom: hasBorder
        ? `1px solid ${defaultTheme.cardBorderColor}`
        : "none"
    }),
  text: css({
    fontSize: 15,
    color: defaultTheme.textColor
  }),
  subtext: css({
    fontSize: 15,
    color: defaultTheme.subTextColor,
    marginRight: ".5rem"
  }),
  icon: css({
    color: defaultTheme.textColor,
    fontSize: 20,
    cursor: "pointer"
  })
};

interface Props {
  name: string;
  email: string;
  onDelete?: () => void;
  containerClasses?: string;
  hasBorder: boolean;
}

const TargetRow = ({
  name,
  email,
  onDelete,
  containerClasses,
  hasBorder
}: Props) => {
  return (
    <Row
      className={clsx(styles.root(hasBorder), containerClasses, "col-md-12")}
    >
      <div>
        <span className={styles.text}>{name}</span>
      </div>
      <Row className="justify-content-center align-items-center">
        <span className={styles.subtext}>{email}</span>
        <CloseIcon onClick={onDelete} className={styles.icon} />
      </Row>
    </Row>
  );
};

TargetRow.defaultProps = {
  onDelete: () => null,
  containerClasses: "",
  hasBorder: true
} as Partial<Props>;

export { TargetRow };
