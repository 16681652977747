import React from "react";

import { ItineraryFlightSegment } from "~/apps/corporate/components/itinerary-flight-segment/itinerary-flight-segment";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { Badge, Button } from "@toolkit/v2";

import { useFlights } from "../flights.context";
import { styles } from "./styles";

export const FlightReservationConflictDialog: React.FC = () => {
  const {
    conflictingInfo,
    handleCancelReservation,
    handleReserveCancelationDialogClose,
    isReserveCancelationDialogOpen,
    isReserveCancelLoading,
  } = useFlights();

  const flightOffer = conflictingInfo.flightOffer;

  return (
    <Dialog
      onClose={() => {
        if (isReserveCancelLoading) {
          return;
        }

        handleReserveCancelationDialogClose();
      }}
      open={isReserveCancelationDialogOpen}
    >
      <DialogHeader
        icon="x-circle-outline"
        onClose={() => {
          handleReserveCancelationDialogClose();
        }}
        variant="pink"
      />
      <DialogContent>
        <div css={styles.top.root}>
          <DialogTitle variant="pink">
            Houve um conflito na reserva.
          </DialogTitle>
          <span css={styles.top.message}>
            {conflictingInfo.canCancelExistingPreReserve
              ? "O viajante já possui uma pré-reserva nesta data."
              : "O viajante já possui uma reserva nesta data."}
          </span>
        </div>
        {flightOffer ? (
          <div css={styles.center.root}>
            <div css={styles.center.flight.root}>
              <span css={styles.center.flight.message}>
                {conflictingInfo.canCancelExistingPreReserve
                  ? "Uma pré-reserva para essa data e companhia aérea já foi realizada. Para prosseguir com esta pré-reserva, é necessário cancelar a pré-reserva anterior. Deseja cancelá-la?"
                  : "Um voo para essa data e companhia aérea já foi emitido, está em processo de emissão/pré-reserva, ou está em processo de cancelamento. Para evitar multas, não é possível realizar esta pré-reserva. Detalhes da reserva existente:"}
              </span>
              <p css={styles.center.travel.id}>
                Viagem: {flightOffer.travelToken}
              </p>
              <p css={styles.center.flight.date}>
                {moment(
                  flightOffer.offer.outbound.departure,
                  "DD/MM/YYYY",
                ).format("dddd, MMM DD")}
              </p>
              <ItineraryFlightSegment
                arrival={{
                  airportCode: flightOffer.offer.outbound.destination.code,
                  time: flightOffer.offer.outbound.arrivalHour,
                }}
                baggage={{
                  checked: {
                    included: flightOffer.offer.outbound.baggage,
                    quantity: flightOffer.offer.outbound.baggagePieces,
                  },
                }}
                carrier={{
                  imageUrl: flightOffer.offer.outbound.carrier?.imageUrl || "",
                  name: flightOffer.offer.outbound.carrier?.name || "",
                }}
                connection={{}}
                css={styles.center.flight.card}
                daysDiff={flightOffer.offer.outbound.daysDiff}
                duration={flightOffer.offer.outbound.durationHours}
                departure={{
                  airportCode: flightOffer.offer.outbound.origin.code,
                  time: flightOffer.offer.outbound.departureHour,
                }}
              />
            </div>
          </div>
        ) : null}
        <Badge css={styles.center.flight.disclaimer.root} variant="pink">
          <Icon
            css={styles.center.flight.disclaimer.icon}
            size={16}
            use="info-outline"
          />
          <span css={styles.center.flight.disclaimer.text}>
            Importante: essa limitação é imposta pelas companhias aéreas.
          </span>
        </Badge>
      </DialogContent>
      <DialogFooter css={styles.footer.root}>
        {conflictingInfo.canCancelExistingPreReserve ? (
          <>
            <Button
              disabled={isReserveCancelLoading}
              onClick={() =>
                handleCancelReservation(conflictingInfo.flightOffer!.offerToken)
              }
              variant="pink"
            >
              {!isReserveCancelLoading && "Sim, cancelar a anterior"}
            </Button>
            <Button
              disabled={isReserveCancelLoading}
              fill="outlined"
              onClick={() => {
                handleReserveCancelationDialogClose();
              }}
            >
              Não
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              handleReserveCancelationDialogClose();
            }}
            variant="pink"
          >
            Ok, entendi
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
};
