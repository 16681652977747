import React, { createContext } from "react";

import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { ItineraryApprovalProvider } from "./itinerary-approval.context";
import { ItineraryInfoProvider } from "./itinerary-info.context";
import { ItineraryPendenciesProvider } from "./itinerary-pendencies.context";
import { ItineraryScreenProvider } from "./itinerary-screen.context";
import { ItineraryServicesProvider } from "./itinerary-services.context";
import { ItineraryContainerProvider } from "./itinerary.container";

const ItineraryContext = createContext<{
  travelToken: string;
}>({
  travelToken: "",
});

type Props = {
  travelToken: string;
};

export const ItineraryProvider: React.FC<Props> = ({
  children,
  travelToken,
}) => {
  return (
    <ItineraryContext.Provider value={{ travelToken }}>
      <ItineraryApprovalProvider>
        <ItineraryInfoProvider>
          <ItineraryServicesProvider>
            <ItineraryPendenciesProvider>
              <ItineraryScreenProvider>
                <ItineraryContainerProvider>
                  {children}
                </ItineraryContainerProvider>
              </ItineraryScreenProvider>
            </ItineraryPendenciesProvider>
          </ItineraryServicesProvider>
        </ItineraryInfoProvider>
      </ItineraryApprovalProvider>
    </ItineraryContext.Provider>
  );
};

export const useItinerary = useContextFactory(
  "ItineraryContext",
  ItineraryContext,
);
