import { useMemo } from "react";

import { useItineraryServices } from "../../../itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { ApprovalRequestServicesCardPresenterFactory } from "./approval-request-services-card.presenter";

export const useApprovalRequestServicesCardGuard = () => {
  const {
    errorOnFetch,
    isLoading: isLoadingItineraryServices,
    isLoadingTravelPoliciesPerOffer,
  } = useItineraryServices();

  return {
    error: errorOnFetch,
    loading: isLoadingItineraryServices || isLoadingTravelPoliciesPerOffer,
  };
};

export const useApprovalRequestServicesCardPresenter = () => {
  const { servicesPresenter } = useItineraryContainer();

  return useMemo(
    () => ({
      approvalRequestServicesCardPresenter: servicesPresenter
        ? ApprovalRequestServicesCardPresenterFactory.create(servicesPresenter)
        : null,
    }),
    [servicesPresenter],
  );
};
