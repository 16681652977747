import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { theme } from "smartrips-skin";
import * as utils from "@helpers";
import { tooltipStyles } from "../../common-styles";

const getPath = (x, y, width, height, index) => {
  const cY = 46 + index * 64;
  return `M ${x},${cY} h ${width} v ${height} h -${width} Z`;
};

const RetangleBar = props => {
  const { fill, x, y, width, height, index } = props;
  const dPath = getPath(x, y, width, height, index);

  return <path d={dPath} stroke={theme.colors.primary} fill={fill} />;
};

const TravelerSpentChart = ({ data }) => {
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <ComposedChart layout="vertical" data={data} margin={{ left: 20 }}>
        <CartesianGrid vertical={false} horizontal={false} />
        <XAxis
          type="number"
          hide={true}
          domain={[0, dataMax => dataMax + dataMax * 0.1]}
        />
        <YAxis dataKey="fullName" type="category" hide={true} />
        {/* Disabled for now */}
        {/* <Tooltip
          {...tooltipStyles}
          formatter={(value, name) => {
            return [utils.toCurrency(value), name];
          }}
        /> */}
        <Bar
          name="Valor total gasto"
          dataKey="totalSpent"
          barSize={20}
          fill={theme.colors.primary}
          shape={RetangleBar}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { TravelerSpentChart };
