import React from "react";

import { BusItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryScreen } from "../../../../pages/travels/itinerary/itinerary-screen.context";
import { ItineraryBusSegment } from "../../../itinerary-bus-segment/itinerary-bus-segment";
import { ItineraryServiceCancelationInfoBadge } from "../../../itinerary-service-cancelation-info-badge/itinerary-service-cancelation-info-badge";
import { ItineraryServiceCancelationUntilBadge } from "../../../itinerary-service-cancelation-until-badge/itinerary-service-cancelation-until-badge";
import { ItineraryServiceCanceledByBadge } from "../../../itinerary-service-canceled-by-badge/itinerary-service-canceled-by-badge";
import { ItineraryServiceReservationCodeBadge } from "../../../itinerary-service-reservation-code-badge/itinerary-service-reservation-code-badge";
import { styles } from "./styles";

type Props = {
  isDisabled: boolean;
  priceInfo?: boolean;
  servicePresenter: BusItineraryServicePresenter;
};

export const ItineraryBusJourney: React.FC<Props> = ({
  isDisabled,
  priceInfo,
  servicePresenter,
}) => {
  const { openServiceDetailsDrawer } = useItineraryScreen();

  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();
  const operationalId = servicePresenter.getOperationalId();
  const seats = servicePresenter.getAllSeats();

  return (
    <div
      css={styles.root({
        isDisabled,
      })}
    >
      {servicePresenter.showReservation() && operationalId ? (
        <ItineraryServiceReservationCodeBadge reservationCode={operationalId} />
      ) : null}
      <div
        css={styles.card.root({
          isDisabled,
        })}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          openServiceDetailsDrawer(servicePresenter.getService());
        }}
      >
        {servicePresenter.getPresentationJourneys().map((journey, index) => (
          <React.Fragment key={journey.index}>
            {index !== 0 ? <hr css={styles.card.divisor} /> : null}
            {journey.segments.map((segment) => (
              <ItineraryBusSegment
                arrival={{
                  address: segment.arrival.terminal,
                  time: journey.arrival.time,
                }}
                busName={segment.busType}
                carrierLogo={segment.carrier.imageUrl}
                carrierName={segment.carrier.name}
                departure={{
                  address: segment.departure.terminal,
                  time: journey.departure.time,
                }}
                duration={segment.duration}
                key={segment.index}
                seat={journey.seat}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      {seats.length > 0 ? (
        <div css={styles.seats.root}>
          <Icon css={styles.seats.icon} size={16} use="bus" />
          <span css={styles.seats.text}>
            Assento(s) selecionado(s): {seats.join(", ")}.
          </span>
        </div>
      ) : null}
      <div css={styles.info.root}>
        <Icon css={styles.info.icon} size={16} use="ticket" />
        <span css={styles.info.text}>
          {servicePresenter.isOneWay()
            ? "A passagem de ida deve ser impressa no local de embarque."
            : "As passagens de ida e de volta devem ser impressas no local de embarque."}
        </span>
      </div>
      {priceInfo ? (
        <div css={styles.price.root}>
          <Icon css={styles.price.icon} size={16} use="dollar" />
          <span css={styles.price.text}>
            Preço total (taxas inclusas no preço):{" "}
            <strong>{toCurrency(servicePresenter.getPrice())}.</strong>
          </span>
        </div>
      ) : (
        <>
          {/* {service.isEmitted() ? (
          <div css={styles.vouchers.root}>
            <Icon css={styles.vouchers.icon} size={16} use="ticket" />
            <span css={styles.vouchers.text}>
              Vouchers: {service.getVouchers().join(", ")}.
            </span>
          </div>
        ) : null} */}
          {formattedFreeCancelationUntil ? (
            <ItineraryServiceCancelationUntilBadge
              freeCancelationUntil={formattedFreeCancelationUntil}
              isCancelable={servicePresenter.isCancelable()}
              message="(horário local da companhia)"
              serviceStatus={servicePresenter.getStatus()}
            />
          ) : null}
          {servicePresenter.isCanceled() ? (
            <ItineraryServiceCancelationInfoBadge
              cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
              cancelType={cancelInfo.type}
            />
          ) : null}
          {cancelInfo.showCanceledByMessage && (
            <ItineraryServiceCanceledByBadge
              canceledAt={cancelInfo.canceledAt}
              canceledByUserName={cancelInfo.canceledByUserName}
              isCanceled={cancelInfo.isCanceled}
            />
          )}
        </>
      )}
    </div>
  );
};
