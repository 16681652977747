import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import * as busApi from "../../apis/bus.api";
import * as carApi from "../../apis/car.api";
import * as flightApi from "../../apis/flight.api";
import * as hotelApi from "../../apis/hotel.api";
import * as travelApi from "../../apis/travel.api";
import { BusSearchCreationResponse } from "../../dtos/bus.dto";
import { buildBusSearchData } from "../../helpers/bus.helper";
import { buildCarsSearchCreationData } from "../../helpers/car.helper";
import { buildFlightData } from "../../helpers/flight.helper";
import { buildHotelData } from "../../helpers/hotel.helper";
import { buildTravelData } from "../../helpers/travel.helper";
import { BookingTarget } from "../../models/booking-target.model";
import { UserModel } from "../../models/user.model";
import { FlightFactory, HotelFactory } from "../../parsers/travel.parser";
import {
  BusesSearch,
  CarsSearch,
  FlightsSearch,
  HotelsSearch,
} from "./offer-searcher.types";

export const createBusesSearch = async (
  formData: BusesSearch,
  travelToken: string,
): Promise<{ data?: BusSearchCreationResponse; error?: Error }> => {
  const result: { data?: BusSearchCreationResponse; error?: Error } = {};

  try {
    result.data = await busApi.createBusesSearch(
      buildBusSearchData(formData, travelToken),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
};

export const createCarsSearch = async (
  carsSearch: CarsSearch,
  travelToken: string,
) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await carApi.createCarsSearch(
      buildCarsSearchCreationData(carsSearch, travelToken),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
};

export const createFlightsSearch = async (
  flightsSearch: FlightsSearch,
  travelToken: string,
) => {
  const result: { data?: ReturnType<typeof FlightFactory>; error?: Error } = {};

  try {
    result.data = await flightApi.createFlightsSearch(
      buildFlightData(flightsSearch, travelToken),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
};

export const createHotelsSearch = async (
  hotelsSearch: HotelsSearch,
  travelToken: string,
) => {
  const result: { data?: ReturnType<typeof HotelFactory>; error?: Error } = {};

  try {
    result.data = await hotelApi.createHotelsSearch(
      buildHotelData(hotelsSearch, travelToken),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
};

export const createTravel = async (
  bookingTarget: BookingTarget,
  user: UserModel,
) => {
  const result: { data?: string; error?: Error } = {};

  try {
    result.data = await travelApi.createNewTravel(
      buildTravelData(bookingTarget, user),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
};
