import { css } from "@styled-system/css";

export const styles = {
  description: css({
    alignItems: "center",
    display: "flex",
    gap: "8px"
  }),
  heading: css({
    alignItems: "center",
    display: "flex",
    gap: "4px"
  }),
  icon: css({
    backgroundColor: "white",
    zIndex: 1
  }),
  root: css({
    display: "flex",
    fontSize: "14px",
    gap: "16px",
    padding: "16px",
    position: "relative",
    width: "100%",
    ":before": {
      backgroundColor: "transparent",
      borderLeft: "1px dashed rgba(0,0,0,0.1)",
      content: "''",
      height: "100%",
      left: "25px",
      position: "absolute",
      bottom: 0
    }
  })
};
