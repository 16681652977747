import React from "react";
import { components, GroupBase, SelectComponentsConfig } from "react-select";
import ReactSelectCreatable from "react-select/creatable";

import _ from "lodash";

import { styles } from "./styles";

const defaultComponents: SelectComponentsConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  // eslint-disable-next-line react/display-name
  Menu: ({ ...props }) => {
    const { getStyles } = props;

    return (
      <components.Menu
        getStyles={() => getStyles("menu", props) as any}
        {..._.omit(props, "getStyles")}
      />
    );
  },
  // eslint-disable-next-line react/display-name
  Option: ({ ...props }) => {
    const { getStyles } = props;

    return (
      <components.Option
        getStyles={() => getStyles("option", props) as any}
        {..._.omit(props, "getStyles")}
      />
    );
  },
};

type Props = React.ComponentPropsWithoutRef<typeof ReactSelectCreatable> & {
  error?: boolean;
  fill?: "filled" | "outlined";
  variant?: "blue" | "default" | "green" | "pink";
};

export const CreatableSelect: React.FC<Props> = ({
  components,
  error,
  fill = "outlined",
  variant = "default",
  ...props
}) => {
  return (
    <ReactSelectCreatable
      components={{
        ...defaultComponents,
        ...components,
      }}
      noOptionsMessage={({ inputValue: input }) =>
        `Nenhum resultado${input ? " para " + input : ""}...`
      }
      styles={styles({ error, fill, variant })}
      {...props}
    />
  );
};
