import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  cards: {
    card: {
      bottom: {
        destination: {
          airport: css({
            color: theme.colors.gray[700],
            fontSize: "1.5rem",
            fontWeight: 700,
          }),
          city: css({
            color: theme.colors.gray[500],
            fontSize: "0.875rem",
            textAlign: "right",
          }),
          root: css({
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            width: "100%",
          }),
        },
        icon: css({
          color: theme.colors.pink[500],
          flexShrink: 0,
        }),
        origin: {
          airport: css({
            color: theme.colors.gray[700],
            fontSize: "1.5rem",
            fontWeight: 700,
          }),
          city: css({
            color: theme.colors.gray[500],
            fontSize: "0.875rem",
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }),
          root: css({
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            width: "100%",
          }),
        },
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "48px",
          justifyContent: "space-between",
          width: "100%",
        }),
      },
      root: css({
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        fontFamily: theme.fonts.inter,
        gap: "24px",
        padding: "24px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:hover": {
          borderColor: theme.colors.gray[300],
        },
      }),
      top: {
        date: {
          label: css({ color: theme.colors.gray[500], fontSize: "0.875rem" }),
          value: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
            textAlign: "right",
          }),
          root: css({
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }),
        },
        root: css({
          display: "flex",
          gap: "48px",
          justifyContent: "space-between",
          width: "100%",
        }),
        traveler: {
          label: css({ color: theme.colors.gray[500], fontSize: "0.875rem" }),
          name: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
            textAlign: "left",
          }),
          root: css({
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }),
        },
      },
    },
    root: css({
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "16px 24px",
      width: "100%",
      "@media (max-width: 1024px)": {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      "@media screen and (max-width: 768px)": {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
    "@media screen and (max-width: 768px)": {
      gap: "16px",
    },
  }),
  title: css({
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    color: theme.colors.gray[700],
    fontWeight: 700,
    paddingBottom: "24px",
    "@media screen and (max-width: 768px)": {
      paddingBottom: "16px",
    },
  }),
};
