import { css, CSSObject } from "@styled-system/css";

const getPosition = ({ position }: { position: { x: string; y: string } }) => {
  const base: Record<string, CSSObject> = {
    ["0"]: {
      bottom: 0,
      left: `${parseInt(position.x, 10) * 44}px`,
      "@media screen and (max-width: 992px)": {
        bottom: "unset",
        left: 0,
        top: `${parseInt(position.x, 10) * 44}px`,
      },
    },
    ["1"]: {
      bottom: "44px",
      left: `${parseInt(position.x, 10) * 44}px`,
      "@media screen and (max-width: 992px)": {
        bottom: "unset",
        left: "44px",
        top: `${parseInt(position.x, 10) * 44}px`,
      },
    },
    ["3"]: {
      bottom: "100px",
      left: `${parseInt(position.x, 10) * 44}px`,
      top: "44px",
      "@media screen and (max-width: 992px)": {
        bottom: "unset",
        left: "unset",
        right: "44px",
        top: `${parseInt(position.x, 10) * 44}px`,
      },
    },
    ["4"]: {
      bottom: "100px",
      left: `${parseInt(position.x, 10) * 44}px`,
      top: 0,
      "@media screen and (max-width: 992px)": {
        bottom: "unset",
        left: "unset",
        right: 0,
        top: `${parseInt(position.x, 10) * 44}px`,
      },
    },
  };

  return base[position.y];
};

export const styles = {
  seats: {
    container: ({ maxX }: { maxX: number }) =>
      css({
        position: "relative",
        height: "190px",
        width: `${44 * (maxX + 1)}px`,
        "@media screen and (max-width: 992px)": {
          height: `${44 * (maxX + 1)}px`,
          width: "190px",
        },
      }),
    seat: {
      container: ({
        position,
      }: {
        position: {
          x: string;
          y: string;
          z: string;
        };
      }) =>
        css({
          height: "32px",
          position: "absolute",
          width: "32px",
          ...getPosition({ position }),
        }),
    },
  },
};
