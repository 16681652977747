import React, { useCallback, useEffect, useMemo, useState } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { SearchExpirationDialog } from "~/apps/shared/components/search-expiration-dialog/search-expiration-dialog";
import { useCheckExpiration } from "~/apps/shared/hooks/use-check-expiration";

import { HotelResultProvider, useHotelResult } from "./hotel-result.context";
import { HotelResultAmenities } from "./result-amenities/result-amenities";
import { HotelAvailableRooms } from "./result-available-rooms/result-available-rooms";
import { HotelResultDescription } from "./result-description/result-description";
import { HotelResultFavoriteDialog } from "./result-favorite-dialog/result-favorite-dialog";
import { HotelResultHeader } from "./result-header/result-header";
import { HotelResultInfo } from "./result-info/result-info";
import { HotelResultMap } from "./result-map/result-map";
import { HotelResultNearbyPlaces } from "./result-nearby-places/result-nearby-places";
import { HotelResultOutOfPolicyDialog } from "./result-out-of-policy-dialog/result-out-of-policy-dialog";
import { HotelResultPicturesDialog } from "./result-pictures-dialog/result-pictures-dialog";
import { HotelResultPictures } from "./result-pictures/result-pictures";
import { HotelResultRoomAmenitiesDialog } from "./result-room-amenities-dialog/result-room-amenities-dialog";
import { HotelResultRules } from "./result-rules/result-rules";
import { HotelResultUsefulInformation } from "./result-useful-information/result-useful-information";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    hospitalityHotelToken?: string;
    searchHotelToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({
  hospitalityHotelToken,
  searchHotelToken,
}) => {
  const {
    errorOnFetch,
    handleSetExpiration,
    hasExpired,
    hotelAmenities,
    hotelInfo,
    loadHotelDetails,
    loadHotelRooms,
    loadReceptionHours,
    nearbyPlaces,
    searchValidUntil,
  } = useHotelResult();
  useCheckExpiration(searchValidUntil, handleSetExpiration);

  const [view, setView] = useState<"default" | "pictures">("default");

  const toggleView = useCallback(() => {
    setView((prev) => (prev === "default" ? "pictures" : "default"));
  }, []);

  useEffect(() => {
    if (!hospitalityHotelToken || !searchHotelToken) {
      return;
    }

    void loadHotelDetails(searchHotelToken, hospitalityHotelToken);
    void loadHotelRooms(hospitalityHotelToken, searchHotelToken);
  }, []);

  useEffect(() => {
    if (!hospitalityHotelToken || !hotelInfo) {
      return;
    }

    if (!hotelInfo.checkInHour.informed && !hotelInfo.checkOutHour.informed) {
      loadReceptionHours(hospitalityHotelToken);
    }
  }, [hotelInfo]);

  const title = useMemo(() => (hotelInfo ? hotelInfo.name : ""), [hotelInfo]);

  if (errorOnFetch) {
    return (
      <ErrorPage
        message={errorOnFetch.description}
        title={errorOnFetch.title}
      />
    );
  }

  return (
    <>
      <PageTitle title={title} />
      <div css={styles.root}>
        <HotelResultHeader
          toggleView={view === "pictures" ? toggleView : undefined}
        />
        <div css={styles.body}>
          {view === "default" ? (
            <>
              <div css={styles.top}>
                <HotelResultInfo />
                <HotelResultPictures toggleView={toggleView} />
              </div>
              <HotelAvailableRooms />
              <div css={styles.info}>
                <HotelResultUsefulInformation />
                {hotelInfo && hotelInfo.description ? (
                  <HotelResultDescription description={hotelInfo.description} />
                ) : null}
                <HotelResultRules />
                <HotelResultNearbyPlaces places={nearbyPlaces} />
                {hotelAmenities.length > 0 ? (
                  <HotelResultAmenities amenities={hotelAmenities} />
                ) : null}
              </div>
              {hotelInfo ? (
                <HotelResultMap
                  css={styles.map}
                  lat={hotelInfo.latitude}
                  lng={hotelInfo.longitude}
                />
              ) : null}
            </>
          ) : (
            <>
              <HotelResultPictures expanded toggleView={toggleView} />
            </>
          )}
        </div>
      </div>
      {hotelInfo ? <HotelResultFavoriteDialog hotel={hotelInfo} /> : null}
      <HotelResultOutOfPolicyDialog />
      <HotelResultPicturesDialog />
      <HotelResultRoomAmenitiesDialog />
      <SearchExpirationDialog open={hasExpired} />
    </>
  );
};

export const HotelResult: React.FC<Props> = ({ ...props }) => {
  return (
    <HotelResultProvider>
      <Component {...props} />
    </HotelResultProvider>
  );
};
