import { UserBookingPhoneConfig } from "~/constants/enums";

export function User(userDto) {
  const {
    user_token,
    email,
    first_name,
    last_name,
    full_name,
    document,
    phone,
    booking_phone_config,
    send_sms,
    active,
    last_login,
    created_at,
    client_token,
    role,
    guest,
    capabilities,
    hidden,
    has_accepted_terms,
    billing_profile_token
  } = userDto;

  const result = {};

  result.userToken = user_token;
  result.email = email;
  result.firstName = first_name;
  result.lastName = last_name;
  result.fullName = full_name;
  result.document = document;
  result.phone = phone;
  result.bookingPhoneConfig = booking_phone_config
    ? booking_phone_config
    : UserBookingPhoneConfig.UNITIATED;
  result.sendSms = send_sms;
  result.active = active;
  result.lastLogin = last_login;
  result.createdAt = created_at;
  result.clientToken = client_token;
  result.role = role;
  result.guest = guest;
  result.capabilities = capabilities;
  result.hidden = hidden;
  result.billingProfileToken = billing_profile_token;
  result.hasAcceptedTerms = has_accepted_terms;

  return result;
}

export function UserAutocomplete(userDto) {
  const {
    user_token,
    email,
    first_name,
    last_name,
    full_name,
    document,
    client_token
  } = userDto;

  const result = {};

  result.userToken = user_token;
  result.email = email;
  result.firstName = first_name;
  result.lastName = last_name;
  result.fullName = full_name;
  result.document = document;
  result.clientToken = client_token;
  result.label = full_name;

  return result;
}

export function UserArea(userDto) {
  const {
    user_token,
    full_name,
    first_name,
    last_name,
    userArea,
    email
  } = userDto;

  const result = {};

  result.userToken = user_token;
  result.fullName = full_name;
  result.firstName = first_name;
  result.lastName = last_name;
  result.email = email;
  result.userAreaToken = userArea.user_area_token;

  return result;
}

export function UsersArea(usersDto) {
  return usersDto.map(dto => UserArea(dto));
}
