import React, { useRef, useState } from "react";
import { DayPickerRangeController } from "react-dates";

import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import moment, { Moment } from "moment";

import { Button } from "@toolkit/v2";

import { Form, useForm } from "../../../../shared/components/form/form";
import { HourSelect } from "../../../../shared/components/hour-select/hour-select";
import { Icon } from "../../../../shared/components/icon/icon";
import { InputErrorMessage } from "../../../../shared/components/input-error-message/input-error-message";
import { LocationAutocomplete } from "../../../../shared/components/location-autocomplete/location-autocomplete";
import { Popover } from "../../../../shared/components/popover/popover";
import { CarFormDateInput } from "../../offer-searcher/car-form/car-form-date-input/car-form-date-input";
import {
  carFormSchema,
  CarFormSchema,
} from "../../offer-searcher/car-form/car-form.schema";
import { useOfferSearcher } from "../../offer-searcher/offer-searcher.context";
import { CarsSearch } from "../../offer-searcher/offer-searcher.types";
import { styles } from "./styles";

type Props = {
  data: Partial<CarsSearch> | null;
  onClose: () => void;
  travelToken: string;
};

export const OfferSearcherDialogCarForm: React.FC<Props> = ({
  data,
  onClose,
  travelToken,
}) => {
  const { handleSearchCars } = useOfferSearcher();

  const [focusedDateInput, setFocusedDateInput] = useState<
    "endDate" | "startDate" | null
  >(null);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);

  const datesContainerElement = useRef<HTMLDivElement>(null);

  const form = useForm<CarFormSchema>({
    defaultValues: data
      ? {
          dropoffDate: data.dropoffDate ? data.dropoffDate : null,
          dropoffLocation: data.dropoffLocation ? data.dropoffLocation : null,
          dropoffTime: data.dropoffTime ? data.dropoffTime : "10",
          pickupDate: data.pickupDate ? data.pickupDate : null,
          pickupLocation: data.pickupLocation ? data.pickupLocation : null,
          pickupTime: data.pickupTime ? data.pickupTime : "10",
          sameLocationDropoff: data.sameLocationDropoff
            ? data.sameLocationDropoff
            : true,
        }
      : {
          dropoffDate: null,
          dropoffLocation: null,
          dropoffTime: "10:00",
          pickupDate: null,
          pickupLocation: null,
          pickupTime: "10:00",
          sameLocationDropoff: true,
        },
    onSubmit: async () => {
      const values = form.getValues();

      await handleSearchCars({
        dropoffDate: values.dropoffDate,
        dropoffLocation: values.dropoffLocation,
        dropoffTime: values.dropoffTime,
        pickupDate: values.pickupDate,
        pickupLocation: values.pickupLocation,
        pickupTime: values.pickupTime,
        sameLocationDropoff: values.sameLocationDropoff,
        travelToken,
      });

      onClose();
    },
    schema: carFormSchema,
  });

  const handleCloseDatePopover = () => {
    setIsDatePopoverOpen(false);
  };

  const handleDateInputClick = (type: "pickup" | "dropoff") => () => {
    if (type === "pickup") {
      setFocusedDateInput("startDate");
    } else {
      setFocusedDateInput("endDate");
    }

    setIsDatePopoverOpen(true);
  };

  const handleDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    form.setValue("pickupDate", date.startDate);

    if (date.startDate) {
      form.clearErrors("pickupDate");
    }

    form.setValue("dropoffDate", date.endDate);

    if (date.endDate) {
      form.clearErrors("dropoffDate");
    }
  };

  const handleDateInputFocusChange = (
    focusedDateInput: "endDate" | "startDate" | null,
  ) => {
    if (!focusedDateInput) {
      setIsDatePopoverOpen(false);
    }

    setFocusedDateInput(focusedDateInput);
  };

  return (
    <>
      <Form context={form} css={styles.root}>
        <div css={styles.center.root}>
          <div css={styles.center.control}>
            <div css={styles.center.same.root}>
              <label css={styles.center.same.radio}>
                <Checkbox
                  checked={form.watch("sameLocationDropoff")}
                  onChange={(e) => {
                    form.setValue("sameLocationDropoff", e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.center.same.label}>
                  Devolver no mesmo local
                </span>
              </label>
            </div>
            <InputErrorMessage>
              {form.errors["sameLocationDropoff"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.center.locations}>
            <div css={styles.center.control}>
              <span css={styles.center.label}>
                {!form.watch("sameLocationDropoff")
                  ? "Retirada"
                  : "Bairro, locadora de veículos ou ponto de interesse"}
              </span>
              <div css={styles.center.input.root}>
                <Icon size={20} use="car" />
                <LocationAutocomplete
                  css={styles.center.input.input}
                  locationType={[
                    "sublocality",
                    "street_address",
                    "airport",
                    "car_rental",
                    "point_of_interest",
                  ]}
                  placeholder="Digite o local da retirada..."
                  onSelect={(location) => {
                    if (!location) {
                      form.setValue("pickupLocation", null);

                      return;
                    }

                    form.setValue("pickupLocation", {
                      latitude: location.latitude,
                      longitude: location.longitude,
                      search: location.formattedAddress,
                    });

                    form.clearErrors("pickupLocation");
                  }}
                  value={form.getValues("pickupLocation")?.search}
                />
              </div>
              <InputErrorMessage>
                {form.errors["pickupLocation"]?.message}
              </InputErrorMessage>
            </div>
            {!form.watch("sameLocationDropoff") ? (
              <div css={styles.center.control}>
                <span css={styles.center.label}>Devolução</span>
                <div css={styles.center.input.root}>
                  <Icon size={20} use="car" />
                  <LocationAutocomplete
                    css={styles.center.input.input}
                    placeholder="Digite o local da devolução..."
                    onSelect={(location) => {
                      if (!location) {
                        form.setValue("dropoffLocation", null);

                        return;
                      }

                      form.setValue("dropoffLocation", {
                        latitude: location.latitude,
                        longitude: location.longitude,
                        search: location.formattedAddress,
                      });

                      form.clearErrors("dropoffLocation");
                    }}
                    value={form.getValues("dropoffLocation")?.search}
                  />
                </div>
                <InputErrorMessage>
                  {form.errors["dropoffLocation"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </div>
          <div css={styles.center.control}>
            <span css={styles.center.label}>Retirada - Devolução</span>
            <div css={styles.center.dates.root} ref={datesContainerElement}>
              <div css={styles.center.input.root}>
                <CarFormDateInput
                  css={styles.center.input.input}
                  onChange={() => {
                    return;
                  }}
                  onClick={handleDateInputClick("pickup")}
                  placeholder="Retirada"
                  value={
                    form.watch("pickupDate")
                      ? form.watch("pickupDate").format("DD MMM")
                      : ""
                  }
                />
                <hr css={styles.center.dates.divisor} />
                <HourSelect
                  css={styles.center.input.input}
                  id="pickupTime"
                  name="pickupTime"
                  onChange={({ value }) => {
                    form.setValue("pickupTime", value);
                  }}
                  styles={styles.center.select({
                    error: false,
                    fill: "outlined",
                    variant: "default",
                  })}
                  value={{
                    label: form.watch("pickupTime"),
                    value: form.watch("pickupTime"),
                  }}
                />
              </div>
              <div css={styles.center.input.root}>
                <CarFormDateInput
                  css={styles.center.input.input}
                  onChange={() => {
                    return;
                  }}
                  onClick={handleDateInputClick("dropoff")}
                  placeholder="Devolução"
                  value={
                    form.watch("dropoffDate")
                      ? form.watch("dropoffDate").format("DD MMM")
                      : ""
                  }
                />
                <hr css={styles.center.dates.divisor} />
                <HourSelect
                  css={styles.center.input.input}
                  id="dropoffTime"
                  name="dropoffTime"
                  onChange={({ value }) => {
                    form.setValue("dropoffTime", value);
                  }}
                  styles={styles.center.select({
                    error: false,
                    fill: "outlined",
                    variant: "default",
                  })}
                  value={{
                    label: form.watch("dropoffTime"),
                    value: form.watch("dropoffTime"),
                  }}
                />
              </div>
            </div>
            <InputErrorMessage>
              {form.errors["pickupDate"]?.message ||
                form.errors["dropoffDate"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.bottom.root}>
          <Button css={styles.bottom.button} variant="pink">
            Pesquisar carros
          </Button>
        </div>
      </Form>
      {focusedDateInput ? (
        <Popover
          anchorEl={datesContainerElement.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          css={styles.picker.popover}
          onClose={handleCloseDatePopover}
          open={isDatePopoverOpen}
        >
          <div css={styles.picker.picker}>
            <DayPickerRangeController
              endDate={form.watch("dropoffDate")}
              focusedInput={focusedDateInput}
              hideKeyboardShortcutsPanel
              isOutsideRange={(date) => date.diff(moment(), "days") < 0}
              minimumNights={0}
              numberOfMonths={2}
              onDatesChange={handleDateChange}
              onFocusChange={handleDateInputFocusChange}
              startDate={form.watch("pickupDate")}
            />
          </div>
        </Popover>
      ) : null}
    </>
  );
};
