import React from "react";

import { SidebarHelpButton } from "../../../help-button/help-button";
import { CarsMap, CarsMapSkeleton } from "../../cars-map/cars-map";
import { useCars } from "../../cars.context";
import { ContentFilters } from "../content-filters/content-filters";
import { SidebarAddressFilter } from "./sidebar-address-filter/sidebar-address-filter";
import { SidebarSummary } from "./sidebar-summary/sidebar-summary";
import { styles } from "./styles";

export const ContentSidebar: React.FC = () => {
  const {
    filters,
    handleClearAddressFilter,
    isMapVisible,
    isLoading,
    searchInfo,
  } = useCars();

  return (
    <div css={styles.root}>
      {filters.addressFilter ? (
        <SidebarAddressFilter
          addressFilter={filters.addressFilter}
          handleClearFilter={handleClearAddressFilter}
        />
      ) : null}
      <SidebarSummary />
      {isLoading ? (
        <CarsMapSkeleton />
      ) : !isMapVisible && searchInfo ? (
        <CarsMap />
      ) : null}
      <ContentFilters />
      <SidebarHelpButton />
    </div>
  );
};
