import React, { createContext, useEffect } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { CustomError } from "~/types";

import * as expensesHelper from "../../../helpers/expense.helper";
import { useSafeState } from "../../../hooks";
import { ExpenseReportWithUser } from "../../../models/expense.model";
import * as reportsListService from "./ReportsList.service";

interface FormData {
  description: string;
  reportType: string;
}

interface State {
  currentPage: number;
  isCreationDialogOpen: boolean;
  isLoading: boolean;
  isRemoveDialogOpen: boolean;
  isRemoving: boolean;
  isSubmiting: boolean;
  reports: ExpenseReportWithUser[];
  reportToRemove: ExpenseReportWithUser | null;
  searchFitler: string;
  statusFilter: string;
  totalPages: number;
}

interface Actions {
  createReport: (data: FormData) => void;
  fetchReports: () => void;
  handleChangeSearch: (searchText: string) => void;
  handleCloseReportCreationDialog: () => void;
  handleCloseReportRemovalDialog(): void;
  handleOpenReportCreationDialog: () => void;
  handleSelectReportToRemove(
    report: ExpenseReportWithUser,
  ): (e: React.MouseEvent<HTMLButtonElement>) => void;
  loadNextReports: () => void;
  onReportRemoval(): void;
}

type ContextProps = State & Actions;

const initialState: State = {
  currentPage: 1,
  isCreationDialogOpen: false,
  isLoading: false,
  isRemoveDialogOpen: false,
  isRemoving: false,
  isSubmiting: false,
  reports: [],
  reportToRemove: null,
  searchFitler: "",
  statusFilter: "all",
  totalPages: 1,
};

const ReportsListContext = createContext<ContextProps>({} as ContextProps);

export const ReportsListProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const fetchReports = async () => {
    setState({ isLoading: true });
    const { statusFilter, searchFitler } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(1, statusFilter, searchFitler),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      currentPage: 1,
      isLoading: false,
      reports: reportsWithUsers,
      totalPages: reportsData!.totalPages,
    });
  };

  const loadNextReports = async () => {
    setState({ isLoading: true });
    const { reports, currentPage, statusFilter, searchFitler } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(
        currentPage + 1,
        statusFilter,
        searchFitler,
      ),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      currentPage: currentPage + 1,
      isLoading: false,
      reports: reports.concat(reportsWithUsers),
      totalPages: reportsData!.totalPages,
    });
  };

  const handleChangeSearch = debounce(
    (searchText) => setState({ searchFitler: searchText }),
    300,
  );

  const handleOpenReportCreationDialog = () => {
    setState({
      isCreationDialogOpen: true,
    });
  };

  const handleCloseReportCreationDialog = () => {
    setState({
      isCreationDialogOpen: false,
    });
  };

  const createReport = async (data: FormData) => {
    setState({ isSubmiting: true });

    const {
      data: expenseReportToken,
      error: expenseReportError,
    } = await reportsListService.createReport(data);

    if (expenseReportError) {
      setState({ isSubmiting: false });

      showSnackMessage(expenseReportError.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isCreationDialogOpen: false,
      isSubmiting: false,
    });

    navigate(`/reports/${expenseReportToken}`);
  };

  const handleSelectReportToRemove = (report: ExpenseReportWithUser) => (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setState({ isRemoveDialogOpen: true, reportToRemove: report });
  };

  const handleCloseReportRemovalDialog = () => {
    if (!state.isRemoving) {
      setState({
        isRemoveDialogOpen: false,
        reportToRemove: null,
      });
    }
  };

  const onReportRemoval = async () => {
    setState({ isRemoving: true });
    const { reportToRemove } = state;

    const advanceResponse = await removeReportAdvancesIfNecessary(
      reportToRemove!,
    );

    if (advanceResponse.error) {
      setState({ isRemoving: false });

      showSnackMessage(advanceResponse.error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { error: reportError } = await reportsListService.removeExpenseReport(
      reportToRemove!.expenseReportToken,
    );

    if (reportError) {
      setState({ isRemoving: false });

      showSnackMessage(reportError.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isRemoveDialogOpen: false,
      isRemoving: false,
      reports: state.reports.filter(
        (r) => r.expenseReportToken !== reportToRemove?.expenseReportToken,
      ),
      reportToRemove: null,
    });

    showSnackMessage("Relatório excluído com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const removeReportAdvancesIfNecessary = async (
    report: ExpenseReportWithUser,
  ) => {
    const { advancedExpenses, expenseReportToken } = report;

    const result: { error?: CustomError } = {};

    if (advancedExpenses && advancedExpenses.length) {
      const tokensToRemove = advancedExpenses.map(
        (advance) => advance.expenseAdvanceToken,
      );

      const {
        error: advanceError,
      } = await reportsListService.removeAdvancesFromExpenseReport(
        expenseReportToken,
        tokensToRemove,
      );

      result.error = advanceError;
    }

    return result;
  };

  useEffect(() => {
    void fetchReports();
  }, [state.searchFitler]);

  return (
    <ReportsListContext.Provider
      value={{
        ...state,
        createReport,
        fetchReports,
        handleChangeSearch,
        handleCloseReportCreationDialog,
        handleCloseReportRemovalDialog,
        handleOpenReportCreationDialog,
        handleSelectReportToRemove,
        loadNextReports,
        onReportRemoval,
      }}
    >
      {children}
    </ReportsListContext.Provider>
  );
};

export { ReportsListContext };
