import React from "react";

import { RangeFilter } from "~/apps/shared/components/range-filter/range-filter";
import { toKilometers } from "~/apps/shared/utils/to-kilometers";

import { styles } from "./styles";

type Props = {
  disabled: boolean;
  handleChangeDistanceRange: (distanceRange: [number, number]) => void;
  distanceRange: [number, number];
  selectedRange: [number, number];
};

export const DistanceFilter: React.FC<Props> = ({
  disabled,
  handleChangeDistanceRange,
  distanceRange: [min, max],
  selectedRange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Distância</span>
      <RangeFilter
        defaultValue={selectedRange}
        disabled={disabled}
        formatFunc={toKilometers}
        max={max}
        min={min}
        onRangeDragEnd={handleChangeDistanceRange}
      />
    </div>
  );
};
