import moment from "moment";
import forge from "node-forge";
import qs from "querystring"; // from native node

import { CurrencyCode, CurrencySymbol } from "~/constants/enums";

import { CABINS } from "../constants";

export function getAllowedClasses(cabinClass) {
  let result;
  if (cabinClass === CABINS.first) {
    result = [
      CABINS.economy,
      CABINS.premiumEconomy,
      CABINS.business,
      CABINS.first
    ];
  } else if (cabinClass === CABINS.business) {
    result = [CABINS.economy, CABINS.premiumEconomy, CABINS.business];
  } else if (cabinClass === CABINS.premiumEconomy) {
    result = [CABINS.economy, CABINS.premiumEconomy];
  } else {
    result = [CABINS.economy];
  }

  return result;
}

export function snakeToCamel(value) {
  return value.replace(/_\w/g, m => m[1].toUpperCase());
}

export function deepClone(obj, mapKeyFunc = x => x) {
  let temp;
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) {
    return obj;
  }

  if (obj instanceof Date) {
    temp = new obj.constructor();
  }
  //or new Date(obj);
  else {
    temp = obj.constructor();
  }

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj.isActiveClone = null;
      temp[mapKeyFunc(key)] = deepClone(obj[key], mapKeyFunc);
      delete obj.isActiveClone;
    }
  }
  return temp;
}

export function deepCloneMapToCamel(obj) {
  return deepClone(obj, snakeToCamel);
}

export const toCurrency = (
  value,
  decimals = 2,
  currencyCode = CurrencyCode.BRL
) => {
  if (typeof value === "undefined" || value === null) {
    return "-";
  }
  return (
    `${CurrencySymbol[currencyCode]} ` +
    value.toLocaleString("pt-BR", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })
  );
};

export const formatCurrency = (value, currency, language = "pt-BR") => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currency
  });

  return formatter.format(value);
};

export const toBrazilianFormat = (value, decimals = 2) => {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

export function parseCurrencyStringToNumber(currencyValue) {
  const replacedCurrency = currencyValue
    .replace(/[R\$|.|\u2000| ]/g, "")
    .replace(/,/g, ".");

  return parseFloat(replacedCurrency);
}

export const toKilometers = value => {
  return (
    value.toLocaleString("pt-BR", {
      maximumFractionDigits: 2
    }) + " Km"
  );
};

export const differenceInDays = (startDate, endDate) => {
  if (endDate.diff) {
    return endDate.diff(startDate, "days");
  }

  return moment(endDate).diff(moment(startDate), "days");
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAllFirstLetters(str) {
  return str
    .split(" ")
    .map(w => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
}

export const getNameFirstLetters = name => {
  const parts = name.match(/\b(\w)/g);
  const length = parts.length;

  return []
    .concat(parts[0])
    .concat(parts[length - 1])
    .map(capitalizeFirstLetter)
    .join("");
};

export function stringToHslColor(str, s = 70, l = 60) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    /* tslint:disable:no-bitwise */
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    /* tslint:enable:no-bitwise */
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}

export function removeSpecialCharacters(str) {
  return str.replace(/[`\-~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, "");
}

export function removeNonDigits(str) {
  return str?.replace(/\D/g, "");
}

export function removeBlankSpaces(str) {
  return str.replace(/(\u2000| )/gi, "");
}

export function formatFlightDuration(rawDuration) {
  if (!rawDuration) {
    return "";
  }

  const [rawHours, rawMinutes] = rawDuration.split(":");
  const hours = rawHours && rawMinutes ? rawHours : 0;
  const minutes = rawMinutes ? rawMinutes : rawHours;

  const formattedHours = hours > 0 ? `${hours.replace(/^0+/, "")}h` : "";

  const formattedMinutes = minutes > 0 ? `${minutes.replace(/^0+/, "")}m` : "";

  return formattedHours + " " + formattedMinutes;
}

export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function encryptData(dataToEncrypt, encryptionToken, publicKey) {
  // Gere um query string a partir do objeto do cartão
  const queryString = qs.stringify(dataToEncrypt);
  const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
  const buffer = forge.util.createBuffer(queryString, "utf8");
  const bytes = buffer.getBytes();

  const encrypted = forgedPublicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");
  const b64Encoded = forge.util.encode64(encrypted);

  return b64Encoded;
}

export function encryptPassword(password, encryptionToken, publicKey) {
  const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
  const buffer = forge.util.createBuffer(password, "utf8");
  const bytes = buffer.getBytes();

  const encrypted = forgedPublicKey.encrypt(bytes, "RSA-OAEP");
  const b64Encoded = forge.util.encode64(encrypted);

  const passwordData = encryptionToken + "_" + b64Encoded;

  return passwordData;
}

export function isInRange(value, range) {
  return value >= range[0] && value <= range[1];
}

export function secondsToHHMM(secs) {
  var hours = Math.floor(secs / 3600);
  var minutes = Math.floor((secs - hours * 3600) / 60);
  // var seconds = secs - (hours * 3600) - (minutes * 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  // if (seconds < 10) {seconds = "0"+seconds;}
  return hours + ":" + minutes;
}

export function HHMMToSeconds(hhmmStr) {
  const [hours, minutes] = hhmmStr.split(":");
  // const numbers = Array.prototype.map.call(numbersStr, item => parseInt(item, 10));
  const result = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60;
  return result;
}

export function isNumber(value) {
  return (
    (!!value || value === 0) &&
    value !== null &&
    typeof value === "number" &&
    !Number.isNaN(value)
  );
}

export function toPercentage(portion, total) {
  if (!total) {
    return "";
  }

  const result = ((portion / total) * 100).toFixed(2) + "%";
  return result;
}

export function isEmptyNullOrUndefined(value) {
  return value === "" || typeof value === "undefined" || value === null;
}

export function scrollToElementWithGivenId(elementId) {
  const el = document.getElementById(elementId);

  if (el) {
    el.scrollIntoView();
  }
}

export function justHourFromHourString(hourText) {
  const result = Number(hourText.split(":")[0]);
  return result;
}

export function buildUrlWithParamters(url, params) {
  return url.concat("?", qs.stringify(params));
}

export function combineWithoutRepetitions(comboOptions, comboLength) {
  // If the length of the combination is 1 then each element of the original array
  // is a combination itself.
  if (comboLength === 1) {
    return comboOptions.map(comboOption => [comboOption]);
  }

  // Init combinations array.
  const combos = [];

  // Extract characters one by one and concatenate them to combinations of smaller lengths.
  // We need to extract them because we don't want to have repetitions after concatenation.
  comboOptions.forEach((currentOption, optionIndex) => {
    // Generate combinations of smaller size.
    const smallerCombos = combineWithoutRepetitions(
      comboOptions.slice(optionIndex + 1),
      comboLength - 1
    );

    // Concatenate currentOption with all combinations of smaller size.
    smallerCombos.forEach(smallerCombo => {
      combos.push([currentOption].concat(smallerCombo));
    });
  });

  return combos;
}

export function getAllCombinations(options) {
  const result = options
    .map((_, i) => i)
    .reduce((acc, index) => {
      let combinations = combineWithoutRepetitions(options, index + 1);
      acc = acc.concat(combinations);
      return acc;
    }, []);
  return result;
}

export function sortByField(field) {
  return function sort(a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  };
}

export function formatCompleteDateName(date) {
  return moment(date).format("ddd, DD MMM YYYY");
}

export function forceFileDownload(url, fileName = "") {
  const linkEl = document.createElement("a");
  linkEl.href = url;
  linkEl.setAttribute("download", fileName);
  document.body.append(linkEl);
  linkEl.click();
  linkEl.remove();
}

export function formatPhone(value) {
  if (!value) {
    return null;
  }
  // regex to remove all the spaces, parenthesis and hifen
  const formattedPhone = value.replace(/[\s|(|)|-]/g, "");

  return formattedPhone;
}

export function addBrazilPhonePrefix(str) {
  if (!str) {
    return null;
  }

  return !str.startsWith("+55") ? "+55" + str : str;
}

export function formatPrefixPhone(str) {
  if (!str) {
    return "";
  }
  if (str.startsWith("+55")) {
    return str.slice(3);
  }

  return str;
}

export function formatDisplayedPhone(str) {
  let ddd, phoneNumber;

  if (!str) {
    return "";
  }

  if (str.startsWith("+55")) {
    ddd = "(" + str.slice(3, 5) + ")";
    phoneNumber = str.slice(5, 10) + "-" + str.slice(10);
  } else {
    ddd = "(" + str.slice(0, 2) + ")";
    phoneNumber = str.slice(3, 7) + "-" + str.slice(7);
  }
  return [ddd, phoneNumber].join(" ");
}

export function getPercentageDiff(a, b) {
  return ((a - b) / a) * 100;
}

export function stringWithCapitalLetters(str) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getParamsFromLocation(location) {
  const innerLocation = location || window.location;

  const search = innerLocation.search
    ? innerLocation.search.replace(/^\?/g, "")
    : "";

  const params = qs.parse(search);
  return deepCloneMapToCamel(params);
}

export function compose(...fns) {
  return (...args) => fns.forEach(fn => fn && fn(...args));
}

export function removeAccentFromWord(word) {
  return new String(word).normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export function stringIncludes(stringA, stringB) {
  return removeAccentFromWord(stringA)
    .toLocaleLowerCase()
    .includes(removeAccentFromWord(stringB).toLocaleLowerCase());
}

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

export function isUrl(string) {
  if (typeof string !== "string") {
    return false;
  }

  var match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  var everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

export default {
  getAllCombinations,
  combineWithoutRepetitions,
  justHourFromHourString,
  deepClone,
  deepCloneMapToCamel,
  snakeToCamel,
  toCurrency,
  toKilometers,
  shallowEqual,
  differenceInDays,
  getNameFirstLetters,
  stringToHslColor,
  removeSpecialCharacters,
  removeBlankSpaces,
  formatFlightDuration,
  isObjectEmpty,
  encryptData,
  isInRange,
  secondsToHHMM,
  HHMMToSeconds,
  isNumber,
  formatCompleteDateName,
  formatPhone,
  addBrazilPhonePrefix,
  formatPrefixPhone,
  formatDisplayedPhone,
  stringWithCapitalLetters
};
