import React from "react";
import { connect } from "react-redux";
import { UserModel } from "@models/user.model";
import { Redirect } from "@reach/router";

interface ReduxState {
  user: UserModel;
}

export interface PermissionRouteProps {
  component: React.ComponentType;
  userPermissions: Record<string, any> | undefined;
  permission: string;
  redirectTo?: string;
  [key: string]: any;
}

const getPermissionInfo = (permission: string) => {
  const [scope, type] = permission.split(".");
  return [scope, type];
};

const PermissionRoute: React.FC<PermissionRouteProps> = ({
  component: Component,
  permission,
  userPermissions,
  redirectTo,
  ...rest
}) => {
  const [scope, type] = getPermissionInfo(permission);

  if (hasPermission(userPermissions, scope, type)) {
    return <Component {...rest} />;
  }

  if (!userPermissions && !hasPermission(userPermissions, scope, type)) {
    return <Redirect to={"/login"} noThrow={true} />;
  }

  return (
    <Redirect to={redirectTo ? redirectTo : "/permission"} noThrow={true} />
  );
};

const hasPermission = (
  userPermissions: Record<string, any> | undefined,
  scope: string,
  type: string
) => {
  if (!userPermissions) {
    return false;
  }

  if (
    typeof userPermissions![scope] !== "boolean" &&
    typeof userPermissions![scope][type] !== "boolean"
  ) {
    // throw new Error(`A permissão ${scope + "." + type} não existe.`);
    return false;
  }

  return !type
    ? userPermissions![scope] === true
    : userPermissions![scope][type] === true;
};

export default connect((state: ReduxState) => ({
  userPermissions: state.user.permissions
}))(PermissionRoute);
