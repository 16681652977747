import * as itineraryHelper from "~/apps/corporate/helpers/itinerary.helper";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesPresenter } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";

import { TravelPlanServicesCardTab } from "./travel-plan-services-card";

class TravelPlanServicesCardPresenter {
  constructor(private readonly servicesPresenter: ItineraryServicesPresenter) {}

  private getCanceledTabServices(services: ItineraryServicePresenter[]) {
    return services.filter((s) => s.belongsToCanceledTab());
  }

  private getFrustratedPurchasesTabServices(
    services: ItineraryServicePresenter[],
  ) {
    return services.filter((s) => s.belongsToFrustratedPurchasesTab());
  }

  public getPresentationalServices() {
    const services = this.servicesPresenter.getAllServices();

    const canceledServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.getCanceledTabServices(services),
    );

    const frustratedPurchasesServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.getFrustratedPurchasesTabServices(services),
    );

    const travelPlanServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.servicesPresenter.splitServicesByDateAndSetSuggestions(
        this.getTravelPlanTabServices(services),
      ),
    );

    return {
      cancelamentos: canceledServices,
      "plano-de-viagem": {
        ...travelPlanServices,
        FRUSTRATED_PURCHASES: Object.values(frustratedPurchasesServices).reduce(
          (prev, curr) => {
            return [...prev, ...curr];
          },
          [],
        ),
      },
    };
  }

  public getPresentationalServicesCounts() {
    const presentationalServices = this.getPresentationalServices();

    return Object.entries(presentationalServices).reduce((prev, [k, v]) => {
      prev[k as TravelPlanServicesCardTab] = Object.values(v).reduce(
        (prev, curr) =>
          prev + curr.reduce((prev, curr) => prev + curr.services.length, 0),
        0,
      );

      return prev;
    }, {} as Record<TravelPlanServicesCardTab, number>);
  }

  private getTravelPlanTabServices(services: ItineraryServicePresenter[]) {
    return services.filter((s) => s.belongsToTravelPlanTab());
  }

  private hasCanceledServices() {
    return this.getPresentationalServicesCounts()["cancelamentos"] > 0;
  }

  private hasTravelPlanServices() {
    return this.getPresentationalServicesCounts()["plano-de-viagem"] > 0;
  }

  public shouldUseCanceledTabAsDefault() {
    return this.hasCanceledServices() && !this.hasTravelPlanServices();
  }
}

export class TravelPlanServicesCardPresenterFactory {
  public static create(servicesPresenter: ItineraryServicesPresenter) {
    return new TravelPlanServicesCardPresenter(servicesPresenter);
  }
}
