import { ControlProps, GroupBase, OptionProps } from "react-select";
import { StylesConfig } from "react-windowed-select";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const getColors = ({
  error,
  fill,
  variant,
}: {
  error?: boolean;
  fill: "filled" | "outlined";
  variant: "blue" | "default" | "green" | "pink";
}) => {
  if (fill === "outlined") {
    switch (variant) {
      default:
        return {
          control: ({
            state,
          }: {
            state: ControlProps<unknown, boolean, GroupBase<unknown>>;
          }) => ({
            backgroundColor: theme.colors.white,
            borderColor: error
              ? theme.colors.pink[500]
              : state.menuIsOpen
              ? theme.colors.gray[300]
              : theme.colors.gray[100],
            color: error ? theme.colors.pink[500] : theme.colors.gray[500],
            ["&:hover"]: {
              color: error ? theme.colors.pink[700] : theme.colors.gray[500],
            },
          }),
          dropdownIndicator: {
            color: error ? theme.colors.pink[500] : theme.colors.gray[100],
            ["&:hover"]: {
              color: error ? theme.colors.pink[500] : theme.colors.gray[100],
            },
          },
          option: ({
            state,
          }: {
            state: OptionProps<unknown, boolean, GroupBase<unknown>>;
          }) => ({
            backgroundColor: state.isSelected
              ? theme.colors.background.gray
              : "transparent",
            color: state.isSelected
              ? theme.colors.gray[700]
              : theme.colors.gray[300],
            ["&:hover"]: {
              backgroundColor: theme.colors.background.gray,
              color: theme.colors.gray[700],
            },
          }),
          placeholder: {
            color: theme.colors.gray[300],
          },
          singleValue: {
            color: "inherit",
          },
        };
    }
  }

  switch (variant) {
    default:
      return {
        control: ({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state,
        }: {
          state: ControlProps<unknown, boolean, GroupBase<unknown>>;
        }) => ({
          backgroundColor: error
            ? theme.colors.pink[500]
            : theme.colors.gray[500],
          borderColor: error ? theme.colors.pink[500] : theme.colors.gray[500],
          color: theme.colors.white,
          ["&:hover"]: {
            borderColor: error
              ? theme.colors.pink[700]
              : theme.colors.gray[500],
            color: theme.colors.white,
          },
        }),
        dropdownIndicator: {
          color: theme.colors.white,
          ["&:hover"]: {
            color: theme.colors.white,
          },
        },
        option: ({
          state,
        }: {
          state: OptionProps<unknown, boolean, GroupBase<unknown>>;
        }) => ({
          backgroundColor: state.isSelected
            ? theme.colors.background.gray
            : "transparent",
          color: state.isSelected
            ? theme.colors.gray[700]
            : theme.colors.gray[300],
          ["&:hover"]: {
            backgroundColor: theme.colors.background.gray,
            color: theme.colors.gray[700],
          },
        }),
        placeholder: {
          color: theme.colors.white,
        },
        singleValue: {
          color: "inherit",
        },
      };
  }
};

export const styles = ({
  error,
  fill,
  variant,
}: {
  error?: boolean;
  fill: "filled" | "outlined";
  variant: "blue" | "default" | "green" | "pink";
}) => {
  return <StylesConfig>{
    control: (styles, state) => ({
      ...styles,
      alignItems: "center",
      borderRadius: "8px",
      boxShadow: "none",
      display: "flex",
      flexWrap: "nowrap",
      gap: "8px",
      minHeight: "48px",
      padding: "0 16px",
      transition: "all 0.15s ease-in-out",
      ["&:not(:disabled)"]: {
        cursor: "pointer",
      },
      ...getColors({ error, fill, variant }).control({ state }),
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "0",
      ...getColors({ error, fill, variant }).dropdownIndicator,
    }),
    group: (styles) => ({
      ...styles,
      borderBottom: `1px solid ${theme.colors.gray[100]}`,
      padding: "0",
      ["&:last-of-type"]: {
        borderBottom: "unset",
      },
    }),
    groupHeading: (styles) => ({
      ...styles,
      color: theme.colors.gray[700],
      fontFamily: theme.fonts.inter,
      fontSize: "0.625rem",
      fontWeight: 700,
      padding: "16px 16px 8px 16px",
      margin: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    input: (styles) => ({
      ...styles,
      color: theme.colors.gray[500],
      fontFamily: theme.fonts.inter,
      overflow: "hidden",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1301,
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      overflow: "hidden",
      padding: "12px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ...getColors({ error, fill, variant }).placeholder,
    }),
    option: (styles, state) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      padding: "12px 16px",
      ["&:disabled"]: {
        opacity: 0.5,
        pointerEvents: "none",
      },
      ["&:first-of-type"]: {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
      ["&:last-of-type"]: {
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      },
      ["&:not(:disabled)"]: {
        cursor: "pointer",
      },
      ...getColors({ error, fill, variant }).option({ state }),
    }),
    placeholder: (styles) => ({
      ...styles,
      alignItems: "center",
      display: "flex",
      fontFamily: theme.fonts.inter,
      ...getColors({ error, fill, variant }).placeholder,
    }),
    singleValue: (styles) => ({
      ...styles,
      alignItems: "center",
      display: "flex",
      fontFamily: theme.fonts.inter,
      padding: "8px 0",
      whiteSpace: "wrap",
      ...getColors({ error, fill, variant }).singleValue,
    }),
    valueContainer: () => ({
      display: "flex",
      height: "fit-content",
      width: "fit-content",
    }),
  };
};

styles.root = css({
  width: "inherit",
});
