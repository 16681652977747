import React from "react";

import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";

import { Button } from "@toolkit/v2";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItineraryContainer } from "../../../../itinerary.container";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";
import { styles } from "./styles";
import {
  travelerPhoneAccordionSchema,
  TravelerPhoneAccordionSchema,
} from "./traveler-phone-accordion.schema";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const TravelerPhoneAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const { infoModel, pendenciesModel } = useItineraryContainer();

  const { pendencies, saveTravelerPhoneAndProceed } = useItineraryPendencies();

  const form = useForm<TravelerPhoneAccordionSchema>({
    defaultValues: {
      phone: "",
    } as TravelerPhoneAccordionSchema,
    onSubmit: async () => {
      if (!infoModel) {
        return;
      }

      const values = form.getValues();

      const success = await saveTravelerPhoneAndProceed({
        phone: values.phone,
        travelerToken: infoModel.getTravelerToken(),
      });

      if (!success) {
        return;
      }

      next();
    },
    schema: travelerPhoneAccordionSchema,
  });

  if (!pendencies || !pendenciesModel) {
    return null;
  }

  const isResolved = pendencies.isTravelerPhoneResolved;

  return (
    <PendenciesAccordion
      description="Informe o celular do viajante para podermos contactá-lo em casos emergenciais."
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={`${index}. Celular.`}
      toggleExpanded={toggleExpanded}
    >
      <Form context={form} css={styles.form.root}>
        <div css={styles.form.input.root}>
          <span css={styles.form.input.label}>
            Celular
            {pendenciesModel.isTravelerPhoneRequired() ? (
              "*"
            ) : (
              <span css={styles.optional}> (opcional)</span>
            )}
          </span>
          <Form.Field
            name="phone"
            render={({ onChange, value }) => (
              <InputMasked
                css={styles.form.input.input}
                maskType="cellphone"
                onChange={onChange}
                placeholder="Digite o número do celular..."
                value={value}
              />
            )}
          />
          <InputErrorMessage>{form.errors["phone"]?.message}</InputErrorMessage>
        </div>
        <Button
          css={styles.button}
          disabled={form.formState.isSubmitting || isLoadingNext}
        >
          Salvar
        </Button>
      </Form>
    </PendenciesAccordion>
  );
};
