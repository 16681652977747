import React, { useEffect } from "react";

import ProfileIcon from "@material-ui/icons/AccountCircle";
import { css } from "@styled-system/css";

import { Box, Flex } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { formatCurrency } from "~/helpers";

import Layout from "@shared/Layout";

import SpinnerPortal from "../shared/Spinner";
import { AdvancedExpenseStatus } from "./AdvancedExpenseStatus";
import {
  ExpenseAdvanceApprovalReviewProvider,
  useExpenseAdvanceApprovalReviewContext,
  useExpenseAdvanceApprovalReviewActionsContext
} from "./ExpenseAdvanceApprovalReview.context";
import { SideCard } from "./SideCard";
import { PageContainer, Title, AdvanceInfo, BlockText } from "./styles";
import { SubNavbar } from "./Subnavbar";

interface Props {
  expenseAdvanceToken: string;
}

const styles = {
  profileIcon: css({
    width: 45,
    height: 45,
    color: "secondary"
  })
};

const ExpenseAdvanceApprovalReview = ({ expenseAdvanceToken }: Props) => {
  const {
    isLoading,
    traveler,
    expenseAdvance
  } = useExpenseAdvanceApprovalReviewContext();
  const { fetchData } = useExpenseAdvanceApprovalReviewActionsContext();

  useEffect(() => {
    fetchData(expenseAdvanceToken);
  }, [expenseAdvanceToken]);

  return (
    <Layout>
      <SpinnerPortal visible={isLoading} />
      <SubNavbar
        pageName="Adiantamento de despesas"
        token={expenseAdvance ? expenseAdvance.expenseAdvanceToken : ""}
        name={expenseAdvance ? expenseAdvance.name : ""}
      />
      <PageContainer>
        <Title>Aprovação de adiantamento</Title>
        <Flex
          pt={[24, 40]}
          pb={[80, 40]}
          flexDirection={["column-reverse", "row"]}
          justifyContent={["initial", "space-between"]}
        >
          {!!expenseAdvance && (
            <>
              <AdvanceInfo>
                <Box textAlign="center" mb={["large", "xlarge"]}>
                  <BlockText fontSize={[0, 1]} fontWeight="bold" mb="small">
                    Período da viagem
                  </BlockText>
                  <BlockText fontSize={[0, 2]} mb={"large"}>
                    {expenseAdvance.travelStart.format("ddd, DD MMM YYYY")} -{" "}
                    {expenseAdvance.travelEnd.format("ddd, DD MMM YYYY")}
                  </BlockText>

                  <BlockText fontSize={[20, 28]} fontWeight="bold">
                    {expenseAdvance.name}
                  </BlockText>
                </Box>

                <Flex alignItems="center" mb={[24, "xlarge"]}>
                  <Box mr="medium">
                    <ProfileIcon css={styles.profileIcon} />
                  </Box>
                  <Box lineHeight="20px">
                    <BlockText>Viajante</BlockText>
                    <BlockText fontWeight="bold">
                      {traveler!.fullName}
                    </BlockText>
                  </Box>
                </Flex>

                <Flex
                  minHeight={90}
                  border="1px solid"
                  borderColor="border"
                  borderRadius={1}
                  flexDirection={["column", "row"]}
                >
                  <Box
                    px="medium"
                    height="auto"
                    width={["100%", "75%"]}
                    borderRight={["none", "1px solid"]}
                    borderBottom={["1px solid", "none"]}
                    borderColor={["border", "border"]}
                    borderRadius={0}
                  >
                    <Box py="medium">
                      <BlockText
                        fontSize={[1, 2]}
                        fontWeight="bold"
                        mb={[12, "medium"]}
                      >
                        Justificativa
                      </BlockText>
                      <BlockText
                        fontSize={1}
                        lineHeight={1.3}
                        color={defaultTheme.subTextColor}
                      >
                        {expenseAdvance.justification}
                      </BlockText>
                    </Box>
                  </Box>
                  <Box
                    flex={1}
                    p={["small", "medium"]}
                    textAlign="center"
                    display={["flex", "block"]}
                    justifyContent={["center", "initial"]}
                  >
                    <BlockText
                      fontSize={[1, 2]}
                      fontWeight="bold"
                      mb={[0, "medium"]}
                      mr={["small", 0]}
                    >
                      Valor
                    </BlockText>
                    <BlockText fontSize={[1, 18]} color="success">
                      {formatCurrency(
                        expenseAdvance.requestedValue,
                        expenseAdvance.currency
                      )}
                    </BlockText>
                  </Box>
                </Flex>
              </AdvanceInfo>
              <Box width="100%" maxWidth={["auto", 431.58]}>
                <SideCard />
                <AdvancedExpenseStatus />
              </Box>
            </>
          )}
        </Flex>
      </PageContainer>
    </Layout>
  );
};

const ContextContainer = (props: Props) => (
  <ExpenseAdvanceApprovalReviewProvider>
    <ExpenseAdvanceApprovalReview {...props} />
  </ExpenseAdvanceApprovalReviewProvider>
);

export { ContextContainer as ExpenseAdvanceApprovalReview };
