import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.pink[500],
    cursor: "pointer",
    transition: "color 0.15s ease-in-out",
    ":disabled": {
      cursor: "default",
      opacity: 0.75,
    },
    ":hover": {
      color: theme.colors.pink[700],
    },
  }),
};
