import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  root: () =>
    StyleSheet.create({
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
    }),
};
