import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import {
  TravelApprovalStatus,
  TravelApprovalStatusModelFactory,
  TravelApprovalStatusModel,
} from "~/apps/corporate/models/approval-status.model";
import {
  TravelApprovalHistory,
  TravelApprovalHistoryModelFactory,
  TravelApprovalHistoryModel,
} from "~/apps/corporate/models/approvals.model";
import {
  TravelApproval,
  TravelApprovalModelFactory,
  TravelApprovalModel,
} from "~/apps/corporate/models/travel.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { useItineraryInfo } from "./itinerary-info.context";
import { useItinerary } from "./itinerary.context";
import * as itineraryService from "./itinerary.service";

interface Actions {
  cancelApprovalProcess: (approvalRequestToken: string) => Promise<void>;
  createApprovalRequest: (justification: string) => Promise<boolean>;
  fetchItineraryApprovalHistory: () => Promise<TravelApprovalHistoryModel | null>;
  fetchItineraryApprovalStatus: () => Promise<TravelApprovalStatusModel | null>;
  fetchTravelApproval: () => Promise<TravelApprovalModel | null>;
}

type State = {
  errorOnFetch: Error | null;
  errorOnFetchApprovalHistory: Error | null;
  errorOnFetchApprovalStatus: Error | null;
  isLoading: boolean;
  isLoadingApprovalHistory: boolean;
  isLoadingApprovalStatus: boolean;
  isLoadingCancelOwnApprovalProcess: boolean;
  isLoadingCreateApprovalRequest: boolean;
  travelApproval: TravelApproval | null;
  travelApprovalHistory: TravelApprovalHistory | null;
  travelApprovalStatus: TravelApprovalStatus | null;
};

const initialState: State = {
  errorOnFetch: null,
  errorOnFetchApprovalHistory: null,
  errorOnFetchApprovalStatus: null,
  isLoading: false,
  isLoadingApprovalHistory: false,
  isLoadingApprovalStatus: false,
  isLoadingCancelOwnApprovalProcess: false,
  isLoadingCreateApprovalRequest: false,
  travelApproval: null,
  travelApprovalHistory: null,
  travelApprovalStatus: null,
};

const ItineraryApprovalContext = createContext<Actions & State>({
  ...initialState,
  cancelApprovalProcess: async () => {
    return;
  },
  createApprovalRequest: async () => {
    return false;
  },
  fetchItineraryApprovalHistory: async () => {
    return null;
  },
  fetchItineraryApprovalStatus: async () => {
    return null;
  },
  fetchTravelApproval: async () => {
    return null;
  },
});

export const ItineraryApprovalProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const { travelToken } = useItinerary();
  const { fetchItineraryInfo } = useItineraryInfo();

  const [state, setState] = useState(initialState);

  const createApprovalRequest = useCallback(
    async (justification: string) => {
      setState((prev) => ({
        ...prev,
        isLoadingCreateApprovalRequest: true,
      }));

      const { error } = await itineraryService.createApprovalRequest(
        justification,
        travelToken,
      );

      if (error) {
        setState((prev) => ({
          ...prev,
          isLoadingCreateApprovalRequest: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingCreateApprovalRequest: false,
      }));

      return true;
    },
    [showSnackMessage, travelToken],
  );

  const fetchItineraryApprovalHistory = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingApprovalHistory: true,
    }));

    const travelApprovalHistoryResponse = await itineraryService.getTravelApprovalHistory(
      travelToken,
    );

    if (travelApprovalHistoryResponse.isFailure()) {
      const error = travelApprovalHistoryResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetchApprovalHistory: error,
        isLoadingApprovalHistory: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    setState((prev) => ({
      ...prev,
      isLoadingApprovalHistory: false,
      travelApprovalHistory: travelApprovalHistoryResponse.data,
    }));

    return TravelApprovalHistoryModelFactory.create(
      travelApprovalHistoryResponse.data,
    );
  }, [showSnackMessage, travelToken]);

  const cancelApprovalProcess = useCallback(
    async (approvalRequestToken: string) => {
      setState((prev) => ({
        ...prev,
        isLoadingCancelOwnApprovalProcess: true,
      }));

      const { error } = await itineraryService.cancelApprovalProcess(
        approvalRequestToken,
      );

      if (error) {
        setState((prev) => ({
          ...prev,
          isLoadingCancelOwnApprovalProcess: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingCancelOwnApprovalProcess: false,
      }));

      await Promise.all([
        fetchItineraryApprovalHistory(),
        fetchItineraryInfo(),
      ]);
    },
    [fetchItineraryApprovalHistory, fetchItineraryInfo, showSnackMessage],
  );

  const fetchItineraryApprovalStatus = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingApprovalStatus: true,
    }));

    const travelApprovalStatus = await itineraryService.getTravelApprovalStatus(
      travelToken,
    );

    if (travelApprovalStatus.isFailure()) {
      const error = travelApprovalStatus.data;

      setState((prev) => ({
        ...prev,
        errorOnFetchApprovalStatus: error,
        isLoadingApprovalStatus: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    setState((prev) => ({
      ...prev,
      isLoadingApprovalStatus: false,
      travelApprovalStatus: travelApprovalStatus.data,
    }));

    const travelApprovalStatusModel = TravelApprovalStatusModelFactory.create(
      travelApprovalStatus.data,
    );

    return travelApprovalStatusModel;
  }, [showSnackMessage, travelToken]);

  const fetchTravelApproval = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const travelApproval = await itineraryService.getTravelApproval(
      travelToken,
    );

    if (travelApproval.error) {
      const error = travelApproval.error;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    if (!travelApproval.data) {
      setState((prev) => ({
        ...prev,
        errorOnFetch: ERROR.UNEXPECTED,
        isLoading: false,
      }));

      return null;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      travelApproval: travelApproval.data!,
    }));

    return TravelApprovalModelFactory.create(travelApproval.data);
  }, [showSnackMessage, travelToken]);

  return (
    <ItineraryApprovalContext.Provider
      value={{
        ...state,
        cancelApprovalProcess,
        createApprovalRequest,
        fetchItineraryApprovalHistory,
        fetchItineraryApprovalStatus,
        fetchTravelApproval,
      }}
    >
      {children}
    </ItineraryApprovalContext.Provider>
  );
};

export const useItineraryApproval = useContextFactory(
  "ItineraryApprovalContext",
  ItineraryApprovalContext,
);
