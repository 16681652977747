import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";
import { StarRatingDisplay } from "~/apps/shared/components/star-rating-display/star-rating-display";

import { styles } from "./styles";

type Props = {
  stars: {
    checked: boolean;
    value: number;
  }[];
  handleChangeStarsFilter: (event: any, checked: boolean) => void;
};

export const StarsFilter: React.FC<Props> = ({
  stars,
  handleChangeStarsFilter,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Estrelas</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {stars.map((star) => (
          <CheckboxGroup.Item
            aria-label={star.value ? `${star.value} estrelas` : "Outros"}
            checked={star.checked}
            css={styles.checkbox.group.item}
            id={star.value.toString()}
            key={star.value}
            onChange={(e) => {
              handleChangeStarsFilter(e, !star.checked);
            }}
            value={star.checked}
          >
            {star.value === 0 ? (
              <span css={styles.checkbox.label}>Outros</span>
            ) : (
              <StarRatingDisplay
                css={styles.checkbox.star}
                rating={star.value}
                size={16}
              />
            )}
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
