import React, { memo } from "react";

import { css } from "@styled-system/css";
import moment from "moment";

import { Flex, Box } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import {
  ADVANCED_EXPENSE_STATUS,
  ADVANCED_EXPENSE_STATUS_LABEL,
  ADVANCED_EXPENSE_STATUS_COLOR
} from "@constants";

import { StOutlinedButton } from "@shared/buttons";

import { AdvancedExpense } from "../AdvancedExpenses.types";
import * as utils from "../AdvancedExpenses.utils";

const styles = {
  listItem: css({
    cursor: "pointer",
    color: defaultTheme.subTextColor,
    fontSize: [12, 16],
    py: "1.5rem",
    px: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
    ["&:not(:last-child)"]: {
      borderBottom: "1px solid",
      borderColor: "border"
    }
  }),
  itemName: css({ fontWeight: 700 }),
  itemValue: css({
    textAlign: "right",
    fontWeight: 700,
    minWidth: 80
  }),
  itemDate: css({
    pb: ".5rem",
    fontSize: 15
  }),
  button: (canAddToReport: boolean) =>
    css({
      marginLeft: "1.5rem",
      padding: ".25rem 1rem",
      fontSize: "13px !important",
      fontWeight: "normal",
      lineHeight: "24.5px",
      borderColor: "border",
      cursor: `${canAddToReport ? "pointer" : "not-allowed"}`,
      color: `${canAddToReport ? "text" : "gray.2"}`,
      bg: `${canAddToReport ? "white" : "#F7F8FD"}`
    }),
  status: (status: AdvancedExpense["status"]) =>
    css({
      fontSize: 10,
      minWidth: "80px",
      textAlign: "center",
      fontWeight: "bold",
      p: "2px 13px",
      marginRight: "1rem",
      lineHeight: 1.5,
      borderRadius: "60px",
      backgroundColor: `${ADVANCED_EXPENSE_STATUS_COLOR[status]}`,
      color: "white"
    }),
  deleteIconBox: css({
    padding: "0.5rem",
    borderRadius: "50%",
    marginLeft: "0.5rem",
    ["&:hover"]: {
      bg: "gray.0"
    }
  }),
  deleteIcon: css({
    cursor: "pointer"
  }),
  disabledDeleteIcon: css({
    ml: "1rem",
    mr: "0.5rem",
    cursor: "not-allowed",
    color: "gray.1"
  })
};

interface Props {
  item: AdvancedExpense;
  handleOpenDetails: (expenseAdvanceToken: string) => () => void;
  addToReport: (
    expenseAdvanceToken: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}

const DesktopItem = memo(({ item, handleOpenDetails, addToReport }: Props) => {
  const handleStopPropagation = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const handleAddToReport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => addToReport(item.expenseAdvanceToken, e);

  const canAddToReport = item.status === ADVANCED_EXPENSE_STATUS.CLOSED;

  return (
    <Flex
      onClick={handleOpenDetails(item.expenseAdvanceToken)}
      css={styles.listItem}
    >
      <Flex flexDirection="column" flex={1}>
        <Box css={styles.itemDate}>
          {moment(item.createdAt).format("DD MMM YYYY")}
        </Box>
        <Box css={styles.itemName}>{item.name}</Box>
      </Flex>
      <Box css={styles.status(item.status)}>
        {ADVANCED_EXPENSE_STATUS_LABEL[item.status]}
      </Box>
      <Box css={styles.itemValue}>{utils.parseValue(item.requestedValue)}</Box>
      <StOutlinedButton
        css={styles.button(canAddToReport)}
        onClick={canAddToReport ? handleAddToReport : handleStopPropagation}
        disableRipple={!canAddToReport}
      >
        Adicionar a relatório
      </StOutlinedButton>
    </Flex>
  );
});

export { DesktopItem };
