import React, { createContext, useCallback, useContext } from "react";
import { useQuery } from "react-query";

import {
  CreateCompanyEventDto,
  CreateEventTravelDto,
  CreateEventUpdateDto,
  EditCompanyEventDto,
  EditEventParticipantDto,
  EditEventUpdateDto,
  GetUserCompanyEventDto,
  ListEventParticipantsDto,
  SearchEventTargetsDto,
} from "sm-types/sm-company-events";

import { ALERT_TYPES } from "~/constants";

import {
  EventParticipantType,
  ParticipationStatus,
} from "~/models/event.model";

import { useApplicationContext } from "~/Context";

import { EditEventForm } from "./edit-event";
import type { InviteEventParticipantsForm } from "./event/overview/participants/invite-dialog";
import { EditEventUpdateForm } from "./event/overview/updates";
import { CreateUpdateForm } from "./event/overview/updates/create-dialog";
import * as eventsService from "./events.service";
import type { NewEventForm } from "./new-event";

type Context = {
  changeEventParticipationStatus: (data: {
    status: ParticipationStatus;
    token: string;
  }) => Promise<{
    success: boolean;
  }>;
  createEventTravel: (data: {
    data: {
      travel_name?: string;
      traveler_token: string;
    };
    token: string;
  }) => Promise<CreateEventTravelDto | undefined>;
  createEventUpdate: (
    form: CreateUpdateForm,
  ) => Promise<CreateEventUpdateDto | undefined>;
  disinviteEventParticipants: (data: {
    token: string;
    participant_token: string;
  }) => Promise<{
    success: boolean;
  }>;
  editEvent: (
    form: EditEventForm,
  ) => Promise<Partial<EditCompanyEventDto> | undefined>;
  editEventParticipant: (data: {
    data: {
      participant_type: EventParticipantType;
    };
    eventToken: string;
    token: string;
  }) => Promise<EditEventParticipantDto | undefined>;
  editEventUpdate: (
    form: EditEventUpdateForm,
  ) => Promise<EditEventUpdateDto | undefined>;
  events: GetUserCompanyEventDto[];
  excludeEvent: (data: {
    token: string;
  }) => Promise<{
    success: boolean;
  }>;
  getEvent: (data: {
    token: string;
  }) => Promise<GetUserCompanyEventDto | undefined>;
  getEventParticipants: (data: {
    token: string;
  }) => Promise<ListEventParticipantsDto | undefined>;
  inviteEventParticipants: (
    form: InviteEventParticipantsForm,
  ) => Promise<{
    success: boolean;
  }>;
  isLoadingEvents: boolean;
  newEvent: (
    form: NewEventForm,
  ) => Promise<Partial<CreateCompanyEventDto> | undefined>;
  searchEventTargets: (data: {
    search?: string;
    token: string;
  }) => Promise<SearchEventTargetsDto | undefined>;
};

const EventsContext = createContext({} as Context);

export const EventsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplicationContext();

  const { data: events, isLoading: isLoadingEvents } = useQuery(
    ["events"],
    async () => {
      const result = await eventsService.listEvents();

      if (!result.data || result.error) {
        showSnackMessage(
          "Não foi possível carregar os eventos",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      return result.data;
    },
  );

  const changeEventParticipationStatus: Context["changeEventParticipationStatus"] = useCallback(
    async ({ status, token }) => {
      if (status === ParticipationStatus.ACCEPTED) {
        const result = await eventsService.acceptEventParticipation({ token });

        if (result.error) {
          showSnackMessage(
            "Não foi possível aceitar o convite",
            ALERT_TYPES.ERROR,
          );

          return {
            success: false,
          };
        }

        showSnackMessage(
          "Você confirmou a sua presença no evento.",
          ALERT_TYPES.SUCCESS,
        );

        return {
          success: true,
        };
      }

      if (status === ParticipationStatus.DECLINED) {
        const result = await eventsService.declineEventParticipation({ token });

        if (result.error) {
          showSnackMessage(
            "Não foi possível recusar o convite",
            ALERT_TYPES.ERROR,
          );

          return {
            success: false,
          };
        }

        showSnackMessage(
          "Você recusou a sua presença no evento.",
          ALERT_TYPES.SUCCESS,
        );

        return {
          success: true,
        };
      }

      return {
        success: false,
      };
    },
    [showSnackMessage],
  );

  const createEventTravel: Context["createEventTravel"] = useCallback(
    async ({ data, token }) => {
      const result = await eventsService.createEventTravel({ data, token });

      if (result.error) {
        showSnackMessage(
          "Não foi possível carregar a oferta",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      return result.data;
    },
    [showSnackMessage],
  );

  const createEventUpdate: Context["createEventUpdate"] = useCallback(
    async (form) => {
      const result = await eventsService.createEventUpdate({
        data: {
          on_top: form.update.on_top,
          text: form.update.text,
        },
        token: form.event.token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível criar a atualização",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      showSnackMessage("Atualização criada com sucesso", ALERT_TYPES.SUCCESS);

      return result.data;
    },
    [showSnackMessage],
  );

  const disinviteEventParticipants: Context["disinviteEventParticipants"] = useCallback(
    async ({ token, participant_token }) => {
      const result = await eventsService.disinviteEventParticipant({
        participant_token,
        token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível convidar o participante.",
          ALERT_TYPES.ERROR,
        );

        return {
          success: false,
        };
      }

      showSnackMessage(
        "Participante desconvidado com sucesso.",
        ALERT_TYPES.SUCCESS,
      );

      return {
        success: true,
      };
    },
    [showSnackMessage],
  );

  const editEvent: Context["editEvent"] = useCallback(
    async (form) => {
      const result = await eventsService.editEvent({
        data: {
          city: form.location.city,
          country: form.location.country,
          ...(form.description && { description: form.description }),
          end_date: form.endDate,
          name: form.name,
          privacy: form.privacy,
          start_date: form.startDate,
          state: form.location.state,
        },
        token: form.token,
      });

      if (result.error) {
        showSnackMessage("Não foi possível editar o evento", ALERT_TYPES.ERROR);

        return;
      }

      showSnackMessage("Evento editado com sucesso", ALERT_TYPES.SUCCESS);

      return result.data;
    },
    [showSnackMessage],
  );

  const editEventParticipant: Context["editEventParticipant"] = useCallback(
    async ({ data, eventToken, token }) => {
      const result = await eventsService.editEventParticipant({
        data: {
          participant_type: data.participant_type,
        },
        eventToken,
        token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível editar o participante",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      showSnackMessage("Participante editado com sucesso", ALERT_TYPES.SUCCESS);

      return result.data;
    },
    [showSnackMessage],
  );

  const editEventUpdate: Context["editEventUpdate"] = useCallback(
    async (form) => {
      const result = await eventsService.editEventUpdate({
        data: {
          on_top: form.update.on_top,
        },
        token: form.event.token,
        updateToken: form.update.token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível editar a atualização",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      showSnackMessage("Atualização editada com sucesso", ALERT_TYPES.SUCCESS);

      return result.data;
    },
    [showSnackMessage],
  );

  const excludeEvent: Context["excludeEvent"] = useCallback(
    async ({ token }) => {
      const result = await eventsService.excludeEvent({
        token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível excluir o evento",
          ALERT_TYPES.ERROR,
        );

        return { success: false };
      }

      showSnackMessage("Evento excluído com sucesso", ALERT_TYPES.SUCCESS);

      return {
        success: result.data?.success || false,
      };
    },
    [showSnackMessage],
  );

  const getEvent: Context["getEvent"] = useCallback(
    async ({ token }) => {
      const result = await eventsService.getEvent({ token });

      if (!result.data || result.error) {
        showSnackMessage(
          "Não foi possível carregar o evento",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      return result.data;
    },
    [showSnackMessage],
  );

  const getEventParticipants: Context["getEventParticipants"] = useCallback(
    async ({ token }) => {
      const result = await eventsService.listEventParticipants({ token });

      if (!result.data || result.error) {
        showSnackMessage(
          "Não foi possível carregar os participantes",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      return result.data;
    },
    [showSnackMessage],
  );

  const newEvent: Context["newEvent"] = useCallback(
    async (form) => {
      const result = await eventsService.createEvent({
        data: {
          city: form.location.city,
          country: form.location.country,
          end_date: form.endDate,
          name: form.name,
          privacy: form.privacy,
          start_date: form.startDate,
          state: form.location.state,
        },
      });

      if (result.error) {
        showSnackMessage("Não foi possível criar o evento", ALERT_TYPES.ERROR);

        return;
      }

      showSnackMessage("Evento criado com sucesso", ALERT_TYPES.SUCCESS);

      return result.data;
    },
    [showSnackMessage],
  );

  const inviteEventParticipants: Context["inviteEventParticipants"] = useCallback(
    async ({
      message,
      invited_users_tokens,
      token,
    }: InviteEventParticipantsForm) => {
      const result = await eventsService.inviteEventParticipants({
        data: {
          invited_users_tokens: invited_users_tokens.map(
            (invitedUser) => invitedUser.token,
          ),
          ...(message && { message }),
        },
        token,
      });

      if (result.error) {
        showSnackMessage(
          "Não foi possível convidar o participante.",
          ALERT_TYPES.ERROR,
        );

        return {
          success: false,
        };
      }

      showSnackMessage(
        "Participante convidado com sucesso.",
        ALERT_TYPES.SUCCESS,
      );

      return {
        success: true,
      };
    },
    [showSnackMessage],
  );

  const searchEventTargets: Context["searchEventTargets"] = useCallback(
    async ({ search, token }) => {
      const result = await eventsService.searchEventTargets({ search, token });

      if (!result.data || result.error) {
        showSnackMessage(
          "Não foi possível carregar os participantes relacionados",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      return result.data;
    },
    [showSnackMessage],
  );

  return (
    <EventsContext.Provider
      value={{
        changeEventParticipationStatus,
        createEventTravel,
        createEventUpdate,
        editEvent,
        editEventParticipant,
        editEventUpdate,
        disinviteEventParticipants,
        events: events || [],
        excludeEvent,
        getEvent,
        getEventParticipants,
        inviteEventParticipants,
        isLoadingEvents,
        newEvent,
        searchEventTargets,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => useContext(EventsContext);
