import React, { useState, useEffect, useCallback, createContext } from "react";

import { getClientPermissions } from "~/apps/corporate/helpers/user.helper";
import * as tagsService from "~/apps/corporate/pages/configurations/views/company/tags/tags.service";
import { ALERT_TYPES, COPY_STATUS } from "~/apps/shared/constants";
import { TripsTabsFilter } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { TravelTag } from "../../models/tags.models";
import * as tripsService from "./trips.service";
import { ICompleteTrip } from "./trips.types";

const generateTravelsMap = (travels: any[]) => {
  return travels.reduce((acc, cur) => {
    acc[cur.token] = cur;

    return acc;
  }, {});
};

const getTravelsWithUsers = (travels: any[], users: any[]) => {
  const usersMap = users.reduce((acc, cur) => {
    acc[cur.userToken] = cur.fullName;

    return acc;
  }, {});

  return travels.map((travel) => ({
    ...travel,
    travelerName: usersMap[travel.travelerToken] || "",
  }));
};

const deletionInitialState = {
  isDeleting: false,
  isDeletionDialogVisible: false,
  selectedTravel: null,
};
const filtersInitialState = {
  pendingFilters: [],
  search: "",
  tabFilter: TripsTabsFilter.ALL,
  userToken: "",
};
const replicationInitialState = {
  copyTravels: [],
  isFormOpen: false,
  originalTravels: [],
  selectedTravel: null,
};
const smartPointsInitialState = {
  isExplanationVisible: false,
  pointsInfo: {
    notPaidPoints: 0,
    paidPoints: 0,
    rewardActive: false,
  },
};
const tagsInitialState = {
  isLoading: false,
  onlyAdminOption: null,
  requiredTravelTagOption: null,
  tags: {},
};
const travelsListInitialState = {
  currentPage: 1,
  isLoadingTravelsCount: false,
  loading: false,
  loadingError: null,
  loadingMore: false,
  totalPages: 1,
  travels: [],
  travelsCount: {
    all: 0,
    canceled: 0,
    draft: 0,
    now: 0,
    past: 0,
    upcoming: 0,
  },
  users: [],
};

type TravelsFiltersContextProps = {
  pendingFilters: string[];
  search: string;
  tabFilter: TripsTabsFilter;
  userToken: string;
};
type TravelsListContextProps = {
  currentPage: number;
  isLoadingTravelsCount: boolean;
  loading: boolean;
  loadingError: any | null;
  loadingMore: boolean;
  totalPages: number;
  travels: ICompleteTrip[];
  travelsCount: {
    all: number;
    canceled: number;
    draft: number;
    now: number;
    past: number;
    upcoming: number;
  };
  users: any[];
};
type TravelsListDeletionContextProps = {
  isDeleting: boolean;
  isDeletionDialogVisible: boolean;
  selectedTravel: any | null;
};
type TravelsListReplicationContextProps = {
  copyTravels: any[];
  isFormOpen: boolean;
  originalTravels: any[];
  replicationGroups?: any;
  selectedTravel: any | null;
};
type TravelsSmartPointsContextProps = {
  isExplanationVisible: boolean;
  pointsInfo: {
    notPaidPoints: number;
    paidPoints: number;
    rewardActive: boolean;
  };
};
type TravelsTagsContextProps = {
  isLoading: boolean;
  onlyAdminOption: boolean | null;
  requiredTravelTagOption: boolean | null;
  tags: {
    [travelToken: string]: TravelTag[];
  };
};

const TravelsFiltersActionsContext = createContext({} as any);
const TravelsFiltersContext = createContext(
  filtersInitialState as TravelsFiltersContextProps,
);
const TravelsListActionsContext = createContext({} as any);
const TravelsListContext = createContext(
  travelsListInitialState as TravelsListContextProps,
);
const TravelsListDeletionActionsContext = createContext({} as any);
const TravelsListDeletionContext = createContext(
  deletionInitialState as TravelsListDeletionContextProps,
);
const TravelsListReplicationActionsContext = createContext({} as any);
const TravelsListReplicationContext = createContext(
  replicationInitialState as TravelsListReplicationContextProps,
);
const TravelsSmartPointsActionsContext = createContext({} as any);
const TravelsSmartPointsContext = createContext(
  smartPointsInitialState as TravelsSmartPointsContextProps,
);
const TravelsTagsActionsContext = createContext({} as any);
const TravelsTagsContext = createContext(
  tagsInitialState as TravelsTagsContextProps,
);

export const TravelsListProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [
    deletionState,
    setDeletionState,
  ] = useState<TravelsListDeletionContextProps>(deletionInitialState);
  const [filtersState, setFiltersState] = useState<TravelsFiltersContextProps>(
    filtersInitialState,
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [
    replicationState,
    setReplicationState,
  ] = useState<TravelsListReplicationContextProps>(replicationInitialState);
  const [
    smartPointsState,
    setSmartPointsState,
  ] = useState<TravelsSmartPointsContextProps>(smartPointsInitialState);
  const [tagsState, setTagsState] = useState<TravelsTagsContextProps>(
    tagsInitialState,
  );
  const [
    travelListState,
    setTravelsListState,
  ] = useState<TravelsListContextProps>(travelsListInitialState);

  const fetchTravelsCount = useCallback(
    async ({
      pendingFilters,
      search,
      userToken,
    }: {
      pendingFilters: string[];
      search: string;
      userToken: string;
    }) => {
      if (!user) {
        return;
      }

      setTravelsListState((prev) => ({
        ...prev,
        isLoadingTravelsCount: true,
        travelsCount: {
          all: 0,
          draft: 0,
          upcoming: 0,
          now: 0,
          past: 0,
          canceled: 0,
        },
      }));

      const filters = {
        search,
        travelerToken: userToken,
        pendingFilters,
      };

      const { data, error } = await tripsService.getTravelsCount(
        user.getUserToken(),
        filters as any,
      );

      if (error) {
        setTravelsListState((prev) => ({
          ...prev,
          isLoadingTravelsCount: false,
        }));

        return;
      }

      setTravelsListState((prev) => ({
        ...prev,
        isLoadingTravelsCount: false,
        travelsCount: data,
      }));
    },
    [],
  );

  // Deletion

  const handleSelectToDelete = (travel: any) => {
    setDeletionState((prev) => ({
      ...prev,
      selectedTravel: travel,
      isDeletionDialogVisible: true,
    }));
  };

  const handleCloseDeletionDialog = () => {
    if (deletionState.isDeleting) {
      return null;
    }

    setDeletionState((prev) => ({
      ...prev,
      selectedTravel: null,
      isDeletionDialogVisible: false,
    }));
  };

  const processTravelDeletion = async () => {
    setDeletionState((prev) => ({ ...prev, isDeleting: true }));
    const { selectedTravel } = deletionState;

    const { error } = await tripsService.deleteTravel(selectedTravel.travelId);

    if (error) {
      setDeletionState((prev) => ({ ...prev, isDeleting: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setTravelsListState((prev) => ({
      ...prev,
      travels: prev.travels.filter(
        (travel) => travel.travelId !== selectedTravel.travelId,
      ),
    }));

    void fetchTravelsCount({
      pendingFilters: filtersState.pendingFilters,
      search: filtersState.search,
      userToken: filtersState.userToken,
    });

    setDeletionState((prev) => ({
      ...prev,
      isDeleting: false,
      isDeletionDialogVisible: false,
      selectedTravel: null,
    }));

    showSnackMessage("Viagem excluída com sucesso", ALERT_TYPES.SUCCESS);
  };

  // Travels List

  const fetchData = async () => {
    setTravelsListState((prev) => ({ ...prev, loading: true }));

    const params = {
      page: 1,
      ...filtersState,
    };

    const [
      { data: travelsData, error: travelsError },
      { data: users, error: usersError },
    ] = await Promise.all([
      tripsService.getUserTravels(params),
      tripsService.getClientUsers(),
    ]);

    const basicDataError = travelsError || usersError;

    if (basicDataError) {
      setTravelsListState((prev) => ({
        ...prev,
        loading: false,
        loadingError: basicDataError,
      }));

      showSnackMessage(basicDataError.description, ALERT_TYPES.ERROR);

      return;
    }

    if (isFirstRender) {
      const permissions = getClientPermissions();

      if (!permissions || !permissions.travels) {
        return;
      }

      const {
        travels: { gamification: hasGamificationPermission },
      } = permissions;

      if (hasGamificationPermission) {
        const loadUserPointsResponse = await tripsService.loadUserPoints();

        if (loadUserPointsResponse.error) {
          showSnackMessage(
            loadUserPointsResponse.error.description,
            ALERT_TYPES.ERROR,
          );
        }

        setSmartPointsState((prev) => ({
          ...prev,
          pointsInfo: loadUserPointsResponse.data,
        }));
      }

      setIsFirstRender(false);
    }

    setTravelsListState((prev) => ({
      ...prev,
      currentPage: parseInt(travelsData.metaData.current, 10),
      loading: false,
      totalPages: travelsData.metaData.pages,
      travels: travelsData.travels,
      users: users,
    }));
  };

  const loadMoreTravels = async () => {
    setTravelsListState((prev) => ({ ...prev, loadingMore: true }));

    const nextPage = travelListState.currentPage + 1;

    const params = {
      page: nextPage,
      ...filtersState,
    };

    const { data: travelsData, error } = await tripsService.getUserTravels(
      params,
    );

    if (error) {
      setTravelsListState((prev) => ({
        ...prev,
        loadingMore: false,
        loadingError: error,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setTravelsListState((prev) => ({
      ...prev,
      currentPage: parseInt(travelsData.metaData.current, 10),
      loadingMore: false,
      totalPages: travelsData.metaData.pages,
      travels: prev.travels.concat(travelsData.travels),
    }));
  };

  // Filters

  const handleChangePendingFilters = (pendingFilters: any) => {
    setFiltersState((prev) => ({ ...prev, pendingFilters }));
  };

  const handleChangeSearchFilter = (search: string) => {
    setFiltersState((prev) => ({ ...prev, search }));
  };

  const handleChangeTabFilter = (tabFilter: TripsTabsFilter) => {
    setFiltersState((prev) => ({ ...prev, tabFilter }));
  };

  const handleChangeUserFilter = (userToken: string) => {
    setFiltersState((prev) => ({ ...prev, userToken }));
  };

  // Replication

  const handleCloseReplicationForm = () => {
    setReplicationState((prev) => ({
      ...prev,
      selectedTravel: null,
      isFormOpen: false,
    }));
  };

  const setReplicatingTravels = (originalTravel: any, copies: any[]) => {
    const { originalTravels, copyTravels } = replicationState;

    const originalTravelsMap = generateTravelsMap(originalTravels);
    const updatedOriginals = originalTravels;

    if (!originalTravelsMap[originalTravel.token]) {
      updatedOriginals.push(originalTravel);
    }

    const copiesMap = generateTravelsMap(copies);
    const updatedCopys = copyTravels
      .filter((travel) => !copiesMap[travel.token])
      .concat(copies);

    setReplicationState((prev) => ({
      ...prev,
      selectedTravel: null,
      isFormOpen: false,
      originalTravels: updatedOriginals,
      copyTravels: updatedCopys,
    }));
  };

  const checkReplicationStatus = async () => {
    const { copyTravels } = replicationState;

    const travelsToCheck = copyTravels.filter(
      (travel) => travel.copyStatus === COPY_STATUS.PROCESSING,
    );

    // Do nothing if there is nothing to do
    if (travelsToCheck.length === 0) {
      return null;
    }

    const statusCalls = travelsToCheck.map((travel) =>
      tripsService.checkTravelCopyStatus(travel.token),
    );
    const results = await Promise.all(statusCalls);

    const successResponses = results
      .filter((result) => !!result.data)
      .map((result) => result.data);
    const responsesMap = generateTravelsMap(successResponses);

    const updatedCopys = copyTravels.map((copy) => {
      const responseItem = responsesMap[copy.token];

      if (
        !!responseItem &&
        responseItem.copyStatus !== COPY_STATUS.PROCESSING
      ) {
        return responseItem;
      }

      return copy;
    });

    setReplicationState((prev) => ({
      ...prev,
      copyTravels: updatedCopys,
    }));
  };

  const replicationTravelGroups = React.useMemo(() => {
    const { originalTravels, copyTravels } = replicationState;
    const { users } = travelListState;

    const copiesWithUsers = getTravelsWithUsers(copyTravels, users);
    const originalWithUsers = getTravelsWithUsers(originalTravels, users);

    const groupsMap = originalWithUsers.reduce((acc, current) => {
      acc[current.token] = {
        originalTravel: current,
        copyTravels: [],
      };
      return acc;
    }, {});

    const filledGroups = copiesWithUsers.reduce((acc, current) => {
      const { copyFrom } = current;
      const group = acc[copyFrom];

      acc[copyFrom] = {
        ...group,
        copyTravels: group.copyTravels.concat(current),
      };
      return acc;
    }, groupsMap);

    return Object.keys(filledGroups).map((key) => filledGroups[key]);
  }, [replicationState.originalTravels, replicationState.copyTravels]);

  // Points

  const handleClosePointsExplanation = () => {
    setSmartPointsState((prev) => ({
      ...prev,
      isExplanationVisible: false,
    }));
  };

  const handleOpenPointsExplanation = () => {
    setSmartPointsState((prev) => ({
      ...prev,
      isExplanationVisible: true,
    }));
  };

  // Tags

  const loadUserTravelTags = async () => {
    setTagsState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await tagsService.getUserTravelTags();

    if (error) {
      setTagsState((prev) => ({
        ...prev,
        isLoading: false,
        tags: {},
      }));
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setTagsState((prev) => ({
      ...prev,
      isLoading: false,
      tags: data,
    }));
  };

  const loadTagConfigOptions = async () => {
    const { onlyAdminOption, requiredTravelTagOption } = tagsState;

    if (onlyAdminOption === null || requiredTravelTagOption === null) {
      const {
        data: clientTagOptions,
        error,
      } = await tagsService.getClientTagOptions();

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      const { tagsAdminOnly, travelTagsRequired } = clientTagOptions;

      setTagsState((prev) => ({
        ...prev,
        onlyAdminOption: tagsAdminOnly,
        requiredTravelTagOption: travelTagsRequired,
      }));
    }
  };

  const setTagsConfig = (tagsConfig: any) => {
    setTagsState((prev) => ({
      ...prev,
      onlyAdminOption: !!tagsConfig.tagsAdminOnly,
      requiredTravelTagOption: !!tagsConfig.travelTagsRequired,
    }));
  };

  useEffect(() => {
    void fetchData();
  }, [
    filtersState.search,
    filtersState.userToken,
    filtersState.tabFilter,
    filtersState.pendingFilters,
  ]);

  useEffect(() => {
    void fetchTravelsCount({
      pendingFilters: filtersState.pendingFilters,
      search: filtersState.search,
      userToken: filtersState.userToken,
    });
  }, [
    fetchTravelsCount,
    filtersState.search,
    filtersState.userToken,
    filtersState.pendingFilters,
  ]);

  useEffect(() => {
    void loadTagConfigOptions();
  }, []);

  return (
    <TravelsListContext.Provider
      value={{
        ...travelListState,
      }}
    >
      <TravelsListActionsContext.Provider
        value={{
          fetchData,
          loadMoreTravels,
          fetchTravelsCount,
        }}
      >
        <TravelsFiltersContext.Provider value={{ ...filtersState }}>
          <TravelsFiltersActionsContext.Provider
            value={{
              handleChangeSearchFilter,
              handleChangeUserFilter,
              handleChangeTabFilter,
              tabFilter: filtersState.tabFilter,
              handleChangePendingFilters,
              pendingFilters: filtersState.pendingFilters,
            }}
          >
            <TravelsSmartPointsContext.Provider value={{ ...smartPointsState }}>
              <TravelsSmartPointsActionsContext.Provider
                value={{
                  handleOpenPointsExplanation,
                  handleClosePointsExplanation,
                }}
              >
                <TravelsListReplicationContext.Provider
                  value={{
                    ...replicationState,
                    replicationGroups: replicationTravelGroups,
                  }}
                >
                  <TravelsListReplicationActionsContext.Provider
                    value={{
                      handleCloseReplicationForm,
                      setReplicatingTravels,
                      checkReplicationStatus,
                    }}
                  >
                    <TravelsListDeletionContext.Provider
                      value={{ ...deletionState }}
                    >
                      <TravelsListDeletionActionsContext.Provider
                        value={{
                          handleSelectToDelete,
                          handleCloseDeletionDialog,
                          processTravelDeletion,
                        }}
                      >
                        <TravelsTagsContext.Provider
                          value={{
                            ...tagsState,
                          }}
                        >
                          <TravelsTagsActionsContext.Provider
                            value={{
                              loadUserTravelTags,
                              loadTagConfigOptions,
                              setTagsConfig,
                            }}
                          >
                            {children}
                          </TravelsTagsActionsContext.Provider>
                        </TravelsTagsContext.Provider>
                      </TravelsListDeletionActionsContext.Provider>
                    </TravelsListDeletionContext.Provider>
                  </TravelsListReplicationActionsContext.Provider>
                </TravelsListReplicationContext.Provider>
              </TravelsSmartPointsActionsContext.Provider>
            </TravelsSmartPointsContext.Provider>
          </TravelsFiltersActionsContext.Provider>
        </TravelsFiltersContext.Provider>
      </TravelsListActionsContext.Provider>
    </TravelsListContext.Provider>
  );
};

export const useTravelsListActions = useContextFactory(
  "TravelsListActionsContext",
  TravelsListActionsContext,
);
export const useTravelsList = useContextFactory(
  "TravelsListContext",
  TravelsListContext,
);
export const useTravelsFiltersActions = useContextFactory(
  "TravelsFiltersActionsContext",
  TravelsFiltersActionsContext,
);
export const useTravelsFilters = useContextFactory(
  "TravelsFiltersContext",
  TravelsFiltersContext,
);
export const useTravelsListDeletionActions = useContextFactory(
  "TravelsListDeletionActionsContext",
  TravelsListDeletionActionsContext,
);
export const useTravelsListDeletion = useContextFactory(
  "TravelsListDeletionContext",
  TravelsListDeletionContext,
);
export const useTravelsSmartPointsActions = useContextFactory(
  "TravelsSmartPointsActionsContext",
  TravelsSmartPointsActionsContext,
);
export const useTravelsListReplicationActions = useContextFactory(
  "TravelsListReplicationActionsContext",
  TravelsListReplicationActionsContext,
);
export const useTravelsListReplication = useContextFactory(
  "TravelsListReplicationContext",
  TravelsListReplicationContext,
);
export const useTravelsSmartPoints = useContextFactory(
  "TravelsSmartPointsContext",
  TravelsSmartPointsContext,
);
export const useTravelsTagsActions = useContextFactory(
  "TravelsTagsActionsContext",
  TravelsTagsActionsContext,
);
export const useTravelsTags = useContextFactory(
  "TravelsTagsContext",
  TravelsTagsContext,
);
