import React from "react";

import { MenuItem } from "@material-ui/core";
import { css } from "emotion";

const styles = {
  rootItem: css({
    padding: "1rem .5rem 1rem 1rem"
  })
};

interface ISuggestion {
  AirportName: string;
  City: string;
  CityId: string;
  CountryCompleteName: string;
  CountryId: string;
  CountryName: string;
  PlaceId: string;
  PlaceName: string;
  RegionId: string;
  label: string;
  error?: boolean;
}

export interface IAirportItem {
  isHighlighted: boolean;
  suggestion: ISuggestion;
}

export interface IMenuFlightItemProps {
  item: IAirportItem;
}

export const MenuAirportItem = ({ item }: IMenuFlightItemProps) => {
  const { isHighlighted, suggestion } = item;

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      classes={{
        root: styles.rootItem
      }}
      disabled={suggestion.error}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      {suggestion?.error ? (
        suggestion.label
      ) : (
        <>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              fontSize: "1rem",
              fontWeight: 600
            }}
          >
            {suggestion.City}, {suggestion.CountryCompleteName}{" "}
            <span
              style={{
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "#5a6872",
                marginBottom: "0.031rem"
              }}
            >
              {suggestion.PlaceId.split("-")[0]}
            </span>
          </p>
          <p
            style={{
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#5a6872"
            }}
          >
            {suggestion.AirportName}
          </p>
        </>
      )}
    </MenuItem>
  );
};
