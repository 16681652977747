const pallete = {
  blue: {
    400: "#62A5F8",
    500: "#4386F4",
    600: "#2764E9",
    700: "#1F4FD6",
    800: "#2041AD",
    900: "#1F3B89",
    950: "#182553",
  },
  pink: {
    400: "#FF6980",
    500: "#FF385C",
    600: "#ED1143",
    700: "#C80839",
    800: "#A80937",
    900: "#8F0C35",
    950: "#500118",
  },
};

const palleteColors = Object.values(pallete).reduce((prev, color) => {
  return [...prev, ...Object.values(color)];
}, [] as string[]);

const aCharCode = "A".charCodeAt(0);

export const stringToColor = (string: string) => {
  const upperCaseString = string.toUpperCase();

  const firstLetterCharCode = upperCaseString.charCodeAt(0);

  let initialIndex = firstLetterCharCode - aCharCode;

  while (initialIndex >= palleteColors.length) {
    initialIndex -= palleteColors.length;
  }

  return palleteColors[initialIndex];
};
