import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";
import classnames from "classnames";

const style = css({
  marginTop: "5px",
  marginLeft: "5px",
  fontSize: "13px",
  fontWeight: "bold",
  letterSpacing: "0.04rem",
  lineHeight: "16px",
  fontFamily: "'Open sans', sans-serif",
  color: `${defaultTheme.secondaryColor}`
});

const InputError = props => {
  const { className, ...rest } = props;

  return <div className={classnames(style, className)} {...rest} />;
};

export default InputError;
