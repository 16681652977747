import { useEffect } from "react";
import ReactGA from "react-ga";

import { useLocation } from "../../hooks/use-location";

const PageViewsTracker: React.FC = () => {
  const { location } = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-103541597-3");
    ReactGA.pageview(location.pathname + location.search);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!location) {
      return;
    }

    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export { PageViewsTracker };
