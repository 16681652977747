import React, { PropsWithChildren } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import {
  PermissionsKeysLiterals,
  PermissionsValuesLiterals,
} from "~/apps/shared/constants/enums";

type Props = {
  atLeastOne?: boolean;
  expects?: PermissionsValuesLiterals[];
  permissions: PermissionsKeysLiterals[];
};

export const PermissionProtectedRender: React.FC<PropsWithChildren<Props>> = ({
  atLeastOne = false,
  expects,
  permissions = [],
  children,
}) => {
  const { user } = useUser();

  if (!user) {
    return null;
  }

  if (atLeastOne) {
    if (
      permissions.length > 0 &&
      permissions.some((permission, index) =>
        user.hasStrictPermission(permission, expects?.[index]),
      )
    ) {
      return <>{children}</>;
    }
  }

  if (
    permissions.length > 0 &&
    permissions.every((permission, index) =>
      user.hasStrictPermission(permission, expects?.[index]),
    )
  ) {
    return <>{children}</>;
  }

  return null;
};
