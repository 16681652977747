import React, { forwardRef } from "react";

import { Icon } from "../../../../../shared/components/icon/icon";
import { styles } from "./styles";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  icon?: React.ReactNode;
};

export const CarFormDateInput = forwardRef<HTMLInputElement, Props>(
  ({ icon, onBlur, onFocus, ...props }, ref) => {
    return (
      <div css={styles.root}>
        {icon ? icon : <Icon size={20} use="calendar" />}
        <input
          css={styles.input}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

CarFormDateInput.displayName = "CarFormDateInput";
