import React from "react";
import { AirportAutosuggest } from "../form-inputs/AirportAutosuggest/AirportAutosuggest";
import { AirportAutosuggest as AirportAutosuggestModel } from "../../../../models/autosuggest.model";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import { defaultTheme } from "../../../../assets/styles/theme";
import { DateInput } from "../../shared/DateInput/DateInput";
import { Row } from "../../../shared/layout/Row";
import { Moment } from "moment";
import { css } from "emotion";
import { Input } from "../../../shared/inputs";
import MenuItem from "@material-ui/core/MenuItem";
import { CABIN_CLASSES, FLIGHT_TYPE } from "../../../../constants";
import { ExpenseFormProps } from "../ExpenseDrawer.types";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
  valueInput: css({
    width: "89%",
    marginLeft: "1rem"
  }),
  input: css({
    width: "100%"
  }),
  flightTypeInput: css({
    width: "68%"
  }),
  menuItem: css({
    fontFamily: "inherit",
    color: "inherit",
    lineHeight: "inherit"
  }),
  aiportInputContainer: css({
    paddingBottom: "1.5rem"
  })
};

const FlightExpenseForm = ({
  values,
  errors,
  touched,
  loading,
  readOnly,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched
}: ExpenseFormProps) => {
  const handleSetDate = (fieldName: string) => (date: Moment | null) => {
    setFieldValue(fieldName, date);
  };

  const handleAiportChange = (segment: string) => (
    airportItem: AirportAutosuggestModel
  ) => {
    setFieldValue(segment, {
      locale: airportItem.PlaceId.split("-")[0],
      name: airportItem.label,
      cityId: airportItem.CityId,
      country: airportItem.CountryName
    });
  };

  const handleClearAiport = (fieldName: string) => () => {
    setFieldValue(fieldName, null);
  };

  const outboundFlightDateValidation = (day: Moment) => {
    if (!values.endDate) {
      return false;
    }

    return !day.isSameOrBefore(values.endDate!);
  };

  const inboundFlightDateValidation = (day: Moment) => {
    if (!values.startDate) {
      return false;
    }

    return !day.isSameOrAfter(values.startDate!);
  };

  const isRoundTrip = values.flightType === "roundtrip";

  return (
    <div>
      {values.origin || (values.origin && readOnly) ? (
        <Input
          label="Aeroporto de origem"
          value={values.origin.name}
          disabled={true}
          containerClasses={styles.aiportInputContainer}
          icon={
            <FlightTakeoffIcon
              style={{ fontSize: 20, color: defaultTheme.subTextColor }}
            />
          }
          InputProps={{
            endAdornment: (
              <CloseIcon
                onClick={handleClearAiport("origin")}
                style={{
                  cursor: "pointer",
                  color: defaultTheme.subTextColor,
                  marginLeft: ".5rem"
                }}
              />
            )
          }}
        />
      ) : (
        <AirportAutosuggest
          handleSelect={handleAiportChange("origin")}
          handleRemoveSelection={handleClearAiport("origin")}
          inputProps={{
            id: "origin",
            name: "origin",
            label: "Aeroporto de origem",
            placeholder: "Aeroporto de origem",
            disabled: loading,
            containerClasses: styles.aiportInputContainer,
            icon: (
              <FlightTakeoffIcon
                style={{ fontSize: 20, color: defaultTheme.subTextColor }}
              />
            ),
            onBlur: handleBlur,
            error: errors.origin && touched.origin,
            errorMessage: errors.origin && touched.origin ? errors.origin : null
          }}
        />
      )}
      {values.destination || (values.destination && readOnly) ? (
        <Input
          label="Aeroporto de destino"
          value={values.destination.name}
          disabled={true}
          containerClasses={styles.aiportInputContainer}
          icon={
            <FlightLandIcon
              style={{ fontSize: 20, color: defaultTheme.subTextColor }}
            />
          }
          InputProps={{
            endAdornment: (
              <CloseIcon
                onClick={handleClearAiport("destination")}
                style={{
                  cursor: "pointer",
                  color: defaultTheme.subTextColor,
                  marginLeft: ".5rem"
                }}
              />
            )
          }}
        />
      ) : (
        <AirportAutosuggest
          handleSelect={handleAiportChange("destination")}
          handleRemoveSelection={handleClearAiport("destination")}
          inputProps={{
            id: "destination",
            name: "destination",
            label: "Aeroporto de destino",
            placeholder: "Aeroporto de destino",
            disabled: loading,
            containerClasses: styles.aiportInputContainer,
            icon: (
              <FlightLandIcon
                style={{ fontSize: 20, color: defaultTheme.subTextColor }}
              />
            ),
            onBlur: handleBlur,
            error: errors.destination && touched.destination,
            errorMessage:
              errors.destination && touched.destination
                ? errors.destination
                : null
          }}
        />
      )}
      <Row style={{ paddingBottom: "1.5rem" }}>
        <Input
          id="cabinClass"
          name="cabinClass"
          disabled={loading || readOnly}
          select={true}
          value={values.cabinClass}
          onChange={handleChange}
          onBlur={handleBlur}
          containerClasses={styles.input}
          label="Classe"
          error={errors.cabinClass && touched.cabinClass}
          errorMessage={
            errors.cabinClass && touched.cabinClass ? errors.cabinClass : null
          }
        >
          {CABIN_CLASSES.map((cabinClass: { name: string; value: string }) => (
            <MenuItem
              value={cabinClass.value}
              key={cabinClass.name + "_" + cabinClass.value}
              className={styles.menuItem}
            >
              {cabinClass.name}
            </MenuItem>
          ))}
        </Input>
        <div style={{ padding: "0 .75rem" }} />
        <Input
          id="flightType"
          name="flightType"
          disabled={loading || readOnly}
          select={true}
          value={values.flightType}
          onChange={handleChange}
          onBlur={handleBlur}
          containerClasses={styles.flightTypeInput}
          label="Tipo"
          error={errors.flightType && touched.flightType}
          errorMessage={
            errors.flightType && touched.flightType ? errors.flightType : null
          }
        >
          {FLIGHT_TYPE.map((flighType: { name: string; value: string }) => (
            <MenuItem
              value={flighType.value}
              className={styles.menuItem}
              key={flighType.value}
            >
              {flighType.name}
            </MenuItem>
          ))}
        </Input>
      </Row>
      <Row style={{ paddingBottom: "1.5rem" }}>
        <DateInput
          id="startDate"
          rootStyle={{ width: isRoundTrip ? "75%" : "100%" }}
          placeholder="Ida"
          date={values.startDate!}
          handleDateChange={handleSetDate("startDate")}
          error={Boolean(errors.startDate && touched.startDate)}
          errorMessage={errors.startDate}
          setTouched={setFieldTouched("startDate")}
          disabled={loading || readOnly}
          isOutsideRange={outboundFlightDateValidation}
        />
        {isRoundTrip ? (
          <>
            <div style={{ padding: "0 .75rem" }} />
            <DateInput
              id="endDate"
              rootStyle={{ width: "75%" }}
              placeholder="Volta"
              date={values.endDate!}
              handleDateChange={handleSetDate("endDate")}
              error={Boolean(errors.endDate && touched.endDate)}
              errorMessage={errors.endDate}
              setTouched={setFieldTouched("endDate")}
              disabled={loading || readOnly}
              isOutsideRange={inboundFlightDateValidation}
            />
          </>
        ) : null}
      </Row>
    </div>
  );
};

export { FlightExpenseForm };
