import moment from "moment";

import { useInterval } from "./use-interval";

const CHECK_EXPIRATION_INTERVAL = 3 * 60 * 1000; // 3 minutes

export const useCheckExpiration = (
  expirationTime: Date | null,
  setExpiration: (hasExpired: boolean) => void,
  checkInterval: number = CHECK_EXPIRATION_INTERVAL,
) => {
  const checkExpiration = () => {
    let hasExpired;

    if (expirationTime) {
      const now = new Date().toUTCString();

      hasExpired = moment(moment(expirationTime)).diff(now, "minutes") <= 0;
    }

    if (hasExpired) {
      setExpiration(hasExpired);
    }
  };

  useInterval(checkExpiration, checkInterval);
};
