import React, { createContext, useCallback, useState } from "react";

import { navigate } from "@reach/router";
import * as offerApi from "~/apps/corporate/apis/offers.api";
import {
  BusesSearch,
  CarsSearch,
  FlightsSearch,
  HotelsSearch,
} from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ItineraryService } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import {
  RepeatedOffers,
  RepeatedOffersModel,
} from "~/apps/corporate/models/offer.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { ServiceType } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { sleep } from "~/apps/shared/utils/sleep";

import { useItineraryApproval } from "./itinerary-approval.context";
import { useItineraryPendencies } from "./itinerary-pendencies.context";
import { useItineraryServices } from "./itinerary-services.context";
import { useItinerary } from "./itinerary.context";
import * as itineraryService from "./itinerary.service";

interface Actions {
  cancelService: (offerToken: string) => Promise<void>;
  closeAddServiceDrawer: () => void;
  closeApprovalHistoryDialog: () => void;
  closePaymentLoadingDialog: () => void;
  closeServiceCancelationAlertDialog: () => void;
  closeServiceChoiceContextDialog: () => void;
  closeServiceDeletionAlertDialog: () => void;
  closeServiceDetailsDrawer: () => void;
  closeServiceOutOfPolicyDialog: () => void;
  closeServiceTravelersDialog: () => void;
  closeShareDialog: () => void;
  closeTravelCategorizationDialog: () => void;
  closeTravelDeletionAlertDialog: () => void;
  closeTravelRepeatedOffersDialog: () => void;
  closeTravelTagsDialog: () => void;
  closePaymentActionDialog: () => void;
  deleteService: (offerToken: string) => Promise<void>;
  deleteTravel: () => Promise<void>;
  doesTravelHaveRepeatedOffers: (
    offerTokens: string[],
    sleepDuration?: number,
  ) => Promise<boolean>;
  openAddServiceDrawer: (
    type: ServiceType,
    data?: BusesSearch | CarsSearch | FlightsSearch | HotelsSearch,
  ) => void;
  openApprovalHistoryDialog: () => void;
  openPaymentActionDialog: () => void;
  openPaymentLoadingDialog: () => void;
  openServiceCancelationAlertDialog: (service: ItineraryService) => void;
  openServiceChoiceContextDialog: (service: ItineraryService) => void;
  openServiceDeletionAlertDialog: (service: ItineraryService) => void;
  openServiceDetailsDrawer: (service: ItineraryService) => void;
  openServiceOutOfPolicyDialog: (service: ItineraryService) => void;
  openServiceTravelersDialog: (service: ItineraryService) => void;
  openShareDialog: () => void;
  openTravelDeletionAlertDialog: () => void;
  openTravelCategorizationDialog: (
    selectedCategorization:
      | "billing-profile"
      | "company-area"
      | "cost-center"
      | "project",
  ) => void;
  openTravelTagsDialog: () => void;
  proceedFromPendencies: () => Promise<void>;
  share: (url: string, email: string) => Promise<void>;
}

type State = {
  errorOnFetchCancelService: Error | null;
  errorOnFetchDeleteService: Error | null;
  errorOnFetchDeleteTravel: Error | null;
  isAddServiceDrawerOpen: boolean;
  isApprovalHistoryDialogOpen: boolean;
  isLoadingCancelService: boolean;
  isLoadingDeleteService: boolean;
  isLoadingDeleteTravel: boolean;
  isLoadingShare: boolean;
  isLoadingTravelRepeatedOffers: boolean;
  isPaymentActionDialogOpen: boolean;
  isPaymentLoadingDialogOpen: boolean;
  isProceedingFromPendencies: boolean;
  isServiceCancelationAlertDialogOpen: boolean;
  isServiceChoiceContextDialogOpen: boolean;
  isServiceDeletionAlertDialogOpen: boolean;
  isServiceDetailsDrawerOpen: boolean;
  isServiceOutOfPolicyDialogOpen: boolean;
  isServiceTravelersDialogOpen: boolean;
  isShareDialogOpen: boolean;
  isTravelCategorizationDialogOpen: boolean;
  isTravelDeletionAlertDialogOpen: boolean;
  isTravelRepeatedOffersDialogOpen: boolean;
  isTravelTagsDialogOpen: boolean;
  selectedAddServiceServiceData:
    | BusesSearch
    | CarsSearch
    | FlightsSearch
    | HotelsSearch
    | null;
  selectedAddServiceServiceType: ServiceType | null;
  selectedCategorization:
    | "billing-profile"
    | "company-area"
    | "cost-center"
    | "project"
    | null;
  selectedServiceCancelationAlertDialogService: ItineraryService | null;
  selectedServiceChoiceContextDialogService: ItineraryService | null;
  selectedServiceDeletionAlertDialogService: ItineraryService | null;
  selectedServiceDetailsDrawerService: ItineraryService | null;
  selectedServiceOutOfPolicyDialogService: ItineraryService | null;
  selectedServiceTravelersDialogService: ItineraryService | null;
  travelRepeatedOffers: RepeatedOffers | null;
};

const initialState: State = {
  errorOnFetchCancelService: null,
  errorOnFetchDeleteService: null,
  errorOnFetchDeleteTravel: null,
  isAddServiceDrawerOpen: false,
  isApprovalHistoryDialogOpen: false,
  isLoadingCancelService: false,
  isLoadingDeleteService: false,
  isLoadingDeleteTravel: false,
  isLoadingShare: false,
  isLoadingTravelRepeatedOffers: false,
  isPaymentActionDialogOpen: false,
  isPaymentLoadingDialogOpen: false,
  isProceedingFromPendencies: false,
  isServiceCancelationAlertDialogOpen: false,
  isServiceChoiceContextDialogOpen: false,
  isServiceDeletionAlertDialogOpen: false,
  isServiceDetailsDrawerOpen: false,
  isServiceOutOfPolicyDialogOpen: false,
  isServiceTravelersDialogOpen: false,
  isShareDialogOpen: false,
  isTravelCategorizationDialogOpen: false,
  isTravelDeletionAlertDialogOpen: false,
  isTravelRepeatedOffersDialogOpen: false,
  isTravelTagsDialogOpen: false,
  selectedAddServiceServiceData: null,
  selectedAddServiceServiceType: null,
  selectedCategorization: null,
  selectedServiceCancelationAlertDialogService: null,
  selectedServiceChoiceContextDialogService: null,
  selectedServiceDeletionAlertDialogService: null,
  selectedServiceDetailsDrawerService: null,
  selectedServiceOutOfPolicyDialogService: null,
  selectedServiceTravelersDialogService: null,
  travelRepeatedOffers: null,
};

const ItineraryScreenContext = createContext<Actions & State>({
  ...initialState,
  cancelService: async () => {
    return;
  },
  closeAddServiceDrawer: () => {
    return;
  },
  closeApprovalHistoryDialog: () => {
    return;
  },
  closePaymentLoadingDialog: () => {
    return;
  },
  closeTravelDeletionAlertDialog: () => {
    return;
  },
  closeServiceCancelationAlertDialog: () => {
    return;
  },
  closeServiceChoiceContextDialog: () => {
    return;
  },
  closeServiceDeletionAlertDialog: () => {
    return;
  },
  closeServiceDetailsDrawer: () => {
    return;
  },
  closeServiceOutOfPolicyDialog: () => {
    return;
  },
  closeServiceTravelersDialog: () => {
    return;
  },
  closeShareDialog: () => {
    return;
  },
  closeTravelRepeatedOffersDialog: () => {
    return;
  },
  closeTravelCategorizationDialog: () => {
    return;
  },
  closeTravelTagsDialog: () => {
    return;
  },
  closePaymentActionDialog: () => {
    return;
  },
  deleteService: async () => {
    return;
  },
  deleteTravel: async () => {
    return;
  },
  doesTravelHaveRepeatedOffers: async () => {
    return false;
  },
  openAddServiceDrawer: () => {
    return;
  },
  openApprovalHistoryDialog: () => {
    return;
  },
  openPaymentActionDialog: () => {
    return;
  },
  openPaymentLoadingDialog: () => {
    return;
  },
  openTravelDeletionAlertDialog: () => {
    return;
  },
  openServiceCancelationAlertDialog: () => {
    return;
  },
  openServiceChoiceContextDialog: () => {
    return;
  },
  openServiceDeletionAlertDialog: () => {
    return;
  },
  openServiceDetailsDrawer: () => {
    return;
  },
  openServiceOutOfPolicyDialog: () => {
    return;
  },
  openServiceTravelersDialog: () => {
    return;
  },
  openShareDialog: () => {
    return;
  },
  openTravelCategorizationDialog: () => {
    return;
  },
  openTravelTagsDialog: () => {
    return;
  },
  proceedFromPendencies: async () => {
    return;
  },
  share: async () => {
    return;
  },
});

export const ItineraryScreenProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const { travelToken } = useItinerary();
  const { fetchItineraryApprovalStatus } = useItineraryApproval();
  const { resolveAllPendencies } = useItineraryPendencies();
  const { fetchItineraryServices, services } = useItineraryServices();

  const [state, setState] = useState(initialState);

  const cancelService = useCallback(
    async (offerToken: string) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchCancelService: null,
        isLoadingCancelService: true,
      }));

      const { message, success } = await offerApi.cancelOffer(offerToken);

      if (success) {
        setState((prev) => ({
          ...prev,
          isLoadingCancelService: false,
          isServiceCancelationAlertDialogOpen: false,
          selectedServiceCancelationAlertDialogService: null,
        }));

        showSnackMessage(message, ALERT_TYPES.SUCCESS);

        await fetchItineraryServices();

        return;
      }

      const error = {
        ...ERROR.UNEXPECTED,
        description: message,
      };

      setState((prev) => ({
        ...prev,
        errorOnFetchCancelService: error,
        isLoadingCancelService: false,
        selectedServiceCancelationAlertDialogService: null,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    },
    [fetchItineraryServices, showSnackMessage],
  );

  const closeAddServiceDrawer = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isAddServiceDrawerOpen: false,
      selectedAddServiceServiceData: null,
    }));
  }, []);

  const closeApprovalHistoryDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isApprovalHistoryDialogOpen: false,
    }));
  }, []);

  const closePaymentLoadingDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isPaymentLoadingDialogOpen: false,
    }));
  }, []);

  const closeTravelDeletionAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelDeletionAlertDialogOpen: false,
    }));
  }, []);

  const closeServiceChoiceContextDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceChoiceContextDialogOpen: false,
    }));
  }, []);

  const closeServiceCancelationAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceCancelationAlertDialogOpen: false,
    }));
  }, []);

  const closeServiceDeletionAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceDeletionAlertDialogOpen: false,
    }));
  }, []);

  const closeServiceDetailsDrawer = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceDetailsDrawerOpen: false,
    }));
  }, []);

  const closeServiceOutOfPolicyDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceOutOfPolicyDialogOpen: false,
    }));
  }, []);

  const closeServiceTravelersDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isServiceTravelersDialogOpen: false,
    }));
  }, []);

  const closeShareDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isShareDialogOpen: false,
    }));
  }, []);

  const closeTravelRepeatedOffersDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelRepeatedOffersDialogOpen: false,
    }));
  }, []);

  const closeTravelCategorizationDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelCategorizationDialogOpen: false,
      selectedCategorization: null,
    }));
  }, []);

  const closeTravelTagsDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelTagsDialogOpen: false,
    }));
  }, []);

  const closePaymentActionDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isPaymentActionDialogOpen: false,
    }));
  }, []);

  const deleteService = useCallback(
    async (offerToken: string) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchDeleteService: null,
        isLoadingDeleteService: true,
      }));

      const { error } = await itineraryService.deleteService(offerToken);

      if (error) {
        setState((prev) => ({
          ...prev,
          errorOnFetchDeleteService: error,
          isLoadingDeleteService: false,
          selectedServiceDeletionAlertDialogService: null,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingDeleteService: false,
        isServiceDeletionAlertDialogOpen: false,
        selectedServiceDeletionAlertDialogService: null,
      }));

      await fetchItineraryServices();
    },
    [fetchItineraryServices, showSnackMessage],
  );

  const deleteTravel = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetchDeleteTravel: null,
      isLoadingDeleteTravel: true,
    }));

    const { error } = await itineraryService.deleteTravel(travelToken);

    if (error) {
      setState((prev) => ({
        ...prev,
        errorOnFetchDeleteTravel: error,
        isLoadingDeleteTravel: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingDeleteTravel: false,
    }));

    navigate("/travels");
  }, [showSnackMessage, travelToken]);

  const doesTravelHaveRepeatedOffers = useCallback(
    async (offerTokens: string[], sleepDuration = 0) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchTravelRepeatedOffers: null,
        isLoadingTravelRepeatedOffers: true,
      }));

      const [travelRepeatedOffersResponse] = await Promise.all([
        itineraryService.getTravelRepeatedOffers(offerTokens),
        sleep(sleepDuration),
      ]);

      if (travelRepeatedOffersResponse.isFailure()) {
        const error = travelRepeatedOffersResponse.data;

        setState((prev) => ({
          ...prev,
          errorOnFetchTravelRepeatedOffers: error,
          isLoadingTravelRepeatedOffers: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      const travelRepeatedOffersModel = new RepeatedOffersModel(
        travelRepeatedOffersResponse.data,
      );

      if (travelRepeatedOffersModel.hasRepeatedOffers()) {
        setState((prev) => ({
          ...prev,
          isLoadingTravelRepeatedOffers: false,
          isTravelRepeatedOffersDialogOpen: true,
          travelRepeatedOffers: travelRepeatedOffersResponse.data,
        }));

        return true;
      }

      setState((prev) => ({
        ...prev,
        isLoadingTravelRepeatedOffers: false,
      }));

      return false;
    },
    [services, showSnackMessage],
  );

  const openAddServiceDrawer = useCallback(
    (
      type: ServiceType,
      data?: BusesSearch | CarsSearch | FlightsSearch | HotelsSearch,
    ) => {
      setState((prev) => ({
        ...prev,
        isAddServiceDrawerOpen: true,
        selectedAddServiceServiceData: data ? data : null,
        selectedAddServiceServiceType: type,
      }));
    },
    [],
  );

  const openApprovalHistoryDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isApprovalHistoryDialogOpen: true,
    }));
  }, []);

  const openPaymentLoadingDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isPaymentLoadingDialogOpen: true,
    }));
  }, []);

  const openTravelDeletionAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelDeletionAlertDialogOpen: true,
    }));
  }, []);

  const openServiceCancelationAlertDialog = useCallback(
    (service: ItineraryService) => {
      setState((prev) => ({
        ...prev,
        isServiceCancelationAlertDialogOpen: true,
        selectedServiceCancelationAlertDialogService: service,
      }));
    },
    [],
  );

  const openServiceChoiceContextDialog = useCallback(
    (service: ItineraryService) => {
      setState((prev) => ({
        ...prev,
        isServiceChoiceContextDialogOpen: true,
        selectedServiceChoiceContextDialogService: service,
      }));
    },
    [],
  );

  const openServiceDeletionAlertDialog = useCallback(
    (service: ItineraryService) => {
      setState((prev) => ({
        ...prev,
        isServiceDeletionAlertDialogOpen: true,
        selectedServiceDeletionAlertDialogService: service,
      }));
    },
    [],
  );

  const openServiceDetailsDrawer = useCallback((service: ItineraryService) => {
    setState((prev) => ({
      ...prev,
      isServiceDetailsDrawerOpen: true,
      selectedServiceDetailsDrawerService: service,
    }));
  }, []);

  const openServiceOutOfPolicyDialog = useCallback(
    (service: ItineraryService) => {
      setState((prev) => ({
        ...prev,
        isServiceOutOfPolicyDialogOpen: true,
        selectedServiceOutOfPolicyDialogService: service,
      }));
    },
    [],
  );

  const openServiceTravelersDialog = useCallback(
    (service: ItineraryService) => {
      setState((prev) => ({
        ...prev,
        isServiceTravelersDialogOpen: true,
        selectedServiceTravelersDialogService: service,
      }));
    },
    [],
  );

  const openShareDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isShareDialogOpen: true,
    }));
  }, []);

  const openPaymentActionDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isPaymentActionDialogOpen: true,
    }));
  }, []);

  const openTravelCategorizationDialog = useCallback(
    (
      selectedCategorization:
        | "billing-profile"
        | "company-area"
        | "cost-center"
        | "project",
    ) => {
      setState((prev) => ({
        ...prev,
        isTravelCategorizationDialogOpen: true,
        selectedCategorization,
      }));
    },
    [],
  );

  const openTravelTagsDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isTravelTagsDialogOpen: true,
    }));
  }, []);

  const proceedFromPendencies = useCallback(async () => {
    if (!services) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isProceedingFromPendencies: true,
    }));

    const [travelApprovalStatusModel] = await Promise.all([
      fetchItineraryApprovalStatus(),
      sleep(3000),
    ]);

    if (!travelApprovalStatusModel) {
      setState((prev) => ({
        ...prev,
        isProceedingFromPendencies: false,
      }));

      return;
    }

    resolveAllPendencies();

    setState((prev) => ({
      ...prev,
      isProceedingFromPendencies: false,
    }));

    if (travelApprovalStatusModel.mustRequestApproval()) {
      navigate(`/travels/${travelToken}/itinerary/approval-request`);

      return;
    }

    navigate(`/travels/${travelToken}/payment`);
  }, [
    fetchItineraryApprovalStatus,
    resolveAllPendencies,
    services,
    travelToken,
  ]);

  const share = useCallback(
    async (url: string, email: string) => {
      setState((prev) => ({
        ...prev,
        isLoadingShare: true,
      }));

      const { error } = await itineraryService.shareItineraryPDF(
        travelToken,
        url,
        email,
      );

      if (error) {
        setState((prev) => ({
          ...prev,
          isLoadingShare: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingShare: false,
      }));

      showSnackMessage("Compartilhado com sucesso.", ALERT_TYPES.SUCCESS);
    },
    [showSnackMessage, travelToken],
  );

  return (
    <ItineraryScreenContext.Provider
      value={{
        ...state,
        cancelService,
        closeAddServiceDrawer,
        closeApprovalHistoryDialog,
        closePaymentActionDialog,
        closePaymentLoadingDialog,
        closeServiceCancelationAlertDialog,
        closeServiceChoiceContextDialog,
        closeServiceDeletionAlertDialog,
        closeServiceDetailsDrawer,
        closeServiceOutOfPolicyDialog,
        closeServiceTravelersDialog,
        closeShareDialog,
        closeTravelCategorizationDialog,
        closeTravelDeletionAlertDialog,
        closeTravelRepeatedOffersDialog,
        closeTravelTagsDialog,
        deleteService,
        deleteTravel,
        doesTravelHaveRepeatedOffers,
        openAddServiceDrawer,
        openApprovalHistoryDialog,
        openPaymentActionDialog,
        openPaymentLoadingDialog,
        openServiceCancelationAlertDialog,
        openServiceChoiceContextDialog,
        openServiceDeletionAlertDialog,
        openServiceDetailsDrawer,
        openServiceOutOfPolicyDialog,
        openServiceTravelersDialog,
        openShareDialog,
        openTravelCategorizationDialog,
        openTravelDeletionAlertDialog,
        openTravelTagsDialog,
        proceedFromPendencies,
        share,
      }}
    >
      {children}
    </ItineraryScreenContext.Provider>
  );
};

export const useItineraryScreen = useContextFactory(
  "ItineraryScreenContext",
  ItineraryScreenContext,
);
