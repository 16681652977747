import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPoliciesPerOffer } from "~/apps/corporate/models/travel.model";
import { UserModel } from "~/apps/corporate/models/user.model";

class ItineraryJourneyHeaderPresenter {
  constructor(
    private readonly infoModel: ItineraryInfoModel,
    private readonly servicePresenter: ItineraryServicePresenter,
    private readonly travelPoliciesPerOffer: TravelPoliciesPerOffer | null,
    private readonly userModel: UserModel,
  ) {}

  public getBookingFailedReason() {
    return this.servicePresenter.getBookingFailedReason();
  }

  public getChangedPrice() {
    return this.servicePresenter.getChangedPrice();
  }

  public isOutOfPolicy() {
    if (!this.travelPoliciesPerOffer) {
      return false;
    }

    const offerToken = this.servicePresenter.getOfferToken();

    if (!(offerToken in this.travelPoliciesPerOffer)) {
      return false;
    }

    const policy = this.travelPoliciesPerOffer[offerToken];

    return policy.outOfPolicy;
  }

  public showAsDenied({
    isApprovalReview,
    isSelected,
  }: {
    isApprovalReview?: boolean;
    isSelected?: boolean;
  }) {
    if (isApprovalReview) {
      return !isSelected;
    }

    return this.servicePresenter.isApprovalDeclined();
  }

  public showBookingFailedReasonBadge() {
    return this.servicePresenter.showBookingFailedReasonBadge();
  }

  public showCancelServiceBadge({ isTravelPlan }: { isTravelPlan?: boolean }) {
    if (!isTravelPlan) {
      return false;
    }

    return this.servicePresenter.showCancelServiceBadge();
  }

  public showChoiceContextBadge({
    isApprovalReview,
    isUnavailable,
  }: {
    isApprovalReview?: boolean;
    isUnavailable?: boolean;
  }) {
    if (!isApprovalReview || isUnavailable) {
      return false;
    }

    return this.servicePresenter.showChoiceContextBadge();
  }

  public showCopyOfferTokenButton() {
    return this.servicePresenter.showCopyOfferTokenButton();
  }

  public showDeleteButton({ isTravelPlan }: { isTravelPlan?: boolean }) {
    if (!isTravelPlan) {
      return false;
    }

    const isUserBooker = this.infoModel.isUserBooker(this.userModel);
    const isUserMasterApprover = this.userModel.isMasterApprover();

    const userHasPermissionToDelete = isUserBooker || isUserMasterApprover;

    if (!userHasPermissionToDelete) {
      return false;
    }

    return this.servicePresenter.showDeleteButton();
  }

  public showDeniedOnApprovalBadge() {
    return this.servicePresenter.showDeniedOnApprovalBadge();
  }

  public showErrorOnFetchOutOfPolicy({ hasError }: { hasError?: boolean }) {
    return hasError && this.servicePresenter.showErrorOnFetchOutOfPolicy();
  }

  public showNegotiatedBadge() {
    return this.servicePresenter.showNegotiatedBadge();
  }

  public showOutdatedBadge() {
    return this.servicePresenter.showOutdatedBadge();
  }

  public showOutOfPolicyBadge() {
    return this.isOutOfPolicy() && this.servicePresenter.showOutOfPolicyBadge();
  }

  public showSmApprovesBadge({
    isApprovalReview,
    isUnavailable,
  }: {
    isApprovalReview?: boolean;
    isUnavailable?: boolean;
  }) {
    if (!isApprovalReview || isUnavailable) {
      return false;
    }

    return this.servicePresenter.showSmApprovesBadge();
  }

  public showUnavailableBadge() {
    return this.servicePresenter.showUnavailableBadge();
  }
}

export class ItineraryJourneyHeaderPresenterFactory {
  public static create(
    infoModel: ItineraryInfoModel,
    servicePresenter: ItineraryServicePresenter,
    travelPoliciesPerOffer: TravelPoliciesPerOffer | null,
    userModel: UserModel,
  ): ItineraryJourneyHeaderPresenter {
    return new ItineraryJourneyHeaderPresenter(
      infoModel,
      servicePresenter,
      travelPoliciesPerOffer,
      userModel,
    );
  }
}
