import React, { useCallback, useMemo } from "react";

import { navigate } from "@reach/router";
import { Drawer } from "~/apps/shared/components/drawer/drawer";

import { RestrictionForm } from "./restriction-form/restriction-form";

type Props = {
  token?: string;
};

export const RestrictionDrawer: React.FC<Props> = ({ token }) => {
  const handleClose = useCallback(() => {
    navigate("/configurations/trips/country-restrictions");
  }, []);

  const mode = useMemo(() => {
    const path = window.location.pathname.split("/").pop();

    if (!!path && ["create", "edit"].includes(path)) {
      return path as "create" | "edit";
    }

    return null;
  }, [window.location.pathname]);

  const open = useMemo(() => mode !== null, [mode]);

  return (
    <Drawer onClose={handleClose} open={open}>
      <RestrictionForm handleClose={handleClose} mode={mode} token={token} />
    </Drawer>
  );
};
