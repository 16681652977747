import React, { ReactNode } from "react";
import Responsive from "react-responsive";

interface Props {
  children: Element | Element[] | ReactNode;
}

const Desktop = (props: Props) => <Responsive {...props} minWidth={992} />;
const TabletAndMobile = (props: Props) => (
  <Responsive {...props} maxWidth={991} />
);
const Tablet = (props: Props) => (
  <Responsive {...props} minWidth={768} maxWidth={991} />
);
const Mobile = (props: Props) => <Responsive {...props} maxWidth={767} />;

export { Desktop, TabletAndMobile, Tablet, Mobile };
