import React, { useMemo } from "react";
import { components } from "react-windowed-select";

import { CreditCard } from "~/apps/corporate/models/credit-card.model";
import { VCN } from "~/apps/corporate/models/vcn.model";
import { Select } from "~/apps/shared/components/select/select";
import { CARD_ISSUERS } from "~/apps/shared/constants/credit-card.constant";
import { CardPayerType, PaymentOption } from "~/apps/shared/constants/enums";
import { Option } from "~/apps/shared/types";

import { styles } from "./styles";

const buildCreditCardOption = (card: CreditCard) => {
  return {
    label: card.description,
    value: card.creditCardToken,
  };
};

const buildVCNOption = (vcn: VCN) => {
  return {
    label: `CARTÃO VIRTUAL (${vcn.provider})`,
    value: vcn.vcnToken,
  };
};

type Props = {
  handleChangeSelectedCardToken?: (cardToken: string) => void;
  payableCards: {
    creditCards: CreditCard[];
    vcns: VCN[];
  };
  paymentOption?: PaymentOption;
  selectedCardToken?: string | null;
  title: string;
};

export const ServicePaymentOptionSection: React.FC<Props> = ({
  handleChangeSelectedCardToken,
  payableCards,
  paymentOption,
  selectedCardToken,
  title,
}) => {
  if (
    !handleChangeSelectedCardToken &&
    paymentOption === PaymentOption.CREDIT_CARD
  ) {
    throw new Error(
      "handleChangeSelectedCardToken is required when paymentOption is CREDIT_CARD",
    );
  }

  const cardOptions = useMemo(() => {
    const options = [
      {
        label: "Cartões da empresa:",
        options: [] as Option<string>[],
      },
      {
        label: "Cartões do viajante:",
        options: [] as Option<string>[],
      },
      {
        label: "Seus cartões:",
        options: [] as Option<string>[],
      },
    ];

    for (const creditCard of payableCards.creditCards) {
      if (creditCard.payerType === CardPayerType.COMPANY) {
        options[0].options.push(buildCreditCardOption(creditCard));
      }

      if (creditCard.payerType === CardPayerType.TRAVELER) {
        options[1].options.push(buildCreditCardOption(creditCard));
      }

      if (creditCard.payerType === CardPayerType.PAYER) {
        options[2].options.push(buildCreditCardOption(creditCard));
      }
    }

    for (const vcn of payableCards.vcns) {
      if (vcn.payerType === CardPayerType.COMPANY) {
        options[0].options.push(buildVCNOption(vcn));
      }
    }

    return options;
  }, [payableCards]);

  if (!paymentOption) {
    return null;
  }

  const isPaymentBilled = [
    PaymentOption.AGENCY_BILLED,
    PaymentOption.BILLED,
    PaymentOption.SUPPLIER_BILLED,
  ].includes(paymentOption);

  return (
    <div css={styles.root}>
      <span css={styles.title}>{title}</span>
      {isPaymentBilled ? (
        <span css={styles.billed}>Pagamento faturado.</span>
      ) : handleChangeSelectedCardToken ? (
        <Select
          components={{
            // eslint-disable-next-line react/display-name
            Option: ({ ...props }) => {
              const { children, getStyles } = props;

              const value = ((props as unknown) as {
                value: string;
              }).value;

              const card = payableCards.creditCards.find(
                (creditCard) => creditCard.creditCardToken === value,
              );
              const vcn = payableCards.vcns.find(
                (vcn) => vcn.vcnToken === value,
              );

              const issuer = card
                ? CARD_ISSUERS.find((issuer) => issuer.brand === card.brand)
                : vcn
                ? CARD_ISSUERS.find((issuer) => issuer.brand === vcn.provider)
                : null;

              return (
                <components.Option
                  css={getStyles("option", props) as any}
                  {...props}
                >
                  <div css={styles.card.root}>
                    {issuer ? (
                      <img
                        alt={`Logo da ${issuer.brand}`}
                        height={20}
                        src={issuer.logo}
                        width={24}
                      />
                    ) : null}
                    {children}
                    {card ? <> (final {card.lastDigits})</> : null}
                  </div>
                </components.Option>
              );
            },
            // eslint-disable-next-line react/display-name
            ValueContainer: ({ children, ...props }) => {
              const card = payableCards.creditCards.find(
                (creditCard) =>
                  creditCard.creditCardToken === selectedCardToken,
              );
              const vcn = payableCards.vcns.find(
                (vcn) => vcn.vcnToken === selectedCardToken,
              );

              const issuer = card
                ? CARD_ISSUERS.find((issuer) => issuer.brand === card.brand)
                : vcn
                ? CARD_ISSUERS.find((issuer) => issuer.brand === vcn.provider)
                : null;

              return (
                <components.ValueContainer {...props}>
                  <div css={styles.card.root}>
                    {issuer ? (
                      <img
                        alt={`Logo da ${issuer.brand}`}
                        height={20}
                        src={issuer.logo}
                        width={24}
                      />
                    ) : null}
                    {card ? card.description : children}
                    {card ? <> (final {card.lastDigits})</> : null}
                  </div>
                </components.ValueContainer>
              );
            },
          }}
          onChange={({ value }) => {
            handleChangeSelectedCardToken(value);
          }}
          options={cardOptions}
          placeholder="Selecione um cartão..."
          useSetDefaultValueIfOnlyOneOption
          value={selectedCardToken ? selectedCardToken : undefined}
        />
      ) : null}
    </div>
  );
};
