import {
  PolicyForm,
  PolicyItem,
  PolicyTarget,
} from "~/apps/corporate/models/policy.model";

export interface CreatePolicyResult {
  data?: PolicyItem;
  error?: Error;
}

export interface DeletePolicyResult {
  error?: Error;
}

export interface EditPolicyResult {
  data?: PolicyItem;
  error?: Error;
}

export interface Error {
  description: string;
  title: string;
  type?: string;
}

export interface GetPolicyDetailedResult {
  data?: PolicyForm;
  error?: Error;
}

export interface GetPolicyTargetsResult {
  data?: PolicyTarget[];
  error?: Error;
}

export type IFlightPolicies =
  | "nationalFlightPolicy"
  | "internationalFlightPolicy";

export enum FlightPolicy {
  NATIONAL_FLIGHT_POLICY = "nationalFlightPolicy",
  INTERNATIONAL_FLIGHT_POLICY = "internationalFlightPolicy",
}

export type IHotelPolicies = "nationalHotelPolicy" | "internationalHotelPolicy";

export type ITravelPolicyForm = Omit<
  PolicyForm,
  "nationalFoodPolicy" | "internationalFoodPolicy" | "nationalMileagePolicy"
>;

export interface ListPoliciesResult {
  data?: PolicyItem[];
  error?: Error;
}

export interface SavePolicyResult {
  error?: Error;
}
