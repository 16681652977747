import React from "react";

import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";

import { Layout } from "../../components/layout/layout";
import { ScrollToTop } from "../../components/scroll-to-top/scroll-to-top";
import { AnalyticsMenu } from "./analytics-menu/analytics-menu";
import { styles } from "./styles";
import { AnalyticsFlightInfo } from "./views/analytics-flight-info/analytics-flight-info";
import { AnalyticsHotelInfo } from "./views/analytics-hotel-info/analytics-hotel-info";
import { AnalyticsPanel } from "./views/analytics-panel/analytics-panel";
import { AnalyticsRealTimeTravels } from "./views/analytics-real-time-travels/analytics-real-time-travels";
import { AnalyticsReportDownload } from "./views/analytics-report-download/analytics-report-download";

type Props = RouteComponentProps;

const Outlet: React.FC = () => {
  return (
    <Router css={styles.router} primary={false}>
      <AnalyticsPanel path="/" />
      <AnalyticsFlightInfo path="/flight-info" />
      <AnalyticsHotelInfo path="/hotel-info" />
      <PermissionProtectedRoute
        component={AnalyticsReportDownload}
        path="/report-download"
        permissions={["analytics.excelReportDownloads"]}
      />
      <PermissionProtectedRoute
        component={AnalyticsRealTimeTravels}
        path="/real-time"
        permissions={["analytics.realTimeTravels"]}
      />
      <Redirect from="*" noThrow to="./" />
    </Router>
  );
};

export const Analytics: React.FC<Props> = () => {
  return (
    <>
      <ScrollToTop />
      <Layout css={styles.layout}>
        <div css={styles.root}>
          <AnalyticsMenu />
          <div css={styles.outlet.root}>
            <Outlet />
          </div>
        </div>
      </Layout>
    </>
  );
};
