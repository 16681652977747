import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    color: theme.colors.pink[500],
  }),
  root: css({
    alignItems: "center",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "6px",
    display: "flex",
    gap: "8px",
    padding: "4px 16px",
    width: "fit-content",
  }),
  text: css({
    color: theme.colors.gray[700],
    fontSize: "0.75rem",
  }),
};
