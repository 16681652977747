import React from "react";

import { Check as CheckIcon, Block as BlockIcon } from "@material-ui/icons/";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import clsx from "classnames";
import { css } from "emotion";

import { Text, Box, Flex } from "@toolkit";

import { MEDIA_QUERIES } from "@constants";
import { EXPENSES_CATEGORIES } from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { Expense, ExpensePolicy } from "@models/expense.model";

import { defaultTheme } from "@theme";

import { StOutlinedButton } from "@shared/buttons";
import { Column, Row } from "@shared/layout/index";
import {
  Desktop,
  Mobile,
  Tablet,
  TabletAndMobile,
} from "@shared/layout/Responsive";
import { LongText } from "@shared/LongText";

import { CurrencyConversionSummary } from "~/components/shared/currency-conversion-summary";

import { ExpenseCardCategoryTag } from "../../shared/ExpenseCardCategoryTag";

const styles = {
  root: (isEditable: boolean) =>
    css({
      margin: isEditable ? "0.75rem 0 0rem 2rem" : "1.5rem 0 0rem 2rem",
      [MEDIA_QUERIES.mobileBreakpoint]: {
        marginLeft: 0,
      },
    }),
  cardRoot: css({
    display: "flex",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0 4px 0 0",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      minHeight: 90,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      flexDirection: "column",
    },
  }),
  baseCard: css({
    display: "flex",
    alignItems: "flex-end",
    gap: "1rem",
    flexWrap: "wrap",
    color: defaultTheme.textColor,
    padding: "1.25rem 1.75rem",
    cursor: "pointer",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "1rem",
    },
  }),
  inactiveCard: css({
    color: defaultTheme.subTextColor + " !important",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
  }),
  buttonsDiv: css({
    alignItems: "center",
    paddingBottom: ".75rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingBottom: ".5rem",
    },
  }),
  period: css({
    fontSize: 14,
    paddingBottom: ".25rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
    },
  }),
  description: css({
    fontSize: 14,
    fontWeight: "bold",
    maxWidth: "250px",
    lineHeight: 1.5,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
    },
  }),
  priceColumn: css({
    justifyContent: "center",
    alignItems: "flex-end",
    flexShrink: 0,
    whiteSpace: "nowrap",
  }),
  price: css({
    fontWeight: "bold",
    fontSize: 18,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 13,
    },
  }),
  positionBottom: css({}),
  approvedValueDiv: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 .5rem",
    borderLeft: `1px solid ${defaultTheme.cardBorderColor}`,
    minWidth: 133,
    maxWidth: 133,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      borderTop: `1px solid ${defaultTheme.cardBorderColor}`,
      borderLeft: "none",
      padding: ".5rem",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      maxWidth: "100%",
    },
  }),
  approvedLabel: css({
    fontSize: 14,
    paddingBottom: ".5rem",
    textAlign: "center",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 13,
      fontWeight: "bold",
      paddingRight: ".5rem",
      paddingBottom: 0,
    },
  }),
  outOfPolicyLabel: css({
    fontSize: 14,
    fontWeight: "bold",
    color: defaultTheme.secondaryColor,
    marginRight: "1rem",
    alignSelf: "center",
    cursor: "pointer",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
    },
  }),
  refundableIcons: css({
    marginRight: ".25rem",
    width: "1rem",
    height: "1rem",
  }),
};

interface Props {
  expense: Expense;
  policyInfo?: ExpensePolicy;
  isEditable: boolean;
  isRefundable: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
  handleOutOfPolicyClick: () => void;
}

const ExpenseItem = ({
  expense,
  policyInfo,
  isEditable,
  isRefundable,
  handleEdit,
  handleDelete,
  handleOutOfPolicyClick,
}: Props) => {
  const areParticipantsVisible =
    expense.expenseCategory === EXPENSES_CATEGORIES.BREAKFAST ||
    expense.expenseCategory === EXPENSES_CATEGORIES.LUNCH ||
    expense.expenseCategory === EXPENSES_CATEGORIES.DINNER ||
    expense.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
    expense.expenseCategory === EXPENSES_CATEGORIES.APARTMENT;

  const convertedValue = expensesHelper.convertCurrencyValue(expense);

  const isForeignCurrency = expensesHelper.isForeignCurrencyExpense(expense);

  return (
    <div className={styles.root(isEditable)}>
      <Row style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
        <Row>
          <ExpenseCardCategoryTag
            categoryName={expense.expenseCategoryName}
            categoryColor={expense.expenseCategoryColor}
            isCanceled={expense.canceled}
          />
        </Row>
        <Row className={styles.buttonsDiv}>
          {!!policyInfo && policyInfo.outOfPolicy ? (
            <div
              className={styles.outOfPolicyLabel}
              onClick={handleOutOfPolicyClick}
            >
              <span>Fora da política</span>
            </div>
          ) : null}
          {isEditable ? (
            <>
              <Desktop>
                <StOutlinedButton
                  onClick={handleEdit}
                  style={{
                    fontSize: 14,
                    padding: ".5rem",
                    fontWeight: "400",
                  }}
                >
                  Editar
                </StOutlinedButton>
              </Desktop>
              <DeleteIcon
                style={{
                  fontSize: 24,
                  color: defaultTheme.subTextColor,
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                }}
                onClick={handleDelete}
              />
            </>
          ) : null}
        </Row>
      </Row>
      <div className={styles.cardRoot}>
        <Row
          className={clsx(
            styles.baseCard,
            !isEditable ? styles.inactiveCard : "",
          )}
          onClick={handleEdit}
        >
          <div>
            <p className={styles.period}>
              {expense.date.format("dddd, DD MMM YYYY")}
            </p>
            <Mobile>
              <LongText limit={30} className={styles.description}>
                {expense.description}
              </LongText>
            </Mobile>
            <Tablet>
              <LongText limit={70} className={styles.description}>
                {expense.description}
              </LongText>
            </Tablet>
            <Desktop>
              <LongText limit={40} className={styles.description}>
                {expense.description}
              </LongText>
            </Desktop>
          </div>
          <Column
            className={clsx(
              styles.priceColumn,
              expense.approvedValue ? styles.positionBottom : "",
            )}
          >
            <p className={styles.price}>
              {utils.toCurrency(convertedValue, 2)}
            </p>
          </Column>
        </Row>
        {expense.approvedValue ? (
          <div className={styles.approvedValueDiv}>
            <div className={styles.approvedLabel}>
              <Desktop>
                <span>Pago</span>
              </Desktop>
              <TabletAndMobile>
                <span>Valor pago: </span>
              </TabletAndMobile>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                className={styles.price}
                style={{ color: defaultTheme.successTextColor }}
              >
                {utils.toCurrency(expense.approvedValue)}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      {isForeignCurrency ? (
        <CurrencyConversionSummary expense={expense} />
      ) : null}
      <Flex
        py={["small"]}
        px={["medium", null, 16]}
        justifyContent={"space-between"}
        alignItems="center"
        border="1px solid"
        borderTop="none"
        borderColor="border"
        borderRadius={"0 0 4px 4px"}
        backgroundColor="rgba(0, 0, 0, 0.02)"
      >
        {areParticipantsVisible ? (
          <Text color={defaultTheme.subTextColor} fontSize={0}>
            {expense.participantsQuantity > 1
              ? `${expense.participantsQuantity} participantes`
              : `${expense.participantsQuantity} participante`}
          </Text>
        ) : null}
        <Box color={defaultTheme.subTextColor} ml="auto" fontSize={0}>
          {isRefundable ? (
            <Flex alignItems="center">
              <CheckIcon className={styles.refundableIcons} />
              <Text>Reembolsável</Text>
            </Flex>
          ) : (
            <Flex alignItems="center">
              <BlockIcon className={styles.refundableIcons} />
              <Text>Não reembolsável</Text>
            </Flex>
          )}
        </Box>
      </Flex>
    </div>
  );
};

export { ExpenseItem };
