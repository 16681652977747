import { snackbarConstants } from "../constants/snackbar.constant";

const initialState = {
  visible: false,
  type: "",
  message: ""
};

export function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case snackbarConstants.SHOW_SUCCESS_MESSAGE:
      return {
        type: "success",
        message: action.message,
        visible: true
      };
    case snackbarConstants.SHOW_ERROR_MESSAGE:
      return {
        type: "error",
        message: action.message,
        visible: true
      };
    case snackbarConstants.CLEAR_SNACKBAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default snackbarReducer;
