import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  children: css({
    alignItems: "inherit",
    display: "inherit",
    gap: "inherit",
    justifyContent: "inherit",
  }),
  fallback: css({
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    height: "fit-content",
    justifyContent: "center",
  }),
  icon: css({
    color: theme.colors.gray[300],
    position: "relative",
  }),
  root: css({
    backgroundColor: theme.colors.gray[700],
    borderRadius: "8px",
    color: theme.colors.white,
    fontSize: "0.75rem",
    lineHeight: "1.25",
    padding: "8px",
  }),
};
