import React from "react";

import { theme } from "@skin/v2";
import { Select, SelectProps } from "@components/shared/inputs";

export interface IOutlinedSelectProps extends SelectProps {}

export const OutlinedSelect: React.FC<IOutlinedSelectProps> = ({
  ...props
}) => {
  return (
    <Select
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          display: "flex",
          justifyContent: "center",
          width: "16.625rem",
          height: "3rem",
          cursor: "pointer",
          borderRadius: "0.5rem",
          border: `1px solid ${theme.colors.gray[500]}`,
          boxShadow: "none",
          "&:hover": {
            borderColor: theme.colors.gray[700]
          },
          "&:focus-within": {
            border: `1px solid ${theme.colors.gray[500]}`,
            boxShadow: `0 0 0 1px ${theme.colors.gray[500]}`
          }
        }),
        menu: baseStyles => ({
          ...baseStyles,
          width: "16.625rem"
        }),
        menuList: baseStyles => ({
          ...baseStyles,
          width: "16.625rem",
          borderRadius: "0.5rem"
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          cursor: "pointer",
          padding: "0.75rem 1rem",
          color: isSelected ? theme.colors.white : theme.colors.black,
          backgroundColor: isSelected
            ? theme.colors.gray[500]
            : theme.colors.white,
          "&:hover": {
            backgroundColor: theme.colors.gray[300],
            color: theme.colors.white
          }
        }),
        valueContainer: baseStyles => ({
          ...baseStyles,
          flex: "none"
        }),
        indicatorSeparator: () => ({}),
        dropdownIndicator: baseStyles => ({
          ...baseStyles,
          color: theme.colors.gray[500]
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          lineHeight: "1.25rem"
        }),
        placeholder: baseStyles => ({
          ...baseStyles,
          color: theme.colors.gray[500],
          fontWeight: "bold"
        })
      }}
      theme={currentTheme => ({
        ...currentTheme,
        colors: {
          ...currentTheme.colors,
          primary: theme.colors.gray[500]
        }
      })}
      {...props}
    />
  );
};
