import React, { useContext, useEffect, useState } from "react";

import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Filters } from "~/apps/shared/components/filters/filters";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";

import { Flex } from "@toolkit";
import { Button } from "@toolkit/v2";

import { projectBatchApi, updateProjectsBatchApi } from "~/apis/excel.api";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import PageTitle from "@components/shared/PageTitle";

import { ProjectsForm } from "./form";
import { InactivationDialog } from "./inactivation-dialog";
import { LoadingSpinner } from "./LoadingSpinner/LoadingSpinner";
import { ProjectStateSelect } from "./project-state-select/project-state-select";
import { ProjectsList } from "./projects-list";
import { ProjectsContext, ProjectsProvider } from "./projects.context";
import { styles } from "./styles";

const DEFAULT_FILTERS = [
  {
    label: "Ativos",
    value: "active",
  },
  {
    label: "Arquivados",
    value: "archived",
  },
];

const Projects = () => {
  const {
    loading,
    openForm,
    fetchProjects,
    setNameFilter,
    selectedTab,
    handleTabChange,
  } = useContext(ProjectsContext);

  const [name, setName] = useState("");

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameFilter(event.target.value);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div>
      <Flex css={styles.buttons.root}>
        <ContainedButton css={styles.buttons.add} onClick={openForm}>
          Novo projeto
        </ContainedButton>

        <Flex style={{ gap: "1.5rem" }}>
          <Button
            fill="outlined"
            onClick={updateProjectsBatchApi.onDownloadModelClick}
            css={styles.buttons.excel}
          >
            <Icon use="cloud-arrow-down" />
            Exportar
          </Button>
          <BatchFileUploader
            onUpload={updateProjectsBatchApi.onUpload}
            onDownloadModelClick={updateProjectsBatchApi.onDownloadModelClick}
            onSuccessUpload={fetchProjects}
          >
            {({ openDialog }) => (
              <Button
                fill="outlined"
                onClick={openDialog}
                css={styles.buttons.excel}
              >
                <Icon use="cloud-arrow-up" />
                Atualizar via excel
              </Button>
            )}
          </BatchFileUploader>
          <BatchFileUploader
            onUpload={projectBatchApi.onUpload}
            onDownloadModelClick={projectBatchApi.onDownloadModelClick}
            onSuccessUpload={fetchProjects}
          >
            {({ openDialog }) => (
              <Button
                fill="outlined"
                onClick={openDialog}
                css={styles.buttons.excel}
              >
                <Icon use="cloud-arrow-up" />
                Cadastrar via excel
              </Button>
            )}
          </BatchFileUploader>
        </Flex>
      </Flex>
      <Flex style={{ gap: "1.5rem", padding: "1.5rem 0 2rem 0" }}>
        <SearchInput
          onChange={handleChangeName}
          placeholder="Buscar por nome de projetos"
        />
        <Filters
          closeOnSelect
          onChange={(filter) => handleTabChange(filter)}
          options={DEFAULT_FILTERS}
          value={selectedTab.toString()}
        />
      </Flex>
      <ProjectStateSelect />
      {loading ? <LoadingSpinner /> : <ProjectsList />}
      <InactivationDialog />
      <ProjectsForm />
    </div>
  );
};

const ProjectsContainer = (_: any) => (
  <React.Fragment>
    <PageTitle title="Projetos" />
    <ProjectsProvider>
      <Projects />
    </ProjectsProvider>
  </React.Fragment>
);

export { ProjectsContainer as Projects };
