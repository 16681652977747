import * as analyticsService from "../Analytics.service";
import * as analyticsApi from "@apis/analytics.api";
import * as flightInfoService from "../flight-info/FlightInfo.service";
import {
  GetFiltersOptionsResult,
  GetGeneralStaticDataResult,
  GetSpentByTravelerDataResult,
  SavesAndWastesDataResult,
  AlterDataResult,
  CancelDataResult,
  QueryParams,
  GetGeneralTransactedDataResult
} from "./panel.model";
import { ERROR } from "@constants";

export async function getFilterOptionsResult(): Promise<GetFiltersOptionsResult> {
  const getFiltersOptionsResult: GetFiltersOptionsResult = {};
  try {
    const costCenterOptions = await analyticsService.loadCostCentersOptions();
    const tagOptions = await analyticsService.loadTagOptions();
    const travelerOptions = await analyticsService.loadTravelerOptions();
    const companyAreaOptions = await analyticsService.loadCompanyAreaOptions();
    const projectOptions = await analyticsService.loadProjectOptions();
    const billingProfileOptions = await analyticsService.loadBillingProfileOptions();
    const expenseCategoriesOptions = await analyticsService.loadExpenseCategoriesOptions();

    getFiltersOptionsResult.data = {
      costCenterOptions,
      tagOptions,
      travelerOptions,
      companyAreaOptions,
      projectOptions,
      billingProfileOptions,
      expenseCategoriesOptions
    };
  } catch (error) {
    if (error.response) {
      getFiltersOptionsResult.error = ERROR.FILTERS_LOAD_ERROR;
    } else if (error.request) {
      getFiltersOptionsResult.error = ERROR.FILTERS_LOAD_ERROR;
    } else {
      getFiltersOptionsResult.error = ERROR.FILTERS_LOAD_ERROR;
    }
  }

  return getFiltersOptionsResult;
}

export async function getGeneralTransactedDataResult(
  config: QueryParams
): Promise<GetGeneralTransactedDataResult> {
  const result: GetGeneralTransactedDataResult = {};

  const dto = {
    config: {
      start_month: config.startMonth,
      start_year: config.startYear,
      end_month: config.endMonth,
      end_year: config.endYear,
      cost_center_token: config.costCenterToken,
      tag_token: config.tagToken,
      traveler_token: config.travelerToken,
      company_area_token: config.companyAreaToken,
      project_token: config.projectToken,
      billing_profile_token: config.billingProfileToken
    }
  };

  try {
    result.data = await analyticsApi.getGeneralTransactedData(dto);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getGeneralStaticDataResult(
  config: QueryParams
): Promise<GetGeneralStaticDataResult> {
  const result: GetGeneralStaticDataResult = {};

  const dto = {
    config: {
      start_month: config.startMonth,
      start_year: config.startYear,
      end_month: config.endMonth,
      end_year: config.endYear,
      cost_center_token: config.costCenterToken,
      tag_token: config.tagToken,
      traveler_token: config.travelerToken,
      company_area_token: config.companyAreaToken,
      project_token: config.projectToken,
      billing_profile_token: config.billingProfileToken
    }
  };

  try {
    result.data = await analyticsApi.getGeneralStaticData(dto);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getSpentByTraveler(
  config: QueryParams
): Promise<GetSpentByTravelerDataResult> {
  const result: GetSpentByTravelerDataResult = {};

  const dto = {
    start_month: config.startMonth,
    start_year: config.startYear,
    end_month: config.endMonth,
    end_year: config.endYear,
    cost_center_token: config.costCenterToken,
    tag_token: config.tagToken,
    traveler_token: config.travelerToken,
    company_area_token: config.companyAreaToken,
    project_token: config.projectToken,
    billing_profile_token: config.billingProfileToken
  };

  try {
    result.data = await analyticsApi.getSpentByTraveler(dto);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getTotalSavings(
  config: QueryParams
): Promise<SavesAndWastesDataResult> {
  const result: SavesAndWastesDataResult = {};

  try {
    result.data = await flightInfoService.getSavings(config);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getTotalWastes(
  config: QueryParams
): Promise<SavesAndWastesDataResult> {
  const result: SavesAndWastesDataResult = {};

  try {
    result.data = await flightInfoService.getWastes(config);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getAlterFee(
  config: QueryParams
): Promise<AlterDataResult> {
  const result: AlterDataResult = {};

  try {
    result.data = await flightInfoService.getAlterFee(config);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getAlterDifference(
  config: QueryParams
): Promise<AlterDataResult> {
  const result: AlterDataResult = {};

  try {
    result.data = await flightInfoService.getAlterDifference(config);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function getCancelFee(
  config: QueryParams
): Promise<CancelDataResult> {
  const result: CancelDataResult = {};

  try {
    result.data = await flightInfoService.getCancelFee(config);
  } catch (error) {
    result.error = error;
  }

  return result;
}
