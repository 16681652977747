import {
  CreateCompanyEventDto,
  CreateEventTravelDto,
  CreateEventUpdateDto,
  EditCompanyEventDto,
  EditEventParticipantDto,
  EditEventUpdateDto,
  GetUserCompanyEventDto,
  ListEventParticipantsDto,
  ListEventUpdatesDto,
  SearchEventTargetsDto
} from "sm-types/sm-company-events";

import { getClientToken } from "~/helpers/user.helper";

import * as eventsApi from "@apis/events.api";

export const acceptEventParticipation = async ({
  token
}: eventsApi.AcceptEventParticipationData): Promise<{ error?: Error }> => {
  const result: { error?: Error } = {};

  try {
    await eventsApi.acceptEventParticipation({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const createEvent = async ({
  data
}: Omit<eventsApi.CreateEventData, "clientToken">): Promise<{
  data?: CreateCompanyEventDto;
  error?: Error;
}> => {
  const result: { data?: CreateCompanyEventDto; error?: Error } = {};

  try {
    const clientToken = getClientToken();

    result.data = await eventsApi.createEvent({
      clientToken,
      data
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const createEventTravel = async ({
  data,
  token
}: eventsApi.CreateEventTravelData): Promise<{
  data?: CreateEventTravelDto;
  error?: Error;
}> => {
  const result: {
    data?: CreateEventTravelDto;
    error?: Error;
  } = {};

  try {
    result.data = await eventsApi.createEventTravel({
      data,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const createEventUpdate = async ({
  data,
  token
}: eventsApi.CreateEventUpdateData): Promise<{
  data?: CreateEventUpdateDto;
  error?: Error;
}> => {
  const result: { data?: CreateEventUpdateDto; error?: Error } = {};

  try {
    result.data = await eventsApi.createEventUpdate({
      data,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const declineEventParticipation = async ({
  token
}: eventsApi.AcceptEventParticipationData): Promise<{ error?: Error }> => {
  const result: { error?: Error } = {};

  try {
    await eventsApi.declineEventParticipation({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const disinviteEventParticipant = async ({
  participant_token,
  token
}: eventsApi.DisinviteEventParticipantData): Promise<{ error?: Error }> => {
  const result: { error?: Error } = {};

  try {
    await eventsApi.disinviteEventParticipant({
      participant_token,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const editEvent = async ({
  data,
  token
}: eventsApi.EditEventData): Promise<{
  data?: EditCompanyEventDto;
  error?: Error;
}> => {
  const result: { data?: EditCompanyEventDto; error?: Error } = {};

  try {
    result.data = await eventsApi.editEvent({
      data,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const editEventParticipant = async ({
  data,
  eventToken,
  token
}: eventsApi.EditEventParticipantData): Promise<{
  data?: EditEventParticipantDto;
  error?: Error;
}> => {
  const result: { data?: EditEventParticipantDto; error?: Error } = {};

  try {
    result.data = await eventsApi.editEventParticipant({
      data,
      eventToken,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const editEventUpdate = async ({
  data,
  token,
  updateToken
}: eventsApi.EditEventUpdateData): Promise<{
  data?: EditEventUpdateDto;
  error?: Error;
}> => {
  const result: { data?: EditEventUpdateDto; error?: Error } = {};

  try {
    result.data = await eventsApi.editEventUpdate({
      data,
      token,
      updateToken
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const excludeEvent = async ({
  token
}: eventsApi.ExcludeEventData): Promise<{
  data?: {
    success: boolean;
  };
  error?: Error;
}> => {
  const result: {
    data?: {
      success: boolean;
    };
    error?: Error;
  } = {};

  try {
    result.data = await eventsApi.excludeEvent({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const getEvent = async ({
  token
}: eventsApi.GetEventData): Promise<{
  data?: GetUserCompanyEventDto;
  error?: Error;
}> => {
  const result: {
    data?: GetUserCompanyEventDto;
    error?: Error;
  } = {};

  try {
    result.data = await eventsApi.getEvent({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const inviteEventParticipants = async ({
  data,
  token
}: eventsApi.InviteEventParticipantsData): Promise<{
  error?: Error;
}> => {
  const result: {
    error?: Error;
  } = {};

  try {
    await eventsApi.inviteEventParticipants({
      data,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const listEvents = async (): Promise<{
  data?: GetUserCompanyEventDto[];
  error?: Error;
}> => {
  const result: {
    data?: GetUserCompanyEventDto[];
    error?: Error;
  } = {};

  try {
    const clientToken = getClientToken();

    result.data = await eventsApi.listEvents({
      clientToken
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const listEventParticipants = async ({
  token
}: eventsApi.ListEventParticipantsData): Promise<{
  data?: ListEventParticipantsDto;
  error?: Error;
}> => {
  const result: {
    data?: ListEventParticipantsDto;
    error?: Error;
  } = {};

  try {
    result.data = await eventsApi.listEventParticipants({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const listEventUpdates = async ({
  token
}: eventsApi.ListEventUpdatesData): Promise<{
  data?: ListEventUpdatesDto;
  error?: Error;
}> => {
  const result: {
    data?: ListEventUpdatesDto;
    error?: Error;
  } = {};

  try {
    result.data = await eventsApi.listEventUpdates({
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};

export const searchEventTargets = async ({
  search,
  token
}: eventsApi.SearchEventTargetsData): Promise<{
  data?: SearchEventTargetsDto;
  error?: Error;
}> => {
  const result: { data?: SearchEventTargetsDto; error?: Error } = {};

  try {
    result.data = await eventsApi.searchEventTargets({
      search,
      token
    });
  } catch (error) {
    result.error = error as any;
  }

  return result;
};
