import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { Icon } from "~/apps/shared/components/icon/icon";
import { LoadingOverlay } from "~/apps/shared/components/loading-overlay/loading-overlay";
import { Button } from "smartrips-toolkit/lib/v2";

import { APIKeysCopyInput } from "./api-keys-copy-input/api-keys-copy-input";
import {
  APIKeysProvider,
  useAPIKeysActions,
  useAPIKeys,
} from "./api-keys.context";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Component: React.FC<Props> = () => {
  const {
    clientId,
    clientSecret,
    errorOnDownloadExternalApiDoc,
    isLoadingClientExternalCredentials,
    isLoadingCreateClientExternalCredentials,
    isLoadingDownloadExternalApiDoc,
    isLoadingRemoveClientExternalCredentials,
    isLoadingResetClientExternalCredentials,
  } = useAPIKeys();

  const {
    createClientExternalCredentials,
    downloadDocumentation,
    loadClientExternalCredentials,
    removeClientExternalCredentials,
    resetClientExternalCredentials,
  } = useAPIKeysActions();

  const clientHasExternalCredentials = clientId && clientSecret;

  useEffect(() => {
    void loadClientExternalCredentials();
  }, []);

  return (
    <>
      <PageTitle title="API Externa" />
      <LoadingOverlay
        css={styles.loading}
        open={isLoadingClientExternalCredentials}
      />
      <div css={styles.root}>
        <div css={styles.top}>
          <Button
            css={styles.button}
            disabled={
              !!errorOnDownloadExternalApiDoc || isLoadingDownloadExternalApiDoc
            }
            onClick={async () => {
              await downloadDocumentation();
            }}
          >
            <Icon size={16} use="cloud-arrow-down" />
            Baixar documentação
          </Button>
          {!clientHasExternalCredentials ? (
            <Button
              css={styles.button}
              disabled={isLoadingCreateClientExternalCredentials}
              onClick={async () => {
                await createClientExternalCredentials();
              }}
              variant="pink"
            >
              <Icon size={16} use="plus" />
              Criar uma nova chave
            </Button>
          ) : null}
        </div>
        {errorOnDownloadExternalApiDoc ? (
          <p css={styles.error}>
            Houve um erro ao baixar a documentação da API. Por favor, envie um
            email com o título &quot;Documentação da API Smartrips&quot; para:{" "}
            <strong css={styles.strong}>meajuda@smartrips.com.br</strong>
          </p>
        ) : null}
        {clientHasExternalCredentials ? (
          <div css={styles.card.root}>
            <div css={styles.card.header.root}>
              <span css={styles.card.header.title}>API Externa</span>
            </div>
            <div css={styles.card.body.root}>
              <div css={styles.card.body.input.root}>
                <span css={styles.card.body.input.label}>Client ID</span>
                <APIKeysCopyInput text={clientId} />
              </div>
              <div css={styles.card.body.input.root}>
                <span css={styles.card.body.input.label}>Client Secret</span>
                <APIKeysCopyInput text={clientSecret} />
              </div>
              <div css={styles.card.footer.root}>
                <Button
                  css={styles.card.footer.button}
                  disabled={isLoadingResetClientExternalCredentials}
                  onClick={async () => {
                    await resetClientExternalCredentials();
                  }}
                  type="submit"
                >
                  <Icon size={16} use="arrow-path" />
                  Recriar
                </Button>
                <Button
                  css={styles.card.footer.button}
                  disabled={isLoadingRemoveClientExternalCredentials}
                  fill="outlined"
                  onClick={async () => {
                    await removeClientExternalCredentials();
                  }}
                >
                  <Icon size={16} use="trash" />
                  Remover chaves
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <span css={styles.empty}>Nenhuma chave configurada.</span>
        )}
      </div>
    </>
  );
};

export const APIKeys: React.FC<Props> = ({ ...props }) => (
  <APIKeysProvider>
    <Component {...props} />
  </APIKeysProvider>
);
