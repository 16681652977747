import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    button: css({
      "@media (max-width: 640px)": {
        display: "none",
      },
    }),
  },
  right: {
    bottom: css({
      alignItems: "center",
      display: "flex",
      gap: "32px",
      "@media screen and (max-width: 768px)": {
        gap: "16px",
      },
      "@media (max-width: 640px)": {
        justifyContent: "space-between",
        width: "100%",
      },
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "32px",
      "@media (max-width: 640px)": {
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        gap: "16px",
        justifyContent: "space-between",
        padding: "8px 16px",
        width: "100%",
      },
    }),
    search: {
      root: css({
        display: "flex",
        flexDirection: "column",
      }),
      field: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        lineHeight: "24px",
      }),
      value: css({
        color: theme.colors.gray[300],
        lineHeight: "24px",
      }),
    },
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 80px",
    position: "sticky",
    top: 0,
    transition: "all 0.15s ease-in-out",
    zIndex: 2,
    "@media (max-width: 1280px)": {
      padding: "24px",
    },
    "@media (max-width: 640px)": {
      backgroundColor: theme.colors.background.gray,
      border: "unset",
      padding: "16px",
    },
  }),
};
