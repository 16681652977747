import React from "react";

import { theme } from "@skin/v2";
import { FormControlLabel, Radio } from "@material-ui/core";

type RadioGroupItemProps = {
  children: React.ReactNode;
  value: any;
  disabled?: boolean;
};

export default function RadioGroupItem(props: RadioGroupItemProps) {
  const { children, value, disabled, ...rest } = props;

  return (
    <FormControlLabel
      control={<Radio style={{ color: theme.colors.gray[500] }} />}
      label={children}
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
}
