import React, { useCallback } from "react";

import { NotEnoughTravelersForm } from "~/apps/corporate/components/not-enough-travelers-form/not-enough-travelers-form";
import { ItineraryServicesModelFactory } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import { Dialog, DialogContent } from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryPendencies } from "../../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../../itinerary-screen.context";
import { styles } from "./styles";
import { useTravelPlanServiceTravelersDialogPresenter } from "./travel-plan-service-travelers-dialog.hooks";

export const TravelPlanServiceTravelersDialog: React.FC = () => {
  const { servicePresenter } = useTravelPlanServiceTravelersDialogPresenter();

  const { offersTravelers, saveNotEnoughTravelers } = useItineraryPendencies();
  const {
    closeServiceTravelersDialog,
    isServiceTravelersDialogOpen,
  } = useItineraryScreen();

  const handleSubmit = useCallback(
    async (
      data: Record<
        string,
        {
          main: number;
          userToken: string;
        }[]
      >,
    ) => {
      if (!servicePresenter) {
        return;
      }

      const itineraryServicesModel = ItineraryServicesModelFactory.create([
        servicePresenter.getService(),
      ]);

      const success = await saveNotEnoughTravelers(
        data,
        itineraryServicesModel,
      );

      if (!success) {
        return;
      }
    },
    [servicePresenter, saveNotEnoughTravelers],
  );

  if (!offersTravelers || !servicePresenter) {
    return null;
  }

  const formattedTitle = servicePresenter.isHotelService()
    ? "Editar hóspedes"
    : "Editar viajantes";

  return (
    <Dialog
      css={styles.root}
      onClose={closeServiceTravelersDialog}
      open={isServiceTravelersDialogOpen}
    >
      <header css={styles.header.root}>
        <span css={styles.header.title}>{formattedTitle}</span>
        <button css={styles.header.close} onClick={closeServiceTravelersDialog}>
          <Icon size={16} use="x" />
        </button>
      </header>
      <DialogContent css={styles.content.root}>
        <NotEnoughTravelersForm
          offersTravelers={offersTravelers}
          onSubmit={async ({ offersTravelers }) => {
            await handleSubmit(offersTravelers);
          }}
          services={[servicePresenter]}
        />
      </DialogContent>
    </Dialog>
  );
};
