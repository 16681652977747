import React from "react";

import { Option } from "../../types";
import { getDayHoursWithInterval } from "../../utils/get-day-hours-with-interval";
import { Select } from "../select/select";
import { styles } from "./styles";

const hourOptions: Option<string>[] = getDayHoursWithInterval(30).map(
  (hour) => ({
    label: hour,
    value: hour,
  }),
);

type Props = React.ComponentProps<typeof Select>;

export const HourSelect: React.FC<Props> = ({ ...props }) => {
  return (
    <Select
      css={styles.root}
      options={hourOptions}
      placeholder="00:00"
      {...props}
    />
  );
};
