import React, { useState, useMemo } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { useContextFactory } from "@hooks";

import * as service from "./ExpenseAdvanceApprovalsList.service";
import { ExpenseAdvanceApprovalItem } from "./types";

interface ExpenseAdvanceApprovalsListState {
  isLoading: boolean;
  search: string;
  expenseAdvanceApprovals: ExpenseAdvanceApprovalItem[];
}

interface ExpenseAdvanceApprovalsListSelectors {
  visibleApprovals: ExpenseAdvanceApprovalItem[];
}

interface ExpenseAdvanceApprovalsListActions {
  loadApprovals: (status: 1 | 99) => void;
  handleChangeSearch: (search: string) => void;
}

const ExpenseAdvanceApprovalsListContext = React.createContext(
  {} as ExpenseAdvanceApprovalsListState & ExpenseAdvanceApprovalsListSelectors,
);
const ExpenseAdvanceApprovalsListActionsContext = React.createContext(
  {} as ExpenseAdvanceApprovalsListActions,
);

export const useExpenseAdvanceApprovalsListContext: () => ExpenseAdvanceApprovalsListState &
  ExpenseAdvanceApprovalsListSelectors = useContextFactory(
  "ExpenseAdvanceApprovalsListContext",
  ExpenseAdvanceApprovalsListContext,
);
export const useExpenseAdvanceApprovalsListActionsContext: () => ExpenseAdvanceApprovalsListActions = useContextFactory(
  "ExpenseAdvanceApprovalsListActionsContext",
  ExpenseAdvanceApprovalsListActionsContext,
);

const ListInitialState: ExpenseAdvanceApprovalsListState = {
  expenseAdvanceApprovals: [],
  isLoading: false,
  search: "",
};

export const ExpenseAdvanceApprovalsListProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [listState, setListState] = useState(ListInitialState);

  const loadApprovals = async (status: 1 | 99) => {
    setListState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await service.getPendingApprovalExpenseAdvances(
      status,
    );

    if (error) {
      setListState((prev) => ({ ...prev, isLoading: false }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setListState((prev) => ({
      ...prev,
      expenseAdvanceApprovals: data!,
      isLoading: false,
    }));
  };

  const handleChangeSearch = debounce((search) => {
    setListState((prev) => ({ ...prev, search }));
  }, 300);

  const visibleApprovals = useMemo(() => {
    const { expenseAdvanceApprovals, search } = listState;

    return expenseAdvanceApprovals.filter((approval) => {
      return (
        approval.name.toLowerCase().includes(search.toLowerCase()) ||
        approval.requesterFullName.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [listState.search, listState.expenseAdvanceApprovals]);

  return (
    <ExpenseAdvanceApprovalsListContext.Provider
      value={{
        ...listState,
        visibleApprovals,
      }}
    >
      <ExpenseAdvanceApprovalsListActionsContext.Provider
        value={{
          handleChangeSearch,
          loadApprovals,
        }}
      >
        {children}
      </ExpenseAdvanceApprovalsListActionsContext.Provider>
    </ExpenseAdvanceApprovalsListContext.Provider>
  );
};
