import * as yup from "yup";

const formSchema = yup.object().shape({
  reviewTitle: yup
    .string()
    .required("Preencha o titulo da avaliação")
    .min(3, "Mínimos de 3 caracteres")
    .max(63, "O título não pode ser maior que 63 caracteres")
    .typeError("Preencha o titulo da avaliação"),
  positiveReview: yup
    .string()
    .required("Preencha sua avaliação de pontos positivos")
    .min(3, "Sua avaliação precisa ter no mínimo 3 caracteres")
    .max(255, "Sua avaliação pode ter no máximo 255 caracteres"),
  negativeReview: yup
    .string()
    .required("Preencha sua avaliação de pontos negativos")
    .min(3, "Sua avaliação precisa ter no mínimo 3 caracteres")
    .max(255, "Sua avaliação pode ter no máximo 255 caracteres"),
  generalGrade: yup
    .number()
    .typeError("Preencha a nota geral do hotel")
    .required("Preencha a nota geral do hotel"),
  treatmentGrade: yup.number().required(),
  cleaningGrade: yup.number().required(),
  roomGrade: yup.number().required(),
  locationGrade: yup.number().required(),
  wifiGrade: yup.number().required()
});

export { formSchema };
