import {
  ApprovalAction,
  ApprovalPolicyCondition,
  ApprovalTerritoryCondition,
  ClientActivationStatus,
  ClientApprovalConfigType,
  ClientBookingPhoneConfig,
  TravelIntegrationOption,
} from "~/apps/shared/constants/enums";
import { logger } from "~/apps/shared/utils/logger";
import { Descendant } from "slate";
import { ConfigOptions, Nullable } from "sm-types/common";

export type Client = {
  activationStatus: ClientActivationStatus;
  budgetsActive: boolean;
  clientSlug: string;
  clientToken: string;
  costCenterActive: boolean;
  createdAt: string;
  email: string;
  id: string;
  name: string;
  vaultActive: boolean;
};

export type ClientBoss = {
  email: string;
  employeeToken: string;
  token: string;
};

export type ApprovalConfig = {
  type: ClientApprovalConfigType;
  ruleToApply: Nullable<{
    action: ApprovalPolicyCondition;
    policyCondition: ApprovalTerritoryCondition;
    territoryCondition: ApprovalAction;
  }>;
};

export type ClientConfig = {
  approvalPriorityRanking?: string;
  bank3Enable?: boolean;
  bookingPhoneConfig: ClientBookingPhoneConfig;
  budgetsActive: boolean;
  approvalConfig: ApprovalConfig;
  busVisible: boolean;
  carVisible: boolean;
  clientConfigToken: string;
  companyAreaDisplay: string;
  companyAreaEnabled: boolean;
  companyBoardDisplay: string;
  config: ConfigOptions;
  costCenterActive: boolean;
  customCorporativeCardName: string;
  customMessage?: string;
  customMessageLink?: string;
  flightVisible: boolean;
  hotelVisible: boolean;
  projectEnablingState: "off" | "optional" | "required";
  supportChannel: string;
  tagsAdminOnly: boolean;
  travelIntegration: TravelIntegrationOption;
  travelTagsRequired: boolean;
  vaultActive: boolean;
};

export class ClientConfigModel {
  constructor(protected clientConfig: ClientConfig) {}

  public changeClientConfig(clientConfig: Partial<ClientConfig>) {
    this.clientConfig = {
      ...this.clientConfig,
      ...clientConfig,
    };

    return this;
  }

  public getCompanyAreaDisplay() {
    return this.clientConfig.companyAreaDisplay;
  }

  public getCompanyBoardDisplay() {
    return this.clientConfig.companyBoardDisplay;
  }

  public getCustomCorporativeCardName() {
    return this.clientConfig.customCorporativeCardName;
  }

  private getCustomMessage() {
    return this.clientConfig.customMessage;
  }

  public getParsedCustomMessage() {
    return this.parseCustomMessage(this.getCustomMessage());
  }

  public getFlightAllowedCabinClasses() {
    return this.clientConfig.config.flights.allowedClasses;
  }

  public getHomeWarning() {
    return this.clientConfig.config.travels.homePageWarning;
  }

  public getProjectEnablingState() {
    return this.clientConfig.projectEnablingState;
  }

  public getSupportChannel() {
    return this.clientConfig.supportChannel;
  }

  public getToken() {
    return this.clientConfig.clientConfigToken;
  }

  public hasCustomMessage() {
    return !!this.clientConfig.customMessage;
  }

  public hasHomeWarning() {
    return !!this.clientConfig.config.travels.homePageWarning;
  }

  public isBusesVisible() {
    return this.clientConfig.busVisible;
  }

  public isCarsVisible() {
    return this.clientConfig.carVisible;
  }

  public isHotelsVisible() {
    return this.clientConfig.hotelVisible;
  }

  public isFlightsVisible() {
    return this.clientConfig.flightVisible;
  }

  public isCompanyAreaEnabled() {
    return this.clientConfig.companyAreaEnabled;
  }

  public isCompanyBudgetsEnabled() {
    return this.clientConfig.budgetsActive;
  }

  public isCompanyCostCenterEnabled() {
    return this.clientConfig.costCenterActive;
  }

  public isCompanyProjectEnabled() {
    return this.getProjectEnablingState() !== "off";
  }

  public isCompanyVaultEnabled() {
    return this.clientConfig.vaultActive;
  }

  public isUniqueApproverFlow(): boolean {
    return this.isNavanApprovalFlow() || this.isBossApprovalFlow();
  }

  private parseCustomMessage(customMessage: string | undefined) {
    if (!customMessage) {
      return null;
    }

    try {
      const parsedCustomMessage = JSON.parse(customMessage);

      if (!parsedCustomMessage) {
        return [
          {
            children: [
              {
                text: customMessage,
              },
            ],
            type: "paragraph",
          },
        ] as string & Descendant[];
      }

      return parsedCustomMessage;
    } catch (error) {
      logger.error(`error parsing custom message: ${error}`);

      return [
        {
          children: [
            {
              text: customMessage,
            },
          ],
          type: "paragraph",
        },
      ] as string & Descendant[];
    }
  }

  public tagsAreCreatedByAdminsOnly() {
    return this.clientConfig.tagsAdminOnly;
  }

  private isNavanApprovalFlow(): boolean {
    return (
      this.clientConfig.approvalConfig.type ===
      ClientApprovalConfigType.NAVAN_SINGLE_APPROVER
    );
  }

  private isBossApprovalFlow(): boolean {
    return (
      this.clientConfig.approvalConfig.type ===
      ClientApprovalConfigType.BOSS_FLOW
    );
  }
}

export type ClientInfo = {
  activationStatus: ClientActivationStatus;
  bookingFeeOption: string;
  budgetsActive: boolean;
  clientSlug: string;
  clientToken: string;
  costCenterActive: boolean;
  createdAt: string;
  email: string;
  id: string;
  name: string;
  vaultActive: boolean;
};
