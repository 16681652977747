import {
  AdvancedCategory,
  BasicCategory,
  CarPolicyCategory,
  IntermediateCategory,
  SpecialCategory
} from "~/constants/enums";

import { StringTMap } from "~/types";

import { CarSearchCreationRequest } from "../dtos/car.dto";

export function buildCarCreationData(
  carSearchData: any
): CarSearchCreationRequest {
  const requestData: any = {};

  requestData.pickup_latitude = carSearchData.pickupLocation!.latitude;
  requestData.pickup_longitude = carSearchData.pickupLocation!.longitude;
  requestData.pickup_search = carSearchData.pickupLocation!.search;

  const [pickupHour, pickupMinute] = carSearchData.pickupTime.split(":");
  requestData.pickup_hour = parseInt(pickupHour, 0);
  requestData.pickup_minute = parseInt(pickupMinute, 0);
  requestData.pickup_date = carSearchData.pickupDate!.format("YYYY-MM-DD");

  const [dropoffHour, dropoffMinute] = carSearchData.dropoffTime.split(":");
  requestData.dropoff_hour = parseInt(dropoffHour, 0);
  requestData.dropoff_minute = parseInt(dropoffMinute, 0);
  requestData.dropoff_date = carSearchData.dropoffDate!.format("YYYY-MM-DD");

  requestData.diff_dropoff = !carSearchData.sameLocationDropoff;

  if (!carSearchData.sameLocationDropoff && carSearchData.dropoffLocation) {
    requestData.dropoff_latitude = carSearchData.dropoffLocation.latitude;
    requestData.dropoff_longitude = carSearchData.dropoffLocation.longitude;
    requestData.dropoff_search = carSearchData.dropoffLocation.search;
  }

  if (carSearchData.travelToken) {
    requestData.travel_token = carSearchData.travelToken;
  }

  return requestData;
}

export function getCarSmartripsCategory(
  sippCode: string
): CarPolicyCategory | null {
  if (doesBelongToCategory(sippCode, BasicCategory)) {
    return CarPolicyCategory.BASIC;
  }

  if (doesBelongToCategory(sippCode, IntermediateCategory)) {
    return CarPolicyCategory.INTERMEDIATE;
  }

  if (doesBelongToCategory(sippCode, AdvancedCategory)) {
    return CarPolicyCategory.ADVANCED;
  }

  if (doesBelongToCategory(sippCode, SpecialCategory)) {
    return CarPolicyCategory.SPECIAL;
  }

  return null;
}

function doesBelongToCategory(
  sippCode: string,
  category: StringTMap<string>
): boolean {
  const categoryArray = Object.values(category);
  return categoryArray.includes(sippCode.charAt(0));
}
