import { navigate } from "@reach/router";

import userService from "../apis/user.api";
import userConstants from "../constants/user.constant";

export const userActions = {
  login,
  logout,
  editUser,
  setUser
};

export default userActions;

function login(email, password, loadGeneralData) {
  return dispatch => {
    dispatch(request());

    userService.login(email, password).then(
      user => {
        dispatch(success(user));
        loadGeneralData();
        if (!user.capabilities.search) {
          navigate("/trips");
        } else {
          navigate("/");
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function setUser(user) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}

function logout() {
  void userService.logout();
  return { type: userConstants.LOGOUT };
}

function editUser(user) {
  return dispatch => {
    dispatch({ type: userConstants.EDIT_USERNAME, payload: user });
  };
}
