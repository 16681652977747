import React from "react";
import { Box, Text } from "@toolkit";
import DeletionDialog from "@shared/dialogs/DeletionDialog";
import { ExpenseReport, ExpenseReportWithUser } from "~/models/expense.model";
import { css } from "@styled-system/css";

type ReportType = ExpenseReport | ExpenseReportWithUser;

interface IProps {
  isOpen: boolean;
  isRemoving: boolean;
  report: ReportType | null;
  onClose(): void;
  onRemove(): void;
}

const ReportRemovalDialog: React.FC<IProps> = ({
  isOpen,
  isRemoving,
  report,
  onClose,
  onRemove
}) => {
  return (
    <DeletionDialog
      open={isOpen}
      title="Exclusão de relatorio de despesas"
      deleteButtonLabel="Excluir"
      isDeleting={isRemoving}
      message={!!report && <ReportRemovalMessage report={report} />}
      handleClose={onClose}
      handleDeletion={onRemove}
    />
  );
};

const ReportRemovalMessage: React.FC<{ report: ReportType }> = ({ report }) => {
  return (
    <Box>
      <Text fontSize={1} pb={12} css={css({ display: "block" })}>
        Deseja realmente excluir o relatório{" "}
        <strong>
          &quot;{report.description || "Relatório sem descrição"}&quot;
        </strong>
        ?
      </Text>
      <Text fontSize={1}>
        Ao excluir um relatório, todas as despesas no mesmo serão{" "}
        <strong>perdidas</strong>, já os adiantamentos serão removidos,
        permitindo a utilização em outros relatórios.
      </Text>
    </Box>
  );
};

export { ReportRemovalDialog };
