import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  header: {
    root: css({}),
  },
  main: {
    empty: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
    }),
    list: {
      item: {
        left: {
          badge: css({
            border: "none",
            lineHeight: "18px",
          }),
          description: css({
            color: theme.colors.gray[500],
            fontWeight: 600,
            lineHeight: "24px",
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "32px",
          }),
          text: css({
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }),
        },
        right: {
          count: css({
            alignItems: "center",
            display: "flex",
            color: theme.colors.gray[300],
            fontSize: "0.75rem",
            gap: "8px",
            marginRight: "8px",
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "8px",
          }),
        },
        root: css({
          alignItems: "center",
          backgroundColor: theme.colors.background.gray,
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          padding: "8px 16px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    loading: {
      root: css({
        alignItems: "center",
        color: theme.colors.gray[300],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "20px",
      fontWeight: 600,
      padding: "12px 0",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  }),
};
