import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  card: {
    divisor: css({
      backgroundColor: theme.colors.gray[100],
      border: "none",
      height: "1px",
      width: "100%",
    }),
    root: ({ isDisabled }: { isDisabled: boolean }) =>
      css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.875rem",
        gap: "24px",
        padding: "24px",
        transition: "all 0.15s ease-in-out",
        ...(isDisabled && {
          cursor: "default",
        }),
        ":hover": {
          borderColor: theme.colors.gray[300],
          ...(isDisabled && {
            borderColor: theme.colors.gray[100],
          }),
        },
      }),
  },
  info: {
    icon: css({
      color: theme.colors.gray[500],
      flexShrink: 0,
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      gap: "8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
    }),
  },
  price: {
    icon: css({
      color: theme.colors.green[500],
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      gap: "8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
    }),
  },
  root: ({ isDisabled }: { isDisabled: boolean }) =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginLeft: "24px",
      ...(isDisabled && {
        opacity: 0.75,
      }),
    }),
  seats: {
    icon: css({
      color: theme.colors.pink[500],
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      gap: "8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
    }),
  },
  vouchers: {
    icon: css({
      color: theme.colors.pink[500],
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      gap: "8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
    }),
  },
};
