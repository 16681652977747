import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { StContainedButton } from "@shared/buttons";
import { css } from "@styled-system/css";
import { Flex, Text, Box } from "@toolkit";
import { Input } from "@shared/inputs";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { useTagsManagementContext } from "./TagsManagement.context";
import Slide from "@material-ui/core/Slide";

const styles = {
  dialog: css({
    "& .MuiDialog-paperWidthSm": {
      width: 450
    }
  }),
  closeIcon: css({
    cursor: "pointer"
  }),
  btn: css({
    px: ["medium", 24],
    py: "xsmall"
  })
};

const AddTagDialog = ({ isOpen, handleClose }) => {
  const {
    addTagInput,
    handleInputChange,
    handleCreateTag
  } = useTagsManagementContext();
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Slide}
      onClose={handleClose}
      css={styles.dialog}
    >
      <Box p="large" pb={0} width={1}>
        <Flex
          pb={[3, 4]}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text fontWeight={"bold"} lineHeight="body">
            Adicionar etiqueta
          </Text>
          <CloseIcon onClick={handleClose} css={styles.closeIcon} />
        </Flex>
        <Box>
          <Input
            id="aaTagInput"
            name="addTagInput"
            label="Nome da etiqueta"
            value={addTagInput}
            onChange={handleInputChange}
          />
        </Box>
        <Flex justifyContent="flex-end" pt="large" pb={24}>
          <StContainedButton
            onClick={handleCreateTag}
            isFullWidth={false}
            css={styles.btn}
            disabled={!addTagInput}
          >
            Confirmar
          </StContainedButton>
        </Flex>
      </Box>
    </Dialog>
  );
};

export { AddTagDialog };
