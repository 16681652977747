import * as yup from "yup";

const mapRules = <T>(
  map: Record<string, unknown>,
  rule: T,
): Record<string, T> => {
  return Object.keys(map).reduce(
    (newMap, key) => ({ ...newMap, [key]: rule }),
    {},
  );
};

const offerTravelerSchema = yup.array().of(
  yup.object().shape({
    fullName: yup.string(),
    main: yup.number().required(),
    userToken: yup.string().required("Preencha o viajante."),
  }),
);

export const notEnoughTravelersSchema = yup.object().shape({
  offersTravelers: yup.lazy((map) =>
    yup.object(mapRules<typeof offerTravelerSchema>(map, offerTravelerSchema)),
  ),
});

export type NotEnoughTravelersSchema = yup.InferType<
  typeof notEnoughTravelersSchema
>;
