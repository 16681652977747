import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  doorOptions: {
    checked: boolean;
    id: string;
    label: string;
  }[];
  handleDoorsFilterChange: (event: any, checked: boolean) => void;
};

export const DoorsFilter: React.FC<Props> = ({
  doorOptions,
  handleDoorsFilterChange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Número de portas</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {doorOptions.map((doorOption) => (
          <CheckboxGroup.Item
            aria-label={doorOption.label}
            checked={doorOption.checked}
            css={styles.checkbox.group.item}
            id={doorOption.id}
            key={doorOption.id}
            onChange={(e) => {
              handleDoorsFilterChange(e, !doorOption.checked);
            }}
            value={doorOption.checked}
          >
            <span css={styles.text}>{doorOption.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
