import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers";

const middlewares = [thunk];

const isProdEnvironment = process.env.NODE_ENV === "prod";

if (!isProdEnvironment) {
  middlewares.push(logger);
}
const composeEnhancers = !isProdEnvironment
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
