import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    carrier: {
      link: css({
        color: theme.colors.pink[500],
        fontSize: "0.875rem",
      }),
      root: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
    },
    heading: {
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
      subtitle: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontSize: "1.125rem",
        fontWeight: 600,
      }),
    },
    root: ({ center }: { center?: boolean }) =>
      css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: "24px",
        justifyContent: center ? "center" : "unset",
        padding: "0 0 48px 0",
        width: "100dvw",
      }),
  },
  header: {
    left: {
      breadcrumb: ({ active }: { active: boolean }) =>
        css({
          alignItems: "center",
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "1px solid",
          borderColor: "transparent",
          color: active ? theme.colors.gray[700] : theme.colors.gray[300],
          cursor: "pointer",
          fontFamily: theme.fonts.inter,
          fontSize: "0.875rem",
          fontWeight: active ? 600 : "unset",
          transition: "all 0.15s ease-in-out",
          ":hover": {
            borderColor: theme.colors.gray[700],
            color: theme.colors.gray[700],
          },
        }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "12px",
        padding: "24px",
      }),
    },
    right: {
      button: css({
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[300],
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
        gap: "12px",
        padding: "24px",
        transition: "color 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
        },
        "& > :first-of-type": {
          "@media (max-width: 512px)": {
            display: "none",
          },
        },
      }),
      root: css({}),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100dvw",
  }),
};
