import React from "react";

import hotelPlaceholder from "~/apps/shared/assets/images/placeholders/hotel-placeholder.jpg";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const NoImageHotel: React.FC<Props> = ({ ...props }) => {
  return (
    <div css={styles.root} {...props}>
      <img alt="Hotel" css={styles.image} src={hotelPlaceholder} />
    </div>
  );
};
