import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  bottom: () =>
    StyleSheet.create({
      badge: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        gap: 4,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 8,
        marginLeft: 24,
      },
    }),
  root: () =>
    StyleSheet.create({
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
    }),
  top: () =>
    StyleSheet.create({
      description: {
        color: theme.colors.gray[700],
        flex: 1,
        fontSize: 12,
        fontWeight: 600 - 100,
      },
      icon: {
        alignItems: "center",
        aspectRatio: "1",
        color: theme.colors.gray[700],
        display: "flex",
        flexShrink: 0,
        height: 14,
        justifyContent: "center",
        pointerEvents: "auto",
        width: 14,
      },
      policy: {
        color: theme.colors.pink[500],
        fontSize: 12,
        fontWeight: 600 - 100,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 8,
      },
    }),
};
