import React from "react";

import { getBookingFailedReasonByType } from "~/apps/corporate/helpers/offer.helper";
import { Icon } from "~/apps/shared/components/icon/icon";
import { BOOKING_FAILED_REASON } from "~/apps/shared/constants";
import { CurrencyCode, ServiceType } from "~/apps/shared/constants/enums";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  bookingFailedReason: string;
  changedPrice: {
    currency: CurrencyCode;
    total: number;
  } | null;
  type: ServiceType;
};

export const ItineraryServiceBookingFailed: React.FC<Props> = ({
  bookingFailedReason,
  changedPrice,
  type,
}) => {
  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="no-symbol" />
      <span css={styles.text}>
        Falha na emissão. Motivo:{" "}
        {
          getBookingFailedReasonByType(type)?.[
            bookingFailedReason as keyof ReturnType<
              typeof getBookingFailedReasonByType
            >
          ]
        }{" "}
        {bookingFailedReason === BOOKING_FAILED_REASON.PRICE_CHANGE &&
        changedPrice
          ? `Novo preço: R$ ${toCurrency(
              changedPrice.total,
              2,
              changedPrice.currency,
            )}`
          : null}
      </span>
    </div>
  );
};
