import React from "react";

import { Link } from "@reach/router";

import { useLocation } from "../../hooks/use-location";
import { Collapse } from "../collapse/collapse";
import { Icon, Icons } from "../icon/icon";
import { styles } from "./styles";

type Props = {
  icon?: Icons;
  label: React.ReactNode;
  onChange: () => void;
  open: boolean;
};

export const NavigationMenu: React.FC<Props> = ({
  children,
  icon,
  label,
  onChange,
  open,
}) => {
  const isActive = !!open;

  return (
    <div css={styles.root}>
      <button
        css={styles.button.root({ isActive })}
        onClick={() => {
          onChange();
        }}
      >
        <div css={styles.button.left.root}>
          {icon ? (
            <Icon css={styles.button.left.icon} size={20} use={icon} />
          ) : null}
          <span css={styles.button.left.label}>{label}</span>
        </div>
        <Icon
          css={styles.button.right.icon({ isActive })}
          size={16}
          use="chevron-up"
        />
      </button>
      <Collapse expanded={open}>
        <ul css={styles.links}>{children}</ul>
      </Collapse>
    </div>
  );
};

type NavigationMenuItemProps = Omit<
  React.ComponentProps<typeof Link>,
  "children"
> & {
  icon?: Icons;
  label: string;
};

export const NavigationMenuItem = React.forwardRef<
  HTMLAnchorElement,
  NavigationMenuItemProps
>(({ icon, label, to, ...props }, ref) => {
  const { location } = useLocation();

  const hasIcon = !!icon;

  const isCurrent = location.pathname === to;
  const isActive = isCurrent;

  return (
    <Link
      css={styles.link.root({ hasIcon, isActive })}
      ref={ref as any}
      to={to}
      {...props}
    >
      <div css={styles.link.left.root}>
        {icon ? (
          <Icon css={styles.link.left.icon} size={20} use={icon} />
        ) : null}
        <span css={styles.link.left.label}>{label}</span>
      </div>
    </Link>
  );
});

NavigationMenuItem.displayName = "NavigationMenuItem";
