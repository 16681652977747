import React from "react";

import { CurrencyInput } from "~/apps/shared/components/currency-input/currency-input";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";

import { styles } from "./styles";

export const FlightMaxValueInput: React.FC<
  React.ComponentPropsWithoutRef<typeof CurrencyInput> & {
    error?: string;
  }
> = ({ error, ...props }) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Preço máximo por voo</span>
      <CurrencyInput css={styles.input} {...props} />
      <InputErrorMessage>{error}</InputErrorMessage>
      {/* <div>
        <span>Rotas Exceções (em breve)</span>
        <p>Especifique um valor limite de voo para uma dada rota.</p>
      </div> */}
    </div>
  );
};
