import { RecalculateCarOfferRequestAdditional } from "~/apps/corporate/dtos/car.dto";
import { AdditionalPriceInfo } from "~/apps/corporate/models/car.model";

import { CarAdditionalPresentation } from "./car-result.types";

export class CarResultAdditionalsSorter {
  public static sortCarAdditionalsByDisabledAndName(
    additionals: CarAdditionalPresentation[],
  ): CarAdditionalPresentation[] {
    return additionals.sort((a, b) => {
      if (a.disabled) {
        return -1;
      }

      if (b.disabled) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  }

  public static sortCarAdditionalsByPrice(
    additionals: CarAdditionalPresentation[],
  ): CarAdditionalPresentation[] {
    return additionals.sort((a, b) => {
      return a.price - b.price;
    });
  }
}

export type CarSupplierAdditionalsPresentationMapData = Partial<
  Pick<CarAdditionalPresentation, "details" | "icon" | "rate" | "shortDetails">
>;

export type CarSupplierAdditionalsPresentationMap<O> = {
  [K in keyof O]: CarSupplierAdditionalsPresentationMapData;
};

type CarSupplierAdditionalsCodesMap<O> = {
  [K in keyof O]: O[K] extends readonly string[] ? O[K] : string;
};

export abstract class CarResultHelper<O> {
  public readonly car: {
    contractDetails: {
      accessoryAdditionals: AdditionalPriceInfo[] | undefined;
      protectionAdditionals: AdditionalPriceInfo[] | undefined;
      serviceAdditionals: AdditionalPriceInfo[] | undefined;
    };
  };
  public readonly codesMap: CarSupplierAdditionalsCodesMap<O>;
  public readonly presentationMap: CarSupplierAdditionalsPresentationMap<O>;

  constructor({
    car,
    codesMap,
    presentationMap,
  }: {
    car: {
      contractDetails: {
        accessoryAdditionals: AdditionalPriceInfo[] | undefined;
        protectionAdditionals: AdditionalPriceInfo[] | undefined;
        serviceAdditionals: AdditionalPriceInfo[] | undefined;
      };
    };
    codesMap: CarSupplierAdditionalsCodesMap<O>;
    presentationMap: CarSupplierAdditionalsPresentationMap<O>;
  }) {
    this.car = car;
    this.codesMap = codesMap;
    this.presentationMap = presentationMap;
  }

  public buildAdditionalsToRecalculate(
    additional: CarAdditionalPresentation,
    selectedAdditionals: {
      category: string;
      code: string;
      quantity?: string | undefined;
    }[],
  ): RecalculateCarOfferRequestAdditional[] {
    const additionalsToRecalculate = (() => {
      const additionalExistsInCurrentAdditionals = selectedAdditionals.find(
        (a) => a.code === additional.code,
      );

      const isSelected = additional.selected;

      if (additionalExistsInCurrentAdditionals) {
        if (isSelected) {
          return selectedAdditionals.map((a) => {
            if (a.code === additional.code) {
              return this.formatAdditionalToRecalculate(additional);
            }

            return a;
          });
        }

        return selectedAdditionals.filter((a) => a.code !== additional.code);
      }

      return [
        ...selectedAdditionals,
        this.formatAdditionalToRecalculate(additional),
      ];
    })();

    const patchedAdditionalsToRecalculate = this.patchAdditionalsToRecalculate(
      additional,
      selectedAdditionals,
      additionalsToRecalculate,
    );

    return patchedAdditionalsToRecalculate;
  }

  private findCodesMapValueByKey<K extends keyof O>(
    key: K,
  ): CarSupplierAdditionalsCodesMap<O>[K] {
    return this.codesMap[key];
  }

  private findKeyByUnknownCode(code: string): keyof O | null {
    let key: keyof O | undefined;

    for (const k in this.codesMap) {
      const codesMapValue = this.findCodesMapValueByKey(k);
      if (Array.isArray(codesMapValue)) {
        if (codesMapValue.find((c) => c === code)) {
          key = k;
          break;
        }
      }

      if (codesMapValue === code) {
        key = k;
        break;
      }
    }

    if (!key) {
      return null;
    }

    return key;
  }

  private findPresentationByKey<K extends keyof O>(
    key: K,
  ): CarSupplierAdditionalsPresentationMap<O>[K] {
    return this.presentationMap[key];
  }

  public findPresentationByUnknownCode(
    code: string,
  ): CarSupplierAdditionalsPresentationMapData | null {
    const key = this.findKeyByUnknownCode(code);

    if (!key) {
      return null;
    }

    const presentation = this.findPresentationByKey(key);

    return presentation;
  }

  public abstract formatAccessoryAdditionals(): {
    additionals?: CarAdditionalPresentation[] | undefined;
  };

  public formatAdditionalToRecalculate(additional: {
    category: string;
    code: string;
    quantity?: number;
  }): RecalculateCarOfferRequestAdditional {
    return {
      category: additional.category,
      code: additional.code,
      ...(additional.quantity &&
        additional.quantity !== 0 && {
          quantity: additional.quantity.toString(),
        }),
    };
  }

  public abstract formatProtectionAdditionals(): {
    additionals?: CarAdditionalPresentation[] | undefined;
  };

  public getSelectedAdditionals(): {
    category: string;
    code: string;
    quantity?: string;
  }[] {
    const {
      contractDetails: {
        accessoryAdditionals,
        protectionAdditionals,
        serviceAdditionals,
      },
    } = this.car;

    if (
      !accessoryAdditionals ||
      !protectionAdditionals ||
      !serviceAdditionals
    ) {
      return [];
    }

    return Object.values({
      accessoryAdditionals,
      protectionAdditionals,
      serviceAdditionals,
    }).reduce(
      (prev, curr) => {
        return [
          ...prev,
          ...curr
            .filter((additional) => additional.selected)
            .map((additional) => ({
              category: additional.supplierCategory,
              code: additional.code,
              ...(additional.quantity &&
                additional.quantity !== 0 && {
                  quantity: additional.quantity.toString(),
                }),
            })),
        ];
      },
      [] as {
        category: string;
        code: string;
        quantity?: string;
      }[],
    );
  }

  public abstract formatServiceAdditionals(): {
    additionals?: CarAdditionalPresentation[] | undefined;
  };

  public groupPresentationByKeys<K extends keyof O>(
    keys: readonly K[],
  ): Record<K, CarSupplierAdditionalsPresentationMap<O>> {
    return Object.entries(this.presentationMap).reduce((prev, [key, curr]) => {
      if (!(keys as readonly string[]).includes(key)) {
        return prev;
      }

      return {
        ...prev,
        [key]: curr,
      };
    }, {} as Record<K, CarSupplierAdditionalsPresentationMap<O>>);
  }

  public isUnknownCodeWithinPossibleCodesByKeys<K extends keyof O>(
    code: string,
    keyOrKeys: K | K[],
  ): boolean {
    const keys: K[] = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];

    for (const key of keys) {
      const codesMapValue = this.findCodesMapValueByKey(key);

      if (Array.isArray(codesMapValue)) {
        for (const c of codesMapValue) {
          if (c === code) {
            return true;
          }

          continue;
        }

        continue;
      }

      if (codesMapValue === code) {
        return true;
      }
    }

    return false;
  }

  public patchAdditionalsToRecalculate(
    additional: CarAdditionalPresentation,
    selectedAdditionals: {
      category: string;
      code: string;
      quantity?: string | undefined;
    }[],
    additionalsToRecalculate: RecalculateCarOfferRequestAdditional[],
  ): RecalculateCarOfferRequestAdditional[] {
    return additionalsToRecalculate;
  }
}
