import React, { useEffect, useContext, useMemo } from "react";

import ProfileIcon from "@material-ui/icons/AccountCircle";
import { RouteComponentProps, navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import classnames from "classnames";
import { css } from "emotion";
import debounce from "lodash/debounce";
import { Moment } from "moment";
import { theme } from "smartrips-skin";

import { Box, Flex } from "@toolkit";

import {
  EXPENSE_REPORT_STATUS,
  EXPENSE_STATUS,
  MEDIA_QUERIES,
  EXPENSE_APPROVAL_STATUS,
  EXPENSES_CATEGORIES,
} from "@constants";
import PAGE_TITLES from "@constants/page-titles.constants";

import * as expensesHelper from "@helpers/expense.helper";

import { Expense } from "@models/expense.model";

import { AdministrativeInfo } from "@shared/administrative-info";

import { AltertBox, AltertTypeEnum } from "~/components/shared/altert-box";

import { TravelTabs } from "@components/shared/travel-tabs";

import { defaultTheme } from "../../../assets/styles/theme";
import { StOutlinedButton } from "../../shared/buttons";
import { ExpenseOutOfPolicyDialog } from "../../shared/expense-policy-dialogs/ExpenseOutOfPolicyDialog";
import Layout from "../../shared/Layout";
import { Column } from "../../shared/layout/Column";
import { Desktop, TabletAndMobile } from "../../shared/layout/Responsive";
import { Row } from "../../shared/layout/Row";
import PageTitle from "../../shared/PageTitle";
import SpinnerPortal from "../../shared/Spinner";
import { ExpenseDrawer } from "../ExpenseDrawer/ExpenseDrawer";
import { AdvancedExpenseItem } from "../shared/AdvancedExpenseItem/AdvancedExpenseItem";
import { ApprovalHistoryContainer } from "../shared/ApprovalHistory/ApprovalHistory.container";
import { CategoriesBreakdown } from "../shared/CategoriesBreakdown";
import { ExpensesListByDate } from "../shared/ExpensesListByDate";
import { InvoicesDownload } from "../shared/InvoicesDownload";
import { ReportSubNavbar } from "../shared/ReportSubNavbar";
import { ApprovalDeniedCard } from "./ApprovalDeniedCard/ApprovalDeniedCard";
import { ApprovalSentCard } from "./ApprovalSentCard/ApprovalSentCard";
import { DescriptionInput } from "./DescriptionInput/DescriptionInput";
import { ExpenseItem } from "./ExpenseItem/ExpenseItem";
import { InfoSideCard } from "./InfoSideCard/InfoSideCard";
import {
  ReportContext,
  ReportProvider,
  useReportApprovalFormContext,
} from "./report.context";
import { ReportDeletionDialog } from "./ReportDeletionDialog/ReportDeletionDialog";
import { ReportPaidCard } from "./ReportPaidCard/ReportPaidCard";

const styles = {
  container: css({
    width: "100%",
    paddingRight: "16px",
    paddingLeft: "16px",
    marginRight: "auto",
    marginLeft: "auto",
    ["@media (min-width: 1200px)"]: {
      maxWidth: 1250,
    },
  }),
  root: css({
    padding: "2.5rem 0 3.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: ".5rem 0 3.5rem",
    },
  }),
  innerContainer: css({
    display: "flex",
    flexDirection: "row",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      flexDirection: "column-reverse",
      paddingBottom: "2.5rem",
    },
  }),
  mainCard: css({
    [MEDIA_QUERIES.desktopBreakpoint]: {
      border: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 4,
      padding: "3rem 4.5rem 2rem 4.5rem",
      width: 740,
      height: "fit-content",
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "1.5rem 0 0 0",
      width: "100%",
      maxWidth: "100%",
    },
  }),
  sideCard: css({
    [MEDIA_QUERIES.desktopBreakpoint]: {
      maxWidth: 463.59,
      width: "100%",
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "0 !important",
    },
  }),
  buttonsRow: css({
    ["@media (max-width: 395px)"]: {
      justifyContent: "space-between",
    },
  }),
  button: css({
    padding: ".5rem 1.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: "150",
    },
  }),
  addToReportButton: css({
    padding: ".5rem 1.5rem",
    ["@media (min-width: 396px)"]: {
      marginLeft: "2rem",
    },
  }),
  travelInfo: css({
    paddingBottom: "4rem",
    justifyContent: "center",
    alignItems: "center",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingBottom: "1.5rem",
    },
  }),
  reportDescription: css({
    fontSize: 28,
    fontWeight: "bold",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      paddingTop: 26,
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      fontSize: 24,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 20,
    },
  }),
  period: css({
    [MEDIA_QUERIES.tabletBreakpoint]: {
      fontSize: 16,
      paddingBottom: ".5rem",
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
    },
  }),
  travelerInfo: css({
    paddingBottom: "2.5rem",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 16,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 14,
    },
  }),
  travelerAvatar: css({
    paddingRight: "1rem",
    ["& svg"]: {
      width: 45,
      height: 45,
      color: theme.colors.secondary,
    },
  }),
  noExpenses: css({
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    lineHeight: 1.5,
    color: defaultTheme.textColor,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingTop: "2rem",
      fontSize: 14,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingTop: "2rem",
      fontSize: 13,
    },
  }),
  avatarDiv: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      lineHeight: "20px",
    },
  }),
  pageTitle: css({
    fontSize: 24,
    fontWeight: "bolder",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    paddingTop: "2.5rem",
  }),
  disclaimer: css({
    fontSize: 13,
    color: defaultTheme.subTextColor,
    textAlign: "center",
    padding: "0 1rem 1rem",
    lineHeight: "1.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1rem .5rem",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    },
  }),
  addExpenseRow: css({
    justifyContent: "center",
    paddingBottom: "2.5rem",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingBottom: "1.5rem",
    },
  }),
  navigateToApprovalReviewButton: {
    button: css({
      width: "100%",
    }),
    root: css({
      marginBottom: "2.5rem",
    }),
  },
};

type Props = RouteComponentProps<{
  reportToken: string;
}>;

const Component: React.FC<Props> = ({ reportToken, ...props }) => {
  const { user } = useUser();

  const {
    administrativeInfo,
    expensesPolicies,
    fetchReport,
    handleCancelApprovalRequest,
    handleCloseExpenseDrawer,
    handleClosePolicyDialog,
    handleDownloadInvoices,
    handleExpenseRemoval,
    handleOutOfPolicyClick,
    handleSendReport,
    isDownloadingInvoices,
    isExpenseDrawerOpen,
    isLoggedUserTraveler,
    isProcessingReviewCancel,
    isSendingToApproval,
    loading,
    onAfterSave,
    openExpenseDrawer,
    report,
    reportApprovalStatus,
    reportApprovedTotalPrice,
    reportPeriod,
    reportRefundablePrice,
    reportTotalPrice,
    selectedExpenseToEdit,
    selectedExpenseToShowPolicy,
    selectedReceiptToEdit,
    traveler,
    updateDescription,
  } = useContext(ReportContext);

  const {
    canSubmitReportToApproval,
    isApprovalFormVisible,
  } = useReportApprovalFormContext();

  const { state } = props.location as any;
  const origin = state && state.origin ? state.origin : null;

  useEffect(() => {
    if (!reportToken) {
      return;
    }

    fetchReport(reportToken);
  }, []);

  const debouncUpdateDescription = debounce((description: string) => {
    updateDescription(description);
  }, 300);

  const handleOpenExpenseDrawer = () => openExpenseDrawer();

  const handleEditExpense = (selectedExpense: Expense) => () =>
    openExpenseDrawer(selectedExpense);

  const getFormattedReportPeriod = (start: Moment, end: Moment) => {
    return (
      start.format("ddd, DD MMM YYYY") + " - " + end.format("ddd, DD MMM YYYY")
    );
  };

  const showNavigateToApprovalReviewButton = useMemo(() => {
    if (!reportApprovalStatus || !user) {
      return false;
    }

    const isOnPendingStatus =
      reportApprovalStatus.status ===
        EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL ||
      reportApprovalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT;

    if (!isOnPendingStatus) {
      return false;
    }

    if (user.isMasterApprover()) {
      return true;
    }

    const currentStageApprovers = reportApprovalStatus.expenseApprovers.filter(
      (approver) => approver.stage === reportApprovalStatus.stage,
    );

    const isUserAmongCurrentStageApprovers = currentStageApprovers.some(
      (approver) => approver.userToken === user.getUserToken(),
    );

    return isUserAmongCurrentStageApprovers;
  }, [reportApprovalStatus, user]);

  if (report && reportApprovalStatus) {
    const isSelectedExpenseCreatedAuto =
      !!selectedExpenseToEdit &&
      selectedExpenseToEdit.status === EXPENSE_STATUS.CREATED_AUTO;

    const areAllExpensesCanceled = report.expenses.every(
      (expense) => !!expense.canceled,
    );

    const hasAtLeastOneRefundableExpense = report.expenses.some(
      (expense) => expense.status === EXPENSE_STATUS.CREATED_MANUAL,
    );

    const isReportEditable =
      report.status === EXPENSE_REPORT_STATUS.OPEN ||
      report.status === EXPENSE_REPORT_STATUS.DECLINED;

    const isReportDescriptionEditable =
      isLoggedUserTraveler && isReportEditable;

    const isSendButtonVisible =
      !areAllExpensesCanceled &&
      isReportEditable &&
      isLoggedUserTraveler &&
      (reportApprovalStatus.status === EXPENSE_APPROVAL_STATUS.NEED_APPROVAL ||
        reportApprovalStatus.status === EXPENSE_APPROVAL_STATUS.DECLINED);

    const isReportDone =
      expensesHelper.areAllExpensesTraveled(report.expenses) &&
      hasAtLeastOneRefundableExpense &&
      !areAllExpensesCanceled &&
      !!report.description.trim();

    const isSendButtonEnabled =
      (isReportDone && !isApprovalFormVisible) ||
      (canSubmitReportToApproval &&
        (isApprovalFormVisible ||
          reportApprovalStatus.status ===
            EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT));

    const lastRepliedApprover = expensesHelper.getLastRepliedApprover(
      reportApprovalStatus.expenseApprovers,
    );

    const isAdvancedExpenseRemovable =
      isReportEditable &&
      (report.status === EXPENSE_REPORT_STATUS.OPEN ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL);

    const visibleExpenses = expensesHelper.getVisibleExpenses(report);
    const sortedExpenses = expensesHelper.sortExpensesByDate(visibleExpenses);
    const expensesMapByDate = expensesHelper.getExpensesMapByDate(
      sortedExpenses,
    );

    const availableExpenses = report.expenses.filter(
      (expense) =>
        !expense.canceled &&
        expense.expenseCategory !== EXPENSES_CATEGORIES.FEE,
    );

    const showApprovalProcessDisclaimer =
      reportApprovalStatus.status ===
      EXPENSE_APPROVAL_STATUS.DOES_NOT_NEED_APPROVAL;

    const showEndOfTripDisclaimer =
      !showApprovalProcessDisclaimer &&
      !expensesHelper.areAllExpensesTraveled(report.expenses);

    const showDescriptionDisclaimer =
      !report.description.trim() &&
      !showApprovalProcessDisclaimer &&
      !showEndOfTripDisclaimer;

    const latestExpenseDate = expensesHelper.getLastExpenseDate(
      report.expenses,
    );

    const renderAppropriateSideCard = () => {
      if (report.status === EXPENSE_REPORT_STATUS.OPEN) {
        return (
          <div>
            <InfoSideCard
              expenses={availableExpenses}
              advancedExpenses={report.advancedExpenses}
              totalPrice={reportRefundablePrice}
              handleSend={handleSendReport}
              isSending={isSendingToApproval}
              isSendButtonEnabled={isSendButtonEnabled}
              isSendButtonVisible={isSendButtonVisible}
              isApprovalFormVisible={isApprovalFormVisible}
              isReportOpen={true}
            />
            {showApprovalProcessDisclaimer ? (
              <AltertBox
                type={AltertTypeEnum.WARNING}
                text="É necessário estar atrelado a um fluxo de aprovação de
              despesas para poder enviar um relatório para aprovação."
              />
            ) : null}
            {showEndOfTripDisclaimer ? (
              availableExpenses.length === 0 ? (
                <AltertBox
                  type={AltertTypeEnum.INFO}
                  text="O relatório de despesas só poderá ser enviado para aprovação
                    após o fim da viagem."
                />
              ) : (
                <AltertBox
                  type={AltertTypeEnum.WARNING}
                  text={`O relatório de despesas só poderá ser enviado para aprovação
              após o fim da viagem${
                latestExpenseDate
                  ? `, que está previsto para ${latestExpenseDate.format(
                      "dddd, D MMM YYYY",
                    )}`
                  : ""
              }.`}
                />
              )
            ) : null}
            {showDescriptionDisclaimer ? (
              <AltertBox
                type={AltertTypeEnum.WARNING}
                text="Para prosseguir é necessário preencher a descrição do relatório."
              />
            ) : null}
            <CategoriesBreakdown
              expenses={availableExpenses}
              firstColumnName="A solicitar"
            />
          </div>
        );
      } else if (
        report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT
      ) {
        return (
          <ApprovalSentCard
            handleCancelApprovalRequest={handleCancelApprovalRequest}
            isProcessingCancelation={isProcessingReviewCancel}
            status={report.status}
          />
        );
      } else if (report.status === EXPENSE_REPORT_STATUS.DECLINED) {
        return (
          <>
            <ApprovalDeniedCard
              approver={lastRepliedApprover}
              message={reportApprovalStatus.responseMessage}
              expenses={report.expenses}
              advancedExpenses={report.advancedExpenses}
              totalPrice={reportTotalPrice}
              handleSend={handleSendReport}
              isSending={isSendingToApproval}
              isSendButtonEnabled={isSendButtonEnabled}
              isSendButtonVisible={isSendButtonVisible}
              isApprovalFormVisible={isApprovalFormVisible}
            />
            <CategoriesBreakdown
              expenses={availableExpenses}
              showBothColumns={false}
            />
          </>
        );
      } else if (
        report.status === EXPENSE_REPORT_STATUS.CLOSED &&
        reportApprovalStatus.status === EXPENSE_APPROVAL_STATUS.PAID
      ) {
        return (
          <>
            <ReportPaidCard
              advancedExpenses={report.advancedExpenses}
              approver={lastRepliedApprover}
              expenses={availableExpenses}
              totalApprovedValue={reportApprovedTotalPrice}
              totalValue={reportRefundablePrice}
            />
            <CategoriesBreakdown
              expenses={availableExpenses}
              secondColumnName={"Aprovado"}
              showBothColumns={true}
            />
          </>
        );
      } else if (report.status === EXPENSE_REPORT_STATUS.CLOSED) {
        return (
          <>
            <InfoSideCard
              advancedExpenses={report.advancedExpenses}
              expenses={availableExpenses}
              handleSend={handleSendReport}
              isApprovalFormVisible={false}
              isSendButtonEnabled={false}
              isSendButtonVisible={false}
              totalPrice={reportRefundablePrice}
            />
            <CategoriesBreakdown expenses={availableExpenses} />
          </>
        );
      }
    };

    const renderTravelTabs = () => {
      const navigateToTravel = () =>
        navigate(`/travels/${report.travelToken}/itinerary`);

      if (!isLoggedUserTraveler) {
        return null;
      }

      if (!report.travelToken) {
        return null;
      }

      return (
        <Box mb="large">
          <Flex justifyContent="center">
            <TravelTabs selected="expenses" onTravelClick={navigateToTravel} />
          </Flex>
        </Box>
      );
    };

    const renderExpenseItem = (expense: Expense) => {
      const isEditable =
        !expense.canceled &&
        expense.status === EXPENSE_STATUS.CREATED_MANUAL &&
        isReportEditable;

      const isRefundable =
        !expense.canceled && expense.status !== EXPENSE_STATUS.CREATED_AUTO;

      return (
        <ExpenseItem
          expense={expense}
          handleDelete={handleExpenseRemoval(expense)}
          handleEdit={handleEditExpense(expense)}
          handleOutOfPolicyClick={handleOutOfPolicyClick(expense)}
          isEditable={isEditable}
          isRefundable={isRefundable}
          key={expense.expenseToken}
          policyInfo={expensesPolicies[expense.expenseToken]}
        />
      );
    };

    return (
      <Layout>
        <PageTitle
          title={PAGE_TITLES.EXPENSE_REPORT + " - " + report.description}
        />
        <ReportSubNavbar
          origin={origin}
          pageName="Relatório de despesa"
          reportName={report.description}
          reportToken={report.expenseReportToken}
        />
        <div className={styles.container}>
          <Desktop>
            <div className={styles.pageTitle}>
              <span>Relatório de despesa</span>
            </div>
          </Desktop>
          <div className={styles.root}>
            <div className={styles.innerContainer}>
              <Column className={styles.mainCard}>
                {renderTravelTabs()}
                <Column className={styles.travelInfo}>
                  <Desktop>
                    <p>
                      {getFormattedReportPeriod(
                        reportPeriod!.start,
                        reportPeriod!.end,
                      )}
                    </p>
                    {isReportDescriptionEditable ? (
                      <div style={{ paddingTop: 6, width: "100%" }}>
                        <DescriptionInput
                          value={report.description}
                          handleChange={debouncUpdateDescription}
                        />
                      </div>
                    ) : (
                      <p className={styles.reportDescription}>
                        {report.description}
                      </p>
                    )}
                  </Desktop>
                  <TabletAndMobile>
                    <p className={styles.period}>
                      {report.expenses.length > 0
                        ? getFormattedReportPeriod(
                            reportPeriod!.start,
                            reportPeriod!.end,
                          )
                        : null}
                    </p>
                    <p className={styles.reportDescription}>
                      {report.description}
                    </p>
                  </TabletAndMobile>
                </Column>
                {traveler ? (
                  <Row className={styles.travelerInfo}>
                    <Desktop>
                      <div className={styles.travelerAvatar}>
                        <ProfileIcon />
                      </div>
                    </Desktop>
                    <div className={styles.avatarDiv}>
                      <p>Viajante</p>
                      <p style={{ fontWeight: "bold" }}>{traveler.fullName}</p>
                    </div>
                  </Row>
                ) : null}
                {isReportEditable ? (
                  <Row className={styles.addExpenseRow}>
                    <StOutlinedButton
                      style={{ padding: ".5rem 1.5rem" }}
                      onClick={handleOpenExpenseDrawer}
                      color="primary"
                    >
                      Nova despesa
                    </StOutlinedButton>
                  </Row>
                ) : null}
                <Column>
                  {report.advancedExpenses.map((advancedExpense) => (
                    <AdvancedExpenseItem
                      key={advancedExpense.expenseAdvanceToken}
                      advancedExpense={advancedExpense}
                      handleDelete={handleExpenseRemoval(
                        advancedExpense,
                        "expense-advance",
                      )}
                      isRemovable={isAdvancedExpenseRemovable}
                    />
                  ))}
                  {!visibleExpenses.length ? (
                    <Row className={styles.noExpenses}>
                      Nenhuma despesa adicionada, <br /> utilize do botão acima
                      para adicionar despesas ao seu relatório
                    </Row>
                  ) : null}
                  {visibleExpenses.length ? (
                    <ExpensesListByDate expensesMap={expensesMapByDate}>
                      {renderExpenseItem}
                    </ExpensesListByDate>
                  ) : null}
                </Column>
              </Column>
              <div style={{ padding: "0 1rem" }} />
              <Column className={classnames("col-md-4", styles.sideCard)}>
                {renderAppropriateSideCard()}
                {showNavigateToApprovalReviewButton ? (
                  <Row className={styles.navigateToApprovalReviewButton.root}>
                    <StOutlinedButton
                      className={styles.navigateToApprovalReviewButton.button}
                      color="secondary"
                      onClick={() => {
                        navigate(
                          `/reports/${report.expenseReportToken}/approval-review`,
                        );
                      }}
                    >
                      Ir para a aprovação
                    </StOutlinedButton>
                  </Row>
                ) : null}
                <AdministrativeInfo
                  billingProfile={administrativeInfo.billingProfile}
                  boxProps={{
                    mb: [0, "large"],
                  }}
                  companyArea={administrativeInfo.companyArea}
                  costCenter={administrativeInfo.costCenter}
                  project={administrativeInfo.project}
                />
                {(report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT ||
                  report.status === EXPENSE_REPORT_STATUS.CLOSED) && (
                  <InvoicesDownload
                    isDownloading={isDownloadingInvoices}
                    handleDownload={handleDownloadInvoices}
                  />
                )}
                <ApprovalHistoryContainer
                  approvalStatus={reportApprovalStatus}
                />
              </Column>
            </div>
          </div>
          <ExpenseDrawer
            handleClose={handleCloseExpenseDrawer}
            onAfterSave={onAfterSave}
            open={isExpenseDrawerOpen}
            readOnly={!isReportEditable || isSelectedExpenseCreatedAuto}
            reportToken={report.expenseReportToken}
            selectedExpense={selectedExpenseToEdit}
            selectedReceipt={selectedReceiptToEdit}
            travelerToken={report.travelerToken}
          />
          <ReportDeletionDialog />
          <ExpenseOutOfPolicyDialog
            expense={selectedExpenseToShowPolicy}
            onClose={handleClosePolicyDialog}
            open={!!selectedExpenseToShowPolicy}
            policies={expensesPolicies}
          />
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <PageTitle title={PAGE_TITLES.EXPENSE_REPORT} />
        <SpinnerPortal visible={loading} />
        <ReportSubNavbar
          origin={origin}
          pageName="Relatório de despesa"
          reportName={""}
          reportToken={""}
        />
        <div className={styles.container} />
      </Layout>
    );
  }
};

export const Report: React.FC<Props> = ({ ...props }) => (
  <ReportProvider>
    <Component {...props} />
  </ReportProvider>
);
