export const isEveryValueTrue = (obj: Record<string, any>): boolean => {
  return (
    Object.values(obj).length > 0 &&
    Object.values(obj).every((value) => {
      if (typeof value === "object" && !!value) {
        return isEveryValueTrue(value);
      }

      return value === true;
    })
  );
};
