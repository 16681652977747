import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core/";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import cx from "classnames";
import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

import { EXPENSE_STATUS } from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { AdvancedExpense } from "@models/advanced-expense.model";
import { Expense } from "@models/expense.model";

import { CoinsTooltip } from "~/components/shared/coins-tooltip";

const styles = {
  expenseLine: css({
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ".15rem",
    marginBottom: ".5rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    fontSize: 14
  }),
  expenseDescription: css({
    maxWidth: "75%",
    lineHeight: "21px"
  }),
  expenseValue: css({
    width: "24%",
    textAlign: "right"
  }),
  approvedLine: css({
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: ".15rem",
    marginBottom: ".5rem",
    fontSize: 14
  }),
  approvedText: css({
    lineHeight: "21px",
    textAlign: "end",
    paddingRight: "2rem"
  }),
  totalValueRow: css({
    justifyContent: "space-between",
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: "0.5rem"
  }),
  cellHead: css({
    fontWeight: "bold",
    padding: 0,
    border: "none",
    paddingBottom: "0.5rem"
  }),
  cellBody: css({
    padding: 0,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    position: "relative"
  }),
  partialRefundIcon: css({
    position: "absolute",
    width: "1rem",
    height: "1.2rem",
    right: -20,
    color: defaultTheme.reds.lightRed
  })
};

interface Props {
  expensesData: Expense[];
  advancedExpensesData: AdvancedExpense[];
  type: "approval" | "payment";
  containerClasses?: string;
  showSecondColumn: boolean;
  firstColumnName: string;
  secondColumnName: string;
  children: (
    totalExpenseValue: number,
    approvedValue: number,
    totalAdvancedExpense: number
  ) => JSX.Element;
}

const ExpensesBreakdown = ({
  expensesData,
  advancedExpensesData,
  type,
  containerClasses,
  showSecondColumn,
  firstColumnName,
  secondColumnName,
  children: renderFn
}: Props) => {
  const filteredExpenses = expensesData.filter(
    expense =>
      expense.status !== EXPENSE_STATUS.CREATED_AUTO && !expense.canceled
  );

  const {
    totalValue: totalExpenseValue,
    approvedValue
  } = filteredExpenses.reduce(
    (acc, expense) => {
      const totalValueSum = (acc.totalValue += expensesHelper.convertCurrencyValue(
        expense
      ));
      const approvedValueSum =
        !!expense.approvedValue && expense.approvedValue > 0
          ? (acc.approvedValue += expense.approvedValue)
          : acc.approvedValue;

      return {
        totalValue: totalValueSum,
        approvedValue: approvedValueSum
      };
    },
    {
      totalValue: 0,
      approvedValue: 0
    }
  );

  const totalAdvancedExpense = advancedExpensesData.reduce((sum, curr) => {
    const value = curr.approvedValue || curr.requestedValue;
    return sum + value;
  }, 0);

  if (!filteredExpenses.length) {
    return (
      <div>
        <div
          style={{
            fontSize: 13,
            color: defaultTheme.subTextColor,
            paddingLeft: ".5rem",
            paddingBottom: "1rem"
          }}
        >
          <span>Nenhuma despesa reembolsável adicionada ao relatório</span>
        </div>
        <div style={{ marginTop: "1.5rem" }}>
          {renderFn(totalExpenseValue, approvedValue, totalAdvancedExpense)}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.cellHead}>Despesa</TableCell>
            <TableCell className={styles.cellHead} align="right">
              {firstColumnName}
            </TableCell>
            {showSecondColumn && (
              <TableCell className={styles.cellHead} align="right">
                {secondColumnName}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredExpenses.map(expense => (
            <TableRow key={expense.expenseToken} className={styles.expenseLine}>
              <TableCell
                align="left"
                component="th"
                scope="row"
                className={cx(styles.expenseDescription, styles.cellBody)}
              >
                {expense.description}
              </TableCell>
              <TableCell
                align="right"
                className={cx(styles.expenseValue, styles.cellBody)}
              >
                {expensesHelper.isForeignCurrencyExpense(expense) ? (
                  <CoinsTooltip
                    title={`Valor convertido -
                     Moeda: ${expense.currency} -
                    Taxa de conversão: ${utils.toCurrency(
                      expense.exchangeRate
                    )}`}
                    placement="right"
                    iconSize="12px"
                  />
                ) : null}{" "}
                {utils.toCurrency(expensesHelper.convertCurrencyValue(expense))}
              </TableCell>
              {showSecondColumn && (
                <TableCell
                  align="right"
                  className={cx(styles.expenseValue, styles.cellBody)}
                >
                  {utils.toCurrency(expense.approvedValue || 0)}
                  {expense.approvedValue !==
                    expensesHelper.convertCurrencyValue(expense) && (
                    <ArrowDownwardIcon className={styles.partialRefundIcon} />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ marginTop: "1.5rem" }}>
        {renderFn(totalExpenseValue, approvedValue, totalAdvancedExpense)}
      </div>
    </div>
  );
};

ExpensesBreakdown.defaultProps = {
  containerClasses: "",
  type: "approval",
  showSecondColumn: false,
  firstColumnName: "Solicitado",
  secondColumnName: "Aprovado"
} as Partial<Props>;

export { ExpensesBreakdown };
