import * as autosuggestApi from "@apis/autosuggest.api";
import * as expensesApi from "@apis/expense.api";
import * as expensesCategoriesApi from "@apis/expenses-category.api";
import {
  AirportAutosuggest,
  HotelAutosuggest
} from "@models/autosuggest.model";
import { ERROR } from "@constants";
import { CustomError, ImageFile } from "~/types";
import {
  CreateFlightExpenseRequest,
  CreateHotelExpenseRequest,
  CreateGeneralExpenseRequest,
  CreateExpenseResponse,
  CreateKilometerExpenseRequest
} from "@dtos/expenses.dto";
import { KilometerLocation } from "./ExpenseDrawer.types";
import {
  CalculateRouteDistanceAndValueResponseBody,
  CalculateRouteDistanceAndValueRequestBody
} from "@dtos/expense-approval";
import { getMapZoom } from "@helpers/expense.helper";

export async function getAiportAutosuggest(
  search: string
): Promise<{ data?: AirportAutosuggest[]; error?: CustomError }> {
  const result: { data?: AirportAutosuggest[]; error?: CustomError } = {};

  try {
    result.data = await autosuggestApi.getAirports(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    }
  }

  return result;
}

export async function getHotelAutosuggest(
  search: string
): Promise<{ data?: HotelAutosuggest[]; error?: CustomError }> {
  const result: { data?: HotelAutosuggest[]; error?: CustomError } = {};

  try {
    result.data = await autosuggestApi.getHotelsSuggestions(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    }
  }

  return result;
}

export async function getImageUploadUrl(
  filetype: string,
  filename: string
): Promise<{ data?: string; error?: CustomError }> {
  const result: { data?: string; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getFileUploadUrl({ filetype, filename });
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_IMAGE_UPLOAD_URL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_IMAGE_UPLOAD_URL_ERROR;
    }
  }

  return result;
}

export async function uploadImage(
  signedUrl: string,
  file: ImageFile
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensesApi.uploadImageFile(signedUrl, file);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_IMAGE_UPLOAD_URL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_IMAGE_UPLOAD_URL_ERROR;
    }
  }

  return result;
}

export async function createNewExpense(
  expenseData:
    | CreateFlightExpenseRequest
    | CreateHotelExpenseRequest
    | CreateKilometerExpenseRequest
    | CreateGeneralExpenseRequest
): Promise<{ data?: CreateExpenseResponse; error?: CustomError }> {
  const result: { data?: CreateExpenseResponse; error?: CustomError } = {};

  try {
    result.data = await expensesApi.createNewExpense(expenseData);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_CREATION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_CREATION_ERROR;
    }
  }

  return result;
}

export async function updateExpense(
  expenseData:
    | CreateFlightExpenseRequest
    | CreateHotelExpenseRequest
    | CreateKilometerExpenseRequest
    | CreateGeneralExpenseRequest,
  expenseToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensesApi.updateExpense(expenseData, expenseToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_UPDATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_UPDATE_ERROR;
    }
  }

  return result;
}

export async function getCalculatedRouteDistanceAndValue(
  originLocation: KilometerLocation,
  destinationLocation: KilometerLocation
): Promise<{
  data?: CalculateRouteDistanceAndValueResponseBody;
  error?: CustomError;
}> {
  const result: {
    data?: CalculateRouteDistanceAndValueResponseBody;
    error?: CustomError;
  } = {};

  try {
    const dto: CalculateRouteDistanceAndValueRequestBody = {
      origin_latitude: originLocation.latitude,
      origin_longitude: originLocation.longitude,
      destination_latitude: destinationLocation.latitude,
      destination_longitude: destinationLocation.longitude
    };

    result.data = await expensesApi.getCalculatedRouteDistanceAndValue(dto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CALCULATE_ROUTE_DISTANCE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CALCULATE_ROUTE_DISTANCE_ERROR;
    }
  }

  return result;
}

export async function getStaticMapReceipt(
  routeDirections: string,
  distance: number
): Promise<{ data?: ImageFile; error?: CustomError }> {
  const result: { data?: ImageFile; error?: CustomError } = {};

  try {
    const zoom = getMapZoom(distance);

    const staticMapParams = {
      zoom,
      size: "500x600",
      key: GOOGLE_MAPS_API_KEY
    };
    result.data = await expensesApi.getGoogleStaticMapImage(
      routeDirections,
      staticMapParams
    );
  } catch (error) {
    result.error = ERROR.UNEXPECTED_GENERATE_KILOMETER_RECEIPT_ERROR;
  }

  return result;
}

export async function getKilometerExpenseCreationPermission(): Promise<{
  data?: boolean;
}> {
  const result: { data?: boolean } = {};

  try {
    const response = await expensesApi.getKilometerExpensePermission();
    result.data = response.isKilometerExpensesAllowed;
  } catch (error) {
    result.data = false;
  }

  return result;
}

export async function getExpenseCategoriesOptions(): Promise<{
  data?: any[];
  error?: CustomError;
}> {
  const result: {
    data?: any[];
    error?: CustomError;
  } = {};

  try {
    result.data = await expensesCategoriesApi.getExpenseCategoriesOptions();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR;
    }
  }

  return result;
}
