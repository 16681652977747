import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  amenity: css({
    alignItems: "center",
    color: theme.colors.gray[700],
    display: "flex",
    gap: "8px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.5rem",
    fontWeight: 600,
  }),
};
