import React from "react";

import Collapse from "@material-ui/core/Collapse";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { UserModel } from "@models/user.model";

import { StContainedButton, StOutlinedButton } from "@shared/buttons";
import { Input } from "@shared/inputs";

const styles = {
  input: css({
    [".MuiOutlinedInput-multiline"]: {
      padding: "0 !important",
      marginBottom: "medium",
      fontSize: [1, 1, 2]
    }
  }),
  button: css({
    py: "xsmall",
    px: ["medium", "medium", "large"],
    maxHeight: 34,
    minWidth: 120,
    fontSize: 1
  })
};

interface IProps {
  traveler: UserModel;
  loadingDeny: boolean;
  declineMessage: string;
  isDeclineFormVisible: boolean;
  handleChangeDeclineMessage(e: React.ChangeEvent<HTMLInputElement>): void;
  handleCancelDeny(): void;
  handleProcessDeny(): void;
}

const DeclineFormPresentational: React.FC<IProps> = ({
  traveler,
  loadingDeny,
  declineMessage,
  isDeclineFormVisible,
  handleChangeDeclineMessage,
  handleCancelDeny,
  handleProcessDeny
}) => {
  return (
    <Box>
      <Collapse in={isDeclineFormVisible}>
        <Box
          p="24px 20px"
          borderBottom="1px solid"
          borderColor="border"
          borderRadius={0}
        >
          <Box mb="medium" lineHeight="21px">
            <Text fontSize={0}>
              Adicione uma mensagem para deixar{" "}
              <strong>{traveler.fullName!} </strong>
              sabendo por que seu pedido foi negado.
              <Text color={defaultTheme.subTextColor}>(opcional)</Text>
            </Text>
          </Box>
          <Input
            id="message"
            name="message"
            value={declineMessage}
            onChange={handleChangeDeclineMessage}
            disabled={loadingDeny}
            rows={4}
            rowsMax={6}
            multiline={true}
            css={styles.input}
            inputProps={{
              style: { width: "91%" }
            }}
          />
        </Box>
      </Collapse>
      {isDeclineFormVisible && (
        <Box p="24px 20px">
          <Flex alignItems="center" justifyContent="center">
            <StOutlinedButton
              onClick={handleCancelDeny}
              css={styles.button}
              disabled={loadingDeny}
              loading={loadingDeny}
            >
              Cancelar
            </StOutlinedButton>
            <Box px="24px" />
            <StContainedButton
              color="secondary"
              onClick={handleProcessDeny}
              isFullWidth={false}
              css={styles.button}
              disabled={loadingDeny}
              loading={loadingDeny}
            >
              Negar
            </StContainedButton>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export { DeclineFormPresentational };
