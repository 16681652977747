import React from "react";
import Skeleton from "react-loading-skeleton";

import { Collapse } from "~/apps/shared/components/collapse/collapse";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  description: string;
  isExpanded: boolean;
  isResolved: boolean;
  title: React.ReactNode;
  toggleExpanded: () => void;
};

export const PendenciesAccordion: React.FC<Props> = ({
  children,
  description,
  isExpanded,
  isResolved,
  title,
  toggleExpanded,
}) => {
  return (
    <div css={styles.root({ isResolved })}>
      <button
        css={styles.header.root({})}
        onClick={() => {
          toggleExpanded();
        }}
      >
        <div css={styles.header.container}>
          <div css={styles.header.left.root}>
            <span css={styles.header.left.title}>{title}</span>
            <span css={styles.header.left.description}>{description}</span>
          </div>
          <div css={styles.header.right.root}>
            <Icon
              css={styles.header.right.chevron}
              size={16}
              use={isExpanded ? "chevron-up" : "chevron-down"}
            />
          </div>
        </div>
        {isExpanded ? <hr css={styles.divisor} /> : null}
      </button>
      <Collapse expanded={isExpanded}>
        <div css={styles.body.root}>{children}</div>
      </Collapse>
    </div>
  );
};

export const PendenciesAccordionSkeleton: React.FC = () => {
  return (
    <div css={styles.root({})}>
      <div css={styles.header.root({ isDisabled: true })}>
        <div css={styles.header.container}>
          <div css={styles.header.left.root}>
            <Skeleton height="18px" width="144px" />
            <Skeleton height="14px" width="256px" />
          </div>
          <div css={styles.header.right.root}>
            <Icon
              css={styles.header.right.chevron}
              size={16}
              use="chevron-down"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
