import React, { useEffect } from "react";

import { Box, Text } from "@toolkit";

import { HeaderContainer } from "../shared/HeaderContainer";
import { RealTimeDatepicker } from "./Datepicker";
import { RealTimeMap } from "./real-time-map/real-time-map";
import {
  useRealTimeTravels,
  RealTimeTravelsProvider,
} from "./real-time-travels.context";
import { TableContainer as RealTimeTravelsTable } from "./Table/table.container";

const Component: React.FC = () => {
  const {
    endDate,
    getRealTimeTravels,
    handleChangeRangeDatePickerFocus,
    handleRangeDateChange,
    handleSelect,
    rangeFocus,
    startDate,
    travelsByCity,
  } = useRealTimeTravels();

  useEffect(() => {
    if (startDate && endDate) {
      void getRealTimeTravels();
    }
  }, [startDate, endDate]);

  return (
    <Box mt={[0, 12]} px={12}>
      <HeaderContainer>
        <Box>
          <Text fontSize={24} fontWeight="bold">
            Viagens em tempo real
          </Text>
        </Box>
        <Box height={[42, 42, 0]} />
      </HeaderContainer>
      <Box height="max-content" overflow="hidden">
        <RealTimeDatepicker
          endDate={endDate}
          focusedInput={rangeFocus}
          handleChange={handleRangeDateChange}
          handleFocusChange={handleChangeRangeDatePickerFocus}
          startDate={startDate}
        />
        <Box flex={1}>
          <RealTimeMap
            handleSelect={handleSelect}
            travelsByCity={travelsByCity}
          />
        </Box>
        <RealTimeTravelsTable />
      </Box>
      <Box mb={80} />
    </Box>
  );
};

export const RealTimeTravels: React.FC = () => (
  <RealTimeTravelsProvider>
    <Component />
  </RealTimeTravelsProvider>
);
