import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  ItineraryCarSegment,
  ItineraryCarSegmentSkeleton,
} from "~/apps/corporate/components/itinerary-car-segment/itinerary-car-segment";
import { CarOffer } from "~/apps/corporate/models/car.model";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  car: CarOffer;
  daysInterval: number;
  diffDropoff: boolean;
  handleOpenOutOfPolicyDialog: () => void;
  handleShowOnMap: () => void;
  offerLink: string;
};

export const CarCard: React.FC<Props> = ({
  car,
  daysInterval,
  diffDropoff,
  handleShowOnMap,
  handleOpenOutOfPolicyDialog,
  offerLink,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 1024;

  return (
    <div css={styles.root}>
      <ItineraryCarSegment
        contract={{
          features: {
            ali: car.contractDetails.features.ali,
            freeCancelation: Boolean(car.contractDetails.freeCancelationUntil),
            ldw: car.contractDetails.features.ldw,
            unlimitedKm: car.contractDetails.features.unlimitedKm,
          },
        }}
        diffDropOff={diffDropoff}
        dropOff={car.dropoff}
        handleShowOnMap={!isMobile ? handleShowOnMap : undefined}
        model={{
          description: car.carDetails.modelDescription,
          features: {
            abs: car.carDetails.features.abs,
            air: car.carDetails.features.air,
            airbag: car.carDetails.features.airbag,
            baggages: car.carDetails.features.baggages,
            doors: car.carDetails.features.doors
              ? car.carDetails.features.doors
              : undefined,
            passengers: car.carDetails.features.passengers,
            steering: car.carDetails.features.steering
              ? car.carDetails.features.steering
              : undefined,
            transmission: car.carDetails.features.transmission
              ? car.carDetails.features.transmission
              : undefined,
          },
          image: car.carDetails.image,
          title: car.carDetails.modelTitle,
        }}
        selectedAdditionals={[
          ...(car.contractDetails.accessoryAdditionals
            ? car.contractDetails.accessoryAdditionals
            : []),
          ...(car.contractDetails.protectionAdditionals
            ? car.contractDetails.protectionAdditionals
            : []),
          ...(car.contractDetails.serviceAdditionals
            ? car.contractDetails.serviceAdditionals
            : []),
        ].filter((additional) => additional.selected)}
        pickUp={car.pickup}
      />
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          {car.outOfPolicy ? (
            <OutOfPolicyBadge
              onClick={() => {
                handleOpenOutOfPolicyDialog();
              }}
            />
          ) : null}
          <img
            alt={`Logo da ${car.rentalDetails.name}`}
            height={24}
            src={car.rentalDetails.logo}
          />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.price.root}>
            <span css={styles.right.bottom.price.label}>
              Total para {daysInterval} dias:
            </span>
            <div css={styles.right.bottom.price.value}>
              {toCurrency(car.contractDetails.finalPriceInfo.netTotal)}
            </div>
          </div>
          <a
            css={styles.right.bottom.link({ disabled: false })}
            href={offerLink}
            target="_blank"
            rel="noreferrer"
          >
            Ver mais
          </a>
        </div>
      </div>
    </div>
  );
};

export const CarCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <ItineraryCarSegmentSkeleton />
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          <Skeleton height="24px" width="80px" />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.price.root}>
            <Skeleton height="14px" width="96px" />
            <Skeleton height="24px" width="128px" />
          </div>
          <div css={styles.right.bottom.link({ disabled: true })}>Ver mais</div>
        </div>
      </div>
    </div>
  );
};
