import { css } from "@styled-system/css";

export const styles = {
  root: ({ color }: { color: string }) =>
    css({
      color,
      fontSize: "0.75rem",
      fontWeight: 600,
    }),
};
