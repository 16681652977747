import React from "react";

import { ITagStatus } from "sm-types/common";

import { styles } from "./styles";

const OFFER_TAGS: {
  [key in keyof ITagStatus]: string;
} = {
  pendingApproval: "pendingApproval",
  preReserved: "preReserved",
  preReserving: "preReserving",
  preReserveFailed: "preReserveFailed",
  preReserveExpired: "preReserveExpired",
  negotiated: "negotiated"
} as const;

const OFFER_TAGS_COLOR = {
  [OFFER_TAGS.pendingApproval]: "#C354FF",
  [OFFER_TAGS.preReserved]: "#194EC0",
  [OFFER_TAGS.preReserving]: "#FA831B",
  [OFFER_TAGS.preReserveFailed]: "#FA831B",
  [OFFER_TAGS.preReserveExpired]: "#FA831B",
  [OFFER_TAGS.negotiated]: "#FA831B"
} as const;

const OFFER_TAGS_TRANSLATED = {
  [OFFER_TAGS.pendingApproval]: "Pendente de aprovação",
  [OFFER_TAGS.preReserved]: "Pré-reservado",
  [OFFER_TAGS.preReserving]: "Pré-reservando",
  [OFFER_TAGS.preReserveFailed]: "Falha na pré-reserva",
  [OFFER_TAGS.preReserveExpired]: "Pré-reserva expirada",
  [OFFER_TAGS.negotiated]: "Negociada"
} as const;

type Props = {
  tags: ITagStatus;
};

export const OfferCardTagsMap: React.VFC<Props> = ({ tags }) => {
  return (
    <div css={styles.root}>
      {tags.pendingApproval ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.pendingApproval,
            border: `1px solid ${OFFER_TAGS_COLOR.pendingApproval}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.pendingApproval}
        </span>
      ) : null}
      {tags.preReserved ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.preReserved,
            border: `1px solid ${OFFER_TAGS_COLOR.preReserved}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.preReserved}
        </span>
      ) : null}
      {tags.preReserving ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.preReserving,
            border: `1px solid ${OFFER_TAGS_COLOR.preReserving}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.preReserving}
        </span>
      ) : null}
      {tags.preReserveFailed ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.preReserveFailed,
            border: `1px solid ${OFFER_TAGS_COLOR.preReserveFailed}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.preReserveFailed}
        </span>
      ) : null}
      {tags.preReserveExpired ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.preReserveExpired,
            border: `1px solid ${OFFER_TAGS_COLOR.preReserveExpired}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.preReserveExpired}
        </span>
      ) : null}
      {tags.negotiated ? (
        <span
          css={styles.tag}
          style={{
            color: OFFER_TAGS_COLOR.negotiated,
            border: `1px solid ${OFFER_TAGS_COLOR.negotiated}`
          }}
        >
          {OFFER_TAGS_TRANSLATED.negotiated}
        </span>
      ) : null}
    </div>
  );
};
