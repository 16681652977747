import React, { useMemo } from "react";

import { Link } from "@reach/router";
import { css } from "@styled-system/css";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import moment from "moment";

import { RealTimeTravel } from "~/models/analytics.model";

import { useRealTimeTravels } from "../real-time-travels.context";
import { TableColumn } from "./table.models";
import { TablePresentational } from "./table.presentational";

const styles = {
  link: css({
    color: "primary",
  }),
};

const TableContainer = () => {
  const { clientConfig } = useClientConfig();

  const { travels, travelsByCity, selectedCity, status } = useRealTimeTravels();

  const companyAreaDisplay = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : "";

  const travelsByCityCasted = travelsByCity as {
    [key: string]: RealTimeTravel[];
  };

  const data = useMemo(() => {
    if (selectedCity) {
      return travelsByCityCasted[selectedCity].map(
        (travel: RealTimeTravel) => ({
          billingProfileName: travel.billingProfileName,
          bookerToken: travel.bookerToken,
          companyAreaName: travel.companyAreaName,
          costCenterName: travel.costCenterName,
          destinationCity: travel.destinationCity,
          originCity: travel.originCity,
          travelEnd: moment(travel.travelEnd).format("DD/MM/YYYY"),
          travelerName: travel.travelerName,
          travelerToken: travel.travelerToken,
          travelName: travel.travelName,
          travelStart: moment(travel.travelStart).format("DD/MM/YYYY"),
          travelToken: travel.travelToken,
        }),
      );
    }

    return [];
  }, [travelsByCityCasted, selectedCity]);

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        Header: "Nome da viagem",
        accessor: "travelName",
        // eslint-disable-next-line react/display-name
        Cell: ({ row }: any) => (
          <Link
            css={styles.link}
            to={`/travels/${row.original.travelToken}/itinerary`}
          >
            {row.values.travelName}
          </Link>
        ),
      },
      {
        Header: "Data de ida",
        accessor: "travelStart",
      },
      {
        Header: "Data de volta",
        accessor: "travelEnd",
      },
      {
        Header: "Viajante",
        accessor: "travelerName",
      },
      {
        Header: "Origem",
        accessor: "originCity",
      },
      {
        Header: "Destino",
        accessor: "destinationCity",
      },
      {
        Header: "Perfil de cobrança",
        accessor: "billingProfileName",
      },
      {
        Header: "Centro de custo",
        accessor: "costCenterName",
      },
      {
        Header: companyAreaDisplay || "Área",
        accessor: "companyAreaName",
      },
    ],
    [],
  );

  return (
    <TablePresentational
      columns={columns}
      data={data}
      noTravels={!travels.length}
      status={status}
    />
  );
};

export { TableContainer };
