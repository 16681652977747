import React from "react";

import { DialogProps } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { css } from "emotion";

import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  container: css({
    minWidth: 500,
    minHeight: 300,
    maxWidth: "100%",
    ["@media (max-width: 767px)"]: {
      margin: 20,
      minWidth: 300
    }
  }),
  title: css({
    fontSize: "20px",
    fontFamily: "'Open sans', sans-serif",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 400px)"]: {
      fontSize: 16
    }
  }),
  actionsContainer: css({
    flex: "1 1 auto",
    padding: "0.5rem 1.5rem",
    overflowY: "auto"
  })
};

type BaseDialogProps = DialogProps & {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  children: React.ReactNode;
};

function BaseDialog({
  open,
  onClose,
  title,
  children,
  className,
  ...rest
}: BaseDialogProps) {
  return (
    <Dialog
      classes={{
        paper: className || styles.container
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      {...rest}
    >
      {title ? (
        <DialogTitle>
          <span className={styles.title}>{title}</span>
        </DialogTitle>
      ) : null}
      {children}
    </Dialog>
  );
}

function BaseDialogContent({ children }) {
  return <DialogContent>{children}</DialogContent>;
}

function BaseDialogActions({ children }) {
  return <footer className={styles.actionsContainer}>{children}</footer>;
}

BaseDialog.Content = BaseDialogContent;
BaseDialog.Actions = BaseDialogActions;

export default BaseDialog;
