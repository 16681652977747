import { getAuthorizationHeader } from "~/helpers/user.helper";

import {
  IGetBookingTargetsResponseDto,
  IGetUserBookerTargetsResponseDto,
  ISearchBookerTargetToAddResponseDto,
  IgetReserveBytravelerTokenParams,
  IReserve
} from "~/dtos/booker-target.dto";

import {
  IBookerTargetToAdd,
  IBookingTarget
} from "~/models/booker-target.model";

import { api } from "./interceptors";

export function searchBookerTargetsToAdd(
  search: string,
  userToken: string
): Promise<IBookerTargetToAdd[]> {
  return api
    .request<ISearchBookerTargetToAddResponseDto>({
      method: "GET",
      url: "/booking/booker-targets/add-search",
      params: { search, booker_token: userToken },
      headers: getAuthorizationHeader()
    })
    .then(({ data }) =>
      data.map(item => ({
        email: item.email,
        fullName: item.full_name,
        userToken: item.user_token,
        alreadyTarget: item.already_target
      }))
    );
}

export function getUserBookerTargets(
  userToken: string
): Promise<IBookerTargetToAdd[]> {
  return api
    .request<IGetUserBookerTargetsResponseDto>({
      method: "GET",
      url: `/booking/booker-targets/${userToken}`,
      headers: getAuthorizationHeader()
    })
    .then(({ data }) =>
      data.map(item => ({
        email: item.email,
        fullName: item.full_name,
        userToken: item.user_token,
        alreadyTarget: true
      }))
    );
}

export function getTravelBookingTargets(
  search: string
): Promise<IBookingTarget[]> {
  return api
    .request<IGetBookingTargetsResponseDto>({
      method: "GET",
      url: "/booking/booker-targets/book-search",
      params: { search },
      headers: getAuthorizationHeader()
    })
    .then(({ data }) =>
      data.map(item => ({
        email: item.email,
        fullName: item.full_name,
        userToken: item.user_token,
        allowed: item.allowed
      }))
    );
}

export function getReserveBytravelToken({
  travelToken,
  outbound_route,
  flightDate,
  cia
}: IgetReserveBytravelerTokenParams): Promise<IReserve> {
  const result = api
    .request({
      method: "GET",
      url: `/booking/travels/${travelToken}/check-flight-reservation?route=${outbound_route}&date=${flightDate}&cia=${cia}`,
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
  return result;
}
