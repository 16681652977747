import React, { useState, ReactNode, useMemo, ChangeEvent } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { ALERT_TYPES, PROJECT_ENABLING_STATE } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { ProjectItem } from "@models/projects.model";

import { CustomError } from "~/types";

import * as projectsServices from "./projects.service";
import { ProjectForm, ProjectEnablingState } from "./projects.types";

interface Props {
  children: ReactNode;
}

interface State {
  isFormOpen: boolean;
  isInactiveDialogOpen: boolean;
  isSubmitting: boolean;
  loading: boolean;
  nameFilter: string;
  projects: ProjectItem[];
  selectedTab: string;
  selectedToEdition: ProjectItem | null;
  selectedToInactivate: ProjectItem | null;
}

interface Selectors {
  visibleProjects: ProjectItem[];
}

interface ProjectsActions {
  activeProject: (project: ProjectItem) => void;
  closeForm: () => void;
  closeInactivationDialog: () => void;
  fetchProjects: () => void;
  handleChangeProjectState: (e: ChangeEvent<HTMLInputElement>) => void;
  handleTabChange: (value: string) => void;
  handleToggleProjectState: (e: ChangeEvent<HTMLInputElement>) => void;
  inactiveProject: () => void;
  openForm: () => void;
  openProjectEdition: (project: ProjectItem) => void;
  saveProject: (formData: ProjectForm) => void;
  selectProjectToInactivate: (project: ProjectItem) => void;
  setNameFilter: (search: string) => void;
}

type ContextProps = State & Selectors & ProjectsActions;

const initialState: State = {
  isFormOpen: false,
  isInactiveDialogOpen: false,
  isSubmitting: false,
  loading: false,
  nameFilter: "",
  projects: [],
  selectedTab: "active",
  selectedToEdition: null,
  selectedToInactivate: null,
};

const ProjectsContext = React.createContext({} as ContextProps);

const { Provider, Consumer } = ProjectsContext;

const ProjectsProvider = ({ children }: Props) => {
  const { showSnackMessage } = useApplication();
  const { clientConfig, handleUpdateProjectEnabled } = useClientConfig();

  const [state, setState] = useState(initialState);

  const fetchProjects = async () => {
    setLoading();

    const result = await projectsServices.listProjectsByClient();

    if (!result.error) {
      setState((prevState) => ({
        ...prevState,
        projects: result.data!,
        loading: false,
      }));
    } else {
      setErrorState(result.error);
    }
  };

  const toggleProject = async (
    projectToken: string,
    toggleTo: "active" | "inactive",
  ) => {
    const { error } = await projectsServices.toggleProjectActiveStatus(
      projectToken,
    );

    if (!error) {
      const projects = state.projects.map((project) => {
        if (project.projectToken === projectToken) {
          project.active = toggleTo === "active" ? true : false;
        }

        return project;
      });

      setState((prevState) => ({
        ...prevState,
        projects,
        isInactiveDialogOpen: false,
        selectedToInactivate: null,
      }));

      showSnackMessage(
        toggleTo === "active"
          ? "Projeto ativado com sucesso"
          : "Projeto arquivado com sucesso",
        ALERT_TYPES.SUCCESS,
      );
    } else {
      setErrorState(error);
    }
  };

  const inactiveProject = () => {
    const { selectedToInactivate } = state;

    if (selectedToInactivate) {
      void toggleProject(selectedToInactivate.projectToken, "inactive");
    }
  };

  const activeProject = (project: ProjectItem) => {
    void toggleProject(project.projectToken, "active");
  };

  const selectProjectToInactivate = (project: ProjectItem) => {
    setState((prevState) => ({
      ...prevState,
      selectedToInactivate: project,
      isInactiveDialogOpen: true,
    }));
  };

  const closeInactivationDialog = () => {
    setState((prevState) => ({
      ...prevState,
      isInactiveDialogOpen: false,
      selectedToInactivate: null,
    }));
  };

  const setNameFilter = debounce((search: string) => {
    setState((prevState) => ({
      ...prevState,
      nameFilter: search,
    }));
  }, 300);

  const setLoading = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  const setErrorState = (error: CustomError) => {
    setState((prevState) => ({
      ...prevState,
      loading: false,
      isSubmitting: false,
    }));
    showSnackMessage(error.description, ALERT_TYPES.ERROR);
  };

  const openForm = async () => {
    setState((prevState) => ({
      ...prevState,
      isFormOpen: true,
    }));
  };

  const closeForm = () => {
    if (state.isSubmitting) {
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isFormOpen: false,
      selectedToEdition: null,
    }));
  };

  function compareByLowerCaseFullName(itemA: any, itemB: any) {
    if (itemA.fullName.toLowerCase() < itemB.fullName.toLowerCase()) {
      return -1;
    }
    if (itemA.fullName.toLowerCase() > itemB.fullName.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const openProjectEdition = async (project: ProjectItem) => {
    const result = await projectsServices.getSingleProject(
      project.projectToken,
    );

    if (result.error) {
      setErrorState(result.error);
      setState((prevState) => ({
        ...prevState,
        isFormOpen: false,
      }));
    } else {
      const projectItem = result.data!;
      projectItem!.users = projectItem!.users.sort(compareByLowerCaseFullName);

      setState((prevState) => ({
        ...prevState,
        isFormOpen: true,
        selectedToEdition: projectItem!,
      }));
    }
  };

  const handleTabChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedTab: value,
    }));
  };

  const saveProject = (formData: ProjectForm) => {
    const { selectedToEdition } = state;

    if (selectedToEdition) {
      void editProject(formData, selectedToEdition.projectToken);
    } else {
      void createProject(formData);
    }
  };

  const createProject = async (formData: ProjectForm) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const result = await projectsServices.createProject(formData);

    if (result.error) {
      setErrorState(result.error);
    } else {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isFormOpen: false,
        selectedToEdition: null,
        projects: prevState.projects.concat(result.data!),
      }));
      showSnackMessage("Projeto criado com sucesso", ALERT_TYPES.SUCCESS);
    }
  };

  const editProject = async (formData: ProjectForm, projectToken: string) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const result = await projectsServices.editProject(formData, projectToken);

    if (result.error) {
      setErrorState(result.error);
    } else {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isFormOpen: false,
        selectedToEdition: null,
        projects: prevState.projects.map((project) =>
          project.projectToken === projectToken ? result.data! : project,
        ),
      }));
      showSnackMessage("Projeto editado com sucesso", ALERT_TYPES.SUCCESS);
    }
  };

  const handleToggleProjectState = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!clientConfig) {
      return null;
    }

    const clientConfigToken = clientConfig.getToken();
    const projectEnablingState = clientConfig.getProjectEnablingState();

    const { checked } = e.target;
    const newState: any = checked
      ? PROJECT_ENABLING_STATE.OPTIONAL
      : PROJECT_ENABLING_STATE.OFF;
    handleUpdateProjectEnabled(newState);

    const { error } = await projectsServices.changeProjectEnablingState(
      newState,
      clientConfigToken,
    );

    if (error) {
      handleUpdateProjectEnabled(projectEnablingState!);
      return setErrorState(error);
    }
  };

  const handleChangeProjectState = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ProjectEnablingState;

    if (!clientConfig) {
      return null;
    }

    const clientConfigToken = clientConfig.getToken();
    const projectEnablingState = clientConfig.getProjectEnablingState();

    handleUpdateProjectEnabled(value);

    const { error } = await projectsServices.changeProjectEnablingState(
      value,
      clientConfigToken,
    );

    if (error) {
      handleUpdateProjectEnabled(projectEnablingState!);
      return setErrorState(error);
    }
  };

  const visibleProjects = useMemo(() => {
    const onlyActives = state.selectedTab === "active";

    return state.projects
      .filter((project) =>
        project.name.toLowerCase().includes(state.nameFilter.toLowerCase()),
      )
      .filter((project) => project.active === onlyActives);
  }, [state.projects, state.nameFilter, state.selectedTab]);

  return (
    <Provider
      value={{
        ...state,
        visibleProjects,
        fetchProjects,
        activeProject,
        inactiveProject,
        selectProjectToInactivate,
        closeInactivationDialog,
        setNameFilter,
        openForm,
        closeForm,
        openProjectEdition,
        saveProject,
        handleTabChange,
        handleToggleProjectState,
        handleChangeProjectState,
      }}
    >
      {children}
    </Provider>
  );
};

export { ProjectsProvider, Consumer as ProjectsConsumer, ProjectsContext };
