import {
  CreateCountryRestrictionRequest,
  CreateCountryRestrictionResponse,
  DeleteCountryRestrictionRequest,
  DeleteCountryRestrictionResponse,
  GetCountryRestrictionRequest,
  GetCountryRestrictionResponse,
  ListCountryRestrictionsResponse,
  UpdateCountryRestrictionRequest,
  UpdateCountryRestrictionResponse,
} from "~/apps/corporate/dtos/country-restriction.dto";
import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";
import { AxiosResponse } from "axios";

export async function listCountryRestrictions(): Promise<ListCountryRestrictionsResponse> {
  return await api
    .request({
      url: `/booking/clients/${getClientToken()}/country-restrictions`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<ListCountryRestrictionsResponse>) => {
      return response.data;
    });
}

export async function createCountryRestriction(
  data: CreateCountryRestrictionRequest,
): Promise<CreateCountryRestrictionResponse> {
  return await api
    .request({
      url: `/booking/clients/${getClientToken()}/country-restrictions`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data,
    })
    .then((response: AxiosResponse<CreateCountryRestrictionResponse>) => {
      return response.data;
    });
}

export async function getCountryRestriction(
  data: GetCountryRestrictionRequest,
): Promise<GetCountryRestrictionResponse> {
  return await api
    .request({
      url: `/booking/country-restrictions/${data.country_restriction_token}`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<GetCountryRestrictionResponse>) => {
      return response.data;
    });
}

export async function updateCountryRestriction(
  data: UpdateCountryRestrictionRequest,
): Promise<UpdateCountryRestrictionResponse> {
  return await api
    .request({
      url: `/booking/country-restrictions/${data.country_restriction_token}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data,
    })
    .then((response: AxiosResponse<UpdateCountryRestrictionResponse>) => {
      return response.data;
    });
}

export async function deleteCountryRestriction(
  data: DeleteCountryRestrictionRequest,
) {
  return await api
    .request({
      url: `/booking/country-restrictions/${data.country_restriction_token}`,
      method: "DELETE",
      headers: getAuthorizationHeader(),
      data,
    })
    .then((response: AxiosResponse<DeleteCountryRestrictionResponse>) => {
      return response.data;
    });
}
