import React, { ChangeEvent } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { navigate } from "@reach/router";
import { css } from "@styled-system/css";
import { css as oldCss } from "emotion";
import { NumericDictionary } from "lodash";

import { Flex, Box, Text, Paragraph } from "@toolkit";

import { defaultTheme } from "@assets/styles/theme";
import { colors } from "@assets/styles/theme/constants";

import {
  EXPENSE_REPORTS_STATUS_TRANSLATION,
  MEDIA_QUERIES,
  EXPENSE_REPORT_STATUS,
  EXPENSE_REPORT_TYPE_TRANSLATION
} from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { ExpenseReportWithUser } from "@models/expense.model";

import ColoredTag from "@shared/ColoredTag";

const styles = {
  root: css({
    flexGrow: 1,
    border: "1px solid",
    borderColor: "border",
    cursor: "pointer",
    transition: "box-shadow 200ms",
    "&:hover": {
      boxShadow: "0 4px 6px 0 rgba(211,222,233,0.6)"
    }
  }),
  boldText: css({
    fontSize: [1, 20],
    fontWeight: "bold",
    mb: ["small"],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: ["18px", "1.5rem"]
  }),
  checbox: css({
    color: "border",
    padding: 0,
    mr: ["xsmall", "small"],
    ["svg"]: {
      height: [24, 32],
      width: [24, 32]
    },
    ["&:hover"]: {
      backgroundColor: "transparent !important"
    },
    ["&.Mui-checked"]: {
      color: "primary"
    },
    ["&.Mui-checked:hover"]: {
      backgroundColor: "transparent !important"
    }
  })
};

const statusTagsStyles: NumericDictionary<string> = {
  "1": oldCss({
    color: `${colors.slateContrastGray} !important`,
    backgroundColor: `${colors.botticelli} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "2": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.successTextColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "3": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.secondaryColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "99": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${colors.brinkPink} !important`,
    wiminWidthdth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "100": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.primaryColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0,
      width: 142
    }
  })
};

interface Props {
  report: ExpenseReportWithUser;
  isSelected: boolean;
  isSelectable: boolean;
  handleSelectReport(e: ChangeEvent<HTMLInputElement>): void;
}

const ReportItem: React.FC<Props> = ({
  report,
  isSelected,
  isSelectable,
  handleSelectReport
}) => {
  const STATUS_TRANSLATIONS: {
    [key: number]: string;
  } = EXPENSE_REPORTS_STATUS_TRANSLATION;
  const getFormattedReportPeriod = () => {
    const { periodStart, periodEnd } = expensesHelper.calculateExpensesPeriod(
      report.expenses
    );

    return `${periodStart.format("DD MMM YYYY")} - ${periodEnd.format(
      "DD MMM YYYY"
    )}`;
  };

  const totalPrice = expensesHelper.getReportTotalPrice(
    report.expenses,
    report.status
  );

  const handleNavigate = () => {
    if (report.status === EXPENSE_REPORT_STATUS.CLOSED) {
      navigate(`/reports/${report.expenseReportToken}`, {
        state: { origin: "/expenses/reports" }
      });
    } else {
      navigate(`/reports/${report.expenseReportToken}/approval-review`, {
        state: { origin: "/expenses/reports" }
      });
    }
  };

  return (
    <Flex
      alignItems="center"
      mb={["medium", "large"]}
      ml={[isSelectable ? "-8px" : 0, 0]}
    >
      {isSelectable && (
        <Checkbox
          id={report.expenseReportToken}
          checked={isSelected}
          onChange={handleSelectReport}
          css={styles.checbox}
        />
      )}
      <Box css={styles.root} onClick={handleNavigate}>
        <Box width="max-content" p={["small", "16px 24px 0"]} display={"flex"}>
          <ColoredTag
            tagStyle={statusTagsStyles[report.status]}
            isUppercase={false}
          >
            {STATUS_TRANSLATIONS[report.status]}
          </ColoredTag>
          <Paragraph
            fontSize={["12px"]}
            mb={["small", "medium"]}
            color={defaultTheme.subTextColor}
            letterSpacing={"0.04rem"}
            margin={"3px 0px 0px 7px"}
          >
            <b>Tipo: </b>
            {EXPENSE_REPORT_TYPE_TRANSLATION[report.reportType]}
          </Paragraph>
        </Box>
        <Flex
          py={["small", "medium"]}
          px={["small", 24]}
          justifyContent={"space-between"}
          alignItems="baseline"
        >
          <Flex flexDirection="column" maxWidth="50%">
            <Text fontSize={[0, 2]} mb={["small", "medium"]}>
              {getFormattedReportPeriod()}
            </Text>
            <Text css={styles.boldText}>{report.description}</Text>
          </Flex>
          <Box textAlign="right" maxWidth="50%">
            <Paragraph fontSize={[0, 2]} mb={["small", "medium"]}>
              Viajante
            </Paragraph>
            <Text css={styles.boldText}>
              {report?.user?.fullName || "Usuário inativo"}
            </Text>
          </Box>
        </Flex>
        <Flex
          py={["small", "medium"]}
          px={["small", 24]}
          justifyContent={"space-between"}
          alignItems="center"
          borderTop="1px solid"
          borderTopColor="border"
          borderRadius={0}
          backgroundColor="rgba(0, 0, 0, 0.02)"
        >
          <Text fontSize={[0, 2]}>
            <strong>ID do relatório:</strong> {report.expenseReportToken}
          </Text>
          <Text fontSize={[0, 2]}>
            <strong>Valor total:</strong> {utils.toCurrency(totalPrice)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export { ReportItem };
