import React from "react";

import PointerIcon from "@material-ui/icons/RoomRounded";
import { css } from "@styled-system/css";
import PropTypes from "prop-types";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { StOutlinedButton } from "@components/shared/buttons";

const styles = {
  title: css({
    textTransform: "capitalize"
  }),
  pointer: css({
    color: defaultTheme.subTextColor,
    fontSize: [2],
    mr: "xsmall"
  }),
  button: css({
    px: 24,
    py: "small",
    width: ["100%", "auto"]
  })
};

const PendingReviewCard = ({
  reviewData,
  initialDate,
  endDate,
  handleReview
}) => {
  return (
    <Box border="1px solid" borderColor="border" p={["medium", 24]} mb={[24]}>
      <Flex
        flexDirection={["column", "row"]}
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
      >
        <Box mb={["medium", 0]}>
          <Box mb={["medium"]}>
            <Text fontSize={[2, 3]} fontWeight="bold" css={styles.title}>
              {reviewData.hotelName}
            </Text>
          </Box>
          <Box mb={["xsmall", "small"]}>
            <Flex alignItems="center">
              <PointerIcon css={styles.pointer} />
              <Text color={defaultTheme.subTextColor} fontSize={[0, 1]}>
                {reviewData.hotelCity}/{reviewData.hotelState} -
                {reviewData.hotelCountry}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Text fontSize={[0, 1]} fontWeight="bold" mr="xsmall">
              Período de estadia:{" "}
            </Text>
            <Text fontSize={[0, 1]}>
              {initialDate} - {endDate}
            </Text>
          </Box>
        </Box>
        <StOutlinedButton
          color="primary"
          css={styles.button}
          onClick={handleReview}
        >
          Realizar avaliação
        </StOutlinedButton>
      </Flex>
    </Box>
  );
};

PendingReviewCard.propTypes = {
  reviewData: PropTypes.object,
  initialDate: PropTypes.string,
  endDate: PropTypes.string,
  handleReview: PropTypes.func
};

export { PendingReviewCard };
