import * as flightApi from "~/apps/corporate/apis/flight.api";
import * as rewardsApi from "~/apps/corporate/apis/rewards.api";
import {
  AddFlightOfferRequest,
  AddFlightOfferResponse,
  CheckReservationConflictRequestBody,
} from "~/apps/corporate/dtos/flights.dto";
import { getClientPermissions } from "~/apps/corporate/helpers/user.helper";
import {
  FlightPlaneClientLimit,
  FlightReservationConflict,
} from "~/apps/corporate/models/flight.model";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const addFlightOffer = async (data: AddFlightOfferRequest) => {
  const result: { data?: AddFlightOfferResponse; error?: Error } = {};

  try {
    result.data = await flightApi.addFlightOffer(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    }
  }

  return result;
};

export const cancelPreReserve = async (data: { offerToken: string }) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await flightApi.cancelPreReserve(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    }
  }

  return result;
};

export const checkReservationConflict = async (
  data: CheckReservationConflictRequestBody,
) => {
  const result: { data?: FlightReservationConflict; error?: Error } = {};

  try {
    result.data = await flightApi.checkReservationConflict(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    }
  }

  return result;
};

export const checkPlaneClientLimit = async (data: any) => {
  const result: { data?: FlightPlaneClientLimit; error?: Error } = {};

  try {
    result.data = await flightApi.checkPlaneClientLimit(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    }
  }

  return result;
};

export const getClientRewardPolicy = async () => {
  const result: { data?: any; error?: Error } = {};

  const permissions = getClientPermissions();

  if (!permissions) {
    result.error = ERROR.UNEXPECTED;

    return result;
  }

  const { travels } = permissions;

  const gamificationPermission = travels?.gamification;

  try {
    result.data = gamificationPermission
      ? await rewardsApi.getLoggedClientRewardPolicy()
      : {};
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REWARD_POLICY_ERROR;
    }
  }

  return result;
};

export const getFlights = async (flightToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await flightApi.getFlights(flightToken);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export async function listFlightFareFamilies(
  flightToken: string,
  requestData: { search_id: string; itinerary_id: string },
) {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await flightApi.listFlightFareFamilies(
      flightToken,
      requestData,
    );
  } catch (error) {
    result.error = error;
  }

  return result;
}
