import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import { pageTitles } from "~/apps/corporate/constants/page-titles";

type Props = {
  title: string;
};

export const PageTitle: React.FC<Props> = ({ title }) => {
  const formattedTitle = useMemo(
    () =>
      title === pageTitles.BASE_TITLE
        ? pageTitles.BASE_TITLE
        : title + " — " + pageTitles.BASE_TITLE,
    [title],
  );

  return <Helmet title={formattedTitle} />;
};
