import React from "react";

import DeletionDialog from "@shared/dialogs/DeletionDialog";

import { useAdvanceApprovalProcess } from "../advance-approval-process.context";

const ApprovalRuleDeletionDialog = () => {
  const {
    deleting,
    selectedDeleteRule,
    handleCancelDelete,
    deleteApprovableRule,
  } = useAdvanceApprovalProcess();

  return (
    <DeletionDialog
      open={!!selectedDeleteRule}
      title="Exclusão de regra"
      message="Deseja realmente excluir a regra de aprovação selecionada?"
      handleClose={handleCancelDelete}
      handleDeletion={deleteApprovableRule}
      isDeleting={deleting}
    />
  );
};

export { ApprovalRuleDeletionDialog };
