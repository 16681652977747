import * as policyApi from "~/apps/corporate/apis/policy.api";
import { getClientToken } from "~/apps/corporate/helpers/user.helper";
import {
  PolicyDto,
  PolicyItem,
  PolicyTarget,
  SavePolicyTargetsDto,
} from "~/apps/corporate/models/policy.model";
import { CABINS, NATIONAL_TYPES, SERVICE_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";

import {
  ListPoliciesResult,
  CreatePolicyResult,
  GetPolicyDetailedResult,
  EditPolicyResult,
  DeletePolicyResult,
  GetPolicyTargetsResult,
  ITravelPolicyForm,
  SavePolicyResult,
} from "./travel-policies.types";

export async function listPolicies(): Promise<ListPoliciesResult> {
  const result: ListPoliciesResult = {};

  try {
    result.data = await policyApi.getPoliciesList();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_LIST_ERROR;
    }
  }

  return result;
}

function parseFormPolicyToPolicyDto(policy: ITravelPolicyForm): PolicyDto {
  const result = {
    policy_token: policy.policyToken!,
    target_type: policy.targetType,
    client_token: getClientToken(),
    description: policy.description,
    national_flights_class: policy.nationalFlightPolicy.class || CABINS.economy,
    national_flights_advance: policy.nationalFlightPolicy.advance || 0,
    national_flights_max_value: policy.nationalFlightPolicy.maxValue
      ? parseFloat(policy.nationalFlightPolicy.maxValue.toString())
      : null,
    national_flights_price_excess: policy.nationalFlightPolicy.priceExcess
      ? parseFloat(policy.nationalFlightPolicy.priceExcess.toString())
      : null,
    international_optimal_enabled:
      policy.internationalFlightPolicy.optimalPriceEnabled,
    national_optimal_enabled: policy.nationalFlightPolicy.optimalPriceEnabled,
    international_flights_class: policy.internationalFlightPolicy.class,
    international_flights_advance:
      policy.internationalFlightPolicy.advance || 0,
    international_flights_max_value: policy.internationalFlightPolicy.maxValue
      ? parseFloat(policy.internationalFlightPolicy.maxValue.toString())
      : null,
    international_flights_price_excess: policy.internationalFlightPolicy
      .priceExcess
      ? parseFloat(policy.internationalFlightPolicy.priceExcess.toString())
      : null,
    international_duration: policy.internationalFlightPolicy.duration,
    international_duration_class:
      policy.internationalFlightPolicy.durationClass,
    international_stars: policy.internationalHotelPolicy.stars || 0,
    national_stars: policy.nationalHotelPolicy.stars || 0,
    national_hotels_advance: policy.nationalHotelPolicy.advance || 0,
    national_hotels_max_value: policy.nationalHotelPolicy.maxValue
      ? parseFloat(policy.nationalHotelPolicy.maxValue.toString())
      : null,
    international_hotels_advance: policy.internationalHotelPolicy.advance || 0,
    international_hotels_max_value: policy.internationalHotelPolicy.maxValue
      ? parseFloat(policy.internationalHotelPolicy.maxValue.toString())
      : null,
    national_cars_max_category: policy.nationalCarPolicy.maxCategory,
    search_visibility: true,
  };

  return result;
}

function extractRules(policy: ITravelPolicyForm) {
  const nationalRules = policy.nationalHotelPolicy.rules.map((item) => {
    return {
      policy_rule_token: item.policyRuleToken,
      offer_type: SERVICE_TYPES.hotel,
      national_type: NATIONAL_TYPES.NATIONAL,
      max_value: item.maxValue,
      location_name: item.locationName,
      location_city: item.locationCity,
      location_state: item.locationState,
      location_country: item.locationCountry,
    };
  });

  const internationalRules = policy.internationalHotelPolicy.rules.map(
    (item) => {
      return {
        policy_rule_token: item.policyRuleToken,
        offer_type: SERVICE_TYPES.hotel,
        national_type: NATIONAL_TYPES.INTERNATIONAL,
        max_value: item.maxValue,
        location_name: item.locationName,
        location_city: item.locationCity,
        location_state: item.locationState,
        location_country: item.locationCountry,
      };
    },
  );

  return nationalRules.concat(internationalRules);
}

export async function createPolicy(
  policy: ITravelPolicyForm,
): Promise<CreatePolicyResult> {
  const result: CreatePolicyResult = {};
  const createPolicyDto = {
    policy: parseFormPolicyToPolicyDto(policy),
    rules: extractRules(policy),
    targets: policy.targets.map((target) => ({
      target_type: "u",
      target_token: target.userToken,
    })),
  };
  try {
    result.data = await policyApi.createPolicy(createPolicyDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_CREATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_CREATE_ERROR;
    }
  }

  return result;
}

export async function editPolicy(
  policy: ITravelPolicyForm,
): Promise<EditPolicyResult> {
  const result: EditPolicyResult = {};

  const editPolicyDto = {
    policy: parseFormPolicyToPolicyDto(policy),
    rules: extractRules(policy),
    targets: policy.targets.map((target) => ({
      policy_target_token: target.policyTargetToken,
      target_type: "u",
      target_token: target.userToken,
    })),
  };

  try {
    result.data = await policyApi.editPolicy(editPolicyDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_EDIT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_EDIT_ERROR;
    }
  }

  return result;
}

export async function deletePolicy(
  policyToken: string,
): Promise<DeletePolicyResult> {
  const result: DeletePolicyResult = {};

  try {
    await policyApi.deletePolicy(policyToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_DELETE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_DELETE_ERROR;
    }
  }

  return result;
}

export async function getPolicyDetailed(
  policy: PolicyItem,
): Promise<GetPolicyDetailedResult> {
  const result: GetPolicyDetailedResult = {};

  try {
    result.data = await policyApi.getPolicyDetailed(policy);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_DETAIL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_DETAIL_ERROR;
    }
  }

  return result;
}

export async function getPolicyTargets(
  policy: PolicyItem,
): Promise<GetPolicyTargetsResult> {
  const result: GetPolicyTargetsResult = {};

  try {
    result.data = await policyApi.getPolicyTargets(policy);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_TARGETS_LIST;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_TARGETS_LIST;
    }
  }

  return result;
}

export async function searchPolicyTarget(
  searchText: string,
): Promise<{ data?: PolicyTarget[]; error?: any }> {
  const result: { data?: PolicyTarget[]; error?: any } = {
    data: undefined,
    error: undefined,
  };

  try {
    result.data = await policyApi.searchPolicyTargets(searchText);
    result.data = result.data
      ? result.data.map((item: PolicyTarget) =>
          Object.assign({}, item, { label: item.fullName }),
        )
      : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR;
    }
  }

  return result;
}

export async function savePolicyTargets(
  policy: PolicyItem,
  targets: PolicyTarget[],
): Promise<SavePolicyResult> {
  const result: SavePolicyResult = {};

  const targetsData = targets.map((item) => ({
    policy_target_token: item.policyTargetToken,
    target_token: item.userToken,
    policy_token: policy.policyToken,
    target_type: "user",
  }));
  const dto: SavePolicyTargetsDto = {
    targets: targetsData,
    policy_token: policy.policyToken,
  };

  try {
    await policyApi.savePolicyTargets(dto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_POLICY_TARGETS_SAVE;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_POLICY_TARGETS_SAVE;
    }
  }

  return result;
}
