import React, { memo } from "react";

import { Link } from "@reach/router";
import { css } from "@styled-system/css";
import moment from "moment";

import { Flex, Box, Paragraph } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import {
  ADVANCED_EXPENSE_STATUS_LABEL,
  ADVANCED_EXPENSE_STATUS_COLOR
} from "@constants";

import * as utils from "@helpers";

import { ExpenseAdvanceApprovalItem } from "../types";

const styles = {
  anchor: css({
    display: "block",
    textDecoration: "none",

    ["&:not(:last-child)"]: {
      borderBottom: "1px solid",
      borderColor: "border"
    }
  }),
  listItem: css({
    cursor: "pointer",
    color: defaultTheme.subTextColor,
    fontSize: [12, 16],
    py: "1.5rem",
    alignItems: "center",
    justifyContent: "space-between"
  }),
  itemName: css({ fontWeight: 700 }),
  itemValue: css({
    textAlign: "right",
    minWidth: 80
  }),
  itemDate: css({
    pb: ".5rem",
    fontSize: [0, 15]
  }),
  status: (status: 1 | 2 | 3 | 99 | 100) =>
    css({
      fontSize: 10,
      minWidth: "80px",
      textAlign: "center",
      fontWeight: "bold",
      p: "2px 13px",
      marginRight: [0, "medium"],
      lineHeight: 1.5,
      borderRadius: "60px",
      backgroundColor: `${ADVANCED_EXPENSE_STATUS_COLOR[status]}`,
      color: "white"
    }),
  deleteIconBox: css({
    padding: "0.5rem",
    borderRadius: "50%",
    marginLeft: "0.5rem",
    ["&:hover"]: {
      bg: "gray.0"
    }
  }),
  deleteIcon: css({
    cursor: "pointer"
  }),
  disabledDeleteIcon: css({
    ml: "1rem",
    mr: "0.5rem",
    cursor: "not-allowed",
    color: "gray.1"
  })
};

interface Props {
  item: ExpenseAdvanceApprovalItem;
}

const ApprovalItem = memo(({ item }: Props) => {
  return (
    <Link
      css={styles.anchor}
      to={`/expense-advance/${item.expenseAdvanceToken}/approval-review`}
    >
      <Flex css={styles.listItem}>
        <Flex flexDirection="column" flex={1}>
          <Box css={styles.itemDate}>
            {moment(item.travelStart).format("DD MMM YYYY")} -{" "}
            {moment(item.travelEnd).format("DD MMM YYYY")}
          </Box>
          <Box css={styles.itemName}>{item.name}</Box>
        </Flex>
        <Box display={["none", "block"]} css={styles.status(item.status)}>
          {ADVANCED_EXPENSE_STATUS_LABEL[item.status]}
        </Box>
        <Box textAlign="right" css={styles.itemValue}>
          <Paragraph pb="small">
            {utils.toCurrency(item.requestedValue)}
          </Paragraph>
          <Paragraph fontWeight="bold" display={["none", "block"]}>
            {item.requesterFullName}
          </Paragraph>
          <Box display={["block", "none"]} css={styles.status(item.status)}>
            {ADVANCED_EXPENSE_STATUS_LABEL[item.status]}
          </Box>
        </Box>
      </Flex>
    </Link>
  );
});

export { ApprovalItem };
