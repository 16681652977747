import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CancelCircleIcon from "@material-ui/icons/CancelOutlined";
import { Row } from "../../../shared/layout/Row";
import { UserModel } from "../../../../models/user.model";
import { Desktop } from "../../../shared/layout/Responsive";
import { MEDIA_QUERIES } from "../../../../constants";
import { ApprovalResponseStatus } from "../ReportApprovalReview.types";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    backgroundColor: defaultTheme.applicationBackground,
    lineHeight: 1.5,
    color: defaultTheme.textColor,
    width: "100%",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 0
    }
  }),
  cardStrip: css({
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: "100%",
    height: ".75rem",
    backgroundColor: defaultTheme.subTextColor
  }),
  headerSection: css({
    padding: "1rem 2rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      borderBottom: "none",
      padding: ".5rem 1rem"
    }
  }),
  section: css({
    padding: "1rem 2rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: ".5rem 1rem 1.5rem"
    }
  }),
  title: css({
    fontSize: 18,
    fontWeight: "bold"
  }),
  checkIcon: css({
    color: defaultTheme.successTextColor,
    marginRight: "1rem",
    fontSize: 28
  }),
  cancelIcon: css({
    color: defaultTheme.secondaryColor,
    marginRight: "1rem",
    fontSize: 28
  }),
  optionsSection: css({
    padding: "1rem 2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  })
};

const ApprovalAnsweredCard = ({
  traveler,
  type
}: {
  traveler: UserModel;
  type: ApprovalResponseStatus;
}) => (
  <div className={styles.root}>
    <Desktop>
      <div
        style={{
          backgroundColor:
            type === "DECLINED"
              ? defaultTheme.subTextColor
              : defaultTheme.successTextColor
        }}
        className={styles.cardStrip}
      />
    </Desktop>
    <section className={styles.headerSection}>
      <div
        className={styles.title}
        style={{
          color:
            type === "DECLINED"
              ? defaultTheme.secondaryColor
              : defaultTheme.successTextColor
        }}
      >
        {type === "APPROVED" ? "Solicitação aprovada" : null}
        {type === "PAID" ? "Relatório pago" : null}
        {type === "DECLINED" ? "Solicitação negada" : null}
      </div>
    </section>
    <section className={styles.section}>
      <Row style={{ alignItems: "center" }}>
        <Desktop>
          {type === "DECLINED" ? (
            <CancelCircleIcon className={styles.cancelIcon} />
          ) : (
            <CheckCircleIcon className={styles.checkIcon} />
          )}
        </Desktop>
        {type === "APPROVED" ? (
          <span style={{ fontSize: 14 }}>Solicitação aprovada.</span>
        ) : null}
        {type === "PAID" ? (
          <span style={{ fontSize: 14 }}>
            Relatório pago. Um email com sua resposta foi enviado ao
            solicitante.
          </span>
        ) : null}
        {type === "DECLINED" ? (
          <span style={{ fontSize: 14 }}>
            Solicitação declinada. Um email com sua resposta foi enviado para{" "}
            {traveler.fullName}
          </span>
        ) : null}
      </Row>
    </section>
  </div>
);

export { ApprovalAnsweredCard };
