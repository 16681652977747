import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { useUserBillingProfiles } from "~/apps/corporate/hooks/use-user-billing-profiles";
import { useUserCompanyAreas } from "~/apps/corporate/hooks/use-user-company-areas";
import { useUserCostCenters } from "~/apps/corporate/hooks/use-user-cost-centers";
import { useUserProjects } from "~/apps/corporate/hooks/use-user-project";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import {
  useItineraryInfoCategorizationGuard,
  useItineraryInfoCategorizationPresenter,
} from "./itinerary-info-categorization.hooks";
import { styles } from "./styles";

type Props = {
  isDisabled?: boolean;
};

export const ItineraryInfoCategorization: React.FC<Props> = ({
  isDisabled,
}) => {
  const { error, loading } = useItineraryInfoCategorizationGuard();
  const {
    itineraryInfoCategorizationPresenter,
  } = useItineraryInfoCategorizationPresenter();

  const { openTravelCategorizationDialog } = useItineraryScreen();

  const travelerToken = itineraryInfoCategorizationPresenter
    ? itineraryInfoCategorizationPresenter.getTravelerToken()
    : undefined;

  const travelHasBillingProfile = itineraryInfoCategorizationPresenter
    ? itineraryInfoCategorizationPresenter.travelHasBillingProfile()
    : false;
  const travelHasCompanyArea = itineraryInfoCategorizationPresenter
    ? itineraryInfoCategorizationPresenter.travelHasCompanyArea()
    : false;
  const travelHasCostCenter = itineraryInfoCategorizationPresenter
    ? itineraryInfoCategorizationPresenter.travelHasCostCenter()
    : false;
  const travelHasProject = itineraryInfoCategorizationPresenter
    ? itineraryInfoCategorizationPresenter.travelHasProject()
    : false;

  const { billingProfiles, isLoadingBillingProfiles } = useUserBillingProfiles(
    travelerToken,
    travelHasBillingProfile,
  );
  const { companyAreas, isLoadingCompanyAreas } = useUserCompanyAreas(
    travelerToken,
    travelHasCompanyArea,
  );
  const { costCenters, isLoadingCostCenters } = useUserCostCenters(
    travelerToken,
    travelHasCostCenter,
  );
  const { isLoadingProjects, projects } = useUserProjects(
    travelerToken,
    travelHasProject,
  );

  const travelBillingProfile = useMemo(() => {
    if (!billingProfiles || !itineraryInfoCategorizationPresenter) {
      return null;
    }

    return billingProfiles.find(
      (billingProfile) =>
        billingProfile.billingProfileToken ===
        itineraryInfoCategorizationPresenter.getTravelBillingProfileToken(),
    );
  }, [billingProfiles, itineraryInfoCategorizationPresenter]);
  const travelCompanyArea = useMemo(() => {
    if (!companyAreas || !itineraryInfoCategorizationPresenter) {
      return null;
    }

    return companyAreas.find(
      (companyArea) =>
        companyArea.companyAreaToken ===
        itineraryInfoCategorizationPresenter.getTravelCompanyAreaToken(),
    );
  }, [companyAreas, itineraryInfoCategorizationPresenter]);
  const travelCostCenter = useMemo(() => {
    if (!costCenters || !itineraryInfoCategorizationPresenter) {
      return null;
    }

    return costCenters.find(
      (costCenter) =>
        costCenter.token ===
        itineraryInfoCategorizationPresenter.getTravelCostCenterToken(),
    );
  }, [costCenters, itineraryInfoCategorizationPresenter]);
  const travelProject = useMemo(() => {
    if (!projects || !itineraryInfoCategorizationPresenter) {
      return null;
    }

    return projects.find(
      (project) =>
        project.projectToken ===
        itineraryInfoCategorizationPresenter.getTravelProjectToken(),
    );
  }, [projects, itineraryInfoCategorizationPresenter]);

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  if (
    !travelHasBillingProfile &&
    !travelHasCompanyArea &&
    !travelHasCostCenter &&
    !travelHasProject
  ) {
    return null;
  }

  return (
    <div css={styles.root}>
      {travelHasCompanyArea ? (
        <div css={styles.item.root}>
          <Icon size={14} use="square-3-stack-3d" />
          <div css={styles.item.text}>
            Área:&nbsp;
            {isLoadingCompanyAreas ? (
              <Skeleton height="14px" width="96px" />
            ) : companyAreas && travelCompanyArea ? (
              <button
                css={styles.item.button({})}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("company-area");
                }}
              >
                {travelCompanyArea.name}
              </button>
            ) : (
              <button
                css={styles.item.button({ isEmpty: true })}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("company-area");
                }}
              >
                Nenhuma área aplicada.
              </button>
            )}
          </div>
        </div>
      ) : null}
      {travelHasCostCenter ? (
        <div css={styles.item.root}>
          <Icon size={14} use="dollar-coin" />
          <div css={styles.item.text}>
            Centro de custo:&nbsp;
            {isLoadingCostCenters ? (
              <Skeleton height="14px" width="96px" />
            ) : costCenters && travelCostCenter ? (
              <button
                css={styles.item.button({})}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("cost-center");
                }}
              >
                {travelCostCenter.name}
              </button>
            ) : (
              <button
                css={styles.item.button({ isEmpty: true })}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("cost-center");
                }}
              >
                Nenhum centro de custo aplicado.
              </button>
            )}
          </div>
        </div>
      ) : null}
      {travelHasBillingProfile ? (
        <div css={styles.item.root}>
          <Icon size={14} use="building" />
          <div css={styles.item.text}>
            CNPJ:&nbsp;
            {isLoadingBillingProfiles ? (
              <Skeleton height="14px" width="96px" />
            ) : billingProfiles && travelBillingProfile ? (
              <button
                css={styles.item.button({})}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("billing-profile");
                }}
              >
                {travelBillingProfile.name}
              </button>
            ) : (
              <button
                css={styles.item.button({ isEmpty: true })}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("billing-profile");
                }}
              >
                Nenhum CNPJ aplicado.
              </button>
            )}
          </div>
        </div>
      ) : null}
      {travelHasProject ? (
        <div css={styles.item.root}>
          <Icon size={14} use="clipboard-document-list" />
          <div css={styles.item.text}>
            Projeto:&nbsp;
            {isLoadingProjects ? (
              <Skeleton height="14px" width="96px" />
            ) : projects && travelProject ? (
              <button
                css={styles.item.button({})}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("project");
                }}
              >
                {travelProject.name}
              </button>
            ) : (
              <button
                css={styles.item.button({ isEmpty: true })}
                disabled={isDisabled}
                onClick={() => {
                  openTravelCategorizationDialog("project");
                }}
              >
                Nenhum projeto aplicado.
              </button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
