import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

export const SmApprovesBadge: React.FC = () => {
  return (
    <Tooltip
      arrow
      content={
        <>
          <span>
            <strong>Smartrips Aprova </strong>destaca as opções de aéreo com as
            melhores relações entre preço e duração.
            <br />
            <br />
            <span>
              <strong>Smartrips Aprova</strong> <strong>não</strong>{" "}
            </span>
            está associado com a política de viagens estabelecida pela empresa.
          </span>
        </>
      }
      position="top"
    >
      <div>
        <Badge css={styles.root} variant="blue">
          <Icon css={styles.icon} size={16} use="paper-airplane" />
        </Badge>
      </div>
    </Tooltip>
  );
};
