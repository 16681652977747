import React, { useState } from "react";

import {
  HotelPolicy,
  HotelPolicyRule,
  PolicyItem,
  PolicyTarget,
} from "~/apps/corporate/models/policy.model";
import { ALERT_TYPES, TARGET_TYPES, CABINS } from "~/apps/shared/constants";
import { CarCategory } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { ALERT, Error } from "~/apps/shared/types";
import { isEmptyNullOrUndefined } from "~/apps/shared/utils/is-empty-null-or-undefined";
import { sortByField } from "~/apps/shared/utils/sort-by-field";

import * as policyService from "./travel-policies.service";
import { ListPoliciesResult } from "./travel-policies.types";
import {
  IFlightPolicies,
  IHotelPolicies,
  ITravelPolicyForm,
} from "./travel-policies.types";

const BLANK_FORM: ITravelPolicyForm = {
  createdAt: undefined,
  description: "",
  modifiedAt: undefined,
  policyToken: undefined,
  searchVisiblity: true,
  targetType: "u",
  internationalFlightPolicy: {
    class: CABINS.economy,
    advance: 0,
    duration: 0,
    durationClass: CABINS.economy,
    rules: [],
    maxValue: undefined,
    priceExcess: null,
    priceExcessActive: false,
    optimalPriceEnabled: false,
  },
  internationalHotelPolicy: {
    stars: 0,
    advance: 0,
    rules: [],
    maxValue: undefined,
  },
  nationalCarPolicy: {
    advance: null,
    maxCategory: null,
  },
  nationalFlightPolicy: {
    class: CABINS.economy,
    advance: 0,
    rules: [],
    maxValue: undefined,
    priceExcess: null,
    priceExcessActive: false,
    duration: 0,
    durationClass: CABINS.economy,
    optimalPriceEnabled: false,
  },
  nationalHotelPolicy: {
    stars: 0,
    advance: 0,
    rules: [],
    maxValue: undefined,
  },
  targets: [],
};

type State = {
  fetchLoading: boolean;
  form: ITravelPolicyForm;
  formVisible: boolean;
  isDeleteVisible: boolean;
  isDeleting: boolean;
  loading: boolean;
  loadingPolicyDetails: boolean;
  originalTargets: PolicyTarget[];
  policies: PolicyItem[];
  saving: boolean;
  selectedPolicyItem: PolicyItem | null;
  snack: {
    type: ALERT;
    open: boolean;
    message: string;
  };
};

const initialState: State = {
  fetchLoading: false,
  form: BLANK_FORM,
  formVisible: false,
  isDeleteVisible: false,
  isDeleting: false,
  loading: false,
  loadingPolicyDetails: false,
  originalTargets: [],
  policies: [],
  saving: false,
  snack: {
    type: null,
    open: false,
    message: "",
  },
  selectedPolicyItem: null,
};

type PoliciesContextProps = State & {
  closeSnackbar: () => void;
  handleAdvanceDaysChange: (
    event: any,
    policyName: IFlightPolicies | IHotelPolicies,
  ) => void;
  handleDeletionCancel: () => void;
  handleDeletionConfirm: () => void;
  handleFormClose: () => void;
  handleGeneralInputChange: (event: any) => void;
  handleChangeCarMaxCategory(
    policyType: "national" | "international",
    value: CarCategory | null,
  ): void;
  handleHotelRuleAddition: (
    policyName: IHotelPolicies,
    policyRuleToken: string,
  ) => void;
  handleHotelRuleLocationChange: (
    result: any,
    policyName: IHotelPolicies,
    ruleId: string,
  ) => void;
  handleHotelRuleMaxValueChange: (
    maxValue: number,
    policyName: IHotelPolicies,
    ruleId: string,
  ) => void;
  handleHotelRuleRemoval: (policyName: IHotelPolicies, ruleId: string) => void;
  handleInternationalClassChange: (event: any) => void;
  handleInternationalClassDurationChange: (event: any) => void;
  handleInternationalDurationChange: (event: any) => void;
  handleInternationalHotelStarRating: (starRating: number) => void;
  handleMaxValueChange: (
    value: number,
    policyName: IFlightPolicies | IHotelPolicies,
  ) => void;
  handleNationalHotelStarRating: (starRating: number) => void;
  handleOptimalPriceToggle: (flightPolicie: IFlightPolicies) => void;
  handlePriceExcessChange: (event: any, policyName: IFlightPolicies) => void;
  handlePriceExcessToggle: (policyName: IFlightPolicies) => void;
  handleTargetDeletion: (targetToken: string) => void;
  handleTargetSelection: (user: PolicyTarget) => void;
  loadPolicies: () => void;
  openNewPolicy: () => void;
  selectPolicyForEdition: (policy: PolicyItem) => void;
  savePolicyForm: () => Promise<void>;
  selectPolicyToDelete: (policy: PolicyItem) => void;
};

export const PoliciesContext = React.createContext<PoliciesContextProps>(
  {} as PoliciesContextProps,
);

export const PoliciesProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  function setSafeState(newState: Partial<State>) {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  async function loadPolicies() {
    setSafeState({ fetchLoading: true });

    const {
      error,
      data: policies,
    }: ListPoliciesResult = await policyService.listPolicies();

    if (error) {
      setSafeState({ fetchLoading: false });
      setErrorState(error);
      return;
    }

    setSafeState({
      fetchLoading: false,
      policies: orderPoliciesList(policies!),
    });
  }

  function closeSnackbar() {
    setSafeState({
      snack: {
        type: null,
        open: false,
        message: "",
      },
    });
  }

  function handlePriceExcessToggle(policyName: IFlightPolicies) {
    setState((prevState: State) => {
      const policy = prevState.form[policyName];

      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            priceExcess: policy.priceExcessActive ? null : 1,
            priceExcessActive: !policy.priceExcessActive,
          },
        }),
      };
    });
  }

  function handleTargetSelection(user: PolicyTarget) {
    setSafeState({
      form: {
        ...state.form,
        targets: state.form.targets.concat(user),
      },
    });
  }

  function handleTargetDeletion(targetToken: string) {
    const targets = state.form.targets.filter(
      (target) => target.userToken !== targetToken,
    );

    setSafeState({
      form: {
        ...state.form,
        targets,
      },
    });
  }

  function handleDeletionCancel() {
    setSafeState({ isDeleteVisible: false, selectedPolicyItem: null });
  }

  async function handleDeletionConfirm() {
    setSafeState({ isDeleting: true });
    const policyToken = state.selectedPolicyItem
      ? state.selectedPolicyItem.policyToken
      : "";
    const { error } = await policyService.deletePolicy(policyToken);
    if (error) {
      setSafeState({
        isDeleteVisible: false,
        selectedPolicyItem: null,
        isDeleting: false,
        snack: {
          type: ALERT_TYPES.ERROR,
          open: true,
          message: error.description,
        },
      });
    } else {
      const remainingPolicies = state.policies.filter(
        (item) => item.policyToken !== policyToken,
      );
      setSafeState({
        isDeleteVisible: false,
        selectedPolicyItem: null,
        isDeleting: false,
        policies: remainingPolicies,
        snack: {
          type: ALERT_TYPES.SUCCESS,
          open: true,
          message: "Política excluída com sucesso",
        },
      });
    }
  }

  function selectPolicyToDelete(policy: PolicyItem) {
    setSafeState({ isDeleteVisible: true, selectedPolicyItem: policy });
  }

  // more popover actions

  async function selectPolicyForEdition(policy: PolicyItem) {
    setSafeState({
      formVisible: true,
      loadingPolicyDetails: true,
      selectedPolicyItem: policy,
    });
    const { error, data } = await policyService.getPolicyDetailed(policy);
    if (error) {
      return setSafeState({
        selectedPolicyItem: null,
        formVisible: false,
        loadingPolicyDetails: false,
        snack: {
          type: ALERT_TYPES.ERROR,
          open: true,
          message: error.description,
        },
      });
    }

    const formToOpen = data ? data : state.form;
    formToOpen.targetType = policy.targetType;
    formToOpen.nationalFlightPolicy.priceExcessActive = !!formToOpen
      .nationalFlightPolicy.priceExcess;
    formToOpen.internationalFlightPolicy.priceExcessActive = !!formToOpen
      .internationalFlightPolicy.priceExcess;

    setSafeState({
      loadingPolicyDetails: false,
      form: formToOpen,
      originalTargets: data!.targets,
    });
  }

  // form actions
  async function savePolicyForm(): Promise<void> {
    const { form } = state;
    if (form.policyToken) {
      await editPolicy(form);
    } else {
      await createPolicy(form);
    }
  }

  async function createPolicy(form: ITravelPolicyForm) {
    setSafeState({ saving: true });
    const { error, data } = await policyService.createPolicy(form);

    if (error) {
      return setSafeState({
        saving: false,
        snack: {
          type: ALERT_TYPES.ERROR,
          open: true,
          message: error.description,
        },
      });
    }

    setState((prevState: State) => {
      const newPolicies = data
        ? [data, ...prevState.policies]
        : prevState.policies;
      return {
        ...prevState,
        form: BLANK_FORM,
        originalTargets: [],
        policies: orderPoliciesList(newPolicies),
        saving: false,
        formVisible: false,
        snack: {
          type: ALERT_TYPES.SUCCESS,
          open: true,
          message: "Política criada com sucesso.",
        },
      };
    });
  }

  async function editPolicy(form: ITravelPolicyForm): Promise<void> {
    setSafeState({ saving: true });
    const { error, data } = await policyService.editPolicy(form);

    if (error) {
      return setSafeState({
        saving: false,
        snack: {
          type: ALERT_TYPES.ERROR,
          open: true,
          message: error.description,
        },
      });
    }

    setState((prevState: State) => {
      const updatedPolicies = prevState.policies.map((item) => {
        const updatedItem = data ? data : item;
        return item.policyToken === updatedItem.policyToken
          ? Object.assign({}, updatedItem, {
              targetCount: form.targets.length,
              targetType: form.targetType,
            })
          : item;
      });

      return {
        ...prevState,
        form: BLANK_FORM,
        originalTargets: [],
        policies: updatedPolicies,
        saving: false,
        formVisible: false,
        snack: {
          type: ALERT_TYPES.SUCCESS,
          open: true,
          message: "Política editada com sucesso.",
        },
      };
    });
  }

  function handleGeneralInputChange(e: any) {
    const value = e.target.value;
    setState((prevState: State) => {
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [e.target.name]: value,
        }),
      };
    });
  }

  function handleMaxValueChange(
    value: number,
    policyName: IFlightPolicies | IHotelPolicies,
  ) {
    setState((prevState: State) => {
      const policy = prevState.form[policyName];

      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            maxValue: value,
          },
        }),
      };
    });
  }

  function handleAdvanceDaysChange(
    e: any,
    policyName: IFlightPolicies | IHotelPolicies,
  ) {
    const value = e.target.value;
    setState((prevState: State) => {
      const policy = prevState.form[policyName];

      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            advance: value,
          },
        }),
      };
    });
  }

  function handlePriceExcessChange(e: any, policyName: IFlightPolicies) {
    const value = isEmptyNullOrUndefined(e.target.value)
      ? null
      : parseFloat(e.target.value);
    const priceExcess = value || value === 0 ? 1 + value / 100 : null;
    setState((prevState: State) => {
      const policy = prevState.form[policyName];

      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            priceExcess,
          },
        }),
      };
    });
  }

  function handleInternationalClassChange(e: any) {
    const value = e.target.value;
    setState((prevState: State) => {
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          internationalFlightPolicy: {
            ...prevState.form.internationalFlightPolicy,
            class: value,
          },
        }),
      };
    });
  }
  //Devis
  function handleInternationalClassDurationChange(e: any) {
    const value = e.target.value;
    setState((prevState: State) => {
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          internationalFlightPolicy: {
            ...prevState.form.internationalFlightPolicy,
            durationClass: value,
          },
        }),
      };
    });
  }

  function handleInternationalDurationChange(e: any) {
    const value = e.target.value;
    setState((prevState: State) => {
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          internationalFlightPolicy: {
            ...prevState.form.internationalFlightPolicy,
            duration: value,
          },
        }),
      };
    });
  }

  function handleOptimalPriceToggle(flightPolicies: IFlightPolicies): void {
    setState((prevState: State) => {
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [flightPolicies]: {
            ...prevState.form[flightPolicies],
            optimalPriceEnabled: !prevState.form[flightPolicies]
              .optimalPriceEnabled,
          },
        }),
      };
    });
  }

  function handleNationalHotelStarRating(nextValue: number) {
    setState((prevState: State) => ({
      ...prevState,
      form: Object.assign({}, prevState.form, {
        nationalHotelPolicy: {
          ...prevState.form.nationalHotelPolicy,
          stars: nextValue,
        },
      }),
    }));
  }

  function handleInternationalHotelStarRating(nextValue: number) {
    setState((prevState: State) => ({
      ...prevState,
      form: Object.assign({}, prevState.form, {
        internationalHotelPolicy: {
          ...prevState.form.internationalHotelPolicy,
          stars: nextValue,
        },
      }),
    }));
  }

  function handleFormClose() {
    setSafeState({
      loadingPolicyDetails: false,
      formVisible: false,
      selectedPolicyItem: null,
      form: BLANK_FORM,
      originalTargets: [],
    });
  }

  function openNewPolicy() {
    setSafeState({ formVisible: true });
  }

  function handleHotelRuleLocationChange(
    result: HotelPolicyRule,
    policyName: IHotelPolicies,
    ruleId: string,
  ) {
    setState((prevState: State) => {
      const policy: HotelPolicy = prevState.form[policyName];
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            rules: policy.rules.map((item: HotelPolicyRule) => {
              if (item.policyRuleToken === ruleId) {
                return {
                  ...item,
                  locationName: result.locationName,
                  locationCity: result.locationCity,
                  locationState: result.locationState,
                  locationCountry: result.locationCountry,
                };
              } else {
                return item;
              }
            }),
          },
        }),
      };
    });
  }

  function handleHotelRuleMaxValueChange(
    maxValue: number,
    policyName: IHotelPolicies,
    ruleId: string,
  ) {
    setState((prevState: State) => {
      const policy: HotelPolicy = prevState.form[policyName];
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            rules: policy.rules.map((item: HotelPolicyRule) => {
              if (item.policyRuleToken === ruleId) {
                return {
                  ...item,
                  maxValue,
                };
              } else {
                return item;
              }
            }),
          },
        }),
      };
    });
  }

  function handleHotelRuleAddition(
    policyName: IHotelPolicies,
    policyRuleToken: string,
  ) {
    setState((prevState: State) => {
      const policy: HotelPolicy = prevState.form[policyName];
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            rules: policy.rules.concat({
              policyRuleToken,
              locationName: "",
              locationCity: "",
              locationState: "",
              locationCountry: "",
            }),
          },
        }),
      };
    });
  }

  function handleHotelRuleRemoval(policyName: IHotelPolicies, ruleId: string) {
    setState((prevState: State) => {
      const policy: HotelPolicy = prevState.form[policyName];
      return {
        ...prevState,
        form: Object.assign({}, prevState.form, {
          [policyName]: {
            ...policy,
            rules: policy.rules.filter(
              (item: HotelPolicyRule) => item.policyRuleToken !== ruleId,
            ),
          },
        }),
      };
    });
  }

  // car

  function handleChangeCarMaxCategory(
    policyType: "national" | "international",
    value: CarCategory | null,
  ): void {
    const formData = state.form;

    if (policyType === "national") {
      formData.nationalCarPolicy.maxCategory = value;
    }

    setState((prevState) => ({ ...prevState, form: formData }));
  }

  // general

  function setErrorState(error: Error) {
    setSafeState({
      snack: {
        type: ALERT_TYPES.ERROR,
        open: true,
        message: error.description,
      },
    });
  }

  function orderPoliciesList(policies: PolicyItem[]) {
    const { company: companyPolicies, user: userPolicies } = policies.reduce(
      (acc: any, current) => {
        if (current.targetType === TARGET_TYPES.COMPANY) {
          acc.company.push(current);
        } else {
          acc.user.push(current);
        }

        return acc;
      },
      {
        company: [],
        user: [],
      },
    );

    const orderedCompanyPolicies = companyPolicies.sort(
      sortByField("description"),
    );
    const orderedUserPolicies = userPolicies.sort(sortByField("description"));

    return [].concat(orderedCompanyPolicies, orderedUserPolicies);
  }

  return (
    <PoliciesContext.Provider
      value={{
        ...state,
        closeSnackbar,
        handleAdvanceDaysChange,
        handleChangeCarMaxCategory,
        handleDeletionCancel,
        handleDeletionConfirm,
        handleFormClose,
        handleGeneralInputChange,
        handleHotelRuleAddition,
        handleHotelRuleLocationChange,
        handleHotelRuleMaxValueChange,
        handleHotelRuleRemoval,
        handleInternationalClassChange,
        handleInternationalClassDurationChange,
        handleInternationalDurationChange,
        handleInternationalHotelStarRating,
        handleMaxValueChange,
        handleNationalHotelStarRating,
        handleOptimalPriceToggle,
        handlePriceExcessChange,
        handlePriceExcessToggle,
        handleTargetDeletion,
        handleTargetSelection,
        loadPolicies,
        openNewPolicy,
        savePolicyForm,
        selectPolicyForEdition,
        selectPolicyToDelete,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
};

export const usePolicies = useContextFactory(
  "PoliciesContext",
  PoliciesContext,
);
