import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { css } from "emotion";
import { darken } from "polished";
import { theme } from "smartrips-skin";

import { MEDIA_QUERIES } from "~/constants";

import { Input } from "@shared/inputs";

const v1Styles = {
  container: css({
    width: "50%",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      width: "100%",
    },
    [".MuiInputBase-root"]: {
      height: 58,
      padding: 16,
    },
    [".MuiSelect-root"]: {
      padding: "0 24px 0 0 !important",
      ["&:focus"]: {
        backgroundColor: "#FFF",
      },
    },
    [".MuiOutlinedInput-notchedOutline"]: {
      paddingLeft: "0px !important",
      ["&::after"]: {
        top: "calc(100% - 1px)",
      },
    },
    [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
      borderColor: darken(0.2, theme.colors.border),
    },
  }),
};

const v2Styles = {
  container: css({
    width: "fit-content",
    [".MuiFormControl-root"]: {
      display: "unset",
    },
    [".MuiInputBase-root"]: {
      borderRadius: "0",
      height: "36px",
      padding: "0",
    },
    [".MuiSelect-root"]: {
      padding: "0 32px 0 0 !important",
      ["&:focus"]: {
        backgroundColor: "#FFF",
      },
    },
    [".MuiOutlinedInput-notchedOutline"]: {
      border: "none",
      paddingLeft: "0px !important",
      ["&::after"]: {
        top: "calc(100% - 1px)",
      },
    },
    [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
      border: "none",
    },
  }),
};

export const CABIN_CLASSES_MAP: Record<string, CabinClass> = {
  business: { name: "Business", value: "business" },
  economy: { name: "Econômica", value: "economy" },
  first: { name: "Primeira classe", value: "first" },
  premium_economy: { name: "Econômica premium", value: "premium_economy" },
};

interface CabinClass {
  value: string;
  name: string;
}

type Props = {
  handleCabinChange: (cabin: string) => void;
  value: string;
  version?: "v1" | "v2";
};

const CabinClassSelect = ({
  handleCabinChange,
  value,
  version = "v1",
}: Props) => {
  const { clientConfig } = useClientConfig();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleCabinChange(e.target.value);
  };

  const clientCabinClassOptions = clientConfig?.getFlightAllowedCabinClasses();

  const cabinClassOptions = clientCabinClassOptions
    ? clientCabinClassOptions.map((cabinClass) => CABIN_CLASSES_MAP[cabinClass])
    : Object.values(CABIN_CLASSES_MAP);

  return (
    <Input
      containerClasses={
        version === "v1" ? v1Styles.container : v2Styles.container
      }
      id="cabinClass"
      name="cabinClass"
      onChange={handleChange}
      select={true}
      underlineHighlight={true}
      value={value}
    >
      {cabinClassOptions.map((cabinClass: CabinClass) => (
        <MenuItem
          key={cabinClass.name + "_" + cabinClass.value}
          value={cabinClass.value}
        >
          {cabinClass.name}
        </MenuItem>
      ))}
    </Input>
  );
};

export { CabinClassSelect };
