import React from "react";
import { connect } from "react-redux";
import { UserModel } from "@models/user.model";

interface ReduxState {
  user: UserModel;
}

export interface PermissionVisibilityProps {
  userPermissions?: Record<string, any>;
  permissions: string[];
  children: JSX.Element;
  atLeastOne?: boolean;
}

const checkClientPermission = (
  permission: string,
  userPermissions: Record<string, any> | undefined
) => {
  if (!userPermissions) {
    return false;
  }

  const [scope, type] = permission.split(".");

  if (
    typeof userPermissions![scope] !== "boolean" &&
    typeof userPermissions![scope][type] !== "boolean"
  ) {
    // throw new Error(`A permissão ${permission} não existe.`);
    return false;
  }

  return !type
    ? userPermissions![scope] === true
    : userPermissions![scope][type] === true;
};

const hasPermission = (
  permissions: string[],
  userPermissions: Record<string, any> | undefined,
  atLeastOne: boolean
) => {
  if (atLeastOne) {
    return permissions.some(permission => {
      return checkClientPermission(permission, userPermissions);
    });
  }

  return permissions.every(permission => {
    return checkClientPermission(permission, userPermissions);
  });
};

const PermissionVisibility: React.FC<PermissionVisibilityProps> = ({
  permissions,
  userPermissions,
  atLeastOne = false,
  children
}) => {
  return hasPermission(permissions, userPermissions, atLeastOne)
    ? children
    : null;
};

export default connect((state: ReduxState) => ({
  userPermissions: state.user.permissions
}))(PermissionVisibility);
