import { NestedKeyOf, NestedObjectValues } from "../../types";

export enum AdvancedCategory {
  CompactElite = "D",
  Fullsize = "F",
  MiniElite = "N",
  Premium = "P",
}

export enum ApprovalListStatus {
  APPROVED = "approved",
  PENDING = "pending",
}

export enum ApprovalProcessTypes {
  AREA = "AREA",
  COMPANY = "COMPANY",
  COST_CENTER = "COST-CENTER",
  PROJECT = "PROJECT",
  USER = "USER",
}

export enum AreaType {
  AREA = "area",
  BOARD = "board",
}

export enum BasicCategory {
  Economy = "E",
  Compact = "C",
  Intermediate = "I",
}

export enum BookingFeeOptions {
  FIXED_MONTHLY = "FIXED_MONTHLY",
  NONE = "NONE",
  OFFER_INDIVIDUALLY = "OFFER_INDIVIDUALLY",
  OFFER_MONTHLY = "OFFER_MONTHLY",
  TRAVEL_INDIVIDUALLY = "TRAVEL_INDIVIDUALLY",
  TRAVEL_MONTHLY = "TRAVEL_MONTHLY",
}

export enum BookingFeeStatus {
  FAILED = "FAILED",
  NONE = "NONE", // shouldnt exist
  PAID = "PAID",
  PENDING = "PENDING",
  UNITIATED = "UNITIATED",
}

export enum BookingStatus {
  DROPPED = "DROPPED",
  EMITTED = "EMITTED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  UNITIATED = "UNITIATED",
}

export enum CancelingStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  UNITIATED = "UNITIATED",
}

export const capabilities = {
  ANALYTICS: "analytics",
  APPROVAL_ANY_TIME_FOR_OTHERS: "approvalAnyTimeForOthers",
  APPROVALS: "approvals",
  BASIC: "basic",
  BATCH_ACTIONS: "batchActions",
  CONFIGURATIONS: "configurations",
  EVENT_ORGANIZER: "eventOrganizer",
  EXPENSE_FOR_OTHERS: "expenseForOthers",
  EXPENSES: "expenses",
  EXTERNAL_CREDENTIALS: "externalCredentials",
  FINANCIAL: "financial",
  MANUAL_MANAGEMENT: "manualManagement",
  REVIEWS: "reviews",
  SEARCH: "search",
  SELF_SEARCH: "selfSearch",
  TRAVELS: "travels",
} as const;

export type Capabilities = Record<CapabilitiesLiterals, boolean>;

export type CapabilitiesLiterals = typeof capabilities[keyof typeof capabilities];

export enum CardOwnerType {
  CLIENT = "client",
  USER = "user",
}

export enum CardPayerType {
  COMPANY = "COMPANY",
  PAYER = "PAYER",
  TRAVELER = "TRAVELER",
}

export enum CardProvider {
  B2B = "b2b",
  BANK3 = "bank3",
  REGULAR = "regular",
}

export enum CardServiceType {
  // TERRESTRIAL = "terrestrial",
  BUS = "bus",
  CAR = "car",
  FLIGHT = "flight",
  GENERAL = "general",
  HOTEL = "hotel",
  OTHER = "other",
  RIDE = "ride",
}

export enum CarCategory {
  ADVANCED = "ADVANCED",
  BASIC = "BASIC",
  INTERMEDIATE = "INTERMEDIATE",
  SPECIAL = "SPECIAL",
}

export enum ChangeStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  REQUESTED = "REQUESTED",
  SUCCESS = "SUCCESS",
  UNITIATED = "UNITIATED",
}

export enum ChangeType {
  ALTERED = "ALTERED",
  PARTIALLY_ALTERED = "PARTIALLY_ALTERED",
}

export enum ChatProvider {
  JIVO = "JIVO",
  OCTADESK = "OCTADESK",
}

export enum ClientBookingPhoneConfig {
  NOT_REQUIRED = "NOT_REQUIRED",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

//Ideal colocar no SM-types
export enum ClientApprovalConfigType {
  REGULAR = "REGULAR",
  NAVAN_SINGLE_APPROVER = "NAVAN_SINGLE_APPROVER",
  BOSS_FLOW = "BOSS_FLOW",
}

export enum ClientActivationStatus {
  INACTIVATED = "INACTIVATED",
  OBFUSCATED = "OBFUSCATED",
  ACTIVE = "ACTIVE",
}

export enum ApprovalPolicyCondition {
  ALL = "ALL",
  IN = "IN",
  OUT = "OUT",
}

export enum ApprovalTerritoryCondition {
  ALL = "ALL",
  NATIONAL = "NATIONAL",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum ApprovalAction {
  NOTIFY = "NOTIFY",
  APPROVE = "APPROVE",
}
//-----

export enum ClientApprovalOption {
  REGULAR = "REGULAR",
  SINGLE = "SINGLE",
  SINGLE_APPROVAL = "SINGLE_APPROVAL",
  SINGLE_NOTIFICATION = "SINGLE_NOTIFICATION",
}

export enum CorporateCardType {
  INDIVIDUAL = "INDIVIDUAL",
  PUBLIC = "PUBLIC",
}

export enum CountryRestrictionSeverity {
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export enum CountryRestrictionType {
  ALLOW_WITH_WARNING = "allow-with-warning",
  BLOCK_ABROAD = "block-abroad",
  BLOCK_ALL = "block-all",
}

export enum CurrencyCode {
  BRL = "BRL",
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  ARS = "ARS",
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  AUD = "AUD",
  AZN = "AZN",
  BAM = "BAM",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BND = "BND",
  BOB = "BOB",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GNF = "GNF",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MOP = "MOP",
  MUR = "MUR",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SOS = "SOS",
  SYP = "SYP",
  THB = "THB",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  XAF = "XAF",
  XOF = "XOF",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum CurrencySymbol {
  BRL = "R$",
  USD = "$",
  CAD = "C$",
  EUR = "€",
  ARS = "$",
  AED = "د.إ",
  AFN = "؋",
  ALL = "L",
  AMD = "֏",
  AUD = "A$",
  AZN = "₼",
  BAM = "KM",
  BDT = "৳",
  BGN = "лв",
  BHD = "ب.د",
  BIF = "₣",
  BND = "B$",
  BOB = "Bs.",
  BWP = "P",
  BYN = "Br",
  BZD = "BZ$",
  CDF = "₣",
  CHF = "CHF",
  CLP = "$",
  CNY = "¥",
  COP = "$",
  CRC = "₡",
  CVE = "$",
  CZK = "Kč",
  DJF = "₣",
  DKK = "kr",
  DOP = "RD$",
  DZD = "د.ج",
  EEK = "KR",
  EGP = "£",
  ERN = "Nfk",
  ETB = "Br",
  GBP = "£",
  GEL = "₾",
  GHS = "₵",
  GNF = "₣",
  GTQ = "Q",
  HKD = "HK$",
  HNL = "L",
  HRK = "kn",
  HUF = "Ft",
  IDR = "Rp",
  ILS = "₪",
  INR = "₹",
  IQD = "ع.د",
  IRR = "﷼",
  ISK = "kr",
  JMD = "J$",
  JOD = "د.ا",
  JPY = "¥",
  KES = "KSh",
  KHR = "៛",
  KMF = "₣",
  KRW = "₩",
  KWD = "د.ك",
  KZT = "₸",
  LBP = "ل.ل",
  LKR = "₨",
  LTL = "Lt",
  LVL = "Ls",
  LYD = "ل.د",
  MAD = "د.م.",
  MDL = "L",
  MGA = "Ar",
  MKD = "ден",
  MMK = "K",
  MOP = "MOP$",
  MUR = "₨",
  MXN = "$",
  MYR = "RM",
  MZN = "MT",
  NAD = "$",
  NGN = "₦",
  NIO = "C$",
  NOK = "kr",
  NPR = "₨",
  NZD = "NZ$",
  OMR = "ر.ع.",
  PAB = "B/.",
  PEN = "S/",
  PHP = "₱",
  PKR = "₨",
  PLN = "zł",
  PYG = "₲",
  QAR = "ر.ق",
  RON = "lei",
  RSD = "дин",
  RUB = "₽",
  RWF = "₣",
  SAR = "ر.س",
  SDG = "ج.س.",
  SEK = "kr",
  SGD = "S$",
  SOS = "S",
  SYP = "ل.س",
  THB = "฿",
  TND = "د.ت",
  TOP = "T$",
  TRY = "₺",
  TTD = "TT$",
  TWD = "NT$",
  TZS = "Sh",
  UAH = "₴",
  UGX = "Sh",
  UYU = "$",
  UZS = "лв",
  VEF = "Bs",
  VND = "₫",
  XAF = "₣",
  XOF = "₣",
  YER = "﷼",
  ZAR = "R",
  ZMW = "ZK",
  ZWL = "$",
}

export enum DocumentType {
  CPF = "id",
  PASSPORT = "passport",
  RG = "rg",
}

export enum DocumentTarget {
  BRAZILIAN = "BRAZILIAN",
  FOREIGNER = "FOREIGNER",
  UNIDENTIFIED = "UNIDENTIFIED",
}

export enum ExpenseCategory {
  APARTMENT = "apartment",
  BAGGAGE = "baggage",
  BREAKFAST = "breakfast",
  BUS = "bus",
  CANCELING = "canceling",
  CAR = "car",
  CAR_ADDITIONAL = "car-additional",
  CUSTOM = "custom",
  DINNER = "dinner",
  EXPENSE_FEE = "expense-fee",
  FEE = "fee",
  FLIGHT = "flight",
  HOTEL = "hotel",
  HOTEL_ADDITIONAL = "hotel-additional",
  INSURANCE = "insurance",
  KILOMETER = "kilometer",
  LUNCH = "lunch",
  OTHER = "other",
  RIDE_HEILING = "ride-heiling",
  SEAT = "seat",
  TRANSPORT = "transport",
}

export const FeatureLevel = {
  ADVANCED: "advanced",
  BASIC: "basic",
  NONE: "none",
} as const;

export type FeatureLevelLiterals = typeof FeatureLevel[keyof typeof FeatureLevel];

export enum FieldRequirement {
  NONE = "NONE",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export enum GenderType {
  FEMALE = "F",
  MALE = "M",
}

export enum IntermediateCategory {
  IntermediateElite = "J",
  Mini = "M",
  Standard = "S",
}

export enum OfferStatus {
  APPROVAL_DECLINED = "APPROVAL_DECLINED",
  BOOKING_FAILED = "BOOKING_FAILED",
  BOOKING_PROCESSING = "BOOKING_PROCESSING",
  CANCELED = "CANCELED",
  CANCELING = "CANCELING",
  DRAFT = "DRAFT",
  EMITTED = "EMITTED",
}

export enum OfferType {
  BUS = "BUS",
  CAR = "CAR",
  FLIGHT = "FLIGHT",
  HOTEL = "HOTEL",
}

export enum OrderItemStatus {
  FAILED = "FAILED",
  NOT_SURE = "NOT_SURE",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  TIME_OUT = "TIME_OUT",
}

export enum PayableService {
  BUS = "bus",
  CAR = "car",
  FEE = "fee",
  FLIGHT = "flight",
  HOTEL = "hotel",
}

export enum PaymentType {
  BILLED = "billed",
  BILLED_SUPPLIER = "supplier_billed",
  CREDIT_CARD = "credit_card",
  DEBT_CARD = "debt_card",
  MONEY = "money",
}

export enum PaymentOption {
  AGENCY_BILLED = "AGENCY_BILLED", // when we bill the client, but use our own credit card behind the scenes
  BILLED = "BILLED",
  CLIENT_CREDIT = "CLIENT_CREDIT", // when client uses his own credit card
  CREDIT_CARD = "CREDIT_CARD",
  SUPPLIER_BILLED = "SUPPLIER_BILLED", // when we regularly bill the client
}

export type Permissions = Partial<{
  analytics: Partial<{
    centerCostBalance: boolean;
    economy: boolean;
    excelReportDownloads: boolean;
    generalSpending: FeatureLevelLiterals;
    notFlownTickets: boolean;
    policy: boolean;
    realTimeTravels: boolean;
    waste: boolean;
  }>;
  companySettings: Partial<{
    areas: boolean;
    billingProfiles: boolean;
    costCenters: boolean;
    projects: boolean;
  }>;
  expenses: Partial<{
    advances: boolean;
    km: boolean;
    policies: boolean;
  }>;
  externalAPI: boolean;
  support: Partial<{
    concierge: boolean;
    fullDay: boolean;
    phone: boolean;
    whatsapp: boolean;
  }>;
  travels: Partial<{
    checkin: boolean;
    countryRestrictions: boolean;
    gamification: boolean;
    negotiations: boolean;
    policies: boolean;
    tags: boolean;
    visaAdvisory: boolean;
  }>;
}>;

export type PermissionsValuesLiterals = NestedObjectValues<Permissions>;

export type PermissionsKeysLiterals = NestedKeyOf<Required<Permissions>>;

export enum ServiceType {
  BUS = "bus",
  CAR = "car",
  FLIGHT = "flight",
  HOTEL = "hotel",
  INSURANCE = "insurance",
}

export enum SpecialCategory {
  Luxury = "L",
  LuxuryElite = "W",
  Special = "X",
}

export enum SSOConfigProvider {
  AUTH0 = "AUTH0",
  CUSTOM = "CUSTOM",
  GOOGLE = "GOOGLE",
  MICROSOFT_AZURE = "MICROSOFT_AZURE",
  OKTA = "OKTA",
  PING_ID = "PING_ID",
}

export enum TravelIntegrationOption {
  NONE = "NONE",
  TRIPACTIONS = "TRIPACTIONS",
}

export enum TripsStatusFilter {
  HAS_APPROVAL_PENDING = "hasApprovalPending",
  HAS_BOOKING_FAILED = "hasBookingFailed",
  HAS_BOOKING_PENDING = "hasBookingPending",
  HAS_CANCELING_PENDING = "hasCancelingPending",
}

export enum TripsTabsFilter {
  ALL = "all",
  CANCELED = "canceled",
  DRAFT = "draft",
  NOW = "now",
  PAST = "past",
  UPCOMING = "upcoming",
}

export enum UserBookingPhoneConfig {
  NOT_REQUIRED = "NOT_REQUIRED",
  UNITIATED = "UNITIATED",
}
