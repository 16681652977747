import React, { useEffect } from "react";
import Layout from "@shared/Layout";
import {
  SupportEvaluationProvider,
  useSupportEvaluationActionsContext,
  useSupportEvaluationContext
} from "./SupportEvaluation.context";
import SpinnerPortal from "@components/shared/Spinner";
import PageTitle from "@components/shared/PageTitle";
import { css } from "@styled-system/css";
import styled from "@shared/styled";
import { EvaluationForm } from "./form";
import { Box, Text } from "@toolkit";
import { string } from "prop-types";
import { SubmittedMessage } from "./SubmittedMessage";

const ContentContainer = styled("div")(
  css({
    py: "large",
    pb: ["5rem", "large"],
    px: ["small", "medium"],
    mx: "auto",
    height: "100%",
    width: "100%",
    maxWidth: ["auto", "auto", "1050px"]
  })
);

const SupportEvaluation = props => {
  const { isFetchingStatus, submitted } = useSupportEvaluationContext();
  const { fetchEvaluationStatus } = useSupportEvaluationActionsContext();

  useEffect(() => {
    const { supportEvaluationToken } = props;
    fetchEvaluationStatus(supportEvaluationToken);
  }, []);

  return (
    <Layout>
      <PageTitle title="Pesquisa de satisfação" />
      <SpinnerPortal visible={isFetchingStatus} />
      <ContentContainer>
        {submitted ? (
          <SubmittedMessage />
        ) : (
          <React.Fragment>
            <Box mb="large">
              <Text fontSize={[3]} fontWeight="bold">
                Avaliação de atendimento
              </Text>
            </Box>
            <EvaluationForm />
          </React.Fragment>
        )}
      </ContentContainer>
    </Layout>
  );
};

SupportEvaluation.propTypes = {
  supportEvaluationToken: string
};

const Container = props => (
  <SupportEvaluationProvider>
    <SupportEvaluation {...props} />
  </SupportEvaluationProvider>
);

Container.propTypes = {
  supportEvaluationToken: string
};

export { Container as SupportEvaluation };
