import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "24px",
      position: "sticky",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
  },
  body: {
    card: {
      left: css({
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: "16px",
        width: "100%",
      }),
      right: {
        card: css({}),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "0 24px",
          width: "100%",
          "@media screen and (max-width: 768px)": {
            padding: "16px 0",
          },
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "16px",
        "@media screen and (max-width: 768px)": {
          flexDirection: "column-reverse",
        },
      }),
    },
    input: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "@media screen and (max-width: 768px)": {
          minWidth: "288px",
        },
      }),
    },
    row: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
      "& > div": {
        flex: 1,
      },
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "16px",
      padding: "24px",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
    select: {
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    services: {
      checkbox: css({
        alignItems: "center",
        display: "flex",
        minWidth: "192px",
        "& > :nth-child(2)": {
          cursor: "pointer",
        },
      }),
      label: css({
        color: theme.colors.gray[700],
        cursor: "pointer",
        fontWeight: 600,
        marginLeft: "8px",
        width: "100%",
      }),
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        padding: "16px",
        width: "100%",
        "& > label": {
          flex: 1,
        },
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  }),
};
