import { removeBlankSpaces, removeSpecialCharacters } from "~/helpers";
import { validateCnpj } from "~/helpers/document.helper";

import { MaskFunction, MaskType } from "./MaskedInput.types";

export const MaskEnum = {
  CPF: [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/
  ],
  CNPJ: [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/
  ],
  Zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
  Phone: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  cellphone: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  Date: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  Datetime: [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":"
  ],
  Time: [/\d/, /\d/, ":", /\d/, /\d/],
  CVV_3: [/\d/, /\d/, /\d/],
  CVV_4: [/\d/, /\d/, /\d/, /\d/],
  MonthAndYear: [/\d/, /\d/, "/", /[1-5]/, /\d/],
  ExpirationDate: [/\d/, /\d/, "/", /\d/, /\d/]
};

function getPhoneMask(value = "") {
  const withouSpecialChars = removeSpecialCharacters(value);
  const formattedValue = removeBlankSpaces(withouSpecialChars);

  if (formattedValue.length <= 10) {
    return MaskEnum.Phone;
  } else {
    return MaskEnum.cellphone;
  }
}

function getCvvMask(value = "") {
  return value.length <= 3 ? MaskEnum.CVV_3 : MaskEnum.CVV_4;
}

export function getMask(args: { value?: string; mask: MaskType }) {
  const { value, mask } = args;

  if (typeof mask !== "string") {
    return mask;
  }

  const masks: Record<
    Exclude<MaskType, MaskFunction>,
    (string | RegExp)[] | MaskFunction
  > = {
    cpf: MaskEnum.CPF,
    cnpj: MaskEnum.CNPJ,
    "cpf-cnpj": !validateCnpj(value) ? MaskEnum.CPF : MaskEnum.CNPJ,
    cep: MaskEnum.Zipcode,
    phone: MaskEnum.Phone,
    cellphone: MaskEnum.cellphone,
    "phone-or-cellphone": getPhoneMask,
    date: MaskEnum.Date,
    datetime: MaskEnum.Datetime,
    time: MaskEnum.Time,
    "month-and-year": MaskEnum.MonthAndYear,
    cvv: getCvvMask
  };

  return masks[mask] || mask;
}
