import { useEffect, useMemo } from "react";

import { useItineraryServices } from "../../../itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { TravelPlanServicesCardTab } from "./travel-plan-services-card";
import { TravelPlanServicesCardPresenterFactory } from "./travel-plan-services-card.presenter";

type UseTravelPlanServicesCardDefaultTabParams = {
  setSelectedTab: (tab: TravelPlanServicesCardTab) => void;
};

export const useTravelPlanServicesCardDefaultTab = ({
  setSelectedTab,
}: UseTravelPlanServicesCardDefaultTabParams) => {
  const {
    travelPlanServicesCardPresenter,
  } = useTravelPlanServicesCardPresenter();

  useEffect(() => {
    if (!travelPlanServicesCardPresenter) {
      return;
    }

    if (!travelPlanServicesCardPresenter.shouldUseCanceledTabAsDefault()) {
      return;
    }

    setSelectedTab("cancelamentos");
  }, [travelPlanServicesCardPresenter]);
};

export const useTravelPlanServicesCardGuard = () => {
  const {
    errorOnFetch,
    isLoading: isLoadingItineraryServices,
    isLoadingTravelPoliciesPerOffer,
  } = useItineraryServices();

  return {
    error: errorOnFetch,
    loading: isLoadingItineraryServices || isLoadingTravelPoliciesPerOffer,
  };
};

export const useTravelPlanServicesCardPresenter = () => {
  const { servicesPresenter } = useItineraryContainer();

  return useMemo(
    () => ({
      travelPlanServicesCardPresenter: servicesPresenter
        ? TravelPlanServicesCardPresenterFactory.create(servicesPresenter)
        : null,
    }),
    [servicesPresenter],
  );
};
