import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.gray[300],
    cursor: "pointer",
    ":disabled": {
      cursor: "default",
      opacity: 0.75,
    },
  }),
  span: css({
    display: "flex",
  }),
};
