import React, { useCallback, useMemo, useState } from "react";
import { GroupBase, OptionProps } from "react-select";
import { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import { defaultTheme } from "~/assets/styles/theme";

import { APPROVAL_PROCESS_TYPES } from "~/constants";

import { UserSearch } from "~/models/user.model";

import { TargetItem } from "~/components/configuration/shared/TargetItem";
import { useFormContext } from "~/components/shared/form";

import { searchApproverUsersByNameAndEmail } from "../../approval-processes.service";

const customStyles: StylesConfig<UserSearch, boolean, GroupBase<UserSearch>> = {
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "0",
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    padding: "0.5rem 0.5rem 0 0.5rem",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: "18rem",
    padding: "0 0.5rem",
    borderRadius: "0.25rem",
    border: `1px solid ${
      state.selectProps.menuIsOpen ? defaultTheme.primaryColor : `#c9d3df`
    }`,
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      borderColor: defaultTheme.primaryColor,
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: "#989ba1",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    width: "18rem",
    height: "14rem",
    boxShadow: "none",
    marginTop: "0",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    height: "13rem",
  }),
};

function AutoCompleteUserOption(props: OptionProps<UserSearch>) {
  const [hover, setHover] = useState(false);

  const { data, selectOption } = props;

  return (
    <TargetItem
      item={{
        email: data.email,
        label: data.fullName,
        fullName: data.fullName,
      }}
      onClick={() => selectOption(data)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        color: "#4c5566",
        backgroundColor: hover ? "#f9fafc" : "initial",
      }}
      {...props}
    />
  );
}

interface IUserAutoCompleteSelect {
  actionAddApprover: (data: {
    userToken: string;
    email: string;
    fullName: string;
  }) => void;
  ruleIndex: number;
}

export const UserAutoCompleteSelect = ({
  actionAddApprover,
  ruleIndex,
}: IUserAutoCompleteSelect) => {
  const { watch } = useFormContext();
  const typeField = watch(`rules[${ruleIndex}].type`);

  const handleSearchApprovers = useCallback(
    async (searchQuery: string): Promise<UserSearch[]> => {
      const result = await searchApproverUsersByNameAndEmail(searchQuery);
      return result.data as UserSearch[];
    },
    [],
  );

  const loadOptions = useMemo(
    () =>
      debounce((text: string, callback) => {
        void handleSearchApprovers(text).then(callback);
      }, 300),
    [handleSearchApprovers],
  );

  return (
    <AsyncSelect
      escapeClearsValue
      controlShouldRenderValue={false}
      placeholder={
        typeField?.value === APPROVAL_PROCESS_TYPES.NOTIFY
          ? "Buscar destinatário"
          : "Buscar aprovador"
      }
      onChange={actionAddApprover}
      noOptionsMessage={() => "Nenhum usuário encontrado"}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: AutoCompleteUserOption,
      }}
      getOptionValue={(option) => option.userToken}
      getOptionLabel={(option) => option.fullName}
      loadOptions={loadOptions}
      styles={customStyles}
      autoFocus
      menuIsOpen
    />
  );
};
