import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";

import { TagsProvider } from "../../configurations/views/company/tags/tags.context";
import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { ItineraryServiceDetailsDrawer } from "../itinerary/itinerary-content/itinerary-service-details-drawer/itinerary-service-details-drawer";
import { useItineraryInfo } from "../itinerary/itinerary-info.context";
import { useItineraryPendencies } from "../itinerary/itinerary-pendencies.context";
import { ItineraryServiceChoiceContextDialog } from "../itinerary/itinerary-service-choice-context-dialog/itinerary-service-choice-context-dialog";
import { ItineraryServiceOutOfPolicyDialog } from "../itinerary/itinerary-service-out-of-policy-dialog/itinerary-service-out-of-policy-dialog";
import { useItineraryServices } from "../itinerary/itinerary-services.context";
import { ItineraryTravelCategorizationDialog } from "../itinerary/itinerary-travel-categorization-dialog/itinerary-travel-categorization-dialog";
import { ItineraryTravelTagsDialog } from "../itinerary/itinerary-travel-tags-dialog/itinerary-travel-tags-dialog";
import { ItineraryProvider } from "../itinerary/itinerary.context";
import { ApprovalReviewContent } from "./approval-review-content/approval-review-content";
import { ApprovalReviewHeader } from "./approval-review-header/approval-review-header";
import { ApprovalReviewProvider } from "./approval-review.context";
import { useApprovalReviewGuard } from "./approval-review.hooks";
import { styles } from "./styles";

type Props = RouteComponentProps<{
  travelToken: string;
}>;

const Component: React.FC<Props> = () => {
  const { enabled, error } = useApprovalReviewGuard();

  const { fetchTravelApproval } = useItineraryApproval();
  const { fetchItineraryInfo } = useItineraryInfo();
  const { fetchItineraryPendencies } = useItineraryPendencies();
  const { fetchTravelPoliciesPerOffer } = useItineraryServices();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    void fetchItineraryInfo();
    void fetchItineraryPendencies();
    void fetchTravelApproval();
    void fetchTravelPoliciesPerOffer();
  }, [
    enabled,
    fetchItineraryInfo,
    fetchItineraryPendencies,
    fetchTravelApproval,
    fetchTravelPoliciesPerOffer,
  ]);

  if (error) {
    return (
      <>
        <PageTitle title={pageTitles.APPROVAL_REVIEW} />
        <div css={styles.root}>
          <ApprovalReviewHeader />
          <ErrorPage {...error} />
        </div>
      </>
    );
  }

  return (
    <>
      <PageTitle title={pageTitles.APPROVAL_REVIEW} />
      <div css={styles.root}>
        <ApprovalReviewHeader />
        <ApprovalReviewContent />
      </div>
      <ItineraryServiceChoiceContextDialog />
      <ItineraryServiceDetailsDrawer />
      <ItineraryServiceOutOfPolicyDialog />
      <ItineraryTravelCategorizationDialog />
      <ItineraryTravelTagsDialog />
    </>
  );
};

export const ApprovalReview: React.FC<Props> = ({ ...props }) => {
  const { travelToken } = props;

  if (!travelToken) {
    return null;
  }

  return (
    <TagsProvider>
      <ItineraryProvider travelToken={travelToken}>
        <ApprovalReviewProvider>
          <Component {...props} />
        </ApprovalReviewProvider>
      </ItineraryProvider>
    </TagsProvider>
  );
};
