import React from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { useUserAgencyChatConfig } from "~/apps/corporate/hooks/use-user-agency-chat-config";

import { ChatProvider } from "../../constants/enums";
import { LivechatJivoChat } from "./livechat-jivo-chat/livechat-jivo-chat";
import { LivechatOctadeskChat } from "./livechat-octadesk-chat/livechat-octadesk-chat";

export const Livechat: React.FC = () => {
  const { user } = useUser();

  const {
    chatConfig,
    errorOnFetchChatConfig,
    isLoadingChatConfig,
  } = useUserAgencyChatConfig(user);

  if (!chatConfig || errorOnFetchChatConfig || isLoadingChatConfig) {
    return null;
  }

  switch (chatConfig.provider) {
    case ChatProvider.JIVO:
      return <LivechatJivoChat chatConfig={chatConfig} />;
    case ChatProvider.OCTADESK:
      return <LivechatOctadeskChat chatConfig={chatConfig} />;
    default:
      return null;
  }
};
