import React from "react";
import { DesktopItem } from "./DesktopItem";
import { MobileItem } from "./MobileItem";
import { Desktop, TabletAndMobile } from "@shared/layout/Responsive";
import { AdvancedExpense } from "../AdvancedExpenses.types";

interface Props {
  item: AdvancedExpense;
  handleOpenDetails: (expenseAdvanceToken: string) => () => void;
  addToReport: (
    expenseAdvanceToken: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}

const AdvancedExpenseItem = (props: Props) => {
  return (
    <>
      <Desktop>
        <DesktopItem {...props} />
      </Desktop>
      <TabletAndMobile>
        <MobileItem {...props} />
      </TabletAndMobile>
    </>
  );
};

export { AdvancedExpenseItem };
