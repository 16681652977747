import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useItineraryScreen } from "../../itinerary-screen.context";
import { styles } from "./styles";

export const ItineraryHeaderDeleteTravelButton: React.FC = () => {
  const { openTravelDeletionAlertDialog } = useItineraryScreen();

  return (
    <Tooltip content="Excluir" position="top">
      <button
        css={styles.button}
        onClick={() => {
          openTravelDeletionAlertDialog();
        }}
      >
        <Icon size={16} use="trash" />
      </button>
    </Tooltip>
  );
};
