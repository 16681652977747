import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    fontSize: "0.875rem",
    justifyContent: "center",
    width: "100%",
  }),
  deny: {
    info: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
      fontWeight: 700,
    }),
    label: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      lineHeight: "1.25",
    }),
    name: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    optional: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    textarea: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      fontSize: "0.875rem",
      fontFamily: theme.fonts.inter,
      outline: "none",
      marginTop: "8px",
      height: "148px",
      padding: "13px 16px",
      resize: "none",
      transition: "all 0.15s ease-in-out",
      width: "100%",
      "&:focus": {
        borderColor: theme.colors.gray[500],
      },
    }),
  },
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  link: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    border: "1px solid",
    borderColor: theme.colors.pink[500],
    borderRadius: "8px",
    color: theme.colors.pink[500],
    display: "flex",
    fontSize: "0.875rem",
    fontWeight: 600,
    gap: "12px",
    justifyContent: "center",
    lineHeight: "1rem",
    padding: "14px 24px",
    textDecoration: "none",
    transition: "all 0.15s ease-in-out",
    ":hover": {
      color: theme.colors.pink[700],
      borderColor: theme.colors.pink[700],
    },
  }),
  warning: {
    icon: css({}),
    root: css({
      backgroundColor: "#ffe8ab",
      borderColor: "#ffe8ab",
      color: "#806300",
      fontWeight: 500,
      gap: "16px",
      lineHeight: "1.125",
      padding: "16px 24px",
      ":hover": {
        backgroundColor: "#ffe8ab",
        borderColor: "#ffe8ab",
      },
    }),
    strong: css({
      fontWeight: 600,
    }),
    text: css({}),
  },
};
