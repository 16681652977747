import React from "react";

import { CARD_ISSUERS } from "~/apps/shared/constants/credit-card.constant";

import { styles } from "./styles";

type Props = {
  creditCard: {
    brand: string;
    description: string;
    lastDigits: string;
  };
};

export const ItineraryServiceDetailsDrawerCreditCardInfo: React.FC<Props> = ({
  creditCard,
}) => {
  const { brand, description, lastDigits } = creditCard;

  const cardIssuer = CARD_ISSUERS.find(
    (cardIssuer) => cardIssuer.brand === brand,
  );

  return (
    <div css={styles.root}>
      {cardIssuer?.logo && (
        <img
          alt={`Logo da ${cardIssuer.brand}`}
          css={styles.image}
          height={20}
          src={cardIssuer.logo}
          width={24}
        />
      )}
      <span css={styles.text}>
        Forma de pagamento: {description} ({brand !== "bank3" && "final"}{" "}
        {lastDigits}).
      </span>
    </div>
  );
};
