import React, { useCallback } from "react";

import { MenuItem, Tab, Tabs } from "@material-ui/core";
import { css } from "emotion";

import { defaultTheme } from "~/assets/styles/theme";

import { MEDIA_QUERIES } from "~/constants";

import { Input } from "@shared/inputs";

const v1Styles = {
  tabs: css({
    borderBottom: "1px solid #FAFAFA",
    margin: "0.5rem 0 0 0",
    minHeight: "36px",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      marginBottom: 8
    }
  }),
  indicator: css({
    backgroundColor: defaultTheme.primaryColor,
    borderRadius: 4
  }),
  scroller: css({
    height: 30
  }),
  tab: css({
    borderRadius: 4,
    fontFamily: "'Open sans', sans-serif",
    fontSize: 15,
    letterSpacing: "0.04rem",
    minHeight: "30px",
    minWidth: "75px",
    padding: "0 .5rem",
    paddingBottom: "0.1rem",
    textTransform: "none",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      fontSize: 14
    }
  }),
  selected: css({
    color: defaultTheme.primaryColor,
    fontWeight: "bold"
  })
};

const v2Styles = {
  container: css({
    width: "fit-content",
    [".MuiFormControl-root"]: {
      display: "unset"
    },
    [".MuiInputBase-root"]: {
      borderRadius: "0",
      height: "36px",
      padding: "0"
    },
    [".MuiSelect-root"]: {
      padding: "0 32px 0 0 !important",
      ["&:focus"]: {
        backgroundColor: "#FFF"
      }
    },
    [".MuiOutlinedInput-notchedOutline"]: {
      border: "none",
      paddingLeft: "0px !important",
      ["&::after"]: {
        top: "calc(100% - 1px)"
      }
    },
    [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
      border: "none"
    }
  })
};

const types = [
  {
    label: "Ida e volta",
    value: "roundtrip"
  },
  {
    label: "Só ida",
    value: "oneway"
  }
] as const;

type Type = typeof types[number]["value"];

type Props = {
  onChange: (_: any, type: Type) => void;
  value: Type;
  version?: "v1" | "v2";
};

export const FlightTypeSelect: React.VFC<Props> = ({
  onChange,
  value,
  version = "v1"
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(null, e.target.value as Type);
    },
    [onChange]
  );

  if (version === "v2") {
    return (
      <Input
        containerClasses={v2Styles.container}
        id="type"
        name="type"
        onChange={handleChange}
        select={true}
        underlineHighlight={true}
        value={value}
      >
        {types.map(type => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Input>
    );
  }

  return (
    <Tabs
      className={v1Styles.tabs}
      classes={{
        indicator: v1Styles.indicator,
        scroller: v1Styles.scroller
      }}
      onChange={onChange}
      value={value}
    >
      {types.map(type => (
        <Tab
          classes={{
            root: v1Styles.tab,
            selected: v1Styles.selected
          }}
          key={type.value}
          label={type.label}
          value={type.value}
        />
      ))}
    </Tabs>
  );
};
