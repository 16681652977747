import React, { useContext } from "react";
import { ApplicationContext } from "~/Context";
import Layout from "@shared/Layout";
import { Box, Flex, Text } from "@toolkit";
import styled from "@shared/styled";
import { css } from "@styled-system/css";
import { Divider } from "@shared/Divider";
import { Router } from "@reach/router";
import { StyledLink } from "./StyledLink";
import { TravelApprovals } from "./TravelApprovals/TravelApprovals";
import { ExpenseReportApprovals } from "./ExpenseReportApprovals/ExpenseReportApprovals";
import { colors } from "@theme";
import { ExpenseAdvanceApprovals } from "./ExpenseAdvanceApprovals/ExpenseAdvanceApprovals";
import PermissionRoute from "@components/shared/permissions/PermissionRoute";
import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";
import { useApplication } from "~/apps/corporate/contexts/application.context";

const Container = styled("div")(
  css({
    width: "100%",
    maxWidth: ["100%", 1250],
    mx: "auto",
    py: ["large"],
    px: ["medium", 0]
  })
);

const Count = styled("span")(
  css({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF385C",
    color: "#fff",
    borderRadius: "100%",
    height: "16px",
    width: "16px",
    fontSize: "10px",
    fontWeight: "bold",
    marginLeft: "small",
    position: "relative",
    top: "-1px"
  })
);

const StyledLinkWithCount = ({
  to,
  activeOnPartial,
  children,
  approvals,
  ...rest
}) => (
  <StyledLink to={to} activeOnPartial={activeOnPartial} {...rest}>
    {children}
    {!!approvals?.length && (
      <Count>
        <span>{approvals.length}</span>
      </Count>
    )}
  </StyledLink>
);

const Approvals = () => {
  const {
    pendingTravelTokens,
    pendingReportTokens,
    pendingAdvancesTokens
  } = useApplication();

  return (
    <Layout>
      <Container>
        <Box pb={["large", 24]}>
          <Text fontSize={24} fontWeight="bold">
            Minhas Aprovações
          </Text>
        </Box>
        <Box>
          <Flex alignItems="center" mr={[-16, 0]}>
            <StyledLinkWithCount to="" approvals={pendingTravelTokens}>
              Viagens
            </StyledLinkWithCount>
            <StyledLinkWithCount
              to="expenses-report"
              activeOnPartial={true}
              approvals={pendingReportTokens}
            >
              Relatório de despesas
            </StyledLinkWithCount>
            <PermissionVisibility permissions={["expenses.advances"]}>
              <StyledLinkWithCount
                to="expenses-advance"
                activeOnPartial={true}
                approvals={pendingAdvancesTokens}
              >
                Adiantamentos
              </StyledLinkWithCount>
            </PermissionVisibility>
          </Flex>
          <Divider />
        </Box>
        <Box id="content" width="100%">
          <Router>
            <TravelApprovals path="/" />
            <ExpenseReportApprovals path="/expenses-report/*" />
            <PermissionRoute
              path="/expenses-advance/*"
              component={ExpenseAdvanceApprovals}
              permission="expenses.advances"
            />
          </Router>
        </Box>
      </Container>
    </Layout>
  );
};

export { Approvals };
