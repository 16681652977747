import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "0 auto",
    width: "calc(100% - 2 * 8px)",
  }),
  items: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    height: "fit-content",
    minWidth: "336px",
    padding: "24px 16px 16px 16px",
    position: "sticky",
    top: "16px",
    width: "336px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    padding: "0 8px",
  }),
};
