import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { getAirportsSuggestions } from "~/apps/corporate/apis/autosuggest.api";
import { CABIN_CLASSES_MAP } from "~/apps/corporate/components/offer-searcher/flight-form/flight-form.constants";
import {
  flightFormSchema,
  FlightFormSchema,
} from "~/apps/corporate/components/offer-searcher/flight-form/flight-form.schema";
import { FlightsSearch } from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { BookingTarget } from "~/apps/corporate/models/booking-target.model";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { DatePicker } from "~/apps/shared/components/date-picker/date-picker";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Select } from "~/apps/shared/components/select/select";
import { TextField } from "~/apps/shared/components/text-field/text-field";
import { ServiceType } from "~/apps/shared/constants/enums";

import { Button, CircularSpinner, Switch } from "@toolkit/v2";

import { useNewTrip } from "../../new-trip.context";
import {
  NewTripOfferSearcherTravelerAutocomplete,
  NewTripOfferSearcherTravelerAutocompleteSkeleton,
} from "../traveler-autocomplete/traveler-autocomplete";
import { styles } from "./styles";

type Props = {
  handleSearchFlights: (flightsSearch: FlightsSearch) => Promise<void>;
};

export const NewTripOfferSearcherFlightForm: React.FC<Props> = ({
  handleSearchFlights,
}) => {
  const { clientConfig } = useClientConfig();

  const { selectedSearch } = useNewTrip();

  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);
  const [traveler, setTraveler] = useState<BookingTarget | null>(null);

  const clientFlightAllowedCabinClasses = clientConfig
    ? clientConfig.getFlightAllowedCabinClasses()
    : null;

  const cabinClassOptions = useMemo(() => {
    if (!clientFlightAllowedCabinClasses) {
      const cabinClassOptions = Object.values(CABIN_CLASSES_MAP).map(
        (cabinClass) => ({
          label: cabinClass.name,
          value: cabinClass.value,
        }),
      );

      return cabinClassOptions;
    }

    const cabinClasses = clientFlightAllowedCabinClasses.map(
      (clientFlightAllowedCabinClass) =>
        CABIN_CLASSES_MAP[clientFlightAllowedCabinClass],
    );

    const cabinClassOptions = cabinClasses.map((cabinClass) => ({
      label: cabinClass.name,
      value: cabinClass.value,
    }));

    return cabinClassOptions;
  }, [clientFlightAllowedCabinClasses]);

  const form = useForm<FlightFormSchema>({
    defaultValues: {
      cabinClass: "economy",
      destination: null,
      endDate: null,
      origin: null,
      startDate: null,
      type: "roundtrip",
    } as FlightFormSchema,
    onSubmit: async () => {
      if (!traveler) {
        return;
      }

      const values = form.getValues();

      await handleSearchFlights({
        cabinClass: values.cabinClass,
        destination: values.destination,
        endDate: values.endDate,
        origin: values.origin,
        startDate: values.startDate,
        traveler,
        type: values.type,
      });
    },
    schema: flightFormSchema,
  });

  const handleSwapLocations = useCallback(() => {
    const { destination, origin } = form.getValues();

    form.setValue("destination", origin);
    form.setValue("origin", destination);
  }, [form]);

  const showTravelerErrorMessage =
    (hasTriedToSubmit && !traveler) || (!!traveler && !traveler.allowed);

  // useEffect(() => {
  //   if (!defaultBookingTarget || !!traveler) {
  //     return;
  //   }

  //   setTraveler(defaultBookingTarget);
  // }, [defaultBookingTarget]);

  useEffect(() => {
    if (!selectedSearch || selectedSearch.type !== ServiceType.FLIGHT) {
      return;
    }

    form.setValue("cabinClass", selectedSearch.data.cabinClass);
    form.setValue("destination", selectedSearch.data.destination);
    form.setValue("endDate", selectedSearch.data.endDate);
    form.setValue("origin", selectedSearch.data.origin);
    form.setValue("startDate", selectedSearch.data.startDate);
    form.setValue("type", selectedSearch.data.type);

    if (selectedSearch.data.traveler) {
      setTraveler(selectedSearch.data.traveler);
    }

    void form.trigger([
      "cabinClass",
      "destination",
      "endDate",
      "origin",
      "startDate",
      "type",
    ]);
  }, [selectedSearch]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.top.root}>
        <Form.Field<FlightFormSchema["type"] | undefined>
          name="type"
          render={({ value }) => (
            <label css={styles.top.switch.root}>
              <Switch
                active={value === "roundtrip"}
                onChange={(e) => {
                  form.setValue(
                    "type",
                    e.target.checked ? "roundtrip" : "oneway",
                  );
                }}
                variant="pink"
              />
              <span css={styles.top.switch.text}>Ida e volta</span>
            </label>
          )}
        />
        <NewTripOfferSearcherTravelerAutocomplete
          onChange={(value) => {
            if (!value) {
              setTraveler(null);

              return;
            }

            setTraveler((prev) => {
              if (!prev) {
                return {
                  fullName: value,
                } as BookingTarget;
              }

              return {
                ...prev,
                fullName: value,
              };
            });
          }}
          onSelect={(value) => {
            setTraveler({
              allowed: value.allowed,
              email: value.email,
              fullName: value.label,
              userToken: value.userToken,
            });
          }}
          showErrorMessage={showTravelerErrorMessage}
          value={traveler ? traveler.fullName : undefined}
        />
        <div css={styles.top.control.root}>
          <Form.Field
            name="cabinClass"
            render={({ value }) => (
              <Select
                css={styles.top.control.cabin}
                onChange={({ value }) => {
                  form.setValue("cabinClass", value);
                }}
                options={cabinClassOptions}
                placeholder="Selecione a categoria"
                value={(() => {
                  const cabinClassOption = cabinClassOptions.find(
                    (cabinClass) => cabinClass.value === value,
                  );

                  if (!cabinClassOption) {
                    return undefined;
                  }

                  return {
                    ...cabinClassOption,
                    label: (
                      <>
                        <span css={styles.top.control.label}>
                          Classe:&nbsp;
                        </span>
                        {cabinClassOption.label}
                      </>
                    ),
                  };
                })()}
              />
            )}
          />
          <InputErrorMessage css={styles.top.control.error}>
            {form.errors["cabinClass"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.left.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.left.control.location.origin.container}>
              <Form.Field<FlightFormSchema["origin"] | undefined>
                name="origin"
                render={({ value }) => (
                  <AsyncAutocomplete
                    fetchItems={async (search) => {
                      try {
                        return {
                          data: await getAirportsSuggestions(search),
                        };
                      } catch (error) {
                        return {
                          error,
                        };
                      }
                    }}
                    inputProps={{
                      placeholder: "Digite o local de origem...",
                    }}
                    onChange={(value) => {
                      form.setValue("origin.label", value);
                    }}
                    onSelect={(location) => {
                      if (!location) {
                        form.setValue("origin", null);

                        return;
                      }

                      form.setValue("origin", location);
                    }}
                    renderInput={({ loading, ...props }) => (
                      <div css={styles.center.left.control.location.input.root}>
                        {loading ? (
                          <CircularSpinner
                            css={
                              styles.center.left.control.location.input.loading
                            }
                            size={20}
                          />
                        ) : (
                          <Icon size={20} use="airplane-taking-off" />
                        )}
                        <TextField
                          css={styles.center.left.control.location.input.input}
                          inputProps={{ ...props }}
                          label="Origem*"
                          variant="outlined"
                        />
                      </div>
                    )}
                    renderItem={({ isHighlighted, item, ...props }) => {
                      if (item.error) {
                        return (
                          <li
                            css={
                              styles.center.left.control.location.suggestion
                                .item
                            }
                            key={0}
                            {...props}
                          >
                            Nenhum local encontrado...
                          </li>
                        );
                      }

                      return (
                        <li
                          css={styles.center.left.control.location.suggestion.button(
                            {
                              isHighlighted,
                            },
                          )}
                          key={JSON.stringify(item)}
                          {...props}
                        >
                          {item.label}
                        </li>
                      );
                    }}
                    value={value ? value.label : null}
                  />
                )}
              />
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["origin"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.center.left.swap.root({})}>
            <button
              css={styles.center.left.swap.button}
              onClick={() => {
                handleSwapLocations();
              }}
              type="button"
            >
              <Icon size={16} use="arrows-right-left" />
            </button>
          </div>
          <div css={styles.center.control.root}>
            <div
              css={styles.center.left.control.location.destination.container}
            >
              <Form.Field<FlightFormSchema["destination"] | undefined>
                name="destination"
                render={({ value }) => (
                  <AsyncAutocomplete
                    fetchItems={async (search) => {
                      try {
                        return {
                          data: await getAirportsSuggestions(search),
                        };
                      } catch (error) {
                        return {
                          error,
                        };
                      }
                    }}
                    inputProps={{
                      placeholder: "Digite o local de destino...",
                    }}
                    onChange={(value) => {
                      form.setValue("destination.label", value);
                    }}
                    onSelect={(location) => {
                      if (!location) {
                        form.setValue("destination", null);

                        return;
                      }

                      form.setValue("destination", location);
                    }}
                    renderInput={({ loading, ...props }) => (
                      <div css={styles.center.left.control.location.input.root}>
                        {loading ? (
                          <CircularSpinner
                            css={
                              styles.center.left.control.location.input.loading
                            }
                            size={20}
                          />
                        ) : (
                          <Icon size={20} use="airplane-landing" />
                        )}
                        <TextField
                          css={styles.center.left.control.location.input.input}
                          inputProps={{ ...props }}
                          label="Destino*"
                          variant="outlined"
                        />
                      </div>
                    )}
                    renderItem={({ isHighlighted, item, ...props }) => {
                      if (item.error) {
                        return (
                          <li
                            css={
                              styles.center.left.control.location.suggestion
                                .item
                            }
                            key={0}
                            {...props}
                          >
                            Nenhum local encontrado...
                          </li>
                        );
                      }

                      return (
                        <li
                          css={styles.center.left.control.location.suggestion.button(
                            {
                              isHighlighted,
                            },
                          )}
                          key={JSON.stringify(item)}
                          {...props}
                        >
                          {item.label}
                        </li>
                      );
                    }}
                    value={value ? value.label : null}
                  />
                )}
              />
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["destination"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.center.right.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.right.control.dates.container}>
              <div css={styles.center.right.control.dates.root}>
                {form.watch("type") === "oneway" ? (
                  <DatePicker
                    anchorDirection="right"
                    date={form.watch("startDate")}
                    focused={rangeDateFocus === "startDate"}
                    id="startDate"
                    onDateChange={(date) => {
                      form.setValue("startDate", date);

                      if (date) {
                        form.clearErrors("startDate");
                      }
                    }}
                    onFocusChange={({ focused }) => {
                      setRangeFocus(focused ? "startDate" : null);
                    }}
                    placeholder="Ida*"
                    range={false}
                    renderCalendarInfo={undefined}
                  />
                ) : (
                  <DatePicker
                    allowSameDay
                    anchorDirection="right"
                    customArrowIcon={
                      <div css={styles.center.right.control.dates.arrow.root}>
                        <span
                          css={styles.center.right.control.dates.arrow.divisor}
                        >
                          |
                        </span>
                        <Icon size={16} use="calendar" />
                      </div>
                    }
                    endDate={form.watch("endDate")}
                    endDatePlaceholderText="Volta*"
                    focusedInput={rangeDateFocus}
                    onDatesChange={(date) => {
                      form.setValue("startDate", date.startDate);

                      if (date.startDate) {
                        form.clearErrors("startDate");
                      }

                      form.setValue("endDate", date.endDate);

                      if (date.endDate) {
                        form.clearErrors("endDate");
                      }
                    }}
                    onFocusChange={(focused) => {
                      setRangeFocus(focused);
                    }}
                    range
                    renderCalendarInfo={undefined}
                    startDate={form.watch("startDate")}
                    startDatePlaceholderText="Ida*"
                  />
                )}
              </div>
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["startDate"]?.message ||
                form.errors["endDate"]?.message}
            </InputErrorMessage>
          </div>
          <Button
            css={styles.button}
            onClick={() => {
              setHasTriedToSubmit(true);
            }}
            variant="pink"
          >
            <Icon use="search-outline" />
          </Button>
        </div>
      </div>
    </Form>
  );
};

export const NewTripOfferSearcherFlightFormSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.skeleton.top}>
        <label css={styles.top.switch.root}>
          <Switch active disabled variant="pink" />
          <Skeleton height="16px" width="96px" />
        </label>
        <NewTripOfferSearcherTravelerAutocompleteSkeleton />
      </div>
      <div css={styles.center.root}>
        <div css={styles.skeleton.location.container}>
          <div css={styles.skeleton.location.bound}>
            <Skeleton height="20px" width="20px" />
            <div css={styles.skeleton.location.input}>
              <Skeleton height="20px" width="72px" />
            </div>
            <div css={styles.center.left.swap.root({ isDisabled: true })}>
              <button
                css={styles.center.left.swap.button}
                disabled
                type="button"
              >
                <Icon size={16} use="arrows-right-left" />
              </button>
            </div>
            <Skeleton height="20px" width="20px" />
            <div css={styles.skeleton.location.input}>
              <Skeleton height="20px" width="72px" />
            </div>
          </div>
        </div>
        <div css={styles.skeleton.right}>
          <div css={styles.skeleton.dates.container}>
            <Skeleton height="20px" width="96px" />
          </div>
          <Button css={styles.button} disabled variant="pink">
            <Icon use="search-outline" />
          </Button>
        </div>
      </div>
    </div>
  );
};
