import React from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

const styles = {
  button: css({
    padding: "0.75rem 2rem",
    border: `1px solid ${theme.colors.gray[500]}`,
    borderRadius: "0.5rem",
    cursor: "pointer",
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.25rem"
  })
};

export interface IOutlinedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const OutlinedButton: React.FC<IOutlinedButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <button css={styles.button} {...rest}>
      {children}
    </button>
  );
};
