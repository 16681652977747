import React from "react";

import {
  Check as CheckIcon,
  Close as CloseIcon,
  HourglassEmpty as HourglassIcon,
  Person as UserIcon
} from "@material-ui/icons";
import { Link } from "@reach/router";
import moment from "moment";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Flex, Text } from "smartrips-toolkit";

import placeholder from "@assets/images/no-image-available.svg";

import {
  ParticipationStatus,
  mapParticipantInviteStatus
} from "~/models/event.model";

import { Spacing } from "~/components/shared/layout";

import { styles } from "./styles";

const mapParticipantInviteStatusBadgeColor = (
  participantInviteStatus: ParticipationStatus
) => {
  return ({
    [ParticipationStatus.ACCEPTED]: theme.colors.success,
    [ParticipationStatus.DECLINED]: theme.colors.cancel,
    [ParticipationStatus.REALLY_PENDING]: theme.colors.orange[1],
    [ParticipationStatus.VIRTUAL_PENDING]: theme.colors.orange[1]
  } as {
    [key in ParticipationStatus]: string;
  })[participantInviteStatus];
};

type Props = {
  event: GetUserCompanyEventDto;
  showParticipationStatus?: boolean;
};

export const ListEventsEventCard: React.VFC<Props> = ({
  event,
  showParticipationStatus
}) => {
  const is_organizer = event.viewer_info.is_organizer;
  const participation_status = event.viewer_info.participation_status;

  return (
    <Link
      css={styles.root({
        detail:
          participation_status && showParticipationStatus
            ? mapParticipantInviteStatusBadgeColor(participation_status)
            : theme.colors.primary
      })}
      to={`/events/${event.token}/overview`}
    >
      <div css={styles.left}>
        <Flex alignItems="center">
          <Text color={theme.colors.gray[3]} fontSize={14}>
            {moment(event.start_date).format("DD MMM").toUpperCase()}
          </Text>
          <Spacing direction="horizontal" space="8px" />
          <Text color={theme.colors.gray[3]} fontSize={14}>
            {moment(event.end_date).format("DD MMM").toUpperCase()}
          </Text>
        </Flex>
        <Text fontWeight="bold">{event.name}</Text>
        <Text color={theme.colors.gray[3]} fontSize={14}>
          {event.city}, {event.state}, {event.country}
        </Text>
      </div>
      <div css={styles.right.root}>
        <img
          alt="Imagem indisponível."
          css={styles.right.image}
          src={placeholder}
        />
        {showParticipationStatus ? (
          <div
            css={styles.right.badge.root}
            style={
              participation_status
                ? {
                    backgroundColor: mapParticipantInviteStatusBadgeColor(
                      participation_status
                    )
                  }
                : undefined
            }
          >
            {is_organizer ? (
              <UserIcon classes={styles.right.badge.icon} />
            ) : participation_status === ParticipationStatus.ACCEPTED ? (
              <CheckIcon classes={styles.right.badge.icon} />
            ) : participation_status === ParticipationStatus.DECLINED ? (
              <CloseIcon classes={styles.right.badge.icon} />
            ) : (
              <HourglassIcon classes={styles.right.badge.icon} />
            )}
            <Text fontSize={12} fontWeight="bold">
              {is_organizer
                ? "Organizando"
                : participation_status
                ? mapParticipantInviteStatus(participation_status)
                : null}
            </Text>
          </div>
        ) : null}
      </div>
    </Link>
  );
};
