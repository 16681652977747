import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "24px",
      position: "sticky",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
  },
  body: {
    input: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        minWidth: "288px",
      }),
    },
    row: css({
      alignItems: "flex-end",
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "16px",
      padding: "24px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
    select: {
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
      select: css({
        width: "100%",
      }),
    },
    switch: {
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        gap: "12px",
        height: "fit-content",
        padding: "13px 16px",
        width: "fit-content",
      }),
      text: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  }),
};
