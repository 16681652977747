import { css } from "@styled-system/css";
import { css as ecss } from "emotion";

export const styles = {
  button: css({
    height: "fit-content",
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.75
    }
  }),
  dialog: {
    actions: ecss({
      alignItems: "center",
      display: "flex",
      gap: "32px",
      justifyContent: "space-between",
      padding: "32px"
    }),
    content: ecss({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "0 32px",
      overflow: "visible",
      width: "100%"
    }),
    paper: ecss({
      maxWidth: 700,
      overflow: "visible",
      width: "100%",
      ["@media (max-width: 767px)"]: {
        margin: 20
      }
    })
  },
  header: css({
    padding: "32px 32px 0 32px"
  })
};
