import React from "react";
import { css } from "emotion";
import { defaultTheme } from "@theme";
import { Expense } from "@models/expense.model";
import { Column } from "@shared/layout/Column";
import { Row } from "@shared/layout/Row";
import * as utils from "@helpers";
import { StContainedButton } from "@shared/buttons";
import { MEDIA_QUERIES } from "@constants";
import { ExpensesBreakdown } from "../../shared/ExpensesBreakdown";
import Collapse from "@material-ui/core/Collapse";
import { CategoryForm } from "../CategoryForm";
import { AdvancedExpense } from "@models/advanced-expense.model";
import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";
import { Box } from "@toolkit";
import { DeclineForm } from "../DeclineForm";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      borderRadius: 0,
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
    }
  }),
  titleSection: css({
    padding: "1.5rem 1.25rem",
    fontWeight: "bold",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  segment: css({
    padding: "1.5rem 1.25rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  actionSegment: css({
    padding: "1.5rem 1.25rem"
  }),
  actionDiv: css({
    display: "flex",
    justifyContent: "space-around"
  }),
  buttons: css({
    padding: ".35rem 2rem",
    maxHeight: 34,
    fontSize: 14,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: 120,
      padding: ".5rem 1rem"
    }
  })
};

interface Props {
  expenses: Expense[];
  advancedExpenses: AdvancedExpense[];
  loading: boolean;
  isApproveEnabled: boolean;
  showCategoryForm: boolean;
  showDeclineForm: boolean;
  handleApprove: () => void;
  handleDeny: () => void;
}

const PaymentCard = ({
  expenses,
  advancedExpenses,
  loading,
  isApproveEnabled,
  showCategoryForm,
  showDeclineForm,
  handleApprove,
  handleDeny
}: Props) => {
  return (
    <Column className={styles.root}>
      <div className={styles.titleSection}>
        <span>Resumo da solicitação</span>
      </div>
      <section style={{ padding: "1rem 2rem" }}>
        <div style={{ paddingBottom: "1.5rem" }}>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>
            Serviços selecionados
          </p>
        </div>
        <ExpensesBreakdown
          containerClasses={styles.segment}
          type="payment"
          expensesData={expenses}
          advancedExpensesData={advancedExpenses}
          showSecondColumn={true}
        >
          {(totalExpenseValue, approvedValue, totalAdvancedExpense) => (
            <div>
              <div>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14,
                    color: defaultTheme.grays.dark
                  }}
                >
                  <p>Total solicitado</p>
                  <p>{utils.toCurrency(totalExpenseValue)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14
                  }}
                >
                  <p>Total aprovado</p>
                  <p>{utils.toCurrency(approvedValue)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14,
                    color: defaultTheme.reds.lightRed
                  }}
                >
                  <p>Adiantamento</p>
                  <p>- {utils.toCurrency(totalAdvancedExpense)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 15,
                    fontWeight: "bold"
                  }}
                >
                  <p>Total reembolso</p>
                  <p>
                    {utils.toCurrency(approvedValue - totalAdvancedExpense)}
                  </p>
                </Row>
              </div>
            </div>
          )}
        </ExpensesBreakdown>
      </section>
      <DeclineForm />
      {!showDeclineForm && (
        <>
          <PermissionVisibility
            permissions={[
              "companySettings.costCenters",
              "companySettings.billingProfiles",
              "companySettings.areas",
              "companySettings.projects"
            ]}
            atLeastOne={true}
          >
            <Collapse in={showCategoryForm}>
              <CategoryForm />
            </Collapse>
          </PermissionVisibility>
          <div className={styles.actionSegment}>
            <Box display="flex" flexDirection="column">
              <StContainedButton
                onClick={handleApprove}
                isFullWidth={true}
                className={styles.buttons}
                disabled={!isApproveEnabled}
                loading={loading}
              >
                Finalizar relatório
              </StContainedButton>
              <Box py="xsmall" />
              <StContainedButton
                color="secondary"
                onClick={handleDeny}
                isFullWidth={false}
                className={styles.buttons}
                disabled={loading}
                loading={loading}
              >
                Negar relatório
              </StContainedButton>
            </Box>
          </div>
        </>
      )}
    </Column>
  );
};

export { PaymentCard };
