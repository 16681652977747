import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { MdOutlineTravelExplore } from "react-icons/md";

import { ZoomInMarker } from "~/apps/shared/components/location-marker/location-marker";

import { defaultTheme } from "~/assets/styles/theme";

type Props = {
  cityName: string;
  lat: number;
  lng: number;
  onClick: (cityName: string) => void;
  travelsQuantity: number;
};

export const MapMarker: React.FC<Props> = ({
  cityName,
  lat,
  lng,
  onClick,
  travelsQuantity,
}) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  const handleMarkerClick = () => {
    setIsInfoWindowOpen(!isInfoWindowOpen);

    onClick(cityName);
  };

  return (
    <Marker
      position={{
        lat,
        lng,
      }}
    >
      <ZoomInMarker
        lat={lat}
        lng={lng}
        onClick={handleMarkerClick}
        type="search-point"
      />
      {isInfoWindowOpen && (
        <InfoWindow
          onCloseClick={() => {
            setIsInfoWindowOpen(false);
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem",
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: "0.5rem",
              }}
            >
              <MdOutlineTravelExplore
                style={{
                  color: defaultTheme.primaryColor,
                  fontSize: "1.2rem",
                  marginRight: "0.25rem",
                }}
              />
              <h3 style={{ fontSize: "1rem", marginBottom: -2 }}>{cityName}</h3>
            </div>
            <p style={{ fontSize: "0.9rem" }}>
              {travelsQuantity > 1 ? "Estão" : "Está"} ocorrendo{" "}
              {travelsQuantity} {travelsQuantity > 1 ? "viagens" : "viagem"} na
              cidade.
            </p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};
