import moment from "moment";

export const getMonthsShortLocale = (locale: string) => {
  moment.locale(locale);

  return moment.months();
};

export const monthsInBetweenMap = ({
  displayedYear,
  endMonth,
  endYear,
  startMonth,
  startYear,
}: {
  displayedYear: number;
  endMonth?: number;
  endYear?: number;
  startMonth?: number;
  startYear?: number;
}) => {
  const result: Record<number, boolean> = {};

  if (typeof startMonth === "undefined" || typeof endMonth === "undefined") {
    for (let k = 0; k < 12; k++) {
      result[k] = false;
    }
  } else {
    for (let i = 0; i < 12; i++) {
      const isMonthYearAfterStart =
        i * 30 + displayedYear * 360 >
        startMonth * 30 + (startYear ? startYear : 0) * 360;

      const isMonthYearBeforeEnd =
        i * 30 + displayedYear * 360 <
        endMonth * 30 + (endYear ? endYear : 0) * 360;

      result[i] = isMonthYearAfterStart && isMonthYearBeforeEnd ? true : false;
    }
  }

  return result;
};
