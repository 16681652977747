import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { useUserBillingProfiles } from "~/apps/corporate/hooks/use-user-billing-profiles";
import { useUserCompanyAreas } from "~/apps/corporate/hooks/use-user-company-areas";
import { useUserCostCenters } from "~/apps/corporate/hooks/use-user-cost-centers";
import { useUserProjects } from "~/apps/corporate/hooks/use-user-project";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Select } from "~/apps/shared/components/select/select";

import { Button } from "@toolkit/v2";

import { useItineraryInfo } from "../itinerary-info.context";
import { useItineraryPendencies } from "../itinerary-pendencies.context";
import { useItineraryScreen } from "../itinerary-screen.context";
import { useItineraryContainer } from "../itinerary.container";
import {
  travelCategorizationSchema,
  TravelCategorizationSchema,
} from "./itinerary-travel-categorization-dialog.schema";
import { styles } from "./styles";

export const ItineraryTravelCategorizationDialog: React.FC = () => {
  const { infoModel } = useItineraryContainer();

  const { user } = useUser();

  const { fetchItineraryInfo } = useItineraryInfo();
  const { saveCategorizationAndProceed } = useItineraryPendencies();
  const {
    closeTravelCategorizationDialog,
    isTravelCategorizationDialogOpen,
    selectedCategorization,
  } = useItineraryScreen();

  const { billingProfiles, isLoadingBillingProfiles } = useUserBillingProfiles(
    infoModel?.getTravelerToken(),
    isTravelCategorizationDialogOpen &&
      selectedCategorization === "billing-profile",
  );
  const { companyAreas, isLoadingCompanyAreas } = useUserCompanyAreas(
    infoModel?.getTravelerToken(),
    isTravelCategorizationDialogOpen &&
      selectedCategorization === "company-area",
  );
  const { costCenters, isLoadingCostCenters } = useUserCostCenters(
    infoModel?.getTravelerToken(),
    isTravelCategorizationDialogOpen &&
      selectedCategorization === "cost-center",
  );
  const { isLoadingProjects, projects } = useUserProjects(
    infoModel?.getTravelerToken(),
    isTravelCategorizationDialogOpen && selectedCategorization === "project",
  );

  const form = useForm<TravelCategorizationSchema>({
    defaultValues: {
      billingProfileToken: null,
      companyAreaToken: null,
      costCenterToken: null,
      projectToken: null,
    } as TravelCategorizationSchema,
    onSubmit: async () => {
      const values = form.getValues();

      const success = await saveCategorizationAndProceed({
        billingProfileToken: values.billingProfileToken,
        companyAreaToken: values.companyAreaToken,
        costCenterToken: values.costCenterToken,
        projectToken: values.projectToken,
      });

      if (!success) {
        return;
      }

      await fetchItineraryInfo();

      closeTravelCategorizationDialog();
    },
    schema: travelCategorizationSchema,
  });

  const {
    billingProfileToken,
    companyAreaToken,
    costCenterToken,
    projectToken,
  } = form.watch([
    "billingProfileToken",
    "companyAreaToken",
    "costCenterToken",
    "projectToken",
  ]);

  const billingProfileOptions = useMemo(() => {
    if (!billingProfiles || !user) {
      return null;
    }

    return billingProfiles
      .filter(
        (billingProfile) =>
          billingProfile.client_token === user.getClientToken(),
      )
      .map((billingProfile) => ({
        label: billingProfile.name,
        value: billingProfile.billingProfileToken,
      }));
  }, [billingProfiles, user]);
  const companyAreaOptions = useMemo(() => {
    if (!companyAreas || !user) {
      return null;
    }

    return companyAreas
      .filter(
        (companyArea) => companyArea.clientToken === user.getClientToken(),
      )
      .map((companyArea) => ({
        label: companyArea.name,
        value: companyArea.companyAreaToken,
      }));
  }, [companyAreas, user]);
  const costCenterOptions = useMemo(() => {
    if (!costCenters || !user) {
      return null;
    }

    return costCenters
      .filter((costCenter) => costCenter.clientToken === user.getClientToken())
      .map((costCenter) => ({
        label: costCenter.name,
        value: costCenter.token,
      }));
  }, [costCenters, user]);
  const projectOptions = useMemo(() => {
    if (!projects || !user) {
      return null;
    }

    return projects
      .filter((project) => project.clientToken === user.getClientToken())
      .map((project) => ({
        label: project.name,
        value: project.projectToken,
      }));
  }, [projects, user]);

  const selectedBillingProfile = useMemo(() => {
    if (!billingProfiles || !billingProfileToken) {
      return undefined;
    }

    const billingProfile = billingProfiles.find(
      (billingProfile) =>
        billingProfile.billingProfileToken === billingProfileToken,
    );

    if (!billingProfile) {
      return undefined;
    }

    return {
      label: billingProfile.name,
      value: billingProfile.billingProfileToken,
    };
  }, [billingProfiles, billingProfileToken]);
  const selectedCompanyArea = useMemo(() => {
    if (!companyAreas || !companyAreaToken) {
      return undefined;
    }

    const companyArea = companyAreas.find(
      (companyArea) => companyArea.companyAreaToken === companyAreaToken,
    );

    if (!companyArea) {
      return undefined;
    }

    return {
      label: companyArea.name,
      value: companyArea.companyAreaToken,
    };
  }, [companyAreas, companyAreaToken]);
  const selectedCostCenter = useMemo(() => {
    if (!costCenters || !costCenterToken) {
      return undefined;
    }

    const costCenter = costCenters.find(
      (costCenter) => costCenter.token === costCenterToken,
    );

    if (!costCenter) {
      return undefined;
    }

    return {
      label: costCenter.name,
      value: costCenter.token,
    };
  }, [costCenters, costCenterToken]);
  const selectedProject = useMemo(() => {
    if (!projects || !projectToken) {
      return undefined;
    }

    const project = projects.find(
      (project) => project.projectToken === projectToken,
    );

    if (!project) {
      return undefined;
    }

    return {
      label: project.name,
      value: project.projectToken,
    };
  }, [projects, projectToken]);

  useEffect(() => {
    if (!infoModel) {
      return;
    }

    form.reset({
      billingProfileToken: infoModel.getBillingProfileToken(),
      companyAreaToken: infoModel.getCompanyAreaToken(),
      costCenterToken: infoModel.getCostCenterToken(),
      projectToken: infoModel.getProjectToken(),
    });
  }, [infoModel]);

  if (!infoModel) {
    return null;
  }

  const travelName = infoModel.getTravelName();

  return (
    <Dialog
      onClose={closeTravelCategorizationDialog}
      open={isTravelCategorizationDialogOpen}
    >
      <DialogHeader icon="baggage" onClose={closeTravelCategorizationDialog} />
      <DialogTitle>
        Categorizar viagem {travelName ? <>&quot;{travelName}&quot;</> : null}:
      </DialogTitle>
      <Form context={form}>
        <DialogContent css={styles.body.root}>
          {selectedCategorization === "billing-profile" ? (
            <>
              {isLoadingBillingProfiles ? (
                <div css={styles.body.select.root}>
                  <Skeleton height="16px" width="128px" />
                  <Skeleton height="48px" width="100%" />
                </div>
              ) : billingProfileOptions ? (
                <div css={styles.body.select.root}>
                  <span css={styles.body.select.label}>Perfil CNPJ</span>
                  <Select
                    id="billingProfileToken"
                    isSearchable
                    name="billingProfileToken"
                    onChange={({ value }) => {
                      form.setValue("billingProfileToken", value);
                    }}
                    options={billingProfileOptions}
                    placeholder="Escolha um perfil CNPJ..."
                    useSetDefaultValueIfOnlyOneOption
                    value={selectedBillingProfile}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          {selectedCategorization === "company-area" ? (
            <>
              {isLoadingCompanyAreas ? (
                <div css={styles.body.select.root}>
                  <Skeleton height="16px" width="128px" />
                  <Skeleton height="48px" width="100%" />
                </div>
              ) : companyAreaOptions ? (
                <div css={styles.body.select.root}>
                  <span css={styles.body.select.label}>Área</span>
                  <Select
                    id="companyAreaToken"
                    isSearchable
                    name="companyAreaToken"
                    onChange={({ value }) => {
                      form.setValue("companyAreaToken", value);
                    }}
                    options={companyAreaOptions}
                    placeholder="Escolha uma área..."
                    useSetDefaultValueIfOnlyOneOption
                    value={selectedCompanyArea}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          {selectedCategorization === "cost-center" ? (
            <>
              {isLoadingCostCenters ? (
                <div css={styles.body.select.root}>
                  <Skeleton height="16px" width="128px" />
                  <Skeleton height="48px" width="100%" />
                </div>
              ) : costCenterOptions ? (
                <div css={styles.body.select.root}>
                  <span css={styles.body.select.label}>Centro de custo</span>
                  <Select
                    id="costCenterToken"
                    isSearchable
                    name="costCenterToken"
                    onChange={({ value }) => {
                      form.setValue("costCenterToken", value);
                    }}
                    options={costCenterOptions}
                    placeholder="Escolha um centro de custo..."
                    useSetDefaultValueIfOnlyOneOption
                    value={selectedCostCenter}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          {selectedCategorization === "project" ? (
            <>
              {isLoadingProjects ? (
                <div css={styles.body.select.root}>
                  <Skeleton height="16px" width="128px" />
                  <Skeleton height="48px" width="100%" />
                </div>
              ) : projectOptions ? (
                <div css={styles.body.select.root}>
                  <span css={styles.body.select.label}>Projeto</span>
                  <Select
                    id="projectToken"
                    isSearchable
                    name="projectToken"
                    onChange={({ value }) => {
                      form.setValue("projectToken", value);
                    }}
                    options={projectOptions}
                    placeholder="Escolha um projeto..."
                    useSetDefaultValueIfOnlyOneOption
                    value={selectedProject}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </DialogContent>
        <DialogFooter>
          <Button
            css={styles.body.button}
            disabled={form.formState.isSubmitting}
            fill="outlined"
            onClick={() => {
              closeTravelCategorizationDialog();
            }}
            type="button"
          >
            Fechar
          </Button>
          <Button
            css={styles.body.button}
            disabled={form.formState.isSubmitting}
          >
            Salvar
          </Button>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
