import { UserModel } from "./user.model";

export type TravelApprover = {
  email: string;
  first_name: string;
  last_name: string;
  stage: number;
  user_token: string;
};

export type TravelApprovalStatus = {
  allNextApprovers: TravelApprover[];
  allPreviousStagesApprovers: TravelApprover[];
  approvalToken?: string;
  currentApprovers: TravelApprover[];
  isCancelable: boolean;
  lastRequestCanceled?: boolean;
  lastRequestStatus?: number;
  mustRequestApproval: boolean;
  pendingApprovalRequestToken?: string;
  status?: number;
};

export class TravelApprovalStatusModel {
  constructor(protected travelApprovalStatus: TravelApprovalStatus) {}

  public getAllCurrentApprovers() {
    return this.travelApprovalStatus.currentApprovers;
  }

  public getAllNextApprovers() {
    return this.travelApprovalStatus.allNextApprovers;
  }

  public getPendingApprovalRequestToken() {
    return this.travelApprovalStatus.pendingApprovalRequestToken;
  }

  public getStageOneApprovers() {
    return this.getAllNextApprovers().filter(
      (approver) => approver.stage === 1,
    );
  }

  public getStageTwoApprovers() {
    return this.getAllNextApprovers().filter(
      (approver) => approver.stage === 2,
    );
  }

  public mustRequestApproval() {
    return this.travelApprovalStatus.mustRequestApproval;
  }

  public isFinalApprover() {
    return this.getAllNextApprovers().length === 0;
  }

  public isUserAmongApprovers(user: UserModel) {
    return (
      this.isUserAmongCurrentApprovers(user) ||
      this.isUserAmongNextApprovers(user)
    );
  }

  public isUserAmongCurrentApprovers(user: UserModel) {
    return this.getAllCurrentApprovers().some(
      (approver) => approver.user_token === user.getUserToken(),
    );
  }

  private isUserAmongNextApprovers(user: UserModel) {
    return this.getAllNextApprovers().some(
      (approver) => approver.user_token === user.getUserToken(),
    );
  }
}

export class TravelApprovalStatusModelFactory {
  public static create(travelApprovalStatus: TravelApprovalStatus) {
    return new TravelApprovalStatusModel(travelApprovalStatus);
  }
}
