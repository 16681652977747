import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  handleSelectedClassOnlyToggle: () => void;
  selectedClassOnly: boolean;
};

export const SelectedClassOnlyFilter: React.FC<Props> = ({
  handleSelectedClassOnlyToggle,
  selectedClassOnly,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title.root}>
        <span css={styles.title.label}>Classe de cabine</span>
        <Badge fill="outlined">Novo</Badge>
      </span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        <CheckboxGroup.Item
          checked={selectedClassOnly}
          css={styles.checkbox.group.item.root}
          label={
            <span css={styles.checkbox.group.item.label}>
              Somente ofertas com a mesma classe de cabine selecionada em todos
              os segmentos e trechos.
            </span>
          }
          onChange={handleSelectedClassOnlyToggle}
        />
      </CheckboxGroup>
    </div>
  );
};
