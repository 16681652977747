import React from "react";
import { Box, Flex } from "@toolkit";
import { StyledLink } from "../StyledLink";
import { Router } from "@reach/router";

import { ExpenseAdvanceApprovalsList } from "./ExpenseAdvanceApprovalsList";

const ExpenseAdvanceApprovals = () => {
  return (
    <div>
      <Flex alignItems="center" mb={40}>
        <StyledLink to="">Aprovações pendentes</StyledLink>
        <StyledLink to="pending-payment">Pagamento pendente</StyledLink>
      </Flex>
      <Box>
        <Router>
          <ExpenseAdvanceApprovalsList
            path="/"
            status={1}
            title="Adiantamentos pendendes de aprovação"
          />
          <ExpenseAdvanceApprovalsList
            path="/pending-payment"
            status={99}
            title="Adiantamentos pendendes de pagamento"
          />
        </Router>
      </Box>
    </div>
  );
};

export { ExpenseAdvanceApprovals };
