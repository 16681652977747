import React from "react";

import { Redirect, Router } from "@reach/router";

import { ItineraryApprovalRequest } from "./itinerary-approval-request/itinerary-approval-request";
import { ItineraryPendencies } from "./itinerary-pendencies/itinerary-pendencies";
import { ItineraryTravelPlan } from "./itinerary-travel-plan/itinerary-travel-plan";
import { styles } from "./styles";

const Outlet: React.FC = () => {
  return (
    <Router css={styles.router} primary={false}>
      <ItineraryApprovalRequest path="/approval-request" />
      <ItineraryPendencies path="/pendencies" />
      <ItineraryTravelPlan path="/travel-plan" />
      <Redirect from="*" noThrow to="./travel-plan" />
    </Router>
  );
};

export const ItineraryContent: React.FC = () => {
  return <Outlet />;
};
