import * as Sentry from "@sentry/react";

import { navigate } from "@reach/router";
import axios from "axios";

import { getAuthorizationHeader } from "../helpers/user.helper";

const smartripsAxios = axios.create({
  baseURL: BASE_URL // eslint-disable-line no-undef,
});

// Interceptor functions

const shouldIntercept = error => {
  try {
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

const setTokenData = accessToken => {
  localStorage.setItem("access_token", accessToken);
};

const doTokenRefresh = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/auth/refresh`, undefined, {
        headers: getAuthorizationHeader(),
        withCredentials: true,
        timeout: 10000 // 10 seconds
      })
      .then(({ data }) => {
        resolve(data.data.access_token);
      })
      .catch(err => {
        reject(err);
      });
  });
};

async function handleTokenRefresh() {
  // Use Web Lock api if available
  // to lock all requests
  if (navigator?.locks) {
    return new Promise((resolve, reject) => {
      navigator.locks.request("handle_token_refresh", async lock => {
        await doTokenRefresh().then(resolve).catch(reject);
      });
    });
  }

  return doTokenRefresh();
}

async function handleRefreshError(reject, error) {
  if (error.response) {
    const { status, data } = error.response;

    if (
      status === 403 &&
      data?.message !== "User has recently refreshed this session." // Should not logout user
    ) {
      await navigate("/login", { state: { err: data?.message } });
      localStorage.removeItem("access_token");
      Sentry.setUser(null);
    }

    // TODO: map any other possible error with messages
    reject(error);
  } else {
    reject(error);
  }
}

const attachTokenToRequest = (request, token) => {
  request.headers["Authorization"] = token;
};

const setOnRejectedInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = error => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch(err => {
          console.error(err);
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then(accessToken => {
          options.setTokenData(accessToken);
          options.attachTokenToRequest(originalRequest, accessToken);
          processQueue(null, accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          void handleRefreshError(reject, err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

setOnRejectedInterceptor(smartripsAxios);

const setOnFulfilledInterceptor = axiosClient => {
  const interceptor = response => {
    if (
      (response.data === null || response.data === undefined) &&
      response.status !== 204
    ) {
      return Promise.reject("empty response");
    }

    return response;
  };

  axiosClient.interceptors.response.use(interceptor, undefined);
};

setOnFulfilledInterceptor(smartripsAxios);

export { smartripsAxios as api };
