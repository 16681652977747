import { AxiosResponse } from "axios";
import moment from "moment";

import {
  ApprovalProcessDto,
  ApprovalProcessCreateDto,
  ApprovalProcessEditDto,
  RespondApprovalRequestDto
} from "../dtos/approval-process.dto";
import {
  ApprovalStatusResponseDto,
  ApprovalReponseRequestBody
} from "../dtos/approval-status.dto";
import { ApprovalsListResponse } from "../dtos/approvals.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  ApprovalTarget,
  ApprovalProcessSearchItem,
  ApprovalProcessListItem
} from "../models/approval-process.model";
import { ApprovalsList } from "../models/approvals.model";
import { UserSearch } from "../models/user.model";
import { UserAutocomplete as UserAutocompleteFactory } from "../parsers/user.parser";
import { api } from "./interceptors";

export function getApprovalRulesList(): Promise<ApprovalProcessListItem[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approvals`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovalProcessListItem[]>) => {
      return response.data;
    });
}

export function searchApprovalTarget(
  searchString: string,
  targetType?: string
): Promise<ApprovalTarget[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approval-targets`,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: {
        text: searchString,
        type: targetType
      }
    })
    .then((response: AxiosResponse<ApprovalTarget[]>) => {
      return response.data;
    });
}

export function saveApprovalTarget(data: {
  targetToken: string;
  approvalToken: string;
}): Promise<ApprovalTarget[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approval-targets`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: {
        target_token: data.targetToken,
        approval_token: data.approvalToken
      }
    })
    .then((response: AxiosResponse<ApprovalTarget[]>) => {
      return response.data;
    });
}

export function createApprovalRule(
  bodyDto: ApprovalProcessCreateDto
): Promise<{ approvalRuleToken: string }> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approvals`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: bodyDto
    })
    .then(response => ({
      approvalRuleToken: response.data.approval_process_token
    }));
}

export function editApprovalRule(
  approvalToken: string,
  data: ApprovalProcessEditDto
): Promise<{ approvalRuleToken: string }> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approvals/${approvalToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: data
    })
    .then(response => ({
      approvalRuleToken: response.data.approval_process_token
    }));
}

export function deleteApprovalRule(approvalToken: string) {
  return api.request({
    url: `/booking/approvals/${approvalToken}/inactivate`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
}

export function getApprovalRule(
  approvalToken: string
): Promise<ApprovalProcessDto> {
  return api
    .request({
      url: `/booking/approvals-flows/${approvalToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovalProcessDto>) => {
      return response.data;
    });
}

export function getTravelApprovalStatus(
  travelToken: string
): Promise<ApprovalStatusResponseDto> {
  return api
    .request({
      url: `/booking/travels/${travelToken}/approval-status`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(
      (response: AxiosResponse<ApprovalStatusResponseDto>) => response.data
    );
}

// ! Readequar esse EP para o novo modelo
export function createApprovalSolicitation(
  travelToken: string,
  message: string
) {
  return api
    .request({
      url: `/booking/travels/${travelToken}/request-approval`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: {
        request_message: message
      }
    })
    .then(response => response.data);
}

export function approveApprovalRequest(
  approvalRequestToken: string,
  body: ApprovalReponseRequestBody
): Promise<any> {
  return api.request({
    url: `/booking/approval-requests/${approvalRequestToken}/approve`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data: body
  });
}

export function denyApprovalRequest(
  approvalRequestToken: string,
  body: ApprovalReponseRequestBody
): Promise<any> {
  return api.request({
    url: `/booking/approval-requests/${approvalRequestToken}/deny`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data: body
  });
}

export function getPendingApprovalTravelTokensByUser(): Promise<string[]> {
  return api
    .request({
      url: "/booking/travels/user/pending-approval",
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<string[]>) => response.data);
}

export function searchApproverUsersByNameAndEmail(
  value: string
): Promise<UserSearch[]> {
  return api
    .request({
      url: `/booking/user/approver/search/${value}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<UserSearch[]>) =>
      response.data.map(userDto => UserAutocompleteFactory(userDto))
    );
}
export function searchApprovalProcesses(data: {
  searchQuery?: string;
  targetType?: string;
  userToken?: string;
}): Promise<ApprovalProcessSearchItem[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/approvals/search`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: {
        search_query: data.searchQuery,
        target_type: data.targetType,
        user_token: data.userToken
      }
    })
    .then((response: AxiosResponse<ApprovalProcessSearchItem[]>) => {
      return response.data;
    });
}

export function getApprovalsList(
  page: number,
  status: string,
  search?: string
): Promise<ApprovalsList> {
  return api
    .request({
      url: `/booking/travels/approvals/${page}/${status}`,
      method: "GET",
      params: {
        search: search ? search : ""
      },
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovalsListResponse>) => {
      const { travels, current, pages, total_travels } = response.data;

      return {
        travels: travels.map(travel => travel),
        currentPage: parseInt(current, 10),
        pages,
        totalTravels: total_travels
      };
    });
}

export function denyOfferApproval(offerToken: string) {
  return api.request({
    url: `/booking/offers/${offerToken}/deny`,
    method: "PUT",
    headers: getAuthorizationHeader()
  });
}

export function undenyOfferApproval(offerToken: string) {
  return api.request({
    url: `/booking/offers/${offerToken}/undeny`,
    method: "PUT",
    headers: getAuthorizationHeader()
  });
}

export function cancelOwnApprovalProcess(approvalRequestToken: string) {
  return api.request({
    url: `/booking/approval/cancel/${approvalRequestToken}`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
}
