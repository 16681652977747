import { getAuthorizationHeader, getClientToken } from "@helpers/user.helper";

import {
  AreasFactory,
  DetailedAreaFactory,
  AreaFactory
} from "../parsers/areas.parser";
import { api } from "./interceptors";

export const fetchClientAreas = () => {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/company-areas`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => AreasFactory(response.data));
};

export const fetchArea = companyAreaToken => {
  return api
    .request({
      url: `/booking/company-areas/${companyAreaToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => DetailedAreaFactory(response.data));
};

export const createArea = area => {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/company-areas`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: area
    })
    .then(response => {
      return AreaFactory(response.data);
    });
};

export const updateArea = area => {
  const { companyAreaToken, ...rest } = area;
  return api
    .request({
      url: `/booking/company-areas/${companyAreaToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: {
        ...rest
      }
    })
    .then(response => AreaFactory(response.data));
};

export const addUserToArea = (userToken, companyAreaToken) => {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/user-areas`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: {
        user_token: userToken,
        company_area_token: companyAreaToken
      }
    })
    .then(response => AreaFactory(response.data));
};

export const removeUserFromArea = userAreaToken => {
  return api
    .request({
      url: `/booking/user-areas/${userAreaToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
};

export const fetchAreasBySearchText = searchText => {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/company-areas/search/${searchText}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => AreasFactory(response.data));
};

export const updateAreaDisplayNameFromClientConfig = (
  clientConfigToken,
  areaName
) => {
  return api
    .request({
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: {
        company_area_display: areaName
      }
    })
    .then(response => response.data);
};

export const updateBoardDisplayNameFromClientConfig = (
  clientConfigToken,
  boardName
) => {
  return api
    .request({
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: {
        company_board_display: boardName
      }
    })
    .then(response => response.data);
};

export const updateCompanyAreaEnabledFromClientConfig = (
  clientConfigToken,
  status
) => {
  return api
    .request({
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: {
        company_area_enabled: status
      }
    })
    .then(response => response.data);
};

export const getUserAreas = userToken => {
  return api
    .request({
      url: `/booking/users/${userToken}/company-areas`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => AreasFactory(data));
};
