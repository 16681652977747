import { AxiosResponse } from "axios";

import {
  GetServicesInvoiceListResponseBody,
  DownloadTransactionsInvoicesRequestBody,
  DownloadTransactionsInvoicesResponseBody,
  GetSmartripsServicesInvoiceListReponseBody
} from "../dtos/financial.dto";
import * as utils from "../helpers";
import { getAuthorizationHeader } from "../helpers/user.helper";
import { SmartripsInvoiceItem } from "../models/financial.model";
import { api } from "./interceptors";

export function getServicesInvoiceList(
  startDate: string | null,
  endDate: string | null,
  type: string,
  page: number,
  search?: string,
  tag?: string
): Promise<GetServicesInvoiceListResponseBody> {
  const params = {
    startDate,
    endDate,
    search,
    tag
  };

  return api
    .request({
      url: utils.buildUrlWithParamters(
        `/booking/financial/transaction/list/${page}/${type}`,
        params
      ),
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(
      (response: AxiosResponse<GetServicesInvoiceListResponseBody>) =>
        response.data
    );
}

export function getSmartripsServicesInvoiceList(
  startDate: string | null,
  endDate: string | null
): Promise<SmartripsInvoiceItem[]> {
  const params = {
    startDate,
    endDate
  };

  return api
    .request({
      url: utils.buildUrlWithParamters(
        `/booking/financial/transaction/list/1/smartrips`,
        params
      ),
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(
      (response: AxiosResponse<GetSmartripsServicesInvoiceListReponseBody>) =>
        response.data.services.map(service => ({
          type: service.service,
          receiptFile: service.receipt_file
        }))
    );
}

export function downloadTransactionsInvoices(
  dto: DownloadTransactionsInvoicesRequestBody
) {
  return api
    .request({
      url: `/booking/financial/transaction/download`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      (response: AxiosResponse<DownloadTransactionsInvoicesResponseBody>) => {
        const link = document.createElement("a");
        link.href = response.data.filename;
        link.setAttribute("download", "smartrips-pacote-notas.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    );
}

export function downloadFinancialStatement(
  startDate: string | null,
  endDate: string | null,
  tag: string
) {
  const params = {
    startDate,
    endDate,
    tag
  };

  return api
    .request({
      url: utils.buildUrlWithParamters(
        "/booking/financial/statement/download",
        params
      ),
      method: "GET",
      headers: getAuthorizationHeader(),
      responseType: "blob"
    })
    .then(response => {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "smartrips-extrato.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export function downloadTravelInvoices(travelToken: string) {
  return api
    .request({
      url: `/booking/financial/travels/${travelToken}/download`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      utils.forceFileDownload(
        response.data.filename,
        `travel-${travelToken}-invoices.zip`
      );
    });
}

export function downloadExpenseReportInvoices(expenseReportToken: string) {
  return api
    .request({
      url: `/booking/financial/expense/${expenseReportToken}/download`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      utils.forceFileDownload(
        response.data.filename,
        `report-${expenseReportToken}-invoices.zip`
      );
    });
}
