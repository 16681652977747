import React, { useState, useMemo } from "react";
import Cards, { Focused } from "react-credit-cards";

import "react-credit-cards/es/styles-compiled.css";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { theme } from "@skin/v2";

import { removeBlankSpaces } from "~/helpers";

import { Checkbox } from "@shared/checkbox/Checkbox";

import BillingProfileSelect from "~/components/shared/billing-profile-select";
import { Form, useFormContext } from "~/components/shared/form";
import { Spacing } from "~/components/shared/layout";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";

import { CARD_OWNER_TYPES, MEDIA_QUERIES } from "../../../../constants";
import { CARD_ISSUERS } from "../../../../constants/credit-card.constant";
import { Input, MaskedInput } from "../../../shared/inputs";
import { Row } from "../../../shared/layout/Row";
import { CardIssuer, CardCreationFormProps } from "../CardCreation.types";
import { CardNumberInput } from "../fields/CardNumberInput";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  main: css({
    display: "flex",
    flexDirection: "row",
    overflowY: "auto",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      flexDirection: "column-reverse",
    },
  }),
  contentColumn: css({
    padding: "1.75rem 2rem",
    maxWidth: "calc(50%)",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      maxWidth: "none",
      padding: "1rem 1rem",
    },
  }),
  subTitle: css({
    marginBottom: "0.5rem",
    fontWeight: 600,
    fontSize: 18,
  }),
  inputDiv: css({
    marginBottom: "1rem",
  }),
  formTypeCardlSelectedLabel: css({
    marginRight: 0,
    width: "6rem",
    color: "#999BA0",
  }),
  centeredText: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
  }),
  footer: css({
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    padding: "2rem",
  }),
};

const getIssuer = (value: string): CardIssuer | null => {
  const numberWithoutSpaces = removeBlankSpaces(value);
  const issuer = CARD_ISSUERS.find(
    (item) => !!numberWithoutSpaces.match(item.startsWith),
  );

  return issuer || null;
};

export default function CardCreationForm(props: CardCreationFormProps) {
  const { showClientSettings, handleCancel, defaultOwnerType } = props;

  const { showSnackMessage } = useApplication();

  const [focused, setFocused] = useState<Focused>();
  const { watch, submitForm, setValue } = useFormContext();

  const issuer = watch("issuer");
  const enableToFlight = watch("enableToFlight");
  const enableToCar = watch("enableToCar");
  const enableToBus = watch("enableToBus");
  const enableToOther = watch("enableToOther");
  const enableToHotel = watch("enableToHotel");
  const enableToRide = watch("enableToRide");

  const hasTypeSelected =
    enableToFlight ||
    enableToCar ||
    enableToBus ||
    enableToOther ||
    enableToHotel ||
    enableToRide;

  const onSubmitForm = () => {
    if (!hasTypeSelected) {
      showSnackMessage(
        "Pelo menos uma finalidade deve ser selecionada.",
        ALERT_TYPES.ERROR,
      );
      return;
    }
    return submitForm();
  };

  const actions = useMemo(() => {
    return {
      onFocus: function onFocus(event: React.FocusEvent<HTMLInputElement>) {
        let focusedField: Focused | undefined;

        switch (event.target.id) {
          case "number":
            focusedField = "number";
            break;
          case "holderName":
            focusedField = "name";
            break;
          case "expirationDate":
            focusedField = "expiry";
            break;
          case "cvv":
            focusedField = "cvc";
            break;
        }

        setFocused(focusedField);
      },
      onChange: function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const issuer = getIssuer(event.target.value);

        if (issuer) {
          setValue("issuer", issuer);
        }
      },
    };
  }, [setValue]);

  return (
    <section css={styles.root}>
      <div css={styles.main}>
        <div css={styles.contentColumn}>
          <div>
            {defaultOwnerType === CARD_OWNER_TYPES.CLIENT ? (
              <div style={{ marginBottom: "1.5rem" }}>
                <p css={styles.subTitle}>Perfil de cobrança</p>
                <Form.Field
                  name="billingProfile"
                  as={
                    <BillingProfileSelect
                      additionalOptions={[
                        { label: "Aplicar para todos", value: "null" },
                      ]}
                      onFocus={actions.onFocus}
                      onCreditCardTab
                      placeholder="Selecione um perfil"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          display: "flex",
                          justifyContent: "center",
                          height: "3rem",
                          cursor: "pointer",
                          borderRadius: "0.5rem",
                          border: `1px solid ${theme.colors.gray[500]}`,
                          boxShadow: "none",
                          "&:hover": {
                            borderColor: theme.colors.gray[700],
                          },
                          "&:focus-within": {
                            border: `1px solid ${theme.colors.gray[500]}`,
                            boxShadow: `0 0 0 1px ${theme.colors.gray[500]}`,
                          },
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          zIndex: 10,
                        }),
                        menuList: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: "0.5rem",
                        }),
                        option: (baseStyles, { isSelected }) => ({
                          ...baseStyles,
                          cursor: "pointer",
                          padding: "0.75rem 1rem",
                          color: isSelected
                            ? theme.colors.white
                            : theme.colors.black,
                          backgroundColor: isSelected
                            ? theme.colors.gray[500]
                            : theme.colors.white,
                          "&:hover": {
                            backgroundColor: theme.colors.gray[300],
                            color: theme.colors.white,
                          },
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          flex: "none",
                        }),
                        indicatorSeparator: () => ({}),
                        dropdownIndicator: (baseStyles) => ({
                          ...baseStyles,
                          color: theme.colors.gray[500],
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          display: "flex",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          lineHeight: "1.25rem",
                          maxWidth: "16rem",
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: theme.colors.gray[500],
                          fontWeight: "bold",
                        }),
                      }}
                      theme={(currentTheme) => ({
                        ...currentTheme,
                        colors: {
                          ...currentTheme.colors,
                          primary: theme.colors.gray[500],
                        },
                      })}
                    />
                  }
                />
              </div>
            ) : null}

            <p css={styles.subTitle}>Dados do cartão</p>
            <div css={styles.inputDiv} style={{ marginTop: "1rem" }}>
              <Form.Field
                name="holderName"
                as={
                  <Input
                    id="holderName"
                    onFocus={actions.onFocus}
                    label="Nome do portador (igual ao cartão)"
                    placeholder="John Doe"
                  />
                }
              />
            </div>
            <div css={styles.inputDiv}>
              <Form.Field
                name="number"
                onChange={actions.onChange}
                as={
                  <CardNumberInput
                    id="number"
                    label="Número do cartão"
                    placeholder="0000 0000 0000 0000"
                    onFocus={actions.onFocus}
                    issuer={issuer}
                  />
                }
              />
            </div>
            <div css={styles.inputDiv}>
              <Row>
                <Form.Field
                  name="expirationDate"
                  as={
                    <MaskedInput
                      id="expirationDate"
                      label="Data de expiração"
                      mask="month-and-year"
                      placeholder="11/20"
                      onFocus={actions.onFocus}
                    />
                  }
                />

                <Spacing direction="right" space={4} />

                <Form.Field
                  name="cvv"
                  as={
                    <MaskedInput
                      id="cvv"
                      name="cvv"
                      label="CVV"
                      placeholder="123"
                      mask="cvv"
                      onFocus={actions.onFocus}
                    />
                  }
                />
              </Row>
            </div>
            <div css={styles.inputDiv}>
              <Form.Field
                name="description"
                as={
                  <Input
                    id="description"
                    name="description"
                    label="Apelido do cartão"
                    placeholder="Cartão de Marketing"
                    onFocus={actions.onFocus}
                  />
                }
              />
            </div>

            <p css={styles.subTitle} style={{ marginTop: "1.5rem" }}>
              Finalidade do cartão
            </p>
            <div>
              <Row style={{ justifyContent: "space-between" }}>
                <Form.Field<boolean>
                  name="enableToFlight"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToFlight"
                          name="enableToFlight"
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          checked={props.value}
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Aéreo"
                    />
                  )}
                />
                <Form.Field<boolean>
                  name="enableToHotel"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToHotel"
                          name="enableToHotel"
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          defaultChecked={props.value}
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Hotel"
                    />
                  )}
                />

                <Form.Field<boolean>
                  name="enableToCar"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToCar"
                          name="enableToCar"
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          defaultChecked={props.value}
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Carro"
                    />
                  )}
                />
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <Form.Field<boolean>
                  name="enableToRide"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToRide"
                          name="enableToRide"
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          defaultChecked={props.value}
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Táxi"
                    />
                  )}
                />
                <Form.Field<boolean>
                  name="enableToBus"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToBus"
                          name="enableToBus"
                          defaultChecked={props.value}
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Ônibus"
                    />
                  )}
                />
                <Form.Field<boolean>
                  name="enableToOther"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="enableToOther"
                          name="enableToOther"
                          defaultChecked={props.value}
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Outro"
                    />
                  )}
                />
              </Row>
            </div>
            {defaultOwnerType === "user" ? (
              <Row style={{ position: "absolute" }}>
                <Form.Field<boolean>
                  name="allowApprovers"
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="allowApprovers"
                          placeholder="Cartão de Marketing"
                          onChange={(event) =>
                            props.onChange(event.target.checked as any)
                          }
                          defaultChecked={props.value}
                          color={theme.colors.gray[500]}
                        />
                      }
                      label="Viabilizar o uso deste cartão em minhas viagens por parte de aprovadores e solicitantes."
                    />
                  )}
                />
              </Row>
            ) : null}
          </div>

          {/* Atualmente esse checkbox não funciona */}
          {/* {showClientSettings ? (
            <>
              <Row>
                <Form.Field<boolean>
                  name="enableToClient"
                  render={props => (
                    <FormControlLabel
                      classes={{ root: styles.formControlLabel }}
                      control={
                        <Checkbox
                          id="enableToClient"
                          name="enableToClient"
                          onChange={event => event.target.checked}
                          defaultChecked={props.value}
                        />
                      }
                      label="Tornar cartão visível para toda a empresa"
                    />
                  )}
                />
              </Row>
            </>
          ) : null} */}
        </div>
        <div css={styles.contentColumn}>
          <CardCreationCard focused={focused} />
        </div>
      </div>
      <div css={styles.footer}>
        <ContainedButton onClick={onSubmitForm}>
          Salvar alterações
        </ContainedButton>
        <OutlinedButton onClick={handleCancel}>Cancelar</OutlinedButton>
      </div>
    </section>
  );
}

function CardCreationCard(props: { focused?: Focused }) {
  const { watch } = useFormContext();

  const values = watch(["number", "expirationDate", "cvv", "holderName"]);

  return (
    <Cards
      number={values.number}
      name={values.holderName}
      expiry={values.expirationDate}
      cvc={values.cvv}
      focused={props.focused}
      placeholders={{
        name: "Keanu Reeves",
      }}
      locale={{
        valid: "Válido até",
      }}
      acceptedCards={["visa", "mastercard", "amex", "dinersclub", "elo"]}
    />
  );
}
