import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    explanation: {
      button: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.pink[500],
        cursor: "pointer",
        fontSize: "0.875rem",
        fontWeight: 500,
        textDecoration: "underline",
        transition: "all 0.15s ease-in-out",
        width: "fit-content",
        ":hover": {
          color: theme.colors.pink[700],
        },
      }),
      info: css({
        color: theme.colors.pink[500],
        fontSize: "0.875rem",
        fontWeight: 500,
      }),
      paragraph: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "24px",
        width: "100%",
        "@media screen and (max-width: 768px)": {
          padding: "16px",
        },
      }),
      title: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
    image: css({
      width: "80px",
    }),
    important: {
      paragraph: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "24px",
        width: "100%",
        "@media screen and (max-width: 768px)": {
          padding: "16px",
        },
      }),
      title: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
    referral: {
      button: css({
        fontSize: "0.875rem",
      }),
      display: {
        button: css({
          flexShrink: 0,
        }),
        root: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          color: theme.colors.gray[700],
          display: "flex",
          gap: "16px",
          maxWidth: "fit-content",
          padding: "12px 16px",
          whiteSpace: "nowrap",
          width: "100%",
        }),
        text: css({
          lineHeight: "20px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
      },
      label: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
        textAlign: "center",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    }),
    stepper: {
      root: css({
        padding: "24px 0 0 0",
        ".Mui": {
          "&Stepper-root": {
            padding: "0",
          },
          "&StepConnector": {
            "&-line": {
              borderColor: theme.colors.pink[300],
            },
            "&-root": {
              top: "16px",
            },
          },
          "&Typography-root": {
            color: theme.colors.gray[700],
            fontFamily: theme.fonts.inter,
          },
        },
      }),
      step: {
        icon: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.pink[500]}`,
          borderRadius: "50%",
          color: theme.colors.pink[500],
          display: "flex",
          fontSize: "0.75rem",
          fontWeight: 600,
          height: "32px",
          justifyContent: "center",
          width: "32px",
        }),
        label: css({
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }),
      },
    },
    terms: {
      button: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.pink[500],
        cursor: "pointer",
        fontSize: "0.875rem",
        fontWeight: 500,
        textAlign: "right",
        textDecoration: "underline",
        transition: "all 0.15s ease-in-out",
        width: "fit-content",
        ":hover": {
          color: theme.colors.pink[700],
        },
      }),
      root: css({
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
      }),
    },
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      textAlign: "center",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
    "@media screen and (max-width: 768px)": {
      width: "100dvw",
    },
  }),
};
