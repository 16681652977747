import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "../icon/icon";
import {
  getMonthsShortLocale,
  monthsInBetweenMap,
} from "./month-picker.helper";
import { styles } from "./styles";

enum CalendarType {
  END = "end",
  START = "start",
}

enum OrientationType {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

type State = {
  endDisplayedYear: number | undefined;
  endSelectedMonth: number | undefined;
  endSelectedYear: number | undefined;
  startDisplayedYear: number | undefined;
  startSelectedMonth: number | undefined;
  startSelectedYear: number | undefined;
};

const initialState: State = {
  endDisplayedYear: undefined,
  endSelectedMonth: undefined,
  endSelectedYear: undefined,
  startDisplayedYear: undefined,
  startSelectedMonth: undefined,
  startSelectedYear: undefined,
};

type Props = {
  endMonth?: number;
  endYear?: number;
  isClearEnabled?: boolean;
  onRangeApply?: (range: any) => void;
  orientation?: "horizontal" | "vertical";
  startMonth?: number | null;
  startYear?: number | null;
};

export const MonthPicker: React.FC<Props> = ({
  endMonth,
  endYear,
  isClearEnabled = false,
  onRangeApply,
  orientation = "horizontal",
  startMonth,
  startYear,
}) => {
  const [state, setState] = useState(initialState);

  const onApplyClick = useCallback(() => {
    const {
      endSelectedMonth,
      endSelectedYear,
      startSelectedMonth,
      startSelectedYear,
    } = state;

    const range = {
      startSelectedMonth,
      startSelectedYear,
      endSelectedMonth:
        typeof endSelectedMonth !== "undefined"
          ? endSelectedMonth
          : startSelectedMonth,
      endSelectedYear:
        typeof endSelectedYear !== "undefined"
          ? endSelectedYear
          : startSelectedYear,
    };

    onRangeApply?.(range);
  }, [
    onRangeApply,
    state.endSelectedMonth,
    state.endSelectedYear,
    state.startSelectedMonth,
    state.startSelectedYear,
  ]);

  const onClearClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      endSelectedMonth: undefined,
      endSelectedYear: undefined,
      startSelectedMonth: undefined,
      startSelectedYear: undefined,
    }));
  }, []);

  const onEndYearDecrementClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      endDisplayedYear: prev.endDisplayedYear
        ? prev.endDisplayedYear - 1
        : prev.endDisplayedYear,
    }));
  }, []);

  const onEndYearIncrementClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      endDisplayedYear: prev.endDisplayedYear
        ? prev.endDisplayedYear + 1
        : prev.endDisplayedYear,
      startDisplayedYear: prev.startDisplayedYear
        ? prev.startDisplayedYear + 1
        : prev.startDisplayedYear,
    }));
  }, []);

  const onMonthClick = useCallback(
    (month: number, displayedYear: number) => {
      return () => {
        const {
          endSelectedMonth: currentEndSelectedMonth,
          startSelectedMonth: currentStartSelectedMonth,
          startSelectedYear: currentStartSelectedYear = 0,
        } = state;

        if (typeof currentStartSelectedMonth === "undefined") {
          setState((prev) => ({
            ...prev,
            startSelectedMonth: month,
            startSelectedYear: displayedYear,
          }));

          return;
        }

        if (
          typeof currentStartSelectedMonth !== "undefined" &&
          typeof currentEndSelectedMonth === "undefined"
        ) {
          const isSelectedMonthBeforeEqualStart =
            month * 30 + displayedYear * 360 <=
            currentStartSelectedMonth * 30 + currentStartSelectedYear * 360;

          if (isSelectedMonthBeforeEqualStart) {
            setState((prev) => ({
              ...prev,
              startSelectedMonth: month,
              startSelectedYear: displayedYear,
            }));
          } else {
            setState((prev) => ({
              ...prev,
              endSelectedMonth: month,
              endSelectedYear: displayedYear,
            }));
          }

          return;
        }

        if (
          typeof currentStartSelectedMonth !== "undefined" &&
          typeof currentEndSelectedMonth !== "undefined"
        ) {
          setState((prev) => ({
            ...prev,
            endSelectedMonth: undefined,
            endSelectedYear: undefined,
            startSelectedMonth: month,
            startSelectedYear: displayedYear,
          }));

          return;
        }
      };
    },
    [state.endSelectedMonth, state.startSelectedMonth, state.startSelectedYear],
  );

  const onStartYearDecrementClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      endDisplayedYear: prev.endDisplayedYear
        ? prev.endDisplayedYear - 1
        : prev.endDisplayedYear,
      startDisplayedYear: prev.startDisplayedYear
        ? prev.startDisplayedYear - 1
        : prev.startDisplayedYear,
    }));
  }, []);

  const onStartYearIncrementClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      startDisplayedYear: prev.startDisplayedYear
        ? prev.startDisplayedYear + 1
        : prev.startDisplayedYear,
    }));
  }, []);

  useEffect(() => {
    const today = new Date();

    if (
      typeof startMonth === "undefined" ||
      startMonth === null ||
      typeof startYear === "undefined" ||
      startYear === null
    ) {
      setState({
        endDisplayedYear: today.getFullYear() + 1,
        endSelectedMonth: 11,
        endSelectedYear: today.getFullYear(),
        startDisplayedYear: today.getFullYear(),
        startSelectedMonth: 0,
        startSelectedYear: today.getFullYear(),
      });

      return;
    }

    setState({
      endDisplayedYear: startYear + 1,
      endSelectedMonth: endMonth,
      endSelectedYear: endYear,
      startDisplayedYear: startYear,
      startSelectedMonth: startMonth,
      startSelectedYear: startYear,
    });
  }, []);

  const renderHeader = (calendarType: CalendarType) => {
    let displayedYear, onYearDecrementClick, onYearIncrementClick;

    const { endDisplayedYear, startDisplayedYear } = state;

    if (calendarType === CalendarType.START) {
      displayedYear = startDisplayedYear;
      onYearDecrementClick = onStartYearDecrementClick;
      onYearIncrementClick = onStartYearIncrementClick;
    } else {
      displayedYear = endDisplayedYear;
      onYearDecrementClick = onEndYearDecrementClick;
      onYearIncrementClick = onEndYearIncrementClick;
    }

    return (
      <div css={styles.top.header.root}>
        {calendarType === CalendarType.START &&
        orientation === OrientationType.HORIZONTAL ? (
          <button
            css={styles.top.header.nav}
            onClick={onYearDecrementClick}
            type="button"
          >
            <Icon size={16} use="chevron-left" />
          </button>
        ) : null}
        {calendarType === CalendarType.START &&
          orientation === OrientationType.VERTICAL && (
            <button
              css={styles.top.header.nav}
              onClick={onYearDecrementClick}
              type="button"
            >
              <Icon size={16} use="chevron-up" />
            </button>
          )}
        <div css={styles.top.header.year}>{displayedYear}</div>
        {calendarType === CalendarType.END &&
        orientation === OrientationType.HORIZONTAL ? (
          <button
            css={styles.top.header.nav}
            onClick={onYearIncrementClick}
            type="button"
          >
            <Icon size={16} use="chevron-right" />
          </button>
        ) : null}
        {calendarType === CalendarType.END &&
          orientation === OrientationType.VERTICAL && (
            <button
              css={styles.top.header.nav}
              onClick={onYearIncrementClick}
              type="button"
            >
              <Icon size={16} use="chevron-down" />
            </button>
          )}
      </div>
    );
  };

  const renderMonths = (calendarType: CalendarType) => {
    let displayedYear = 0;

    const monthsShort = getMonthsShortLocale("pt");

    const {
      endDisplayedYear,
      endSelectedMonth,
      endSelectedYear,
      startDisplayedYear,
      startSelectedMonth,
      startSelectedYear,
    } = state;

    if (calendarType === CalendarType.START) {
      displayedYear = startDisplayedYear ? startDisplayedYear : 0;
    } else {
      displayedYear = endDisplayedYear ? endDisplayedYear : 0;
    }

    const monthsInBetween = monthsInBetweenMap({
      displayedYear,
      endMonth: endSelectedMonth,
      endYear: endSelectedYear,
      startMonth: startSelectedMonth,
      startYear: startSelectedYear,
    });

    const months = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [9, 10, 11],
    ];

    const Months = months.map((month, i) => (
      <div css={styles.top.body.root} key={i}>
        {month.map((m, j) => {
          const isSelected =
            (startSelectedMonth === m && startSelectedYear === displayedYear) ||
            (endSelectedMonth === m && endSelectedYear === displayedYear);

          const isInBetween = monthsInBetween[m];

          return (
            <div
              css={styles.top.body.month({
                i,
                isInBetween,
                isSelected,
                j,
              })}
              key={j}
              onClick={onMonthClick(m, displayedYear)}
            >
              {monthsShort[m]}
            </div>
          );
        })}
      </div>
    ));

    return <div>{Months}</div>;
  };

  return (
    <div css={styles.root}>
      <div css={styles.top.root({ orientation })}>
        <div css={styles.top.section}>
          {renderHeader(CalendarType.START)}
          {renderMonths(CalendarType.START)}
        </div>
        <div css={styles.top.section}>
          {renderHeader(CalendarType.END)}
          {renderMonths(CalendarType.END)}
        </div>
      </div>
      <div css={styles.bottom.root}>
        {isClearEnabled ? (
          <button
            css={styles.bottom.clear}
            onClick={onClearClick}
            type="button"
          >
            Limpar
          </button>
        ) : null}
        <button css={styles.bottom.apply} onClick={onApplyClick} type="button">
          Aplicar
        </button>
      </div>
    </div>
  );
};
