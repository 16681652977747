import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "0 auto",
    width: "calc(100% - 2 * 8px)",
  }),
  layout: css({
    backgroundColor: theme.colors.background.gray,
  }),
  outlet: {
    root: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      flexGrow: 1,
      padding: "0 12px",
    }),
  },
  root: css({
    display: "flex",
    gap: "16px",
    height: "100%",
    margin: "0 auto",
    maxWidth: "1920px",
    padding: "16px",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "16px 24px 24px 24px",
    },
    "@media (max-width: 992px)": {
      flexDirection: "column",
      padding: "16px",
    },
  }),
  router: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  }),
};
