import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { styles } from "./styles";

type Props = {
  handleOpenMemberGetMemebrDrawer: () => void;
};

export const NavBarDesktopMemberGetMemberButton: React.FC<Props> = ({
  handleOpenMemberGetMemebrDrawer,
}) => {
  return (
    <Tooltip arrow content="Indique e ganhe" position="bottom">
      <button
        css={styles.root}
        onClick={() => {
          handleOpenMemberGetMemebrDrawer();
        }}
      >
        <Icon size={16} use="person-invite" />
      </button>
    </Tooltip>
  );
};
