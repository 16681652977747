import { ClientBookingPhoneConfig } from "~/constants/enums";

import { GetClientConfigResponseBody } from "@dtos/client.dto";

export function parseClientConfig(data: GetClientConfigResponseBody) {
  return {
    clientConfigToken: data.client_config_token,
    costCenterActive: data.cost_center_active,
    budgetsActive: data.budgets_active,
    vaultActive: data.vault_active,
    approvalOption: data.approval_option,
    approvalConfig: data.approval_config,
    bank3Enable: data.bank3_enabled,
    tagsAdminOnly: data.tags_admin_only,
    travelTagsRequired: data.travel_tags_required,
    bookingPhoneConfig: data.booking_phone_config
      ? data.booking_phone_config
      : ClientBookingPhoneConfig.NOT_REQUIRED,
    companyAreaEnabled: data.company_area_enabled,
    companyAreaDisplay: data.company_area_display,
    companyBoardDisplay: data.company_board_display,
    projectEnablingState: data.project_enabling_state,
    customCorporativeCardName: data.custom_corporative_card_name,
    approvalPriorityRanking: data.approval_ranking,
    travelIntegration: data.travel_integration,
    supportChannel: data.support_channel,
    flightVisible: data.flight_visible,
    hotelVisible: data.hotel_visible,
    carVisible: data.car_visible,
    busVisible: data.bus_visible,
    customMessage: data.custom_message,
    config: data.config
  };
}
