import React, { useContext, useEffect, useMemo } from "react";

import { pageTitles } from "~/apps/corporate/constants/page-titles";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import * as hotelSearchHelper from "~/apps/corporate/helpers/hotel.helper";
import { getClientToken } from "~/apps/corporate/helpers/user.helper";
import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import {
  HospitalityHotel,
  HotelFavorite,
  HotelSearchInfo,
  VisibleHotels,
} from "~/apps/corporate/models/hotel.model";
import { HotelListOffer } from "~/apps/corporate/models/offer.model";
import { TravelInfo } from "~/apps/corporate/models/travel.model";
import { User } from "~/apps/corporate/models/user.model";
import { ALERT_TYPES, HOTEL_SORT_OPTIONS } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useSafeState } from "~/apps/shared/hooks/use-safe-state";
import { Error } from "~/apps/shared/types";
import debounce from "lodash/debounce";

import * as hotelsService from "./hotels.service";
import { FavouriteTargetType } from "./hotels.types";

interface State {
  amenities: {
    checked: boolean;
    label: string;
    value:
      | "breakfast"
      // | "air"
      | "gym"
      | "parking"
      | "reception"
      | "refund"
      | "restaurant"
      | "wifi";
  }[];
  averagePrice: number;
  countryRestriction: CountryRestriction | null;
  distanceRange: [number, number];
  errorOnFetch: Error | null;
  filtering: boolean;
  hotelNameFilter: string;
  hotels: HospitalityHotel[];
  isEditing: boolean;
  isFakeLoading: boolean;
  isFavoriteDialogOpen: boolean;
  isFiltersDrawerVisible: boolean;
  isLoading: boolean;
  isLoadingFavorite: boolean;
  isMapVisible: boolean;
  isPolicyInfoVisible: boolean;
  onlyInPolicy: boolean;
  outOfPolicySelectedHotel: HospitalityHotel | null;
  outOfPolicySelectedOffer: HotelListOffer | null;
  pageTitle: string;
  priceRange: [number, number];
  policy: {
    advance: number;
    price: number;
    stars: number;
  } | null;
  searchInfo: HotelSearchInfo | null;
  selectedDistanceRange: [number, number];
  selectedHotelToFavourite: HospitalityHotel | null;
  selectedPriceRange: [number, number];
  sortedBy: "DISTANCE" | "PRICE";
  stars: Array<{
    checked: boolean;
    value: number;
  }>;
  supplierSearchToken: string;
  travelerInfo: User | null;
  travelInfo: TravelInfo | null;
}

interface Actions {
  closeFavoriteDialog: () => void;
  debounceNameFilter: (search: string) => void;
  handleApplyFilters: (filterState: any) => () => void;
  handleChangeAmenitiesFilter: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleChangeDistanceRange: (distanceRange: [number, number]) => void;
  handleChangePolicyFilter: (_: any, checked: boolean) => void;
  handleChangePriceRange: (priceRange: [number, number]) => void;
  handleChangeSortOption: (sortOption: "PRICE" | "DISTANCE") => void;
  handleChangeStarsFilter: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleCloseEditDrawer: () => void;
  handleCloseFiltersDrawer: () => void;
  handleCloseOutOfPolicyDialog: () => void;
  handleOpenEditDrawer: () => void;
  handleOpenFiltersDrawer: () => void;
  handleShowOutOfPolicyDialog: (hotel: HospitalityHotel) => () => void;
  handleToggleMapVisibility: () => void;
  loadHotelInfos: (hotelToken: string) => void;
  openFavoriteDialog: (hotel: HospitalityHotel) => void;
  removeHotelFavorite: (
    hotel: {
      favorites: HotelFavorite[];
      hotelToken: string;
    },
    favouriteToken: string,
  ) => Promise<void>;
  setHotelFavorite: (
    hotel: {
      favorites: HotelFavorite[];
      hotelToken: string;
      name: string;
    },
    targetType: FavouriteTargetType,
  ) => Promise<void>;
  togglePolicyInfoVisibility: () => void;
}

interface Selectors {
  visibleHotels: VisibleHotels;
}

type ContextProps = State & Actions & Selectors;

const initialState: State = {
  amenities: [
    { value: "breakfast", label: "Café da manhã incluso", checked: false },
    { value: "wifi", label: "Wifi grátis", checked: false },
    { value: "refund", label: "Cancelamento grátis", checked: false },
    { value: "parking", label: "Estacionamento", checked: false },
  ],
  averagePrice: 0,
  countryRestriction: null,
  distanceRange: [0, 0],
  errorOnFetch: null,
  filtering: false,
  hotelNameFilter: "",
  hotels: [],
  isEditing: false,
  isFakeLoading: true,
  isFavoriteDialogOpen: false,
  isFiltersDrawerVisible: false,
  isLoading: false,
  isLoadingFavorite: false,
  isMapVisible: false,
  isPolicyInfoVisible: false,
  onlyInPolicy: false,
  outOfPolicySelectedHotel: null,
  outOfPolicySelectedOffer: null,
  pageTitle: pageTitles.SEARCH_HOTELS,
  policy: null,
  priceRange: [0, 0],
  searchInfo: null,
  selectedDistanceRange: [0, 0],
  selectedHotelToFavourite: null,
  selectedPriceRange: [0, 0],
  sortedBy: HOTEL_SORT_OPTIONS.PRICE,
  stars: [
    { value: 5, checked: false },
    { value: 4, checked: false },
    { value: 3, checked: false },
    { value: 2, checked: false },
    { value: 1, checked: false },
    { value: 0, checked: false },
  ],
  supplierSearchToken: "",
  travelerInfo: null,
  travelInfo: null,
};

const HotelsContext = React.createContext<ContextProps>({
  ...initialState,
  closeFavoriteDialog: () => null,
  debounceNameFilter: () => null,
  handleApplyFilters: () => () => null,
  handleChangeAmenitiesFilter: () => null,
  handleChangeDistanceRange: () => null,
  handleChangePolicyFilter: () => null,
  handleChangePriceRange: () => null,
  handleChangeSortOption: () => null,
  handleChangeStarsFilter: () => null,
  handleCloseEditDrawer: () => null,
  handleCloseFiltersDrawer: () => null,
  handleCloseOutOfPolicyDialog: () => null,
  handleOpenEditDrawer: () => null,
  handleOpenFiltersDrawer: () => null,
  handleShowOutOfPolicyDialog: () => () => null,
  handleToggleMapVisibility: () => null,
  loadHotelInfos: () => null,
  openFavoriteDialog: () => () => null,
  removeHotelFavorite: async () => {
    return;
  },
  setHotelFavorite: async () => {
    return;
  },
  togglePolicyInfoVisibility: () => null,
  visibleHotels: {
    alternativeHotels: [],
    favouriteHotels: [],
  },
});

export const HotelsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useSafeState<State>(initialState);

  const {
    amenities,
    hotelNameFilter,
    hotels,
    onlyInPolicy,
    selectedDistanceRange,
    selectedPriceRange,
    sortedBy,
    stars,
  } = state;

  const closeFavoriteDialog = () => {
    setState({
      isFavoriteDialogOpen: false,
    });
  };

  const loadHotelInfos = async (hotelToken: string) => {
    setState({
      isLoading: true,
    });

    const loadHotelsInfoResponse = await hotelsService.loadHotelsInfo(
      hotelToken,
    );

    if (loadHotelsInfoResponse.error) {
      const error = loadHotelsInfoResponse.error;

      setState({
        errorOnFetch: error,
        isLoading: false,
      });

      return;
    }

    if (!loadHotelsInfoResponse.data) {
      const error = ERROR.UNEXPECTED;

      setState({
        errorOnFetch: error,
        isLoading: false,
      });

      return;
    }

    const hotelInfo = loadHotelsInfoResponse.data;

    if (hotelInfo.isSearchDenied) {
      setState({
        countryRestriction: hotelInfo!.countryRestriction,
        hotels: [],
        isLoading: false,
        pageTitle: `País com restrição ${
          hotelInfo!.countryRestriction.country_name
        }`,
      });

      return;
    }

    const {
      averagePrice,
      maxDistance,
      maxPrice,
      minDistance,
      minPrice,
      orderBy,
    } = hotelInfo.filtersInfo;

    const distanceRange: [number, number] = [
      Math.floor(minDistance),
      Math.ceil(maxDistance),
    ];

    const priceRange: [number, number] = [
      Math.floor(minPrice),
      Math.ceil(maxPrice),
    ];

    setState({
      averagePrice: averagePrice,
      countryRestriction: hotelInfo.countryRestriction,
      distanceRange,
      hotels: hotelInfo.hotelList,
      isLoading: false,
      pageTitle: `Resultados de hotéis para ${hotelInfo.searchInfo.hotelSearch}`,
      policy: {
        advance: hotelInfo.policy.advance,
        price: hotelInfo.policy.maxValue,
        stars: hotelInfo.policy.stars,
      },
      priceRange,
      searchInfo: hotelInfo.searchInfo,
      selectedDistanceRange: distanceRange,
      selectedPriceRange: priceRange,
      sortedBy: orderBy,
      supplierSearchToken: hotelInfo.supplierSearchToken,
      travelerInfo: hotelInfo.travelerInfo,
      travelInfo: hotelInfo.travelInfo,
    });
  };

  const setHotelFavorite = async (
    hotel: {
      favorites: HotelFavorite[];
      hotelToken: string;
      name: string;
    },
    targetType: FavouriteTargetType,
  ) => {
    if (!user) {
      return;
    }

    setState({ isLoadingFavorite: true });

    const targetToken =
      targetType === "u" ? user.getUserToken() : getClientToken();

    if (!targetToken) {
      setState({ isLoadingFavorite: false });

      return;
    }

    const { data, error } = await hotelsService.setFavorite(
      hotel,
      targetType,
      targetToken,
    );

    if (error) {
      setState({ isLoadingFavorite: false });

      showSnackMessage(error.description, "error");

      return;
    }

    const updatedHotels = state.hotels.map((item) => {
      if (item.hotelToken === hotel.hotelToken) {
        item.favorites.push({
          type: targetType,
          token: data!.favouriteToken,
        });
      }

      return item;
    });

    setState({
      hotels: updatedHotels,
      isLoadingFavorite: false,
      isFavoriteDialogOpen: false,
      selectedHotelToFavourite: null,
    });

    showSnackMessage(
      "Hotel adicionado aos favoritos com sucesso.",
      ALERT_TYPES.SUCCESS,
    );

    window.scrollTo(0, 0);
  };

  const removeHotelFavorite = async (
    hotel: {
      favorites: HotelFavorite[];
      hotelToken: string;
    },
    favoriteToken: string,
  ) => {
    setState({ isLoadingFavorite: true });

    const { error } = await hotelsService.removeFromFavorites(favoriteToken);

    if (error) {
      setState({ isLoadingFavorite: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const updatedHotelFavorites = hotel.favorites.filter(
      (favorite) => favorite.token !== favoriteToken,
    );

    const updatedHotels = state.hotels.map((item) => {
      if (item.hotelToken === hotel.hotelToken) {
        item.favorites = updatedHotelFavorites;
      }

      return item;
    });

    setState({
      isLoadingFavorite: false,
      hotels: updatedHotels,
      isFavoriteDialogOpen: false,
      selectedHotelToFavourite: null,
    });

    showSnackMessage(
      "Hotel removido dos favoritos com sucesso.",
      ALERT_TYPES.SUCCESS,
    );
  };

  const openFavoriteDialog = (hotel: HospitalityHotel) => {
    setState({
      isFavoriteDialogOpen: true,
      selectedHotelToFavourite: hotel,
    });
  };

  const handleChangeAmenitiesFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedAmenities = state.amenities.map((amenity) => {
      if (amenity.value === event.target.id) {
        return {
          ...amenity,
          checked,
        };
      }

      return amenity;
    });

    setState({
      amenities: updatedAmenities,
    });
  };

  const handleChangeStarsFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedStars = state.stars.map((star) => {
      if (star.value === parseInt(event.target.id, 10)) {
        return {
          ...star,
          checked,
        };
      }

      return star;
    });

    setState({
      stars: updatedStars,
    });
  };

  const handleChangePriceRange = (priceRange: [number, number]) => {
    setState({
      selectedPriceRange: priceRange,
    });
  };

  const handleChangeDistanceRange = (distanceRange: [number, number]) => {
    setState({
      selectedDistanceRange: distanceRange,
    });
  };

  const handleChangePolicyFilter = (_: any, checked: boolean) => {
    setState({
      onlyInPolicy: checked,
    });
  };

  const handleChangeSortOption = (sortOption: "PRICE" | "DISTANCE") => {
    setState({
      sortedBy: sortOption,
    });
  };

  const debounceNameFilter = debounce((search: string) => {
    setState({ hotelNameFilter: search });
  }, 500);

  const handleShowOutOfPolicyDialog = (hotel: HospitalityHotel) => () => {
    setState({
      // outOfPolicySelectedOffer: offer,
      outOfPolicySelectedHotel: hotel,
    });
  };

  const handleCloseOutOfPolicyDialog = () => {
    setState({
      outOfPolicySelectedOffer: null,
      outOfPolicySelectedHotel: null,
    });
  };

  const handleOpenEditDrawer = () => {
    setState({ isEditing: true });
  };

  const handleCloseEditDrawer = () => {
    setState({ isEditing: false });
  };

  const handleToggleMapVisibility = () => {
    setState({ isMapVisible: !state.isMapVisible });
  };

  const handleApplyFilters = (filterState: any) => () => {
    setState({
      onlyInPolicy: filterState.onlyInPolicy,
      selectedPriceRange: filterState.selectedPriceRange,
      selectedDistanceRange: filterState.selectedDistanceRange,
      amenities: filterState.amenities,
      stars: filterState.stars,
      isFiltersDrawerVisible: false,
    });
  };

  const handleOpenFiltersDrawer = () => {
    setState({ isFiltersDrawerVisible: true });
  };

  const handleCloseFiltersDrawer = () => {
    setState({ isFiltersDrawerVisible: false });
  };

  const togglePolicyInfoVisibility = () => {
    const prevVisibility = state.isPolicyInfoVisible;

    setState({
      isPolicyInfoVisible: !prevVisibility,
    });
  };

  const visibleHotels = useMemo(() => {
    setState({ filtering: true });

    const filteredHotels = hotelSearchHelper.getVisibleHotels(
      hotels,
      selectedDistanceRange,
      selectedPriceRange,
      stars,
      amenities,
      onlyInPolicy,
      hotelNameFilter,
      sortedBy,
    );

    setState({ filtering: false });

    return filteredHotels;
  }, [
    amenities,
    hotelNameFilter,
    hotels,
    onlyInPolicy,
    selectedDistanceRange,
    selectedPriceRange,
    stars,
    sortedBy,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setState({
        isFakeLoading: false,
      });
    }, 3000);
  }, []);

  return (
    <HotelsContext.Provider
      value={{
        ...state,
        closeFavoriteDialog,
        debounceNameFilter,
        handleApplyFilters,
        handleChangeAmenitiesFilter,
        handleChangeDistanceRange,
        handleChangePolicyFilter,
        handleChangePriceRange,
        handleChangeSortOption,
        handleChangeStarsFilter,
        handleCloseEditDrawer,
        handleCloseFiltersDrawer,
        handleCloseOutOfPolicyDialog,
        handleOpenEditDrawer,
        handleOpenFiltersDrawer,
        handleShowOutOfPolicyDialog,
        handleToggleMapVisibility,
        loadHotelInfos,
        openFavoriteDialog,
        removeHotelFavorite,
        setHotelFavorite,
        togglePolicyInfoVisibility,
        visibleHotels,
      }}
    >
      {children}
    </HotelsContext.Provider>
  );
};

export const useHotels = () => useContext(HotelsContext);
