import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    buttons: {
      button: {
        icon: ({ isChecked }: { isChecked: boolean }) =>
          css({
            color: isChecked ? theme.colors.pink[500] : theme.colors.gray[500],
            paddingTop: "4px",
          }),
        root: css({
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          fontFamily: theme.fonts.inter,
          gap: "16px",
          ":disabled": {
            opacity: 0.5,
          },
          ":not(:disabled)": {
            cursor: "pointer",
          },
        }),
        text: {
          bottom: css({
            color: theme.colors.gray[300],
            fontSize: "0.875rem",
            textAlign: "left",
          }),
          root: css({
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }),
          top: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
        },
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      paddingBottom: "24px",
    }),
    top: {
      message: css({
        color: theme.colors.gray[300],
        fontSize: "1rem",
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
};
