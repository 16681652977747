import React, { SyntheticEvent } from "react";
import { Controller, get } from "react-hook-form";

import cx from "classnames";
import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

import { compose } from "@helpers";

import { useFormError } from "@components/shared/form";
import { useFormContext } from "@components/shared/form/Form";

type FormFieldProps<ValueType> = {
  additionalInformation?: React.ReactNode;
  disabled?: boolean;
  label?: React.ReactNode;
  name: string;
  onBlur?: () => void;
  onChange?: (value: ValueType) => void;
  required?: boolean;
  requiredLabel?: string;
} & (
  | {
      as?: React.ReactElement;
      render: (args: FormFieldRenderProps<ValueType>) => React.ReactElement;
    }
  | {
      as: React.ReactElement;
      render?: (args: FormFieldRenderProps<ValueType>) => React.ReactElement;
    }
);

interface FormFieldRenderProps<ValueType> {
  onChange: (event: SyntheticEvent) => void;
  onBlur: (event: SyntheticEvent) => void;
  value: ValueType;
}

const styles = {
  spacing: css({
    width: "100%"
  }),
  label: css({
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "0.5rem"
  }),
  additionalText: css({
    fontSize: "0.9rem",
    margin: "0.5rem 0 0.5rem 1rem",
    color: defaultTheme.subTextColor
  }),
  additionalTextWithError: css({
    color: defaultTheme.errorTextColor
  })
};

export function FormField<ValueType = string>(
  props: FormFieldProps<ValueType>
) {
  const {
    as,
    render,
    name,
    label,
    onChange: parentOnChange,
    onBlur: parentOnBlur,
    disabled,
    required,
    requiredLabel,
    additionalInformation,
    ...rest
  } = props;

  const { control } = useFormContext();

  const error = useFormError(name);

  const hasError = typeof error === "object";
  const additionalText = hasError
    ? error.message
    : required
    ? requiredLabel
    : additionalInformation;

  const handleRender = ({
    onChange,
    onBlur,
    value
  }: FormFieldRenderProps<ValueType>) => {
    const props = {
      name,
      onChange: compose(onChange, parentOnChange),
      onBlur: compose(onBlur, parentOnBlur),
      value,
      hasError,
      disabled,
      required,
      ...rest
    };

    if (render) {
      return render(props);
    }

    if (as) {
      return React.cloneElement(as, props);
    }

    return null;
  };

  return (
    <fieldset className={styles.spacing}>
      {label ? <FormLabel>{label}</FormLabel> : null}

      <Controller name={name} render={handleRender} control={control} />

      {additionalText ? (
        <FormFieldAdditionalText hasError={hasError}>
          {additionalText}
        </FormFieldAdditionalText>
      ) : null}
    </fieldset>
  );
}

export function FormLabel({ children }) {
  return <label className={styles.label}>{children}</label>;
}

interface IFormFieldAdditionalTextProps
  extends React.HTMLProps<HTMLParagraphElement> {
  children: string | undefined;
  hasError: boolean;
}

export function FormFieldAdditionalText(props: IFormFieldAdditionalTextProps) {
  const { children, hasError, ...rest } = props;

  return (
    <p
      className={cx({
        [styles.additionalText]: true,
        [styles.additionalTextWithError]: hasError
      })}
      {...rest}
    >
      {children}
    </p>
  );
}
