import React from "react";

import { Link } from "@reach/router";
import { css } from "@styled-system/css";
import cx from "classnames";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  linkContainer: css({
    py: "medium",
    mr: ["medium", 28],
    maxHeight: 48,
    ["& .link"]: {
      fontSize: [0, 1],
      color: defaultTheme.subTextColor,
      transition: "all 0.3s ease",
      textDecoration: "none",
      cursor: "pointer",
      position: "relative",
      [":hover"]: {
        color: "#FF385C"
      }
    },
    ["& .link.active"]: {
      color: "text",
      fontWeight: "bold",
      ["::after"]: {
        content: "''",
        position: "absolute",
        height: "2px",
        bottom: "-15px",
        left: "0px",
        backgroundColor: "#FF385C",
        width: "100%"
      }
    }
  })
};

const StyledLink = props => {
  const getProps = inprops => {
    const isActive = props.activeOnPartial
      ? inprops.isCurrent || inprops.isPartiallyCurrent
      : inprops.isCurrent;

    return {
      className: cx("link", isActive ? "active" : "")
    };
  };

  return (
    <div css={styles.linkContainer}>
      <Link {...props} getProps={getProps} />
    </div>
  );
};

StyledLink.defaultProps = {
  activeOnPartial: false
};

export { StyledLink };
