import * as expensePolicyApi from "@apis/expense-policy.api";
import * as autosuggestApi from "@apis/autosuggest.api";
import {
  ExpensePolicyListItem,
  ExpensePolicy
} from "@models/expense-policy.model";
import { CustomError } from "~/types";
import { ERROR } from "@constants";
import { AirportAutosuggest } from "@models/autosuggest.model";
import { ExpensePolicyTarget, ExpensePolicyForm } from "./ExpensePolicy.types";
import {
  CreateExpensePolicyResponse,
  EditExpensePolicyResponse
} from "@dtos/expense-policy.dto";
import isEmpty from "lodash/isEmpty";
import * as expensePolicyHelper from "@helpers/expense-policy.helper";

export async function getExpensePoliciesList(): Promise<{
  data?: ExpensePolicyListItem[];
  error?: CustomError;
}> {
  const result: { data?: ExpensePolicyListItem[]; error?: CustomError } = {};

  try {
    result.data = await expensePolicyApi.getExpensePoliciesList();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_EXPENSE_POLICIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_EXPENSE_POLICIES_ERROR;
    }
  }

  return result;
}

export async function deleteExpensePolicy(
  policyToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensePolicyApi.deleteExpensePolicy(policyToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DELETE_EXPENSE_POLICY_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DELETE_EXPENSE_POLICY_ERROR;
    }
  }

  return result;
}

export async function getExpensePolicyInfo(
  policyToken: string
): Promise<{ data?: ExpensePolicy; error?: CustomError }> {
  const result: { data?: ExpensePolicy; error?: CustomError } = {};

  try {
    result.data = await expensePolicyApi.getExpensePolicyInfo(policyToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_POLICY_INFO_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_POLICY_INFO_ERROR;
    }
  }

  return result;
}

export async function getAiportAutosuggest(
  search: string
): Promise<{ data?: AirportAutosuggest[]; error?: CustomError }> {
  const result: { data?: AirportAutosuggest[]; error?: CustomError } = {};

  try {
    result.data = await autosuggestApi.getAirports(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_AUTOSUGGEST_ERROR;
    }
  }

  return result;
}

export async function getExpensePolicyTargetSuggestions(
  search: string
): Promise<{ data?: ExpensePolicyTarget[]; error?: CustomError }> {
  const result: { data?: ExpensePolicyTarget[]; error?: CustomError } = {};

  try {
    const targets = await expensePolicyApi.getExpensePoliciesTargetSuggestions(
      search
    );
    result.data = !isEmpty(targets) ? targets : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_TARGET_SUGGESTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_TARGET_SUGGESTIONS_ERROR;
    }
  }

  return result;
}

export async function createExpensePolicy(
  formData: ExpensePolicyForm
): Promise<{ data?: CreateExpensePolicyResponse; error?: CustomError }> {
  const result: {
    data?: CreateExpensePolicyResponse;
    error?: CustomError;
  } = {};

  try {
    const buildedDto = await expensePolicyHelper.buildCreateExpensePolicyRequestData(
      formData
    );
    result.data = await expensePolicyApi.createExpensePolicy(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_POLICY_CREATION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_POLICY_CREATION_ERROR;
    }
  }

  return result;
}

export async function editExpensePolicy(
  formData: ExpensePolicyForm
): Promise<{ data?: EditExpensePolicyResponse; error?: CustomError }> {
  const result: {
    data?: EditExpensePolicyResponse;
    error?: CustomError;
  } = {};

  try {
    const buildedDto = await expensePolicyHelper.buildCreateExpensePolicyRequestData(
      formData
    );
    result.data = await expensePolicyApi.editExpensePolicy(
      buildedDto,
      formData.policyToken!
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_POLICY_EDITION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_POLICY_EDITION_ERROR;
    }
  }

  return result;
}
