import { ERROR } from "@constants";

import { ApprovalsList } from "@models/approvals.model";

import { CustomError } from "~/types";

import * as approvalApi from "@apis/approval-process.api";

export async function getApprovalsList(
  page: number,
  status: string,
  search?: string
): Promise<{ data?: ApprovalsList; error?: CustomError }> {
  const result: { data?: ApprovalsList; error?: CustomError } = {};

  try {
    result.data = await approvalApi.getApprovalsList(page, status, search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_APPROVALS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_APPROVALS_LIST_ERROR;
    }
  }

  return result;
}
