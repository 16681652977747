import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Legend
} from "recharts";
import { styles, tooltipStyles } from "../../common-styles";
import { colors } from "../../../../assets/styles/theme/constants";
import { theme } from "smartrips-skin";

const CustomizedAxisXTick = props => {
  const { x, y, stroke, payload } = props;
  const [periodName, percentage] = payload.value.split(":");

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {periodName}
      </text>
      <text
        x={0}
        y={28}
        dy={8}
        textAnchor="middle"
        fill={theme.colors.success}
        className={styles.tickText}
      >
        {percentage}
      </text>
    </g>
  );
};

const CustomizedAxisYTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedLegend = props => (
  <div style={{ display: "flex", justifyContent: "center", fontSize: 14 }}>
    {props.payload.map((item, index) => {
      const isLastItem = props.payload.length === index + 1;

      return (
        <div
          key={item.value + "_" + index}
          style={{
            display: "flex",
            marginRight: isLastItem ? "none" : "2rem",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: item.color,
              marginRight: ".75rem"
            }}
          />
          <span>{item.value}</span>
        </div>
      );
    })}
  </div>
);

const TravelPoliciesChart = ({ data }) => {
  const hasSomeDataToShow = data.some(item => !!item.total);

  if (!hasSomeDataToShow) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 330
        }}
      >
        <p>Nenhum dado a ser mostrado</p>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={330}>
      <ComposedChart
        data={data}
        margin={{ top: 40, right: 30, left: 0, bottom: 20 }}
      >
        <CartesianGrid
          strokeDasharray="5 10"
          vertical={false}
          horizontal={false}
        />
        <XAxis
          interval={0}
          axisLine={true}
          dataKey="name"
          tick={<CustomizedAxisXTick />}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick />}
        />
        <Tooltip
          {...tooltipStyles}
          labelFormatter={name => {
            return name.split(":")[0];
          }}
        />
        <Legend
          verticalAlign="top"
          wrapperStyle={{ top: 0 }}
          content={<CustomizedLegend />}
        />
        <Bar
          maxBarSize={32}
          name="Dentro da Política"
          dataKey="inPolicy"
          stackId="a"
          fill={theme.colors.success}
          stroke={theme.colors.success}
          strokeWidth={2}
        />
        <Bar
          maxBarSize={32}
          name="Fora da Política"
          dataKey="outOfPolicy"
          stackId="a"
          fill={colors.punch}
          stroke={colors.punch}
          strokeWidth={2}
        />
        {/* <Bar
          maxBarSize={32}
          name="Sem Política"
          dataKey="noPolicy"
          stackId="a"
          fill={"gray"}
          fillOpacity={0.6}
          stroke={"gray"}
          strokeWidth={2}
        /> */}
        {/* <Bar
          maxBarSize={32}
          name="Total de viagens"
          dataKey="total"
          stackId="b"
          fill={"gray"}
          fillOpacity={0.6}
          stroke={"gray"}
          strokeWidth={2}
        /> */}

        {/* <Line
          name="Total de Viagens"
          dataKey="total"
          activeDot={{ r: 8 }}
          stroke={"#194EC0"}
          fill={"#194EC0"}
          strokeWidth={4}
        /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { TravelPoliciesChart };
