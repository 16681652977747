import React from "react";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { ERROR } from "~/apps/shared/constants/errors";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { PageTitle } from "../../components/page-title/page-title";
import { pageTitles } from "../../constants/page-titles";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const Error: React.FC<Props> = () => {
  const { location } = useLocation();

  const { state } = location;

  const { description, title } = state ? state : ERROR.UNEXPECTED;

  return (
    <>
      <PageTitle title={pageTitles.ERROR} />
      <div css={styles.root}>
        <ErrorPage message={description} title={title} />
      </div>
    </>
  );
};
