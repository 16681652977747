import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  button: css({
    alignItems: "center",
    display: "flex",
    gap: "12px",
    height: "fit-content",
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.75
    },
    '&[color="default"]': {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
      cursor: "pointer",
      color: theme.colors.primary,
      height: "42px"
    }
  }),
  left: {
    dates: css({
      display: "flex",
      gap: "12px",
      textTransform: "uppercase"
    }),
    image: css({
      height: "111px",
      objectFit: "cover"
    }),
    privacy: {
      icon: ecss({
        color: theme.colors.gray[4],
        height: "16px",
        width: "16px"
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px"
      })
    },
    root: css({
      display: "flex",
      gap: "32px",
      width: "100%"
    }),
    text: css({
      color: theme.colors.gray[4],
      display: "flex",
      flexDirection: "column",
      fontSize: 14,
      gap: "12px",
      width: "fit-content"
    }),
    token: css({
      alignItems: "center",
      color: theme.colors.gray[4],
      display: "flex"
    })
  },
  right: {
    menu: ecss({
      transform: "translateY(64px)"
    })
  },
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "64px",
    justifyContent: "space-between",
    padding: "0 32px",
    "@media (max-width: 768px)": {
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "16px",
      padding: "0"
    }
  })
};
