import React from "react";

import { NearbyPlace } from "~/apps/corporate/models/hotel.model";
import { toKilometers } from "~/apps/shared/utils/to-kilometers";

import { styles } from "./styles";

type PlaceRowProps = {
  place: NearbyPlace;
};

const PlaceRow: React.FC<PlaceRowProps> = ({ place }) => {
  const distance = toKilometers(place.distance);

  return (
    <li css={styles.row.root}>
      <span>{place.name}</span>
      <span>{distance}</span>
    </li>
  );
};

type PlacesListProps = {
  maxPlaces: number;
  places: NearbyPlace[];
  title: string;
};

export const PlacesList: React.FC<PlacesListProps> = ({
  maxPlaces,
  places,
  title,
}) => {
  const slicedPlaces = places.slice(0, maxPlaces);

  return (
    <div css={styles.root}>
      <span css={styles.title}>{title}</span>
      <ul css={styles.list}>
        {slicedPlaces.map((place, index) => (
          <PlaceRow key={index} place={place} />
        ))}
      </ul>
    </div>
  );
};
