import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { LoadingOverlay } from "~/apps/shared/components/loading-overlay/loading-overlay";

import { useItineraryInfo } from "../../itinerary-info.context";
import { useItineraryPendencies } from "../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../itinerary-screen.context";
import { useItineraryServices } from "../../itinerary-services.context";
import { ItineraryPriceBreakdown } from "../itinerary-price-breakdown/itinerary-price-breakdown";
import { useItineraryPendenciesGuard } from "./itinerary-pendencies.hooks";
import { PendenciesAccordions } from "./pendencies-accordions/pendencies-accordions";
import { PendenciesProceedButton } from "./pendencies-proceed-button/pendencies-proceed-button";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const ItineraryPendencies: React.FC<Props> = () => {
  const { error } = useItineraryPendenciesGuard();

  const { fetchItineraryInfo } = useItineraryInfo();
  const { isLoading } = useItineraryPendencies();
  const { isProceedingFromPendencies } = useItineraryScreen();
  const { fetchItineraryServices } = useItineraryServices();

  useEffect(() => {
    void fetchItineraryInfo();
    void fetchItineraryServices();
  }, [fetchItineraryInfo, fetchItineraryServices]);

  if (error) {
    return <ErrorPage {...error} />;
  }

  if (isLoading) {
    return <LoadingOverlay message="Verificando pendências..." open />;
  }

  if (isProceedingFromPendencies) {
    return (
      <LoadingOverlay
        message="Não há pendências. Redirecionando para a finalização..."
        open
      />
    );
  }

  return (
    <>
      <div css={styles.root}>
        <div css={styles.left.root}>
          <PendenciesAccordions />
        </div>
        <div css={styles.right.root}>
          <ItineraryPriceBreakdown />
          <PendenciesProceedButton />
        </div>
      </div>
    </>
  );
};
