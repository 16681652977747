import React, { useMemo } from "react";

import { useLocation } from "~/apps/shared/hooks/use-location";

import { useItineraryContainer } from "../itinerary.container";
import { ItineraryHeaderBreadcrumbs } from "./itinerary-header-breadcrumbs/itinerary-header-breadcrumbs";
import { ItineraryHeaderCopyTravelTokenButton } from "./itinerary-header-copy-travel-token-button/itinerary-header-copy-travel-token-button";
import { ItineraryHeaderDeleteTravelButton } from "./itinerary-header-delete-travel-button/itinerary-header-delete-travel-button";
import { ItineraryHeaderDownloadInvoicesButton } from "./itinerary-header-download-invoices-button/itinerary-header-download-invoices-button";
import { ItineraryHeaderSendVoucherEmailButton } from "./itinerary-header-send-voucher-email-button/itinerary-header-send-voucher-email-button";
import { ItineraryHeaderShareTravelButton } from "./itinerary-header-share-travel-button/itinerary-header-share-travel-button";
import { useItineraryHeaderGuard } from "./itinerary-header.hooks";
import { styles } from "./styles";

export const ItineraryHeader: React.FC = () => {
  const { permissions } = useItineraryHeaderGuard();

  const { infoModel, servicesModel } = useItineraryContainer();

  const {
    location: { pathname },
  } = useLocation();

  const isTravelPlan = useMemo(() => pathname.includes("/travel-plan"), [
    pathname,
  ]);

  const showDeleteTravelButton = useMemo(() => {
    if (!infoModel) {
      return false;
    }

    if (!isTravelPlan) {
      return false;
    }

    if (!permissions.canDeleteTravel) {
      return false;
    }

    return infoModel.isDeletable();
  }, [infoModel, isTravelPlan, permissions.canDeleteTravel]);

  const showDownloadInvoicesButton = useMemo(() => {
    if (!servicesModel) {
      return false;
    }

    if (!permissions.canDownloadInvoices) {
      return false;
    }

    const hasAnyCanceledOffer = servicesModel.hasAnyCanceledOffer();
    const hasAnyEmittedOffer = servicesModel.hasAnyEmittedOffer();

    return hasAnyCanceledOffer || hasAnyEmittedOffer;
  }, [permissions.canDownloadInvoices, servicesModel]);

  const showSendVoucherEmailButton = useMemo(() => {
    if (!infoModel || !servicesModel) {
      return false;
    }

    if (!isTravelPlan) {
      return false;
    }

    if (!permissions.canSendVoucherEmail) {
      return false;
    }

    const doesTravelStartAfterSendVoucherEmailReferenceDate = infoModel.doesTravelStartAfterSendVoucherEmailReferenceDate();
    const hasAnyEmittedFlightOffer = servicesModel.hasAnyEmittedFlightOffer();

    return (
      doesTravelStartAfterSendVoucherEmailReferenceDate &&
      hasAnyEmittedFlightOffer
    );
  }, [infoModel, isTravelPlan, permissions.canSendVoucherEmail, servicesModel]);

  const showShareTravelButton = useMemo(() => {
    if (!isTravelPlan) {
      return false;
    }

    if (!permissions.canShareTravel) {
      return false;
    }

    return true;
  }, [isTravelPlan, permissions.canShareTravel]);

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <ItineraryHeaderBreadcrumbs />
      </div>
      <div css={styles.right.root}>
        {showDownloadInvoicesButton ? (
          <ItineraryHeaderDownloadInvoicesButton />
        ) : null}
        {showSendVoucherEmailButton ? (
          <ItineraryHeaderSendVoucherEmailButton />
        ) : null}
        {showShareTravelButton ? <ItineraryHeaderShareTravelButton /> : null}
        <ItineraryHeaderCopyTravelTokenButton />
        {showDeleteTravelButton ? <ItineraryHeaderDeleteTravelButton /> : null}
      </div>
    </div>
  );
};
