import {
  SearchBookerTargetsToAddResponse,
  SearchTravelBookingTargetsResponse,
} from "../dtos/booking-target.dto";
import {
  BookingTarget,
  BookingTargetToAdd,
} from "../models/booking-target.model";

export const parseBookingTargets = (
  data: SearchTravelBookingTargetsResponse,
) => {
  const bookingTargets = data.map((item) => {
    const bookingTarget: BookingTarget = {
      allowed: item.allowed,
      email: item.email,
      fullName: item.full_name,
      userToken: item.user_token,
    };

    return bookingTarget;
  });

  return bookingTargets;
};

export const parseBookingTargetsToAdd = (
  data: SearchBookerTargetsToAddResponse,
) => {
  const bookingTargetsToAdd = data.map((item) => {
    const bookingTarget: BookingTargetToAdd = {
      alreadyTarget: item.already_target,
      email: item.email,
      fullName: item.full_name,
      userToken: item.user_token,
    };

    return bookingTarget;
  });

  return bookingTargetsToAdd;
};
