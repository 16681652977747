import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  rules: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "24px",
    }),
    rule: {
      label: css({}),
      root: css({}),
    },
  },
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.5rem",
    fontWeight: 600,
  }),
};
