import { isValidPassword } from "~/apps/shared/helpers/password.helper";
import * as yup from "yup";

export const passwordUpdateFormSchema = yup.object().shape({
  currentPassword: yup.string().required("Preencha a senha atual."),
  newPassword: yup
    .string()
    .required("Preencha a nova senha.")
    .test("", "Senha deve conter pelo menos 8 caracteres.", (newPassword) => {
      if (!newPassword || newPassword.length < 8) {
        return false;
      }

      return true;
    })
    .test("", "A senha não atende aos padrões.", (newPassword) => {
      if (!newPassword || !isValidPassword(newPassword)) {
        return false;
      }

      return true;
    }),
  newPasswordConfirmation: yup
    .string()
    .required("Preencha a confirmação da senha.")
    .test("", "As senhas não conferem.", function (newPasswordConfirmation) {
      return this.parent.newPassword === newPasswordConfirmation;
    }),
});

export type PasswordUpdateFormSchema = yup.InferType<
  typeof passwordUpdateFormSchema
>;
