import GoogleMap, { Coords } from "google-map-react";
import React from "react";

import { ZoomInMarker } from "~/apps/shared/components/location-marker/location-marker";

const commercialPointsOfInterestOptions = {
  styles: [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          visibility: "#on",
        },
      ],
    },
  ],
};

type Props = React.HTMLAttributes<HTMLDivElement> & Coords;

export const HotelResultMap: React.FC<Props> = ({ lat, lng, ...props }) => {
  return (
    <div {...props}>
      <GoogleMap
        center={{
          lat: lat,
          lng: lng,
        }}
        defaultZoom={13}
        options={{
          gestureHandling: "greedy",
          panControl: true,
          streetViewControl: true,
          ...commercialPointsOfInterestOptions,
        }}
      >
        <ZoomInMarker lat={lat} lng={lng} type="search-point" />
      </GoogleMap>
    </div>
  );
};
