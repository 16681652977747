import React from "react";
import moment from "moment";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { darken } from "@material-ui/core";
import { defaultTheme } from "@assets/styles/theme";

import { Box, Flex, Text } from "@toolkit";

import { BillingProfileListItem } from "@models/billing-profile.model";

import { Icon } from "~/apps/shared/components/icon/icon";

const styles = {
  root: css({
    flexDirection: "column",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.5rem",
  }),
  header: css({
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: theme.colors.background.gray,
    borderRadius: "0.5rem 0.5rem 0 0",
  }),
  content: css({
    flexDirection: "column",
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    p: "1rem",
    gap: "0.5rem",
  }),
  subTitle: css({
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.colors.gray[500],
  }),
  descriptionTitle: css({
    fontSize: "0.875rem",
    fontWeight: 600,
    color: theme.colors.gray[500],
  }),
  description: css({
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.colors.gray[300],
  }),
  icons: {
    trash: css({
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        color: defaultTheme.errorTextColor,
        transition: "200ms",
      },
    }),
    pencil: css({
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        color: darken(theme.colors.gray[300], 0.5),
        transition: "200ms",
      },
    }),
  },
};

export type BillingProfileCardProps = {
  billingProfile: BillingProfileListItem;
  handleEdit: (profile: BillingProfileListItem) => void;
  handleDelete: (profle: BillingProfileListItem) => void;
};

export const BillingProfileCard: React.FC<BillingProfileCardProps> = ({
  billingProfile,
  handleEdit,
  handleDelete,
}) => {
  return (
    <Flex css={styles.root}>
      <Flex css={styles.header}>
        <Text fontSize="1.25rem" fontWeight={600}>
          {billingProfile.name}
        </Text>
        <Flex style={{ gap: "1rem" }}>
          <Icon
            use="pencil"
            css={styles.icons.pencil}
            size={24}
            onClick={() => handleEdit(billingProfile)}
          />
          <Icon
            use="outline-trash"
            css={styles.icons.trash}
            size={24}
            onClick={() => handleDelete(billingProfile)}
          />
        </Flex>
      </Flex>

      <Flex flexDirection="column" px="1rem">
        <Flex css={styles.content}>
          <Text css={styles.subTitle}>CNPJ:</Text>
          <Text css={styles.description}>{billingProfile.cnpj}</Text>
        </Flex>

        <Flex css={styles.content}>
          <Text css={styles.subTitle}>Endereço:</Text>
          <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>Rua: </Text>
              {billingProfile.street}, {billingProfile.number}
              {!!billingProfile.complement && ` - ${billingProfile.complement}`}
            </Text>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>Bairro: </Text>
              {billingProfile.neighborhood}
            </Text>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>CEP: </Text>
              {billingProfile.zipCode}
            </Text>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>Cidade: </Text>
              {billingProfile.city}, {billingProfile.state}
            </Text>
          </Flex>
        </Flex>

        <Flex css={styles.content}>
          <Text css={styles.subTitle}>Contato:</Text>
          <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>Email: </Text>
              {billingProfile.email}
            </Text>
            <Text css={styles.description}>
              <Text css={styles.descriptionTitle}>Telefone: </Text>
              {billingProfile.phone}
            </Text>
          </Flex>
        </Flex>

        <Flex css={styles.content} borderBottom="none !important">
          <Text css={styles.subTitle}>Data de criação:</Text>

          <Text css={styles.description}>
            {moment(billingProfile.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
