import React from "react";

import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";

import { styles } from "./styles";

type Props = {
  travelToken: string;
};

export const TripCardTags: React.FC<Props> = ({ travelToken }) => {
  const { travelsTags } = useTags();

  if (!travelsTags) {
    return null;
  }

  if (!(travelToken in travelsTags)) {
    return null;
  }

  const travelTags = travelsTags[travelToken];

  if (!travelTags || travelTags.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      {travelTags.map((tag) => (
        <div css={styles.tag.root} key={tag.travelTagToken}>
          {tag.tagName}
        </div>
      ))}
    </div>
  );
};
