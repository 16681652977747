import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    additionals: {
      additionals: {
        additional: {
          root: css({
            alignItems: "center",
            color: theme.colors.gray[700],
            display: "flex",
            fontSize: "0.875rem",
            gap: "8px",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
        type: css({
          color: theme.colors.gray[500],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "8px 24px",
      }),
    },
    location: {
      rental: {
        capitalize: css({
          textTransform: "capitalize",
        }),
        icon: css({
          color: theme.colors.gray[300],
          flexShrink: 0,
          transform: "translateY(1px)",
        }),
        root: css({
          display: "flex",
          gap: "8px",
        }),
        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "8px 24px",
      }),
    },
    divisor: css({
      backgroundColor: theme.colors.gray[100],
      border: "none",
      height: "1px",
      margin: "0 auto",
      width: "calc(100% - 8px * 2)",
    }),
    model: {
      left: {
        image: css({
          height: "calc(100% - 24px)",
          objectFit: "contain",
          "@media (max-width: 640px)": {
            width: "100%",
          },
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: "40%",
          "@media (max-width: 640px)": {
            justifyContent: "unset",
            width: "100%",
          },
        }),
      },
      right: {
        bottom: {
          column: {
            feature: {
              gray: css({
                alignItems: "center",
                color: theme.colors.gray[700],
                display: "flex",
                fontSize: "0.875rem",
                gap: "8px",
              }),
              green: css({
                alignItems: "center",
                color: theme.colors.green[500],
                display: "flex",
                fontSize: "0.875rem",
                gap: "8px",
              }),
            },
            root: css({
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "8px 0",
            }),
          },
          features: css({
            display: "flex",
            flexWrap: "wrap",
            gap: "8px 24px",
          }),
          root: css({
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          justifyContent: "space-between",
          padding: "0 16px",
          width: "60%",
          "@media (max-width: 640px)": {
            padding: "24px 0 0 0",
            width: "100%",
          },
        }),
        top: {
          description: css({
            color: theme.colors.gray[300],
            fontSize: "0.875rem",
          }),
          name: css({
            fontSize: "1.125rem",
            fontWeight: 700,
          }),
          root: css({
            display: "flex",
            borderRadius: "6px",
            flexDirection: "column",
            gap: "4px",
          }),
        },
      },
      root: css({
        display: "flex",
        "@media (max-width: 640px)": {
          flexDirection: "column",
          padding: "unset",
        },
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      height: "100%",
      margin: "24px 0",
      padding: "0 24px",
    }),
  },
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "24px",
      position: "sticky",
      width: "100%",
    }),
    policy: css({
      color: theme.colors.pink[500],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    price: css({
      fontSize: "1.125rem",
      fontWeight: 700,
    }),
    warning: css({
      color: theme.colors.gray[500],
      fontSize: "0.875rem",
    }),
  },
};
