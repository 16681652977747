import React from "react";

import { css } from "@styled-system/css";
import {
  Dialog,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

export const styles = {
  message: css({
    color: theme.colors.gray[300],
    fontSize: "1rem",
  }),
};

type ExcludeSSOConfigDialogProps = {
  isOpen: boolean;
  handleClose(): void;
  isLoading: boolean;
  handleDelete(): void;
};

export const ExcludeSSOConfigDialog: React.FC<ExcludeSSOConfigDialogProps> = ({
  isOpen,
  handleClose,
  isLoading,
  handleDelete,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableRestoreFocus
      maxWidth="sm"
    >
      <DialogHeader
        color={theme.colors.pink[700]}
        disabled={isLoading}
        icon="trash"
        onClose={handleClose}
      />
      <DialogContent>
        <DialogTitle>
          Tem certeza de que deseja excluir a configuração ?
        </DialogTitle>
        <span css={styles.message}>
          Isso resultará na utilização exclusiva do método de autenticação
          padrão.
        </span>
      </DialogContent>
      <DialogFooter>
        <Button disabled={isLoading} fill="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button disabled={isLoading} onClick={handleDelete} variant="pink">
          Excluir
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
