import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { Sheet } from "~/apps/shared/components/sheet/sheet";

import { Button } from "@toolkit/v2";

import { useHotels } from "../../hotels.context";
import { AmenitiesFilter } from "./amenities-filter/amenities-filter";
import { DistanceFilter } from "./distance-filter/distance-filter";
import { PolicyFilter } from "./policy-filter/policy-filter";
import { PriceFilter } from "./price-filter/price-filter";
import { StarsFilter } from "./stars-filter/stars-filter";
import { styles } from "./styles";

type Props = {
  isMobile?: boolean;
};

export const ContentFilters: React.FC<Props> = ({ isMobile }) => {
  const {
    amenities,
    distanceRange,
    handleChangeAmenitiesFilter,
    handleChangeDistanceRange,
    handleChangePolicyFilter,
    handleChangePriceRange,
    handleChangeStarsFilter,
    isLoading,
    onlyInPolicy,
    priceRange,
    selectedDistanceRange,
    selectedPriceRange,
    stars,
  } = useHotels();

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleCloseSheet = useCallback(() => {
    setIsSheetOpen(false);
  }, []);

  const handleOpenSheet = useCallback(() => {
    setIsSheetOpen(true);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);

      return;
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        <Button
          css={styles.sheet.trigger.root}
          fill="outlined"
          onClick={handleOpenSheet}
        >
          <Icon
            css={styles.sheet.trigger.icon}
            size={20}
            use="adjustments-horizontal"
          />
          Filtrar resultados
        </Button>
        <Sheet
          anchor="bottom"
          disableSwipeToOpen
          onClose={handleCloseSheet}
          onOpen={handleOpenSheet}
          open={isSheetOpen}
        >
          <div css={styles.sheet.root}>
            <PermissionProtectedRender permissions={["travels.policies"]}>
              <PolicyFilter
                handleChangePolicyFilter={handleChangePolicyFilter}
                onlyInPolicy={onlyInPolicy}
              />
            </PermissionProtectedRender>
            <PriceFilter
              disabled={isLoading}
              handleChangePriceRange={handleChangePriceRange}
              priceRange={priceRange}
              selectedRange={selectedPriceRange}
            />
            <DistanceFilter
              disabled={isLoading}
              distanceRange={distanceRange}
              handleChangeDistanceRange={handleChangeDistanceRange}
              selectedRange={selectedDistanceRange}
            />
            <AmenitiesFilter
              amenities={amenities}
              handleChangeAmenitiesFilter={handleChangeAmenitiesFilter}
            />
            <StarsFilter
              handleChangeStarsFilter={handleChangeStarsFilter}
              stars={stars}
            />
          </div>
        </Sheet>
      </>
    );
  }

  return (
    <div css={styles.root}>
      <PermissionProtectedRender permissions={["travels.policies"]}>
        <PolicyFilter
          handleChangePolicyFilter={handleChangePolicyFilter}
          onlyInPolicy={onlyInPolicy}
        />
      </PermissionProtectedRender>
      <PriceFilter
        disabled={isLoading}
        handleChangePriceRange={handleChangePriceRange}
        priceRange={priceRange}
        selectedRange={selectedPriceRange}
      />
      <DistanceFilter
        disabled={isLoading}
        distanceRange={distanceRange}
        handleChangeDistanceRange={handleChangeDistanceRange}
        selectedRange={selectedDistanceRange}
      />
      <AmenitiesFilter
        amenities={amenities}
        handleChangeAmenitiesFilter={handleChangeAmenitiesFilter}
      />
      <StarsFilter
        handleChangeStarsFilter={handleChangeStarsFilter}
        stars={stars}
      />
    </div>
  );
};
