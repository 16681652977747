import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

import {
  ItineraryHotelSegment,
  ItineraryHotelSegmentSkeleton,
} from "~/apps/corporate/components/itinerary-hotel-segment/itinerary-hotel-segment";
import { HospitalityHotel } from "~/apps/corporate/models/hotel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";

import { CompanyDealBadge } from "../../../../../../../components/company-deal-badge/company-deal-badge";
import { NegotiatedFareBadge } from "../../../../../../../components/negotiated-fare-badge/negotiated-fare-badge";
import { useHotels } from "../../../hotels.context";
import { styles } from "./styles";

type Props = {
  handleShowOutOfPolicyDialog: (hotel: HospitalityHotel) => () => void;
  hotel: HospitalityHotel;
  offersLink: string;
  totalGuests: number;
};

export const HotelCard = memo<Props>(
  ({ handleShowOutOfPolicyDialog, hotel, offersLink, totalGuests }) => {
    const { openFavoriteDialog } = useHotels();

    const showCompanyNegotiatedBadge = hotel.isCompanyNegotiated;
    const showNegotiatedBadge = hotel.isNegotiated;
    const showOutOfPolicyBadge = hotel.outOfPolicy;

    return (
      <div css={styles.root({ disabled: false })}>
        <ItineraryHotelSegment
          amenities={hotel.popularAmenities}
          hotel={hotel}
          openFavoriteDialog={openFavoriteDialog}
          showFavoriteButton
        />
        <div css={styles.right.root}>
          {showCompanyNegotiatedBadge ||
          showNegotiatedBadge ||
          showOutOfPolicyBadge ? (
            <div css={styles.right.top.root}>
              {showCompanyNegotiatedBadge ? (
                <CompanyDealBadge css={styles.right.top.badge} />
              ) : showNegotiatedBadge ? (
                <NegotiatedFareBadge css={styles.right.top.badge} />
              ) : null}
              {showOutOfPolicyBadge ? (
                <OutOfPolicyBadge
                  css={styles.right.top.badge}
                  onClick={() => {
                    handleShowOutOfPolicyDialog(hotel)();
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <div css={styles.right.bottom.root}>
            <div css={styles.right.bottom.guests.root}>
              <Icon size={14} use={totalGuests > 1 ? "people" : "person"} />
              <span>
                {totalGuests} Hóspede{totalGuests === 1 ? "" : "s"}
              </span>
            </div>
            <div css={styles.right.bottom.price.root}>
              <span css={styles.right.bottom.price.label}>
                Diárias a partir de
              </span>
              <span css={styles.right.bottom.price.value}>
                {hotel.minPrice.displayValue}
              </span>
            </div>
            {hotel.points ? (
              <PermissionProtectedRender permissions={["travels.gamification"]}>
                <div css={styles.right.bottom.guests.root}>
                  <Icon size={16} use="star-outline" />
                  <span>
                    Até {hotel.points} ponto{hotel.points === 1 ? "" : "s"}
                  </span>
                </div>
              </PermissionProtectedRender>
            ) : null}
            <a
              css={styles.right.button({ disabled: false })}
              href={offersLink}
              rel="noreferrer"
              target="_blank"
            >
              Ver quartos
            </a>
          </div>
        </div>
      </div>
    );
  },
);

HotelCard.displayName = "HotelCard";

export const HotelCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root({ disabled: true })}>
      <ItineraryHotelSegmentSkeleton />
      <div css={styles.right.root}>
        <div css={styles.right.bottom.price.root}>
          <Skeleton height="14px" width="144px" />
          <Skeleton height="24px" width="128px" />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.guests.root}>
            <Skeleton height="16px" width="96px" />
          </div>
        </div>
        <div css={styles.right.button({ disabled: true })}>Ver quartos</div>
      </div>
    </div>
  );
};
