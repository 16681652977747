import { Icons } from "~/apps/shared/components/icon/icon";
import {
  APPROVAL_STATUS,
  BOOKING_FAILED_REASON,
  RESERVE_STATUS,
} from "~/apps/shared/constants";
import {
  BookingStatus,
  CancelingStatus,
  CarCategory,
  ChangeStatus,
  CurrencyCode,
  ServiceType,
} from "~/apps/shared/constants/enums";
import { ApprovalStatus, CancelType } from "~/apps/shared/types";
import { differenceInDays } from "~/apps/shared/utils/difference-in-days";
import moment, { Moment } from "moment";

import { CarSupplier, TransmissionType } from "../car.model";

export type ItineraryBusJourney = {
  arrival: {
    city: string;
    country: string;
    date: string;
    state: string;
    terminal: string;
    time: string;
  };
  departure: {
    city: string;
    country: string;
    date: string;
    state: string;
    terminal: string;
    time: string;
  };
  duration: string;
  index: number;
  segments: {
    arrival: {
      city: string;
      country: string;
      date: string;
      state: string;
      terminal: string;
      time: string;
    };
    busType: string;
    carrier: {
      imageUrl: string;
      name: string;
    };
    departure: {
      city: string;
      country: string;
      date: string;
      state: string;
      terminal: string;
      time: string;
    };
    duration: string;
    eletronicBoardingPass: boolean;
    index: number;
  }[];
  seat: string;
  totalStops: number;
};

export type ItineraryBusSpecialFields = {
  journeys: ItineraryBusJourney[];
};

export type ItineraryCarSpecialFields = {
  accessoryAdditionals?: {
    code: string;
    name: string;
    selected: boolean;
  }[];
  additionalDrivers?: {
    name: string;
    userToken: string;
  }[];
  carDetails: {
    class: CarCategory;
    features: {
      abs: boolean;
      air: boolean;
      airbag: boolean;
      baggages: number;
      doors: number;
      passengers: number;
      steering: string;
      transmission: TransmissionType;
    };
    model: {
      description: string;
      imageUrl: string;
      title: string;
    };
  };
  contractDetails: {
    ali: boolean;
    ldw: boolean;
    mandatoryDriverCreditCard: boolean;
    unlimitedKm: boolean;
  };
  dropOff: {
    address: string;
    date: string;
    storeName: string;
    time: string;
  };
  pickUp: {
    address: string;
    date: string;
    storeName: string;
    time: string;
  };
  protectionAdditionals?: {
    code: string;
    name: string;
    selected: boolean;
  }[];
  rentalCompany: {
    name: string;
    logoUrl: string;
  };
  serviceAdditionals?: {
    code: string;
    name: string;
    selected: boolean;
  }[];
};

export type ItineraryFlightJourney = {
  arrival: {
    airportCode: string;
    airportName: string;
    date: string;
    time: string;
  };
  departure: {
    airportCode: string;
    airportName: string;
    date: string;
    time: string;
  };
  duration: string;
  fareFamily: string;
  index: number;
  segments: {
    arrival: {
      airportCode: string;
      airportName: string;
      date: string;
      time: string;
    };
    baggage?: {
      carryOn: {
        included: boolean;
        quantity: number;
        weight: {
          unit: string;
          value: number;
        };
      };
      checked: {
        included: boolean;
        quantity: number;
        weight: {
          unit: string;
          value: number;
        };
      };
    };
    cabinClass: string;
    carrier: {
      code: string;
      imageUrl: string;
      name: string;
    };
    connection?: {
      isSameAirport: boolean;
      segmentIndex: number;
      waitingTime: string;
    };
    departure: {
      airportCode: string;
      airportName: string;
      date: string;
      time: string;
    };
    duration: string;
    flightNumber: string;
    index: number;
  }[];
  totalStops: number;
};

export type ItineraryFlightSpecialFields = {
  isInternational: boolean;
  isOneWay: boolean;
  journeys: ItineraryFlightJourney[];
  reservation: {
    reservedUntil: string;
    status: string;
  };
};

export type ItineraryHotelSpecialFields = {
  address: string;
  amenities: {
    breakfast: boolean;
    refund: boolean;
    wifi: boolean;
  };
  checkIn: string;
  checkInHour: string;
  checkOut: string;
  checkOutHour: string;
  city: string;
  hotelName: string;
  isCompanyNegotiated: boolean;
  numberOfNights: number;
  roomImageUrl: string;
  roomName: string;
  stars: number;
  totalGuests: number;
};

export interface ItineraryService<
  T =
    | ItineraryBusSpecialFields
    | ItineraryCarSpecialFields
    | ItineraryFlightSpecialFields
    | ItineraryHotelSpecialFields
> {
  approval: {
    deniedOnApproval?: number;
    status: ApprovalStatus;
  };
  booking: {
    code?: string;
    failedReason?: string;
    manualFailed?: boolean | number;
    operationalId?: string;
    status: BookingStatus;
    usedCredit?: number;
  };
  cancellation: {
    canceledAt?: string;
    canceledByUserName?: string;
    canceledByUserToken?: string;
    manualCanceled?: boolean;
    policy?: {
      description: string;
      free: boolean;
      freeUntil?: string | null;
      penalty?: {
        currency: CurrencyCode;
        total: number;
      };
    };
    status: CancelingStatus;
    type?: CancelType;
  };
  changeData: {
    changedByUserName?: string | null;
    changedByUserToken?: string;
    changeFee?: {
      currency: CurrencyCode;
      total: number;
    };
    changedPrice?: {
      currency: CurrencyCode;
      total: number;
    };
    status: ChangeStatus;
  };
  choiceJustification?: string;
  isNegotiated: boolean;
  offerToken: string;
  price: {
    currency: CurrencyCode;
    total: number;
  };
  search: {
    cheapestPrice: number | null;
    destinationCity?: string;
    destinationCountry?: string;
    endDate?: string;
    originCity: string;
    originCountry: string;
    startDate?: string;
    token: string;
  };
  ChoiceContext?: {
    bestPrice: number;
    cheapestRate?: {
      baggagePieces: number;
      class: string;
      family: string;
      fareBasis: string;
      outOfPolicy: boolean | number;
      price: number;
    };
    choiceIndex: number;
    optimalPrice: number;
    smartripsRecommends: boolean;
    resultsCount: number;
  };
  specialFields: T;
  supplier: string;
  type: ServiceType;
}

export type ItineraryBusService = ItineraryService<ItineraryBusSpecialFields>;
export type ItineraryCarService = ItineraryService<ItineraryCarSpecialFields>;
export type ItineraryFlightService = ItineraryService<ItineraryFlightSpecialFields>;
export type ItineraryHotelService = ItineraryService<ItineraryHotelSpecialFields>;

type ItineraryServiceCancelationInfo = {
  canceledAt: string | undefined;
  canceledByUserName: string | undefined;
  fee: {
    currency: CurrencyCode;
    total: number;
  } | null;
  isCanceled: boolean;
  showCanceledByMessage: string | undefined;
  type?: string;
};

export type ItineraryServiceSuggestion = {
  endDate?: moment.Moment;
  location: string;
  startDate: moment.Moment;
  suggestionType: ServiceType;
};

export abstract class ItineraryServiceModel {
  public suggestion: ItineraryServiceSuggestion | null = null;

  constructor(
    protected service: ItineraryService<
      | ItineraryBusSpecialFields
      | ItineraryCarSpecialFields
      | ItineraryFlightSpecialFields
      | ItineraryHotelSpecialFields
    >,
  ) {}

  private getApprovalStatus() {
    return this.service.approval.status;
  }

  public getBookingCode() {
    const bookingCode = this.service.booking.code;

    if (!bookingCode) {
      return null;
    }

    return bookingCode;
  }

  public getBookingFailedReason() {
    const bookingFailedReason = this.service.booking.failedReason;

    if (!bookingFailedReason) {
      return null;
    }

    return bookingFailedReason;
  }

  private getBookingStatus() {
    return this.service.booking.status;
  }

  public getCancelationInfo() {
    const cancelationInfo: ItineraryServiceCancelationInfo = {
      canceledAt: this.service.cancellation.canceledAt,
      canceledByUserName: this.service.cancellation.canceledByUserName,
      fee:
        this.service.cancellation.policy &&
        this.service.cancellation.policy.penalty
          ? this.service.cancellation.policy.penalty
          : null,
      isCanceled: this.isCanceled(),
      showCanceledByMessage: this.service.cancellation.canceledByUserName,
      type: this.service.cancellation.type,
    };

    return cancelationInfo;
  }

  private getCancelationStatus() {
    return this.service.cancellation.status;
  }

  public getChangedPrice() {
    const changedPrice = this.service.changeData.changedPrice;

    if (!changedPrice) {
      return null;
    }

    return changedPrice;
  }

  public getChangeFee() {
    const changeFee = this.service.changeData.changeFee;

    if (!changeFee) {
      return null;
    }

    return changeFee;
  }

  // * TODO: verificar cheapestPrice
  private getCheapestPrice() {
    return this.getSearch().cheapestPrice;
  }

  public getChoiceJustification() {
    const choiceJustification = this.service.choiceJustification;

    if (!choiceJustification) {
      return null;
    }

    return choiceJustification;
  }

  public getCurrency() {
    return this.service.price.currency;
  }

  public getEndDate() {
    const { endDate } = this.getPeriod();

    return endDate;
  }

  public getFreeCancelationUntil() {
    const cancelationPolicy = this.service.cancellation.policy;

    if (!cancelationPolicy) {
      return null;
    }

    return cancelationPolicy.freeUntil;
  }

  public getOfferToken() {
    return this.service.offerToken;
  }

  public getOperationalId() {
    const operationalId = this.service.booking.operationalId;

    if (!operationalId) {
      return null;
    }

    return operationalId;
  }

  public abstract getPeriod(): {
    endDate: Moment;
    startDate: Moment;
  };

  public getPrice() {
    return this.service.price.total;
  }

  public getPriceExcess() {
    const cheapestPrice = this.getCheapestPrice();
    const price = this.getPrice();

    if (!cheapestPrice || cheapestPrice === 0) {
      return null;
    }

    return price / cheapestPrice;
  }

  public getSmRecommendationContext() {
    return this.service.ChoiceContext;
  }

  public getSearch() {
    return this.service.search;
  }

  public abstract getSearchPeriod(): {
    endDate?: Moment;
    startDate: Moment;
  };

  public abstract getService(): ItineraryService<
    | ItineraryBusSpecialFields
    | ItineraryCarSpecialFields
    | ItineraryFlightSpecialFields
    | ItineraryHotelSpecialFields
  >;

  public getStartDate() {
    const { startDate } = this.getPeriod();

    return startDate;
  }

  public abstract getTotalTravelers(): number;

  public getType() {
    return this.service.type;
  }

  public getUsedCredit() {
    return this.service.booking.usedCredit;
  }

  public hasFreeCancelation() {
    const cancelationPolicy = this.service.cancellation.policy;

    if (!cancelationPolicy) {
      return false;
    }

    return cancelationPolicy.free;
  }

  public hasUsedCredits() {
    const usedCredit = this.getUsedCredit();

    if (!usedCredit) {
      return false;
    }

    return usedCredit > 0;
  }

  public isApprovalDeclined() {
    return this.getApprovalStatus() === APPROVAL_STATUS.DECLINED;
  }

  public isApprovalPending(): boolean {
    return (
      this.getApprovalStatus() === APPROVAL_STATUS.PENDING_APPROVAL ||
      this.getApprovalStatus() === APPROVAL_STATUS.PENDING_APPROVAL_2
    );
  }

  public isApprovalUnitiated() {
    return this.getApprovalStatus() === APPROVAL_STATUS.UNITIATED;
  }

  public isApproved() {
    return this.getApprovalStatus() === APPROVAL_STATUS.APPROVED;
  }

  public isBookingDropped() {
    return this.getBookingStatus() === BookingStatus.DROPPED;
  }

  public isBookingFailed() {
    return this.getBookingStatus() === BookingStatus.FAILED;
  }

  public isBookingManualFailed() {
    return (
      this.getBookingStatus() === BookingStatus.FAILED && this.isManualFailed()
    );
  }

  public isBookingProcessing() {
    return this.getBookingStatus() === BookingStatus.PENDING;
  }

  public isBookingUnitiated() {
    return this.getBookingStatus() === BookingStatus.UNITIATED;
  }

  public isBusService(): this is BusItineraryServiceModel {
    return this.service.type === ServiceType.BUS;
  }

  public isCancelable() {
    return (
      !this.isCancelationFailedOrPending() &&
      this.isEmitted() &&
      !this.isOutdated()
    );
  }

  public isCancelationFailedOrPending() {
    return (
      this.getCancelationStatus() === CancelingStatus.FAILED ||
      this.getCancelationStatus() === CancelingStatus.PENDING
    );
  }

  public isCanceled() {
    return this.getCancelationStatus() === CancelingStatus.SUCCESS;
  }

  public isCarService(): this is CarItineraryServiceModel {
    return this.service.type === ServiceType.CAR;
  }

  public isChanged() {
    return this.service.changeData.status === ChangeStatus.SUCCESS;
  }

  public isDeletable() {
    return (
      !this.isApprovalDeclined() &&
      !this.isApprovalPending() &&
      !this.isUnavailable() && // ! Ofertas indisponíveis estão na sessão de compras frustadas, por enquanto estamos impedindo a exclusão.
      this.isBookingUnitiated()
    );
  }

  public isDeniedOnApproval() {
    if (this.service.approval.deniedOnApproval === undefined) {
      return false;
    }

    return !!this.service.approval.deniedOnApproval;
  }

  public isEmitted() {
    return this.getBookingStatus() === BookingStatus.EMITTED;
  }

  public isFlightService(): this is FlightItineraryServiceModel {
    return this.service.type === ServiceType.FLIGHT;
  }

  public isHotelService(): this is HotelItineraryServiceModel {
    return this.service.type === ServiceType.HOTEL;
  }

  private isManualFailed() {
    return !!this.service.booking.manualFailed;
  }

  public isNegotiated() {
    return this.service.isNegotiated;
  }

  public isOutdated() {
    const startDate = this.getStartDate();
    const today = moment().startOf("day");

    return today.isAfter(startDate);
  }

  public isOutdatedOrUnavailable() {
    return this.isOutdated() || this.isUnavailable();
  }

  public isProcessable() {
    return (
      (this.isApprovalPending() || this.isApprovalUnitiated()) &&
      this.isBookingUnitiated() &&
      !this.isOutdated() &&
      !this.isUnavailable()
    );
  }

  public isReserved() {
    return this.isBookingProcessing() || this.isEmitted();
  }

  public isUnavailable() {
    return (
      this.isBookingFailed() &&
      this.getBookingFailedReason() ===
        BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT &&
      !this.isManualFailed()
    );
  }
}

export class BusItineraryServiceModel extends ItineraryServiceModel {
  constructor(protected service: ItineraryBusService) {
    super(service);
  }

  public getAllJourneys() {
    return this.service.specialFields.journeys;
  }

  public getAllSeats() {
    return this.service.specialFields.journeys
      .map((journey) => journey.seat)
      .filter((seat) => !!seat);
  }

  public getFirstJourney() {
    return this.service.specialFields.journeys[0];
  }

  public getInboundJourney() {
    if (this.isOneWay()) {
      return null;
    }

    return this.getLastJourney();
  }

  private getLastJourney() {
    return this.service.specialFields.journeys[
      this.service.specialFields.journeys.length - 1
    ];
  }

  public getOutboundJourney() {
    return this.getFirstJourney();
  }

  public getService(): ItineraryBusService {
    return this.service;
  }

  public getPeriod() {
    return {
      endDate: moment(this.getLastJourney().arrival.date),
      startDate: moment(this.getFirstJourney().departure.date),
    };
  }

  public getSearchPeriod() {
    if (this.isOneWay()) {
      return {
        startDate: moment(this.getFirstJourney().departure.date),
      };
    }

    return {
      endDate: moment(this.getLastJourney().departure.date),
      startDate: moment(this.getFirstJourney().departure.date),
    };
  }

  public getTotalTravelers() {
    return 1;
  }

  public isOneWay() {
    return this.service.specialFields.journeys.length === 1;
  }

  public isSameDayReturn() {
    const inboundJourney = this.getInboundJourney();

    if (!inboundJourney) {
      return false;
    }

    const outboundJourneyDepartureDate = this.getOutboundJourney().departure
      .date;
    const inboundJourneyDepartureDate = inboundJourney.arrival.date;

    return (
      moment(outboundJourneyDepartureDate).diff(
        moment(inboundJourneyDepartureDate),
        "days",
      ) === 0
    );
  }
}

export class CarItineraryServiceModel extends ItineraryServiceModel {
  constructor(protected service: ItineraryCarService) {
    super(service);
  }

  private getAllAdditionals() {
    const {
      accessoryAdditionals,
      protectionAdditionals,
      serviceAdditionals,
    } = this.service.specialFields;

    return [
      ...(accessoryAdditionals ? accessoryAdditionals : []),
      ...(protectionAdditionals ? protectionAdditionals : []),
      ...(serviceAdditionals ? serviceAdditionals : []),
    ];
  }

  public getAllSelectedAdditionals() {
    const allAdditionals = this.getAllAdditionals();

    return allAdditionals.filter((additional) => additional.selected);
  }

  public getClass() {
    return this.service.specialFields.carDetails.class;
  }

  public getContractFeatures() {
    const {
      ali,
      ldw,
      unlimitedKm,
    } = this.service.specialFields.contractDetails;

    return {
      ali,
      freeCancelation: this.hasFreeCancelation(),
      ldw,
      unlimitedKm,
    };
  }

  public getDays() {
    const { date: dropOffDate } = this.getDropOff();
    const { date: pickUpDate } = this.getPickUp();

    return differenceInDays(pickUpDate, dropOffDate);
  }

  public getDropOff() {
    return this.service.specialFields.dropOff;
  }

  public getModel() {
    return this.service.specialFields.carDetails.model;
  }

  public getModelFeatures() {
    return this.service.specialFields.carDetails.features;
  }

  public getService(): ItineraryCarService {
    return this.service;
  }

  public getPeriod() {
    return {
      endDate: moment(this.getDropOff().date),
      startDate: moment(this.getPickUp().date),
    };
  }

  public getPickUp() {
    return this.service.specialFields.pickUp;
  }

  public getRentalCompany() {
    return this.service.specialFields.rentalCompany;
  }

  public getSearchPeriod() {
    return this.getPeriod();
  }

  public getSelectedAccessoryAdditionals() {
    const accessoryAdditionals = this.service.specialFields
      .accessoryAdditionals;

    if (!accessoryAdditionals) {
      return [];
    }

    return accessoryAdditionals.filter((additional) => additional.selected);
  }

  public getSelectedProtectionAdditionals() {
    const protectionAdditionals = this.service.specialFields
      .protectionAdditionals;

    if (!protectionAdditionals) {
      return [];
    }

    return protectionAdditionals.filter((additional) => additional.selected);
  }

  public getSelectedServiceAdditionals() {
    const serviceAdditionals = this.service.specialFields.serviceAdditionals;

    if (!serviceAdditionals) {
      return [];
    }

    return serviceAdditionals.filter((additional) => additional.selected);
  }

  public getSupplier() {
    return this.service.supplier as CarSupplier;
  }

  public getTotalTravelers() {
    return 1;
  }

  public hasDiffDropOff() {
    const { address: dropOffAddress } = this.getDropOff();
    const { address: pickUpAddress } = this.getPickUp();

    return dropOffAddress !== pickUpAddress;
  }
}

export class FlightItineraryServiceModel extends ItineraryServiceModel {
  constructor(protected service: ItineraryFlightService) {
    super(service);
  }

  public getAdvance() {
    const { startDate } = this.getPeriod();

    const today = moment().startOf("day");

    return today.diff(startDate, "days") * -1;
  }

  public getAllJourneys() {
    return this.service.specialFields.journeys;
  }

  public getCommomBaggagePieces() {
    const allJourneys = this.getAllJourneys();

    if (allJourneys.length === 0) {
      return null;
    }

    return allJourneys[0].segments.reduce(
      (acc, segment) => acc + (segment.baggage?.checked.quantity || 0),
      0,
    );
  }

  public getCommomCabinClass() {
    const allJourneys = this.getAllJourneys();

    if (allJourneys.length === 0) {
      return null;
    }

    const allSegments = allJourneys[0].segments;

    if (allSegments.length === 0) {
      return null;
    }

    return allJourneys[0].segments[0].cabinClass;
  }

  public getCommomFareFamily() {
    const allJourneys = this.getAllJourneys();

    if (allJourneys.length === 0) {
      return null;
    }

    return allJourneys[0].fareFamily;
  }

  public getFirstJourney() {
    return this.service.specialFields.journeys[0];
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getIcon(): Icons {
    return "airplane-taking-off";
  }

  public getInboundJourney() {
    if (this.isOneWay()) {
      return null;
    }

    return this.getLastJourney();
  }

  public getJourneyDaysDiff(journey: {
    arrival: { date: string; time: string };
    departure: { date: string; time: string };
  }) {
    return differenceInDays(journey.departure.date, journey.arrival.date);
  }

  private getLastJourney() {
    return this.service.specialFields.journeys[
      this.service.specialFields.journeys.length - 1
    ];
  }

  public getOutboundJourney() {
    return this.getFirstJourney();
  }

  public getReservationStatus() {
    return this.service.specialFields.reservation.status;
  }

  public getReservationReservedUntil() {
    return this.service.specialFields.reservation.reservedUntil;
  }

  public getService(): ItineraryFlightService {
    return this.service;
  }

  public getPeriod() {
    return {
      endDate: moment(this.getLastJourney().arrival.date),
      startDate: moment(this.getFirstJourney().departure.date),
    };
  }

  public getSearchPeriod() {
    if (this.isOneWay()) {
      return {
        startDate: moment(this.getFirstJourney().departure.date),
      };
    }

    return {
      endDate: moment(this.getLastJourney().departure.date),
      startDate: moment(this.getFirstJourney().departure.date),
    };
  }

  public getTotalTravelers() {
    return 1;
  }

  public isInternational() {
    return this.service.specialFields.isInternational;
  }

  public isOneWay() {
    return this.service.specialFields.isOneWay;
  }

  public isReservationUnitiated() {
    return (
      this.service.specialFields.reservation.status === RESERVE_STATUS.UNITIATED
    );
  }

  public isSameDayReturn() {
    const inboundJourney = this.getInboundJourney();

    if (!inboundJourney) {
      return false;
    }

    const outboundJourneyDepartureDate = this.getOutboundJourney().departure
      .date;
    const inboundJourneyDepartureDate = inboundJourney.departure.date;

    return (
      moment(outboundJourneyDepartureDate).diff(
        moment(inboundJourneyDepartureDate),
        "days",
      ) === 0
    );
  }
}

export class HotelItineraryServiceModel extends ItineraryServiceModel {
  constructor(protected service: ItineraryHotelService) {
    super(service);
  }

  public getAddress() {
    return this.service.specialFields.address;
  }

  public getAdvance() {
    const { startDate } = this.getPeriod();

    const today = moment().startOf("day");

    return (today.diff(startDate, "days") + 1) * -1;
  }

  public getAmenities() {
    return this.service.specialFields.amenities;
  }

  public getCheckIn() {
    return this.service.specialFields.checkIn;
  }

  public getCheckInHour() {
    return this.service.specialFields.checkInHour;
  }

  public getCheckOut() {
    return this.service.specialFields.checkOut;
  }

  public getCheckOutHour() {
    return this.service.specialFields.checkOutHour;
  }

  public getCity() {
    return this.service.specialFields.city;
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    const { startDate } = this.getPeriod();

    if (freeCancelationUntil === "any") {
      return moment(startDate).format("DD [de] MMMM [de] YYYY");
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getIcon(): Icons {
    return "building";
  }

  public getHotelName() {
    return this.service.specialFields.hotelName;
  }

  public getMainImageURL() {
    return this.service.specialFields.roomImageUrl;
  }

  public getNightlyPrice() {
    return this.getPrice() / this.service.specialFields.numberOfNights;
  }

  public getNights() {
    return this.service.specialFields.numberOfNights;
  }

  public getService(): ItineraryHotelService {
    return this.service;
  }

  public getPeriod() {
    return {
      endDate: moment(this.getCheckOut()),
      startDate: moment(this.getCheckIn()),
    };
  }

  public getRoom() {
    return {
      imageUrl: this.service.specialFields.roomImageUrl,
      name: this.service.specialFields.roomName,
    };
  }

  public getSearchPeriod() {
    return this.getPeriod();
  }

  public getStars() {
    return this.service.specialFields.stars;
  }

  public getTotalGuests() {
    return this.service.specialFields.totalGuests;
  }

  public getTotalTravelers() {
    return this.getTotalGuests();
  }

  public isCompanyNegotiated() {
    return this.service.specialFields.isCompanyNegotiated;
  }
}

export class ItineraryServiceModelFactory {
  static create(service: ItineraryService) {
    switch (service.type) {
      case ServiceType.BUS:
        return ItineraryServiceModelFactory.createBus(
          service as ItineraryBusService,
        );
      case ServiceType.CAR:
        return ItineraryServiceModelFactory.createCar(
          service as ItineraryCarService,
        );
      case ServiceType.FLIGHT:
        return ItineraryServiceModelFactory.createFlight(
          service as ItineraryFlightService,
        );
      case ServiceType.HOTEL:
        return ItineraryServiceModelFactory.createHotel(
          service as ItineraryHotelService,
        );
      default:
        throw new Error("Invalid service type");
    }
  }

  static createBus(service: ItineraryBusService) {
    return new BusItineraryServiceModel({
      ...service,
      search: {
        ...service.search,
        endDate: service.search.endDate?.split("/").reverse().join("-"),
        startDate: service.search.startDate?.split("/").reverse().join("-"),
      },
      specialFields: {
        ...service.specialFields,
        journeys: service.specialFields.journeys.map((journey) => ({
          ...journey,
          arrival: {
            ...journey.arrival,
            date: journey.arrival.date.split("/").reverse().join("-"),
          },
          departure: {
            ...journey.departure,
            date: journey.departure.date.split("/").reverse().join("-"),
          },
          segments: journey.segments.map((segment) => ({
            ...segment,
            arrival: {
              ...segment.arrival,
              date: segment.arrival.date.split("/").reverse().join("-"),
            },
            departure: {
              ...segment.departure,
              date: segment.departure.date.split("/").reverse().join("-"),
            },
          })),
        })),
      },
    });
  }

  static createCar(service: ItineraryCarService) {
    return new CarItineraryServiceModel(service);
  }

  static createFlight(service: ItineraryFlightService) {
    return new FlightItineraryServiceModel({
      ...service,
      search: {
        ...service.search,
        endDate: service.search.endDate?.split("/").reverse().join("-"),
        startDate: service.search.startDate?.split("/").reverse().join("-"),
      },
      specialFields: {
        ...service.specialFields,
        journeys: service.specialFields.journeys.map((journey) => ({
          ...journey,
          arrival: {
            ...journey.arrival,
            date: journey.arrival.date.split("/").reverse().join("-"),
          },
          departure: {
            ...journey.departure,
            date: journey.departure.date.split("/").reverse().join("-"),
          },
          segments: journey.segments.map((segment) => ({
            ...segment,
            arrival: {
              ...segment.arrival,
              date: segment.arrival.date.split("/").reverse().join("-"),
            },
            departure: {
              ...segment.departure,
              date: segment.departure.date.split("/").reverse().join("-"),
            },
          })),
        })),
      },
    });
  }

  static createHotel(service: ItineraryHotelService) {
    return new HotelItineraryServiceModel({
      ...service,
      search: {
        ...service.search,
        endDate: service.search.endDate?.split("/").reverse().join("-"),
        startDate: service.search.startDate?.split("/").reverse().join("-"),
      },
      specialFields: {
        ...service.specialFields,
        checkIn: moment(service.specialFields.checkIn)
          .utc()
          .format("YYYY-MM-DD"),
        checkOut: moment(service.specialFields.checkOut)
          .utc()
          .format("YYYY-MM-DD"),
      },
    });
  }
}
