import React from "react";

import { Avatar as MuiAvatar } from "@material-ui/core";

import { styles } from "./styles";

const stringAvatar = (name: string) => {
  return `${name[0]}${name[1]}`.toUpperCase();
};

type Props = React.ComponentProps<typeof MuiAvatar> & {
  name?: string;
};

export const Avatar: React.FC<Props> = ({ className, name, ...props }) => {
  if (name) {
    return (
      <div className={className} css={styles.root({ name })}>
        <MuiAvatar {...props}>{stringAvatar(name)}</MuiAvatar>
      </div>
    );
  }

  return <MuiAvatar {...props} />;
};
