import React from "react";

import { VcnDisplay } from "~/models/vcn.model";

import { VcnItemPresentational } from "./VcnItem.presentational";

interface Props {
  vcn: VcnDisplay;
}

const VcnItemContainer = ({ vcn }: Props) => {
  return <VcnItemPresentational vcn={vcn} />;
};

export { VcnItemContainer as VcnItem };
