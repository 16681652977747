import React from "react";

import { useTheme } from "@material-ui/core";
import { css } from "emotion";

type Type = "margin" | "padding";

type Direction =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "all"
  | "vertical"
  | "horizontal";

type SpacingProps = {
  type?: Type;
  direction: Direction;
  space: string | number;
};

const styles: Record<
  Type,
  Record<Direction, (space: string | number) => string>
> = {
  margin: {
    all: (space: string | number) => css({ margin: space }),
    top: (space: string | number) => css({ margin: `${space} 0 0 0` }),
    right: (space: string | number) => css({ margin: `0 ${space} 0 0` }),
    bottom: (space: string | number) => css({ margin: `0 0 ${space} 0` }),
    left: (space: string | number) => css({ margin: `0 0 0 ${space}` }),
    vertical: (space: string | number) => css({ margin: `${space} 0` }),
    horizontal: (space: string | number) => css({ margin: `0 ${space}` })
  },
  padding: {
    all: (space: string | number) => css({ padding: space }),
    top: (space: string | number) => css({ padding: `${space} 0 0 0` }),
    right: (space: string | number) => css({ padding: `0 ${space} 0 0` }),
    bottom: (space: string | number) => css({ padding: `0 0 ${space} 0` }),
    left: (space: string | number) => css({ padding: `0 0 0 ${space}` }),
    vertical: (space: string | number) => css({ padding: `${space} 0` }),
    horizontal: (space: string | number) => css({ padding: `0 ${space}` })
  }
};

export function Spacing(props: SpacingProps) {
  const { type = "padding", space, direction } = props;

  const theme = useTheme();
  const spacing =
    typeof space === "number" ? `${theme.spacing(space) / 2}px` : space;
  const className = styles[type][direction](spacing);

  return <div aria-hidden className={className} />;
}
