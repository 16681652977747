import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "emotion";
import { darken } from "polished";
import Tooltip from "@material-ui/core/Tooltip";
import PhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import FullScreenIcon from "@material-ui/icons/FullscreenExitRounded";

import { defaultTheme } from "@theme";
import { Column } from "@shared/layout/index";
import { ImageFile, StringTMap } from "~/types";
import InputError from "@shared/inputs/InputError";
import { ImageDialog } from "./ImageDialog";

const styles = {
  rootDiv: css({
    height: "355px !important",
    border: `2px dashed ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    [":focus"]: {
      outline: "none",
      borderColor: defaultTheme.primaryColor
    }
  }),
  errorDiv: css({
    borderColor: defaultTheme.secondaryColor
  }),
  imgDiv: css({
    maxWidth: "100%",
    maxHeight: 350
  }),
  staticPlaceholder: css({
    color: defaultTheme.cardBorderColor
  }),
  hoveredPlaceholder: css({
    position: "absolute",
    justifyContent: "center",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    zIndex: 1,
    borderRadius: 4
  }),
  tooltip: css({
    fontSize: 12,
    width: "100%",
    padding: ".7rem"
  }),
  fullScreenBtn: css({
    position: "absolute",
    height: 42,
    width: 42,
    top: 8,
    right: 8,
    padding: 8,
    cursor: "pointer",
    color: "#666666",
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: defaultTheme.cardBorderColor,
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    zIndex: 10,
    outline: "none",
    transition: "background-color 200ms ease",
    ["&:hover"]: {
      backgroundColor: darken(0.05, defaultTheme.cardSecondaryBackgroundColor)
    }
  })
};

interface IFullScreenBtnProps {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const FullScreenButton: React.FC<IFullScreenBtnProps> = ({ onClick }) => {
  return (
    <Tooltip
      title="Visualizar comprovante em tela cheia"
      classes={{ tooltip: styles.tooltip }}
    >
      <button className={styles.fullScreenBtn} onClick={onClick}>
        <FullScreenIcon style={{ color: defaultTheme.subTextColor }} />
      </button>
    </Tooltip>
  );
};

interface IAddPicturePlaceholder {
  containerClasses?: string;
}

const AddPicturePlaceholder: React.FC<IAddPicturePlaceholder> = ({
  containerClasses = ""
}) => (
  <Column
    style={{
      alignItems: "center"
    }}
    className={containerClasses}
  >
    <PhotoIcon
      style={{
        fontSize: 50,
        color: "inherit",
        marginBottom: "1rem"
      }}
    />
    <span
      style={{
        fontSize: 14,
        color: "inherit",
        textAlign: "center"
      }}
    >
      Arraste e solte um arquivo <br /> ou clique aqui para selecioná-lo
      <br />
      <br />
      (Arquivos aceitos: .jpg e .png de até 5MB)
    </span>
  </Column>
);

interface IDropzoneProps {
  imageUrl: string | null;
  inputProps?: StringTMap<any>;
  handleChange: (imageFile: ImageFile) => void;
}

const DropzoneInput: React.FC<IDropzoneProps> = ({
  imageUrl,
  inputProps,
  handleChange
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: ImageFile[]) => {
      const file = acceptedFiles.find(currentFile => !!currentFile);

      if (file) {
        handleChange(file);
      }
    },
    [handleChange]
  );

  const onDropRejected = useCallback((rejectedFiles: ImageFile[]) => {
    const file = rejectedFiles.find(currentFile => !!currentFile);

    if (file) {
      const extension = file.name.toLowerCase().split(".").pop();
      if (extension !== "jpg" && extension !== "png" && extension !== "jpeg") {
        setError("Somente arquivos .jpg e .png são aceitos");
      } else if (file.size > 5242880) {
        setError("Tamanho máximo de 5MB");
      }
    } else {
      setError(null);
    }
  }, []);

  const handleOpenImageInFullScreen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (imageUrl) {
        setFullScreen(true);
      }
    },
    [imageUrl]
  );

  const handleCloseFullScreenVisibility = () => {
    setFullScreen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "image/jpeg, image/png",
    maxSize: 5242880,
    multiple: false
  });

  const setMouseHover = () => setHover(true);
  const removeMouseHover = () => setHover(false);

  return (
    <>
      <ImageDialog
        isOpen={isFullScreen}
        imgUrl={imageUrl}
        onClose={handleCloseFullScreenVisibility}
      />
      <div
        {...getRootProps()}
        className={`${styles.rootDiv} ${error ? styles.errorDiv : ""}`}
        onMouseEnter={setMouseHover}
        onMouseLeave={removeMouseHover}
      >
        {imageUrl && <FullScreenButton onClick={handleOpenImageInFullScreen} />}
        <Column style={{ alignItems: "center", maxHeight: "100%" }}>
          <input {...inputProps} {...getInputProps()} />
          {imageUrl ? (
            <img src={imageUrl} className={styles.imgDiv} />
          ) : (
            <AddPicturePlaceholder
              containerClasses={styles.staticPlaceholder}
            />
          )}
          {imageUrl && hover ? (
            <AddPicturePlaceholder
              containerClasses={styles.hoveredPlaceholder}
            />
          ) : null}
        </Column>
      </div>
      {error ? (
        <InputError errorStyle={{ paddingTop: ".5rem" }}>{error}</InputError>
      ) : null}
    </>
  );
};

export { DropzoneInput };
