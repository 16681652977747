import React, { useCallback, useEffect, useState } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import {
  NavigationMenu,
  NavigationMenuItem,
} from "~/apps/shared/components/navigation-menu/navigation-menu";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { capabilities } from "~/apps/shared/constants/enums";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { styles } from "./styles";

export const ConfigurationsMenu: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Configurações</span>
      <hr css={styles.divisor} />
      <ConfigurationsMenuItems />
    </div>
  );
};

const ConfigurationsMenuItems: React.FC = () => {
  const {
    location: { pathname },
  } = useLocation();

  const { clientConfig } = useClientConfig();

  const [open, setOpen] = useState<
    "company" | "expenses" | "financial" | "trips" | null
  >(null);

  const companyAreaDisplay = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : null;

  const companyBoardDisplay = clientConfig
    ? clientConfig.getCompanyBoardDisplay()
    : null;

  const handleOpen = useCallback((id: typeof open) => {
    setOpen((prev) => (prev === id ? null : id));
  }, []);

  useEffect(() => {
    if (pathname.includes("/configurations/trips")) {
      setOpen("trips");
    } else if (pathname.includes("/configurations/company")) {
      setOpen("company");
    } else if (pathname.includes("/configurations/expenses")) {
      setOpen("expenses");
    } else if (pathname.includes("/configurations/financial")) {
      setOpen("financial");
    } else {
      setOpen(null);
    }
  }, [pathname]);

  return (
    <ul css={styles.items.root}>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenuItem
          icon="people"
          label="Pessoas"
          to="/configurations/users"
        />
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenu
          icon="airplane-taking-off"
          label="Regras de viagem"
          onChange={() => {
            handleOpen("trips");
          }}
          open={open === "trips"}
        >
          <NavigationMenuItem
            label="Políticas de viagem"
            to="/configurations/trips"
          />
          <NavigationMenuItem
            label="Fluxos de aprovação"
            to="/configurations/trips/approval-processes"
          />
          <PermissionProtectedRender
            permissions={["travels.countryRestrictions"]}
          >
            <NavigationMenuItem
              label="Restrições de viagens"
              to="/configurations/trips/country-restrictions"
            />
          </PermissionProtectedRender>
          <NavigationMenuItem
            label="Campos personalizados"
            to="/configurations/trips/custom-field-options"
          />
        </NavigationMenu>
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenu
          icon="building"
          label="Organização da empresa"
          onChange={() => {
            handleOpen("company");
          }}
          open={open === "company"}
        >
          <PermissionProtectedRender
            permissions={["companySettings.costCenters"]}
          >
            <NavigationMenuItem
              label="Centros de Custos"
              to="/configurations/company/cost-centers"
            />
          </PermissionProtectedRender>
          <PermissionProtectedRender permissions={["companySettings.areas"]}>
            <NavigationMenuItem
              label={`${companyAreaDisplay}`}
              to="/configurations/company/areas"
            />
          </PermissionProtectedRender>
          <PermissionProtectedRender permissions={["companySettings.projects"]}>
            <NavigationMenuItem
              label="Projetos"
              to="/configurations/company/projects"
            />
          </PermissionProtectedRender>
          <PermissionProtectedRender permissions={["companySettings.areas"]}>
            <NavigationMenuItem
              label={`${companyBoardDisplay}`}
              to="/configurations/company/boards"
            />
          </PermissionProtectedRender>
          <NavigationMenuItem
            label="Etiquetas"
            to="/configurations/company/tags"
          />
          {/* <NavigationMenuItem
            label="Orçamentos"
            to="/configurations/company/budgets"
          /> */}
        </NavigationMenu>
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenu
          icon="money-withdraw"
          label="Regras de despesas"
          onChange={() => {
            handleOpen("expenses");
          }}
          open={open === "expenses"}
        >
          <NavigationMenuItem
            label="Fluxo de reembolsos"
            to="/configurations/expenses"
          />
          <NavigationMenuItem
            label="Categoria de despesas"
            to="/configurations/expenses/expenses-categories"
          />
          <PermissionProtectedRender permissions={["expenses.policies"]}>
            <NavigationMenuItem
              label="Políticas de despesas"
              to="/configurations/expenses/expense-policy"
            />
          </PermissionProtectedRender>
          <PermissionProtectedRender permissions={["expenses.advances"]}>
            <NavigationMenuItem
              label="Fluxo de adiantamentos"
              to="/configurations/expenses/advance-approval-process"
            />
          </PermissionProtectedRender>
        </NavigationMenu>
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenu
          icon="dollar"
          label="Configurações de conta"
          onChange={() => {
            handleOpen("financial");
          }}
          open={open === "financial"}
        >
          <PermissionProtectedRender
            permissions={["companySettings.billingProfiles"]}
          >
            <NavigationMenuItem
              label="Empresas (CNPJs)"
              to="/configurations/financial"
            />
          </PermissionProtectedRender>
          <NavigationMenuItem
            label="Configurações de pagamento"
            to="/configurations/financial/payment-methods"
          />
        </NavigationMenu>
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenuItem
          icon="code-bracket-square"
          label="API Smartrips"
          to="/configurations/developers/api-keys"
        />
      </CapabilityProtectedRender>
      <CapabilityProtectedRender capabilities={[capabilities.CONFIGURATIONS]}>
        <NavigationMenuItem
          icon="share-nodes"
          label="Integrações"
          to="/configurations/integrations"
        />
      </CapabilityProtectedRender>
    </ul>
  );
};
