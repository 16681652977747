import { css } from "@styled-system/css";

import { Box } from "@toolkit";

import styled from "@shared/styled";

const HeaderContainer = styled(Box)(
  css({
    py: 24,
    px: ["medium", "medium", 0],
    backgroundColor: ["white", "white", "transparent"],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: ["1px solid", "1px solid", "none"],
    borderBottomColor: ["border", "border"],
    borderRadius: 0,
    zIndex: 1,
    position: ["sticky", "sticky", "relative"],
    width: "100%",
    top: 0,
  }),
);

export { HeaderContainer };
