import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  filter: {
    bottom: {
      icon: css({
        "& > svg": {
          strokeWidth: "2px",
        },
      }),
      price: css({
        fontSize: "1rem",
        fontWeight: 700,
      }),
      root: css({
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }),
    },
    root: ({ active, isMobile }: { active?: boolean; isMobile?: boolean }) =>
      !isMobile
        ? css({
            width: "25%",
            "& > :first-child": {
              alignItems: "flex-start",
              border: "none",
              borderRadius: "0px",
              flexDirection: "column",
              fontSize: "0.875rem",
              gap: "4px",
              height: "100%",
              justifyContent: "center",
              overflow: "hidden",
              padding: "14px 24px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              "& > :first-child": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              },
              ":disabled": {
                color: theme.colors.gray[300],
                cursor: "default",
                opacity: 1,
              },
              ...(active && {
                backgroundColor: theme.colors.pink[500],
                color: theme.colors.white,
              }),
            },
          })
        : css({
            width: "100%",
            "& > :first-child": {
              justifyContent: "space-between",
              width: "100%",
            },
          }),
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    justifyContent: "space-between",
    width: "100%",
  }),
  tabs: {
    root: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      flex: 1,
      height: "fit-content",
      "& > :first-child > :last-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "& > :last-child > :first-child": {
        borderRadius: "0 8px 8px 0",
      },
    }),
    tab: {
      button: css({
        border: "none",
        borderRadius: "0px",
        flexDirection: "column",
        fontWeight: 400,
        gap: "4px",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }),
      divider: css({
        backgroundColor: theme.colors.gray[100],
        height: "100%",
        width: "1px",
      }),
      price: css({
        fontWeight: 700,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        whiteSpace: "nowrap",
        width: "25%",
      }),
      title: css({
        fontSize: "0.875rem",
      }),
    },
  },
};
