import React from "react";

import {
  Dialog,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { TravelTagsCreatableSelect } from "../travel-tags-creatable-select/travel-tags-creatable-select";
import { TravelTagsRemovableBadges } from "../travel-tags-removable-badges/travel-tags-removable-badges";
import { styles } from "./styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  travelName: string | null;
  travelToken: string;
};

export const TravelTagsDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  travelName,
  travelToken,
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogHeader icon="tag" onClose={onClose} />
      <DialogTitle>
        Editar etiquetas da viagem{" "}
        {travelName ? <>&quot;{travelName}&quot;</> : null}
      </DialogTitle>
      <div css={styles.root}>
        <div css={styles.body.root}>
          <TravelTagsCreatableSelect travelToken={travelToken} />
          <TravelTagsRemovableBadges travelToken={travelToken} />
        </div>
      </div>
    </Dialog>
  );
};
