import {
  getClientToken,
  getAuthorizationHeader,
} from "~/apps/corporate/helpers/user.helper";
import {
  downloadFileFromUrl,
  downloadFile,
} from "~/apps/shared/helpers/download.helper";
import { api } from "~/apps/shared/services/api";
import { AxiosError, AxiosResponse } from "axios";

const createBatchApiFactory = (options: {
  modelFileDownloadUrl: string;
  modelFilename: string;
  originType: "url" | "api";
  uploadMethod: "POST" | "PUT";
  url: () => string;
}) => {
  return {
    onDownloadModelClick: async () => {
      if (options.originType === "api") {
        return await api
          .request({
            headers: {
              ...getAuthorizationHeader(),
              "Content-Type":
                "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet",
            },
            method: "GET",
            responseType: "arraybuffer",
            url: `/booking/clients/${getClientToken()}${
              options.modelFileDownloadUrl
            }`,
          })
          .then((response: AxiosResponse) => {
            downloadFile({
              data: response.data,
              filename: options.modelFilename,
            });
          });
      }

      return await api
        .request({
          headers: getAuthorizationHeader(),
          method: "GET",
          url: options.modelFileDownloadUrl,
        })
        .then((response: AxiosResponse) => {
          downloadFileFromUrl({
            filename: options.modelFilename,
            url: response.data,
          });
        });
    },
    onUpload: async (file: File) => {
      return await api
        .request({
          data: getFile(file),
          headers: getAuthorizationHeader(),
          method: options.uploadMethod,
          url: options.url(),
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError<ExcelCreateError>) => {
          throw error.response?.data;
        });
    },
  };
};

export const boardBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/board",
  modelFilename: "board.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/board/excel`,
});

export const createUserApprovalFlows = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/approval-flows?type=USER",
  modelFilename: "userApprovalProcess.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/approval-flows/excel`,
});

export const costCenterBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/costCenter",
  modelFilename: "cost-center.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/cost-center/excel`,
});

export const companyAreaBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/companyArea",
  modelFilename: "company-area.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/company-area/excel`,
});

export type ExcelCreateError = {
  code: string;
  message: string;
  name: string;
  row: number;
};

const getFile = (file: File): FormData => {
  const formData = new FormData();
  formData.append("file", file);

  return formData;
};

export const projectBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/project",
  modelFilename: "project.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/project/excel`,
});

export const tagBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/tag",
  modelFilename: "tag.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/tag/excel`,
});

export const updateCompanyAreasBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: `/company-area/excel`,
  modelFilename: "company-areas.xlsx",
  originType: "api",
  uploadMethod: "PUT",
  url: () => `/booking/clients/${getClientToken()}/company-area/excel`,
});

export const updateCostCentersBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: `/cost-centers/excel`,
  modelFilename: "cost-centers.xlsx",
  originType: "api",
  uploadMethod: "PUT",
  url: () => `/booking/clients/${getClientToken()}/cost-centers/excel`,
});

export const updateProjectsBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: `/projects/excel`,
  modelFilename: "project-list.xlsx",
  originType: "api",
  uploadMethod: "PUT",
  url: () => `/booking/clients/${getClientToken()}/projects/excel`,
});

export const updateUsersBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: `/users/excel/download`,
  modelFilename: "user-list.xlsx",
  originType: "api",
  uploadMethod: "PUT",
  url: () => `/booking/clients/${getClientToken()}/users/excel`,
});

export const userBatchApi = createBatchApiFactory({
  modelFileDownloadUrl: "/booking/excel/download/user",
  modelFilename: "user.model.xlsx",
  originType: "url",
  uploadMethod: "POST",
  url: () => `/booking/clients/${getClientToken()}/user/excel`,
});
