import { EXPENSES_CATEGORIES } from "@constants";

export function getTotalAmount(arr) {
  if (!arr) {
    return 0;
  }
  return arr.reduce((acc, cur) => {
    return (acc += cur.total);
  }, 0);
}

export function getPercentage(portion, total) {
  return (portion * 100) / total;
}

export function formatPercentage(value) {
  if (!value) {
    value = 0;
  }
  const formatted = value.toFixed(2).replace(".", ",");
  return `${formatted}%`;
}

export function getTotalSpent(arr) {
  return arr.reduce((acc, cur) => {
    return cur.type === EXPENSES_CATEGORIES.FLIGHT ? (acc += cur.total) : acc;
  }, 0);
}

export function getCreditsPerCompany(creditList) {
  return creditList.reduce((acc, cur) => {
    if (!acc.some(item => item.provider === cur.provider)) {
      acc.push(cur);
    } else {
      acc = acc.map(item =>
        item.provider === cur.provider
          ? { ...item, value: item.value + cur.value }
          : item
      );
    }

    return acc;
  }, []);
}
