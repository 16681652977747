import React, { useCallback, useState } from "react";

import { CarCategoryExplanationDialog } from "~/apps/corporate/components/car-category-explanation-dialog/car-category-explanation-dialog";
import { Select } from "~/apps/shared/components/select/select";

import { styles } from "./styles";

export const CarCategorySelect: React.FC<
  React.ComponentPropsWithoutRef<typeof Select>
> = ({ ...props }) => {
  const [
    isCarCategoryExplanationDialogOpen,
    setIsCarCategoryExplanationDialogOpen,
  ] = useState(false);

  const handleCloseCarCategoryExplanationDialog = useCallback(() => {
    setIsCarCategoryExplanationDialogOpen(false);
  }, []);

  const handleOpenCarCategoryExplanationDialog = useCallback(() => {
    setIsCarCategoryExplanationDialogOpen(true);
  }, []);

  return (
    <>
      <CarCategoryExplanationDialog
        onClose={() => {
          handleCloseCarCategoryExplanationDialog();
        }}
        open={isCarCategoryExplanationDialogOpen}
        showAll
      />
      <div css={styles.root}>
        <Select fill="filled" {...props} />
        <button
          css={styles.clear}
          onClick={() => {
            handleOpenCarCategoryExplanationDialog();
          }}
        >
          Entenda as categorias
        </button>
      </div>
    </>
  );
};
