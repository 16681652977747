const areaTypes: {
  [key in "city" | "country" | "state"]:
    | "administrative_area_level_1"
    | "administrative_area_level_2"
    | "country"
    | "locality";
} = {
  city: "administrative_area_level_2",
  state: "administrative_area_level_1",
  country: "country",
};

const areaSizes = {
  city: "long_name",
  state: "short_name",
  country: "short_name",
} as const;

const extractArea = (
  addressComponents: GeocoderAddressComponent[],
  areaType: keyof typeof areaTypes,
) => {
  let area = extractAreaFromGoogle(addressComponents)(areaTypes[areaType]);

  if (!area[0]) {
    areaTypes.city = "locality";
    area = extractAreaFromGoogle(addressComponents)(areaTypes[areaType]);
  }

  return area.length ? area[0][areaSizes[areaType]] : "";
};

const extractAreaFromGoogle = (
  addressComponents: GeocoderAddressComponent[],
) => {
  return (areaType: typeof areaTypes[keyof typeof areaTypes]) => {
    const area = addressComponents.filter((addressComponent) => {
      if (addressComponent.types.join().indexOf(areaType) > -1) {
        return addressComponent;
      }

      return false;
    });

    return area;
  };
};

const cityTypes = {
  administrative_area_level_2: true,
  administrative_area_level_3: true,
  locality: true,
  archipelago: true,
  establishment: true,
  natural_feature: true,
} as const;

const extractCity = (addressComponents: GeocoderAddressComponent[]) => {
  const cityComponent = addressComponents.find(
    (component: { types: string[] }) => {
      return component.types.some((type) => type in cityTypes);
    },
  );

  return cityComponent ? cityComponent["long_name"] : "";
};

export const extractDetails = (result: GeocoderResult) => {
  const { lat, lng } = result.geometry.location;

  const details = {
    city: extractCity(result.address_components),
    country: extractArea(result.address_components, "country"),
    latitude: typeof lat === "number" ? lat : lat(),
    longitude: typeof lng === "number" ? lng : lng(),
    state: extractArea(result.address_components, "state"),
  };

  return details;
};
