import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";

import { BusCategoriesExplanationDialog } from "./bus-categories-explanation-dialog/bus-categories-explanation-dialog";
import { BusSeatPickerDrawer } from "./bus-search-picker-drawer/bus-search-picker-drawer";
import { BusesContent } from "./buses-content/buses-content";
import { BusesHeader } from "./buses-header/buses-header";
import { BusesProvider, useBusesPageActions } from "./buses.context";
import { BusesEditSearchDialog } from "./edit-search-dialog/edit-search-dialog";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    busToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({ busToken, travelToken }) => {
  const { fetchBusTrips } = useBusesPageActions();

  useEffect(() => {
    if (!busToken) {
      return;
    }

    void fetchBusTrips(busToken);
  }, [busToken]);

  return (
    <>
      <PageTitle title="Pesquisa de ônibus" />
      <div css={styles.root}>
        <BusesHeader />
        <BusesContent />
      </div>
      {travelToken ? <BusesEditSearchDialog travelToken={travelToken} /> : null}
      <BusCategoriesExplanationDialog />
      <BusSeatPickerDrawer />
    </>
  );
};

export const Buses: React.FC<Props> = ({ ...props }) => {
  const { busToken } = props;

  if (!busToken) {
    return null;
  }

  return (
    <BusesProvider busToken={busToken}>
      <Component {...props} />
    </BusesProvider>
  );
};
