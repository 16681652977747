import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { Column } from "@shared/layout/Column";
import { css } from "emotion";
import PageTitle from "@shared/PageTitle";
import PAGE_TITLES from "@constants/page-titles.constants";
import {
  ApprovalReportsListProvider,
  ApprovalReportsListContext
} from "./ApprovalReportsList.context";
import { ReportItem } from "./ReportItem/ReportItem";
import InfiniteScroll from "react-infinite-scroller";
import SpinnerPortal from "@shared/Spinner";
import { MEDIA_QUERIES } from "@constants";
import { ListHeader } from "./ListHeader";
import { SignalPaymentDialog } from "./SignalPaymentDialog";

const styles = {
  root: css({
    width: "100%",
    ["@media (max-width: 991px)"]: {
      paddingBottom: "4rem"
    }
  }),
  inputContainer: css({
    width: 350
  }),
  button: css({
    padding: ".5rem 1.5rem",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      marginBottom: "1.5rem"
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: "150"
    }
  })
};

const ApprovalReportsList = () => {
  const {
    reports,
    allowReportPaymentSelection,
    isPaymentConfirmationVisible,
    submittingPayment,
    hasAnyReportSelected,
    selectedReportsToPay,
    reportsErrorOnPayment,
    loadNextReports,
    currentPage,
    totalPages,
    isLoading,
    handleChangeSearch,
    handleSelectReport,
    handleSignalPayment,
    handleClosePaymentDialog,
    handleProccessSignalPayments
  } = useContext(ApprovalReportsListContext);

  const hasNextPage = !isLoading && currentPage < totalPages;

  return (
    <div className={styles.root}>
      <PageTitle title={PAGE_TITLES.EXPENSE_REPORTS} />
      <SpinnerPortal visible={isLoading} />
      <SignalPaymentDialog
        isVisible={isPaymentConfirmationVisible}
        isSubmitting={submittingPayment}
        erroredReports={reportsErrorOnPayment}
        handleSignalPayment={handleProccessSignalPayments}
        handleClose={handleClosePaymentDialog}
      />
      <ListHeader
        allowReportPaymentSelection={allowReportPaymentSelection}
        hasAnyReportSelected={hasAnyReportSelected}
        handleSearchChange={handleChangeSearch}
        handleSignalPayment={handleSignalPayment}
      />
      <div>
        <Column>
          {!reports.length && !isLoading ? (
            <p>Nenhum relatório encontrado</p>
          ) : null}
          <InfiniteScroll
            pageStart={1}
            loadMore={loadNextReports}
            hasMore={hasNextPage}
            loader={
              <div key={0} className="loader">
                Loading ...
              </div>
            }
          >
            {reports.map(report => (
              <ReportItem
                key={report.expenseReportToken}
                report={report}
                isSelected={!!selectedReportsToPay[report.expenseReportToken]}
                isSelectable={allowReportPaymentSelection}
                handleSelectReport={handleSelectReport}
              />
            ))}
          </InfiniteScroll>
        </Column>
      </div>
    </div>
  );
};

interface IProps {
  reportsStatusType: "pending-payment" | "pending-approval" | "paid";
}

const Container = ({ reportsStatusType }: RouteComponentProps<IProps>) => (
  <ApprovalReportsListProvider reportsStatusType={reportsStatusType!}>
    <ApprovalReportsList />
  </ApprovalReportsListProvider>
);

export { Container as ApprovalReportsList };
