import React from "react";

import TITLES from "@constants/page-titles.constants";

import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";

import {
  TravelApprovalsProvider,
  useTravelApprovalsContext
} from "./TravelApprovals.context";
import { TravelApprovalsHeader } from "./TravelApprovalsHeader";
import { TravelApprovalsList } from "./TravelApprovalsList";

const TravelApprovals = () => {
  const { loading } = useTravelApprovalsContext();

  return (
    <React.Fragment>
      <PageTitle title={TITLES.TRAVEL_APPROVALS} />
      <SpinnerPortal visible={loading} />
      <TravelApprovalsHeader />
      <TravelApprovalsList />
    </React.Fragment>
  );
};

const Container = () => (
  <TravelApprovalsProvider>
    <TravelApprovals />
  </TravelApprovalsProvider>
);

export { Container as TravelApprovals };
