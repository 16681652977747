import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  container: {
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      gap: "12px",
      padding: "13px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
  label: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "28px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  text: css({
    color: theme.colors.gray[500],
    fontWeight: 600,
  }),
  tooltip: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
};
