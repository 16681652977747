import React from "react";
import { ExpensePolicyListItem } from "../../../../models/expense-policy.model";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";
import { MoreActionsPopOver } from "../../shared/MoreActionsPopOver/MoreActionsPopOver";
import { Row } from "../../../shared/layout/Row";
import PeopleIcon from "@material-ui/icons/PeopleRounded";
import BusinessIcon from "@material-ui/icons/Business";
import ColoredTag from "../../../shared/ColoredTag";
import { TARGET_TYPES } from "../../../../constants";

const styles = {
  rowDiv: css({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem .5rem",
    cursor: "pointer",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    ["&:hover"]: {
      borderRadius: 4,
      backgroundColor: defaultTheme.cardBorderColor
    }
  }),
  userCount: css({
    fontSize: 15,
    color: defaultTheme.subTextColor,
    padding: "0 1.5rem",
    display: "flex"
  }),
  name: css({
    fontSize: 15,
    color: `${defaultTheme.subTextColor}`
  }),
  icon: css({
    fontSize: 16,
    color: defaultTheme.subTextColor,
    marginLeft: ".5rem"
  }),
  descriptionRow: css({
    display: "flex",
    alignItems: "center"
  }),
  tag: css({
    marginLeft: "1rem"
  })
};

interface Props {
  policy: ExpensePolicyListItem;
  isDefault: boolean;
  onEdit: (policy: ExpensePolicyListItem) => void;
  onDelete: (policy: ExpensePolicyListItem) => void;
}

const PolicyItem = ({ policy, isDefault, onEdit, onDelete }: Props) => {
  const handleClick = () => onEdit(policy);

  return (
    <div className={styles.rowDiv} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}
      >
        <div className={styles.descriptionRow}>
          <span className={styles.name}>{policy.description}</span>
          {isDefault ? (
            <ColoredTag tagStyle={styles.tag}>Padrão</ColoredTag>
          ) : null}
        </div>
      </div>
      <Row style={{ width: "29%", justifyContent: "flex-end" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {policy.targetType !== TARGET_TYPES.COMPANY ? (
            <span className={styles.userCount}>
              {policy.targetCount}
              <PeopleIcon className={styles.icon} />
            </span>
          ) : (
            <span className={styles.userCount}>
              <BusinessIcon className={styles.icon} />
            </span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1.5rem"
          }}
        >
          <MoreActionsPopOver
            handleDelete={!isDefault ? onDelete : undefined}
            handleEdit={onEdit}
            item={policy}
          />
        </div>
      </Row>
    </div>
  );
};

export { PolicyItem };
