import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  list: {
    button: css({
      margin: "8px auto 0 auto",
      width: "fit-content",
    }),
    empty: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "0 32px",
      "@media (max-width: 1280px)": {
        padding: "0 24px",
      },
      "@media (max-width: 640px)": {
        padding: "0 16px",
      },
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    margin: "0 80px",
    padding: "32px 0",
    "@media (max-width: 1280px)": {
      margin: "0 24px",
      padding: "24px 0",
    },
    "@media (max-width: 992px)": {
      margin: "0 16px",
    },
    "@media (max-width: 640px)": {
      padding: "16px 0",
    },
  }),
  top: {
    controls: {
      label: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "24px",
        "@media (max-width: 900px)": {
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "16px",
        },
      }),
      switch: {
        root: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          gap: "12px",
          padding: "13px 16px",
          width: "fit-content",
        }),
        text: css({
          color: theme.colors.gray[300],
          fontWeight: 600,
        }),
      },
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "0 32px",
      "@media (max-width: 1280px)": {
        padding: "0 24px",
      },
      "@media (max-width: 640px)": {
        gap: "8px",
        padding: "0 16px",
      },
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "1.5rem",
      fontWeight: 600,
      width: "fit-content",
    }),
  },
};
