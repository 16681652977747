export const isDevEnv = () => {
  return NODE_ENV === "dev";
};

export const isHomologEnv = () => {
  return process.env.NODE_ENV === "homolog";
};

export const isMockableEnv = () => {
  return NODE_ENV !== "prod";
};

export const isProdEnv = () => {
  return process.env.NODE_ENV === "prod";
};
