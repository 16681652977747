import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  left: () =>
    StyleSheet.create({
      picture: {
        border: `1pt solid ${theme.colors.gray[100]}`,
        borderRadius: 4,
        minHeight: 144,
        objectFit: "cover",
        width: 144,
      },
      root: {
        display: "flex",
      },
    }),
  right: () =>
    StyleSheet.create({
      amenities: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
      bottom: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
      info: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 11,
        gap: 8,
      },
      name: {
        fontSize: 12,
        fontWeight: 700,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 24,
        justifyContent: "space-between",
      },
      stars: {
        color: theme.colors.pink[500],
        display: "flex",
        flexDirection: "row",
        gap: 6,
      },
      top: {
        display: "flex",
        borderRadius: 6,
        flexDirection: "column",
        gap: 4,
      },
    }),
  root: () =>
    StyleSheet.create({
      root: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
      },
    }),
};
