import React from "react";
import { ResponsiveRangeDatePicker } from "@shared/date-pickers/";
import moment from "moment";
import { css } from "emotion";
import uuid from "uuid";
import { defaultTheme } from "@assets/styles/theme";

const styles = {
  icon: css({
    fontSize: "16px",
    color: defaultTheme.subTextColor,
    padding: 0
  }),
  rangeDatePicker: css({
    maxHeight: 45,
    width: 267,
    marginTop: 0,
    padding: "7px 15px 7px 9px",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "4px",
    marginRight: "2rem"
  })
};

interface FinancialDatePickerProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  handleRangeDateChange: (date: moment.Moment) => void;
  rangeDatePickerFocus: any;
  handleChangeRangeDatePickerFocus: (focused: string) => void;
}

const isOutsideRange = (day: moment.Moment) => {
  return day > moment().endOf("day");
};

const FinancialDatePicker = ({
  startDate,
  endDate,
  handleRangeDateChange,
  rangeDatePickerFocus,
  handleChangeRangeDatePickerFocus
}: FinancialDatePickerProps) => {
  return (
    <div className={styles.rangeDatePicker} id="financial-date-picker">
      <ResponsiveRangeDatePicker
        datePickerProps={{
          startDate,
          startDateId: uuid(),
          startDatePlaceholderText: "Início",
          isOutsideRange,
          minimumNights: 0,
          endDate,
          endDateId: uuid(),
          endDatePlaceholderText: "Término",
          onDatesChange: handleRangeDateChange,
          focusedInput: rangeDatePickerFocus,
          onFocusChange: handleChangeRangeDatePickerFocus,
          disableScroll: false,
          noBorder: true,
          small: true,
          hideKeyboardShortcutsPanel: true,
          anchorDirection: "left"
        }}
      />
    </div>
  );
};

export { FinancialDatePicker };
