import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    button: css({
      width: "fit-content",
    }),
    root: css({
      width: "fit-content",
    }),
  },
  center: {
    bounds: css({
      display: "flex",
      gap: "16px",
      width: "100%",
      "@media (max-width: 640px)": {
        flexDirection: "column",
      },
    }),
    cabin: css({
      "& > :first-of-type": {
        minHeight: "auto",
        padding: "12px 16px",
        "& > :first-of-type": {
          "& > :first-of-type": {
            color: theme.colors.gray[700],
            fontFamily: theme.fonts.inter,
          },
        },
      },
    }),
    control: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      width: "100%",
    }),
    dates: css({}),
    input: {
      input: css({
        padding: 0,
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        padding: "12px 16px",
        display: "flex",
        gap: "12px",
        width: "100%",
      }),
    },
    label: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "28px",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
    type: {
      label: css({
        color: theme.colors.gray[700],
        cursor: "pointer",
        fontWeight: 600,
        marginLeft: "8px",
      }),
      radio: css({
        alignItems: "center",
        display: "flex",
        width: "fit-content",
        "& > :nth-child(2)": {
          cursor: "pointer",
        },
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "16px",
      }),
    },
  },
  location: {
    input: {
      input: css({
        border: "none",
        color: theme.colors.gray[700],
        fontFamily: theme.fonts.inter,
        outline: "none",
        textOverflow: "ellipsis",
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "12px",
        padding: "12px 16px",
        width: "100%",
      }),
    },
    suggestion: {
      button: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[500],
          cursor: "pointer",
          overflow: "hidden",
          padding: "12px 16px",
          transition: "all 0.15s ease-in-out",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          ":not(:disabled)": {
            ":hover": {
              backgroundColor: theme.colors.background.gray,
              color: theme.colors.gray[700],
            },
          },
        }),
      item: css({
        color: theme.colors.gray[500],
        display: "flex",
        fontSize: "0.875rem",
        padding: "12px 16px",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    padding: "12px 24px 24px 24px",
    position: "relative",
    width: "100%",
  }),
};
