import React from "react";
import { css } from "emotion";
import { defaultTheme } from "@assets/styles/theme";
import { Expense } from "@models/expense.model";
import { Column } from "@shared/layout/Column";
import { Row } from "@shared/layout/Row";
import * as utils from "@helpers";
import { StContainedButton } from "@shared/buttons";
import { MEDIA_QUERIES } from "@constants";
import classnames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import { UserModel } from "@models/user.model";
import { ExpensesBreakdown } from "../../shared/ExpensesBreakdown";
import { CategoryForm } from "../CategoryForm";
import { AdvancedExpense } from "@models/advanced-expense.model";
import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";
import { DeclineForm } from "../DeclineForm";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      borderRadius: 0,
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
    }
  }),
  titleSection: css({
    padding: "1.5rem 1.25rem",
    fontWeight: "bold",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  segment: css({
    padding: "1.5rem 1.25rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  actionSegment: css({
    padding: "1.5rem 1.25rem"
  }),
  actionDiv: css({
    display: "flex",
    justifyContent: "space-around"
  }),
  approveButton: css({
    color: "#FFFFFF",
    backgroundColor: defaultTheme.successTextColor,
    "&:hover": {
      backgroundColor: "#2da36c"
    },
    "&:disabled": {
      color: "rgba(255, 255, 255, 0.9)",
      backgroundColor: "rgba(51, 182, 121, 0.5)",
      cursor: "not-allowed"
    }
  }),
  buttons: css({
    padding: ".35rem 2rem",
    maxHeight: 34,
    width: 145,
    fontSize: 14,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: 120,
      padding: ".5rem 1rem"
    }
  }),
  totalValueRow: css({
    justifyContent: "space-between",
    fontSize: 15,
    fontWeight: "bold"
  }),
  denialLabel: css({
    marginBottom: "1rem",
    fontSize: 13,
    lineHeight: "21px",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12
    }
  }),
  disclaimerText: css({
    textAlign: "center",
    lineHeight: "15px",
    fontSize: 12,
    color: defaultTheme.subTextColor,
    marginTop: "1rem"
  })
};

interface Props {
  expenses: Expense[];
  advancedExpenses: AdvancedExpense[];
  showDeclineForm: boolean;
  showCategoryForm: boolean;
  loadingApprove: boolean;
  loadingDeny: boolean;
  isApproveEnabled: boolean;
  handleApprove: () => void;
  handleDeny: () => void;
}

const InfoSideCard = ({
  expenses,
  advancedExpenses,
  showDeclineForm,
  showCategoryForm,
  loadingApprove,
  loadingDeny,
  isApproveEnabled,
  handleApprove,
  handleDeny
}: Props) => {
  return (
    <Column className={styles.root}>
      <div className={styles.titleSection}>
        <span>Resumo da solicitação</span>
      </div>
      <section style={{ padding: "1rem 2rem" }}>
        <div style={{ paddingBottom: "1.5rem" }}>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>
            Serviços selecionados
          </p>
        </div>
        <ExpensesBreakdown
          containerClasses={styles.segment}
          expensesData={expenses}
          advancedExpensesData={advancedExpenses}
          firstColumnName={"Solicitado"}
          secondColumnName={"Aprovado"}
          showSecondColumn={true}
        >
          {(totalExpenseValue, approvedValue, totalAdvancedExpense) => (
            <div>
              <div>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14,
                    color: defaultTheme.grays.dark
                  }}
                >
                  <p>Total solicitado</p>
                  <p>{utils.toCurrency(totalExpenseValue)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14
                  }}
                >
                  <p>Total aprovado</p>
                  <p>{utils.toCurrency(approvedValue)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14,
                    color: defaultTheme.reds.lightRed
                  }}
                >
                  <p>Adiantamento</p>
                  <p>- {utils.toCurrency(totalAdvancedExpense)}</p>
                </Row>
              </div>
              <div style={{ paddingTop: ".75rem" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    fontSize: 14,
                    fontWeight: "bold"
                  }}
                >
                  <p>Total reembolso</p>
                  <p>
                    {utils.toCurrency(approvedValue - totalAdvancedExpense)}
                  </p>
                </Row>
              </div>
            </div>
          )}
        </ExpensesBreakdown>
      </section>
      <DeclineForm />
      {!showDeclineForm && (
        <React.Fragment>
          <PermissionVisibility
            permissions={[
              "companySettings.costCenters",
              "companySettings.billingProfiles",
              "companySettings.areas",
              "companySettings.projects"
            ]}
            atLeastOne={true}
          >
            <Collapse in={showCategoryForm}>
              <CategoryForm />
            </Collapse>
          </PermissionVisibility>
          <div className={styles.actionSegment}>
            <div className={styles.actionDiv}>
              <StContainedButton
                color="secondary"
                onClick={handleDeny}
                isFullWidth={false}
                className={styles.buttons}
                disabled={loadingDeny}
                loading={loadingDeny}
              >
                Negar
              </StContainedButton>
              <StContainedButton
                onClick={handleApprove}
                isFullWidth={false}
                className={classnames(styles.buttons, styles.approveButton)}
                disabled={!isApproveEnabled || loadingApprove}
                loading={loadingApprove}
              >
                Aprovar
              </StContainedButton>
            </div>
            <div className={styles.disclaimerText}>
              <span>
                Certifique-se de atribuir um valor a ser aprovado em todas as
                despesas antes de aprová-las
              </span>
            </div>
            <div />
          </div>
        </React.Fragment>
      )}
    </Column>
  );
};

export { InfoSideCard };
