import { DownloadTravelInvoicesResponse } from "../dtos/financial.dto";
import { TravelInvoices } from "../models/financial.model";

export const parseDownloadTravelInvoices = (
  dto: DownloadTravelInvoicesResponse,
) => {
  const travelInvoices: TravelInvoices = {
    url: dto.filename,
  };

  return travelInvoices;
};
