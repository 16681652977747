import { CountryRestrictionType } from "./enums";

export const COUNTRY_LIST = [
  {
    code: "BR",
    name: "Brasil",
  },
  {
    code: "AF",
    name: "Afeganistão",
  },
  {
    code: "AL",
    name: "Albânia",
  },
  {
    code: "DE",
    name: "Alemanha",
  },
  {
    code: "AD",
    name: "Andorra",
  },
  {
    code: "AO",
    name: "Angola",
  },
  {
    code: "AI",
    name: "Anguila",
  },
  {
    code: "AQ",
    name: "Antártida",
  },
  {
    code: "AG",
    name: "Antígua e Barbuda",
  },
  {
    code: "SA",
    name: "Arábia Saudita",
  },
  {
    code: "DZ",
    name: "Argélia",
  },
  {
    code: "AR",
    name: "Argentina",
  },
  {
    code: "AM",
    name: "Armênia",
  },
  {
    code: "AW",
    name: "Aruba",
  },
  {
    code: "AU",
    name: "Austrália",
  },
  {
    code: "AT",
    name: "Áustria",
  },
  {
    code: "AZ",
    name: "Azerbaijão",
  },
  {
    code: "BS",
    name: "Bahamas",
  },
  {
    code: "BH",
    name: "Bahrein",
  },
  {
    code: "BD",
    name: "Bangladesh",
  },
  {
    code: "BB",
    name: "Barbados",
  },
  {
    code: "BE",
    name: "Bélgica",
  },
  {
    code: "BZ",
    name: "Belize",
  },
  {
    code: "BJ",
    name: "Benin",
  },
  {
    code: "BM",
    name: "Bermudas",
  },
  {
    code: "BY",
    name: "Bielorrússia",
  },
  {
    code: "BO",
    name: "Bolívia",
  },
  {
    code: "BQ",
    name: "Bonaire",
  },
  {
    code: "BA",
    name: "Bósnia e Herzegovina",
  },
  {
    code: "BW",
    name: "Botsuana",
  },
  {
    code: "BN",
    name: "Brunei",
  },
  {
    code: "BG",
    name: "Bulgária",
  },
  {
    code: "BF",
    name: "Burkina Faso",
  },
  {
    code: "BI",
    name: "Burundi",
  },
  {
    code: "BT",
    name: "Butão",
  },
  {
    code: "CV",
    name: "Cabo Verde",
  },
  {
    code: "CM",
    name: "Camarões",
  },
  {
    code: "KH",
    name: "Camboja",
  },
  {
    code: "CA",
    name: "Canadá",
  },
  {
    code: "QA",
    name: "Catar",
  },
  {
    code: "KZ",
    name: "Cazaquistão",
  },
  {
    code: "TD",
    name: "Chade",
  },
  {
    code: "CL",
    name: "Chile",
  },
  {
    code: "CN",
    name: "China",
  },
  {
    code: "CY",
    name: "Chipre",
  },
  {
    code: "CO",
    name: "Colômbia",
  },
  {
    code: "KM",
    name: "Comores",
  },
  {
    code: "CG",
    name: "Congo",
  },
  {
    code: "KP",
    name: "Coreia do Norte",
  },
  {
    code: "KR",
    name: "Coreia do Sul",
  },
  {
    code: "CI",
    name: "Costa do Marfim",
  },
  {
    code: "CR",
    name: "Costa Rica",
  },
  {
    code: "HR",
    name: "Croácia",
  },
  {
    code: "CU",
    name: "Cuba",
  },
  {
    code: "CW",
    name: "Curaçao",
  },
  {
    code: "DK",
    name: "Dinamarca",
  },
  {
    code: "DJ",
    name: "Djibuti",
  },
  {
    code: "DM",
    name: "Dominica",
  },
  {
    code: "EG",
    name: "Egito",
  },
  {
    code: "SV",
    name: "El Salvador",
  },
  {
    code: "AE",
    name: "Emirados Árabes Unidos",
  },
  {
    code: "EC",
    name: "Equador",
  },
  {
    code: "ER",
    name: "Eritreia",
  },
  {
    code: "SK",
    name: "Eslováquia",
  },
  {
    code: "SI",
    name: "Eslovênia",
  },
  {
    code: "ES",
    name: "Espanha",
  },
  {
    code: "US",
    name: "Estados Unidos",
  },
  {
    code: "EE",
    name: "Estônia",
  },
  {
    code: "ET",
    name: "Etiópia",
  },
  {
    code: "FJ",
    name: "Fiji",
  },
  {
    code: "PH",
    name: "Filipinas",
  },
  {
    code: "FI",
    name: "Finlândia",
  },
  {
    code: "FR",
    name: "França",
  },
  {
    code: "GA",
    name: "Gabão",
  },
  {
    code: "GM",
    name: "Gâmbia",
  },
  {
    code: "GH",
    name: "Gana",
  },
  {
    code: "GE",
    name: "Geórgia",
  },
  {
    code: "GI",
    name: "Gibraltar",
  },
  {
    code: "GD",
    name: "Granada",
  },
  {
    code: "GR",
    name: "Grécia",
  },
  {
    code: "GL",
    name: "Groelândia",
  },
  {
    code: "GP",
    name: "Guadalupe",
  },
  {
    code: "GU",
    name: "Guam",
  },
  {
    code: "GT",
    name: "Guatemala",
  },
  {
    code: "GG",
    name: "Guernsey",
  },
  {
    code: "GF",
    name: "Guiana Francesa",
  },
  {
    code: "GY",
    name: "Guiana",
  },
  {
    code: "GQ",
    name: "Guiné Equatorial",
  },
  {
    code: "GW",
    name: "Guiné-Bissau",
  },
  {
    code: "GN",
    name: "Guiné",
  },
  {
    code: "HT",
    name: "Haiti",
  },
  {
    code: "NL",
    name: "Holanda",
  },
  {
    code: "HN",
    name: "Honduras",
  },
  {
    code: "HK",
    name: "Hong Kong",
  },
  {
    code: "HU",
    name: "Hungria",
  },
  {
    code: "YE",
    name: "Iêmen",
  },
  {
    code: "BV",
    name: "Ilha Bouvet",
  },
  {
    code: "CX",
    name: "Ilha Christmas",
  },
  {
    code: "IM",
    name: "Ilha de Man",
  },
  {
    code: "HM",
    name: "Ilha Heard e Ilhas McDonald",
  },
  {
    code: "NF",
    name: "Ilha Norfolk",
  },
  {
    code: "KY",
    name: "Ilhas Cayman",
  },
  {
    code: "CC",
    name: "Ilhas Cocos",
  },
  {
    code: "CK",
    name: "Ilhas Cook",
  },
  {
    code: "AX",
    name: "Ilhas de Aland",
  },
  {
    code: "FO",
    name: "Ilhas Faroé",
  },
  {
    code: "GS",
    name: "Ilhas Geórgias do Sul e Sandwich do Sul",
  },
  {
    code: "FK",
    name: "Ilhas Malvinas",
  },
  {
    code: "MP",
    name: "Ilhas Marianas",
  },
  {
    code: "MH",
    name: "Ilhas Marshall",
  },
  {
    code: "UM",
    name: "Ilhas Menores Distantes dos Estados Unidos",
  },
  {
    code: "PN",
    name: "Ilhas Pitcairn",
  },
  {
    code: "SB",
    name: "Ilhas Salomão",
  },
  {
    code: "TC",
    name: "Ilhas Turcas e Caicos",
  },
  {
    code: "VI",
    name: "Ilhas Virgens Americanas",
  },
  {
    code: "VG",
    name: "Ilhas Virgens Britânicas",
  },
  {
    code: "IN",
    name: "Índia",
  },
  {
    code: "ID",
    name: "Indonésia",
  },
  {
    code: "IR",
    name: "Irã",
  },
  {
    code: "IQ",
    name: "Iraque",
  },
  {
    code: "IE",
    name: "Irlanda",
  },
  {
    code: "IS",
    name: "Islândia",
  },
  {
    code: "IL",
    name: "Israel",
  },
  {
    code: "IT",
    name: "Itália",
  },
  {
    code: "JM",
    name: "Jamaica",
  },
  {
    code: "JP",
    name: "Japão",
  },
  {
    code: "JE",
    name: "Jersey",
  },
  {
    code: "JO",
    name: "Jordânia",
  },
  {
    code: "KI",
    name: "Kiribati",
  },
  {
    code: "XK",
    name: "Kosovo",
  },
  {
    code: "KW",
    name: "Kuwait",
  },
  {
    code: "LA",
    name: "Laos",
  },
  {
    code: "LS",
    name: "Lesoto",
  },
  {
    code: "LV",
    name: "Letônia",
  },
  {
    code: "LB",
    name: "Líbano",
  },
  {
    code: "LR",
    name: "Libéria",
  },
  {
    code: "LY",
    name: "Líbia",
  },
  {
    code: "LI",
    name: "Liechtenstein",
  },
  {
    code: "LT",
    name: "Lituânia",
  },
  {
    code: "LU",
    name: "Luxemburgo",
  },
  {
    code: "MO",
    name: "Macau",
  },
  {
    code: "MK",
    name: "Macedônia",
  },
  {
    code: "MG",
    name: "Madagascar",
  },
  {
    code: "MY",
    name: "Malásia",
  },
  {
    code: "MW",
    name: "Malawi",
  },
  {
    code: "MV",
    name: "Maldivas",
  },
  {
    code: "ML",
    name: "Mali",
  },
  {
    code: "MT",
    name: "Malta",
  },
  {
    code: "MA",
    name: "Marrocos",
  },
  {
    code: "MQ",
    name: "Martinica",
  },
  {
    code: "MU",
    name: "Maurício",
  },
  {
    code: "MR",
    name: "Mauritânia",
  },
  {
    code: "YT",
    name: "Mayotte",
  },
  {
    code: "MX",
    name: "México",
  },
  {
    code: "FM",
    name: "Micronésia",
  },
  {
    code: "MZ",
    name: "Moçambique",
  },
  {
    code: "MD",
    name: "Moldávia",
  },
  {
    code: "MC",
    name: "Mônaco",
  },
  {
    code: "MN",
    name: "Mongólia",
  },
  {
    code: "ME",
    name: "Montenegro",
  },
  {
    code: "MS",
    name: "Montserrat",
  },
  {
    code: "MM",
    name: "Myanmar",
  },
  {
    code: "NA",
    name: "Namíbia",
  },
  {
    code: "NR",
    name: "Nauru",
  },
  {
    code: "NP",
    name: "Nepal",
  },
  {
    code: "NI",
    name: "Nicarágua",
  },
  {
    code: "NE",
    name: "Níger",
  },
  {
    code: "NG",
    name: "Nigéria",
  },
  {
    code: "NU",
    name: "Niue",
  },
  {
    code: "NO",
    name: "Noruega",
  },
  {
    code: "NC",
    name: "Nova Caledônia",
  },
  {
    code: "NZ",
    name: "Nova Zelândia",
  },
  {
    code: "OM",
    name: "Omã",
  },
  {
    code: "PW",
    name: "Palau",
  },
  {
    code: "PS",
    name: "Palestina",
  },
  {
    code: "PA",
    name: "Panamá",
  },
  {
    code: "PG",
    name: "Papua Nova Guiné",
  },
  {
    code: "PK",
    name: "Paquistão",
  },
  {
    code: "PY",
    name: "Paraguai",
  },
  {
    code: "PE",
    name: "Peru",
  },
  {
    code: "PF",
    name: "Polinésia Francesa",
  },
  {
    code: "PL",
    name: "Polônia",
  },
  {
    code: "PR",
    name: "Porto Rico",
  },
  {
    code: "PT",
    name: "Portugal",
  },
  {
    code: "KE",
    name: "Quênia",
  },
  {
    code: "KG",
    name: "Quirguistão",
  },
  {
    code: "CD",
    name: "RD Congo",
  },
  {
    code: "GB",
    name: "Reino Unido",
  },
  {
    code: "CF",
    name: "República Centro-Africana",
  },
  {
    code: "DO",
    name: "República Dominicana",
  },
  {
    code: "ZA",
    name: "República Sul-Africana",
  },
  {
    code: "CZ",
    name: "República Tcheca",
  },
  {
    code: "RE",
    name: "Reunião",
  },
  {
    code: "RO",
    name: "Romênia",
  },
  {
    code: "RW",
    name: "Ruanda",
  },
  {
    code: "RU",
    name: "Rússia",
  },
  {
    code: "EH",
    name: "Saara Ocidental",
  },
  {
    code: "MF",
    name: "Saint Martin",
  },
  {
    code: "PM",
    name: "Saint-Pierre e Miquelon",
  },
  {
    code: "AS",
    name: "Samoa Americana",
  },
  {
    code: "WS",
    name: "Samoa",
  },
  {
    code: "SM",
    name: "San Marino",
  },
  {
    code: "SH",
    name: "Santa Helena",
  },
  {
    code: "LC",
    name: "Santa Lúcia",
  },
  {
    code: "BL",
    name: "São Bartolomeu",
  },
  {
    code: "KN",
    name: "São Cristóvão e Neves",
  },
  {
    code: "ST",
    name: "São Tomé e Príncipe",
  },
  {
    code: "VC",
    name: "São Vicente e Granadinas",
  },
  {
    code: "SC",
    name: "Seicheles",
  },
  {
    code: "SN",
    name: "Senegal",
  },
  {
    code: "SL",
    name: "Serra Leoa",
  },
  {
    code: "RS",
    name: "Sérvia",
  },
  {
    code: "SG",
    name: "Singapura",
  },
  {
    code: "SX",
    name: "Sint Maarten",
  },
  {
    code: "SY",
    name: "Síria",
  },
  {
    code: "SO",
    name: "Somália",
  },
  {
    code: "LK",
    name: "Sri Lanka",
  },
  {
    code: "SZ",
    name: "Suazilândia",
  },
  {
    code: "SS",
    name: "Sudão do Sul",
  },
  {
    code: "SD",
    name: "Sudão",
  },
  {
    code: "SE",
    name: "Suécia",
  },
  {
    code: "CH",
    name: "Suíça",
  },
  {
    code: "SR",
    name: "Suriname",
  },
  {
    code: "SJ",
    name: "Svalbard",
  },
  {
    code: "TH",
    name: "Tailândia",
  },
  {
    code: "TW",
    name: "Taiwan",
  },
  {
    code: "TJ",
    name: "Tajiquistão",
  },
  {
    code: "TZ",
    name: "Tanzânia",
  },
  {
    code: "TF",
    name: "Terras Austrais e Antárticas Francesas",
  },
  {
    code: "IO",
    name: "Território Britânico do Oceano íÍdico",
  },
  {
    code: "TL",
    name: "Timor Leste",
  },
  {
    code: "TG",
    name: "Togo",
  },
  {
    code: "TK",
    name: "Tokelau",
  },
  {
    code: "TO",
    name: "Tonga",
  },
  {
    code: "TT",
    name: "Trinidad e Tobago",
  },
  {
    code: "TN",
    name: "Tunísia",
  },
  {
    code: "TM",
    name: "Turcomenistão",
  },
  {
    code: "TR",
    name: "Turquia",
  },
  {
    code: "TV",
    name: "Tuvalu",
  },
  {
    code: "UA",
    name: "Ucrânia",
  },
  {
    code: "UG",
    name: "Uganda",
  },
  {
    code: "UY",
    name: "Uruguai",
  },
  {
    code: "UZ",
    name: "Uzbequistão",
  },
  {
    code: "VU",
    name: "Vanuatu",
  },
  {
    code: "VA",
    name: "Vaticano",
  },
  {
    code: "VE",
    name: "Venezuela",
  },
  {
    code: "VN",
    name: "Vietnã",
  },
  {
    code: "WF",
    name: "Wallis e Futuna",
  },
  {
    code: "ZM",
    name: "Zâmbia",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
  },
];

export const COUNTRY_RESTRICTION_MESSAGES = {
  [CountryRestrictionType.BLOCK_ALL]:
    "Bloquear todas as viagens para este país.",
  [CountryRestrictionType.BLOCK_ABROAD]:
    "Permitir somente viagens dentro do país.",
  [CountryRestrictionType.ALLOW_WITH_WARNING]:
    "Permitir todas as viagens, mas com aviso.",
} as const;
