import { TravelCustomField } from "@dtos/client.dto";

export type CustomFieldOption = GetClientCustomFieldOptionsResponse;

export interface CustomFieldOptionsResponseDto {
  active: boolean;
  client_token: string;
  name: string;
  travel_custom_field: TravelCustomField;
}

export enum CustomFieldOptionsType {
  FLIGHT_PURPOSE = "FLIGHT_PURPOSE",
  HOTEL_PURPOSE = "HOTEL_PURPOSE",
  TRIP_PURPOSE = "TRIP_PURPOSE",
}

export type GetClientCustomFieldOptionsResponse = CustomFieldOptionsResponseDto & {
  created_at: string;
  custom_field_option_token: string;
  updated_at: string;
};

export type SubmitCustomFieldOptionsRequestBody = CustomFieldOptionsResponseDto;
