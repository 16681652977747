import { css } from "@styled-system/css";

export const styles = {
  fullscreen: css({
    bottom: "24px",
    left: "24px",
    position: "absolute",
    zIndex: 1,
  }),
  nav: {
    left: css({
      backgroundColor: "transparent !important",
      border: "none !important",
      left: "8px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
    }),
    right: css({
      backgroundColor: "transparent !important",
      border: "none !important",
      position: "absolute",
      right: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
    }),
  },
};
