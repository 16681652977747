import React, { useState } from "react";

import { getBusSuggestions } from "~/apps/corporate/apis/autosuggest.api";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";

import { Button, CircularSpinner } from "@toolkit/v2";

import { DatePicker } from "../../../../shared/components/date-picker/date-picker";
import { Form, useForm } from "../../../../shared/components/form/form";
import { Icon } from "../../../../shared/components/icon/icon";
import { InputErrorMessage } from "../../../../shared/components/input-error-message/input-error-message";
import {
  BusFormSchema,
  busFormSchema,
} from "../../offer-searcher/bus-form/bus-form.schema";
import { useOfferSearcher } from "../../offer-searcher/offer-searcher.context";
import { BusesSearch } from "../../offer-searcher/offer-searcher.types";
import { styles } from "./styles";

type Props = {
  data: Partial<BusesSearch> | null;
  onClose: () => void;
  travelToken: string;
};

export const OfferSearcherDialogBusForm: React.FC<Props> = ({
  data,
  onClose,
  travelToken,
}) => {
  const { handleSearchBuses } = useOfferSearcher();

  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);

  const form = useForm<BusFormSchema>({
    defaultValues: data
      ? {
          destination: data.destination ? data.destination : null,
          departureDate: data.departureDate ? data.departureDate : null,
          origin: data.origin ? data.origin : null,
          returnDate: data.returnDate ? data.returnDate : null,
          type: data.type ? data.type : "roundtrip",
        }
      : {
          destination: null,
          departureDate: null,
          origin: null,
          returnDate: null,
          type: "roundtrip",
        },
    onSubmit: async () => {
      const values = form.getValues();

      await handleSearchBuses({
        destination: values.destination,
        departureDate: values.departureDate,
        origin: values.origin,
        returnDate: values.returnDate,
        travelToken,
        type: values.type,
      });

      onClose();
    },
    schema: busFormSchema,
  });

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.center.root}>
        <div css={styles.center.control}>
          <div css={styles.center.type.root}>
            <label css={styles.center.type.radio}>
              <Checkbox
                checked={form.watch("type") === "oneway"}
                onChange={() => {
                  form.setValue("type", "oneway");

                  if (form.watch("type") === "oneway") {
                    form.clearErrors("returnDate");
                  }
                }}
                variant="pink"
              />
              <span css={styles.center.type.label}>Só ida</span>
            </label>
            <label css={styles.center.type.radio}>
              <Checkbox
                checked={form.watch("type") === "roundtrip"}
                onChange={() => {
                  form.setValue("type", "roundtrip");
                }}
                variant="pink"
              />
              <span css={styles.center.type.label}>Ida e volta</span>
            </label>
          </div>
          <InputErrorMessage>{form.errors["type"]?.message}</InputErrorMessage>
        </div>
        <div css={styles.center.bounds}>
          <div css={styles.center.control}>
            <span css={styles.center.label}>Origem</span>
            <AsyncAutocomplete
              css={styles.center.input.input}
              defaultValue={form.watch("origin")?.label}
              fetchItems={async (search) => {
                try {
                  return {
                    data: await getBusSuggestions(search),
                  };
                } catch (error) {
                  return {
                    error,
                  };
                }
              }}
              inputProps={{
                placeholder: "Digite o local de origem...",
              }}
              onSelect={(location) => {
                if (!location) {
                  form.setValue("origin", null);

                  return;
                }

                form.setValue("origin", location);
              }}
              renderInput={({ loading, ...props }) => (
                <div css={styles.location.input.root}>
                  <Icon size={20} use="bus" />
                  <input css={styles.location.input.input} {...props} />
                  {loading ? <CircularSpinner size={16} /> : null}
                </div>
              )}
              renderItem={({ isHighlighted, item, ...props }) => {
                if (item.error) {
                  return (
                    <li
                      css={styles.location.suggestion.item}
                      key={0}
                      {...props}
                    >
                      Nenhum local encontrado
                    </li>
                  );
                }

                return (
                  <li
                    css={styles.location.suggestion.button({ isHighlighted })}
                    key={JSON.stringify(item)}
                    {...props}
                  >
                    {item.label}
                  </li>
                );
              }}
            />
            <InputErrorMessage>
              {form.errors["origin"]?.message}
            </InputErrorMessage>
          </div>

          <div css={styles.center.control}>
            <span css={styles.center.label}>Destino</span>
            <AsyncAutocomplete
              css={styles.center.input.input}
              defaultValue={form.watch("destination")?.label}
              fetchItems={async (search) => {
                try {
                  return {
                    data: await getBusSuggestions(search),
                  };
                } catch (error) {
                  return {
                    error,
                  };
                }
              }}
              inputProps={{
                placeholder: "Digite o local de destino...",
              }}
              onSelect={(location) => {
                if (!location) {
                  form.setValue("destination", null);

                  return;
                }

                form.setValue("destination", location);
              }}
              renderInput={({ loading, ...props }) => (
                <div css={styles.location.input.root}>
                  <Icon size={20} use="bus" />
                  <input css={styles.location.input.input} {...props} />
                  {loading ? <CircularSpinner size={16} /> : null}
                </div>
              )}
              renderItem={({ isHighlighted, item, ...props }) => {
                if (item.error) {
                  return (
                    <li
                      css={styles.location.suggestion.item}
                      key={0}
                      {...props}
                    >
                      Nenhum local encontrado
                    </li>
                  );
                }

                return (
                  <li
                    css={styles.location.suggestion.button({ isHighlighted })}
                    key={JSON.stringify(item)}
                    {...props}
                  >
                    {item.label}
                  </li>
                );
              }}
            />
            <InputErrorMessage>
              {form.errors["destination"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.center.control}>
          <span css={styles.center.label}>
            {form.watch("type") === "oneway" ? "Partida" : "Ida - Volta"}
          </span>
          <div css={styles.center.dates}>
            {form.watch("type") === "oneway" ? (
              <DatePicker
                date={form.watch("departureDate")}
                focused={rangeDateFocus === "startDate"}
                id="departureDate"
                onDateChange={(date) => {
                  form.setValue("departureDate", date);

                  if (date) {
                    form.clearErrors("departureDate");
                  }
                }}
                onFocusChange={({ focused }) => {
                  setRangeFocus(focused ? "startDate" : null);
                }}
                placeholder="Ida"
                range={false}
                renderCalendarInfo={undefined}
              />
            ) : (
              <DatePicker
                allowSameDay
                customArrowIcon={<Icon size={16} use="calendar" />}
                endDate={form.watch("returnDate")}
                endDatePlaceholderText="volta"
                focusedInput={rangeDateFocus}
                onDatesChange={(date) => {
                  form.setValue("departureDate", date.startDate);

                  if (date.startDate) {
                    form.clearErrors("departureDate");
                  }

                  form.setValue("returnDate", date.endDate);

                  if (date.endDate) {
                    form.clearErrors("returnDate");
                  }
                }}
                onFocusChange={(focused) => {
                  setRangeFocus(focused);
                }}
                range
                renderCalendarInfo={undefined}
                startDate={form.watch("departureDate")}
                startDatePlaceholderText="Ida"
              />
            )}
          </div>
          <InputErrorMessage>
            {form.errors["departureDate"]?.message ||
              form.errors["returnDate"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.bottom.root}>
        <Button css={styles.bottom.button} variant="pink">
          Pesquisar ônibus
        </Button>
      </div>
    </Form>
  );
};
