import * as expenseCategoriesParser from "~/parsers/expense-categories.parser";
import { AxiosResponse } from "axios";

import {
  ExpenseCategoryDto,
  ExpenseCategoriesOptionsDto
} from "@dtos/expense-categories.dto";

import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import { api } from "./interceptors";

export function getClientExpensesCategories() {
  return api
    .request({
      url: `/booking/client/${getClientToken()}/expense-categories`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseCategoryDto[]>) => {
      return expenseCategoriesParser.parseClientExpenseCategories(
        response.data
      );
    });
}

export function createExpenseCategory(data: Partial<ExpenseCategoryDto>) {
  return api
    .request({
      url: `/booking/expense-category`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data
    })
    .then((response: AxiosResponse<ExpenseCategoryDto>) => {
      return expenseCategoriesParser.parseClientExpenseCategory(response.data);
    });
}

export function updateExpenseCategory(
  expenseCategoryToken: string,
  data: Partial<ExpenseCategoryDto>
) {
  return api
    .request({
      url: `/booking/expense-category/${expenseCategoryToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data
    })
    .then((response: AxiosResponse<ExpenseCategoryDto>) => {
      return expenseCategoriesParser.parseClientExpenseCategory(response.data);
    });
}

export function getExpenseCategoriesOptions() {
  return api
    .request({
      url: `/booking/client/${getClientToken()}/expense-categories-options`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseCategoriesOptionsDto[]>) => {
      return expenseCategoriesParser.parseClientExpenseCategoriesOptions(
        response.data
      );
    });
}
