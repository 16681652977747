import React, { useState } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckedCheboxIcon from "@material-ui/icons/CheckBoxRounded";
import MoreIcon from "@material-ui/icons/MoreVert";
import { css } from "emotion";

import * as expensesHelper from "@helpers/expense.helper";

import { defaultTheme } from "../../../../assets/styles/theme";
import { colors } from "../../../../assets/styles/theme/constants";
import { EXPENSES_TYPES_TRANSLATION } from "../../../../constants";
import * as utils from "../../../../helpers";
import { Expense } from "../../../../models/expense.model";
import { Column } from "../../../shared/layout/Column";
import { Row } from "../../../shared/layout/Row";
import { CoinsTooltip } from "~/components/shared/coins-tooltip";

const styles = {
  root: css({
    padding: ".5rem 0",
    fontSize: 12,
    color: defaultTheme.subTextColor,
    justifyContent: "space-between",
    ["@media (min-width: 768px)"]: {
      fontSize: 14
    }
  }),
  middleExpense: css({
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  description: css({
    fontWeight: "bold"
  }),
  type: css({
    textTransform: "capitalize",
    fontWeight: "bold",
    paddingBottom: ".25rem"
  }),
  rRow: css({
    alignItems: "center",
    minWidth: 100
  }),
  dateAndValueDiv: css({
    alignItems: "flex-end",
    paddingRight: ".25rem"
  }),
  date: css({
    paddingBottom: ".25rem"
  }),
  value: css({
    fontWeight: "bold"
  }),
  checkbox: css({
    color: defaultTheme.cardBorderColor,
    fontSize: 30
  }),
  checkedCheckbox: css({
    color: `${defaultTheme.primaryColor} !important`,
    fontSize: 30
  }),
  checkboxSecondaryColor: css({
    color: `${defaultTheme.primaryColor} !important`
  }),
  menuItem: css({
    fontFamily: "'Open sans', sans-seriff",
    fontSize: 14,
    padding: ".5rem 1rem",
    ["&:hover"]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor
    },
    ["@media (min-width: 768px)"]: {
      fontSize: 16
    }
  })
};

interface Props {
  expense: Expense;
  containerStyle?: string;
  isLast?: boolean;
  isChecked: boolean;
  handleChange: (checked: boolean) => void;
  handleEdit: () => void;
  handleDelete: () => void;
}

const MobileExpenseItem = ({
  expense,
  isLast,
  isChecked,
  handleChange,
  handleEdit,
  handleDelete
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const innerHandleChange = (_: any, checked: boolean) => {
    handleChange(checked);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const convertedValue = expensesHelper.convertCurrencyValue(expense);

  const isForeignCurrencyExpense = expensesHelper.isForeignCurrencyExpense(
    expense
  );

  return (
    <Row className={`${styles.root} ${!isLast ? styles.middleExpense : ""}`}>
      <Row style={{ alignItems: "center" }}>
        <Column>
          <Checkbox
            checked={isChecked}
            onChange={innerHandleChange}
            icon={<CheckboxIcon className={styles.checkbox} />}
            checkedIcon={
              <CheckedCheboxIcon className={styles.checkedCheckbox} />
            }
            classes={{
              colorSecondary: styles.checkboxSecondaryColor
            }}
            inputProps={{
              "aria-label": "Selecionar despesa"
            }}
          />
        </Column>
        <Column>
          <p
            className={styles.type}
            style={{
              color: colors.white,
              backgroundColor: expense.expenseCategoryColor
            }}
          >
            {expense.expenseCategoryName}
          </p>
          <p className={styles.description}>{expense.description}</p>
        </Column>
        {isForeignCurrencyExpense ? (
          <div style={{ marginLeft: "10px" }}>
            <CoinsTooltip
              placement="right"
              title="Despesa em moeda estrangeira."
              iconSize="14px"
            />
          </div>
        ) : null}
      </Row>
      <Row className={styles.rRow}>
        <Column className={styles.dateAndValueDiv}>
          <p className={styles.date}>{expense.date.format("DD MMM YYYY")}</p>
          <p className={styles.value}>{utils.toCurrency(convertedValue)}</p>
        </Column>
        <Column>
          <Column
            onClick={handleClick}
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
          >
            <MoreIcon />
          </Column>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseOption}
          >
            <MenuItem
              classes={{
                root: styles.menuItem
              }}
              onClick={handleEdit}
            >
              Editar
            </MenuItem>
            <MenuItem
              classes={{
                root: styles.menuItem
              }}
              onClick={handleDelete}
            >
              Deletar
            </MenuItem>
          </Menu>
        </Column>
      </Row>
    </Row>
  );
};

export { MobileExpenseItem };
