import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  input: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    padding: "13px 16px",
    width: "288px",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
};
