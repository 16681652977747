import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/HelpRounded";
import { css } from "@styled-system/css";

import { Box, Flex } from "@toolkit";
import { StContainedButton } from "~/components/shared/buttons";
import { Desktop } from "~/components/shared/layout/index";
import { SearchInput } from "@shared/inputs";
import styled from "@shared/styled";

const styles = {
  tooltip: css({
    [".MuiTooltip-tooltip"]: {
      backgroundColor: "#F7F8FD",
      color: "#8994A4",
      fontSize: 1,
      width: "100%",
      maxWidth: 370,
      padding: "small"
    }
  }),
  helpIcon: css({
    marginLeft: "small",
    color: "#D5DEE8",
    fontSize: [3, 4],
    position: "relative"
  }),
  button: css({
    ["&.MuiButton-root"]: {
      py: "small",
      px: 24
    }
  })
};

const StyledTooltip = styled(props => (
  <Tooltip {...props} classes={{ popper: props.className, tooltip: "tooltip" }}>
    <HelpIcon css={styles.helpIcon} />
  </Tooltip>
))(styles.tooltip);

interface IProps {
  allowReportPaymentSelection: boolean;
  hasAnyReportSelected: boolean;
  handleSearchChange(search: string): void;
  handleSignalPayment(): void;
}

const ListHeader: React.FC<IProps> = ({
  allowReportPaymentSelection,
  hasAnyReportSelected,
  handleSearchChange,
  handleSignalPayment
}) => {
  return (
    <Box>
      <Desktop>
        <Box maxWidth={350} mb="large">
          <SearchInput
            inputProps={{
              placeholder: "Busca por usuário ou descrição"
            }}
            handleChange={handleSearchChange}
          />
        </Box>
      </Desktop>
      {allowReportPaymentSelection && (
        <Flex alignItems="center" mb="large">
          <StContainedButton
            color="primary"
            onClick={handleSignalPayment}
            disabled={!hasAnyReportSelected}
            isFullWidth={false}
            css={styles.button}
          >
            Pagar relatórios selecionados
          </StContainedButton>
          <StyledTooltip
            title="Selecione ao menos um relatório para realizar a baixa de pagamento"
            placement="right"
          />
        </Flex>
      )}
    </Box>
  );
};

export { ListHeader };
