import React, { useRef, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";

import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import {
  ALERT_TYPES,
  DEFAULT_COMPANY_BOARD_NAME,
} from "~/apps/shared/constants";

import { theme } from "@skin/v2";

import * as boardService from "../boards.service";

export const styles = {
  input: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    padding: "13px 16px",
    width: "288px",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
    fontWeight: 600,
    marginBottom: "4px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
};

const BOARD_NAME_LENGTH = {
  MIN: 2,
  MAX: 16,
};

export const BoardName = () => {
  const { showSnackMessage } = useApplication();
  const {
    clientConfig,
    handleUpdateCompanyBoardNameDisplay,
  } = useClientConfig();

  const [isValid, setIsValid] = useState({
    error: false,
    errorMessage: "",
  });
  const [name, setName] = useState("");

  const isFirstRender = useRef(true);
  const nameAlreadyDerivedFromClientConfig = useRef(false);

  const clientConfigToken = clientConfig ? clientConfig.getToken() : null;

  const companyBoardDisplay =
    clientConfig && clientConfig.getCompanyBoardDisplay();

  const handleBlur = () => {
    if (name.length < BOARD_NAME_LENGTH.MIN) {
      setIsValid({
        error: true,
        errorMessage: `O nome deve ter ao menos ${BOARD_NAME_LENGTH.MIN} caracteres.`,
      });

      return;
    }

    if (name.length > BOARD_NAME_LENGTH.MAX) {
      setIsValid({
        error: true,
        errorMessage: `O nome deve ter no máximo ${BOARD_NAME_LENGTH.MAX} caracteres.`,
      });

      return;
    }

    setIsValid({
      error: false,
      errorMessage: "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setName(e.target.value);
  };

  React.useEffect(() => {
    if (companyBoardDisplay && !nameAlreadyDerivedFromClientConfig.current) {
      setName(companyBoardDisplay);
      nameAlreadyDerivedFromClientConfig.current = true;
    }
  }, [companyBoardDisplay]);

  useDebounce(
    async () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;

        return;
      }

      if (!name.length) {
        return;
      }

      if (
        companyBoardDisplay === DEFAULT_COMPANY_BOARD_NAME &&
        (name.length < BOARD_NAME_LENGTH.MIN ||
          name.length > BOARD_NAME_LENGTH.MAX)
      ) {
        return;
      }

      if (!clientConfigToken) {
        return;
      }

      const { error } =
        name.length < BOARD_NAME_LENGTH.MIN ||
        name.length > BOARD_NAME_LENGTH.MAX
          ? await boardService.updateBoardDisplayNameFromClientConfig(
              clientConfigToken,
              DEFAULT_COMPANY_BOARD_NAME,
            )
          : await boardService.updateBoardDisplayNameFromClientConfig(
              clientConfigToken,
              name,
            );
      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);
      }

      name.length < BOARD_NAME_LENGTH.MIN || name.length > BOARD_NAME_LENGTH.MAX
        ? handleUpdateCompanyBoardNameDisplay(DEFAULT_COMPANY_BOARD_NAME)
        : handleUpdateCompanyBoardNameDisplay(name);
    },
    300,
    [name],
  );

  return (
    <div css={styles.root}>
      <span css={styles.label}>Nome personalizado</span>
      <Input
        css={styles.input}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Escolha um nome"
        value={name}
      />
      {isValid.error ? (
        <InputErrorMessage>{isValid.errorMessage}</InputErrorMessage>
      ) : null}
    </div>
  );
};
