import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
  }),
  error: css({
    alignItems: "center",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
  }),
  root: css({
    flexGrow: 1,
    padding: "0 80px",
    "@media (max-width: 1280px)": {
      padding: "0 24px",
    },
    "@media (max-width: 992px)": {
      padding: "0 16px",
    },
  }),
};
