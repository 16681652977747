import React from "react";

import { darken } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";
import * as yup from "yup";

import { theme } from "@skin/v2";

import { Flex } from "@toolkit";

import { Autocomplete } from "@shared/autocomplete/AutocompleteAsync";
import { Input } from "@shared/inputs/Input";
import { Column } from "@shared/layout/Column";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { Form, useForm } from "@components/shared/form";
import { Row } from "@components/shared/layout";

import { useBoards } from "../../boards.context";
import * as boardService from "../../boards.service";
import { SelectedBoard } from "../board-drawer";

const styles = {
  form: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  }),
  input: {
    label: css({
      fontSize: "1rem",
      fontWeight: 600,
      color: theme.colors.gray[700],
      paddingBottom: "0.5rem",
      marginBottom: "0.5rem",
    }),
    outline: css({
      paddingLeft: "0 !important",
    }),
    container: css({
      [".MuiInputBase-root"]: {
        borderRadius: "0.5rem",
      },
      [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
        borderColor: `${theme.colors.gray[500]} !important`,
        color: theme.colors.gray[500],
      },
    }),
  },
  areaItem: css({
    display: "flex",
    gap: "1.25rem",
    alignItems: "center",
    width: "fit-content",
    marginTop: "1rem",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.colors.background.gray,
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
  }),
  icon: {
    root: css({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "1.25rem",
      height: "1.25rem",
      backgroundColor: theme.colors.gray[500],
      borderRadius: "0.25rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: darken(theme.colors.gray[500], 0.5),
        transition: "200ms",
      },
    }),
    svg: css({
      color: theme.colors.white,
      fontSize: "1.125rem",
    }),
  },
  emptyAreas: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: theme.colors.gray[400],
  }),
  saveButton: css({
    display: "flex",
    justifyContent: "center",
    width: "8.5rem",
  }),
};

const schema = yup.object().shape({
  boardName: yup
    .string()
    .min(2, "Esse campo requer no mínimo 2 caracteres")
    .required("Campo obrigatório"),
});

type BoardFormProps = {
  selectedBoard: SelectedBoard;
  boardName: string | null;
  companyAreaName: string | null;
  handleClose: () => void;
  onSubmit: (values: { boardName: string }) => void;
};

export const BoardForm: React.FC<BoardFormProps> = ({
  boardName,
  selectedBoard,
  companyAreaName,
  handleClose,
  onSubmit,
}) => {
  const context = useForm({
    onSubmit,
    schema,
    defaultValues: {
      boardName: selectedBoard.name || "",
    },
  });

  const { handleAddAreaToBoard, handleRemoveAreasFromBoard } = useBoards();

  return (
    <Form context={context} css={styles.form}>
      <Flex flexDirection="column" height="100%" style={{ gap: "2rem" }}>
        <Column>
          <label css={styles.input.label}>Nome da {boardName}</label>
          <Form.Field
            name="boardName"
            as={
              <Input
                name="boardName"
                css={styles.input.container}
                placeholder={`Nome da ${companyAreaName}`}
              />
            }
          />
        </Column>
        <Column>
          <label css={styles.input.label}>
            Adicionar {companyAreaName} a {boardName}
          </label>
          <Autocomplete
            inputProps={{
              placeholder: `Procure por ${companyAreaName}`,
              notchedOutlineStyle: styles.input.outline,
              css: styles.input.container,
            }}
            handleSelect={handleAddAreaToBoard}
            fetchItems={boardService.fetchAreas}
            clearOnSelect={true}
            menuStyle={{
              minWidth: "none",
              borderRadius: "0.5rem",
            }}
          />
        </Column>
        <Column>
          <label css={styles.input.label}>{companyAreaName} incluídas</label>
          {selectedBoard.subAreas.length > 0 ? (
            <Flex style={{ gap: "0.5rem", flexWrap: "wrap" }}>
              {selectedBoard.subAreas.map((area) => (
                <Row key={area.companyAreaToken} css={styles.areaItem}>
                  <p>{area.name}</p>
                  <div
                    css={styles.icon.root}
                    onClick={() => handleRemoveAreasFromBoard(area)}
                  >
                    <CloseIcon css={styles.icon.svg} />
                  </div>
                </Row>
              ))}
            </Flex>
          ) : (
            <p css={styles.emptyAreas}>Sem {companyAreaName} atribuídos(as)</p>
          )}
        </Column>
      </Flex>
      <Flex style={{ gap: "1.5rem", alignSelf: "flex-end" }}>
        <ContainedButton onClick={context.submitForm} css={styles.saveButton}>
          Salvar
        </ContainedButton>
        <OutlinedButton type="button" onClick={handleClose}>
          Cancelar
        </OutlinedButton>
      </Flex>
    </Form>
  );
};
