import React from "react";

import { RouteComponentProps } from "@reach/router";

import { RealTimeTravels } from "@components/analytics/real-time/real-time-travels";

type Props = RouteComponentProps;

export const AnalyticsRealTimeTravels: React.FC<Props> = () => {
  return <RealTimeTravels />;
};
