import React, { createContext, useMemo } from "react";

import {
  TravelPaymentModel,
  TravelPaymentModelFactory,
} from "~/apps/corporate/models/travel.model";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { usePayment } from "./payment.context";

const PaymentContainerContext = createContext<{
  travelPaymentModel: TravelPaymentModel | null;
}>({
  travelPaymentModel: null,
});

export const PaymentContainerProvider: React.FC = ({ children }) => {
  const { travelPayment } = usePayment();

  const travelPaymentModel = useMemo(() => {
    if (!travelPayment) {
      return null;
    }

    return TravelPaymentModelFactory.create(travelPayment);
  }, [travelPayment]);

  return (
    <PaymentContainerContext.Provider value={{ travelPaymentModel }}>
      {children}
    </PaymentContainerContext.Provider>
  );
};

export const usePaymentContainer = useContextFactory(
  "PaymentContainerContext",
  PaymentContainerContext,
);
