import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";

import { styles } from "./styles";

type Props = {
  additionalCode?: string | null;
  additionalLabel?: string | null;
  reservationCode: string;
  reservationLabel?: string | null;
};

export const TravelPlanPDFServiceReservationCode: React.FC<Props> = ({
  additionalCode,
  additionalLabel = "Bilhete",
  reservationCode,
  reservationLabel = "Código de reserva (Localizador)",
}) => {
  const badgeStyles = styles.badge();

  return (
    <View style={badgeStyles.root}>
      <PDFIcon size={14} use="info-outline" />
      <View>
        <Text>
          {reservationLabel}:{" "}
          <strong style={badgeStyles.strong}>{reservationCode}</strong>
          {additionalCode ? (
            <>
              {" "}
              - {additionalLabel}:{" "}
              <strong style={badgeStyles.strong}>{additionalCode}</strong>
            </>
          ) : null}
        </Text>
      </View>
    </View>
  );
};
