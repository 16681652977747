import { GetClientUserCreditsResponse } from "../dtos/credits.dto";
import { Credit } from "../models/credit.model";

export const parseClientUserCredits = (dto: GetClientUserCreditsResponse) => {
  return dto.map((clientUserCredit) => {
    const credit: Credit = {
      bookedAt: clientUserCredit.bookedAt,
      canceledAt: clientUserCredit.canceledAt,
      cancelType: clientUserCredit.cancelType,
      comparisonValue: clientUserCredit.comparisonValue,
      expirationDate: clientUserCredit.expirationDate,
      initialUsageDate: clientUserCredit.initialUsageDate,
      operationalId: clientUserCredit.operationalId,
      provider: clientUserCredit.provider,
      providerCreditToken: clientUserCredit.providerCreditToken,
      traveler: clientUserCredit.traveler,
      type: clientUserCredit.type,
      value: clientUserCredit.value,
    };

    return credit;
  });
};
