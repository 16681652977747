import React from "react";

import Grid from "@material-ui/core/Grid";

import { Box } from "@toolkit";

import { toCurrency } from "@helpers";

import { AdvanceRangeContainer } from "../shared/AdvanceRangeChart";
import { SmallInfoCard } from "../shared/SmallInfoCard";
import { useHotelInfoContext } from "./HotelInfo.context";
import { SpentByDestination } from "./SpentByDestination";
import { SpentByHotel } from "./SpentByHotel";
import { SpentThroughtTime } from "./SpentThroughtTime";

const HotelInfoContent: React.FC = () => {
  const {
    hotelInfo: { totalSpent, averageSpent, advanceRange },
  } = useHotelInfoContext();

  return (
    <Box pt={[24, 0]}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} xs={12}>
          <SmallInfoCard
            title="Gasto total (R$)"
            value={toCurrency(totalSpent)}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <SmallInfoCard
            title="Ticket médio (R$)"
            value={toCurrency(averageSpent)}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <SpentThroughtTime />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <AdvanceRangeContainer
            data={advanceRange}
            barTitle="Qtd. de hospedagens"
          />
        </Grid>
        <Grid item xs={12}>
          <SpentByDestination />
        </Grid>
        <Grid item xs={12}>
          <SpentByHotel />
        </Grid>
      </Grid>
      <Box pb={88} />
    </Box>
  );
};

export { HotelInfoContent };
