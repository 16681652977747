import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  clear: css({
    background: "none",
    border: "none",
    color: theme.colors.pink[500],
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 600,
    outline: "none",
    padding: 0,
    textDecoration: "underline",
  }),
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
  }),
};
