import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    picture: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "4px",
      height: "100%",
      minHeight: "192px",
      maxHeight: "192px",
      maxWidth: "256px",
      objectFit: "cover",
      overflow: "hidden",
      width: "256px",
      "@media (max-width: 1280px)": {
        maxWidth: "192px",
        width: "192px",
      },
      "@media (max-width: 1024px)": {
        maxWidth: "256px",
        width: "256px",
      },
      "@media (max-width: 768px)": {
        maxWidth: "192px",
        width: "192px",
      },
      "@media (max-width: 640px)": {
        border: "unset",
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "unset",
        maxHeight: "192px",
        maxWidth: "unset",
        width: "100%",
      },
    }),
    root: css({
      flexShrink: 0,
      marginRight: "16px",
      "@media (max-width: 1440px)": {
        marginRight: "0",
      },
      "@media (max-width: 640px)": {
        marginRight: "unset",
      },
    }),
  },
  overlay: {
    favorite: css({
      left: "0",
      position: "absolute",
      top: "32px",
      zIndex: 1,
    }),
  },
  right: {
    bottom: {
      amenities: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      info: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      justifyContent: "space-between",
      padding: "0 16px",
      width: "100%",
      "@media (max-width: 640px)": {
        padding: "16px 16px 0 16px",
      },
    }),
    top: {
      name: css({
        fontSize: "1.125rem",
        fontWeight: 700,
      }),
      root: css({
        display: "flex",
        borderRadius: "6px",
        flexDirection: "column",
        gap: "4px",
      }),
      stars: css({
        color: theme.colors.pink[500],
      }),
    },
  },
  root: css({
    display: "flex",
    flex: 1,
    "@media (max-width: 640px)": {
      flexDirection: "column",
      padding: "unset",
    },
  }),
};
