import React from "react";

import { navigate } from "@reach/router";
import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { colors } from "~/assets/styles/theme";

import { APPROVAL_TYPES, APPROVAL_TYPES_LABELS } from "~/constants";

const TAG_COLORS = {
  [APPROVAL_TYPES.COST_CENTER]: theme.colors.gray[500],
  [APPROVAL_TYPES.PROJECT]: colors.fireBush,
  [APPROVAL_TYPES.USER]: theme.colors.green[500],
  [APPROVAL_TYPES.AREA]: theme.colors.gray[400],
  [APPROVAL_TYPES.COMPANY]: theme.colors.blue[500],
};

const styles = {
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.background.gray,
    borderRadius: "0.5rem",
    cursor: "pointer",
    display: "flex",
    height: "3.75rem",
    justifyContent: "space-between",
    padding: "0.5rem 1rem",
    width: "100%",
  }),
  name: css({
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  targets: css({
    color: theme.colors.gray[300],
    display: "block",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  tag: (backgroundColor: string) =>
    css({
      backgroundColor: backgroundColor,
      borderRadius: "0.25rem",
      color: theme.colors.white,
      fontSize: "0.75rem",
      height: "1.625rem",
      lineHeight: "1.125rem",
      marginLeft: "1rem",
      padding: "0.25rem 1rem",
      textAlign: "center",
      width: "7.75rem",
    }),
};

type Props = {
  name?: string;
  onDeleteClick: () => void;
  targets?: {
    email?: string;
    name?: string;
  }[];
  token: string;
  type: string;
};

export default function ApprovalCard({
  name,
  onDeleteClick,
  targets,
  token,
  type,
}: Props) {
  const handleClick = () => {
    return navigate(`/configurations/trips/approval-processes/${token}/edit`);
  };

  const parsedTargets =
    type === APPROVAL_TYPES.COMPANY
      ? "Toda a empresa"
      : targets?.map((target) => target.name).join(", ");

  const deleteButtonIsVisible = type !== APPROVAL_TYPES.COMPANY;

  return (
    <div css={styles.root} onClick={handleClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "28rem" }}>
          <h6 css={styles.name}>{name ? name : "-"}</h6>
          <span css={styles.targets}>Alvos: {parsedTargets}</span>
        </div>
        <span css={styles.tag(TAG_COLORS[type])}>
          {APPROVAL_TYPES_LABELS[type]}
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <Button
          onClick={(e) => {
            e.stopPropagation();

            handleClick();
          }}
          shape="icon"
        >
          <Icon size={20} use="pencil" />
        </Button>
        {deleteButtonIsVisible ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();

              onDeleteClick();
            }}
            shape="icon"
            variant="pink"
          >
            <Icon size={20} use="trash" />
          </Button>
        ) : null}
      </div>
    </div>
  );
}
