import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "8px 0",
    width: "100%",
  }),
  item: {
    card: {
      button: css({
        fontSize: "0.875rem",
        justifyContent: "center",
        marginTop: "16px",
      }),
      description: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        height: "100%",
      }),
      icon: css({
        color: theme.colors.white,
      }),
      loader: css({
        color: theme.colors.white,
      }),
      name: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        backgroundColor: theme.colors.background.gray,
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        padding: "16px",
      }),
    },
    list: css({
      display: "grid",
      gap: "16px",
      gridTemplateColumns: "1fr 1fr 1fr",
      "@media screen and (max-width: 1280px)": {
        gridTemplateColumns: "1fr 1fr",
      },
      "@media screen and (max-width: 768px)": {
        gridTemplateColumns: "1fr",
      },
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "1.125rem",
      fontWeight: 700,
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
};
