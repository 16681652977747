import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useItinerary } from "../../itinerary/itinerary.context";
import { PaymentHeaderBreadcrumbs } from "./payment-header-breadcrumbs/payment-header-breadcrumbs";
import { styles } from "./styles";

export const PaymentHeader: React.FC = () => {
  const { travelToken } = useItinerary();

  const [copiedTravelId, setCopiedTravelId] = useState(false);

  const handleCopyTravelId = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      await navigator.clipboard.writeText(travelToken);

      if (copiedTravelId) {
        return;
      }

      setCopiedTravelId(true);

      setTimeout(() => {
        setCopiedTravelId(false);
      }, 2000);
    },
    [copiedTravelId, travelToken],
  );

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <PaymentHeaderBreadcrumbs />
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.id.root}>
          <Tooltip
            content={copiedTravelId ? "Copiado!" : "Copiar"}
            position="top"
          >
            <button css={styles.right.id.button} onClick={handleCopyTravelId}>
              <Icon
                size={16}
                use={copiedTravelId ? "check" : "clipboard-document"}
              />
            </button>
          </Tooltip>
          <span>ID: {travelToken}</span>
        </div>
      </div>
    </div>
  );
};
