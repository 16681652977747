import React, { useCallback, useEffect } from "react";
import { useMutation } from "react-query";

import { navigate } from "@reach/router";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import {
  GetUserCompanyEventDto,
  SearchEventTargetsDto,
} from "sm-types/sm-company-events";

import { ALERT_TYPES } from "~/constants";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import { BusSearchForm } from "~/models/bus.model";
import {
  BookerParticipantType,
  EventInviteStatus,
  ParticipationStatus,
} from "~/models/event.model";

import { useApplicationContext } from "~/Context";

import { useEvents } from "~/components/events/events.context";
import { RecentFlightsProvider } from "~/components/new-trip/FlightForm/RecentFlights/RecentFlightsProvider";
import * as newTripService from "~/components/new-trip/NewTrip.service";
import {
  CarSearch,
  FlightSearch,
  HotelSearch,
} from "~/components/new-trip/NewTrip.types";
import {
  OfferSearch,
  OfferSearchAlert,
  OfferSearchForm,
  OfferSearchProvider,
  OfferSearchSkeleton,
  OfferSearchTabs,
  useOfferSearch,
} from "~/components/new-trip/offer-search";

import { useEvent } from "../../event.context";
import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

const Component: React.VFC<Props> = ({ event }) => {
  const user = getUserFromLocalStorage();

  const { showSnackMessage } = useApplicationContext();
  const { clientConfig } = useClientConfig();

  const { setGetBookingTargets } = useOfferSearch();

  const { invalidateEvent, participants } = useEvent();
  const { createEventTravel, searchEventTargets } = useEvents();

  const { mutateAsync: createEventTravelMutation } = useMutation({
    mutationFn: async ({
      data,
    }: Omit<Parameters<typeof createEventTravel>[0], "token">) => {
      const result = await createEventTravel({
        data,
        token: event.token,
      });

      if (!result) {
        return;
      }

      await invalidateEvent();

      return result;
    },
  });

  useEffect(() => {
    const getBookingTargets = async ({ search }: { search: string }) => {
      if (
        event.viewer_info.booker_type !== BookerParticipantType.FOR_ALL ||
        event.viewer_info.participation_status !== ParticipationStatus.ACCEPTED
      ) {
        return Promise.resolve(
          [
            {
              allowed: true,
              email: user?.email,
              fullName: `${user?.firstName} ${user?.lastName}`,
              travel_token: event.viewer_info.travel_info?.token,
              userToken: user?.userToken,
            },
          ].filter((user) =>
            user.fullName.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      }

      const result = await searchEventTargets({
        search,
        token: event.token,
      });

      if (!result) {
        return [];
      }

      return result.map((target) => ({
        allowed: true,
        fullName: target.full_name,
        userToken: target.user_token,
        ...target,
      }));
    };

    setGetBookingTargets(getBookingTargets);

    return () => {
      setGetBookingTargets(null);
    };
  }, [event]);

  const handleBusSearch = useCallback(
    async (search: BusSearchForm) => {
      if (!participants || !search.traveler) {
        return;
      }

      const participant = participants.find(
        (participant) => participant.user_token === search.traveler!.userToken,
      );

      if (!participant) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários fora do evento.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      if (participant.invite_status !== EventInviteStatus.ACCEPTED) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários não confirmados.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      let travel_token = participant.travel_info?.token;

      if (!travel_token) {
        const result = await createEventTravelMutation({
          data: {
            travel_name: search.traveler.fullName,
            traveler_token: search.traveler.userToken,
          },
        });

        if (!result) {
          return;
        }

        travel_token = result.travel_token;
      }

      const { data: busSearch } = await newTripService.createBusSearch(
        search,
        travel_token,
      );

      if (!busSearch) {
        return;
      }

      navigate(`/travels/${travel_token}/search/bus/${busSearch.bus_token}`);
    },
    [createEventTravelMutation, event, participants],
  );

  const handleCarSearch = useCallback(
    async (search: CarSearch) => {
      if (!participants || !search.traveler) {
        return;
      }

      const participant = participants.find(
        (participant) => participant.user_token === search.traveler!.userToken,
      );

      if (!participant) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários fora do evento.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      if (participant.invite_status !== EventInviteStatus.ACCEPTED) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários não confirmados.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      let travel_token = participant.travel_info?.token;

      if (!travel_token) {
        const result = await createEventTravelMutation({
          data: {
            travel_name: search.traveler.fullName,
            traveler_token: search.traveler.userToken,
          },
        });

        if (!result) {
          return;
        }

        travel_token = result.travel_token;
      }

      const { data: carSearch } = await newTripService.createCarSearch(
        search,
        travel_token,
      );

      navigate(`/travels/${travel_token}/search/cars/${carSearch.car_token}`);
    },
    [createEventTravelMutation, event, participants],
  );

  const handleFlightSearch = useCallback(
    async (search: FlightSearch) => {
      if (!participants || !search.traveler) {
        return;
      }

      const participant = participants.find(
        (participant) => participant.user_token === search.traveler!.userToken,
      );

      if (!participant) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários fora do evento.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      if (participant.invite_status !== EventInviteStatus.ACCEPTED) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários não confirmados.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      let travel_token = participant.travel_info?.token;

      if (!travel_token) {
        const result = await createEventTravelMutation({
          data: {
            travel_name: search.traveler.fullName,
            traveler_token: search.traveler.userToken,
          },
        });

        if (!result) {
          return;
        }

        travel_token = result.travel_token;
      }

      const { data: flightSearch } = await newTripService.createFlightSearch(
        search,
        travel_token,
      );

      navigate(`/travels/${travel_token}/search/flights/${flightSearch.token}`);
    },
    [createEventTravelMutation, event, participants],
  );

  const handleHotelSearch = useCallback(
    async (search: HotelSearch) => {
      if (!participants || !search.traveler) {
        return;
      }

      const participant = participants.find(
        (participant) => participant.user_token === search.traveler!.userToken,
      );

      if (!participant) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários fora do evento.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      if (participant.invite_status !== EventInviteStatus.ACCEPTED) {
        showSnackMessage(
          "Não é possível realizar a busca para usuários não confirmados.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      let travel_token = participant.travel_info?.token;

      if (!travel_token) {
        const result = await createEventTravelMutation({
          data: {
            travel_name: search.traveler.fullName,
            traveler_token: search.traveler.userToken,
          },
        });

        if (!result) {
          return;
        }

        travel_token = result.travel_token;
      }

      const { data: hotelSearch } = await newTripService.createHotelSearch(
        search,
        travel_token,
      );

      navigate(`/travels/${travel_token}/search/hotels/${hotelSearch.token}`);
    },
    [createEventTravelMutation, event, participants],
  );

  return (
    <div id="booking">
      {clientConfig ? (
        <OfferSearch className={styles.root}>
          {clientConfig.hasHomeWarning() ? (
            <OfferSearchAlert __html={clientConfig.getHomeWarning()!} />
          ) : null}
          <OfferSearchTabs
            busVisible={clientConfig.isBusesVisible()}
            carVisible={clientConfig.isCarsVisible()}
            hotelVisible={clientConfig.isHotelsVisible()}
          />
          <OfferSearchForm
            className={styles.form}
            handleBusSearch={handleBusSearch}
            handleCarSearch={handleCarSearch}
            handleFlightSearch={handleFlightSearch}
            handleHotelSearch={handleHotelSearch}
          />
        </OfferSearch>
      ) : (
        <OfferSearchSkeleton />
      )}
    </div>
  );
};

export const EventOverviewBooking: React.VFC<Props> = ({ event }) => {
  return (
    <RecentFlightsProvider>
      <OfferSearchProvider>
        <Component event={event} />
      </OfferSearchProvider>
    </RecentFlightsProvider>
  );
};
