import React, { useContext } from "react";
import { defaultTheme } from "../../../../assets/styles/theme";
import { Row } from "../../../shared/layout/Row";
import { ExpensePolicyContext } from "./ExpensePolicy.context";
import isEmpty from "lodash/isEmpty";
import { PolicyItem } from "./PolicyItem";
import { TARGET_TYPES } from "../../../../constants";

const ExpensePolicyList = () => {
  const {
    loading,
    expensePolicies,
    handleEditPolicy,
    handleSelectPolicyToDelete
  } = useContext(ExpensePolicyContext);

  return (
    <div>
      <Row style={{ paddingBottom: ".5rem" }}>
        <div style={{ display: "flex", flex: 1 }}>
          <span
            style={{
              color: defaultTheme.subTextColor,
              fontSize: 15,
              fontWeight: "bold"
            }}
          >
            Políticas
          </span>
        </div>
        <div style={{ width: "14%" }}>
          <span
            style={{
              color: defaultTheme.subTextColor,
              fontSize: 15,
              fontWeight: "bold"
            }}
          >
            # Usuários
          </span>
        </div>
      </Row>
      {!loading && isEmpty(expensePolicies) ? (
        <span>Nenhuma política de despesas encontrada</span>
      ) : null}
      {expensePolicies.map(policy => (
        <PolicyItem
          key={policy.policyToken}
          policy={policy}
          isDefault={policy.targetType === TARGET_TYPES.COMPANY}
          onEdit={handleEditPolicy}
          onDelete={handleSelectPolicyToDelete}
        />
      ))}
    </div>
  );
};

export { ExpensePolicyList };
