import { APPROVAL_STATUS } from "~/apps/shared/constants";
import { ApprovalStatus } from "~/apps/shared/types";
import moment from "moment";

export type TravelApprovalHistoryRecordApprover = {
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  stage: number;
  userToken: string;
};

type TravelApprovalHistoryRecord = {
  approvalProcessToken: string;
  approvalRequestToken: string;
  approver?: TravelApprovalHistoryRecordApprover;
  approver2?: TravelApprovalHistoryRecordApprover;
  canceled: boolean;
  closedAt: string | null;
  createdAt: string;
  firstApprovedAt: string;
  firstAttempt: boolean;
  requestMessage: string;
  reservedUntil: string;
  responseMessage: string;
  responseMessage2: string;
  status: ApprovalStatus;
  travelToken: string;
  travelerToken: string;
};

export class TravelApprovalHistoryRecordModel {
  constructor(protected record: TravelApprovalHistoryRecord) {}

  public getApprovalRequestToken() {
    return this.record.approvalRequestToken;
  }

  public getApprover() {
    const approver = this.record.approver;

    if (!approver) {
      return null;
    }

    return approver;
  }

  public getFormattedClosedAt() {
    const closedAt = this.record.closedAt;

    if (!closedAt) {
      return null;
    }

    return moment(this.record.closedAt).format("DD/MM/YYYY [às] HH[h]mm");
  }

  public getFormattedCreatedAt() {
    return moment(this.record.createdAt).format("DD/MM/YYYY [às] HH[h]mm");
  }

  public getFormattedStatusLabel() {
    if (this.isCanceled()) {
      return "cancelado pelo solicitante";
    }

    switch (this.getStatus()) {
      case APPROVAL_STATUS.APPROVED:
        return "aprovado";
      case APPROVAL_STATUS.DECLINED:
        return "negado";
      case APPROVAL_STATUS.DOES_NOT_NEED_APPROVAL:
        return "não precisa de aprovação";
      case APPROVAL_STATUS.UNITIATED:
        return "ainda precisa de aprovação";
      case APPROVAL_STATUS.PENDING_APPROVAL_2:
        return "aprovação pendente";
      case APPROVAL_STATUS.PENDING_APPROVAL:
        return "aprovação pendente";
      default:
        break;
    }

    return "";
  }

  public getRequestMessage() {
    return this.record.requestMessage;
  }

  public getResponseMessage() {
    return this.record.responseMessage2 || this.record.responseMessage;
  }

  public getStatus() {
    return this.record.status;
  }

  public getStatusColor() {
    if (this.isCanceled()) {
      return "#B24531";
    }

    switch (this.getStatus()) {
      case APPROVAL_STATUS.APPROVED:
        return "#027A48";
      case APPROVAL_STATUS.DECLINED:
        return "#B24531";
      case APPROVAL_STATUS.DOES_NOT_NEED_APPROVAL:
        return "#027A48";
      case APPROVAL_STATUS.UNITIATED:
        return "#808080";
      case APPROVAL_STATUS.PENDING_APPROVAL_2:
        return "#FA831B";
      case APPROVAL_STATUS.PENDING_APPROVAL:
        return "#FA831B";
      default:
        break;
    }

    return "";
  }

  public isCanceled() {
    return this.record.canceled;
  }
}

export type TravelApprovalHistory = {
  history: TravelApprovalHistoryRecord[];
  isPendingApproval: boolean;
};

export class TravelApprovalHistoryModel {
  constructor(
    protected travelApprovalHistory: {
      history: TravelApprovalHistoryRecordModel[];
      isPendingApproval: boolean;
    },
  ) {}

  public getLatestApprovalRecord() {
    if (this.travelApprovalHistory.history.length === 0) {
      return null;
    }

    return this.travelApprovalHistory.history[0];
  }

  public getRecords() {
    return this.travelApprovalHistory.history;
  }

  public hasRecords() {
    return this.travelApprovalHistory.history.length > 0;
  }

  public isPendingApproval() {
    return this.travelApprovalHistory.isPendingApproval;
  }
}

export class TravelApprovalHistoryModelFactory {
  public static create(travelApprovalHistory: TravelApprovalHistory) {
    return new TravelApprovalHistoryModel({
      ...travelApprovalHistory,
      history: travelApprovalHistory.history.map(
        (record) => new TravelApprovalHistoryRecordModel(record),
      ),
    });
  }
}
