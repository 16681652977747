import React from "react";

import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";

import { ItineraryBusJourney } from "./itinerary-bus-journey/itinerary-bus-journey";
import { ItineraryCarJourney } from "./itinerary-car-journey/itinerary-car-journey";
import { ItineraryFlightJourney } from "./itinerary-flight-journey/itinerary-flight-journey";
import { ItineraryHotelJourney } from "./itinerary-hotel-journey/itinerary-hotel-journey";
import { ItineraryJourneyHeader } from "./itinerary-journey-header/itinerary-journey-header";
import { styles } from "./styles";

type Props = {
  isApprovalReview?: boolean;
  isSelected?: boolean;
  isTravelPlan?: boolean;
  isUnavailable?: boolean;
  onSelect?: (servicePresenter: ItineraryServicePresenter) => void;
  servicePresenter: ItineraryServicePresenter;
};

export const ItineraryServiceJourney: React.FC<Props> = ({
  isApprovalReview,
  isSelected,
  isTravelPlan,
  isUnavailable,
  onSelect,
  servicePresenter,
}) => {
  const isDisabled =
    servicePresenter.isApprovalDeclined() ||
    servicePresenter.isOutdatedOrUnavailable() ||
    !!isUnavailable;

  return (
    <div css={styles.root({ isDisabled })}>
      {onSelect ? (
        <div css={styles.checkbox.root}>
          <Checkbox
            checked={isSelected}
            disabled={isDisabled}
            onChange={() => {
              onSelect(servicePresenter);
            }}
            variant="pink"
          />
        </div>
      ) : null}
      <div css={styles.body.root}>
        <ItineraryJourneyHeader
          isApprovalReview={isApprovalReview}
          isSelected={isSelected}
          isTravelPlan={isTravelPlan}
          isUnavailable={isUnavailable}
          servicePresenter={servicePresenter}
        />
        {servicePresenter.isBusService() ? (
          <ItineraryBusJourney
            isDisabled={isDisabled}
            servicePresenter={servicePresenter}
          />
        ) : null}
        {servicePresenter.isCarService() ? (
          <ItineraryCarJourney
            isDisabled={isDisabled}
            servicePresenter={servicePresenter}
          />
        ) : null}
        {servicePresenter.isFlightService() ? (
          <ItineraryFlightJourney
            isDisabled={isDisabled}
            servicePresenter={servicePresenter}
          />
        ) : null}
        {servicePresenter.isHotelService() ? (
          <ItineraryHotelJourney
            isDisabled={isDisabled}
            servicePresenter={servicePresenter}
          />
        ) : null}
      </div>
    </div>
  );
};
