import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  additionals: () =>
    StyleSheet.create({
      additional: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        gap: 8,
      },
      root: {
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        flexDirection: "row",
        gap: 24,
        paddingTop: 24,
        width: "100%",
      },
    }),
  car: () =>
    StyleSheet.create({
      column: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: "8px 0",
      },
      features: {
        display: "flex",
        flexDirection: "row",
        gap: "8px 24px",
      },
      image: {
        width: 144,
        objectFit: "contain",
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "8px 24px",
      },
      row: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        gap: "8px",
      },
    }),
  heading: () =>
    StyleSheet.create({
      description: {
        color: theme.colors.gray[100],
        fontSize: 11,
        whiteSpace: "nowrap",
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "4px 8px",
      },
      title: {
        color: theme.colors.gray[700],
        fontSize: 12,
        fontWeight: 700 - 100,
        whiteSpace: "nowrap",
      },
    }),
  location: () =>
    StyleSheet.create({
      location: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        gap: 8,
      },
      root: {
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 24,
      },
    }),
  root: () =>
    StyleSheet.create({
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
      },
    }),
};
