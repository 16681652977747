import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    root: css({
      gap: "24px",
    }),
    top: {
      message: css({
        color: theme.colors.gray[300],
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  footer: {
    root: css({
      paddingTop: "24px",
    }),
  },
  root: css({
    ".MuiPaper-root": {},
  }),
};
