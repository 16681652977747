import React from "react";

import { Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";

import { Integrations } from "./Integrations";
import { SSO } from "./sso/sso";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <Integrations path="/" />
      <SSO path="/sso" />
    </Router>
  );
};

export const IntegrationsRoutes: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
