import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    icon: css({
      backgroundColor: theme.colors.white,
      color: theme.colors.gray[300],
      height: "32px",
    }),
    right: {
      bottom: {
        date: css({
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "4px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }),
      top: {
        label: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
        root: css({
          overflow: "hidden",
          paddingRight: "16px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          "@media (max-width: 640px)": {
            paddingRight: "24px",
          },
        }),
      },
    },
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "0 16px",
      "@media (max-width: 640px)": {
        width: "100%",
      },
    }),
  },
  root: css({
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
    gap: "4px 0",
  }),
};
