import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "12px 24px 24px 24px",
    }),
  },
  content: {
    center: {
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "24px 0",
      }),
    },
    top: {
      message: css({
        color: theme.colors.gray[700],
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  header: {
    close: css({
      backgroundColor: "transparent",
      border: "none",
      right: "12px",
      position: "absolute",
      top: "12px",
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      padding: "24px 24px 12px 24px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
  root: css({
    ".MuiDialog-paper": {},
  }),
  warning: {
    message: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      lineHeight: "1.125",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
  },
};
