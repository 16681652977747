import { SubmitHotelReviewResponseBody } from "@dtos/hotel-review.dto";
import moment from "moment";

export function parseHotelReviewSubmitResponse(
  data: SubmitHotelReviewResponseBody
) {
  return {
    cleaningGrade: data.cleaning_grade,
    clientToken: data.client_token,
    createdAt: moment(data.created_at),
    generalGrade: data.general_grade,
    hotelCity: data.hotel_city,
    hotelContinent: data.hotel_continent,
    hotelCountry: data.hotel_country,
    hotelIdentification: data.hotel_identification,
    hotelName: data.hotel_name,
    hotelReviewToken: data.hotel_review_token,
    hotelState: data.hotel_state,
    locationGrade: data.location_grade,
    negativeReview: data.negative_review,
    offerToken: data.offer_token,
    positiveReview: data.positive_review,
    reviewTitle: data.review_title,
    roomGrade: data.room_grade,
    treatmentGrade: data.treatment_grade,
    userToken: data.user_token,
    wifiGrade: data.wifi_grade
  };
}
