import React, { useEffect } from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { Button, Switch } from "@toolkit/v2";
import { darken } from "@material-ui/core";
import { Flex, Box } from "@toolkit";

import { tagBatchApi } from "~/apis/excel.api";

import PageTitle from "@shared/PageTitle";
import Spinner from "@shared/Spinner";

import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Filters } from "~/apps/shared/components/filters/filters";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ContainedButton } from "@components/shared/buttons/contained-button";
import { AddTagDialog } from "./AddTagDialog";
import { EditTagDialog } from "./EditTagDialog";
import {
  useTagsManagementContext,
  TagsManagementProvider
} from "./TagsManagement.context";
import { TagsTable } from "./TagsTable";

const DEFAULT_FILTERS = [
  {
    label: "Ativas",
    value: "active"
  },
  {
    label: "Arquivadas",
    value: "archived"
  }
];

const styles = {
  buttons: {
    add: css({
      width: "fit-content",
      height: "3rem",
      backgroundColor: theme.colors.pink[500],
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2)
      }
    }),
    excel: css({
      height: "3rem"
    })
  },
  switch: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      width: "fit-content",
      padding: "0.75rem 1rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
      cursor: "pointer"
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600
    })
  }
};

const Tags = () => {
  const {
    tab,
    isEditDialogOpen,
    isAddTagDialogOpen,
    openAddTagDialog,
    closeAddTagDialog,
    closeEditDialog,
    handleTabChange,
    fetchClientTagsAndOptions,
    onlyAdminOption,
    requiredTravelTagOption,
    toggleOnlyAdminOption,
    toggleRequiredTravelTagOption,
    loading
  } = useTagsManagementContext();

  useEffect(() => {
    fetchClientTagsAndOptions();
  }, []);

  return loading ? (
    <Spinner visible />
  ) : (
    <Box>
      <Box>
        <Flex justifyContent="space-between" mb="1.5rem">
          <Flex style={{ gap: "1.5rem" }}>
            <ContainedButton
              css={styles.buttons.add}
              onClick={openAddTagDialog}
            >
              Adicionar etiqueta
            </ContainedButton>
            <BatchFileUploader
              onUpload={tagBatchApi.onUpload}
              onDownloadModelClick={tagBatchApi.onDownloadModelClick}
            >
              {({ openDialog }) => (
                <Button
                  fill="outlined"
                  onClick={openDialog}
                  css={styles.buttons.excel}
                >
                  <Icon use="cloud-arrow-up" />
                  Cadastrar via excel
                </Button>
              )}
            </BatchFileUploader>
          </Flex>
          <Filters
            closeOnSelect
            onChange={filter => handleTabChange(filter)}
            options={DEFAULT_FILTERS}
            value={tab}
          />
        </Flex>
        <Flex
          flexDirection="column"
          style={{ gap: "0.5rem", marginBottom: "1.5rem" }}
        >
          <label css={styles.switch.root}>
            <Switch
              active={!!onlyAdminOption}
              onChange={toggleOnlyAdminOption()}
              variant="pink"
            />
            <span css={styles.switch.text}>
              Somente administradores podem criar etiquetas
            </span>
          </label>

          <label css={styles.switch.root}>
            <Switch
              active={!!requiredTravelTagOption}
              onChange={toggleRequiredTravelTagOption()}
              variant="pink"
            />
            <span css={styles.switch.text}>
              Tornar etiquetas obrigatórias em viagens
            </span>
          </label>
        </Flex>
      </Box>
      <TagsTable />
      <EditTagDialog isOpen={isEditDialogOpen} handleClose={closeEditDialog} />
      <AddTagDialog
        isOpen={isAddTagDialogOpen}
        handleClose={closeAddTagDialog}
      />
    </Box>
  );
};

const TagsContainer = props => {
  return (
    <TagsManagementProvider>
      <PageTitle title={"Etiquetas"} />
      <Tags {...props} />
    </TagsManagementProvider>
  );
};

export { TagsContainer as Tags };
