import React, { useEffect } from "react";

import { Box } from "@toolkit";

import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";

import {
  BillingProfilesProvider,
  useBillingProfileContext,
  useBillingProfileActionsContext,
} from "./BillingProfiles.context";
import { BillingProfileDeletionDialog } from "./deletion-dialog";
import { BillingProfileForm } from "./form";
import { ProfilesList } from "./profiles-list";

const Component = () => {
  const { isLoading } = useBillingProfileContext();
  const { loadProfiles } = useBillingProfileActionsContext();

  useEffect(() => {
    loadProfiles();
  }, []);

  return (
    <>
      <PageTitle title="Perfis de cobrança" />
      <SpinnerPortal visible={isLoading} />
      <Box>
        <ProfilesList />
        <BillingProfileForm />
      </Box>
      <BillingProfileDeletionDialog />
    </>
  );
};

export const BillingProfiles: React.FC = () => {
  return (
    <BillingProfilesProvider>
      <Component />
    </BillingProfilesProvider>
  );
};
