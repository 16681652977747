import { useMemo } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { useItineraryServices } from "~/apps/corporate/pages/travels/itinerary/itinerary-services.context";
import { useItineraryContainer } from "~/apps/corporate/pages/travels/itinerary/itinerary.container";

import { ItineraryJourneyHeaderPresenterFactory } from "./itinerary-journey-header.presenter";

export const useItineraryJourneyHeaderPresenter = ({
  servicePresenter,
}: {
  servicePresenter: ItineraryServicePresenter;
}) => {
  const { user } = useUser();

  const { infoModel } = useItineraryContainer();

  const { travelPoliciesPerOffer } = useItineraryServices();

  return useMemo(
    () => ({
      itineraryJourneyHeaderPresenter:
        infoModel && user
          ? ItineraryJourneyHeaderPresenterFactory.create(
              infoModel,
              servicePresenter,
              travelPoliciesPerOffer,
              user,
            )
          : null,
    }),
    [infoModel, servicePresenter, travelPoliciesPerOffer, user],
  );
};
