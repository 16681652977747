import React from "react";
import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { Flex, Text } from "@toolkit";
import { Button } from "@toolkit/v2";

import { defaultTheme } from "@assets/styles/theme";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useTagsManagementContext } from "./TagsManagement.context";

const styles = {
  tableHeader: css({
    fontSize: 15,
    fontWeight: "bold",
    color: defaultTheme.subTextColor,
    padding: "0rem .5rem .5rem .5rem",
  }),
  descriptionColumn: css({
    width: "75%",
    textAlign: "left",
  }),
  tripsColumn: css({
    width: "16%",
    textAlign: "right",
  }),
  optionsColumn: css({
    width: "9%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  }),
  tagItem: css({
    alignItems: "center",
    color: defaultTheme.subTextColor,
    fontSize: 15,
    padding: "1rem .5rem 1rem 1rem",
    cursor: "pointer",
    ["&:not(:last-child)"]: {
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    },
    ["&:hover"]: {
      borderRadius: 4,
      backgroundColor: defaultTheme.cardBorderColor,
    },
  }),
  list: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    }),
    item: {
      left: {
        name: css({
          color: theme.colors.gray[500],
          fontWeight: 600,
          lineHeight: "1.5rem",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }),
        token: css({
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          lineHeight: "1.125rem",
        }),
      },
      right: {
        count: css({
          alignItems: "center",
          display: "flex",
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          gap: "0.5rem",
          marginRight: "0.5rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "0.5rem",
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.gray,
        borderRadius: "0.5rem",
        display: "flex",
        gap: "0.5rem",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        cursor: "pointer",
      }),
    },
  },
};

const TagItem = ({
  tag,
  type,
  handleArchiveTag,
  handleUnarchiveTag,
  handleEdit,
}) => {
  return (
    <li css={styles.list.item.root} onClick={() => handleEdit(tag)}>
      <Flex style={{ gap: "2rem" }}>
        <div css={styles.list.item.left.root}>
          <span css={styles.list.item.left.name}>Nome da etiqueta</span>
          <span css={styles.list.item.left.token}>{tag.tagName}</span>
        </div>
        <div css={styles.list.item.left.root}>
          <span css={styles.list.item.left.name}>Viagens</span>
          <span css={styles.list.item.left.token}>
            {tag.useCount > 0 ? tag.useCount : ""}{" "}
            {tag.useCount > 0
              ? "cadastradas"
              : tag.useCount === 0
              ? "Nenhuma cadastrada"
              : "cadastrada"}
          </span>
        </div>
      </Flex>
      <div css={styles.list.item.right.root}>
        {type === "active" ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();

              handleEdit(tag);
            }}
            shape="icon"
          >
            <Icon size={20} use="pencil" />
          </Button>
        ) : null}

        <Button
          onClick={(e) => {
            e.stopPropagation();

            if (type === "active") {
              handleArchiveTag(tag);
            } else {
              handleUnarchiveTag(tag);
            }
          }}
          shape="icon"
          variant="pink"
        >
          <Icon
            size={type === "active" ? 20 : 18}
            use={type === "active" ? "trash" : "archive-box-arrow-up"}
          />
        </Button>
      </div>
    </li>
  );
};

function compareByLowerCaseTagName(itemA, itemB) {
  if (itemA.tagName.toLowerCase() < itemB.tagName.toLowerCase()) {
    return -1;
  }
  if (itemA.tagName.toLowerCase() > itemB.tagName.toLowerCase()) {
    return 1;
  }
  return 0;
}

const TagsTable = () => {
  const {
    tab,
    activeTags,
    inactiveTags,
    handleArchiveTag,
    handleUnarchiveTag,
    openEditDialog,
  } = useTagsManagementContext();

  return activeTags.length || inactiveTags.length ? (
    <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
      {tab === "active"
        ? activeTags
            .slice()
            .sort(compareByLowerCaseTagName)
            .map((tag) => (
              <TagItem
                key={tag.tagToken}
                tag={tag}
                type="active"
                handleArchiveTag={handleArchiveTag}
                handleEdit={openEditDialog}
              />
            ))
        : inactiveTags.map((tag) => (
            <TagItem
              key={tag.tagToken}
              tag={tag}
              type="inactive"
              handleUnarchiveTag={handleUnarchiveTag}
              handleEdit={openEditDialog}
            />
          ))}
    </Flex>
  ) : (
    <Text fontSize={2}>Nenhuma etiqueta encontrada.</Text>
  );
};

export { TagsTable };
