import { getAuthorizationHeader } from "../helpers/user.helper";
import { api } from "./interceptors";

export function getBusAutosuggestResults(search) {
  return api
    .request({
      url: `/booking/autosuggest/bus/${search}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response =>
      response.data.location_list.map(location => ({
        ...location,
        label: location.name
      }))
    );
}

export function createBusSearch(dto) {
  return api
    .request({
      url: "/booking/bus",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(response => response.data);
}

export function getBusTrips(busSearchToken) {
  return api
    .request({
      url: `/booking/bus/${busSearchToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function getTripDetails(tripPartId, busToken) {
  return api
    .request({
      url: `/booking/bus/${busToken}/trips/${tripPartId}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function getAllTripsDetails(tripPartsIds, busToken) {
  return Promise.all(tripPartsIds.map(id => getTripDetails(id, busToken)));
}

export function addBusOfferToTravel(dto) {
  return api
    .request({
      url: "/booking/offer",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(response => response.data);
}
