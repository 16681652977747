import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap-reverse",
      gap: "8px",
      justifyContent: "center",
      width: "100%",
      "@media (max-width: 640px)": {
        justifyContent: "space-between",
      },
    }),
  },
  center: {
    bottom: {
      button: css({
        fontSize: "0.875rem",
        padding: "8px 12px",
        textAlign: "left",
        width: "fit-content",
        ":disabled": {
          opacity: 0.5,
        },
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    center: {
      amenities: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      negotiated: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
        lineHeight: "1.125",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxWidth: "384px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      justifyContent: "space-between",
      padding: "0 16px",
      width: "100%",
      "@media (max-width: 640px)": {
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        gap: "16px",
        padding: "16px 0",
      },
    }),
    top: {
      name: css({
        fontWeight: 600,
        lineHeight: "24px",
      }),
      root: css({
        display: "flex",
        borderRadius: "6px",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  left: {
    overlay: {
      fare: css({
        borderRadius: "6px",
        fontSize: "0.875rem",
        gap: "8px",
        left: "8px",
        opacity: 0.75,
        position: "absolute",
        top: "8px",
      }),
      favorite: css({
        right: "8px",
        opacity: 0.75,
        position: "absolute",
        top: "8px",
      }),
      images: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.white,
        cursor: "pointer",
        right: "8px",
        position: "absolute",
        bottom: "8px",
      }),
      policy: css({
        borderRadius: "6px",
        cursor: "pointer",
        fontSize: "0.875rem",
        gap: "8px",
        left: "8px",
        opacity: 0.75,
        position: "absolute",
        top: "8px",
      }),
    },
    picture: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      height: "100%",
      maxHeight: "256px",
      minHeight: "192px",
      objectFit: "cover",
      overflow: "hidden",
      width: "256px",
      "@media (max-width: 992px)": {
        width: "192px",
      },
      "@media (max-width: 640px)": {
        border: "unset",
        minHeight: "192px",
        maxHeight: "192px",
        width: "100%",
      },
    }),
    root: css({
      flexShrink: 0,
      marginRight: "16px",
      position: "relative",
      "@media (max-width: 1440px)": {
        marginRight: "0",
      },
      "@media (max-width: 640px)": {
        marginRight: "unset",
      },
    }),
  },
  right: {
    bottom: {},
    button: css({
      backgroundColor: theme.colors.pink[500],
      border: "1px solid",
      borderColor: theme.colors.pink[500],
      borderRadius: "8px",
      color: theme.colors.white,
      fontWeight: 500,
      textDecoration: "none",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        backgroundColor: theme.colors.pink[700],
        borderColor: theme.colors.pink[700],
      },
      "@media (max-width: 1440px)": {
        justifyContent: "center",
        width: "100%",
      },
    }),
    center: {
      guests: {
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
      points: {
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "@media (max-width: 640px)": {
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "8px",
          width: "100%",
        },
      }),
    },
    guests: {
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
    },
    root: css({
      alignItems: "center",
      borderLeft: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      gap: "16px",
      justifyContent: "space-between",
      paddingLeft: "16px",
      "@media (max-width: 640px)": {
        alignItems: "flex-start",
        borderLeft: "unset",
        gap: "8px",
        padding: "16px 0 0 0",
      },
      "@media (max-width: 380px)": {
        gap: "16px",
      },
    }),
    top: {
      badge: {
        icon: css({
          "& > svg": {
            strokeWidth: 2,
          },
        }),
        root: css({
          backgroundColor: "#ffe8ab",
          borderColor: "#ffe8ab",
          color: "#806300",
          cursor: "pointer",
          fontWeight: 500,
          gap: "8px",
          padding: "4px 16px",
          whiteSpace: "nowrap",
          ":hover": {
            backgroundColor: "#e5d099",
            borderColor: "#e5d099",
            color: "#735900",
          },
        }),
      },
      badges: {
        badge: css({
          width: "100%",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
      },
      label: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      price: {
        night: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "4px",
        }),
        value: css({
          color: theme.colors.gray[700],
          fontSize: "1.5rem",
          fontWeight: 700,
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "@media (max-width: 640px)": {
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
        },
      }),
      sub: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        textAlign: "right",
      }),
    },
  },
  root: css({
    backgroundColor: theme.colors.background.gray,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    padding: "16px",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  }),
};
