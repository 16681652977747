import React from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

import CircularSpinner from "@components/shared/CircularSpinner";

const styles = {
  button: (isDisabled?: boolean) =>
    css({
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      padding: "0.75rem 2rem",
      border: "none",
      borderRadius: "0.5rem",
      cursor: isDisabled ? "not-allowed" : "pointer",
      color: theme.colors.white,
      backgroundColor: isDisabled
        ? theme.colors.gray[100]
        : theme.colors.gray[500],
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      "&:hover": {
        backgroundColor: isDisabled ? "" : darken(theme.colors.gray[500], 0.5),
        transition: "200ms"
      }
    })
};

export interface IContainedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export const ContainedButton: React.FC<IContainedButtonProps> = ({
  children,
  loading,
  ...rest
}) => {
  return (
    <button css={styles.button(rest?.disabled)} {...rest}>
      {children} {loading ? <CircularSpinner size={14} /> : null}
    </button>
  );
};
