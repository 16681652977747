import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { EXPENSES_CATEGORIES_TRANSLATION } from "@constants";
import * as utils from "@helpers";
import { styles, tooltipStyles } from "../../common-styles";

const CustomizedAxisXTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedAxisYTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

const ServicesChart = ({ data, expenseTypes }) => {
  return (
    <ResponsiveContainer height={330} width={"100%"}>
      <ComposedChart data={data} margin={{ top: 10 }}>
        <CartesianGrid
          strokeDasharray="5 10"
          vertical={false}
          horizontal={false}
        />
        <XAxis axisLine={true} dataKey="name" tick={<CustomizedAxisXTick />} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick />}
        />
        <Tooltip
          {...tooltipStyles}
          formatter={(value, name) => {
            return [utils.toCurrency(value), name];
          }}
        />
        {expenseTypes.map(item => {
          return (
            <Bar
              key={item.type}
              name={EXPENSES_CATEGORIES_TRANSLATION[item.type]}
              dataKey={item.type}
              stackId="a"
              fill={item.color}
              stroke={item.color}
              barSize={32}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { ServicesChart };
