import React from "react";

import { ErrorOutline as WarningIcon } from "@material-ui/icons/";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import clsx from "classnames";
import { css } from "emotion";
import moment from "moment";
import { theme } from "smartrips-skin";

import { Flex, Text } from "@toolkit";

import {
  MEDIA_QUERIES,
  ADVANCED_EXPENSE_STATUS,
  EXPENSE_REPORT_STATUS,
} from "@constants";

import * as utils from "@helpers";

import { AdvancedExpense } from "@models/advanced-expense.model";

import { defaultTheme } from "@theme";

import { Column, Row } from "@shared/layout/index";
import {
  Desktop,
  Mobile,
  Tablet,
  TabletAndMobile,
} from "@shared/layout/Responsive";
import { LongText } from "@shared/LongText";

import { ExpenseCardCategoryTag } from "../ExpenseCardCategoryTag";

const styles = {
  cardRoot: css({
    display: "flex",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0 4px 0 0",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      minHeight: 90,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      flexDirection: "column",
    },
  }),
  baseCard: css({
    display: "flex",
    alignItems: "center",
    padding: "1rem 1.75rem",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    color: defaultTheme.subTextColor + " !important",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "1rem",
    },
  }),
  period: css({
    fontSize: 14,
    paddingBottom: ".5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
    },
  }),
  description: css({
    fontSize: 14,
    fontWeight: "bold",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
    },
  }),
  priceColumn: css({
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 80,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: 75,
    },
  }),
  price: css({
    fontWeight: "bold",
    fontSize: 18,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 13,
    },
  }),
  positionBottom: css({
    [MEDIA_QUERIES.desktopBreakpoint]: {
      position: "absolute",
      right: "1.75rem",
      bottom: "1.5rem",
    },
  }),
  approvedValueDiv: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 .5rem",
    borderLeft: `1px solid ${defaultTheme.cardBorderColor}`,
    minWidth: 133,
    maxWidth: 133,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      borderTop: `1px solid ${defaultTheme.cardBorderColor}`,
      borderLeft: "none",
      padding: ".5rem",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      maxWidth: "100%",
    },
  }),
  approvedLabel: css({
    fontSize: 14,
    paddingBottom: ".5rem",
    textAlign: "center",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 13,
      fontWeight: "bold",
      paddingRight: ".5rem",
      paddingBottom: 0,
    },
  }),
  discountableIcon: css({
    marginRight: ".25rem",
    width: "1rem",
    height: "1rem",
  }),
};

interface Props {
  advancedExpense: AdvancedExpense;
  handleDelete?: () => void;
  children?: React.ReactNode;
  isRemovable: boolean;
}

const AdvancedExpenseItem = ({
  advancedExpense,
  isRemovable,
  handleDelete,
}: Props) => {
  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <Row style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
        <Row>
          <ExpenseCardCategoryTag
            categoryName="Adiantamento"
            categoryColor={theme.colors.cancel}
            isCanceled={false}
          />
        </Row>
        {isRemovable ? (
          <Row>
            <DeleteIcon
              style={{
                fontSize: 24,
                color: defaultTheme.subTextColor,
                cursor: "pointer",
                marginBottom: ".5rem",
              }}
              onClick={handleDelete}
            />
          </Row>
        ) : null}
      </Row>
      <div className={styles.cardRoot}>
        <Row className={styles.baseCard}>
          <div>
            <p className={styles.period}>
              {moment(advancedExpense.createdAt).format("dddd, DD MMM YYYY")}
            </p>
            <Mobile>
              <LongText limit={30} className={styles.description}>
                {advancedExpense.name}
              </LongText>
            </Mobile>
            <Tablet>
              <LongText limit={70} className={styles.description}>
                {advancedExpense.name}
              </LongText>
            </Tablet>
            <Desktop>
              <LongText limit={40} className={styles.description}>
                {advancedExpense.name}
              </LongText>
            </Desktop>
          </div>
          <Column
            className={clsx(
              styles.priceColumn,
              advancedExpense.approvedValue ? styles.positionBottom : "",
            )}
          >
            <p className={styles.price}>
              {utils.toCurrency(advancedExpense.requestedValue)}
            </p>
          </Column>
        </Row>

        {advancedExpense.approvedValue ? (
          <div className={styles.approvedValueDiv}>
            <div className={styles.approvedLabel}>
              <Desktop>
                <span>Pago</span>
              </Desktop>
              <TabletAndMobile>
                <span>Valor pago: </span>
              </TabletAndMobile>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                className={styles.price}
                style={{ color: defaultTheme.successTextColor }}
              >
                {utils.toCurrency(advancedExpense.approvedValue)}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <Flex
        py={["small"]}
        px={["medium", null, 24]}
        justifyContent={"space-between"}
        alignItems="center"
        border="1px solid"
        borderTop="none"
        borderColor="border"
        borderRadius={"0 0 4px 4px"}
        backgroundColor="rgba(0, 0, 0, 0.02)"
      >
        <Text color={defaultTheme.subTextColor} ml="auto" fontSize={0}>
          <Flex alignItems="center">
            <WarningIcon className={styles.discountableIcon} />
            <Text>Descontável</Text>
          </Flex>
        </Text>
      </Flex>
    </div>
  );
};

export { AdvancedExpenseItem };
