import { getAuthorizationHeader } from "@helpers/user.helper";

import { api } from "./interceptors";

export function getEvaluationStatus(evaluationToken) {
  return api
    .request({
      url: `/booking/support-evaluation/${evaluationToken}/status`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export function submitSupportEvaluation(evaluationToken, dto) {
  return api
    .request({
      url: `/booking/support-evaluation/${evaluationToken}/submit`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => data);
}
