import React, { useEffect, useMemo } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { formatCurrency } from "~/apps/shared/utils/format-currency";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryScreen } from "../itinerary-screen.context";
import { useItineraryServices } from "../itinerary-services.context";
import { useItineraryServiceChoiceContextDialogPresenter } from "./itinerary-service-choice-context-dialog.hooks";
import { styles } from "./styles";

export const ItineraryServiceChoiceContextDialog: React.FC = () => {
  const {
    servicePresenter,
  } = useItineraryServiceChoiceContextDialogPresenter();

  const { trackContextOfChoiceDialogView } = useUser();

  const {
    closeServiceChoiceContextDialog,
    isServiceChoiceContextDialogOpen,
  } = useItineraryScreen();
  const { travelPoliciesPerOffer } = useItineraryServices();

  const isSelectedServiceOutOfPolicy = useMemo(() => {
    if (!servicePresenter || !travelPoliciesPerOffer) {
      return false;
    }

    if (!(servicePresenter.getOfferToken() in travelPoliciesPerOffer)) {
      return false;
    }

    return travelPoliciesPerOffer[servicePresenter.getOfferToken()].outOfPolicy;
  }, [servicePresenter, travelPoliciesPerOffer]);

  useEffect(() => {
    if (!isServiceChoiceContextDialogOpen || !servicePresenter) {
      return;
    }

    void trackContextOfChoiceDialogView();
  }, [
    isServiceChoiceContextDialogOpen,
    servicePresenter,
    trackContextOfChoiceDialogView,
  ]);

  if (!servicePresenter || !servicePresenter.isFlightService()) {
    return null;
  }

  const choiceJustification = servicePresenter.getChoiceJustification();
  const smRecommendationContext = servicePresenter.getSmRecommendationContext();

  const smRecommendationContextCheapestFare =
    smRecommendationContext?.cheapestRate;

  return (
    <Dialog
      css={styles.root}
      onClose={closeServiceChoiceContextDialog}
      open={isServiceChoiceContextDialogOpen}
    >
      <DialogHeader
        icon={servicePresenter.getIcon()}
        onClose={closeServiceChoiceContextDialog}
      />
      <DialogContent css={styles.content.root}>
        <div css={styles.content.top.root}>
          <DialogTitle>Contexto da escolha do usuário:</DialogTitle>
          <span css={styles.content.top.message}>
            {servicePresenter.getFormattedItineraryServiceHeaderName()}.
          </span>
        </div>
        <div css={styles.content.service.root}>
          <Icon
            css={styles.content.service.icon}
            size={16}
            use={servicePresenter.getIcon()}
          />
          <span css={styles.content.service.period}>
            {servicePresenter.getFormattedPeriod()}
          </span>
          |
          <span css={styles.content.service.price}>
            {formatCurrency(servicePresenter.getPrice())}
          </span>
        </div>
        {choiceJustification ? (
          <div css={styles.content.section.root}>
            <span css={styles.content.section.label}>
              Justificativa do solicitante:
            </span>
            <p css={styles.content.section.box}>
              <em css={styles.content.section.italic}>{choiceJustification}</em>
            </p>
          </div>
        ) : null}
        {smRecommendationContext && smRecommendationContext.choiceIndex ? (
          <div css={styles.content.section.root}>
            <span css={styles.content.section.label}>
              Ranking dos mais recomendados
            </span>
            <p css={styles.content.section.box}>
              <strong
                css={styles.content.section.strong}
              >{`Nº ${smRecommendationContext.choiceIndex} `}</strong>
              {` dentre ${
                smRecommendationContext.resultsCount > 100
                  ? ""
                  : smRecommendationContext.resultsCount
              } resultados`}
              .
            </p>
          </div>
        ) : null}
        {smRecommendationContext && smRecommendationContext.optimalPrice ? (
          <div css={styles.content.section.root}>
            <span css={styles.content.section.label}>
              Preço do meio dos 50 mais recomendados:
            </span>
            <p css={styles.content.section.box}>
              {toCurrency(smRecommendationContext.optimalPrice)}
            </p>
          </div>
        ) : null}
        {servicePresenter.isFlightService() &&
        smRecommendationContextCheapestFare ? (
          <div css={styles.content.section.root}>
            <span css={styles.content.section.label}>
              Comparação das tarifas do voo escolhido:
            </span>
            <div css={styles.content.section.fares.root}>
              <TravelPlanServiceChoiceContextDialogFareDetails
                baggagePieces={servicePresenter.getCommomBaggagePieces()}
                color="blue"
                family={servicePresenter.getCommomFareFamily()}
                outOfPolicy={isSelectedServiceOutOfPolicy}
                price={servicePresenter.getPrice()}
                title="Tarifa Escolhida"
              />
              <TravelPlanServiceChoiceContextDialogFareDetails
                baggagePieces={
                  smRecommendationContextCheapestFare.baggagePieces
                }
                color="green"
                family={smRecommendationContextCheapestFare.family}
                outOfPolicy={!!smRecommendationContextCheapestFare.outOfPolicy}
                price={smRecommendationContextCheapestFare.price}
                title="Tarifa Mais Econômica"
              />
            </div>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

type TravelPlanServiceChoiceContextDialogFareDetailsProps = {
  baggagePieces: number | null;
  color: "blue" | "green";
  family: string | null;
  outOfPolicy: boolean;
  price: number;
  title: string;
};

const TravelPlanServiceChoiceContextDialogFareDetails: React.FC<TravelPlanServiceChoiceContextDialogFareDetailsProps> = ({
  baggagePieces,
  color,
  family,
  outOfPolicy,
  price,
  title,
}) => {
  return (
    <div css={styles.content.section.fares.column.root}>
      <span css={styles.content.section.fares.column.title({ color })}>
        {title}
      </span>
      <div css={styles.content.section.fares.column.field.root({ color })}>
        <span css={styles.content.section.fares.column.field.label({ color })}>
          Nome
        </span>
        <span css={styles.content.section.fares.column.field.value}>
          {family ? family.toLowerCase() : "N/A"}
        </span>
      </div>
      <div css={styles.content.section.fares.column.field.root({ color })}>
        <span css={styles.content.section.fares.column.field.label({ color })}>
          Bagagem Despachada
        </span>
        <Icon
          css={styles.content.section.fares.column.field.icon({ color })}
          size={16}
          use={baggagePieces && baggagePieces > 0 ? "check" : "x"}
        />
      </div>
      <div css={styles.content.section.fares.column.field.root({ color })}>
        <span css={styles.content.section.fares.column.field.label({ color })}>
          Preço
        </span>
        <span css={styles.content.section.fares.column.field.value}>
          {formatCurrency(price)}
        </span>
      </div>
      <div css={styles.content.section.fares.column.field.root({ color })}>
        <span css={styles.content.section.fares.column.field.label({ color })}>
          Dentro da Política
        </span>
        <span css={styles.content.section.fares.column.field.value}>
          <Icon
            css={styles.content.section.fares.column.field.icon({ color })}
            size={16}
            use={!outOfPolicy ? "check" : "x"}
          />
        </span>
      </div>
    </div>
  );
};
