import { useMemo } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { useItineraryInfo } from "../../../itinerary-info.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { ItineraryInfoCategorizationPresenterFactory } from "./itinerary-info-categorization.presenter";

export const useItineraryInfoCategorizationGuard = () => {
  const {
    errorOnFetch: errorOnFetchClientConfig,
    isLoading: isLoadingClientConfig,
  } = useClientConfig();

  const {
    errorOnFetch: errorOnFetchItineraryInfo,
    isLoading: isLoadingItineraryInfo,
  } = useItineraryInfo();

  return {
    error: errorOnFetchClientConfig || errorOnFetchItineraryInfo,
    loading: isLoadingClientConfig || isLoadingItineraryInfo,
  };
};

export const useItineraryInfoCategorizationPresenter = () => {
  const { clientConfig } = useClientConfig();

  const { infoModel } = useItineraryContainer();

  return useMemo(
    () => ({
      itineraryInfoCategorizationPresenter:
        clientConfig && infoModel
          ? ItineraryInfoCategorizationPresenterFactory.create(
              clientConfig,
              infoModel,
            )
          : null,
    }),
    [clientConfig, infoModel],
  );
};
