import * as cardApi from "../../../apis/card.api";
import * as encryptionApi from "../../../apis/encryption.api";
import { ERROR } from "../../../constants";
import errorTypes from "../../../constants/errors";
import {
  CardEncryptionModel,
  CardServiceTypes,
  CardModel
} from "../../../models/card.model";
import { CustomError } from "../../../types";

type CreateCorporateCardPayload = {
  cardHash: string;
  description: string;
  encryptionToken: string;
  serviceTypes: CardServiceTypes;
};

type CreateCorporatePublicCardPayload = CreateCorporateCardPayload & {
  billingProfileToken?: string | null;
};

type CreateCorporateIndividualCardPayload = CreateCorporateCardPayload & {
  allowApprovers: boolean;
};

export enum CorporateCardType {
  PUBLIC = "PUBLIC",
  INDIVIDUAL = "INDIVIDUAL"
}

export async function createCorporateCard(
  data: CreateCorporatePublicCardPayload | CreateCorporateIndividualCardPayload,
  type: CorporateCardType
): Promise<{ data?: CardModel; error?: CustomError }> {
  const result: { data?: CardModel; error?: CustomError } = {};

  try {
    if (type === CorporateCardType.PUBLIC) {
      result.data = await cardApi.createCorporatePublicCard(data);
    } else {
      result.data = await cardApi.createCorporateIndividualCard(data);
    }
  } catch (error) {
    if (error.response) {
      const { data: errorData } = error.response;
      const { type } = errorData;

      if (type === errorTypes.INVALID_CREDIT_CARD) {
        result.error = ERROR.INVALID_CARD_CREATION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_CARD_CREATION_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CARD_CREATION_ERROR;
    }
  }

  return result;
}

export async function getEncryptionDataResult(): Promise<{
  data?: CardEncryptionModel;
  error?: CustomError;
}> {
  const result: { data?: CardEncryptionModel; error?: CustomError } = {};

  try {
    result.data = await encryptionApi.getEncryptionKey();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CARD_CREATION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CARD_CREATION_ERROR;
    }
  }

  return result;
}
