import { useState } from "react";

type ChangesFunction<T> = (prevState: T) => T;

function useSafeState<T>(
  initialState: T
): [T, (changes: Partial<T> | ChangesFunction<T>) => void] {
  const [state, setState] = useState<T>(initialState);

  const setSafeState = (changes: Partial<T> | ChangesFunction<T>) => {
    if (typeof changes === "function") {
      setState(prevState => changes(prevState));
    } else {
      setState(prevState => Object.assign({}, prevState, changes));
    }
  };

  return [state, setSafeState];
}

export { useSafeState };
