import React from "react";

import { Icon } from "../../icon/icon";
import { styles } from "./styles";

export const Checkbox: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    variant?: "blue" | "default" | "green" | "pink";
  }
> = ({ className, disabled, variant = "default", ...props }) => {
  return (
    <label css={styles.root({ disabled })} className={className}>
      <input
        css={styles.input}
        disabled={disabled}
        type="checkbox"
        {...props}
      />
      <span css={styles.checkbox({ checked: !!props.checked, variant })}>
        <Icon
          css={styles.icon({ checked: !!props.checked })}
          size={12}
          use="check"
        />
      </span>
    </label>
  );
};
