import * as customFieldOptionApi from "~/apps/corporate/apis/custom-field-options.api";
import {
  CustomFieldOption,
  SubmitCustomFieldOptionsRequestBody,
} from "~/apps/corporate/dtos/custom-field-options.dto";
import { getClientToken } from "~/apps/corporate/helpers/user.helper";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export async function activateCustomFieldOption(
  customFieldOptionToken: string,
): Promise<{
  data?: CustomFieldOption;
  error?: Error;
}> {
  const result: { data?: CustomFieldOption; error?: Error } = {};

  try {
    result.data = await customFieldOptionApi.activateCustomFieldOption(
      customFieldOptionToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    }
  }

  return result;
}

export async function addCustomFieldOption(
  customFieldOptions: SubmitCustomFieldOptionsRequestBody[],
): Promise<{
  data?: CustomFieldOption[];
  error?: Error;
}> {
  const result: { data?: CustomFieldOption[]; error?: Error } = {};

  try {
    result.data = await customFieldOptionApi.addCustomFieldOptions(
      customFieldOptions,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_CUSTOM_FIELD_OPTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_CUSTOM_FIELD_OPTIONS_ERROR;
    }
  }

  return result;
}

export async function getClientCustomFieldOptions(): Promise<{
  data?: CustomFieldOption[];
  error?: Error;
}> {
  const result: { data?: CustomFieldOption[]; error?: Error } = {};

  const clientToken = getClientToken();

  if (!clientToken) {
    result.error = ERROR.UNEXPECTED_GET_CUSTOM_FIELD_OPTIONS_ERROR;

    return result;
  }

  try {
    result.data = await customFieldOptionApi.getClientCustomFieldOptions(
      clientToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_CUSTOM_FIELD_OPTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_CUSTOM_FIELD_OPTIONS_ERROR;
    }
  }

  return result;
}

export async function inactivateCustomFieldOption(
  customFieldOptionToken: string,
): Promise<{
  data?: CustomFieldOption;
  error?: Error;
}> {
  const result: { data?: CustomFieldOption; error?: Error } = {};

  try {
    result.data = await customFieldOptionApi.inactivateCustomFieldOption(
      customFieldOptionToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    }
  }

  return result;
}

export async function updateCustomFieldOption(
  customFieldOptionToken: string,
  customFieldOption: SubmitCustomFieldOptionsRequestBody,
): Promise<{
  data?: CustomFieldOption;
  error?: Error;
}> {
  const result: { data?: CustomFieldOption; error?: Error } = {};

  try {
    result.data = await customFieldOptionApi.updateCustomFieldOptions(
      customFieldOptionToken,
      customFieldOption,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR;
    }
  }

  return result;
}
