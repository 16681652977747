import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputError from "./InputError";
import commonStyles from "./common-styles";
import { PropTypes } from "prop-types";

class PasswordInput extends React.Component {
  state = {
    showPassword: false
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      error = false,
      errorMessage,
      visibilityChange = false,
      InputProps,
      notchedOutlineStyle,
      underlineHighlight,
      containerClasses,
      ...rest
    } = this.props;

    return (
      <div className={`${commonStyles.containerStyle} ${containerClasses}`}>
        <TextField
          {...rest}
          variant="outlined"
          error={error}
          type={this.state.showPassword ? "text" : "password"}
          InputProps={{
            ...InputProps,
            classes: {
              ...commonStyles.inputBaseStyles,
              notchedOutline: `${commonStyles.notchedOutlineStyle} ${notchedOutlineStyle}`,
              focused: underlineHighlight
                ? error
                  ? commonStyles.notchedOutlineFocused
                  : commonStyles.underlineFocused
                : commonStyles.notchedOutlineFocused,
              error: underlineHighlight
                ? commonStyles.underlineErrored
                : commonStyles.error
            },
            endAdornment: visibilityChange ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null
          }}
          InputLabelProps={{
            classes: commonStyles.inputLabelStyles
          }}
          inputProps={{
            className: commonStyles.inputStyles
          }}
        />
        {error && errorMessage ? <InputError>{errorMessage}</InputError> : null}
      </div>
    );
  }
}

PasswordInput.propTypes = {
  error: PropTypes.bool,
  visibilityChange: PropTypes.bool,
  errorMessage: PropTypes.string,
  InputProps: PropTypes.object,
  underlineHighlight: PropTypes.bool,
  notchedOutlineStyle: PropTypes.string,
  containerClasses: PropTypes.string
};

export { PasswordInput };
