import React from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { AlertBox } from "~/apps/shared/components/alert-box/alert-box";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";

const Component: React.FC = () => {
  const { clientConfig } = useClientConfig();

  if (!clientConfig) {
    return null;
  }

  if (!clientConfig.hasHomeWarning()) {
    return null;
  }

  const homeWarning = clientConfig.getHomeWarning();

  if (!homeWarning) {
    return null;
  }

  return (
    <AlertBox>
      <p
        dangerouslySetInnerHTML={{
          __html: homeWarning,
        }}
      />
    </AlertBox>
  );
};

export const NewTripOfferSearcherAlert: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
