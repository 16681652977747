import React from "react";

import { navigate } from "@reach/router";
import { theme } from "smartrips-skin";
import { Button, Text } from "smartrips-toolkit";

import { Capabilities } from "~/constants/enums";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import { styles } from "./styles";

export const ListEventsHeader: React.VFC = () => {
  const user = getUserFromLocalStorage();

  return (
    <div css={styles.root}>
      <div css={styles.left}>
        <Text fontSize="1.5rem" fontWeight="bold">
          Eventos
        </Text>
        <Text color={theme.colors.gray[2]} fontSize={14} lineHeight={1.5}>
          Veja os eventos relacionados a você.
        </Text>
      </div>
      {user?.capabilities[Capabilities.EVENT_ORGANIZER] ? (
        <Button
          css={{ height: "fit-content" }}
          onClick={() => {
            navigate("/events/new");
          }}
        >
          Novo Evento
        </Button>
      ) : null}
    </div>
  );
};
