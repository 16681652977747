import {
  AdvancedExpenseDto,
  AdvancedExpenseDetailsDto,
  AdvancedExpenseApprovers
} from "@dtos/advanced-expense.dto";
import { AdvancedExpense } from "@models/advanced-expense.model";
import { getLoggedUserToken } from "@helpers/user.helper";
import moment, { Moment } from "moment";

export function parseSingleAdvancedExpenseDto(
  dto: AdvancedExpenseDto
): AdvancedExpense {
  return {
    expenseAdvanceToken: dto.expense_advance_token,
    travelerToken: dto.traveler_token,
    name: dto.name,
    justification: dto.justification,
    requestedValue: dto.requested_value,
    approvedValue: dto.approved_value,
    travelStart: dto.travel_start as Moment,
    travelEnd: dto.travel_end as Moment,
    currency: dto.currency,
    status: dto.status,
    createdAt: dto.created_at,
    expenseReportToken: dto.expense_report_token
  };
}

export function parseAdvancedExpensesDto(
  dto: AdvancedExpenseDto[]
): AdvancedExpense[] {
  return dto.map(advancedExpense =>
    parseSingleAdvancedExpenseDto(advancedExpense)
  );
}

export function parseAdvanceExpenseCreation(
  data: Record<string, any>
): Partial<AdvancedExpenseDto> {
  return {
    traveler_token: getLoggedUserToken(),
    name: data.name,
    justification: data.justification ? data.justification : null,
    requested_value: data.value,
    travel_start: data.startDate,
    travel_end: data.endDate,
    currency: "BRL"
  };
}

export function parseAdvanceExpenseApprover(
  approver: AdvancedExpenseApprovers
) {
  return {
    stage: approver.stage,
    approvableApprovalRequestApproverToken:
      approver.approvable_approval_request_approver_token,
    repliedAt: approver.replied_at,
    userToken: approver.user_token,
    email: approver.email,
    firstName: approver.first_name,
    lastName: approver.last_name,
    role: approver.role,
    fullName: approver.full_name
  };
}

export function parseAdvancedExpenseDetails(
  data: AdvancedExpenseDetailsDto
): any {
  return {
    expenseAdvance: {
      expenseAdvanceToken: data.expenseAdvance.expense_advance_token,
      travelerToken: data.expenseAdvance.traveler_token,
      name: data.expenseAdvance.name,
      justification: data.expenseAdvance.justification,
      requestedValue: data.expenseAdvance.requested_value,
      approvedValue: data.expenseAdvance.approved_value,
      travelStart: moment(data.expenseAdvance.travel_start, "YYYY-MM-DD"),
      travelEnd: moment(data.expenseAdvance.travel_end, "YYYY-MM-DD"),
      currency: data.expenseAdvance.currency,
      status: data.expenseAdvance.status,
      createdAt: data.expenseAdvance.created_at,
      expenseReportToken: data.expenseAdvance.expense_report_token
    },
    expenseAdvanceRequest: {
      approvableApprovalRequestToken:
        data.expenseAdvanceRequest.approvable_approval_request_token,
      approvableApprovalProcessToken:
        data.expenseAdvanceRequest.approvable_approval_process_token,
      approvableToken: data.expenseAdvanceRequest.approvable_token,
      approvalStage: data.expenseAdvanceRequest.approval_stages,
      currentApprovalStage: data.expenseAdvanceRequest.current_approval_stage,
      status: data.expenseAdvanceRequest.status,
      responseMessage: data.expenseAdvanceRequest.response_message,
      active: data.expenseAdvanceRequest.active,
      closedAt: data.expenseAdvanceRequest.closed_at,
      createdAt: data.expenseAdvanceRequest.created_at,
      updatedAt: data.expenseAdvanceRequest.updated_at,
      expenseReportToken: data.expenseAdvanceRequest.expense_report_token,
      travelerToken: data.expenseAdvanceRequest.traveler_token
    },
    expenseAdvanceApprovers: data.expenseAdvanceApprovers.map(approver => ({
      stage: approver.stage,
      approvableApprovalRequestApproverToken:
        approver.approvable_approval_request_approver_token,
      repliedAt: approver.replied_at,
      userToken: approver.user_token,
      email: approver.email,
      firstName: approver.first_name,
      lastName: approver.last_name,
      role: approver.role,
      fullName: approver.full_name
    }))
  };
}
