import { snakeCaseToCamelCase } from "./snake-case-to-camel-case";

const deepClone = (obj: any, mapKeyFunc = (x: any) => x) => {
  let temp;
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) {
    return obj;
  }

  if (obj instanceof Date) {
    temp = new obj.constructor();
  }
  //or new Date(obj);
  else {
    temp = obj.constructor();
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj.isActiveClone = null;
      temp[mapKeyFunc(key)] = deepClone(obj[key], mapKeyFunc);
      delete obj.isActiveClone;
    }
  }
  return temp;
};

export const deepCloneMapToCamelCase = (obj: any) => {
  return deepClone(obj, snakeCaseToCamelCase);
};
