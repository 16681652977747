import React from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ServiceType } from "~/apps/shared/constants/enums";

import { Button } from "@toolkit/v2";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useTravelPlanAddServiceGuard } from "./itinerary-add-service.hooks";
import { styles } from "./styles";

type Props = {
  canAddOffers?: boolean;
};

export const TravelPlanAddService: React.FC<Props> = ({ canAddOffers }) => {
  const { loading } = useTravelPlanAddServiceGuard();

  const { clientConfig } = useClientConfig();

  const { openAddServiceDrawer } = useItineraryScreen();

  if (loading) {
    return <TravelPlanAddServiceSkeleton />;
  }

  if (!canAddOffers) {
    return null;
  }

  if (!clientConfig) {
    return null;
  }

  if (
    !clientConfig.isBusesVisible() &&
    !clientConfig.isCarsVisible() &&
    !clientConfig.isFlightsVisible() &&
    !clientConfig.isHotelsVisible()
  ) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Adicione outros serviços à sua viagem:</span>
      <hr css={styles.divisor} />
      <div css={styles.buttons.root}>
        {clientConfig.isFlightsVisible() ? (
          <Button
            css={styles.buttons.button.root}
            onClick={() => {
              openAddServiceDrawer(ServiceType.FLIGHT);
            }}
          >
            <Icon size={16} use="airplane-landing" />
            <span>Voo</span>
          </Button>
        ) : null}
        {clientConfig.isHotelsVisible() ? (
          <Button
            css={styles.buttons.button.root}
            onClick={() => {
              openAddServiceDrawer(ServiceType.HOTEL);
            }}
          >
            <Icon size={16} use="building" />
            <span>Hotel</span>
          </Button>
        ) : null}
        {clientConfig.isCarsVisible() ? (
          <Button
            css={styles.buttons.button.root}
            onClick={() => {
              openAddServiceDrawer(ServiceType.CAR);
            }}
          >
            <Icon size={16} use="car" />
            <span>Carro</span>
          </Button>
        ) : null}
        {clientConfig.isBusesVisible() ? (
          <Button
            css={styles.buttons.button.root}
            onClick={() => {
              openAddServiceDrawer(ServiceType.BUS);
            }}
          >
            <Icon size={16} use="bus" />
            <span>Ônibus</span>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const TravelPlanAddServiceSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Adicione outros serviços à sua viagem:</span>
      <hr css={styles.divisor} />
      <div css={styles.buttons.root}>
        <Button css={styles.buttons.button.root} disabled>
          <Icon size={16} use="airplane-landing" />
          <span>Voo</span>
        </Button>
        <Button css={styles.buttons.button.root} disabled>
          <Icon size={16} use="building" />
          <span>Hotel</span>
        </Button>
        <Button css={styles.buttons.button.root} disabled>
          <Icon size={16} use="car" />
          <span>Carro</span>
        </Button>
        <Button css={styles.buttons.button.root} disabled>
          <Icon size={16} use="bus" />
          <span>Ônibus</span>
        </Button>
      </div>
    </div>
  );
};
