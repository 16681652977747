import {
  parseAdvancedExpensesDto,
  parseSingleAdvancedExpenseDto,
  parseAdvancedExpenseDetails
} from "~/parsers/advanced-expense.parser";

import {
  AdvancedExpense,
  AdvancedExpenseDetails
} from "@models/advanced-expense.model";

import {
  getAuthorizationHeader,
  getLoggedUserToken,
  getClientToken
} from "../helpers/user.helper";
import { api } from "./interceptors";

export function fetchAdvancedExpenses(): Promise<AdvancedExpense[]> {
  return api
    .request({
      url: `/booking/users/${getLoggedUserToken()}/expenses/advances`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => parseAdvancedExpensesDto(response.data));
}

export function createAdvancedExpense(data: Partial<AdvancedExpense>) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/expenses/advances`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data
    })
    .then(response => parseSingleAdvancedExpenseDto(response.data));
}

export function fetchAdvancedExpenseDetails(
  advancedExpenseToken: string
): Promise<AdvancedExpenseDetails> {
  return api
    .request({
      url: `/booking/expenses/advances/${advancedExpenseToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => parseAdvancedExpenseDetails(response.data));
}

export function addAdvancedExpenseToReport(
  advancedExpenseToken: string,
  expenseReportToken: string
) {
  return api
    .request({
      url: `/booking/expense/report/${expenseReportToken}/add`,
      method: "POST",
      headers: getAuthorizationHeader(),
      params: {
        type: "expense-advance"
      },
      data: {
        expense_advance_token: advancedExpenseToken
      }
    })
    .then(response => response.data);
}
