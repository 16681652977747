import { AxiosResponse } from "axios";

import {
  CostCenterCreationRequest,
  CostCenterListResponseItem,
  CostCenterResponse,
  CostCenterListByUserResponseItem,
  CostCenterUpdateRequest
} from "../dtos/cost-center.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  CostCenterListItem,
  CostCenter,
  CostCenterUserListItem
} from "../models/cost-center.model";
import * as costCenterParser from "../parsers/cost-center.parser";
import { api } from "./interceptors";

export function createCostCenter(
  data: CostCenterCreationRequest
): Promise<any> {
  return api.request({
    url: `/booking/clients/${data.client_token}/cost-centers`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data
  });
}

export function updateCostCenter(
  costCenterToken: string,
  data: CostCenterUpdateRequest
): Promise<any> {
  return api.request({
    url: `/booking/cost-centers/${costCenterToken}`,
    method: "PUT",
    headers: getAuthorizationHeader(),
    data
  });
}

export function listCostCenters(): Promise<CostCenterListItem[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/cost-centers`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CostCenterListResponseItem[]>) =>
      response.data.map(item => costCenterParser.parseCostCenterListItem(item))
    );
}

export function listCostCentersByUser(
  userToken: string
): Promise<CostCenterUserListItem[]> {
  return api
    .request({
      url: `/booking/user/${userToken}/cost-centers/`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CostCenterListByUserResponseItem[]>) =>
      response.data.map(item =>
        costCenterParser.parseCostCenterListByUserItem(item)
      )
    );
}

export function getCostCenterByToken(token: string): Promise<CostCenter> {
  return api
    .request({
      url: `/booking/cost-centers/${token}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CostCenterResponse>) =>
      costCenterParser.parseCostCenter(response.data)
    );
}

export function archiveCostCenter(token: string): Promise<any> {
  return api.request({
    url: `/booking/cost-centers/${token}`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
}
