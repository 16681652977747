import styled from "@shared/styled";
import { css } from "@styled-system/css";

import { Text as ToolkitText } from "@toolkit";

export const PageContainer = styled("div")(
  css({
    width: "100%",
    margin: "0 auto",
    px: "medium",
    maxWidth: ["100%", 1250]
  })
);

export const Title = styled("span")(
  css({
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",

    display: ["none", "block"],
    mt: 40
  })
);

export const AdvanceInfo = styled("div")(
  css({
    width: "100%",
    maxWidth: ["100%", 740],
    height: "fit-content",
    pt: [24, 48],
    pb: [0, 48],
    px: [0, 72],

    borderRadius: 1,
    border: ["none", "1px solid"],
    borderColor: ["border", "border"]
  })
);

export const BlockText = styled(ToolkitText)(
  css({
    display: "block"
  })
);
