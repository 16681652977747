import React from "react";

import { Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";

import { Areas } from "./areas/areas";
import { Boards } from "./boards/boards";
import { Budgets } from "./budgets/index";
import { CostCenters } from "./cost-centers";
import { Projects } from "./projects/projects";
import { Tags } from "./tags";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <PermissionProtectedRoute
        component={Areas}
        path="/areas"
        permissions={["companySettings.areas"]}
      />
      <PermissionProtectedRoute
        component={Boards}
        path="/boards"
        permissions={["companySettings.areas"]}
      />
      <PermissionProtectedRoute
        component={Budgets}
        path="/budgets"
        permissions={[]}
      />
      <PermissionProtectedRoute
        component={CostCenters}
        path="/cost-centers"
        permissions={["companySettings.costCenters"]}
      />
      <PermissionProtectedRoute
        component={Projects}
        path="/projects"
        permissions={["companySettings.projects"]}
      />
      <Tags path="/tags" />
    </Router>
  );
};

export const CompanyConfigurations: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
