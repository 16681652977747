import React, { useState } from "react";

import DOMPurify from "dompurify";

import { styles } from "./styles";

type Props = Omit<React.HTMLAttributes<HTMLParagraphElement>, "children"> & {
  children: string;
  expansible?: boolean;
  limit: number;
};

export const LongText: React.VFC<Props> = ({
  children,
  expansible = false,
  limit,
  ...props
}) => {
  const [visible, setVisibility] = useState(false);

  const toggleVisibility = () => {
    setVisibility((prev) => !prev);
  };

  const shouldShowVisibilitySpan = expansible && children.length > limit;

  const text = visible
    ? children
    : children.length <= limit
    ? children
    : children.substring(0, limit) + "...";

  return (
    <div css={styles.root} {...props}>
      <p>{DOMPurify.sanitize(text)}</p>
      {shouldShowVisibilitySpan ? (
        <button css={styles.button} onClick={toggleVisibility}>
          {visible ? "Ver menos" : "Ver mais"}
        </button>
      ) : null}
    </div>
  );
};
