import React from "react";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { Card } from "~/apps/corporate/models/card.model";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";

import { Button } from "@toolkit/v2";

import {
  editPaymentMethodSchema,
  EditPaymentMethodSchema,
} from "./edit-payment-method.schema";
import { styles } from "./styles";

const serviceTypeOptions = [
  {
    key: "enableToBus",
    label: "Ônibus",
  },
  {
    key: "enableToCar",
    label: "Carro",
  },
  {
    key: "enableToFlight",
    label: "Aéreo",
  },
  {
    key: "enableToHotel",
    label: "Hotel",
  },
  {
    key: "enableToRide",
    label: "Táxi",
  },
  {
    key: "enableToOther",
    label: "Outros",
  },
];

type Props = {
  back: () => void;
  card: Card;
};

export const EditPaymentMethod: React.FC<Props> = ({ back, card }) => {
  const queryClient = useQueryClient();

  const { editCard, isLoadingCreateCard, isLoadingEditCard } = useCards();

  const form = useForm<EditPaymentMethodSchema>({
    defaultValues: {
      allowApprovers: card.allowApprovers,
      description: card.description,
      enableToBus: card.serviceTypes.bus,
      enableToCar: card.serviceTypes.car,
      enableToClient: !!card.billingProfileToken,
      enableToFlight: card.serviceTypes.flight,
      enableToHotel: card.serviceTypes.hotel,
      enableToOther: card.serviceTypes.other,
      enableToRide: card.serviceTypes.ride,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await editCard({
        allowApprovers: values.allowApprovers,
        description: values.description,
        enableToBus: values.enableToBus,
        enableToCar: values.enableToCar,
        enableToClient: values.enableToClient,
        enableToFlight: values.enableToFlight,
        enableToHotel: values.enableToHotel,
        enableToOther: values.enableToOther,
        enableToRide: values.enableToRide,
        token: card.token,
      });

      if (!sucess) {
        return;
      }

      void invalidateBillingProfilePayableCardsForTravel(queryClient);

      back();
    },
    schema: editPaymentMethodSchema,
  });

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>Apelido do cartão</span>
          <Form.Field
            name="description"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder="Digite um apelido para o cartão..."
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["description"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>Finalidades do cartão</span>
          <div css={styles.body.services.root}>
            {serviceTypeOptions.map((option) => (
              <label css={styles.body.services.checkbox} key={option.key}>
                <Checkbox
                  checked={form.watch(option.key)}
                  onChange={(e) => {
                    form.setValue(option.key, e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.body.services.label}>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
        <label css={styles.body.services.checkbox}>
          <Checkbox
            checked={form.watch("allowApprovers")}
            onChange={(e) => {
              form.setValue("allowApprovers", e.target.checked);
            }}
            variant="pink"
          />
          <span css={styles.body.services.label}>
            Viabilizar o uso deste cartão em minhas viagens por parte de
            aprovadores e solicitantes.
          </span>
        </label>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          Cancelar
        </Button>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          variant="pink"
        >
          Salvar
        </Button>
      </div>
    </Form>
  );
};
