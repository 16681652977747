import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  checkbox: {
    label: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    group: {
      item: css({
        width: "fit-content",
      }),
      root: css({
        gap: "2px",
        width: "fit-content",
      }),
    },
    star: css({
      color: theme.colors.gray[300],
    }),
  },
  root: css({
    color: theme.colors.gray[300],
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  text: css({
    fontSize: "0.875rem",
    lineHeight: "19.36px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
