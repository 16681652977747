import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    divisor: css({
      backgroundColor: theme.colors.gray[100],
      border: "none",
      height: "1px",
      margin: "8px 0",
      width: "100%",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    section: {
      box: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "16px",
      }),
      fares: {
        column: {
          field: {
            icon: ({ color }: { color: "blue" | "green" }) =>
              css({
                color: theme.colors[color][700],
              }),
            label: ({ color }: { color: "blue" | "green" }) =>
              css({
                color: theme.colors[color][700],
                fontSize: "0.875rem",
                fontWeight: 600,
              }),
            root: ({ color }: { color: "blue" | "green" }) =>
              css({
                alignItems: "center",
                backgroundColor: color === "blue" ? "#c0dbfd" : "#b2ffda",
                border: `1px solid ${theme.colors.gray[100]}`,
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "16px",
                width: "100%",
              }),
            value: css({
              color: theme.colors.gray[700],
              fontSize: "0.875rem",
              textTransform: "capitalize",
            }),
          },
          root: css({
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }),
          title: ({ color }: { color: "blue" | "green" }) =>
            css({
              color: theme.colors[color][700],
              fontSize: "0.75rem",
              fontWeight: 700,
              textTransform: "uppercase",
            }),
        },
        root: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          display: "flex",
          gap: "16px",
          padding: "16px",
          width: "100%",
        }),
      },
      italic: css({
        fontStyle: "italic",
      }),
      label: css({
        color: theme.colors.gray[700],
        fontSize: "1rem",
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        fontSize: "0.875rem",
        gap: "8px",
      }),
      strong: css({
        fontWeight: 600,
      }),
    },
    service: {
      icon: css({}),
      period: css({
        flex: 1,
        fontSize: "0.875rem",
      }),
      price: css({
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.blue[700],
        border: `1px solid ${theme.colors.blue[700]}`,
        borderRadius: "8px",
        color: theme.colors.white,
        display: "flex",
        gap: "16px",
        padding: "16px",
        width: "100%",
      }),
    },
    top: {
      message: css({
        color: theme.colors.gray[300],
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  root: css({
    ".MuiPaper-root": {
      overflowY: "auto",
      "@media (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
};
