import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { css as oldCss } from "emotion";
import { useTheme } from "../../../hooks";

const getClasses = theme => ({
  tabs: oldCss({
    margin: "0.5rem 0 0 0",
    borderBottom: "1px solid #FAFAFA",
    minHeight: "36px"
  }),
  indicator: oldCss({
    backgroundColor: theme.colors.primary,
    borderRadius: theme.radii[1]
  }),
  scroller: oldCss({
    height: 30
  }),
  tab: oldCss({
    fontSize: theme.fontSizes[1] + "px",
    fontWeight: theme.fontWeights.body,
    padding: `0 ${theme.space[2]}px`,
    paddingBottom: "0.1rem",
    fontFamily: "'Open sans',sans-serif",
    textTransform: "none",
    minHeight: "30px",
    minWidth: "75px",
    borderRadius: theme.radii[1],
    letterSpacing: "0.04rem"
  }),
  selected: oldCss({
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.bold
  })
});

const BusTypeTabs = ({ value, onChange }) => {
  const theme = useTheme();
  const classes = getClasses(theme);

  return (
    <Tabs
      value={value}
      onChange={onChange}
      className={classes.tabs}
      classes={{
        indicator: classes.indicator,
        scroller: classes.scroller
      }}
    >
      <Tab
        label="Ida e volta"
        value="roundtrip"
        classes={{
          root: classes.tab,
          selected: classes.selected
        }}
      />
      <Tab
        label="Só ida"
        value="oneway"
        classes={{
          root: classes.tab,
          selected: classes.selected
        }}
      />
    </Tabs>
  );
};

export { BusTypeTabs };
