import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  input: css({
    backgroundColor: "transparent",
    border: "none",
    flex: 1,
    fontFamily: theme.fonts.inter,
    outline: "none",
    padding: "18.5px 0",
    "&::placeholder": {
      color: theme.colors.gray[100],
    },
  }),
  root: css({
    alignItems: "center",
    color: theme.colors.gray[700],
    display: "flex",
    gap: "12px",
    position: "relative",
    transition: "all 0.15s ease-in-out",
    width: "100%",
    "&:hover": {
      borderColor: theme.colors.gray[300],
    },
  }),
};
