import { OfferStatus } from "~/apps/shared/constants/enums";
import moment from "moment";

import {
  BusItineraryServicePresenter,
  CarItineraryServicePresenter,
  FlightItineraryServicePresenter,
  HotelItineraryServicePresenter,
  ItineraryServicePresenter,
} from "../models/itinerary/itinerary-service.presenter";

const filterServicesByOfferStatus = (
  services: ItineraryServicePresenter[],
  offerStatus: OfferStatus,
) => {
  const servicesGroupedByOfferStatus = services.filter(
    (service) => service.getStatus() === offerStatus,
  );

  return servicesGroupedByOfferStatus;
};

const getPresentationServicesForOfferStatus = (
  services: ItineraryServicePresenter[],
  offerStatus: OfferStatus,
) => {
  const servicesFilteredByOfferStatus = filterServicesByOfferStatus(
    services,
    offerStatus,
  );

  const {
    busServices,
    carServices,
    flightServices,
    hotelServices,
  } = segregateServicesByType(servicesFilteredByOfferStatus);

  const servicesSortedByType = [
    ...busServices,
    ...carServices,
    ...flightServices,
    ...hotelServices,
  ];

  const servicesSortedByDate = sortServicesByDate(servicesSortedByType);

  return groupServicesByDate(servicesSortedByDate);
};

export const getPresentationServicesGroupedByOfferStatus = (
  services: ItineraryServicePresenter[],
) => {
  if (services.length === 0) {
    return {
      [OfferStatus.APPROVAL_DECLINED]: [],
      [OfferStatus.BOOKING_FAILED]: [],
      [OfferStatus.BOOKING_PROCESSING]: [],
      [OfferStatus.CANCELED]: [],
      [OfferStatus.CANCELING]: [],
      [OfferStatus.DRAFT]: [],
      [OfferStatus.EMITTED]: [],
    };
  }

  const approvalDeclinedServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.APPROVAL_DECLINED,
  );
  const bookingFailedServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.BOOKING_FAILED,
  );
  const bookingProcessingServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.BOOKING_PROCESSING,
  );
  const canceledServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.CANCELED,
  );
  const cancelingServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.CANCELING,
  );
  const draftServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.DRAFT,
  );
  const emittedServices = getPresentationServicesForOfferStatus(
    services,
    OfferStatus.EMITTED,
  );

  return {
    [OfferStatus.APPROVAL_DECLINED]: approvalDeclinedServices,
    [OfferStatus.BOOKING_FAILED]: bookingFailedServices,
    [OfferStatus.BOOKING_PROCESSING]: bookingProcessingServices,
    [OfferStatus.CANCELED]: canceledServices,
    [OfferStatus.CANCELING]: cancelingServices,
    [OfferStatus.DRAFT]: draftServices,
    [OfferStatus.EMITTED]: emittedServices,
  };
};

const groupServicesByDate = (services: ItineraryServicePresenter[]) => {
  const servicesByDate = services.reduce((prev, curr) => {
    if (curr.isBusService() || curr.isFlightService()) {
      const presentationJourneys = curr.getPresentationJourneys();

      const firstJourney = presentationJourneys[0];

      const startDate = moment(firstJourney.departure.date).format(
        "YYYY-MM-DD",
      );

      if (!(startDate in prev)) {
        prev[startDate] = [];
      }

      prev[startDate].push(curr);

      return prev;
    }

    const startDate = curr.getStartDate().format("YYYY-MM-DD");

    if (!(startDate in prev)) {
      prev[startDate] = [];
    }

    prev[startDate].push(curr);

    return prev;
  }, {} as Record<string, ItineraryServicePresenter[]>);

  const servicesGroupedByDate = Object.keys(servicesByDate).map((date) => ({
    date,
    services: servicesByDate[date],
  }));

  return servicesGroupedByDate.sort((a, b) => {
    const dateA = moment(a.date, "YYYY-MM-DD");
    const dateB = moment(b.date, "YYYY-MM-DD");

    return dateA.diff(dateB);
  });
};

const segregateServicesByType = (services: ItineraryServicePresenter[]) => {
  return services.reduce(
    (prev, curr) => {
      if (curr.isBusService()) {
        prev.busServices.push(curr);

        return prev;
      }

      if (curr.isCarService()) {
        prev.carServices.push(curr);

        return prev;
      }

      if (curr.isFlightService()) {
        prev.flightServices.push(curr);

        return prev;
      }

      if (curr.isHotelService()) {
        prev.hotelServices.push(curr);

        return prev;
      }

      return prev;
    },
    {
      busServices: [],
      carServices: [],
      flightServices: [],
      hotelServices: [],
    } as {
      busServices: BusItineraryServicePresenter[];
      carServices: CarItineraryServicePresenter[];
      flightServices: FlightItineraryServicePresenter[];
      hotelServices: HotelItineraryServicePresenter[];
    },
  );
};

const sortServicesByDate = (services: ItineraryServicePresenter[]) => {
  return services.sort((a, b) => {
    return a.getStartDate().diff(b.getStartDate());
  });
};
