import * as Sentry from "@sentry/react";

import { Error as CustomError } from "~/apps/shared/types/index";
import { AxiosError } from "axios";
import { APP_ERROR_CODES } from "sm-types/errors";

import { ERROR } from "../constants/errors";
import { logger } from "./logger";

class AxiosErrorFormatter {
  constructor(private readonly fallbackError: CustomError = ERROR.UNEXPECTED) {}

  public format(error: AxiosError) {
    const { response } = error;

    if (!response) {
      return this.handleConnectionError();
    }

    const { data, status } = response;

    if (!data) {
      if (status === 400) {
        return this.handleBadRequestError();
      }

      if (status === 403) {
        return this.handleForbiddenError();
      }

      if (status === 403) {
        return this.handleConflictError();
      }

      if (status === 500) {
        return this.handleInternalServerError(error);
      }

      return this.fallbackError;
    }

    const { type } = data;

    if (!type) {
      return this.fallbackError;
    }

    const messageFromAppErrorCodes = getMessageFromAppErrorCodes(type);

    if (messageFromAppErrorCodes) {
      return {
        ...this.fallbackError,
        description: messageFromAppErrorCodes,
      };
    }

    return this.fallbackError;
  }

  private handleBadRequestError() {
    return ERROR.GENERAL_BAD_REQUEST_ERROR;
  }

  private handleConflictError() {
    return ERROR.GENERAL_CONFLICT_ERROR;
  }

  private handleConnectionError() {
    return ERROR.GENERAL_CONNECTION_ERROR;
  }

  private handleForbiddenError() {
    return ERROR.GENERAL_FORBIDDEN_ERROR;
  }

  private handleInternalServerError(error: AxiosError) {
    this.notifySentryAboutAxiosError(error);

    return ERROR.GENERAL_BAD_REQUEST_ERROR;
  }

  private notifySentryAboutAxiosError(error: AxiosError) {
    Sentry.withScope((scope) => {
      scope.setTag("axios", true);

      Sentry.captureException(error);
    });
  }
}

type FormatServiceErrorParams = {
  error?: any;
  fallback?: CustomError;
};

export const formatServiceError = (
  params?: FormatServiceErrorParams,
): CustomError => {
  if (!params) {
    return ERROR.UNEXPECTED;
  }

  const { error, fallback } = params;

  const serviceErrorFormatter = new ServiceErrorFormatter(fallback);

  return serviceErrorFormatter.format(error);
};

const getMessageFromAppErrorCodes = (type: string) => {
  let message = "";

  Object.values(APP_ERROR_CODES).forEach((errorTypeObject) => {
    if (Object.keys(errorTypeObject).includes(type)) {
      message = (errorTypeObject[type as keyof typeof errorTypeObject] as any)
        .messageLangMap?.["PT-BR"];
    }
  });

  return message;
};

const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError === true;
};

const isAlreadyFormattedError = (error: any): error is CustomError => {
  return (
    typeof error === "object" && "description" in error && "title" in error
  );
};

class ServiceErrorFormatter {
  constructor(private readonly fallbackError: CustomError = ERROR.UNEXPECTED) {}

  public format(error: any) {
    if (!error) {
      return this.fallbackError;
    }

    if (isAlreadyFormattedError(error)) {
      return this.handleAlreadyFormattedError(error);
    }

    if (isAxiosError(error)) {
      return this.handleAxiosError(error);
    }

    return this.handleUnexpectedError(error);
  }

  private handleAlreadyFormattedError(error: CustomError) {
    return error;
  }

  private handleAxiosError(error: AxiosError) {
    const axiosErrorFormatter = new AxiosErrorFormatter(this.fallbackError);

    return axiosErrorFormatter.format(error);
  }

  private handleUnexpectedError(error: any) {
    logger.error(error);
    this.notifySentryAboutUnexpectedError(error);

    return ERROR.UNEXPECTED;
  }

  private notifySentryAboutUnexpectedError(error: any) {
    Sentry.withScope((scope) => {
      scope.setTag("unexpected", true);

      Sentry.captureException(error);
    });
  }
}
