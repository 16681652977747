import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: css({
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "1440px",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "0 0 8px 0",
    },
    "@media (max-width: 992px)": {
      padding: "0",
    },
  }),
  info: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    lineHeight: "24px",
    margin: "16px 80px",
    padding: "32px",
    "@media (max-width: 1280px)": {
      margin: "16px 24px",
      padding: "24px",
    },
    "@media (max-width: 992px)": {
      margin: "16px",
    },
    "@media (max-width: 640px)": {
      padding: "16px",
    },
  }),
  map: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    height: "512px",
    margin: "0 80px 16px 80px",
    overflow: "hidden",
    "@media (max-width: 1280px)": {
      margin: "0 24px 16px 24px",
    },
    "@media (max-width: 992px)": {
      margin: "0 16px 16px 16px",
    },
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 640px)": {},
  }),
  top: css({
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 640px)": {
      flexDirection: "column-reverse",
    },
  }),
};
