import { CurrencyCode, ServiceType } from "~/apps/shared/constants/enums";
import { logger } from "~/apps/shared/utils/logger";

import { OfferTraveler } from "../offer.model";
import {
  BusItineraryServiceModel,
  CarItineraryServiceModel,
  FlightItineraryServiceModel,
  HotelItineraryServiceModel,
  ItineraryBusService,
  ItineraryBusSpecialFields,
  ItineraryCarService,
  ItineraryCarSpecialFields,
  ItineraryFlightService,
  ItineraryFlightSpecialFields,
  ItineraryHotelService,
  ItineraryHotelSpecialFields,
  ItineraryService,
  ItineraryServiceModel,
  ItineraryServiceModelFactory,
} from "./itinerary-service.model";

export type ItineraryServices = ItineraryService<
  | ItineraryBusSpecialFields
  | ItineraryCarSpecialFields
  | ItineraryFlightSpecialFields
  | ItineraryHotelSpecialFields
>[];

export class ItineraryServicesModel {
  protected services: {
    busServices: BusItineraryServiceModel[];
    carServices: CarItineraryServiceModel[];
    flightServices: FlightItineraryServiceModel[];
    hotelServices: HotelItineraryServiceModel[];
  } = {
    busServices: [],
    carServices: [],
    flightServices: [],
    hotelServices: [],
  };

  constructor(services: {
    busServices?: BusItineraryServiceModel[];
    carServices?: CarItineraryServiceModel[];
    flightServices?: FlightItineraryServiceModel[];
    hotelServices?: HotelItineraryServiceModel[];
  }) {
    this.services = {
      ...this.services,
      ...services,
    };
  }

  public getAllServiceOfferTokens() {
    return this.getAllServices().map((service) => service.getOfferToken());
  }

  public getAllServices(): ItineraryServiceModel[] {
    return [
      ...this.getBusServices(),
      ...this.getCarServices(),
      ...this.getFlightServices(),
      ...this.getHotelServices(),
    ];
  }

  public getBusServices(): BusItineraryServiceModel[] {
    return this.services.busServices;
  }

  private getCanceledServices() {
    const allServices = this.getAllServices();

    const frustratedPurchasesServices = allServices.filter((service) =>
      service.isCanceled(),
    );

    return frustratedPurchasesServices;
  }

  public getCarServices(): CarItineraryServiceModel[] {
    return this.services.carServices;
  }

  public getEmittedOrProcessingServices() {
    return this.getAllServices().filter((service) => service.isReserved());
  }

  public getFlightServices(): FlightItineraryServiceModel[] {
    return this.services.flightServices;
  }

  public getHotelServices(): HotelItineraryServiceModel[] {
    return this.services.hotelServices;
  }

  public getProcessableServices() {
    return this.getAllServices().filter((service) => service.isProcessable());
  }

  public getProcessableServicesWithPotentialMultipleTravelers() {
    return this.getProcessableServices().filter((service) =>
      service.isHotelService(),
    );
  }

  public getServiceByOfferToken(offerToken: string) {
    const service = this.getAllServices().find(
      (service) => service.getOfferToken() === offerToken,
    );

    if (!service) {
      return null;
    }

    return service;
  }

  public hasAnyCanceledOffer() {
    return this.getCanceledServices().length > 0;
  }

  public hasAnyEmittedFlightOffer() {
    return this.getFlightServices().some((service) => service.isEmitted());
  }

  public hasAnyEmittedOffer() {
    return this.getAllServices().some((service) => service.isEmitted());
  }

  public hasEnoughTravelers(offersTravelers: Record<string, OfferTraveler[]>) {
    const processableServicesWithPotentialMultipleTravelers = this.getProcessableServicesWithPotentialMultipleTravelers();

    return processableServicesWithPotentialMultipleTravelers.every(
      (service) => {
        const offerToken = service.getOfferToken();

        if (!(offerToken in offersTravelers)) {
          logger.error("offer token not found in offers' travelers");

          return false;
        }

        const serviceTotalTravelers = service.getTotalTravelers();

        if (serviceTotalTravelers === 1) {
          return true;
        }

        const offerTravelers = offersTravelers[offerToken];

        return offerTravelers.length === serviceTotalTravelers;
      },
    );
  }

  public hasOffersToProceed() {
    return this.getProcessableServices().length > 0;
  }

  public isSomeOfferNotInBRL() {
    return this.getAllServices().some(
      (service) => service.getCurrency() !== CurrencyCode.BRL,
    );
  }
}

export class ItineraryServicesModelFactory {
  static create(services: ItineraryServices) {
    const busServices = (services.filter(
      (s) => s.type === ServiceType.BUS,
    ) as ItineraryBusService[]).map((s) =>
      ItineraryServiceModelFactory.createBus(s),
    );

    const carServices = (services.filter(
      (s) => s.type === ServiceType.CAR,
    ) as ItineraryCarService[]).map((s) =>
      ItineraryServiceModelFactory.createCar(s),
    );

    const flightServices = (services.filter(
      (s) => s.type === ServiceType.FLIGHT,
    ) as ItineraryFlightService[]).map((s) =>
      ItineraryServiceModelFactory.createFlight(s),
    );

    const hotelServices = (services.filter(
      (s) => s.type === ServiceType.HOTEL,
    ) as ItineraryHotelService[]).map((s) =>
      ItineraryServiceModelFactory.createHotel(s),
    );

    return new ItineraryServicesModel({
      busServices,
      carServices,
      flightServices,
      hotelServices,
    });
  }
}
