import React from "react";

import {
  ItineraryServicePresenter,
  ItineraryServicesGroupedByOfferStatus,
} from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { OfferStatus } from "~/apps/shared/constants/enums";
import moment from "moment";

import { useItineraryScreen } from "../../pages/travels/itinerary/itinerary-screen.context";
import { ItineraryJourneySuggestion } from "./itinerary-service-journey/itinerary-journey-suggestion/itinerary-journey-suggestion";
import { ItineraryServiceJourney } from "./itinerary-service-journey/itinerary-service-journey";
import { styles } from "./styles";

type Props = {
  canAddOffers?: boolean;
  color: string;
  groups: ItineraryServicesGroupedByOfferStatus[OfferStatus];
  isApprovalReview?: boolean;
  isTravelPlan?: boolean;
  onSelect?: (servicePresenter: ItineraryServicePresenter) => void;
  selectedServiceTokens?: string[];
  title?: string;
  unavailableServiceTokens?: string[];
};

const Component: React.FC<Props> = ({
  canAddOffers,
  color,
  groups,
  isApprovalReview,
  isTravelPlan,
  onSelect,
  selectedServiceTokens,
  title,
  unavailableServiceTokens,
}) => {
  const { openAddServiceDrawer } = useItineraryScreen();

  return (
    <div css={styles.root}>
      {title ? <span css={styles.title({ color })}>{title}</span> : null}
      <ul css={styles.groups.root({ shiftRight: !!onSelect })}>
        {groups.map((group) => (
          <li css={styles.groups.group.root} key={group.date}>
            <span
              css={styles.groups.group.date({
                shiftRight: !!onSelect,
              })}
            >
              {moment(group.date).format("dddd, DD [de] MMMM")}
            </span>
            <ul css={styles.groups.group.cards}>
              {group.services.map((servicePresenter, index) => {
                const isSelected = selectedServiceTokens
                  ? selectedServiceTokens.includes(
                      servicePresenter.getOfferToken(),
                    )
                  : false;

                const isUnavailable = unavailableServiceTokens
                  ? unavailableServiceTokens.includes(
                      servicePresenter.getOfferToken(),
                    )
                  : false;

                return (
                  <React.Fragment key={index}>
                    <ItineraryServiceJourney
                      isApprovalReview={isApprovalReview}
                      isTravelPlan={isTravelPlan}
                      onSelect={onSelect}
                      isSelected={isSelected}
                      isUnavailable={isUnavailable}
                      servicePresenter={servicePresenter}
                    />
                    {canAddOffers && servicePresenter.suggestion ? (
                      <ItineraryJourneySuggestion
                        openAddServiceDrawer={openAddServiceDrawer}
                        suggestion={servicePresenter.suggestion}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ItineraryPresentationalServices: React.FC<Props> = ({
  ...props
}) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
