export function parseCategoryFormData(data) {
  const result = {};

  if (data.name) {
    result.name = data.name;
  }
  if (data.expenseFamily) {
    result.expense_family = data.expenseFamily;
  }
  if (data.expenseCategory) {
    result.expense_category = data.expenseCategory;
  }
  if (data.color) {
    result.color = data.color;
  }

  return result;
}
