import { Coords } from "google-map-react";
import React, { createContext, useEffect } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import * as carsHelper from "~/apps/corporate/helpers/car.helper";
import {
  CarListFilters,
  CarOffer,
  CarSearchInfo,
  RentalOption,
} from "~/apps/corporate/models/car.model";
import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import { CarPolicy } from "~/apps/corporate/models/policy.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { useSafeState } from "~/apps/shared/hooks/use-safe-state";
import { Error } from "~/apps/shared/types";

import * as carsService from "./cars.service";

interface Actions {
  fetchCarsList: (carToken: string) => Promise<void>;
  handleAirConditioningFilterChange: (_: any, checked: boolean) => void;
  handleApplyAddressFilter: (rentalOption: RentalOption) => () => void;
  handleApplyFilters: (newFilters: CarListFilters) => () => void;
  handleClassesFilterChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleClearAddressFilter: () => void;
  handleCloseEditionDrawer: () => void;
  handleCloseFiltersDrawer: () => void;
  handleCloseOutOfPolicyDialog: () => void;
  handleDoorsFilterChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleExpiration: (hasExpired: boolean) => void;
  handleInPolicyOnlyFilterChange: (_: any, checked: boolean) => void;
  handleOpenEditionDrawer: () => void;
  handleOpenFiltersDrawer: () => void;
  handleOpenOutOfPolicyDialog(car: CarOffer): () => void;
  handlePriceFilterChange: (range: [number, number]) => void;
  handleRentalsFilterChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleShowAgencyOnMap: (car: CarOffer) => () => void;
  handleToggleCategoryExplanation: () => void;
  handleToggleMapVisibility: () => void;
  handleTogglePolicyInfoDialogVisibility: () => void;
  handleTransmissionFilterChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

type FiltersState = CarListFilters & {
  filtering: boolean;
  isFilterDrawerVisible: boolean;
};

const initialFiltersState: FiltersState = {
  addressFilter: null,
  airConditioningOnly: false,
  classesOptions: [],
  doorsOptions: [],
  filtering: false,
  inPolicyOnly: false,
  isFilterDrawerVisible: false,
  priceRange: [0, 0],
  rentalsOptions: [],
  selectedPriceRange: [0, 0],
  transmissionOptions: [
    { id: "manual", label: "Manual", checked: false },
    { id: "automatic", label: "Automática", checked: false },
  ],
};

type State = {
  addingOffer: boolean;
  cars: CarOffer[];
  countryRestriction?: CountryRestriction;
  errorOnFetch: Error | null;
  expirationTime: Date | null;
  hasExpired: boolean;
  isCategoryExplanationVisible: boolean;
  isEditing: boolean;
  isFakeLoading: boolean;
  isLoading: boolean;
  isMapVisible: boolean;
  isPolicyInfoDialogVisible: boolean;
  policy?: CarPolicy;
  searchInfo: CarSearchInfo | null;
  selectedAgencyCoords: Coords | null;
  selectedOutOfPolicyOffer: CarOffer | null;
  visibleCars: CarOffer[];
};

const initialState: State = {
  addingOffer: false,
  cars: [],
  countryRestriction: undefined,
  errorOnFetch: null,
  expirationTime: null,
  hasExpired: false,
  isCategoryExplanationVisible: false,
  isEditing: false,
  isFakeLoading: true,
  isLoading: true,
  isMapVisible: false,
  isPolicyInfoDialogVisible: false,
  policy: undefined,
  searchInfo: null,
  selectedAgencyCoords: null,
  selectedOutOfPolicyOffer: null,
  visibleCars: [],
};

type ContextProps = Actions &
  State & {
    filters: FiltersState;
  };

const CarsContext = createContext<ContextProps>({
  ...initialState,
  fetchCarsList: async () => {
    return;
  },
  filters: initialFiltersState,
  handleAirConditioningFilterChange: () => null,
  handleApplyAddressFilter: () => () => null,
  handleApplyFilters: () => () => null,
  handleClassesFilterChange: () => null,
  handleClearAddressFilter: () => null,
  handleCloseEditionDrawer: () => null,
  handleCloseFiltersDrawer: () => null,
  handleCloseOutOfPolicyDialog: () => null,
  handleDoorsFilterChange: () => null,
  handleExpiration: () => null,
  handleInPolicyOnlyFilterChange: () => null,
  handleOpenEditionDrawer: () => null,
  handleOpenFiltersDrawer: () => null,
  handleOpenOutOfPolicyDialog: () => () => null,
  handlePriceFilterChange: () => null,
  handleRentalsFilterChange: () => null,
  handleShowAgencyOnMap: () => () => null,
  handleToggleCategoryExplanation: () => null,
  handleToggleMapVisibility: () => null,
  handleTogglePolicyInfoDialogVisibility: () => null,
  handleTransmissionFilterChange: () => null,
});

export const CarsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [filters, updateFilters] = useSafeState(initialFiltersState);
  const [state, setState] = useSafeState(initialState);

  const fetchCarsList = async (carToken: string) => {
    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const { data: carData, error: carError } = await carsService.getCarsList(
      carToken,
    );

    if (carError) {
      setState((prev) => ({
        ...prev,
        errorOnFetch: carError,
        isLoading: false,
      }));
      showSnackMessage(carError.description, ALERT_TYPES.ERROR);

      return;
    }

    const { filtersInfo } = carData!;

    const priceRange: [number, number] = [
      filtersInfo.minPrice ? filtersInfo.minPrice : 0,
      filtersInfo.maxPrice ? filtersInfo.maxPrice : 0,
    ];

    updateFilters((prev) => ({
      ...prev,
      classesOptions: filtersInfo.classesOptions.map((item) => ({
        ...item,
        checked: false,
      })),
      doorsOptions: filtersInfo.doorsOptions.map((item) => ({
        checked: false,
        id: item,
        label: item + " portas",
      })),
      priceRange,
      rentalsOptions: filtersInfo.rentalsOptions.map((item) => ({
        ...item,
        checked: false,
      })),
      selectedPriceRange: priceRange,
    }));

    setState((prev) => ({
      ...prev,
      cars: carData!.carList,
      countryRestriction: carData!.countryRestriction,
      expirationTime: carData!.expirationTime,
      isLoading: false,
      policy: carData!.policy,
      searchInfo: carData!.searchInfo,
      visibleCars: carData!.carList,
    }));
  };

  const handleExpiration = (hasExpired: boolean) => {
    setState((prevProps) => ({
      ...prevProps,
      hasExpired,
    }));
  };

  const handleOpenEditionDrawer = () => {
    setState((prev) => ({ ...prev, isEditing: true }));
  };

  const handleCloseEditionDrawer = () => {
    setState((prev) => ({ ...prev, isEditing: false }));
  };

  const handleToggleMapVisibility = () =>
    setState((prev) => ({
      ...prev,
      isMapVisible: !prev.isMapVisible,
      selectedAgencyCoords: null,
    }));

  const handleShowAgencyOnMap = (car: CarOffer) => () => {
    const { latitude, longitude } = car.pickup;

    setState((prev) => ({
      ...prev,
      isMapVisible: true,
      selectedAgencyCoords: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      },
    }));
  };

  const handleOpenOutOfPolicyDialog = (car: CarOffer) => () => {
    setState((prev) => ({
      ...prev,
      selectedOutOfPolicyOffer: car,
    }));
  };

  const handleCloseOutOfPolicyDialog = () => {
    setState((prev) => ({
      ...prev,
      selectedOutOfPolicyOffer: null,
    }));
  };

  const handleToggleCategoryExplanation = () => {
    setState((prev) => ({
      ...prev,
      isCategoryExplanationVisible: !prev.isCategoryExplanationVisible,
    }));
  };

  const handleTogglePolicyInfoDialogVisibility = () => {
    setState((prev) => ({
      ...prev,
      isPolicyInfoDialogVisible: !prev.isPolicyInfoDialogVisible,
    }));
  };

  // Filters changes
  const handleClassesFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedClassesOptions = filters.classesOptions.map((option) => {
      if (option.id === e.target.id) {
        return { ...option, checked };
      }

      return option;
    });

    const newFilters = {
      ...filters,
      classesOptions: updatedClassesOptions,
    };

    filterCarResults(newFilters);
  };

  const handleRentalsFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedRentalsOptions = filters.rentalsOptions.map((option) => {
      if (option.id === e.target.id) {
        return { ...option, checked };
      }

      return option;
    });

    const newFilters = {
      ...filters,
      rentalsOptions: updatedRentalsOptions,
    };

    filterCarResults(newFilters);
  };

  const handleDoorsFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedDoorsOptions = filters.doorsOptions.map((option) => {
      if (option.id.toString() === e.target.id) {
        return { ...option, checked };
      }

      return option;
    });

    const newFilters = {
      ...filters,
      doorsOptions: updatedDoorsOptions,
    };

    filterCarResults(newFilters);
  };

  const handleTransmissionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedTransmissionOptions = filters.transmissionOptions.map(
      (option) => {
        if (option.id === e.target.id) {
          return { ...option, checked };
        }

        return option;
      },
    );

    const newFilters = {
      ...filters,
      transmissionOptions: updatedTransmissionOptions,
    };

    filterCarResults(newFilters);
  };

  const handleAirConditioningFilterChange = (_: any, checked: boolean) => {
    const newFilters = {
      ...filters,
      airConditioningOnly: checked,
    };

    filterCarResults(newFilters);
  };

  const handleInPolicyOnlyFilterChange = (_: any, checked: boolean) => {
    const newFilters = {
      ...filters,
      inPolicyOnly: checked,
    };

    filterCarResults(newFilters);
  };

  const handlePriceFilterChange = (range: [number, number]) => {
    const newFilters = {
      ...filters,
      selectedPriceRange: range,
    };

    filterCarResults(newFilters);
  };

  const handleApplyAddressFilter = (rentalOption: RentalOption) => () => {
    const newFilters = {
      ...filters,
      addressFilter: {
        address: rentalOption.address,
        lat: rentalOption.lat,
        lng: rentalOption.lng,
      },
    };

    const newState = {
      ...state,
      isMapVisible: false,
    };

    filterCarResults(newFilters, newState);
  };

  const handleClearAddressFilter = () => {
    const newFilters = {
      ...filters,
      addressFilter: null,
    };

    filterCarResults(newFilters);
  };

  const handleOpenFiltersDrawer = () => {
    const newFilters = {
      ...filters,
      isFilterDrawerVisible: true,
    };

    filterCarResults(newFilters);
  };

  const handleCloseFiltersDrawer = () => {
    const newFilters = {
      ...filters,
      isFilterDrawerVisible: false,
    };

    filterCarResults(newFilters);
  };

  const handleApplyFilters = (newFilters: CarListFilters) => () => {
    filterCarResults({
      ...filters,
      ...newFilters,
      isFilterDrawerVisible: false,
    });
  };

  const filterCarResults = (newFilters: typeof filters, newState = state) => {
    updateFilters({ ...newFilters, filtering: true });

    const filteredCars = carsHelper.filterCarList(state.cars, newFilters);

    updateFilters({ ...newFilters, filtering: false });

    setState({ ...newState, visibleCars: filteredCars });
  };

  useEffect(() => {
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        isFakeLoading: false,
      }));
    }, 3000);
  }, []);

  return (
    <CarsContext.Provider
      value={{
        ...state,
        fetchCarsList,
        filters,
        handleAirConditioningFilterChange,
        handleApplyAddressFilter,
        handleApplyFilters,
        handleClassesFilterChange,
        handleClearAddressFilter,
        handleCloseEditionDrawer,
        handleCloseFiltersDrawer,
        handleCloseOutOfPolicyDialog,
        handleDoorsFilterChange,
        handleExpiration,
        handleInPolicyOnlyFilterChange,
        handleOpenEditionDrawer,
        handleOpenFiltersDrawer,
        handleOpenOutOfPolicyDialog,
        handlePriceFilterChange,
        handleRentalsFilterChange,
        handleShowAgencyOnMap,
        handleToggleCategoryExplanation,
        handleToggleMapVisibility,
        handleTogglePolicyInfoDialogVisibility,
        handleTransmissionFilterChange,
      }}
    >
      {children}
    </CarsContext.Provider>
  );
};

export const useCars = useContextFactory("CarsContext", CarsContext);
