import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  description: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
    textAlign: "left",
    width: "fit-content",
  }),

  header: {
    description: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      textAlign: "left",
      width: "fit-content",
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      fontFamily: theme.fonts.inter,
      gap: "4px",
      width: "100%",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      width: "fit-content",
    }),
  },
  group: {
    checkbox: {
      strong: css({
        fontWeight: 600,
      }),
      text: css({
        fontSize: "0.875rem",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      ".MuiTypography-root": {
        "& > :first-of-type": {
          height: "fit-content",
          lineHeight: "1.25",
        },
      },
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
};
