import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  autocomplete: {
    input: {
      chevron: css({
        color: theme.colors.gray[100],
        transform: "rotate(180deg)",
      }),
      input: css({
        alignItems: "center",
        border: "none",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "16px",
        outline: "none",
        padding: "13px 0",
        width: "100%",
      }),
      root: ({ isEmpty }: { isEmpty: boolean }) =>
        css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          backgroundColor: "transparent",
          display: "flex",
          gap: "16px",
          outline: "none",
          overflow: "hidden",
          padding: isEmpty ? "0 16px" : "0 16px 0 9px",
        }),
    },
    item: {
      flag: css({
        borderRadius: "4px",
        height: "28px",
        width: "40px",
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          alignItems: "center",
          backgroundColor: isHighlighted
            ? theme.colors.background.gray
            : "transparent",
          color: theme.colors.gray[500],
          cursor: isDisabled ? "default" : "pointer",
          display: "flex",
          fontSize: "0.875rem",
          gap: "12px",
          padding: "12px 16px",
        }),
    },
  },
  root: css({
    width: "288px",
  }),
};
