import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  HotelPolicyRule,
  PolicyTarget,
} from "~/apps/corporate/models/policy.model";
import { Collapse } from "~/apps/shared/components/collapse/collapse";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import {
  ALERT_TYPES,
  CABINS,
  CAR_POLICY_CATEGORY_TRANSLATION,
  TARGET_TYPES,
} from "~/apps/shared/constants";
import { CarCategory } from "~/apps/shared/constants/enums";
import { mouseCallbackType, Option } from "~/apps/shared/types";
import { sortByField } from "~/apps/shared/utils/sort-by-field";
import { FormikErrors, FormikProps, withFormik } from "formik";
import uuid from "uuid";

import { Button } from "@toolkit/v2";

import { useApplication } from "../../../../../../../contexts/application.context";
import { usePolicies } from "../../travel-policies.context";
import { searchPolicyTarget } from "../../travel-policies.service";
import {
  FlightPolicy,
  IFlightPolicies,
  IHotelPolicies,
  ITravelPolicyForm,
} from "../../travel-policies.types";
import { AddUsersInput } from "./add-users-input/add-users-input";
import { AllowedCategoryStarRating } from "./allowed-category-star-rating/allowed-category-star-rating";
import { AllowedInternationalFlightDurationClassSelect } from "./allowed-international-flight-duration-class-select/allowed-international-flight-duration-class-select";
import { CarCategorySelect } from "./car-category-select/car-category-select";
import { DefaultInternationalFlightClassSelect } from "./default-international-flight-class-select/default-international-flight-class-select";
import { FlightMaxValueInput } from "./flight-max-value-input/flight-max-value-input";
import { FlightPriceExcessSwitch } from "./flight-price-excess-switch/flight-price-excess-switch";
import { InternationalHotelMaxValuePerLocationArray } from "./international-hotel-max-value-per-location-array/international-hotel-max-value-per-location-array";
import { NationalHotelMaxValuePerLocationArray } from "./national-hotel-max-value-per-location-array/national-hotel-max-value-per-location-array";
import { OptimalPriceSwitch } from "./optimal-price-switch/optimal-price-switch";
import { OtherHotelLocalitiesMaxValue } from "./other-hotel-localities-max-value/other-hotel-localities-max-value";
import { PurchaseAdvanceInDaysInput } from "./purchase-advance-in-days-input/purchase-advance-in-days-input";
import { styles } from "./styles";

const CABIN_RADIOS = [
  {
    label: "Econômica",
    value: CABINS.economy,
  },
  {
    label: "Econômica Premium",
    value: CABINS.premiumEconomy,
  },
  {
    label: "Executiva",
    value: CABINS.business,
  },
  {
    label: "Primeira Classe",
    value: CABINS.first,
  },
] as const;

const CAR_CATEGORY_OPTIONS = [
  {
    label: CAR_POLICY_CATEGORY_TRANSLATION.BASIC,
    value: CarCategory.BASIC,
  },
  {
    label: CAR_POLICY_CATEGORY_TRANSLATION.INTERMEDIATE,
    value: CarCategory.INTERMEDIATE,
  },
  {
    label: CAR_POLICY_CATEGORY_TRANSLATION.ADVANCED,
    value: CarCategory.ADVANCED,
  },
  {
    label: CAR_POLICY_CATEGORY_TRANSLATION.SPECIAL,
    value: CarCategory.SPECIAL,
  },
] as const;

type AdressResult = {
  city: string;
  country: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  placeId: string;
  state: string;
};

type Props = {
  form: ITravelPolicyForm;
  handleClose?: mouseCallbackType;
  handleSave?: mouseCallbackType;
  isSaving?: boolean;
  onRuleRemoval?: any;
};

type Values = {
  description: string;
  internationalFlightAdvance: number;
  internationalFlightClass: string;
  internationalFlightMaxValue?: number;
  internationalFlightOptimalPriceActive: boolean;
  internationalFlightPriceExcess?: string | null;
  internationalFlightPriceExcessActive: boolean;
  internationalFlightsDuration: number;
  internationalFlightsDurationClass: string;
  internationalHotelAdvance: number;
  internationalHotelMaxValue?: number;
  internationalHotelRules: HotelPolicyRule[];
  internationalHotelStars: number;
  nationalCarMaxCategory?: Option<CarCategory>;
  nationalFlightAdvance: number;
  nationalFlightMaxValue?: number;
  nationalFlightOptimalPriceActive: boolean;
  nationalFlightPriceExcess?: string | null;
  nationalFlightPriceExcessActive: boolean;
  nationalHotelAdvance: number;
  nationalHotelMaxValue?: number;
  nationalHotelRules: HotelPolicyRule[];
  nationalHotelStars: number;
  searchVisiblity: boolean;
  targets: PolicyTarget[];
  targetType?: string;
};

const Component: React.FC<FormikProps<Values> & Props> = ({
  errors,
  form,
  handleBlur,
  handleChange,
  isSaving,
  isValid,
  resetForm,
  setFieldValue,
  touched,
  values,
}) => {
  const { showSnackMessage } = useApplication();
  const {
    handleAdvanceDaysChange,
    handleChangeCarMaxCategory,
    handleFormClose,
    handleGeneralInputChange,
    handleHotelRuleAddition,
    handleHotelRuleLocationChange,
    handleHotelRuleMaxValueChange,
    handleHotelRuleRemoval,
    handleInternationalClassChange,
    handleInternationalClassDurationChange,
    handleInternationalDurationChange,
    handleInternationalHotelStarRating,
    handleMaxValueChange,
    handleNationalHotelStarRating,
    handleOptimalPriceToggle,
    handlePriceExcessChange,
    handlePriceExcessToggle,
    handleTargetDeletion,
    handleTargetSelection,
    originalTargets,
    savePolicyForm,
  } = usePolicies();

  const [isInternationalCollapsed, setInternationalCollapsed] = useState(true);
  const [isNationalCollapsed, setNationalCollapsed] = useState(true);
  const [isUsersCollapsed, setIsUsersCollapsed] = useState(true);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      handleGeneralInputChange(e);
    },
    [handleChange, handleGeneralInputChange],
  );

  const handleTargetRemove = useCallback(
    (targetToken: string) => () => {
      const targets = values.targets.filter(
        (target) => target.userToken !== targetToken,
      );

      setFieldValue("targets", targets);
      handleTargetDeletion(targetToken);
    },
    [handleTargetDeletion, setFieldValue, values],
  );

  const handleTargetSelect = useCallback(
    (user: PolicyTarget) => {
      const isTargetAlreadyAdded = !!values.targets.find(
        (target) => target.userToken === user.userToken,
      );

      if (isTargetAlreadyAdded) {
        return;
      }

      const originalTarget = originalTargets.find(
        (target) => target.userToken === user.userToken,
      );

      if (!originalTarget && user.inUse) {
        showSnackMessage(
          "O usuário selecionado já está participando de outra política.",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      user.policyTargetToken = originalTarget
        ? originalTarget.policyTargetToken
        : undefined;

      setFieldValue("targets", values.targets.concat(user));
      handleTargetSelection(user);
    },
    [
      handleTargetSelection,
      originalTargets,
      setFieldValue,
      showSnackMessage,
      values,
    ],
  );

  const innerHandleChangeCarMaxCategory = useCallback(
    (policyType: "national" | "international") => {
      return (value: Option<CarCategory> | null) => {
        if (policyType === "national") {
          setFieldValue("nationalCarMaxCategory", value);
        }

        handleChangeCarMaxCategory(policyType, value?.value || null);
      };
    },
    [handleChangeCarMaxCategory, setFieldValue],
  );

  const onAdvanceDaysChange = useCallback(
    (policyName: IFlightPolicies | IHotelPolicies) => {
      return (e: any) => {
        handleChange(e);
        handleAdvanceDaysChange(e, policyName);
      };
    },
    [handleChange, handleAdvanceDaysChange],
  );

  const onHotelRuleAdditionClick = useCallback(
    (arrayHelpers: any, policyName: IHotelPolicies) => {
      return (e: any) => {
        const policyRuleToken = uuid();
        arrayHelpers.push({
          policyRuleToken,
          locationName: "",
          locationCity: "",
          locationState: "",
          locationCountry: "",
        });
        handleHotelRuleAddition(policyName, policyRuleToken);
      };
    },
    [handleHotelRuleAddition],
  );

  const onHotelRuleRemovalClick = useCallback(
    (
      arrayHelpers: any,
      policyName: IHotelPolicies,
      ruleId: string,
      index: number,
    ) => {
      return (e: any) => {
        arrayHelpers.remove(index);
        handleHotelRuleRemoval(policyName, ruleId);
      };
    },
    [handleHotelRuleRemoval],
  );

  const onHotelRuleLocationChange = useCallback(
    (
      arrayHelpers: any,
      policyName: IHotelPolicies,
      inputId: string,
      index: number,
      inputName: string,
    ) => {
      return (result: AdressResult | null) => {
        let newValue;
        if (!result) {
          newValue = {
            policyRuleToken: inputId,
            locationName: "",
            locationCity: "",
            locationState: "",
            locationCountry: "",
          };
        } else {
          newValue = {
            policyRuleToken: inputId,
            locationName: result.formattedAddress,
            locationCity: result.city,
            locationState: result.state,
            locationCountry: result.country,
          };
        }
        // arrayHelpers.replace(index, newValue);
        setFieldValue(inputName, newValue.locationName);
        handleHotelRuleLocationChange(newValue, policyName, inputId);
      };
    },
    [handleHotelRuleLocationChange, setFieldValue],
  );

  const onHotelRuleMaxValueChange = useCallback(
    (policyName: IHotelPolicies, ruleId: string, inputName: string) => {
      return (value: number | null) => {
        if (value === null) {
          return;
        }

        setFieldValue(inputName, value);
        handleHotelRuleMaxValueChange(value, policyName, ruleId);
      };
    },
    [handleHotelRuleMaxValueChange, setFieldValue],
  );

  const onInternationalClassChange = useCallback(
    (e: any) => {
      handleChange(e);
      handleInternationalClassChange(e);
    },
    [handleChange, handleInternationalClassChange],
  );

  const onInternationalClassDurationClassChange = useCallback(
    (e: any) => {
      handleChange(e);
      handleInternationalClassDurationChange(e);
    },
    [handleChange, handleInternationalClassDurationChange],
  );

  const onInternationalDurationChange = useCallback(
    (e: any) => {
      handleChange(e);
      handleInternationalDurationChange(e);
    },
    [handleChange, handleInternationalDurationChange],
  );

  const onMaxValueChange = useCallback(
    (policyName: IFlightPolicies | IHotelPolicies, inputId: string) => {
      return (value: number | null) => {
        if (value === null) {
          return;
        }

        setFieldValue(inputId, value);
        handleMaxValueChange(value, policyName);
      };
    },
    [handleMaxValueChange, setFieldValue],
  );

  const onOptimalPriceToggle = useCallback(
    (flightPolicie: IFlightPolicies, currentState: boolean) => {
      return (e: any) => {
        handleOptimalPriceToggle(flightPolicie);
        setFieldValue(e.target.name, !currentState);
      };
    },
    [handleOptimalPriceToggle, setFieldValue],
  );

  const onPriceExcessChange = useCallback(
    (policyName: IFlightPolicies) => {
      return (e: any) => {
        const value = e.target.value;
        const pattern = /^\d+$/; // digit only
        if (value === "" || pattern.test(value)) {
          handleChange(e);
          handlePriceExcessChange(e, policyName);
        }
      };
    },
    [handleChange, handlePriceExcessChange],
  );

  const onPriceExcessToggle = useCallback(
    (
      policyName: IFlightPolicies,
      currentState: boolean,
      excessFieldName: string,
    ) => {
      return (e: any) => {
        // handleChange(e);
        setFieldValue(e.target.name, !currentState);
        setFieldValue(excessFieldName, currentState === false ? "0" : "");
        handlePriceExcessToggle(policyName);
      };
    },
    [handlePriceExcessToggle, setFieldValue],
  );

  const resetInternationalStars = useCallback(() => {
    handleInternationalHotelStarRating(0);
  }, []);

  const resetNationalStars = useCallback(() => {
    handleNationalHotelStarRating(0);
  }, []);

  const onSaveClick = useCallback(async () => {
    await savePolicyForm();
  }, [savePolicyForm]);

  const toggleInternationalCollapse = useCallback(
    () => setInternationalCollapsed((isCollapsed) => !isCollapsed),
    [],
  );

  const toggleNationalCollapse = useCallback(
    () => setNationalCollapsed((isCollapsed) => !isCollapsed),
    [],
  );

  const toggleUsersCollapse = useCallback(
    () => setIsUsersCollapsed((isCollapsed) => !isCollapsed),
    [],
  );

  useEffect(() => {
    setFieldValue("nationalHotelStars", form.nationalHotelPolicy.stars);
  }, [form.nationalHotelPolicy]);

  useEffect(() => {
    setFieldValue(
      "internationalHotelStars",
      form.internationalHotelPolicy.stars,
    );
  }, [form.internationalHotelPolicy]);

  return (
    <div css={styles.root}>
      <DrawerHeader onClose={handleFormClose}>
        {form.policyToken ? "Editar Política" : "Nova Política"}
      </DrawerHeader>
      <div css={styles.body.root}>
        <div css={styles.body.name.root}>
          <span css={styles.body.name.label}>Nome da Política</span>
          <Input
            css={styles.body.name.input}
            id="description"
            name="description"
            onBlur={handleBlur}
            onChange={handleNameChange}
            placeholder="Ex.: Básica, Premium ..."
            value={values.description}
          />
          <InputErrorMessage>
            {errors.description && touched.description
              ? errors.description
              : undefined}
          </InputErrorMessage>
        </div>
        <FormSection
          description="Cadaste ou edite as regras"
          handleCollapse={toggleNationalCollapse}
          isCollapsed={isNationalCollapsed}
          title="Regras para viagens nacionais "
        >
          <div css={styles.body.type.national.root}>
            <span css={styles.body.type.national.title}>Aéreo</span>
            <div css={styles.body.type.national.service}>
              <PurchaseAdvanceInDaysInput
                id="nationalFlightAdvance"
                name="nationalFlightAdvance"
                onChange={onAdvanceDaysChange("nationalFlightPolicy")}
                value={values.nationalFlightAdvance}
              />
              <OptimalPriceSwitch
                active={values.nationalFlightOptimalPriceActive}
                name="nationalFlightOptimalPriceActive"
                onChange={onOptimalPriceToggle(
                  FlightPolicy.NATIONAL_FLIGHT_POLICY,
                  values.nationalFlightOptimalPriceActive,
                )}
              />
              <FlightPriceExcessSwitch
                name="nationalFlightPriceExcess"
                onPriceExcessChange={onPriceExcessChange(
                  "nationalFlightPolicy",
                )}
                onPriceExcessToggle={onPriceExcessToggle(
                  "nationalFlightPolicy",
                  values.nationalFlightPriceExcessActive,
                  "nationalFlightPriceExcess",
                )}
                priceExcess={values.nationalFlightPriceExcess}
                priceExcessActive={values.nationalFlightPriceExcessActive}
              />
              <FlightMaxValueInput
                error={
                  errors.nationalFlightMaxValue &&
                  touched.nationalFlightMaxValue
                    ? errors.nationalFlightMaxValue
                    : undefined
                }
                id="nationalFlightMaxValue"
                name="nationalFlightMaxValue"
                onBlur={handleBlur}
                onChange={onMaxValueChange(
                  "nationalFlightPolicy",
                  "nationalFlightMaxValue",
                )}
                placeholder="Ex.: R$ 1000,00"
                value={values.nationalFlightMaxValue}
              />
              <span>
                <strong>Política de valor máximo por rota</strong> -{" "}
                <i>Em breve</i>
              </span>
            </div>
            <span css={styles.body.type.national.title}>Hospedagem</span>
            <div css={styles.body.type.national.service}>
              <PurchaseAdvanceInDaysInput
                id="nationalHotelAdvance"
                name="nationalHotelAdvance"
                onChange={onAdvanceDaysChange("nationalHotelPolicy")}
                value={values.nationalHotelAdvance}
              />
              <NationalHotelMaxValuePerLocationArray
                errors={errors}
                handleBlur={handleBlur}
                onHotelRuleAdditionClick={onHotelRuleAdditionClick}
                onHotelRuleLocationChange={onHotelRuleLocationChange}
                onHotelRuleMaxValueChange={onHotelRuleMaxValueChange}
                onHotelRuleRemovalClick={onHotelRuleRemovalClick}
                touched={touched}
                values={values}
              />
              <OtherHotelLocalitiesMaxValue
                error={
                  errors.nationalHotelMaxValue && touched.nationalHotelMaxValue
                    ? errors.nationalHotelMaxValue
                    : undefined
                }
                id="nationalHotelMaxValue"
                name="nationalHotelMaxValue"
                onBlur={handleBlur}
                onChange={onMaxValueChange(
                  "nationalHotelPolicy",
                  "nationalHotelMaxValue",
                )}
                placeholder="Ex.: R$ 1000,00"
                value={values.nationalHotelMaxValue}
              />
              <AllowedCategoryStarRating
                name="nationalHotelStars"
                onClear={resetNationalStars}
                onStarClick={handleNationalHotelStarRating}
                value={values.nationalHotelStars}
              />
            </div>
            <span css={styles.body.type.national.title}>
              Aluguel de Veículos
            </span>
            <div css={styles.body.type.national.service}>
              <CarCategorySelect
                onChange={(value) => {
                  innerHandleChangeCarMaxCategory("national")(
                    value as Option<CarCategory>,
                  );
                }}
                options={CAR_CATEGORY_OPTIONS}
                placeholder="Selecione a categoria"
                value={values.nationalCarMaxCategory}
              />
            </div>
          </div>
        </FormSection>
        <FormSection
          description="Cadaste ou edite as regras"
          handleCollapse={toggleInternationalCollapse}
          isCollapsed={isInternationalCollapsed}
          title="Regras para viagens internacionais"
        >
          <div css={styles.body.type.national.root}>
            <span css={styles.body.type.national.title}>Aéreo</span>
            <div css={styles.body.type.national.service}>
              <PurchaseAdvanceInDaysInput
                id="internationalFlightAdvance"
                name="internationalFlightAdvance"
                onChange={onAdvanceDaysChange("internationalFlightPolicy")}
                value={values.internationalFlightAdvance}
              />
              <DefaultInternationalFlightClassSelect
                id="internationalFlightClass"
                name="internationalFlightClass"
                onChange={({ value }) => {
                  onInternationalClassChange({
                    target: {
                      name: "internationalFlightClass",
                      value: value,
                    },
                  });
                }}
                options={CABIN_RADIOS}
                value={CABIN_RADIOS.find(
                  (option) => option.value === values.internationalFlightClass,
                )}
              />
              <AllowedInternationalFlightDurationClassSelect
                internationalFlightsDuration={
                  values.internationalFlightsDuration
                }
                internationalFlightsDurationClass={
                  values.internationalFlightsDurationClass
                }
                internationalFlightsDurationClassOptions={CABIN_RADIOS}
                onInternationalDurationChange={onInternationalDurationChange}
                onInternationalFlightsDurationClassChange={({ value }) => {
                  onInternationalClassDurationClassChange({
                    target: {
                      name: "internationalFlightsDurationClass",
                      value: value,
                    },
                  });
                }}
              />
              <OptimalPriceSwitch
                active={values.internationalFlightOptimalPriceActive}
                name="internationalFlightOptimalPriceActive"
                onChange={onOptimalPriceToggle(
                  FlightPolicy.INTERNATIONAL_FLIGHT_POLICY,
                  values.internationalFlightOptimalPriceActive,
                )}
              />
              <FlightPriceExcessSwitch
                name="internationalFlightPriceExcess"
                onPriceExcessChange={onPriceExcessChange(
                  "internationalFlightPolicy",
                )}
                onPriceExcessToggle={onPriceExcessToggle(
                  "internationalFlightPolicy",
                  values.internationalFlightPriceExcessActive,
                  "internationalFlightPriceExcess",
                )}
                priceExcess={values.internationalFlightPriceExcess}
                priceExcessActive={values.internationalFlightPriceExcessActive}
              />
              <FlightMaxValueInput
                error={
                  errors.internationalFlightMaxValue &&
                  touched.internationalFlightMaxValue
                    ? errors.internationalFlightMaxValue
                    : undefined
                }
                id="internationalFlightMaxValue"
                name="internationalFlightMaxValue"
                onBlur={handleBlur}
                onChange={onMaxValueChange(
                  "internationalFlightPolicy",
                  "internationalFlightMaxValue",
                )}
                placeholder="Ex.: R$ 1000,00"
                value={values.internationalFlightMaxValue}
              />
              <span>
                <strong>Política de valor máximo por rota</strong> -{" "}
                <i>Em breve</i>
              </span>
            </div>
            <span css={styles.body.type.national.title}>Hospedagem</span>
            <div css={styles.body.type.national.service}>
              <PurchaseAdvanceInDaysInput
                id="internationalHotelAdvance"
                name="internationalHotelAdvance"
                onChange={onAdvanceDaysChange("internationalHotelPolicy")}
                value={values.internationalHotelAdvance}
              />
              <InternationalHotelMaxValuePerLocationArray
                errors={errors}
                handleBlur={handleBlur}
                onHotelRuleAdditionClick={onHotelRuleAdditionClick}
                onHotelRuleLocationChange={onHotelRuleLocationChange}
                onHotelRuleMaxValueChange={onHotelRuleMaxValueChange}
                onHotelRuleRemovalClick={onHotelRuleRemovalClick}
                touched={touched}
                values={values}
              />
              <OtherHotelLocalitiesMaxValue
                error={
                  errors.internationalHotelMaxValue &&
                  touched.internationalHotelMaxValue
                    ? errors.internationalHotelMaxValue
                    : undefined
                }
                id="internationalHotelMaxValue"
                name="internationalHotelMaxValue"
                onBlur={handleBlur}
                onChange={onMaxValueChange(
                  "internationalHotelPolicy",
                  "internationalHotelMaxValue",
                )}
                placeholder="Ex.: R$ 1000,00"
                value={values.internationalHotelMaxValue}
              />
              <AllowedCategoryStarRating
                name="internationalHotelStars"
                onClear={resetInternationalStars}
                onStarClick={handleInternationalHotelStarRating}
                value={values.internationalHotelStars}
              />
            </div>
          </div>
        </FormSection>
        {!!values.targetType && values.targetType !== TARGET_TYPES.COMPANY ? (
          <FormSection
            description="Cadaste ou edite os usuários"
            handleCollapse={toggleUsersCollapse}
            isCollapsed={isUsersCollapsed}
            title="Usuários participantes"
          >
            <div css={styles.body.type.national.root}>
              <AddUsersInput
                onDelete={handleTargetRemove}
                onSelect={handleTargetSelect}
                fetchItems={searchPolicyTarget}
                targets={values.targets}
              />
            </div>
          </FormSection>
        ) : null}
      </div>
      <footer css={styles.footer.root}>
        <Button
          disabled={!isValid || isSaving}
          onClick={onSaveClick}
          type="submit"
        >
          {form.policyToken ? "Salvar Alterações" : "Salvar"}
        </Button>
        <Button
          fill="outlined"
          onClick={() => {
            handleFormClose();
            resetForm();
          }}
        >
          Cancelar
        </Button>
      </footer>
    </div>
  );
};

const FormSection: React.FC<
  PropsWithChildren<{
    description: string;
    handleCollapse: () => void;
    isCollapsed: boolean;
    title: string;
  }>
> = ({ children, description, handleCollapse, isCollapsed, title }) => {
  return (
    <div css={styles.body.section.root}>
      <div
        css={styles.body.section.header.root({ isCollapsed })}
        onClick={handleCollapse}
      >
        <div css={styles.body.section.header.left.root}>
          <span css={styles.body.section.header.left.title}>{title}</span>
          <p css={styles.body.section.header.left.description}>{description}</p>
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();

            handleCollapse();
          }}
          shape="icon"
        >
          <Icon
            css={styles.body.section.header.right.icon({ isCollapsed })}
            use="chevron-up"
          />
        </Button>
      </div>
      <Collapse expanded={!isCollapsed}>
        <div css={styles.body.section.body.root}>{children}</div>
      </Collapse>
    </div>
  );
};

export const PolicyForm = withFormik<Props, Values>({
  handleSubmit: () => {
    return;
  },
  mapPropsToValues: ({ ...props }) => {
    return {
      description: props.form.description,
      internationalFlightAdvance: props.form.internationalFlightPolicy.advance,
      internationalFlightClass: props.form.internationalFlightPolicy.class,
      internationalFlightsDuration:
        props.form.internationalFlightPolicy.duration,
      internationalFlightsDurationClass:
        props.form.internationalFlightPolicy.durationClass,
      internationalFlightMaxValue: props.form.internationalFlightPolicy.maxValue
        ? props.form.internationalFlightPolicy.maxValue
        : undefined,
      internationalFlightOptimalPriceActive:
        props.form.internationalFlightPolicy.optimalPriceEnabled,
      internationalFlightPriceExcess: props.form.internationalFlightPolicy
        .priceExcess
        ? Math.round(
            (props.form.internationalFlightPolicy.priceExcess - 1) * 100,
          ).toString()
        : null,
      internationalFlightPriceExcessActive:
        props.form.internationalFlightPolicy.priceExcessActive,
      internationalHotelAdvance: props.form.internationalHotelPolicy.advance,
      internationalHotelMaxValue: props.form.internationalHotelPolicy.maxValue
        ? props.form.internationalHotelPolicy.maxValue
        : undefined,
      internationalHotelStars: props.form.internationalHotelPolicy.stars,
      internationalHotelRules: props.form.internationalHotelPolicy.rules.sort(
        sortByField("locationName"),
      ),
      nationalCarMaxCategory: CAR_CATEGORY_OPTIONS.find(
        (i) => i.value === props.form.nationalCarPolicy.maxCategory,
      ),
      nationalFlightAdvance: props.form.nationalFlightPolicy.advance,
      // no backend vamos salvar o fator multiplicador do preço mais barato => priceExcess >=1
      nationalFlightMaxValue: props.form.nationalFlightPolicy.maxValue
        ? props.form.nationalFlightPolicy.maxValue
        : undefined,
      nationalFlightOptimalPriceActive:
        props.form.nationalFlightPolicy.optimalPriceEnabled,
      nationalFlightPriceExcess: props.form.nationalFlightPolicy.priceExcess
        ? Math.round(
            (props.form.nationalFlightPolicy.priceExcess - 1) * 100,
          ).toString()
        : null,
      nationalFlightPriceExcessActive:
        props.form.nationalFlightPolicy.priceExcessActive,
      nationalHotelAdvance: props.form.nationalHotelPolicy.advance,
      nationalHotelMaxValue: props.form.nationalHotelPolicy.maxValue
        ? props.form.nationalHotelPolicy.maxValue
        : undefined,
      nationalHotelRules: props.form.nationalHotelPolicy.rules.sort(
        sortByField("locationName"),
      ),
      nationalHotelStars: props.form.nationalHotelPolicy.stars,
      searchVisiblity: props.form.searchVisiblity,
      targets: props.form.targets,
      targetType: props.form.targetType,
    };
  },
  validate: (values: Values) => {
    const errors: FormikErrors<Values> = {};

    if (!values.description) {
      errors.description = "Preencha a descrição da política";
    }

    if (values.nationalHotelRules.length) {
      errors.nationalHotelRules = [];
      values.nationalHotelRules.forEach(
        (item: HotelPolicyRule, index: number) => {
          errors.nationalHotelRules!.push({});
          if (!item.locationName) {
            errors.nationalHotelRules![index]!.locationName =
              "Destino obrigatório";
          }
          if (!item.maxValue) {
            errors.nationalHotelRules![index]!.maxValue = "Valor obrigatório";
          }
        },
      );
    }

    if (
      errors.nationalHotelRules &&
      errors.nationalHotelRules.every((item) => Object.keys(item!).length === 0)
    ) {
      delete errors.nationalHotelRules;
    }

    if (values.internationalHotelRules.length) {
      errors.internationalHotelRules = [];
      values.internationalHotelRules.forEach(
        (item: HotelPolicyRule, index: number) => {
          errors.internationalHotelRules!.push({});
          if (!item.locationName) {
            errors.internationalHotelRules![index]!.locationName =
              "Destino obrigatório";
          }
          if (!item.maxValue) {
            errors.internationalHotelRules![index]!.maxValue =
              "Valor obrigatório";
          }
        },
      );
    }

    if (
      errors.internationalHotelRules &&
      errors.internationalHotelRules.every(
        (item) => Object.keys(item!).length === 0,
      )
    ) {
      delete errors.internationalHotelRules;
    }

    return errors;
  },
})(Component);
