import React from "react";

import { Tooltip } from "@material-ui/core";
import {
  Link as LinkIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
} from "@material-ui/icons";
import { css } from "@styled-system/css";
import { css as emotionCss } from "emotion";

import styled from "~/components/shared/styled";

import {
  colors,
  defaultTheme,
} from "../../../../../../../../../assets/styles/theme";
import { CARD_BRANDS } from "../../../../../../../../../constants";
import { CardModel } from "../../../../../../../../../models/card.model";
import { Column } from "../../../../../../../../../components/shared/layout/Column";
import { Row } from "../../../../../../../../../components/shared/layout/Row";
import { MoreActionsPopOver } from "../../../../../../../../../components/configuration/shared/MoreActionsPopOver/MoreActionsPopOver";
import { ServiceIconsList } from "../ServiceIconsList";

const styles = {
  root: emotionCss({
    padding: "1rem 2rem 0.9rem 2rem",
    marginBottom: "1.5rem",
    marginRight: "0rem",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 10,
    width: 290,
    position: "relative",
    ["@media (min-width: 500px)"]: {
      marginRight: "1.5rem",
    },
  }),
  lastItem: emotionCss({
    borderBottom: "none !important",
  }),
  logoDiv: emotionCss({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-0.5rem",
    height: 41,
  }),
  optionsAndInfos: emotionCss({
    display: "flex",
    gap: "0.25rem",
    position: "absolute",
    right: "8px",
  }),
  infos: emotionCss({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }),
  logo: (cardBrand: string) =>
    emotionCss({
      width: cardBrand === CARD_BRANDS.AMEX ? 40 : 50,
      height: "auto",
    }),
  infoDiv: emotionCss({
    width: "auto",
  }),
  infoContainer: emotionCss({
    fontSize: 14,
    color: colors.slateGray,
  }),
  description: emotionCss({
    width: "14rem",
    lineHeight: "1rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  publicIcon: emotionCss({
    fontSize: "1.25rem",
    marginRight: "0.25rem",
    color: defaultTheme.primaryColor,
  }),
  serviceType: emotionCss({
    marginLeft: "auto",
    padding: "0.25rem",
    borderRadius: "0.25rem",
    color: "white",
    fontSize: "0.75rem",
  }),
  billingProfile: emotionCss({
    borderTop: `2px dashed ${defaultTheme.cardBorderColor}`,
    marginTop: "0.6rem",
    color: colors.slateGray,
    paddingTop: "0.5rem",
    fontSize: "0.812rem",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  cardName: emotionCss({
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: colors.slateGray,
  }),
  cardNumber: emotionCss({
    fontSize: 15,
    fontWeight: "bold",
    color: colors.slateGray,
    justifyContent: "space-between",
    width: "100%",
  }),
  mockDigits: emotionCss({
    position: "relative",
    top: -9.5,
    fontSize: 30,
  }),
  deleteIcon: emotionCss({
    color: colors.slateGray,
    cursor: "pointer",
  }),
  expirationInfo: emotionCss({
    color: colors.slateGray,
    justifyContent: "center",
    minWidth: 47,
  }),
  cardNumberDiv: emotionCss({
    alignItems: "center",
  }),
};

const oldStyles = {
  tooltip: css({
    [".MuiTooltip-arrow"]: {
      color: `${defaultTheme.primaryColor} !important`,
    },
    [".MuiTooltip-tooltip"]: {
      marginRight: "8px",
      fontSize: "0.75rem",
      backgroundColor: defaultTheme.primaryColor,
      textAlign: "center",
      padding: "0.3rem",
      lineHeight: "1.25rem",
      cursor: "auto",
    },
    [".MuiTooltip-tooltipPlacementTop"]: {
      marginBottom: "0.5rem",
    },
  }),
};

interface Props {
  card: CardModel;
  issuerConfig: {
    brand: string;
    startsWith: RegExp;
    validationPattern: RegExp;
    lengths: number[];
    cvvDigitsPattern: RegExp;
    cvvDigits: number;
    logo: any;
  };
  handleDelete: (card: CardModel) => void;
  handleEdit: (card: CardModel) => void;
}

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    arrow
    placement="top"
    title={
      <span style={{ fontWeight: "bold", padding: "0.5rem" }}>
        {props.text}
      </span>
    }
  >
    {props.children}
  </Tooltip>
))(oldStyles.tooltip);

const CardItemPresentational = ({
  card,
  issuerConfig,
  handleDelete,
  handleEdit,
}: Props) => {
  return (
    <Column
      className={styles.root}
      style={{
        borderLeft: `4px solid ${defaultTheme.primaryColor}`,
      }}
    >
      <Row className={styles.logoDiv}>
        <img
          src={issuerConfig.logo}
          title={issuerConfig.brand}
          alt={issuerConfig.brand}
          className={styles.logo(card.brand)}
        />
        <div className={styles.optionsAndInfos}>
          <div className={styles.infos}>
            {card.personal ? (
              <StyledTooltip
                text={
                  card.allowApprovers
                    ? "Cartão pessoal disponível para aprovadores e solicitantes"
                    : "Cartão pessoal"
                }
              >
                {card.allowApprovers ? (
                  <PersonAddIcon className={styles.publicIcon} />
                ) : (
                  <PersonIcon className={styles.publicIcon} />
                )}
              </StyledTooltip>
            ) : (
              <>
                {card.billingProfileToken &&
                card.billingProfileToken !== "null" ? (
                  <StyledTooltip text="Vinculado a um perfil de cobrança">
                    <LinkIcon className={styles.publicIcon} />
                  </StyledTooltip>
                ) : null}
                <StyledTooltip text="Cartão da empresa">
                  <PeopleIcon className={styles.publicIcon} />
                </StyledTooltip>
              </>
            )}
          </div>
          <MoreActionsPopOver
            item={card}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </div>
      </Row>
      <Row style={{ padding: ".5rem 0" }}>
        <div className={styles.infoContainer}>
          <p className={styles.description}>{card.description}</p>
        </div>
      </Row>
      <Row className={styles.cardNumberDiv}>
        <Row className={styles.cardNumber}>
          <span className={styles.mockDigits}>••••</span>
          <span className={styles.mockDigits}>••••</span>
          <span className={styles.mockDigits}>••••</span>
          <span>{card.lastDigits}</span>
        </Row>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Column style={{ paddingRight: "1rem", justifyContent: "center" }}>
          <Row className={styles.infoDiv} style={{ alignItems: "center" }}>
            <div className={styles.cardName}>{card.holderName}</div>
          </Row>
        </Column>
        <Column style={{ alignSelf: "flex-end" }}>
          <Column className={styles.expirationInfo}>
            <p style={{ fontSize: 10 }}>Válido até</p>
            <p style={{ fontSize: 16 }}>{card.expirationDate}</p>
          </Column>
        </Column>
      </Row>

      <ServiceIconsList serviceTypes={card.serviceTypes} />
    </Column>
  );
};

export { CardItemPresentational };
