import * as React from "react";

import { Collapse, List, ListItem } from "@material-ui/core";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownRounded";
import ArrowUp from "@material-ui/icons/KeyboardArrowUpRounded";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { Box, Flex } from "@toolkit";

import { EXPENSES_FAMILIES, EXPENSES_FAMILIES_TRANSLATION } from "@constants";

import { useExpensesCategories } from "../ExpensesCategories.context";
import { ExpenseSubCategoryList } from "./ExpenseSubCategoryList";

const styles = {
  tableHeader: css({
    fontSize: 15,
    fontWeight: "bold",
    color: theme.colors.gray[500],
    padding: "0rem .5rem .5rem .5rem",
  }),
  descriptionColumn: css({
    textAlign: "left",
    fontSize: "1.125rem",
  }),
  optionsColumn: css({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  }),
  separator: css({
    flex: "auto",
  }),
  listItem: css({
    p: "0 !important",
    mb: "0.5rem",
    borderRadius: "0.5rem",
    border: "1px solid",
    borderColor: theme.colors.gray[100],
    ["&:first-of-type"]: {
      mt: ".5rem",
    },
    ["& .MuiList-root"]: {
      p: 0,
    },
  }),
  subListItem: (isOpen: boolean) =>
    css({
      justifyContent: "space-between !important",
      fontSize: 15,
      fontWeight: 700,
      cursor: "pointer",
      color: theme.colors.gray[500],
      backgroundColor: theme.colors.background.gray,
      p: "1rem",
      borderRadius: isOpen ? "0.5rem 0.5rem 0 0" : "0.5rem",
    }),
  arrowIcon: css({
    backgroundColor: theme.colors.gray[500],
    color: "white",
    borderRadius: "0.25rem",
    fontSize: 15,
    marginLeft: "0.2rem",
  }),
};

export const ExpenseCategoryList: React.FC<{}> = () => {
  const { visibleCategories } = useExpensesCategories();

  const [itemOpen, setItemOpen] = React.useState<string | null>(null);

  const handleClick = (family: string) => () => {
    setItemOpen((prevFamily) => (prevFamily === family ? null : family));
  };

  return (
    <Box>
      <div style={{ width: "100%" }}>
        <Flex css={styles.tableHeader}>
          <div css={styles.descriptionColumn}>Grupo de categorias</div>
          <div css={styles.separator} />
          <div css={styles.optionsColumn} />
        </Flex>
        <Flex flexDirection="column" style={{ gap: "1rem" }}>
          {Object.values(EXPENSES_FAMILIES).map((family, idx) => (
            <List key={idx} css={styles.listItem}>
              <ListItem
                css={styles.subListItem(itemOpen === family)}
                onClick={handleClick(family)}
              >
                <p>{EXPENSES_FAMILIES_TRANSLATION[family]}</p>
                {itemOpen === family ? (
                  <ArrowUp css={styles.arrowIcon} />
                ) : (
                  <ArrowDown css={styles.arrowIcon} />
                )}
              </ListItem>
              <Collapse
                in={itemOpen === family}
                timeout="auto"
                unmountOnExit={true}
              >
                <ExpenseSubCategoryList
                  categoryFamily={family}
                  subcategories={visibleCategories}
                />
              </Collapse>
            </List>
          ))}
        </Flex>
      </div>
    </Box>
  );
};
