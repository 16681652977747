import * as analyticsApi from "~/apps/corporate/apis/analytics.api";
import { Error } from "~/apps/shared/types";

import { ERROR } from "@constants";

import { RealTimeTravelRequestDto } from "@dtos/analytics.dto";

export const getRealTimeTravelsData = async (dto: RealTimeTravelRequestDto) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await analyticsApi.getRealTimeTravelsData(dto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_REAL_TIME_TRAVELS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_REAL_TIME_TRAVELS_ERROR;
    }
  }

  return result;
};
