import React from "react";

import _ from "lodash";

import { Icon } from "../icon/icon";
import { RangeDatePicker } from "./range-date-picker/range-date-picker";
import { SingleDatePicker } from "./single-date-picker/single-date-picker";

const defaultCustomInputIcon = <Icon size={16} use="calendar" />;

type Props = {} & (
  | ({
      range: false;
    } & React.ComponentPropsWithoutRef<typeof SingleDatePicker>)
  | ({
      range: true;
    } & React.ComponentPropsWithoutRef<typeof RangeDatePicker>)
);

export const DatePicker: React.FC<Props> = ({
  customInputIcon = defaultCustomInputIcon,
  ...props
}) => {
  if (props.range) {
    return (
      <RangeDatePicker
        customInputIcon={customInputIcon}
        {..._.omit(props, "range")}
      />
    );
  }

  return (
    <SingleDatePicker
      customInputIcon={customInputIcon}
      {..._.omit(props, "range")}
    />
  );
};
