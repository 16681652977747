import React, { useCallback, useRef, useState } from "react";
import { useMutation } from "react-query";

import { Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDownRounded as ArrowDownIcon } from "@material-ui/icons";
import {
  Apartment as CompanyIcon,
  LockOutlined as LockIcon
} from "@material-ui/icons";
import { navigate } from "@reach/router";
import moment from "moment";
import {
  GetUserCompanyEventDto,
  ParticipantioStatus
} from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";

import placeholder from "@assets/images/no-image-available.svg";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import {
  ParticipationStatus,
  mapParticipantInviteStatus
} from "~/models/event.model";

import CircularSpinner from "~/components/shared/CircularSpinner";
import { Spacing } from "~/components/shared/layout";
import { CopyIdIcon } from "~/components/travels/copy-id-icon";

import { useEvents } from "../../events.context";
import { useEvent } from "../event.context";
import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventHeader: React.VFC<Props> = ({ event }) => {
  const user = getUserFromLocalStorage();

  const { invalidateEvent } = useEvent();
  const { changeEventParticipationStatus } = useEvents();

  const {
    mutateAsync: changeEventParticipationStatusMutation,
    isLoading: isLoadingChangeEventParticipationStatus
  } = useMutation({
    mutationFn: async ({ status }: { status: ParticipationStatus }) => {
      const result = await changeEventParticipationStatus({
        status,
        token: event.token
      });

      if (result.success) {
        await invalidateEvent();
      }
    }
  });

  const [
    isParticipationStatusMenuOpen,
    setIsParticipationStatusMenuOpen
  ] = useState(false);

  const participationStatusMenuContainerRef = useRef<HTMLDivElement>(null);

  const handleAcceptParticipation = useCallback(async () => {
    setIsParticipationStatusMenuOpen(false);

    await changeEventParticipationStatusMutation({
      status: ParticipationStatus.ACCEPTED
    });
  }, [changeEventParticipationStatusMutation]);

  const handleDeclineParticipation = useCallback(async () => {
    setIsParticipationStatusMenuOpen(false);

    await changeEventParticipationStatusMutation({
      status: ParticipationStatus.DECLINED
    });
  }, [changeEventParticipationStatusMutation]);

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <img
          alt="Imagem indisponível."
          css={styles.left.image}
          src={placeholder}
        />
        <div css={styles.left.text}>
          <div css={styles.left.dates}>
            <time>{moment(event.start_date).format("DD MMM")}</time>
            <time>{moment(event.end_date).format("DD MMM")}</time>
          </div>
          <Text color={theme.colors.gray[5]} fontSize="18px" fontWeight="bold">
            {event.name}
          </Text>
          <Text>
            {event.city}, {event.state}, {event.country}.
          </Text>
          <div css={styles.left.privacy.root}>
            {event.privacy === "PUBLIC" ? (
              <CompanyIcon className={styles.left.privacy.icon} />
            ) : (
              <LockIcon className={styles.left.privacy.icon} />
            )}
            <Text>
              {event.privacy === "PUBLIC"
                ? user
                  ? `Todos na ${user.clientName} podem ver e ir a esse evento`
                  : "Todos na empresa poderão ver e ir ao evento"
                : "Apenas convidados podem ver e ir a esse evento"}
              .
            </Text>
          </div>
          <div css={styles.left.token}>
            <CopyIdIcon id={event.token} />
            <Text>
              ID: <strong>{event.token}</strong>
            </Text>
          </div>
        </div>
      </div>
      {event.viewer_info.is_organizer ? (
        <Flex flexShrink={0}>
          <Button
            css={styles.button}
            onClick={() => {
              navigate(`/events/${event.token}/edit`);
            }}
          >
            Editar
          </Button>
        </Flex>
      ) : !event.viewer_info.participation_status ||
        event.viewer_info.participation_status ===
          ParticipantioStatus.REALLY_PENDING ||
        event.viewer_info.participation_status ===
          ParticipantioStatus.VIRTUAL_PENDING ? (
        <Flex flexShrink={0}>
          <Button
            color="default"
            css={styles.button}
            disabled={isLoadingChangeEventParticipationStatus}
            onClick={handleDeclineParticipation}
          >
            Recusar
          </Button>
          <Spacing direction="horizontal" space="8px" />
          <Button
            css={styles.button}
            disabled={isLoadingChangeEventParticipationStatus}
            onClick={handleAcceptParticipation}
          >
            Confirmar
          </Button>
        </Flex>
      ) : (
        <div
          aria-controls="invite-status-options"
          aria-haspopup="true"
          aria-owns={
            isParticipationStatusMenuOpen ? "invite-status-options" : undefined
          }
          ref={participationStatusMenuContainerRef}
        >
          <Button
            color="default"
            css={styles.button}
            disabled={isLoadingChangeEventParticipationStatus}
            onClick={() => setIsParticipationStatusMenuOpen(true)}
          >
            {mapParticipantInviteStatus(
              event.viewer_info!.participation_status
            )}
            {isLoadingChangeEventParticipationStatus ? (
              <CircularSpinner size={16} />
            ) : (
              <ArrowDownIcon fontSize="small" />
            )}
          </Button>
          <Menu
            anchorEl={participationStatusMenuContainerRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            className={styles.right.menu}
            id="invite-status-options"
            keepMounted
            onClose={() => setIsParticipationStatusMenuOpen(false)}
            open={isParticipationStatusMenuOpen}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            {event.viewer_info.participation_status ===
            ParticipationStatus.ACCEPTED ? (
              <MenuItem
                disabled={isLoadingChangeEventParticipationStatus}
                onClick={handleDeclineParticipation}
              >
                <Text color={theme.colors.gray[4]} fontSize={14}>
                  Eu não irei mais
                </Text>
              </MenuItem>
            ) : (
              <MenuItem
                disabled={isLoadingChangeEventParticipationStatus}
                onClick={handleAcceptParticipation}
              >
                <Text color={theme.colors.gray[4]} fontSize={14}>
                  Eu irei
                </Text>
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </div>
  );
};
