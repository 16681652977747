import { Coords } from "google-map-react";
import React, { useCallback, useMemo } from "react";
import StarRatingComponent from "react-star-rating-component";

import { HospitalityHotel } from "~/apps/corporate/models/hotel.model";
import { AmenitiesIcons } from "~/apps/shared/components/amenities-icons/amenities-icons";
import { Icon } from "~/apps/shared/components/icon/icon";
import { NoImageHotel } from "~/apps/shared/components/no-image-hotel/no-image-hotel";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = Coords & {
  hotel: HospitalityHotel;
  isVisible: boolean;
  offersUrl: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
};

export const HotelMarker: React.FC<Props> = ({
  hotel,
  isVisible,
  offersUrl,
  onClick,
  onClose,
}) => {
  const minNightlyPrice = useMemo(() => toCurrency(hotel.minPrice.value), [
    hotel,
  ]);

  const handleCloseCard = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();

      e.preventDefault();
      e.stopPropagation();
    },
    [onClose],
  );

  if (!hotel.latitude || !hotel.longitude) {
    return null;
  }

  return (
    <>
      <button
        css={styles.marker({
          outOfPolicy: hotel.outOfPolicy,
        })}
        onClick={onClick}
      >
        {minNightlyPrice}
      </button>
      {isVisible ? (
        <a css={styles.link} href={offersUrl} rel="noreferrer" target="_blank">
          <div css={styles.card.root}>
            <Button
              css={styles.card.close}
              onClick={handleCloseCard}
              shape="icon"
            >
              <Icon size={16} use="x" />
            </Button>
            {hotel.mainPictureUrl ? (
              <img
                alt={hotel.name}
                css={styles.card.top.picture}
                src={hotel.mainPictureUrl}
              />
            ) : (
              <NoImageHotel css={styles.card.top.picture} />
            )}
            <div css={styles.card.bottom.root}>
              <div css={styles.card.bottom.top}>
                <StarRatingComponent
                  editing={false}
                  name="hotelRate"
                  renderStarIcon={() => <Icon size={16} use="star" />}
                  starColor={theme.colors.pink[500]}
                  starCount={hotel.stars}
                  value={hotel.stars}
                />
                {hotel.outOfPolicy ? (
                  <span css={styles.card.bottom.policy}>Fora da política</span>
                ) : null}
              </div>
              <span css={styles.card.bottom.name}>{hotel.name}</span>
              <AmenitiesIcons
                css={styles.amenities}
                breakfast={hotel.popularAmenities.breakfast}
                cancel={hotel.popularAmenities.refund}
                wifi={hotel.popularAmenities.wifi}
              />
              <span css={styles.card.bottom.price.info}>
                Diárias a partir de{" "}
                <strong css={styles.card.bottom.price.value}>
                  {minNightlyPrice}
                </strong>
              </span>
            </div>
          </div>
        </a>
      ) : null}
    </>
  );
};
