import React from "react";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  maxedImage: css({
    width: "100%"
  }),
  dialog: css({
    "& .MuiDialog-paper": {
      maxWidth: "none",
      background: "none",
      borderRadius: 0,
      boxShadow: "none",
      position: "relative",
      ["@media (max-width: 400px)"]: {
        margin: 20
      }
    }
  }),
  closeIcon: css({
    marginBottom: ".5rem",
    fontSize: 32,
    color: defaultTheme.subTextColor,
    cursor: "pointer",
    position: "absolute",
    top: "8px",
    right: "8px"
  })
};

interface IProps {
  isOpen: boolean;
  imgUrl?: string | null;
  onClose(): void;
}

const ImageDialog: React.FC<IProps> = ({ isOpen, imgUrl, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} css={styles.dialog}>
      <CloseIcon css={styles.closeIcon} onClick={onClose} />
      {imgUrl && <img src={imgUrl} css={styles.maxedImage} />}
    </Dialog>
  );
};

ImageDialog.defaultProps = {
  imgUrl: ""
} as Partial<IProps>;

export { ImageDialog };
