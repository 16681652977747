import React from "react";

import { Drawer } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { CircularSpinner } from "@toolkit/v2";

import { BusCardRefreshSeatsWarning } from "../buses-content/content-list/bus-card/bus-card-refresh-seats-warning/bus-card-refresh-seats-warning";
import { BusSeatSelection } from "../buses-content/content-list/bus-card/bus-seat-selection/bus-seat-selection";
import { useBusesTrip, useBusesTripActions } from "../buses.context";
import { styles } from "./styles";

export const BusSeatPickerDrawer: React.FC = () => {
  const { allTripsState, visibleTrip: trip } = useBusesTrip();
  const {
    handleCloseSeatsDrawer,
    handleProgressBooking,
    handleRefreshBusSeats,
    handleSeatSelection,
  } = useBusesTripActions();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 992;

  if (!isMobile) {
    return null;
  }

  return (
    <Drawer
      anchor="left"
      css={styles.dialog}
      onClose={handleCloseSeatsDrawer}
      open={!!trip}
    >
      {(() => {
        if (!trip) {
          return null;
        }

        const tripPartsState = allTripsState[trip.id].tripPartsState;

        const detailsError = allTripsState[trip.id].error;
        const isLoading = allTripsState[trip.id].isLoadingDetails;
        const wereSeatsLoaded = Object.keys(tripPartsState).find(
          (tripPartId) => {
            return tripPartsState[tripPartId].seats.length > 0;
          },
        );

        const tripParts = Object.keys(tripPartsState).map((tripPartId) => {
          return tripPartsState[tripPartId];
        });

        const areAllSeatsSelected = tripParts.every(
          (part) => part.selectedSeats.length > 0,
        );

        return (
          <div css={styles.root}>
            <div css={styles.header.root}>
              <span css={styles.header.title}>
                Selecione os assentos abaixo
              </span>
              <div css={styles.header.right.root}>
                <button
                  css={styles.header.right.button}
                  onClick={handleCloseSeatsDrawer}
                >
                  <span>Voltar aos resultados</span>
                  <Icon size={16} use="x" />
                </button>
              </div>
            </div>
            <div css={styles.body.root}>
              {isLoading && <CircularSpinner />}
              {!!detailsError && !isLoading ? (
                <BusCardRefreshSeatsWarning
                  error={detailsError}
                  onRefreshSeats={handleRefreshBusSeats(trip.id)}
                />
              ) : null}
              {!detailsError && !isLoading && wereSeatsLoaded ? (
                <BusSeatSelection
                  areAllSeatsSelected={areAllSeatsSelected}
                  onProgressBooking={handleProgressBooking(trip.id)}
                  onSeatSelect={handleSeatSelection}
                  trip={trip}
                  tripParts={tripParts}
                />
              ) : null}
            </div>
          </div>
        );
      })()}
    </Drawer>
  );
};
