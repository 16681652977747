import React, { createContext } from "react";

import { useContextFactory } from "../../hooks/use-context-factory";
import { FormField, FormLabel } from "./form-field/form-field";
import { UseFormMethods } from "./hooks/use-form";

type FormContextProps = UseFormMethods;

const FormContext = createContext<FormContextProps>({} as FormContextProps);

type Props = {
  className?: string;
  context: FormContextProps;
};

export const Form: React.FC<Props> & {
  Field: typeof FormField;
  Label: typeof FormLabel;
} = ({ children, context, ...props }) => {
  return (
    <form {...props} onSubmit={context.submitForm}>
      <FormContext.Provider value={context}>{children}</FormContext.Provider>
    </form>
  );
};

Form.Field = FormField;
Form.Label = FormLabel;

export const useFormContext = useContextFactory("FormContext", FormContext);

export { useForm } from "./hooks/use-form";
export { useFormError } from "./hooks/use-form-error";
export { FormField } from "./form-field/form-field";
