import { View } from "@react-pdf/renderer";
import React from "react";

import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPoliciesPerOffer } from "~/apps/corporate/models/travel.model";

import { styles } from "./styles";
import { TravelPlanPDFBusJourney } from "./travel-plan-pdf-bus-journey/travel-plan-pdf-bus-journey";
import { TravelPlanPDFCarJourney } from "./travel-plan-pdf-car-journey/travel-plan-pdf-car-journey";
import { TravelPlanPDFFlightJourney } from "./travel-plan-pdf-flight-journey/travel-plan-pdf-flight-journey";
import { TravelPlanPDFHotelJourney } from "./travel-plan-pdf-hotel-journey/travel-plan-pdf-hotel-journey";
import { TravelPlanPDFServiceJourneyHeader } from "./travel-plan-pdf-service-journey-header/travel-plan-pdf-service-journey-header";

type Props = {
  infoModel: ItineraryInfoModel;
  servicePresenter: ItineraryServicePresenter;
  travelPoliciesPerOffer: TravelPoliciesPerOffer;
};

export const TravelPlanPDFServiceJourney: React.FC<Props> = ({
  infoModel,
  servicePresenter,
  travelPoliciesPerOffer,
}) => {
  const rootStyles = styles.root();

  const servicePolicy =
    travelPoliciesPerOffer[servicePresenter.getOfferToken()];

  const isOutOfPolicy =
    servicePresenter.getOfferToken() in travelPoliciesPerOffer
      ? servicePresenter.isDraft() && servicePolicy.outOfPolicy
      : false;

  const traveler = infoModel.getTraveler();

  return (
    <View style={rootStyles.root}>
      <TravelPlanPDFServiceJourneyHeader
        isOutOfPolicy={isOutOfPolicy}
        servicePresenter={servicePresenter}
      />
      {servicePresenter.isBusService() ? (
        <TravelPlanPDFBusJourney servicePresenter={servicePresenter} />
      ) : null}
      {servicePresenter.isCarService() ? (
        <TravelPlanPDFCarJourney servicePresenter={servicePresenter} />
      ) : null}
      {servicePresenter.isFlightService() ? (
        <TravelPlanPDFFlightJourney servicePresenter={servicePresenter} />
      ) : null}
      {servicePresenter.isHotelService() ? (
        <TravelPlanPDFHotelJourney
          servicePresenter={servicePresenter}
          traveler={traveler}
        />
      ) : null}
    </View>
  );
};
