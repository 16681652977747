import { ClientConfigModel } from "~/apps/corporate/models/client.model";
import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";

class ItineraryInfoCategorizationPresenter {
  constructor(
    private readonly clientConfigModel: ClientConfigModel,
    private readonly infoModel: ItineraryInfoModel,
  ) {}

  public getTravelBillingProfileToken() {
    return this.infoModel.getBillingProfileToken();
  }

  public getTravelCompanyAreaToken() {
    return this.infoModel.getCompanyAreaToken();
  }

  public getTravelerToken() {
    return this.infoModel.getTravelerToken();
  }

  public getTravelCostCenterToken() {
    return this.infoModel.getCostCenterToken();
  }

  public getTravelProjectToken() {
    return this.infoModel.getProjectToken();
  }

  public travelHasBillingProfile() {
    return !!this.getTravelBillingProfileToken();
  }

  public travelHasCompanyArea() {
    return (
      this.clientConfigModel.isCompanyAreaEnabled() &&
      !!this.getTravelCompanyAreaToken()
    );
  }

  public travelHasCostCenter() {
    return (
      this.clientConfigModel.isCompanyCostCenterEnabled() &&
      !!this.getTravelCostCenterToken()
    );
  }

  public travelHasProject() {
    return (
      this.clientConfigModel.isCompanyProjectEnabled() &&
      !!this.getTravelProjectToken()
    );
  }
}

export class ItineraryInfoCategorizationPresenterFactory {
  static create(
    clientConfigModel: ClientConfigModel,
    infoModel: ItineraryInfoModel,
  ) {
    return new ItineraryInfoCategorizationPresenter(
      clientConfigModel,
      infoModel,
    );
  }
}
