import React, { useContext } from "react";

import { Box, Text } from "@toolkit";

import PageTitle from "@shared/PageTitle";
import Spinner from "@shared/Spinner";

import { HeaderContainer } from "../shared/HeaderContainer";
import { LastUpdatedMessage } from "../shared/lastUpdatedMessage";
import { MobileFilters } from "../shared/MobileFilters";
import { PanelProvider, PanelContext } from "./panel.context";
import { PanelContent } from "./PanelContent";
import { PanelFilters } from "./PanelFilters";

const Panel: React.FC = () => {
  const {
    isLoadingPage,
    loadingData,
    params,
    costCenterOptions,
    tagOptions,
    travelerOptions,
    companyAreaOptions,
    projectOptions,
    billingProfileOptions,
    onApplyFilters,
    staticData: { generatedAt },
  } = useContext(PanelContext);
  const isLoading = isLoadingPage || loadingData;
  const title = "Analytics - Painel";

  return (
    <Box p={[0, 12]}>
      <PageTitle title={title} />
      <Spinner visible={isLoading} />
      <HeaderContainer>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Text fontSize={24} fontWeight="bold">
            Visão geral
          </Text>
          {generatedAt ? <LastUpdatedMessage updatedAt={generatedAt} /> : null}
        </Box>
        <MobileFilters
          filtersData={params}
          companyAreas={companyAreaOptions}
          billingProfiles={billingProfileOptions}
          costCenters={costCenterOptions}
          projects={projectOptions}
          tags={tagOptions}
          travelers={travelerOptions}
          onApply={onApplyFilters}
        />
      </HeaderContainer>
      <PanelFilters />
      <PanelContent />
    </Box>
  );
};

const PanelContainer: React.FC = () => {
  return (
    <div>
      <PanelProvider>
        <Panel />
      </PanelProvider>
    </div>
  );
};

export { PanelContainer as Panel };
