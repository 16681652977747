import React, { useState, createContext } from "react";
import { useContextFactory } from "@hooks";
import { TagService } from "./Tag.service";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

const TagContext = createContext();

const initialState = {
  isDialogOpen: false,
  repeatedTag: false,
  selectedTags: [],
  tagInputValue: "",
  tagOptions: [],
};

export const useTagContext = useContextFactory("Tag", TagContext);

export const TagProvider = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const createTag = async (tagName) => {
    const { data: newTag, error } = await TagService.createNewTag(tagName);

    if (error) {
      showSnackMessage(createTagError.description, ALERT_TYPES.ERROR);

      return;
    }

    return newTag;
  };

  const updateTagOptions = (tag) => {
    const { tagToken } = tag;

    setState((prev) => ({
      ...prev,
      tagOptions: [
        ...prev.tagOptions,
        {
          ...transformTagIntoTagOptions(tag),
          tagToken,
        },
      ],
    }));
  };

  const updateSelectedTags = (tags) => {
    setState((prev) => ({
      ...prev,
      selectedTags: [...prev.selectedTags, ...tags],
    }));
  };

  const addTravelTag = async (tag, travelToken) => {
    const { label, isNewOption } = tag;

    const tagName = label.trim();

    let tagToken = tag.tagToken;

    const { tagOptions, selectedTags } = state;

    const tagAlreadyExists = tagOptions.some(
      (item) =>
        item.tagName.toLowerCase().trim() === tagName.toLowerCase().trim(),
    );

    const tagAlreadySelected = selectedTags.some(
      (item) =>
        item.tagName.toLowerCase().trim() === tagName.toLowerCase().trim(),
    );

    if (tagAlreadySelected) {
      setState((prev) => ({ ...prev, repeatedTag: true }));

      return;
    }

    if (isNewOption && tagAlreadyExists) {
      setState((prev) => ({ ...prev, repeatedTag: true }));

      return;
    }

    if (isNewOption) {
      const newTag = await createTag(tagName);

      updateTagOptions(newTag);

      tag = newTag;
      tagToken = newTag.tagToken;
    }

    const { data, error } = await TagService.addTravelTag(
      tagToken,
      travelToken,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      return;
    }

    const { travelTagToken } = data;

    setState((prev) => ({
      ...prev,
      repeatedTag: false,
      selectedTags: [
        ...prev.selectedTags,
        { ...tag, tagToken, travelTagToken },
      ],
    }));
  };

  const handleRemoveTravelTag = async (travelTagToken) => {
    await TagService.removeTravelTagToken(travelTagToken);
    setState((prev) => ({
      ...prev,
      repeatedTag: false,
      selectedTags: prev.selectedTags.filter(
        (tags) => tags.travelTagToken !== travelTagToken,
      ),
    }));
  };

  const loadTagOptions = async () => {
    const { data, error } = await TagService.getClientTags();

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        tagOptions: [],
      }));

      return;
    }

    const options = data.map(transformTagIntoTagOptions);

    setState((prev) => ({
      ...prev,
      tagOptions: options,
    }));
  };

  const loadTravelTags = async (travelToken) => {
    const { data, error } = await TagService.getTravelTags(travelToken);

    if (error) {
      setState((prev) => ({
        ...prev,
        selectedTags: [],
      }));
    }

    setState((prev) => ({
      ...prev,
      selectedTags: data,
    }));
  };

  const openTagDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  const closeTagDialog = () => {
    setState((prev) => ({
      ...prev,
      repeatedTag: false,
      isDialogOpen: false,
    }));
  };

  const transformTagIntoTagOptions = (tag) => {
    return {
      ...tag,
      label: tag.tagName,
      value: tag.tagName,
    };
  };

  return (
    <TagContext.Provider
      value={{
        ...state,
        addTravelTag,
        closeTagDialog,
        handleRemoveTravelTag,
        loadTagOptions,
        loadTravelTags,
        openTagDialog,
        updateSelectedTags,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
