import * as carApi from "~/apps/corporate/apis/car.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import {
  AddCarOfferToTravelRequest,
  GetCarResponse,
  RecalculateCarOfferResponse,
  RecalculateCarOfferRequest,
} from "~/apps/corporate/dtos/car.dto";
import { UserSearch } from "~/apps/corporate/models/user.model";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const addCarOfferToTravel = async (
  travelToken: string,
  data: AddCarOfferToTravelRequest,
) => {
  const result: { error?: Error } = {};

  try {
    await carApi.addCarOfferToTravel(travelToken, data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_CAR_OFFER_TO_TRAVEL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_CAR_OFFER_TO_TRAVEL_ERROR;
    }
  }

  return result;
};

export const recalculateCarOffer = async (
  carToken: string,
  dto: RecalculateCarOfferRequest,
) => {
  const result: {
    data?: RecalculateCarOfferResponse;
    error?: Error;
  } = {};

  try {
    result.data = await carApi.recalculateCarOffer(carToken, dto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_ADDITIONAL_TO_CAR_OFFER_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_ADDITIONAL_TO_CAR_OFFER_ERROR;
    }
  }

  return result;
};

export const getCarSupplierOffer = async (
  carToken: string,
  supplierOfferToken: string,
) => {
  const result: { data?: GetCarResponse; error?: Error } = {};

  try {
    result.data = await carApi.getCarSupplierOffer(
      carToken,
      supplierOfferToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_CAR_SUPPLIER_OFFER;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_CAR_SUPPLIER_OFFER;
    }
  }

  return result;
};

export const searchAdditionalDrivers = async (search: string) => {
  const result: { data?: UserSearch[]; error?: Error } = {};

  try {
    result.data = await userApi.getUsersByName(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SEARCH_CONDUCTORS;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SEARCH_CONDUCTORS;
    }
  }

  return result;
};
