import React, { useState, useEffect } from "react";

import { MenuItem } from "@material-ui/core";
import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { css as emotionCss } from "emotion";
import { isEmpty } from "lodash";

import { Box, Flex } from "@toolkit";

import { CARD_ISSUERS } from "@constants/credit-card.constant";

import { LegacyCardModel } from "@models/card.model";

import * as cardApi from "@apis/card.api";

import { Input } from "@shared/inputs/Input";

interface Props {
  [key: string]: any;
}

const styles = {
  logo: css({
    height: "auto",
    maxHeight: 30,
    width: 30,
  }),
  rowItem: css({
    alignItems: "center",
    maxWidth: "100%",
    position: "relative",
    width: "100%",
    ["@media (max-width: 767px)"]: {
      fontSize: 12,
      maxWidth: 450,
    },
  }),
  logoDiv: css({
    alignItems: "center",
    display: "flex",
    position: "absolute",
    right: "medium",
    top: "50%",
    transform: "translate(0, -50%)",
  }),
  description: css({
    mr: "xlarge",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  inputBase: emotionCss({
    maxWidth: 380,
  }),
};

enum Status {
  FAILURE = "failure",
  FETCHING = "fetching",
  IDLE = "idle",
  SUCCESS = "success",
}

interface CardOptions {
  brand: string;
  description: string;
  lastDigits: string;
  logo: string;
  token: string;
}

interface UseCreditCardOptionsState {
  cards: CardOptions[];
  error: string;
  status: Status;
}

function buildCardOptions(creditCards: LegacyCardModel[]): CardOptions[] {
  return creditCards.map((card) => ({
    brand: card.brand,
    description: card.description,
    lastDigits: card.lastDigits,
    logo: CARD_ISSUERS.find((issuer) => issuer.brand === card.brand)!.logo!,
    token: card.token,
  }));
}

const useCreditCardOptions = () => {
  const [state, setState] = useState<UseCreditCardOptionsState>({
    cards: [],
    error: "",
    status: Status.IDLE,
  });

  useEffect(() => {
    function loadAvailableCards() {
      cardApi
        .listCardsByClient()
        .then((cardsResult) => {
          const generalCards = cardsResult.filter(
            (card) => card.serviceType === "general",
          );

          setState({
            cards: buildCardOptions(generalCards),
            error: "",
            status: Status.SUCCESS,
          });
        })
        .catch((error) =>
          setState({ cards: [], status: Status.FAILURE, error }),
        );
    }
    loadAvailableCards();
  }, []);

  return { ...state, options: state.cards };
};

export const CreditCardInput: React.FC<Props> = (props) => {
  const { showSnackMessage } = useApplication();

  const { options: creditCardOptions, status, error } = useCreditCardOptions();

  if (status === Status.FAILURE) {
    showSnackMessage(error, ALERT_TYPES.ERROR);
  }

  return (
    <Input
      select={true}
      label="Cartão de crédito"
      rootStyle={styles.inputBase}
      {...props}
    >
      {creditCardOptions.map((card) => (
        <MenuItem key={card.token} value={card.token}>
          <Flex css={styles.rowItem}>
            <Box css={styles.description}>
              {card.description} (final {card.lastDigits})
            </Box>
            <div css={styles.logoDiv}>
              <img src={card.logo} css={styles.logo} />
            </div>
          </Flex>
        </MenuItem>
      ))}
      {isEmpty(creditCardOptions) && (
        <MenuItem value="">
          <Box>
            <span style={{ marginRight: ".35rem" }}>
              Nenhum cartão cadastrado
            </span>
          </Box>
        </MenuItem>
      )}
    </Input>
  );
};
