import React, { useMemo } from "react";

import { NearbyPlace } from "~/apps/corporate/models/hotel.model";
import { StringTMap } from "~/apps/shared/types";

import { PlacesList } from "./places-list/places-list";
import { styles } from "./styles";

type Props = {
  places: Partial<StringTMap<NearbyPlace[]>>;
};

export const HotelResultNearbyPlaces: React.FC<Props> = ({ places }) => {
  const { airport, restaurant, touristAttraction } = places;

  const shouldShowNearbyPlaces = useMemo(
    () =>
      (airport && airport.length > 0) ||
      (restaurant && restaurant.length > 0) ||
      (touristAttraction && touristAttraction.length > 0),
    [airport, restaurant, touristAttraction],
  );

  if (!shouldShowNearbyPlaces) {
    return null;
  }

  return (
    <div css={styles.root}>
      <h3 css={styles.title}>Proximidades do Hotel</h3>
      <div css={styles.lists}>
        {restaurant && restaurant.length > 0 && (
          <PlacesList maxPlaces={5} places={restaurant} title="Restaurantes" />
        )}
        {touristAttraction && touristAttraction.length > 0 && (
          <PlacesList
            maxPlaces={5}
            places={touristAttraction}
            title="Pontos de interesse"
          />
        )}
        {airport && airport.length > 0 && (
          <PlacesList maxPlaces={3} places={airport} title="Aeroportos" />
        )}
      </div>
    </div>
  );
};
