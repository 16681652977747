import React, { CSSProperties, useState } from "react";

import DOMPurify from "dompurify";
import { css } from "emotion";

import { defaultTheme } from "../../assets/styles/theme";

const styles = {
  clickableSpan: css({
    color: defaultTheme.primaryColor,
    fontWeight: "bold",
    cursor: "pointer"
  })
};

interface Props {
  children: string;
  limit: number;
  expansible?: boolean;
  style?: CSSProperties;
  className?: string;
}

const LongText = ({
  children,
  limit,
  expansible = false,
  style,
  className
}: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = () => setVisibility(prevVisible => !prevVisible);

  const text = visible
    ? children
    : children.length <= limit
    ? children
    : children.substring(0, limit) + "...";

  //* DOMPurify will sanitize the text message so that setting the innerHTML will no longer be dangerous
  const sanitizedHTML = DOMPurify.sanitize(text);

  const shouldShowVisibilitySpan = expansible && children.length > limit;

  return (
    <div style={style} className={className}>
      <span dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      {shouldShowVisibilitySpan ? (
        <div style={{ padding: "1rem 0" }}>
          <span className={styles.clickableSpan} onClick={toggleVisibility}>
            {visible ? "Ver menos" : "Ver mais"}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export { LongText };
