import * as yup from "yup";

export const editBasicInformationSchema = yup.object().shape({
  approvalProcessToken: yup.string().nullable(true),
  billingProfileToken: yup.string().nullable(true),
  firstName: yup.string().required("Nome é obrigatório."),
  lastName: yup.string().required("Sobrenome é obrigatório."),
  phone: yup.string().nullable(true),
  sendSms: yup.boolean().nullable(true),
});

export type EditBasicInformationSchema = yup.InferType<
  typeof editBasicInformationSchema
>;
