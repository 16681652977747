import { useQuery } from "react-query";

import * as customFieldOptionsApi from "~/apps/corporate/apis/custom-field-options.api";
import { CustomFieldOptionsType } from "~/apps/corporate/dtos/custom-field-options.dto";
import { getClientToken } from "~/apps/corporate/helpers/user.helper";

export const useCustomFieldOptions = ({
  shouldFetchFlightPurposes,
  shouldFetchHotelPurposes,
  shouldFetchTripPurposes,
}: {
  shouldFetchFlightPurposes?: boolean;
  shouldFetchHotelPurposes?: boolean;
  shouldFetchTripPurposes?: boolean;
}) => {
  const clientToken = getClientToken();

  const {
    data: customFieldOptions,
    error: errorCustomFieldOptions,
    isLoading: isLoadingCustomFieldOptions,
  } = useQuery(
    ["client", clientToken, "custom-field-options"],
    async () => {
      if (!clientToken) {
        return;
      }

      if (
        shouldFetchFlightPurposes === undefined ||
        shouldFetchHotelPurposes === undefined ||
        shouldFetchTripPurposes === undefined
      ) {
        return;
      }

      const [flightPurposes, hotelPurposes, tripPurposes] = await Promise.all(
        [
          CustomFieldOptionsType.FLIGHT_PURPOSE,
          CustomFieldOptionsType.HOTEL_PURPOSE,
          CustomFieldOptionsType.TRIP_PURPOSE,
        ].map((type) => {
          if (
            type === CustomFieldOptionsType.FLIGHT_PURPOSE &&
            shouldFetchFlightPurposes
          ) {
            return customFieldOptionsApi.getClientCustomFieldOptions(
              clientToken,
              type,
            );
          }

          if (
            type === CustomFieldOptionsType.HOTEL_PURPOSE &&
            shouldFetchHotelPurposes
          ) {
            return customFieldOptionsApi.getClientCustomFieldOptions(
              clientToken,
              type,
            );
          }

          if (
            type === CustomFieldOptionsType.TRIP_PURPOSE &&
            shouldFetchTripPurposes
          ) {
            return customFieldOptionsApi.getClientCustomFieldOptions(
              clientToken,
              type,
            );
          }

          return null;
        }),
      );

      return {
        flightPurposes,
        hotelPurposes,
        tripPurposes,
      };
    },
    {
      enabled:
        !!clientToken &&
        (shouldFetchFlightPurposes ||
          shouldFetchHotelPurposes ||
          shouldFetchTripPurposes),
    },
  );

  return {
    customFieldOptions,
    errorCustomFieldOptions,
    isLoadingCustomFieldOptions,
  };
};
