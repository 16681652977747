import { LoyaltyProgramResponseBody } from "../dtos/loyalty-program.dto";
import { LoyaltyProgram } from "../models/loyalty-program.model";

export const parseLoyaltyProgram = (data: LoyaltyProgramResponseBody) => {
  const loyaltyProgram: LoyaltyProgram = {
    active: data.active,
    code: data.code,
    loyaltyProgramToken: data.loyalty_program_token,
    name: data.name,
    userToken: data.user_token,
  };

  return loyaltyProgram;
};
