import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
  services: {
    empty: css({
      color: theme.colors.gray[500],
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    }),
  },
  skeleton: {
    section: css({
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
