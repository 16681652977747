import React, { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  WindowScroller,
} from "react-virtualized";

import { CountryRestrictionMessage } from "~/apps/corporate/components/country-restriction-message/country-restriction-message";
import * as busHelper from "~/apps/corporate/helpers/bus.helper";
import { NoResultsWarning } from "~/apps/shared/components/no-results-warning/no-results-warning";
import { BUS_STRETCH_TYPE } from "~/apps/shared/constants";
import { useUpdatedVirtualizedGrid } from "~/apps/shared/hooks/use-updated-virtualized-grid";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import {
  useBusesPage,
  useBusesPageActions,
  useBusesSearch,
  useBusesTrip,
  useBusesTripActions,
} from "../../buses.context";
import { ContentFilters } from "../content-filters/content-filters";
import { BusCard, BusCardSkeleton } from "./bus-card/bus-card";
import { BusStretchTabs } from "./bus-stretch-tabs/bus-stretch-tabs";
import { styles } from "./styles";

export const ContentList: React.FC = () => {
  const {
    currentSection,
    countryRestriction,
    departureTrips,
    isFiltering: filtering,
    isLoading,
    returnTrips,
  } = useBusesPage();
  const {
    handleChangeSection,
    handleToggleCategoryExplanation,
  } = useBusesPageActions();
  const { searchInfo } = useBusesSearch();
  const { allTripsState, visibleTrips } = useBusesTrip();
  const {
    handleProgressBooking,
    handleRefreshBusSeats,
    handleSeatSelection,
    handleTripDetailsClick,
  } = useBusesTripActions();

  const trips =
    currentSection === BUS_STRETCH_TYPE.DEPARTURE
      ? departureTrips
      : returnTrips;

  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 1024, [windowWidth]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
  });

  const listRef = useRef<List>(null);

  const renderBus: ListRowRenderer = ({ index, key, parent, style }) => {
    const trip = visibleTrips[index];
    const tripFromAllTrips = allTripsState[trip.id];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!tripFromAllTrips) {
      return null;
    }

    const detailsError = tripFromAllTrips.error;
    const isLoading = tripFromAllTrips.isLoadingDetails;
    const isOpen = tripFromAllTrips.isOpen;
    const tripPartsState = tripFromAllTrips.tripPartsState;
    const wereSeatsLoaded = Object.keys(tripPartsState).find((tripPartId) => {
      return tripPartsState[tripPartId].seats.length > 0;
    });

    const tripParts = Object.keys(tripPartsState).map((tripPartId) => {
      return tripPartsState[tripPartId];
    });

    const areAllSeatsSelected = tripParts.every(
      (part) => part.selectedSeats.length > 0,
    );

    const { arrival, departure } = busHelper.getTripSegments(trip);
    const hasAvailability = busHelper.getOfferSeatAvailability(trip.parts[0]);

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <BusCard
            areAllSeatsSelected={areAllSeatsSelected}
            arrival={arrival}
            departure={departure}
            handleToggleCategoryExplanation={handleToggleCategoryExplanation}
            hasAvailability={hasAvailability}
            isLoading={isLoading}
            isOpen={isOpen}
            loadingError={detailsError}
            onProgressBooking={handleProgressBooking(trip.id)}
            onRefreshSeats={handleRefreshBusSeats(trip.id)}
            onSeatSelect={handleSeatSelection}
            selectBus={handleTripDetailsClick(trip)}
            trip={trip}
            tripParts={tripParts}
            wereSeatsLoaded={wereSeatsLoaded}
          />
        </div>
      </CellMeasurer>
    );
  };

  const stats = useMemo(() => {
    const count = visibleTrips.length;

    return filtering
      ? "Filtrando..."
      : `${count} ${count === 1 ? "resultado" : "resultados"}${
          !isMobile ? " de ônibus para sua pesquisa" : ""
        }`;
  }, [filtering, visibleTrips]);

  useUpdatedVirtualizedGrid(cache, listRef);

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        {countryRestriction ? (
          <CountryRestrictionMessage
            message={countryRestriction.message_content}
            restrictionType={countryRestriction.restriction_type}
          />
        ) : null}
        {searchInfo && !searchInfo.oneway ? (
          <BusStretchTabs
            currentSection={currentSection}
            handleClick={handleChangeSection}
          />
        ) : null}
        <div css={styles.stats.root}>
          <span css={styles.stats.count}>
            {!isLoading ? (
              <p css={styles.stats.root}>{stats}</p>
            ) : (
              <Skeleton height="16px" width={!isMobile ? "288px" : "96px"} />
            )}
          </span>
          {!isMobile ? null : <ContentFilters isMobile={isMobile} />}
        </div>
      </div>
      {!isLoading ? (
        <WindowScroller>
          {({ height, isScrolling, registerChild, scrollTop }) => (
            <div style={{ flex: "1 1 auto" }}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <List
                      autoHeight
                      deferredMeasurementCache={cache}
                      height={height}
                      isScrolling={isScrolling}
                      noRowsRenderer={() => (
                        <div css={styles.empty.root}>
                          <NoResultsWarning
                            message={
                              trips.length === 0 ? (
                                <>
                                  <p>
                                    Ops, parece que não encontramos nenhum
                                    ônibus para a sua pesquisa em nossos
                                    fornecedores.
                                  </p>
                                  <p css={styles.empty.text}>
                                    Pedimos deculpa pelo transtorno. Por ora,
                                    recomendamos que entre em contato com nossa
                                    equipe de atendimento para que possamos
                                    encontrar um ônibus para você.
                                  </p>
                                </>
                              ) : trips.length > 0 &&
                                visibleTrips.length === 0 ? (
                                "Nenhum ônibus encontrado para filtro o selecionado..."
                              ) : null
                            }
                          />
                        </div>
                      )}
                      overscanRowCount={8}
                      ref={listRef}
                      rowCount={visibleTrips.length}
                      rowHeight={cache.rowHeight}
                      rowRenderer={renderBus}
                      scrollTop={scrollTop}
                      style={{ outline: "none" }}
                      width={width}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          )}
        </WindowScroller>
      ) : (
        <div>
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <BusCardSkeleton key={index} />
            ))}
        </div>
      )}
    </div>
  );
};
