import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { CarOffer } from "~/apps/corporate/models/car.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { formatCurrency } from "~/apps/shared/utils/format-currency";
import moment from "moment";

import { Button, Badge } from "@toolkit/v2";

import { getAllAdditionalsFlattenedForPresentationBySupplier } from "./car-summary.helper";
import { styles } from "./styles";

type Props = {
  car: CarOffer;
  handleOpenOutOfPolicyDialog: () => void;
  handleSelectOffer: () => void;
  loading: boolean;
};

export const CarSummary: React.FC<Props> = ({
  car,
  handleOpenOutOfPolicyDialog,
  handleSelectOffer,
  loading,
}) => {
  const additionals = useMemo(
    () => getAllAdditionalsFlattenedForPresentationBySupplier(car),
    [car],
  );

  const days = useMemo(() => car.contractDetails.dailyInfo.quantity, [car]);

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        {car.outOfPolicy ? (
          <Badge
            css={styles.top.badge.root}
            onClick={handleOpenOutOfPolicyDialog}
          >
            <Icon
              css={styles.top.badge.icon}
              size={16}
              use="exclamation-triangle"
            />
            Fora da política
          </Badge>
        ) : null}
        <img
          alt={car.rentalDetails.name}
          height={24}
          src={car.rentalDetails.logo}
        />
        <img
          alt={car.carDetails.modelDescription}
          height={128}
          src={car.carDetails.image}
        />
        <div css={styles.top.model.root}>
          <span css={styles.top.model.description}>
            {car.carDetails.modelDescription}
          </span>
          <span css={styles.top.model.title}>{car.carDetails.modelTitle}</span>
        </div>
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.points.root}>
          <div css={styles.center.points.point.root}>
            <div css={styles.center.points.point.title.root}>
              <Icon size={16} use="map-pin" />
              <span css={styles.center.points.point.title.text}>Retirada</span>
            </div>
            <div css={styles.center.points.point.location.root}>
              <span css={styles.center.points.point.location.rental}>
                {car.pickup.storeName.toLowerCase()}
              </span>
              <span css={styles.center.points.point.location.search}>
                {car.pickup.address.toLowerCase()}
              </span>
            </div>
            <div css={styles.center.points.point.dates.root}>
              <div css={styles.center.points.point.dates.date}>
                <Icon size={14} use="calendar" />
                <span>{moment(car.pickup.date).format("ddd, DD/MM/YYYY")}</span>
              </div>
              <div css={styles.center.points.point.dates.time}>
                <Icon size={16} use="clock-outline" />
                <span>{car.pickup.time}</span>
              </div>
            </div>
          </div>
          <div css={styles.center.points.point.divisor} />
          <div css={styles.center.points.point.root}>
            <div css={styles.center.points.point.title.root}>
              <Icon size={16} use="map-pin" />
              <span css={styles.center.points.point.title.text}>Devolução</span>
            </div>
            <div css={styles.center.points.point.location.root}>
              <span css={styles.center.points.point.location.rental}>
                {car.dropoff.storeName.toLowerCase()}
              </span>
              <span css={styles.center.points.point.location.search}>
                {car.dropoff.address.toLowerCase()}
              </span>
            </div>
            <div css={styles.center.points.point.dates.root}>
              <div css={styles.center.points.point.dates.date}>
                <Icon size={14} use="calendar" />
                <span>
                  {moment(car.dropoff.date).format("ddd, DD/MM/YYYY")}
                </span>
              </div>
              <div css={styles.center.points.point.dates.time}>
                <Icon size={16} use="clock-outline" />
                <span>{car.dropoff.time}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={styles.bottom.root}>
        <div css={styles.bottom.subs.root}>
          {/* <span css={styles.bottom.total.label}>Sub-total</span> */}
          <span css={styles.bottom.total.label}>Adicionais</span>
          {additionals
            ? additionals.map((additional) =>
                additional.selected ? (
                  <div css={styles.bottom.subs.sub.root} key={additional.code}>
                    <span css={styles.bottom.subs.sub.label}>
                      {additional.name}
                    </span>
                    {/* <div css={styles.bottom.subs.sub.bottom.root}>
                      <span css={styles.bottom.subs.sub.bottom.label}>
                        {additional.quantity >= 2 ? (
                          <>{additional.quantity} &times; </>
                        ) : null}
                        {additional.rate === "daily" ? (
                          <>
                            {additional.dayCount === 0 ? (
                              1
                            ) : (
                              <>{additional.dayCount}</>
                            )}{" "}
                            diárias &times;{" "}
                          </>
                        ) : (
                          <>1 &times; </>
                        )}
                        {formatCurrency(additional.price, "BRL")}
                      </span>
                      <span css={styles.bottom.subs.sub.bottom.value}>
                        {formatCurrency(
                          additional.dayCount *
                            (additional.quantity === 0
                              ? 1
                              : additional.quantity) *
                            additional.price,
                          "BRL"
                        )}
                      </span>
                    </div> */}
                  </div>
                ) : null,
              )
            : null}
        </div>
        <div css={styles.bottom.total.root}>
          <span css={styles.bottom.total.label}>
            Total previsto para {days} diárias:
          </span>
          <span css={styles.bottom.total.value}>
            {formatCurrency(car.contractDetails.finalPriceInfo.netTotal, "BRL")}
          </span>
        </div>
        <Button
          css={styles.bottom.button}
          disabled={loading}
          onClick={handleSelectOffer}
        >
          Adicionar à viagem
        </Button>
      </div>
    </div>
  );
};

export const CarSummarySkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <Skeleton height="128px" width="256px" />
        <div css={styles.top.model.root}>
          <Skeleton height="16px" width="192px" />
          <Skeleton height="14px" width="144px" />
        </div>
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.points.root}>
          <div css={styles.center.points.point.root}>
            <div css={styles.center.points.point.title.root}>
              <Icon size={16} use="map-pin" />
              <span css={styles.center.points.point.title.text}>Retirada</span>
            </div>
            <div css={styles.center.points.point.location.root}>
              <Skeleton height="14px" width="256px" />
              <Skeleton height="14px" width="288px" />
            </div>
            <div css={styles.center.points.point.dates.root}>
              <Skeleton height="14px" width="32px" />
              <Skeleton height="14px" width="32px" />
            </div>
          </div>
          <div css={styles.center.points.point.root}>
            <div css={styles.center.points.point.title.root}>
              <Icon size={16} use="map-pin" />
              <span css={styles.center.points.point.title.text}>Devolução</span>
            </div>
            <div css={styles.center.points.point.location.root}>
              <Skeleton height="14px" width="256px" />
              <Skeleton height="14px" width="288px" />
            </div>
            <div css={styles.center.points.point.dates.root}>
              <Skeleton height="14px" width="32px" />
              <Skeleton height="14px" width="32px" />
            </div>
          </div>
        </div>
      </div>
      <div css={styles.bottom.root}>
        <div css={styles.bottom.total.root}>
          <span css={styles.bottom.total.label}>
            Total previsto para <Skeleton height="14px" width="16px" /> diárias:
          </span>
          <Skeleton height="16px" width="96px" />
        </div>
        <Button css={styles.bottom.button} disabled>
          Adicionar à viagem
        </Button>
      </div>
    </div>
  );
};
