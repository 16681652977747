import React, { useEffect } from "react";

import { Router } from "@reach/router";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import Layout from "@shared/Layout";
import styled from "@shared/styled";

import { HotelReviewDrawer } from "@components/shared-logic/HotelReview/HotelReviewDrawer";
import PageTitle from "@components/shared/PageTitle";
import SpinnerPortal from "@components/shared/Spinner";

import { DoneReviews } from "./DoneReviews";
import { PendingReviews } from "./PendingReviews";
import { ReviewTabs } from "./ReviewTabs";
import {
  UserReviewsProvider,
  useUserReviewsActionsContext,
  useUserReviewsContext
} from "./UserReviews.context";

const Container = styled("div")(
  css({
    width: "100%",
    px: "medium",
    mx: "auto",
    maxWidth: ["auto", 1250]
  })
);

const UserReviews = props => {
  const { isFetching, selectedReview } = useUserReviewsContext();
  const {
    loadPage,
    handleCloseReviewDrawer,
    onHotelReviewSuccess
  } = useUserReviewsActionsContext();

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <Layout>
      <PageTitle title="Minhas avaliações" />
      <SpinnerPortal visible={isFetching} />
      <Container>
        <Box py={["large"]} pb={[60, "large"]}>
          <Box mb={["large", 40]}>
            <Text fontSize={24} fontWeight="bold">
              Minhas avaliações
            </Text>
          </Box>
          <Box width="100%">
            <Flex flexDirection={["column", "row"]}>
              <ReviewTabs />
              <Box width={"100%"}>
                <Router>
                  <DoneReviews path="/" />
                  <PendingReviews path="/pending-reviews" />
                </Router>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Container>
      <HotelReviewDrawer
        isOpen={!!selectedReview}
        offerToken={selectedReview ? selectedReview.offerToken : ""}
        onSuccess={onHotelReviewSuccess}
        onClose={handleCloseReviewDrawer}
      />
    </Layout>
  );
};

const ContextContainer = props => {
  return (
    <UserReviewsProvider>
      <UserReviews {...props} />
    </UserReviewsProvider>
  );
};

export { ContextContainer as UserReviews };
