import React from "react";

import classNames from "classnames";
import { css } from "emotion";

import { theme } from "@skin/v2";

import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  root: css({
    backgroundColor: "#FFFFFF",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    color: defaultTheme.textColor,
    cursor: "pointer",
    fontSize: 14,
    padding: ".5rem 1rem",
    width: "auto",
    "&:hover": {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
      borderColor: defaultTheme.cardSecondaryBackgroundColor,
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.colors.pink[500],
      border: `1px solid ${theme.colors.pink[500]}`,
      color: "#FFFFFF",
      "&:hover": {
        opacity: 0.9,
      },
    },
  }),
};

type Props = React.ComponentPropsWithoutRef<"div"> & {
  isActive?: boolean;
};

const Button: React.FC<Props> = ({
  children,
  className,
  isActive = false,
  ...props
}) => {
  return (
    <div
      aria-selected={isActive}
      className={classNames(styles.root, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export { Button as FilterButton };
