import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  autocomplete: {
    item: {
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: isHighlighted
            ? theme.colors.background.gray
            : "transparent",
          color: theme.colors.gray[500],
          cursor: isDisabled ? "default" : "pointer",
          display: "flex",
          flexDirection: "column",
          fontSize: "0.875rem",
          gap: "4px",
          padding: "12px 16px",
        }),
    },
  },
  info: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    lineHeight: "20px",
    margin: "4px 0 0 16px",
  }),
  input: css({
    width: "288px",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  targets: {
    item: {
      delete: css({
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        outline: "none",
      }),
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
        marginRight: "8px",
        whiteSpace: "nowrap",
      }),
      name: css({
        color: theme.colors.gray[500],
        flex: 1,
        fontSize: "0.875rem",
      }),
      root: css({
        display: "flex",
        padding: "8px 0",
        "&:not(&:last-child)": {
          borderBottom: `1px solid ${theme.colors.background.gray}`,
        },
      }),
    },
    list: css({
      display: "flex",
      flexDirection: "column",
      margin: "4px 0 0 16px",
    }),
  },
};
