import React from "react";
import Skeleton from "react-loading-skeleton";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useHotels } from "../../../hotels.context";
import { styles } from "./styles";

export const SidebarSummary: React.FC = () => {
  const { averagePrice, isLoading, policy } = useHotels();

  return (
    <div css={styles.root}>
      <PermissionProtectedRender permissions={["travels.policies"]}>
        <div css={styles.section.root}>
          <span css={styles.section.title}>Sua política</span>
          <ul css={styles.section.list.root}>
            <li css={styles.section.list.item.root}>
              <Icon size={16} use="dollar" />
              <span css={styles.section.list.item.text}>
                Valor máximo para o destino:{" "}
                {!isLoading ? (policy ? toCurrency(policy.price) : null) : null}
              </span>
              {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
            </li>
            <li css={styles.section.list.item.root}>
              <Icon size={16} use="clock-outline" />
              <span css={styles.section.list.item.text}>
                Antecedência mínima de compra:{" "}
                {!isLoading ? (policy ? policy.advance + " dias" : null) : null}
              </span>
              {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
            </li>
            <li css={styles.section.list.item.root}>
              <Icon size={16} use="star-outline" />
              <span css={styles.section.list.item.text}>
                Máximo de estrelas permitidas:{" "}
                {!isLoading
                  ? policy
                    ? policy.stars + " estrelas"
                    : null
                  : null}
              </span>
              {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
            </li>
          </ul>
        </div>
      </PermissionProtectedRender>
      <div css={styles.section.root}>
        <span css={styles.section.title}>Estatísticas do destino</span>
        <ul css={styles.section.list.root}>
          <li css={styles.section.list.item.root}>
            <Icon size={16} use="wallet" />
            <span css={styles.section.list.item.text}>
              Preço médio: {!isLoading ? toCurrency(averagePrice) : null}
            </span>
            {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
          </li>
        </ul>
      </div>
    </div>
  );
};
