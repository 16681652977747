import {
  BookingFeeStatus,
  CarCategory,
  CurrencyCode,
  OfferStatus,
  PaymentOption,
  ServiceType,
} from "~/apps/shared/constants/enums";
import moment from "moment";

import * as itineraryHelper from "../helpers/itinerary.helper";
import { BusService } from "./bus.model";
import { CarService } from "./car.model";
import { CreditCard } from "./credit-card.model";
import { FlightService } from "./flight.model";
import { HotelService } from "./hotel.model";
import { ItineraryServiceModel } from "./itinerary/itinerary-service.model";
import { ItineraryServicePresenterFactory } from "./itinerary/itinerary-service.presenter";
import {
  ItineraryServices,
  ItineraryServicesModelFactory,
  ItineraryServicesModel,
} from "./itinerary/itinerary-services.model";
import { VCN } from "./vcn.model";

export type CarPolicy = {
  advance: number | null;
  isNational?: boolean;
  maxCategory: CarCategory | null;
  outOfPolicy?: boolean;
};

export type FlightPolicy = {
  advance: number;
  class: string;
  maxValue: number;
  optimalPrice: {
    enabled: boolean;
    price: number;
  };
  outOfPolicy: boolean;
  priceExcess: number;
};

export type HotelPolicy = {
  advance: number;
  maxValue: number;
  outOfPolicy: boolean;
  stars: number;
};

export type IPolicy = FlightPolicy | HotelPolicy | CarPolicy;

export type Travel = {
  advanceDays: number;
  billingProfileToken: string | null;
  bookerToken: string;
  bookingFeeStatus: BookingFeeStatus;
  companyAreaToken: string | null;
  costCenterToken: string | null;
  createdAt: string;
  eventToken: string | null;
  expenseReportToken: string | null;
  feePaidAt: string | null;
  feeValue: number | null;
  flightPurposeToken: string | null;
  hasAnyManuallyAprovedOfferNotProcessed: boolean;
  hotelPurposeToken: string | null;
  isDeletable: boolean;
  isPossibleToAddOrAlterOffers: boolean;
  isProcessable: boolean;
  name: string;
  projectToken: string | null;
  rawEndTravel: string;
  rawStartTravel: string;
  services: TravelServices;
  summary: {
    approvalDenied: number;
    canceled: number;
    canceling: number;
    draft: number;
    emissionFailure: number;
    emitted: number;
    emitting: number;
    total: number;
  };
  token: string;
  travelerToken: string;
  travelProps: {
    allOffersEmitted: boolean;
    allOffersCanceled: boolean;
    hasEmittingOffers: boolean;
    hasEmittedOffers: boolean;
  };
  tripPurposeToken: string | null;
};

export type TravelAvailability = {
  availability: boolean;
  currentPrice: number;
  message: string;
  offerToken: string;
  selectedPrice: number;
};

export type TravelChanges = {
  availabilityChanges: {
    availability: boolean;
    currentPrice: number;
    difference: number;
    name: string;
    offerToken: string;
    type: string;
  }[];
  hasChanges: boolean;
  priceChanges: {
    availability: boolean;
    currentPrice: number;
    difference: number;
    name: string;
    offerToken: string;
    type: string;
  }[];
};

export type TravelFlightCredit = {
  consumerOfferToken: string;
  expirationDate: string;
  initialUsageDate: string;
  international: boolean;
  operationalId: string;
  providerCreditToken: string;
  value: number;
};

export type TravelFlightCredits = Record<string, TravelFlightCredit>;

export type TravelInfo = {
  advanceDays: number;
  bookerToken: string;
  bus: boolean;
  canceled: boolean;
  car: boolean;
  clientToken: string;
  concluded: boolean;
  costCenterToken: string;
  createdAt: string;
  draft: boolean;
  flight: boolean;
  hotel: boolean;
  id: number;
  scheduled: boolean;
  status: number;
  traveled: boolean;
  travelEnd: boolean;
  travelerToken: string;
  travelName: string;
  travelStart: boolean;
  travelToken: string;
};

export type TravelJustificationPolicy = {
  needJustification: boolean;
  offerTokensToJustify: string[];
};

export type TravelApproval = {
  emittedOffers: ItineraryServices;
  offersToReview: ItineraryServices;
};

export class TravelApprovalModel {
  constructor(
    private readonly travelApproval: {
      emittedOffers: ItineraryServicesModel;
      offersToReview: ItineraryServicesModel;
    },
  ) {}

  public getApprovalReviewPresentationalServices() {
    const availableOffersToReviewServices = this.getAllAvailableOffersToReviewServices();
    const emittedServices = this.getAllEmittedServices();
    const unavailableOffersToReviewServices = this.getAllUnavailableOffersToReviewServices();

    return {
      emittedServices: itineraryHelper.getPresentationServicesGroupedByOfferStatus(
        emittedServices.map((service) =>
          ItineraryServicePresenterFactory.create(service),
        ),
      ),
      frustratedPurchaseServices: itineraryHelper.getPresentationServicesGroupedByOfferStatus(
        unavailableOffersToReviewServices.map((service) =>
          ItineraryServicePresenterFactory.create(service),
        ),
      ),
      offersToReviewServices: itineraryHelper.getPresentationServicesGroupedByOfferStatus(
        availableOffersToReviewServices.map((service) =>
          ItineraryServicePresenterFactory.create(service),
        ),
      ),
    };
  }

  public getAllAvailableOffersToReviewServices() {
    return this.getAllOffersToReviewServices().filter(
      (service) => !service.isUnavailable(),
    );
  }

  public getAllEmittedServices() {
    return this.travelApproval.emittedOffers.getAllServices();
  }

  public getAllOffersToReviewServices() {
    return this.travelApproval.offersToReview.getAllServices();
  }

  private getAllServices() {
    return [
      ...this.getAllEmittedServices(),
      ...this.getAllOffersToReviewServices(),
    ];
  }

  private getAllUnavailableOffersToReviewServices() {
    return this.getAllOffersToReviewServices().filter((service) =>
      service.isUnavailable(),
    );
  }

  public getNotDeniedAndUnavailableServices() {
    const allServices = this.getAllServices();

    return allServices.filter((service) => {
      const isApprovalDenied = service.isApprovalDeclined();
      const isUnavailable = service.isUnavailable();

      return !isApprovalDenied && isUnavailable;
    });
  }

  public getUnavailableServiceTokens() {
    const allServices = this.getAllServices();

    const unavailableServices = allServices.filter((service) =>
      service.isUnavailable(),
    );

    return unavailableServices.map((service) => service.getOfferToken());
  }

  public hasOffersToProceed() {
    return this.getAllOffersToReviewServices().length > 0;
  }

  public hasOutOfPolicyService(travelPoliciesPerOffer: TravelPoliciesPerOffer) {
    return this.getAllServices().some((service) => {
      const offerToken = service.getOfferToken();

      if (!(offerToken in travelPoliciesPerOffer)) {
        return false;
      }

      const travelPolicy = travelPoliciesPerOffer[offerToken];

      return travelPolicy.outOfPolicy;
    });
  }

  public isSomeOfferNotInBRL() {
    return this.getAllServices().some(
      (service) => service.getCurrency() !== CurrencyCode.BRL,
    );
  }
}

export class TravelApprovalModelFactory {
  static create(travelApproval: TravelApproval) {
    const emittedOffersModel = ItineraryServicesModelFactory.create(
      travelApproval.emittedOffers,
    );

    const offersToReviewModel = ItineraryServicesModelFactory.create(
      travelApproval.offersToReview,
    );

    return new TravelApprovalModel({
      emittedOffers: emittedOffersModel,
      offersToReview: offersToReviewModel,
    });
  }
}

// ---

export type TravelPayment = {
  bookingFeeStatus: BookingFeeStatus;
  feePaidAt: string | null;
  feeValue: number | null;
  services: ItineraryServices;
};

export class TravelPaymentModel {
  constructor(
    private readonly travelPayment: {
      bookingFeeStatus: BookingFeeStatus;
      feePaidAt: string | null;
      feeValue: number | null;
      services: ItineraryServicesModel;
    },
  ) {}

  public areAllProcessableServicesPaidWithCard(
    paymentOptions: Record<ServiceType, PaymentOption>,
  ) {
    return [
      ...(this.hasProcessableBusServices() ? [paymentOptions.bus] : []),
      ...(this.hasProcessableCarServices() ? [paymentOptions.car] : []),
      ...(this.hasProcessableFlightServices() ? [paymentOptions.flight] : []),
      ...(this.hasProcessableHotelServices() ? [paymentOptions.hotel] : []),
    ].every((paymentOption) => paymentOption === PaymentOption.CREDIT_CARD);
  }

  private getBookingFeeStatus() {
    return this.travelPayment.bookingFeeStatus;
  }

  public getFeePaidAt() {
    const feePaidAt = this.travelPayment.feePaidAt;

    if (!feePaidAt) {
      return null;
    }

    return moment(feePaidAt);
  }

  public getFeeValue() {
    return this.travelPayment.feeValue;
  }

  public getServiceByOfferToken(offerToken: string) {
    return this.travelPayment.services.getServiceByOfferToken(offerToken);
  }

  public isBookingFeeNotPaid() {
    return this.getBookingFeeStatus() !== BookingFeeStatus.PAID;
  }

  public isBookingFeeNotPending() {
    return this.getBookingFeeStatus() !== BookingFeeStatus.PENDING;
  }

  public isSomeOfferNotInBRL() {
    return this.getAllServices().some(
      (service) => service.getCurrency() !== CurrencyCode.BRL,
    );
  }

  public getAllPayableCardsForChosenServices(
    payableCards: Record<
      ServiceType | "other",
      {
        creditCards: CreditCard[];
        vcns: VCN[];
      }
    >,
    paymentOptions: Record<ServiceType, PaymentOption>,
  ) {
    const processableServiceTypes = Object.keys(paymentOptions);

    return Object.entries(payableCards).reduce(
      (prev, [k, v]) => {
        if (!processableServiceTypes.includes(k)) {
          return prev;
        }

        const creditCardsToPush: CreditCard[] = [];

        v.creditCards.forEach((creditCard) => {
          if (
            prev.creditCards.find(
              (card) => card.creditCardToken === creditCard.creditCardToken,
            )
          ) {
            return;
          }

          creditCardsToPush.push(creditCard);
        });

        const vcnsToPush: VCN[] = [];

        v.vcns.forEach((vcn) => {
          if (prev.vcns.find((card) => card.vcnToken === vcn.vcnToken)) {
            return;
          }

          vcnsToPush.push(vcn);
        });

        return {
          ...prev,
          creditCards: [...prev.creditCards, ...creditCardsToPush],
          vcns: [...prev.vcns, ...vcnsToPush],
        };
      },
      {
        creditCards: [],
        vcns: [],
      } as {
        creditCards: CreditCard[];
        vcns: VCN[];
      },
    );
  }

  public getAllServices() {
    return this.travelPayment.services.getAllServices();
  }

  public getCardTokensDefaultValue(
    payableCards: Record<
      ServiceType | "other",
      {
        creditCards: CreditCard[];
        vcns: VCN[];
      }
    >,
    processableServices: ItineraryServiceModel[],
  ) {
    return Object.entries(payableCards).reduce(
      (prev, [serviceType, v]) => {
        const isServiceTypeValid = [
          ServiceType.BUS,
          ServiceType.CAR,
          ServiceType.FLIGHT,
          ServiceType.HOTEL,
          "other",
        ].includes(serviceType as ServiceType | "other");

        if (!isServiceTypeValid) {
          return prev;
        }

        if (serviceType === "other") {
          prev["feeCardToken"] =
            v.creditCards.length === 1
              ? v.creditCards[0].creditCardToken
              : null;

          return prev;
        }

        const hasProcessableServices = processableServices.some(
          (service) => service.getType() === serviceType,
        );

        prev[
          `${serviceType}CardToken` as keyof typeof prev
        ] = hasProcessableServices
          ? v.creditCards.length === 1
            ? v.creditCards[0].creditCardToken
            : v.vcns.length === 1
            ? v.vcns[0].vcnToken
            : null
          : null;

        return prev;
      },
      {
        busCardToken: null,
        carCardToken: null,
        feeCardToken: null,
        flightCardToken: null,
        hotelCardToken: null,
      } as {
        busCardToken: string | null;
        carCardToken: string | null;
        feeCardToken: string | null;
        flightCardToken: string | null;
        hotelCardToken: string | null;
      },
    );
  }

  public getServicesModel() {
    return this.travelPayment.services;
  }

  public hasAtLeastOnePayableCardForChosenServices(
    payableCards: Record<
      ServiceType | "other",
      {
        creditCards: CreditCard[];
        vcns: VCN[];
      }
    >,
    paymentOptions: Record<ServiceType, PaymentOption>,
  ) {
    return Object.values(
      this.getAllPayableCardsForChosenServices(payableCards, paymentOptions),
    ).some((payableCards) => payableCards.length > 0);
  }

  public hasAtLeastOneCardWithSpecificPaymentIntentForChosenServices(
    payableCards: Record<
      ServiceType | "other",
      {
        creditCards: CreditCard[];
        vcns: VCN[];
      }
    >,
    paymentOptions: Record<ServiceType, PaymentOption>,
  ) {
    const processableServiceTypes = Object.keys(paymentOptions);

    const cardToPaymentIntentMap = {} as Record<
      string,
      typeof processableServiceTypes
    >;

    Object.entries(payableCards).forEach(([serviceType, payableCards]) => {
      if (!processableServiceTypes.includes(serviceType)) {
        return;
      }

      payableCards.creditCards.forEach((creditCard) => {
        const creditCardToken = creditCard.creditCardToken;

        if (!(creditCardToken in cardToPaymentIntentMap)) {
          cardToPaymentIntentMap[creditCardToken] = [serviceType];

          return;
        }

        cardToPaymentIntentMap[creditCardToken] = [
          ...cardToPaymentIntentMap[creditCardToken],
          serviceType,
        ];
      });

      payableCards.vcns.forEach((vcn) => {
        const vcnToken = vcn.vcnToken;

        if (!(vcnToken in cardToPaymentIntentMap)) {
          cardToPaymentIntentMap[vcnToken] = [serviceType as ServiceType];

          return;
        }

        cardToPaymentIntentMap[vcnToken] = [
          ...cardToPaymentIntentMap[vcnToken],
          serviceType as ServiceType,
        ];
      });
    });

    return Object.values(cardToPaymentIntentMap).some(
      (paymentIntents) =>
        paymentIntents.length !== processableServiceTypes.length,
    );
  }

  public hasProcessableBusServices() {
    return (
      this.getAllServices().filter((service) => service.isBusService()).length >
      0
    );
  }

  public hasProcessableCarServices() {
    return (
      this.getAllServices().filter((service) => service.isCarService()).length >
      0
    );
  }

  public hasProcessableFlightServices() {
    return (
      this.getAllServices().filter((service) => service.isFlightService())
        .length > 0
    );
  }

  public hasProcessableHotelServices() {
    return (
      this.getAllServices().filter((service) => service.isHotelService())
        .length > 0
    );
  }

  public hasServices() {
    return this.getAllServices().length > 0;
  }
}

export class TravelPaymentModelFactory {
  static create(travelPayment: TravelPayment) {
    const itineraryServicesModel = ItineraryServicesModelFactory.create(
      travelPayment.services,
    );

    return new TravelPaymentModel({
      ...travelPayment,
      services: itineraryServicesModel,
    });
  }
}

export type TravelPoliciesPerOffer = {
  [offerToken: string]: TravelPolicyForOffer;
};

export type TravelPolicyForBusOffer = {
  outOfPolicy: boolean;
} & Partial<{
  isNational: boolean;
}>;

export type TravelPolicyForCarOffer = {
  outOfPolicy: boolean;
} & Partial<{
  isNational: boolean;
  maxCategory: CarCategory;
}>;

export type TravelPolicyForFlightOffer = {
  outOfPolicy: boolean;
} & Partial<{
  advance: number;
  class: string;
  isNational: boolean;
  maxValue: number;
  optimalPrice: {
    enabled: boolean;
    price: number;
  };
  priceExcess: number | null;
}>;

export type TravelPolicyForHotelOffer = {
  outOfPolicy: boolean;
} & Partial<{
  advance: number;
  isNational: boolean;
  maxValue: number;
  stars: number;
}>;

export type TravelPolicyForOffer =
  | TravelPolicyForBusOffer
  | TravelPolicyForCarOffer
  | TravelPolicyForFlightOffer
  | TravelPolicyForHotelOffer;

export type TravelService<
  T =
    | BusService
    | CarService
    | FlightService
    | HotelService
    | {
        type: "suggestion";
      }
> = T & {
  availability?: unknown;
  canceledByUserName: string | null;
  changedByUserName: string | null;
  isCancelable: boolean;
  isDeletable: boolean;
  status: OfferStatus;
};

export type TravelServices = {
  busServices: TravelService<BusService>[];
  carServices: TravelService<CarService>[];
  flightServices: TravelService<FlightService>[];
  hotelServices: TravelService<HotelService>[];
  insuranceServices?: any[];
};

export type TravelWithChanges = Travel & {
  changes: TravelChanges;
};
