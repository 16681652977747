import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryPresentationalServices } from "~/apps/corporate/components/itinerary-presentational-services/itinerary-presentational-services";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { OfferStatus } from "~/apps/shared/constants/enums";
import { OFFER_STATUS_COLOR } from "~/apps/shared/constants/offers.constants";

import { useApprovalReview } from "../../approval-review.context";
import {
  useApprovalReviewServicesCardGuard,
  useApprovalReviewServicesCardPresenter,
} from "./approval-review-services-card.hooks";
import { styles } from "./styles";

const APPROVAL_REVIEW_SERVICES_CARD_TABS_TRANSLATION = {
  "a-comprar": "Itens solicitados",
  compras: "Itens emitidos",
} as const;

export type ApprovalReviewServicesCardTabs = keyof typeof APPROVAL_REVIEW_SERVICES_CARD_TABS_TRANSLATION;

export const ApprovalReviewServicesCard: React.FC = () => {
  const { loading } = useApprovalReviewServicesCardGuard();
  const {
    approvalReviewServicesCardPresenter,
  } = useApprovalReviewServicesCardPresenter();

  const {
    isApproved,
    isDenied,
    selectedServices,
    setSelectedServices,
  } = useApprovalReview();

  const [
    selectedTab,
    setSelectedTab,
  ] = useState<ApprovalReviewServicesCardTabs>("a-comprar");

  const handleSelect = useCallback(
    (servicePresenter: ItineraryServicePresenter) => {
      const serviceModel = servicePresenter.getServiceModel();

      if (!selectedServices) {
        setSelectedServices([serviceModel]);

        return;
      }

      const isServiceAlreadySelected = selectedServices.find(
        (selectedService) =>
          selectedService.getOfferToken() === serviceModel.getOfferToken(),
      );

      if (isServiceAlreadySelected) {
        setSelectedServices(
          selectedServices.filter(
            (selectedService) =>
              selectedService.getOfferToken() !== serviceModel.getOfferToken(),
          ),
        );

        return;
      }

      setSelectedServices([...selectedServices, serviceModel]);
    },
    [selectedServices, setSelectedServices],
  );

  if (loading) {
    return <ApprovalReviewServicesCardSkeleton />;
  }

  if (!approvalReviewServicesCardPresenter) {
    return null;
  }

  const presentationalServices = approvalReviewServicesCardPresenter.getPresentationalServices();
  const presentationalServicesCounts = approvalReviewServicesCardPresenter.getPresentationalServicesCounts();

  const selectedServiceTokens = selectedServices
    ? selectedServices.map((service) => service.getOfferToken())
    : [];

  const unavailableServiceTokens = approvalReviewServicesCardPresenter.getUnavailableServiceTokens();

  return (
    <div css={styles.root}>
      {approvalReviewServicesCardPresenter.hasEmittedServices() ? (
        <>
          <div css={styles.tabs.root}>
            {Object.entries(APPROVAL_REVIEW_SERVICES_CARD_TABS_TRANSLATION).map(
              ([tab, tabTranslation]) =>
                presentationalServicesCounts[
                  tab as ApprovalReviewServicesCardTabs
                ] > 0 && (
                  <button
                    css={styles.tabs.tab({ active: selectedTab === tab })}
                    key={tab}
                    onClick={() => {
                      setSelectedTab(tab as ApprovalReviewServicesCardTabs);
                    }}
                  >
                    {tabTranslation}
                    {(tab as ApprovalReviewServicesCardTabs) !== "a-comprar"
                      ? ` (${
                          presentationalServicesCounts[
                            tab as ApprovalReviewServicesCardTabs
                          ]
                        })`
                      : null}
                  </button>
                ),
            )}
          </div>
          <hr css={styles.divisor} />
        </>
      ) : null}
      <div css={styles.services.root}>
        {presentationalServicesCounts[selectedTab] > 0 ? (
          Object.entries(presentationalServices[selectedTab])
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, groups]) => groups.length > 0)
            .map(([status, groups], index) => {
              if (selectedTab === "a-comprar") {
                const hideSelect = isApproved || isDenied;

                return (
                  <ItineraryPresentationalServices
                    color={OFFER_STATUS_COLOR[status as OfferStatus]}
                    groups={groups}
                    isApprovalReview
                    key={index}
                    onSelect={hideSelect ? undefined : handleSelect}
                    selectedServiceTokens={selectedServiceTokens}
                    unavailableServiceTokens={unavailableServiceTokens}
                  />
                );
              }

              return (
                <ItineraryPresentationalServices
                  color={OFFER_STATUS_COLOR[status as OfferStatus]}
                  groups={groups}
                  key={index}
                />
              );
            })
        ) : (
          <p css={styles.services.empty}>Nenhum serviço encontrado.</p>
        )}
      </div>
    </div>
  );
};

const ApprovalReviewServicesCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="14px" width="192px" />
          <Skeleton height="128px" width="100%" />
          <Skeleton height="128px" width="100%" />
        </div>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="128px" width="100%" />
        </div>
      </div>
    </div>
  );
};
