import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { Either, failure, success } from "~/apps/shared/utils/either";

import { api } from "../../shared/services/api";
import {
  AddOfferJustificationRequestBody,
  CheckForRepeatedOfferResponse,
  CheckOfferAvailabilityResponse,
  GetOfferTravelersResponse,
} from "../dtos/offers.dto";
import { OfferAvailability } from "../models/offer.model";
import * as offersParser from "../parsers/offers.parser";

export const addOfferJustification = async (
  offerToken: string,
  data: AddOfferJustificationRequestBody,
) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offer/justification/${offerToken}/add`,
    })
    .then(({ data }) => data);
};

export const addTravelerToOffer = async (offerToken: string, data: any) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offers/${offerToken}/travelers`,
    })
    .then(({ data }) => ({
      createdAt: data.created_at,
      main: data.main,
      offerToken: data.offer_token,
      travelerOfferToken: data.traveler_offer_token,
      travelToken: data.travel_token,
      userToken: data.user_token,
    }));
};

export const cancelOffer = async (offerToken: string) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offers/${offerToken}/cancel`,
    })
    .then(() => ({
      message: "Solicitação de cancelamento enviado com sucesso.",
      success: true,
    }));
};

export const checkForRepeatedOffer = async (offerToken: string) => {
  return await api
    .request<CheckForRepeatedOfferResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/emission-repetition`,
    })
    .then(({ data }) => offersParser.parseRepeatedOffer(offerToken, data));
};

export const checkOfferAvailability = async (
  offerToken: string,
): Promise<Either<any, OfferAvailability>> => {
  return await api
    .request<CheckOfferAvailabilityResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      timeout: 60 * 1000,
      url: `/booking/offers/${offerToken}/refresh`,
    })
    .then(({ data }) => success(offersParser.parseCheckOfferAvailability(data)))
    .catch((error) => failure(error));
};

export const deleteOffer = async (offerToken: string) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offers/${offerToken}/inactivate`,
    })
    .then(() => ({
      message: "Serviço removido do itinerário com sucesso.",
      success: true,
    }));
};

export const deleteOffers = async (offerTokens: string[]) => {
  const promises = offerTokens.map((token) => deleteOffer(token));

  return await Promise.all(promises).then(() => {
    return {
      message: "Serviços removido do itinerário com sucesso.",
      success: true,
    };
  });
};

export const getOffersFromTravel = async (travelToken: string) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offer/travels/${travelToken}`,
    })
    .then((response) => {
      const { data, status } = response;

      if (status === 200 || status === 304) {
        return data.map((item: any) => offersParser.OfferFactory(item));
      }
    });
};

export const getOfferTravelers = async (offerToken: string) => {
  return await api
    .request<GetOfferTravelersResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/travelers`,
    })
    .then(({ data }) =>
      data.map((item) => offersParser.parseOfferTraveler(item)),
    );
};

export const removeTravelerFromOffer = async (
  offerToken: string,
  travelerOfferToken: string,
) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/offers/${offerToken}/travelers/${travelerOfferToken}`,
    })
    .then(({ data }) => data);
};

export const updateOffer = async (offerToken: string, data: any) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/offer/${offerToken}`,
    })
    .then(({ data }) => data);
};
