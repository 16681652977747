import React from "react";

import { css } from "@styled-system/css";
import { DatePicker } from "~/apps/shared/components/date-picker/date-picker";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Moment } from "moment";
import uuid from "uuid";

interface Date {
  endDate: Moment | null;
  startDate: Moment | null;
}

interface Props {
  endDate: Moment | null;
  focusedInput: "startDate" | "endDate" | null;
  handleChange: (date: Date) => void;
  handleFocusChange: (focused: "startDate" | "endDate" | null) => void;
  startDate: Moment | null;
}

const styles = {
  root: css({
    mb: "1rem",
    width: "fit-content",
  }),
};

export const RealTimeDatepicker: React.FC<Props> = ({
  startDate,
  endDate,
  focusedInput,
  handleChange,
  handleFocusChange,
}) => {
  return (
    <div css={styles.root}>
      <DatePicker
        customArrowIcon={<Icon size={16} use="calendar" />}
        endDate={endDate}
        endDateId={uuid()}
        endDatePlaceholderText="até"
        focusedInput={focusedInput}
        onDatesChange={handleChange}
        onFocusChange={handleFocusChange}
        range
        startDate={startDate}
        startDateId={uuid()}
        startDatePlaceholderText="De"
      />
    </div>
  );
};
