import { useEffect, useRef } from "react";

export const useInterval = (
  callback: () => void,
  delay: false | null | number,
) => {
  const savedCallback = useRef(() => {
    return;
  });

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === false || delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);

  return null;
};
