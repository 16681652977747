import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: ({ hasError }: { hasError: boolean }) =>
    css({
      backgroundColor: hasError
        ? theme.colors.pink[500]
        : theme.colors.gray[100],
      border: 0,
      height: "1px",
      width: "100%",
    }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "100%",
  }),
  value: ({
    isDisabled,
    isEmpty,
  }: {
    isDisabled?: boolean;
    isEmpty?: boolean;
  }) =>
    css({
      color: isEmpty ? theme.colors.pink[500] : theme.colors.gray[700],
      display: "inline-block",
      fontSize: "1.5rem",
      fontWeight: 700,
      minWidth: "24px",
      outline: "none",
      padding: "0",
      textOverflow: "ellipsis",
      transition: "all 0.15s ease-in-out",
      width: "100%",
      ":focus": {
        borderBottomColor: theme.colors.gray[700],
      },
      "::placeholder": {
        color: theme.colors.gray[100],
      },
      ...(!isDisabled && {
        ":hover": {
          borderBottomColor: theme.colors.gray[700],
        },
      }),
    }),
};
