import React, { useEffect, useState } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import isEmpty from "lodash/isEmpty";

import { Box, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";

import { BillingProfileSelect } from "../../shared/BillingProfileSelect";
import { GenericSelect } from "../../shared/GenericSelect";
import { CostCenterSelect } from "../CostCenterSelect/CostCenterSelect";
import {
  useReportApprovalFormActionsContext,
  useReportApprovalFormContext,
} from "../report.context";

export const ReportApprovalForm = () => {
  const { clientConfig } = useClientConfig();

  const {
    areas,
    billingProfiles,
    projects,
    selectedArea,
    selectedBillingProfile,
    selectedProject,
  } = useReportApprovalFormContext();
  const {
    handleChangeData,
    isBillingProfileNeeded,
    isCompanyAreaNeeded,
    isCostCenterNeeded,
    isProjectNeeded,
    shouldBillingProfileSelectBeVisible,
    shouldCostCenterSelectBeVisible,
    shouldProjectSelectBeVisible,
  } = useReportApprovalFormActionsContext();

  const [projectError, setProjectError] = useState("");

  const areaDisplayName = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : "Área";

  const isProjectRequired = isProjectNeeded();

  const isSomeCategoryVisible =
    isCompanyAreaNeeded() ||
    shouldCostCenterSelectBeVisible ||
    shouldBillingProfileSelectBeVisible() ||
    shouldProjectSelectBeVisible();

  const isSomeCategoryNeeded =
    isCompanyAreaNeeded() ||
    isCostCenterNeeded() ||
    isBillingProfileNeeded() ||
    isProjectRequired;

  if (!isSomeCategoryVisible) {
    return null;
  }

  const handleProjectSelectBlur = () => {
    let error = "";

    if (isEmpty(projects) && isProjectRequired) {
      error = "Nenhum projeto cadastrado para o perfil de cobrança selecionado";
    }

    setProjectError(error);
  };

  useEffect(() => {
    setProjectError("");
  }, [selectedBillingProfile]);

  return (
    <Box
      borderBottom="1px solid"
      borderBottomColor="border"
      borderRadius={0}
      px={20}
      py={24}
    >
      <Box mb={["medium", 24]}>
        <Text fontSize={[1, 2]} fontWeight="bold">
          Categorização do relatório
        </Text>
      </Box>
      {shouldCostCenterSelectBeVisible() && (
        <PermissionVisibility permissions={["companySettings.costCenters"]}>
          <Box mb={["medium"]}>
            <Box fontSize={[0, 1]} fontWeight="bold" mb="small">
              <Text>
                Centro de custos{" "}
                <Text color={defaultTheme.subTextColor} fontSize={0}>
                  *
                </Text>
              </Text>
            </Box>
            <CostCenterSelect />
          </Box>
        </PermissionVisibility>
      )}
      {shouldBillingProfileSelectBeVisible() && (
        <PermissionVisibility permissions={["companySettings.billingProfiles"]}>
          <Box mb={["medium"]}>
            <Box mb={["small"]}>
              <Text fontSize={[0, 1]} fontWeight="bold">
                Perfil de cobrança{" "}
                <Text color={defaultTheme.subTextColor} fontSize={0}>
                  *
                </Text>
              </Text>
            </Box>
            <Box mb="small">
              <BillingProfileSelect
                id="selectedBillingProfile"
                name="selectedBillingProfile"
                handleChange={handleChangeData}
                options={billingProfiles}
                value={selectedBillingProfile}
              />
            </Box>
          </Box>
        </PermissionVisibility>
      )}
      {isCompanyAreaNeeded() && (
        <PermissionVisibility permissions={["companySettings.areas"]}>
          <Box mb={["medium"]}>
            <Box mb={["small"]}>
              <Text fontSize={[0, 1]} fontWeight="bold">
                {areaDisplayName}{" "}
                <Text color={defaultTheme.subTextColor} fontSize={0}>
                  *
                </Text>
              </Text>
            </Box>
            <Box mb="small">
              <GenericSelect
                id="selectedArea"
                name="selectedArea"
                labelKey="name"
                valueKey="companyAreaToken"
                options={areas}
                value={selectedArea}
                onChange={handleChangeData}
                error={isEmpty(areas)}
                errorMessage={
                  isEmpty(areas) && `Nenhum(a) ${areaDisplayName} cadastrado(a)`
                }
              />
            </Box>
          </Box>
        </PermissionVisibility>
      )}
      {shouldProjectSelectBeVisible() && (
        <PermissionVisibility permissions={["companySettings.projects"]}>
          <Box mb={["medium"]}>
            <Box mb={["small"]}>
              <Text fontSize={[0, 1]} fontWeight="bold">
                Projeto{" "}
                {isProjectRequired && (
                  <Text color={defaultTheme.subTextColor} fontSize={0}>
                    *
                  </Text>
                )}
              </Text>
            </Box>
            <Box mb="small">
              <GenericSelect
                id="selectedProject"
                name="selectedProject"
                labelKey="name"
                valueKey="projectToken"
                options={projects}
                value={selectedProject}
                onChange={handleChangeData}
                onBlur={handleProjectSelectBlur}
                error={!!projectError}
                errorMessage={projectError}
              />
            </Box>
          </Box>
        </PermissionVisibility>
      )}
      {isSomeCategoryNeeded && (
        <Box>
          <Text fontSize={0} color={defaultTheme.subTextColor}>
            * Obrigatório para prosseguir com a solicitação
          </Text>
        </Box>
      )}
    </Box>
  );
};
