import React from "react";

import { SwipeableDrawer } from "@material-ui/core";

import { styles } from "./styles";

export const Sheet: React.FC<
  React.ComponentPropsWithoutRef<typeof SwipeableDrawer>
> = ({ children, ...props }) => {
  return (
    <SwipeableDrawer css={styles.root} {...props}>
      {children}
    </SwipeableDrawer>
  );
};
