import React, { useCallback, useState } from "react";

import { MemberGetMemberDrawer } from "~/apps/corporate/components/member-get-member-drawer/member-get-member-drawer";
import { NavBarDesktop } from "~/apps/corporate/components/nav-bar/desktop/desktop";
import { NavBarMobile } from "~/apps/corporate/components/nav-bar/mobile/mobile";
import {
  Desktop,
  TabletAndMobile,
} from "~/apps/corporate/components/responsive/responsive";
import { UserProfileDrawer } from "~/apps/corporate/components/user-profile-drawer/user-profile-drawer";
import classNames from "classnames";
import { css } from "emotion";

import AcceptTermsDialog from "@components/shared/accept-terms-dialog";

import { defaultTheme } from "../../assets/styles/theme";

const styles = {
  children: css({
    flex: 1,
  }),
  root: css({
    backgroundColor: defaultTheme.applicationBackground,
    display: "flex",
    flexDirection: "column",
    minHeight: "100dvh",
  }),
};

type Props = React.HTMLAttributes<HTMLDivElement>;

const Layout: React.FC<Props> = ({ children, className, ...props }) => {
  const [
    isMemberGetMemebrDrawerOpen,
    setIsMemberGetMemebrDrawerOpen,
  ] = useState(false);
  const [isUserProfileDrawerOpen, setIsUserProfileDrawerOpen] = useState(false);

  const handleCloseMemberGetMemebrDrawer = useCallback(() => {
    setIsMemberGetMemebrDrawerOpen(false);
  }, []);

  const handleOpenMemberGetMemebrDrawer = useCallback(() => {
    setIsMemberGetMemebrDrawerOpen(true);
  }, []);

  const handleCloseUserProfileDrawer = useCallback(() => {
    setIsUserProfileDrawerOpen(false);
  }, []);

  const handleOpenUserProfileDrawer = useCallback(() => {
    setIsUserProfileDrawerOpen(true);
  }, []);

  return (
    <>
      <div className={classNames(styles.root, className)} {...props}>
        <AcceptTermsDialog />
        <Desktop>
          <NavBarDesktop
            handleOpenMemberGetMemebrDrawer={handleOpenMemberGetMemebrDrawer}
            handleOpenUserProfileDrawer={handleOpenUserProfileDrawer}
          />
        </Desktop>
        <div className={styles.children}>{children}</div>
        <TabletAndMobile>
          <NavBarMobile
            handleOpenUserProfileDrawer={handleOpenUserProfileDrawer}
          />
        </TabletAndMobile>
      </div>
      <MemberGetMemberDrawer
        onClose={handleCloseMemberGetMemebrDrawer}
        open={isMemberGetMemebrDrawerOpen}
      />
      <UserProfileDrawer
        onClose={handleCloseUserProfileDrawer}
        open={isUserProfileDrawerOpen}
      />
    </>
  );
};

export default Layout;
