import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    flexShrink: 0,
    outline: "none",
  }),
  input: css({
    backgroundColor: "transparent",
    border: "none",
    fontFamily: theme.fonts.inter,
    outline: "none",
    padding: "13px 12px 13px 16px",
    width: "100%",
  }),
  root: css({
    alignItems: "center",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    color: theme.colors.gray[700],
    display: "flex",
    padding: "0px 16px 0px 0px",
    transition: "border-color 0.15s ease-in-out",
    width: "100%",
    "& > :first-of-type:focus": {
      borderColor: theme.colors.gray[300],
    },
  }),
};
