import { ERROR } from "@constants";

import { AnalyticsParamsDto } from "~/dtos/analytics.dto";

import { CustomError } from "~/types";

import * as analyticsApi from "@apis/analytics.api";

import * as analyticsService from "../Analytics.service";
import { IFilterParams } from "../shared/types";
import { IHotelInfo } from "./HotelInfo.types";

export async function getHotelInfo(config: IFilterParams) {
  const result: { data?: IHotelInfo; error?: CustomError } = {};

  try {
    const requestDto = parseAnalyticsParams(config);
    result.data = await analyticsApi.getHotelsAnalyticsInfo(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getFilterOptionsResult() {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const costCenterOptions = await analyticsService.loadCostCentersOptions();
    const tagOptions = await analyticsService.loadTagOptions();
    const travelerOptions = await analyticsService.loadTravelerOptions();
    const companyAreaOptions = await analyticsService.loadCompanyAreaOptions();
    const projectOptions = await analyticsService.loadProjectOptions();
    const billingProfileOptions = await analyticsService.loadBillingProfileOptions();
    result.data = {
      costCenterOptions,
      tagOptions,
      travelerOptions,
      companyAreaOptions,
      projectOptions,
      billingProfileOptions
    };
  } catch (error) {
    if (error.response) {
      result.error = ERROR.FILTERS_LOAD_ERROR;
    } else if (error.request) {
      result.error = ERROR.FILTERS_LOAD_ERROR;
    } else {
      result.error = ERROR.FILTERS_LOAD_ERROR;
    }
  }

  return result;
}

function parseAnalyticsParams(config: IFilterParams) {
  const request: AnalyticsParamsDto = {
    start_month: config.startMonth,
    start_year: config.startYear,
    end_month: config.endMonth,
    end_year: config.endYear,
    cost_center_token: config.costCenterToken,
    tag_token: config.tagToken,
    traveler_token: config.travelerToken,
    company_area_token: config.companyAreaToken,
    project_token: config.projectToken,
    billing_profile_token: config.billingProfileToken
  };

  return request;
}
