import { css } from "@styled-system/css";

export const styles = {
  left: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "60%",
      "@media (max-width: 992px)": {
        width: "100%",
      },
    }),
  },
  right: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      maxWidth: "512px",
      width: "100%",
      "@media (max-width: 1440px)": {
        maxWidth: "40%",
      },
      "@media (max-width: 992px)": {
        maxWidth: "unset",
      },
    }),
  },
  root: css({
    display: "flex",
    gap: "16px",
    margin: "0 auto",
    maxWidth: "1440px",
    padding: "16px 80px 80px 80px",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "16px 24px 24px 24px",
    },
    "@media (max-width: 992px)": {
      flexDirection: "column",
      padding: "16px",
    },
  }),
};
