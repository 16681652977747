import React from "react";
import Skeleton from "react-loading-skeleton";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { StopFilter } from "../../../flights.types";
import { styles } from "./styles";

const STOP_TRANSLATOR = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
};

type Props = {
  handleStopToggle: (toggledStop: StopFilter) => void;
  loading?: boolean;
  stops: StopFilter[];
  stopsPriceMap: Record<string, number> | undefined;
};

export const StopsFilter: React.FC<Props> = ({
  handleStopToggle,
  loading,
  stops,
  stopsPriceMap,
}) => {
  if (stops.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Conexões</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {stops.map((stop) => (
          <CheckboxGroup.Item
            checked={stop.checked}
            css={styles.checkbox.group.item.root}
            id={stop.value}
            key={stop.value}
            name={stop.value}
            onChange={() => {
              handleStopToggle(stop);
            }}
            value={stop.checked}
          >
            <div css={styles.checkbox.group.item.label.root}>
              <span css={styles.checkbox.group.item.label.label}>
                {stop.label}
              </span>
              {!loading ? (
                stopsPriceMap ? (
                  <StopsPrices stop={stop} stopsPriceMap={stopsPriceMap} />
                ) : null
              ) : (
                <StopsPricesSkeleton />
              )}
            </div>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};

type StopsPricesProps = {
  stop: StopFilter;
  stopsPriceMap: Record<string, number>;
};

const StopsPrices: React.FC<StopsPricesProps> = ({ stop, stopsPriceMap }) => {
  return (
    <span css={styles.checkbox.group.item.label.price}>
      {stop.value in STOP_TRANSLATOR &&
      STOP_TRANSLATOR[stop.value as keyof typeof STOP_TRANSLATOR] in
        stopsPriceMap
        ? toCurrency(
            stopsPriceMap[
              STOP_TRANSLATOR[stop.value as keyof typeof STOP_TRANSLATOR]
            ],
          )
        : null}
    </span>
  );
};

const StopsPricesSkeleton: React.FC = () => {
  return <Skeleton height="18px" width="64px" />;
};
