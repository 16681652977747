import { theme } from "@skin/v2";

export const SELECT_STYLES = {
  container: (baseStyles) => ({
    ...baseStyles,
    flex: 1,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 20,
    borderRadius: "0.5rem",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: "3rem",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "0 0.5rem",
    border: `1px solid ${theme.colors.gray[100]}`,
    boxShadow: "none",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    "&:hover": {
      borderColor: theme.colors.gray[300],
    },
    "&:focus-within": {
      border: `1px solid ${theme.colors.gray[500]}`,
      boxShadow: `0 0 0 1px ${theme.colors.gray[500]}`,
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: `${theme.colors.gray[100]}`,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "0.25rem",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
  option: (baseStyles, { isSelected }) => ({
    ...baseStyles,
    cursor: "pointer",
    backgroundColor: isSelected ? theme.colors.gray[500] : theme.colors.white,
    "&:hover": {
      backgroundColor: theme.colors.gray[500],
      color: theme.colors.white,
    },
  }),
};
