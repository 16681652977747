import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { css } from "emotion";

import { AirportAutosuggest as AirportAutosuggestModel } from "@models/autosuggest.model";

import { Autocomplete } from "@shared/autocomplete/AutocompleteAsync";

import * as expenseDrawerService from "../../ExpenseDrawer.service";

const styles = {
  rootItem: css({
    padding: "1rem .5rem 1rem 1rem"
  })
};
interface Props {
  handleSelect: (airportItem: AirportAutosuggestModel) => void;
  handleRemoveSelection?: (airportItem: AirportAutosuggestModel) => void;
  inputProps?: any;
}

const AirportAutosuggest = ({
  handleSelect,
  handleRemoveSelection,
  inputProps
}: Props) => {
  return (
    <Autocomplete
      fetchItems={expenseDrawerService.getAiportAutosuggest}
      handleSelect={handleSelect}
      handleRemoveSelection={handleRemoveSelection}
      customItemElement={({ onClick, item }) => (
        <MenuItem
          component="div"
          classes={{
            root: styles.rootItem
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            whiteSpace: "normal"
          }}
          onClick={onClick}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              fontSize: "1rem",
              fontWeight: 600
            }}
          >
            {item.City}, {item.CountryCompleteName}{" "}
            <span
              style={{
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "#5a6872",
                marginBottom: "0.031rem"
              }}
            >
              {item.PlaceId.split("-")[0]}
            </span>
          </p>
          <p
            style={{
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#5a6872"
            }}
          >
            {item.AirportName}
          </p>
        </MenuItem>
      )}
      inputProps={inputProps}
      menuStyle={{
        width: "100%",
        marginTop: inputProps?.error ? "-2.5rem" : "-1.5rem"
      }}
    />
  );
};

export { AirportAutosuggest };
