import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  item: any;
  isHighlighted: boolean;
}

class ListItem extends React.Component<Props> {
  public render() {
    const { item, isHighlighted, ...rest } = this.props;

    return (
      <MenuItem
        key={item.label}
        style={{
          background: isHighlighted ? "lightgray" : "white"
        }}
        value={item}
        {...rest}
      >
        {item.label}
      </MenuItem>
    );
  }
}

export { ListItem };
