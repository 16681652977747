import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  checkbox: {
    group: {
      root: css({
        width: "fit-content",
      }),
    },
  },
  root: css({
    color: theme.colors.gray[300],
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  tabs: {
    root: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      overflow: "hidden",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        margin: "0 16px",
      },
    }),
    tab: {
      button: css({
        border: "none",
        borderRadius: "0px",
        justifyContent: "center",
        width: "100%",
      }),
      divider: css({
        backgroundColor: theme.colors.gray[100],
        height: "100%",
        width: "1px",
      }),
      root: css({
        display: "flex",
        whiteSpace: "nowrap",
        width: "100%",
      }),
    },
  },
  text: css({
    fontSize: "0.875rem",
    lineHeight: "19.36px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
