import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate } from "@reach/router";
import { UserProfileDrawer } from "~/apps/corporate/components/user-profile-drawer/user-profile-drawer";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ItineraryServicesModelFactory } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { PaymentOption } from "~/apps/shared/constants/enums";
import { logger } from "~/apps/shared/utils/logger";
import * as yup from "yup";

import { Button } from "@toolkit/v2";

import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useItinerary } from "../../../itinerary/itinerary.context";
import { usePaymentContainer } from "../../payment.container";
import { usePayment } from "../../payment.context";
import { PaymentDecisionActions } from "./payment-decision-actions/payment-decision-actions";
import { PaymentDecisionCredits } from "./payment-decision-credits/payment-decision-credits";
import { PaymentDecisionFields } from "./payment-decision-fields/payment-decision-fields";
import {
  usePaymentPayableCards,
  usePaymentPaymentOptions,
} from "./payment-payment.hooks";
import { styles } from "./styles";

export const PaymentDecision: React.FC = () => {
  const {
    infoModel,
    offersAvailabilitiesModel,
    offersChangesModel,
    travelApprovalHistoryModel,
  } = useItineraryContainer();
  const { travelPaymentModel } = usePaymentContainer();

  const { user } = useUser();

  const { travelToken } = useItinerary();
  const {
    changeTravelFlightCreditOfferToken,
    confirmPayment,
    isLoading,
    selectedTravelFlightCreditOfferToken,
    shouldBookingFeeBeCharged,
    travelFlightCredits,
    travelPayment,
  } = usePayment();

  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);

  const {
    errorPaymentOptions,
    isLoadingPaymentOptions,
    paymentOptions,
  } = usePaymentPaymentOptions(infoModel);

  const {
    errorPayableCards,
    isLoadingPayableCards,
    payableCards,
  } = usePaymentPayableCards(infoModel);

  const formSchema = useMemo(() => {
    if (!paymentOptions) {
      return yup.object().shape({
        busCardToken: yup.string().nullable(true),
        carCardToken: yup.string().nullable(true),
        feeCardToken: yup.string().nullable(true),
        flightCardToken: yup.string().nullable(true),
        hotelCardToken: yup.string().nullable(true),
      });
    }

    return yup.object().shape({
      busCardToken:
        paymentOptions.bus === PaymentOption.CREDIT_CARD
          ? yup.string().required("Selecione um cartão.").nullable(true)
          : yup.string().nullable(true),
      carCardToken:
        paymentOptions.car === PaymentOption.CREDIT_CARD
          ? yup.string().required("Selecione um cartão.").nullable(true)
          : yup.string().nullable(true),
      feeCardToken: shouldBookingFeeBeCharged
        ? yup.string().required("Selecione um cartão.").nullable(true)
        : yup.string().nullable(true),
      flightCardToken:
        paymentOptions.flight === PaymentOption.CREDIT_CARD
          ? yup.string().required("Selecione um cartão.").nullable(true)
          : yup.string().nullable(true),
      hotelCardToken:
        paymentOptions.hotel === PaymentOption.CREDIT_CARD
          ? yup.string().required("Selecione um cartão.").nullable(true)
          : yup.string().nullable(true),
    });
  }, [paymentOptions, shouldBookingFeeBeCharged]);

  type FormSchema = yup.InferType<typeof formSchema>;

  const form = useForm<FormSchema>({
    defaultValues: {
      busCardToken: null,
      carCardToken: null,
      feeCardToken: null,
      flightCardToken: null,
      hotelCardToken: null,
    } as FormSchema,
    onSubmit: async () => {
      if (!paymentOptions) {
        return;
      }

      const values = form.getValues();

      await confirmPayment({
        cardTokens: {
          busCardToken: values.busCardToken,
          carCardToken: values.carCardToken,
          feeCardToken: values.feeCardToken,
          flightCardToken: values.flightCardToken,
          hotelCardToken: values.hotelCardToken,
        },
        payableCards,
        paymentOptions,
        selectedCredit:
          selectedTravelFlightCreditOfferToken && travelFlightCredits
            ? travelFlightCredits[selectedTravelFlightCreditOfferToken]
            : null,
      });
    },
    schema: formSchema,
  });

  const handleCloseUserProfile = useCallback(() => {
    setIsUserProfileOpen(false);
  }, []);

  const handleOpenUserProfile = useCallback(() => {
    setIsUserProfileOpen(true);
  }, []);

  useEffect(() => {
    if (errorPaymentOptions) {
      logger.error("loading payment options failed.");
      navigate(`/travels/${travelToken}/itinerary/travel-plan`);

      return;
    }

    if (errorPayableCards) {
      logger.error("loading payable cards failed.");
      navigate(`/travels/${travelToken}/itinerary/travel-plan`);

      return;
    }
  }, [errorPayableCards, errorPaymentOptions, travelToken]);

  useEffect(() => {
    if (!payableCards || !travelPaymentModel) {
      return;
    }

    const travelPaymentServices = travelPaymentModel.getAllServices();

    logger.info("reseted payment form with new default values");
    form.reset(
      travelPaymentModel.getCardTokensDefaultValue(
        payableCards,
        travelPaymentServices,
      ),
    );
  }, [payableCards, travelPaymentModel]);

  if (isLoading || isLoadingPayableCards || isLoadingPaymentOptions) {
    return <PaymentDecisionSkeleton />;
  }

  if (
    !infoModel ||
    !payableCards ||
    !paymentOptions ||
    !travelApprovalHistoryModel ||
    !travelPayment ||
    !travelPaymentModel ||
    !user
  ) {
    return null;
  }

  const travelPaymentServicesModel = ItineraryServicesModelFactory.create(
    travelPayment.services,
  );

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>Insira os dados de pagamento.</span>
          <span css={styles.header.description}>
            Preencha os campos abaixo para finalizar a compra.
          </span>
        </div>
        <Form css={styles.form.root} context={form}>
          <hr css={styles.divisor} />
          <PaymentDecisionFields
            payableCards={payableCards}
            paymentOptions={paymentOptions}
            shouldBookingFeeBeCharged={shouldBookingFeeBeCharged}
            travelPaymentModel={travelPaymentModel}
          />
          <PaymentDecisionActions
            handleOpenUserProfile={handleOpenUserProfile}
            infoModel={infoModel}
            offersAvailabilitiesModel={offersAvailabilitiesModel}
            offersChangesModel={offersChangesModel}
            paymentOptions={paymentOptions}
            travelApprovalHistoryModel={travelApprovalHistoryModel}
            travelPaymentModel={travelPaymentModel}
          />
          {travelFlightCredits &&
          Object.keys(travelFlightCredits).length > 0 ? (
            <>
              <hr css={styles.divisor} />
              <PaymentDecisionCredits
                onChange={changeTravelFlightCreditOfferToken}
                selectedTravelFlightCreditOfferToken={
                  selectedTravelFlightCreditOfferToken
                }
                travelFlightCredits={travelFlightCredits}
                travelPaymentServicesModel={travelPaymentServicesModel}
              />
            </>
          ) : null}
        </Form>
      </div>
      <UserProfileDrawer
        defaultView="save-payment-method"
        onClose={handleCloseUserProfile}
        open={isUserProfileOpen}
      />
    </>
  );
};

const PaymentDecisionSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <Skeleton height="16px" width="236px" />
        <Skeleton height="14px" width="346px" />
      </div>
      <hr css={styles.divisor} />
      <Skeleton height="16px" width="236px" />
      <Skeleton height="48px" width="100%" />
      <hr css={styles.divisor} />
      <Skeleton height="16px" width="236px" />
      <Skeleton height="48px" width="100%" />
      <Button css={styles.button} disabled variant="pink">
        Confirmar pagamento
      </Button>
    </div>
  );
};
