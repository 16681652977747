import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    color: theme.colors.gray[300],
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    padding: "0 24px 24px 24px",
    width: "fit-content",
  }),
  tag: {
    name: css({}),
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      fontSize: "0.75rem",
      fontWeight: 600,
      gap: "6px",
      padding: "6px 12px",
    }),
  },
};
