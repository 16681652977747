import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";

import { api } from "../../shared/services/api";
import {
  GetUserProjectsResponse,
  CreateProjectRequestBody,
  ProjectCreateResponseBody,
  ProjectEditionRequestBody,
  ProjectEditionResponseBody,
  ProjectItemResponse,
} from "../dtos/projects.dto";
import * as projectsParser from "../parsers/projects.parser";

export async function changeClientProjectEnablingState(
  projectState: string,
  clientConfigToken: string,
) {
  return await api
    .request({
      data: {
        project_enabling_state: projectState,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
    })
    .then(({ data }) => data);
}

export async function createProject(data: CreateProjectRequestBody) {
  return await api
    .request<ProjectCreateResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/project",
    })
    .then(({ data }) => data.project_token);
}

export async function editProject(
  data: ProjectEditionRequestBody,
  projectToken: string,
) {
  return await api
    .request<ProjectEditionResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/project/${projectToken}`,
    })
    .then(({ data }) => data);
}

export async function getSingleProject(projectToken: string) {
  return await api
    .request<ProjectItemResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/project/${projectToken}`,
    })
    .then(({ data }) => projectsParser.parseProjectItem(data));
}

export async function getUserProjects(userToken: string) {
  return await api
    .request<GetUserProjectsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/projects`,
    })
    .then(({ data }) => projectsParser.parseUserProjects(data));
}

export async function listClientProjects() {
  return await api
    .request<ProjectItemResponse[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/client/${getClientToken()}/project`,
    })
    .then(({ data }) =>
      data.map((project) => projectsParser.parseProjectItem(project)),
    );
}

export async function toggleProjectActiveStatus(projectToken: string) {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/project/toggle/${projectToken}`,
    })
    .then(({ data }) => data);
}
