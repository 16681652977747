import React, { useEffect } from "react";

import { Router, navigate } from "@reach/router";
import { CapabilityProtectedRoute } from "~/apps/shared/components/capability-protected-route/capability-protected-route";
import { capabilities } from "~/apps/shared/constants/enums";

import { EditEvent } from "./edit-event";
import { Event } from "./event";
import { EventsProvider } from "./events.context";
import { ListEvents } from "./list-events/index";
import { NewEvent } from "./new-event";

export const Events: React.VFC = () => {
  return (
    <EventsProvider>
      <Router>
        <CapabilityProtectedRoute
          capabilities={[capabilities.BASIC]}
          component={ListEvents}
          path="/"
        />
        <CapabilityProtectedRoute
          capabilities={[capabilities.EVENT_ORGANIZER]}
          component={NewEvent}
          path="/new"
        />
        <CapabilityProtectedRoute
          capabilities={[capabilities.BASIC]}
          component={EventRedirect}
          path="/:token"
        />
        <CapabilityProtectedRoute
          capabilities={[capabilities.BASIC]}
          component={EditEvent}
          path="/:token/edit"
        />
        <CapabilityProtectedRoute
          capabilities={[capabilities.BASIC]}
          component={Event}
          path="/:token/:tab"
        />
      </Router>
    </EventsProvider>
  );
};

const EventRedirect: React.VFC<{
  token: string;
}> = ({ token }) => {
  useEffect(() => {
    navigate(`/events/${token}/overview`);
  }, []);

  return null;
};
