import React, { useContext } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { TOGGLE_COST_CENTER_TEXTS } from "@constants";

import { CostCentersContext } from "../CostCenters.context";
import { ToggleStatusDialog } from "./ToggleStatusDialog.presentational";

const ToggleStatusDialogContainer = () => {
  const { clientConfig } = useClientConfig();

  const {
    isToggleDialogOpen,
    proccessToggleCostCentersStatus,
    handleCloseToggleDialog,
  } = useContext(CostCentersContext);

  const isCostCentersEnabled = clientConfig
    ? clientConfig.isCompanyCostCenterEnabled()
    : false;

  const texts = isCostCentersEnabled
    ? TOGGLE_COST_CENTER_TEXTS.inactivate
    : TOGGLE_COST_CENTER_TEXTS.activate;

  return (
    <ToggleStatusDialog
      isOpen={isToggleDialogOpen}
      title={texts.title}
      message={texts.message}
      confirmationButtonLabel={texts.buttonLabel}
      handleConfirmation={proccessToggleCostCentersStatus}
      handleClose={handleCloseToggleDialog}
    />
  );
};

export { ToggleStatusDialogContainer as ToggleStatusDialog };
