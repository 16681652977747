import React, { createContext, useMemo } from "react";

import {
  TravelApprovalStatusModel,
  TravelApprovalStatusModelFactory,
} from "~/apps/corporate/models/approval-status.model";
import {
  TravelApprovalHistoryModel,
  TravelApprovalHistoryModelFactory,
} from "~/apps/corporate/models/approvals.model";
import {
  ItineraryInfoModel,
  ItineraryInfoModelFactory,
} from "~/apps/corporate/models/itinerary/itinerary-info.model";
import {
  ItineraryPendenciesModel,
  ItineraryPendenciesModelFactory,
} from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import {
  ItineraryServicesModel,
  ItineraryServicesModelFactory,
} from "~/apps/corporate/models/itinerary/itinerary-services.model";
import {
  ItineraryServicesPresenter,
  ItineraryServicesPresenterFactory,
} from "~/apps/corporate/models/itinerary/itinerary-services.presenter";
import {
  OffersAvailabilitiesModel,
  OffersAvailabilitiesModelFactory,
  OffersChangesModel,
  OffersChangesModelFactory,
  RepeatedOffersModel,
  RepeatedOffersModelFactory,
} from "~/apps/corporate/models/offer.model";
import {
  TravelApprovalModel,
  TravelApprovalModelFactory,
} from "~/apps/corporate/models/travel.model";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { useItineraryApproval } from "./itinerary-approval.context";
import { useItineraryInfo } from "./itinerary-info.context";
import { useItineraryPendencies } from "./itinerary-pendencies.context";
import { useItineraryScreen } from "./itinerary-screen.context";
import { useItineraryServices } from "./itinerary-services.context";

const ItineraryContainerContext = createContext<{
  infoModel: ItineraryInfoModel | null;
  offersAvailabilitiesModel: OffersAvailabilitiesModel | null;
  offersChangesModel: OffersChangesModel | null;
  pendenciesModel: ItineraryPendenciesModel | null;
  servicesModel: ItineraryServicesModel | null;
  servicesPresenter: ItineraryServicesPresenter | null;
  travelApprovalModel: TravelApprovalModel | null;
  travelApprovalHistoryModel: TravelApprovalHistoryModel | null;
  travelApprovalStatusModel: TravelApprovalStatusModel | null;
  travelRepeatedOffersModel: RepeatedOffersModel | null;
}>({
  infoModel: null,
  offersAvailabilitiesModel: null,
  offersChangesModel: null,
  pendenciesModel: null,
  servicesModel: null,
  servicesPresenter: null,
  travelApprovalModel: null,
  travelApprovalHistoryModel: null,
  travelApprovalStatusModel: null,
  travelRepeatedOffersModel: null,
});

export const ItineraryContainerProvider: React.FC = ({ children }) => {
  const {
    travelApproval,
    travelApprovalHistory,
    travelApprovalStatus,
  } = useItineraryApproval();
  const { info } = useItineraryInfo();
  const { pendencies } = useItineraryPendencies();
  const { travelRepeatedOffers } = useItineraryScreen();
  const {
    offersAvailabilities,
    offersChanges,
    services,
  } = useItineraryServices();

  const infoModel = useMemo(() => {
    if (!info) {
      return null;
    }

    return ItineraryInfoModelFactory.create(info);
  }, [info]);

  const offersAvailabilitiesModel = useMemo(() => {
    if (!offersAvailabilities) {
      return null;
    }

    return OffersAvailabilitiesModelFactory.create(offersAvailabilities);
  }, [offersAvailabilities]);

  const offersChangesModel = useMemo(() => {
    if (!offersChanges) {
      return null;
    }

    return OffersChangesModelFactory.create(offersChanges);
  }, [offersChanges]);

  const pendenciesModel = useMemo(() => {
    if (!pendencies) {
      return null;
    }

    return ItineraryPendenciesModelFactory.create(pendencies);
  }, [pendencies]);

  const servicesModel = useMemo(() => {
    if (!services) {
      return null;
    }

    return ItineraryServicesModelFactory.create(services);
  }, [services]);

  const servicesPresenter = useMemo(() => {
    if (!servicesModel) {
      return null;
    }

    return ItineraryServicesPresenterFactory.create(servicesModel);
  }, [servicesModel]);

  const travelApprovalModel = useMemo(() => {
    if (!travelApproval) {
      return null;
    }

    return TravelApprovalModelFactory.create(travelApproval);
  }, [travelApproval]);

  const travelApprovalHistoryModel = useMemo(() => {
    if (!travelApprovalHistory) {
      return null;
    }

    return TravelApprovalHistoryModelFactory.create(travelApprovalHistory);
  }, [travelApprovalHistory]);

  const travelApprovalStatusModel = useMemo(() => {
    if (!travelApprovalStatus) {
      return null;
    }

    return TravelApprovalStatusModelFactory.create(travelApprovalStatus);
  }, [travelApprovalStatus]);

  const travelRepeatedOffersModel = useMemo(() => {
    if (!travelRepeatedOffers) {
      return null;
    }

    return RepeatedOffersModelFactory.create(travelRepeatedOffers);
  }, [travelRepeatedOffers]);

  return (
    <ItineraryContainerContext.Provider
      value={{
        infoModel,
        offersAvailabilitiesModel,
        offersChangesModel,
        pendenciesModel,
        servicesModel,
        servicesPresenter,
        travelApprovalModel,
        travelApprovalHistoryModel,
        travelApprovalStatusModel,
        travelRepeatedOffersModel,
      }}
    >
      {children}
    </ItineraryContainerContext.Provider>
  );
};

export const useItineraryContainer = useContextFactory(
  "ItineraryContainerContext",
  ItineraryContainerContext,
);
