import React from "react";

import { Text, Flex } from "@toolkit";

import * as utils from "@helpers";

import { Expense } from "~/models/expense.model";

interface Props {
  expense: Expense;
}

export const CurrencyConversionSummary = ({
  expense: { currency, exchangeRate, value }
}: Props) => {
  return (
    <Flex
      py={["small"]}
      px={["medium", null, 16]}
      justifyContent={"space-between"}
      alignItems="center"
      border="1px solid"
      borderTop="none"
      borderColor="border"
      backgroundColor="rgba(30, 144, 255, 0.2)"
      color={"#4E6172"}
    >
      <Text fontSize={0}>{`Moeda usada: ${currency}`}</Text>
      <Text fontSize={0}>
        {`Taxa de conversão: ${utils.toCurrency(exchangeRate)}`}
      </Text>
      <Text fontSize={0}>
        {`Valor: ${utils.toCurrency(value, 2, currency)}`}
      </Text>
    </Flex>
  );
};
