import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { navigate } from "@reach/router";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { Button, Flex, Text } from "smartrips-toolkit";

import { useEvents } from "~/components/events/events.context";
import { Spacing } from "~/components/shared/layout";

import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventSettingsManage: React.VFC<Props> = ({ event }) => {
  const queryClient = useQueryClient();

  const { excludeEvent } = useEvents();

  const {
    isLoading: isLoadingExcludeEvent,
    mutateAsync: excludeEventMutation
  } = useMutation({
    mutationFn: async () => {
      const result = await excludeEvent({ token: event.token });

      if (!result) {
        return;
      }

      await queryClient.invalidateQueries(["events"], { exact: true });

      return result;
    }
  });

  const handleExcludeEvent = useCallback(async () => {
    const success = await excludeEventMutation();

    if (!success) {
      return;
    }

    navigate("/events");
  }, [excludeEventMutation, navigate]);

  return (
    <div css={styles.root}>
      <Text fontSize="1.125rem" fontWeight="bold">
        Gerenciar
      </Text>
      <Flex>
        <Spacing direction="horizontal" space="8px" />
        <Button
          color="cancel"
          css={styles.button}
          disabled={isLoadingExcludeEvent}
          onClick={handleExcludeEvent}
        >
          Excluir
        </Button>
      </Flex>
    </div>
  );
};
