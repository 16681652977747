import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Input } from "../input/input";
import { styles } from "./styles";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  placeholder: string;
};

export const SearchInput: React.FC<Props> = ({
  className,
  placeholder,
  ...props
}) => {
  return (
    <div className={className} css={styles.root}>
      <Icon css={styles.icon} size={18} use="search-outline" />
      <Input
        css={styles.input}
        placeholder={placeholder}
        type="text"
        {...props}
      />
    </div>
  );
};
