import { DocumentType } from "~/apps/shared/constants/enums";
import * as documentHelper from "~/apps/shared/helpers/document.helper";
import { Moment } from "moment";
import * as yup from "yup";

export const userDocumentFormSchema = yup.object().shape({
  birthDate: yup
    .mixed<Moment>()
    .required("A data de nascimento é obrigatória.")
    .test("", "Data inválida.", (birthDate) => {
      if (!birthDate) {
        return true;
      }

      if (!birthDate.isValid()) {
        return false;
      }

      return true;
    }),
  documentNumber: yup
    .string()
    .required("O número do documento é obrigatório.")
    .test(
      "",
      "O número do documento é inválido.",
      (documentNumber, context) => {
        if (context.parent.type === DocumentType.CPF) {
          return documentHelper.validateCPF(documentNumber);
        }

        return true;
      },
    ),
  expirationDate: yup
    .mixed<Moment>()
    .test("", "Data inválida.", (expirationDate, context) => {
      if (context.parent.type === DocumentType.PASSPORT) {
        if (!expirationDate) {
          return false;
        }

        if (!expirationDate.isValid()) {
          return false;
        }

        return true;
      }

      return true;
    })
    .nullable(true),
  firstName: yup.string().required("O nome é obrigatório."),
  issueDate: yup
    .mixed<Moment>()
    .test("", "Data inválida.", (issueDate, context) => {
      if (context.parent.type === DocumentType.PASSPORT) {
        if (!issueDate) {
          return false;
        }

        if (!issueDate.isValid()) {
          return false;
        }

        return true;
      }

      return true;
    })
    .nullable(true),
  issuingCountry: yup
    .string()
    .test("", "Um país emissor é obrigatório.", (issuingCountry, context) => {
      if (context.parent.type === DocumentType.PASSPORT) {
        if (!issuingCountry) {
          return false;
        }

        return true;
      }

      return true;
    })
    .nullable(true),
  lastName: yup.string().required("O sobrenome é obrigatório."),
  nationality: yup
    .string()
    .test("", "A nacionalidade é obrigatória.", (nationality, context) => {
      if (context.parent.type === DocumentType.PASSPORT) {
        if (!nationality) {
          return false;
        }

        return true;
      }

      return true;
    })
    .nullable(true),
  residenceCountry: yup
    .string()
    .test("", "A nacionalidade é obrigatória.", (residenceCountry, context) => {
      if (context.parent.type === DocumentType.PASSPORT) {
        if (!residenceCountry) {
          return false;
        }

        return true;
      }

      return true;
    })
    .nullable(true),
  sex: yup.string().required("O sexo é obrigatório."),
  type: yup
    .mixed<DocumentType>()
    .required("O tipo do documento é obrigatório.")
    .nullable(true),
  userToken: yup.string().required("O token do usuário é obrigatório."),
});

export type UserDocumentFormSchema = yup.InferType<
  typeof userDocumentFormSchema
>;
