import { DocumentType } from "~/apps/shared/constants/enums";
import * as documentHelper from "~/apps/shared/helpers/document.helper";
import * as yup from "yup";

export const documentationAccordionSchema = yup.object().shape({
  documents: yup
    .array()
    .of(
      yup.object().shape({
        birthDate: yup
          .mixed<string>()
          .required("A data de nascimento é obrigatória."),
        documentNumber: yup
          .string()
          .required("O número do documento é obrigatório.")
          .test(
            "",
            "O número do documento é inválido.",
            (documentNumber, context) => {
              if (context.parent.type === DocumentType.CPF) {
                return documentHelper.validateCPF(documentNumber);
              }

              if (context.parent.type === DocumentType.RG) {
                return documentHelper.validateRG(documentNumber);
              }

              return true;
            },
          ),
        expirationDate: yup
          .mixed<string>()
          .test("", "Data inválida.", (expirationDate, context) => {
            if (context.parent.type === DocumentType.PASSPORT) {
              if (!expirationDate) {
                return false;
              }

              return true;
            }

            return true;
          })
          .nullable(true),
        firstName: yup.string().required("O nome é obrigatório."),
        issueDate: yup
          .mixed<string>()
          .test("", "Data inválida.", (issueDate, context) => {
            if (context.parent.type === DocumentType.PASSPORT) {
              if (!issueDate) {
                return false;
              }

              return true;
            }

            return true;
          })
          .nullable(true),
        issuingCountry: yup
          .string()
          .test(
            "",
            "Um país emissor é obrigatório.",
            (issuingCountry, context) => {
              if (context.parent.type === DocumentType.PASSPORT) {
                if (!issuingCountry) {
                  return false;
                }

                return true;
              }

              return true;
            },
          )
          .nullable(true),
        lastName: yup.string().required("O sobrenome é obrigatório."),
        nationality: yup.string().nullable(true),
        residenceCountry: yup.string().nullable(true),
        sex: yup.string(),
        type: yup
          .mixed<DocumentType>()
          .required("O tipo do documento é obrigatório."),
        userToken: yup.string().required("O token do usuário é obrigatório."),
      }),
    )
    .required("Os documentos são obrigatórios."),
});

export type DocumentationAccordionSchema = yup.InferType<
  typeof documentationAccordionSchema
>;
