import { ERROR, APPROVABLE_TYPES } from "@constants";
import ERROR_TYPES from "@constants/errors";

import * as expenseApprovalHelper from "@helpers/expense-approval.helper";
import { getClientToken } from "@helpers/user.helper";

import { ApprovableRuleCreateBody } from "@dtos/expense-approval";

import { ApprovableRule, TargetApproval } from "@models/expense-approval.model";
import { UserSearch } from "@models/user.model";

import { CustomError } from "~/types";

import * as approvalRulesApi from "@apis/approval-process.api";
import * as expenseApprovalApi from "@apis/expense-approval.api";

import { Form } from "./ExpensesProcess.types";

export async function listApprovableRules(): Promise<{
  data?: ApprovableRule[];
  error?: CustomError;
}> {
  const result: { data?: ApprovableRule[]; error?: CustomError } = {};

  try {
    result.data = await expenseApprovalApi.getApprovableRuleList();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR;
    }
  }

  return result;
}

export async function searchTargetUsers(
  searchString: string,
): Promise<{ data?: TargetApproval[]; error?: CustomError }> {
  const result: { data?: TargetApproval[]; error?: CustomError } = {};

  try {
    result.data = await expenseApprovalApi.getApprovalTargets(searchString);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_USER_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_USER_SEARCH_ERROR;
    }
  }

  return result;
}

export async function searchUserByNameAndEmail(
  value: string,
): Promise<{ data?: UserSearch[]; error?: CustomError }> {
  const result: { data?: UserSearch[]; error?: CustomError } = {};

  try {
    result.data = await approvalRulesApi.searchApproverUsersByNameAndEmail(
      value,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_USER_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_USER_SEARCH_ERROR;
    }
  }

  return result;
}

export async function createApprovableRule(
  formData: Form,
): Promise<{ data?: ApprovableRule; error?: CustomError }> {
  const result: { data?: ApprovableRule; error?: CustomError } = {};

  try {
    const approvers = expenseApprovalHelper.buildExpenseApprovers(
      formData.expenseApprovers,
    );

    const bodyDto: ApprovableRuleCreateBody = {
      item_token: formData.target!.token,
      type: "user",
      rule: formData.rule,
      expense_approvers: approvers,
      payment_approvers: formData.paymentApprovers.map(
        (item) => item.userToken,
      ),
      client_token: getClientToken(),
      approvable_type: APPROVABLE_TYPES.EXPENSE_REPORT,
    };

    result.data = await expenseApprovalApi.createApprovableRule(bodyDto);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (
        status === 400 &&
        data.type === ERROR_TYPES.EXPENSE_RULE_ALREADY_EXISTS
      ) {
        result.error = ERROR.EXPENSE_RULE_ALREADY_EXISTS_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_CREATE_EXPENSE_APPROVAL_RULE_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_EXPENSE_APPROVAL_RULE_ERROR;
    }
  }

  return result;
}

export async function editApprovableRule(
  formData: Form,
): Promise<{ data?: ApprovableRule; error?: CustomError }> {
  const result: { data?: ApprovableRule; error?: CustomError } = {};

  try {
    const approvers = expenseApprovalHelper.buildExpenseApprovers(
      formData.expenseApprovers,
    );

    const bodyDto: ApprovableRuleCreateBody = {
      item_token: formData.target!.token,
      type: "user",
      rule: formData.rule,
      expense_approvers: approvers,
      payment_approvers: formData.paymentApprovers.map(
        (item) => item.userToken,
      ),
      client_token: getClientToken(),
      approvable_type: APPROVABLE_TYPES.EXPENSE_REPORT,
    };

    result.data = await expenseApprovalApi.editApprovableRule(
      bodyDto,
      formData.token!,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EDIT_EXPENSE_APPROVAL_RULE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EDIT_EXPENSE_APPROVAL_RULE_ERROR;
    }
  }

  return result;
}

export async function deleteApprovableRule(
  expenseApprovalToken: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expenseApprovalApi.deleteApprovableRule(
      expenseApprovalToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_DELETE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_DELETE_ERROR;
    }
  }

  return result;
}
