import React from "react";
import Skeleton from "react-loading-skeleton";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { CarrierFilter } from "../../../flights.types";
import { styles } from "./styles";

type Props = {
  availableCarriers: CarrierFilter[];
  carriersPriceMap: Record<string, number> | undefined;
  handleCarrierToggle: (toggledCarrier: CarrierFilter) => void;
  loading?: boolean;
};

export const AirlinesFilter: React.FC<Props> = ({
  availableCarriers,
  carriersPriceMap,
  handleCarrierToggle,
  loading,
}) => {
  if (availableCarriers.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Linhas aéreas</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {availableCarriers.map((carrier) => (
          <CheckboxGroup.Item
            checked={carrier.checked}
            css={styles.checkbox.group.item.root}
            id={carrier.value}
            key={carrier.value}
            name={carrier.value}
            onChange={() => {
              handleCarrierToggle(carrier);
            }}
            value={carrier.checked}
          >
            <div css={styles.checkbox.group.item.label.root}>
              <span css={styles.checkbox.group.item.label.label}>
                {carrier.label}
              </span>
              {!loading ? (
                carriersPriceMap ? (
                  <AirlinesPrices
                    carrier={carrier}
                    carriersPriceMap={carriersPriceMap}
                  />
                ) : null
              ) : (
                <AirlinesPricesSkeleton />
              )}
            </div>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};

type AirlinesPricesProps = {
  carrier: CarrierFilter;
  carriersPriceMap: Record<string, number>;
};

const AirlinesPrices: React.FC<AirlinesPricesProps> = ({
  carrier,
  carriersPriceMap,
}) => {
  return (
    <span css={styles.checkbox.group.item.label.price}>
      {carrier.value in carriersPriceMap
        ? toCurrency(
            carriersPriceMap[carrier.value as keyof typeof carriersPriceMap],
          )
        : null}
    </span>
  );
};

const AirlinesPricesSkeleton: React.FC = () => {
  return <Skeleton height="18px" width="64px" />;
};
