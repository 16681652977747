export function transformTagConfigOptionIntoDto(data) {
  const result = {};
  if (Object.prototype.hasOwnProperty.call(data, "onlyAdminOption")) {
    result.tags_admin_only = data.onlyAdminOption;
  }

  if (Object.prototype.hasOwnProperty.call(data, "requiredTravelTagOption")) {
    result.travel_tags_required = data.requiredTravelTagOption;
  }

  return result;
}
