import React, { useCallback } from "react";
import { DateRangePicker, SingleDatePicker } from "react-dates";

import DateRangeIcon from "@material-ui/icons/DateRange";
import NavPrevIcon from "@material-ui/icons/ExpandLessRounded";
import NavNextIcon from "@material-ui/icons/ExpandMoreRounded";
import { css } from "emotion";

import { defaultTheme } from "../../../assets/styles/theme";
import { Row } from "../layout/Row";
import { styles as newStyles } from "./styles";

const styles = {
  arrow: css({
    color: defaultTheme.subTextColor,
  }),
  calendarIcon: css({
    fontSize: 24,
    color: defaultTheme.subTextColor,
  }),
  dateDiv: css({
    padding: "11px 11px 9px",
    minWidth: 107,
  }),
  divisor: css({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
  }),
  icon: css({
    fontSize: 16,
    color: defaultTheme.subTextColor,
  }),
  navIcon: css({
    fontSize: 24,
    color: defaultTheme.subTextColor,
  }),
  verticalHeader: css({
    padding: "8px 14px",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    alignItems: "center",
  }),
};

const CustomNavNext = () => (
  <div>
    <NavNextIcon className={styles.navIcon} />
  </div>
);

const CustomNavPrvev = () => (
  <div>
    <NavPrevIcon className={styles.navIcon} />
  </div>
);

const CustomArrowIcon = () => <span className={styles.arrow}>—</span>;

const CalendarIcon = () => <DateRangeIcon className={styles.icon} />;

const renderVerticalRangePickerHeader = ({
  startDate,
  endDate,
  onFocusChange,
  startDatePlaceholderText,
  endDatePlaceholderText,
}: Pick<
  React.ComponentPropsWithoutRef<typeof DateRangePicker>,
  | "endDate"
  | "endDatePlaceholderText"
  | "onFocusChange"
  | "startDate"
  | "startDatePlaceholderText"
>) =>
  // eslint-disable-next-line react/display-name
  () => {
    const formattedStartDate = startDate
      ? startDate.format("DD/MM/YYYY")
      : startDatePlaceholderText;
    const formattedEndDate = endDate
      ? endDate.format("DD/MM/YYYY")
      : endDatePlaceholderText;

    const setStartFocused = () => onFocusChange("startDate");
    const setEndFocused = () => onFocusChange("endDate");

    return (
      <Row className={styles.verticalHeader}>
        <DateRangeIcon className={styles.calendarIcon} />
        <div className={styles.dateDiv} onClick={setStartFocused}>
          <span
            style={{
              color: startDate ? "inherit" : defaultTheme.cardBorderColor,
            }}
          >
            {formattedStartDate}
          </span>
        </div>
        <div className={styles.divisor}>—</div>
        <div className={styles.dateDiv} onClick={setEndFocused}>
          <span
            style={{
              color: endDate ? "inherit" : defaultTheme.cardBorderColor,
            }}
          >
            {formattedEndDate}
          </span>
        </div>
      </Row>
    );
  };

type ResponsiveRangeDatePickerProps = {
  datePickerProps: React.ComponentPropsWithoutRef<typeof DateRangePicker>;
};

const ResponsiveRangeDatePicker: React.VFC<ResponsiveRangeDatePickerProps> = ({
  datePickerProps,
}) => {
  const getDateRangePickerProps = useCallback(() => {
    const isMobileDevice = window.innerWidth <= 992;

    let dateRangePickerProps = datePickerProps;

    if (!datePickerProps.customArrowIcon) {
      dateRangePickerProps.customArrowIcon = <CustomArrowIcon />;
    }

    if (!datePickerProps.customInputIcon) {
      dateRangePickerProps.customInputIcon = <CalendarIcon />;
    }

    if (isMobileDevice) {
      dateRangePickerProps = {
        ...dateRangePickerProps,
        appendToBody: false,
        calendarInfoPosition: "top",
        navNext: <CustomNavNext />,
        navPrev: <CustomNavPrvev />,
        orientation: "vertical",
        readOnly: true,
        renderCalendarInfo: renderVerticalRangePickerHeader({
          endDate: datePickerProps.endDate,
          endDatePlaceholderText: datePickerProps.endDatePlaceholderText,
          onFocusChange: datePickerProps.onFocusChange,
          startDate: datePickerProps.startDate,
          startDatePlaceholderText: datePickerProps.startDatePlaceholderText,
        }),
        withFullScreenPortal: true,
      };
    }

    return dateRangePickerProps;
  }, [datePickerProps]);

  return (
    <div css={newStyles.range}>
      <DateRangePicker {...getDateRangePickerProps()} />
    </div>
  );
};

const renderVerticalSinglePickerHeader = ({
  date,
  placeholder,
}: Pick<
  React.ComponentPropsWithoutRef<typeof SingleDatePicker>,
  "date" | "placeholder"
  // eslint-disable-next-line react/display-name
>) => () => {
  const formattedDate = date ? date.format("DD/MM/YYYY") : placeholder;

  return (
    <Row className={styles.verticalHeader}>
      <DateRangeIcon className={styles.calendarIcon} />
      <div className={styles.dateDiv}>
        <span
          style={{
            color: formattedDate ? "inherit" : defaultTheme.cardBorderColor,
          }}
        >
          {formattedDate}
        </span>
      </div>
    </Row>
  );
};

type ResponsiveSingleDatePickerProps = {
  datePickerProps: React.ComponentPropsWithoutRef<typeof SingleDatePicker>;
};

const ResponsiveSingleDatePicker: React.VFC<ResponsiveSingleDatePickerProps> = ({
  datePickerProps,
}) => {
  const getSingleDatePickerProps = useCallback(() => {
    const isMobileDevice = window.innerWidth <= 992;

    let singleDatePickerProps = datePickerProps;

    if (!datePickerProps.customInputIcon) {
      singleDatePickerProps.customInputIcon = <CalendarIcon />;
    }

    if (isMobileDevice) {
      singleDatePickerProps = {
        ...datePickerProps,
        appendToBody: false,
        calendarInfoPosition: "top",
        navNext: <CustomNavNext />,
        navPrev: <CustomNavPrvev />,
        orientation: "vertical",
        readOnly: true,
        renderCalendarInfo: renderVerticalSinglePickerHeader({
          date: datePickerProps.date,
          placeholder: datePickerProps.placeholder,
        }),
        withFullScreenPortal: true,
      };
    }

    return singleDatePickerProps;
  }, [datePickerProps]);

  return (
    <div css={newStyles.single}>
      <SingleDatePicker {...getSingleDatePickerProps()} />
    </div>
  );
};

export { ResponsiveRangeDatePicker, ResponsiveSingleDatePicker };
