import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

import {
  CreateDocumentRequestBody,
  CreateDocumentResponse,
  DeleteDocumentResponse,
  EditDocumentRequestBody,
  EditDocumentResponse,
  GetDocumentResponse,
  GetUserDocumentsResponse,
} from "../dtos/documents.dto";
import * as documentsParser from "../parsers/documents.parser";

export const createDocument = async (data: CreateDocumentRequestBody) => {
  return await api
    .request<CreateDocumentResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/document",
    })
    .then(({ data }) => documentsParser.parseCreateDocumentResponse(data));
};

export const deleteDocument = async (documentToken: string) => {
  return await api.request<DeleteDocumentResponse>({
    headers: getAuthorizationHeader(),
    method: "DELETE",
    url: `/booking/document/${documentToken}`,
  });
};

export const editDocument = async (
  data: EditDocumentRequestBody,
  documentToken: string,
) => {
  return await api
    .request<EditDocumentResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/document/${documentToken}`,
    })
    .then(({ data }) => documentsParser.parseEditDocumentResponse(data));
};

export const getDocument = async (documentToken: string) => {
  return await api
    .request<GetDocumentResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/document/${documentToken}`,
    })
    .then(({ data }) => documentsParser.parseDocumentResponse(data));
};

export const getUserDocuments = async (userToken: string) => {
  return await api
    .request<GetUserDocumentsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/document/user/${userToken}`,
    })
    .then(({ data }) => documentsParser.parseUserDocumentsResponse(data));
};
