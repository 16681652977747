import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    categories: {
      list: {
        item: {
          root: css({
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }),
          text: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            lineHeight: "1.125",
          }),
          title: css({
            color: theme.colors.gray[700],
            fontWeight: 600,
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }),
      },
      root: css({
        display: "flex",
        width: "100%",
      }),
    },
    root: css({
      gap: "24px",
    }),
    top: {
      message: css({
        color: theme.colors.gray[300],
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  header: {
    root: css({}),
  },
  root: css({
    ".MuiDialog-paper": {
      overflowY: "auto",
    },
  }),
};
