import React, { createContext, useEffect } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { FETCH_STATUS, EXPENSE_REPORT_STATUS_TYPE } from "@constants";

import * as expensesHelper from "@helpers/expense.helper";

import { ExpenseReportWithUser } from "@models/expense.model";

import { useSafeState } from "@hooks";

import * as reportsListService from "./OthersReportsList.service";

interface Actions {
  fetchReports: () => void;
  handleChangeSearch: (searchText: string) => void;
  loadNextReports: () => void;
}

type State = {
  currentPage: number;
  reports: ExpenseReportWithUser[];
  searchFilter: string;
  status: string;
  statusFilter: string;
  totalPages: number;
};

const initialState: State = {
  currentPage: 1,
  reports: [],
  searchFilter: "",
  status: FETCH_STATUS.FETCHING,
  statusFilter: EXPENSE_REPORT_STATUS_TYPE.ALL,
  totalPages: 1,
};

type ContextProps = Actions & State;

const OthersReportsListContext = createContext<ContextProps>({
  ...initialState,
  fetchReports: () => null,
  handleChangeSearch: () => null,
  loadNextReports: () => null,
});

const OthersReportsListProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const fetchReports = async () => {
    setState({ status: FETCH_STATUS.FETCHING });
    const { statusFilter, searchFilter } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(1, statusFilter, searchFilter),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ status: FETCH_STATUS.ERROR });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      currentPage: 1,
      reports: reportsWithUsers,
      status: FETCH_STATUS.SUCCESS,
      totalPages: reportsData!.totalPages,
    });
  };

  const loadNextReports = async () => {
    setState({ status: FETCH_STATUS.FETCHING });
    const { reports, currentPage, statusFilter, searchFilter } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(
        currentPage + 1,
        statusFilter,
        searchFilter,
      ),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ status: FETCH_STATUS.ERROR });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      currentPage: currentPage + 1,
      reports: reports.concat(reportsWithUsers),
      status: FETCH_STATUS.SUCCESS,
      totalPages: reportsData!.totalPages,
    });
  };

  const handleChangeSearch = debounce(
    (searchText) => setState({ searchFilter: searchText }),
    300,
  );

  useEffect(() => {
    void fetchReports();
  }, [state.searchFilter]);

  return (
    <OthersReportsListContext.Provider
      value={{
        ...state,
        fetchReports,
        loadNextReports,
        handleChangeSearch,
      }}
    >
      {children}
    </OthersReportsListContext.Provider>
  );
};

export { OthersReportsListContext, OthersReportsListProvider };
