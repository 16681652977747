import React, { useEffect } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { Box } from "@toolkit";

import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";

import { BoardDrawer } from "./board-drawer/board-drawer";
import { BoardHeader } from "./board-header/board-header";
import { BoardName } from "./board-name/board-name";
import { BoardTable } from "./board-table/board-table";
import { BoardProvider, useBoards } from "./boards.context";

const Component = () => {
  const { clientConfig } = useClientConfig();

  const {
    closeDrawerAndReset,
    drawerOpen,
    handleCreateBoard,
    handleOpenEditBoard,
    handleSaveBoard,
    handleSearch,
    handleToggleArchiveBoard,
    isLoading,
    loadClientBoards,
    searchInput,
    selectedBoard,
    visibleBoards,
  } = useBoards();

  const companyAreaDisplay = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : null;

  const companyBoardDisplay = clientConfig
    ? clientConfig.getCompanyBoardDisplay()
    : "Diretorias";

  useEffect(() => {
    loadClientBoards();
  }, []);

  return (
    <Box>
      <PageTitle title={companyBoardDisplay} />
      <SpinnerPortal visible={isLoading} />
      <BoardName />
      <BoardHeader
        boardName={companyBoardDisplay}
        handleBtnClick={handleCreateBoard}
        handleSearchOnChange={handleSearch}
        onBatchUploadSuccess={loadClientBoards}
        searchInput={searchInput}
      />
      <BoardTable
        boards={visibleBoards}
        handleEdit={handleOpenEditBoard}
        handleToggleArchive={handleToggleArchiveBoard}
      />
      <BoardDrawer
        boardName={companyBoardDisplay}
        companyAreaName={companyAreaDisplay}
        drawerOpen={drawerOpen}
        handleClose={closeDrawerAndReset}
        handleSubmit={handleSaveBoard}
        selectedBoard={selectedBoard}
      />
    </Box>
  );
};

export const Boards = () => (
  <BoardProvider>
    <Component />
  </BoardProvider>
);
