import React from "react";
import { ExpenseReportApprovalStatus } from "../../../../models/expense.model";
import { ApprovalHistoryPresentational } from "./ApprovalHistory.presentational";
import isEmpty from "lodash/isEmpty";
import { EXPENSE_APPROVAL_STATUS } from "../../../../constants";

interface Props {
  approvalStatus: ExpenseReportApprovalStatus;
}

const ApprovalHistoryContainer = ({ approvalStatus }: Props) => {
  const {
    expenseApproversHistory,
    currentApprovalStage,
    status
  } = approvalStatus;
  const lastReply = expenseApproversHistory
    ? expenseApproversHistory[expenseApproversHistory.length - 1]
    : null;

  const currentStage =
    status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT
      ? 99
      : currentApprovalStage;

  const hasAnyReplyForThisStage = expenseApproversHistory
    ? expenseApproversHistory.some(history => history.stage === currentStage)
    : false;

  const showCurrentStage =
    (!!lastReply &&
      lastReply.stage !== currentStage &&
      !hasAnyReplyForThisStage) ||
    (!lastReply &&
      (status === EXPENSE_APPROVAL_STATUS.DECLINED ||
        status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL ||
        status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT));

  if (!isEmpty(approvalStatus.expenseApproversHistory) || showCurrentStage) {
    return (
      <ApprovalHistoryPresentational
        currentStage={currentStage}
        approversHistory={expenseApproversHistory || []}
        showCurrentStage={showCurrentStage}
      />
    );
  } else {
    return null;
  }
};

export { ApprovalHistoryContainer };
