import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import BackwardIcon from "@material-ui/icons/ArrowBack";
import ForwardIcon from "@material-ui/icons/ArrowForward";
import NavPrevIcon from "@material-ui/icons/ExpandLessRounded";
import NavNextIcon from "@material-ui/icons/ExpandMoreRounded";
import { css } from "emotion";
import { Row } from "@shared/layout/Row";
import { Box, Flex } from "@toolkit";
import { defaultTheme } from "@assets/styles/theme";
import { theme } from "@skin/v2";

const styles = {
  spanButton: css({
    cursor: "pointer",
    fontSize: 14,
    height: 14
  }),
  applyButton: css({
    fontSize: 14,
    height: 14,
    color: theme.colors.pink[500],
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
      borderBottom: `1px solid ${theme.colors.pink[500]}`
    }
  }),
  clearButton: css({
    color: defaultTheme.textColor,
    "&:hover": {
      opacity: 0.8,
      borderBottom: `1px solid ${defaultTheme.textColor}`
    }
  }),
  horizontalNavigationBtn: css({
    color: "#757575",
    border: "1px solid #dce0e0",
    height: "34px",
    width: "39px",
    padding: "6px 9px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  })
};

const CALENDAR_TYPE = {
  START: "start",
  END: "end"
};

const ORIENTATION_TYPE = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical"
};

function getMonthsShortLocale(locale) {
  if (getMonthsShortLocale[locale]) {
    return getMonthsShortLocale[locale];
  }

  moment.locale(locale);
  return moment.months();
}

function monthsInBetweenMap(
  startMonth,
  startYear,
  endMonth,
  endYear,
  displayedYear
) {
  const result = {};
  if (typeof startMonth === "undefined" || typeof endMonth === "undefined") {
    for (var k = 0; k < 12; k++) {
      result[k] = false;
    }
  } else {
    for (var i = 0; i < 12; i++) {
      const isMonthYearAfterStart =
        i * 30 + displayedYear * 360 > startMonth * 30 + startYear * 360;
      const isMonthYearBeforeEnd =
        i * 30 + displayedYear * 360 < endMonth * 30 + endYear * 360;
      result[i] = isMonthYearAfterStart && isMonthYearBeforeEnd ? true : false;
    }
  }

  return result;
}

class MonthPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startSelectedYear: undefined,
      startSelectedMonth: undefined,
      startDisplayedYear: undefined,
      endSelectedYear: undefined,
      endSelectedMonth: undefined,
      endDisplayedYear: undefined
    };
  }

  componentDidMount() {
    const today = new Date();
    const { startYear, startMonth, endYear, endMonth } = this.props;

    if (
      typeof startMonth === "undefined" ||
      startMonth === null ||
      typeof startYear === "undefined" ||
      startYear === null
    ) {
      this.setState({
        startSelectedYear: today.getFullYear(),
        startDisplayedYear: today.getFullYear(),
        startSelectedMonth: 0,
        endSelectedYear: today.getFullYear(),
        endDisplayedYear: today.getFullYear() + 1,
        endSelectedMonth: 11
      });
    } else {
      this.setState({
        startSelectedYear: startYear,
        startDisplayedYear: startYear,
        startSelectedMonth: startMonth,
        endSelectedYear: endYear,
        endDisplayedYear: startYear + 1,
        endSelectedMonth: endMonth
      });
    }
  }

  onMonthClick = (month, displayedYear) => {
    return e => {
      const {
        startSelectedYear: currentStartSelectedYear,
        startSelectedMonth: currentStartSelectedMonth,
        endSelectedYear: currentEndSelectedYear,
        endSelectedMonth: currentEndSelectedMonth
      } = this.state;

      if (typeof currentStartSelectedMonth === "undefined") {
        this.setState({
          startSelectedYear: displayedYear,
          startSelectedMonth: month
        });
      } else if (
        typeof currentStartSelectedMonth !== "undefined" &&
        typeof currentEndSelectedMonth === "undefined"
      ) {
        const isSelectedMonthBeforeEqualStart =
          month * 30 + displayedYear * 360 <=
          currentStartSelectedMonth * 30 + currentStartSelectedYear * 360;

        if (isSelectedMonthBeforeEqualStart) {
          this.setState({
            startSelectedYear: displayedYear,
            startSelectedMonth: month
          });
        } else {
          this.setState({
            endSelectedYear: displayedYear,
            endSelectedMonth: month
          });
        }
      } else if (
        typeof currentStartSelectedMonth !== "undefined" &&
        typeof currentEndSelectedMonth !== "undefined"
      ) {
        this.setState({
          startSelectedYear: displayedYear,
          startSelectedMonth: month,
          endSelectedYear: undefined,
          endSelectedMonth: undefined
        });
      }
    };
  };

  onStartYearDecrementClick = () => {
    this.setState(state => {
      return {
        startDisplayedYear: state.startDisplayedYear - 1,
        endDisplayedYear: state.endDisplayedYear - 1
      };
    });
  };

  onEndYearDecrementClick = () => {
    this.setState(state => {
      return {
        endDisplayedYear: state.endDisplayedYear - 1
      };
    });
  };

  onStartYearIncrementClick = () => {
    this.setState(state => {
      return {
        startDisplayedYear: state.startDisplayedYear + 1
      };
    });
  };

  onEndYearIncrementClick = () => {
    this.setState(state => {
      return {
        endDisplayedYear: state.endDisplayedYear + 1,
        startDisplayedYear: state.startDisplayedYear + 1
      };
    });
  };

  onClearClick = () => {
    this.setState({
      startSelectedYear: undefined,
      startSelectedMonth: undefined,
      endSelectedYear: undefined,
      endSelectedMonth: undefined
    });
  };

  onApplyClick = () => {
    const {
      startSelectedMonth,
      startSelectedYear,
      endSelectedMonth,
      endSelectedYear
    } = this.state;
    const range = {
      startSelectedMonth,
      startSelectedYear,
      endSelectedMonth:
        typeof endSelectedMonth !== "undefined"
          ? endSelectedMonth
          : startSelectedMonth,
      endSelectedYear:
        typeof endSelectedYear !== "undefined"
          ? endSelectedYear
          : startSelectedYear
    };
    this.props.onRangeApply(range);
  };

  renderHeader = calendarType => {
    let displayedYear, onYearDecrementClick, onYearIncrementClick;
    const { startDisplayedYear, endDisplayedYear } = this.state;
    const { orientation } = this.props;

    if (calendarType === CALENDAR_TYPE.START) {
      displayedYear = startDisplayedYear;
      onYearDecrementClick = this.onStartYearDecrementClick;
      onYearIncrementClick = this.onStartYearIncrementClick;
    } else {
      displayedYear = endDisplayedYear;
      onYearDecrementClick = this.onEndYearDecrementClick;
      onYearIncrementClick = this.onEndYearIncrementClick;
    }

    return (
      <Flex alignItems="center" style={{ position: "relative" }}>
        {calendarType === CALENDAR_TYPE.START &&
        orientation === ORIENTATION_TYPE.HORIZONTAL ? (
          <div
            className={styles.horizontalNavigationBtn}
            onClick={onYearDecrementClick}
          >
            <BackwardIcon />
          </div>
        ) : null}

        {calendarType === CALENDAR_TYPE.START &&
          orientation === ORIENTATION_TYPE.VERTICAL && (
            <Box
              position="absolute"
              left={0}
              style={{ cursor: "pointer" }}
              onClick={onYearDecrementClick}
            >
              <NavPrevIcon />
            </Box>
          )}

        <Box flex={1} textAlign="center" fontSize={18} fontWeight="bold">
          {displayedYear}
        </Box>

        {calendarType === CALENDAR_TYPE.END &&
        orientation === ORIENTATION_TYPE.HORIZONTAL ? (
          <div
            className={styles.horizontalNavigationBtn}
            onClick={onYearIncrementClick}
          >
            <ForwardIcon />
          </div>
        ) : null}

        {calendarType === CALENDAR_TYPE.END &&
          orientation === ORIENTATION_TYPE.VERTICAL && (
            <Box
              position="absolute"
              right={0}
              top={-260}
              style={{ cursor: "pointer" }}
              onClick={onYearIncrementClick}
            >
              <NavNextIcon />
            </Box>
          )}
      </Flex>
    );
  };

  renderMonths = calendarType => {
    let displayedYear;
    const monthsShort = getMonthsShortLocale("pt");
    const {
      startSelectedMonth,
      startSelectedYear,
      startDisplayedYear,
      endSelectedMonth,
      endSelectedYear,
      endDisplayedYear
    } = this.state;

    if (calendarType === CALENDAR_TYPE.START) {
      displayedYear = startDisplayedYear;
    } else {
      displayedYear = endDisplayedYear;
    }

    const monthsInBetween = monthsInBetweenMap(
      startSelectedMonth,
      startSelectedYear,
      endSelectedMonth,
      endSelectedYear,
      displayedYear
    );

    const months = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [9, 10, 11]
    ];

    const Months = months.map((month, i) => (
      <div
        style={{
          display: "flex"
        }}
        key={i}
      >
        {month.map((m, j) => {
          let isSelected, isInBetween;
          isSelected =
            (startSelectedMonth === m && startSelectedYear === displayedYear) ||
            (endSelectedMonth === m && endSelectedYear === displayedYear);

          isInBetween = monthsInBetween[m];

          return (
            <div
              style={{
                padding: "8px",
                width: "90px",
                height: "38px",
                borderLeft: "1px solid #e4e7e7",
                borderTop: "1px solid #e4e7e7",
                color: isSelected || isInBetween ? "#fff" : "#565a5c",
                cursor: "pointer",
                textAlign: "center",
                borderRight: j === 2 ? "1px solid #e4e7e7" : "none",
                borderBottom: i == 3 ? "1px solid #e4e7e7" : "none",
                backgroundColor: isSelected
                  ? theme.colors.pink[500]
                  : isInBetween
                  ? theme.colors.pink[300]
                  : "transparent"
              }}
              key={j}
              onClick={this.onMonthClick(m, displayedYear)}
            >
              {monthsShort[m]}
            </div>
          );
        })}
      </div>
    ));

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        {Months}
      </div>
    );
  };

  render() {
    const { isClearEnabled, orientation } = this.props;
    return (
      <Box width={[311, 622]}>
        <Flex
          flexDirection={
            orientation === ORIENTATION_TYPE.HORIZONTAL ? "row" : "column"
          }
        >
          <div
            style={{
              width: "311px",
              height: "256px",
              padding: "1rem"
            }}
          >
            {this.renderHeader(CALENDAR_TYPE.START)}
            <div style={{ padding: "16px" }} />
            {this.renderMonths(CALENDAR_TYPE.START)}
          </div>
          <div
            style={{
              width: "311px",
              height: "256px",
              padding: "1rem"
            }}
          >
            {this.renderHeader(CALENDAR_TYPE.END)}
            <div style={{ padding: "16px" }} />
            {this.renderMonths(CALENDAR_TYPE.END)}
          </div>
        </Flex>
        <Row
          style={{
            justifyContent: isClearEnabled ? "space-between" : "flex-end",
            padding: "1rem 1rem 0 1rem"
          }}
        >
          {isClearEnabled ? (
            <span
              className={`${styles.clearButton} ${styles.spanButton}`}
              onClick={this.onClearClick}
            >
              Limpar
            </span>
          ) : null}

          <span
            className={`${styles.applyButton} ${styles.spanButton}`}
            onClick={this.onApplyClick}
          >
            Aplicar
          </span>
        </Row>
      </Box>
    );
  }
}

MonthPicker.defaultProps = {
  orientation: ORIENTATION_TYPE.HORIZONTAL
};

MonthPicker.propTypes = {
  onRangeApply: PropTypes.func,
  startYear: PropTypes.number,
  startMonth: PropTypes.number,
  endYear: PropTypes.number,
  endMonth: PropTypes.number,
  isClearEnabled: PropTypes.bool,
  orientation: PropTypes.oneOf([
    ORIENTATION_TYPE.HORIZONTAL,
    ORIENTATION_TYPE.VERTICAL
  ])
};

export { MonthPicker };
