import React from "react";

import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";
import { lighten } from "polished";
import { theme } from "smartrips-skin";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { FilterButton } from "./FilterButton";

const styles = {
  popover: css({
    ["& .MuiPaper-root"]: {
      padding: 24,
      backgroundColor: "white",
      boxShadow:
        "rgba(50, 50, 93, 0.06) 0px 4px 8px 0px, rgba(121, 135, 157, 0.1) 0px 0px 4px 0px, rgba(50, 50, 93, 0.06) 0px 2px 6px 0px;",
      width: "auto",
      overflowX: "visible",
      overflowY: "visible"
    }
  }),
  closeButton: css({
    height: 16,
    width: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: lighten(0.1, defaultTheme.subTextColor),
    ml: 12,
    transition: "all 300ms ease",
    cursor: "pointer",
    ["&:hover"]: {
      backgroundColor: defaultTheme.subTextColor
    }
  }),
  closeIcon: css({
    color: "white",
    fontSize: 14
  }),
  applyButton: css({
    fontSize: 14,
    height: 14,
    color: '#ff385c',
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
      borderBottom: "1px solid",
      borderBottomColor: "primary"
    }
  })
};

const FilterButtonPopover = props => {
  const {
    children,
    anchorEl,
    isFilterSelected,
    isOpen,
    onClearClick,
    onApplyClick,
    onButtonClick,
    onPopoverClose,
    selectedButtonText,
    unselectedButtonText,
    containerStyle,
    applyEnabled,
    clearEnabled,
    showClearButton
  } = props;

  const isActive = isFilterSelected || !!anchorEl;
  const wrapperStyle = containerStyle ? containerStyle : {};

  return (
    <div style={wrapperStyle}>
      <FilterButton
        isActive={isActive}
        onClick={onButtonClick}
        aria-owns={isOpen ? "travel-status-picker" : undefined}
        aria-haspopup="true"
      >
        <Flex alignItems="center" minHeight={16}>
          <Text>
            {isFilterSelected ? selectedButtonText : unselectedButtonText}
          </Text>
          {showClearButton && clearEnabled && (
            <Box onClick={onClearClick} css={styles.closeButton}>
              <CloseIcon css={styles.closeIcon} />
            </Box>
          )}
        </Flex>
      </FilterButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        css={styles.popover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {children}
        <Flex mt="medium" justifyContent="flex-end">
          {applyEnabled ? (
            <span css={styles.applyButton} onClick={onApplyClick}>
              Aplicar
            </span>
          ) : null}
        </Flex>
      </Popover>
    </div>
  );
};

export { FilterButtonPopover };
