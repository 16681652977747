import { AxiosResponse } from "axios";

import {
  CreateAndEditExpensePolicyRequest,
  CreateExpensePolicyResponse,
  EditExpensePolicyResponse
} from "../dtos/expense-policy.dto";
import { getClientToken, getAuthorizationHeader } from "../helpers/user.helper";
import {
  ExpensePolicyListItem,
  ExpensePolicy
} from "../models/expense-policy.model";
import { api } from "./interceptors";

export function createExpensePolicy(dto: CreateAndEditExpensePolicyRequest) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/policies`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      (response: AxiosResponse<CreateExpensePolicyResponse>) => response.data
    );
}

export function editExpensePolicy(
  dto: CreateAndEditExpensePolicyRequest,
  policyToken: string
) {
  return api
    .request({
      url: `/booking/policies/${policyToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      (response: AxiosResponse<EditExpensePolicyResponse>) => response.data
    );
}

export function getExpensePoliciesList() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/policies`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpensePolicyListItem[]>) => response.data);
}

export function getExpensePolicyInfo(policyToken: string) {
  return api
    .request({
      url: `/booking/policies/${policyToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpensePolicy>) => response.data);
}

export function deleteExpensePolicy(policyToken: string) {
  return api
    .request({
      url: `/booking/policies/${policyToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<any>) => response.data);
}

export function getExpensePoliciesTargetSuggestions(searchString: string) {
  return api
    .request({
      url: `/booking/policies/targets/search/${searchString}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(
      (
        response: AxiosResponse<
          Array<{
            email: string;
            userToken: string;
            fullName: string;
            inUse: boolean;
            label: string;
          }>
        >
      ) => response.data.map(item => ({ ...item, label: item.fullName }))
    );
}
