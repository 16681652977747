import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.gray[700],
    fontFamily: theme.fonts.inter,
    padding: "12px 0",
    outline: "none",
  }),
};
