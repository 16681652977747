import React from "react";
import { useQuery, useQueryClient } from "react-query";

import * as userApi from "~/apps/corporate/apis/user.api";
import { getLoggedUserToken } from "~/apps/corporate/helpers/user.helper";
import { AsyncData } from "~/apps/shared/components/async-data/async-data";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Form, useForm } from "~/apps/shared/components/form/form";

import { Button } from "@toolkit/v2";

import {
  AcceptTermsDialogSchema,
  acceptTermsDialogSchema,
} from "./accept-terms-dialog.schema";
import { styles } from "./styles";

export const AcceptTermsDialog: React.FC = () => {
  const userToken = getLoggedUserToken();

  const status = useQuery(
    ["accept-terms-status", userToken],
    () => {
      if (!userToken) {
        return;
      }

      return userApi.getUserAcceptedTerms(userToken);
    },
    {
      cacheTime: Infinity,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  if (!userToken) {
    return null;
  }

  return (
    <AsyncData {...status}>
      <AsyncData.Success>
        {(data) => (
          <AcceptTermsDialogSuccess
            hasAcceptedTerms={data.has_accepted_terms}
            userToken={userToken}
          />
        )}
      </AsyncData.Success>
    </AsyncData>
  );
};

type AcceptTermsDialogSuccessProps = {
  hasAcceptedTerms: boolean;
  userToken: string;
};

const AcceptTermsDialogSuccess: React.FC<AcceptTermsDialogSuccessProps> = ({
  hasAcceptedTerms,
  userToken,
}) => {
  const queryClient = useQueryClient();

  const form = useForm<AcceptTermsDialogSchema>({
    defaultValues: {
      acceptTerms: false,
    },
    onSubmit: async function onSubmit() {
      await userApi.updateUserAcceptTerms(userToken);

      await queryClient.refetchQueries(["accept-terms-status", userToken]);
    },
    schema: acceptTermsDialogSchema,
  });

  const acceptTerms = form.watch("acceptTerms");

  if (hasAcceptedTerms) {
    return null;
  }

  return (
    <Dialog
      css={styles.root}
      onClose={() => {
        void form.submitForm();
      }}
      open
    >
      <DialogHeader css={styles.header.root} icon="clipboard-document-list" />
      <DialogContent css={styles.content.root}>
        <div css={styles.content.top.root}>
          <DialogTitle>Política de Privacidade da Smartrips Ltda</DialogTitle>
          <span css={styles.content.top.message}>
            Última Atualização: 03 de fevereiro de 2022
          </span>
        </div>
        <div css={styles.content.terms.root}>
          <AcceptTermsDialogTerms />
        </div>
      </DialogContent>
      <DialogFooter css={styles.footer.root}>
        <Form css={styles.footer.form.root} context={form}>
          <label css={styles.footer.form.checkbox.root}>
            <Checkbox
              checked={acceptTerms}
              onChange={() => {
                form.setValue("acceptTerms", !acceptTerms);
              }}
              variant="pink"
            />
            <span css={styles.footer.form.checkbox.label}>
              Eu aceito a política de privacidade.
            </span>
          </label>
          <Button
            css={styles.footer.button}
            disabled={!acceptTerms}
            onClick={form.submitForm}
            variant="pink"
          >
            Salvar
          </Button>
        </Form>
      </DialogFooter>
    </Dialog>
  );
};

const AcceptTermsDialogTerms: React.FC = () => {
  return (
    <>
      <p>
        Quando você utiliza a <strong>SMARTRIPS LTDA</strong>, você nos confia
        seus dados e informações. Nos comprometemos a manter essa confiança.
      </p>
      <p>
        Nesse sentido, a presente Política de Privacidade (“Política”) explica
        de maneira clara e acessível como as suas informações e dados serão
        coletados, usados, compartilhados e armazenados por meio dos nossos
        sistemas.
      </p>
      <p>
        <strong>
          A aceitação da nossa Política será feita quando você acessar ou usar o
          site, aplicativo ou serviços da <strong>SMARTRIPS LTDA</strong>. Isso
          indicará que você está ciente e em total acordo com a forma como
          utilizaremos as suas informações e seus dados.
        </strong>
      </p>
      <p>
        A presente Política está dividida da seguinte forma para facilitar a sua
        compreensão:
      </p>
      <ol css={styles.content.terms.list}>
        <li>
          1. Quais informações a <strong>SMARTRIPS LTDA</strong> coleta
        </li>
        <li>
          2. Como a <strong>SMARTRIPS LTDA</strong> usa as informações coletadas
        </li>
        <li>
          3. Como, quando e com quem a <strong>SMARTRIPS LTDA</strong>{" "}
          compartilha suas informações
        </li>
        <li>4. Quais direitos você tem sobre as informações que coletamos</li>
        <li>
          5. Como a <strong>SMARTRIPS LTDA</strong> protege suas informações
        </li>
        <li>6. Atualizações dessa política de privacidade</li>
        <li>7. Lei aplicável</li>
      </ol>
      <p>
        Este documento deve ser lido em conjunto com o nosso Termos de Uso
        (https://smartrips.com.br/), que contém uma visão geral da nossa
        plataforma. Caso tenha dúvidas ou precise tratar de qualquer assunto
        relacionado a esta Política, entre em contato conosco através do e-mail
        artoni@smartrips.com.br.
      </p>
      <h2 css={styles.content.terms.heading}>1. INFORMAÇÕES QUE COLETAMOS</h2>
      <p>Nós coletamos os seguintes tipos de informações:</p>
      <h3 css={styles.content.terms.subheading}>
        1.1. Informações que você nos fornece.
      </h3>
      <p>Isso inclui:</p>
      <ul css={styles.content.terms.list}>
        <li>
          Dados de cadastro. Quando você se cadastra na{" "}
          <strong>SMARTRIPS LTDA</strong>, você nos fornece informações como
          NOME; RG; CPF; NÚMERO DO PASSAPORTE (DEPENDE DO TRAJETO); TELEFONE
          (OPCIONAL).
        </li>
      </ul>
      <h3 css={styles.content.terms.subheading}>
        1.2. Informações geradas quando você usa nossos serviços.
      </h3>
      <p>Nós coletamos as seguintes informações geradas:</p>
      <ul css={styles.content.terms.list}>
        <li>
          <u>Registros de acesso.</u> A <strong>SMARTRIPS LTDA</strong> coleta
          automaticamente registros de acesso a aplicação, que incluem o
          endereço IP, com data e hora, utilizado para acessar a{" "}
          <strong>SMARTRIPS LTDA</strong>. Esses dados são de coleta
          obrigatória, de acordo com a Lei 12.965/2014, mas somente serão
          fornecidos para terceiros com a sua autorização expressa ou por meio
          de demanda judicial.{" "}
        </li>
        <li>
          <u>Dados de uso.</u> Nós coletamos informações sobre suas interações
          na <strong>SMARTRIPS LTDA</strong>, como sua navegação, as páginas ou
          outro conteúdo que você acessa ou cria, suas buscas, participações em
          pesquisas ou fóruns e outras ações.{" "}
        </li>
        <li>
          <u>Dados de localização do dispositivo conectado.</u> Nós coletamos
          dados de localização, que são coletados através do seu equipamento ou
          dispositivo conectado, caso você autorize. A sua autorização para
          compartilhar a sua localização poderá ser revogada a qualquer momento.
          Todavia, isso poderá inativar algumas das funcionalidades da
          plataforma.{" "}
        </li>
        <li>
          <u>Dados de pagamento.</u> Quando você realiza pagamentos na{" "}
          <strong>SMARTRIPS LTDA</strong>, poderão ser armazenados dados do
          pagamento, como a data e hora, o valor e outros detalhes da transação,
          que poderão ser utilizados inclusive para fins de prevenção à fraude.
          Desta forma, poderemos lhe proporcionar um ambiente seguro e adequado
          para você realizar as suas transações.{" "}
        </li>
        <li>
          <u>
            Comunicações com a <strong>SMARTRIPS LTDA</strong>
          </u>
          . Quando você se comunica com a <strong>SMARTRIPS LTDA</strong>,
          coletamos informações sobre sua comunicação, incluindo metadados como
          data, IP e hora das comunicações e todo o seu conteúdo, assim como
          qualquer informação que você escolha fornecer.{" "}
        </li>
        <li>
          <u>Cookies e tecnologias semelhantes.</u> Nós utilizamos cookies, que
          são arquivos de texto gerados e armazenados no seu navegador ou
          aparelho por sites, aplicativos e anúncios online. Os cookies poderão
          ser utilizados para as seguintes finalidades: autenticar usuários,
          lembrar preferências e configurações do usuário, entender os
          comportamentos e interesses do usuário.
        </li>
      </ul>
      <h3 css={styles.content.terms.subheading}>
        1.3. Informações de outras fontes.
      </h3>
      <p>Isso pode incluir: </p>
      <ul css={styles.content.terms.list}>
        <li>
          <u>Dados coletados de outras plataformas.</u> A{" "}
          <strong>SMARTRIPS LTDA</strong> poderá interagir com outras
          plataformas e outros serviços, como meios de pagamento. Alguns desses
          serviços podem nos fornecer informações sobre você, aos quais
          coletaremos para lhe proporcionar uma melhor experiência e melhorar
          cada vez mais os nossos serviços e lhe oferecer novas funcionalidades,
          bem como oferecer as melhores ofertas de viagens possíveis.
        </li>
      </ul>
      <h2 css={styles.content.terms.heading}>
        2. COMO USAMOS SUAS INFORMAÇÕES
      </h2>
      <p>
        Não custa lembrar, prezamos muito pela sua privacidade. Por isso, todos
        os dados e informações sobre você são tratadas como confidenciais, e
        somente as usaremos para os fins aqui descritos e autorizados por você,
        principalmente para que você possa utilizar a{" "}
        <strong>SMARTRIPS LTDA</strong> de forma plena, visando sempre melhorar
        a sua experiência como usuário.
      </p>
      <h3 css={styles.content.terms.subheading}>2.1. Usos autorizados</h3>
      <p> Desta forma, poderemos utilizar seus dados para:</p>
      <ul css={styles.content.terms.list}>
        <li>
          Permitir que você acesse e utilize todas as funcionalidades da{" "}
          <strong>SMARTRIPS LTDA</strong>;{" "}
        </li>
        <li>
          Enviar a você mensagens a respeito de suporte ou serviço, como
          alertas, notificações e atualizações;{" "}
        </li>
        <li>
          Nos comunicar com você sobre produtos, serviços, promoções, notícias,
          atualizações, eventos e outros assuntos que você possa ter interesse;{" "}
        </li>
        <li>Analisar o tráfego dos usuários em nossas aplicações; </li>
        <li>
          Realizar publicidade direcionada conforme seus gostos, interesses e
          outras informações coletadas;{" "}
        </li>
        <li>
          Personalizar o serviço para este adequar cada vez mais aos seus gostos
          e interesses;{" "}
        </li>
        <li>
          Detecção e prevenção de fraudes, spam e incidentes de segurança;{" "}
        </li>
        <li>
          Verificar ou autenticar as informações fornecidas por você, inclusive
          comparando a dados coletados de outras fontes;{" "}
        </li>
        <li>
          Entender melhor o comportamento do usuário e construir perfis
          comportamentais;
        </li>
        <li>
          Para qualquer fim que você autorizar no momento da coleta de dados;{" "}
        </li>
        <li>Cumprir obrigações legais.</li>
      </ul>
      <p>
        Eventualmente, poderemos utilizar dados para finalidades não previstas
        nesta política de privacidade, mas estas estarão dentro das suas
        legítimas expectativas.{" "}
        <strong>
          O eventual uso dos seus dados para finalidades que não cumpram com
          essa prerrogativa será feito mediante sua autorização prévia.
        </strong>
      </p>
      <h3 css={styles.content.terms.subheading}>2.2. Exclusão dos dados </h3>
      <p>
        Todos os dados coletados serão excluídos de nossos servidores quando
        você assim requisitar, por procedimento gratuito e facilitado, ou quando
        estes não forem mais necessários ou relevantes para lhe oferecermos os
        nossos serviços, salvo se houver qualquer outra razão para a sua
        manutenção, como eventual obrigação legal de retenção de dados ou
        necessidade de preservação destes para resguardo de direitos da
        SMARTRIPS LTDA.
      </p>
      <p>
        Nos casos em que você solicite a exclusão dos seus dados, manteremos os
        seus dados por 24 (vinte e quatro) meses contados a partir da sua
        solicitação de exclusão
      </p>
      <h3 css={styles.content.terms.subheading}>2.3. Monitoramento</h3>
      <p>
        A <strong>SMARTRIPS LTDA</strong> se reserva no direito de monitorar
        toda a plataforma, principalmente para assegurar que as regras descritas
        em nosso Termos de Uso estão sendo observadas, ou ainda se não há
        violação ou abuso das leis aplicáveis.
      </p>
      <h3 css={styles.content.terms.subheading}>2.4. Exclusão de usuário</h3>
      <p>
        A <strong>SMARTRIPS LTDA</strong> se reserva no direito de excluir
        determinado usuário, independente do tipo que for, caso a presente
        Política ou os Termos de Uso não sejam respeitados. Como prezamos pelo
        bom relacionamento com os usuários, reconhecemos que têm o direito de
        buscar entender os motivos e até contestá-los, o que pode ser feito pelo
        seguinte e-mail: artoni@smartrips.com.br.
      </p>
      <h2 css={styles.content.terms.heading}>
        3. COMPARTILHAMENTO DAS INFORMAÇÕES
      </h2>
      <p>
        A <strong>SMARTRIPS LTDA</strong> pode compartilhar as informações que
        coleta com parceiros comerciais e anunciantes, localizados tanto no
        Brasil como nos seguintes países: América do Norte; Europa; Ásia. O
        compartilhamento das informações ocorrerá de forma anônima, sempre que
        for possível, visando preservar a sua privacidade. Por meio deste
        documento, você autoriza expressamente tais compartilhamentos.
      </p>
      <p>
        A <strong>SMARTRIPS LTDA</strong> permite que outras empresas e redes de
        anúncio anunciem em nossa plataforma por meio de diferentes tecnologias.
        Portanto, você poderá eventualmente receber diretamente em seu
        aplicativo anúncios, conteúdo e links exibidos de forma personalizada,
        de acordo com seus interesses e comportamentos em nossa plataforma ou em
        outros serviços com os quais você interaja. Para tanto, dados podem ser
        compartilhados entre a <strong>SMARTRIPS LTDA</strong> e essas outras
        empresas, principalmente identificadores únicos, endereços IP, cookies e
        scripts java, que podem ser utilizados para medir a eficiência da
        publicidade online. Por meio deste documento, você autoriza
        expressamente tais compartilhamentos. Todavia, a{" "}
        <strong>SMARTRIPS LTDA</strong> não se responsabiliza pelos atos,
        anúncios e conteúdo gerados por nossos parceiros comerciais e redes de
        anúncio, e esta Política não se aplica a eles, uma vez que a SMARTRIPS
        LTDA não as controla. Sugerimos ter acesso e ler as políticas de
        privacidade das empresas com as quais você eventualmente interaja.
      </p>
      <p>
        Todos os dados, informações e conteúdos sobre você podem ser
        considerados ativos no caso de negociações em que a{" "}
        <strong>SMARTRIPS LTDA</strong> fizer parte. Portanto, nos reservamos no
        direito de, por exemplo, incluir seus dados dentre os ativos da empresa
        caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio
        desta Política você concorda e está ciente desta possibilidade.{" "}
      </p>
      <p>
        A <strong>SMARTRIPS LTDA</strong> se reserva no direito de fornecer seus
        dados e informações sobre você, incluindo interações suas, caso seja
        requisitado judicialmente para tanto, ato necessário para que a empresa
        esteja em conformidade com as leis nacionais, ou caso você autorize
        expressamente.{" "}
      </p>
      <h2 css={styles.content.terms.heading}>4. DIREITOS DOS TITULARES</h2>
      <p>
        Você sempre poderá optar em não divulgar seus dados para nós, mas tenha
        em mente que alguns desses dados podem ser necessários para utilizar as
        funcionalidades de nossas aplicações. Independente disso, você sempre
        possuirá direitos relativos à privacidade e à proteção dos seus dados
        pessoais.
      </p>
      <p>
        Dessa forma, abaixo resumimos todos os direitos que você tem sob as leis
        setoriais brasileiras relativas a proteção de dados e a Lei Geral de
        Proteção de Dados (“LGPD”), quais sejam:
      </p>{" "}
      <ol css={styles.content.terms.list}>
        <li>
          <strong>4.1. Direito de acesso.</strong> Este direito permite que você
          possa requisitar e receber uma cópia dos dados pessoais que possuímos
          sobre Você.
        </li>
        <li>
          <strong>4.2. Direito de retificação.</strong> Este direito permite que
          você, a qualquer momento, possa solicitar a correção e/ou retificação
          dos seus dados pessoais, caso identifique que alguns deles estão
          incorretos. Contudo, para ser efetivada essa correção, teremos que
          checar a validade dos dados que você nos fornece. Você pode retificar
          diretamente alguns dos seus dados pessoais através do e-mail
          artoni@smartrips.com.br.{" "}
        </li>
        <li>
          <strong>4.3. Direito de exclusão.</strong> Este direito permite que
          você possa nos solicitar a exclusão dos dados pessoais que possuímos
          sobre você. Todos os dados coletados serão excluídos de nossos
          servidores quando você assim requisitar ou quando estes não forem mais
          necessários ou relevantes para lhe oferecermos nossos serviços, salvo
          se houver qualquer outra razão para a sua manutenção, como eventual
          obrigação legal de retenção de dados ou necessidade de preservação
          destes para resguardo de direitos da <strong>SMARTRIPS LTDA</strong>.
          Para alterar suas informações pessoais ou excluí-las do nosso banco de
          dados, basta enviar um e-mail para artoni@smartrips.com.br.{" "}
        </li>
        <li>
          <strong>4.4. Direito de oposição ao processamento.</strong> Você
          também tem o direito de contestar onde e em que contexto estamos
          tratando seus dados pessoais para diferentes finalidades. Em
          determinadas situações, podemos demonstrar que temos motivos legítimos
          para tratar seus dados, os quais se sobrepõem aos seus direitos, caso,
          por exemplo, sejam essenciais para o fornecimento de nossas
          aplicações.{" "}
        </li>
        <li>
          <strong>
            4.5. Direito de solicitar anonimização, bloqueio ou eliminação.
          </strong>{" "}
          Este direito permite que você nos peça para suspender o processamento
          de seus dados pessoais nos seguintes cenários: (a) se você quiser que
          nós estabeleçamos a precisão dos dados; (b) quando você precisar que
          sejam mantidos os dados mesmo se não precisarmos mais deles, conforme
          necessário, para estabelecer, exercer ou defender reivindicações
          legais; ou (c) se você se opôs ao uso de seus dados, mas nesta
          hipótese precisamos verificar se temos motivos legítimos para usá-los.
        </li>
        <li>
          <strong>4.6. Direito à portabilidade.</strong> Forneceremos a você, ou
          a terceiros que você escolheu, seus dados pessoais em formato
          estruturado e interoperável.
        </li>
        <li>
          <strong>4.7. Direito de retirar o seu consentimento.</strong> Você tem
          o direito de retirar o seu consentimento em relação aos termos desta
          Política de Privacidade. No entanto, isso não afetará a legalidade de
          qualquer processamento realizado anteriormente. Se você retirar o seu
          consentimento, talvez não possamos fornecer determinados serviços.
        </li>
        <li>
          <strong>4.8. Direito a revisão de decisões automatizadas.</strong>
          Você também tem o direito de solicitar a revisão de decisões tomadas
          unicamente com base em tratamento automatizado de seus dados pessoais
          que afetem seus interesses, incluídas as decisões destinadas a
          definição de perfis pessoais, profissionais, de consumo e de crédito
          e/ou os aspectos de sua personalidade. Talvez seja necessário
          solicitar informações específicas suas para nos ajudar a confirmar sua
          identidade e garantir seu direito de acessar seus dados pessoais (ou
          de exercer seus outros direitos). Esta é uma medida de segurança para
          garantir que os dados pessoais não sejam divulgados a qualquer pessoa
          que não tenha direito de recebê-los. Podemos também contatá-lo para
          obter mais informações em relação à sua solicitação, a fim de acelerar
          nossa resposta. Tentamos responder a todas as solicitações legítimas
          dentro de 5 dias úteis. Ocasionalmente, pode levar mais de 5 dias se
          sua solicitação for particularmente complexa ou se você tiver feito
          várias solicitações. Neste caso, iremos comunicá-lo e mantê-lo
          atualizado sobre o andamento da sua solicitação. Caso Você tenha
          alguma dúvida sobre essas questões e sobre como você pode exercer
          esses direitos, fique à vontade para entrar em contato conosco no
          e-mail artoni@smartrips.com.br.
        </li>
      </ol>
      <h2 css={styles.content.terms.heading}>5. SEGURANÇA DAS INFORMAÇÕES</h2>
      <p>
        Todos os seus dados são confidenciais e somente as pessoas com as
        devidas autorizações terão acesso a eles. Qualquer uso destes estará de
        acordo com a presente Política. A <strong>SMARTRIPS LTDA</strong>{" "}
        empreenderá todos os esforços razoáveis de mercado para garantir a
        segurança dos nossos sistemas e dos seus dados. Nossos servidores estão
        localizados em diferentes locais para garantir estabilidade e segurança,
        e somente podem ser acessados por meio de canais de comunicação
        previamente autorizados.
      </p>
      <p>
        Todas as suas informações serão, sempre que possível, criptografadas,
        caso não inviabilizem o seu uso pela plataforma. A qualquer momento você
        poderá requisitar cópia dos seus dados armazenados em nossos sistemas.
        Manteremos os dados e informações somente até quando estas forem
        necessárias ou relevantes para as finalidades descritas nesta Política,
        ou em caso de períodos pré-determinados por lei, ou até quando estas
        forem necessárias para a manutenção de interesses legítimos da{" "}
        <strong>SMARTRIPS LTDA</strong>.
      </p>
      <p>
        A <strong>SMARTRIPS LTDA</strong> considera a sua privacidade algo
        extremamente importante e fará tudo que estiver ao alcance para
        protegê-la. Todavia, não temos como garantir que todos os dados e
        informações sobre você em nossa plataforma estarão livres de acessos não
        autorizados, principalmente caso haja compartilhamento indevido das
        credenciais necessárias para acessar o nosso aplicativo. Portanto, você
        é o único responsável por manter sua senha de acesso em local seguro e é
        vedado o compartilhamento desta com terceiros. Você se compromete a
        notificar a <strong>SMARTRIPS LTDA</strong> imediatamente, através de
        meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem
        como o acesso não autorizado por terceiros a esta.
      </p>
      <h2 css={styles.content.terms.heading}>
        6. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE
      </h2>
      <p>
        {" "}
        A <strong>SMARTRIPS LTDA</strong> se reserva no direito de alterar essa
        Política quantas vezes forem necessárias, visando fornecer a você mais
        segurança, conveniência, e melhorar cada vez mais a sua experiência. É
        por isso que é muito importante acessar nossa Política periodicamente.
        Para facilitar, indicamos no início do documento a data da última
        atualização. Caso sejam feitas alterações relevantes que ensejem novas
        autorizações suas, publicaremos uma nova política de privacidade,
        sujeita novamente ao seu consentimento.
      </p>
      <h2 css={styles.content.terms.heading}>7. LEI APLICÁVEL</h2>
      <p>
        Este documento é regido e deve ser interpretado de acordo com as leis da
        República Federativa do Brasil. Fica eleito o Foro da Comarca de São
        Paulo, São Paulo, como o competente para dirimir quaisquer questões
        porventura oriundas do presente documento, com expressa renúncia a
        qualquer outro, por mais privilegiado que seja.
      </p>
    </>
  );
};
