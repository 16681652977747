import React from "react";

import { Link } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { Icon } from "~/apps/shared/components/icon/icon";
import { capabilities } from "~/apps/shared/constants/enums";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { styles } from "./styles";

type Props = {
  handleOpenUserProfileDrawer: () => void;
};

export const NavBarMobile: React.FC<Props> = ({
  handleOpenUserProfileDrawer,
}) => {
  const { approvalsCount } = useApplication();
  const { user } = useUser();

  return (
    <div css={styles.root}>
      {user && user.isHidden() ? (
        <span css={styles.client}>
          <strong>{user.getClientName()} </strong>- Esta é a empresa em que você
          está conectado.
        </span>
      ) : null}
      <div css={styles.links.root}>
        <CapabilityProtectedRender
          atLeastOne
          capabilities={[capabilities.SEARCH, capabilities.SELF_SEARCH]}
        >
          <NavBarMobileLink
            icon={<Icon size={20} use="airplane-taking-off" />}
            label="Início"
            to="/"
          />
        </CapabilityProtectedRender>
        <NavBarMobileLink
          aliases={["/travels"]}
          icon={<Icon size={20} use="baggage" />}
          label="Viagens"
          to="/trips"
        />
        <CapabilityProtectedRender capabilities={[capabilities.APPROVALS]}>
          <NavBarMobileLink
            icon={<Icon size={20} use="thumbs-up" />}
            label={`Aprovações${approvalsCount ? ` (${approvalsCount})` : ""}`}
            to="/approvals"
          />
        </CapabilityProtectedRender>
        <CapabilityProtectedRender capabilities={[capabilities.EXPENSES]}>
          <NavBarMobileLink
            icon={<Icon size={20} use="dollar" />}
            label="Despesas"
            to="/expenses"
          />
        </CapabilityProtectedRender>
        <button
          css={styles.links.button}
          onClick={() => {
            handleOpenUserProfileDrawer();
          }}
        >
          <Icon size={20} use="person" />
          <span>Perfil</span>
        </button>
      </div>
    </div>
  );
};

type NavBarMobileLinkProps = {
  aliases?: string[];
  icon: React.ReactNode;
  label: string;
  to: string;
};

export const NavBarMobileLink: React.FC<NavBarMobileLinkProps> = ({
  aliases,
  icon,
  label,
  to,
}) => {
  const { location } = useLocation();

  const isCurrent = location.pathname === to;
  const isPartiallyCurrent =
    location.pathname.startsWith(to) ||
    (aliases
      ? aliases.some((alias) => location.pathname.startsWith(alias))
      : false);

  const active = isCurrent || (isPartiallyCurrent && to !== "/");

  return (
    <Link to={to}>
      <div css={styles.links.link({ active })}>
        {icon}
        <span>{label}</span>
      </div>
    </Link>
  );
};
