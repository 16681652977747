import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  additional: {
    root: ({ error }: { error: boolean }) =>
      css({
        color: error ? "#DB4332" : "#5E6879",
        fontSize: "0.9rem",
        margin: "0.5rem 0 0.5rem 1rem",
      }),
  },
  label: {
    root: ({ hasError }: { hasError?: boolean }) =>
      css({
        color: hasError ? theme.colors.pink[500] : theme.colors.gray[500],
        display: "inline-block",
        fontWeight: 600,
        lineHeight: "28px",
        marginBottom: "8px",
      }),
  },
};
