import {
  GetTravelApprovalHistoryResponse,
  GetTravelApprovalHistoryResponseApprover,
} from "../dtos/approvals.dto";
import {
  TravelApprovalHistory,
  TravelApprovalHistoryRecordApprover,
} from "../models/approvals.model";

export const parseTravelApprovalHistory = (
  dto: GetTravelApprovalHistoryResponse,
) => {
  const travelApprovalHistory: TravelApprovalHistory = {
    history: dto.history.map((history) => ({
      approvalProcessToken: history.approval_process_token,
      approvalRequestToken: history.approval_request_token,
      ...(history.approver && {
        approver: parseTravelApprovalHistoryRecordApprover(history.approver),
      }),
      ...(history.approver2 && {
        approver2: parseTravelApprovalHistoryRecordApprover(history.approver2),
      }),
      canceled: history.canceled,
      closedAt: history.closed_at,
      createdAt: history.created_at,
      firstApprovedAt: history.first_approved_at,
      firstAttempt: history.first_attempt,
      requestMessage: history.request_message,
      reservedUntil: history.reserved_until,
      responseMessage: history.response_message,
      responseMessage2: history.response_message2,
      status: history.status,
      travelerToken: history.traveler_token,
      travelToken: history.travel_token,
    })),
    isPendingApproval: dto.approvalPending,
  };

  return travelApprovalHistory;
};

const parseTravelApprovalHistoryRecordApprover = (
  approver: GetTravelApprovalHistoryResponseApprover,
) => {
  const travelApprovalHistoryRecordApprover: TravelApprovalHistoryRecordApprover = {
    email: approver.email,
    firstName: approver.first_name,
    fullName: approver.full_name
      ? approver.full_name
      : `${approver.first_name} ${approver.last_name}`,
    lastName: approver.last_name,
    stage: approver.stage,
    userToken: approver.user_token,
  };

  return travelApprovalHistoryRecordApprover;
};
