import { useQuery } from "react-query";

import * as creditsApi from "~/apps/corporate/apis/credits.api";
import { UserModel } from "~/apps/corporate/models/user.model";

export const useClientUserCredits = (user: UserModel | null) => {
  const {
    data: userCredits,
    error: errorClientUserCredits,
    isLoading: isLoadingClientUserCredits,
  } = useQuery(
    ["user", "credits"],
    async () => {
      if (!user) {
        return;
      }

      return await creditsApi.getClientUserCredits(user.getUserToken());
    },
    {
      enabled: !!user,
    },
  );

  return {
    errorClientUserCredits,
    isLoadingClientUserCredits,
    userCredits,
  };
};
