import React from "react";

import MuiTabs from "@material-ui/core/Tabs";

import { styles } from "./Tabs.styles";
import TabsItem from "./TabsItem";

type Props<T = string> = React.ComponentPropsWithoutRef<typeof MuiTabs> & {
  onChange: (e: React.ChangeEvent<{}>, value: T) => void;
  value: T;
};

export default function Tabs<T>({
  children,
  classes,
  onChange,
  value,
  ...props
}: Props<T>) {
  return (
    <MuiTabs
      classes={{
        indicator: styles.indicator,
        scroller: styles.scroller,
        ...classes
      }}
      onChange={onChange}
      value={value}
      {...props}
    >
      {children}
    </MuiTabs>
  );
}

Tabs.Item = TabsItem;
