import { useEffect, useState } from "react";

export const useDrawerTransition = (
  isOpen: boolean,
  onClose: () => void,
  timeoutOnClose = 300,
) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);

    const closeTimeout = setTimeout(() => {
      onClose();
    }, timeoutOnClose);

    return () => {
      clearTimeout(closeTimeout);
    };
  };

  return {
    handleClose,
    isVisible,
  };
};
