import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Text
} from "recharts";
import * as utils from "@helpers";
import { colors } from "@theme";
import { FLIGHT_CARRIERS_COLORS } from "~/constants";

export const COLOR_BY_INDEX = {
  ["0"]: colors.scooter,
  ["1"]: colors.jungleGreen,
  ["2"]: colors.cerulean,
  ["3"]: colors.saffron,
  ["4"]: colors.brinkPink,
  ["5"]: colors.purple,
  ["6"]: colors.fireBush,
  ["7"]: colors.sunglo,
  ["8"]: colors.dodgerBlue,
  ["9"]: colors.punch,
  ["10"]: colors.tangerine
};

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value
  } = props;

  return (
    <g>
      <Text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill={fill}
        width={160}
        style={{ textTransform: "capitalize", fontSize: 14 }}
      >
        {utils.capitalizeAllFirstLetters(payload.provider)}
      </Text>
      <Text
        x={cx}
        y={cy}
        dy={16}
        textAnchor="middle"
        fill="#999"
        width={160}
        style={{ fontSize: 12 }}
      >
        {`(${utils.toCurrency(value)})`}
      </Text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const CreditsPieChart = ({ creditsPerCompany }) => {
  const [index, setIndex] = useState(0);

  const onPieEnter = (data, i) => {
    setIndex(i);
  };
  const hasSomeDataToShow = creditsPerCompany.length;

  if (!hasSomeDataToShow) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 330
        }}
      >
        <p>Nenhum dado a ser mostrado</p>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={220}>
      <PieChart>
        <Pie
          activeIndex={index}
          activeShape={renderActiveShape}
          data={creditsPerCompany}
          dataKey="value"
          innerRadius={80}
          outerRadius={100}
          onMouseEnter={onPieEnter}
        >
          {creditsPerCompany.map((entry, i) => (
            <Cell
              key={entry.type}
              fill={
                FLIGHT_CARRIERS_COLORS[entry.provider] ||
                COLOR_BY_INDEX[i] ||
                "darkgrey"
              }
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { CreditsPieChart };
