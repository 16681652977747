import React from "react";

import { Step as MuiStep, StepLabel as MuiStepLabel } from "@material-ui/core";

type Props = React.ComponentProps<typeof MuiStep>;

export const Step: React.FC<Props> = ({ children, ...props }) => {
  return <MuiStep {...props}>{children}</MuiStep>;
};

type StepLabelProps = Omit<
  React.ComponentProps<typeof MuiStepLabel>,
  "StepIconComponent"
> & {
  icon?: React.ElementType;
};

export const StepLabel: React.FC<StepLabelProps> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <MuiStepLabel StepIconComponent={icon} {...props}>
      {children}
    </MuiStepLabel>
  );
};
