import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  }),
  title: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "4px",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "1.25",
    }),
  },
};
