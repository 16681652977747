import React, { useContext, useEffect, useState } from "react";

import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import { fixDecimalPlaces } from "~/utils/number.utils";
import { css } from "emotion";
import { withFormik, FormikErrors, FormikProps } from "formik";
import { Moment } from "moment";
import moment from "moment";

import { Box } from "@toolkit";

import { defaultTheme } from "@assets/styles/theme";

import {
  CurrencyCode,
  CurrencySymbol,
  ExpenseCategory
} from "~/constants/enums";

import { EXPENSES_CATEGORIES } from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { Expense } from "@models/expense.model";

import { ImageFile } from "~/types";

import { StOutlinedButton, StContainedButton } from "@shared/buttons";
import DrawerHeader from "@shared/DrawerHeader";
import { DrawerSpinner } from "@shared/DrawerSpinner";
import { ImageViewer } from "@shared/ImageViewer";
import { Input, CurrencyInput } from "@shared/inputs";
import { Row } from "@shared/layout/index";

import { LocationAutocomplete } from "@components/shared-logic/LocationAutocomplete/LocationAutocomplete";

import { DateInput } from "../shared/DateInput/DateInput";
import {
  ExpenseDrawerProvider,
  ExpenseDrawerContext
} from "./ExpenseDrawer.context";
import { FormValues } from "./ExpenseDrawer.types";
import { DropzoneInput } from "./form-inputs/DropzoneInput/DropzoneInput";
import { PaymentMethodSelect } from "./form-inputs/PaymentMethodSelect";
import { FlightExpenseForm } from "./forms/FlightExpenseForm";
import { HotelExpenseForm } from "./forms/HotelExpenseForm";
import { KilometerExpenseForm } from "./forms/KilometerExpenseForm";
import { SuggestionDialog } from "./SuggestionDialog";

const styles = {
  drawerPaper: css({
    ["@media (min-width: 768px)"]: {
      maxWidth: 500
    }
  }),
  drawerBody: css({
    padding: "2rem 2rem 0 2rem",
    height: "calc(100vh - 60px)",
    width: 480,
    ["@media (max-height: 992px)"]: {
      height: "auto"
    },
    ["@media (max-width: 500px)"]: {
      padding: "2rem 1rem",
      width: "100vw"
    }
  }),
  buttonsDiv: css({
    display: "flex",
    gap: "1rem",
    width: "100%",
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    ["@media (max-width: 500px)"]: {
      padding: "1rem"
    }
  }),
  button: css({
    padding: ".5rem 1rem",
    minWidth: 160,
    ["@media (max-width: 768px)"]: {
      minWidth: 150
    }
  }),
  cancelButton: css({
    padding: ".5rem 1rem"
  }),
  valueInput: css({
    width: "60%",
    marginLeft: "1.2rem"
  }),
  input: css({
    paddingBottom: "1.5rem"
  }),
  receiptTitle: css({
    fontWeight: "bold",
    marginBottom: "1rem",
    color: defaultTheme.textColor
  }),
  noReciptDiv: css({
    width: "100%",
    height: 180,
    justifyContent: "center",
    alignItems: "center"
  }),
  mealTypeSelect: css({
    maxWidth: "44%",
    width: "100%"
  }),
  participantsQuantitySelect: css({
    width: "100%"
  }),
  currencySelect: css({
    width: "40%"
  }),
  exchangeRateInput: css({
    width: "55%"
    // marginLeft: "24px"
  }),
  convertedValueInput: css({
    width: "45%",
    marginLeft: "1.2rem"
  })
};

const PARTICIPANTS_QUANTITY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const VALUE_PATTERN = /([A-z]*(:?| ) ?)?(R\$ ?)?\d{1,3}(\.?\d{3})*,\d{2}$/gm;

const formatMatchingValue = (value: string) => {
  const formattedString = value
    .replace(/([A-z $:]*)/g, "")
    .replace(".", "")
    .replace(",", ".");

  return parseFloat(formattedString);
};

interface Props {
  open: boolean;
  readOnly?: boolean;
  selectedExpense: Expense | null;
  selectedReceipt: ImageFile | null;
  reportToken?: string;
  travelerToken?: string;
  handleClose: () => void;
  onAfterSave?: () => void;
}

type OwnProps = Props & FormikProps<FormValues>;

const ExpenseDrawer = ({
  readOnly,
  selectedExpense = null,
  handleClose,
  onAfterSave,
  values,
  isValid,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched
}: OwnProps) => {
  const {
    visibleExpenseCategoriesOptions,
    setSelectedExpense,
    handleSaveExpense,
    isLoading,
    isSubmitting
  } = useContext(ExpenseDrawerContext);

  const [matchedValue, setMatchedValue] = useState<number | null>(null);
  // const { recognizeImage } = useOCRWorker({
  //   pattern: VALUE_PATTERN,
  //   matchFormatter: formatMatchingValue
  // });

  // useEffect(() => {
  //   const analyzeImageForValue = async () => {
  //     try {
  //       const matchingValue = await recognizeImage(values.receipt!);

  //       if (values.value && values.value !== matchingValue) {
  //         setMatchedValue(matchingValue);
  //       } else {
  //         setFieldValue("value", matchingValue);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (values.receipt && !readOnly) {
  //     analyzeImageForValue();
  //   }
  // }, [values.receipt]);

  useEffect(() => {
    if (selectedExpense) {
      setSelectedExpense(selectedExpense);
    }

    return () => {
      setSelectedExpense(null);
    };
  }, [selectedExpense, setSelectedExpense]);

  const handleSetDate = (fieldName: string) => (date: Moment | null) => {
    setFieldValue(fieldName, date);
  };

  const handleInputTypeChange = (
    e: React.ChangeEvent<React.InputHTMLAttributes<HTMLInputElement>>
  ) => {
    handleChange(e);
    const category = visibleExpenseCategoriesOptions.find(
      option => option.expenseCategoryToken === e.target.value
    );
    setFieldValue("expenseCategory", category!.expenseCategory);
  };

  const handleChangePrice = (value: number) => {
    setFieldValue("value", value);
  };

  const handleChangeExchangeRate = (value: number) => {
    setFieldValue("exchangeRate", value);
  };

  const handleLocationChange = (address: any) => {
    setFieldValue("locationSearch", address ? address.formattedAddress : "");
  };

  const handleSelectFile = (imageFile: ImageFile) => {
    setFieldValue("receipt", imageFile);
  };

  const setTouched = (fieldName: string) => () => {
    setFieldTouched(fieldName, true);
  };

  const isBrazilianCurrency = () => {
    return values.currency === CurrencyCode.BRL;
  };

  const isKilometerCategory = () => {
    return values.expenseCategory === ExpenseCategory.KILOMETER;
  };

  const handleSubmit = () => {
    const expenseFromValues = { ...values };

    //isBrazilianCurrency()
    //Isso é feito para garantir que ao altera a moeda para BRL, a taxa de conversão é 1.
    if (isBrazilianCurrency()) {
      expenseFromValues.exchangeRate = 1;
    }

    //isKilometerCategory()
    //Isso é feito pois a categoria Kilometer só pode ser criada/editada na moeda BRL
    if (isKilometerCategory() && !isBrazilianCurrency()) {
      expenseFromValues.currency = CurrencyCode.BRL;
      expenseFromValues.exchangeRate = 1;
    }

    handleSaveExpense(expenseFromValues, onAfterSave);
  };

  const dateOfBuyValidation = (day: Moment) => {
    const now = moment();
    return day.isAfter(now, "day");
  };

  const getFormContainerEl = () => {
    return document.getElementById("form-container");
  };

  const handleAcceptSuggestion = () => {
    setFieldValue("value", matchedValue);
    setMatchedValue(null);
  };

  const handleCloseSuggestionDialog = () => {
    setMatchedValue(null);
  };

  const renderImageInpunt = () => {
    if (readOnly && values.receipt) {
      return (
        <div>
          <p className={styles.receiptTitle}>Comprovante</p>
          <ImageViewer imageUrl={URL.createObjectURL(values.receipt)} />
        </div>
      );
    } else if (readOnly && !values.receipt) {
      return <Row className={styles.noReciptDiv}>Sem comprovante</Row>;
    } else if (values.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER) {
      return (
        <div style={{ paddingBottom: "1.5rem" }}>
          <DropzoneInput
            imageUrl={
              values.receipt ? URL.createObjectURL(values.receipt) : null
            }
            handleChange={handleSelectFile}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const isCustomCategory =
    values.expenseCategory !== EXPENSES_CATEGORIES.FLIGHT &&
    values.expenseCategory !== EXPENSES_CATEGORIES.HOTEL &&
    values.expenseCategory !== EXPENSES_CATEGORIES.APARTMENT &&
    values.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER;

  const convertedValue =
    values.exchangeRate && values.value
      ? fixDecimalPlaces(values.value * values.exchangeRate)
      : null;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <DrawerHeader
        title={
          selectedExpense
            ? readOnly
              ? "Visualizar despesa"
              : "Editar despesa"
            : "Nova despesa"
        }
        handleClose={handleClose}
      />
      <SuggestionDialog
        isOpen={!!matchedValue}
        suggestionValue={matchedValue}
        container={getFormContainerEl}
        handleAcceptSuggestion={handleAcceptSuggestion}
        handleCloseDialog={handleCloseSuggestionDialog}
      />
      {isLoading ? (
        <Box width={["100%", 500]} height="100%">
          <DrawerSpinner visible={isLoading} />
        </Box>
      ) : (
        <div className={styles.drawerBody}>
          <Input
            id="expenseCategoryToken"
            name="expenseCategoryToken"
            label="Tipo"
            disabled={isSubmitting || readOnly}
            onChange={handleInputTypeChange}
            onBlur={handleBlur}
            value={values.expenseCategoryToken}
            containerClasses={styles.input}
            select={true}
            error={errors.expenseCategoryToken && touched.expenseCategoryToken}
            errorMessage={
              errors.expenseCategoryToken &&
              touched.expenseCategoryToken &&
              errors.expenseCategoryToken
            }
          >
            {visibleExpenseCategoriesOptions.map(category => (
              <MenuItem
                value={category.expenseCategoryToken}
                key={category.expenseCategoryToken + "_" + category.name}
              >
                {category.name}
              </MenuItem>
            ))}
          </Input>
          <Input
            id="description"
            name="description"
            label="Descrição"
            disabled={isSubmitting || readOnly}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            containerClasses={styles.input}
            error={errors.description && touched.description}
            errorMessage={
              errors.description && touched.description && errors.description
            }
          />
          {values.expenseCategory === EXPENSES_CATEGORIES.BREAKFAST ||
          values.expenseCategory === EXPENSES_CATEGORIES.LUNCH ||
          values.expenseCategory === EXPENSES_CATEGORIES.DINNER ||
          values.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
          values.expenseCategory === EXPENSES_CATEGORIES.APARTMENT ? (
            <div>
              <Row style={{ paddingBottom: "1.5rem" }}>
                <Input
                  id="participantsQuantity"
                  name="participantsQuantity"
                  label="Nº de participantes"
                  disabled={isSubmitting || readOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.participantsQuantity}
                  containerClasses={styles.participantsQuantitySelect}
                  select={true}
                  error={
                    errors.participantsQuantity && touched.participantsQuantity
                  }
                  errorMessage={
                    errors.participantsQuantity &&
                    touched.participantsQuantity &&
                    errors.participantsQuantity
                  }
                >
                  {PARTICIPANTS_QUANTITY.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Input>
              </Row>
            </div>
          ) : null}
          {values.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER ? (
            <div>
              <Row style={{ paddingBottom: "1.5rem" }}>
                <Input
                  id="currency"
                  name="currency"
                  label="Moeda Usada"
                  disabled={isSubmitting || readOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currency}
                  containerClasses={styles.currencySelect}
                  select={true}
                  error={errors.currency && touched.currency}
                  errorMessage={
                    errors.currency && touched.currency && errors.currency
                  }
                >
                  {Object.values(CurrencyCode).map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Input>
                <CurrencyInput
                  id="value"
                  name="value"
                  label={`Valor (${CurrencySymbol[values.currency]})`}
                  disabled={isSubmitting || readOnly}
                  currencyCode={values.currency}
                  value={values.value}
                  onChange={handleChangePrice}
                  onBlur={handleBlur}
                  containerClasses={styles.valueInput}
                  formatOnNull={false}
                  error={Boolean(errors.value && touched.value)}
                  errorMessage={
                    errors.value && touched.value ? errors.value : null
                  }
                />
              </Row>
              {!isBrazilianCurrency() ? (
                <Row style={{ paddingBottom: "1.5rem" }}>
                  <CurrencyInput
                    id="exchangeRate"
                    name="exchangeRate"
                    label={`Valor pago por 1 ${
                      CurrencyCode[values.currency]
                    } (${CurrencySymbol.BRL})`}
                    disabled={isSubmitting || readOnly}
                    value={values.exchangeRate}
                    onChange={handleChangeExchangeRate}
                    onBlur={handleBlur}
                    containerClasses={styles.exchangeRateInput}
                    formatOnNull={false}
                    error={Boolean(errors.exchangeRate && touched.exchangeRate)}
                    errorMessage={
                      errors.exchangeRate && touched.exchangeRate
                        ? errors.exchangeRate
                        : null
                    }
                  />
                  <CurrencyInput
                    id="convertedValue"
                    name="convertedValue"
                    label={`Valor convertido (${CurrencySymbol.BRL})`}
                    disabled={true}
                    currencyCode={CurrencyCode.BRL}
                    value={convertedValue}
                    onChange={() => {
                      return;
                    }}
                    containerClasses={styles.convertedValueInput}
                    formatOnNull={false}
                  />
                </Row>
              ) : null}

              <Row style={{ paddingBottom: "1.5rem" }}>
                <DateInput
                  id="date"
                  date={values.date}
                  handleDateChange={handleSetDate("date")}
                  placeholder="Data de Compra"
                  rootStyle={{ width: "100%" }}
                  error={Boolean(errors.date && touched.date)}
                  errorMessage={errors.date}
                  setTouched={setTouched("date")}
                  disabled={isSubmitting || readOnly}
                  isOutsideRange={dateOfBuyValidation}
                />
              </Row>
            </div>
          ) : null}
          {isCustomCategory ? (
            <LocationAutocomplete
              handleSelect={handleLocationChange}
              handleBlur={handleBlur}
              inputProps={{
                id: "locationCity",
                name: "locationCity",
                label: "Local (cidade)",
                disabled: isSubmitting || readOnly,
                containerClasses: styles.input,
                error: errors.locationSearch && touched.locationSearch,
                errorMessage:
                  errors.locationSearch && touched.locationSearch
                    ? errors.locationSearch
                    : null
              }}
              value={values.locationSearch!}
              locationType="(cities)"
            />
          ) : null}
          <Row style={{ paddingBottom: "1.5rem" }}>
            <PaymentMethodSelect
              name="paymentMethod"
              id="paymentMethod"
              value={values.paymentMethod}
              handleChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting || readOnly}
              error={Boolean(errors.paymentMethod && touched.paymentMethod)}
              errorMessage={
                touched.paymentMethod && errors.paymentMethod
                  ? errors.paymentMethod
                  : null
              }
            />
          </Row>
          {values.expenseCategory === EXPENSES_CATEGORIES.FLIGHT ? (
            <FlightExpenseForm
              readOnly={readOnly}
              loading={isSubmitting}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setTouched}
            />
          ) : null}
          {values.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
          values.expenseCategory === EXPENSES_CATEGORIES.APARTMENT ? (
            <HotelExpenseForm
              readOnly={readOnly}
              loading={isSubmitting}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setTouched}
            />
          ) : null}
          {values.expenseCategory === EXPENSES_CATEGORIES.KILOMETER ? (
            <KilometerExpenseForm
              readOnly={readOnly}
              loading={isSubmitting}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setTouched}
            />
          ) : null}
          {renderImageInpunt()}
        </div>
      )}
      {!readOnly && !isLoading ? (
        <Row className={styles.buttonsDiv}>
          <StContainedButton
            onClick={handleSubmit}
            isFullWidth={false}
            className={styles.button}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          >
            {selectedExpense ? "Salvar despesa" : "Registrar despesa"}
          </StContainedButton>
          <StOutlinedButton
            onClick={handleClose}
            disabled={isSubmitting}
            className={styles.cancelButton}
          >
            Cancelar
          </StOutlinedButton>
        </Row>
      ) : null}
    </div>
  );
};

const FormikContainer = withFormik<Props, FormValues>({
  mapPropsToValues: ({
    selectedExpense,
    selectedReceipt,
    reportToken,
    travelerToken
  }) =>
    expensesHelper.forkmikMapPropsToValue(
      selectedExpense,
      selectedReceipt,
      reportToken,
      travelerToken
    ),
  handleSubmit: () => null,
  validate: values => {
    const errors: FormikErrors<FormValues> = {};

    if (values.currency !== CurrencyCode.BRL && !values.exchangeRate) {
      errors.exchangeRate = "Preencha a taxa de conversão da moeda estrangeira";
    }

    if (!values.expenseCategoryToken) {
      errors.expenseCategoryToken = "Selecione o tipo da despesa";
    }

    if (!values.description) {
      errors.description = "Descreva sua despesa";
    }

    if (!values.date) {
      errors.date = "Selecione a data de compra da despesa";
    }

    if (!utils.isNumber(values.value)) {
      errors.value = "Digite o valor da despesa";
    }

    if (
      values.expenseCategory !== EXPENSES_CATEGORIES.FLIGHT &&
      values.expenseCategory !== EXPENSES_CATEGORIES.HOTEL &&
      values.expenseCategory !== EXPENSES_CATEGORIES.APARTMENT &&
      values.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER
    ) {
      if (!values.locationSearch) {
        errors.locationSearch = "Preencha o local da despesa";
      }

      if (!values.receipt) {
        errors.receipt = "Selecione o comprovante a ser enviado";
      }
    }

    if (values.expenseCategory === EXPENSES_CATEGORIES.FLIGHT) {
      if (!values.origin || utils.isObjectEmpty(values.origin)) {
        errors.origin = "Preencha o aeroporto de origem";
      }

      if (!values.destination || utils.isObjectEmpty(values.destination)) {
        errors.destination = "Preencha o aeroporto de destino";
      }

      if (!values.startDate) {
        errors.startDate = "Preencha a data de partida";
      }

      if (values.flightType === "roundtrip" && !values.endDate) {
        errors.endDate = "Preencha a data de chegada";
      } else if (
        values.flightType === "roundtrip" &&
        values.endDate!.isBefore(values.startDate!)
      ) {
        errors.endDate = "Data de chegada inválida";
      }

      if (!values.cabinClass) {
        errors.cabinClass = "Selecione classe do voo";
      }

      if (!values.flightType) {
        errors.flightType = "Preencha o tipo de voo";
      }
    }

    if (
      values.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
      values.expenseCategory === EXPENSES_CATEGORIES.APARTMENT
    ) {
      if (
        values.expenseCategory === EXPENSES_CATEGORIES.HOTEL &&
        (!values.hotel || utils.isObjectEmpty(values.hotel))
      ) {
        errors.hotel = "Preencha o local do hotel";
      }

      if (
        values.expenseCategory === EXPENSES_CATEGORIES.APARTMENT &&
        (!values.hotel || utils.isObjectEmpty(values.hotel))
      ) {
        errors.hotel = "Preencha o local do apartamento/airbnb";
      }

      if (!values.startDate) {
        errors.startDate = "Preencha a data de check-in";
      }

      if (!values.endDate) {
        errors.endDate = "Preencha a data de check-out";
      } else if (values.endDate!.isBefore(values.startDate!)) {
        errors.endDate = "Data de check-out inválida";
      }
    }

    if (
      values.expenseCategory === EXPENSES_CATEGORIES.BREAKFAST ||
      values.expenseCategory === EXPENSES_CATEGORIES.LUNCH ||
      values.expenseCategory === EXPENSES_CATEGORIES.DINNER ||
      values.expenseCategory === EXPENSES_CATEGORIES.HOTEL ||
      values.expenseCategory === EXPENSES_CATEGORIES.APARTMENT
    ) {
      if (!values.participantsQuantity) {
        errors.participantsQuantity = "Preencha o número de participantes";
      }
    }

    if (values.expenseCategory === EXPENSES_CATEGORIES.KILOMETER) {
      const { originLocation, destinationLocation, distance } = values;

      const isOriginFilled = Boolean(
        originLocation &&
          originLocation.search &&
          originLocation.latitude &&
          originLocation.longitude
      );

      const isDestinationFilled = Boolean(
        destinationLocation &&
          destinationLocation.search &&
          destinationLocation.latitude &&
          destinationLocation.longitude
      );

      if (!isOriginFilled) {
        errors.originLocation = "Preencha o local de origem";
      }

      if (!isDestinationFilled) {
        errors.destinationLocation = "Preencha o local de destino";
      }

      if (!distance) {
        errors.distance = "Rota inválida, insira uma nova rota";
      }
    }

    if (!values.paymentMethod) {
      errors.paymentMethod = "Preencha o método de pagamento";
    }

    return errors;
  }
})(ExpenseDrawer);

const Container = (props: Props) => {
  const { isSubmitting, isLoading } = useContext(ExpenseDrawerContext);
  const emptyOnClose = () => console.log("nada");

  return (
    <ExpenseDrawerProvider>
      <Drawer
        open={props.open}
        onClose={isSubmitting || isLoading ? emptyOnClose : props.handleClose}
        anchor="right"
        classes={{
          paper: styles.drawerPaper
        }}
      >
        <FormikContainer {...props} />
      </Drawer>
    </ExpenseDrawerProvider>
  );
};

Container.defaultProps = {
  onAfterSave: () => null
} as Partial<Props>;

export { Container as ExpenseDrawer };
