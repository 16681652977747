import {
  ExpenseCategoryDto,
  ExpenseCategoriesOptionsDto
} from "@dtos/expense-categories.dto";

export function parseClientExpenseCategories(data: ExpenseCategoryDto[]) {
  return data.map(category => parseClientExpenseCategory(category));
}

export function parseClientExpenseCategoriesOptions(
  data: ExpenseCategoriesOptionsDto[]
) {
  return data.map(category => parseClientExpenseCategoryOption(category));
}

export function parseClientExpenseCategory(data: ExpenseCategoryDto) {
  const result = {
    expenseCategoryToken: data.expense_category_token,
    name: data.name,
    active: data.active,
    clientToken: data.client_token,
    color: data.color,
    default: data.default,
    expenseCategory: data.expense_category,
    expenseFamily: data.expense_family
  };

  return result;
}

export function parseClientExpenseCategoryOption(
  data: Partial<ExpenseCategoryDto>
) {
  const result = {
    name: data.name,
    expenseCategory: data.expense_category,
    expenseCategoryToken: data.expense_category_token
  };

  return result;
}
