import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

import {
  GetCardEncryptionKeyResponse,
  GetGeneralEncryptionDataResponse,
} from "../dtos/encryption.dto";

export async function getCardEncryptionKey() {
  return api
    .request<GetCardEncryptionKeyResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: "/booking/card/encryption-key",
    })
    .then(({ data }) => data);
}

export async function getGeneralEncryptionData() {
  return await api
    .request<GetGeneralEncryptionDataResponse>({
      method: "GET",
      url: "/auth/encryption",
    })
    .then(({ data }) => data.data);
}
