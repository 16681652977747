import React from "react";

import { CreditCard } from "~/apps/corporate/models/credit-card.model";
import { TravelPaymentModel } from "~/apps/corporate/models/travel.model";
import { VCN } from "~/apps/corporate/models/vcn.model";
import { useFormContext } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { PaymentOption, ServiceType } from "~/apps/shared/constants/enums";

import { ServicePaymentOptionSection } from "../service-payment-option-section/service-payment-option-section";
import { styles } from "./styles";

// all tokens are equal for all services in this case
const anyValidFormKeySinceAllAreTreatedEqually = "busCardToken";

type Props = {
  payableCards: Record<
    ServiceType | "other",
    {
      creditCards: CreditCard[];
      vcns: VCN[];
    }
  >;
  paymentOptions: Record<ServiceType, PaymentOption>;
  shouldBookingFeeBeCharged: boolean;
  travelPaymentModel: TravelPaymentModel;
};

export const PaymentDecisionFields: React.FC<Props> = ({
  payableCards,
  paymentOptions,
  shouldBookingFeeBeCharged,
  travelPaymentModel,
}) => {
  const form = useFormContext();

  const areAllProcessableServicesPaidWithCard = travelPaymentModel.areAllProcessableServicesPaidWithCard(
    paymentOptions,
  );

  const hasAtLeastOnePayableCardForChosenServices = travelPaymentModel.hasAtLeastOnePayableCardForChosenServices(
    payableCards,
    paymentOptions,
  );

  const hasAtLeastOneCardWithSpecificPaymentIntentForChosenServices = travelPaymentModel.hasAtLeastOneCardWithSpecificPaymentIntentForChosenServices(
    payableCards,
    paymentOptions,
  );

  const hasProcessableBusServices = travelPaymentModel.hasProcessableBusServices();
  const hasProcessableCarServices = travelPaymentModel.hasProcessableCarServices();
  const hasProcessableFlightServices = travelPaymentModel.hasProcessableFlightServices();
  const hasProcessableHotelServices = travelPaymentModel.hasProcessableHotelServices();

  const shouldDisplayOnlyOneCardSelect =
    areAllProcessableServicesPaidWithCard &&
    hasAtLeastOnePayableCardForChosenServices &&
    !hasAtLeastOneCardWithSpecificPaymentIntentForChosenServices;

  if (shouldDisplayOnlyOneCardSelect) {
    const allPayableCardsForChosenServices = travelPaymentModel.getAllPayableCardsForChosenServices(
      payableCards,
      paymentOptions,
    );

    const selectedCardToken = form.watch(
      anyValidFormKeySinceAllAreTreatedEqually,
    );

    return (
      <>
        <div css={styles.section}>
          <ServicePaymentOptionSection
            handleChangeSelectedCardToken={(value) => {
              form.setValue("busCardToken", value);
              form.setValue("carCardToken", value);
              form.setValue("flightCardToken", value);
              form.setValue("hotelCardToken", value);
            }}
            payableCards={allPayableCardsForChosenServices}
            paymentOption={PaymentOption.CREDIT_CARD}
            selectedCardToken={selectedCardToken}
            title="Cartão para pagamento:"
          />
          <InputErrorMessage>
            {form.errors["busCardToken"]?.message ||
              form.errors["carCardToken"]?.message ||
              form.errors["flightCardToken"]?.message ||
              form.errors["hotelCardToken"]?.message}
          </InputErrorMessage>
        </div>
        {shouldBookingFeeBeCharged ? (
          <>
            <hr css={styles.divisor} />
            <div css={styles.section}>
              <ServicePaymentOptionSection
                handleChangeSelectedCardToken={(value) => {
                  form.setValue("feeCardToken", value);
                }}
                payableCards={{
                  creditCards: payableCards.other.creditCards,
                  vcns: [],
                }}
                paymentOption={PaymentOption.CREDIT_CARD}
                selectedCardToken={form.watch("feeCardToken")}
                title="Meio de pagamento da taxa da viagem:"
              />
              <InputErrorMessage>
                {form.errors["feeCardToken"]?.message}
              </InputErrorMessage>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      {hasProcessableFlightServices ? (
        <div css={styles.section}>
          <ServicePaymentOptionSection
            handleChangeSelectedCardToken={(value) => {
              form.setValue("flightCardToken", value);
            }}
            payableCards={payableCards.flight}
            paymentOption={paymentOptions.flight}
            selectedCardToken={form.watch("flightCardToken")}
            title="Meio de pagamento para aéreo:"
          />
          <InputErrorMessage>
            {form.errors["flightCardToken"]?.message}
          </InputErrorMessage>
        </div>
      ) : null}
      {hasProcessableHotelServices ? (
        <div css={styles.section}>
          <ServicePaymentOptionSection
            handleChangeSelectedCardToken={(value) => {
              form.setValue("hotelCardToken", value);
            }}
            payableCards={payableCards.hotel}
            paymentOption={paymentOptions.hotel}
            selectedCardToken={form.watch("hotelCardToken")}
            title="Meio de pagamento para hotelaria:"
          />
          <InputErrorMessage>
            {form.errors["hotelCardToken"]?.message}
          </InputErrorMessage>
        </div>
      ) : null}
      {hasProcessableCarServices ? (
        <div css={styles.section}>
          <ServicePaymentOptionSection
            handleChangeSelectedCardToken={(value) => {
              form.setValue("carCardToken", value);
            }}
            payableCards={payableCards.car}
            paymentOption={paymentOptions.car}
            selectedCardToken={form.watch("carCardToken")}
            title="Meio de pagamento para locação de veículo:"
          />
          <InputErrorMessage>
            {form.errors["carCardToken"]?.message}
          </InputErrorMessage>
        </div>
      ) : null}
      {hasProcessableBusServices ? (
        <div css={styles.section}>
          <ServicePaymentOptionSection
            handleChangeSelectedCardToken={(value) => {
              form.setValue("busCardToken", value);
            }}
            payableCards={payableCards.bus}
            paymentOption={paymentOptions.bus}
            selectedCardToken={form.watch("busCardToken")}
            title="Meio de pagamento para passagens de ônibus:"
          />
          <InputErrorMessage>
            {form.errors["busCardToken"]?.message}
          </InputErrorMessage>
        </div>
      ) : null}
      {shouldBookingFeeBeCharged ? (
        <>
          <hr css={styles.divisor} />
          <div css={styles.section}>
            <ServicePaymentOptionSection
              handleChangeSelectedCardToken={(value) => {
                form.setValue("feeCardToken", value);
              }}
              payableCards={{
                creditCards: payableCards.other.creditCards,
                vcns: [],
              }}
              paymentOption={PaymentOption.CREDIT_CARD}
              selectedCardToken={form.watch("feeCardToken")}
              title="Meio de pagamento da taxa da viagem:"
            />
            <InputErrorMessage>
              {form.errors["feeCardToken"]?.message}
            </InputErrorMessage>
          </div>
        </>
      ) : null}
    </>
  );
};
