import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { formatCurrency } from "~/apps/shared/utils/format-currency";
import moment from "moment";

import { CreditsCreditDetailsDialog } from "./credits-credit-details-dialog/credits-credit-details-dialog";
import { useClientUserCredits } from "./credits-section.hooks";
import { styles } from "./styles";

export const UserProfileDrawerCreditsSection: React.FC = () => {
  const { user } = useUser();

  const { isLoadingClientUserCredits, userCredits } = useClientUserCredits(
    user,
  );

  const [isCreditDetailsDialogOpen, setIsCreditDetailsDialogOpen] = useState(
    false,
  );

  const handleCloseCreditDetailsDialog = useCallback(() => {
    setIsCreditDetailsDialogOpen(false);
  }, []);

  const handleOpenCreditDetailsDialog = useCallback(() => {
    setIsCreditDetailsDialogOpen(true);
  }, []);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>Créditos</span>{" "}
          {userCredits && userCredits.length > 0 ? (
            <button
              css={styles.header.button}
              onClick={() => {
                handleOpenCreditDetailsDialog();
              }}
            >
              Ver detalhes
            </button>
          ) : null}
        </div>
        <div css={styles.credits.root}>
          {userCredits && userCredits.length > 0 ? (
            userCredits.map((userCredit) => (
              <div
                css={styles.credits.card.root}
                key={userCredit.providerCreditToken}
              >
                <div css={styles.credits.card.content.root}>
                  <span css={styles.credits.card.content.value}>
                    {formatCurrency(userCredit.value)}
                  </span>
                  <span css={styles.credits.card.content.expiration}>
                    Expira em:{" "}
                    {moment(userCredit.expirationDate).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
            ))
          ) : isLoadingClientUserCredits ? (
            <Skeleton height="132px" width="352px" />
          ) : (
            <span css={styles.credits.empty}>Nenhum crédito disponível.</span>
          )}
        </div>
      </div>
      {userCredits ? (
        <CreditsCreditDetailsDialog
          credits={userCredits}
          onClose={handleCloseCreditDetailsDialog}
          open={isCreditDetailsDialogOpen}
        />
      ) : null}
    </>
  );
};
