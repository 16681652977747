import { useMemo } from "react";

import { HotelListOffer } from "~/apps/corporate/models/offer.model";

export const useFilteredVisibleOffers = (
  offers: HotelListOffer[],
  filters: any,
) => {
  const getVisibleOffers = () => {
    if (offers.length === 0) {
      return [];
    }

    const filterByFreeCancelation = (offer: HotelListOffer) => {
      if (!filters.freeCancelationOnly) {
        return true;
      }

      return offer.popularAmenities.refund === filters.freeCancelationOnly;
    };

    const filterByBreakfast = (offer: HotelListOffer) => {
      if (!filters.breakfastOnly) {
        return true;
      }

      return offer.popularAmenities.breakfast === filters.breakfastOnly;
    };

    return offers.filter(filterByFreeCancelation).filter(filterByBreakfast);
  };

  const visibleOffers = useMemo(() => getVisibleOffers(), [filters, offers]);

  return visibleOffers;
};
