import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate } from "@reach/router";
import { CarSearchInfo } from "~/apps/corporate/models/car.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { useCars } from "../cars.context";
import { styles } from "./styles";

export const CarsHeader: React.FC = () => {
  const { isFakeLoading, isLoading, searchInfo } = useCars();

  const navigateBack = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <div css={styles.root}>
      <Button css={styles.left.button} shape="icon" onClick={navigateBack}>
        <Icon size={16} use="arrow-left" />
      </Button>
      <div css={styles.right.root}>
        {isFakeLoading || isLoading ? (
          <CarsHeaderSearchSkeleton />
        ) : searchInfo ? (
          <CarsHeaderSearchInfo searchInfo={searchInfo} />
        ) : null}
      </div>
    </div>
  );
};

type CarsHeaderSearchInfoProps = {
  searchInfo: CarSearchInfo;
};

const CarsHeaderSearchInfo: React.FC<CarsHeaderSearchInfoProps> = ({
  searchInfo,
}) => {
  const { handleOpenEditionDrawer } = useCars();

  const {
    dropoffCity,
    dropoffDate,
    dropoffHour,
    dropoffMinute,
    dropoffState,
    pickupCity,
    pickupDate,
    pickupHour,
    pickupMinute,
    pickupState,
  } = searchInfo!;

  const endHour = moment()
    .set({ hour: dropoffHour, minute: dropoffMinute })
    .format("HH:mm");
  const startHour = moment()
    .set({ hour: pickupHour, minute: pickupMinute })
    .format("HH:mm");
  const travelEnd = moment(dropoffDate).format("DD MMM");
  const travelStart = moment(pickupDate).format("DD MMM");

  return (
    <>
      <div css={styles.right.search.root}>
        <span css={styles.right.search.field}>
          {pickupCity}, {pickupState}
        </span>
        <span css={styles.right.search.value}>
          {travelStart}, {startHour}
        </span>
      </div>
      <div css={styles.right.search.root}>
        <span css={styles.right.search.field}>
          {dropoffCity}, {dropoffState}
        </span>
        <span css={styles.right.search.value}>
          {travelEnd}, {endHour}
        </span>
      </div>
      <Button onClick={handleOpenEditionDrawer} shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};

const CarsHeaderSearchSkeleton: React.FC = () => {
  return (
    <>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <Button disabled shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};
