import * as Sentry from "@sentry/react";

import { navigate } from "@reach/router";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import axios, { AxiosError, AxiosResponse } from "axios";

import { logger } from "../utils/logger";

export const api = axios.create({
  baseURL: BASE_URL,
});

const shouldIntercept = (error: AxiosError) => {
  try {
    return error.response?.status === 401;
  } catch (e) {
    return false;
  }
};

const setTokenData = (accessToken: any) => {
  localStorage.setItem("access_token", accessToken);
};

const doTokenRefresh = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/auth/refresh`, undefined, {
        headers: getAuthorizationHeader(),
        timeout: 10000,
        withCredentials: true,
      })
      .then(({ data }) => {
        resolve(data.data.access_token);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

async function handleTokenRefresh() {
  if ("locks" in navigator) {
    return new Promise((resolve, reject) => {
      (navigator as any).locks.request("handle_token_refresh", async () => {
        await doTokenRefresh().then(resolve).catch(reject);
      });
    });
  }

  return doTokenRefresh();
}

async function handleRefreshError(reject: any, error: AxiosError) {
  if (error.response) {
    const { status, data } = error.response;

    if (
      status === 403 &&
      data?.message !== "User has recently refreshed this session." // Should not logout user
    ) {
      navigate("/login", { state: { err: data?.message } });

      localStorage.removeItem("access_token");
      Sentry.setUser(null);
    }

    // TODO: map any other possible error with messages
    reject(error);
  } else {
    reject(error);
  }
}

const attachTokenToRequest = (request: any, token: any) => {
  request.headers["Authorization"] = token;
};

const setOnRejectedInterceptor = (
  axiosClient: typeof api,
  customOptions = {},
) => {
  let isRefreshing = false;
  let failedQueue: any[] = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error: AxiosError | null, token: unknown = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error: any) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;

          options.attachTokenToRequest(originalRequest, token);

          return axiosClient.request(originalRequest);
        })
        .catch((error) => {
          logger.error(error);

          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((accessToken) => {
          options.setTokenData(accessToken);
          options.attachTokenToRequest(originalRequest, accessToken);
          processQueue(null, accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          void handleRefreshError(reject, err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

setOnRejectedInterceptor(api);

const setOnFulfilledInterceptor = (axiosClient: typeof api) => {
  const interceptor = (response: AxiosResponse) => {
    if (
      (response.data === null || response.data === undefined) &&
      response.status !== 204
    ) {
      return Promise.reject("empty response");
    }

    return response;
  };

  axiosClient.interceptors.response.use(interceptor, undefined);
};

setOnFulfilledInterceptor(api);
