import { css } from "@styled-system/css";
import { theme } from "smartrips-skin";

export const styles = {
  button: css({
    height: "fit-content"
  }),
  root: css({
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: 8,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "32px",
    textDecoration: "none",
    transition: "border-color 0.15s ease-in-out",
    width: "100%"
  })
};
