import React from "react";

import { Tooltip } from "@material-ui/core";
import {
  CreditCard as CreditCardIcon,
  Link as LinkIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { css } from "@styled-system/css";
import { css as emotionCss } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

import { CardServiceType } from "~/constants/enums";

import { VCNS_TRANSLATIONS } from "@constants";

import { VcnDisplay } from "~/models/vcn.model";

import { Column } from "@shared/layout/Column";
import { Row } from "@shared/layout/Row";

import styled from "~/components/shared/styled";

import { ServiceIconsList } from "../ServiceIconsList";

const styles = {
  root: emotionCss({
    // height: "9.56rem",
    height: "188.19px",
    padding: "1rem 2rem",
    marginBottom: "1.5rem",
    marginRight: "0rem",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderLeft: `4px solid ${defaultTheme.primaryColor}`,
    borderRadius: 10,
    width: 290,
    position: "relative",
    ["@media (min-width: 500px)"]: {
      marginRight: "1.5rem",
    },
  }),
  logoDiv: emotionCss({
    marginTop: "-0.5rem",
    height: 41,
    paddingRight: "1.5rem",
    alignItems: "center",
    color: defaultTheme.subTextColor,
  }),
  optionsAndInfos: emotionCss({
    display: "flex",
    gap: "0.25rem",
    position: "absolute",
    right: "1.875rem",
  }),
  infos: emotionCss({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }),
  publicIcon: emotionCss({
    fontSize: "1.25rem",
    marginRight: "0.25rem",
    color: defaultTheme.primaryColor,
  }),
  cardDescription: emotionCss({
    fontSize: 14,
    color: defaultTheme.subTextColor,
  }),
  cardName: emotionCss({
    marginTop: "0.375rem",
    lineHeight: 1.5,
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: defaultTheme.subTextColor,
  }),
  cardNumber: emotionCss({
    fontSize: 15,
    fontWeight: "bold",
    color: defaultTheme.subTextColor,
    justifyContent: "space-between",
    width: "100%",
  }),
  mockDigits: emotionCss({
    position: "relative",
    top: -7.5,
    fontSize: 30,
  }),
  cardNumberDiv: emotionCss({
    alignItems: "center",
    paddingBottom: "1.7rem",
  }),
};

const oldStyles = {
  tooltip: css({
    [".MuiTooltip-arrow"]: {
      color: `${defaultTheme.primaryColor} !important`,
    },
    [".MuiTooltip-tooltip"]: {
      marginRight: "8px",
      fontSize: "0.75rem",
      backgroundColor: defaultTheme.primaryColor,
      textAlign: "center",
      padding: "0.3rem",
      lineHeight: "1.25rem",
      cursor: "auto",
    },
    [".MuiTooltip-tooltipPlacementTop"]: {
      marginBottom: "0.5rem",
    },
  }),
};

interface Props {
  vcn: VcnDisplay;
}

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    arrow
    placement="top"
    title={
      <span style={{ fontWeight: "bold", padding: "0.5rem" }}>
        {props.text}
      </span>
    }
  >
    {props.children}
  </Tooltip>
))(oldStyles.tooltip);

const VcnItemPresentational = ({ vcn }: Props) => {
  //Todo: Remover essa função e trazer do back no mesmo formato dos cartões físicos.
  const buildServices = (serviceTypes: string[]) => {
    if (serviceTypes.includes(CardServiceType.GENERAL)) {
      return {
        flight: true,
        car: true,
        hotel: true,
        other: true,
        ride: true,
        bus: true,
      };
    }
    return {
      flight: serviceTypes.includes(CardServiceType.FLIGHT),
      car: serviceTypes.includes(CardServiceType.CAR),
      hotel: serviceTypes.includes(CardServiceType.HOTEL),
      other: false,
      ride: false,
      bus: false,
    };
  };

  return (
    <Column className={styles.root}>
      <Row className={styles.logoDiv}>
        <CreditCardIcon style={{ fontSize: 28 }} />
        <div className={styles.optionsAndInfos}>
          <div className={styles.infos}>
            {vcn.billingProfileToken ? (
              <StyledTooltip text="Vinculado a um perfil de cobrança">
                <LinkIcon className={styles.publicIcon} />
              </StyledTooltip>
            ) : null}
            <StyledTooltip text="Cartão da empresa">
              <PeopleIcon className={styles.publicIcon} />
            </StyledTooltip>
          </div>
        </div>
      </Row>
      <Row style={{ padding: ".5rem 0" }}>
        <div className={styles.cardDescription}>
          <span>CARTÃO VIRTUAL ({VCNS_TRANSLATIONS[vcn.provider]})</span>
        </div>
      </Row>
      <Row className={styles.cardNumberDiv}>
        <Row className={styles.cardNumber}>
          <span className={styles.mockDigits}>••••</span>
          <span className={styles.mockDigits}>••••</span>
          <span className={styles.mockDigits}>••••</span>
          <span className={styles.mockDigits}>••••</span>
        </Row>
      </Row>
      <ServiceIconsList serviceTypes={buildServices(vcn.serviceTypes)} />
    </Column>
  );
};

export { VcnItemPresentational };
