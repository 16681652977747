import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    borderRadius: "6px",
    cursor: "unset",
    fontWeight: 500,
    padding: "4px 16px",
  }),
};
