import { api } from "~/apps/shared/services/api";

import {
  GetReserveBytravelTokenRequestParams,
  GetUserBookerTargetsResponse,
  SearchBookerTargetsToAddResponse,
  SearchTravelBookingTargetsResponse,
} from "../dtos/booking-target.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import * as bookingTargetParser from "../parsers/booking-target.parser";

export async function getReserveBytravelToken({
  travelToken,
  outbound_route,
  flightDate,
  cia,
}: GetReserveBytravelTokenRequestParams) {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/check-flight-reservation?route=${outbound_route}&date=${flightDate}&cia=${cia}`,
    })
    .then(({ data }) => data);
}

export async function getUserBookerTargets(userToken: string) {
  return await api
    .request<GetUserBookerTargetsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/booker-targets/${userToken}`,
    })
    .then(({ data }) =>
      data.map((item) => ({
        alreadyTarget: true,
        email: item.email,
        fullName: item.full_name,
        userToken: item.user_token,
      })),
    );
}

export async function searchBookerTargetsToAdd(
  search: string,
  bookerToken: string,
) {
  return await api
    .request<SearchBookerTargetsToAddResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: { search, booker_token: bookerToken },
      url: "/booking/booker-targets/add-search",
    })
    .then(({ data }) => bookingTargetParser.parseBookingTargetsToAdd(data));
}

export async function searchTravelBookingTargets(search: string) {
  return await api
    .request<SearchTravelBookingTargetsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: { search },
      url: "/booking/booker-targets/book-search",
    })
    .then(({ data }) => bookingTargetParser.parseBookingTargets(data));
}
