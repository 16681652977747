import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { RouteComponentProps } from "@reach/router";
import { css } from "emotion";

import PAGE_TITLES from "../../../constants/page-titles.constants";
import { Expense } from "../../../models/expense.model";
import { StContainedButton, StOutlinedButton } from "../../shared/buttons";
import DeletionDialog from "../../shared/dialogs/DeletionDialog";
import { Column } from "../../shared/layout/Column";
import { Desktop } from "../../shared/layout/Responsive";
import { Row } from "../../shared/layout/Row";
import PageTitle from "../../shared/PageTitle";
import { ExpenseDrawer } from "../ExpenseDrawer/ExpenseDrawer";
import { ExpensesContext } from "../Expenses.context";
import { ReportSelectDrawer } from "../ReportSelectDrawer/ReportSelectDrawer";
import { NewReportDialog } from "../shared/NewReportDialog";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { ExpenseItem } from "./ExpenseItem/ExpenseItem";

const styles = {
  root: css({
    width: "100%",
    ["@media (max-width: 991px)"]: {
      paddingBottom: "4rem",
    },
  }),
  inputContainer: css({
    width: 350,
  }),
  buttonsRow: css({
    gap: "1.5rem",
    paddingBottom: "1.5rem",
    ["@media (max-width: 395px)"]: {
      justifyContent: "space-between",
    },
  }),
  button: css({
    padding: ".5rem 1.5rem",
    ["@media (max-width: 767px)"]: {
      minWidth: "150",
    },
  }),
  addToReportButton: css({
    padding: ".5rem 1rem",
  }),
  noExpenses: css({
    paddingTop: ".5rem",
    fontSize: 14,
    ["@media (max-width: 991px)"]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: "2rem",
      paddingLeft: 0,
      fontSize: 12,
    },
    ["@media (max-width: 767px)"]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: "2rem",
      paddingLeft: 0,
      fontSize: 15,
    },
  }),
};

const ExpensesList = (_: RouteComponentProps) => {
  const {
    expenses,
    fetchExpenses,
    loadNextExpenses,
    currentPage,
    totalPages,
    handleChangeSearchFilter,
    checkedExpenses,
    isExpenseDrawerOpen,
    openExpensesDrawer,
    selectedExpense,
    selectedExpenseReceiptFile,
    handleDrawerClose,
    onAfterExpenseSave,
    handleCheckExpense,
    expenseToDelete,
    isDeletionDialogOpen,
    isDeleting,
    handleExpenseDelete,
    proccessExpenseDeletion,
    handleCloseDeleteDialog,
    handleOpenReportListDrawer,
    isReportCreationDialogOpen,
    isSubmitingReportCreation,
    handleCloseReportCreationDialog,
    createReportWithExpenses,
    isLoading,
  } = useContext(ExpensesContext);
  useEffect(() => {
    fetchExpenses();
  }, []);

  const handleEdit = (expense: Expense) => () => {
    openExpensesDrawer(expense);
  };

  const handleOpenDrawer = () => {
    openExpensesDrawer();
  };

  const isAnyExpenseChecked = Object.values(checkedExpenses).some(
    (item) => item,
  );
  const hasNextPage = !isLoading && currentPage < totalPages;

  return (
    <div className={styles.root}>
      <PageTitle title={PAGE_TITLES.EXPENSES} />
      <Row className={styles.buttonsRow}>
        <StContainedButton
          onClick={handleOpenDrawer}
          isFullWidth={false}
          className={styles.button}
        >
          Criar despesa
        </StContainedButton>
        <StOutlinedButton
          className={styles.addToReportButton}
          disabled={!isAnyExpenseChecked}
          onClick={handleOpenReportListDrawer}
          color="primary"
        >
          Adicionar a relatório
        </StOutlinedButton>
      </Row>
      <Desktop>
        <div style={{ marginBottom: "2rem" }}>
          <SearchFilterInput
            containerClasses={styles.inputContainer}
            placeholder="Buscar por descrição"
            onChange={handleChangeSearchFilter}
          />
        </div>
      </Desktop>
      <div>
        <Column>
          {!expenses.length ? (
            <div className={styles.noExpenses}>
              Nenhuma despesa pendente, utilize o botão acima para criar novas
              despesas
            </div>
          ) : null}
          <InfiniteScroll
            pageStart={1}
            loadMore={loadNextExpenses}
            hasMore={hasNextPage}
            loader={
              <div key={0} className="loader">
                Loading ...
              </div>
            }
          >
            {expenses.map((expense, index) => {
              const isLastItem = expenses.length === index + 1;
              return (
                <ExpenseItem
                  key={expense.expenseToken}
                  isLast={isLastItem}
                  isChecked={!!checkedExpenses[expense.expenseToken]}
                  expense={expense}
                  handleChange={handleCheckExpense(expense.expenseToken)}
                  handleEdit={handleEdit(expense)}
                  handleDelete={handleExpenseDelete(expense)}
                />
              );
            })}
          </InfiniteScroll>
        </Column>
      </div>
      <NewReportDialog
        open={isReportCreationDialogOpen}
        isSubmiting={isSubmitingReportCreation}
        handleClose={handleCloseReportCreationDialog}
        handleCreate={createReportWithExpenses}
      />
      <ExpenseDrawer
        open={isExpenseDrawerOpen}
        handleClose={handleDrawerClose}
        onAfterSave={onAfterExpenseSave}
        selectedExpense={selectedExpense}
        selectedReceipt={selectedExpenseReceiptFile}
      />
      <ReportSelectDrawer />
      <DeletionDialog
        open={isDeletionDialogOpen}
        title="Exclusão de despesa"
        message={
          <span>
            Deseja realmente excluir a despesa{" "}
            <span style={{ fontWeight: "bold" }}>
              {expenseToDelete ? expenseToDelete.description : ""}
            </span>
            ?
          </span>
        }
        handleDeletion={proccessExpenseDeletion}
        handleClose={handleCloseDeleteDialog}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export { ExpensesList };
