import React, { useCallback, useMemo } from "react";

import { CurrencyCode } from "../../constants/enums";
import { toCurrency } from "../../utils/to-currency";
import { Input } from "../input/input";

const CurrencyInput = ({
  currencyCode,
  formatOnNull,
  onChange,
  value,
  ...inputProps
}: Omit<React.ComponentPropsWithoutRef<typeof Input>, "onChange"> & {
  currencyCode?: CurrencyCode;
  formatOnNull?: boolean;
  onChange?: (value: number | null) => void;
}) => {
  const maskToCurrency = useCallback((unmaskedValue: string) => {
    const [integers, decimals] = unmaskedValue.split(".");

    if (decimals) {
      const splitValue = [integers, decimals].join("");

      const formattedDecimals = splitValue.slice(-2);
      const formattedIntegers = splitValue.substr(0, splitValue.length - 2);

      return parseFloat(formattedIntegers + "." + formattedDecimals);
    }

    return parseFloat(unmaskedValue);
  }, []);

  const unmaskValue = useCallback((currencyValue: string) => {
    if (!currencyValue.includes(",")) {
      currencyValue = [
        currencyValue.slice(0, -2),
        ",",
        currencyValue.slice(-2),
      ].join("");
    }

    const floatString = currencyValue
      .replace(/(R\$|\u2000|[a-zA-Z]|\s)/g, "")
      .replace(/[`\-~!@#$%^&*()_|+=?;:'".<>{}[\]\\/]/g, "")
      .replace(/,/g, ".");

    const [integer, decimals] = floatString.split(".");

    if (!integer && !!decimals && decimals !== "0" && decimals.length < 2) {
      return String.prototype.concat(integer, ".0", decimals);
    }

    return floatString;
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      const unmaskedValue = unmaskValue(e.target.value);
      const maskedValue = maskToCurrency(unmaskedValue);

      onChange(maskedValue);
    },
    [maskToCurrency, onChange, unmaskValue],
  );

  const formattedValue = useMemo(
    () =>
      value || value === 0
        ? toCurrency(parseFloat(value as any), 2, currencyCode)
        : formatOnNull
        ? toCurrency(0, 2, currencyCode)
        : "",
    [currencyCode, formatOnNull, value],
  );

  return (
    <Input
      onChange={handleChange}
      pattern="^R\$ ([0-9]+(\.[0-9]{3})*),[0-9]{2}$"
      type={"text"}
      value={formattedValue}
      {...inputProps}
    />
  );
};

export { CurrencyInput };
