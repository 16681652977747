import React from "react";

import { PolicyTarget } from "~/apps/corporate/models/policy.model";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

export const AddUsersInput: React.FC<
  React.ComponentProps<typeof AsyncAutocomplete> & {
    onDelete: (targetUserToken: PolicyTarget["userToken"]) => () => void;
    targets: PolicyTarget[];
  }
> = ({ onDelete, targets, ...props }) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Adicionar usuários</span>
      <div css={styles.input}>
        <AsyncAutocomplete
          clearOnSelect
          filterItems={(items) =>
            items.filter(
              (item) =>
                !targets.find((target) => target.userToken === item.userToken),
            )
          }
          inputProps={{
            placeholder: "Usuários participantes...",
          }}
          renderItem={({ isHighlighted, item }) => {
            if (item.error) {
              return (
                <li
                  css={styles.autocomplete.item.root({
                    isDisabled: true,
                    isHighlighted: false,
                  })}
                >
                  Nenhum resultado.
                </li>
              );
            }

            return (
              <li css={styles.autocomplete.item.root({ isHighlighted })}>
                <span>{item.label}</span>
                <span css={styles.autocomplete.item.email}>{item.email}</span>
              </li>
            );
          }}
          {...props}
        />
      </div>
      {targets.length === 0 ? (
        <span css={styles.info}>
          Nenhum usuário participando desta política.
        </span>
      ) : (
        <ul css={styles.targets.list}>
          {targets.map((target) => (
            <li css={styles.targets.item.root} key={target.userToken}>
              <span css={styles.targets.item.name}>{target.fullName}</span>
              <span css={styles.targets.item.email}>{target.email}</span>
              <button
                css={styles.targets.item.delete}
                onClick={onDelete(target.userToken)}
              >
                <Icon size={16} use="x" />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
