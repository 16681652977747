import qs from "querystring"; // from native node

import { deepCloneMapToCamelCase } from "./deep-clone-map-to-camel-case";

export const getParamsFromLocation = (location: any) => {
  const innerLocation = location || window.location;

  const search = innerLocation.search
    ? innerLocation.search.replace(/^\?/g, "")
    : "";

  const params = qs.parse(search);

  return deepCloneMapToCamelCase(params);
};
