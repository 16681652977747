import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Select } from "~/apps/shared/components/select/select";
import * as yup from "yup";

import { Button } from "@toolkit/v2";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItineraryContainer } from "../../../../itinerary.container";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";
import { styles } from "./styles";
import { useCustomFieldOptions } from "./travel-purposes-accordion.hooks";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const TravelPurposesAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const { pendenciesModel } = useItineraryContainer();

  const { pendencies, saveTravelPurposesAndProceed } = useItineraryPendencies();

  const {
    customFieldOptions,
    errorCustomFieldOptions,
    isLoadingCustomFieldOptions,
  } = useCustomFieldOptions({
    shouldFetchFlightPurposes: pendenciesModel
      ? pendenciesModel.isFlightPurposePending()
      : undefined,
    shouldFetchHotelPurposes: pendenciesModel
      ? pendenciesModel.isHotelPurposePending()
      : undefined,
    shouldFetchTripPurposes: pendenciesModel
      ? pendenciesModel.isTripPurposePending()
      : undefined,
  });

  const formSchema = useMemo(() => {
    if (!pendenciesModel) {
      return yup.object().shape({
        flightPurposeToken: yup.string().nullable(true),
        hotelPurposeToken: yup.string().nullable(true),
        tripPurposeToken: yup.string().nullable(true),
      });
    }

    return yup.object().shape({
      flightPurposeToken:
        pendenciesModel.isFlightPurposePending() &&
        pendenciesModel.isFlightPurposeRequired()
          ? yup.string().required("Detalhe o motivo do voo.").nullable(true)
          : yup.string().nullable(true),
      hotelPurposeToken:
        pendenciesModel.isHotelPurposePending() &&
        pendenciesModel.isHotelPurposeRequired()
          ? yup
              .string()
              .required("Detalhe o motivo da hospedagem.")
              .nullable(true)
          : yup.string().nullable(true),
      tripPurposeToken:
        pendenciesModel.isTripPurposePending() &&
        pendenciesModel.isTripPurposeRequired()
          ? yup.string().required("Detalhe o motivo da viagem.").nullable(true)
          : yup.string().nullable(true),
    });
  }, [pendenciesModel]);

  type FormSchema = yup.InferType<typeof formSchema>;

  const form = useForm<FormSchema>({
    defaultValues: {
      flightPurposeToken: null,
      hotelPurposeToken: null,
      tripPurposeToken: null,
    } as FormSchema,
    onSubmit: async () => {
      const values = form.getValues();

      const success = await saveTravelPurposesAndProceed({
        flightPurposeToken: values.flightPurposeToken,
        hotelPurposeToken: values.hotelPurposeToken,
        tripPurposeToken: values.tripPurposeToken,
      });

      if (!success) {
        return;
      }

      next();
    },
    schema: formSchema,
  });

  const {
    flightPurposeToken,
    hotelPurposeToken,
    tripPurposeToken,
  } = form.watch([
    "flightPurposeToken",
    "hotelPurposeToken",
    "tripPurposeToken",
  ]);

  const selectedFlightPurpose = useMemo(() => {
    if (!customFieldOptions || !flightPurposeToken) {
      return undefined;
    }

    const flightPurpose = customFieldOptions.flightPurposes?.find(
      (flightPurpose) =>
        flightPurpose.custom_field_option_token === flightPurposeToken,
    );

    if (!flightPurpose) {
      return undefined;
    }

    return {
      label: flightPurpose.name,
      value: flightPurpose.custom_field_option_token,
    };
  }, [flightPurposeToken]);

  const selectedHotelPurpose = useMemo(() => {
    if (!customFieldOptions || !hotelPurposeToken) {
      return undefined;
    }

    const hotelPurpose = customFieldOptions.hotelPurposes?.find(
      (hotelPurpose) =>
        hotelPurpose.custom_field_option_token === hotelPurposeToken,
    );

    if (!hotelPurpose) {
      return undefined;
    }

    return {
      label: hotelPurpose.name,
      value: hotelPurpose.custom_field_option_token,
    };
  }, [hotelPurposeToken]);

  const selectedTripPurpose = useMemo(() => {
    if (!customFieldOptions || !tripPurposeToken) {
      return undefined;
    }

    const tripPurpose = customFieldOptions.tripPurposes?.find(
      (tripPurpose) =>
        tripPurpose.custom_field_option_token === tripPurposeToken,
    );

    if (!tripPurpose) {
      return undefined;
    }

    return {
      label: tripPurpose.name,
      value: tripPurpose.custom_field_option_token,
    };
  }, [tripPurposeToken]);

  if (
    (!customFieldOptions && !isLoadingCustomFieldOptions) ||
    errorCustomFieldOptions ||
    !pendencies ||
    !pendenciesModel
  ) {
    return null;
  }

  const isResolved = pendencies.isTravelPurposesResolved;

  return (
    <PendenciesAccordion
      description="Especifique a motivação principal da viagem."
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={`${index}. Motivos da viagem.`}
      toggleExpanded={toggleExpanded}
    >
      <Form context={form} css={styles.form.root}>
        {pendenciesModel.isFlightPurposePending() ? (
          <>
            {isLoadingCustomFieldOptions ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : !customFieldOptions ? null : (
              <div css={styles.form.select.root}>
                <span css={styles.form.select.label}>
                  Motivo do voo
                  {pendenciesModel.isFlightPurposeRequired() ? (
                    "*"
                  ) : (
                    <span css={styles.optional}> (opcional)</span>
                  )}
                </span>
                <Select
                  id="flightPurposeToken"
                  name="flightPurposeToken"
                  onChange={({ value }) => {
                    form.setValue("flightPurposeToken", value);
                  }}
                  options={customFieldOptions.flightPurposes?.map(
                    (flightPurpose) => ({
                      label: flightPurpose.name,
                      value: flightPurpose.custom_field_option_token,
                    }),
                  )}
                  placeholder="Escolha o motivo do voo..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedFlightPurpose}
                />
                <InputErrorMessage>
                  {form.errors["flightPurposeToken"]?.message}
                </InputErrorMessage>
              </div>
            )}
          </>
        ) : null}
        {pendenciesModel.isHotelPurposePending() ? (
          <>
            {isLoadingCustomFieldOptions ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : !customFieldOptions ? null : (
              <div css={styles.form.select.root}>
                <span css={styles.form.select.label}>
                  Motivo da hospedagem
                  {pendenciesModel.isHotelPurposeRequired() ? (
                    "*"
                  ) : (
                    <span css={styles.optional}> (opcional)</span>
                  )}
                </span>
                <Select
                  id="hotelPurposeToken"
                  name="hotelPurposeToken"
                  onChange={({ value }) => {
                    form.setValue("hotelPurposeToken", value);
                  }}
                  options={customFieldOptions.hotelPurposes?.map(
                    (hotelPurpose) => ({
                      label: hotelPurpose.name,
                      value: hotelPurpose.custom_field_option_token,
                    }),
                  )}
                  placeholder="Escolha o motivo da hospedagem..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedHotelPurpose}
                />
                <InputErrorMessage>
                  {form.errors["hotelPurposeToken"]?.message}
                </InputErrorMessage>
              </div>
            )}
          </>
        ) : null}
        {pendenciesModel.isTripPurposePending() ? (
          <>
            {isLoadingCustomFieldOptions ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : !customFieldOptions ? null : (
              <div css={styles.form.select.root}>
                <span css={styles.form.select.label}>
                  Motivo da viagem
                  {pendenciesModel.isTripPurposeRequired() ? (
                    "*"
                  ) : (
                    <span css={styles.optional}> (opcional)</span>
                  )}
                </span>
                <Select
                  id="tripPurposeToken"
                  name="tripPurposeToken"
                  onChange={({ value }) => {
                    form.setValue("tripPurposeToken", value);
                  }}
                  options={customFieldOptions.tripPurposes?.map(
                    (tripPurpose) => ({
                      label: tripPurpose.name,
                      value: tripPurpose.custom_field_option_token,
                    }),
                  )}
                  placeholder="Escolha o motivo da viagem..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedTripPurpose}
                />
                <InputErrorMessage>
                  {form.errors["tripPurposeToken"]?.message}
                </InputErrorMessage>
              </div>
            )}
          </>
        ) : null}
        <Button
          css={styles.button}
          disabled={form.formState.isSubmitting || isLoadingNext}
        >
          Salvar
        </Button>
      </Form>
    </PendenciesAccordion>
  );
};
