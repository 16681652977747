import React, { ChangeEvent, createContext, useEffect, useMemo } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import * as expensesHelper from "@helpers/expense.helper";

import { ExpenseReportWithUser } from "@models/expense.model";

import { useSafeState } from "@hooks";

import * as reportsListService from "./ApprovalReportsList.service";

interface SignalPaymentItem {
  error: boolean;
  reportToken: string;
}

interface Actions {
  fetchReports(): void;
  handleChangeSearch(searchText: string): void;
  handleClosePaymentDialog(): void;
  handleProccessSignalPayments(): void;
  handleSelectReport(e: ChangeEvent<HTMLInputElement>): void;
  handleSignalPayment(): void;
  loadNextReports(): void;
}

type Selectors = {
  allowReportPaymentSelection: boolean;
  hasAnyReportSelected: boolean;
};

type State = {
  currentPage: number;
  isLoading: boolean;
  isPaymentConfirmationVisible: boolean;
  reports: ExpenseReportWithUser[];
  reportsErrorOnPayment: ExpenseReportWithUser[];
  searchFitler: string;
  selectedReportsToPay: Record<string, boolean>;
  submittingPayment: boolean;
  totalPages: number;
};

const initialState: State = {
  currentPage: 1,
  isLoading: false,
  isPaymentConfirmationVisible: false,
  reports: [],
  reportsErrorOnPayment: [],
  searchFitler: "",
  selectedReportsToPay: {},
  submittingPayment: false,
  totalPages: 1,
};

type ContextProps = Actions & Selectors & State;

const ApprovalReportsListContext = createContext<ContextProps>(
  {} as ContextProps,
);

type Props = {
  reportsStatusType: "pending-approval" | "pending-payment" | "paid";
};

export const ApprovalReportsListProvider: React.FC<Props> = ({
  children,
  reportsStatusType,
}) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const fetchReports = async () => {
    setState({ isLoading: true });
    const { searchFitler } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(
        1,
        reportsStatusType,
        searchFitler,
      ),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      isLoading: false,
      reports: reportsWithUsers,
      selectedReportsToPay: {},
      currentPage: 1,
      totalPages: reportsData!.totalPages,
    });
  };

  const loadNextReports = async () => {
    setState({ isLoading: true });

    const { reports, currentPage, searchFitler } = state;

    const [
      { data: reportsData, error: reportsError },
      { data: usersData, error: usersError },
    ] = await Promise.all([
      reportsListService.getExpenseReportsList(
        currentPage + 1,
        reportsStatusType,
        searchFitler,
      ),
      reportsListService.getUserList(),
    ]);

    const error = reportsError || usersError;

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const reportsWithUsers = expensesHelper.getReportsWithUsers(
      reportsData!.expenseReports,
      usersData!,
    );

    setState({
      isLoading: false,
      reports: reports.concat(reportsWithUsers),
      currentPage: currentPage + 1,
      totalPages: reportsData!.totalPages,
    });
  };

  const handleChangeSearch = debounce(
    (searchText) => setState({ searchFitler: searchText }),
    300,
  );

  const handleSelectReport = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const { selectedReportsToPay } = state;

    setState({
      selectedReportsToPay: {
        ...selectedReportsToPay,
        [id]: checked,
      },
    });
  };

  const handleSignalPayment = () => {
    setState({
      isPaymentConfirmationVisible: true,
    });
  };

  const handleClosePaymentDialog = () => {
    setState({
      isPaymentConfirmationVisible: false,
      reportsErrorOnPayment: [],
    });
  };

  const handleProccessSignalPayments = async () => {
    setState({ submittingPayment: true });
    const { selectedReportsToPay } = state;
    const expenseReportTokens = Object.keys(selectedReportsToPay).filter(
      (key) => !!selectedReportsToPay[key],
    );

    const {
      data,
      error,
    } = await reportsListService.signalMultipleReportPayments(
      expenseReportTokens,
    );

    if (error) {
      setState({
        isPaymentConfirmationVisible: false,
        submittingPayment: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const hasAnyErroredReports = data?.some((item) => item.error);

    if (hasAnyErroredReports) {
      handleSignalPaymentWithErroredReports(data!);
    } else {
      handleSignalPaymentSuccess(data!);
    }
  };

  const handleSignalPaymentWithErroredReports = (
    signalItems: SignalPaymentItem[],
  ) => {
    const erroredReports = getSignalPaymentErrorReports(signalItems);
    const updatedReports = getUpdatedSuccessPaymentReports(signalItems);

    setState({
      reports: updatedReports,
      selectedReportsToPay: {},
      submittingPayment: false,
      reportsErrorOnPayment: erroredReports,
    });
  };

  const handleSignalPaymentSuccess = (signalItems: SignalPaymentItem[]) => {
    const updatedReports = getUpdatedSuccessPaymentReports(signalItems);

    setState({
      reports: updatedReports,
      selectedReportsToPay: {},
      isPaymentConfirmationVisible: false,
      submittingPayment: false,
    });

    showSnackMessage(
      "Baixa de relatórios realizada com sucesso",
      ALERT_TYPES.SUCCESS,
    );
  };

  const getSignalPaymentErrorReports = (signalItems: SignalPaymentItem[]) => {
    const { reports } = state;

    const erroredSignalPaymentMap = signalItems.reduce(
      (acc: Record<string, boolean>, item) => {
        if (item.error) {
          acc[item.reportToken] = true;
        }

        return acc;
      },
      {},
    );

    return reports.filter(
      (report) => erroredSignalPaymentMap[report.expenseReportToken],
    );
  };

  const getUpdatedSuccessPaymentReports = (
    signalItems: SignalPaymentItem[],
  ) => {
    const { reports } = state;
    const successReportTokens = signalItems
      .filter((item) => !item.error)
      .map((item) => item.reportToken);

    const updatedReports = reports.filter(
      (report) =>
        !successReportTokens.find(
          (reportToken) => report.expenseReportToken === reportToken,
        ),
    );

    return updatedReports;
  };

  const allowReportPaymentSelection = useMemo(() => {
    return reportsStatusType === "pending-payment";
  }, [reportsStatusType]);

  const hasAnyReportSelected = useMemo(() => {
    return Object.keys(state.selectedReportsToPay).some(
      (reportToken) => !!state.selectedReportsToPay[reportToken],
    );
  }, [state.selectedReportsToPay]);

  useEffect(() => {
    void fetchReports();
  }, [state.searchFitler]);

  return (
    <ApprovalReportsListContext.Provider
      value={{
        ...state,
        allowReportPaymentSelection,
        fetchReports,
        handleChangeSearch,
        handleClosePaymentDialog,
        handleProccessSignalPayments,
        handleSelectReport,
        handleSignalPayment,
        hasAnyReportSelected,
        loadNextReports,
      }}
    >
      {children}
    </ApprovalReportsListContext.Provider>
  );
};

export { ApprovalReportsListContext };
