import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  router: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  }),
};
