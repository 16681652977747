import React from "react";

import { Badge } from "@toolkit/v2";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

type Props = React.ComponentProps<typeof Badge>;

export const ErrorOnFetchOutOfPolicyBadge: React.FC<Props> = ({
  children,
  ...props
}) => {
  return (
    <Badge css={styles.root} {...props}>
      <Icon css={styles.icon} size={16} use="x" />
      {children ? children : "Erro ao buscar política."}
    </Badge>
  );
};
