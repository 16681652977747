import React from "react";

import { Fade as MuiFade, FadeProps as MuiFadeProps } from "@material-ui/core";

import { CircularSpinner } from "@toolkit/v2";

import { Dialog } from "../dialog/dialog";
import { styles } from "./styles";

type Props = Omit<React.ComponentProps<typeof Dialog>, "onClose"> & {
  message?: string;
};

export const LoadingOverlay: React.FC<Props> = ({
  message,
  open,
  ...props
}) => {
  return (
    <Dialog
      css={styles.root}
      open={open}
      TransitionComponent={DialogTransition}
      // transitionDuration={0}
      {...props}
    >
      <CircularSpinner />
      {message ? <span css={styles.message}>{message}</span> : null}
    </Dialog>
  );
};

const DialogTransition = React.forwardRef<unknown, MuiFadeProps>(
  (props, ref) => <MuiFade ref={ref} {...props} />,
);

DialogTransition.displayName = "DialogTransition";
