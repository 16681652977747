import { AxiosResponse } from "axios";

import { APPROVABLE_TYPES } from "@constants";

import {
  ApprovableRuleCreateBody,
  ApprovableRuleEditBody,
  ApprovableRuleCreateResponse,
  ApprovableRuleEditResponse
} from "../dtos/expense-approval";
import {
  getAuthorizationHeader,
  getClientToken,
  getLoggedUserToken
} from "../helpers/user.helper";
import {
  ApprovableRule,
  TargetApproval
} from "../models/expense-approval.model";
import { approvableProcessFactory } from "../parsers/approvable-process.parser";
import { api } from "./interceptors";

export function createApprovableRule(
  dto: ApprovableRuleCreateBody
): Promise<ApprovableRule> {
  return api
    .request({
      url: "/booking/expense/approval",
      method: "POST",
      data: dto,
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovableRuleCreateResponse>) =>
      approvableProcessFactory(response.data.createdApprovalProcess)
    );
}

export function getApprovableRuleList(
  approvableType: string = APPROVABLE_TYPES.EXPENSE_REPORT
): Promise<ApprovableRule[]> {
  return api
    .request({
      url: `/booking/client/${getClientToken()}/expense/approval`,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: { approvableType }
    })
    .then((response: AxiosResponse<ApprovableRule[]>) => response.data);
}

export function editApprovableRule(
  dto: ApprovableRuleEditBody,
  expenseApprovalToken: string
): Promise<ApprovableRule> {
  return api
    .request({
      url: `/booking/expense/approval/${expenseApprovalToken}`,
      method: "PUT",
      data: dto,
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovableRuleEditResponse>) =>
      approvableProcessFactory(response.data.updatedExpenseApprovalProcess)
    );
}

export function getSingleApprovableRule(
  expenseApprovalToken: string
): Promise<ApprovableRule> {
  return api
    .request({
      url: `/booking/expense/approval/${expenseApprovalToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ApprovableRule>) => response.data);
}

export function deleteApprovableRule(
  approvableProcessToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense/approval/${approvableProcessToken}`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
}

export function getApprovalTargets(
  searchString: string,
  approvableType = APPROVABLE_TYPES.EXPENSE_REPORT
) {
  return api
    .request({
      url: `/booking/expense/approval/search/${searchString}`,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: {
        approvableType
      }
    })
    .then((response: AxiosResponse<TargetApproval[]>) =>
      response.data.map(target => ({ ...target, label: target.name }))
    );
}

export function fetchUserApprovalRule(
  approvableType = APPROVABLE_TYPES.EXPENSE_REPORT
) {
  return api
    .request({
      url: `/booking/expense/approval/user/${getLoggedUserToken()}`,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: {
        approvableType
      }
    })
    .then(response => response.data);
}
