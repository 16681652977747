import React from "react";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import {
  Dialog,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";

import {
  useBillingProfileDeletionContext,
  useBillingProfileDeletionActionsContext,
} from "../BillingProfiles.context";

export const BillingProfileDeletionDialog = () => {
  const {
    isDeleting,
    selectedBillingProfile,
  } = useBillingProfileDeletionContext();
  const {
    handleCancelDeletion,
    processDelete,
  } = useBillingProfileDeletionActionsContext();

  const isOpen = !!selectedBillingProfile;
  const isLoading = isDeleting;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancelDeletion}
      disableRestoreFocus
      maxWidth="sm"
    >
      <DialogHeader
        color={theme.colors.pink[700]}
        disabled={isLoading}
        icon="trash"
        onClose={handleCancelDeletion}
      />
      <DialogContent>
        <DialogTitle>Exclusão de perfil de cobrança</DialogTitle>
        <span style={{ fontSize: "1rem", color: theme.colors.gray[300] }}>
          Deseja realmente excluir o perfil de cobrança selecionado?
        </span>
      </DialogContent>
      <DialogFooter>
        <Button
          disabled={isLoading}
          fill="outlined"
          onClick={handleCancelDeletion}
        >
          Cancelar
        </Button>
        <Button disabled={isLoading} onClick={processDelete} variant="pink">
          Excluir
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
