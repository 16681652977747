import React, { useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import DrawerHeader from "../../shared/DrawerHeader";
import { css } from "emotion";
import { Row } from "../../shared/layout/Row";
import { StContainedButton, StOutlinedButton } from "../../shared/buttons";
import { defaultTheme } from "../../../assets/styles/theme";
import { PoliciesContext } from "../trips/policy/policies.context";

const styles = {
  body: css({
    padding: "2rem",
    height: "calc(100vh - 132px)",
    overflowY: "auto"
  }),
  optionsRow: css({
    padding: "1rem 2rem",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    justifyContent: "flex-end"
  }),
  circularProgress: css({
    color: defaultTheme.primaryColor
  })
};

interface Props {
  title: string;
}

const Form = ({ title }: Props) => {
  const { handleFormClose } = useContext(PoliciesContext);

  return (
    <>
      <DrawerHeader title={title} handleClose={handleFormClose} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        className={styles.body}
      >
        <CircularProgress className={styles.circularProgress} />
      </div>
      <footer>
        <Row className={styles.optionsRow}>
          <StOutlinedButton
            style={{ marginRight: "1rem" }}
            onClick={handleFormClose}
          >
            Cancelar
          </StOutlinedButton>
          <StContainedButton isFullWidth={false} disabled={true}>
            Salvar política
          </StContainedButton>
        </Row>
      </footer>
    </>
  );
};

export { Form as EmptyForm };
