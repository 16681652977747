import React, { useMemo } from "react";

import { Breadcrumbs } from "~/apps/shared/components/breadcrumbs/breadcrumbs";

import { useItinerary } from "../../../itinerary/itinerary.context";

export const ApprovalReviewHeaderBreadcrumbs: React.FC = () => {
  const { travelToken } = useItinerary();

  const routes = useMemo(() => {
    return [
      {
        name: "Revisar Solicitação",
        url: `/travels/${travelToken}/approval-review`,
      },
      {
        name: "Realizar Pagamento",
        url: `/travels/${travelToken}/payment`,
      },
    ];
  }, [travelToken]);

  return <Breadcrumbs routes={routes} />;
};
