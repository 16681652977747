import React from "react";
import Skeleton from "react-loading-skeleton";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useItineraryContainer } from "../../itinerary.container";
import { useItinerary } from "../../itinerary.context";
import { ItineraryInfoCategorization } from "./itinerary-info-categorization/itinerary-info-categorization";
import { ItineraryInfoEvent } from "./itinerary-info-event/itinerary-info-event";
import { ItineraryInfoTags } from "./itinerary-info-tags/itinerary-info-tags";
import { ItineraryInfoTravelName } from "./itinerary-info-travel-name/itinerary-info-travel-name";
import { useItineraryInfoGuard } from "./itinerary-info.hooks";
import { styles } from "./styles";

type Props = {
  canEditCategorization?: boolean;
  canEditTravelName?: boolean;
  canEditTravelTags?: boolean;
};

export const ItineraryInfo: React.FC<Props> = ({
  canEditCategorization,
  canEditTravelName,
  canEditTravelTags,
}) => {
  const { infoModel } = useItineraryContainer();

  const { error, loading } = useItineraryInfoGuard();

  const { user } = useUser();

  if (error) {
    return <ItineraryInfoError />;
  }

  if (loading) {
    return <ItineraryInfoSkeleton />;
  }

  if (!infoModel || !user) {
    return null;
  }

  const booker = infoModel.getBooker();

  const eventToken = infoModel.getEventToken();

  const isUserBooker = infoModel.isUserBooker(user);
  const isUserTraveler = infoModel.isUserTraveler(user);

  const traveler = infoModel.getTraveler();

  return (
    <div css={styles.root}>
      <ItineraryInfoTravelName
        infoModel={infoModel}
        isDisabled={!canEditTravelName}
      />
      <div css={styles.period.root}>
        <Icon size={14} use="calendar" />
        <span css={styles.period.text}>{infoModel.getPeriod()}</span>
      </div>
      <div css={styles.users.root}>
        <div css={styles.users.booker.root}>
          <Icon css={styles.users.booker.icon} size={14} use="person-invite" />
          <div css={styles.users.booker.text.root}>
            <span css={styles.users.booker.text.label}>Solicitante:&nbsp;</span>
            <Tooltip
              arrow
              content={`${booker.email}${isUserBooker ? " (você)" : ""}`}
              position="top"
            >
              <span css={styles.users.booker.text.value}>{booker.name}</span>
            </Tooltip>
          </div>
        </div>
        <div css={styles.users.traveler.root}>
          <Icon css={styles.users.traveler.icon} size={14} use="person" />
          <div css={styles.users.traveler.text.root}>
            <span css={styles.users.traveler.text.label}>Viajante:&nbsp;</span>
            <span css={styles.users.traveler.text.value}>
              <Tooltip
                arrow
                content={`${traveler.email}${isUserTraveler ? " (você)" : ""}`}
                position="top"
              >
                <span css={styles.users.traveler.text.value}>
                  {traveler.name}
                </span>
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      {eventToken ? <ItineraryInfoEvent eventToken={eventToken} /> : null}
      <ItineraryInfoCategorization isDisabled={!canEditCategorization} />
      <ItineraryInfoTags isDisabled={!canEditTravelTags} />
    </div>
  );
};

const ItineraryInfoError: React.FC = () => {
  const { travelToken } = useItinerary();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.token}>{travelToken}</span>
        <hr css={styles.divisor} />
      </div>
      <span css={styles.error}>
        Ocorreu um erro. Tente novamente mais tarde.
      </span>
    </div>
  );
};

const ItineraryInfoSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <Skeleton height="29px" width="256px" />
        <hr css={styles.divisor} />
      </div>
      <div css={styles.users.root}>
        <div css={styles.users.booker.root}>
          <Skeleton height="14px" width="144px" />
        </div>
        <div css={styles.users.traveler.root}>
          <Skeleton height="14px" width="144px" />
        </div>
      </div>
      <Skeleton height="14px" width="256px" />
    </div>
  );
};
