import moment, { Moment } from "moment";
import * as yup from "yup";

import { CarLocation } from "../offer-searcher.types";

export const carFormSchema = yup.object().shape({
  dropoffDate: yup
    .mixed<Moment>()
    .required("Preencha a data de devolução")
    .nullable(true),
  dropoffLocation: yup
    .mixed<CarLocation>()
    .test(
      "",
      "Preencha o local de devolução do veículo",
      (dropoffLocation, context) => {
        if (!context.parent.sameLocationDropoff && !dropoffLocation) {
          return false;
        }

        return true;
      },
    )
    .nullable(true),
  dropoffTime: yup.string().required("Preencha o horário de devolução"),
  pickupDate: yup
    .mixed<Moment>()
    .required("Preencha a data de retirada")
    .test("", "Data inicial inferior a hoje", (pickupDate) => {
      if (!pickupDate) {
        return false;
      }

      const today = moment().startOf("day");

      if (pickupDate.isBefore(today)) {
        return false;
      }

      return true;
    })
    .nullable(true),
  pickupLocation: yup
    .mixed<CarLocation>()
    .required("Preencha o local de retirada do veículo")
    .nullable(true),
  pickupTime: yup.string().required("Preencha o horário de retirada"),
  sameLocationDropoff: yup.boolean(),
});

export type CarFormSchema = yup.InferType<typeof carFormSchema>;
