import React, { useContext } from "react";
import SpinnerPortal from "../shared/Spinner";
import { FinancialProvider, FinancialContext } from "./Financial.context";
import { FinancialTabs } from "./FinancialTabs";
import { InvoicesList } from "./InvoicesList/InvoicesList";
import Layout from "../shared/Layout";
import { css } from "emotion";
import PageTitle from "../shared/PageTitle";
import TITLES from "../../constants/page-titles.constants";

const styles = {
  container: css({
    width: "100%",
    paddingRight: "16px",
    paddingLeft: "16px",
    marginRight: "auto",
    marginLeft: "auto",
    ["@media (min-width: 1250px)"]: {
      maxWidth: 1250
    }
  }),
  title: css({
    fontWeight: "bold",
    fontSize: 24
  })
};

const Financial = () => {
  const { visibleTab, loading, handleTabChange } = useContext(FinancialContext);

  return (
    <Layout>
      <PageTitle title={TITLES.FINANCIAL} />
      <SpinnerPortal visible={loading} />
      <div className={styles.container}>
        <div style={{ padding: "2.5rem 0" }}>
          <span className={styles.title}>Financeiro</span>
        </div>
        <div>
          <FinancialTabs
            activeTab={visibleTab}
            handleTabChange={handleTabChange}
          />
          <div>
            <InvoicesList />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const Container = () => (
  <FinancialProvider>
    <Financial />
  </FinancialProvider>
);

export { Container as Financial };
