import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import alertReducer from "./alert.reducer";
import snackbackReducer from "./snackbar.reducer";
import { userCreationReducer } from "./user-creation.reducer";

let reducers = combineReducers({
  user: userReducer,
  alert: alertReducer,
  snackbar: snackbackReducer,
  userCreation: userCreationReducer
});

export default reducers;
