import React from "react";
import { Controller } from "react-hook-form";

import { css } from "@styled-system/css";
import isEmpty from "lodash/isEmpty";

import { Box, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { Input } from "@shared/inputs";

import { StContainedButton } from "@components/shared/buttons";
import { useForm } from "@components/shared/form";

import {
  useSupportEvaluationActionsContext,
  useSupportEvaluationContext
} from "../SupportEvaluation.context";
import * as validations from "../validations";
import { SatisfactionInput } from "./SatisfactionInput";
import { SolvedInput } from "./SolvedInput";

const EvaluationForm = () => {
  const { control, submitForm, setValue, errors } = useForm({
    onSubmit: handleSubmitEvaluation,
    schema: validations.formSchema,
    defaultValues: {
      satisfactionRate: "",
      solved: null,
      userComment: ""
    }
  });

  const handleSolvedClick = value => () => {
    setValue("solved", value, true);
  };

  const { isSubmitting } = useSupportEvaluationContext();
  const { handleSubmitEvaluation } = useSupportEvaluationActionsContext();

  const isValid = isEmpty(errors);

  return (
    <form onSubmit={submitForm}>
      <Box textAlign="center">
        <Controller
          as={SatisfactionInput}
          name="satisfactionRate"
          control={control}
          error={errors.satisfactionRate}
        />
        <Controller
          as={SolvedInput}
          name="solved"
          onClick={handleSolvedClick}
          control={control}
          error={errors.solved}
        />
        <Box py={["large"]} maxWidth={["auto", "700px"]} mx={["auto"]}>
          <Box mb="large">
            <Text fontSize={[2]} fontWeight="bold">
              Nos deixe seu comentário:{" "}
              <Text color={defaultTheme.subTextColor} fontSize={[0]}>
                (opcional)
              </Text>
            </Text>
          </Box>
          <Controller
            as={
              <Input
                name="userComment"
                rows={6}
                rowsMax={6}
                multiline={true}
                placeholder="Avalie seu atendimento ou nos deixe um comentário :)"
                InputProps={{
                  style: { padding: 0 }
                }}
              />
            }
            name="userComment"
            control={control}
          />
        </Box>
        <StContainedButton
          isFullWidth={false}
          type="submit"
          css={css({
            py: "small",
            px: "medium"
          })}
          disabled={!isValid || isSubmitting}
          loading={isSubmitting}
        >
          Enviar avaliação
        </StContainedButton>
      </Box>
    </form>
  );
};

export { EvaluationForm };
