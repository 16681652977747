import React, { useContext, useState, useEffect } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import isEmpty from "lodash/isEmpty";

import { HotelReviewItem } from "@models/hotel-review.model";

import { useParams } from "@hooks";

import * as userReviewsService from "./UserReviews.service";

interface PageActions {
  handleCloseReviewDrawer: () => void;
  handleReview: (review: HotelReviewItem) => () => void;
  loadPage: () => void;
  onHotelReviewSuccess: (data: any) => void;
}

type PageState = {
  doneReviews: HotelReviewItem[];
  isFetching: boolean;
  isPageMounted: boolean;
  pendingReviews: HotelReviewItem[];
  selectedReview: HotelReviewItem | null;
};

const initialPageState: PageState = {
  doneReviews: [],
  isFetching: false,
  isPageMounted: false,
  pendingReviews: [],
  selectedReview: null,
};

const PageContext = React.createContext<PageState>({} as PageState);
const PageActionsContext = React.createContext<PageActions>({} as PageActions);

export const useUserReviewsContext = () => useContext(PageContext);
export const useUserReviewsActionsContext = () =>
  useContext(PageActionsContext);

export const UserReviewsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [pageState, setPageState] = useState(initialPageState);

  const pageParams: any = useParams();

  const loadPage = async () => {
    setPageState((prev) => ({ ...prev, isFetching: true }));

    const {
      data: hotelReviewsData,
      error: hotelReviewsError,
    } = await userReviewsService.getUserHotelReviews();

    if (hotelReviewsError) {
      setPageState((prev) => ({ ...prev, isFetching: false }));

      showSnackMessage(hotelReviewsError.description, ALERT_TYPES.ERROR);

      return;
    }

    const hotelReviews = hotelReviewsData!.reduce(
      (acc: any, hotelReview) => {
        if (hotelReview.reviewed) {
          acc.doneReviews.push(hotelReview);
        } else {
          acc.pendingReviews.push(hotelReview);
        }

        return acc;
      },
      {
        doneReviews: [],
        pendingReviews: [],
      },
    );

    setPageState((prev) => ({
      ...prev,
      doneReviews: hotelReviews.doneReviews,
      isFetching: false,
      isPageMounted: true,
      pendingReviews: hotelReviews.pendingReviews,
    }));
  };

  const handlePreSelectOfferToReview = (offerToken: string) => {
    const pendingReviewOffer = pageState.pendingReviews.find(
      (reviewItem) => reviewItem.offerToken === offerToken,
    );

    if (!pendingReviewOffer) {
      showSnackMessage(
        "Não foi possível encontrar a oferta a ser avaliada",
        ALERT_TYPES.ERROR,
      );

      return;
    }

    setPageState((prev) => ({
      ...prev,
      selectedReview: pendingReviewOffer,
    }));
  };

  const handleReview = (review: HotelReviewItem) => () => {
    setPageState((prev) => ({ ...prev, selectedReview: review }));
  };

  const handleCloseReviewDrawer = () => {
    setPageState((prev) => ({ ...prev, selectedReview: null }));
  };

  const onHotelReviewSuccess = (data: any) => {
    const selectedReview = pageState.pendingReviews.find(
      (review) => review.offerToken === review.offerToken,
    );
    const updatedPendingReviews = pageState.pendingReviews.filter(
      (review) => review.offerToken !== data.offerToken,
    );

    const newDoneReview = Object.assign(selectedReview, data, {
      reviewed: 1,
    });

    setPageState((prev) => ({
      ...prev,
      doneReviews: prev.doneReviews.concat(newDoneReview),
      pendingReviews: updatedPendingReviews,
      selectedReview: null,
    }));
  };

  useEffect(() => {
    if (
      pageState.isPageMounted &&
      !isEmpty(pageParams) &&
      pageParams.offerToken
    ) {
      handlePreSelectOfferToReview(pageParams.offerToken);
    }
  }, [pageState.isPageMounted]);

  return (
    <PageContext.Provider value={pageState}>
      <PageActionsContext.Provider
        value={{
          handleCloseReviewDrawer,
          handleReview,
          loadPage,
          onHotelReviewSuccess,
        }}
      >
        {children}
      </PageActionsContext.Provider>
    </PageContext.Provider>
  );
};
