import React from "react";
import { css } from "emotion";
import { defaultTheme } from "@theme";
import { MEDIA_QUERIES, EXPENSE_REPORTS_STATUS_TRANSLATION } from "@constants";
import { StContainedButton } from "@shared/buttons";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    backgroundColor: defaultTheme.applicationBackground,
    marginBottom: "2rem",
    width: "100%",
    color: defaultTheme.textColor,
    lineHeight: 1.5,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 0,
      marginBottom: 0
    }
  }),
  segment: css({
    padding: "1rem 2rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  lastSegment: css({
    padding: "1rem 2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  title: css({
    padding: "1.5rem 1.25rem",
    fontWeight: "bold",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  subTitle: css({
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: "1rem"
  }),
  button: css({
    fontSize: 14
  })
};

interface Props {
  status: number;
  isProcessingCancelation: boolean;
  handleCancelApprovalRequest(): void;
}

const ApprovalSentCard = ({
  status,
  isProcessingCancelation,
  handleCancelApprovalRequest
}: Props) => {
  const STATUS_TRANSLATION: {
    [key: number]: string;
  } = EXPENSE_REPORTS_STATUS_TRANSLATION;

  return (
    <div className={styles.root}>
      <div className={styles.title}>Pedido de aprovação enviado</div>
      <section className={styles.segment}>
        <div>
          <div>
            <span className={styles.subTitle}>Status: </span>
            <div style={{ fontSize: 14 }}>{STATUS_TRANSLATION[status]}</div>
          </div>
        </div>
      </section>
      <section className={styles.segment}>
        <div style={{ fontSize: 14 }}>
          <span>
            Seu pedido de aprovação já foi enviado para que seus aprovadores
            possam revisá-lo, agora basta aguardar sua resposta 😉
          </span>
        </div>
      </section>
      <section className={styles.lastSegment}>
        <StContainedButton
          color="secondary"
          className={styles.button}
          disabled={isProcessingCancelation}
          loading={isProcessingCancelation}
          onClick={handleCancelApprovalRequest}
        >
          Cancelar pedido de aprovação
        </StContainedButton>
      </section>
    </div>
  );
};

export { ApprovalSentCard };
