import hotelApi from "~/apps/corporate/apis/hotel.api";
import {
  LoadHotelsResponse,
  SetFavouriteResponse,
} from "~/apps/corporate/dtos/hotel.dto";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { FavouriteTargetType } from "./hotels.types";

export async function loadHotelsInfo(
  hotelToken: string,
): Promise<{ data?: LoadHotelsResponse; error?: Error }> {
  const result: { data?: LoadHotelsResponse; error?: Error } = {};

  try {
    result.data = await hotelApi.loadHotels(hotelToken);
  } catch (error) {
    if (error.response) {
      if (error.response.data) {
        const type: string = error.response.data.type;

        result.error = ERROR[type as keyof typeof ERROR] as Error;
      } else {
        result.error = ERROR.UNEXPECTED_LOAD_HOTEL_INFO_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_HOTEL_INFO_ERROR;
    }
  }

  return result;
}

export async function removeFromFavorites(
  favouriteToken: string,
): Promise<{ data?: any; error?: Error }> {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await hotelApi.removeHotelFavorite(favouriteToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR;
    }
  }

  return result;
}

export async function setFavorite(
  hotel: {
    hotelToken: string;
    name: string;
  },
  targetType: FavouriteTargetType,
  targetToken: string,
): Promise<{ data?: SetFavouriteResponse; error?: Error }> {
  const result: { data?: SetFavouriteResponse; error?: Error } = {};

  try {
    const requestData = {
      hotel_identification: hotel.hotelToken,
      hotel_name: hotel.name,
      target_token: targetToken,
      target_type: targetType,
    };

    result.data = await hotelApi.setHotelFavorite(requestData);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR;
    }
  }

  return result;
}
