import React, { useState, useMemo, MouseEvent } from "react";
import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import FormControl from "@material-ui/core/FormControl";
import { Option } from "~/types";
import { Select } from "@shared/inputs";
import { ActionMeta } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

interface State {
  anchorEl: React.ReactNode | null;
  isFilterSelected: boolean;
  projectToken: string;
  wasFilterApplied: boolean;
}

const initialState: State = {
  anchorEl: null,
  isFilterSelected: false,
  projectToken: "",
  wasFilterApplied: false
};

interface Props {
  options?: Array<Option<string>>;
  onApply: (projectToken: string) => void;
  containerStyles?: React.CSSProperties;
}

const ProjectFilter: React.FC<Props> = ({
  options = [],
  onApply,
  containerStyles = {}
}) => {
  const [state, setState] = useState(initialState);

  const selectedProject = useMemo(() => {
    return state.projectToken
      ? options.find(project => project.value === state.projectToken)
      : null;
  }, [state.projectToken, options]);

  const handleApply = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      isFilterSelected: !!state.projectToken,
      wasFilterApplied: true
    }));
    onApply(state.projectToken);
  };

  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      projectToken: "",
      isFilterSelected: false,
      wasFilterApplied: false
    }));
    onApply("");
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { target } = e;
    setState(prevState => ({
      ...prevState,
      anchorEl: target,
      isFilterSelected: true
    }));
  };

  const handleChange = (option: OptionType | null, event: ActionMeta) => {
    setState(prevState => ({
      ...prevState,
      projectToken: option && option.value ? option.value : "",
      isFilterSelected: true,
      wasFilterApplied: false
    }));
    if (event.action === "clear") {
      onApply("");
    }
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      projectToken: state.wasFilterApplied ? state.projectToken : "",
      isFilterSelected: state.wasFilterApplied ? state.wasFilterApplied : false
    }));
  };

  return (
    <FilterButtonPopover
      anchorEl={state.anchorEl}
      isFilterSelected={state.isFilterSelected}
      isOpen={!!state.anchorEl}
      applyEnabled
      clearEnabled
      showClearButton={!!state.projectToken}
      onApplyClick={handleApply}
      onClearClick={handleClear}
      onButtonClick={handleClick}
      onPopoverClose={handleClose}
      unselectedButtonText={"Projeto"}
      selectedButtonText={selectedProject ? selectedProject.label : "Projeto"}
      containerStyle={containerStyles}
    >
      <div>
        <FormControl style={{ width: "240px" }}>
          <div>
            <Select
              placeholder="Buscar projeto"
              value={selectedProject}
              options={options}
              onChange={handleChange}
            />
          </div>
        </FormControl>
      </div>
    </FilterButtonPopover>
  );
};

export default ProjectFilter;
