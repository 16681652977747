import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: {
    root: css({
      alignItems: "center",
      color: theme.colors.gray[700],
      display: "flex",
      flexDirection: "column",
      fontWeight: 600,
      gap: "8px",
      textAlign: "center",
    }),
  },
  navigate: {
    root: css({
      paddingBottom: "24px",
    }),
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    maxWidth: "512px",
    width: "100%",
  }),
};
