import React, { useState } from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { darken } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";

import { Input } from "~/apps/shared/components/input/input";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

const styles = {
  root: css({
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  }),
  input: css({
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    width: "100%",
    height: "3.5rem",
    padding: "0.75rem 1rem",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.75rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }),
  copyIcon: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "2.5rem",
    height: "2.5rem",
    backgroundColor: theme.colors.gray[700],
    color: "white",
    padding: "0.25rem",
    borderRadius: "0.25rem",
    cursor: "pointer",
    ["&:hover"]: {
      backgroundColor: darken(theme.colors.gray[400], 0.2),
      transition: "0.3s"
    }
  })
};

type CopyInputProps = {
  text: string;
};

export const CopyInput: React.FC<CopyInputProps> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = (): Promise<void> => {
    return navigator.clipboard.writeText(text);
  };

  const handleCopyClick = async () => {
    await copyTextToClipboard();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div css={styles.root}>
      <Input css={styles.input} value={text} disabled />
      <Tooltip
        content={isCopied ? "Copiado!" : "Copiar"}
        position="right"
        arrow
      >
        <div css={styles.copyIcon} onClick={handleCopyClick}>
          <CopyIcon style={{ fontSize: 16 }} />
        </div>
      </Tooltip>
    </div>
  );
};
