import React, { useMemo } from "react";

import { FlightOutOfPolicyDialog as OutOfPolicyDialog } from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";
import moment from "moment";

import { useFlights } from "../../../flights.context";

export const FlightOutOfPolicyDialog: React.FC = () => {
  const {
    flightInfo,
    generalInfo,
    handlePolicyDialogClose,
    isPolicyDialogOpen,
    policy,
    selectedFlight,
    selectedOutOfPolicy,
  } = useFlights();

  const departure = useMemo(() => {
    if (!flightInfo) {
      return null;
    }

    return selectedFlight
      ? moment(selectedFlight.outbound.departure, "DD/MM/YYYY")
      : moment(flightInfo.outboundFlightDate);
  }, [flightInfo, selectedFlight]);

  const policyOffer = useMemo(() => {
    if (!departure || !selectedOutOfPolicy) {
      return null;
    }

    const today = moment();
    const advance = departure.diff(today, "days");

    return {
      advance,
      allowedClass: selectedOutOfPolicy.allowedClass,
      class: selectedOutOfPolicy.cabinClass,
      price: selectedOutOfPolicy.price,
      priceExcess: generalInfo.cheapestPrice
        ? selectedOutOfPolicy.price / generalInfo.cheapestPrice
        : null,
    };
  }, [departure, generalInfo, selectedOutOfPolicy]);

  if (flightInfo && isPolicyDialogOpen && policyOffer) {
    return (
      <OutOfPolicyDialog
        offer={policyOffer}
        onClose={handlePolicyDialogClose}
        open
        policy={policy}
      />
    );
  }

  return null;
};
