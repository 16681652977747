import React, { useMemo, useState } from "react";
import {
  ArrayField,
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from "react-hook-form";

import { FormGroup } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";

import {
  APPROVAL_POLICY_CONDITION,
  APPROVAL_PROCESS_TYPES,
  APPROVAL_RULES_TERRITORIES,
} from "~/constants";

import { ApprovalProcessRulesType } from "~/models/approval-process.model";

import { useFormContext } from "~/components/shared/form";
import { FormFieldAdditionalText } from "~/components/shared/form/FormField";

import IconButton from "@components/shared/icon-button";

import { ApprovalProcessesFormSelect } from "../approval-process-form-select/approval-process-form-select";
import {
  APPROVAL_PROCESS_TYPES_OPTIONS,
  POLICIES_OPTIONS,
  TERRITORIES_OPTIONS,
} from "../approval-process-options/approval-process-options";
import { ApprovalProcessRuleActions } from "../approval-process-rule-actions/approval-process-rule-actions";
import { styles } from "../styles";

interface IApprovalProcessApproveRule {
  control: Control;
  errors: DeepMap<FieldValues, FieldError>;
  field: Partial<ArrayField<ApprovalProcessRulesType, "id">>;
  isSingleApprover: boolean;
  isUniqueApprovalCreation: boolean;
  removeRule: (index: number) => void;
  ruleIndex: number;
  watchFields: {
    rules: ApprovalProcessRulesType[];
    approvalNeeded: boolean;
  };
}

export const ApprovalProcessApproveRule = ({
  control,
  errors,
  field,
  isSingleApprover,
  isUniqueApprovalCreation,
  removeRule,
  ruleIndex,
  watchFields,
}: IApprovalProcessApproveRule) => {
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const handleMouseEnter = () => setShowRemoveButton(true);
  const handleMouseLeave = () => setShowRemoveButton(false);

  const { watch, setValue } = useFormContext();
  const rule: ApprovalProcessRulesType = watch(`rules[${ruleIndex}]`);

  const thereIsARuleThatCoversAll = (
    rules: ApprovalProcessRulesType[],
  ): boolean => {
    const rule = rules.find(
      (rule) =>
        rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL &&
        rule.territory?.value === APPROVAL_RULES_TERRITORIES.ALL,
    );

    if (rule) {
      return true;
    }

    return false;
  };

  const selectedRulesAlreadyCoversAllCases = (
    selectedRules: ApprovalProcessRulesType[],
  ): boolean => {
    const ruleNational = selectedRules.find(
      (rule) =>
        rule.territory?.value === APPROVAL_RULES_TERRITORIES.NATIONAL &&
        rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL,
    );

    const ruleInternational = selectedRules.find(
      (rule) =>
        rule.territory?.value === APPROVAL_RULES_TERRITORIES.INTERNATIONAL &&
        rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL,
    );

    if (ruleNational && ruleInternational) {
      return true;
    }

    return false;
  };

  const availablePoliciesOptions = useMemo(() => {
    let policiesOptions = [...POLICIES_OPTIONS];

    const rules = watchFields.rules.filter(
      (r) => r.type.value === APPROVAL_PROCESS_TYPES.APPROVE,
    );

    if (thereIsARuleThatCoversAll(rules)) {
      policiesOptions = [];
    }

    if (selectedRulesAlreadyCoversAllCases(rules)) {
      policiesOptions = [];
    }

    if (rules.length) {
      const rulesPerPolicy: Record<
        string,
        Array<ApprovalProcessRulesType>
      > = rules.reduce(
        (acc: Record<string, Array<ApprovalProcessRulesType>>, rule) => {
          if (rule.policy?.value === APPROVAL_POLICY_CONDITION.IN_POLICY) {
            acc[APPROVAL_POLICY_CONDITION.IN_POLICY].push(rule);
          }

          if (rule.policy?.value === APPROVAL_POLICY_CONDITION.OUT_OF_POLICY) {
            acc[APPROVAL_POLICY_CONDITION.OUT_OF_POLICY].push(rule);
          }

          if (rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL) {
            acc[APPROVAL_POLICY_CONDITION.ALL].push(rule);
          }

          return acc;
        },
        {
          [APPROVAL_POLICY_CONDITION.OUT_OF_POLICY]: [],
          [APPROVAL_POLICY_CONDITION.IN_POLICY]: [],
          [APPROVAL_POLICY_CONDITION.ALL]: [],
        },
      );

      Object.keys(APPROVAL_POLICY_CONDITION).forEach((key: string) => {
        const typedKey = key as keyof {
          ALL: string;
          IN_POLICY: string;
          OUT_OF_POLICY: string;
        };

        if (
          rulesPerPolicy[APPROVAL_POLICY_CONDITION[typedKey]].length > 1 ||
          (rulesPerPolicy[APPROVAL_POLICY_CONDITION[typedKey]].length === 1 &&
            rulesPerPolicy[APPROVAL_POLICY_CONDITION[typedKey]][0]?.territory
              ?.value === APPROVAL_POLICY_CONDITION.ALL)
        ) {
          policiesOptions = policiesOptions.filter(
            (policy) => policy.value !== APPROVAL_POLICY_CONDITION[typedKey],
          );
        }
      });
    }

    rules.forEach((rule) => {
      if (
        rule.policy?.value === APPROVAL_POLICY_CONDITION.IN_POLICY ||
        rule.policy?.value === APPROVAL_POLICY_CONDITION.OUT_OF_POLICY
      ) {
        policiesOptions = policiesOptions.filter(
          (policy) => policy.value !== APPROVAL_POLICY_CONDITION.ALL,
        );
      }
    });

    const ruleSelected = watchFields.rules.find(
      (_, index) => index === ruleIndex,
    );

    if (
      ruleSelected?.policy &&
      ruleSelected.policy.value === rule.policy?.value &&
      !policiesOptions.find(
        (policy) => policy.value === ruleSelected.policy?.value,
      )
    ) {
      policiesOptions.unshift(ruleSelected.policy);
    }

    if (ruleSelected?.policy?.value === APPROVAL_POLICY_CONDITION.ALL) {
      policiesOptions = [...POLICIES_OPTIONS];
    }

    const onlyOneRuleSeleted = (): boolean => {
      const rulesSelected = rules.filter(
        (rule) => typeof rule.policy?.value !== "undefined",
      );

      if (rulesSelected.length === 1) {
        return true;
      }

      return false;
    };

    if (
      (ruleSelected?.policy?.value === APPROVAL_POLICY_CONDITION.IN_POLICY ||
        ruleSelected?.policy?.value ===
          APPROVAL_POLICY_CONDITION.OUT_OF_POLICY) &&
      ruleIndex === 0 &&
      onlyOneRuleSeleted()
    ) {
      policiesOptions.push(POLICIES_OPTIONS[2]);
    }

    return policiesOptions;
  }, [rule.policy?.value, ruleIndex, watchFields.rules]);

  const availableTerritoriesOptions = useMemo(() => {
    let territoriesOptions = [...TERRITORIES_OPTIONS];

    const rules = watchFields.rules.filter(
      (r) => r.type.value === APPROVAL_PROCESS_TYPES.APPROVE,
    );

    if (thereIsARuleThatCoversAll(rules)) {
      territoriesOptions = [];
    }

    if (availablePoliciesOptions.length === 0) {
      territoriesOptions = [];
    }

    const rulesWithPolicyAlreadySelected = watchFields.rules.filter(
      (r) =>
        r.policy?.value === rule.policy?.value &&
        r.type.value === APPROVAL_PROCESS_TYPES.APPROVE,
    );

    if (rulesWithPolicyAlreadySelected.length) {
      rulesWithPolicyAlreadySelected.forEach((rule) => {
        territoriesOptions = territoriesOptions.filter(
          (territory) => territory.value !== rule.territory?.value,
        );

        if (
          rule.territory?.value === APPROVAL_RULES_TERRITORIES.NATIONAL ||
          rule.territory?.value === APPROVAL_RULES_TERRITORIES.INTERNATIONAL
        ) {
          territoriesOptions = territoriesOptions.filter(
            (territory) => territory.value !== APPROVAL_RULES_TERRITORIES.ALL,
          );
        }

        if (rule.territory?.value === APPROVAL_RULES_TERRITORIES.ALL) {
          territoriesOptions = [];
        }
      });
    }

    rules.forEach((rule) => {
      if (
        rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL &&
        rule.territory?.value === APPROVAL_RULES_TERRITORIES.NATIONAL
      ) {
        territoriesOptions = territoriesOptions.filter(
          (territory) =>
            territory.value === APPROVAL_RULES_TERRITORIES.INTERNATIONAL,
        );
      }

      if (
        rule.policy?.value === APPROVAL_POLICY_CONDITION.ALL &&
        rule.territory?.value === APPROVAL_RULES_TERRITORIES.INTERNATIONAL
      ) {
        territoriesOptions = territoriesOptions.filter(
          (territory) =>
            territory.value === APPROVAL_RULES_TERRITORIES.NATIONAL,
        );
      }
    });

    const ruleSelected = watchFields.rules.find(
      (_, index) => index === ruleIndex,
    );

    if (
      ruleSelected?.territory &&
      ruleSelected.territory.value === rule.territory?.value
    ) {
      territoriesOptions.unshift(ruleSelected.territory);
    }

    const selectedRulesAlreadyCoversAllCases = (): boolean => {
      const rules = rulesWithPolicyAlreadySelected.filter(
        (rule) =>
          rule.territory?.value === APPROVAL_RULES_TERRITORIES.NATIONAL ||
          rule.territory?.value === APPROVAL_RULES_TERRITORIES.INTERNATIONAL,
      );

      if (rules.length > 1) {
        return true;
      }

      return false;
    };

    if (
      (ruleSelected?.territory?.value === APPROVAL_RULES_TERRITORIES.NATIONAL ||
        ruleSelected?.territory?.value ===
          APPROVAL_RULES_TERRITORIES.INTERNATIONAL) &&
      !selectedRulesAlreadyCoversAllCases()
    ) {
      territoriesOptions.push(TERRITORIES_OPTIONS[2]);
    }

    if (ruleSelected?.territory?.value === APPROVAL_RULES_TERRITORIES.ALL) {
      territoriesOptions = [...TERRITORIES_OPTIONS];
    }

    return territoriesOptions;
  }, [
    availablePoliciesOptions.length,
    rule.policy?.value,
    rule.territory?.value,
    ruleIndex,
    watchFields.rules,
  ]);

  return (
    <div
      className={styles.groupContainer}
      key={field.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.messageContainer}>
          <FormGroup className={styles.formGroup}>
            <Controller
              control={control}
              defaultValue={rule.type}
              name={`rules[${ruleIndex}].type`}
              render={({ onChange, value }) => (
                <ApprovalProcessesFormSelect
                  options={
                    watchFields.approvalNeeded
                      ? APPROVAL_PROCESS_TYPES_OPTIONS
                      : [APPROVAL_PROCESS_TYPES_OPTIONS[1]]
                  }
                  defaultValue={
                    watchFields.approvalNeeded
                      ? APPROVAL_PROCESS_TYPES_OPTIONS[0]
                      : APPROVAL_PROCESS_TYPES_OPTIONS[1]
                  }
                  onChange={(e) => {
                    onChange(e);
                    setValue(`rules[${ruleIndex}].policy`, undefined);
                    setValue(`rules[${ruleIndex}].territory`, undefined);
                  }}
                  value={value}
                />
              )}
            />
          </FormGroup>
          <span>para viagens</span>
          <FormGroup className={styles.formGroup}>
            <Controller
              as={
                <ApprovalProcessesFormSelect
                  noOptionsMessage={() => "Todas as opções foram contempladas"}
                  options={availablePoliciesOptions}
                  placeholder="dentro ou fora"
                />
              }
              control={control}
              defaultValue={rule.policy}
              name={`rules[${ruleIndex}].policy`}
            />
            <FormFieldAdditionalText className={styles.messageError} hasError>
              {errors.rules?.[ruleIndex]?.policy?.message}
            </FormFieldAdditionalText>
          </FormGroup>
          <span>da política no âmbito</span>
          <FormGroup className={styles.formGroup}>
            <Controller
              as={
                <ApprovalProcessesFormSelect
                  options={availableTerritoriesOptions}
                  placeholder="nacional ou internacional"
                  noOptionsMessage={() => "Todas as opções foram contempladas"}
                />
              }
              control={control}
              defaultValue={rule.territory}
              name={`rules[${ruleIndex}].territory`}
            />
            <FormFieldAdditionalText className={styles.messageError} hasError>
              {errors.rules?.[ruleIndex]?.territory?.message}
            </FormFieldAdditionalText>
          </FormGroup>
          <span>para os seguintes usuários:</span>
        </div>
        {showRemoveButton && !isSingleApprover ? (
          <IconButton
            icon={<CloseIcon />}
            onClick={() => removeRule(ruleIndex)}
            style={{ width: "2rem", height: "2rem", marginRight: "0.25rem" }}
          />
        ) : null}
      </div>
      <ApprovalProcessRuleActions
        control={control}
        ruleIndex={ruleIndex}
        isSingleApprover={isSingleApprover}
        isUniqueApprovalCreation={isUniqueApprovalCreation}
      />
    </div>
  );
};
