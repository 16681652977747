import React, { useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useReinviteUserMutation } from "../../../users.hooks";
import { styles } from "./styles";

type Props = {
  userToken: string;
};

export const UserFormGeneralInfoReinviteButton: React.FC<Props> = ({
  userToken,
}) => {
  const { showSnackMessage } = useApplication();

  const { reinviteUser, reinviteUserStatus } = useReinviteUserMutation(
    userToken,
  );

  const handleReenvite = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      const reinviteUserResponse = await reinviteUser();

      if (!reinviteUserResponse) {
        return;
      }

      if (reinviteUserResponse.isFailure()) {
        const error = reinviteUserResponse.data;

        showSnackMessage(
          error.description ||
            "Não foi possível enviar o convite para o usuário",
          ALERT_TYPES.ERROR,
        );

        return;
      }

      showSnackMessage("Convite enviado com sucesso!", ALERT_TYPES.SUCCESS);
    },
    [reinviteUser, showSnackMessage],
  );

  return (
    <Button
      css={styles.root}
      disabled={reinviteUserStatus === "success"}
      onClick={async (e) => {
        await handleReenvite(e);
      }}
      type="button"
    >
      {reinviteUserStatus === "loading" ? (
        <CircularSpinner css={styles.loading} size={18} />
      ) : null}
      {reinviteUserStatus === "success" ? (
        <>
          <Icon size={18} use="check" />
          Reenviado
        </>
      ) : null}
      {reinviteUserStatus === "idle" || reinviteUserStatus === "error"
        ? "Reenviar convite"
        : null}
    </Button>
  );
};
