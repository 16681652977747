import React, { ReactNode } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

import {
  StOutlinedButton,
  StContainedButton,
} from "@components/shared/buttons";

const styles = {
  dialog: css({
    zIndex: 1301,
  }),
  dialogTitle: css({
    fontSize: "20px",
    fontFamily: "'Open sans', sans-serif",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 400px)"]: {
      fontSize: 16,
    },
  }),
  dialogPaper: css({
    maxWidth: 700,
    ["@media (max-width: 767px)"]: {
      margin: 20,
    },
  }),
  dialogText: css({
    fontFamily: "'Open sans', sans-serif",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 400px)"]: {
      fontSize: 14,
    },
  }),
  dialogActionsDiv: css({
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    margin: 0,
    padding: "1rem 0.5rem",
  }),
  deleteButton: css({
    padding: ".5rem 1.5rem",
  }),
};

interface Props {
  isOpen: boolean;
  title: string;
  message: ReactNode | string;
  confirmationButtonLabel: string;
  handleConfirmation: () => void;
  handleClose: () => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ToggleStatusDialog = ({
  isOpen,
  title,
  message,
  confirmationButtonLabel,
  handleConfirmation,
  handleClose,
}: Props) => {
  return (
    <Dialog
      className={styles.dialog}
      classes={{
        paper: styles.dialogPaper,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <span className={styles.dialogTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className={styles.dialogText}>{message}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.dialogActionsDiv}>
        <StOutlinedButton onClick={handleClose}>Cancelar</StOutlinedButton>
        <div>
          <StContainedButton
            onClick={handleConfirmation}
            color="primary"
            className={styles.deleteButton}
          >
            {confirmationButtonLabel}
          </StContainedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export { ToggleStatusDialog };
