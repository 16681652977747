import React from "react";
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps
} from "@material-ui/core";

type IconButtonProps = Omit<MuiIconButtonProps, "children"> & {
  icon: JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export default function IconButton(props: IconButtonProps) {
  const { icon, onClick, size = "small", ...rest } = props;

  return (
    <MuiIconButton onClick={onClick} size={size} {...rest}>
      {icon}
    </MuiIconButton>
  );
}
