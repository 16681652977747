import { useEffect, useRef, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ERROR } from "~/apps/shared/constants/errors";
import { PageError } from "~/apps/shared/types";

import { useItineraryApproval } from "../../itinerary-approval.context";
import { useItineraryInfo } from "../../itinerary-info.context";
import { useItineraryServices } from "../../itinerary-services.context";
import { useItineraryContainer } from "../../itinerary.container";
import { useItinerary } from "../../itinerary.context";

export const useItineraryApprovalRequestGuard = () => {
  const {
    infoModel,
    travelApprovalHistoryModel,
    travelApprovalStatusModel,
  } = useItineraryContainer();

  const { user } = useUser();

  const { travelToken } = useItinerary();
  const {
    errorOnFetchApprovalHistory,
    errorOnFetchApprovalStatus,
  } = useItineraryApproval();
  const { errorOnFetch: errorOnFetchItineraryInfo } = useItineraryInfo();
  const {
    errorOnFetch: errorOnFetchItineraryServices,
    services,
  } = useItineraryServices();

  const [error, setError] = useState<PageError | null>(null);

  const hasCheckedIfShouldRedirect = useRef(false);

  useEffect(() => {
    if (
      !infoModel ||
      !services ||
      !travelApprovalHistoryModel ||
      !travelApprovalStatusModel ||
      !user
    ) {
      return;
    }

    if (hasCheckedIfShouldRedirect.current) {
      return;
    }

    hasCheckedIfShouldRedirect.current = true;

    const isUserBooker = infoModel.isUserBooker(user);

    const isUserMasterApprover = user.isMasterApprover();

    const isUserAuthorizedToAccessApprovalRequest =
      isUserBooker || isUserMasterApprover;

    if (!isUserAuthorizedToAccessApprovalRequest) {
      setError({
        ...ERROR.UNAUTHORIZED_PROCEED_FROM_TRAVEL_PLAN,
        navigateMessage: "Voltar ao plano de viagem",
        navigateTo: () => {
          navigate(`/travels/${travelToken}/itinerary/travel-plan`);
        },
      });

      return;
    }

    if (travelApprovalHistoryModel.isPendingApproval()) {
      setError({
        ...ERROR.UNEXPECTED_PENDING_APPROVAL_TRAVEL_APPROVAL_REQUEST_ACCESS,
        navigateMessage: "Voltar ao plano de viagem",
        navigateTo: () => {
          navigate(`/travels/${travelToken}/itinerary/travel-plan`);
        },
      });

      return;
    }

    if (!travelApprovalStatusModel.mustRequestApproval()) {
      setError({
        ...ERROR.UNEXPECTED_NOT_NEEDED_APPROVAL_REQUEST,
        navigateMessage: "Voltar ao plano de viagem",
        navigateTo: () => {
          navigate(`/travels/${travelToken}/itinerary/travel-plan`);
        },
      });

      return;
    }

    setError(null);
  }, [
    hasCheckedIfShouldRedirect,
    infoModel,
    services,
    travelApprovalHistoryModel,
    travelApprovalStatusModel,
    travelToken,
    user,
  ]);

  return {
    error:
      errorOnFetchApprovalHistory ||
      errorOnFetchApprovalStatus ||
      errorOnFetchItineraryInfo ||
      errorOnFetchItineraryServices ||
      error,
  };
};
