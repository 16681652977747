import React from "react";

import { RangeFilter } from "~/apps/shared/components/range-filter/range-filter";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  disabled: boolean;
  handlePriceFilterChange: (priceRange: number[]) => void;
  priceRange: number[];
  selectedRange: number[];
};

export const PriceFilter: React.FC<Props> = ({
  disabled,
  handlePriceFilterChange,
  priceRange: [min, max],
  selectedRange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Valor total</span>
      <RangeFilter
        defaultValue={selectedRange}
        disabled={disabled}
        formatFunc={toCurrency}
        max={max}
        min={min}
        onRangeDragEnd={handlePriceFilterChange}
      />
    </div>
  );
};
