import React, { createContext, useCallback, useEffect, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import {
  CreateDocumentDto,
  EditDocumentDto,
} from "~/apps/shared/dtos/documents.dto";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Document } from "~/apps/shared/models/documents.model";
import { Error } from "~/apps/shared/types";

import { useApplication } from "./application.context";
import * as documentsService from "./documents.service";
import { useUser } from "./user.context";

interface Actions {
  createDocument: (data: CreateDocumentDto) => Promise<boolean>;
  deleteDocument: (documentToken: string) => Promise<boolean>;
  editDocument: (data: EditDocumentDto) => Promise<boolean>;
  fetchUserDocuments: () => Promise<void>;
}

type State = {
  documents?: Document[] | null;
  errorOnFetchDeleteDocument?: Error | null;
  isLoading?: boolean;
  isLoadingCreateDocument?: boolean;
  isLoadingDeleteDocument?: boolean;
  isLoadingEditDocument?: boolean;
};

const initialState: State = {
  documents: null,
  errorOnFetchDeleteDocument: null,
  isLoading: false,
  isLoadingCreateDocument: false,
  isLoadingDeleteDocument: false,
  isLoadingEditDocument: false,
};

type ContextProps = Actions & State;

const DocumentsContext = createContext<ContextProps>({
  ...initialState,
  createDocument: async () => {
    return false;
  },
  deleteDocument: async () => {
    return false;
  },
  editDocument: async () => {
    return false;
  },
  fetchUserDocuments: async () => {
    return;
  },
});

export const DocumentsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const createDocument = useCallback(
    async (data: CreateDocumentDto) => {
      setState((prev) => ({
        ...prev,
        isLoadingCreateDocument: true,
      }));

      const createDocumentResponse = await documentsService.createDocument(
        data,
      );

      if (createDocumentResponse.error) {
        const error = createDocumentResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingCreateDocument: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!createDocumentResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingCreateDocument: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        documents: prev.documents
          ? [...prev.documents, createDocumentResponse.data!]
          : [createDocumentResponse.data!],
        isLoadingCreateDocument: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const deleteDocument = useCallback(
    async (documentToken: string) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchDeleteDocument: null,
        isLoadingDeleteDocument: true,
      }));

      const deleteDocumentResponse = await documentsService.deleteDocument(
        documentToken,
      );

      if (deleteDocumentResponse.error) {
        const error = deleteDocumentResponse.error;

        setState((prev) => ({
          ...prev,
          errorOnFetchDeleteDocument: error,
          isLoadingDeleteDocument: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        documents: prev.documents
          ? prev.documents.filter(
              (document) => document.documentToken !== documentToken,
            )
          : [],
        isLoadingDeleteDocument: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const editDocument = useCallback(
    async (data: EditDocumentDto) => {
      setState((prev) => ({
        ...prev,
        isLoadingEditDocument: true,
      }));

      const editDocumentResponse = await documentsService.editDocument(data);

      if (editDocumentResponse.error) {
        const error = editDocumentResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingEditDocument: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!editDocumentResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingEditDocument: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        documents: prev.documents
          ? prev.documents.map((document) =>
              document.documentToken ===
              editDocumentResponse.data!.documentToken
                ? editDocumentResponse.data!
                : document,
            )
          : [],
        isLoadingEditDocument: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const fetchUserDocuments = useCallback(async () => {
    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const documents = await documentsService.getUserDocuments(
      user.getUserToken(),
    );

    if (documents.error) {
      const error = documents.error;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!documents.data) {
      const error = ERROR.UNEXPECTED;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      documents: documents.data!,
      isLoading: false,
    }));
  }, [showSnackMessage, user]);

  useEffect(() => {
    void fetchUserDocuments();
  }, [fetchUserDocuments]);

  return (
    <DocumentsContext.Provider
      value={{
        ...state,
        createDocument,
        deleteDocument,
        editDocument,
        fetchUserDocuments,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export const useDocuments = useContextFactory(
  "DocumentsContext",
  DocumentsContext,
);
