import { useEffect, useState } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";

import { useItineraryContainer } from "../itinerary.container";

const initialPermissions = {
  canDeleteTravel: false,
  canDownloadInvoices: false,
  canSendVoucherEmail: false,
  canShareTravel: false,
};

export const useItineraryHeaderGuard = () => {
  const { infoModel } = useItineraryContainer();

  const { user } = useUser();

  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    const isUserBooker =
      infoModel && user ? infoModel.isUserBooker(user) : false;

    const isUserMasterApprover = user ? user.isMasterApprover() : false;

    const isUserTraveler =
      infoModel && user ? infoModel.isUserTraveler(user) : false;

    setPermissions({
      canDeleteTravel: isUserBooker || isUserMasterApprover,
      canDownloadInvoices:
        isUserBooker || isUserMasterApprover || isUserTraveler,
      canSendVoucherEmail:
        isUserBooker || isUserMasterApprover || isUserTraveler,
      canShareTravel: isUserBooker || isUserMasterApprover || isUserTraveler,
    });
  }, [infoModel, user]);

  return {
    permissions,
  };
};
