import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

type Props = Omit<React.ComponentProps<typeof Badge>, "children">;

export const CompanyDealBadge: React.FC<Props> = ({ ...props }) => {
  return (
    <Badge css={styles.root} fill="outlined" variant="pink" {...props}>
      <Icon css={styles.icon} size={16} use="tag" />
      Acordo de Empresa
    </Badge>
  );
};
