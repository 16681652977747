import React, { useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import { css } from "@styled-system/css";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { Box, Flex, Paragraph, Text } from "@toolkit";

const styles = {
  expandIconContainer: css({
    cursor: "pointer",
    ["svg"]: {
      color: "primary",
      height: 20,
      width: 20,
    },
  }),
};

interface IAdministrativeItem {
  [key: string]: any;
  name: string;
}

interface IProps {
  billingProfile?: IAdministrativeItem;
  boxProps?: Record<string, any>;
  companyArea?: IAdministrativeItem;
  costCenter?: IAdministrativeItem;
  defaultCollpase?: boolean;
  project?: IAdministrativeItem;
}

interface IItemInfo {
  item: IAdministrativeItem;
  label: string;
}

const ItemInfo: React.FC<IItemInfo> = ({ item, label }) => {
  return (
    <Box py="small">
      <Paragraph fontSize={1} fontWeight="bold" lineHeight="21px">
        {label}
      </Paragraph>
      <Text fontSize={1} lineHeight="21px">
        {item.name}
      </Text>
    </Box>
  );
};

export const AdministrativeInfo: React.FC<IProps> = ({
  billingProfile,
  companyArea,
  costCenter,
  project,
  defaultCollpase,
  boxProps,
}) => {
  const { clientConfig } = useClientConfig();

  const [isCollapsed, setCollapse] = useState(defaultCollpase || false);

  const areaDisplayName = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : "Área";

  const hasAnyInfoToShow =
    billingProfile || companyArea || costCenter || project;

  if (!hasAnyInfoToShow) {
    return null;
  }

  function handleToggleCollapse() {
    setCollapse((prev) => !prev);
  }

  return (
    <Box
      borderColor={["border", "border"]}
      borderRadius={[0, 4]}
      borderX={["none", "1px solid"]}
      borderY="1px solid"
      px={["medium", 20]}
      {...boxProps}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={[24, "medium"]}
      >
        <Text fontSize={2} fontWeight="bold">
          Informações administrativas
        </Text>
        <Box onClick={handleToggleCollapse} css={styles.expandIconContainer}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Box>
      </Flex>
      <Collapse in={!isCollapsed}>
        <Box pb="medium">
          {billingProfile && (
            <ItemInfo item={billingProfile} label="Perfil de cobrança" />
          )}
          {companyArea && (
            <ItemInfo item={companyArea} label={areaDisplayName} />
          )}
          {costCenter && (
            <ItemInfo item={costCenter} label="Centro de custos" />
          )}
          {project && <ItemInfo item={project} label="Projeto" />}
        </Box>
      </Collapse>
    </Box>
  );
};
