import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  container: css({
    margin: "0 auto",
    padding: "64px 0",
    "@media (min-width: 768px)": {
      maxWidth: "calc(692px + 64px)",
    },
  }),
  events: {
    empty: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginX: "auto",
      padding: "64px 0",
      textAlign: "center",
      width: "fit-content",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "0 32px",
      "@media (max-width: 768px)": {
        padding: "0 16px",
      },
    }),
  },
  filters: {
    chips: {
      chip: {
        root: css({
          borderRadius: "24px",
          color: theme.colors.gray[4],
          padding: "8px 12px",
          transition: "all 0.15s ease-in-out",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "rgba(0, 0, 0, 0.5)",
          },
          '&[aria-selected="true"]': {
            backgroundColor: "rgba(0, 48, 151, 0.1)",
            color: theme.colors.primary,
            "&:hover": {
              backgroundColor: "#FFFFFF",
              borderColor: theme.colors.primary,
            },
          },
        }),
      },
      label: css({
        letterSpacing: "0.04rem",
        margin: "0 8px",
        "@media (max-width: 768px)": {
          display: "none",
        },
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
        padding: "0 32px",
        "@media (max-width: 576px)": {
          display: "flex",
          flexWrap: "wrap",
        },
        "@media (max-width: 768px)": {
          padding: "0 16px",
        },
      }),
    },
    root: css({
      alignContent: "flex-end",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      justifyContent: "space-between",
    }),
  },
  loader: css({
    position: "relative",
    height: "50px",
    marginTop: "5rem",
    ".MuiCircularProgress-root": {
      color: "primary",
    },
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "24px",
    margin: "0 0 64px 0",
  }),
  search: css({
    padding: "0 32px",
    "@media (max-width: 768px)": {
      padding: "0 16px",
    },
  }),
  tabs: {
    root: ecss({
      minHeight: "unset !important",
      padding: "0 32px",
      "@media (max-width: 768px)": {
        padding: "0 16px",
      },
    }),
    scroller: ecss({
      border: `none !important`,
      borderRadius: 0,
      overflow: "visible",
      maxHeight: 38,
    }),
    tab: {
      root: ecss({
        fontFamily: "'Open sans', sans-serif",
        fontSize: 14,
        letterSpacing: "0.04rem",
        minHeight: "unset !important",
        minWidth: "unset !important",
        padding: "8px",
        textTransform: "none",
      }),
      selected: ecss({
        color: theme.colors.primary,
        fontWeight: "bold",
      }),
    },
  },
};
