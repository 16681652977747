import React, { createContext, useCallback, useMemo, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { TravelApprovalStatusModelFactory } from "~/apps/corporate/models/approval-status.model";
import { ClientInfo } from "~/apps/corporate/models/client.model";
import { ItineraryInfoModelFactory } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { OffersAvailabilitiesModelFactory } from "~/apps/corporate/models/offer.model";
import {
  TravelFlightCredits,
  TravelPayment,
  TravelPaymentModelFactory,
} from "~/apps/corporate/models/travel.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { BookingFeeOptions, CardProvider } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { generateShortUniqueId } from "~/apps/shared/utils/generate-short-unique-id";
import { logger } from "~/apps/shared/utils/logger";
import { sleep } from "~/apps/shared/utils/sleep";

import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { useItineraryInfo } from "../itinerary/itinerary-info.context";
import { useItineraryScreen } from "../itinerary/itinerary-screen.context";
import { useItineraryServices } from "../itinerary/itinerary-services.context";
import { useItinerary } from "../itinerary/itinerary.context";
import * as paymentService from "./payment.service";
import { ConfirmPaymentParams, ProcessPaymentParams } from "./payment.types";

interface Actions {
  changeTravelFlightCreditOfferToken: (offerToken: string | null) => void;
  confirmPayment: (data: ConfirmPaymentParams) => Promise<boolean>;
  denyApprovalRequest: (denyJustification?: string) => Promise<boolean>;
  fetchClientInfo: () => Promise<void>;
  fetchTravelFlightCredits: () => Promise<void>;
  fetchTravelPayment: () => Promise<void>;
  fetchUserPaymentPermission: (userToken: string) => Promise<boolean>;
}

type State = {
  canProceedWithRemainingOffers: boolean;
  clientInfo: ClientInfo | null;
  errorOnBook: Error | null;
  errorOnFetch: Error | null;
  errorOnFetchClientInfo: Error | null;
  errorOnFetchTravelFlightCredits: Error | null;
  errorOnFetchUserPaymentPermission: Error | null;
  isBooked: boolean;
  isDenied: boolean;
  isLoading: boolean;
  isLoadingApprovalDeny: boolean;
  isLoadingBook: boolean;
  isLoadingClientInfo: boolean;
  isLoadingTravelFlightCredits: boolean;
  isLoadingUserPaymentPermission: boolean;
  selectedTravelFlightCreditOfferToken: string | null;
  travelFlightCredits: TravelFlightCredits | null;
  travelPayment: TravelPayment | null;
};

const initialState: State = {
  canProceedWithRemainingOffers: false,
  clientInfo: null,
  errorOnBook: null,
  errorOnFetch: null,
  errorOnFetchClientInfo: null,
  errorOnFetchTravelFlightCredits: null,
  errorOnFetchUserPaymentPermission: null,
  isBooked: false,
  isDenied: false,
  isLoading: false,
  isLoadingApprovalDeny: false,
  isLoadingBook: false,
  isLoadingClientInfo: false,
  isLoadingTravelFlightCredits: false,
  isLoadingUserPaymentPermission: false,
  selectedTravelFlightCreditOfferToken: null,
  travelFlightCredits: null,
  travelPayment: null,
};

type ContextProps = Actions &
  State & {
    shouldBookingFeeBeCharged: boolean;
  };

const PaymentContext = createContext<ContextProps>({
  ...initialState,
  changeTravelFlightCreditOfferToken: () => {
    return;
  },
  confirmPayment: async () => {
    return false;
  },
  denyApprovalRequest: async () => {
    return false;
  },
  fetchClientInfo: async () => {
    return;
  },
  fetchTravelFlightCredits: async () => {
    return;
  },
  fetchTravelPayment: async () => {
    return;
  },
  fetchUserPaymentPermission: async () => {
    return false;
  },
  shouldBookingFeeBeCharged: false,
});

export const PaymentProvider: React.FC = ({ children }) => {
  const { removeTravelFromPendingList, showSnackMessage } = useApplication();
  const { user } = useUser();

  const { travelToken } = useItinerary();
  const {
    travelApprovalHistory,
    travelApprovalStatus,
  } = useItineraryApproval();
  const { info } = useItineraryInfo();
  const {
    closePaymentLoadingDialog,
    doesTravelHaveRepeatedOffers,
    openPaymentActionDialog,
    openPaymentLoadingDialog,
  } = useItineraryScreen();
  const {
    fetchOffersAvailabilitiesAndChanges,
    offersAvailabilities,
  } = useItineraryServices();

  const [state, setState] = useState(initialState);

  const shouldBookingFeeBeCharged = useMemo(() => {
    const { clientInfo, travelPayment } = state;

    if (!clientInfo || !travelPayment) {
      return false;
    }

    const travelPaymentModel = TravelPaymentModelFactory.create(travelPayment);

    return (
      clientInfo.bookingFeeOption === BookingFeeOptions.TRAVEL_INDIVIDUALLY &&
      travelPaymentModel.isBookingFeeNotPaid() &&
      travelPaymentModel.isBookingFeeNotPending()
    );
  }, [state.clientInfo, state.travelPayment]);

  const processPayment = useCallback(
    async ({
      billingProfileToken,
      data,
      infoModel,
      services,
    }: ProcessPaymentParams) => {
      openPaymentLoadingDialog();

      setState((prev) => ({
        ...prev,
        errorOnBook: null,
        isLoadingBook: true,
      }));

      const [bookResponse] = await Promise.all([
        paymentService.book(
          {
            billingProfileToken,
            companyAreaToken: infoModel.getCompanyAreaToken(),
            costCenterToken: infoModel.getCostCenterToken(),
            ...(data.selectedCredit && {
              credits: {
                consumerOfferToken: data.selectedCredit.consumerOfferToken,
                tokens: [data.selectedCredit.providerCreditToken],
              },
            }),
            feeCreditCardToken: shouldBookingFeeBeCharged
              ? data.cardTokens.feeCardToken
              : null,
            flightPurposeToken: infoModel.getFlightPurposeToken(),
            hotelPurposeToken: infoModel.getHotelPurposeToken(),
            offers: services.map((service) => {
              const cardToken = service.isBusService()
                ? data.cardTokens.busCardToken
                : service.isCarService()
                ? data.cardTokens.carCardToken
                : service.isFlightService()
                ? data.cardTokens.flightCardToken
                : data.cardTokens.hotelCardToken;

              const serviceCreditCard = data.payableCards
                ? data.payableCards[service.getType()].creditCards.find(
                    (creditCard) => creditCard.creditCardToken === cardToken,
                  )
                : undefined;
              const serviceVCN = data.payableCards
                ? data.payableCards[service.getType()].vcns.find(
                    (vcn) => vcn.vcnToken === cardToken,
                  )
                : undefined;

              const creditCardProvider = serviceCreditCard
                ? CardProvider.REGULAR
                : serviceVCN
                ? serviceVCN.provider
                : null;
              const creditCardToken = cardToken;

              const type = data.paymentOptions[service.getType()];

              return {
                offerToken: service.getOfferToken(),
                paymentInfo: {
                  ...(creditCardProvider &&
                    creditCardToken &&
                    !type.includes("BILLED") && {
                      creditCard: {
                        provider: creditCardProvider,
                        token: creditCardToken,
                      },
                    }),
                  type,
                },
              };
            }),
            orderUUID: generateShortUniqueId(),
            projectToken: infoModel.getProjectToken(),
            tripPurposeToken: infoModel.getTripPurposeToken(),
          },
          travelToken,
        ),
        sleep(3000),
      ]);

      if (bookResponse.error) {
        const error = bookResponse.error!;

        closePaymentLoadingDialog();

        setState((prev) => ({
          ...prev,
          errorOnBook: error,
          isLoadingBook: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      closePaymentLoadingDialog();

      setState((prev) => ({
        ...prev,
        isBooked: true,
        isLoadingBook: false,
      }));

      removeTravelFromPendingList(travelToken);

      return true;
    },
    [
      closePaymentLoadingDialog,
      openPaymentLoadingDialog,
      removeTravelFromPendingList,
      shouldBookingFeeBeCharged,
      showSnackMessage,
      travelToken,
    ],
  );

  const changeTravelFlightCreditOfferToken = useCallback(
    (offerToken: string | null) => {
      setState((prev) => ({
        ...prev,
        selectedTravelFlightCreditOfferToken: offerToken,
      }));
    },
    [],
  );

  const confirmPayment = useCallback(
    async (data: ConfirmPaymentParams): Promise<boolean> => {
      const { canProceedWithRemainingOffers, travelPayment } = state;

      if (!info || !travelApprovalHistory || !travelPayment || !user) {
        return false;
      }

      const infoModel = ItineraryInfoModelFactory.create(info);
      const travelPaymentModel = TravelPaymentModelFactory.create(
        travelPayment,
      );

      const billingProfileToken =
        infoModel.getBillingProfileToken() || user.getBillingProfileToken();

      if (!billingProfileToken) {
        const error = ERROR.UNEXPECTED;

        logger.error(
          "reached payment confirmation without a defined billing profile token",
        );

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (canProceedWithRemainingOffers) {
        if (!offersAvailabilities) {
          return true;
        }

        const offersAvailabilitiesModel = OffersAvailabilitiesModelFactory.create(
          offersAvailabilities,
        );

        const remainingOffers = travelPaymentModel
          .getAllServices()
          .filter((service) => {
            const offerAvailability = offersAvailabilitiesModel.getOfferAvailabilityByOfferToken(
              service.getOfferToken(),
            );

            if (!offerAvailability) {
              return true;
            }

            return offerAvailability.isAvailable();
          });

        const processPaymentResponse = await processPayment({
          billingProfileToken,
          data,
          infoModel,
          services: remainingOffers,
        });

        if (!processPaymentResponse) {
          return false;
        }

        return true;
      }

      openPaymentLoadingDialog();

      const travelPaymentServices = travelPaymentModel.getAllServices();

      const travelHasRepeatedOffers = await doesTravelHaveRepeatedOffers(
        travelPaymentServices.map((service) => service.getOfferToken()),
        3000,
      );

      if (travelHasRepeatedOffers) {
        closePaymentLoadingDialog();

        return false;
      }

      const {
        offersAvailabilitiesModel,
        offersChangesModel,
      } = await fetchOffersAvailabilitiesAndChanges(travelPaymentServices);

      const hasOffersAvailabilitiesWithErrors =
        offersAvailabilitiesModel.getCount() !== travelPaymentServices.length;
      const hasPriceChanges = offersChangesModel.hasPriceChanges(
        travelPaymentServices,
      );
      const hasUnavailableOffers = offersAvailabilitiesModel.hasUnavailableOffers();

      if (
        hasOffersAvailabilitiesWithErrors ||
        hasPriceChanges ||
        hasUnavailableOffers
      ) {
        closePaymentLoadingDialog();
        openPaymentActionDialog();

        setState((prev) => ({
          ...prev,
          canProceedWithRemainingOffers: true,
        }));

        return false;
      }

      const processPaymentResponse = await processPayment({
        billingProfileToken,
        data,
        infoModel,
        services: travelPaymentServices,
      });

      if (!processPaymentResponse) {
        closePaymentLoadingDialog();
        openPaymentActionDialog();

        return false;
      }

      closePaymentLoadingDialog();

      setState((prev) => ({
        ...prev,
        isLoadingBook: false,
      }));

      return true;
    },
    [
      closePaymentLoadingDialog,
      doesTravelHaveRepeatedOffers,
      fetchOffersAvailabilitiesAndChanges,
      info,
      offersAvailabilities,
      openPaymentActionDialog,
      openPaymentLoadingDialog,
      processPayment,
      showSnackMessage,
      state.canProceedWithRemainingOffers,
      state.travelPayment,
      travelApprovalHistory,
      travelApprovalStatus,
      user,
    ],
  );

  const denyApprovalRequest = useCallback(
    async (denyJustification?: string) => {
      const { travelPayment } = state;

      if (!travelApprovalStatus || !travelPayment) {
        return false;
      }

      const travelApprovalStatusModel = TravelApprovalStatusModelFactory.create(
        travelApprovalStatus,
      );

      const pendingApprovalRequestToken = travelApprovalStatusModel.getPendingApprovalRequestToken();

      if (!pendingApprovalRequestToken) {
        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingApprovalDeny: true,
      }));

      const { error } = await paymentService.sendApprovalResponse({
        approvalRequestToken: pendingApprovalRequestToken,
        responseMessage: denyJustification,
        shouldApprove: false,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          isLoadingApprovalDeny: false,
        }));

        return false;
      }

      removeTravelFromPendingList(travelToken);

      setState((prev) => ({
        ...prev,
        isDenied: true,
        isLoadingApprovalDeny: false,
      }));

      return true;
    },
    [
      removeTravelFromPendingList,
      showSnackMessage,
      state.travelPayment,
      travelApprovalStatus,
      travelToken,
    ],
  );

  const fetchClientInfo = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetchClientInfo: null,
      isLoadingClientInfo: true,
    }));

    const clientInfo = await paymentService.getClientInfo();

    if (clientInfo.isFailure()) {
      const error = clientInfo.data;

      setState((prev) => ({
        ...prev,
        errorOnFetchClientInfo: error,
        isLoadingClientInfo: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      clientInfo: clientInfo.data,
      isLoadingClientInfo: false,
    }));
  }, [showSnackMessage]);

  const fetchTravelFlightCredits = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetchTravelFlightCredits: null,
      isLoadingTravelFlightCredits: false,
    }));

    const travelFlightCredits = await paymentService.getTravelFlightCredits(
      travelToken,
    );

    if (travelFlightCredits.isFailure()) {
      const error = travelFlightCredits.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      travelFlightCredits: travelFlightCredits.data,
    }));
  }, [showSnackMessage, travelToken]);

  const fetchTravelPayment = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: false,
    }));

    const travelPayment = await paymentService.getTravelPayment(travelToken);

    if (travelPayment.isFailure()) {
      const error = travelPayment.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      travelPayment: travelPayment.data,
    }));
  }, [showSnackMessage, travelToken]);

  const fetchUserPaymentPermission = useCallback(
    async (userToken: string) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchUserPaymentPermission: null,
        isLoadingUserPaymentPermission: true,
      }));

      const checkPaymentPermissionResponse = await paymentService.checkPaymentPermission(
        travelToken,
        userToken,
      );

      if (checkPaymentPermissionResponse.isFailure()) {
        const error = checkPaymentPermissionResponse.data;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          errorOnFetchUserPaymentPermission: error,
          isLoadingUserPaymentPermission: false,
        }));

        return false;
      }

      const { hasPermission } = checkPaymentPermissionResponse.data;

      setState((prev) => ({
        ...prev,
        isLoadingUserPaymentPermission: false,
      }));

      return hasPermission;
    },
    [showSnackMessage, travelToken],
  );

  return (
    <PaymentContext.Provider
      value={{
        ...state,
        changeTravelFlightCreditOfferToken,
        confirmPayment,
        denyApprovalRequest,
        fetchClientInfo,
        fetchTravelFlightCredits,
        fetchTravelPayment,
        fetchUserPaymentPermission,
        shouldBookingFeeBeCharged,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = useContextFactory("PaymentContext", PaymentContext);
