import React from "react";

import { MenuItem as MuiMenuItem } from "@material-ui/core";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof MuiMenuItem>;

export const MenuItem: React.FC<Props> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <MuiMenuItem {...props} />
    </div>
  );
};
