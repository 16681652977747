import React from "react";

import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import { AsyncData } from "~/apps/shared/components/async-data/async-data";

import { CircularSpinner } from "@toolkit/v2";

import { useListCountryRestrictions } from "../hooks/use-country-restrictions-data";
import { RestrictionCard } from "../restriction-card/restriction-card";
import { styles } from "./styles";

type LoaderProps = {
  children: (data: CountryRestriction[]) => JSX.Element;
};

const RestrictionsListLoader = ({ children }: LoaderProps) => {
  const query = useListCountryRestrictions();

  return (
    <AsyncData {...query}>
      <AsyncData.Success>{(data) => children(data)}</AsyncData.Success>
      <AsyncData.Loading>
        <CircularSpinner />
      </AsyncData.Loading>
    </AsyncData>
  );
};

export const RestrictionsList: React.FC = () => {
  return (
    <RestrictionsListLoader>
      {(countryRestrictions) =>
        countryRestrictions.length === 0 ? (
          <span css={styles.empty}>Nenhuma restrição cadastrada.</span>
        ) : (
          <>
            {(() => {
              const sortedCountryRestrictions = countryRestrictions.sort(
                (a, b) => {
                  return a.created_at < b.created_at ? 1 : -1;
                },
              );

              return (
                <ul css={styles.root}>
                  {sortedCountryRestrictions.map((countryRestriction) => (
                    <RestrictionCard
                      countryRestriction={countryRestriction}
                      key={countryRestriction.country_restriction_token}
                    />
                  ))}
                </ul>
              );
            })()}
          </>
        )
      }
    </RestrictionsListLoader>
  );
};
