import React, { useState } from "react";

import { Button } from "@toolkit/v2";

import { DatePicker } from "../../../../shared/components/date-picker/date-picker";
import { Form, useForm } from "../../../../shared/components/form/form";
import { Icon } from "../../../../shared/components/icon/icon";
import { InputErrorMessage } from "../../../../shared/components/input-error-message/input-error-message";
import { Input } from "../../../../shared/components/input/input";
import { LocationAutocomplete } from "../../../../shared/components/location-autocomplete/location-autocomplete";
import {
  hotelFormSchema,
  HotelFormSchema,
} from "../../offer-searcher/hotel-form/hotel-form.schema";
import { useOfferSearcher } from "../../offer-searcher/offer-searcher.context";
import { HotelsSearch } from "../../offer-searcher/offer-searcher.types";
import { styles } from "./styles";

type Props = {
  data: Partial<HotelsSearch> | null;
  onClose: () => void;
  travelToken: string;
};

export const OfferSearcherDialogHotelForm: React.FC<Props> = ({
  data,
  onClose,
  travelToken,
}) => {
  const { handleSearchHotels } = useOfferSearcher();

  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);

  const form = useForm<HotelFormSchema>({
    defaultValues: data
      ? {
          endDate: data.endDate ? data.endDate : null,
          location: data.location ? data.location : {},
          startDate: data.startDate ? data.startDate : null,
          totalGuests: data.totalGuests ? data.totalGuests : 1,
        }
      : {
          endDate: null,
          location: null,
          startDate: null,
          totalGuests: 1,
        },
    onSubmit: async () => {
      const values = form.getValues();

      await handleSearchHotels({
        endDate: values.endDate,
        location: values.location,
        startDate: values.startDate,
        totalGuests: values.totalGuests,
        travelToken,
      });

      onClose();
    },
    schema: hotelFormSchema,
  });

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.center.root}>
        <div css={styles.center.control}>
          <span css={styles.center.label}>Local da hospedagem</span>
          <div css={styles.center.input.root}>
            <Icon size={20} use="hotel-outline" />
            <LocationAutocomplete
              css={styles.center.input.input}
              placeholder="Digite o local da hospedagem..."
              onSelect={(location) => {
                if (!location) {
                  form.setValue("location", null);

                  return;
                }

                form.setValue("location", {
                  latitude: location.latitude,
                  longitude: location.longitude,
                  search: location.formattedAddress,
                });
              }}
              value={form.getValues("location")?.search}
            />
          </div>
          <InputErrorMessage>
            {form.errors["location"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.center.control}>
          <span css={styles.center.label}>Check in - Check out</span>
          <div css={styles.center.dates}>
            <DatePicker
              allowSameDay
              customArrowIcon={<Icon size={16} use="calendar" />}
              endDate={form.watch("endDate")}
              endDatePlaceholderText="até"
              startDate={form.watch("startDate")}
              startDatePlaceholderText="De"
              focusedInput={rangeDateFocus}
              range
              renderCalendarInfo={undefined}
              onDatesChange={(date) => {
                form.setValue("startDate", date.startDate);

                if (date.startDate) {
                  form.clearErrors("startDate");
                }

                form.setValue("endDate", date.endDate);

                if (date.endDate) {
                  form.clearErrors("endDate");
                }
              }}
              onFocusChange={(focused) => {
                setRangeFocus(focused);
              }}
            />
          </div>
          <InputErrorMessage>
            {form.errors["startDate"]?.message ||
              form.errors["endDate"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.center.guests}>
          <span css={styles.center.label}>Número de Hóspedes</span>
          <div css={styles.center.input.root}>
            <Icon size={20} use="person" />
            <Input
              css={styles.center.input.input}
              onChange={(e) => {
                form.setValue("totalGuests", Number(e.target.value) || 0);
              }}
              placeholder="Quantidade de pessoas..."
              type="number"
              value={form.watch("totalGuests")}
            />
          </div>
          <InputErrorMessage>
            {form.errors["totalGuests"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.bottom.root}>
        <Button css={styles.bottom.button} variant="pink">
          Pesquisar hotéis
        </Button>
      </div>
    </Form>
  );
};
