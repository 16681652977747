import { useMemo } from "react";

import { useItineraryServices } from "../../../itinerary/itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { usePaymentContainer } from "../../payment.container";
import { usePayment } from "../../payment.context";
import { PaymentServicesCardPresenterFactory } from "./payment-services-card.presenter";

export const usePaymentServicesCardGuard = () => {
  const { isLoadingTravelPoliciesPerOffer } = useItineraryServices();
  const { isLoading: isLoadingTravelPayment } = usePayment();

  return {
    loading: isLoadingTravelPayment || isLoadingTravelPoliciesPerOffer,
  };
};

export const usePaymentServicesCardPresenter = () => {
  const { offersAvailabilitiesModel } = useItineraryContainer();
  const { travelPaymentModel } = usePaymentContainer();

  return useMemo(
    () => ({
      paymentServicesCardPresenter: travelPaymentModel
        ? PaymentServicesCardPresenterFactory.create(
            offersAvailabilitiesModel,
            travelPaymentModel,
          )
        : null,
    }),
    [offersAvailabilitiesModel, travelPaymentModel],
  );
};
