import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    apply: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      cursor: "pointer",
      fontSize: "0.875rem",
      fontWeight: 500,
      outline: "none",
      textDecoration: "underline",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.pink[700],
      },
    }),
    clear: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[500],
      cursor: "pointer",
      fontSize: "0.875rem",
      fontWeight: 500,
      outline: "none",
      textDecoration: "underline",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.gray[700],
      },
    }),
    root: css({
      display: "flex",
      gap: "12px",
      justifyContent: "flex-end",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "flex-end",
    padding: "24px",
  }),
  top: {
    body: {
      month: ({
        i,
        isInBetween,
        isSelected,
        j,
      }: {
        i: number;
        isInBetween: boolean;
        isSelected: boolean;
        j: number;
      }) =>
        css({
          alignItems: "center",
          backgroundColor: isSelected
            ? theme.colors.pink[500]
            : isInBetween
            ? "#ff9fac"
            : "transparent",
          borderBottom:
            i === 3
              ? isSelected || isInBetween
                ? `1px solid ${theme.colors.pink[500]}`
                : `1px solid ${theme.colors.gray[100]}`
              : "none",
          borderLeft:
            isSelected || isInBetween
              ? `1px solid ${theme.colors.pink[500]}`
              : `1px solid ${theme.colors.gray[100]}`,
          borderRight:
            j === 2
              ? isSelected || isInBetween
                ? `1px solid ${theme.colors.pink[500]}`
                : `1px solid ${theme.colors.gray[100]}`
              : "none",
          borderTop:
            isSelected || isInBetween
              ? `1px solid ${theme.colors.pink[500]}`
              : `1px solid ${theme.colors.gray[100]}`,
          color:
            isSelected || isInBetween
              ? theme.colors.white
              : theme.colors.gray[500],
          cursor: "pointer",
          display: "flex",
          fontSize: "0.875rem",
          height: "38px",
          justifyContent: "center",
          padding: "8px",
          width: "90px",
        }),
      root: css({
        display: "flex",
      }),
    },
    header: {
      nav: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[500],
        cursor: "pointer",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
        },
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }),
      year: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        textAlign: "center",
        width: "100%",
      }),
    },
    root: ({ orientation }: { orientation: "horizontal" | "vertical" }) =>
      css({
        alignItems: "center",
        display: "flex",
        flexDirection: orientation === "horizontal" ? "row" : "column",
        gap: "26px",
        justifyContent: "flex-end",
      }),
    section: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
  },
};
