import { APPROVAL_TYPES, ERROR } from "~/constants";

import { clientService } from "@apis/client.api";

import {
  GetApprovalPriorityRanking,
  SaveApprovalPriorityRanking,
} from "./ApprovalPriorityRanking.types";

export async function getApprovalRankingPriority(): Promise<GetApprovalPriorityRanking> {
  try {
    const config = await clientService.getClientConfig();

    return {
      data: {
        clientConfigToken: config.clientConfigToken,
        priorityRanking: config.approvalPriorityRanking
          ? config.approvalPriorityRanking.split(";")
          : [],
      },
    };
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    }
  }
}

export async function updateApprovalRankingPriority(
  clientConfigToken: string,
  priorityRanking: string[],
): Promise<SaveApprovalPriorityRanking> {
  try {
    const dto = {
      approval_ranking: [...priorityRanking, APPROVAL_TYPES.COMPANY],
    };

    await clientService.updateClientConfig(clientConfigToken, dto);

    return {
      data: {
        success: true,
      },
    };
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    }
  }
}
