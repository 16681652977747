import React, { useState, ChangeEvent } from "react";

import Collapse from "@material-ui/core/Collapse";
import { css } from "@styled-system/css";

import { Box, Text, Paragraph, Flex } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { formatCurrency } from "@helpers";

import { Input } from "@shared/inputs";

import {
  StContainedButton,
  StOutlinedButton
} from "~/components/shared/buttons";

import {
  useExpenseAdvanceApprovalReviewContext,
  useExpenseAdvanceApprovalReviewActionsContext
} from "../../ExpenseAdvanceApprovalReview.context";
import { BlockText } from "../../styles";

const styles = {
  approvalButton: css({
    py: "small",
    px: 24,
    fontSize: 1,
    width: ["100%", 178],
    maxHeight: 38
  }),
  cancelButton: css({
    py: "small",
    px: 24,
    fontSize: 1,
    width: "100%",
    maxWidth: ["100%", 164],
    maxHeight: 38
  })
};

const ApprovalCard = () => {
  const [denyFormVisible, setDenyFormVisible] = useState(false);
  const [denyMessage, setDenyMessage] = useState("");

  const handleToggleDenyForm = () =>
    setDenyFormVisible(prevState => !prevState);

  const handleChangeMessage = (e: ChangeEvent<HTMLInputElement>) => {
    setDenyMessage(e.target.value);
  };

  const {
    traveler,
    expenseAdvance,
    isSubmitting
  } = useExpenseAdvanceApprovalReviewContext();
  const {
    handleApprove,
    handleDeny
  } = useExpenseAdvanceApprovalReviewActionsContext();

  return (
    <Box
      width="100%"
      maxWidth={["auto", 431.58]}
      height="fit-content"
      border="1px solid"
      borderColor="border"
    >
      <Box p={[16, 24]} backgroundColor="#F7F8FD">
        <Text fontSize={[1, 2]} fontWeight="bold">
          Resumo da solicitação
        </Text>
      </Box>

      <Box
        p={[16, 24]}
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius={0}
      >
        <Paragraph fontSize={[1, 2]} fontWeight="bold" mb="medium">
          Adiantamento
        </Paragraph>

        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize={[1, 2]} fontWeight="bold">
            Valor
          </Text>
          <Text fontSize={[1, 2]}>
            {formatCurrency(
              expenseAdvance!.requestedValue,
              expenseAdvance!.currency
            )}
          </Text>
        </Flex>
      </Box>
      <Collapse in={denyFormVisible}>
        <Box
          p={[16, 24]}
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid"
          borderTopColor="border"
          borderRadius={0}
        >
          <BlockText
            fontSize={0}
            color={defaultTheme.subTextColor}
            mb="medium"
            lineHeight="1.6"
          >
            Adicione uma mensagem para deixar{" "}
            <strong>{traveler!.fullName}</strong> sabendo por que seu pedido foi
            negado. <strong>(opcional)</strong>
          </BlockText>
          <Input
            id="message"
            name="message"
            placeholder="Digite uma mensagem"
            value={denyMessage}
            onChange={handleChangeMessage}
            disabled={isSubmitting}
            rows={4}
            rowsMax={6}
            multiline={true}
            InputProps={{
              style: { padding: 0 }
            }}
            inputProps={{
              style: { width: "91%" }
            }}
          />
        </Box>
      </Collapse>

      {denyFormVisible ? (
        <Flex
          p={[16, 24]}
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid"
          borderTopColor="border"
          borderRadius={0}
          flexDirection={["column", "row"]}
        >
          <StOutlinedButton
            css={styles.cancelButton}
            onClick={handleToggleDenyForm}
            disabled={isSubmitting}
          >
            Cancelar
          </StOutlinedButton>
          <Box py={["xsmall", 0]} px={["0", "medium"]} />
          <StContainedButton
            color="secondary"
            css={styles.cancelButton}
            onClick={handleDeny(denyMessage)}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Negar solicitação
          </StContainedButton>
        </Flex>
      ) : (
        <Flex
          p={[16, 24]}
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid"
          borderTopColor="border"
          borderRadius={0}
          flexDirection={["column", "row"]}
        >
          <StContainedButton
            css={styles.approvalButton}
            onClick={handleApprove}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Aprovar solicitação
          </StContainedButton>
          <Box py={["xsmall", 0]} px={["0", "medium"]} />
          <StContainedButton
            color="secondary"
            css={styles.cancelButton}
            onClick={handleToggleDenyForm}
            disabled={isSubmitting}
          >
            Negar solicitação
          </StContainedButton>
        </Flex>
      )}
    </Box>
  );
};

export { ApprovalCard };
