import React from "react";
import { Controller } from "react-hook-form";

import isEmpty from "lodash/isEmpty";

import { Box, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { StOutlinedButton, StContainedButton } from "@shared/buttons";
import { Input } from "@shared/inputs";
import InputError from "@shared/inputs/InputError";

import { useForm } from "@components/shared/form";

import { GradeRange, ItemGradeSelect } from "./inputs";
import { formSchema } from "./validations";

interface Props {
  isSubmitting: boolean;
  onSubmit: (formData: any) => void;
  onClose: () => void;
}

const HotelReviewForm = ({ isSubmitting, onSubmit, onClose }: Props) => {
  const { control, submitForm, errors } = useForm({
    onSubmit,
    schema: formSchema,
    defaultValues: {
      reviewTitle: "",
      positiveReview: "",
      negativeReview: "",
      generalGrade: "",
      treatmentGrade: "",
      cleaningGrade: "",
      roomGrade: "",
      locationGrade: "",
      wifiGrade: ""
    }
  });

  const isValid = isEmpty(errors);
  const hasServiceGradeErrors =
    !!errors.treatmentGrade ||
    !!errors.cleaningGrade ||
    !!errors.roomGrade ||
    (!!errors.locationGrade && !!errors.wifiGrade);

  return (
    <form style={{ width: "100%" }} onSubmit={submitForm}>
      <Box maxWidth={[380]} mb={["large"]}>
        <Box mb="medium">
          <Text fontWeight="bold" fontSize={[1]}>
            Dê um título à sua avaliação
          </Text>
        </Box>
        <Box>
          <Controller
            as={Input}
            id="reviewTitle"
            name="reviewTitle"
            placeholder="Título"
            control={control}
            error={!!errors.reviewTitle}
            errorMessage={errors.reviewTitle && errors.reviewTitle.message}
          />
        </Box>
      </Box>
      <Box mb={["large"]}>
        <Box mb="small">
          <Text fontWeight="bold" fontSize={[1]}>
            Dê sua nota
          </Text>
        </Box>
        <Controller
          as={GradeRange}
          id="generalGrade"
          name="generalGrade"
          control={control}
        />
        {errors.generalGrade && (
          <InputError>{errors.generalGrade.message}</InputError>
        )}
      </Box>
      <Box mb={["large"]}>
        <Box mb="medium">
          <Text fontWeight="bold" fontSize={[1]}>
            Pontuação do hotel
          </Text>
        </Box>
        <Box ml={["12px"]} width={["auto", 300]}>
          <Controller
            as={ItemGradeSelect}
            id="roomGrade"
            name="roomGrade"
            label="Acomodações"
            control={control}
          />
          <Controller
            as={ItemGradeSelect}
            id="treatmentGrade"
            name="treatmentGrade"
            label="Atendimento"
            control={control}
          />
          <Controller
            as={ItemGradeSelect}
            id="cleaningGrade"
            name="cleaningGrade"
            label="Limpeza"
            control={control}
          />
          <Controller
            as={ItemGradeSelect}
            id="locationGrade"
            name="locationGrade"
            label="Localização"
            control={control}
          />
          <Controller
            as={ItemGradeSelect}
            id="wifiGrade"
            name="wifiGrade"
            label="Wifi"
            control={control}
          />
          {hasServiceGradeErrors && (
            <InputError>Preencha todas as notas dos serviços</InputError>
          )}
        </Box>
      </Box>
      <Box mb={["large"]}>
        <Box mb={["medium"]}>
          <Text fontWeight="bold" fontSize={[1]}>
            Nos deixe seus pontos positivos{" "}
            <Text fontWeight="normal" color={defaultTheme.subTextColor}>
              (Opcional)
            </Text>
          </Text>
        </Box>
        <Box>
          <Controller
            as={Input}
            id="positiveReview"
            name="positiveReview"
            control={control}
            rows={6}
            rowsMax={6}
            multiline={true}
            placeholder="Descreva seus pontos positivos sobre sua experiência :)"
            InputProps={{
              style: { padding: 0 }
            }}
            error={!!errors.positiveReview}
            errorMessage={
              errors.positiveReview && errors.positiveReview.message
            }
          />
        </Box>
      </Box>
      <Box mb={["large"]}>
        <Box mb={["medium"]}>
          <Text fontWeight="bold" fontSize={[1]}>
            Nos deixe seus pontos negativos{" "}
            <Text fontWeight="normal" color={defaultTheme.subTextColor}>
              (Opcional)
            </Text>
          </Text>
        </Box>
        <Box>
          <Controller
            as={Input}
            id="negativeReview"
            name="negativeReview"
            control={control}
            rows={6}
            rowsMax={6}
            multiline={true}
            placeholder="Descreva seus pontos negativos sobre sua experiência :("
            InputProps={{
              style: { padding: 0 }
            }}
            error={!!errors.negativeReview}
            errorMessage={
              errors.negativeReview && errors.negativeReview.message
            }
          />
        </Box>
      </Box>
      <Box textAlign="center">
        <StContainedButton
          isFullWidth={false}
          style={{ padding: "8px 16px" }}
          type="submit"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          Enviar avaliação
        </StContainedButton>
        <StOutlinedButton
          style={{ padding: "8px 16px", marginLeft: "16px" }}
          onClick={onClose}
        >
          Cancelar
        </StOutlinedButton>
      </Box>
    </form>
  );
};

export { HotelReviewForm };
