import React, { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { MemberGetMemberDrawer } from "~/apps/corporate/components/member-get-member-drawer/member-get-member-drawer";
import { useMemberGetMember } from "~/apps/corporate/contexts/member-get-member.context";
import { CopyIconButtonWithTooltip } from "~/apps/shared/components/copy-icon-button-with-tooltip/copy-icon-button-with-tooltip";

import { styles } from "./styles";

export const UserProfileDrawerMemberGetMemberSection: React.FC = () => {
  const { isLoadingShareCode, shareCode } = useMemberGetMember();

  const [
    isMemberGetMemberDrawerOpen,
    setIsMemberGetMemberDrawerOpen,
  ] = useState(false);

  const formattedShareCode = useMemo(() => {
    if (!shareCode) {
      return null;
    }

    return `indiqueeganhe.smartrips.com.br/?share-code=${shareCode}`;
  }, [shareCode]);

  const handleCloseMemberGetMemberDrawer = useCallback(() => {
    setIsMemberGetMemberDrawerOpen(false);
  }, []);

  const handleOpenMemberGetMemberDrawer = useCallback(() => {
    setIsMemberGetMemberDrawerOpen(true);
  }, []);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>Indique e ganhe</span>
          <button
            css={styles.header.button}
            onClick={() => {
              handleOpenMemberGetMemberDrawer();
            }}
          >
            Saiba mais
          </button>
        </div>
        <div css={styles.referral.root}>
          {formattedShareCode ? (
            <>
              <span css={styles.referral.label}>
                Compartilhe o link com colegas de outras empresas:
              </span>
              <div css={styles.referral.display.root}>
                <span css={styles.referral.display.text}>
                  {formattedShareCode}
                </span>
                <CopyIconButtonWithTooltip
                  css={styles.referral.display.button}
                  text={formattedShareCode}
                />
              </div>
            </>
          ) : isLoadingShareCode ? (
            <>
              <span css={styles.referral.label}>
                Compartilhe o link com colegas de outras empresas:
              </span>
              <div css={styles.referral.display.root}>
                <Skeleton height="18px" width="222px" />
                <CopyIconButtonWithTooltip
                  css={styles.referral.display.button}
                  disabled
                  text=""
                />
              </div>
            </>
          ) : (
            <span css={styles.referral.label}>
              Indicou o próximo cliente Smartrips, você ganha R$1.000,00!
            </span>
          )}
        </div>
      </div>
      <MemberGetMemberDrawer
        onClose={handleCloseMemberGetMemberDrawer}
        open={isMemberGetMemberDrawerOpen}
      />
    </>
  );
};
