import React from "react";

import SwapIcon from "@material-ui/icons/ImportExportRounded";

import { Button } from "./styles";

type SwapButtonProps = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  "aria-label" | "children" | "type"
> & {
  version?: "v1" | "v2";
};

const SwapButton: React.VFC<SwapButtonProps> = ({
  version = "v1",
  ...props
}) => {
  return (
    <Button aria-label="Trocar" type="button" version={version} {...props}>
      <SwapIcon />
    </Button>
  );
};

export { SwapButton };
