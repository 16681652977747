import { css } from "@styled-system/css";

export const styles = {
  root: ({ color, size }: { color?: string; size?: number }) =>
    css({
      alignItems: "center",
      aspectRatio: "1",
      ...(color && { color: `${color}` }),
      display: "flex",
      flexShrink: 0,
      height: `${size || 24}px`,
      justifyContent: "center",
      pointerEvents: "auto",
      width: `${size || 24}px`,
    }),
};
