import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  client: css({
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    color: theme.colors.gray[500],
    fontSize: "0.875rem",
    padding: "12px",
    textAlign: "center",
  }),
  links: {
    button: css({
      alignItems: "center",
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[500],
      display: "flex",
      fontSize: "0.875rem",
      fontFamily: theme.fonts.inter,
      gap: "8px",
      opacity: 0.5,
      "@media screen and (max-width: 768px)": {
        "& > :nth-of-type(2)": {
          display: "none",
        },
      },
    }),
    link: ({ active }: { active: boolean }) =>
      css({
        alignItems: "center",
        borderRadius: "24px",
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
        padding: "12px 16px",
        ...(active
          ? {
              backgroundColor: theme.colors.gray[500],
              color: theme.colors.white,
              opacity: 1,
            }
          : {
              color: theme.colors.gray[500],
              opacity: 0.5,
              "@media screen and (max-width: 768px)": {
                "& > :nth-of-type(2)": {
                  display: "none",
                },
              },
            }),
      }),
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.white,
      display: "flex",
      gap: "12px",
      justifyContent: "space-around",
      padding: "12px",
      width: "100%",
      "& > *": {
        textDecoration: "none",
      },
    }),
  },
  root: css({
    bottom: 0,
    borderTop: `1px solid ${theme.colors.gray[100]}`,
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    position: "sticky",
    width: "100%",
    zIndex: 1000,
  }),
};
