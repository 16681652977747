import * as analyticsApi from "@apis/analytics.api";
import * as analyticsService from "../Analytics.service";
import * as creditsApi from "@apis/credits.api";
import { getClientPermissions } from "@helpers/user.helper";
import { ERROR, IMPERMISSIBLE_ANALYTICS_DATA } from "@constants";

export async function getFlightInfo(config) {
  const result = {};

  try {
    const requestDto = parseAnalyticsParams(config);
    result.data = await analyticsApi.getFlightsAnalyticsInfo(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getSavings(config) {
  const result = {};
  const {
    analytics: { economy: economyPermission },
  } = getClientPermissions();

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = economyPermission
      ? await analyticsApi.getSavings(requestArg)
      : IMPERMISSIBLE_ANALYTICS_DATA.ECONOMY;
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getWastes(config) {
  const result = {};
  const {
    analytics: { waste: wastePermission },
  } = getClientPermissions();

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = wastePermission
      ? await analyticsApi.getWastes(requestArg)
      : IMPERMISSIBLE_ANALYTICS_DATA.WASTE;
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getTotalSpents(config) {
  const result = {};

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = await analyticsApi.getTotalSpents(requestArg);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getAlterFee(config) {
  const result = {};
  const {
    analytics: { waste: wastePermission },
  } = getClientPermissions();

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = wastePermission
      ? await analyticsApi.getAlterFee(requestArg)
      : IMPERMISSIBLE_ANALYTICS_DATA.ALTER_FEE;
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getAlterDifference(config) {
  const result = {};
  const {
    analytics: { waste: wastePermission },
  } = getClientPermissions();

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = wastePermission
      ? await analyticsApi.getAlterDifference(requestArg)
      : IMPERMISSIBLE_ANALYTICS_DATA.ALTER_DIFFERENCE;
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getCancelFee(config) {
  const result = {};
  const {
    analytics: { waste: wastePermission },
  } = getClientPermissions();

  const requestArg = parseAnalyticsParams(config);

  try {
    result.data = wastePermission
      ? await analyticsApi.getCancelFee(requestArg)
      : IMPERMISSIBLE_ANALYTICS_DATA.CANCEL_FEE;
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
}

export async function getFilterOptionsResult() {
  const result = {};

  try {
    const [
      billingProfileOptions,
      companyAreaOptions,
      costCenterOptions,
      projectOptions,
      tagOptions,
      travelerOptions,
    ] = await Promise.all([
      analyticsService.loadBillingProfileOptions(),
      analyticsService.loadCompanyAreaOptions(),
      analyticsService.loadCostCentersOptions(),
      analyticsService.loadProjectOptions(),
      analyticsService.loadTagOptions(),
      analyticsService.loadTravelerOptions(),
    ])


    result.data = {
      billingProfileOptions,
      companyAreaOptions,
      costCenterOptions,
      projectOptions,
      tagOptions,
      travelerOptions,
    };
  } catch (error) {
    result.error = ERROR.FILTERS_LOAD_ERROR;
  }

  return result;
}

export async function getClientCredits() {
  const result = {};
  try {
    result.data = await creditsApi.getClientCredits();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }
  return result;
}

function parseAnalyticsParams(config) {
  const request = {};

  request.start_month = config.startMonth;
  request.start_year = config.startYear;
  request.end_month = config.endMonth;
  request.end_year = config.endYear;
  request.cost_center_token = config.costCenterToken;
  request.tag_token = config.tagToken;
  request.traveler_token = config.travelerToken;
  request.company_area_token = config.companyAreaToken;
  request.project_token = config.projectToken;
  request.billing_profile_token = config.billingProfileToken;

  return request;
}
