import React from "react";
import Creatable from "react-select/creatable";
import OriginalSelect, {
  components,
  Props as ReactSelectProps,
  OptionProps,
  MenuProps,
} from "react-windowed-select";

import { darken } from "polished";

import { colors } from "../../../assets/styles/theme/constants";

function getStyles(error) {
  return {
    control: (styles, state) => {
      const controlStyles = {
        ...styles,
        minHeight: 45,
        boxShadow: "none",
        borderRadius: 4,
        borderColor: state.menuIsOpen ? "#ff385c" : colors.botticelli,
        ["&:hover"]: {
          borderColor: state.menuIsOpen
            ? darken(0.1, "#ff385c")
            : colors.botticelli,
          cursor: "pointer",
        },
      };

      if (error) {
        controlStyles.borderColor = colors.redError;
        controlStyles.borderWidth = 2;
        controlStyles.boxShadow = "none";
        controlStyles["&:hover"] = {
          borderColor: colors.redError,
        };
      }

      return controlStyles;
    },
    input: (styles) => ({
      ...styles,
      fontFamily: "'Open-sans', sans-serif",
      color: colors.ebony,
    }),
    singleValue: (styles) => ({
      ...styles,
      fontFamily: "'Open-sans', sans-serif",
      color: colors.ebony,
    }),
    option: (styles, state) => {
      const optionStyles = {
        ...styles,
        fontFamily: "'Open-sans', sans-serif",
        color: state.isSelected ? colors.white : colors.ebony,
        backgroundColor: state.isSelected ? "#ff385c" : "transparent",
        borderBottom: "1px solid rgb(223 223 223)",
        padding: "12px",
        ["&:hover"]: {
          backgroundColor: "#ff385c",
          color: colors.white,
          cursor: "pointer",
        },
      };

      if (state.isDisabled) {
        optionStyles.opacity = 0.5;
        optionStyles.pointerEvents = "none";
        optionStyles.cursor = "default";
      }

      return optionStyles;
    },
    placeholder: (styles) => ({
      ...styles,
      color: "#999BA0",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      letterSpacing: "0.00938em",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: colors.botticelli,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: colors.slateContrastGray + " !important",
      ["&:hover"]: {
        color: darken(0.1, colors.slateContrastGray) + " !important",
      },
    }),
    menu: (styles) => ({
      ...styles,
      padding: "8px 0",
      zIndex: 2,
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1301,
    }),
  };
}

function noOptionsMessage({ inputValue }) {
  return `Nenhum resultado ${inputValue ? "para " + inputValue : ""}`;
}

function Menu(props: MenuProps) {
  const {
    children,
    className,
    cx,
    getStyles: getMenuStyles,
    innerRef,
    innerProps,
  } = props;

  return (
    <div
      id="virtualized-select"
      css={getMenuStyles("menu", props)}
      className={cx({ menu: true }, className)}
      {...innerProps}
      ref={innerRef}
    >
      {children}
    </div>
  );
}

function Option(props: OptionProps) {
  const { children, ...rest } = props;

  return (
    <components.Option {...rest}>
      <div>{children}</div>
    </components.Option>
  );
}

export type SelectProps = ReactSelectProps & {
  windowThreshold?: number;
  error?: boolean;
};

export function Select(props: SelectProps) {
  const { error, components, windowThreshold = 100, ...other } = props;

  const innerComponents = Object.assign<
    {},
    typeof components,
    ReactSelectProps["components"]
  >({}, components, { Menu, Option: components?.Option || Option });

  return (
    <OriginalSelect
      styles={getStyles(error)}
      noOptionsMessage={noOptionsMessage}
      components={innerComponents}
      windowThreshold={windowThreshold}
      {...other}
    />
  );
}

export function CreatableSelect(props: SelectProps) {
  const { error, components, ...other } = props;

  const innerComponents = Object.assign({}, components, { Menu, Option });

  return (
    <Creatable
      styles={getStyles(error)}
      noOptionsMessage={noOptionsMessage}
      components={innerComponents}
      {...other}
    />
  );
}
