import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  withGoogleMap,
  DirectionsRenderer
} from "react-google-maps";
import { css } from "emotion";
import clsx from "classnames";
import { usePrevious } from "../../../hooks";
import isEqual from "lodash/isEqual";

const styles = {
  root: css({
    height: 400,
    width: "100%"
  })
};

const BRAZIL_COORDINATES = {
  lat: -14.2350044,
  lng: -51.9252815
};

interface MapProps {
  origin: [number, number] | null;
  destination: [number, number] | null;
  defaultZoom: number;
}

const DirectionsMap = withGoogleMap((props: MapProps) => {
  const [directions, setDirections] = useState<any>();
  const prevProps = usePrevious(props);

  const shouldComponentUpdate = () => {
    const { origin: currentOrigin, destination: currentDestination } = props;

    if (!prevProps && props) {
      return true;
    } else if (
      !!prevProps &&
      (!isEqual(prevProps.origin, currentOrigin) ||
        !isEqual(prevProps.destination, currentDestination))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(
    () => {
      const { origin, destination } = props;
      const shouldUpdate = shouldComponentUpdate();

      if (!shouldUpdate) {
        return;
      }

      if (origin && destination) {
        const directionsService = new google.maps.DirectionsService();

        const request = {
          origin: new google.maps.LatLng(...origin),
          destination: new google.maps.LatLng(...destination),
          travelMode: google.maps.TravelMode.DRIVING
        };

        directionsService.route(request, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
            setDirections(null);
          }
        });
      } else {
        setDirections(null);
      }
    },
    [props.origin, props.destination]
  );

  const getMapCenter = () => {
    const { origin, destination } = props;

    if (origin && destination) {
      return undefined;
    } else if (origin) {
      return { lat: origin[0], lng: origin[1] };
    } else {
      return BRAZIL_COORDINATES;
    }
  };

  const mapZoom = props.origin ? 10 : props.defaultZoom;

  return (
    <GoogleMap
      defaultZoom={props.defaultZoom}
      zoom={mapZoom}
      defaultCenter={BRAZIL_COORDINATES}
      center={getMapCenter()}
      options={{
        disableDefaultUI: true,
        draggable: false
      }}
    >
      {directions ? <DirectionsRenderer directions={directions} /> : null}
    </GoogleMap>
  );
});

interface ContainerProps {
  origin: [number, number] | null;
  destination: [number, number] | null;
  defaultZoom: number;
  containerClasses: string;
}

const Container = ({ containerClasses, ...rest }: ContainerProps) => {
  return (
    <DirectionsMap
      {...rest}
      containerElement={<div className={clsx(styles.root, containerClasses)} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};
export { Container as DirectionsMap };
