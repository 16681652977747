import {
  AddCarOfferToTravelRequest,
  CarSearchCreationRequest,
  GetCarLocationAutosuggestResponse,
  GetCarResponse,
  GetCarsListResponse,
  RecalculateCarOfferRequest,
  RecalculateCarOfferResponse,
} from "~/apps/corporate/dtos/car.dto";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { CarAutosuggestItem } from "~/apps/corporate/models/car.model";
import { api } from "~/apps/shared/services/api";

export async function addCarOfferToTravel(
  travelToken: string,
  data: AddCarOfferToTravelRequest,
) {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/offers/cars`,
    })
    .then(({ data }) => data);
}

export async function createCarsSearch(data: CarSearchCreationRequest) {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/car",
    })
    .then(({ data }) => data);
}

export async function getCarLocationAutosuggestions(
  search: string,
): Promise<CarAutosuggestItem[]> {
  return await api
    .request<GetCarLocationAutosuggestResponse>({
      url: `/booking/autosuggest/car/${search}`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => {
      return data.location_list.map((location) => ({
        ...location,
        label: location.name,
      }));
    });
}

export async function getCarsList(
  carToken: string,
): Promise<GetCarsListResponse> {
  return await api
    .request<GetCarsListResponse>({
      url: `/booking/cars/${carToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

export const getCarSupplierOffer = async (
  carToken: string,
  supplierOfferToken: string,
) => {
  return await api
    .request<GetCarResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/cars/${carToken}/supplier-offer/${supplierOfferToken}`,
    })
    .then(({ data }) => data);
};

export const recalculateCarOffer = async (
  carToken: string,
  data: RecalculateCarOfferRequest,
) => {
  return await api
    .request<RecalculateCarOfferResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/cars/${carToken}/recalculate`,
    })
    .then(({ data }) => data);
};
