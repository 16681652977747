import React from "react";
import Skeleton from "react-loading-skeleton";

import { getAllFareDetailsMappedByCarrierCode } from "~/apps/corporate/helpers/flight.helper";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

interface Props {
  bound: {
    carrier?: {
      code: string;
    };
    cabinClass: string;
    destination: {
      code: string;
    };
    origin: {
      code: string;
    };
  };
  boundFamily: {
    baggage?: {
      pieces?: number;
      weight?: number;
    };
    family: string;
  };
  flightIsInternational: boolean;
}

export const FlightFareBound: React.FC<Props> = ({
  bound,
  boundFamily,
  flightIsInternational,
}) => {
  const allFareDetailsMappedByCarrierCode = getAllFareDetailsMappedByCarrierCode(
    flightIsInternational,
  );

  const carrierCode = bound.carrier ? bound.carrier.code : null;
  const familyName = boundFamily.family.replace(/\s+/g, "").toLocaleLowerCase();

  const fareFamilyDetailsInJson =
    carrierCode && carrierCode in allFareDetailsMappedByCarrierCode
      ? allFareDetailsMappedByCarrierCode[carrierCode][familyName]
      : null;

  if (fareFamilyDetailsInJson) {
    return (
      <FlightFareBoundItem
        cabinClass={bound.cabinClass}
        carryOnLuggage={fareFamilyDetailsInJson.carryOnLuggage}
        changeRule={fareFamilyDetailsInJson.changeRule}
        checkedBaggage={fareFamilyDetailsInJson.checkedBaggage}
        destinationCode={bound.destination.code}
        family={boundFamily.family}
        originCode={bound.origin.code}
        refundRule={fareFamilyDetailsInJson.refundRule}
      />
    );
  }

  const checkedBaggageInfo = {
    pieces: boundFamily.baggage?.pieces,
    weight: boundFamily.baggage?.weight,
  };

  return (
    <FlightFareBoundItem
      cabinClass={bound.cabinClass}
      checkedBaggage={checkedBaggageInfo}
      destinationCode={bound.destination.code}
      family={boundFamily.family}
      originCode={bound.origin.code}
    />
  );
};

export const FlightFareBoundSkeleton: React.FC = () => {
  return <FlightFareBoundItemSkeleton />;
};

const getBaggageWeightText = (weight?: number) => {
  return weight ? `de ${weight}kg` : "";
};

const getCarryOnLuggageText = (pieces?: number, weight?: number) => {
  if (!pieces || pieces === 0) return null;

  return pieces === 1
    ? `1 Bagagem de mão ${getBaggageWeightText(weight)}`
    : `${pieces} Bagagens de mão ${getBaggageWeightText(weight)}`;
};

const getDispatchedBaggageText = (pieces?: number, weight?: number): string => {
  if (pieces === 0 || !pieces) return "Nenhuma bagagem despachada.";

  if (pieces === 1) {
    return `1 Bagagem despachada ${getBaggageWeightText(weight)}.`;
  } else {
    return `${pieces} Bagagens despachadas ${getBaggageWeightText(weight)}.`;
  }
};

type FareBoundItemProps = {
  cabinClass: string;
  carryOnLuggage?: {
    pieces: number;
    weight?: number;
  };
  changeRule?: string;
  checkedBaggage?: {
    pieces?: number;
    weight?: number;
  };
  destinationCode: string;
  family: string;
  originCode: string;
  refundRule?: string;
};

const FlightFareBoundItem: React.FC<FareBoundItemProps> = ({
  cabinClass,
  carryOnLuggage,
  changeRule,
  checkedBaggage,
  destinationCode,
  family,
  originCode,
  refundRule,
}) => {
  return (
    <div css={styles.item.root}>
      <div css={styles.item.top.root}>
        <span css={styles.item.top.family}>Tarifa {family}</span>
        <div css={styles.item.top.bounds.root}>
          <span css={styles.item.top.bounds.bound}>{originCode}</span>
          <Icon size={12} use="arrow-right" />
          <span css={styles.item.top.bounds.bound}>{destinationCode}</span>
        </div>
        <span css={styles.item.top.cabin}>{cabinClass}</span>
      </div>
      <div css={styles.item.bottom.root}>
        <div css={styles.item.bottom.carryings.root}>
          <div css={styles.item.bottom.carryings.carrying.root}>
            <Icon
              css={styles.item.bottom.carryings.carrying.icon}
              size={18}
              use="briefcase"
            />
            <div css={styles.item.bottom.carryings.carrying.texts.container}>
              <span css={styles.item.bottom.carryings.carrying.texts.name}>
                Mochila ou bolsa
              </span>
              <span css={styles.item.bottom.carryings.carrying.texts.text}>
                Deve caber embaixo do assento dianteiro.
              </span>
            </div>
          </div>
          {carryOnLuggage?.pieces && carryOnLuggage.pieces > 0 ? (
            <div css={styles.item.bottom.carryings.carrying.root}>
              <Icon
                css={styles.item.bottom.carryings.carrying.icon}
                size={18}
                use="baggage"
              />
              <div css={styles.item.bottom.carryings.carrying.texts.container}>
                <span css={styles.item.bottom.carryings.carrying.texts.name}>
                  {getCarryOnLuggageText(
                    carryOnLuggage.pieces,
                    carryOnLuggage.weight,
                  )}
                </span>
              </div>
            </div>
          ) : null}
          {checkedBaggage &&
          checkedBaggage.pieces &&
          checkedBaggage.pieces > 0 ? (
            <div css={styles.item.bottom.carryings.carrying.root}>
              <Icon
                css={styles.item.bottom.carryings.carrying.icon}
                size={18}
                use="baggage"
              />
              <div css={styles.item.bottom.carryings.carrying.texts.container}>
                <span css={styles.item.bottom.carryings.carrying.texts.name}>
                  {getDispatchedBaggageText(
                    checkedBaggage.pieces,
                    checkedBaggage.weight,
                  )}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {changeRule?.trim() || refundRule?.trim() ? (
          <div css={styles.item.bottom.policies.root}>
            {changeRule?.trim() ? (
              <div css={styles.item.bottom.policies.policy.root}>
                <Icon
                  css={styles.item.bottom.policies.policy.icon}
                  size={18}
                  use="calendar"
                />
                <div css={styles.item.bottom.policies.policy.texts.container}>
                  <span css={styles.item.bottom.policies.policy.texts.name}>
                    Política de remarcação
                  </span>
                  <span css={styles.item.bottom.policies.policy.texts.text}>
                    {changeRule}.
                  </span>
                </div>
              </div>
            ) : null}
            {refundRule?.trim() ? (
              <div css={styles.item.bottom.policies.policy.root}>
                <Icon
                  css={styles.item.bottom.policies.policy.icon}
                  size={18}
                  use="money-withdraw"
                />
                <div css={styles.item.bottom.policies.policy.texts.container}>
                  <span css={styles.item.bottom.policies.policy.texts.name}>
                    Política de reembolso
                  </span>
                  <span css={styles.item.bottom.policies.policy.texts.text}>
                    {refundRule}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const FlightFareBoundItemSkeleton: React.FC = () => {
  return (
    <div css={styles.item.root}>
      <div css={styles.item.top.root}>
        <span css={styles.item.top.family}>
          <Skeleton height="20px" width="96px" />
        </span>
        <div css={styles.item.top.bounds.root}>
          <Skeleton height="14px" width="24px" />
          <Icon size={12} use="arrow-right" />
          <Skeleton height="14px" width="24px" />
        </div>
        <span css={styles.item.top.cabin}>
          <Skeleton height="12px" width="54px" />
        </span>
      </div>
      <div css={styles.item.bottom.root}>
        <div css={styles.item.bottom.carryings.root}>
          <div css={styles.item.bottom.carryings.carrying.root}>
            <Skeleton height="20px" width="20px" />
            <div css={styles.item.bottom.carryings.carrying.texts.container}>
              <span css={styles.item.bottom.carryings.carrying.texts.name}>
                <Skeleton height="14px" width="96px" />
              </span>
              <span css={styles.item.bottom.carryings.carrying.texts.text}>
                <Skeleton height="14px" width="256px" />
              </span>
            </div>
          </div>
          <div css={styles.item.bottom.carryings.carrying.root}>
            <Skeleton height="20px" width="20px" />
            <div css={styles.item.bottom.carryings.carrying.texts.container}>
              <span css={styles.item.bottom.carryings.carrying.texts.name}>
                <Skeleton height="14px" width="96px" />
              </span>
              <span css={styles.item.bottom.carryings.carrying.texts.text}>
                <Skeleton height="14px" width="256px" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
