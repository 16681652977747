import { ERROR } from "@constants";

import clientApi from "@apis/client.api";
import * as tagApi from "@apis/tag.api";

const TagService = {
  async getClientTags() {
    const result = {};
    try {
      result.data = await tagApi.getClientTags();
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      }
    }
    return result;
  },
  async getClientTagOptions() {
    const result = {};
    try {
      result.data = await clientApi.getClientConfig();
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      }
    }
    return result;
  },
  async getUserTravelTags() {
    const result = {};
    try {
      result.data = await tagApi.getUserTravelTags();
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      }
    }
    return result;
  },
  async getTravelTags(travelToken) {
    const result = {};
    try {
      result.data = await tagApi.getTravelTags(travelToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      }
    }
    return result;
  },
  async createNewTag(tagName) {
    const result = {};
    try {
      result.data = await tagApi.createNewTag(tagName);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_CREATE_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_CREATE_TAG_ERROR;
      }
    }
    return result;
  },
  async addTravelTag(tagToken, travelToken) {
    const result = {};
    try {
      result.data = await tagApi.createTravelTag(tagToken, travelToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_ADD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_ADD_TAG_ERROR;
      }
    }
    return result;
  },
  async removeTravelTagToken(travelTagToken) {
    const result = {};
    try {
      result.data = await tagApi.removeTravelTagToken(travelTagToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  },
  async getAllClientTags() {
    const result = {};
    try {
      result.data = await tagApi.getAllClientTags();
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  },
  async editTag(tagName, tagToken) {
    const result = {};
    try {
      result.data = await tagApi.editTag(tagName, tagToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_EDIT_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  },
  async archiveTag(tagToken) {
    const result = {};
    try {
      result.data = await tagApi.archiveTag(tagToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_ARCHIVE_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  },
  async unarchiveTag(tagToken) {
    const result = {};
    try {
      result.data = await tagApi.unarchiveTag(tagToken);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_UNARCHIVE_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  },
  async changeTagOption(clientConfigToken, data) {
    const result = {};
    try {
      result.data = await clientApi.updateClientConfig(clientConfigToken, data);
    } catch (error) {
      if (error.response) {
        result.error = ERROR.UNEXPECTED_UPDATE_CONFIG_TAG_ERROR;
      } else if (error.request) {
        result.error = ERROR.GENERAL_CONNECTION_ERROR;
      } else {
        result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
      }
    }
    return result;
  }
};

export { TagService };
