import React from "react";

import { evaluatePasswordParameters } from "../../helpers/password.helper";
import { styles } from "./styles";

type Props = {
  password?: string;
};

export const PasswordMeter: React.FC<Props> = ({ password }) => {
  const {
    hasLowercaseChar,
    hasMininumLength,
    hasNumber,
    hasSpecialChar,
    hasUppercaseChar,
  } = evaluatePasswordParameters(password || "");

  return (
    <div css={styles.root}>
      <p css={styles.label}>A senha deve conter:</p>
      <ul css={styles.list.root}>
        <li css={styles.list.item({ valid: hasMininumLength })}>
          No mínimo 8 caracteres
        </li>
        <li css={styles.list.item({ valid: hasUppercaseChar })}>
          Ao menos 1 letra maiúscula
        </li>
        <li css={styles.list.item({ valid: hasLowercaseChar })}>
          Ao menos 1 letra minúscula
        </li>
        <li css={styles.list.item({ valid: hasNumber })}>Ao menos 1 número</li>
        <li css={styles.list.item({ valid: hasSpecialChar })}>
          {
            "Ao menos 1 caractere especial (ex: !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~)"
          }
        </li>
      </ul>
    </div>
  );
};
