import {
  APPROVAL_POLICY_LABELS,
  APPROVAL_PROCESS_TYPES_LABELS,
  APPROVAL_RULES_TERRITORIES_LABELS,
} from "~/constants";

export const APPROVAL_PROCESS_TYPES_OPTIONS = Object.keys(
  APPROVAL_PROCESS_TYPES_LABELS,
).map((key) => ({
  label: APPROVAL_PROCESS_TYPES_LABELS[key],
  value: key,
}));

export const POLICIES_OPTIONS = Object.keys(APPROVAL_POLICY_LABELS).map(
  (key) => ({
    label: APPROVAL_POLICY_LABELS[key],
    value: key,
  }),
);

export const TERRITORIES_OPTIONS = Object.keys(
  APPROVAL_RULES_TERRITORIES_LABELS,
).map((key) => ({
  label: APPROVAL_RULES_TERRITORIES_LABELS[key],
  value: key,
}));
