import React, { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  WindowScroller,
} from "react-virtualized";

import { CountryRestrictionMessage } from "~/apps/corporate/components/country-restriction-message/country-restriction-message";
import { NoResultsWarning } from "~/apps/shared/components/no-results-warning/no-results-warning";
import { useUpdatedVirtualizedGrid } from "~/apps/shared/hooks/use-updated-virtualized-grid";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { useFlights } from "../../flights.context";
import { ContentFilters } from "../content-filters/content-filters";
import { FlightCard, FlightCardSkeleton } from "./flight-card/flight-card";
import { FlightOutOfPolicyDialog } from "./flight-out-of-policy-dialog/flight-out-of-policy-dialog";
import { ListOrderOptions } from "./list-order-options/list-order-options";
import { styles } from "./styles";

export const ContentList: React.FC = () => {
  const {
    countryRestriction,
    filtering,
    flights,
    handleOpenFlightDetailsDrawer,
    handlePolicyDialogOpen,
    handleSingleFlightFilter,
    isLoading,
    ordering,
    selectFlight,
    setViewed,
    singleFlightFilter,
    viewedOffers,
    visibleFlights,
  } = useFlights();
  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 1024, [windowWidth]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
  });

  const listRef = useRef<List>(null);

  const renderFlight: ListRowRenderer = ({ index, key, parent, style }) => {
    const flight = visibleFlights[index];
    const hasBeenViewed = !!viewedOffers[flight.itineraryId];

    const handleSetViewed = (itineraryId: string) => {
      setViewed(itineraryId);
    };

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <FlightCard
            flight={flight}
            handleSingleFlightFilter={handleSingleFlightFilter}
            hasBeenViewed={hasBeenViewed}
            isInboundFlightChecked={singleFlightFilter.inboundChecked}
            isOutboundFlightChecked={singleFlightFilter.outboundChecked}
            onOutOfPolicyClick={handlePolicyDialogOpen}
            openFlightDetailsDrawer={handleOpenFlightDetailsDrawer}
            selectFlight={selectFlight}
            setViewed={handleSetViewed}
          />
        </div>
      </CellMeasurer>
    );
  };

  const stats = useMemo(() => {
    const count = visibleFlights.length;

    return filtering
      ? "Filtrando..."
      : ordering
      ? "Ordenando..."
      : `${count} ${count === 1 ? "resultado" : "resultados"}${
          !isMobile ? " de voo para sua pesquisa" : ""
        }`;
  }, [filtering, visibleFlights]);

  useUpdatedVirtualizedGrid(cache, listRef);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          {countryRestriction ? (
            <CountryRestrictionMessage
              message={countryRestriction.message_content}
              restrictionType={countryRestriction.restriction_type}
            />
          ) : null}
          <ListOrderOptions
            disabled={visibleFlights.length === 0 || isLoading}
          />
          <div css={styles.stats.root}>
            <span css={styles.stats.count}>
              {!isLoading ? (
                <p css={styles.stats.root}>{stats}</p>
              ) : (
                <Skeleton height="16px" width={!isMobile ? "288px" : "96px"} />
              )}
            </span>
            {!isMobile ? null : <ContentFilters isMobile={isMobile} />}
          </div>
        </div>
        {!isLoading ? (
          <WindowScroller>
            {({ height, isScrolling, registerChild, scrollTop }) => (
              <div style={{ flex: "1 1 auto" }}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <List
                        autoHeight
                        deferredMeasurementCache={cache}
                        height={height}
                        isScrolling={isScrolling}
                        noRowsRenderer={() => (
                          <div css={styles.empty.root}>
                            <NoResultsWarning
                              message={
                                flights.length === 0 ? (
                                  <>
                                    <p>
                                      Ops, parece que não encontramos nenhum voo
                                      para a sua pesquisa em nossos
                                      fornecedores.
                                    </p>
                                    <p css={styles.empty.text}>
                                      Pedimos deculpa pelo transtorno. Por ora,
                                      recomendamos que entre em contato com
                                      nossa equipe de atendimento para que
                                      possamos encontrar um voo para você.
                                    </p>
                                  </>
                                ) : flights.length > 0 &&
                                  visibleFlights.length === 0 ? (
                                  "Nenhum voo encontrado para filtro o selecionado..."
                                ) : null
                              }
                            />
                          </div>
                        )}
                        overscanRowCount={8}
                        ref={listRef}
                        rowCount={visibleFlights.length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={renderFlight}
                        scrollTop={scrollTop}
                        style={{ outline: "none" }}
                        width={width}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            )}
          </WindowScroller>
        ) : (
          <div>
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <FlightCardSkeleton key={index} />
              ))}
          </div>
        )}
      </div>
      <FlightOutOfPolicyDialog />
    </>
  );
};
