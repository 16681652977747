import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  front: {
    bumpers: {
      "1": css({
        backgroundColor: theme.colors.gray[100],
        borderBottomLeftRadius: "33px",
        borderTopLeftRadius: "33px",
        height: "100%",
        position: "relative",
        width: "50px",
        "@media screen and (max-width: 992px)": {
          borderBottomLeftRadius: "0px",
          borderTopRightRadius: "33px",
          height: "50px",
          width: "100%",
        },
      }),
      "2": css({
        backgroundColor: theme.colors.gray[300],
        borderBottomLeftRadius: "50%",
        borderTopLeftRadius: "50%",
        height: "100%",
        left: "20px",
        position: "absolute",
        top: "0",
        width: "30px",
        "@media screen and (max-width: 992px)": {
          borderBottomLeftRadius: "0px",
          borderTopRightRadius: "50%",
          height: "30px",
          left: "0",
          top: "20px",
          width: "100%",
        },
      }),
      "3": css({
        backgroundColor: theme.colors.white,
        borderBottomLeftRadius: "50%",
        borderTopLeftRadius: "50%",
        height: "100%",
        left: "25px",
        position: "absolute",
        top: "0",
        width: "30px",
        "@media screen and (max-width: 992px)": {
          borderBottomLeftRadius: "0px",
          borderTopRightRadius: "50%",
          height: "30px",
          left: "0",
          top: "25px",
          width: "100%",
        },
      }),
    },
    lights: {
      left: css({
        backgroundColor: theme.colors.gray[300],
        borderRadius: "100%",
        bottom: "9px",
        height: "32px",
        left: "1px",
        position: "absolute",
        transform: "rotate(-17deg)",
        width: "8px",
        "@media screen and (max-width: 992px)": {
          bottom: "unset",
          height: "8px",
          left: "9px",
          top: "1px",
          width: "32px",
        },
      }),
      right: css({
        backgroundColor: theme.colors.gray[300],
        borderRadius: "100%",
        height: "32px",
        left: "1px",
        position: "absolute",
        top: "9px",
        transform: "rotate(17deg)",
        width: "8px",
        "@media screen and (max-width: 992px)": {
          height: "8px",
          left: "unset",
          right: "9px",
          top: "1px",
          width: "32px",
        },
      }),
    },
    root: css({
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      "@media screen and (max-width: 992px)": {
        width: "100%",
      },
    }),
  },
  root: ({ width }: { width: string }) =>
    css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "12px",
      borderBottomLeftRadius: "33px",
      borderTopLeftRadius: "33px",
      height: "auto",
      minHeight: "224px",
      minWidth: "512px",
      padding: "16px",
      paddingLeft: "40px",
      paddingTop: "16px",
      position: "relative",
      width,
      "@media (max-width: 992px)": {
        borderBottomLeftRadius: "12px",
        borderTopRightRadius: "33px",
        height: width,
        minHeight: "512px",
        minWidth: "224px",
        paddingLeft: "16px",
        paddingTop: "40px",
        width: "auto",
      },
    }),
  wheel: css({
    bottom: "16px",
    color: theme.colors.gray[300],
    left: "40px",
    position: "absolute",
    transform: "rotate(-90deg)",
    "& > svg": {
      height: "32px",
      width: "32px",
    },
    "@media screen and (max-width: 992px)": {
      bottom: "unset",
      left: "12px",
      top: "40px",
      transform: "none",
    },
  }),
};
