import React from "react";

import { BUS_STRETCH_TYPE } from "~/apps/shared/constants";

import { styles } from "./styles";

type Props = {
  currentSection: typeof BUS_STRETCH_TYPE[keyof typeof BUS_STRETCH_TYPE];
  handleClick: (
    section: typeof BUS_STRETCH_TYPE[keyof typeof BUS_STRETCH_TYPE],
  ) => () => void;
};

export const BusStretchTabs: React.FC<Props> = ({
  currentSection,
  handleClick,
}) => {
  return (
    <div css={styles.root}>
      <button
        css={styles.tab({
          active: BUS_STRETCH_TYPE.DEPARTURE === currentSection,
        })}
        onClick={handleClick(BUS_STRETCH_TYPE.DEPARTURE)}
      >
        {BUS_STRETCH_TYPE.RETURN === currentSection
          ? "Selecionar ida"
          : "Selecionando ida"}
      </button>
      <button
        css={styles.tab({ active: BUS_STRETCH_TYPE.RETURN === currentSection })}
        disabled={BUS_STRETCH_TYPE.DEPARTURE === currentSection}
        onClick={handleClick(BUS_STRETCH_TYPE.RETURN)}
      >
        Selecionando volta
      </button>
    </div>
  );
};
