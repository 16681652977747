import React, { createContext, useEffect } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { ApprovalListStatus } from "~/constants/enums";

import { TravelApproval } from "~/models/travel.model";

import { useSafeState, useContextFactory } from "@hooks";

import * as travelApprovalsService from "./TravelApprovals.service";

interface Actions {
  handleChangeSearch: (searchText: string) => void;
  handleTabClick: (currentStatus: ApprovalListStatus) => void;
  loadInfo: (currentStatus?: ApprovalListStatus) => void;
  loadNextPage: () => Promise<void>;
}

type State = {
  currentPage: number;
  loading: boolean;
  pages: number;
  searchText: string;
  status: ApprovalListStatus;
  totalTravels: number;
  travels: TravelApproval[];
};

const initialState: State = {
  currentPage: 1,
  loading: false,
  pages: 0,
  searchText: "",
  status: ApprovalListStatus.PENDING,
  totalTravels: 0,
  travels: [],
};

type ContextProps = Actions & State;

const TravelApprovalsContext = createContext<ContextProps>({} as ContextProps);

export const TravelApprovalsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const loadInfo = async (currentStatus: ApprovalListStatus = state.status) => {
    const { searchText } = state;

    setState({
      currentPage: 1,
      loading: true,
      pages: 0,
      status: currentStatus,
      totalTravels: 0,
      travels: [],
    });

    const [{ data: approvalsData, error: approvalsError }] = await Promise.all([
      travelApprovalsService.getApprovalsList(1, currentStatus, searchText),
    ]);

    if (approvalsError) {
      setState({
        loading: false,
      });

      showSnackMessage(approvalsError.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      currentPage: approvalsData!.currentPage,
      loading: false,
      pages: approvalsData!.pages,
      totalTravels: approvalsData!.totalTravels,
      travels: approvalsData!.travels,
    });
  };

  const loadNextPage = async () => {
    const { travels, currentPage, status } = state;

    setState({
      loading: true,
    });

    const { data, error } = await travelApprovalsService.getApprovalsList(
      currentPage + 1,
      status,
    );

    if (error) {
      setState({
        loading: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      currentPage: currentPage + 1,
      loading: false,
      travels: travels.concat(data!.travels),
    });
  };

  const handleTabClick = async (status: ApprovalListStatus) => {
    if (status !== state.status) {
      await loadInfo(status);
    }
  };

  const handleChangeSearch = debounce((searchText) => {
    setState({ searchText });
  }, 1000);

  useEffect(() => {
    void loadInfo();
  }, [state.searchText]);

  return (
    <TravelApprovalsContext.Provider
      value={{
        ...state,
        handleChangeSearch,
        handleTabClick,
        loadInfo,
        loadNextPage,
      }}
    >
      {children}
    </TravelApprovalsContext.Provider>
  );
};

export const useTravelApprovalsContext: () => ContextProps = useContextFactory(
  "TravelApprovalsContext",
  TravelApprovalsContext,
);
