import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    width: "fit-content",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px",
    width: "100%",
  }),
  top: {
    address: {
      address: css({
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: "19.36px",
        textTransform: "capitalize",
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.pink[500],
        display: "flex",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
};
