import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { CarCategoryExplanationDialog } from "~/apps/corporate/components/car-category-explanation-dialog/car-category-explanation-dialog";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { SearchExpirationDialog } from "~/apps/shared/components/search-expiration-dialog/search-expiration-dialog";
import { useCheckExpiration } from "~/apps/shared/hooks/use-check-expiration";

import { CarsContent } from "./cars-content/cars-content";
import { CarsHeader } from "./cars-header/cars-header";
import { CarsProvider, useCars } from "./cars.context";
import { CarsEditSearchDialog } from "./edit-search-dialog/edit-search-dialog";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    carToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({ carToken, travelToken }) => {
  const {
    expirationTime,
    fetchCarsList,
    handleExpiration,
    handleToggleCategoryExplanation,
    hasExpired,
    isCategoryExplanationVisible,
    policy,
  } = useCars();

  useCheckExpiration(expirationTime, handleExpiration, 2 * 60 * 1000);

  useEffect(() => {
    if (!carToken) {
      return;
    }

    void fetchCarsList(carToken);
  }, [carToken]);

  return (
    <>
      <PageTitle title="Pesquisa de carros" />
      <div css={styles.root}>
        <CarsHeader />
        <CarsContent />
      </div>
      {policy && policy.maxCategory ? (
        <CarCategoryExplanationDialog
          maxCategory={policy.maxCategory}
          onClose={handleToggleCategoryExplanation}
          open={isCategoryExplanationVisible}
        />
      ) : null}
      {travelToken ? <CarsEditSearchDialog travelToken={travelToken} /> : null}
      <SearchExpirationDialog open={hasExpired} />
    </>
  );
};

export const Cars: React.FC<Props> = ({ ...props }) => {
  return (
    <CarsProvider>
      <Component {...props} />
    </CarsProvider>
  );
};
