import {
  ExpensePolicyForm,
  HotelPolicyRule,
  ExpensePolicyTarget
} from "../components/configuration/expenses/expense-policy/ExpensePolicy.types";
import { CreateAndEditExpensePolicyRequest } from "../dtos/expense-policy.dto";
import { getClientToken } from "./user.helper";
import {
  ExpensePolicy,
  ExpensePolicyListItem
} from "../models/expense-policy.model";
import { TARGET_TYPES, CABINS } from "../constants";
import * as utils from "./index";

export function buildCreateExpensePolicyRequestData(
  formData: ExpensePolicyForm
): CreateAndEditExpensePolicyRequest {
  const buildedData: any = {};

  buildedData.policy = {
    policy_token: formData.policyToken,
    target_type: formData.targetType ? formData.targetType : "u",
    client_token: getClientToken(),
    description: formData.description,
    national_flights_max_value: formData.nationalFlightMaxValue,
    national_hotels_max_value: formData.nationalHotelMaxValue,
    national_breakfast_max_value: formData.nationalBreakfastMaxValue,
    national_lunch_max_value: formData.nationalLunchMaxValue,
    national_dinner_max_value: formData.nationalDinnerMaxValue,
    national_mileage_rate: formData.nationalMileageRate,
    international_flights_max_value: formData.internationalFlightMaxValue,
    international_hotels_max_value: formData.internationalHotelMaxValue,
    international_breakfast_max_value: formData.internationalBreakfastMaxValue,
    international_lunch_max_value: formData.internationalLunchMaxValue,
    international_dinner_max_value: formData.internationalDinnerMaxValue,
    // new fields
    national_flights_class: formData.nationalFlightsClass || CABINS.economy,
    national_flights_advance: formData.nationalFlightsAdvance || 0,
    national_flights_price_excess: formData.nationalFlightsPriceExcess,
    international_flights_class:
      formData.internationalFlightsClass || CABINS.economy,
    international_flights_advance: formData.internationalFlightsAdvance || 0,
    international_flights_price_excess:
      formData.internationalFlightsPriceExcess,
    international_duration: formData.internationalDuration,
    international_duration_class: formData.internationalDurationClass,
    national_stars: formData.nationalStars,
    national_hotels_advance: formData.nationalHotelsAdvance || 0,
    international_stars: formData.internationalStars,
    international_hotels_advance: formData.nationalHotelsAdvance || 0,
    search_visibility: formData.searchVisibility
  };

  const buildedHotelRules = new Array()
    .concat(formData.nationalHotelRules, formData.internationalHotelRules)
    .map(buildExpensePolicyHotelRule);

  buildedData.rules = new Array().concat(buildedHotelRules);

  buildedData.targets = formData.targets.map(buildExpensePolicyTarget);

  return buildedData;
}

function buildExpensePolicyHotelRule(rule: HotelPolicyRule) {
  const buildedRule: any = {
    offer_type: rule.offerType,
    national_type: rule.nationalType,
    max_value: rule.maxValue,
    location_name: rule.locationName,
    location_city: rule.locationCity,
    location_state: rule.locationState,
    location_country: rule.locationCountry
  };

  if (rule.policyRuleToken) {
    buildedRule.policy_rule_token = rule.policyRuleToken;
  }

  return buildedRule;
}

function buildExpensePolicyTarget(target: ExpensePolicyTarget) {
  const buildedTarget: any = {
    target_type: "u",
    target_token: target.userToken
  };

  if (target.policyTargetToken) {
    buildedTarget.policy_target_token = target.policyTargetToken;
  }

  return buildedTarget;
}

export function mapExpensePolicyToFormData(
  policy: ExpensePolicy | null
): ExpensePolicyForm {
  if (policy) {
    const buildedFormData: any = {
      policyToken: policy.policyToken,
      targetType: policy.targetType,
      description: policy.description,
      nationalFlightMaxValue: policy.nationalFlightPolicy.maxValue,
      nationalHotelMaxValue: policy.nationalHotelPolicy.maxValue,
      nationalBreakfastMaxValue: policy.nationalFoodPolicy.breakfastMaxValue,
      nationalLunchMaxValue: policy.nationalFoodPolicy.lunchMaxValue,
      nationalDinnerMaxValue: policy.nationalFoodPolicy.dinnerMaxValue,
      nationalMileageRate: policy.nationalMileagePolicy.rate,
      internationalFlightMaxValue: policy.internationalFlightPolicy.maxValue,
      internationalHotelMaxValue: policy.internationalHotelPolicy.maxValue,
      internationalBreakfastMaxValue:
        policy.internationalFoodPolicy.breakfastMaxValue,
      internationalLunchMaxValue: policy.internationalFoodPolicy.lunchMaxValue,
      internationalDinnerMaxValue:
        policy.internationalFoodPolicy.dinnerMaxValue,
      // fields from other form
      nationalFlightsClass: policy.nationalFlightPolicy.class,
      nationalFlightsAdvance: policy.nationalFlightPolicy.advance,
      nationalFlightsPriceExcess: policy.nationalFlightPolicy.priceExcess,
      internationalFlightsClass: policy.internationalFlightPolicy.class,
      internationalFlightsAdvance: policy.internationalFlightPolicy.advance,
      internationalFlightsPriceExcess:
        policy.internationalFlightPolicy.priceExcess,
      internationalDuration: policy.internationalFlightPolicy.duration,
      internationalDurationClass:
        policy.internationalFlightPolicy.durationClass,
      nationalStars: policy.nationalHotelPolicy.stars,
      nationalHotelsAdvance: policy.nationalHotelPolicy.advance,
      internationalStars: policy.internationalHotelPolicy.stars,
      internationalHotelsAdvance: policy.internationalHotelPolicy.advance,
      searchVisibility: true
    };

    const nationalHotelRules = policy.nationalHotelPolicy.rules;
    const internationalHotelRules = policy.internationalHotelPolicy.rules;

    buildedFormData.nationalHotelRules = nationalHotelRules;
    buildedFormData.internationalHotelRules = internationalHotelRules;

    buildedFormData.targets = policy.targets.map(target => ({
      policyTargetToken: target.policyTargetToken,
      email: target.email,
      fullName: target.fullName,
      userToken: target.userToken,
      isUse: 1
    }));

    return buildedFormData;
  } else {
    return {
      targetType: "u",
      description: "",
      nationalFlightMaxValue: undefined,
      nationalFlightRules: [],
      nationalHotelMaxValue: undefined,
      nationalHotelRules: [],
      nationalBreakfastMaxValue: undefined,
      nationalLunchMaxValue: undefined,
      nationalDinnerMaxValue: undefined,
      nationalMileageRate: undefined,
      internationalFlightMaxValue: undefined,
      internationalFlightRules: [],
      internationalHotelMaxValue: undefined,
      internationalHotelRules: [],
      internationalBreakfastMaxValue: undefined,
      internationalDinnerMaxValue: undefined,
      internationalLunchMaxValue: undefined,
      targets: [],
      nationalFlightsClass: undefined,
      nationalFlightsAdvance: undefined,
      nationalFlightsPriceExcess: undefined,
      internationalFlightsClass: undefined,
      internationalFlightsAdvance: undefined,
      internationalFlightsPriceExcess: undefined,
      internationalDuration: undefined,
      internationalDurationClass: undefined,
      nationalStars: undefined,
      nationalHotelsAdvance: undefined,
      internationalStars: undefined,
      internationalHotelsAdvance: undefined,
      searchVisibility: true
    };
  }
}

export function orderPoliciesList(policies: ExpensePolicyListItem[]) {
  const { company: companyPolicies, user: userPolicies } = policies.reduce(
    (acc: any, current) => {
      if (current.targetType === TARGET_TYPES.COMPANY) {
        acc.company.push(current);
      } else {
        acc.user.push(current);
      }

      return acc;
    },
    {
      company: [],
      user: []
    }
  );

  const orderedCompanyPolicies = companyPolicies.sort(
    utils.sortByField("description")
  );
  const orderedUserPolicies = userPolicies.sort(
    utils.sortByField("description")
  );

  return new Array().concat(orderedCompanyPolicies, orderedUserPolicies);
}
