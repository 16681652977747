import React, { useMemo } from "react";

import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import * as yup from "yup";

import { Button } from "@toolkit/v2";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItineraryContainer } from "../../../../itinerary.container";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";
import { styles } from "./styles";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const OffersJustificationAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const { pendenciesModel, servicesPresenter } = useItineraryContainer();

  const {
    pendencies,
    saveOffersJustificationAndProceed,
  } = useItineraryPendencies();

  const formSchema = useMemo(() => {
    if (!pendenciesModel) {
      return yup.object().shape({
        offersJustifications: yup.array().of(
          yup.object().shape({
            justification: yup.string(),
            offerToken: yup.string(),
          }),
        ),
      });
    }

    return yup.object().shape({
      offersJustifications: yup.array().of(
        yup.object().shape({
          justification: pendenciesModel.isOffersJustificationRequired()
            ? yup.string().required("Justifique a oferta.")
            : yup.string(),
          offerToken: yup.string(),
        }),
      ),
    });
  }, [pendenciesModel]);

  type FormSchema = yup.InferType<typeof formSchema>;

  const form = useForm<FormSchema>({
    defaultValues: {
      offersJustifications: pendenciesModel
        ? pendenciesModel.getOffersJustificationPendingDefaultValue()
        : [],
    } as FormSchema,
    onSubmit: async () => {
      const values = form.getValues();

      const success = await saveOffersJustificationAndProceed(
        values.offersJustifications,
      );

      if (!success) {
        return;
      }

      next();
    },
    schema: formSchema,
  });

  if (!pendencies || !pendenciesModel || !servicesPresenter) {
    return null;
  }

  const isResolved = pendencies.isOffersJustificationResolved;

  return (
    <PendenciesAccordion
      description="Justifique a escolha das ofertas."
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={
        <>
          {index}. Escolha das ofertas.
          {pendenciesModel.isOffersJustificationRequired() ? (
            "*"
          ) : (
            <span css={styles.optional}> (opcional)</span>
          )}
        </>
      }
      toggleExpanded={toggleExpanded}
    >
      <Form context={form} css={styles.form.root}>
        {pendenciesModel
          .getOffersJustificationPending()
          .map((offerToken, index) => {
            const service = servicesPresenter.getServiceByOfferToken(
              offerToken,
            );

            if (!service) {
              return null;
            }

            const indexedErrors = form.errors["offersJustifications"]?.[index];
            const indexedName = `offersJustifications[${index}]`;

            return (
              <div css={styles.form.textarea.root} key={offerToken}>
                <div css={styles.form.textarea.label.root}>
                  <Icon size={16} use={service.getIcon()} />
                  <span css={styles.form.textarea.label.text}>
                    {service.getFormattedItineraryServiceHeaderName()}
                  </span>
                </div>
                <Form.Field
                  name={`${indexedName}.justification`}
                  render={({ onChange, value }) => (
                    <textarea
                      css={styles.form.textarea.textarea}
                      onChange={onChange}
                      placeholder="Justifique a escolha da oferta..."
                      value={value}
                    />
                  )}
                />
                <InputErrorMessage>
                  {indexedErrors?.["justification"]?.message}
                </InputErrorMessage>
              </div>
            );
          })}
        <Button
          css={styles.button}
          disabled={form.formState.isSubmitting || isLoadingNext}
        >
          Salvar
        </Button>
      </Form>
    </PendenciesAccordion>
  );
};
