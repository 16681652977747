import {
  CostCenterListResponseItem,
  CostCenterResponse,
  CostCenterListByUserResponseItem
} from "../dtos/cost-center.dto";
import {
  CostCenterListItem,
  CostCenter,
  CostCenterUserListItem
} from "../models/cost-center.model";
import * as userParser from "./user.parser";

export function parseCostCenterListItem({
  id,
  cost_center_token,
  name,
  active,
  client_token,
  created_at,
  total_users
}: CostCenterListResponseItem): CostCenterListItem {
  return {
    id,
    token: cost_center_token,
    name,
    active,
    clientToken: client_token,
    createdAt: created_at,
    userCount: total_users
  };
}

export function parseCostCenter({
  id,
  cost_center_token,
  name,
  active,
  client_token,
  created_at,
  users,
  total_users
}: CostCenterResponse): CostCenter {
  return {
    id,
    token: cost_center_token,
    name,
    active,
    clientToken: client_token,
    createdAt: created_at,
    users: users.map(user => userParser.User(user)),
    userCount: total_users
  };
}

export function parseCostCenterListByUserItem({
  id,
  cost_center_token,
  name,
  active,
  client_token,
  created_at
}: CostCenterListByUserResponseItem): CostCenterUserListItem {
  return {
    id,
    token: cost_center_token,
    name,
    active,
    clientToken: client_token,
    createdAt: created_at
  };
}
