import { ERROR } from "@constants";

import {
  BudgetResult,
  DeleteBudgetResult,
  RegisterBudgetDto,
  UpdateBudgetDto,
} from "@models/budget.model";

import { CustomError } from "~/types";

import * as budgetApi from "@apis/budget.api";

export async function listClientBudgets(
  clientToken: string,
): Promise<{
  data?: BudgetResult[];
  error?: CustomError;
}> {
  const result: { data?: BudgetResult[]; error?: CustomError } = {};

  try {
    result.data = await budgetApi.getClientBudgets(clientToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUDGET_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUDGET_LIST_ERROR;
    }
  }

  return result;
}

export async function deleteClientBudget(
  budgetToken: string,
): Promise<{
  data?: DeleteBudgetResult;
  error?: CustomError;
}> {
  const result: { data?: DeleteBudgetResult; error?: CustomError } = {};

  try {
    result.data = await budgetApi.deleteBudget(budgetToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUDGET_DELETE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUDGET_DELETE_ERROR;
    }
  }

  return result;
}

export async function registerBudget(
  budget: RegisterBudgetDto,
): Promise<{
  data?: BudgetResult;
  error?: CustomError;
}> {
  const result: { data?: BudgetResult; error?: CustomError } = {};

  try {
    result.data = await budgetApi.registerBudget(budget);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUDGET_CREATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUDGET_CREATE_ERROR;
    }
  }

  return result;
}

export async function updateBudget(
  budgetToken: string,
  budget: UpdateBudgetDto,
): Promise<{
  data?: BudgetResult;
  error?: CustomError;
}> {
  const result: { data?: BudgetResult; error?: CustomError } = {};

  try {
    result.data = await budgetApi.updateBudget(budgetToken, budget);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_BUDGET_CREATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_BUDGET_CREATE_ERROR;
    }
  }

  return result;
}
