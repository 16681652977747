import * as Sentry from "@sentry/react";
import { useEffect } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";

export const useSentrySetUser = () => {
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      return;
    }

    Sentry.setUser(user);
  }, [user]);
};
