import { css } from "@styled-system/css";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  }),
};
