import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Text
} from "recharts";
import {
  EXPENSES_CATEGORIES_TRANSLATION,
  COLOR_BY_EXPENSE_CATEGORY
} from "@constants";
import * as utils from "@helpers";
import { theme } from "smartrips-skin";

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;

  return (
    <g>
      <Text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill={fill}
        width={160}
        style={{ textTransform: "capitalize", fontSize: 14 }}
      >
        {EXPENSES_CATEGORIES_TRANSLATION[payload.type]}
      </Text>
      <Text
        x={cx}
        y={cy}
        dy={16}
        textAnchor="middle"
        fill={theme.colors.text}
        width={160}
        style={{ fontSize: 12 }}
      >
        {utils.toCurrency(value)}
      </Text>
      <Text
        x={cx}
        y={cy}
        dy={32}
        textAnchor="middle"
        fill="#999"
        width={160}
        style={{ fontSize: 12 }}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </Text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

class ServiceExpensesPieChart extends React.Component {
  state = {
    activeIndex: 0
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    const { generalExpenses } = this.props;
    const hasSomeDataToShow = generalExpenses.some(expense => !!expense.total);

    if (!hasSomeDataToShow) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 330
          }}
        >
          <p>Nenhum dado a ser mostrado</p>
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={330}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={generalExpenses}
            dataKey="total"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            onMouseEnter={this.onPieEnter}
          >
            {generalExpenses.map(entry => (
              <Cell
                key={entry.type}
                fill={COLOR_BY_EXPENSE_CATEGORY[entry.type]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export { ServiceExpensesPieChart };
