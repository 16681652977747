import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { Filters } from "~/apps/shared/components/filters/filters";
import { theme } from "smartrips-skin/lib/v2";

import {
  BillingProfilesProvider,
  useBillingProfileActionsContext,
  useBillingProfileContext,
} from "~/apps/corporate/pages/configurations/views/financial/billing-profiles/BillingProfiles.context";
import { ContainedButton } from "@components/shared/buttons/contained-button";

import {
  CostCentersContext,
  CostCentersProvider,
} from "../cost-centers/CostCenters.context";
import { BudgetsDeletionAlertDialog } from "./budgets-alert-dialog";
import { RegisterBudgetsForm } from "./budgets-register-form";
import {
  PERIOD_DEFAULT_FILTERS,
  PERIOD_DETAILD_MONTH_DEFAULT_FILTERS,
  PERIOD_DETAILD_SEMESTER_DEFAULT_FILTERS,
  PERIOD_DETAILD_TRIMESTER_DEFAULT_FILTERS,
  YEAR_DEFAULT_FILTERS,
} from "./budgets.utils";
import BudgetsCardsContainer from "./bugets-cards/bugets-cards-container";
import { NewBudgetsProvider, useNewBudgetsContext } from "./newBudgets.context";

const styles = {
  root: css({
    display: "flex",
    alignContent: "center",
    gap: "1rem",
    padding: "0.75rem 0rem",
    borderRadius: "0.5rem",
  }),
  filtersContainer: css({
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
  }),
  buttons: {
    root: css({
      flexDirection: "row",
      justifyContent: "space-between",
      ["@media (max-width: 1500px)"]: {
        flexDirection: "column",
        gap: "1rem",
      },
    }),
    add: css({
      width: "fit-content",
      height: "3rem",
      backgroundColor: theme.colors.pink[500],
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2),
      },
    }),
  },
};

const Budgets = () => {
  const {
    isLoading: isLoadingBudgets,
    periodDetaildFilter,
    periodFilter,
    filteredBudgets,
    yearFilter,
    isBudgetAlertDialogOpen,
    budgetToDelete,
    isFormOpen,
    budgetToEdit,
    loadClientBudgets,
    handlePeriodFilter,
    handlePeriodDetaildFilter,
    handleYearFilter,
    handleBudgetAlertDialog,
    handleDeleteBudget,
    handleOpenForm,
    handleCloseForm,
  } = useNewBudgetsContext();

  const {
    billingProfiles,
    isLoading: isLoadingProfiles,
  } = useBillingProfileContext();

  const { loadProfiles } = useBillingProfileActionsContext();

  const {
    fetchCostCenters,
    costCenters,
    isLoading: isLoadingCostCenters,
  } = useContext(CostCentersContext);

  useEffect(() => {
    loadClientBudgets();
    loadProfiles();
    fetchCostCenters();
  }, []);

  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();

  return (
    <div>
      <div css={styles.root}>
        <div style={{ width: "100%" }}>
          <ContainedButton
            css={styles.buttons.add}
            onClick={() => handleOpenForm()}
          >
            Cadastrar Orçamento
          </ContainedButton>
        </div>
        <div css={styles.filtersContainer}>
          <Filters
            value={yearFilter ? yearFilter : anoAtual.toString()}
            onChange={(e) => handleYearFilter(e)}
            closeOnSelect
            options={YEAR_DEFAULT_FILTERS}
          ></Filters>
          <Filters
            value={periodFilter}
            onChange={(e) => handlePeriodFilter(e)}
            closeOnSelect
            options={PERIOD_DEFAULT_FILTERS}
          ></Filters>
          {periodFilter === "SEMESTER" && (
            <Filters
              value={periodDetaildFilter ? periodDetaildFilter : ""}
              onChange={(e) => handlePeriodDetaildFilter(e)}
              closeOnSelect
              options={PERIOD_DETAILD_SEMESTER_DEFAULT_FILTERS}
            ></Filters>
          )}
          {periodFilter === "TRIMESTER" && (
            <Filters
              value={periodDetaildFilter ? periodDetaildFilter : ""}
              onChange={(e) => handlePeriodDetaildFilter(e)}
              closeOnSelect
              options={PERIOD_DETAILD_TRIMESTER_DEFAULT_FILTERS}
            ></Filters>
          )}
          {periodFilter === "MONTH" && (
            <Filters
              value={periodDetaildFilter ? periodDetaildFilter : ""}
              onChange={(e) => handlePeriodDetaildFilter(e)}
              closeOnSelect
              options={PERIOD_DETAILD_MONTH_DEFAULT_FILTERS}
            ></Filters>
          )}
        </div>
      </div>
      {!isLoadingProfiles && !isLoadingCostCenters && !isLoadingBudgets ? (
        <BudgetsCardsContainer
          budgets={filteredBudgets}
          billingProfiles={billingProfiles}
          costCenters={costCenters}
        />
      ) : (
        <Skeleton height={500} style={{ marginTop: 10 }}></Skeleton>
      )}
      <BudgetsDeletionAlertDialog
        isLoading={false}
        message="Deseja realmente excluir o orçamento selecionado?"
        onClose={() => handleBudgetAlertDialog(false)}
        onDelete={() => handleDeleteBudget(budgetToDelete)}
        open={isBudgetAlertDialogOpen}
        title="Excluir orçamento"
      />
      <RegisterBudgetsForm
        open={isFormOpen}
        selectedBudget={budgetToEdit}
        handleClose={handleCloseForm}
      />
    </div>
  );
};

export default Budgets;

const Container = () => (
  <CostCentersProvider>
    <BillingProfilesProvider>
      <NewBudgetsProvider>
        <Budgets />
      </NewBudgetsProvider>
    </BillingProfilesProvider>
  </CostCentersProvider>
);

export { Container as Budgets };
