import React, { useMemo, useState } from "react";

import { Button, Switch } from "@toolkit/v2";

import { useHotelResult } from "../hotel-result.context";
import { RoomCard, RoomCardSkeleton } from "./room-card/room-card";
import { styles } from "./styles";

export const HotelAvailableRooms: React.FC = () => {
  const {
    filters,
    handleToggleBreakfastOnly,
    handleToggleFreeCancelationOnly,
    loading,
    loadingRooms,
  } = useHotelResult();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <h2 css={styles.top.title}>Quartos disponíveis</h2>
        <div css={styles.top.controls.root}>
          <span css={styles.top.controls.label}>
            Mostrar apenas ofertas com:
          </span>
          <label css={styles.top.controls.switch.root}>
            <Switch
              active={filters.freeCancelationOnly}
              disabled={loading || loadingRooms}
              onChange={handleToggleFreeCancelationOnly}
              variant="pink"
            />
            <span css={styles.top.controls.switch.text}>
              Cancelamento grátis
            </span>
          </label>
          <label css={styles.top.controls.switch.root}>
            <Switch
              active={filters.breakfastOnly}
              disabled={loading || loadingRooms}
              onChange={handleToggleBreakfastOnly}
              variant="pink"
            />
            <span css={styles.top.controls.switch.text}>
              Café da manhã incluso
            </span>
          </label>
        </div>
      </div>
      <HotelAvailableRoomsList />
    </div>
  );
};

const HotelAvailableRoomsList: React.FC = () => {
  const {
    handleOpenOutOfPolicyDialog,
    handleOpenRoomAmenitiesDialog,
    handleSelectOffer,
    handleShowRoomPictures,
    hotelInfo,
    loading,
    loadingRooms,
    searchInfo,
    visibleOffers,
  } = useHotelResult();

  const [showMore, setShowMore] = useState(false);

  const actuallyVisibleOffers = useMemo(
    () => (showMore ? visibleOffers : visibleOffers.slice(0, 10)),
    [showMore, visibleOffers],
  );

  const isLoading = useMemo(() => loading || loadingRooms, [
    loading,
    loadingRooms,
  ]);

  return (
    <ul css={styles.list.root}>
      {isLoading ? (
        <HotelAvailableRoomsListSkeleton />
      ) : hotelInfo && searchInfo && visibleOffers.length > 0 ? (
        <>
          {actuallyVisibleOffers.map((offer) => (
            <RoomCard
              freeCancelationUntil={offer.cancelation?.freeUntil}
              handleOpenOutOfPolicyDialog={handleOpenOutOfPolicyDialog(offer)}
              handleOpenRoomAmenitiesDialog={handleOpenRoomAmenitiesDialog}
              handleSelectOffer={handleSelectOffer(offer)}
              handleShowRoomPictures={handleShowRoomPictures}
              hotelInfo={hotelInfo}
              isButtonDisabled={loading}
              key={offer.roomId}
              offer={offer}
              roomAmenities={offer.extraAmenities}
              roomPictures={offer.roomPictures}
              totalGuests={searchInfo.totalGuests}
            />
          ))}
          {!showMore ? (
            <Button
              css={styles.list.button}
              onClick={() => {
                setShowMore(true);
              }}
              variant="pink"
            >
              Ver mais
            </Button>
          ) : null}
        </>
      ) : (
        <span css={styles.list.empty}>Não há quartos para exibir...</span>
      )}
    </ul>
  );
};

const HotelAvailableRoomsListSkeleton: React.FC = () => {
  return (
    <>
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <RoomCardSkeleton key={index} />
        ))}
      <Button css={styles.list.button} disabled variant="pink">
        Ver mais
      </Button>
    </>
  );
};
