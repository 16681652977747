import React from "react";

import { Box, Text } from "@toolkit";

import PageTitle from "@shared/PageTitle";
import Spinner from "@shared/Spinner";

import { HeaderContainer } from "../shared/HeaderContainer";
import { LastUpdatedMessage } from "../shared/lastUpdatedMessage";
import { MobileFilters } from "../shared/MobileFilters";
import { Filters } from "./Filters/Filters";
import { FlightInfoProvider, useFlightInfoContext } from "./FlightInfo.context";
import { FlightInfoContent } from "./FlightInfoContent";

const FlightInfo = () => {
  const {
    loadingData,
    filterState,
    costCenterOptions,
    tagOptions,
    travelerOptions,
    companyAreaOptions,
    projectOptions,
    billingProfileOptions,
    onApplyFilters,
    generatedAt
  } = useFlightInfoContext();

  const title = "Analytics - Economias e Desperdícios";

  return (
    <Box p={[0, 12]}>
      <PageTitle title={title} />
      <Spinner visible={loadingData} />
      <HeaderContainer>
        <Box
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Text fontSize={24} fontWeight="bold">
            Aéreo
          </Text>
          {generatedAt ? <LastUpdatedMessage updatedAt={generatedAt} /> : null}
        </Box>
        <MobileFilters
          filtersData={filterState}
          companyAreas={companyAreaOptions}
          billingProfiles={billingProfileOptions}
          costCenters={costCenterOptions}
          projects={projectOptions}
          tags={tagOptions}
          travelers={travelerOptions}
          onApply={onApplyFilters}
        />
      </HeaderContainer>
      <Filters />
      <FlightInfoContent />
    </Box>
  );
};

const Container = () => (
  <div>
    <FlightInfoProvider>
      <FlightInfo />
    </FlightInfoProvider>
  </div>
);

export { Container as FlightInfo };
