import * as expensesAdvanceApprovalsApi from "@apis/expense-advance-approvals";
import { GetPendingApprovalExpenseAdvancesResult } from "./types";

import { ERROR } from "@constants";

export async function getPendingApprovalExpenseAdvances(status: number) {
  const result: GetPendingApprovalExpenseAdvancesResult = {};

  try {
    result.data = await expensesAdvanceApprovalsApi.getApproverExpenseAdvances(
      status
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVALS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVALS_ERROR;
    }
  }

  return result;
}
