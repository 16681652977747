import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  container: {
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      gap: "12px",
      padding: "13px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
  info: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    lineHeight: "20px",
    margin: "4px 0 0 16px",
  }),
  input: css({
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    color: theme.colors.gray[700],
    margin: "0 4px",
    outline: "none",
    width: "64px",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  text: css({
    color: theme.colors.gray[500],
    fontWeight: 600,
  }),
};
