import React from "react";
import { FocusedInputShape } from "react-dates";

import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import { css } from "@styled-system/css";
import { FormikErrors } from "formik";
import { Moment } from "moment";
import { darken } from "polished";
import { theme } from "smartrips-skin";
import uuid from "uuid";

import { Box, Flex, Text } from "@toolkit";

import { IBookingTarget } from "~/models/booker-target.model";

import { getAirports } from "@apis/autosuggest.api";

import Autocomplete from "@shared/new-trip/Autocomplete";

import { StContainedButton } from "~/components/shared/buttons";
import { Icon } from "~/components/shared/icon";
import {
  IAirportItem,
  MenuAirportItem
} from "~/components/shared/menu-airport-item";
import { SwapButton } from "~/components/shared/new-trip/SwapButton";

import {
  ResponsiveRangeDatePicker,
  ResponsiveSingleDatePicker
} from "../../shared/date-pickers";
import { CabinClassSelect } from "../CabinClassSelect/CabinClassSelect";
import { FlightSearch, FlightSearchPlace } from "../NewTrip.types";
import { useOfferSearch } from "../offer-search";
import { TravelerAutocomplete } from "../TravelerAutocomplete";
import { FlightTypeSelect } from "./FlightTypeSelect";
const v1Styles = {
  locationContainer: css({
    position: "relative"
  }),
  origin: css({
    borderRightWidth: ["1px", "0.5px"],
    borderRadius: [1, "4px 0 0 4px"]
  }),
  destination: css({
    borderLeftWidth: ["1px", "0.5px"],
    borderRadius: [1, "0 4px 4px 0"]
  }),
  swapButton: css({
    position: ["absolute", "relative"],
    right: [16, "initial"],
    top: [45, "initial"],
    zIndex: [1, 0]
  }),
  getDateStyles: (error: boolean) =>
    css({
      height: 58,
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border",
      borderBottomColor: error ? "cancel" : "border",
      borderRadius: 1,
      padding: "medium",
      transition: "all 0.2s",

      ["&:hover"]: {
        borderColor: darken(0.2, theme.colors.border),
        borderBottomColor: error ? "cancel" : darken(0.2, theme.colors.border)
      }
    }),
  button: css({
    py: "small",
    px: "medium",
    lineHeight: "24px"
  }),
  error: css({
    display: "block",
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  })
};

const v2Styles = {
  button: css({
    alignItems: "center",
    aspectRatio: "1",
    backgroundColor: theme.colors.primary,
    border: "none",
    borderRadius: "26px",
    color: theme.colors.white,
    cursor: "pointer",
    display: "flex",
    height: "52px",
    justifyContent: "center",
    transition: "all 0.15s ease-in-out",
    width: "52px",
    ["&:hover"]: {
      backgroundColor: darken(0.2, theme.colors.primary)
    }
  }),
  error: css({
    display: "block",
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  }),
  fields: {
    container: css({
      display: "flex",
      flexDirection: "column",
      height: "52px",
      width: "100%"
    }),
    dates: {
      divisor: css({
        backgroundColor: "border",
        height: "40px",
        margin: "0 16px",
        transform: "translateY(-8px)",
        width: "1px"
      }),
      labels: css({
        display: "flex",
        gap: "32px",
        width: "100%"
      }),
      root: (error: boolean) =>
        css({
          alignItems: "center",
          borderColor: "border",
          borderRadius: "26px",
          display: "flex",
          height: "52px",
          padding: "0 16px",
          transition: "all 0.15s",
          width: "100%",
          ["&:hover"]: {
            borderBottomColor: error
              ? "cancel"
              : darken(0.2, theme.colors.border),
            borderColor: darken(0.2, theme.colors.border)
          }
        })
    },
    divisor: css({
      backgroundColor: "border",
      height: "40px",
      width: "1px"
    }),
    label: ({ error }: { error: boolean }) =>
      css({
        alignItems: "center",
        color: !error ? theme.colors.gray[3] : "cancel",
        display: "flex",
        fontWeight: "600",
        gap: "8px",
        paddingTop: "4px",
        whiteSpace: "nowrap",
        width: "100%"
      }),
    location: {
      field: css({
        border: "none",
        height: "100%",
        padding: "0",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%"
      }),
      root: css({
        alignItems: "center",
        borderRadius: "26px",
        display: "flex",
        gap: "16px",
        padding: "0 16px",
        width: "100%"
      })
    },
    root: css({
      alignItems: "center",
      border: "1px solid",
      borderColor: "border",
      borderRadius: "35px",
      display: "flex",
      padding: "8px",
      width: "100%"
    })
  },
  header: {
    left: {
      label: css({
        transform: "translateY(-2px)"
      }),
      root: ({ error }: { error: boolean }) =>
        css({
          alignItems: "center",
          color: error ? "cancel" : "text",
          display: "flex",
          gap: "8px"
        })
    },
    root: css({
      alignItems: "flex-end",
      display: "flex",
      gap: "16px",
      padding: "16px 0"
    })
  },
  root: css({
    display: "flex",
    flexDirection: "column"
  })
};

type Props = {
  destinationInputRef: React.MutableRefObject<HTMLInputElement | null>;
  errors: FormikErrors<FlightSearch>;
  handleCabinChange: (e: any) => void;
  handleChange: (e: any) => void;
  handleChangeRangeDatePickerFocues: (
    focused: FocusedInputShape | null
  ) => void;
  handleChangeSingleDatePickerFocus: ({
    focused
  }: {
    focused: boolean;
  }) => void;
  handleChangeTraveler: (traveler: IBookingTarget) => void;
  handleLocationChange: (
    type: "origin" | "destination"
  ) => (place: FlightSearchPlace) => void;
  handleRangeDateChange: ({
    startDate,
    endDate
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  handleSingleDateChange: (date: Moment | null) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleSwapLocations(): void;
  handleTabChange: (_: any, tab: any) => void;
  originInputRef: React.MutableRefObject<HTMLInputElement | null>;
  rangeDatePickerFocus: FocusedInputShape | null;
  singleDatePickerFocus: boolean;
  values: FlightSearch;
  version?: "v1" | "v2";
};

const FlightForm = ({
  destinationInputRef,
  errors,
  handleCabinChange,
  handleChangeRangeDatePickerFocues,
  handleChangeSingleDatePickerFocus,
  handleChangeTraveler,
  handleLocationChange,
  handleRangeDateChange,
  handleSingleDateChange,
  handleSubmit,
  handleSwapLocations,
  handleTabChange,
  originInputRef,
  rangeDatePickerFocus,
  singleDatePickerFocus,
  values,
  version = "v1"
}: Props) => {
  const { getBookingTargets } = useOfferSearch();

  if (version === "v2") {
    return (
      <form autoComplete="off" css={v2Styles.root} onSubmit={handleSubmit}>
        <div css={v2Styles.header.root}>
          <div css={v2Styles.header.left.root({ error: !!errors.traveler })}>
            <span css={v2Styles.header.left.label}>Viajante:</span>
            <TravelerAutocomplete
              customGetBookingTargets={getBookingTargets}
              onChange={handleChangeTraveler}
              traveler={values.traveler}
              version={version}
            />
          </div>
          <FlightTypeSelect
            onChange={handleTabChange}
            value={values.type}
            version="v2"
          />
          <CabinClassSelect
            handleCabinChange={handleCabinChange}
            value={values.cabinClass}
            version={version}
          />
        </div>
        <div css={v2Styles.fields.root}>
          <div css={v2Styles.fields.location.root}>
            <div css={v2Styles.fields.container}>
              <div css={v2Styles.fields.label({ error: !!errors.origin })}>
                <Icon
                  css={{
                    opacity: "50%"
                  }}
                  size={20}
                  use="airplane-taking-off"
                />
                <Text fontSize="0.875rem">Origem</Text>
              </div>
              <Autocomplete
                focusInputOnSuggestionClick={false}
                inputProps={{
                  css: v2Styles.fields.location.field,
                  id: "origin",
                  inputRef: originInputRef,
                  placeholder: "Digite o local de partida",
                  version
                }}
                ResultItemComponent={(item: IAirportItem) => (
                  <MenuAirportItem item={item} />
                )}
                search={getAirports}
                updateParent={handleLocationChange("origin")}
                value={values.origin ? values.origin.label : ""}
              />
            </div>
            <SwapButton onClick={handleSwapLocations} version="v2" />
            <div css={v2Styles.fields.container}>
              <div css={v2Styles.fields.label({ error: !!errors.origin })}>
                <Icon
                  css={css({
                    opacity: "50%"
                  })}
                  size={20}
                  use="airplane-landing"
                />
                <Text fontSize="0.875rem">Destino</Text>
              </div>
              <Autocomplete
                focusInputOnSuggestionClick={false}
                inputProps={{
                  css: v2Styles.fields.location.field,
                  id: "destination",
                  inputRef: destinationInputRef,
                  placeholder: "Digite o local de destino",
                  version
                }}
                ResultItemComponent={(item: IAirportItem) => (
                  <MenuAirportItem item={item} />
                )}
                search={getAirports}
                updateParent={handleLocationChange("destination")}
                value={values.destination ? values.destination.label : ""}
              />
            </div>
          </div>
          <span css={v2Styles.fields.divisor} />
          <div
            css={v2Styles.fields.dates.root(
              !!errors.endDate || !!errors.startDate
            )}
            id="new-trip-picker"
          >
            {values.type === "roundtrip" && (
              <div css={v2Styles.fields.container}>
                <div css={v2Styles.fields.dates.labels}>
                  <div
                    css={v2Styles.fields.label({ error: !!errors.startDate })}
                  >
                    <Icon
                      css={{
                        opacity: "50%"
                      }}
                      size={16}
                      use="calendar"
                    />
                    <Text fontSize="0.875rem">Data do Embarque</Text>
                  </div>
                  <div
                    css={v2Styles.fields.label({ error: !!errors.startDate })}
                  >
                    <Icon
                      css={{
                        opacity: "50%"
                      }}
                      size={16}
                      use="calendar"
                    />
                    <Text fontSize="0.875rem">Data do Retorno</Text>
                  </div>
                </div>
                <ResponsiveRangeDatePicker
                  datePickerProps={{
                    anchorDirection: "left",
                    customArrowIcon: (
                      <div css={v2Styles.fields.dates.divisor} />
                    ),
                    customInputIcon: <></>,
                    disableScroll: false,
                    endDate: values.endDate,
                    endDateId: uuid(),
                    endDatePlaceholderText: "Selecione uma data",
                    focusedInput: rangeDatePickerFocus,
                    hideKeyboardShortcutsPanel: true,
                    minimumNights: 0,
                    noBorder: true,
                    onDatesChange: handleRangeDateChange,
                    onFocusChange: handleChangeRangeDatePickerFocues,
                    startDate: values.startDate,
                    startDateId: uuid(),
                    startDatePlaceholderText: "Selecione uma data"
                  }}
                />
              </div>
            )}
            {values.type === "oneway" && (
              <div css={v2Styles.fields.container}>
                <div css={v2Styles.fields.label({ error: !!errors.endDate })}>
                  <Icon
                    css={{
                      opacity: "50%"
                    }}
                    size={20}
                    use="airplane-taking-off"
                  />
                  <Text fontSize="0.875rem">Data do Embarque</Text>
                </div>
                <ResponsiveSingleDatePicker
                  datePickerProps={{
                    anchorDirection: "left",
                    customInputIcon: <></>,
                    date: values.startDate,
                    disabled: false,
                    focused: singleDatePickerFocus,
                    hideKeyboardShortcutsPanel: true,
                    id: uuid(),
                    noBorder: true,
                    onDateChange: handleSingleDateChange,
                    onFocusChange: handleChangeSingleDatePickerFocus as any,
                    placeholder: "Selecione uma data"
                  }}
                />
              </div>
            )}
          </div>
          <button css={v2Styles.button} type="submit">
            <Icon use="search-outline" />
          </button>
        </div>
      </form>
    );
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box p={["20px 8px 16px", "large"]}>
        <FlightTypeSelect onChange={handleTabChange} value={values.type} />
        <Flex
          css={v1Styles.locationContainer}
          flexDirection={["column", "row"]}
          mb="small"
        >
          <Autocomplete
            focusInputOnSuggestionClick={false}
            icon={FlightTakeoffIcon}
            inputProps={{
              css: v1Styles.origin,
              error: errors.origin,
              id: "origin",
              inputRef: originInputRef,
              placeholder: "Origem"
            }}
            ResultItemComponent={(item: IAirportItem) => (
              <MenuAirportItem item={item} />
            )}
            search={getAirports}
            updateParent={handleLocationChange("origin")}
            value={values.origin ? values.origin.label : ""}
          />
          <SwapButton css={v1Styles.swapButton} onClick={handleSwapLocations} />
          <Box display={["block", "none"]} height="8px" />
          <Autocomplete
            focusInputOnSuggestionClick={false}
            icon={FlightLandIcon}
            inputProps={{
              id: "destination",
              placeholder: "Destino",
              inputRef: destinationInputRef,
              error: errors.destination,
              css: v1Styles.destination
            }}
            ResultItemComponent={(item: IAirportItem) => (
              <MenuAirportItem item={item} />
            )}
            search={getAirports}
            updateParent={handleLocationChange("destination")}
            value={values.destination ? values.destination.label : ""}
          />
        </Flex>
        <Flex flexDirection={["column", "row"]}>
          <Box maxWidth={["100%", "50%"]} mb={["small", 0]} width="100%">
            <Flex
              alignItems="center"
              css={v1Styles.getDateStyles(
                !!errors.startDate || !!errors.endDate
              )}
              id="new-trip-picker"
            >
              {values.type === "roundtrip" && (
                <ResponsiveRangeDatePicker
                  datePickerProps={{
                    anchorDirection: "left",
                    customArrowIcon: (
                      <div css={v2Styles.fields.dates.divisor} />
                    ),
                    disableScroll: false,
                    endDate: values.endDate,
                    endDateId: uuid(),
                    endDatePlaceholderText: "Volta",
                    focusedInput: rangeDatePickerFocus,
                    hideKeyboardShortcutsPanel: true,
                    minimumNights: 0,
                    noBorder: true,
                    onDatesChange: handleRangeDateChange,
                    onFocusChange: handleChangeRangeDatePickerFocues,
                    startDate: values.startDate,
                    startDateId: uuid(),
                    startDatePlaceholderText: "Ida"
                  }}
                />
              )}
              {values.type === "oneway" && (
                <ResponsiveSingleDatePicker
                  datePickerProps={{
                    anchorDirection: "left",
                    date: values.startDate,
                    disabled: false,
                    focused: singleDatePickerFocus,
                    hideKeyboardShortcutsPanel: true,
                    id: uuid(),
                    noBorder: true,
                    onDateChange: handleSingleDateChange,
                    onFocusChange: handleChangeSingleDatePickerFocus as any,
                    placeholder: "Partida"
                  }}
                />
              )}
            </Flex>
            {errors.startDate ? (
              <span css={v1Styles.error}>{errors.startDate}</span>
            ) : null}
            {errors.endDate && values.type === "roundtrip" ? (
              <span css={v1Styles.error}>{errors.endDate}</span>
            ) : null}
          </Box>
          <Box width="8px" />
          <CabinClassSelect
            handleCabinChange={handleCabinChange}
            value={values.cabinClass}
          />
        </Flex>
      </Box>
      <Box
        borderRadius="0 0 5px 5px"
        borderTop="1px solid"
        borderTopColor="border"
        p={["24px 8px 16px", "large"]}
      >
        <Flex
          alignItems="center"
          flexDirection={["column", "row"]}
          justifyContent="space-between"
        >
          <Box maxWidth={["100%", 300]} mb={["medium", 0]} width="100%">
            <TravelerAutocomplete
              customGetBookingTargets={getBookingTargets}
              error={errors.traveler}
              onChange={handleChangeTraveler}
              traveler={values.traveler}
            />
          </Box>
          <Box maxWidth={["100%", 141.39]} width="100%">
            <StContainedButton css={v1Styles.button} size="small" type="submit">
              Pesquisar voos
            </StContainedButton>
          </Box>
        </Flex>
      </Box>
    </form>
  );
};

export { FlightForm };
