import { useItineraryInfo } from "../../itinerary-info.context";

export const useItineraryInfoGuard = () => {
  const {
    errorOnFetch: errorOnFetchItineraryInfo,
    isLoading: isLoadingItineraryInfo,
  } = useItineraryInfo();

  return {
    error: errorOnFetchItineraryInfo,
    loading: isLoadingItineraryInfo,
  };
};
