import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesPresenter } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { ItineraryPriceBreakdownPresenterFactory } from "../../../../itinerary-price-breakdown/itinerary-price-breakdown.presenter";
import { styles } from "./styles";

type Props = {
  servicesPresenter: ItineraryServicesPresenter;
};

export const TravelPlanPDFPriceBreakdown: React.FC<Props> = ({
  servicesPresenter,
}) => {
  const rootStyles = styles.root();

  const itineraryPriceBreakdownPresenter = ItineraryPriceBreakdownPresenterFactory.create(
    servicesPresenter,
  );

  return (
    <View style={rootStyles.root}>
      {itineraryPriceBreakdownPresenter.hasProcessableServices() ? (
        <View style={rootStyles.container}>
          <View style={rootStyles.title}>
            <Text>
              {itineraryPriceBreakdownPresenter.hasReservedServices()
                ? "Novos "
                : null}
              Serviços:
            </Text>
          </View>
          <View style={rootStyles.services}>
            <TravelPlanPDFPriceBreakdownServices
              servicesPresenters={itineraryPriceBreakdownPresenter.getProcessableServices()}
            />
          </View>
        </View>
      ) : null}
      {itineraryPriceBreakdownPresenter.hasReservedServices() ? (
        <View style={rootStyles.container}>
          <View style={rootStyles.title}>
            <Text>Serviços pagos:</Text>
          </View>
          <View style={rootStyles.services}>
            <TravelPlanPDFPriceBreakdownServices
              servicesPresenters={itineraryPriceBreakdownPresenter.getReservedServices()}
            />
          </View>
        </View>
      ) : null}
      {itineraryPriceBreakdownPresenter.hasReservedServices() ? (
        <View style={rootStyles.total}>
          <View>
            <Text>Valor total reservado:</Text>
          </View>
          <View>
            <Text>
              {itineraryPriceBreakdownPresenter.getFormattedReservedServicesTotalPrice()}
            </Text>
          </View>
        </View>
      ) : null}
      {itineraryPriceBreakdownPresenter.hasProcessableServices() ? (
        <View style={rootStyles.total}>
          <View>
            <Text>Valor a pagar:</Text>
          </View>
          <View>
            <Text>
              {itineraryPriceBreakdownPresenter.getFormattedProcessableServicesTotalPrice()}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

type TravelPlanPDFPriceBreakdownServicesProps = {
  servicesPresenters: ItineraryServicePresenter[];
};

const TravelPlanPDFPriceBreakdownServices: React.FC<TravelPlanPDFPriceBreakdownServicesProps> = ({
  servicesPresenters,
}) => {
  const serviceStyles = styles.service();

  return (
    <>
      {servicesPresenters.map((servicePresenter) => (
        <View style={serviceStyles.root} key={servicePresenter.getOfferToken()}>
          <View style={serviceStyles.name}>
            <Text>
              {servicePresenter.getFormattedItineraryServiceHeaderName()}
            </Text>
          </View>
          <View style={serviceStyles.price}>
            <Text>{formatCurrency(servicePresenter.getPrice())}</Text>
          </View>
        </View>
      ))}
    </>
  );
};
