import React from "react";
import Select, {
  Props,
  StylesConfig,
  OptionProps,
  components,
} from "react-select";

import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

import { defaultTheme } from "~/assets/styles/theme";

const customIconStyles = { fontSize: "1.125rem", color: "#003097" };

const IconOption = (props: OptionProps) => (
  <components.Option {...props}>
    {props.label}
    {props.isSelected && <CheckRoundedIcon style={customIconStyles} />}
  </components.Option>
);

const customStyles: StylesConfig = {
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "0",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "1.5rem",
    border: "0",
    borderRadius: "0",
    borderBottom: `1px solid ${
      state.selectProps.menuIsOpen ? defaultTheme.primaryColor : `#c9d3df`
    }`,
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      borderBottomColor: defaultTheme.primaryColor,
    },
    fontSize: "1rem",
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.selectProps.menuIsOpen ? "#003097" : "black",
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: state.selectProps.menuIsOpen ? "#003097" : "#989ba1",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 11,
    width: "auto",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    marginTop: "0",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "14rem",
    height: "2.5rem",
    paddging: "0.5rem",
    fontSize: "0.875rem",
    color: state.isSelected ? "#003097" : "#4c5566",
    borderRadius: "0.25rem",
    backgroundColor: state.isSelected ? "#f0f4ff" : "transparent",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isSelected ? "#f0f4ff" : "#f9fafc",
    },
  }),
};

export const ApprovalProcessesFormSelect = ({ ...rest }: Props) => {
  return (
    <Select
      aria-label="Selecione a opção"
      isSearchable={false}
      isClearable={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: IconOption,
      }}
      styles={customStyles}
      {...rest}
    />
  );
};
