import { ConfigOptions } from "sm-types/common";

import {
  TravelIntegrationOption,
  ClientBookingPhoneConfig,
  ClientApprovalOption
} from "~/constants/enums";

import { ApprovalConfig } from "@models/client.model";

export type UpdateClientRequest = {
  name?: string;
  client_slug?: string;
  email?: string;
  cost_center_active?: boolean;
  budgets_active?: boolean;
  vault_active?: boolean;
};

export type GetClientConfigResponseBody = {
  client_config_token: string;
  budgets_active: boolean;
  company_area_display: string;
  company_board_display: string;
  company_area_enabled: boolean;
  approval_option: ClientApprovalOption;
  approval_config: ApprovalConfig;
  cost_center_active: boolean;
  project_enabling_state: "off" | "optional" | "required";
  tags_admin_only: boolean;
  travel_tags_required: boolean;
  booking_phone_config: ClientBookingPhoneConfig;
  vault_active: boolean;
  bank3_enabled: boolean;
  custom_corporative_card_name: string;
  approval_ranking: string;
  travel_integration: TravelIntegrationOption;
  support_channel: string;
  flight_visible: boolean;
  hotel_visible: boolean;
  car_visible: boolean;
  bus_visible: boolean;
  custom_message?: string;
  config: ConfigOptions;
};

export type Options = {
  name: string;
  option_token: string;
};

export enum TravelCustomField {
  TRIP_PURPOSE = "TRIP_PURPOSE",
  HOTEL_PURPOSE = "HOTEL_PURPOSE",
  FLIGHT_PURPOSE = "FLIGHT_PURPOSE"
}

export enum FieldVisibility {
  NONE = "NONE",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED"
}

export type ICustomFieldOptions = ({
  label: string;
  options: Options;
  travel_custom_field: TravelCustomField;
  type: string;
  visibility: FieldVisibility;
} | null)[];

export type CustomFieldOptionsResponse = ICustomFieldOptions[];

export interface IGetClientExternalCredentialsResponse {
  clientId: string;
  clientSecret: string;
}

export type IClientExternalCredentialsResponse = {
  data: {
    success: boolean;
  };
  status: string;
};
