import hotelApi from "~/apps/corporate/apis/hotel.api";
import * as reviewsApi from "~/apps/corporate/apis/reviews.api";
import {
  HospitalityHotelDetails,
  HotelDetailsResponse,
  HotelRoomsListResponse,
  SetFavouriteResponse,
} from "~/apps/corporate/dtos/hotel.dto";
import { HotelReviewListItem } from "~/apps/corporate/models/hotel-review.model";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

type HotelPolicyStatus = {
  price?: number;
  stars?: number;
  advance?: number;
  outOfPolicy?: boolean;
};
export async function loadHotelRooms(
  hospitalityHotelToken: string,
  searchHotelToken: string,
): Promise<{ data?: HotelRoomsListResponse; error?: Error }> {
  const result: { data?: HotelRoomsListResponse; error?: Error } = {};

  try {
    result.data = await hotelApi.loadRooms(
      searchHotelToken,
      hospitalityHotelToken,
    );
  } catch (error) {
    result.error = ERROR.UNEXPECTED_LOAD_HOTEL_ROOMS_ERROR;
  }

  return result;
}

export async function loadHotelDetails(
  hotelToken: string,
  hospitalityHotelToken: string,
): Promise<{ data?: HotelDetailsResponse; error?: Error }> {
  const result: { data?: HotelDetailsResponse; error?: Error } = {};

  try {
    result.data = await hotelApi.loadHotelDetails(
      hotelToken,
      hospitalityHotelToken,
    );
  } catch (error) {
    if (error.response) {
      const type: string = error.response.data?.type;
      result.error =
        (ERROR[type as keyof typeof ERROR] as any) ||
        ERROR.UNEXPECTED_LOAD_HOTEL_DETAILS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_HOTEL_DETAILS_ERROR;
    }
  }

  return result;
}

export async function getReceptionHours(
  hospitalityHotelToken: string,
): Promise<{
  data?: { checkInHour: string; checkOutHour: string };
  error?: Error;
}> {
  const result: {
    data?: { checkInHour: string; checkOutHour: string };
    error?: Error;
  } = {};

  try {
    result.data = await hotelApi.getReceptionHours(hospitalityHotelToken);
  } catch (error) {
    if (error.response) {
      const type: string = error.response.data?.type;
      result.error =
        (ERROR[type as keyof typeof ERROR] as any) ||
        ERROR.UNEXPECTED_LOAD_HOTEL_RECEPTION_HOURS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_HOTEL_RECEPTION_HOURS_ERROR;
    }
  }

  return result;
}

export async function addHotelOffer(
  selectedOfferId: string,
  smSearchHotelToken: string,
  supplierSearchToken: string,
  travelToken: string,
  hotelPolicyStatus: HotelPolicyStatus,
): Promise<{ data?: any; error?: Error }> {
  const result: { data?: any; error?: Error } = {};

  try {
    const requestData = {
      // todo: remove searchHotelToken
      searchHotelToken: smSearchHotelToken,
      smartripsSearchToken: smSearchHotelToken,
      hospitalitySearchToken: supplierSearchToken,
      // todo: remove offerId
      offerId: selectedOfferId,
      hospitalityOfferId: selectedOfferId,
      hotelPolicyStatus,
    };

    result.data = await hotelApi.addHotelOffer(requestData, travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_HOTEL_OFFER_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_HOTEL_OFFER_ERROR;
    }
  }

  return result;
}

export async function setFavorite(
  hotel: HospitalityHotelDetails,
  targetType: string,
  targetToken: string,
): Promise<{ data?: SetFavouriteResponse; error?: Error }> {
  const result: { data?: SetFavouriteResponse; error?: Error } = {};

  try {
    const requestData = {
      hotel_identification: hotel.hotelToken,
      hotel_name: hotel.name,
      target_token: targetToken,
      target_type: targetType,
    };

    result.data = await hotelApi.setHotelFavorite(requestData);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR;
    }
  }

  return result;
}

export async function removeFromFavorites(
  favouriteToken: string,
): Promise<{ data?: any; error?: Error }> {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await hotelApi.removeHotelFavorite(favouriteToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR;
    }
  }

  return result;
}

export async function getHotelNearbyPlaces(
  hotelLatitude: number,
  hotelLongitude: number,
): Promise<{ data?: any; error?: Error }> {
  const result: { data?: any; error?: Error } = {};

  try {
    const requestData = {
      latitude: hotelLatitude,
      longitude: hotelLongitude,
      radius: 10000,
      types: ["restaurant", "tourist_attraction", "airport"],
    };

    result.data = await hotelApi.getNearbyPlaces(requestData);
  } catch (error) {
    result.error = ERROR.GENERAL_CONNECTION_ERROR;
  }

  return result;
}

export async function getHotelReviews(hotelId: string | number) {
  const result: { data?: HotelReviewListItem[]; error?: Error } = {};

  try {
    result.data = await reviewsApi.listHotelReviews(hotelId);
  } catch (error) {
    result.data = [];
  }

  return result;
}
