import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    amenities: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    info: css({
      alignItems: "center",
      color: theme.colors.gray[700],
      display: "flex",
      fontSize: "0.875rem",
      gap: "8px",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: "none",
    height: "1px",
    margin: "0 auto",
    width: "100%",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 640px)": {
      padding: "24px 0 0 0",
    },
  }),
  top: {
    description: css({
      fontSize: "0.875rem",
    }),
    name: css({
      fontWeight: 700,
    }),
    root: css({
      display: "flex",
      borderRadius: "6px",
      flexDirection: "column",
      gap: "4px",
    }),
  },
};
