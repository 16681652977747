import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    card: {
      button: css({
        fontSize: "0.875rem",
      }),
      dates: {
        date: css({
          color: theme.colors.gray[700],
          textTransform: "capitalize",
        }),
        root: css({
          display: "flex",
          gap: "8px",
        }),
      },
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        fontSize: "0.875rem",
        justifyContent: "space-between",
        padding: "12px 12px 12px 24px",
        transition: "all 0.15s ease-in-out",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginLeft: "24px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  top: {
    description: css({
      color: theme.colors.gray[500],
      flex: 1,
      fontWeight: 600,
    }),
    icon: css({
      backgroundColor: theme.colors.white,
      padding: "4px 0",
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
  },
};
