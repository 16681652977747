import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

const getHandleColors = ({
  disabled,
  variant,
}: {
  disabled: boolean;
  variant: "blue" | "default" | "green" | "pink";
}) => {
  switch (variant) {
    default:
      return {
        borderColor: !disabled
          ? theme.colors.gray[700]
          : theme.colors.gray[100],
      };
  }
};

const getRailColors = ({
  variant,
}: {
  variant: "blue" | "default" | "green" | "pink";
}) => {
  switch (variant) {
    default:
      return {
        backgroundColor: theme.colors.gray[100],
      };
  }
};

const getTrackColors = ({
  disabled,
  variant,
}: {
  disabled: boolean;
  variant: "blue" | "default" | "green" | "pink";
}) => {
  switch (variant) {
    default:
      return {
        backgroundColor: !disabled
          ? theme.colors.gray[300]
          : theme.colors.gray[100],
      };
  }
};

export const styles = {
  label: css({
    color: theme.colors.gray[300],
    fontSize: "0.75rem",
  }),
  range: css({
    padding: "0 8px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  slider: ({
    disabled,
    isMobile,
    variant,
  }: {
    disabled: boolean;
    isMobile: boolean;
    variant: "blue" | "default" | "green" | "pink";
  }) => ({
    handleStyle: {
      border: "solid 3px",
      boxShadow: "none",
      height: isMobile ? 20 : 14,
      marginTop: isMobile ? -8 : -5,
      width: isMobile ? 20 : 14,
      ...getHandleColors({ disabled, variant }),
    },
    railStyle: {
      ...getRailColors({ variant }),
    },
    trackStyle: [{ ...getTrackColors({ disabled, variant }) }],
  }),
  values: css({
    alignItems: "center",
    color: theme.colors.gray[300],
    display: "flex",
    fontSize: "0.75rem",
    justifyContent: "space-between",
  }),
};
