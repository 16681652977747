import React from "react";

import { Form } from "~/apps/shared/components/form/form";
import { UseFormMethods } from "~/apps/shared/components/form/hooks/use-form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";
import { ThreeWayDateSelect } from "~/apps/shared/components/three-way-date-select/three-way-date-select";
import { DOCUMENT_TYPES_TRANSLATION, SEXES } from "~/apps/shared/constants";
import { DocumentType } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

type Props = {
  documentPending: {
    type: DocumentType;
  };
  errors?: UseFormMethods["errors"];
  form: UseFormMethods;
  namePrefix: string;
};

export const DocumentationAccordionRGForm: React.FC<Props> = ({
  documentPending,
  errors,
  form,
  namePrefix,
}) => {
  const translatedDocumentType =
    DOCUMENT_TYPES_TRANSLATION[documentPending.type];

  return (
    <>
      <Form.Field
        name={`${namePrefix}.documentNumber`}
        render={({ onChange, value }) => (
          <div css={styles.form.input.root}>
            <span css={styles.form.input.label}>{translatedDocumentType}</span>
            <InputMasked
              css={styles.form.input.input}
              maskType="rg"
              onChange={onChange}
              placeholder={`Digite o número do documento...`}
              value={value}
            />
            <InputErrorMessage>
              {errors?.["documentNumber"]?.message}
            </InputErrorMessage>
          </div>
        )}
      />
      <div css={styles.row}>
        <Form.Field
          name={`${namePrefix}.firstName`}
          render={({ onChange, value }) => (
            <div css={styles.form.input.root}>
              <span css={styles.form.input.label}>Nome do viajante</span>
              <Input
                css={styles.form.input.input}
                onChange={onChange}
                placeholder="Digite o nome do viajante..."
                value={value}
              />
              {errors?.["firstName"] ? (
                <InputErrorMessage>
                  {errors["firstName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.form.input.info}>
                  Exatamente igual ao documento do viajante.
                </span>
              )}
            </div>
          )}
        />
        <Form.Field
          name={`${namePrefix}.lastName`}
          render={({ onChange, value }) => (
            <div css={styles.form.input.root}>
              <span css={styles.form.input.label}>Sobrenome do viajante</span>
              <Input
                css={styles.form.input.input}
                onChange={onChange}
                placeholder="Digite o sobrenome do viajante..."
                value={value}
              />
              {errors?.["lastName"] ? (
                <InputErrorMessage>
                  {errors["lastName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.form.input.info}>
                  Exatamente igual ao documento do viajante.
                </span>
              )}
            </div>
          )}
        />
      </div>
      <div css={styles.form.select.root}>
        <span css={styles.form.select.label}>Sexo</span>
        <Select
          id="sex"
          name={`${namePrefix}.sex`}
          onChange={({ value }) => {
            form.setValue(`${namePrefix}.sex`, value);
          }}
          options={SEXES}
          placeholder="Escolha um sexo..."
          value={SEXES.find(
            (sex) => sex.value === form.watch(`${namePrefix}.sex`),
          )}
        />
        <InputErrorMessage>{errors?.["sex"]?.message}</InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <ThreeWayDateSelect
          label="Data de nascimento"
          onChange={(value) =>
            form.setValue(`${namePrefix}.birthDate`, value.format("YYYY-MM-DD"))
          }
          type="birthDate"
        />
        <InputErrorMessage>{errors?.["birthDate"]?.message}</InputErrorMessage>
      </div>
    </>
  );
};
