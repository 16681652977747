import { useItineraryInfo } from "../../itinerary-info.context";
import { useItineraryServices } from "../../itinerary-services.context";

export const useItineraryHeaderShareTravelButtonGuard = () => {
  const {
    errorOnFetch: errorOnFetchItineraryInfo,
    isLoading: isLoadingItineraryInfo,
  } = useItineraryInfo();
  const {
    errorOnFetch: errorOnFetchItineraryServices,
    errorOnFetchTravelPoliciesPerOffer: errorOnFetchTravelPoliciesPerOffer,
    isLoading: isLoadingItineraryServices,
    isLoadingTravelPoliciesPerOffer: isLoadingTravelPoliciesPerOffer,
  } = useItineraryServices();

  return {
    error:
      errorOnFetchItineraryInfo ||
      errorOnFetchItineraryServices ||
      errorOnFetchTravelPoliciesPerOffer,
    loading:
      isLoadingItineraryInfo ||
      isLoadingItineraryServices ||
      isLoadingTravelPoliciesPerOffer,
  };
};
