import React, { useCallback } from "react";

import { Credit } from "~/apps/corporate/models/credit.model";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { capitalizeAllFirstLetters } from "~/apps/shared/utils/capitalize-all-first-letters";
import { toCurrency } from "~/apps/shared/utils/to-currency";
import moment from "moment";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof Dialog> & {
  credits: Credit[];
};

export const CreditsCreditDetailsDialog: React.FC<Props> = ({
  credits,
  onClose,
  open,
}) => {
  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown" | "none") => {
      if (!onClose) {
        return;
      }

      if (reason === "none") {
        onClose(event, "backdropClick");

        return;
      }

      onClose(event, reason);
    },
    [onClose],
  );

  return (
    <Dialog css={styles.root} onClose={handleClose} open={open}>
      <DialogHeader
        icon="dollar-coin"
        onClose={() => {
          handleClose({}, "none");
        }}
      />
      <DialogContent>
        <DialogTitle>Seus créditos.</DialogTitle>
        <span css={styles.message}>Veja os detalhes dos seus créditos:</span>
        <div css={styles.root}>
          <table>
            <thead>
              <tr>
                <th css={styles.table.th}>ID</th>
                <th css={styles.table.th}>Companhia aérea</th>
                <th css={styles.table.th}>Valor</th>
                <th css={styles.table.th}>A partir de</th>
                <th css={styles.table.th}>Expira em</th>
              </tr>
            </thead>
            <tbody>
              {credits.map((credit) => (
                <tr key={credit.providerCreditToken}>
                  <td css={styles.table.td}>{credit.operationalId}</td>
                  <td css={styles.table.td}>
                    {capitalizeAllFirstLetters(credit.provider)}
                  </td>
                  <td css={styles.table.td}>{toCurrency(credit.value)}</td>
                  <td css={styles.table.td}>
                    {moment(credit.initialUsageDate).format("DD/MM/YYYY")}
                  </td>
                  <td css={styles.table.td}>
                    {moment(credit.expirationDate).format("DD/MM/YYYY")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
};
