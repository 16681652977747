import { StylesConfig } from "react-windowed-select";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { getColors } from "../../../../shared/components/select/styles";

export const styles = {
  bottom: {
    button: css({
      width: "fit-content",
    }),
    root: css({
      width: "fit-content",
    }),
  },
  center: {
    control: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      width: "100%",
    }),
    dates: {
      divisor: css({
        height: "100%",
      }),
      root: css({
        display: "flex",
        gap: "16px",
        width: "100%",
        "@media (max-width: 640px)": {
          flexDirection: "column",
        },
      }),
    },
    input: {
      input: css({
        padding: "0",
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        padding: "12px 16px",
        display: "flex",
        gap: "12px",
        position: "relative",
        width: "100%",
      }),
    },
    label: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "28px",
    }),
    locations: css({
      display: "flex",
      gap: "16px",
      width: "100%",
      "@media (max-width: 640px)": {
        flexDirection: "column",
      },
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
    same: {
      label: css({
        color: theme.colors.gray[700],
        cursor: "pointer",
        fontWeight: 600,
        marginLeft: "8px",
      }),
      radio: css({
        alignItems: "center",
        display: "flex",
        width: "fit-content",
        "& > :nth-child(2)": {
          cursor: "pointer",
        },
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "16px",
      }),
    },
    select: ({
      error,
      fill,
      variant,
    }: {
      error?: boolean;
      fill: "filled" | "outlined";
      variant: "blue" | "default" | "green" | "pink";
    }) => {
      return <StylesConfig>{
        control: (styles, state) => ({
          ...styles,
          alignItems: "center",
          border: "none",
          boxShadow: "none",
          display: "flex",
          minHeight: "unset",
          flexWrap: "nowrap",
          gap: "8px",
          transition: "all 0.15s ease-in-out",
          ["&:not(:disabled)"]: {
            cursor: "pointer",
          },
          ...getColors({ error, fill, variant }).control({ state }),
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          padding: "0",
          ...getColors({ error, fill, variant }).dropdownIndicator,
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        input: (styles) => ({
          ...styles,
          color: theme.colors.gray[500],
          fontFamily: theme.fonts.inter,
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }),
        menuList: (styles) => ({
          ...styles,
          padding: 0,
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 1301,
        }),
        option: (styles, state) => ({
          ...styles,
          fontFamily: theme.fonts.inter,
          fontSize: "0.875rem",
          padding: "12px 16px",
          ["&:disabled"]: {
            opacity: 0.5,
            pointerEvents: "none",
          },
          ["&:first-of-type"]: {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
          ["&:last-of-type"]: {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          ["&:not(:disabled)"]: {
            cursor: "pointer",
          },
          ...getColors({ error, fill, variant }).option({ state }),
        }),
        placeholder: (styles) => ({
          ...styles,
          fontFamily: theme.fonts.inter,
          ...getColors({ error, fill, variant }).placeholder,
        }),
        singleValue: (styles) => ({
          ...styles,
          fontFamily: theme.fonts.inter,
          whiteSpace: "nowrap",
          ...getColors({ error, fill, variant }).singleValue,
        }),
        valueContainer: (styles) => ({
          display: "flex",
          height: "fit-content",
          width: "fit-content",
        }),
      };
    },
  },
  location: {
    input: {
      input: css({
        border: "none",
        color: theme.colors.gray[700],
        fontFamily: theme.fonts.inter,
        outline: "none",
        textOverflow: "ellipsis",
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "12px",
        padding: "12px 16px",
        width: "100%",
      }),
    },
    suggestion: {
      button: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[500],
          cursor: "pointer",
          overflow: "hidden",
          padding: "12px 16px",
          transition: "all 0.15s ease-in-out",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          ":not(:disabled)": {
            ":hover": {
              backgroundColor: theme.colors.background.gray,
              color: theme.colors.gray[700],
            },
          },
        }),
      item: css({
        color: theme.colors.gray[500],
        display: "flex",
        fontSize: "0.875rem",
        padding: "12px 16px",
      }),
    },
  },
  picker: {
    picker: css({
      overflow: "hidden",
      ".Calendar": {},
      ".Date": {
        "&RangePicker": {
          width: "100%",
          "&_picker": {
            background: "transparent",
            top: "calc(100% + 16px) !important",
          },
          "&Input": {
            alignItems: "center",
            backgroundColor: "transparent",
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: "8px",
            display: "flex",
            gap: "12px",
            padding: "12px 16px",
            width: "100%",
            "&_calendarIcon": {
              margin: 0,
              padding: 0,
            },
          },
        },
        "&Input": {
          background: "unset",
          width: "100%",
          "&_input": {
            backgroundColor: "transparent",
            borderBottom: "none !important",
            color: theme.colors.gray[700],
            fontFamily: theme.fonts.inter,
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "20px",
            padding: "unset",
            width: "100%",
          },
        },
      },
      ".DayPicker": {
        borderRadius: "8px",
        overflow: "hidden",
        top: "4px",
        "&Navigation": {
          display: "flex",
          justifyContent: "space-between",
          margin: "0 8px",
          top: "4px",
          "& > :nth-of-type(1)": {
            transform: "rotate(0deg)",
          },
          "& > :nth-of-type(2)": {
            transform: "rotate(0deg)",
          },
          "&_button": {
            alignItems: "center",
            aspectRatio: "1",
            borderRadius: "12px",
            color: theme.colors.gray[300],
            display: "flex",
            height: "24px",
            justifyContent: "center",
            padding: "4px",
            transition: "all 0.15s ease-in-out",
            width: "24px",
            ":hover": {
              color: theme.colors.gray[700],
            },
          },
        },
        "&_weekHeaders": {
          top: "-8px",
        },
      },
    }),
    popover: css({
      ".MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    padding: "12px 24px 24px 24px",
    position: "relative",
    width: "100%",
  }),
};
