import React from "react";
import moment from "moment";
import CheckIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";
import { ExpenseApproverHistory } from "../../../../models/expense.model";
import { MEDIA_QUERIES } from "../../../../constants";

const styles = {
  root: css({
    width: "100%",
    padding: "1.5rem 1.25rem",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      display: "none"
    }
  }),
  title: css({
    fontWeight: "bold",
    fontSize: 16,
    paddingBottom: "1.5rem"
  }),
  subTitle: css({
    fontWeight: "bold",
    fontSize: 14,
    paddingBottom: "1rem"
  }),
  stageDiv: css({
    paddingBottom: "1rem"
  }),
  stageTitle: css({
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    paddingBottom: ".5rem"
  }),
  approvedDetails: css({
    fontSize: 12,
    color: defaultTheme.subTextColor,
    lineHeight: "1rem"
  }),
  declinedDetails: css({
    fontSize: 12,
    color: defaultTheme.errorTextColor,
    lineHeight: "1rem"
  }),
  analysisText: css({
    fontSize: 12,
    color: defaultTheme.primaryColor,
    lineHeight: "1rem"
  }),
  checkIcon: css({
    color: defaultTheme.successTextColor,
    fontSize: 16,
    marginLeft: ".35rem"
  }),
  closeIcon: css({
    color: defaultTheme.errorTextColor,
    fontSize: 16,
    marginLeft: ".35rem"
  })
};

const STAGE_TRANSLATIONS: { [key: number]: string } = {
  1: "Primeira etapa",
  2: "Segunda etapa",
  3: "Terceira etapa",
  4: "Quarta etapa",
  99: "Pagamento"
};

interface StageItemProps {
  stage: number;
  approved: boolean;
  currentStage: number;
  approverName?: string;
  repliedAt?: string;
}

const StageItem = ({
  stage,
  approved,
  currentStage,
  approverName,
  repliedAt
}: StageItemProps) => {
  const formattedDate = moment(repliedAt).format("ddd, DD [de] MMMM YYYY");
  const isOnAnalysis = !approved && !repliedAt && currentStage === stage;

  return (
    <div className={styles.stageDiv}>
      <div className={styles.stageTitle}>
        <span>{STAGE_TRANSLATIONS[stage]}</span>
        {approved ? <CheckIcon className={styles.checkIcon} /> : null}
        {!approved && repliedAt ? (
          <CloseIcon className={styles.closeIcon} />
        ) : null}
      </div>
      {approved ? (
        <div className={styles.approvedDetails}>
          {stage === 99 ? "Pago" : "Aprovado"} por {approverName},{" "}
          {formattedDate}
        </div>
      ) : null}
      {!approved && repliedAt ? (
        <div className={styles.declinedDetails}>
          Negado por {approverName}, {formattedDate}
        </div>
      ) : null}
      {isOnAnalysis ? (
        <div className={styles.analysisText}>Em análise</div>
      ) : null}
    </div>
  );
};

interface Props {
  currentStage: number;
  approversHistory: ExpenseApproverHistory[];
  showCurrentStage?: boolean;
}

const ApprovalHistoryPresentational = ({
  currentStage,
  approversHistory,
  showCurrentStage
}: Props) => (
  <div className={styles.root}>
    <div className={styles.title}>
      <span>Histórico de aprovações</span>
    </div>
    <div className={styles.subTitle}>
      <span>Status:</span>
    </div>
    {approversHistory.map(history => (
      <StageItem
        key={history.userToken}
        stage={history.stage}
        approved={history.approved}
        currentStage={currentStage}
        approverName={history.fullName}
        repliedAt={history.repliedAt}
      />
    ))}
    {showCurrentStage ? (
      <StageItem
        stage={currentStage}
        approved={false}
        currentStage={currentStage}
      />
    ) : null}
  </div>
);

export { ApprovalHistoryPresentational };
