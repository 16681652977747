import React from "react";
import { Box } from "@toolkit";
import { useFlightInfoContext } from "../FlightInfo.context";
import {
  CostCenterFilter,
  TagFilter,
  TravelerFilter,
  AreaFilter,
  ProjectFilter,
  BillingProfileFilter,
  PeriodFilter
} from "../../shared/filters";
import PermissionVisibility from "@shared/permissions/PermissionVisibility";

const Filters = () => {
  const {
    filterState,
    costCenterOptions,
    tagOptions,
    travelerOptions,
    companyAreaOptions,
    projectOptions,
    billingProfileOptions,
    onPeriodRangeApply,
    onFilterCostCenter,
    onFilterTag,
    onFilterTraveler,
    onFilterCompanyArea,
    onFilterProject,
    onFilterBillingProfile
  } = useFlightInfoContext();

  return (
    <Box display={["none", "none", "flex"]} alignItems="center" py={24}>
      <PeriodFilter
        startMonth={filterState.startMonth}
        startYear={filterState.startYear}
        endMonth={filterState.endMonth}
        endYear={filterState.endYear}
        onApply={onPeriodRangeApply}
      />
      <PermissionVisibility permissions={["companySettings.costCenters"]}>
        <CostCenterFilter
          onApply={onFilterCostCenter}
          options={costCenterOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      {/* <PermissionVisibility permissions={["travels.tags"]}>
        <TagFilter
          onApply={onFilterTag}
          options={tagOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility> */}
      <TravelerFilter
        onApply={onFilterTraveler}
        options={travelerOptions}
        containerStyles={{ marginRight: "1rem" }}
      />
      <PermissionVisibility permissions={["companySettings.areas"]}>
        <AreaFilter
          onApply={onFilterCompanyArea}
          options={companyAreaOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.projects"]}>
        <ProjectFilter
          onApply={onFilterProject}
          options={projectOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.billingProfiles"]}>
        <BillingProfileFilter
          onApply={onFilterBillingProfile}
          options={billingProfileOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
    </Box>
  );
};

export default Filters;
export { Filters };
