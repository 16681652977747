import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
  }),
  footer: {
    left: {
      button: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.pink[500],
        cursor: "pointer",
        ":disabled": {
          cursor: "default",
          opacity: 0.75,
        },
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[300],
        display: "flex",
        fontSize: "0.875rem",
        fontWeight: 500,
        gap: "8px",
      }),
    },
    right: {
      root: ({ disabled }: { disabled?: boolean }) =>
        css({
          alignItems: "center",
          color: theme.colors.pink[500],
          display: "flex",
          fontSize: "0.875rem",
          fontWeight: disabled ? 400 : 500,
          gap: "8px",
          opacity: disabled ? 0.75 : 1,
          textDecoration: "underline",
          transition: "color 0.15s ease-in-out",
          ":hover": {
            color: theme.colors.pink[700],
          },
        }),
    },
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      gap: "24px",
      justifyContent: "space-between",
      padding: "12px 24px",
    }),
  },
  header: {
    bottom: {
      name: css({
        fontSize: "1.25rem",
        fontWeight: 600,
      }),
      placeholder: css({
        color: theme.colors.gray[100],
        fontSize: "1.25rem",
        fontWeight: 500,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "24px",
        justifyContent: "space-between",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "24px 24px 0 24px",
    }),
    top: {
      date: css({
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
      }),
      right: {
        actions: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
          "@media screen and (max-width: 768px)": {
            gap: "16px",
            position: "absolute",
            right: "24px",
            top: "24px",
          },
        }),
        button: css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.pink[500],
          cursor: "pointer",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        traveler: css({
          fontSize: "0.875rem",
          whiteSpace: "nowrap",
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "24px",
        justifyContent: "space-between",
        "@media screen and (max-width: 768px)": {
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "8px",
        },
      }),
    },
  },
  offers: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    position: "relative",
    zIndex: 0,
    "&::before": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      bottom: 0,
      content: '""',
      height: "calc(100% - 48px)",
      left: "calc(24px + 6px + 1px)",
      margin: "auto",
      position: "absolute",
      top: 0,
      width: "1px",
      zIndex: -1,
    },
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    color: theme.colors.gray[700],
    marginBottom: "24px",
    position: "relative",
    overflow: "hidden",
    textDecoration: "none",
    transition: "box-shadow 0.15s ease-in-out",
    width: "100%",
    "&:hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    },
    "@media screen and (max-width: 768px)": {
      marginBottom: "16px",
    },
  }),
};
