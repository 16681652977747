import { StyleSheet } from "@react-pdf/renderer";

export const styles = {
  root: ({ size = 24 }: { size?: number }) =>
    StyleSheet.create({
      root: {
        alignItems: "center",
        aspectRatio: "1",
        display: "flex",
        flexShrink: 0,
        height: size,
        justifyContent: "center",
        pointerEvents: "auto",
        width: size,
      },
    }),
};
