import moment from "moment";

export const getDayHoursWithInterval = (minutesInterval: number) => {
  if (minutesInterval === 0) {
    return [];
  }

  const hours: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minutesInterval) {
      hours.push(moment().set({ hour, minute }).format("HH:mm"));
    }
  }

  return hours;
};
