import { USER_ROLES } from "~/apps/shared/constants";
import { UserBookingPhoneConfig } from "~/apps/shared/constants/enums";
import _ from "lodash";
import * as yup from "yup";

export const createUserSchema = yup.object().shape({
  approvalProcess: yup
    .object({
      active: yup.number(),
      name: yup.string(),
      token: yup.string(),
      type: yup.string(),
    })
    .nullable(true),
  billingProfileToken: yup.string().nullable(true),
  bookerTargets: yup.array().of(
    yup.object({
      email: yup.string(),
      fullName: yup.string(),
      label: yup.string(),
      userToken: yup.string(),
    }),
  ),
  bossToken: yup.string(),
  capabilities: yup
    .object({
      analytics: yup.bool(),
      approvalAnyTimeForOthers: yup.bool(),
      approvals: yup.bool(),
      basic: yup.bool(),
      batchActions: yup.bool(),
      configurations: yup.bool(),
      eventOrganizer: yup.bool(),
      expenseForOthers: yup.bool(),
      expenses: yup.bool(),
      externalCredentials: yup.bool(),
      financial: yup.bool(),
      reviews: yup.bool(),
      search: yup.bool(),
      selfSearch: yup.bool(),
    })
    .required(),
  clientToken: yup.string().required("Campo obrigatório..."),
  email: yup
    .string()
    .email("Digite um email válido...")
    .required("Digite o email..."),
  firstName: yup.string().required("Digite o nome..."),
  guest: yup.bool().required("Campo obrigatório..."),
  hidden: yup.bool().nullable(true),
  lastName: yup.string().required("Digite o sobrenome..."),
  phone: yup
    .string()
    .test("", "Digite um número válido...", (phone) => {
      if (!phone) {
        return true;
      }

      const digits = phone.trim().replace(/\D/g, "");

      if (digits.length <= 9) {
        return false;
      }

      return true;
    })
    .nullable(true),
  role: yup
    .string()
    .oneOf(Object.values(_.omit(USER_ROLES, "super")))
    .required("Campo obrigatório..."),
  sendInvite: yup.bool().required("Campo obrigatório..."),
  userName: yup.string(),
  userPreference: yup.object({
    currency: yup.string(),
  }),
});

export type CreateUserSchema = yup.InferType<typeof createUserSchema>;

export const editUserSchema = yup.object().shape({
  approvalProcess: yup
    .object({
      active: yup.number(),
      name: yup.string(),
      token: yup.string(),
      type: yup.string(),
    })
    .nullable(true),
  billingProfileToken: yup.string().nullable(true),
  bookingPhoneConfig: yup.mixed<UserBookingPhoneConfig>(),
  bookerTargets: yup.array().of(
    yup.object({
      email: yup.string(),
      fullName: yup.string(),
      label: yup.string(),
      userToken: yup.string(),
    }),
  ),
  bossToken: yup.string(),
  capabilities: yup
    .object({
      analytics: yup.bool(),
      approvalAnyTimeForOthers: yup.bool(),
      approvals: yup.bool(),
      basic: yup.bool(),
      batchActions: yup.bool(),
      configurations: yup.bool(),
      eventOrganizer: yup.bool(),
      expenseForOthers: yup.bool(),
      expenses: yup.bool(),
      externalCredentials: yup.bool(),
      financial: yup.bool(),
      reviews: yup.bool(),
      search: yup.bool(),
      selfSearch: yup.bool(),
    })
    .required(),
  email: yup
    .string()
    .email("Digite um email válido...")
    .required("Digite o email..."),
  firstName: yup.string().required("Digite o nome..."),
  guest: yup.bool(),
  lastName: yup.string().required("Digite o sobrenome..."),
  phone: yup
    .string()
    .test("", "Digite um número válido...", (phone) => {
      if (!phone) {
        return true;
      }

      const digits = phone.trim().replace(/\D/g, "");

      if (digits.length <= 9) {
        return false;
      }

      return true;
    })
    .nullable(true),
  role: yup.string().oneOf(Object.values(_.omit(USER_ROLES, "super"))),
  sendSms: yup.bool(),
  userToken: yup.string().required("Campo obrigatório..."),
});

export type EditUserSchema = yup.InferType<typeof editUserSchema>;
