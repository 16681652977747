import React, { useState } from "react";

import { RouteComponentProps } from "@reach/router";
import { Layout } from "~/apps/corporate/components/layout/layout";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import * as passwordApi from "~/apps/shared/apis/password.api";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputPassword } from "~/apps/shared/components/input-password/input-password";
import { Logo } from "~/apps/shared/components/logo/logo";
import { PasswordMeter } from "~/apps/shared/components/password-meter/password-meter";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { Button } from "@toolkit/v2";

import {
  passwordUpdateFormSchema,
  PasswordUpdateFormSchema,
} from "./password-update-form.schema";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const PasswordUpdate: React.FC<Props> = () => {
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [updateMessage, setUpdateMessage] = useState<string | null>(null);

  const form = useForm<PasswordUpdateFormSchema>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      await passwordApi
        .updatePassword(values.currentPassword, values.newPassword)
        .then((response) => {
          if (!response || !response.success) {
            setUpdateError(ERROR.UNEXPECTED);

            return;
          }

          setUpdateMessage(response.message);
        })
        .catch((error) => {
          setUpdateError(error);
        });

      form.reset();
    },
    schema: passwordUpdateFormSchema,
  });

  return (
    <>
      <PageTitle title={pageTitles.UPDATE_PASSWORD} />
      <Layout>
        <div css={styles.container.root}>
          <Logo showName />

          <Form context={form} css={styles.container.form.root}>
            {!form.submitting && updateError ? (
              <div css={styles.container.form.error}>
                <Icon use="x-circle-outline" />
                <span>{updateError.description}</span>
              </div>
            ) : null}
            {!form.submitting && updateMessage ? (
              <div css={styles.container.form.message}>
                <Icon use="info-outline" />
                <span>{updateMessage}</span>
              </div>
            ) : null}
            <div css={styles.container.form.field.root}>
              <Form.Field
                as={
                  <InputPassword
                    aria-label="Senha atual"
                    id="currentPassword"
                    placeholder="Senha atual"
                  />
                }
                onChange={() => {
                  void form.trigger("currentPassword");
                }}
                name="currentPassword"
              />
              <InputErrorMessage css={styles.container.form.field.error}>
                {form.errors["currentPassword"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.container.form.field.root}>
              <Form.Field
                as={
                  <InputPassword
                    aria-label="Nova senha"
                    id="newPassword"
                    placeholder="Nova senha"
                  />
                }
                onChange={() => {
                  void form.trigger("newPassword");
                }}
                name="newPassword"
              />
              <InputErrorMessage css={styles.container.form.field.error}>
                {form.errors["newPassword"]?.message}
              </InputErrorMessage>
            </div>
            <PasswordMeter password={form.watch("newPassword")} />
            <div css={styles.container.form.field.root}>
              <Form.Field
                as={
                  <InputPassword
                    aria-label="Confirmar senha"
                    id="newPasswordConfirmation"
                    placeholder="Confirmar senha"
                  />
                }
                onChange={() => {
                  void form.trigger("newPasswordConfirmation");
                }}
                name="newPasswordConfirmation"
              />
              <InputErrorMessage css={styles.container.form.field.error}>
                {form.errors["newPasswordConfirmation"]?.message}
              </InputErrorMessage>
            </div>
            <Button
              css={styles.container.form.button}
              disabled={!form.formState.isValid || form.submitting}
              type="submit"
            >
              {!form.submitting ? "Atualizar senha" : "Atualizando..."}
            </Button>
          </Form>
        </div>
      </Layout>
    </>
  );
};
