import { CABINS } from "../constants";

export const getAllowedClasses = (cabinClass: string): string[] => {
  let result;

  if (cabinClass === CABINS.first) {
    result = [
      CABINS.economy,
      CABINS.premiumEconomy,
      CABINS.business,
      CABINS.first,
    ];
  } else if (cabinClass === CABINS.business) {
    result = [CABINS.economy, CABINS.premiumEconomy, CABINS.business];
  } else if (cabinClass === CABINS.premiumEconomy) {
    result = [CABINS.economy, CABINS.premiumEconomy];
  } else {
    result = [CABINS.economy];
  }

  return Array.isArray(result) ? result : [result];
};
