import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  container: css({
    alignItems: "center",
    display: "flex",
    gap: "24px",
    height: "100%",
    justifyContent: "space-between",
    margin: "0 auto",
  }),
  left: {
    link: css({
      textDecoration: "none",
    }),
    root: css({
      display: "flex",
      flexShrink: 0,
      justifyContent: "flex-start",
      textDecoration: "none",
    }),
  },
  right: {
    nav: {
      links: {
        approvals: css({
          backgroundColor: theme.colors.pink[500],
          borderRadius: "50%",
          color: theme.colors.white,
          display: "grid",
          fontSize: "0.625rem",
          fontWeight: 600,
          height: "16px",
          marginLeft: "6px",
          minHeight: "16px",
          minWidth: "16px",
          placeItems: "center",
          width: "16px",
        }),
        item: css({
          display: "flex",
        }),
        link: ({ active }: { active: boolean }) =>
          css({
            borderBottom: "2px solid",
            borderBottomColor: active ? theme.colors.pink[500] : "transparent",
            color: active ? theme.colors.gray[700] : theme.colors.gray[300],
            display: "flex",
            fontFamily: theme.fonts.inter,
            padding: "24px 16px",
            textDecoration: "none",
            transform: "translateY(1px)",
            transition: "all 0.15s ease-in-out",
            ":hover": {
              borderBottomColor: theme.colors.pink[500],
              color: theme.colors.gray[700],
            },
          }),
        root: css({
          alignItems: "flex-end",
          display: "flex",
        }),
      },
      root: css({
        display: "flex",
        flexShrink: 0,
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      flexShrink: 0,
      gap: "16px",
      height: "100%",
      justifyContent: "flex-end",
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.gray[100],
    padding: "0 80px",
    position: "relative",
    zIndex: 20,
    "@media screen and (max-width: 1280px)": {
      padding: "0 24px 0 24px",
    },
    "@media screen and (max-width: 992px)": {
      padding: "0 24px 0 24px",
    },
    "@media screen and (max-width: 768px)": {
      padding: "0 16px",
    },
  }),
  warning: {
    root: css({
      backgroundColor: theme.colors.white,
      borderBottom: "1px solid",
      borderBottomColor: theme.colors.gray[100],
      color: theme.colors.gray[500],
      display: "flex",
      fontSize: "0.875rem",
      justifyContent: "center",
      padding: "12px",
    }),
    strong: css({
      fontWeight: 600,
      marginRight: "0.25rem",
    }),
  },
};
