import React from "react";

import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";
import { NoImageHotel } from "~/apps/shared/components/no-image-hotel/no-image-hotel";
import { StarRatingDisplay } from "~/apps/shared/components/star-rating-display/star-rating-display";

import { styles } from "./styles";

type Props = {
  servicePresenter: HotelItineraryServicePresenter;
};

export const ItineraryHotelDetailsDrawerHotel: React.FC<Props> = ({
  servicePresenter,
}) => {
  const hotelAddress = servicePresenter.getAddress();
  const hotelName = servicePresenter.getHotelName();
  const hotelStars = servicePresenter.getStars();
  const mainImageURL = servicePresenter.getMainImageURL();

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        {mainImageURL ? (
          <img alt={hotelName} css={styles.left.picture} src={mainImageURL} />
        ) : (
          <NoImageHotel css={styles.left.picture} />
        )}
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          <span css={styles.right.top.name}>{hotelName}</span>
          <StarRatingDisplay
            css={styles.right.top.stars}
            rating={hotelStars}
            size={16}
          />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.info}>
            <Icon size={20} use="map" />
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {hotelAddress}
            </span>
          </div>
          <div css={styles.right.bottom.info}>
            <Icon size={20} use="arrow-right-start-on-rectangle" />
            <span>Check-in: {servicePresenter.getFormattedCheckIn()}</span>
          </div>
          <div css={styles.right.bottom.info}>
            <Icon size={20} use="arrow-right-end-on-rectangle" />
            <span>Check-out: {servicePresenter.getFormattedCheckOut()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
