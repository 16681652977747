import { Capabilities } from "./enums";

const ADMIN: Partial<Capabilities> = {
  analytics: true,
  approvalAnyTimeForOthers: false,
  approvals: true,
  basic: true,
  batchActions: false,
  configurations: true,
  eventOrganizer: true,
  expenseForOthers: true,
  expenses: true,
  externalCredentials: true,
  financial: true,
  manualManagement: false,
  reviews: true,
  search: true,
  selfSearch: true,
};

const CUSTOMIZED: Partial<Capabilities> = {
  analytics: false,
  approvalAnyTimeForOthers: false,
  approvals: false,
  basic: true,
  batchActions: false,
  configurations: false,
  eventOrganizer: false,
  expenseForOthers: false,
  expenses: false,
  externalCredentials: false,
  financial: false,
  manualManagement: false,
  reviews: true,
  search: false,
  selfSearch: false,
};

const MANAGER: Partial<Capabilities> = {
  analytics: true,
  approvalAnyTimeForOthers: false,
  approvals: true,
  basic: true,
  batchActions: false,
  configurations: false,
  eventOrganizer: false,
  expenseForOthers: false,
  expenses: true,
  externalCredentials: false,
  financial: true,
  manualManagement: false,
  reviews: true,
  search: true,
  selfSearch: true,
};

const SELF_TRAVELER: Partial<Capabilities> = {
  analytics: false,
  approvalAnyTimeForOthers: false,
  approvals: false,
  basic: true,
  batchActions: false,
  configurations: false,
  eventOrganizer: false,
  expenseForOthers: false,
  expenses: true,
  externalCredentials: false,
  financial: false,
  manualManagement: false,
  reviews: false,
  search: false,
  selfSearch: true,
};

const TRAVELER: Partial<Capabilities> = {
  analytics: false,
  approvalAnyTimeForOthers: false,
  approvals: false,
  basic: true,
  batchActions: false,
  configurations: false,
  eventOrganizer: false,
  expenseForOthers: false,
  expenses: true,
  externalCredentials: false,
  financial: false,
  manualManagement: false,
  reviews: false,
  search: false,
  selfSearch: false,
};

const USER: Partial<Capabilities> = {
  analytics: false,
  approvalAnyTimeForOthers: false,
  approvals: true,
  basic: true,
  batchActions: false,
  configurations: false,
  eventOrganizer: false,
  expenseForOthers: false,
  expenses: true,
  externalCredentials: false,
  financial: false,
  manualManagement: false,
  reviews: true,
  search: true,
  selfSearch: true,
};

export const DEFAULT_CAPABILITIES = {
  ADMIN,
  CUSTOMIZED,
  MANAGER,
  SELF_TRAVELER,
  TRAVELER,
  USER,
};
