import React from "react";
import { TwitterPicker, Color, ColorResult } from "react-color";
import { css } from "@emotion/core";
import { theme } from "smartrips-skin";

export interface IColorPickerInput {
  color: Color;
  onChange: any;
}

const styles = {
  color: css({
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%"
  }),
  swatch: css({
    background: "#fff",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.border}`,
    display: "inline-block",
    cursor: "pointer"
  }),
  popover: css({
    position: "absolute",
    zIndex: 2
  }),
  cover: css({
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  })
};

export interface IState {
  color: string;
  displayColorPicker: boolean;
}

export interface IProps {
  color: string;
  onChange: (color: string) => void;
}

const ColorPickerInput = ({ color = "#777", onChange }: IProps) => {
  const [state, setState] = React.useState<IState>({
    color,
    displayColorPicker: false
  });

  const handleClick = () => {
    setState((prevState: IState) => ({
      ...prevState,
      displayColorPicker: !prevState.displayColorPicker
    }));
  };

  const handleChange = (colorResult: ColorResult) => {
    setState((prevState: IState) => ({ ...prevState, color: colorResult.hex }));
    onChange(colorResult.hex);
  };

  const handleClose = () => {
    setState((prevState: IState) => ({
      ...prevState,
      displayColorPicker: false
    }));
  };
  return (
    <div>
      <div css={styles.swatch} onClick={handleClick}>
        <div
          css={styles.color}
          style={{
            backgroundColor: state.color!
          }}
        />
      </div>
      {state.displayColorPicker ? (
        <div css={styles.popover}>
          <div css={styles.cover} onClick={handleClose} />
          <TwitterPicker color={state.color} onChangeComplete={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export { ColorPickerInput };
