import { useState, useEffect } from "react";

import Axios from "axios";

const fetchImage = async (url: string) => {
  return Axios.get(url, {
    responseType: "blob",
  }).then(({ data }) => URL.createObjectURL(data));
};

export const useImageBuffer = (imageUrl?: string) => {
  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    const getImage = async () => {
      const img = document.createElement("img");
      const canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");

      img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;

        ctx?.drawImage(img, 0, 0);

        const base64Data = canvas
          .toDataURL("image/png")
          .replace("data:image/png;base64,", "");

        const imageBuffer = Buffer.from(base64Data, "base64");

        setImage(imageBuffer);
      };

      let blob: string | null = null;

      try {
        blob = await fetchImage(`https://cors.bridged.cc/${imageUrl}`);
      } catch (error) {
        setImage(null);

        return;
      }

      img.setAttribute("src", blob);
    };

    void getImage();
  }, [imageUrl]);

  return image;
};
