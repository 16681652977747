import React from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { theme } from "@skin/v2";

import { CardOwnerType } from "~/constants/enums";
import { OptionType } from "~/constants/shared";

import { CardModel } from "~/models/card.model";

import { Checkbox } from "@shared/checkbox/Checkbox";

import BillingProfileSelect from "~/components/shared/billing-profile-select";
import Drawer from "~/components/shared/drawer";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";

import { Row } from "../../shared/layout/Row";
import { DrawerSpinner } from "../DrawerSpinner";
import { FormFieldAdditionalText } from "../form/FormField";
import { Input } from "../inputs";
import { EDIT_CREDIT_CARD_SCHEMA } from "./validations";

const styles = {
  drawer: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: [500, 815],
    },
  }),
  form: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  formContent: css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    padding: "1.5rem",
    overflow: "auto",
    height: "100%",
  }),
  formFooter: css({
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    padding: "2rem",
  }),
  divWhithLabel: css({
    position: "relative",
    padding: "10px",
    border: "1px solid rgb(211, 222, 233)",
    borderRadius: "0.5rem",
  }),
  labelOnBorder: css({
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    position: "absolute",
    fontSize: "12px",
    color: "#999BA0",
    top: "-7px",
    left: " 10px",
    backgroundColor: "white",
    padding: "0 5px",
  }),
  formTypeCardlLabel: css({
    marginRight: 0,
    width: "6rem",
  }),
  checkbox: css({
    marginRight: 0,
    width: "6rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  }),
};

export interface IEditCreditCardFormData {
  allowApprovers: boolean;
  billingProfile?: OptionType<string>;
  description: string;
  enableToBus: boolean;
  enableToCar: boolean;
  enableToFlight: boolean;
  enableToHotel: boolean;
  enableToOther: boolean;
  enableToRide: boolean;
  ownerType: CardOwnerType;
}

interface ICreditCardEditingDrawerProps {
  card: CardModel | null;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose(): void;
  onSubmit: (cardToken: string, data: IEditCreditCardFormData) => void;
}

export const CreditCardEditingDrawer = ({
  card,
  isOpen,
  isSubmitting,
  onClose,
  onSubmit,
}: ICreditCardEditingDrawerProps) => {
  const { showSnackMessage } = useApplication();

  const ownerType = card?.personal ? CardOwnerType.user : CardOwnerType.client;

  const {
    control,
    errors,
    register,
    handleSubmit,
  } = useForm<IEditCreditCardFormData>({
    resolver: yupResolver(EDIT_CREDIT_CARD_SCHEMA),
  });

  const onSubmitForm = (data: IEditCreditCardFormData) => {
    const cardToken = card?.token as string;
    const defaultBillingProfileToken = card?.billingProfileToken as string;

    const isSomeServiceEnabled =
      data.enableToBus ||
      data.enableToCar ||
      data.enableToFlight ||
      data.enableToHotel ||
      data.enableToOther ||
      data.enableToRide;

    if (!isSomeServiceEnabled) {
      showSnackMessage(
        "Pelo menos uma finalidade deve ser selecionada.",
        ALERT_TYPES.ERROR,
      );

      return;
    }

    const parsedValues = {
      ...data,
      billingProfile: data.billingProfile?.value
        ? data.billingProfile
        : { label: "", value: defaultBillingProfileToken },
    };

    onSubmit(cardToken, parsedValues);
  };

  const billingProfileIsApplicableToAll =
    !card?.personal && card?.billingProfileToken === null;

  const defaultBillingProfile = billingProfileIsApplicableToAll
    ? "null"
    : card?.billingProfileToken;

  return (
    <Drawer
      anchor="right"
      css={styles.drawer}
      onClose={onClose}
      open={isOpen}
      title="Editar cartão de crédito"
    >
      <DrawerSpinner visible={isSubmitting} />

      <form onSubmit={handleSubmit(onSubmitForm)} css={styles.form}>
        <div style={{ display: "none" }}>
          <input ref={register} name="ownerType" value={ownerType} />
        </div>
        <div css={styles.formContent}>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <FormGroup style={{ width: "100%" }}>
              <label style={{ fontWeight: "bold", marginBottom: 8 }}>
                Apelido do cartão
              </label>
              <Controller
                as={Input}
                control={control}
                defaultValue={card?.description}
                error={!!errors.description}
                name="description"
                placeholder="Apelido do cartão"
              />
              <FormFieldAdditionalText
                style={{
                  marginLeft: 1,
                  marginTop: 75,
                  position: "absolute",
                }}
                hasError
              >
                {errors?.description?.message}
              </FormFieldAdditionalText>
            </FormGroup>
          </div>
          {ownerType === CardOwnerType.client && (
            <div style={{ gap: "1rem" }}>
              <FormGroup style={{ width: "100%" }}>
                <label style={{ fontWeight: "bold", marginBottom: 8 }}>
                  Perfil de cobrança
                </label>
                <Controller
                  as={
                    <BillingProfileSelect
                      additionalOptions={[
                        { label: "Aplicar para todos", value: "null" },
                      ]}
                      defaultValue={defaultBillingProfile}
                      onCreditCardTab
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          border: `1px solid ${theme.colors.gray[500]}`,
                          borderRadius: "0.5rem",
                          boxShadow: "none",
                          cursor: "pointer",
                          display: "flex",
                          height: "3rem",
                          justifyContent: "center",
                          "&:hover": {
                            borderColor: theme.colors.gray[700],
                          },
                          "&:focus-within": {
                            border: `1px solid ${theme.colors.gray[500]}`,
                            boxShadow: `0 0 0 1px ${theme.colors.gray[500]}`,
                          },
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          zIndex: 10,
                        }),
                        menuList: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: "0.5rem",
                        }),
                        option: (baseStyles, { isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? theme.colors.gray[500]
                            : theme.colors.white,
                          color: isSelected
                            ? theme.colors.white
                            : theme.colors.black,
                          cursor: "pointer",
                          padding: "0.75rem 1rem",
                          "&:hover": {
                            backgroundColor: theme.colors.gray[300],
                            color: theme.colors.white,
                          },
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          flex: "none",
                        }),
                        indicatorSeparator: () => ({}),
                        dropdownIndicator: (baseStyles) => ({
                          ...baseStyles,
                          color: theme.colors.gray[500],
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          display: "flex",
                          fontWeight: "bold",
                          justifyContent: "space-between",
                          lineHeight: "1.25rem",
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: theme.colors.gray[500],
                          fontWeight: "bold",
                        }),
                      }}
                      theme={(currentTheme) => ({
                        ...currentTheme,
                        colors: {
                          ...currentTheme.colors,
                          primary: theme.colors.gray[500],
                        },
                      })}
                    />
                  }
                  control={control}
                  name="billingProfile"
                  placeholder="Perfil de cobrança"
                />
              </FormGroup>
            </div>
          )}
          <FormGroup style={{ width: "100%" }}>
            <label style={{ fontWeight: "bold", marginBottom: 8 }}>
              Finalidade do cartão
            </label>
            <div css={styles.divWhithLabel}>
              <Row style={{ justifyContent: "space-between" }}>
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.flight}
                  name="enableToFlight"
                  render={(props) => (
                    <label htmlFor="enableToFlight" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToFlight"
                        name="enableToFlight"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Aéreo
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.bus}
                  name="enableToBus"
                  render={(props) => (
                    <label htmlFor="enableToBus" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToBus"
                        name="enableToBus"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Ônibus
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.car}
                  name="enableToCar"
                  render={(props) => (
                    <label htmlFor="enableToCar" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToCar"
                        name="enableToCar"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Carro
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.ride}
                  name="enableToRide"
                  render={(props) => (
                    <label htmlFor="enableToRide" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToRide"
                        name="enableToRide"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Táxi
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.hotel}
                  name="enableToHotel"
                  render={(props) => (
                    <label htmlFor="enableToHotel" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToHotel"
                        name="enableToHotel"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Hotel
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={card?.serviceTypes.other}
                  name="enableToOther"
                  render={(props) => (
                    <label htmlFor="enableToOther" css={styles.checkbox}>
                      <Checkbox
                        checked={props.value}
                        color={theme.colors.gray[500]}
                        id="enableToOther"
                        name="enableToOther"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                      Outro
                    </label>
                  )}
                />
              </Row>
            </div>
          </FormGroup>
          {ownerType === CardOwnerType.user && (
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: -12 }}
            >
              <Controller
                control={control}
                defaultValue={card?.allowApprovers}
                name="allowApprovers"
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={card?.allowApprovers as boolean}
                        name="allowApprovers"
                        onChange={(e) => onChange(e.target.checked)}
                        style={{ marginLeft: "1rem" }}
                        value={value}
                      />
                    }
                    label="Viabilizar o uso deste cartão em minhas viagens por parte de aprovadores e solicitantes."
                  />
                )}
              />
            </div>
          )}
        </div>
        <div css={styles.formFooter}>
          <ContainedButton type="submit" disabled={isSubmitting}>
            Salvar alterações
          </ContainedButton>
          <OutlinedButton onClick={onClose} disabled={isSubmitting}>
            Cancelar
          </OutlinedButton>
        </div>
      </form>
    </Drawer>
  );
};
