import { css as ecss } from "emotion";

export const styles = {
  form: ecss({
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
  }),
  root: ecss({
    borderRadius: "8px",
    height: "fit-content !important",
    padding: "0 0 0 0 !important",
    position: "relative",
    width: "100%",
    "& > :last-child": {
      borderBottomLeftRadius: "8px !important",
      borderBottomRightRadius: "8px !important"
    }
  })
};
