import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactAutocomplete from "react-autocomplete";

import { Option } from "~/apps/shared/types";

import { theme } from "@skin/v2";

import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { styles } from "./styles";

type Item = Option<string> & {
  error?: string;
};

const defaultGetItemValue = (item: Item) => {
  return item.label;
};

const defaultMenuStyle: React.CSSProperties = {
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.gray[100]}`,
  borderRadius: "8px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  maxHeight: "288px",
  left: "0",
  listStyle: "none",
  overflowY: "auto",
  position: "absolute",
  right: "0",
  top: "calc(100% + 4px)",
  width: "100%",
  zIndex: 10,
};

const defaultRenderInput = ({
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div css={styles.input.root}>
      <input css={styles.input.input} {...props} />
    </div>
  );
};

const defaultWrapperStyle: React.CSSProperties = {
  display: "block",
  overflow: "visible",
  position: "relative",
};

const defaultRenderItem = (item: Item, isHighlighted: boolean) => {
  if (item.error) {
    return (
      <li css={styles.menu.item({ isDisabled: true, isHighlighted: false })}>
        Nenhum resultado.
      </li>
    );
  }

  return <li css={styles.menu.item({ isHighlighted })}>{item.label}</li>;
};

const normalize = (str: string) => {
  return str
    .normalize("NFKD")
    .replace(/[^\w\s]/g, "")
    .toLowerCase();
};

export const Autocomplete: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof ReactAutocomplete>,
    | "getItemValue"
    | "handleSelect"
    | "items"
    | "onSelect"
    | "renderInput"
    | "renderItem"
    | "value"
  > &
    Partial<
      Pick<
        React.ComponentPropsWithoutRef<typeof ReactAutocomplete>,
        "getItemValue" | "renderItem"
      >
    > & {
      clearOnSelect?: boolean;
      defaultValue?: Item;
      filterItems?: (items: Item[]) => Item[];
      matchFunction?: (item: Item, value: string) => boolean;
      options: Item[];
      renderInput?: (
        props: React.InputHTMLAttributes<HTMLInputElement>,
      ) => JSX.Element;
      onSelect?: (item: Item) => void;
    }
> = ({
  clearOnSelect,
  defaultValue,
  filterItems = (items) => items,
  getItemValue = defaultGetItemValue,
  matchFunction = (option, value) =>
    normalize(option.label).includes(normalize(value)),
  options,
  menuStyle = defaultMenuStyle,
  onSelect,
  renderInput = defaultRenderInput,
  renderItem = defaultRenderItem,
  wrapperStyle = defaultWrapperStyle,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue?.label || "");

  const ref = useRef<HTMLDivElement>(null);

  const clear = useCallback(() => {
    setOpen(false);
    setValue("");
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (!value) {
        clear();

        return;
      }

      setOpen(true);
      setValue(value);
    },
    [clear],
  );

  const handleSelect = useCallback(
    (value: string, item: Item) => {
      onSelect?.(item);

      if (clearOnSelect) {
        clear();

        return;
      }

      setOpen(false);
      setValue(value);
    },
    [clearOnSelect, onSelect],
  );

  const items = useMemo(() => {
    const result = filterItems(
      options.filter((option) => matchFunction(option, value)),
    );

    if (result.length === 0) {
      return [{ error: "NO_RESULTS", label: "", value: "" }];
    }

    return result;
  }, [matchFunction, options, value]);

  useOnClickOutside(() => {
    setOpen(false);
  }, ref);

  return (
    <div ref={ref}>
      <ReactAutocomplete
        getItemValue={getItemValue}
        items={items}
        menuStyle={menuStyle}
        onChange={handleChange}
        open={options.length > 0 && open}
        onSelect={handleSelect}
        renderInput={(props) => {
          return renderInput({
            ...props,
          });
        }}
        renderItem={renderItem}
        value={value}
        wrapperStyle={wrapperStyle}
        {...props}
      />
    </div>
  );
};
