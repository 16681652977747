import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { css } from "@styled-system/css";

import { Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { Input } from "@shared/inputs";

const styles = {
  input: css({
    [".MuiSelect-select .document"]: {
      display: "none"
    }
  }),
  menuItem: css({
    width: "100%",

    ["span"]: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  })
};

const BillingProfileSelect = ({ handleChange, options, value, ...rest }) => {
  return (
    <Input
      placeholder="Perfis de cobrança"
      select
      onChange={handleChange}
      value={value}
      css={styles.input}
      {...rest}
    >
      {options.map(option => (
        <MenuItem
          key={option.billingProfileToken}
          value={option.billingProfileToken}
          css={styles.menuItem}
        >
          <Flex flexDirection="column">
            <Text fontSize={[1, 2]}>{option.name}</Text>
            <Text
              fontSize={[0, 1]}
              color={defaultTheme.subTextColor}
              className="document"
            >
              CNPJ: {option.cnpj}
            </Text>
          </Flex>
        </MenuItem>
      ))}
    </Input>
  );
};

export { BillingProfileSelect };
