import React, { useMemo } from "react";

import { OfferSearcherDialog } from "~/apps/corporate/components/offer-searcher-dialog/offer-search-dialog";
import * as busHelper from "~/apps/corporate/helpers/bus.helper";
import { ServiceType } from "~/apps/shared/constants/enums";
import moment from "moment";

import { useBusesSearch, useBusesSearchActions } from "../buses.context";

type Props = {
  travelToken: string;
};

export const BusesEditSearchDialog: React.FC<Props> = ({ travelToken }) => {
  const { isEditing, searchInfo } = useBusesSearch();
  const { handleCloseEdition } = useBusesSearchActions();

  const data = useMemo(() => {
    if (!searchInfo) {
      return null;
    }

    const { departureDate, oneway, returnDate } = searchInfo;

    const { destinationLocation, originLocation } = busHelper.buildBusLocations(
      searchInfo,
    );

    return {
      origin: originLocation,
      destination: destinationLocation,
      departureDate: moment(departureDate),
      returnDate: returnDate ? moment(returnDate) : null,
      type: oneway ? "oneway" : "roundtrip",
    };
  }, [
    searchInfo,
    searchInfo?.departureDate,
    searchInfo?.oneway,
    searchInfo?.returnDate,
    searchInfo?.travelToken,
  ]);

  if (!data || isEditing === undefined) {
    return null;
  }

  return (
    <OfferSearcherDialog
      data={data}
      onClose={handleCloseEdition}
      open={isEditing}
      travelToken={travelToken}
      title="Editar busca"
      type={ServiceType.BUS}
    />
  );
};
