import React, { useMemo, useState } from "react";
import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@shared/inputs";

const initialState = {
  anchorEl: null,
  isFilterSelected: false,
  costCenterToken: "",
  wasFilterApplied: false
};

const CostCenterFilter = ({ options = [], containerStyles = {}, onApply }) => {
  const [state, setState] = useState(initialState);
  const {
    costCenterToken,
    isFilterSelected,
    anchorEl,
    wasFilterApplied
  } = state;

  const selectedCostCenter = useMemo(() => {
    return costCenterToken
      ? options.find(cc => cc.value === costCenterToken)
      : null;
  }, [costCenterToken, options]);

  const handleApply = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      isFilterSelected: !!costCenterToken,
      wasFilterApplied: true
    }));
    onApply(costCenterToken);
  };

  const handleClear = e => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      costCenterToken: null,
      isFilterSelected: false,
      wasFilterApplied: false
    }));
    onApply("");
  };

  const handleClick = e => {
    const { target } = e;
    setState(prevState => ({
      ...prevState,
      anchorEl: target,
      isFilterSelected: true
    }));
  };

  const handleChange = (option, event) => {
    setState(prevState => ({
      ...prevState,
      costCenterToken: option ? option.value : null,
      isFilterSelected: true,
      wasFilterApplied: false
    }));
    if (event.action === "clear") {
      onApply("");
    }
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      costCenterToken: wasFilterApplied ? costCenterToken : "",
      isFilterSelected: wasFilterApplied ? wasFilterApplied : false
    }));
  };

  return (
    <FilterButtonPopover
      anchorEl={anchorEl}
      isFilterSelected={isFilterSelected}
      isOpen={!!anchorEl}
      applyEnabled
      clearEnabled
      showClearButton={!!state.costCenterToken}
      onApplyClick={handleApply}
      onClearClick={handleClear}
      onButtonClick={handleClick}
      onPopoverClose={handleClose}
      unselectedButtonText={"Centro de Custo"}
      selectedButtonText={
        selectedCostCenter ? selectedCostCenter.label : "Centro de custo"
      }
      containerStyle={containerStyles}
    >
      <div>
        <FormControl style={{ width: "240px" }}>
          <div>
            <Select
              placeholder="Centro de custos"
              value={selectedCostCenter}
              options={options}
              onChange={handleChange}
            />
          </div>
        </FormControl>
      </div>
    </FilterButtonPopover>
  );
};

export default CostCenterFilter;
