import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "12px",
    padding: "24px",
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
  text: {
    link: css({
      color: theme.colors.pink[500],
      cursor: "pointer",
      textDecoration: "underline",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.pink[700],
      },
    }),
    root: css({
      alignItems: "center",
      color: theme.colors.gray[700],
      display: "inline-block",
      fontSize: "0.875rem",
    }),
  },
};
