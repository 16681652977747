import React from "react";

import { CARD_ISSUERS } from "../../../../../../../../../constants/credit-card.constant";
import { CardModel } from "../../../../../../../../../models/card.model";
import { CardItemPresentational } from "./CardItem.presentational";

interface Props {
  card: CardModel;
  handleDelete: (card: CardModel) => void;
  handleEdit: (card: CardModel) => void;
}

const CardItemContainer = ({ card, handleDelete, handleEdit }: Props) => {
  const issuerConfig = CARD_ISSUERS.find(issuer => issuer.brand === card.brand);

  return (
    <CardItemPresentational
      card={card}
      issuerConfig={issuerConfig!}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
    />
  );
};

export { CardItemContainer as CardItem };
