import React, { useCallback, useEffect, useMemo } from "react";

import { Link } from "@reach/router";
import { Drawer, DrawerHeader } from "~/apps/shared/components/drawer/drawer";

import { useUser } from "../../contexts/user.context";
import { RemoveDocumentAlertDialog } from "./remove-document-alert-dialog /remove-document-alert-dialog";
import { RemoveLoyaltyProgramAlertDialog } from "./remove-loyalty-program-alert-dialog/remove-loyalty-program-alert-dialog";
import { RemovePaymentMethodAlertDialog } from "./remove-payment-method-alert-dialog/remove-payment-method-alert-dialog";
import { styles } from "./styles";
import {
  UserProfileDrawerProvider,
  useUserProfileDrawer,
} from "./user-profile-drawer.context";
import { UserProfileDrawerBasicInformationSection } from "./views/default/basic-information-section/basic-information-section";
import { UserProfileDrawerCardsSection } from "./views/default/cards-section/cards-section";
import { UserProfileDrawerCreditsSection } from "./views/default/credits-section/credits-section";
import { UserProfileDrawerDocumentsSection } from "./views/default/documents-section/documents-section";
import { UserProfileDrawerLoyaltySection } from "./views/default/loyalty-section/loyalty-section";
import { UserProfileDrawerMemberGetMemberSection } from "./views/default/member-get-member-section/member-get-member-section";
import { UserProfileDrawerEditBasicInformation } from "./views/edit-basic-information/edit-basic-information";
import { UserProfileDrawerSaveDocument } from "./views/save-document/save-document";
import { UserProfileDrawerSaveLoyaltyProgram } from "./views/save-loyalty-program/save-loyalty-program";
import { UserProfileDrawerSavePaymentMethod } from "./views/save-payment-method/save-payment-method";

type Props = Pick<React.ComponentProps<typeof Drawer>, "onClose" | "open"> & {
  defaultView?: ReturnType<typeof useUserProfileDrawer>["view"];
};

const Component: React.FC<Props> = ({ defaultView, onClose, open }) => {
  const { logout } = useUser();
  const { changeView, view } = useUserProfileDrawer();

  const drawerTitle = useMemo(() => {
    switch (view) {
      case "edit-basic-information":
        return "Editar informações básicas";
      case "save-document":
        return "Salvar documento";
      case "save-loyalty-program":
        return "Salvar programa de fidelidade";
      case "save-payment-method":
        return "Salvar meio de pagamento";
      case "save-user-boss":
        return "Salvar supervisor";
      default:
        return "Meu perfil";
    }
  }, [view]);

  const handleBack = useCallback(() => {
    changeView("default");
  }, [changeView]);

  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown" | "none") => {
      if (!onClose) {
        changeView("default");

        return;
      }

      if (reason === "none") {
        if (view !== "default") {
          changeView("default");

          return;
        }

        onClose(event, "backdropClick");

        return;
      }

      changeView("default");

      onClose(event, reason);
    },
    [changeView, onClose, view],
  );

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  useEffect(() => {
    if (!defaultView || !open) {
      return;
    }

    changeView(defaultView);
  }, [changeView, defaultView, open]);

  return (
    <>
      <Drawer anchor="right" onClose={handleClose} open={open}>
        <DrawerHeader
          onClose={() => {
            handleClose({}, "none");
          }}
        >
          {drawerTitle}
        </DrawerHeader>
        <div css={styles.root}>
          {view === "default" ? (
            <>
              <UserProfileDrawerBasicInformationSection />
              <UserProfileDrawerMemberGetMemberSection />
              <UserProfileDrawerLoyaltySection />
              <UserProfileDrawerDocumentsSection />
              <UserProfileDrawerCardsSection />
              <UserProfileDrawerCreditsSection />
              {/* <UserProfileDrawerUserBossSection /> */}
              <div css={styles.section.root}>
                <div css={styles.section.header.root}>
                  <span css={styles.section.header.title}>Segurança</span>
                  <Link css={styles.section.header.link} to="/password/update">
                    Alterar senha
                  </Link>
                </div>
              </div>
              <div css={styles.section.root}>
                <div css={styles.section.header.root}>
                  <span css={styles.section.header.title}>Encerrar sessão</span>
                  <button
                    css={styles.section.header.button}
                    onClick={async () => {
                      await handleLogout();
                    }}
                  >
                    Sair
                  </button>
                </div>
              </div>
            </>
          ) : null}
          {view === "edit-basic-information" ? (
            <UserProfileDrawerEditBasicInformation back={handleBack} />
          ) : null}
          {view === "save-document" ? (
            <UserProfileDrawerSaveDocument back={handleBack} />
          ) : null}
          {view === "save-loyalty-program" ? (
            <UserProfileDrawerSaveLoyaltyProgram back={handleBack} />
          ) : null}
          {view === "save-payment-method" ? (
            <UserProfileDrawerSavePaymentMethod back={handleBack} />
          ) : null}
          {/* {view === "save-user-boss" ? (
            <UserProfileDrawerSaveUserBoss back={handleBack} />
          ) : null} */}
        </div>
      </Drawer>
      <RemoveDocumentAlertDialog />
      <RemoveLoyaltyProgramAlertDialog />
      <RemovePaymentMethodAlertDialog />
    </>
  );
};

export const UserProfileDrawer: React.FC<Props> = ({ ...props }) => {
  return (
    <UserProfileDrawerProvider>
      <Component {...props} />
    </UserProfileDrawerProvider>
  );
};
