import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      marginLeft: "24px",
    }),
  },
  description: ({
    isDenied,
    isUnavailable,
  }: {
    isDenied?: boolean;
    isUnavailable?: boolean;
  }) =>
    css({
      alignItems: "center",
      color:
        isDenied || isUnavailable
          ? theme.colors.pink[500]
          : theme.colors.gray[700],
      display: "flex",
      flex: 1,
      fontWeight: 600,
      padding: "4px 16px 4px 0",
    }),
  icon: ({
    isDenied,
    isUnavailable,
  }: {
    isDenied?: boolean;
    isUnavailable?: boolean;
  }) =>
    css({
      alignItems: "center",
      backgroundColor: theme.colors.white,
      color:
        isDenied || isUnavailable
          ? theme.colors.pink[500]
          : theme.colors.gray[700],
      display: "flex",
      height: "26px",
      padding: "4px 0",
    }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  top: {
    root: css({
      display: "flex",
      gap: "8px",
    }),
  },
};
