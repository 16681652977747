import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import { useUserProfileDrawer } from "../user-profile-drawer.context";

export const RemovePaymentMethodAlertDialog: React.FC = () => {
  const queryClient = useQueryClient();

  const {
    deleteCard,
    errorOnFetchDeleteCard,
    isLoadingDeleteCard,
  } = useCards();

  const {
    closeRemovePaymentMethodAlertDialog,
    isRemovePaymentMethodAlertDialogOpen,
    selectedPaymentMethod,
  } = useUserProfileDrawer();

  const handleClose = useCallback(() => {
    closeRemovePaymentMethodAlertDialog();
  }, [closeRemovePaymentMethodAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!selectedPaymentMethod) {
      closeRemovePaymentMethodAlertDialog();

      return;
    }

    await deleteCard(selectedPaymentMethod.token);

    void invalidateBillingProfilePayableCardsForTravel(queryClient);

    closeRemovePaymentMethodAlertDialog();
  }, [
    closeRemovePaymentMethodAlertDialog,
    deleteCard,
    invalidateBillingProfilePayableCardsForTravel,
    selectedPaymentMethod,
  ]);

  useEffect(() => {
    if (!errorOnFetchDeleteCard) {
      return;
    }

    handleClose();
  }, [errorOnFetchDeleteCard, handleClose]);

  return (
    <AlertDialog
      confirmLabel="Remover"
      isLoading={isLoadingDeleteCard}
      message={`Deseja realmente remover o meio de pagamento${
        selectedPaymentMethod ? ` ${selectedPaymentMethod.description}` : null
      }?`}
      onClose={closeRemovePaymentMethodAlertDialog}
      onConfirm={async () => {
        await handleConfirm();
      }}
      open={isRemovePaymentMethodAlertDialogOpen}
      title="Remover meio de pagamento."
    />
  );
};
