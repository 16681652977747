import React, { useState } from "react";

import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text
} from "recharts";

import { toCurrency } from "@helpers";

import { colors } from "@theme";

import { SpentByHotelDestinationItem } from "../HotelInfo.types";

const colorsArr = [
  colors.scooter,
  colors.jungleGreen,
  colors.cerulean,
  colors.saffron,
  colors.brinkPink,
  colors.purple,
  colors.fireBush,
  colors.sunglo,
  colors.dodgerBlue,
  colors.punch
];

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
    destination
  } = props;

  return (
    <g>
      <Text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill={fill}
        width={160}
        style={{ fontSize: 14 }}
      >
        {destination}
      </Text>
      <Text
        x={cx}
        y={cy}
        dy={16}
        textAnchor="middle"
        fill="#999"
        width={160}
        style={{ fontSize: 12 }}
      >
        {`(${toCurrency(value)})`}
      </Text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

interface IProps {
  data: SpentByHotelDestinationItem[];
}

const SpentByDestinationChart: React.FC<IProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  function onPieEnter(_: any, index: number) {
    setActiveIndex(index);
  }

  return (
    <ResponsiveContainer width="100%" height={220}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          innerRadius={80}
          dataKey="total"
          nameKey="destination"
          onMouseEnter={onPieEnter}
        >
          {data.map((_: any, index: number) => (
            <Cell key={`cell-${index}`} fill={colorsArr[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { SpentByDestinationChart };
