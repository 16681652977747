import React, { useContext, useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { Flex, Text } from "@toolkit";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { darken } from "@material-ui/core";

import { CreditCardEditingDrawer } from "~/components/shared/credit-card-editing-drawer";

import { ContainedButton } from "@components/shared/buttons/contained-button";

import TITLES from "../../../../../../../constants/page-titles.constants";
import { CardCreation } from "../../../../../../../components/shared-logic/CardCreation/CardCreation";
import PageTitle from "../../../../../../../components/shared/PageTitle";
import Snackbar from "../../../../../../../components/shared/SnackBar";
import SpinnerPortal from "../../../../../../../components/shared/Spinner";
import { CardDeletionDialog } from "./CardDeletionDialog";
import { CreditCardsList } from "./cards-list/CreditCardsList";
import { SpecialCardsList } from "./cards-list/SpecialCardsList";
import {
  PaymentMethodsProvider,
  PaymentMethodsContext,
} from "./PaymentMethods.context";

const styles = {
  addButton: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
};

const PaymentMethods = () => {
  const {
    cards,
    vcns,
    isFormDrawerOpen,
    isLoading,
    snack,
    fetchCards,
    fetchVcn,
    handleOpenForm,
    handleCloseForm,
    onAfterCardSave,
    handleError,
    handleCloseSnack,
    toggleEditCardDrawerVisibility,
    isEditDrawerOpen,
    selectedToEdit,
    processCardEdit,
    isEditingCard,
  } = useContext(PaymentMethodsContext);

  useEffect(() => {
    fetchCards();
    fetchVcn();
  }, []);

  return (
    <div>
      <PageTitle title={TITLES.PAYMENT_METHODS} />
      <SpinnerPortal visible={isLoading} />
      <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
        <ContainedButton onClick={handleOpenForm} css={styles.addButton}>
          Adicionar meio de pagamento
        </ContainedButton>
        <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
          <Text fontSize={24} fontWeight="bold">
            Cartões de crédito
          </Text>
          {cards.length ? (
            <CreditCardsList />
          ) : (
            <div
              style={{ paddingLeft: ".5rem", fontSize: 14, color: "#4C5566" }}
            >
              Nenhum cartão de crédito cadastrado
            </div>
          )}
        </Flex>
        <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
          <Text fontSize={24} fontWeight="bold">
            Cartões especiais
          </Text>
          {vcns.length ? (
            <SpecialCardsList />
          ) : (
            <div
              style={{ paddingLeft: ".5rem", fontSize: 14, color: "#4C5566" }}
            >
              Nenhum cartão especial cadastrado
            </div>
          )}
        </Flex>
      </Flex>
      <CardDeletionDialog />
      <CardCreation
        open={isFormDrawerOpen}
        defaultOwnerType="client"
        showClientSettings={false}
        handleClose={handleCloseForm}
        onAfterSave={onAfterCardSave}
        onError={handleError}
      />
      <CreditCardEditingDrawer
        isOpen={isEditDrawerOpen}
        onClose={toggleEditCardDrawerVisibility}
        card={selectedToEdit}
        onSubmit={processCardEdit}
        isSubmitting={isEditingCard}
      />
      <Snackbar
        visible={snack.open}
        type={snack.type}
        message={snack.message}
        handleClose={handleCloseSnack}
      />
    </div>
  );
};

const Container = (_: RouteComponentProps<{}>) => (
  <PaymentMethodsProvider>
    <PaymentMethods />
  </PaymentMethodsProvider>
);

export { Container as PaymentMethods };
