import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    details: {
      details: css({
        margin: "unset",
        minWidth: "unset",
        padding: "unset",
      }),
      root: css({
        display: "flex",
        flex: 1,
        minWidth: "768px",
        "@media screen and (max-width: 768px)": {
          minWidth: "unset",
        },
      }),
    },
    root: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "24px",
      justifyContent: "space-between",
      width: "100%",
    }),
    summary: {
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "4px",
        minWidth: "512px",
        "@media screen and (max-width: 512px)": {
          minWidth: "unset",
        },
      }),
      title: css({
        fontWeight: 600,
        lineHeight: "28px",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0 24px",
    width: "100%",
  }),
};
