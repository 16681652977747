import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DrawerHeader from "@shared/DrawerHeader";
import { DrawerSpinner } from "@shared/DrawerSpinner";
import { css } from "@styled-system/css";

import { AdvancedExpenseRequestForm } from "./AdvancedExpenseRequestForm";

const styles = {
  drawer: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: 500
    }
  })
};

import { useAdvancedExpensesContext } from "../AdvancedExpenses.context";

const FormDrawer = () => {
  const {
    isLoading,
    drawerOpen,
    handleCloseForm
  } = useAdvancedExpensesContext();
  return (
    <Drawer
      css={styles.drawer}
      open={drawerOpen}
      onClose={handleCloseForm}
      anchor="right"
    >
      <DrawerHeader
        title="Solicitar adiantamento"
        handleClose={handleCloseForm}
      />
      {isLoading ? (
        <DrawerSpinner visible={isLoading} />
      ) : (
        <AdvancedExpenseRequestForm />
      )}
    </Drawer>
  );
};

export { FormDrawer as AdvancedExpenseDrawer };
