import React from "react";

import PointerIcon from "@material-ui/icons/RoomRounded";
import NegativeIcon from "@material-ui/icons/SentimentDissatisfiedRounded";
import PositiveIcon from "@material-ui/icons/SentimentVerySatisfiedRounded";
import { css } from "@styled-system/css";
import PropTypes from "prop-types";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { Desktop, TabletAndMobile } from "@shared/layout/Responsive";

import { ReviewGrades } from "@components/shared/hotel-review";

const styles = {
  title: css({
    textTransform: "capitalize"
  }),
  pointer: css({
    color: defaultTheme.subTextColor,
    fontSize: [1, 2],
    mr: "xsmall"
  }),
  positiveIcon: css({
    position: "relative",
    top: "3px",
    color: "success",
    fontSize: [2],
    mr: "xsmall"
  }),
  negativeIcon: css({
    position: "relative",
    top: "3px",
    color: defaultTheme.subTextColor,
    fontSize: [2],
    mr: "xsmall"
  })
};

const DoneReviewCard = ({ reviewData, initialDate, endDate }) => {
  return (
    <Box border="1px solid" borderColor="border" p={["medium", 24]} mb={[24]}>
      <Flex flexDirection={["column", "row"]} justifyContent="space-between">
        <Box mb={["medium", 0]}>
          <Box mb={["medium"]}>
            <Flex justifyContent="space-between">
              <Box>
                <Text fontSize={[2, 3]} fontWeight="bold" css={styles.title}>
                  {reviewData.hotelName}
                </Text>
                <Flex alignItems="center" pt={["xsmall", "small"]}>
                  <PointerIcon css={styles.pointer} />
                  <Text color={defaultTheme.subTextColor} fontSize={[0]}>
                    {reviewData.hotelCity}/{reviewData.hotelState} -
                    {reviewData.hotelCountry}
                  </Text>
                </Flex>
              </Box>
              <TabletAndMobile>
                <Text fontSize={[2]} fontWeight="bold">
                  Nota: {reviewData.generalGrade}
                </Text>
              </TabletAndMobile>
            </Flex>
          </Box>
          <Box mb={["medium"]}>
            <Flex alignItems="center">
              <Text fontSize={[0, 1]} lineHeight={["16px"]}>
                <PositiveIcon css={styles.positiveIcon} />-{" "}
                {reviewData.positiveReview}
              </Text>
            </Flex>
          </Box>
          <Box mb={["medium"]}>
            <Flex alignItems="center">
              <Text fontSize={[0, 1]} lineHeight={["16px"]}>
                <NegativeIcon css={styles.negativeIcon} />-{" "}
                {reviewData.negativeReview}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Text fontSize={[0, 1]} fontWeight="bold" mr="xsmall">
              Período de estadia:{" "}
            </Text>
            <Text fontSize={[0, 1]}>
              {initialDate} - {endDate}
            </Text>
          </Box>
        </Box>
        <Box ml={[0, "medium"]} minWidth={[150]} px={[0, "medium"]}>
          <Flex flexDirection={["column"]} alignItems={["flex-end"]}>
            <Desktop>
              <Box mb={["medium"]}>
                <Text fontSize={[3]}>Nota: {reviewData.generalGrade}</Text>
              </Box>
            </Desktop>
            <Box alignSelf={["center", "unset"]}>
              <ReviewGrades
                roomGrade={reviewData.roomGrade}
                treatmentGrade={reviewData.treatmentGrade}
                cleaningGrade={reviewData.cleaningGrade}
                locationGrade={reviewData.locationGrade}
                wifiGrade={reviewData.wifiGrade}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

DoneReviewCard.propTypes = {
  reviewData: PropTypes.object,
  initialDate: PropTypes.string,
  endDate: PropTypes.string
};

export { DoneReviewCard };
