import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryPresentationalServices } from "~/apps/corporate/components/itinerary-presentational-services/itinerary-presentational-services";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { OfferStatus } from "~/apps/shared/constants/enums";
import { OFFER_STATUS_COLOR } from "~/apps/shared/constants/offers.constants";

import { styles } from "./styles";
import {
  TravelPlanServicesCardTabs,
  TravelPlanServicesCardTabsSkeleton,
} from "./travel-plan-services-card-tabs/travel-plan-services-card-tabs";
import {
  useTravelPlanServicesCardDefaultTab,
  useTravelPlanServicesCardGuard,
  useTravelPlanServicesCardPresenter,
} from "./travel-plan-services-card.hook";

const TRAVEL_PLAN_SERVICES_CARD_STATUS_TRANSLATION = {
  [OfferStatus.APPROVAL_DECLINED]: "Ofertas com aprovação negada",
  [OfferStatus.BOOKING_FAILED]: "Ofertas com falha de emissão",
  [OfferStatus.BOOKING_PROCESSING]: "Ofertas processando emissão",
  [OfferStatus.CANCELED]: "Ofertas canceladas",
  [OfferStatus.CANCELING]: "Ofertas cancelando",
  [OfferStatus.DRAFT]: "Ofertas em rascunho",
  [OfferStatus.EMITTED]: "Ofertas emitidas",
  FRUSTRATED_PURCHASES: "Compras frustradas",
} as const;

export const TRAVEL_PLAN_SERVICES_CARD_TABS_TRANSLATION = {
  "plano-de-viagem": "Plano de Viagem",
  cancelamentos: "Cancelamentos",
} as const;

export type TravelPlanServicesCardTab = keyof typeof TRAVEL_PLAN_SERVICES_CARD_TABS_TRANSLATION;

type Props = {
  canAddOffers?: boolean;
};

const Component: React.FC<Props> = ({ canAddOffers }) => {
  const { error, loading } = useTravelPlanServicesCardGuard();
  const {
    travelPlanServicesCardPresenter,
  } = useTravelPlanServicesCardPresenter();

  const [selectedTab, setSelectedTab] = useState<TravelPlanServicesCardTab>(
    "plano-de-viagem",
  );

  useTravelPlanServicesCardDefaultTab({
    setSelectedTab,
  });

  if (error) {
    return <TravelPlanServicesCardError />;
  }

  if (loading) {
    return <TravelPlanServicesCardSkeleton />;
  }

  if (!travelPlanServicesCardPresenter) {
    return null;
  }

  const presentationalServices = travelPlanServicesCardPresenter.getPresentationalServices();
  const presentationalServicesCounts = travelPlanServicesCardPresenter.getPresentationalServicesCounts();

  return (
    <div css={styles.root}>
      <TravelPlanServicesCardTabs
        servicesCounts={presentationalServicesCounts}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        {presentationalServicesCounts[selectedTab] > 0 ? (
          Object.entries(presentationalServices[selectedTab])
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, groups]) => groups.length > 0)
            .map(([status, groups], index) => (
              <ItineraryPresentationalServices
                canAddOffers={canAddOffers}
                color={
                  status === "FRUSTRATED_PURCHASES"
                    ? OFFER_STATUS_COLOR.BOOKING_FAILED
                    : OFFER_STATUS_COLOR[status as OfferStatus]
                }
                groups={groups}
                isTravelPlan
                key={index}
                title={
                  TRAVEL_PLAN_SERVICES_CARD_STATUS_TRANSLATION[
                    status as OfferStatus
                  ]
                }
              />
            ))
        ) : (
          <p css={styles.services.empty}>
            Nenhum serviço encontrado para esse filtro.
          </p>
        )}
      </div>
    </div>
  );
};

export const TravelPlanServicesCard: React.FC<Props> = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <TravelPlanServicesCardError {...props} />}>
      <Component {...props} />
    </ErrorBoundary>
  );
};

const TravelPlanServicesCardError: React.FC = () => {
  return (
    <div css={styles.root}>
      <TravelPlanServicesCardTabs
        isDisabled
        selectedTab="plano-de-viagem"
        servicesCounts={{
          cancelamentos: 0,
          "plano-de-viagem": 0,
        }}
      />
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        <p css={styles.services.empty}>
          Ocorreu um erro. Tente novamente mais tarde.
        </p>
      </div>
    </div>
  );
};

const TravelPlanServicesCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <TravelPlanServicesCardTabsSkeleton />
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="14px" width="192px" />
          <Skeleton height="128px" width="100%" />
          <Skeleton height="128px" width="100%" />
        </div>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="128px" width="100%" />
        </div>
      </div>
    </div>
  );
};
