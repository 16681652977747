import React, { useCallback } from "react";
import { useQuery } from "react-query";

import { globalHistory, RouteComponentProps } from "@reach/router";
import * as userApi from "~/apps/corporate/apis/user.api";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import AsyncData from "~/apps/shared/components/async-data/async-data";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import {
  AUTHENTICATION_ERRORS,
  AUTHENTICATION_ERRORS_MESSAGES,
} from "~/apps/shared/constants/errors";

import { CircularSpinner } from "@toolkit/v2";

import { styles } from "./styles";

type Props = RouteComponentProps & {
  ssoToken?: string;
};

export const SSOLoginUniq: React.FC<Props> = ({ ssoToken }) => {
  const loginSSOUniq = useCallback(async (ssoToken: string) => {
    try {
      const result = await userApi.ssoLoginUniq(ssoToken);

      globalHistory.navigate(result.redirectUrl);
    } catch (err: any) {
      let error;

      if (err.response) {
        const { data } = err.response;

        if (data.message === AUTHENTICATION_ERRORS.SSO_CONFIGS_NOT_FOUND) {
          error = AUTHENTICATION_ERRORS.SSO_CONFIGS_NOT_FOUND;
        }

        if (data.message === AUTHENTICATION_ERRORS.AUTH_METHOD_NOT_ENABLED) {
          error = AUTHENTICATION_ERRORS.AUTH_METHOD_NOT_ENABLED;
        }
      }

      const errorMessage = error
        ? AUTHENTICATION_ERRORS_MESSAGES[error]
        : "Usuário ou cliente inválidos.";

      throw new Error(errorMessage);
    }
  }, []);

  const query = useQuery(
    ["ssoUniqLogin", { ssoToken }],
    () => {
      if (!ssoToken) {
        return;
      }

      return loginSSOUniq(ssoToken);
    },
    {
      enabled: !!ssoToken,
      retry: false,
    },
  );

  if (!ssoToken) {
    return null;
  }

  return (
    <>
      <PageTitle title={pageTitles.BASE_TITLE} />
      <div css={styles.root}>
        <AsyncData {...query}>
          <AsyncData.Loading>
            <CircularSpinner />
          </AsyncData.Loading>
          <AsyncData.Error>
            {(error) => (
              <ErrorPage
                message={error.message}
                title="Ops, aconteceu um erro."
              />
            )}
          </AsyncData.Error>
        </AsyncData>
      </div>
    </>
  );
};
