import React from "react";
import { Controller } from "react-hook-form";

import { css } from "@styled-system/css";
import { Input } from "~/apps/shared/components/input/input";

import { theme } from "@skin/v2";

import { Box, Flex, Text, Paragraph } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { EXPENSES_FAMILIES_TRANSLATION } from "@constants";

import { ColorPickerInput } from "@shared/color-picker/ColorPickerInput";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { useForm } from "@components/shared/form";
import { OutlinedSelect } from "@components/shared/outlined-select";

import * as expenseCategoryUtils from "../expenseCategories.utils";
import { useExpensesCategories } from "../ExpensesCategories.context";
import { categoryFormSchema } from "./category-form.schema";

const DEFAULT_COLOR = "#FCB900";

const styles = {
  button: {
    save: css({
      display: "flex",
      justifyContent: "center",
      width: "8.5rem",
    }),
  },
  buttonsRoot: css({
    display: "flex",
    gap: "1.5rem",
    alignSelf: "flex-end",
    padding: "1.5rem",
  }),
  input: css({
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    width: "100%",
    height: "3rem",
    padding: "0.75rem 1rem",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.5rem",
  }),
};

export const CategoryForm: React.FC = () => {
  const {
    handleCloseForm,
    handleFormSubmit,
    selectedCategory,
  } = useExpensesCategories();

  const { control, submitForm, errors } = useForm({
    defaultValues: {
      categoryName: selectedCategory.name || "",
      color: selectedCategory.color || DEFAULT_COLOR,
      expenseFamily: selectedCategory.expenseFamily
        ? {
            label:
              EXPENSES_FAMILIES_TRANSLATION[selectedCategory.expenseFamily],
            value: selectedCategory.expenseFamily,
          }
        : "",
    },
    onSubmit: handleFormSubmit,
    schema: categoryFormSchema,
  });

  const [color, setColor] = React.useState<string>(
    selectedCategory.color || DEFAULT_COLOR,
  );

  const handleChangeColor = (colorResult: string) => {
    setColor(colorResult);
  };

  return (
    <form onSubmit={submitForm} style={{ height: "100%" }}>
      <Flex flexDirection="column" height="100%">
        <Box p={24} flex={1}>
          <Box maxWidth={350}>
            <Box mb="1rem">
              <Text fontSize="1.125rem" fontWeight="bold">
                Grupo de categoria
              </Text>
            </Box>
            <Controller
              as={<OutlinedSelect />}
              id="expenseFamily"
              name="expenseFamily"
              control={control}
              placeholder="Selecione uma categoria"
              error={!!errors.expenseFamily}
              options={expenseCategoryUtils.transformEnumIntoOptions(
                EXPENSES_FAMILIES_TRANSLATION,
              )}
              isClearable={false}
              isDisabled={selectedCategory.expenseFamily}
            />
            {errors.expenseFamily && (
              <Text
                mt="5px"
                ml="5px"
                fontSize={13}
                fontWeight="bold"
                color="cancel"
                letterSpacing="0.04rem"
              >
                Campo obrigatório
              </Text>
            )}
          </Box>
          <Box mt="1.5rem">
            <Box mb="1rem">
              <Text fontSize="1.125rem" fontWeight="bold">
                Nome da categoria
              </Text>
            </Box>
            <Controller
              as={<Input css={styles.input} />}
              id="categoryName"
              name="categoryName"
              control={control}
              placeholder="Digite o nome que deseja atribuir"
              error={!!errors.categoryName}
              errorMessage={errors.categoryName && errors.categoryName.message}
            />
          </Box>
          <Box mt="1.5rem">
            <Box mb="1rem">
              <Paragraph fontSize={1} fontWeight="bold">
                Cor da categoria{" "}
                <Text fontSize={1} color={defaultTheme.subTextColor}>
                  (opcional)
                </Text>
              </Paragraph>
            </Box>
            <Controller
              as={
                <ColorPickerInput color={color} onChange={handleChangeColor} />
              }
              id="color"
              name="color"
              control={control}
              placeholder="Cor da categoria"
            />
          </Box>
        </Box>
        <Flex css={styles.buttonsRoot}>
          <ContainedButton type="submit" css={styles.button.save}>
            Salvar
          </ContainedButton>
          <OutlinedButton onClick={handleCloseForm}>Cancelar</OutlinedButton>
        </Flex>
      </Flex>
    </form>
  );
};
