import React from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { CountryRestrictionDto } from "~/apps/corporate/dtos/country-restriction.dto";
import { AsyncData } from "~/apps/shared/components/async-data/async-data";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import {
  Form,
  useForm,
  useFormContext,
} from "~/apps/shared/components/form/form";
import { RichTextEditor } from "~/apps/shared/components/rich-text-editor/rich-text-editor";
import { CountryRestrictionType } from "~/apps/shared/constants/enums";
import { FormModeType } from "~/apps/shared/types";

import { CircularSpinner } from "@toolkit/v2";
import { Button } from "@toolkit/v2";

import { CountryRestrictionMessage } from "../../../../../../../components/country-restriction-message/country-restriction-message";
import { useGetCountryRestriction } from "../../hooks/use-country-restrictions-data";
import { useCountryRestrictionMutation } from "../../hooks/use-country-restrictions-data";
import { CountryRestrictionTypeRadioGroup } from "./country-restriction-type-radio-group/country-restriction-type-radio-group";
import { CountrySelect } from "./country-select";
import { parseDefaultValues } from "./restriction-form.helper";
import countryRestrictionSchema from "./restriction-form.schema";
import { CountryRestrictionFormData } from "./restriction-form.types";
import { styles } from "./styles";

const DEFAULT_MESSAGE_WHEN_EMPTY = [
  {
    children: [
      {
        text:
          "Digite algo no editor acima para ver uma pré-visualização da mensagem.",
      },
    ],
  },
] as const;

type RestrictionFormProps = {
  handleClose: () => void;
  mode: FormModeType | null;
  token?: string;
};

export const RestrictionForm: React.FC<RestrictionFormProps> = ({
  handleClose,
  mode,
  token,
}) => {
  return (
    <div css={styles.root}>
      <DrawerHeader onClose={handleClose}>
        {mode === "create"
          ? "Criar Restrição de Viagem"
          : "Editar Restrição de Viagem"}
      </DrawerHeader>
      {mode === "create" ? (
        <RestrictionFormContainer
          defaultValues={parseDefaultValues({ mode: "create" })}
          handleClose={handleClose}
          mode="create"
        />
      ) : null}
      {mode === "edit" && token ? (
        <RestrictionFormLoader mode="edit" token={token}>
          {(data) => (
            <RestrictionFormContainer
              defaultValues={parseDefaultValues({ data, mode: "edit" })}
              handleClose={handleClose}
              mode="edit"
            />
          )}
        </RestrictionFormLoader>
      ) : null}
    </div>
  );
};

type RestrictionFormContainerProps = {
  defaultValues: CountryRestrictionFormData;
  handleClose: () => void;
  mode: FormModeType;
};

const RestrictionFormContainer: React.FC<RestrictionFormContainerProps> = ({
  defaultValues,
  handleClose,
  mode,
}) => {
  const { showSnackMessage } = useApplication();

  const mutation = useCountryRestrictionMutation(
    mode,
    defaultValues.countryRestrictionToken,
  );

  const context = useForm<CountryRestrictionFormData>({
    defaultValues: defaultValues,
    onSubmit: async function onSubmit(data) {
      const response = await mutation.mutateAsync(data);

      if (response.error) {
        showSnackMessage(
          `${response.error.title}: ${response.error.description}`,
          "error",
        );

        return;
      }

      showSnackMessage("Restrição de viagem salva com sucesso!", "success");

      handleClose();
    },
    schema: countryRestrictionSchema,
  });

  return (
    <Form css={styles.form.root} context={context}>
      <div css={styles.form.body.root}>
        <CountrySelect
          defaultValue={defaultValues.country}
          onSelect={(item) => {
            context.setValue("country", item);
          }}
        />
        <Form.Field
          as={<CountryRestrictionTypeRadioGroup />}
          label="Tipo de restrição"
          name="restrictionType"
        />
        <RestrictionFormMessage />
      </div>
      <footer css={styles.footer.root}>
        <Button disabled={context.submitting} onClick={context.submitForm}>
          Salvar
        </Button>
        <Button fill="outlined" onClick={handleClose} type="button">
          Cancelar
        </Button>
      </footer>
    </Form>
  );
};

const RestrictionFormMessage: React.FC = () => {
  const { watch } = useFormContext();

  const watchFields = watch(["message", "restrictionType"], {
    message: DEFAULT_MESSAGE_WHEN_EMPTY,
    restrictionType: CountryRestrictionType.ALLOW_WITH_WARNING,
  });

  return (
    <div css={styles.form.body.message.root}>
      <Form.Field
        as={<RichTextEditor />}
        label="Digite um comentário que poderá ser visualizado pelo usuário"
        name="message"
      />
      <Form.Label css={styles.form.body.message.label}>
        Exemplo de como irá aparecer para o usuário
      </Form.Label>
      <CountryRestrictionMessage
        message={watchFields.message}
        restrictionType={watchFields.restrictionType}
      />
    </div>
  );
};

type RestrictionFormLoaderProps = {
  children: (data: CountryRestrictionDto) => JSX.Element;
  mode: FormModeType;
  token: string;
};

const RestrictionFormLoader = ({
  children,
  mode,
  token,
}: RestrictionFormLoaderProps) => {
  const query = useGetCountryRestriction({
    mode,
    countryRestrictionToken: token,
  });

  return (
    <AsyncData {...query}>
      <AsyncData.Success>{(data) => children(data)}</AsyncData.Success>
      <AsyncData.Loading>
        <CircularSpinner />
      </AsyncData.Loading>
    </AsyncData>
  );
};
