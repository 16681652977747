import React, { useCallback, useEffect } from "react";

import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { styles } from "./styles";

export const TravelPlanTravelDeletionAlertDialog: React.FC = () => {
  const { infoModel } = useItineraryContainer();

  const {
    closeTravelDeletionAlertDialog,
    deleteTravel,
    errorOnFetchDeleteTravel,
    isLoadingDeleteTravel,
    isTravelDeletionAlertDialogOpen,
  } = useItineraryScreen();

  const handleClose = useCallback(() => {
    closeTravelDeletionAlertDialog();
  }, [closeTravelDeletionAlertDialog]);

  const handleConfirm = useCallback(async () => {
    await deleteTravel();
  }, [deleteTravel]);

  useEffect(() => {
    if (!errorOnFetchDeleteTravel) {
      return;
    }

    handleClose();
  }, [errorOnFetchDeleteTravel, handleClose]);

  return (
    <AlertDialog
      confirmLabel="Excluir"
      isLoading={isLoadingDeleteTravel}
      message={
        <span>
          Deseja realmente excluir a viagem
          <strong css={styles.strong}>
            {infoModel ? ` "${infoModel.getTravelName()}"` : null}
          </strong>
          ?
        </span>
      }
      onClose={() => {
        handleClose();
      }}
      onConfirm={async () => {
        await handleConfirm();
      }}
      open={isTravelDeletionAlertDialogOpen}
      title="Excluir viagem."
    />
  );
};
