import { APPROVAL_REVIEW_MESSAGES } from "~/apps/shared/constants";

import { BookingTarget } from "../models/booking-target.model";
import { ItineraryServiceModel } from "../models/itinerary/itinerary-service.model";
import { UserModel } from "../models/user.model";

export const buildTravelData = (
  bookingTarget: BookingTarget,
  user: UserModel,
) => {
  const userToken = user.getUserToken();

  const travelData = {
    booker_token: userToken,
    travel_name: "",
    traveler_token: bookingTarget.userToken,
  };

  return travelData;
};

export const getNoAvailabilityDenyMessage = (
  notAvailableServices: ItineraryServiceModel[],
) => {
  const noAvailabilityDenyMessage = notAvailableServices.reduce(
    (prev, curr) => {
      prev.push(`\n ${curr.getFormattedItineraryServiceHeaderName()};`);

      return prev;
    },
    [APPROVAL_REVIEW_MESSAGES.NO_AVAILABILITY_DECLINE] as string[],
  );

  return noAvailabilityDenyMessage.join("");
};
