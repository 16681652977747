import { getClientToken } from "@helpers/user.helper";

import { CardEditRequest, CardEditResponse } from "~/dtos/card.dto";

import { VcnDisplay } from "@models/vcn.model";

import * as cardApi from "../../../../../../../apis/card.api";
import * as vcnApi from "../../../../../../../apis/vcn.api";
import { ERROR } from "../../../../../../../constants";
import { LegacyCardModel } from "../../../../../../../models/card.model";
import { CustomError } from "../../../../../../../types";

export async function listCardsByClient(): Promise<{
  data?: LegacyCardModel[];
  error?: CustomError;
}> {
  const result: { data?: LegacyCardModel[]; error?: CustomError } = {};

  try {
    result.data = await cardApi.listCardsByClient();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    }
  }

  return result;
}

export async function listVcnsByClient(): Promise<{
  data?: VcnDisplay[];
  error?: CustomError;
}> {
  const result: { data?: VcnDisplay[]; error?: CustomError } = {};

  try {
    result.data = await vcnApi.listVcnsByClient(getClientToken());
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    }
  }

  return result;
}

export async function listCardsByUser(
  userToken: string
): Promise<{
  data?: LegacyCardModel[];
  error?: CustomError;
}> {
  const result: { data?: LegacyCardModel[]; error?: CustomError } = {};

  try {
    result.data = await cardApi.listCardsByUser(userToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CARDS_ERROR;
    }
  }

  return result;
}

export async function deleteCard(
  cardToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await cardApi.deleteCard(cardToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CARD_DELETION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CARD_DELETION_ERROR;
    }
  }

  return result;
}

export async function editCard(
  cardToken: string,
  data: CardEditRequest
): Promise<{ data?: CardEditResponse; error?: CustomError }> {
  const result: { data?: CardEditResponse; error?: CustomError } = {};

  try {
    result.data = await cardApi.editCard(cardToken, data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CARD_EDITION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CARD_EDITION_ERROR;
    }
  }

  return result;
}
