import { ERROR } from "@constants";

import * as flightApi from "@apis/flight.api";

import type { RecentFlightSearch } from "../../NewTrip.types";

export async function getUserRecentFlights() {
  const result: { data?: RecentFlightSearch[]; error?: any } = {};

  try {
    result.data = await flightApi.getUserRecentFlights();
  } catch (error: any) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    }
  }

  return result;
}
