import { ItineraryServicesPresenter } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";
import { toCurrency } from "~/apps/shared/utils/to-currency";

class ItineraryPriceBreakdownPresenter {
  constructor(private readonly servicesPresenter: ItineraryServicesPresenter) {}

  public getFormattedProcessableServicesTotalPrice() {
    return toCurrency(this.getProcessableServicesTotalPrice());
  }

  public getFormattedReservedServicesTotalPrice() {
    return toCurrency(this.getReservedServicesTotalPrice());
  }

  public getProcessableServices() {
    return this.servicesPresenter
      .getAllServices()
      .filter((service) => service.isProcessable());
  }

  private getProcessableServicesTotalPrice() {
    return this.getProcessableServices().reduce((prev, curr) => {
      return prev + curr.getPrice();
    }, 0);
  }

  public getReservedServices() {
    return this.servicesPresenter
      .getAllServices()
      .filter((service) => service.isReserved());
  }

  private getReservedServicesTotalPrice() {
    return this.getReservedServices().reduce((prev, curr) => {
      return prev + curr.getPrice();
    }, 0);
  }

  public hasProcessableServices() {
    return this.getProcessableServices().length > 0;
  }

  public hasReservedServices() {
    return this.getReservedServices().length > 0;
  }

  public showConvertedPriceWarning() {
    return this.getProcessableServices().some((service) =>
      service.isNotInBRL(),
    );
  }
}

export class ItineraryPriceBreakdownPresenterFactory {
  static create(servicesPresenter: ItineraryServicesPresenter) {
    return new ItineraryPriceBreakdownPresenter(servicesPresenter);
  }
}
