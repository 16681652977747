import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { FlightSearchInfo } from "~/apps/corporate/models/flight.model";
import { RangeFilter } from "~/apps/shared/components/range-filter/range-filter";
import { BOUND_RANGE_TYPES, BOUND_TYPES } from "~/apps/shared/constants";
import { secondsToHHMM } from "~/apps/shared/utils/seconds-to-hh-mm";

import { Button } from "@toolkit/v2";

import { RangeFilter as RangeFilterType } from "../../../flights.types";
import { styles } from "./styles";

const tabs = [
  { label: "Saída", value: "departure" },
  { label: "Chegada", value: "arrival" },
] as const;

type BoundFilter = {
  arrivalRange: RangeFilterType;
  departureRange: RangeFilterType;
};

type Props = {
  flightInfo: FlightSearchInfo | null;
  handleBoundFilterChange: (
    boundType: string,
    rangeType: string,
    range: number[],
  ) => void;
  hoursRange: RangeFilterType;
  inboundFilter: BoundFilter;
  loading?: boolean;
  outboundFilter: BoundFilter;
};

export const HourFilter: React.FC<Props> = ({
  flightInfo,
  handleBoundFilterChange,
  hoursRange: [min, max],
  inboundFilter,
  loading,
  outboundFilter,
}) => {
  const [view, setView] = useState<typeof tabs[number]["value"]>(tabs[0].value);

  const handleRangeDragEnd = useCallback(
    (rangeType: string, boundType: string) => (range: [number, number]) => {
      handleBoundFilterChange(boundType, rangeType, range);
    },
    [],
  );

  const handleViewChange = useCallback((newTab: typeof view) => {
    setView(newTab);
  }, []);

  return (
    <div css={styles.root}>
      <div css={styles.tabs.root}>
        {tabs.map((tab, index) => (
          <div css={styles.tabs.tab.root} key={tab.value}>
            {index !== 0 ? <div css={styles.tabs.tab.divider} /> : null}
            <Button
              css={styles.tabs.tab.button}
              fill={view === tab.value ? "filled" : "outlined"}
              onClick={() => {
                handleViewChange(tab.value);
              }}
            >
              {tab.label}
            </Button>
          </div>
        ))}
      </div>
      {view === "arrival" ? (
        <>
          <span css={styles.title}>
            Horário de chegada em{" "}
            {!loading ? (
              flightInfo ? (
                flightInfo.destinationCity
              ) : null
            ) : (
              <Skeleton height="16px" width="64px" />
            )}
          </span>
          <RangeFilter
            defaultValue={outboundFilter.arrivalRange}
            disabled={loading}
            formatFunc={secondsToHHMM}
            max={max}
            min={min}
            onRangeDragEnd={handleRangeDragEnd(
              BOUND_RANGE_TYPES.arrival,
              BOUND_TYPES.outbound,
            )}
            step={15 * 60}
          />
          {flightInfo && !flightInfo.oneway ? (
            <>
              <span css={styles.title}>
                Horário de chegada em{" "}
                {!loading ? (
                  flightInfo.originCity
                ) : (
                  <Skeleton height="16px" width="64px" />
                )}
              </span>
              <RangeFilter
                defaultValue={inboundFilter.arrivalRange}
                disabled={loading}
                formatFunc={secondsToHHMM}
                min={min}
                max={max}
                onRangeDragEnd={handleRangeDragEnd(
                  BOUND_RANGE_TYPES.arrival,
                  BOUND_TYPES.inbound,
                )}
                step={15 * 60}
              />
            </>
          ) : null}
        </>
      ) : null}
      {view === "departure" ? (
        <>
          <span css={styles.title}>
            Horário de saída de{" "}
            {!loading ? (
              flightInfo ? (
                flightInfo.originCity
              ) : null
            ) : (
              <Skeleton height="16px" width="64px" />
            )}
          </span>
          <RangeFilter
            defaultValue={outboundFilter.departureRange}
            disabled={loading}
            formatFunc={secondsToHHMM}
            max={max}
            min={min}
            onRangeDragEnd={handleRangeDragEnd(
              BOUND_RANGE_TYPES.departure,
              BOUND_TYPES.outbound,
            )}
            step={15 * 60}
          />
          {flightInfo && !flightInfo.oneway ? (
            <>
              <span css={styles.title}>
                Horário de saída de{" "}
                {!loading ? (
                  flightInfo.destinationCity
                ) : (
                  <Skeleton height="16px" width="64px" />
                )}
              </span>
              <RangeFilter
                defaultValue={inboundFilter.departureRange}
                disabled={loading}
                formatFunc={secondsToHHMM}
                min={min}
                max={max}
                onRangeDragEnd={handleRangeDragEnd(
                  BOUND_RANGE_TYPES.departure,
                  BOUND_TYPES.inbound,
                )}
                step={15 * 60}
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
