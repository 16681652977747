import React from "react";

import { HotelPolicyRule } from "~/apps/corporate/models/policy.model";
import { CurrencyInput } from "~/apps/shared/components/currency-input/currency-input";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { LocationAutocomplete } from "~/apps/shared/components/location-autocomplete/location-autocomplete";
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikErrors,
  FormikTouched,
} from "formik";

import { Button } from "@toolkit/v2";

import { IHotelPolicies } from "../../../travel-policies.types";
import { styles } from "./styles";

type AdressResult = {
  city: string;
  country: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  placeId: string;
  state: string;
};

export const InternationalHotelMaxValuePerLocationArray: React.FC<{
  errors: FormikErrors<{
    internationalHotelRules: HotelPolicyRule[];
  }>;
  handleBlur: (e: any) => void;
  onHotelRuleAdditionClick: (
    arrayHelpers: FieldArrayRenderProps,
    policyName: IHotelPolicies,
  ) => (e: any) => void;
  onHotelRuleLocationChange: (
    arrayHelpers: any,
    policyName: IHotelPolicies,
    inputId: string,
    index: number,
    inputName: string,
  ) => (result: AdressResult | null) => void;
  onHotelRuleMaxValueChange: (
    policyName: IHotelPolicies,
    ruleId: string,
    inputName: string,
  ) => (value: number | null) => void;
  onHotelRuleRemovalClick: (
    arrayHelpers: any,
    policyName: IHotelPolicies,
    ruleId: string,
    index: number,
  ) => (e: any) => void;
  touched: FormikTouched<{
    internationalHotelRules?: HotelPolicyRule[];
  }>;
  values: { internationalHotelRules: HotelPolicyRule[] };
}> = ({
  errors,
  handleBlur,
  onHotelRuleAdditionClick,
  onHotelRuleLocationChange,
  onHotelRuleMaxValueChange,
  onHotelRuleRemovalClick,
  touched,
  values,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Política de valor máximo</span>
      <FieldArray
        name="internationalHotelRules"
        render={(arrayHelpers) => (
          <ul css={styles.list}>
            {values.internationalHotelRules.map((rule, index) => (
              <li css={styles.item.root} key={rule.policyRuleToken}>
                <div css={styles.item.input.root}>
                  <div css={styles.item.input.container}>
                    <span css={styles.item.input.label}>Cidade</span>
                    <LocationAutocomplete
                      css={styles.item.input.input}
                      id={rule.policyRuleToken}
                      locationType="(cities)"
                      name="internationalHotelRules[${index}].locationName"
                      onSelect={onHotelRuleLocationChange(
                        arrayHelpers,
                        "internationalHotelPolicy",
                        rule.policyRuleToken,
                        index,
                        `internationalHotelRules[${index}].locationName`,
                      )}
                      placeholder="Local (cidade)"
                      value={rule.locationName}
                    />
                  </div>
                  <InputErrorMessage>
                    {errors.internationalHotelRules &&
                    errors.internationalHotelRules[index] &&
                    errors.internationalHotelRules![index]!.locationName &&
                    touched.internationalHotelRules
                      ? errors.internationalHotelRules![index]!.locationName
                      : undefined}
                  </InputErrorMessage>
                </div>
                <div css={styles.item.input.root}>
                  <div css={styles.item.input.container}>
                    <span css={styles.item.input.label}>Diária Máxima</span>
                    <CurrencyInput
                      css={styles.item.input.input}
                      formatOnNull={false}
                      id={rule.policyRuleToken + "max-value"}
                      name={`internationalHotelRules[${index}].maxValue`}
                      onBlur={handleBlur}
                      onChange={onHotelRuleMaxValueChange(
                        "internationalHotelPolicy",
                        rule.policyRuleToken,
                        `internationalHotelRules[${index}].maxValue`,
                      )}
                      placeholder="R$ 250,00"
                      value={rule.maxValue}
                    />
                  </div>
                  <InputErrorMessage>
                    {errors.internationalHotelRules &&
                    errors.internationalHotelRules[index] &&
                    errors.internationalHotelRules![index]!.maxValue &&
                    touched.internationalHotelRules
                      ? errors.internationalHotelRules![index]!.maxValue
                      : undefined}
                  </InputErrorMessage>
                </div>
                <div css={styles.item.actions}>
                  <Button
                    onClick={onHotelRuleRemovalClick(
                      arrayHelpers,
                      "internationalHotelPolicy",
                      rule.policyRuleToken,
                      index,
                    )}
                    shape="icon"
                  >
                    <Icon use="x" />
                  </Button>
                </div>
              </li>
            ))}
            <Button
              css={styles.button}
              onClick={onHotelRuleAdditionClick(
                arrayHelpers,
                "internationalHotelPolicy",
              )}
            >
              Adicionar cidade
            </Button>
          </ul>
        )}
      />
    </div>
  );
};
