import React from "react";

import {
  BusStretchInfo,
  BusStretchInfoSkeleton,
} from "./bus-stretch-info/bus-stretch-info";

type Props = {
  arrival: {
    address: string;
    time: string;
  };
  busName: string;
  carrierLogo: string;
  carrierName: string;
  departure: {
    address: string;
    time: string;
  };
  duration: string;
  seat?: string;
};

export const ItineraryBusSegment: React.FC<Props> = ({
  arrival,
  busName,
  carrierLogo,
  carrierName,
  departure,
  duration,
  seat,
}) => {
  return (
    <BusStretchInfo
      arrival={arrival}
      busName={busName}
      carrierLogo={carrierLogo}
      carrierName={carrierName}
      departure={departure}
      duration={duration}
      seat={seat}
    />
  );
};

export const ItineraryBusSegmentSkeleton: React.FC = () => {
  return (
    <>
      <BusStretchInfoSkeleton />
    </>
  );
};
