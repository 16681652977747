import * as areasApi from "~/apps/corporate/apis/areas.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import { ERROR } from "~/apps/shared/constants/errors";

import {
  CreateAreaForm,
  FetchAreaResult,
  FetchClientAreasResult,
  UpdateAreaForm,
} from "./areas.types";

export const fetchClientAreas = async () => {
  const result: FetchClientAreasResult = {};

  try {
    result.data = await areasApi.fetchClientAreas();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR;
    }
  }

  return result;
};

export const fetchArea = async (companyAreaToken: string) => {
  const result: FetchAreaResult = {};

  try {
    result.data = await areasApi.fetchArea(companyAreaToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_AREA_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_AREA_ERROR;
    }
  }

  return result;
};

export const fetchUsers = async (search: string) => {
  const result: any = {};

  try {
    result.data = await userApi.getUsersByName(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_USERS_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_USERS_FETCH_ERROR;
    }
  }

  return result;
};

export const createNewArea = async (area: CreateAreaForm) => {
  const result: any = {};

  try {
    result.data = await areasApi.createArea(area);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_AREA_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_AREA_ERROR;
    }
  }

  return result;
};

export const updateArea = async (area: UpdateAreaForm) => {
  const result: any = {};

  try {
    result.data = await areasApi.updateArea(area);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_AREA_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_AREA_ERROR;
    }
  }

  return result;
};

export const addUserToArea = async (
  userToken: string,
  companyAreaToken: string,
) => {
  const result: any = {};

  try {
    result.data = await areasApi.addUserToArea(userToken, companyAreaToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_USER_TO_AREA_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_USER_TO_AREA_ERROR;
    }
  }

  return result;
};

export const removeUserFromArea = async (userAreaToken: string) => {
  const result: any = {};

  try {
    result.data = await areasApi.removeUserFromArea(userAreaToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_USER_FROM_AREA_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_USER_FROM_AREA_ERROR;
    }
  }

  return result;
};

export const updateAreaDisplayName = async (
  clientConfigToken: string,
  areaName: string,
) => {
  const result: any = {};

  try {
    result.data = await areasApi.updateAreaDisplayNameFromClientConfig(
      clientConfigToken,
      areaName,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    }
  }

  return result;
};

export const updateCompanyAreaEnabled = async (
  clientConfigToken: string,
  enabled: boolean,
) => {
  const result: any = {};

  try {
    result.data = await areasApi.updateCompanyAreaEnabledFromClientConfig(
      clientConfigToken,
      enabled,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    }
  }

  return result;
};
