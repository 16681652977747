import * as yup from "yup";

export const saveLoyaltyProgramSchema = yup.object().shape({
  code: yup.string().required("Esse campo é obrigatório."),
  name: yup.string().required("Preencha o código do programa."),
});

export type SaveLoyaltyProgramSchema = yup.InferType<
  typeof saveLoyaltyProgramSchema
>;
