import { DrawerHeader as NewDrawerHeader } from "~/apps/shared/components/drawer/drawer";

import React from "react";
import { PropTypes } from "prop-types";

const DrawerHeader = (props) => {
  const { classes, handleClose, headerStyles, title } = props;

  return (
    <NewDrawerHeader
      className={classes}
      css={headerStyles}
      onClose={handleClose}
    >
      {title}
    </NewDrawerHeader>
  );
};

DrawerHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerStyles: PropTypes.object,
  title: PropTypes.string,
};

export default DrawerHeader;
