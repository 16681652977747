import React, { useMemo } from "react";

import { ItineraryServiceInfoModel } from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPolicyForOffer } from "~/apps/corporate/models/travel.model";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { useLocation } from "~/apps/shared/hooks/use-location";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { ItineraryServiceDetailsDrawerCreditCardInfo } from "../itinerary-service-details-drawer-credit-card-info/itinerary-service-details-drawer-credit-card-info";
import { ItineraryHotelDetailsDrawerHotel } from "./itinerary-hotel-details-drawer-hotel/itinerary-hotel-details-drawer-hotel";
import { ItineraryHotelDetailsDrawerRoom } from "./itinerary-hotel-details-drawer-room/itinerary-hotel-details-drawer-room";
import { ItineraryHotelDetailsDrawerTravelers } from "./itinerary-hotel-details-drawer-travelers/itinerary-hotel-details-drawer-travelers";
import { styles } from "./styles";

type Props = {
  closeServiceDetailsDrawer: () => void;
  itineraryServiceInfoModel: ItineraryServiceInfoModel | null;
  policy: TravelPolicyForOffer | null;
  servicePresenter: HotelItineraryServicePresenter;
};

export const ItineraryHotelDetailsDrawer: React.FC<Props> = ({
  closeServiceDetailsDrawer,
  itineraryServiceInfoModel,
  policy,
  servicePresenter,
}) => {
  const {
    location: { pathname },
  } = useLocation();

  const creditCard = useMemo(() => {
    if (!itineraryServiceInfoModel) {
      return null;
    }

    return itineraryServiceInfoModel.getCreditCard();
  }, [itineraryServiceInfoModel]);

  const isTravelPlan = useMemo(() => {
    return pathname.includes("/travel-plan");
  }, [pathname]);

  const showDoNotWasteTimeMessage = !servicePresenter.isEmitted();

  const showOutOfPolicyInfo = useMemo(
    () => (policy ? policy.outOfPolicy : false),
    [policy],
  );

  return (
    <>
      <DrawerHeader onClose={closeServiceDetailsDrawer}>
        Detalhes do hotel
      </DrawerHeader>
      <div css={styles.body.root}>
        <ItineraryHotelDetailsDrawerHotel servicePresenter={servicePresenter} />
        <ItineraryHotelDetailsDrawerRoom servicePresenter={servicePresenter} />
        {isTravelPlan ? (
          <ItineraryHotelDetailsDrawerTravelers
            servicePresenter={servicePresenter}
          />
        ) : null}
      </div>
      <footer css={styles.footer.root}>
        {showOutOfPolicyInfo ? (
          <span css={styles.footer.policy}>Fora da política.</span>
        ) : null}
        <span css={styles.footer.warning}>Preço total previsto:</span>
        <span css={styles.footer.price}>
          {formatCurrency(servicePresenter.getPrice())}
        </span>
        {showDoNotWasteTimeMessage ? (
          <span css={styles.footer.warning}>
            Preços e disponibilidade podem mudar. Não perca tempo.
          </span>
        ) : null}
        {creditCard ? (
          <ItineraryServiceDetailsDrawerCreditCardInfo
            creditCard={creditCard}
          />
        ) : null}
      </footer>
    </>
  );
};
