import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  categoryOptions: {
    checked: boolean;
    id: string | number;
    label: string;
  }[];
  disabled: boolean;
  handleCategoryFilterChange: (
    e: React.ChangeEvent<any>,
    checked: boolean,
  ) => void;
  handleToggleCategoryExplanation: () => void;
};

export const ClassesFilter: React.FC<Props> = ({
  categoryOptions,
  disabled,
  handleCategoryFilterChange,
  handleToggleCategoryExplanation,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.heading.root}>
        <span css={styles.heading.title}>Classes</span>
        <button
          css={styles.heading.info}
          onClick={() => {
            handleToggleCategoryExplanation();
          }}
        >
          Entenda as classes
        </button>
      </div>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {categoryOptions.map((categoryOption) => (
          <CheckboxGroup.Item
            checked={categoryOption.checked}
            disabled={disabled}
            id={categoryOption.id.toString()}
            key={categoryOption.id}
            name={categoryOption.label}
            onChange={handleCategoryFilterChange}
            value={categoryOption.checked}
          >
            <span css={styles.checkbox.group.item.label}>
              {categoryOption.label}
            </span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
