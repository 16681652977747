import { css } from "@styled-system/css";

export const styles = {
  root: css({
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    padding: "4px 0",
  }),
  tag: ({ color }: { color: string }) =>
    css({
      border: `1px solid ${color}`,
      borderRadius: "16px",
      color,
      fontSize: "0.625rem",
      fontWeight: 600,
      padding: "4px 8px",
      whiteSpace: "nowrap",
    }),
};
