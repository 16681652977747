import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.pink[500],
    cursor: "pointer",
    fontFamily: theme.fonts.inter,
    fontWeight: 600,
    textDecoration: "underline",
  }),
  root: css({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
};
