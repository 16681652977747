import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    alignItems: "center",
    aspectRatio: 1,
    borderRadius: "50%",
    color: theme.colors.white,
    display: "flex",
    flexShrink: 0,
    height: "57px",
    justifyContent: "center",
    width: "57px",
  }),
  center: {
    control: {
      error: css({
        padding: "0 16px",
      }),
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        "@media screen and (max-width: 425px)": {
          flex: "unset",
          minWidth: "unset",
          width: "100%",
        },
      }),
      skeleton: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        minWidth: "288px",
        padding: "12px 16px",
        "@media screen and (max-width: 425px)": {
          flex: "unset",
          minWidth: "unset",
          width: "100%",
        },
      }),
    },
    left: {
      control: {
        location: {
          destination: {
            container: css({
              border: `1px solid ${theme.colors.gray[100]}`,
              borderLeft: "none",
              borderRadius: "0 calc(57px / 2) calc(57px / 2) 0",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }),
          },
          input: {
            input: css({
              width: "100%",
              ".Mui": {
                "&FormLabel-root": {
                  color: theme.colors.gray[100],
                  fontFamily: theme.fonts.inter,
                  "&.Mui-focused": {},
                },
                "&InputBase-input": {
                  fontFamily: theme.fonts.inter,
                  padding: "26px 16px 12px 12px",
                  textOverflow: "ellipsis",
                },
                "&InputLabel-outlined": {
                  "&.MuiInputLabel-shrink": {
                    color: theme.colors.gray[700],
                    fontWeight: 700,
                    transform: "translate(12px, 12px) scale(0.75)",
                  },
                },
                "&OutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              },
            }),
            loading: css({
              flexShrink: 0,
            }),
            root: css({
              alignItems: "center",
              borderRadius: "8px",
              color: theme.colors.gray[700],
              display: "flex",
              paddingLeft: "16px",
              width: "100%",
            }),
          },
          origin: {
            container: css({
              border: `1px solid ${theme.colors.gray[100]}`,
              borderRadius: "calc(57px / 2) 0 0 calc(57px / 2)",
              borderRight: "none",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }),
          },
          suggestion: {
            button: ({
              isDisabled,
              isHighlighted,
            }: {
              isDisabled?: boolean;
              isHighlighted: boolean;
            }) =>
              css({
                backgroundColor: "transparent",
                border: "none",
                color: theme.colors.gray[500],
                cursor: "pointer",
                overflow: "hidden",
                padding: "12px 16px",
                transition: "all 0.15s ease-in-out",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                ":not(:disabled)": {
                  ":hover": {
                    backgroundColor: theme.colors.background.gray,
                    color: theme.colors.gray[700],
                  },
                },
              }),
            item: css({
              color: theme.colors.gray[500],
              display: "flex",
              fontSize: "0.875rem",
              padding: "12px 16px",
            }),
          },
        },
      },
      root: css({
        display: "flex",
        width: "100%",
      }),
      swap: {
        button: css({
          aspectRatio: 1,
          backgroundColor: "transparent",
          border: "1px solid",
          borderColor: theme.colors.gray[100],
          borderRadius: "50%",
          color: theme.colors.gray[500],
          cursor: "pointer",
          flexShrink: 0,
          padding: "6px",
          transition: "all 0.15s ease-in-out",
          ":hover": {
            borderColor: theme.colors.gray[300],
            color: theme.colors.gray[700],
          },
        }),
        root: css({
          alignItems: "center",
          borderBottom: `1px solid ${theme.colors.gray[100]}`,
          borderTop: `1px solid ${theme.colors.gray[100]}`,
          display: "flex",
          justifyContent: "center",
          height: "59px",
        }),
      },
    },
    right: {
      control: {
        dates: {
          arrow: {
            divisor: css({
              color: theme.colors.gray[100],
            }),
            root: css({
              alignItems: "center",
              display: "flex",
              gap: "12px",
            }),
          },
          container: css({
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: "calc(57px / 2)",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }),
          root: css({
            ".Date": {
              "&Input": {
                "&_input": {
                  padding: "18.5px 0 18.5px 0 !important",
                },
              },
              "&RangePicker": {
                "&Input": {
                  border: "none !important",
                  borderRadius: "0 !important",
                  padding: "0 16px !important",
                },
              },
            },
            ".SingleDatePicker": {
              "&Input": {
                border: "none !important",
                borderRadius: "0 !important",
                padding: "0 16px !important",
              },
            },
          }),
        },
      },
      root: css({
        alignItems: "flex-start",
        display: "flex",
        gap: "8px",
        "@media screen and (max-width: 768px)": {
          width: "100%",
        },
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      gap: "8px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        flexDirection: "column",
      },
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  top: {
    control: {
      cabin: css({
        "& > :first-of-type": {
          minHeight: "auto",
          border: "none",
          padding: "14px 16px 14px 16px",
          "& > :first-of-type": {
            "& > :first-of-type": {
              color: theme.colors.gray[700],
              fontFamily: theme.fonts.inter,
              fontWeight: 600,
              height: "16px",
            },
          },
        },
      }),
      error: css({
        padding: "0 16px 12px 16px",
      }),
      label: css({
        color: theme.colors.gray[700],
        flexShrink: 0,
        fontWeight: 400,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "8px",
        display: "flex",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          borderColor: theme.colors.gray[100],
        },
        "@media screen and (max-width: 768px)": {
          minWidth: "unset",
          width: "100%",
        },
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      width: "100%",
    }),
    switch: {
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        gap: "12px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "fit-content",
        ":hover": {
          borderColor: theme.colors.gray[100],
        },
      }),
      text: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
    },
  },
};
