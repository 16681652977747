import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate } from "@reach/router";
import { BusSearchInfo } from "~/apps/corporate/models/bus.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { Button } from "@toolkit/v2";

import {
  useBusesPage,
  useBusesSearchActions,
  useBusesSearch,
} from "../buses.context";
import { styles } from "./styles";

export const BusesHeader: React.FC = () => {
  const { isFakeLoading, isLoading } = useBusesPage();
  const { searchInfo } = useBusesSearch();

  const navigateBack = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <div css={styles.root}>
      <Button css={styles.left.button} shape="icon" onClick={navigateBack}>
        <Icon size={16} use="arrow-left" />
      </Button>
      <div css={styles.right.root}>
        {isFakeLoading || isLoading ? (
          <BusesHeaderSearchSkeleton />
        ) : searchInfo ? (
          <BusesHeaderSearchInfo searchInfo={searchInfo} />
        ) : null}
      </div>
    </div>
  );
};

type BusesHeaderSearchInfoProps = {
  searchInfo: BusSearchInfo;
};

const BusesHeaderSearchInfo: React.FC<BusesHeaderSearchInfoProps> = ({
  searchInfo,
}) => {
  const { handleEditClick } = useBusesSearchActions();

  const {
    destinationCityName,
    destinationStateCode,
    originCityName,
    originStateCode,
  } = searchInfo;

  const { departureDate, returnDate } = searchInfo;

  const formattedDepartureDate = moment(departureDate).format("DD MMM");
  const formattedReturnDate = returnDate
    ? moment(returnDate).format("DD MMM")
    : formattedDepartureDate;

  return (
    <>
      <div css={styles.right.search.root}>
        <span css={styles.right.search.field}>{formattedDepartureDate}</span>
        <span css={styles.right.search.value}>
          {originCityName} - {originStateCode}
        </span>
      </div>
      <div css={styles.right.search.root}>
        <span css={styles.right.search.field}>{formattedReturnDate}</span>
        <span css={styles.right.search.value}>
          {destinationCityName} - {destinationStateCode}
        </span>
      </div>
      <Button onClick={handleEditClick} shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};

const BusesHeaderSearchSkeleton: React.FC = () => {
  return (
    <>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <Button disabled shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};
