import { parseSingleAdvancedExpenseDto } from "~/parsers/advanced-expense.parser";
import moment from "moment";

import { convertDateToUTC } from "~/helpers/date.helpers";

import {
  ExpenseResponseItem,
  ExpenseReportResponseItem
} from "../dtos/expenses.dto";
import { Expense } from "../models/expense.model";

export function expenseFactory(expenseItem: ExpenseResponseItem): Expense {
  return {
    id: expenseItem.id,
    expenseToken: expenseItem.expense_token,
    description: expenseItem.description,
    date: moment.utc(convertDateToUTC(expenseItem.date)),
    value: expenseItem.value,
    currency: expenseItem.currency,
    exchangeRate: expenseItem.exchange_rate,
    canceled: expenseItem.canceled,
    canceledAt: expenseItem.canceled_at
      ? moment.utc(convertDateToUTC(expenseItem.canceled_at))
      : null,
    canceledBy: expenseItem.canceled_by,
    approvedValue: expenseItem.approved_value,
    receiptFile: expenseItem.receipt_file,
    originLocale: expenseItem.origin_locale,
    originName: expenseItem.origin_name,
    originCityId: expenseItem.origin_city_id,
    originCity: expenseItem.origin_city,
    originCountry: expenseItem.origin_country,
    destinationLocale: expenseItem.destination_locale,
    destinationName: expenseItem.destination_name,
    destinationCityId: expenseItem.destination_city_id,
    destinationCity: expenseItem.destination_city,
    destinationCountry: expenseItem.destination_country,
    international: expenseItem.international,
    cabinClass: expenseItem.cabin_class,
    oneway: expenseItem.oneway,
    hotelLatitude: expenseItem.hotel_latitude,
    hotelLongitude: expenseItem.hotel_longitude,
    hotelCity: expenseItem.hotel_city,
    hotelState: expenseItem.hotel_state,
    hotelCountry: expenseItem.hotel_country,
    hotelContinent: expenseItem.hotel_continent,
    hotelSearch: expenseItem.hotel_search,
    hotelPlaceId: expenseItem.hotel_place_id,
    advanceDays: expenseItem.advance_days,
    locationSearch: expenseItem.location_search,
    originLocationSearch: expenseItem.origin_location_search,
    originCoordinates: expenseItem.origin_coordinates,
    destinationLocationSearch: expenseItem.destination_location_search,
    destinationCoordinates: expenseItem.destination_coordinates,
    distance: expenseItem.distance,
    mealType: expenseItem.meal_type,
    participantsQuantity: expenseItem.participants_quantity,
    travelerToken: expenseItem.traveler_token,
    expenseReportToken: expenseItem.expense_report_token,
    status: expenseItem.status,
    createdAt: moment(expenseItem.created_at),
    startDate: expenseItem.start_date
      ? moment.utc(convertDateToUTC(expenseItem.start_date))
      : null,
    endDate: expenseItem.end_date
      ? moment.utc(convertDateToUTC(expenseItem.end_date))
      : null,
    expenseCategory: expenseItem.expense_category,
    expenseCategoryToken: expenseItem.expense_category_token,
    expenseCategoryName: expenseItem.expense_category_name,
    expenseCategoryColor: expenseItem.expense_category_color,
    paymentMethod: expenseItem.payment_method,
    expenseFamily: expenseItem.expense_family
  };
}

export function expenseReportFactory(
  expenseReportItem: ExpenseReportResponseItem
): any {
  return {
    id: expenseReportItem.id,
    expenseReportToken: expenseReportItem.expense_report_token,
    travelToken: expenseReportItem.travel_token,
    travelerToken: expenseReportItem.traveler_token,
    description: expenseReportItem.description,
    status: expenseReportItem.status,
    createdAt: moment(expenseReportItem.created_at),
    closedAt: expenseReportItem.closed_at
      ? moment(expenseReportItem.closed_at)
      : null,
    clientToken: expenseReportItem.client_token,
    costCenterToken: expenseReportItem.cost_center_token,
    companyAreaToken: expenseReportItem.company_area_token,
    billingProfileToken: expenseReportItem.billing_profile_token,
    projectToken: expenseReportItem.project_token,
    reportType: expenseReportItem.report_type,
    expenses: expenseReportItem.expenses.map(expenseItem =>
      expenseFactory(expenseItem)
    ),
    advancedExpenses:
      expenseReportItem.expense_advances &&
      expenseReportItem.expense_advances.map(advance =>
        parseSingleAdvancedExpenseDto(advance)
      )
  };
}
