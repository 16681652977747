import React from "react";

import { css } from "emotion";

import { MEDIA_QUERIES } from "@constants";

import { SearchInput } from "@shared/inputs";

import { StatusTab } from "./StatusTab";
import { useTravelApprovalsContext } from "./TravelApprovals.context";

const styles = {
  inputContainer: css({
    width: 300,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      width: "100%"
    }
  })
};

const TravelApprovalsHeader = () => {
  const {
    status,
    handleChangeSearch,
    handleTabClick
  } = useTravelApprovalsContext();

  return (
    <div>
      <StatusTab selected={status} handleChange={handleTabClick} />
      <div className={styles.inputContainer}>
        <SearchInput
          inputProps={{
            label: "Buscar",
            placeholder: "Por nome da viagem, viajante ou id",
            style: { width: "100%" }
          }}
          handleChange={handleChangeSearch}
        />
      </div>
    </div>
  );
};

export { TravelApprovalsHeader };
