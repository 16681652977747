import React from "react";

import { GetUserCompanyEventDto } from "sm-types/sm-company-events";

import { EventSettingsManage } from "./manage";
import { EventSettingsPrivacy } from "./privacy";
import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventSettings: React.VFC<Props> = ({ event }) => {
  return (
    <div css={styles.root}>
      <EventSettingsPrivacy event={event} />
      <EventSettingsManage event={event} />
    </div>
  );
};
