import { removeSpecialCharacters } from "@helpers";
import { getAuthorizationHeader } from "@helpers/user.helper";

import { api } from "@apis/interceptors";

export const getAirports = term => {
  return api
    .request({
      url: `/booking/airports/search`,
      timeout: 25000,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: {
        search: removeSpecialCharacters(term)
      }
    })
    .then(({ data }) => {
      return buildAirportsData(data);
    })
    .catch(handleError);
};

export const getHotels = term => {
  return getHotelsSuggestions(term).catch(handleError);
};

export const getHotelsSuggestions = term => {
  return api
    .request({
      url: `/booking/autosuggest/hotel/${removeSpecialCharacters(term)}`,
      timeout: 25000,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => {
      return buildHotelsData(data);
    });
};

const buildAirportsData = ({ location_list }) => {
  return location_list.map(airport => {
    return {
      ...airport,
      label: airport.PlaceName
    };
  });
};

const buildHotelsData = ({ location_list }) => {
  return location_list.map(location => {
    return {
      ...location,
      label: location.name
    };
  });
};

function handleError(error) {
  if (error.response) {
    if (error.response.status === 400) {
      throw {
        title: "Error",
        description: "Nenhum resultado encontrado"
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    if (error.code === "ECONNABORTED" && !error.request.status) {
      throw {
        title: "Error",
        description: "Erro, verifique sua conexão com a internet."
      };
    }
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  throw { title: "title", description: "Erro inesperado" };
}
