import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  buttons: {
    root: css({
      flexDirection: "row",
      justifyContent: "space-between",
      ["@media (max-width: 1500px)"]: {
        flexDirection: "column",
        gap: "1rem",
      },
    }),
    add: css({
      width: "fit-content",
      height: "3rem",
      backgroundColor: theme.colors.pink[500],
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2),
      },
    }),
    excel: css({
      height: "3rem",
    }),
  },
};
