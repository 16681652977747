import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  amenities: css({
    padding: "4px 8px",
  }),
  card: {
    bottom: {
      button: css({
        justifyContent: "center",
      }),
    },
    center: {
      address: css({
        color: theme.colors.gray[700],
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        paddingBottom: "12px",
      }),
    },
    close: css({
      borderRadius: "50%",
      height: "32px",
      opacity: 0.75,
      position: "absolute",
      right: "8px",
      top: "8px",
      width: "32px",
      ":hover": {
        opacity: 1,
      },
    }),
    root: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow:
        "rgba(50, 50, 93, 0.06) 0px 4px 8px 0px, rgba(121, 135, 157, 0.1) 0px 0px 4px 0px, rgba(50, 50, 93, 0.06) 0px 2px 6px 0px",
      color: theme.colors.gray[700],
      display: "flex",
      flexDirection: "column",
      fontSize: "1rem",
      fontFamily: theme.fonts.inter,
      overflow: "hidden",
      padding: "8px",
      position: "relative",
      right: "160px",
      transition: "all 0.15s ease 0s",
      width: "288px",
      zIndex: 1,
    }),
    top: {
      logo: css({
        height: "24px",
        objectFit: "contain",
        width: "fit-content",
      }),
      root: css({}),
    },
  },
  marker: css({
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  }),
};
