import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  form: {
    body: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    button: css({
      justifyContent: "center",
      outline: "none",
      ":focus": {
        backgroundColor: theme.colors.gray[700],
      },
    }),
    error: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.pink[500]}`,
      borderRadius: "8px",
      color: theme.colors.pink[500],
      display: "flex",
      gap: "12px",
      minHeight: "48px",
      justifyContent: "center",
      fontSize: "0.875rem",
      fontWeight: 600,
      padding: "12px 16px",
      "& > :first-of-type": {
        flexShrink: 0,
      },
    }),
    field: {
      error: css({
        fontWeight: 600,
        marginTop: "4px",
      }),
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        transition: "border-color 0.15s ease-in-out",
        width: "100%",
        ":focus": {
          borderColor: theme.colors.gray[300],
        },
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
  },
  footer: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
    sso: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      outline: "none",
      textDecoration: "underline",
      transition: "color 0.15s ease-in-out",
      ":focus, :hover": {
        color: theme.colors.black,
      },
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.background.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    margin: "auto",
    maxWidth: "352px",
    padding: "32px",
    width: "100%",
  }),
};
