import { css } from "@styled-system/css";

import { theme } from "@skin/v2";
import { darken } from "@material-ui/core";

export const styles = {
  autocomplete: {
    item: {
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: isHighlighted
            ? theme.colors.background.gray
            : "transparent",
          color: theme.colors.gray[500],
          cursor: isDisabled ? "default" : "pointer",
          display: "flex",
          flexDirection: "column",
          fontSize: "0.875rem",
          gap: "4px",
          padding: "12px 16px",
        }),
    },
  },
  form: {
    body: {
      message: {
        label: css({
          marginTop: "24px",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        gap: "24px",
        marginTop: "24px",
        padding: "0 24px",
        width: "100%",
      }),
    },
    input: {
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        width: "100%",
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        lineHeight: "28px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "space-between",
    }),
    users: {
      item: {
        root: css({
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
          width: "fit-content",
          marginTop: "1rem",
          padding: "0.5rem",
          borderRadius: "0.5rem",
          backgroundColor: theme.colors.background.gray,
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
          fontWeight: 500,
          lineHeight: "0.875rem",
        }),
        iconContainer: css({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "1.25rem",
          height: "1.25rem",
          backgroundColor: theme.colors.gray[500],
          borderRadius: "0.25rem",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: darken(theme.colors.gray[500], 0.5),
            transition: "200ms",
          },
        }),
        icon: css({
          color: theme.colors.white,
          fontSize: "1.125rem",
        }),
      },
      list: css({
        gap: "0.5rem",
        flexWrap: "wrap",
      }),
    },
  },
  footer: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "40px 24px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
  }),
};
