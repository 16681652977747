import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  capabilities: {
    master: css({
      marginTop: "16px",
    }),
    toggle: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[700],
      cursor: "pointer",
      display: "flex",
      fontSize: "0.875rem",
      fontWeight: 500,
      gap: "8px",
      marginTop: "16px",
    }),
  },
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "0 auto",
    marginTop: "16px",
    width: "100%",
  }),
  role: {
    radio: {
      badge: css({
        borderRadius: "6px",
        fontSize: "0.75rem",
        fontWeight: 500,
        padding: "2px 12px",
      }),
      group: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        ".MuiFormControlLabel-root": {
          alignItems: "center",
          display: "flex",
          gap: "8px",
          height: "auto",
          margin: "unset",
        },
      }),
      item: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
  }),
};
