import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  option: {
    root: css({
      alignItems: "center",
      color: theme.colors.gray[700],
      display: "flex",
      gap: "8px",
    }),
  },
};
