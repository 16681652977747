import React, { useRef, useEffect } from "react";

import DateRangeIcon from "@material-ui/icons/DateRange";
import HotelIcon from "@material-ui/icons/Hotel";
import { css } from "@styled-system/css";
import { FormikErrors } from "formik";
import { Moment } from "moment";
import { darken } from "polished";
import { theme } from "smartrips-skin";
import uuid from "uuid";

import { Box, Flex } from "@toolkit";

import { IBookingTarget } from "~/models/booker-target.model";

import { UserModel } from "@models/user.model";

import { StContainedButton } from "@shared/buttons";
import { ResponsiveRangeDatePicker } from "@shared/date-pickers";
import { GoogleLocationAutocomplete } from "@shared/new-trip/GoogleLocationAutocomplete";

import { GuestNumberSelect } from "../GuestNumberSelect/GuestNumberSelect";
import { HotelSearch } from "../NewTrip.types";
import { useOfferSearch } from "../offer-search";
import { TravelerAutocomplete } from "../TravelerAutocomplete";

const styles = {
  getDateStyles: (error: boolean) =>
    css({
      height: 58,
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border",
      borderBottomColor: error ? "cancel" : "border",
      borderRadius: 1,
      padding: "medium",
      transition: "all 0.2s",

      ["&:hover"]: {
        borderColor: darken(0.2, theme.colors.border),
        borderBottomColor: error ? "cancel" : darken(0.2, theme.colors.border)
      }
    }),
  button: css({
    py: "small",
    px: "medium",
    lineHeight: "24px"
  }),
  error: css({
    display: "block",
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  })
};

interface Props {
  values: HotelSearch;
  errors: FormikErrors<HotelSearch>;
  focusedDateInput: string | null;
  handleLocationChange: (location: any) => void;
  handleDateChange: (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  handleTravelerChange: (traveler: IBookingTarget) => void;
  handleFocusChange: (focused: string) => void;
  handleChange: (e: any) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const HotelFormPresentational = ({
  values,
  errors,
  focusedDateInput,
  handleLocationChange,
  handleDateChange,
  handleTravelerChange,
  handleFocusChange,
  handleChange,
  handleSubmit
}: Props) => {
  const { getBookingTargets } = useOfferSearch();

  const locationSearchEl = useRef<HTMLInputElement>();
  const locationValue =
    values.location && values.location.search ? values.location.search : "";

  useEffect(() => {
    if (locationSearchEl.current) {
      locationSearchEl.current.focus();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Box p={["20px 8px 16px", "large"]}>
        <Flex mb="small" flexDirection={["column", "row"]}>
          <GoogleLocationAutocomplete
            value={locationValue}
            handleSelect={handleLocationChange}
            icon={HotelIcon}
            inputProps={{
              placeholder: "Endereço, cidade ou ponto de interesse",
              ref: locationSearchEl,
              error: errors.location
            }}
          />
        </Flex>
        <Flex flexDirection={["column", "row"]}>
          <Box width="100%" maxWidth={["100%", "50%"]} mb={["small", 0]}>
            <Flex
              alignItems="center"
              id="new-trip-picker"
              css={styles.getDateStyles(!!errors.startDate || !!errors.endDate)}
            >
              <ResponsiveRangeDatePicker
                datePickerProps={{
                  startDate: values.startDate,
                  startDateId: uuid(),
                  startDatePlaceholderText: "Check-in",
                  endDate: values.endDate,
                  endDateId: uuid(),
                  endDatePlaceholderText: "Check-out",
                  onDatesChange: handleDateChange,
                  focusedInput: focusedDateInput,
                  onFocusChange: handleFocusChange,
                  disableScroll: false,
                  noBorder: true,
                  hideKeyboardShortcutsPanel: true,
                  anchorDirection: "left",
                  customInputIcon: <DateRangeIcon />
                }}
              />
            </Flex>
            {errors.startDate ? (
              <span css={styles.error}>{errors.startDate}</span>
            ) : null}
            {errors.endDate ? (
              <span css={styles.error}>{errors.endDate}</span>
            ) : null}
          </Box>
          <Box width="8px" />
          <GuestNumberSelect
            value={values.totalGuests}
            handleChange={handleChange}
          />
        </Flex>
      </Box>

      <Box
        p={["24px 8px 16px", "large"]}
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius="0 0 5px 5px"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box width="100%" maxWidth={["100%", 300]} mb={["medium", 0]}>
            <TravelerAutocomplete
              customGetBookingTargets={getBookingTargets}
              error={errors.traveler}
              onChange={handleTravelerChange}
              traveler={values.traveler}
            />
          </Box>
          <Box width="100%" maxWidth={["100%", 150.38]}>
            <StContainedButton size="small" type="submit" css={styles.button}>
              Pesquisar hotéis
            </StContainedButton>
          </Box>
        </Flex>
      </Box>
    </form>
  );
};

export { HotelFormPresentational };
