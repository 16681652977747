import React, { useEffect, useMemo } from "react";
import { TagProvider, useTagContext } from "@shared/tags/tags.context";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@shared/inputs";

const TagSelect = props => {
  const { tagOptions, loadTagOptions } = useTagContext();

  useEffect(() => {
    loadTagOptions();
  }, []);

  const selectedTag = useMemo(() => {
    return props.value
      ? tagOptions.find(tag => tag.tagToken === props.value)
      : null;
  }, [props.value]);

  const handleTagChange = data => {
    const tagToken = data && data.tagToken ? data.tagToken : "";
    props.handleChange(tagToken);
  };

  return (
    <div>
      <FormControl
        style={{ width: "240px", height: "45px", marginRight: "2rem" }}
      >
        <div>
          <Select
            aria-label="Buscar etiqueta"
            placeholder="Buscar etiqueta"
            value={selectedTag}
            options={tagOptions}
            onChange={handleTagChange}
          />
        </div>
      </FormControl>
    </div>
  );
};

const TagSelectContainer = props => {
  return (
    <TagProvider>
      <TagSelect {...props} />
    </TagProvider>
  );
};

export { TagSelectContainer as TagSelect };
