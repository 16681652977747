import React, { useMemo } from "react";
import { IoWarningOutline } from "react-icons/io5";

import { FlightFareBound } from "~/apps/corporate/components/flight-fare-bound/flight-fare-bound";
import { FlightJourneyDetailsCollapsibleCard } from "~/apps/corporate/components/flight-journey-details-collapsible-card/flight-journey-details-collapsible-card";
import { ItineraryServiceInfoModel } from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { FlightItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPolicyForOffer } from "~/apps/corporate/models/travel.model";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { ItineraryServiceDetailsDrawerCreditCardInfo } from "../itinerary-service-details-drawer-credit-card-info/itinerary-service-details-drawer-credit-card-info";
import { styles } from "./styles";

type Props = {
  closeServiceDetailsDrawer: () => void;
  itineraryServiceInfoModel: ItineraryServiceInfoModel | null;
  policy: TravelPolicyForOffer | null;
  servicePresenter: FlightItineraryServicePresenter;
};

export const ItineraryFlightDetailsDrawer: React.FC<Props> = ({
  closeServiceDetailsDrawer,
  itineraryServiceInfoModel,
  policy,
  servicePresenter,
}) => {
  const creditCard = useMemo(() => {
    if (!itineraryServiceInfoModel) {
      return null;
    }

    return itineraryServiceInfoModel.getCreditCard();
  }, [itineraryServiceInfoModel]);

  const inboundJourney = servicePresenter.getInboundJourney();
  const outboundJourney = servicePresenter.getOutboundJourney();

  const showDoNotWasteTimeMessage = !servicePresenter.isEmitted();

  const showOutOfPolicyInfo = useMemo(
    () => (policy ? policy.outOfPolicy : false),
    [policy],
  );

  return (
    <>
      <DrawerHeader onClose={closeServiceDetailsDrawer}>
        Detalhes do voo
      </DrawerHeader>
      <div css={styles.body.root}>
        <div css={styles.body.bounds.root}>
          <div css={styles.body.bounds.bound.root}>
            <span css={styles.body.bounds.bound.title}>Detalhes da ida</span>
            <FlightJourneyDetailsCollapsibleCard
              arrival={outboundJourney.arrival}
              departure={outboundJourney.departure}
              isBaggageVisible
              segments={outboundJourney.segments}
            />
          </div>
          {inboundJourney ? (
            <div css={styles.body.bounds.bound.root}>
              <span css={styles.body.bounds.bound.title}>
                Detalhes da volta
              </span>
              <FlightJourneyDetailsCollapsibleCard
                arrival={inboundJourney.arrival}
                departure={inboundJourney.departure}
                isBaggageVisible
                segments={inboundJourney.segments}
              />
            </div>
          ) : null}
        </div>
        <div css={styles.body.fares.root}>
          {outboundJourney.fareFamily ? (
            <div css={styles.body.fares.fare.root}>
              <span css={styles.body.fares.fare.title}>Tarifa da ida</span>
              <div css={styles.body.fares.fare.card}>
                {(() => {
                  const firstSegment = outboundJourney.segments[0];

                  const hasCarryOnBaggage =
                    firstSegment.baggage?.carryOn.included;
                  const hasCheckedBaggage =
                    firstSegment.baggage?.checked.included;

                  const baggage = hasCarryOnBaggage
                    ? firstSegment.baggage?.carryOn
                    : hasCheckedBaggage
                    ? firstSegment.baggage?.checked
                    : null;

                  return (
                    <FlightFareBound
                      bound={{
                        cabinClass: firstSegment.cabinClass,
                        carrier: {
                          code: firstSegment.carrier.code,
                        },
                        destination: {
                          code: outboundJourney.arrival.airportCode,
                        },
                        origin: {
                          code: outboundJourney.departure.airportCode,
                        },
                      }}
                      boundFamily={{
                        baggage: {
                          pieces: baggage ? baggage.quantity : 0,
                          weight: baggage ? baggage.weight.value : 0,
                        },
                        family: outboundJourney.fareFamily,
                      }}
                      flightIsInternational={servicePresenter.isInternational()}
                    />
                  );
                })()}
              </div>
            </div>
          ) : null}
          {inboundJourney && inboundJourney.fareFamily ? (
            <div css={styles.body.fares.fare.root}>
              <span css={styles.body.fares.fare.title}>Tarifa da volta</span>
              <div css={styles.body.fares.fare.card}>
                {(() => {
                  const firstSegment = inboundJourney.segments[0];

                  const hasCarryOnBaggage =
                    firstSegment.baggage?.carryOn.included;
                  const hasCheckedBaggage =
                    firstSegment.baggage?.checked.included;

                  const baggage = hasCarryOnBaggage
                    ? firstSegment.baggage?.carryOn
                    : hasCheckedBaggage
                    ? firstSegment.baggage?.checked
                    : null;

                  return (
                    <FlightFareBound
                      bound={{
                        cabinClass: firstSegment.cabinClass,
                        carrier: {
                          code: firstSegment.carrier.code,
                        },
                        destination: {
                          code: inboundJourney.arrival.airportCode,
                        },
                        origin: {
                          code: inboundJourney.departure.airportCode,
                        },
                      }}
                      boundFamily={{
                        baggage: {
                          pieces: baggage ? baggage.quantity : 0,
                          weight: baggage ? baggage.weight.value : 0,
                        },
                        family: inboundJourney.fareFamily,
                      }}
                      flightIsInternational={servicePresenter.isInternational()}
                    />
                  );
                })()}
              </div>
            </div>
          ) : null}
        </div>

        {outboundJourney.segments[0].baggage ? null : (
          <div css={styles.warning.root}>
            <IoWarningOutline css={styles.warning.icon} />
            <p>
              As informações sobre a(s) tarifa(s) podem estar incompletas. Por
              favor, verifique os detalhes ou entre em contato para mais
              informações.
            </p>
          </div>
        )}
      </div>
      <footer css={styles.footer.root}>
        {showOutOfPolicyInfo ? (
          <span css={styles.footer.policy}>Fora da política.</span>
        ) : null}
        <span css={styles.footer.warning}>Preço total previsto:</span>
        <span css={styles.footer.price}>
          {formatCurrency(servicePresenter.getPrice())}
        </span>
        {showDoNotWasteTimeMessage ? (
          <span css={styles.footer.warning}>
            Preços e disponibilidade podem mudar. Não perca tempo.
          </span>
        ) : null}
        {creditCard ? (
          <ItineraryServiceDetailsDrawerCreditCardInfo
            creditCard={creditCard}
          />
        ) : null}
      </footer>
    </>
  );
};
