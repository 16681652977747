import React, { createContext, useState } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { RecalculateCarOfferRequest } from "~/apps/corporate/dtos/car.dto";
import { CarOffer } from "~/apps/corporate/models/car.model";
import { CarPolicy } from "~/apps/corporate/models/policy.model";
import { UserSearch } from "~/apps/corporate/models/user.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import * as carResultService from "./car-result.service";

interface Actions {
  handleChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  handleCloseOutOfPolicyDialog: () => void;
  handleExpiration: (hasExpired: boolean) => void;
  handleGetCarSupplierOffer: (
    carToken: string,
    supplierOfferToken: string,
  ) => Promise<void>;
  handleOpenOutOfPolicyDialog: () => void;
  handleRecalculateCarOffer: (
    carToken: string,
  ) => (data: RecalculateCarOfferRequest) => Promise<void>;
  handleSearchAdditionalDrivers: (
    search: string,
  ) => Promise<UserSearch[] | null>;
  handleSelectOffer: (carData: CarOffer) => () => void;
}

type State = {
  additionalDrivers: UserSearch[];
  car: CarOffer | null;
  error: Error | null;
  expirationTime: Date | null;
  hasExpired: boolean;
  isOutOfPolicyDialogOpen: boolean;
  loading: boolean;
  loadingRecalculate: boolean;
  policy?: CarPolicy;
};

const initialState: State = {
  additionalDrivers: [],
  car: null,
  error: null,
  expirationTime: null,
  hasExpired: false,
  loading: false,
  loadingRecalculate: false,
  isOutOfPolicyDialogOpen: false,
  policy: undefined,
};

type ContextProps = Actions & State;

const CarResultContext = createContext({} as ContextProps);

type Props = {
  carToken: string;
  travelToken: string;
};

export const CarResultProvider: React.FC<Props> = ({
  carToken,
  children,
  travelToken,
}) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const handleChangeAdditionalDrivers = (additionalDrivers: UserSearch[]) => {
    setState((prev) => ({
      ...prev,
      additionalDrivers,
    }));
  };

  const handleCloseOutOfPolicyDialog = () => {
    setState((prev) => ({
      ...prev,
      isOutOfPolicyDialogOpen: false,
    }));
  };

  const handleExpiration = (hasExpired: boolean) => {
    setState((prev) => ({
      ...prev,
      hasExpired,
    }));
  };

  const handleGetCarSupplierOffer = async (
    carToken: string,
    supplierOfferToken: string,
  ) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const {
      data: carSupplierOffer,
      error,
    } = await carResultService.getCarSupplierOffer(
      carToken,
      supplierOfferToken,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        error,
        loading: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      loading: false,
      ...(carSupplierOffer && {
        expirationTime: carSupplierOffer.expirationTime,
        policy: carSupplierOffer.policy,
        car: carSupplierOffer.supplierOffer,
      }),
    }));
  };

  const handleOpenOutOfPolicyDialog = () => {
    setState((prev) => ({
      ...prev,
      isOutOfPolicyDialogOpen: true,
    }));
  };

  const handleRecalculateCarOffer = (carToken: string) => async (
    data: RecalculateCarOfferRequest,
  ) => {
    setState((prev) => ({
      ...prev,
      loadingRecalculate: true,
    }));

    const response = await carResultService.recalculateCarOffer(carToken, data);

    if (response.error) {
      showSnackMessage(response.error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        loadingRecalculate: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      loadingRecalculate: false,
      ...(response.data && {
        car: response.data.offer,
      }),
    }));
  };

  const handleSearchAdditionalDrivers = async (search: string) => {
    const { data, error } = await carResultService.searchAdditionalDrivers(
      search,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    if (!data) {
      return null;
    }

    return data;
  };

  const handleSelectOffer = (carData: CarOffer) => async () => {
    if (!carData.supplierInfo) {
      return;
    }

    setState((prev) => ({
      ...prev,
      addingOffer: true,
    }));

    const { error } = await carResultService.addCarOfferToTravel(travelToken, {
      additional_drivers: state.additionalDrivers.map((c) => c.userToken),
      e_out_of_policy: carData.outOfPolicy,
      search_token: carToken,
      supplier_offer_token: carData.supplierInfo.id,
    });

    if (error) {
      setState((prev) => ({ ...prev, addingOffer: false }));
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({ ...prev, addingOffer: false }));
    showSnackMessage("Oferta adicionada com sucesso!", ALERT_TYPES.SUCCESS);

    navigate(`/travels/${travelToken}/itinerary`);
  };

  return (
    <CarResultContext.Provider
      value={{
        ...state,
        handleChangeAdditionalDrivers,
        handleCloseOutOfPolicyDialog,
        handleExpiration,
        handleGetCarSupplierOffer,
        handleOpenOutOfPolicyDialog,
        handleRecalculateCarOffer,
        handleSearchAdditionalDrivers,
        handleSelectOffer,
      }}
    >
      {children}
    </CarResultContext.Provider>
  );
};

export const useCarResult = useContextFactory(
  "CarResultContext",
  CarResultContext,
);
