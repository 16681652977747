import React from "react";
import { Box } from "@toolkit";

import { useExpenseAdvanceApprovalsListContext } from "./ExpenseAdvanceApprovalsList.context";
import { ApprovalItem } from "./ApprovalItem";

const ApprovalsList = () => {
  const {
    visibleApprovals,
    isLoading
  } = useExpenseAdvanceApprovalsListContext();

  return (
    <Box>
      {!visibleApprovals.length &&
        !isLoading && <p>Nenhum adiantamento encontrado</p>}
      {visibleApprovals.map(expenseAdvanceApproval => (
        <ApprovalItem
          key={expenseAdvanceApproval.expenseAdvanceToken}
          item={expenseAdvanceApproval}
        />
      ))}
    </Box>
  );
};

export { ApprovalsList };
