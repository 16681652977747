import React from "react";
import Divider from "@material-ui/core/Divider";
import { css } from "emotion";
import clsx from "classnames";

const styles = {
  divider: css({
    background:
      "linear-gradient(90deg, rgba(211,222,233,1) 0%, rgba(233,238,244,1) 35%, rgba(241,245,248,1) 100%)"
  })
};

const StyledDivider = props => {
  const { className, ...other } = props;
  return <Divider className={clsx(className, styles.divider)} {...other} />;
};

export { StyledDivider as Divider };
