import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: "#FFD6D6",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    color: theme.colors.gray[700],
    display: "flex",
    flexDirection: "column",
    fontSize: "0.875rem",
    gap: "12px",
    padding: "24px",
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
};
