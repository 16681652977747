import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { FlightListOffer } from "~/apps/corporate/models/offer.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Button } from "@toolkit/v2";

import {
  FlightCardRouteDetails,
  FlightCardRouteDetailsSkeleton,
} from "./card-route-details/card-route-details";
import { styles } from "./styles";

type Props = {
  flight: FlightListOffer;
  handleSingleFlightFilter: (
    flightType: "outbound" | "inbound",
    flightNumber: string,
  ) => void;
  hasBeenViewed: boolean;
  isInboundFlightChecked: boolean;
  isOutboundFlightChecked: boolean;
  onOutOfPolicyClick: (flight: FlightListOffer) => void;
  openFlightDetailsDrawer: () => void;
  selectFlight: (flight: FlightListOffer) => void;
  setViewed: (itineraryId: string) => void;
};

export const FlightCard: React.FC<Props> = ({
  flight,
  handleSingleFlightFilter,
  hasBeenViewed,
  isInboundFlightChecked,
  isOutboundFlightChecked,
  onOutOfPolicyClick,
  openFlightDetailsDrawer,
  selectFlight,
  setViewed,
}) => {
  const anyDifferentAirport = useCallback(() => {
    return !!flight.diffAirports && !!flight.inbound;
  }, [flight]);

  const anyStopChange = useCallback(() => {
    const { inbound, outbound } = flight;

    if (flight.stopType === "zero") {
      return false;
    }

    const inboundChange =
      inbound &&
      inbound.stops &&
      inbound.stops.length > 0 &&
      inbound.stops.some((stop) => stop.airportChange);

    const outboundChange =
      outbound.stops &&
      outbound.stops.length > 0 &&
      outbound.stops.some((stop) => stop.airportChange);

    return !!inboundChange || !!outboundChange;
  }, [flight]);

  const handleSelectFlight = useCallback(
    (flight: FlightListOffer) => {
      selectFlight(flight);

      if (!hasBeenViewed) {
        setViewed(flight.itineraryId.toString());
      }
    },
    [hasBeenViewed, selectFlight, setViewed],
  );

  const getPriceLabel = useCallback(() => {
    const [real, cents] = toCurrency(flight.price)
      .replace("R$ ", "")
      .split(",");

    if (!real || !cents) {
      return <span css={styles.price.root}>R$ - </span>;
    }

    return (
      <span css={styles.price.root}>
        R$ {real},<span css={styles.price.cents}>{cents}</span>
      </span>
    );
  }, [flight]);

  return (
    <div
      css={styles.root}
      onClick={() => {
        openFlightDetailsDrawer();

        handleSelectFlight(flight);
      }}
    >
      {hasBeenViewed ? <span css={styles.viewed}>Visualizado</span> : null}
      <div css={styles.container({ viewed: hasBeenViewed })}>
        <div css={styles.left.root}>
          <FlightCardRouteDetails
            bound={flight.outbound}
            checked={isOutboundFlightChecked}
            handleSingleFlightFilter={handleSingleFlightFilter}
            isOneWay={!flight.inbound}
          />
          {flight.inbound ? (
            <>
              <div css={styles.left.divisor} />
              <FlightCardRouteDetails
                bound={flight.inbound}
                checked={isInboundFlightChecked}
                handleSingleFlightFilter={handleSingleFlightFilter}
              />
            </>
          ) : null}
          {anyDifferentAirport() || anyStopChange() ? (
            <div css={styles.left.diff} role="alert">
              <Icon size={16} use="exclamation-triangle" />
              <p>Atenção: há mudança de aeroporto entre trechos.</p>
            </div>
          ) : null}
        </div>
        <div css={styles.right.root}>
          {flight.outOfPolicy && (
            <div css={styles.right.top.root}>
              <OutOfPolicyBadge
                onClick={(e) => {
                  e.stopPropagation();

                  onOutOfPolicyClick(flight);
                }}
              />
            </div>
          )}
          <div css={styles.right.bottom.root}>
            <div css={styles.right.bottom.price.root}>
              <span css={styles.right.bottom.price.label}>A partir de</span>
              <div css={styles.right.bottom.price.value}>{getPriceLabel()}</div>
            </div>
            <Button
              css={styles.right.bottom.button({ disabled: false })}
              onClick={(e) => {
                e.stopPropagation();

                selectFlight(flight);
              }}
              variant="pink"
            >
              Ver mais
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FlightCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.container({ disabled: true })}>
        <div css={styles.left.root}>
          <FlightCardRouteDetailsSkeleton />
          <FlightCardRouteDetailsSkeleton />
        </div>
        <div css={styles.right.root}>
          <div css={styles.right.top.root}>
            <Skeleton height="20px" width="92px" />
          </div>
          <div css={styles.right.bottom.root}>
            <div css={styles.right.bottom.price.root}>
              <Skeleton height="14px" width="144px" />
              <Skeleton height="24px" width="128px" />
            </div>
            <Button
              css={styles.right.bottom.button({ disabled: true })}
              disabled
              variant="pink"
            >
              Ver mais
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
