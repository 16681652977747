import React, { useMemo } from "react";

import { Breadcrumbs } from "~/apps/shared/components/breadcrumbs/breadcrumbs";

import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useItinerary } from "../../../itinerary/itinerary.context";

export const PaymentHeaderBreadcrumbs: React.FC = () => {
  const { travelApprovalHistoryModel } = useItineraryContainer();

  const { travelToken } = useItinerary();

  const routes = useMemo(() => {
    if (
      travelApprovalHistoryModel &&
      travelApprovalHistoryModel.isPendingApproval()
    ) {
      return [
        {
          name: "Revisar Solicitação",
          url: `/travels/${travelToken}/approval-review`,
        },
        {
          name: "Realizar Pagamento",
          url: `/travels/${travelToken}/payment`,
        },
      ];
    }

    return [
      {
        name: "Plano de Viagem",
        url: `/travels/${travelToken}/itinerary/travel-plan`,
      },
      {
        name: "Pendências Administrativas",
        url: `/travels/${travelToken}/itinerary/pendencies`,
      },
      {
        name: "Realizar Pagamento",
        url: `/travels/${travelToken}/payment`,
      },
    ];
  }, [travelApprovalHistoryModel, travelToken]);

  return <Breadcrumbs routes={routes} />;
};
