import React, { useCallback, useState } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { COUNTRY_RESTRICTION_MESSAGES } from "~/apps/shared/constants/countries.constant";

import { Badge, Button } from "@toolkit/v2";

import { useDeleteCountryRestrictionMutation } from "../hooks/use-country-restrictions-data";
import { styles } from "./styles";

type Props = {
  countryRestriction: CountryRestriction;
};

export const RestrictionCard: React.FC<Props> = ({ countryRestriction }) => {
  const { showSnackMessage } = useApplication();

  const [modal, setModal] = useState<{ component: React.ReactNode | null }>({
    component: null,
  });

  const deleteMutation = useDeleteCountryRestrictionMutation(
    countryRestriction.country_restriction_token,
  );

  const handleDelete = useCallback(() => {
    setModal({
      component: (
        <AlertDialog
          confirmLabel="Excluir"
          message="Deseja realmente excluir a restrição de viagem selecionada?"
          onClose={() => setModal({ component: null })}
          onConfirm={async () => {
            const response = await deleteMutation.mutateAsync();

            if (response.error) {
              showSnackMessage(
                `${response.error.title}: ${response.error.description}`,
                "error",
              );

              return;
            }

            showSnackMessage(
              "Restrição de viagem excluída com sucesso",
              "success",
            );
          }}
          open
          title="Exclusão de restrição de viagem."
        />
      ),
    });
  }, [deleteMutation, showSnackMessage]);

  const handleEdit = useCallback(() => {
    navigate(
      `/configurations/trips/country-restrictions/${countryRestriction.country_restriction_token}/edit`,
    );
  }, []);

  return (
    <>
      <li css={styles.root} onClick={handleEdit}>
        <div css={styles.left}>
          <Badge
            css={styles.badge({
              restrictionType: countryRestriction.restriction_type,
            })}
          >
            {COUNTRY_RESTRICTION_MESSAGES[countryRestriction.restriction_type]}
          </Badge>
          <div css={styles.country.root}>
            <img
              alt={countryRestriction.country_name}
              css={styles.country.flag}
              src={`/images/flags/${countryRestriction.country_iso_code.toLowerCase()}.svg`}
            />
            <span>{countryRestriction.country_name}</span>
          </div>
        </div>
        <div css={styles.right.root}>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              handleEdit();
            }}
            shape="icon"
          >
            <Icon size={20} use="pencil" />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              handleDelete();
            }}
            shape="icon"
            variant="pink"
          >
            <Icon size={20} use="trash" />
          </Button>
        </div>
      </li>
      {modal.component}
    </>
  );
};
