import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    color: theme.colors.pink[500],
    fontSize: "0.75rem",
    fontWeight: 700,
  }),
};
