import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    root: css({
      borderTop: `1px dashed ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      padding: "0 24px",
    }),
    segments: {
      connection: {
        left: {
          root: css({
            alignItems: "center",
            color: theme.colors.gray[300],
            display: "flex",
            fontSize: "0.875rem",
            gap: "8px",
            "@media (max-width: 1024px)": {
              fontSize: "0.75rem",
            },
            "@media (max-width: 640px)": {
              "& > :first-of-type": {
                display: "none",
              },
            },
          }),
        },
        right: {
          root: css({
            alignItems: "center",
            color: theme.colors.gray[300],
            display: "flex",
            gap: "12px",
            fontSize: "0.75rem",
            whiteSpace: "nowrap",
          }),
          top: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
        },
        root: css({
          alignItems: "center",
          backgroundColor: theme.colors.background.gray,
          borderRadius: "8px",
          boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          padding: "16px",
          "@media (max-width: 425px)": {
            backgroundColor: "unset",
            borderBottom: `1px dashed ${theme.colors.gray[100]}`,
            borderTop: `1px dashed ${theme.colors.gray[100]}`,
            borderRadius: "unset",
            boxShadow: "unset",
            padding: "16px 0",
          },
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
      }),
      segment: {
        carrier: {
          image: css({
            borderRadius: "8px",
          }),
          right: {
            image: css({
              borderRadius: "4px",
            }),
            root: css({
              color: theme.colors.gray[300],
              display: "flex",
              flexDirection: "column",
              fontSize: "0.75rem",
              gap: "4px",
            }),
            row: css({
              alignItems: "center",
              display: "flex",
              gap: "8px",
            }),
          },
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "16px",
            padding: "0 24px",
          }),
        },
        itinerary: {
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "16px",
          }),
          text: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "24px 0",
        }),
      },
    },
  },
  root: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  }),
  top: {
    left: {
      itinerary: {
        code: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
          textAlign: "center",
        }),
        date: css({
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          textAlign: "center",
        }),
        name: css({
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          textAlign: "center",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "16px",
        width: "100%",
      }),
    },
    right: {
      button: {
        icon: ({ isCollapsed }: { isCollapsed: boolean }) =>
          css({
            transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
            transition: "transform 0.15s ease-in-out",
          }),
        root: css({
          flexShrink: 0,
          "@media (max-width: 640px)": {
            display: "none",
          },
        }),
      },
    },
    root: ({ isCollapsed }: { isCollapsed: boolean }) => ({
      alignItems: "center",
      backgroundColor: theme.colors.background.gray,
      borderRadius: isCollapsed ? "8px" : "8px 8px 0 0",
      cursor: "pointer",
      display: "flex",
      gap: "24px",
      justifyContent: "space-between",
      padding: "16px 24px",
    }),
  },
};
