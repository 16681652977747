import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../assets/styles/theme";
import clsx from "classnames";
import { MEDIA_QUERIES } from "../../constants";

const styles = {
  labelTag: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    fontWeight: "bold",
    color: defaultTheme.textColor,
    backgroundColor: "#F2F2F2",
    padding: "0px 13px",
    borderRadius: "25px",
    lineHeight: 1.5,
    letterSpacing: "0.04rem",
    height: 19,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      height: 18
    }
  })
};

const ColoredTag = props => {
  const { children, tagStyle, isUppercase } = props;

  return (
    <div
      className={clsx(styles.labelTag, tagStyle)}
      style={{
        textTransform: isUppercase ? "uppercase" : "none"
      }}
    >
      {children}
    </div>
  );
};

ColoredTag.defaultProps = {
  isUppercase: true,
  tagStyle: ""
};

export default ColoredTag;
