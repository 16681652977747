import React from "react";
import { Box, Flex, Text } from "@toolkit";
import { PendingReviewCard } from "./PendingReviewCard";
import {
  useUserReviewsContext,
  useUserReviewsActionsContext
} from "../UserReviews.context";
import isEmpty from "lodash/isEmpty";

const PendingReviews = () => {
  const { pendingReviews } = useUserReviewsContext();
  const { handleReview } = useUserReviewsActionsContext();

  return (
    <Box width={["100%"]}>
      <Flex flexDirection="column">
        {isEmpty(pendingReviews) && (
          <Text fontSize={1}>Você não possui nenhuma avaliação pendente</Text>
        )}
        {pendingReviews.map(review => (
          <PendingReviewCard
            key={review.offerToken}
            reviewData={review}
            initialDate={review.hotelInitialDate.format("DD MMM, YYYY")}
            endDate={review.hotelEndDate.format("DD MMM, YYYY")}
            handleReview={handleReview(review)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export { PendingReviews };
