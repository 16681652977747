import React from "react";
import { Box, Flex } from "@toolkit";
import { TravelerSpent } from "@models/analytics.model";
import * as utils from "@helpers";
import { Column } from "react-table";
import { TravelerSpentTable } from "./TravelerSpentTable";
import { TravelerSpentChart } from "./TravelerSpentChart";
import isEmpty from "lodash/isEmpty";

interface Props {
  data: TravelerSpent[];
}

const TravelerSpentData = ({ data }: Props) => {
  const tableData = React.useMemo(() => data, [data]);
  const tableColumns: Column[] = React.useMemo(
    () => [
      {
        Header: "Viajante",
        accessor: "traveler"
      },
      {
        Header: "Menor valor gasto",
        accessor: "minimunSpent",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Valor médio",
        accessor: "averageSpent",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Valor total gasto",
        accessor: "totalSpent",
        Cell: props => utils.toCurrency(props.value)
      }
    ],
    []
  );

  if (isEmpty(data)) {
    return (
      <Flex justifyContent="center" alignItems="center" height={330}>
        <p>Nenhum dado a ser mostrado</p>
      </Flex>
    );
  }

  return (
    <div>
      <Flex>
        <Box maxWidth={["100%", "100%", "60%"]} width="100%">
          <TravelerSpentTable data={tableData} columns={tableColumns} />
        </Box>
        <Box display={["none", "none", "block"]} maxWidth={"40%"} width="100%">
          <TravelerSpentChart data={tableData} />
        </Box>
      </Flex>
    </div>
  );
};

export { TravelerSpentData };
