import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

export const styles = {
  groupContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    margin: "1rem 0 1rem",
    padding: "0.5rem 0 0",
    "&:hover": {
      backgroundColor: "#F9FAFC",
    },
  }),
  messageContainer: css({
    width: "auto",
    height: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "0.875rem",
  }),
  formGroup: css({
    position: "relative",
  }),
  messageError: css({
    position: "absolute",
    width: "max-content",
    margin: "0",
    marginTop: "1.75rem",
    fontSize: "12px",
    color: defaultTheme.errorTextColor,
  }),
};
