import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: theme.colors.white,
    display: " flex",
    "& > :first-of-type": {
      borderRadius: "8px 0 0 8px",
    },
    "& > :last-of-type": {
      borderRadius: "0px 8px 8px 0",
    },
  }),
  tab: ({ active }: { active: boolean }) =>
    css({
      alignItems: "center",
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: theme.colors.gray[100],
      color: theme.colors.gray[100],
      fontSize: "0.875rem",
      fontFamily: theme.fonts.inter,
      justifyContent: "center",
      padding: "16px 0",
      transition: "all 0.15s ease-in-out",
      width: "100%",
      "&:not(:disabled)": {
        color: theme.colors.gray[700],
        cursor: "pointer",
        ...(active && {
          borderColor: theme.colors.pink[500],
          color: theme.colors.pink[500],
          fontWeight: 600,
        }),
        "&:hover": {
          borderColor: theme.colors.gray[300],
          color: theme.colors.gray[300],
          ...(active && {
            borderColor: theme.colors.pink[700],
            color: theme.colors.pink[700],
          }),
        },
      },
    }),
};
