import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import * as areasParser from "~/apps/corporate/parsers/areas.parser";
import { api } from "~/apps/shared/services/api";

import { GetUserCompanyAreasResponse } from "../dtos/areas.dto";
import {
  CreateAreaForm,
  UpdateAreaForm,
} from "../pages/configurations/views/company/areas/areas.types";

export const addUserToArea = async (
  userToken: string,
  companyAreaToken: string,
) => {
  return await api
    .request<any>({
      data: {
        user_token: userToken,
        company_area_token: companyAreaToken,
      },
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/user-areas`,
    })
    .then(({ data }) => areasParser.AreaFactory(data));
};

export const createArea = async (data: CreateAreaForm) => {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/company-areas`,
    })
    .then(({ data }) => {
      return areasParser.AreaFactory(data);
    });
};

export const fetchArea = async (companyAreaToken: string) => {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/company-areas/${companyAreaToken}`,
    })
    .then(({ data }) => areasParser.DetailedAreaFactory(data));
};

export const fetchAreasBySearchText = async (searchText: string) => {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/company-areas/search/${searchText}`,
    })
    .then(({ data }) => areasParser.AreasFactory(data));
};

export const fetchClientAreas = async () => {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/company-areas`,
    })
    .then(({ data }) => areasParser.AreasFactory(data));
};

export const getUserCompanyAreas = async (userToken: string) => {
  return await api
    .request<GetUserCompanyAreasResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/company-areas`,
    })
    .then(({ data }) => areasParser.parseUserCompanyAreas(data));
};

export const removeUserFromArea = async (userAreaToken: string) => {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/user-areas/${userAreaToken}`,
    })
    .then(({ data }) => data);
};

export const updateArea = async (area: UpdateAreaForm) => {
  const { companyAreaToken, ...rest } = area;

  return await api
    .request<any>({
      data: {
        ...rest,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/company-areas/${companyAreaToken}`,
    })
    .then(({ data }) => areasParser.AreaFactory(data));
};

export const updateAreaDisplayNameFromClientConfig = async (
  clientConfigToken: string,
  areaName: string,
) => {
  return await api
    .request<any>({
      data: {
        company_area_display: areaName,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
    })
    .then(({ data }) => data);
};

export const updateBoardDisplayNameFromClientConfig = async (
  clientConfigToken: string,
  boardName: string,
) => {
  return await api
    .request<any>({
      data: {
        company_board_display: boardName,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
    })
    .then(({ data }) => data);
};

export const updateCompanyAreaEnabledFromClientConfig = async (
  clientConfigToken: string,
  enabled: boolean,
) => {
  return await api
    .request<any>({
      data: {
        company_area_enabled: enabled,
      },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
    })
    .then(({ data }) => data);
};
