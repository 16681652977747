import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "2px solid",
    borderColor: "transparent",
    color: theme.colors.gray[300],
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    fontFamily: theme.fonts.inter,
    padding: "22px 16px",
    transform: "translateY(1px)",
    transition: "all 0.15s ease-in-out",
    whiteSpace: "nowrap",
    ":hover": {
      borderBottomColor: theme.colors.pink[500],
      color: theme.colors.gray[700],
    },
  }),
};
