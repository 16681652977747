import React, { useState, useEffect } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import sortBy from "lodash/sortBy";
import moment from "moment";

import { useContextFactory } from "@hooks";

import {
  IFilterParams,
  SpentThroughtTimeItem,
  AdvanceRangeItem,
  AnalyticsOption,
} from "../shared/types";
import * as flightInfoService from "./FlightInfo.service";
import {
  FlightInfoAggregatedItem,
  SpentByCompanyItem,
  SpentByFlightDestinationItem,
  FlightCredits,
  FlightWastes,
} from "./FlightInfo.types";
import * as flightInfoUtils from "./FlightInfo.utils";

type FlightInfoState = {
  advanceRange: AdvanceRangeItem[];
  altersInfo: FlightInfoAggregatedItem[];
  averageSpent: number;
  billingProfileOptions: AnalyticsOption[];
  cancelsInfo: FlightInfoAggregatedItem[];
  companyAreaOptions: AnalyticsOption[];
  costCenterOptions: AnalyticsOption[];
  creditsInfo: FlightCredits;
  filterState: IFilterParams;
  generatedAt: Date | null;
  loadingData: boolean;
  projectOptions: AnalyticsOption[];
  spentByCompany: SpentByCompanyItem[];
  spentByDestination: SpentByFlightDestinationItem[];
  spentThroughtTime: SpentThroughtTimeItem[];
  tagOptions: AnalyticsOption[];
  totalSpent: number;
  travelerOptions: AnalyticsOption[];
  wastesInfo: FlightWastes;
};

type LoadFlightInfoResult = Omit<
  FlightInfoState,
  | "billingProfileOptions"
  | "companyAreaOptions"
  | "costCenterOptions"
  | "filterState"
  | "loadingData"
  | "projectOptions"
  | "tagOptions"
  | "travelerOptions"
>;

type FlightInfoActions = {
  loadFlightInfo: (args: any) => Promise<LoadFlightInfoResult | void>;
  onApplyFilters: (args: any) => void;
  onFilterBillingProfile: (args: any) => void;
  onFilterCompanyArea: (args: any) => void;
  onFilterCostCenter: (args: any) => void;
  onFilterProject: (args: any) => void;
  onFilterTag: (args: any) => void;
  onFilterTraveler: (args: any) => void;
  onPeriodRangeApply: (args: any) => void;
};

type FlightInfoContext = FlightInfoState & FlightInfoActions;

const FlightInfoContext = React.createContext<FlightInfoContext>(
  {} as FlightInfoContext,
);

const initialFlightInfoState = {
  advanceRange: [],
  altersInfo: [],
  averageSpent: 0,
  billingProfileOptions: [],
  cancelsInfo: [],
  companyAreaOptions: [],
  costCenterOptions: [],
  creditsInfo: {
    credits: [],
    creditsPerCompany: [],
    totalCredits: 0,
    totalNearExpiration: 0,
  },
  generatedAt: null,
  loadingData: false,
  projectOptions: [],
  spentByCompany: [],
  spentByDestination: [],
  spentThroughtTime: [],
  tagOptions: [],
  totalSpent: 0,
  travelerOptions: [],
  wastesInfo: {
    alterDifferencePercentage: null,
    alterFeePercentage: null,
    cancelFee: {
      noShowWastesPercentage: null,
      regularWastesPercentage: null,
      totalNoShowWastes: 0,
      totalRegularWastes: 0,
    },
    totalAlterDifference: 0,
    totalAlterFee: 0,
    totalWastes: 0,
    totalWastesPercentage: null,
  },
};

const today = moment();

const initialFilterState: IFilterParams = {
  billingProfileToken: "",
  companyAreaToken: "",
  costCenterToken: "",
  endMonth: 12,
  endYear: today.year(),
  projectToken: "",
  startMonth: 1,
  startYear: today.year(),
  tagToken: "",
  travelerToken: "",
};

export const useFlightInfoContext = useContextFactory(
  "FlightInfo",
  FlightInfoContext,
);

export const FlightInfoProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [flightInfoState, setFlightInfoState] = useState(
    initialFlightInfoState,
  );
  const [filters, setFilters] = useState(initialFilterState);

  function setSafeState(newState) {
    setFlightInfoState((prev) => {
      return {
        ...prev,
        ...newState,
      };
    });
  }

  useEffect(() => {
    void loadPage();
  }, []);

  useEffect(() => {
    void loadData();
  }, [
    filters.costCenterToken,
    filters.tagToken,
    filters.travelerToken,
    filters.startMonth,
    filters.startYear,
    filters.endMonth,
    filters.endYear,
    filters.companyAreaToken,
    filters.projectToken,
    filters.billingProfileToken,
  ]);

  const loadPage = async () => {
    setSafeState({ loadingData: true });

    const getFilterOptionsResultResponse = await flightInfoService.getFilterOptionsResult();

    if (getFilterOptionsResultResponse.error as any) {
      const error = getFilterOptionsResultResponse.error;

      setSafeState({
        isLoadingPage: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const getFilterOptionsResult = getFilterOptionsResultResponse.data;

    setSafeState({
      billingProfileOptions: getFilterOptionsResult.billingProfileOptions,
      companyAreaOptions: getFilterOptionsResult.companyAreaOptions,
      costCenterOptions: getFilterOptionsResult.costCenterOptions,
      isLoadingPage: false,
      projectOptions: getFilterOptionsResult.projectOptions,
      tagOptions: getFilterOptionsResult.tagOptions,
      travelerOptions: getFilterOptionsResult.travelerOptions,
    });
  };

  const loadData = async () => {
    setSafeState({ loadingData: true });

    const data = await loadFlightInfo({
      startMonth: filters.startMonth,
      startYear: filters.startYear,
      endMonth: filters.endMonth,
      endYear: filters.endYear,
      costCenterToken: filters.costCenterToken,
      tagToken: filters.tagToken,
      travelerToken: filters.travelerToken,
      companyAreaToken: filters.companyAreaToken,
      projectToken: filters.projectToken,
      billingProfileToken: filters.billingProfileToken,
    });

    setSafeState({
      loadingData: false,
      ...data,
    });
  };

  const loadFlightInfo = async (
    params,
  ): Promise<LoadFlightInfoResult | void> => {
    const flightInfoPromise = flightInfoService.getFlightInfo(params);
    const totalSpentPromise = flightInfoService.getTotalSpents(params);
    const alterFeePromise = flightInfoService.getAlterFee(params);
    const alterDifferencePromise = flightInfoService.getAlterDifference(params);
    const cancelFeePromise = flightInfoService.getCancelFee(params);
    const creditsPromise = flightInfoService.getClientCredits();

    const [
      { data: flightInfo, error: flightInfoError },
      { data: totalSpentData, error: totalSpentError },
      { data: alterFeeData, error: alterFeeError },
      { data: alterDifferenceData, error: alterDifferenceError },
      { data: cancelFeeData, error: cancelFeeError },
      { data: creditsData, error: creditsError },
    ] = await Promise.all([
      flightInfoPromise,
      totalSpentPromise,
      alterFeePromise,
      alterDifferencePromise,
      cancelFeePromise,
      creditsPromise,
    ]);

    const error =
      flightInfoError ||
      totalSpentError ||
      alterFeeError ||
      alterDifferenceError ||
      cancelFeeError ||
      creditsError;

    if (error) {
      setSafeState({ loadingData: false });
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    const totalAlterFee = flightInfoUtils.getTotalAmount(alterFeeData);
    const totalAlterDifference =
      flightInfoUtils.getTotalAmount(alterDifferenceData) - totalAlterFee;
    const alterFeePercentage = flightInfoUtils.getPercentage(
      totalAlterFee,
      totalAlterFee + totalAlterDifference,
    );
    const alterDifferencePercentage = flightInfoUtils.getPercentage(
      totalAlterDifference,
      totalAlterFee + totalAlterDifference,
    );
    const { regularWastes, noshowWastes } = cancelFeeData;
    const totalRegularWastes = flightInfoUtils.getTotalAmount(regularWastes);
    const totalNoShowWastes = flightInfoUtils.getTotalAmount(noshowWastes);
    const regularWastesPercentage = flightInfoUtils.getPercentage(
      totalRegularWastes,
      totalRegularWastes + totalNoShowWastes,
    );
    const noShowWastesPercentage = flightInfoUtils.getPercentage(
      totalNoShowWastes,
      totalRegularWastes + totalNoShowWastes,
    );

    const totalCancelAndAlterWastes =
      totalAlterFee +
      totalAlterDifference +
      totalRegularWastes +
      totalNoShowWastes;
    const totalSpent = flightInfoUtils.getTotalSpent(totalSpentData);

    const totalWastes = totalCancelAndAlterWastes;

    const totalWastesPercentage = flightInfoUtils.getPercentage(
      totalWastes,
      totalSpent,
    );

    const {
      creditList,
      totalCredits,
      totalCreditsNearExpiration,
    } = creditsData;
    const sortedCredits = sortBy(creditList, "value");
    const creditsPerCompany = flightInfoUtils.getCreditsPerCompany(creditList);

    return {
      advanceRange: flightInfo.advanceRange,
      altersInfo: flightInfo.altersInfo,
      averageSpent: flightInfo.spent.average,
      cancelsInfo: flightInfo.cancelsInfo,
      creditsInfo: {
        credits: sortedCredits,
        creditsPerCompany,
        totalCredits,
        totalNearExpiration: totalCreditsNearExpiration,
      },
      generatedAt: flightInfo.generatedAt,
      spentByCompany: flightInfo.spentByCompany,
      spentByDestination: flightInfo.spentByDestination,
      spentThroughtTime: flightInfo.spentThroughtTime,
      totalSpent: flightInfo.spent.total,
      wastesInfo: {
        alterDifferencePercentage,
        alterFeePercentage,
        cancelFee: {
          noShowWastesPercentage,
          regularWastesPercentage,
          totalNoShowWastes,
          totalRegularWastes,
        },
        totalAlterDifference,
        totalAlterFee,
        totalWastes,
        totalWastesPercentage,
      },
    };
  };

  const onPeriodRangeApply = (newPeriod) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endMonth: newPeriod.endSelectedMonth + 1,
      endYear: newPeriod.endSelectedYear,
      startMonth: newPeriod.startSelectedMonth + 1,
      startYear: newPeriod.startSelectedYear,
    }));
  };

  function onFilterCostCenter(costCenterToken: string) {
    setFilters((prev) => {
      return {
        ...prev,
        costCenterToken,
      };
    });
  }

  function onFilterTag(tagToken: string) {
    setFilters((prev) => {
      return {
        ...prev,
        tagToken,
      };
    });
  }

  function onFilterTraveler(travelerToken: string) {
    setFilters((prev) => {
      return {
        ...prev,
        travelerToken,
      };
    });
  }

  function onFilterCompanyArea(companyAreaToken: string) {
    setFilters((prev) => ({
      ...prev,
      companyAreaToken,
    }));
  }

  function onFilterProject(projectToken: string) {
    setFilters((prev) => ({
      ...prev,
      projectToken,
    }));
  }

  function onFilterBillingProfile(billingProfileToken: string) {
    setFilters((prev) => ({
      ...prev,
      billingProfileToken,
    }));
  }

  function onApplyFilters(filters) {
    setFilters((prev) => ({
      ...prev,
      ...filters,
    }));
  }

  return (
    <FlightInfoContext.Provider
      value={{
        ...flightInfoState,
        filterState: filters,
        loadFlightInfo,
        onApplyFilters,
        onFilterBillingProfile,
        onFilterCompanyArea,
        onFilterCostCenter,
        onFilterProject,
        onFilterTag,
        onFilterTraveler,
        onPeriodRangeApply,
      }}
    >
      {children}
    </FlightInfoContext.Provider>
  );
};
