import {
  BOOKING_FAILED_REASON_BUS_TRANSLATED,
  BOOKING_FAILED_REASON_CAR_TRANSLATED,
  BOOKING_FAILED_REASON_FLIGHT_TRANSLATED,
  BOOKING_FAILED_REASON_HOTEL_TRANSLATED,
  SERVICE_TYPES,
} from "~/apps/shared/constants";
import { ServiceType } from "~/apps/shared/constants/enums";

export const getBookingFailedReasonByType = (serviceType: ServiceType) => {
  switch (serviceType) {
    case SERVICE_TYPES.flight:
      return BOOKING_FAILED_REASON_FLIGHT_TRANSLATED;
    case SERVICE_TYPES.hotel:
      return BOOKING_FAILED_REASON_HOTEL_TRANSLATED;
    case SERVICE_TYPES.car:
      return BOOKING_FAILED_REASON_CAR_TRANSLATED;
    case SERVICE_TYPES.bus:
      return BOOKING_FAILED_REASON_BUS_TRANSLATED;
  }
};
