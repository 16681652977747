import React from "react";

import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";

import { styles } from "./styles";

export const LayoutErrorBoundary: React.FC = ({ children }) => {
  return (
    <ErrorBoundary css={styles.root} level="fatal">
      {children}
    </ErrorBoundary>
  );
};
