import React, { useEffect } from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { CircularSpinner } from "@toolkit/v2";

import { useItineraryScreen } from "../../itinerary/itinerary-screen.context";
import { useItineraryServices } from "../../itinerary/itinerary-services.context";
import { usePayment } from "../payment.context";
import { styles } from "./styles";

export const PaymentLoadingDialog: React.FC = () => {
  const {
    closePaymentLoadingDialog,
    isPaymentLoadingDialogOpen,
    isLoadingTravelRepeatedOffers,
  } = useItineraryScreen();
  const { isLoadingOffersAvailabilitiesAndChanges } = useItineraryServices();
  const { isLoadingBook } = usePayment();

  useEffect(() => {
    return () => {
      closePaymentLoadingDialog();
    };
  }, [closePaymentLoadingDialog]);

  return (
    <Dialog open={isPaymentLoadingDialogOpen}>
      <DialogHeader icon="paper-airplane" />
      <DialogContent>
        {isLoadingBook ? (
          <>
            <DialogTitle>Estamos processando a sua reserva.</DialogTitle>
            <span css={styles.message}>
              Por favor, não atualize ou feche a sua página.
            </span>
          </>
        ) : null}
        {isLoadingOffersAvailabilitiesAndChanges ||
        isLoadingTravelRepeatedOffers ? (
          <>
            <DialogTitle>Verificando os preços e disponibilidades.</DialogTitle>
            <span css={styles.message}>
              Por favor, não atualize ou feche a sua página.
            </span>
          </>
        ) : null}
      </DialogContent>
      <CircularSpinner />
    </Dialog>
  );
};
