import * as yup from "yup";

import { SSOConfigProvider } from "~/constants/enums";
import { OptionType } from "~/constants/shared";

export const CONFIG_SSO_VALIDATION = yup.object().shape({
  provider: yup
    .object()
    .required("Selecione o provedor")
    .typeError("Selecione o provedor"),
  tenantID: yup.string().when("provider", {
    is: (option: OptionType<SSOConfigProvider>) =>
      option?.value === SSOConfigProvider.MICROSOFT_AZURE,
    then: yup.string().required("Informe o ID do locatário"),
    otherwise: yup.string().optional(),
  }),
  discoveryEndpoint: yup.string().when("provider", {
    is: (option: OptionType<SSOConfigProvider>) =>
      option?.value !== SSOConfigProvider.MICROSOFT_AZURE &&
      option?.value !== SSOConfigProvider.GOOGLE,
    then: yup.string().required("Informe o discovery endpoint"),
    otherwise: yup.string().optional(),
  }),
  clientId: yup.string().required("Informe ID do cliente"),
  clientSecret: yup.string().required("Informe o segredo do cliente"),
  ssoIsTheOnlyLoginMethod: yup.boolean().required(),
});
