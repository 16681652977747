import React from "react";

import IconButton from "@material-ui/core/IconButton";
import MuiSnackbar, {
  SnackbarProps as MuiSnackbarProps
} from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import { css } from "emotion";

import { defaultTheme } from "../../assets/styles/theme";
import { colors } from "../../assets/styles/theme/constants";

const styles = {
  root: css({
    ["@media (min-width: 960px)"]: {
      maxWidth: 1000
    }
  }),
  message: css({
    display: "flex",
    alignItems: "center"
  }),
  icon: css({
    fontSize: 20
  })
};

const snackTypeStyles: Record<SnackbarType, string> = {
  success: css({
    backgroundColor: "rgb(49, 49, 49)",
    color: colors.white
  }),
  error: css({
    backgroundColor: defaultTheme.secondaryColor,
    color: colors.white
  }),
  info: css({
    backgroundColor: colors.dodgerBlue,
    color: colors.white
  })
};

export type CustomSnackbarOptions = MuiSnackbarProps;

export type SnackbarType = "success" | "error" | "info";

export type SnackbarProps = {
  type: SnackbarType;
  visible: boolean;
  message: React.ReactNode;
  handleClose: () => void;
  options?: CustomSnackbarOptions;
};

export default function Snackbar(props: SnackbarProps) {
  const { type, visible, message, handleClose, options = {} } = props;

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={handleClose}
      open={visible}
      autoHideDuration={5000}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root: classnames(styles.root, snackTypeStyles[type])
        }
      }}
      message={
        <span id="message-id" className={styles.message}>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon className={styles.icon} />
        </IconButton>
      ]}
      {...options}
    />
  );
}
