import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";
import moment from "moment";

import { Flex, Box, Paragraph, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import {
  ADVANCED_EXPENSE_STATUS,
  ADVANCED_EXPENSE_STAGE_TRANSLATION
} from "@constants";

import { AdvancedExpenseApprover } from "@models/advanced-expense.model";

import { useAdvancedExpensesContext } from "../AdvancedExpenses.context";

const styles = {
  title: css({
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: "body",
    mb: "large"
  }),
  stageTitle: css({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    pb: ".2rem"
  }),
  checkIcon: css({
    color: "success",
    fontSize: 16,
    ml: ".35rem"
  }),
  approvedDetails: css({
    fontSize: 12,
    color: defaultTheme.subTextColor,
    lineHeight: "1rem"
  }),
  declinedDetails: css({
    fontSize: 12,
    color: defaultTheme.subTextColor,
    lineHeight: "1rem"
  }),
  analysisDetails: css({
    fontSize: 12,
    color: "primary",
    lineHeight: "1rem"
  }),
  closeIcon: css({
    color: "cancel",
    fontSize: 16,
    ml: ".35rem"
  }),
  spinner: css({
    color: "primary"
  })
};

type CurrentStage = 1 | 2 | 3 | 4 | 99;

interface StageItemProps {
  currentStage: number | null;
  currentStatus: number | null;
  reply: AdvancedExpenseApprover;
}

const StageItem: React.FC<StageItemProps> = ({
  reply,
  currentStage,
  currentStatus
}) => {
  return (
    <Box mb="medium">
      {currentStatus === ADVANCED_EXPENSE_STATUS.DECLINED &&
      currentStage === reply.stage ? (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[reply.stage]}{" "}
            <CloseIcon css={styles.closeIcon} />
          </Paragraph>
          <Text css={styles.declinedDetails}>
            Negado por {reply.fullName},{" "}
            {moment(reply.repliedAt!).format("ddd, DD [de] MMMM YYYY")}
          </Text>
        </Box>
      ) : (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[reply.stage]}
            <CheckIcon css={styles.checkIcon} />
          </Paragraph>
          <Text css={styles.approvedDetails}>
            {reply.stage === 99 ? "Pago" : "Aprovado"} por {reply.fullName},{" "}
            {moment(reply.repliedAt!).format("ddd, DD [de] MMMM YYYY")}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const AdvancedExpenseStatus = () => {
  const {
    selectedAdvancedExpenseDetails,
    selectedAdvancedExpenseToken,
    isLoadingDetails,
    fetchAdvancedExpenseDetails
  } = useAdvancedExpensesContext();
  const {
    expenseAdvanceApprovers,
    expenseAdvanceRequest,
    expenseAdvance
  } = selectedAdvancedExpenseDetails;

  React.useEffect(() => {
    fetchAdvancedExpenseDetails(selectedAdvancedExpenseToken!);
  }, [fetchAdvancedExpenseDetails]);

  const approversHistory = expenseAdvanceApprovers
    ? expenseAdvanceApprovers.filter(
        (item: AdvancedExpenseApprover) => item.repliedAt
      )
    : [];

  const currentStage = expenseAdvanceRequest
    ? expenseAdvanceRequest.status === 99
      ? 99
      : expenseAdvanceRequest.currentApprovalStage
    : 1;

  const currentRequestStatus = expenseAdvanceRequest
    ? expenseAdvanceRequest.status
    : null;

  const requestNotFinished =
    expenseAdvanceRequest && !expenseAdvanceRequest.closedAt;

  if (isLoadingDetails) {
    return (
      <Flex justifyContent="center" alignItems="center" height="130px">
        <CircularProgress css={styles.spinner} />
      </Flex>
    );
  }

  return (
    <Box>
      <Paragraph css={styles.title}>
        Solicitação "{expenseAdvance ? expenseAdvance.name : null}"
      </Paragraph>
      {approversHistory.map((reply: AdvancedExpenseApprover) => (
        <StageItem
          reply={reply}
          currentStage={currentStage}
          currentStatus={currentRequestStatus}
        />
      ))}
      {requestNotFinished ? (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[currentStage as CurrentStage]}
          </Paragraph>
          <Text css={styles.analysisDetails}>Em análise</Text>
        </Box>
      ) : null}
    </Box>
  );
};

export { AdvancedExpenseStatus };
