import moment from "moment";

import {
  FlightsSearch,
  HotelsSearch,
} from "../../components/offer-searcher/offer-searcher.types";
import { RecentFlightsSearch, RecentHotelsSearch } from "./new-trip.types";

export const buildFlightsSearch = (
  recentFlightsSearch: RecentFlightsSearch,
): FlightsSearch => {
  return {
    cabinClass: recentFlightsSearch.cabinClass,
    destination: {
      CityId: recentFlightsSearch.destination.destinationCityId,
      CountryId: `${recentFlightsSearch.destination.destinationName
        .split(" ")
        .pop()}`,
      CountryName: recentFlightsSearch.destination.destinationCountry,
      label: recentFlightsSearch.destination.destinationName,
      PlaceId: `${recentFlightsSearch.destination.destinationLocale}-${
        recentFlightsSearch.destination.destinationCityId.split("-")[1]
      }`,
      PlaceName: recentFlightsSearch.destination.destinationCity,
      RegionId: "",
    },
    endDate: moment(recentFlightsSearch.inboundFlightDate),
    origin: {
      CityId: recentFlightsSearch.origin.originCityId,
      CountryId: `${recentFlightsSearch.origin.originName.split(" ").pop()}`,
      CountryName: recentFlightsSearch.origin.originCountry,
      label: recentFlightsSearch.origin.originName,
      PlaceId: `${recentFlightsSearch.origin.originLocale}-${
        recentFlightsSearch.origin.originCityId.split("-")[1]
      }`,
      PlaceName: recentFlightsSearch.origin.originCity,
      RegionId: "",
    },
    startDate: moment(recentFlightsSearch.outboundFlightDate),
    traveler: {
      allowed: true,
      email: "",
      fullName: recentFlightsSearch.user.fullName,
      userToken: recentFlightsSearch.user.userToken,
      label: recentFlightsSearch.user.fullName,
    },
    type: recentFlightsSearch.oneway ? "oneway" : "roundtrip",
  };
};

export const buildHotelsSearch = (
  recentHotelsSearch: RecentHotelsSearch,
): HotelsSearch => {
  return {
    ...recentHotelsSearch,
    endDate: moment(recentHotelsSearch.endDate),
    startDate: moment(recentHotelsSearch.startDate),
  };
};
