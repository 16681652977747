import React, { ReactNode, useState } from "react";

import { useContextFactory } from "@hooks";

import { HotelSearch } from "../../NewTrip.types";
import { buildHotelFields } from "./RecentHotel.utils";
import * as RecentHotelsService from "./RecentHotels.service";

type RecentHotelContextProps = {
  recentHotelsSearches: HotelSearch[];
  selectedSearch: HotelSearch;
  loading: boolean;
  userDontHaveRecentSearches: boolean;
  fetchRecentHotels: () => void;
  handlePopulateFields: (HotelSearch: HotelSearch) => void;
};

const RecentHotelsContext = React.createContext({} as RecentHotelContextProps);

const useRecentHotelsContext = useContextFactory(
  "RecentHotels",
  RecentHotelsContext
);

interface IState {
  recentHotelsSearches: HotelSearch[];
  selectedSearch: HotelSearch;
  loading: boolean;
  userDontHaveRecentSearches: boolean;
}

const initialState = {
  recentHotelsSearches: [],
  selectedSearch: {
    startDate: null,
    endDate: null,
    location: null,
    totalGuests: 1,
    traveler: null
  },
  loading: false,
  userDontHaveRecentSearches: false
};

const RecentHotelProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<IState>(initialState);

  const fetchRecentHotels = async () => {
    setState(prevState => ({ ...prevState, loading: true }));

    const { data, error } = await RecentHotelsService.getUserRecentHotels();

    if (error) {
      setState(prevState => ({ ...prevState, loading: false }));
    }

    const userDontHaveRecentSearches = data && data.length ? false : true;

    setState(prevState => ({
      ...prevState,
      recentHotelsSearches: data || [],
      userDontHaveRecentSearches,
      loading: false
    }));
  };

  const handlePopulateFields = (selectedHotelSearch: HotelSearch) => {
    setState(prevState => ({
      ...prevState,
      selectedSearch: buildHotelFields(selectedHotelSearch)
    }));
  };

  return (
    <RecentHotelsContext.Provider
      value={{ ...state, fetchRecentHotels, handlePopulateFields }}
    >
      {children}
    </RecentHotelsContext.Provider>
  );
};

export { RecentHotelProvider, useRecentHotelsContext };
