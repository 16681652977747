import { Bound } from "~/apps/corporate/models/flight.model";
import { FlightListOffer } from "~/apps/corporate/models/offer.model";
import { HHMMToSeconds } from "~/apps/shared/utils/hh-mm-to-seconds";
import { isInRange } from "~/apps/shared/utils/is-in-range";

import {
  BaggageFilter,
  BaggageFilterEnum,
  CarrierFilter,
  ClassFilter,
  DurationFilter,
  IFlightFilters,
  InboundFilter,
  OutboundFilter,
  SingleFlightFilter,
  StopFilter,
  VisibleFlightsFilterInterface,
} from "./flights.types";

export class VisibleFlightsFilter implements VisibleFlightsFilterInterface {
  private baggage: BaggageFilter[];
  private carriers: CarrierFilter[];
  private classes: ClassFilter[];
  private duration: DurationFilter;
  private inbound: InboundFilter;
  private onlyInPolicy: boolean;
  private outbound: OutboundFilter;
  private selectedCabinClass: string;
  private selectedClassOnly: boolean;
  private singleFlightFilter: SingleFlightFilter;
  private stops: StopFilter[];

  constructor(filters: IFlightFilters) {
    this.baggage = filters.baggage;
    this.carriers = filters.availableCarriers;
    this.classes = filters.flightClasses;
    this.duration = filters.durationFilter;
    this.inbound = filters.inboundFilter;
    this.onlyInPolicy = filters.onlyInPolicy;
    this.outbound = filters.outboundFilter;
    this.selectedCabinClass = filters.selectedCabinClass;
    this.selectedClassOnly = filters.selectedClassOnly;
    this.singleFlightFilter = filters.singleFlightFilter;
    this.stops = filters.stops;
  }

  private allLegsHaveSelectedCabinClass(bound: Bound, cabinClass: string) {
    return bound.segments.every((segment) => segment.cabinClass === cabinClass);
  }

  private filterAccordingToPolicy(flight: FlightListOffer) {
    return this.onlyInPolicy ? !flight.outOfPolicy : true;
  }

  public getVisibleFlights(flightOffers: FlightListOffer[]): FlightListOffer[] {
    const result = flightOffers
      .filter(this.filterAccordingToPolicy, this)
      .filter(this.hasAnySelectedStops, this)
      .filter(this.hasAnySelectedClasses, this)
      .filter(this.hasAnySelectedCarriers, this)
      .filter(this.hasAnySelectedBaggage, this)
      .filter(this.hasAnyOutboundRange, this)
      .filter(this.hasAnyInboundRange, this)
      .filter(this.hasAnySelectedFlight, this)
      .filter(this.hasFlightsByCabinClass, this);
    return result;
  }

  private hasAnyInboundRange(flight: FlightListOffer) {
    if (!flight.inbound) {
      return true;
    }

    const isDepartureInRange = isInRange(
      HHMMToSeconds(flight.inbound.departureHour),
      this.inbound.departureRange,
    );
    const isArrivalInRange = isInRange(
      HHMMToSeconds(flight.inbound.arrivalHour),
      this.inbound.arrivalRange,
    );
    const isDurationInRange = isInRange(
      flight.inbound.durationMinutes * 60,
      this.duration.durationRange,
    );

    return [isDepartureInRange, isArrivalInRange, isDurationInRange].every(
      (item) => item,
    );
  }

  private hasAnyOutboundRange(flight: FlightListOffer) {
    const isDepartureInRange = isInRange(
      HHMMToSeconds(flight.outbound.departureHour),
      this.outbound.departureRange,
    );
    const isArrivalInRange = isInRange(
      HHMMToSeconds(flight.outbound.arrivalHour),
      this.outbound.arrivalRange,
    );
    const isDurationInRange = isInRange(
      flight.outbound.durationMinutes * 60,
      this.duration.durationRange,
    );

    return [isDepartureInRange, isArrivalInRange, isDurationInRange].every(
      (item) => item,
    );
  }

  private hasAnySelectedBaggage(flight: FlightListOffer) {
    if (this.baggage.every((item) => !item.checked)) {
      return true;
    }

    return this.baggage
      .reduce((acc: boolean[], current) => {
        if (current.checked && current.value === BaggageFilterEnum.no_baggage) {
          acc.push(
            flight.inbound
              ? !flight.outbound.baggage && !flight.inbound.baggage
              : !flight.outbound.baggage,
          );
        } else if (
          current.checked &&
          current.value === BaggageFilterEnum.outbound
        ) {
          acc.push(flight.outbound.baggage);
        } else if (current.checked && current.value === "inbound") {
          acc.push(!!flight.inbound?.baggage);
        }
        return acc;
      }, [])
      .some((item) => item);
  }

  private hasAnySelectedClasses(flight: FlightListOffer) {
    if (this.classes.every((item) => !item.checked)) {
      return true;
    }

    return this.classes
      .filter((item) => item.checked)
      .some((item) => item.value === flight.cabinClass);
  }

  private hasAnySelectedCarriers(flight: FlightListOffer) {
    if (this.carriers.every((item) => !item.checked)) {
      return true;
    }

    return this.carriers
      .filter((item) => item.checked)
      .some(
        (item) =>
          flight.carriers[item.value] &&
          Object.keys(flight.carriers).length === 1,
      );
  }

  private hasAnySelectedFlight(flight: FlightListOffer) {
    const {
      inboundChecked,
      outboundChecked,
      outboundFlightNumber,
      inboundFlightNumber,
    } = this.singleFlightFilter;

    if (!inboundChecked && !outboundChecked) {
      return true;
    }

    const matchesOutbound =
      outboundFlightNumber === flight.outbound.segments[0].flightNumber;
    const matchesInbound =
      inboundFlightNumber === flight.inbound?.segments[0].flightNumber;

    if (inboundChecked && outboundChecked) {
      return matchesOutbound && matchesInbound;
    } else if (outboundChecked) {
      return matchesOutbound;
    } else if (inboundChecked) {
      return matchesInbound;
    }
  }

  private hasAnySelectedStops(flight: FlightListOffer) {
    if (this.stops.every((stop) => !stop.checked)) {
      return true;
    }

    return this.stops
      .filter((stop) => stop.checked)
      .some((stop) => stop.value === flight.stopType);
  }

  private hasFlightsByCabinClass(flight: FlightListOffer) {
    if (!this.selectedClassOnly) return true;

    const doesAllOutboundLegsHaveSelectedCabinClass = this.allLegsHaveSelectedCabinClass(
      flight.outbound,
      this.selectedCabinClass,
    );
    const doesAllInboundLegsHaveSelectedCabinClass = flight.inbound
      ? this.allLegsHaveSelectedCabinClass(
          flight.inbound,
          this.selectedCabinClass,
        )
      : true;

    return (
      doesAllOutboundLegsHaveSelectedCabinClass &&
      doesAllInboundLegsHaveSelectedCabinClass
    );
  }
}
