import React, { useEffect, useRef, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { EXPENSES_CATEGORIES } from "@constants";

import {
  ExpenseCategoryCamelCase,
  ExpenseCategoryDto,
} from "@dtos/expense-categories.dto";
import { ExpenseCategoryFormResult } from "@dtos/expense-categories.dto";

import { useContextFactory } from "@hooks";

import { parseCategoryFormData } from "./expenseCategories.parser";
import {
  sortByDefaultFirst,
  checkDefaultAndActivated,
} from "./expenseCategories.utils";
import * as expensesCategoryService from "./expensesCategoryService";

interface ExpensesCategoriesState {
  categories: ExpenseCategoryCamelCase[];
  formOpen: boolean;
  loading: boolean;
  searchInput: string;
  selectedCategory: Partial<ExpenseCategoryCamelCase>;
  selectedTab: "archived" | "active";
  visibleCategories: ExpenseCategoryCamelCase[];
}

interface Actions {
  handleChangeTab: (filter: "archived" | "active") => void;
  handleCloseForm: () => void;
  handleFormSubmit: (data: ExpenseCategoryFormResult) => void;
  handleOpenCreateForm: () => void;
  handleOpenEdit: (item: ExpenseCategoryCamelCase) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleArchive: (expenseCategory: ExpenseCategoryCamelCase) => void;
  loadExpensesCategories: () => void;
}

const initialExpensesCategoriesState: ExpensesCategoriesState = {
  categories: [],
  formOpen: false,
  loading: false,
  searchInput: "",
  selectedCategory: {
    expenseCategoryToken: "",
  },
  selectedTab: "active",
  visibleCategories: [],
};

type IExpensesCategoriesContext = ExpensesCategoriesState & Actions;

const ExpensesCategoriesContext = React.createContext<IExpensesCategoriesContext>(
  {
    ...initialExpensesCategoriesState,
    handleSearch: () => null,
    handleChangeTab: () => null,
    handleOpenCreateForm: () => null,
    handleCloseForm: () => null,
    handleOpenEdit: () => null,
    handleToggleArchive: () => null,
    handleFormSubmit: () => null,
    loadExpensesCategories: () => null,
  },
);

export const ExpensesCategoriesProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialExpensesCategoriesState);

  const isFirstRender = useRef(true);

  const updateVisibleCategories = () => {
    setState((prev) => ({
      ...prev,
      visibleCategories: sortByDefaultFirst(
        prev.categories.filter(
          (category) =>
            category.name
              .toLowerCase()
              .includes(prev.searchInput.toLowerCase()) &&
            checkDefaultAndActivated(
              category.active,
              category.default,
              prev.selectedTab === "active",
            ),
        ),
      ),
    }));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setState((prev) => ({
      ...prev,
      searchInput: event.target.value,
    }));
  };

  const handleChangeTab = (filter: "archived" | "active") => {
    setState((prev) => ({
      ...prev,
      selectedTab: filter,
    }));
  };

  const handleOpenCreateForm = () => {
    setState((prev) => ({
      ...prev,
      formOpen: true,
      selectedCategory: {},
    }));
  };

  const handleCloseForm = () => {
    setState((prev) => ({
      ...prev,
      selectedCategory: {},
      formOpen: false,
    }));
  };

  const handleOpenEdit = React.useCallback(
    (expenseCategory: ExpenseCategoryCamelCase) => {
      setState((prev) => ({
        ...prev,
        selectedCategory: expenseCategory,
        formOpen: true,
      }));
    },
    [],
  );

  const handleToggleArchive = React.useCallback(
    async (expenseCategory: ExpenseCategoryCamelCase) => {
      const { expenseCategoryToken } = expenseCategory;

      const {
        error,
      } = await expensesCategoryService.updateExpenseCategory(
        expenseCategoryToken!,
        { active: !expenseCategory.active },
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        categories: prev.categories.map((item) =>
          item.expenseCategoryToken !== expenseCategoryToken
            ? item
            : { ...item, active: !item.active },
        ),
      }));
    },
    [],
  );

  const createExpenseCategory = async (data: ExpenseCategoryFormResult) => {
    const newCategory: Partial<ExpenseCategoryDto> = parseCategoryFormData({
      color: data.color,
      expenseCategory: EXPENSES_CATEGORIES.CUSTOM,
      expenseFamily: data.expenseFamily.value,
      name: data.categoryName.trim(),
    });

    const {
      data: createdCategory,
      error,
    } = await expensesCategoryService.createExpenseCategory(newCategory);

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      categories: [...prev.categories, createdCategory!],
    }));

    showSnackMessage("Categoria criada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const editExpenseCategory = async (data: ExpenseCategoryFormResult) => {
    const { expenseCategoryToken } = state.selectedCategory;

    const editedCategory: Partial<ExpenseCategoryDto> = parseCategoryFormData({
      color: data.color,
      expenseCategory: EXPENSES_CATEGORIES.CUSTOM,
      expenseFamily: data.expenseFamily.value,
      name: data.categoryName,
    });

    const {
      data: updatedCategory,
      error,
    } = await expensesCategoryService.updateExpenseCategory(
      expenseCategoryToken!,
      editedCategory,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setState((prev) => ({
      ...prev,
      categories: prev.categories.map((item) =>
        item.expenseCategoryToken !== expenseCategoryToken
          ? item
          : updatedCategory!,
      ),
    }));

    showSnackMessage("Categoria editada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const handleFormSubmit = async (data: ExpenseCategoryFormResult) => {
    const { selectedCategory } = state;

    const nameAlrealdyExists = state.categories.find(
      (item) => item.name === data.categoryName.trim(),
    );

    const previousName =
      selectedCategory.name && selectedCategory.name === data.categoryName;

    if (nameAlrealdyExists && !previousName) {
      showSnackMessage(
        "Já existe outra categoria com o mesmo nome",
        ALERT_TYPES.ERROR,
      );

      return;
    }

    if (!selectedCategory.expenseCategoryToken) {
      void createExpenseCategory(data);
    } else {
      void editExpenseCategory(data);
    }

    handleCloseForm();
  };

  const loadExpensesCategories = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const {
      data,
      error,
    } = await expensesCategoryService.getClientExpensesCategories();

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({ ...prev, loading: false }));

      return;
    }

    setState((prev) => ({
      ...prev,
      categories: data!,
      loading: false,
    }));
  };

  useEffect(updateVisibleCategories, [state.categories]);

  useDebounce(
    () => {
      if (!isFirstRender.current) {
        updateVisibleCategories();
      } else {
        isFirstRender.current = false;
      }
    },
    200,
    [state.searchInput, state.selectedTab],
  );

  return (
    <ExpensesCategoriesContext.Provider
      value={{
        ...state,
        handleChangeTab,
        handleCloseForm,
        handleFormSubmit,
        handleOpenCreateForm,
        handleOpenEdit,
        handleSearch,
        handleToggleArchive,
        loadExpensesCategories,
      }}
    >
      {children}
    </ExpensesCategoriesContext.Provider>
  );
};

export const useExpensesCategories = useContextFactory(
  "ExpensesCategoriesContext",
  ExpensesCategoriesContext,
);
