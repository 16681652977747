import React from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { theme } from "smartrips-skin";

import * as utils from "@helpers";

import { styles, tooltipStyles } from "../../common-styles";
import { SpentThroughtTimeItem } from "../../shared/types";

const CustomizedAxisXTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedAxisYTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

interface IProps {
  data: SpentThroughtTimeItem[];
}

const SpentThroughtTimeChart: React.FC<IProps> = ({ data }) => {
  return (
    <ResponsiveContainer height={330} width={"100%"}>
      <ComposedChart data={data} margin={{ top: 10 }}>
        <CartesianGrid
          strokeDasharray="5 10"
          vertical={false}
          horizontal={false}
        />
        <XAxis axisLine={true} dataKey="name" tick={<CustomizedAxisXTick />} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick />}
        />
        <Tooltip
          {...tooltipStyles}
          formatter={(value, name) => {
            return [utils.toCurrency(value), name];
          }}
        />
        <Bar
          name="Total gasto"
          dataKey={"total"}
          fill={theme.colors.primary}
          stroke={theme.colors.primary}
          maxBarSize={32}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { SpentThroughtTimeChart };
