import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { styles } from "./styles";

type Props = {
  canceledAt?: string;
  canceledByUserName?: string;
  isCanceled: boolean;
};

export const ItineraryServiceCanceledByBadge: React.FC<Props> = ({
  canceledAt,
  canceledByUserName,
  isCanceled,
}) => {
  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="person" />
      <span css={styles.text}>
        {isCanceled ? "Cancelado" : "Cancelamento solicitado"}
        {canceledByUserName ? ` por: ${canceledByUserName}` : null}
        {canceledAt
          ? ` em ${moment(canceledAt).format("DD/MM/YYYY HH:mm")}`
          : null}
        .
      </span>
    </div>
  );
};
