import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { TransmissionType } from "~/apps/corporate/models/car.model";
import carFallback from "~/apps/shared/assets/images/fallbacks/car-fallback.png";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { PDFRemoteImage } from "~/apps/shared/components/pdf-remote-image/pdf-remote-image";

import { styles } from "./styles";

type Props = {
  contract: {
    features: {
      ali?: boolean;
      ldw?: boolean;
      freeCancelation?: boolean;
      unlimitedKm?: boolean;
    };
  };
  diffDropOff?: boolean;
  dropOff: {
    address: string;
    storeName: string;
    time: string;
  };
  model: {
    description: string;
    features: {
      abs?: boolean;
      air?: boolean;
      airbag?: boolean;
      baggages?: number;
      doors?: number;
      passengers?: number;
      steering?: string;
      transmission?: TransmissionType;
    };
    image?: string;
    title: string;
  };
  pickUp: {
    address: string;
    storeName: string;
    time: string;
  };
  selectedAdditionals: {
    code: string;
    name: string;
  }[];
};

export const TravelPlanPDFCarSegment: React.FC<Props> = ({
  contract,
  diffDropOff,
  dropOff,
  model,
  pickUp,
  selectedAdditionals,
}) => {
  const additionalsStyles = styles.additionals();
  const carStyles = styles.car();
  const headingStyles = styles.heading();
  const locationStyles = styles.location();
  const rootStyles = styles.root();

  return (
    <View style={rootStyles.root}>
      <View style={headingStyles.root}>
        <View style={headingStyles.title}>
          <Text>{model.title}</Text>
        </View>
        <View style={headingStyles.description}>
          <Text>{model.description}</Text>
        </View>
      </View>
      <View style={carStyles.root}>
        {model.image ? (
          <PDFRemoteImage
            fallbackSrc={carFallback}
            src={model.image}
            style={carStyles.image}
          />
        ) : null}
        <View style={carStyles.features}>
          <View style={carStyles.column}>
            <View style={carStyles.row}>
              <PDFIcon size={14} use="people" />
              <View>
                <Text>{model.features.passengers} passageiros</Text>
              </View>
            </View>
            <View style={carStyles.row}>
              <PDFIcon size={14} use="car-door" />
              <View>
                <Text>{model.features.doors} portas</Text>
              </View>
            </View>
            <View style={carStyles.row}>
              <PDFIcon size={14} use="transmission" />
              <View>
                <Text>
                  {model.features.transmission === TransmissionType.MANUAL
                    ? "Manual"
                    : "Automático"}
                </Text>
              </View>
            </View>
            {model.features.air ? (
              <View style={carStyles.row}>
                <PDFIcon size={14} use="snow" />
                <View>
                  <Text>Ar condicionado</Text>
                </View>
              </View>
            ) : null}
          </View>
          <View style={carStyles.column}>
            {contract.features.ldw ? (
              <View style={carStyles.row}>
                <PDFIcon size={14} use="shield-check" />
                <View>
                  <Text>Proteção LDW</Text>
                </View>
              </View>
            ) : null}
            {contract.features.ali ? (
              <View style={carStyles.row}>
                <PDFIcon size={14} use="shield-check" />
                <View>
                  <Text>Proteção ALI</Text>
                </View>
              </View>
            ) : null}
            {contract.features.unlimitedKm ? (
              <View style={carStyles.row}>
                <PDFIcon size={14} use="speedometer" />
                <View>
                  <Text>Quilometragem livre</Text>
                </View>
              </View>
            ) : null}
            {contract.features.freeCancelation ? (
              <View style={carStyles.row}>
                <PDFIcon size={14} use="calendar-x" />
                <View>
                  <Text>Cancelamento grátis</Text>
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </View>
      <View style={locationStyles.root}>
        <View style={locationStyles.location}>
          <PDFIcon size={14} use="map-pin" />
          <View>
            <Text>
              Retirada às {pickUp.time}h: {pickUp.storeName.toLowerCase()},{" "}
              {pickUp.address.toLowerCase()}.
            </Text>
          </View>
        </View>
        <View style={locationStyles.location}>
          <PDFIcon size={14} use="map-pin" />
          <View>
            <View>
              <Text>
                Devolução às {dropOff.time}h:{" "}
                {diffDropOff ? (
                  <>
                    {dropOff.storeName.toLowerCase()},{" "}
                    {dropOff.address.toLowerCase()}.
                  </>
                ) : (
                  <>No mesmo local.</>
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {selectedAdditionals.length > 0 ? (
        <View style={additionalsStyles.root}>
          <View style={carStyles.features}>
            {selectedAdditionals.slice(0, 4).map((additional) => (
              <View style={additionalsStyles.additional} key={additional.code}>
                <PDFIcon size={14} use="check" />
                <View>
                  <Text>{additional.name}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ) : null}
    </View>
  );
};
