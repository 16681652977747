import React from "react";
import {
  Drawer as DrawerMui,
  DrawerProps as DrawerMuiProps
} from "@material-ui/core";
import DrawerHeader from "~/components/shared/DrawerHeader";
import { css as emotionCSS } from "emotion";
import { css } from "@styled-system/css";
import { theme } from "@skin/v2";
import { defaultTheme } from "~/assets/styles/theme";

type DrawerProps = DrawerMuiProps & {
  title?: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
};

const styles = {
  container: emotionCSS({
    width: "50.9375rem",
    maxWidth: "100%"
  }),
  content: emotionCSS({
    paddingBottom: "72px"
  }),
  footer: emotionCSS({
    width: "100%",
    position: "fixed",
    bottom: 0,
    padding: "1rem 2rem",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    "& > *:last-child": {
      marginLeft: "1rem"
    }
  })
};

const newStyles = {
  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "4.25rem",
    padding: "0.5rem",
    backgroundColor: theme.colors.background.gray
  })
};

function Drawer(props: DrawerProps) {
  const { title, open, children, onClose, ...rest } = props;

  return (
    <DrawerMui
      open={open}
      onClose={onClose}
      anchor="right"
      classes={{
        paper: styles.container
      }}
      {...rest}
    >
      {title ? (
        <DrawerHeader
          title={title}
          handleClose={onClose}
          headerStyles={newStyles.header}
        />
      ) : null}

      {children}
    </DrawerMui>
  );
}

function DrawerContent({ children }) {
  return <section className={styles.content}>{children}</section>;
}

function DrawerActions({ children }) {
  return <footer className={styles.footer}>{children}</footer>;
}

Drawer.Content = DrawerContent;
Drawer.Actions = DrawerActions;

export default Drawer;
