import { AxiosResponse } from "axios";

import { getAuthorizationHeader } from "../helpers/user.helper";
import { VcnModel, VcnDisplay } from "../models/vcn.model";
import { api } from "./interceptors";

export function listVcnsByBillingProfile(
  billingProfileToken: string
): Promise<VcnModel> {
  return api
    .request({
      url: `/booking/billing-profiles/${billingProfileToken}/vcns`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<VcnModel>) => response.data);
}

export function listVcnsByClient(clientToken: string): Promise<VcnDisplay[]> {
  return api
    .request({
      url: `/booking/clients/${clientToken}/vcns`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<VcnDisplay[]>) => {
      return response.data;
    });
}
