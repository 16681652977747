import React, { useMemo, useRef, useState } from "react";

import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";

import BatchFileUploaderDialog from "~/components/shared/batch-file-uploader/BatchFileUploaderDialog";
import { OutlinedButton } from "../buttons/outlined-button";

const styles = {
  button: css({
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    height: "100%"
  })
};

type BatchFileUploaderProps = {
  onUpload: (file: File) => Promise<void>;
  onDownloadModelClick: () => void;
  onSuccessUpload?: () => Promise<unknown>;
  title?: string;
};

export default function BatchFileUploader(props: BatchFileUploaderProps) {
  const [showDialog, setShowDialog] = useState(false);

  const _onUpload = useRef(props.onUpload);
  const _onDownloadModelClick = useRef(props.onDownloadModelClick);
  const _onSuccessUpload = useRef(props.onSuccessUpload);

  const actions = useMemo(() => {
    return {
      openDialog: () => setShowDialog(true),
      closeDialog: () => setShowDialog(false)
    };
  }, []);

  return (
    <>
      <OutlinedButton onClick={actions.openDialog} css={styles.button}>
        <span>
          {" "}
          {props.title ? props.title : "Fazer upload do arquivo excel"}
        </span>
        <Icon use="upload" size={20} style={{ marginTop: -4 }} />
      </OutlinedButton>
      {showDialog ? (
        <BatchFileUploaderDialog
          open={showDialog}
          onClose={actions.closeDialog}
          onUpload={_onUpload.current}
          onDownloadModelClick={_onDownloadModelClick.current}
          onSuccessUpload={_onSuccessUpload.current}
        />
      ) : null}
    </>
  );
}
