import { GetTravelApprovalStatusResponse } from "../dtos/approval-status.dto";
import { TravelApprovalStatus } from "../models/approval-status.model";

export const parseTravelApprovalStatus = (
  dto: GetTravelApprovalStatusResponse,
) => {
  const travelApprovalStatus: TravelApprovalStatus = {
    allNextApprovers: dto.all_next_approvers,
    allPreviousStagesApprovers: dto.all_previous_stages_approvers,
    approvalToken: dto.approval_token,
    currentApprovers: dto.current_approvers,
    isCancelable: dto.is_cancelable,
    lastRequestCanceled: dto.last_request_canceled,
    lastRequestStatus: dto.last_request_status,
    mustRequestApproval: dto.must_request_approval,
    pendingApprovalRequestToken: dto.pending_approval_request_token,
    status: dto.last_request_status,
  };

  return travelApprovalStatus;
};
