import React from "react";

import { ServiceType } from "~/apps/shared/constants/enums";

import { OfferSearcherDialog } from "../../../../../../components/offer-searcher-dialog/offer-search-dialog";
import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItinerary } from "../../../itinerary.context";

export const TravelPlanAddServiceDialog: React.FC = () => {
  const { travelToken } = useItinerary();
  const {
    closeAddServiceDrawer,
    isAddServiceDrawerOpen,
    selectedAddServiceServiceData,
    selectedAddServiceServiceType,
  } = useItineraryScreen();

  if (!selectedAddServiceServiceType || !travelToken) {
    return null;
  }

  return (
    <OfferSearcherDialog
      data={
        selectedAddServiceServiceData
          ? selectedAddServiceServiceData
          : undefined
      }
      onClose={closeAddServiceDrawer}
      open={isAddServiceDrawerOpen}
      title="Pesquisar serviço para adicionar à viagem:"
      travelToken={travelToken}
      type={
        selectedAddServiceServiceType as
          | ServiceType.BUS
          | ServiceType.CAR
          | ServiceType.FLIGHT
          | ServiceType.HOTEL
      }
    />
  );
};
