import React from "react";
import { TElementRef } from "../../../types";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Column = React.forwardRef(
  // tslint:disable-next-line:no-shadowed-variable
  ({ children, style, ...rest }: Props, ref: TElementRef<HTMLDivElement>) => (
    <div
      {...rest}
      ref={ref}
      style={{
        ...style,
        display: "flex",
        flexDirection: "column"
      }}
    >
      {children}
    </div>
  )
);

export { Column };
