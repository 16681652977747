import { TravelApprovalStatusModel } from "~/apps/corporate/models/approval-status.model";
import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { UserModel } from "~/apps/corporate/models/user.model";

class ItineraryTravelPlanPresenter {
  constructor(
    private readonly infoModel: ItineraryInfoModel,
    private readonly travelApprovalStatusModel: TravelApprovalStatusModel | null,
    private readonly userModel: UserModel,
  ) {}

  public canAddOffers() {
    return (
      this.infoModel.isPossibleToAddOrAlterOffers() &&
      (this.isUserBooker() || this.isUserMasterApprover())
    );
  }

  public canCancelApprovalRequest() {
    return this.isUserBooker() || this.isUserMasterApprover();
  }

  public canEditCategorization() {
    return this.isUserBooker() || this.isUserMasterApprover();
  }

  public canEditTravelName() {
    return this.isUserBooker() || this.isUserMasterApprover();
  }

  public canEditTravelTags() {
    return this.isUserBooker() || this.isUserMasterApprover();
  }

  public canGoToApprovalReview() {
    return this.isUserAmongCurrentApprovers() || this.isUserMasterApprover();
  }

  private isUserAmongApprovers() {
    return this.travelApprovalStatusModel
      ? this.travelApprovalStatusModel.isUserAmongApprovers(this.userModel)
      : false;
  }

  private isUserAmongCurrentApprovers() {
    return this.travelApprovalStatusModel
      ? this.travelApprovalStatusModel.isUserAmongCurrentApprovers(
          this.userModel,
        )
      : false;
  }

  private isUserBooker() {
    return this.infoModel.isUserBooker(this.userModel);
  }

  private isUserMasterApprover() {
    return this.userModel.isMasterApprover();
  }

  private isUserTraveler() {
    return this.infoModel.isUserTraveler(this.userModel);
  }

  public isUserAuthorizedToAccessTravelPlan() {
    return (
      this.isUserAmongApprovers() ||
      this.isUserBooker() ||
      this.isUserMasterApprover() ||
      this.isUserTraveler()
    );
  }
}

export class ItineraryTravelPlanPresenterFactory {
  static create(
    infoModel: ItineraryInfoModel,
    travelApprovalStatusModel: TravelApprovalStatusModel | null,
    userModel: UserModel,
  ): ItineraryTravelPlanPresenter {
    return new ItineraryTravelPlanPresenter(
      infoModel,
      travelApprovalStatusModel,
      userModel,
    );
  }
}
