import { GroupBase, StylesConfig } from "react-select";

import { css } from "@styled-system/css";
import { ApprovalProcessSearchItem } from "~/apps/corporate/models/approval-process.model";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  }),
  select: <
    StylesConfig<
      ApprovalProcessSearchItem,
      boolean,
      GroupBase<ApprovalProcessSearchItem>
    >
  >{
    control: (baseStyles) => ({
      ...baseStyles,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow: "none",
      cursor: "pointer",
      height: "auto",
      outline: "none",
      "&:focus-within": {
        border: `1px solid ${theme.colors.gray[500]}`,
      },
      "&:hover": {
        borderColor: theme.colors.gray[300],
      },
    }),
    loadingMessage: (styles) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      overflow: "hidden",
      padding: "12px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: theme.colors.background.gray,
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      margin: "0",
      padding: "4px",
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      padding: "0 8px 0 0",
      paddingLeft: "0",
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "4px",
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.colors.pink[500],
        color: theme.colors.white,
      },
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      overflow: "hidden",
      padding: "12px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    option: (baseStyles, { isSelected }) => ({
      ...baseStyles,
      backgroundColor: "unset",
      color: isSelected ? theme.colors.gray[700] : theme.colors.gray[300],
      cursor: "pointer",
      padding: "12px 16px",
      "&:hover": {
        backgroundColor: theme.colors.background.gray,
        color: theme.colors.gray[700],
      },
    }),
    valueContainer: (baseStyles, { hasValue }) => ({
      ...baseStyles,
      padding: "8px",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "1.25",
  }),
};
