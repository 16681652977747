import React from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

import { Icon } from "~/apps/shared/components/icon/icon";

import {
  OrderableListProvider,
  useOrderableListContext
} from "./OrderableList.context";
import {
  OrderableListProps,
  OrderableListContainerProps,
  OrderableListItemProps
} from "./OrderableList.types";
import { Text } from "@toolkit";

const styles = {
  list: css({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  }),
  itemContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.colors.background.gray
  }),
  number: css({
    textAlign: "center",
    width: "1.625rem",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    marginRight: "0.5rem",
    backgroundColor: theme.colors.pink[500],
    color: theme.colors.white,
    fontSize: "0.75rem",
    fontWeight: "bold",
    lineHeight: "1.125rem"
  }),
  arrowButton: css({
    display: "flex",
    justifyContent: "center",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    backgroundColor: theme.colors.gray[500],
    cursor: "pointer"
  })
};

export default function OrderableList<T = unknown>(
  props: OrderableListProps<T>
) {
  const { renderItem, ...rest } = props;

  return (
    <OrderableListProvider {...rest}>
      <OrderableListContainer renderItem={props.renderItem} />
    </OrderableListProvider>
  );
}

function OrderableListContainer<T>(props: OrderableListContainerProps<T>) {
  const { renderItem } = props;

  const { value } = useOrderableListContext<T>();

  return (
    <ul css={styles.list}>
      {value?.map((option, index) => (
        <OrderableListItem<T>
          key={index}
          index={index}
          option={option}
          renderItem={renderItem}
        />
      ))}
    </ul>
  );
}

function OrderableListItem<T>(props: OrderableListItemProps<T>) {
  const { option, index, renderItem } = props;

  const {
    value,
    onArrowDownClick,
    onArrowUpClick
  } = useOrderableListContext<T>();

  const canClickUp = index !== 0;
  const canClickDown = index !== value?.length - 1;

  return (
    <li css={styles.itemContainer}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text as="span" css={styles.number}>
          {index + 1}
        </Text>
        {renderItem(option)}
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        {canClickUp ? (
          <div css={styles.arrowButton} onClick={onArrowUpClick(index)}>
            <Icon use="arrow-up" size={20} />
          </div>
        ) : null}
        {canClickDown ? (
          <div css={styles.arrowButton} onClick={onArrowDownClick(index)}>
            <Icon use="arrow-down" size={20} />
          </div>
        ) : null}
      </div>
    </li>
  );
}
