import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "emotion";
import { defaultTheme } from "../../assets/styles/theme";

const styles = {
  root: css({
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.6)"
  }),
  circularProgress: css({
    color: defaultTheme.primaryColor
  })
};

const spinnerRoot = document.getElementById("spinner-root");

class Spinner extends React.Component {
  el = document.createElement("div");
  componentDidMount() {
    spinnerRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    spinnerRoot.removeChild(this.el);
  }
  render() {
    const { dispatch, ...props } = this.props; // eslint-disable-line no-unused-vars

    return ReactDOM.createPortal(
      <div className={styles.root}>
        <CircularProgress className={styles.circularProgress} size={80} />
      </div>,
      this.el
    );
  }
}

const SpinnerPortal = ({ visible, ...props }) => {
  return visible ? <Spinner {...props} /> : null;
};

SpinnerPortal.propTypes = {
  visible: PropTypes.bool
};

SpinnerPortal.defaultProps = {
  visible: false
};

export default SpinnerPortal;
