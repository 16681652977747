import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
  }),
  records: {
    empty: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    record: {
      approver: {
        name: css({
          fontWeight: 600,
        }),
        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          paddingLeft: "24px",
        }),
      },
      date: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
      request: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        paddingLeft: "24px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      status: {
        status: ({ color }: { color: string }) =>
          css({
            color,
            fontSize: "0.875rem",
            fontWeight: 600,
          }),

        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          paddingLeft: "24px",
        }),
      },
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    }),
  },
  root: css({
    ".MuiDialog-paper": {
      overflowY: "auto",
      "@media (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
};
