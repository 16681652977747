import { useEffect, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ERROR } from "~/apps/shared/constants/errors";
import { PageError } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { useItineraryInfo } from "../itinerary/itinerary-info.context";
import { useItineraryServices } from "../itinerary/itinerary-services.context";

export const useApprovalReviewGuard = () => {
  const { user } = useUser();

  const {
    errorOnFetch: errorOnFetchTravelApproval,
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
  } = useItineraryApproval();
  const { errorOnFetch: errorOnFetchItineraryInfo } = useItineraryInfo();
  const {
    errorOnFetchTravelPoliciesPerOffer: errorOnFetchItineraryTravelPoliciesPerOffer,
  } = useItineraryServices();

  const [enabled, setEnabled] = useState(false);
  const [error, setError] = useState<PageError | null>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    void (async () => {
      const [
        travelApprovalHistoryModel,
        travelApprovalStatusModel,
      ] = await Promise.all([
        fetchItineraryApprovalHistory(),
        fetchItineraryApprovalStatus(),
      ]);

      if (!travelApprovalHistoryModel || !travelApprovalStatusModel) {
        logger.error(
          "could not fetch the approval history or the approval status",
        );

        return;
      }

      if (!travelApprovalHistoryModel.isPendingApproval()) {
        setError({
          ...ERROR.UNEXPECTED_NOT_PENDING_APPROVAL_TRAVEL_APPROVAL_REVIEW_ACCESS,
          navigateMessage: "Voltar para minhas aprovações",
          navigateTo: () => {
            navigate("/approvals");
          },
        });

        return;
      }

      const isUserAmongCurrentApprovers = travelApprovalStatusModel.isUserAmongCurrentApprovers(
        user,
      );

      const isUserMasterApprover = user.isMasterApprover();

      const isUserAuthorizedToAccessApprovalReview =
        isUserAmongCurrentApprovers || isUserMasterApprover;

      if (!isUserAuthorizedToAccessApprovalReview) {
        setError({
          ...ERROR.UNAUTHORIZED_ACCESS_TO_APPROVAL_REVIEW,
          navigateMessage: "Voltar para minhas aprovações",
          navigateTo: () => {
            navigate("/approvals");
          },
        });

        return;
      }

      setEnabled(true);

      setError(null);
    })();
  }, [fetchItineraryApprovalHistory, fetchItineraryApprovalHistory, user]);

  return {
    enabled,
    error:
      errorOnFetchItineraryInfo ||
      errorOnFetchTravelApproval ||
      errorOnFetchItineraryTravelPoliciesPerOffer ||
      error,
  };
};
