import React, { useMemo, useState } from "react";

import {
  OrderableListOptionType,
  OrderableListContextType,
  OrderableListProviderProps
} from "./OrderableList.types";

const OrderableListContext = React.createContext<OrderableListContextType>(
  {} as OrderableListContextType
);

function move<T>(array: T[], from: number, to: number) {
  const newArray: T[] = [...array];

  const item = newArray.splice(from, 1)[0];

  newArray.splice(to, 0, item);

  return newArray;
}

export function OrderableListProvider<T>(props: OrderableListProviderProps<T>) {
  const { children, onChange, value: _value } = props;

  const [value, setValue] = useState<OrderableListOptionType<T>[]>(
    _value || []
  );

  const state = useMemo(
    () => ({
      value,
      onArrowUpClick: (index: number) => {
        return function () {
          if (index === 0) {
            return;
          }

          const newIndex = index - 1;

          const newOrder = move(value, index, newIndex);

          setValue(newOrder);

          onChange && onChange(newOrder);
        };
      },
      onArrowDownClick: (index: number) => {
        return function () {
          if (index === value.length - 1) {
            return;
          }

          const newIndex = index + 1;

          const newOrder = move(value, index, newIndex);

          setValue(newOrder);

          onChange && onChange(newOrder);
        };
      }
    }),
    [value, onChange]
  );

  return (
    <OrderableListContext.Provider value={state}>
      {children}
    </OrderableListContext.Provider>
  );
}

export function useOrderableListContext<T>() {
  const context = React.useContext<OrderableListContextType<T>>(
    OrderableListContext
  );

  if (!context) {
    throw new Error(
      "useOrderableListContext só pode ser usado dentro de um OrderableListProvider"
    );
  }

  return context;
}
