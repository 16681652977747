import React from "react";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

import { darken } from "@material-ui/core";
import { navigate } from "@reach/router";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { defaultTheme } from "~/assets/styles/theme";

import SSOIcon from "@assets/images/integrations/sso-icon.svg";
import { colors } from "@assets/styles/theme/constants";

import { ContainedButton } from "@components/shared/buttons/contained-button";

import { MoreActionsPopOver } from "../../../../../../../components/configuration/shared/MoreActionsPopOver/MoreActionsPopOver";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "24.25rem",
    padding: "1rem",
    border: `1px solid ${defaultTheme.grays.veryLight}`,
    borderRadius: "0.5rem",
  }),
  header: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  title: css({
    fontWeight: "bold",
  }),
  description: css({
    color: colors.steelBlue,
    textTransform: "uppercase",
    fontSize: "0.625rem",
  }),
  text: css({
    color: colors.steelBlue,
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
  }),
  active: css({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    color: colors.jungleGreen,
    fontWeight: "bold",
  }),
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
};

export type IntegrationCardProps = {
  title: string;
  description: string;
  text: string;
  handleDeleteConfig: () => void;
  isLoading: boolean;
  isIntegrated: boolean;
};

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  title,
  description,
  text,
  handleDeleteConfig,
  isLoading,
  isIntegrated,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
          <img
            alt="Ícone (SSO)"
            height={40}
            src={SSOIcon}
            style={{ borderRadius: "0.25rem" }}
            width={40}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.375rem",
            }}
          >
            <p css={styles.title}>{title}</p>
            <span css={styles.description}>{description}</span>
          </div>
        </div>
        {isIntegrated ? (
          <MoreActionsPopOver item={{}} handleDelete={handleDeleteConfig} />
        ) : null}
      </div>
      <p css={styles.text}>{text}</p>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Skeleton style={{ height: 38, width: 110 }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: isIntegrated ? "space-between" : "flex-end",
            alignItems: "center",
          }}
        >
          {isIntegrated ? (
            <div css={styles.active}>
              <FaCheck style={{ fontSize: "0.875rem" }} />
              <span>Ativo</span>
            </div>
          ) : null}
          <div style={{ alignSelf: "flex-end" }}>
            <ContainedButton
              css={styles.button}
              onClick={() => navigate("/configurations/integrations/sso")}
            >
              Configurar
            </ContainedButton>
          </div>
        </div>
      )}
    </div>
  );
};
