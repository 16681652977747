import React from "react";

import { HotelOutOfPolicyDialog as OutOfPolicyDialog } from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";

import { useHotelResult } from "../hotel-result.context";

export const HotelResultOutOfPolicyDialog: React.FC = () => {
  const {
    outOfPolicySelectedOffer: offer,
    hotelInfo: hotel,
    policy,
    handleCloseOutOfPolicyDialog,
    searchInfo,
  } = useHotelResult();

  if (hotel && offer && policy && searchInfo) {
    const visible = !!offer;
    const selectedOffer = {
      advance: searchInfo.advanceDays,
      price: offer.nightlyPrice.value,
      stars: hotel.stars,
      totalGuests: searchInfo.totalGuests,
    };

    return (
      <div>
        <OutOfPolicyDialog
          offer={selectedOffer}
          onClose={handleCloseOutOfPolicyDialog}
          open={visible}
          policy={policy}
        />
      </div>
    );
  }

  return null;
};
