import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    "& > svg": {
      strokeWidth: 2,
    },
  }),
  root: css({
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: 500,
    gap: "8px",
    padding: "4px 16px",
  }),
};
