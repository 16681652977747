import {
  RealTimeTravelDto,
  GetFlightInfoDto,
  GetHotelInfoDto
} from "@dtos/analytics.dto";

const MONTH_MAP: Record<number, string> = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez"
};

export function parseRealTimeTravels(dto: RealTimeTravelDto) {
  return {
    travelToken: dto.travel_token,
    travelerName: dto.full_name,
    travelName: dto.travel_name,
    travelStart: dto.travel_start,
    travelEnd: dto.travel_end,
    originCity: dto.origin_city,
    destinationCity: dto.destination_city,
    latitude: dto.latitude,
    longitude: dto.longitude,
    costCenterName: dto.cost_center_name,
    companyAreaName: dto.company_area_name,
    billingProfileName: dto.billing_profile_name,
    travelerToken: dto.traveler_token,
    bookerToken: dto.booker_token
  };
}

export function parseFlightsInfo(dto: GetFlightInfoDto) {
  const spentThroughtTime = dto.spentThroughtTime.map(item => ({
    total: item.total,
    name: `${MONTH_MAP[item.month]}/${item.year.toString().slice(2, 4)}`
  }));

  const spentByDestination = dto.spentByDestination.map(item => ({
    count: item.count,
    total: item.total,
    maxPrice: item.max_price,
    average: item.average,
    minPrice: item.min_price,
    origin: item.origin,
    destination: item.destination
  }));

  const advanceRange = dto.advanceRange.map(item => ({
    advanceRange: item.advance_range,
    count: item.count
  }));

  return {
    spentThroughtTime,
    spentByDestination,
    spentByCompany: dto.spentByCompany,
    advanceRange,
    spent: dto.spent,
    cancelsInfo: dto.cancelsInfos,
    altersInfo: dto.altersInfo,
    generatedAt: dto.generatedAt
  };
}

export function parseHotelInfo(dto: GetHotelInfoDto) {
  const spentThroughtTime = dto.spentThroughtTime.map(item => ({
    total: item.total,
    name: `${MONTH_MAP[item.month]}/${item.year.toString().slice(2, 4)}`
  }));

  const spentByDestination = dto.spentByDestination.map(item => ({
    count: item.count,
    total: item.total,
    nightAverage: item.night_average,
    totalAverage: item.total_average,
    durationAverage: item.duration_average,
    destination: item.destination
  }));

  const spentByHotel = dto.spentByHotel.map(item => ({
    count: item.count,
    total: item.total,
    nightAverage: item.night_average,
    totalAverage: item.total_average,
    durationAverage: item.duration_average,
    hotelName: item.hotel_name,
    destination: item.destination
  }));

  const advanceRange = dto.advanceRange.map(item => ({
    advanceRange: item.advance_range,
    count: item.count
  }));

  return {
    spentThroughtTime,
    spentByDestination,
    spentByHotel,
    advanceRange,
    spent: dto.spent,
    generatedAt: dto.generatedAt
  };
}
