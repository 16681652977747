import React, { ChangeEvent } from "react";

import CreditCardIcon from "@material-ui/icons/CreditCardRounded";
import { css } from "emotion";

import { InputProps } from "~/components/shared/inputs/Input";

import { defaultTheme } from "../../../../assets/styles/theme";
import { CARD_MASKS } from "../../../../constants/credit-card.constant";
import * as utils from "../../../../helpers";
import { MaskedInput } from "../../../shared/inputs";
import { CardIssuer } from "../CardCreation.types";

const styles = {
  cardIcon: css({
    color: defaultTheme.subTextColor,
    width: 40
  }),
  issuerLogo: css({
    width: 40
  })
};

type CardNumberInputProps = InputProps & {
  issuer?: CardIssuer | null;
};

const MASKS: { [key: number]: any } = CARD_MASKS;

const CardNumberInput = (props: CardNumberInputProps) => {
  const { value, issuer, label, placeholder, onChange, ...rest } = props;

  const getMask = (inputValue: string) => {
    let maskLength: number | null = 16;
    const formattedValue = utils.removeBlankSpaces(inputValue);

    const maxLength = issuer ? Math.max.apply(null, issuer.lengths) : 16;

    if (formattedValue.length > maxLength) {
      maskLength = maxLength;
    } else if (issuer) {
      maskLength = issuer.lengths.find(length => {
        return formattedValue.length <= length;
      })!;
    }

    return MASKS[maskLength];
  };

  const getIssuerLogo = () => {
    if (issuer) {
      return <img src={issuer.logo} className={styles.issuerLogo} />;
    } else {
      return <CreditCardIcon className={styles.cardIcon} />;
    }
  };

  return (
    <MaskedInput
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      mask={getMask}
      {...rest}
      InputProps={{
        endAdornment: getIssuerLogo()
      }}
    />
  );
};

export { CardNumberInput };
