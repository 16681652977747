import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  form: {
    root: css({
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    marginTop: "223px",
    position: "relative",
    "@media screen and (max-width: 992px)": {
      marginTop: "unset",
      "& > :first-of-type": {
        display: "none",
      },
    },
  }),
  tabs: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderRadius: "calc(60px / 2)",
      display: "flex",
      gap: "8px",
      left: "24px",
      padding: "8px",
      position: "absolute",
      top: "calc(-60px + 8px)",
      width: "fit-content",
      "@media screen and (max-width: 992px)": {
        backgroundColor: theme.colors.background.white,
        borderRadius: "unset",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        display: "flex",
        gap: "8px",
        overflowY: "auto",
        left: "unset",
        padding: "12px 24px 0 24px",
        position: "relative",
        top: "unset",
        width: "100%",
        zIndex: 1,
        "::-webkit-scrollbar": {
          display: "none",
        },
      },
    }),
    tab: ({ active }: { active: boolean }) => ({
      label: css({
        fontFamily: theme.fonts.inter,
        fontWeight: 500,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
          fontWeight: active ? 600 : 500,
        },
      }),
      root: css({
        alignItems: "center",
        backgroundColor: active ? theme.colors.gray[500] : "transparent",
        border: "none",
        borderRadius: "calc(44px / 2)",
        color: active ? theme.colors.white : theme.colors.gray[100],
        cursor: "pointer",
        display: "flex",
        flexShrink: 0,
        gap: "8px",
        padding: "12px 24px",
        transition: "all 0.15s ease-in-out",
        "&:disabled": {
          cursor: "default",
          opacity: 0.5,
        },
        "&:hover": {
          backgroundColor: active ? theme.colors.gray[700] : "transparent",
          color: active ? theme.colors.white : theme.colors.gray[700],
        },
        "@media screen and (max-width: 992px)": {
          backgroundColor: "transparent",
          borderBottom: active
            ? `2px solid ${theme.colors.gray[500]}`
            : "2px solid transparent",
          borderRadius: "unset",
          color: active ? theme.colors.gray[500] : theme.colors.gray[100],
          "&:hover": {
            backgroundColor: "transparent",
            color: active ? theme.colors.gray[700] : theme.colors.gray[700],
          },
        },
      }),
    }),
  },
};
