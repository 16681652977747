import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  switch: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      width: "fit-content",
      padding: "0.75rem 1rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
      cursor: "pointer",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
  radioGroup: {
    root: css({
      margin: "0.5rem 0 0 1.125rem",
    }),
    radio: css({
      ["&.Mui-checked"]: {
        color: `${theme.colors.pink[500]} !important`,
      },
    }),
    label: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
  },
};
