import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  select: {
    day: css({
      maxWidth: "94.48px",
      width: "fit-content",
    }),
    label: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    month: css({
      maxWidth: "103.53px",
      width: "fit-content",
    }),
    root: css({
      display: "flex",
      gap: "16px",
    }),
    year: css({
      maxWidth: "114.2px",
      width: "fit-content",
    }),
  },
};
