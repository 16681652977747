import { css } from "@styled-system/css";

export const styles = {
  body: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      width: "100%",
    }),
  },
  checkbox: {
    root: css({
      display: "flex",
      justifyContent: "center",
      paddingTop: "4px",
      minWidth: "18px",
    }),
  },
  root: ({ isDisabled }: { isDisabled: boolean }) =>
    css({
      display: "flex",
      gap: "16px",
      opacity: isDisabled ? 0.75 : 1,
      width: "100%",
    }),
};
