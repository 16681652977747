import React from "react";
import { Select } from "@shared/inputs";
import { CostCenterListByUserItem } from "~/models/cost-center.model";
import {
  useReportCategoryFormContext,
  useReportCategoryFormActionsContext
} from "../ReportApprovalReview.context";

const isOptionDisabled = (option: any) => {
  if (option.budgetExtrapolated || option.vaultExtrapolated) {
    return true;
  }

  return false;
};

const isOptionSelected = (
  selectedCostCenter: CostCenterListByUserItem | null
) => (costCenter: CostCenterListByUserItem) => {
  if (selectedCostCenter && costCenter.token === selectedCostCenter.token) {
    return true;
  }

  return false;
};

const CostCenterSelectContainer = () => {
  const { costCenters, selectedCostCenter } = useReportCategoryFormContext();
  const {
    handleChangeCostCenter,
    shouldCostCenterSelectBeVisible
  } = useReportCategoryFormActionsContext();

  if (shouldCostCenterSelectBeVisible()) {
    return (
      <Select
        placeholder="Centro de custos"
        onChange={handleChangeCostCenter}
        options={costCenters}
        value={selectedCostCenter}
        isOptionDisabled={isOptionDisabled}
        isOptionSelected={isOptionSelected(selectedCostCenter)}
      />
    );
  }

  return null;
};

export { CostCenterSelectContainer as CostCenterSelect };
