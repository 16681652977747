import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  paragraph: {
    paragraph: css({
      color: theme.colors.gray[700],
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.5rem",
    fontWeight: 600,
  }),
};
