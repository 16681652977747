import React, { createContext, useContext, useState } from "react";
import * as supportEvaluationService from "./SupportEvaluation.service";
import { SUPPORT_EVALUATION_RESPONSE_TYPES } from "@constants";
import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

const SupportEvaluationContext = createContext();
const SupportEvaluationActionsContext = createContext();

export const useSupportEvaluationContext = () =>
  useContext(SupportEvaluationContext);

export const useSupportEvaluationActionsContext = () =>
  useContext(SupportEvaluationActionsContext);

const initialState = {
  description: null,
  evaluationToken: null,
  isFetchingStatus: false,
  isSubmitting: false,
  submitted: false,
};

export const SupportEvaluationProvider = ({children}) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const fetchEvaluationStatus = async (evaluationToken) => {
    setState((prevState) => ({ ...prevState, isFetchingStatus: true }));

    const { data, error } = await supportEvaluationService.getEvaluationStatus(
      evaluationToken,
    );

    if (error) {
      setState((prevState) => ({ ...prevState, isFetchingStatus: false }));
      
      await returnToHome();

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data.active) {
      setState((prevState) => ({ ...prevState, isFetchingStatus: false }));
      
      return handleInactiveEvaluation(data.type);
    }

    setState((prevState) => ({
      ...prevState,
      description: data.description,
      evaluationToken: evaluationToken,
      isFetchingStatus: false,
    }));
  };

  const handleSubmitEvaluation = async (formData) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const { error } = await supportEvaluationService.submitSupportEvaluation(
      state.evaluationToken,
      formData,
    );

    if (error) {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prevState) => ({
      ...prevState,
      isSubmitting: false,
      submitted: true,
    }));
    showSnackMessage("Avaliação realizada com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const handleInactiveEvaluation = async (type) => {
    let message;
    if (
      type ===
      SUPPORT_EVALUATION_RESPONSE_TYPES.SUPPORT_EVALUATION_ALREADY_ANSWERED
    ) {
      message = "Essa avaliação de suporte já foi realizada.";
    } else if (
      type === SUPPORT_EVALUATION_RESPONSE_TYPES.SUPPORT_EVALUATION_EXPIRED
    ) {
      message = "O periodo para realizar essa avaliação expirou.";
    }

    await returnToHome();
    
    showSnackMessage(message, ALERT_TYPES.SUCCESS);
  };

  const returnToHome = async () => {
    await navigate("/");
  };

  return (
    <SupportEvaluationContext.Provider value={state}>
      <SupportEvaluationActionsContext.Provider
        value={{
          fetchEvaluationStatus,
          handleSubmitEvaluation,
        }}
      >
        {children}
      </SupportEvaluationActionsContext.Provider>
    </SupportEvaluationContext.Provider>
  );
};
