import { Path, Svg, View } from "@react-pdf/renderer";
import React, { cloneElement } from "react";

import { theme } from "@skin/v2";

import { Icon, icons } from "../icon/icon";
import { styles } from "./styles";

const getColor = (variant: "default" | "pink") => {
  switch (variant) {
    case "default":
      return theme.colors.gray[500];
    case "pink":
      return theme.colors.pink[500];
    default:
      return theme.colors.gray[500];
  }
};

const transform = (
  element: React.JSX.Element,
  props: Omit<Props, "use">,
): React.JSX.Element => {
  const { color, size } = props;

  if (React.isValidElement(element)) {
    if (element.type === "path") {
      const { d, fill, stroke, ...rest } = element.props as Omit<
        React.ComponentProps<typeof Path>,
        "children"
      >;

      return (
        <Path
          d={d}
          fill={fill === "currentColor" ? color : fill}
          stroke={stroke === "currentColor" ? color : stroke}
          {...rest}
        />
      );
    }

    if (element.type === "svg") {
      const { children, fill, stroke, viewBox } = element.props as Omit<
        React.ComponentProps<typeof Svg>,
        "children"
      > & {
        children?: React.JSX.Element | React.JSX.Element[];
        size?: number;
      };

      return (
        <Svg
          fill={fill === "currentColor" ? color : fill}
          height={size}
          stroke={stroke === "currentColor" ? color : stroke}
          viewBox={viewBox}
          width={size}
        >
          {children
            ? React.Children.map(children, (child) =>
                transform(child, {
                  ...child.props,
                  ...props,
                }),
              )
            : null}
        </Svg>
      );
    }

    return cloneElement(element);
  }

  return cloneElement(element);
};

type Props = Pick<React.ComponentProps<typeof Icon>, "size" | "use"> &
  React.ComponentProps<typeof View> & {
    color?: string;
    variant?: "default" | "pink";
  };

export const PDFIcon: React.FC<Props> = ({
  color,
  size,
  use,
  variant = "default",
  ...props
}) => {
  const rootStyles = styles.root({ size });

  const icon = icons[use];

  return (
    <View style={rootStyles.root} {...props}>
      {transform(icon, {
        color: color ? color : getColor(variant),
        size,
      })}
    </View>
  );
};
