import { ServiceType } from "~/apps/shared/constants/enums";
import { deepClone } from "~/apps/shared/utils/deep-clone";
import moment from "moment";

import * as itineraryHelper from "../../helpers/itinerary.helper";
import {
  ItineraryServiceModelFactory,
  ItineraryServiceSuggestion,
} from "./itinerary-service.model";
import {
  BusItineraryServicePresenter,
  CarItineraryServicePresenter,
  FlightItineraryServicePresenter,
  HotelItineraryServicePresenter,
  ItineraryServicePresenter,
  ItineraryServicePresenterFactory,
} from "./itinerary-service.presenter";
import { ItineraryServicesModel } from "./itinerary-services.model";

class ItineraryServiceSuggestionCalculator {
  constructor(protected hotelServices: HotelItineraryServicePresenter[]) {}

  public calculateSuggestion(servicePresenter: ItineraryServicePresenter) {
    if (
      !servicePresenter.isBusService() &&
      !servicePresenter.isFlightService()
    ) {
      return;
    }

    if (
      servicePresenter.isSameDayReturn() ||
      servicePresenter.isOutdated() ||
      servicePresenter.isUnavailable()
    ) {
      return;
    }

    const searchPeriod = servicePresenter.getSearchPeriod();

    if (this.hasAnyHotelInRange(searchPeriod, this.hotelServices)) {
      return;
    }

    const destinationCity = servicePresenter.getSearch().destinationCity;
    const destinationCountry = servicePresenter.getSearch().destinationCountry;

    const location = servicePresenter.isBusService()
      ? servicePresenter.getOutboundJourney().arrival.city
      : `${destinationCity}${
          destinationCountry ? `, ${destinationCountry}` : ""
        }`;

    const suggestion: ItineraryServiceSuggestion = {
      endDate: searchPeriod.endDate,
      location,
      startDate: searchPeriod.startDate,
      suggestionType: ServiceType.HOTEL,
    };

    return suggestion;
  }

  private hasAnyHotelInRange(
    period: {
      endDate?: moment.Moment;
      startDate: moment.Moment;
    },
    hotelServices: HotelItineraryServicePresenter[],
  ) {
    const { endDate, startDate } = period;

    return hotelServices.some((hotelService) => {
      const checkIn = hotelService.getCheckIn();

      return (
        checkIn.isSameOrAfter(startDate, "day") &&
        checkIn.isSameOrBefore(endDate, "day")
      );
    });
  }
}

export class ItineraryServicesPresenter {
  protected services: {
    busServices: BusItineraryServicePresenter[];
    carServices: CarItineraryServicePresenter[];
    flightServices: FlightItineraryServicePresenter[];
    hotelServices: HotelItineraryServicePresenter[];
  } = {
    busServices: [],
    carServices: [],
    flightServices: [],
    hotelServices: [],
  };

  constructor(services: {
    busServices?: BusItineraryServicePresenter[];
    carServices?: CarItineraryServicePresenter[];
    flightServices?: FlightItineraryServicePresenter[];
    hotelServices?: HotelItineraryServicePresenter[];
  }) {
    this.services = {
      ...this.services,
      ...services,
    };
  }

  public getAllServices(): ItineraryServicePresenter[] {
    return [
      ...this.getBusServices(),
      ...this.getCarServices(),
      ...this.getFlightServices(),
      ...this.getHotelServices(),
    ];
  }

  private getBusServices(): BusItineraryServicePresenter[] {
    return this.services.busServices;
  }

  private getCarServices(): CarItineraryServicePresenter[] {
    return this.services.carServices;
  }

  private getFlightServices(): FlightItineraryServicePresenter[] {
    return this.services.flightServices;
  }

  private getHotelServices(): HotelItineraryServicePresenter[] {
    return this.services.hotelServices;
  }

  public getServiceByOfferToken(offerToken: string) {
    const service = this.getAllServices().find(
      (service) => service.getOfferToken() === offerToken,
    );

    if (!service) {
      return null;
    }

    return service;
  }

  private getTravelPlanTabServices(services: ItineraryServicePresenter[]) {
    return services.filter((s) => s.belongsToTravelPlanTab());
  }

  public getTravelPlanTabServicesGroupedByDate() {
    const services = this.getAllServices();

    return this.groupServicesByDate(this.getTravelPlanTabServices(services));
  }

  private groupServicesByDate(services: ItineraryServicePresenter[]) {
    const servicesByDate = services.reduce((prev, curr) => {
      const period = curr.getPeriod();

      const date = period.startDate.format("YYYY-MM-DD");

      if (!(date in prev)) {
        prev[date] = [];
      }

      prev[date].push(curr);

      return prev;
    }, {} as Record<string, ItineraryServicePresenter[]>);

    const servicesGroupedByDate = Object.keys(servicesByDate).map((date) => ({
      date,
      services: servicesByDate[date],
    }));

    return servicesGroupedByDate.sort((a, b) => {
      const dateA = moment(a.date, "YYYY-MM-DD");
      const dateB = moment(b.date, "YYYY-MM-DD");

      return dateA.diff(dateB);
    });
  }

  public splitServicesByDateAndSetSuggestions(
    services: ItineraryServicePresenter[],
  ) {
    const suggestionCalculator = new ItineraryServiceSuggestionCalculator(
      this.getHotelServices(),
    );

    return services.reduce((prev, curr) => {
      if (curr.isBusService() && !curr.isOneWay()) {
        const service = curr.getService();

        const inboundService = deepClone(service);
        const outboundService = deepClone(service);

        const outboundServiceModel = ItineraryServiceModelFactory.createBus(
          outboundService,
        );
        const outboundServicePresenter = ItineraryServicePresenterFactory.createBus(
          outboundServiceModel,
        );

        outboundServicePresenter.presentationJourneys = outboundServicePresenter
          .getAllJourneys()
          .slice(0, 1);

        const serviceSuggestion = suggestionCalculator.calculateSuggestion(
          curr,
        );

        if (serviceSuggestion) {
          outboundServicePresenter.suggestion = serviceSuggestion;
        }

        const inboundServiceModel = ItineraryServiceModelFactory.createBus(
          inboundService,
        );
        const inboundServicePresenter = ItineraryServicePresenterFactory.createBus(
          inboundServiceModel,
        );

        inboundServicePresenter.presentationJourneys = inboundServicePresenter
          .getAllJourneys()
          .slice(
            inboundServicePresenter.getAllJourneys().length - 1,
            inboundServicePresenter.getAllJourneys().length,
          );

        return [...prev, outboundServicePresenter, inboundServicePresenter];
      }

      if (curr.isFlightService() && !curr.isOneWay()) {
        const service = curr.getService();

        const inboundService = deepClone(service);
        const outboundService = deepClone(service);

        const outboundServiceModel = ItineraryServiceModelFactory.createFlight(
          outboundService,
        );
        const outboundServicePresenter = ItineraryServicePresenterFactory.createFlight(
          outboundServiceModel,
        );

        outboundServicePresenter.presentationJourneys = outboundServicePresenter
          .getAllJourneys()
          .slice(0, 1);

        const serviceSuggestion = suggestionCalculator.calculateSuggestion(
          curr,
        );

        if (serviceSuggestion) {
          outboundServicePresenter.suggestion = serviceSuggestion;
        }

        const inboundServiceModel = ItineraryServiceModelFactory.createFlight(
          inboundService,
        );
        const inboundServicePresenter = ItineraryServicePresenterFactory.createFlight(
          inboundServiceModel,
        );

        inboundServicePresenter.presentationJourneys = inboundServicePresenter
          .getAllJourneys()
          .slice(
            inboundServicePresenter.getAllJourneys().length - 1,
            inboundServicePresenter.getAllJourneys().length,
          );

        return [...prev, outboundServicePresenter, inboundServicePresenter];
      }

      return [...prev, curr];
    }, [] as ItineraryServicePresenter[]);
  }
}

export class ItineraryServicesPresenterFactory {
  static create(services: ItineraryServicesModel) {
    const busServices = services
      .getBusServices()
      .map((s) => ItineraryServicePresenterFactory.createBus(s));

    const carServices = services
      .getCarServices()
      .map((s) => ItineraryServicePresenterFactory.createCar(s));

    const flightServices = services
      .getFlightServices()
      .map((s) => ItineraryServicePresenterFactory.createFlight(s));

    const hotelServices = services
      .getHotelServices()
      .map((s) => ItineraryServicePresenterFactory.createHotel(s));

    return new ItineraryServicesPresenter({
      busServices,
      carServices,
      flightServices,
      hotelServices,
    });
  }
}
