import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import {
  GetUserCompanyEventDto,
  ListEventParticipantsDto
} from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";
import * as yup from "yup";

import { UserModel } from "~/models/user.model";

import clientApi from "@apis/client.api";

import { useEvents } from "~/components/events/events.context";
import { Checkbox } from "~/components/shared/checkbox";
import { Form, useForm } from "~/components/shared/form";
import { Input } from "~/components/shared/inputs";
import { Spacing } from "~/components/shared/layout";

import { useEvent } from "../../../event.context";
import { EventOverviewParticipantsCombobox } from "../participants-combobox";
import { styles } from "./styles";

const validationSchema = yup.object({
  message: yup.string(),
  invited_users_tokens: yup
    .array()
    .of(
      yup.object({
        token: yup.string().required()
      })
    )
    .defined(),
  token: yup.string().required()
});

export type InviteEventParticipantsForm = yup.InferType<
  typeof validationSchema
>;

type Props = Omit<React.ComponentPropsWithoutRef<typeof Dialog>, "onClose"> & {
  event: GetUserCompanyEventDto;
  onClose: () => void;
  participants: ListEventParticipantsDto;
};

export const EventOverviewParticipantsInviteDialog: React.VFC<Props> = ({
  event,
  onClose,
  participants,
  ...props
}) => {
  const { invalidateParticipants } = useEvent();
  const { inviteEventParticipants } = useEvents();

  const { mutateAsync: inviteEventParticipantsMutation } = useMutation({
    mutationFn: async (form: InviteEventParticipantsForm) => {
      const result = await inviteEventParticipants(form);

      if (!result.success) {
        return;
      }

      await invalidateParticipants();
    }
  });

  const { data: users, isLoading: isLoadingUsers } = useQuery<UserModel[]>(
    ["users"],
    async () => {
      return await clientApi.getUsersRequest();
    }
  );

  const onSubmit = useCallback(
    async (values: InviteEventParticipantsForm) => {
      await inviteEventParticipantsMutation(values);

      onClose();
    },
    [inviteEventParticipantsMutation, onClose]
  );

  const form = useForm({
    defaultValues: {
      message: "",
      invited_users_tokens: [],
      token: event.token
    } as InviteEventParticipantsForm,
    onSubmit,
    schema: validationSchema
  });

  const [customMessage, setCustomMessage] = useState(false);

  if (!users) {
    return null;
  }

  return (
    <Dialog
      classes={{
        paper: styles.dialog.paper
      }}
      {...props}
    >
      <div css={styles.header}>
        <Text color={theme.colors.gray[4]} fontSize={24} fontWeight="bold">
          Convide Participantes
        </Text>
      </div>
      <Form context={form}>
        <DialogContent
          classes={{
            root: styles.dialog.content
          }}
        >
          <Form.Field
            name="invited_users_tokens"
            render={({ onChange, value }) => (
              <EventOverviewParticipantsCombobox
                aria-label="Convide participantes."
                onChange={onChange}
                options={users.map(user => {
                  const participant = participants.find(
                    participant => participant.user_token === user.userToken
                  );

                  return {
                    email: user.email,
                    ...(participant && {
                      invite_status: participant.invite_status
                    }),
                    name: user.fullName || user.email,
                    token: user.userToken
                  };
                })}
                placeholder="Digite o e-mail de um participante..."
                value={value}
              />
            )}
          />
          <Text color={theme.colors.gray[3]} fontSize={12}>
            Adicione e-mails um por um ou cole uma lista
          </Text>
          {customMessage ? (
            <>
              <Spacing direction="vertical" space="8px" />
              <Form.Field
                as={
                  <Input placeholder="Digite uma mensagem personalizada para o convite..." />
                }
                name="message"
              />
              <Text color={theme.colors.gray[3]} fontSize={12}>
                Essa mensagem será enviada para todos os participantes
                convidados.
              </Text>
            </>
          ) : null}
        </DialogContent>
        <DialogActions
          classes={{
            root: styles.dialog.actions
          }}
        >
          <Flex alignItems="center">
            <Checkbox
              checked={customMessage}
              onChange={e => {
                const customMessage = e.target.checked;

                if (!customMessage) {
                  form.setValue("message", "");
                }

                setCustomMessage(customMessage);
              }}
            />
            <Text fontSize={14}>Adicionar uma mensagem personalizada?</Text>
          </Flex>
          <Flex alignItems="center">
            <Button
              color="cancel"
              css={styles.button}
              disabled={isLoadingUsers}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Spacing direction="horizontal" space="8px" />
            <Button
              css={styles.button}
              disabled={
                !form.formState.isDirty ||
                form.formState.isSubmitting ||
                !form.formState.isValid ||
                isLoadingUsers
              }
              type="submit"
            >
              Convidar
            </Button>
          </Flex>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
