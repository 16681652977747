import moment from "moment";

interface DateRange {
  start: string | Date;
  end: string | Date;
}

export const DATE_FORMATS = {
  ISO: "YYYY-MM-DDThh:mm:ssZ",
  DATE_AND_HOUR: "DD/MM/YYYY HH:mm",
  DATE: "DD/MM/YYYY"
};

export function calculateDateRange(dateRanges: DateRange[]): DateRange {
  const startDates = dateRanges.map(range => range.start);
  const endDates = dateRanges.map(range => range.end);

  const min = startDates.reduce((a, b) => {
    return a < b ? a : b;
  });

  const max = endDates.reduce((a, b) => {
    return a > b ? a : b;
  });

  return {
    start: min,
    end: max
  };
}

export function calculateLastMonth(): moment.Moment {
  moment.locale("pt-BR");

  return moment().subtract(1, "month").add(1, "day");
}

export function formatDate(
  date: string | Date | number,
  format: string = DATE_FORMATS.ISO
): string {
  return moment(date).format(format);
}

export function formatDateAndHours(date: Date | string): string {
  return moment(new Date(date)).format("DD/MM/YYYY [às] HH:mm:ss");
}

export function convertDateToUTC(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return new Date(date.toUTCString());
}

export function getDateFromDashedDate(dashedDate: string): Date {
  const [year, month, day] = dashedDate.split("-");
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date;
}

export function setTimeBasedOnGivenDate(
  date: Date,
  hours: number,
  minutes = 0,
  seconds = 0
) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
    seconds
  );
}

export function getUTCStringTimeOnDashedDate(
  dashedDate: string,
  hours: number,
  minutes = 0,
  seconds = 0
): string {
  const [year, month, day] = dashedDate.split("-");
  const isoString = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      hours,
      minutes,
      seconds
    )
  ).toISOString();

  return isoString;
}

export function convertHoursAndMinutes(hoursAndMinutes: string): string {
  const [hours, minutes] = hoursAndMinutes.split(":");
  return hours === "00" ? `${minutes}m` : `${hours}h ${minutes}m`;
}

export const formatDateWithDayOfWeek = (date: Date | string) => {
  return moment(date).format("ddd, DD MMM YYYY");
};
