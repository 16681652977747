import * as clientApi from "~/apps/corporate/apis/client.api";
import * as tagsApi from "~/apps/corporate/apis/tags.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { ClientTag, Tag, TravelTag } from "../../../../../models/tags.models";

export const addTravelTag = async (tagToken: string, travelToken: string) => {
  const result: { data?: { travelTagToken: string }; error?: Error } = {};

  try {
    result.data = await tagsApi.addTravelTag(tagToken, travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_TAG_ERROR;
    }
  }

  return result;
};

export const archiveTag = async (tagToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await tagsApi.archiveTag(tagToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ARCHIVE_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};

export const changeTagOption = async (clientConfigToken: string, data: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await clientApi.updateClientConfig(clientConfigToken, data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_CONFIG_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};

export const createTag = async (tagName: string) => {
  const result: { data?: Tag; error?: Error } = {};

  try {
    result.data = await tagsApi.createTag(tagName);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_TAG_ERROR;
    }
  }

  return result;
};

export const editTag = async (tagName: string, tagToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await tagsApi.editTag(tagName, tagToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EDIT_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};

export const getAllClientTags = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await tagsApi.getAllClientTags();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};

export const getClientTags = async () => {
  const result: { data?: ClientTag[]; error?: Error } = {};

  try {
    result.data = await tagsApi.getClientTags();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    }
  }

  return result;
};

export const getClientTagOptions = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await clientApi.getClientConfig();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    }
  }

  return result;
};

export const getTravelTags = async (travelToken: string) => {
  const result: { data?: TravelTag[]; error?: Error } = {};

  try {
    result.data = await tagsApi.getTravelTags(travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    }
  }

  return result;
};

export const getUserTravelTags = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await tagsApi.getUserTravelTags();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_TAG_ERROR;
    }
  }

  return result;
};

export const removeTravelTag = async (travelTagToken: string) => {
  const result: { error?: Error } = {};

  try {
    await tagsApi.removeTravelTag(travelTagToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};

export const unarchiveTag = async (tagToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await tagsApi.unarchiveTag(tagToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UNARCHIVE_TAG_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.GENERAL_UNEXPECTED_ERROR;
    }
  }

  return result;
};
