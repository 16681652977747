import React from "react";

import { Link } from "@reach/router";
import { css } from "@styled-system/css";

import { MEDIA_QUERIES } from "@constants";

import { defaultTheme } from "@theme";

import { Column } from "@shared/layout/Column";
import { Desktop, TabletAndMobile } from "@shared/layout/Responsive";
import { Row } from "@shared/layout/Row";

const styles = {
  root: css({
    paddingBottom: "2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingBottom: "1.5rem"
    }
  }),
  card: css({
    width: "100%",
    padding: "1.25rem",
    borderRadius: 4,
    borderTopLeftRadius: 0,
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: ".75rem .5rem"
    }
  }),
  topText: css({
    paddingBottom: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 16,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".75rem"
    }
  }),
  bottomText: css({
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 16,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12
    }
  }),
  requestData: css({
    color: defaultTheme.subTextColor,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      width: 150,
      lineHeight: "15px"
    }
  }),
  clickableSpanDiv: css({
    [MEDIA_QUERIES.mobileBreakpoint]: {
      alignSelf: "flex-end"
    }
  }),
  clickableSpan: css({
    color: "#FF385C",
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "none",
    ["&:hover"]: {
      opacity: 0.8
    }
  }),
  tagRoot: css({
    color: "#FFF",
    fontSize: 11,
    fontWeight: "bold",
    padding: ".25rem .5rem",
    textTransform: "uppercase",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    lineHeight: "16.5px",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 8,
      lineHeight: "10.5px"
    }
  }),
  travelName: css({
    fontSize: 22,
    fontWeight: 600,
    paddingBottom: ".75rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 15
    }
  }),
  travelerName: css({
    fontSize: 20,
    paddingBottom: "1rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingBottom: ".75rem",
      fontSize: 14
    }
  })
};

interface Props {
  travelName: string;
  travelToken: string;
  travelerName: string;
  tagInfo: {
    text: string;
    color: string;
  };
  actionInfo: {
    text: string;
    link: string;
  };
  formattedDate: string;
  approvalDateText: string;
}

const TravelCardPresentational = ({
  travelName,
  travelToken,
  travelerName,
  tagInfo,
  actionInfo,
  formattedDate,
  approvalDateText
}: Props) => {
  return (
    <div css={styles.root}>
      <div style={{ display: "flex" }}>
        <div css={styles.tagRoot} style={{ backgroundColor: tagInfo.color }}>
          <span>{tagInfo.text}</span>
        </div>
      </div>
      <div css={styles.card}>
        <Column>
          <Row css={styles.topText}>
            <div>
              <span>{formattedDate}</span>
            </div>
            <div>
              <span>
                <Desktop>
                  <span>ID da Viagem</span>
                </Desktop>
                <TabletAndMobile>
                  <span>ID</span>
                </TabletAndMobile>
                : {travelToken}
              </span>
            </div>
          </Row>
          <div css={styles.travelName}>
            <span>{travelName}</span>
          </div>
          <div css={styles.travelerName}>
            <span>{travelerName}</span>
          </div>
          <Row css={styles.bottomText}>
            <div css={styles.requestData}>
              <span>{approvalDateText}</span>
            </div>
            <div css={styles.clickableSpanDiv}>
              <Link
                to={actionInfo.link}
                css={styles.clickableSpan}
                state={{ origin: "approvals" }}
              >
                <span>{actionInfo.text}</span>
              </Link>
            </div>
          </Row>
        </Column>
      </div>
    </div>
  );
};

export { TravelCardPresentational };
