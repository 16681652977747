import { customAlphabet } from "nanoid";

const alphabet =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateShortUniqueId = () => {
  const nanoid = customAlphabet(alphabet, 8);

  return nanoid();
};
