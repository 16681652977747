import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate } from "@reach/router";
import { FlightSearchInfo } from "~/apps/corporate/models/flight.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { useFlights } from "../flights.context";
import { styles } from "./styles";

export const FlightsHeader: React.FC = () => {
  const { flightInfo, isFakeLoading, isLoading } = useFlights();

  const navigateBack = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <div css={styles.root}>
      <Button css={styles.left.button} shape="icon" onClick={navigateBack}>
        <Icon size={16} use="arrow-left" />
      </Button>
      <div css={styles.right.root}>
        {isFakeLoading || isLoading ? (
          <FlightsHeaderSearchSkeleton />
        ) : flightInfo ? (
          <FlightsHeaderSearchInfo flightInfo={flightInfo} />
        ) : null}
      </div>
    </div>
  );
};

type FlightsHeaderSearchInfoProps = {
  flightInfo: FlightSearchInfo;
};

const FlightsHeaderSearchInfo: React.FC<FlightsHeaderSearchInfoProps> = ({
  flightInfo,
}) => {
  const { handleOpenEdition } = useFlights();

  const inboundInfo = !flightInfo.oneway
    ? {
        origin: flightInfo.destinationLocale,
        destination: flightInfo.originLocale,
        date: flightInfo.inboundFlightDate
          ? moment(flightInfo.inboundFlightDate).format("DD MMM")
          : "",
      }
    : null;

  const outboundInfo = {
    origin: flightInfo.originLocale,
    destination: flightInfo.destinationLocale,
    date: flightInfo.outboundFlightDate
      ? moment(flightInfo.outboundFlightDate).format("DD MMM")
      : "",
  };

  return (
    <>
      <div css={styles.right.search.root}>
        <span css={styles.right.search.field}>{outboundInfo.date}</span>
        <span css={styles.right.search.value}>
          {outboundInfo.origin} - {outboundInfo.destination}
        </span>
      </div>
      {inboundInfo ? (
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>{inboundInfo.date}</span>
          <span css={styles.right.search.value}>
            {inboundInfo.origin} - {inboundInfo.destination}
          </span>
        </div>
      ) : null}
      <Button onClick={handleOpenEdition} shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};

const FlightsHeaderSearchSkeleton: React.FC = () => {
  return (
    <>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <div css={styles.right.search.root}>
        <Skeleton
          css={styles.skeleton.right.search.field}
          height="20px"
          width="32px"
        />
        <Skeleton height="24px" width="96px" />
      </div>
      <Button disabled shape="icon" variant="pink">
        <Icon size={16} use="pencil" />
      </Button>
    </>
  );
};
