import React from "react";
import { Paper } from "../../shared/Paper";
import { PaperTitle } from "../../shared/PaperTitle";
import { useHotelInfoContext } from "../HotelInfo.context";
import { SpentThroughtTimeChart } from "./SpentThroughtTimeChart";
import { Box } from "@toolkit";

const SpentThroughtTime: React.FC = () => {
  const {
    hotelInfo: { spentThroughtTime }
  } = useHotelInfoContext();

  return (
    <Paper>
      <PaperTitle>Gasto ao longo do tempo</PaperTitle>
      <Box ml={-16}>
        <SpentThroughtTimeChart data={spentThroughtTime} />
      </Box>
    </Paper>
  );
};

export { SpentThroughtTime };
