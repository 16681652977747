import React, { useEffect, useMemo, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Box, Flex, Text } from "@toolkit";
import { css } from "@styled-system/css";
import moment from "moment";

import styled from "@shared/styled";
import DrawerHeader from "@shared/DrawerHeader";
import { MonthFilterDrawer, IMonthDateRange } from "./MonthFilterDrawer";
import { FilterSelect } from "./FilterSelect";
import { StContainedButton } from "~/components/shared/buttons";

const styles = {
  drawer: css({
    ["& .MuiPaper-root"]: {
      position: "relative",
      width: "100%",
      maxWidth: 500
    }
  })
};

const Label = styled(Text)(
  css({
    fontSize: 2,
    fontWeight: "bold",
    display: "block",
    lineHeight: "21px",
    mb: "small"
  })
);

const Divider = styled(Box)(
  css({
    my: 24,
    height: "1px",
    backgroundColor: "border"
  })
);

function getFormatedPeriod(
  startMonth: number,
  startYear: number,
  endMonth: number,
  endYear: number
) {
  const startDate = moment(new Date(startYear, startMonth - 1, 1));

  const endDate = moment(new Date(endYear, endMonth - 1, 1));

  return (
    moment(startDate).format("MMM YYYY") +
    " — " +
    moment(endDate).format("MMM YYYY")
  );
}

interface IFilters {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  costCenterToken: string;
  companyAreaToken: string;
  projectToken: string;
  billingProfileToken: string;
  tagToken?: string;
  travelerToken?: string;
}

interface IOption {
  value: string;
  label: string;
  [key: string]: any;
}

interface IFiltersProps {
  filterData: IFilters;
  costCenters: IOption[];
  tags?: IOption[];
  travelers?: IOption[];
  companyAreas: IOption[];
  projects: IOption[];
  billingProfiles: IOption[];
  isMonthPickerVisible: boolean;
  onChange(filterName: string): (value: string) => void;
  onSubmit(): void;
  onMonthRangeChange(range: IMonthDateRange): void;
  toggleMonthPickerVisibility(): void;
  onClose(): void;
}

const Filters: React.FC<IFiltersProps> = ({
  filterData,
  billingProfiles,
  companyAreas,
  costCenters,
  tags,
  travelers,
  projects,
  isMonthPickerVisible,
  onChange,
  onMonthRangeChange,
  onSubmit,
  toggleMonthPickerVisibility,
  onClose
}) => {
  const periodText = useMemo(() => {
    return getFormatedPeriod(
      filterData.startMonth,
      filterData.startYear,
      filterData.endMonth,
      filterData.endYear
    );
  }, [
    filterData.startMonth,
    filterData.startYear,
    filterData.endMonth,
    filterData.endYear
  ]);

  return (
    <>
      <DrawerHeader title="Filtros" handleClose={onClose} />
      <MonthFilterDrawer
        isOpen={isMonthPickerVisible}
        onClose={toggleMonthPickerVisibility}
        onRangeApply={onMonthRangeChange}
        startMonth={filterData.startMonth - 1}
        startYear={filterData.startYear}
        endMonth={filterData.endMonth - 1}
        endYear={filterData.endYear}
      />
      <Box
        pt={24}
        pb={150}
        px="large"
        height="calc(100vh - 143px)"
        overflowY="auto"
      >
        <Label>Período</Label>
        <Box
          p={12}
          border="1px solid"
          borderColor="border"
          lineHeight="19px"
          onClick={toggleMonthPickerVisibility}
        >
          {periodText}
        </Box>
        <Divider />
        <Label>Centro de custo</Label>
        <FilterSelect
          placeholder="Selecione o centro de custo"
          value={filterData.costCenterToken}
          options={costCenters}
          onChange={onChange("costCenterToken")}
        />
        <Divider />
        {/* {tags && (
          <>
            <Label>Etiqueta</Label>
            <FilterSelect
              placeholder="Selecione a etiqueta"
              value={filterData.tagToken || ""}
              options={tags}
              onChange={onChange("tagToken")}
            />
            <Divider />
          </>
        )} */}
        {travelers && (
          <>
            <Label>Viajantes</Label>
            <FilterSelect
              placeholder="Selecione o viajante"
              value={filterData.travelerToken || ""}
              options={travelers}
              onChange={onChange("travelerToken")}
            />
            <Divider />
          </>
        )}
        <Label>Área</Label>
        <FilterSelect
          placeholder="Selecione a área"
          value={filterData.companyAreaToken}
          options={companyAreas}
          onChange={onChange("companyAreaToken")}
        />
        <Divider />
        <Label>Projeto</Label>
        <FilterSelect
          placeholder="Selecione o projeto"
          value={filterData.projectToken}
          options={projects}
          onChange={onChange("projectToken")}
        />
        <Divider />
        <Label>Perfil de cobrança</Label>
        <FilterSelect
          placeholder="Selecione o perfil de cobrança"
          value={filterData.billingProfileToken}
          options={billingProfiles}
          onChange={onChange("billingProfileToken")}
        />
      </Box>
      <Flex p={24} alignItems="center">
        <StContainedButton onClick={onSubmit}>
          Filtrar resultados
        </StContainedButton>
      </Flex>
    </>
  );
};

interface IFilterDrawerProps {
  isOpen: boolean;
  filtersData: IFilters;
  costCenters: IOption[];
  tags?: IOption[];
  travelers?: IOption[];
  companyAreas: IOption[];
  projects: IOption[];
  billingProfiles: IOption[];
  onClose(): void;
  onSubmit(filters: IFilters): void;
}

const FiltersDrawer: React.FC<IFilterDrawerProps> = ({
  isOpen,
  filtersData,
  onClose,
  onSubmit,
  ...options
}) => {
  const [isMonthPickerVisible, setMonthPickerVisibility] = useState(false);
  const [filterState, setFilterState] = useState<IFilters>(filtersData);

  function toggleMonthPickerVisibility() {
    setMonthPickerVisibility(prevState => !prevState);
  }

  function handleChangeMonthRange(range: IMonthDateRange) {
    setFilterState(prevState => ({
      ...prevState,
      startMonth: range.startSelectedMonth + 1,
      startYear: range.startSelectedYear,
      endMonth: range.endSelectedMonth + 1,
      endYear: range.endSelectedYear
    }));
    setMonthPickerVisibility(false);
  }

  function handleChange(filterName: string) {
    return (value: string) => {
      setFilterState(prevState => ({
        ...prevState,
        [filterName]: value
      }));
    };
  }

  function handleSubmit() {
    onSubmit(filterState);
  }

  useEffect(() => {
    if (isOpen) {
      setFilterState(filtersData);
    }
  }, [isOpen]);

  return (
    <Drawer open={isOpen} css={styles.drawer} onClose={onClose}>
      <Filters
        {...options}
        filterData={filterState}
        isMonthPickerVisible={isMonthPickerVisible}
        onChange={handleChange}
        onMonthRangeChange={handleChangeMonthRange}
        toggleMonthPickerVisibility={toggleMonthPickerVisibility}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Drawer>
  );
};

export { FiltersDrawer };
