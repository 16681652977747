import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  autocomplete: css({
    width: "100%",
  }),
  input: {
    container: css({
      alignItems: "center",
      display: "flex",
      gap: "4px",
      width: "100%",
    }),
    input: css({
      backgroundColor: "transparent",
      border: "none",
      fontFamily: theme.fonts.inter,
      fontWeight: 600,
      height: "100%",
      outline: "none",
      padding: "14px 0",
      textOverflow: "ellipsis",
      width: "100%",
    }),
    label: css({
      flexShrink: 0,
      "@media screen and (max-width: 768px)": {
        fontSize: "0.875rem",
      },
    }),
    loading: css({
      flexShrink: 0,
    }),
    root: css({
      alignItems: "center",
      borderRadius: "8px",
      display: "flex",
      height: "100%",
      gap: "12px",
      paddingLeft: "16px",
      width: "100%",
    }),
  },
  item: {
    button: {
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      name: css({
        color: theme.colors.gray[700],
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[500],
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          padding: "12px 16px",
          transition: "all 0.15s ease-in-out",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ":not(:disabled)": {
            ":hover": {
              backgroundColor: theme.colors.background.gray,
              color: theme.colors.gray[700],
            },
          },
        }),
    },
    item: css({
      color: theme.colors.gray[500],
      display: "flex",
      fontSize: "0.875rem",
      padding: "12px 16px",
    }),
  },
};
