import React from "react";

import { EXPENSES_APPROVAL_LABELS } from "@constants";

import { ApprovableRule } from "@models/expense-approval.model";

import { ApprovalCardPresentational } from "./ApprovalCard.presentational";

interface Props {
  approvalRule: ApprovableRule;
  handleEdit: (approvalRule: ApprovableRule) => void;
  handleDelete: (approvalRule: ApprovableRule) => void;
}

const ApprovalCardContainer = ({
  approvalRule,
  handleEdit,
  handleDelete,
}: Props) => {
  const approvers = approvalRule.approvers
    .filter((item) => item.stage === 1)
    .map((item) => item.fullName)
    .join(", ");
  const approvers2 = approvalRule.approvers
    .filter((item) => item.stage === 2)
    .map((item) => item.fullName)
    .join(", ");
  const approvers3 = approvalRule.approvers
    .filter((item) => item.stage === 3)
    .map((item) => item.fullName)
    .join(", ");
  const approvers4 = approvalRule.approvers
    .filter((item) => item.stage === 4)
    .map((item) => item.fullName)
    .join(", ");
  const paymentApprovers = approvalRule.approvers
    .filter((item) => item.stage === 99)
    .map((item) => item.fullName)
    .join(", ");

  return (
    <ApprovalCardPresentational
      ruleItem={approvalRule}
      approvers={approvers}
      approvers2={approvers2}
      approvers3={approvers3}
      approvers4={approvers4}
      paymentApprovers={paymentApprovers}
      title={approvalRule.itemInfo.name}
      ruleLabel={EXPENSES_APPROVAL_LABELS[approvalRule.rule]}
      onEditClick={handleEdit}
      onDeleteClick={handleDelete}
    />
  );
};

export { ApprovalCardContainer };
