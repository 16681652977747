import { MONTH_MAP } from "~/apps/shared/constants";

import {
  GetFlightsAnalyticsInfoResponse,
  GetHotelsAnalyticsInfoResponse,
  GetRealTimeTravelsDataResponse,
} from "../dtos/analytics.dto";

export const parseFlightsAnalyticsInfo = (
  dto: GetFlightsAnalyticsInfoResponse,
) => {
  const spentThroughtTime = dto.spentThroughtTime.map((item) => ({
    name: `${MONTH_MAP[item.month]}/${item.year.toString().slice(2, 4)}`,
    total: item.total,
  }));

  const spentByDestination = dto.spentByDestination.map((item) => ({
    average: item.average,
    count: item.count,
    destination: item.destination,
    maxPrice: item.max_price,
    minPrice: item.min_price,
    origin: item.origin,
    total: item.total,
  }));

  const advanceRange = dto.advanceRange.map((item) => ({
    advanceRange: item.advance_range,
    count: item.count,
  }));

  return {
    advanceRange,
    altersInfo: dto.altersInfo,
    cancelsInfo: dto.cancelsInfos,
    generatedAt: dto.generatedAt,
    spent: dto.spent,
    spentByCompany: dto.spentByCompany,
    spentByDestination,
    spentThroughtTime,
  };
};

export const parseHotelsAnalyticsInfo = (
  dto: GetHotelsAnalyticsInfoResponse,
) => {
  const spentThroughtTime = dto.spentThroughtTime.map((item) => ({
    name: `${MONTH_MAP[item.month]}/${item.year.toString().slice(2, 4)}`,
    total: item.total,
  }));

  const spentByDestination = dto.spentByDestination.map((item) => ({
    count: item.count,
    destination: item.destination,
    durationAverage: item.duration_average,
    nightAverage: item.night_average,
    total: item.total,
    totalAverage: item.total_average,
  }));

  const spentByHotel = dto.spentByHotel.map((item) => ({
    count: item.count,
    destination: item.destination,
    durationAverage: item.duration_average,
    hotelName: item.hotel_name,
    nightAverage: item.night_average,
    total: item.total,
    totalAverage: item.total_average,
  }));

  const advanceRange = dto.advanceRange.map((item) => ({
    advanceRange: item.advance_range,
    count: item.count,
  }));

  return {
    advanceRange,
    generatedAt: dto.generatedAt,
    spent: dto.spent,
    spentByDestination,
    spentByHotel,
    spentThroughtTime,
  };
};

export const parseRealTimeTravelsData = (
  dtos: GetRealTimeTravelsDataResponse,
) => {
  return dtos.map((dto) => ({
    billingProfileName: dto.billing_profile_name,
    bookerToken: dto.booker_token,
    companyAreaName: dto.company_area_name,
    costCenterName: dto.cost_center_name,
    destinationCity: dto.destination_city,
    latitude: dto.latitude,
    longitude: dto.longitude,
    originCity: dto.origin_city,
    travelEnd: dto.travel_end,
    travelerName: dto.full_name,
    travelerToken: dto.traveler_token,
    travelName: dto.travel_name,
    travelStart: dto.travel_start,
    travelToken: dto.travel_token,
  }));
};
