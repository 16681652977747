import React from "react";

import { Router } from "@reach/router";

import { Users } from "./users/users";
import { UsersUserDocumentForm } from "./users/users-user-document-form/users-user-document-form";
import { UsersUserForm } from "./users/users-user-form/users-user-form";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <Users path="/">
        <UsersUserDocumentForm
          mode="create"
          path=":userToken/document/create"
        />
        <UsersUserDocumentForm
          mode="edit"
          path=":userToken/document/:documentToken/edit"
        />
        <UsersUserForm mode="create" path="create" />
        <UsersUserForm mode="edit" path=":userToken/edit" />
      </Users>
    </Router>
  );
};

export const UsersConfigurations: React.FC = () => {
  return <Outlet />;
};
