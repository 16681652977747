import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  group: {
    accessories: {
      root: css({
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        width: "100%",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      width: "100%",
    }),
    heading: {
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      subtitle: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
      title: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingTop: "6px",
    width: "100%",
  }),
};
