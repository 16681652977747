import React from "react";

import {
  BusTripDetailsContentSeat,
  TripConnection,
} from "~/apps/corporate/dtos/bus.dto";
import { Icon } from "~/apps/shared/components/icon/icon";

import { Button } from "@toolkit/v2";

import { SeatPicker } from "./seat-picker/seat-picker";
import { SelectedSeatsList } from "./selected-seats-list/selected-seats-list";
import { styles } from "./styles";

const LEGEND_ITEMS: {
  icon: React.ReactNode;
  label: string;
}[] = [
  {
    icon: (
      <div
        css={styles.pickers.picker.legend.item.square({
          color: "#33B679",
        })}
      />
    ),
    label: "Livre",
  },
  {
    icon: (
      <div
        css={styles.pickers.picker.legend.item.square({
          color: "#FFC82C",
        })}
      />
    ),
    label: "Selecionado",
  },
  {
    icon: (
      <div
        css={styles.pickers.picker.legend.item.square({
          color: "#FFFFFF",
        })}
      >
        <Icon size={8} use="x" />
      </div>
    ),
    label: "Ocupado",
  },
];

type Props = {
  areAllSeatsSelected: boolean;
  onProgressBooking: () => void;
  onSeatSelect: (
    busId: number,
    tripPartId: string,
  ) => (selectedSeats: BusTripDetailsContentSeat[]) => void;
  trip: TripConnection;
  tripParts: {
    id: string;
    seats: BusTripDetailsContentSeat[];
    selectedSeats: BusTripDetailsContentSeat[];
  }[];
};

export const BusSeatSelection: React.FC<Props> = ({
  areAllSeatsSelected,
  onProgressBooking,
  onSeatSelect,
  trip,
  tripParts,
}) => {
  const renderSeatPickers = () => {
    return tripParts.map((part, index) => {
      const info = trip.parts[index];

      const arrivalName = info.arrival.place.name;
      const departureName = info.departure.place.name;

      return (
        <div css={styles.pickers.picker.root} key={part.id}>
          {index > 0 && <BusSeatSelectionStopDivision name={departureName} />}
          {tripParts.length > 1 && (
            <div css={styles.pickers.picker.parts.root}>
              <Icon
                css={styles.pickers.picker.parts.icon}
                size={18}
                use="bus"
              />
              <span css={styles.pickers.picker.parts.text}>
                {departureName} - {arrivalName}
              </span>
            </div>
          )}
          <SeatPicker
            handleSelect={onSeatSelect(trip.id, part.id)}
            seats={part.seats}
            selectedSeats={part.selectedSeats}
          />
          <ul css={styles.pickers.picker.legend.root}>
            {LEGEND_ITEMS.map((item, index) => (
              <li css={styles.pickers.picker.legend.item.root} key={index}>
                {item.icon}
                <span css={styles.pickers.picker.legend.item.label}>
                  {item.label}
                </span>
              </li>
            ))}
          </ul>
          <div>
            <SelectedSeatsList
              selectedSeats={part.selectedSeats}
              updateSeats={onSeatSelect(trip.id, part.id)}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div css={styles.root}>
      {renderSeatPickers()}
      <div css={styles.bottom.root}>
        <Button disabled={!areAllSeatsSelected} onClick={onProgressBooking}>
          Continuar reserva
        </Button>
        <p css={styles.bottom.warning}>
          <strong>Atenção:</strong> O assento selecionado só será reservado após
          a confirmação da compra. Caso esse fique indisponível entre a escolha
          e compra, outro será selecionado automaticamente.
        </p>
      </div>
    </div>
  );
};

type BusSeatSelectionStopDivisionProps = {
  name: string;
};

const BusSeatSelectionStopDivision: React.FC<BusSeatSelectionStopDivisionProps> = ({
  name,
}) => {
  return (
    <div css={styles.stop.root}>
      <p css={styles.stop.text}>
        Haverá uma parada em <strong>{name}</strong>
      </p>
    </div>
  );
};
