import React, { useCallback, useState } from "react";

import { AcceptTermsDialog } from "../accept-terms-dialog/accept-terms-dialog";
import { MemberGetMemberDrawer } from "../member-get-member-drawer/member-get-member-drawer";
import { NavBarDesktop } from "../nav-bar/desktop/desktop";
import { NavBarMobile } from "../nav-bar/mobile/mobile";
import { Desktop, TabletAndMobile } from "../responsive/responsive";
import { UserProfileDrawer } from "../user-profile-drawer/user-profile-drawer";
import { LayoutErrorBoundary } from "./layout-error-boundary/layout-error-boundary";
import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const Layout: React.FC<Props> = ({ children, ...props }) => {
  const [
    isMemberGetMemebrDrawerOpen,
    setIsMemberGetMemebrDrawerOpen,
  ] = useState(false);
  const [isUserProfileDrawerOpen, setIsUserProfileDrawerOpen] = useState(false);

  const handleCloseMemberGetMemebrDrawer = useCallback(() => {
    setIsMemberGetMemebrDrawerOpen(false);
  }, []);

  const handleOpenMemberGetMemebrDrawer = useCallback(() => {
    setIsMemberGetMemebrDrawerOpen(true);
  }, []);

  const handleCloseUserProfileDrawer = useCallback(() => {
    setIsUserProfileDrawerOpen(false);
  }, []);

  const handleOpenUserProfileDrawer = useCallback(() => {
    setIsUserProfileDrawerOpen(true);
  }, []);

  return (
    <>
      <AcceptTermsDialog />
      <div css={styles.root} {...props}>
        <Desktop>
          <NavBarDesktop
            handleOpenMemberGetMemebrDrawer={handleOpenMemberGetMemebrDrawer}
            handleOpenUserProfileDrawer={handleOpenUserProfileDrawer}
          />
        </Desktop>
        <LayoutErrorBoundary>{children}</LayoutErrorBoundary>
        <TabletAndMobile>
          <NavBarMobile
            handleOpenUserProfileDrawer={handleOpenUserProfileDrawer}
          />
        </TabletAndMobile>
      </div>
      <MemberGetMemberDrawer
        onClose={handleCloseMemberGetMemebrDrawer}
        open={isMemberGetMemebrDrawerOpen}
      />
      <UserProfileDrawer
        onClose={handleCloseUserProfileDrawer}
        open={isUserProfileDrawerOpen}
      />
    </>
  );
};
