import * as userParser from "./user.parser";

export function AreaFactory(areaDto) {
  const {
    company_area_token,
    name,
    area_type,
    active,
    users,
    client_token,
    parent_token,
    subAreas
  } = areaDto;

  const result = {};

  result.companyAreaToken = company_area_token;
  result.clientToken = client_token;
  result.name = name;
  result.areaType = area_type;
  result.active = active;
  result.usersCount = users ? users.length : 0;
  result.subAreasCount = subAreas ? subAreas.length : 0;
  result.label = name;
  result.parentToken = parent_token;

  return result;
}

export function DetailedAreaFactory(areaDto) {
  const { company_area_token, name, users, subAreas } = areaDto;

  const result = {};

  result.companyAreaToken = company_area_token;
  result.name = name;
  result.users = userParser.UsersArea(users);
  result.subAreas = AreasFactory(subAreas);

  return result;
}

export function AreasFactory(areasDto) {
  return areasDto.map(areaDto => AreaFactory(areaDto));
}
