import {
  AddFlightOfferRequest,
  CheckReservationConflictResponse,
} from "~/apps/corporate/dtos/flights.dto";
import { FlightListOffer } from "~/apps/corporate/models/offer.model";

import { FlightReservationConflict } from "../models/flight.model";
import { OfferFactory } from "./offers.parser";

type AddFlightOfferDto = {
  flight: FlightListOffer;
  flightToken: string;
  generalInfo: {
    airportPrices?: Record<string, number>;
    averagePrice?: number;
    bestRecommendedPrice?: number;
    carrierPrices?: Record<string, number>;
    cheapestPrice?: number;
    optimalPrice?: number;
    searchResultCount?: number;
    stopPrices?: Record<string, number>;
  };
  policyStatus: {
    advance: number;
    allowedClasses: string[];
    maxValueVisible?: number;
    optimalPrice?: {
      enabled: boolean;
      price: number;
    };
    outOfPolicy: boolean;
    price: number;
    priceExcess: number | null;
  };
};

export const buildAddFlightOfferRequest = ({
  flight,
  flightToken,
  generalInfo,
  policyStatus,
}: AddFlightOfferDto) => {
  const {
    fareId,
    supplier,
    searchChannel,
    itineraryId,
    reserve,
    outbound,
    inbound,
    supplierSearchId,
    recommendedIndex,
    smartripsOutOfPolicy,
  } = flight;

  const cheapestFare = flight.familyOffers[0];
  const mainOfferOutOfPolicy = cheapestFare.mainOfferOutOfPolicy;

  const addFlightOfferRequest: AddFlightOfferRequest = {
    cheapestPrice: generalInfo.cheapestPrice,
    fareDetails: {
      inboundDetails: inbound?.fareDetails
        ? {
            carryOnLuggage: inbound.fareDetails.carryOnLuggage || {},
            changeRule: inbound.fareDetails.changeRule || null,
            checkedBaggage: inbound.fareDetails.checkedBaggage,
            refundRule: inbound.fareDetails.refundRule || null,
            reservedSeat: inbound.fareDetails.reservedSeat,
          }
        : undefined,
      outboundDetails: {
        carryOnLuggage: outbound.fareDetails?.carryOnLuggage || {},
        changeRule: outbound.fareDetails?.changeRule || null,
        checkedBaggage: outbound.fareDetails?.checkedBaggage || {},
        refundRule: outbound.fareDetails?.refundRule || null,
        reservedSeat: outbound.fareDetails?.reservedSeat,
      },
    },
    policy: {
      advance: policyStatus.advance,
      allowedClass: policyStatus.allowedClasses[0],
      maxFareOutOfPolicy: mainOfferOutOfPolicy,
      maxValue: policyStatus.maxValueVisible,
      outOfPolicy: policyStatus.outOfPolicy,
      priceExcess: policyStatus.priceExcess,
      smOutOfPolicy: smartripsOutOfPolicy,
    },
    preReserve: reserve,
    searchChannel,
    smFlightSearchToken: flightToken,
    searchMetadata: {
      averagePrice: generalInfo.averagePrice ? generalInfo.averagePrice : 0,
      bestRecommendedPrice: generalInfo.bestRecommendedPrice
        ? generalInfo.bestRecommendedPrice
        : 0,
      cheapestFare: {
        baggagePieces: cheapestFare.outbound.baggage?.pieces || 0,
        class: cheapestFare.outbound.fares[0].class,
        family: cheapestFare.outbound.family,
        fareBasis: cheapestFare.outbound.fares[0].base,
        price: cheapestFare.price,
      },
      optimalPrice: policyStatus.optimalPrice
        ? policyStatus.optimalPrice.price
        : cheapestFare.price,
      recommendedIndex: recommendedIndex,
      searchResultCount: generalInfo.searchResultCount
        ? generalInfo.searchResultCount
        : 0,
    },
    selectedFare: {
      fareId,
      itineraryId: String(itineraryId),
      searchId: supplierSearchId,
      supplier,
    },
  };

  return addFlightOfferRequest;
};

export const parseCheckReservationConflict = (
  dto: CheckReservationConflictResponse,
) => {
  const flightReservationConflict: FlightReservationConflict = {
    canCancelConflictingReserve: dto.canCancelConflictingReserve,
    canPreReserve: dto.canPreReserve,
    conflictingOffer: dto.conflictingOffer
      ? OfferFactory(dto.conflictingOffer)
      : null,
  };

  return flightReservationConflict;
};
