import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    color: theme.colors.gray[300],
  }),
  input: css({
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.gray[700],
    outline: "none",
    padding: "0 16px 0 12px",
    textOverflow: "ellipsis",
    width: "100%",
    "::placeholder": {
      color: theme.colors.gray[100],
    },
  }),
  root: css({
    alignItems: "center",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    padding: "0 0 0 16px",
    width: "100%",
    "&:hover": {
      border: `1px solid ${darken(theme.colors.gray[100], 0.2)}`,
    },
  }),
};
