import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { SearchExpirationDialog } from "~/apps/shared/components/search-expiration-dialog/search-expiration-dialog";
import { useCheckExpiration } from "~/apps/shared/hooks/use-check-expiration";

import { FlightsEditSearchDialog } from "./edit-search-dialog/edit-search-dialog";
import { FlightAvailabilityDialog } from "./flight-availability-dialog/flight-availability-dialog";
import { FlightDetailsDrawer } from "./flight-details-drawer/flight-details-drawer";
import { FlightFareDetailsDrawer } from "./flight-fare-details-drawer/flight-fare-details-drawer";
import { FlightReservationConflictDialog } from "./flight-reservation-conflict-dialog/flight-reservation-conflict-dialog";
import { FlightsContent } from "./flights-content/flights-content";
import { FlightsHeader } from "./flights-header/flights-header";
import { FlightsProvider, useFlights } from "./flights.context";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    flightToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({ flightToken, travelToken }) => {
  const {
    fetchFlightsList,
    handleSetExpiration,
    hasExpired,
    pageTitle,
    validUntil,
  } = useFlights();

  useCheckExpiration(validUntil, handleSetExpiration);

  useEffect(() => {
    if (!flightToken) {
      return;
    }

    void fetchFlightsList(flightToken);
  }, [flightToken]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <div css={styles.root}>
        <FlightsHeader />
        <FlightsContent />
      </div>
      <FlightAvailabilityDialog />
      <FlightDetailsDrawer />
      {travelToken ? (
        <FlightsEditSearchDialog travelToken={travelToken} />
      ) : null}
      {travelToken ? <FlightFareDetailsDrawer /> : null}
      <FlightReservationConflictDialog />
      <SearchExpirationDialog open={hasExpired} />
    </>
  );
};

export const Flights: React.FC<Props> = ({ ...props }) => {
  return (
    <FlightsProvider>
      <Component {...props} />
    </FlightsProvider>
  );
};
