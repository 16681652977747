import React, { useCallback, useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesModelFactory } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Badge } from "smartrips-toolkit/lib/v2";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../../../itinerary-screen.context";
import { styles } from "./styles";

type Props = {
  servicePresenter: HotelItineraryServicePresenter;
};

export const ItineraryHotelDetailsDrawerTravelers: React.FC<Props> = ({
  servicePresenter,
}) => {
  const {
    fetchOffersTravelers,
    isLoadingOffersTravelers,
    offersTravelers,
  } = useItineraryPendencies();
  const { openServiceTravelersDialog } = useItineraryScreen();

  const handleOpenServiceTravelersDialog = useCallback(() => {
    openServiceTravelersDialog(servicePresenter.getService());
  }, [openServiceTravelersDialog, servicePresenter]);

  const services = useMemo(
    () => ItineraryServicesModelFactory.create([servicePresenter.getService()]),
    [servicePresenter],
  );

  useEffect(() => {
    void fetchOffersTravelers(services);
  }, [fetchOffersTravelers, services]);

  if (isLoadingOffersTravelers) {
    if (!offersTravelers) {
      return null;
    }

    return (
      <>
        <hr css={styles.divisor} />
        <div css={styles.root}>
          <div css={styles.top.root}>
            <Skeleton height="16px" width="84px" />
          </div>
          <ul css={styles.travelers.root}>
            <li css={styles.travelers.traveler.root}>
              <div css={styles.travelers.traveler.avatar}>
                <Skeleton height="32px" width="32px" />
              </div>
              <Skeleton height="16px" width="96px" />
              <Skeleton height="16px" width="144px" />
            </li>
          </ul>
        </div>
      </>
    );
  }

  if (!offersTravelers) {
    return null;
  }

  const offerToken = servicePresenter.getOfferToken();

  const offerTravelers =
    offerToken in offersTravelers ? offersTravelers[offerToken] : null;

  if (!offerTravelers) {
    return null;
  }

  const canEditTravelers =
    servicePresenter.isBookingUnitiated() &&
    servicePresenter.getTotalGuests() > 1;

  return (
    <>
      <hr css={styles.divisor} />
      <div css={styles.root}>
        <div css={styles.top.root}>
          <span css={styles.top.name}>Hóspedes:</span>
          {canEditTravelers ? (
            <button
              css={styles.top.button}
              onClick={() => {
                handleOpenServiceTravelersDialog();
              }}
            >
              Editar hóspedes
            </button>
          ) : null}
        </div>
        <ul css={styles.travelers.root}>
          {offerTravelers.map((offerTraveler) => (
            <li css={styles.travelers.traveler.root} key={offerTraveler.email}>
              <div css={styles.travelers.traveler.avatar}>
                <Avatar name={offerTraveler.fullName} />
              </div>
              <span css={styles.travelers.traveler.text}>
                <strong css={styles.travelers.traveler.name}>
                  {offerTraveler.fullName}
                </strong>{" "}
                &lt;
                {offerTraveler.email}&gt;
              </span>
              {offerTraveler.main ? (
                <Badge css={styles.travelers.traveler.main} variant="pink">
                  Principal
                </Badge>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
