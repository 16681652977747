import React from "react";
import { Box, Text } from "@toolkit";
import { ConfirmationTravelers } from "../types";

interface Props {
  travelers: ConfirmationTravelers[];
}

const TravelersSection = ({ travelers }: Props) => {
  return (
    <>
      <Box
        mt={24}
        mb="medium"
        py="medium"
        borderBottom="1px solid"
        borderBottomColor="border"
        borderRadius={0}
        width="100%"
      >
        <Text fontSize={2} fontWeight="bold">
          Hóspede
          {travelers.length > 1 ? "s" : ""}
        </Text>
      </Box>
      <Box width="100%">
        <ul>
          {travelers.map((traveler, index) => (
            <li key={index} style={{ marginBottom: 8 }}>
              <Text fontSize={1} mr="large">
                <Text fontWeight="bold">Nome: </Text>
                {`${traveler.firstName} ${traveler.lastName}`}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
};

export { TravelersSection };
