import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/styles";
import { css } from "@styled-system/css";

const styles = {
  root: css({
    display: "grid",
    placeItems: "center"
  }),
  spinner: css({
    color: "#FFFFFF",
    left: "50%",
    marginLeft: -12,
    marginTop: -12,
    position: "absolute",
    top: "50%"
  })
};

type Props = React.ComponentPropsWithoutRef<typeof CircularProgress>;

const CircularSpinner: React.VFC<Props> = ({ classes, size, ...props }) => {
  return (
    <div css={styles.root}>
      <CircularProgress
        classes={{
          root: styles.spinner,
          ...classes
        }}
        size={size || 24}
        {...props}
      />
    </div>
  );
};

export default withStyles({})(CircularSpinner);
