import {
  CheckForRepeatedOfferResponse,
  CheckOfferAvailabilityResponse,
  OfferTravelerDto,
} from "../dtos/offers.dto";
import {
  OfferAvailability,
  OfferTraveler,
  RepeatedOffer,
} from "../models/offer.model";

export function OfferFactory(
  dto: Partial<
    Record<
      "id" | "item_token" | "offer_token" | "offer" | "travel_token" | "type",
      any
    >
  >,
) {
  const { id, item_token, offer_token, offer, travel_token, type } = dto;

  const result = {
    id: id,
    itemToken: item_token,
    offer: offer,
    offerToken: offer_token,
    travelToken: travel_token,
    type: type,
  };

  return result;
}

export const parseCheckOfferAvailability = (
  dto: CheckOfferAvailabilityResponse,
) => {
  const offerAvailability: OfferAvailability = {
    ...dto.availability,
  };

  return offerAvailability;
};

export const parseRepeatedOffer = (
  offerToken: string,
  dto: CheckForRepeatedOfferResponse,
) => {
  const repeatedOffer: RepeatedOffer = {
    isRepeated: dto.is_repeated,
    offerToken,
    travelToken: dto.travel_token,
  };

  return repeatedOffer;
};

export const parseOfferTraveler = (dto: OfferTravelerDto) => {
  const offerTraveler: OfferTraveler = {
    email: dto.email,
    fullName: dto.fullName,
    main: dto.main,
    travelerOfferToken: dto.travelerOfferToken,
    userToken: dto.userToken,
  };

  return offerTraveler;
};

// function SubOfferFactory(offerDto) {
//   const {
//     name,
//     stars,
//     latitude,
//     longitude,
//     address,
//     zipcode,
//     main_picture_url,
//     description,
//     selected_offer,
//     amenities,
//     payment_types
//   } = offerDto;

//   let result = {};

//   result.name = name;
//   result.stars = stars;
//   result.latitude = latitude;
//   result.longitude = longitude;
//   result.address = address;
//   result.zipcode = zipcode;
//   result.mainPictureUrl = main_picture_url;
//   result.description = description;
//   result.selectedOffer = OfferItemFactory(selected_offer);
//   result.amenities = amenities;
//   result.paymentTypes = payment_types;

//   return result;
// }

// function OfferItemFactory(offerItemDto) {
//   const {
//     id,
//     supplier,
//     price,
//     nightly_price,
//     room_name,
//     bed_option,
//     amenities,
//     offer_url,
//     supplier_logo
//   } = offerItemDto;

//   let result = {};

//   result.id = id;
//   result.supplier = supplier;
//   result.price = price;
//   result.nightlyPrice = nightly_price;
//   result.roomName = room_name;
//   result.bedOption = bed_option;
//   result.amenities = amenities;
//   result.offerUrl = offer_url;
//   result.supplierLogo = supplier_logo;

//   return result;
// }
