import React from "react";

import { CompanyDealBadge } from "~/apps/corporate/components/company-deal-badge/company-deal-badge";
import { ItineraryHotelSegment } from "~/apps/corporate/components/itinerary-hotel-segment/itinerary-hotel-segment";
import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { useItineraryContainer } from "~/apps/corporate/pages/travels/itinerary/itinerary.container";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryScreen } from "../../../../pages/travels/itinerary/itinerary-screen.context";
import { ItineraryServiceCancelationInfoBadge } from "../../../itinerary-service-cancelation-info-badge/itinerary-service-cancelation-info-badge";
import { ItineraryServiceCancelationUntilBadge } from "../../../itinerary-service-cancelation-until-badge/itinerary-service-cancelation-until-badge";
import { ItineraryServiceCanceledByBadge } from "../../../itinerary-service-canceled-by-badge/itinerary-service-canceled-by-badge";
import { styles } from "./styles";

type Props = {
  isDisabled: boolean;
  servicePresenter: HotelItineraryServicePresenter;
};

export const ItineraryHotelJourney: React.FC<Props> = ({
  isDisabled,
  servicePresenter,
}) => {
  const { infoModel } = useItineraryContainer();

  const { openServiceDetailsDrawer } = useItineraryScreen();

  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();

  const showCompanyDealBadge = servicePresenter.isCompanyNegotiated();

  const traveler = infoModel ? infoModel.getTraveler() : null;

  return (
    <div
      css={styles.root({
        isDisabled,
      })}
    >
      {showCompanyDealBadge ? <CompanyDealBadge /> : null}
      {servicePresenter.showReservation() && traveler && traveler.name ? (
        <div css={styles.reservation.root}>
          <Icon css={styles.reservation.icon} size={16} use="person" />
          <span css={styles.reservation.text}>
            Reservado em nome de {traveler.name}.
          </span>
        </div>
      ) : null}
      <div
        css={styles.card.root({
          isDisabled,
        })}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          openServiceDetailsDrawer(servicePresenter.getService());
        }}
      >
        <ItineraryHotelSegment
          amenities={servicePresenter.getAmenities()}
          hotel={{
            address: servicePresenter.getAddress(),
            favorites: [],
            mainPictureUrl: servicePresenter.getMainImageURL(),
            name: servicePresenter.getHotelName(),
            stars: servicePresenter.getStars(),
          }}
        />
      </div>
      <div css={styles.dates.root}>
        <div css={styles.dates.date.root}>
          <Icon
            css={styles.dates.date.icon}
            size={16}
            use="arrow-right-end-on-rectangle"
          />
          <span css={styles.dates.date.text}>
            Check-in: {servicePresenter.getFormattedCheckIn()}
          </span>
        </div>
        <div css={styles.dates.date.root}>
          <Icon
            css={styles.dates.date.icon}
            size={16}
            use="arrow-right-start-on-rectangle"
          />
          <span css={styles.dates.date.text}>
            Check-out: {servicePresenter.getFormattedCheckOut()}
          </span>
        </div>
      </div>
      {formattedFreeCancelationUntil ? (
        <ItineraryServiceCancelationUntilBadge
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          message="(horário local do hotel)"
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <ItineraryServiceCancelationInfoBadge
          cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
          cancelType={cancelInfo.type}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage ? (
        <ItineraryServiceCanceledByBadge
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      ) : null}
    </div>
  );
};
