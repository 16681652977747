import React from "react";
import { Box, Text } from "@toolkit";
import { colors } from "@theme";
import { css } from "@styled-system/css";

const styles = {
  base: css({
    ["&.canceled"]: {
      position: "relative",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colors.botticelli,
      borderBottom: "none",
      color: colors.punch,
      backgroundColor: colors.linkWater,
      ["&::after"]: {
        content: "''",
        height: "2px",
        backgroundColor: colors.linkWater,
        width: "100%",
        position: "absolute",
        bottom: "-1px",
        left: 0
      }
    }
  })
};

const ExpenseCardCategoryTag = ({
  categoryName,
  categoryColor,
  isCanceled
}) => {
  return (
    <Box
      py={["4px", "6px"]}
      px="medium"
      borderRadius="8px 8px 0 0"
      fontSize={0}
      fontWeight="bold"
      color="white"
      backgroundColor={categoryColor}
      css={styles.base}
      className={isCanceled ? "canceled" : ""}
    >
      <Text>
        {categoryName}
        {isCanceled && " (Cancelado)"}
      </Text>
    </Box>
  );
};

ExpenseCardCategoryTag.defaultProps = {
  isCanceled: false
};

export { ExpenseCardCategoryTag };
