import { UserSearch, UserModel } from "../models/user.model";
import { BuildedApprovers } from "../apps/corporate/pages/configurations/views/expenses/expenses-process/ExpensesProcess.types";
import { ExpenseApprover } from "../models/expense-approval.model";
import isEmpty from "lodash/isEmpty";

export function buildExpenseApprovers(approvers: UserSearch[][]) {
  const buildedApprovers = approvers.map((item, index) =>
    item.map(approver => ({
      user_token: approver.userToken,
      stage: index + 1
    }))
  );

  return buildedApprovers.reduce((acc, current) => {
    return acc.concat(current);
  }, []);
}

export function formatApproversToForm(approvers: ExpenseApprover[]): any {
  const separatedApprovers = approvers.reduce(
    (
      acc: {
        paymentApprovers: UserSearch[];
        expenseApprovers: ExpenseApprover[];
      },
      current
    ) => {
      if (current.stage === 99) {
        acc.paymentApprovers.push(userToUserSearch(current));
      } else {
        acc.expenseApprovers.push(current);
      }

      return acc;
    },
    {
      expenseApprovers: [],
      paymentApprovers: []
    }
  );

  const {
    expenseApprovers: rawExpenseApprovers,
    paymentApprovers
  } = separatedApprovers;

  const expenseApprovers = rawExpenseApprovers
    .reduce(
      (acc: UserSearch[][], current) => {
        const index = current.stage - 1;
        const updatedIndexItem = acc[index].concat(userToUserSearch(current));
        acc[index] = updatedIndexItem;

        return acc;
      },
      [[], [], [], []]
    )
    .filter(item => !isEmpty(item));

  return {
    expenseApprovers,
    paymentApprovers
  };
}

export function userToUserSearch(user: UserModel): UserSearch {
  return {
    userToken: user.userToken,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.fullName!,
    document: user.document!,
    clientToken: user.clientToken!,
    label: user.fullName!
  };
}
