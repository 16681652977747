import React from "react";

import { Table, TableRow, TableBody, TableCell } from "@material-ui/core";
import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

import Utils from "@helpers";

import { DetailsCard } from "../../shared/DetailsCard";
import { useFlightInfoContext } from "../FlightInfo.context";
import { formatPercentage } from "../FlightInfo.utils";

const styles = {
  detailRow: css({
    py: 12,
    "& .MuiTableCell-root": {
      px: 0,
      fontSize: [0, 1, 1],
      borderBottom: "none",
      color: defaultTheme.subTextColor
    }
  }),
  detailName: css({
    mr: "auto"
  })
};

const CancelFeeCard = () => {
  const { wastesInfo } = useFlightInfoContext();
  return (
    <DetailsCard
      title={"Multas de cancelamento e noshow"}
      total={
        wastesInfo.cancelFee.totalRegularWastes +
        wastesInfo.cancelFee.totalNoShowWastes
      }
    >
      <Table>
        <TableBody>
          <TableRow css={styles.detailRow}>
            <TableCell css={styles.detailName}>
              Multas de cancelamento
            </TableCell>
            <TableCell align="right">
              {Utils.toCurrency(wastesInfo.cancelFee.totalRegularWastes)}
            </TableCell>
            <TableCell align="right">
              {formatPercentage(wastesInfo.cancelFee.regularWastesPercentage)}
            </TableCell>
          </TableRow>
          <TableRow css={styles.detailRow}>
            <TableCell css={styles.detailName}>Perdas com noshow</TableCell>
            <TableCell align="right">
              {Utils.toCurrency(wastesInfo.cancelFee.totalNoShowWastes)}
            </TableCell>
            <TableCell align="right">
              {formatPercentage(wastesInfo.cancelFee.noShowWastesPercentage)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DetailsCard>
  );
};

export { CancelFeeCard };
