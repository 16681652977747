import React, { useMemo, useRef } from "react";
import { useDebounce } from "react-use";

import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { TRAVEL_NAME_PLACEHOLDERS } from "~/apps/shared/constants";

import { useItineraryInfo } from "../../../itinerary-info.context";
import {
  itineraryInfoTravelNameSchema,
  ItineraryInfoTravelNameSchema,
} from "./itinerary-info-travel-name.schema";
import { styles } from "./styles";

type Props = {
  infoModel: ItineraryInfoModel;
  isDisabled?: boolean;
};

export const ItineraryInfoTravelName: React.FC<Props> = ({
  infoModel,
  isDisabled,
}) => {
  const { updateTravelName } = useItineraryInfo();

  const form = useForm<ItineraryInfoTravelNameSchema>({
    defaultValues: {
      name: infoModel.getTravelName(),
    },
    mode: "all",
    onSubmit: async () => {
      const values = form.getValues();

      await updateTravelName(values.name);
    },
    schema: itineraryInfoTravelNameSchema,
  });

  const hasError = useMemo(() => !!form.errors["name"], [form]);

  const isFirstRender = useRef(true);

  const name = form.watch("name");

  const placeholder = useMemo(
    () =>
      TRAVEL_NAME_PLACEHOLDERS[
        Math.floor(Math.random() * TRAVEL_NAME_PLACEHOLDERS.length)
      ],
    [],
  );

  useDebounce(
    () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;

        return;
      }

      void updateTravelName(name);
    },
    500,
    [isFirstRender, name],
  );

  return (
    <div css={styles.root}>
      <Form context={form}>
        <Form.Field
          name="name"
          render={({ onBlur, onChange, value }) => (
            <Input
              autoFocus={!infoModel.getTravelName() && !isDisabled}
              css={styles.value({
                isDisabled: isDisabled,
              })}
              disabled={isDisabled}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
            />
          )}
        />
        <InputErrorMessage>{form.errors["name"]?.message}</InputErrorMessage>
      </Form>
      <hr css={styles.divisor({ hasError })} />
    </div>
  );
};
