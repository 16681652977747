import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    backgroundColor: "transparent",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "6px",
    color: theme.colors.gray[300],
    cursor: "pointer",
    display: "grid",
    height: "26px",
    placeItems: "center",
    transition: "all 0.15s ease-in-out",
    width: "26px",
    "&:hover": {
      borderColor: theme.colors.gray[300],
      color: theme.colors.gray[700],
    },
  }),
};
