import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  root: () =>
    StyleSheet.create({
      container: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        paddingBottom: 4,
      },
      root: {
        border: `1pt solid ${theme.colors.gray[100]}`,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
      },
      services: {
        display: "flex",
        flexDirection: "column",
        gap: 6,
      },
      title: {
        color: theme.colors.gray[700],
        fontSize: 11,
        fontWeight: 600 - 100,
      },
      total: {
        borderTop: `1pt dashed ${theme.colors.gray[100]}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 11,
        fontWeight: 600 - 100,
        paddingTop: 8,
      },
    }),
  service: () =>
    StyleSheet.create({
      name: {
        fontSize: 11,
      },
      price: {
        fontSize: 11,
      },
      root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      serviceItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 11,
        paddingBottom: 2,
        marginTop: 8,
        fontWeight: 600 - 100,
      },
    }),
};
