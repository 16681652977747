import React from "react";
import { Box, Text } from "@toolkit";
import { HotelConfirmationData } from "../types";
import isEmpty from "lodash/isEmpty";
import * as utils from "@helpers";

interface Props {
  hotelData: HotelConfirmationData;
}

const RoomSection = ({ hotelData }: Props) => {
  if (isEmpty(hotelData)) {
    return null;
  }

  return (
    <>
      <Box
        mt={24}
        mb="medium"
        py="medium"
        borderBottom="1px solid"
        borderBottomColor="border"
        borderRadius={0}
        width="100%"
      >
        <Text fontSize={2} fontWeight="bold">
          Quarto selecionado
        </Text>
      </Box>
      <Box mb="medium">
        <Text fontSize={1} mr="large">
          <Text fontWeight="bold">Quarto: </Text>
          {hotelData.roomName}
        </Text>
      </Box>
      <Box mb="medium">
        <Text fontSize={1} mr="large">
          <Text fontWeight="bold">Valor negociado da diária: </Text>
          {utils.toCurrency(hotelData.negotiatedPrice)}
        </Text>
        <Text fontSize={1}>
          <Text fontWeight="bold">Valor total da reserva: </Text>
          {utils.toCurrency(hotelData.finalPrice)}
        </Text>
      </Box>
      <Box>
        <Text fontSize={1} mr="large">
          <Text fontWeight="bold">Check-in: </Text>
          {hotelData.hotelInitialDate.format("DD [de] MMMM [de] YYYY")}
        </Text>
        <Text fontSize={1}>
          <Text fontWeight="bold">Check-out: </Text>
          {hotelData.hotelEndDate.format("DD [de] MMMM [de] YYYY")}
        </Text>
      </Box>
    </>
  );
};

export { RoomSection };
