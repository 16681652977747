import { AxiosResponse } from "axios";
import moment from "moment";

import { GetHotelNegotiatedRequestBody } from "@dtos/offline-booking";

import { api } from "./interceptors";

export function getHotelNegotiationRequest(requestToken: string) {
  return api
    .request({
      url: `/booking/hotel-negotiated-request/${requestToken}`,
      method: "GET"
    })
    .then(
      (response): AxiosResponse<GetHotelNegotiatedRequestBody> => ({
        ...response.data,
        hotelInitialDate: moment(response.data.hotelInitialDate, "YYYY-MM-DD"),
        hotelEndDate: moment(response.data.hotelEndDate, "YYYY-MM-DD")
      })
    );
}

export function confirmHotelBookingRequest(
  requestToken: string,
  bookingCode: string
) {
  return api
    .request({
      url: `/booking/hotel-negotiated-request/${requestToken}/confirm`,
      method: "POST",
      data: {
        booking_code: bookingCode
      }
    })
    .then(response => response.data);
}

export function denyHotelBookingRequest(requestToken: string) {
  return api
    .request({
      url: `/booking/hotel-negotiated-request/${requestToken}/deny`,
      method: "POST"
    })
    .then(response => response.data);
}
