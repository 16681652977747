import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  billed: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    color: theme.colors.gray[700],
    padding: "16px",
  }),
  card: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
