import React, { useState, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import { navigate } from "@reach/router";
import { UserDocumentCard } from "~/apps/corporate/components/user-document-card/user-document-card";
import { UserDocumentCardMoreActionsPopover } from "~/apps/corporate/components/user-document-card/user-document-card-more-actions-popover/user-document-card-more-actions-popover";
import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { UserModel } from "~/apps/corporate/models/user.model";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";
import { DOCUMENT_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { Document } from "~/apps/shared/models/documents.model";

import { styles } from "./styles";

type Props = {
  documents: Document[];
  user?: UserModel;
};

export const UsersFormDocumentsList: React.FC<Props> = ({
  documents,
  user,
}) => {
  const queryClient = useQueryClient();

  const { deleteDocument } = useDocuments();

  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] = useState(
    false,
  );
  const [
    moreOptionsPopoverAnchorElement,
    setMoreOptionsPopoverAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  );

  const addDocumentsLabel = useMemo(() => {
    const remainingDocuments = Object.keys(DOCUMENT_TYPES_TRANSLATION).filter(
      (k) => !documents.find((userDocument) => userDocument.type === k),
    );

    if (remainingDocuments.length === 1) {
      const remaningDocument =
        DOCUMENT_TYPES_TRANSLATION[
          remainingDocuments[0] as keyof typeof DOCUMENT_TYPES_TRANSLATION
        ];

      return `Adicionar ${remaningDocument}`;
    }

    if (remainingDocuments.length === 0) {
      return null;
    }

    return "Adicionar documentos";
  }, [documents]);

  const handleAdd = useCallback(
    (type?: Document["type"]) => {
      if (!user) {
        return;
      }

      navigate(
        `/configurations/users/${user.getUserToken()}/document/create${
          type ? `?type=${type}` : ""
        }`,
      );
    },
    [user],
  );

  const handleCloseDeleteDocumentDialog = useCallback(() => {
    setIsDeleteDocumentDialogOpen(false);
  }, []);

  const handleCloseMoreOptionsPopover = useCallback(() => {
    setMoreOptionsPopoverAnchorElement(null);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!selectedDocument) {
      return;
    }

    const success = await deleteDocument(selectedDocument.documentToken);

    handleCloseDeleteDocumentDialog();

    if (!success) {
      return;
    }

    void queryClient.invalidateQueries("user");
  }, [
    deleteDocument,
    handleCloseDeleteDocumentDialog,
    queryClient,
    selectedDocument,
  ]);

  const handleEdit = useCallback(() => {
    if (!selectedDocument) {
      return;
    }

    queryClient.setQueryData(
      ["document", selectedDocument.documentToken],
      selectedDocument,
    );

    if (!user) {
      return;
    }

    navigate(
      `/configurations/users/${user.getUserToken()}/document/${
        selectedDocument.documentToken
      }/edit`,
    );
  }, [queryClient, selectedDocument, user]);

  const handleOpenMoreOptionsPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>, document: Document) => {
      setMoreOptionsPopoverAnchorElement(event.currentTarget);
      setSelectedDocument(document);
    },
    [],
  );

  const handleOpenDeleteDocumentDialog = useCallback(() => {
    if (!selectedDocument) {
      return;
    }

    const document = documents.find(
      (document) => document.documentToken === selectedDocument.documentToken,
    );

    if (!document) {
      return;
    }

    setIsDeleteDocumentDialogOpen(true);
  }, [documents, selectedDocument]);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>Documentos</span>
          {addDocumentsLabel ? (
            <button
              css={styles.header.button}
              onClick={() => {
                handleAdd();
              }}
              type="button"
            >
              {addDocumentsLabel}
            </button>
          ) : null}
        </div>
        <div css={styles.documents.root}>
          {documents.length > 0 ? (
            documents.map((document) => (
              <UserDocumentCard
                document={document}
                key={document.documentToken}
                onClick={(event, document) => {
                  handleOpenMoreOptionsPopover(event, document);
                }}
              />
            ))
          ) : (
            <span css={styles.documents.empty}>
              Nenhum documento cadastrado.
            </span>
          )}
        </div>
      </div>
      <AlertDialog
        confirmLabel="Remover"
        message="Deseja realmente remover esse documento?"
        onClose={() => {
          handleCloseDeleteDocumentDialog();
        }}
        onConfirm={async () => {
          await handleDelete();
        }}
        open={isDeleteDocumentDialogOpen}
        title="Remover documento."
      />
      <UserDocumentCardMoreActionsPopover
        anchorElement={moreOptionsPopoverAnchorElement}
        document={selectedDocument}
        onClose={handleCloseMoreOptionsPopover}
        onDelete={() => {
          if (!selectedDocument) {
            return;
          }

          handleOpenDeleteDocumentDialog();
        }}
        onEdit={() => {
          if (!selectedDocument) {
            return;
          }

          void handleEdit();
        }}
      />
    </>
  );
};
