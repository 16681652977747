import React, { useCallback, useRef } from "react";

import { Button } from "smartrips-toolkit/lib/v2";

import { Popover } from "../popover/popover";
import { styles } from "./styles";

type Props = React.ComponentProps<typeof Button> &
  Pick<React.ComponentProps<typeof Popover>, "open" | "onClose"> & {
    label?: string;
    onOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };

export const ButtonWithPopover: React.FC<Props> = ({
  children,
  label,
  onClose,
  onOpen,
  open,
  ...props
}) => {
  const anchorEl = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onOpen?.(e);
    },
    [onOpen],
  );

  return (
    <div ref={anchorEl}>
      <Button
        onClick={(e) => {
          handleClick(e);
        }}
        {...props}
      >
        {label}
      </Button>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        css={styles.popover}
        onClose={onClose}
        open={open}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        {children}
      </Popover>
    </div>
  );
};
