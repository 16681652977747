import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "@styled-system/css";

export const Spinner = () => (
  <CircularProgress
    size={20}
    css={css({
      marginRight: "medium",
      ["svg"]: {
        marginRight: "0px !important"
      }
    })}
  />
);
