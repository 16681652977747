import { css } from "@styled-system/css";
import { theme } from "smartrips-skin";

export const styles = {
  button: css({
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    gap: "8px",
    height: "fit-content",
    width: "fit-content"
  }),
  root: css({
    alignItems: "center",
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: 8,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    gap: "24px",
    justifyContent: "space-between",
    padding: "32px",
    textDecoration: "none",
    transition: "border-color 0.15s ease-in-out",
    width: "100%",
    "@media (max-width: 576px)": {
      alignItems: "flex-start",
      flexDirection: "column"
    }
  })
};
