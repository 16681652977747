import React, { ChangeEvent } from "react";
import { Input as Select } from "@shared/inputs";
import MenuItem from "@material-ui/core/MenuItem";
import { getDayHoursInterval } from "@helpers/new-trip.helper";
import { MEDIA_QUERIES } from "@constants";
import { theme } from "smartrips-skin";
import { darken } from "polished";
import { css } from "emotion";
import cx from "classnames";

const styles = {
  container: css({
    width: "50%",

    [MEDIA_QUERIES.tabletBreakpoint]: {
      width: "100%"
    },

    [".MuiInputBase-root"]: {
      height: 58,
      padding: 16
    },

    [".MuiSelect-root"]: {
      padding: "0 24px 0 0 !important",

      ["&:focus"]: {
        backgroundColor: "#FFF"
      }
    },

    [".MuiOutlinedInput-notchedOutline"]: {
      paddingLeft: "0px !important",
      borderRadius: "0 4px 4px 0",

      [MEDIA_QUERIES.tabletBreakpoint]: {
        borderRadius: "4px"
      },

      ["&::after"]: {
        top: "calc(100% - 1px)"
      }
    },

    [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
      borderColor: darken(0.2, theme.colors.border)
    }
  }),
  selectPaper: css({
    maxHeight: 300
  })
};

const HOUR_RANGE: string[] = getDayHoursInterval(30);

interface IProps {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  containerClasses?: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

const HourSelect: React.FC<IProps> = ({ containerClasses, ...rest }) => {
  return (
    <Select
      {...rest}
      select={true}
      containerClasses={cx(styles.container, containerClasses)}
      underlineHighlight={true}
      SelectProps={{
        MenuProps: { classes: { paper: styles.selectPaper } }
      }}
    >
      {HOUR_RANGE.map(time => (
        <MenuItem key={time} value={time}>
          {time}
        </MenuItem>
      ))}
    </Select>
  );
};

HourSelect.defaultProps = {
  containerClasses: ""
} as Partial<IProps>;

export { HourSelect };
