import React, { useState } from "react";
import { Input } from "../../shared/inputs";

interface Props {
  containerClasses: string;
  placeholder: string;
  onChange: (search: string) => void;
}

const SearchFilterInput = ({
  containerClasses,
  placeholder,
  onChange
}: Props) => {
  const [search, setSearch] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSearch(e.currentTarget.value);
    onChange(e.currentTarget.value);
  };

  return (
    <Input
      label="Buscar"
      placeholder={placeholder}
      value={search}
      onChange={handleChange}
      containerClasses={containerClasses}
    />
  );
};

export { SearchFilterInput };
