import { useMemo } from "react";

import { useItineraryApproval } from "../../../itinerary/itinerary-approval.context";
import { useItineraryServices } from "../../../itinerary/itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { ApprovalReviewServicesCardPresenterFactory } from "./approval-review-services-card.presenter";

export const useApprovalReviewServicesCardGuard = () => {
  const { isLoading: isLoadingTravelApproval } = useItineraryApproval();
  const {
    isLoadingOffersAvailabilitiesAndChanges,
    isLoadingTravelPoliciesPerOffer,
  } = useItineraryServices();

  return {
    loading:
      isLoadingTravelApproval ||
      isLoadingOffersAvailabilitiesAndChanges ||
      isLoadingTravelPoliciesPerOffer,
  };
};

export const useApprovalReviewServicesCardPresenter = () => {
  const {
    offersAvailabilitiesModel,
    travelApprovalModel,
  } = useItineraryContainer();

  return useMemo(
    () => ({
      approvalReviewServicesCardPresenter: travelApprovalModel
        ? ApprovalReviewServicesCardPresenterFactory.create(
            offersAvailabilitiesModel,
            travelApprovalModel,
          )
        : null,
    }),
    [offersAvailabilitiesModel, travelApprovalModel],
  );
};
