import React from "react";

import { css } from "@styled-system/css";

import { ApprovalListStatus } from "~/constants/enums";

import { MEDIA_QUERIES } from "@constants";

import { defaultTheme } from "@theme";

import { Row } from "@shared/layout/Row";

const tabStyles = {
  fontSize: 14,
  padding: "1rem 0",
  marginRight: "1.75rem",
  transition: "color 0.2s ease 0s",
  [MEDIA_QUERIES.tabletBreakpoint]: {
    fontSize: 12,
    marginRight: "1rem",
  },
};

const styles = {
  root: css({
    color: defaultTheme.textColor,
    paddingBottom: "2.5rem",
  }),
  activeTab: css({
    ...tabStyles,
    color: defaultTheme.textColor,
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: `2px solid #FF385C`,
  }),
  inactiveTab: css({
    ...tabStyles,
    color: defaultTheme.subTextColor,
    transition: "all 0.3s ease",
    textDecoration: "none",
    cursor: "pointer",
    ["&:hover"]: {
      color: "#FF385C",
    },
  }),
};

interface Props {
  selected: string;
  handleChange: (currentStatus: ApprovalListStatus) => void;
}

const StatusTab = ({ selected, handleChange }: Props) => {
  return (
    <div css={styles.root}>
      <Row>
        <div
          onClick={() => handleChange(ApprovalListStatus.PENDING)}
          css={
            selected === ApprovalListStatus.PENDING
              ? styles.activeTab
              : styles.inactiveTab
          }
        >
          <div>Solicitações pendentes</div>
        </div>
        <div
          onClick={() => handleChange(ApprovalListStatus.APPROVED)}
          css={
            selected === ApprovalListStatus.APPROVED
              ? styles.activeTab
              : styles.inactiveTab
          }
        >
          <div>Solicitações aprovadas</div>
        </div>
      </Row>
    </div>
  );
};

export { StatusTab };
