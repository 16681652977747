import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  accordion: {
    root: css({
      maxHeight: "768px",
      overflow: "auto"
    }),
    trigger: css({
      padding: "16px",
      ":disabled": {
        cursor: "default",
        opacity: 1
      }
    })
  },
  button: css({
    alignItems: "center",
    display: "flex",
    gap: "8px",
    height: "fit-content",
    width: "fit-content",
    ":disabled": {
      opacity: 0.5
    }
  }),
  header: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 768px)": {
      alignItems: "flex-start",
      flexDirection: "column"
    }
  }),
  loader: css({
    position: "relative",
    height: "50px",
    marginTop: "5rem",
    ".MuiCircularProgress-root": {
      color: "primary"
    }
  }),
  participant: {
    anchor: css({
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 4,
      cursor: "pointer",
      padding: "8px 12px",
      textDecoration: "none",
      transition: "background-color 0.15s ease-in-out",
      width: "fit-content",
      ":disabled": {
        cursor: "default",
        opacity: 0.75
      },
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    }),
    badge: css({
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "4px",
      padding: "4px 8px",
      width: "fit-content"
    }),
    button: css({
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 4,
      cursor: "pointer",
      padding: "8px 12px",
      transition: "background-color 0.15s ease-in-out",
      ":disabled": {
        cursor: "default",
        opacity: 0.75
      },
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    }),
    card: css({
      backgroundColor: "rgba(0, 0, 0, 0.025)",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px 16px 16px 56px"
    }),
    chevron: ({
      disabled,
      expanded
    }: {
      disabled: boolean;
      expanded: boolean;
    }) =>
      css({
        color: theme.colors.gray[2],
        opacity: disabled ? 0 : 1,
        transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
        transition: "transform 0.15s ease-in-out"
      }),
    footer: {
      link: css({
        color: theme.colors.primary,
        fontSize: 14,
        fontWeight: "bold"
      }),
      root: css({
        alignItems: "center",
        borderTop: `1px solid ${theme.colors.gray[1]}`,
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 16px"
      }),
      token: css({
        alignItems: "center",
        color: theme.colors.gray[3],
        display: "flex"
      })
    },
    icons: css({
      alignItems: "center",
      display: "flex",
      gap: "4px"
    }),
    offers: css({
      display: "flex",
      flexDirection: "column"
    }),
    travel: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[1]}`,
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px 0 0 0"
    })
  },
  root: css({
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: 8,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "32px",
    textDecoration: "none",
    transition: "border-color 0.15s ease-in-out",
    width: "100%"
  }),
  tabs: {
    root: ecss({
      minHeight: "unset !important",
      "@media (max-width: 768px)": {
        padding: "0"
      }
    }),
    scroller: ecss({
      border: `none !important`,
      borderRadius: 0,
      overflow: "visible",
      maxHeight: 38
    }),
    tab: {
      root: ecss({
        fontFamily: "'Open sans', sans-serif",
        fontSize: 14,
        letterSpacing: "0.04rem",
        minHeight: "unset !important",
        minWidth: "unset !important",
        padding: "8px",
        textTransform: "none"
      }),
      selected: ecss({
        color: theme.colors.primary,
        fontWeight: "bold"
      })
    }
  }
};
