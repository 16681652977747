import React, { useCallback, useMemo } from "react";

import { RecalculateCarOfferRequest } from "~/apps/corporate/dtos/car.dto";
import { CarOffer } from "~/apps/corporate/models/car.model";
import { UserSearch } from "~/apps/corporate/models/user.model";

import {
  Additional,
  AdditionalAdditionalDrivers,
  AdditionalQuantity,
} from "../additional/additional";
import { CarAdditionalPresentation } from "../car-result.types";
import { LocalizaAdditionalsHelper } from "./localiza-additionals.helper";
import { styles } from "./styles";

type Props = {
  additionalDrivers: UserSearch[];
  car: CarOffer;
  handleChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  handleRecalculateCarOffer: (
    data: RecalculateCarOfferRequest,
  ) => Promise<void>;
  handleSearchAdditionalDrivers: (
    search: string,
  ) => Promise<UserSearch[] | null>;
  loadingRecalculate: boolean;
};

export const LocalizaAdditionals: React.FC<Props> = ({
  additionalDrivers,
  car,
  handleChangeAdditionalDrivers,
  handleRecalculateCarOffer,
  handleSearchAdditionalDrivers,
  loadingRecalculate,
}) => {
  const localizaAdditionals = useMemo(
    () =>
      new LocalizaAdditionalsHelper({
        car,
      }),
    [car],
  );

  const recalculateCarOffer = useCallback(
    async (additional: CarAdditionalPresentation) => {
      if (!car.supplierInfo || loadingRecalculate) {
        return;
      }

      const selectedAdditionals = localizaAdditionals.getSelectedAdditionals();

      const additionalsToRecalculate = localizaAdditionals.buildAdditionalsToRecalculate(
        additional,
        selectedAdditionals,
      );

      await handleRecalculateCarOffer({
        additionals: additionalsToRecalculate,
        supplier_offer_id: car.supplierInfo.id,
      });
    },
    [handleRecalculateCarOffer, localizaAdditionals, loadingRecalculate],
  );

  return (
    <div css={styles.root}>
      <LocalizaAdditionalsProtections
        disabled={loadingRecalculate}
        formattedProtections={localizaAdditionals.formatProtectionAdditionals()}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
      />
      <LocalizaAdditionalsServices
        additionalDrivers={additionalDrivers}
        disabled={loadingRecalculate}
        formattedServices={localizaAdditionals.formatServiceAdditionals()}
        handleChangeAdditionalDrivers={handleChangeAdditionalDrivers}
        handleSearchAdditionalDrivers={handleSearchAdditionalDrivers}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
      />
      <LocalizaAdditionalsAccessories
        disabled={loadingRecalculate}
        formattedAccessories={localizaAdditionals.formatAccessoryAdditionals()}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
      />
    </div>
  );
};

type LocalizaAdditionalsAccessoriesProps = {
  disabled: boolean;
  formattedAccessories: {
    additionals?: CarAdditionalPresentation[] | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
};

const LocalizaAdditionalsAccessories: React.FC<LocalizaAdditionalsAccessoriesProps> = ({
  disabled,
  formattedAccessories,
  handleChange,
}) => {
  if (Object.keys(formattedAccessories).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Acessórios</span>
        <span css={styles.group.heading.subtitle}>
          Adicione acessórios para sua praticidade e conforto (sujeitos a
          disponibilidade na agência)
        </span>
      </div>
      <div css={styles.group.accessories.root}>
        {formattedAccessories.additionals
          ? formattedAccessories.additionals.map((additional) => (
              <AdditionalQuantity
                additional={additional}
                disabled={additional.disabled || disabled}
                key={additional.code}
                max={2}
                onChange={handleChange}
                quantity={additional.quantity}
              />
            ))
          : null}
      </div>
    </div>
  );
};

type LocalizaAdditionalsProtectionsProps = {
  disabled: boolean;
  formattedProtections: {
    additionals?: CarAdditionalPresentation[] | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
};

const LocalizaAdditionalsProtections: React.FC<LocalizaAdditionalsProtectionsProps> = ({
  disabled,
  formattedProtections,
  handleChange,
}) => {
  if (Object.keys(formattedProtections).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Proteção do carro</span>
        <span css={styles.group.heading.subtitle}>
          Mais segurança na sua jornada
        </span>
      </div>
      {formattedProtections.additionals
        ? formattedProtections.additionals.map((additional) => (
            <Additional
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
            />
          ))
        : null}
    </div>
  );
};

type LocalizaAdditionalsServicesProps = {
  additionalDrivers: UserSearch[];
  disabled: boolean;
  formattedServices: {
    additionals?: CarAdditionalPresentation[] | undefined;
    additionalDrivers?: CarAdditionalPresentation | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
  handleChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  handleSearchAdditionalDrivers: (
    search: string,
  ) => Promise<UserSearch[] | null>;
};

const LocalizaAdditionalsServices: React.FC<LocalizaAdditionalsServicesProps> = ({
  additionalDrivers,
  disabled,
  formattedServices,
  handleChange,
  handleChangeAdditionalDrivers,
  handleSearchAdditionalDrivers,
}) => {
  if (Object.keys(formattedServices).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Serviços</span>
        <span css={styles.group.heading.subtitle}>
          Fique livre de preocupações! Conheça e inclua os nossos adicionais na
          sua reserva:
        </span>
      </div>
      {formattedServices.additionals
        ? formattedServices.additionals.map((additional) => (
            <Additional
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
            />
          ))
        : null}
      {formattedServices.additionalDrivers ? (
        <AdditionalAdditionalDrivers
          additional={formattedServices.additionalDrivers}
          additionalDrivers={additionalDrivers}
          disabled={formattedServices.additionalDrivers.disabled || disabled}
          onChange={handleChange}
          onChangeAdditionalDrivers={handleChangeAdditionalDrivers}
          searchAdditionalDrivers={handleSearchAdditionalDrivers}
        />
      ) : null}
    </div>
  );
};
