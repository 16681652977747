import React, { SyntheticEvent } from "react";
import { Controller } from "react-hook-form";

import { compose } from "~/apps/shared/utils/compose";

import { useFormContext } from "../form";
import { useFormError } from "../hooks/use-form-error";

type FormFieldProps<ValueType> = {
  additionalInformation?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  name: string;
  onBlur?: () => void;
  onChange?: (value: ValueType) => void;
  required?: boolean;
  requiredLabel?: string;
  showError?: boolean;
} & (
  | {
      as?: React.ReactElement;
      render: (args: FormFieldRenderProps<ValueType>) => React.ReactElement;
    }
  | {
      as: React.ReactElement;
      render?: (args: FormFieldRenderProps<ValueType>) => React.ReactElement;
    }
);

interface FormFieldRenderProps<ValueType> {
  onChange: (event: SyntheticEvent) => void;
  onBlur: (event: SyntheticEvent) => void;
  value: ValueType;
}

import { styles } from "./styles";

export function FormField<T = string>({
  additionalInformation,
  as,
  disabled,
  label,
  name,
  onBlur: parentOnBlur,
  onChange: parentOnChange,
  render,
  required,
  requiredLabel,
  showError,
  ...rest
}: FormFieldProps<T>) {
  const { control } = useFormContext();

  const error = useFormError(name);

  const hasError = typeof error === "object";

  const additionalText =
    hasError && showError
      ? error?.message
      : required
      ? requiredLabel
      : additionalInformation;

  const handleRender = ({
    onChange,
    onBlur,
    value,
  }: FormFieldRenderProps<T>) => {
    const props = {
      disabled,
      error,
      name,
      onBlur: compose(onBlur, parentOnBlur),
      onChange: compose(onChange, parentOnChange),
      required,
      value,
      ...rest,
    };

    if (render) {
      return render(props);
    }

    if (as) {
      return React.cloneElement(as, props);
    }

    return null;
  };

  return (
    <>
      {label ? <FormLabel hasError={hasError}>{label}</FormLabel> : null}
      <Controller control={control} name={name} render={handleRender as any} />
      {additionalText || (hasError && showError) ? (
        <FormFieldAdditionalText hasError={hasError}>
          {hasError
            ? error?.message
            : required
            ? requiredLabel
            : additionalInformation}
        </FormFieldAdditionalText>
      ) : null}
    </>
  );
}

type FormLabelProps = React.HTMLAttributes<HTMLLabelElement> & {
  hasError?: boolean;
};

export const FormLabel: React.FC<FormLabelProps> = ({
  children,
  hasError,
  ...props
}) => {
  return (
    <label css={styles.label.root({ hasError })} {...props}>
      {children}
    </label>
  );
};

type FormFieldAdditionalTextProps = React.HTMLAttributes<HTMLParagraphElement> & {
  hasError: boolean;
};

export const FormFieldAdditionalText: React.FC<FormFieldAdditionalTextProps> = ({
  children,
  hasError,
  ...props
}) => {
  return (
    <p css={styles.additional.root({ error: hasError })} {...props}>
      {children}
    </p>
  );
};
