import { api } from "~/apps/shared/services/api";

import { GetClientUserCreditsResponse } from "../dtos/credits.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import * as creditsParser from "../parsers/credits.parser";

export async function getClientUserCredits(userToken: string) {
  return await api
    .request<GetClientUserCreditsResponse>({
      url: `/booking/clients/${getClientToken()}/credits/users/${userToken}`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => creditsParser.parseClientUserCredits(data));
}
