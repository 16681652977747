import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: ({ isDisabled }: { isDisabled?: boolean }) =>
    css({
      backgroundColor: "transparent",
      border: "none",
      borderBottom: !isDisabled
        ? `1px solid ${theme.colors.pink[300]}`
        : "unset",
      color: theme.colors.pink[500],
      fontFamily: theme.fonts.inter,
      transition: "all 0.15s ease-in-out",
      ":not(:disabled)": {
        cursor: "pointer",
        ":hover": {
          borderBottom: `1px solid ${theme.colors.pink[500]}`,
        },
      },
    }),
  empty: ({ isDisabled }: { isDisabled?: boolean }) =>
    css({
      backgroundColor: "transparent",
      border: "none",
      borderBottom: !isDisabled
        ? `1px solid ${theme.colors.gray[300]}`
        : "unset",
      color: theme.colors.gray[500],
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      transition: "all 0.15s ease-in-out",
      ...(!isDisabled && {
        ":hover": {
          borderBottom: `1px solid ${theme.colors.gray[500]}`,
        },
      }),
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
  root: css({
    alignItems: "center",
    color: theme.colors.gray[500],
    display: "flex",
    gap: "12px",
  }),
  tags: css({
    fontSize: "0.875rem",
    fontWeight: 600,
    textDecoration: "none",
  }),
  text: css({
    fontSize: "0.875rem",
  }),
};
