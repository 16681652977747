import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  approval: {
    credit: {
      label: css({
        color: theme.colors.green[500],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "center",
        borderTop: `1px dashed ${theme.colors.gray[100]}`,
        display: "flex",
        gap: "8px",
        justifyContent: "space-between",
        paddingTop: "16px",
      }),
      value: css({
        color: theme.colors.green[500],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
    empty: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    prices: {
      price: {
        label: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
        root: ({ isUnavailable = false }: { isUnavailable?: boolean }) =>
          css({
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            textDecoration: !isUnavailable ? "none" : "line-through",
          }),
        total: css({
          alignItems: "center",
          borderTop: `1px dashed ${theme.colors.gray[100]}`,
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          paddingTop: "16px",
        }),
        value: ({ isReserved = false }: { isReserved?: boolean }) =>
          css({
            color: isReserved
              ? theme.colors.green[500]
              : theme.colors.gray[700],
            flexShrink: 0,
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    toggle: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      fontFamily: theme.fonts.inter,
      fontSize: "0.75rem",
      fontWeight: 500,
      textDecoration: "underline",
      transition: "color 0.15s ease-in-out",
      width: "fit-content",
      ":hover": {
        color: theme.colors.pink[700],
      },
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
  },
  denial: {
    credit: {
      label: css({
        color: theme.colors.green[500],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "center",
        borderTop: `1px dashed ${theme.colors.gray[100]}`,
        display: "flex",
        gap: "8px",
        justifyContent: "space-between",
        paddingTop: "16px",
      }),
      value: css({
        color: theme.colors.green[500],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
    empty: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    prices: {
      price: {
        label: css({
          color: theme.colors.pink[500],
          fontSize: "0.875rem",
        }),
        root: ({ isUnavailable = false }: { isUnavailable?: boolean }) =>
          css({
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            textDecoration: !isUnavailable ? "none" : "line-through",
          }),
        total: css({
          alignItems: "center",
          borderTop: `1px dashed ${theme.colors.pink[300]}`,
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          paddingTop: "16px",
        }),
        value: ({ isReserved = false }: { isReserved?: boolean }) =>
          css({
            color: isReserved
              ? theme.colors.green[500]
              : theme.colors.pink[500],
            flexShrink: 0,
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    title: css({
      color: theme.colors.pink[500],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
  },
  disclaimer: css({
    borderTop: `1px dashed ${theme.colors.gray[100]}`,
    color: theme.colors.gray[700],
    fontSize: "0.75rem",
    lineHeight: "14px",
    paddingTop: "16px",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
