import React, { cloneElement } from "react";

import classNames from "classnames";

import { styles } from "./styles";

const icons = {
  airplane: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z"></path>
    </svg>
  ),
  "airplane-landing": (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 19h19v2h-19v-2zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97 1.33-1.97-1.54-1.45.39 2.59 4.49s7.12-1.9 16.57-4.43c.81-.23 1.28-1.05 1.07-1.85z"></path>
    </svg>
  ),
  "airplane-taking-off": (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 19h19v2h-19v-2zm16.84-3.15c.8.21 1.62-.26 1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.76-9.02L10.12 2v8.28L5.15 8.95l-.93-2.32-1.45-.39v5.17l16.57 4.44z"></path>
    </svg>
  ),
  bus: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 16c0 .88.39 1.67 1 2.22v1.28c0 .83.67 1.5 1.5 1.5S8 20.33 8 19.5V19h8v.5c0 .82.67 1.5 1.5 1.5.82 0 1.5-.67 1.5-1.5v-1.28c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6V6h12v5z"></path>
    </svg>
  ),
  calendar: (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 20 19"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8 0.999951V2.49995H5.3V0.999951C5.24317 0.84685 5.14085 0.714807 5.00678 0.621563C4.8727 0.528319 4.71331 0.478339 4.55 0.478339C4.38669 0.478339 4.22729 0.528319 4.09322 0.621563C3.95915 0.714807 3.85683 0.84685 3.8 0.999951V2.49995H2C1.46957 2.49995 0.960859 2.71066 0.585786 3.08574C0.210714 3.46081 0 3.96952 0 4.49995V16.5C0.133333 17.7 0.8 18.3666 2 18.5H18C18.5304 18.5 19.0391 18.2892 19.4142 17.9142C19.7893 17.5391 20 17.0304 20 16.5V4.49995C20 3.96952 19.7893 3.46081 19.4142 3.08574C19.0391 2.71066 18.5304 2.49995 18 2.49995H16.3V0.999951C16.3 0.787778 16.2157 0.584295 16.0657 0.434266C15.9157 0.284237 15.7122 0.199951 15.5 0.199951C15.2878 0.199951 15.0843 0.284237 14.9343 0.434266C14.7843 0.584295 14.7 0.787778 14.7 0.999951M3.7 3.99995H2C1.66667 3.99995 1.5 4.16662 1.5 4.49995V8.29995H18.5V4.49995C18.5 4.16662 18.3333 3.99995 18 3.99995H16.3V5.49995C16.3 5.71212 16.2157 5.91561 16.0657 6.06564C15.9157 6.21567 15.7122 6.29995 15.5 6.29995C15.2878 6.29995 15.0843 6.21567 14.9343 6.06564C14.7843 5.91561 14.7 5.71212 14.7 5.49995V3.99995H5.4V5.49995C5.34317 5.65305 5.24085 5.7851 5.10678 5.87834C4.9727 5.97158 4.81331 6.02156 4.65 6.02156C4.48669 6.02156 4.32729 5.97158 4.19322 5.87834C4.05915 5.7851 3.95683 5.65305 3.9 5.49995L3.7 3.99995ZM18.4 9.79995H1.4V16.5C1.4 16.8333 1.56667 17 1.9 17H17.9C18.2333 17 18.4 16.8333 18.4 16.5V9.79995Z"
        fill="currentColor"
      />
    </svg>
  ),
  car: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01l-1.97 5.67c-.07.21-.11.43-.11.66v7.16c0 .83.67 1.5 1.5 1.5S6 20.33 6 19.5V19h12v.5c0 .82.67 1.5 1.5 1.5.82 0 1.5-.67 1.5-1.5v-7.16c0-.22-.04-.45-.11-.66l-1.97-5.67zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.27-3.82c.14-.4.52-.68.95-.68h9.56c.43 0 .81.28.95.68L19 11H5z"></path>
    </svg>
  ),
  hotel: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z"></path>
    </svg>
  ),
  "question-circle-outline": (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 10a2.5 2.5 0 1 1 5 .2 2.4 2.4 0 0 1-2.5 2.4V14m0 3h0m9-5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  ),
  "search-outline": (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3465 22.3464L16.9949 16.9948M1.65356 10.5728C1.65356 5.64682 5.6469 1.65349 10.5729 1.65349C15.4989 1.65349 19.4923 5.64682 19.4923 10.5728C19.4923 15.4989 15.4989 19.4922 10.5729 19.4922C5.6469 19.4922 1.65356 15.4989 1.65356 10.5728Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.01104"
      />
    </svg>
  )
} as const;

export type Icons = keyof typeof icons;

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  size?: number;
  use: Icons;
};

export const Icon: React.FC<Props> = ({
  className,
  color,
  size,
  use,
  ...props
}) => {
  return (
    <span
      className={classNames(styles.root({ color, size }), className)}
      {...props}
    >
      {cloneElement(icons[use], {
        props: {
          ...icons[use].props,
          role: "img"
        }
      })}
    </span>
  );
};
