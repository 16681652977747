import React from "react";

import { Redirect, RouteComponentProps, Router } from "@reach/router";

import { ScrollToTop } from "../../components/scroll-to-top/scroll-to-top";
import { PasswordRedefine } from "./password-redefine/password-redefine";
import { PasswordReset } from "./password-reset/password-reset";
import { PasswordUpdate } from "./password-update/password-update";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Outlet: React.FC = () => {
  return (
    <Router css={styles.router} primary={false}>
      <PasswordRedefine path="/redefine/:redefineToken" />
      <PasswordReset path="/reset" />
      <PasswordUpdate path="/update" />
      <Redirect from="*" noThrow to="/" />
    </Router>
  );
};

export const Password: React.FC<Props> = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
