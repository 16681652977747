import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { useItineraryInfo } from "../../../itinerary-info.context";

export const useTravelPlanAddServiceGuard = () => {
  const { isLoading: isLoadingClientConfig } = useClientConfig();

  const { isLoading: isLoadingItineraryInfo } = useItineraryInfo();

  return {
    loading: isLoadingClientConfig || isLoadingItineraryInfo,
  };
};
