import React from "react";
import { Autocomplete } from "../../../../../shared/autocomplete/AutocompleteAsync";
import { getExpensePolicyTargetSuggestions } from "../../ExpensePolicy.service";
import { ExpensePolicyTarget } from "../../ExpensePolicy.types";
import { TargetItem } from "../../../../shared/TargetItem";
import { css } from "emotion";

const styles = {
  inputContainer: css({
    width: 350
  }),
  inputOutlineStyle: css({
    paddingLeft: "0 !important"
  })
};

interface Props {
  inputProps?: any;
  handleSelect: (target: ExpensePolicyTarget) => void;
}

const TargetsAutosuggestion = ({ inputProps, handleSelect }: Props) => {
  return (
    <Autocomplete
      inputProps={{
        placeholder: "Procurar por usuários",
        underlineHighlight: true,
        containerClasses: styles.inputContainer,
        notchedOutlineStyle: styles.inputOutlineStyle,
        ...inputProps
      }}
      clearOnSelect={true}
      handleSelect={handleSelect}
      fetchItems={getExpensePolicyTargetSuggestions}
      customItemElement={TargetItem}
    />
  );
};

export { TargetsAutosuggestion };
