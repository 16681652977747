import React from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { User } from "~/apps/corporate/models/user.model";

import * as usersService from "../../users.service";
import { styles } from "./styles";
import {
  UserListItem,
  UserListItemSkeleton,
} from "./users-list-item/users-list-item";

const onUserEditClick = (userToken: string, guest?: boolean | null) => {
  return () => {
    navigate(
      `/configurations/users/${userToken}/edit?type=${
        guest ? "guest" : "user"
      }`,
    );
  };
};

type Props = {
  visibility?: "active" | "inactive";
  users: User[];
};

export const UsersList: React.FC<Props> = ({ visibility, users }) => {
  const queryClient = useQueryClient();

  const { showSnackMessage } = useApplication();

  const onUserDelete = useCallback(
    async (user: User) => {
      try {
        await usersService.deleteUser(user.userToken);

        void queryClient.invalidateQueries(["users"]);

        showSnackMessage("Usuário excluído com sucesso!", "success");
      } catch (error) {
        showSnackMessage(
          error?.description || "Não foi possível excluir usuário",
          "error",
        );
      }
    },
    [queryClient, showSnackMessage],
  );

  const onUserUnarchive = useCallback(
    async (user: User) => {
      try {
        await usersService.unarchiveUser(user.userToken);

        void queryClient.invalidateQueries(["users"]);

        showSnackMessage("Usuário reativado com sucesso!", "success");
      } catch (error) {
        showSnackMessage(
          error?.description || "Não foi possível reativar o usuário",
          "error",
        );
      }
    },
    [queryClient, showSnackMessage],
  );

  return (
    <div css={styles.root}>
      <span css={styles.count}>Usuários encontrados: {users.length}</span>
      <div css={styles.list}>
        {users.map((user) => (
          <UserListItem
            key={user.userToken}
            onUserDelete={onUserDelete}
            onUserEditClick={onUserEditClick}
            onUserUnarchive={onUserUnarchive}
            user={user}
            visibility={visibility}
          />
        ))}
      </div>
    </div>
  );
};

export const UsersListSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <UserListItemSkeleton key={index} />
        ))}
    </div>
  );
};
