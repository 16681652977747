import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { ITripOffer } from "../../../trips.types";
import { CardOfferStatus } from "../card-offer-status/card-offer-status";
import { CardOfferTags } from "../card-offer-tags/card-offer-tags";
import { styles } from "./styles";
type Props = {
  offer: ITripOffer;
};

export const TripCardCarOffer: React.FC<Props> = ({ offer }) => {
  const { car, date, status, tagStatus } = offer;

  if (!car) {
    return null;
  }

  const { modelDescription, rentDays } = car;

  const formattedEndDate = moment(date.end).format("DD MMM YYYY");
  const formattedStartDate = moment(date.initial).format("DD MMM YYYY");

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <Icon css={styles.left.icon} size={16} use="car" />
        <div css={styles.left.right.root}>
          <div css={styles.left.right.top.root}>
            <span css={styles.left.right.top.label}>
              {rentDays > 1 ? `${rentDays} dias` : `${rentDays} dia`} de aluguel
              com <strong>{modelDescription}</strong>
            </span>
          </div>
          <div css={styles.left.right.bottom.root}>
            <CardOfferStatus status={status} />
            <span css={styles.left.right.bottom.date}>
              {formattedStartDate} - {formattedEndDate}
            </span>
          </div>
        </div>
      </div>
      <CardOfferTags tags={tagStatus} />
    </div>
  );
};
