import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Sheet } from "~/apps/shared/components/sheet/sheet";

import { Button } from "@toolkit/v2";

import {
  useBusesFilters,
  useBusesFiltersActions,
  useBusesPage,
  useBusesPageActions,
} from "../../buses.context";
import { ArrivalTerminalFilter } from "./arrival-terminal-filter/arrival-terminal-filter";
import { ClassesFilter } from "./classes-filter/classes-filter";
import { CompanyFilter } from "./company-filter/company-filter";
import { DepartureTerminalFilter } from "./departure-terminal-filter/departure-terminal-filter";
import { PriceFilter } from "./price-filter/price-filter";
import { styles } from "./styles";

type Props = {
  isMobile?: boolean;
};

export const ContentFilters: React.FC<Props> = ({ isMobile }) => {
  const {
    handleArrivalFilterChange,
    handleCategoryFilterChange,
    handleCompanyFilterChange,
    handleDepartureFilterChange,
    handlePriceFilterChange,
  } = useBusesFiltersActions();
  const {
    arrivalOptions,
    categoryOptions,
    companyOptions,
    departureOptions,
    priceRange,
    selectedPriceRange,
  } = useBusesFilters();
  const { handleToggleCategoryExplanation } = useBusesPageActions();
  const { isLoading } = useBusesPage();

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleCloseSheet = useCallback(() => {
    setIsSheetOpen(false);
  }, []);

  const handleOpenSheet = useCallback(() => {
    setIsSheetOpen(true);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);

      return;
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        <Button
          css={styles.sheet.trigger.root}
          fill="outlined"
          onClick={handleOpenSheet}
        >
          <Icon
            css={styles.sheet.trigger.icon}
            size={20}
            use="adjustments-horizontal"
          />
          Filtrar resultados
        </Button>
        <Sheet
          anchor="bottom"
          disableSwipeToOpen
          onClose={handleCloseSheet}
          onOpen={handleOpenSheet}
          open={isSheetOpen}
        >
          <div css={styles.sheet.root}>
            <PriceFilter
              disabled={isLoading}
              handlePriceFilterChange={handlePriceFilterChange}
              priceRange={priceRange}
              selectedRange={selectedPriceRange}
            />
            <ClassesFilter
              disabled={isLoading}
              categoryOptions={categoryOptions}
              handleCategoryFilterChange={handleCategoryFilterChange}
              handleToggleCategoryExplanation={handleToggleCategoryExplanation}
            />
            <DepartureTerminalFilter
              departureOptions={departureOptions}
              disabled={isLoading}
              handleDepartureFilterChange={handleDepartureFilterChange}
            />
            <ArrivalTerminalFilter
              arrivalOptions={arrivalOptions}
              disabled={isLoading}
              handleArrivalFilterChange={handleArrivalFilterChange}
            />
            <CompanyFilter
              companyOptions={companyOptions}
              disabled={isLoading}
              handleCompanyFilterChange={handleCompanyFilterChange}
            />
          </div>
        </Sheet>
      </>
    );
  }

  return (
    <div css={styles.root}>
      <PriceFilter
        disabled={isLoading}
        handlePriceFilterChange={handlePriceFilterChange}
        priceRange={priceRange}
        selectedRange={selectedPriceRange}
      />
      <ClassesFilter
        disabled={isLoading}
        categoryOptions={categoryOptions}
        handleCategoryFilterChange={handleCategoryFilterChange}
        handleToggleCategoryExplanation={handleToggleCategoryExplanation}
      />
      <DepartureTerminalFilter
        departureOptions={departureOptions}
        disabled={isLoading}
        handleDepartureFilterChange={handleDepartureFilterChange}
      />
      <ArrivalTerminalFilter
        arrivalOptions={arrivalOptions}
        disabled={isLoading}
        handleArrivalFilterChange={handleArrivalFilterChange}
      />
      <CompanyFilter
        companyOptions={companyOptions}
        disabled={isLoading}
        handleCompanyFilterChange={handleCompanyFilterChange}
      />
    </div>
  );
};
