import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { SnackbarType } from "./snackbar";

export const styles = {
  close: css({
    backgroundColor: "transparent",
    border: "none",
    color: "inherit",
    cursor: "pointer",
  }),
  message: css({
    fontSize: "0.75rem",
  }),
  root: ({ type }: { type: SnackbarType }) =>
    css({
      ".MuiPaper-root": {
        alignItems: "flex-start",
        backgroundColor:
          type === "error"
            ? theme.colors.pink[500]
            : type === "success"
            ? theme.colors.green[500]
            : theme.colors.gray[500],
        borderRadius: "8px",
        color: theme.colors.white,
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        justifyContent: "space-between",
        maxWidth: "384px",
        padding: "12px 16px",
        transition: "all 0.15s ease-in-out !important",
        ":hover": {
          backgroundColor:
            type === "error"
              ? theme.colors.pink[700]
              : type === "success"
              ? theme.colors.green[700]
              : theme.colors.gray[700],
        },
        "@media screen and (max-width: 768px)": {
          maxWidth: "unset",
          width: "100%",
        },
      },
      ".MuiSnackbarContent": {
        "&-action": {
          margin: "unset",
          padding: "unset",
          transform: "translateY(2.5px)",
        },
        "&-message": {
          flex: 1,
          padding: "unset",
        },
      },
    }),
};
