import {
  DocumentTarget,
  DocumentType,
  FieldRequirement,
} from "~/apps/shared/constants/enums";

export type ItineraryDocumentPendenciesDocuments = {
  expired: boolean;
  registered: boolean;
  target: DocumentTarget;
  type: DocumentType;
}[];

export type ItineraryDocumentPendencies = {
  documents: ItineraryDocumentPendenciesDocuments;
  travelerIdentificationStatus: DocumentTarget;
};

class ItineraryDocumentPendenciesModel {
  constructor(
    private readonly documentPendencies: ItineraryDocumentPendencies,
  ) {}

  private getDocumentsPending() {
    return this.documentPendencies.documents.filter((document) => {
      const isExpired = document.expired;

      if (isExpired) {
        return true;
      }

      const isRegistered = document.registered;

      if (!isRegistered) {
        return true;
      }

      return false;
    });
  }

  public getDocumentsPendingForTarget(target: DocumentTarget) {
    return target === DocumentTarget.BRAZILIAN
      ? this.getDocumentsPendingForBrazilian()
      : this.getDocumentsPendingForForeigner();
  }

  private getDocumentsPendingForBrazilian() {
    return this.getDocumentsPending().filter(
      (document) => document.target === DocumentTarget.BRAZILIAN,
    );
  }

  private getDocumentsPendingForForeigner() {
    return this.getDocumentsPending().filter(
      (document) => document.target === DocumentTarget.FOREIGNER,
    );
  }

  public getTravelerIdentification() {
    return this.documentPendencies.travelerIdentificationStatus;
  }

  public hasDifferentDocumentsPendingForDifferentTargets() {
    const documentsPendingForBrazilian = this.getDocumentsPendingForBrazilian();
    const documentsPendingForForeigner = this.getDocumentsPendingForForeigner();

    if (
      documentsPendingForBrazilian.length === 0 ||
      documentsPendingForForeigner.length === 0
    ) {
      return false;
    }

    if (
      documentsPendingForBrazilian.length !==
      documentsPendingForForeigner.length
    ) {
      return true;
    }

    const documentTypesPendingForBrazilian = documentsPendingForBrazilian.map(
      (document) => document.type,
    );
    const documentTypesPendingForForeigner = documentsPendingForForeigner.map(
      (document) => document.type,
    );

    return documentTypesPendingForBrazilian.some(
      (document) => !documentTypesPendingForForeigner.includes(document),
    );
  }

  public isPendingForTarget(target: DocumentTarget) {
    return this.getDocumentsPendingForTarget(target).length > 0;
  }

  public isTravelerIdentified() {
    return (
      this.documentPendencies.travelerIdentificationStatus !==
      DocumentTarget.UNIDENTIFIED
    );
  }

  public isTravelerUnidentified() {
    return (
      this.documentPendencies.travelerIdentificationStatus ===
      DocumentTarget.UNIDENTIFIED
    );
  }

  public toObject() {
    return this.documentPendencies;
  }
}

export class ItineraryDocumentPendenciesModelFactory {
  public static create(documentPendencies: ItineraryDocumentPendencies) {
    return new ItineraryDocumentPendenciesModel(documentPendencies);
  }
}

export type ItineraryPendencies = {
  billingProfile: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  companyArea: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  costCenter: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  flightPurpose: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  hotelPurpose: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  justification: {
    data: string[];
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  project: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  travelerPhone: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  travelTags: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
  tripPurpose: {
    formRequirement: FieldRequirement;
    pending: boolean;
  };
};

export class ItineraryPendenciesModel {
  public isCategorizationResolved = false;
  public isDocumentationResolved = false;
  public isNotEnoughTravelersResolved = false;
  public isOffersJustificationResolved = false;
  public isTravelerPhoneResolved = false;
  public isTravelPurposesResolved = false;

  constructor(
    public pendencies: {
      isCategorizationResolved: boolean;
      isDocumentationResolved: boolean;
      isNotEnoughTravelersResolved: boolean;
      isOffersJustificationResolved: boolean;
      isTravelerPhoneResolved: boolean;
      isTravelPurposesResolved: boolean;
      itineraryDocumentPendencies: ItineraryDocumentPendenciesModel;
      itineraryPendencies: ItineraryPendencies;
    },
  ) {
    this.resolveOptionalPendencies();

    const {
      isCategorizationResolved,
      isDocumentationResolved,
      isNotEnoughTravelersResolved,
      isOffersJustificationResolved,
      isTravelerPhoneResolved,
      isTravelPurposesResolved,
    } = pendencies;

    if (isCategorizationResolved) {
      this.isCategorizationResolved = isCategorizationResolved;
    }

    if (isDocumentationResolved) {
      this.isDocumentationResolved = isDocumentationResolved;
    }

    if (isNotEnoughTravelersResolved) {
      this.isNotEnoughTravelersResolved = isNotEnoughTravelersResolved;
    }

    if (isOffersJustificationResolved) {
      this.isOffersJustificationResolved = isOffersJustificationResolved;
    }

    if (isTravelerPhoneResolved) {
      this.isTravelerPhoneResolved = isTravelerPhoneResolved;
    }

    if (isTravelPurposesResolved) {
      this.isTravelPurposesResolved = isTravelPurposesResolved;
    }
  }

  public getOffersJustificationPending() {
    return this.pendencies.itineraryPendencies.justification.data;
  }

  public getOffersJustificationPendingDefaultValue() {
    return this.getOffersJustificationPending().map((offerToken) => ({
      justification: "",
      offerToken,
    }));
  }

  public hasBlockingPendencies() {
    return (
      this.isCategorizationBlocking() ||
      this.isDocumentationBlocking() ||
      this.isOffersJustificationBlocking() ||
      this.isTravelerPhoneBlocking() ||
      this.isTravelPurposeBlocking()
    );
  }

  public hasPendencies() {
    return (
      this.isCategorizationPending() ||
      this.isDocumentationPending() ||
      this.isHotelPurposePending() ||
      this.isOffersJustificationPending() ||
      this.isTravelerPhonePending() ||
      this.isTravelPurposePending()
    );
  }

  private isBillingProfileBlocking() {
    return this.isBillingProfilePending() && this.isBillingProfileRequired();
  }

  public isBillingProfileOptional() {
    return (
      this.pendencies.itineraryPendencies.billingProfile.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isBillingProfilePending() {
    return this.pendencies.itineraryPendencies.billingProfile.pending;
  }

  public isBillingProfileRequired() {
    return (
      this.pendencies.itineraryPendencies.billingProfile.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isCategorizationBlocking() {
    return (
      !this.isCategorizationResolved &&
      (this.isBillingProfileBlocking() ||
        this.isCompanyAreaBlocking() ||
        this.isCostCenterBlocking() ||
        this.isProjectBlocking() ||
        this.isTravelTagsBlocking())
    );
  }

  public isCategorizationPending() {
    return (
      !this.isCategorizationResolved &&
      (this.isBillingProfilePending() ||
        this.isCompanyAreaPending() ||
        this.isCostCenterPending() ||
        this.isProjectPending() ||
        this.isTravelTagsPending())
    );
  }

  private isCompanyAreaBlocking() {
    return this.isCompanyAreaPending() && this.isCompanyAreaRequired();
  }

  public isCompanyAreaOptional() {
    return (
      this.pendencies.itineraryPendencies.companyArea.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isCompanyAreaPending() {
    return this.pendencies.itineraryPendencies.companyArea.pending;
  }

  public isCompanyAreaRequired() {
    return (
      this.pendencies.itineraryPendencies.companyArea.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isCostCenterBlocking() {
    return this.isCostCenterPending() && this.isCostCenterRequired();
  }

  public isCostCenterOptional() {
    return (
      this.pendencies.itineraryPendencies.costCenter.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isCostCenterPending() {
    return this.pendencies.itineraryPendencies.costCenter.pending;
  }

  public isCostCenterRequired() {
    return (
      this.pendencies.itineraryPendencies.costCenter.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isDocumentationBlocking() {
    return !this.isDocumentationResolved && this.isDocumentationPending();
  }

  private isDocumentationOptional() {
    return false;
  }

  public isDocumentationPending() {
    let target = this.pendencies.itineraryDocumentPendencies.getTravelerIdentification();

    if (this.pendencies.itineraryDocumentPendencies.isTravelerUnidentified()) {
      target = DocumentTarget.BRAZILIAN;
    }

    return (
      !this.isDocumentationResolved &&
      this.pendencies.itineraryDocumentPendencies.isPendingForTarget(target)
    );
  }

  public isDocumentationRequired() {
    return this.isDocumentationPending();
  }

  private isFlightPurposeBlocking() {
    return this.isFlightPurposePending() && this.isFlightPurposeRequired();
  }

  public isFlightPurposePending() {
    return this.pendencies.itineraryPendencies.flightPurpose.pending;
  }

  public isFlightPurposeRequired() {
    return (
      this.pendencies.itineraryPendencies.flightPurpose.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isHotelPurposeBlocking() {
    return this.isHotelPurposePending() && this.isHotelPurposeRequired();
  }

  public isHotelPurposePending() {
    return this.pendencies.itineraryPendencies.hotelPurpose.pending;
  }

  public isHotelPurposeRequired() {
    return (
      this.pendencies.itineraryPendencies.hotelPurpose.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isOffersJustificationBlocking() {
    return (
      this.isOffersJustificationPending() &&
      this.isOffersJustificationRequired()
    );
  }

  public isOffersJustificationOptional() {
    return (
      this.pendencies.itineraryPendencies.justification.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isOffersJustificationPending() {
    return (
      !this.isOffersJustificationResolved &&
      this.pendencies.itineraryPendencies.justification.pending
    );
  }

  public isOffersJustificationRequired() {
    return (
      this.pendencies.itineraryPendencies.justification.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isProjectBlocking() {
    return this.isProjectPending() && this.isProjectRequired();
  }

  public isProjectOptional() {
    return (
      this.pendencies.itineraryPendencies.project.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isProjectPending() {
    return this.pendencies.itineraryPendencies.project.pending;
  }

  public isProjectRequired() {
    return (
      this.pendencies.itineraryPendencies.project.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isTravelerPhoneBlocking() {
    return this.isTravelerPhonePending() && this.isTravelerPhoneRequired();
  }

  public isTravelerPhoneOptional() {
    return (
      this.pendencies.itineraryPendencies.travelerPhone.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isTravelerPhonePending() {
    return (
      !this.isTravelerPhoneResolved &&
      this.pendencies.itineraryPendencies.travelerPhone.pending
    );
  }

  public isTravelerPhoneRequired() {
    return (
      this.pendencies.itineraryPendencies.travelerPhone.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isTravelPurposeBlocking() {
    return (
      !this.isTravelPurposesResolved &&
      (this.isFlightPurposeBlocking() ||
        this.isHotelPurposeBlocking() ||
        this.isTripPurposeBlocking())
    );
  }

  public isTravelPurposePending() {
    return (
      !this.isTravelPurposesResolved &&
      (this.isFlightPurposePending() ||
        this.isHotelPurposePending() ||
        this.isTripPurposePending())
    );
  }

  private isTripPurposeBlocking() {
    return this.isTripPurposePending() && this.isTripPurposeRequired();
  }

  public isTripPurposePending() {
    return this.pendencies.itineraryPendencies.tripPurpose.pending;
  }

  public isTripPurposeRequired() {
    return (
      this.pendencies.itineraryPendencies.tripPurpose.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  private isTravelTagsBlocking() {
    return this.isTravelTagsPending() && this.isTravelTagsRequired();
  }

  private isTravelTagsOptional() {
    return (
      this.pendencies.itineraryPendencies.travelTags.formRequirement ===
      FieldRequirement.OPTIONAL
    );
  }

  public isTravelTagsPending() {
    return this.pendencies.itineraryPendencies.travelTags.pending;
  }

  public isTravelTagsRequired() {
    return (
      this.pendencies.itineraryPendencies.travelTags.formRequirement ===
      FieldRequirement.REQUIRED
    );
  }

  public resolveAllPendencies() {
    this.isCategorizationResolved = true;
    this.isDocumentationResolved = true;
    this.isNotEnoughTravelersResolved = true;
    this.isOffersJustificationResolved = true;
    this.isTravelerPhoneResolved = true;
    this.isTravelPurposesResolved = true;

    return this;
  }

  public resolveCategorizationPendencies() {
    this.isCategorizationResolved = true;

    return this;
  }

  public resolveDocumentationPendencies() {
    this.isDocumentationResolved = true;

    return this;
  }

  public resolveNotEnoughTravelersPendencies() {
    this.isNotEnoughTravelersResolved = true;

    return this;
  }

  public resolveOffersJustificationPendencies() {
    this.isOffersJustificationResolved = true;

    return this;
  }

  private resolveOptionalPendencies() {
    this.isCategorizationResolved =
      this.isBillingProfileOptional() &&
      this.isCompanyAreaOptional() &&
      this.isCostCenterOptional() &&
      this.isProjectOptional() &&
      this.isTravelTagsOptional();

    this.isDocumentationResolved = this.isDocumentationOptional();

    this.isOffersJustificationResolved = this.isOffersJustificationOptional();

    this.isTravelerPhoneResolved = this.isTravelerPhoneOptional();
  }

  public resolveTravelPurposes() {
    this.isTravelPurposesResolved = true;

    return this;
  }

  public resolveTravelerPhonePendencies() {
    this.isTravelerPhoneResolved = true;

    return this;
  }

  public toObject() {
    const {
      itineraryDocumentPendencies,
      itineraryPendencies,
    } = this.pendencies;

    return {
      isCategorizationResolved: this.isCategorizationResolved,
      isDocumentationResolved: this.isDocumentationResolved,
      isNotEnoughTravelersResolved: this.isNotEnoughTravelersResolved,
      isOffersJustificationResolved: this.isOffersJustificationResolved,
      isTravelerPhoneResolved: this.isTravelerPhoneResolved,
      isTravelPurposesResolved: this.isTravelPurposesResolved,
      itineraryDocumentPendencies: itineraryDocumentPendencies.toObject(),
      itineraryPendencies,
    };
  }

  public updateItineraryDocumentPendencies(
    itineraryDocumentPendencies: ItineraryDocumentPendenciesModel,
  ) {
    this.pendencies.itineraryDocumentPendencies = itineraryDocumentPendencies;

    return this;
  }
}

export class ItineraryPendenciesModelFactory {
  public static create(pendencies: {
    isCategorizationResolved: boolean;
    isDocumentationResolved: boolean;
    isNotEnoughTravelersResolved: boolean;
    isOffersJustificationResolved: boolean;
    isTravelerPhoneResolved: boolean;
    isTravelPurposesResolved: boolean;
    itineraryDocumentPendencies: ItineraryDocumentPendencies;
    itineraryPendencies: ItineraryPendencies;
  }) {
    const { itineraryDocumentPendencies } = pendencies;

    const itineraryDocumentPendenciesModel = ItineraryDocumentPendenciesModelFactory.create(
      itineraryDocumentPendencies,
    );

    return new ItineraryPendenciesModel({
      ...pendencies,
      itineraryDocumentPendencies: itineraryDocumentPendenciesModel,
    });
  }
}
