import React, { useMemo } from "react";

import { Filters } from "~/apps/shared/components/filters/filters";
import { TripsStatusFilter as TripsStatusFilterEnum } from "~/apps/shared/constants/enums";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { useTravelsFilters, useTravelsFiltersActions } from "../trips.context";

const options = [
  {
    label: "Aprovação Pendente",
    value: TripsStatusFilterEnum.HAS_APPROVAL_PENDING,
  },
  {
    label: "Processando Emissão",
    value: TripsStatusFilterEnum.HAS_BOOKING_PENDING,
  },
  {
    label: "Processando Cancelamento",
    value: TripsStatusFilterEnum.HAS_CANCELING_PENDING,
  },
  {
    label: "Falha de Emissão",
    value: TripsStatusFilterEnum.HAS_BOOKING_FAILED,
  },
] as const;

export const TripsStatusFilter: React.FC = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 435, [windowWidth]);

  const { pendingFilters } = useTravelsFilters();
  const { handleChangePendingFilters } = useTravelsFiltersActions();

  return (
    <Filters
      anchor={isMobile ? "left" : "right"}
      isMulti
      onChange={(value) => {
        if (pendingFilters.some((filter) => filter === value)) {
          handleChangePendingFilters(
            pendingFilters.filter((filter) => filter !== value),
          );

          return;
        }

        handleChangePendingFilters([...pendingFilters, value]);
      }}
      options={options}
      value={pendingFilters.map((filter) => filter)}
    />
  );
};
