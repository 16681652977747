import React from "react";
import { css } from "@styled-system/css";
import Dialog from "@material-ui/core/Dialog";
import { useAdvancedExpensesContext } from "../AdvancedExpenses.context";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { AdvancedExpenseStatus } from "./AdvancedExpenseStatus";

const styles = {
  modalRoot: css({
    [".MuiDialog-paper"]: {
      width: "100%",
      maxWidth: "500px",
      p: "2rem 2.5rem 2.5rem 2rem"
    }
  }),
  closeIcon: css({
    cursor: "pointer",
    position: "absolute",
    right: "0.8rem",
    top: "0.8rem"
  })
};
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export const StatusDialog: React.FC<{}> = ({}) => {
  const {
    statusDialogOpen,
    handleCloseItemStatus
  } = useAdvancedExpensesContext();
  return (
    <Dialog
      css={styles.modalRoot}
      open={statusDialogOpen}
      TransitionComponent={Transition}
      onClose={handleCloseItemStatus}
    >
      <CloseIcon css={styles.closeIcon} onClick={handleCloseItemStatus} />
      <AdvancedExpenseStatus />
    </Dialog>
  );
};

export default StatusDialog;
