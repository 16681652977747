import React, { useState, useEffect } from "react";
import { useContextFactory } from "@hooks";
import { TagService } from "@shared/tags/Tag.service";
import { ALERT_TYPES } from "@constants";
import * as TagManagementUtils from "./tagsManagement.utils";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

const initialState = {
  tab: "active",
  addTagInput: "",
  editTagInput: "",
  activeTags: [],
  inactiveTags: [],
  isAddTagDialogOpen: false,
  isEditDialogOpen: false,
  selectedTag: null,
  loading: false,
  onlyAdminOption: null,
  requiredTravelTagOption: null
};

const TagsManagementContext = React.createContext();

const useTagsManagementContext = useContextFactory(
  "TagsManagement",
  TagsManagementContext
);

const TagsManagementProvider = props => {
  const {
    showSnackMessage,
  } = useApplication();
  const {
    clientConfig,
    handleUpdateTagsAdminOnly,
    handleUpdateTravelTagsRequired
  } = useClientConfig();

  const [state, setState] = useState(initialState);
  
  const clientConfigToken = clientConfig ? clientConfig.getToken() : null;

  const handleTabChange = (value) => {
    setState(prevState => ({ ...prevState, tab: value }));
  };

  const handleInputChange = e => {
    e.persist();
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const openEditDialog = tag => {
    setState(prevState => ({
      ...prevState,
      isEditDialogOpen: true,
      selectedTag: tag,
      editTagInput: tag.tagName
    }));
  };

  const closeEditDialog = () => {
    setState(prevState => ({
      ...prevState,
      isEditDialogOpen: false,
      selectedTag: null,
      editTagInput: ""
    }));
  };

  const openAddTagDialog = () => {
    setState(prevState => ({
      ...prevState,
      isAddTagDialogOpen: true,
      addTagInput: ""
    }));
  };

  const closeAddTagDialog = () => {
    setState(prevState => ({
      ...prevState,
      isAddTagDialogOpen: false,
      addTagInput: ""
    }));
  };

  const toggleOnlyAdminOption = () => () => {
    toggleTagOption("onlyAdminOption");
  };

  const toggleRequiredTravelTagOption = () => () => {
    toggleTagOption("requiredTravelTagOption");
  };

  const toggleTagOption = async optionName => {
    const previousOption = state[optionName];
    setState(prevState => ({
      ...prevState,
      [optionName]: !previousOption
    }));
    const { error } = await TagService.changeTagOption(
      clientConfigToken,
      TagManagementUtils.transformTagConfigOptionIntoDto({
        [optionName]: !previousOption
      })
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      setState(prevState => ({
        ...prevState,
        [optionName]: previousOption
      }));
    }
  };

  const handleCreateTag = async () => {
    if (
      isTagRepeated(state.addTagInput, [
        ...state.activeTags,
        ...state.inactiveTags
      ])
    ) {
      showSnackMessage(
        "Outra etiqueta já possui esse nome.",
        ALERT_TYPES.ERROR
      );
      return;
    }

    const { data, error } = await TagService.createNewTag(
      state.addTagInput.trim()
    );

    if (error) {
      setState(prevState => ({
        ...prevState,
        addTagInput: ""
      }));
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      return;
    }

    const createdTag = { ...data, useCount: 0 };

    setState(prevState => ({
      ...prevState,
      activeTags: [createdTag, ...prevState.activeTags],
      addTagInput: "",
      isAddTagDialogOpen: false
    }));
    showSnackMessage("Tag criada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const handleEditTag = async () => {
    const tagsToCompare = state.activeTags
      .concat(state.inactiveTags)
      .filter(tag => tag.tagToken !== state.selectedTag.tagToken);

    if (isTagRepeated(state.editTagInput, tagsToCompare)) {
      showSnackMessage(
        "Outra etiqueta já possui esse nome.",
        ALERT_TYPES.ERROR
      );
      return;
    }

    const { data, error } = await TagService.editTag(
      state.editTagInput.trim(),
      state.selectedTag.tagToken
    );
    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      setState(prevState => ({
        ...prevState
      }));
      return;
    }

    setState(prevState => ({
      ...prevState,
      activeTags: prevState.activeTags.map(tag =>
        tag.tagToken === data.tagToken
          ? { ...data, useCount: prevState.selectedTag.useCount }
          : tag
      ),
      inactiveTags: prevState.inactiveTags.map(tag =>
        tag.tagToken === data.tagToken
          ? { ...data, useCount: prevState.selectedTag.useCount }
          : tag
      ),
      selectedTag: null,
      editTagInput: ""
    }));
    showSnackMessage("Etiqueta alterada com sucesso", ALERT_TYPES.SUCCESS);
    closeEditDialog();
  };

  const handleArchiveTag = async (tag) => {
    const { error } = await TagService.archiveTag(tag.tagToken);
    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      return;
    }

    setState(prevState => ({
      ...prevState,
      activeTags: prevState.activeTags.filter(
        cur => cur.tagToken !== tag.tagToken
      ),
      inactiveTags: [tag, ...prevState.inactiveTags]
    }));
  };

  const handleUnarchiveTag = async (tag) => {
    const { error } = await TagService.unarchiveTag(tag.tagToken);
    if (error) {
      showSnackMessage(error.description);
      return;
    }

    setState(prevState => ({
      ...prevState,
      activeTags: [tag, ...prevState.activeTags],
      inactiveTags: prevState.inactiveTags.filter(
        cur => cur.tagToken !== tag.tagToken
      )
    }));
  };

  const fetchClientTagsAndOptions = async () => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    const activeTags = [],
      inactiveTags = [];
    const [
      { data: tagsData, error: tagsError },
      { data: tagOptionsData, error: tagOptionsError }
    ] = await Promise.all([
      TagService.getAllClientTags(),
      TagService.getClientTagOptions()
    ]);
    if (tagsError) {
      showSnackMessage(tagsError.description, ALERT_TYPES.ERROR);
    }

    if (tagOptionsError) {
      showSnackMessage(tagsError.description, ALERT_TYPES.ERROR);
    }

    if (tagsError || tagOptionsError) {
      setState(prevState => ({
        ...prevState,
        loading: false
      }));
      return;
    }

    tagsData.forEach(tag => {
      tag.active ? activeTags.push(tag) : inactiveTags.push(tag);
    });

    setState(prevState => ({
      ...prevState,
      activeTags,
      inactiveTags,
      onlyAdminOption: !!tagOptionsData.tagsAdminOnly,
      requiredTravelTagOption: !!tagOptionsData.travelTagsRequired,
      loading: false
    }));
  };

  const isTagRepeated = (tagName, arr) => {
    const name = tagName.trim().toLowerCase();
    return arr.some(item => item.tagName.trim().toLowerCase() === name);
  };

  const loadTagConfigOptions = async () => {
    if (
      state.onlyAdminOption === null ||
      state.requiredTravelTagOption === null
    ) {
      const {
        data: tagsConfig,
        error
      } = await TagService.getClientTagOptions();

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);
        return;
      }

      setTagsConfig(tagsConfig);
    }
  };

  const setTagsConfig = tagsConfig => {
    setState(prevState => ({
      ...prevState,
      onlyAdminOption: !!tagsConfig.tagsAdminOnly,
      requiredTravelTagOption: !!tagsConfig.travelTagsRequired
    }));
  };

  useEffect(() => {
    handleUpdateTagsAdminOnly(!!state.onlyAdminOption);
  }, [state.onlyAdminOption]);

  useEffect(() => {
    handleUpdateTravelTagsRequired(state.requiredTravelTagOption);
  }, [state.requiredTravelTagOption]);

  return (
    <TagsManagementContext.Provider
      value={{
        ...state,
        handleTabChange,
        handleInputChange,
        handleCreateTag,
        handleEditTag,
        fetchClientTagsAndOptions,
        loadTagConfigOptions,
        handleArchiveTag,
        handleUnarchiveTag,
        openEditDialog,
        openAddTagDialog,
        closeEditDialog,
        closeAddTagDialog,
        toggleOnlyAdminOption,
        toggleRequiredTravelTagOption,
        setTagsConfig
      }}
    >
      {props.children}
    </TagsManagementContext.Provider>
  );
};

export { TagsManagementProvider, useTagsManagementContext };
