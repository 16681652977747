import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: css({
    color: theme.colors.gray[700],
  }),
  strong: css({
    fontWeight: 600,
  }),
};
