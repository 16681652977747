import React, { useState, useContext } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";

import { getUserFromLocalStorage } from "@helpers/user.helper";

import { ICreateSSOConfigDto, IEditSSOConfigDto } from "~/dtos/sso-config.dto";

import { CompleteSSOConfig } from "~/models/sso-config.model";

import * as ssoConfigApi from "@apis/sso-config.api";

import { useContextFactory } from "@hooks";

import { useApplication } from "../../../../contexts/application.context";
import { ISSOConfigData } from "./sso-config-dialog";

interface PageContextState {
  isSSOConfigDialogOpen: boolean;
  isDeleteSSOConfigDialogOpen: boolean;
  ssoConfig: CompleteSSOConfig | null;
  isGettingSSOConfig: boolean;
  isCreatingSSOConfig: boolean;
  isEditingSSOConfig: boolean;
  isDeletingSSOConfig: boolean;
}

const IntegrationsContext = React.createContext({} as PageContextState);
export const useIntegrationsContext = useContextFactory(
  "Integrations",
  IntegrationsContext,
);

const pageInitialState: PageContextState = {
  isSSOConfigDialogOpen: false,
  isDeleteSSOConfigDialogOpen: false,
  ssoConfig: null,
  isGettingSSOConfig: true,
  isCreatingSSOConfig: false,
  isEditingSSOConfig: false,
  isDeletingSSOConfig: false,
};

interface PageContextActions {
  toggleSSOConfigDialogVisibility: () => void;
  toggleDeleteSSOConfigDialogVisibility: () => void;
  getSSOConfig: () => void;
  createSSOConfig: (data: ISSOConfigData) => void;
  editSSOConfig: (data: ISSOConfigData) => void;
  deleteSSOConfig: () => void;
}

const IntegrationsActionsContext = React.createContext(
  {} as PageContextActions,
);
export const useIntegrationsActionsContext = useContextFactory(
  "IntegrationsActions",
  IntegrationsActionsContext,
);

export const IntegrationsProvider = (props: { children: JSX.Element }) => {
  const { showSnackMessage } = useApplication();

  const [pageState, setPageState] = useState(pageInitialState);

  const toggleSSOConfigDialogVisibility = () => {
    setPageState((prevState) => ({
      ...prevState,
      isSSOConfigDialogOpen: !prevState.isSSOConfigDialogOpen,
    }));
  };

  const toggleDeleteSSOConfigDialogVisibility = () => {
    setPageState((prevState) => ({
      ...prevState,
      isDeleteSSOConfigDialogOpen: !prevState.isDeleteSSOConfigDialogOpen,
    }));
  };

  const getSSOConfig = async () => {
    try {
      setPageState((prevState) => ({
        ...prevState,
        isGettingSSOConfig: true,
      }));

      const loggedUser = getUserFromLocalStorage();

      const ssoConfig = await ssoConfigApi.getSSOConfig(
        loggedUser?.clientToken,
      );

      setPageState((prevState) => ({ ...prevState, ssoConfig }));
    } catch (e) {
      showSnackMessage("Falha ao obter as configurações", ALERT_TYPES.ERROR);
    } finally {
      setPageState((prevState) => ({
        ...prevState,
        isGettingSSOConfig: false,
      }));
    }
  };

  const createSSOConfig = async (data: ISSOConfigData) => {
    try {
      setPageState((prevState) => ({
        ...prevState,
        isCreatingSSOConfig: true,
      }));

      const loggedUser = getUserFromLocalStorage();

      const ssoConfigCreationData: ICreateSSOConfigDto = {
        provider: data.provider.value,
        client_id: data.clientId,
        client_secret: data.clientSecret,
        sso_is_the_only_login_method: data.ssoIsTheOnlyLoginMethod,
      };

      if (data.tenantID) {
        ssoConfigCreationData.tenant_id = data.tenantID.trim();
      }

      if (data.discoveryEndpoint) {
        ssoConfigCreationData.discovery_endpoint = data.discoveryEndpoint.trim();
      }

      const ssoConfig = await ssoConfigApi.createSSOConfig(
        loggedUser?.clientToken,
        ssoConfigCreationData,
      );

      showSnackMessage(
        "Configurações criadas com sucesso",
        ALERT_TYPES.SUCCESS,
      );
      setPageState((prevState) => ({
        ...prevState,
        isSSOConfigDialogOpen: false,
        ssoConfig,
      }));
    } catch (e) {
      showSnackMessage(
        "Ocorreu um erro ao criar as configurações",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setPageState((prevState) => ({
        ...prevState,
        isCreatingSSOConfig: false,
      }));
    }
  };

  const editSSOConfig = async (data: ISSOConfigData) => {
    try {
      setPageState((prevState) => ({
        ...prevState,
        isEditingSSOConfig: true,
      }));

      const loggedUser = getUserFromLocalStorage();

      const ssoConfigEditionData: IEditSSOConfigDto = {
        provider: data.provider.value,
        client_id: data.clientId,
        client_secret: data.clientSecret,
        sso_is_the_only_login_method: data.ssoIsTheOnlyLoginMethod,
      };

      if (data.tenantID) {
        ssoConfigEditionData.tenant_id = data.tenantID.trim();
      }

      if (data.discoveryEndpoint) {
        ssoConfigEditionData.discovery_endpoint = data.discoveryEndpoint.trim();
      }

      const ssoConfig = await ssoConfigApi.editSSOConfig(
        loggedUser?.clientToken,
        ssoConfigEditionData,
      );

      showSnackMessage(
        "Configurações editadas com sucesso",
        ALERT_TYPES.SUCCESS,
      );
      setPageState((prevState) => ({
        ...prevState,
        isSSOConfigDialogOpen: false,
        ssoConfig,
      }));
    } catch (e) {
      showSnackMessage(
        "Ocorreu um erro ao editar as configurações",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setPageState((prevState) => ({
        ...prevState,
        isEditingSSOConfig: false,
      }));
    }
  };

  const deleteSSOConfig = async () => {
    try {
      setPageState((prevState) => ({
        ...prevState,
        isDeletingSSOConfig: true,
      }));

      const loggedUser = getUserFromLocalStorage();

      await ssoConfigApi.deleteSSOConfig(loggedUser?.clientToken);

      showSnackMessage(
        "Configurações excluídas com sucesso",
        ALERT_TYPES.SUCCESS,
      );

      setPageState((prevState) => ({
        ...prevState,
        isDeleteSSOConfigDialogOpen: false,
        ssoConfig: null,
      }));
    } catch (e) {
      showSnackMessage(
        "Ocorreu um erro ao excluir as configurações",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setPageState((prevState) => ({
        ...prevState,
        isDeletingSSOConfig: false,
      }));
    }
  };

  return (
    <IntegrationsContext.Provider value={pageState}>
      <IntegrationsActionsContext.Provider
        value={{
          toggleSSOConfigDialogVisibility,
          toggleDeleteSSOConfigDialogVisibility,
          getSSOConfig,
          createSSOConfig,
          editSSOConfig,
          deleteSSOConfig,
        }}
      >
        {props.children}
      </IntegrationsActionsContext.Provider>
    </IntegrationsContext.Provider>
  );
};
