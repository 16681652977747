import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { theme } from "smartrips-skin";

const styles = () => ({
  root: {
    width: "100%",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "1.4em",
    letterSpacing: "normal",
    fontFamily: "'Open sans', sans-serif",
    padding: ".5rem .5rem",
    minHeight: 35,
    ["@media (max-width: 767px)"]: {
      minWidth: 100,
      fontSize: 12
    }
  },
  textPrimary: {
    color: theme.colors.primary,
    "&:hover": {
      backgroundColor: "rgba(25, 78, 192, 0.08)"
    }
  },
  label: {
    lineHeight: "inherit",
    letterSpacing: "inherit"
  }
});

let StTextButton = props => {
  const { classes, children } = props;
  return (
    <Button classes={classes} {...props}>
      {children}
    </Button>
  );
};

StTextButton = withStyles(styles)(StTextButton);

export { StTextButton };
