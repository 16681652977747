import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import InputError from "@shared/inputs/InputError";

const styles = {
  radioGroup: css({
    flexDirection: ["column", "row"],
    justifyContent: ["initial", "space-between"],
    ml: [0, "medium"],
    ["label:last-child"]: {
      mr: 0
    }
  }),
  formControlLabel: css({
    display: "flex",
    flexDirection: ["row", "column"],
    alignItems: ["center", "auto"]
  }),
  radio: css({
    color: defaultTheme.subTextColor,
    backgroundColor: "transparent !important",
    ["&:hover"]: {
      color: "primary"
    },
    ["&.Mui-checked"]: {
      color: "primary"
    }
  }),
  icon: css({
    fontSize: [40, 50]
  })
};

const RATE_CONFIGURATIONS = [
  {
    label: "Encantado",
    message: "Superaram todas as minhas expectativas.",
    iconClass: "icon-very-happy",
    value: "5"
  },
  {
    label: "Satisfeito",
    message: "Resolveram meu problema e me trataram bem.",
    iconClass: "icon-happy",
    value: "4"
  },
  {
    label: "Cansado",
    message: "Resolveram meu problema, mas demoraram.",
    iconClass: "icon-anger",
    value: "3"
  },
  {
    label: "Chateado",
    message: "Não resolveram meu problema.",
    iconClass: "icon-upset",
    value: "2"
  },
  {
    label: "Mal atendido",
    message: "Não me trataram bem.",
    iconClass: "icon-sad",
    value: "1"
  }
];

const StyledText = ({ label, message, checked }) => (
  <Box
    textAlign={["left", "center"]}
    maxWidth={["auto", "200px"]}
    fontFamily="'Open sans', sans-serif"
  >
    <Flex flexDirection="column">
      <Text
        fontWeight="bold"
        fontSize={[1]}
        css={css({ textTransform: "uppercase" })}
        color={checked ? "primary" : "initial"}
      >
        {label}
      </Text>
      <Text
        fontSize={"13px"}
        color={checked ? "primary" : defaultTheme.subTextColor}
      >
        {message}
      </Text>
    </Flex>
  </Box>
);

const StyledRadio = ({ iconClass, ...other }) => (
  <Radio
    disableRipple={true}
    disableFocusRipple={true}
    disableTouchRipple={true}
    icon={<span className={iconClass} css={styles.icon} />}
    checkedIcon={<span className={iconClass} css={styles.icon} />}
    css={styles.radio}
    {...other}
  />
);

const SatisfactionInput = React.forwardRef((props, ref) => {
  return (
    <Box mb="large">
      <Box mb="large">
        <Text fontSize={[2]} fontWeight="bold">
          Como você se sente após seu atendimento?
        </Text>
      </Box>
      <RadioGroup {...props} ref={ref} css={styles.radioGroup}>
        {RATE_CONFIGURATIONS.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<StyledRadio iconClass={item.iconClass} />}
              label={
                <StyledText
                  label={item.label}
                  message={item.message}
                  checked={item.value === props.value}
                />
              }
              css={styles.formControlLabel}
            />
          );
        })}
      </RadioGroup>
      {props.error && (
        <InputError css={css({ mt: "medium" })}>
          {props.error.message}
        </InputError>
      )}
    </Box>
  );
});

export { SatisfactionInput };
