import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  baggage: () =>
    StyleSheet.create({
      root: {
        alignItems: "center",
        color: theme.colors.gray[300],
        display: "flex",
        flexDirection: "row",
        gap: 4,
      },
      text: {
        fontSize: 10,
        lineHeight: "1.25",
        whiteSpace: "nowrap",
      },
    }),
  info: () =>
    StyleSheet.create({
      duration: {
        fontSize: 11,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        whiteSpace: "nowrap",
      },
      stops: {
        color: theme.colors.gray[700],
        fontSize: 10,
      },
    }),
  item: () =>
    StyleSheet.create({
      bottom: {
        color: theme.colors.gray[300],
      },
      container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 16,
        paddingRight: 16,
      },
      diff: {
        color: theme.colors.gray[300],
        fontSize: 10,
        left: "calc(100% + 4px)",
        position: "absolute",
        top: 1,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
      },
      top: {
        color: theme.colors.gray[700],
        fontSize: 14,
        fontWeight: 700 - 100,
      },
    }),
  root: () =>
    StyleSheet.create({
      carrier: {
        height: 32,
        width: 32,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "16px 24px",
      },
    }),
  stop: () =>
    StyleSheet.create({
      code: {
        color: theme.colors.gray[300],
        fontSize: 10,
        fontWeight: 600 - 100,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        padding: 8,
        transform: "translateY(-8px)",
      },
    }),
  stops: () =>
    StyleSheet.create({
      dot: {
        backgroundColor: theme.colors.pink[500],
        borderRadius: 2,
        maxHeight: 4,
        maxWidth: 4,
        minHeight: 4,
        minWidth: 4,
        transform: "translateY(-1.5px)",
      },
      root: {
        alignItems: "flex-start",
        backgroundColor: theme.colors.gray[300],
        display: "flex",
        flexDirection: "row",
        height: 1,
        justifyContent: "space-between",
        maxWidth: 96,
        minWidth: 48,
        position: "relative",
        width: "100%",
      },
    }),
};
