import moment from "moment";

import { FlightSearch, RecentFlightSearch } from "../../NewTrip.types";

export function buildFlightFields(
  recentFlightSearch: RecentFlightSearch
): FlightSearch {
  return {
    startDate: moment(recentFlightSearch.outboundFlightDate),
    endDate: moment(recentFlightSearch.inboundFlightDate),
    type: recentFlightSearch.oneway ? "oneway" : "roundtrip",
    origin: {
      CityId: recentFlightSearch.origin.originCityId,
      CountryId: `${recentFlightSearch.origin.originName.split(" ").pop()}`,
      CountryName: recentFlightSearch.origin.originCountry,
      PlaceId: `${recentFlightSearch.origin.originLocale}-${
        recentFlightSearch.origin.originCityId.split("-")[1]
      }`,
      PlaceName: recentFlightSearch.origin.originCity,
      RegionId: "",
      label: recentFlightSearch.origin.originName
    },
    destination: {
      CityId: recentFlightSearch.destination.destinationCityId,
      CountryId: `${recentFlightSearch.destination.destinationName
        .split(" ")
        .pop()}`,
      CountryName: recentFlightSearch.destination.destinationCountry,
      PlaceId: `${recentFlightSearch.destination.destinationLocale}-${
        recentFlightSearch.destination.destinationCityId.split("-")[1]
      }`,
      PlaceName: recentFlightSearch.destination.destinationCity,
      RegionId: "",
      label: recentFlightSearch.destination.destinationName
    },
    cabinClass: recentFlightSearch.cabinClass,
    traveler: {
      ...recentFlightSearch.user,
      label: recentFlightSearch.user.fullName
    }
  };
}
