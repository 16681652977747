import React from "react";
import { Box, Flex, Text } from "@toolkit";
import { css } from "@styled-system/css";
import PropTypes from "prop-types";

const styles = {
  root: css({
    lineHeight: "1.5",
    ["div.selected"]: {
      fontWeight: "bold",
      borderBottomColor: "primary",
      pb: ["5px", "small"]
    }
  }),
  tab: css({
    cursor: "pointer",
    minWidth: 115,
    borderBottom: "2px solid",
    borderBottomColor: "transparent",
    borderRadius: 0,
    [":hover:not(.selected)"]: {
      color: "primary",
      backgroundColor: "rgba(0, 0, 0, 0.03)"
    },
    [":first-of-type"]: {
      borderRadius: "4px 0 0 2px"
    },
    ["last-child"]: {
      borderRadius: "0 4px 2px 0"
    }
  })
};

const TravelTabs = ({ selected, onTravelClick, onExpensesClick }) => {
  return (
    <Box border="1px solid" borderColor="border" css={styles.root}>
      <Flex alignItems="center">
        <Box
          py={["xsmall", "small"]}
          px={24}
          textAlign="center"
          css={styles.tab}
          className={selected === "travel" && "selected"}
          onClick={onTravelClick}
        >
          <Text fontSize={1}>Viagem</Text>
        </Box>
        <Box
          py={["xsmall", "small"]}
          px={24}
          textAlign="center"
          css={styles.tab}
          className={selected === "expenses" && "selected"}
          onClick={onExpensesClick}
        >
          <Text fontSize={1}>Despesas</Text>
        </Box>
      </Flex>
    </Box>
  );
};

TravelTabs.propTypes = {
  selected: PropTypes.oneOf(["travel", "expenses"]),
  onTravelClick: PropTypes.func,
  onExpensesClick: PropTypes.func
};

TravelTabs.defaultProps = {
  onTravelClick: () => null,
  onExpensesClick: () => null
};

export { TravelTabs };
