import { BusAutosuggestItem } from "~/apps/corporate/models/bus.model";
import moment, { Moment } from "moment";
import * as yup from "yup";

export const busFormSchema = yup.object().shape({
  destination: yup
    .mixed<BusAutosuggestItem>()
    .required("Preencha seu local de destino")
    .nullable(true),
  departureDate: yup
    .mixed<Moment>()
    .required("Preencha a data de ida")
    .test("", "Data inicial inferior a hoje", (departureDate) => {
      if (!departureDate) {
        return false;
      }

      const today = moment().startOf("day");

      if (departureDate.isBefore(today)) {
        return false;
      }

      return true;
    })
    .nullable(true),
  origin: yup
    .mixed<BusAutosuggestItem>()
    .required("Preencha seu local de origem")
    .nullable(true),
  returnDate: yup
    .mixed<Moment>()
    .test("", "Preencha a data de volta", (returnDate, context) => {
      if (context.parent.type === "roundtrip" && !returnDate) {
        return false;
      }

      return true;
    })
    .nullable(true),
  type: yup.mixed<"oneway" | "roundtrip">().required(),
});

export type BusFormSchema = yup.InferType<typeof busFormSchema>;
