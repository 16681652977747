import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  amenities: string[];
};

export const HotelResultAmenities: React.FC<Props> = ({ amenities }) => {
  return (
    <div css={styles.root}>
      <h3 css={styles.title}>Amenidades</h3>
      <ul>
        {amenities.map((amenity) => (
          <li css={styles.amenity} key={amenity}>
            <Icon size={16} use="check" />
            <span>{amenity}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
