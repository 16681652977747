import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { styles } from "./styles";

type Props = {
  offerToken: string;
};

export const ItineraryJourneyHeaderCopyTokenButton: React.FC<Props> = ({
  offerToken,
}) => {
  const [copiedServiceToken, setCopiedServiceToken] = useState(false);

  const handleCopyToken = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      await navigator.clipboard.writeText(offerToken);

      if (copiedServiceToken) {
        return;
      }

      setCopiedServiceToken(true);

      setTimeout(() => {
        setCopiedServiceToken(false);
      }, 2000);
    },
    [copiedServiceToken, offerToken],
  );

  return (
    <Tooltip arrow content="Copiar" position="top">
      <button
        css={styles.button}
        onClick={async (e) => {
          await handleCopyToken(e);
        }}
      >
        <Icon
          size={14}
          use={copiedServiceToken ? "check" : "clipboard-document"}
        />
      </button>
    </Tooltip>
  );
};
