import React from "react";

import { RadioGroup as MuiRadioGroup } from "@material-ui/core";

import { RadioGroupItem } from "./radio-group-item/radio-group-item";
import { styles } from "./styles";

export const RadioGroup: React.FC<
  React.ComponentPropsWithoutRef<typeof MuiRadioGroup>
> & {
  Item: typeof RadioGroupItem;
} = ({ children, id, name, onChange, value, ...props }) => {
  return (
    <MuiRadioGroup
      aria-label={name}
      classes={{ ...styles.classes }}
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      {...props}
    >
      {children}
    </MuiRadioGroup>
  );
};

RadioGroup.Item = RadioGroupItem;
