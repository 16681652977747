import React from "react";

import { RouteComponentProps } from "@reach/router";

import { HotelInfo } from "@components/analytics/hotel-info/HotelInfo";

type Props = RouteComponentProps;

export const AnalyticsHotelInfo: React.FC<Props> = () => {
  return <HotelInfo />;
};
