import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    ".MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      maxWidth: "768px",
      width: "100%",
    },
  }),
};
