import { css } from "@styled-system/css";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    margin: "auto",
    justifyContent: "center",
  }),
};
