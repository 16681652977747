import * as supportEvaluationApi from "@apis/supportEvaluation.api";
import { ERROR } from "@constants";
import errorTypes from "@constants/errors";

export async function getEvaluationStatus(supportEvaluationToken) {
  const result = {};

  try {
    result.data = await supportEvaluationApi.getEvaluationStatus(
      supportEvaluationToken
    );
  } catch (error) {
    if (error.response) {
      const { type } = error.response;

      if (type === errorTypes.ERROR_NOT_ALLOWED) {
        result.error = ERROR.USER_NOT_ALLOWED_TO_EVALUATE;
      } else {
        result.error = ERROR.UNEXPECTED_GET_EVALUATION_STATUS_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EVALUATION_STATUS_ERROR;
    }
  }

  return result;
}

export async function submitSupportEvaluation(
  supportEvaluationToken,
  formData
) {
  const result = {};

  try {
    const requestDto = buildSubmitionData(formData);
    result.data = await supportEvaluationApi.submitSupportEvaluation(
      supportEvaluationToken,
      requestDto
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SUBMIT_SUPPORT_EVALUATION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SUBMIT_SUPPORT_EVALUATION_ERROR;
    }
  }

  return result;
}

function buildSubmitionData(formData) {
  return {
    satisfaction_rate: parseInt(formData.satisfactionRate, 10),
    solved: formData.solved,
    user_comment: formData.userComment ? formData.userComment : undefined
  };
}
