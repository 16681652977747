import React, { useContext } from "react";

import { AdvancedExpense } from "@models/advanced-expense.model";
import { Expense } from "@models/expense.model";

import DeletionDialog from "../../../shared/dialogs/DeletionDialog";
import { ReportContext } from "../report.context";

interface IsAdvancedExpenseParams {
  expenseToDelete: Expense | AdvancedExpense | null;
  type: "expense" | "expense-advance" | null;
}

// type differentiating
function isAdvancedExpense(
  expenseToDelete: IsAdvancedExpenseParams["expenseToDelete"],
  type: IsAdvancedExpenseParams["type"],
): expenseToDelete is AdvancedExpense {
  return type === "expense-advance";
}

const ReportDeletionDialog = () => {
  const {
    selectedExpenseToDelete,
    expenseToDeleteType,
    handleCloseDeletionDialog,
    proccessExpenseRemoval,
    processAdvancedExpenseRemoval,
    proccessingDelete,
  } = useContext(ReportContext);

  return (
    <DeletionDialog
      open={!!selectedExpenseToDelete}
      title={
        expenseToDeleteType === "expense-advance"
          ? "Remoção de adiantamento"
          : "Remoção de despesa"
      }
      handleClose={handleCloseDeletionDialog}
      handleDeletion={
        expenseToDeleteType === "expense-advance"
          ? processAdvancedExpenseRemoval
          : proccessExpenseRemoval
      }
      isDeleting={proccessingDelete}
      message={
        <span>
          Deseja realmente remover{" "}
          {expenseToDeleteType === "expense-advance"
            ? "o adiantamento "
            : "a despesa "}
          <span style={{ fontWeight: "bold" }}>
            {selectedExpenseToDelete
              ? isAdvancedExpense(selectedExpenseToDelete, expenseToDeleteType)
                ? selectedExpenseToDelete.name
                : selectedExpenseToDelete.description
              : null}
          </span>{" "}
          ?
        </span>
      }
    />
  );
};

export { ReportDeletionDialog };
