import React, { useEffect } from "react";
import { components } from "react-select";

import { useClientBillingProfiles } from "~/apps/corporate/hooks/use-client-billing-profiles";
import { BillingProfile } from "~/apps/corporate/models/billing-profile.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { formatServiceError } from "~/apps/shared/utils/errors";

import { Select } from "../../../shared/components/select/select";
import { Option } from "../../../shared/types";
import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { styles } from "./styles";

const defaultBuildOptions = (options: BillingProfile[]): Option<string>[] => {
  return options.map((option) => ({
    label: `${option.cnpj} - ${option.legalName}`,
    value: option.billingProfileToken,
  }));
};

type Props = React.ComponentProps<typeof Select> & {
  additionalOptions?: Option<string>[];
  buildOptions?: (options: BillingProfile[]) => Option<string>[];
};

export const BillingProfileSelect: React.FC<Props> = ({
  additionalOptions = [],
  buildOptions = defaultBuildOptions,
  defaultValue,
  ...props
}) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const {
    billingProfiles,
    errorBillingProfiles,
    isLoadingBillingProfiles,
  } = useClientBillingProfiles(
    user ? user.getClientToken() : undefined,
    !!user,
  );

  useEffect(() => {
    if (!errorBillingProfiles) {
      return;
    }

    const error = formatServiceError({
      error: errorBillingProfiles,
      fallback: {
        description: "Erro ao carregar os perfis de cobrança",
        title: "Erro inesperado",
      },
    });

    showSnackMessage(error.description, ALERT_TYPES.ERROR);
  }, [errorBillingProfiles]);

  if (errorBillingProfiles) {
    return (
      <Select
        placeholder="Houve um erro ao carregar os perfis de cobrança..."
        isDisabled
      />
    );
  }

  if (isLoadingBillingProfiles) {
    return <Select placeholder="Carregando perfis de cobrança..." isDisabled />;
  }

  if (!billingProfiles) {
    return null;
  }

  const billingProfilesOptions = [
    ...additionalOptions,
    ...buildOptions(billingProfiles),
  ];

  return (
    <Select
      aria-label="Selecione o perfil de cobrança"
      components={{
        ...components,
        // eslint-disable-next-line react/display-name
        Option: ({ ...props }) => {
          const { label } = props;

          return (
            <components.Option {...props}>
              <div css={styles.option.root}>
                <Icon size={18} use="building" />
                <span>{label}</span>
              </div>
            </components.Option>
          );
        },
      }}
      defaultValue={
        defaultValue
          ? billingProfilesOptions.find(
              (option) => option.value === defaultValue,
            )
          : undefined
      }
      isSearchable
      options={billingProfilesOptions}
      placeholder="Perfil de cobrança"
      {...props}
    />
  );
};
