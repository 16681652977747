export const formatFlightDuration = (rawDuration?: string) => {
  if (!rawDuration) {
    return "";
  }

  const [rawMinutes, rawHours] = rawDuration.split(":").reverse();

  const hours = rawHours && rawMinutes ? rawHours : "0";
  const minutes = rawMinutes ? rawMinutes : rawHours;

  const formattedHours =
    Number(hours) > 0 ? `${hours.replace(/^0+/, "")}h` : "";

  const formattedMinutes =
    Number(minutes) > 0 ? `${minutes.replace(/^0+/, "")}m` : "";

  if (!formattedHours && !formattedMinutes) {
    return "";
  }

  return `${formattedHours}${
    formattedHours && formattedMinutes ? " " : ""
  }${formattedMinutes}`;
};
