import React from "react";

const OFFER_TAGS = {
  negotiated: {
    color: "#FA831B",
    label: "Negociada",
  },
  pendingApproval: {
    color: "#C354FF",
    label: "Pendente de aprovação",
  },
  preReserved: {
    color: "#194EC0",
    label: "Pré-reservado",
  },
  preReserveExpired: {
    color: "#FA831B",
    label: "Pré-reserva expirada",
  },
  preReserveFailed: {
    color: "#FA831B",
    label: "Falha na pré-reserva",
  },
  preReserving: {
    color: "#FA831B",
    label: "Pré-reservando",
  },
} as const;

import { styles } from "./styles";

type Props = {
  tags: {
    [key in keyof typeof OFFER_TAGS]: boolean;
  };
};

export const CardOfferTags: React.FC<Props> = ({ tags }) => {
  return (
    <div css={styles.root}>
      {Object.entries(tags).map(([tag, isPresent]) => {
        if (!isPresent || !(tag in OFFER_TAGS)) {
          return null;
        }

        const offerTag = OFFER_TAGS[tag as keyof typeof OFFER_TAGS];

        return (
          <span
            css={styles.tag({
              color: offerTag.color,
            })}
            key={tag}
          >
            {offerTag.label}
          </span>
        );
      })}
    </div>
  );
};
