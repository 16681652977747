import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../assets/styles/theme";
import clsx from "classnames";
import { FINANCIAL_TABS } from "../../constants";
import { Divider } from "@shared/Divider";

const styles = {
  root: css({
    marginBottom: "2rem"
  }),
  row: css({
    display: "flex",
    alignItems: "center"
  }),
  activeTab: css({
    color: defaultTheme.textColor,
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: `2px solid ${defaultTheme.primaryColor}`
  }),
  inactiveTab: css({
    color: defaultTheme.subTextColor,
    transition: "all 0.3s ease",
    textDecoration: "none",
    cursor: "pointer",
    ["&:hover"]: {
      color: defaultTheme.primaryColor
    }
  }),
  tab: css({
    fontSize: 14,
    padding: "1rem 0",
    marginRight: "1.75rem",
    transition: "color 0.2s ease 0s"
  })
};

interface TabProps {
  label: string;
  value: number;
  activeTab: number;
  handleTabChange: () => void;
}

const Tab = ({ label, value, activeTab, handleTabChange }: TabProps) => {
  const isActive = activeTab === value;

  return (
    <div
      className={clsx(
        styles.tab,
        isActive ? styles.activeTab : styles.inactiveTab
      )}
      onClick={handleTabChange}
    >
      <span>{label}</span>
    </div>
  );
};

interface Props {
  activeTab: number;
  containerClasses?: string;
  handleTabChange: (tab: number) => () => void;
}

const FinancialTabs = ({
  activeTab,
  containerClasses,
  handleTabChange
}: Props) => (
  <div className={clsx(styles.root, containerClasses)}>
    <div className={styles.row}>
      <Tab
        label="Voos"
        value={FINANCIAL_TABS.FLIGHTS}
        activeTab={activeTab}
        handleTabChange={handleTabChange(FINANCIAL_TABS.FLIGHTS)}
      />
      <Tab
        label="Hotéis"
        value={FINANCIAL_TABS.HOTELS}
        activeTab={activeTab}
        handleTabChange={handleTabChange(FINANCIAL_TABS.HOTELS)}
      />
      <Tab
        label="Aluguel de carros"
        value={FINANCIAL_TABS.CARS}
        activeTab={activeTab}
        handleTabChange={handleTabChange(FINANCIAL_TABS.CARS)}
      />
      <Tab
        label="Ônibus"
        value={FINANCIAL_TABS.BUSES}
        activeTab={activeTab}
        handleTabChange={handleTabChange(FINANCIAL_TABS.BUSES)}
      />
      <Tab
        label="Smartrips"
        value={FINANCIAL_TABS.SMARTRIPS}
        activeTab={activeTab}
        handleTabChange={handleTabChange(FINANCIAL_TABS.SMARTRIPS)}
      />
    </div>
    <Divider style={{ marginBottom: ".5rem" }} />
  </div>
);

FinancialTabs.defaultProps = {
  containerClasses: ""
} as Partial<Props>;

export { FinancialTabs };
