import azulFareDetailsJson from "~/assets/airlines/azul/azul-fare-details.json";
import golFareDetailsJson from "~/assets/airlines/gol/gol-fare-details.json";
import latamFareDetailsJson from "~/assets/airlines/latam/latam-fare-details.json";

import { getAllCombinations } from "../helpers";

export function buildFlightData(flightSearchData, travelToken = null) {
  const flight = {};

  if (travelToken) {
    flight.travel_token = travelToken;
  }

  flight.origin_locale = flightSearchData.origin.PlaceId.split("-")[0];
  flight.origin_name = flightSearchData.origin.label;
  flight.origin_city_id = flightSearchData.origin.CityId;
  flight.origin_country = flightSearchData.origin.CountryName;
  //flight.origin_continent = flightSearchData.origin.continent;

  flight.destination_locale = flightSearchData.destination.PlaceId.split(
    "-"
  )[0];
  flight.destination_name = flightSearchData.destination.label;
  flight.destination_city_id = flightSearchData.destination.CityId;
  flight.destination_country = flightSearchData.destination.CountryName;
  //flight.destination_continent = flightSearchData.destination.continent;

  flight.oneway = flightSearchData.type === "oneway";
  flight.cabin_class = flightSearchData.cabinClass;

  flight.outbound_flight_start = "00:01";
  flight.outbound_flight_end = "23:59";
  flight.outbound_flight_date = flightSearchData.startDate.format("YYYY-MM-DD");
  flight.outbound_flight_type = "departure";

  // type === 0 significa ida e volta
  if (flightSearchData.type === "roundtrip") {
    flight.inbound_flight_start = "00:01";
    flight.inbound_flight_end = "23:59";
    flight.inbound_flight_date = flightSearchData.endDate.format("YYYY-MM-DD");
    flight.inbound_flight_type = "departure";
  }

  return flight;
}

export function getMaxCreditCombination(credits, offerPrice) {
  let result;
  const creditsSum = credits.reduce((acc, current) => {
    acc += current.value;
    return acc;
  }, 0);

  if (creditsSum <= offerPrice) {
    result = credits;
  } else if (credits.length === 1) {
    result = [];
  } else {
    const allCombinations = getAllCombinations(credits);
    const maxCombinationIndexResult = findMaxValueCombinationLowerThanPrice(
      allCombinations,
      offerPrice
    );
    result =
      maxCombinationIndexResult.index === -1
        ? []
        : allCombinations[maxCombinationIndexResult.index];
  }

  return result;
}

function findMaxValueCombinationLowerThanPrice(allCombinations, price) {
  function getCombinationSum(combination) {
    const sum = combination.reduce((acc, current) => {
      acc += current.value;
      return acc;
    }, 0);

    return sum;
  }

  const result = { index: -1, sum: 0 };
  allCombinations.forEach((combination, index) => {
    let sum = getCombinationSum(combination);
    if (sum <= price && sum > result.sum) {
      result.index = index;
      result.sum = sum;
    }
  });

  return result;
}

const mapFareDetailsByFamilyName = fares => {
  return fares.reduce((acc, curr) => {
    acc[curr.name.trim().toLocaleLowerCase()] = curr;
    return acc;
  }, {});
};

export const getAllFareDetailsMappedByCarrierCode = flightIsInternational => {
  const latamFareDetails = latamFareDetailsJson;
  const golFareDetails = golFareDetailsJson;
  const azulFareDetails = azulFareDetailsJson;

  const keyToAccessInJson = flightIsInternational
    ? "internationalFares"
    : "nationalFares";

  // ! implementação temporária até confirmarmos as tarifas para voos internacionais.
  if (flightIsInternational) {
    return {};
  }

  const fareDetailsMap = {
    [latamFareDetails.code]: mapFareDetailsByFamilyName(
      latamFareDetails[keyToAccessInJson]
    ),
    [golFareDetails.code]: mapFareDetailsByFamilyName(
      golFareDetails[keyToAccessInJson]
    ),
    [azulFareDetails.code]: mapFareDetailsByFamilyName(
      azulFareDetails[keyToAccessInJson]
    )
  };

  return fareDetailsMap;
};

export const AIRLINE_WEB_SITES = {
  G3: {
    name: "Gol Linhas Aéreas",
    link: "https://www.voegol.com.br/tarifas"
  },
  AD: {
    name: "Azul",
    link: "https://www.voeazul.com.br/br/pt/sobreazul/para-a-sua-viagem"
  },
  LA: {
    name: "LATAM Airlines",
    link:
      "https://www.latamairlines.com/us/pt/central-ajuda/perguntas/compras/assistencia/tarifas-passagem-domestico"
  }
};

export const getAirlineWebSite = airlineCode => {
  if (AIRLINE_WEB_SITES?.[airlineCode]) {
    return AIRLINE_WEB_SITES[airlineCode];
  } else {
    return null;
  }
};
