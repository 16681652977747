import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import { navigate, RouteComponentProps } from "@reach/router";
import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";
import { Flex, Text } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { TRANSLATED_SSO_CONFIG_PROVIDER } from "~/constants";
import { SSOConfigProvider } from "~/constants/enums";
import { OptionType } from "~/constants/shared";

import { Checkbox } from "@shared/checkbox/Checkbox";

import InputError from "~/components/shared/inputs/InputError";
import PageTitle from "~/components/shared/PageTitle";
import SpinnerPortal from "~/components/shared/Spinner";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { OutlinedSelect } from "@components/shared/outlined-select";

import {
  IntegrationsProvider,
  useIntegrationsActionsContext,
  useIntegrationsContext,
} from "../Integrations.context";
import { CONFIG_SSO_VALIDATION } from "../validations";

const PROVIDER_OPTIONS = Object.keys(TRANSLATED_SSO_CONFIG_PROVIDER).map(
  (key) => ({
    label: TRANSLATED_SSO_CONFIG_PROVIDER[key as SSOConfigProvider],
    value: key,
  }),
);

const styles = {
  input: css({
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    width: "100%",
    height: "3rem",
    padding: "0.75rem 1rem",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.5rem",
  }),
};

export interface ISSOConfigData {
  provider: OptionType<SSOConfigProvider>;
  tenantID?: string;
  discoveryEndpoint?: string;
  clientId: string;
  clientSecret: string;
  ssoIsTheOnlyLoginMethod: boolean;
}

const SSO: React.FC<RouteComponentProps> = () => {
  const {
    ssoConfig,
    isGettingSSOConfig,
    isCreatingSSOConfig,
    isEditingSSOConfig,
  } = useIntegrationsContext();
  const {
    getSSOConfig,
    createSSOConfig,
    editSSOConfig,
  } = useIntegrationsActionsContext();

  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
  } = useForm<ISSOConfigData>({
    resolver: yupResolver(CONFIG_SSO_VALIDATION),
  });

  const backToIntegrations = () => navigate("/configurations/integrations");

  const isLoading = isCreatingSSOConfig || isEditingSSOConfig;
  const provider = watch("provider") || { value: ssoConfig?.provider };

  useEffect(() => {
    if (provider?.value !== ssoConfig?.provider) {
      setValue("discoveryEndpoint", "");
    }
  }, [provider]);

  useEffect(() => {
    getSSOConfig();
  }, []);

  return (
    <Box>
      <Flex style={{ alignItems: "center", gap: "1rem" }}>
        <Button shape="icon" onClick={backToIntegrations}>
          <Icon use="arrow-left" style={{ width: 18, height: 18 }} />
        </Button>
        <h6 style={{ fontSize: "1.25rem" }}>Single sign-on (SSO)</h6>
      </Flex>
      <PageTitle title="Single sign-on (SSO)" />
      <SpinnerPortal visible={isGettingSSOConfig} />
      {isGettingSSOConfig ? null : (
        <Flex
          flexDirection="column"
          style={{ marginTop: "2rem", gap: "1.5rem" }}
        >
          <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text fontSize={2} fontWeight="bold">
              Provedor
            </Text>
            <Controller
              as={
                <OutlinedSelect
                  options={PROVIDER_OPTIONS}
                  error={!!errors.provider}
                />
              }
              id="provider"
              name="provider"
              control={control}
              placeholder="Selecione o provedor"
              error={!!errors.provider}
              defaultValue={
                ssoConfig
                  ? {
                      label: TRANSLATED_SSO_CONFIG_PROVIDER[ssoConfig.provider],
                      value: ssoConfig.provider,
                    }
                  : null
              }
            />
            {errors?.provider ? (
              <InputError style={{ marginTop: 0 }}>
                {errors?.provider?.message}
              </InputError>
            ) : null}
          </Box>
          {provider?.value === SSOConfigProvider.MICROSOFT_AZURE ? (
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "0.5rem" }}
            >
              <Text fontSize={2} fontWeight="bold">
                ID do Locatário
              </Text>
              <Controller
                as={<Input css={styles.input} />}
                id="tenantID"
                name="tenantID"
                control={control}
                placeholder="Preencha o ID do locatário"
                error={!!errors.tenantID}
                defaultValue={
                  ssoConfig ? ssoConfig?.domain.slice(34).split("/")[0] : ""
                }
              />
            </Box>
          ) : null}
          {provider?.value &&
          provider?.value !== SSOConfigProvider.MICROSOFT_AZURE &&
          provider?.value !== SSOConfigProvider.GOOGLE ? (
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "0.5rem" }}
            >
              <Text fontSize={2} fontWeight="bold">
                Discovery Endpoint
              </Text>
              <Controller
                as={<Input css={styles.input} />}
                id="discoveryEndpoint"
                name="discoveryEndpoint"
                control={control}
                placeholder="Preencha o discovery endpoint"
                error={!!errors.discoveryEndpoint}
                defaultValue={ssoConfig?.domain}
              />
            </Box>
          ) : null}
          <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text fontSize={2} fontWeight="bold">
              ID do Cliente
            </Text>
            <Controller
              as={<Input css={styles.input} />}
              id="clientId"
              name="clientId"
              control={control}
              placeholder="Preencha o ID do cliente"
              error={!!errors.clientId}
              errorMessage={errors.clientId?.message}
              defaultValue={ssoConfig?.client_id}
            />
          </Box>
          <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text fontSize={2} fontWeight="bold">
              Segredo do Cliente
            </Text>
            <Controller
              as={<Input css={styles.input} />}
              id="clientSecret"
              name="clientSecret"
              control={control}
              placeholder="Preencha o segredo do cliente"
              error={!!errors.clientSecret}
              errorMessage={errors.clientSecret?.message}
              defaultValue={ssoConfig?.client_secret}
            />
          </Box>
          <Controller
            name="ssoIsTheOnlyLoginMethod"
            defaultValue={ssoConfig?.sso_is_the_only_login_method || false}
            control={control}
            render={(props) => (
              <label
                htmlFor="ssoIsTheOnlyLoginMethod"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: "-0.5rem",
                }}
              >
                <Checkbox
                  id="ssoIsTheOnlyLoginMethod"
                  name="ssoIsTheOnlyLoginMethod"
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={props.value}
                  color={theme.colors.gray[500]}
                />
                Tornar este SSO o único método de autenticação permitido para a
                empresa
              </label>
            )}
          />
          <Flex style={{ gap: "2rem" }}>
            <ContainedButton
              disabled={isLoading}
              onClick={handleSubmit(
                ssoConfig ? editSSOConfig : createSSOConfig,
              )}
              style={{ width: 180, justifyContent: "center" }}
            >
              Salvar
            </ContainedButton>
            <OutlinedButton
              disabled={isLoading}
              onClick={backToIntegrations}
              style={{ width: 180, justifyContent: "center" }}
            >
              Cancelar
            </OutlinedButton>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

type Props = RouteComponentProps;

const Container: React.FC<Props> = () => (
  <IntegrationsProvider>
    <SSO />
  </IntegrationsProvider>
);

export { Container as SSO };
