import React, { memo } from "react";

import uuid from "uuid";

import { RealTimeTravel } from "~/models/analytics.model";

import { CustomMap, MapTheme } from "~/components/shared/custom-map";

import { MapMarker } from "./marker";

type Props = {
  handleSelect: (cityName: string) => void;
  travelsByCity: { [key: string]: RealTimeTravel[] };
};

const Component: React.FC<Props> = ({ handleSelect, travelsByCity }) => {
  return (
    <CustomMap
      height="37.5rem"
      markers={Object.keys(travelsByCity).map((cityName: string) => {
        return travelsByCity[cityName].length ? (
          <MapMarker
            cityName={cityName}
            key={uuid()}
            lat={parseFloat(travelsByCity[cityName][0].latitude)}
            lng={parseFloat(travelsByCity[cityName][0].longitude)}
            onClick={handleSelect}
            travelsQuantity={travelsByCity[cityName].length}
          />
        ) : null;
      })}
      theme={MapTheme.GRAY}
    />
  );
};

export const RealTimeMap = memo(Component);
