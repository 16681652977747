import React from "react";

import { SidebarHelpButton } from "../../../help-button/help-button";
import { HotelsMap, HotelsMapSkeleton } from "../../hotels-map/hotels-map";
import { useHotels } from "../../hotels.context";
import { ContentFilters } from "../content-filters/content-filters";
import { SidebarSummary } from "./sidebar-summary/sidebar-summary";
import { styles } from "./styles";

export const ContentSidebar: React.FC = () => {
  const { isMapVisible, isLoading, searchInfo } = useHotels();

  return (
    <div css={styles.root}>
      <SidebarSummary />
      {isLoading ? (
        <HotelsMapSkeleton />
      ) : !isMapVisible && searchInfo ? (
        <HotelsMap />
      ) : null}
      <ContentFilters />
      <SidebarHelpButton />
    </div>
  );
};
