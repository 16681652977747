import React, { useRef } from "react";
import { useQuery } from "react-query";

import { navigate } from "@reach/router";
import { css } from "@styled-system/css";

import { Text } from "@toolkit";

import { APPROVAL_TYPES } from "~/constants";

import { useApplicationContext } from "~/Context";

import AsyncData from "~/components/shared/async-data";
import { Form, useForm } from "~/components/shared/form";
import OrderableList from "~/components/shared/orderable-list";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { Divider } from "@components/shared/Divider";
import FullpageLoader from "@components/shared/fullpage-loader";

import approvalPriorityRankingSchema from "./ApprovalPriorityRanking.schema";
import * as approvalRankingService from "./ApprovalPriorityRanking.service";
import { IApprovalPriorityRanking } from "./ApprovalPriorityRanking.types";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  form: css({
    marginTop: "1rem",
  }),
  loader: css({
    height: "80vh",
  }),
  label: css({
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: "1.5rem",
  }),
  footer: css({
    display: "flex",
    alignSelf: "flex-end",
    gap: "1rem",
  }),
};

const DEFAULT_OPTIONS = [
  {
    label: "Projeto",
    value: APPROVAL_TYPES.PROJECT,
  },
  {
    label: "Usuário",
    value: APPROVAL_TYPES.USER,
  },
  {
    label: "Centro de custo",
    value: APPROVAL_TYPES.COST_CENTER,
  },
  {
    label: "Área",
    value: APPROVAL_TYPES.AREA,
  },
];

interface IApprovalPriorityRankingProps {
  path?: string;
}

export function ApprovalPriorityRanking(_props: IApprovalPriorityRankingProps) {
  const onClose = useRef(() =>
    navigate("/configurations/trips/approval-processes"),
  );

  const queryInfo = useQuery(
    ["approval_ranking"],
    approvalRankingService.getApprovalRankingPriority,
    {
      cacheTime: 0,
    },
  );

  return (
    <AsyncData {...queryInfo}>
      <AsyncData.Loading>
        <FullpageLoader css={styles.loader} />
      </AsyncData.Loading>
      <AsyncData.Success>
        {(data) => (
          <ApprovalPriorityRankingSuccess
            data={data}
            onClose={onClose.current}
          />
        )}
      </AsyncData.Success>
    </AsyncData>
  );
}

type ApprovalPriorityRankingSuccessProps = {
  data: IApprovalPriorityRanking;
  onClose: () => void;
};

function getDefaultValues(data: IApprovalPriorityRanking) {
  if (!Array.isArray(data.priorityRanking) || !data.priorityRanking?.length) {
    return {
      clientConfigToken: data.clientConfigToken,
      priorityRanking: DEFAULT_OPTIONS,
    };
  }

  return {
    clientConfigToken: data.clientConfigToken,
    priorityRanking: data.priorityRanking
      .filter((option) => option !== APPROVAL_TYPES.COMPANY)
      .map((option) => ({
        value: option,
        label: DEFAULT_OPTIONS.find((x) => x.value === option)?.label,
      })),
  };
}

type ApprovalPriorityRankingFormType = {
  priorityRanking: Array<{ label: string; value: string }>;
};

function ApprovalPriorityRankingSuccess(
  props: ApprovalPriorityRankingSuccessProps,
) {
  const { data, onClose } = props;

  const { showSnackMessage } = useApplicationContext();

  const context = useForm<ApprovalPriorityRankingFormType>({
    defaultValues: getDefaultValues(data),
    schema: approvalPriorityRankingSchema,
    onSubmit: async function handleOnSubmit(values) {
      const {
        error,
      } = await approvalRankingService.updateApprovalRankingPriority(
        data.clientConfigToken,
        values.priorityRanking.map((option) => option.value),
      );

      if (error) {
        showSnackMessage(`${error.title}: ${error.description}`, "error");

        return;
      }

      showSnackMessage("Salvo com sucesso!", "success");
    },
  });

  return (
    <div css={styles.root}>
      <main>
        <Text as="h5" fontSize="1.125rem" fontWeight={600} marginBottom="1rem">
          Hierarquia de fluxos de aprovação
        </Text>
        <Divider />
        <Form context={context} css={styles.form}>
          <Text
            as="p"
            fontSize="1rem"
            lineHeight="1.5rem"
            marginBottom="1.5rem"
          >
            A ordem de prioridade define o peso que cada tipo de processo de
            aprovação tem ao se enviar uma solicitação de aprovação.
          </Text>

          <Text
            as="p"
            fontSize="1rem"
            lineHeight="1.5rem"
            marginBottom="1.5rem"
          >
            Exemplo: caso um mesmo usuário esteja inserido em um processo de
            aprovação de usuário e um de centro de custo, e o peso de centro de
            custo seja maior que o de usuário, ao enviar uma solicitação de
            aprovação o processo de aprovação do centro de custo terá prioridade
            em cima do de usuário
          </Text>

          <Form.Field
            name="priorityRanking"
            as={
              <OrderableList
                renderItem={(option) => (
                  <span css={styles.label}>{option.label}</span>
                )}
              />
            }
          />
        </Form>
      </main>
      <footer css={styles.footer}>
        <ContainedButton
          disabled={context.submitting}
          loading={context.submitting}
          onClick={context.submitForm}
        >
          Salvar alterações
        </ContainedButton>
        <OutlinedButton style={{ width: "11.25rem" }} onClick={onClose}>
          Cancelar
        </OutlinedButton>
      </footer>
    </div>
  );
}
