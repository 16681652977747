import { css } from "@styled-system/css";
import { darken, shade } from "polished";
import { Theme } from "smartrips-skin";

import { colors } from "~/assets/styles/theme";

import styled from "@shared/styled";

export const Button = styled.button<{ theme: Theme; version: "v1" | "v2" }>(
  ({ theme, version }) =>
    version === "v1"
      ? css({
          width: [46, 52],
          height: [38, 58],
          borderRadius: [1, 0],
          borderWidth: [1, "1px 0 1px 0"],
          borderStyle: "solid",
          borderColor: "border",
          cursor: "pointer",
          backgroundColor: "#F7F8FD",
          transition: "all 0.2s",
          outline: 0,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          ["&:hover"]: {
            borderColor: darken(0.2, theme.colors.border),
            backgroundColor: shade(0.02, "#F7F8FD")
          },

          ["svg"]: {
            color: colors.slateGray,
            fontSize: 24,
            transform: ["none", "rotate(90deg)"]
          }
        })
      : css({
          alignItems: "center",
          aspectRatio: "1",
          backgroundColor: "transparent",
          border: "1px solid",
          borderColor: "border",
          borderRadius: "50%",
          cursor: "pointer",
          display: "flex",
          height: "38px",
          justifyContent: "center",
          outline: 0,
          transition: "all 0.15s",
          width: "38px",
          ["&:hover"]: {
            backgroundColor: shade(0.02, "#F7F8FD"),
            borderColor: darken(0.2, theme.colors.border)
          },
          ["svg"]: {
            color: colors.slateGray,
            fontSize: 24,
            transform: ["none", "rotate(90deg)"]
          }
        })
);
