import React, { useState, ChangeEvent, FocusEvent } from "react";
import { css } from "emotion";
import { Input } from "../../../shared/inputs";

const styles = {
  inputOutline: css({
    border: "none  !important",
    borderBottom: "0px solid #FFF !important",
    padding: "0px !important",
    ["::after"]: {
      top: 45
    }
  }),
  containerClasses: css({
    width: "100%"
  })
};

interface Props {
  value: string;
  handleChange: (description: string) => void;
}

const DescriptionInput = ({ value, ...props }: Props) => {
  const [description, setDescription] = useState(value);
  const [touched, setTouched] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: descriptionValue } = e.target;

    setDescription(descriptionValue);
    props.handleChange(descriptionValue);
  };

  const handleBlur = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  const isErrored = touched && !description;

  return (
    <Input
      id="description"
      placeholder="Descrição"
      underlineHighlight={true}
      value={description}
      onChange={handleChange}
      onBlur={handleBlur}
      notchedOutlineStyle={styles.inputOutline}
      containerClasses={styles.containerClasses}
      inputProps={{
        style: {
          fontSize: 28,
          fontWeight: "bold",
          textAlign: "center"
        }
      }}
      InputProps={{
        inputRef: (element: HTMLInputElement) => {
          if (element) {
            element.style.setProperty("padding-bottom", "6px", "important");
          }
        }
      }}
      error={isErrored}
      errorMessage={isErrored ? "Preencha a descrição do relatório" : null}
      errorStyle={{
        textAlign: "center",
        fontSize: 15
      }}
    />
  );
};

export { DescriptionInput };
