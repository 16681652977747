import React from "react";
import { Box, Flex, Text } from "@toolkit";
import { DoneReviewCard } from "./DoneReviewCard";
import { useUserReviewsContext } from "../UserReviews.context";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps
} from "react-virtualized";
import isEmpty from "lodash/isEmpty";

const DoneReviews = () => {
  const { doneReviews } = useUserReviewsContext();
  const reviewsCount = doneReviews.length;

  const listMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 220
  });

  const renderReviewItem = ({ index, key, style, parent }: ListRowProps) => {
    const review = doneReviews[index];
    return (
      <CellMeasurer
        key={key}
        cache={listMeasureCache}
        parent={parent}
        rowIndex={index}
        columnIndex={0}
      >
        <div style={style}>
          <DoneReviewCard
            reviewData={review}
            initialDate={review.hotelInitialDate.format("DD MMM, YYYY")}
            endDate={review.hotelEndDate.format("DD MMM, YYYY")}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <Box width={["100%"]}>
      <Flex flexDirection="column">
        {isEmpty(doneReviews) && (
          <Text fontSize={1}>Você não realizou nenhuma avaliação ainda</Text>
        )}
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <List
              autoHeight={true}
              width={width}
              height={5 * 220}
              rowHeight={listMeasureCache.rowHeight}
              rowRenderer={renderReviewItem}
              rowCount={reviewsCount}
              overscanRowCount={5}
              deferredMeasurementCache={listMeasureCache}
              style={{ outline: "none" }}
            />
          )}
        </AutoSizer>
      </Flex>
    </Box>
  );
};

export { DoneReviews };
