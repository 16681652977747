import React, { useContext, useEffect } from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import isEmpty from "lodash/isEmpty";
import { Box, Flex, Text } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { SearchInput } from "~/apps/shared/components/search-input/search-input";
import { ContainedButton } from "@components/shared/buttons/contained-button";

import SpinnerPortal from "../../../../../../../components/shared/Spinner";
import { ApprovalCardContainer } from "./approval-card/ApprovalCard.container";
import { ApprovalRuleDeletionDialog } from "./ApprovalRuleDeletionDialog";
import {
  ExpensesProcessProvider,
  ExpensesProcessContext,
} from "./ExpensesProcess.context";
import { Form } from "./form/FormDrawer";

const styles = {
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
};

const ExpensesProcess = () => {
  const {
    loading,
    approvalRules,
    visibleRules,
    selectedFormRule,
    isFormVisible,
    loadApprovals,
    handleOpenForm,
    handleCloseForm,
    handleSelectApprovalRuleToEdit,
    handleSelectApprovalRuleToDelete,
    handleChangeSearch,
  } = useContext(ExpensesProcessContext);

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeSearch(e.target.value);
  };

  useEffect(() => {
    loadApprovals();
  }, []);

  return (
    <Box>
      <SpinnerPortal visible={loading} />
      <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
        <ContainedButton onClick={handleOpenForm} css={styles.button}>
          Novo processo
        </ContainedButton>

        <SearchInput
          onChange={handleChangeSearchText}
          placeholder="Pesquise por nome do usuário"
        />

        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          {visibleRules.map((rule) => (
            <ApprovalCardContainer
              key={rule.expenseApprovalProcessToken}
              approvalRule={rule}
              handleEdit={handleSelectApprovalRuleToEdit}
              handleDelete={handleSelectApprovalRuleToDelete}
            />
          ))}
          {isEmpty(approvalRules) && <Text>Nenhum fluxo cadastrado</Text>}
          {!isEmpty(approvalRules) && isEmpty(visibleRules) && (
            <Text>Nenhum fluxo encontrado</Text>
          )}
        </Flex>
      </Flex>
      <Form
        open={isFormVisible}
        approvalRule={selectedFormRule}
        handleClose={handleCloseForm}
      />
      <ApprovalRuleDeletionDialog />
    </Box>
  );
};

const Container = (_: any) => (
  <ExpensesProcessProvider>
    <ExpensesProcess />
  </ExpensesProcessProvider>
);

export { Container as ExpensesProcess };
