import { fixDecimalPlaces } from "~/utils/number.utils";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Moment } from "moment";
import moment from "moment";
import uuid from "uuid";

import { CurrencyCode } from "~/constants/enums";

import { FormValues } from "../components/expenses/ExpenseDrawer/ExpenseDrawer.types";
import {
  EXPENSE_APPROVAL_STATUS,
  EXPENSE_REPORT_STATUS,
  EXPENSES_CATEGORIES
} from "../constants";
import {
  CreateFlightExpenseRequest,
  CreateHotelExpenseRequest,
  CreateKilometerExpenseRequest,
  CreateGeneralExpenseRequest,
  CreateMealExpenseRequest
} from "../dtos/expenses.dto";
import { Client } from "../models/client.model";
import { CostCenterListByUserItem } from "../models/cost-center.model";
import {
  Expense,
  ExpenseReport,
  ExpenseReportWithUser,
  ExpenseReportApprovalStatus,
  ExpenseReportApprovalStatusApprover,
  ExpenseApproverHistory,
  PaymentMethodsOptions
} from "../models/expense.model";
import { UserModel } from "../models/user.model";
import { ImageFile, StringBooleanMap, StringTMap } from "../types";

const MAX_RECEIPT_WIDTH = 900;

const MAP_ZOOM_RANGE = [
  { min: 0, max: 5, zoom: 14 },
  { min: 6, max: 10, zoom: 13 },
  { min: 11, max: 20, zoom: 12 },
  { min: 21, max: 50, zoom: 11 },
  { min: 51, max: 1000, zoom: 10 }
];

export function getTemporaryFilename(imageFile: ImageFile): string {
  const fileExtension = imageFile.type.split("/").pop();
  return String.prototype.concat(uuid(), `.${fileExtension}`);
}

export function formatFlightExpenseCreationData(
  expenseFormValues: FormValues,
  receiptFileName?: string
): CreateFlightExpenseRequest {
  const isOneway = expenseFormValues.flightType === "oneway";
  const defaultExchangeRate = 1;

  const formattedData: CreateFlightExpenseRequest = {
    description: expenseFormValues.description,
    date: expenseFormValues.date!.format("YYYY-MM-DD"),
    value: expenseFormValues.value!,
    currency: expenseFormValues.currency,
    exchange_rate: expenseFormValues.exchangeRate
      ? expenseFormValues.exchangeRate
      : defaultExchangeRate,
    origin_locale: expenseFormValues.origin!.locale,
    origin_name: expenseFormValues.origin!.name,
    origin_city_id: expenseFormValues.origin!.cityId,
    origin_country: expenseFormValues.origin!.country,
    destination_locale: expenseFormValues.destination!.locale,
    destination_name: expenseFormValues.destination!.name,
    destination_city_id: expenseFormValues.destination!.cityId,
    destination_country: expenseFormValues.destination!.country,
    start_date: expenseFormValues.startDate!.format("YYYY-MM-DD"),
    cabin_class: expenseFormValues.cabinClass!,
    oneway: isOneway,
    expense_category: expenseFormValues.expenseCategory,
    expense_category_token: expenseFormValues.expenseCategoryToken,
    payment_method: expenseFormValues.paymentMethod as PaymentMethodsOptions,
    traveler_token: expenseFormValues.travelerToken
  };

  if (receiptFileName) {
    formattedData.receipt_file = receiptFileName;
  }

  if (!isOneway && expenseFormValues.endDate) {
    formattedData.end_date = expenseFormValues.endDate.format("YYYY-MM-DD");
  }

  if (expenseFormValues.reportToken) {
    formattedData.expense_report_token = expenseFormValues.reportToken;
  }

  return formattedData;
}

export function formatHotelExpenseCreationData(
  expenseFormValues: FormValues,
  receiptFileName?: string
): CreateHotelExpenseRequest {
  const defaultExchangeRate = 1;

  const formattedData: CreateHotelExpenseRequest = {
    description: expenseFormValues.description,
    date: expenseFormValues.date!.format("YYYY-MM-DD"),
    value: expenseFormValues.value!,
    currency: expenseFormValues.currency,
    exchange_rate: expenseFormValues.exchangeRate
      ? expenseFormValues.exchangeRate
      : defaultExchangeRate,
    participants_quantity: expenseFormValues.participantsQuantity! as number,
    hotel_search: expenseFormValues.hotel!.search,
    hotel_latitude: expenseFormValues.hotel!.latitude,
    hotel_longitude: expenseFormValues.hotel!.longitude,
    start_date: expenseFormValues.startDate!.format("YYYY-MM-DD"),
    end_date: expenseFormValues.endDate!.format("YYYY-MM-DD"),
    expense_category: expenseFormValues.expenseCategory,
    expense_category_token: expenseFormValues.expenseCategoryToken,
    payment_method: expenseFormValues.paymentMethod as PaymentMethodsOptions,
    traveler_token: expenseFormValues.travelerToken
  };

  if (receiptFileName) {
    formattedData.receipt_file = receiptFileName;
  }

  if (expenseFormValues.reportToken) {
    formattedData.expense_report_token = expenseFormValues.reportToken;
  }

  return formattedData;
}

function formatKilometerExpenseCreationData(
  expenseFormValues: FormValues,
  receiptFileName: string
): CreateKilometerExpenseRequest {
  const { originLocation, destinationLocation } = expenseFormValues;
  const defaultExchangeRate = 1;

  const formattedData: CreateKilometerExpenseRequest = {
    description: expenseFormValues.description,
    date: expenseFormValues.date!.format("YYYY-MM-DD"),
    value: expenseFormValues.value!,
    currency: expenseFormValues.currency,
    exchange_rate: expenseFormValues.exchangeRate
      ? expenseFormValues.exchangeRate
      : defaultExchangeRate,
    receipt_file: receiptFileName,
    origin_location_search: originLocation!.search,
    origin_coordinates: originLocation!.latitude
      .toString()
      .concat(",", originLocation!.longitude.toString()),
    destination_location_search: destinationLocation!.search,
    destination_coordinates: destinationLocation!.latitude
      .toString()
      .concat(",", destinationLocation!.longitude.toString()),
    distance: expenseFormValues.distance!,
    expense_category: expenseFormValues.expenseCategory,
    expense_category_token: expenseFormValues.expenseCategoryToken,
    payment_method: expenseFormValues.paymentMethod as PaymentMethodsOptions,
    traveler_token: expenseFormValues.travelerToken
  };

  if (expenseFormValues.reportToken) {
    formattedData.expense_report_token = expenseFormValues.reportToken;
  }

  return formattedData;
}

export function formatGeneralExpenseCreationData(
  expenseFormValues: FormValues,
  receiptFileName: string
): CreateGeneralExpenseRequest {
  const defaultExchangeRate = 1;

  const formattedData: CreateGeneralExpenseRequest = {
    description: expenseFormValues.description,
    date: expenseFormValues.date!.format("YYYY-MM-DD"),
    value: expenseFormValues.value!,
    currency: expenseFormValues.currency,
    exchange_rate: expenseFormValues.exchangeRate
      ? expenseFormValues.exchangeRate
      : defaultExchangeRate,
    location_search: expenseFormValues.locationSearch!,
    receipt_file: receiptFileName,
    expense_category: expenseFormValues.expenseCategory,
    expense_category_token: expenseFormValues.expenseCategoryToken,
    payment_method: expenseFormValues.paymentMethod as PaymentMethodsOptions,
    traveler_token: expenseFormValues.travelerToken
  };

  if (expenseFormValues.reportToken) {
    formattedData.expense_report_token = expenseFormValues.reportToken;
  }

  return formattedData;
}

export function formatMealExpenseCreationData(
  expenseFormValues: FormValues,
  receiptFileName: string
): CreateMealExpenseRequest {
  const defaultExchangeRate = 1;

  const formattedData: CreateMealExpenseRequest = {
    description: expenseFormValues.description,
    date: expenseFormValues.date!.format("YYYY-MM-DD"),
    value: expenseFormValues.value!,
    currency: expenseFormValues.currency,
    exchange_rate: expenseFormValues.exchangeRate
      ? expenseFormValues.exchangeRate
      : defaultExchangeRate,
    location_search: expenseFormValues.locationSearch!,
    participants_quantity: expenseFormValues.participantsQuantity! as number,
    receipt_file: receiptFileName,
    expense_category: expenseFormValues.expenseCategory,
    expense_category_token: expenseFormValues.expenseCategoryToken,
    payment_method: expenseFormValues.paymentMethod as PaymentMethodsOptions,
    traveler_token: expenseFormValues.travelerToken
  };

  if (expenseFormValues.reportToken) {
    formattedData.expense_report_token = expenseFormValues.reportToken;
  }

  return formattedData;
}

export function getExpenseCreationFormattedData(
  expenseFormValues: FormValues,
  receiptFileName: string
) {
  switch (expenseFormValues.expenseCategory) {
    case EXPENSES_CATEGORIES.FLIGHT:
      return formatFlightExpenseCreationData(
        expenseFormValues,
        receiptFileName
      );
    case EXPENSES_CATEGORIES.HOTEL:
      return formatHotelExpenseCreationData(expenseFormValues, receiptFileName);
    case EXPENSES_CATEGORIES.APARTMENT:
      return formatHotelExpenseCreationData(expenseFormValues, receiptFileName);
    case EXPENSES_CATEGORIES.KILOMETER:
      return formatKilometerExpenseCreationData(
        expenseFormValues,
        receiptFileName
      );
    case EXPENSES_CATEGORIES.BREAKFAST:
      return formatMealExpenseCreationData(expenseFormValues, receiptFileName);
    case EXPENSES_CATEGORIES.LUNCH:
      return formatMealExpenseCreationData(expenseFormValues, receiptFileName);
    case EXPENSES_CATEGORIES.DINNER:
      return formatMealExpenseCreationData(expenseFormValues, receiptFileName);
    default:
      return formatGeneralExpenseCreationData(
        expenseFormValues,
        receiptFileName
      );
  }
}

export function getCheckedExpenseTokens(
  checkedExpenses: StringBooleanMap
): string[] {
  return Object.keys(checkedExpenses).reduce(
    (acc: string[], current: string) => {
      if (checkedExpenses[current]) {
        acc.push(current);
      }

      return acc;
    },
    []
  );
}

export function forkmikMapPropsToValue(
  selectedExpense: Expense | null = null,
  selectedReceipt: ImageFile | null = null,
  reportToken?: string,
  travelerToken?: string
): FormValues {
  if (selectedExpense) {
    return mapPropsToValuesWithExpense(
      selectedExpense,
      selectedReceipt,
      reportToken,
      travelerToken
    );
  } else {
    return mapPropsToEmptyValues(reportToken, travelerToken);
  }
}

function mapPropsToEmptyValues(
  reportToken?: string,
  travelerToken?: string
): FormValues {
  return {
    description: "",
    date: null,
    value: null,
    currency: CurrencyCode.BRL,
    exchangeRate: null,
    locationSearch: "",
    origin: null,
    destination: null,
    cabinClass: "",
    flightType: "",
    hotel: null,
    receipt: null,
    originLocation: null,
    destinationLocation: null,
    distance: null,
    participantsQuantity: "",
    reportToken,
    startDate: null,
    endDate: null,
    expenseCategory: "",
    expenseCategoryToken: "",
    paymentMethod: "",
    travelerToken
  };
}

function mapPropsToValuesWithExpense(
  expense: Expense,
  selectedReceipt: ImageFile | null,
  reportToken?: string,
  travelerToken?: string
): FormValues {
  const {
    description,
    date,
    value,
    currency,
    exchangeRate,
    locationSearch,
    originLocale,
    originName,
    originCityId,
    originCountry,
    destinationLocale,
    destinationName,
    destinationCityId,
    destinationCountry,
    cabinClass,
    oneway,
    hotelSearch,
    hotelLatitude,
    hotelLongitude,
    originLocationSearch,
    originCoordinates,
    destinationLocationSearch,
    destinationCoordinates,
    distance,
    participantsQuantity,
    startDate,
    endDate,
    expenseCategory,
    expenseCategoryToken,
    paymentMethod
  } = expense;

  const isOriginFilled =
    originLocale && originName && originCityId && originCountry;

  const isDestinationFilled =
    destinationLocale &&
    destinationName &&
    destinationCityId &&
    destinationCountry;

  const isHotelFilled = hotelSearch && hotelLatitude && hotelLongitude;

  const isOriginLocationFilled = originLocationSearch && originCoordinates;
  const originCoord = originCoordinates
    ? getCoordinatesFromString(originCoordinates)
    : null;

  const isDestinationLocationFilled =
    destinationLocationSearch && destinationCoordinates;
  const destinationCoord = destinationCoordinates
    ? getCoordinatesFromString(destinationCoordinates)
    : null;

  return {
    description,
    date,
    value,
    currency,
    exchangeRate,
    locationSearch: locationSearch ? locationSearch : "",
    origin: isOriginFilled
      ? {
          locale: originLocale!,
          name: originName!,
          cityId: originCityId!,
          country: originCityId!
        }
      : null,
    destination: isDestinationFilled
      ? {
          locale: destinationLocale!,
          name: destinationName!,
          cityId: destinationCityId!,
          country: destinationCountry!
        }
      : null,
    cabinClass: cabinClass ? cabinClass : "",
    flightType: oneway ? "oneway" : "roundtrip",
    hotel: isHotelFilled
      ? {
          search: hotelSearch!,
          latitude: parseFloat(hotelLatitude!),
          longitude: parseFloat(hotelLongitude!)
        }
      : null,
    receipt: selectedReceipt ? selectedReceipt : null,
    originLocation: isOriginLocationFilled
      ? {
          search: originLocationSearch!,
          latitude: originCoord!.lat,
          longitude: originCoord!.lng
        }
      : null,
    destinationLocation: isDestinationLocationFilled
      ? {
          search: destinationLocationSearch!,
          latitude: destinationCoord!.lat,
          longitude: destinationCoord!.lng
        }
      : null,
    distance,
    participantsQuantity,
    reportToken,
    startDate: startDate ? startDate : null,
    endDate: endDate ? endDate : null,
    expenseCategory: expenseCategory ? expenseCategory : "",
    expenseCategoryToken: expenseCategoryToken ? expenseCategoryToken : "",
    paymentMethod: paymentMethod ? paymentMethod : "",
    travelerToken
  };
}

export function calculateExpensesPeriod(
  expenses: Expense[]
): { periodStart: Moment; periodEnd: Moment } {
  if (isEmpty(expenses)) {
    return {
      periodStart: moment(),
      periodEnd: moment()
    };
  }

  const dateInfo = getDateInfoFromExpenses(expenses);
  const startDates = dateInfo.map(dates => dates.start);
  const endDates = dateInfo.map(dates => dates.end);

  const min = startDates.reduce((a: Moment, b: Moment) => {
    if (!a) {
      return b;
    }

    return a.isBefore(b) ? a : b;
  });

  const max = endDates.reduce((a: Moment, b: Moment) => {
    if (!a) {
      return b;
    }

    return a.isAfter(b) ? a : b;
  });

  return {
    periodStart: min,
    periodEnd: max
  };
}

function getDateInfoFromExpenses(
  expenses: Expense[]
): Array<{ start: Moment; end: Moment }> {
  return expenses.map(expense => {
    if (expense.startDate) {
      return {
        start: expense.startDate,
        end: expense.endDate || expense.startDate
      };
    } else {
      return {
        start: expense.date,
        end: expense.date
      };
    }
  });
}

export function isForeignCurrencyExpense(expense: Expense): boolean {
  return expense.currency !== CurrencyCode.BRL;
}

export function hasForeignCurrencyExpenses(expenses: Expense[]): boolean {
  return expenses.some(expense => isForeignCurrencyExpense(expense));
}

export function getReportTotalPrice(
  expenses: Expense[],
  reportStatus: number
): number {
  const filteredExpenses = expenses.filter(
    expense =>
      !expense.canceled && expense.expenseCategory !== EXPENSES_CATEGORIES.FEE
  );

  if (reportStatus === EXPENSE_REPORT_STATUS.CLOSED) {
    return filteredExpenses.reduce((acc, current) => {
      if (current.approvedValue) {
        const convertedValue = convertCurrencyValue(current);
        return acc + convertedValue;
      }
      return acc;
    }, 0);
  } else {
    return filteredExpenses.reduce((acc, current) => {
      const convertedValue = convertCurrencyValue(current);
      return acc + convertedValue;
    }, 0);
  }
}

export function getReportsWithUsers(
  reports: ExpenseReport[],
  users: UserModel[]
): ExpenseReportWithUser[] {
  const usersObject: StringTMap<UserModel> = users.reduce(
    (acc: StringTMap<UserModel>, current) => {
      acc[current.userToken] = current;
      return acc;
    },
    {}
  );

  return reports.map(report => {
    return Object.assign({}, report, {
      user: usersObject[report.travelerToken]
    });
  });
}

export function getSelectedCostCenter(
  costCenters: CostCenterListByUserItem[],
  report: ExpenseReport,
  isRequired: boolean
) {
  if (report.costCenterToken) {
    const selectedCostCenter = costCenters.find(
      item => item.token === report.costCenterToken
    );
    return selectedCostCenter ? selectedCostCenter : null;
  }

  if (isRequired && costCenters.length === 1) {
    return costCenters[0];
  }

  return null;
}

export function getSelectedCompanyArea(
  areas: Record<string, any>[],
  report: ExpenseReport,
  isRequired: boolean
) {
  if (report.companyAreaToken) {
    return report.companyAreaToken;
  }

  if (isRequired && areas.length === 1) {
    return areas[0].companyAreaToken;
  }

  return "";
}

export function getSelectedBillingProfile(
  billingProfiles: Record<string, any>[],
  report: ExpenseReport
) {
  if (report.billingProfileToken) {
    return report.billingProfileToken;
  }

  if (billingProfiles.length === 1) {
    return billingProfiles[0].billingProfileToken;
  }

  return "";
}

export function getSelectedProject(
  projects: Record<string, any>[],
  report: ExpenseReport,
  isRequired: boolean
) {
  if (report.projectToken) {
    return report.projectToken;
  }

  if (isRequired && projects.length === 1) {
    return projects[0].projectToken;
  }

  return "";
}

export function getExpensesWithUpdatedApprovedValues(
  approvalStatus: ExpenseReportApprovalStatus,
  expenses: Expense[]
): Expense[] {
  const approvedValuesMap: StringTMap<number> = approvalStatus!.lastApprovedValues.reduce(
    (acc: StringTMap<number>, current) => {
      acc[current.expenseToken] = current.approvedValue;
      return acc;
    },
    {}
  );

  return expenses
    .filter(expense => expense.expenseCategory !== EXPENSES_CATEGORIES.FEE)
    .map(expense => {
      const approvedValue = approvedValuesMap[expense.expenseToken];
      expense.approvedValue =
        approvedValue !== undefined && !isNaN(approvedValue)
          ? approvedValue
          : convertCurrencyValue(expense);

      return expense;
    });
}

export function getLastRepliedApprover(
  approvers: ExpenseReportApprovalStatusApprover[]
): ExpenseReportApprovalStatusApprover {
  const sortedApprovers = approvers.sort((a, b) => {
    if (a.repliedAt === b.repliedAt) {
      return b.stage - a.stage;
    } else {
      return moment(b.repliedAt).diff(a.repliedAt);
    }
  });

  return sortedApprovers[0];
}

export function insertNewApproverHistoryRow(
  approvalStatus: ExpenseReportApprovalStatus,
  user: UserModel,
  approved: boolean
): ExpenseReportApprovalStatus {
  const currentApprovalStage =
    approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT
      ? 99
      : approvalStatus.currentApprovalStage;

  const newHistory = generateApproverHistoryRow(
    user,
    approved,
    currentApprovalStage
  );

  return Object.assign({}, approvalStatus, {
    expenseApproversHistory: approvalStatus.expenseApproversHistory.concat(
      newHistory
    )
  });
}

function generateApproverHistoryRow(
  user: UserModel,
  approved: boolean,
  approvalStage: number
): ExpenseApproverHistory {
  return {
    approved,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.firstName.concat(" ", user.lastName),
    repliedAt: moment().toString(),
    role: user.role!,
    stage: approvalStage,
    userToken: user.userToken
  };
}

function getCoordinatesFromString(stringCoordinates: string) {
  const [latString, lngString] = stringCoordinates.split(",");

  return {
    lat: parseFloat(latString),
    lng: parseFloat(lngString)
  };
}

export function extractCoordinatesArray(
  location:
    | {
        search: string;
        latitude: number;
        longitude: number;
      }
    | null
    | undefined
): [number, number] | null {
  if (location && location.latitude && location.longitude) {
    return [location.latitude, location.longitude];
  }

  return null;
}

export function hasLocationChanged(
  formValues: FormValues,
  originalExpense: Expense
) {
  if (originalExpense.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER) {
    return true;
  }

  const originCoordinates = getCoordinatesFromString(
    originalExpense.originCoordinates!
  );
  const destinationCoordinates = getCoordinatesFromString(
    originalExpense.destinationCoordinates!
  );

  const isOriginLocationsSame =
    isEqual(formValues.originLocation!.latitude, originCoordinates.lat) &&
    isEqual(formValues.originLocation!.longitude, originCoordinates.lng);

  const isDestinationLocationsSame =
    isEqual(
      formValues.destinationLocation!.latitude,
      destinationCoordinates.lat
    ) &&
    isEqual(
      formValues.destinationLocation!.longitude,
      destinationCoordinates.lng
    );

  return !(isOriginLocationsSame && isDestinationLocationsSame);
}

export function getMapZoom(distance: number) {
  const zoomRange = MAP_ZOOM_RANGE.find(
    range => distance > range.min && distance < range.max
  );

  return zoomRange && zoomRange.zoom ? zoomRange.zoom : 10;
}

export function areAllExpensesTraveled(expenses: Expense[]) {
  const now = moment();

  const activeExpenses = expenses.filter(expense => !expense.canceled);

  const result = activeExpenses.every((expense: Expense) => {
    const date = expense.endDate || expense.startDate || expense.date;
    return now > date;
  });

  return result;
}

export function getLastExpenseDate(expenses: Expense[]): Moment | null {
  const activeExpenses = expenses.filter(expense => !expense.canceled);
  if (activeExpenses.length === 0) return null;

  let lastExpenseDate: Moment | null = null;
  for (const expense of activeExpenses) {
    const expenseDate = expense.endDate || expense.startDate || expense.date;

    if (!lastExpenseDate || expenseDate.isAfter(lastExpenseDate)) {
      lastExpenseDate = expenseDate;
    }
  }

  return lastExpenseDate;
}

export async function getResizedReceiptImage(
  imageFile: ImageFile
): Promise<any> {
  return new Promise(resolve => {
    function returnImageFile(name: string, type: string) {
      return (imageBlob: Blob | null) => {
        const img = new File([imageBlob!], name, { type });
        return resolve(img as ImageFile);
      };
    }

    const imageUrl = URL.createObjectURL(imageFile);
    const image = new Image();

    image.onload = () => {
      // No need to resize if it is on limits
      if (image.width <= MAX_RECEIPT_WIDTH) {
        return resolve(imageFile);
      }

      const canvas = document.createElement("canvas");
      canvas.width = MAX_RECEIPT_WIDTH;
      canvas.height = (image.height * MAX_RECEIPT_WIDTH) / image.width;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(returnImageFile(imageFile.name, imageFile.type));
    };

    image.src = imageUrl;
  });
}

export async function rotateReceipt(
  imgUrl: string,
  rotationDegrees: number
): Promise<string> {
  return new Promise(resolve => {
    const image = new Image();
    image.src = imgUrl;

    image.onload = () => {
      const canvas = document.createElement("canvas");

      if ([90, 270].includes(rotationDegrees)) {
        canvas.width = image.height;
        canvas.height = image.width;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }

      const ctx = canvas.getContext("2d");
      ctx!.translate(canvas.width / 2, canvas.height / 2);
      ctx!.rotate((rotationDegrees * Math.PI) / 180);
      ctx!.drawImage(image, -image.width / 2, -image.height / 2);

      return resolve(canvas.toDataURL("base64"));
    };
  });
}

export function getVisibleExpenses(report: ExpenseReport) {
  return report.expenses.filter(
    expense => expense.expenseCategory !== EXPENSES_CATEGORIES.FEE
  );
}

export function convertCurrencyValue(expense: Expense): number {
  return fixDecimalPlaces(expense.value * expense.exchangeRate);
}

export function getExpensesMapByDate(expenses: Expense[]) {
  return expenses.reduce((acc, expense) => {
    const date = expense.date && expense.date.format("ddd, DD MMM YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(expense);

    return acc;
  }, {} as Record<string, Expense[]>);
}

export function sortExpensesByDate(expenses: Expense[]) {
  return expenses.sort((a, b) => a.date.diff(b.date));
}
