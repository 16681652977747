import React from "react";

import Drawer from "@material-ui/core/Drawer";
import { css } from "@styled-system/css";

import { ApprovableRule } from "@models/expense-approval.model";

import { FormContainer } from "./Form";

const styles = {
  drawer: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: [500, 815],
    },
  }),
};

interface Props {
  open: boolean;
  approvalRule: ApprovableRule | null;
  handleClose: () => void;
}

const FormDrawer = ({ open, approvalRule, handleClose }: Props) => {
  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      css={styles.drawer}
    >
      <FormContainer approvalRule={approvalRule} />
    </Drawer>
  );
};

export { FormDrawer as Form };
