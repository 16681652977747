import * as clientApi from "~/apps/corporate/apis/client.api";
import * as projectsApi from "~/apps/corporate/apis/projects.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import { CreateProjectRequestBody } from "~/apps/corporate/dtos/projects.dto";
import { getClientToken } from "~/apps/corporate/helpers/user.helper";
import errorTypes, { ERROR } from "~/apps/shared/constants/errors";

import { UserSearch } from "@models/user.model";

import {
  ListProjectsResult,
  GenericResult,
  GetSingleProjectResult,
  ProjectForm,
  CreationAndEditionResult,
} from "./projects.types";

export interface Error {
  title: string;
  description: string;
}

export async function listProjectsByClient() {
  const result: ListProjectsResult = {};

  try {
    result.data = await projectsApi.listClientProjects();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_PROJECT_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_PROJECT_LIST_ERROR;
    }
  }

  return result;
}

export async function toggleProjectActiveStatus(projectToken: string) {
  const result: GenericResult = {};

  try {
    result.data = await projectsApi.toggleProjectActiveStatus(projectToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_PROJECT_STATUS_CHANGE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_PROJECT_STATUS_CHANGE_ERROR;
    }
  }

  return result;
}

export async function getSingleProject(projectToken: string) {
  const result: GetSingleProjectResult = {};

  try {
    result.data = await projectsApi.getSingleProject(projectToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_PROJECT_EDIT_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_PROJECT_EDIT_FETCH_ERROR;
    }
  }

  return result;
}

export async function createProject(formData: ProjectForm) {
  const result: CreationAndEditionResult = {};

  try {
    const clientToken = getClientToken();

    if (!clientToken) {
      result.error = ERROR.UNEXPECTED;

      return result;
    }

    const createProjectRequestBody: CreateProjectRequestBody = {
      client_token: clientToken,
      name: formData.name,
      user_tokens: formData.users.map((user) => user.userToken),
    };

    const projectToken = await projectsApi.createProject(
      createProjectRequestBody,
    );
    const createdProject = buildProjectItem(formData, projectToken);
    result.data = createdProject;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 403 && data.type === errorTypes.PROJECT_ALREADY_EXISTS) {
        result.error = ERROR.PROJECT_NAME_ALREADY_TAKEN;
      } else {
        result.error = ERROR.UNEXPECTED_PROJECT_CREATION_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_PROJECT_CREATION_ERROR;
    }
  }

  return result;
}

export async function editProject(formData: ProjectForm, projectToken: string) {
  const result: CreationAndEditionResult = {};

  try {
    const requestDto = {
      name: formData.name,
      user_tokens: formData.users.map((user) => user.userToken),
      client_token: formData.clientToken!,
      active: !!formData.active,
    };

    await projectsApi.editProject(requestDto, projectToken);
    const updatedProject = buildProjectItem(formData, projectToken);
    result.data = updatedProject;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 403 && data.type === errorTypes.PROJECT_ALREADY_EXISTS) {
        result.error = ERROR.PROJECT_NAME_ALREADY_TAKEN;
      } else {
        result.error = ERROR.UNEXPECTED_PROJECT_EDITING_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_PROJECT_EDITING_ERROR;
    }
  }

  return result;
}

export async function searchUsers(
  search: string,
): Promise<{ data?: UserSearch[]; error?: Error }> {
  const result: { data?: UserSearch[]; error?: Error } = {};

  try {
    result.data = await userApi.getUsersByName(search);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function changeProjectEnablingState(
  projectState: string,
  clientConfigToken: string,
) {
  const result: GenericResult = {};

  try {
    const requestDto = {
      project_enabling_state: projectState,
    };

    result.data = await clientApi.updateClientConfig(
      clientConfigToken,
      requestDto,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPTECT_UPDATE_PROJECT_ENABLING_STATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPTECT_UPDATE_PROJECT_ENABLING_STATE_ERROR;
    }
  }

  return result;
}

function buildProjectItem(formData: ProjectForm, projectToken: string) {
  return Object.assign({}, formData, {
    active: true,
    clientToken: formData.clientToken!,
    projectToken,
    totalUsers: formData.users.length,
    createdAt: "",
  });
}
