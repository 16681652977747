import React, { useState, createContext, useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import moment, { Moment } from "moment";

import { RealTimeTravel, GetRealTimeTravel } from "@models/analytics.model";

import { CustomError } from "~/types";

import { parseTravelsByCity } from "./real-time-travels.helper";
import * as realTimeTravelsService from "./real-time-travels.service";

moment.locale("pt-br");

interface Date {
  endDate: Moment | null;
  startDate: Moment | null;
}

type FocusedInputShape = "endDate" | "startDate";

export enum Status {
  FAILURE = "failure",
  FETCHING = "fetching",
  IDLE = "idle",
  SUCCESS = "success",
}

type State = {
  error: CustomError | null;
  rangeFocus: FocusedInputShape | null;
  selectedCity: string | null;
  status: Status;
  travels: RealTimeTravel[];
  travelsByCity: { [key: string]: RealTimeTravel[] } | {};
};

const initialState = {
  travels: [],
  travelsByCity: [],
  selectedCity: null,
  status: Status.IDLE,
  error: null,
  rangeFocus: null,
};

// default range is one week from today
const initialDatesState = {
  startDate: moment(),
  endDate: moment().add(7, "days"),
};

interface Actions {
  getRealTimeTravels: () => Promise<void>;
  handleRangeDateChange: (date: Date) => void;
  handleChangeRangeDatePickerFocus: (focused: FocusedInputShape | null) => void;
  handleSelect: (cityName: string) => void;
}

const RealTimeTravelsContext = createContext<State & Actions & Date>({
  ...initialDatesState,
  ...initialState,
  getRealTimeTravels: async () => {
    return;
  },
  handleChangeRangeDatePickerFocus: () => null,
  handleRangeDateChange: () => null,
  handleSelect: () => null,
});

export const RealTimeTravelsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [dates, setDates] = useState(initialDatesState);
  const [rangeFocus, setRangeFocus] = useState<FocusedInputShape | null>(null);
  const [state, setState] = useState<State>(initialState);

  const getRealTimeTravels = async () => {
    setState((prev) => ({
      ...prev,
      error: null,
      selectedCity: null,
      status: Status.FETCHING,
    }));

    await realTimeTravelsService
      .getRealTimeTravelsData({
        end_date: dates.endDate.format("YYYY-MM-DD"),
        start_date: dates.startDate.format("YYYY-MM-DD"),
      })
      .then((travels) => {
        const travelsByCity = parseTravelsByCity(travels.data!);

        setState((prev) => ({
          ...prev,
          status: Status.SUCCESS,
          travels: travels.data!,
          travelsByCity,
        }));
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          error,
          selectedCity: null,
          status: Status.FAILURE,
          travels: [],
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);
      });
  };

  const handleChangeRangeDatePickerFocus = (
    focused: FocusedInputShape | null,
  ) => {
    setRangeFocus(focused);
  };

  const handleRangeDateChange = (date: {
    endDate: Moment | null;
    startDate: Moment | null;
  }) => {
    setDates({ startDate: date.startDate!, endDate: date.endDate! });
  };

  const handleSelect = useCallback((cityName: string) => {
    setState((prev) => ({
      ...prev,
      selectedCity: cityName,
    }));
  }, []);

  return (
    <RealTimeTravelsContext.Provider
      value={{
        ...dates,
        ...state,
        getRealTimeTravels,
        handleChangeRangeDatePickerFocus,
        handleRangeDateChange,
        handleSelect,
        rangeFocus,
      }}
    >
      {children}
    </RealTimeTravelsContext.Provider>
  );
};

export const useRealTimeTravels = useContextFactory(
  "RealTimeTravelsContext",
  RealTimeTravelsContext,
);
