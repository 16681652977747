import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  basic: {
    info: {
      row: {
        label: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
          width: "128px",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "16px",
        }),
        value: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
  },
  header: {
    button: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      cursor: "pointer",
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      fontWeight: 500,
      marginTop: "2px",
      transition: "color 0.15s ease-in-out",
      width: "fit-content",
      "&:hover": {
        color: theme.colors.pink[700],
      },
    }),
    root: css({
      display: "flex",
      justifyContent: "space-between",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "1.25",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px",
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
};
