import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    display: "flex",
  }),
  input: css({
    alignItems: "center",
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    overflow: "hidden",
    padding: "12px 16px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  }),
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
  }),
};
