import { css } from "emotion";
import { defaultTheme } from "@theme";
import { theme } from "@skin/v2";

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap"
});

const notchedOutlineStyle = css({
  border: `1px solid ${defaultTheme.cardBorderColor}`,
  borderRadius: '0.5rem !important',
  display: "inline-block",
  [`.MuiOutlinedInput-root.Mui-focused &`]: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: defaultTheme.cardBorderColor
  },
  [`::after`]: {
    content: "''",
    display: "block",
    position: "relative",
    top: "37px",
    transform: "scaleX(0)",
    borderBottom: `2px solid ${defaultTheme.primaryColor}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    transition: "transform 300ms ease"
  }
});

const inputStyles = css({
  padding: "13.5px 14px 12.5px !important",
  ["&:disabled"]: {
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    cursor: "not-allowed"
  }
});

const inputLabelStyles = {
  root: css({
    top: "-4px !important",
    color: "#999BA0"
  }),
  shrink: css({
    top: "0 !important"
  }),
  focused: css({
    color: `${theme.colors.gray[500]} !important`
  }),
  error: css({
    color: `${defaultTheme.secondaryColor} !important`
  })
};

const notchedOutlineFocused = css({
  [`.${notchedOutlineStyle}`]: {
    borderColor: `${theme.colors.gray[500]} !important`,
    borderWidth: "2px !important"
  }
});

const underlineFocused = css({
  [`.${notchedOutlineStyle}::after`]: {
    transform: "scaleX(1)"
  }
});

const underlineErrored = css({
  [`.${notchedOutlineStyle}`]: {
    borderColor: `${defaultTheme.cardBorderColor} !important`,
    borderBottomColor: `${defaultTheme.secondaryColor} !important`,
    borderWidth: "1px 1px 2px 1px !important"
  }
});

const error = css({
  [`.${notchedOutlineStyle}`]: {
    borderColor: `${defaultTheme.secondaryColor} !important`,
    borderWidth: 2
  }
});

const inputBaseStyles = {
  root: css({
    [`&:hover:not(.${notchedOutlineFocused}):not(.${underlineFocused}):not(.${error}):not(.${underlineErrored}) .${notchedOutlineStyle}`]: {
      borderColor: `${defaultTheme.cardBorderColor} !important`
    },
    color: defaultTheme.textColor,
    fontFamily: "'Open sans', sans-serif"
  }),
  disabled: css({
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor
  })
};

export default {
  containerStyle,
  notchedOutlineStyle,
  notchedOutlineFocused,
  underlineFocused,
  underlineErrored,
  error,
  inputStyles,
  inputLabelStyles,
  inputBaseStyles
};
