// todo!: add type
export function FlightFactory(flightDto: any) {
  const {
    id,
    flight_token,
    travel_token,
    origin_locale,
    origin_name,
    origin_city,
    destination_locale,
    destination_name,
    destination_city,
    international,
    outbound_flight_date,
    outbound_flight_type,
    outbound_flight_start,
    outbound_flight_end,
    inbound_flight_date,
    inbound_flight_type,
    inbound_flight_start,
    inbound_flight_end,
    oneway,
    advance_days
  } = flightDto;

  const result = {} as any;

  result.id = id;
  result.token = flight_token;
  result.travelToken = travel_token;
  result.originLocale = origin_locale;
  result.originName = origin_name;
  result.originCity = origin_city;
  result.destinationLocale = destination_locale;
  result.destinationName = destination_name;
  result.destinationCity = destination_city;
  result.international = international;
  result.outboundDate = outbound_flight_date;
  result.outboundType = outbound_flight_type;
  result.outboundStart = outbound_flight_start;
  result.outboundEnd = outbound_flight_end;
  result.inboundDate = inbound_flight_date;
  result.inboundType = inbound_flight_type;
  result.inboundStart = inbound_flight_start;
  result.inboundEnd = inbound_flight_end;
  result.oneway = oneway;
  result.advanceDays = advance_days;

  return result;
}
// todo!: add type
export function HotelFactory(hotelDto: any) {
  const {
    id,
    hotel_token,
    travel_token,
    hotel_latitude,
    hotel_longitude,
    hotel_city,
    hotel_state,
    hotel_country,
    hotel_continent,
    hotel_initial_date,
    hotel_end_date,
    advance_days
  } = hotelDto;

  const result = {} as any;

  result.id = id;
  result.token = hotel_token;
  result.travelToken = travel_token;
  result.latitude = hotel_latitude;
  result.logintude = hotel_longitude;
  result.city = hotel_city;
  result.state = hotel_state;
  result.country = hotel_country;
  result.continent = hotel_continent;
  result.initialDate = hotel_initial_date;
  result.endDate = hotel_end_date;
  result.advanceDays = advance_days;

  return result;
}

// function CarFactory(dto) {
//   const {
//     id,
//     car_token,
//     travel_token,
//     car_latitude,
//     car_longitude,
//     car_city,
//     car_state,
//     car_country,
//     car_initial_date,
//     car_end_date,
//     advance_days
//   } = dto;

//   const result = {};

//   result.id = id;
//   result.token = car_token;
//   result.travelToken = travel_token;
//   result.latitude = car_latitude;
//   result.logintude = car_longitude;
//   result.city = car_city;
//   result.state = car_state;
//   result.country = car_country;
//   result.initialDate = car_initial_date;
//   result.endDate = car_end_date;
//   result.advanceDays = advance_days;

//   return result;
// }

// function BusFactory(dto) {
//   const {
//     id,
//     bus_token,
//     travel_token,
//     origin_locale,
//     origin_name,
//     destination_locale,
//     destination_name,
//     outbound_bus_date,
//     inbound_bus_date,
//     oneway,
//     advance_days
//   } = dto;

//   const result = {};

//   result.id = id;
//   result.token = bus_token;
//   result.travelToken = travel_token;
//   result.originLocale = origin_locale;
//   result.originName = origin_name;
//   result.destinationLocale = destination_locale;
//   result.destinationName = destination_name;
//   result.outboundDate = outbound_bus_date;
//   result.inboundDate = inbound_bus_date;
//   result.oneway = oneway;
//   result.advanceDays = advance_days;

//   return result;
// }
// todo!: add type
function FlightItineraryFactory(dto: any) {
  const {
    origin_locale,
    origin_name,
    destination_locale,
    destination_name,
    date,
    order,
    type
  } = dto;

  const result = {} as any;

  result.originLocale = origin_locale;
  result.originName = origin_name;
  result.destinationLocale = destination_locale;
  result.destinationName = destination_name;
  result.date = date;
  result.order = order;
  result.type = type;

  return result;
}

// function BusItineraryFactory(dto) {
//   const { origin_name, destination_name, date, order, type } = dto;
//   const result = {};

//   result.originName = origin_name;
//   result.destinationName = destination_name;
//   result.date = date;
//   result.order = order;
//   result.type = type;

//   return result;
// }
// todo!: add type
function HotelItineraryFactory(dto: any) {
  const { hotel_days, hotel_city, hotel_state, date, order, type } = dto;
  const result = {} as any;

  result.days = hotel_days;
  result.city = hotel_city;
  result.state = hotel_state;
  result.date = date;
  result.order = order;
  result.type = type;

  return result;
}

// function CarItineraryFactory(dto) {
//   const { car_days, car_city, car_state, date, order, type } = dto;
//   const result = {};

//   result.days = car_days;
//   result.city = car_city;
//   result.state = car_state;
//   result.date = date;
//   result.order = order;
//   result.type = type;

//   return result;
// }

// todo!: add type
export function TravelFactory(dto: any) {
  const {
    id,
    travel_token,
    travel_name,
    flight, // todo: retirar
    hotel, // todo: retirar
    // car,
    // bus,
    flight_purpose_token,
    hotel_purpose_token,
    trip_purpose_token,
    advance_days,
    status,
    created_at,
    booker_token,
    approver_token,
    traveler_token,
    approval_status,
    approval_created_at,
    approver_stage,
    // flights, // todo: retirar
    // hotels, // todo: retirar
    // cars,
    // buses,
    scheduled,
    concluded,
    canceled,
    ongoing,
    draft,
    cost_center_token,
    itinerary,
    services,
    expired,
    traveled,
    rawStartTravel,
    rawEndTravel,
    travel_start,
    travel_end,
    fee_paid,
    fee_paid_at,
    fee_value,
    hasAlteration,
    hasInsurance,
    hasCancelation,
    copy_status,
    copy_from,
    friend_house_status,
    billing_profile_token,
    company_area_token,
    project_token,
    expense_report_token,
    booking_fee_status
  } = dto;

  const result = {} as any;

  result.id = id;
  result.token = travel_token;
  result.name = travel_name;
  result.flight = flight;
  result.hotel = hotel;
  result.advanceDays = advance_days;
  result.status = status;
  result.createdAt = created_at;
  result.bookerToken = booker_token;
  result.approverToken = approver_token;
  result.travelerToken = traveler_token;
  result.approvalStatus = approval_status;
  result.approvalCreatedAt = approval_created_at ? approval_created_at : null;
  result.approverStage = approver_stage;
  result.scheduled = scheduled;
  result.concluded = concluded;
  result.canceled = canceled;
  result.ongoing = ongoing;
  result.draft = draft;
  result.costCenterToken = cost_center_token;

  result.services = services;
  result.expired = expired;
  result.traveled = traveled;
  result.rawStartTravel = rawStartTravel;
  result.rawEndTravel = rawEndTravel;
  result.travelStart = travel_start;
  result.travelEnd = travel_end;
  result.feePaid = fee_paid;
  result.feePaidAt = fee_paid_at;
  result.feeValue = fee_value;
  result.hasAlteration = hasAlteration;
  result.hasInsurance = hasInsurance;
  result.hasCancelation = hasCancelation;
  result.copyStatus = copy_status;
  result.copyFrom = copy_from;
  result.friendHouseStatus = friend_house_status;
  result.billingProfileToken = billing_profile_token;
  result.companyAreaToken = company_area_token;
  result.projectToken = project_token;
  result.expenseReportToken = expense_report_token;
  result.tripPurposeToken = trip_purpose_token;
  result.flightPurposeToken = flight_purpose_token;
  result.hotelPurposeToken = hotel_purpose_token;
  result.bookingFeeStatus = booking_fee_status;

  return result;
}
// todo!: add type
export function UpdateTravelFactory(data: any) {
  const result = {} as any;

  if (Object.prototype.hasOwnProperty.call(data, "friendHouseStatus")) {
    result.friend_house_status = data.friendHouseStatus;
  }

  return result;
}
