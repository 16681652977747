import { GroupBase, StylesConfig } from "react-select";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { BookerTarget } from "../users-user-form.types";

export const styles = {
  label: {
    avatar: css({
      minWidth: "32px !important",
      "& > :first-of-type": {
        flexShrink: 0,
        height: "32px !important",
        width: "32px !important",
      },
    }),
    info: {
      email: css({
        color: theme.colors.gray[500],
        fontSize: "0.75rem",
        fontWeight: 400,
        height: "fit-content",
      }),
      name: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 600,
        height: "fit-content",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
    root: css({
      display: "flex",
      gap: "8px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  }),
  select: <StylesConfig<BookerTarget, boolean, GroupBase<BookerTarget>>>{
    control: (baseStyles) => ({
      ...baseStyles,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow: "none",
      cursor: "pointer",
      height: "auto",
      outline: "none",
      "&:focus-within": {
        border: `1px solid ${theme.colors.gray[500]}`,
      },
      "&:hover": {
        borderColor: theme.colors.gray[300],
      },
    }),
    loadingMessage: (styles) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      overflow: "hidden",
      padding: "12px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: theme.colors.background.gray,
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      margin: "0",
      padding: "4px",
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      padding: "0 8px 0 0",
      paddingLeft: "0",
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "4px",
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.colors.pink[500],
        color: theme.colors.white,
      },
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      overflow: "hidden",
      padding: "12px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    option: (baseStyles, { isSelected }) => ({
      ...baseStyles,
      backgroundColor: "unset",
      color: isSelected ? theme.colors.gray[700] : theme.colors.gray[300],
      cursor: "pointer",
      padding: "12px 16px",
      "&:hover": {
        backgroundColor: theme.colors.background.gray,
        color: theme.colors.gray[700],
      },
    }),
    valueContainer: (baseStyles, { hasValue }) => ({
      ...baseStyles,
      gap: "4px",
      padding: hasValue ? "4px" : "8px",
    }),
  },
  title: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "4px",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "1.25",
    }),
  },
};
