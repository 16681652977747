import React from "react";

import {
  EXPENSES_CATEGORIES,
  EXPENSES_CATEGORIES_TRANSLATION
} from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { Expense, ExpensesPolicies } from "@models/expense.model";
import { ExpensePolicy } from "@models/expense.model";

import { ExpensePolicyBaseDialog } from "./ExpensePolicyBaseDialog";
import { GeneralWarning } from "./GeneralWarning";

interface ExpenseInfoProps {
  expense: Expense;
  policy: ExpensePolicy;
}

const FlightExpensePolicyInfo = ({ expense, policy }: ExpenseInfoProps) => {
  const convertedValue = expensesHelper.convertCurrencyValue(expense);
  const participants = expense.participantsQuantity;
  const maxValueDiff = convertedValue - policy.maxValue * participants;
  const isOutOfPolicy = maxValueDiff > 0;

  const MaxValueWarning = () => {
    return isOutOfPolicy ? (
      <GeneralWarning
        title={`O valor do voo inserido está ${utils.toCurrency(
          maxValueDiff
        )} acima do valor máximo.`}
        message={`O valor máximo permitido para despesas aéreas é de ${utils.toCurrency(
          policy.maxValue
        )}`}
      />
    ) : null;
  };

  return (
    <div>
      <MaxValueWarning />
    </div>
  );
};

const HotelExpensePolicyInfo = ({ expense, policy }: ExpenseInfoProps) => {
  const convertedValue = expensesHelper.convertCurrencyValue(expense);
  const participants = expense.participantsQuantity;
  const numberOfNights =
    expense.endDate && expense.startDate
      ? expense.endDate.diff(expense.startDate, "days")
      : 1;
  const maxValueDiff =
    convertedValue / (participants * numberOfNights) - policy.maxValue;
  const isOutOfPolicy = maxValueDiff > 0;

  const MaxValueWarning = () => {
    return isOutOfPolicy ? (
      <GeneralWarning
        title={`O valor da despesa inserida está ${utils.toCurrency(
          maxValueDiff
        )} acima do valor máximo.`}
        message={`O valor máximo permitido para despesas de hospedagem é de ${utils.toCurrency(
          policy.maxValue
        )} por noite.`}
      />
    ) : null;
  };

  return (
    <div>
      <MaxValueWarning />
    </div>
  );
};

const MealExpensePolicyInfo = ({ expense, policy }: ExpenseInfoProps) => {
  const convertedValue = expensesHelper.convertCurrencyValue(expense);
  const participants = expense.participantsQuantity;
  const maxValueDiff = convertedValue - policy.maxValue * participants;
  const isOutOfPolicy = maxValueDiff > 0;

  const MaxValueWarning = () => {
    return isOutOfPolicy ? (
      <GeneralWarning
        title={`O valor da despesa inserida está ${utils.toCurrency(
          maxValueDiff
        )} acima do valor máximo.`}
        message={`O valor máximo permitido para despesas de alimentação do tipo ${
          EXPENSES_CATEGORIES_TRANSLATION[expense.expenseCategory!]
        } é de ${utils.toCurrency(policy.maxValue)} por participante.`}
      />
    ) : null;
  };

  return (
    <div>
      <MaxValueWarning />
    </div>
  );
};

interface Props {
  open: boolean;
  expense: Expense | null;
  policies: ExpensesPolicies;
  onClose: () => void;
}

const ExpenseOutOfPolicyDialog = ({
  open,
  expense,
  policies,
  onClose
}: Props) => {
  if (!expense) {
    return null;
  }

  const getExpenseTypePolicyInfo = () => {
    switch (expense.expenseCategory) {
      case EXPENSES_CATEGORIES.FLIGHT:
        return <FlightExpensePolicyInfo expense={expense} policy={policy} />;
      case EXPENSES_CATEGORIES.HOTEL:
        return <HotelExpensePolicyInfo expense={expense} policy={policy} />;
      case EXPENSES_CATEGORIES.APARTMENT:
        return <HotelExpensePolicyInfo expense={expense} policy={policy} />;
      case EXPENSES_CATEGORIES.BREAKFAST:
        return <MealExpensePolicyInfo expense={expense} policy={policy} />;
      case EXPENSES_CATEGORIES.LUNCH:
        return <MealExpensePolicyInfo expense={expense} policy={policy} />;
      case EXPENSES_CATEGORIES.DINNER:
        return <MealExpensePolicyInfo expense={expense} policy={policy} />;
      default:
        return;
    }
  };

  const policy = policies[expense.expenseToken];
  return (
    <ExpensePolicyBaseDialog
      open={open}
      onClose={onClose}
      title="Essa despesa está fora de sua política"
    >
      {getExpenseTypePolicyInfo()}
    </ExpensePolicyBaseDialog>
  );
};

export { ExpenseOutOfPolicyDialog };
