import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ErrorWarning } from "~/apps/shared/components/error-warning/error-warning";
import {
  ALERT_TYPES,
  TRAVELS_PAGE_REDIRECT_MESSAGES,
} from "~/apps/shared/constants";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { CircularSpinner } from "@toolkit/v2";

import {
  TagsProvider,
  useTags,
} from "../../configurations/views/company/tags/tags.context";
import {
  useTravelsList,
  useTravelsListActions,
  useTravelsListDeletionActions,
  useTravelsTags,
  useTravelsTagsActions,
} from "../trips.context";
import { styles } from "./styles";
import { TripCard, TripCardSkeleton } from "./trip-card/trip-card";
import { TripDeletionDialog } from "./trip-deletion-dialog/trip-deletion-dialog";

const Component: React.FC = () => {
  const { location } = useLocation();

  const { showSnackMessage } = useApplication();
  const { setTravelsTags } = useTags();
  const { isLoading: isLoadingUserTravelTags, tags } = useTravelsTags();
  const { loadUserTravelTags } = useTravelsTagsActions();
  const {
    currentPage,
    isLoadingTravelsCount,
    loading: isLoadingTravels,
    loadingError,
    loadingMore,
    totalPages,
    travels,
  } = useTravelsList();
  const { loadMoreTravels } = useTravelsListActions();
  const { handleSelectToDelete } = useTravelsListDeletionActions();

  const anyLoading =
    isLoadingTravels || isLoadingUserTravelTags || isLoadingTravelsCount;
  const hasMoreTravelsToLoad = currentPage < totalPages;
  const noResultFound = travels.length === 0 && !isLoadingTravels;

  useEffect(() => {
    const params = location.state;

    if (params && params.message) {
      showSnackMessage(
        params.message in TRAVELS_PAGE_REDIRECT_MESSAGES
          ? TRAVELS_PAGE_REDIRECT_MESSAGES[
              params.message as keyof typeof TRAVELS_PAGE_REDIRECT_MESSAGES
            ]
          : params.message,
        ALERT_TYPES.SUCCESS,
      );
    }

    loadUserTravelTags();
  }, []);

  useEffect(() => {
    setTravelsTags(tags);
  }, [tags]);

  if (anyLoading) {
    return (
      <div css={styles.root}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <TripCardSkeleton key={index} />
          ))}
      </div>
    );
  }

  if (loadingError) {
    return (
      <div css={styles.error}>
        <ErrorWarning
          message={loadingError.description}
          title={loadingError.title}
        />
      </div>
    );
  }

  if (noResultFound) {
    return (
      <div css={styles.root}>
        <span css={styles.empty}>
          Não foi encontrado nenhum resultado para sua busca...
        </span>
      </div>
    );
  }

  return (
    <>
      <div css={styles.root}>
        <InfiniteScroll
          hasMore={hasMoreTravelsToLoad}
          loader={<CircularSpinner key={0} size={24} />}
          loadMore={() => {
            if (loadingMore) {
              return;
            }

            loadMoreTravels();
          }}
          pageStart={1}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          useWindow
        >
          {travels.length > 0 ? (
            travels.map((travel) => (
              <TripCard
                key={travel.travelId}
                selectTravelToCancel={handleSelectToDelete}
                travel={travel}
              />
            ))
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </div>
      <TripDeletionDialog />
    </>
  );
};

export const TripsList: React.FC = () => {
  return (
    <TagsProvider>
      <Component />
    </TagsProvider>
  );
};
