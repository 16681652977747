import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  right: {
    bottom: {
      guests: {
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
      points: {
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
      price: {
        label: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          "@media (max-width: 640px)": {
            alignItems: "flex-end",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          },
        }),
        value: css({
          color: theme.colors.gray[700],
          fontSize: "1.5rem",
          fontWeight: 700,
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        justifyContent: "flex-end",
        width: "100%",
        "@media (max-width: 640px)": {
          alignItems: "flex-start",
          justifyContent: "space-between",
        },
      }),
    },
    button: ({ disabled }: { disabled: boolean }) =>
      css({
        alignItems: "center",
        backgroundColor: theme.colors.pink[500],
        border: "1px solid",
        borderColor: theme.colors.pink[500],
        borderRadius: "8px",
        color: theme.colors.white,
        display: "flex",
        fontWeight: 500,
        height: "46px",
        justifyContent: "center",
        minHeight: "46px",
        opacity: !disabled ? 1 : 0.75,
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        ":hover": {
          backgroundColor: theme.colors.pink[700],
          borderColor: theme.colors.pink[700],
        },
        "@media (max-width: 1440px)": {
          padding: "12px 0",
          textAlign: "center",
        },
      }),
    root: css({
      alignItems: "center",
      borderLeft: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      gap: "16px",
      justifyContent: "flex-end",
      minWidth: "192px",
      paddingLeft: "16px",
      "@media (max-width: 640px)": {
        alignItems: "flex-start",
        borderLeft: "unset",
        gap: "24px",
        padding: "16px",
      },
    }),
    top: {
      badge: css({
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
    },
  },
  root: ({ disabled }: { disabled: boolean }) =>
    css({
      backgroundColor: theme.colors.background.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      marginBottom: !disabled ? "16px" : "unset",
      padding: "16px",
      opacity: !disabled ? 1 : 0.75,
      overflow: "hidden",
      "@media (max-width: 640px)": {
        backgroundColor: theme.colors.white,
        flexDirection: "column",
        padding: "unset",
      },
    }),
};
