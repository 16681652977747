import React, { createContext, useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Tab, Tabs } from "@material-ui/core";
import { EmojiObjectsOutlined as WarningIcon } from "@material-ui/icons";
import classNames from "classnames";
import { Box, Flex } from "smartrips-toolkit";

import { IBookingTarget } from "~/models/booker-target.model";
import type { BusSearchForm } from "~/models/bus.model";

import { useContextFactory } from "~/hooks";

import { AltertBox, AltertTypeEnum } from "~/components/shared/altert-box";
import { Row } from "~/components/shared/layout";

import { RichTextEditor } from "@components/shared/rich-text-editor";

import { BusFormContainer } from "../BusForm/BusForm.container";
import { CarFormContainer } from "../CarForm/CarForm.container";
import { FlightFormContainer } from "../FlightForm/FlightForm.container";
import { HotelFormContainer } from "../HotelForm/HotelForm.container";
import type { CarSearch, FlightSearch, HotelSearch } from "../NewTrip.types";
import { styles } from "./styles";

export const OFFER_SEARCH_TABS = {
  BUS: "bus",
  CAR: "car",
  FLIGHT: "flight",
  HOTEL: "hotel"
} as const;

export type OfferSearchTab = typeof OFFER_SEARCH_TABS[keyof typeof OFFER_SEARCH_TABS];

type OfferSearchContextProps = {
  getBookingTargets:
    | ((props: { search: string }) => Promise<IBookingTarget[]>)
    | null;
  handleTabChange: (_: unknown, selectedTab: OfferSearchTab) => void;
  selectedTab: OfferSearchTab;
  setGetBookingTargets: (
    getBookingTargets: OfferSearchContextProps["getBookingTargets"]
  ) => void;
};

const OfferSearchContext = createContext<OfferSearchContextProps>(
  {} as OfferSearchContextProps
);

export const useOfferSearch = useContextFactory(
  "OfferSearchContext",
  OfferSearchContext
);

export const OfferSearchProvider: React.FC = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<
    OfferSearchContextProps["selectedTab"]
  >(OFFER_SEARCH_TABS.FLIGHT);
  const [getBookingTargets, setGetBookingTargets] = useState<
    OfferSearchContextProps["getBookingTargets"]
  >(null);

  const handleTabChange: OfferSearchContextProps["handleTabChange"] = useCallback(
    (_, tab) => {
      setSelectedTab(tab);
    },
    []
  );

  const setGetBookingTargetsMiddleware = useCallback(
    (getBookingTargets: OfferSearchContextProps["getBookingTargets"]) => {
      setGetBookingTargets(() => getBookingTargets);
    },
    []
  );

  return (
    <OfferSearchContext.Provider
      value={{
        getBookingTargets,
        handleTabChange,
        selectedTab,
        setGetBookingTargets: setGetBookingTargetsMiddleware
      }}
    >
      {children}
    </OfferSearchContext.Provider>
  );
};

export const OfferSearch = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div className={classNames(styles.root, className)} ref={ref} {...props}>
      {children}
    </div>
  );
});

OfferSearch.displayName = "OfferSearchContainer";

export const OfferSearchAlert: React.VFC<{
  __html: string;
}> = ({ __html }) => {
  return (
    <AltertBox
      containerStyle={{
        alignSelf: "center",
        margin: "2rem 2rem -1rem"
      }}
      text={
        <p
          dangerouslySetInnerHTML={{
            __html
          }}
        />
      }
      textStyle={{ lineHeight: "1.125rem" }}
      type={AltertTypeEnum.INFO}
    />
  );
};

export const OfferSearchMessage: React.FC<{
  children: string;
  link?: string;
}> = ({ children, link }) => {
  return (
    <Box css={styles.message.root}>
      <Flex alignItems="center" justifyContent="center">
        <WarningIcon css={styles.message.icon} />
        <RichTextEditor.Readonly
          value={
            children && typeof children === "string"
              ? JSON.parse(children)
              : children
          }
        />
      </Flex>
    </Box>
  );
};

export const OfferSearchForm = React.forwardRef<
  HTMLDivElement,
  Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
    handleBusSearch: (form: BusSearchForm) => void;
    handleCarSearch: (form: CarSearch) => void;
    handleFlightSearch: (form: FlightSearch) => void;
    handleHotelSearch: (form: HotelSearch) => void;
  }
>(
  (
    {
      className,
      handleBusSearch,
      handleCarSearch,
      handleFlightSearch,
      handleHotelSearch,
      ...props
    },
    ref
  ) => {
    const { selectedTab } = useOfferSearch();

    return (
      <div className={classNames(styles.form, className)} ref={ref} {...props}>
        {selectedTab === OFFER_SEARCH_TABS.FLIGHT && (
          <FlightFormContainer onSubmit={handleFlightSearch} />
        )}
        {selectedTab === OFFER_SEARCH_TABS.HOTEL && (
          <HotelFormContainer onSubmit={handleHotelSearch} />
        )}
        {selectedTab === OFFER_SEARCH_TABS.CAR && (
          <CarFormContainer onSubmit={handleCarSearch} />
        )}
        {selectedTab === OFFER_SEARCH_TABS.BUS && (
          <BusFormContainer onSubmit={handleBusSearch} />
        )}
      </div>
    );
  }
);

OfferSearchForm.displayName = "OfferSearchForm";

export const OfferSearchSkeleton: React.VFC<
  React.ComponentPropsWithoutRef<typeof Box>
> = ({ ...props }) => {
  return (
    <Box className="col-md-11" css={styles.skeleton.root} {...props}>
      <Flex justifyContent="center" width="100%">
        <Box css={styles.skeleton.tabs}>
          <Skeleton height={40} />
        </Box>
      </Flex>
      <Box css={styles.skeleton.body}>
        <Box mb={["medium", 3]} width="15%">
          <Skeleton height={20} />
        </Box>
        <Flex
          alignItems="center"
          flexDirection={["column"]}
          justifyContent="space-between"
        >
          <Box width="100%" mb={[2, 2]}>
            <Skeleton height={60} />
          </Box>
          <Box width="100%">
            <Flex
              alignItems="center"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
            >
              <Box mb={[2, 0]} mr={[0, 2]} width={["100%", "55%"]}>
                <Skeleton height={60} />
              </Box>
              <Box width={["100%", "45%"]}>
                <Skeleton height={60} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box css={styles.skeleton.footer}>
        <Flex
          alignItems="center"
          flexDirection={["column", "row"]}
          justifyContent="space-between"
        >
          <Box maxWidth={["100%", 300]} mb={["medium", 0]} width="100%">
            <Skeleton height={60} />
          </Box>
          <Box maxWidth={["100%", 141.39]} width="100%">
            <Skeleton height={40} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export const OfferSearchTabs = React.forwardRef<
  HTMLDivElement,
  Omit<React.ComponentPropsWithoutRef<typeof Row>, "children"> & {
    busVisible: boolean;
    carVisible: boolean;
    hotelVisible: boolean;
  }
>(({ busVisible, carVisible, className, hotelVisible, ...props }, ref) => {
  const { handleTabChange, selectedTab } = useOfferSearch();

  return (
    <Row
      className={classNames(styles.tabs.container, className)}
      ref={ref}
      {...props}
    >
      <Tabs
        classes={{
          indicator: styles.tabs.tab.indicator,
          root: styles.tabs.root,
          scroller: styles.tabs.tab.scroller
        }}
        onChange={handleTabChange}
        value={selectedTab}
      >
        <Tab
          classes={{
            root: classNames(
              styles.tabs.tab.root,
              hotelVisible || busVisible || carVisible
                ? styles.tabs.tab.first
                : styles.tabs.tab.single
            ),
            selected: styles.tabs.tab.selected
          }}
          label="Voos"
          value={OFFER_SEARCH_TABS.FLIGHT}
        />
        {hotelVisible && (
          <Tab
            classes={{
              root: classNames(
                styles.tabs.tab.root,
                busVisible || carVisible
                  ? styles.tabs.tab.middle
                  : styles.tabs.tab.last
              ),
              selected: styles.tabs.tab.selected
            }}
            label="Hotéis"
            value={OFFER_SEARCH_TABS.HOTEL}
          />
        )}
        {carVisible && (
          <Tab
            classes={{
              root: classNames(
                styles.tabs.tab.root,
                busVisible ? styles.tabs.tab.middle : styles.tabs.tab.last
              ),
              selected: styles.tabs.tab.selected
            }}
            label="Carros"
            value={OFFER_SEARCH_TABS.CAR}
          />
        )}
        {busVisible && (
          <Tab
            classes={{
              root: classNames(styles.tabs.tab.root, styles.tabs.tab.last),
              selected: styles.tabs.tab.selected
            }}
            label="Ônibus"
            value={OFFER_SEARCH_TABS.BUS}
          />
        )}
      </Tabs>
    </Row>
  );
});

OfferSearchTabs.displayName = "OfferSearchTabs";
