import React, { useEffect, useMemo, useState } from "react";

import {
  ItineraryServiceInfoModel,
  ItineraryServiceInfoModelFactory,
} from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { Drawer } from "~/apps/shared/components/drawer/drawer";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";

import { useItineraryScreen } from "../../itinerary-screen.context";
import { useItineraryServices } from "../../itinerary-services.context";
import * as itineraryService from "../../itinerary.service";
import { ItineraryBusDetailsDrawer } from "./itinerary-bus-details-drawer/itinerary-bus-details-drawer";
import { ItineraryCarDetailsDrawer } from "./itinerary-car-details-drawer/itinerary-car-details-drawer";
import { ItineraryFlightDetailsDrawer } from "./itinerary-flight-details-drawer/itinerary-flight-details-drawer";
import { ItineraryHotelDetailsDrawer } from "./itinerary-hotel-details-drawer/itinerary-hotel-details-drawer";
import { useItineraryServiceDetailsDrawerPresenter } from "./itinerary-service-details-drawer.hooks";
import { styles } from "./styles";

const Component: React.FC = () => {
  const { servicePresenter } = useItineraryServiceDetailsDrawerPresenter();

  const {
    closeServiceDetailsDrawer,
    isServiceDetailsDrawerOpen,
  } = useItineraryScreen();
  const { travelPoliciesPerOffer } = useItineraryServices();

  const [
    itineraryServiceInfoModel,
    setItineraryServiceInfoModel,
  ] = useState<ItineraryServiceInfoModel | null>(null);

  const policy = useMemo(() => {
    if (!servicePresenter || !travelPoliciesPerOffer) {
      return null;
    }

    return travelPoliciesPerOffer[servicePresenter.getOfferToken()];
  }, [servicePresenter]);

  useEffect(() => {
    if (!servicePresenter) {
      return;
    }

    void (async () => {
      const itineraryServiceInfo = await itineraryService.getItineraryServiceInfo(
        servicePresenter.getOfferToken(),
      );

      if (itineraryServiceInfo.isFailure()) {
        return;
      }

      const itineraryServiceInfoModel = ItineraryServiceInfoModelFactory.create(
        itineraryServiceInfo.data,
      );

      setItineraryServiceInfoModel(itineraryServiceInfoModel);
    })();
  }, [servicePresenter]);

  return (
    <Drawer
      css={styles.root}
      onClose={closeServiceDetailsDrawer}
      open={isServiceDetailsDrawerOpen}
    >
      {servicePresenter ? (
        <>
          {servicePresenter.isBusService() ? (
            <ItineraryBusDetailsDrawer
              closeServiceDetailsDrawer={closeServiceDetailsDrawer}
              itineraryServiceInfoModel={itineraryServiceInfoModel}
              policy={policy}
              servicePresenter={servicePresenter}
            />
          ) : null}
          {servicePresenter.isCarService() ? (
            <ItineraryCarDetailsDrawer
              closeServiceDetailsDrawer={closeServiceDetailsDrawer}
              itineraryServiceInfoModel={itineraryServiceInfoModel}
              policy={policy}
              servicePresenter={servicePresenter}
            />
          ) : null}
          {servicePresenter.isFlightService() ? (
            <ItineraryFlightDetailsDrawer
              closeServiceDetailsDrawer={closeServiceDetailsDrawer}
              itineraryServiceInfoModel={itineraryServiceInfoModel}
              policy={policy}
              servicePresenter={servicePresenter}
            />
          ) : null}
          {servicePresenter.isHotelService() ? (
            <ItineraryHotelDetailsDrawer
              closeServiceDetailsDrawer={closeServiceDetailsDrawer}
              itineraryServiceInfoModel={itineraryServiceInfoModel}
              policy={policy}
              servicePresenter={servicePresenter}
            />
          ) : null}
        </>
      ) : null}
    </Drawer>
  );
};

export const ItineraryServiceDetailsDrawer: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
