import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { CircularSpinner } from "@toolkit/v2";

import { useItineraryServices } from "../../itinerary-services.context";
import { styles } from "./styles";

export const ItineraryHeaderDownloadInvoicesButton: React.FC = () => {
  const {
    downloadInvoices,
    isLoadingDownloadInvoices,
  } = useItineraryServices();

  const [downloadedInvoices, setDownloadedInvoices] = useState(false);

  const handleDownloadInvoices = useCallback(async () => {
    const success = await downloadInvoices();

    if (!success) {
      return;
    }

    setDownloadedInvoices(true);
  }, [downloadInvoices]);

  return (
    <Tooltip
      content={
        downloadedInvoices
          ? "Notas baixadas"
          : isLoadingDownloadInvoices
          ? "Baixando notas..."
          : "Baixar notas"
      }
      position="top"
    >
      <span css={styles.span}>
        <button
          css={styles.button}
          disabled={downloadedInvoices}
          onClick={async () => {
            await handleDownloadInvoices();
          }}
        >
          {isLoadingDownloadInvoices ? (
            <CircularSpinner size={16} />
          ) : (
            <Icon
              size={16}
              use={downloadedInvoices ? "check" : "cloud-arrow-down"}
            />
          )}
        </button>
      </span>
    </Tooltip>
  );
};
