import { CurrencyCode, CurrencySymbol } from "~/apps/shared/constants/enums";

export const toCurrency = (
  value: number | undefined | null,
  decimals = 2,
  currencyCode = CurrencyCode.BRL,
) => {
  if (typeof value === "undefined" || value === null) {
    return "-";
  }

  return (
    `${CurrencySymbol[currencyCode]} ` +
    value.toLocaleString("pt-BR", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  );
};
