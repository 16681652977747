import React from "react";

import { OfferSearcherDialog } from "~/apps/corporate/components/offer-searcher-dialog/offer-search-dialog";
import { ServiceType } from "~/apps/shared/constants/enums";

import { useFlights } from "../flights.context";

type Props = {
  travelToken: string;
};

export const FlightsEditSearchDialog: React.FC<Props> = ({ travelToken }) => {
  const {
    addServiceDrawer,
    buildAddServiceData,
    handleCloseEdition,
  } = useFlights();

  return (
    <OfferSearcherDialog
      data={buildAddServiceData()}
      onClose={handleCloseEdition}
      open={addServiceDrawer.isOpen}
      travelToken={travelToken}
      title="Editar busca"
      type={ServiceType.FLIGHT}
    />
  );
};
