import React, { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  WindowScroller,
} from "react-virtualized";

import { CountryRestrictionMessage } from "~/apps/corporate/components/country-restriction-message/country-restriction-message";
import { NoResultsWarning } from "~/apps/shared/components/no-results-warning/no-results-warning";
import { CarOutOfPolicyDialog } from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";
import { useUpdatedVirtualizedGrid } from "~/apps/shared/hooks/use-updated-virtualized-grid";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import moment from "moment";

import { useCars } from "../../cars.context";
import { ContentFilters } from "../content-filters/content-filters";
import { CarCard, CarCardSkeleton } from "./car-card/car-card";
import { styles } from "./styles";

export const ContentList: React.FC = () => {
  const {
    cars,
    countryRestriction,
    filters: { filtering },
    handleCloseOutOfPolicyDialog,
    handleOpenOutOfPolicyDialog,
    handleShowAgencyOnMap,
    isLoading,
    policy,
    searchInfo,
    selectedOutOfPolicyOffer,
    visibleCars,
  } = useCars();
  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 1024, [windowWidth]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
  });

  const listRef = useRef<List>(null);

  const renderCar: ListRowRenderer = ({ index, key, parent, style }) => {
    if (!searchInfo) {
      return null;
    }

    const car = visibleCars[index];

    if (!car.supplierInfo) {
      return null;
    }

    const daysInterval = moment(searchInfo!.dropoffDate).diff(
      moment(searchInfo!.pickupDate),
      "days",
    );
    const offerLink = `/travels/${searchInfo.travelToken}/search/cars/${searchInfo.carToken}/${car.supplierInfo.id}`;

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <CarCard
            car={car}
            daysInterval={daysInterval}
            diffDropoff={searchInfo.diffDropoff}
            handleOpenOutOfPolicyDialog={handleOpenOutOfPolicyDialog(car)}
            handleShowOnMap={handleShowAgencyOnMap(car)}
            offerLink={offerLink}
          />
        </div>
      </CellMeasurer>
    );
  };

  const stats = useMemo(() => {
    const count = visibleCars.length;

    return filtering
      ? "Filtrando..."
      : `${count} ${count === 1 ? "resultado" : "resultados"}${
          !isMobile ? " de carro para sua pesquisa" : ""
        }`;
  }, [filtering, visibleCars]);

  useUpdatedVirtualizedGrid(cache, listRef);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          {countryRestriction ? (
            <CountryRestrictionMessage
              message={countryRestriction.message_content}
              restrictionType={countryRestriction.restriction_type}
            />
          ) : null}
          <div css={styles.stats.root}>
            <span css={styles.stats.count}>
              {!isLoading ? (
                <p css={styles.stats.root}>{stats}</p>
              ) : (
                <Skeleton height="16px" width={!isMobile ? "288px" : "96px"} />
              )}
            </span>
            {!isMobile ? null : <ContentFilters isMobile={isMobile} />}
          </div>
        </div>
        {!isLoading ? (
          <WindowScroller>
            {({ height, isScrolling, registerChild, scrollTop }) => (
              <div style={{ flex: "1 1 auto" }}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <List
                        autoHeight
                        deferredMeasurementCache={cache}
                        height={height}
                        isScrolling={isScrolling}
                        noRowsRenderer={() => (
                          <div css={styles.empty.root}>
                            <NoResultsWarning
                              message={
                                cars.length === 0 ? (
                                  <>
                                    <p>
                                      Ops, parece que não encontramos nenhum
                                      carro para a sua pesquisa em nossos
                                      fornecedores.
                                    </p>
                                    <p css={styles.empty.text}>
                                      Pedimos deculpa pelo transtorno. Por ora,
                                      recomendamos que entre em contato com
                                      nossa equipe de atendimento para que
                                      possamos encontrar um carro para você.
                                    </p>
                                  </>
                                ) : cars.length > 0 &&
                                  visibleCars.length === 0 ? (
                                  "Nenhum carro encontrado para filtro o selecionado..."
                                ) : null
                              }
                            />
                          </div>
                        )}
                        overscanRowCount={8}
                        ref={listRef}
                        rowCount={visibleCars.length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={renderCar}
                        scrollTop={scrollTop}
                        style={{ outline: "none" }}
                        width={width}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            )}
          </WindowScroller>
        ) : (
          <div>
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <CarCardSkeleton key={index} />
              ))}
          </div>
        )}
      </div>
      {policy && searchInfo && selectedOutOfPolicyOffer ? (
        <CarOutOfPolicyDialog
          onClose={handleCloseOutOfPolicyDialog}
          offer={selectedOutOfPolicyOffer}
          open
          policy={policy}
        />
      ) : null}
    </>
  );
};
