import React, { useState } from "react";

import { withFormik, FormikErrors, FormikProps } from "formik";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment";

import { IBookingTarget } from "~/models/booker-target.model";

import { CarSearch } from "../NewTrip.types";
import { CarFormPresentational } from "./CarForm.presentational";

interface Props {
  onSubmit: (value: CarSearch) => void;
}

const CarFormContainer = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
  setErrors
}: FormikProps<CarSearch>) => {
  const [focused, setFocus] = useState("");
  const [dateVisible, setDateVisibility] = useState(false);

  const removeFieldError = (fieldName: string) => {
    const updatedErrors = reduce(
      errors,
      (acc: any, value, key) => {
        if (key !== fieldName) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    setErrors(updatedErrors);
  };

  const handleDropoffDiffChange = (_: any, checked: boolean) => {
    setFieldValue("sameLocationDropoff", checked);
  };

  const handleLocationChange = (type: "pickupLocation" | "dropoffLocation") => (
    location: any
  ) => {
    if (!location) {
      setFieldValue(type, null);
    } else {
      setFieldValue(type, {
        search: location.formattedAddress,
        latitude: location.latitude(),
        longitude: location.longitude()
      });

      removeFieldError(type);
    }
  };

  const handleDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setFieldValue("pickupDate", date.startDate);
    setFieldValue("dropoffDate", date.endDate);

    if (date.startDate) {
      removeFieldError("pickupDate");
    }

    if (date.endDate) {
      removeFieldError("dropoffDate");
    }
  };

  const handleTravelerChange = (traveler: IBookingTarget) => {
    setFieldValue("traveler", traveler);

    if (traveler) {
      removeFieldError("traveler");
    }
  };

  const handleDateInputClick = (inputType: "pickup" | "dropoff") => () => {
    if (inputType === "pickup") {
      setFocus("startDate");
    } else {
      setFocus("endDate");
    }

    setDateVisibility(true);
  };

  const handleFocusChange = (focusedInput: any) => {
    if (!focusedInput) {
      setDateVisibility(false);
    }

    setFocus(focusedInput);
  };

  const handleCloseDatePopover = () => {
    setDateVisibility(false);
  };

  return (
    <CarFormPresentational
      values={values}
      errors={errors}
      dateInputFocused={focused}
      datePopoverVisible={dateVisible}
      handleDropoffDiffChange={handleDropoffDiffChange}
      handleLocationChange={handleLocationChange}
      handleDateChange={handleDateChange}
      handleTravelerChange={handleTravelerChange}
      handleDateInputClick={handleDateInputClick}
      handleFocusChange={handleFocusChange}
      handleChange={handleChange}
      handleCloseDatePopover={handleCloseDatePopover}
      handleSubmit={handleSubmit}
    />
  );
};

const FormikContainer = withFormik<Props, CarSearch>({
  mapPropsToValues: () => {
    return {
      sameLocationDropoff: true,
      pickupLocation: null,
      pickupDate: null,
      pickupTime: "10:00",
      dropoffLocation: null,
      dropoffDate: null,
      dropoffTime: "10:00",
      traveler: null
    };
  },
  validate: values => {
    const errors: FormikErrors<CarSearch> = {};

    if (!values.pickupLocation) {
      errors.pickupLocation = "Preencha o local de retirada do veículo";
    }

    if (!values.sameLocationDropoff && !values.dropoffLocation) {
      errors.dropoffLocation = "Preencha o local de devolução do veículo";
    }

    if (!values.pickupDate) {
      errors.pickupDate = "Preencha a data de retirada";
    }

    if (!values.pickupDate) {
      errors.pickupDate = "Preencha a data de check-in";
    } else if (
      values.pickupDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    ) {
      errors.pickupDate = "Data inicial inferior a hoje";
    }

    if (!values.pickupTime) {
      errors.pickupTime = "Preencha o horário de retirada";
    }

    if (!values.dropoffDate) {
      errors.dropoffDate = "Preencha a data de devolução";
    }

    if (!values.dropoffTime) {
      errors.dropoffTime = "Preencha o horário de devolução";
    }

    if (!values.traveler) {
      errors.traveler = "Atribua um viajante";
    }

    return errors;
  },
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(CarFormContainer);

export { FormikContainer as CarFormContainer };
