import React from "react";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  fill?: "filled" | "outlined";
  rating: number;
  size?: number;
};

export const StarRatingDisplay: React.FC<Props> = ({
  fill = "filled",
  rating,
  size = 20,
  ...props
}) => {
  return (
    <div css={styles.root} {...props}>
      {Array(rating)
        .fill(0)
        .map((_, i) => {
          return (
            <Icon
              key={i}
              size={size}
              use={fill === "filled" ? "star" : "star-outline"}
            />
          );
        })}
    </div>
  );
};
