import { css } from "emotion";
import { colors } from "../../assets/styles/theme/constants";

const styles = {
  tickText: css({
    fontSize: "12px !important"
  })
};

const tooltipStyles = {
  wrapperStyle: {
    borderColor: colors.botticelli,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4
  },
  contentStyle: {
    border: "none",
    padding: "1rem",
    background: "rgba(255, 255, 255, 0.9)"
  },
  labelStyle: {
    fontSize: 16,
    paddingBottom: ".75rem"
  },
  itemStyle: {
    fontSize: 14,
    paddingBottom: ".35rem"
  }
};

export { styles, tooltipStyles };
