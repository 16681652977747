import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof Badge>;

export const ItinerayJourneyHeaderCancelServiceBadge: React.FC<Props> = ({
  ...props
}) => {
  return (
    <Badge css={styles.root} fill="outlined" {...props}>
      <Icon css={styles.icon} size={16} use="x-circle-outline" />
      Cancelar serviço.
    </Badge>
  );
};
