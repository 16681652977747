import React from "react";
import Responsive from "react-responsive";

type Props = React.ComponentProps<typeof Responsive>;

export const Desktop: React.FC<Props> = ({ children, ...props }) => (
  <Responsive minWidth={992} {...props}>
    {children}
  </Responsive>
);

export const Mobile: React.FC<Props> = ({ children, ...props }) => (
  <Responsive maxWidth={767} {...props}>
    {children}
  </Responsive>
);

export const Tablet: React.FC<Props> = ({ children, ...props }) => (
  <Responsive minWidth={768} {...props} maxWidth={991}>
    {children}
  </Responsive>
);

export const TabletAndMobile: React.FC<Props> = ({ children, ...props }) => (
  <Responsive maxWidth={991} {...props}>
    {children}
  </Responsive>
);
