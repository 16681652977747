import { useQuery } from "react-query";

import * as approvalProcessApi from "~/apps/corporate/apis/approval-process.api";
import { UserModel } from "~/apps/corporate/models/user.model";

export const useClientApprovalProcesses = (user: UserModel | null) => {
  const userToken = user?.getUserToken();

  const {
    data: approvalProcesses,
    error: errorApprovalProcesses,
    isLoading: isLoadingApprovalProcesses,
  } = useQuery(
    ["users", userToken, "approval-processes"],
    async () => {
      if (!userToken) {
        return;
      }

      return await approvalProcessApi.searchApprovalProcesses({
        userToken,
      });
    },
    {
      enabled: !!userToken,
    },
  );

  return {
    approvalProcesses,
    errorApprovalProcesses,
    isLoadingApprovalProcesses,
  };
};
