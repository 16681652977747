import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  lists: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  text: css({
    color: theme.colors.gray[700],
  }),
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.5rem",
    fontWeight: 600,
  }),
};
