import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { css } from "@styled-system/css";

import { Box, Flex } from "@toolkit";

import { StContainedButton, StTextButton } from "@shared/buttons";
import { Input } from "@shared/inputs";
import { CurrencyInput } from "@shared/inputs/CurrencyInput";

import { Form, useForm } from "@components/shared/form";

import { useAdvancedExpensesContext } from "../AdvancedExpenses.context";
import { AdvancedExpenseDateRangeInput } from "./DateRangeInput";
import { SuccessMessage } from "./SuccessMessage";
import validationSchema from "./validationSchema";

const styles = {
  submitBtn: css({
    px: [null, "1.5rem"]
  })
};

export const AdvancedExpenseRequestForm = () => {
  const context = useForm({
    onSubmit: async (data: FieldValues) => {
      handleCreateAdvancedExpense(data);
      setIsSubmitted(true);
    },
    schema: validationSchema,
    mode: "all"
  });
  const { register, errors, control } = context;

  const {
    handleCreateAdvancedExpense,
    handleCloseForm
  } = useAdvancedExpensesContext();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  if (isSubmitted) {
    return <SuccessMessage handleClose={handleCloseForm} />;
  }

  return (
    <Box px="4rem">
      <Form context={context}>
        <Box flex={1} pt="2rem">
          <Box>
            <Input
              name="name"
              id="name"
              ref={register}
              label="Nome do adiantamento"
              error={Boolean(errors.name)}
              errorMessage={errors.name && errors.name.message}
            />
          </Box>
          <Box mt="1.5rem">
            <Controller
              as={CurrencyInput}
              name="value"
              control={control}
              label="Valor (R$)"
              error={Boolean(errors.value)}
              errorMessage={errors.value && errors.value.message}
            />
          </Box>
          <Flex mt="1.5rem">
            <AdvancedExpenseDateRangeInput />
          </Flex>
          <Box mt="1.5rem">
            <Input
              name="justification"
              id="justification"
              ref={register}
              label="Justificativa"
              rows={6}
              rowsMax={6}
              multiline={true}
              error={Boolean(errors.justification)}
              errorMessage={
                errors.justification && errors.justification.message
              }
            />
          </Box>
        </Box>
        <Flex justifyContent="center" alignItems="center" my="2rem">
          <StContainedButton
            type="submit"
            isFullWidth={true}
            css={styles.submitBtn}
          >
            Solicitar
          </StContainedButton>
          <Box width="100%" ml="1rem">
            <StTextButton onClick={handleCloseForm}>Cancelar</StTextButton>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};
