import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PhotoGallery } from "~/apps/shared/components/photo-gallery/photo-gallery";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

type Props = {
  expanded?: boolean;
  toggleView: () => void;
};

export const HotelResultPictures: React.FC<Props> = ({
  expanded = false,
  toggleView,
}) => {
  const { hotelInfo, loading } = useHotelResult();
  const { width: windowWidth } = useWindowSize();

  const isMobile = useMemo(() => windowWidth <= 640, [windowWidth]);

  if (isMobile) {
    return (
      <div css={styles.root}>
        {loading ? (
          <Skeleton height="322px" width="100%" />
        ) : hotelInfo ? (
          <div css={styles.gallery.root}>
            <PhotoGallery
              autoPlay
              items={hotelInfo.pictures.map((picture) => ({
                original: picture,
                thumbnail: picture,
              }))}
              showNav={false}
              showPlayButton={false}
              showThumbnails={false}
            />
            <span css={styles.gallery.count}>
              {hotelInfo.pictures.length > 1
                ? `${hotelInfo.pictures.length} fotos`
                : `${hotelInfo.pictures.length} foto`}
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div css={styles.root}>
      {loading ? (
        <Skeleton height="322px" width="100%" />
      ) : hotelInfo ? (
        <>
          <div css={styles.container({ expanded })}>
            {!expanded ? (
              <>
                <div css={styles.left.root}>
                  <img
                    alt={hotelInfo.name}
                    css={styles.left.picture}
                    src={hotelInfo.pictures[0]}
                  />
                </div>
                <div
                  css={styles.right.root({
                    expanded,
                    length: hotelInfo.pictures.slice(1, 5).length,
                  })}
                >
                  {hotelInfo.pictures.slice(1, 5).map((picture, index) => (
                    <img
                      alt={hotelInfo.name}
                      css={styles.right.picture}
                      height={153}
                      key={index}
                      src={picture}
                      width={254}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
          {expanded ? (
            <div css={styles.container({ expanded: true })}>
              {hotelInfo.pictures.map((picture, index) => (
                <img
                  alt={hotelInfo.name}
                  css={styles.picture}
                  key={index}
                  src={picture}
                />
              ))}
            </div>
          ) : null}
          {!expanded ? (
            <button css={styles.button.root} onClick={toggleView}>
              <Icon size={16} use="grid" />
              <span>Mostrar todas as fotos</span>
            </button>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
