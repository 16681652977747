import React from "react";

import DoneIcon from "@material-ui/icons/DoneAllRounded";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  icon: css({
    color: "success",
    fontSize: [70, 80]
  })
};

const ConfirmedMessage = () => {
  return (
    <Box height={[500]} width="100%" textAlign="left">
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Flex alignItems="center">
          <Box mr="medium">
            <DoneIcon css={styles.icon} />
          </Box>
          <Box maxWidth={500}>
            <Flex flexDirection="column">
              <Text
                fontSize={[24, 30]}
                color={defaultTheme.subTextColor}
                fontWeight="bold"
                mb="small"
              >
                Reserva confirmada!
              </Text>
              <Text
                fontSize={[1, 18]}
                color={defaultTheme.subTextColor}
                lineHeight={["16px", "21px"]}
              >
                Obrigado, um e-mail será enviado para o(s) viajante(s)
                notificando a confirmação.
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export { ConfirmedMessage };
