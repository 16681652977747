import React from "react";

import { darken } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";
import { Flex } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { ProjectItem } from "@models/projects.model";
import { UserModel } from "@models/user.model";

import { Autocomplete } from "@shared/autocomplete/AutocompleteAsync";
import DrawerHeader from "@shared/DrawerHeader";
import { Input } from "@shared/inputs";
import { Column } from "@shared/layout/Column";
import { Row } from "@shared/layout/Row";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { Form, useForm } from "@components/shared/form";

import * as groupsService from "../projects.service";
import { ProjectForm } from "../projects.types";
import { PROJECT_FORM_SCHEMA } from "./schema";

const styles = {
  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "4.25rem",
    backgroundColor: theme.colors.background.gray,
    paddingLeft: "0.5rem",
    fontSize: "1.125rem",
  }),
  form: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  }),
  body: css({
    height: "100%",
    overflowY: "auto",
  }),
  input: {
    label: css({
      fontSize: "1rem",
      fontWeight: 600,
      color: theme.colors.gray[700],
      paddingBottom: "0.5rem",
      marginBottom: "0.5rem",
    }),
    outline: css({
      paddingLeft: "0 !important",
    }),
    container: css({
      [".MuiInputBase-root"]: {
        borderRadius: "0.5rem",
      },
      [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
        borderColor: `${theme.colors.gray[500]} !important`,
        color: theme.colors.gray[500],
      },
    }),
  },
  emptyUsers: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: theme.colors.gray[400],
  }),
  userItem: css({
    display: "flex",
    gap: "1.25rem",
    alignItems: "center",
    width: "fit-content",
    marginTop: "1rem",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.colors.background.gray,
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
  }),
  icon: {
    root: css({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "1.25rem",
      height: "1.25rem",
      backgroundColor: theme.colors.gray[500],
      borderRadius: "0.25rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: darken(theme.colors.gray[500], 0.5),
        transition: "200ms",
      },
    }),
    svg: css({
      color: theme.colors.white,
      fontSize: "1.125rem",
    }),
  },
  buttonsDiv: css({
    display: "flex",
    gap: "1.5rem",
    alignSelf: "flex-end",
  }),
  saveButton: css({
    display: "flex",
    justifyContent: "center",
    width: "8.5rem",
  }),
};

export interface IProjectsFormProps {
  selectedProject: ProjectItem | null;
  onSubmit: (formData: ProjectForm) => void;
  submitting: boolean;
  handleClose: () => void;
}

export const ProjectsForm: React.FC<IProjectsFormProps> = ({
  selectedProject,
  submitting,
  onSubmit,
  handleClose,
}) => {
  const context = useForm<ProjectForm>({
    schema: PROJECT_FORM_SCHEMA,
    onSubmit: onSubmit,
    defaultValues: {
      name: selectedProject?.name || "",
      users: selectedProject?.users || [],
      active: selectedProject?.active || true,
      clientToken: selectedProject?.clientToken || "",
    },
  });

  const { setValue, watch, errors } = context;
  const users = watch("users") as UserModel[];

  const handleSelectUser = (user: any) => {
    const isUserAlreadyAdded = !!users.find(
      (item) => item.userToken === user.userToken,
    );

    if (!isUserAlreadyAdded) {
      const updatedUsers = users.concat(user);
      setValue("users", updatedUsers);
    }
  };

  const handleRemoveUser = (user: any) => () => {
    const updatedUsers = users.filter(
      (item) => item.userToken !== user.userToken,
    );
    setValue("users", updatedUsers);
  };

  return (
    <Flex flexDirection="column" style={{ height: "100%" }}>
      <DrawerHeader
        title={selectedProject ? "Editando projeto" : "Novo projeto"}
        handleClose={handleClose}
        headerStyles={styles.header}
      />
      <Form context={context} css={styles.form}>
        <Column css={styles.body}>
          <Column>
            <label css={styles.input.label}>Nome do projeto</label>
            <Form.Field
              name="name"
              as={
                <Input
                  placeholder="Nome do projeto"
                  css={styles.input.container}
                  error={!!errors?.name}
                />
              }
            />
          </Column>
          <div style={{ padding: "1rem" }} />
          <Column>
            <label css={styles.input.label}>Quem faz parte do projeto?</label>
            <Form.Field
              name="users"
              as={
                <Autocomplete
                  inputProps={{
                    placeholder: "Pesquise por nome ou e-mail",
                    notchedOutlineStyle: styles.input.outline,
                    css: styles.input.container,
                  }}
                  handleSelect={handleSelectUser}
                  fetchItems={groupsService.searchUsers}
                  clearOnSelect={true}
                  menuStyle={{
                    minWidth: "none",
                    borderRadius: "0.5rem",
                  }}
                />
              }
            />
          </Column>
          <div style={{ padding: "1rem" }} />
          <Column>
            <label css={styles.input.label}>Membros</label>
            {users.length > 0 ? (
              <Flex style={{ gap: "0.5rem", flexWrap: "wrap" }}>
                {users.map((user) => (
                  <Row key={user.userToken} css={styles.userItem}>
                    <p>{user.fullName}</p>
                    <div
                      css={styles.icon.root}
                      onClick={handleRemoveUser(user)}
                    >
                      <CloseIcon css={styles.icon.svg} />
                    </div>
                  </Row>
                ))}
              </Flex>
            ) : (
              <p css={styles.emptyUsers}>Sem usuários atribuídos</p>
            )}
          </Column>
        </Column>
        <Row css={styles.buttonsDiv}>
          <ContainedButton
            type="submit"
            disabled={submitting}
            loading={submitting}
            css={styles.saveButton}
          >
            Salvar
          </ContainedButton>
          <OutlinedButton
            type="button"
            onClick={handleClose}
            disabled={submitting}
          >
            Cancelar
          </OutlinedButton>
        </Row>
      </Form>
    </Flex>
  );
};
