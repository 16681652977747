import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

export const styles = {
  titleContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "3.125rem",
    padding: "1.875rem 0",
    backgroundColor: defaultTheme.primaryColor,
    color: "white",
  }),
  title: css({
    padding: "1rem 2rem",
  }),
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    padding: "2rem",
  }),
  checkboxLabel: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.25rem",
    fontSize: "0.875rem",
    cursor: "pointer",
  }),
};
