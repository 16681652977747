import React, { useCallback } from "react";
import { useMutation } from "react-query";

import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";
import * as yup from "yup";

import { useEvents } from "~/components/events/events.context";
import { AltertBox, AltertTypeEnum } from "~/components/shared/altert-box";
import { Checkbox } from "~/components/shared/checkbox";
import { Form, useForm } from "~/components/shared/form";
import { Input } from "~/components/shared/inputs";
import { Spacing } from "~/components/shared/layout";

import { useEvent } from "../../../event.context";
import { styles } from "./styles";

const validationSchema = yup.object({
  event: yup.object({
    token: yup.string().defined()
  }),
  update: yup.object({
    on_top: yup.boolean().defined(),
    text: yup
      .string()
      .required("Por favor, digite uma mensagem para a nova atualização.")
  })
});

export type CreateUpdateForm = yup.InferType<typeof validationSchema>;

type Props = Omit<React.ComponentPropsWithoutRef<typeof Dialog>, "onClose"> & {
  event: GetUserCompanyEventDto;
  onClose: () => void;
};

export const EventOverviewUpdatesCreateDialog: React.VFC<Props> = ({
  event,
  onClose,
  ...props
}) => {
  const { invalidateUpdates } = useEvent();
  const { createEventUpdate } = useEvents();

  const {
    isLoading: isLoadingCreateEventUpdate,
    mutateAsync: createEventUpdateMutation
  } = useMutation({
    mutationFn: async (form: CreateUpdateForm) => {
      const result = await createEventUpdate(form);

      if (!result) {
        return;
      }

      await invalidateUpdates();

      return result;
    }
  });

  const onSubmit = useCallback(
    async (values: CreateUpdateForm) => {
      await createEventUpdateMutation(values);

      onClose();
    },
    [createEventUpdateMutation, onClose]
  );

  const form = useForm({
    defaultValues: {
      event: {
        token: event.token
      },
      update: {
        on_top: false,
        text: ""
      }
    } as CreateUpdateForm,
    onSubmit,
    schema: validationSchema
  });

  return (
    <Dialog
      classes={{
        paper: styles.dialog.paper
      }}
      {...props}
    >
      <div css={styles.header}>
        <Text color={theme.colors.gray[4]} fontSize={24} fontWeight="bold">
          Nova Atualização
        </Text>
        <Text color={theme.colors.gray[3]} fontSize={14} lineHeight={1.25}>
          Informe os participantes dos últimos detalhes com atualizações sobre o
          evento. Um email será enviado a todos os participantes.
        </Text>
      </div>
      <Form context={form}>
        <DialogContent
          classes={{
            root: styles.dialog.content
          }}
        >
          <Form.Field
            as={
              <Input placeholder="Digite uma mensagem para a nova atualização..." />
            }
            name="update.text"
          />
        </DialogContent>
        <AltertBox
          containerStyle={{
            alignSelf: "center",
            margin: "32px 32px 0 32px"
          }}
          text="Uma vez publicada a atualização, ela não poderá ser editada nem excluída! Revise seu texto."
          textStyle={{
            lineHeight: 1.25
          }}
          type={AltertTypeEnum.INFO}
        />
        <DialogActions
          classes={{
            root: styles.dialog.actions
          }}
        >
          <Flex alignItems="center">
            <Form.Field
              name="update.on_top"
              render={({ onChange, value }) => (
                <>
                  <Checkbox
                    checked={Boolean(value)}
                    onChange={e => {
                      onChange(e.target.checked as any);
                    }}
                  />
                  <Text fontSize={14}>Fixar essa atualização no topo?</Text>
                </>
              )}
            ></Form.Field>
          </Flex>
          <Flex alignItems="center">
            <Button
              color="cancel"
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              Cancelar
            </Button>
            <Spacing direction="horizontal" space="8px" />
            <Button
              disabled={
                !form.formState.isDirty ||
                form.formState.isSubmitting ||
                !form.formState.isValid ||
                isLoadingCreateEventUpdate
              }
              type="submit"
            >
              Publicar
            </Button>
          </Flex>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
