import React, { useCallback } from "react";

import { useFormContext } from "~/apps/shared/components/form/form";
import { DEFAULT_CAPABILITIES } from "~/apps/shared/constants/capabilities.constants";
import { Capabilities } from "~/apps/shared/constants/enums";

import { styles } from "./styles";
import { UserFormPermissionsSelect } from "./user-form-permissions-select/user-form-permissions-select";

export const UserFormPermissions: React.FC = () => {
  const form = useFormContext();

  const handleCapabilitiesChange = useCallback(
    (capabilities: Capabilities) => {
      form.setValue("capabilities", capabilities);
    },
    [form],
  );

  const handleRoleChange = useCallback(
    (role: string) => {
      form.setValue("role", role);

      const defaultCapabilities =
        DEFAULT_CAPABILITIES[
          role.replace("ROLE_", "") as keyof typeof DEFAULT_CAPABILITIES
        ];

      form.setValue("capabilities", defaultCapabilities);
    },
    [form],
  );

  const { capabilities, role } = form.watch(["capabilities", "role"]);

  return (
    <div css={styles.root}>
      <span css={styles.title}>Permissões:</span>
      <UserFormPermissionsSelect
        capabilities={capabilities}
        onCapabilitiesChange={handleCapabilitiesChange}
        onRoleChange={handleRoleChange}
        role={role}
      />
    </div>
  );
};
