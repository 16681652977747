export const forceFileDownload = (url: string, fileName = "") => {
  const anchorElement = document.createElement("a");

  anchorElement.href = url;

  anchorElement.setAttribute("download", fileName);

  document.body.append(anchorElement);

  anchorElement.click();
  anchorElement.remove();
};
