import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { borderRadius } from "polished";

import { theme } from "@skin/v2";

export const styles = {
  header: css({
    height: "4.25rem",
    backgroundColor: theme.colors.background.gray,
    paddingLeft: "0.5rem",
    fontSize: "1.125rem",
  }),
  form: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  }),
  body: css({
    height: "100%",
  }),
  inputDiv: css({
    paddingBottom: "1rem",
  }),
  inputLabel: css({
    fontSize: 16,
    fontWeight: 600,
    color: theme.colors.gray[700],
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  }),
  buttonsDiv: css({
    display: "flex",
    gap: "1.5rem",
    alignSelf: "flex-end",
  }),
  button: css({
    padding: ".5rem 1rem",
    ["&:first-of-type"]: {
      padding: ".5rem 1.7rem",
    },
  }),
  userItem: css({
    display: "flex",
    gap: "1.25rem",
    alignItems: "center",
    width: "fit-content",
    marginTop: "1rem",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.colors.background.gray,
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
  }),
  iconContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1.25rem",
    height: "1.25rem",
    backgroundColor: theme.colors.gray[500],
    borderRadius: "0.25rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: darken(theme.colors.gray[500], 0.5),
      transition: "200ms",
    },
  }),
  icon: css({
    color: theme.colors.white,
    fontSize: "1.125rem",
  }),
  inputOutlineStyle: css({
    paddingLeft: "0 !important",
  }),
  emptyUsers: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: theme.colors.gray[400],
  }),
  saveButton: css({
    display: "flex",
    justifyContent: "center",
    width: "8.5rem",
  }),
  input: {
    container: css({
      backgroundColor: theme.colors.background.gray,
      borderRadius: "0.5rem",
      width: "100%",
      padding: "0.5rem 1rem",
    }),
  },
};
