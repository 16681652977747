import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  checkbox: {
    group: {
      item: {
        label: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          fontFamily: theme.fonts.inter,
          lineHeight: "19.36px",
          marginLeft: "8px",
          transform: "translateY(-1px)",
          "@media (max-width: 1440px)": {
            width: "275.69px",
          },
          "@media (max-width: 1024px)": {
            width: "unset",
          },
        }),
        root: css({
          alignItems: "flex-start",
        }),
      },
      root: css({
        width: "fit-content",
      }),
    },
  },
  root: css({
    color: theme.colors.gray[300],
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  title: {
    label: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
  },
};
