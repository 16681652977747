import { useEffect } from "react";

export function useFocusElementOnMount(elementId) {
  useEffect(() => {
    const element = document.getElementById(elementId);

    if (element) {
      element.focus();
    }
  }, []);
}
