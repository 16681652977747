import React from "react";

import {
  Dialog,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { Icons } from "../icon/icon";
import { styles } from "./styles";

export const AlertDialog: React.FC<{
  confirmLabel?: string;
  icon?: Icons;
  isLoading?: boolean;
  message: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title: React.ReactNode;
}> = ({
  confirmLabel,
  icon,
  isLoading,
  message,
  onClose,
  onConfirm,
  open,
  title,
}) => {
  return (
    <Dialog
      aria-labelledby={typeof title === "string" ? title : undefined}
      aria-describedby={typeof message === "string" ? message : undefined}
      css={styles.root}
      onClose={onClose}
      open={open}
    >
      <DialogHeader
        color={theme.colors.pink[700]}
        icon={icon ? icon : "trash"}
        onClose={onClose}
      />
      <DialogContent>
        <DialogTitle>{title}</DialogTitle>
        {typeof message === "string" ? (
          <span css={styles.message}>{message}</span>
        ) : (
          message
        )}
      </DialogContent>
      <DialogFooter css={styles.footer.root}>
        <Button disabled={isLoading} fill="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button disabled={isLoading} onClick={onConfirm} variant="pink">
          {confirmLabel}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
