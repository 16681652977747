import React, { createContext, useCallback, useEffect, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import { ClientBoss } from "../models/client.model";
import { useApplication } from "./application.context";
import * as clientService from "./client.service";
import { useUser } from "./user.context";

interface Actions {
  fetchClientBosses: () => Promise<void>;
}

type State = {
  errorOnFetchClientBosses: Error | null;
  isLoadingClientBosses: boolean;
  clientBosses: ClientBoss[] | null;
};

const initialState: State = {
  errorOnFetchClientBosses: null,
  isLoadingClientBosses: false,
  clientBosses: null,
};

type ContextProps = Actions & State;

export const ClientContext = createContext<ContextProps>({
  ...initialState,
  fetchClientBosses: async () => {
    return;
  },
});

export const ClientProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchClientBosses = useCallback(async () => {
    if (!user) {
      return;
    }

    const clientToken = user.getClientToken();

    if (!clientToken) {
      logger.error("clientToken not found");

      return;
    }

    setState((prev) => ({
      ...prev,
      errorOnFetchClientBosses: null,
      isLoadingClientBosses: true,
    }));

    const clientBossesResponse = await clientService.getClientBosses(
      clientToken,
    );

    if (clientBossesResponse.error) {
      const error = clientBossesResponse.error;

      setState((prev) => ({
        ...prev,
        errorOnFetchClientBosses: error,
        isLoadingClientBosses: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!clientBossesResponse.data) {
      const error = ERROR.UNEXPECTED;

      setState((prev) => ({
        ...prev,
        errorOnFetchClientBosses: error,
        isLoadingClientBosses: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      clientBosses: clientBossesResponse.data!,
      isLoadingClientBosses: false,
    }));
  }, [user]);

  useEffect(() => {
    // void fetchClientBosses();
  }, [fetchClientBosses]);

  return (
    <ClientContext.Provider
      value={{
        ...state,
        fetchClientBosses,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = useContextFactory("ClientContext", ClientContext);
