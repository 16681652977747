import userConstants, {
  userConstants as authConstants
} from "../constants/user.constant";
import { getUserFromLocalStorage } from "../helpers/user.helper";

const initialUser = getUserFromLocalStorage();
const emptyState = {
  loginError: {}
};
const initialState = initialUser
  ? { loggedIn: true, loginError: {}, loggingIn: false, ...initialUser }
  : emptyState;

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loginError: {}
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        ...action.user,
        loginError: {}
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginError: action.error
      };
    case authConstants.LOGOUT:
      return emptyState;
    case userConstants.EDIT_USERNAME:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        sendSms: action.payload.sendSms,
        billingProfile: action.payload.billingProfile
      };
    default:
      return state;
  }
}

export default authentication;
