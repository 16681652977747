import * as countryRestrictionApi from "~/apps/corporate/apis/country-restriction.api";
import {
  CreateCountryRestrictionResponse,
  GetCountryRestrictionResponse,
  ListCountryRestrictionsResponse,
  UpdateCountryRestrictionResponse,
} from "~/apps/corporate/dtos/country-restriction.dto";
import { HttpResponse } from "~/apps/shared/types";

import { CountryRestrictionFormData } from "./restriction-drawer/restriction-form/restriction-form.types";

export async function listCountryRestrictions(): Promise<
  HttpResponse<ListCountryRestrictionsResponse>
> {
  const result: HttpResponse<ListCountryRestrictionsResponse, any> = {};

  try {
    const response = await countryRestrictionApi.listCountryRestrictions();

    result.data = response;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      result.error = {
        title: "Erro",
        description: "Não foi possível listar a restrições de viagem",
      };
    } else if (error.request) {
      result.error = {
        title: "Erro",
        description: "Problemas de conectividade",
      };
    } else {
      result.error = { title: "title", description: "Erro Inesperado" };
    }
  }

  return result;
}

export async function createCountryRestriction(
  data: CountryRestrictionFormData,
): Promise<HttpResponse<CreateCountryRestrictionResponse>> {
  const result: HttpResponse<CreateCountryRestrictionResponse, any> = {};

  try {
    const response = await countryRestrictionApi.createCountryRestriction({
      country_name: data.country.label,
      country_iso_code: data.country.value,
      message_content: JSON.stringify(data.message),
      restriction_type: data.restrictionType,
    });

    result.data = response;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      result.error = {
        title: "Erro",
        description: "Não foi salvar a restrição de viagem",
      };
    } else if (error.request) {
      result.error = {
        title: "Erro",
        description: "Problemas de conectividade",
      };
    } else {
      result.error = { title: "title", description: "Erro Inesperado" };
    }
  }

  return result;
}

export async function updateCountryRestriction(
  data: CountryRestrictionFormData,
): Promise<HttpResponse<UpdateCountryRestrictionResponse>> {
  const result: HttpResponse<UpdateCountryRestrictionResponse, any> = {};

  try {
    const response = await countryRestrictionApi.updateCountryRestriction({
      country_restriction_token: data.countryRestrictionToken!,
      country_name: data.country.label,
      country_iso_code: data.country.value,
      message_content: JSON.stringify(data.message),
      restriction_type: data.restrictionType,
    });

    result.data = response;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      result.error = {
        title: "Erro",
        description: "Não foi possível salvar a restrição de viagem",
      };
    } else if (error.request) {
      result.error = {
        title: "Erro",
        description: "Problemas de conectividade",
      };
    } else {
      result.error = { title: "title", description: "Erro Inesperado" };
    }
  }

  return result;
}

export async function getCountryRestriction(
  countryRestrictionToken: string,
): Promise<HttpResponse<GetCountryRestrictionResponse>> {
  const result: HttpResponse<GetCountryRestrictionResponse, any> = {};

  try {
    const response = await countryRestrictionApi.getCountryRestriction({
      country_restriction_token: countryRestrictionToken,
    });

    result.data = response;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      result.error = {
        title: "Erro",
        description: "Não foi possível carregar a restrição de viagem",
      };
    } else if (error.request) {
      result.error = {
        title: "Erro",
        description: "Problemas de conectividade",
      };
    } else {
      result.error = { title: "title", description: "Erro Inesperado" };
    }
  }

  return result;
}

export async function deleteCountryRestriction(
  countryRestrictionToken: string,
): Promise<HttpResponse<GetCountryRestrictionResponse>> {
  const result: HttpResponse<GetCountryRestrictionResponse, any> = {};

  try {
    const response = await countryRestrictionApi.deleteCountryRestriction({
      country_restriction_token: countryRestrictionToken,
    });

    result.data = response;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      result.error = {
        title: "Erro",
        description: "Não foi possível excluir a restrição de viagem",
      };
    } else if (error.request) {
      result.error = {
        title: "Erro",
        description: "Problemas de conectividade",
      };
    } else {
      result.error = { title: "title", description: "Erro Inesperado" };
    }
  }

  return result;
}
