import React, { useState, FocusEvent, useMemo } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId
} from "react-places-autocomplete";

import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clxs from "classnames";
import { css } from "emotion";

import * as googleHelper from "@helpers/google.helper";

import { GenericJSXElement } from "~/types";

import { Spinner } from "../Autocomplete/Spinner";
import { Input } from "../Input";

const styles = {
  inputDiv: css({
    width: "100%"
  }),
  suggestionsPaper: css({
    position: "absolute",
    width: "100%",
    top: "46px",
    left: "0px",
    display: "table",
    flexGrow: 1,
    zIndex: 10
  }),
  logoContainer: css({
    padding: "4px"
  }),
  logoImg: css({
    display: "inline-block",
    width: "150px"
  }),
  itemDiv: css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  })
};

interface State {
  address: string;
  isSelecting: boolean;
  error: string;
}

interface Classes {
  paper: string;
}

// https://developers.google.com/maps/documentation/javascript/supported_types#table3
type LocationType =
  | "(regions)"
  | "(cities)"
  | "geocode"
  | "address"
  | "establishment"
  | "locality"
  | "sublocality"
  | "postal_code"
  | "country"
  | "administrative_area_level_1"
  | "administrative_area_level_2"
  | "administrative_area_level_3"
  | "street_address"
  | "street_number"
  | "airport"
  | "neighborhood"
  | "point_of_interest"
  | "car_rental";

interface Props {
  inputProps?: any;
  handleSelect: (
    addressObject: {
      placeId: string;
      formattedAddress: string;
      city: string;
      state: string;
      country: string;
      latitude: number;
      longitude: number;
    } | null
  ) => void;
  value?: string;
  icon?: GenericJSXElement;
  handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  locationType?: LocationType | LocationType[];
  classes: Classes;
}

const GoogleLocationAutocomplete = ({
  inputProps,
  handleSelect,
  icon,
  value,
  locationType,
  classes
}: Props) => {
  const [state, setState] = useState<State>({
    address: "",
    isSelecting: false,
    error: ""
  });
  const setSafeState = (newState: Partial<State>) => {
    setState(prevState => Object.assign({}, prevState, newState));
  };

  const handleChange = (address: string) => {
    if (address.length === 0) {
      handleSelect(null);
    }

    if (state.error) {
      setSafeState({
        address,
        error: ""
      });
    } else {
      setSafeState({ address });
    }
  };

  const innerHandleSelect = (address: string, placeId: string) => {
    setSafeState({ address, isSelecting: true });

    geocodeByPlaceId(placeId)
      .then(results => {
        const result = results[0];
        const { place_id, formatted_address } = result;
        const details = googleHelper.extractDetails(result);

        setSafeState({ address: formatted_address });
        return {
          placeId: place_id,
          formattedAddress: formatted_address,
          ...details
        };
      })
      .then(handleSelect)
      .then(() => setSafeState({ isSelecting: false }))
      .catch(err => {
        // Resolvi deixar o console para erros futuros
        console.error("Autocomplete error", err);
        setSafeState({ error: "ZERO_RESULTS" });
      });
  };

  const handleError = (status: string) => {
    switch (status) {
      case "ZERO_RESULTS":
        setSafeState({ error: "ZERO_RESULTS" });
        break;

      default:
        throw { title: "Erro desconhecido", description: status };
    }
  };

  const renderError = () => {
    return (
      <MenuItem disabled={true}>
        <div>
          <span style={{ fontWeight: 300 }}>Nenhum local encontrado com: </span>
          <strong style={{ fontWeight: 500 }}>{state.address}</strong>
        </div>
      </MenuItem>
    );
  };

  const renderLogo = () => {
    return (
      <MenuItem
        className={`d-flex justify-content-end ${styles.logoContainer}`}
        disabled={true}
      >
        <img
          className={styles.logoImg}
          src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
        />
      </MenuItem>
    );
  };

  const renderSuggestion = (
    suggestion: any,
    index: any,
    getSuggestionItemProps: any
  ) => {
    const matches = match(suggestion.description, state.address);
    const parts = parse(suggestion.description, matches);

    return (
      <MenuItem
        key={index}
        selected={suggestion.active}
        component="div"
        {...getSuggestionItemProps(suggestion)}
      >
        <div className={styles.itemDiv}>
          {parts.map((part, i) => {
            return part.highlight ? (
              <span key={String(i)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(i)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  const { error } = state;
  const autocompleteValue = state.address ? state.address : value;
  const _locationType = locationType
    ? ([] as LocationType[]).concat(locationType)
    : undefined;

  return (
    <PlacesAutocomplete
      value={autocompleteValue}
      onError={handleError}
      onChange={handleChange}
      onSelect={innerHandleSelect}
      debounce={500}
      searchOptions={{
        types: _locationType
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative", width: "100%" }}>
          <Input
            {...getInputProps({
              ...inputProps,
              startIcon: loading ? Spinner : icon
            })}
            value={autocompleteValue}
          />
          <Paper
            square={true}
            className={clxs(classes.paper, styles.suggestionsPaper)}
          >
            {error
              ? renderError()
              : suggestions.map((suggestion, index) => {
                  return renderSuggestion(
                    suggestion,
                    index,
                    getSuggestionItemProps
                  );
                })}
            {!error && suggestions.length > 0 ? renderLogo() : null}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

GoogleLocationAutocomplete.defaultProps = {
  classes: {
    paper: ""
  }
} as Partial<Props>;

export { GoogleLocationAutocomplete };
