import { useEffect, useState } from "react";

import { globalHistory } from "@reach/router";

export const useLocation = () => {
  const [location, setLocation] = useState({
    navigate: globalHistory.navigate,
    location: globalHistory.location,
  });

  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const { location } = params;

      setLocation((prev) => Object.assign({}, prev, { location }));
    });

    return () => {
      removeListener();
    };
  }, []);

  return location;
};
