import React, { useCallback, useMemo } from "react";

import { RecalculateCarOfferRequest } from "~/apps/corporate/dtos/car.dto";
import { CarOffer } from "~/apps/corporate/models/car.model";
import { UserSearch } from "~/apps/corporate/models/user.model";

import {
  Additional,
  AdditionalAdditionalDrivers,
  AdditionalDialog,
  AdditionalQuantity,
} from "../additional/additional";
import { CarAdditionalPresentation } from "../car-result.types";
import { MovidaAdditionalsHelper } from "./movida-additionals.helper";
import { styles } from "./styles";

type Props = {
  additionalDrivers: UserSearch[];
  car: CarOffer;
  handleChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  handleRecalculateCarOffer: (
    data: RecalculateCarOfferRequest,
  ) => Promise<void>;
  handleSearchAdditionalDrivers: (
    search: string,
  ) => Promise<UserSearch[] | null>;
  loadingRecalculate: boolean;
};

export const MovidaAdditionals: React.FC<Props> = ({
  additionalDrivers,
  car,
  handleChangeAdditionalDrivers,
  handleRecalculateCarOffer,
  handleSearchAdditionalDrivers,
  loadingRecalculate,
}) => {
  const movidaAdditionals = useMemo(
    () =>
      new MovidaAdditionalsHelper({
        car,
      }),
    [car],
  );

  const recalculateCarOffer = useCallback(
    async (additional: CarAdditionalPresentation) => {
      if (!car.supplierInfo || loadingRecalculate) {
        return;
      }

      const selectedAdditionals = movidaAdditionals.getSelectedAdditionals();

      const additionalsToRecalculate = movidaAdditionals.buildAdditionalsToRecalculate(
        additional,
        selectedAdditionals,
      );

      await handleRecalculateCarOffer({
        additionals: additionalsToRecalculate,
        supplier_offer_id: car.supplierInfo.id,
      });
    },
    [handleRecalculateCarOffer, movidaAdditionals, loadingRecalculate],
  );

  return (
    <div css={styles.root}>
      <MovidaAdditionalsProtections
        disabled={loadingRecalculate}
        formattedProtections={movidaAdditionals.formatProtectionAdditionals()}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
      />
      <MovidaAdditionalsServices
        additionalDrivers={additionalDrivers}
        disabled={loadingRecalculate}
        formattedServices={movidaAdditionals.formatServiceAdditionals()}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
        handleChangeAdditionalDrivers={handleChangeAdditionalDrivers}
        handleSearchAdditionalDrivers={handleSearchAdditionalDrivers}
      />
      <MovidaAdditionalsAccessories
        disabled={loadingRecalculate}
        formattedAccessories={movidaAdditionals.formatAccessoryAdditionals()}
        handleChange={async (additional, checked) => {
          await recalculateCarOffer({
            ...additional,
            selected: checked,
          });
        }}
      />
    </div>
  );
};

type MovidaAdditionalsAccessoriesProps = {
  disabled: boolean;
  formattedAccessories: {
    additionals?: CarAdditionalPresentation[] | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
};

const MovidaAdditionalsAccessories: React.FC<MovidaAdditionalsAccessoriesProps> = ({
  formattedAccessories,
  disabled,
  handleChange,
}) => {
  if (Object.keys(formattedAccessories).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Acessórios</span>
        <span css={styles.group.heading.subtitle}>
          Complete sua reserva com acessórios que deixam sua viagem mais fácil e
          segura.
        </span>
      </div>
      {formattedAccessories.additionals ? (
        <div css={styles.group.accessories.root}>
          {formattedAccessories.additionals.map((additional) => (
            <AdditionalQuantity
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
              quantity={additional.quantity}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

type MovidaAdditionalsProtectionsProps = {
  disabled: boolean;
  formattedProtections: {
    additionals?: CarAdditionalPresentation[] | undefined;
    packages?: CarAdditionalPresentation[] | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
};

const MovidaAdditionalsProtections: React.FC<MovidaAdditionalsProtectionsProps> = ({
  disabled,
  formattedProtections,
  handleChange,
}) => {
  if (Object.keys(formattedProtections).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Proteção</span>
        <span css={styles.group.heading.subtitle}>
          Escolha sua proteção e itens
        </span>
      </div>
      {formattedProtections.packages ? (
        <div css={styles.group.protections.root}>
          {formattedProtections.packages.map((additional) => (
            <AdditionalDialog
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
            />
          ))}
        </div>
      ) : null}
      {formattedProtections.additionals
        ? formattedProtections.additionals.map((additional) => (
            <Additional
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
            />
          ))
        : null}
    </div>
  );
};

type MovidaAdditionalsServicesProps = {
  additionalDrivers: UserSearch[];
  disabled: boolean;
  formattedServices: {
    additionalDrivers?: CarAdditionalPresentation | undefined;
    additionals?: CarAdditionalPresentation[] | undefined;
  };
  handleChange: (
    additional: CarAdditionalPresentation,
    checked: boolean,
  ) => void;
  handleChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  handleSearchAdditionalDrivers: (
    search: string,
  ) => Promise<UserSearch[] | null>;
};

const MovidaAdditionalsServices: React.FC<MovidaAdditionalsServicesProps> = ({
  additionalDrivers,
  disabled,
  formattedServices,
  handleChange,
  handleChangeAdditionalDrivers,
  handleSearchAdditionalDrivers,
}) => {
  if (Object.keys(formattedServices).length === 0) {
    return null;
  }

  return (
    <div css={styles.group.root}>
      <div css={styles.group.heading.root}>
        <span css={styles.group.heading.title}>Serviços</span>
        <span css={styles.group.heading.subtitle}>
          Adicione serviços e aumente as possibilidades do seu Movida.
        </span>
      </div>
      {formattedServices.additionals
        ? formattedServices.additionals.map((additional) => (
            <Additional
              additional={additional}
              disabled={additional.disabled || disabled}
              key={additional.code}
              onChange={handleChange}
            />
          ))
        : null}
      {formattedServices.additionalDrivers ? (
        <AdditionalAdditionalDrivers
          additional={formattedServices.additionalDrivers}
          disabled={formattedServices.additionalDrivers.disabled || disabled}
          additionalDrivers={additionalDrivers}
          max={3}
          onChange={handleChange}
          onChangeAdditionalDrivers={handleChangeAdditionalDrivers}
          searchAdditionalDrivers={handleSearchAdditionalDrivers}
        />
      ) : null}
    </div>
  );
};
