import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { useUserBillingProfiles } from "~/apps/corporate/hooks/use-user-billing-profiles";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";
import { formatDisplayedPhone } from "~/apps/shared/utils/format-displayed-phone";

import { Button, Switch } from "@toolkit/v2";

import { useClientApprovalProcesses } from "./edit-basic-information.hooks";
import {
  editBasicInformationSchema,
  EditBasicInformationSchema,
} from "./edit-basic-information.schema";
import { styles } from "./styles";

type Props = {
  back: () => void;
};

export const UserProfileDrawerEditBasicInformation: React.FC<Props> = ({
  back,
}) => {
  const { editUserProfile, isLoadingEditUserProfile, user } = useUser();

  const {
    approvalProcesses,
    errorApprovalProcesses,
    isLoadingApprovalProcesses,
  } = useClientApprovalProcesses(user);

  const {
    billingProfiles,
    errorBillingProfiles,
    isLoadingBillingProfiles,
  } = useUserBillingProfiles(user?.getUserToken());

  const form = useForm<EditBasicInformationSchema>({
    defaultValues: {
      approvalProcessToken: null,
      billingProfileToken: null,
      firstName: user ? user.getFirstName() : "",
      lastName: user ? user.getLastName() : "",
      phone: user ? formatDisplayedPhone(user.getPhone()) : null,
      sendSms: user ? user.getSendSms() : null,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await editUserProfile({
        billingProfileToken: values.billingProfileToken,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        sendSms: values.sendSms,
      });

      if (!sucess) {
        return;
      }

      back();
    },
    schema: editBasicInformationSchema,
  });

  const { approvalProcessToken, billingProfileToken } = form.watch([
    "approvalProcessToken",
    "billingProfileToken",
  ]);

  const selectedApprovalProcess = useMemo(() => {
    if (!approvalProcesses || !approvalProcessToken) {
      return undefined;
    }

    const approvalProcess = approvalProcesses.find(
      (approvalProcess) => approvalProcess.token === approvalProcessToken,
    );

    if (!approvalProcess) {
      return undefined;
    }

    return {
      label: approvalProcess.name,
      value: approvalProcess.token,
    };
  }, [approvalProcesses, approvalProcessToken]);

  const selectedBillingProfile = useMemo(() => {
    if (!billingProfiles || !billingProfileToken) {
      return undefined;
    }

    const billingProfile = billingProfiles.find(
      (billingProfile) =>
        billingProfile.billingProfileToken === billingProfileToken,
    );

    if (!billingProfile) {
      return undefined;
    }

    return {
      label: billingProfile.name,
      value: billingProfile.billingProfileToken,
    };
  }, [billingProfiles, billingProfileToken]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Nome*</span>
            <Form.Field
              name="firstName"
              render={({ onChange, value }) => (
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder="Digite o nome..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["firstName"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Sobrenome*</span>
            <Form.Field
              name="lastName"
              render={({ onChange, value }) => (
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder="Digite o sobrenome..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["lastName"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Celular</span>
            <Form.Field
              name="phone"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="cellphone"
                  onChange={onChange}
                  placeholder="Digite o número do celular..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["phone"]?.message}
            </InputErrorMessage>
          </div>
          <Form.Field<EditBasicInformationSchema["sendSms"] | undefined>
            name="sendSms"
            render={({ value }) => (
              <label css={styles.body.switch.root}>
                <Switch
                  active={!!value}
                  onChange={(e) => {
                    form.setValue("sendSms", e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.body.switch.text}>
                  Receber informações por SMS
                </span>
              </label>
            )}
          />
        </div>
        {isLoadingApprovalProcesses ? (
          <div css={styles.body.select.root}>
            <Skeleton height="16px" width="128px" />
            <Skeleton height="48px" width="100%" />
          </div>
        ) : errorApprovalProcesses ? null : approvalProcesses ? (
          <div css={styles.body.select.root}>
            <span css={styles.body.select.label}>
              Processo de aprovação de usuário
            </span>
            <Select
              css={styles.body.select.select}
              id="approvalProcessToken"
              name="approvalProcessToken"
              onChange={({ value }) => {
                form.setValue("approvalProcessToken", value);
              }}
              options={approvalProcesses.map((approvalProcess) => ({
                label: approvalProcess.name,
                value: approvalProcess.token,
              }))}
              placeholder="Escolha um processo de aprovação de usuário..."
              value={selectedApprovalProcess}
            />
            <InputErrorMessage>
              {form.errors["approvalProcessToken"]?.message}
            </InputErrorMessage>
          </div>
        ) : null}
        {isLoadingBillingProfiles ? (
          <div css={styles.body.select.root}>
            <Skeleton height="16px" width="128px" />
            <Skeleton height="48px" width="100%" />
          </div>
        ) : errorBillingProfiles ? null : billingProfiles ? (
          <div css={styles.body.select.root}>
            <span css={styles.body.select.label}>CNPJ</span>
            <Select
              id="billingProfileToken"
              name="billingProfileToken"
              onChange={({ value }) => {
                form.setValue("billingProfileToken", value);
              }}
              options={billingProfiles.map((billingProfile) => ({
                label: billingProfile.name,
                value: billingProfile.billingProfileToken,
              }))}
              placeholder="Escolha um CNPJ..."
              value={selectedBillingProfile}
            />
            <InputErrorMessage>
              {form.errors["billingProfileToken"]?.message}
            </InputErrorMessage>
          </div>
        ) : null}
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={form.formState.isSubmitting || isLoadingEditUserProfile}
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          Cancelar
        </Button>
        <Button
          disabled={form.formState.isSubmitting || isLoadingEditUserProfile}
          variant="pink"
        >
          Salvar
        </Button>
      </div>
    </Form>
  );
};
