import { AxiosResponse } from "axios";

import {
  GetCarLocationAutosuggestResponse,
  CarSearchCreationRequest,
  GetCarsListResponse,
  AddCarOfferToTravelRequest,
  GetCarResponse,
  RecalculateCarOfferResponse,
  RecalculateCarOfferRequest
} from "../dtos/car.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import { CarAutosuggestItem } from "../models/car.model";
import { api } from "./interceptors";

export function getCarLocationAutosuggestions(
  search: string
): Promise<CarAutosuggestItem[]> {
  return api
    .request({
      url: `/booking/autosuggest/car/${search}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<GetCarLocationAutosuggestResponse>) => {
      return response.data.location_list.map(location => ({
        ...location,
        label: location.name
      }));
    });
}

export function createCarSearch(dto: CarSearchCreationRequest): Promise<any> {
  return api
    .request({
      url: "/booking/car",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<any>) => response.data);
}

export function getCarsList(carToken: string): Promise<GetCarsListResponse> {
  return api
    .request({
      url: `/booking/cars/${carToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<GetCarsListResponse>) => response.data);
}

export const addCarOfferToTravel = async (
  travelToken: string,
  dto: AddCarOfferToTravelRequest
) => {
  return await api
    .request<void>({
      data: dto,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/offers/cars`
    })
    .then(response => response.data);
};

export const recalculateCarOffer = async (
  carToken: string,
  dto: RecalculateCarOfferRequest
) => {
  return await api
    .request<RecalculateCarOfferResponse>({
      data: dto,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/cars/${carToken}/recalculate`
    })
    .then(response => response.data);
};

export const getCarSupplierOffer = async (
  carToken: string,
  supplierOfferToken: string
) => {
  return await api
    .request<GetCarResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/cars/${carToken}/supplier-offer/${supplierOfferToken}`
    })
    .then(response => response.data);
};
