import GoogleMap, { Coords, ChangeEventValue } from "google-map-react";
import React, { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { HospitalityHotel } from "~/apps/corporate/models/hotel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ZoomInMarker } from "~/apps/shared/components/location-marker/location-marker";

import { Button } from "@toolkit/v2";

import { useHotels } from "../hotels.context";
import { HotelMarker } from "./hotel-marker/hotel-marker";
import { styles } from "./styles";

type Props = {
  isExpanded?: boolean;
};

export const HotelsMap: React.FC<Props> = ({ isExpanded = false }) => {
  const {
    handleToggleMapVisibility,
    searchInfo,
    travelInfo,
    visibleHotels,
  } = useHotels();

  const defaultCenter = {
    lat: parseFloat(searchInfo!.hotelLatitude),
    lng: parseFloat(searchInfo!.hotelLongitude),
  };

  const [center, setCenter] = useState<Coords>(defaultCenter);
  const [selectedHotel, setSelectedHotel] = useState<HospitalityHotel | null>(
    null,
  );

  const getLinkUrl = useCallback(
    (hotel: HospitalityHotel) =>
      `/travels/${travelInfo!.travelToken}/search/hotels/${
        hotel.hotelToken
      }/result/${searchInfo!.hotelToken}`,
    [searchInfo, travelInfo],
  );

  const handleClickOutside = useCallback(() => {
    setSelectedHotel(null);
  }, []);

  const handleMapChange = useCallback(
    ({ center: selectedCenter }: ChangeEventValue) => {
      setCenter(selectedCenter);
    },
    [],
  );

  const handleMarkerClick = useCallback(
    (hotelData: HospitalityHotel) => (
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.stopPropagation();

      if (
        !!selectedHotel &&
        selectedHotel.hotelToken === hotelData.hotelToken
      ) {
        return;
      }

      setSelectedHotel(hotelData);
    },
    [selectedHotel],
  );

  const hotels = useMemo(
    () => [
      ...visibleHotels.favouriteHotels,
      ...visibleHotels.alternativeHotels,
    ],
    [visibleHotels],
  );

  return (
    <div css={styles.root({ isExpanded })}>
      <Button css={styles.button.root} onClick={handleToggleMapVisibility}>
        <Icon size={14} use={!isExpanded ? "search-outline" : "chevron-left"} />
        {!isExpanded ? "Buscar no mapa" : "Voltar para a lista"}
      </Button>
      <GoogleMap
        center={center}
        defaultZoom={13}
        onChange={handleMapChange}
        onClick={() => {
          if (isExpanded) {
            handleClickOutside();

            return;
          }

          handleToggleMapVisibility();

          window.scrollTo(0, 0);
        }}
        options={
          isExpanded
            ? {
                gestureHandling: "greedy",
                panControl: true,
                streetViewControl: true,
              }
            : {
                fullscreenControl: false,
                draggableCursor: "pointer",
                gestureHandling: "greedy",
                panControl: false,
                streetViewControl: false,
                zoomControl: false,
              }
        }
      >
        {hotels.map((hotel) => {
          const isVisible =
            !!selectedHotel && selectedHotel.hotelToken === hotel.hotelToken;

          return (
            <HotelMarker
              hotel={hotel}
              isVisible={isVisible}
              key={hotel.hotelToken}
              lat={hotel.latitude}
              lng={hotel.longitude}
              offersUrl={getLinkUrl(hotel)}
              onClick={handleMarkerClick(hotel)}
              onClose={handleClickOutside}
            />
          );
        })}
        <ZoomInMarker
          lat={defaultCenter.lat}
          lng={defaultCenter.lng}
          type="search-point"
        />
      </GoogleMap>
    </div>
  );
};

export const HotelsMapSkeleton: React.FC = () => {
  return (
    <div css={styles.root({ isExpanded: false })}>
      <Skeleton height="100%" width="100%" />
    </div>
  );
};
