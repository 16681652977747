import React from "react";

import { css } from "@styled-system/css";

import { BillingProfileListItem } from "@models/billing-profile.model";
import { BudgetResult } from "@models/budget.model";
import { CostCenterListItem } from "@models/cost-center.model";

import { MonthRespecitiveTranslation } from "../budgets.utils";
import BudgetCard from "./budget-card";

const styles = {
  root: css({
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    gap: "0.5rem",
    padding: "0.75rem 0rem",
    borderRadius: "0.5rem",
    width: "100%",
  }),
};

export type BudgetsCardsContainerProps = {
  budgets: BudgetResult[];
  billingProfiles: BillingProfileListItem[];
  costCenters: CostCenterListItem[];
};
const BudgetsCardsContainer = (props: BudgetsCardsContainerProps) => {
  return (
    <div css={styles.root}>
      {props.budgets.map((budget) => (
        <BudgetCard
          key={budget.budget_token}
          token={budget.budget_token}
          costCentersName={
            props.costCenters.find(
              (item) => item.token === budget.cost_center_token,
            )?.name
          }
          billingProfileName={
            props.billingProfiles.find(
              (item) =>
                item.billingProfileToken === budget.billing_profile_token,
            )?.legalName
          }
          timeframe={budget.timeframe}
          year={budget.year}
          month={MonthRespecitiveTranslation[budget.month]}
          semester={budget.semester}
          trimester={budget.trimester}
          action={budget.action}
          value={budget.value}
        />
      ))}
    </div>
  );
};

export default BudgetsCardsContainer;
