import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  buttons: {
    button: {
      root: css({
        flex: 1,
        fontSize: "0.875rem",
        justifyContent: "center",
        minWidth: "128px",
        "& > :first-of-type": {
          flexShrink: 0,
        },
      }),
    },
    root: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "12px",
      width: "100%",
    }),
  },
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
