import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { getBookingFailedReasonByType } from "~/apps/corporate/helpers/offer.helper";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { BOOKING_FAILED_REASON } from "~/apps/shared/constants";
import { CurrencyCode, ServiceType } from "~/apps/shared/constants/enums";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  isOutOfPolicy: boolean;
  servicePresenter: ItineraryServicePresenter;
};

export const TravelPlanPDFServiceJourneyHeader: React.FC<Props> = ({
  isOutOfPolicy,
  servicePresenter,
}) => {
  const bottomStyles = styles.bottom();
  const rootStyles = styles.root();
  const topStyles = styles.top();

  const bookingFailedReason = servicePresenter.getBookingFailedReason();
  const changedPrice = servicePresenter.getChangedPrice();

  const showBookingFailedReasonBadge = servicePresenter.showBookingFailedReasonBadge();
  const showDeniedOnApprovalBadge = servicePresenter.showDeniedOnApprovalBadge();
  const showOutdatedBadge = servicePresenter.showOutdatedBadge();
  const showUnavailableBadge = servicePresenter.showUnavailableBadge();

  return (
    <View style={rootStyles.root}>
      <View style={topStyles.root}>
        <TravelPlanPDFServiceIcon servicePresenter={servicePresenter} />
        <View style={topStyles.description}>
          <Text>
            {servicePresenter.getFormattedItineraryServiceHeaderName()}:
          </Text>
        </View>
        {isOutOfPolicy ? (
          <>
            <PDFIcon size={14} use="x" variant="pink" />
            <View style={topStyles.policy}>
              <Text>Fora da política</Text>
            </View>
          </>
        ) : null}
      </View>
      {showBookingFailedReasonBadge ||
      showDeniedOnApprovalBadge ||
      showOutdatedBadge ||
      showUnavailableBadge ? (
        <View style={bottomStyles.root}>
          {showDeniedOnApprovalBadge ? (
            <View style={bottomStyles.badge}>
              <PDFIcon size={12} use="thumbs-down" />
              <Text>Aprovação negada.</Text>
            </View>
          ) : null}
          {showOutdatedBadge ? (
            <View style={bottomStyles.badge}>
              <PDFIcon size={12} use="calendar-x" />
              <Text>Data expirada.</Text>
            </View>
          ) : null}
          {showUnavailableBadge ? (
            <View style={bottomStyles.badge}>
              <PDFIcon size={12} use="x" />
              <Text>Não mais disponível.</Text>
            </View>
          ) : null}
          {showBookingFailedReasonBadge ? (
            <TravelPlanPDFServiceBookingFailed
              bookingFailedReason={bookingFailedReason!}
              changedPrice={changedPrice}
              type={servicePresenter.getType()}
            />
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

type TravelPlanPDFServiceBookingFailedProps = {
  bookingFailedReason: string;
  changedPrice: {
    currency: CurrencyCode;
    total: number;
  } | null;
  type: ServiceType;
};

export const TravelPlanPDFServiceBookingFailed: React.FC<TravelPlanPDFServiceBookingFailedProps> = ({
  bookingFailedReason,
  changedPrice,
  type,
}) => {
  const bottomStyles = styles.bottom();

  return (
    <View style={bottomStyles.badge}>
      <PDFIcon size={12} use="no-symbol" />
      <Text>
        Falha na emissão. Motivo:{" "}
        {
          getBookingFailedReasonByType(type)?.[
            bookingFailedReason as keyof ReturnType<
              typeof getBookingFailedReasonByType
            >
          ]
        }{" "}
        {bookingFailedReason === BOOKING_FAILED_REASON.PRICE_CHANGE &&
        changedPrice
          ? `Novo preço: R$ ${toCurrency(
              changedPrice.total,
              2,
              changedPrice.currency,
            )}`
          : null}
      </Text>
    </View>
  );
};

type TravelPlanPDFServiceIconProps = {
  servicePresenter: ItineraryServicePresenter;
};

const TravelPlanPDFServiceIcon: React.FC<TravelPlanPDFServiceIconProps> = ({
  servicePresenter,
}) => {
  const topStyles = styles.top();

  return (
    <PDFIcon
      size={14}
      style={topStyles.icon}
      use={servicePresenter.getIcon()}
    />
  );
};
