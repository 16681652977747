import * as offlineBookingApi from "@apis/offline-booking.api";
import { GenericResult } from "./types";
import { ERROR } from "@constants";

export async function getHotelNegotiationRequest(requestToken: string) {
  const result: GenericResult = {};

  try {
    result.data = await offlineBookingApi.getHotelNegotiationRequest(
      requestToken
    );
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        result.error = ERROR.HOTEL_CONFIRMATION_NOT_ALLOWED_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_GET_HOTEL_NEGOTIATION_DATA_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_HOTEL_NEGOTIATION_DATA_ERROR;
    }
  }

  return result;
}

export async function confirmHotelBookingRequest(
  requestToken: string,
  bookingCode: string
) {
  const result: GenericResult = {};

  try {
    result.data = await offlineBookingApi.confirmHotelBookingRequest(
      requestToken,
      bookingCode
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CONFIRM_HOTEL_BOOKING_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CONFIRM_HOTEL_BOOKING_REQUEST_ERROR;
    }
  }

  return result;
}

export async function denyHotelBookingRequest(requestToken: string) {
  const result: GenericResult = {};

  try {
    result.data = await offlineBookingApi.denyHotelBookingRequest(requestToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DENY_HOTEL_BOOKING_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DENY_HOTEL_BOOKING_REQUEST_ERROR;
    }
  }

  return result;
}
