import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  root: () =>
    StyleSheet.create({
      card: {
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
        padding: 16,
      },
      divisor: {
        backgroundColor: theme.colors.gray[100],
        border: "none",
        height: "1px",
        margin: "24px 0",
        width: "100%",
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        marginLeft: 24,
      },
      segments: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
      },
    }),
};
