import { CarOffer, CarSupplier } from "~/apps/corporate/models/car.model";

import { CarAdditionalPresentation } from "../car-result.types";
import { LocalizaAdditionalsHelper } from "../localiza-additionals/localiza-additionals.helper";
import { MovidaAdditionalsHelper } from "../movida-additionals/movida-additionals.helper";

export const getAllAdditionalsFlattenedForPresentationBySupplier = (
  car: CarOffer,
) => {
  const supplier = car.supplierInfo?.supplier || car.rentalDetails.name;

  if (supplier.toUpperCase() === CarSupplier.LOCALIZA.toUpperCase()) {
    const localizaAdditionalsHelper = new LocalizaAdditionalsHelper({
      car,
    });

    const formattedLocalizaAccessoryAdditionals = localizaAdditionalsHelper.formatAccessoryAdditionals();
    const formattedLocalizaProtectionAdditionals = localizaAdditionalsHelper.formatProtectionAdditionals();
    const formattedLocalizaServiceAdditionals = localizaAdditionalsHelper.formatServiceAdditionals();

    const flattenedLocalizaAccessoryAdditionals = flatFormattedAdditionals(
      formattedLocalizaAccessoryAdditionals,
    );
    const flattenedLocalizaProtectionAdditionals = flatFormattedAdditionals(
      formattedLocalizaProtectionAdditionals,
    );
    const flattenedLocalizaServiceAdditionals = flatFormattedAdditionals(
      formattedLocalizaServiceAdditionals,
    );

    return [
      ...flattenedLocalizaAccessoryAdditionals,
      ...flattenedLocalizaProtectionAdditionals,
      ...flattenedLocalizaServiceAdditionals,
    ];
  }

  if (supplier.toUpperCase() === CarSupplier.MOVIDA.toUpperCase()) {
    const movidaAdditionalsHelper = new MovidaAdditionalsHelper({
      car,
    });

    const formattedMovidaAccessoryAdditionals = movidaAdditionalsHelper.formatAccessoryAdditionals();
    const formattedMovidaServiceAdditionals = movidaAdditionalsHelper.formatServiceAdditionals();
    const formattedMovidaProtectionAdditionals = movidaAdditionalsHelper.formatProtectionAdditionals();

    const flattenedMovidaAccessoryAdditionals = flatFormattedAdditionals(
      formattedMovidaAccessoryAdditionals,
    );
    const flattenedMovidaProtectionAdditionals = flatFormattedAdditionals(
      formattedMovidaProtectionAdditionals,
    );
    const flattenedMovidaServiceAdditionals = flatFormattedAdditionals(
      formattedMovidaServiceAdditionals,
    );

    return [
      ...flattenedMovidaAccessoryAdditionals,
      ...flattenedMovidaProtectionAdditionals,
      ...flattenedMovidaServiceAdditionals,
    ];
  }

  return null;
};

const flatFormattedAdditionals = (formattedAdditionals: {
  [key: string]:
    | CarAdditionalPresentation[]
    | CarAdditionalPresentation
    | undefined;
}) => {
  return Object.values(formattedAdditionals).reduce(
    (prev: CarAdditionalPresentation[], curr) => {
      if (!curr) {
        return prev;
      }

      if (!Array.isArray(curr)) {
        return [...prev, curr];
      }

      return [...prev, ...curr];
    },
    [],
  );
};
