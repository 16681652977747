import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  dialog: css({
    ".MuiDialog-paper": {
      borderRadius: "8px",
      maxWidth: "640px",
      padding: "0",
      overflow: "visible",
      "@media (max-width: 640px)": {
        minWidth: "unset",
        overflow: "auto",
      },
    },
  }),
  header: {
    close: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[300],
      cursor: "pointer",
      right: "24px",
      position: "absolute",
      top: "24px",
      transition: "color 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.gray[700],
      },
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      padding: "24px 24px 12px 24px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      paddingRight: "24px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
  }),
};
