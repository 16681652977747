import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  item: {
    bottom: {
      carryings: {
        carrying: {
          icon: css({
            color: theme.colors.pink[500],
            flexShrink: 0,
          }),
          root: css({
            alignItems: "flex-start",
            display: "flex",
            gap: "16px",
          }),
          texts: {
            container: css({
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }),
            name: css({
              color: theme.colors.pink[500],
              fontSize: "0.875rem",
              fontWeight: 600,
            }),
            text: css({
              color: theme.colors.gray[300],
              fontSize: "0.875rem",
            }),
          },
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
      },
      policies: {
        policy: {
          icon: css({
            color: theme.colors.pink[500],
            flexShrink: 0,
          }),
          root: css({
            alignItems: "flex-start",
            display: "flex",
            gap: "16px",
          }),
          texts: {
            container: css({
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }),
            name: css({
              color: theme.colors.pink[500],
              fontSize: "0.875rem",
              fontWeight: 600,
            }),
            text: css({
              color: theme.colors.gray[300],
              fontSize: "0.875rem",
            }),
          },
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "48px",
      height: "100%",
      width: "100%",
    }),
    top: {
      bounds: {
        bound: css({
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[300],
          display: "flex",
          gap: "6px",
        }),
      },
      cabin: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
      }),
      family: css({
        color: theme.colors.gray[700],
        fontSize: "1.125rem",
        fontWeight: 700,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
};
