import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { CANCEL_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  cancelFee: number | null;
  cancelType?: string;
  isChanged?: boolean;
};

export const ItineraryServiceCancelationInfoBadge: React.FC<Props> = ({
  cancelFee,
  cancelType,
  isChanged,
}) => {
  const translatedCancelType = isChanged
    ? "devido a alteração"
    : cancelType
    ? CANCEL_TYPES_TRANSLATION[
        cancelType as keyof typeof CANCEL_TYPES_TRANSLATION
      ]
    : null;

  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="x" />
      <span css={styles.text}>
        Cancelamento
        {translatedCancelType ? ` tipo: ${translatedCancelType}` : ""}.
        {cancelFee ? ` Multa: ${toCurrency(cancelFee)}` : ""}
      </span>
    </div>
  );
};
