import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useFlights } from "../../../flights.context";
import { styles } from "./styles";

export const SidebarSummary: React.FC = () => {
  const { isLoading, policy } = useFlights();

  const advance = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const { advance } = policy;

    return advance ? advance : 0;
  }, [isLoading, policy]);

  const maxPrice = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const { maxValue, maxValueVisible } = policy;

    return maxValue ? maxValue : maxValueVisible ? maxValueVisible : 0;
  }, [isLoading, policy]);

  if (advance === 0 && maxPrice === 0) {
    return null;
  }

  return (
    <PermissionProtectedRender permissions={["travels.policies"]}>
      <div css={styles.root}>
        <div css={styles.section.root}>
          <span css={styles.section.title}>Sua política</span>
          <ul css={styles.section.list.root}>
            {maxPrice !== 0 ? (
              <li css={styles.section.list.item.root}>
                <Icon size={16} use="dollar" />
                <span css={styles.section.list.item.text}>
                  Valor máximo para o destino:{" "}
                  {!isLoading ? toCurrency(maxPrice) : null}
                </span>
                {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
              </li>
            ) : null}
            {advance !== 0 ? (
              <li css={styles.section.list.item.root}>
                <Icon size={16} use="clock-outline" />
                <span css={styles.section.list.item.text}>
                  Antecedência mínima de compra:{" "}
                  {!isLoading ? advance + " dias" : null}
                </span>
                {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </PermissionProtectedRender>
  );
};
