import { useQuery } from "react-query";

import * as areasApi from "~/apps/corporate/apis/areas.api";

export const useUserCompanyAreas = (userToken?: string, enabled?: boolean) => {
  const {
    data: companyAreas,
    error: errorCompanyAreas,
    isLoading: isLoadingCompanyAreas,
  } = useQuery(
    ["users", userToken, "company-areas"],
    async () => {
      if (!userToken) {
        return;
      }

      return await areasApi.getUserCompanyAreas(userToken);
    },
    {
      enabled: (enabled === undefined ? true : enabled) && !!userToken,
    },
  );

  return {
    companyAreas,
    errorCompanyAreas,
    isLoadingCompanyAreas,
  };
};
