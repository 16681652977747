import React, { SyntheticEvent } from "react";
import Mask from "react-text-mask";

import { Input, InputProps } from "~/components/shared/inputs";

import { MaskType } from "./MaskedInput.types";
import { getMask } from "./MaskedInput.utils";

export type MaskedInputProps = InputProps & {
  mask: MaskType;
  value?: string;
  guide?: boolean;
  onChange?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
};

export default function MaskedInput(props: MaskedInputProps): JSX.Element {
  const { mask, value, ...rest } = props;

  const parsedMask = getMask({ mask, value });

  return (
    <Mask
      {...rest}
      type="numeric"
      mask={parsedMask}
      value={value}
      placeholderChar={"\u2000"}
      render={(ref, props) => <Input ref={ref} {...props} />}
    />
  );
}
