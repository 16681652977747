import React from "react";

import { styles } from "./styles";

export const HotelResultUsefulInformation: React.FC = () => {
  return (
    <div css={styles.root}>
      <h3 css={styles.title}>Informações Úteis</h3>
      <div css={styles.paragraph.root}>
        <p css={styles.paragraph.paragraph}>
          Taxa por pessoa extra pode ser aplicada a depender da política do
          hotel.
        </p>
        <p css={styles.paragraph.paragraph}>
          Documento de identificação e cartão de crédito são necessários no
          check-in para cobrança de incidentes.
        </p>
        <p css={styles.paragraph.paragraph}>
          Solicitações especiais estão sujeitas à disponibilidade no momento do
          check-in e podem incorrer em cobranças adicionais. Solicitações
          especiais não podem ser garantidas.
        </p>
      </div>
    </div>
  );
};
