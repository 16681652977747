import { css } from "@styled-system/css";

import { theme } from "@skin/v2";
import { defaultTheme } from "@assets/styles/theme";

export const styles = {
  item: ({ isIncluded }: { isIncluded: boolean }) =>
    css({
      alignItems: "center",
      color: isIncluded ? theme.colors.green[500] : defaultTheme.secondaryColor,
      display: "flex",
      fontSize: "0.875rem",
      gap: "8px",
    }),
  root: css({
    display: "flex",
    flexDirection: "column",
  }),
};
