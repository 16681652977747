import React, { useContext } from "react";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "emotion";

import { defaultTheme } from "../../../../../../../assets/styles/theme";
import { Column } from "../../../../../../../components/shared/layout/Column";
// import { BudgetsContext } from "./budgets.context";

const styles = {
  dialogPaper: css({
    width: 640,
    maxHeight: "90vh",
    overflowY: "auto",
  }),
  titleDiv: css({
    padding: "2rem 3rem 0",
    fontWeight: "bold",
  }),
  iconDiv: css({
    padding: ".5rem",
    position: "absolute",
    top: 0,
    right: 0,
  }),
  icon: css({
    color: defaultTheme.subTextColor,
    cursor: "pointer",
  }),
  content: css({
    padding: "2rem 3rem",
    lineHeight: "24px",
    fontSize: 14,
    color: defaultTheme.textColor,
  }),
};

interface ApprovalExplanationDialogProps {
  isOpened: boolean;
  onCloseClick: () => void;
}

function ApprovalExplanationDialog(props: ApprovalExplanationDialogProps) {
  const { isOpened, onCloseClick } = props;

  return (
    <Dialog
      open={isOpened}
      classes={{ paper: styles.dialogPaper }}
      onClose={onCloseClick}
    >
      <div>
        <div className={styles.titleDiv}>
          Configure um processo de aprovação
        </div>
        <div className={styles.iconDiv}>
          <CloseIcon className={styles.icon} onClick={onCloseClick} />
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <p>
            Um processo de aprovação customizado controla quais <b>viajantes</b>{" "}
            possuem permissão para viajar e quais viajantes precisam de
            aprovação.
          </p>
          <br />
          <p>
            A aprovação será solicitada pelos criadores da viagem para os
            aprovadores do viajante especificados no cadastro. Os aprovadores
            podem ser de primeiro nível ou segundo nível. A aprovação de segundo
            nível só será solicitada, caso sejam especificados aprovadores de
            segundo nível e após a aprovação de primeiro nível acontecer.
          </p>
          <br />
          <p>
            <b>
              Importante: Quando há processo de aprovação, o aprovador é quem de
              fato realiza a reserva, comprando a viagem.
            </b>
          </p>
          <br />
          <p>
            O cadastro de processo de aprovação pode ter os seguintes tipos de
            alvo, isto é, aqueles a quem o processo de aprovação se aplicará:
          </p>
          <br />
          <Column>
            <span>
              <b>Usuário</b> - Processo se aplicará para um funcionário
              específico;
            </span>
            <span>
              {" "}
              <b>Projeto</b> - Processo se aplicará a um projeto;
            </span>
            <span>
              {" "}
              <b>Centro de custo</b> - Processo se aplicará a um centro de
              custo;
            </span>
          </Column>
          <br />
          <div>
            <p>
              Um processo de aprovação possui três possibilidades de
              configuração de regra, de acordo com o seu grau de restrição:
            </p>
            <br />
            <ul style={{ listStyle: "inside" }}>
              <li>Sem necessidade de aprovação, ou seja, sem restrição;</li>
              <li>
                Aprovação necessária para viagens fora da política de aprovação;
              </li>
              <li>Aprovação necessária para todas as viagens;</li>
            </ul>
          </div>
          <br />
          <div>
            <p>
              A hierarquia de processos de aprovação funciona da seguinte forma:
            </p>
            <br />
            <ul style={{ listStyle: "inside" }}>
              <li style={{ paddingBottom: "0.25rem" }}>
                Se o usuário não for alvo de nenhum processo de aprovação, então
                nenhuma restrição será aplicada a ele, logo não haverá
                necessidade de aprovação;
              </li>
              <li style={{ paddingBottom: "0.25rem" }}>
                Se o usuário for alvo de um processo de aprovação, então este
                processo criado será aplicado exclusivamente para ele;
              </li>
              <li style={{ paddingBottom: "0.25rem" }}>
                Se um grupo for alvo de um processo de aprovação, então este
                processo criado será aplicado a todos seus integrantes.
                <b>
                  {" "}
                  Se este pertencer a mais de um grupo, a regra menos restritiva
                  será aplicada.
                </b>{" "}
                Em caso de algum integrante do grupo já for alvo de um processo
                de aprovação individual, este será o processo aplicado a ele.
              </li>
              <li>
                Se o usuário criador da viagem for o mesmo responsável por
                aprovar solicitações daquele viajante, então este poderá
                proceder com a compra diretamente;
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export { ApprovalExplanationDialog };
