import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { RESERVE_STATUS } from "~/apps/shared/constants";
import moment from "moment";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  reservationReservedUntil: string | null;
  reservationStatus: string;
};

export const ItineraryServiceReservationInfoBadge: React.FC<Props> = ({
  reservationReservedUntil,
  reservationStatus,
  ...props
}) => {
  const momentReservedUntil = reservationReservedUntil
    ? moment(reservationReservedUntil)
    : null;

  const now = moment();

  const isReserveExpired =
    !!momentReservedUntil && momentReservedUntil.isBefore(now);

  const reservedUntilFormatted =
    momentReservedUntil?.format("DD [de] MMMM [de] YYYY [às] HH[h]mm[min].") ||
    null;

  const showPreReservation =
    reservationStatus === RESERVE_STATUS.RESERVED && !isReserveExpired;
  const showPreReservationExpired =
    reservationStatus === RESERVE_STATUS.RESERVED && isReserveExpired;
  const showPreReservationFailed = reservationStatus === RESERVE_STATUS.FAILED;
  const showPreReservationPending =
    reservationStatus === RESERVE_STATUS.PENDING ||
    reservationStatus === RESERVE_STATUS.FINAL_PENDING;

  return (
    <>
      {showPreReservation ? (
        <Tooltip
          arrow
          content="Sua pré-reserva foi realizada com sucesso, e seu lugar no voo esta garantido até sua data de expiraçao. Após a expiração da data de reserva você ainda poderá emitir o bilhete a depender da disponibilidade. Porém as condições podem mudar."
          position="top"
        >
          <div css={styles.root} {...props}>
            <Icon css={styles.green} size={16} use="check-circle" />
            <span css={styles.text}>
              Voo pré-reservado (bilhete não emitido).
              {!!reservedUntilFormatted &&
                ` Expira em ${reservedUntilFormatted}`}
            </span>
          </div>
        </Tooltip>
      ) : null}
      {showPreReservationExpired ? (
        <Tooltip
          arrow
          content="Sua pré-reserva para este voo expirou, você ainda poderá emitir o bilhete a depender da disponibilidade. Porém as condições podem mudar."
          position="top"
        >
          <div css={styles.root} {...props}>
            <Icon css={styles.pink} size={16} use="clock-outline" />
            <span css={styles.text}>
              Pré-reserva expirada
              {reservedUntilFormatted ? ` em ${reservedUntilFormatted}` : "."}
            </span>
          </div>
        </Tooltip>
      ) : null}
      {showPreReservationFailed ? (
        <Tooltip
          arrow
          content="Sua pré-reserva para este voo falhou, você ainda poderá emitir o bilhete a depender da disponibilidade. Porém as condições podem mudar."
          position="top"
        >
          <div css={styles.root} {...props}>
            <Icon css={styles.pink} size={16} use="x-circle-outline" />
            <span css={styles.text}>Falha na pré-reserva.</span>
          </div>
        </Tooltip>
      ) : null}
      {showPreReservationPending ? (
        <Tooltip
          arrow
          content="Estamos pré-reservando seu voo, mas não se preocupe você poderá continuar com sua compra normalmente enquanto isso."
          position="top"
        >
          <div css={styles.root} {...props}>
            <Icon css={styles.blue} size={16} use="arrow-path" />
            <span css={styles.text}>Pré-reservando voo.</span>
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};
