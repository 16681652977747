import React, { useState, useRef, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import AsyncSelect from "react-select/async";

import {
  GroupAddOutlined as GroupAddOutlinedIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
} from "@material-ui/icons";
import { UserSearch } from "~/apps/corporate/models/user.model";
import { AvatarGroup } from "~/apps/shared/components/avatar-group/avatar-group";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import {
  Dialog,
  DialogFooter,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Popover } from "~/apps/shared/components/popover/popover";
import { Radio } from "~/apps/shared/components/radio-group/radio/radio";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { debounce } from "lodash";

import { Button } from "@toolkit/v2";

// import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { TargetItem } from "~/components/configuration/shared/TargetItem";

import { CarAdditionalPresentation } from "../car-result.types";
import { styles } from "./styles";

type AdditionalProps = {
  additional: CarAdditionalPresentation;
  disabled: boolean;
  onChange: (additional: CarAdditionalPresentation, checked: boolean) => void;
};

export const Additional: React.FC<AdditionalProps> = ({
  additional,
  disabled,
  onChange,
}) => {
  return (
    <button
      css={styles.additional.root}
      disabled={disabled}
      onClick={() => {
        onChange(additional, !additional.checked);
      }}
    >
      <div css={styles.additional.top.root}>
        <div css={styles.additional.top.left.root({ disabled })}>
          {additional.icon}
          <span css={styles.additional.top.left.name}>{additional.name}</span>
          {additional.tooltip ? (
            <Tooltip arrow content={additional.tooltip} position="top">
              <div css={styles.additional.top.left.tooltip}>
                <Icon size={18} use="question-circle-outline" />
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div css={styles.additional.top.right.root}>
          {/* <span css={styles.additional.top.right.price}>
            {formatCurrency(additional.price, "BRL")}
            {additional.rate === "daily" ? " /dia" : " *valor único"}
          </span> */}
          <Checkbox
            checked={additional.checked}
            css={styles.additional.top.right.checkbox({
              disabled,
            })}
            disabled={disabled}
            onChange={(e) => {
              onChange(additional, e.target.checked);
            }}
            variant="pink"
          />
        </div>
      </div>
      {additional.details ? (
        <div css={styles.additional.bottom.root}>
          <p css={styles.additional.bottom.details}>{additional.details}</p>
        </div>
      ) : null}
    </button>
  );
};

type AdditionalAdditionalDriversProps = {
  additional: CarAdditionalPresentation;
  additionalDrivers: UserSearch[];
  disabled: boolean;
  max?: number;
  onChange: (additional: CarAdditionalPresentation, checked: boolean) => void;
  onChangeAdditionalDrivers: (additionalDrivers: UserSearch[]) => void;
  searchAdditionalDrivers: (search: string) => Promise<UserSearch[] | null>;
};

export const AdditionalAdditionalDrivers: React.FC<AdditionalAdditionalDriversProps> = ({
  additional,
  disabled,
  additionalDrivers,
  max,
  onChange,
  onChangeAdditionalDrivers,
  searchAdditionalDrivers,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isAdding, setIsAdding] = useState(false);

  const clearAdditionalDrivers = () => {
    onChangeAdditionalDrivers([]);
  };

  const handleAddAdditionalDriver = (additionalDriver: UserSearch) => {
    const newAdditionalDrivers = [...additionalDrivers, additionalDriver];

    if (max !== undefined && newAdditionalDrivers.length > max) {
      return;
    }

    onChange({ ...additional, quantity: newAdditionalDrivers.length }, true);
    onChangeAdditionalDrivers(newAdditionalDrivers);
  };

  const handleRemoveAdditionalDriver = (additionalDriver: {
    fullName: string;
    userToken: string;
  }) => {
    const newAdditionalDrivers = additionalDrivers.filter(
      (c) => c.userToken !== additionalDriver.userToken,
    );

    if (newAdditionalDrivers.length === 0) {
      clearAdditionalDrivers();
      onChange(additional, false);

      return;
    }

    onChange({ ...additional, quantity: newAdditionalDrivers.length }, true);
    onChangeAdditionalDrivers(newAdditionalDrivers);
  };

  const loadOptions = useMemo(
    () =>
      debounce((search: string, callback: any) => {
        void searchAdditionalDrivers(search).then(callback);
      }, 300),
    [searchAdditionalDrivers],
  );

  const startAdding = () => {
    setIsAdding(true);
  };

  const stopAdding = () => {
    setIsAdding(false);
  };

  return (
    <div css={styles.conductors.root}>
      <div css={styles.conductors.top.root}>
        <div css={styles.additional.top.left.root({ disabled })}>
          {additional.icon}
          <span css={styles.additional.top.left.name}>{additional.name}</span>
        </div>
        <div css={styles.additional.top.right.root}>
          {/* <span css={styles.additional.top.right.price}>
            {formatCurrency(additional.price, "BRL")}
            {additional.rate === "daily" ? (
              " /dia"
            ) : additional.rate === "daily-per" ? (
              <>/dia &times; condutor</>
            ) : (
              " *valor único"
            )}
          </span> */}
        </div>
      </div>
      <div css={styles.conductors.bottom.root}>
        <div css={styles.conductors.bottom.label.root}>
          <span css={styles.conductors.bottom.label.strong}>Condutores</span>
          {max !== undefined ? (
            <span css={styles.conductors.bottom.label.weak}> (até {max})</span>
          ) : null}
          <span css={styles.conductors.bottom.label.strong}>:</span>
        </div>
        <div css={styles.conductors.bottom.avatars.root} ref={containerRef}>
          <AvatarGroup max={max !== undefined ? max + 1 : undefined}>
            {additionalDrivers.map((additionalDriver, index) => (
              <Tooltip
                arrow
                content={additionalDriver.fullName}
                key={index}
                position="top"
              >
                <div css={styles.conductors.bottom.avatars.avatar.root}>
                  <button
                    css={styles.conductors.bottom.avatars.avatar.remove}
                    disabled={disabled}
                    onClick={() =>
                      handleRemoveAdditionalDriver(additionalDriver)
                    }
                  >
                    &times;
                  </button>
                  <Avatar
                    css={styles.conductors.bottom.avatars.avatar.avatar}
                    name={additionalDriver.fullName}
                  />
                </div>
              </Tooltip>
            ))}
            {max === undefined || additionalDrivers.length < max ? (
              <Tooltip arrow content="Atribuir" position="top">
                <span css={styles.conductors.bottom.avatars.span}>
                  <button
                    css={styles.conductors.bottom.avatars.add}
                    disabled={
                      max !== undefined && additionalDrivers.length >= max
                    }
                    onClick={() => {
                      startAdding();
                    }}
                  >
                    {additionalDrivers.length > 0 ? (
                      <PersonAddOutlinedIcon fontSize="small" />
                    ) : (
                      <GroupAddOutlinedIcon fontSize="small" />
                    )}
                  </button>
                </span>
              </Tooltip>
            ) : null}
          </AvatarGroup>
          <Popover
            anchorEl={containerRef.current}
            anchorOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
            css={styles.conductors.bottom.avatars.popover.root}
            onClose={() => {
              stopAdding();
            }}
            open={isAdding}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <AsyncSelect
              autoFocus
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                // eslint-disable-next-line react/display-name
                Option: (props) => {
                  const { data, selectOption } = props;

                  return (
                    <TargetItem
                      item={{
                        email: data.email,
                        fullName: data.fullName,
                        label: data.fullName,
                      }}
                      onClick={() => selectOption(data)}
                      {...props}
                    />
                  );
                },
              }}
              controlShouldRenderValue={false}
              escapeClearsValue
              filterOption={(option) =>
                !additionalDrivers.some((c) => c.userToken === option.value)
              }
              getOptionLabel={(option) => option.fullName}
              getOptionValue={(option) => option.userToken}
              loadOptions={loadOptions}
              menuIsOpen
              noOptionsMessage={() => "Nenhum usuário encontrado"}
              onChange={handleAddAdditionalDriver}
              placeholder="Procurar por usuários"
              styles={styles.conductors.bottom.avatars.popover.autocomplete}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

type AdditionalDialogProps = {
  additional: CarAdditionalPresentation;
  disabled: boolean;
  onChange: (additional: CarAdditionalPresentation, checked: boolean) => void;
};

export const AdditionalDialog: React.FC<AdditionalDialogProps> = ({
  additional,
  children,
  disabled,
  onChange,
}) => {
  const [
    isAdditionalDetailsDialogOpen,
    setIsAdditionalDetailsDialogOpen,
  ] = useState(false);

  const handleAdditionalDetailsDialogClose = () => {
    setIsAdditionalDetailsDialogOpen(false);
  };

  const handleAdditionalDetailsDialogOpen = () => {
    setIsAdditionalDetailsDialogOpen(true);
  };

  return (
    <>
      <div css={styles.dialog.root}>
        <div css={styles.dialog.left.root({ disabled })}>
          <div css={styles.dialog.left.top.root}>
            {additional.icon}
            <span css={styles.dialog.left.top.name}>{additional.name}</span>
          </div>
          <div css={styles.dialog.left.bottom.root}>
            <button
              css={styles.dialog.left.bottom.button}
              onClick={(e) => {
                e.stopPropagation();

                handleAdditionalDetailsDialogOpen();
              }}
            >
              Ver mais
            </button>
          </div>
        </div>
        <div css={styles.dialog.right.root}>
          {/* <span css={styles.dialog.right.price}>
            {formatCurrency(additional.price, "BRL")}
            {additional.rate === "daily" ? " /dia" : " *valor único"}
          </span> */}
          <Radio
            checked={additional.checked}
            css={styles.dialog.right.radio({
              disabled,
            })}
            disabled={disabled}
            onChange={(e) => {
              onChange(additional, e.target.checked);
            }}
            variant="pink"
          />
        </div>
      </div>
      <Dialog
        css={styles.dialog.dialog.root}
        onClose={handleAdditionalDetailsDialogClose}
        open={isAdditionalDetailsDialogOpen}
      >
        <DialogHeader
          icon="info"
          onClose={handleAdditionalDetailsDialogClose}
        />
        {children ? (
          children
        ) : (
          <>
            <p css={styles.dialog.dialog.details}>{additional.details}</p>
            <DialogFooter css={styles.dialog.dialog.footer}>
              <Button
                css={styles.dialog.dialog.button}
                onClick={handleAdditionalDetailsDialogClose}
              >
                Entendi
              </Button>
            </DialogFooter>
          </>
        )}
      </Dialog>
    </>
  );
};

type AdditionalQuantityProps = {
  additional: CarAdditionalPresentation;
  disabled: boolean;
  max?: number;
  min?: number;
  onChange: (additional: CarAdditionalPresentation, checked: boolean) => void;
  quantity: number;
};

export const AdditionalQuantity: React.FC<AdditionalQuantityProps> = ({
  additional,
  disabled,
  max,
  min = 0,
  onChange,
  quantity,
}) => {
  const handleDecrease = () => {
    if (additional.checked && quantity - 1 === 0) {
      onChange(
        {
          ...additional,
          quantity: 0,
        },
        false,
      );

      return;
    }

    if (quantity - 1 < min) {
      return;
    }

    onChange(
      {
        ...additional,
        quantity: quantity - 1,
      },
      additional.checked,
    );
  };

  const handleIncrease = () => {
    if (!additional.checked && quantity + 1 === 1) {
      onChange(
        {
          ...additional,
          quantity: 1,
        },
        true,
      );

      return;
    }

    if (max !== undefined && quantity + 1 > max) {
      return;
    }

    onChange(
      {
        ...additional,
        quantity: quantity + 1,
      },
      additional.checked,
    );
  };

  return (
    <div css={styles.quantity.root({ checked: additional.checked })}>
      <div css={styles.quantity.left.root}>
        <div css={styles.quantity.left.top.root}>
          {additional.icon}
          <span css={styles.quantity.left.top.name}>{additional.name}</span>
        </div>
        {/* <div css={styles.quantity.left.bottom.root}>
          <span css={styles.quantity.left.bottom.price}>
            {formatCurrency(additional.price, "BRL")}
            {additional.rate === "daily" ? " /dia" : " *valor único"} (unidade)
          </span>
        </div> */}
      </div>
      <div css={styles.quantity.right.root}>
        <button
          css={styles.quantity.right.button}
          disabled={disabled || quantity <= min}
          onClick={handleDecrease}
        >
          -
        </button>
        <span css={styles.quantity.right.quantity}>{quantity}</span>
        <button
          css={styles.quantity.right.button}
          disabled={disabled || (max !== undefined && quantity >= max)}
          onClick={handleIncrease}
        >
          +
        </button>
      </div>
    </div>
  );
};

export const AdditionalSkeleton: React.FC = () => {
  return (
    <div css={styles.additional.skeleton.root}>
      <button css={styles.additional.top.root} disabled>
        <div css={styles.additional.top.left.root({ disabled: false })}>
          <Skeleton height="16px" width="16px" />
          <Skeleton height="16px" width="192px" />
        </div>
        <div css={styles.additional.top.right.root}>
          <Skeleton height="16px" width="96px" />
          <Checkbox disabled />
        </div>
      </button>
    </div>
  );
};
