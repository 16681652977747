import { AxiosError } from "axios";

import {
  ICustomFieldOptions,
  CustomFieldOptionsResponse,
  IGetClientExternalCredentialsResponse,
  IClientExternalCredentialsResponse
} from "~/dtos/client.dto";
import { UserResponse } from "~/dtos/user.dto";

import { UserModel } from "~/models/user.model";

import errorTypes from "../constants/errors";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import { parseClientConfig } from "../parsers/client.parser";
import { User as UserFactory } from "../parsers/user.parser";
import { api } from "./interceptors";

export type UsersVisiblity = "active" | "inactive";

export type GetUsersFilter = {
  visibility?: UsersVisiblity;
  query?: string;
  billingProfileToken?: string;
};

async function getUsersRequest(filters?: GetUsersFilter) {
  const response = await api.request({
    url: `/booking/client/${getClientToken()}/user`,
    method: "GET",
    headers: getAuthorizationHeader(),
    params: filters
  });
  const users = response.data.map((userDto: UserResponse) =>
    UserFactory(userDto)
  );
  return users;
}

async function getClientInfo() {
  const { data } = await api.request({
    url: `/booking/client/${getClientToken()}`,
    method: "GET",
    headers: getAuthorizationHeader()
  });
  return {
    id: data.id,
    clientToken: data.client_token,
    clientSlug: data.client_slug,
    email: data.email,
    name: data.name,
    costCenterActive: data.cost_center_active,
    budgetsActive: data.budgets_active,
    vaultActive: data.vault_active,
    active: data.active,
    bookingFeeOption: data.booking_fee_option,
    createdAt: data.created_at
  };
}

function updateClientInfo(data: any) {
  return api.request({
    url: `/booking/client/${getClientToken()}`,
    method: "PUT",
    headers: getAuthorizationHeader(),
    data
  });
}

async function getUsers() {
  try {
    return getUsersRequest();
  } catch (error) {
    return handleError(error);
  }
}

async function getActiveUsers() {
  const users = await getUsersRequest();
  return users.filter((user: UserModel) => !user.hidden && !!user.active);
}

function getClientConfig() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/client-config`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => parseClientConfig(data));
}

export function updateClientConfig(clientConfigToken: string, dto: any) {
  return api
    .request({
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => data);
}

export async function getClientPurposeFieldOptions(): Promise<
  ICustomFieldOptions[]
> {
  const response = await api.request<CustomFieldOptionsResponse>({
    url: `/booking/clients/${getClientToken()}/custom-fields`,
    method: "GET",
    headers: getAuthorizationHeader()
  });
  const result = response.data;
  return result;
}

function handleError(error: AxiosError) {
  if (error.response) {
    const { data, status } = error.response;

    if (status === 400 && data.type === errorTypes.EMAIL_PASSWORD_INCORRECT) {
      throw { title: "title", description: "Usuário ou senha inválidos" };
    }

    if (status === 400 && data.type === errorTypes.ERROR_INVALID_INPUT) {
      throw { title: "title", description: "Preenchimento inválido" };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  throw { title: "title", description: "Erro inesperado" };
}

async function getClientExternalCredentials(): Promise<IGetClientExternalCredentialsResponse> {
  const { data } = await api.request({
    url: `/booking/client/${getClientToken()}/external-credentials`,
    method: "GET",
    headers: getAuthorizationHeader()
  });
  return data;
}

async function resetClientExternalCredentials(): Promise<IClientExternalCredentialsResponse> {
  const { data } = await api.request({
    url: `/booking/clients/${getClientToken()}/reset-credentials`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
  return data;
}

async function removeClientExternalCredentials(): Promise<IClientExternalCredentialsResponse> {
  const { data } = await api.request({
    url: `/booking/clients/${getClientToken()}/credentials`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
  return data;
}

export const clientService = {
  getUsers,
  getActiveUsers,
  getUsersRequest,
  getClientInfo,
  getClientConfig,
  updateClientInfo,
  updateClientConfig,
  getClientPurposeFieldOptions,
  getClientExternalCredentials,
  resetClientExternalCredentials,
  removeClientExternalCredentials
};

export default clientService;
