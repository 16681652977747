import React, { useEffect, useContext } from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

import {
  ExpensePolicyProvider,
  ExpensePolicyContext,
} from "./ExpensePolicy.context";
import PageTitle from "../../../shared/PageTitle";
import PAGE_TITLES from "../../../../constants/page-titles.constants";
import Spinner from "../../../shared/Spinner";
import { ExpensePolicyList } from "./ExpensePolicyList";
import { PolicyDeletionDialog } from "./PolicyDeletionDialog";
import { PolicyForm } from "./PolicyForm/PolicyForm.container";
import { ContainedButton } from "@components/shared/buttons/contained-button";

const styles = {
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
};

const ExpensePolicy = () => {
  const { loading, fetchPolicies, handleOpenForm } = useContext(
    ExpensePolicyContext,
  );

  useEffect(() => {
    fetchPolicies();
  }, []);

  return (
    <div>
      <PageTitle title={PAGE_TITLES.EXPENSE_POLICY} />
      <Spinner visible={loading} />
      <PolicyDeletionDialog />
      <PolicyForm />
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: "2rem" }} className="d-flex">
          <ContainedButton onClick={handleOpenForm} css={styles.button}>
            Adicionar política
          </ContainedButton>
        </div>
        <ExpensePolicyList />
      </div>
    </div>
  );
};

const Container = (_: any) => (
  <ExpensePolicyProvider>
    <ExpensePolicy />
  </ExpensePolicyProvider>
);

export { Container as ExpensePolicy };
