import React, { useCallback, useMemo, useState } from "react";

import { Collapse } from "~/apps/shared/components/collapse/collapse";
import { Icon } from "~/apps/shared/components/icon/icon";
import { FLIGHT_CABIN_CLASS_TRANSLATOR } from "~/apps/shared/constants";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import { formatFlightDuration } from "~/apps/shared/utils/format-flight-duration";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  arrival: {
    airportCode: string;
    airportName: string;
    date: string;
  };
  departure: {
    airportCode: string;
    airportName: string;
    date: string;
  };
  isBaggageVisible?: boolean;
  segments: {
    arrival: {
      airportCode: string;
      airportName: string;
      date: string;
      time: string;
    };
    baggage?: {
      carryOn: {
        included: boolean;
        quantity: number;
        weight: {
          unit: string;
          value: number;
        };
      };
      checked: {
        included: boolean;
        quantity: number;
        weight: {
          unit: string;
          value: number;
        };
      };
    };
    cabinClass: string;
    carrier?: {
      imageUrl: string;
      name: string;
    };
    connection?: {
      isSameAirport: boolean;
      segmentIndex: number;
      waitingTime: string;
    };
    departure: {
      airportCode: string;
      airportName: string;
      date: string;
      time: string;
    };
    duration: string;
    flightNumber: string;
  }[];
};

export const FlightJourneyDetailsCollapsibleCard: React.FC<Props> = ({
  arrival,
  departure,
  isBaggageVisible = false,
  segments,
}) => {
  const [open, setOpen] = useState(true);
  const { width: windowWidth } = useWindowSize();

  const handleCollapse = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const getCheckedBaggageMessage = useCallback(
    (baggage: {
      included: boolean;
      quantity: number;
      weight: {
        unit: string;
        value: number;
      };
    }) => {
      let message = "";

      if (baggage.quantity > 1) {
        message += `Inclui ${baggage.quantity} bagagens despachadas`;
      } else if (baggage.quantity === 0 || baggage.quantity === 1) {
        message += "Inclui 1 bagagem despachada";
      }

      if (baggage.weight.value) {
        message += ` de até ${baggage.weight.value}${baggage.weight.unit}`;
      }

      return message;
    },
    [],
  );

  const isMobile = useMemo(() => windowWidth <= 640, [windowWidth]);

  return (
    <div css={styles.root}>
      <div
        css={styles.top.root({ isCollapsed: !open })}
        onClick={handleCollapse}
      >
        <div css={styles.top.left.root}>
          <div css={styles.top.left.itinerary.root}>
            <span css={styles.top.left.itinerary.date}>
              {moment(departure.date).format("ddd, DD MMM YYYY")}
            </span>
            <span css={styles.top.left.itinerary.code}>
              {departure.airportCode}
            </span>
            <span css={styles.top.left.itinerary.name}>
              {departure.airportName}
            </span>
          </div>
          <Icon size={16} use="arrow-right" />
          <div css={styles.top.left.itinerary.root}>
            <span css={styles.top.left.itinerary.date}>
              {moment(arrival.date).format("ddd, DD MMM YYYY")}
            </span>
            <span css={styles.top.left.itinerary.code}>
              {arrival.airportCode}
            </span>
            <span css={styles.top.left.itinerary.name}>
              {arrival.airportName}
            </span>
          </div>
        </div>
        <Button
          css={styles.top.right.button.root}
          onClick={(e) => {
            e.stopPropagation();

            handleCollapse();
          }}
          shape="icon"
        >
          <Icon
            css={styles.top.right.button.icon({ isCollapsed: !open })}
            use="chevron-up"
          />
        </Button>
      </div>
      <Collapse expanded={open}>
        <div css={styles.bottom.root}>
          <div css={styles.bottom.segments.root}>
            {segments.map((segment, index) => (
              <React.Fragment key={index}>
                <div css={styles.bottom.segments.segment.root}>
                  <div css={styles.bottom.segments.segment.itinerary.root}>
                    <Icon size={16} use="airplane-taking-off" />
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.departure.time}
                    </span>
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.departure.airportCode}
                    </span>
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.departure.airportName}
                    </span>
                  </div>
                  {segment.carrier ? (
                    <div css={styles.bottom.segments.segment.carrier.root}>
                      <img
                        alt={segment.carrier.name}
                        css={styles.bottom.segments.segment.carrier.image}
                        height={64}
                        src={segment.carrier.imageUrl}
                        title={segment.carrier.name}
                      />
                      <div
                        css={styles.bottom.segments.segment.carrier.right.root}
                      >
                        <div
                          css={styles.bottom.segments.segment.carrier.right.row}
                        >
                          <span>Operado por: {segment.carrier.name}</span>
                          <img
                            css={
                              styles.bottom.segments.segment.carrier.right.image
                            }
                            height={16}
                            src={segment.carrier.imageUrl}
                          />
                        </div>
                        <span>
                          Classe:{" "}
                          {
                            FLIGHT_CABIN_CLASS_TRANSLATOR[
                              segment.cabinClass as keyof typeof FLIGHT_CABIN_CLASS_TRANSLATOR
                            ]
                          }
                        </span>
                        <span>Vôo: {segment.flightNumber}</span>
                        {isBaggageVisible ? (
                          segment.baggage?.checked.included ? (
                            <span>
                              {getCheckedBaggageMessage(
                                segment.baggage.checked,
                              )}
                            </span>
                          ) : segment.baggage?.carryOn.included ? (
                            <span>Apenas bagagem de mão.</span>
                          ) : null
                        ) : null}
                        <span>
                          Duração: {formatFlightDuration(segment.duration)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div css={styles.bottom.segments.segment.itinerary.root}>
                    <Icon size={16} use="airplane-landing" />
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.arrival.time}
                    </span>
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.arrival.airportCode}
                    </span>
                    <span css={styles.bottom.segments.segment.itinerary.text}>
                      {segment.arrival.airportName}
                    </span>
                  </div>
                </div>
                {segment.connection ? (
                  <div css={styles.bottom.segments.connection.root}>
                    <div css={styles.bottom.segments.connection.left.root}>
                      <Icon size={16} use="ellipsis-vertical" />
                      <span>Conexão:</span>
                    </div>
                    <div css={styles.bottom.segments.connection.right.root}>
                      <span css={styles.bottom.segments.connection.right.top}>
                        {segment.connection.isSameAirport ? (
                          "Mesmo aeroporto."
                        ) : (
                          <>
                            {
                              segments[segment.connection.segmentIndex + 1]
                                ?.departure.airportCode
                            }{" "}
                            -{" "}
                            {
                              segments[segment.connection.segmentIndex + 1]
                                ?.departure.airportName
                            }
                          </>
                        )}
                      </span>
                      <span>
                        {!isMobile ? "Duração: " : null}
                        {formatFlightDuration(segment.connection.waitingTime)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};
