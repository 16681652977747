import moment from "moment";

interface IMonthRange {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
}

export function getFormattedMonthPickerPeriod(range: IMonthRange) {
  const startDate = moment(new Date(range.startYear, range.startMonth - 1, 1));

  const endDate = moment(new Date(range.endYear, range.endMonth - 1, 1));

  return (
    moment(startDate).format("MMM YYYY") +
    " - " +
    moment(endDate).format("MMM YYYY")
  );
}
