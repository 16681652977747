import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  image: css({
    objectFit: "contain",
    flexShrink: 0,
  }),
  info: {
    other: {
      duration: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      name: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        gap: "8px",
        "@media (max-width: 425px)": {
          alignItems: "flex-start",
        },
      }),
      seat: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      flex: 1,
      gap: "8px 24px",
      minWidth: "288px",
      "@media (max-width: 640px)": {
        flex: "unset",
        minWidth: "unset",
        width: "100%",
      },
      "@media (max-width: 425px)": {
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "24px",
      },
    }),
    segments: {
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
      segment: {
        place: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "12px",
          width: "100%",
        }),
        time: css({
          color: theme.colors.gray[700],
          flexShrink: 0,
          fontWeight: 600,
          textAlign: "right",
          width: "48px",
          "@media (max-width: 640px)": {
            textAlign: "unset",
          },
        }),
      },
    },
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    width: "100%",
    "@media (max-width: 640px)": {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  }),
};
