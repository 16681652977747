import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  arrivalOptions: {
    checked: boolean;
    id: string | number;
    label: string;
  }[];
  disabled: boolean;
  handleArrivalFilterChange: (
    e: React.ChangeEvent<any>,
    checked: boolean,
  ) => void;
};

export const ArrivalTerminalFilter: React.FC<Props> = ({
  arrivalOptions,
  disabled,
  handleArrivalFilterChange,
}) => {
  if (arrivalOptions.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Terminal de Chegada</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {arrivalOptions.map((arrivalOption) => (
          <CheckboxGroup.Item
            checked={arrivalOption.checked}
            disabled={disabled}
            id={arrivalOption.id.toString()}
            key={arrivalOption.id}
            name={arrivalOption.label}
            onChange={handleArrivalFilterChange}
            value={arrivalOption.checked}
          >
            <span css={styles.checkbox.group.item.label}>
              {arrivalOption.label}
            </span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
