import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    color: theme.colors.pink[500],
    "& > svg": {
      strokeWidth: 2,
    },
  }),
  root: css({
    alignItems: "center",
    backgroundColor: "#FFE2E5",
    border: `1px solid ${theme.colors.pink[300]}`,
    borderRadius: "6px",
    display: "flex",
    gap: "8px",
    padding: "4px 16px",
    width: "fit-content",
  }),
  text: css({
    color: theme.colors.pink[500],
    fontSize: "0.75rem",
    fontWeight: 500,
  }),
};
