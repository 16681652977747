import { useEffect } from "react";

import isEmpty from "lodash/isEmpty";

import { useLocation } from "./use-location";

export const useLocationState = (callback: (params: any) => any) => {
  const { location } = useLocation();

  useEffect(() => {
    const locationState = location.state;

    if (locationState && !isEmpty(locationState)) {
      callback(locationState);
    }
  }, [callback, location]);
};
