import React, { useCallback, useEffect } from "react";

import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import {
  companyAreaBatchApi,
  updateCompanyAreasBatchApi,
} from "~/apps/shared/apis/excel.api";
import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Filters } from "~/apps/shared/components/filters/filters";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";
import { Option } from "~/apps/shared/types";

import { Button, Switch } from "@toolkit/v2";

import { AreaDrawer } from "./area-drawer/area-drawer";
import { AreasCustomName } from "./areas-custom-name/areas-custom-name";
import { AreasProvider, useAreas } from "./areas.context";
import { styles } from "./styles";

const DEFAULT_FILTERS: Option<ReturnType<typeof useAreas>["selectedTab"]>[] = [
  {
    label: "Ativas",
    value: "active",
  },
  {
    label: "Arquivadas",
    value: "archived",
  },
];

const Component: React.FC = () => {
  const { clientConfig } = useClientConfig();

  const {
    companyAreaDisplay,
    handleOpenCreateArea,
    handleOpenEditArea,
    handleToggleArchiveArea,
    handleToggleAreaRequired,
    loadClientAreas,
    selectedTab,
    toggleSelectedTab,
    visibleAreas,
    handleChangeSearchInput,
  } = useAreas();

  const isCompanyAreaEnabled = clientConfig
    ? clientConfig.isCompanyAreaEnabled()
    : false;

  const handleFilter = useCallback(
    (filter: string) => {
      if (filter === selectedTab.toString()) {
        return;
      }

      toggleSelectedTab();
    },
    [selectedTab, toggleSelectedTab],
  );

  useEffect(() => {
    void loadClientAreas();
  }, []);

  return (
    <>
      <PageTitle title={companyAreaDisplay} />
      <div css={styles.root}>
        <AreasCustomName />
        <div css={styles.header.root}>
          <Button
            onClick={handleOpenCreateArea}
            variant="pink"
            style={{ height: "3rem" }}
          >
            Adicionar área
          </Button>
          <div css={styles.header.right}>
            <Button
              fill="outlined"
              onClick={updateCompanyAreasBatchApi.onDownloadModelClick}
              style={{ height: "3rem" }}
            >
              <Icon use="cloud-arrow-down" />
              Exportar
            </Button>
            <BatchFileUploader
              onDownloadModelClick={
                updateCompanyAreasBatchApi.onDownloadModelClick
              }
              onSuccessUpload={loadClientAreas}
              onUpload={updateCompanyAreasBatchApi.onUpload}
            >
              {({ openDialog }) => (
                <Button
                  fill="outlined"
                  onClick={openDialog}
                  style={{ height: "3rem" }}
                >
                  <Icon use="cloud-arrow-up" />
                  Atualizar via excel
                </Button>
              )}
            </BatchFileUploader>
            <BatchFileUploader
              onDownloadModelClick={companyAreaBatchApi.onDownloadModelClick}
              onSuccessUpload={loadClientAreas}
              onUpload={companyAreaBatchApi.onUpload}
            >
              {({ openDialog }) => (
                <Button
                  fill="outlined"
                  onClick={openDialog}
                  style={{ height: "3rem" }}
                >
                  <Icon use="cloud-arrow-up" />
                  Cadastrar via excel
                </Button>
              )}
            </BatchFileUploader>
          </div>
        </div>
        <div css={styles.controls.root}>
          <SearchInput
            onChange={handleChangeSearchInput}
            placeholder="Pesquise por uma área..."
          />
          <Filters
            closeOnSelect
            onChange={(filter) => {
              handleFilter(filter);
            }}
            options={DEFAULT_FILTERS}
            value={selectedTab.toString()}
          />
        </div>
        <label css={styles.required.root}>
          <Switch
            active={isCompanyAreaEnabled}
            onChange={handleToggleAreaRequired}
            variant="pink"
          />
          <span css={styles.required.text}>
            Tornar areas obrigatório na compra de viagem
          </span>
        </label>
        <ul css={styles.list.root}>
          {visibleAreas.length === 0 ? (
            <span css={styles.empty}>Nenhuma área encontrada.</span>
          ) : (
            visibleAreas.map((area) => (
              <li
                css={styles.list.item.root}
                key={area.companyAreaToken}
                onClick={() => {
                  handleOpenEditArea(area.companyAreaToken);
                }}
              >
                <div css={styles.list.item.left.root}>
                  <span css={styles.list.item.left.name}>{area.name}</span>
                </div>
                <div css={styles.list.item.right.root}>
                  <div css={styles.list.item.right.count}>
                    {area.usersCount}
                    <Icon size={16} use="people" />
                  </div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();

                      handleOpenEditArea(area.companyAreaToken);
                    }}
                    shape="icon"
                  >
                    <Icon size={20} use="pencil" />
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();

                      handleToggleArchiveArea(
                        area.companyAreaToken,
                        !area.active,
                      );
                    }}
                    shape="icon"
                    variant="pink"
                  >
                    <Icon
                      size={area.active ? 20 : 18}
                      use={area.active ? "trash" : "archive-box-arrow-up"}
                    />
                  </Button>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
      <AreaDrawer />
    </>
  );
};

export const Areas: React.FC = () => (
  <AreasProvider>
    <Component />
  </AreasProvider>
);
