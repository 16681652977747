import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import {
  HospitalityHotelDetails,
  ICurrencyResponse,
} from "~/apps/corporate/dtos/hotel.dto";
import { HotelReviewListItem } from "~/apps/corporate/models/hotel-review.model";
import {
  HotelSearchInfo,
  NearbyPlace,
  FiltersInfo,
} from "~/apps/corporate/models/hotel.model";
import { HotelListOffer } from "~/apps/corporate/models/offer.model";
import { TravelInfo } from "~/apps/corporate/models/travel.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useSafeState } from "~/apps/shared/hooks/use-safe-state";
import { Error, StringTMap } from "~/apps/shared/types";
import moment from "moment";

import { useFilteredVisibleOffers } from "./hotel-result.hooks";
import * as hotelResultService from "./hotel-result.service";

interface State {
  brlExchangeRates: ICurrencyResponse;
  checkinHour: string | null;
  checkoutHour: string | null;
  dialogPictures: string[];
  errorOnFetch: Error | null;
  filtersInfo: FiltersInfo | null;
  hasExpired: boolean;
  hotelAmenities: string[];
  hotelInfo: HospitalityHotelDetails | null;
  isFavoriteDialogOpen: boolean;
  isLoadingFavorite: boolean;
  isPicturesDialogOpen: boolean;
  isRoomAmenitiesDialogOpen: boolean;
  loading: boolean;
  loadingReceptionHours: boolean;
  loadingRooms: boolean;
  nearbyPlaces: StringTMap<NearbyPlace[]>;
  outOfPolicySelectedOffer: HotelListOffer | null;
  policy: {
    price: number;
    stars: number;
    advance: number;
  } | null;
  roomAmenities: string[];
  roomOffers: HotelListOffer[];
  searchInfo: HotelSearchInfo | null;
  searchValidUntil: Date | null;
  supplierSearchToken: string;
  travelInfo: TravelInfo | null;
  userCurrency: string;
}

interface Filters {
  breakfastOnly: boolean;
  freeCancelationOnly: boolean;
}

interface Selectors {
  visibleOffers: HotelListOffer[];
}

interface Actions {
  closeFavoriteDialog: () => void;
  handleCloseOutOfPolicyDialog: () => void;
  handleClosePicturesDialog: () => void;
  handleCloseRoomAmenitiesDialog: () => void;
  handleOpenOutOfPolicyDialog: (offer: HotelListOffer) => () => void;
  handleOpenRoomAmenitiesDialog: (amenities: string[]) => () => void;
  handleSelectOffer: (offer: HotelListOffer) => () => void;
  handleSetExpiration: (hasExpiration: boolean) => void;
  handleShowRoomPictures: (pictures: string[]) => () => void;
  handleToggleBreakfastOnly: () => void;
  handleToggleFreeCancelationOnly: () => void;
  loadHotelDetails: (searchToken: string, hotelId: string) => Promise<void>;
  loadHotelRooms: (
    hospitalityHotelToken: string,
    searchHotelToken: string,
  ) => Promise<void>;
  loadReceptionHours: (hospitalityHotelToken: string) => void;
  openFavoriteDialog: () => void;
  removeHotelFavorite: (favouriteToken: string) => Promise<void>;
  setHotelFavorite: (
    hotel: HospitalityHotelDetails,
    targetType: string,
  ) => Promise<void>;
}

type ContextProps = State & Selectors & { filters: Filters } & Actions;

const initialState: State = {
  brlExchangeRates: {
    rates: {},
    sourceCurrency: "",
    success: false,
    timestamp: "",
  },
  checkinHour: null,
  checkoutHour: null,
  dialogPictures: [],
  errorOnFetch: null,
  filtersInfo: null,
  hasExpired: false,
  hotelAmenities: [],
  hotelInfo: null,
  isFavoriteDialogOpen: false,
  isLoadingFavorite: false,
  isPicturesDialogOpen: false,
  isRoomAmenitiesDialogOpen: false,
  loading: false,
  loadingReceptionHours: false,
  loadingRooms: false,
  nearbyPlaces: {},
  outOfPolicySelectedOffer: null,
  policy: null,
  roomAmenities: [],
  roomOffers: [],
  searchInfo: null,
  searchValidUntil: null,
  supplierSearchToken: "",
  travelInfo: null,
  userCurrency: "",
};

const filtersInitialState = {
  freeCancelationOnly: false,
  breakfastOnly: false,
};

const HotelResultContext = createContext<ContextProps>({
  ...initialState,
  filters: filtersInitialState,
  closeFavoriteDialog: () => null,
  handleCloseOutOfPolicyDialog: () => null,
  handleClosePicturesDialog: () => null,
  handleCloseRoomAmenitiesDialog: () => null,
  handleOpenOutOfPolicyDialog: () => () => null,
  handleOpenRoomAmenitiesDialog: () => () => null,
  handleSelectOffer: () => () => null,
  handleSetExpiration: () => null,
  handleShowRoomPictures: () => () => null,
  handleToggleBreakfastOnly: () => null,
  handleToggleFreeCancelationOnly: () => null,
  loadHotelDetails: async () => undefined,
  loadHotelRooms: async () => undefined,
  loadReceptionHours: () => null,
  openFavoriteDialog: () => async () => undefined,
  removeHotelFavorite: async () => {
    return;
  },
  setHotelFavorite: async () => {
    return;
  },
  visibleOffers: [],
});

// Hotel review

interface HotelReviewsState {
  hotelReviews: HotelReviewListItem[];
  isReviewsDialogOpen: boolean;
  visibleReviews: HotelReviewListItem[];
}

interface HotelReviewsActions {
  handleCloseHotelReviewsDialog: () => void;
  handleOpenHotelReviewsDialog: () => void;
}

const hotelReviewsInitialState: HotelReviewsState = {
  hotelReviews: [
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
  ],
  isReviewsDialogOpen: false,
  visibleReviews: [
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
    {
      cleaningGrade: 0,
      clientToken: "string",
      createdAt: moment(moment.now()),
      generalGrade: "0",
      hotelCity: "string",
      hotelContinent: "string",
      hotelCountry: "string",
      hotelIdentification: "string",
      hotelName: "string",
      hotelReviewToken: "string",
      hotelState: "string",
      locationGrade: 0,
      negativeReview: "string",
      offerToken: "string",
      positiveReview: "string",
      reviewTitle: "string",
      roomGrade: 0,
      treatmentGrade: 0,
      userToken: "string",
      wifiGrade: 0,
    },
  ],
};

const HotelReviewsContext = createContext({} as HotelReviewsState);
const HotelReviewsActionsContext = createContext({} as HotelReviewsActions);

export const HotelResultProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [filters, setFilters] = useSafeState<Filters>(filtersInitialState);
  const [hotelReviewsState, setHotelReviewsState] = useSafeState(
    hotelReviewsInitialState,
  );
  const [state, setState] = useSafeState<State>(initialState);

  const visibleOffers = useFilteredVisibleOffers(state.roomOffers, filters);

  const loadReceptionHours = async (hospitalityHotelToken: string) => {
    setState({ loadingReceptionHours: true });

    const { data, error } = await hotelResultService.getReceptionHours(
      hospitalityHotelToken,
    );

    if (error) {
      return setState({
        loadingReceptionHours: false,
        checkinHour: null,
        checkoutHour: null,
      });
    }

    setState({
      loadingReceptionHours: false,
      checkinHour: data?.checkInHour || null,
      checkoutHour: data?.checkOutHour || null,
    });
  };

  const loadHotelDetails = async (
    hotelToken: string,
    hospitalityHotelToken: string,
  ) => {
    setState({
      errorOnFetch: null,
      loading: true,
    });

    const { data, error } = await hotelResultService.loadHotelDetails(
      hotelToken,
      hospitalityHotelToken,
    );

    if (error) {
      setState({
        errorOnFetch: error,
        loading: false,
      });

      return;
    }

    const { latitude, longitude } = data!.details;
    const { data: placesData } = await hotelResultService.getHotelNearbyPlaces(
      latitude,
      longitude,
    );

    const checkinHour = data!.details.checkInHour.informed
      ? data!.details.checkInHour.hour
      : null;
    const checkoutHour = data!.details.checkOutHour.informed
      ? data!.details.checkOutHour.hour
      : null;

    setState({
      checkinHour: checkinHour,
      checkoutHour: checkoutHour,
      hotelAmenities: data!.details.extraAmenities,
      hotelInfo: data!.details,
      loading: false,
      nearbyPlaces: placesData ? placesData : {},
      searchInfo: data!.searchInfo,
    });
  };

  const handleSetExpiration = useCallback((hasExpired) => {
    setState({ hasExpired: hasExpired });
  }, []);

  const loadHotelRooms = async (
    hotelToken: string,
    hotelId: string,
  ): Promise<void> => {
    setState({
      loadingRooms: true,
    });

    const { data, error } = await hotelResultService.loadHotelRooms(
      hotelToken,
      hotelId,
    );

    if (error) {
      return setState({
        loadingRooms: false,
      });
    }

    setState({
      loadingRooms: false,
      roomOffers: data!.rooms,
      supplierSearchToken: data!.searchToken,
      policy: {
        price: data!.policy.maxValue,
        stars: data!.policy.stars,
        advance: data!.policy.advance,
      },
      searchValidUntil: data!.searchValidUntil,
    });
  };

  const handleSelectOffer = (offer: HotelListOffer) => async () => {
    setState({ loading: true });

    const { policy, searchInfo, supplierSearchToken } = state;

    const policyStatus = {
      ...policy,
      outOfPolicy: offer.outOfPolicy,
    };

    const { error } = await hotelResultService.addHotelOffer(
      offer.roomId,
      searchInfo!.hotelToken,
      supplierSearchToken,
      searchInfo!.travelToken,
      policyStatus,
    );

    if (error) {
      setState({ loading: false });
      showSnackMessage(error.description, "error");

      return;
    }

    navigate(`/travels/${searchInfo!.travelToken}/itinerary`);

    setState({ loading: false });
    showSnackMessage("Oferta selecionada com sucesso!", "success");
  };

  const setHotelFavorite = async (
    hotel: HospitalityHotelDetails,
    targetType: string,
  ) => {
    if (!user) {
      return;
    }

    setState({ isLoadingFavorite: true });

    const targetToken =
      targetType === "u" ? user.getUserToken() : user.getClientToken()!;

    const { data, error } = await hotelResultService.setFavorite(
      hotel,
      targetType,
      targetToken,
    );

    if (error) {
      setState({ isLoadingFavorite: false });
      showSnackMessage(error.description, "error");

      return;
    }

    const updatedHotel: HospitalityHotelDetails = {
      ...state.hotelInfo!,
      favorites: state.hotelInfo!.favorites.concat({
        type: targetType,
        token: data!.favouriteToken,
      }),
    };

    setState({
      hotelInfo: updatedHotel,
      isLoadingFavorite: false,
      isFavoriteDialogOpen: false,
    });

    showSnackMessage(
      "Hotel adicionado aos favoritos com sucesso.",
      ALERT_TYPES.SUCCESS,
    );
  };

  const openFavoriteDialog = () => {
    setState({
      isFavoriteDialogOpen: true,
    });
  };

  const removeHotelFavorite = async (favoriteToken: string) => {
    setState({ isLoadingFavorite: true });

    const { error } = await hotelResultService.removeFromFavorites(
      favoriteToken,
    );

    if (error) {
      setState({ isLoadingFavorite: false });
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    const updatedHotelFavorites = state.hotelInfo!.favorites.filter(
      (favorite) => favorite.token !== favoriteToken,
    );

    setState({
      isLoadingFavorite: false,
      hotelInfo: {
        ...state.hotelInfo!,
        favorites: updatedHotelFavorites,
      },
      isFavoriteDialogOpen: false,
    });

    showSnackMessage(
      "Hotel removido dos favoritos com sucesso.",
      ALERT_TYPES.SUCCESS,
    );
  };

  const handleOpenOutOfPolicyDialog = (offer: HotelListOffer) => () => {
    setState({
      outOfPolicySelectedOffer: offer,
    });
  };

  const handleCloseOutOfPolicyDialog = () => {
    setState({
      outOfPolicySelectedOffer: null,
    });
  };

  const closeFavoriteDialog = () => {
    setState({
      isFavoriteDialogOpen: false,
    });
  };

  const handleShowRoomPictures = (pictures: string[]) => () => {
    setState({
      isPicturesDialogOpen: true,
      dialogPictures: pictures,
    });
  };

  const handleClosePicturesDialog = () => {
    setState({
      isPicturesDialogOpen: false,
      dialogPictures: [],
    });
  };

  const handleOpenRoomAmenitiesDialog = (amenities: string[]) => () => {
    setState({
      isRoomAmenitiesDialogOpen: true,
      roomAmenities: amenities,
    });
  };

  const handleCloseRoomAmenitiesDialog = () => {
    setState({
      isRoomAmenitiesDialogOpen: false,
    });
  };

  // filters

  const handleToggleFreeCancelationOnly = () => {
    setFilters({ freeCancelationOnly: !filters.freeCancelationOnly });
  };

  const handleToggleBreakfastOnly = () => {
    setFilters({ breakfastOnly: !filters.breakfastOnly });
  };

  // Hotel reviews
  const handleOpenHotelReviewsDialog = () => {
    // setHotelReviewsState({ isReviewsDialogOpen: true });
  };

  const handleCloseHotelReviewsDialog = () => {
    // setHotelReviewsState({ isReviewsDialogOpen: false });
  };

  return (
    <HotelResultContext.Provider
      value={{
        ...state,
        filters,
        closeFavoriteDialog,
        handleCloseOutOfPolicyDialog,
        handleClosePicturesDialog,
        handleCloseRoomAmenitiesDialog,
        handleOpenOutOfPolicyDialog,
        handleOpenRoomAmenitiesDialog,
        handleSelectOffer,
        handleSetExpiration,
        handleShowRoomPictures,
        handleToggleBreakfastOnly,
        handleToggleFreeCancelationOnly,
        loadHotelDetails,
        loadHotelRooms,
        loadReceptionHours,
        openFavoriteDialog,
        removeHotelFavorite,
        setHotelFavorite,
        visibleOffers,
      }}
    >
      <HotelReviewsContext.Provider value={hotelReviewsState}>
        <HotelReviewsActionsContext.Provider
          value={{
            handleCloseHotelReviewsDialog,
            handleOpenHotelReviewsDialog,
          }}
        >
          {children}
        </HotelReviewsActionsContext.Provider>
      </HotelReviewsContext.Provider>
    </HotelResultContext.Provider>
  );
};

export const useHotelResult = () => useContext(HotelResultContext);

export const useHotelReviewsActions = () =>
  useContext(HotelReviewsActionsContext);

export const useHotelReviews = () => useContext(HotelReviewsContext);
