import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { OfferStatus } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

type Props = {
  freeCancelationUntil?: string;
  isCancelable?: boolean;
  message?: string;
  serviceStatus: OfferStatus;
};

export const TravelPlanPDFServiceCancelationUntil: React.FC<Props> = ({
  freeCancelationUntil,
  isCancelable,
  message,
  serviceStatus,
}) => {
  const badgeStyles = styles.badge();

  const showMessage =
    serviceStatus !== OfferStatus.BOOKING_FAILED &&
    serviceStatus !== OfferStatus.CANCELED;

  if (!freeCancelationUntil || !isCancelable || !showMessage) {
    return null;
  }

  return (
    <View style={badgeStyles.root}>
      <PDFIcon size={14} use="money-withdraw" />
      <View>
        <Text>
          Cancelamento grátis até {freeCancelationUntil}{" "}
          {message ? message : ""}.
        </Text>
      </View>
    </View>
  );
};
