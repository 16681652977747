import React from "react";
import Skeleton from "react-loading-skeleton";

import { TransmissionType } from "~/apps/corporate/models/car.model";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  contract: {
    features: {
      ali?: boolean;
      ldw?: boolean;
      freeCancelation?: boolean;
      unlimitedKm?: boolean;
    };
  };
  diffDropOff?: boolean;
  dropOff: {
    address: string;
    storeName: string;
    time: string;
  };
  handleShowOnMap?: () => void;
  model: {
    description: string;
    features: {
      abs?: boolean;
      air?: boolean;
      airbag?: boolean;
      baggages?: number;
      doors?: number;
      passengers?: number;
      steering?: string;
      transmission?: TransmissionType;
    };
    image?: string;
    title: string;
  };
  pickUp: {
    address: string;
    storeName: string;
    time: string;
  };
  selectedAdditionals: {
    code: string;
    name: string;
  }[];
};

export const ItineraryCarSegment: React.FC<Props> = ({
  contract,
  diffDropOff,
  dropOff,
  handleShowOnMap,
  model,
  pickUp,
  selectedAdditionals,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.heading.root}>
        <span css={styles.heading.title}>{model.title}</span>
        <span css={styles.heading.description}>{model.description}</span>
      </div>
      <div css={styles.car.root}>
        {model.image ? (
          <img
            alt={model.description}
            css={styles.car.image}
            src={model.image}
          />
        ) : null}
        <div css={styles.car.features}>
          <ul css={styles.car.column.root}>
            <li css={styles.car.column.feature.gray}>
              <Icon size={16} use="people" />
              <span>{model.features.passengers} passageiros</span>
            </li>
            <li css={styles.car.column.feature.gray}>
              <Icon size={16} use="car-door" />
              <span>{model.features.doors} portas</span>
            </li>
            <li css={styles.car.column.feature.gray}>
              <Icon size={16} use="transmission" />
              <span>
                {model.features.transmission === TransmissionType.MANUAL
                  ? "Manual"
                  : "Automático"}
              </span>
            </li>
            {model.features.air ? (
              <li css={styles.car.column.feature.gray}>
                <Icon size={18} use="snow" />
                <span>Ar condicionado</span>
              </li>
            ) : null}
          </ul>
          <ul css={styles.car.column.root}>
            {contract.features.ldw ? (
              <li css={styles.car.column.feature.green}>
                <Icon size={16} use="shield-check" />
                <span>Proteção LDW</span>
              </li>
            ) : null}
            {contract.features.ali ? (
              <li css={styles.car.column.feature.green}>
                <Icon size={16} use="shield-check" />
                <span>Proteção ALI</span>
              </li>
            ) : null}
            {contract.features.unlimitedKm ? (
              <li css={styles.car.column.feature.green}>
                <Icon size={16} use="speedometer" />
                <span>Quilometragem livre</span>
              </li>
            ) : null}
            {contract.features.freeCancelation ? (
              <li css={styles.car.column.feature.green}>
                <Icon size={16} use="calendar-x" />
                <span>Cancelamento grátis</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <div css={styles.location.root}>
        <div css={styles.location.rental.root}>
          <Icon css={styles.location.rental.icon} size={16} use="map-pin" />
          <div css={styles.location.rental.text}>
            <span>Retirada às {pickUp.time}h:</span>{" "}
            <span css={styles.location.rental.capitalize}>
              {pickUp.storeName.toLowerCase()}
            </span>
            ,{" "}
            <span css={styles.location.rental.capitalize}>
              {pickUp.address.toLowerCase()}
            </span>
            .
          </div>
        </div>
        <div css={styles.location.rental.root}>
          <Icon css={styles.location.rental.icon} size={16} use="map-pin" />
          <div css={styles.location.rental.text}>
            <span>Devolução às {dropOff.time}h:</span>{" "}
            {diffDropOff ? (
              <>
                <span css={styles.location.rental.capitalize}>
                  {dropOff.storeName.toLowerCase()}
                </span>
                ,{" "}
                <span css={styles.location.rental.capitalize}>
                  {dropOff.address.toLowerCase()}
                </span>
                .
              </>
            ) : (
              <span>No mesmo local.</span>
            )}
          </div>
          {handleShowOnMap ? (
            <button css={styles.location.map.root} onClick={handleShowOnMap}>
              <Icon size={16} use="map" />
              <span css={styles.location.map.label}>Ver no mapa</span>
            </button>
          ) : null}
        </div>
      </div>
      {selectedAdditionals.length > 0 ? (
        <div css={styles.additionals.root}>
          <ul css={styles.additionals.additionals.root}>
            {selectedAdditionals.slice(0, 4).map((additional) => (
              <li
                css={styles.additionals.additionals.additional}
                key={additional.code}
              >
                <Icon size={16} use="check" />
                <span>{additional.name}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export const ItineraryCarSegmentSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.heading.root}>
        <Skeleton height="18px" width="192px" />
        <Skeleton height="14px" width="144px" />
      </div>
      <div css={styles.car.root}>
        <Skeleton height="103px" width="144px" />
        <div css={styles.car.features}>
          <ul css={styles.car.column.root}>
            <li css={styles.car.column.feature.gray}>
              <Skeleton height="16px" width="16px" />
              <Skeleton height="14px" width="96px" />
            </li>
            <li css={styles.car.column.feature.gray}>
              <Skeleton height="16px" width="16px" />
              <Skeleton height="14px" width="72px" />
            </li>
            <li css={styles.car.column.feature.gray}>
              <Skeleton height="16px" width="16px" />
              <Skeleton height="14px" width="64px" />
            </li>
          </ul>
          <ul css={styles.car.column.root}>
            <li css={styles.car.column.feature.green}>
              <Skeleton height="16px" width="16px" />
              <Skeleton height="14px" width="128px" />
            </li>
            <li css={styles.car.column.feature.green}>
              <Skeleton height="16px" width="16px" />
              <Skeleton height="14px" width="128px" />
            </li>
          </ul>
        </div>
      </div>
      <div css={styles.location.root}>
        <div css={styles.location.rental.root}>
          <Skeleton height="16px" width="16px" />
          <div css={styles.location.rental.text}>
            <Skeleton height="16px" width="192px" />
          </div>
        </div>
        <div css={styles.location.rental.root}>
          <div css={styles.location.rental.root}>
            <Skeleton height="16px" width="16px" />
            <div css={styles.location.rental.text}>
              <Skeleton height="16px" width="192px" />
            </div>
          </div>
        </div>
      </div>
      <div css={styles.additionals.root}>
        <ul css={styles.additionals.additionals.root}>
          <li css={styles.additionals.additionals.additional}>
            <Skeleton height="16px" width="16px" />
            <Skeleton height="14px" width="128px" />
          </li>
          <li css={styles.additionals.additionals.additional}>
            <Skeleton height="16px" width="16px" />
            <Skeleton height="14px" width="96px" />
          </li>
        </ul>
      </div>
    </div>
  );
};
