import React from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  ComposedChart,
  ResponsiveContainer
} from "recharts";
import { theme } from "smartrips-skin";

import { styles, tooltipStyles } from "../../common-styles";
import { AdvanceRangeChartProps } from "./AdvanceRangeChart.types";

function getRangeLabelY(stringRange: string) {
  return stringRange === ">28" ? "28 +" : stringRange.split("-").join(" a ");
}

function getRangeLabelX(stringRange: string) {
  return stringRange === ">28"
    ? "Mais de 28 dias"
    : "".concat("Entre ", stringRange.split("-").join(" e "), " dias");
}

function CustomizedAxisXTick(props) {
  const { x, y, payload } = props;
  const label = getRangeLabelY(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {label}
      </text>
    </g>
  );
}

function CustomizedAxisYTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
}

export function AdvanceRangeChart(props: AdvanceRangeChartProps) {
  return (
    <ResponsiveContainer width="100%" height={330}>
      <ComposedChart
        data={props.data}
        margin={{ top: 40, right: 30, left: 0, bottom: 20 }}
      >
        <CartesianGrid
          strokeDasharray="5 10"
          vertical={false}
          horizontal={false}
        />
        <XAxis
          interval={0}
          axisLine={true}
          dataKey="advanceRange"
          tick={<CustomizedAxisXTick />}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick />}
        />
        <Tooltip
          {...tooltipStyles}
          labelFormatter={name => {
            return getRangeLabelX(name);
          }}
        />
        <Bar
          maxBarSize={32}
          name={props.barTitle}
          dataKey="count"
          fill={theme.colors.primary}
          stroke={theme.colors.primary}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
