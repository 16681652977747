import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  container: css({
    margin: "0 auto",
    "@media (min-width: 768px)": {
      maxWidth: "calc(692px + 64px)",
    },
  }),
  loader: css({
    position: "relative",
    height: "50px",
    marginTop: "5rem",
    ".MuiCircularProgress-root": {
      color: "primary",
    },
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "24px",
    margin: "0 0 64px 0",
    padding: "64px 0",
    "@media (max-width: 768px)": {
      padding: "32px 0",
    },
  }),
  tabs: {
    root: ecss({
      minHeight: "unset !important",
      padding: "0 32px",
      "@media (max-width: 768px)": {
        padding: "0",
      },
    }),
    scroller: ecss({
      border: `none !important`,
      borderRadius: 0,
      overflow: "visible",
      maxHeight: 38,
    }),
    tab: {
      root: ecss({
        fontFamily: "'Open sans', sans-serif",
        fontSize: 14,
        letterSpacing: "0.04rem",
        minHeight: "unset !important",
        minWidth: "unset !important",
        padding: "8px",
        textTransform: "none",
      }),
      selected: ecss({
        color: theme.colors.primary,
        fontWeight: "bold",
      }),
    },
  },
};
