import React from "react";

import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { ItineraryPendenciesModel } from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import * as yup from "yup";

import { styles } from "./styles";

class CategorizationAccordionFormDefaultValuesCalculator {
  constructor(private readonly infoModel: ItineraryInfoModel) {}

  public calculate() {
    return {
      billingProfileToken: this.infoModel.getBillingProfileToken(),
      companyAreaToken: this.infoModel.getCompanyAreaToken(),
      costCenterToken: this.infoModel.getCostCenterToken(),
      projectToken: this.infoModel.getProjectToken(),
    };
  }
}

class CategorizationAccordionFormSchemaCalculator {
  constructor(private readonly pendenciesModel: ItineraryPendenciesModel) {}

  public calculate() {
    return yup.object().shape({
      billingProfileToken:
        this.pendenciesModel.isBillingProfilePending() &&
        this.pendenciesModel.isBillingProfileRequired()
          ? yup.string().required("Selecione um CNPJ.").nullable(true)
          : yup.string().nullable(true),
      companyAreaToken:
        this.pendenciesModel.isCompanyAreaPending() &&
        this.pendenciesModel.isCompanyAreaRequired()
          ? yup.string().required("Selecione uma área.").nullable(true)
          : yup.string().nullable(true),
      costCenterToken:
        this.pendenciesModel.isCostCenterPending() &&
        this.pendenciesModel.isCostCenterRequired()
          ? yup
              .string()
              .required("Selecione um centro de custo.")
              .nullable(true)
          : yup.string().nullable(true),
      projectToken:
        this.pendenciesModel.isProjectPending() &&
        this.pendenciesModel.isProjectRequired()
          ? yup.string().required("Selecione um projeto.").nullable(true)
          : yup.string().nullable(true),
    });
  }
}

class CategorizationAccordionPresenter {
  constructor(
    private readonly infoModel: ItineraryInfoModel,
    private readonly pendenciesModel: ItineraryPendenciesModel,
  ) {}

  public calculateFormDefaultValues() {
    return new CategorizationAccordionFormDefaultValuesCalculator(
      this.infoModel,
    ).calculate();
  }

  public calculateFormSchema() {
    return new CategorizationAccordionFormSchemaCalculator(
      this.pendenciesModel,
    ).calculate();
  }

  public getTravelerToken() {
    return this.infoModel.getTravelerToken();
  }

  private renderCategorizationSectionLabelOptionalSuffix() {
    return <span css={styles.optional}> (opcional)</span>;
  }

  public renderBillingProfileSectionLabel() {
    return (
      <span css={styles.form.select.label}>
        CNPJ
        {this.pendenciesModel.isBillingProfileRequired()
          ? "*"
          : this.renderCategorizationSectionLabelOptionalSuffix()}
      </span>
    );
  }

  public renderCompanyAreaSectionLabel() {
    return (
      <span css={styles.form.select.label}>
        Área
        {this.pendenciesModel.isCompanyAreaRequired()
          ? "*"
          : this.renderCategorizationSectionLabelOptionalSuffix()}
      </span>
    );
  }

  public renderCostCenterSectionLabel() {
    return (
      <span css={styles.form.select.label}>
        Centro de custo
        {this.pendenciesModel.isCostCenterRequired()
          ? "*"
          : this.renderCategorizationSectionLabelOptionalSuffix()}
      </span>
    );
  }

  public renderProjectSectionLabel() {
    return (
      <span css={styles.form.select.label}>
        Projeto
        {this.pendenciesModel.isProjectRequired()
          ? "*"
          : this.renderCategorizationSectionLabelOptionalSuffix()}
      </span>
    );
  }

  public renderTravelTagsSectionLabel() {
    return (
      <span css={styles.form.select.label}>
        Etiquetas
        {this.pendenciesModel.isTravelTagsRequired()
          ? "*"
          : this.renderCategorizationSectionLabelOptionalSuffix()}
      </span>
    );
  }

  public shouldFetchUserBillingProfiles() {
    return this.pendenciesModel.isBillingProfilePending();
  }

  public shouldFetchUserCompanyAreas() {
    return this.pendenciesModel.isCompanyAreaPending();
  }

  public shouldFetchUserCostCenters() {
    return this.pendenciesModel.isCostCenterPending();
  }

  public shouldFetchUserProjects() {
    return this.pendenciesModel.isProjectPending();
  }

  public showBillingProfileSection() {
    return this.pendenciesModel.isBillingProfilePending();
  }

  public showCompanyAreaSection() {
    return this.pendenciesModel.isCompanyAreaPending();
  }

  public showCostCenterSection() {
    return this.pendenciesModel.isCostCenterPending();
  }

  public showProjectSection() {
    return this.pendenciesModel.isProjectPending();
  }

  public showTravelTagsSection() {
    return this.pendenciesModel.isTravelTagsPending();
  }
}

export class CategorizationAccordionPresenterFactory {
  static create(
    infoModel: ItineraryInfoModel,
    pendenciesModel: ItineraryPendenciesModel,
  ) {
    return new CategorizationAccordionPresenter(infoModel, pendenciesModel);
  }
}
