import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  section: {
    approved: {
      icon: css({
        color: theme.colors.green[500],
      }),
      link: css({
        alignItems: "center",
        backgroundColor: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.gray[500],
        borderRadius: "8px",
        color: theme.colors.gray[500],
        display: "flex",
        fontSize: "0.875rem",
        fontWeight: 500,
        gap: "12px",
        justifyContent: "center",
        lineHeight: "1rem",
        padding: "14px 24px",
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
          borderColor: theme.colors.gray[700],
        },
      }),
      message: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.25",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "12px",
      }),
    },
    buttons: {
      button: css({
        fontSize: "0.875rem",
        justifyContent: "center",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    declined: {
      icon: css({
        color: theme.colors.pink[500],
      }),
      link: css({
        alignItems: "center",
        backgroundColor: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.gray[500],
        borderRadius: "8px",
        color: theme.colors.gray[500],
        display: "flex",
        fontSize: "0.875rem",
        fontWeight: 500,
        gap: "12px",
        justifyContent: "center",
        lineHeight: "1rem",
        padding: "14px 24px",
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
          borderColor: theme.colors.gray[700],
        },
      }),
      message: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.25",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "12px",
      }),
    },
    justification: {
      buttons: css({
        display: "flex",
        gap: "8px",
        justifyContent: "flex-end",
        width: "100%",
      }),
      info: css({
        color: theme.colors.gray[700],
        fontSize: "0.75rem",
        fontWeight: 700,
        marginBottom: "8px",
      }),
      label: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.25",
      }),
      name: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      optional: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      textarea: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        fontFamily: theme.fonts.inter,
        height: "148px",
        outline: "none",
        marginTop: "8px",
        padding: "13px 16px",
        resize: "none",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
    },
    message: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
      lineHeight: "1.125",
      padding: "0 24px",
      textAlign: "center",
    }),
    request: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      lineHeight: "1.125",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    strong: css({
      fontWeight: 600,
    }),
  },
  top: {
    policy: css({
      alignItems: "center",
      color: theme.colors.pink[500],
      display: "flex",
      gap: "8px",
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    root: css({
      display: "flex",
      gap: "16px",
      justifyContent: "space-between",
    }),
    title: ({
      isApproved,
      isDenied,
    }: {
      isApproved?: boolean;
      isDenied?: boolean;
    }) =>
      css({
        color: isApproved
          ? theme.colors.green[500]
          : isDenied
          ? theme.colors.pink[500]
          : theme.colors.gray[700],
        fontWeight: 600,
      }),
  },
};
