import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    root: css({
      paddingTop: "24px",
    }),
  },
  message: css({
    color: theme.colors.gray[700],
    lineHeight: "1.125",
  }),
  root: css({
    ".MuiPaper-root": {
      "@media (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
};
