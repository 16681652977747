import React from "react";

import { css } from "@emotion/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core/";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { Box, Paragraph } from "@toolkit";

import {
  EXPENSES_FAMILIES_TRANSLATION,
  EXPENSES_CATEGORIES,
  EXPENSE_STATUS
} from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { Expense } from "@models/expense.model";

import { defaultTheme } from "@theme";
import { colors } from "@theme";

import { CoinsTooltip } from "~/components/shared/coins-tooltip";

const styles = {
  headerCell: css({
    fontSize: 14,
    padding: "0.5rem 0",
    fontWeight: "bold",
    border: "none"
  }),
  familyName: css({
    border: "none",
    padding: 0,
    paddingTop: "1rem",
    fontSize: 14,
    color: defaultTheme.subTextColor,
    fontWeight: "bold"
  }),
  bodyCell: css({
    padding: "0.5rem 0.25rem",
    fontSize: 14,
    position: "relative"
  }),
  partialRefundIcon: css({
    position: "absolute",
    width: "1rem",
    height: "1.2rem",
    right: -15,
    color: defaultTheme.reds.lightRed
  }),
  row: css({
    ["&:last-child .MuiTableCell-body"]: {
      borderBottom: 0
    }
  })
};

interface Props {
  expenses: Expense[];
  firstColumnName?: string;
  secondColumnName?: string;
  showBothColumns?: boolean;
}

type Map<T> = Record<string, T>;

const addItemToMap = (
  map: Map<any>,
  itemKey: string,
  itemValue: Expense | string
) => {
  map[itemKey] ? map[itemKey].push(itemValue) : (map[itemKey] = [itemValue]);
  return map;
};

const removeDuplicatedMapValues = (map: Map<string[]>): Map<string[]> => {
  const newMap = Object.assign({});
  for (const key in map) {
    newMap[key] = [...new Set(map[key])];
  }
  return newMap;
};

const createExpensesCategoriesMap = (expenses: Expense[]) => {
  return expenses.reduce((acc, expense: Expense) => {
    if (!expense.expenseCategoryName) return acc;

    addItemToMap(acc, expense.expenseCategoryName, expense);
    return acc;
  }, {} as Map<Expense[]>);
};

const createCategoriesFamiliesMap = (expenses: Expense[]) => {
  return expenses.reduce((acc, expense: Expense) => {
    if (!expense.expenseCategoryName) return acc;

    addItemToMap(acc, expense.expenseFamily, expense.expenseCategoryName);
    return acc;
  }, {} as Map<string[]>);
};

const getTotalValueOfCategory = (
  expenseCategory: string,
  categoriesObj: Map<Expense[]>
) => {
  const totalAmount = categoriesObj[expenseCategory].reduce((acc, cur) => {
    if (cur.value) {
      acc += expensesHelper.convertCurrencyValue(cur);
    }
    return acc;
  }, 0);
  return utils.toCurrency(totalAmount);
};

const getTotalApprovedValueOfCategory = (
  expenseCategory: string,
  categoriesObj: Map<Expense[]>
) => {
  const totalAmount = categoriesObj[expenseCategory].reduce((acc, cur) => {
    if (cur.approvedValue) {
      acc += cur.approvedValue;
    }
    return acc;
  }, 0);

  return utils.toCurrency(totalAmount);
};

const CategoriesBreakdown: React.FC<Props> = ({
  expenses,
  firstColumnName = "Solicitado",
  secondColumnName = "Aprovado",
  showBothColumns = false
}) => {
  const filteredExpenses = expenses
    ? expenses.filter(
        expense =>
          !expense.canceled &&
          expense.status !== EXPENSE_STATUS.CREATED_AUTO &&
          expense.expenseCategory !== EXPENSES_CATEGORIES.EXPENSE_FEE
      )
    : [];
  const categoriesFamiliesMap = createCategoriesFamiliesMap(filteredExpenses);
  const uniqueCategoriesFamiliesMap = removeDuplicatedMapValues(
    categoriesFamiliesMap
  );
  const expenseCategoriesMap = createExpensesCategoriesMap(filteredExpenses);

  return (
    <Box border={[null, `1px solid`]} borderColor={[null, "border"]} my="large">
      <Box
        p={"1.5rem 1.25rem"}
        fontWeight="bold"
        borderBottom={`1px solid`}
        borderColor="border"
        backgroundColor={[null, colors.linkWater]}
        style={{ display: "flex" }}
      >
        <span style={{ marginRight: "2px" }}>Despesas por categoria</span>{" "}
        {expensesHelper.hasForeignCurrencyExpenses(expenses) ? (
          <CoinsTooltip
            title={`As despesas criadas em moedas diversas foram convertidas para a moeda local (BRL) utilizando as taxas de conversão informadas no momento da criação, a fim de facilitar a elaboração dos relatórios.`}
            placement="right"
            iconSize="16px"
          />
        ) : null}
      </Box>
      <Box pb="small">
        {filteredExpenses.length ? (
          <Box p="1rem 1.5rem">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell css={styles.headerCell} align="left">
                    Categoria
                  </TableCell>
                  <TableCell align="right" css={styles.headerCell}>
                    {firstColumnName}
                  </TableCell>
                  {showBothColumns ? (
                    <TableCell align="right" css={styles.headerCell}>
                      {secondColumnName}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(uniqueCategoriesFamiliesMap).map(
                  ([expenseFamily, expenseCategories]) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell css={styles.familyName}>
                            {EXPENSES_FAMILIES_TRANSLATION[expenseFamily]}
                          </TableCell>
                        </TableRow>
                        {expenseCategories.map(category => {
                          const categoryTotal = getTotalValueOfCategory(
                            category,
                            expenseCategoriesMap
                          );
                          const categoryApprovedTotal = getTotalApprovedValueOfCategory(
                            category,
                            expenseCategoriesMap
                          );
                          return (
                            <TableRow css={styles.row} key={category}>
                              <TableCell css={styles.bodyCell}>
                                {category}
                              </TableCell>
                              <TableCell align="right" css={styles.bodyCell}>
                                {categoryTotal}
                              </TableCell>
                              {showBothColumns ? (
                                <TableCell align="right" css={styles.bodyCell}>
                                  {categoryApprovedTotal}
                                  {categoryTotal !== categoryApprovedTotal ? (
                                    <ArrowDownwardIcon
                                      css={styles.partialRefundIcon}
                                    />
                                  ) : null}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Paragraph
            color={defaultTheme.subTextColor}
            fontSize={13}
            p="1.5rem 0 1rem 1.25rem"
          >
            Nenhuma despesa reembolsável adicionada ao relatório
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};

export { CategoriesBreakdown };
