import { css } from "@styled-system/css";
import { darken } from "polished";

import { colors } from "~/assets/styles/theme";

import styled from "@shared/styled";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>(
  ({ theme, isFocused, isErrored }) =>
    css({
      height: 58,
      width: "100%",
      padding: "medium",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: isFocused ? darken(0.2, theme.colors.border) : "border",
      borderBottomColor: isErrored ? "cancel" : "border",
      borderRadius: 1,
      background: "white",
      position: "relative",
      transition: "all 0.2s",
      fontFamily: '"Open sans", sans-serif',

      display: "flex",
      alignItems: "center",

      ["&:hover"]: {
        borderColor: darken(0.2, theme.colors.border),
        borderBottomColor: isErrored
          ? "cancel"
          : darken(0.2, theme.colors.border)
      },

      ["input"]: {
        flex: 1,
        height: "100%",
        background: "transparent",
        border: 0,
        outline: "none",
        color: "text",
        fontSize: 2,
        lineHeight: "32px",

        ["&::placeholder"]: {
          color: "#999ba0"
        }
      },

      [".indicator"]: {
        position: "absolute",
        bottom: "-1px",
        left: "-1px",
        right: "-1px",
        opacity: 1,
        height: 2,
        backgroundColor: "primary",
        borderRadius: "0px 0px 4px 4px",
        transition: "transform 200ms ease-in-out 0s",
        transformOrigin: "center center",
        transform: isFocused ? "scaleX(1)" : "scaleX(0)"
      },

      // Icons styles

      ["svg"]: {
        fontSize: 20,
        color: colors.slateGray,

        ["&:first-of-type"]: {
          marginRight: "medium"
        },

        ["&:nth-of-type(2)"]: {
          marginLeft: "medium"
        }
      }
    })
);

export const Error = styled.span(
  css({
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  })
);
