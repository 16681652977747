export function downloadFile(args: {
  data: any;
  filename: string;
  options?: BlobPropertyBag;
}) {
  const url = window.URL.createObjectURL(new Blob([args.data], args.options));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", args.filename); //or any other extension

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

export function downloadFileFromUrl(args: { url: any; filename: string }) {
  const link = document.createElement("a");
  link.href = args.url;
  link.target = "_blank";
  link.setAttribute("download", args.filename); //or any other extension

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
