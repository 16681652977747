import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  select: css({
    width: "fit-content",
  }),
};
