import React, { useCallback, useState } from "react";

import { RouteComponentProps } from "@reach/router";
import { GetUsersRequestParams } from "~/apps/corporate/dtos/client.dto";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { ErrorWarning } from "~/apps/shared/components/error-warning/error-warning";
import { FilterableList } from "~/apps/shared/components/filterable-list/filterable-list";
import { formatServiceError } from "~/apps/shared/utils/errors";

import { UsersHeader } from "./users-header/users-header";
import { UsersList, UsersListSkeleton } from "./users-list/users-list";
import { useUsers } from "./users.hooks";

type Props = RouteComponentProps;

const Component: React.FC<Props> = ({ children }) => {
  const [params, setParams] = useState<
    GetUsersRequestParams & {
      visibility: "active" | "inactive";
    }
  >({
    visibility: "active",
  });

  const { errorOnFetchUsers, isLoadingUsers, refetchUsers, users } = useUsers(
    params,
  );

  const handleSuccessUpload = useCallback(async () => {
    await refetchUsers();
  }, [refetchUsers]);

  if (errorOnFetchUsers) {
    const formattedError = formatServiceError({ error: errorOnFetchUsers });

    return (
      <>
        <UsersHeader
          onSuccessUpload={handleSuccessUpload}
          selectedBillingProfile={params.billingProfileToken}
          selectedTab={params.visibility}
        />
        <ErrorWarning
          message={formattedError.description}
          title={formattedError.title}
        />
      </>
    );
  }

  if (isLoadingUsers) {
    return (
      <>
        <UsersHeader
          onSuccessUpload={handleSuccessUpload}
          selectedBillingProfile={params.billingProfileToken}
          selectedTab={params.visibility}
        />
        <UsersListSkeleton />
      </>
    );
  }

  if (!users) {
    return null;
  }

  return (
    <>
      <FilterableList
        data={users}
        filterData={(searchValue, data) => {
          const lowerCaseSearch = searchValue.toLowerCase();

          return data.filter(
            (user) =>
              user.fullName?.toLowerCase().includes(lowerCaseSearch) ||
              user.email.toLowerCase().includes(lowerCaseSearch),
          );
        }}
      >
        {({ filteredList, onFilter }) => (
          <>
            <UsersHeader
              onFilter={(value) => {
                setParams((prev) => ({
                  ...prev,
                  [value.field]: value.value,
                }));
              }}
              onSearch={(event) => {
                onFilter(event.target.value);
              }}
              onSuccessUpload={handleSuccessUpload}
              selectedBillingProfile={params.billingProfileToken}
              selectedTab={params.visibility}
            />
            <UsersList users={filteredList} visibility={params.visibility} />
          </>
        )}
      </FilterableList>
      {children}
    </>
  );
};

export const Users: React.FC<Props> = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <ErrorWarning />}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
