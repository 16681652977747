import React, { useCallback, useState } from "react";

import { Icon } from "../icon/icon";
import { Tooltip } from "../tooltip/tooltip";
import { styles } from "./styles";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
};

export const CopyIconButtonWithTooltip: React.FC<Props> = ({
  onClick,
  text,
  ...props
}) => {
  const [copiedTravelId, setCopiedTravelId] = useState(false);

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      await navigator.clipboard.writeText(text);

      if (copiedTravelId) {
        return;
      }

      onClick?.(e);

      setCopiedTravelId(true);

      setTimeout(() => {
        setCopiedTravelId(false);
      }, 2000);
    },
    [copiedTravelId, onClick, text],
  );

  return (
    <Tooltip content={copiedTravelId ? "Copiado!" : "Copiar"} position="top">
      <button css={styles.root} onClick={handleClick} {...props}>
        <Icon size={16} use={copiedTravelId ? "check" : "clipboard-document"} />
      </button>
    </Tooltip>
  );
};
