import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useReportDownload } from "../analytics-report-download.context";
import { ReportType } from "../analytics-report-download.types";
import { styles } from "./styles";

const REPORTS = [
  {
    items: [
      {
        description:
          "Dados para conferência de cobranças em cartões ou em boletos.",
        name: "Conciliação",
        type: ReportType.CONCILIATION,
      },
      {
        description: "Cancelamentos, Alterações e Créditos.",
        name: "Não viajados",
        type: ReportType.CANCELATION,
      },
      {
        description:
          "Contém todas as solicitações de aprovação de viagens realizadas no período.",
        name: "Solicitações de Aprovação",
        type: ReportType.APPROVAL,
      },
      {
        description:
          "Contém os gastos dos viajantes acumulados por viagens no período.",
        name: "Gasto por Viajante",
        type: ReportType.TRAVELER,
      },
      {
        description: "Dados das fees de viagem cobradas em determinado período",
        name: "Fees de Viagem",
        type: ReportType.TRAVEL_FEE,
      },
    ],
    title: "Geral",
  },
  {
    items: [
      {
        description: "Dados completos das ofertas de voo compradas.",
        name: "Voos",
        type: ReportType.FLIGHT,
      },
      {
        description: "Dados completos da adesão ou não à política.",
        name: "Adoção de Política",
        type: ReportType.FLIGHT_POLICY,
      },
      {
        description:
          "Informações completas sobre as alterações realizadas. Ex: Aprovador da alteração, diferença paga, etc.",
        name: "Alterações de Voos",
        type: ReportType.FLIGHT_CHANGE,
      },
    ],
    title: "Aéreo",
  },
  {
    items: [
      {
        description: "Dados completos das ofertas de hotel compradas.",
        name: "Hotéis",
        type: ReportType.HOTEL,
      },
      {
        description: "Dados completos da adesão ou não à política.",
        name: "Adoção de Política",
        type: ReportType.HOTEL_POLICY,
      },
    ],
    title: "Hospedagem",
  },
  {
    items: [
      {
        description: "Dados completos das ofertas de carro compradas.",
        name: "Aluguel de Carros",
        type: ReportType.CAR,
      },
      {
        description: "Dados completos das ofertas de ônibus compradas.",
        name: "Rodoviário",
        type: ReportType.BUS,
      },
    ],
    title: "Terrestres",
  },
  {
    items: [
      {
        description: "Dados das despesas já reembolsadas.",
        name: "Reembolsadas",
        type: ReportType.EXPENSE,
      },
      {
        description:
          "Dados das despesas ainda pendentes de aprovação ou pagamento.",
        name: "Pendentes de Reembolso",
        type: ReportType.PENDING_EXPENSE,
      },
    ],
    title: "Despesas",
  },
];

export const ReportDownloadList: React.FC = () => {
  return (
    <ul css={styles.root}>
      {REPORTS.map((report, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <hr css={styles.divisor} />}
          <ReportDownloadListItem key={index} report={report} />
        </React.Fragment>
      ))}
    </ul>
  );
};

type ReportDownloadListItemProps = {
  report: {
    items: {
      description: string;
      name: string;
      type: ReportType;
    }[];
    title: string;
  };
};

const ReportDownloadListItem: React.FC<ReportDownloadListItemProps> = ({
  report,
}) => {
  return (
    <li css={styles.item.root}>
      <span css={styles.item.title}>{report.title}</span>
      <div css={styles.item.list}>
        {report.items.map((item, index) => (
          <ReportDownloadListItemCard item={item} key={index} />
        ))}
      </div>
    </li>
  );
};

type ReportDownloadListItemCardProps = {
  item: {
    description: string;
    name: string;
    type: ReportType;
  };
};

const ReportDownloadListItemCard: React.FC<ReportDownloadListItemCardProps> = ({
  item,
}) => {
  const { handleDownloadReport } = useReportDownload();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = useCallback(
    async (type: ReportType) => {
      setIsDownloading(true);

      await handleDownloadReport(type);

      setIsDownloading(false);
    },
    [handleDownloadReport],
  );

  return (
    <div css={styles.item.card.root}>
      <span css={styles.item.card.name}>{item.name}</span>
      <p css={styles.item.card.description}>{item.description}</p>
      <Button
        css={styles.item.card.button}
        disabled={isDownloading}
        onClick={async () => {
          await handleDownload(item.type);
        }}
      >
        {isDownloading ? (
          <CircularSpinner css={styles.item.card.loader} size={18} />
        ) : (
          <Icon css={styles.item.card.icon} size={18} use="cloud-arrow-down" />
        )}
        Download
      </Button>
    </div>
  );
};
