import React from "react";
import Skeleton from "react-loading-skeleton";

import { Link } from "@reach/router";
import { useCompanyEvent } from "~/apps/corporate/hooks/use-company-event";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  eventToken: string;
};

export const ItineraryInfoEvent: React.FC<Props> = ({ eventToken }) => {
  const { event, isLoadingEvent } = useCompanyEvent(eventToken);

  return (
    <div css={styles.root}>
      <Icon size={14} use="flag" />
      <span css={styles.text}>
        Essa viagem faz parte do evento:{" "}
        {isLoadingEvent ? (
          <Skeleton height="14px" width="96px" />
        ) : event ? (
          <Link css={styles.link} to={`/events/${eventToken}/overview`}>
            {event.name}.
          </Link>
        ) : (
          <Link css={styles.link} to={`/events/${eventToken}/overview`}>
            {eventToken}.
          </Link>
        )}
      </span>
    </div>
  );
};
