import React, { useMemo } from "react";

import { RichTextEditor } from "~/apps/shared/components/rich-text-editor/rich-text-editor";
import {
  CountryRestrictionSeverity,
  CountryRestrictionType,
} from "~/apps/shared/constants/enums";

import { styles } from "./styles";

const SEVERITY_BY_RESTRICTION_TYPE: Record<
  CountryRestrictionType,
  CountryRestrictionSeverity
> = {
  [CountryRestrictionType.BLOCK_ALL]: CountryRestrictionSeverity.ERROR,
  [CountryRestrictionType.BLOCK_ABROAD]: CountryRestrictionSeverity.ERROR,
  [CountryRestrictionType.ALLOW_WITH_WARNING]:
    CountryRestrictionSeverity.WARNING,
};

type Props = {
  message: any;
  restrictionType: CountryRestrictionType;
  severity?: CountryRestrictionSeverity;
};

export const CountryRestrictionMessage = ({
  message,
  restrictionType,
  severity,
}: Props) => {
  const value = useMemo(
    () =>
      message
        ? typeof message === "string"
          ? JSON.parse(message)
          : Object.keys(message).length > 0
          ? message
          : null
        : null,
    [message],
  );

  if (!value) {
    return null;
  }

  return (
    <div
      css={styles.root({
        severity: severity || SEVERITY_BY_RESTRICTION_TYPE[restrictionType],
      })}
    >
      <RichTextEditor.Readonly
        placeholder="Digite uma mensagem de aviso..."
        value={value}
      />
    </div>
  );
};
