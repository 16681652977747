import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  checkbox: {
    group: {
      item: {
        label: css({
          fontSize: "0.875rem",
          lineHeight: "19.36px",
        }),
      },
      root: css({
        gap: "2px",
        width: "fit-content",
      }),
    },
  },
  heading: {
    info: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      cursor: "pointer",
      fontFamily: theme.fonts.inter,
      fontSize: "0.75rem",
      fontWeight: 600,
      textDecoration: "underline",
      ":hover": {
        color: theme.colors.pink[700],
      },
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "12px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
  root: css({
    color: theme.colors.gray[300],
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};
