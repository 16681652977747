import React, { useEffect, useState } from "react";

import { Link } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import * as travelHelper from "~/apps/corporate/helpers/travel.helper";
import { TravelApprovalHistoryModel } from "~/apps/corporate/models/approvals.model";
import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import {
  OffersAvailabilitiesModel,
  OffersChangesModel,
} from "~/apps/corporate/models/offer.model";
import { TravelPaymentModel } from "~/apps/corporate/models/travel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { PaymentOption, ServiceType } from "~/apps/shared/constants/enums";

import { Badge, Button } from "@toolkit/v2";

import { useItineraryScreen } from "../../../../itinerary/itinerary-screen.context";
import { useItineraryServices } from "../../../../itinerary/itinerary-services.context";
import { useItinerary } from "../../../../itinerary/itinerary.context";
import { usePayment } from "../../../payment.context";
import { styles } from "./styles";

type Props = {
  handleOpenUserProfile: () => void;
  infoModel: ItineraryInfoModel;
  offersAvailabilitiesModel: OffersAvailabilitiesModel | null;
  offersChangesModel: OffersChangesModel | null;
  paymentOptions: Record<ServiceType, PaymentOption>;
  travelApprovalHistoryModel: TravelApprovalHistoryModel;
  travelPaymentModel: TravelPaymentModel;
};

export const PaymentDecisionActions: React.FC<Props> = ({
  handleOpenUserProfile,
  infoModel,
  offersAvailabilitiesModel,
  offersChangesModel,
  paymentOptions,
  travelApprovalHistoryModel,
  travelPaymentModel,
}) => {
  const { user } = useUser();

  const { travelToken } = useItinerary();
  const { isLoadingTravelRepeatedOffers } = useItineraryScreen();
  const { isLoadingOffersAvailabilitiesAndChanges } = useItineraryServices();
  const {
    canProceedWithRemainingOffers,
    denyApprovalRequest,
    isLoadingApprovalDeny,
    isLoadingBook,
    shouldBookingFeeBeCharged,
  } = usePayment();

  const [denyJustification, setDenyJustification] = useState("");

  useEffect(() => {
    if (denyJustification || !offersAvailabilitiesModel) {
      return;
    }

    const areAllOffersUnavailable = offersAvailabilitiesModel.areAllOffersUnavailable();

    if (!areAllOffersUnavailable) {
      return;
    }

    setDenyJustification(
      travelHelper.getNoAvailabilityDenyMessage(travelPaymentServices),
    );
  }, [denyJustification, offersAvailabilitiesModel]);

  if (!user) {
    return null;
  }

  const travelPaymentServices = travelPaymentModel.getAllServices();

  const areAllOffersUnavailable = offersAvailabilitiesModel
    ? offersAvailabilitiesModel.areAllOffersUnavailable()
    : false;
  const areAllOffersWithPriceChanges = offersChangesModel
    ? offersChangesModel.areAllOffersWithPriceChanges(travelPaymentServices)
    : false;
  const hasUnavailableOffers = offersAvailabilitiesModel
    ? offersAvailabilitiesModel.hasUnavailableOffers()
    : false;
  const hasPriceChanges = offersChangesModel
    ? offersChangesModel.hasPriceChanges(travelPaymentServices)
    : false;

  const isSelfBooking = infoModel.isUserBooker(user);
  const isSomePaymentOptionCreditCard = Object.values(paymentOptions).some(
    (paymentOption) => paymentOption === PaymentOption.CREDIT_CARD,
  );

  const showAddCreditCardButton =
    !areAllOffersUnavailable &&
    (canProceedWithRemainingOffers ||
      (!hasUnavailableOffers && !hasPriceChanges)) &&
    (isSomePaymentOptionCreditCard || shouldBookingFeeBeCharged);
  const showBackToApprovalReviewButton =
    !areAllOffersUnavailable &&
    (hasPriceChanges || hasUnavailableOffers) &&
    travelApprovalHistoryModel.isPendingApproval();
  const showBackToTravelPlanButton =
    (areAllOffersUnavailable || canProceedWithRemainingOffers) &&
    isSelfBooking &&
    !travelApprovalHistoryModel.isPendingApproval();
  const showDenyApprovalRequestButton =
    areAllOffersUnavailable && travelApprovalHistoryModel.isPendingApproval();
  const showProceedButton =
    !areAllOffersUnavailable &&
    (canProceedWithRemainingOffers ||
      (!hasUnavailableOffers && !hasPriceChanges));

  return (
    <>
      {showAddCreditCardButton ? (
        <>
          <hr css={styles.divisor} />
          <Button
            css={styles.button}
            disabled={isLoadingBook || isLoadingOffersAvailabilitiesAndChanges}
            fill="outlined"
            onClick={() => {
              handleOpenUserProfile();
            }}
            type="button"
          >
            <Icon size={16} use="credit-card" />
            Adicionar cartão de crédito
          </Button>
        </>
      ) : null}
      {showBackToApprovalReviewButton ? (
        <Link css={styles.link} to={`/travels/${travelToken}/approval-review`}>
          <Icon size={16} use="arrow-left" />
          Revisitar itens a aprovar
        </Link>
      ) : null}
      {showBackToTravelPlanButton ? (
        <>
          <Link
            css={styles.link}
            to={`/travels/${travelToken}/itinerary/travel-plan`}
          >
            <Icon size={16} use="arrow-left" />
            Voltar ao plano de viagem
          </Link>
          {areAllOffersUnavailable ? (
            <Badge css={styles.warning.root}>
              <Icon css={styles.warning.icon} size={16} use="info-outline" />
              <span css={styles.warning.text}>
                Todas as ofertas{" "}
                <strong css={styles.warning.strong}>
                  se tornaram indisponíveis.{" "}
                </strong>
                Neste caso,{" "}
                <strong css={styles.warning.strong}>
                  não é possível prosseguir com a compra.
                </strong>
              </span>
            </Badge>
          ) : null}
        </>
      ) : null}
      {showProceedButton ? (
        <>
          <Button
            css={styles.button}
            disabled={
              isLoadingBook ||
              isLoadingOffersAvailabilitiesAndChanges ||
              isLoadingTravelRepeatedOffers
            }
            type="submit"
            variant="pink"
          >
            <Icon size={16} use="check-circle" />
            {canProceedWithRemainingOffers
              ? hasUnavailableOffers
                ? "Prosseguir mesmo assim"
                : "Prosseguir"
              : "Confirmar pagamento"}
          </Button>
          {canProceedWithRemainingOffers ? (
            <>
              {areAllOffersWithPriceChanges ? (
                <Badge css={styles.warning.root}>
                  <Icon
                    css={styles.warning.icon}
                    size={16}
                    use="info-outline"
                  />
                  <span css={styles.warning.text}>
                    Algumas ofertas{" "}
                    <strong css={styles.warning.strong}>
                      sofreram alteração de preço.{" "}
                    </strong>
                    Deseja prosseguir?
                  </span>
                </Badge>
              ) : (
                <Badge css={styles.warning.root}>
                  <Icon
                    css={styles.warning.icon}
                    size={16}
                    use="info-outline"
                  />
                  <span css={styles.warning.text}>
                    Algumas ofertas{" "}
                    <strong css={styles.warning.strong}>
                      não puderam ter sua disponibilidade conferidas,{" "}
                    </strong>
                    portanto{" "}
                    <strong css={styles.warning.strong}>
                      poderão sofrer alterações de disponibilidade ou preço
                      durante o processamento.
                    </strong>
                  </span>
                </Badge>
              )}
            </>
          ) : null}
        </>
      ) : null}
      {showDenyApprovalRequestButton ? (
        <>
          <div css={styles.deny.root}>
            <span css={styles.deny.label}>
              Adicione uma mensagem para deixar{" "}
              <strong css={styles.deny.name}>
                {infoModel.getBooker().name}
              </strong>{" "}
              sabendo por que seu pedido foi negado.{" "}
              <span css={styles.deny.optional}>(opcional)</span>
            </span>
            <textarea
              css={styles.deny.textarea}
              disabled={isLoadingApprovalDeny}
              onChange={(e) => {
                setDenyJustification(e.target.value);
              }}
              maxLength={250}
              value={denyJustification}
            />
            <span css={styles.deny.info}>
              Máximo de 250 caracteres ({denyJustification.length}
              /250)
            </span>
          </div>
          <Button
            css={styles.button}
            disabled={isLoadingApprovalDeny}
            onClick={async () => {
              await denyApprovalRequest(denyJustification);
            }}
            type="button"
            variant="pink"
          >
            <Icon size={16} use="thumbs-down" />
            Negar integralmente a aprovação
          </Button>
          <Badge css={styles.warning.root}>
            <Icon css={styles.warning.icon} size={16} use="info-outline" />
            <span css={styles.warning.text}>
              Todas as ofertas{" "}
              <strong css={styles.warning.strong}>
                se tornaram indisponíveis.
              </strong>{" "}
              Neste caso, você deverá{" "}
              <strong css={styles.warning.strong}>
                negar a aprovação, pois não é possível prosseguir.
              </strong>
            </span>
          </Badge>
        </>
      ) : null}
    </>
  );
};
