import moment, { Moment } from "moment";
import * as yup from "yup";

import { FlightsSearchPlace } from "../offer-searcher.types";

export const flightFormSchema = yup.object().shape({
  cabinClass: yup.string(),
  destination: yup
    .mixed<FlightsSearchPlace>()
    .required("Preencha seu local de destino")
    .nullable(true),
  endDate: yup
    .mixed<Moment>()
    .test("", "Preencha a data de volta", (endDate, context) => {
      if (context.parent.type === "roundtrip" && !endDate) {
        return false;
      }

      return true;
    })
    .nullable(true),
  origin: yup
    .mixed<FlightsSearchPlace>()
    .required("Preencha seu local de origem")
    .nullable(true),
  startDate: yup
    .mixed<Moment>()
    .required("Preencha a data de ida")
    .test("", "Data inicial inferior a hoje", (startDate) => {
      if (!startDate) {
        return false;
      }

      const today = moment().startOf("day");

      if (startDate.isBefore(today)) {
        return false;
      }

      return true;
    })
    .nullable(true),
  type: yup.mixed<"oneway" | "roundtrip">().required(),
});

export type FlightFormSchema = yup.InferType<typeof flightFormSchema>;
