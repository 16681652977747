import {
  FormControlLabelClassKey,
  StyledComponentProps,
} from "@material-ui/core";
import { css as ecss } from "emotion";

import { theme } from "@skin/v2";

export const styles = {
  classes: <StyledComponentProps<FormControlLabelClassKey>["classes"]>{
    label: ecss({
      color: theme.colors.gray[700],
    }),
    root: ecss({
      display: "flex",
      height: "48px",
      gap: "4px",
    }),
  },
};
