import React from "react";

import moment from "moment";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

const Breakfast: React.FC<{ isIncluded: boolean }> = ({ isIncluded }) => (
  <div css={styles.item({ isIncluded })}>
    <Icon size={20} use="coffee" />
    <span>
      {isIncluded ? "Café da manhã incluso" : "Café da manhã não incluso"}
    </span>
  </div>
);

const Refund: React.FC<Pick<Props, "cancelationUntil">> = ({
  cancelationUntil,
}) => {
  const cancelationText =
    cancelationUntil && cancelationUntil !== "any"
      ? "até " + moment(cancelationUntil).format("DD [de] MMMM [de] YYYY")
      : "";

  return (
    <div css={styles.item({ isIncluded: true })}>
      <Icon size={20} use="x-circle-outline" />
      <span>Cancelamento grátis {cancelationText}</span>
    </div>
  );
};

const Wifi: React.FC = () => (
  <div css={styles.item({ isIncluded: true })}>
    <Icon size={20} use="wifi" />
    <span>Wifi</span>
  </div>
);

type Props = React.HTMLAttributes<HTMLDivElement> & {
  breakfast?: boolean;
  cancel?: boolean;
  cancelationUntil?: string | null;
  wifi?: boolean;
};

export const AmenitiesIcons = ({
  breakfast,
  cancel,
  cancelationUntil,
  wifi,
  ...props
}: Props) => {
  return (
    <div css={styles.root} {...props}>
      <Breakfast isIncluded={!!breakfast} />
      {cancel ? <Refund cancelationUntil={cancelationUntil} /> : null}
      {wifi ? <Wifi /> : null}
    </div>
  );
};
