import { AxiosResponse } from "axios";
import moment from "moment";

import {
  getLoggedUserToken,
  getAuthorizationHeader
} from "@helpers/user.helper";

import {
  GetHotelReviewRequestItem,
  SubmitHotelReviewRequestBody,
  SubmitHotelReviewResponseBody,
  GetHotelReviewListItemResponse
} from "@dtos/hotel-review.dto";

import {
  HotelReviewItem,
  HotelReviewListItem
} from "@models/hotel-review.model";

import * as hotelReviewParser from "../parsers/hotel-review.parser";
import { api } from "./interceptors";

export function getUserHotelReviews(): Promise<HotelReviewItem[]> {
  return api
    .request({
      url: `/booking/users/${getLoggedUserToken()}/hotels/reviews`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<GetHotelReviewRequestItem[]>) =>
      data.map(item => ({
        ...item,
        hotelInitialDate: moment(item.hotelInitialDate),
        hotelEndDate: moment(item.hotelEndDate),
        createdAt: item.createdAt ? moment(item.createdAt) : undefined
      }))
    );
}

export function submitHotelReview(
  offerToken: string,
  dto: SubmitHotelReviewRequestBody
) {
  return api
    .request({
      url: `/booking/offers/${offerToken}/hotels/reviews`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<SubmitHotelReviewResponseBody>) =>
      hotelReviewParser.parseHotelReviewSubmitResponse(data)
    );
}

export function listHotelReviews(
  hotelId: string | number
): Promise<HotelReviewListItem[]> {
  return api
    .request({
      url: `/booking/hotels/${hotelId}/reviews`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<GetHotelReviewListItemResponse[]>) =>
      data.map(reviewItem => ({
        ...reviewItem,
        createdAt: moment(reviewItem.createdAt)
      }))
    );
}
