import React from "react";

import { SlideFilter } from "~/apps/shared/components/slide-filter/slide-filter";
import { formatFlightDuration } from "~/apps/shared/utils/format-flight-duration";
import { secondsToHHMM } from "~/apps/shared/utils/seconds-to-hh-mm";

import { RangeFilter as RangeFilterType } from "../../../flights.types";
import { styles } from "./styles";

type Props = {
  disabled?: boolean;
  durationFilter: {
    defaultDurationRange: RangeFilterType;
    durationRange: RangeFilterType;
  };
  handleDurationFilterChange: (maxRange: number) => void;
};

export const DurationFilter: React.FC<Props> = ({
  disabled,
  durationFilter,
  handleDurationFilterChange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Maior duração</span>
      <SlideFilter
        defaultValue={durationFilter.durationRange[1]}
        disabled={disabled}
        formatFunc={(value) =>
          formatFlightDuration(secondsToHHMM(value)).replace(" ", "")
        }
        max={durationFilter.defaultDurationRange[1]}
        min={durationFilter.defaultDurationRange[0]}
        onRangeDragEnd={handleDurationFilterChange}
        step={5 * 60}
      />
    </div>
  );
};
