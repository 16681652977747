import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    offers: {
      offers: {
        list: {
          item: {
            link: css({
              color: theme.colors.pink[500],
              transition: "all 0.15s ease-in-out",
              ":hover": {
                color: theme.colors.pink[700],
              },
            }),
            root: css({
              color: theme.colors.gray[700],
              fontSize: "0.875rem",
              listStyle: "disc",
              marginLeft: "16px",
            }),
          },
          root: css({
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
        title: css({
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop: "16px",
      }),
      warning: {
        icon: css({}),
        root: css({
          backgroundColor: "#ffe8ab",
          borderColor: "#ffe8ab",
          color: theme.colors.gray[700],
          fontWeight: 500,
          gap: "16px",
          justifyContent: "flex-start",
          lineHeight: "1.125",
          padding: "16px 24px",
          width: "100%",
          ":hover": {
            backgroundColor: "#ffe8ab",
            borderColor: "#ffe8ab",
          },
        }),
        text: css({}),
      },
    },
    root: css({}),
  },
  message: css({
    color: theme.colors.gray[300],
    lineHeight: "1.25",
  }),
  root: css({
    ".MuiPaper-root": {
      overflow: "visible",
      "@media (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
};
