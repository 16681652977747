import React, { useMemo } from "react";
import { Select } from "@shared/inputs";

interface IOption {
  value: string;
  label: string;
  [key: string]: any;
}

interface IProps {
  placeholder: string;
  value: string;
  options: IOption[];
  onChange(optionValue: string): void;
}

const FilterSelect: React.FC<IProps> = ({
  placeholder,
  options,
  value,
  onChange
}) => {
  const selectedOption = useMemo(() => {
    return options.find(opt => opt.value === value);
  }, [value, options]);

  function handleChange(option: IOption | null) {
    onChange(option?.value || "");
  }

  return (
    <Select
      placeholder={placeholder}
      value={selectedOption}
      options={options}
      onChange={handleChange}
    />
  );
};

export { FilterSelect };
