import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    "& > svg": {
      strokeWidth: 2,
    },
  }),
  root: css({
    backgroundColor: "#FFE2E5",
    borderColor: theme.colors.pink[300],
    borderRadius: "6px",
    color: theme.colors.pink[500],
    fontWeight: 500,
    gap: "8px",
    padding: "4px 16px",
    ":hover": {
      backgroundColor: "#FFE2E5",
      borderColor: theme.colors.pink[300],
      color: theme.colors.pink[500],
    },
  }),
};
