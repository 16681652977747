import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "8px 0",
    width: "100%",
  }),
  form: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    textarea: {
      textarea: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        fontFamily: theme.fonts.inter,
        outline: "none",
        padding: "13px 16px",
        resize: "none",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
      label: {
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        text: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  optional: css({
    color: theme.colors.gray[100],
    fontSize: "0.875rem",
  }),
};
