import { RecalculateCarOfferRequestAdditional } from "~/apps/corporate/dtos/car.dto";
import { AdditionalPriceInfo } from "~/apps/corporate/models/car.model";

import {
  CarResultAdditionalsSorter,
  CarResultHelper,
} from "../car-result.helper";
import { CarAdditionalPresentation } from "../car-result.types";
import {
  movidaAdditionalsCodesMap,
  movidaAdditionalsPresentationMap,
} from "./movida-additionals.constants";
import {
  accessoryAdditionalsKeys,
  AccessoryAdditionalsKeys,
  protectionAdditionalsKeys,
  ProtectionAdditionalsKeys,
  serviceAdditionalsKeys,
  ServiceAdditionalsKeys,
} from "./movida-additionals.types";

export class MovidaAdditionalsHelper extends CarResultHelper<
  typeof movidaAdditionalsCodesMap
> {
  constructor({
    car,
  }: {
    car: {
      contractDetails: {
        accessoryAdditionals: AdditionalPriceInfo[] | undefined;
        protectionAdditionals: AdditionalPriceInfo[] | undefined;
        serviceAdditionals: AdditionalPriceInfo[] | undefined;
      };
    };
  }) {
    super({
      car,
      codesMap: movidaAdditionalsCodesMap,
      presentationMap: movidaAdditionalsPresentationMap,
    });
  }

  private fallbackToSimplifiedAdditionalPresentation(
    additional: AdditionalPriceInfo,
  ): CarAdditionalPresentation {
    return {
      category: additional.supplierCategory,
      checked: additional.selected,
      code: additional.code,
      dayCount: additional.dayCount,
      disabled: !additional.allowChange,
      name: additional.name,
      price: additional.netPrice,
      quantity: additional.selected ? additional.quantity : 0,
      rate: "daily",
      selected: additional.selected,
    };
  }

  public formatAccessoryAdditionals() {
    const accessoryAdditionalsRaw = this.car.contractDetails
      .accessoryAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      accessoryAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionals?: CarAdditionalPresentation[];
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: CarResultAdditionalsSorter.sortCarAdditionalsByDisabledAndName(
          groupedAdditionalsForPresentation.additionals,
        ),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  public formatProtectionAdditionals() {
    const protectionAdditionalsRaw = this.car.contractDetails
      .protectionAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      protectionAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        if (
          this.isUnknownCodeWithinPossibleCodesByKeys(curr.code, [
            "PROTECAO_BASICA",
            "PROTECAO_PREMIUM",
            "PROTECAO_SUPER",
          ])
        ) {
          // easier way to filter PROTECAO_BASICA, removing it from the output (could be done in a better place)
          if (
            this.isUnknownCodeWithinPossibleCodesByKeys(
              curr.code,
              "PROTECAO_BASICA",
            )
          ) {
            return prev;
          }

          return {
            ...prev,
            packages: prev.packages ? [...prev.packages, curr] : [curr],
          };
        }

        // we want to filter PROTECAO_A_VIDROS because we understand that it is contained within PROTECAO_A_PNEUS_E_VIDROS_PLUS
        if (
          this.isUnknownCodeWithinPossibleCodesByKeys(
            curr.code,
            "PROTECAO_A_VIDROS",
          )
        ) {
          return prev;
        }

        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionals?: CarAdditionalPresentation[];
        packages?: CarAdditionalPresentation[];
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: CarResultAdditionalsSorter.sortCarAdditionalsByDisabledAndName(
          groupedAdditionalsForPresentation.additionals,
        ),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  public formatServiceAdditionals() {
    const serviceAdditionalsRaw = this.car.contractDetails.serviceAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      serviceAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        // easier way to filter CARBON_FREE, removing it from the output (could be done in a better place)
        if (
          this.isUnknownCodeWithinPossibleCodesByKeys(curr.code, "CARBON_FREE")
        ) {
          return prev;
        }

        if (
          this.isUnknownCodeWithinPossibleCodesByKeys(
            curr.code,
            "CONDUTOR_ADICIONAL",
          )
        ) {
          return {
            ...prev,
            additionalDrivers: curr,
          };
        }

        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionalDrivers?: CarAdditionalPresentation;
        additionals?: CarAdditionalPresentation[];
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: CarResultAdditionalsSorter.sortCarAdditionalsByDisabledAndName(
          groupedAdditionalsForPresentation.additionals,
        ),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  private formatAdditionalsForPresentation(
    additionals?: AdditionalPriceInfo[],
  ): CarAdditionalPresentation[] {
    return !additionals
      ? []
      : additionals.map((additional) => {
          const additionalPresentation = this.findPresentationByUnknownCode(
            additional.code,
          );

          if (!additionalPresentation) {
            return this.fallbackToSimplifiedAdditionalPresentation(additional);
          }

          return {
            category: additional.supplierCategory,
            checked: additional.selected,
            code: additional.code,
            dayCount: additional.dayCount,
            details: additionalPresentation.details,
            disabled: !additional.allowChange,
            icon: additionalPresentation.icon,
            name: additional.name,
            price: additional.netPrice,
            quantity: additional.selected ? additional.quantity : 0,
            rate: additionalPresentation.rate || "daily",
            selected: additional.selected,
            shortDetails: additionalPresentation.shortDetails,
          };
        });
  }

  public groupPresentationByCategory() {
    return {
      accessory: this.groupPresentationByKeys<AccessoryAdditionalsKeys>(
        accessoryAdditionalsKeys,
      ),
      protection: this.groupPresentationByKeys<ProtectionAdditionalsKeys>(
        protectionAdditionalsKeys,
      ),
      service: this.groupPresentationByKeys<ServiceAdditionalsKeys>(
        serviceAdditionalsKeys,
      ),
    };
  }

  public patchAdditionalsToRecalculate(
    additional: CarAdditionalPresentation,
    selectedAdditionals: {
      category: string;
      code: string;
      quantity?: string | undefined;
    }[],
    additionalsToRecalculate: RecalculateCarOfferRequestAdditional[],
  ): RecalculateCarOfferRequestAdditional[] {
    if (
      this.isUnknownCodeWithinPossibleCodesByKeys(additional.code, [
        "PROTECAO_BASICA",
        "PROTECAO_PREMIUM",
        "PROTECAO_SUPER",
      ])
    ) {
      return [
        ...additionalsToRecalculate.filter(
          (a) =>
            !this.isUnknownCodeWithinPossibleCodesByKeys(a.code, [
              "PROTECAO_BASICA",
              "PROTECAO_PREMIUM",
              "PROTECAO_SUPER",
            ]),
        ),
        this.formatAdditionalToRecalculate(additional),
      ];
    }

    return additionalsToRecalculate;
  }
}
