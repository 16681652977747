import React from "react";
import { Control, useFieldArray } from "react-hook-form";

import {
  ApprovalProcessActions,
  ApprovalProcessApproversLevel,
} from "~/models/approval-process.model";

import { useFormError } from "~/components/shared/form";
import { FormFieldAdditionalText } from "~/components/shared/form/FormField";

import { ApprovalProcessStage } from "./approval-process-stage/approval-process-stage";

interface Props {
  control: Control;
  isSingleApprover?: boolean;
  isUniqueApprovalCreation?: boolean;
  ruleIndex: number;
}

export const ApprovalProcessRuleActions: React.FC<Props> = ({
  control,
  isSingleApprover,
  isUniqueApprovalCreation,
  ruleIndex,
}) => {
  const { fields } = useFieldArray<ApprovalProcessActions>({
    name: `rules[${ruleIndex}].actions`,
    control: control,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {fields.map((field, actionIndex) => (
        <ApprovalProcessAction
          actionIndex={actionIndex}
          control={control}
          isSingleApprover={isSingleApprover}
          isUniqueApprovalCreation={isUniqueApprovalCreation}
          key={field.id}
          ruleIndex={ruleIndex}
        />
      ))}
    </div>
  );
};

type ApprovalProcessActionProps = {
  actionIndex: number;
  control: Control;
  isSingleApprover?: boolean;
  isUniqueApprovalCreation?: boolean;
  ruleIndex: number;
};

const ApprovalProcessAction: React.FC<ApprovalProcessActionProps> = ({
  actionIndex,
  control,
  isSingleApprover,
  isUniqueApprovalCreation,
  ruleIndex,
}) => {
  const {
    append: addStage,
    fields,
    remove: removeStage,
  } = useFieldArray<ApprovalProcessApproversLevel>({
    control: control,
    name: `rules[${ruleIndex}].actions[${actionIndex}].stages`,
  });

  const error = useFormError(
    `rules[${ruleIndex}].actions[${actionIndex}].stages`,
  );

  return (
    <>
      {fields.map((field, stageIndex) => (
        <ApprovalProcessStage
          actionIndex={actionIndex}
          addStage={addStage}
          control={control}
          isSingleApprover={isSingleApprover}
          isUniqueApprovalCreation={isUniqueApprovalCreation}
          key={field.id}
          removeStage={removeStage}
          ruleIndex={ruleIndex}
          stageFields={fields}
          stageIndex={stageIndex}
        />
      ))}
      {error ? (
        <FormFieldAdditionalText hasError>
          {error.message}
        </FormFieldAdditionalText>
      ) : null}
    </>
  );
};
