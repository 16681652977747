import React, { useCallback, useState } from "react";

import { Drawer } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import {
  NavigationMenu,
  NavigationMenuItem,
} from "~/apps/shared/components/navigation-menu/navigation-menu";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

export const AnalyticsMenu: React.FC = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 992;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  if (isMobile) {
    return (
      <>
        <Button
          css={styles.button}
          fill="outlined"
          onClick={() => {
            handleOpenDrawer();
          }}
        >
          <Icon size={16} use="bars-3-bottom-right" />
        </Button>
        <Drawer
          anchor="left"
          css={styles.drawer}
          onClose={() => {
            handleCloseDrawer();
          }}
          open={isDrawerOpen}
        >
          <AnalyticsMenuItems />
        </Drawer>
      </>
    );
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Analytics</span>
      <hr css={styles.divisor} />
      <AnalyticsMenuItems />
    </div>
  );
};

const AnalyticsMenuItems: React.FC = () => {
  const [open, setOpen] = useState<string | null>("trips");

  const handleOpen = useCallback((id: string) => {
    setOpen((prev) => (prev === id ? null : id));
  }, []);

  return (
    <ul css={styles.items.root}>
      <NavigationMenu
        icon="globe"
        label="Viagens"
        onChange={() => {
          handleOpen("trips");
        }}
        open={open === "trips"}
      >
        <NavigationMenuItem label="Visão geral" to="/analytics" />
        <NavigationMenuItem label="Aéreo" to="/analytics/flight-info" />
        <NavigationMenuItem label="Hotel" to="/analytics/hotel-info" />
        <NavigationMenuItem
          label="Download de relatórios"
          to="/analytics/report-download"
        />
        <NavigationMenuItem
          label="Viagens em tempo real"
          to="/analytics/real-time"
        />
      </NavigationMenu>
    </ul>
  );
};
