import { ERROR } from "@constants";
import ERROR_TYPES from "@constants/errors";

import { getClientToken } from "@helpers/user.helper";

import { UpdateClientRequest } from "@dtos/client.dto";

import { Client } from "@models/client.model";
import { CostCenterListItem, CostCenter } from "@models/cost-center.model";
import { UserSearch } from "@models/user.model";

import { CustomError } from "~/types";

import clientApi from "@apis/client.api";
import * as costCenterApi from "@apis/cost-center.api";
import userApi from "@apis/user.api";

import { FormValues } from "./CostCenters.types";

export async function searchUsers(
  search: string,
): Promise<{ data?: UserSearch[]; error?: CustomError }> {
  const result: { data?: UserSearch[]; error?: CustomError } = {};

  try {
    result.data = await userApi.getUsersByName(search);
  } catch (error) {
    result.error = error;
  }

  return result;
}

export async function createCostCenter(
  values: FormValues,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const requestData = {
      name: values.name,
      user_tokens: values.users.map((user) => user.userToken),
      client_token: getClientToken(),
    };

    result.data = await costCenterApi.createCostCenter(requestData);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (
        status === 400 &&
        data.type === ERROR_TYPES.COST_CENTER_ALREADY_EXISTS
      ) {
        result.error = ERROR.COST_CENTER_ALREADY_EXISTS_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_COST_CENTER_CREATE_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_COST_CENTER_CREATE_ERROR;
    }
  }

  return result;
}

export async function updateCostCenter(
  values: FormValues,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const requestData = {
      name: values.name,
      user_tokens: values.users.map((user) => user.userToken),
      client_token: getClientToken(),
      active: values.active!,
    };

    result.data = await costCenterApi.updateCostCenter(
      values.token!,
      requestData,
    );
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (
        status === 400 &&
        data.type === ERROR_TYPES.COST_CENTER_ALREADY_EXISTS
      ) {
        result.error = ERROR.COST_CENTER_ALREADY_EXISTS_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_COST_CENTER_CREATE_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_COST_CENTER_EDIT_ERROR;
    }
  }

  return result;
}

export async function listCostCenters(): Promise<{
  data?: CostCenterListItem[];
  error?: CustomError;
}> {
  const result: { data?: CostCenterListItem[]; error?: CustomError } = {};

  try {
    result.data = await costCenterApi.listCostCenters();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_COST_CENTERS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_COST_CENTERS_LIST_ERROR;
    }
  }

  return result;
}

export async function getCostCenterByToken(
  token: string,
): Promise<{ data?: CostCenter; error?: CustomError }> {
  const result: { data?: CostCenter; error?: CustomError } = {};

  try {
    result.data = await costCenterApi.getCostCenterByToken(token);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_COST_CENTER_BY_TOKEN_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_COST_CENTER_BY_TOKEN_ERROR;
    }
  }

  return result;
}

export async function archiveCostCenter(
  token: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await costCenterApi.archiveCostCenter(token);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_COST_CENTER_ARCHIVE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_COST_CENTER_ARCHIVE_ERROR;
    }
  }

  return result;
}

export async function getClientInfo(): Promise<{
  data?: Client;
  error?: CustomError;
}> {
  const result: { data?: Client; error?: CustomError } = {};

  try {
    result.data = await clientApi.getClientInfo();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    }
  }

  return result;
}

export async function toggleClientCostCenters(
  clientConfigToken: string,
  costCenterActive: boolean,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const requestData: UpdateClientRequest = {
      cost_center_active: costCenterActive,
    };

    result.data = await clientApi.updateClientConfig(
      clientConfigToken,
      requestData,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_TOGGLE_COST_CENTERS_ACTIVE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_TOGGLE_COST_CENTERS_ACTIVE_ERROR;
    }
  }

  return result;
}
