import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.pink[500],
    border: "none",
    borderRadius: "6px",
    color: theme.colors.white,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: "32px",
    justifyContent: "center",
    transition: "all 0.15s ease-in-out",
    width: "32px",
    ":hover": {
      backgroundColor: theme.colors.pink[700],
    },
  }),
  text: css({
    color: theme.colors.white,
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  }),
};
