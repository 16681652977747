import React, { useState, useEffect } from "react";

import moment from "moment";

import { buildDate as potentiallyCachedBuildDate } from "../../../../package.json";
import * as envUtils from "../utils/env";
import { logger } from "../utils/logger";

const clearCacheAndForceReload = () => {
  if (caches as CacheStorage | undefined) {
    void caches.keys().then((names) => {
      for (const name of names) {
        void caches.delete(name);
      }
    });
  }

  window.location.reload();
};

const getLatestBuildDate = async (): Promise<number> => {
  const meta = await fetch("/meta.json", {
    cache: "no-store",
  }).then((res) => res.json());

  const currentBuildDate = meta.buildDate;

  if (!currentBuildDate) {
    throw new Error("missing build date");
  }

  if (typeof currentBuildDate !== "number") {
    throw new Error("invalid build date");
  }

  return currentBuildDate;
};

const getPotentiallyCachedBuildDate = () => {
  return potentiallyCachedBuildDate;
};

const dateIsGreaterThan = (d1: number, d2: number) => {
  return moment(d1).isAfter(moment(d2));
};

export const shouldClearCache = async () => {
  if (envUtils.isDevEnv()) {
    return false;
  }

  let latestBuildDate: number | undefined;

  try {
    latestBuildDate = await getLatestBuildDate();
  } catch (error) {
    logger.error(`error fetching current build date: ${JSON.stringify(error)}`);
  }

  if (!latestBuildDate) {
    return false;
  }

  const potentiallyCachedBuildDate = getPotentiallyCachedBuildDate();

  logger.info(
    `latest build date ${latestBuildDate} - potentially cached build date: ${potentiallyCachedBuildDate}`,
  );

  return dateIsGreaterThan(latestBuildDate, potentiallyCachedBuildDate);
};

// eslint-disable-next-line react/display-name
export const withClearCache = (Component: React.ElementType) => (
  props: React.ComponentProps<typeof Component>,
) => {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    const clearCache = async () => {
      if (await shouldClearCache()) {
        clearCacheAndForceReload();

        return;
      }

      setIsLatestBuildDate(true);

      return;
    };

    void clearCache();
  }, []);

  return isLatestBuildDate ? <Component {...props} /> : null;
};
