import React from "react";

import Drawer from "~/components/shared/drawer";
import { DrawerSpinner } from "~/components/shared/DrawerSpinner";

import {
  CardCreationProvider,
  useCardCreationContext
} from "./CardCreation.context";
import { CardCreationProps } from "./CardCreation.types";
import CardFormContainer from "./form/CardCreationForm";

function CardCreation(props: CardCreationProps) {
  const { showClientSettings, handleClose, defaultOwnerType } = props;

  const { submitting } = useCardCreationContext();

  const innerHandleClose = !submitting ? handleClose : () => null;

  return (
    <Drawer title="Adicionar cartão de crédito" open onClose={innerHandleClose}>
      <DrawerSpinner visible={submitting} />
      <CardFormContainer
        defaultOwnerType={defaultOwnerType}
        showClientSettings={showClientSettings}
        handleCancel={innerHandleClose}
      />
    </Drawer>
  );
}

function CardCreationContainer(props: CardCreationProps) {
  if (!props.open) return null;

  return (
    <CardCreationProvider {...props}>
      <CardCreation {...props} />
    </CardCreationProvider>
  );
}

export { CardCreationContainer as CardCreation };
