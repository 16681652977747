import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";

import { useItineraryApproval } from "../../itinerary-approval.context";
import { useItineraryInfo } from "../../itinerary-info.context";
import { ItineraryServiceOutOfPolicyDialog } from "../../itinerary-service-out-of-policy-dialog/itinerary-service-out-of-policy-dialog";
import { useItineraryServices } from "../../itinerary-services.context";
import { ItineraryTravelRepeatedOffersDialog } from "../../itinerary-travel-repeated-offers-dialog/itinerary-travel-repeated-offers-dialog";
import { ItineraryInfo } from "../itinerary-info/itinerary-info";
import { ItineraryServiceDetailsDrawer } from "../itinerary-service-details-drawer/itinerary-service-details-drawer";
import { ApprovalRequestJustification } from "./approval-request-justification/approval-request-justification";
import { ApprovalRequestServicesCard } from "./approval-request-services-card/approval-request-services-card";
import { useItineraryApprovalRequestGuard } from "./itinerary-approval-request.hooks";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const ItineraryApprovalRequest: React.FC<Props> = () => {
  const { error } = useItineraryApprovalRequestGuard();

  const {
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
  } = useItineraryApproval();
  const { fetchItineraryInfo } = useItineraryInfo();
  const {
    fetchItineraryServices,
    fetchTravelPoliciesPerOffer,
  } = useItineraryServices();

  useEffect(() => {
    void fetchItineraryApprovalHistory();
    void fetchItineraryApprovalStatus();
    void fetchItineraryInfo();
    void fetchItineraryServices();
    void fetchTravelPoliciesPerOffer();
  }, [
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
    fetchItineraryInfo,
    fetchItineraryServices,
    fetchTravelPoliciesPerOffer,
  ]);

  if (error) {
    return <ErrorPage {...error} />;
  }

  return (
    <>
      <div css={styles.root}>
        <div css={styles.left.root}>
          <ItineraryInfo />
          <ApprovalRequestServicesCard />
        </div>
        <div css={styles.right.root}>
          <ApprovalRequestJustification />
        </div>
      </div>
      <ItineraryServiceDetailsDrawer />
      <ItineraryServiceOutOfPolicyDialog />
      <ItineraryTravelRepeatedOffersDialog />
    </>
  );
};
