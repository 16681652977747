import React, { useContext } from "react";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import {
  Dialog,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";

import { PaymentMethodsContext } from "./PaymentMethods.context";

const CardDeletionDialog = () => {
  const {
    selectedToDelete,
    isLoading,
    handleCloseDeletionDialog,
    proccessCardDeletion,
  } = useContext(PaymentMethodsContext);
  const isOpen = !!selectedToDelete;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDeletionDialog}
      disableRestoreFocus
      maxWidth="sm"
    >
      <DialogHeader
        color={theme.colors.pink[700]}
        disabled={isLoading}
        icon="trash"
        onClose={handleCloseDeletionDialog}
      />
      <DialogContent>
        <DialogTitle>Exclusão de cartão</DialogTitle>
        <span style={{ fontSize: "1rem", color: theme.colors.gray[300] }}>
          Deseja realmente excluir o cartão selecionado?
        </span>
      </DialogContent>
      <DialogFooter>
        <Button
          disabled={isLoading}
          fill="outlined"
          onClick={handleCloseDeletionDialog}
        >
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          onClick={proccessCardDeletion}
          variant="pink"
        >
          Excluir
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export { CardDeletionDialog };
