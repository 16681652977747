import React from "react";
import {
  CostCenterFilter,
  TagFilter,
  TravelerFilter,
  AreaFilter,
  ProjectFilter,
  BillingProfileFilter,
  PeriodFilter
} from "../shared/filters";
import { Box } from "@toolkit";
import PermissionVisibility from "@shared/permissions/PermissionVisibility";
import { useHotelInfoContext } from "./HotelInfo.context";

const HotelInfoFilters: React.FC = () => {
  const {
    filters,
    filterOptions,
    onDateFilterChange,
    onFilterChange
  } = useHotelInfoContext();
  return (
    <Box display={["none", "none", "flex"]} alignItems="center" py={24}>
      <PeriodFilter
        startMonth={filters.startMonth}
        startYear={filters.startYear}
        endMonth={filters.endMonth}
        endYear={filters.endYear}
        onApply={onDateFilterChange}
      />
      <PermissionVisibility permissions={["companySettings.costCenters"]}>
        <CostCenterFilter
          onApply={onFilterChange("costCenterToken")}
          options={filterOptions.costCenterOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      {/* <PermissionVisibility permissions={["travels.tags"]}>
        <TagFilter
          onApply={onFilterChange("tagToken")}
          options={filterOptions.tagOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility> */}
      <TravelerFilter
        onApply={onFilterChange("travelerToken")}
        options={filterOptions.travelerOptions}
        containerStyles={{ marginRight: "1rem" }}
      />
      <PermissionVisibility permissions={["companySettings.areas"]}>
        <AreaFilter
          onApply={onFilterChange("companyAreaToken")}
          options={filterOptions.companyAreaOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.projects"]}>
        <ProjectFilter
          onApply={onFilterChange("projectToken")}
          options={filterOptions.projectOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.billingProfiles"]}>
        <BillingProfileFilter
          onApply={onFilterChange("billingProfileToken")}
          options={filterOptions.billingProfileOptions}
          containerStyles={{ marginRight: "1rem" }}
        />
      </PermissionVisibility>
    </Box>
  );
};

export { HotelInfoFilters };
