import { ERROR, EXPENSE_STATUS } from "@constants";
import ERROR_TYPES from "@constants/errors";

import { getClientPermissions } from "@helpers/user.helper";

import {
  ExpenseReportDeniedResponseBody,
  ExpenseReportApproveResponseBody
} from "@dtos/expenses.dto";

import { Client } from "@models/client.model";
import { CostCenterListByUserItem } from "@models/cost-center.model";
import {
  ExpenseReport,
  Expense,
  ExpenseReportApprovalStatus,
  ExpensesPolicies
} from "@models/expense.model";
import { Travel } from "@models/travel.model";
import { UserModel } from "@models/user.model";

import { CustomError, ImageFile } from "~/types";

import * as areasApi from "@apis/areas.api";
import * as billingProfileApi from "@apis/billing-profile.api";
import clientApi from "@apis/client.api";
import * as costCentersApi from "@apis/cost-center.api";
import * as expensesApi from "@apis/expense.api";
import * as financialApi from "@apis/financial.api";
import * as projectsApi from "@apis/projects.api";
import * as travelApi from "@apis/travel.api";
import * as userApi from "@apis/user.api";

export async function getReportByToken(
  reportToken: string
): Promise<{ data?: ExpenseReport; error?: CustomError }> {
  const result: { data?: ExpenseReport; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getReportByToken(reportToken);
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 403 && data.type === ERROR_TYPES.ERROR_NOT_ALLOWED) {
        result.error = ERROR.USER_NOT_ALLOWED_TO_FETCH_REPORT_ERROR;
      } else {
        result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
      }
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    }
  }

  return result;
}

export async function getTraveler(
  travelerToken: string
): Promise<{ data?: UserModel; error?: CustomError }> {
  const result: { data?: UserModel; error?: CustomError } = {};

  try {
    result.data = await userApi.getUserRequest(travelerToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    }
  }

  return result;
}

export async function getReportTravel(
  travelToken: string
): Promise<{ data?: Travel; error?: CustomError }> {
  const result: { data?: Travel; error?: CustomError } = {};

  try {
    result.data = await travelApi.getTravelRequest(travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR;
    }
  }

  return result;
}

export async function getReceiptImageFile(
  receiptUrl: string
): Promise<{ data?: ImageFile; error?: CustomError }> {
  const result: { data?: ImageFile; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getImageFile(receiptUrl);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    }
  }

  return result;
}

export async function getClientInfo(): Promise<{
  data?: Client;
  error?: CustomError;
}> {
  const result: { data?: Client; error?: CustomError } = {};

  try {
    result.data = await clientApi.getClientInfo();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    }
  }

  return result;
}

export async function denyExpenseReportApprovalRequest(
  approvalProcessToken: string,
  declineMessage?: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const dto: ExpenseReportDeniedResponseBody = {
      approved: false
    };

    if (declineMessage) {
      dto.response_message = declineMessage;
    }

    result.data = await expensesApi.sendExpenseReportApprovalResponse(
      dto,
      approvalProcessToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_DENY_RESPONSE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_DENY_RESPONSE_ERROR;
    }
  }

  return result;
}

export async function approveExpenseReportApprovalRequest(
  approvalProcessToken: string,
  expensesData: Expense[],
  selectedArea: string,
  selectedBillingProfile: string,
  selectedCostCenter: string,
  selectedProject: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const dto: ExpenseReportApproveResponseBody = {
      approved: true,
      billing_profile_token: selectedBillingProfile
        ? selectedBillingProfile
        : undefined,
      company_area_token: selectedArea ? selectedArea : undefined,
      cost_center_token: selectedCostCenter ? selectedCostCenter : undefined,
      project_token: selectedProject ? selectedProject : undefined,
      expense_approved_values: expensesData
        .filter(
          expense =>
            expense.status === EXPENSE_STATUS.CREATED_MANUAL &&
            !expense.canceled
        )
        .map(expense => ({
          expense_token: expense.expenseToken,
          approved_value: expense.approvedValue || 0
        }))
    };

    result.data = await expensesApi.sendExpenseReportApprovalResponse(
      dto,
      approvalProcessToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR;
    }
  }

  return result;
}

export async function signalReportPayment(
  expenseReportToken: string
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await expensesApi.signalReportPayment(expenseReportToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR;
    }
  }

  return result;
}

export async function getExpenseReportApprovalStatus(
  expenseReportToken: string
): Promise<{ data?: ExpenseReportApprovalStatus; error?: CustomError }> {
  const result: {
    data?: ExpenseReportApprovalStatus;
    error?: CustomError;
  } = {};

  try {
    result.data = await expensesApi.getExpenseReportApprovalStatus(
      expenseReportToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR;
    }
  }

  return result;
}

export async function getReportExpensesPolicies(
  expenseReportToken: string
): Promise<{ data?: ExpensesPolicies; error?: CustomError }> {
  const result: { data?: ExpensesPolicies; error?: CustomError } = {};

  const clientPermissions = getClientPermissions();

  if (!clientPermissions) {
    result.error = ERROR.UNEXPECTED;

    return result;
  }

  const { expenses } = clientPermissions;

  const expensePolicyPermission = expenses?.policies;

  try {
    result.data = expensePolicyPermission
      ? await expensesApi.getReportExpensesPolicies(expenseReportToken)
      : {};
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR;
    }
  }

  return result;
}

export async function downloadExpenseReportInvoices(
  expenseReportToken: string
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await financialApi.downloadExpenseReportInvoices(expenseReportToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR;
    }
  }

  return result;
}

export async function getTravelerCostCenters(
  travelerToken: string
): Promise<{ data?: CostCenterListByUserItem[]; error?: CustomError }> {
  const result: { data?: CostCenterListByUserItem[]; error?: CustomError } = {};
  const {
    companySettings: { costCenters: costCentersPermission }
  } = getClientPermissions();

  try {
    const costCenters = costCentersPermission
      ? await costCentersApi.listCostCentersByUser(travelerToken)
      : [];
    result.data = costCenters.map(costCenter => ({
      ...costCenter,
      label: costCenter.name,
      value: costCenter.token
    }));
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR;
    }
  }

  return result;
}

export async function getBillingProfiles(travelerToken: string) {
  const result: any = {};
  const {
    companySettings: { billingProfiles: billingProfilesPermission }
  } = getClientPermissions();

  try {
    result.data = billingProfilesPermission
      ? await billingProfileApi.getUserBillingProfileList(travelerToken)
      : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    }
  }

  return result;
}

export async function getUserAreas(userToken: string) {
  const result: any = {};
  const {
    companySettings: { areas: areasPermission }
  } = getClientPermissions();

  try {
    result.data = areasPermission ? await areasApi.getUserAreas(userToken) : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_USER_AREAS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_USER_AREAS_ERROR;
    }
  }

  return result;
}

export async function getUserProjects(userToken: string) {
  const result: any = {};
  const {
    companySettings: { projects: projectsPermission }
  } = getClientPermissions();

  try {
    result.data = projectsPermission
      ? await projectsApi.getUserProjects(userToken)
      : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_USER_PROJECTS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_USER_PROJECTS_ERROR;
    }
  }

  return result;
}
