import { CarSearchCreationRequest } from "~/apps/corporate/dtos/car.dto";
import {
  AdvancedCategory,
  BasicCategory,
  CarCategory,
  IntermediateCategory,
  SpecialCategory,
} from "~/apps/shared/constants/enums";
import { StringTMap } from "~/apps/shared/types";

import { CarsSearch } from "../components/offer-searcher/offer-searcher.types";
import { CarListFilters, CarOffer } from "../models/car.model";

export const buildCarsSearchCreationData = (
  carsSearch: CarsSearch,
  travelToken: string,
): CarSearchCreationRequest => {
  const [dropoffHour, dropoffMinute] = carsSearch.dropoffTime.split(":");
  const [pickupHour, pickupMinute] = carsSearch.pickupTime.split(":");

  const carSearchCreationData = {
    diff_dropoff: !carsSearch.sameLocationDropoff,
    dropoff_date: carsSearch.dropoffDate!.format("YYYY-MM-DD"),
    dropoff_hour: parseInt(dropoffHour, 0),
    dropoff_minute: parseInt(dropoffMinute, 0),
    pickup_date: carsSearch.pickupDate!.format("YYYY-MM-DD"),
    pickup_hour: parseInt(pickupHour, 0),
    pickup_latitude: carsSearch.pickupLocation!.latitude,
    pickup_longitude: carsSearch.pickupLocation!.longitude,
    pickup_minute: parseInt(pickupMinute, 0),
    pickup_search: carsSearch.pickupLocation!.search,
    ...(!carsSearch.sameLocationDropoff &&
      carsSearch.dropoffLocation && {
        dropoff_latitude: carsSearch.dropoffLocation.latitude,
        dropoff_longitude: carsSearch.dropoffLocation.longitude,
        dropoff_search: carsSearch.dropoffLocation.search,
      }),
    travel_token: travelToken,
  };

  return carSearchCreationData;
};

const doesBelongToCategory = (
  sippCode: string,
  category: StringTMap<string>,
) => {
  const categoryArray = Object.values(category);

  return categoryArray.includes(sippCode.charAt(0));
};

export const filterCarList = (
  cars: CarOffer[],
  filters: CarListFilters,
): CarOffer[] => {
  const result = cars.reduce((acc: CarOffer[], current) => {
    const isAmongSelectedClassesOptions = hasAnySelectedClassesOptions(
      current,
      filters.classesOptions,
    );

    const isAmongSelectedRentalsOptions = hasAnySelectedRentalOption(
      current,
      filters.rentalsOptions,
    );

    const isAmongSelectedDoorsOptions = hasAnySelectedDoorsOptions(
      current,
      filters.doorsOptions,
    );

    const isAmongSelectedTransmissionOptions = hasAnySelectedTransmissionOptions(
      current,
      filters.transmissionOptions,
    );

    const isAmongSelectedAirConditioningOptions = hasSelectedAirConditioning(
      current,
      filters.airConditioningOnly,
    );

    const isBetweenPriceRange = isBetweenSelectedPriceRange(
      current,
      filters.selectedPriceRange!,
    );

    const isAmongSelectedPolicyOption = isOnPolicyOnly(
      current,
      filters.inPolicyOnly,
    );

    const isOnSelectedCoordinates = filters.addressFilter
      ? isOnAddress(current, filters.addressFilter)
      : true;

    const areAllConditionsSatisfied =
      isAmongSelectedClassesOptions &&
      isAmongSelectedRentalsOptions &&
      isAmongSelectedDoorsOptions &&
      isAmongSelectedTransmissionOptions &&
      isAmongSelectedAirConditioningOptions &&
      isBetweenPriceRange &&
      isOnSelectedCoordinates &&
      isAmongSelectedPolicyOption;

    if (areAllConditionsSatisfied) {
      acc.push(current);
    }

    return acc;
  }, []);

  return result;
};

export const getCarSmartripsCategory = (sippCode: string) => {
  if (doesBelongToCategory(sippCode, BasicCategory)) {
    return CarCategory.BASIC;
  }

  if (doesBelongToCategory(sippCode, IntermediateCategory)) {
    return CarCategory.INTERMEDIATE;
  }

  if (doesBelongToCategory(sippCode, AdvancedCategory)) {
    return CarCategory.ADVANCED;
  }

  if (doesBelongToCategory(sippCode, SpecialCategory)) {
    return CarCategory.SPECIAL;
  }

  return null;
};

const hasAnySelectedClassesOptions = (
  car: CarOffer,
  classesOptions: {
    checked: boolean;
    id: string;
  }[],
) => {
  if (hasNoItemsChecked(classesOptions)) {
    return true;
  }

  return classesOptions
    .filter((option) => option.checked)
    .some((option) => option.id === car.carDetails.class);
};

const hasSelectedAirConditioning = (
  car: CarOffer,
  airConditionOnly: boolean,
) => {
  if (!airConditionOnly) {
    return true;
  }

  return car.carDetails.features.air;
};

const hasAnySelectedDoorsOptions = (
  car: CarOffer,
  doorsOptions: {
    checked: boolean;
    id: string;
  }[],
) => {
  if (hasNoItemsChecked(doorsOptions)) {
    return true;
  }

  return doorsOptions
    .filter((option) => option.checked)
    .some(
      (option) =>
        option.id.toString() === car.carDetails.features.doors?.toString(),
    );
};

const hasAnySelectedRentalOption = (
  car: CarOffer,
  rentalsOptions: {
    checked: boolean;
    id: string;
  }[],
) => {
  if (hasNoItemsChecked(rentalsOptions)) {
    return true;
  }

  return rentalsOptions
    .filter((option) => option.checked)
    .some((option) => option.id === car.rentalDetails.id);
};

const hasAnySelectedTransmissionOptions = (
  car: CarOffer,
  transmissionOptions: {
    checked: boolean;
    id: string;
  }[],
) => {
  if (hasNoItemsChecked(transmissionOptions)) {
    return true;
  }

  return transmissionOptions
    .filter((option) => option.checked)
    .some((option) => option.id === car.carDetails.features.transmission);
};

const hasNoItemsChecked = (
  items: {
    checked: boolean;
  }[],
) => {
  return items.every((item) => !item.checked);
};

const isBetweenSelectedPriceRange = (car: CarOffer, priceRange: number[]) => {
  const [min, max] = priceRange;
  const { netTotal } = car.contractDetails.finalPriceInfo;

  return netTotal >= min && netTotal <= max;
};

const isOnAddress = (
  car: CarOffer,
  addressFilter: {
    lat: number;
    lng: number;
  },
) => {
  const { lat, lng } = addressFilter;
  const { latitude, longitude } = car.pickup;

  return parseFloat(latitude) === lat && parseFloat(longitude) === lng;
};

const isOnPolicyOnly = (car: CarOffer, isOnPolicyOnly: boolean) => {
  return isOnPolicyOnly ? !car.outOfPolicy : true;
};
