import { logger } from "~/apps/shared/utils/logger";

import { APPROVAL_PROCESS_TYPES, ERROR } from "@constants";

import {
  ApprovalProcessCreateDto,
  ApprovalProcessEditDto,
} from "@dtos/approval-process.dto";

import {
  ApprovalProcessFormType,
  ApprovalProcessInfo,
  ApprovalTarget,
} from "@models/approval-process.model";
import { UserSearch } from "@models/user.model";

import * as ApprovalRulesApi from "@apis/approval-process.api";

import {
  GetApprovalProcessResult,
  CreateApprovalProcessResult,
  ListApprovalProcessResult,
  EditApprovalProcessResult,
} from "./approval-processes.types";

export async function loadApprovalRules(): Promise<ListApprovalProcessResult> {
  try {
    const rules = await ApprovalRulesApi.getApprovalRulesList();

    return {
      data: rules,
    };
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    }
  }
}

export async function loadApprovalRule(
  token: string,
): Promise<GetApprovalProcessResult> {
  try {
    const rules = await ApprovalRulesApi.getApprovalRule(token);

    return {
      data: rules,
    };
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_LISTING_ERROR };
    }
  }
}

function mapRules(
  rules: ApprovalProcessFormType["rules"],
): ApprovalProcessCreateDto["rules"] {
  if (!Array.isArray(rules)) {
    return [];
  }

  const parsed = rules.reduce((acc, rule) => {
    rule.actions.forEach((action) => {
      if (rule.type.value === APPROVAL_PROCESS_TYPES.APPROVE) {
        const stages = action.stages.map((stage) => {
          return stage.users.map((user) => {
            return {
              user_token: user.token,
              stage: stage.stage ? stage.stage : 1,
              action: APPROVAL_PROCESS_TYPES.APPROVE,
              policy_condition: rule.policy.value,
              territory_condition: rule.territory.value,
            };
          });
        });

        return acc.push(...stages.flat());
      }

      const stages = action.stages.map((stage) => {
        return stage.users.map((user) => {
          return {
            user_token: user.token,
            stage: null,
            action: APPROVAL_PROCESS_TYPES.NOTIFY,
            policy_condition: rule.policy.value,
            territory_condition: rule.territory.value,
          };
        });
      });

      return acc.push(...stages.flat());
    });

    return acc;
  }, [] as ApprovalProcessCreateDto["rules"]);

  return parsed;
}

export function getSingleApproverToken(
  data: ApprovalProcessFormType,
): string | null {
  try {
    const singleApproverToken =
      data.rules[0]?.actions[0].stages[0].users[0].token;
    return singleApproverToken;
  } catch (err) {
    //Devo logar? ou melhor fazer alguma outra coisa?
    logger.error(
      `ocorreu um erro ao definir o singleApproverToken: ${err.message}`,
    );
    return null;
  }
}

export async function createRule(
  data: ApprovalProcessInfo,
): Promise<CreateApprovalProcessResult> {
  try {
    const bodyDto: ApprovalProcessCreateDto = {
      name: data.name,
      targets: data.targets.map((target) => ({ target_token: target.token })),
      rules: mapRules(data.rules),
      single_approver_token: data.singleApproverToken,
      target_type: data.type.value,
      need_approval: data.approvalNeeded,
    };

    const result = await ApprovalRulesApi.createApprovalRule(bodyDto);

    return { data: result };
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_CREATION_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_CREATION_ERROR };
    }
  }
}

export async function editRule(
  data: ApprovalProcessInfo,
): Promise<EditApprovalProcessResult> {
  try {
    const bodyDto: ApprovalProcessEditDto = {
      name: data.name,
      targets: data.targets.map((target) => ({ target_token: target.token })),
      rules: mapRules(data.rules),
      single_approver_token: data.singleApproverToken,
      target_type: data.type.value,
      need_approval: data.approvalNeeded,
    };

    const response = await ApprovalRulesApi.editApprovalRule(
      data.token as string,
      bodyDto,
    );

    return { data: response };
  } catch (error) {
    if (error.response) {
      if (error.response.data.pending_approval_requests) {
        return { error: ERROR.APPROVAL_RULE_PENDING_REQUESTS };
      }

      return { error: ERROR.UNEXPECTED_APPROVAL_EDITION_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_EDITION_ERROR };
    }
  }
}

export async function searchRuleTarget(
  searchText: string,
  targetType?: string,
): Promise<{ data?: ApprovalTarget[]; error?: any }> {
  const result: { data?: ApprovalTarget[]; error?: any } = {
    data: undefined,
    error: undefined,
  };

  try {
    result.data = await ApprovalRulesApi.searchApprovalTarget(
      searchText,
      targetType,
    );
    result.data = result.data.map((item) =>
      Object.assign({}, item, { label: item.name }),
    );
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR };
    }
  }

  return result;
}

export async function deleteRule(
  approvalToken: string,
): Promise<{ data?: any; error?: any }> {
  try {
    const result = await ApprovalRulesApi.deleteApprovalRule(approvalToken);

    return { data: result.data };
  } catch (error) {
    if (error.response) {
      if (error.response.data.pending_approval_requests) {
        return { error: ERROR.APPROVAL_RULE_PENDING_REQUESTS };
      }
    }

    return { error: error };
  }
}

export async function searchApproverUsersByNameAndEmail(
  value: string,
): Promise<{ data?: UserSearch[]; error?: Error }> {
  const result: { data?: UserSearch[]; error?: Error } = {};

  try {
    result.data = await ApprovalRulesApi.searchApproverUsersByNameAndEmail(
      value,
    );
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_APPROVER_USERS_LIST_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_APPROVER_USERS_LIST_ERROR };
    }
  }

  return result;
}
