import React, { useContext, useEffect } from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";

import { theme } from "@skin/v2";

import { Flex } from "@toolkit";
import { Button, Switch } from "@toolkit/v2";

import TITLES from "@constants/page-titles.constants";

import {
  costCenterBatchApi,
  updateCostCentersBatchApi,
} from "~/apis/excel.api";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import PageTitle from "@components/shared/PageTitle";
import SnackBar from "@components/shared/SnackBar";
import SpinnerPortal from "@components/shared/Spinner";

import { ArchiveDialog } from "./ArchiveDialog/ArchiveDialog";
import { CostCentersProvider, CostCentersContext } from "./CostCenters.context";
import { CostCentersForm } from "./form";
import { CostCentersList } from "./list";
import { ToggleStatusDialog } from "./ToggleStatusDialog/ToggleStatusDialog.container";

const styles = {
  buttons: {
    root: css({
      flexDirection: "column",
      gap: "1rem",
      justifyContent: "space-between",
    }),
    add: css({
      width: "fit-content",
      height: "3rem",
      backgroundColor: theme.colors.pink[500],
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2),
      },
    }),
    excel: css({
      height: "3rem",
    }),
  },
  switch: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      width: "fit-content",
      margin: "2rem 0",
      padding: "0.75rem 1rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
      cursor: "pointer",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
};

const CostCenters = () => {
  const { clientConfig } = useClientConfig();

  const {
    handleOpenToggleDialog,
    isFormOpen,
    selectedToEdit,
    isLoading,
    snack,
    fetchCostCenters,
    handleOpenForm,
    handleCloseForm,
    handleCloseSnackbar,
    handleChangeSearch,
  } = useContext(CostCentersContext);

  const isCostCentersEnabled = clientConfig
    ? clientConfig.isCompanyCostCenterEnabled()
    : false;

  useEffect(() => {
    fetchCostCenters();
  }, []);

  const handleCostCenterStatusCheck = () => {
    handleOpenToggleDialog();
  };

  return (
    <div>
      <PageTitle title={TITLES.COST_CENTERS} />
      <SpinnerPortal visible={isLoading} />
      <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
        <Flex css={styles.buttons.root}>
          <ContainedButton onClick={handleOpenForm()} css={styles.buttons.add}>
            Adicionar centro de custos
          </ContainedButton>
          <Flex style={{ gap: "1.5rem" }}>
            <Button
              fill="outlined"
              onClick={updateCostCentersBatchApi.onDownloadModelClick}
              css={styles.buttons.excel}
            >
              <Icon use="cloud-arrow-down" />
              Exportar
            </Button>
            <BatchFileUploader
              onUpload={updateCostCentersBatchApi.onUpload}
              onDownloadModelClick={
                updateCostCentersBatchApi.onDownloadModelClick
              }
              onSuccessUpload={fetchCostCenters}
            >
              {({ openDialog }) => (
                <Button
                  fill="outlined"
                  onClick={openDialog}
                  css={styles.buttons.excel}
                >
                  <Icon use="cloud-arrow-up" />
                  Atualizar via excel
                </Button>
              )}
            </BatchFileUploader>
            <BatchFileUploader
              onUpload={costCenterBatchApi.onUpload}
              onDownloadModelClick={costCenterBatchApi.onDownloadModelClick}
              onSuccessUpload={fetchCostCenters}
            >
              {({ openDialog }) => (
                <Button
                  fill="outlined"
                  onClick={openDialog}
                  css={styles.buttons.excel}
                >
                  <Icon use="cloud-arrow-up" />
                  Cadastrar via excel
                </Button>
              )}
            </BatchFileUploader>
          </Flex>
        </Flex>
        <SearchInput
          onChange={handleChangeSearch}
          placeholder="Buscar por centro de custo"
        />
      </Flex>
      <label css={styles.switch.root}>
        <Switch
          active={isCostCentersEnabled}
          onChange={handleCostCenterStatusCheck}
          variant="pink"
        />
        <span css={styles.switch.text}>
          Ativar função centro de custo para empresa
        </span>
      </label>
      <CostCentersList />
      <CostCentersForm
        open={isFormOpen}
        selectedCostCenter={selectedToEdit}
        handleClose={handleCloseForm}
      />
      <ArchiveDialog />
      <ToggleStatusDialog />
      <SnackBar
        visible={snack.open}
        type={snack.type}
        message={snack.message}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

const Container = () => (
  <CostCentersProvider>
    <CostCenters />
  </CostCentersProvider>
);

export { Container as CostCenters };
