import React, { ReactNode, useState, useEffect } from "react";

import { navigate } from "@reach/router";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import { ALERT_TYPES } from "../../constants";
import * as expensesHelper from "../../helpers/expense.helper";
import { getUserFromLocalStorage } from "../../helpers/user.helper";
import { Expense, ExpenseReport } from "../../models/expense.model";
import { UserModel } from "../../models/user.model";
import { CustomError, StringBooleanMap, ImageFile } from "../../types";
import * as expensesService from "./Expenses.service";

interface State {
  expenses: Expense[];
  currentPage: number;
  totalPages: number;
  searchFilter: string;
  selectedExpense: Expense | null;
  selectedExpenseReceiptFile: ImageFile | null;
  checkedExpenses: StringBooleanMap;
  isExpenseDrawerOpen: boolean;
  isLoading: boolean;
  expenseToDelete: Expense | null;
  isDeleting: boolean;
  isDeletionDialogOpen: boolean;
  pendingReportsList: ExpenseReport[];
  reportListCurrentPage: number;
  reportListTotalPages: number;
  selectedExpenseReportTokenToAdd: string;
  isReportListDrawerOpen: boolean;
  isReportCreationDialogOpen: boolean;
  isSubmitingReportCreation: boolean;
  loadingReports: boolean;
  loggedUser: UserModel;
  isUserApprover: boolean;
  isUserPayer: boolean;
  snack: {
    type: string;
    open: boolean;
    message: string;
  };
}

interface Actions {
  fetchExpenses: () => void;
  loadNextExpenses: () => void;
  handleChangeSearchFilter: (searchText: string) => void;
  openExpensesDrawer: (selectedExpense?: Expense) => void;
  handleDrawerClose: () => void;
  onAfterExpenseSave: () => void;
  handleCheckExpense: (expenseToken: string) => (checked: boolean) => void;
  handleExpenseDelete: (expense: Expense) => () => void;
  proccessExpenseDeletion: () => void;
  handleCloseDeleteDialog: () => void;
  handleOpenReportListDrawer: () => void;
  handleCloseReportListDrawer: () => void;
  fetchUserPendingReports: () => void;
  loadNextPendingReports: () => void;
  handleCheckExpenseReport: (
    ExpenseReportToken: string
  ) => (_: any, checked: boolean) => void;
  addExpensesToReport: (expenseReportToken: string) => void;
  handleOpenReportCreationDialog: () => void;
  handleCloseReportCreationDialog: () => void;
  createReportWithExpenses: (formData: {
    description: string;
    reportType: string;
  }) => void;
  handleSnackClose: () => void;
  checkUserPermisions: () => void;
}

type ContextProps = State & Actions;

const initialState: State = {
  expenses: [],
  currentPage: 1,
  totalPages: 1,
  searchFilter: "",
  selectedExpense: null,
  selectedExpenseReceiptFile: null,
  checkedExpenses: {},
  isExpenseDrawerOpen: false,
  isLoading: false,
  expenseToDelete: null,
  isDeleting: false,
  isDeletionDialogOpen: false,
  pendingReportsList: [],
  reportListCurrentPage: 1,
  reportListTotalPages: 1,
  selectedExpenseReportTokenToAdd: "",
  isReportListDrawerOpen: false,
  isReportCreationDialogOpen: false,
  isSubmitingReportCreation: false,
  loadingReports: false,
  loggedUser: getUserFromLocalStorage()!,
  isUserApprover: false,
  isUserPayer: false,
  snack: {
    type: "",
    open: false,
    message: ""
  }
};

const ExpensesContext = React.createContext<ContextProps>({
  ...initialState,
  fetchExpenses: () => null,
  loadNextExpenses: () => null,
  handleChangeSearchFilter: () => null,
  openExpensesDrawer: () => null,
  handleDrawerClose: () => null,
  onAfterExpenseSave: () => null,
  handleCheckExpense: () => () => null,
  handleExpenseDelete: () => () => null,
  proccessExpenseDeletion: () => null,
  handleCloseDeleteDialog: () => null,
  handleOpenReportListDrawer: () => null,
  handleCloseReportListDrawer: () => null,
  fetchUserPendingReports: () => null,
  loadNextPendingReports: () => null,
  handleCheckExpenseReport: () => () => null,
  addExpensesToReport: () => null,
  handleOpenReportCreationDialog: () => null,
  handleCloseReportCreationDialog: () => null,
  createReportWithExpenses: () => null,
  handleSnackClose: () => null,
  checkUserPermisions: () => null
});

const { Provider, Consumer } = ExpensesContext;

const ExpensesProvider = ({ children }: { children: ReactNode | Element }) => {
  const [state, setState] = useState<State>(initialState);
  const setSafeState = (newState: Partial<State>) => {
    setState(prevState => Object.assign({}, prevState, newState));
  };

  const checkUserPermisions = async () => {
    const {
      data,
      error
    } = await expensesService.checkUserExpensesApprovalProcesses();

    if (error) {
      return setErrorState(error);
    }

    setSafeState({
      isUserApprover: !isEmpty(data!.approval),
      isUserPayer: !isEmpty(data!.payment)
    });
  };

  const fetchExpenses = async () => {
    setSafeState({ isLoading: true });
    const { searchFilter } = state;

    const {
      data: expensesData,
      error: expensesError
    } = await expensesService.getPendingExpenses(1, searchFilter);

    if (expensesError) {
      return setErrorState(expensesError);
    }

    setSafeState({
      isLoading: false,
      expenses: expensesData!.expenses,
      currentPage: 1,
      totalPages: expensesData!.totalPages
    });
  };

  const loadNextExpenses = async () => {
    setSafeState({ isLoading: true });
    const { expenses, currentPage, searchFilter } = state;

    const {
      data: expensesData,
      error: expensesError
    } = await expensesService.getPendingExpenses(currentPage + 1, searchFilter);

    if (expensesError) {
      return setErrorState(expensesError);
    }

    setSafeState({
      isLoading: false,
      expenses: expenses.concat(expensesData!.expenses),
      currentPage: currentPage + 1,
      totalPages: expensesData!.totalPages
    });
  };

  const handleChangeSearchFilter = debounce(
    searchText => setSafeState({ searchFilter: searchText }),
    300
  );

  const handleCheckExpense = (expenseToken: string) => (checked: boolean) => {
    setSafeState({
      checkedExpenses: Object.assign({}, state.checkedExpenses, {
        [expenseToken]: checked
      })
    });
  };

  const proccessExpenseDeletion = async () => {
    setSafeState({ isDeleting: true });

    const { expenseToken } = state.expenseToDelete!;
    const { error: deleteError } = await expensesService.deleteExpense(
      expenseToken
    );

    if (deleteError) {
      return setErrorState(deleteError);
    }

    setSafeState({
      expenses: state.expenses.filter(
        expense => expense.expenseToken !== expenseToken
      ),
      checkedExpenses: Object.assign({}, state.checkedExpenses, {
        [expenseToken]: false
      }),
      expenseToDelete: null,
      isDeleting: false,
      isDeletionDialogOpen: false,
      snack: {
        type: ALERT_TYPES.SUCCESS,
        open: true,
        message: "Despesa excluída com sucesso"
      }
    });
  };

  const handleExpenseDelete = (expense: Expense) => () => {
    setSafeState({
      expenseToDelete: expense,
      isDeletionDialogOpen: true
    });
  };

  const handleCloseDeleteDialog = () => {
    setSafeState({
      expenseToDelete: null,
      isDeleting: false,
      isDeletionDialogOpen: false
    });
  };

  const openExpensesDrawer = async (selectedExpense?: Expense) => {
    setSafeState({ isLoading: true });
    let receiptFile;

    if (selectedExpense && selectedExpense.receiptFile) {
      const {
        data: receiptImageFile,
        error: receiptImageError
      } = await expensesService.getReceiptImageFile(
        selectedExpense.receiptFile
      );

      if (receiptImageError) {
        return setErrorState(receiptImageError);
      }

      receiptFile = receiptImageFile;
    }

    setSafeState({
      isLoading: false,
      isExpenseDrawerOpen: true,
      selectedExpense,
      selectedExpenseReceiptFile: receiptFile
    });
  };

  const handleDrawerClose = () => {
    setSafeState({
      isExpenseDrawerOpen: false,
      selectedExpense: null,
      selectedExpenseReceiptFile: null
    });
  };

  const handleOpenReportListDrawer = () => {
    setSafeState({
      isReportListDrawerOpen: true
    });
  };

  const handleCloseReportListDrawer = () => {
    setSafeState({
      isReportListDrawerOpen: false,
      selectedExpenseReportTokenToAdd: ""
    });
  };

  const onAfterExpenseSave = () => {
    setSafeState({
      isExpenseDrawerOpen: false,
      selectedExpense: null,
      selectedExpenseReceiptFile: null,
      snack: {
        type: ALERT_TYPES.SUCCESS,
        open: true,
        message: state.selectedExpense
          ? "Despesa editada com sucesso"
          : "Despesa criada com sucesso"
      }
    });
    void fetchExpenses();
  };

  const fetchUserPendingReports = async () => {
    setSafeState({ loadingReports: true });

    const {
      data: expenseReports,
      error: expenseReportsError
    } = await expensesService.listReportsByUser(1, "open");

    if (expenseReportsError) {
      return setErrorState(expenseReportsError);
    }

    setSafeState({
      loadingReports: false,
      pendingReportsList: expenseReports!.expenseReports,
      reportListCurrentPage: 1,
      reportListTotalPages: expenseReports!.totalPages
    });
  };

  const loadNextPendingReports = async () => {
    setSafeState({ loadingReports: true });
    const { pendingReportsList, reportListCurrentPage } = state;

    const {
      data: expenseReports,
      error: expenseReportsError
    } = await expensesService.listReportsByUser(
      reportListCurrentPage + 1,
      "open"
    );

    if (expenseReportsError) {
      return setErrorState(expenseReportsError);
    }

    setSafeState({
      loadingReports: false,
      pendingReportsList: pendingReportsList.concat(
        expenseReports!.expenseReports
      ),
      reportListCurrentPage: reportListCurrentPage + 1,
      reportListTotalPages: expenseReports!.totalPages
    });
  };

  const handleCheckExpenseReport = (expenseReportToken: string) => (
    _: any,
    checked: boolean
  ) => {
    setSafeState({
      selectedExpenseReportTokenToAdd: checked ? expenseReportToken : ""
    });
  };

  const addExpensesToReport = async (expenseReportToken: string) => {
    setSafeState({ loadingReports: true });

    const selectedExpensesTokens = expensesHelper.getCheckedExpenseTokens(
      state.checkedExpenses
    );

    const {
      error: addExpensesError
    } = await expensesService.addExpensesToReport(
      selectedExpensesTokens,
      expenseReportToken
    );

    if (addExpensesError) {
      return setErrorState(addExpensesError);
    }

    setSafeState({
      loadingReports: false,
      checkedExpenses: {},
      isReportListDrawerOpen: false,
      selectedExpenseReportTokenToAdd: ""
    });

    navigate(`/reports/${expenseReportToken}`);
  };

  const handleOpenReportCreationDialog = () => {
    setSafeState({
      isReportCreationDialogOpen: true
    });
  };

  const handleCloseReportCreationDialog = () => {
    setSafeState({
      isReportCreationDialogOpen: false
    });
  };

  const createReportWithExpenses = async (formData: {
    description: string;
    reportType: string;
  }) => {
    setSafeState({ isSubmitingReportCreation: true });

    const selectedExpensesTokens = expensesHelper.getCheckedExpenseTokens(
      state.checkedExpenses
    );

    const {
      data: expenseReportToken,
      error: expenseReportError
    } = await expensesService.createReportWithExpenses(
      selectedExpensesTokens,
      formData
    );

    if (expenseReportError) {
      return setErrorState(expenseReportError);
    }

    setSafeState({
      checkedExpenses: {},
      isSubmitingReportCreation: false,
      isReportCreationDialogOpen: false,
      isReportListDrawerOpen: false,
      selectedExpenseReportTokenToAdd: ""
    });

    navigate(`/reports/${expenseReportToken}`);
  };

  const handleSnackClose = () => {
    setSafeState({
      snack: {
        type: "",
        open: false,
        message: ""
      }
    });
  };

  const setErrorState = (error: CustomError) => {
    setSafeState({
      isLoading: false,
      isDeleting: false,
      isSubmitingReportCreation: false,
      loadingReports: false,
      snack: {
        type: ALERT_TYPES.ERROR,
        open: true,
        message: error.description
      }
    });
  };

  useEffect(() => {
    setSafeState({ loggedUser: getUserFromLocalStorage() });
  }, []);

  useEffect(() => {
    fetchExpenses();
  }, [state.searchFilter]);

  return (
    <Provider
      value={{
        ...state,
        fetchExpenses,
        loadNextExpenses,
        handleChangeSearchFilter,
        openExpensesDrawer,
        handleDrawerClose,
        onAfterExpenseSave,
        handleCheckExpense,
        handleExpenseDelete,
        proccessExpenseDeletion,
        handleCloseDeleteDialog,
        handleOpenReportListDrawer,
        handleCloseReportListDrawer,
        fetchUserPendingReports,
        loadNextPendingReports,
        handleCheckExpenseReport,
        addExpensesToReport,
        handleOpenReportCreationDialog,
        handleCloseReportCreationDialog,
        createReportWithExpenses,
        handleSnackClose,
        checkUserPermisions
      }}
    >
      {children}
    </Provider>
  );
};

export { ExpensesContext, ExpensesProvider, Consumer as ExpensesConsumer };
