import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  image: css({
    borderRadius: "4px",
  }),
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "8px",
    width: "fit-content",
  }),
  text: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
  }),
};
