import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { CompanyDealBadge } from "~/apps/corporate/components/company-deal-badge/company-deal-badge";
import { NegotiatedFareBadge } from "~/apps/corporate/components/negotiated-fare-badge/negotiated-fare-badge";
import { HospitalityHotelDetails } from "~/apps/corporate/dtos/hotel.dto";
import { HotelListOffer } from "~/apps/corporate/models/offer.model";
import { AmenitiesIcons } from "~/apps/shared/components/amenities-icons/amenities-icons";
import { Icon } from "~/apps/shared/components/icon/icon";
import { NoImageHotel } from "~/apps/shared/components/no-image-hotel/no-image-hotel";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  freeCancelationUntil?: string;
  handleOpenOutOfPolicyDialog: () => void;
  handleOpenRoomAmenitiesDialog: (amenities: string[]) => () => void;
  handleSelectOffer: () => void;
  handleShowRoomPictures: (pictures: string[]) => () => void;
  hotelInfo: HospitalityHotelDetails;
  isButtonDisabled: boolean;
  offer: HotelListOffer;
  roomAmenities?: string[];
  roomPictures?: string[];
  totalGuests: number;
};

export const RoomCard: React.FC<Props> = ({
  freeCancelationUntil,
  handleOpenOutOfPolicyDialog,
  handleOpenRoomAmenitiesDialog,
  handleSelectOffer,
  handleShowRoomPictures,
  hotelInfo,
  isButtonDisabled,
  offer,
  roomAmenities,
  roomPictures,
  totalGuests,
}) => {
  const { width: windowWidth } = useWindowSize();

  const isMobile = useMemo(() => windowWidth <= 640, [windowWidth]);

  const showCompanyNegotiatedBadge = offer.isCompanyNegotiated;
  const showNegotiatedBadge = offer.isNegotiated;
  const showOutOfPolicyBadge = offer.outOfPolicy;

  return (
    <li css={styles.root}>
      <div css={styles.left.root}>
        {roomPictures && roomPictures.length > 0 ? (
          <>
            <img
              alt={offer.roomName}
              css={styles.left.picture}
              onClick={() => {
                handleShowRoomPictures(roomPictures)();
              }}
              src={roomPictures[0]}
            />
            <button
              css={styles.left.overlay.images}
              onClick={() => {
                handleShowRoomPictures(roomPictures)();
              }}
            >
              <Icon use="photos" />
            </button>
          </>
        ) : (
          <NoImageHotel
            css={styles.left.picture}
            style={{ cursor: "default" }}
          />
        )}
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.top.root}>
          <span css={styles.center.top.name}>{offer.roomName}</span>
        </div>
        <div css={styles.center.center.root}>
          <AmenitiesIcons
            breakfast={offer.popularAmenities.breakfast}
            cancel={offer.popularAmenities.refund}
            cancelationUntil={freeCancelationUntil}
            css={styles.center.center.amenities}
            wifi={hotelInfo.popularAmenities.wifi}
          />
          {offer.isNegotiated ? (
            <span css={styles.center.center.negotiated}>
              Essa é uma tarifa negociada, e os valores podem variar de acordo
              com disponibilidade e temporada.
            </span>
          ) : null}
        </div>
        {!!roomAmenities && roomAmenities.length > 0 ? (
          <div css={styles.center.bottom.root}>
            <Button
              css={styles.center.bottom.button}
              fill="outlined"
              onClick={handleOpenRoomAmenitiesDialog(roomAmenities!)}
            >
              <Icon use="eye" />
              <span>
                {windowWidth > 992
                  ? "Ver itens disponíveis nessa acomodação"
                  : "Ver itens"}
              </span>
            </Button>
          </div>
        ) : null}
      </div>
      <div css={styles.right.root}>
        <div css={styles.bottom.root}>
          <div css={styles.right.top.badges.root}>
            {showCompanyNegotiatedBadge ? (
              <CompanyDealBadge css={styles.right.top.badges.badge} />
            ) : showNegotiatedBadge ? (
              <NegotiatedFareBadge css={styles.right.top.badges.badge} />
            ) : null}
            {showOutOfPolicyBadge ? (
              <OutOfPolicyBadge
                css={styles.right.top.badges.badge}
                onClick={() => {
                  handleOpenOutOfPolicyDialog();
                }}
              />
            ) : null}
          </div>
          {!isMobile ? null : (
            <span css={styles.right.top.sub}>Impostos e taxas inclusos</span>
          )}
        </div>
        <div css={styles.right.top.root}>
          <span css={styles.right.top.label}>Diárias a partir de</span>
          <div css={styles.right.top.price.root}>
            <span css={styles.right.top.price.value}>
              {offer.nightlyPrice.displayValue}
            </span>
            <span css={styles.right.top.price.night}>/noite</span>
          </div>
        </div>
        {!isMobile ? (
          <span css={styles.right.top.sub}>Impostos e taxas incluídos</span>
        ) : null}
        <div css={styles.right.center.root}>
          <div css={styles.right.guests.root}>
            <Icon size={16} use={totalGuests > 1 ? "people" : "person"} />
            <span>
              {totalGuests} Hóspede{totalGuests === 1 ? "" : "s"}
            </span>
          </div>
          {offer.points ? (
            <PermissionProtectedRender permissions={["travels.gamification"]}>
              <div css={styles.right.guests.root}>
                <Icon size={16} use="star-outline" />
                <span>
                  Ganhe {offer.points} ponto{offer.points === 1 ? "" : "s"}
                </span>
              </div>
            </PermissionProtectedRender>
          ) : null}
        </div>
        <Button
          css={styles.right.button}
          disabled={isButtonDisabled}
          onClick={handleSelectOffer}
          variant="pink"
        >
          Adicionar{windowWidth > 768 || windowWidth <= 640 ? " à viagem" : ""}
        </Button>
      </div>
    </li>
  );
};

export const RoomCardSkeleton: React.FC = () => {
  const { width: windowWidth } = useWindowSize();

  return (
    <li css={styles.root}>
      <div css={styles.left.root}>
        <Skeleton
          css={styles.left.picture}
          style={{
            backgroundColor: "transparent",
          }}
        />
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.top.root}>
          <Skeleton height="24px" width="192px" />
        </div>
        <div css={styles.center.center.root}>
          <Skeleton height="20px" width="144px" />
          <Skeleton height="20px" width="192px" />
        </div>
        <div css={styles.center.bottom.root}>
          <Button css={styles.center.bottom.button} disabled fill="outlined">
            <Icon use="eye" />
            <span>
              {windowWidth > 768
                ? "Ver itens disponíveis nessa acomodação"
                : "Ver itens"}
            </span>
          </Button>
        </div>
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          <Skeleton height="14px" width="144px" />
          <div css={styles.right.top.price.root}>
            <Skeleton height="24px" width="128px" />
            <Skeleton height="14px" width="36px" />
          </div>
          <Skeleton height="12px" width="144px" />
        </div>
        <div css={styles.right.center.root}>
          <Skeleton height="16px" width="96px" />
        </div>
        <Button css={styles.right.button} disabled variant="pink">
          Adicionar à viagem
        </Button>
      </div>
    </li>
  );
};
