import React, { useCallback, useState } from "react";

import { getBusSuggestions } from "~/apps/corporate/apis/autosuggest.api";
import {
  busFormSchema,
  BusFormSchema,
} from "~/apps/corporate/components/offer-searcher/bus-form/bus-form.schema";
import { BusesSearch } from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { BookingTarget } from "~/apps/corporate/models/booking-target.model";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { DatePicker } from "~/apps/shared/components/date-picker/date-picker";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { TextField } from "~/apps/shared/components/text-field/text-field";

import { Button, CircularSpinner, Switch } from "@toolkit/v2";

import { NewTripOfferSearcherTravelerAutocomplete } from "../traveler-autocomplete/traveler-autocomplete";
import { styles } from "./styles";

type Props = {
  handleSearchBuses: (busesSearch: BusesSearch) => Promise<void>;
};

export const NewTripOfferSearcherBusForm: React.FC<Props> = ({
  handleSearchBuses,
}) => {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);
  const [traveler, setTraveler] = useState<BookingTarget | null>(null);

  const form = useForm<BusFormSchema>({
    defaultValues: {
      destination: null,
      departureDate: null,
      origin: null,
      returnDate: null,
      type: "roundtrip",
    } as BusFormSchema,
    onSubmit: async () => {
      if (!traveler) {
        return;
      }

      const values = form.getValues();

      await handleSearchBuses({
        destination: values.destination,
        departureDate: values.departureDate,
        origin: values.origin,
        returnDate: values.returnDate,
        traveler,
        type: values.type,
      });
    },
    schema: busFormSchema,
  });

  const handleSwapLocations = useCallback(() => {
    const { destination, origin } = form.getValues();

    form.setValue("destination", origin);
    form.setValue("origin", destination);
  }, [form]);

  const showTravelerErrorMessage =
    (hasTriedToSubmit && !traveler) || (!!traveler && !traveler.allowed);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.top.root}>
        <Form.Field<BusFormSchema["type"] | undefined>
          name="type"
          render={({ value }) => (
            <label css={styles.top.switch.root}>
              <Switch
                active={value === "roundtrip"}
                onChange={(e) => {
                  if (value === "oneway") {
                    form.clearErrors("returnDate");
                  }

                  form.setValue(
                    "type",
                    e.target.checked ? "roundtrip" : "oneway",
                  );
                }}
                variant="pink"
              />
              <span css={styles.top.switch.text}>Passagem de ida e volta</span>
            </label>
          )}
        />
        <NewTripOfferSearcherTravelerAutocomplete
          onChange={(value) => {
            if (!value) {
              setTraveler(null);

              return;
            }

            setTraveler((prev) => {
              if (!prev) {
                return {
                  fullName: value,
                } as BookingTarget;
              }

              return {
                ...prev,
                fullName: value,
              };
            });
          }}
          onSelect={(value) => {
            setTraveler({
              allowed: value.allowed,
              email: value.email,
              fullName: value.label,
              userToken: value.userToken,
            });
          }}
          showErrorMessage={showTravelerErrorMessage}
          value={traveler ? traveler.fullName : undefined}
        />
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.left.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.left.control.location.origin.container}>
              <Form.Field<BusFormSchema["origin"] | undefined>
                name="origin"
                render={({ value }) => (
                  <AsyncAutocomplete
                    fetchItems={async (search) => {
                      try {
                        return {
                          data: await getBusSuggestions(search),
                        };
                      } catch (error) {
                        return {
                          error,
                        };
                      }
                    }}
                    inputProps={{
                      placeholder: "Digite o local de origem...",
                    }}
                    onChange={(value) => {
                      form.setValue("origin.label", value);
                    }}
                    onSelect={(location) => {
                      if (!location) {
                        form.setValue("origin", null);

                        return;
                      }

                      form.setValue("origin", location);
                    }}
                    renderInput={({ loading, ...props }) => (
                      <div css={styles.center.left.control.location.input.root}>
                        {loading ? (
                          <CircularSpinner
                            css={
                              styles.center.left.control.location.input.loading
                            }
                            size={20}
                          />
                        ) : (
                          <Icon size={20} use="bus" />
                        )}
                        <TextField
                          css={styles.center.left.control.location.input.input}
                          inputProps={{ ...props }}
                          label="Origem*"
                          variant="outlined"
                        />
                      </div>
                    )}
                    renderItem={({ isHighlighted, item, ...props }) => {
                      if (item.error) {
                        return (
                          <li
                            css={
                              styles.center.left.control.location.suggestion
                                .item
                            }
                            key={0}
                            {...props}
                          >
                            Nenhum local encontrado...
                          </li>
                        );
                      }

                      return (
                        <li
                          css={styles.center.left.control.location.suggestion.button(
                            {
                              isHighlighted,
                            },
                          )}
                          key={JSON.stringify(item)}
                          {...props}
                        >
                          {item.label}
                        </li>
                      );
                    }}
                    value={value?.label}
                  />
                )}
              />
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["origin"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.center.left.swap.root}>
            <button
              css={styles.center.left.swap.button}
              onClick={() => {
                handleSwapLocations();
              }}
              type="button"
            >
              <Icon size={16} use="arrows-right-left" />
            </button>
          </div>
          <div css={styles.center.control.root}>
            <div
              css={styles.center.left.control.location.destination.container}
            >
              <Form.Field<BusFormSchema["destination"] | undefined>
                name="destination"
                render={({ value }) => (
                  <AsyncAutocomplete
                    fetchItems={async (search) => {
                      try {
                        return {
                          data: await getBusSuggestions(search),
                        };
                      } catch (error) {
                        return {
                          error,
                        };
                      }
                    }}
                    inputProps={{
                      placeholder: "Digite o local de destino...",
                    }}
                    onChange={(value) => {
                      form.setValue("destination.label", value);
                    }}
                    onSelect={(location) => {
                      if (!location) {
                        form.setValue("destination", null);

                        return;
                      }

                      form.setValue("destination", location);
                    }}
                    renderInput={({ loading, ...props }) => (
                      <div css={styles.center.left.control.location.input.root}>
                        {loading ? (
                          <CircularSpinner
                            css={
                              styles.center.left.control.location.input.loading
                            }
                            size={20}
                          />
                        ) : (
                          <Icon size={20} use="bus" />
                        )}
                        <TextField
                          css={styles.center.left.control.location.input.input}
                          inputProps={{ ...props }}
                          label="Destino*"
                          variant="outlined"
                        />
                      </div>
                    )}
                    renderItem={({ isHighlighted, item, ...props }) => {
                      if (item.error) {
                        return (
                          <li
                            css={
                              styles.center.left.control.location.suggestion
                                .item
                            }
                            key={0}
                            {...props}
                          >
                            Nenhum local encontrado...
                          </li>
                        );
                      }

                      return (
                        <li
                          css={styles.center.left.control.location.suggestion.button(
                            {
                              isHighlighted,
                            },
                          )}
                          key={JSON.stringify(item)}
                          {...props}
                        >
                          {item.label}
                        </li>
                      );
                    }}
                    value={value?.label}
                  />
                )}
              />
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["destination"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.center.right.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.right.control.dates.container}>
              <div css={styles.center.right.control.dates.root}>
                {form.watch("type") === "oneway" ? (
                  <DatePicker
                    anchorDirection="right"
                    date={form.watch("departureDate")}
                    focused={rangeDateFocus === "startDate"}
                    id="departureDate"
                    onDateChange={(date) => {
                      form.setValue("departureDate", date);

                      if (date) {
                        form.clearErrors("departureDate");
                      }
                    }}
                    onFocusChange={({ focused }) => {
                      setRangeFocus(focused ? "startDate" : null);
                    }}
                    placeholder="Ida*"
                    range={false}
                    renderCalendarInfo={undefined}
                  />
                ) : (
                  <DatePicker
                    allowSameDay
                    anchorDirection="right"
                    customArrowIcon={
                      <div css={styles.center.right.control.dates.arrow.root}>
                        <span
                          css={styles.center.right.control.dates.arrow.divisor}
                        >
                          |
                        </span>
                        <Icon size={16} use="calendar" />
                      </div>
                    }
                    endDate={form.watch("returnDate")}
                    endDatePlaceholderText="Volta*"
                    focusedInput={rangeDateFocus}
                    onDatesChange={(date) => {
                      form.setValue("departureDate", date.startDate);

                      if (date.startDate) {
                        form.clearErrors("departureDate");
                      }

                      form.setValue("returnDate", date.endDate);

                      if (date.endDate) {
                        form.clearErrors("returnDate");
                      }
                    }}
                    onFocusChange={(focused) => {
                      setRangeFocus(focused);
                    }}
                    range
                    renderCalendarInfo={undefined}
                    startDate={form.watch("departureDate")}
                    startDatePlaceholderText="Ida*"
                  />
                )}
              </div>
            </div>
            <InputErrorMessage css={styles.center.control.error}>
              {form.errors["departureDate"]?.message ||
                form.errors["returnDate"]?.message}
            </InputErrorMessage>
          </div>
          <Button
            css={styles.button}
            onClick={() => {
              setHasTriedToSubmit(true);
            }}
            variant="pink"
          >
            <Icon use="search-outline" />
          </Button>
        </div>
      </div>
    </Form>
  );
};
