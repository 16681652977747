import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Input } from "@shared/inputs";
import { MEDIA_QUERIES } from "@constants";
import { theme } from "smartrips-skin";
import { darken } from "polished";
import { css } from "emotion";
import cx from "classnames";

const styles = {
  inputContainer: css({
    width: "50%",

    [MEDIA_QUERIES.tabletBreakpoint]: {
      width: "100%"
    },

    [".MuiInputBase-root"]: {
      height: 58,
      padding: 16
    },

    [".MuiSelect-root"]: {
      padding: "0 24px 0 0 !important",

      ["&:focus"]: {
        backgroundColor: "#FFF"
      }
    },

    [".MuiOutlinedInput-notchedOutline"]: {
      paddingLeft: "0px !important",

      ["&::after"]: {
        top: "calc(100% - 1px)"
      }
    },

    [".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"]: {
      borderColor: darken(0.2, theme.colors.border)
    }
  })
};

const TOTAL_GUESTS = [1, 2, 3, 4, 5, 6];

const GuestNumberSelect = ({ value, handleChange, containerClasses }) => (
  <Input
    id="totalGuests"
    name="totalGuests"
    select={true}
    value={value}
    onChange={handleChange}
    underlineHighlight={true}
    containerClasses={cx(styles.inputContainer, containerClasses)}
  >
    {TOTAL_GUESTS.map(guestNumber => (
      <MenuItem key={guestNumber} value={guestNumber}>
        {guestNumber} Pessoa
        {guestNumber > 1 ? "s" : ""}
      </MenuItem>
    ))}
  </Input>
);

GuestNumberSelect.defaultProps = {
  containerClasses: ""
};

export { GuestNumberSelect };
