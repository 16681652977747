import React from "react";
import { useSlate } from "slate-react";

import { Tooltip } from "../../tooltip/tooltip";
import {
  isBlockButtonActive,
  isMarkButtonActive,
  TEXT_ALIGN_TYPES,
  toggleBlock,
  toggleMark,
  isLinkButtonActive,
  insertLink,
  unwrapLink,
} from "../rich-text-editor.helper";
import { ToolbarButtonProps } from "../rich-text-editor.types";
import { styles } from "./styles";

export const AddLinkButton: React.FC<ToolbarButtonProps> = ({
  icon,
  title,
}) => {
  const editor = useSlate();

  return (
    <Tooltip arrow content={title} position="top">
      <button
        css={styles.button({ isActive: isLinkButtonActive(editor) })}
        onClick={(event) => {
          event.preventDefault();

          const url = window.prompt("Insira a URL");

          if (!url) {
            return;
          }

          insertLink(editor, url);
        }}
        type="button"
      >
        {icon}
      </button>
    </Tooltip>
  );
};

export const BlockButton: React.FC<ToolbarButtonProps> = ({
  format,
  icon,
  title,
}) => {
  const editor = useSlate();

  if (!format) {
    return null;
  }

  return (
    <Tooltip arrow content={title} position="top">
      <button
        css={styles.button({
          isActive: isBlockButtonActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
          ),
        })}
        onClick={(event) => {
          event.preventDefault();

          toggleBlock(editor, format);
        }}
        type="button"
      >
        {icon}
      </button>
    </Tooltip>
  );
};

export const Element: React.FC<any> = ({ attributes, children, element }) => {
  const { align, type } = element;

  switch (type) {
    case "block-quote":
      return (
        <blockquote css={styles.element({ align })} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul css={styles.bulleted({ align })} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 css={styles.element({ align })} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 css={styles.element({ align })} {...attributes}>
          {children}
        </h2>
      );
    case "link":
      return (
        <LinkComponent
          element={element}
          css={styles.element({ align })}
          {...attributes}
        >
          {children}
        </LinkComponent>
      );
    case "list-item":
      return (
        <li css={styles.element({ align })} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol css={styles.numbered({ align })} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p css={styles.element({ align })} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf: React.FC<any> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const LinkComponent = React.forwardRef<HTMLAnchorElement, any>(
  ({ attributes, children, element }, ref) => {
    return (
      <a
        {...attributes}
        href={element.url}
        ref={ref}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  },
);

LinkComponent.displayName = "LinkComponent";

export const MarkButton: React.FC<ToolbarButtonProps> = (props) => {
  const { format, icon, title } = props;

  const editor = useSlate();

  if (!format) {
    return null;
  }

  return (
    <Tooltip arrow content={title} position="top">
      <button
        css={styles.button({ isActive: isMarkButtonActive(editor, format) })}
        onClick={(event) => {
          event.preventDefault();

          toggleMark(editor, format);
        }}
        type="button"
      >
        {icon}
      </button>
    </Tooltip>
  );
};

export const RemoveLinkButton: React.FC<ToolbarButtonProps> = (props) => {
  const { icon, title } = props;

  const editor = useSlate();

  return (
    <Tooltip arrow content={title} position="top">
      <button
        css={styles.button({ isActive: isLinkButtonActive(editor) })}
        onClick={() => {
          if (!isLinkButtonActive(editor)) {
            return;
          }

          unwrapLink(editor);
        }}
        type="button"
      >
        {icon}
      </button>
    </Tooltip>
  );
};

export const Toolbar: React.FC = ({ children }) => {
  return (
    <ul css={styles.toolbar}>
      {React.Children.map(children, (child) => child)}
    </ul>
  );
};
