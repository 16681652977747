import * as userApi from "~/apps/corporate/apis/user.api";
import * as userParser from "~/apps/corporate/parsers/user.parser";
import errorTypes, { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { USER_ERRORS } from "sm-types/errors";

import {
  EditUserProfileDto,
  EditUserProfileRequestBody,
  LoginDto,
} from "../dtos/user.dto";
import { User, UserModel } from "../models/user.model";

export const editUser = async (data: User) => {
  const result: { data?: User; error?: Error } = {};

  try {
    const editUserRequestBody = userParser.parseEditUserRequest(data);

    result.data = await userApi.editUser(editUserRequestBody, data.userToken);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400) {
        if (data.type === errorTypes.ERROR_INVALID_INPUT) {
          result.error = {
            description: "Preenchimento inválido",
            title: "title",
          };
        }

        if (data.message === '"email" must be a valid email') {
          result.error = {
            description: "Email inválido",
            title: "Dados inválidos",
          };
        }
      }

      if (status === 403) {
        result.error = {
          description:
            "Você não possui permissão o suficiente para realizar essa ação",
          title: "Erro",
        };
      }

      if (status === 409) {
        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_ACTIVE_USER) {
          result.error = {
            description: "Já existe um usuário ativo cadastrado com este email",
            title: "title",
          };
        }

        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_INACTIVE_USER) {
          result.error = {
            description:
              "Já existe um usuário inativo cadastrado com este email. Reative o usuário caso queira utilizá-lo",
            title: "title",
          };
        }
      }

      result.error = { description: "Erro Inesperado", title: "title" };
    } else if (error.request) {
      result.error = {
        description: "Problemas de conectividade",
        title: "Erro",
      };
    } else {
      result.error = { description: "Erro Inesperado", title: "title" };
    }
  }

  return result;
};

export const editUserProfile = async (
  data: EditUserProfileDto,
  userToken: string,
) => {
  const result: { data?: User; error?: Error } = {};

  try {
    const editUserProfileRequestBody: EditUserProfileRequestBody = {
      ...(data.billingProfileToken && {
        billing_profile_token: data.billingProfileToken,
      }),
      first_name: data.firstName,
      last_name: data.lastName,
      ...(data.phone && {
        phone: data.phone,
      }),
      ...(data.sendSms && {
        send_sms: data.sendSms,
      }),
    };

    result.data = await userApi.editUserProfile(
      editUserProfileRequestBody,
      userToken,
    );
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const login = async (data: LoginDto) => {
  const result: { data?: UserModel; error?: Error } = {};

  try {
    result.data = await userApi.login(data.email, data.password);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const logout = async () => {
  const result: { error?: Error } = {};

  try {
    await userApi.logout();
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const trackContextOfChoiceDialogView = async (userToken: string) => {
  try {
    await userApi.trackContextOfChoiceDialogView(userToken);
  } catch (error) {
    return;
  }
};
