import { api } from "~/apps/shared/services/api";

import {
  CreateCorporateCardRequestBody,
  CreateCorporateCardResponse,
  DeleteCardResponse,
  EditCardRequestBody,
  EditCardResponse,
  GetUserCardsResponse,
} from "../dtos/cards.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import * as cardsParser from "../parsers/cards.parser";

export async function createCorporateIndividualCard(
  data: CreateCorporateCardRequestBody,
) {
  return api
    .request<CreateCorporateCardResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/corporate-cards/individual",
    })
    .then(({ data }) => cardsParser.parseCreateCorporateCard(data));
}

export async function createCorporatePublicCard(
  data: CreateCorporateCardRequestBody,
) {
  return api
    .request<CreateCorporateCardResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/corporate-cards/public",
    })
    .then(({ data }) => cardsParser.parseCreateCorporateCard(data));
}

export async function deleteCard(cardToken: string) {
  return api
    .request<DeleteCardResponse>({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/cards/${cardToken}`,
    })
    .then(({ data }) => data);
}

export async function editCard(cardToken: string, data: EditCardRequestBody) {
  return api
    .request<EditCardResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/cards/${cardToken}`,
    })
    .then(({ data }) => data);
}

export async function getUserCards(userToken: string) {
  return await api
    .request<GetUserCardsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/${userToken}/card`,
    })
    .then(({ data }) => cardsParser.parseUserCards(data));
}
