import React from "react";
import { Box, Flex } from "@toolkit";
import { ServicesChart } from "./ServicesChart";
import Utils from "@helpers";
import {
  EXPENSE_CHARTS,
  EXPENSES_CATEGORIES_TRANSLATION,
  COLOR_BY_EXPENSE_CATEGORY
} from "@constants";
import {
  GeneralService,
  GeneralTransactedChartRow
} from "@models/analytics.model";

interface Props {
  generalServices: GeneralService[];
  chartData: GeneralTransactedChartRow[];
}

const SpentThroughtTime = (props: Props) => {
  const { generalServices, chartData } = props;
  const hasSomeDataToShow = generalServices.some(service => !!service.total);

  generalServices.sort((a, b) => {
    if (a.total > b.total) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <div>
      <Flex flex={1} flexDirection="column">
        {hasSomeDataToShow ? (
          <ServicesChart data={chartData} expenseTypes={EXPENSE_CHARTS} />
        ) : (
          <Flex alignItems="center" justifyContent="center" height={330}>
            <p>Nenhum dado a ser mostrado</p>
          </Flex>
        )}
      </Flex>
      <Flex pt={24}>
        <Flex flexWrap="wrap" justifyContent="center" flex={1}>
          {generalServices.map(item => {
            if (!item.total) {
              return null;
            }

            return (
              <Flex key={item.type} mx={12} mb="medium" alignItems="center">
                <Box
                  size=".75rem"
                  backgroundColor={COLOR_BY_EXPENSE_CATEGORY[item.type]}
                  marginRight=".75rem"
                />
                <Box color="#678094" fontSize={0}>
                  <p style={{ fontWeight: "bold", paddingBottom: ".35rem" }}>
                    {EXPENSES_CATEGORIES_TRANSLATION[item.type]}
                  </p>
                  <p>{Utils.toCurrency(item.total)}</p>
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </div>
  );
};

export { SpentThroughtTime };
