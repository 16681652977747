import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import moment from "moment";

import { PeriodRange } from "../../analytics.types";
import * as reportDownloadService from "./analytics-report-download.service";
import { ReportType } from "./analytics-report-download.types";

const today = moment();

interface Actions {
  handleDownloadReport: (reportType: ReportType) => Promise<void>;
  onPeriodFilterClose: () => void;
  onPeriodFilterOpen: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPeriodRangeApply: (periodRange: PeriodRange) => void;
}

type State = {
  dateFilters: {
    anchorEl: any;
    endMonth: number;
    endYear: number;
    isFilterSelected: boolean;
    startMonth: number;
    startYear: number;
  };
};

const initialState: State = {
  dateFilters: {
    anchorEl: null,
    isFilterSelected: true,
    startMonth: 1,
    startYear: today.year(),
    endMonth: 12,
    endYear: today.year(),
  },
};

type ContextProps = Actions & State;

const ReportDownloadContext = createContext<ContextProps>({
  ...initialState,
  handleDownloadReport: async () => {
    return;
  },
  onPeriodFilterClose: () => null,
  onPeriodFilterOpen: () => null,
  onPeriodRangeApply: () => null,
});

export const ReportDownloadProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const onPeriodFilterOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const anchorEl = e.target;

      setState((prev) => ({
        ...prev,
        dateFilters: {
          ...prev.dateFilters,
          anchorEl,
        },
      }));
    },
    [],
  );

  const onPeriodFilterClose = useCallback(() => {
    setState((prev) => ({
      ...prev,
      dateFilters: {
        ...prev.dateFilters,
        anchorEl: null,
      },
    }));
  }, []);

  const onPeriodRangeApply = useCallback((periodRange: PeriodRange) => {
    setState((prev) => ({
      ...prev,
      dateFilters: {
        ...prev.dateFilters,
        anchorEl: null,
        endMonth: periodRange.endSelectedMonth + 1,
        endYear: periodRange.endSelectedYear,
        startMonth: periodRange.startSelectedMonth + 1,
        startYear: periodRange.startSelectedYear,
      },
    }));
  }, []);

  const handleDownloadReport = async (type: ReportType) => {
    const { dateFilters } = state;

    const dateParams = {
      endMonth: dateFilters.endMonth,
      endYear: dateFilters.endYear,
      startMonth: dateFilters.startMonth,
      startYear: dateFilters.startYear,
    };

    const reportDownloadMethod = reportDownloadService.getReportDownloadMethod(
      type,
    );

    if (!reportDownloadMethod) {
      showSnackMessage(
        "Não foi possível encontrar o tipo de download desejado",
        ALERT_TYPES.ERROR,
      );

      return;
    }

    const reportDownloadMethodResponse = await reportDownloadMethod(dateParams);

    if (reportDownloadMethodResponse.error) {
      const error = reportDownloadMethodResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }
  };

  return (
    <ReportDownloadContext.Provider
      value={{
        ...state,
        handleDownloadReport,
        onPeriodFilterClose,
        onPeriodFilterOpen,
        onPeriodRangeApply,
      }}
    >
      {children}
    </ReportDownloadContext.Provider>
  );
};

export const useReportDownload = useContextFactory(
  "ReportDownloadContext",
  ReportDownloadContext,
);
