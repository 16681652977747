import React from "react";

import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { formatCurrency } from "~/apps/shared/utils/format-currency";
import { theme } from "smartrips-skin/lib/v2";

import { BudgetActionType, TimeframeType } from "@models/budget.model";

import {
  BudgetActionTypeTranslation,
  TimeframeTypeTranslation,
} from "../budgets.utils";
import { useNewBudgetsContext } from "../newBudgets.context";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "0.45rem",
    width: "300px",
    minHeight: "11rem",
    padding: "0.75rem 1rem",
    border: `2px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.5rem",
    position: "relative",
  }),
  text: {
    mainToken: css({
      fontWeight: 600,
      fontSize: "0.75rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),

    subText: css({
      fontSize: "0.75rem",
      color: theme.colors.gray[300],
    }),
    subMainText: css({
      fontWeight: 600,
      fontSize: "0.75rem",
      color: theme.colors.pink[500],
    }),
  },
  sub: {
    container: css({
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
    }),
    iconsMainContainer: css({
      display: "flex",
      justifyContent: "flex-end",
      gap: "0.5rem",
      alignSelf: "flex-end",
      position: "absolute",
      bottom: "1",
    }),
    iconsContainer: css({
      cursor: "pointer",
    }),
  },
};

export type BudgetsCardsProps = {
  token: string;
  costCentersName?: string;
  billingProfileName?: string;
  timeframe: TimeframeType;
  year: number;
  month?: string;
  semester?: number;
  trimester?: number;
  action: BudgetActionType;
  value: number;
};

const BudgetCard = (props: BudgetsCardsProps) => {
  const {
    handleBudgetAlertDialog,
    handleSelectedBudgetToDelete,
    handleOpenForm,
  } = useNewBudgetsContext();

  return (
    <div css={styles.root}>
      {props.costCentersName ? (
        <h1 css={styles.text.mainToken}>{props.costCentersName}</h1>
      ) : (
        <h1 css={styles.text.mainToken}>{props.billingProfileName}</h1>
      )}
      <hr></hr>
      {props.costCentersName && (
        <div css={styles.sub.container}>
          <p css={styles.text.subMainText}>Tipo de Alvo: </p>
          <p css={styles.text.subText}>Centro de Custo</p>
        </div>
      )}
      {props.billingProfileName && (
        <div css={styles.sub.container}>
          <p css={styles.text.subMainText}>Tipo de Alvo: </p>
          <p css={styles.text.subText}>CNPJ</p>
        </div>
      )}
      <div css={styles.sub.container}>
        <p css={styles.text.subMainText}>Ano:</p>
        <p css={styles.text.subText}>{props.year}</p>
      </div>
      <div css={styles.sub.container}>
        <p css={styles.text.subMainText}>Período:</p>
        <p css={styles.text.subText}>
          {TimeframeTypeTranslation[props.timeframe]}
        </p>
      </div>
      {props.month && (
        <div css={styles.sub.container}>
          <p css={styles.text.subMainText}>Mês: </p>
          <p css={styles.text.subText}>{props.month}</p>
        </div>
      )}
      {props.semester && (
        <div css={styles.sub.container}>
          <p css={styles.text.subMainText}>Semestre: </p>
          <p css={styles.text.subText}>{props.semester}º</p>
        </div>
      )}
      {props.trimester && (
        <div css={styles.sub.container}>
          <p css={styles.text.subMainText}>Trimestre: </p>
          <p css={styles.text.subText}>{props.trimester}º</p>
        </div>
      )}
      <div css={styles.sub.container}>
        <p css={styles.text.subMainText}>Notificar: </p>
        <p css={styles.text.subText}>
          {BudgetActionTypeTranslation[props.action]}
        </p>
      </div>
      <div css={styles.sub.container}>
        <p css={styles.text.subMainText}>Valor do orçamento</p>
        <p css={styles.text.subText}>{formatCurrency(props.value)}</p>
      </div>
      <div css={styles.sub.iconsMainContainer}>
        <div css={styles.sub.iconsContainer}>
          <Tooltip content="Exluir" position="top">
            <Icon
              onClick={() => {
                handleBudgetAlertDialog(true);
                handleSelectedBudgetToDelete(props.token);
              }}
              size={16}
              use="trash"
            />
          </Tooltip>
        </div>
        <div css={styles.sub.iconsContainer}>
          <Tooltip content="Editar" position="top">
            <Icon
              onClick={() => handleOpenForm(props.token)}
              size={16}
              use="cogwheel-filled"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default BudgetCard;
