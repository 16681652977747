import { api } from "~/apps/shared/services/api";

import {
  GetItineraryDocumentPendenciesResponse,
  GetItineraryInfoResponse,
  GetItineraryPendenciesResponse,
  GetItineraryServiceInfoResponse,
  GetItineraryServicesResponse,
} from "../dtos/itinerary.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import * as itineraryParser from "../parsers/itinerary.parser";

export async function getItineraryInfo(travelToken: string) {
  return await api
    .request<GetItineraryInfoResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/itinerary/info`,
    })
    .then(({ data }) => itineraryParser.parseItineraryInfo(data));
}

export async function getItineraryDocumentPendencies(travelToken: string) {
  return await api
    .request<GetItineraryDocumentPendenciesResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/document-pendencies`,
    })
    .then(({ data }) => itineraryParser.parseItineraryDocumentPendencies(data));
}

export async function getItineraryPendencies(travelToken: string) {
  return await api
    .request<GetItineraryPendenciesResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/itinerary/pendencies`,
    })
    .then(({ data }) => itineraryParser.parseItineraryPendencies(data));
}

export async function getItineraryServiceInfo(offerToken: string) {
  return await api
    .request<GetItineraryServiceInfoResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/order-info`,
    })
    .then(({ data }) => itineraryParser.parseItineraryServiceInfo(data));
}

export async function getItineraryServices(travelToken: string) {
  return await api
    .request<GetItineraryServicesResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/itinerary/offers`,
    })
    .then(({ data }) => itineraryParser.parseItineraryServices(data));
}
