import { parseAdvanceExpenseApprover } from "~/parsers/advanced-expense.parser";
import { parsePendingExpenseAdvanceItem } from "~/parsers/expense-advance-approvals.parser";

import {
  getLoggedUserToken,
  getAuthorizationHeader
} from "@helpers/user.helper";

import { AdvancedExpenseApprovers } from "@dtos/advanced-expense.dto";
import { PendingExpenseAdvanceItem } from "@dtos/expense-advance-approval.dto";

import { api } from "./interceptors";

export function getApproverExpenseAdvances(statusList: number | number[]) {
  return api
    .request<PendingExpenseAdvanceItem[]>({
      url: `/booking/approvers/${getLoggedUserToken()}/expenses/advances`,
      method: "GET",
      headers: getAuthorizationHeader(),
      params: {
        reqStatusList: statusList
      }
    })
    .then(response =>
      response.data.map(item => parsePendingExpenseAdvanceItem(item))
    );
}

export function approveExpenseAdvanceRequest(
  expenseAdvanceToken: string,
  requestData: { approved_value: number }
) {
  return api
    .request({
      url: `/booking/expenses/advances/${expenseAdvanceToken}/approve`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then(response => response.data);
}

export function denyExpenseAdvanceRequest(
  expenseAdvanceToken: string,
  requestData: { message?: string }
) {
  return api
    .request({
      url: `/booking/expenses/advances/${expenseAdvanceToken}/deny`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then(response => response.data);
}

export function getExpenseAdvanceApprovers(expenseAdvanceToken: string) {
  return api
    .request<AdvancedExpenseApprovers[]>({
      url: `/booking/expenses/advances/${expenseAdvanceToken}/approvers`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response =>
      response.data.map(approver => parseAdvanceExpenseApprover(approver))
    );
}
