import * as expensesApi from "@apis/expense.api";
import clientApi from "@apis/client.api";
import { ExpenseReportList } from "@models/expense.model";
import { CustomError } from "~/types";
import { ERROR } from "@constants";
import { UserModel } from "@models/user.model";

interface SignalPaymentItem {
  reportToken: string;
  error: boolean;
}

export async function getExpenseReportsList(
  page: number,
  status: string,
  search?: string
): Promise<{ data?: ExpenseReportList; error?: CustomError }> {
  const result: { data?: ExpenseReportList; error?: CustomError } = {};

  try {
    result.data = await expensesApi.listApproverReportsList(
      page,
      status,
      search
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    }
  }

  return result;
}

export async function getUserList(): Promise<{
  data?: UserModel[];
  error?: CustomError;
}> {
  const result: { data?: UserModel[]; error?: CustomError } = {};

  try {
    result.data = await clientApi.getUsersRequest();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_USER_LIST_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_USER_LIST_FETCH_ERROR;
    }
  }

  return result;
}

export async function signalMultipleReportPayments(
  expenseReportTokens: string[]
): Promise<{ data?: SignalPaymentItem[]; error?: CustomError }> {
  const result: { data?: SignalPaymentItem[]; error?: CustomError } = {};

  try {
    result.data = await expensesApi.signalMultipleReportPayments({
      expense_report_tokens: expenseReportTokens
    });
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SIGNAL_MULTIPLE_PAYMENTS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SIGNAL_MULTIPLE_PAYMENTS_ERROR;
    }
  }

  return result;
}
