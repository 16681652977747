import React from "react";
import Mask from "react-text-mask";

import { Input } from "../input/input";
import { getMask } from "./input-masked.helper";
import { MaskType } from "./input-masked.types";

type Props = Omit<React.ComponentProps<typeof Mask>, "mask" | "maskType"> & {
  guide?: boolean;
  value?: string;
} & (
    | {
        mask?: undefined;
        maskType: MaskType;
      }
    | {
        mask: (RegExp | string)[] | false;
        maskType?: undefined;
      }
  );

export const InputMasked: React.FC<Props> = ({
  className,
  guide,
  mask,
  maskType,
  value,
  ...props
}) => {
  return (
    <Mask
      guide={guide}
      mask={mask ? mask : maskType ? getMask({ maskType, value }) : false}
      placeholderChar={"\u2000"}
      render={(ref, props) => {
        return (
          <Input
            className={className}
            ref={ref as (_: HTMLInputElement) => void}
            {...props}
          />
        );
      }}
      type="numeric"
      value={value}
      {...props}
    />
  );
};
