import React, { useContext, useEffect, useState } from "react";
import { ExpenseFormProps } from "../ExpenseDrawer.types";
import { LocationAutocomplete } from "../../../shared-logic/LocationAutocomplete/LocationAutocomplete";
import { css } from "emotion";
import { ExpenseDrawerContext } from "../ExpenseDrawer.context";
import { DateInput } from "../../shared/DateInput/DateInput";
import moment, { Moment } from "moment";
import * as utils from "../../../../helpers";
import * as expensesHelper from "../../../../helpers/expense.helper";
import { defaultTheme } from "../../../../assets/styles/theme";
import InputError from "../../../shared/inputs/InputError";
import { DirectionsMap } from "../DirectionsMap";
import isEqual from "lodash/isEqual";

const styles = {
  root: css({
    width: "100%"
  }),
  inputContainer: css({
    paddingBottom: "1.5rem"
  }),
  autocompletePaper: css({
    display: "block"
  }),
  distanceRow: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  label: css({
    fontSize: 14,
    color: defaultTheme.textColor,
    fontWeight: "bold",
    paddingBottom: ".5rem"
  }),
  value: css({
    fontSize: 18
  }),
  errorMessage: css({
    textAlign: "center",
    margin: 0,
    paddingTop: ".5rem"
  }),
  mapContainer: css({
    marginBottom: "1.5rem"
  })
};

const KilometerExpenseForm = ({
  values,
  errors,
  touched,
  loading,
  readOnly,
  handleBlur,
  setFieldValue,
  setFieldTouched
}: ExpenseFormProps) => {
  const [stashedLocation, setStashedLocation] = useState({
    stashedOrigin: values.originLocation,
    stashedDestination: values.destinationLocation
  });

  const { calculateRouteDistanceAndValue } = useContext(ExpenseDrawerContext);

  const handleLocationChange = (
    type: "originLocation" | "destinationLocation"
  ) => (address: any) => {
    setFieldValue(type, {
      search: address ? address.formattedAddress : "",
      latitude: address ? address.latitude() : null,
      longitude: address ? address.longitude() : null
    });
  };

  const originLocationSearch =
    values.originLocation && values.originLocation.search
      ? values.originLocation.search
      : "";

  const destinationLocationSearch =
    values.destinationLocation && values.destinationLocation.search
      ? values.destinationLocation.search
      : "";

  const handleChangeDistanceAndValue = (data: {
    distance: number;
    value: number;
    routeDirections: string;
  }) => {
    setFieldValue("distance", data.distance);
    setFieldValue("value", data.value);
    setFieldValue("routeDirections", data.routeDirections);
    setFieldTouched("distance")();
    setFieldTouched("value")();
    setFieldTouched("routeDirections")();
    setStashedLocation({
      stashedOrigin: values.originLocation,
      stashedDestination: values.destinationLocation
    });
  };

  const handleChangeDate = (date: Moment | null) => {
    setFieldValue("date", date);
  };

  const dateOfBuyValidation = (day: Moment) => {
    return day.isSameOrAfter(moment());
  };

  const shouldDistanceUpdate = (
    currentOrigin: any,
    currentDestination: any
  ) => {
    const { stashedOrigin, stashedDestination } = stashedLocation;

    if (
      !isEqual(stashedOrigin, currentOrigin) ||
      !isEqual(stashedDestination, currentDestination)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(
    () => {
      const { originLocation, destinationLocation } = values;
      const shouldUpdateDistance = shouldDistanceUpdate(
        originLocation,
        destinationLocation
      );

      if (!shouldUpdateDistance) {
        return;
      }

      const isOriginFilled = Boolean(
        originLocation && originLocation.latitude && originLocation.longitude
      );
      const isDestinationFilled = Boolean(
        destinationLocation &&
          destinationLocation.latitude &&
          destinationLocation.longitude
      );

      if (isOriginFilled && isDestinationFilled) {
        calculateRouteDistanceAndValue(values, handleChangeDistanceAndValue);
      } else {
        setFieldValue("distance", 0);
        setFieldValue("value", 0);
        setFieldValue("routeDirections", null);
        setStashedLocation({
          stashedOrigin: null,
          stashedDestination: null
        });
      }
    },
    [values.originLocation, values.destinationLocation]
  );

  const origin = expensesHelper.extractCoordinatesArray(values.originLocation);

  const destination = expensesHelper.extractCoordinatesArray(
    values.destinationLocation
  );

  return (
    <div className={styles.root}>
      <DateInput
        id="date"
        date={values.date}
        handleDateChange={handleChangeDate}
        placeholder="Data da Viagem"
        rootStyle={{ marginBottom: "1.5rem" }}
        error={Boolean(errors.date && touched.date)}
        errorMessage={errors.date}
        setTouched={setFieldTouched("date")}
        disabled={loading || readOnly}
        isOutsideRange={dateOfBuyValidation}
      />
      <LocationAutocomplete
        handleSelect={handleLocationChange("originLocation")}
        handleBlur={handleBlur}
        inputProps={{
          id: "originLocation",
          name: "originLocation",
          label: "Origem",
          disabled: loading || readOnly,
          containerClasses: styles.inputContainer,
          error: errors.originLocation && touched.originLocation,
          errorMessage:
            errors.originLocation &&
            touched.originLocation &&
            errors.originLocation
        }}
        classes={{ paper: styles.autocompletePaper }}
        value={originLocationSearch}
      />
      <LocationAutocomplete
        handleSelect={handleLocationChange("destinationLocation")}
        handleBlur={handleBlur}
        inputProps={{
          id: "destinationLocation",
          name: "destinationLocation",
          label: "Destino",
          disabled: loading || readOnly,
          containerClasses: styles.inputContainer,
          error: errors.destinationLocation && touched.destinationLocation,
          errorMessage:
            errors.destinationLocation &&
            touched.destinationLocation &&
            errors.destinationLocation
        }}
        classes={{ paper: styles.autocompletePaper }}
        value={destinationLocationSearch}
      />
      <div style={{ marginBottom: "1.5rem" }}>
        <div className={styles.distanceRow}>
          <div>
            <div className={styles.label}>
              <span>Distância(Km)</span>
            </div>
            <div className={styles.value}>
              <span>{values.distance} km</span>
            </div>
          </div>
          <div>
            <div className={styles.label}>
              <span>Valor</span>
            </div>
            <div className={styles.value}>
              <span>{utils.toCurrency(values.value)}</span>
            </div>
          </div>
        </div>
        {errors.distance && touched.distance ? (
          <InputError className={styles.errorMessage}>
            {errors.distance}
          </InputError>
        ) : null}
      </div>
      {!readOnly ? (
        <DirectionsMap
          origin={origin}
          destination={destination}
          defaultZoom={2}
          containerClasses={styles.mapContainer}
        />
      ) : null}
    </div>
  );
};

export { KilometerExpenseForm };
