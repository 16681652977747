import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Box, Text } from "@toolkit";
import { css } from "@styled-system/css";
import { theme } from "smartrips-skin";
import { transparentize } from "polished";
import InputError from "@components/shared/inputs/InputError";

const styles = {
  buttonGroup: css({
    [".MuiButtonGroup-grouped"]: {
      minWidth: [150]
    },
    [".MuiButton-outlinedPrimary"]: {
      color: "primary",
      borderColor: transparentize(0.5, theme.colors.primary),
      [":hover"]: {
        borderColor: "primary"
      }
    },
    [".MuiButton-outlinedPrimary.checked"]: {
      color: "white",
      backgroundColor: "primary",
      [":hover"]: {
        backgroundColor: "primary"
      }
    },
    [".MuiButton-label"]: {
      fontFamily: "'Open sans', sans-serif"
    }
  })
};

const isChecked = (value, selectedValue) =>
  typeof selectedValue === "boolean" && value === selectedValue;

const SolvedInput = props => {
  const { checked, error, onClick } = props;

  return (
    <Box>
      <Box mb="large">
        <Text fontSize={[2]} fontWeight="bold">
          Seu problema foi resolvido?
        </Text>
      </Box>
      <ButtonGroup color="primary" css={styles.buttonGroup}>
        <Button
          onClick={onClick(true)}
          className={isChecked(true, checked) && "checked"}
        >
          Sim
        </Button>
        <Button
          onClick={onClick(false)}
          className={isChecked(false, checked) && "checked"}
        >
          Não
        </Button>
      </ButtonGroup>
      {error && (
        <InputError css={css({ mt: "medium" })}>{error.message}</InputError>
      )}
    </Box>
  );
};

export { SolvedInput };
