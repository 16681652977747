import React from "react";
import CircularSpinner from "../CircularSpinner";
import Button from "@material-ui/core/Button";
import { css } from "emotion";
import { theme } from "smartrips-skin";

const containedStyles = {
  root: css({
    width: "100%",
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "normal",
    lineHeight: "1.4em",
    letterSpacing: "normal",
    padding: ".5rem .5rem",
    minHeight: "35px",
    ["@media (max-width: 767px)"]: {
      minWidth: 100,
      fontSize: 12
    }
  }),
  containedPrimary: css({
    backgroundColor: theme.colors.primary,
    "&:hover": {
      backgroundColor: theme.colors.tertiary
    },
    "&:disabled": {
      color: "rgba(255, 255, 255, 0.9)",
      backgroundColor: "rgba(25, 78, 192, 0.5)",
      cursor: "not-allowed"
    }
  }),
  containedSecondary: css({
    backgroundColor: "#E25141",
    "&:hover": {
      backgroundColor: "#de3927"
    },
    "&:disabled": {
      color: "rgba(255, 255, 255, 0.9)",
      backgroundColor: "rgba(222, 57, 39, 0.5)",
      cursor: "not-allowed"
    }
  }),
  label: css({
    lineHeight: "inherit",
    letterSpacing: "inherit"
  })
};

let StContainedButton = props => {
  const { children, loading, isFullWidth, disabled, ...rest } = props;
  const style = {
    width: isFullWidth ? "100%" : "",
    display: "inline-block",
    position: "relative"
  };

  // cursor: "not-allowed" on containedStyles was not enough to work
  if (disabled) {
    style.cursor = "not-allowed";
  }

  return (
    <div style={style}>
      <Button
        variant="contained"
        classes={containedStyles}
        color="primary"
        disabled={disabled}
        endIcon={loading ? <CircularSpinner size={14} /> : null}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

StContainedButton.defaultProps = {
  isFullWidth: true,
  loading: false
};

export { StContainedButton };
