import { AxiosResponse } from "axios";

import { BudgetData } from "@models/analytics.model";

import {
  CreateDepositDto,
  CreateWithdrawalDto,
  CreateTransferDto,
  CreateExpenditureDto,
  CreateBudgetDto,
  CheckBudgetsRequest,
  SetBudgetsResponseItem
} from "../dtos/budget.dto";
import { isNumber } from "../helpers";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  BudgetListItem,
  Operation,
  BudgetLimitItem,
  BudgetResult,
  DeleteBudgetResult,
  RegisterBudgetDto,
  UpdateBudgetDto
} from "../models/budget.model";
import { api } from "./interceptors";

export function setBudgetRequest(
  dto: CreateBudgetDto
): Promise<SetBudgetsResponseItem[]> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/budgets`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<SetBudgetsResponseItem[]>) => {
      return response.data;
    });
}

export function getBudgetsListRequest(
  clientToken: string,
  month: number,
  year: number
): Promise<BudgetListItem[]> {
  return api
    .request({
      url: `/booking/clients/${clientToken}/budgets?month=${month}&year=${year}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<BudgetListItem[]>) =>
      response.data.map(budget => {
        const availableBalance = budget.plannedValue
          ? budget.plannedValue - budget.totalSpent
          : null;

        return {
          ...budget,
          balance:
            !!budget.original && !isNumber(budget.balance) ? 0 : budget.balance,
          availableBalance:
            budget.plannedValue && isNumber(availableBalance)
              ? availableBalance! < 0
                ? 0
                : availableBalance
              : null
        };
      })
    );
}

export function getCostCenterOperationsRequest(
  costCenterToken: string
): Promise<Operation[]> {
  return api
    .request({
      url: `/booking/cost-centers/${costCenterToken}/operations`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<Operation[]>) => {
      return response.data;
    });
}

export function createDepositRequest(
  dto: CreateDepositDto
): Promise<Operation> {
  return api
    .request({
      url: `/booking/cost-centers/${dto.cost_center_token}/operations`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<Operation>) => {
      return response.data;
    });
}

export function createWithdrawalRequest(
  dto: CreateWithdrawalDto
): Promise<Operation> {
  return api
    .request({
      url: `/booking/cost-centers/${dto.cost_center_token}/operations`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<Operation>) => {
      return response.data;
    });
}

export function createTranferRequest(
  dto: CreateTransferDto
): Promise<Operation> {
  return api
    .request({
      url: `/booking/cost-centers/${dto.cost_center_token}/operations`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<Operation>) => {
      return response.data;
    });
}

export function createExpenditureRequest(
  dto: CreateExpenditureDto
): Promise<Operation> {
  return api
    .request({
      url: `/booking/cost-centers/${dto.cost_center_token}/operations`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<Operation>) => {
      return response.data;
    });
}

export function checkBudgetsLimits(
  dto: CheckBudgetsRequest
): Promise<BudgetLimitItem[]> {
  return api
    .request({
      url: "/booking/budgets/limits",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then((response: AxiosResponse<BudgetLimitItem[]>) => response.data);
}

export function getUserCostCenterBudgets(userToken: string) {
  return api
    .request({
      url: `/booking/budgets/users/${userToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<BudgetData[]>) => data);
}

export function getClientBudgets(clientToken: string) {
  return api
    .request({
      url: `/booking/budgets/${clientToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<BudgetResult[]>) => data);
}

export function deleteBudget(budgetToken: string) {
  return api
    .request({
      url: `/booking/budgets/${budgetToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<DeleteBudgetResult>) => data);
}

export function registerBudget(dto: RegisterBudgetDto) {
  return api
    .request({
      url: `/booking/budgets/`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<BudgetResult>) => data);
}

export function updateBudget(budgetToken: string, dto: UpdateBudgetDto) {
  return api
    .request({
      url: `/booking/budgets/${budgetToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<BudgetResult>) => data);
}
