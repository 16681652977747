import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    name: {
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        width: "100%",
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        lineHeight: "28px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      gap: "24px",
      marginTop: "24px",
      padding: "0 24px",
      width: "100%",
    }),
    section: {
      body: {
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          padding: "16px 24px",
        }),
      },
      header: {
        left: {
          description: css({
            color: theme.colors.gray[500],
            fontSize: "0.75rem",
            lineHeight: "18px",
          }),
          root: css({
            display: "flex",
            flexDirection: "column",
          }),
          title: css({
            color: theme.colors.gray[700],
            fontWeight: 600,
            lineHeight: "24px",
          }),
        },
        right: {
          icon: ({ isCollapsed }: { isCollapsed: boolean }) =>
            css({
              transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
              transition: "transform 0.15s ease-in-out",
            }),
        },
        root: ({ isCollapsed }: { isCollapsed: boolean }) =>
          css({
            alignItems: "center",
            backgroundColor: theme.colors.background.gray,
            borderRadius: isCollapsed ? "8px" : "8px 8px 0 0",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 16px",
            transition: "border-radius 0.15s ease-in-out",
          }),
      },
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }),
    },
    type: {
      national: {
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
        service: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          ":not(:last-child)": {
            borderBottom: `1px solid ${theme.colors.background.gray}`,
            paddingBottom: "24px",
          },
        }),
        title: css({
          fontSize: "1.25rem",
          fontWeight: 600,
          lineHeight: "28px",
        }),
      },
    },
  },
  footer: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "40px 24px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between",
    width: "768px",
  }),
};
