import React from "react";
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps
} from "@material-ui/core/RadioGroup";

import RadioGroupItem from "./RadioGroupItem";

export type RadioGroupProps = MuiRadioGroupProps;
function RadioGroup(props: RadioGroupProps) {
  const { value, onChange, id, name, children, ...rest } = props;

  return (
    <MuiRadioGroup
      id={id}
      name={name}
      aria-label={name}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {children}
    </MuiRadioGroup>
  );
}

RadioGroup.Item = RadioGroupItem;

export default RadioGroup;
