import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  container: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    color: theme.colors.gray[500],
    cursor: "pointer",
    display: "flex",
    fontFamily: theme.fonts.inter,
    gap: "8px",
    padding: "12px 16px",
    transition: "all 0.15s ease-in-out",
    ":hover": {
      color: theme.colors.gray[700],
    },
    ":disabled": {
      cursor: "default",
      opacity: 0.75,
    },
  }),
  count: css({
    alignItems: "center",
    backgroundColor: theme.colors.pink[500],
    borderRadius: "12px",
    color: theme.colors.white,
    display: "flex",
    fontSize: "0.875rem",
    fontWeight: 500,
    height: "24px",
    justifyContent: "center",
    lineHeight: "16px",
    width: "24px",
  }),
  icon: {
    icon: css({
      color: theme.colors.gray[300],
    }),
    root: css({
      display: "grid",
      height: "24px",
      placeItems: "center",
      width: "24px",
    }),
  },
  label: css({
    whiteSpace: "nowrap",
  }),
  options: {
    option: {
      button: ({ selected }: { selected: boolean }) =>
        css({
          alignItems: "center",
          backgroundColor: "transparent",
          border: "none",
          color: selected ? theme.colors.gray[700] : theme.colors.gray[300],
          display: "flex",
          fontSize: "0.875rem",
          justifyContent: "space-between",
          padding: "12px 16px",
          transition: "all 0.15s ease-in-out",
          width: "100%",
          ["&:disabled"]: {
            opacity: 0.5,
            pointerEvents: "none",
          },
          ["&:not(:disabled)"]: {
            cursor: "pointer",
          },
          ["&:hover"]: {
            backgroundColor: theme.colors.background.gray,
            color: theme.colors.gray[700],
          },
        }),
      check: css({
        marginLeft: "8px",
      }),
      label: css({
        whiteSpace: "nowrap",
      }),
      root: css({
        overflow: "hidden",
        ["&:first-of-type"]: {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
        ["&:last-of-type"]: {
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      }),
    },
    root: ({ anchor }: { anchor?: "left" | "right" }) =>
      css({
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        left: anchor === "left" ? 0 : "unset",
        listStyle: "none",
        margin: 0,
        padding: 0,
        position: "absolute",
        right: anchor === "right" ? 0 : "unset",
        top: "calc(100% + 4px)",
        minWidth: "100%",
        zIndex: 1,
      }),
  },
  root: css({
    position: "relative",
  }),
};
