import React from "react";

import {
  Dialog,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "~/apps/shared/components/dialog/dialog";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

export const PolicyDeletionAlertDialog: React.FC<{
  isLoading: boolean;
  message: string;
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  title: string;
}> = ({ isLoading, message, onClose, onDelete, open, title }) => {
  return (
    <Dialog
      aria-labelledby={title}
      aria-describedby={title}
      onClose={onClose}
      open={open}
    >
      <DialogHeader
        disabled={isLoading}
        icon="trash"
        onClose={onClose}
        variant="pink"
      />
      <DialogContent>
        <DialogTitle variant="pink">{title}</DialogTitle>
        <span css={styles.message}>{message}</span>
      </DialogContent>
      <DialogFooter>
        <Button disabled={isLoading} fill="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={isLoading} onClick={onDelete} variant="pink">
          Excluir
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
