/* eslint-disable */
import React from "react";
import { css } from "@styled-system/css";
import { useTable } from "react-table";
import { defaultTheme } from "~/assets/styles/theme";

const styles = css({
  mb: "xsmall",
  ["table"]: {
    width: "100%",
    ["th"]: {
      fontSize: [0, 1],
      color: defaultTheme.subTextColor,
      fontWeight: "bold"
    },
    ["thead"]: {
      ["th"]: {
        pb: "small",
        pr: ["medium", "large"],
        textAlign: "left",
        [":first-of-type"]: {
          pr: ["medium", "xlarge"]
        },
        [":last-child"]: {
          pr: 0
        }
      }
    },
    ["tbody"]: {
      ["tr"]: {
        fontSize: [0, 1],
        color: defaultTheme.subTextColor
      },
      ["td"]: {
        py: 12,
        pr: "small",
        textAlign: "left",
        [":first-of-type"]: {
          maxWidth: [110, 180, 200],
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        },
        [":last-child"]: {
          pr: 0
        }
      }
    }
  }
});

const StyledTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  return (
    <div css={styles}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { StyledTable };
