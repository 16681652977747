import React from "react";

import CheckIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";
import moment from "moment";

import { Box, Paragraph, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import {
  ADVANCED_EXPENSE_STATUS,
  ADVANCED_EXPENSE_STAGE_TRANSLATION
} from "@constants";

import { AdvancedExpenseApprover } from "@models/advanced-expense.model";

import { useExpenseAdvanceApprovalReviewContext } from "../ExpenseAdvanceApprovalReview.context";

const styles = {
  title: css({
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: "body",
    mb: 24
  }),
  stageTitle: css({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    pb: ".2rem"
  }),
  checkIcon: css({
    color: "success",
    fontSize: 16,
    ml: ".35rem"
  }),
  approvedDetails: css({
    fontSize: 12,
    color: defaultTheme.subTextColor,
    lineHeight: "1rem"
  }),
  declinedDetails: css({
    fontSize: 12,
    color: defaultTheme.subTextColor,
    lineHeight: "1rem"
  }),
  analysisDetails: css({
    fontSize: 12,
    color: "primary",
    lineHeight: "1rem"
  }),
  closeIcon: css({
    color: "cancel",
    fontSize: 16,
    ml: ".35rem"
  }),
  spinner: css({
    color: "primary"
  })
};

type CurrentStage = 1 | 2 | 3 | 4 | 99;

interface StageItemProps {
  currentStage: number | null;
  currentStatus: number | null;
  reply: AdvancedExpenseApprover;
}

const StageItem: React.FC<StageItemProps> = ({
  reply,
  currentStage,
  currentStatus
}) => {
  return (
    <Box mb="medium">
      {currentStatus === ADVANCED_EXPENSE_STATUS.DECLINED &&
      currentStage === reply.stage ? (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[reply.stage]}{" "}
            <CloseIcon css={styles.closeIcon} />
          </Paragraph>
          <Text css={styles.declinedDetails}>
            Negado por {reply.fullName},{" "}
            {moment(reply.repliedAt!).format("ddd, DD [de] MMMM YYYY")}
          </Text>
        </Box>
      ) : (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[reply.stage]}
            <CheckIcon css={styles.checkIcon} />
          </Paragraph>
          <Text css={styles.approvedDetails}>
            {reply.stage === 99 ? "Pago" : "Aprovado"} por {reply.fullName},{" "}
            {moment(reply.repliedAt!).format("ddd, DD [de] MMMM YYYY")}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const AdvancedExpenseStatus = () => {
  const {
    expenseAdvanceApprovers,
    expenseAdvanceRequest
  } = useExpenseAdvanceApprovalReviewContext();

  const approversHistory = expenseAdvanceApprovers
    ? expenseAdvanceApprovers.filter(
        (item: AdvancedExpenseApprover) => item.repliedAt
      )
    : [];

  const currentStage = expenseAdvanceRequest
    ? expenseAdvanceRequest.status === 99
      ? 99
      : expenseAdvanceRequest.currentApprovalStage
    : 1;

  const currentRequestStatus = expenseAdvanceRequest
    ? expenseAdvanceRequest.status
    : null;

  const requestNotFinished =
    expenseAdvanceRequest && !expenseAdvanceRequest.closedAt;

  if (!approversHistory.length) {
    return null;
  }

  return (
    <Box
      display={["none", "block"]}
      mt={["medium", "large"]}
      p={[24]}
      border="1px solid"
      borderColor="border"
    >
      <Paragraph css={styles.title}>Histórico</Paragraph>
      {approversHistory.map((reply: AdvancedExpenseApprover) => (
        <StageItem
          reply={reply}
          currentStage={currentStage}
          currentStatus={currentRequestStatus}
        />
      ))}
      {requestNotFinished ? (
        <Box>
          <Paragraph css={styles.stageTitle}>
            {ADVANCED_EXPENSE_STAGE_TRANSLATION[currentStage as CurrentStage]}
          </Paragraph>
          <Text css={styles.analysisDetails}>Em análise</Text>
        </Box>
      ) : null}
    </Box>
  );
};

export { AdvancedExpenseStatus };
