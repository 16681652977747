import React, { useState, useMemo, MouseEvent } from "react";
import { ActionMeta } from "react-select";

import FormControl from "@material-ui/core/FormControl";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { Option } from "~/types";

import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import { Select } from "@shared/inputs";

interface OptionType {
  label: string;
  value: string;
}

interface State {
  anchorEl: React.ReactNode | null;
  companyAreaToken: string;
  isFilterSelected: boolean;
  wasFilterApplied: boolean;
}

const initialState: State = {
  anchorEl: null,
  companyAreaToken: "",
  isFilterSelected: false,
  wasFilterApplied: false,
};

interface Props {
  containerStyles?: React.CSSProperties;
  onApply: (companyAreaToken: string) => void;
  options?: Array<Option<string>>;
}

const AreaFilter: React.FC<Props> = ({
  containerStyles = {},
  onApply,
  options = [],
}) => {
  const { clientConfig } = useClientConfig();

  const [state, setState] = useState(initialState);

  const areaName = clientConfig && clientConfig.getCompanyAreaDisplay();

  const selectedArea = useMemo(() => {
    return state.companyAreaToken
      ? options.find((area) => area.value === state.companyAreaToken)
      : null;
  }, [state.companyAreaToken, options]);

  const handleApply = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
      isFilterSelected: !!state.companyAreaToken,
      wasFilterApplied: true,
    }));
    onApply(state.companyAreaToken);
  };

  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
      companyAreaToken: "",
      isFilterSelected: false,
      wasFilterApplied: false,
    }));

    onApply("");
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { target } = e;

    setState((prevState) => ({
      ...prevState,
      anchorEl: target,
      isFilterSelected: true,
    }));
  };

  const handleChange = (option: OptionType | null, event: ActionMeta<any>) => {
    setState((prevState) => ({
      ...prevState,
      companyAreaToken: option && option.value ? option.value : "",
      isFilterSelected: true,
      wasFilterApplied: false,
    }));

    if (event.action === "clear") {
      onApply("");
    }
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
      companyAreaToken: state.wasFilterApplied ? state.companyAreaToken : "",
      isFilterSelected: state.wasFilterApplied ? state.wasFilterApplied : false,
    }));
  };

  return (
    <FilterButtonPopover
      anchorEl={state.anchorEl}
      applyEnabled
      clearEnabled
      containerStyle={containerStyles}
      isFilterSelected={state.isFilterSelected}
      isOpen={!!state.anchorEl}
      onApplyClick={handleApply}
      onButtonClick={handleClick}
      onClearClick={handleClear}
      onPopoverClose={handleClose}
      selectedButtonText={
        selectedArea ? selectedArea.label : areaName || "Área"
      }
      showClearButton={!!state.companyAreaToken}
      unselectedButtonText={areaName || "Área"}
    >
      <div>
        <FormControl style={{ width: "240px" }}>
          <div>
            <Select
              onChange={handleChange}
              options={options}
              placeholder="Buscar área"
              value={selectedArea}
            />
          </div>
        </FormControl>
      </div>
    </FilterButtonPopover>
  );
};

export default AreaFilter;
