import { css } from "@styled-system/css";

export const styles = {
  loading: css({
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  }),
  root: css({
    display: "flex",
    gap: "16px",
    margin: "0 auto",
    maxWidth: "1440px",
    padding: "16px 80px 64px 80px",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "16px 24px 8px 24px",
    },
    "@media (max-width: 992px)": {
      padding: "0 16px",
    },
  }),
};
