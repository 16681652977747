type CabinClass = {
  name: string;
  value: string;
};

export const CABIN_CLASSES_MAP: Record<string, CabinClass> = {
  economy: { name: "Econômica", value: "economy" },
  premium_economy: { name: "Econômica Premium", value: "premium_economy" },
  business: { name: "Business", value: "business" },
  first: { name: "Primeira Classe", value: "first" },
} as const;
