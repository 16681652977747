import * as clientApi from "~/apps/corporate/apis/client.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const downloadExternalApiDoc = async () => {
  const result: { error?: Error } = {};

  try {
    await clientApi.downloadExternalApiDoc();
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const getClientExternalCredentials = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await clientApi.getClientExternalCredentials();
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const removeClientExternalCredentials = async () => {
  const result: { error?: Error } = {};

  try {
    await clientApi.removeClientExternalCredentials();
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const resetClientExternalCredentials = async () => {
  const result: { error?: Error } = {};

  try {
    await clientApi.resetClientExternalCredentials();
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};
