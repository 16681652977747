import { TravelTag } from "~/apps/corporate/models/tags.models";

class ItineraryInfoTagsPresenter {
  constructor(
    private readonly travelsTags: Record<string, TravelTag[] | undefined>,
  ) {}

  public getTravelTags(travelToken: string) {
    if (!this.hasTravelTagsRecord()) {
      return null;
    }

    const travelTags = this.travelsTags[travelToken];

    if (!travelTags) {
      return null;
    }

    return travelTags;
  }

  private hasTravelTagsRecord() {
    if (Object.keys(this.travelsTags).length === 0) {
      return false;
    }

    if (
      Object.values(this.travelsTags).every(
        (travelTags) => !travelTags || travelTags.length === 0,
      )
    ) {
      return false;
    }

    return true;
  }
}

export class ItineraryInfoTagsPresenterFactory {
  static create(travelsTags: Record<string, TravelTag[] | undefined>) {
    return new ItineraryInfoTagsPresenter(travelsTags);
  }
}
