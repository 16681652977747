import React, { useEffect } from "react";

import { css } from "@styled-system/css";

import { Box, Flex, Paragraph } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { Input } from "@shared/inputs";
import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";
import styled from "@shared/styled";

import { StContainedButton } from "../shared/buttons";
import {
  HotelBookingConfirmationProvider,
  useHotelBookingConfirmationContext,
  useHotelBookingConfirmationActionsContext
} from "./HotelBookingConfirmation.context";
import { HotelBookingConfirmationDenyDialog } from "./HotelBookingConfirmationDenyDialog";
import { ConfirmedMessage, DeniedMessage } from "./ReponseMessages";
import { RoomSection, TravelersSection } from "./Sections";

const REQUEST_STATUS = {
  UNCHANGED: "UNCHANGED",
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED"
};

const Container = styled("div")(
  css({
    width: "100%",
    maxWidth: ["100%", 800],
    mx: "auto",
    py: ["large"],
    px: ["medium", 0],
    backgroundColor: "white",
    textAlign: "center"
  })
);

const HotelBookingConfirmation = ({ hotelNegotiatedBookingRequestToken }) => {
  const {
    isLoading,
    isResponding,
    travelers,
    hotelData,
    bookingCode,
    requestStatus
  } = useHotelBookingConfirmationContext();
  const {
    fetchNegotiationData,
    handleChangeBookingCode,
    handleApproveHotelBooking,
    handleShowDenyDialog
  } = useHotelBookingConfirmationActionsContext();

  useEffect(() => {
    fetchNegotiationData(hotelNegotiatedBookingRequestToken);
  }, []);

  return (
    <Container>
      <PageTitle title="Confirmação de reserva" />
      <SpinnerPortal visible={isLoading} />

      {requestStatus === REQUEST_STATUS.CONFIRMED && <ConfirmedMessage />}
      {requestStatus === REQUEST_STATUS.DENIED && <DeniedMessage />}

      {requestStatus === REQUEST_STATUS.UNCHANGED && (
        <Flex flexDirection="column" alignItems="center">
          <Paragraph fontSize={24} fontWeight="bold">
            Confirmação de reserva
          </Paragraph>

          <TravelersSection travelers={travelers} />
          <RoomSection hotelData={hotelData} />

          <Box mt="large">
            <Flex justifyContent="center">
              <Box mb="medium" width={300}>
                <Input
                  label="Código da reserva"
                  placeholder="Preencha o código da reserva"
                  value={bookingCode}
                  onChange={handleChangeBookingCode}
                />
                <Paragraph
                  mt="small"
                  fontSize={0}
                  color={defaultTheme.subTextColor}
                >
                  *Obrigatório para confirmar reserva
                </Paragraph>
              </Box>
            </Flex>
            <Flex mt="small" alignItems="center">
              <StContainedButton
                isFullWidth={false}
                css={css({ px: 24 })}
                loading={isResponding}
                disabled={isResponding || !bookingCode}
                onClick={handleApproveHotelBooking}
              >
                Confirmar reserva
              </StContainedButton>
              <Box width={24} />
              <StContainedButton
                isFullWidth={false}
                css={css({ px: 24 })}
                color="secondary"
                disabled={isResponding}
                onClick={handleShowDenyDialog}
              >
                Negar reserva
              </StContainedButton>
            </Flex>
          </Box>
        </Flex>
      )}

      <HotelBookingConfirmationDenyDialog />
    </Container>
  );
};

const ProviderContainer = props => {
  return (
    <HotelBookingConfirmationProvider>
      <HotelBookingConfirmation {...props} />
    </HotelBookingConfirmationProvider>
  );
};

export { ProviderContainer as HotelBookingConfirmation };
