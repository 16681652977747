import React from "react";

import Grid from "@material-ui/core/Grid";

import { Box } from "@toolkit";

import { toCurrency } from "@helpers";

import { AdvanceRangeContainer } from "../shared/AdvanceRangeChart";
import { SmallInfoCard } from "../shared/SmallInfoCard";
import { CreditPerCompany } from "./CreditPerCompany";
import { AlterFeeCard } from "./DetailsCard/AlterFeeCard";
import { CancelFeeCard } from "./DetailsCard/CancelFeeCard";
import { useFlightInfoContext } from "./FlightInfo.context";
import { SpentByCompany } from "./SpentByCompany";
import { SpentByRoute } from "./SpentByRoute";
import { SpentThroughtTime } from "./SpentThroughtTime";

const FlightInfoContent: React.FC = () => {
  const { totalSpent, averageSpent, advanceRange } = useFlightInfoContext();

  return (
    <Box pt={[24, 0]}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} xs={12}>
          <SmallInfoCard
            title="Gasto total (R$)"
            value={toCurrency(totalSpent)}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <SmallInfoCard
            title="Ticket Médio (R$)"
            value={toCurrency(averageSpent)}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <SpentThroughtTime />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <AdvanceRangeContainer
            data={advanceRange}
            barTitle="Número de voos"
          />
        </Grid>
        <Grid item xs={12}>
          <SpentByRoute />
        </Grid>
        <Grid item xs={12}>
          <SpentByCompany />
        </Grid>
        <Grid item xs={12}>
          <CreditPerCompany />
        </Grid>
        <Grid item lg={6} xs={12}>
          <CancelFeeCard />
        </Grid>
        <Grid item lg={6} xs={12}>
          <AlterFeeCard />
        </Grid>
      </Grid>
      <Box pb={88} />
    </Box>
  );
};

export { FlightInfoContent };
