import {
  CreateTagResponse,
  GetClientTagsResponse,
  GetTravelTagsResponse,
} from "../dtos/tags.dto";
import { ClientTag, Tag, TravelTag } from "../models/tags.models";

export const parseClientTags = (dto: GetClientTagsResponse) => {
  const clientTags: ClientTag[] = dto.map((clientTag) => ({
    active: clientTag.active,
    createdAt: clientTag.createdAt,
    createdBy: clientTag.createdBy,
    tagName: clientTag.tagName,
    tagToken: clientTag.tagToken,
  }));

  return clientTags;
};

export const parseTag = (dto: CreateTagResponse) => {
  const tag: Tag = {
    tagName: dto.tagName,
    tagToken: dto.tagToken,
  };

  return tag;
};

export const parseTravelTags = (dto: GetTravelTagsResponse) => {
  const travelTags: TravelTag[] = dto.map((travelTag) => ({
    tagName: travelTag.tagName,
    tagToken: travelTag.tagToken,
    travelTagToken: travelTag.travelTagToken,
  }));

  return travelTags;
};
