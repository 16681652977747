import { useEffect, useRef, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ERROR } from "~/apps/shared/constants/errors";
import { PageError } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import { useItineraryApproval } from "../../itinerary-approval.context";
import { useItineraryPendencies } from "../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../itinerary-screen.context";
import { useItineraryContainer } from "../../itinerary.container";
import { useItinerary } from "../../itinerary.context";

export const useItineraryPendenciesGuard = () => {
  const { infoModel, servicesModel } = useItineraryContainer();

  const { user } = useUser();

  const { travelToken } = useItinerary();
  const { errorOnFetchApprovalStatus } = useItineraryApproval();
  const {
    errorOnFetch,
    fetchItineraryPendencies,
    fetchOffersTravelers,
  } = useItineraryPendencies();
  const { proceedFromPendencies } = useItineraryScreen();

  const [error, setError] = useState<PageError | null>(null);

  const hasCheckedIfShouldRedirect = useRef(false);

  useEffect(() => {
    void (async () => {
      if (!infoModel || !servicesModel || !user) {
        return;
      }

      if (hasCheckedIfShouldRedirect.current) {
        return;
      }

      hasCheckedIfShouldRedirect.current = true;

      const isUserBooker = infoModel.isUserBooker(user);

      const isUserMasterApprover = user.isMasterApprover();

      const isUserAuthorizedToAccessPendencies =
        isUserBooker || isUserMasterApprover;

      if (!isUserAuthorizedToAccessPendencies) {
        setError({
          ...ERROR.UNAUTHORIZED_PROCEED_FROM_TRAVEL_PLAN,
          navigateMessage: "Voltar ao plano de viagem",
          navigateTo: () => {
            navigate(`/travels/${travelToken}/itinerary/travel-plan`);
          },
        });

        return;
      }

      setError(null);

      const [itineraryPendencies, offersTravelers] = await Promise.all([
        fetchItineraryPendencies(),
        fetchOffersTravelers(servicesModel),
      ]);

      if (!itineraryPendencies) {
        logger.error("could not fetch the travel pendencies");

        return;
      }

      if (itineraryPendencies.hasPendencies()) {
        return;
      }

      if (
        offersTravelers &&
        !servicesModel.hasEnoughTravelers(offersTravelers)
      ) {
        return;
      }

      void proceedFromPendencies();
    })();
  }, [
    fetchItineraryPendencies,
    fetchOffersTravelers,
    hasCheckedIfShouldRedirect,
    infoModel,
    proceedFromPendencies,
    servicesModel,
    travelToken,
    user,
  ]);

  return {
    error: errorOnFetch || errorOnFetchApprovalStatus || error,
  };
};
