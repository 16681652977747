import React, { ReactNode, useState, useEffect, useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { ExpenseCategoryCamelCase } from "@dtos/expense-categories.dto";

import {
  GeneralService,
  GeneralStaticData,
  GeneralTransactedChartRow,
  TravelerSpent,
} from "@models/analytics.model";

import { ALERT, Option } from "~/types";

import * as savesAndWastesUtils from "../flight-info/FlightInfo.utils";
import {
  GetFiltersOptionsResult,
  GetGeneralStaticDataResult,
  GetSpentByTravelerDataResult,
  SavesAndWastesDataResult,
  AlterDataResult,
  CancelDataResult,
  QueryParams,
  GetGeneralTransactedDataResult,
} from "../panel/panel.model";
import { IFilters } from "../shared/MobileFilters";
import * as panelService from "./panel.service";

interface State {
  isDownloadingFile: boolean;
  isLoadingPage: boolean;
  snack: {
    type: ALERT;
    open: boolean;
    message: string;
  };
  costCenterOptions: Array<Option<string>>;
  travelerOptions: Array<Option<string>>;
  tagOptions: Array<Option<string>>;
  companyAreaOptions: Array<Option<string>>;
  projectOptions: Array<Option<string>>;
  billingProfileOptions: Array<Option<string>>;
  expenseCategoriesOptions: ExpenseCategoryCamelCase[];
  loadingData: boolean;
  hasError: boolean;
  generalExpenses: GeneralService[];
  chartData: GeneralTransactedChartRow[];
  staticData: GeneralStaticData;
  totalSavings: number;
  totalWastes: number;
  travelerSpent: TravelerSpent[];
}

interface PeriodRange {
  startSelectedMonth: number;
  startSelectedYear: number;
  endSelectedMonth: number;
  endSelectedYear: number;
}

interface ParamsState {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  costCenterToken: string;
  tagToken: string;
  travelerToken: string;
  companyAreaToken: string;
  projectToken: string;
  billingProfileToken: string;
  expenseCategories: ExpenseCategoryCamelCase[];
}

interface Actions {
  closeSnackbar: () => void;
  loadPage: () => void;
  onFilterCostCenter: (costCenterToken: string) => void;
  onFilterTag: (tagToken: string) => void;
  onFilterTraveler: (travelerToken: string) => void;
  onFilterCompanyArea: (companyAreaToken: string) => void;
  onFilterProject: (projectToken: string) => void;
  onFilterBillingProfile: (billingProfileToken: string) => void;
  onPeriodRangeApply: (newPeriod: PeriodRange) => void;
  onFilterExpenseCategories(
    selectedCategories: ExpenseCategoryCamelCase[],
  ): void;
  onApplyFilters(filters: IFilters): void;
}

const initialState: State = {
  billingProfileOptions: [],
  chartData: [],
  companyAreaOptions: [],
  costCenterOptions: [],
  expenseCategoriesOptions: [],
  generalExpenses: [],
  hasError: false,
  isDownloadingFile: false,
  isLoadingPage: true,
  loadingData: false,
  projectOptions: [],
  snack: {
    message: "",
    open: false,
    type: null,
  },
  staticData: {
    averageAdvance: 0,
    generatedAt: null,
    travelInfo: {
      average: 0,
      averageDuration: 0,
      maximum: 0,
      minimum: 0,
      quantity: 0,
      total: 0,
    },
    travelPolicyAdoption: {
      inPolicy: 0,
      noPolicy: 0,
      outOfPolicy: 0,
      percentage: "",
    },
    travelPolicyAdoptions: [],
    totalTravels: 0,
  },
  tagOptions: [],
  totalSavings: 0,
  totalWastes: 0,
  travelerOptions: [],
  travelerSpent: [],
};

const today = new Date();

const initialParamsState = {
  billingProfileToken: "",
  companyAreaToken: "",
  costCenterToken: "",
  endMonth: 12,
  endYear: today.getFullYear(),
  expenseCategories: [],
  projectToken: "",
  startMonth: 1,
  startYear: today.getFullYear(),
  tagToken: "",
  travelerToken: "",
};

type ContextProps = State &
  Actions & {
    params: ParamsState;
  };

const PanelContext = React.createContext<ContextProps>({} as ContextProps);

const { Provider, Consumer } = PanelContext;

const PanelProvider = ({ children }: { children: ReactNode }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);
  const [params, setParams] = useState<ParamsState>(initialParamsState);

  function setSafeState(newState: Partial<State>) {
    setState((prev) => {
      return {
        ...prev,
        ...newState,
      };
    });
  }

  const loadPage = useCallback(async () => {
    const {
      error,
      data,
    }: GetFiltersOptionsResult = await panelService.getFilterOptionsResult();
    if (error) {
      setSafeState({
        isLoadingPage: false,
      });
      showSnackMessage(error!.description, ALERT_TYPES.ERROR);
    } else {
      setSafeState({
        billingProfileOptions: data!.billingProfileOptions,
        companyAreaOptions: data!.companyAreaOptions,
        costCenterOptions: data!.costCenterOptions,
        expenseCategoriesOptions: data!.expenseCategoriesOptions,
        isLoadingPage: false,
        projectOptions: data!.projectOptions,
        tagOptions: data!.tagOptions,
        travelerOptions: data!.travelerOptions,
      });
    }
  }, [showSnackMessage]);

  async function loadExpenseChartData(
    filterParams: QueryParams,
  ): Promise<GetGeneralTransactedDataResult> {
    return panelService.getGeneralTransactedDataResult(filterParams);
  }

  async function loadGeneralStaticData(
    filterParams: QueryParams,
  ): Promise<GetGeneralStaticDataResult> {
    return panelService.getGeneralStaticDataResult(filterParams);
  }

  async function loadSpentByTravelerData(
    filterParams: QueryParams,
  ): Promise<GetSpentByTravelerDataResult> {
    return panelService.getSpentByTraveler(filterParams);
  }

  async function loadSavings(
    filterParams: QueryParams,
  ): Promise<SavesAndWastesDataResult> {
    return panelService.getTotalSavings(filterParams);
  }

  async function loadWastes(
    filterParams: QueryParams,
  ): Promise<SavesAndWastesDataResult> {
    return panelService.getTotalWastes(filterParams);
  }

  async function loadAlterFee(
    filterParams: QueryParams,
  ): Promise<AlterDataResult> {
    return panelService.getAlterFee(filterParams);
  }

  async function loadAlterDifference(
    filterParams: QueryParams,
  ): Promise<AlterDataResult> {
    return panelService.getAlterDifference(filterParams);
  }

  async function loadCancelFee(
    filterParams: QueryParams,
  ): Promise<CancelDataResult> {
    return panelService.getCancelFee(filterParams);
  }

  function closeSnackbar() {
    setSafeState({
      snack: {
        type: null,
        open: false,
        message: "",
      },
    });
  }

  function onFilterCostCenter(costCenterToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        costCenterToken,
      };
    });
  }

  function onFilterTag(tagToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        tagToken,
      };
    });
  }

  function onFilterTraveler(travelerToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        travelerToken,
      };
    });
  }

  function onFilterCompanyArea(companyAreaToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        companyAreaToken,
      };
    });
  }

  function onFilterProject(projectToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        projectToken,
      };
    });
  }

  function onFilterBillingProfile(billingProfileToken: string) {
    setParams((prev) => {
      return {
        ...prev,
        billingProfileToken,
      };
    });
  }

  function onFilterExpenseCategories(
    selectedCategories: ExpenseCategoryCamelCase[],
  ) {
    setParams((prev) => ({
      ...prev,
      expenseCategories: selectedCategories,
    }));
  }

  function onApplyFilters(filters: IFilters) {
    setParams((prev) => ({
      ...prev,
      ...filters,
    }));
  }

  const loadData = useCallback(async () => {
    setSafeState({ loadingData: true });

    const {
      data: staticData,
      error: generalStaticError,
    } = await loadGeneralStaticData(params);

    if (generalStaticError) {
      console.log("ERROR: ", generalStaticError);
    }

    const [
      { data: generalData, error: generalExpenseError },
      { data: savingsData, error: savingsError },
      { data: alterFeeData, error: alterFeeError },
      { data: alterDifferenceData, error: alterDifferenceError },
      { data: cancelFeeData, error: cancelFeeError },
      { data: travelerSpentData, error: travelerSpentError },
    ] = await Promise.all([
      loadExpenseChartData(params),
      loadSavings(params),
      loadAlterFee(params),
      loadAlterDifference(params),
      loadCancelFee(params),
      loadSpentByTravelerData(params),
    ]);
    if (
      generalExpenseError ||
      savingsError ||
      alterFeeError ||
      alterDifferenceError ||
      cancelFeeError ||
      travelerSpentError
    ) {
      return setSafeState({ hasError: true, loadingData: false });
    }

    const totalAlterFee = alterFeeData
      ? savesAndWastesUtils.getTotalAmount(alterFeeData!.data)
      : 0;
    const totalAlterDifference = alterDifferenceData
      ? savesAndWastesUtils.getTotalAmount(alterDifferenceData!.data) -
        totalAlterFee
      : 0;

    const { regularWastes, noshowWastes } = cancelFeeData!.data;
    const totalRegularWastes = savesAndWastesUtils.getTotalAmount(
      regularWastes,
    );
    const totalNoShowWastes = savesAndWastesUtils.getTotalAmount(noshowWastes);

    const totalSavings = savingsData
      ? savesAndWastesUtils.getTotalAmount(savingsData!.data)
      : 0;

    const totalWastes =
      totalAlterFee +
      totalAlterDifference +
      totalRegularWastes +
      totalNoShowWastes;

    setState((prev: State) => {
      return {
        ...prev,
        chartData: generalData!.chartData,
        generalExpenses: generalData!.generalExpenses,
        hasError: false,
        loadingData: false,
        staticData: staticData!,
        totalSavings,
        totalWastes,
        travelerSpent: travelerSpentData!,
      };
    });
  }, [params]);

  function onPeriodRangeApply(newPeriod: PeriodRange) {
    const newParams = {
      endMonth: newPeriod.endSelectedMonth + 1,
      endYear: newPeriod.endSelectedYear,
      startMonth: newPeriod.startSelectedMonth + 1,
      startYear: newPeriod.startSelectedYear,
    };

    setParams((prevParams: ParamsState) => {
      return {
        ...prevParams,
        ...newParams,
      };
    });
  }

  // Effect hooks

  useEffect(() => {
    void loadPage();
  }, [loadPage]);

  useEffect(() => {
    void loadData();
  }, [params, loadData]);

  return (
    <Provider
      value={{
        ...state,
        params,
        closeSnackbar,
        loadPage,
        onApplyFilters,
        onFilterBillingProfile,
        onFilterCompanyArea,
        onFilterCostCenter,
        onFilterExpenseCategories,
        onFilterProject,
        onFilterTag,
        onFilterTraveler,
        onPeriodRangeApply,
      }}
    >
      {children}
    </Provider>
  );
};

export { PanelContext, PanelProvider, Consumer as PanelConsumer };
