import React, { useCallback, useMemo, useState } from "react";

import { Search as SearchIcon } from "@material-ui/icons/";
import moment from "moment";
import { theme } from "smartrips-skin";
import { Box, Text } from "smartrips-toolkit";

import { CompanyEventStatus, ParticipationStatus } from "~/models/event.model";

import CircularSpinner from "~/components/shared/CircularSpinner";

import { FilterButton } from "../../shared/filterButtonPopover/FilterButton";
import { Input } from "../../shared/inputs";
import Layout from "../../shared/Layout";
import PageTitle from "../../shared/PageTitle";
import { Tabs } from "../../shared/tabs";
import { useEvents } from "../events.context";
import { ListEventsEventCard } from "./event-card";
import { ListEventsHeader } from "./header";
import { styles } from "./styles";

const defaultFilters = [
  {
    label: "Participando",
    value: "participating"
  },
  {
    label: "Organizando",
    value: "organizing"
  },
  {
    label: "Convidado",
    value: "invited"
  },
  {
    label: "Recusado",
    value: "declined"
  }
] as const;

const defaultTabs: {
  label: string;
  value: CompanyEventStatus;
}[] = [
  {
    label: "Próximos",
    value: CompanyEventStatus.UPCOMING
  },
  {
    label: "Em progresso",
    value: CompanyEventStatus.IN_PROGRESS
  },
  {
    label: "Passados",
    value: CompanyEventStatus.ENDED
  }
] as const;

export const ListEvents: React.VFC = () => {
  const { events, isLoadingEvents } = useEvents();

  const [filters, setFilters] = useState<
    typeof defaultFilters[number]["value"][]
  >([]);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState<typeof defaultTabs[number]["value"]>(
    CompanyEventStatus.UPCOMING
  );

  const filteredEvents = useMemo(() => {
    const filteredBySearch = events.filter(event =>
      event.name.toLowerCase().includes(search.toLowerCase())
    );

    const filteredByTab = filteredBySearch.filter(
      event => ((event.status as unknown) as CompanyEventStatus) === tab
    );

    const filteredByFilters = filteredByTab.filter(event => {
      if (filters.length === 0) {
        return true;
      }

      if (
        filters.some(filter => {
          if (filter === "declined") {
            return (
              event.viewer_info.participation_status ===
              ParticipationStatus.DECLINED
            );
          }

          if (filter === "invited") {
            return [
              ParticipationStatus.REALLY_PENDING,
              ParticipationStatus.VIRTUAL_PENDING
            ].includes(event.viewer_info.participation_status);
          }

          if (filter === "organizing") {
            return event.viewer_info.is_organizer;
          }

          if (filter === "participating") {
            return (
              event.viewer_info.participation_status ===
                ParticipationStatus.ACCEPTED || event.viewer_info.is_organizer
            );
          }
        })
      ) {
        return true;
      }

      return false;
    });

    return filteredByFilters;
  }, [events, filters, search, tab]);

  const groupedByDateEvents = useMemo(() => {
    const groupedByDateEvents: {
      [key: string]: typeof filteredEvents;
    } = {};

    for (const event of filteredEvents) {
      const date = moment(event.start_date).format("MMMM, YYYY");

      const formattedDate = date.charAt(0).toUpperCase() + date.slice(1);

      if (!(formattedDate in groupedByDateEvents)) {
        groupedByDateEvents[formattedDate] = [];
      }

      groupedByDateEvents[formattedDate].push(event);
    }

    return groupedByDateEvents;
  }, [filteredEvents]);

  const handleFilters = useCallback((filter: typeof filters[number]) => {
    setFilters(prev => {
      if (prev.includes(filter)) {
        return prev.filter(f => f !== filter);
      }

      return [...prev, filter];
    });
  }, []);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  return (
    <>
      <PageTitle title="Eventos" />
      <Layout>
        <Box css={styles.container} className="container">
          {isLoadingEvents ? (
            <div css={styles.loader}>
              <CircularSpinner />
            </div>
          ) : (
            <div css={styles.root}>
              <ListEventsHeader />
              <Input
                css={styles.search}
                icon={
                  <SearchIcon
                    css={{
                      color: theme.colors.gray[2]
                    }}
                    fontSize="small"
                  />
                }
                InputProps={{
                  inputRef: element => {
                    if (!element) {
                      return;
                    }

                    element.style.setProperty(
                      "padding",
                      "13.5px 14px 12.5px 0",
                      "important"
                    );
                  }
                }}
                onChange={handleSearch}
                placeholder="Buscar por um evento..."
                value={search}
              />
              <div css={styles.filters.root}>
                <Tabs
                  classes={{
                    ...styles.tabs
                  }}
                  onChange={(_, tab) => {
                    setTab(tab);
                  }}
                  value={tab}
                >
                  {defaultTabs.map(tab => (
                    <Tabs.Item
                      classes={{ ...styles.tabs.tab }}
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                <div css={styles.filters.chips.root}>
                  <Text
                    color={theme.colors.gray[4]}
                    css={styles.filters.chips.label}
                    fontSize={14}
                  >
                    Filtre por
                  </Text>
                  {defaultFilters.map(filter => (
                    <FilterButton
                      css={styles.filters.chips.chip.root}
                      isActive={filters.includes(filter.value)}
                      key={filter.value}
                      onClick={() => {
                        handleFilters(filter.value);
                      }}
                    >
                      {filter.label}
                    </FilterButton>
                  ))}
                </div>
              </div>
              <div css={styles.events.root}>
                {Object.entries(groupedByDateEvents).length > 0 ? (
                  <>
                    {Object.entries(groupedByDateEvents).map(
                      ([date, events]) => (
                        <>
                          <Text fontSize={24} fontWeight="bold">
                            {date}
                          </Text>
                          {events.map(event => (
                            <ListEventsEventCard
                              event={event}
                              key={event.token}
                              showParticipationStatus
                            />
                          ))}
                        </>
                      )
                    )}
                  </>
                ) : (
                  <div css={styles.events.empty}>
                    <Text fontSize={18} fontWeight="bold">
                      Nenhum evento encontrado...
                    </Text>
                    <Text color={theme.colors.gray[3]} fontSize={14}>
                      Verifique os seus filtros.
                    </Text>
                  </div>
                )}
              </div>
            </div>
          )}
        </Box>
      </Layout>
    </>
  );
};
