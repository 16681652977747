import * as documentsApi from "~/apps/shared/apis/documents.api";
import errorTypes from "~/apps/shared/constants/errors";
import {
  CreateDocumentDto,
  EditDocumentDto,
} from "~/apps/shared/dtos/documents.dto";
import { Document } from "~/apps/shared/models/documents.model";
import * as documentsParser from "~/apps/shared/parsers/documents.parser";
import { Error } from "~/apps/shared/types";
import { formatServiceError } from "~/apps/shared/utils/errors";

export const createDocument = async (data: CreateDocumentDto) => {
  const result: { data?: Document; error?: Error } = {};

  try {
    const createDocumentRequestBody = documentsParser.parseCreateDocumentRequestBody(
      data,
    );

    result.data = await documentsApi.createDocument(createDocumentRequestBody);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const deleteDocument = async (documentToken: string) => {
  const result: { error?: Error } = {};

  try {
    await documentsApi.deleteDocument(documentToken);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (data.type === errorTypes.ERROR_GETTING_DOCUMENT && status === 400) {
        result.error = {
          description: "Não foi possível deletar este documento",
          title: "Erro",
        };

        return result;
      }
    }

    result.error = formatServiceError({ error });
  }

  return result;
};

export const editDocument = async (data: EditDocumentDto) => {
  const result: { data?: Document; error?: Error } = {};

  try {
    const editDocumentRequestBody = documentsParser.parseEditDocumentRequestBody(
      data,
    );

    result.data = await documentsApi.editDocument(
      editDocumentRequestBody,
      data.documentToken,
    );
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400 && data.type === errorTypes.ERROR_INVALID_INPUT) {
        result.error = {
          description: "Erro inesperado, verifique os dados e tente novamente.",
          title: "Erro",
        };

        return result;
      }

      if (status === 400 && data.type === errorTypes.ERROR_FINDING_USER) {
        result.error = {
          description: "Não é possível editar documentos desse viajante.",
          title: "Erro",
        };

        return result;
      }

      if (status === 400 && data.type === errorTypes.ERROR_GETTING_DOCUMENT) {
        result.error = {
          description: "Não foi possível editar este documento.",
          title: "Erro",
        };

        return result;
      }
    }

    result.error = formatServiceError({ error });
  }

  return result;
};

export const getUserDocuments = async (userToken: string) => {
  const result: { data?: Document[]; error?: Error } = {};

  try {
    result.data = await documentsApi.getUserDocuments(userToken);
  } catch (error) {
    if (error.response) {
      result.error = formatServiceError({ error });
    }
  }

  return result;
};
