import { GroupBase, StylesConfig } from "react-select";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { defaultTheme } from "@assets/styles/theme";

import { ApprovalTarget } from "~/models/approval-process.model";

export const styles = {
  loader: css({
    width: "33.375rem",
    marginTop: "10rem",
  }),
  formContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  contentContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
    padding: "1rem 0",
  }),
  footerContainer: css({
    display: "flex",
    justifyContent: "end",
    paddingTop: "1.5rem",
    gap: "1rem",
  }),
  messageContainer: css({
    width: "auto",
    height: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "0.5rem",
  }),
  approversSelectContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  titleContainer: css({
    paddingBottom: "1rem",
  }),
  title: css({
    fontSize: "1.125rem",
    fontWeight: 600,
  }),
  label: css({
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: "0.75rem",
  }),
  approverStagesSpacing: css({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "0.25rem 0",
  }),
  formGroup: css({
    position: "relative",
  }),
  messageError: css({
    position: "absolute",
    width: "max-content",
    margin: "0",
    marginTop: "1.75rem",
    fontSize: "12px",
    color: defaultTheme.errorTextColor,
  }),
  warningMessageContainerTop: css({
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    borderLeft: `4px solid ${defaultTheme.warningTextColor}`,
    padding: "16px 32px",
    marginBottom: "16px",
  }),
  messageCard: css({
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    borderLeft: `4px solid ${defaultTheme.primaryColor}`,
    padding: "16px 32px",
    width: 640,
    marginBottom: "16px",
  }),
  warningMessageContainer: css({
    display: "flex",
    gap: "0.625rem",
    padding: "1rem",
    alignItems: "center",
    borderRadius: "0.5rem",
    backgroundColor: theme.colors.background.gray,
    marginTop: "2rem",
  }),
  warningMessageIcon: css({
    color: defaultTheme.warningTextColor,
  }),
  warningMessage: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  }),
  messageCardTitle: css({
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "12px",
  }),
  warningMessageTop: css({
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1rem",
    marginBottom: "0.10rem",
  }),
};

export const asyncAutoCompleteStyles: StylesConfig<
  ApprovalTarget,
  false,
  GroupBase<ApprovalTarget>
> = {
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 20,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: "3rem",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "0 0.5rem",
    border: `1px solid ${theme.colors.gray[100]}`,
    boxShadow: "none",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    "&:hover": {
      borderColor: theme.colors.gray[300],
    },
    "&:focus-within": {
      border: `1px solid ${theme.colors.gray[500]}`,
      boxShadow: `0 0 0 1px ${theme.colors.gray[500]}`,
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: `${theme.colors.gray[100]}`,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "0.25rem",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
};
