import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { HotelFavorite } from "~/apps/corporate/models/hotel.model";
import hotelPlaceholder from "~/apps/shared/assets/images/placeholders/hotel-placeholder.jpg";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { PDFRemoteImage } from "~/apps/shared/components/pdf-remote-image/pdf-remote-image";
import { toKilometers } from "~/apps/shared/utils/to-kilometers";

import { styles } from "./styles";
import { TravelPlanPDFHotelSegmentAmenitiesIcons } from "./travel-plan-pdf-hotel-segment-amenities-icons/travel-plan-pdf-hotel-segment-amenities-icons";
import { TravelPlanPDFHotelSegmentStarRatingDisplay } from "./travel-plan-pdf-hotel-segment-star-rating-display/travel-plan-pdf-hotel-segment-star-rating-display";

type Props = {
  amenities: {
    breakfast?: boolean;
    refund?: boolean;
    wifi?: boolean;
  };
  hotel: {
    address: string;
    distance?: number;
    favorites: HotelFavorite[];
    mainPictureUrl?: string;
    name: string;
    stars: number;
  };
};

export const TravelPlanPDFHotelSegment: React.FC<Props> = ({
  amenities,
  hotel,
}) => {
  const leftStyles = styles.left();
  const rightStyles = styles.right();
  const rootStyles = styles.root();

  return (
    <View style={rootStyles.root}>
      <View style={leftStyles.root}>
        <PDFRemoteImage
          fallbackSrc={hotelPlaceholder}
          src={hotel.mainPictureUrl}
          style={leftStyles.picture}
        />
      </View>
      <View style={rightStyles.root}>
        <View style={rightStyles.top}>
          <View style={rightStyles.name}>
            <Text>{hotel.name}</Text>
          </View>
          <TravelPlanPDFHotelSegmentStarRatingDisplay
            rating={hotel.stars}
            style={rightStyles.stars}
          />
        </View>
        <View style={rightStyles.bottom}>
          <View style={rightStyles.info}>
            <PDFIcon size={14} use="map-pin" />
            <View
              style={{
                textTransform: "capitalize",
              }}
            >
              <Text>{hotel.address.toLowerCase()}</Text>
            </View>
          </View>
          {hotel.distance ? (
            <View style={rightStyles.info}>
              <PDFIcon size={14} use="male" />
              <View>
                <Text>
                  {toKilometers(hotel.distance)} de distância de sua pesquisa
                </Text>
              </View>
            </View>
          ) : null}
          <TravelPlanPDFHotelSegmentAmenitiesIcons
            breakfast={amenities.breakfast}
            cancel={amenities.refund}
            wifi={amenities.wifi}
          />
        </View>
      </View>
    </View>
  );
};
