import { ERROR } from "@constants";

import { getClientPermissions } from "@helpers/user.helper";

import {
  ExpenseReportFinishResponse,
  ExpenseReportFinishRequest,
  ExpenseReportSendApprovalRequestBody,
} from "@dtos/expenses.dto";

import { AdvancedExpense } from "@models/advanced-expense.model";
import { Client } from "@models/client.model";
import { CostCenterListByUserItem } from "@models/cost-center.model";
import {
  ExpenseReport,
  Expense,
  ExpenseReportApprovalStatus,
  ExpensesPolicies,
} from "@models/expense.model";
import { Travel } from "@models/travel.model";
import { UserModel } from "@models/user.model";

import * as areasApi from "@apis/areas.api";
import * as billingProfileApi from "@apis/billing-profile.api";
import clientApi from "@apis/client.api";
import * as costCentersApi from "@apis/cost-center.api";
import * as expensesApi from "@apis/expense.api";
import * as financialApi from "@apis/financial.api";
import * as projectsApi from "@apis/projects.api";
import * as travelApi from "@apis/travel.api";
import * as userApi from "@apis/user.api";

import { CustomError, ImageFile } from "../../../types";

export async function getReportByToken(
  reportToken: string,
): Promise<{ data?: ExpenseReport; error?: CustomError }> {
  const result: { data?: ExpenseReport; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getReportByToken(reportToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    }
  }

  return result;
}

export async function getTraveler(
  travelerToken: string,
): Promise<{ data?: UserModel; error?: CustomError }> {
  const result: { data?: UserModel; error?: CustomError } = {};

  try {
    result.data = await userApi.getUserRequest(travelerToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_FETCH_ERROR;
    }
  }

  return result;
}

export async function getReportTravel(
  travelToken: string,
): Promise<{ data?: Travel; error?: CustomError }> {
  const result: { data?: Travel; error?: CustomError } = {};

  try {
    result.data = await travelApi.getTravelRequest(travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR;
    }
  }

  return result;
}

export async function finishExpenseReport(
  reportToken: string,
  costCenterToken?: string,
): Promise<{ data?: ExpenseReportFinishResponse; error?: CustomError }> {
  const result: {
    data?: ExpenseReportFinishResponse;
    error?: CustomError;
  } = {};

  try {
    const requestData: ExpenseReportFinishRequest = {};

    if (costCenterToken) {
      requestData.cost_center_token = costCenterToken;
    }

    result.data = await expensesApi.finishExpenseReport(
      reportToken,
      requestData,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FINISH_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FINISH_REPORT_ERROR;
    }
  }

  return result;
}

export async function removeExpense(
  removedExpense: Expense,
  reportToken: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  const expenses = [
    {
      expense_token: removedExpense.expenseToken,
    },
  ];

  try {
    result.data = await expensesApi.removeExpensesFromReport(
      { expenses },
      reportToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR;
    }
  }

  return result;
}

export async function removeAdvancedExpense(
  advancedExpenseToRemove: AdvancedExpense,
  reportToken: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensesApi.removeAdvancedExpenseFromReport(
      [advancedExpenseToRemove.expenseAdvanceToken],
      reportToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR;
    }
  }

  return result;
}

export async function getReceiptImageFile(
  receiptUrl: string,
): Promise<{ data?: ImageFile; error?: CustomError }> {
  const result: { data?: ImageFile; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getImageFile(receiptUrl);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    }
  }

  return result;
}

export async function updateExpenseReportDescription(
  description: string,
  expenseReportToken: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensesApi.updateExpesseReportDescription(
      description,
      expenseReportToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_DESCRIPTION_UPDATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_DESCRIPTION_UPDATE_ERROR;
    }
  }

  return result;
}

export async function getClientInfo(): Promise<{
  data?: Client;
  error?: CustomError;
}> {
  const result: { data?: Client; error?: CustomError } = {};

  try {
    result.data = await clientApi.getClientInfo();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENT_INFO_ERROR;
    }
  }

  return result;
}

export async function getTravelerCostCenters(
  travelerToken: string,
): Promise<{ data?: CostCenterListByUserItem[]; error?: CustomError }> {
  const result: { data?: CostCenterListByUserItem[]; error?: CustomError } = {};
  const {
    companySettings: { costCenters: costCenterPermission },
  } = getClientPermissions();

  try {
    const costCenters = costCenterPermission
      ? await costCentersApi.listCostCentersByUser(travelerToken)
      : [];
    result.data = costCenters.map((costCenter) => ({
      ...costCenter,
      label: costCenter.name,
      value: costCenter.token,
    }));
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR;
    }
  }

  return result;
}

export async function sendExpenseReportApprovalRequest(
  expenseReportToken: string,
  costCenterToken: string | null,
  companyAreaToken: string,
  billingProfileToken: string,
  projectToken: string,
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const dto: ExpenseReportSendApprovalRequestBody = {
      expense_report_token: expenseReportToken,
      cost_center_token: costCenterToken ? costCenterToken : undefined,
      company_area_token: companyAreaToken ? companyAreaToken : undefined,
      billing_profile_token: billingProfileToken
        ? billingProfileToken
        : undefined,
      project_token: projectToken ? projectToken : undefined,
    };

    result.data = await expensesApi.sendExpenseReportApprovalRequest(dto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_SEND_APPROVAL_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_SEND_APPROVAL_REQUEST_ERROR;
    }
  }

  return result;
}

export async function getExpenseReportApprovalStatus(
  expenseReportToken: string,
): Promise<{ data?: ExpenseReportApprovalStatus; error?: CustomError }> {
  const result: {
    data?: ExpenseReportApprovalStatus;
    error?: CustomError;
  } = {};

  try {
    result.data = await expensesApi.getExpenseReportApprovalStatus(
      expenseReportToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR;
    }
  }

  return result;
}

export async function getReportExpensesPolicies(
  expenseReportToken: string,
): Promise<{ data?: ExpensesPolicies; error?: CustomError }> {
  const result: { data?: ExpensesPolicies; error?: CustomError } = {};
  const {
    expenses: { policies: expensePolicyPermission },
  } = getClientPermissions();

  try {
    result.data = expensePolicyPermission
      ? await expensesApi.getReportExpensesPolicies(expenseReportToken)
      : {};
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR;
    }
  }

  return result;
}

export async function downloadExpenseReportInvoices(
  expenseReportToken: string,
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await financialApi.downloadExpenseReportInvoices(expenseReportToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR;
    }
  }

  return result;
}

export async function getBillingProfiles(travelerToken: string) {
  const result: any = {};

  try {
    result.data = await billingProfileApi.getUserBillingProfileList(
      travelerToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    }
  }

  return result;
}

export async function getUserAreas(userToken: string) {
  const result: any = {};
  const {
    companySettings: { areas: areasPermission },
  } = getClientPermissions();

  try {
    result.data = areasPermission ? await areasApi.getUserAreas(userToken) : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_USER_AREAS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_USER_AREAS_ERROR;
    }
  }

  return result;
}

export async function getUserProjects(userToken: string) {
  const result: any = {};
  const {
    companySettings: { projects: projectsPermission },
  } = getClientPermissions();

  try {
    result.data = projectsPermission
      ? await projectsApi.getUserProjects(userToken)
      : [];
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_USER_PROJECTS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_USER_PROJECTS_ERROR;
    }
  }

  return result;
}

export async function cancelReportApprovalRequest(
  expenseReportToken: string,
): Promise<{ data?: { result: string }; error?: CustomError }> {
  const result: { data?: { result: string }; error?: CustomError } = {};

  try {
    result.data = await expensesApi.cancelReportApprovalRequest(
      expenseReportToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CANCEL_REPORT_APPROVAL_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CANCEL_REPORT_APPROVAL_REQUEST_ERROR;
    }
  }

  return result;
}
