import React from "react";

import { navigate } from "@reach/router";
import { useFormContext } from "~/apps/shared/components/form/form";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

const onClose = () => navigate("/configurations/users");

export const UserFormFooter: React.FC = () => {
  const form = useFormContext();

  return (
    <footer css={styles.root}>
      <Button disabled={form.submitting} type="submit">
        Salvar
      </Button>
      <Button
        disabled={form.submitting}
        fill="outlined"
        onClick={onClose}
        type="button"
      >
        Cancelar
      </Button>
    </footer>
  );
};
