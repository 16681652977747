import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    card: {
      divisor: css({
        backgroundColor: theme.colors.gray[100],
        border: "none",
        height: "1px",
        margin: "24px 0",
        width: "100%",
      }),
      root: css({
        fontSize: "0.875rem",
      }),
    },
    divisor: css({
      backgroundColor: theme.colors.gray[100],
      border: "none",
      height: "1px",
      margin: "0 auto",
      width: "calc(100% - 8px * 2)",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      height: "100%",
      margin: "24px 0",
      padding: "0 24px",
    }),
  },
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "24px",
      position: "sticky",
      width: "100%",
    }),
    policy: css({
      color: theme.colors.pink[500],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    price: css({
      fontSize: "1.125rem",
      fontWeight: 700,
    }),
    warning: css({
      color: theme.colors.gray[500],
      fontSize: "0.875rem",
    }),
  },
};
