import React from "react";

import DeniedIcon from "@material-ui/icons/CancelOutlined";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  icon: css({
    color: "cancel",
    fontSize: [70, 80]
  })
};

const DeniedMessage = () => {
  return (
    <Box height={[500]} width="100%" textAlign="left">
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Flex alignItems="center">
          <Box mr="medium">
            <DeniedIcon css={styles.icon} />
          </Box>
          <Box maxWidth={500}>
            <Flex flexDirection="column">
              <Text
                fontSize={[24, 30]}
                color={defaultTheme.subTextColor}
                fontWeight="bold"
                mb="small"
              >
                Reserva negada.
              </Text>
              <Text
                fontSize={[1, 18]}
                color={defaultTheme.subTextColor}
                lineHeight={["16px", "21px"]}
              >
                Informaremos o(s) viajante(s) da não disponibilidade da oferta
                solicitada.
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export { DeniedMessage };
