import React from "react";
import PropTypes from "prop-types";

const Layout = ({ children, style, ...rest }) => (
  <div
    {...rest}
    style={
      style ? { ...style, position: "absolute" } : { position: "absolute" }
    }
  >
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object
};

export default Layout;
