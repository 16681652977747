import React from "react";
import { useTable } from "react-table";

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { Box } from "@toolkit";

import { Status } from "../real-time-travels.context";
import { TableColumn, RowData } from "./table.models";

const styles = {
  cell: css({
    padding: "0.625rem",
    textAlign: "left",
    whiteSpace: "nowrap",
    "&:first-of-type": {
      borderLeft: `1px solid rgba(224, 224, 224, 1)`,
    },
    "&:last-of-type": {
      borderRight: `1px solid rgba(224, 224, 224, 1)`,
    },
  }),
  headerCell: css({
    backgroundColor: theme.colors.pink[300],
    fontWeight: "bold",
    padding: "0.625rem",
    textAlign: "left",
    whiteSpace: "nowrap",
  }),
  message: css({
    fontSize: "0.875rem",
    lineHeight: "1.5",
    pt: "medium",
  }),
  stickyHeader: css({
    position: "sticky",
    top: 0,
    zIndex: 1,
  }),
};

type Props = {
  columns: TableColumn[];
  data: RowData[];
  noTravels: boolean;
  status: Status;
};

const TablePresentational: React.FC<Props> = ({
  columns,
  data,
  noTravels,
  status,
}) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
  });

  const noSelectedCity = !!rows.length;

  if (status === Status.FETCHING) {
    return null;
  }

  if (noTravels) {
    return (
      <div css={styles.message}>
        Não existe nenhuma viagem acontecendo no período selecionado
      </div>
    );
  }

  if (!noSelectedCity) {
    return (
      <div css={styles.message}>
        Clique em um dos marcadores no mapa para obter mais informações sobre as
        viagens
      </div>
    );
  }

  return (
    <Box flex={1} maxHeight="37.5rem" mt="medium" overflow="auto">
      <MuiTable {...getTableProps}>
        <TableHead css={styles.stickyHeader}>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  css={styles.headerCell}
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => (
                  <TableCell
                    css={styles.cell}
                    {...cell.getCellProps()}
                    key={cell.getCellProps().key}
                  >
                    {cell.value ? cell.render("Cell") : "-"}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </Box>
  );
};

export { TablePresentational };
