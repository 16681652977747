import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { Text } from "@toolkit";

import {
  EXPENSE_PAYMENT_METHOD,
  EXPENSE_PAYMENT_METHOD_TRANSLATION,
} from "@constants";

import { Input } from "@shared/inputs/Input";

import { PaymentMethodsOptions } from "../../ExpenseDrawer.types";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
  value: PaymentMethodsOptions | "";
  handleChange: (e: React.ChangeEvent) => void;
  onBlur: (e: any) => void;
  error?: boolean;
  errorMessage?: string | null;
}

const getPaymentMethods = (): string[] => {
  return Object.values(EXPENSE_PAYMENT_METHOD).filter(
    (item) => item !== EXPENSE_PAYMENT_METHOD.SMARTRIPS,
  );
};

const PaymentMethodSelect: React.FC<Props> = ({
  value,
  handleChange,
  ...rest
}) => {
  const { clientConfig } = useClientConfig();

  const customCorporativeCardName =
    clientConfig && clientConfig.getCustomCorporativeCardName();

  return (
    <div style={{ width: "100%" }}>
      <Input
        label="Método de pagamento"
        onChange={handleChange}
        select={true}
        value={value}
        {...rest}
      >
        {getPaymentMethods().map((item) => (
          <MenuItem value={item} key={item}>
            {item === EXPENSE_PAYMENT_METHOD.CORPORATIVE_CREDIT_CARD ? (
              <Text>
                {customCorporativeCardName ||
                  EXPENSE_PAYMENT_METHOD_TRANSLATION[item]}
              </Text>
            ) : (
              <Text>{EXPENSE_PAYMENT_METHOD_TRANSLATION[item]}</Text>
            )}
          </MenuItem>
        ))}
      </Input>
    </div>
  );
};

export { PaymentMethodSelect };
