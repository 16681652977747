export const convertHoursAndMinutes = (hoursAndMinutes: string) => {
  const timeSections = hoursAndMinutes.split(":");

  if (timeSections.length === 3) {
    const [days, hours, minutes] = timeSections;

    if (days === "00") {
      return hours === "00" ? `${minutes}m` : `${hours}h ${minutes}m`;
    }

    return `${days}d ${hours}h ${minutes}m`;
  }

  const [hours, minutes] = timeSections;

  return hours === "00" ? `${minutes}m` : `${hours}h ${minutes}m`;
};
