import React from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Box, Flex, Text } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { defaultTheme } from "@assets/styles/theme";

import { ApprovableRule } from "@models/expense-approval.model";

const styles = {
  root: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "0.5rem",
  }),
  header: css({
    padding: "0.5rem 1rem",
    backgroundColor: theme.colors.background.gray,
    borderRadius: "0.5rem 0.5rem 0 0",
  }),
  title: css({
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
  }),
  icons: {
    trash: css({
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        color: defaultTheme.errorTextColor,
        transition: "200ms",
      },
    }),
    pencil: css({
      color: theme.colors.gray[300],
      cursor: "pointer",
      "&:hover": {
        color: darken(theme.colors.gray[300], 0.5),
        transition: "200ms",
      },
    }),
  },
  label: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
  }),
  text: css({
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: theme.colors.gray[500],
  }),
  approversContainer: css({
    width: "19.5rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
};

type ApprovalCardPresentationalProps = {
  ruleItem: ApprovableRule;
  approvers: string;
  approvers2?: string;
  approvers3: string;
  approvers4: string;
  paymentApprovers: string;
  title: string;
  ruleLabel: string;
  onDeleteClick: (rule: ApprovableRule) => void;
  onEditClick: (rule: ApprovableRule) => void;
};

export const ApprovalCardPresentational: React.FC<ApprovalCardPresentationalProps> = ({
  ruleItem,
  approvers,
  approvers2,
  approvers3,
  approvers4,
  paymentApprovers,
  title,
  ruleLabel,
  onDeleteClick,
  onEditClick,
}) => {
  const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onDeleteClick(ruleItem);
  };

  return (
    <Box css={styles.root}>
      <Flex justifyContent="space-between" css={styles.header}>
        <Text css={styles.title}>{title}</Text>
        <Flex style={{ gap: "1rem" }}>
          <Icon
            use="pencil"
            css={styles.icons.pencil}
            size={24}
            onClick={() => onEditClick(ruleItem)}
          />
          <Icon
            use="outline-trash"
            css={styles.icons.trash}
            size={24}
            onClick={handleDelete}
          />
        </Flex>
      </Flex>

      <Flex padding="1rem" justifyContent="space-between">
        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          <Box>
            <Text css={styles.label}>Regra de aprovação: </Text>
            <Text css={styles.text}>{ruleLabel}</Text>
          </Box>

          {paymentApprovers ? (
            <Box style={{ width: "19.5rem" }}>
              <Text css={styles.label}>Pagamento: </Text>
              <Text css={styles.text}>{paymentApprovers}</Text>
            </Box>
          ) : null}
        </Flex>

        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          <Box css={styles.approversContainer}>
            <Text css={styles.label}>Aprovadores: </Text>
            <Text css={styles.text}>{approvers}</Text>
          </Box>

          <Box css={styles.approversContainer}>
            <Text css={styles.label}>Segundo nível: </Text>
            <Text css={styles.text}>{approvers2 || "-"}</Text>
          </Box>

          <Box css={styles.approversContainer}>
            <Text css={styles.label}>Terceiro nível: </Text>
            <Text css={styles.text}>{approvers3 || "-"}</Text>
          </Box>

          <Box css={styles.approversContainer}>
            <Text css={styles.label}>Quarto nível: </Text>
            <Text css={styles.text}>{approvers4 || "-"}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
