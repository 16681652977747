import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  HospitalityHotel,
  HotelFavorite,
} from "~/apps/corporate/models/hotel.model";
import { AmenitiesIcons } from "~/apps/shared/components/amenities-icons/amenities-icons";
import { Icon } from "~/apps/shared/components/icon/icon";
import { NoImageHotel } from "~/apps/shared/components/no-image-hotel/no-image-hotel";
import { StarRatingDisplay } from "~/apps/shared/components/star-rating-display/star-rating-display";
import { toKilometers } from "~/apps/shared/utils/to-kilometers";

import { HotelFavoriteButton } from "./hotel-favorite-button/hotel-favorite-button";
import { styles } from "./styles";

type Props = {
  amenities: {
    breakfast?: boolean;
    refund?: boolean;
    wifi?: boolean;
  };
  hotel: {
    address: string;
    distance?: number;
    favorites: HotelFavorite[];
    mainPictureUrl?: string;
    name: string;
    stars: number;
  };
} & (
  | {
      openFavoriteDialog?: never;
      showFavoriteButton?: false;
    }
  | {
      openFavoriteDialog: (hotel: HospitalityHotel) => void;
      showFavoriteButton: true;
    }
);

export const ItineraryHotelSegment: React.FC<Props> = ({
  amenities,
  hotel,
  ...props
}) => {
  return (
    <div css={styles.root}>
      {props.showFavoriteButton ? (
        <HotelFavoriteButton
          css={styles.overlay.favorite}
          openFavoriteDialog={props.openFavoriteDialog}
          hotel={hotel}
        />
      ) : null}
      <div css={styles.left.root}>
        {hotel.mainPictureUrl ? (
          <img
            alt={hotel.name}
            css={styles.left.picture}
            src={hotel.mainPictureUrl}
          />
        ) : (
          <NoImageHotel css={styles.left.picture} />
        )}
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          <span css={styles.right.top.name}>{hotel.name}</span>
          <StarRatingDisplay
            css={styles.right.top.stars}
            rating={hotel.stars}
            size={16}
          />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.info}>
            <Icon size={20} use="map-pin" />
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {hotel.address.toLowerCase()}
            </span>
          </div>
          {hotel.distance ? (
            <div css={styles.right.bottom.info}>
              <Icon size={20} use="male" />
              <span>
                {toKilometers(hotel.distance)} de distância de sua pesquisa
              </span>
            </div>
          ) : null}
          <AmenitiesIcons
            breakfast={amenities.breakfast}
            cancel={amenities.refund}
            css={styles.right.bottom.amenities}
            wifi={amenities.wifi}
          />
        </div>
      </div>
    </div>
  );
};

export const ItineraryHotelSegmentSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <Skeleton css={styles.left.picture} />
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.top.root}>
          <Skeleton height="24px" width="192px" />
          <Skeleton height="20px" width="128px" />
        </div>
        <div css={styles.right.bottom.root}>
          <div css={styles.right.bottom.info}>
            <Skeleton height="20px" width="92px" />
          </div>
          <div css={styles.right.bottom.info}>
            <Skeleton height="20px" width="144px" />
          </div>
          <div css={styles.right.bottom.info}>
            <Skeleton height="20px" width="92px" />
          </div>
          <div css={styles.right.bottom.info}>
            <Skeleton height="20px" width="144px" />
          </div>
        </div>
      </div>
    </div>
  );
};
