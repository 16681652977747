import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
    "@media screen and (max-width: 768px)": {
      width: "100dvw",
    },
  }),
};
