import React from "react";

import { Redirect, RouteComponentProps } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilitiesLiterals } from "~/apps/shared/constants/enums";

type Props = RouteComponentProps & {
  atLeastOne?: boolean;
  capabilities?: CapabilitiesLiterals[];
  component: React.ElementType;
};

export const CapabilityProtectedRoute: React.FC<Props> = ({
  atLeastOne = false,
  capabilities = [],
  component: Component,
  ...props
}) => {
  const { user } = useUser();

  if (!user) {
    return <Redirect noThrow to="/login" />;
  }

  if (atLeastOne) {
    if (
      capabilities.length > 0 &&
      capabilities.some((capability) => user.hasCapability(capability))
    ) {
      return <Component {...props} />;
    }
  }

  if (
    capabilities.length <= 0 ||
    !capabilities.every((capability) => user.hasCapability(capability))
  ) {
    return <Redirect noThrow to="/permission" />;
  }

  return <Component {...props} />;
};
