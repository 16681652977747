import * as approvalProcessApi from "~/apps/corporate/apis/approval-process.api";
import * as approvalsApi from "~/apps/corporate/apis/approvals.api";
import * as financialApi from "~/apps/corporate/apis/financial.api";
import * as itineraryApi from "~/apps/corporate/apis/itinerary.api";
import * as offersApi from "~/apps/corporate/apis/offers.api";
import * as travelApi from "~/apps/corporate/apis/travel.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import { AddOfferJustificationRequestBody } from "~/apps/corporate/dtos/offers.dto";
import {
  EditAdministrativeInfoRequestBody,
  UpdateTravelInfoRequestBody,
} from "~/apps/corporate/dtos/travel.dto";
import { TravelApprovalStatus } from "~/apps/corporate/models/approval-status.model";
import { TravelApprovalHistory } from "~/apps/corporate/models/approvals.model";
import { TravelInvoices } from "~/apps/corporate/models/financial.model";
import { ItineraryInfo } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import {
  ItineraryDocumentPendencies,
  ItineraryPendencies,
} from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import { ItineraryServiceInfo } from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { ItineraryServices } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import {
  OfferAvailability,
  OfferTraveler,
  RepeatedOffers,
} from "~/apps/corporate/models/offer.model";
import {
  TravelApproval,
  TravelPoliciesPerOffer,
} from "~/apps/corporate/models/travel.model";
import { User } from "~/apps/corporate/models/user.model";
import * as userParser from "~/apps/corporate/parsers/user.parser";
import * as documentsApi from "~/apps/shared/apis/documents.api";
import errors, { ERROR } from "~/apps/shared/constants/errors";
import { CreateDocumentDto } from "~/apps/shared/dtos/documents.dto";
import { Document } from "~/apps/shared/models/documents.model";
import * as documentsParser from "~/apps/shared/parsers/documents.parser";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { formatServiceError } from "~/apps/shared/utils/errors";

export const addOfferJustification = async (
  offerToken: string,
  data: AddOfferJustificationRequestBody,
) => {
  const result: { error?: Error } = {};

  try {
    await offersApi.addOfferJustification(offerToken, data);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const addTravelersToOffer = async (
  offerToken: string,
  data: {
    main: number;
    userToken: string;
  }[],
) => {
  const result: { error?: Error } = {};

  try {
    await Promise.all(
      data.map((traveler) =>
        offersApi.addTravelerToOffer(offerToken, {
          main: traveler.main === 1,
          traveler_token: traveler.userToken,
        }),
      ),
    );
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const cancelApprovalProcess = async (approvalRequestToken: string) => {
  const result: { error?: Error } = {};

  try {
    await approvalProcessApi.cancelApprovalProcess(approvalRequestToken);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const checkOffersAvailabilities = async (offerTokens: string[]) => {
  const result: Record<string, Either<Error, OfferAvailability>> = {};

  const offersAvailabilities = await Promise.all(
    offerTokens.map((offerToken) =>
      offersApi.checkOfferAvailability(offerToken),
    ),
  );

  offerTokens.forEach((offerToken, index) => {
    const offerAvailability = offersAvailabilities[index];

    if (offerAvailability.isSuccess()) {
      result[offerToken] = success(offerAvailability.data);

      return;
    }

    result[offerToken] = failure(formatServiceError());
  });

  return result;
};

export const createApprovalRequest = async (
  justification: string,
  travelToken: string,
) => {
  const result: { error?: Error } = {};

  try {
    await approvalProcessApi.createApprovalRequest(justification, travelToken);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const createDocument = async (data: CreateDocumentDto) => {
  const result: { data?: Document; error?: Error } = {};

  try {
    const createDocumentRequestBody = documentsParser.parseCreateDocumentRequestBody(
      data,
    );

    result.data = await documentsApi.createDocument(createDocumentRequestBody);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const deleteDocument = async (documentToken: string) => {
  const result: { error?: Error } = {};

  try {
    await documentsApi.deleteDocument(documentToken);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (data.type === errors.ERROR_GETTING_DOCUMENT && status === 400) {
        result.error = {
          description: "Não foi possível deletar este documento",
          title: "Erro",
        };

        return result;
      }
    }

    if (error.request) {
      result.error = {
        description: "Problemas de conectividade",
        title: "Erro",
      };

      return result;
    }

    result.error = formatServiceError({ error });
  }

  return result;
};

export const deleteService = async (offerToken: string) => {
  const result: { error?: Error } = {};

  try {
    const { message, success } = await offersApi.deleteOffer(offerToken);

    if (!success) {
      result.error = {
        description: message,
        title: "Erro",
      };
    }
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const deleteTravel = async (travelToken: string) => {
  const result: { error?: Error } = {};

  try {
    const { message, success } = await travelApi.deleteTravel(travelToken);

    if (!success) {
      result.error = {
        description: message,
        title: "Erro",
      };
    }
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const downloadTravelInvoices = async (travelToken: string) => {
  const result: { data?: TravelInvoices; error?: Error } = {};

  try {
    result.data = await financialApi.downloadTravelInvoices(travelToken);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_SEND_VOUCHER_EMAIL_ERROR,
    });
  }

  return result;
};

export const editUser = async (data: User, userToken: string) => {
  const result: { error?: Error } = {};

  try {
    const editUserRequestBody = userParser.parseEditUserRequest(data);

    await userApi.editUser(editUserRequestBody, userToken);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const getItineraryInfo = async (
  travelToken: string,
): Promise<Either<Error, ItineraryInfo>> => {
  try {
    return success(await itineraryApi.getItineraryInfo(travelToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_ITINERARY_INFO,
      }),
    );
  }
};

export const getItineraryDocumentPendencies = async (
  travelToken: string,
): Promise<Either<Error, ItineraryDocumentPendencies>> => {
  try {
    return success(
      await itineraryApi.getItineraryDocumentPendencies(travelToken),
    );
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_ITINERARY_DOCUMENT_PENDENCIES,
      }),
    );
  }
};

export const getItineraryPendencies = async (
  travelToken: string,
): Promise<Either<Error, ItineraryPendencies>> => {
  try {
    return success(await itineraryApi.getItineraryPendencies(travelToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_ITINERARY_PENDENCIES,
      }),
    );
  }
};

export const getItineraryServiceInfo = async (
  offerToken: string,
): Promise<Either<Error, ItineraryServiceInfo>> => {
  try {
    return success(await itineraryApi.getItineraryServiceInfo(offerToken));
  } catch (error) {
    return failure(formatServiceError({ error }));
  }
};

export const getItineraryServices = async (
  travelToken: string,
): Promise<Either<Error, ItineraryServices>> => {
  try {
    return success(await itineraryApi.getItineraryServices(travelToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_ITINERARY_SERVICES,
      }),
    );
  }
};

export const getOffersTravelers = async (offerTokens: string[]) => {
  const result: {
    data?: Record<string, OfferTraveler[]>;
    error?: Error;
  } = {};

  try {
    const offersTravelers = await Promise.all(
      offerTokens.map(async (offerToken) => ({
        offerToken,
        travelers: await offersApi.getOfferTravelers(offerToken),
      })),
    );

    result.data = offersTravelers.reduce(
      (prev, { offerToken, travelers }) => ({
        ...prev,
        [offerToken]: travelers,
      }),
      {},
    );
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const getTravelApproval = async (travelToken: string) => {
  const result: { data?: TravelApproval; error?: Error } = {};

  try {
    result.data = await travelApi.getTravelApproval(travelToken);
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const getTravelApprovalHistory = async (
  travelToken: string,
): Promise<Either<Error, TravelApprovalHistory>> => {
  try {
    return success(await approvalsApi.getTravelApprovalHistory(travelToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_TRAVEL_APPROVAL_HISTORY,
      }),
    );
  }
};

export const getTravelApprovalStatus = async (
  travelToken: string,
): Promise<Either<Error, TravelApprovalStatus>> => {
  try {
    return success(
      await approvalProcessApi.getTravelApprovalStatus(travelToken),
    );
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_TRAVEL_APPROVAL_STATUS,
      }),
    );
  }
};

export const getTravelPoliciesPerOffer = async (
  travelToken: string,
): Promise<Either<Error, TravelPoliciesPerOffer>> => {
  try {
    return success(await travelApi.getTravelPoliciesPerOffer(travelToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_GET_TRAVEL_POLICIES_PER_OFFER,
      }),
    );
  }
};

export const getTravelRepeatedOffers = async (
  offerTokens: string[],
): Promise<Either<Error, RepeatedOffers>> => {
  try {
    return success(
      await Promise.all(
        offerTokens.map((offerToken) =>
          offersApi.checkForRepeatedOffer(offerToken),
        ),
      ),
    );
  } catch (error) {
    return failure(formatServiceError({ error }));
  }
};

export const removeTravelersFromOffer = async (
  offerToken: string,
  travelerOfferTokens: string[],
) => {
  const result: { error?: Error } = {};

  try {
    await Promise.all(
      travelerOfferTokens.map((travelerOfferToken) =>
        offersApi.removeTravelerFromOffer(offerToken, travelerOfferToken),
      ),
    );
  } catch (error) {
    result.error = formatServiceError({ error });
  }

  return result;
};

export const sendVoucherEmail = async (travelToken: string) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.sendVoucherEmail(travelToken);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_SEND_VOUCHER_EMAIL_ERROR,
    });
  }

  return result;
};

export const shareItineraryPDF = async (
  travelToken: string,
  blobUrl: string,
  email: string,
) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.shareItineraryPDF(travelToken, blobUrl, email);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_TRAVEL_ITINERARY_SHARE_ERROR,
    });
  }

  return result;
};

export const updateTravelAdministrativeInfo = async (
  data: EditAdministrativeInfoRequestBody,
  travelToken: string,
) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.updateTravelAdministrativeInfo(data, travelToken);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_TRAVEL_INFO_UPDATE_ERROR,
    });
  }

  return result;
};

export const updateTravelInfo = async (
  data: UpdateTravelInfoRequestBody,
  travelToken: string,
) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.updateTravelInfo(data, travelToken);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_TRAVEL_INFO_UPDATE_ERROR,
    });
  }

  return result;
};

export const updateTravelName = async (
  travelToken: string,
  travelName: string,
) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.updateTravelName(travelToken, travelName);
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400 && data.type === errors.ERROR_INVALID_INPUT) {
        result.error = {
          description: "Nome de viagem inválido",
          title: "Erro",
        };
      }

      if (status === 403 && data.type === errors.ERROR_NOT_ALLOWED) {
        result.error = {
          description: "Você não pode atualizar essa viagem",
          title: "Erro",
        };
      }

      if (!result.error) {
        result.error = formatServiceError({ error });
      }
    } else if (error.request) {
      result.error = {
        description: "Problemas de conectividade",
        title: "Erro",
      };
    } else {
      result.error = formatServiceError({ error });
    }
  }

  return result;
};
