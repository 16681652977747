import { css } from "@styled-system/css";
import { css as ecss } from "emotion";

import { theme } from "@skin/v2";

export const styles = {
  button: {
    avatar: css({
      height: "36px",
      width: "36px",
    }),
    icon: ({ active }: { active: boolean }) =>
      css({
        color: active ? theme.colors.gray[500] : theme.colors.gray[300],
        "& path": {
          strokeWidth: "2px",
        },
      }),
    root: css({
      alignItems: "center",
      background: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      gap: "8px",
    }),
  },
  menu: {
    item: ecss({
      padding: "8px 10px",
    }),
  },
  profile: {
    drawer: ecss({
      maxWidth: "768px",
      width: "100%",
    }),
    menu: css({
      padding: ".5rem 1.2rem",
      fontSize: 14,
      color: theme.colors.gray[300],
      transition: "color 0.2s ease",
      ["&:hover"]: {
        color: theme.colors.gray[500],
      },
    }),
  },
};
