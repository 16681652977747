import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    fontSize: "0.875rem",
    justifyContent: "center",
    width: "100%",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  form: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
  },
  header: {
    description: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      textAlign: "left",
      width: "fit-content",
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      fontFamily: theme.fonts.inter,
      gap: "4px",
      width: "100%",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      width: "fit-content",
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
