import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  handleTransmissionFilterChange: (event: any, checked: boolean) => void;
  transmissionOptions: {
    checked: boolean;
    id: string;
    label: string;
  }[];
};

export const TransmissionFilter: React.FC<Props> = ({
  handleTransmissionFilterChange,
  transmissionOptions,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Transmissão</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {transmissionOptions.map((transmissionOption) => (
          <CheckboxGroup.Item
            aria-label={transmissionOption.label}
            checked={transmissionOption.checked}
            css={styles.checkbox.group.item}
            id={transmissionOption.id}
            key={transmissionOption.id}
            onChange={(e) => {
              handleTransmissionFilterChange(e, !transmissionOption.checked);
            }}
            value={transmissionOption.checked}
          >
            <span css={styles.text}>{transmissionOption.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
