import React from "react";
import { Box, Flex, Text } from "@toolkit";
import CircularProgress from "@material-ui/core/CircularProgress";
import DownloadIcon from "@material-ui/icons/CloudDownloadRounded";
import { css } from "@styled-system/css";

const styles = {
  downloadIcon: css({
    color: "primary",
    fontSize: ["2"]
  }),
  progress: css({
    width: ["14px !important", "16px !important"],
    height: ["14px !important", "16px !important"],
    color: "primary"
  })
};

const InvoicesDownload = ({ isDownloading, handleDownload }) => (
  <Box
    mb={[0, "large"]}
    px={["medium", "large"]}
    py={["24px", "medium"]}
    borderWidth={["0px", "1px"]}
    borderBottomWidth={["1px", "1px"]}
    borderStyle={"solid"}
    borderColor="border"
    borderRadius={[0, 1]}
    css={css({ cursor: isDownloading ? "progress" : "pointer" })}
  >
    <Flex
      alignItems="center"
      justifyContent="space-between"
      onClick={handleDownload}
    >
      <Text fontSize="1" fontWeight="bold" color="primary">
        {isDownloading ? "Baixando comprovantes" : "Baixar comprovantes"}
      </Text>
      {isDownloading ? (
        <CircularProgress css={styles.progress} />
      ) : (
        <DownloadIcon css={styles.downloadIcon} />
      )}
    </Flex>
  </Box>
);

export { InvoicesDownload };
