import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  classOptions: {
    checked: boolean;
    id: string;
    label: string;
  }[];
  handleClassesFilterChange: (event: any, checked: boolean) => void;
};

export const ClassesFilter: React.FC<Props> = ({
  classOptions,
  handleClassesFilterChange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Categorias de Carros</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {classOptions.map((classOption) => (
          <CheckboxGroup.Item
            aria-label={classOption.label}
            checked={classOption.checked}
            css={styles.checkbox.group.item}
            id={classOption.id}
            key={classOption.id}
            onChange={(e) => {
              handleClassesFilterChange(e, !classOption.checked);
            }}
            value={classOption.checked}
          >
            <span css={styles.text}>{classOption.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
