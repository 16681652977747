import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    root: css({
      flex: 1,
    }),
  },
  footer: {
    root: css({
      alignItems: "center",
      animationDirection: "normal",
      animationDuration: "0.15s",
      animationName: "outer-upper-shadow-dimming",
      animationTimeline: "scroll(block nearest)",
      animationTimingFunction: "ease-in-out",
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      color: theme.colors.gray[700],
      display: "flex",
      fontSize: "1.125rem",
      fontWeight: 600,
      gap: "16px",
      justifyContent: "space-between",
      padding: "24px",
      position: "sticky",
      width: "100%",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
    "@media (max-width: 1024px)": {
      width: "calc(100dvw - 48px)",
    },
    "@media (max-width: 640px)": {
      width: "100dvw",
    },
  }),
};
