import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Link, navigate } from "@reach/router";
import { Icon } from "~/apps/shared/components/icon/icon";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useItineraryServices } from "../../../itinerary/itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useItinerary } from "../../../itinerary/itinerary.context";
import { useApprovalReview } from "../../approval-review.context";
import { useApprovalReviewDecisionGuard } from "./approval-review-decision.hooks";
import { styles } from "./styles";

export const ApprovalReviewDecision: React.FC = () => {
  const { loading } = useApprovalReviewDecisionGuard();

  const {
    infoModel,
    travelApprovalModel,
    travelApprovalHistoryModel,
    travelApprovalStatusModel,
  } = useItineraryContainer();

  const {
    approveApprovalRequest,
    denyApprovalRequest,
    isApproved,
    isDenied,
    isLoadingApproveApprovalRequest,
    isLoadingDeclineApprovalRequest,
    selectedServices,
  } = useApprovalReview();
  const { travelToken } = useItinerary();
  const {
    fetchItineraryServices,
    travelPoliciesPerOffer,
  } = useItineraryServices();

  const [isJustificatingDecline, setIsJustificatingDecline] = useState(false);
  const [justification, setJustification] = useState("");

  const closeDeclineJustification = useCallback(() => {
    setIsJustificatingDecline(false);
  }, []);

  const handleApproveApprovalRequest = useCallback(async () => {
    await approveApprovalRequest(justification);

    await fetchItineraryServices();
  }, [
    justification,
    approveApprovalRequest,
    fetchItineraryServices,
    travelToken,
  ]);

  const handleDenyApprovalRequest = useCallback(async () => {
    if (!travelApprovalStatusModel) {
      return;
    }

    const success = await denyApprovalRequest(justification);

    if (!success) {
      return;
    }

    navigate("/approvals");
  }, [denyApprovalRequest, justification, travelApprovalStatusModel]);

  const openDeclineJustification = useCallback(() => {
    setIsJustificatingDecline(true);
  }, []);

  if (loading) {
    return <ApprovalReviewDecisionSkeleton />;
  }

  if (
    !travelApprovalModel ||
    !travelApprovalHistoryModel ||
    !travelApprovalStatusModel
  ) {
    return null;
  }

  const showServicesOutOfPolicyMessage =
    !isApproved &&
    !!travelPoliciesPerOffer &&
    travelApprovalModel.hasOutOfPolicyService(travelPoliciesPerOffer);

  const lastestApprovalRecord = travelApprovalHistoryModel.getLatestApprovalRecord();

  const lastApprovalRecordRequestMessage = lastestApprovalRecord
    ? lastestApprovalRecord.getRequestMessage()
    : null;

  const travelApprovalOffersToReviewServices = travelApprovalModel.getAllOffersToReviewServices();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title({ isApproved, isDenied })}>
          {isApproved
            ? "Viagem aprovada:"
            : isDenied
            ? "Solicitação declinada:"
            : "Pedido de aprovação:"}
        </span>
        {showServicesOutOfPolicyMessage ? (
          <span css={styles.top.policy}>
            <Icon size={16} use="exclamation-triangle" />
            Há ofertas fora da política.
          </span>
        ) : null}
      </div>
      <hr css={styles.divisor} />
      {isApproved ? (
        <div css={styles.section.root}>
          <div css={styles.section.approved.root}>
            <Icon css={styles.section.approved.icon} use="check-circle" />
            <span css={styles.section.approved.message}>
              Solicitação aprovada. Encaminhada aos aprovadores finais.
            </span>
          </div>
          <Link css={styles.section.approved.link} to="/approvals">
            Voltar para minhas aprovações
          </Link>
        </div>
      ) : isDenied ? (
        <div css={styles.section.root}>
          <div css={styles.section.declined.root}>
            <Icon css={styles.section.declined.icon} use="x-circle-outline" />
            <span css={styles.section.declined.message}>
              Solicitação declinada. Um e-mail com sua resposta foi enviado
              {infoModel ? `  para ${infoModel.getBooker().name}` : null}.
            </span>
          </div>
          <Link css={styles.section.declined.link} to="/approvals">
            Voltar para minhas aprovações
          </Link>
        </div>
      ) : (
        <div css={styles.section.root}>
          {lastApprovalRecordRequestMessage ? (
            <span css={styles.section.request}>
              Justificativa enviada em{" "}
              {lastestApprovalRecord &&
                lastestApprovalRecord.getFormattedCreatedAt()}
              : &quot;
              <strong css={styles.section.strong}>
                {lastApprovalRecordRequestMessage}
              </strong>
              &quot;
            </span>
          ) : null}
          {isJustificatingDecline ? (
            <div css={styles.section.justification.root}>
              <span css={styles.section.justification.label}>
                Adicione uma mensagem para deixar{" "}
                {infoModel ? (
                  <strong css={styles.section.justification.name}>
                    {infoModel.getBooker().name}
                  </strong>
                ) : (
                  "o solicitante"
                )}{" "}
                sabendo por que seu pedido foi negado.{" "}
                <span css={styles.section.justification.optional}>
                  (opcional)
                </span>
              </span>
              <textarea
                css={styles.section.justification.textarea}
                disabled={
                  isLoadingApproveApprovalRequest ||
                  isLoadingDeclineApprovalRequest
                }
                maxLength={250}
                onChange={(e) => {
                  setJustification(e.target.value);
                }}
                value={justification}
              />
              <span css={styles.section.justification.info}>
                Máximo de 250 caracteres ({justification.length}
                /250)
              </span>
              <div css={styles.section.justification.buttons}>
                <Button
                  css={styles.section.buttons.button}
                  disabled={isLoadingDeclineApprovalRequest}
                  fill="outlined"
                  onClick={() => {
                    closeDeclineJustification();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  css={styles.section.buttons.button}
                  disabled={isLoadingDeclineApprovalRequest}
                  onClick={async () => {
                    await handleDenyApprovalRequest();
                  }}
                  variant="pink"
                >
                  {isLoadingDeclineApprovalRequest ? (
                    <CircularSpinner
                      css={{
                        color: "#FFFFFF",
                      }}
                      size={16}
                    />
                  ) : null}
                  Negar
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div css={styles.section.buttons.root}>
                {!travelApprovalStatusModel.isFinalApprover() ? (
                  <>
                    <span css={styles.section.justification.label}>
                      Adicione uma mensagem para{" "}
                      {infoModel ? (
                        <strong css={styles.section.justification.name}>
                          {infoModel.getBooker().name}
                        </strong>
                      ) : (
                        "o solicitante"
                      )}
                      .{" "}
                      <span css={styles.section.justification.optional}>
                        (opcional)
                      </span>
                    </span>
                    <textarea
                      css={styles.section.justification.textarea}
                      disabled={
                        isLoadingApproveApprovalRequest ||
                        isLoadingDeclineApprovalRequest
                      }
                      maxLength={250}
                      onChange={(e) => {
                        setJustification(e.target.value);
                      }}
                      value={justification}
                    />
                    <span css={styles.section.justification.info}>
                      Máximo de 250 caracteres ({justification.length}
                      /250)
                    </span>
                  </>
                ) : null}
                <Button
                  css={styles.section.buttons.button}
                  disabled={
                    isLoadingApproveApprovalRequest ||
                    !selectedServices ||
                    selectedServices.length === 0
                  }
                  onClick={async () => {
                    await handleApproveApprovalRequest();
                  }}
                  variant="pink"
                >
                  {isLoadingApproveApprovalRequest ? (
                    <CircularSpinner
                      css={{
                        color: "#FFFFFF",
                      }}
                      size={16}
                    />
                  ) : !selectedServices ||
                    selectedServices.length === 0 ? null : (
                    <Icon size={16} use="thumbs-up" />
                  )}
                  <span>
                    {!selectedServices || selectedServices.length === 0
                      ? "Selecione ao menos uma oferta"
                      : selectedServices.length <
                        travelApprovalOffersToReviewServices.length
                      ? "Aprovar ofertas selecionadas"
                      : "Aprovar solicitação"}
                  </span>
                </Button>
                <Button
                  css={styles.section.buttons.button}
                  disabled={isLoadingApproveApprovalRequest}
                  fill="outlined"
                  onClick={() => {
                    openDeclineJustification();
                  }}
                  variant="pink"
                >
                  <Icon size={16} use="thumbs-down" />

                  <span>Negar solicitação</span>
                </Button>
              </div>
              {travelApprovalStatusModel.isFinalApprover() ? (
                <span css={styles.section.message}>
                  Ao aprovar esta viagem, você será redirecionado para a tela de
                  pagamento para prosseguir com a reserva.
                </span>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const ApprovalReviewDecisionSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title({})}>Pedido de aprovação:</span>
      </div>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <div css={styles.section.buttons.root}>
          <Button
            css={styles.section.buttons.button}
            disabled
            onClick={async () => {
              return;
            }}
            variant="pink"
          >
            <Icon size={16} use="thumbs-up" />
            <span>Aprovar solicitação</span>
          </Button>
          <Button
            css={styles.section.buttons.button}
            fill="outlined"
            disabled
            variant="pink"
          >
            <Icon size={16} use="thumbs-down" />
            <span>Negar solicitação</span>
          </Button>
        </div>
        <Skeleton height="27px" width="100%" />
      </div>
    </div>
  );
};
