import React, { useEffect, useMemo } from "react";

import { navigate } from "@reach/router";
import { Box } from "smartrips-toolkit";

import CircularSpinner from "~/components/shared/CircularSpinner";
import Layout from "~/components/shared/Layout";
import PageTitle from "~/components/shared/PageTitle";
import { Tabs } from "~/components/shared/tabs";

import { EventProvider, useEvent } from "./event.context";
import { EventHeader } from "./header";
import { EventOverview } from "./overview";
import { EventSettings } from "./settings";
import { styles } from "./styles";

import "./styles.scss";

type Props = {
  tab?: string;
  token: string;
};

const Component: React.VFC<Props> = ({ tab, token }) => {
  const { event, isLoading } = useEvent();

  const defaultTabs = useMemo(() => {
    if (event?.viewer_info?.is_organizer) {
      return [
        {
          label: "Visão Geral",
          value: "overview"
        },
        {
          label: "Configurações",
          value: "settings"
        }
      ] as const;
    }

    return [
      {
        label: "Visão Geral",
        value: "overview"
      }
    ] as const;
  }, [event]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!event) {
      navigate("/events");

      return;
    }
  }, [event, isLoading, navigate]);

  if (!event) {
    return null;
  }

  return (
    <>
      <PageTitle title={event ? event.name : "Evento"} />
      <Layout>
        <Box css={styles.container} className="container">
          <div css={styles.root}>
            {isLoading ? (
              <div css={styles.loader}>
                <CircularSpinner />
              </div>
            ) : (
              <>
                <EventHeader event={event} />
                <Tabs
                  classes={{
                    ...styles.tabs
                  }}
                  onChange={(_, tab) => {
                    navigate(`/events/${token}/${tab}`);
                  }}
                  value={tab}
                >
                  {defaultTabs.map(tab => (
                    <Tabs.Item
                      classes={{
                        ...styles.tabs.tab
                      }}
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                {tab === "overview" ? <EventOverview event={event} /> : null}
                {tab === "settings" ? <EventSettings event={event} /> : null}
              </>
            )}
          </div>
        </Box>
      </Layout>
    </>
  );
};

export const Event: React.FC<Props> = ({ tab, token }) => {
  return (
    <EventProvider token={token}>
      <Component tab={tab} token={token} />
    </EventProvider>
  );
};
