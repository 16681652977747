import React from "react";

import { Badge } from "@toolkit/v2";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

type Props = React.ComponentProps<typeof Badge> & {
  disabled?: boolean;
};

export const OutOfPolicyBadge: React.FC<Props> = ({
  children,
  disabled,
  ...props
}) => {
  return (
    <Badge css={styles.root({ disabled })} {...props}>
      <Icon css={styles.icon} size={16} use="exclamation-triangle" />
      {children ? children : "Fora da política."}
    </Badge>
  );
};
