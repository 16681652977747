import React, {
  useState,
  ReactNode,
  ChangeEvent,
  useRef,
  useEffect,
} from "react";
import useDebounce from "react-use/lib/useDebounce";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Error } from "~/apps/shared/types";
import { sortByField } from "~/apps/shared/utils/sort-by-field";

import { ALERT_TYPES } from "@constants";

import { CostCenter, CostCenterListItem } from "@models/cost-center.model";

import * as costCentersService from "./CostCenters.service";
import { FormValues } from "./CostCenters.types";

interface State {
  costCenters: CostCenterListItem[];
  visibleCostCenters: CostCenterListItem[];
  searchInput: string;
  isFormOpen: boolean;
  selectedToEdit: CostCenter | null;
  selectedToArchive: CostCenterListItem | null;
  isLoading: boolean;
  isSubmitting: boolean;
  isArchiving: boolean;
  isToggleDialogOpen: boolean;
  snack: {
    open: boolean;
    type: string;
    message: string;
  };
}

interface Actions {
  fetchCostCenters: () => void;
  handleOpenToggleDialog: () => void;
  handleCloseToggleDialog: () => void;
  proccessToggleCostCentersStatus: () => void;
  handleOpenForm: (costCenterToken?: string) => () => void;
  handleCloseForm: () => void;
  handleCloseSnackbar: () => void;
  handleSaveCostCenter: (values: FormValues) => void;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleArchiveCostCenter: (costCenter: CostCenterListItem) => () => void;
  handleCloseArchiveDialog: () => void;
  proccessArchiveCostCenter: () => void;
}

const initialState: State = {
  costCenters: [],
  visibleCostCenters: [],
  searchInput: "",
  isFormOpen: false,
  selectedToEdit: null,
  selectedToArchive: null,
  isLoading: false,
  isSubmitting: false,
  isArchiving: false,
  isToggleDialogOpen: false,
  snack: {
    open: false,
    type: "",
    message: "",
  },
};

type ContextProps = State & Actions;

const CostCentersContext = React.createContext<ContextProps>({
  ...initialState,
  fetchCostCenters: () => null,
  handleOpenToggleDialog: () => null,
  handleCloseToggleDialog: () => null,
  proccessToggleCostCentersStatus: () => null,
  handleOpenForm: () => () => null,
  handleCloseForm: () => null,
  handleCloseSnackbar: () => null,
  handleSaveCostCenter: () => null,
  handleChangeSearch: () => null,
  handleArchiveCostCenter: () => () => null,
  handleCloseArchiveDialog: () => null,
  proccessArchiveCostCenter: () => null,
});

const { Provider, Consumer } = CostCentersContext;

const CostCentersProvider = ({ children }: { children: ReactNode }) => {
  const { clientConfig, handleUpdateCostCenterActive } = useClientConfig();

  const [state, setState] = useState(initialState);

  const setSafeState = (newState: Partial<State>) =>
    setState((prevState) => Object.assign({}, prevState, newState));

  const isFirstRender = useRef(true);

  const setErrorState = (error: Error) =>
    setSafeState({
      isLoading: false,
      isSubmitting: false,
      isArchiving: false,
      snack: {
        open: true,
        type: ALERT_TYPES.ERROR,
        message: error.description,
      },
    });

  const fetchCostCenters = async () => {
    setSafeState({ isLoading: true });

    const {
      data: costCenters,
      error,
    } = await costCentersService.listCostCenters();

    if (error) {
      return setErrorState(error);
    }

    const sortedCostCenters = costCenters!.sort(sortByField("name"));

    setSafeState({
      isLoading: false,
      costCenters: sortedCostCenters,
    });
  };

  const handleOpenToggleDialog = () => {
    setSafeState({ isToggleDialogOpen: true });
  };

  const handleCloseToggleDialog = () => {
    setSafeState({ isToggleDialogOpen: false });
  };

  const proccessToggleCostCentersStatus = async () => {
    if (!clientConfig) {
      return;
    }

    const currentStatus = clientConfig.isCompanyCostCenterEnabled();

    setSafeState({ isLoading: true });

    const { error } = await costCentersService.toggleClientCostCenters(
      clientConfig.getToken(),
      !currentStatus,
    );

    if (error) {
      setErrorState(error);
    }

    handleUpdateCostCenterActive(!currentStatus);

    setSafeState({
      isLoading: false,
      isToggleDialogOpen: false,
      snack: {
        message: currentStatus
          ? "Centros de custos desativados com sucesso"
          : "Centros de custos ativados com sucesso",
        open: true,
        type: ALERT_TYPES.SUCCESS,
      },
    });
  };

  function compareByLowerCaseFullName(itemA: any, itemB: any) {
    if (itemA.fullName.toLowerCase() < itemB.fullName.toLowerCase()) {
      return -1;
    }
    if (itemA.fullName.toLowerCase() > itemB.fullName.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const handleOpenForm = (costCenterToken?: string) => async () => {
    let selectedToEdit: CostCenter | null = null;

    if (costCenterToken) {
      const {
        data: costCenter,
        error,
      } = await costCentersService.getCostCenterByToken(costCenterToken);

      if (error) {
        return setErrorState(error);
      }

      selectedToEdit = costCenter!;
      selectedToEdit.users.sort(compareByLowerCaseFullName);
      selectedToEdit!.users = selectedToEdit!.users.sort(
        compareByLowerCaseFullName,
      );
    }

    setSafeState({
      isFormOpen: true,
      selectedToEdit,
    });
  };

  const handleCloseForm = () => {
    setSafeState({
      isFormOpen: false,
      selectedToEdit: null,
    });
  };

  const handleSaveCostCenter = (values: FormValues) => {
    if (values.token) {
      void updateCostCenter(values);
    } else {
      void createCostCenter(values);
    }
  };

  const createCostCenter = async (values: FormValues) => {
    setSafeState({ isSubmitting: true });

    const { error } = await costCentersService.createCostCenter(values);

    if (error) {
      return setErrorState(error);
    }

    setSafeState({
      isFormOpen: false,
      isSubmitting: false,
      snack: {
        open: true,
        type: ALERT_TYPES.SUCCESS,
        message: "Centro de custos criado com sucesso",
      },
    });

    void fetchCostCenters();
  };

  const updateCostCenter = async (values: FormValues) => {
    setSafeState({ isSubmitting: true });

    const { error } = await costCentersService.updateCostCenter(values);

    if (error) {
      return setErrorState(error);
    }

    setSafeState({
      isFormOpen: false,
      selectedToEdit: null,
      isSubmitting: false,
      snack: {
        open: true,
        type: ALERT_TYPES.SUCCESS,
        message: "Centro de custos editado com sucesso",
      },
    });

    void fetchCostCenters();
  };

  const handleArchiveCostCenter = (costCenter: CostCenterListItem) => () => {
    setSafeState({
      selectedToArchive: costCenter,
    });
  };

  const handleCloseArchiveDialog = () => {
    setSafeState({
      selectedToArchive: null,
    });
  };

  const proccessArchiveCostCenter = async () => {
    setSafeState({ isArchiving: true });
    const { token } = state.selectedToArchive!;

    const { error } = await costCentersService.archiveCostCenter(token);

    if (error) {
      return setErrorState(error);
    }

    const costCenters = state.costCenters.filter(
      (costCenter) => costCenter.token !== token,
    );

    setSafeState({
      isArchiving: false,
      costCenters,
      selectedToArchive: null,
      snack: {
        open: true,
        type: ALERT_TYPES.SUCCESS,
        message: "Centro de custo arquivado com sucesso",
      },
    });
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSafeState({ searchInput: e.target.value });
  };

  const updateVisibleCostCenters = () => {
    const updatedVisibleCostCenters = state.costCenters.filter((costCenter) => {
      return costCenter.name
        .toLocaleLowerCase()
        .includes(state.searchInput.toLocaleLowerCase());
    });

    setSafeState({
      visibleCostCenters: updatedVisibleCostCenters,
    });
  };

  useEffect(updateVisibleCostCenters, [state.costCenters]);

  useDebounce(
    () => {
      if (!isFirstRender.current) {
        updateVisibleCostCenters();
      } else {
        isFirstRender.current = false;
      }
    },
    300,
    [state.searchInput],
  );

  const handleCloseSnackbar = () => {
    setSafeState({
      snack: {
        open: false,
        type: "",
        message: "",
      },
    });
  };

  return (
    <Provider
      value={{
        ...state,
        fetchCostCenters,
        handleOpenToggleDialog,
        handleCloseToggleDialog,
        proccessToggleCostCentersStatus,
        handleOpenForm,
        handleCloseForm,
        handleCloseSnackbar,
        handleSaveCostCenter,
        handleChangeSearch,
        handleArchiveCostCenter,
        handleCloseArchiveDialog,
        proccessArchiveCostCenter,
      }}
    >
      {children}
    </Provider>
  );
};

export {
  CostCentersContext,
  CostCentersProvider,
  Consumer as CostCentersConsumer,
};
