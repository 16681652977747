import { AxiosResponse } from "axios";

import { ICreateSSOConfigDto, IEditSSOConfigDto } from "~/dtos/sso-config.dto";

import { CompleteSSOConfig } from "~/models/sso-config.model";

import { getAuthorizationHeader } from "../helpers/user.helper";
import { api } from "./interceptors";

export const createSSOConfig = async (
  clientToken: string,
  data: ICreateSSOConfigDto
): Promise<CompleteSSOConfig> => {
  return api
    .request({
      url: `/booking/clients/${clientToken}/sso-config`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: data
    })
    .then((response: AxiosResponse<CompleteSSOConfig>) => response.data);
};

export const getSSOConfig = async (
  clientToken: string
): Promise<CompleteSSOConfig> => {
  return api
    .request({
      url: `/booking/clients/${clientToken}/sso-config`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CompleteSSOConfig>) => response.data);
};

export const editSSOConfig = async (
  clientToken: string,
  data: IEditSSOConfigDto
): Promise<CompleteSSOConfig> => {
  return api
    .request({
      url: `/booking/clients/${clientToken}/sso-config`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: data
    })
    .then((response: AxiosResponse<CompleteSSOConfig>) => response.data);
};

export const deleteSSOConfig = async (
  clientToken: string
): Promise<{ success: boolean }> => {
  return api
    .request({
      url: `/booking/clients/${clientToken}/sso-config`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<{ success: boolean }>) => response.data);
};
