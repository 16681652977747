import moment from "moment";

import { SERVICE_TYPES, SUPPLIERS } from "@constants";

import { BusSearchCreationRequest } from "../dtos/bus.dto";
import { BusSearchForm } from "../models/bus.model";

type BusPolicyStatus = {
  outOfPolicy: boolean;
};

export function buildBusSearchData(
  formData: BusSearchForm,
  travelToken: string
) {
  const { origin, destination, type, departureDate, returnDate } = formData;
  const isOneway = type === "oneway";

  const data: BusSearchCreationRequest = {
    travel_token: travelToken,
    from_slug: origin!.slug,
    to_slug: destination!.slug,
    origin_lat: origin!.latitude,
    origin_lon: origin!.longitude,
    departure_lat: destination!.latitude,
    departure_lon: destination!.longitude,
    origin_city_name: origin!.city.name,
    origin_state_name: origin!.state.name,
    origin_state_code: origin!.state.code,
    origin_country_name: origin!.country.name,
    origin_country_code: origin!.country.code,
    destination_city_name: destination!.city.name,
    destination_state_name: destination!.state.name,
    destination_state_code: destination!.state.code,
    destination_country_name: destination!.country.name,
    destination_country_code: destination!.country.code,
    departure_date: departureDate!.format("YYYY-MM-DD"),
    oneway: isOneway
  };

  if (!isOneway && returnDate) {
    data.return_date = returnDate.format("YYYY-MM-DD");
  }

  return data;
}

export function buildBusLocations(searchInfo: any) {
  const originName = [
    searchInfo.originCityName,
    searchInfo.originStateCode
  ].join(", ");

  const originLocation = {
    city: {
      name: searchInfo.originCityName
    },
    state: {
      code: searchInfo.originStateCode,
      name: searchInfo.originStateName
    },
    country: {
      code: searchInfo.originCountryCode,
      name: searchInfo.originCountryName
    },
    latitude: searchInfo.originLat,
    longitude: searchInfo.originLon,
    name: originName,
    slug: searchInfo.fromSlug,
    label: originName
  };

  const destinationName = [
    searchInfo.destinationCityName,
    searchInfo.destinationStateCode
  ].join(", ");

  const destinationLocation = {
    city: {
      name: searchInfo.destinationCityName
    },
    state: {
      code: searchInfo.destinationStateCode,
      name: searchInfo.destinationStateName
    },
    country: {
      code: searchInfo.destinationCountryCode,
      name: searchInfo.destinationCountryName
    },
    latitude: searchInfo.departureLat,
    longitude: searchInfo.departureLon,
    name: destinationName,
    slug: searchInfo.toSlug,
    label: destinationName
  };

  return {
    originLocation,
    destinationLocation
  };
}

export function formatSegment(segment: any) {
  return {
    ...segment,
    schedule: {
      ...segment.schedule,
      time: moment(segment.schedule.time, "HH:mm:ss").format("HH:mm")
    }
  };
}

export function getTripSegments(trip: any) {
  const departureInfo = trip.parts[0].departure;
  const departure = formatSegment(departureInfo);

  const lastPartIndex = trip.parts.length - 1;
  const arrivalInfo = trip.parts[lastPartIndex].arrival;
  const arrival = formatSegment(arrivalInfo);

  return {
    departure,
    arrival
  };
}

export function getOfferSeatAvailability(tripPart: any) {
  if (tripPart) {
    return tripPart.isReservable && tripPart.availableSeats > 0;
  }

  return true;
}

export function getStretchPrice(tripParts: any) {
  const seatsInfo = tripParts.reduce((acc: any[], part: any) => {
    acc = acc.concat(part.selectedSeats);
    return acc;
  }, []);

  const seatsPrice = seatsInfo.reduce((sum: number, seat: any) => {
    sum += seat.details.price;
    return sum;
  }, 0);

  return seatsPrice;
}

export function buildBusOffer(
  tripState: any,
  pageState: any,
  searchState: any,
  policyStatus: BusPolicyStatus
) {
  const {
    selectedDepartureBusId,
    selectedReturnBusId,
    allTripsState
  } = tripState;
  const { departureTrips, returnTrips } = pageState;
  const { travelToken, busToken } = searchState.searchInfo;

  // Departure
  let departureTrip = departureTrips.find(
    (trip: any) => selectedDepartureBusId === trip.id
  );
  const departureTripPartsState =
    allTripsState[selectedDepartureBusId].tripPartsState;
  const departureTripParts = Object.keys(departureTripPartsState).map(
    tripPartId => {
      return departureTripPartsState[tripPartId];
    }
  );
  const departureTripPartIds = departureTripParts.map(item => item.id);
  const departureSelectedSeatsIds = departureTripParts.map(
    item => item.selectedSeats[0].id
  );

  const departurePrice = getStretchPrice(departureTripParts);
  departureTrip = Object.assign({}, departureTrip, {
    selectedPrice: departurePrice
  });

  // Return
  let returnSelectedSeatsIds, returnTripPartIds, returnTrip, returnPrice;
  if (selectedReturnBusId) {
    returnTrip = returnTrips.find(
      (trip: any) => selectedReturnBusId === trip.id
    );
    const returnTripPartsState =
      allTripsState[selectedReturnBusId].tripPartsState;
    const returnTripParts = Object.keys(returnTripPartsState).map(
      tripPartId => {
        return returnTripPartsState[tripPartId];
      }
    );
    returnTripPartIds = returnTripParts.map(item => item.id);
    returnSelectedSeatsIds = returnTripParts.map(
      item => item.selectedSeats[0].id
    );
    returnPrice = getStretchPrice(returnTripParts);
    returnTrip = Object.assign({}, returnTrip, { selectedPrice: returnPrice });
  }

  const price = returnTrip ? departurePrice + returnPrice : departurePrice;

  const busOffer = {
    travel_token: travelToken,
    item_token: busToken,
    type: SERVICE_TYPES.bus,
    cheapest_price: price,
    original_price: price,
    price,
    offer: {
      departureTrip,
      returnTrip
    },
    supplier: SUPPLIERS.CLICKBUS,
    search_channel: SUPPLIERS.CLICKBUS,
    outbound_seat: departureSelectedSeatsIds.join(";"),
    outbound_trip_ids: departureTripPartIds.join(";"),
    outbound_carrier: departureTrip.parts[0].travelCompany.name,
    outbound_departure_hour: parseInt(
      departureTrip.parts[0].departure.schedule.time.split(":")[0],
      10
    ),
    outbound_fare_family: departureTrip.parts[0].bus.name,
    inbound_seat: returnSelectedSeatsIds
      ? returnSelectedSeatsIds.join(";")
      : undefined,
    inbound_trip_ids: returnTripPartIds
      ? returnTripPartIds.join(";")
      : undefined,
    inbound_carrier: returnTrip
      ? returnTrip.parts[0].travelCompany.name
      : undefined,
    inbound_departure_hour: returnTrip
      ? parseInt(returnTrip.parts[0].departure.schedule.time.split(":")[0], 10)
      : undefined,
    inbound_fare_family: returnTrip ? returnTrip.parts[0].bus.name : undefined,
    e_out_of_policy: policyStatus.outOfPolicy
  };

  return busOffer;
}
