import React, { useMemo } from "react";

import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { CircularSpinner } from "@toolkit/v2";

import { useBusesPage } from "../buses.context";
import { ContentList } from "./content-list/content-list";
import { ContentSidebar } from "./content-sidebar/content-sidebar";
import { styles } from "./styles";

export const BusesContent: React.FC = () => {
  const { errorOnFetch, isFakeLoading } = useBusesPage();
  const { width: windowWidth } = useWindowSize();

  const isMobile = useMemo(() => windowWidth <= 1024, [windowWidth]);

  if (errorOnFetch) {
    return (
      <ErrorPage
        message={errorOnFetch.description}
        title={errorOnFetch.title}
      />
    );
  }

  if (isFakeLoading) {
    return (
      <div css={styles.loading}>
        <CircularSpinner />
      </div>
    );
  }

  return (
    <div css={styles.root}>
      {!isMobile ? (
        <>
          <ContentSidebar />
          <ContentList />
        </>
      ) : (
        <>
          <ContentList />
        </>
      )}
    </div>
  );
};
