import moment from "moment";

import { DOCUMENT_TYPES } from "../constants";

function getDateBreakdown(date) {
  const momentDate = moment(date);

  return [momentDate.date(), momentDate.month() + 1, momentDate.year()];
}

export function buildDocumentWithDates(document) {
  let buildedDocument = document;
  if (document.birthDate) {
    const [birthDay, birthMonth, birthYear] = getDateBreakdown(
      document.birthDate
    );

    buildedDocument = Object.assign({}, document, {
      birthDay,
      birthMonth,
      birthYear
    });
  }

  if (document.issueDate) {
    const [issueDay, issueMonth, issueYear] = getDateBreakdown(
      document.issueDate
    );

    buildedDocument.issueDay = issueDay;
    buildedDocument.issueMonth = issueMonth;
    buildedDocument.issueYear = issueYear;
  }

  if (document.expirationDate) {
    const [expirationDay, expirationMonth, expirationYear] = getDateBreakdown(
      document.expirationDate
    );

    buildedDocument.expirationDay = expirationDay;
    buildedDocument.expirationMonth = expirationMonth;
    buildedDocument.expirationYear = expirationYear;
  }

  return buildedDocument;
}

export function buildIdDocumentData(data) {
  const documentData = data;

  documentData.type = DOCUMENT_TYPES.ID;

  documentData.birthDate = buildDocumentDate(
    data.birthDay,
    data.birthMonth,
    data.birthYear
  );
  documentData.nationality = "BR";
  documentData.issuingCountry = "BR";

  return documentData;
}

export function buildRgDocumentData(data) {
  const documentData = data;

  documentData.type = DOCUMENT_TYPES.RG;
  documentData.nationality = "BR";

  return documentData;
}

export function buildPassportDocumentData(data) {
  const documentData = data;

  documentData.type = DOCUMENT_TYPES.PASSPORT;
  documentData.expirationDate = buildDocumentDate(
    data.expirationDay,
    data.expirationMonth,
    data.expirationYear
  );

  documentData.issueDate = buildDocumentDate(
    data.issueDay,
    data.issueMonth,
    data.issueYear
  );

  documentData.birthDate = buildDocumentDate(
    data.birthDay,
    data.birthMonth,
    data.birthYear
  );

  return documentData;
}

export function validateRg(documentNumber) {
  const numbersOnlyRe = /^[0-9]*$/;
  return numbersOnlyRe.test(documentNumber);
}

export function validateCpf(documentNumber) {
  const documentArray = Array.from(
    documentNumber.replace(/(\.|-|\u2000| )/g, "")
  );

  if (documentArray.every(digit => digit === documentArray[0])) {
    return false;
  }

  const firstSum = documentArray.slice(0, 9).reduce((acc, current, index) => {
    acc += current * (10 - index);
    return acc;
  }, 0);

  const firstRest = (firstSum * 10) % 11;
  const firstCheckDigit = firstRest >= 10 && firstRest <= 11 ? 0 : firstRest;

  if (firstCheckDigit == documentArray.slice(9, 10)[0]) {
    const secondSum = documentArray
      .slice(0, 10)
      .reduce((acc, current, index) => {
        acc += current * (11 - index);
        return acc;
      }, 0);

    const secondRest = (secondSum * 10) % 11;
    const secondCheckDigit =
      secondRest >= 10 && secondRest <= 11 ? 0 : secondRest;

    return secondCheckDigit == documentArray.slice(10, 11)[0];
  } else {
    return false;
  }
}

export function validateCnpj(cnpj) {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") {
    return false;
  }

  if (cnpj.length != 14) {
    return false;
  }

  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  ) {
    return false;
  }

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) {
    return false;
  }

  return true;
}

function buildDocumentDate(day, month, year) {
  const dt = new Date(+year, +month, +day);
  return dt.toISOString();
}
