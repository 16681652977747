import React, { useEffect } from "react";
import SpinnerPortal from "~/components/shared/Spinner";
import PageTitle from "~/components/shared/PageTitle";
import { Box } from "@toolkit";
import { SearchInput } from "@shared/inputs";

import {
  ExpenseAdvanceApprovalsListProvider,
  useExpenseAdvanceApprovalsListContext,
  useExpenseAdvanceApprovalsListActionsContext
} from "./ExpenseAdvanceApprovalsList.context";
import { RouteComponentProps } from "@reach/router";
import { ApprovalsList } from "./ApprovalsList";

interface Props {
  status: 1 | 99;
  title: string;
}

const ExpenseAdvanceApprovalsList: React.FC<RouteComponentProps<Props>> = ({
  status,
  title
}) => {
  const { isLoading } = useExpenseAdvanceApprovalsListContext();
  const {
    loadApprovals,
    handleChangeSearch
  } = useExpenseAdvanceApprovalsListActionsContext();

  useEffect(() => {
    loadApprovals(status!);
  }, []);

  return (
    <>
      <PageTitle title={title} />
      <SpinnerPortal visible={isLoading} />
      <Box mb="large" maxWidth={350}>
        <SearchInput
          handleChange={handleChangeSearch}
          inputProps={{
            placeholder: "Buscar por usuário os descrição"
          }}
        />
      </Box>
      <ApprovalsList />
    </>
  );
};

const ContextContaier: React.FC<RouteComponentProps<Props>> = props => (
  <ExpenseAdvanceApprovalsListProvider>
    <ExpenseAdvanceApprovalsList {...props} />
  </ExpenseAdvanceApprovalsListProvider>
);

export { ContextContaier as ExpenseAdvanceApprovalsList };
