import React, { useContext } from "react";
import { Box } from "@toolkit";

import { PanelContext } from "./panel.context";
import {
  CostCenterFilter,
  TagFilter,
  TravelerFilter,
  AreaFilter,
  ProjectFilter,
  BillingProfileFilter,
  PeriodFilter
} from "../shared/filters";
import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";

const styles = {
  filter: {
    marginRight: "1rem"
  }
};

const PanelFilters: React.FC = () => {
  const {
    costCenterOptions,
    tagOptions,
    travelerOptions,
    companyAreaOptions,
    projectOptions,
    billingProfileOptions,
    params,
    onFilterCostCenter,
    onFilterTag,
    onFilterTraveler,
    onFilterCompanyArea,
    onFilterProject,
    onFilterBillingProfile,
    onPeriodRangeApply
  } = useContext(PanelContext);

  return (
    <Box display={["none", "none", "flex"]} alignItems="center" py={24}>
      <PeriodFilter
        startYear={params.startYear}
        startMonth={params.startMonth}
        endYear={params.endYear}
        endMonth={params.endMonth}
        onApply={onPeriodRangeApply}
      />
      <PermissionVisibility permissions={["companySettings.costCenters"]}>
        <CostCenterFilter
          onApply={onFilterCostCenter}
          options={costCenterOptions}
          containerStyles={styles.filter}
        />
      </PermissionVisibility>
      {/* <PermissionVisibility permissions={["travels.tags"]}>
        <TagFilter
          onApply={onFilterTag}
          options={tagOptions}
          containerStyles={styles.filter}
        />
      </PermissionVisibility> */}
      <TravelerFilter
        onApply={onFilterTraveler}
        options={travelerOptions}
        containerStyles={styles.filter}
      />
      <PermissionVisibility permissions={["companySettings.areas"]}>
        <AreaFilter
          onApply={onFilterCompanyArea}
          options={companyAreaOptions}
          containerStyles={styles.filter}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.projects"]}>
        <ProjectFilter
          onApply={onFilterProject}
          options={projectOptions}
          containerStyles={styles.filter}
        />
      </PermissionVisibility>
      <PermissionVisibility permissions={["companySettings.billingProfiles"]}>
        <BillingProfileFilter
          onApply={onFilterBillingProfile}
          options={billingProfileOptions}
          containerStyles={styles.filter}
        />
      </PermissionVisibility>
    </Box>
  );
};

export { PanelFilters };
