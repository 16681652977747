import React from "react";

import { HotelItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { AmenitiesIcons } from "~/apps/shared/components/amenities-icons/amenities-icons";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { styles } from "./styles";

type Props = {
  servicePresenter: HotelItineraryServicePresenter;
};

export const ItineraryHotelDetailsDrawerRoom: React.FC<Props> = ({
  servicePresenter,
}) => {
  const amenities = servicePresenter.getAmenities();
  const room = servicePresenter.getRoom();

  return (
    <>
      <hr css={styles.divisor} />
      <div css={styles.root}>
        <div css={styles.top.root}>
          <span css={styles.top.name}>Foi selecionado: {room.name}</span>
          <span css={styles.top.description}>
            por {servicePresenter.getNights()} noites para{" "}
            {servicePresenter.getTotalGuests()} hóspede(s), a{" "}
            {formatCurrency(servicePresenter.getNightlyPrice())}
            /noite.
          </span>
        </div>
        <div css={styles.bottom.root}>
          <AmenitiesIcons
            breakfast={amenities.breakfast}
            cancel={amenities.refund}
            cancelationUntil={
              servicePresenter.hasFreeCancelation()
                ? servicePresenter.getFreeCancelationUntil()
                : null
            }
            css={styles.bottom.amenities}
            wifi={amenities.wifi}
          />
        </div>
      </div>
    </>
  );
};
