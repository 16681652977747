import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  }),
  section: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  }),
};
