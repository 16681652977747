import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  controls: {
    filters: css({
      flexShrink: 0,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
    }),
    search: {
      icon: css({
        color: theme.colors.gray[300],
      }),
      input: css({
        lineHeight: "24px",
        padding: "0",
        textOverflow: "ellipsis",
        width: "100%",
      }),
      root: ({ disabled }: { disabled: boolean }) =>
        css({
          alignItems: "center",
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          display: "flex",
          gap: "12px",
          opacity: !disabled ? 1 : 0.75,
          padding: "12px 16px",
          width: "100%",
        }),
    },
    select: {
      icon: css({
        display: "inline-block",
        marginRight: "12px",
      }),
      label: css({
        color: theme.colors.gray[700],
        flexShrink: 0,
        fontWeight: 400,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: theme.colors.gray[100],
        borderRadius: "8px",
        display: "flex",
        flexShrink: 0,
        transition: "all 0.15s ease-in-out",
        width: "fit-content",
        ":hover": {
          borderColor: theme.colors.gray[300],
        },
        "@media screen and (max-width: 768px)": {
          minWidth: "unset",
        },
      }),
      select: css({
        "& > :first-of-type": {
          minHeight: "auto",
          border: "none",
          padding: "14px 16px 14px 16px",
          "& > :first-of-type": {
            "& > :first-of-type": {
              alignItems: "center",
              color: theme.colors.gray[700],
              display: "flex",
              fontFamily: theme.fonts.inter,
              fontWeight: 600,
              justifyContent: "center",
              overflow: "visible",
              "@media screen and (max-width: 1024px)": {
                fontWeight: "unset",
              },
            },
          },
        },
      }),
    },
    sort: {
      icon: css({
        color: theme.colors.gray[300],
      }),
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        color: theme.colors.gray[500],
        fontWeight: 400,
        padding: "12px 16px",
        ":hover": {
          border: `1px solid ${theme.colors.gray[100]}`,
          color: theme.colors.gray[700],
        },
      }),
    },
  },
  empty: {
    root: css({
      display: "flex",
      justifyContent: "center",
      paddingTop: "calc(128px - 16px)",
      "@media (max-width: 1024px)": {
        paddingTop: "unset",
      },
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 400,
    }),
  },
  stats: {
    count: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    }),
  },
  list: {
    groups: {
      divisor: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        marginBottom: "16px",
        whiteSpace: "nowrap",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  }),
};
