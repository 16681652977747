import React, { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { RouteComponentProps } from "@reach/router";
import { css } from "emotion";

import { MEDIA_QUERIES } from "../../../constants";
import PAGE_TITLES from "../../../constants/page-titles.constants";
import { StContainedButton } from "../../shared/buttons";
import { Column } from "../../shared/layout/Column";
import { Desktop } from "../../shared/layout/Responsive";
import PageTitle from "../../shared/PageTitle";
import SpinnerPortal from "../../shared/Spinner";
import { NewReportDialog } from "../shared/NewReportDialog";
import { ReportItem } from "../shared/ReportItem";
import { ReportRemovalDialog } from "../shared/ReportRemovalDialog";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { ReportsListProvider, ReportsListContext } from "./ReportsList.context";

const styles = {
  root: css({
    width: "100%",
    ["@media (max-width: 991px)"]: {
      paddingBottom: "4rem",
    },
  }),
  inputContainer: css({
    width: 350,
  }),
  button: css({
    padding: ".5rem 1.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: "150",
    },
  }),
  noReports: css({
    paddingLeft: "1.5rem",
    paddingTop: ".5rem",
    fontSize: 18,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: ".5rem",
      paddingLeft: 0,
      fontSize: 16,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: ".5rem",
      paddingLeft: 0,
      fontSize: 15,
    },
  }),
};

const ReportsList = (_: RouteComponentProps) => {
  const {
    reports,
    loadNextReports,
    currentPage,
    totalPages,
    isLoading,
    isCreationDialogOpen,
    isSubmiting,
    isRemoving,
    isRemoveDialogOpen,
    reportToRemove,
    fetchReports,
    createReport,
    handleChangeSearch,
    handleOpenReportCreationDialog,
    handleCloseReportCreationDialog,
    handleCloseReportRemovalDialog,
    handleSelectReportToRemove,
    onReportRemoval,
  } = useContext(ReportsListContext);

  useEffect(() => {
    fetchReports();
  }, []);

  const hasNextPage = !isLoading && currentPage < totalPages;

  return (
    <div className={styles.root}>
      <PageTitle title={PAGE_TITLES.EXPENSE_REPORTS} />
      <SpinnerPortal visible={isLoading} />
      <div style={{ marginBottom: "2rem" }}>
        <div style={{ marginBottom: "1.5rem" }}>
          <StContainedButton
            isFullWidth={false}
            className={styles.button}
            onClick={handleOpenReportCreationDialog}
          >
            Criar relatório
          </StContainedButton>
        </div>
        <Desktop>
          <SearchFilterInput
            containerClasses={styles.inputContainer}
            placeholder="Busca por usuário ou descrição"
            onChange={handleChangeSearch}
          />
        </Desktop>
      </div>
      <div>
        <Column>
          {!reports.length && !isLoading ? (
            <div className={styles.noReports}>Nenhum relatório encontrado</div>
          ) : null}
          <InfiniteScroll
            pageStart={1}
            loadMore={loadNextReports}
            hasMore={hasNextPage}
            loader={
              <div key={0} className="loader">
                Loading ...
              </div>
            }
          >
            {reports.map((report) => (
              <ReportItem
                report={report}
                key={report.expenseReportToken}
                handleRemoval={handleSelectReportToRemove(report)}
              />
            ))}
          </InfiniteScroll>
        </Column>
      </div>
      <NewReportDialog
        open={isCreationDialogOpen}
        isSubmiting={isSubmiting}
        handleCreate={createReport}
        handleClose={handleCloseReportCreationDialog}
      />
      <ReportRemovalDialog
        isOpen={isRemoveDialogOpen}
        isRemoving={isRemoving}
        report={reportToRemove}
        onClose={handleCloseReportRemovalDialog}
        onRemove={onReportRemoval}
      />
    </div>
  );
};

const Container = (props: RouteComponentProps) => (
  <ReportsListProvider>
    <ReportsList {...props} />
  </ReportsListProvider>
);

export { Container as ReportsList };
