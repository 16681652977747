import React from "react";

import { MaskedInput } from "@shared/inputs";

const PhoneInput = ({ optional, ...rest }) => {
  return (
    <MaskedInput
      {...rest}
      aria-label="Telefone"
      InputLabelProps={{ shrink: true }}
      mask="phone-or-cellphone"
      maxlength={16}
    />
  );
};

export { PhoneInput };
