import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Filters } from "~/apps/shared/components/filters/filters";
import { Icon } from "~/apps/shared/components/icon/icon";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Button } from "@toolkit/v2";

import { useFlights } from "../../../flights.context";
import { formatMoreOptions, formatOptionName } from "../../../flights.helper";
import { styles } from "./styles";

type Props = {
  disabled?: boolean;
};

export const ListOrderOptions: React.FC<Props> = ({ disabled }) => {
  const {
    flightInfo,
    handleFlightOrderingClick,
    isLoading,
    orderOptions,
  } = useFlights();
  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 640, [windowWidth]);

  const formattedOrderOptions = useMemo(
    () =>
      orderOptions.map((item) => ({
        ...item,
        price: toCurrency(item.price),
      })),
    [orderOptions],
  );

  const mainOptions = useMemo(() => formattedOrderOptions.slice(0, 3), [
    formattedOrderOptions,
  ]);
  const otherOptions = useMemo(() => formattedOrderOptions.slice(3), [
    formattedOrderOptions,
  ]);

  const selectedOtherOption = useMemo(
    () => otherOptions.find((item) => item.isSelected),
    [otherOptions],
  );

  const formattedOtherOptions = useMemo(
    () =>
      !flightInfo ? [] : formatMoreOptions(otherOptions, flightInfo.oneway),
    [flightInfo, formattedOrderOptions],
  );

  const [
    selectedOtherOptionTitle,
    selectedOtherOptionSubtitle,
  ] = useMemo(() => {
    if (!selectedOtherOption) {
      return ["", ""];
    }

    return formatOptionName(selectedOtherOption);
  }, [selectedOtherOption]);

  if (isMobile) {
    return (
      <div css={styles.root}>
        <Filters
          closeOnSelect
          css={styles.filter.root({
            isMobile: true,
          })}
          disabled={disabled}
          options={mainOptions
            .map((option) => ({
              label: `${option.title} - ${option.price}`,
              value: option.type,
            }))
            .concat(
              formattedOtherOptions.map((option) => ({
                label: `${option.title} - ${option.subtitle}`,
                value: option.type,
              })),
            )}
          onChange={(value) => {
            handleFlightOrderingClick(value);
          }}
          value={
            mainOptions.find((option) => option.isSelected)?.type ||
            (selectedOtherOption ? selectedOtherOption.type : "")
          }
        />
      </div>
    );
  }

  return (
    <div css={styles.root}>
      <ul css={styles.tabs.root}>
        {mainOptions.map((option) => (
          <li css={styles.tabs.tab.root} key={option.type}>
            <Button
              css={styles.tabs.tab.button}
              disabled={disabled}
              fill={option.isSelected ? "filled" : "outlined"}
              onClick={() => {
                handleFlightOrderingClick(option.type);
              }}
              variant={option.isSelected ? "pink" : "default"}
            >
              <span css={styles.tabs.tab.title}>{option.title}</span>
              {isLoading ? (
                <Skeleton height="16px" width="32px" />
              ) : (
                <span css={styles.tabs.tab.price}>{option.price}</span>
              )}
            </Button>
            <div css={styles.tabs.tab.divider} />
          </li>
        ))}
        <Filters
          anchor="right"
          closeOnSelect
          css={styles.filter.root({
            active: selectedOtherOption
              ? selectedOtherOption.isSelected
              : false,
          })}
          disabled={disabled}
          icon={
            <div css={styles.filter.bottom.root}>
              {selectedOtherOption ? (
                <span css={styles.filter.bottom.price}>
                  {selectedOtherOption.price &&
                  selectedOtherOption.price !== "-"
                    ? selectedOtherOption.price
                    : selectedOtherOptionSubtitle}
                </span>
              ) : (
                <Icon
                  css={styles.filter.bottom.icon}
                  size={20}
                  use="adjustments-horizontal"
                />
              )}
            </div>
          }
          label={
            selectedOtherOption ? `${selectedOtherOptionTitle}` : "Mais opçoes"
          }
          options={formattedOtherOptions.map((option) => ({
            label: `${option.title} - ${option.subtitle}`,
            value: option.type,
          }))}
          onChange={(value) => {
            handleFlightOrderingClick(value);
          }}
          value={selectedOtherOption ? selectedOtherOption.type : ""}
        />
      </ul>
    </div>
  );
};
