import React from "react";

import { BillingProfileSelect } from "~/apps/corporate/components/billing-profile-select/billing-profile-select";
import { Form, useFormContext } from "~/apps/shared/components/form/form";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { styles } from "./styles";

export const UserFormBillingProfile: React.FC = () => {
  const form = useFormContext();

  return (
    <div css={styles.root}>
      <div css={styles.title.root}>
        <span css={styles.title.text}>CNPJ:</span>
        <Tooltip arrow content="Vincule o usuário a um CNPJ." />
      </div>
      <Form.Field
        name="billingProfileToken"
        render={({ value }) => (
          <BillingProfileSelect
            additionalOptions={[
              {
                label: "Acesso a todos",
                value: "null",
              },
            ]}
            defaultValue={value}
            isClearable
            onChange={({ value }) => {
              form.setValue("billingProfileToken", value);
            }}
          />
        )}
      />
    </div>
  );
};
