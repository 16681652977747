import * as yup from "yup";

const Schema = yup.object().shape({
  name: yup.string().required("Preencha o nome do adiantamento"),
  value: yup.number().required("Preencha o valor"),
  startDate: yup
    .date()
    .typeError("Preencha a data de volta")
    .required("Preencha a data de ida"),
  endDate: yup
    .date()
    .typeError("Preencha a data de volta")
    .min(yup.ref("startDate"), "Data de volta deve ser após a data de ida")
    .required("Preencha a data de volta"),
  justification: yup
    .string()
    .max(250, "A justificativa pode ter no máximo 250 caracteres")
});

export default Schema;
