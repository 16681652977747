import React, { useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { useContextFactory } from "@hooks";

import * as hotelBookingConfirmationService from "./HotelBookingConfirmation.service";
import { ConfirmationTravelers, HotelConfirmationData } from "./types";

interface HotelBookingConfirmationState {
  bookingCode: string;
  hotelData: HotelConfirmationData | {};
  isDenyDialogVisible: boolean;
  isLoading: boolean;
  isResponding: boolean;
  negotiationRequestToken: string;
  requestStatus: "UNCHANGED" | "CONFIRMED" | "DENIED";
  travelers: ConfirmationTravelers[];
}

interface HotelBookingConfirmationActions {
  fetchNegotiationData: (negotiationRequestToken: string) => void;
  handleApproveHotelBooking: (bookingCode: string) => void;
  handleChangeBookingCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseDenyDialog: () => void;
  handleDenyHotelBooking: () => void;
  handleShowDenyDialog: () => void;
}

const HotelBookingConfirmationContext = React.createContext(
  {} as HotelBookingConfirmationState,
);
const HotelBookingConfirmationActionsContext = React.createContext(
  {} as HotelBookingConfirmationActions,
);

export const useHotelBookingConfirmationContext: () => HotelBookingConfirmationState = useContextFactory(
  "HotelBookingConfirmationContext",
  HotelBookingConfirmationContext,
);
export const useHotelBookingConfirmationActionsContext: () => HotelBookingConfirmationActions = useContextFactory(
  "HotelBookingConfirmationActionsContext",
  HotelBookingConfirmationActionsContext,
);

const bookingConfirmationInitialState: HotelBookingConfirmationState = {
  bookingCode: "",
  hotelData: {},
  isDenyDialogVisible: false,
  isLoading: false,
  isResponding: false,
  negotiationRequestToken: "",
  requestStatus: "UNCHANGED",
  travelers: [],
};

export const HotelBookingConfirmationProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [bookingConfirmationState, setHotelBookingConfirmationState] = useState(
    bookingConfirmationInitialState,
  );

  const fetchNegotiationData = async (negotiationRequestToken: string) => {
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const {
      data,
      error,
    } = await hotelBookingConfirmationService.getHotelNegotiationRequest(
      negotiationRequestToken,
    );

    if (error) {
      setHotelBookingConfirmationState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      hotelData: {
        finalPrice: data.finalPrice,
        hotelEndDate: data.hotelEndDate,
        hotelInitialDate: data.hotelInitialDate,
        negotiatedPrice: data.negotiatedPrice,
        roomName: data.roomName,
      },
      isLoading: false,
      negotiationRequestToken,
      travelers: data.travelers,
    }));
  };

  const handleChangeBookingCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      bookingCode: value,
    }));
  };

  const handleApproveHotelBooking = async () => {
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isResponding: true,
    }));

    const { negotiationRequestToken, bookingCode } = bookingConfirmationState;

    const {
      error,
    } = await hotelBookingConfirmationService.confirmHotelBookingRequest(
      negotiationRequestToken,
      bookingCode,
    );

    if (error) {
      setHotelBookingConfirmationState((prev) => ({
        ...prev,
        isResponding: false,
      }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isResponding: false,
      requestStatus: "CONFIRMED",
    }));

    showSnackMessage("Reserva confirmada com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const handleDenyHotelBooking = async () => {
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isResponding: true,
    }));

    const {
      error,
    } = await hotelBookingConfirmationService.denyHotelBookingRequest(
      bookingConfirmationState.negotiationRequestToken,
    );

    if (error) {
      setHotelBookingConfirmationState((prev) => ({
        ...prev,
        isResponding: false,
      }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isResponding: false,
      requestStatus: "DENIED",
    }));

    showSnackMessage("Reserva negada com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const handleShowDenyDialog = () => {
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isDenyDialogVisible: true,
    }));
  };

  const handleCloseDenyDialog = () => {
    setHotelBookingConfirmationState((prev) => ({
      ...prev,
      isDenyDialogVisible: false,
    }));
  };

  return (
    <HotelBookingConfirmationContext.Provider value={bookingConfirmationState}>
      <HotelBookingConfirmationActionsContext.Provider
        value={{
          fetchNegotiationData,
          handleApproveHotelBooking,
          handleChangeBookingCode,
          handleCloseDenyDialog,
          handleDenyHotelBooking,
          handleShowDenyDialog,
        }}
      >
        {children}
      </HotelBookingConfirmationActionsContext.Provider>
    </HotelBookingConfirmationContext.Provider>
  );
};
