import * as encryptionApi from "~/apps/corporate/apis/encryption.api";
import { CardOwnerType } from "~/apps/shared/constants/enums";
import { hashCardData } from "~/apps/shared/utils/hash-card-data";
import { logger } from "~/apps/shared/utils/logger";
import moment from "moment";

import {
  CreateCorporateCardDto,
  CreateCorporateCardRequestBody,
  CreateCorporateCardResponse,
  EditCardResponse,
  GetUserCardsResponse,
} from "../dtos/cards.dto";
import { Card } from "../models/card.model";

export const parseCreateCorporateCardRequestBody = async (
  data: CreateCorporateCardDto,
  ownerType: CardOwnerType,
) => {
  try {
    const encryptionKey = await encryptionApi.getCardEncryptionKey();

    const hashedCardData = hashCardData(
      {
        brand: data.issuer?.brand,
        cvv: data.cvv.trim(),
        expirationDate: data.expirationDate,
        holderName: data.holderName,
        number: data.number,
      },
      encryptionKey.publicKey,
    );

    if (ownerType === CardOwnerType.CLIENT) {
      const createCorporateCardRequestBody: CreateCorporateCardRequestBody = {
        billingProfileToken: data.billingProfileToken
          ? data.billingProfileToken
          : null,
        cardHash: hashedCardData,
        description: data.description,
        encryptionToken: encryptionKey.searchToken,
        serviceTypes: {
          bus: data.enableToBus,
          car: data.enableToCar,
          flight: data.enableToFlight,
          hotel: data.enableToHotel,
          other: data.enableToOther,
          ride: data.enableToRide,
        },
      };

      return createCorporateCardRequestBody;
    }

    const createCorporateCardRequestBody: CreateCorporateCardRequestBody = {
      allowApprovers: data.allowApprovers,
      cardHash: hashedCardData,
      description: data.description,
      encryptionToken: encryptionKey.searchToken,
      serviceTypes: {
        bus: data.enableToBus,
        car: data.enableToCar,
        flight: data.enableToFlight,
        hotel: data.enableToHotel,
        other: data.enableToOther,
        ride: data.enableToRide,
      },
    };

    return createCorporateCardRequestBody;
  } catch (error) {
    logger.error(error);

    return null;
  }
};

export const parseCreateCorporateCard = (dto: CreateCorporateCardResponse) => {
  const card: Card = {
    ...dto.creditCard,
    createdAt: moment(dto.creditCard.createdAt),
    token: dto.creditCard.creditCardToken,
  };

  return card;
};

export const parseUserCards = (dto: GetUserCardsResponse) => {
  return dto.map((userCard) => {
    const card: Card = {
      ...userCard,
      allowApprovers: userCard.allowApprovers === 1,
      createdAt: moment(userCard.createdAt),
      token: userCard.creditCardToken,
    };

    return card;
  });
};
