import React, { useMemo } from "react";

import { TransmissionType } from "~/apps/corporate/models/car.model";
import { ItineraryServiceInfoModel } from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { CarItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPolicyForOffer } from "~/apps/corporate/models/travel.model";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { ItineraryServiceDetailsDrawerCreditCardInfo } from "../itinerary-service-details-drawer-credit-card-info/itinerary-service-details-drawer-credit-card-info";
import { formatSelectedAdditionals } from "./itinerary-car-details-drawer.helper";
import { styles } from "./styles";

type Props = {
  closeServiceDetailsDrawer: () => void;
  itineraryServiceInfoModel: ItineraryServiceInfoModel | null;
  policy: TravelPolicyForOffer | null;
  servicePresenter: CarItineraryServicePresenter;
};

export const ItineraryCarDetailsDrawer: React.FC<Props> = ({
  closeServiceDetailsDrawer,
  itineraryServiceInfoModel,
  policy,
  servicePresenter,
}) => {
  const allSelectedAdditionals = servicePresenter.getAllSelectedAdditionals();
  const contractFeatures = servicePresenter.getContractFeatures();

  const creditCard = useMemo(() => {
    if (!itineraryServiceInfoModel) {
      return null;
    }

    return itineraryServiceInfoModel.getCreditCard();
  }, [itineraryServiceInfoModel]);

  const dropOff = servicePresenter.getDropOff();
  const model = servicePresenter.getModel();
  const modelFeatures = servicePresenter.getModelFeatures();
  const pickUp = servicePresenter.getPickUp();

  const showDoNotWasteTimeMessage = !servicePresenter.isEmitted();

  const showOutOfPolicyInfo = useMemo(
    () => (policy ? policy.outOfPolicy : false),
    [policy],
  );

  const {
    accessory: selectedAccessoryAdditionals,
    protection: selectedProtectionAdditionals,
    service: selectedServiceAdditionals,
  } = formatSelectedAdditionals(
    {
      accessoryAdditionals: servicePresenter.getSelectedAccessoryAdditionals(),
      protectionAdditionals: servicePresenter.getSelectedProtectionAdditionals(),
      serviceAdditionals: servicePresenter.getSelectedServiceAdditionals(),
    },
    servicePresenter.getSupplier(),
  );

  return (
    <>
      <DrawerHeader onClose={closeServiceDetailsDrawer}>
        Detalhes do carro
      </DrawerHeader>
      <div css={styles.body.root}>
        <div css={styles.body.model.root}>
          <div css={styles.body.model.left.root}>
            {model.imageUrl ? (
              <img
                alt={model.description}
                css={styles.body.model.left.image}
                src={model.imageUrl}
              />
            ) : null}
          </div>
          <div css={styles.body.model.right.root}>
            <div css={styles.body.model.right.top.root}>
              <span css={styles.body.model.right.top.name}>{model.title}</span>
              <span css={styles.body.model.right.top.description}>
                {model.description}
              </span>
            </div>
            <div css={styles.body.model.right.bottom.root}>
              <div css={styles.body.model.right.bottom.features}>
                <ul css={styles.body.model.right.bottom.column.root}>
                  <li css={styles.body.model.right.bottom.column.feature.gray}>
                    <Icon size={16} use="people" />
                    <span>{modelFeatures.passengers} passageiros</span>
                  </li>
                  <li css={styles.body.model.right.bottom.column.feature.gray}>
                    <Icon size={16} use="car-door" />
                    <span>{modelFeatures.doors} portas</span>
                  </li>
                  <li css={styles.body.model.right.bottom.column.feature.gray}>
                    <Icon size={16} use="transmission" />
                    <span>
                      {modelFeatures.transmission === TransmissionType.MANUAL
                        ? "Manual"
                        : "Automático"}
                    </span>
                  </li>
                  {modelFeatures.air ? (
                    <li
                      css={styles.body.model.right.bottom.column.feature.gray}
                    >
                      <Icon size={18} use="snow" />
                      <span>Ar condicionado</span>
                    </li>
                  ) : null}
                </ul>
                <ul css={styles.body.model.right.bottom.column.root}>
                  {contractFeatures.ldw ? (
                    <li
                      css={styles.body.model.right.bottom.column.feature.green}
                    >
                      <Icon size={16} use="shield-check" />
                      <span>Proteção LDW</span>
                    </li>
                  ) : null}
                  {contractFeatures.ali ? (
                    <li
                      css={styles.body.model.right.bottom.column.feature.green}
                    >
                      <Icon size={16} use="shield-check" />
                      <span>Proteção ALI</span>
                    </li>
                  ) : null}
                  {contractFeatures.unlimitedKm ? (
                    <li
                      css={styles.body.model.right.bottom.column.feature.green}
                    >
                      <Icon size={16} use="speedometer" />
                      <span>Quilometragem livre</span>
                    </li>
                  ) : null}
                  {contractFeatures.freeCancelation ? (
                    <li
                      css={styles.body.model.right.bottom.column.feature.green}
                    >
                      <Icon size={16} use="calendar-x" />
                      <span>Cancelamento grátis</span>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr css={styles.body.divisor} />
        <div css={styles.body.location.root}>
          <div css={styles.body.location.rental.root}>
            <Icon
              css={styles.body.location.rental.icon}
              size={16}
              use="map-pin"
            />
            <div css={styles.body.location.rental.text}>
              <span>Retirada às {pickUp.time}h:</span>{" "}
              <span css={styles.body.location.rental.capitalize}>
                {pickUp.storeName.toLowerCase()}
              </span>
              ,{" "}
              <span css={styles.body.location.rental.capitalize}>
                {pickUp.address.toLowerCase()}
              </span>
              .
            </div>
          </div>
          <div css={styles.body.location.rental.root}>
            <Icon
              css={styles.body.location.rental.icon}
              size={16}
              use="map-pin"
            />
            <div css={styles.body.location.rental.text}>
              <span>Devolução às {dropOff.time}h:</span>{" "}
              {servicePresenter.hasDiffDropOff() ? (
                <>
                  <span css={styles.body.location.rental.capitalize}>
                    {dropOff.storeName.toLowerCase()}
                  </span>
                  ,{" "}
                  <span css={styles.body.location.rental.capitalize}>
                    {dropOff.address.toLowerCase()}
                  </span>
                  .
                </>
              ) : (
                <span>No mesmo local.</span>
              )}
            </div>
          </div>
        </div>
        {allSelectedAdditionals.length > 0 ? (
          <>
            <hr css={styles.body.divisor} />
            <div css={styles.body.additionals.root}>
              {selectedAccessoryAdditionals.length > 0 ? (
                <>
                  <span css={styles.body.additionals.additionals.type}>
                    Acessórios
                  </span>
                  <ul css={styles.body.additionals.additionals.root}>
                    {selectedAccessoryAdditionals.map((additional) => (
                      <li
                        css={
                          styles.body.additionals.additionals.additional.root
                        }
                        key={additional.code}
                      >
                        {additional.icon}
                        <span>
                          {additional.name}
                          {additional.shortDetails
                            ? ` (${additional.shortDetails})`
                            : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {selectedProtectionAdditionals.length > 0 ? (
                <>
                  <span css={styles.body.additionals.additionals.type}>
                    Proteções
                  </span>
                  <ul css={styles.body.additionals.additionals.root}>
                    {selectedProtectionAdditionals.map((additional) => (
                      <li
                        css={
                          styles.body.additionals.additionals.additional.root
                        }
                        key={additional.code}
                      >
                        {additional.icon}
                        <span>
                          {additional.name}
                          {additional.shortDetails
                            ? ` (${additional.shortDetails})`
                            : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {selectedServiceAdditionals.length > 0 ? (
                <>
                  <span css={styles.body.additionals.additionals.type}>
                    Serviços
                  </span>
                  <ul css={styles.body.additionals.additionals.root}>
                    {selectedServiceAdditionals.map((additional) => (
                      <li
                        css={
                          styles.body.additionals.additionals.additional.root
                        }
                        key={additional.code}
                      >
                        {additional.icon}
                        <span>
                          {additional.name}
                          {additional.shortDetails
                            ? ` (${additional.shortDetails})`
                            : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      <footer css={styles.footer.root}>
        {showOutOfPolicyInfo ? (
          <span css={styles.footer.policy}>Fora da política.</span>
        ) : null}
        <span css={styles.footer.warning}>
          Preço total previsto para {servicePresenter.getDays()} dias:
        </span>
        <span css={styles.footer.price}>
          {formatCurrency(servicePresenter.getPrice())}
        </span>
        {showDoNotWasteTimeMessage ? (
          <span css={styles.footer.warning}>
            Preços e disponibilidade podem mudar. Não perca tempo.
          </span>
        ) : null}
        {creditCard ? (
          <ItineraryServiceDetailsDrawerCreditCardInfo
            creditCard={creditCard}
          />
        ) : null}
      </footer>
    </>
  );
};
