import React, { useCallback, useEffect, useMemo, useState } from "react";

import { navigate, RouteComponentProps } from "@reach/router";
import { UserDocumentForm } from "~/apps/corporate/components/user-document-form/user-document-form";
import {
  mapUserDocumentFormDefaultValues,
  mapUserDocumentFormValuesToCreateDocumentDto,
  mapUserDocumentFormValuesToEditDocumentDto,
} from "~/apps/corporate/components/user-document-form/user-document-form.helper";
import { UserDocumentFormSchema } from "~/apps/corporate/components/user-document-form/user-document-form.schema";
import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { UserModel } from "~/apps/corporate/models/user.model";
import { Drawer, DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { FullPageLoader } from "~/apps/shared/components/full-page-loader/full-page-loader";
import { DOCUMENT_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { DocumentType } from "~/apps/shared/constants/enums";
import { Document } from "~/apps/shared/models/documents.model";
import { Option } from "~/apps/shared/types";

import { useUserData, useUserDocument } from "../users.hooks";
import { styles } from "./styles";

const onClose = (userToken: string) => {
  navigate(`/configurations/users/${userToken}/edit`);
};

type Props = RouteComponentProps<{
  documentToken?: string;
  userToken: string;
}> & {
  mode: "create" | "edit";
};

export const UsersUserDocumentForm: React.FC<Props> = ({
  documentToken,
  mode,
  userToken,
}) => {
  const { errorOnFetchUserData, isLoadingUserData, userData } = useUserData(
    userToken,
  );
  const {
    errorOnFetchUserDocument,
    isLoadingUserDocument,
    userDocument,
  } = useUserDocument(documentToken);

  const drawerTitle = useMemo(
    () => (mode === "create" ? "Novo documento" : "Editando documento"),
    [mode],
  );

  const handleClose = useCallback(() => {
    if (!userToken) {
      return;
    }

    onClose(userToken);
  }, [userToken]);

  if (errorOnFetchUserData || errorOnFetchUserDocument) {
    return null;
  }

  if (isLoadingUserData || isLoadingUserDocument) {
    return (
      <Drawer
        anchor="right"
        onClose={() => {
          handleClose();
        }}
        open
      >
        <DrawerHeader
          onClose={() => {
            handleClose();
          }}
        >
          {drawerTitle}
        </DrawerHeader>
        <div css={styles.root}>
          <FullPageLoader />
        </div>
      </Drawer>
    );
  }

  if (!userData || (mode === "edit" && !userDocument)) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      onClose={() => {
        handleClose();
      }}
      open
    >
      <DrawerHeader
        onClose={() => {
          handleClose();
        }}
      >
        {drawerTitle}
      </DrawerHeader>
      <div css={styles.root}>
        {mode === "create" ? (
          <UsersUserDocumentFormContainer
            documents={userData.documents}
            mode={mode}
            onClose={() => {
              handleClose();
            }}
            user={userData.user}
          />
        ) : userDocument ? (
          <UsersUserDocumentFormContainer
            document={userDocument}
            documents={userData.documents}
            mode={mode}
            onClose={() => {
              handleClose();
            }}
            user={userData.user}
          />
        ) : null}
      </div>
    </Drawer>
  );
};

type UsersUserDocumentFormContainerProps = {
  documents: Document[];
  onClose: () => void;
  user: UserModel;
} & (
  | {
      mode: "create";
    }
  | {
      mode: "edit";
      document: Document;
    }
);

const UsersUserDocumentFormContainer: React.FC<UsersUserDocumentFormContainerProps> = ({
  documents,
  onClose,
  user,
  ...props
}) => {
  const { createDocument, editDocument } = useDocuments();

  const [availableDocumentTypes, setAvailableDocumentTypes] = useState<
    Option<DocumentType>[]
  >([]);

  const selectedDocument = props.mode === "edit" ? props.document : null;

  const onSubmit = useCallback(
    async (values: UserDocumentFormSchema) => {
      if (props.mode === "edit" && !!selectedDocument) {
        const { documentToken } = selectedDocument;

        const sucess = await editDocument(
          mapUserDocumentFormValuesToEditDocumentDto(documentToken, values),
        );

        if (!sucess) {
          return;
        }

        onClose();

        return;
      }

      const sucess = await createDocument(
        mapUserDocumentFormValuesToCreateDocumentDto(values),
      );

      if (!sucess) {
        return;
      }

      onClose();
    },
    [createDocument, editDocument, onClose, props, selectedDocument],
  );

  useEffect(() => {
    if (selectedDocument) {
      return;
    }

    const availableDocumentTypes = Object.entries(DOCUMENT_TYPES_TRANSLATION)
      .map(([k, v]) => ({
        label: v,
        value: k as DocumentType,
      }))
      .filter(
        (documentType) =>
          !documents.find((document) => document.type === documentType.value),
      );

    setAvailableDocumentTypes(availableDocumentTypes);
  }, [documents, selectedDocument]);

  if (props.mode === "edit" && !!selectedDocument) {
    return (
      <UserDocumentForm
        defaultValues={mapUserDocumentFormDefaultValues(selectedDocument, user)}
        onCancel={() => {
          onClose();
        }}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <UserDocumentForm
      availableDocumentTypes={availableDocumentTypes}
      defaultValues={mapUserDocumentFormDefaultValues(null, user)}
      onCancel={() => {
        onClose();
      }}
      onSubmit={onSubmit}
    />
  );
};
