import { ServiceType } from "~/apps/shared/constants/enums";

import { SelectedOffer } from "./offer.model";

export interface AdditionalPriceInfo {
  allowChange: boolean;
  code: string;
  dayCount: number;
  discount: number;
  name: AdditionalsTypes | string;
  netPrice: number;
  quantity: number;
  rawPrice: number;
  selected: boolean;
  supplierCategory: string;
  supplierId: number | null;
  total: number;
}

export enum AdditionalsTypes {
  BABY_BASKET = "babyBasket",
  BOOSTER_SEAT = "boosterSeat",
  CHILD_SEAT = "childSeat",
  LOCALIZA_WAY = "localizaWay",
  MANDATORY_INSURANCE = "mandatoryInsurance",
  THIRD_PARTY_INSURANCE = "thirdPartyInsurance",
}

export interface CarAutosuggestItem {
  destinationId: number;
  label: string;
  name: string;
  type: string;
}

export interface CarDetails {
  class: string;
  features: CarFeatures;
  image: string;
  modelDescription: string;
  modelTitle: string;
  sippCode: string;
  supplierCarGroupCode: string;
  type: string;
}

export interface CarFeatures {
  abs: boolean;
  air: boolean;
  airbag: boolean;
  baggages: number;
  doors: number | null;
  passengers: number;
  steering: SteeringType | null;
  transmission: TransmissionType | null;
}

export interface CarListFilters {
  addressFilter: {
    address: string;
    lat: number;
    lng: number;
  } | null;
  airConditioningOnly: boolean;
  classesOptions: Array<{
    checked: boolean;
    id: string;
    label: string;
  }>;
  doorsOptions: Array<{
    checked: boolean;
    id: string;
    label: string;
  }>;
  inPolicyOnly: boolean;
  priceRange: [number, number];
  rentalsOptions: Array<{
    checked: boolean;
    id: string;
    label: string;
  }>;
  selectedPriceRange: [number, number];
  transmissionOptions: Array<{
    checked: boolean;
    id: string;
    label: string;
  }>;
}

export type CarOffer = {
  additionalDrivers?: {
    cpf: string | null;
    email: string;
    firstName: string;
    lastName: string;
    userToken: string;
  }[];
  carDetails: CarDetails;
  contractDetails: ContractDetails;
  dropoff: RentalLocation;
  outOfPolicy: boolean;
  pickup: RentalLocation;
  rentalDetails: RentalDetails;
  supplierInfo?: SupplierInfo;
};

export interface CarSearchInfo {
  active?: boolean;
  advanceDays: number;
  canceled: boolean;
  carToken: string;
  createdAt: string;
  diffDropoff: boolean;
  dropoffCity: string;
  dropoffContinent: string;
  dropoffCountry: string;
  dropoffDate: string;
  dropoffHour: number;
  dropoffLatitude: string;
  dropoffLongitude: string;
  dropoffMinute: number;
  dropoffPlaceId?: string;
  dropoffPlaceType?: string;
  dropoffSearch: string;
  dropoffState: string;
  id?: number;
  pickupCity: string;
  pickupContinent: string;
  pickupCountry: string;
  pickupDate: string;
  pickupHour: number;
  pickupLatitude: string;
  pickupLongitude: string;
  pickupMinute: number;
  pickupPlaceId?: string;
  pickupPlaceType?: string;
  pickupSearch: string;
  pickupState: string;
  travelToken: string;
  updatedAt: string;
}

export interface CarService {
  data: CarSearchInfo;
  selectedOffer: SelectedOffer<ServiceType.CAR>;
  type: ServiceType.CAR;
}

export enum CarSupplier {
  LOCALIZA = "LOCALIZA",
  MOVIDA = "MOVIDA",
}

export interface ContractDetails {
  accessoryAdditionals?: AdditionalPriceInfo[];
  additionalsInfo: Pick<
    AdditionalPriceInfo,
    "code" | "name" | "selected" | "supplierCategory"
  >[];
  dailyInfo: PriceInfo;
  extraHoursInfo: PriceInfo;
  features: ContractFeatures;
  feesInfo: FeeInfo[];
  finalPriceInfo: FinalPriceInfo;
  freeCancelationUntil: string;
  protectionAdditionals?: AdditionalPriceInfo[];
  serviceAdditionals?: AdditionalPriceInfo[];
}

interface ContractFeatures {
  ali: boolean;
  ldw: boolean;
  mandatoryDriverCreditCard: boolean;
  unlimitedKm: boolean;
}

export interface FeeInfo {
  name: FeeTypes;
  total: number;
}

export enum FeeTypes {
  RETURN_FEE = "returnFee",
  SERVICE_FEE = "serviceFee",
}

export interface FinalPriceInfo {
  discount: number;
  netTotal: number;
  rawTotal: number;
}

export interface PriceInfo {
  discount: number;
  netPrice: number;
  quantity: number;
  rawPrice: number;
  total: number;
}

interface RentalDetails {
  id: string;
  logo: string;
  name: string;
}

export interface RentalLocation {
  address: string;
  date: string;
  latitude: string;
  longitude: string;
  storeCode: string;
  storeName: string;
  time: string;
}

export interface RentalOption {
  address: string;
  color: string;
  id: string;
  lat: number;
  lng: number;
  logo: string;
  name: string;
  rentalId: string;
  supplier: CarSupplier;
}

// New offer format
export enum SteeringType {
  COMMON = "common",
  ELETRIC = "eletric",
  HYDRAULIC = "hydraulic",
}

export interface SupplierInfo {
  id: string;
  supplier: string;
}

export enum TransmissionType {
  AUTOMATIC = "automatic",
  MANUAL = "manual",
}
