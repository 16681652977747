import React, { useContext } from "react";
import DeletionDialog from "../../../shared/dialogs/DeletionDialog";
import { ExpensePolicyContext } from "./ExpensePolicy.context";

const PolicyDeletionDialog = () => {
  const {
    isDeleting,
    selectedPolicyToDelete,
    handleCloseDeletionDialog,
    processPolicyDeletion
  } = useContext(ExpensePolicyContext);

  const policyDescription = selectedPolicyToDelete
    ? selectedPolicyToDelete.description
    : "";

  return (
    <DeletionDialog
      open={!!selectedPolicyToDelete}
      title="Exclusão de política"
      message={
        <span>
          Deseja realmente excluir a política{" "}
          <span style={{ fontWeight: "bold" }}>{policyDescription}</span>
        </span>
      }
      handleClose={handleCloseDeletionDialog}
      handleDeletion={processPolicyDeletion}
      isDeleting={isDeleting}
    />
  );
};

export { PolicyDeletionDialog };
