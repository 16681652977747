import React, { useCallback, useRef, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Menu } from "~/apps/shared/components/menu/menu";
import { MenuItem } from "~/apps/shared/components/menu/menu-item/menu-item";
import { capabilities } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

type Props = {
  handleOpenUserProfileDrawer: () => void;
};

export const NavBarDesktopProfileMenu: React.FC<Props> = ({
  handleOpenUserProfileDrawer,
}) => {
  const { logout, user } = useUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuAnchorRef = useRef<HTMLDivElement | null>(null);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const handleNavigateToReviews = useCallback(() => {
    navigate("/user-reviews");
  }, []);

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleOpenUserProfile = useCallback(() => {
    handleOpenUserProfileDrawer();

    setIsMenuOpen(false);
  }, [handleOpenUserProfileDrawer]);

  return (
    <div ref={menuAnchorRef}>
      <button css={styles.button.root} onClick={handleOpenMenu}>
        {user ? (
          <Avatar css={styles.button.avatar} name={user.getFirstName()} />
        ) : null}
        <Icon
          css={styles.button.icon({
            active: isMenuOpen,
          })}
          size={16}
          use="chevron-down"
        />
      </button>
      <Menu
        anchorEl={menuAnchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        getContentAnchorEl={null}
        onClose={handleCloseMenu}
        open={isMenuOpen}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <MenuItem
          classes={{ root: styles.menu.item }}
          css={styles.profile.menu}
          onClick={() => {
            handleOpenUserProfile();
          }}
        >
          Meu perfil
        </MenuItem>
        <CapabilityProtectedRender capabilities={[capabilities.REVIEWS]}>
          <MenuItem
            classes={{ root: styles.menu.item }}
            css={styles.profile.menu}
            onClick={() => {
              handleNavigateToReviews();
            }}
          >
            Minhas avaliações
          </MenuItem>
        </CapabilityProtectedRender>
        <MenuItem
          classes={{ root: styles.menu.item }}
          css={styles.profile.menu}
          onClick={async () => {
            await handleLogout();
          }}
        >
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
};
