import { UserModel } from "~/apps/corporate/models/user.model";

export const canUserCreateTag = (
  newTagName: string,
  tagsAreCreatedByAdminsOnly: boolean,
  user: UserModel,
) => {
  if (!newTagName) {
    return false;
  }

  if (!tagsAreCreatedByAdminsOnly) {
    return true;
  }

  return user.hasConfigurationsCapability();
};
