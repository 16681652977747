import * as analyticsApi from "../../apis/analytics.api";
import * as financialApi from "../../apis/financial.api";
import { ERROR } from "../../constants";
import { GetServicesInvoiceListResponseBody } from "../../dtos/financial.dto";
import { SmartripsInvoiceItem } from "../../models/financial.model";
import { CustomError } from "../../types";
import { getDayMonthAndYearFromDate } from "./Financial.utils";

export async function getServicesInvoiceList(
  startDate: string | null,
  endDate: string | null,
  type: string,
  page: number,
  search = "",
  tag = ""
): Promise<{ data?: GetServicesInvoiceListResponseBody; error?: CustomError }> {
  const result: {
    data?: GetServicesInvoiceListResponseBody;
    error?: CustomError;
  } = {};

  try {
    result.data = await financialApi.getServicesInvoiceList(
      startDate,
      endDate,
      type,
      page,
      search,
      tag
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_INVOICE_SERVICES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_INVOICE_SERVICES_ERROR;
    }
  }

  return result;
}

export async function getSmartripsServicesInvoiceList(
  startDate: string | null,
  endDate: string | null
): Promise<{ data?: SmartripsInvoiceItem[]; error?: CustomError }> {
  const result: { data?: SmartripsInvoiceItem[]; error?: CustomError } = {};

  try {
    result.data = await financialApi.getSmartripsServicesInvoiceList(
      startDate,
      endDate
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_SMARTRIPS_INVOICE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_SMARTRIPS_INVOICE_ERROR;
    }
  }

  return result;
}

export async function downloadTrasactionsInvoices(
  transactionTokens: string[]
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    const requestData = { transactions: transactionTokens };
    await financialApi.downloadTransactionsInvoices(requestData);
  } catch (error) {
    result.error = ERROR.UNEXPECTED_TRANSACTIONS_INVOICES_DOWNLOAD_ERROR;
  }

  return result;
}

export async function downloadFinancialStatement(
  startDate: string | null,
  endDate: string | null,
  tag: string
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await financialApi.downloadFinancialStatement(startDate, endDate, tag);
  } catch (error) {
    result.error = ERROR.UNEXPECTED_FINACIAL_STATEMENT_DOWNLOAD_ERROR;
  }

  return result;
}

//TODO!: verificar
export async function downloadConciliationTable(
  startDate: string | null,
  endDate: string | null
): Promise<any> {
  const {
    day: startDay,
    month: startMonth,
    year: startYear
  } = getDayMonthAndYearFromDate(startDate);
  const {
    day: endDay,
    month: endMonth,
    year: endYear
  } = getDayMonthAndYearFromDate(endDate);
  const config = {
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear
  };

  // const fileName = "relatorio-viagens-" + month + " - " + year;
  const fileName = `relatorio-viagens-${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`;

  const result: { error?: CustomError } = {};
  try {
    await analyticsApi.downloadConciliationsReport(fileName, config);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }
  return result;
}
