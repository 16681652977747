import React, { useRef, useState } from "react";

import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import { AvatarGroup } from "~/apps/shared/components/avatar-group/avatar-group";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { styles } from "./styles";
import { UserAutoCompletePopover } from "./user-auto-complete-popover/user-auto-complete-popover";

type ApproverAvatarGroupAvatar = {
  email?: string;
  id?: string;
  name?: string;
  token?: string;
};

type Props = {
  addApprover: (data: {
    email: string;
    fullName: string;
    userToken: string;
  }) => void;
  fields: ApproverAvatarGroupAvatar[];
  isSingleApprover?: boolean;
  isUniqueApprovalCreation?: boolean;
  removeApprover: (index: number) => void;
  ruleIndex: number;
};

export const ApproverAvatarGroup: React.FC<Props> = ({
  addApprover,
  fields,
  isSingleApprover,
  isUniqueApprovalCreation,
  removeApprover,
  ruleIndex,
}) => {
  const [isAddingApprovers, setIsAddingApprovers] = useState(false);

  const containerEl = useRef<HTMLDivElement>(null);

  const toggleIsAddingApprovers = () =>
    setIsAddingApprovers((prevState) => !prevState);

  const avatarIndex = fields.length + 1;

  const isNoApprover = fields.length === 0;

  const isUniqueApprovalCreationAndNotApprover =
    isUniqueApprovalCreation && isNoApprover;

  const showAddApproverButton = isUniqueApprovalCreation
    ? isUniqueApprovalCreationAndNotApprover
    : !isSingleApprover;

  return (
    <div ref={containerEl}>
      <AvatarGroup max={11}>
        {fields.map((field, index) => (
          <Tooltip
            arrow
            content={field.name ? field.name : ""}
            key={field.id}
            position="bottom"
          >
            <div css={styles.avatars.avatar.root}>
              {!isSingleApprover ? (
                <button
                  css={styles.avatars.avatar.remove({ zIndex: avatarIndex })}
                  onClick={() => removeApprover(index)}
                  type="button"
                >
                  <Icon size={10} use="x" />
                </button>
              ) : null}
              <Avatar
                css={styles.avatars.avatar.avatar({ zIndex: avatarIndex })}
                name={field.name}
              />
            </div>
          </Tooltip>
        ))}
        {showAddApproverButton ? (
          <Tooltip arrow content="Atribuir" position="top">
            <button
              css={styles.avatars.add}
              onClick={() => {
                toggleIsAddingApprovers();
              }}
              type="button"
            >
              {fields.length > 0 ? (
                <PersonAddOutlinedIcon fontSize="small" />
              ) : (
                <GroupAddOutlinedIcon fontSize="small" />
              )}
            </button>
          </Tooltip>
        ) : null}
      </AvatarGroup>
      <UserAutoCompletePopover
        containerEl={containerEl}
        approversVisible={isAddingApprovers}
        toggleApproversVisibility={toggleIsAddingApprovers}
        actionAddApprover={addApprover}
        ruleIndex={ruleIndex}
      />
    </div>
  );
};
