import React from "react";

import { Drawer } from "~/apps/shared/components/drawer/drawer";

import { useAreas } from "../areas.context";
import { AreaForm } from "./area-form/area-form";

export const AreaDrawer: React.FC = () => {
  const { drawerOpen, handleCloseDrawer } = useAreas();

  return (
    <Drawer open={drawerOpen} onClose={handleCloseDrawer}>
      <AreaForm handleCloseDrawer={handleCloseDrawer} />
    </Drawer>
  );
};
