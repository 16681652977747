import {
  getAuthorizationHeader,
  getLoggedUserToken,
  getClientToken
} from "@helpers/user.helper";

import { api } from "./interceptors";

export function getClientTags() {
  return api
    .request({
      url: `/booking/tags/search`,
      method: "GET",
      headers: getAuthorizationHeader(),
      data: { client_token: getClientToken() }
    })
    .then(response => {
      return response.data;
    });
}

export function getUserTravelTags() {
  return api
    .request({
      url: `/booking/users/${getLoggedUserToken()}/tags`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function getTravelTags(travelToken) {
  return api
    .request({
      url: `/booking/travels/${travelToken}/tags`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function createNewTag(tagName) {
  return api
    .request({
      url: `/booking/tags`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: {
        name: tagName,
        user_token: getLoggedUserToken(),
        client_token: getClientToken()
      }
    })
    .then(response => {
      return response.data;
    });
}

export function editTag(tagName, tagToken) {
  return api
    .request({
      url: `/booking/tags/${tagToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: { name: tagName }
    })
    .then(response => {
      return response.data;
    });
}

export function createTravelTag(tagToken, travelToken) {
  return api
    .request({
      url: `/booking/travels/${travelToken}/tags/${tagToken}/travel_tags`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function removeTravelTagToken(travelTagToken) {
  return api.request({
    url: `/booking/${travelTagToken}/travel_tags`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
}

export function getAllClientTags() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/tags`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function archiveTag(tagToken) {
  return api.request({
    url: `/booking/tags/${tagToken}/archive`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
}

export function unarchiveTag(tagToken) {
  return api.request({
    url: `/booking/tags/${tagToken}/unarchive`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
}

export function getClientTagOptions() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/tag-options`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}
