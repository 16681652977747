import React, {
  ComponentType,
  CSSProperties,
  useEffect,
  useState
} from "react";
import { Flex, Box } from "@toolkit";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/CloseRounded";
import RotateLeftIcon from "@material-ui/icons/RotateLeftRounded";
import RotateRightIcon from "@material-ui/icons/RotateRightRounded";
import { css } from "emotion";

import { rotateReceipt } from "@helpers/expense.helper";

const styles = {
  rootDiv: css({
    display: "flex",
    position: "relative",
    flexDirection: "column"
  }),
  minimizedImage: css({
    width: "100%",
    maxHeight: 400,
    borderRadius: 4,
    cursor: "pointer"
  }),
  maxedImage: css({
    width: "100%",
    cursor: "pointer"
  }),
  dialogPaper: css({
    maxWidth: "none",
    background: "none",
    borderRadius: 0,
    boxShadow: "none",
    position: "relative",
    ["@media (max-width: 400px)"]: {
      margin: 20
    }
  }),
  closeIcon: css({
    marginBottom: ".5rem",
    fontSize: 32,
    color: "#FFF",
    cursor: "pointer",
    position: "absolute",
    top: 8,
    right: 8
  }),
  iconButton: css({
    borderRadius: 8,
    backgroundColor: "rgba(0, 0, 0, 0.05)"
  })
};

interface RotationButtonProps {
  icon: ComponentType;
  tooltip: string;
  onClick(): void;
}

const RotationButton: React.FC<RotationButtonProps> = ({
  icon: Icon,
  tooltip,
  onClick
}) => (
  <Tooltip title={tooltip} placement="bottom">
    <IconButton onClick={onClick} className={styles.iconButton}>
      <Icon />
    </IconButton>
  </Tooltip>
);

interface Props {
  imageUrl: string;
  rootStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  dialogStyle?: CSSProperties;
}

const ImageViewer = ({
  imageUrl,
  rootStyle,
  imageStyle,
  dialogStyle
}: Props) => {
  const [imgUrl, setImgUrl] = useState(imageUrl ? imageUrl : "");
  const [rotation, setRotation] = useState(0);
  const [maxed, setMaxed] = useState(false);
  const toogleVisibility = () => setMaxed(prevState => !prevState);

  function handleRotateRight() {
    const rotationDegrees = rotation + 90;
    setRotation(rotationDegrees >= 360 ? 0 : rotationDegrees);
  }

  function handleRotateLeft() {
    const rotationDegrees = rotation - 90;
    setRotation(rotationDegrees < 0 ? 270 : rotationDegrees);
  }

  useEffect(() => {
    async function rotate() {
      const rotatedImg = await rotateReceipt(imageUrl, rotation);
      setImgUrl(rotatedImg);
    }

    rotate();
  }, [rotation, imageUrl]);

  return (
    <div className={styles.rootDiv} style={rootStyle}>
      <Flex alignItems="center" height={400}>
        <img
          src={imgUrl}
          style={imageStyle}
          className={styles.minimizedImage}
          onClick={toogleVisibility}
        />
      </Flex>
      <Flex
        mt="medium"
        mb={["medium", 0]}
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
      >
        <RotationButton
          icon={RotateLeftIcon}
          tooltip="Virar para esquerda"
          onClick={handleRotateLeft}
        />
        <Box px="small" />
        <RotationButton
          icon={RotateRightIcon}
          tooltip="Virar para direita"
          onClick={handleRotateRight}
        />
      </Flex>
      <Dialog
        open={maxed}
        onClose={toogleVisibility}
        style={dialogStyle}
        classes={{
          paper: styles.dialogPaper
        }}
      >
        <CloseIcon className={styles.closeIcon} onClick={toogleVisibility} />
        <img src={imgUrl} className={styles.maxedImage} />
      </Dialog>
    </div>
  );
};

export { ImageViewer };
