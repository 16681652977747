import { css } from "@styled-system/css";
import { CountryRestrictionType } from "~/apps/shared/constants/enums";

import { theme } from "@skin/v2";

const DEFAULT_COLORS = {
  [CountryRestrictionType.BLOCK_ALL]: theme.colors.pink[700],
  [CountryRestrictionType.BLOCK_ABROAD]: theme.colors.gray[500],
  [CountryRestrictionType.ALLOW_WITH_WARNING]: theme.colors.green[500],
} as const;

export const styles = {
  badge: ({ restrictionType }: { restrictionType: CountryRestrictionType }) =>
    css({
      backgroundColor: DEFAULT_COLORS[restrictionType],
      borderColor: DEFAULT_COLORS[restrictionType],
      borderRadius: "4px",
      padding: "4px 8px",
      ":hover": {
        backgroundColor: DEFAULT_COLORS[restrictionType],
        borderColor: DEFAULT_COLORS[restrictionType],
      },
    }),
  country: {
    flag: css({
      borderRadius: "4px",
      height: "16px",
      width: "24px",
    }),
    root: css({
      display: "flex",
      gap: "8px",
    }),
  },
  left: css({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  }),
  right: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.background.gray,
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
  }),
};
