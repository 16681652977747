import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { DOCUMENT_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { Document } from "~/apps/shared/models/documents.model";

import { styles } from "./styles";

type Props = {
  document: Document;
  onClick: (event: React.MouseEvent<HTMLElement>, document: Document) => void;
};

export const UserDocumentCard: React.FC<Props> = ({ document, onClick }) => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.bottom.name}>
          {DOCUMENT_TYPES_TRANSLATION[document.type]}
        </span>
        <button
          css={styles.top.button}
          onClick={(event) => {
            onClick(event, document);
          }}
          type="button"
        >
          <Icon size={16} use="ellipsis-vertical" />
        </button>
      </div>
      <div css={styles.bottom.root}>
        <span css={styles.bottom.name}>
          {document.firstName} {document.lastName}
        </span>
        <span css={styles.bottom.code}>{document.documentNumber}</span>
      </div>
    </div>
  );
};
