import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    overflowX: "auto",
    padding: "0 16px",
    "::-webkit-scrollbar": {
      display: "none",
    },
  }),
  skeleton: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
    padding: "16px",
  }),
  tab: ({ active }: { active: boolean }) =>
    css({
      backgroundColor: "transparent",
      border: "none",
      color: active ? theme.colors.gray[700] : theme.colors.gray[300],
      flexShrink: 0,
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      fontWeight: active ? 600 : 500,
      padding: "16px 8px",
      transition: "color 0.15s ease-in-out",
      ":disabled": {
        opacity: 0.75,
      },
      ":hover": {
        color: theme.colors.gray[700],
      },
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
};
