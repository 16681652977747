import React, { MouseEvent, useMemo, useState } from "react";
import { MonthPicker } from "../MonthPicker";
import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import { getFormattedMonthPickerPeriod } from "../helper";

interface IDateRange {
  startSelectedMonth: number;
  startSelectedYear: number;
  endSelectedMonth: number;
  endSelectedYear: number;
}

interface IProps {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  onApply(dateRange: IDateRange): void;
}

const PeriodFilter: React.FC<IProps> = ({
  startMonth,
  startYear,
  endMonth,
  endYear,
  onApply
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const period = useMemo(() => {
    return getFormattedMonthPickerPeriod({
      startMonth,
      startYear,
      endMonth,
      endYear
    });
  }, [startMonth, startYear, endMonth, endYear]);

  function onFilterOpen(e: MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget);
  }

  function onFilterClose() {
    setAnchorEl(null);
  }

  function onApplyAndClose(dateRange: IDateRange) {
    onApply(dateRange);
    setAnchorEl(null);
  }

  return (
    <FilterButtonPopover
      anchorEl={anchorEl}
      isFilterSelected
      isOpen={!!anchorEl}
      onButtonClick={onFilterOpen}
      onPopoverClose={onFilterClose}
      selectedButtonText={period}
      unselectedButtonText={"Este filtro sempre está selecionado"}
      containerStyle={{ marginRight: "1rem" }}
    >
      <MonthPicker
        isClearEnabled={false}
        onRangeApply={onApplyAndClose}
        startYear={startYear}
        startMonth={startMonth - 1}
        endYear={endYear}
        endMonth={endMonth - 1}
      />
    </FilterButtonPopover>
  );
};

export { PeriodFilter };
