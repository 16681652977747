import { MONTH_MAP } from "~/apps/shared/constants";
import { api } from "~/apps/shared/services/api";
import { forceFileDownload } from "~/apps/shared/utils/force-file-download";

import {
  AnalyticsGenericRequest,
  GetBudgetsPerformanceRequest,
  GetFlightsAnalyticsInfoResponse,
  GetGeneralStaticDataRequest,
  GetGeneralStaticDataResponse,
  GetGeneralTransactedDataRequest,
  GetGeneralTransactedDataResponse,
  GetHotelsAnalyticsInfoResponse,
  GetRealTimeTravelsDataRequest,
  GetRealTimeTravelsDataResponse,
  GetSpentByTravelerResponse,
} from "../dtos/analytics.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  AlterData,
  BudgetsPerformanceData,
  CancelFee,
  Expenses,
  GeneralStaticData,
  GeneralTransactedData,
  PlannedSpentData,
  PlannedSpentDto,
  Savings,
  ValueData,
  ValueDto,
  Wastes,
} from "../models/analytics.model";
import * as analyticsParser from "../parsers/analytics.parser";

const downloadReport = async (fileName: string, data: any, path: string) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      responseType: "blob",
      url: `/booking/clients/${getClientToken()}/analytics/table/${path}`,
    })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));

      forceFileDownload(url, `${fileName}.xlsx`);
    });
};

export function downloadConciliationsReport(fileName: string, config: any) {
  const path = "conciliations";

  return downloadReport(fileName, config, path);
}

export function downloadCancelationsReport(fileName: string, config: any) {
  const path = "cancelations";

  return downloadReport(fileName, config, path);
}

export function downloadFlightsReport(fileName: string, config: any) {
  const path = "flights";

  return downloadReport(fileName, config, path);
}

export function downloadFlightsPolicyReport(fileName: string, config: any) {
  const path = "flights-policy";

  return downloadReport(fileName, config, path);
}

export function downloadHotelsReport(fileName: string, config: any) {
  const path = "hotels";

  return downloadReport(fileName, config, path);
}

export function downloadHotelsPolicyReport(fileName: string, config: any) {
  const path = "hotels-policy";

  return downloadReport(fileName, config, path);
}

export function downloadCarsReport(fileName: string, config: any) {
  const path = "cars";

  return downloadReport(fileName, config, path);
}

export function downloadCarsPolictReport(fileName: string, config: any) {
  const path = "cars-policy";

  return downloadReport(fileName, config, path);
}

export function downloadBusesReport(fileName: string, config: any) {
  const path = "buses";

  return downloadReport(fileName, config, path);
}

export function downloadTravelersReport(fileName: string, config: any) {
  const path = "travelers";

  return downloadReport(fileName, config, path);
}

export function downloadExpensesReport(fileName: string, config: any) {
  const path = "expenses";

  return downloadReport(fileName, config, path);
}

export function downloadPendingExpensesReport(fileName: string, config: any) {
  const path = "pending-expenses";

  return downloadReport(fileName, config, path);
}

export function downloadFlightChangesReport(fileName: string, config: any) {
  const path = "flight-changes";

  return downloadReport(fileName, config, path);
}

export function downloadApprovalsReport(fileName: string, config: any) {
  const path = "approvals";

  return downloadReport(fileName, config, path);
}

export function downloadTravelFees(fileName: string, config: any) {
  const path = "travel-fees";

  return downloadReport(fileName, config, path);
}

export async function getAlterDifference(data: AnalyticsGenericRequest) {
  return await api
    .request<AlterData[]>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/alter-difference`,
    })
    .then(({ data }) => data);
}

export async function getAlterFee(data: AnalyticsGenericRequest) {
  return await api
    .request<AlterData[]>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/alter-fee`,
    })
    .then(({ data }) => data);
}

export async function getBudgetsPerformance(
  data: GetBudgetsPerformanceRequest,
): Promise<BudgetsPerformanceData> {
  return await api
    .request<BudgetsPerformanceData>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/budgets`,
    })
    .then(({ data }) => data);
}

export async function getCancelFee(data: AnalyticsGenericRequest) {
  return await api
    .request<CancelFee>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/cancel-fee`,
    })
    .then(({ data }) => data);
}

export async function getFlightsAnalyticsInfo(data: AnalyticsGenericRequest) {
  return await api
    .request<GetFlightsAnalyticsInfoResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/flight-info`,
    })
    .then(({ data }) => analyticsParser.parseFlightsAnalyticsInfo(data));
}

export async function getGeneralStaticData(
  data: GetGeneralStaticDataRequest,
): Promise<GeneralStaticData> {
  return await api
    .request<GetGeneralStaticDataResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/general-info`,
    })
    .then(({ data }) => {
      return {
        averageAdvance: data.averageAdvance,
        generatedAt: data.generatedAt,
        totalPriceTravels: data.totalPriceTravels,
        totalTravels: data.totalTravels,
        travelInfo: data.travelInfo,
        travelPolicyAdoption: data.travelPolicyAdoption,
        travelPolicyAdoptions: data.travelPolicyAdoptions.map((item) => {
          return {
            name:
              MONTH_MAP[item.month] +
              "/" +
              item.year.toString().slice(2, 4) +
              ":" +
              item.percentage +
              "%",
            inPolicy: item.inPolicy,
            month: item.month,
            noPolicy: item.noPolicy,
            outOfPolicy: item.outOfPolicy,
            percentage: item.percentage,
            total: item.total,
            year: item.year,
          };
        }),
      };
    });
}

export async function getGeneralTransactedData(
  data: GetGeneralTransactedDataRequest,
): Promise<GeneralTransactedData> {
  return await api
    .request<GetGeneralTransactedDataResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/transacted`,
    })
    .then(({ data }) => {
      return {
        chartData: data.chartData.map((item) => ({
          bus: item.bus ? item.bus : null,
          car: item.car ? item.car : null,
          flight: item.flight ? item.flight : null,
          hotel: item.hotel ? item.hotel : null,
          insurance: item.insurance ? item.insurance : null,
          name: MONTH_MAP[item.month] + "/" + item.year.toString().slice(2, 4),
          total: item.total,
        })),
        generalExpenses: data.generalExpenses,
        types: data.types,
      };
    });
}

export async function getHotelsAnalyticsInfo(data: AnalyticsGenericRequest) {
  return await api
    .request<GetHotelsAnalyticsInfoResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/hotel-info`,
    })
    .then(({ data }) => analyticsParser.parseHotelsAnalyticsInfo(data));
}

export async function getPlannedSpentRequest(
  data: AnalyticsGenericRequest,
): Promise<PlannedSpentData> {
  const mapDtoToData = (item: ValueDto): ValueData => {
    return {
      month: item.month,
      name: MONTH_MAP[item.month] + "/" + item.year.toString().slice(2, 4),
      total: item.total,
      year: item.year,
    };
  };

  return await api
    .request<PlannedSpentDto>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/planned-spent`,
    })
    .then(({ data }) => {
      return {
        plannedValues: data.plannedValues.map(mapDtoToData),
        spentValues: data.spentValues.map(mapDtoToData),
      };
    });
}

export async function getRealTimeTravelsData(
  data: GetRealTimeTravelsDataRequest,
) {
  return await api
    .request<GetRealTimeTravelsDataResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/ongoing`,
    })
    .then(({ data }) => analyticsParser.parseRealTimeTravelsData(data));
}

export async function getSavings(data: AnalyticsGenericRequest) {
  return await api
    .request<Savings[]>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/policy-economy`,
    })
    .then(({ data }) => {
      return data.map((item) => ({
        ...item,
        name: MONTH_MAP[item.months] + "/" + item.years.toString().slice(2, 4),
      }));
    });
}

export async function getSpentByTraveler(data: AnalyticsGenericRequest) {
  return await api
    .request<GetSpentByTravelerResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/traveler-spent`,
    })
    .then(({ data }) =>
      data.map((item) => ({
        averageSpent: item.average_spent,
        minimunSpent: item.minimun_spent,
        totalSpent: item.total_spent,
        traveler: item.traveler,
        travelerToken: item.traveler_token,
      })),
    );
}

export async function getTotalSpents(data: AnalyticsGenericRequest) {
  return await api
    .request<Expenses[]>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/expense-type-spent`,
    })
    .then(({ data }) => data);
}

export async function getWastes(data: AnalyticsGenericRequest) {
  return await api
    .request<Wastes[]>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/analytics/policy-potential-economy`,
    })
    .then(({ data }) => {
      return data.map((item) => ({
        ...item,
        name: MONTH_MAP[item.months] + "/" + item.years.toString().slice(2, 4),
      }));
    });
}
