import React, { useState } from "react";

import { createTheme, MuiThemeProvider, Tooltip } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { css } from "emotion";

const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        top: "0.5rem",
        left: "-0.25rem"
      }
    }
  }
});

const styles = {
  copyIdIcon: css({
    fontSize: "1rem",
    marginRight: "0.5rem",
    marginTop: "0.375rem",
    cursor: "pointer"
  }),
  tooltip: css({
    fontSize: 14
  })
};

interface ICopyTextProps {
  text: string;
}

const CopyText = ({ text }: ICopyTextProps) => (
  <span className={styles.tooltip}>{text}</span>
);

interface ICopyIdIconProps {
  id: string;
}

export const CopyIdIcon = ({ id }: ICopyIdIconProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ): Promise<void> => {
    e.stopPropagation();
    return navigator.clipboard.writeText(id);
  };

  const handleCopyClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    await copyTextToClipboard(e);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title={
            isCopied ? <CopyText text="Copiado!" /> : <CopyText text="Copiar" />
          }
          placement="top"
          arrow
        >
          <span onClick={handleCopyClick}>
            <CopyIcon className={styles.copyIdIcon} />
          </span>
        </Tooltip>
      </MuiThemeProvider>
    </MuiThemeProvider>
  );
};
