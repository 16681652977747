import React, { createRef, useCallback, useEffect, useState } from "react";

import RcSlider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const RcRange = createSliderWithTooltip((RcSlider as any).Range);

import { useWindowSize } from "../../hooks/use-window-size";
import { styles } from "./styles";

type RangeFilterProps = React.ComponentProps<typeof RcRange> & {
  defaultValue?: any;
  disabled?: boolean;
  formatFunc?: (value: number) => string;
  max: number;
  min: number;
  onRangeDragEnd?: (range: any) => void;
  step?: number;
  variant?: "blue" | "default" | "green" | "pink";
};

export const RangeFilter: React.FC<RangeFilterProps> = ({
  defaultValue = [],
  disabled = false,
  formatFunc = (value: number) => value.toString(),
  max,
  min,
  onRangeDragEnd,
  variant = "default",
  ...props
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 991;

  const [range, setRange] = useState<any>(defaultValue);

  const handleDragEnd = useCallback(
    (range: any) => {
      onRangeDragEnd?.(range);
      setRange(range);
    },
    [onRangeDragEnd],
  );

  const ref = createRef<HTMLDivElement>();

  const [leftLabel, rightLabel] = range;

  const minLabel = formatFunc(min);
  const maxLabel = formatFunc(max);

  useEffect(() => {
    const oneHandle = ref.current?.querySelector(".rc-slider-handle");

    if (oneHandle) {
      oneHandle.setAttribute("aria-label", "Máximo");
    }

    const handleOne = ref.current?.querySelector(".rc-slider-handle-1");
    const handleTwo = ref.current?.querySelector(".rc-slider-handle-2");

    if (handleOne && handleTwo) {
      handleOne.setAttribute("aria-label", "Mínimo");
      handleTwo.setAttribute("aria-label", "Máximo");
    }
  }, []);

  return (
    <div css={styles.root}>
      <span css={styles.label}>
        {leftLabel ? formatFunc(leftLabel) : minLabel} /{" "}
        {rightLabel ? formatFunc(rightLabel) : maxLabel}
      </span>
      <div css={styles.range} ref={ref}>
        <RcRange
          defaultValue={(defaultValue ? defaultValue : [min, max]) as any}
          disabled={disabled}
          min={min}
          max={max}
          onAfterChange={handleDragEnd}
          tipFormatter={(value: number) => `${formatFunc(value)}`}
          {...styles.slider({ disabled, isMobile, variant })}
          {...props}
        />
      </div>
      <div css={styles.values}>
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
    </div>
  );
};
