import React, { useRef } from "react";
import { DayPickerRangeController } from "react-dates";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Popover from "@material-ui/core/Popover";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CarIcon from "@material-ui/icons/DirectionsCarRounded";
import { css } from "@styled-system/css";
import { css as materialCss } from "emotion";
import { FormikErrors } from "formik";
import moment, { Moment } from "moment";
import { darken } from "polished";
import { theme } from "smartrips-skin";
import uuid from "uuid";

import { Box, Flex } from "@toolkit";

import { MEDIA_QUERIES } from "@constants";

import { IBookingTarget } from "~/models/booker-target.model";

import { defaultTheme } from "@theme";

import { useFocusElementOnMount } from "@hooks";

import { StContainedButton } from "@shared/buttons";
import { ResponsiveRangeDatePicker } from "@shared/date-pickers";
import { Desktop, TabletAndMobile } from "@shared/layout/Responsive";
import { GoogleLocationAutocomplete } from "@shared/new-trip/GoogleLocationAutocomplete";
import { Input } from "@shared/new-trip/Input";

import { HourSelect } from "~/components/shared/new-trip/HourSelect";

import { CarSearch } from "../NewTrip.types";
import { useOfferSearch } from "../offer-search";
import { TravelerAutocomplete } from "../TravelerAutocomplete";

const styles = {
  getOriginStyles: (samePlace: boolean) =>
    css({
      borderRightWidth: ["1px", samePlace ? "1px" : 0],
      borderRadius: [1, samePlace ? 1 : "4px 0 0 4px"]
    }),
  destination: css({
    borderLeftWidth: ["1px", 0],
    borderRadius: [1, "0 4px 4px 0"]
  }),
  desktopDateContainer: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  }),
  dateInput: css({
    borderRadius: "4px 0 0 4px",
    borderWidth: "1px 0 1px 1px"
  }),
  getDateStyles: (error: boolean) =>
    css({
      height: 58,
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border",
      borderBottomColor: error ? "cancel" : "border",
      borderRadius: 1,
      padding: "medium",
      transition: "all 0.2s",

      ["&:hover"]: {
        borderColor: darken(0.2, theme.colors.border),
        borderBottomColor: error ? "cancel" : darken(0.2, theme.colors.border)
      }
    }),
  error: css({
    display: "block",
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  }),
  button: css({
    py: "small",
    px: "medium",
    lineHeight: "24px"
  })
};

const materialStyles = {
  checkboxLabel: materialCss({
    fontSize: 14,
    lineHeight: "1.46429em"
  }),
  checkbox: materialCss({
    color: defaultTheme.cardBorderColor,
    padding: ".5rem",
    ["&:hover"]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor + "!important"
    }
  }),
  checkedCheckbox: materialCss({
    color: `${defaultTheme.primaryColor} !important`
  }),
  popover: materialCss({
    backgroundColor: "#FFFFFF",
    boxShadow:
      "rgba(50, 50, 93, 0.06) 0px 4px 8px 0px, rgba(121, 135, 157, 0.1) 0px 0px 4px 0px, rgba(50, 50, 93, 0.06) 0px 2px 6px 0px;",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: ".5rem"
    }
  })
};

const dateValidation = (date: Moment) => {
  return date.diff(moment(), "days") < 0;
};

interface Props {
  values: CarSearch;
  errors: FormikErrors<CarSearch>;
  dateInputFocused: any;
  datePopoverVisible: boolean;
  handleDropoffDiffChange: (_: any, checked: boolean) => void;
  handleLocationChange: (
    type: "pickupLocation" | "dropoffLocation"
  ) => (location: any) => void;
  handleDateChange: (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  handleTravelerChange: (traveler: IBookingTarget) => void;
  handleDateInputClick: (type: "pickup" | "dropoff") => () => void;
  handleFocusChange: (focused: any) => void;
  handleChange: (e: any) => void;
  handleCloseDatePopover: () => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const CarFormPresentational = ({
  values,
  errors,
  dateInputFocused,
  datePopoverVisible,
  handleDropoffDiffChange,
  handleLocationChange,
  handleDateChange,
  handleTravelerChange,
  handleDateInputClick,
  handleFocusChange,
  handleChange,
  handleCloseDatePopover,
  handleSubmit
}: Props) => {
  const dateDivEl = useRef<any>();
  useFocusElementOnMount("pickupLocation");

  const { getBookingTargets } = useOfferSearch();

  return (
    <form onSubmit={handleSubmit}>
      <Box p={["20px 8px 16px", "large"]}>
        <div>
          <FormControlLabel
            classes={{ label: materialStyles.checkboxLabel }}
            control={
              <Checkbox
                checked={values.sameLocationDropoff}
                onChange={handleDropoffDiffChange}
                classes={{
                  root: materialStyles.checkbox,
                  checked: materialStyles.checkedCheckbox
                }}
              />
            }
            label="Devolver no mesmo local"
          />
        </div>
        <Flex mb="small" flexDirection={["column", "row"]}>
          <GoogleLocationAutocomplete
            value={values.pickupLocation ? values.pickupLocation.search : ""}
            handleSelect={handleLocationChange("pickupLocation")}
            icon={CarIcon}
            locationType={[
              "sublocality",
              "street_address",
              "airport",
              "car_rental",
              "point_of_interest"
            ]}
            inputProps={{
              id: "pickupLocation",
              placeholder: "Bairro, locadora de veículos ou ponto de interesse",
              error: errors.pickupLocation,
              css: styles.getOriginStyles(values.sameLocationDropoff)
            }}
          />

          {!values.sameLocationDropoff && (
            <>
              <Box display={["block", "none"]} height="8px" />
              <GoogleLocationAutocomplete
                value={
                  values.dropoffLocation ? values.dropoffLocation.search : ""
                }
                handleSelect={handleLocationChange("dropoffLocation")}
                icon={CarIcon}
                locationType={[
                  "sublocality",
                  "street_address",
                  "airport",
                  "car_rental",
                  "point_of_interest"
                ]}
                inputProps={{
                  placeholder:
                    "Bairro, locadora de veículos ou ponto de interesse",
                  error: errors.dropoffLocation,
                  css: styles.destination
                }}
              />
            </>
          )}
        </Flex>
        <Flex flexDirection={["column", "row"]}>
          <TabletAndMobile>
            <Flex
              alignItems="center"
              id="new-trip-picker"
              css={styles.getDateStyles(
                !!errors.pickupDate || !!errors.dropoffDate
              )}
            >
              <ResponsiveRangeDatePicker
                datePickerProps={{
                  startDate: values.pickupDate,
                  startDateId: uuid(),
                  startDatePlaceholderText: "Retirada",
                  endDate: values.dropoffDate,
                  endDateId: uuid(),
                  endDatePlaceholderText: "Devolução",
                  onDatesChange: handleDateChange,
                  focusedInput: dateInputFocused,
                  onFocusChange: handleFocusChange,
                  disableScroll: false,
                  noBorder: true,
                  hideKeyboardShortcutsPanel: true,
                  anchorDirection: "left",
                  customInputIcon: <DateRangeIcon />
                }}
              />
            </Flex>
            {errors.pickupDate ? (
              <span css={styles.error}>{errors.pickupDate}</span>
            ) : null}
            {errors.dropoffDate && !values.sameLocationDropoff ? (
              <span css={styles.error}>{errors.dropoffDate}</span>
            ) : null}
            <Box height="8px" />
          </TabletAndMobile>
          <div ref={dateDivEl} css={styles.desktopDateContainer}>
            <Flex width={["100%", "50%"]}>
              <Desktop>
                <Box width="50%">
                  <Input
                    placeholder="Retirada"
                    value={
                      values.pickupDate
                        ? values.pickupDate.format("DD MMM")
                        : ""
                    }
                    onClick={handleDateInputClick("pickup")}
                    startIcon={DateRangeIcon}
                    css={styles.dateInput}
                    error={errors.pickupDate}
                  />
                </Box>
              </Desktop>
              <HourSelect
                id="pickupTime"
                name="pickupTime"
                placeholder="Horário"
                value={values.pickupTime}
                onChange={handleChange}
              />
            </Flex>
            <Box width={["16px", "8px"]} />
            <Flex width={["100%", "50%"]}>
              <Desktop>
                <Box width="50%">
                  <Input
                    placeholder="Devolução"
                    value={
                      values.dropoffDate
                        ? values.dropoffDate.format("DD MMM")
                        : ""
                    }
                    onClick={handleDateInputClick("dropoff")}
                    startIcon={DateRangeIcon}
                    css={styles.dateInput}
                    error={errors.dropoffDate}
                  />
                </Box>
              </Desktop>
              <HourSelect
                id="dropoffTime"
                name="dropoffTime"
                placeholder="Horário"
                value={values.dropoffTime}
                onChange={handleChange}
              />
              <div>
                <Popover
                  open={datePopoverVisible}
                  anchorEl={dateDivEl.current}
                  onClose={handleCloseDatePopover}
                  classes={{
                    paper: materialStyles.popover
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <DayPickerRangeController
                    startDate={values.pickupDate}
                    endDate={values.dropoffDate}
                    onDatesChange={handleDateChange}
                    focusedInput={dateInputFocused}
                    onFocusChange={handleFocusChange}
                    numberOfMonths={2}
                    noBorder={true}
                    hideKeyboardShortcutsPanel={true}
                    isOutsideRange={dateValidation}
                  />
                </Popover>
              </div>
            </Flex>
          </div>
        </Flex>
      </Box>

      <Box
        p={["24px 8px 16px", "large"]}
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius="0 0 5px 5px"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box width="100%" maxWidth={["100%", 300]} mb={["medium", 0]}>
            <TravelerAutocomplete
              customGetBookingTargets={getBookingTargets}
              error={errors.traveler}
              onChange={handleTravelerChange}
              traveler={values.traveler}
            />
          </Box>
          <Box width="100%" maxWidth={["100%", 152.38]}>
            <StContainedButton css={styles.button} size="small" type="submit">
              Pesquisar carros
            </StContainedButton>
          </Box>
        </Flex>
      </Box>
    </form>
  );
};

export { CarFormPresentational };
