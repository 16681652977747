import * as carsApi from "~/apps/corporate/apis/car.api";
import { GetCarsListResponse } from "~/apps/corporate/dtos/car.dto";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const getCarsList = async (
  carToken: string,
): Promise<{ data?: GetCarsListResponse; error?: Error }> => {
  const result: { data?: GetCarsListResponse; error?: Error } = {};

  try {
    result.data = await carsApi.getCarsList(carToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LOAD_CARS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LOAD_CARS_LIST_ERROR;
    }
  }

  return result;
};
