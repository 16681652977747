import React, { createContext, useCallback, useEffect } from "react";

import * as userApi from "~/apps/corporate/apis/user.api";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { useSafeState } from "~/apps/shared/hooks/use-safe-state";
import { logger } from "~/apps/shared/utils/logger";

import { useApplication } from "./application.context";
import { useUser } from "./user.context";

interface Actions {
  generateShareCode: () => Promise<void>;
  getShareCode: () => Promise<void>;
}

type State = {
  homeLink: string | null;
  isGeneratingShareCode: boolean;
  isLoadingShareCode: boolean;
  shareCode: string | null;
};

type ContextProps = Actions & State;

const initialState: State = {
  homeLink: null,
  isGeneratingShareCode: false,
  isLoadingShareCode: false,
  shareCode: null,
};

const MemberGetMemberContext = createContext<ContextProps>({
  ...initialState,
  generateShareCode: async () => {
    return;
  },
  getShareCode: async () => {
    return;
  },
});

export const MemberGetMemberProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [safeState, setSafeState] = useSafeState(initialState);

  const generateShareCode = useCallback(async (): Promise<void> => {
    if (!user) {
      logger.error("user not found");

      return;
    }

    try {
      setSafeState({
        isGeneratingShareCode: true,
      });

      const response = await userApi.generateShareCode(user.getUserToken());

      setSafeState({
        shareCode: response.code,
      });
    } catch (error) {
      showSnackMessage(
        "Ocorreu um erro ao gerar o código de indicação",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setSafeState({
        isGeneratingShareCode: false,
      });
    }
  }, [showSnackMessage, user]);

  const getShareCode = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }

    try {
      setSafeState({ isLoadingShareCode: true });

      const response = await userApi.getShareCode(user.getUserToken());

      setSafeState({
        homeLink: response.home_link,
        shareCode: response.code,
      });
    } catch (error) {
      showSnackMessage(
        "Ocorreu um erro ao buscar as informações do usuário",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setSafeState({ isLoadingShareCode: false });
    }
  }, [showSnackMessage, user]);

  useEffect(() => {
    void getShareCode();
  }, [getShareCode]);

  return (
    <MemberGetMemberContext.Provider
      value={{
        ...safeState,
        generateShareCode,
        getShareCode,
      }}
    >
      {children}
    </MemberGetMemberContext.Provider>
  );
};

export const useMemberGetMember = useContextFactory(
  "MemberGetMemberContext",
  MemberGetMemberContext,
);
