import React from "react";

import { Box, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import PageTitle from "@shared/PageTitle";
import Spinner from "@shared/Spinner";

import { HeaderContainer } from "../shared/HeaderContainer";
import { LastUpdatedMessage } from "../shared/lastUpdatedMessage";
import { MobileFilters } from "../shared/MobileFilters";
import { HotelInfoProvider, useHotelInfoContext } from "./HotelInfo.context";
import { HotelInfoContent } from "./HotelInfoContent";
import { HotelInfoFilters } from "./HotelInfoFilters";

const HotelInfo: React.FC = () => {
  const {
    hotelInfo,
    filters,
    filterOptions: {
      costCenterOptions,
      tagOptions,
      travelerOptions,
      companyAreaOptions,
      projectOptions,
      billingProfileOptions,
    },
    onApplyFilters,
  } = useHotelInfoContext();

  const TITLE = "Analytics - Hotel";

  return (
    <Box p={[0, 12]}>
      <PageTitle title={TITLE} />
      <Spinner visible={hotelInfo.isLoading} />
      <HeaderContainer>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Text fontSize={24} fontWeight="bold">
            Hotel
          </Text>
          <Text
            style={{
              color: defaultTheme.subTextColor,
              fontSize: "0.85rem",
              marginTop: "5px",
            }}
          >
            {hotelInfo.generatedAt ? (
              <LastUpdatedMessage updatedAt={hotelInfo.generatedAt} />
            ) : null}
          </Text>
        </Box>
        <MobileFilters
          onApply={onApplyFilters}
          filtersData={filters}
          companyAreas={companyAreaOptions}
          billingProfiles={billingProfileOptions}
          costCenters={costCenterOptions}
          projects={projectOptions}
          tags={tagOptions}
          travelers={travelerOptions}
        />
      </HeaderContainer>
      <HotelInfoFilters />
      <HotelInfoContent />
    </Box>
  );
};

const Container: React.FC = () => (
  <HotelInfoProvider>
    <HotelInfo />
  </HotelInfoProvider>
);

export { Container as HotelInfo };
