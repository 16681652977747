import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ItineraryServiceModel } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import { ItineraryServices } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import {
  OffersAvailabilities,
  OffersAvailabilitiesModel,
  OffersAvailabilitiesModelFactory,
  OffersChanges,
  OffersChangesModel,
  OffersChangesModelFactory,
} from "~/apps/corporate/models/offer.model";
import { TravelPoliciesPerOffer } from "~/apps/corporate/models/travel.model";
import {
  ALERT_TYPES,
  SEND_VOUCHER_EMAIL_SUCCESS_MESSAGE,
} from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { forceFileDownload } from "~/apps/shared/utils/force-file-download";
import { sleep } from "~/apps/shared/utils/sleep";

import { useItinerary } from "./itinerary.context";
import * as itineraryService from "./itinerary.service";

interface Actions {
  downloadInvoices: () => Promise<boolean>;
  fetchItineraryServices: () => Promise<void>;
  fetchOffersAvailabilitiesAndChanges: (
    servicesModels: ItineraryServiceModel[],
    sleepDuration?: number,
  ) => Promise<{
    offersAvailabilitiesModel: OffersAvailabilitiesModel;
    offersChangesModel: OffersChangesModel;
  }>;
  fetchTravelPoliciesPerOffer: () => Promise<void>;
  sendVoucherEmail: () => Promise<boolean>;
}

type State = {
  errorOnFetch: Error | null;
  errorOnFetchOffersAvailabilities: Record<string, Error> | null;
  errorOnFetchTravelPoliciesPerOffer: Error | null;
  errorOnFetchTravelRepeatedOffers: Error | null;
  isLoading: boolean;
  isLoadingDownloadInvoices: boolean;
  isLoadingOffersAvailabilitiesAndChanges: boolean;
  isLoadingSendVoucherEmail: boolean;
  isLoadingTravelPoliciesPerOffer: boolean;
  offersAvailabilities: OffersAvailabilities | null;
  offersChanges: OffersChanges | null;
  services: ItineraryServices | null;
  travelPoliciesPerOffer: TravelPoliciesPerOffer | null;
};

const initialState: State = {
  errorOnFetch: null,
  errorOnFetchOffersAvailabilities: null,
  errorOnFetchTravelPoliciesPerOffer: null,
  errorOnFetchTravelRepeatedOffers: null,
  isLoading: false,
  isLoadingDownloadInvoices: false,
  isLoadingOffersAvailabilitiesAndChanges: false,
  isLoadingSendVoucherEmail: false,
  isLoadingTravelPoliciesPerOffer: false,
  offersAvailabilities: null,
  offersChanges: null,
  services: null,
  travelPoliciesPerOffer: null,
};

const ItineraryServicesContext = createContext<Actions & State>({
  ...initialState,
  downloadInvoices: async () => {
    return false;
  },
  fetchItineraryServices: async () => {
    return;
  },
  fetchOffersAvailabilitiesAndChanges: async () => {
    return {
      offersAvailabilitiesModel: OffersAvailabilitiesModelFactory.create({}),
      offersChangesModel: OffersChangesModelFactory.create({}),
    };
  },
  fetchTravelPoliciesPerOffer: async () => {
    return;
  },
  sendVoucherEmail: async () => {
    return false;
  },
});

export const ItineraryServicesProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const { travelToken } = useItinerary();

  const [state, setState] = useState(initialState);

  const downloadInvoices = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingDownloadInvoices: true,
    }));

    const downloadTravelInvoicesResponse = await itineraryService.downloadTravelInvoices(
      travelToken,
    );

    if (downloadTravelInvoicesResponse.error) {
      const error = downloadTravelInvoicesResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingDownloadInvoices: false,
      }));

      return false;
    }

    if (!downloadTravelInvoicesResponse.data) {
      const error = ERROR.UNEXPECTED;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingDownloadInvoices: false,
      }));

      return false;
    }

    const { url } = downloadTravelInvoicesResponse.data;

    forceFileDownload(url, `travel-${travelToken}-invoices.zip`);

    setState((prev) => ({
      ...prev,
      isLoadingDownloadInvoices: false,
    }));

    return true;
  }, [showSnackMessage, travelToken]);

  const fetchItineraryServices = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const itineraryServicesResponse = await itineraryService.getItineraryServices(
      travelToken,
    );

    if (itineraryServicesResponse.isFailure()) {
      const error = itineraryServicesResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      services: itineraryServicesResponse.data,
    }));
  }, [showSnackMessage, travelToken]);

  const fetchOffersAvailabilitiesAndChanges = useCallback(
    async (servicesModels: ItineraryServiceModel[], sleepDuration = 0) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchOffersAvailabilities: null,
        isLoadingOffersAvailabilitiesAndChanges: true,
      }));

      const [checkOffersAvailabilitiesResponse] = await Promise.all([
        itineraryService.checkOffersAvailabilities(
          servicesModels.map((serviceModel) => serviceModel.getOfferToken()),
        ),
        sleep(sleepDuration),
      ]);

      const offersAvailabilitiesWithFailure: Record<
        string,
        Error
      > = Object.entries(checkOffersAvailabilitiesResponse).reduce(
        (prev, [k, v]) => {
          if (v.isFailure()) {
            prev[k] = v.data;
          }

          return prev;
        },
        {} as Record<string, Error>,
      );

      const offersAvailabilitiesWithSuccess = Object.entries(
        checkOffersAvailabilitiesResponse,
      ).reduce((prev, [k, v]) => {
        if (v.isSuccess()) {
          prev[k] = v.data;
        }

        return prev;
      }, {} as OffersAvailabilities);

      const offersAvailabilitiesModel = OffersAvailabilitiesModelFactory.create(
        offersAvailabilitiesWithSuccess,
      );

      const calculateOffersChanges = () => {
        const offersChanges = Object.entries(
          offersAvailabilitiesModel.getAllOffersAvailabilities(),
        ).reduce((prev, [k, v]) => {
          if (v.hasChanges(servicesModels)) {
            prev[k] = v.getOfferAvailability();
          }

          return prev;
        }, {} as OffersAvailabilities);

        return offersChanges;
      };

      const offersAvailabilities = offersAvailabilitiesWithSuccess;
      const offersChanges = calculateOffersChanges();

      setState((prev) => ({
        ...prev,
        errorOnFetchOffersAvailabilities:
          Object.keys(offersAvailabilitiesWithFailure).length > 0
            ? offersAvailabilitiesWithFailure
            : null,
        isLoadingOffersAvailabilitiesAndChanges: false,
        offersAvailabilities,
        offersChanges,
      }));

      const offersChangesModel = OffersChangesModelFactory.create(
        offersChanges,
      );

      return {
        offersAvailabilitiesModel,
        offersChangesModel,
      };
    },
    [showSnackMessage],
  );

  const fetchTravelPoliciesPerOffer = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingTravelPoliciesPerOffer: true,
    }));

    const travelPoliciesPerOfferResponse = await itineraryService.getTravelPoliciesPerOffer(
      travelToken,
    );

    if (travelPoliciesPerOfferResponse.isFailure()) {
      const error = travelPoliciesPerOfferResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetchTravelPoliciesPerOffer: error,
        isLoadingTravelPoliciesPerOffer: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingTravelPoliciesPerOffer: false,
      travelPoliciesPerOffer: travelPoliciesPerOfferResponse.data,
    }));
  }, [showSnackMessage, travelToken]);

  const sendVoucherEmail = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingSendVoucherEmail: true,
    }));

    const sendVoucherEmailResponse = await itineraryService.sendVoucherEmail(
      travelToken,
    );

    if (sendVoucherEmailResponse.error) {
      const error = sendVoucherEmailResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingSendVoucherEmail: false,
      }));

      return false;
    }

    setState((prev) => ({
      ...prev,
      isLoadingSendVoucherEmail: false,
    }));

    showSnackMessage(SEND_VOUCHER_EMAIL_SUCCESS_MESSAGE, ALERT_TYPES.SUCCESS);

    return true;
  }, [showSnackMessage, travelToken]);

  return (
    <ItineraryServicesContext.Provider
      value={{
        ...state,
        downloadInvoices,
        fetchItineraryServices,
        fetchOffersAvailabilitiesAndChanges,
        fetchTravelPoliciesPerOffer,
        sendVoucherEmail,
      }}
    >
      {children}
    </ItineraryServicesContext.Provider>
  );
};

export const useItineraryServices = useContextFactory(
  "ItineraryServicesContext",
  ItineraryServicesContext,
);
