import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: "none",
    height: "1px",
    margin: "0 auto",
    width: "100%",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 640px)": {
      padding: "24px 0 0 0",
    },
  }),
  top: {
    button: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      cursor: "pointer",
      fontSize: "0.875rem",
      fontWeight: 500,
      textDecoration: "underline",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.pink[700],
      },
    }),
    name: css({
      fontWeight: 700,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      justifyContent: "space-between",
    }),
  },
  travelers: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    traveler: {
      avatar: css({
        minWidth: "32px !important",
        "& > :first-of-type": {
          flexShrink: 0,
          "& > :first-of-type": {
            height: "32px !important",
            width: "32px !important",
          },
        },
      }),
      main: css({}),
      name: css({
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
      }),
      text: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        width: "100%",
        wordBreak: "break-all",
      }),
    },
  },
  traveler: {
    avatar: css({
      minWidth: "32px !important",
      "& > :first-of-type": {
        flexShrink: 0,
        "& > :first-of-type": {
          height: "32px !important",
          width: "32px !important",
        },
      },
    }),
    name: css({
      fontWeight: 600,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "8px",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      wordBreak: "break-all",
    }),
  },
};
