import { useMutation, useQuery, useQueryClient } from "react-query";

import { GetUsersRequestParams } from "~/apps/corporate/dtos/client.dto";
import { User } from "~/apps/corporate/models/user.model";
import { Either, failure, success } from "~/apps/shared/utils/either";

import * as usersService from "../users.service";
import {
  CreateUserSchema,
  EditUserSchema,
} from "./users-user-form/users-user-form.schema";

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createUser } = useMutation({
    mutationFn: async (data: CreateUserSchema): Promise<Either<any, User>> => {
      try {
        return success(await usersService.createUser(data));
      } catch (error) {
        return failure(error);
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries("users");
    },
  });

  return { createUser };
};

export const useEditUserMutation = (userToken?: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editUser } = useMutation({
    mutationFn: async (data: EditUserSchema): Promise<Either<any, User>> => {
      try {
        return success(await usersService.editUser(data));
      } catch (error) {
        return failure(error);
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["user", userToken]);
      void queryClient.invalidateQueries("users");
    },
  });

  return { editUser };
};

export const useReinviteUserMutation = (userToken?: string) => {
  const { mutateAsync: reinviteUser, status: reinviteUserStatus } = useMutation(
    {
      mutationFn: async () => {
        if (!userToken) {
          return null;
        }

        return await usersService.reenviteUser(userToken);
      },
    },
  );

  return { reinviteUser, reinviteUserStatus };
};

export const useUserData = (userToken?: string) => {
  const {
    data: userData,
    error: errorOnFetchUserData,
    isLoading: isLoadingUserData,
  } = useQuery(
    ["user", userToken],
    async () => {
      if (!userToken) {
        return;
      }

      const getUserResponse = await usersService.getUser(userToken);

      if (!getUserResponse.data || getUserResponse.error) {
        return;
      }

      return getUserResponse.data;
    },
    {
      enabled: !!userToken,
    },
  );

  return {
    errorOnFetchUserData,
    isLoadingUserData,
    userData,
  };
};

export const useUserDocument = (documentToken?: string) => {
  const {
    data: userDocument,
    error: errorOnFetchUserDocument,
    isLoading: isLoadingUserDocument,
  } = useQuery(
    ["document", documentToken],
    async () => {
      if (!documentToken) {
        return;
      }

      const getDocumentResponse = await usersService.getDocument(documentToken);

      if (!getDocumentResponse.data || getDocumentResponse.error) {
        return;
      }

      return getDocumentResponse.data;
    },
    {
      enabled: !!documentToken,
    },
  );

  return {
    errorOnFetchUserDocument,
    isLoadingUserDocument,
    userDocument,
  };
};

export const useUsers = (params: GetUsersRequestParams) => {
  const {
    data: users,
    error: errorOnFetchUsers,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery(["users", { params }], async () => {
    const getUsersResponse = await usersService.getUsers(params);

    if (getUsersResponse.isFailure()) {
      throw new Error(getUsersResponse.data.description);
    }

    return getUsersResponse.data;
  });

  return {
    errorOnFetchUsers,
    isLoadingUsers,
    refetchUsers,
    users,
  };
};
