import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  text: string;
};

export const APIKeysCopyInput: React.FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      await navigator.clipboard.writeText(text);

      if (copied) {
        return;
      }

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
    [copied, text],
  );

  return (
    <div css={styles.root}>
      <Input css={styles.input} disabled value={text} />
      <Tooltip arrow content={copied ? "Copiado!" : "Copiar"} position="right">
        <div css={styles.button}>
          <Button
            onClick={async (e) => {
              await handleClick(e);
            }}
            shape="icon"
          >
            <Icon size={16} use={copied ? "check" : "clipboard-document"} />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};
