import React from "react";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";

import { Layout } from "../../components/layout/layout";

type Props = RouteComponentProps;

export const Permission: React.FC<Props> = () => {
  return (
    <Layout>
      <ErrorPage
        message="Você não tem permissão para acessar essa página."
        title="Sem permissão"
      />
    </Layout>
  );
};
