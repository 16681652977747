import React from "react";

import { Bound } from "~/apps/corporate/models/flight.model";
import { FlightListOffer } from "~/apps/corporate/models/offer.model";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Icon } from "~/apps/shared/components/icon/icon";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { toCurrency } from "~/apps/shared/utils/to-currency";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { ItineraryFlightSegment } from "../../../../../../components/itinerary-flight-segment/itinerary-flight-segment";
import { styles } from "./styles";

const formatDate = (date: string) => {
  return moment(date, "DD/MM/YYYY").format("dddd, MMM DD");
};

type Props = {
  flight: FlightListOffer;
  handleShowOutPolicyInfo(): void;
  isAdding: boolean;
  onAddToTrip(): void;
  onReserveChange(e: React.ChangeEvent<HTMLInputElement>): void;
  planeLimitExceeded: boolean;
};

export const FlightSummary: React.FC<Props> = ({
  flight,
  handleShowOutPolicyInfo,
  isAdding,
  onAddToTrip,
  onReserveChange,
  planeLimitExceeded,
}) => {
  const { outbound, inbound } = flight;

  const hasBaggage = outbound.baggage || inbound?.baggage;
  const hasPriceChange =
    flight.originalPrice && flight.price !== flight.originalPrice;
  const isAddingFlight = isAdding;
  const isFlightAvailable = flight.available;
  const isPlaneLimitExceeded = planeLimitExceeded;

  const isButtonDisabled =
    !isFlightAvailable || isAddingFlight || isPlaneLimitExceeded;

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.date}>{formatDate(outbound.departure)}</span>
        <ItineraryFlightSegment
          arrival={{
            airportCode: outbound.destination.code,
            time: outbound.arrivalHour,
          }}
          baggage={{
            checked: {
              included: outbound.baggage,
              quantity: outbound.baggagePieces,
            },
          }}
          carrier={{
            imageUrl: outbound.carrier?.imageUrl || "",
            name: outbound.carrier?.name || "",
          }}
          connection={{}}
          daysDiff={outbound.daysDiff}
          duration={outbound.durationHours}
          departure={{
            airportCode: outbound.origin.code,
            time: outbound.departureHour,
          }}
          stops={outbound.stops}
        />
        {inbound ? (
          <>
            <span css={styles.top.date}>{formatDate(inbound.departure)}</span>
            <ItineraryFlightSegment
              arrival={{
                airportCode: inbound.destination.code,
                time: inbound.arrivalHour,
              }}
              baggage={{
                checked: {
                  included: inbound.baggage,
                  quantity: inbound.baggagePieces,
                },
              }}
              carrier={{
                imageUrl: inbound.carrier?.imageUrl || "",
                name: inbound.carrier?.name || "",
              }}
              connection={{}}
              daysDiff={inbound.daysDiff}
              duration={inbound.durationHours}
              departure={{
                airportCode: inbound.origin.code,
                time: inbound.departureHour,
              }}
              stops={inbound.stops}
            />
          </>
        ) : null}
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.section.root}>
          <span css={styles.center.section.title}>Bilhetes aéreos</span>
          <div css={styles.center.section.content.root}>
            <span css={styles.center.section.content.text}>
              1 x bilhete {inbound ? "ida e volta" : "só ida"}{" "}
              {outbound.origin.code} - {outbound.destination.code}
            </span>
            <span css={styles.center.section.content.price}>
              {toCurrency(flight.price)}
            </span>
          </div>
          {!flight.available ? (
            <span css={styles.center.section.content.fee}>
              Tarifa indisponível
            </span>
          ) : null}
        </div>
        {hasBaggage ? (
          <div css={styles.center.section.root}>
            <span css={styles.center.section.title}>Extras</span>
            {outbound.baggage ? <BaggageRow bound={outbound} /> : null}
            {inbound && inbound.baggage ? <BaggageRow bound={inbound} /> : null}
          </div>
        ) : null}
      </div>
      <div css={styles.bottom.root}>
        <div css={styles.bottom.pricing.root}>
          <span css={styles.bottom.pricing.text}>Valor total</span>
          <span css={styles.bottom.pricing.price}>
            {toCurrency(flight.price)}
          </span>
        </div>
        {flight.outOfPolicy ? (
          <OutOfPolicyBadge
            onClick={() => {
              handleShowOutPolicyInfo();
            }}
          />
        ) : null}
        {hasPriceChange ? <FarePriceChange flight={flight} /> : null}
        <label css={styles.bottom.reserve.root}>
          <Checkbox
            checked={flight.reserve}
            onChange={onReserveChange}
            variant="pink"
          />
          <span css={styles.bottom.reserve.label}>
            Já quero pré-reservar este voo
          </span>
          <Tooltip content="Ao reservar um voo, você garante sua disponibilidade por 24h ou até o fim do dia, dependendo da companhia aérea, prevenindo assim possíveis indisponibilidades.">
            <div>
              <Icon size={16} use="question-circle-outline" />
            </div>
          </Tooltip>
        </label>
        <Button
          css={styles.bottom.button}
          disabled={isButtonDisabled}
          onClick={onAddToTrip}
        >
          {flight.reserve ? "Adicionar e reservar" : "Adicionar à viagem"}
        </Button>
      </div>
    </div>
  );
};

type BaggageRowProps = {
  bound: Bound;
};

const BaggageRow: React.FC<BaggageRowProps> = ({ bound }) => (
  <div css={styles.center.section.content.root}>
    <span css={styles.center.section.content.text}>
      {bound.baggagePieces} x{" "}
      {bound.baggagePieces > 1 ? "bagagens despachadas" : "bagagem despachada"}{" "}
      {bound.origin.code} - {bound.destination.code}
    </span>
    <span css={styles.center.section.content.free}>Grátis</span>
  </div>
);

type FarePriceChangeProps = {
  flight: FlightListOffer;
};

const FarePriceChange: React.FC<FarePriceChangeProps> = ({ flight }) => {
  const { price, originalPrice, outbound } = flight;

  const alteredDiff = price - originalPrice!;
  const alteredLabel = alteredDiff > 0 ? "aumentou de" : "diminuiu de";
  const routeLabel = `${outbound.origin.code} - ${outbound.destination.code}`;

  return (
    <div css={styles.bottom.change.root}>
      {alteredDiff > 0 ? (
        <Icon size={20} use="arrow-trending-up" />
      ) : (
        <Icon size={20} use="arrow-trending-down" />
      )}
      <span css={{ color: alteredDiff > 0 ? "#FFA500" : "success" }}>
        Preço de {routeLabel} {alteredLabel} {toCurrency(originalPrice!)} para{" "}
        {toCurrency(price)}.
      </span>
    </div>
  );
};
