export const colors = {
  black: "#000000",
  cerulean: "#039BE5",
  ebony: "#0C1421",
  scooter: "#23C4DA",
  jungleGreen: "#33B679",
  jungleGreenHover: "#2f9163",
  cornflowerBlue: "#4285F4",
  tudora: "#494849",
  doveGray: "#616161",
  slateGray: "#758398",
  slateContrastGray: "#5E6879",
  steelBlue: "#4C5566",
  paleMistBlue: "#F0F4FF",
  moodyBlue: "#7986CB",
  cucumberGreen: "#88B053",
  silver: "#C1C1C1",
  botticelli: "#D3DEE9",
  punch: "#DB4332",
  mystic: "#DFE6ED",
  fireBush: "#E49635",
  anzac: "#E4C441",
  sunglo: "#E67C73",
  burntSienna: "#E9705E",
  tangerine: "#F09300",
  concrete: "#F2F2F2",
  pomegranate: "#F4511E",
  saffron: "#F6BF26",
  linkWater: "#F7F8FD",
  whisperWhite: "#F7F7F7",
  brinkPink: "#FF6090",
  brinkPinkHover: "#E85080",
  white: "#FFFFFF",
  darkGray: "#5B6268",
  fern: "#5EB27B",
  lightGray: "#79879D",
  veryLightGray: "#eee",
  redError: "#DB4332",
  purple: "#673ab7",
  canceled: "rgb(171, 185, 199)",
  dodgerBlue: "#1E90FF",
  intermediaryGreen: "#067906",
  minBlue: "#2f69e4",
  mediumBlue: "#194EC0",
  darkBlue: "#133FA0",
  midnightBlue: "#1D2C3C"
};
