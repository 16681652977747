import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { StOutlinedButton, StContainedButton } from "../buttons";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  dialogTitle: css({
    fontSize: "20px",
    fontFamily: "'Open sans', sans-serif",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 400px)"]: {
      fontSize: 16
    }
  }),
  dialogPaper: css({
    ["@media (max-width: 767px)"]: {
      margin: 20
    }
  }),
  dialogText: css({
    fontFamily: "'Open sans', sans-serif",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 400px)"]: {
      fontSize: 14
    }
  }),
  dialogActionsDiv: css({
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    margin: 0,
    padding: "1rem 0.5rem"
  }),
  deleteButton: css({
    padding: ".5rem 1.5rem"
  })
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DeletionDialog = ({
  open,
  title,
  message,
  deleteButtonLabel,
  handleClose,
  handleDeletion,
  isDeleting
}) => {
  return (
    <Dialog
      classes={{
        paper: styles.dialogPaper
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <span className={styles.dialogTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className={styles.dialogText}>{message}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.dialogActionsDiv}>
        <StOutlinedButton onClick={handleClose}>Cancelar</StOutlinedButton>
        <div>
          <StContainedButton
            onClick={handleDeletion}
            color="secondary"
            className={styles.deleteButton}
            disabled={isDeleting}
            loading={isDeleting}
          >
            {deleteButtonLabel}
          </StContainedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

DeletionDialog.defaultProps = {
  isDeleting: false,
  deleteButtonLabel: "Excluir"
};

export default DeletionDialog;
