import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";

import { useItineraryApproval } from "../../itinerary-approval.context";
import { useItineraryInfo } from "../../itinerary-info.context";
import { ItineraryServiceChoiceContextDialog } from "../../itinerary-service-choice-context-dialog/itinerary-service-choice-context-dialog";
import { ItineraryServiceOutOfPolicyDialog } from "../../itinerary-service-out-of-policy-dialog/itinerary-service-out-of-policy-dialog";
import { useItineraryServices } from "../../itinerary-services.context";
import { ItineraryTravelRepeatedOffersDialog } from "../../itinerary-travel-repeated-offers-dialog/itinerary-travel-repeated-offers-dialog";
import { ItineraryInfo } from "../itinerary-info/itinerary-info";
import { ItineraryPriceBreakdown } from "../itinerary-price-breakdown/itinerary-price-breakdown";
import { ItineraryServiceDetailsDrawer } from "../itinerary-service-details-drawer/itinerary-service-details-drawer";
import { useItineraryTravelPlanGuard } from "./itinerary-travel-plan.hooks";
import { styles } from "./styles";
import { TravelPlanAddServiceDialog } from "./travel-plan-add-service-dialog/travel-plan-add-service-dialog";
import { TravelPlanAddService } from "./travel-plan-add-service/itinerary-add-service";
import { TravelPlanApprovalHistoryDialog } from "./travel-plan-approval-history-dialog/travel-plan-approval-history-dialog";
import { TravelPlanApprovalHistory } from "./travel-plan-approval-history/travel-plan-approval-history";
import { TravelPlanProceedButton } from "./travel-plan-proceed-button/travel-plan-proceed-button";
import { TravelPlanServiceCancelationAlertDialog } from "./travel-plan-service-cancelation-alert-dialog/travel-plan-service-cancelation-alert-dialog";
import { TravelPlanServiceDeletionAlertDialog } from "./travel-plan-service-deletion-alert-dialog/travel-plan-service-deletion-alert-dialog";
import { TravelPlanServiceTravelersDialog } from "./travel-plan-service-travelers-dialog/travel-plan-service-travelers-dialog";
import { TravelPlanServicesCard } from "./travel-plan-services-card/travel-plan-services-card";
import { TravelPlanShareDialog } from "./travel-plan-share-dialog/travel-plan-share-dialog";
import { TravelPlanTravelDeletionAlertDialog } from "./travel-plan-travel-deletion-alert-dialog/travel-plan-travel-deletion-alert-dialog";

type Props = RouteComponentProps;

export const ItineraryTravelPlan: React.FC<Props> = () => {
  const { error, permissions } = useItineraryTravelPlanGuard();

  const {
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
  } = useItineraryApproval();
  const { fetchItineraryInfo } = useItineraryInfo();
  const {
    fetchItineraryServices,
    fetchTravelPoliciesPerOffer,
  } = useItineraryServices();

  useEffect(() => {
    void fetchItineraryApprovalHistory();
    void fetchItineraryApprovalStatus();
    void fetchItineraryInfo();
    void fetchItineraryServices();
    void fetchTravelPoliciesPerOffer();
  }, [
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
    fetchItineraryInfo,
    fetchItineraryServices,
    fetchTravelPoliciesPerOffer,
  ]);

  if (error) {
    return <ErrorPage {...error} />;
  }

  return (
    <>
      <div css={styles.root}>
        <div css={styles.left.root}>
          <ItineraryInfo
            canEditCategorization={permissions.canEditCategorization}
            canEditTravelName={permissions.canEditTravelName}
            canEditTravelTags={permissions.canEditTravelTags}
          />
          <TravelPlanAddService canAddOffers={permissions.canAddOffers} />
          <TravelPlanServicesCard canAddOffers={permissions.canAddOffers} />
        </div>
        <div css={styles.right.root}>
          <TravelPlanApprovalHistory
            canCancelApprovalRequest={permissions.canCancelApprovalRequest}
            canGoToApprovalReview={permissions.canGoToApprovalReview}
          />
          <ItineraryPriceBreakdown showReservedServicesToggle />
          <TravelPlanProceedButton />
        </div>
      </div>
      <ItineraryServiceChoiceContextDialog />
      <ItineraryServiceDetailsDrawer />
      <ItineraryServiceOutOfPolicyDialog />
      <ItineraryTravelRepeatedOffersDialog />
      <TravelPlanAddServiceDialog />
      <TravelPlanApprovalHistoryDialog />
      <TravelPlanServiceCancelationAlertDialog />
      <TravelPlanServiceDeletionAlertDialog />
      <TravelPlanServiceTravelersDialog />
      <TravelPlanShareDialog />
      <TravelPlanTravelDeletionAlertDialog />
    </>
  );
};
