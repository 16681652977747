import React, { useContext, useEffect, useState } from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";
import { FinancialContext } from "../Financial.context";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SearchInput } from "../../shared/inputs";
import { StOutlinedButton, DownloadButton } from "../../shared/buttons";
import { FlightsTable } from "./FlightsTable";
import { HotelsTable } from "./HotelsTable";
import { CarsTable } from "./CarsTable";
import { FINANCIAL_TABS } from "../../../constants";
import CheckboxIcon from "@material-ui/icons/CheckBoxRounded";
import UnckeckedCheckBoxIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SmartripsServicesList } from "./SmartripsServicesList";
import { TagSelect } from "@shared/inputs/TagSelect";
import { FinancialDatePicker } from "../FinancialDatePicker";
import { BusesTable } from "./BusesTable";

const styles = {
  filtersRow: css({
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem"
  }),
  downloadRow: css({
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem"
  }),
  checkboxDiv: css({
    marginRight: "1rem",
    minWidth: 175.86
  }),
  checkboxControl: css({
    marginLeft: 0
  }),
  checkboxLabel: css({
    fontSize: 14,
    lineHeight: "1.46429em"
  }),
  checkbox: css({
    color: defaultTheme.cardBorderColor,
    padding: ".5rem",
    ["&:hover"]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor + "!important"
    }
  }),
  checkedCheckbox: css({
    color: `${defaultTheme.primaryColor} !important`
  }),
  dateContainer: css({
    marginRight: "2rem"
  }),
  inputContainer: css({
    width: 240,
    marginRight: "2rem"
  }),
  downloadButton: css({
    padding: ".5rem 1.5rem",
    fontSize: 14,
    width: 225.88
  }),
  downloadingText: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }),
  circularProgress: css({
    color: defaultTheme.primaryColor,
    marginRight: ".5rem"
  }),
  downloadButtons: css({
    marginRight: "2rem"
  }),
  icon: css({
    color: defaultTheme.subTextColor,
    fontSize: 16
  })
};

const InvoicesList = () => {
  const {
    fetchInvoices,
    startDate,
    endDate,
    tagFilter,
    visibleTab,
    downloadingInvoices,
    hasSomeInvoiceSelected,
    downloadingStatement,
    donwloadingConciliation,
    areAllInvoicesSelected,
    handleChangeSearchFilter,
    handleChangeTagFilter,
    handleChangeSelectAll,
    handleDownloadTransactionsInvoices,
    handleDownloadFinancialStatement,
    handleDownloadConciliation,
    handleRangeDateChange,
    handleChangeRangeDatePickerFocus,
    rangeFocus
  } = useContext(FinancialContext);

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div>
      <div className={styles.filtersRow}>
        <FinancialDatePicker
          startDate={startDate}
          endDate={endDate}
          handleRangeDateChange={handleRangeDateChange}
          handleChangeRangeDatePickerFocus={handleChangeRangeDatePickerFocus}
          rangeDatePickerFocus={rangeFocus}
        />
        <DownloadButton
          isDowloading={downloadingStatement}
          handleDownload={handleDownloadFinancialStatement}
          containerClasses={styles.downloadButtons}
        >
          Baixar Extrato
        </DownloadButton>
        <DownloadButton
          isDowloading={donwloadingConciliation}
          handleDownload={handleDownloadConciliation}
          containerClasses={styles.downloadButtons}
        >
          Baixar Conciliação
        </DownloadButton>
      </div>
      {visibleTab !== FINANCIAL_TABS.SMARTRIPS ? (
        <div className={styles.downloadRow}>
          <div className={styles.checkboxDiv}>
            <FormControlLabel
              className={styles.checkboxControl}
              classes={{ label: styles.checkboxLabel }}
              control={
                <Checkbox
                  checked={hasSomeInvoiceSelected}
                  onChange={handleChangeSelectAll}
                  classes={{
                    root: styles.checkbox,
                    checked: styles.checkedCheckbox
                  }}
                  checkedIcon={
                    areAllInvoicesSelected ? (
                      <CheckboxIcon />
                    ) : (
                      <IndeterminateCheckBoxIcon />
                    )
                  }
                  icon={<UnckeckedCheckBoxIcon />}
                />
              }
              label={
                hasSomeInvoiceSelected
                  ? "Deselecionar todos"
                  : "Selecionar todos"
              }
            />
          </div>
          <SearchInput
            inputProps={{
              placeholder: "Buscar comprovante",
              label: "Buscar",
              containerClasses: styles.inputContainer
            }}
            handleChange={handleChangeSearchFilter}
          />
          <TagSelect value={tagFilter} handleChange={handleChangeTagFilter} />
          <StOutlinedButton
            className={styles.downloadButton}
            disabled={!hasSomeInvoiceSelected || downloadingInvoices}
            onClick={handleDownloadTransactionsInvoices}
            color="primary"
          >
            {downloadingInvoices ? (
              <div className={styles.downloadingText}>
                <CircularProgress
                  className={styles.circularProgress}
                  size={18}
                />
                <span>Baixando</span>
              </div>
            ) : (
              "Baixar notas selecionadas"
            )}
          </StOutlinedButton>
        </div>
      ) : null}
      <div>
        {visibleTab === FINANCIAL_TABS.FLIGHTS ? <FlightsTable /> : null}
        {visibleTab === FINANCIAL_TABS.HOTELS ? <HotelsTable /> : null}
        {visibleTab === FINANCIAL_TABS.CARS ? <CarsTable /> : null}
        {visibleTab === FINANCIAL_TABS.BUSES ? <BusesTable /> : null}
        {visibleTab === FINANCIAL_TABS.SMARTRIPS ? (
          <SmartripsServicesList />
        ) : null}
      </div>
    </div>
  );
};

export { InvoicesList };
