import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { FlightItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";

import { TravelPlanPDFServiceCancelationInfo } from "../../travel-plan-pdf-service-cancelation-info/travel-plan-pdf-service-cancelation-info";
import { TravelPlanPDFServiceCancelationUntil } from "../../travel-plan-pdf-service-cancelation-until/travel-plan-pdf-service-cancelation-until";
import { TravelPlanPDFServiceCanceledBy } from "../../travel-plan-pdf-service-canceled-by/travel-plan-pdf-service-canceled-by";
import { TravelPlanPDFServiceReservationCode } from "../../travel-plan-pdf-service-reservation-code/travel-plan-pdf-service-reservation-code";
import { TravelPlanPDFServiceReservationInfo } from "../../travel-plan-pdf-service-reservation-info/travel-plan-pdf-service-reservation-info";
import { styles } from "./styles";
import { TravelPlanPDFFlightSegment } from "./travel-plan-pdf-flight-segment/travel-plan-pdf-flight-segment";

type Props = {
  servicePresenter: FlightItineraryServicePresenter;
};

export const TravelPlanPDFFlightJourney: React.FC<Props> = ({
  servicePresenter,
}) => {
  const badgeStyles = styles.badge();
  const rootStyles = styles.root();

  const bookingCode = servicePresenter.getBookingCode();
  const cancelInfo = servicePresenter.getCancelationInfo();
  const changeFee = servicePresenter.getChangeFee();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();
  const operationalId = servicePresenter.getOperationalId();

  const reservationReservedUntil = servicePresenter.getReservationReservedUntil();
  const reservationStatus = servicePresenter.getReservationStatus();

  return (
    <View style={rootStyles.root}>
      {servicePresenter.showReservation() && operationalId ? (
        <TravelPlanPDFServiceReservationCode
          additionalCode={bookingCode}
          additionalLabel="Bilhete"
          reservationCode={operationalId}
          reservationLabel="Localizador"
        />
      ) : null}
      <View style={rootStyles.card}>
        {servicePresenter.getAllJourneys().map((journey, index) => (
          <React.Fragment key={journey.index}>
            {index !== 0 ? <hr style={rootStyles.divisor} /> : null}
            <View style={rootStyles.segments}>
              {journey.segments.map((segment) => (
                <TravelPlanPDFFlightSegment
                  arrival={segment.arrival}
                  baggage={segment.baggage}
                  carrier={segment.carrier}
                  connection={segment.connection}
                  daysDiff={servicePresenter.getJourneyDaysDiff({
                    arrival: segment.arrival,
                    departure: segment.departure,
                  })}
                  departure={segment.departure}
                  duration={segment.duration}
                  key={segment.index}
                />
              ))}
            </View>
          </React.Fragment>
        ))}
      </View>
      {servicePresenter.isBookingUnitiated() &&
      !servicePresenter.isReservationUnitiated() ? (
        <TravelPlanPDFServiceReservationInfo
          reservationReservedUntil={reservationReservedUntil}
          reservationStatus={reservationStatus}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <TravelPlanPDFServiceCancelationInfo
          cancelFee={
            changeFee
              ? changeFee.total
              : cancelInfo.fee
              ? cancelInfo.fee.total
              : null
          }
          cancelType={cancelInfo.type}
          isChanged={servicePresenter.isChanged()}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage ? (
        <TravelPlanPDFServiceCanceledBy
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      ) : null}
      {!servicePresenter.isCanceled() && servicePresenter.hasUsedCredits() ? (
        <View style={badgeStyles.root}>
          <PDFIcon size={14} use="dollar" />
          <View>
            <Text>
              Crédito utilizado: {servicePresenter.getFormattedUsedCredits()}.
            </Text>
          </View>
        </View>
      ) : null}
      {formattedFreeCancelationUntil ? (
        <TravelPlanPDFServiceCancelationUntil
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          message="(horário local da companhia)"
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
    </View>
  );
};
