import React from "react";

import { styles } from "./styles";

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <div css={styles.left.container.root}>{children}</div>
      </div>
      <div css={styles.right.root}>
        <div css={styles.right.banner} />
      </div>
    </div>
  );
};
