import { css } from "@styled-system/css";
import { CountryRestrictionSeverity } from "~/apps/shared/constants/enums";

import { theme } from "@skin/v2";

const SEVERITY_COLORS: Record<
  CountryRestrictionSeverity,
  { color: string; backgroundColor: string; borderColor: string }
> = {
  warning: {
    backgroundColor: "#ffe3c7",
    borderColor: "#fda101",
    color: theme.colors.gray[700],
  },
  error: {
    backgroundColor: "#ffe3e0",
    borderColor: "#f73625",
    color: theme.colors.gray[700],
  },
  info: {
    backgroundColor: "#c3ecff",
    borderColor: "#0076fe",
    color: theme.colors.gray[700],
  },
};

export const styles = {
  root: ({ severity }: { severity: CountryRestrictionSeverity }) =>
    css({
      border: "2px solid",
      borderRadius: "8px",
      fontSize: "0.875rem",
      padding: "24px",
      ...SEVERITY_COLORS[severity],
    }),
};
