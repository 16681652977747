import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  spinner: css({
    color: "primary",
    size: "80px !important"
  })
};

const DrawerSpinner = () => {
  return (
    <Box width="100%" height="calc(100vh - 60px)">
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress css={styles.spinner} />
        <Text fontSize={4} color={defaultTheme.subTextColor} mt="large">
          Carregando
        </Text>
      </Flex>
    </Box>
  );
};

export { DrawerSpinner };
