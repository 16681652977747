import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  item: {
    button: ({ isEmpty }: { isEmpty?: boolean }) =>
      css({
        backgroundColor: "transparent",
        border: "none",
        color: isEmpty ? theme.colors.gray[500] : theme.colors.pink[500],
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
        fontWeight: isEmpty ? "unset" : 600,
        overflow: "hidden",
        textDecoration: "none",
        textOverflow: "ellipsis",
        transition: "all 0.15s ease-in-out",
        whiteSpace: "nowrap",
        width: "100%",
        ":not(:disabled)": {
          borderBottom: isEmpty
            ? `1px solid ${theme.colors.gray[300]}`
            : `1px solid ${theme.colors.pink[300]}`,
          cursor: "pointer",
          ":hover": {
            borderBottom: isEmpty
              ? `1px solid ${theme.colors.gray[500]}`
              : `1px solid ${theme.colors.pink[500]}`,
          },
        },
      }),
    root: css({
      alignItems: "center",
      color: theme.colors.gray[500],
      display: "flex",
      gap: "12px",
      maxWidth: "calc(50% - 16px)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      "@media screen and (max-width: 640px)": {
        maxWidth: "unset",
      },
    }),
    text: css({
      alignItems: "center",
      display: "flex",
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
    }),
  },
  root: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    "@media screen and (max-width: 640px)": {
      flexDirection: "column",
      flexWrap: "unset",
    },
  }),
};
