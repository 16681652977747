import React from "react";

import { Switch } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  handleAirConditioningFilterChange: (_: any, checked: boolean) => void;
  showAirConditioningOnly: boolean;
};

export const AirConditioningFilter: React.FC<Props> = ({
  handleAirConditioningFilterChange,
  showAirConditioningOnly,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Ar condicionado</span>
      <label css={styles.switch.root}>
        <Switch
          active={showAirConditioningOnly}
          onChange={() => {
            handleAirConditioningFilterChange(null, !showAirConditioningOnly);
          }}
          variant="pink"
        />
        <span css={styles.switch.text}>Mostrar somente carros com A/C</span>
      </label>
    </div>
  );
};
