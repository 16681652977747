import React from "react";

import { HotelOutOfPolicyDialog as OutOfPolicyDialog } from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";

import { useHotels } from "../../../hotels.context";

export const HotelOutOfPolicyDialog: React.FC = () => {
  const {
    outOfPolicySelectedHotel: hotel,
    policy,
    handleCloseOutOfPolicyDialog,
    searchInfo,
  } = useHotels();

  if (!!hotel && policy) {
    const selectedOffer = {
      advance: searchInfo ? searchInfo.advanceDays : 0,
      price: hotel.minPrice.value,
      stars: hotel.stars,
      totalGuests: searchInfo?.totalGuests ? searchInfo.totalGuests : 1,
    };

    return (
      <OutOfPolicyDialog
        offer={selectedOffer}
        onClose={handleCloseOutOfPolicyDialog}
        open
        policy={policy}
      />
    );
  }

  return null;
};
