import { Font, Page, Text, View, Document, Image } from "@react-pdf/renderer";
import React from "react";

import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { ItineraryServicesPresenter } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";
import { TravelPoliciesPerOffer } from "~/apps/corporate/models/travel.model";
import logo from "~/apps/shared/assets/logo.png";
import moment from "moment";

import { styles } from "./styles";
import { TravelPlanPDFPriceBreakdown } from "./travel-plan-pdf-price-breakdown/travel-plan-pdf-price-breakdown";
import { TravelPlanPDFServiceJourney } from "./travel-plan-pdf-service-journey/travel-plan-pdf-service-journey";

type Props = {
  infoModel: ItineraryInfoModel | null;
  servicesPresenter: ItineraryServicesPresenter | null;
  travelPoliciesPerOffer: TravelPoliciesPerOffer | null;
};

// Font.register({
//   family: "Inter",
//   fonts: [
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
//       fontWeight: 100,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
//       fontWeight: 200,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
//       fontWeight: 300,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
//       fontWeight: 400,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
//       fontWeight: 500,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
//       fontWeight: 600,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
//       fontWeight: 700,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
//       fontWeight: 800,
//     },
//     {
//       src:
//         "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
//       fontWeight: 900,
//     },
//   ],
// });

export const TravelPlanPDF: React.FC<Props> = ({
  infoModel,
  servicesPresenter,
  travelPoliciesPerOffer,
}) => {
  const logoStyles = styles.logo();
  const infoStyles = styles.info();
  const rootStyles = styles.root();
  const servicesStyles = styles.services();

  if (!infoModel || !servicesPresenter || !travelPoliciesPerOffer) {
    return null;
  }

  return (
    <Document>
      <Page size="A4" style={rootStyles.root}>
        <View style={rootStyles.header}>
          <View style={logoStyles.root}>
            <Image src={logo} style={logoStyles.image} />
            <View style={logoStyles.name}>
              <Text>smartrips</Text>
            </View>
          </View>
          <View style={rootStyles.count}>
            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <View style={rootStyles.body}>
          <View style={infoStyles.root}>
            <View style={infoStyles.title}>
              <Text>Itinerário da viagem:</Text>
            </View>
            <View style={infoStyles.name}>
              <Text>{infoModel.getTravelName()}</Text>
            </View>
          </View>
          <TravelPlanPDFPriceBreakdown servicesPresenter={servicesPresenter} />
          <View style={servicesStyles.root}>
            {servicesPresenter
              .getTravelPlanTabServicesGroupedByDate()
              .map((group, index) => (
                <View style={servicesStyles.container} key={index}>
                  <View style={servicesStyles.date}>
                    <Text>
                      {moment(group.date).format("dddd, DD [de] MMMM")}
                    </Text>
                  </View>
                  <View style={servicesStyles.services}>
                    {group.services.map((servicePresenter) => (
                      <TravelPlanPDFServiceJourney
                        infoModel={infoModel}
                        key={servicePresenter.getOfferToken()}
                        servicePresenter={servicePresenter}
                        travelPoliciesPerOffer={travelPoliciesPerOffer}
                      />
                    ))}
                  </View>
                </View>
              ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
