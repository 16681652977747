import { css } from "@styled-system/css";

export const styles = {
  icon: css({
    "& > svg": {
      strokeWidth: 2,
    },
  }),
  root: ({ disabled }: { disabled?: boolean }) =>
    css({
      backgroundColor: "#ffe8ab",
      borderColor: "#e5d099",
      borderRadius: "6px",
      color: "#806300",
      cursor: disabled ? "unset" : "pointer",
      fontWeight: 500,
      gap: "8px",
      padding: "4px 16px",
      ":hover": {
        backgroundColor: disabled ? "#ffe8ab" : "#ffe8ab",
        borderColor: disabled ? "#e5d099" : "#806300",
        color: disabled ? "#806300" : "#735900",
      },
    }),
};
