import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  additionalCode?: string | null;
  additionalLabel?: string | null;
  reservationCode: string;
  reservationLabel?: string | null;
};

export const ItineraryServiceReservationCodeBadge: React.FC<Props> = ({
  additionalCode,
  additionalLabel = "Bilhete",
  reservationCode,
  reservationLabel = "Código de reserva (Localizador)",
}) => {
  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="info-outline" />
      <span css={styles.text}>
        {reservationLabel}:{" "}
        <strong css={styles.strong}>{reservationCode}</strong>
        {additionalCode ? (
          <>
            {" "}
            - {additionalLabel}:{" "}
            <strong css={styles.strong}>{additionalCode}</strong>
          </>
        ) : null}
      </span>
    </div>
  );
};
