import { css } from "@styled-system/css";
import { theme } from "smartrips-skin";

export const styles = {
  button: css({
    alignItems: "center",
    display: "flex",
    gap: "8px",
    height: "fit-content"
  }),
  header: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 768px)": {
      alignItems: "flex-start",
      flexDirection: "column"
    }
  }),
  list: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxHeight: "768px",
    overflow: "auto"
  }),
  loader: css({
    position: "relative",
    height: "50px",
    marginTop: "5rem",
    ".MuiCircularProgress-root": {
      color: "primary"
    }
  }),
  root: css({
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: 8,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "32px",
    textDecoration: "none",
    transition: "border-color 0.15s ease-in-out",
    width: "100%"
  }),
  update: {
    action: css({
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      height: "fit-content"
    }),
    card: css({
      backgroundColor: theme.colors.gray[0],
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px",
      width: "100%"
    }),
    badge: css({
      alignItems: "center",
      backgroundColor: theme.colors.primary,
      borderRadius: "4px",
      color: "white",
      display: "flex",
      gap: "4px",
      padding: "4px 8px"
    }),
    root: css({
      display: "flex",
      gap: "16px",
      padding: "16px 0"
    })
  }
};
