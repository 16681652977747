import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  center: {
    flight: {
      card: css({
        margin: "16px 0 8px 0",
        width: "fit-content",
        "& > :nth-child(2)": {
          paddingRight: "0",
        },
      }),
      date: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        marginTop: "4px",
        textTransform: "capitalize",
      }),
      disclaimer: {
        icon: css({}),
        root: css({
          backgroundColor: theme.colors.pink[300],
          borderColor: theme.colors.pink[300],
          color: theme.colors.pink[700],
          fontWeight: 500,
          gap: "16px",
          justifyContent: "flex-start",
          lineHeight: "1.125",
          padding: "16px 24px",
          width: "100%",
          ":hover": {
            backgroundColor: theme.colors.pink[300],
            borderColor: theme.colors.pink[300],
          },
        }),
        text: css({}),
      },
      message: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.25",
        marginBottom: "16px",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: "8px 0",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    travel: {
      id: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
  },
  footer: {
    root: css({}),
  },
  top: {
    message: css({
      color: theme.colors.gray[300],
      fontSize: "1rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
};
