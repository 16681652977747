import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

import {
  BusSearchCreationResponse,
  BusTripDetailsContentResponse,
  BusTripsResponse,
} from "../dtos/bus.dto";

export async function addBusOfferToTravel(data: any) {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/offer",
    })
    .then(({ data }) => data);
}

export async function createBusesSearch(data: any) {
  return await api
    .request<BusSearchCreationResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/bus",
    })
    .then(({ data }) => data);
}

export async function getAllTripsDetails(
  allTripsDetailsRequests: {
    busToken: string;
    tripId: string;
  }[],
) {
  return await Promise.all(
    allTripsDetailsRequests.map(({ busToken, tripId }) =>
      getTripDetails({
        busToken,
        tripId,
      }),
    ),
  );
}

export async function getBusTrips(busSearchToken: string) {
  return await api
    .request<BusTripsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/bus/${busSearchToken}/list`,
    })
    .then(({ data }) => data);
}

export async function getTripDetails({
  busToken,
  tripId,
}: {
  busToken: string;
  tripId: string;
}) {
  return await api
    .request<BusTripDetailsContentResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/bus/${busToken}/trips/${tripId}`,
    })
    .then(({ data }) => data);
}
