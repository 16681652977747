import { ERROR } from "@constants";

import {
  ExpenseCategoryCamelCase,
  ExpenseCategoryDto,
} from "@dtos/expense-categories.dto";

import { CustomError } from "~/types";

import * as expensesCategoryApi from "@apis/expenses-category.api";

export async function getClientExpensesCategories() {
  const result: { data?: ExpenseCategoryCamelCase[]; error?: CustomError } = {};

  try {
    result.data = await expensesCategoryApi.getClientExpensesCategories();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR;
    }
  }
  return result;
}

export async function createExpenseCategory(data: Partial<ExpenseCategoryDto>) {
  const result: { data?: ExpenseCategoryCamelCase; error?: CustomError } = {};

  try {
    result.data = await expensesCategoryApi.createExpenseCategory(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_CLIENT_EXPENSES_CATEGORIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_CLIENT_EXPENSES_CATEGORIES_ERROR;
    }
  }
  return result;
}

export async function updateExpenseCategory(
  expenseCategoryToken: string,
  data: Partial<ExpenseCategoryDto>,
) {
  const result: { data?: ExpenseCategoryCamelCase; error?: CustomError } = {};

  try {
    result.data = await expensesCategoryApi.updateExpenseCategory(
      expenseCategoryToken,
      data,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_CLIENT_EXPENSES_CATEGORIES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_CLIENT_EXPENSES_CATEGORIES_ERROR;
    }
  }
  return result;
}
