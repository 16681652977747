import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  amenities: css({
    padding: "4px 8px",
  }),
  card: {
    bottom: {
      name: css({
        fontWeight: 700,
        lineHeight: "24px",
        padding: "4px 8px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
      }),
      policy: css({
        color: theme.colors.pink[500],
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: "20px",
      }),
      price: {
        info: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
          fontWeight: 400,
          padding: "4px 8px",
          verticalAlign: "baseline",
        }),
        value: css({
          color: theme.colors.gray[700],
          fontWeight: 700,
          lineHeight: "28px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        padding: "4px 0",
        width: "100%",
      }),
      top: css({
        display: "flex",
        justifyContent: "space-between",
        padding: "4px 8px",
      }),
    },
    close: css({
      borderRadius: "50%",
      height: "32px",
      opacity: 0.75,
      position: "absolute",
      right: "8px",
      top: "8px",
      width: "32px",
      ":hover": {
        opacity: 1,
      },
    }),
    root: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow:
        "rgba(50, 50, 93, 0.06) 0px 4px 8px 0px, rgba(121, 135, 157, 0.1) 0px 0px 4px 0px, rgba(50, 50, 93, 0.06) 0px 2px 6px 0px",
      color: theme.colors.gray[700],
      fontSize: "1rem",
      fontFamily: theme.fonts.inter,
      overflow: "hidden",
      position: "relative",
      right: "160px",
      transition: "all 0.15s ease 0s",
      width: "288px",
      zIndex: 1,
    }),
    top: {
      picture: css({
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        height: "144px",
        objectFit: "cover",
        width: "100%",
      }),
    },
  },
  link: css({
    cursor: "pointer",
    flexDirection: "column",
    textDecoration: "none",
  }),
  marker: ({ outOfPolicy }: { outOfPolicy: boolean }) =>
    css({
      alignItems: "center",
      backgroundColor: theme.colors.white,
      border: "none",
      borderRadius: "15px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      color: !outOfPolicy ? theme.colors.gray[700] : theme.colors.pink[700],
      cursor: "pointer",
      display: "flex",
      fontFamily: theme.fonts.inter,
      fontSize: "0.75rem",
      fontWeight: 700,
      justifyContent: "center",
      padding: "6px 8px",
      whiteSpace: "nowrap",
      width: "fit-content",
    }),
};
