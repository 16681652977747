import React, { useCallback, useEffect, useMemo } from "react";

import { useLoyaltyPrograms } from "~/apps/corporate/contexts/loyalty-program.context";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";
import { LOYALTY_PROGRAMS } from "~/apps/shared/constants";

import { useUserProfileDrawer } from "../user-profile-drawer.context";

export const RemoveLoyaltyProgramAlertDialog: React.FC = () => {
  const {
    errorOnFetchRemoveLoyaltyProgram,
    isLoadingRemoveLoyaltyProgram,
    removeLoyaltyProgram,
  } = useLoyaltyPrograms();
  const {
    closeRemoveLoyaltyProgramAlertDialog,
    isRemoveLoyaltyProgramAlertDialogOpen,
    selectedLoyaltyProgram,
  } = useUserProfileDrawer();

  const handleClose = useCallback(() => {
    closeRemoveLoyaltyProgramAlertDialog();
  }, [closeRemoveLoyaltyProgramAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!selectedLoyaltyProgram) {
      closeRemoveLoyaltyProgramAlertDialog();

      return;
    }

    await removeLoyaltyProgram(selectedLoyaltyProgram);

    closeRemoveLoyaltyProgramAlertDialog();
  }, [
    closeRemoveLoyaltyProgramAlertDialog,
    removeLoyaltyProgram,
    selectedLoyaltyProgram,
  ]);

  const translatedLoyaltyProgramName = useMemo(() => {
    if (!selectedLoyaltyProgram) {
      return null;
    }

    return selectedLoyaltyProgram.name in LOYALTY_PROGRAMS
      ? LOYALTY_PROGRAMS[selectedLoyaltyProgram.name]
      : null;
  }, [selectedLoyaltyProgram]);

  useEffect(() => {
    if (!errorOnFetchRemoveLoyaltyProgram) {
      return;
    }

    handleClose();
  }, [errorOnFetchRemoveLoyaltyProgram, handleClose]);

  return (
    <AlertDialog
      confirmLabel="Remover"
      isLoading={isLoadingRemoveLoyaltyProgram}
      message={`Deseja realmente remover o programa de fidelidade${
        translatedLoyaltyProgramName ? ` ${translatedLoyaltyProgramName}` : null
      }?`}
      onClose={() => {
        handleClose();
      }}
      onConfirm={async () => {
        await handleConfirm();
      }}
      open={isRemoveLoyaltyProgramAlertDialogOpen}
      title="Remover programa de fidelidade."
    />
  );
};
