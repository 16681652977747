import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  FlightFamilyOffer,
  FlightListOffer,
} from "~/apps/corporate/models/offer.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Badge, Button } from "@toolkit/v2";

import {
  FlightFareBound,
  FlightFareBoundSkeleton,
} from "../../../../../../components/flight-fare-bound/flight-fare-bound";
import { styles } from "./styles";

interface Props {
  flight: FlightListOffer;
  flightIsInternational: boolean;
  onSelect: (fareFamily: FlightFamilyOffer) => () => void;
}

export const FlightFareList: React.FC<Props> = ({
  flight,
  flightIsInternational,
  onSelect,
}) => {
  return (
    <div css={styles.root}>
      {flight.familyOffers.map((fareFamily) => (
        <FlightFareItem
          fareFamily={fareFamily}
          flight={flight}
          flightIsInternational={flightIsInternational}
          key={fareFamily.itineraryId}
          onSelect={onSelect(fareFamily)}
        />
      ))}
    </div>
  );
};

export const FlightFareListSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      {Array(2)
        .fill(0)
        .map((_, index) => (
          <FlightFareItemSkeleton key={index} short={(index + 1) % 2 !== 0} />
        ))}
    </div>
  );
};

type FlightFareItemProps = {
  fareFamily: FlightFamilyOffer;
  flight: FlightListOffer;
  flightIsInternational: boolean;
  onSelect(): void;
};

const FlightFareItem: React.FC<FlightFareItemProps> = ({
  fareFamily,
  flight: { inbound, outbound },
  flightIsInternational,
  onSelect,
}) => {
  const { inbound: inboundFamily, outbound: outboundFamily } = fareFamily;

  return (
    <div css={styles.item.container}>
      {fareFamily.outOfPolicy ? (
        <div css={styles.item.policy.root}>
          <Badge css={styles.item.policy.badge.root}>
            <Icon
              css={styles.item.policy.badge.icon}
              size={16}
              use="exclamation-triangle"
            />
            Fora da política
          </Badge>
        </div>
      ) : null}
      <div css={styles.item.root}>
        <FlightFareBound
          bound={outbound}
          boundFamily={outboundFamily}
          flightIsInternational={flightIsInternational}
        />
        {inbound && inboundFamily ? (
          <>
            <hr css={styles.item.divisor} />
            <FlightFareBound
              bound={inbound}
              boundFamily={inboundFamily}
              flightIsInternational={flightIsInternational}
            />
          </>
        ) : null}
        <div css={styles.item.bottom.root}>
          <span css={styles.item.bottom.price}>
            {toCurrency(fareFamily.price)}
          </span>
          <Button
            css={styles.item.bottom.button}
            onClick={onSelect}
            variant="pink"
          >
            Selecionar tarifa
          </Button>
        </div>
      </div>
    </div>
  );
};

type FlightFareItemSkeletonProps = {
  short?: boolean;
};

const FlightFareItemSkeleton: React.FC<FlightFareItemSkeletonProps> = ({
  short,
}) => {
  return (
    <div css={styles.item.container}>
      <div css={styles.item.root}>
        <FlightFareBoundSkeleton />
        {!short ? (
          <>
            <hr css={styles.item.divisor} />
            <FlightFareBoundSkeleton />
          </>
        ) : null}
        <hr css={styles.item.divisor} />
        <div css={styles.item.bottom.root}>
          <span css={styles.item.bottom.price}>
            <Skeleton height="24px" width="128px" />
          </span>
          <Button css={styles.item.bottom.button} disabled variant="pink">
            Selecionar tarifa
          </Button>
        </div>
      </div>
    </div>
  );
};
