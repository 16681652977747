import React, { useCallback } from "react";

import { styles } from "./styles";

export const NavBarDesktopHelpButton: React.FC = () => {
  const handleClick = useCallback(() => {
    const octachat = window.octadesk?.chat;

    if (!octachat) {
      return;
    }

    window.octadesk.chat.show();
    window.octadesk.chat.showApp();
    window.octadesk.chat.hideButton();
  }, []);

  return (
    <button
      css={styles.root}
      onClick={() => {
        handleClick();
      }}
    >
      Precisa de ajuda?
    </button>
  );
};
