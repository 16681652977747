import GoogleMap, { Coords, ChangeEventValue } from "google-map-react";
import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { RentalOption, CarSupplier } from "~/apps/corporate/models/car.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ZoomInMarker } from "~/apps/shared/components/location-marker/location-marker";
import { capitalizeFirstLetter } from "~/apps/shared/utils/capitalize-first-letter";

import { Button } from "@toolkit/v2";

import { useCars } from "../cars.context";
import { RentalMarker } from "./rental-marker/rental-marker";
import { styles } from "./styles";

type Props = {
  isExpanded?: boolean;
};

export const CarsMap: React.FC<Props> = ({ isExpanded = false }) => {
  const {
    filters,
    handleApplyAddressFilter,
    handleClearAddressFilter,
    handleToggleMapVisibility,
    searchInfo,
    selectedAgencyCoords,
    visibleCars,
  } = useCars();

  const searchPointCoords = {
    lat: parseFloat(searchInfo!.pickupLatitude),
    lng: parseFloat(searchInfo!.pickupLongitude),
  };

  const defaultCenter = selectedAgencyCoords
    ? selectedAgencyCoords
    : searchPointCoords;

  const [center, setCenter] = useState<Coords>(defaultCenter);
  const [selectedRental, setSelectedRental] = useState<RentalOption | null>(
    null,
  );

  const handleClickOutside = useCallback(() => {
    setSelectedRental(null);
  }, []);

  const handleMapChange = useCallback(
    ({ center: selectedCenter }: ChangeEventValue) => {
      setCenter(selectedCenter);
    },
    [],
  );

  const handleMarkerClick = (rental: RentalOption) => (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!!selectedRental && selectedRental.rentalId === rental.rentalId) {
      return;
    }

    setCenter({
      lat: rental.lat,
      lng: rental.lng,
    });
    setSelectedRental(rental);
  };

  const rentals = visibleCars.reduce(
    (prev, curr) => {
      const { rentalDetails, pickup, supplierInfo } = curr;
      const { id: rentalId, name, logo } = rentalDetails;
      const { address, latitude, longitude } = pickup;

      const coordsId = [latitude, longitude].join("_");
      const id = [rentalId, latitude, longitude].join("_");

      if (!prev.tmp[coordsId]) {
        prev.tmp[coordsId] = {};
        prev.out[coordsId] = [];
      }

      if (!prev.tmp[coordsId] || !prev.tmp[coordsId]![rentalId]) {
        const rental: RentalOption = {
          address,
          color: "",
          id,
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
          logo: logo,
          name: capitalizeFirstLetter(name),
          rentalId: rentalId,
          supplier:
            (supplierInfo?.supplier || rentalDetails.name).toLowerCase() ===
            CarSupplier.LOCALIZA.toLowerCase()
              ? CarSupplier.LOCALIZA
              : CarSupplier.MOVIDA,
        };

        if (prev.tmp[coordsId]) {
          prev.tmp[coordsId]![rentalId] = rental;
        }

        prev.out[coordsId].push(rental);
      }

      return prev;
    },
    {
      out: {} as { [key: string]: RentalOption[] },
      tmp: {} as {
        [key: string]: { [key: string]: RentalOption | undefined } | undefined;
      },
    },
  );

  const flattenedRentals = Object.keys(rentals.out).map(
    (key) => rentals.out[key],
  );

  return (
    <div css={styles.root({ isExpanded })}>
      <Button css={styles.button.root} onClick={handleToggleMapVisibility}>
        <Icon size={14} use={!isExpanded ? "search-outline" : "chevron-left"} />
        {!isExpanded ? "Buscar no mapa" : "Voltar para a lista"}
      </Button>
      <GoogleMap
        center={center}
        defaultZoom={13}
        onChange={handleMapChange}
        onClick={() => {
          if (isExpanded) {
            handleClickOutside();

            return;
          }

          handleToggleMapVisibility();

          window.scrollTo(0, 0);
        }}
        options={
          isExpanded
            ? {
                gestureHandling: "greedy",
                panControl: true,
                streetViewControl: true,
              }
            : {
                fullscreenControl: false,
                draggableCursor: "pointer",
                gestureHandling: "greedy",
                panControl: false,
                streetViewControl: false,
                zoomControl: false,
              }
        }
      >
        {flattenedRentals.map((flattenedRental) => {
          const rental = flattenedRental[0];

          const hasManyAgencies = flattenedRental.length > 1;
          const isVisible = !!selectedRental && selectedRental.id === rental.id;

          return (
            <RentalMarker
              filters={filters}
              handleApplyAddressFilter={handleApplyAddressFilter(rental)}
              handleClearAddressFilter={handleClearAddressFilter}
              hasManyAgencies={hasManyAgencies}
              isVisible={isVisible}
              key={rental.id}
              lat={rental.lat}
              lng={rental.lng}
              onClick={handleMarkerClick(rental)}
              onClose={handleClickOutside}
              rental={rental}
            />
          );
        })}
        <ZoomInMarker
          lat={defaultCenter.lat}
          lng={defaultCenter.lng}
          type="search-point"
        />
      </GoogleMap>
    </div>
  );
};

export const CarsMapSkeleton: React.FC = () => {
  return (
    <div css={styles.root({ isExpanded: false })}>
      <Skeleton height="100%" width="100%" />
    </div>
  );
};
