import React from "react";

import { Table, TableRow, TableBody, TableCell } from "@material-ui/core";
import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

import Utils from "@helpers";

import { DetailsCard } from "../../shared/DetailsCard";
import { useFlightInfoContext } from "../FlightInfo.context";
import { formatPercentage } from "../FlightInfo.utils";

const styles = {
  detailRow: css({
    py: 12,
    "& .MuiTableCell-root": {
      px: 0,
      fontSize: [0, 1, 1],
      borderBottom: "none",
      color: defaultTheme.subTextColor
    }
  }),
  detailName: css({
    mr: "auto"
  })
};

const AlterFeeCard = () => {
  const { wastesInfo } = useFlightInfoContext();

  return (
    <DetailsCard
      title={"Alteração de vôo"}
      total={wastesInfo.totalAlterFee + wastesInfo.totalAlterDifference}
    >
      <Table>
        <TableBody>
          <TableRow css={styles.detailRow}>
            <TableCell css={styles.detailName}>Multas de alteração</TableCell>
            <TableCell align="right">
              {Utils.toCurrency(wastesInfo.totalAlterFee)}
            </TableCell>
            <TableCell align="right">
              {formatPercentage(wastesInfo.alterFeePercentage)}
            </TableCell>
          </TableRow>
          <TableRow css={styles.detailRow}>
            <TableCell css={styles.detailName}>
              Diferença para nova tarifa
            </TableCell>
            <TableCell align="right">
              {Utils.toCurrency(wastesInfo.totalAlterDifference)}
            </TableCell>
            <TableCell align="right">
              {formatPercentage(wastesInfo.alterDifferencePercentage)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DetailsCard>
  );
};

export { AlterFeeCard };
