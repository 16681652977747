import React from "react";

import { Menu as MuiMenu } from "@material-ui/core";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof MuiMenu>;

export const Menu: React.FC<Props> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <MuiMenu {...props} />
    </div>
  );
};
