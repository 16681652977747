import React, { useMemo } from "react";

import { UserAutocomplete } from "~/apps/corporate/components/user-autocomplete/user-autocomplete";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";

import { Button } from "@toolkit/v2";

import { ItineraryServicePresenter } from "../../models/itinerary/itinerary-service.presenter";
import { OfferTraveler } from "../../models/offer.model";
import {
  notEnoughTravelersSchema,
  NotEnoughTravelersSchema,
} from "./not-enough-travelers.schema";
import { styles } from "./styles";

type Props = {
  disabled?: boolean;
  offersTravelers: Record<string, OfferTraveler[]>;
  onSubmit: (data: {
    offersTravelers: Record<
      string,
      {
        main: number;
        userToken: string;
      }[]
    >;
  }) => void;
  services: ItineraryServicePresenter[];
};

export const NotEnoughTravelersForm: React.FC<Props> = ({
  disabled,
  offersTravelers,
  onSubmit,
  services,
}) => {
  const defaultValues = useMemo(() => {
    return {
      offersTravelers: services.reduce((prev, curr) => {
        const offerToken = curr.getOfferToken();

        if (!(offerToken in offersTravelers)) {
          return prev;
        }

        const offerTravelers = offersTravelers[offerToken];

        const serviceTotalTravelers = curr.getTotalTravelers();

        return {
          ...prev,
          [offerToken]: Array(serviceTotalTravelers)
            .fill(null)
            .map((_, index) => {
              if (index >= offerTravelers.length) {
                return {
                  main: 0,
                  userToken: "",
                };
              }

              const offerTraveler = offerTravelers[index];

              return {
                fullName: offerTraveler.fullName,
                main: offerTraveler.main,
                userToken: offerTraveler.userToken,
              };
            }),
        };
      }, {}),
    } as NotEnoughTravelersSchema;
  }, [offersTravelers, services]);

  const form = useForm<NotEnoughTravelersSchema>({
    defaultValues,
    onSubmit: async () => {
      const values = form.getValues() as NotEnoughTravelersSchema;

      const offersTravelersFilteredByMainTraveler = Object.entries(
        values.offersTravelers,
      ).reduce(
        (prev, [k, v]) => {
          if (!v) {
            return prev;
          }

          const mainTraveler = offersTravelers[k].find(
            (offerTraveler) => offerTraveler.main === 1,
          );

          if (!mainTraveler) {
            return {
              ...prev,
              [k]: v,
            };
          }

          return {
            ...prev,
            [k]: v.filter(
              (offerTraveler) =>
                offerTraveler.userToken !== mainTraveler.userToken,
            ),
          };
        },
        {} as Record<
          string,
          {
            main: number;
            userToken: string;
          }[]
        >,
      );

      onSubmit({
        offersTravelers: offersTravelersFilteredByMainTraveler,
      });
    },
    schema: notEnoughTravelersSchema,
  });

  return (
    <Form context={form} css={styles.form.root}>
      {services.map((service) => {
        const formattedName = service.isHotelService() ? (
          <>
            {service.getNights()} noites em {service.getHotelName()} em{" "}
            <strong>{service.getCity()}</strong>
          </>
        ) : (
          service.getFormattedName()
        );

        const offerToken = service.getOfferToken();

        return (
          <div css={styles.form.service.root} key={offerToken}>
            <div css={styles.form.service.label.root}>
              <Icon size={16} use={service.getIcon()} />
              <span css={styles.form.service.label.text}>{formattedName}*</span>
            </div>
            {Array(service.getTotalTravelers())
              .fill(null)
              .map((_, index) => {
                const formattedLabel = service.isHotelService()
                  ? `Hóspede ${index === 0 ? "principal" : index + 1}:`
                  : `Viajante ${index === 0 ? "principal" : index + 1}:`;

                const namePrefix = `offersTravelers[${offerToken}][${index}]`;

                return (
                  <div
                    css={styles.form.service.input.root}
                    key={`${offerToken}-${index}`}
                  >
                    <span css={styles.form.service.input.label}>
                      {formattedLabel}
                    </span>
                    <Form.Field<
                      | {
                          fullName?: string;
                          main?: string;
                          userToken: string;
                        }
                      | undefined
                    >
                      name={namePrefix}
                      render={({ value }) => {
                        if (value && value.main) {
                          return (
                            <div css={styles.form.service.input.display}>
                              {value.fullName} (principal)
                            </div>
                          );
                        }

                        return (
                          <UserAutocomplete
                            css={styles.form.service.input.autocomplete}
                            filterItems={(users) => {
                              const offerTravelers = form.watch(
                                `offersTravelers[${offerToken}]`,
                              );

                              if (!offerTravelers) {
                                return users;
                              }

                              return users.filter((user) => {
                                if (
                                  Object.values(offerTravelers).some(
                                    (offerTraveler: any) =>
                                      offerTraveler.userToken ===
                                      user.userToken,
                                  )
                                ) {
                                  return false;
                                }

                                return true;
                              });
                            }}
                            onChange={(value) => {
                              if (!value) {
                                form.setValue(`${namePrefix}.userToken`, "");
                              }
                            }}
                            onSelect={(value) => {
                              form.setValue(
                                `${namePrefix}.userToken`,
                                value.userToken,
                              );
                            }}
                            value={value ? value.fullName : undefined}
                          />
                        );
                      }}
                    />
                    <InputErrorMessage>
                      {
                        form.errors["offersTravelers"]?.[offerToken]?.[index]?.[
                          "userToken"
                        ]?.message
                      }
                    </InputErrorMessage>
                  </div>
                );
              })}
          </div>
        );
      })}
      <Button
        css={styles.button}
        disabled={disabled || form.formState.isSubmitting}
      >
        Salvar
      </Button>
    </Form>
  );
};
