import moment from "moment";
import { FINANCIAL_TABS, FINANCIAL_TYPES } from "../constants";

export function getYearList() {
  const minYear = 2019;
  const maxYear = moment().year() + 1;
  const yearDiff = maxYear - minYear;

  return new Array(yearDiff + 1).fill(0).map((_, index) => minYear + index);
}

export function getSelectedType(tab: number) {
  switch (tab) {
    case FINANCIAL_TABS.FLIGHTS:
      return FINANCIAL_TYPES.FLIGHT;
    case FINANCIAL_TABS.HOTELS:
      return FINANCIAL_TYPES.HOTEL;
    case FINANCIAL_TABS.CARS:
      return FINANCIAL_TYPES.CAR;
    case FINANCIAL_TABS.BUSES:
      return FINANCIAL_TYPES.BUS;
    case FINANCIAL_TABS.SMARTRIPS:
      return FINANCIAL_TYPES.SMARTRIPS;
    default:
      return FINANCIAL_TYPES.FLIGHT;
  }
}
