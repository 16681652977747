import React, { useState, memo } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import { css } from "@styled-system/css";
import moment from "moment";

import { Flex, Box } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import {
  ADVANCED_EXPENSE_STATUS,
  ADVANCED_EXPENSE_STATUS_LABEL,
  ADVANCED_EXPENSE_STATUS_COLOR
} from "@constants";

import { AdvancedExpense } from "../AdvancedExpenses.types";
import * as utils from "../AdvancedExpenses.utils";

const styles = {
  listItem: css({
    cursor: "pointer",
    color: defaultTheme.subTextColor,
    fontSize: [12, 16],
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    ["&:not(:last-child)"]: {
      borderBottom: "1px solid",
      borderColor: "border"
    }
  }),
  itemName: css({ flex: 1, fontWeight: 700 }),
  itemValue: css({
    textAlign: "right",
    marginTop: ".3rem",
    fontWeight: 700
  }),
  menuItem: css({
    fontSize: 14
  }),
  status: (status: AdvancedExpense["status"]) =>
    css({
      fontSize: 10,
      fontWeight: "bold",
      p: "2px 13px",
      marginRight: "1rem",
      lineHeight: 1.5,
      borderRadius: "60px",
      backgroundColor: `${ADVANCED_EXPENSE_STATUS_COLOR[status]}`,
      color: "white"
    })
};

interface Props {
  item: AdvancedExpense;
  handleOpenDetails: (expenseAdvanceToken: string) => () => void;
  addToReport: (
    expenseAdvanceToken: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}

const MobileItem = memo(({ item, handleOpenDetails, addToReport }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOption = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleAddToReport = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
    addToReport(item.expenseAdvanceToken, e);

  const canBeAddedToReport = item.status === ADVANCED_EXPENSE_STATUS.CLOSED;

  return (
    <Flex
      onClick={handleOpenDetails(item.expenseAdvanceToken)}
      css={styles.listItem}
    >
      <Box css={styles.itemName}>{item.name}</Box>
      <Box css={styles.status(item.status)}>
        {ADVANCED_EXPENSE_STATUS_LABEL[item.status]}
      </Box>
      <Flex flexDirection="column">
        <Box>{moment(item.createdAt).format("DD MMM YYYY")}</Box>
        <Box css={styles.itemValue}>
          {utils.parseValue(item.requestedValue)}
        </Box>
      </Flex>
      <Box>
        {canBeAddedToReport ? (
          <>
            <Box css={css({ marginLeft: "1rem" })} onClick={handleClick}>
              <MoreIcon />
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseOption}
            >
              <MenuItem css={styles.menuItem} onClick={handleAddToReport}>
                Adicionar a relatório
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Box>
    </Flex>
  );
});

export { MobileItem };
