import React from "react";

import { navigate } from "@reach/router";

import { EXPENSE_REPORT_STATUS } from "~/constants";

import * as expensesHelper from "@helpers/expense.helper";

import { ExpenseReportWithUser } from "@models/expense.model";

import { ReportItemPresentational } from "./ReportItem.presentational";

interface IProps {
  report: ExpenseReportWithUser;
  handleRemoval?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const ReportItemContainer: React.FC<IProps> = ({ report, handleRemoval }) => {
  function getFormattedReportPeriod() {
    const { periodStart, periodEnd } = expensesHelper.calculateExpensesPeriod(
      report.expenses
    );

    return `${periodStart.format("DD MMM YYYY")} - ${periodEnd.format(
      "DD MMM YYYY"
    )}`;
  }

  function handleNavigate() {
    navigate(`/reports/${report.expenseReportToken}`, {
      state: { origin: "/expenes/reports" }
    });
  }

  const totalPrice = expensesHelper.getReportTotalPrice(
    report.expenses,
    report.status
  );

  const allowRemoval =
    !!handleRemoval &&
    !report.travelToken &&
    (report.status === EXPENSE_REPORT_STATUS.OPEN ||
      report.status === EXPENSE_REPORT_STATUS.DECLINED);

  return (
    <ReportItemPresentational
      report={report}
      totalPrice={totalPrice}
      hasForeignCurrencyExpenses={expensesHelper.hasForeignCurrencyExpenses(
        report.expenses
      )}
      formattedPeriod={getFormattedReportPeriod()}
      allowRemoval={allowRemoval}
      onClick={handleNavigate}
      handleRemove={handleRemoval}
    />
  );
};

export { ReportItemContainer as ReportItem };
