import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  range: css({
    display: "flex",
    ".Calendar": {},
    ".Date": {
      "&RangePicker": {
        width: "100%",
        "&_picker": {
          background: "transparent",
          top: "calc(100% + 16px) !important",
        },
        "&Input": {
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: "8px",
          display: "flex",
          gap: "12px",
          width: "100%",
          "&_calendarIcon": {
            margin: 0,
            padding: 0,
          },
        },
      },
      "&Input": {
        background: "unset",
        width: "100%",
        "&_input": {
          backgroundColor: "transparent",
          borderBottom: "none !important",
          color: theme.colors.gray[700],
          fontFamily: theme.fonts.inter,
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
          padding: "unset",
          width: "100%",
        },
      },
    },
    ".DayPicker": {
      borderRadius: "8px",
      overflow: "hidden",
      "&Navigation": {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 16px",
        top: "18px",
        "& > :nth-of-type(1)": {
          transform: "rotate(0deg)",
        },
        "& > :nth-of-type(2)": {
          transform: "rotate(0deg)",
        },
        "&_button": {
          alignItems: "center",
          aspectRatio: "1",
          borderRadius: "12px",
          color: theme.colors.gray[300],
          display: "flex",
          height: "24px",
          justifyContent: "center",
          padding: "4px",
          transition: "all 0.15s ease-in-out",
          width: "24px",
          ":hover": {
            color: theme.colors.gray[700],
          },
        },
      },
      "&_weekHeaders": {
        top: "-8px",
      },
    },
  }),
  single: css({
    display: "flex",
    ".Calendar": {},
    ".Date": {
      "&Input": {
        background: "unset",
        width: "100%",
        "&_input": {
          backgroundColor: "transparent",
          borderBottom: "none !important",
          color: theme.colors.gray[700],
          fontFamily: theme.fonts.inter,
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
          outline: "none",
          padding: "unset",
          width: "100%",
        },
      },
    },
    ".DayPicker": {
      borderRadius: "8px",
      overflow: "hidden",
      "&Navigation": {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 16px",
        top: "18px",
        "& > :nth-of-type(1)": {
          transform: "rotate(0deg)",
        },
        "& > :nth-of-type(2)": {
          transform: "rotate(0deg)",
        },
        "&_button": {
          alignItems: "center",
          aspectRatio: "1",
          borderRadius: "12px",
          color: theme.colors.gray[300],
          display: "flex",
          height: "24px",
          justifyContent: "center",
          padding: "4px",
          transition: "all 0.15s ease-in-out",
          width: "24px",
          ":hover": {
            color: theme.colors.gray[700],
          },
        },
      },
      "&_weekHeaders": {
        top: "-8px",
      },
    },
    ".SingleDatePicker": {
      width: "100%",
      "&_picker": {
        background: "transparent",
        top: "calc(100% + 16px) !important",
      },
      "&Input": {
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "8px",
        display: "flex",
        gap: "12px",
        width: "100%",
        "&_calendarIcon": {
          margin: 0,
          padding: 0,
        },
      },
    },
  }),
};
