import React from "react";

import { LockOutlined as LockIcon } from "@material-ui/icons";
import { navigate } from "@reach/router";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import { Spacing } from "~/components/shared/layout";

import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventSettingsPrivacy: React.VFC<Props> = ({ event }) => {
  const user = getUserFromLocalStorage();

  return (
    <div css={styles.root}>
      <Flex alignItems="center">
        {event.privacy === "PUBLIC" ? <LockIcon /> : <LockIcon />}
        <Spacing direction="horizontal" space="8px" />
        <Flex flexDirection="column">
          <Text fontSize="1.125rem" fontWeight="bold">
            {event.privacy === "PUBLIC" ? "Público" : "Privado"}
          </Text>
          <Spacing direction="vertical" space="2px" />
          <Text color={theme.colors.gray[2]} fontSize={14}>
            {event.privacy === "PUBLIC"
              ? user
                ? `Todos na ${user.clientName} podem ver e ir a esse evento`
                : "Todos na empresa poderão ver e ir ao evento"
              : "Apenas convidados podem ver e ir a esse evento"}
            .
          </Text>
        </Flex>
      </Flex>
      <Button
        css={styles.button}
        onClick={() => {
          navigate(`/events/${event.token}/edit`);
        }}
      >
        Alterar
      </Button>
    </div>
  );
};
