import * as yup from "yup";

export const travelCategorizationSchema = yup.object().shape({
  billingProfileToken: yup.string().nullable(true),
  companyAreaToken: yup.string().nullable(true),
  costCenterToken: yup.string().nullable(true),
  projectToken: yup.string().nullable(true),
});

export type TravelCategorizationSchema = yup.InferType<
  typeof travelCategorizationSchema
>;
