import * as approvalProcessApi from "~/apps/corporate/apis/approval-process.api";
import { SendApprovalResponseRequestBody } from "~/apps/corporate/dtos/approval-status.dto";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const denyOffersApproval = async (offerTokens: string[]) => {
  const result: { error?: Error } = {};

  try {
    await Promise.all(
      offerTokens.map((offerToken) =>
        approvalProcessApi.denyOfferApproval(offerToken),
      ),
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DENY_OFFER_APPROVAL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DENY_OFFER_APPROVAL_ERROR;
    }
  }

  return result;
};

export const sendApprovalResponse = async ({
  approvalRequestToken,
  responseMessage,
  shouldApprove,
}: {
  approvalRequestToken: string;
  responseMessage?: string;
  shouldApprove: boolean;
}) => {
  const result: { error?: Error } = {};

  try {
    const sendApprovalResponseRequestBody: SendApprovalResponseRequestBody = {
      response_message: responseMessage,
    };

    shouldApprove
      ? await approvalProcessApi.approveApprovalRequest(
          approvalRequestToken,
          sendApprovalResponseRequestBody,
        )
      : await approvalProcessApi.denyApprovalRequest(
          approvalRequestToken,
          sendApprovalResponseRequestBody,
        );
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        result.error = ERROR.NO_PERMISSION_APPROVAL_RESPONSE_ERROR;

        return result;
      }

      result.error = ERROR.UNEXPECTED_APPROVAL_RESPONSE_ERROR;

      return result;
    }

    if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;

      return result;
    }

    result.error = ERROR.UNEXPECTED_APPROVAL_RESPONSE_ERROR;
  }

  return result;
};
