import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    root: css({
      padding: "24px",
    }),
  },
  header: {
    close: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[300],
      cursor: "pointer",
      right: "24px",
      position: "absolute",
      top: "24px",
      transition: "color 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.gray[700],
      },
    }),
    root: css({
      justifyContent: "flex-end",
      padding: "24px 24px 0 24px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      paddingRight: "24px",
    }),
  },
  root: css({
    ".MuiDialog-paper": {
      gap: "0",
      overflow: "visible",
      padding: "0",
    },
  }),
};
