import React, { useEffect, useState } from "react";

import { BusTripDetailsContentSeat } from "~/apps/corporate/dtos/bus.dto";
import { isEmpty } from "lodash";
import maxBy from "lodash/maxBy";
import size from "lodash/size";
import uuid from "uuid";

import { BusContainer } from "./bus-container/bus-container";
import { Seat } from "./seat/seat";
import { styles } from "./styles";

type Props = {
  axisZ?: number;
  handleSelect: (selectedSeats: BusTripDetailsContentSeat[]) => void;
  maxSelection?: number;
  seats: BusTripDetailsContentSeat[];
  selectedSeats: BusTripDetailsContentSeat[];
};

export const SeatPicker: React.FC<Props> = ({
  axisZ = 0,
  handleSelect,
  maxSelection = 1,
  seats,
  selectedSeats,
}) => {
  const [maxX, setMaxX] = useState(0);

  const selectedSeatsMap = selectedSeats.reduce(
    (prev, curr) => {
      prev[curr.id] = curr;

      return prev;
    },
    {} as {
      [key: string]: BusTripDetailsContentSeat;
    },
  );

  const onSeatClick = (seat: BusTripDetailsContentSeat) => () => {
    const isAlreadySelected = seat.id in selectedSeatsMap;
    const selectedSize = size(selectedSeats);

    const hasAchievedMaxLength = selectedSize === maxSelection;

    if (hasAchievedMaxLength && !isAlreadySelected) {
      return null;
    }

    if (!isAlreadySelected) {
      const updatedSeats = selectedSeats.concat(seat);
      handleSelect(updatedSeats);
    } else {
      const updatedSeats = selectedSeats.filter((item) => item.id !== seat.id);
      handleSelect(updatedSeats);
    }
  };

  const renderSeats = () => {
    return seats
      .filter((seat) => parseInt(seat.position.z, 10) === axisZ)
      .map((seat) => {
        return (
          <div
            css={styles.seats.seat.container({ position: seat.position })}
            key={seat.id ? seat.id : uuid()}
          >
            <Seat
              disabled={!seat.available}
              label={seat.name}
              onClick={onSeatClick(seat)}
              selected={!!selectedSeatsMap[seat.id]}
            />
          </div>
        );
      });
  };

  useEffect(() => {
    if (isEmpty(seats)) {
      return;
    }

    const maxXSeat = maxBy(seats, (seat) => parseInt(seat.position.x, 10));

    if (!maxXSeat) {
      return;
    }

    setMaxX(parseInt(maxXSeat.position.x, 10));
  }, [seats]);

  const containerWidth = 44 * (maxX + 1) + 52;

  return (
    <BusContainer width={`${containerWidth}px`} zAxis={axisZ}>
      <div css={styles.seats.container({ maxX })}>{renderSeats()}</div>
    </BusContainer>
  );
};
