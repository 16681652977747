import React, { useContext } from "react";

import Drawer from "@material-ui/core/Drawer";
import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { withFormik, FormikErrors, FormikProps } from "formik";
import isEmpty from "lodash/isEmpty";

import DrawerHeader from "@shared/DrawerHeader";

import { defaultTheme } from "../../../../../assets/styles/theme";
import * as expensePolicyHelper from "../../../../../helpers/expense-policy.helper";
import { AirportAutosuggest } from "../../../../../models/autosuggest.model";
import { ExpensePolicy } from "../../../../../models/expense-policy.model";
import { DrawerSpinner } from "../../../../shared/DrawerSpinner";
import { EmptyForm } from "../../../shared/EmptyForm";
import { ExpensePolicyContext } from "../ExpensePolicy.context";
import {
  ExpensePolicyForm,
  FlightPolicyRule,
  HotelPolicyRule,
  ExpensePolicyTarget,
} from "../ExpensePolicy.types";
import { PolicyFormPresentational } from "./PolicyForm.presentational";

const styles = {
  drawerPaper: css({
    [".MuiDrawer-paper"]: {
      minWidth: 815,
    },
  }),
  drawerHeader: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  }),
};

type Props = {
  handleFormClose: () => void;
  isSaving: boolean;
  onSubmit: (values: ExpensePolicyForm) => void;
  selectedPolicy: ExpensePolicy | null;
};

interface PolicyFormProps extends FormikProps<ExpensePolicyForm> {
  handleFormClose: () => void;
  isSaving: boolean;
  selectedPolicy: ExpensePolicy | null;
}

const PolicyFormContainer = ({
  errors,
  handleBlur,
  handleChange,
  handleFormClose,
  handleSubmit,
  isSaving,
  isValid,
  selectedPolicy,
  setFieldValue,
  touched,
  values,
}: PolicyFormProps) => {
  const { showSnackMessage } = useApplication();

  const handleCurrencyChange = (fieldName: string) => (value: number) => {
    setFieldValue(fieldName, value);
  };

  const onRuleRemoval = (arrayHelpers: any, index: number) => () => {
    arrayHelpers.remove(index);
  };

  const handleAddFlightRule = (
    arrayHelpers: any,
    nationalType: string,
  ) => () => {
    arrayHelpers.push({
      destinationCity: "",
      destinationCode: "",
      destinationName: "",
      maxValue: 0,
      nationalType,
      offerType: "flight",
      originCity: "",
      originCode: "",
      originName: "",
      routeType: "roundtrip",
    });
  };

  const handleAddHotelRule = (
    arrayHelpers: any,
    nationalType: string,
  ) => () => {
    arrayHelpers.push({
      locationCity: "",
      locationCountry: "",
      locationName: "",
      locationState: "",
      nationalType,
      offerType: "hotel",
    });
  };

  const handleChangeAirport = (
    fieldName: string,
    segment: "origin" | "destination",
    previousData: FlightPolicyRule,
  ) => (data: AirportAutosuggest) => {
    if (segment === "origin") {
      setFieldValue(fieldName, {
        ...previousData,
        originCity: data.CityId,
        originCode: data.PlaceId,
        originName: data.PlaceName,
      });
    } else {
      setFieldValue(fieldName, {
        ...previousData,
        destinationCity: data.CountryId,
        destinationName: data.PlaceName,
        originCode: data.PlaceId,
      });
    }
  };

  const handleChangeHotel = (
    fieldName: string,
    previousData: HotelPolicyRule,
  ) => (data: any) => {
    if (data) {
      setFieldValue(fieldName, {
        ...previousData,
        locationCity: data.city,
        locationCountry: data.country,
        locationName: data.formattedAddress,
        locationState: data.state,
      });
    } else {
      setFieldValue(fieldName, {
        ...previousData,
        locationCity: "",
        locationCountry: "",
        locationName: "",
        locationState: "",
      });
    }
  };

  const handleSelectTarget = (target: ExpensePolicyTarget) => {
    const isAlreadyInserted = values.targets.some(
      (item) => item.userToken === target.userToken,
    );

    const originalTarget = selectedPolicy
      ? selectedPolicy.targets.find(
          (item) => item.userToken === target.userToken,
        )
      : null;

    target.policyTargetToken = originalTarget
      ? originalTarget.policyTargetToken
      : undefined;

    if (target.inUse && !originalTarget) {
      showSnackMessage(
        "O usuário selecionado já está participando de outra política",
        ALERT_TYPES.ERROR,
      );
    } else if (!isAlreadyInserted) {
      const updatedTargets = values.targets.concat(target);

      setFieldValue("targets", updatedTargets);
    }
  };

  const handleRemoveTarget = (targetToken: string) => () => {
    const updatedTargets = values.targets.filter(
      (target) => target.userToken !== targetToken,
    );
    setFieldValue("targets", updatedTargets);
  };

  return (
    <PolicyFormPresentational
      errors={errors}
      handleAddFlightRule={handleAddFlightRule}
      handleAddHotelRule={handleAddHotelRule}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleChangeAirport={handleChangeAirport}
      handleChangeHotel={handleChangeHotel}
      handleCurrencyChange={handleCurrencyChange}
      handleFormClose={handleFormClose}
      handleRemoveTarget={handleRemoveTarget}
      handleRuleRemoval={onRuleRemoval}
      handleSelectTarget={handleSelectTarget}
      handleSubmit={handleSubmit}
      isSubmitting={isSaving}
      isValid={isValid}
      touched={touched}
      values={values}
    />
  );
};

const FormikContainer = withFormik<Props, ExpensePolicyForm>({
  mapPropsToValues: (props) => {
    return expensePolicyHelper.mapExpensePolicyToFormData(props.selectedPolicy);
  },
  validate: (values) => {
    const errors: FormikErrors<ExpensePolicyForm> = {};

    if (!values.description) {
      errors.description = "Preencha a descrição da política";
    }

    if (!isEmpty(values.nationalHotelRules)) {
      errors.nationalHotelRules = [];
      values.nationalHotelRules.forEach((item: HotelPolicyRule, index) => {
        errors.nationalHotelRules!.push({});
        if (!item.locationName) {
          errors.nationalHotelRules![index]!.locationName =
            "Destino obrigatório";
        }

        if (!item.maxValue) {
          errors.nationalHotelRules![index]!.maxValue = "Valor obrigatório";
        }
      });
    }

    if (
      errors.nationalHotelRules &&
      errors.nationalHotelRules.every((item) => Object.keys(item!).length === 0)
    ) {
      delete errors.nationalHotelRules;
    }

    if (!isEmpty(values.internationalHotelRules)) {
      errors.internationalHotelRules = [];
      values.internationalHotelRules.forEach((item: HotelPolicyRule, index) => {
        errors.internationalHotelRules!.push({});
        if (!item.locationName) {
          errors.internationalHotelRules![index]!.locationName =
            "Destino obrigatório";
        }

        if (!item.maxValue) {
          errors.internationalHotelRules![index]!.maxValue =
            "Valor obrigatório";
        }
      });
    }

    if (
      errors.internationalHotelRules &&
      errors.internationalHotelRules.every(
        (item) => Object.keys(item!).length === 0,
      )
    ) {
      delete errors.internationalHotelRules;
    }

    return errors;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(PolicyFormContainer);

const FormDrawer = () => {
  const {
    fetchingExpensePolicy,
    handleCloseForm,
    handleSubmitPolicy,
    isFormVisible,
    isSaving,
    selectedPolicyToEdit,
  } = useContext(ExpensePolicyContext);

  return (
    <Drawer
      anchor="right"
      css={styles.drawerPaper}
      onClose={handleCloseForm}
      open={isFormVisible}
    >
      {fetchingExpensePolicy ? (
        <EmptyForm title="Editando política" />
      ) : (
        <>
          <DrawerSpinner
            loadingDots={true}
            message="Salvando"
            messageStyle={{ fontSize: 24, color: defaultTheme.subTextColor }}
            visible={isSaving}
          />
          <DrawerHeader
            handleClose={handleCloseForm}
            headerStyles={styles.drawerHeader}
            title={selectedPolicyToEdit ? "Editando política" : "Nova política"}
          />
          <FormikContainer
            handleFormClose={handleCloseForm}
            isSaving={isSaving}
            onSubmit={handleSubmitPolicy}
            selectedPolicy={selectedPolicyToEdit}
          />
        </>
      )}
    </Drawer>
  );
};

export { FormDrawer as PolicyForm };
