import React from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import {
  TravelPolicyForCarOffer,
  TravelPolicyForFlightOffer,
  TravelPolicyForHotelOffer,
} from "~/apps/corporate/models/travel.model";
import {
  CarOutOfPolicyDialog,
  FlightOutOfPolicyDialog,
  HotelOutOfPolicyDialog,
} from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";

import { useItineraryScreen } from "../itinerary-screen.context";
import { useItineraryServices } from "../itinerary-services.context";
import { useItineraryServiceOutOfPolicyDialogPresenter } from "./itinerary-service-out-of-policy-dialog.hooks";

export const ItineraryServiceOutOfPolicyDialog: React.FC = () => {
  const { servicePresenter } = useItineraryServiceOutOfPolicyDialogPresenter();

  const { clientConfig } = useClientConfig();

  const {
    closeServiceOutOfPolicyDialog,
    isServiceOutOfPolicyDialogOpen,
  } = useItineraryScreen();
  const { travelPoliciesPerOffer } = useItineraryServices();

  if (!clientConfig || !servicePresenter || !travelPoliciesPerOffer) {
    return null;
  }

  if (servicePresenter.isBusService()) {
    return null;
  }

  if (servicePresenter.isCarService()) {
    const travelPolicyForCarOffer = travelPoliciesPerOffer[
      servicePresenter.getOfferToken()
    ] as TravelPolicyForCarOffer | undefined;

    const offer: React.ComponentProps<typeof CarOutOfPolicyDialog>["offer"] = {
      carDetails: {
        category: servicePresenter.getClass(),
      },
    };

    const policy: React.ComponentProps<
      typeof CarOutOfPolicyDialog
    >["policy"] = {
      ...travelPolicyForCarOffer,
    };

    return (
      <CarOutOfPolicyDialog
        offer={offer}
        onClose={closeServiceOutOfPolicyDialog}
        open={isServiceOutOfPolicyDialogOpen}
        policy={policy}
      />
    );
  }

  if (servicePresenter.isFlightService()) {
    const travelPolicyForFlightOffer = travelPoliciesPerOffer[
      servicePresenter.getOfferToken()
    ] as TravelPolicyForFlightOffer | undefined;

    const offer: React.ComponentProps<
      typeof FlightOutOfPolicyDialog
    >["offer"] = {
      advance: servicePresenter.getAdvance(),
      class: servicePresenter.getCommomCabinClass() || "",
      price: servicePresenter.getPrice(),
      priceExcess: travelPolicyForFlightOffer?.priceExcess
        ? servicePresenter.getPriceExcess()
        : null,
    };

    const policy: React.ComponentProps<
      typeof FlightOutOfPolicyDialog
    >["policy"] = {
      ...travelPolicyForFlightOffer,
      allowedClasses: clientConfig.getFlightAllowedCabinClasses(),
      maxValueVisible: travelPolicyForFlightOffer?.maxValue,
      price: travelPolicyForFlightOffer?.maxValue || 0,
    };

    return (
      <FlightOutOfPolicyDialog
        offer={offer}
        onClose={closeServiceOutOfPolicyDialog}
        open={isServiceOutOfPolicyDialogOpen}
        policy={policy}
      />
    );
  }

  if (servicePresenter.isHotelService()) {
    const travelPolicyForHotelOffer = travelPoliciesPerOffer[
      servicePresenter.getOfferToken()
    ] as TravelPolicyForHotelOffer | undefined;

    const offer: React.ComponentProps<
      typeof HotelOutOfPolicyDialog
    >["offer"] = {
      advance: servicePresenter.getAdvance(),
      price: servicePresenter.getNightlyPrice(),
      stars: servicePresenter.getStars(),
      totalGuests: servicePresenter.getTotalGuests(),
    };

    const policy: React.ComponentProps<
      typeof HotelOutOfPolicyDialog
    >["policy"] = {
      ...travelPolicyForHotelOffer,
      price: travelPolicyForHotelOffer?.maxValue,
    };

    return (
      <HotelOutOfPolicyDialog
        offer={offer}
        onClose={closeServiceOutOfPolicyDialog}
        open={isServiceOutOfPolicyDialogOpen}
        policy={policy}
      />
    );
  }

  return null;
};
