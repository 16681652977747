import { RecalculateCarOfferRequestAdditional } from "~/apps/corporate/dtos/car.dto";
import {
  AdditionalPriceInfo,
  CarOffer,
} from "~/apps/corporate/models/car.model";

import {
  CarResultHelper,
  CarResultAdditionalsSorter,
} from "../car-result.helper";
import { CarAdditionalPresentation } from "../car-result.types";
import {
  localizaAdditionalsCodesMap,
  localizaAdditionalsPresentationMap,
} from "./localiza-additionals.constants";
import {
  accessoryAdditionalsKeys,
  AccessoryAdditionalsKeys,
  protectionAdditionalsKeys,
  ProtectionAdditionalsKeys,
  serviceAdditionalsKeys,
  ServiceAdditionalsKeys,
} from "./localiza-additionals.type";

export class LocalizaAdditionalsHelper extends CarResultHelper<
  typeof localizaAdditionalsCodesMap
> {
  constructor({
    car,
  }: {
    car: {
      contractDetails: {
        accessoryAdditionals: AdditionalPriceInfo[] | undefined;
        protectionAdditionals: AdditionalPriceInfo[] | undefined;
        serviceAdditionals: AdditionalPriceInfo[] | undefined;
      };
    };
  }) {
    super({
      car,
      codesMap: localizaAdditionalsCodesMap,
      presentationMap: localizaAdditionalsPresentationMap,
    });
  }

  private fallbackToSimplifiedAdditionalPresentation(
    additional: AdditionalPriceInfo,
  ): CarAdditionalPresentation {
    return {
      category: additional.supplierCategory,
      checked: additional.selected,
      code: additional.code,
      dayCount: additional.dayCount,
      disabled: !additional.allowChange,
      name: additional.name,
      price: additional.netPrice,
      quantity: additional.selected ? additional.quantity : 0,
      rate: "daily",
      selected: additional.selected,
    };
  }

  public formatAccessoryAdditionals() {
    const accessoryAdditionalsRaw = this.car.contractDetails
      .accessoryAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      accessoryAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionals?: CarAdditionalPresentation[];
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: CarResultAdditionalsSorter.sortCarAdditionalsByDisabledAndName(
          groupedAdditionalsForPresentation.additionals,
        ),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  public formatProtectionAdditionals() {
    const protectionAdditionalsRaw = this.car.contractDetails
      .protectionAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      protectionAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionals?: CarAdditionalPresentation[];
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: groupedAdditionalsForPresentation.additionals.sort((a) => {
          if (
            a.code ===
            localizaAdditionalsCodesMap.PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS
          ) {
            return 1;
          }

          if (a.code === localizaAdditionalsCodesMap.PROTECAO_TOTAL_AVARIAS) {
            return 1;
          }

          return -1;
        }),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  public formatServiceAdditionals() {
    const serviceAdditionalsRaw = this.car.contractDetails.serviceAdditionals;

    const formattedAdditionalsForPresentation = this.formatAdditionalsForPresentation(
      serviceAdditionalsRaw,
    );

    const groupedAdditionalsForPresentation = formattedAdditionalsForPresentation.reduce(
      (prev, curr) => {
        if (
          this.isUnknownCodeWithinPossibleCodesByKeys(
            curr.code,
            "CONDUTOR_ADICIONAL_ILIMITADO",
          )
        ) {
          return {
            ...prev,
            additionalDrivers: curr,
          };
        }

        return {
          ...prev,
          additionals: prev.additionals ? [...prev.additionals, curr] : [curr],
        };
      },
      {} as {
        additionals?: CarAdditionalPresentation[];
        additionalDrivers?: CarAdditionalPresentation;
      },
    );

    const sortedAdditionalsForPresentation: typeof groupedAdditionalsForPresentation = {
      ...groupedAdditionalsForPresentation,
      ...(groupedAdditionalsForPresentation.additionals && {
        additionals: CarResultAdditionalsSorter.sortCarAdditionalsByDisabledAndName(
          groupedAdditionalsForPresentation.additionals,
        ),
      }),
    };

    return sortedAdditionalsForPresentation;
  }

  private formatAdditionalsForPresentation(
    additionals?: AdditionalPriceInfo[],
  ): CarAdditionalPresentation[] {
    return !additionals
      ? []
      : additionals.map((additional) => {
          const additionalPresentation = this.findPresentationByUnknownCode(
            additional.code,
          );

          if (!additionalPresentation) {
            return this.fallbackToSimplifiedAdditionalPresentation(additional);
          }

          if (
            this.isUnknownCodeWithinPossibleCodesByKeys(
              additional.code,
              "PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS",
            )
          ) {
            const protecaoTotalAvarias = additionals.find(
              (a) =>
                a.code === localizaAdditionalsCodesMap.PROTECAO_TOTAL_AVARIAS,
            );

            if (protecaoTotalAvarias && protecaoTotalAvarias.selected) {
              return {
                category: additional.supplierCategory,
                checked: true,
                code: additional.code,
                dayCount: additional.dayCount,
                details: additionalPresentation.details,
                disabled: true,
                icon: additionalPresentation.icon,
                name: additional.name,
                price: 0,
                quantity: additional.selected ? additional.quantity : 0,
                rate: additionalPresentation.rate || "daily",
                selected: false,
                shortDetails: additionalPresentation.shortDetails,
                tooltip: "Já incluso na Proteção Total de Avarias",
              };
            }
          }

          return {
            category: additional.supplierCategory,
            checked: additional.selected,
            code: additional.code,
            dayCount: additional.dayCount,
            details: additionalPresentation.details,
            disabled: !additional.allowChange,
            icon: additionalPresentation.icon,
            name: additional.name,
            price: additional.netPrice,
            quantity: additional.selected ? additional.quantity : 0,
            rate: additionalPresentation.rate || "daily",
            selected: additional.selected,
            shortDetails: additionalPresentation.shortDetails,
          };
        });
  }

  public groupPresentationByCategory() {
    return {
      accessory: this.groupPresentationByKeys<AccessoryAdditionalsKeys>(
        accessoryAdditionalsKeys,
      ),
      protection: this.groupPresentationByKeys<ProtectionAdditionalsKeys>(
        protectionAdditionalsKeys,
      ),
      service: this.groupPresentationByKeys<ServiceAdditionalsKeys>(
        serviceAdditionalsKeys,
      ),
    };
  }

  public patchAdditionalsToRecalculate(
    additional: CarAdditionalPresentation,
    selectedAdditionals: {
      category: string;
      code: string;
      quantity?: string | undefined;
    }[],
    additionalsToRecalculate: RecalculateCarOfferRequestAdditional[],
  ): RecalculateCarOfferRequestAdditional[] {
    if (
      this.isUnknownCodeWithinPossibleCodesByKeys(
        additional.code,
        "PROTECAO_TOTAL_AVARIAS",
      )
    ) {
      return additionalsToRecalculate.filter(
        (a) =>
          a.code !==
          localizaAdditionalsCodesMap.PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS,
      );
    }
    if (
      this.isUnknownCodeWithinPossibleCodesByKeys(
        additional.code,
        "PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS",
      ) &&
      selectedAdditionals.some(
        (a) => a.code === localizaAdditionalsCodesMap.PROTECAO_TOTAL_AVARIAS,
      )
    ) {
      return additionalsToRecalculate.filter(
        (a) =>
          a.code !==
          localizaAdditionalsCodesMap.PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS,
      );
    }

    return additionalsToRecalculate;
  }
}
