import React, { createRef, useCallback, useEffect, useState } from "react";

import RcSlider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const Slider = createSliderWithTooltip(RcSlider);

import { useWindowSize } from "../../hooks/use-window-size";
import { styles } from "./styles";

type SlideFilterProps = React.ComponentProps<typeof Slider> & {
  defaultValue?: number;
  disabled?: boolean;
  formatFunc?: (value: number) => string;
  max: number;
  min: number;
  onRangeDragEnd?: (range: number) => void;
  step?: number;
  variant?: "blue" | "default" | "green" | "pink";
};

export const SlideFilter: React.FC<SlideFilterProps> = ({
  defaultValue = 0,
  disabled = false,
  formatFunc = (value: number) => value.toString(),
  max,
  min,
  onRangeDragEnd,
  variant = "default",
  ...props
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 991;

  const [value, setValue] = useState(defaultValue);

  const handleDragEnd = useCallback(
    (value: number) => {
      onRangeDragEnd?.(value);
      setValue(value);
    },
    [onRangeDragEnd],
  );

  const ref = createRef<HTMLDivElement>();

  const [leftLabel, rightLabel] = [min, value];

  const minLabel = formatFunc(min);
  const maxLabel = formatFunc(max);

  useEffect(() => {
    const oneHandle = ref.current?.querySelector(".rc-slider-handle");

    if (oneHandle) {
      oneHandle.setAttribute("aria-label", "Máximo");
    }

    const handleOne = ref.current?.querySelector(".rc-slider-handle-1");

    if (handleOne) {
      handleOne.setAttribute("aria-label", "Mínimo");
    }
  }, []);

  return (
    <div css={styles.root}>
      <span css={styles.label}>
        {leftLabel ? formatFunc(leftLabel) : minLabel} /{" "}
        {rightLabel ? formatFunc(rightLabel) : maxLabel}
      </span>
      <div css={styles.range} ref={ref}>
        <Slider
          defaultValue={defaultValue ? defaultValue : max}
          disabled={disabled}
          min={min}
          max={max}
          onAfterChange={handleDragEnd}
          tipFormatter={(value: number) => `${formatFunc(value)}`}
          {...styles.slider({ disabled, isMobile, variant })}
          {...props}
        />
      </div>
      <div css={styles.values}>
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
    </div>
  );
};
