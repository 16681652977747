import React from "react";

import { FormControlLabel as MuiFormControlLabel } from "@material-ui/core";

import { Checkbox } from "../checkbox/checkbox";
import { styles } from "./styles";

export const CheckboxGroupItem: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof MuiFormControlLabel>,
    "control" | "label"
  > & {
    label?: React.ReactNode;
    variant?: "blue" | "default" | "green" | "pink";
  }
> = ({
  children,
  disabled,
  id,
  label,
  value,
  variant = "default",
  ...props
}) => {
  return (
    <MuiFormControlLabel
      classes={{ ...styles.classes }}
      control={<Checkbox id={id} variant={variant} />}
      disabled={disabled}
      label={label ? label : <div css={styles.label}>{children}</div>}
      style={{
        height: "auto",
        margin: 0,
      }}
      value={value}
      {...props}
    />
  );
};
