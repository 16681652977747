import React from "react";
import Autosuggest from "react-autosuggest";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { css } from "emotion";
import debounce from "lodash/debounce";
import { PropTypes } from "prop-types";

import { Input } from "../Input";
import { Spinner } from "./Spinner";

const styles = {
  root: css({
    width: "100%",
    height: "100%"
  }),
  container: css({
    position: "relative",
    flexGrow: 1,
    height: "100%"
  }),
  suggestionsContainerOpen: css({
    position: "absolute",
    zIndex: 1,
    marginTop: 2,
    left: 0,
    right: 0
  }),
  suggestion: css({
    display: "block"
  }),
  suggestionsList: css({
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }),
  rootItem: css({
    padding: "1rem .5rem 1rem 1rem"
  }),
  dropdown: css({
    width: "max-content",
    minWidth: "100%",
    maxHeight: 240,
    overflowY: "auto"
  })
};

class Autocomplete extends React.Component {
  state = {
    value: "",
    settedData: "",
    data: [],
    isLoading: false,
    lastFetchedSuggestion: "",
    lastSuggestionsArray: [],
    suggestions: []
  };

  renderInputComponent = inputProps => {
    const { ref, inputRef, ...rest } = inputProps;
    const { icon } = this.props;

    return (
      <Input
        {...rest}
        ref={node => {
          ref(node);

          if (inputRef && typeof inputRef === "object") {
            inputRef.current = node;
          } else if (inputRef && typeof inputRef === "function") {
            inputRef(node);
          }
        }}
        startIcon={
          !icon ? undefined : !this.state.isLoading ? icon && icon : Spinner
        }
      />
    );
  };

  debouncedLoadSuggestions = debounce(this.loadSuggestions, 500);

  loadSuggestions(value) {
    if (value === this.state.lastFetchedSuggestion) {
      const { lastSuggestionsArray } = this.state;

      this.setState({
        suggestions: lastSuggestionsArray
      });
    } else {
      this.setState({ isLoading: true });

      this.props
        .search(value)
        .then(data => {
          this.setState({ data, lastFetchedSuggestion: value });
        })
        .then(() => {
          const suggestions = this.getSuggestions(value);

          this.setState({
            isLoading: false,
            suggestions,
            lastSuggestionsArray: suggestions
          });
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            suggestions: [{ label: error.description, error: true }]
          });
        });
    }
  }

  clearSuggestions = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions = value => {
    const { data } = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (data.length === 0) {
      return [{ label: `Nenhum resultado para ${value}.`, error: true }];
    }

    return inputLength === 0 ? [] : data;
  };

  getSuggestionValue = suggestion => {
    if (!suggestion.error && !suggestion.disabled) {
      return suggestion.label;
    } else {
      this.clearSuggestions();
      return null;
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (!suggestion.error && !suggestion.disabled) {
      this.setState({ settedData: suggestion.label });
      this.props.updateParent(suggestion);
    }
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { noResultsComponent, ResultItemComponent } = this.props;
    const { isLoading } = this.state;
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    if (isLoading) {
      return null;
    }

    if (suggestion.error && noResultsComponent) {
      return noResultsComponent;
    }

    if (!suggestion.error && ResultItemComponent) {
      return (
        <ResultItemComponent
          isHighlighted={isHighlighted}
          suggestion={suggestion}
        />
      );
    }

    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        classes={{
          root: styles.rootItem
        }}
        disabled={suggestion.error}
      >
        <Typography variant="inherit" noWrap>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          })}
        </Typography>
      </MenuItem>
    );
  };

  handleInputChange = (_, { newValue, method }) => {
    if (method === "click" && newValue === null) {
      this.props.updateParent(null);
      this.setState({ value: "" });
      return;
    }

    if (newValue.length === 0) {
      this.props.updateParent(null);
    }

    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === "input-focused") {
      return null;
    }

    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  componentDidUpdate(prevProps) {
    const { value: inputValue } = this.props;

    if (inputValue !== prevProps.value) {
      this.setState({ value: inputValue });
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const {
      inputProps,
      shouldRenderSuggestions,
      defaultSuggestions = [],
      focusInputOnSuggestionClick
    } = this.props;

    const inputValue = value ? value : this.props.value;

    return (
      <Autosuggest
        id={inputProps?.id}
        suggestions={suggestions.length ? suggestions : defaultSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        inputProps={{
          ...inputProps,
          value: inputValue,
          onChange: this.handleInputChange
        }}
        theme={{
          container: styles.container,
          suggestionsContainerOpen: styles.suggestionsContainerOpen,
          suggestionsList: styles.suggestionsList,
          suggestion: styles.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper
            {...options.containerProps}
            classes={{
              root: styles.dropdown
            }}
          >
            {options.children ? <MenuList>{options.children}</MenuList> : null}
          </Paper>
        )}
        shouldRenderSuggestions={shouldRenderSuggestions}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
      />
    );
  }
}

Autocomplete.propTypes = {
  inputProps: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
  updateParent: PropTypes.func.isRequired,
  icon: PropTypes.node,
  value: PropTypes.string.isRequired,
  noResultsComponent: PropTypes.node,
  ResultItemComponent: PropTypes.elementType,
  focusInputOnSuggestionClick: PropTypes.bool
};

Autocomplete.defaultProps = {
  focusInputOnSuggestionClick: true
};

export default Autocomplete;
