import React from "react";

import { ServiceType } from "~/apps/shared/constants/enums";

import { Dialog } from "../../../shared/components/dialog/dialog";
import { Icon } from "../../../shared/components/icon/icon";
import { OfferSearcherProvider } from "../offer-searcher/offer-searcher.context";
import {
  BusesSearch,
  CarsSearch,
  FlightsSearch,
  HotelsSearch,
} from "../offer-searcher/offer-searcher.types";
import { OfferSearcherDialogBusForm } from "./bus-form/bus-form";
import { OfferSearcherDialogCarForm } from "./car-form/car-form";
import { OfferSearcherDialogFlightForm } from "./flight-form/flight-form";
import { OfferSearcherDialogHotelForm } from "./hotel-form/hotel-form";
import { styles } from "./styles";

type Props = {
  onClose: () => void;
  open: boolean;
  title: string;
  travelToken: string;
} & (
  | {
      data?: Partial<BusesSearch>;
      type: ServiceType.BUS;
    }
  | {
      data?: Partial<CarsSearch>;
      type: ServiceType.CAR;
    }
  | {
      data?: Partial<FlightsSearch>;
      type: ServiceType.FLIGHT;
    }
  | {
      data?: Partial<HotelsSearch>;
      type: ServiceType.HOTEL;
    }
);

const Component: React.FC<Props> = ({
  onClose,
  open,
  title,
  travelToken,
  ...props
}) => {
  return (
    <Dialog
      css={styles.dialog}
      disableEnforceFocus
      onClose={onClose}
      open={open}
    >
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>{title}</span>
          <button css={styles.header.close} onClick={onClose}>
            <Icon size={16} use="x" />
          </button>
        </div>
        <>
          {props.type === ServiceType.BUS && (
            <OfferSearcherDialogBusForm
              data={props.data ? props.data : null}
              onClose={onClose}
              travelToken={travelToken}
            />
          )}
          {props.type === ServiceType.CAR && (
            <OfferSearcherDialogCarForm
              data={props.data ? props.data : null}
              onClose={onClose}
              travelToken={travelToken}
            />
          )}
          {props.type === ServiceType.FLIGHT && (
            <OfferSearcherDialogFlightForm
              data={props.data ? props.data : null}
              onClose={onClose}
              travelToken={travelToken}
            />
          )}
          {props.type === ServiceType.HOTEL && (
            <OfferSearcherDialogHotelForm
              data={props.data ? props.data : null}
              onClose={onClose}
              travelToken={travelToken}
            />
          )}
        </>
      </div>
    </Dialog>
  );
};

export const OfferSearcherDialog: React.FC<Props> = ({ ...props }) => {
  return (
    <OfferSearcherProvider>
      <Component {...props} />
    </OfferSearcherProvider>
  );
};
