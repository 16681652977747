import React from "react";

import noResultsError from "~/apps/shared/assets/images/errors/no-results.png";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  message?: React.ReactNode;
  onClick?: () => void;
  onClickMessage?: string;
};

export const NoResultsWarning: React.FC<Props> = ({
  message,
  onClick,
  onClickMessage,
}) => {
  return (
    <div css={styles.root}>
      <img alt="" height={256} src={noResultsError} width={256} />
      {message !== null ? (
        <div css={styles.message.root}>
          {message ? message : "Nenhum resultado encontrado."}
        </div>
      ) : null}
      {onClickMessage && onClick ? (
        <div css={styles.navigate.root}>
          <Button onClick={onClick} variant="pink">
            {onClickMessage}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
