import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bulleted: ({ align }: { align: any }) =>
    css({
      listStyle: "inside",
      listStyleType: "disc",
      textAlign: align,
    }),
  button: ({ isActive }: { isActive: boolean }) =>
    css({
      backgroundColor: "transparent",
      border: "none",
      color: isActive ? theme.colors.gray[700] : theme.colors.gray[100],
      height: "24px",
      transition: "all 0.15s ease-in-out",
      width: "24px",
      ":hover": {
        color: isActive ? theme.colors.gray[700] : theme.colors.gray[300],
      },
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
  element: ({ align }: { align: any }) =>
    css({
      textAlign: align,
    }),
  numbered: ({ align }: { align: any }) =>
    css({ listStyle: "inside", listStyleType: "decimal", textAlign: align }),
  toolbar: css({
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    display: "flex",
    gap: "8px",
    padding: "8px",
    "& > *": {
      display: "inline-block",
    },
  }),
};
