import { StylesConfig, GroupBase } from "react-select";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  additional: {
    bottom: {
      details: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        lineHeight: "1.25",
        textAlign: "left",
      }),
      root: css({
        backgroundColor: theme.colors.background.gray,
        borderRadius: "0 0 8px 8px",
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        gap: "16px",
        padding: "12px 16px",
        width: "100%",
      }),
    },
    root: css({
      backgroundColor: theme.colors.background.white,
      border: "1px solid",
      borderColor: theme.colors.gray[100],
      borderRadius: "8px",
      display: "flex",
      flex: "0 1",
      flexDirection: "column",
      fontFamily: theme.fonts.inter,
      transition: "all 0.15s ease-in-out",
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
    skeleton: {
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.white,
        border: "1px solid",
        borderColor: theme.colors.gray[100],
        borderRadius: "8px",
        display: "flex",
        flex: "0 1",
        flexDirection: "column",
        fontFamily: theme.fonts.inter,
        justifyContent: "center",
        minHeight: "58px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        ":not(:disabled)": {
          cursor: "pointer",
        },
      }),
    },
    top: {
      left: {
        name: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          fontWeight: 600,
          display: "flex",
          height: "42px",
          textAlign: "left",
          whiteSpace: "nowrap",
        }),
        root: ({ disabled }: { disabled?: boolean }) =>
          css({
            alignItems: "center",
            color: theme.colors.pink[500],
            display: "flex",
            gap: "12px",
            ...(disabled && {
              opacity: 0.5,
            }),
          }),
        tooltip: css({
          height: "16px",
          width: "16px",
        }),
      },
      right: {
        checkbox: ({ disabled }: { disabled: boolean }) =>
          css({
            ...(disabled && {
              opacity: 0.5,
            }),
          }),
        price: css({
          color: theme.colors.pink[500],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          gap: "6px",
          whiteSpace: "nowrap",
          ".MuiSvgIcon-root": {
            "& > path": {
              fill: theme.colors.pink[500],
            },
          },
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        flexWrap: "wrap",
        gap: "0 24px",
        justifyContent: "space-between",
        padding: "8px 16px",
        width: "100%",
      }),
    },
  },
  conductors: {
    bottom: {
      avatars: {
        add: css({
          backgroundColor: "white",
          border: `1px dashed ${theme.colors.gray[100]}`,
          borderRadius: "50%",
          color: theme.colors.gray[300],
          display: "grid",
          height: "36px",
          placeItems: "center",
          width: "36px",
          ":disabled": {
            opacity: 0.5,
          },
          ":not(:disabled)": {
            cursor: "pointer",
          },
        }),
        avatar: {
          avatar: css({
            height: "36px",
            width: "36px",
          }),
          remove: css({
            backgroundColor: "white",
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: "50%",
            display: "grid",
            fontSize: "0.75rem",
            height: "16px",
            opacity: 0,
            placeItems: "center",
            position: "absolute",
            right: 0,
            top: 0,
            transition: "opacity 0.15s ease-in-out",
            width: "16px",
            zIndex: 1,
            ":disabled": {
              opacity: 0.5,
            },
            ":not(:disabled)": {
              cursor: "pointer",
            },
          }),
          root: css({
            border: "none",
            position: "relative",
            zIndex: 0,
            "&:hover > :first-of-type": {
              opacity: 1,
            },
          }),
        },
        popover: {
          autocomplete: {
            container: (baseStyles) => ({
              ...baseStyles,
              padding: "0.5rem 0.5rem 0 0.5rem",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: `1px solid ${
                state.selectProps.menuIsOpen
                  ? theme.colors.gray[300]
                  : `#c9d3df`
              }`,
              borderRadius: "0.25rem",
              boxShadow: "none",
              padding: "0 0.5rem",
              width: "18rem",
              "&:hover": {
                borderColor: theme.colors.gray[300],
                cursor: "pointer",
              },
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "none",
              height: "14rem",
              marginTop: "0",
              width: "18rem",
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              height: "13rem",
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: "#989ba1",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: "0",
            }),
          } as StylesConfig<any, boolean, GroupBase<any>>,
          root: css({
            "& > .MuiPopover-paper": {
              height: "256px",
              overflow: "hidden",
            },
          }),
        },
        root: css({}),
        span: css({
          display: "flex",
        }),
      },
      error: css({
        color: theme.colors.pink[500],
        fontSize: "0.875rem",
      }),
      label: {
        root: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
        strong: css({
          fontWeight: 600,
        }),
        weak: css({
          color: theme.colors.gray[300],
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.gray,
        borderRadius: "0 0 8px 8px",
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        gap: "8px",
        padding: "12px 16px",
        width: "100%",
      }),
    },
    root: css({
      backgroundColor: theme.colors.background.white,
      border: "1px solid",
      borderColor: theme.colors.gray[100],
      borderRadius: "8px",
      display: "flex",
      flex: "0 1",
      flexDirection: "column",
      transition: "all 0.15s ease-in-out",
    }),
    top: {
      root: css({
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        fontFamily: theme.fonts.inter,
        gap: "24px",
        height: "58px",
        justifyContent: "space-between",
        padding: "8px 32px 8px 16px",
        width: "100%",
        ":not(:disabled)": {
          cursor: "pointer",
        },
      }),
    },
  },
  dialog: {
    dialog: {
      button: css({}),
      footer: css({
        paddingTop: "24px",
      }),
      details: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.5",
        textAlign: "left",
      }),
      root: css({
        ".MuiDialog-paper": {
          overflowY: "auto",
        },
      }),
    },
    left: {
      bottom: {
        button: css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
          textDecoration: "underline",
          ":not(:disabled)": {
            cursor: "pointer",
          },
        }),
        root: css({}),
      },
      root: ({ disabled }: { disabled?: boolean }) =>
        css({
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          flexShrink: 0,
          fontFamily: theme.fonts.inter,
          gap: "8px",
          justifyContent: "space-between",
          padding: "20px 16px",
          ...(disabled && {
            opacity: 0.5,
          }),
        }),
      top: {
        name: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
          textAlign: "left",
          whiteSpace: "nowrap",
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.pink[500],
          display: "flex",
          gap: "8px",
        }),
      },
    },
    right: {
      checkbox: ({ disabled }: { disabled: boolean }) =>
        css({
          cursor: "pointer",
          display: "grid",
          height: "fit-content",
          placeItems: "center",
          ...(disabled && {
            cursor: "default",
            opacity: 0.5,
          }),
        }),
      price: css({
        color: theme.colors.pink[500],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
      radio: ({ disabled }: { disabled: boolean }) =>
        css({
          ...(disabled && {
            opacity: 0.5,
          }),
        }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexShrink: 0,
        gap: "6px",
        padding: "0 16px 0 12px",
        whiteSpace: "nowrap",
      }),
    },
    root: css({
      backgroundColor: theme.colors.background.white,
      border: "1px solid",
      borderColor: theme.colors.gray[100],
      borderRadius: "8px",
      display: "flex",
      flex: 1,
      minWidth: "unset",
      transition: "all 0.15s ease-in-out",
      width: "fit-content",
    }),
  },
  quantity: {
    left: {
      bottom: {
        price: css({
          color: theme.colors.pink[500],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          gap: "6px",
          whiteSpace: "nowrap",
        }),
      },
      root: css({
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        flexDirection: "column",
        fontFamily: theme.fonts.inter,
        gap: "8px",
        justifyContent: "space-between",
        padding: "20px 16px",
        width: "100%",
        ":not(:disabled)": {
          cursor: "pointer",
        },
      }),
      top: {
        name: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
          textAlign: "left",
          whiteSpace: "nowrap",
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.pink[500],
          display: "flex",
          gap: "8px",
        }),
      },
    },
    right: {
      button: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[300],
        fontSize: "1.25rem",
        height: "24px",
        width: "24px",
        ":disabled": {
          opacity: 0.5,
        },
        ":not(:disabled)": {
          cursor: "pointer",
        },
      }),
      quantity: css({
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "8px",
        padding: "0px 12px",
      }),
    },
    root: ({ checked }: { checked: boolean }) =>
      css({
        backgroundColor: theme.colors.background.white,
        border: "1px solid",
        borderColor: theme.colors.gray[100],
        borderRadius: "8px",
        display: "flex",
        flex: "1",
        minWidth: "unset",
        transition: "all 0.15s ease-in-out",
      }),
  },
};
