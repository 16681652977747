import React from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import {
  ExpensePolicyListItem,
  ExpensePolicy,
} from "@models/expense-policy.model";

import { orderPoliciesList } from "../../../../helpers/expense-policy.helper";
import { useSafeState } from "../../../../hooks";
import * as expensePolicyService from "./ExpensePolicy.service";
import { ExpensePolicyForm } from "./ExpensePolicy.types";

interface State {
  expensePolicies: ExpensePolicyListItem[];
  fetchingExpensePolicy: boolean;
  isDeleting: boolean;
  isFormVisible: boolean;
  isSaving: boolean;
  loading: boolean;
  selectedPolicyToDelete: ExpensePolicyListItem | null;
  selectedPolicyToEdit: ExpensePolicy | null;
}

interface Actions {
  fetchPolicies: () => void;
  handleCloseDeletionDialog: () => void;
  handleCloseForm: () => void;
  handleEditPolicy: (policy: ExpensePolicyListItem) => void;
  handleOpenForm: () => void;
  handleSelectPolicyToDelete: (policy: ExpensePolicyListItem) => void;
  handleSubmitPolicy: (formData: ExpensePolicyForm) => void;
  processPolicyDeletion: () => void;
}

type ContextProps = State & Actions;

const initialState: State = {
  expensePolicies: [],
  fetchingExpensePolicy: false,
  isDeleting: false,
  isFormVisible: false,
  isSaving: false,
  loading: false,
  selectedPolicyToDelete: null,
  selectedPolicyToEdit: null,
};

export const ExpensePolicyContext = React.createContext<ContextProps>({
  ...initialState,
  fetchPolicies: () => null,
  handleCloseDeletionDialog: () => null,
  handleCloseForm: () => null,
  handleEditPolicy: () => null,
  handleOpenForm: () => null,
  handleSelectPolicyToDelete: () => null,
  handleSubmitPolicy: () => null,
  processPolicyDeletion: () => null,
});

export const ExpensePolicyProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const fetchPolicies = async () => {
    setState({ loading: true });

    const { data, error } = await expensePolicyService.getExpensePoliciesList();

    if (error) {
      setState({ loading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      expensePolicies: orderPoliciesList(data!),
      loading: false,
    });
  };

  const handleSelectPolicyToDelete = (policy: ExpensePolicyListItem) => {
    setState({ selectedPolicyToDelete: policy });
  };

  const handleCloseDeletionDialog = () => {
    setState({ selectedPolicyToDelete: null });
  };

  const processPolicyDeletion = async () => {
    setState({ isDeleting: true });

    const { selectedPolicyToDelete, expensePolicies } = state;

    const { error } = await expensePolicyService.deleteExpensePolicy(
      selectedPolicyToDelete!.policyToken,
    );

    if (error) {
      setState({ isDeleting: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const updatedPolicies = expensePolicies.filter(
      (policy) => policy.policyToken !== selectedPolicyToDelete!.policyToken,
    );

    setState({
      expensePolicies: updatedPolicies,
      isDeleting: false,
      selectedPolicyToDelete: null,
    });

    showSnackMessage("Política excluída com sucesso", ALERT_TYPES.SUCCESS);
  };

  const handleOpenForm = () => {
    setState({
      isFormVisible: true,
      selectedPolicyToEdit: null,
    });
  };

  const handleCloseForm = () => {
    setState({
      isFormVisible: false,
      selectedPolicyToEdit: null,
    });
  };

  const handleEditPolicy = async (policy: ExpensePolicyListItem) => {
    setState({ fetchingExpensePolicy: true, isFormVisible: true });

    const { data, error } = await expensePolicyService.getExpensePolicyInfo(
      policy.policyToken,
    );

    if (error) {
      setState({ fetchingExpensePolicy: false, isFormVisible: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      fetchingExpensePolicy: false,
      selectedPolicyToEdit: {
        ...data!,
        policyToken: policy.policyToken,
        targetType: policy.targetType,
      },
    });
  };

  const handleSubmitPolicy = (formData: ExpensePolicyForm) => {
    if (formData.policyToken) {
      void editExpensePolicy(formData);
    } else {
      void createExpensePolicy(formData);
    }
  };

  const createExpensePolicy = async (formData: ExpensePolicyForm) => {
    setState({ isSaving: true });

    const { expensePolicies } = state;

    const { data, error } = await expensePolicyService.createExpensePolicy(
      formData,
    );

    if (error) {
      setState({ isSaving: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const policies = expensePolicies.concat({
      description: data!.description,
      policyToken: data!.policyToken,
      targetCount: data!.targetCount,
      targetType: data!.targetType,
    });

    setState({
      expensePolicies: orderPoliciesList(policies),
      isFormVisible: false,
      isSaving: false,
      selectedPolicyToEdit: null,
    });

    showSnackMessage("Política criada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const editExpensePolicy = async (formData: ExpensePolicyForm) => {
    setState({ isSaving: true });

    const { expensePolicies } = state;

    const { data, error } = await expensePolicyService.editExpensePolicy(
      formData,
    );

    if (error) {
      setState({ isSaving: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const updatedExpensePolicies = expensePolicies.map((policy) => {
      if (policy.policyToken === formData.policyToken) {
        return {
          ...policy,
          description: data!.description,
          targetCount: formData.targets.length,
        };
      }
      return policy;
    });

    setState({
      expensePolicies: updatedExpensePolicies,
      isFormVisible: false,
      isSaving: false,
      selectedPolicyToEdit: null,
    });

    showSnackMessage("Política atualizada com sucesso", ALERT_TYPES.SUCCESS);
  };

  //

  return (
    <ExpensePolicyContext.Provider
      value={{
        ...state,
        fetchPolicies,
        handleCloseDeletionDialog,
        handleCloseForm,
        handleEditPolicy,
        handleOpenForm,
        handleSelectPolicyToDelete,
        handleSubmitPolicy,
        processPolicyDeletion,
      }}
    >
      {children}
    </ExpensePolicyContext.Provider>
  );
};
