import React from "react";
import { Box } from "@toolkit";
import { GradeTopTag } from "./GradeTag";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const GradeRange = ({ value, ...other }: Props) => {
  return (
    <Box>
      <GradeTopTag value={value} />
      <input
        type="range"
        min={0}
        max={10}
        step={0.5}
        style={{ width: 328 }}
        value={value}
        {...other}
      />
    </Box>
  );
};

export { GradeRange };
