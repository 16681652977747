import React from "react";

import { css } from "@styled-system/css";

import { Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import styled from "@shared/styled";

import { Paper } from "./Paper";

const StyledText = styled(Text)(
  css({
    color: defaultTheme.subTextColor,
    display: "block",
    textTransform: "uppercase"
  })
);

interface IProps {
  title: string;
  value: string | number;
  [key: string]: any;
}

const SmallInfoCard: React.FC<IProps> = ({ title, value, ...other }) => {
  return (
    <Paper {...other}>
      <StyledText lineHeight="14px" fontSize={0} fontWeight="bold" mb={16}>
        {title}
      </StyledText>
      <StyledText fontSize={4} fontWeight="bold">
        {value}
      </StyledText>
    </Paper>
  );
};

export { SmallInfoCard };
