import React from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { CustomFieldOption } from "~/apps/corporate/dtos/custom-field-options.dto";
import { Icon } from "~/apps/shared/components/icon/icon";
import { theme } from "smartrips-skin/lib/v2";
import { Box, Flex, Text } from "smartrips-toolkit";

import { Button } from "@toolkit/v2";

import { useCustomFieldOptions } from "../custom-field-options.context";

const styles = {
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
  container: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "3.5rem",
      width: "100%",
      height: "3rem",
      padding: "1.75rem 1rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
      backgroundColor: theme.colors.background.gray,
      cursor: "pointer",
    }),
    textPrincipal: css({
      fontWeight: 600,
    }),
    textSecondery: css({
      fontSize: "0.75rem",
    }),
    activeIcon: css({
      fontSize: "1rem",
      color: theme.colors.green[500],
    }),
    inactiveIcon: css({
      fontSize: "1rem",
      color: theme.colors.pink[500],
    }),
    content: css({
      display: "flex",
      alignItems: "center",
      width: "70%",
      gap: "3.5rem",
    }),
    inner: css({
      gap: "0.5rem",
      display: "flex",
      flexDirection: "column",
    }),
    innerSecondary: css({
      gap: "0.5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }),
    innerOptions: css({
      width: "30%",
      display: "flex",
      justifyContent: "flex-end",
      gap: "0.5rem",
      alignItems: "center",
    }),
  },
};

type CustomFieldOptionContainerProps = {
  customFieldOption: CustomFieldOption;
};

const CustomFieldOptionContainer = (props: CustomFieldOptionContainerProps) => {
  const {
    handleActivate,
    handleInactivate,
    handleOpenUpdateBox,
  } = useCustomFieldOptions();

  const handleActivateCustomFieldOption = async () => {
    if (props.customFieldOption.active) {
      await handleInactivate(props.customFieldOption.custom_field_option_token);
    } else {
      await handleActivate(props.customFieldOption.custom_field_option_token);
    }
  };

  return (
    <Box
      css={styles.container.root}
      onClick={() => handleOpenUpdateBox(props.customFieldOption)}
    >
      <Flex css={styles.container.content}>
        <Flex css={styles.container.inner}>
          <Text css={styles.container.textPrincipal}>
            Nome da personalização
          </Text>
          <Text css={styles.container.textSecondery}>
            {props.customFieldOption.name}
          </Text>
        </Flex>
      </Flex>
      <Flex css={styles.container.innerOptions}>
        <Button
          onClick={(e) => {
            e.stopPropagation();

            handleOpenUpdateBox(props.customFieldOption);
          }}
          shape="icon"
        >
          <Icon size={20} use="pencil" />
        </Button>

        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleActivateCustomFieldOption();
          }}
          shape="icon"
          variant="pink"
        >
          <Icon
            size={props.customFieldOption.active ? 20 : 18}
            use={
              props.customFieldOption.active ? "trash" : "archive-box-arrow-up"
            }
          />
        </Button>
      </Flex>
    </Box>
  );
};

export default CustomFieldOptionContainer;
