export function buildHotelData(hotelSearchData, travelToken = null) {
  const hotel = {};

  if (travelToken) {
    hotel.travel_token = travelToken;
  }

  hotel.hotel_search = hotelSearchData.location.search;
  hotel.hotel_latitude = hotelSearchData.location.latitude;
  hotel.hotel_longitude = hotelSearchData.location.longitude;
  hotel.hotel_initial_date = hotelSearchData.startDate.format("YYYY-MM-DD");
  hotel.hotel_end_date = hotelSearchData.endDate.format("YYYY-MM-DD");
  hotel.total_guests = hotelSearchData.totalGuests;

  return hotel;
}
