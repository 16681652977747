import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  form: {
    body: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "24px",
      padding: "24px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      position: "relative",
      width: "100%",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
    "@media screen and (max-width: 768px)": {
      width: "100dvw",
    },
  }),
};
