import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    button: css({
      display: "flex",
      justifyContent: "center",
      width: "100%",
      ":disabled": {
        opacity: 0.5,
      },
    }),
    root: css({
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    }),
    subs: {
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      sub: {
        bottom: {
          label: css({
            color: theme.colors.gray[300],
            fontSize: "0.875rem",
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }),
          value: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
        },
        label: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }),
      },
    },
    total: {
      label: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "center",
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "24px",
      }),
      value: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
    },
  },
  center: {
    points: {
      point: {
        dates: {
          date: css({
            alignItems: "center",
            display: "flex",
            fontSize: "0.75rem",
            gap: "8px",
          }),
          root: css({
            alignItems: "center",
            color: theme.colors.gray[300],
            display: "flex",
            gap: "8px",
          }),
          time: css({
            alignItems: "center",
            display: "flex",
            fontSize: "0.75rem",
            gap: "8px",
          }),
        },
        divisor: css({
          backgroundColor: theme.colors.gray[100],
          height: "1px",
          width: "100%",
        }),
        location: {
          rental: css({
            fontSize: "0.875rem",
            fontWeight: 600,
            textTransform: "capitalize",
          }),
          root: css({
            color: theme.colors.gray[300],
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            paddingLeft: "24px",
          }),
          search: css({
            fontSize: "0.875rem",
            textTransform: "capitalize",
          }),
        },
        root: css({
          color: theme.colors.gray[700],
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
        title: {
          root: css({
            alignItems: "center",
            color: theme.colors.pink[500],
            display: "flex",
            gap: "8px",
          }),
          text: css({
            fontSize: "0.875rem",
            fontWeight: 600,
          }),
        },
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }),
    },
    root: css({
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    }),
  },
  root: css({
    backgroundColor: theme.colors.background.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
  }),
  top: {
    badge: {
      icon: css({
        "& > svg": {
          strokeWidth: 2,
        },
      }),
      root: css({
        backgroundColor: "#ffe8ab",
        borderColor: "#ffe8ab",
        color: "#806300",
        cursor: "pointer",
        fontWeight: 500,
        gap: "8px",
        padding: "4px 16px",
        ":hover": {
          backgroundColor: "#e5d099",
          borderColor: "#e5d099",
          color: "#735900",
        },
      }),
      text: css({
        fontSize: "0.875rem",
      }),
    },
    model: {
      description: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      title: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    }),
  },
};
