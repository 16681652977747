import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  form: {
    root: css({
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "8px",
      minWidth: "384px",
    }),
    skeleton: {
      heading: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingTop: "6px",
      }),
    },
  },
  icon: ({ color, size }: { color?: string; size?: number }) =>
    css({
      alignItems: "center",
      aspectRatio: "1",
      ...(color && { color: `${color}` }),
      display: "flex",
      height: `${size || 24}px`,
      justifyContent: "center",
      transition: "all 0.15s ease-in-out",
      width: `${size || 24}px`,
    }),
  loading: css({
    opacity: 0.75,
  }),
  root: css({
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    gap: "24px",
    margin: "0 auto",
    maxWidth: "1440px",
    padding: "16px 80px 80px 80px",
    width: "100%",
    "@media (max-width: 1280px)": {
      padding: "24px",
    },
    "@media (max-width: 992px)": {
      padding: "16px",
    },
  }),
  summary: {
    root: css({
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      minWidth: "384px",
      width: "384px",
      "@media (max-width: calc(384px * 2 + 24px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    }),
    title: css({
      fontWeight: 600,
      lineHeight: "28px",
    }),
  },
};
