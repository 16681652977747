import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

export async function getLoggedClientRewardPolicy() {
  return api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/reward-policy`,
    })
    .then((response) => response.data);
}
