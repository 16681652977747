import React, { useContext } from "react";
import { css } from "emotion";
import { FinancialContext } from "../Financial.context";
import { FlightItem } from "./FlightItem";
import InfiniteScroll from "react-infinite-scroller";
import isEmpty from "lodash/isEmpty";
import { OFFER_TRANSACTION_LABELS } from "../../../constants";

const styles = {
  tableRoot: css({
    width: "100%"
  }),
  noInvoices: css({
    paddingLeft: ".75rem"
  })
};

const FlightsTable = () => {
  const {
    flightInvoices,
    selectedInvoices,
    currentPage,
    totalPages,
    loading,
    loadNextInvoices,
    handleSelectInvoice
  } = useContext(FinancialContext);
  const hasNextPage = !loading && currentPage < totalPages;

  return (
    <div>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadNextInvoices}
        hasMore={hasNextPage}
        loader={
          <div key={0} className="loader">
            Loading ...
          </div>
        }
      >
        {!loading && isEmpty(flightInvoices) ? (
          <div className={styles.noInvoices}>
            <span>Nenhuma nota encontrada no período selecionado</span>
          </div>
        ) : null}
        <table className={styles.tableRoot}>
          <tbody>
            {flightInvoices.map(invoice => (
              <FlightItem
                typeLabel={OFFER_TRANSACTION_LABELS[invoice.type]}
                key={invoice.selectedOffer.transactionToken}
                flightInvoice={invoice}
                isSelected={
                  !!selectedInvoices[invoice.selectedOffer.transactionToken]
                }
                handleSelect={handleSelectInvoice(
                  invoice.selectedOffer.transactionToken
                )}
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export { FlightsTable };
