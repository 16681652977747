import React from "react";

import { RouteComponentProps } from "@reach/router";

import { Panel } from "@components/analytics/panel/Panel";

type Props = RouteComponentProps;

export const AnalyticsPanel: React.FC<Props> = () => {
  return <Panel />;
};
