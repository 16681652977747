import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { CANCEL_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { styles } from "./styles";

type Props = {
  cancelFee: number | null;
  cancelType?: string;
  isChanged?: boolean;
};

export const TravelPlanPDFServiceCancelationInfo: React.FC<Props> = ({
  cancelFee,
  cancelType,
  isChanged,
}) => {
  const badgeStyles = styles.badge();

  const translatedCancelType = isChanged
    ? "devido a alteração"
    : cancelType
    ? CANCEL_TYPES_TRANSLATION[
        cancelType as keyof typeof CANCEL_TYPES_TRANSLATION
      ]
    : null;

  return (
    <View style={badgeStyles.root}>
      <PDFIcon size={14} use="x" />
      <View>
        <Text>
          Cancelamento
          {translatedCancelType ? ` tipo: ${translatedCancelType}` : ""}.
          {cancelFee ? ` Multa: ${toCurrency(cancelFee)}` : ""}
        </Text>
      </View>
    </View>
  );
};
