import React from "react";
import Helmet from "react-helmet";

const SPLASH_RESOLUTIONS = [
  {
    height: "568px",
    orientation: "portrait",
    pixelRatio: "2",
    splashResolution: "640x1136",
    width: "320px",
  },
  {
    height: "667px",
    orientation: "portrait",
    pixelRatio: "2",
    splashResolution: "750x1334",
    width: "375px",
  },
  {
    height: "736px",
    orientation: "portrait",
    pixelRatio: "3",
    splashResolution: "1242x2208",
    width: "414px",
  },
  {
    height: "812px",
    orientation: "portrait",
    pixelRatio: "3",
    splashResolution: "1125x2436",
    width: "375px",
  },
  {
    height: "1024px",
    orientation: "portrait",
    pixelRatio: "2",
    splashResolution: "1536x2048",
    width: "768px",
  },
  {
    height: "1112px",
    orientation: "portrait",
    pixelRatio: "2",
    splashResolution: "1668x2224",
    width: "834px",
  },
  {
    height: "1366px",
    orientation: "portrait",
    pixelRatio: "2",
    splashResolution: "2048x2732",
    width: "1024px",
  },
] as const;

const ICON_RESOLUTIONS = [
  "72x72",
  "96x96",
  "128x128",
  "144x144",
  "152x152",
  "192x192",
  "384x384",
  "512x512",
] as const;

import ios_icon_128x128 from "@assets/images/ios-icons/ios-icon-128x128.png";
import ios_icon_144x144 from "@assets/images/ios-icons/ios-icon-144x144.png";
import ios_icon_152x152 from "@assets/images/ios-icons/ios-icon-152x152.png";
import ios_icon_192x192 from "@assets/images/ios-icons/ios-icon-192x192.png";
import ios_icon_384x384 from "@assets/images/ios-icons/ios-icon-384x384.png";
import ios_icon_512x512 from "@assets/images/ios-icons/ios-icon-512x512.png";
import ios_icon_72x72 from "@assets/images/ios-icons/ios-icon-72x72.png";
import ios_icon_96x96 from "@assets/images/ios-icons/ios-icon-96x96.png";
import ios_splash_1125x2436 from "@assets/images/ios-startup-images/startup-1125x2436.png";
import ios_splash_1242x2208 from "@assets/images/ios-startup-images/startup-1242x2208.png";
import ios_splash_1536x2048 from "@assets/images/ios-startup-images/startup-1536x2048.png";
import ios_splash_1668x2224 from "@assets/images/ios-startup-images/startup-1668x2224.png";
import ios_splash_2048x2732 from "@assets/images/ios-startup-images/startup-2048x2732.png";
import ios_splash_640x1136 from "@assets/images/ios-startup-images/startup-640x1136.png";
import ios_splash_750x1334 from "@assets/images/ios-startup-images/startup-750x1334.png";

const handleIOSIconResolution = (
  resolution: typeof ICON_RESOLUTIONS[number],
) => {
  switch (resolution) {
    case "72x72":
      return ios_icon_72x72;
    case "96x96":
      return ios_icon_96x96;
    case "128x128":
      return ios_icon_128x128;
    case "144x144":
      return ios_icon_144x144;
    case "152x152":
      return ios_icon_152x152;
    case "192x192":
      return ios_icon_192x192;
    case "384x384":
      return ios_icon_384x384;
    case "512x512":
      return ios_icon_512x512;
    default:
      return ios_icon_72x72;
  }
};

const handleIOSSplashImageResolution = (
  resolution: typeof SPLASH_RESOLUTIONS[number]["splashResolution"],
) => {
  switch (resolution) {
    case "640x1136":
      return ios_splash_640x1136;
    case "750x1334":
      return ios_splash_750x1334;
    case "1242x2208":
      return ios_splash_1242x2208;
    case "1125x2436":
      return ios_splash_1125x2436;
    case "1536x2048":
      return ios_splash_1536x2048;
    case "1668x2224":
      return ios_splash_1668x2224;
    case "2048x2732":
      return ios_splash_2048x2732;
    default:
      return ios_splash_640x1136;
  }
};

export const IOSResponsiveIcons: React.FC = () => {
  const renderIOSIcons = () =>
    ICON_RESOLUTIONS.map((resolution) => {
      return (
        <link
          href={handleIOSIconResolution(resolution)}
          key={resolution}
          rel="apple-touch-icon"
          sizes={resolution}
        />
      );
    });

  const renderIOSSplashImages = () =>
    SPLASH_RESOLUTIONS.map((resolution) => (
      <link
        href={handleIOSSplashImageResolution(resolution.splashResolution)}
        key={resolution.splashResolution}
        media={`(device-width: ${resolution.width}) and (device-height: ${resolution.height}) and (-webkit-device-pixel-ratio: ${resolution.pixelRatio}) and (orientation: ${resolution.orientation})`}
        rel="apple-touch-startup-image"
      />
    ));

  return (
    <Helmet>
      {renderIOSIcons()}
      {renderIOSSplashImages()}
    </Helmet>
  );
};
