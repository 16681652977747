import React from "react";

import { Check as CheckIcon, Block as BlockIcon } from "@material-ui/icons/";
import clsx from "classnames";
import { css } from "emotion";

import { Text, Flex, Box } from "@toolkit";

import { MEDIA_QUERIES } from "@constants";

import * as utils from "@helpers";
import * as expensesHelper from "@helpers/expense.helper";

import { Expense, ExpensePolicy } from "@models/expense.model";

import { defaultTheme } from "@theme";

import { CurrencyInput } from "@shared/inputs";
import InputError from "@shared/inputs/InputError";
import { Column, Row } from "@shared/layout/index";
import {
  Desktop,
  Mobile,
  Tablet,
  TabletAndMobile,
} from "@shared/layout/Responsive";
import { LongText } from "@shared/LongText";

import { CurrencyConversionSummary } from "~/components/shared/currency-conversion-summary";

import { ExpenseCardCategoryTag } from "../../shared/ExpenseCardCategoryTag";

const styles = {
  cardRoot: css({
    display: "flex",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0 4px 0 0",
    [MEDIA_QUERIES.desktopBreakpoint]: {},
    [MEDIA_QUERIES.mobileBreakpoint]: {
      flexDirection: "column",
    },
  }),
  card: css({
    display: "flex",
    alignItems: "flex-end",
    gap: "1rem",
    flexWrap: "wrap",
    color: defaultTheme.textColor,
    padding: "1.25rem 1.75rem",
    cursor: "pointer",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "1rem",
    },
  }),
  inactiveCard: css({
    color: defaultTheme.subTextColor + " !important",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
  }),
  buttonsDiv: css({
    alignItems: "center",
    paddingBottom: ".75rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingBottom: ".5rem",
    },
  }),
  period: css({
    fontSize: 14,
    paddingBottom: ".3rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
    },
  }),
  description: css({
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1.5,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 13,
    },
  }),
  priceColumn: css({
    justifyContent: "center",
    alignItems: "flex-end",
    flexShrink: 0,
    whiteSpace: "nowrap",
  }),
  price: css({
    fontWeight: "bold",
    fontSize: 18,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 14,
    },
  }),
  positionBottom: css({}),
  typeTag: css({
    padding: ".375rem 1rem",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    fontSize: 12,
    fontWeight: "bold",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 10,
      padding: ".15rem 1rem",
    },
  }),
  inputDiv: css({
    padding: "0 1rem",
  }),
  approvedValueDiv: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 .5rem",
    borderLeft: `1px solid ${defaultTheme.cardBorderColor}`,
    minWidth: 133,
    maxWidth: 133,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      borderTop: `1px solid ${defaultTheme.cardBorderColor}`,
      borderLeft: "none",
      padding: ".5rem",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      maxWidth: "100%",
    },
  }),
  approvedLabel: css({
    fontSize: 14,
    paddingBottom: ".5rem",
    textAlign: "center",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 16,
      fontWeight: "bold",
      paddingRight: ".5rem",
      paddingBottom: 0,
    },
  }),
  outOfPolicyLabel: css({
    fontSize: 14,
    fontWeight: "bold",
    color: defaultTheme.secondaryColor,
    marginRight: "1rem",
    alignSelf: "center",
    cursor: "pointer",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
      marginRight: 0,
    },
  }),
  refundableIcons: css({
    marginRight: ".25rem",
    width: "1rem",
    height: "1rem",
  }),
};

interface Props {
  expense: Expense;
  policyInfo?: ExpensePolicy;
  showApprovalInput?: boolean;
  disabled?: boolean;
  refundable?: boolean;
  participantsVisible?: boolean;
  error: string | null;
  onClick: () => void;
  onInputChange: (value: number) => void;
  onInputBlur: (e: any) => void;
  handleOutOfPolicyClick: () => void;
}

const ExpenseItemPresentational = ({
  expense,
  policyInfo,
  showApprovalInput,
  error,
  disabled,
  refundable,
  participantsVisible,
  onClick,
  onInputChange,
  onInputBlur,
  handleOutOfPolicyClick,
}: Props) => {
  const convertedValue = expensesHelper.convertCurrencyValue(expense);
  const isForeignCurrency = expensesHelper.isForeignCurrencyExpense(expense);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Row style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
        <ExpenseCardCategoryTag
          categoryName={expense.expenseCategoryName}
          categoryColor={expense.expenseCategoryColor}
          isCanceled={expense.canceled}
        />
        {!!policyInfo && policyInfo.outOfPolicy ? (
          <div
            className={styles.outOfPolicyLabel}
            onClick={handleOutOfPolicyClick}
          >
            <span>Fora da política</span>
          </div>
        ) : null}
      </Row>
      <div className={styles.cardRoot}>
        <Row
          className={clsx(styles.card, disabled ? styles.inactiveCard : "")}
          onClick={onClick}
        >
          <div
            css={{
              display: "flex",
              width: "100%",
              maxWidth: "192px",
              flexDirection: "column",
            }}
          >
            <p className={styles.period}>
              {expense.date.format("dddd, DD MMM YYYY")}
            </p>
            <Mobile>
              <LongText limit={30} className={styles.description}>
                {expense.description}
              </LongText>
            </Mobile>
            <Tablet>
              <LongText limit={70} className={styles.description}>
                {expense.description}
              </LongText>
            </Tablet>
            <Desktop>
              <LongText limit={40} className={styles.description}>
                {expense.description}
              </LongText>
            </Desktop>
          </div>
          <Column
            className={clsx(
              styles.priceColumn,
              !!expense.approvedValue || showApprovalInput
                ? styles.positionBottom
                : null,
            )}
          >
            <p className={styles.price}>{utils.toCurrency(convertedValue)}</p>
          </Column>
        </Row>
        {showApprovalInput ? (
          <div className={clsx(styles.approvedValueDiv, styles.inputDiv)}>
            <div className={styles.approvedLabel}>
              <Desktop>
                <span>Aprovar</span>
              </Desktop>
              <TabletAndMobile>
                <span>Aprovar: </span>
              </TabletAndMobile>
            </div>
            <CurrencyInput
              placeholder="Valor"
              value={expense.approvedValue}
              onChange={onInputChange}
              onBlur={onInputBlur}
              error={!!error}
            />
          </div>
        ) : null}
        {!showApprovalInput && !!expense.approvedValue ? (
          <div className={styles.approvedValueDiv}>
            <div className={styles.approvedLabel}>
              <Desktop>
                <span>Aprovado</span>
              </Desktop>
              <TabletAndMobile>
                <span>Valor aprovado: </span>
              </TabletAndMobile>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                className={styles.price}
                style={{ color: defaultTheme.successTextColor }}
              >
                {utils.toCurrency(expense.approvedValue)}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      {isForeignCurrency ? (
        <CurrencyConversionSummary expense={expense} />
      ) : null}
      <Flex
        width="100%"
        py={["small"]}
        px={["medium", null, 16]}
        justifyContent={"space-between"}
        alignItems="center"
        border="1px solid"
        borderTop="none"
        borderColor="border"
        borderRadius={"0 0 4px 4px"}
        backgroundColor="rgba(0, 0, 0, 0.02)"
      >
        {participantsVisible ? (
          <Text color={defaultTheme.subTextColor} fontSize={0}>
            {expense.participantsQuantity > 1
              ? `${expense.participantsQuantity} participantes`
              : `${expense.participantsQuantity} participante`}
          </Text>
        ) : null}
        <Box color={defaultTheme.subTextColor} ml="auto" fontSize={0}>
          {refundable ? (
            <Flex alignItems="center">
              <CheckIcon className={styles.refundableIcons} />
              <Text>Reembolsável</Text>
            </Flex>
          ) : (
            <Flex alignItems="center">
              <BlockIcon className={styles.refundableIcons} />
              <Text>Não reembolsável</Text>
            </Flex>
          )}
        </Box>
      </Flex>

      {error ? <InputError>{error}</InputError> : null}
    </div>
  );
};

ExpenseItemPresentational.defaultProps = {
  error: null,
  onInputChange: () => null,
  onInputBlur: () => null,
} as Partial<Props>;

export { ExpenseItemPresentational };
