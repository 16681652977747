import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  checkbox: {
    group: {
      item: {
        label: {
          root: css({
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }),
          label: css({
            fontSize: "0.875rem",
            lineHeight: "19.36px",
          }),
          price: css({
            color: theme.colors.gray[300],
            fontSize: "0.75rem",
          }),
        },
        root: css({
          ".MuiFormControlLabel-label": {
            width: "100%",
          },
        }),
      },
      root: css({
        gap: "2px",
      }),
    },
  },
  root: css({
    color: theme.colors.gray[300],
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
