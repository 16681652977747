import React, { useState } from "react";

import { FormikProps, withFormik, FormikErrors } from "formik";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment";

import { IBookingTarget } from "~/models/booker-target.model";

import { HotelSearch } from "../NewTrip.types";
import { HotelFormPresentational } from "./HotelForm.presentational";
import { useRecentHotelsContext } from "./RecentHotels/RecentHotelsProvider";

interface Props {
  onSubmit: (hotelSearchData: HotelSearch) => void;
  searchFields?: HotelSearch;
}

const HotelFormContainer = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  setFieldValue,
  setErrors
}: FormikProps<HotelSearch>) => {
  const [focused, setFocus] = useState<string | null>(null);

  const removeFieldError = (fieldName: string) => {
    const updatedErrors = reduce(
      errors,
      (acc: any, value, key) => {
        if (key !== fieldName) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    setErrors(updatedErrors);
  };

  const handleLocationChange = (location: any) => {
    if (!location) {
      setFieldValue("location", null);
    } else {
      setFieldValue("location", {
        search: location.formattedAddress,
        latitude: location.latitude(),
        longitude: location.longitude()
      });
      removeFieldError("location");
    }
  };

  const handleDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setFieldValue("startDate", date.startDate);
    setFieldValue("endDate", date.endDate);

    if (date.startDate) {
      removeFieldError("startDate");
    }

    if (date.endDate) {
      removeFieldError("endDate");
    }
  };

  const handleTravelerChange = (traveler: IBookingTarget) => {
    setFieldValue("traveler", traveler);
    if (traveler) {
      removeFieldError("traveler");
    }
  };

  return (
    <HotelFormPresentational
      values={values}
      errors={errors}
      focusedDateInput={focused}
      handleLocationChange={handleLocationChange}
      handleDateChange={handleDateChange}
      handleTravelerChange={handleTravelerChange}
      handleFocusChange={setFocus}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};

const FormikContainer = withFormik<Props, HotelSearch>({
  mapPropsToValues: ({ searchFields }) => {
    return searchFields
      ? {
          startDate: searchFields.startDate,
          endDate: searchFields.endDate,
          location: searchFields.location,
          totalGuests: searchFields.totalGuests,
          traveler: searchFields.traveler
        }
      : {
          startDate: null,
          endDate: null,
          location: null,
          totalGuests: 1,
          traveler: null
        };
  },
  validate: values => {
    const errors: FormikErrors<HotelSearch> = {};

    if (!values.startDate) {
      errors.startDate = "Preencha a data de check-in";
    } else if (
      values.startDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    ) {
      errors.startDate = "Data inicial inferior a hoje";
    }

    if (!values.endDate) {
      errors.endDate = "Preencha a data de check-out";
    }

    if (
      values.startDate &&
      values.endDate &&
      values.endDate.diff(values.startDate, "days") > 31
    ) {
      errors.endDate = "Tempo de hospedagem deve ser inferior a 31 dias";
    }

    if (
      values.startDate &&
      values.endDate &&
      values.startDate.format("YYYY-MM-DD") ===
        values.endDate.format("YYYY-MM-DD")
    ) {
      errors.startDate = "Data de check-in e check-out devem ser diferentes";
    }

    if (!values.location) {
      errors.location = "Preencha o local da hospedagem";
    }

    if (!values.traveler) {
      errors.traveler = "Atribua um viajante";
    }

    return errors;
  },
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(HotelFormContainer);

const HotelFormWithRecentHotels = (props: Props) => {
  const { selectedSearch } = useRecentHotelsContext();

  return (
    <FormikContainer onSubmit={props.onSubmit} searchFields={selectedSearch} />
  );
};

export { HotelFormWithRecentHotels as HotelFormContainer };
