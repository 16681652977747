import {
  BusSearchCreationRequest,
  TripConnection,
  TripPart,
  TripPartSegment,
} from "~/apps/corporate/dtos/bus.dto";
import {
  BusSearchForm,
  BusSearchInfo,
} from "~/apps/corporate/models/bus.model";
import { SERVICE_TYPES, SUPPLIERS } from "~/apps/shared/constants";
import moment from "moment";

import {
  PageState,
  SearchState,
  TripState,
} from "../pages/travels/search/buses/buses.context";

type BusPolicyStatus = {
  outOfPolicy: boolean;
};

export const buildBusSearchData = (
  formData: BusSearchForm,
  travelToken: string,
) => {
  const { departureDate, destination, origin, returnDate, type } = formData;

  const isOneway = type === "oneway";

  const data: BusSearchCreationRequest = {
    departure_date: departureDate!.format("YYYY-MM-DD"),
    departure_lat: destination!.latitude,
    departure_lon: destination!.longitude,
    destination_city_name: destination!.city.name,
    destination_country_code: destination!.country.code,
    destination_country_name: destination!.country.name,
    destination_state_code: destination!.state.code,
    destination_state_name: destination!.state.name,
    from_slug: origin!.slug,
    oneway: isOneway,
    origin_city_name: origin!.city.name,
    origin_country_code: origin!.country.code,
    origin_country_name: origin!.country.name,
    origin_lat: origin!.latitude,
    origin_lon: origin!.longitude,
    origin_state_code: origin!.state.code,
    origin_state_name: origin!.state.name,
    to_slug: destination!.slug,
    travel_token: travelToken,
  };

  if (!isOneway && returnDate) {
    data.return_date = returnDate.format("YYYY-MM-DD");
  }

  return data;
};

export const buildBusLocations = (searchInfo: BusSearchInfo) => {
  const originName = [
    searchInfo.originCityName,
    searchInfo.originStateCode,
  ].join(", ");

  const originLocation = {
    city: {
      name: searchInfo.originCityName,
    },
    country: {
      code: searchInfo.originCountryCode,
      name: searchInfo.originCountryName,
    },
    label: originName,
    latitude: searchInfo.originLat,
    longitude: searchInfo.originLon,
    name: originName,
    slug: searchInfo.fromSlug,
    state: {
      code: searchInfo.originStateCode,
      name: searchInfo.originStateName,
    },
  };

  const destinationName = [
    searchInfo.destinationCityName,
    searchInfo.destinationStateCode,
  ].join(", ");

  const destinationLocation = {
    city: {
      name: searchInfo.destinationCityName,
    },
    country: {
      code: searchInfo.destinationCountryCode,
      name: searchInfo.destinationCountryName,
    },
    label: destinationName,
    latitude: searchInfo.departureLat,
    longitude: searchInfo.departureLon,
    name: destinationName,
    slug: searchInfo.toSlug,
    state: {
      code: searchInfo.destinationStateCode,
      name: searchInfo.destinationStateName,
    },
  };

  return {
    destinationLocation,
    originLocation,
  };
};

export const formatSegment = (segment: TripPartSegment) => {
  return {
    ...segment,
    schedule: {
      ...segment.schedule,
      time: moment(segment.schedule.time, "HH:mm:ss").format("HH:mm"),
    },
  };
};

export const getTripSegments = (trip: TripConnection) => {
  const departureInfo = trip.parts[0].departure;
  const departure = formatSegment(departureInfo);

  const lastPartIndex = trip.parts.length - 1;

  const arrivalInfo = trip.parts[lastPartIndex].arrival;
  const arrival = formatSegment(arrivalInfo);

  return {
    arrival,
    departure,
  };
};

export const getOfferSeatAvailability = (tripPart?: TripPart) => {
  if (tripPart) {
    return tripPart.availableSeats > 0 && tripPart.isReservable;
  }

  return true;
};

export const getStretchPrice = (tripParts: any) => {
  const seatsInfo = tripParts.reduce((prev: any, curr: any) => {
    prev = prev.concat(curr.selectedSeats);

    return prev;
  }, []);

  const seatsPrice = seatsInfo.reduce((prev: any, curr: any) => {
    prev += curr.details.price;

    return prev;
  }, 0);

  return seatsPrice;
};

export const buildBusOffer = (
  tripState: TripState,
  pageState: PageState,
  searchState: SearchState,
  policyStatus: BusPolicyStatus,
) => {
  if (!searchState.searchInfo) {
    return null;
  }

  const { departureTrips, returnTrips } = pageState;
  const { busToken, travelToken } = searchState.searchInfo;
  const {
    allTripsState,
    selectedDepartureBusId,
    selectedReturnBusId,
  } = tripState;

  if (!selectedDepartureBusId) {
    return null;
  }

  // Departure
  let departureTrip = departureTrips.find(
    (trip) => selectedDepartureBusId === trip.id.toString(),
  );
  const departureTripPartsState =
    allTripsState[parseInt(selectedDepartureBusId, 10)].tripPartsState;
  const departureTripParts = Object.keys(departureTripPartsState).map(
    (tripPartId) => {
      return departureTripPartsState[tripPartId];
    },
  );
  const departureTripPartIds = departureTripParts.map((item) => item.id);
  const departureSelectedSeatsIds = departureTripParts.map(
    (item) => item.selectedSeats[0].id,
  );

  const departurePrice = getStretchPrice(departureTripParts);
  departureTrip = Object.assign({}, departureTrip, {
    selectedPrice: departurePrice,
  });

  // Return
  let returnSelectedSeatsIds, returnTripPartIds, returnTrip, returnPrice;
  if (selectedReturnBusId) {
    returnTrip = returnTrips.find(
      (trip) => selectedReturnBusId === trip.id.toString(),
    );
    const returnTripPartsState =
      allTripsState[parseInt(selectedReturnBusId, 10)].tripPartsState;
    const returnTripParts = Object.keys(returnTripPartsState).map(
      (tripPartId) => {
        return returnTripPartsState[tripPartId];
      },
    );
    returnTripPartIds = returnTripParts.map((item) => item.id);
    returnSelectedSeatsIds = returnTripParts.map(
      (item) => item.selectedSeats[0].id,
    );
    returnPrice = getStretchPrice(returnTripParts);
    returnTrip = Object.assign({}, returnTrip, { selectedPrice: returnPrice });
  }

  const price = returnTrip ? departurePrice + returnPrice : departurePrice;

  const busOffer = {
    cheapest_price: price,
    e_out_of_policy: policyStatus.outOfPolicy,
    inbound_carrier: returnTrip
      ? returnTrip.parts[0].travelCompany.name
      : undefined,
    inbound_fare_family: returnTrip ? returnTrip.parts[0].bus.name : undefined,
    inbound_departure_hour: returnTrip
      ? parseInt(returnTrip.parts[0].departure.schedule.time.split(":")[0], 10)
      : undefined,
    inbound_seat: returnSelectedSeatsIds
      ? returnSelectedSeatsIds.join(";")
      : undefined,
    inbound_trip_ids: returnTripPartIds
      ? returnTripPartIds.join(";")
      : undefined,
    item_token: busToken,
    offer: {
      departureTrip,
      returnTrip,
    },
    original_price: price,
    outbound_carrier: departureTrip.parts[0].travelCompany.name,
    outbound_departure_hour: parseInt(
      departureTrip.parts[0].departure.schedule.time.split(":")[0],
      10,
    ),
    outbound_fare_family: departureTrip.parts[0].bus.name,
    outbound_seat: departureSelectedSeatsIds.join(";"),
    outbound_trip_ids: departureTripPartIds.join(";"),
    price,
    search_channel: SUPPLIERS.CLICKBUS,
    supplier: SUPPLIERS.CLICKBUS,
    travel_token: travelToken,
    type: SERVICE_TYPES.bus,
  };

  return busOffer;
};
