import { BlobProvider } from "@react-pdf/renderer";
import React, { useCallback, useState } from "react";

import * as clientApi from "~/apps/corporate/apis/client.api";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { ERROR } from "~/apps/shared/constants/errors";
import { forceFileDownload } from "~/apps/shared/utils/force-file-download";
import * as yup from "yup";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryServices } from "../../../itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { styles } from "./styles";
import { TravelPlanPDF } from "./travel-plan-pdf/travel-plan-pdf";
import { useTravelPlanShareDialogGuard } from "./travel-plan-share-dialog.hooks";

export const TravelPlanShareDialog: React.FC = () => {
  const { loading } = useTravelPlanShareDialogGuard();

  const { infoModel, servicesPresenter } = useItineraryContainer();

  const { closeShareDialog, isShareDialogOpen, share } = useItineraryScreen();
  const { travelPoliciesPerOffer } = useItineraryServices();

  const [selectedManner, setSelectedManner] = useState<"guest" | "user" | null>(
    null,
  );

  const handleDownload = useCallback(
    (url: string | null) => {
      if (!infoModel || !url) {
        return;
      }

      const travelName = infoModel.getTravelName();

      forceFileDownload(url, travelName ? travelName : "Itinerário");
    },
    [infoModel],
  );

  const handleShare = useCallback(
    async (url: string | null, email: string) => {
      if (!url) {
        return;
      }

      await share(url, email);
    },
    [share],
  );

  if (!infoModel || !servicesPresenter) {
    return null;
  }

  return (
    <Dialog
      aria-labelledby="Compartilhar viagem"
      aria-describedby="Compartilhe o seu plano de viagem"
      css={styles.root}
      onClose={closeShareDialog}
      open={isShareDialogOpen}
    >
      <DialogHeader onClose={closeShareDialog} icon="share-nodes" />
      <DialogContent>
        <DialogTitle>Compartilhar itinerário.</DialogTitle>
        <span css={styles.message}>Escolha como deseja compartilhar:</span>
      </DialogContent>
      <DialogFooter css={styles.footer.root}>
        <BlobProvider
          document={
            <TravelPlanPDF
              infoModel={infoModel}
              servicesPresenter={servicesPresenter}
              travelPoliciesPerOffer={travelPoliciesPerOffer}
            />
          }
        >
          {({ loading: loadingTravelPlanPDF, url }) => {
            if (loading || loadingTravelPlanPDF) {
              return (
                <>
                  <Button
                    onClick={() => {
                      setSelectedManner("user");
                    }}
                  >
                    <Icon size={16} use="paper-airplane" />
                    Usuário Smartrips
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedManner("guest");
                    }}
                  >
                    <Icon size={16} use="at-symbol" />
                    E-mail de terceiro
                  </Button>
                  <Button disabled>
                    <CircularSpinner
                      css={styles.footer.download.button.spinner}
                      size={16}
                    />
                    Baixar
                  </Button>
                </>
              );
            }

            return (
              <>
                {selectedManner === "guest" ? (
                  <TravelPlanShareDialogGuestForm
                    handleBack={() => {
                      setSelectedManner(null);
                    }}
                    handleShare={async (email) => {
                      await handleShare(url, email);

                      setSelectedManner(null);
                    }}
                  />
                ) : null}
                {selectedManner === "user" ? (
                  <TravelPlanShareDialogUserForm
                    handleBack={() => {
                      setSelectedManner(null);
                    }}
                    handleShare={async (email) => {
                      await handleShare(url, email);

                      setSelectedManner(null);
                    }}
                  />
                ) : null}
                {selectedManner === null ? (
                  <>
                    <Button
                      onClick={() => {
                        setSelectedManner("user");
                      }}
                    >
                      <Icon size={16} use="paper-airplane" />
                      Usuário Smartrips
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedManner("guest");
                      }}
                    >
                      <Icon size={16} use="at-symbol" />
                      E-mail de terceiro
                    </Button>
                    <Button
                      onClick={() => {
                        handleDownload(url);
                      }}
                    >
                      <Icon size={16} use="cloud-arrow-down" />
                      Baixar
                    </Button>
                  </>
                ) : null}
              </>
            );
          }}
        </BlobProvider>
      </DialogFooter>
    </Dialog>
  );
};

type TravelPlanShareDialogGuestFormProps = {
  handleBack: () => void;
  handleShare: (email: string) => void;
};

const TravelPlanShareDialogGuestForm: React.FC<TravelPlanShareDialogGuestFormProps> = ({
  handleBack,
  handleShare,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!email) {
      setError("Campo obrigatório.");

      return;
    }

    if (!yup.string().email().isValidSync(email)) {
      setError("Email inválido.");

      return;
    }

    handleShare(email);
  };

  return (
    <div css={styles.footer.guest.root}>
      <Input
        css={styles.footer.guest.input}
        onChange={handleChange}
        placeholder="Digite o e-mail do destinatário..."
        type="email"
        value={email}
      />
      {error ? <InputErrorMessage>{error}</InputErrorMessage> : null}
      <div css={styles.footer.guest.buttons.root}>
        <Button
          css={styles.footer.guest.buttons.button}
          fill="outlined"
          onClick={() => {
            handleBack();
          }}
        >
          Voltar
        </Button>
        <Button
          css={styles.footer.guest.buttons.button}
          disabled={!email || !!error}
          onClick={() => {
            handleSubmit();
          }}
        >
          Compartilhar
        </Button>
      </div>
    </div>
  );
};

type TravelPlanShareDialogUserFormProps = {
  handleBack: () => void;
  handleShare: (email: string) => void;
};

const TravelPlanShareDialogUserForm: React.FC<TravelPlanShareDialogUserFormProps> = ({
  handleBack,
  handleShare,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!email) {
      setError("Campo obrigatório.");

      return;
    }

    if (!yup.string().email().isValidSync(email)) {
      setError("Email inválido.");

      return;
    }

    handleShare(email);
  };

  return (
    <div css={styles.footer.user.root}>
      <AsyncAutocomplete
        fetchItems={async () => {
          return await clientApi
            .getUsers()
            .then((users) => {
              if (!users) {
                return { error: ERROR.UNEXPECTED };
              }

              return {
                data: users
                  .filter(
                    (user) =>
                      user.email.toLowerCase().includes(email.toLowerCase()) ||
                      user.fullName
                        ?.toLowerCase()
                        .includes(email.toLowerCase()),
                  )
                  .map((user) => ({
                    email: user.email,
                    label: user.fullName,
                    userToken: user.userToken,
                  })),
              };
            })
            .catch(() => {
              return { error: ERROR.UNEXPECTED };
            });
        }}
        filterItems={(users) => users.filter((user) => user.email !== email)}
        inputProps={{
          placeholder: "Usuário ou e-mail...",
        }}
        onChange={(value) => {
          setEmail(value);
          setError("");
        }}
        onSelect={(user) => {
          setEmail(user.email);
        }}
        renderItem={({ isHighlighted, item }) => {
          if (item.error) {
            return (
              <li
                css={styles.footer.user.autocomplete.item.root({
                  isDisabled: true,
                  isHighlighted: false,
                })}
              >
                Nenhum resultado.
              </li>
            );
          }

          return (
            <li
              css={styles.footer.user.autocomplete.item.root({ isHighlighted })}
            >
              <span>{item.label}</span>
              <span css={styles.footer.user.autocomplete.item.email}>
                {item.email}
              </span>
            </li>
          );
        }}
      />
      {error ? <InputErrorMessage>{error}</InputErrorMessage> : null}
      <div css={styles.footer.user.buttons.root}>
        <Button
          css={styles.footer.user.buttons.button}
          fill="outlined"
          onClick={() => {
            handleBack();
          }}
        >
          Voltar
        </Button>
        <Button
          css={styles.footer.user.buttons.button}
          disabled={!email || !!error}
          onClick={() => {
            handleSubmit();
          }}
        >
          Compartilhar
        </Button>
      </div>
    </div>
  );
};
