import React, { useEffect } from "react";

import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItinerary } from "../../../itinerary.context";
import { useItineraryInfoTagsPresenter } from "./itinerary-info-tags.hooks";
import { styles } from "./styles";

type Props = {
  isDisabled?: boolean;
};

export const ItineraryInfoTags: React.FC<Props> = ({ isDisabled }) => {
  const { itineraryInfoTagsPresenter } = useItineraryInfoTagsPresenter();

  const { travelToken } = useItinerary();
  const { openTravelTagsDialog } = useItineraryScreen();
  const { loadTravelTags } = useTags();

  useEffect(() => {
    void loadTravelTags(travelToken);
  }, [travelToken]);

  if (!itineraryInfoTagsPresenter) {
    return null;
  }

  const travelTags = itineraryInfoTagsPresenter.getTravelTags(travelToken);

  return (
    <div css={styles.root}>
      <Icon size={14} use="tag" />
      {travelTags && travelTags.length > 0 ? (
        <span css={styles.text}>
          Etiquetas aplicadas:{" "}
          <button
            css={styles.button({ isDisabled })}
            disabled={isDisabled}
            onClick={() => {
              openTravelTagsDialog();
            }}
          >
            <span css={styles.tags}>
              {travelTags.map((travelTag) => travelTag.tagName).join(", ")}.
            </span>
          </button>
        </span>
      ) : (
        <button
          css={styles.empty({ isDisabled })}
          disabled={isDisabled}
          onClick={() => {
            openTravelTagsDialog();
          }}
        >
          Nenhuma etiqueta aplicada.
        </button>
      )}
    </div>
  );
};
