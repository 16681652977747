import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  dialog: {
    actions: ecss({
      alignItems: "center",
      display: "flex",
      gap: "32px",
      justifyContent: "space-between",
      padding: "32px"
    }),
    content: ecss({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      padding: "0 32px",
      overflow: "visible",
      width: "100%"
    }),
    paper: ecss({
      maxWidth: 700,
      overflow: "visible",
      width: "100%",
      ["@media (max-width: 767px)"]: {
        margin: 20
      }
    }),
    select: {
      multiValue: {
        avatar: css({
          fontSize: "12px",
          fontWeight: 700,
          height: "24px",
          width: "24px"
        }),
        removeButton: css({
          alignItems: "center",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          display: "flex",
          height: "24px",
          justifyContent: "center",
          width: "24px"
        }),
        root: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: "16px",
          display: "flex",
          gap: "8px",
          padding: "4px"
        })
      },
      multiValueContainer: {
        root: css({
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          padding: "4px"
        })
      },
      option: {
        avatar: css({
          fontSize: "12px",
          fontWeight: 700,
          height: "24px",
          width: "24px"
        }),
        root: css({
          cursor: "pointer",
          padding: "16px",
          transition: "background-color 0.15s ease-in-out",
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
          }
        })
      }
    }
  },
  header: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "32px 32px 0 32px"
  })
};
