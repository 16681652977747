import React, { createContext, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";

import {
  GetUserCompanyEventDto,
  ListEventParticipantsDto,
  ListEventUpdatesDto
} from "sm-types/sm-company-events";

import { useContextFactory } from "~/hooks";

import { useEvents } from "../events.context";
import * as eventsService from "../events.service";

type EventContextProps = {
  event: GetUserCompanyEventDto | null;
  invalidateEvent: () => Promise<void>;
  invalidateParticipants: () => Promise<void>;
  invalidateUpdates: () => Promise<void>;
  isLoading: boolean;
  isLoadingParticipants: boolean;
  isLoadingUpdates: boolean;
  participants: ListEventParticipantsDto | null;
  updates: ListEventUpdatesDto | null;
};

const EventContext = createContext<EventContextProps>({} as EventContextProps);

export const useEvent = useContextFactory("EventContext", EventContext);

type Props = {
  token: string;
};

export const EventProvider: React.FC<Props> = ({ children, token }) => {
  const queryClient = useQueryClient();

  const { getEvent, getEventParticipants } = useEvents();

  const { data: event, isLoading } = useQuery(["events", token], async () => {
    return await getEvent({ token });
  });

  const { data: participants, isLoading: isLoadingParticipants } = useQuery(
    ["events", token, "participants"],
    async () => {
      return await getEventParticipants({ token });
    },
    {
      enabled: !!event
    }
  );

  const { data: updates, isLoading: isLoadingUpdates } = useQuery(
    ["events", token, "updates"],
    async () => {
      const result = await eventsService.listEventUpdates({
        token
      });

      if (!result.data || result.error) {
        return null;
      }

      return result.data;
    },
    {
      enabled: !!event
    }
  );

  const invalidateEvent: EventContextProps["invalidateEvent"] = useCallback(async () => {
    await queryClient.invalidateQueries(["events", token]);
  }, [queryClient, token]);

  const invalidateParticipants: EventContextProps["invalidateParticipants"] = useCallback(async () => {
    await queryClient.invalidateQueries(["events", token, "participants"]);
  }, [queryClient, token]);

  const invalidateUpdates: EventContextProps["invalidateUpdates"] = useCallback(async () => {
    await queryClient.invalidateQueries(["events", token, "updates"]);
  }, [queryClient, token]);

  return (
    <EventContext.Provider
      value={{
        event: event || null,
        invalidateEvent,
        invalidateParticipants,
        invalidateUpdates,
        isLoading,
        isLoadingParticipants,
        isLoadingUpdates,
        participants: participants || null,
        updates: updates || null
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
