import * as envUtils from "./apps/shared/utils/env";
import { logger } from "./apps/shared/utils/logger";

const register = async () => {
  await navigator.serviceWorker.register("sw.js").then(
    (registration) => {
      logger.info(
        `vite sw registration successful: ${JSON.stringify(registration)} 😍`,
      );
    },
    (err) => {
      logger.error(`vite sw registration failed: ${JSON.stringify(err)} 😠`);
    },
  );
};

if ("serviceWorker" in navigator && !envUtils.isDevEnv()) {
  window.addEventListener("load", () => {
    void register();
  });
}
