import { OfferStatus } from "./enums";

export const OFFER_STATUS_COLOR = {
  [OfferStatus.APPROVAL_DECLINED]: "#FF0000",
  [OfferStatus.BOOKING_FAILED]: "#FF0000",
  [OfferStatus.BOOKING_PROCESSING]: "#FA831B",
  [OfferStatus.CANCELED]: "#B24531",
  [OfferStatus.CANCELING]: "#FA831B",
  [OfferStatus.DRAFT]: "#808080",
  [OfferStatus.EMITTED]: "#027A48",
} as const;

export const OFFER_STATUS_TRANSLATED = {
  [OfferStatus.APPROVAL_DECLINED]: "Aprovação negada",
  [OfferStatus.BOOKING_FAILED]: "Falha de emissão",
  [OfferStatus.BOOKING_PROCESSING]: "Emitindo",
  [OfferStatus.CANCELED]: "Cancelada",
  [OfferStatus.CANCELING]: "Cancelando",
  [OfferStatus.DRAFT]: "Rascunho",
  [OfferStatus.EMITTED]: "Emitida",
} as const;
