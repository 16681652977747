import { api } from "../../shared/services/api";
import {
  CostCenterCreationRequest,
  GetUserCostCentersResponse,
  CostCenterListResponseItem,
  CostCenterResponse,
  CostCenterUpdateRequest,
} from "../dtos/cost-center-dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import * as costCenterParser from "../parsers/cost-center.parser";

export async function archiveCostCenter(token: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "DELETE",
    url: `/booking/cost-centers/${token}`,
  });
}

export async function createCostCenter(data: CostCenterCreationRequest) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/clients/${data.client_token}/cost-centers`,
  });
}

export async function getCostCenterByToken(token: string) {
  return await api
    .request<CostCenterResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/cost-centers/${token}`,
    })
    .then(({ data }) => costCenterParser.parseCostCenter(data));
}

export async function getUserCostCenters(userToken: string) {
  return await api
    .request<GetUserCostCentersResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/${userToken}/cost-centers/`,
    })
    .then(({ data }) => costCenterParser.parseUserCostCenters(data));
}

export async function listCostCenters() {
  return await api
    .request<CostCenterListResponseItem[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/cost-centers`,
    })
    .then(({ data }) =>
      data.map((item) => costCenterParser.parseCostCenterListItem(item)),
    );
}

export async function updateCostCenter(
  costCenterToken: string,
  data: CostCenterUpdateRequest,
) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/cost-centers/${costCenterToken}`,
  });
}
