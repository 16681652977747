import React from "react";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";
import { Row } from "../../../shared/layout/Row";
import { Column } from "../../../shared/layout/Column";
import RadioButton from "@material-ui/core/Radio";
import RadioIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { ExpenseReport } from "../../../../models/expense.model";
import AddCircleIcon from "@material-ui/icons/AddCircleOutlineRounded";
import * as expensesHelper from "../../../../helpers/expense.helper";

const styles = {
  root: css({
    padding: "1.5rem 1.5rem 1.5rem .75rem",
    marginBottom: "1.5rem",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    alignItems: "center",
    ["@media (max-width: 500px)"]: {
      padding: "1.25rem 2rem 1.25rem 1rem"
    }
  }),
  reportPeriod: css({
    marginBottom: "1rem",
    fontSize: 15,
    ["@media (max-width: 500px)"]: {
      fontSize: 12
    }
  }),
  reportDescription: css({
    fontWeight: "bold",
    fontSize: 18,
    ["@media (max-width: 500px)"]: {
      fontSize: 14
    }
  }),
  radioRoot: css({
    color: defaultTheme.cardBorderColor,
    marginRight: "1rem",
    ["@media (max-width: 500px)"]: {
      marginRight: ".5rem"
    }
  }),
  newItemRadioRoot: css({
    width: 52,
    height: 52,
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
    ["@media (max-width: 500px)"]: {
      marginRight: ".5rem"
    }
  }),
  radioIcon: css({
    fontSize: 28,
    ["@media (max-width: 500px)"]: {
      fontSize: 21
    }
  }),
  radioChecked: css({
    color: `${defaultTheme.primaryColor} !important`
  }),
  newItemRoot: css({
    padding: "1.5rem 1.5rem 1.5rem .75rem",
    marginBottom: "1.5rem",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    alignItems: "center",
    cursor: "pointer",
    ["@media (max-width: 500px)"]: {
      padding: "1.25rem 2rem 1.25rem 1rem"
    }
  })
};

interface ReportSelectItemProps {
  reportData: ExpenseReport;
  isChecked: boolean;
  handleChange: (_: any, checked: boolean) => void;
}

const ReportSelectItem = ({
  reportData,
  isChecked,
  handleChange
}: ReportSelectItemProps) => {
  const { expenseReportToken, description, createdAt } = reportData;

  const getFormattedReportPeriod = () => {
    const { periodStart, periodEnd } = expensesHelper.calculateExpensesPeriod(
      reportData.expenses
    );

    return `${periodStart.format("DD MMM YYYY")} - ${periodEnd.format(
      "DD MMM YYYY"
    )}`;
  };

  return (
    <Row className={styles.root}>
      <div>
        <RadioButton
          checked={isChecked}
          onChange={handleChange}
          classes={{
            root: styles.radioRoot,
            checked: styles.radioChecked
          }}
          value={expenseReportToken}
          icon={<RadioIcon className={styles.radioIcon} />}
          checkedIcon={<RadioCheckedIcon className={styles.radioIcon} />}
        />
      </div>
      <Column>
        {reportData.expenses.length ? (
          <div className={styles.reportPeriod}>
            <span>{getFormattedReportPeriod()}</span>
          </div>
        ) : null}
        <div className={styles.reportDescription}>{description}</div>
      </Column>
    </Row>
  );
};

interface NewReportItemProps {
  onClick: () => void;
}

const NewReportItem = ({ onClick }: NewReportItemProps) => (
  <Row className={styles.newItemRoot} onClick={onClick}>
    <Row className={styles.newItemRadioRoot}>
      <AddCircleIcon
        className={styles.radioIcon}
        style={{ color: defaultTheme.primaryColor }}
      />
    </Row>
    <Column>
      <div
        style={{ color: defaultTheme.primaryColor }}
        className={styles.reportDescription}
      >
        Criar novo relatório
      </div>
    </Column>
  </Row>
);

export { ReportSelectItem, NewReportItem };
