import React from "react";

import { CostCenterListByUserItem } from "~/models/cost-center.model";

import { Select } from "@shared/inputs";

import {
  useReportApprovalFormActionsContext,
  useReportApprovalFormContext,
} from "../report.context";

const isOptionDisabled = (option: any) => {
  if (option.budgetExtrapolated || option.vaultExtrapolated) {
    return true;
  }

  return false;
};

const isOptionSelected = (
  selectedCostCenter: CostCenterListByUserItem | null,
) => (costCenter: CostCenterListByUserItem) => {
  if (selectedCostCenter && costCenter.token === selectedCostCenter.token) {
    return true;
  }

  return false;
};

const CostCenterSelectContainer = () => {
  const { costCenters, selectedCostCenter } = useReportApprovalFormContext();
  const {
    handleChangeCostCenter,
    shouldCostCenterSelectBeVisible,
  } = useReportApprovalFormActionsContext();

  if (shouldCostCenterSelectBeVisible()) {
    return (
      <Select
        placeholder="Centro de custos"
        onChange={handleChangeCostCenter}
        options={costCenters}
        value={selectedCostCenter}
        isOptionDisabled={isOptionDisabled}
        isOptionSelected={isOptionSelected(selectedCostCenter)}
      />
    );
  }

  return null;
};

export { CostCenterSelectContainer as CostCenterSelect };
