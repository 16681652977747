export enum TimeframeTypeTranslation {
  "YEAR" = "Anual",
  "SEMESTER" = "Semestral",
  "TRIMESTER" = "Trimestral",
  "MONTH" = "Mensal",
}

export enum BudgetActionTypeTranslation {
  "NOTIFY_FIRST" = "Ao violar 1ª vez",
  "NOTIFY_EACH" = "A cada violação",
  "NONE" = "Não notificar",
  "PREVENT" = "Prevenir",
}

export const MonthRespecitiveTranslation: { [key: number]: string } = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};

export const PERIOD_DEFAULT_FILTERS = [
  { label: "Todos Períodos", value: "" },
  {
    label: "Anual",
    value: "YEAR",
  },
  {
    label: "Semestral",
    value: "SEMESTER",
  },
  {
    label: "Trimestral",
    value: "TRIMESTER",
  },
  {
    label: "Mensal",
    value: "MONTH",
  },
];

export const PERIOD_DETAILD_SEMESTER_DEFAULT_FILTERS = [
  { label: "Todos", value: "" },
  {
    label: "1º Semestre",
    value: "1",
  },
  {
    label: "2º Semestre",
    value: "2",
  },
];

export const PERIOD_DETAILD_TRIMESTER_DEFAULT_FILTERS = [
  { label: "Todos", value: "" },
  {
    label: "1º Trimestre",
    value: "1",
  },
  {
    label: "2º Trimestre",
    value: "2",
  },
  {
    label: "3º Trimestre",
    value: "3",
  },
  {
    label: "4º Trimestre",
    value: "4",
  },
];

export const PERIOD_DETAILD_MONTH_DEFAULT_FILTERS = [
  { label: "Todos", value: "" },
  {
    label: "Janeiro",
    value: "1",
  },
  {
    label: "Fevereiro",
    value: "2",
  },
  {
    label: "Março",
    value: "3",
  },
  {
    label: "Abril",
    value: "4",
  },
  {
    label: "Maio",
    value: "5",
  },
  {
    label: "Junho",
    value: "6",
  },
  {
    label: "Julho",
    value: "7",
  },
  {
    label: "Agosto",
    value: "8",
  },
  {
    label: "Setembro",
    value: "9",
  },
  {
    label: "Outubro",
    value: "10",
  },
  {
    label: "Novembro",
    value: "11",
  },
  {
    label: "Dezembro",
    value: "12",
  },
];

export const YEAR_DEFAULT_FILTERS = [
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];

export const PERIOD_SELECT_VALUES = [
  {
    label: "Anual",
    value: "YEAR",
  },
  {
    label: "Semestral",
    value: "SEMESTER",
  },
  {
    label: "Trimestral",
    value: "TRIMESTER",
  },
  {
    label: "Mensal",
    value: "MONTH",
  },
];

export const YEAR_SELECT_VALUES = [
  {
    label: "2024",
    value: 2024,
  },
  {
    label: "2025",
    value: 2025,
  },
  {
    label: "2026",
    value: 2026,
  },
  {
    label: "2027",
    value: 2027,
  },
  {
    label: "2028",
    value: 2028,
  },
  {
    label: "2029",
    value: 2029,
  },
  {
    label: "2030",
    value: 2030,
  },
];

export const MONTH_SELECT_VALUES = [
  {
    label: "Janeiro",
    value: 1,
  },
  {
    label: "Fevereiro",
    value: 2,
  },
  {
    label: "Março",
    value: 3,
  },
  {
    label: "Abril",
    value: 4,
  },
  {
    label: "Maio",
    value: 5,
  },
  {
    label: "Junho",
    value: 6,
  },
  {
    label: "Julho",
    value: 7,
  },
  {
    label: "Agosto",
    value: 8,
  },
  {
    label: "Setembro",
    value: 9,
  },
  {
    label: "Outubro",
    value: 10,
  },
  {
    label: "Novembro",
    value: 11,
  },
  {
    label: "Dezembro",
    value: 12,
  },
];

export const SEMESTER_SELECT_VALUES = [
  {
    label: "1º Semestre",
    value: 1,
  },
  {
    label: "2º Semestre",
    value: 2,
  },
];

export const TRIMESTER_SELECT_VALUES = [
  {
    label: "1º Trimestre",
    value: 1,
  },
  {
    label: "2º Trimestre",
    value: 2,
  },
  {
    label: "3º Trimestre",
    value: 3,
  },
  {
    label: "4º Trimestre",
    value: 4,
  },
];
