import React from "react";
import { css } from "@emotion/core";
import { Desktop } from "@shared/layout/Responsive";
import { Expense } from "@models/expense.model";
import { MEDIA_QUERIES } from "@constants";

const styles = {
  dateLabel: css({
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "left",
    verticalAlign: "baseline",
    backgroundColor: "white",
    position: "relative",
    paddingBottom: "0.25rem",
    marginBottom: "0.25rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 20,
      lineHeight: "24px"
    }
  }),
  timelineSidebar: css({
    position: "absolute",
    backgroundImage: "linear-gradient(#D5DEE8 50%, transparent 50%)",
    backgroundSize: "2px 8px",
    backgroundRepeatX: "no-repeat",
    backgroundRepeatY: "repeat",
    top: "45px",
    bottom: "0px",
    width: "2px",
    left: "10px",
    "&::before": {
      top: "-1px"
    },
    "&::after": {
      bottom: "-1px"
    },
    "&:after, &:before": {
      content: "''",
      position: "absolute",
      left: "-2px",
      height: "6px",
      width: "6px",
      borderRadius: "50%",
      backgroundColor: "#C1C1C1"
    }
  })
};

export type Props = {
  expensesMap: Record<string, Expense[]>;
  children(expense: Expense): JSX.Element;
};

export const ExpensesListByDate: React.FC<Props> = ({
  expensesMap,
  children: renderExpenseFn
}) => {
  return (
    <div style={{ position: "relative" }}>
      <Desktop>
        <div css={styles.timelineSidebar} />
      </Desktop>
      {Object.entries(expensesMap).map(([date, expenses]) => (
        <div
          style={{
            marginBottom: "2rem"
          }}
          key={date}
        >
          <div css={styles.dateLabel}>{date}</div>
          {expenses.map(expense => renderExpenseFn(expense))}
        </div>
      ))}
    </div>
  );
};

export default ExpensesListByDate;
