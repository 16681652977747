import React, { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { UserDocumentCard } from "~/apps/corporate/components/user-document-card/user-document-card";
import { UserDocumentCardMoreActionsPopover } from "~/apps/corporate/components/user-document-card/user-document-card-more-actions-popover/user-document-card-more-actions-popover";
import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { DOCUMENT_TYPES_TRANSLATION } from "~/apps/shared/constants";
import { Document } from "~/apps/shared/models/documents.model";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { styles } from "./styles";

export const UserProfileDrawerDocumentsSection: React.FC = () => {
  const {
    addDocument,
    editDocument,
    openRemoveDocumentAlertDialog,
  } = useUserProfileDrawer();

  const { documents, isLoading: isLoadingUserDocuments } = useDocuments();

  const [
    moreOptionsPopoverAnchorElement,
    setMoreOptionsPopoverAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  );

  const addDocumentsLabel = useMemo(() => {
    if (!documents) {
      return null;
    }

    const remainingDocuments = Object.keys(DOCUMENT_TYPES_TRANSLATION).filter(
      (k) => !documents.find((userDocument) => userDocument.type === k),
    );

    if (remainingDocuments.length === 1) {
      const remaningDocument =
        DOCUMENT_TYPES_TRANSLATION[
          remainingDocuments[0] as keyof typeof DOCUMENT_TYPES_TRANSLATION
        ];

      return `Adicionar ${remaningDocument}`;
    }

    if (remainingDocuments.length === 0) {
      return null;
    }

    return "Adicionar documentos";
  }, [documents]);

  const handleCloseMoreOptionsPopover = useCallback(() => {
    setMoreOptionsPopoverAnchorElement(null);
  }, []);

  const handleOpenMoreOptionsPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>, document: Document) => {
      setMoreOptionsPopoverAnchorElement(event.currentTarget);
      setSelectedDocument(document);
    },
    [],
  );

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>Documentos</span>
          {addDocumentsLabel ? (
            <button
              css={styles.header.button}
              onClick={() => {
                addDocument();
              }}
            >
              {addDocumentsLabel}
            </button>
          ) : null}
        </div>
        <div css={styles.documents.root}>
          {documents && documents.length > 0 ? (
            documents.map((document) => (
              <UserDocumentCard
                document={document}
                key={document.documentToken}
                onClick={(event, document) => {
                  handleOpenMoreOptionsPopover(event, document);
                }}
              />
            ))
          ) : isLoadingUserDocuments ? (
            <Skeleton height="132px" width="352px" />
          ) : (
            <span css={styles.documents.empty}>
              Nenhum documento cadastrado.
            </span>
          )}
        </div>
      </div>
      <UserDocumentCardMoreActionsPopover
        anchorElement={moreOptionsPopoverAnchorElement}
        document={selectedDocument}
        onClose={handleCloseMoreOptionsPopover}
        onDelete={() => {
          if (!selectedDocument) {
            return;
          }

          openRemoveDocumentAlertDialog(selectedDocument);
        }}
        onEdit={() => {
          if (!selectedDocument) {
            return;
          }

          editDocument(selectedDocument);
        }}
      />
    </>
  );
};
