import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import busCompanyLogoPlaceholder from "~/apps/shared/assets/images/placeholders/bus-company-logo-placeholder.svg";

import { styles } from "./styles";

type Props = {
  arrival: {
    address: string;
    time: string;
  };
  busName: string;
  carrierLogo: string;
  carrierName: string;
  departure: {
    address: string;
    time: string;
  };
  duration: string;
  seat?: string;
};

export const BusStretchInfo: React.FC<Props> = ({
  arrival,
  busName,
  carrierLogo,
  carrierName,
  departure,
  duration,
  seat,
}) => {
  return (
    <div css={styles.root}>
      <BusCompanyImage
        alt={carrierName}
        css={styles.image}
        height={48}
        src={carrierLogo}
        title={carrierName}
        width={128}
      />
      <div css={styles.info.root}>
        <div css={styles.info.segments.root}>
          <BusSegmentInfo place={departure.address} time={departure.time} />
          <BusSegmentInfo place={arrival.address} time={arrival.time} />
        </div>
        <div css={styles.info.other.root}>
          <span css={styles.info.other.duration}>{duration}</span>
          <span css={styles.info.other.name}>{busName}</span>
          {seat ? (
            <span css={styles.info.other.seat}>Assento: {seat}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type BusCompanyImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

const BusCompanyImage: React.FC<BusCompanyImageProps> = ({ src, ...props }) => {
  const [hasError, setHasError] = useState(false);

  return (
    <img
      onError={() => {
        setHasError(true);
      }}
      src={hasError ? busCompanyLogoPlaceholder : src}
      {...props}
    />
  );
};

type BusSegmentInfoProps = {
  place: string;
  time: string;
};

const BusSegmentInfo: React.FC<BusSegmentInfoProps> = ({ place, time }) => {
  return (
    <div css={styles.info.segments.segment.root}>
      <span css={styles.info.segments.segment.time}>{time}</span>
      <span css={styles.info.segments.segment.place}>{place}</span>
    </div>
  );
};

export const BusStretchInfoSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <Skeleton height="48px" width="128px" />
      <div css={styles.info.root}>
        <div css={styles.info.segments.root}>
          <Skeleton height="16px" width="256px" />
          <Skeleton height="16px" width="256px" />
        </div>
        <div css={styles.info.other.root}>
          <Skeleton height="16px" width="56px" />
          <Skeleton height="14px" width="72px" />
        </div>
      </div>
    </div>
  );
};
