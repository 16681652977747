import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";

import { FormModeType } from "~/apps/shared/types";

import * as countryRestrictionService from "../country-restrictions.service";
import { CountryRestrictionFormData } from "../restriction-drawer/restriction-form/restriction-form.types";

const keys = {
  base: ["country-restrictions"] as const,
  list: () => [...keys.base, "list"] as const,
  details: (id: QueryKey) => [...keys.base, "details", id] as const,
  mutation: (id: QueryKey) => [...keys.base, "mutation", id] as const,
  delete: (id: QueryKey) => [...keys.base, "delete", id] as const,
};

export function useListCountryRestrictions() {
  const query = useQuery(
    keys.list(),
    countryRestrictionService.listCountryRestrictions,
  );

  return query;
}

async function getCountryRestriction(mode: FormModeType, token: string) {
  if (mode === "create") {
    return {};
  }

  return countryRestrictionService.getCountryRestriction(token);
}

export function useGetCountryRestriction({
  countryRestrictionToken,
  mode,
}: {
  countryRestrictionToken: string;
  mode: FormModeType;
}) {
  const query = useQuery(keys.details([mode, countryRestrictionToken]), () =>
    getCountryRestriction(mode, countryRestrictionToken),
  );

  return query;
}

async function mutateCountryRestriction(
  mode: FormModeType,
  data: CountryRestrictionFormData,
) {
  if (mode === "create") {
    return countryRestrictionService.createCountryRestriction(data);
  }

  return countryRestrictionService.updateCountryRestriction(data);
}

export function useCountryRestrictionMutation(
  mode: FormModeType,
  countryRestrictionToken?: string,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: keys.mutation([mode, countryRestrictionToken]),
    mutationFn: async (data: CountryRestrictionFormData) => {
      return await mutateCountryRestriction(mode, data);
    },
    onSuccess: () => {
      if (mode === "edit") {
        void queryClient.invalidateQueries(
          keys.details([mode, countryRestrictionToken]),
        );
      }

      return queryClient.invalidateQueries(keys.list());
    },
  });

  return mutation;
}

export function useDeleteCountryRestrictionMutation(
  countryRestrictionToken: string,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: keys.delete([countryRestrictionToken]),
    mutationFn: async () => {
      return await countryRestrictionService.deleteCountryRestriction(
        countryRestrictionToken!,
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list());
    },
  });

  return mutation;
}
