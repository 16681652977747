import { useMemo } from "react";

import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";

import { ItineraryInfoTagsPresenterFactory } from "./itinerary-info-tags.presenter";

export const useItineraryInfoTagsPresenter = () => {
  const { travelsTags } = useTags();

  return useMemo(
    () => ({
      itineraryInfoTagsPresenter: travelsTags
        ? ItineraryInfoTagsPresenterFactory.create(travelsTags)
        : null,
    }),
    [travelsTags],
  );
};
