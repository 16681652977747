import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    terms: {
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop: "16px",
      }),
      section: {
        email: css({
          color: theme.colors.pink[500],
          fontWeight: 500,
        }),
        list: css({
          display: "flex",
          flexDirection: "column",
          fontSize: "0.875rem",
          gap: "8px",
          lineHeight: "1.125",
          listStyleType: "decimal",
          marginLeft: "1.125rem",
        }),
        paragraph: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          lineHeight: "1.125",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
        strong: css({
          fontWeight: 600,
        }),
        title: css({
          color: theme.colors.gray[700],
          fontSize: "1rem",
          fontWeight: 600,
        }),
      },
      table: {
        td: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          padding: "8px",
        }),
        th: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          fontWeight: 600,
          padding: "8px",
          textAlign: "left",
        }),
      },
    },
  },
  root: css({
    ".MuiPaper-root": {
      overflowY: "auto",
    },
  }),
};
