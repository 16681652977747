import * as yup from "yup";

export const categoryFormSchema = yup.object().shape({
  expenseFamily: yup.object().required("Preencha o tipo de despesa"),
  categoryName: yup
    .string()
    .trim()
    .min(2, "O campo precisa de ao menos 2 caracteres")
    .required("Preencha o nome da categoria"),
});
