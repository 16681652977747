import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  selected?: boolean;
};

export const Seat: React.FC<Props> = ({
  disabled,
  label,
  onClick,
  selected,
}) => {
  const details = selected ? "#F9A307" : disabled ? "#86C6AC" : "#0E8D59";
  const primary = selected ? "#FFC82C" : disabled ? "#99DABC" : "#33B679";

  return (
    <button
      css={styles({ details, primary }).root}
      disabled={disabled}
      onClick={onClick}
    >
      <div css={styles({ details, primary }).arms.right} />
      <div css={styles({ details, primary }).arms.left} />
      {disabled ? <Icon size={14} use="x" /> : label}
    </button>
  );
};
