import React, { useContext } from "react";

import { AsyncDataContext, AsyncDataProvider } from "./async-data.context";
import { AsyncDataProps } from "./async-data.types";

export function AsyncData<T>(
  props: AsyncDataProps<T> & { children: React.ReactNode },
) {
  return <AsyncDataProvider {...props} />;
}

type AsyncDataLoadingProps = {
  children: React.ReactElement;
};

function Loading(props: AsyncDataLoadingProps) {
  const { isLoading } = useContext(AsyncDataContext);

  return isLoading ? props.children : null;
}

type AsyncDataErrorProps = {
  children: React.ReactElement | ((error: any) => JSX.Element);
};

function InnerError(props: AsyncDataErrorProps) {
  const { error, isError } = useContext(AsyncDataContext);

  return isError
    ? typeof props.children === "function"
      ? props.children(error)
      : props.children
    : null;
}

type AsyncDataSuccessProps = {
  children: React.ReactElement | ((data: any) => JSX.Element);
};

function getData(data: any): any {
  return data?.data || data;
}

function Success(props: AsyncDataSuccessProps) {
  const { isSuccess, data } = useContext(AsyncDataContext);

  return isSuccess && getData(data)
    ? typeof props.children === "function"
      ? props.children(getData(data))
      : props.children
    : null;
}

type AsyncDataEmptyProps = {
  children: React.ReactElement;
  isEmpty?: (data: any) => boolean;
};

function Empty(props: AsyncDataEmptyProps) {
  const { isEmpty = defaultIsEmpty, children } = props;

  const { isSuccess, data } = useContext(AsyncDataContext);

  return isSuccess && isEmpty(getData(data)) ? children : null;
}

function defaultIsEmpty(data: any) {
  if (Array.isArray(data) && !data.length) {
    return true;
  }

  if (typeof data === "object" && !Object.keys(data).length) {
    return true;
  }

  return !!data;
}

AsyncData.Empty = Empty;
AsyncData.Error = InnerError;
AsyncData.Loading = Loading;
AsyncData.Success = Success;

export default AsyncData;
