import { css } from "@styled-system/css";

export const styles = {
  root: css({
    ".MuiPaper-root": {
      background: "transparent",
      "@media (max-width: 640px)": {
        width: "100%",
      },
    },
  }),
};
