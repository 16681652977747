import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ItineraryInfo } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { useItinerary } from "./itinerary.context";
import * as itineraryService from "./itinerary.service";

interface Actions {
  fetchItineraryInfo: () => Promise<void>;
  updateTravelName: (travelName: string) => Promise<void>;
}

type State = {
  errorOnFetch: Error | null;
  info: ItineraryInfo | null;
  isLoading: boolean;
};

const initialState: State = {
  errorOnFetch: null,
  isLoading: false,
  info: null,
};

const ItineraryInfoContext = createContext<Actions & State>({
  ...initialState,
  fetchItineraryInfo: async () => {
    return;
  },
  updateTravelName: async () => {
    return;
  },
});

export const ItineraryInfoProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const { travelToken } = useItinerary();

  const [state, setState] = useState(initialState);

  const fetchItineraryInfo = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const itineraryInfoResponse = await itineraryService.getItineraryInfo(
      travelToken,
    );

    if (itineraryInfoResponse.isFailure()) {
      const error = itineraryInfoResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      info: itineraryInfoResponse.data,
    }));
  }, [showSnackMessage, travelToken]);

  const updateTravelName = useCallback(
    async (travelName: string) => {
      const { info } = state;

      if (!info) {
        return;
      }

      const { error } = await itineraryService.updateTravelName(
        travelToken,
        travelName,
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        info: {
          ...info,
          travelName,
        },
      }));
    },
    [state.info, showSnackMessage, travelToken],
  );

  return (
    <ItineraryInfoContext.Provider
      value={{
        ...state,
        fetchItineraryInfo,
        updateTravelName,
      }}
    >
      {children}
    </ItineraryInfoContext.Provider>
  );
};

export const useItineraryInfo = useContextFactory(
  "ItineraryInfoContext",
  ItineraryInfoContext,
);
