import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";

export const styles = {
  messageError: css({
    position: "absolute",
    width: "max-content",
    margin: "0",
    marginTop: "1.75rem",
    fontSize: "12px",
    color: defaultTheme.errorTextColor,
  }),
  approverStagesSpacing: css({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "0.25rem 0",
  }),
};
