import React, { createContext, useCallback, useEffect, useState } from "react";

import { Card } from "~/apps/corporate/models/card.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { CardOwnerType } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { CreateCorporateCardDto, EditCardDto } from "../dtos/cards.dto";
import { useApplication } from "./application.context";
import * as cardsService from "./cards.service";
import { useUser } from "./user.context";

interface Actions {
  createCard: (
    data: CreateCorporateCardDto,
    ownerType?: CardOwnerType,
  ) => Promise<boolean>;
  deleteCard: (cardToken: string) => Promise<boolean>;
  editCard: (data: EditCardDto) => Promise<boolean>;
  fetchUserCards: () => Promise<void>;
}

type State = {
  cards: Card[] | null;
  errorOnFetchDeleteCard: Error | null;
  isLoading?: boolean;
  isLoadingCreateCard?: boolean;
  isLoadingDeleteCard?: boolean;
  isLoadingEditCard?: boolean;
};

const initialState: State = {
  cards: null,
  errorOnFetchDeleteCard: null,
  isLoading: false,
  isLoadingCreateCard: false,
  isLoadingDeleteCard: false,
  isLoadingEditCard: false,
};

type ContextProps = Actions & State;

const CardsContext = createContext<ContextProps>({
  ...initialState,
  createCard: async () => {
    return false;
  },
  deleteCard: async () => {
    return false;
  },
  editCard: async () => {
    return false;
  },
  fetchUserCards: async () => {
    return;
  },
});

export const CardsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const createCard = useCallback(
    async (
      data: CreateCorporateCardDto,
      ownerType: CardOwnerType = CardOwnerType.USER,
    ) => {
      setState((prev) => ({
        ...prev,
        isLoadingCreateCard: true,
      }));

      const createCardResponse = await cardsService.createCorporateCard(
        data,
        ownerType,
      );

      if (createCardResponse.error) {
        const error = createCardResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingCreateCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!createCardResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingCreateCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        cards: prev.cards
          ? [...prev.cards, createCardResponse.data!]
          : [createCardResponse.data!],
        isLoadingCreateCard: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const deleteCard = useCallback(
    async (cardToken: string) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchDeleteCard: null,
        isLoadingDeleteCard: true,
      }));

      const deleteCardResponse = await cardsService.deleteCard(cardToken);

      if (deleteCardResponse.error) {
        const error = deleteCardResponse.error;

        setState((prev) => ({
          ...prev,
          errorOnFetchDeleteCard: error,
          isLoadingDeleteCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!deleteCardResponse.data || !deleteCardResponse.data.success) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          errorOnFetchDeleteCard: error,
          isLoadingDeleteCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        cards: prev.cards
          ? prev.cards.filter((card) => card.token !== cardToken)
          : [],
        isLoadingDeleteCard: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const editCard = useCallback(
    async (data: EditCardDto) => {
      setState((prev) => ({
        ...prev,
        isLoadingEditCard: true,
      }));

      const editCardResponse = await cardsService.editCard(data);

      if (editCardResponse.error) {
        const error = editCardResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingEditCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!editCardResponse.data || !editCardResponse.data.success) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingEditCard: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        cards: prev.cards
          ? prev.cards.map((card) =>
              card.token === data.token
                ? {
                    ...card,
                    ...(data.allowApprovers && {
                      allowApprovers: data.allowApprovers,
                    }),
                    ...(data.description && {
                      description: data.description,
                    }),
                    serviceTypes: {
                      bus: data.enableToBus,
                      car: data.enableToCar,
                      flight: data.enableToFlight,
                      hotel: data.enableToHotel,
                      other: data.enableToOther,
                      ride: data.enableToRide,
                    },
                  }
                : card,
            )
          : [],
        isLoadingEditCard: false,
      }));

      return true;
    },
    [showSnackMessage],
  );

  const fetchUserCards = useCallback(async () => {
    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const cards = await cardsService.getUserCards(user.getUserToken());

    if (cards.error) {
      const error = cards.error;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!cards.data) {
      const error = ERROR.UNEXPECTED;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      cards: cards.data!,
      isLoading: false,
    }));
  }, [showSnackMessage, user]);

  useEffect(() => {
    void fetchUserCards();
  }, [fetchUserCards]);

  return (
    <CardsContext.Provider
      value={{
        ...state,
        createCard,
        deleteCard,
        editCard,
        fetchUserCards,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

export const useCards = useContextFactory("CardsContext", CardsContext);
