import React from "react";

import { getCarSmartripsCategory } from "~/apps/corporate/helpers/car.helper";
import { CarPolicy } from "~/apps/corporate/models/policy.model";
import {
  CAR_POLICY_CATEGORY_TRANSLATION,
  FLIGHT_CABIN_CLASS_TRANSLATOR,
} from "~/apps/shared/constants";
import { CarCategory } from "~/apps/shared/constants/enums";

import { toCurrency } from "../../utils/to-currency";
import { toPercentage } from "../../utils/to-percentage";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../dialog/dialog";
import { styles } from "./styles";

const CAR_CATEGORY_WEIGHT = {
  [CarCategory.BASIC]: 0,
  [CarCategory.INTERMEDIATE]: 1,
  [CarCategory.ADVANCED]: 2,
  [CarCategory.SPECIAL]: 3,
} as const;

type CarOutOfPolicyDialogProps = {
  offer: {
    carDetails: {
      category?: CarCategory;
      sippCode?: string;
    };
  } | null;
  onClose(): void;
  open: boolean;
  policy: CarPolicy;
};

export const CarOutOfPolicyDialog: React.FC<CarOutOfPolicyDialogProps> = ({
  offer,
  onClose,
  open,
  policy,
}) => {
  const CategoryWarning: React.FC = () => {
    if (!offer) {
      return null;
    }

    const { category, sippCode } = offer.carDetails;
    const { maxCategory } = policy;

    const carCategory = category
      ? category
      : sippCode
      ? getCarSmartripsCategory(sippCode)
      : null;

    if (!carCategory || !maxCategory) {
      return null;
    }

    const showCategoryWarning =
      CAR_CATEGORY_WEIGHT[carCategory] > CAR_CATEGORY_WEIGHT[maxCategory];

    if (!showCategoryWarning) {
      return null;
    }

    const translatedCarCategory = CAR_POLICY_CATEGORY_TRANSLATION[
      carCategory
    ] as string | undefined;
    const translatedMaxCategory = CAR_POLICY_CATEGORY_TRANSLATION[
      maxCategory
    ] as string | undefined;

    return (
      <OutOfPolicyWarning
        message={
          <span>
            A categoria do veículo selecionado é{" "}
            <strong>
              {translatedCarCategory ? translatedCarCategory : carCategory}
            </strong>
            , porém sua categoria limite é{" "}
            <strong>
              {translatedMaxCategory ? translatedMaxCategory : maxCategory}
            </strong>
          </span>
        }
        title="A categoria do veiculo está acima da máxima de sua polĩtica"
      />
    );
  };

  return (
    <Dialog css={styles.root} onClose={onClose} open={open}>
      <DialogHeader
        icon="exclamation-triangle"
        onClose={onClose}
        variant="pink"
      />
      <DialogContent>
        <DialogTitle variant="pink">Fora da política.</DialogTitle>
        <span css={styles.content.top.message}>
          Este veículo está fora da política da empresa.
        </span>
        <div css={styles.content.center.root}>
          <CategoryWarning />
        </div>
      </DialogContent>
    </Dialog>
  );
};

type FlightOutOfPolicyDialogProps = {
  offer: {
    advance: number;
    class: string;
    price: number;
    priceExcess: number | null;
  };
  onClose: () => void;
  open: boolean;
  policy: {
    advance?: number;
    allowedClasses: string[];
    maxValueVisible?: number;
    optimalPrice?: {
      enabled: boolean;
      price: number;
    };
    price: number | null;
    priceExcess?: number | null;
  };
};

export const FlightOutOfPolicyDialog: React.FC<FlightOutOfPolicyDialogProps> = ({
  offer,
  onClose,
  open,
  policy,
}) => {
  const AdvanceWarning: React.FC = () => {
    const { advance } = policy;

    if (!advance) {
      return null;
    }

    const showAdvanceWarning = offer.advance < advance;

    if (!showAdvanceWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`Vôos devem ser reservados com ${advance} dia(s) de antecedência de acordo com a política.`}
        title={`A reserva está com ${
          advance - offer.advance
        } dia(s) de atraso.`}
      />
    );
  };

  const ClassWarning = () => {
    const { allowedClasses } = policy;
    const { class: offerClass } = offer;

    const showClassWarning = !allowedClasses.includes(offerClass);

    if (!showClassWarning) {
      return null;
    }

    const translatedAllowedClasses = allowedClasses
      .map(
        (allowedClass) =>
          FLIGHT_CABIN_CLASS_TRANSLATOR[
            allowedClass as keyof typeof FLIGHT_CABIN_CLASS_TRANSLATOR
          ],
      )
      .filter((allowedClass: string | undefined) => !!allowedClass);

    const translatedOfferClass = FLIGHT_CABIN_CLASS_TRANSLATOR[
      offerClass as keyof typeof FLIGHT_CABIN_CLASS_TRANSLATOR
    ] as string | undefined;

    return (
      <OutOfPolicyWarning
        message={`Classe(s) permitida(s) na sua política: ${
          translatedAllowedClasses.length > 0
            ? translatedAllowedClasses.join(", ")
            : "N/A"
        }.`}
        title={`Foi selecionado um voo da classe ${
          translatedOfferClass ? translatedOfferClass : offerClass
        }.`}
      />
    );
  };

  const OptimalPriceWarning = () => {
    const policyOptimalPrice = policy.optimalPrice?.price
      ? policy.optimalPrice.price
      : 0;

    const showOptimalPriceWarning =
      policy.optimalPrice?.enabled &&
      offer.price &&
      offer.price > policy.optimalPrice.price;

    if (!showOptimalPriceWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`O cálculo foi feito em cima de critérios estatistícos habilitados por sua empresa`}
        title={`
          Esta oferta está ${toCurrency(
            offer.price - policyOptimalPrice,
          )} acima do preço de ${toCurrency(
          policyOptimalPrice,
        )} permitido para o resultado da busca.
          `}
      />
    );
  };

  const PriceExcessWarning = () => {
    const { priceExcess: offerPriceExcess } = offer;
    const { priceExcess: policyPriceExcess } = policy;

    if (!offerPriceExcess || !policyPriceExcess) {
      return null;
    }

    const showPriceExcessWarning = offerPriceExcess > policyPriceExcess;

    if (!showPriceExcessWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`O preço máximo permitido só poderia exceder o preço mais barato em ${toPercentage(
          policyPriceExcess - 1,
          1,
        )}, totalizando: ${toCurrency(
          (offer.price * policyPriceExcess) / offerPriceExcess,
        )} de acordo com a política.`}
        title={`A oferta selecionada está ${toPercentage(
          offerPriceExcess - 1,
          1,
        )} acima do preço mais barato encontrado.`}
      />
    );
  };

  const PriceWarning = () => {
    const hasPriceLimit =
      typeof policy.price !== "undefined" &&
      policy.price !== null &&
      policy.price !== 0;

    const showPriceWarning = hasPriceLimit && offer.price > policy.price!;

    if (!showPriceWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`O preço máximo permitido por voo é de ${toCurrency(
          policy.price!,
        )} de acordo com a política.`}
        title={`A oferta selecionada está ${toCurrency(
          offer.price - policy.price!,
        )} acima do valor do teto.`}
      />
    );
  };

  return (
    <Dialog css={styles.root} onClose={onClose} open={open}>
      <DialogHeader
        icon="exclamation-triangle"
        onClose={onClose}
        variant="pink"
      />
      <DialogContent>
        <DialogTitle variant="pink">Fora da política.</DialogTitle>
        <span css={styles.content.top.message}>
          Este voo está fora da política da empresa.
        </span>
        <div css={styles.content.center.root}>
          <AdvanceWarning />
          <ClassWarning />
          <PriceWarning />
          <PriceExcessWarning />
          <OptimalPriceWarning />
        </div>
      </DialogContent>
    </Dialog>
  );
};

type HotelOutOfPolicyDialogProps = {
  offer: {
    advance: number;
    price: number;
    stars: number;
    totalGuests: number;
  };
  onClose: () => void;
  open: boolean;
  policy: {
    advance?: number;
    price?: number | null;
    stars?: number;
  };
};

export const HotelOutOfPolicyDialog: React.FC<HotelOutOfPolicyDialogProps> = ({
  offer,
  onClose,
  open,
  policy,
}) => {
  const hasPriceLimit =
    typeof policy.price !== "undefined" && policy.price !== null;
  const totalGuests = offer.totalGuests || 1;

  const isPriceOutOfPolicy =
    hasPriceLimit && offer.price / totalGuests > policy.price!;

  const AdvanceWarning = () => {
    const { advance } = policy;

    if (!advance) {
      return null;
    }

    const showAdvanceWarning = offer.advance < advance;

    if (!showAdvanceWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`Hotéis devem ser reservados com ${advance} dia(s) de antecedência de acordo com a política.`}
        title={`A reserva está com ${
          advance - offer.advance
        } dia(s) de atraso.`}
      />
    );
  };

  const PriceWarning = () => {
    const showPriceWarning = isPriceOutOfPolicy;

    if (!showPriceWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`O preço máximo por hóspede permitido para um quarto por noite é de ${toCurrency(
          policy.price,
        )} de acordo com a política.`}
        title={`A oferta selecionada está ${toCurrency(
          offer.price - (policy.price ? policy.price : 1) * totalGuests,
        )} acima do valor do teto.`}
      />
    );
  };

  const StarWarning = () => {
    const isStarOutOfPolicy = policy.stars && offer.stars > policy.stars;

    const showStarWarning =
      isStarOutOfPolicy && (!hasPriceLimit || isPriceOutOfPolicy);

    if (!showStarWarning) {
      return null;
    }

    return (
      <OutOfPolicyWarning
        message={`O número máximo de estrelas permitido pela política é de ${policy.stars} estrela(s).`}
        title={`Foi selecionado um hotel de ${offer.stars} estrela(s).`}
      />
    );
  };

  return (
    <Dialog css={styles.root} onClose={onClose} open={open}>
      <DialogHeader
        icon="exclamation-triangle"
        onClose={onClose}
        variant="pink"
      />
      <DialogContent>
        <DialogTitle variant="pink">Fora da política.</DialogTitle>
        <span css={styles.content.top.message}>
          Este hotel está fora da política da empresa.
        </span>
        <div css={styles.content.center.root}>
          <AdvanceWarning />
          <StarWarning />
          <PriceWarning />
        </div>
      </DialogContent>
    </Dialog>
  );
};

type OutOfPolicyWarningProps = {
  message: string | JSX.Element;
  title: string;
};

const OutOfPolicyWarning: React.FC<OutOfPolicyWarningProps> = ({
  message,
  title,
}) => {
  return (
    <div css={styles.warning.root}>
      <div css={styles.warning.title}>{title}</div>
      <div css={styles.warning.message}>{message}</div>
    </div>
  );
};
