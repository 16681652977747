import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import {
  DirectionsBus as BusIcon,
  DirectionsCar as CarIcon,
  Hotel as HotelIcon,
  LocalTaxi as TaxiIcon,
  Settings as SettingsIcon,
  FlightTakeoff as FlightIcon
} from "@material-ui/icons";
import { css } from "@styled-system/css";

import { CardServiceType } from "~/constants/enums";

import styled from "~/components/shared/styled";

import { defaultTheme } from "../../../../../../../../assets/styles/theme";

const styles = {
  tooltip: css({
    [".MuiTooltip-arrow"]: {
      color: `${defaultTheme.primaryColor} !important`,
      paddingRight: "18px"
    },
    [".MuiTooltip-tooltip"]: {
      width: "3.4rem",
      marginRight: "8px",
      fontSize: "0.75rem",
      backgroundColor: defaultTheme.primaryColor,
      textAlign: "center",
      padding: "0.3rem",
      lineHeight: "1.25rem",
      cursor: "auto"
      // border: `1px solid ${defaultTheme.primaryColor}`
    },
    [".MuiTooltip-tooltipPlacementTop"]: {
      marginBottom: "0.5rem"
    }
  })
};

type Props = {
  serviceTypes: {
    [CardServiceType.FLIGHT]: boolean;
    [CardServiceType.BUS]: boolean;
    [CardServiceType.OTHER]: boolean;
    [CardServiceType.HOTEL]: boolean;
    [CardServiceType.RIDE]: boolean;
    [CardServiceType.CAR]: boolean;
  };
};

const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className }}
    arrow
    placement="top"
    title={<span style={{ fontWeight: "bold" }}>{props.text}</span>}
  >
    {props.children}
  </Tooltip>
))(styles.tooltip);

export const ServiceIconsList = ({ serviceTypes }: Props): JSX.Element => {
  return (
    <div
      style={{
        justifyContent: "space-between",
        marginTop: "0.3rem",
        paddingTop: "0.5rem",
        display: "flex"
      }}
    >
      <StyledTooltip text={"Aéreo"}>
        <div style={{ paddingRight: "0.5rem" }}>
          <FlightIcon
            fontSize="small"
            style={{
              color: serviceTypes.flight
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
      <StyledTooltip text={"Ônibus"}>
        <div style={{ paddingRight: "0.5rem" }}>
          <BusIcon
            fontSize="small"
            style={{
              color: serviceTypes.bus
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
      <StyledTooltip text={"Carro"}>
        <div style={{ paddingRight: "0.5rem" }}>
          <CarIcon
            fontSize="small"
            style={{
              color: serviceTypes.car
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
      <StyledTooltip text={"Táxi"}>
        <div style={{ paddingRight: "0.5rem" }}>
          <TaxiIcon
            fontSize="small"
            style={{
              color: serviceTypes.ride
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
      <StyledTooltip text={"Hotel"}>
        <div style={{ paddingRight: "0.5rem" }}>
          <HotelIcon
            fontSize="small"
            style={{
              color: serviceTypes.hotel
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
      <StyledTooltip text={"Outros"}>
        <div>
          <SettingsIcon
            fontSize="small"
            style={{
              color: serviceTypes.other
                ? defaultTheme.primaryColor
                : defaultTheme.cardBorderColor
            }}
          />
        </div>
      </StyledTooltip>
    </div>
  );
};
