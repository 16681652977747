import * as cardsApi from "~/apps/corporate/apis/cards.api";
import { Card } from "~/apps/corporate/models/card.model";
import * as cardsParser from "~/apps/corporate/parsers/cards.parser";
import { CardOwnerType } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error, SuccessResponse } from "~/apps/shared/types";

import {
  CreateCorporateCardDto,
  EditCardDto,
  EditCardRequestBody,
} from "../dtos/cards.dto";

export const createCorporateCard = async (
  data: CreateCorporateCardDto,
  ownerType: CardOwnerType,
) => {
  const result: { data?: Card; error?: Error } = {};

  try {
    const createCorporateCardRequestBody = await cardsParser.parseCreateCorporateCardRequestBody(
      data,
      ownerType,
    );

    if (!createCorporateCardRequestBody) {
      result.error = ERROR.UNEXPECTED;

      return result;
    }

    result.data =
      ownerType === CardOwnerType.CLIENT
        ? await cardsApi.createCorporatePublicCard(
            createCorporateCardRequestBody,
          )
        : await cardsApi.createCorporateIndividualCard(
            createCorporateCardRequestBody,
          );
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const deleteCard = async (cardToken: string) => {
  const result: {
    data?: SuccessResponse;
    error?: Error;
  } = {};

  try {
    result.data = await cardsApi.deleteCard(cardToken);
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const editCard = async (data: EditCardDto) => {
  const result: {
    data?: SuccessResponse;
    error?: Error;
  } = {};

  try {
    const editCardRequestBody: EditCardRequestBody = {
      allow_approvers: data.allowApprovers,
      description: data.description,
      service_types: {
        bus: data.enableToBus,
        car: data.enableToCar,
        flight: data.enableToFlight,
        hotel: data.enableToHotel,
        other: data.enableToOther,
        ride: data.enableToRide,
      },
    };

    result.data = await cardsApi.editCard(data.token, editCardRequestBody);
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};

export const getUserCards = async (userToken: string) => {
  const result: { data?: Card[]; error?: Error } = {};

  try {
    result.data = await cardsApi.getUserCards(userToken);
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};
