import * as yup from "yup";
import * as utils from "@helpers";
import * as documentHelpers from "@helpers/document.helper";

const validatePhoneSize = (phone: string) => {
  const withouSpecialChars = utils.removeSpecialCharacters(phone);
  const formattedValue = utils.removeBlankSpaces(withouSpecialChars);
  const size = formattedValue.length;

  return size === 10 || size === 11;
};

const formSchema = yup.object().shape({
  name: yup.string().required("Preencha o nome fantasia"),
  legalName: yup.string().required("Preencha a razão social"),
  phone: yup
    .string()
    .required("Preencha o telefone")
    .test("is valid size", "Número inválido", validatePhoneSize),
  cnpj: yup
    .string()
    .required("Preencha o CNPJ")
    .test("is valid", "CNPJ inválido", documentHelpers.validateCnpj),
  zipCode: yup.string().required("Preencha o CEP"),
  street: yup.string().required("Preencha o nome da rua"),
  complement: yup.string().nullable(true),
  number: yup.string().required("Preencha o número"),
  neighborhood: yup.string().required("Preencha o bairro"),
  city: yup.string().required("Preencha a cidade"),
  state: yup.string().required("Preecha o estado"),
  email: yup
    .string()
    .email("Formato inválido de email")
    .required("Preencha o email")
});

export { formSchema };
