import React from "react";
import ReactWindowedSelect, {
  components as reactWindowedSelectComponents,
  GroupBase,
  SelectComponentsConfig,
} from "react-windowed-select";

import { Option } from "../../types";
import { useSetDefaultValueIfOnlyOneOption } from "./select.hooks";
import { styles } from "./styles";

const defaultComponents: SelectComponentsConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  ...reactWindowedSelectComponents,
  // eslint-disable-next-line react/display-name
  Menu: ({ ...props }) => {
    const { children, getStyles, innerProps, innerRef } = props;

    return (
      <div
        css={getStyles("menu", props) as any}
        id="virtualized-select"
        {...innerProps}
        ref={innerRef}
      >
        {children}
      </div>
    );
  },
  // eslint-disable-next-line react/display-name
  Option: ({ ...props }) => {
    const { children, getStyles } = props;

    return (
      <reactWindowedSelectComponents.Option
        css={getStyles("option", props) as any}
        {...props}
      >
        <div>{children}</div>
      </reactWindowedSelectComponents.Option>
    );
  },
};

export const Select: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof ReactWindowedSelect>,
    "isSearchable" | "onChange" | "value" | "windowThreshold"
  > & {
    error?: boolean;
    fill?: "filled" | "outlined";
    isDisabled?: boolean;
    isSearchable?: React.ComponentPropsWithoutRef<
      typeof ReactWindowedSelect
    >["isSearchable"];
    onChange?: (value: Option<string>) => void;
    useSetDefaultValueIfOnlyOneOption?: boolean;
    value?:
      | {
          label: React.ReactNode;
          value: string;
        }
      | string;
    variant?: "blue" | "default" | "green" | "pink";
    windowThreshold?: React.ComponentPropsWithoutRef<
      typeof ReactWindowedSelect
    >["windowThreshold"];
  }
> = ({
  components,
  error,
  fill = "outlined",
  isSearchable = false,
  menuPlacement = "auto",
  onChange,
  options,
  placeholder = "Selecione...",
  variant = "default",
  windowThreshold = 1024,
  ...props
}) => {
  useSetDefaultValueIfOnlyOneOption({
    enabled: props.useSetDefaultValueIfOnlyOneOption,
    onChange,
    options,
  });

  return (
    <ReactWindowedSelect
      components={{
        ...defaultComponents,
        ...components,
      }}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      noOptionsMessage={({ inputValue: input }) =>
        `Nenhum resultado${input ? " para " + input : ""}...`
      }
      onChange={onChange as any}
      options={options}
      placeholder={placeholder}
      styles={styles({ error, fill, variant })}
      windowThreshold={windowThreshold}
      {...props}
    />
  );
};
