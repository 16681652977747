import React, { createContext, useContext, useState } from "react";

import classNames from "classnames";

import { styles } from "./styles";

type AccordionContextProps = {
  expanded: string[];
  setExpanded: (expanded: string[]) => void;
};

const AccordionContext = createContext<AccordionContextProps>(
  {} as AccordionContextProps
);

export const AccordionProvider = AccordionContext.Provider;

export const useAccordionContext = () => {
  return useContext(AccordionContext);
};

export const Accordion = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ children, className, ...props }, ref) => {
  const [expanded, setExpanded] = useState<AccordionContextProps["expanded"]>(
    []
  );

  return (
    <AccordionProvider
      value={{
        expanded,
        setExpanded
      }}
    >
      <ul className={classNames(styles.root, className)} ref={ref} {...props}>
        {children}
      </ul>
    </AccordionProvider>
  );
});

Accordion.displayName = "Accordion";

export const AccordionHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  return (
    <div className={classNames(styles.header, className)} ref={ref} {...props}>
      {children}
    </div>
  );
});

AccordionHeader.displayName = "AccordionHeader";

type AccordionItemContextProps = {
  id: string;
};

const AccordionItemContext = createContext<AccordionItemContextProps>(
  {} as AccordionItemContextProps
);

export const AccordionItemProvider = AccordionItemContext.Provider;

export const useAccordionItemContext = () => {
  return useContext(AccordionItemContext);
};

export const AccordionItem = React.forwardRef<
  HTMLLIElement,
  Omit<React.HTMLAttributes<HTMLLIElement>, "children" | "id"> & {
    children:
      | ((props: { expanded: boolean }) => React.ReactNode)
      | React.ReactNode;
    id: Pick<AccordionItemContextProps, "id">["id"];
  }
>(({ children, className, id, ...props }, ref) => {
  const { expanded } = useAccordionContext();

  return (
    <AccordionItemProvider
      value={{
        id
      }}
    >
      <li className={classNames(styles.item, className)} ref={ref} {...props}>
        {typeof children === "function"
          ? children({
              expanded: expanded.includes(id)
            })
          : children}
      </li>
    </AccordionItemProvider>
  );
});

AccordionItem.displayName = "AccordionItem";

export const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...props }, ref) => {
  const { expanded, setExpanded } = useAccordionContext();
  const { id } = useAccordionItemContext();

  return (
    <button
      className={classNames(styles.trigger, className)}
      onClick={() => {
        setExpanded(
          expanded.includes(id)
            ? expanded.filter(item => item !== id)
            : [...expanded, id]
        );
      }}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});

AccordionTrigger.displayName = "AccordionTrigger";

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  const { expanded } = useAccordionContext();
  const { id } = useAccordionItemContext();

  return expanded.includes(id) ? (
    <div className={classNames(styles.content, className)} ref={ref} {...props}>
      {children}
    </div>
  ) : null;
});

AccordionContent.displayName = "AccordionContent";
