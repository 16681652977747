import React from "react";

import { Link } from "@reach/router";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useItinerary } from "../../../itinerary/itinerary.context";
import { styles } from "./styles";

export const PaymentSucessful: React.FC = () => {
  const { travelApprovalHistoryModel } = useItineraryContainer();

  const { travelToken } = useItinerary();

  if (!travelApprovalHistoryModel) {
    return null;
  }

  const isPendingApproval = travelApprovalHistoryModel.isPendingApproval();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title}>
          {isPendingApproval
            ? "Viagem aprovada (processando pagamento):"
            : "Processando pagamento:"}
        </span>
      </div>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <div css={styles.section.approved.root}>
          <Icon css={styles.section.approved.icon} size={16} use="at-symbol" />
          <span css={styles.section.approved.message}>
            Um e-mail de confirmação de reserva foi enviado ao viajante.
          </span>
        </div>
        {isPendingApproval ? (
          <Link css={styles.section.approved.link} to="/approvals">
            Voltar para minhas aprovações
          </Link>
        ) : (
          <Link
            css={styles.section.approved.link}
            to={`/travels/${travelToken}/itinerary/travel-plan`}
          >
            Voltar para plano de viagem
          </Link>
        )}
      </div>
    </div>
  );
};
