import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
    width: "171px",
  }),
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "24px",
    justifyContent: "space-between",
    width: "100%",
  }),
  top: {
    description: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
};
