import React from "react";
import Skeleton from "react-loading-skeleton";

import { Bound } from "~/apps/corporate/models/flight.model";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { convertHoursAndMinutes } from "~/apps/shared/utils/convert-hours-and-minutes";
import { formatFlightDuration } from "~/apps/shared/utils/format-flight-duration";

import { styles } from "./styles";

type Props = {
  bound: Bound;
  checked: boolean;
  handleSingleFlightFilter: (
    flightType: "outbound" | "inbound",
    flightNumber: string,
  ) => void;
  isOneWay?: boolean;
};

export const FlightCardRouteDetails: React.FC<Props> = ({
  bound,
  checked,
  handleSingleFlightFilter,
  isOneWay,
}) => {
  const { arrivalHour, departureHour } = bound;

  const destinationAirport = bound.destination.code;
  const estimatedTravelTime = bound.durationHours;
  const hasCheckedBags = bound.baggage;
  const originAirport = bound.origin.code;
  const providerName = bound.carrier ? bound.carrier.name : "";
  const providerPic = bound.carrier ? bound.carrier.imageUrl : "";
  const stops = bound.stops && bound.stops.length > 0 ? bound.stops.length : 0;

  const carrierCode = bound.carrier?.code;
  const operatorCode = bound.segments[0].carrier?.code;
  const operatorName = bound.segments[0].carrier?.name;

  return (
    <div css={styles.root}>
      {!isOneWay ? (
        <label
          css={styles.checkbox}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Checkbox
            checked={checked}
            onChange={() => {
              handleSingleFlightFilter(
                bound.type as "inbound" | "outbound",
                bound.segments[0].flightNumber,
              );
            }}
            variant="pink"
          />
        </label>
      ) : null}
      <div css={styles.carrier.root}>
        <img
          alt={providerName}
          css={styles.carrier.image}
          height={32}
          src={providerPic}
          title={providerName}
        />
        {bound.carrier ? (
          <p css={styles.carrier.name}>{bound.carrier.name}</p>
        ) : null}
      </div>
      <div css={styles.itinerary.root}>
        <div css={styles.itinerary.item.root}>
          <span css={styles.itinerary.item.top}>{departureHour}</span>
          <span css={styles.itinerary.item.bottom}>{originAirport}</span>
        </div>
        <div css={styles.itinerary.stops.root}>
          <div css={styles.itinerary.stops.dot} />
          {Array(stops)
            .fill(0)
            .map((_, index) => {
              if (!bound.stops) {
                return null;
              }

              const airportCode = bound.stops[index].code;

              return (
                <FlightCardRouteDetailsStopsTooltip
                  key={index}
                  position="top"
                  stops={bound.stops}
                >
                  <div css={styles.itinerary.stops.stop.root}>
                    <div css={styles.itinerary.stops.dot} />
                    <span css={styles.itinerary.stops.stop.code}>
                      {airportCode}
                    </span>
                  </div>
                </FlightCardRouteDetailsStopsTooltip>
              );
            })}
          <div css={styles.itinerary.stops.dot} />
        </div>
        <div css={styles.itinerary.item.root}>
          <span css={styles.itinerary.item.top}>{arrivalHour}</span>
          <span css={styles.itinerary.item.bottom}>{destinationAirport}</span>
          {bound.daysDiff > 0 ? (
            <span css={styles.itinerary.item.diff}>+{bound.daysDiff}</span>
          ) : null}
        </div>
      </div>
      <div css={styles.info.root}>
        <span css={styles.info.duration}>
          {formatFlightDuration(estimatedTravelTime)}
        </span>
        {bound.stops && stops ? (
          <FlightCardRouteDetailsStopsTooltip
            position="top"
            stops={bound.stops}
          >
            <span css={styles.info.stops({ direct: false })}>
              {stops > 1 ? `${stops} conexões` : `${stops} conexão`}
            </span>
          </FlightCardRouteDetailsStopsTooltip>
        ) : (
          <span css={styles.info.stops({ direct: true })}>Direto</span>
        )}
      </div>
      {hasCheckedBags ? (
        <div css={styles.baggage.root}>
          <Icon size={16} use="baggage" />
          <p css={styles.baggage.text}>
            {bound.baggagePieces > 1 ? bound.baggagePieces : 1}{" "}
            {bound.baggagePieces > 1 ? ` bagagens` : ` bagagem`}
          </p>
        </div>
      ) : null}
      {carrierCode !== operatorCode ? (
        <div css={styles.operator.root}>
          <span
            css={styles.operator.text}
          >{`Operado por ${operatorName}`}</span>
          {bound.segments[0].carrier ? (
            <img height={16} src={bound.segments[0].carrier.imageUrl} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const FlightCardRouteDetailsSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <Skeleton height="18px" width="18px" />
      <div css={styles.carrier.root}>
        <Skeleton height="32px" width="32px" />
        <div css={styles.carrier.name}>
          <Skeleton height="12px" width="24px" />
        </div>
      </div>
      <div css={styles.itinerary.root}>
        <div css={styles.itinerary.item.root}>
          <div css={styles.itinerary.item.top}>
            <Skeleton height="16px" width="54px" />
          </div>
          <div css={styles.itinerary.item.bottom}>
            <Skeleton height="16px" width="32px" />
          </div>
        </div>
        <div css={styles.itinerary.stops.root}>
          <Skeleton height="1px" width="64px" />
        </div>
        <div css={styles.itinerary.item.root}>
          <div css={styles.itinerary.item.top}>
            <Skeleton height="16px" width="54px" />
          </div>
          <div css={styles.itinerary.item.bottom}>
            <Skeleton height="16px" width="32px" />
          </div>
          <div css={styles.itinerary.item.diff}>
            <Skeleton height="12px" width="12px" />
          </div>
        </div>
      </div>
      <div css={styles.info.root}>
        <span css={styles.info.duration}>
          <Skeleton height="14px" width="56px" />
        </span>
        <Skeleton height="12px" width="56px" />
      </div>
      <Skeleton height="16px" width="96px" />
    </div>
  );
};

type FlightCardRouteDetailsStopsTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  "content"
> & {
  stops: Bound["stops"];
};

const FlightCardRouteDetailsStopsTooltip: React.FC<FlightCardRouteDetailsStopsTooltipProps> = ({
  children,
  stops,
  ...props
}) => {
  if (!stops) {
    return null;
  }

  return (
    <Tooltip
      content={
        <>
          {stops.map((stop, index) => {
            const [airportCode, city] = stop.name.split(" - ");

            const formattedName = city
              ? `${city} (${airportCode})`
              : airportCode;

            return (
              <span key={index}>
                {convertHoursAndMinutes(stop.durationHours)}
                <br />
                em {formattedName}
                <br />
              </span>
            );
          })}
        </>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
