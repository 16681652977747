import React from "react";

import * as userApi from "~/apps/corporate/apis/user.api";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { ERROR } from "~/apps/shared/constants/errors";

import { styles } from "./styles";

type Props = Omit<React.ComponentProps<typeof AsyncAutocomplete>, "fetchItems">;

export const UserAutocomplete: React.FC<Props> = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      css={styles.autocomplete}
      fetchItems={async (search) => {
        try {
          const usersByName = await userApi.getUsersByName(search);

          if (!usersByName) {
            return {
              error: ERROR.UNEXPECTED,
            };
          }

          return {
            data: usersByName,
          };
        } catch (error) {
          return {
            error,
          };
        }
      }}
      inputProps={{
        placeholder: "Escolha o usuário...",
      }}
      openOnInputClick
      renderInput={({ onClick, ...props }) => (
        <div
          css={styles.input.root}
          onClick={(e) => {
            onClick?.(e as any);
          }}
        >
          <div css={styles.input.container}>
            <input css={styles.input.input} {...props} />
          </div>
        </div>
      )}
      renderItem={({ isHighlighted, item, ...props }) => {
        if (item.error) {
          return (
            <li css={styles.item.item} key={0} {...props}>
              Nenhum usuário encontrado...
            </li>
          );
        }

        return (
          <li
            css={styles.item.button.root({ isHighlighted })}
            key={JSON.stringify(item)}
            {...props}
          >
            <span css={styles.item.button.name}>{item.label}</span>
            <span css={styles.item.button.email}>{item.email}</span>
          </li>
        );
      }}
      {...(props as any)}
    />
  );
};
