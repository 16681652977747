import React from "react";

import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { Switch } from "@toolkit/v2";

import { styles } from "./styles";

export const OptimalPriceSwitch: React.FC<
  React.ComponentPropsWithoutRef<typeof Switch>
> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <div css={styles.label.root}>
        <span css={styles.label.text}>Política de preço estatístico</span>
        <Tooltip
          content={
            <div css={styles.tooltip.root}>
              <strong>
                Preço estatistíco é cálculado com base na <i>mediana</i> dos 50
                resultados mais recomendados na busca.
              </strong>
              <p>
                <i>
                  <strong>Mediana:</strong> o número do centro; é encontrado
                  ordenando-se todos os dados e escolhendo o que está no centro.
                  Exemplo: a mediana de 4, 1 e 7 é 4, porque, quando os números
                  são colocados em ordem (1 , 4, 7), o número 4 está no centro.
                </i>
              </p>
            </div>
          }
        />
      </div>
      <label css={styles.container.root}>
        <Switch variant="pink" {...props} />
        <span css={styles.container.text}>
          Ativar política de preço estatístico
        </span>
      </label>
    </div>
  );
};
