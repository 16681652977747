import React from "react";

import Drawer from "@material-ui/core/Drawer";
import { css } from "@styled-system/css";
import { AreaListItem } from "~/apps/corporate/models/area.model";
import { Flex } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import DrawerHeader from "@shared/DrawerHeader";

import { BoardForm } from "./board-form/board-form";

const styles = {
  drawerPaper: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: [500, 815],
    },
  }),
  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "4.25rem",
    backgroundColor: theme.colors.background.gray,
    paddingLeft: "0.5rem",
    fontSize: "1.125rem",
  }),
};

export type SelectedBoard = {
  companyAreaToken?: string;
  name?: string;
  originalSubAreas: AreaListItem[];
  subAreas: AreaListItem[];
};

export type BoardDrawerProps = {
  selectedBoard: SelectedBoard;
  boardName: string | null;
  companyAreaName: string | null;
  drawerOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: { boardName: string }) => void;
};

export const BoardDrawer: React.FC<BoardDrawerProps> = ({
  selectedBoard,
  boardName,
  companyAreaName,
  drawerOpen,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleClose}
      css={styles.drawerPaper}
    >
      <Flex flexDirection="column" style={{ height: "100%" }}>
        <DrawerHeader
          title={
            selectedBoard.companyAreaToken
              ? `Editar ${boardName}`
              : `Adicionar nova ${boardName}`
          }
          handleClose={handleClose}
          headerStyles={styles.header}
        />
        <BoardForm
          boardName={boardName}
          companyAreaName={companyAreaName}
          handleClose={handleClose}
          onSubmit={handleSubmit}
          selectedBoard={selectedBoard}
        />
      </Flex>
    </Drawer>
  );
};
