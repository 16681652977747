import React, { useContext } from "react";

export const useContextFactory = <T>(
  name: string,
  context: React.Context<T>,
) => {
  return () => {
    const ctx = useContext(context);

    if (ctx === undefined) {
      throw new Error(`use${name} must be used within a ${name}Provider`);
    }

    return ctx;
  };
};
