import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  popover: css({
    ".MuiPaper-root": {
      overflow: "visible",
    },
  }),
};
