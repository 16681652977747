import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate, RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { CarSupplier } from "~/apps/corporate/models/car.model";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { LoadingOverlay } from "~/apps/shared/components/loading-overlay/loading-overlay";
import { CarOutOfPolicyDialog } from "~/apps/shared/components/out-of-policy-dialog/out-of-policy-dialog";
import { SearchExpirationDialog } from "~/apps/shared/components/search-expiration-dialog/search-expiration-dialog";
import { useCheckExpiration } from "~/apps/shared/hooks/use-check-expiration";

import { AdditionalSkeleton } from "./additional/additional";
import { CarResultProvider, useCarResult } from "./car-result.context";
import { CarSummary, CarSummarySkeleton } from "./car-summary/car-summary";
import { LocalizaAdditionals } from "./localiza-additionals/localiza-additionals";
import { MovidaAdditionals } from "./movida-additionals/movida-additionals";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    carToken?: string;
    supplierOfferToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({
  carToken,
  supplierOfferToken,
  travelToken,
}) => {
  const {
    additionalDrivers,
    car,
    error,
    expirationTime,
    handleChangeAdditionalDrivers,
    handleCloseOutOfPolicyDialog,
    handleGetCarSupplierOffer,
    handleExpiration,
    handleRecalculateCarOffer,
    handleSearchAdditionalDrivers,
    handleSelectOffer,
    handleOpenOutOfPolicyDialog,
    hasExpired,
    isOutOfPolicyDialogOpen,
    loading,
    loadingRecalculate,
    policy,
  } = useCarResult();

  useCheckExpiration(expirationTime, handleExpiration, 2 * 60 * 1000);

  useEffect(() => {
    if (!carToken || !supplierOfferToken) {
      return;
    }

    void handleGetCarSupplierOffer(carToken, supplierOfferToken);
  }, [carToken, supplierOfferToken]);

  if (!carToken || !travelToken) {
    return null;
  }

  if (error) {
    return (
      <ErrorPage
        message={error.description}
        navigateTo={() => {
          navigate("/");
        }}
        title={error.title}
      />
    );
  }

  return (
    <>
      <PageTitle title="Resultado de carro" />
      <div css={styles.root}>
        <div css={styles.form.root}>
          {loading ? (
            <div css={styles.form.skeleton.root}>
              <div css={styles.form.skeleton.heading}>
                <span css={styles.summary.title}>Adicionais</span>
                <Skeleton height="14px" width="256px" />
              </div>
              {Array(9)
                .fill(0)
                .map((_, i) => (
                  <AdditionalSkeleton key={i} />
                ))}
            </div>
          ) : car ? (
            <>
              {(
                car.supplierInfo?.supplier || car.rentalDetails.name
              ).toUpperCase() === CarSupplier.LOCALIZA.toUpperCase() ? (
                <LocalizaAdditionals
                  additionalDrivers={additionalDrivers}
                  car={car}
                  handleChangeAdditionalDrivers={handleChangeAdditionalDrivers}
                  handleRecalculateCarOffer={handleRecalculateCarOffer(
                    carToken,
                  )}
                  handleSearchAdditionalDrivers={handleSearchAdditionalDrivers}
                  loadingRecalculate={loadingRecalculate}
                />
              ) : null}
              {(
                car.supplierInfo?.supplier || car.rentalDetails.name
              ).toUpperCase() === CarSupplier.MOVIDA.toUpperCase() ? (
                <MovidaAdditionals
                  additionalDrivers={additionalDrivers}
                  car={car}
                  handleChangeAdditionalDrivers={handleChangeAdditionalDrivers}
                  handleRecalculateCarOffer={handleRecalculateCarOffer(
                    carToken,
                  )}
                  handleSearchAdditionalDrivers={handleSearchAdditionalDrivers}
                  loadingRecalculate={loadingRecalculate}
                />
              ) : null}
            </>
          ) : null}
        </div>
        <div css={styles.summary.root}>
          <span css={styles.summary.title}>Resumo da reserva</span>
          {loading ? (
            <CarSummarySkeleton />
          ) : car ? (
            <CarSummary
              car={car}
              handleSelectOffer={handleSelectOffer(car)}
              handleOpenOutOfPolicyDialog={handleOpenOutOfPolicyDialog}
              loading={loadingRecalculate}
            />
          ) : null}
        </div>
      </div>
      {car && policy ? (
        <CarOutOfPolicyDialog
          offer={car}
          onClose={handleCloseOutOfPolicyDialog}
          open={isOutOfPolicyDialogOpen}
          policy={policy}
        />
      ) : null}
      <SearchExpirationDialog
        handleNavigeToNewTrip={() => {
          navigate(`/travels/${travelToken}/search/cars/${carToken}`);
        }}
        hideRefreshButton
        open={hasExpired}
      />
      <LoadingOverlay css={styles.loading} open={loadingRecalculate} />
    </>
  );
};

export const CarResult: React.FC<Props> = ({ ...props }) => {
  const { carToken, travelToken } = props;

  if (!carToken || !travelToken) {
    return null;
  }

  return (
    <CarResultProvider carToken={carToken} travelToken={travelToken}>
      <Component {...props} />
    </CarResultProvider>
  );
};
