import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  error: css({
    color: theme.colors.gray[500],
    fontSize: "0.875rem",
  }),
  period: {
    root: css({
      alignItems: "center",
      color: theme.colors.gray[500],
      display: "flex",
      gap: "12px",
    }),
    text: css({
      color: theme.colors.gray[500],
      fontSize: "0.875rem",
      textTransform: "capitalize",
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  top: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      width: "100%",
    }),
    token: css({
      color: theme.colors.gray[700],
      display: "inline-block",
      fontSize: "1.5rem",
      fontWeight: 700,
      minWidth: "24px",
      outline: "none",
      padding: "0",
      textOverflow: "ellipsis",
      transition: "all 0.15s ease-in-out",
      width: "100%",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
  users: {
    booker: {
      icon: css({
        color: theme.colors.gray[300],
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[500],
        display: "flex",
        gap: "12px",
        maxWidth: "calc(50% - 16px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        "@media screen and (max-width: 640px)": {
          maxWidth: "unset",
        },
      }),
      text: {
        label: css({
          color: theme.colors.gray[500],
        }),
        root: css({
          fontSize: "0.875rem",
        }),
        value: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
      },
    },
    root: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
    }),
    traveler: {
      icon: css({
        color: theme.colors.gray[700],
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[500],
        display: "flex",
        gap: "12px",
        maxWidth: "calc(50% - 16px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        "@media screen and (max-width: 640px)": {
          maxWidth: "unset",
        },
      }),
      text: {
        label: css({
          color: theme.colors.gray[500],
        }),
        root: css({
          fontSize: "0.875rem",
        }),
        value: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
      },
    },
  },
};
