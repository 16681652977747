import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { ERROR } from "~/apps/shared/constants/errors";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryServices } from "../../../itinerary/itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { usePaymentContainer } from "../../payment.container";
import { usePayment } from "../../payment.context";
import { styles } from "./styles";

export const PaymentPriceBreakdown: React.FC = () => {
  const {
    offersAvailabilitiesModel,
    offersChangesModel,
  } = useItineraryContainer();
  const { travelPaymentModel } = usePaymentContainer();

  const {
    isLoading,
    selectedTravelFlightCreditOfferToken,
    travelFlightCredits,
  } = usePayment();

  const { errorOnFetchOffersAvailabilities } = useItineraryServices();

  const appliedCredit = useMemo(() => {
    if (!travelFlightCredits || !selectedTravelFlightCreditOfferToken) {
      return 0;
    }

    const selectedCreditInfo = Object.keys(travelFlightCredits)
      .map((key) => ({ key, ...travelFlightCredits[key] }))
      .find((credit) => credit.key === selectedTravelFlightCreditOfferToken);

    if (selectedCreditInfo) {
      return selectedCreditInfo.value;
    }

    return 0;
  }, [travelFlightCredits, selectedTravelFlightCreditOfferToken]);

  const servicesTotalPrice = useMemo(() => {
    if (!travelPaymentModel) {
      return 0;
    }

    const allServices = travelPaymentModel.getAllServices();

    const servicesTotalPrices = allServices
      .filter((service) => {
        const offerToken = service.getOfferToken();

        const offerAvailability = offersAvailabilitiesModel
          ? offersAvailabilitiesModel.getOfferAvailabilityByOfferToken(
              offerToken,
            )
          : null;

        const isUnavailable = offerAvailability
          ? offerAvailability.isUnavailable()
          : false;

        return !isUnavailable;
      })
      .map((service) => service.getPrice());

    return servicesTotalPrices.reduce((prev, curr) => prev + curr, 0);
  }, [offersAvailabilitiesModel, travelPaymentModel]);

  const servicesTotalPriceAfterChanges = useMemo(() => {
    if (!offersChangesModel) {
      return null;
    }

    if (!travelPaymentModel) {
      return 0;
    }

    const allServices = travelPaymentModel.getAllServices();

    const servicesTotalPricesAfterChanges = allServices
      .filter((service) => {
        const offerToken = service.getOfferToken();

        const offerAvailability = offersAvailabilitiesModel
          ? offersAvailabilitiesModel.getOfferAvailabilityByOfferToken(
              offerToken,
            )
          : null;

        const isUnavailable = offerAvailability
          ? offerAvailability.isUnavailable()
          : false;

        return !isUnavailable;
      })
      .map((service) => {
        const offerChanges = offersChangesModel.getOfferChangesByOfferToken(
          service.getOfferToken(),
        );

        const hasChanges = offerChanges
          ? offerChanges.hasChanges(travelPaymentModel.getAllServices())
          : false;

        return hasChanges && offerChanges
          ? offerChanges.getCurrentPrice()
          : service.getPrice();
      });

    return servicesTotalPricesAfterChanges.reduce(
      (prev, curr) => prev + curr,
      0,
    );
  }, [offersAvailabilitiesModel, offersChangesModel, travelPaymentModel]);

  if (isLoading) {
    return <PaymentPriceBreakdownSkeleton />;
  }

  if (!travelPaymentModel) {
    return null;
  }

  // const feePaidAt = travelPayment.getFeePaidAt();
  // const feeValue = travelPayment.getFeeValue();
  const hasPriceChanges =
    servicesTotalPriceAfterChanges !== null &&
    servicesTotalPrice !== servicesTotalPriceAfterChanges;
  const travelPaymentServices = travelPaymentModel.getAllServices();

  return (
    <div css={styles.root} id="payment-price-breakdown">
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        {travelPaymentModel.hasServices() ? (
          <ul css={styles.section.prices.root}>
            {travelPaymentServices.map((serviceModel) => {
              const offerToken = serviceModel.getOfferToken();

              const offerAvailability = offersAvailabilitiesModel
                ? offersAvailabilitiesModel.getOfferAvailabilityByOfferToken(
                    offerToken,
                  )
                : null;
              const offerChanges = offersChangesModel
                ? offersChangesModel.getOfferChangesByOfferToken(offerToken)
                : null;

              const errorOnFetchOfferAvailability = errorOnFetchOffersAvailabilities
                ? errorOnFetchOffersAvailabilities[offerToken]
                : null;
              const hasPriceChanges = offerChanges
                ? offerChanges.hasPriceChanges(travelPaymentServices)
                : false;
              const isPriceChangePositive = offerChanges
                ? offerChanges.isPriceChangePositive(serviceModel)
                : null;
              const isUnavailable = offerAvailability
                ? offerAvailability.isUnavailable()
                : false;

              const servicePresenter = ItineraryServicePresenterFactory.create(
                serviceModel,
              );

              return (
                <li
                  css={styles.section.prices.price.root({
                    hasPriceChanges,
                    isPriceChangePositive,
                    isUnavailable,
                  })}
                  key={offerToken}
                >
                  <span css={styles.section.prices.price.label}>
                    {servicePresenter.getFormattedName()}:
                  </span>
                  <span
                    css={styles.section.prices.price.value({
                      hasPriceChanges,
                    })}
                  >
                    {servicePresenter.getFormattedPrice()}
                  </span>
                  {errorOnFetchOfferAvailability ? (
                    <Tooltip
                      arrow
                      content={
                        errorOnFetchOfferAvailability.description !==
                        ERROR.UNEXPECTED.description
                          ? errorOnFetchOfferAvailability.description
                          : "Erro ao buscar disponibilidade."
                      }
                      position="left"
                    >
                      <div css={styles.section.prices.icon}>
                        <Icon size={16} use="exclamation-triangle" />
                      </div>
                    </Tooltip>
                  ) : null}
                  {hasPriceChanges && offerChanges ? (
                    <>
                      <span css={styles.section.prices.price.value({})}>
                        {toCurrency(offerChanges.getCurrentPrice())}
                      </span>
                      {isPriceChangePositive ? (
                        <Tooltip
                          arrow
                          content="O preço desta oferta subiu."
                          position="left"
                        >
                          <div css={styles.section.prices.icon}>
                            <Icon size={16} use="arrow-trending-up" />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          arrow
                          content="O preço desta oferta diminuiu."
                          position="left"
                        >
                          <div css={styles.section.prices.icon}>
                            <Icon size={16} use="arrow-trending-down" />
                          </div>
                        </Tooltip>
                      )}
                    </>
                  ) : null}
                  {isUnavailable ? (
                    <Tooltip
                      arrow
                      content="Oferta não mais indisponível."
                      position="left"
                    >
                      <div css={styles.section.prices.icon}>
                        <Icon size={16} use="x" />
                      </div>
                    </Tooltip>
                  ) : null}
                </li>
              );
            })}
          </ul>
        ) : (
          <span css={styles.section.empty}>
            Não há ofertas para prosseguir. Revise as informações.
          </span>
        )}
        {appliedCredit ? (
          <div css={styles.section.credit.root}>
            <span css={styles.section.credit.label}>
              Crédito aplicado em aéreo:
            </span>
            <span css={styles.section.credit.value}>
              {toCurrency(-appliedCredit)}
            </span>
          </div>
        ) : null}
        {/* {feeValue ? (
          <div css={styles.section.prices.price.total}>
            <span css={styles.section.prices.price.label}>
              Smartrips
              {feePaidAt
                ? ` (pago em ${feePaidAt.format("DD/MM/YYYY")})`
                : null}
              :
            </span>
            <span css={styles.section.prices.price.value({})}>
              {toCurrency(feeValue)}
            </span>
          </div>
        ) : null} */}
        <div css={styles.section.prices.price.total}>
          <span css={styles.section.prices.price.label}>
            Total da viagem a ser pago:
          </span>
          <span
            css={styles.section.prices.price.value({
              hasPriceChanges,
            })}
          >
            {toCurrency(servicesTotalPrice - appliedCredit)}
          </span>
          {hasPriceChanges && servicesTotalPriceAfterChanges ? (
            <span css={styles.section.prices.price.value({})}>
              {toCurrency(servicesTotalPriceAfterChanges - appliedCredit)}
            </span>
          ) : null}
        </div>
        {travelPaymentModel.isSomeOfferNotInBRL() ? (
          <span css={styles.disclaimer}>
            * Esse preço foi convertido para mostrar o valor aproximado em R$.
            Você irá pagar cada oferta em sua respectiva moeda local. A taxa de
            câmbio pode variar antes de você confirmar o pagamento.
          </span>
        ) : null}
      </div>
    </div>
  );
};

const PaymentPriceBreakdownSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <ul css={styles.section.prices.root}>
          <li css={styles.section.prices.price.root({})}>
            <Skeleton height="14px" width="192px" />
            <Skeleton height="14px" width="64px" />
          </li>
          <li css={styles.section.prices.price.root({})}>
            <Skeleton height="14px" width="144px" />
            <Skeleton height="14px" width="72px" />
          </li>
        </ul>
        <div css={styles.section.prices.price.total}>
          <span css={styles.section.prices.price.label}>
            <Skeleton height="14px" width="288px" />
          </span>
          <span css={styles.section.prices.price.value({})}>
            <Skeleton height="14px" width="48px" />
          </span>
        </div>
      </div>
    </div>
  );
};
