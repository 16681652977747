import React, { useCallback } from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof Dialog>;

export const MemberGetMemberTermsAndConditionsDialog: React.FC<Props> = ({
  onClose,
  open,
}) => {
  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown" | "none") => {
      if (!onClose) {
        return;
      }

      if (reason === "none") {
        onClose(event, "backdropClick");

        return;
      }

      onClose(event, reason);
    },
    [onClose],
  );

  return (
    <Dialog css={styles.root} onClose={handleClose} open={open}>
      <DialogHeader
        icon="clipboard-document-list"
        onClose={() => {
          handleClose({}, "none");
        }}
      />
      <DialogContent>
        <DialogTitle>Termos e condições.</DialogTitle>
        <div css={styles.content.terms.root}>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>Como funciona?</span>
            <p css={styles.content.terms.section.paragraph}>
              Você indica a Smartrips para um colega de outra empresa e se a
              empresa nos contratar, você ganha até R$ 10.000,00 em viagens a
              lazer para você e sua família.
            </p>
          </div>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>
              O que você ganha?
            </span>
            <p css={styles.content.terms.section.paragraph}>
              👉 Você ganha 5% do valor total gasto em viagens no primeiro mês
              pela empresa indicada.
            </p>
          </div>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>Exemplos:</span>
            <table>
              <thead>
                <tr>
                  <th css={styles.content.terms.table.th}>
                    Empresa indicada gastou
                  </th>
                  <th css={styles.content.terms.table.th}>
                    Você ganha (em crédito)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td css={styles.content.terms.table.td}>R$ 10.000,00</td>
                  <td css={styles.content.terms.table.td}>R$ 500,00</td>
                </tr>
                <tr>
                  <td css={styles.content.terms.table.td}>R$ 20.000,00</td>
                  <td css={styles.content.terms.table.td}>R$ 1.000,00</td>
                </tr>
                <tr>
                  <td css={styles.content.terms.table.td}>R$ 100.000,00</td>
                  <td css={styles.content.terms.table.td}>R$ 5.000,00</td>
                </tr>
                <tr>
                  <td css={styles.content.terms.table.td}>
                    R$ 200.000,00 ou mais
                  </td>
                  <td css={styles.content.terms.table.td}>R$ 10.000,00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>
              O que a empresa indicada ganha?
            </span>
            <p css={styles.content.terms.section.paragraph}>
              👉 A nova empresa ganha acesso gratuito à plataforma Smartrips por
              um ano!
            </p>
          </div>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>Passo a passo:</span>
            <ol css={styles.content.terms.section.list}>
              <li>
                Copie o SEU LINK DE INDICAÇÃO para compartilhar com colegas de
                outras empresas;
              </li>
              <li>
                Esse link abrirá o site institucional da Smartrips já com um
                rastreio vinculando você ao site;
              </li>
              <li>
                Quando seu colega clicar no botão &quot;Quero conhecer&quot;, e
                enviar o formulário, nós registraremos a empresa como sua
                indicação;
              </li>
              <li>
                Avise aos seus indicados que eles ganharão um ano grátis de
                plataforma por serem seus convidados.
              </li>
            </ol>
          </div>
          <div css={styles.content.terms.section.root}>
            <p css={styles.content.terms.section.paragraph}>
              <strong css={styles.content.terms.section.strong}>
                ATENÇÃO:{" "}
              </strong>{" "}
              O indicado precisa acessar e preencher o formulário a partir do
              SEU LINK DE INDICAÇÃO. Caso ele entre no site da Smartrips por
              outra via, a empresa NÃO será considerada uma indicação sua e você
              NÃO ganhará nenhum crédito.
            </p>
          </div>
          <div css={styles.content.terms.section.root}>
            <span css={styles.content.terms.section.title}>
              Conheça as regras:
            </span>
            <p css={styles.content.terms.section.paragraph}>
              Para ganhar o prêmio, sua indicação precisa cumprir alguns
              critérios:
            </p>
            <ol css={styles.content.terms.section.list}>
              <li>Cadastro de contato no site institucional da Smartrips;</li>
              <li>Gasto superior a R$ 10.000,00 por mês com viagens;</li>
              <li>Agendamento de reunião com o time de vendas;</li>
              <li>
                Ter passado em nossos critérios de análise de risco financeiro;
              </li>
              <li>Assinar o contrato conosco.</li>
            </ol>
          </div>
          <div css={styles.content.terms.section.root}>
            <p css={styles.content.terms.section.paragraph}>
              Para ganhar seus pontos o novo cliente precisa fechar um mês de
              compra de viagens inteiro com a Smartrips. Consideramos o período
              de 30 dias corridos após a primeira viagem comprada pelo cliente
              na plataforma. Após os 30 dias, você verá na plataforma da
              Smartrips seu crédito em forma de SmartPoints que ainda estarão
              com o status de &quot;Pontos Pendentes&quot;. Lembrando que cada
              ponto equivale a R$ 1,00 para trocar em viagens pessoais a lazer
              para você e sua família.
            </p>
          </div>
          <div css={styles.content.terms.section.root}>
            <p css={styles.content.terms.section.paragraph}>
              Após ficarem pendentes, os pontos ficarão disponíveis em um prazo
              máximo de 30 dias. Para utilizar os pontos disponíveis, basta
              entrar em contato através do e-mail{" "}
              <span css={styles.content.terms.section.email}>
                lazer@smartrips.com.br
              </span>{" "}
              e montar sua viagem com um de nossos agentes.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
