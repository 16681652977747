import React from "react";

import { UserModel } from "~/apps/corporate/models/user.model";
import { Form, useFormContext } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";
import { Input } from "~/apps/shared/components/input/input";

import { Switch } from "@toolkit/v2";

import { CreateUserSchema, EditUserSchema } from "../users-user-form.schema";
import { styles } from "./styles";
import { UserFormGeneralInfoReinviteButton } from "./user-form-general-info-reinvite-button/user-form-general-info-reinvite-button";

type Props = {
  mode: "create" | "edit";
  user?: UserModel;
};

export const UserFormGeneralInfo: React.FC<Props> = ({ mode, user }) => {
  const form = useFormContext();

  return (
    <div css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Nome*</span>
            <Form.Field
              name="firstName"
              render={({ onChange, value }) => (
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder="Digite o nome..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["firstName"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Sobrenome*</span>
            <Form.Field
              name="lastName"
              render={({ onChange, value }) => (
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder="Digite o sobrenome..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["lastName"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Email*</span>
            <Form.Field
              name="email"
              render={({ onChange, value }) => (
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder="Digite o email..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["email"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Celular</span>
            <Form.Field
              name="phone"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="phone-or-cellphone"
                  onChange={onChange}
                  placeholder="Digite o número do celular..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["phone"]?.message}
            </InputErrorMessage>
          </div>
        </div>
      </div>
      {mode === "create" ? (
        <Form.Field<CreateUserSchema["sendInvite"]>
          name="sendInvite"
          render={({ value }) => (
            <label css={styles.body.switch.root}>
              <Switch
                active={!!value}
                onChange={(e) => {
                  form.setValue("sendInvite", e.target.checked);
                }}
                variant="pink"
              />
              <span css={styles.body.switch.text}>
                Enviar convite por email
              </span>
            </label>
          )}
        />
      ) : null}
      {mode === "edit" && user ? (
        <div css={styles.body.row}>
          <Form.Field<EditUserSchema["sendSms"]>
            name="sendSms"
            render={({ value }) => (
              <label css={styles.body.switch.root}>
                <Switch
                  active={!!value}
                  onChange={(e) => {
                    form.setValue("sendSms", e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.body.switch.text}>
                  Receber informações por SMS
                </span>
              </label>
            )}
          />
          {!user.hasLoggedIn() ? (
            <UserFormGeneralInfoReinviteButton
              userToken={user.getUserToken()}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
