import { useMemo, useEffect } from "react";

import { TripConnection } from "~/apps/corporate/dtos/bus.dto";
import scrollIntoView from "scroll-into-view-if-needed";

import { FiltersState, PageState } from "./buses.context";

export const useFocusStretchTabs = (currentSection: string) => {
  useEffect(() => {
    const node = document.getElementById("stretch-tabs");

    if (!node) {
      return;
    }

    scrollIntoView(node, {
      behavior: "smooth",
      scrollMode: "always",
    });
  }, [currentSection]);
};

export const useVisibleTrips = (
  pageState: PageState,
  filtersState: FiltersState,
) => {
  const trips =
    pageState.currentSection === "departure"
      ? pageState.departureTrips
      : pageState.returnTrips;

  const getVisibleList = (
    trips: TripConnection[],
    filtersState: FiltersState,
  ) => {
    function filterByPriceRange(trip: TripConnection) {
      const totalPrice = trip.price;
      return (
        totalPrice >= filtersState.selectedPriceRange[0] &&
        totalPrice <= filtersState.selectedPriceRange[1]
      );
    }

    function filterByDepartureLocation(trip: TripConnection) {
      const selectedPlaces = filtersState.departureOptions
        .filter((item) => item.checked)
        .map((item) => item.label);

      if (!selectedPlaces.length) {
        return true;
      }

      return selectedPlaces.includes(trip.from);
    }

    function filterByArrivalLocation(trip: TripConnection) {
      const selectedPlaces = filtersState.arrivalOptions
        .filter((item) => item.checked)
        .map((item) => item.label);

      if (!selectedPlaces.length) {
        return true;
      }

      return selectedPlaces.includes(trip.to);
    }

    function filterByCompanies(trip: TripConnection) {
      const selectedIds = filtersState.companyOptions
        .filter((item) => item.checked)
        .map((item) => item.id);

      if (!selectedIds.length) {
        return true;
      }

      return selectedIds.includes(trip.parts[0].travelCompany.id);
    }

    function filterByCategories(trip: TripConnection) {
      const selectedIds = filtersState.categoryOptions
        .filter((item) => item.checked)
        .map((item) => item.id);

      if (!selectedIds.length) {
        return true;
      }

      // name used to remove duplicate IDs on the page
      return selectedIds.includes(trip.parts[0].bus.name);
    }

    const filteredTrips = trips
      .filter(filterByPriceRange)
      .filter(filterByDepartureLocation)
      .filter(filterByArrivalLocation)
      .filter(filterByCompanies)
      .filter(filterByCategories);

    return filteredTrips;
  };

  const visibleTrips = useMemo(() => getVisibleList(trips, filtersState), [
    trips,
    filtersState,
  ]);

  return visibleTrips;
};
