import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useApprovalReview } from "../../approval-review.context";
import { useApprovalReviewPriceBreakdownGuard } from "./approval-review-price-breakdown.hooks";
import { styles } from "./styles";

export const ApprovalReviewPriceBreakdown: React.FC = () => {
  const { travelApprovalModel } = useItineraryContainer();

  const { loading } = useApprovalReviewPriceBreakdownGuard();

  const { selectedServices } = useApprovalReview();

  const notSelectedServices = useMemo(() => {
    if (!selectedServices || !travelApprovalModel) {
      return null;
    }

    const offersToReviewServices = travelApprovalModel.getAllOffersToReviewServices();

    const notSelectedServices = offersToReviewServices.filter(
      (service) =>
        !service.isApprovalDeclined() &&
        selectedServices.every(
          (selectedService) =>
            selectedService.getOfferToken() !== service.getOfferToken(),
        ),
    );

    return notSelectedServices;
  }, [selectedServices, travelApprovalModel]);

  const notSelectedServicesTotalPrice = useMemo(() => {
    if (!notSelectedServices) {
      return 0;
    }

    const notSelectedServicesTotalPrices = notSelectedServices.map((service) =>
      service.getPrice(),
    );

    return notSelectedServicesTotalPrices.reduce(
      (prev, curr) => prev + curr,
      0,
    );
  }, [notSelectedServices]);

  const selectedServicesTotalPrice = useMemo(() => {
    if (!selectedServices) {
      return 0;
    }

    const servicesTotalPrices = selectedServices.map((service) =>
      service.getPrice(),
    );

    return servicesTotalPrices.reduce((prev, curr) => prev + curr, 0);
  }, [selectedServices]);

  if (loading) {
    return <ApprovalReviewPriceBreakdownSkeleton />;
  }

  if (!notSelectedServices || !selectedServices || !travelApprovalModel) {
    return null;
  }

  // const feeValue = travelApproval.getFeeValue();

  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />{" "}
      {notSelectedServices.length > 0 ? (
        <>
          <div css={styles.denial.root}>
            <span css={styles.denial.title}>Serviços a serem negados:</span>
            <ul css={styles.denial.prices.root}>
              {notSelectedServices.map((serviceModel) => {
                const servicePresenter = ItineraryServicePresenterFactory.create(
                  serviceModel,
                );

                return (
                  <li
                    css={styles.denial.prices.price.root({})}
                    key={servicePresenter.getOfferToken()}
                  >
                    <span css={styles.denial.prices.price.label}>
                      {servicePresenter.getFormattedName()}:
                    </span>
                    <span css={styles.denial.prices.price.value({})}>
                      {servicePresenter.getFormattedPrice()}
                    </span>
                  </li>
                );
              })}
            </ul>
            <div css={styles.denial.prices.price.total}>
              <span css={styles.denial.prices.price.label}>
                Total da viagem a ser negado:
              </span>
              <span css={styles.denial.prices.price.value({})}>
                {toCurrency(notSelectedServicesTotalPrice)}
              </span>
            </div>
          </div>
          {selectedServices.length > 0 ? <hr css={styles.divisor} /> : null}
        </>
      ) : null}
      {selectedServices.length > 0 ? (
        <div css={styles.approval.root}>
          <span css={styles.approval.title}>Serviços a serem aprovados:</span>
          <ul css={styles.approval.prices.root}>
            {selectedServices.map((serviceModel) => {
              const servicePresenter = ItineraryServicePresenterFactory.create(
                serviceModel,
              );

              return (
                <li
                  css={styles.approval.prices.price.root({})}
                  key={servicePresenter.getOfferToken()}
                >
                  <span css={styles.approval.prices.price.label}>
                    {servicePresenter.getFormattedName()}:
                  </span>
                  <span css={styles.approval.prices.price.value({})}>
                    {servicePresenter.getFormattedPrice()}
                  </span>
                </li>
              );
            })}
          </ul>
          {/* {feeValue ? (
          <div css={styles.section.prices.price.total}>
            <span css={styles.section.prices.price.label}>Smartrips:</span>
            <span css={styles.section.prices.price.value({})}>
              {toCurrency(feeValue)}
            </span>
          </div>
        ) : null} */}
          <div css={styles.approval.prices.price.total}>
            <span css={styles.approval.prices.price.label}>
              Total da viagem a ser aprovado:
            </span>
            <span css={styles.approval.prices.price.value({})}>
              {toCurrency(selectedServicesTotalPrice)}
            </span>
          </div>
          {travelApprovalModel.isSomeOfferNotInBRL() ? (
            <span css={styles.disclaimer}>
              * Esse preço foi convertido para mostrar o valor aproximado em R$.
              Você irá pagar cada oferta em sua respectiva moeda local. A taxa
              de câmbio pode variar antes de você confirmar o pagamento.
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const ApprovalReviewPriceBreakdownSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.approval.root}>
        <ul css={styles.approval.prices.root}>
          <li css={styles.approval.prices.price.root({})}>
            <Skeleton height="14px" width="192px" />
            <Skeleton height="14px" width="64px" />
          </li>
          <li css={styles.approval.prices.price.root({})}>
            <Skeleton height="14px" width="144px" />
            <Skeleton height="14px" width="72px" />
          </li>
        </ul>
        <div css={styles.approval.prices.price.total}>
          <span css={styles.approval.prices.price.label}>
            <Skeleton height="14px" width="288px" />
          </span>
          <span css={styles.approval.prices.price.value({})}>
            <Skeleton height="14px" width="48px" />
          </span>
        </div>
      </div>
    </div>
  );
};
