import React from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { useBusesPage, useBusesPageActions } from "../buses.context";
import { styles } from "./styles";

const CLASSES_EXPLANATIONS = [
  {
    text:
      "O tipo de ônibus mais básico, que geralmente oferece cerca de 40 lugares. Possui poltronas convencionais acolchoadas e pouco reclináveis, ar-condicionado e banheiro (na maioria dos casos).",
    title: "Convencional",
  },
  {
    text:
      "Possui um número de assentos um pouco menor, e sendo assim, mais espaço para cada passageiro, seja no próprio assento ou para esticar as pernas. Muitas vezes ainda é possível encontrar frigobar com água mineral, televisão e rádio, além de ar-condicionado e banheiro.",
    title: "Executivo",
  },
  {
    text:
      "Com poltronas maiores e mais reclináveis, TV, frigobar com água e apoio para as pernas. Algumas empresas ainda oferecem mantas e travesseiros higienizados aos passageiros.",
    title: "Semi-Leito",
  },
  {
    text:
      "Geralmente com no máximo 28 passageiros. Essa categoria conta com poltronas anatômicas e duplas, assentos que reclinam 180º n (viram uma cama) e claro, travesseiro e manta. Em alguns casos, pequenos lanches são oferecidos.",
    title: "Leito",
  },
  {
    text:
      "Espaço mais amplo e confortável, com cama individual e mais espaçosa, cobertor, travesseiro, geladeira, serviços de bordo e lanche. Um diferencial interessante é no transporte de crianças, idosos e demais pessoas que precisem de cuidados especiais. Fica mais fácil viajar com essas pessoas em um ônibus que oferece esse espaço.",
    title: "Leito-Cama",
  },
];

export const BusCategoriesExplanationDialog: React.FC = () => {
  const { isCategoriesExplanationVisible } = useBusesPage();
  const { handleToggleCategoryExplanation } = useBusesPageActions();

  return (
    <Dialog
      css={styles.root}
      disableRestoreFocus
      onClose={handleToggleCategoryExplanation}
      open={isCategoriesExplanationVisible}
    >
      <DialogHeader
        css={styles.header.root}
        icon="bus"
        onClose={() => {
          handleToggleCategoryExplanation();
        }}
      />
      <DialogContent css={styles.content.root}>
        <div css={styles.content.top.root}>
          <DialogTitle>Classes de rodoviário.</DialogTitle>
          <span css={styles.content.top.message}>Entenda as categorias:</span>
        </div>
        <div css={styles.content.categories.root}>
          <ul css={styles.content.categories.list.root}>
            {CLASSES_EXPLANATIONS.map((explanation, index) => (
              <li css={styles.content.categories.list.item.root} key={index}>
                <span css={styles.content.categories.list.item.title}>
                  {explanation.title}
                </span>
                <p css={styles.content.categories.list.item.text}>
                  {explanation.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
