import React, { useRef, useState } from "react";
import { DayPickerRangeController } from "react-dates";

import { CarFormDateInput } from "~/apps/corporate/components/offer-searcher/car-form/car-form-date-input/car-form-date-input";
import {
  carFormSchema,
  CarFormSchema,
} from "~/apps/corporate/components/offer-searcher/car-form/car-form.schema";
import { CarsSearch } from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { BookingTarget } from "~/apps/corporate/models/booking-target.model";
import { DatePicker } from "~/apps/shared/components/date-picker/date-picker";
import {
  defaultNavNext,
  defaultNavPrev,
} from "~/apps/shared/components/date-picker/range-date-picker/range-date-picker";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { HourSelect } from "~/apps/shared/components/hour-select/hour-select";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { LocationAutocomplete } from "~/apps/shared/components/location-autocomplete/location-autocomplete";
import { Popover } from "~/apps/shared/components/popover/popover";
import { TextField } from "~/apps/shared/components/text-field/text-field";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import { Moment } from "moment";
import moment from "moment";

import { Button, CircularSpinner, Switch } from "@toolkit/v2";

import { NewTripOfferSearcherTravelerAutocomplete } from "../traveler-autocomplete/traveler-autocomplete";
import { styles } from "./styles";

type Props = {
  handleSearchCars: (carsSearch: CarsSearch) => Promise<void>;
};

export const NewTripOfferSearcherCarForm: React.FC<Props> = ({
  handleSearchCars,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 992;

  const [focusedDateInput, setFocusedDateInput] = useState<
    "endDate" | "startDate" | null
  >(null);
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [traveler, setTraveler] = useState<BookingTarget | null>(null);

  const datesContainerElement = useRef<HTMLDivElement>(null);

  const form = useForm<CarFormSchema>({
    defaultValues: {
      dropoffDate: null,
      dropoffLocation: null,
      dropoffTime: "10:00",
      pickupDate: null,
      pickupLocation: null,
      pickupTime: "10:00",
      sameLocationDropoff: true,
    } as CarFormSchema,
    onSubmit: async () => {
      if (!traveler) {
        return;
      }

      const values = form.getValues();

      await handleSearchCars({
        dropoffDate: values.dropoffDate,
        dropoffLocation: values.dropoffLocation,
        dropoffTime: values.dropoffTime,
        pickupDate: values.pickupDate,
        pickupLocation: values.pickupLocation,
        pickupTime: values.pickupTime,
        sameLocationDropoff: values.sameLocationDropoff,
        traveler,
      });
    },
    schema: carFormSchema,
  });

  const handleCloseDatePopover = () => {
    setIsDatePopoverOpen(false);
  };

  const handleDateInputClick = (type: "pickup" | "dropoff") => () => {
    if (type === "pickup") {
      setFocusedDateInput("startDate");
    } else {
      setFocusedDateInput("endDate");
    }

    setIsDatePopoverOpen(true);
  };

  const handleDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    form.setValue("pickupDate", date.startDate);

    if (date.startDate) {
      form.clearErrors("pickupDate");
    }

    form.setValue("dropoffDate", date.endDate);

    if (date.endDate) {
      form.clearErrors("dropoffDate");
    }
  };

  const handleDateInputFocusChange = (
    focusedDateInput: "endDate" | "startDate" | null,
  ) => {
    if (!focusedDateInput) {
      setIsDatePopoverOpen(false);
    }

    setFocusedDateInput(focusedDateInput);
  };

  const showTravelerErrorMessage =
    (hasTriedToSubmit && !traveler) || (!!traveler && !traveler.allowed);

  return (
    <>
      <Form context={form} css={styles.root}>
        <div css={styles.top.root}>
          <Form.Field<CarFormSchema["sameLocationDropoff"] | undefined>
            name="sameLocationDropoff"
            render={({ value }) => (
              <label css={styles.top.switch.root}>
                <Switch
                  active={!!value}
                  onChange={(e) => {
                    form.setValue("sameLocationDropoff", e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.top.switch.text}>
                  Devolver no mesmo local
                </span>
              </label>
            )}
          />
          <NewTripOfferSearcherTravelerAutocomplete
            onChange={(value) => {
              if (!value) {
                setTraveler(null);

                return;
              }

              setTraveler((prev) => {
                if (!prev) {
                  return {
                    fullName: value,
                  } as BookingTarget;
                }

                return {
                  ...prev,
                  fullName: value,
                };
              });
            }}
            onSelect={(value) => {
              setTraveler({
                allowed: value.allowed,
                email: value.email,
                fullName: value.label,
                userToken: value.userToken,
              });
            }}
            showErrorMessage={showTravelerErrorMessage}
            value={traveler ? traveler.fullName : undefined}
          />
        </div>
        <div css={styles.center.root}>
          <div css={styles.center.top.root}>
            <div css={styles.center.control.root}>
              <div css={styles.center.top.location.container}>
                <div css={styles.center.top.location.root}>
                  <Form.Field<CarFormSchema["pickupLocation"] | undefined>
                    name="pickupLocation"
                    render={({ value }) => (
                      <LocationAutocomplete
                        css={styles.center.top.location.location}
                        locationType={[
                          "sublocality",
                          "street_address",
                          "airport",
                          "car_rental",
                          "point_of_interest",
                        ]}
                        placeholder="Digite o local da retirada..."
                        onSelect={(location) => {
                          if (!location) {
                            form.setValue("pickupLocation", null);

                            return;
                          }

                          form.setValue("pickupLocation", {
                            latitude: location.latitude,
                            longitude: location.longitude,
                            search: location.formattedAddress,
                          });

                          form.clearErrors("pickupLocation");
                        }}
                        renderInput={({ loading, ...props }) => (
                          <div css={styles.center.top.location.input.root}>
                            {loading ? (
                              <CircularSpinner
                                css={styles.center.top.location.input.loading}
                                size={20}
                              />
                            ) : (
                              <Icon size={20} use="car" />
                            )}
                            <TextField
                              css={styles.center.top.location.input.input}
                              inputProps={{ ...props }}
                              label="Retirada*"
                              variant="outlined"
                            />
                          </div>
                        )}
                        value={value?.search}
                      />
                    )}
                  />
                </div>
              </div>
              <InputErrorMessage css={styles.top.control.error}>
                {form.errors["pickupLocation"]?.message}
              </InputErrorMessage>
            </div>
            {!form.watch("sameLocationDropoff") ? (
              <div css={styles.center.control.root}>
                <div css={styles.center.top.location.container}>
                  <div css={styles.center.top.location.root}>
                    <Form.Field<CarFormSchema["dropoffLocation"] | undefined>
                      name="dropoffLocation"
                      render={({ value }) => (
                        <LocationAutocomplete
                          css={styles.center.top.location.location}
                          locationType={[
                            "sublocality",
                            "street_address",
                            "airport",
                            "car_rental",
                            "point_of_interest",
                          ]}
                          placeholder="Digite o local da devolução..."
                          onSelect={(location) => {
                            if (!location) {
                              form.setValue("dropoffLocation", null);

                              return;
                            }

                            form.setValue("dropoffLocation", {
                              latitude: location.latitude,
                              longitude: location.longitude,
                              search: location.formattedAddress,
                            });

                            form.clearErrors("dropoffLocation");
                          }}
                          renderInput={({ loading, ...props }) => (
                            <div css={styles.center.top.location.input.root}>
                              {loading ? (
                                <CircularSpinner
                                  css={styles.center.top.location.input.loading}
                                  size={20}
                                />
                              ) : (
                                <Icon size={20} use="car" />
                              )}
                              <TextField
                                css={styles.center.top.location.input.input}
                                inputProps={{ ...props }}
                                label="Devolução*"
                                variant="outlined"
                              />
                            </div>
                          )}
                          value={value?.search}
                        />
                      )}
                    />
                  </div>
                </div>
                <InputErrorMessage css={styles.top.control.error}>
                  {form.errors["dropoffLocation"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </div>
          <div css={styles.center.bottom.root}>
            <div
              css={styles.center.bottom.dates.root}
              ref={datesContainerElement}
            >
              <div css={styles.center.bottom.dates.left}>
                <div css={styles.center.control.root}>
                  <div css={styles.center.bottom.dates.date.root}>
                    <CarFormDateInput
                      icon={<Icon size={16} use="calendar" />}
                      onChange={() => {
                        return;
                      }}
                      onClick={handleDateInputClick("pickup")}
                      placeholder="Data da retirada*"
                      value={
                        form.watch("pickupDate")
                          ? form.watch("pickupDate").format("DD/MM/YYYY")
                          : ""
                      }
                    />
                  </div>
                  <InputErrorMessage>
                    {form.errors["pickupDate"]?.message}
                  </InputErrorMessage>
                </div>
                <div css={styles.center.control.root}>
                  <div css={styles.center.bottom.dates.time.root}>
                    <HourSelect
                      id="pickupTime"
                      name="pickupTime"
                      onChange={({ value }) => {
                        form.setValue("pickupTime", value);
                      }}
                      styles={styles.center.bottom.dates.time.select({
                        error: false,
                        fill: "outlined",
                        variant: "default",
                      })}
                      value={{
                        label: form.watch("pickupTime"),
                        value: form.watch("pickupTime"),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div css={styles.center.bottom.dates.right}>
                <div css={styles.center.control.root}>
                  <div css={styles.center.bottom.dates.date.root}>
                    <CarFormDateInput
                      icon={<Icon size={16} use="calendar" />}
                      onChange={() => {
                        return;
                      }}
                      onClick={handleDateInputClick("dropoff")}
                      placeholder="Data da devolução*"
                      value={
                        form.watch("dropoffDate")
                          ? form.watch("dropoffDate").format("DD/MM/YYYY")
                          : ""
                      }
                    />
                  </div>
                  <InputErrorMessage>
                    {form.errors["dropoffDate"]?.message}
                  </InputErrorMessage>
                </div>
                <div css={styles.center.control.root}>
                  <div css={styles.button.container}>
                    <div css={styles.center.bottom.dates.time.root}>
                      <HourSelect
                        id="dropoffTime"
                        name="dropoffTime"
                        onChange={({ value }) => {
                          form.setValue("dropoffTime", value);
                        }}
                        styles={styles.center.bottom.dates.time.select({
                          error: false,
                          fill: "outlined",
                          variant: "default",
                        })}
                        value={{
                          label: form.watch("dropoffTime"),
                          value: form.watch("dropoffTime"),
                        }}
                      />
                    </div>
                    <Button
                      css={styles.button.root}
                      onClick={() => {
                        setHasTriedToSubmit(true);
                      }}
                      variant="pink"
                    >
                      <Icon use="search-outline" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      {focusedDateInput ? (
        <>
          {isMobile ? (
            <DatePicker
              allowSameDay
              endDate={form.watch("dropoffDate")}
              focusedInput={focusedDateInput}
              hideKeyboardShortcutsPanel
              isOutsideRange={(date) => date.diff(moment(), "days") < 0}
              navNext={defaultNavNext}
              navPrev={defaultNavPrev}
              numberOfMonths={2}
              onDatesChange={handleDateChange}
              onFocusChange={handleDateInputFocusChange}
              range
              startDate={form.watch("pickupDate")}
            />
          ) : (
            <Popover
              anchorEl={datesContainerElement.current}
              css={styles.dates.popover}
              onClose={handleCloseDatePopover}
              open={isDatePopoverOpen}
            >
              <div css={styles.dates.root}>
                <DayPickerRangeController
                  endDate={form.watch("dropoffDate")}
                  focusedInput={focusedDateInput}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={(date) => date.diff(moment(), "days") < 0}
                  minimumNights={0}
                  navNext={defaultNavNext}
                  navPrev={defaultNavPrev}
                  numberOfMonths={2}
                  onDatesChange={handleDateChange}
                  onFocusChange={handleDateInputFocusChange}
                  startDate={form.watch("pickupDate")}
                />
              </div>
            </Popover>
          )}
        </>
      ) : null}
    </>
  );
};
