import { useEffect, useState } from "react";

import { getParamsFromLocation } from "../utils/get-params-from-location";
import { useLocation } from "./use-location";

export const useParams = () => {
  const { location } = useLocation();

  const [params, setParams] = useState<any>(getParamsFromLocation(location));

  useEffect(() => {
    setParams(getParamsFromLocation(location));
  }, [location.search]);

  return params;
};
