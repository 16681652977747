import {
  FormControlLabelClassKey,
  StyledComponentProps,
} from "@material-ui/core";
import { css } from "@styled-system/css";
import { css as ecss } from "emotion";

import { theme } from "@skin/v2";

export const styles = {
  classes: <StyledComponentProps<FormControlLabelClassKey>["classes"]>{
    root: ecss({
      display: "flex",
    }),
  },
  label: css({
    alignItems: "center",
    color: theme.colors.gray[700],
    display: "flex",
    fontFamily: theme.fonts.inter,
    height: "24px",
    marginLeft: "8px",
  }),
};
