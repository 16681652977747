import { useEffect, useState } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";

import { useItineraryApproval } from "../../../itinerary-approval.context";
import { useItineraryInfo } from "../../../itinerary-info.context";
import { useItineraryServices } from "../../../itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary.container";

const initialPermissions = {
  canProceedFromTravelPlan: false,
};

export const useTravelPlanProceedButtonGuard = () => {
  const {
    infoModel,
    servicesModel,
    travelApprovalHistoryModel,
  } = useItineraryContainer();

  const { user } = useUser();

  const { errorOnFetchApprovalHistory } = useItineraryApproval();
  const { errorOnFetch: errorOnFetchItineraryInfo } = useItineraryInfo();
  const {
    errorOnFetch: errorOnFetchItineraryServices,
  } = useItineraryServices();

  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (!servicesModel || !travelApprovalHistoryModel || !user) {
      return;
    }

    const isUserBooker = infoModel ? infoModel.isUserBooker(user) : false;

    const isUserMasterApprover = user.isMasterApprover();

    setPermissions({
      canProceedFromTravelPlan:
        (isUserBooker || isUserMasterApprover) &&
        servicesModel.hasOffersToProceed() &&
        !travelApprovalHistoryModel.isPendingApproval(),
    });
  }, [infoModel, servicesModel, travelApprovalHistoryModel, user]);

  return {
    error:
      errorOnFetchApprovalHistory ||
      errorOnFetchItineraryInfo ||
      errorOnFetchItineraryServices,
    permissions,
  };
};
