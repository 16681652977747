import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  form: {
    input: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
      }),
    },
    select: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  row: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  }),
};
