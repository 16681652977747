import { IBookingTarget } from "~/models/booker-target.model";

import * as bookerTargetsApi from "../../apis/bookerTargets.api";
import * as busApi from "../../apis/bus.api";
import * as carApi from "../../apis/car.api";
import * as flightApi from "../../apis/flight.api";
import hotelApi from "../../apis/hotel.api";
import * as travelApi from "../../apis/travel.api";
import { ERROR } from "../../constants";
import { BusSearchCreationResponse } from "../../dtos/bus.dto";
import { buildBusSearchData } from "../../helpers/bus.helper";
import { buildCarCreationData } from "../../helpers/car.helper";
import { buildFlightData } from "../../helpers/flight.helper";
import { buildHotelData } from "../../helpers/hotel.helper";
import { buildTravelData } from "../../helpers/new-trip.helper";
import { BusAutosuggestItem, BusSearchForm } from "../../models/bus.model";
import { CarAutosuggestItem } from "../../models/car.model";
import { CustomError, HttpResponse } from "../../types";
import { FlightSearch, HotelSearch, CarSearch } from "./NewTrip.types";

export async function createFlightSearch(
  flightSearchData: FlightSearch,
  travelToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const buildedDto = buildFlightData(flightSearchData, travelToken);

    result.data = await flightApi.createNewFlight(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
}

export async function createHotelSearch(
  hotelSearchData: HotelSearch,
  travelToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const buildedDto = buildHotelData(hotelSearchData, travelToken);
    result.data = await hotelApi.createNewHotel(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
}

export async function createTravel(
  traveler: IBookingTarget
): Promise<{ data?: string; error?: CustomError }> {
  const result: { data?: string; error?: CustomError } = {};

  try {
    const buildedDto = buildTravelData(traveler);
    result.data = await travelApi.createNewTravel(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
}

export async function getCarLocationAutosuggestions(
  search: string
): Promise<{ data?: CarAutosuggestItem[]; error?: CustomError }> {
  const result: { data?: CarAutosuggestItem[]; error?: CustomError } = {};

  try {
    result.data = await carApi.getCarLocationAutosuggestions(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_CAR_LOCATION_AUTOSUGGESION_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_CAR_LOCATION_AUTOSUGGESION_ERROR;
    }
  }

  return result;
}

export async function createCarSearch(
  carSearchData: CarSearch,
  travelToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    const buildedDto = buildCarCreationData({ ...carSearchData, travelToken });
    result.data = await carApi.createCarSearch(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
}

export async function getBusAutosuggestResults(
  search: string
): Promise<{
  data?: BusAutosuggestItem[];
  error?: CustomError;
}> {
  const result: { data?: BusAutosuggestItem[]; error?: CustomError } = {};

  try {
    result.data = await busApi.getBusAutosuggestResults(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_BUS_AUTOSUGGEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_BUS_AUTOSUGGEST_ERROR;
    }
  }

  return result;
}

export async function createBusSearch(
  formData: BusSearchForm,
  travelToken: string
): Promise<{ data?: BusSearchCreationResponse; error?: CustomError }> {
  const result: { data?: BusSearchCreationResponse; error?: CustomError } = {};

  try {
    const buildedDto = buildBusSearchData(formData, travelToken);
    result.data = await busApi.createBusSearch(buildedDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECT_NEW_TRIP_SEARCH_ERROR;
    }
  }

  return result;
}

export async function getBookingTargets(
  search: string
): Promise<HttpResponse<IBookingTarget[]>> {
  const result: HttpResponse<IBookingTarget[]> = {};
  try {
    result.data = await bookerTargetsApi.getTravelBookingTargets(search);
  } catch (err) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    }
  }

  return result;
}
