import { CarCategory } from "~/apps/shared/constants/enums";

export const ALERT_TYPES = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
} as const;

export const APPROVAL_REVIEW_MESSAGES = {
  NO_AVAILABILITY_DECLINE:
    "Sua viagem foi negada automáticamente devido a uma falta de disponibilidade das seguintes ofertas:",
} as const;

export const APPROVAL_STATUS = {
  APPROVED: 2,
  DECLINED: 3,
  DOES_NOT_NEED_APPROVAL: 100,
  PENDING_APPROVAL_2: 99,
  PENDING_APPROVAL: 1,
  UNITIATED: 0,
} as const;

export const APPROVAL_TYPES = {
  AREA: "AREA",
  COMPANY: "COMPANY",
  COST_CENTER: "COST-CENTER",
  PROJECT: "PROJECT",
  USER: "USER",
} as const;

export const BOOKING_FAILED_REASON = {
  CARD_BLOCKED: "CARD_BLOCKED",
  CARD_LIMIT: "CARD_LIMIT",
  COMPLETE_UNAVAILABILITY_SOLDOUT: "COMPLETE_UNAVAILABILITY_SOLDOUT",
  CUSTOMER_REQUEST: "CUSTOMER_REQUEST",
  HOTEL_UNAVAILABLE: "HOTEL_UNAVAILABLE",
  NEGOTIATION_UNAVAILABLE: "NEGOTIATION_UNAVAILABLE",
  OFFER_INTERNAL_CONDITION_SOLDOUT: "OFFER_INTERNAL_CONDITION_SOLDOUT",
  OTHER: "OTHER",
  PRICE_CHANGE: "PRICE_CHANGE",
  PROCESSING_ERROR: "PROCESSING_ERROR",
} as const;

const BOOKING_FAILED_REASON_TRANSLATED = {
  [BOOKING_FAILED_REASON.CARD_BLOCKED]:
    "Cartão nos foi indicado como bloqueado",
  [BOOKING_FAILED_REASON.CARD_LIMIT]: "Limite do cartão foi excedido",
  [BOOKING_FAILED_REASON.CUSTOMER_REQUEST]: "O solicitante desistiu da compra",
  [BOOKING_FAILED_REASON.NEGOTIATION_UNAVAILABLE]:
    "Tarifa negociada não ofertada para data selecionada",
  [BOOKING_FAILED_REASON.PRICE_CHANGE]: "Aumentou de preço.",
  [BOOKING_FAILED_REASON.PROCESSING_ERROR]: "Outro",
} as const;

export const BOOKING_FAILED_REASON_BUS_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no ônibus selecionado",
} as const;

export const BOOKING_FAILED_REASON_CAR_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "A categoria do carro não está mais disponível na agência selecionada",
} as const;

export const BOOKING_FAILED_REASON_FLIGHT_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no voo selecionado.",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o voo está disponível.",
} as const;

export const BOOKING_FAILED_REASON_HOTEL_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "O quarto selecionado não está mais disponível",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o quarto ainda está disponível",
  [BOOKING_FAILED_REASON.HOTEL_UNAVAILABLE]: "Hotel sem disponibilidade",
} as const;

export const BOUND_RANGE_TYPES = {
  arrival: "arrival",
  departure: "departure",
  duration: "duration",
} as const;

export const BOUND_TYPES = {
  inbound: "inbound",
  outbound: "outbound",
} as const;

export const BUS_STRETCH_TYPE = {
  DEPARTURE: "departure",
  RETURN: "return",
} as const;

export const CANCEL_TYPES = {
  ALTERED: "altered",
  CREDIT: "credit",
  NOSHOW_CREDIT: "noshow-credit",
  NOSHOW_REFUND: "noshow-refund",
  PARTIALLY_ALTERED: "partially-altered",
  REFUND: "refund",
} as const;

export const CANCEL_TYPES_TRANSLATION = {
  [CANCEL_TYPES.ALTERED]: "alteração",
  [CANCEL_TYPES.CREDIT]: "crédito",
  [CANCEL_TYPES.NOSHOW_CREDIT]: "noshow",
  [CANCEL_TYPES.NOSHOW_REFUND]: "noshow",
  [CANCEL_TYPES.PARTIALLY_ALTERED]: "alteração parcial",
  [CANCEL_TYPES.REFUND]: "reembolso",
} as const;

export const COMPANY_AREA_TYPES = {
  AREA: "area",
  BOARD: "board",
} as const;

export const CABINS = {
  business: "business",
  economy: "economy",
  first: "first",
  premiumEconomy: "premium_economy",
} as const;

export const CARD_SERVICE_TYPES = {
  BUS: "bus",
  CAR: "car",
  FEE: "fee",
  FLIGHT: "flight",
  GENERAL: "general",
  HOTEL: "hotel",
  TERRESTRIAL: "terrestrial",
} as const;

export const CAR_POLICY_CATEGORY_TRANSLATION = {
  [CarCategory.ADVANCED]: "Avançada",
  [CarCategory.BASIC]: "Básica",
  [CarCategory.INTERMEDIATE]: "Intermediária",
  [CarCategory.SPECIAL]: "Especial",
} as const;

export const CAR_TRANSMISSION_TYPES = {
  AUTOMATIC: "automatic",
  MANUAL: "manual",
} as const;

export const COPY_STATUS = {
  PROCESSING: 1,
  SUCCESS: 2,
  PARTIAL_SUCCESS: 3,
  FAILURE: 4,
} as const;

export const DEFAULT_COMPANY_AREA_NAME = "Áreas" as const;

export const DEFAULT_COMPANY_BOARD_NAME = "Diretorias" as const;

export const DOCUMENT_TYPES = {
  ID: "id",
  PASSPORT: "passport",
  RG: "rg",
} as const;

export const DOCUMENT_TYPES_TRANSLATION = {
  [DOCUMENT_TYPES.ID]: "CPF",
  [DOCUMENT_TYPES.PASSPORT]: "Passaporte",
  [DOCUMENT_TYPES.RG]: "RG",
} as const;

export const FAVORITE_TYPES = {
  COMPANY: "c",
  GROUP: "g",
  USER: "u",
} as const;

export const FLIGHT_CABIN_CLASS_TRANSLATOR = {
  business: "Executiva",
  economy: "Econômica",
  first: "Primeira Classe",
  premium_economy: "Econômica Premium",
} as const;

export const FLIGHT_ORDER_TYPES = {
  CHEAPEST: "cheapest",
  EARLIEST_INBOUND_ARRIVAL: "earliest_inbound_arrival",
  EARLIEST_INBOUND_DEPARTURE: "earliest_inbound_departure",
  EARLIEST_OUTBOUND_ARRIVAL: "earliest_outbound_arrival",
  EARLIEST_OUTBOUND_DEPARTURE: "earliest_outbound_departure",
  FASTEST: "fastest",
  RECOMMENDED: "recommended",
} as const;

export const FLIGHT_ORDER_TYPES_TRANSLATOR = {
  [FLIGHT_ORDER_TYPES.CHEAPEST]: "Mais barato",
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL]: "Chegada mais cedo - Volta",
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE]: "Partida mais cedo - Volta",
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL]: "Chegada mais cedo - Ida",
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE]: "Partida mais cedo - Ida",
  [FLIGHT_ORDER_TYPES.FASTEST]: "Mais rápido",
  [FLIGHT_ORDER_TYPES.RECOMMENDED]: "Recomendado",
} as const;

export const HOTEL_SORT_OPTIONS = {
  DISTANCE: "DISTANCE",
  PRICE: "PRICE",
} as const;

export const LOYALTY_PROGRAMS = {
  latam_pass: "Latam Pass",
  smiles: "Smiles",
  tudo_azul: "Tudo Azul",
} as const;

export const MONTH_MAP: Record<number, string> = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez",
} as const;

export const NATIONAL_TYPES = {
  INTERNATIONAL: "international",
  NATIONAL: "national",
} as const;

export const PAYMENT_TYPES = {
  BILLED_SUPPLIER: "supplier_billed",
  BILLED: "billed",
  CREDIT_CARD: "credit_card",
  DEBT_CARD: "debt_card",
  MONEY: "money",
} as const;

export const PROJECT_ENABLING_STATE = {
  OFF: "off",
  OPTIONAL: "optional",
  REQUIRED: "required",
} as const;

export const RESERVE_STATUS = {
  FAILED: "FAILED",
  FINAL_PENDING: "FINAL_PENDING",
  PENDING: "PENDING",
  RESERVED: "RESERVED",
  UNITIATED: "UNITIATED",
} as const;

export const SEND_VOUCHER_EMAIL_SUCCESS_MESSAGE =
  "Requisição de email feita com sucesso. Em instantes você receberá um email com vouchers.";

export const SERVICE_TYPES = {
  bus: "bus",
  car: "car",
  flight: "flight",
  hotel: "hotel",
  insurance: "insurance",
  none: "",
} as const;

export const SEXES = [
  {
    label: "Masculino",
    value: "M",
  },
  {
    label: "Feminino",
    value: "F",
  },
] as const;

export const SUPPLIERS = {
  CLICKBUS: "CLICKBUS",
  ITERPEC: "ITERPEC",
  LOCALIZA: "LOCALIZA",
  MOVIDA: "MOVIDA",
  SKYTEAM: "SKYTEAM",
  TECH_TRAVEL: "TECH_TRAVEL",
} as const;

export const TARGET_TYPES = {
  COMPANY: "c",
  GROUP: "g",
  USER: "u",
} as const;

export const TRAVEL_NAME_PLACEHOLDERS = [
  "Reunião com o cliente ABC",
  "Treinamento comercial no RJ",
  "Visita ao cliente XYZ em SP",
  "Visita técnica urgente ao cliente XPTO",
] as const;

export const TRAVELS_PAGE_REDIRECT_MESSAGES = {
  APPROVAL_REQUEST_CANCELED: "A solicitação de aprovação foi cancelada",
  APPROVAL_REQUEST_NOT_PENDING:
    "A viagem selecionada já teve sua revisão realizada.",
  CANNOT_ACCESS_PAYMENT:
    "Você não possui permissão para processar o pagamento da viagem.",
  TRAVEL_STATUS_PAYMENT_NOT_ALLOWED:
    "Reservas não podem ser realizadas no status atual da viagem.",
  USER_NOT_APPROVER: "Você não possui permissão para revisar esta viagem.",
  USER_NOT_STAGE_APPROVER:
    "Você não possui permissão para revisar esta etapa. É possível que sua etapa já tenha sido revisada por outro aprovador",
} as const;

export const USER_ROLES = {
  admin: "ROLE_ADMIN",
  customized: "CUSTOMIZED",
  manager: "ROLE_MANAGER",
  self_traveler: "ROLE_SELF_TRAVELER",
  super: "ROLE_SUPERADMIN",
  traveler: "ROLE_TRAVELER",
  user: "ROLE_USER",
} as const;
