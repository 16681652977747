import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    "& > svg": {
      strokeWidth: 2,
    },
  }),
  root: css({
    borderColor: theme.colors.blue[700],
    borderRadius: "6px",
    fontWeight: 500,
    gap: "8px",
    padding: "4px",
    ":hover": {
      backgroundColor: theme.colors.blue[500],
    },
  }),
};
