import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "8px 0",
    width: "100%",
  }),
  form: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    select: {
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  optional: css({
    color: theme.colors.gray[100],
    fontSize: "0.875rem",
  }),
};
