import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    amenity: {
      icon: css({
        color: theme.colors.green[500],
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      paddingBottom: "24px",
    }),
  },
  header: {
    close: css({
      backgroundColor: "transparent",
      border: "none",
      right: "12px",
      position: "absolute",
      top: "12px",
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      padding: "24px 24px 12px 24px",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      lineHeight: "18px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    maxWidth: "512px",
  }),
};
