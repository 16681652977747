import { Image } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import React from "react";

import { useImageBuffer } from "./use-image-buffer";

type Props = React.ComponentProps<typeof Image> & {
  fallbackSrc: string;
  fallbackStyle?: Style | Style[];
  src?: string;
};

export const PDFRemoteImage: React.FC<Props> = ({
  fallbackSrc,
  fallbackStyle,
  src,
  style,
}) => {
  const imageBuffer = useImageBuffer(src);

  if (!!imageBuffer && !!src) {
    return (
      <Image
        cache={("true" as unknown) as boolean}
        source={{
          data: imageBuffer,
          format: "png",
        }}
        style={style}
      />
    );
  }

  if (fallbackSrc) {
    return (
      <Image src={fallbackSrc} style={fallbackStyle ? fallbackStyle : style} />
    );
  }

  return null;
};
