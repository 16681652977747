import React, { useMemo } from "react";
import * as utils from "@helpers";
import { useHotelInfoContext } from "../HotelInfo.context";
import { Paper } from "../../shared/Paper";
import { PaperTitle } from "../../shared/PaperTitle";
import { Flex, Box } from "@toolkit";
import { StyledTable } from "../../shared/StyledTable";
import { Column } from "react-table";
import orderBy from "lodash/orderBy";
import { SpentByDestinationChart } from "./SpentByDestinationChart";

const SpentByDestination = () => {
  const {
    hotelInfo: { spentByDestination }
  } = useHotelInfoContext();
  const sortedData = useMemo(
    () => orderBy(spentByDestination, "total", "desc"),
    [spentByDestination]
  );
  const tableColumns: Column[] = useMemo(
    () => [
      {
        Header: "Destino",
        accessor: "destination"
      },
      {
        Header: "Qtd.",
        accessor: "count"
      },
      {
        Header: "Total gasto",
        accessor: "total",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Valor médio diária",
        accessor: "nightAverage",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Média de diárias",
        accessor: "durationAverage",
        Cell: props => `${props.value} dia(s)`
      }
    ],
    []
  );

  return (
    <Paper>
      <PaperTitle>Gasto por destino</PaperTitle>
      <Flex
        flexDirection={["column-reverse", "column-reverse", "row"]}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box width={["100%", "100%", "66%"]} minHeight={[0, 0, 220]}>
          <StyledTable data={sortedData} columns={tableColumns} />
        </Box>
        <Box width={["100%", "100%", "33%"]} mb={[24, 24, 0]}>
          <SpentByDestinationChart data={sortedData} />
        </Box>
      </Flex>
    </Paper>
  );
};

export { SpentByDestination };
