import React from "react";
import RoomIcon from "@material-ui/icons/HotelRounded";
import TreatmentIcon from "@material-ui/icons/RoomServiceRounded";
import LocationIcon from "@material-ui/icons/RoomRounded";
import WifiIcon from "@material-ui/icons/WifiRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { Box, Flex, Text } from "@toolkit";
import { css } from "@styled-system/css";
import PropTypes from "prop-types";

const styles = {
  icon: css({
    color: "success",
    fontSize: [20],
    mr: "xsmall"
  }),
  fontIcon: css({
    color: "success",
    size: [20],
    mr: "xsmall",
    position: "relative",
    left: "-3px",
    top: [0, "3px"]
  })
};

const ReviewGrades = ({
  roomGrade,
  treatmentGrade,
  cleaningGrade,
  locationGrade,
  wifiGrade
}) => {
  return (
    <Box>
      <Flex flexDirection={["row", "column"]}>
        <Flex alignItems="center" mb={[0, "medium"]} mr={["medium", 0]}>
          <Box mr="medium">
            <Flex alignItems="center">
              <Tooltip title="Acomodações" aria-label="acomodações">
                <RoomIcon css={styles.icon} />
              </Tooltip>
              <Text fontSize={[2, 18]} fontWeight="bold">
                {roomGrade}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center">
              <Tooltip title="Atendimento" aria-label="atendimento">
                <TreatmentIcon css={styles.icon} />
              </Tooltip>
              <Text fontSize={[2, 18]} fontWeight="bold">
                {treatmentGrade}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center" mb={[0, "medium"]} mr={["medium", 0]}>
          <Box mr="medium">
            <Flex alignItems="center">
              <Tooltip title="Limpeza" aria-label="limpeza">
                <Text className="icon-cleaning" css={styles.fontIcon} />
              </Tooltip>
              <Text fontSize={[2, 18]} fontWeight="bold">
                {cleaningGrade}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center">
              <Tooltip title="Localização" aria-label="localização">
                <LocationIcon css={styles.icon} />
              </Tooltip>
              <Text fontSize={[2, 18]} fontWeight="bold">
                {locationGrade}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Box>
            <Flex alignItems="center">
              <Tooltip title="Wifi" aria-label="wifi">
                <WifiIcon css={styles.icon} />
              </Tooltip>
              <Text fontSize={[2, 18]} fontWeight="bold">
                {wifiGrade}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

ReviewGrades.propTypes = {
  roomGrade: PropTypes.number,
  treatmentGrade: PropTypes.number,
  cleaningGrade: PropTypes.number,
  locationGrade: PropTypes.number,
  wifiGrade: PropTypes.number
};

export { ReviewGrades };
