import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import {
  ItineraryDocumentPendencies,
  ItineraryDocumentPendenciesModelFactory,
  ItineraryPendencies,
  ItineraryPendenciesModel,
  ItineraryPendenciesModelFactory,
} from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import { ItineraryServicesModel } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import { OfferTraveler } from "~/apps/corporate/models/offer.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { DocumentTarget } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { CreateDocumentDto } from "~/apps/shared/dtos/documents.dto";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Document } from "~/apps/shared/models/documents.model";
import { Error } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";
import { sleep } from "~/apps/shared/utils/sleep";

import { useItinerary } from "./itinerary.context";
import * as itineraryService from "./itinerary.service";

interface Actions {
  fetchItineraryPendencies: () => Promise<ItineraryPendenciesModel | null>;
  fetchOffersTravelers: (
    servicesModel: ItineraryServicesModel,
  ) => Promise<Record<string, OfferTraveler[]> | null>;
  resolveAllPendencies: () => void;
  saveCategorizationAndProceed: (data: {
    billingProfileToken: string | null;
    companyAreaToken: string | null;
    costCenterToken: string | null;
    projectToken: string | null;
  }) => Promise<boolean>;
  saveDocumentationAndProceed: (
    data: {
      documents: CreateDocumentDto[];
    },
    target: DocumentTarget,
  ) => Promise<boolean>;
  saveNotEnoughTravelers: (
    data: Record<
      string,
      {
        main: number;
        userToken: string;
      }[]
    >,
    servicesModel: ItineraryServicesModel,
  ) => Promise<boolean>;
  saveNotEnoughTravelersAndProceed: (
    data: Record<
      string,
      {
        main: number;
        userToken: string;
      }[]
    >,
    servicesModel: ItineraryServicesModel,
  ) => Promise<boolean>;
  saveOffersJustificationAndProceed: (
    data: {
      justification: string;
      offerToken: string;
    }[],
  ) => Promise<boolean>;
  saveTravelPurposesAndProceed: (data: {
    flightPurposeToken: string | null;
    hotelPurposeToken: string | null;
    tripPurposeToken: string | null;
  }) => Promise<boolean>;
  saveTravelerPhoneAndProceed: (data: {
    phone: string;
    travelerToken: string;
  }) => Promise<boolean>;
}

type State = {
  errorOnFetch: Error | null;
  errorOnFetchOffersTravelers: Error | null;
  isLoading: boolean;
  isLoadingOffersTravelers: boolean;
  offersTravelers: Record<string, OfferTraveler[]> | null;
  pendencies: {
    isCategorizationResolved: boolean;
    isDocumentationResolved: boolean;
    isNotEnoughTravelersResolved: boolean;
    isOffersJustificationResolved: boolean;
    isTravelerPhoneResolved: boolean;
    isTravelPurposesResolved: boolean;
    itineraryDocumentPendencies: ItineraryDocumentPendencies;
    itineraryPendencies: ItineraryPendencies;
  } | null;
  recentlyCreatedDocuments: Document[];
};

const initialState: State = {
  errorOnFetch: null,
  errorOnFetchOffersTravelers: null,
  isLoading: false,
  isLoadingOffersTravelers: false,
  offersTravelers: null,
  pendencies: null,
  recentlyCreatedDocuments: [],
};

const ItineraryPendenciesContext = createContext<Actions & State>({
  ...initialState,
  fetchItineraryPendencies: async () => {
    return null;
  },
  fetchOffersTravelers: async () => {
    return null;
  },
  resolveAllPendencies: () => {
    return;
  },
  saveCategorizationAndProceed: async () => {
    return false;
  },
  saveDocumentationAndProceed: async () => {
    return false;
  },
  saveNotEnoughTravelers: async () => {
    return false;
  },
  saveNotEnoughTravelersAndProceed: async () => {
    return false;
  },
  saveOffersJustificationAndProceed: async () => {
    return false;
  },
  saveTravelPurposesAndProceed: async () => {
    return false;
  },
  saveTravelerPhoneAndProceed: async () => {
    return false;
  },
});

export const ItineraryPendenciesProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const { travelToken } = useItinerary();

  const [state, setState] = useState(initialState);

  const fetchItineraryPendencies = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const [
      itineraryPendenciesResponse,
      itineraryDocumentPendenciesResponse,
    ] = await Promise.all([
      itineraryService.getItineraryPendencies(travelToken),
      itineraryService.getItineraryDocumentPendencies(travelToken),
      sleep(3000),
    ]);

    if (itineraryPendenciesResponse.isFailure()) {
      const error = itineraryPendenciesResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    if (itineraryDocumentPendenciesResponse.isFailure()) {
      const error = itineraryDocumentPendenciesResponse.data;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    let isCategorizationResolved = false;
    let isDocumentationResolved = false;
    let isNotEnoughTravelersResolved = false;
    let isOffersJustificationResolved = false;
    let isTravelPurposesResolved = false;
    let isTravelerPhoneResolved = false;

    setState((prev) => {
      const { pendencies } = prev;

      if (pendencies) {
        isCategorizationResolved = pendencies.isCategorizationResolved;
        isDocumentationResolved = pendencies.isDocumentationResolved;
        isNotEnoughTravelersResolved = pendencies.isNotEnoughTravelersResolved;
        isOffersJustificationResolved =
          pendencies.isOffersJustificationResolved;
        isTravelPurposesResolved = pendencies.isTravelPurposesResolved;
        isTravelerPhoneResolved = pendencies.isTravelerPhoneResolved;
      }

      return {
        ...prev,
        isLoading: false,
        pendencies: {
          isCategorizationResolved,
          isDocumentationResolved,
          isNotEnoughTravelersResolved,
          isOffersJustificationResolved,
          isTravelPurposesResolved,
          isTravelerPhoneResolved,
          itineraryDocumentPendencies: itineraryDocumentPendenciesResponse.data,
          itineraryPendencies: itineraryPendenciesResponse.data,
        },
      };
    });

    const itineraryPendenciesModel = ItineraryPendenciesModelFactory.create({
      isCategorizationResolved,
      isDocumentationResolved,
      isNotEnoughTravelersResolved,
      isOffersJustificationResolved,
      isTravelPurposesResolved,
      isTravelerPhoneResolved,
      itineraryDocumentPendencies: itineraryDocumentPendenciesResponse.data,
      itineraryPendencies: itineraryPendenciesResponse.data,
    });

    return itineraryPendenciesModel;
  }, [showSnackMessage, travelToken]);

  const fetchOffersTravelers = useCallback(
    async (servicesModel: ItineraryServicesModel) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchOffersTravelers: null,
        isLoadingOffersTravelers: true,
      }));

      const processableServicesWithPotentialMultipleTravelers = servicesModel.getProcessableServicesWithPotentialMultipleTravelers();

      const processableServicesWithPotentialMultipleTravelersOfferTokens = processableServicesWithPotentialMultipleTravelers.map(
        (service) => service.getOfferToken(),
      );

      const offersTravelersResponse = await itineraryService.getOffersTravelers(
        processableServicesWithPotentialMultipleTravelersOfferTokens,
      );

      if (offersTravelersResponse.error) {
        const error = offersTravelersResponse.error;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          errorOnFetchOffersTravelers: error,
          isLoadingOffersTravelers: false,
        }));

        return null;
      }

      if (!offersTravelersResponse.data) {
        const error = ERROR.UNEXPECTED;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          errorOnFetchOffersTravelers: error,
          isLoadingOffersTravelers: false,
        }));

        return null;
      }

      const offersTravelers = offersTravelersResponse.data;

      setState((prev) => ({
        ...prev,
        isLoadingOffersTravelers: false,
        offersTravelers,
      }));

      return offersTravelers;
    },
    [showSnackMessage],
  );

  const resolveAllPendencies = useCallback(() => {
    const { pendencies } = state;

    if (!pendencies) {
      logger.error("pendencies not found.");

      return;
    }

    let pendenciesModel = ItineraryPendenciesModelFactory.create(pendencies);
    pendenciesModel = pendenciesModel.resolveAllPendencies();

    setState((prev) => ({
      ...prev,
      pendencies: {
        ...pendenciesModel.toObject(),
      },
    }));
  }, [state.pendencies]);

  const saveCategorizationAndProceed = useCallback(
    async (data: {
      billingProfileToken: string | null;
      companyAreaToken: string | null;
      costCenterToken: string | null;
      projectToken: string | null;
    }) => {
      const { error } = await itineraryService.updateTravelAdministrativeInfo(
        {
          ...(data.billingProfileToken && {
            billing_profile_token: data.billingProfileToken,
          }),
          ...(data.companyAreaToken && {
            area_token: data.companyAreaToken,
          }),
          ...(data.costCenterToken && {
            cost_center_token: data.costCenterToken,
          }),
          ...(data.projectToken && { project_token: data.projectToken }),
        },
        travelToken,
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      const { pendencies } = state;

      if (pendencies) {
        let pendenciesModel = ItineraryPendenciesModelFactory.create(
          pendencies,
        );
        pendenciesModel = pendenciesModel.resolveCategorizationPendencies();

        setState((prev) => ({
          ...prev,
          pendencies: pendenciesModel.toObject(),
        }));
      }

      return true;
    },
    [showSnackMessage, state.pendencies, travelToken],
  );

  const saveDocumentationAndProceed = useCallback(
    async (
      data: { documents: CreateDocumentDto[] },
      target: DocumentTarget,
    ) => {
      const { pendencies, recentlyCreatedDocuments } = state;

      if (!pendencies) {
        logger.error("pendencies not found.");

        return false;
      }

      const { documents } = data;

      const deleteRecentlyCreatedDocumentsToAvoidDuplication = async () => {
        await Promise.all(
          recentlyCreatedDocuments.map((recentlyCreatedDocument) => {
            return itineraryService.deleteDocument(
              recentlyCreatedDocument.documentToken,
            );
          }),
        );
      };

      await deleteRecentlyCreatedDocumentsToAvoidDuplication();

      const createDocumentResponses = await Promise.all(
        documents.map((document) => {
          return itineraryService.createDocument(document);
        }),
      );

      if (createDocumentResponses.some(({ error }) => !!error)) {
        createDocumentResponses.forEach(({ error }) => {
          if (error) {
            showSnackMessage(error.description, ALERT_TYPES.ERROR);
          }
        });

        return false;
      }

      const createdDocuments = createDocumentResponses
        .filter((createDocumentResponse) => !!createDocumentResponse.data)
        .map((createDocumentResponse) => createDocumentResponse.data!);

      const itineraryDocumentPendenciesResponse = await itineraryService.getItineraryDocumentPendencies(
        travelToken,
      );

      if (itineraryDocumentPendenciesResponse.isFailure()) {
        const error = itineraryDocumentPendenciesResponse.data;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      const itineraryDocumentPendencies =
        itineraryDocumentPendenciesResponse.data;

      const itineraryDocumentPendenciesModel = ItineraryDocumentPendenciesModelFactory.create(
        itineraryDocumentPendencies,
      );

      let pendenciesModel = ItineraryPendenciesModelFactory.create(pendencies);

      if (itineraryDocumentPendenciesModel.isPendingForTarget(target)) {
        pendenciesModel = pendenciesModel.updateItineraryDocumentPendencies(
          itineraryDocumentPendenciesModel,
        );

        setState((prev) => ({
          ...prev,
          pendencies: {
            ...pendenciesModel.toObject(),
          },
          recentlyCreatedDocuments: createdDocuments,
        }));

        return false;
      }

      pendenciesModel = pendenciesModel.resolveDocumentationPendencies();

      setState((prev) => ({
        ...prev,
        pendencies: {
          ...pendenciesModel.toObject(),
        },
        recentlyCreatedDocuments: createdDocuments,
      }));

      return true;
    },
    [
      showSnackMessage,
      state.pendencies,
      state.recentlyCreatedDocuments,
      travelToken,
    ],
  );

  const saveNotEnoughTravelers = useCallback(
    async (
      data: Record<
        string,
        {
          main: number;
          userToken: string;
        }[]
      >,
      servicesModel: ItineraryServicesModel,
    ) => {
      const { offersTravelers } = state;

      if (!offersTravelers) {
        logger.error("offers travelers not found.");

        return false;
      }

      const removeNonMainTravelersFromOffers = async () => {
        const removeTravelersFromOfferResponses = await Promise.all(
          Object.entries(offersTravelers).map(([k, v]) =>
            itineraryService.removeTravelersFromOffer(
              k,
              v
                .filter((offerTraveler) => offerTraveler.main === 0)
                .map((offerTraveler) => offerTraveler.travelerOfferToken),
            ),
          ),
        );

        if (removeTravelersFromOfferResponses.some(({ error }) => !!error)) {
          removeTravelersFromOfferResponses.forEach(({ error }) => {
            if (error) {
              showSnackMessage(error.description, ALERT_TYPES.ERROR);
            }
          });

          return false;
        }

        return true;
      };

      if (!(await removeNonMainTravelersFromOffers())) {
        return false;
      }

      const addTravelersToOfferResponses = await Promise.all(
        Object.entries(data).map(([k, v]) =>
          itineraryService.addTravelersToOffer(k, v),
        ),
      );

      if (addTravelersToOfferResponses.some(({ error }) => !!error)) {
        addTravelersToOfferResponses.forEach(({ error }) => {
          if (error) {
            showSnackMessage(error.description, ALERT_TYPES.ERROR);
          }
        });

        return false;
      }

      await fetchOffersTravelers(servicesModel);

      return true;
    },
    [fetchOffersTravelers, showSnackMessage, state.offersTravelers],
  );

  const saveNotEnoughTravelersAndProceed = useCallback(
    async (
      data: Record<
        string,
        {
          main: number;
          userToken: string;
        }[]
      >,
      servicesModel: ItineraryServicesModel,
    ) => {
      const { offersTravelers, pendencies } = state;

      if (!offersTravelers || !pendencies) {
        logger.error("offers travelers or pendencies not found.");

        return false;
      }

      if (!(await saveNotEnoughTravelers(data, servicesModel))) {
        return false;
      }

      let pendenciesModel = ItineraryPendenciesModelFactory.create(pendencies);
      pendenciesModel = pendenciesModel.resolveNotEnoughTravelersPendencies();

      setState((prev) => ({
        ...prev,
        pendencies: {
          ...pendenciesModel.toObject(),
        },
      }));

      return true;
    },
    [saveNotEnoughTravelers, showSnackMessage, state.pendencies],
  );

  const saveOffersJustificationAndProceed = useCallback(
    async (
      data: {
        justification: string;
        offerToken: string;
      }[],
    ) => {
      const { pendencies } = state;

      if (!pendencies) {
        logger.error("pendencies not found.");

        return false;
      }

      const justifiedOffers = await Promise.all(
        data.map(({ justification, offerToken }) => {
          return itineraryService.addOfferJustification(offerToken, {
            justification,
          });
        }),
      );

      if (justifiedOffers.every(({ error }) => !error)) {
        let pendenciesModel = ItineraryPendenciesModelFactory.create(
          pendencies,
        );
        pendenciesModel = pendenciesModel.resolveOffersJustificationPendencies();

        setState((prev) => ({
          ...prev,
          pendencies: {
            ...pendenciesModel.toObject(),
          },
        }));

        return true;
      }

      justifiedOffers.forEach(({ error }) => {
        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);
        }
      });

      return false;
    },
    [state.pendencies, showSnackMessage],
  );

  const saveTravelPurposesAndProceed = useCallback(
    async (data: {
      flightPurposeToken: string | null;
      hotelPurposeToken: string | null;
      tripPurposeToken: string | null;
    }) => {
      const { pendencies } = state;

      if (!pendencies) {
        logger.error("pendencies not found.");

        return false;
      }

      const { error } = await itineraryService.updateTravelInfo(
        {
          ...(data.flightPurposeToken && {
            flight_purpose_token: data.flightPurposeToken,
          }),
          ...(data.hotelPurposeToken && {
            hotel_purpose_token: data.hotelPurposeToken,
          }),
          ...(data.tripPurposeToken && {
            trip_purpose_token: data.tripPurposeToken,
          }),
        },
        travelToken,
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      let pendenciesModel = ItineraryPendenciesModelFactory.create(pendencies);
      pendenciesModel = pendenciesModel.resolveTravelPurposes();

      setState((prev) => ({
        ...prev,
        pendencies: {
          ...pendenciesModel.toObject(),
        },
      }));

      return true;
    },
    [state.pendencies, showSnackMessage, travelToken],
  );

  const saveTravelerPhoneAndProceed = useCallback(
    async (data: { phone: string; travelerToken: string }) => {
      const { pendencies } = state;

      if (!pendencies || !user) {
        logger.error("pendencies not found.");

        return false;
      }

      const { error } = await itineraryService.editUser(
        {
          ...user.toObject(),
          phone: data.phone,
        },
        data.travelerToken,
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      let pendenciesModel = ItineraryPendenciesModelFactory.create(pendencies);
      pendenciesModel = pendenciesModel.resolveTravelerPhonePendencies();

      setState((prev) => ({
        ...prev,
        pendencies: {
          ...pendenciesModel.toObject(),
        },
      }));

      return true;
    },
    [state.pendencies, showSnackMessage, user],
  );

  return (
    <ItineraryPendenciesContext.Provider
      value={{
        ...state,
        fetchItineraryPendencies,
        fetchOffersTravelers,
        resolveAllPendencies,
        saveCategorizationAndProceed,
        saveDocumentationAndProceed,
        saveNotEnoughTravelers,
        saveNotEnoughTravelersAndProceed,
        saveOffersJustificationAndProceed,
        saveTravelPurposesAndProceed,
        saveTravelerPhoneAndProceed,
      }}
    >
      {children}
    </ItineraryPendenciesContext.Provider>
  );
};

export const useItineraryPendencies = useContextFactory(
  "ItineraryPendenciesContext",
  ItineraryPendenciesContext,
);
