import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px 24px 24px 24px",
    }),
  },
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "0 auto",
    width: "calc(100% - 24px * 2)",
  }),
  header: {
    container: css({
      display: "flex",
      gap: "24px",
      padding: "16px 24px",
      justifyContent: "space-between",
      width: "100%",
    }),
    left: {
      description: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        textAlign: "left",
        width: "fit-content",
      }),
      root: css({
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        fontFamily: theme.fonts.inter,
        gap: "4px",
        width: "100%",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        width: "fit-content",
      }),
    },
    right: {
      chevron: css({
        color: theme.colors.gray[700],
      }),
      root: css({
        display: "flex",
        flexShrink: 0,
        gap: "8px",
        height: "fit-content",
      }),
    },
    root: ({ isDisabled }: { isDisabled?: boolean }) =>
      css({
        backgroundColor: "transparent",
        border: "none",
        cursor: !isDisabled ? "pointer" : "unset",
        display: "flex",
        flexDirection: "column",
        padding: "8px 0",
        width: "100%",
      }),
  },
  root: ({ isResolved }: { isResolved?: boolean }) =>
    css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderLeft: `2px solid ${isResolved ? theme.colors.green[500] : "unset"}`,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      width: "100%",
    }),
};
