import React from "react";

import CloseIcon from "@material-ui/icons/CloseRounded";
import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Flex } from "smartrips-toolkit";

import { Button } from "@toolkit/v2";

import { Row } from "@components/shared/layout";

import { useAreas } from "../../areas.context";
import * as areasService from "../../areas.service";
import { areaFormSchema } from "./area-form.schema";
import { styles } from "./styles";

type AreaFormProps = {
  handleCloseDrawer: () => void;
};

export const AreaForm: React.FC<AreaFormProps> = ({ handleCloseDrawer }) => {
  const { companyAreaDisplay, handleSaveArea, selectedArea } = useAreas();

  return (
    <div css={styles.root}>
      <DrawerHeader onClose={handleCloseDrawer}>
        {selectedArea?.companyAreaToken
          ? `Editar ${companyAreaDisplay}`
          : `Adicionar nova ${companyAreaDisplay}`}
      </DrawerHeader>
      <AreaFormContainer
        handleCloseDrawer={handleCloseDrawer}
        onSubmit={handleSaveArea}
        selectedArea={selectedArea}
      />
    </div>
  );
};

type AreaFormContainerProps = {
  handleCloseDrawer: () => void;
  onSubmit: (data: { areaName: string }) => void;
  selectedArea: Exclude<
    ReturnType<typeof useAreas>["selectedArea"],
    null
  > | null;
};

const AreaFormContainer: React.FC<AreaFormContainerProps> = ({
  handleCloseDrawer,
  onSubmit,
  selectedArea,
}) => {
  const {
    companyAreaDisplay,
    handleAddUserToArea,
    handleRemoveUserFromArea,
  } = useAreas();

  const form = useForm({
    defaultValues: { areaName: selectedArea?.name || "" },
    onSubmit,
    schema: areaFormSchema,
  });

  return (
    <Form css={styles.form.root} context={form}>
      <div css={styles.form.body.root}>
        <div css={styles.form.input.root}>
          <span css={styles.form.input.label}>
            Nome da {companyAreaDisplay}
          </span>
          <Form.Field
            as={<Input placeholder={`Nome da ${companyAreaDisplay}`} />}
            css={styles.form.input.input}
            name="areaName"
          />
          <InputErrorMessage>
            {form.errors["areaName"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.form.input.root}>
          <span css={styles.form.input.label}>
            Adicionar usuários a {companyAreaDisplay}
          </span>
          <AsyncAutocomplete
            clearOnSelect
            fetchItems={areasService.fetchUsers}
            filterItems={(items) =>
              selectedArea?.users
                ? items.filter(
                    (item) =>
                      !selectedArea.users.find(
                        (user) => user.userToken === item.userToken,
                      ),
                  )
                : items
            }
            inputProps={{
              placeholder: "Procure por usuários...",
            }}
            onSelect={handleAddUserToArea}
            renderItem={({ isHighlighted, item }) => {
              if (item.error) {
                return (
                  <li
                    css={styles.autocomplete.item.root({
                      isDisabled: true,
                      isHighlighted: false,
                    })}
                  >
                    Nenhum resultado.
                  </li>
                );
              }

              return (
                <li css={styles.autocomplete.item.root({ isHighlighted })}>
                  <span>{item.label}</span>
                  <span css={styles.autocomplete.item.email}>{item.email}</span>
                </li>
              );
            }}
          />
        </div>
        {!selectedArea?.users || selectedArea.users.length === 0 ? (
          <span>Nenhum usuário participando desta política.</span>
        ) : (
          <Flex css={styles.form.users.list}>
            {selectedArea.users.map((user) => (
              <Row css={styles.form.users.item.root} key={user.userToken}>
                <p>{user.fullName}</p>
                <div
                  css={styles.form.users.item.iconContainer}
                  onClick={() => {
                    handleRemoveUserFromArea(user);
                  }}
                >
                  <CloseIcon css={styles.form.users.item.icon} />
                </div>
              </Row>
            ))}
          </Flex>
        )}
      </div>
      <footer css={styles.footer.root}>
        <Button type="submit">Salvar</Button>
        <Button fill="outlined" onClick={handleCloseDrawer} type="button">
          Cancelar
        </Button>
      </footer>
    </Form>
  );
};
