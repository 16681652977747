import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { DocumentTarget, DocumentType } from "~/apps/shared/constants/enums";
import { logger } from "~/apps/shared/utils/logger";

import { Button, Switch } from "@toolkit/v2";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";
import { DocumentationAccordionCPFForm } from "./documentation-accordion-cpf-form/documentation-accordion-cpf-form";
import { DocumentationAccordionPassportForm } from "./documentation-accordion-passport-form/documentation-accordion-passport-form";
import { DocumentationAccordionRGForm } from "./documentation-accordion-rg-form/documentation-accordion-rg-form";
import { useDocumentationAccordionPresenter } from "./documentation-accordion.hooks";
import {
  DocumentationAccordionSchema,
  documentationAccordionSchema,
} from "./documentation-accordion.schema";
import { styles } from "./styles";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const DocumentationAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const {
    documentationAccordionPresenter,
  } = useDocumentationAccordionPresenter();

  const { fetchUserDocuments } = useDocuments();

  const { pendencies, saveDocumentationAndProceed } = useItineraryPendencies();

  const [target, setTarget] = useState<DocumentTarget | null>(null);

  const hasSetDefaultValuesOnce = useRef(false);

  const form = useForm<DocumentationAccordionSchema>({
    defaultValues: {
      documents: [] as DocumentationAccordionSchema["documents"],
    } as DocumentationAccordionSchema,
    onSubmit: async () => {
      if (!target) {
        logger.error(
          "tried saving dcumentation and proceeding, but target is not defined",
        );

        return;
      }

      const values = form.getValues();

      const success = await saveDocumentationAndProceed(
        {
          documents: values.documents,
        },
        target,
      );

      if (!success) {
        return;
      }

      void fetchUserDocuments();

      next();
    },
    schema: documentationAccordionSchema,
  });

  const handleDocumentTargetSwitch = useCallback(() => {
    if (!documentationAccordionPresenter) {
      return;
    }

    const updatedTarget =
      target === DocumentTarget.BRAZILIAN
        ? DocumentTarget.FOREIGNER
        : DocumentTarget.BRAZILIAN;

    form.reset();
    form.setValue(
      "documents",
      documentationAccordionPresenter.calculateDefaultValue({
        target: updatedTarget,
      }),
    );

    setTarget(updatedTarget);
  }, [documentationAccordionPresenter, form]);

  useEffect(() => {
    if (hasSetDefaultValuesOnce.current) {
      return;
    }

    if (!documentationAccordionPresenter || !!target) {
      return;
    }

    hasSetDefaultValuesOnce.current = true;

    if (documentationAccordionPresenter.isTravelerUnidentified()) {
      const updatedTarget = DocumentTarget.BRAZILIAN;

      setTarget(updatedTarget);

      form.reset();
      form.setValue(
        "documents",
        documentationAccordionPresenter.calculateDefaultValue({
          target: updatedTarget,
        }),
      );

      return;
    }

    const updatedTarget = documentationAccordionPresenter.getTravelerIdentification();

    form.reset();
    form.setValue(
      "documents",
      documentationAccordionPresenter.calculateDefaultValue({
        target: updatedTarget,
      }),
    );

    setTarget(updatedTarget);
  }, [documentationAccordionPresenter, form, hasSetDefaultValuesOnce, target]);

  if (!documentationAccordionPresenter || !pendencies || !target) {
    return null;
  }

  const documentsPendingForTarget = documentationAccordionPresenter.getDocumentsPendingForTarget(
    target,
  );

  if (documentsPendingForTarget.length === 0) {
    return null;
  }

  const isResolved = pendencies.isDocumentationResolved;

  return (
    <PendenciesAccordion
      description="Informe o documento do viajante atualizado."
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={
        <>
          {index}. Documentação.
          {documentationAccordionPresenter.isDocumentationRequired() ? (
            "*"
          ) : (
            <span css={styles.optional}> (opcional)</span>
          )}
        </>
      }
      toggleExpanded={toggleExpanded}
    >
      {documentationAccordionPresenter.showDocumentTargetSwitch() ? (
        <label css={styles.switch.root}>
          <Switch
            active={target === DocumentTarget.BRAZILIAN}
            onChange={() => {
              handleDocumentTargetSwitch();
            }}
            variant="pink"
          />
          <span css={styles.switch.label}>O viajante possui CPF?</span>
        </label>
      ) : null}
      <Form context={form} css={styles.form.root}>
        {documentsPendingForTarget.map((documentPending, index) => {
          const indexedErrors = form.errors["documents"]?.[index];
          const indexedName = `documents[${index}]`;

          return (
            <React.Fragment key={documentPending.type}>
              {index > 0 ? <hr css={styles.divisor} /> : null}
              {documentPending.type === DocumentType.CPF ? (
                <DocumentationAccordionCPFForm
                  documentPending={documentPending}
                  errors={indexedErrors}
                  form={form}
                  namePrefix={indexedName}
                />
              ) : null}
              {documentPending.type === DocumentType.RG ? (
                <DocumentationAccordionRGForm
                  documentPending={documentPending}
                  errors={indexedErrors}
                  form={form}
                  namePrefix={indexedName}
                />
              ) : null}
              {documentPending.type === DocumentType.PASSPORT ? (
                <DocumentationAccordionPassportForm
                  documentPending={documentPending}
                  errors={indexedErrors}
                  form={form}
                  namePrefix={indexedName}
                />
              ) : null}
            </React.Fragment>
          );
        })}
        <Button
          css={styles.button}
          disabled={form.formState.isSubmitting || isLoadingNext}
        >
          Salvar
        </Button>
      </Form>
    </PendenciesAccordion>
  );
};
