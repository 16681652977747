import React from "react";

import { Form } from "~/apps/shared/components/form/form";
import { UseFormMethods } from "~/apps/shared/components/form/hooks/use-form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";
import { ThreeWayDateSelect } from "~/apps/shared/components/three-way-date-select/three-way-date-select";
import { DOCUMENT_TYPES_TRANSLATION, SEXES } from "~/apps/shared/constants";
import { COUNTRY_LIST } from "~/apps/shared/constants/countries.constant";
import { DocumentType } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

const countries = COUNTRY_LIST.map((country) => ({
  label: country.name,
  value: country.code,
}));

type Props = {
  documentPending: {
    type: DocumentType;
  };
  errors?: UseFormMethods["errors"];
  form: UseFormMethods;
  namePrefix: string;
};

export const DocumentationAccordionPassportForm: React.FC<Props> = ({
  documentPending,
  errors,
  form,
  namePrefix,
}) => {
  const translatedDocumentType =
    DOCUMENT_TYPES_TRANSLATION[documentPending.type];

  return (
    <>
      <Form.Field
        name={`${namePrefix}.documentNumber`}
        render={({ onChange, value }) => (
          <div css={styles.form.input.root}>
            <span css={styles.form.input.label}>{translatedDocumentType}</span>
            <Input
              css={styles.form.input.input}
              onChange={onChange}
              placeholder={`Digite o número do documento...`}
              value={value}
            />
            <InputErrorMessage>
              {errors?.["documentNumber"]?.message}
            </InputErrorMessage>
          </div>
        )}
      />
      <div css={styles.row}>
        <Form.Field
          name={`${namePrefix}.firstName`}
          render={({ onChange, value }) => (
            <div css={styles.form.input.root}>
              <span css={styles.form.input.label}>Nome do viajante</span>
              <Input
                css={styles.form.input.input}
                onChange={onChange}
                placeholder="Digite o nome do viajante..."
                value={value}
              />
              {errors?.["firstName"] ? (
                <InputErrorMessage>
                  {errors["firstName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.form.input.info}>
                  Exatamente igual ao documento do viajante.
                </span>
              )}
            </div>
          )}
        />
        <Form.Field
          name={`${namePrefix}.lastName`}
          render={({ onChange, value }) => (
            <div css={styles.form.input.root}>
              <span css={styles.form.input.label}>Sobrenome do viajante</span>
              <Input
                css={styles.form.input.input}
                onChange={onChange}
                placeholder="Digite o sobrenome do viajante..."
                value={value}
              />
              {errors?.["lastName"] ? (
                <InputErrorMessage>
                  {errors["lastName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.form.input.info}>
                  Exatamente igual ao documento do viajante.
                </span>
              )}
            </div>
          )}
        />
      </div>
      <div css={styles.form.select.root}>
        <span css={styles.form.select.label}>Sexo</span>
        <Select
          id="sex"
          name={`${namePrefix}.sex`}
          onChange={({ value }) => {
            form.setValue(`${namePrefix}.sex`, value);
          }}
          options={SEXES}
          placeholder="Escolha um sexo..."
          value={SEXES.find(
            (sex) => sex.value === form.watch(`${namePrefix}.sex`),
          )}
        />
        <InputErrorMessage>{errors?.["sex"]?.message}</InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <ThreeWayDateSelect
          label="Data de nascimento"
          onChange={(value) =>
            form.setValue(`${namePrefix}.birthDate`, value.format("YYYY-MM-DD"))
          }
          type="birthDate"
        />
        <InputErrorMessage>{errors?.["birthDate"]?.message}</InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <span css={styles.form.select.label}>País emissor</span>
        <Select
          id={`${namePrefix}.issuingCountry`}
          isSearchable
          name={`${namePrefix}.issuingCountry`}
          onChange={({ value }) => {
            form.setValue(`${namePrefix}.issuingCountry`, value);
          }}
          options={countries}
          placeholder="Escolha um país emissor..."
          value={countries.find(
            (country) =>
              country.value === form.watch(`${namePrefix}.issuingCountry`),
          )}
        />
        <InputErrorMessage>
          {errors?.["issuingCountry"]?.message}
        </InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <ThreeWayDateSelect
          label="Data de emissão"
          onChange={(value) =>
            form.setValue(`${namePrefix}.issueDate`, value.format("YYYY-MM-DD"))
          }
          type="issueDate"
        />
        <InputErrorMessage>{errors?.["issueDate"]?.message}</InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <ThreeWayDateSelect
          label="Data de expiração"
          onChange={(value) =>
            form.setValue(
              `${namePrefix}.expirationDate`,
              value.format("YYYY-MM-DD"),
            )
          }
          type="expirationDate"
        />
        <InputErrorMessage>
          {errors?.["expirationDate"]?.message}
        </InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <span css={styles.form.select.label}>Nacionalidade</span>
        <Select
          id={`${namePrefix}.nationality`}
          isSearchable
          name={`${namePrefix}.nationality`}
          onChange={({ value }) => {
            form.setValue(`${namePrefix}.nationality`, value);
          }}
          options={countries}
          placeholder="Escolha a sua nacionalidade..."
          value={countries.find(
            (country) =>
              country.value === form.watch(`${namePrefix}.nationality`),
          )}
        />
        <InputErrorMessage>
          {errors?.["nationality"]?.message}
        </InputErrorMessage>
      </div>
      <div css={styles.form.select.root}>
        <span css={styles.form.select.label}>País de residência</span>
        <Select
          id={`${namePrefix}.residenceCountry`}
          isSearchable
          name={`${namePrefix}.residenceCountry`}
          onChange={({ value }) => {
            form.setValue(`${namePrefix}.residenceCountry`, value);
          }}
          options={countries}
          placeholder="Escolha o seu país de residência..."
          value={countries.find(
            (country) =>
              country.value === form.watch(`${namePrefix}.residenceCountry`),
          )}
        />
        <InputErrorMessage>
          {errors?.["residenceCountry"]?.message}
        </InputErrorMessage>
      </div>
    </>
  );
};
