import React, { useCallback } from "react";
import AsyncAutocomplete from "react-select/async";

import { searchApprovalProcesses } from "~/apps/corporate/apis/approval-process.api";
import { ApprovalProcessSearchItem } from "~/apps/corporate/models/approval-process.model";
import { Form } from "~/apps/shared/components/form/form";
import { APPROVAL_TYPES } from "~/apps/shared/constants";
import { debounce } from "lodash";

import { styles } from "./styles";

const handleSearchApprovalProcesses = async (searchQuery: string) => {
  const response = await searchApprovalProcesses({
    searchQuery,
    targetType: APPROVAL_TYPES.USER,
  });

  return response;
};

export const UserFormApprovalProcess: React.FC = () => {
  const loadOptions = useCallback(
    debounce((inputText: string, callback: any) => {
      void handleSearchApprovalProcesses(inputText).then(callback);
    }, 500),
    [handleSearchApprovalProcesses],
  );

  return (
    <div css={styles.root}>
      <span css={styles.title}>Processo de aprovação de usuário:</span>
      <Form.Field<ApprovalProcessSearchItem>
        name="approvalProcess"
        render={({ onBlur, onChange, value }) => (
          <AsyncAutocomplete
            defaultOptions
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.token}
            isClearable
            loadingMessage={() => "Carregando..."}
            loadOptions={loadOptions}
            noOptionsMessage={() => "Busque viajantes..."}
            onBlur={onBlur}
            onChange={onChange as any}
            placeholder="Escolha um fluxo de aprovação"
            styles={styles.select}
            value={value}
          />
        )}
      />
    </div>
  );
};
