import React from "react";
import { Expense } from "../../../../models/expense.model";
import { Desktop, TabletAndMobile } from "../../../shared/layout/Responsive";
import { DesktopExpenseItem } from "./DesktopExpenseItem";
import { MobileExpenseItem } from "./MobileExpenseItem";

interface Props {
  expense: Expense;
  containerStyle?: string;
  isLast?: boolean;
  isChecked: boolean;
  handleChange: (checked: boolean) => void;
  handleEdit: () => void;
  handleDelete: () => void;
}

const ExpenseItem = (props: Props) => (
  <>
    <Desktop>
      <DesktopExpenseItem {...props} />
    </Desktop>
    <TabletAndMobile>
      <MobileExpenseItem {...props} />
    </TabletAndMobile>
  </>
);

export { ExpenseItem };
