import React from "react";

import { OfferSearcherDialog } from "~/apps/corporate/components/offer-searcher-dialog/offer-search-dialog";
import { ServiceType } from "~/apps/shared/constants/enums";
import moment from "moment";

import { useCars } from "../cars.context";

type Props = {
  travelToken: string;
};

export const CarsEditSearchDialog: React.FC<Props> = ({ travelToken }) => {
  const { handleCloseEditionDrawer, isEditing, searchInfo } = useCars();

  const endEditDestination = searchInfo
    ? {
        latitude: parseFloat(searchInfo.dropoffLatitude),
        longitude: parseFloat(searchInfo.dropoffLongitude),
        search: searchInfo.dropoffSearch,
      }
    : null;

  const initialEditDestination = searchInfo
    ? {
        latitude: parseFloat(searchInfo.pickupLatitude),
        longitude: parseFloat(searchInfo.pickupLongitude),
        search: searchInfo.pickupSearch,
      }
    : null;

  const car = searchInfo
    ? {
        dropoffDate: moment(searchInfo.dropoffDate),
        dropoffLocation: searchInfo.diffDropoff ? endEditDestination : null,
        dropoffTime: moment()
          .set({
            hour: searchInfo.dropoffHour,
            minute: searchInfo.dropoffMinute,
          })
          .format("HH:mm"),
        pickupDate: moment(searchInfo.pickupDate),
        pickupLocation: initialEditDestination,
        pickupTime: moment()
          .set({ hour: searchInfo.pickupHour, minute: searchInfo.pickupMinute })
          .format("HH:mm"),
        sameLocationDropoff: !searchInfo.diffDropoff,
        travelToken: searchInfo.travelToken,
      }
    : null;

  if (!car || !endEditDestination || !initialEditDestination) {
    return null;
  }

  return (
    <OfferSearcherDialog
      data={car}
      onClose={handleCloseEditionDrawer}
      open={isEditing}
      travelToken={travelToken}
      title="Editar busca"
      type={ServiceType.CAR}
    />
  );
};
