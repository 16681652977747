import { useEffect, useState } from "react";

import { globalHistory } from "@reach/router";

export function useLocation() {
  const [state, setState] = useState({
    location: globalHistory.location,
    navigate: globalHistory.navigate
  });

  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      const { location } = params;

      setState(state => {
        const newState = Object.assign({}, state, { location });

        return newState;
      });
    });
    return () => {
      removeListener();
    };
  }, []);

  return state;
}
