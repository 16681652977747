import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { TravelTagsCreatableSelect } from "~/apps/corporate/components/travel-tags/travel-tags-creatable-select/travel-tags-creatable-select";
import { TravelTagsRemovableBadges } from "~/apps/corporate/components/travel-tags/travel-tags-removable-badges/travel-tags-removable-badges";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { useUserBillingProfiles } from "~/apps/corporate/hooks/use-user-billing-profiles";
import { useUserCompanyAreas } from "~/apps/corporate/hooks/use-user-company-areas";
import { useUserCostCenters } from "~/apps/corporate/hooks/use-user-cost-centers";
import { useUserProjects } from "~/apps/corporate/hooks/use-user-project";
import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";
import { Form } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Select } from "~/apps/shared/components/select/select";
import * as yup from "yup";

import { Button } from "@toolkit/v2";

import { useForm } from "@components/shared/form";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItinerary } from "../../../../itinerary.context";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";
import { useCategorizationAccordionPresenter } from "./categorization-accordion.hooks";
import { styles } from "./styles";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const CategorizationAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const {
    categorizationAccordionPresenter,
  } = useCategorizationAccordionPresenter();

  const { user } = useUser();

  const { travelToken } = useItinerary();
  const { pendencies, saveCategorizationAndProceed } = useItineraryPendencies();
  const { isLoadingTagOptions } = useTags();

  const travelerToken = categorizationAccordionPresenter
    ? categorizationAccordionPresenter.getTravelerToken()
    : undefined;

  const {
    billingProfiles,
    errorBillingProfiles,
    isLoadingBillingProfiles,
  } = useUserBillingProfiles(
    travelerToken,
    categorizationAccordionPresenter
      ? categorizationAccordionPresenter.shouldFetchUserBillingProfiles()
      : false,
  );
  const {
    companyAreas,
    errorCompanyAreas,
    isLoadingCompanyAreas,
  } = useUserCompanyAreas(
    travelerToken,
    categorizationAccordionPresenter
      ? categorizationAccordionPresenter.shouldFetchUserCompanyAreas()
      : false,
  );
  const {
    costCenters,
    errorCostCenters,
    isLoadingCostCenters,
  } = useUserCostCenters(
    travelerToken,
    categorizationAccordionPresenter
      ? categorizationAccordionPresenter.shouldFetchUserCostCenters()
      : false,
  );
  const { errorProjects, isLoadingProjects, projects } = useUserProjects(
    travelerToken,
    categorizationAccordionPresenter
      ? categorizationAccordionPresenter.shouldFetchUserProjects()
      : false,
  );

  const formSchema = useMemo(() => {
    if (!categorizationAccordionPresenter) {
      return yup.object().shape({
        billingProfileToken: yup.string().nullable(true),
        companyAreaToken: yup.string().nullable(true),
        costCenterToken: yup.string().nullable(true),
        projectToken: yup.string().nullable(true),
      });
    }

    return categorizationAccordionPresenter.calculateFormSchema();
  }, [categorizationAccordionPresenter]);

  type FormSchema = yup.InferType<typeof formSchema>;

  const form = useForm<FormSchema>({
    defaultValues: categorizationAccordionPresenter
      ? categorizationAccordionPresenter.calculateFormDefaultValues()
      : ({
          billingProfileToken: null,
          companyAreaToken: null,
          costCenterToken: null,
          projectToken: null,
        } as FormSchema),
    onSubmit: async () => {
      const values = form.getValues();

      const success = await saveCategorizationAndProceed({
        billingProfileToken: values.billingProfileToken,
        companyAreaToken: values.companyAreaToken,
        costCenterToken: values.costCenterToken,
        projectToken: values.projectToken,
      });

      if (!success) {
        return;
      }

      next();
    },
    schema: formSchema,
  });

  const {
    billingProfileToken,
    companyAreaToken,
    costCenterToken,
    projectToken,
  } = form.watch([
    "billingProfileToken",
    "companyAreaToken",
    "costCenterToken",
    "projectToken",
  ]);

  const billingProfileOptions = useMemo(() => {
    if (!billingProfiles || !user) {
      return null;
    }

    return billingProfiles
      .filter(
        (billingProfile) =>
          billingProfile.client_token === user.getClientToken(),
      )
      .map((billingProfile) => ({
        label: billingProfile.name,
        value: billingProfile.billingProfileToken,
      }));
  }, [billingProfiles, user]);
  const companyAreaOptions = useMemo(() => {
    if (!companyAreas || !user) {
      return null;
    }

    return companyAreas
      .filter(
        (companyArea) => companyArea.clientToken === user.getClientToken(),
      )
      .map((companyArea) => ({
        label: companyArea.name,
        value: companyArea.companyAreaToken,
      }));
  }, [companyAreas, user]);
  const costCenterOptions = useMemo(() => {
    if (!costCenters || !user) {
      return null;
    }

    return costCenters
      .filter((costCenter) => costCenter.clientToken === user.getClientToken())
      .map((costCenter) => ({
        label: costCenter.name,
        value: costCenter.token,
      }));
  }, [costCenters, user]);
  const projectOptions = useMemo(() => {
    if (!projects || !user) {
      return null;
    }

    return projects
      .filter((project) => project.clientToken === user.getClientToken())
      .map((project) => ({
        label: project.name,
        value: project.projectToken,
      }));
  }, [projects, user]);

  const selectedBillingProfile = useMemo(() => {
    if (!billingProfiles || !billingProfileToken) {
      return undefined;
    }

    const billingProfile = billingProfiles.find(
      (billingProfile) =>
        billingProfile.billingProfileToken === billingProfileToken,
    );

    if (!billingProfile) {
      return undefined;
    }

    return {
      label: billingProfile.name,
      value: billingProfile.billingProfileToken,
    };
  }, [billingProfiles, billingProfileToken]);
  const selectedCompanyArea = useMemo(() => {
    if (!companyAreas || !companyAreaToken) {
      return undefined;
    }

    const companyArea = companyAreas.find(
      (companyArea) => companyArea.companyAreaToken === companyAreaToken,
    );

    if (!companyArea) {
      return undefined;
    }

    return {
      label: companyArea.name,
      value: companyArea.companyAreaToken,
    };
  }, [companyAreas, companyAreaToken]);
  const selectedCostCenter = useMemo(() => {
    if (!costCenters || !costCenterToken) {
      return undefined;
    }

    const costCenter = costCenters.find(
      (costCenter) => costCenter.token === costCenterToken,
    );

    if (!costCenter) {
      return undefined;
    }

    return {
      label: costCenter.name,
      value: costCenter.token,
    };
  }, [costCenters, costCenterToken]);
  const selectedProject = useMemo(() => {
    if (!projects || !projectToken) {
      return undefined;
    }

    const project = projects.find(
      (project) => project.projectToken === projectToken,
    );

    if (!project) {
      return undefined;
    }

    return {
      label: project.name,
      value: project.projectToken,
    };
  }, [projects, projectToken]);

  if (!categorizationAccordionPresenter || !pendencies) {
    return null;
  }

  const isResolved = pendencies.isCategorizationResolved;

  return (
    <PendenciesAccordion
      description="Especifique CNPJ, centro de custo e outras informações relevantes."
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={`${index}. Categorização administrativa.`}
      toggleExpanded={toggleExpanded}
    >
      <Form context={form} css={styles.form.root}>
        {categorizationAccordionPresenter.showBillingProfileSection() ? (
          <>
            {isLoadingBillingProfiles ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : errorBillingProfiles ? null : billingProfileOptions ? (
              <div css={styles.form.select.root}>
                {categorizationAccordionPresenter.renderBillingProfileSectionLabel()}
                <Select
                  id="billingProfileToken"
                  name="billingProfileToken"
                  onChange={({ value }) => {
                    form.setValue("billingProfileToken", value);
                  }}
                  options={billingProfileOptions}
                  placeholder="Escolha um CNPJ..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedBillingProfile}
                />
                <InputErrorMessage>
                  {form.errors["billingProfileToken"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </>
        ) : null}
        {categorizationAccordionPresenter.showCompanyAreaSection() ? (
          <>
            {isLoadingCompanyAreas ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : errorCompanyAreas ? null : companyAreaOptions ? (
              <div css={styles.form.select.root}>
                {categorizationAccordionPresenter.renderCompanyAreaSectionLabel()}
                <Select
                  id="companyAreaToken"
                  name="companyAreaToken"
                  onChange={({ value }) => {
                    form.setValue("companyAreaToken", value);
                  }}
                  options={companyAreaOptions}
                  placeholder="Escolha uma área..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedCompanyArea}
                />
                <InputErrorMessage>
                  {form.errors["companyAreaToken"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </>
        ) : null}
        {categorizationAccordionPresenter.showCostCenterSection() ? (
          <>
            {isLoadingCostCenters ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : errorCostCenters ? null : costCenterOptions ? (
              <div css={styles.form.select.root}>
                {categorizationAccordionPresenter.renderCostCenterSectionLabel()}
                <Select
                  id="costCenterToken"
                  name="costCenterToken"
                  onChange={({ value }) => {
                    form.setValue("costCenterToken", value);
                  }}
                  options={costCenterOptions}
                  placeholder="Escolha um centro de custo..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedCostCenter}
                />
                <InputErrorMessage>
                  {form.errors["costCenterToken"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </>
        ) : null}
        {categorizationAccordionPresenter.showProjectSection() ? (
          <>
            {isLoadingProjects ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : errorProjects ? null : projectOptions ? (
              <div css={styles.form.select.root}>
                {categorizationAccordionPresenter.renderProjectSectionLabel()}
                <Select
                  id="projectToken"
                  name="projectToken"
                  onChange={({ value }) => {
                    form.setValue("projectToken", value);
                  }}
                  options={projectOptions}
                  placeholder="Escolha um projeto..."
                  useSetDefaultValueIfOnlyOneOption
                  value={selectedProject}
                />
                <InputErrorMessage>
                  {form.errors["projectToken"]?.message}
                </InputErrorMessage>
              </div>
            ) : null}
          </>
        ) : null}
        {categorizationAccordionPresenter.showTravelTagsSection() ? (
          <>
            {isLoadingTagOptions ? (
              <div css={styles.form.select.root}>
                <Skeleton height="16px" width="128px" />
                <Skeleton height="48px" width="100%" />
              </div>
            ) : (
              <div css={styles.form.select.root}>
                {categorizationAccordionPresenter.renderTravelTagsSectionLabel()}
                <TravelTagsCreatableSelect
                  placeholder="Escolha uma etiqueta..."
                  travelToken={travelToken}
                />
                <TravelTagsRemovableBadges travelToken={travelToken} />
              </div>
            )}
          </>
        ) : null}
        <Button
          css={styles.form.button}
          disabled={form.formState.isSubmitting || isLoadingNext}
        >
          Salvar
        </Button>
      </Form>
    </PendenciesAccordion>
  );
};
