import { useMemo } from "react";

import { useItineraryInfo } from "../../../itinerary-info.context";
import { useItineraryServices } from "../../../itinerary-services.context";

export const useTravelPlanShareDialogGuard = () => {
  const { isLoading: isLoadingItineraryInfo } = useItineraryInfo();
  const { isLoading: isLoadingItineraryServices } = useItineraryServices();

  return {
    loading: isLoadingItineraryInfo || isLoadingItineraryServices,
  };
};
