import React from "react";

import { AlertBox } from "~/apps/shared/components/alert-box/alert-box";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  mode: "create" | "edit";
  type: "guest" | "user";
};

export const UserFormWarnings: React.FC<Props> = ({ mode, type }) => {
  return (
    <>
      {type === "guest" ? (
        <AlertBox css={styles.root}>
          <Icon size={18} use="eye" />
          <span>
            Esse usuário pode apenas visualizar viagens criadas para ele.
          </span>
        </AlertBox>
      ) : null}
      {mode === "create" ? (
        <AlertBox css={styles.root}>
          <Icon size={18} use="flag" />
          <span>
            Não se preocupe, ao ser cadastrado o usuário receberá um e-mail com
            seu acesso ao sistema.
          </span>
        </AlertBox>
      ) : null}
    </>
  );
};
