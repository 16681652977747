import React, { useContext } from "react";

import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Flex } from "smartrips-toolkit";

import { CostCenter } from "@models/cost-center.model";
import { UserModel } from "@models/user.model";

import { Autocomplete } from "@components/shared/autocomplete/AutocompleteAsync";
import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import DrawerHeader from "@components/shared/DrawerHeader";
import { Form, useForm } from "@components/shared/form";
import { Input } from "@components/shared/inputs";
import { Row } from "@components/shared/layout/Row";

import { CostCentersContext } from "../CostCenters.context";
import * as costCentersService from "../CostCenters.service";
import { FormValues } from "../CostCenters.types";
import { COST_CENTER_FORM_SCHEMA } from "./schema";
import { styles } from "./styles";

interface ICostCenterProps {
  open: boolean;
  selectedCostCenter: CostCenter | null;
  handleClose: () => void;
}

const CostCenterComponent = ({
  selectedCostCenter,
  handleClose,
}: ICostCenterProps) => {
  const { isSubmitting, handleSaveCostCenter } = useContext(CostCentersContext);

  const context = useForm<FormValues>({
    schema: COST_CENTER_FORM_SCHEMA,
    onSubmit: handleSaveCostCenter,
    defaultValues: {
      token: selectedCostCenter?.token || "",
      name: selectedCostCenter?.name || "",
      users: selectedCostCenter?.users || [],
    },
  });

  const { watch, setValue, errors } = context;
  const users = watch("users") as UserModel[];
  const token = watch("token") as string;

  const selectUser = (selectedUser: UserModel) => {
    const isUserAlreadySelected = users.some(
      (user) => user.userToken === selectedUser.userToken,
    );

    if (!isUserAlreadySelected) {
      setValue("users", Array.prototype.concat(users, selectedUser));
    }
  };

  const removeUser = (user: UserModel) => () => {
    setValue(
      "users",
      users.filter((u) => u.userToken !== user.userToken),
    );
  };

  return (
    <Flex flexDirection="column" style={{ height: "100%" }}>
      <DrawerHeader
        title={token ? "Editando centro de custo" : "Novo centro de custo"}
        handleClose={handleClose}
        headerStyles={styles.header}
      />
      <Form context={context} css={styles.form}>
        <div css={styles.body}>
          <div css={styles.inputDiv}>
            <div css={styles.inputLabel}>
              <span>Nome do centro de custo</span>
            </div>
            <Form.Field
              as={
                <Input
                  id="name"
                  name="name"
                  placeholder="Vendas e Marketing"
                  error={!!errors?.name}
                  css={styles.input.container}
                />
              }
              name="name"
            />
          </div>
          <div css={styles.inputDiv}>
            <div css={styles.inputLabel}>
              <span>Adicionar usuários ao centro de custo</span>
            </div>
            <Form.Field
              as={
                <Autocomplete
                  inputProps={{
                    placeholder: "Procure por nome ou e-mail",
                    notchedOutlineStyle: styles.inputOutlineStyle,
                    css: styles.input.container,
                  }}
                  handleSelect={selectUser}
                  fetchItems={costCentersService.searchUsers}
                  clearOnSelect={true}
                  menuStyle={{
                    minWidth: "none",
                    borderRadius: "0.5rem",
                  }}
                />
              }
              name="users"
            />
          </div>
          <div>
            <div css={styles.inputLabel}>
              <span>Usuários adicionados ao centro de custo</span>
            </div>
            {users?.length === 0 ? (
              <p css={styles.emptyUsers}>Sem usuários atribuidos</p>
            ) : (
              <Flex style={{ gap: "0.5rem", flexWrap: "wrap" }}>
                {users.map((user) => (
                  <Row key={user.userToken} css={styles.userItem}>
                    <p>{user.fullName}</p>
                    <div css={styles.iconContainer} onClick={removeUser(user)}>
                      <CloseIcon css={styles.icon} />
                    </div>
                  </Row>
                ))}
              </Flex>
            )}
          </div>
        </div>
        <Row css={styles.buttonsDiv}>
          <ContainedButton
            type="submit"
            disabled={isSubmitting}
            css={styles.saveButton}
          >
            Salvar
          </ContainedButton>
          <OutlinedButton
            type="button"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancelar
          </OutlinedButton>
        </Row>
      </Form>
    </Flex>
  );
};

export const CostCentersForm = (props: ICostCenterProps) => (
  <Drawer
    anchor="right"
    open={props.open}
    onClose={props.handleClose}
    PaperProps={{
      style: {
        width: 815,
      },
    }}
  >
    <CostCenterComponent {...props} />
  </Drawer>
);
