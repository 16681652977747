export const secondsToHHMM = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - hours * 3600) / 60);

  let hoursStr = hours.toString();
  let minutesStr = minutes.toString();

  if (hours < 10) {
    hoursStr = "0" + hours.toString();
  }
  if (minutes < 10) {
    minutesStr = "0" + minutes.toString();
  }

  return hoursStr + ":" + minutesStr;
};
