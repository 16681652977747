import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: {
    root: css({
      fontSize: "0.875rem",
      gap: "10px",
      left: "16px",
      padding: "8px 12px",
      position: "absolute",
      top: "16px",
      zIndex: 1,
    }),
  },
  controls: css({
    bottom: "calc(8px + 14px)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    right: "16px",
    zIndex: 1,
  }),
  root: ({ isExpanded }: { isExpanded: boolean }) =>
    css({
      aspectRatio: "1",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      maxHeight: isExpanded ? "768px" : "auto",
      overflow: "hidden",
      position: "relative",
      width: "100%",
    }),
};
