import { useEffect, useRef, useState } from "react";

import useDebouncedCallback from "~/hooks/useDebouncedCallback";

type FilterableListProps<T> = {
  data: T[];
  filterData: (searchValue: string, data: T[]) => T[];
  children: (props: FilterableListRenderProps<T>) => JSX.Element;
};

type FilterableListRenderProps<T> = {
  filteredList: T[];
  onFilter: (value: string) => void;
  searchValue: string;
};

export default function FilterableList<T = unknown>(
  props: FilterableListProps<T>
) {
  const { data, filterData, children } = props;

  const _filterData = useRef(filterData);
  const [filteredList, setFilteredList] = useState<T[]>(data);
  const [searchValue, setSearchValue] = useState("");

  const onFilter = useDebouncedCallback(filter => {
    setSearchValue(filter);

    if (filter) {
      const results = _filterData.current(filter, data);

      setFilteredList(results);
    } else {
      setFilteredList(data);
    }
  }, 500);

  useEffect(() => onFilter(searchValue), [onFilter, searchValue]);

  useEffect(() => {
    setFilteredList(data);
  }, [data]);

  return children({
    filteredList,
    onFilter,
    searchValue
  });
}
