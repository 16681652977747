import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    button: css({
      width: "fit-content",
    }),
    root: css({
      width: "fit-content",
    }),
  },
  center: {
    control: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    }),
    dates: css({}),
    guests: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      width: "calc(50% - 8px)",
      "@media screen and (max-width: 768px)": {
        width: "100%",
      },
    }),
    input: {
      input: css({
        padding: 0,
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        padding: "12px 16px",
        display: "flex",
        gap: "12px",
        position: "relative",
        width: "100%",
      }),
    },
    label: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "28px",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    padding: "12px 24px 24px 24px",
    position: "relative",
    width: "100%",
  }),
};
