import React from "react";
import { Helmet } from "react-helmet";
import { PropTypes } from "prop-types";
import TITLES from "../../constants/page-titles.constants";

const PageTitle = ({ title }) => {
  const formattedTitle =
    title === TITLES.BASE_TITLE
      ? TITLES.BASE_TITLE
      : title + " — " + TITLES.BASE_TITLE;

  return <Helmet title={formattedTitle} />;
};

PageTitle.propTypes = {
  title: PropTypes.string
};

export default PageTitle;
