import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    ".Mui": {
      "&FormLabel": {
        "&-asterisk": {
          color: theme.colors.pink[500],
        },
        "&-root": {
          color: theme.colors.gray[100],
          fontFamily: theme.fonts.inter,
          "&.Mui-focused": {},
        },
      },
      "&InputBase-input": {
        fontFamily: theme.fonts.inter,
        padding: "26px 16px 12px 12px",
        textOverflow: "ellipsis",
      },
      "&InputLabel-outlined": {
        "&.MuiInputLabel-shrink": {
          color: theme.colors.gray[700],
          fontWeight: 700,
          transform: "translate(12px, 12px) scale(0.75)",
        },
      },
      "&OutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
  }),
};
