import React from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryPresentationalServices } from "~/apps/corporate/components/itinerary-presentational-services/itinerary-presentational-services";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { OFFER_STATUS_COLOR } from "~/apps/shared/constants/offers.constants";

import {
  useApprovalRequestServicesCardGuard,
  useApprovalRequestServicesCardPresenter,
} from "./approval-request-services-card.hook";
import { styles } from "./styles";

const Component: React.FC = () => {
  const { error, loading } = useApprovalRequestServicesCardGuard();
  const {
    approvalRequestServicesCardPresenter,
  } = useApprovalRequestServicesCardPresenter();

  if (error) {
    return <ApprovalRequestServicesCardError />;
  }

  if (loading) {
    return <ApprovalRequestServicesCardSkeleton />;
  }

  if (!approvalRequestServicesCardPresenter) {
    return null;
  }

  const presentationalServices = approvalRequestServicesCardPresenter.getPresentationalServices();

  return (
    <div css={styles.root}>
      <div css={styles.services.root}>
        {approvalRequestServicesCardPresenter.hasPresentationalServices() ? (
          <ItineraryPresentationalServices
            color={OFFER_STATUS_COLOR.DRAFT}
            groups={presentationalServices}
          />
        ) : (
          <p css={styles.services.empty}>
            Nenhum serviço encontrado para esse filtro.
          </p>
        )}
      </div>
    </div>
  );
};

export const ApprovalRequestServicesCard: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary
      fallback={() => <ApprovalRequestServicesCardError {...props} />}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
};

const ApprovalRequestServicesCardError: React.FC = () => {
  return (
    <div css={styles.root}>
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        <p css={styles.services.empty}>
          Ocorreu um erro. Tente novamente mais tarde.
        </p>
      </div>
    </div>
  );
};

const ApprovalRequestServicesCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.services.root}>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="14px" width="192px" />
          <Skeleton height="128px" width="100%" />
          <Skeleton height="128px" width="100%" />
        </div>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="128px" width="100%" />
        </div>
      </div>
    </div>
  );
};
