import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import {
  EventParticipantType,
  EventPrivacy,
} from "~/apps/corporate/models/event.model";
import { api } from "~/apps/shared/services/api";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";

export type AcceptEventParticipationData = {
  token: string;
};

export const acceptEventParticipation = async ({
  token,
}: AcceptEventParticipationData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/participants/accept`,
    })
    .then(({ data }) => data);
};

export type CreateEventData = {
  clientToken: string;
  data: {
    city: string;
    country: string;
    end_date: string;
    name: string;
    privacy: EventPrivacy;
    start_date: string;
    state: string;
  };
};

export const createEvent = async ({ clientToken, data }: CreateEventData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/clients/${clientToken}`,
    })
    .then(({ data }) => data);
};

export type CreateEventTravelData = {
  data: {
    travel_name?: string;
    traveler_token: string;
  };
  token: string;
};

export const createEventTravel = async ({
  data,
  token,
}: CreateEventTravelData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/travels`,
    })
    .then(({ data }) => data);
};

export type CreateEventUpdateData = {
  data: {
    on_top: boolean;
    text: string;
  };
  token: string;
};

export const createEventUpdate = async ({
  data,
  token,
}: CreateEventUpdateData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/updates`,
    })
    .then(({ data }) => data);
};

export type DeclineEventParticipationData = {
  token: string;
};

export const declineEventParticipation = async ({
  token,
}: DeclineEventParticipationData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/participants/decline`,
    })
    .then(({ data }) => data);
};

export type DisinviteEventParticipantData = {
  participant_token: string;
  token: string;
};

export const disinviteEventParticipant = async ({
  token,
  participant_token,
}: DisinviteEventParticipantData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/company-events/${token}/participants/${participant_token}/disinvite`,
    })
    .then(({ data }) => data);
};

export type EditEventData = {
  data: Partial<{
    city: string;
    country: string;
    description: string | null;
    end_date: string;
    name: string;
    privacy: EventPrivacy;
    start_date: string;
    state: string;
  }>;
  token: string;
};

export const editEvent = async ({ data, token }: EditEventData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/company-events/${token}`,
    })
    .then(({ data }) => data);
};

export type EditEventParticipantData = {
  data: {
    participant_type: EventParticipantType;
  };
  eventToken: string;
  token: string;
};

export const editEventParticipant = async ({
  data,
  eventToken,
  token,
}: EditEventParticipantData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/company-events/${eventToken}/participants/${token}`,
    })
    .then(({ data }) => data);
};

export type EditEventUpdateData = {
  data: {
    on_top?: boolean;
  };
  token: string;
  updateToken: string;
};

export const editEventUpdate = async ({
  data,
  token,
  updateToken,
}: EditEventUpdateData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/company-events/${token}/updates/${updateToken}`,
    })
    .then(({ data }) => data);
};

export type ExcludeEventData = {
  token: string;
};

export const excludeEvent = async ({ token }: ExcludeEventData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/exclude`,
    })
    .then(({ data }) => data);
};

export type GetEventData = {
  token: string;
};

export const getEvent = async ({ token }: GetEventData) => {
  return await api
    .request<GetUserCompanyEventDto>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/company-events/${token}`,
    })
    .then(({ data }) => data);
};

export type InviteEventParticipantsData = {
  data: {
    invited_users_tokens: string[];
    message?: string;
  };
  token: string;
};

export const inviteEventParticipants = async ({
  data,
  token,
}: InviteEventParticipantsData) => {
  return await api
    .request({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/company-events/${token}/participants/invite`,
    })
    .then(({ data }) => data);
};

type ListEventsData = {
  clientToken: string;
};

export const listEvents = async ({ clientToken }: ListEventsData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/company-events/clients/${clientToken}`,
    })
    .then(({ data }) => data);
};

export type ListEventParticipantsData = {
  token: string;
};

export const listEventParticipants = async ({
  token,
}: ListEventParticipantsData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/company-events/${token}/participants`,
    })
    .then(({ data }) => data);
};

export type ListEventUpdatesData = {
  token: string;
};

export const listEventUpdates = async ({ token }: ListEventUpdatesData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/company-events/${token}/updates`,
    })
    .then(({ data }) => data);
};

export type SearchEventTargetsData = {
  search?: string;
  token: string;
};

export const searchEventTargets = async ({
  search,
  token,
}: SearchEventTargetsData) => {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: {
        search,
      },
      url: `/booking/company-events/${token}/targets-search`,
    })
    .then(({ data }) => data);
};
