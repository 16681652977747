import React from "react";

import WarningIcon from "@material-ui/icons/ErrorOutline";
import { css } from "@styled-system/css";
import { Flex, Box, Text } from "@toolkit";

import { Paragraph } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { DetailsCard } from "../../shared/DetailsCard";
import { useFlightInfoContext } from "../FlightInfo.context";
import { CreditsPieChart } from "./CreditsPieChart";
import { CreditsTable } from "./CreditsTable";

const styles = {
  warningIcon: css({
    width: "1.2rem",
    height: "1.2rem"
  })
};

const CreditPerCompany = () => {
  const {
    creditsInfo: { credits, totalCredits, creditsPerCompany },
    loading
  } = useFlightInfoContext();

  return (
    <DetailsCard
      title={"Créditos com cia aérea"}
      total={totalCredits}
      customTotalName={"Total disponível"}
      style={{ height: "100%" }}
    >
      <Flex color={defaultTheme.subTextColor} alignItems="center">
        <WarningIcon css={styles.warningIcon} />
        <Text ml="0.5rem" fontSize={[0, 1, 1]}>
          Por regra das cias aéreas, os créditos são intransferíveis e
          vinculados ao passageiro
        </Text>
      </Flex>
      <Flex flexDirection={["column", "row", "row"]}>
        {credits.length && !loading ? (
          <>
            <Box width={["100%", "100%", "66%"]}>
              <CreditsTable
                creditsPerCompany={creditsPerCompany}
                totalCredits={totalCredits}
              />
            </Box>
            <Box width={["100%", "100%", "33%"]} mb={[24, 24, 0]}>
              <CreditsPieChart creditsPerCompany={creditsPerCompany} />
            </Box>
          </>
        ) : (
          <Paragraph
            m="1rem"
            mt="2rem"
            color={defaultTheme.subTextColor}
            fontSize={1}
          >
            Nenhum crédito a ser mostrado
          </Paragraph>
        )}
      </Flex>
    </DetailsCard>
  );
};

export { CreditPerCompany };
