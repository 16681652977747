import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
    "@media screen and (max-width: 768px)": {
      width: "100dvw",
    },
  }),
  section: {
    header: {
      button: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.pink[500],
        cursor: "pointer",
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
        fontWeight: 500,
        marginTop: "2px",
        transition: "color 0.15s ease-in-out",
        width: "fit-content",
        "&:hover": {
          color: theme.colors.pink[700],
        },
      }),
      link: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.pink[500],
        cursor: "pointer",
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
        fontWeight: 500,
        marginTop: "2px",
        transition: "color 0.15s ease-in-out",
        width: "fit-content",
        "&:hover": {
          color: theme.colors.pink[700],
        },
      }),
      root: css({
        display: "flex",
        justifyContent: "space-between",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        lineHeight: "1.25",
      }),
    },

    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
  },
};
