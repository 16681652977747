import React, { useMemo } from "react";

import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";

import { CircularSpinner } from "@toolkit/v2";

import {
  useTravelsFilters,
  useTravelsFiltersActions,
  useTravelsList,
} from "../trips.context";
import { styles } from "./styles";

const normalize = (str: string) => {
  return str
    .normalize("NFKD")
    .replace(/[^\w\s]/g, "")
    .toLowerCase();
};

export const TripsTravelerFilter: React.FC = () => {
  const { userToken } = useTravelsFilters();
  const { handleChangeUserFilter } = useTravelsFiltersActions();
  const { users } = useTravelsList();

  const items = useMemo(
    () =>
      users.map((user) => ({
        email: user.email,
        label: user.fullName,
        userToken: user.userToken,
      })),
    [users],
  );

  return (
    <div css={styles.root}>
      <AsyncAutocomplete
        css={styles.autocomplete.root}
        fetchItems={async (search) => {
          return await Promise.resolve({
            data: items.filter((item) =>
              normalize(item.label).includes(normalize(search)),
            ),
          });
        }}
        filterItems={(items) =>
          items.filter((item) => item.userToken !== userToken)
        }
        inputProps={{
          placeholder: "Filtre por viajante...",
        }}
        onClear={() => {
          handleChangeUserFilter("");
        }}
        onSelect={(item) => {
          handleChangeUserFilter(item.userToken);
        }}
        renderItem={({ isHighlighted, item, ...props }) => {
          if (item.error) {
            return (
              <li
                css={styles.autocomplete.item.root({
                  isDisabled: true,
                  isHighlighted: false,
                })}
                key={0}
                {...props}
              >
                Nenhum resultado.
              </li>
            );
          }

          return (
            <li
              css={styles.autocomplete.item.root({ isHighlighted })}
              key={JSON.stringify(item)}
              {...props}
            >
              <span>{item.label}</span>
              <span css={styles.autocomplete.item.email}>{item.email}</span>
            </li>
          );
        }}
        renderInput={({ loading, ...props }) => (
          <div css={styles.autocomplete.input.root}>
            <Icon css={styles.autocomplete.input.icon} size={18} use="person" />
            <Input css={styles.autocomplete.input.input} {...props} />
            {loading ? <CircularSpinner size={16} /> : null}
          </div>
        )}
      />
    </div>
  );
};
