import React from "react";

import { Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";

import { BillingProfiles } from "./billing-profiles/billing-profiles";
import { PaymentMethods } from "./payment-methods/payment-methods";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <PermissionProtectedRoute
        component={BillingProfiles}
        path="/"
        permissions={["companySettings.billingProfiles"]}
        redirectTo="/configurations/financial/payment-methods"
      />
      <PaymentMethods path="/payment-methods" />
    </Router>
  );
};

export const FinancialConfigurations: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
