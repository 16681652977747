import * as yup from "yup";

export const acceptTermsDialogSchema = yup.object().shape({
  acceptTerms: yup
    .bool()
    .oneOf([true], "É preciso aceitar a política de privacidade para continuar")
    .required("É preciso aceitar a política de privacidade para continuar"),
});

export type AcceptTermsDialogSchema = yup.InferType<
  typeof acceptTermsDialogSchema
>;
