import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  list: css({
    display: "flex",
    flexDirection: "column",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  row: {
    root: css({
      alignItems: "center",
      borderBottom: `1px solid ${theme.colors.gray[100]}`,
      color: theme.colors.gray[700],
      display: "flex",
      gap: "16px",
      justifyContent: "space-between",
      padding: "8px 0",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1.25rem",
    fontWeight: 600,
  }),
};
