import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  section: {
    approved: {
      icon: css({
        color: theme.colors.green[500],
      }),
      link: css({
        alignItems: "center",
        backgroundColor: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.gray[100],
        borderRadius: "8px",
        color: theme.colors.gray[500],
        display: "flex",
        fontSize: "0.875rem",
        fontWeight: 500,
        gap: "12px",
        justifyContent: "center",
        lineHeight: "1rem",
        padding: "14px 24px",
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
          borderColor: theme.colors.gray[700],
        },
      }),
      message: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.25",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "12px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    strong: css({
      fontWeight: 600,
    }),
  },
  top: {
    root: css({
      display: "flex",
      gap: "16px",
      justifyContent: "space-between",
    }),
    title: css({
      color: theme.colors.green[500],
      fontWeight: 600,
    }),
  },
};
