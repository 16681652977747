import React from "react";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLSpanElement>;

export const InputErrorMessage: React.FC<Props> = ({ children, ...props }) => {
  if (!children) {
    return null;
  }

  return (
    <span css={styles.root} {...props}>
      {children}
    </span>
  );
};
