import React from "react";

import { Tooltip } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { css } from "emotion";

import { defaultTheme } from "~/assets/styles/theme";

import styled from "@shared/styled";

const styles = {
  MonetizationIcon: css({
    color: defaultTheme.subTextColor
  }),
  tooltip: css({
    [".MuiTooltip-tooltip"]: {
      backgroundColor: "#F7F8FD",
      color: "#8994A4",
      fontSize: 1,
      width: "100%",
      maxWidth: 370,
      padding: "small"
    }
  })
};

export const CoinsTooltip = styled(props => (
  <Tooltip {...props}>
    <MonetizationOnIcon
      color="primary"
      style={{ fontSize: props.iconSize }}
      css={styles.MonetizationIcon}
    />
  </Tooltip>
))(styles.tooltip);
