import React from "react";

import { ApprovalCard } from "./ApprovalCard";
import { PaymentCard } from "./PaymentCard";
import { ApprovalAnsweredCard } from "./ApprovalAnsweredCard";
import { useExpenseAdvanceApprovalReviewContext } from "../ExpenseAdvanceApprovalReview.context";

const SideCard = () => {
  const { approvalStatus } = useExpenseAdvanceApprovalReviewContext();

  if (approvalStatus === "PENDING_APPROVAL") {
    return <ApprovalCard />;
  } else if (approvalStatus === "PENDING_PAYMENT") {
    return <PaymentCard />;
  } else {
    return <ApprovalAnsweredCard />;
  }
};

export { SideCard };
