import React, { useMemo } from "react";

import { ItineraryBusSegment } from "~/apps/corporate/components/itinerary-bus-segment/itinerary-bus-segment";
import { ItineraryServiceInfoModel } from "~/apps/corporate/models/itinerary/itinerary-service-info.model";
import { BusItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { TravelPolicyForOffer } from "~/apps/corporate/models/travel.model";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { formatCurrency } from "~/apps/shared/utils/format-currency";

import { ItineraryServiceDetailsDrawerCreditCardInfo } from "../itinerary-service-details-drawer-credit-card-info/itinerary-service-details-drawer-credit-card-info";
import { styles } from "./styles";

type Props = {
  closeServiceDetailsDrawer: () => void;
  itineraryServiceInfoModel: ItineraryServiceInfoModel | null;
  policy: TravelPolicyForOffer | null;
  servicePresenter: BusItineraryServicePresenter;
};

export const ItineraryBusDetailsDrawer: React.FC<Props> = ({
  closeServiceDetailsDrawer,
  itineraryServiceInfoModel,
  policy,
  servicePresenter,
}) => {
  const creditCard = useMemo(() => {
    if (!itineraryServiceInfoModel) {
      return null;
    }

    return itineraryServiceInfoModel.getCreditCard();
  }, [itineraryServiceInfoModel]);

  const showDoNotWasteTimeMessage = !servicePresenter.isEmitted();

  const showOutOfPolicyInfo = useMemo(
    () => (policy ? policy.outOfPolicy : false),
    [policy],
  );

  return (
    <>
      <DrawerHeader onClose={closeServiceDetailsDrawer}>
        Detalhes do ônibus
      </DrawerHeader>
      <div css={styles.body.root}>
        <div css={styles.body.card.root}>
          {servicePresenter.getAllJourneys().map((journey, index) => (
            <React.Fragment key={journey.index}>
              {index !== 0 ? <hr css={styles.body.card.divisor} /> : null}
              {journey.segments.map((segment) => (
                <ItineraryBusSegment
                  arrival={{
                    address: segment.arrival.terminal,
                    time: journey.arrival.time,
                  }}
                  busName={segment.busType}
                  carrierLogo={segment.carrier.imageUrl}
                  carrierName={segment.carrier.name}
                  departure={{
                    address: segment.departure.terminal,
                    time: journey.departure.time,
                  }}
                  duration={segment.duration}
                  key={segment.index}
                  seat={journey.seat}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <footer css={styles.footer.root}>
        {showOutOfPolicyInfo ? (
          <span css={styles.footer.policy}>Fora da política.</span>
        ) : null}
        <span css={styles.footer.warning}>Preço total previsto:</span>
        <span css={styles.footer.price}>
          {formatCurrency(servicePresenter.getPrice())}
        </span>
        {showDoNotWasteTimeMessage ? (
          <span css={styles.footer.warning}>
            Preços e disponibilidade podem mudar. Não perca tempo.
          </span>
        ) : null}
        {creditCard ? (
          <ItineraryServiceDetailsDrawerCreditCardInfo
            creditCard={creditCard}
          />
        ) : null}
      </footer>
    </>
  );
};
