import React from "react";

import { TravelTagsDialog } from "../../../../components/travel-tags/travel-tags-dialog/travel-tags-dialog";
import { useItineraryScreen } from "../itinerary-screen.context";
import { useItineraryContainer } from "../itinerary.container";

export const ItineraryTravelTagsDialog: React.FC = () => {
  const { infoModel } = useItineraryContainer();

  const {
    closeTravelTagsDialog,
    isTravelTagsDialogOpen,
  } = useItineraryScreen();

  if (!infoModel) {
    return null;
  }

  const travelName = infoModel.getTravelName();
  const travelToken = infoModel.getTravelToken();

  return (
    <TravelTagsDialog
      isOpen={isTravelTagsDialogOpen}
      onClose={closeTravelTagsDialog}
      travelName={travelName}
      travelToken={travelToken}
    />
  );
};
