import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { css } from "@styled-system/css";
import { Filters } from "~/apps/shared/components/filters/filters";
import { Box, Flex } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import SpinnerPortal from "@components/shared/Spinner";

import CreationBox from "./creation-box/creation-box";
import CustomFieldOptionContainer from "./custom-field-option-card/custom-field-option-container";
import {
  CustomFieldOptionProvider,
  useCustomFieldOptions,
} from "./custom-field-options.context";
import UpdateBox from "./update-box/update-box";

const styles = {
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.pink[500],
    "&:hover": {
      backgroundColor: theme.colors.pink[700],
    },
  }),
  search: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      width: "100%",
      height: "3rem",
      padding: "0.75rem 1rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
    }),
    input: css({
      padding: "0",
      textOverflow: "ellipsis",
      width: "100%",
    }),
    icon: css({
      color: theme.colors.gray[300],
    }),
    top: css({
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }),
  },
};

const DEFAULT_FILTERS = [
  {
    label: "Ativas",
    value: "active",
  },
  {
    label: "Arquivadas",
    value: "archived",
  },
];

type Props = RouteComponentProps;

const Component: React.FC<Props> = () => {
  const {
    customFieldOptions,
    filterValue,
    handleOpenCreationBox,
    handleTabChange,
    isLoading,
    loadClientCustomFieldOptions,
  } = useCustomFieldOptions();

  useEffect(() => {
    loadClientCustomFieldOptions();
  }, []);

  return (
    <>
      <SpinnerPortal visible={isLoading && customFieldOptions.length === 0} />
      <CreationBox />
      <UpdateBox />
      <Box>
        <Flex css={styles.search.top}>
          <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
            <ContainedButton
              onClick={handleOpenCreationBox}
              css={styles.button}
            >
              Nova personalização
            </ContainedButton>
          </Flex>
          <Filters
            value={filterValue}
            onChange={(e) => handleTabChange(e)}
            closeOnSelect
            options={DEFAULT_FILTERS}
          ></Filters>
        </Flex>
        <br></br>
        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          {customFieldOptions.map((option) => {
            if (option.active && filterValue === "active") {
              return (
                <CustomFieldOptionContainer
                  key={option.custom_field_option_token}
                  customFieldOption={option}
                />
              );
            } else if (!option.active && filterValue === "archived") {
              return (
                <CustomFieldOptionContainer
                  key={option.custom_field_option_token}
                  customFieldOption={option}
                />
              );
            }
          })}
        </Flex>
      </Box>
    </>
  );
};

export const CustomFieldOptions: React.FC<Props> = ({ ...props }) => (
  <CustomFieldOptionProvider>
    <Component {...props} />
  </CustomFieldOptionProvider>
);
