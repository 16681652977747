import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  companyOptions: {
    checked: boolean;
    id: string | number;
    label: string;
  }[];
  disabled: boolean;
  handleCompanyFilterChange: (
    e: React.ChangeEvent<any>,
    checked: boolean,
  ) => void;
};

export const CompanyFilter: React.FC<Props> = ({
  companyOptions,
  disabled,
  handleCompanyFilterChange,
}) => {
  if (companyOptions.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Companhia</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {companyOptions.map((companyOption) => (
          <CheckboxGroup.Item
            checked={companyOption.checked}
            disabled={disabled}
            id={companyOption.id.toString()}
            key={companyOption.id}
            name={companyOption.label}
            onChange={handleCompanyFilterChange}
            value={companyOption.checked}
          >
            <span css={styles.checkbox.group.item.label}>
              {companyOption.label}
            </span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
