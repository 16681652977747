import {
  CreateDocumentDto,
  EditDocumentDto,
} from "~/apps/shared/dtos/documents.dto";
import { Document } from "~/apps/shared/models/documents.model";
import moment from "moment";

import { UserModel } from "../../models/user.model";
import { UserDocumentFormSchema } from "./user-document-form.schema";

export const mapUserDocumentFormDefaultValues = (
  document?: Document | null,
  user?: UserModel | null,
): UserDocumentFormSchema => {
  if (!document && !user) {
    return {
      birthDate: moment(null),
      documentNumber: "",
      expirationDate: null,
      firstName: "",
      issueDate: null,
      issuingCountry: null,
      lastName: "",
      nationality: null,
      residenceCountry: null,
      sex: "M",
      type: null,
      userToken: "",
    } as UserDocumentFormSchema;
  }

  if (!document) {
    return {
      birthDate: moment(null),
      documentNumber: "",
      expirationDate: null,
      firstName: user ? user.getFirstName() : "",
      issueDate: null,
      issuingCountry: null,
      lastName: user ? user.getLastName() : "",
      nationality: null,
      residenceCountry: null,
      sex: "M",
      type: null,
      userToken: user ? user.getUserToken() : "",
    } as UserDocumentFormSchema;
  }

  return {
    birthDate: document.birthDate ? moment(document.birthDate) : null,
    documentNumber: document.documentNumber,
    expirationDate: document.expirationDate
      ? moment(document.expirationDate)
      : null,
    firstName: document.firstName,
    issueDate: document.issueDate ? moment(document.issueDate) : null,
    issuingCountry: document.issuingCountry,
    lastName: document.lastName,
    nationality: document.nationality,
    residenceCountry: document.residenceCountry,
    sex: document.sex,
    type: document.type,
    userToken: user ? user.getUserToken() : "",
  } as UserDocumentFormSchema;
};

export const mapUserDocumentFormValuesToCreateDocumentDto = (
  values: UserDocumentFormSchema,
): CreateDocumentDto => {
  if (!values.type) {
    throw new Error("document type is expected");
  }

  return {
    birthDate: values.birthDate.format("YYYY-MM-DD"),
    documentNumber: values.documentNumber,
    expirationDate: values.expirationDate
      ? values.expirationDate.format("YYYY-MM-DD")
      : undefined,
    firstName: values.firstName,
    issueDate: values.issueDate
      ? values.issueDate.format("YYYY-MM-DD")
      : undefined,
    issuingCountry: values.issuingCountry ? values.issuingCountry : undefined,
    lastName: values.lastName,
    nationality: values.nationality ? values.nationality : undefined,
    residenceCountry: values.residenceCountry
      ? values.residenceCountry
      : undefined,
    sex: values.sex,
    type: values.type,
    userToken: values.userToken,
  };
};

export const mapUserDocumentFormValuesToEditDocumentDto = (
  documentToken: string,
  values: UserDocumentFormSchema,
): EditDocumentDto => {
  if (!values.type) {
    throw new Error("document type is expected");
  }

  return {
    birthDate: values.birthDate.format("YYYY-MM-DD"),
    documentNumber: values.documentNumber,
    documentToken,
    expirationDate: values.expirationDate
      ? values.expirationDate.format("YYYY-MM-DD")
      : undefined,
    firstName: values.firstName,
    issueDate: values.issueDate
      ? values.issueDate.format("YYYY-MM-DD")
      : undefined,
    issuingCountry: values.issuingCountry,
    lastName: values.lastName,
    nationality: values.nationality,
    residenceCountry: values.residenceCountry,
    sex: values.sex,
    type: values.type,
    userToken: values.userToken,
  };
};
