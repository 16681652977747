import { api } from "~/apps/shared/services/api";

import { GetChatConfigResponse } from "../dtos/agency.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";

export async function getChatConfig(agencyToken: string) {
  return api
    .request<GetChatConfigResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/agencies/${agencyToken}/chat-config`,
    })
    .then(({ data }) => data);
}
