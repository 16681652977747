import React, { CSSProperties, ReactNode } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css, keyframes } from "emotion";
import { defaultTheme } from "../../assets/styles/theme";
import { Column } from "./layout/Column";
import { Row } from "./layout/Row";

const saving = keyframes`
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
`;

const styles = {
  rootDiv: css({
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.6)",
    zIndex: 9999,
    position: "absolute"
  }),
  circularProgress: css({
    color: defaultTheme.primaryColor,
    marginBottom: "1.5rem"
  }),
  loading: css({
    ["span"]: {
      animationName: saving,
      animationDuration: "1.4s",
      animationIterationCount: "infinite",
      animationFillMode: "both"
    },
    ["span:nth-child(2)"]: {
      animationDelay: ".2s"
    },
    ["span:nth-child(3)"]: {
      animationDelay: ".4s"
    }
  })
};

interface Props {
  visible: boolean;
  message?: ReactNode | Element | string;
  messageStyle?: CSSProperties;
  loadingDots?: boolean;
  rootStyle?: CSSProperties;
  spinnerSize?: number;
}

const DrawerSpinner = ({
  visible,
  message,
  messageStyle,
  loadingDots,
  rootStyle,
  spinnerSize
}: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <Column className={styles.rootDiv} style={rootStyle}>
      <CircularProgress
        className={styles.circularProgress}
        size={spinnerSize}
      />
      {message ? (
        <Row style={messageStyle}>
          {message}
          {loadingDots ? (
            <div className={styles.loading}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          ) : null}
        </Row>
      ) : null}
    </Column>
  );
};

DrawerSpinner.defaultProps = {
  spinnerSize: 80
} as Partial<Props>;

export { DrawerSpinner };
