import React, { useEffect } from "react";

import { darken } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { css } from "@styled-system/css";
import { Filters } from "~/apps/shared/components/filters/filters";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";

import { theme } from "@skin/v2";

import { Box, Flex } from "@toolkit";

import PAGE_TITLES from "@constants/page-titles.constants";

import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";

import { ContainedButton } from "@components/shared/buttons/contained-button";

import { CategoryDrawer } from "./CategoryDrawer";
import {
  ExpensesCategoriesProvider,
  useExpensesCategories,
} from "./ExpensesCategories.context";
import { ExpenseCategoryList } from "./list/ExpenseCategoryList";

const DEFAULT_FILTERS = [
  {
    label: "Ativos",
    value: "active",
  },
  {
    label: "Arquivados",
    value: "archived",
  },
];

const styles = {
  button: css({
    backgroundColor: theme.colors.pink[500],
    height: "3rem",
    width: "fit-content",
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
    },
  }),
};

type Props = RouteComponentProps;

const Component: React.FC<Props> = () => {
  const {
    handleChangeTab,
    handleOpenCreateForm,
    handleSearch,
    loadExpensesCategories,
    loading,
    selectedTab,
  } = useExpensesCategories();

  useEffect(() => {
    loadExpensesCategories();
  }, []);

  return (
    <Box>
      <PageTitle title={PAGE_TITLES.EXPENSE_CATEGORY} />
      <SpinnerPortal visible={loading} />
      <Flex flexDirection="column" style={{ gap: "1.5rem" }}>
        <ContainedButton onClick={handleOpenCreateForm} css={styles.button}>
          Adicionar categoria
        </ContainedButton>
        <Flex style={{ gap: "1.5rem" }}>
          <SearchInput onChange={handleSearch} placeholder="Buscar categoria" />
          <Filters
            closeOnSelect
            onChange={(filter) =>
              handleChangeTab(filter as "active" | "archived")
            }
            options={DEFAULT_FILTERS}
            value={selectedTab}
          />
        </Flex>
        <ExpenseCategoryList />
      </Flex>
      <CategoryDrawer />
    </Box>
  );
};

export const ExpensesCategories: React.FC<Props> = ({ ...props }) => (
  <ExpensesCategoriesProvider>
    <Component {...props} />
  </ExpensesCategoriesProvider>
);
