import { Currency } from "~/apps/corporate/dtos/hotel.dto";

export const formatCurrency = (
  value: number | string,
  currency: Currency = "BRL",
  language = "pt-BR",
) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currency,
  });

  return formatter.format(value);
};
