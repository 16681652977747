import React, { useState, createContext, useEffect } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import {
  BusTripDetailsContentSeat,
  FiltersInfo,
  TripConnection,
} from "~/apps/corporate/dtos/bus.dto";
import * as busHelper from "~/apps/corporate/helpers/bus.helper";
import { BusSearchInfo } from "~/apps/corporate/models/bus.model";
import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import { ALERT_TYPES, BUS_STRETCH_TYPE } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import { useFocusStretchTabs, useVisibleTrips } from "./buses.hooks";
import * as busesService from "./buses.service";

interface FiltersActions {
  handleApplyFilters: (filters: FiltersState) => void;
  handleArrivalFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCategoryFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDepartureFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePriceFilterChange: (range: number[]) => void;
}
export type FiltersState = {
  arrivalOptions: { checked: boolean; id: string; label: string }[];
  categoryOptions: { checked: boolean; id: string; label: string }[];
  companyOptions: { checked: boolean; id: string; label: string }[];
  departureOptions: { checked: boolean; id: string; label: string }[];
  priceRange: number[];
  selectedPriceRange: number[];
};
interface PageActions {
  fetchBusTrips: (busSearchToken: string) => void;
  handleChangeSection: (
    stretchType: typeof BUS_STRETCH_TYPE[keyof typeof BUS_STRETCH_TYPE],
  ) => () => void;
  handleCloseFiltersDrawer: () => void;
  handleOpenFiltersDrawer: () => void;
  handleToggleCategoryExplanation: () => void;
}
export type PageState = {
  countryRestriction: CountryRestriction | null;
  currentSection: typeof BUS_STRETCH_TYPE[keyof typeof BUS_STRETCH_TYPE];
  departureFiltersInfo: FiltersInfo;
  departureTrips: TripConnection[];
  errorOnFetch: Error | null;
  generalError: Error | null;
  goToItinerary: boolean;
  isCategoriesExplanationVisible: boolean;
  isFakeLoading: boolean;
  isFiltering: boolean;
  isFiltersDrawerOpen: boolean;
  isLoading: boolean;
  returnFiltersInfo: FiltersInfo;
  returnTrips: TripConnection[];
};
interface SearchActions {
  handleCloseEdition: () => void;
  handleEditClick: () => void;
}
export type SearchState = {
  isEditing?: boolean;
  searchInfo:
    | (BusSearchInfo & {
        travelName: string;
      })
    | null;
};
interface TripActions {
  handleCloseSeatsDrawer: () => void;
  handleProgressBooking: (busId: number) => () => void;
  handleRefreshBusSeats: (busId: number) => () => Promise<void>;
  handleSeatSelection: (
    busId: number,
    tripPartId: string,
  ) => (selectedSeats: BusTripDetailsContentSeat[]) => void;
  handleTripDetailsClick: (trip: TripConnection) => () => Promise<void>;
}
export type TripState = {
  allTripsState: {
    [id: number]: {
      error?: Error;
      id: number;
      isLoadingDetails: boolean;
      isOpen: boolean;
      tripPartsState: {
        [id: string]: {
          id: string;
          seats: BusTripDetailsContentSeat[];
          selectedSeats: BusTripDetailsContentSeat[];
        };
      };
    };
  };
  selectedDepartureBusId: string | null;
  selectedReturnBusId: string | null;
  visibleTrip: TripConnection | null;
  visibleTrips: TripConnection[];
};

const initialFiltersState: FiltersState = {
  arrivalOptions: [],
  categoryOptions: [],
  companyOptions: [],
  departureOptions: [],
  priceRange: [0, 0],
  selectedPriceRange: [0, 0],
};
const initialPageState: PageState = {
  countryRestriction: null,
  currentSection: BUS_STRETCH_TYPE.DEPARTURE,
  departureFiltersInfo: {
    arrivalTerminals: [],
    categories: [],
    companies: [],
    departureTerminals: [],
    maxPrice: 0,
    minPrice: 0,
  },
  departureTrips: [],
  errorOnFetch: null,
  generalError: null,
  goToItinerary: false,
  isCategoriesExplanationVisible: false,
  isFakeLoading: true,
  isFiltering: false,
  isFiltersDrawerOpen: false,
  isLoading: true,
  returnFiltersInfo: {
    arrivalTerminals: [],
    categories: [],
    companies: [],
    departureTerminals: [],
    maxPrice: 0,
    minPrice: 0,
  },
  returnTrips: [],
};
const initialSearchState: SearchState = {
  isEditing: false,
  searchInfo: null,
};
const initialTripState: TripState = {
  allTripsState: {},
  selectedDepartureBusId: null,
  selectedReturnBusId: null,
  visibleTrip: null,
  visibleTrips: [],
};

const BusesFiltersActionsContext = createContext<FiltersActions>({
  handleApplyFilters: () => {
    return;
  },
  handleArrivalFilterChange: () => {
    return;
  },
  handleCategoryFilterChange: () => {
    return;
  },
  handleCompanyFilterChange: () => {
    return;
  },
  handleDepartureFilterChange: () => {
    return;
  },
  handlePriceFilterChange: () => {
    return;
  },
});
const BusesFiltersContext = createContext<FiltersState>(initialFiltersState);
const BusesPageActionsContext = createContext<PageActions>({
  fetchBusTrips: () => {
    return;
  },
  handleChangeSection: () => () => {
    return;
  },
  handleCloseFiltersDrawer: () => {
    return;
  },
  handleOpenFiltersDrawer: () => {
    return;
  },
  handleToggleCategoryExplanation: () => {
    return;
  },
});
const BusesPageContext = createContext<PageState>(initialPageState);
const BusesSearchActionsContext = createContext<SearchActions>({
  handleCloseEdition: () => {
    return;
  },
  handleEditClick: () => {
    return;
  },
});
const BusesSearchContext = createContext<SearchState>(initialSearchState);
const BusesTripActionsContext = createContext<TripActions>({
  handleCloseSeatsDrawer: () => {
    return;
  },
  handleProgressBooking: () => async () => {
    return;
  },
  handleRefreshBusSeats: () => async () => {
    return;
  },
  handleSeatSelection: () => () => {
    return;
  },
  handleTripDetailsClick: () => async () => {
    return;
  },
});
const BusesTripContext = createContext<TripState>(initialTripState);

export const useBusesFiltersActions = useContextFactory(
  "BusesFiltersActionsContext",
  BusesFiltersActionsContext,
);
export const useBusesFilters = useContextFactory(
  "BusesFiltersContext",
  BusesFiltersContext,
);
export const useBusesPageActions = useContextFactory(
  "BusesPageActionsContext",
  BusesPageActionsContext,
);
export const useBusesPage = useContextFactory(
  "BusesPageContext",
  BusesPageContext,
);
export const useBusesSearchActions = useContextFactory(
  "BusesSearchActionsContext",
  BusesSearchActionsContext,
);
export const useBusesSearch = useContextFactory(
  "BusesSearchContext",
  BusesSearchContext,
);
export const useBusesTrip = useContextFactory(
  "BusesTripContext",
  BusesTripContext,
);
export const useBusesTripActions = useContextFactory(
  "BusesTripActionsContext",
  BusesTripActionsContext,
);

type Props = {
  busToken: string;
};

export const BusesProvider: React.FC<Props> = ({ children, busToken }) => {
  const { showSnackMessage } = useApplication();

  const [filtersState, setFiltersState] = useState(initialFiltersState);
  const [pageState, setPageState] = useState(initialPageState);
  const [searchState, setSearchState] = useState(initialSearchState);
  const [tripState, setTripState] = useState(initialTripState);
  const visibleTrips = useVisibleTrips(pageState, filtersState);

  useFocusStretchTabs(pageState.currentSection);

  const currentBuses =
    pageState.currentSection === BUS_STRETCH_TYPE.DEPARTURE
      ? pageState.departureTrips
      : pageState.returnTrips;

  useEffect(() => {
    if (pageState.currentSection === BUS_STRETCH_TYPE.RETURN) {
      setFiltersInfo(pageState.returnFiltersInfo);

      return;
    }

    if (!isEmpty(pageState.departureFiltersInfo)) {
      setFiltersInfo(pageState.departureFiltersInfo);

      return;
    }

    return;
  }, [pageState.currentSection]);

  useEffect(() => {
    if (!pageState.goToItinerary || !searchState.searchInfo) {
      return;
    }

    const { travelToken } = searchState.searchInfo;

    //! Ainda não existe política para ônibus
    const policyStatus = {
      outOfPolicy: false,
    };

    const busOffer = busHelper.buildBusOffer(
      tripState,
      pageState,
      searchState,
      policyStatus,
    );

    void (async function addOfferToItinerary(busOfferToInsert) {
      const {
        error: busInsertionError,
      } = await busesService.addBusOfferToTravel(busOfferToInsert);
      if (busInsertionError) {
        setPageState((prev) => ({
          ...prev,
          goToItinerary: false,
        }));
        showSnackMessage(busInsertionError.description, ALERT_TYPES.ERROR);
      } else {
        navigate(`/travels/${travelToken}/itinerary`);

        showSnackMessage("Oferta adicionada com sucesso", ALERT_TYPES.SUCCESS);
      }
    })(busOffer);
  }, [pageState.goToItinerary]);

  const fetchBusTrips = async (busSearchToken: string) => {
    setPageState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const { data, error } = await busesService.getBusTripsResult(
      busSearchToken,
    );

    if (error) {
      setFiltersState(initialFiltersState);
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        errorOnFetch: error,
      }));

      return;
    }

    if (!data) {
      setFiltersState(initialFiltersState);
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      return;
    }

    const {
      countryRestriction,
      departureFiltersInfo,
      departures: departureTrips,
      returnFiltersInfo,
      returns: returnTrips,
      searchInfo,
      travelInfo,
    } = data;

    setPageState((prev) => ({
      ...prev,
      countryRestriction,
      departureFiltersInfo,
      departureTrips,
      isLoading: false,
      returnFiltersInfo,
      returnTrips,
    }));
    setSearchState({
      searchInfo: {
        ...searchInfo,
        travelName: travelInfo.travelName,
        travelToken: travelInfo.travelToken,
      },
    });

    const allTripsState = [...departureTrips, ...returnTrips].reduce(
      (prev, curr) => {
        prev[curr.id] = {
          id: curr.id,
          isLoadingDetails: false,
          isOpen: false,
          tripPartsState: curr.parts.reduce((prev, curr) => {
            prev[curr.tripId] = {
              id: curr.tripId,
              seats: [],
              selectedSeats: [],
            };

            return prev;
          }, {} as TripState["allTripsState"][number]["tripPartsState"]),
        };

        return prev;
      },
      {} as TripState["allTripsState"],
    );

    setFiltersInfo(departureFiltersInfo);
    setTripState((prev) => ({
      ...prev,
      allTripsState,
    }));
  };

  const handleChangeSection = (
    stretchType: typeof BUS_STRETCH_TYPE[keyof typeof BUS_STRETCH_TYPE],
  ) => {
    const nextClick = () => {
      const { currentSection } = pageState;

      if (currentSection === stretchType) {
        return null;
      } else if (stretchType === BUS_STRETCH_TYPE.DEPARTURE) {
        setPageState((prev) => ({
          ...prev,
          currentSection: BUS_STRETCH_TYPE.DEPARTURE,
        }));
      }
    };

    return nextClick;
  };

  const handleOpenFiltersDrawer = () => {
    setPageState((prev) => ({
      ...prev,
      isFiltersDrawerOpen: true,
    }));
  };

  const handleCloseFiltersDrawer = () => {
    setPageState((prev) => ({
      ...prev,
      isFiltersDrawerOpen: false,
    }));
  };

  const handleToggleCategoryExplanation = () => {
    setPageState((prev) => ({
      ...prev,
      isCategoriesExplanationVisible: !prev.isCategoriesExplanationVisible,
    }));
  };

  const setFiltersInfo = (filtersInfo: FiltersInfo) => {
    const sortedCategories = sortBy(
      filtersInfo.categories,
      (category) => category.id,
    );

    const newFiltersState = {
      arrivalOptions: filtersInfo.arrivalTerminals.map((item) => ({
        checked: false,
        id: item.name,
        label: item.name,
      })),
      categoryOptions: sortedCategories.map((item) => ({
        checked: false,
        id: item.name, // name used to remove duplicate IDs on the page
        label: item.name,
      })),
      companyOptions: filtersInfo.companies.map((item) => ({
        checked: false,
        id: item.id,
        label: item.name,
      })),
      departureOptions: filtersInfo.departureTerminals.map((item) => ({
        checked: false,
        id: item.name,
        label: item.name,
      })),
      priceRange: [
        Math.floor(filtersInfo.minPrice),
        Math.ceil(filtersInfo.maxPrice),
      ],
      selectedPriceRange: [
        Math.floor(filtersInfo.minPrice),
        Math.ceil(filtersInfo.maxPrice),
      ],
    };

    setFiltersState(newFiltersState);
  };

  const filterChange = (
    filterName: keyof typeof filtersState,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (filterName === "priceRange" || filterName === "selectedPriceRange") {
      return;
    }

    setPageState((prev) => {
      return {
        ...prev,
        isFiltering: true,
      };
    });

    const result = filtersState[filterName].map((option) => {
      if (option.id === e.target.id) {
        return { ...option, checked: e.target.checked };
      }

      return option;
    });

    setFiltersState((prev) => {
      return {
        ...prev,
        [filterName]: result,
      };
    });
    setPageState((prev) => ({
      ...prev,
      isFiltering: false,
    }));
  };

  const handleCompanyFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    filterChange("companyOptions", e);
  };

  const handleDepartureFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    filterChange("departureOptions", e);
  };

  const handleArrivalFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    filterChange("arrivalOptions", e);
  };

  const handleCategoryFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    filterChange("categoryOptions", e);
  };

  const handlePriceFilterChange = (range: number[]) => {
    setFiltersState((prev) => ({
      ...prev,
      selectedPriceRange: range,
    }));
  };

  const handleApplyFilters = (filters: typeof filtersState) => {
    const applyClick = () => {
      setFiltersState((prev) => ({
        ...prev,
        ...filters,
      }));
      setPageState((prev) => ({
        ...prev,
        isFiltersDrawerOpen: false,
      }));
    };

    return applyClick;
  };

  const handleEditClick = () => {
    setSearchState((prev) => ({
      ...prev,
      isEditing: true,
    }));
  };

  const handleCloseEdition = () => {
    setSearchState((prev) => ({
      ...prev,
      isEditing: false,
    }));
  };

  const handleTripDetailsClick = (trip: TripConnection) => {
    const onLoadDetailsClick = async () => {
      let isLoadingNeeded;
      const busId = trip.id;

      setTripState((prev) => {
        const prevTripState = prev.allTripsState[busId];
        isLoadingNeeded = !prevTripState.isOpen;

        return {
          ...prev,
          allTripsState: Object.assign({}, prev.allTripsState, {
            [busId]: {
              ...prevTripState,
              isOpen: isLoadingNeeded,
              isLoadingDetails: isLoadingNeeded,
            },
          }),
          visibleTrip: isLoadingNeeded ? trip : null,
        };
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isLoadingNeeded) {
        await retrieveBusDetails(busId);
      }
    };

    return onLoadDetailsClick;
  };

  const handleCloseSeatsDrawer = () => {
    const { visibleTrip } = tripState;

    setTripState((prev) => {
      const prevTripState = visibleTrip
        ? prev.allTripsState[visibleTrip.id]
        : {};

      if (!visibleTrip) {
        return {
          ...prev,
          visibleTrip: null,
        };
      }

      return {
        ...prev,
        allTripsState: Object.assign({}, prev.allTripsState, {
          [visibleTrip.id]: {
            ...prevTripState,
            isOpen: false,
            isLoadingDetails: false,
          },
        }),
        visibleTrip: null,
      };
    });
  };

  const handleRefreshBusSeats = (busId: number) => {
    const onRefreshClick = async () => {
      setTripState((prev) => {
        const prevTripState = prev.allTripsState[busId];

        return {
          ...prev,
          allTripsState: Object.assign({}, prev.allTripsState, {
            [busId]: {
              ...prevTripState,
              isLoadingDetails: true,
            },
          }),
        };
      });

      await retrieveBusDetails(busId);
    };

    return onRefreshClick;
  };

  const retrieveBusDetails = async (busId: number) => {
    const selectedBus = currentBuses.find((bus) => bus.id === busId);

    if (!selectedBus) {
      return;
    }

    const { data, error } = await busesService.getBusTripDetailsResult(
      selectedBus.parts.map((part) => ({
        busToken,
        tripId: part.tripId,
      })),
    );

    if (error) {
      setTripState((prev) => ({
        ...prev,
        allTripsState: Object.assign({}, prev.allTripsState, {
          [busId]: {
            ...prev.allTripsState[busId],
            error: error,
            isLoadingDetails: false,
          },
        }),
      }));

      return;
    }

    if (!data) {
      setTripState((prev) => ({
        ...prev,
        allTripsState: Object.assign({}, prev.allTripsState, {
          [busId]: {
            ...prev.allTripsState[busId],
            isLoadingDetails: false,
          },
        }),
      }));

      return;
    }

    setTripState((prev) => ({
      ...prev,
      allTripsState: Object.assign({}, prev.allTripsState, {
        [busId]: {
          ...prev.allTripsState[busId],
          error: null,
          isLoadingDetails: false,
          isOpen: true,
          tripPartsState: data.reduce((prev, curr) => {
            prev[curr.tripId] = {
              id: curr.tripId,
              seats: curr.seats,
              selectedSeats: [],
            };

            return prev;
          }, {} as TripState["allTripsState"][number]["tripPartsState"]),
        },
      }),
    }));
  };

  const handleSeatSelection = (busId: number, tripPartId: string) => {
    const handleUpdatedSelectedSeats = (
      selectedSeats: BusTripDetailsContentSeat[],
    ) => {
      setTripState((prev) => {
        const busState = {
          ...prev.allTripsState[busId],
          tripPartsState: {
            ...prev.allTripsState[busId].tripPartsState,
            [tripPartId]: {
              ...prev.allTripsState[busId].tripPartsState[tripPartId],
              selectedSeats,
            },
          },
        };

        const newAllTripsState = Object.assign({}, prev.allTripsState, {
          [busId]: busState,
        });

        return {
          ...prev,
          allTripsState: newAllTripsState,
        };
      });
    };

    return handleUpdatedSelectedSeats;
  };

  const handleProgressBooking = (busId: number) => {
    const onNextClick = () => {
      // Close seats drawer and close opened bus offer
      handleCloseSeatsDrawer();

      if (pageState.currentSection === BUS_STRETCH_TYPE.DEPARTURE) {
        setTripState((prev) => ({
          ...prev,
          selectedDepartureBusId: busId.toString(),
        }));

        if (searchState.searchInfo?.oneway) {
          setPageState((prev) => ({
            ...prev,
            goToItinerary: true,
          }));
        } else {
          setPageState((prev) => ({
            ...prev,
            currentSection: BUS_STRETCH_TYPE.RETURN,
          }));
        }
      } else {
        setPageState((prev) => ({
          ...prev,
          goToItinerary: true,
        }));
        setTripState((prev) => ({
          ...prev,
          selectedReturnBusId: busId.toString(),
        }));
      }
    };

    return onNextClick;
  };

  useEffect(() => {
    setTimeout(() => {
      setPageState((prev) => ({
        ...prev,
        isFakeLoading: false,
      }));
    }, 3000);
  }, []);

  return (
    <BusesPageContext.Provider value={pageState}>
      <BusesPageActionsContext.Provider
        value={{
          fetchBusTrips,
          handleChangeSection,
          handleCloseFiltersDrawer,
          handleOpenFiltersDrawer,
          handleToggleCategoryExplanation,
        }}
      >
        <BusesTripContext.Provider value={{ ...tripState, visibleTrips }}>
          <BusesTripActionsContext.Provider
            value={{
              handleCloseSeatsDrawer,
              handleProgressBooking,
              handleRefreshBusSeats,
              handleSeatSelection,
              handleTripDetailsClick,
            }}
          >
            <BusesFiltersContext.Provider value={filtersState}>
              <BusesFiltersActionsContext.Provider
                value={{
                  handleApplyFilters,
                  handleArrivalFilterChange,
                  handleCategoryFilterChange,
                  handleCompanyFilterChange,
                  handleDepartureFilterChange,
                  handlePriceFilterChange,
                }}
              >
                <BusesSearchContext.Provider value={searchState}>
                  <BusesSearchActionsContext.Provider
                    value={{
                      handleCloseEdition,
                      handleEditClick,
                    }}
                  >
                    {children}
                  </BusesSearchActionsContext.Provider>
                </BusesSearchContext.Provider>
              </BusesFiltersActionsContext.Provider>
            </BusesFiltersContext.Provider>
          </BusesTripActionsContext.Provider>
        </BusesTripContext.Provider>
      </BusesPageActionsContext.Provider>
    </BusesPageContext.Provider>
  );
};
