import React, { createContext, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { BillingProfileListItem } from "@models/billing-profile.model";

import { useContextFactory } from "@hooks";

import * as billingProfilesService from "./BillingProfiles.service";
import { BillingProfileForm } from "./BillingProfiles.types";

interface DeletionActions {
  handleCancelDeletion: () => void;
  handleDelete: (profle: BillingProfileListItem) => void;
  processDelete: () => void;
}

type DeletionState = {
  isDeleting: boolean;
  selectedBillingProfile: BillingProfileListItem | null;
};

const deletionInitialState: DeletionState = {
  isDeleting: false,
  selectedBillingProfile: null,
};

interface FormActions {
  handleCloseForm: () => void;
  handleEditProfile: (profile: BillingProfileListItem) => void;
  handleOpenForm: () => void;
  handleSubmit: (formData: BillingProfileForm) => void;
}

type FormState = {
  isFormOpen: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  selectedBillingProfile: BillingProfileListItem | null;
};

const formInitialState: FormState = {
  isFormOpen: false,
  isLoading: false,
  isSubmitting: false,
  selectedBillingProfile: null,
};

interface PageActions {
  loadProfiles: () => void;
}
type PageState = {
  billingProfiles: BillingProfileListItem[];
  isLoading: boolean;
};

const pageInitialState: PageState = {
  billingProfiles: [],
  isLoading: false,
};

const BillingProfileDeletionActionsContext = createContext<DeletionActions>(
  {} as DeletionActions,
);
const BillingProfileDeletionContext = createContext<DeletionState>(
  {} as DeletionState,
);

const BillingProfileFormActionsContext = createContext<FormActions>(
  {} as FormActions,
);
const BillingProfileFormContext = createContext<FormState>({} as FormState);

const BillingProfileActionsContext = createContext<PageActions>(
  {} as PageActions,
);
const BillingProfileContext = createContext<PageState>({} as PageState);

export const BillingProfilesProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [deletionState, setDeletionState] = useState(deletionInitialState);
  const [formState, setFormState] = useState(formInitialState);
  const [pageState, setPageState] = useState(pageInitialState);

  const handleCancelDeletion = () => {
    if (deletionState.isDeleting) {
      return null;
    }

    setDeletionState((prev) => ({
      ...prev,
      selectedBillingProfile: null,
    }));
  };

  const handleCloseForm = () => {
    if (formState.isSubmitting) {
      return null;
    }

    setFormState((prev) => ({
      ...prev,
      isFormOpen: false,
      isLoading: false,
      selectedBillingProfile: null,
    }));
  };

  const handleDelete = (profile: BillingProfileListItem) => {
    setDeletionState((prev) => ({
      ...prev,
      selectedBillingProfile: profile,
    }));
  };

  const handleEditProfile = async (profile: BillingProfileListItem) => {
    setFormState((prev) => ({
      ...prev,
      isFormOpen: true,
      isLoading: true,
    }));

    const {
      data,
      error,
    } = await billingProfilesService.getSingleBillingProfile(
      profile.billingProfileToken,
    );

    if (error) {
      setFormState((prev) => ({
        ...prev,
        isFormOpen: false,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data) {
      setFormState((prev) => ({
        ...prev,
        isFormOpen: false,
        isLoading: false,
      }));

      return;
    }

    setFormState((prev) => ({
      ...prev,
      isLoading: false,
      selectedBillingProfile: data,
    }));
  };

  const handleOpenForm = () => {
    setFormState((prev) => ({
      ...prev,
      isFormOpen: true,
    }));
  };

  const handleSubmit = async (formData: BillingProfileForm) => {
    const { selectedBillingProfile } = formState;

    if (selectedBillingProfile) {
      await updateBillingProfile(
        formData,
        selectedBillingProfile.billingProfileToken,
      );

      return;
    }

    await createBillingProfile(formData);
  };

  const loadProfiles = async () => {
    setPageState((prev) => ({ ...prev, isLoading: true }));

    const {
      data,
      error,
    } = await billingProfilesService.getClientBillingProfilesList();

    if (error) {
      setPageState((prev) => ({ ...prev, isLoading: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setPageState((prev) => ({
      ...prev,
      billingProfiles: data!,
      isLoading: false,
    }));
  };

  const createBillingProfile = async (formData: BillingProfileForm) => {
    setFormState((prev) => ({ ...prev, isSubmitting: true }));

    const { data, error } = await billingProfilesService.createBillingProfile(
      formData,
    );

    if (error) {
      setFormState((prev) => ({ ...prev, isSubmitting: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const newProfile: BillingProfileListItem = Object.assign({}, formData, {
      billingProfileToken: data!,
    });

    setFormState((prev) => ({
      ...prev,
      isFormOpen: false,
      isSubmitting: false,
      selectedBillingProfile: null,
    }));

    setPageState((prev) => ({
      ...prev,
      billingProfiles: prev.billingProfiles.concat(newProfile),
    }));

    showSnackMessage(
      "Perfil de cobrança criado com sucesso",
      ALERT_TYPES.SUCCESS,
    );
  };

  const updateBillingProfile = async (
    formData: BillingProfileForm,
    billingProfileToken: string,
  ) => {
    setFormState((prev) => ({ ...prev, isSubmitting: true }));

    const { error } = await billingProfilesService.updateBillingProfile(
      formData,
      billingProfileToken,
    );

    if (error) {
      setFormState((prev) => ({ ...prev, isSubmitting: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setFormState((prev) => ({
      ...prev,
      isFormOpen: false,
      isSubmitting: false,
      selectedBillingProfile: null,
    }));

    setPageState((prev) => ({
      ...prev,
      billingProfiles: prev.billingProfiles.map((profile) => {
        if (profile.billingProfileToken === billingProfileToken) {
          return Object.assign({}, profile, formData);
        }

        return profile;
      }),
    }));

    showSnackMessage(
      "Perfil de cobrança atualizado com sucesso",
      ALERT_TYPES.SUCCESS,
    );
  };

  const processDelete = async () => {
    const { selectedBillingProfile } = deletionState;

    setDeletionState((prev) => ({ ...prev, isDeleting: true }));

    const { error } = await billingProfilesService.deleteBillingProfile(
      selectedBillingProfile!.billingProfileToken,
    );

    if (error) {
      setDeletionState((prev) => ({
        ...prev,
        isDeleting: false,
        selectedBillingProfile: null,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setDeletionState((prev) => ({
      ...prev,
      isDeleting: false,
      selectedBillingProfile: null,
    }));

    setPageState((prev) => ({
      ...prev,
      billingProfiles: prev.billingProfiles.filter(
        (profile) =>
          profile.billingProfileToken !==
          selectedBillingProfile!.billingProfileToken,
      ),
    }));

    showSnackMessage(
      "Perfil de cobrança excluído com sucesso.",
      ALERT_TYPES.SUCCESS,
    );
  };

  return (
    <BillingProfileContext.Provider value={pageState}>
      <BillingProfileActionsContext.Provider
        value={{
          loadProfiles,
        }}
      >
        <BillingProfileFormContext.Provider value={formState}>
          <BillingProfileFormActionsContext.Provider
            value={{
              handleCloseForm,
              handleEditProfile,
              handleOpenForm,
              handleSubmit,
            }}
          >
            <BillingProfileDeletionContext.Provider value={deletionState}>
              <BillingProfileDeletionActionsContext.Provider
                value={{
                  handleCancelDeletion,
                  handleDelete,
                  processDelete,
                }}
              >
                {children}
              </BillingProfileDeletionActionsContext.Provider>
            </BillingProfileDeletionContext.Provider>
          </BillingProfileFormActionsContext.Provider>
        </BillingProfileFormContext.Provider>
      </BillingProfileActionsContext.Provider>
    </BillingProfileContext.Provider>
  );
};

export const useBillingProfileActionsContext = useContextFactory(
  "BillingProfileActionsContext",
  BillingProfileActionsContext,
);

export const useBillingProfileContext = useContextFactory(
  "BillingProfileContext",
  BillingProfileContext,
);

export const useBillingProfileDeletionActionsContext = useContextFactory(
  "BillingProfileDeletionActionsContext",
  BillingProfileDeletionActionsContext,
);

export const useBillingProfileDeletionContext = useContextFactory(
  "BillingProfileDeletionContext",
  BillingProfileDeletionContext,
);

export const useBillingProfileFormActionsContext = useContextFactory(
  "BillingProfileFormActionsContext",
  BillingProfileFormActionsContext,
);

export const useBillingProfileFormContext = useContextFactory(
  "BillingProfileFormContext",
  BillingProfileFormContext,
);
