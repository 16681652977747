import React, { useMemo, useState } from "react";
import {
  ArrayField,
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from "react-hook-form";

import { FormGroup } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";

import { APPROVAL_PROCESS_TYPES } from "~/constants";

import { ApprovalProcessRulesType } from "~/models/approval-process.model";

import { useFormContext } from "~/components/shared/form";
import { FormFieldAdditionalText } from "~/components/shared/form/FormField";

import IconButton from "@components/shared/icon-button";

import { ApprovalProcessesFormSelect } from "../approval-process-form-select/approval-process-form-select";
import {
  APPROVAL_PROCESS_TYPES_OPTIONS,
  POLICIES_OPTIONS,
  TERRITORIES_OPTIONS,
} from "../approval-process-options/approval-process-options";
import { ApprovalProcessRuleActions } from "../approval-process-rule-actions/approval-process-rule-actions";
import { styles } from "../styles";

interface IApprovalProcessNotifyRule {
  control: Control;
  errors: DeepMap<FieldValues, FieldError>;
  field: Partial<ArrayField<ApprovalProcessRulesType, "id">>;
  removeRule: (index: number) => void;
  ruleIndex: number;
  watchFields: {
    rules: ApprovalProcessRulesType[];
    approvalNeeded: boolean;
  };
}

export const ApprovalProcessNotifyRule = ({
  control,
  errors,
  field,
  removeRule,
  ruleIndex,
  watchFields,
}: IApprovalProcessNotifyRule) => {
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const handleMouseEnter = () => setShowRemoveButton(true);
  const handleMouseLeave = () => setShowRemoveButton(false);

  const { watch, setValue } = useFormContext();
  const rule: ApprovalProcessRulesType = watch(`rules[${ruleIndex}]`);

  const availablePoliciesOptions = useMemo(() => {
    let policiesOptions = [...POLICIES_OPTIONS];

    const rulesWithTerritoryAlreadySelected = watchFields.rules.filter(
      (r) =>
        r.territory?.value === rule.territory?.value &&
        r.type.value === APPROVAL_PROCESS_TYPES.NOTIFY,
    );

    if (rulesWithTerritoryAlreadySelected.length) {
      rulesWithTerritoryAlreadySelected.forEach((rule) => {
        policiesOptions = policiesOptions.filter(
          (policy) => policy.value !== rule.policy?.value,
        );
      });
    }

    const ruleSelected = watchFields.rules.find(
      (_, index) => index === ruleIndex,
    );

    if (
      ruleSelected?.policy &&
      ruleSelected.policy.value === rule.policy?.value
    ) {
      policiesOptions.unshift(ruleSelected.policy);
    }

    return policiesOptions;
  }, [rule.policy?.value, rule.territory?.value, ruleIndex, watchFields.rules]);

  const availableTerritoriesOptions = useMemo(() => {
    let territoriesOptions = [...TERRITORIES_OPTIONS];

    const rulesWithPolicyAlreadySelected = watchFields.rules.filter(
      (r) =>
        r.policy?.value === rule.policy?.value &&
        r.type.value === APPROVAL_PROCESS_TYPES.NOTIFY,
    );

    if (rulesWithPolicyAlreadySelected.length) {
      rulesWithPolicyAlreadySelected.forEach((rule) => {
        territoriesOptions = territoriesOptions.filter(
          (territory) => territory.value !== rule.territory?.value,
        );
      });
    }

    const ruleSelected = watchFields.rules.find(
      (_, index) => index === ruleIndex,
    );

    if (
      ruleSelected?.territory &&
      ruleSelected.territory.value === rule.territory?.value
    ) {
      territoriesOptions.unshift(ruleSelected.territory);
    }

    return territoriesOptions;
  }, [rule.policy?.value, rule.territory?.value, ruleIndex, watchFields.rules]);

  return (
    <div
      className={styles.groupContainer}
      key={field.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.messageContainer}>
          <FormGroup className={styles.formGroup}>
            <Controller
              control={control}
              defaultValue={rule.type}
              name={`rules[${ruleIndex}].type`}
              render={({ onChange, value }) => (
                <ApprovalProcessesFormSelect
                  options={
                    watchFields.approvalNeeded
                      ? APPROVAL_PROCESS_TYPES_OPTIONS
                      : [APPROVAL_PROCESS_TYPES_OPTIONS[1]]
                  }
                  defaultValue={
                    watchFields.approvalNeeded
                      ? APPROVAL_PROCESS_TYPES_OPTIONS[0]
                      : APPROVAL_PROCESS_TYPES_OPTIONS[1]
                  }
                  onChange={(e) => {
                    onChange(e);
                    setValue(`rules[${ruleIndex}].policy`, undefined);
                    setValue(`rules[${ruleIndex}].territory`, undefined);
                  }}
                  value={value}
                />
              )}
            />
          </FormGroup>
          <span>para viagens</span>
          <FormGroup className={styles.formGroup}>
            <Controller
              as={
                <ApprovalProcessesFormSelect
                  noOptionsMessage={() =>
                    "Todas as opções possíveis foram usadas"
                  }
                  options={availablePoliciesOptions}
                  placeholder="dentro ou fora"
                />
              }
              control={control}
              defaultValue={rule.policy}
              name={`rules[${ruleIndex}].policy`}
            />
            <FormFieldAdditionalText className={styles.messageError} hasError>
              {errors.rules?.[ruleIndex]?.policy?.message}
            </FormFieldAdditionalText>
          </FormGroup>
          <span>da política no âmbito</span>
          <FormGroup className={styles.formGroup}>
            <Controller
              as={
                <ApprovalProcessesFormSelect
                  options={availableTerritoriesOptions}
                  placeholder="nacional ou internacional"
                  noOptionsMessage={() =>
                    "Todas as opções possíveis foram usadas"
                  }
                />
              }
              control={control}
              defaultValue={rule.territory}
              name={`rules[${ruleIndex}].territory`}
            />
            <FormFieldAdditionalText className={styles.messageError} hasError>
              {errors.rules?.[ruleIndex]?.territory?.message}
            </FormFieldAdditionalText>
          </FormGroup>
          <span>para os seguintes usuários:</span>
        </div>
        {showRemoveButton && (
          <IconButton
            icon={<CloseIcon />}
            onClick={() => removeRule(ruleIndex)}
            style={{ width: "2rem", height: "2rem", marginRight: "0.25rem" }}
          />
        )}
      </div>
      <ApprovalProcessRuleActions control={control} ruleIndex={ruleIndex} />
    </div>
  );
};
