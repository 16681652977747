import React, { useContext } from "react";

import { Row } from "../../../../../../../../components/shared/layout/Row";
import { PaymentMethodsContext } from "../PaymentMethods.context";
import { VcnItem } from "./vcn-item/VcnItem.container";

export const SpecialCardsList = () => {
  const { vcns } = useContext(PaymentMethodsContext);

  return (
    <Row
      style={{
        width: "100%",
        flexWrap: "wrap"
      }}
    >
      {vcns.map(vcn => (
        <VcnItem key={vcn.vcnToken} vcn={vcn} />
      ))}
    </Row>
  );
};
