import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "16px",
    ".MuiPaper-root": {
      maxWidth: "768px",
    },
  }),
  table: {
    td: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      padding: "8px",
    }),
    th: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      fontWeight: 600,
      padding: "8px",
      textAlign: "left",
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontSize: "1rem",
    fontWeight: 600,
  }),
};
