import React from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";
import clsx from "classnames";

const styles = {
  root: css({
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  }),
  text: css({
    fontSize: "inherit",
    color: defaultTheme.primaryColor,
    marginLeft: ".5rem",
    ["&:hover"]: {
      color: "#2472f2"
    }
  }),
  arrowIcon: css({
    fontSize: 18
  }),
  circularProgress: css({
    color: defaultTheme.textColor,
    width: "18px !important",
    height: "18px !important"
  })
};

const blankClick = () => null;

interface Props {
  children: string;
  isDowloading: boolean;
  containerClasses?: string;
  handleDownload: () => void;
}

const DownloadButton = ({
  children,
  isDowloading,
  containerClasses,
  handleDownload
}: Props) => {
  return (
    <div
      className={clsx(styles.root, containerClasses)}
      onClick={!isDowloading ? handleDownload : blankClick}
    >
      {isDowloading ? (
        <CircularProgress className={styles.circularProgress} />
      ) : (
        <ArrowDownwardIcon className={styles.arrowIcon} />
      )}
      <div className={styles.text}>
        <span>{children}</span>
      </div>
    </div>
  );
};

DownloadButton.defaultProps = {
  containerClasses: "",
  isDowloading: false
} as Partial<Props>;

export { DownloadButton };
