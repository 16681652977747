import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { User } from "~/apps/corporate/models/user.model";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";

import { styles } from "./styles";
import {
  UserListItemActions,
  UserListItemActionsSkeleton,
} from "./user-list-item-actions/users-list-item-actions";
import { UserListItemRoleBadge } from "./user-list-item-role-badge/user-list-item-role-badge";
import { UsersUserDeletionAlertDialog } from "./users-user-deletion-alert-dialog/users-user-deletion-alert-dialog";

type Props = {
  onUserDelete: (user: User) => Promise<void>;
  onUserEditClick: (userToken: string, guest?: boolean | null) => () => void;
  onUserUnarchive: (user: User) => Promise<void>;
  user: User;
  visibility?: "active" | "inactive";
};

export const UserListItem: React.FC<Props> = ({
  onUserDelete,
  onUserEditClick,
  onUserUnarchive,
  user,
  visibility,
}) => {
  // const { clientBosses } = useClient();

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  // const userBoss = useMemo(() => {
  //   if (!clientBosses) {
  //     return null;
  //   }

  //   const userBoss = clientBosses.find(
  //     (clientBoss) => clientBoss.employeeToken === user.userToken,
  //   );

  //   if (!userBoss) {
  //     return null;
  //   }

  //   return userBoss;
  // }, [clientBosses]);

  const onCancelDelete = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const onConfirmDelete = useCallback(
    (user: User) => {
      return async () => {
        void onUserDelete(user);

        onCancelDelete();
      };
    },
    [onCancelDelete, onUserDelete],
  );

  const onUserDeleteClick = useCallback(() => {
    setShowDeleteDialog(true);
  }, []);

  return (
    <>
      <div
        css={styles.root}
        onClick={
          visibility === "active"
            ? onUserEditClick(user.userToken, user.guest)
            : undefined
        }
      >
        <div css={styles.left.root}>
          <Avatar css={styles.left.avatar} name={user.fullName} />
          <div css={styles.left.info.root}>
            <span css={styles.left.info.name}>{user.fullName}</span>
            <span css={styles.left.info.email}>{user.email}</span>
            {/* {userBoss ? (
              <span css={styles.left.info.boss}>Supervisor: asdasdsa</span>
            ) : null} */}
          </div>
        </div>
        <div css={styles.right.root}>
          {user.role ? <UserListItemRoleBadge role={user.role} /> : null}
          <UserListItemActions
            onUserDelete={onUserDeleteClick}
            onUserEdit={onUserEditClick}
            onUserUnarchive={onUserUnarchive}
            user={user}
            visibility={visibility}
          />
        </div>
      </div>
      {showDeleteDialog ? (
        <UsersUserDeletionAlertDialog
          name={user.fullName ? user.fullName : user.firstName}
          onClose={onCancelDelete}
          onConfirm={onConfirmDelete(user)}
        />
      ) : null}
    </>
  );
};

export const UserListItemSkeleton: React.FC = () => {
  return (
    <div aria-disabled css={styles.root}>
      <div css={styles.left.root}>
        <Skeleton css={styles.left.avatar} height="32px" width="32px" />
        <div css={styles.left.info.root}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="18px" width="192px" />
        </div>
      </div>
      <div css={styles.right.root}>
        <UserListItemActionsSkeleton />
      </div>
    </div>
  );
};
