import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { convertHoursAndMinutes } from "~/apps/shared/utils/convert-hours-and-minutes";
import { formatFlightDuration } from "~/apps/shared/utils/format-flight-duration";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  arrival: {
    airportCode: string;
    time: string;
  };
  baggage?: {
    checked: {
      included: boolean;
      quantity: number;
    };
  };
  carrier: {
    imageUrl: string;
    name: string;
  };
  connection?: {};
  daysDiff: number;
  departure: {
    airportCode: string;
    time: string;
  };
  duration: string;
  stops?: {
    code: string;
    name: string;
    durationHours: string;
  }[];
};

export const ItineraryFlightSegment: React.FC<Props> = ({
  arrival,
  baggage,
  carrier,
  stops,
  daysDiff,
  departure,
  duration,
  ...props
}) => {
  return (
    <div css={styles.root} {...props}>
      <img
        alt={carrier.name}
        css={styles.carrier.image}
        height={32}
        src={carrier.imageUrl}
        title={carrier.name}
      />
      <div css={styles.itinerary.root}>
        <div css={styles.itinerary.item.root}>
          <span css={styles.itinerary.item.top}>{departure.time}</span>
          <span css={styles.itinerary.item.bottom}>
            {departure.airportCode}
          </span>
        </div>
        <div css={styles.itinerary.stops.root}>
          <div css={styles.itinerary.stops.dot} />
          {stops
            ? stops.map((stop, index) => {
                const airportCode = stop.code;

                return (
                  <ItineraryFlightSegmentRouteDetailsStopsTooltip
                    key={index}
                    position="top"
                    stops={stops}
                  >
                    <div css={styles.itinerary.stops.stop.root}>
                      <div css={styles.itinerary.stops.dot} />
                      <span css={styles.itinerary.stops.stop.code}>
                        {airportCode}
                      </span>
                    </div>
                  </ItineraryFlightSegmentRouteDetailsStopsTooltip>
                );
              })
            : null}
          <div css={styles.itinerary.stops.dot} />
        </div>
        <div css={styles.itinerary.item.root}>
          <span css={styles.itinerary.item.top}>{arrival.time}</span>
          <span css={styles.itinerary.item.bottom}>{arrival.airportCode}</span>
          {daysDiff > 0 ? (
            <span css={styles.itinerary.item.diff}>+{daysDiff}</span>
          ) : null}
        </div>
      </div>
      <div css={styles.info.root}>
        <span css={styles.info.duration}>{formatFlightDuration(duration)}</span>
        <div>
          {stops ? (
            <ItineraryFlightSegmentRouteDetailsStopsTooltip
              position="top"
              stops={stops}
            >
              <span css={styles.info.stops({ direct: false })}>
                {stops.length > 1
                  ? `${stops.length} conexões`
                  : `${stops.length} conexão`}
              </span>
            </ItineraryFlightSegmentRouteDetailsStopsTooltip>
          ) : (
            <span css={styles.info.stops({ direct: true })}>Direto</span>
          )}
        </div>
      </div>
      {baggage?.checked.included ? (
        <div css={styles.baggage.root}>
          <Icon size={16} use="baggage" />
          <span css={styles.baggage.text}>
            {baggage.checked.quantity > 1 ? baggage.checked.quantity : 1}
          </span>
        </div>
      ) : null}
    </div>
  );
};

type ItineraryFlightSegmentRouteDetailsStopsTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  "content"
> & {
  stops?: {
    name: string;
    durationHours: string;
  }[];
};

const ItineraryFlightSegmentRouteDetailsStopsTooltip: React.FC<ItineraryFlightSegmentRouteDetailsStopsTooltipProps> = ({
  children,
  stops,
  ...props
}) => {
  if (!stops) {
    return null;
  }

  return (
    <Tooltip
      content={
        <>
          {stops.map((stop, index) => {
            const [airportCode, city] = stop.name.split(" - ");

            const formattedName = city
              ? `${city} (${airportCode})`
              : airportCode;

            return (
              <span key={index}>
                {convertHoursAndMinutes(stop.durationHours)}
                <br />
                em {formattedName}
                <br />
              </span>
            );
          })}
        </>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
