import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    ".MuiDrawer-paper": {
      backgroundColor: "white",
      borderRadius: "8px 8px 0 0",
      maxHeight: "calc(100% - 64px)",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "8px",
        "&-thumb": {
          backgroundColor: theme.colors.gray[100],
        },
        "&-track": {
          backgroundColor: theme.colors.white,
        },
      },
    },
  }),
};
