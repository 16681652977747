import React, { useCallback, useEffect, useRef, useState } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { parseAdvanceExpenseCreation } from "~/parsers/advanced-expense.parser";

import { APPROVABLE_TYPES } from "@constants";

import { AdvancedExpenseDetails } from "@models/advanced-expense.model";

import { useContextFactory } from "@hooks";

import * as advancedExpenseService from "./AdvancedExpenses.service";
import { AdvancedExpense } from "./AdvancedExpenses.types";

interface State {
  advancedExpenses: AdvancedExpense[];
  deletionDialogOpen: boolean;
  drawerOpen: boolean;
  isLoading: boolean | null;
  isLoadingDetails: boolean;
  searchInput: string;
  selectedAdvancedExpenseDetails:
    | AdvancedExpenseDetails
    | {
        expenseAdvance: null;
        expenseAdvanceRequest: null;
        expenseAdvanceApprovers: null;
      };
  selectedAdvancedExpenseToken: string | null;
  statusDialogOpen: boolean;
  userHasApprovalRule: boolean | null;
  visibleAdvancedExpenses: AdvancedExpense[];
}

interface Actions {
  addAdvancedExpenseToReport: (reportToken: string) => Promise<void>;
  checkUserHasApprovalRule: () => Promise<void>;
  defineSelectedAdvancedExpense: (advancedExpenseToken: string) => void;
  fetchAdvancedExpenseDetails: (advancedExpenseToken: string) => Promise<void>;
  fetchAdvancedExpenses: () => Promise<void>;
  handleCloseDeletionDialog: () => void;
  handleCloseForm: () => void;
  handleCloseItemStatus: () => void;
  handleCreateAdvancedExpense: (formData: Record<string, any>) => void;
  handleCreateReportWithAdvancedExpense: (formData: {
    description: string;
    reportType: string;
  }) => void;
  handleOpenDeletionDialog: () => void;
  handleOpenForm: () => void;
  handleOpenItemStatus: (advancedExpenseToken: string) => () => void;
  handleSearch: (searchInput: string) => void;
}

type ContextProps = State & Actions;

const initialState: State = {
  advancedExpenses: [],
  deletionDialogOpen: false,
  drawerOpen: false,
  isLoading: null,
  isLoadingDetails: false,
  searchInput: "",
  selectedAdvancedExpenseDetails: {
    expenseAdvance: null,
    expenseAdvanceApprovers: null,
    expenseAdvanceRequest: null,
  },
  selectedAdvancedExpenseToken: null,
  statusDialogOpen: false,
  userHasApprovalRule: null,
  visibleAdvancedExpenses: [],
};

const AdvancedExpensesContext = React.createContext({} as ContextProps);

export const useAdvancedExpensesContext = useContextFactory(
  "AdvancedExpensesContext",
  AdvancedExpensesContext,
);

export const AdvancedExpensesProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const firstRender = useRef(true);

  const fetchAdvancedExpenses = useCallback(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const {
      data: advancedExpenses,
      error,
    } = await advancedExpenseService.fetchAdvancedExpenses();

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({ ...prev, isLoading: false }));

      return;
    }
    setState((prev) => ({
      ...prev,
      advancedExpenses: advancedExpenses!.filter(
        (advancedExpense) => !advancedExpense.expenseReportToken,
      ),
      isLoading: false,
    }));
  }, []);

  const checkUserHasApprovalRule = useCallback(async () => {
    const {
      data: userAdvancedExpenseApprovalRule,
      error,
    } = await advancedExpenseService.fetchUserApprovalRule(
      APPROVABLE_TYPES.EXPENSE_ADVANCE,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }
    const userHasApprovalRule = !!userAdvancedExpenseApprovalRule;

    setState((prev) => ({ ...prev, userHasApprovalRule }));
  }, []);

  const handleSearch = (searchInput: string) => {
    setState((prev) => ({ ...prev, searchInput }));
  };

  const handleOpenForm = () => {
    setState((prev) => ({ ...prev, drawerOpen: true }));
  };

  const handleCloseForm = () => {
    setState((prev) => ({ ...prev, drawerOpen: false }));
  };

  const handleOpenDeletionDialog = () => {
    setState((prev) => ({ ...prev, deletionDialogOpen: true }));
  };

  const handleCloseDeletionDialog = () => {
    setState((prev) => ({ ...prev, deletionDialogOpen: false }));
  };

  const handleOpenItemStatus = useCallback(
    (advancedExpenseToken: string) => () => {
      setState((prev) => ({
        ...prev,
        selectedAdvancedExpenseToken: advancedExpenseToken,
        statusDialogOpen: true,
      }));
    },
    [],
  );

  const fetchAdvancedExpenseDetails = useCallback(
    async (advancedExpenseToken: string) => {
      setState((prev) => ({ ...prev, isLoadingDetails: true }));
      const {
        data: detailedAdvancedExpense,
        error,
      } = await advancedExpenseService.fetchAdvancedExpenseDetails(
        advancedExpenseToken,
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({ ...prev, isLoadingDetails: false }));

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingDetails: false,
        selectedAdvancedExpenseDetails: detailedAdvancedExpense as any,
      }));
    },
    [],
  );

  const handleCloseItemStatus = () => {
    setState((prev) => ({
      ...prev,
      selectedAdvancedExpenseToken: null,
      statusDialogOpen: false,
    }));
  };

  const filterAdvancedExpenses = () => {
    setState((prev) => ({
      ...prev,
      visibleAdvancedExpenses: prev.advancedExpenses.filter((item) =>
        item.name.toLowerCase().includes(prev.searchInput),
      ),
    }));
  };

  const handleCreateAdvancedExpense = async (formData: Record<string, any>) => {
    const newAdvancedExpense = parseAdvanceExpenseCreation(formData);
    const {
      data: createdAdvancedExpense,
      error,
    } = await advancedExpenseService.createAdvanceExpense(newAdvancedExpense);

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      advancedExpenses: [createdAdvancedExpense!, ...prev.advancedExpenses],
    }));
  };

  const defineSelectedAdvancedExpense = (advancedExpenseToken: string) => {
    setState((prev) => ({
      ...prev,
      selectedAdvancedExpenseToken: advancedExpenseToken,
    }));
  };

  const addAdvancedExpenseToReport = async (reportToken: string) => {
    const { error } = await advancedExpenseService.addAdvancedExpenseToReport(
      state.selectedAdvancedExpenseToken!,
      reportToken,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      return;
    }

    navigate(`/reports/${reportToken}`);
  };

  const handleCreateReportWithAdvancedExpense = async (formData: {
    description: string;
    reportType: string;
  }) => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const {
      data: expenseReportToken,
      error: expenseReportError,
    } = await advancedExpenseService.createReportWithAdvancedExpense(
      state.selectedAdvancedExpenseToken!,
      formData,
    );

    if (expenseReportError) {
      showSnackMessage(expenseReportError.description, ALERT_TYPES.ERROR);

      setState((prev) => ({ ...prev, isLoading: false }));

      return;
    }

    setState((prev) => ({ ...prev, isLoading: false }));

    navigate(`/reports/${expenseReportToken}`);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    filterAdvancedExpenses();
  }, [state.searchInput]);

  useEffect(() => {
    filterAdvancedExpenses();
  }, [state.advancedExpenses]);

  return (
    <AdvancedExpensesContext.Provider
      value={{
        ...state,
        addAdvancedExpenseToReport,
        checkUserHasApprovalRule,
        defineSelectedAdvancedExpense,
        fetchAdvancedExpenseDetails,
        fetchAdvancedExpenses,
        handleCloseDeletionDialog,
        handleCloseForm,
        handleCloseItemStatus,
        handleCreateAdvancedExpense,
        handleCreateReportWithAdvancedExpense,
        handleOpenDeletionDialog,
        handleOpenForm,
        handleOpenItemStatus,
        handleSearch,
      }}
    >
      {children}
    </AdvancedExpensesContext.Provider>
  );
};
