import React, { ReactNode, useState } from "react";

import { CardEditRequest } from "~/dtos/card.dto";

import { VcnDisplay } from "@models/vcn.model";

import { IEditCreditCardFormData } from "~/components/shared/credit-card-editing-drawer";

import { ALERT_TYPES } from "../../../../../../../constants";
import { CardModel, LegacyCardModel } from "../../../../../../../models/card.model";
import { CustomError } from "../../../../../../../types";
import * as paymentMethodService from "./PaymentMethods.service";

interface State {
  cards: LegacyCardModel[];
  vcns: VcnDisplay[];
  selectedToDelete: LegacyCardModel | null;
  selectedToEdit: CardModel | null;
  isFormDrawerOpen: boolean;
  isLoading: boolean;
  snack: {
    open: boolean;
    type: string;
    message: string;
  };
  isEditDrawerOpen: boolean;
  isEditingCard: boolean;
}

interface Actions {
  fetchCards: () => void;
  fetchVcn: () => void;
  fetchCardsByUser: (userToken: string) => void;
  handleDeleteCard: (selectedCard: LegacyCardModel) => void;
  handleCloseDeletionDialog: () => void;
  proccessCardDeletion: () => void;
  handleOpenForm: () => void;
  handleCloseForm: () => void;
  onAfterCardSave: (createdCard: LegacyCardModel) => void;
  onAfterUserCardSave: (createdCard: LegacyCardModel) => void;
  handleError: (error: CustomError) => void;
  handleCloseSnack: () => void;
  toggleEditCardDrawerVisibility: () => void;
  handleEditCard(selectedCard: CardModel): void;
  processCardEdit: (
    cardToken: string,
    formData: IEditCreditCardFormData
  ) => void;
}

type ContextProps = State & Actions;

const initialState: State = {
  cards: [],
  vcns: [],
  selectedToDelete: null,
  selectedToEdit: null,
  isFormDrawerOpen: false,
  isLoading: false,
  isEditDrawerOpen: false,
  isEditingCard: false,
  snack: {
    open: false,
    type: "",
    message: ""
  }
};

const PaymentMethodsContext = React.createContext<ContextProps>({
  ...initialState,
  fetchCards: () => null,
  fetchVcn: () => null,
  fetchCardsByUser: () => null,
  handleDeleteCard: () => null,
  handleCloseDeletionDialog: () => null,
  proccessCardDeletion: () => null,
  handleOpenForm: () => null,
  handleCloseForm: () => null,
  onAfterCardSave: () => null,
  onAfterUserCardSave: () => null,
  handleError: () => null,
  handleCloseSnack: () => null,
  toggleEditCardDrawerVisibility: () => null,
  handleEditCard: () => null,
  processCardEdit: () => null
});

const { Provider, Consumer } = PaymentMethodsContext;

const PaymentMethodsProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<State>(initialState);
  const setSafeState = (newState: Partial<State>) => {
    setState(prevState => Object.assign({}, prevState, newState));
  };
  const setErrorState = (error: CustomError) => {
    setSafeState({
      isLoading: false,
      snack: {
        open: true,
        type: ALERT_TYPES.ERROR,
        message: error.description
      }
    });
  };

  const fetchCards = async () => {
    setSafeState({ isLoading: true });

    const {
      data: cards,
      error: cardsError
    } = await paymentMethodService.listCardsByClient();

    if (cardsError) {
      return setErrorState(cardsError);
    }

    setSafeState({
      isLoading: false,
      cards
    });
  };

  const fetchVcn = async () => {
    setSafeState({ isLoading: true });

    const {
      data: vcns,
      error: vcnError
    } = await paymentMethodService.listVcnsByClient();

    if (vcnError) {
      return setErrorState(vcnError);
    }

    setSafeState({
      isLoading: false,
      vcns
    });
  };

  const fetchCardsByUser = async (userToken: string) => {
    setSafeState({ isLoading: true });

    const {
      data: cards,
      error: cardsError
    } = await paymentMethodService.listCardsByUser(userToken);

    if (cardsError) {
      return setErrorState(cardsError);
    }

    setSafeState({
      isLoading: false,
      cards
    });
  };

  const handleDeleteCard = (selectedCard: LegacyCardModel) =>
    setSafeState({ selectedToDelete: selectedCard });

  const toggleEditCardDrawerVisibility = () => {
    setSafeState({ isEditDrawerOpen: !state.isEditDrawerOpen });
  };

  const handleEditCard = (selectedCard: CardModel) => {
    setSafeState({
      isEditDrawerOpen: !state.isEditDrawerOpen,
      selectedToEdit: selectedCard
    });
  };

  const processCardEdit = async (
    cardToken: string,
    formData: IEditCreditCardFormData
  ) => {
    setSafeState({ isEditingCard: true });

    const editCreditCardInput: CardEditRequest = {
      description: formData.description,
      billing_profile_token: formData?.billingProfile?.value
        ? formData.billingProfile.value
        : null,
      allow_approvers: formData?.allowApprovers,
      service_types: {
        flight: formData.enableToFlight,
        car: formData.enableToCar,
        hotel: formData.enableToHotel,
        bus: formData.enableToBus,
        ride: formData.enableToRide,
        other: formData.enableToOther
      }
    };

    const { error } = await paymentMethodService.editCard(
      cardToken,
      editCreditCardInput
    );

    if (error) {
      setSafeState({
        isEditingCard: false,
        snack: {
          open: true,
          type: ALERT_TYPES.ERROR,
          message: error.description
        }
      });
    } else {
      const selectedToken = state?.selectedToEdit?.token;

      const billingProfileIsApplicableToAll =
        formData.billingProfile?.value === "null";

      const billingProfileName = formData.billingProfile?.label.split(" -")[0];

      const newCreditCards = state.cards.map(card => {
        if (card.token !== selectedToken) return card;
        return {
          ...card,
          description: formData.description,
          billingProfileToken: editCreditCardInput.billing_profile_token,
          billingProfileName: billingProfileIsApplicableToAll
            ? "VÁLIDO PARA TODOS CNPJs"
            : billingProfileName || card.billingProfileName,
          allowApprovers: editCreditCardInput.allow_approvers,
          serviceTypes: editCreditCardInput.service_types
        };
      }) as LegacyCardModel[];

      setSafeState({
        cards: newCreditCards,
        isEditingCard: false,
        selectedToEdit: null,
        isEditDrawerOpen: false,
        snack: {
          open: true,
          type: ALERT_TYPES.SUCCESS,
          message: "Cartão editado com sucesso"
        }
      });
    }
  };

  const handleCloseDeletionDialog = () =>
    setSafeState({ selectedToDelete: null });

  const proccessCardDeletion = async () => {
    setSafeState({ isLoading: true });

    const { error } = await paymentMethodService.deleteCard(
      state.selectedToDelete!.token
    );

    if (error) {
      setErrorState(error);
    } else {
      const filteredCards = state.cards.filter(
        card => card.token !== state.selectedToDelete!.token
      );

      setSafeState({
        cards: filteredCards,
        isLoading: false,
        selectedToDelete: null,
        snack: {
          open: true,
          type: ALERT_TYPES.SUCCESS,
          message: "Cartão excluído com sucesso"
        }
      });
    }
  };

  const handleOpenForm = () => setSafeState({ isFormDrawerOpen: true });

  const handleCloseForm = () => setSafeState({ isFormDrawerOpen: false });

  const onAfterCardSave = (createdCard: LegacyCardModel) => {
    if (!createdCard.personal && !createdCard.billingProfileName) {
      createdCard.billingProfileName = "VÁLIDO PARA TODOS CNPJs";
    }

    const cards = !createdCard.personal
      ? Array.prototype.concat(state.cards, createdCard)
      : state.cards;

    setSafeState({
      isFormDrawerOpen: false,
      cards,
      snack: {
        open: true,
        type: ALERT_TYPES.SUCCESS,
        message: "Cartão salvo com sucesso"
      }
    });
  };

  const onAfterUserCardSave = (createdCard: LegacyCardModel) => {
    const cards = createdCard.personal
      ? Array.prototype.concat(state.cards, createdCard)
      : state.cards;

    setSafeState({
      isFormDrawerOpen: false,
      cards,
      snack: {
        open: true,
        type: ALERT_TYPES.SUCCESS,
        message: "Cartão salvo com sucesso"
      }
    });
  };

  const handleCloseSnack = () =>
    setSafeState({
      snack: {
        open: false,
        type: "",
        message: ""
      }
    });

  return (
    <Provider
      value={{
        ...state,
        fetchCards,
        fetchVcn,
        fetchCardsByUser,
        handleDeleteCard,
        handleCloseDeletionDialog,
        proccessCardDeletion,
        handleOpenForm,
        handleCloseForm,
        onAfterCardSave,
        onAfterUserCardSave,
        handleCloseSnack,
        handleError: setErrorState,
        toggleEditCardDrawerVisibility,
        handleEditCard,
        processCardEdit
      }}
    >
      {children}
    </Provider>
  );
};

export {
  PaymentMethodsContext,
  PaymentMethodsProvider,
  Consumer as PaymentMethodsConsumer
};
