import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "40px 24px",
    }),
  },
  form: {
    body: {
      message: {
        label: css({
          marginTop: "24px",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        gap: "12px",
        marginTop: "24px",
        padding: "0 24px",
        width: "100%",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "space-between",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "768px",
  }),
};
