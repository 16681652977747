import React from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";

import { AlertDialog } from "../alert-dialog/alert-dialog";

const refreshCacheAndReload = () => {
  void caches.keys().then((names) => {
    for (const name of names) {
      void caches.delete(name);
    }
  });

  window.location.reload();
};

export const RefreshDialog: React.FC = () => {
  const { closeRefreshDialog, isRefreshDialogVisible } = useApplication();

  return (
    <AlertDialog
      confirmLabel="Atualizar"
      icon="arrow-path"
      message="Uma nova versão deste aplicativo está disponível, atualize agora para continuar utilizando."
      onClose={closeRefreshDialog}
      onConfirm={() => {
        refreshCacheAndReload();
      }}
      open={isRefreshDialogVisible}
      title="Nova versão disponível."
    />
  );
};
