import React, { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { navigate } from "@reach/router";
import { HotelSearchInfo } from "~/apps/corporate/models/hotel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { useHotels } from "../hotels.context";
import { styles } from "./styles";

export const HotelsHeader: React.FC = () => {
  const { isFakeLoading, isLoading, searchInfo } = useHotels();
  const { width: windowWidth } = useWindowSize();

  const isMobile = useMemo(() => windowWidth <= 640, [windowWidth]);

  const navigateBack = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <div css={styles.root}>
      <Button css={styles.left.button} shape="icon" onClick={navigateBack}>
        <Icon size={16} use="arrow-left" />
      </Button>
      <div css={styles.right.root}>
        {isFakeLoading || isLoading ? (
          <HotelsHeaderSearchSkeleton isMobile={isMobile} />
        ) : searchInfo ? (
          <HotelsHeaderSearchInfo isMobile={isMobile} searchInfo={searchInfo} />
        ) : null}
      </div>
    </div>
  );
};

type HotelsHeaderSearchInfoProps = {
  isMobile?: boolean;
  searchInfo: HotelSearchInfo;
};

const HotelsHeaderSearchInfo: React.FC<HotelsHeaderSearchInfoProps> = ({
  isMobile,
  searchInfo: { hotelCity, hotelEndDate, hotelInitialDate, hotelState },
}) => {
  const { handleOpenEditDrawer } = useHotels();

  const travelEnd = moment(hotelEndDate).format("DD MMM");
  const travelStart = moment(hotelInitialDate).format("DD MMM");

  return (
    <>
      {!isMobile ? (
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Destino</span>
          <span css={styles.right.search.value}>
            {hotelCity}-{hotelState}
          </span>
        </div>
      ) : null}
      <div css={styles.right.bottom}>
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Check-in</span>
          <span css={styles.right.search.value}>{travelStart}</span>
        </div>
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Check-out</span>
          <span css={styles.right.search.value}>{travelEnd}</span>
        </div>
        <Button onClick={handleOpenEditDrawer} shape="icon" variant="pink">
          <Icon size={16} use="pencil" />
        </Button>
      </div>
    </>
  );
};

type HotelsHeaderSearchSkeletonProps = {
  isMobile?: boolean;
};

const HotelsHeaderSearchSkeleton: React.FC<HotelsHeaderSearchSkeletonProps> = ({
  isMobile,
}) => {
  return (
    <>
      {!isMobile ? (
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Destino</span>
          <Skeleton height="24px" width="128px" />
        </div>
      ) : null}
      <div css={styles.right.bottom}>
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Check-in</span>
          <Skeleton height="24px" width="64px" />
        </div>
        <div css={styles.right.search.root}>
          <span css={styles.right.search.field}>Check-out</span>
          <Skeleton height="24px" width="64px" />
        </div>
        <Button disabled shape="icon" variant="pink">
          <Icon size={16} use="pencil" />
        </Button>
      </div>
    </>
  );
};
