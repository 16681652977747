import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { BaggageFilter as BaggageFilterType } from "../../../flights.types";
import { styles } from "./styles";

type Props = {
  baggage: BaggageFilterType[];
  handleBaggageToggle: (toggledBaggage: any) => void;
};

export const BaggageFilter: React.FC<Props> = ({
  baggage,
  handleBaggageToggle,
}) => {
  if (baggage.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Bagagem</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {baggage.map((baggage) => (
          <CheckboxGroup.Item
            checked={baggage.checked}
            id={baggage.value}
            key={baggage.value}
            name={baggage.value}
            onChange={() => {
              handleBaggageToggle(baggage);
            }}
            value={baggage.checked}
          >
            <span css={styles.checkbox.group.item.label}>{baggage.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
