import React from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { Icon } from "~/apps/shared/components/icon/icon";
import { RichTextEditor } from "~/apps/shared/components/rich-text-editor/rich-text-editor";

import { styles } from "./styles";

const Component: React.FC = () => {
  const { clientConfig } = useClientConfig();

  if (!clientConfig) {
    return null;
  }

  if (!clientConfig.hasCustomMessage()) {
    return null;
  }

  const customMessage = clientConfig.getParsedCustomMessage();

  if (!customMessage) {
    return null;
  }

  return (
    <div css={styles.root}>
      <Icon size={16} use="question-circle-outline" />
      <div css={styles.text.root}>
        <RichTextEditor.Readonly value={customMessage} />
      </div>
    </div>
  );
};

export const NewTripOfferSearcherMessage: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
