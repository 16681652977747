import React, { useState } from "react";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { css } from "emotion";
import { defaultTheme } from "../../../../assets/styles/theme";

const styles = {
  root: css({
    width: "100%",
    alignItems: "center",
    padding: ".5rem 0"
  }),
  iconButton: css({
    padding: "5px",
    borderRadius: 4,
    [`:hover`]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor
    }
  }),
  icon: css({
    fontSize: 20,
    color: defaultTheme.subTextColor
  }),
  menuPaper: css({
    padding: 0,
    boxShadow:
      "rgba(50, 50, 93, 0.06) 0px 4px 8px 0px, rgba(121, 135, 157, 0.1) 0px 0px 4px 0px, rgba(50, 50, 93, 0.06) 0px 2px 6px 0px;",
    borderRadius: 0
  }),
  menuList: css({
    paddingTop: 0,
    paddingBottom: 0
  }),
  menuItem: css({
    color: defaultTheme.textColor,
    fontSize: 14,
    fontFamily: "inherit",
    lineHeight: 1.5,
    letterSpacing: "0.04rem",
    padding: ".75rem 1.5rem"
  })
};

interface MoreActionsPopOverProps {
  item: any;
  rootClasses?: string;
  handleDelete?: (item: any) => void;
  handleEdit?: (item: any) => void;
  handleArchive?: (item: any) => void;
  handleUnarchive?: (item: any) => void;
}

function MoreActionsPopOver(props: MoreActionsPopOverProps) {
  const {
    item,
    handleEdit,
    handleDelete,
    handleArchive,
    handleUnarchive
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const showMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const closeMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleDeletion = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (handleDelete) {
      handleDelete(item);
      setAnchorEl(null);
      e.stopPropagation();
    }
  };

  const handleArchivation = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (handleArchive) {
      handleArchive(item);
      setAnchorEl(null);
      e.stopPropagation();
    }
  };

  const handleUnarchivation = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (handleUnarchive) {
      handleUnarchive(item);
      setAnchorEl(null);
      e.stopPropagation();
    }
  };

  const handleEdition = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (handleEdit) {
      handleEdit(item);
      setAnchorEl(null);
      e.stopPropagation();
    }
  };

  return (
    <div>
      <IconButton
        aria-label="Mais opções"
        className={styles.iconButton}
        aria-owns={anchorEl ? "option-menu" : undefined}
        aria-haspopup="true"
        onClick={showMenu}
      >
        <MoreIcon className={styles.icon} />
      </IconButton>
      <Menu
        id="option-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        MenuListProps={{
          className: styles.menuList
        }}
        classes={{
          paper: styles.menuPaper
        }}
      >
        {handleEdit ? (
          <MenuItem
            classes={{ root: styles.menuItem }}
            style={{
              borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
            }}
            onClick={handleEdition}
          >
            Editar
          </MenuItem>
        ) : null}
        {handleDelete ? (
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleDeletion}
          >
            Excluir
          </MenuItem>
        ) : null}
        {handleArchive ? (
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleArchivation}
          >
            Arquivar
          </MenuItem>
        ) : null}
        {handleUnarchive ? (
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleUnarchivation}
          >
            Desarquivar
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
}

export { MoreActionsPopOver };
