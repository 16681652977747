import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "8px 0",
    width: "100%",
  }),
  form: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    service: {
      input: {
        autocomplete: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
        }),
        display: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          color: theme.colors.gray[700],
          display: "flex",
          fontWeight: 600,
          height: "48px",
          opacity: 0.75,
          paddingLeft: "16px",
          width: "100%",
        }),
        label: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
      },
      label: {
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        text: css({
          color: theme.colors.gray[700],
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
  },
};
