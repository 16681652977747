import { CarSupplier } from "~/apps/corporate/models/car.model";
import { LocalizaAdditionalsHelper } from "~/apps/corporate/pages/travels/search/cars/car-result/localiza-additionals/localiza-additionals.helper";
import { MovidaAdditionalsHelper } from "~/apps/corporate/pages/travels/search/cars/car-result/movida-additionals/movida-additionals.helper";

type Additional = {
  code: string;
  name: string;
  selected: boolean;
};

export const formatSelectedAdditionals = (
  selectedAdditionals: {
    accessoryAdditionals?: Additional[];
    protectionAdditionals?: Additional[];
    serviceAdditionals?: Additional[];
  },
  supplier: CarSupplier,
) => {
  const helper = helperFactory(selectedAdditionals, supplier);

  const {
    additionals: formattedSelectedLocalizaAccessoryAdditionals = [],
  } = helper.formatAccessoryAdditionals();
  const {
    additionals: formattedSelectedLocalizaProtectionAdditionals = [],
  } = helper.formatProtectionAdditionals();
  const {
    additionals: formattedSelectedLocalizaServiceAdditionals = [],
  } = helper.formatServiceAdditionals();

  return {
    accessory: formattedSelectedLocalizaAccessoryAdditionals,
    protection: formattedSelectedLocalizaProtectionAdditionals,
    service: formattedSelectedLocalizaServiceAdditionals,
  };
};

const helperFactory = (
  additionals: {
    accessoryAdditionals?: Additional[];
    protectionAdditionals?: Additional[];
    serviceAdditionals?: Additional[];
  },
  supplier: CarSupplier,
) => {
  switch (supplier) {
    case CarSupplier.LOCALIZA:
      return new LocalizaAdditionalsHelper({
        car: {
          contractDetails: additionals,
        },
      });
    case CarSupplier.MOVIDA:
      return new MovidaAdditionalsHelper({
        car: {
          contractDetails: additionals,
        },
      });
  }
};
