import React, { useState } from "react";
import { Input } from "./Input";

interface Props {
  inputProps: any;
  handleChange: (search: string) => void;
}

const SearchInput = ({ inputProps, handleChange }: Props) => {
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    handleChange(value);
  };

  return <Input {...inputProps} value={search} onChange={handleChangeSearch} />;
};

export { SearchInput };
