import React from "react";
import { css } from "emotion";
import ColoredTag from "../../../shared/ColoredTag";
import { colors } from "../../../../assets/styles/theme/constants";
import { Desktop, TabletAndMobile } from "../../../shared/layout/Responsive";
import { Expense } from "~/models/expense.model";

interface Props {
  expense: Partial<Expense>;
}

const ExpenseTypeTag = ({ expense }: Props) => {
  return (
    <>
      <Desktop>
        <ColoredTag
          tagStyle={css({
            color: colors.white,
            backgroundColor: expense.expenseCategoryColor || colors.tangerine
          })}
          isUppercase={false}
        >
          {expense.expenseCategoryName}
        </ColoredTag>
      </Desktop>
      <TabletAndMobile>
        <span
          className={css({
            color: colors.white,
            backgroundColor: expense.expenseCategoryColor
          })}
        >
          {expense.expenseCategoryName}
        </span>
      </TabletAndMobile>
    </>
  );
};

export { ExpenseTypeTag };
