import { Coords } from "google-map-react";
import React from "react";
import { Motion, spring } from "react-motion";

import in_policy_marker from "~/apps/shared/assets/images/markers/in-policy-marker.svg";
import out_of_policy_marker from "~/apps/shared/assets/images/markers/out-of-policy-marker.svg";
import search_point from "~/apps/shared/assets/images/markers/search-place-marker.svg";

type Props = Coords &
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, "src"> & {
    type: "in-policy" | "out-of-policy" | "search-point";
  };

const getMarkerIcon = (
  type: "in-policy" | "out-of-policy" | "search-point",
) => {
  switch (type) {
    case "in-policy":
      return in_policy_marker;
    case "out-of-policy":
      return out_of_policy_marker;
    case "search-point":
      return search_point;
    default:
      return search_point;
  }
};

const Marker: React.FC<Props> = ({ alt, className, onClick, style, type }) => {
  return (
    <img
      alt={alt}
      className={className}
      onClick={onClick}
      src={getMarkerIcon(type)}
      style={style}
      width={24}
    />
  );
};

type MarkerSvgProps = React.SVGAttributes<SVGElement> & {
  circle: string;
  exterior: string;
};

export const MarkerSvg: React.FC<MarkerSvgProps> = ({
  circle,
  exterior,
  ...props
}) => {
  return (
    <svg height="38" viewBox="0 0 32 38" width="24" {...props}>
      <path
        d="M10.465 29.1C3.194 20.999-.45 14.926-.45 10.8-.45 4.587 4.587-.45 10.8-.45S22.05 4.587 22.05 10.8c0 4.125-3.644 10.198-10.915 18.3l-.335.374-.335-.373z"
        fill={exterior}
        fillRule="evenodd"
      />
      <circle cx="10.8" cy="10.8" fill={circle} r="5.4" />
    </svg>
  );
};

const config = { damping: 14, stiffness: 140 };

const toCSS = (scale: number) => ({
  transform: `scale3d(${scale}, ${scale}, ${scale})`,
});

export const ZoomInMarker: React.FC<Props> = ({ ...props }) => {
  return (
    <Motion defaultStyle={{ scale: 0 }} style={{ scale: spring(1, config) }}>
      {(value) => <Marker style={toCSS(value.scale)} {...props} />}
    </Motion>
  );
};
