import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  header: {
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.background.gray,
      borderBottom: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      justifyContent: "space-between",
      padding: "24px",
      width: "100%",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "1.125rem",
      fontWeight: 700,
    }),
  },
  root: css({}),
};
