import React from "react";
import { MultiValue, components } from "react-select";
import AsyncAutocomplete from "react-select/async";

import { darken } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Box, Flex, Text } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { defaultTheme } from "@assets/styles/theme";

import { UserSearch } from "@models/user.model";

import { CustomError } from "~/types";

import { ApproverStage } from "../ExpensesProcess.types";
import { SELECT_STYLES } from "../styles";

const styles = {
  label: (stage: number | "payment") =>
    css({
      display: "block",
      fontSize: stage === 0 || stage === "payment" ? "1.25rem" : "1rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
      marginBottom: "0.5rem",
    }),
  closeButton: css({
    width: "1.75rem",
    height: "1.75rem",
    color: theme.colors.white,
    backgroundColor: theme.colors.gray[500],
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0.25rem",
    [`&:hover`]: {
      backgroundColor: darken(theme.colors.gray[500], 0.2),
    },
  }),
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.hasValue ? null : <Icon use="search" style={{ marginLeft: 4 }} />}
      {children}
    </components.Control>
  );
};

type ApproverSelectionProps = {
  stage: ApproverStage;
  label: string;
  approvers: UserSearch[];
  handleSelect: (stage: ApproverStage, approver: UserSearch) => void;
  handleRemoveApprover: (stage: ApproverStage, approverToken: string) => void;
  fetchApprover: (
    search: string,
  ) => Promise<{ data?: UserSearch[]; error?: CustomError }>;
  showRemoveStageButton?: boolean;
  handleRemoveStage?: () => void;
};

export const ApproverSelection: React.FC<ApproverSelectionProps> = ({
  stage,
  label,
  approvers,
  handleSelect,
  handleRemoveApprover,
  fetchApprover,
  showRemoveStageButton,
  handleRemoveStage,
}) => {
  const [multipleApprovers, setMultipleApprovers] = React.useState<
    UserSearch[]
  >(approvers);

  const loadApprovers = async (search: string) => {
    return (await fetchApprover(search)).data;
  };

  const handleSelectApprover = (newApprovers: MultiValue<UserSearch>) => {
    const newApproversMap = newApprovers.reduce((acc, newApprover) => {
      acc[newApprover.userToken] = newApprover;
      return acc;
    }, {} as Record<string, UserSearch>);

    const currentApproversMap = approvers.reduce((acc, approver) => {
      acc[approver.userToken] = approver;
      return acc;
    }, {} as Record<string, UserSearch>);

    const userToRemove = approvers.filter(
      (approver) => !newApproversMap[approver.userToken],
    )[0];
    const userToAdd = newApprovers.filter(
      (newApprover) => !currentApproversMap[newApprover.userToken],
    )[0];

    setMultipleApprovers(newApprovers as UserSearch[]);
    if (userToAdd) handleSelect(stage, userToAdd);
    if (userToRemove) handleRemoveApprover(stage, userToRemove.userToken);
  };

  return (
    <Box marginTop={stage === "payment" ? "1.5rem" : ""}>
      <Text css={styles.label(stage)}>{label}</Text>

      <Flex alignItems="center" style={{ gap: "1rem" }}>
        <AsyncAutocomplete
          aria-label="Digite o nome do aprovador"
          placeholder="Digite o nome do aprovador"
          isMulti
          noOptionsMessage={() => "Nenhum aprovador encontrado"}
          onChange={handleSelectApprover}
          value={multipleApprovers}
          loadOptions={loadApprovers}
          getOptionValue={(option) => option.userToken}
          getOptionLabel={(option) => option.fullName}
          loadingMessage={() => "Carregando..."}
          components={{ Control }}
          styles={SELECT_STYLES}
        />
        {showRemoveStageButton ? (
          <IconButton
            onClick={handleRemoveStage}
            aria-label="close"
            css={styles.closeButton}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : null}
      </Flex>
    </Box>
  );
};
