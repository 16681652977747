import { buildHotelData } from "../helpers/hotel.helper";
import {
  getAuthorizationHeader,
  getClientToken,
  getLoggedUserToken
} from "../helpers/user.helper";
import { HotelFactory } from "../parsers/travel.parser";
import { api } from "./interceptors";

export function getReceptionHours(hospitalityHotelToken) {
  return api
    .request({
      url: `/booking/hotel/hospitality/${hospitalityHotelToken}/reception-hours`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function addHotelOffer(data, travelToken) {
  // todo calcular antes e ja mandar calculado
  return api.request({
    url: `/booking/travels/${travelToken}/offers/hotel`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data
  });
}

function loadHotels(hotelToken) {
  return api
    .request({
      url: `/booking/hotel/${hotelToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      const { data } = response;
      const camelData = data;
      return camelData;
    });
}

function loadRooms(hotelToken, hospitalityToken) {
  return api
    .request({
      url: `/booking/hotel/${hotelToken}/hospitality/${hospitalityToken}/rooms`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      const { data } = response;
      const camelData = data;
      return camelData;
    });
}

function loadHotelDetails(hotelToken, hospitalityToken) {
  return api
    .request({
      url: `/booking/hotel/${hotelToken}/hospitality/${hospitalityToken}/details`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

function createNewHotel(hotelData) {
  return api
    .request({
      url: `/booking/hotel`,
      method: "POST",
      data: hotelData,
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => HotelFactory(data));
}

async function createNewHotelsInBatch(hotelData) {
  const formattedData = buildHotelData(hotelData);
  const hotels = await Promise.all(
    formattedData.map(data => createNewHotel(data))
  );
  const tokens = hotels.map(hotel => ({
    hotel_token: hotel.token
  }));

  return tokens;
}

export function getHotelAutossugest(search) {
  return api
    .request({
      url: `/booking/autosuggest/hotel/${search}`,
      timeout: 25000,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => {
      return data.location_list.map(location => ({
        ...location,
        label: location.name
      }));
    });
}

export function setHotelFavorite(requestData) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/hotels/favourites`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then(({ data }) => data);
}

export function removeHotelFavorite(favouriteToken) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/hotels/favourites/${favouriteToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

function handleError() {
  throw { title: "title", description: "Erro inesperado" };
}

export function getNearbyPlaces(dto) {
  return api
    .request({
      url: "/booking/hotel/nearby-places",
      method: "POST",
      data: dto,
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export async function getUserRecentHotels() {
  return api
    .request({
      url: `/booking/users/${getLoggedUserToken()}/hotels/recent`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export const hotelService = {
  createNewHotel,
  createNewHotelsInBatch,
  loadHotels,
  loadHotelDetails,
  addHotelOffer,
  setHotelFavorite,
  removeHotelFavorite,
  getNearbyPlaces,
  getUserRecentHotels,
  loadRooms,
  getReceptionHours
};

export default hotelService;
