import React, { useState } from "react";
import { Box, Text } from "@toolkit";
import FilterIcon from "@material-ui/icons/FilterList";

import { StOutlinedButton } from "@shared/buttons";
import { FiltersDrawer } from "./FilterDrawer";

export interface IFilters {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  costCenterToken: string;
  companyAreaToken: string;
  projectToken: string;
  billingProfileToken: string;
  tagToken?: string;
  travelerToken?: string;
}

interface IOption {
  value: string;
  label: string;
  [key: string]: any;
}

interface IProps {
  costCenters: IOption[];
  companyAreas: IOption[];
  projects: IOption[];
  billingProfiles: IOption[];
  tags?: IOption[];
  travelers?: IOption[];
  filtersData: IFilters;
  onApply(filters: IFilters): void;
}

const MobileFilters: React.FC<IProps> = ({
  filtersData,
  onApply,
  ...options
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleSubmit(filters: IFilters) {
    onApply(filters);
    setIsOpen(false);
  }

  function toggleVisibility() {
    setIsOpen(prevState => !prevState);
  }

  return (
    <Box display={["block", "block", "none"]}>
      <StOutlinedButton
        color="primary"
        onClick={toggleVisibility}
        style={{
          padding: 8,
          minWidth: "max-content"
        }}
      >
        <FilterIcon />
      </StOutlinedButton>
      <FiltersDrawer
        {...options}
        isOpen={isOpen}
        filtersData={filtersData}
        onClose={toggleVisibility}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export { MobileFilters };
