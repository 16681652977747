export type PeriodParams = {
  endMonth: number;
  endYear: number;
  startMonth: number;
  startYear: number;
};

export type ReportItem = {
  color: string;
  description: string;
  name: string;
  type: ReportType;
};

export type ReportSection = {
  color: string;
  items: ReportItem[];
  title: string;
};

export enum ReportType {
  APPROVAL = "APPROVAL",
  BUS = "BUS",
  CANCELATION = "CANCELATION",
  CAR_POLICY = "CAR_POLICY",
  CAR = "CAR",
  CONCILIATION = "CONCILIATION",
  FLIGHT_CMP = "FLIGHT_CMP",
  FLIGHT_CHANGE = "FLIGHT_CHANGE",
  FLIGHT_POLICY = "FLIGHT_POLICY",
  FLIGHT = "FLIGHT",
  HOTEL_POLICY = "HOTEL_POLICY",
  HOTEL = "HOTEL",
  EXPENSE = "EXPENSE",
  PENDING_EXPENSE = "PENDING_EXPENSE",
  TRAVELER = "TRAVELER",
  TRAVEL_FEE = "TRAVEL_FEE",
}
