import {
  User,
  UserModel,
  UserModelFactory,
} from "~/apps/corporate/models/user.model";
import jwtDecode from "jwt-decode";

import { buildDate } from "../../../../package.json";
import { GetUserFromAccessTokenDto } from "../dtos/user.dto";

export const clearUserFromLocalStorage = () => {
  localStorage.removeItem("access_token");
};

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getAuthorizationHeader = () => {
  return {
    Authorization: getAccessToken(),
    ["X-Client-Version"]: buildDate,
  };
};

export const getClientPermissions = () => {
  const user = getUserFromLocalStorage();

  return user ? user.getPermissions() : undefined;
};

export const getClientToken = () => {
  const user = getUserFromLocalStorage();

  return user ? user.getClientToken() : undefined;
};

export const getUserFromAccessToken = (accessToken: string) => {
  const jwtData = jwtDecode<GetUserFromAccessTokenDto>(accessToken);

  const user: User = {
    agencyToken: jwtData.agency_token,
    capabilities: jwtData.capabilities,
    clientName: jwtData.client_name,
    clientToken: jwtData.client_token,
    email: jwtData.email,
    firstName: jwtData.first_name,
    fullName: `${jwtData.first_name} ${jwtData.last_name}`,
    guest: jwtData.guest,
    hidden: jwtData.hidden,
    lastName: jwtData.last_name,
    permissions: jwtData.permissions,
    phone: jwtData.phone,
    plan: jwtData.plan,
    role: jwtData.role,
    sendSms: jwtData.send_sms,
    userToken: jwtData.user_token,
  };

  return UserModelFactory.create(user);
};

export const getUserFromLocalStorage = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  return getUserFromAccessToken(accessToken);
};

export const getLoggedUserToken = () => {
  const user = getUserFromLocalStorage();

  return user ? user.getUserToken() : undefined;
};
