import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: theme.colors.background.gray,
    display: "grid",
    padding: "24px",
    placeItems: "center",
    minHeight: "100dvh",
  }),
};
