import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  container: ({ disabled, viewed }: { disabled?: boolean; viewed?: boolean }) =>
    css({
      backgroundColor: theme.colors.background.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: viewed ? "0 8px 8px 8px" : "8px",
      cursor: !disabled ? "pointer" : "default",
      display: "flex",
      marginBottom: "16px",
      padding: "16px",
      opacity: !disabled ? 1 : 0.75,
      overflow: "hidden",
      "@media (max-width: 640px)": {
        flexDirection: "column",
        padding: "unset",
      },
    }),
  left: {
    diff: css({
      alignItems: "center",
      color: theme.colors.gray[300],
      display: "flex",
      fontSize: "0.75rem",
      gap: "8px",
      padding: "0 24px 8px 16px",
      "@media (max-width: 640px)": {
        padding: "0 24px",
      },
    }),
    divisor: css({
      borderBottom: `1px dashed ${theme.colors.gray[100]}`,
      height: "1px",
      width: "100%",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      justifyContent: "center",
      paddingRight: "16px",
      position: "relative",
      width: "100%",
      "@media (max-width: 640px)": {
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        gap: "24px",
        padding: "24px 0",
        paddingRight: "unset",
      },
    }),
  },
  price: {
    cents: css({}),
    root: css({}),
  },
  right: {
    badge: {
      icon: css({
        "& > svg": {
          strokeWidth: 2,
        },
      }),
      root: css({
        backgroundColor: "#ffe8ab",
        borderColor: "#ffe8ab",
        color: "#806300",
        cursor: "pointer",
        fontWeight: 500,
        gap: "8px",
        padding: "4px 16px",
        ":hover": {
          backgroundColor: "#e5d099",
          borderColor: "#e5d099",
          color: "#735900",
        },
      }),
    },
    bottom: {
      button: ({ disabled }: { disabled: boolean }) =>
        css({
          backgroundColor: theme.colors.pink[500],
          border: "1px solid",
          borderColor: theme.colors.pink[500],
          borderRadius: "8px",
          color: theme.colors.white,
          fontWeight: 500,
          justifyContent: "center",
          opacity: !disabled ? 1 : 0.75,
          padding: "14px 44.5px",
          textDecoration: "none",
          transition: "all 0.15s ease-in-out",
          width: "100%",
          ":hover": {
            backgroundColor: theme.colors.pink[700],
            borderColor: theme.colors.pink[700],
          },
          "@media (max-width: 1440px)": {
            padding: "12px 0",
            textAlign: "center",
            width: "100%",
          },
        }),
      price: {
        label: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          "@media (max-width: 640px)": {
            alignItems: "flex-start",
            paddingTop: "4px",
          },
        }),
        value: css({
          color: theme.colors.gray[700],
          fontSize: "1.5rem",
          fontWeight: 700,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyContent: "flex-end",
        height: "100%",
        width: "100%",
      }),
    },
    root: css({
      alignItems: "center",
      borderLeft: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      gap: "24px",
      minWidth: "192px",
      paddingLeft: "16px",
      "@media (max-width: 640px)": {
        alignItems: "flex-start",
        borderLeft: "unset",
        gap: "8px",
        padding: "16px",
      },
    }),
    top: {
      root: css({
        display: "flex",
        flexDirection: "column",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
  }),
  viewed: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderBottom: "1px solid white",
    borderRadius: "8px 8px 0 0",
    color: theme.colors.gray[300],
    fontSize: "0.75rem",
    padding: "8px 12px 4px 12px",
    transform: "translateY(1px)",
    width: "fit-content",
  }),
};
