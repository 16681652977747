import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  icon: css({
    color: theme.colors.gray[300],
  }),
  input: css({
    lineHeight: "24px",
    padding: "0",
    textOverflow: "ellipsis",
    width: "100%",
  }),
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    gap: "12px",
    padding: "12px 16px",
    width: "100%",
  }),
};
