import React, { useState } from "react";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

export const InputPassword: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
> = ({ placeholder, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div css={styles.root}>
      <input
        css={styles.input}
        placeholder={
          placeholder ? placeholder : showPassword ? "Senha" : "••••••••"
        }
        type={!showPassword ? "password" : "text"}
        {...props}
      />
      <button
        css={styles.button}
        onClick={() => {
          toggleShowPassword();
        }}
        type="button"
      >
        <Icon use={!showPassword ? "eye-slash" : "eye"} />
      </button>
    </div>
  );
};
