import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { theme } from "smartrips-skin";

export const styles = {
  left: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "32px",
    width: "100%"
  }),
  right: {
    badge: {
      icon: {
        root: ecss({
          height: "16px",
          width: "16px"
        })
      },
      root: css({
        alignItems: "center",
        borderRadius: "4px",
        color: "white",
        display: "flex",
        gap: "4px",
        padding: "2px 8px",
        position: "absolute",
        right: 16,
        top: 16
      })
    },
    image: css({
      height: "78px",
      objectFit: "cover"
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      padding: "32px",
      position: "relative"
    })
  },
  root: ({ detail }: { detail?: string }) =>
    css({
      border: `1px solid ${theme.colors.gray[0]}`,
      borderRadius: 4,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      color: theme.colors.gray[5],
      display: "flex",
      gap: "16px",
      overflow: "hidden",
      position: "relative",
      textDecoration: "none",
      transition: "border-color 0.15s ease-in-out",
      width: "100%",
      "::after": {
        backgroundColor: detail || theme.colors.primary,
        bottom: 0,
        content: "''",
        height: "100%",
        left: 0,
        position: "absolute",
        width: "4px"
      }
    })
};
