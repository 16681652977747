import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ChatConfig } from "~/apps/corporate/models/agency.model";

import { Icon } from "../../icon/icon";
import { styles } from "./styles";

type Props = {
  chatConfig: ChatConfig;
};

export const LivechatOctadeskChat: React.FC<Props> = ({ chatConfig }) => {
  const { clientConfig } = useClientConfig();
  const { user } = useUser();

  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const clearPreviousLoad = useCallback(() => {
    const scriptEl = document.getElementById(`${chatConfig.provider}-script`);

    scriptEl?.remove();

    const chatDivElement = document.getElementById("octadesk-octachat-appchat");

    chatDivElement?.remove();
  }, []);

  const handleCloseChat = useCallback(() => {
    if (!window.octadesk.chat) {
      return;
    }

    window.octadesk.chat.hide();
    window.octadesk.chat.hideApp();
  }, []);

  const handleOpenChat = useCallback(() => {
    if (!window.octadesk.chat) {
      return;
    }

    window.octadesk.chat.show();
    window.octadesk.chat.showApp();
    window.octadesk.chat.hideButton();
  }, []);

  const isLoggedIn = useMemo(() => !!user, [user]);

  const setUserInfo = useCallback(() => {
    if (!clientConfig || !user || !window.octadesk.chat) {
      return;
    }

    window.octadesk.chat.login({
      name: `${user.getFirstName()} ${user.getLastName()}`,
      email: user.getEmail(),
      phone: user.getPhone(),
    });

    window.octadesk.chat.setCustomFields([
      clientConfig.getSupportChannel(),
      user.getClientName(),
      user.getPlan(),
    ]);
  }, [clientConfig, user]);

  useEffect(() => {
    clearPreviousLoad();

    window.octadesk = window.octadesk || {};
    window.octadesk.chatOptions = {
      hide: true,
      hideOnClose: true,
      openOnMessage: true,
      showButton: false,
      subDomain: chatConfig.identifier,
    };

    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");

    script.id = `${chatConfig.provider}-script`;
    script.async = true;
    script.src = chatConfig.url;

    window.addEventListener("onOctaChatReady", function onScriptLoad() {
      setIsLoaded(true);
    });

    window.addEventListener("message", (t) => {
      if (t.data.octachat && t.data.octachat.classes) {
        const classes = t.data.octachat.classes as string[];
        if (classes.indexOf("octachatAppClose") >= 0) {
          setIsChatOpen(false);
        } else if (classes.indexOf("octachatAppOpen") >= 0) {
          setIsChatOpen(true);
        }
      }
    });

    body.appendChild(script);
  }, [chatConfig.url, chatConfig.identifier]);

  useEffect(() => {
    const isApiLoaded = isLoaded && !!window.octadesk;

    if (isApiLoaded) {
      if (isLoggedIn) {
        setUserInfo();
      } else {
        handleCloseChat();
      }
    }
  }, [isLoaded, isLoggedIn, handleCloseChat, setUserInfo]);

  const canShowButton = useMemo(() => isLoaded && !!isLoggedIn, [
    isLoaded,
    isLoggedIn,
  ]);

  if (!canShowButton) {
    return null;
  }

  return (
    <div css={styles.root}>
      {isChatOpen ? (
        <button css={styles.button} onClick={handleCloseChat}>
          <Icon use="x" />
        </button>
      ) : (
        <button css={styles.button} onClick={handleOpenChat}>
          <Icon use="chat-bubble-bottom-center-text" />
        </button>
      )}
    </div>
  );
};
