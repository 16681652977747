import * as yup from "yup";

import { BudgetActionType, TimeframeType } from "@models/budget.model";

export const registerBudgetsFormSchema = yup.object().shape({
  cost_center_token: yup.string().nullable(true),
  billing_profile_token: yup.string().nullable(true),
  timeframe: yup.string().oneOf(Object.values(TimeframeType)).required(),
  year: yup.number().required("Ano é obrigatório."),
  trimester: yup
    .number()
    .nullable(true)
    .when("timeframe", {
      is: TimeframeType.TRIMESTER,
      then: yup.number().required("Trimestre é obrigatório."),
    }),
  semester: yup
    .number()
    .nullable(true)
    .when("timeframe", {
      is: TimeframeType.SEMESTER,
      then: yup.number().required("Semestre é obrigatório."),
    }),
  month: yup
    .number()
    .nullable(true)
    .when("timeframe", {
      is: TimeframeType.MONTH,
      then: yup
        .number()
        .required("Mês é obrigatório quando o Timeframe é MONTH."),
    }),
  action: yup
    .string()
    .oneOf(Object.values(BudgetActionType))
    .required("Ação é obrigatório."),
  value: yup.number().required("Valor é obrigatório."),
});

export type RegisterBudgetsFormSchema = yup.InferType<
  typeof registerBudgetsFormSchema
>;
