import { ExpenseCategoryCamelCase } from "@dtos/expense-categories.dto";

export function sortByDefaultFirst(arr: ExpenseCategoryCamelCase[]) {
  return arr.sort((a, b) => (a.active || a.default ? -1 : 1));
}

export function checkDefaultAndActivated(
  active: boolean,
  isDefault: boolean,
  activeTab: boolean,
) {
  if (activeTab && isDefault) {
    return true;
  }

  if (isDefault && !activeTab) {
    return false;
  }

  return active === activeTab;
}

export function transformEnumIntoOptions(obj: any) {
  return Object.entries(obj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}
