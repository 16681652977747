import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
    fontWeight: 600,
  }),
  root: css({
    ".MuiDialog-container": {
      backgroundColor: theme.colors.white,
    },
    ".MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      display: "grid",
      overflow: "hidden",
      placeItems: "center",
    },
  }),
};
