import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  TravelPlanServicesCardTab,
  TRAVEL_PLAN_SERVICES_CARD_TABS_TRANSLATION,
} from "../travel-plan-services-card";
import { styles } from "./styles";

type Props = {
  isDisabled?: boolean;
  selectedTab: TravelPlanServicesCardTab;
  servicesCounts: Record<TravelPlanServicesCardTab, number> | null;
  setSelectedTab?: (tab: TravelPlanServicesCardTab) => void;
};

export const TravelPlanServicesCardTabs: React.FC<Props> = ({
  isDisabled,
  selectedTab,
  servicesCounts,
  setSelectedTab,
}) => {
  return (
    <div css={styles.root}>
      {Object.entries(TRAVEL_PLAN_SERVICES_CARD_TABS_TRANSLATION).map(
        ([k, v]) => (
          <button
            css={styles.tab({ active: selectedTab === k })}
            disabled={isDisabled}
            key={k}
            onClick={() => {
              if (!setSelectedTab) {
                return;
              }

              setSelectedTab(k as TravelPlanServicesCardTab);
            }}
          >
            {v}
            {servicesCounts &&
            (k as TravelPlanServicesCardTab) !== "plano-de-viagem"
              ? ` (${servicesCounts[k as TravelPlanServicesCardTab]})`
              : null}
          </button>
        ),
      )}
    </div>
  );
};

export const TravelPlanServicesCardTabsSkeleton: React.FC = () => {
  return (
    <div css={styles.skeleton}>
      <Skeleton height="14px" width="96px" />
      <Skeleton height="14px" width="96px" />
      <Skeleton height="14px" width="96px" />
    </div>
  );
};
