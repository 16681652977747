import React, { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";

import Drawer from "@material-ui/core/Drawer";
import { css } from "emotion";

import { StContainedButton, StTextButton } from "../../shared/buttons";
import DrawerHeader from "../../shared/DrawerHeader";
import { DrawerSpinner } from "../../shared/DrawerSpinner";
import { Row } from "../../shared/layout/Row";
import { useAdvancedExpensesContext } from "../AdvancedExpenses/AdvancedExpenses.context";
import { ExpensesContext } from "../Expenses.context";
import {
  ReportSelectItem,
  NewReportItem,
} from "./select-items/ReportSelectItem";

interface ExpenseTypes {
  EXPENSE: "expense";
  ADVANCED_EXPENSE: "advanced-expense";
}

const EXPENSE_TYPES: ExpenseTypes = {
  EXPENSE: "expense",
  ADVANCED_EXPENSE: "advanced-expense",
};

const styles = {
  drawerPaper: css({
    ["@media (min-width: 768px)"]: {
      width: 600,
    },
    ["@media (max-width: 500px)"]: {
      width: "100%",
    },
  }),
  cardList: css({
    padding: "1rem 4rem",
    height: "calc(100% - 130px)",
    overflowY: "auto",
    ["@media (max-width: 500px)"]: {
      padding: "1.25rem 2rem",
    },
  }),
  actions: css({
    padding: ".75rem 4rem",
    justifyContent: "space-around",
    ["@media (max-width: 500px)"]: {
      padding: "1rem 2rem",
    },
  }),
  button: css({
    ["@media (max-width: 767px)"]: {
      minWidth: 150,
    },
  }),
  cancelButton: css({
    padding: ".5rem 1rem",
    maxWidth: 160,
    ["@media (max-width: 767px)"]: {
      maxWidth: 150,
    },
  }),
};

interface ReportSelectDrawerProps {
  type: string;
}

const ReportSelectDrawer = ({ type }: ReportSelectDrawerProps) => {
  const {
    pendingReportsList,
    reportListCurrentPage,
    reportListTotalPages,
    fetchUserPendingReports,
    loadNextPendingReports,
    handleCloseReportListDrawer,
    selectedExpenseReportTokenToAdd,
    handleCheckExpenseReport,
    addExpensesToReport,
    loadingReports,
    handleOpenReportCreationDialog,
  } = useContext(ExpensesContext);

  const { addAdvancedExpenseToReport } = useAdvancedExpensesContext();

  useEffect(() => {
    fetchUserPendingReports();
  }, []);

  const handleAddExpenses = () => {
    addExpensesToReport(selectedExpenseReportTokenToAdd);
  };

  const handleAddAdvancedExpense = () => {
    addAdvancedExpenseToReport(selectedExpenseReportTokenToAdd);
  };

  const isAnyReportSelected = !!selectedExpenseReportTokenToAdd;
  const hasNextPage =
    !loadingReports && reportListCurrentPage < reportListTotalPages;

  return (
    <div style={{ height: "100%" }}>
      <DrawerSpinner visible={loadingReports} />
      <DrawerHeader
        title="Escolha um relatório ou crie um novo"
        handleClose={handleCloseReportListDrawer}
      />
      <div className={styles.cardList}>
        <NewReportItem onClick={handleOpenReportCreationDialog} />
        <InfiniteScroll
          pageStart={1}
          loadMore={loadNextPendingReports}
          hasMore={hasNextPage}
          loader={
            <div key={0} className="loader">
              Carregando...
            </div>
          }
        >
          {pendingReportsList.map((report) => {
            const isChecked =
              selectedExpenseReportTokenToAdd === report.expenseReportToken;

            return (
              <ReportSelectItem
                reportData={report}
                key={report.expenseReportToken}
                isChecked={isChecked}
                handleChange={handleCheckExpenseReport(
                  report.expenseReportToken,
                )}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      <Row className={styles.actions}>
        <StContainedButton
          isFullWidth={false}
          className={styles.button}
          style={{ padding: ".5rem 1rem" }}
          disabled={!isAnyReportSelected || loadingReports}
          loading={loadingReports}
          onClick={
            type === EXPENSE_TYPES.EXPENSE
              ? handleAddExpenses
              : handleAddAdvancedExpense
          }
        >
          {type === EXPENSE_TYPES.EXPENSE
            ? "Registrar despesa"
            : "Registrar adiantamento"}
        </StContainedButton>
        <StTextButton
          disabled={loadingReports}
          onClick={handleCloseReportListDrawer}
          className={styles.cancelButton}
        >
          Cancelar
        </StTextButton>
      </Row>
    </div>
  );
};

interface DrawerReportSelectProps {
  type?: ExpenseTypes["EXPENSE"] | ExpenseTypes["ADVANCED_EXPENSE"];
}

const DrawerReportSelect = ({
  type = EXPENSE_TYPES.EXPENSE,
}: DrawerReportSelectProps) => {
  const { isReportListDrawerOpen, handleCloseReportListDrawer } = useContext(
    ExpensesContext,
  );

  return (
    <Drawer
      open={isReportListDrawerOpen}
      onClose={handleCloseReportListDrawer}
      anchor="right"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <ReportSelectDrawer type={type} />
    </Drawer>
  );
};

export { DrawerReportSelect as ReportSelectDrawer };
