import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  badge: () =>
    StyleSheet.create({
      root: {
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        gap: 4,
        justifyContent: "flex-start",
      },
      strong: {
        fontWeight: 600 - 100,
      },
    }),
};
