import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px",
    width: "100%",
  }),
  sheet: {
    root: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
      width: "100%",
    }),
    trigger: {
      icon: css({
        color: theme.colors.gray[300],
      }),
      root: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        color: theme.colors.gray[500],
        fontWeight: 400,
        padding: "13px 16px",
        ":hover": {
          border: `1px solid ${theme.colors.gray[100]}`,
          color: theme.colors.gray[700],
        },
      }),
    },
  },
};
