import React, { useMemo } from "react";

import { navigate, RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { UserModel } from "~/apps/corporate/models/user.model";
import { Drawer, DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { FullPageLoader } from "~/apps/shared/components/full-page-loader/full-page-loader";
import { ALERT_TYPES, USER_ROLES } from "~/apps/shared/constants";
import { DEFAULT_CAPABILITIES } from "~/apps/shared/constants/capabilities.constants";
import { useParams } from "~/apps/shared/hooks/use-params";
import { Document } from "~/apps/shared/models/documents.model";

import TITLES from "@constants/page-titles.constants";

import { formatPrefixPhone } from "~/helpers";

import { ApprovalProcessSearchItem } from "~/models/approval-process.model";

import {
  useCreateUserMutation,
  useEditUserMutation,
  useUserData,
} from "../users.hooks";
import { styles } from "./styles";
import { UserFormApprovalProcess } from "./user-form-approval-process/user-form-approval-process";
import { UserFormBillingProfile } from "./user-form-billing-profile/user-form-billing-profile";
import { UserFormBookerTargets } from "./user-form-booker-targets/user-form-booker-targets";
import { UsersFormDocumentsList } from "./user-form-documents-list/user-form-documents-list";
import { UserFormFooter } from "./user-form-footer/user-form-footer";
import { UserFormGeneralInfo } from "./user-form-general-info/user-form-general-info";
import { UserFormPermissions } from "./user-form-permissions/user-form-permissions";
import { UserFormWarnings } from "./user-form-warnings/user-form-warnings";
import {
  CreateUserSchema,
  createUserSchema,
  EditUserSchema,
  editUserSchema,
} from "./users-user-form.schema";
import { BookerTarget } from "./users-user-form.types";

const onClose = () => navigate("/configurations/users");

type Props = RouteComponentProps<{
  userToken?: string;
}> & {
  mode: "create" | "edit";
};

export const UsersUserForm: React.FC<Props> = ({ mode, userToken }) => {
  const params = useParams();
  const { type } = params;

  const { user } = useUser();

  const { errorOnFetchUserData, isLoadingUserData, userData } = useUserData(
    userToken,
  );

  const title = useMemo(() => {
    if (type === "guest") {
      return mode === "edit"
        ? `${TITLES.EDIT_GUEST}${
            userData ? ` — ${userData.user.getFullName()}` : ""
          }`
        : TITLES.NEW_GUEST;
    }

    return mode === "edit"
      ? `${TITLES.EDIT_USER}${
          userData ? ` — ${userData.user.getFullName()}` : ""
        }`
      : TITLES.NEW_USER;
  }, [mode, type, userData]);

  if (isLoadingUserData) {
    return (
      <Drawer anchor="right" onClose={onClose} open>
        <DrawerHeader onClose={onClose}>{title}</DrawerHeader>
        <div css={styles.root}>
          <FullPageLoader />
        </div>
      </Drawer>
    );
  }

  if (errorOnFetchUserData) {
    return null;
  }

  if (!user) {
    return null;
  }

  const clientToken = user.getClientToken();

  if (!clientToken) {
    return null;
  }

  return (
    <>
      <PageTitle title={title} />
      <Drawer onClose={onClose} open>
        <DrawerHeader onClose={onClose}>{title}</DrawerHeader>
        <div css={styles.root}>
          {mode === "create" ? (
            <UsersUserFormContainer
              clientToken={clientToken}
              mode={mode}
              type={type}
            />
          ) : userData ? (
            <UsersUserFormContainer
              approvalProcess={userData.approvalProcess}
              bookerTargets={userData.bookerTargets}
              clientToken={clientToken}
              documents={userData.documents}
              mode={mode}
              type={type}
              user={userData.user}
            />
          ) : null}
        </div>
      </Drawer>
    </>
  );
};

type UsersUserFormContainerProps = {
  clientToken: string;
  type: "guest" | "user";
} & (
  | {
      approvalProcess: ApprovalProcessSearchItem;
      bookerTargets: BookerTarget[];
      documents: Document[];
      mode: "edit";
      user: UserModel;
    }
  | {
      mode: "create";
    }
);

const UsersUserFormContainer: React.FC<UsersUserFormContainerProps> = ({
  clientToken,
  type,
  ...props
}) => {
  const { showSnackMessage } = useApplication();

  const { createUser } = useCreateUserMutation();
  const { editUser } = useEditUserMutation(
    props.mode === "edit" ? props.user.getUserToken() : undefined,
  );

  const defaultValues = useMemo(() => {
    if (props.mode === "edit") {
      const { approvalProcess, bookerTargets, user } = props;

      const {
        billingProfileToken,
        bookingPhoneConfig,
        bossToken,
        capabilities,
        email,
        firstName,
        lastName,
        phone,
        role,
        sendSms,
        userToken,
      } = user.toObject();

      const guest = user.isGuest();

      return {
        approvalProcess,
        billingProfileToken: billingProfileToken ? billingProfileToken : "null",
        bookerTargets,
        bossToken,
        bookingPhoneConfig,
        capabilities,
        email,
        firstName,
        guest,
        lastName,
        phone: formatPrefixPhone(phone),
        role: role ? role : USER_ROLES.traveler,
        sendSms: !!sendSms,
        userToken,
      };
    }

    return {
      approvalProcess: null,
      billingProfileToken: "null",
      bookerTargets: [],
      capabilities: type === "guest" ? DEFAULT_CAPABILITIES.TRAVELER : {},
      clientToken,
      email: "",
      firstName: "",
      guest: type === "guest",
      lastName: "",
      phone: "",
      role: type === "guest" ? USER_ROLES.traveler : null,
      sendInvite: false,
    };
  }, [clientToken, props, type]);

  const form = useForm<EditUserSchema>({
    defaultValues,
    onSubmit: async () => {
      const values = form.getValues();

      if (props.mode === "edit") {
        const editUserResponse = await editUser({
          ...(values as EditUserSchema),
        });

        if (editUserResponse.isFailure()) {
          const error = editUserResponse.data;

          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }

        showSnackMessage("Usuário salvo com sucesso!", ALERT_TYPES.SUCCESS);

        onClose();

        return;
      }

      const createUserResponse = await createUser(values as CreateUserSchema);

      if (createUserResponse.isFailure()) {
        const error = createUserResponse.data;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      showSnackMessage("Usuário criado com sucesso!", ALERT_TYPES.SUCCESS);

      onClose();

      return;
    },
    schema: props.mode === "create" ? createUserSchema : editUserSchema,
  });

  const { capabilities, guest, role } = form.watch([
    "capabilities",
    "guest",
    "role",
  ]);

  return (
    <Form css={styles.form.root} context={form}>
      <div css={styles.form.body}>
        {props.mode === "create" ? (
          <UserFormGeneralInfo mode={props.mode} />
        ) : (
          <UserFormGeneralInfo mode={props.mode} user={props.user} />
        )}
        {!guest && role !== USER_ROLES.super ? (
          <>
            <UserFormPermissions />
            {capabilities.search ? <UserFormBookerTargets /> : null}
            <UserFormApprovalProcess />
          </>
        ) : null}
        <UserFormBillingProfile />
        {props.mode === "edit" ? (
          <UsersFormDocumentsList
            documents={props.documents}
            user={props.user}
          />
        ) : null}
        <UserFormWarnings mode={props.mode} type={type} />
      </div>
      <UserFormFooter />
    </Form>
  );
};
