import React, { useMemo } from "react";

import { FlightItineraryServiceModel } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesModel } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import {
  TravelFlightCredit,
  TravelFlightCredits,
} from "~/apps/corporate/models/travel.model";
import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  onChange: (offerToken: string | null) => void;
  selectedTravelFlightCreditOfferToken: string | null;
  travelFlightCredits: TravelFlightCredits;
  travelPaymentServicesModel: ItineraryServicesModel;
};

export const PaymentDecisionCredits: React.FC<Props> = ({
  onChange,
  selectedTravelFlightCreditOfferToken,
  travelFlightCredits,
  travelPaymentServicesModel,
}) => {
  const flightServicesByOfferToken = useMemo(() => {
    return travelPaymentServicesModel
      .getFlightServices()
      .reduce((prev, curr) => {
        const offerToken = curr.getOfferToken();

        prev[offerToken] = curr;

        return prev;
      }, {} as Record<string, FlightItineraryServiceModel>);
  }, [travelPaymentServicesModel]);

  const visibleTravelFlightCredits = useMemo(() => {
    return Object.entries(travelFlightCredits).reduce(
      (prev, [offerToken, travelFlightCredit]) => {
        if (!(offerToken in flightServicesByOfferToken)) {
          return prev;
        }

        const flightServiceModel = flightServicesByOfferToken[offerToken];

        const isSameTerritory =
          flightServiceModel.isInternational() ===
          travelFlightCredit.international;

        if (!isSameTerritory) {
          return prev;
        }

        prev[offerToken] = travelFlightCredit;

        return prev;
      },
      {} as Record<string, TravelFlightCredit>,
    );
  }, [flightServicesByOfferToken, travelFlightCredits]);

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <div css={styles.header.title}>Utilização de créditos:</div>
        <div css={styles.header.description}>
          Escolha em qual oferta aplicar créditos:
        </div>
      </div>
      <CheckboxGroup css={styles.group.root}>
        {Object.keys(visibleTravelFlightCredits).map((offerToken) => {
          const serviceModel = flightServicesByOfferToken[offerToken];

          const servicePresenter = ItineraryServicePresenterFactory.createFlight(
            serviceModel,
          );

          return (
            <CheckboxGroup.Item
              checked={offerToken === selectedTravelFlightCreditOfferToken}
              id={offerToken}
              key={offerToken}
              onChange={() => {
                if (offerToken === selectedTravelFlightCreditOfferToken) {
                  onChange(null);

                  return;
                }

                onChange(offerToken);
              }}
              value={offerToken}
            >
              <span css={styles.group.checkbox.text}>
                {servicePresenter.getFormattedItineraryServiceHeaderName()} (
                {servicePresenter.isInternational()
                  ? "internacional"
                  : "nacional"}
                ).
              </span>
            </CheckboxGroup.Item>
          );
        })}
      </CheckboxGroup>
    </div>
  );
};
