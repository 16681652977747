import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  capabilities: {
    checkbox: {
      group: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "16px",
    width: "100%",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "1.25",
  }),
};
