import { isValidPassword } from "~/apps/shared/helpers/password.helper";
import * as yup from "yup";

export const passwordRedefineFormSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .required("Preencha a confirmação da senha.")
    .test("", "As senhas não conferem.", function (value) {
      return this.parent.password === value;
    }),
  password: yup
    .string()
    .required("Preencha a nova senha.")
    .test("", "Senha deve conter pelo menos 8 caracteres.", (password) => {
      if (!password || password.length < 8) {
        return false;
      }

      return true;
    })
    .test("", "A senha não atende aos padrões.", (password) => {
      if (!password || !isValidPassword(password)) {
        return false;
      }

      return true;
    }),
});

export type PasswordRedefineFormSchema = yup.InferType<
  typeof passwordRedefineFormSchema
>;
