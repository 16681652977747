import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { defaultTheme } from "../../../assets/styles/theme";

interface Props {
  search: string;
}

class NoResults extends React.Component<Props> {
  public render() {
    const { search } = this.props;

    return (
      <MenuItem
        value=""
        style={{
          fontSize: 14,
          color: defaultTheme.textColor,
          cursor: "text"
        }}
      >
        Nenhum resultado encontrado para "
        <span style={{ fontWeight: "bold" }}>{search}</span>"
      </MenuItem>
    );
  }
}

export { NoResults };
