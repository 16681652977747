import forge from "node-forge";
import qs from "querystring";

export const hashCardData = (cardData: any, publicKey: string) => {
  const queryString = qs.stringify(cardData);

  const buffer = forge.util.createBuffer(queryString, "utf8").getBytes();
  const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);

  const encrypted = forgedPublicKey.encrypt(buffer, "RSAES-PKCS1-V1_5");

  const b64Encoded = forge.util.encode64(encrypted);

  return b64Encoded;
};
