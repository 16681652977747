import React, { createContext, useState } from "react";

import { useContextFactory } from "@hooks";

import { FlightSearch, RecentFlightSearch } from "../../NewTrip.types";
import * as RecentFlightsService from "./RecentFlights.service";
import * as utils from "./RecentFlights.utils";

type RecentFlightsContextProps = {
  fetchRecentFlights: () => Promise<void>;
  handlePopulateFields: (selectedFlightSearch: RecentFlightSearch) => void;
  loading: boolean;
  recentFlightSearches: RecentFlightSearch[];
  selectedSearch: FlightSearch;
  userDontHaveRecentSearches: boolean;
};

const initialState: Omit<
  RecentFlightsContextProps,
  "fetchRecentFlights" | "handlePopulateFields"
> = {
  loading: false,
  recentFlightSearches: [],
  selectedSearch: {
    cabinClass: "economy",
    destination: null,
    endDate: null,
    origin: null,
    startDate: null,
    traveler: null,
    type: "roundtrip"
  },
  userDontHaveRecentSearches: false
};

const RecentFlightsContext = createContext({
  ...initialState,
  fetchRecentFlights: async () => {
    return;
  },
  handlePopulateFields: () => {
    return;
  }
} as RecentFlightsContextProps);

const useRecentFlightsContext = useContextFactory(
  "RecentFlightsContext",
  RecentFlightsContext
);

const RecentFlightsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const fetchRecentFlights = async () => {
    setState(prevState => ({ ...prevState, loading: true }));

    const {
      data: recentFlightSearches,
      error
    } = await RecentFlightsService.getUserRecentFlights();

    if (error || !recentFlightSearches) {
      setState(prevState => ({ ...prevState, loading: false }));

      return;
    }

    const userDontHaveRecentSearches = !recentFlightSearches.length;

    setState(prevState => ({
      ...prevState,
      recentFlightSearches,
      userDontHaveRecentSearches,
      loading: false
    }));
  };

  const handlePopulateFields: RecentFlightsContextProps["handlePopulateFields"] = selectedFlightSearch => {
    setState(prevState => ({
      ...prevState,
      selectedSearch: utils.buildFlightFields(selectedFlightSearch)
    }));
  };

  return (
    <RecentFlightsContext.Provider
      value={{ ...state, fetchRecentFlights, handlePopulateFields }}
    >
      {children}
    </RecentFlightsContext.Provider>
  );
};

export { RecentFlightsProvider, useRecentFlightsContext };
