import React from "react";

import { WarningRounded as WarningIcon } from "@material-ui/icons";
import isEmpty from "lodash/isEmpty";

import { Flex, Text } from "@toolkit";

import { BillingProfileListItem } from "@models/billing-profile.model";

import { ContainedButton } from "@components/shared/buttons/contained-button";

import {
  useBillingProfileContext,
  useBillingProfileFormActionsContext,
  useBillingProfileDeletionActionsContext,
} from "../BillingProfiles.context";
import { BillingProfileCard } from "./billing-profile-card";
import { styles } from "./styles";

export const ProfilesList = () => {
  const { billingProfiles } = useBillingProfileContext();
  const {
    handleOpenForm,
    handleEditProfile,
  } = useBillingProfileFormActionsContext();
  const { handleDelete } = useBillingProfileDeletionActionsContext();

  const sortedBillingProfiles = billingProfiles.sort((a, b) => {
    if (!a.createdAt || !b.createdAt) {
      return 0;
    }

    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <ContainedButton css={styles.top.button} onClick={handleOpenForm}>
          Adicionar perfil de cobrança
        </ContainedButton>
        <div css={styles.message.root}>
          <WarningIcon css={styles.message.icon} />
          <span css={styles.message.text}>
            Perfis de cobrança podem levar até 1 dia útil para serem
            configurados e se tornarem válidos.
          </span>
        </div>
      </div>
      {isEmpty(billingProfiles) && (
        <Text>Nenhum perfil de cobrança cadastrado.</Text>
      )}
      <Flex flexDirection="column" style={{ gap: "1rem" }}>
        {sortedBillingProfiles.map((billingProfile: BillingProfileListItem) => (
          <BillingProfileCard
            billingProfile={billingProfile}
            handleDelete={handleDelete}
            handleEdit={handleEditProfile}
            key={billingProfile.billingProfileToken}
          />
        ))}
      </Flex>
    </div>
  );
};
