import React from "react";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { Paper } from "../Paper";
import { PaperTitle } from "../PaperTitle";
import { AdvanceRangeChart } from "./AdvanceRangeChart";
import { AdvanceRangeChartProps } from "./AdvanceRangeChart.types";

export function AdvanceRangeContainer(props: AdvanceRangeChartProps) {
  return (
    <Paper>
      <Flex>
        <PaperTitle>Antecedência de compra</PaperTitle>
        <Text
          color={defaultTheme.subTextColor}
          fontSize={[0, 1]}
          fontWeight="bold"
          ml="xsmall"
        >
          (em dias)
        </Text>
      </Flex>
      <Box mx={-16}>
        <AdvanceRangeChart data={props.data} barTitle={props.barTitle} />
      </Box>
    </Paper>
  );
}
