import * as reviewsApi from "@apis/reviews.api";
import { ERROR } from "@constants";
import { ReviewFormData, SubmitHotelReviewResult } from "./HotelReview.types";

export async function submitHotelReview(
  offerToken: string,
  formData: ReviewFormData
) {
  const result: SubmitHotelReviewResult = {};

  try {
    const requestDto = buildSubmitRequestData(formData);
    result.data = await reviewsApi.submitHotelReview(offerToken, requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SUBMIT_HOTEL_REVIEW_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SUBMIT_HOTEL_REVIEW_ERROR;
    }
  }

  return result;
}

function buildSubmitRequestData(formData: ReviewFormData) {
  return {
    review_title: formData.reviewTitle,
    positive_review: formData.positiveReview,
    negative_review: formData.negativeReview,
    general_grade: formData.generalGrade,
    treatment_grade: formData.treatmentGrade,
    cleaning_grade: formData.cleaningGrade,
    room_grade: formData.roomGrade,
    location_grade: formData.locationGrade,
    wifi_grade: formData.wifiGrade
  };
}
