import { AxiosResponse } from "axios";
import moment from "moment";

import {
  CardCreationResponse,
  CardEditRequest,
  CardEditResponse,
  CardResponse,
  ListTravelPayableCardsRequest,
  ListTravelPayableCardsResponse
} from "../dtos/card.dto";
import { getClientToken, getAuthorizationHeader } from "../helpers/user.helper";
import { CardModel } from "../models/card.model";
import { api } from "./interceptors";

export function listCardsByClient(): Promise<CardModel[]> {
  return api
    .request({
      url: `/booking/client/${getClientToken()}/card`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CardResponse[]>) =>
      response.data.map(card =>
        Object.assign({}, card, {
          token: card.creditCardToken,
          createdAt: moment(card.createdAt)
        })
      )
    );
}

export function listCardsByUser(userToken: string): Promise<CardModel[]> {
  return api
    .request({
      url: `/booking/user/${userToken}/card`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CardResponse[]>) =>
      response.data.map(card =>
        Object.assign({}, card, {
          token: card.creditCardToken,
          createdAt: moment(card.createdAt)
        })
      )
    );
}

export function listCorporateIndividualCards(
  userToken: string
): Promise<CardModel[]> {
  return api
    .request({
      url: `/booking/user/${userToken}/card-individual`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<CardResponse[]>) =>
      response.data.map(card =>
        Object.assign({}, card, {
          token: card.creditCardToken,
          createdAt: moment(card.createdAt)
        })
      )
    );
}

export function listTravelPayableCards(
  requestData: ListTravelPayableCardsRequest
): Promise<AxiosResponse<ListTravelPayableCardsResponse>> {
  const { billingProfileToken, travelToken } = requestData;

  return api.request({
    url: `/booking/billing-profiles/${billingProfileToken}/travels/${travelToken}/cards-new`,
    method: "GET",
    headers: getAuthorizationHeader()
  });
}

export async function createCorporatePublicCard(
  requestData: any
): Promise<CardModel> {
  return api
    .request({
      url: "/booking/corporate-cards/public",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then((response: AxiosResponse<CardCreationResponse>) =>
      Object.assign({}, response.data.creditCard, {
        token: response.data.creditCard.creditCardToken,
        createdAt: moment(response.data.creditCard.createdAt)
      })
    );
}

export async function createCorporateIndividualCard(
  requestData: any
): Promise<CardModel> {
  return api
    .request({
      url: "/booking/corporate-cards/individual",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then((response: AxiosResponse<CardCreationResponse>) =>
      Object.assign({}, response.data.creditCard, {
        token: response.data.creditCard.creditCardToken,
        createdAt: moment(response.data.creditCard.createdAt)
      })
    );
}

export function deleteCard(cardToken: string): Promise<any> {
  return api.request({
    url: `/booking/cards/${cardToken}`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
}

export function editCard(
  cardToken: string,
  requestData: CardEditRequest
): Promise<CardEditResponse> {
  return api.request({
    url: `/booking/cards/${cardToken}`,
    method: "PUT",
    headers: getAuthorizationHeader(),
    data: requestData
  });
}
