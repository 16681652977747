import React, { useCallback, useEffect } from "react";

import {
  globalHistory,
  Link,
  Redirect,
  RouteComponentProps,
  Router,
} from "@reach/router";
import * as userApi from "~/apps/corporate/apis/user.api";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Logo } from "~/apps/shared/components/logo/logo";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { AUTHENTICATION_ERRORS } from "~/apps/shared/constants/errors";
import { getParamsFromLocation } from "~/apps/shared/utils/get-params-from-location";

import { Button } from "@toolkit/v2";

import { AuthLayout } from "../../components/auth-layout/auth-layout";
import { PageTitle } from "../../components/page-title/page-title";
import { ScrollToTop } from "../../components/scroll-to-top/scroll-to-top";
import { pageTitles } from "../../constants/page-titles";
import { useApplication } from "../../contexts/application.context";
import {
  ssoLoginFormSchema,
  SSOLoginFormSchema,
} from "./sso-login-form.schema";
import { SSOLoginUniq } from "./sso-login-uniq/sso-login-uniq";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Component: React.FC<Props> = () => {
  const { showSnackMessage } = useApplication();

  const form = useForm<SSOLoginFormSchema>({
    defaultValues: {
      email: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      await loginSSO(values.email);
    },
    schema: ssoLoginFormSchema,
  });

  const loginSSO = useCallback(
    async (email: string) => {
      try {
        const ssoResult = await userApi.ssoLogin(email);

        globalHistory.navigate(ssoResult.redirectUrl);
      } catch (err) {
        let errorMessage = "Usuário ou cliente inválidos.";

        if (err.response) {
          const { data } = err.response;

          if (data.message === AUTHENTICATION_ERRORS.MISSING_SSO_CONFIGS) {
            errorMessage = "Usuário não possui SSO habilitado.";
          }

          if (data.message === AUTHENTICATION_ERRORS.AUTH_METHOD_NOT_ENABLED) {
            errorMessage =
              "Cliente não possuí esse metódo de autenticação habilitado";
          }
        }

        showSnackMessage(errorMessage, ALERT_TYPES.ERROR);
      }
    },
    [showSnackMessage],
  );

  useEffect(() => {
    const params = getParamsFromLocation(window.location);

    if (params.error) {
      showSnackMessage(
        "Erro ao realizar login, tente novamente.",
        ALERT_TYPES.ERROR,
      );
    }
  }, [showSnackMessage]);

  return (
    <>
      <PageTitle title={pageTitles.BASE_TITLE} />
      <AuthLayout>
        <Logo showName />
        <Form context={form} css={styles.form.root}>
          <div css={styles.form.body}>
            <div css={styles.form.field.root}>
              <Form.Field
                as={
                  <Input
                    aria-label="Email"
                    id="email"
                    placeholder="Email"
                    type="email"
                  />
                }
                css={styles.form.field.input}
                onChange={() => {
                  void form.trigger("email");
                }}
                name="email"
              />
              <InputErrorMessage css={styles.form.field.error}>
                {form.errors["email"]?.message}
              </InputErrorMessage>
            </div>
            <Button
              css={styles.form.button}
              disabled={!form.formState.isValid || form.submitting}
              type="submit"
            >
              {!form.submitting ? "Entrar" : "Entrando..."}
            </Button>
          </div>
        </Form>
        <hr css={styles.divisor} />
        <div css={styles.footer.root}>
          <Link css={styles.footer.sso} to="/login">
            Voltar
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <Component path="/" />
      <SSOLoginUniq path="/:ssoToken/uniq" />
      <Redirect from="*" noThrow to="." />
    </Router>
  );
};

export const SSOLogin: React.FC<Props> = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
