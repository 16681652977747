import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      paddingBottom: "24px",
    }),
    tags: {
      root: css({
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
      }),
      tag: {
        clear: css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[300],
          cursor: "pointer",
        }),
        root: css({
          alignItems: "center",
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          color: theme.colors.gray[300],
          display: "flex",
          fontSize: "0.875rem",
          fontWeight: 600,
          gap: "6px",
          padding: "6px 12px",
        }),
      },
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
};
