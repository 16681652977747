import React, { createContext, useCallback, useEffect, useState } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import * as travelHelper from "~/apps/corporate/helpers/travel.helper";
import { TravelApprovalStatusModelFactory } from "~/apps/corporate/models/approval-status.model";
import { ItineraryServiceModel } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import { OffersAvailabilitiesModelFactory } from "~/apps/corporate/models/offer.model";
import { TravelApprovalModelFactory } from "~/apps/corporate/models/travel.model";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { useItineraryServices } from "../itinerary/itinerary-services.context";
import { useItinerary } from "../itinerary/itinerary.context";
import * as approvalReviewService from "./approval-review.service";

interface Actions {
  approveApprovalRequest: (approvalJustification?: string) => Promise<boolean>;
  denyApprovalRequest: (denyJustification?: string) => Promise<boolean>;
  setSelectedServices: (
    selectedServices: Readonly<ItineraryServiceModel>[],
  ) => void;
}

type State = {
  isApproved: boolean;
  isDenied: boolean;
  isLoadingApproveApprovalRequest: boolean;
  isLoadingDeclineApprovalRequest: boolean;
  selectedServices: Readonly<ItineraryServiceModel>[] | null;
};

const initialState: State = {
  isApproved: false,
  isDenied: false,
  isLoadingApproveApprovalRequest: false,
  isLoadingDeclineApprovalRequest: false,
  selectedServices: null,
};

type ContextProps = Actions & State;

const ApprovalReviewContext = createContext<ContextProps>({
  ...initialState,
  approveApprovalRequest: async () => {
    return false;
  },
  denyApprovalRequest: async () => {
    return false;
  },
  setSelectedServices: () => {
    return;
  },
});

export const ApprovalReviewProvider: React.FC = ({ children }) => {
  const { removeTravelFromPendingList, showSnackMessage } = useApplication();

  const { travelToken } = useItinerary();
  const { travelApproval, travelApprovalStatus } = useItineraryApproval();
  const {
    // fetchOffersAvailabilitiesAndChanges,
    offersAvailabilities,
  } = useItineraryServices();

  const [state, setState] = useState(initialState);

  const approveApprovalRequest = useCallback(
    async (approvalJustification?: string) => {
      if (!travelApproval || !travelApprovalStatus) {
        return false;
      }

      const travelApprovalStatusModel = TravelApprovalStatusModelFactory.create(
        travelApprovalStatus,
      );

      const travelApprovalModel = TravelApprovalModelFactory.create(
        travelApproval,
      );

      const pendingApprovalRequestToken = travelApprovalStatusModel.getPendingApprovalRequestToken();

      if (!pendingApprovalRequestToken) {
        return false;
      }

      const { selectedServices } = state;

      if (!selectedServices) {
        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingApproveApprovalRequest: true,
      }));

      const travelApprovalOffersToReviewServices = travelApprovalModel.getAllOffersToReviewServices();

      const denyDeniableOffers = async () => {
        const deniableOfferTokens: string[] = [];

        const offersAvailabilitiesModel = offersAvailabilities
          ? OffersAvailabilitiesModelFactory.create(offersAvailabilities)
          : null;

        for (const service of travelApprovalOffersToReviewServices) {
          const isApprovalDeclined = service.isApprovalDeclined();

          const isNotSelected = !selectedServices.some(
            (s) => s.getOfferToken() === service.getOfferToken(),
          );

          const isUnavailable =
            !!offersAvailabilitiesModel &&
            offersAvailabilitiesModel.isOfferUnavailableByOfferToken(
              service.getOfferToken(),
            );

          if (!isApprovalDeclined && (isNotSelected || isUnavailable)) {
            deniableOfferTokens.push(service.getOfferToken());

            continue;
          }
          continue;
        }

        const { error } = await approvalReviewService.denyOffersApproval(
          deniableOfferTokens,
        );

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return false;
        }

        return true;
      };

      const deniedDeniableOffers = await denyDeniableOffers();

      if (!deniedDeniableOffers) {
        setState((prev) => ({
          ...prev,
          isLoadingApproveApprovalRequest: false,
        }));

        return false;
      }

      if (travelApprovalStatusModel.isFinalApprover()) {
        setState((prev) => ({
          ...prev,
          isLoadingApproveApprovalRequest: false,
        }));

        navigate(`/travels/${travelToken}/payment`);

        return true;
      }

      const offersAvailabilitiesModel = offersAvailabilities
        ? OffersAvailabilitiesModelFactory.create(offersAvailabilities)
        : null;

      const selectedAndAvailableOffers = offersAvailabilitiesModel
        ? selectedServices.filter((service) => {
            const offerToken = service.getOfferToken();

            const isServiceAvailable =
              !offersAvailabilitiesModel.getOfferAvailabilityByOfferToken(
                offerToken,
              ) ||
              offersAvailabilitiesModel.isOfferAvailableByOfferToken(
                offerToken,
              );

            return isServiceAvailable;
          })
        : selectedServices;

      if (selectedAndAvailableOffers.length === 0) {
        const { error } = await approvalReviewService.sendApprovalResponse({
          approvalRequestToken: pendingApprovalRequestToken,
          responseMessage: travelHelper.getNoAvailabilityDenyMessage(
            travelApprovalOffersToReviewServices,
          ),
          shouldApprove: false,
        });

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          setState((prev) => ({
            ...prev,
            isLoadingApproveApprovalRequest: false,
          }));

          return false;
        }

        removeTravelFromPendingList(travelToken);

        setState((prev) => ({
          ...prev,
          isLoadingApproveApprovalRequest: false,
        }));

        return false;
      }

      if (travelApprovalStatusModel.isFinalApprover()) {
        setState((prev) => ({
          ...prev,
          isLoadingApproveApprovalRequest: false,
        }));

        return true;
      }

      const { error } = await approvalReviewService.sendApprovalResponse({
        approvalRequestToken: pendingApprovalRequestToken,
        responseMessage: approvalJustification,
        shouldApprove: true,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          isLoadingApproveApprovalRequest: false,
        }));

        return false;
      }

      removeTravelFromPendingList(travelToken);

      setState((prev) => ({
        ...prev,
        isApproved: true,
        isLoadingApproveApprovalRequest: false,
      }));

      return true;
    },
    [
      removeTravelFromPendingList,
      showSnackMessage,
      state.selectedServices,
      travelApproval,
      travelApprovalStatus,
      travelToken,
      offersAvailabilities,
    ],
  );

  const denyApprovalRequest = useCallback(
    async (denyJustification?: string) => {
      if (!travelApprovalStatus) {
        return false;
      }

      const travelApprovalStatusModel = TravelApprovalStatusModelFactory.create(
        travelApprovalStatus,
      );

      const pendingApprovalRequestToken = travelApprovalStatusModel.getPendingApprovalRequestToken();

      if (!pendingApprovalRequestToken) {
        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingDeclineApprovalRequest: true,
      }));

      const { error } = await approvalReviewService.sendApprovalResponse({
        approvalRequestToken: pendingApprovalRequestToken,
        responseMessage: denyJustification,
        shouldApprove: false,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          isLoadingDeclineApprovalRequest: false,
        }));

        return false;
      }

      removeTravelFromPendingList(travelToken);

      setState((prev) => ({
        ...prev,
        isDenied: true,
        isLoadingDeclineApprovalRequest: false,
      }));

      return true;
    },
    [
      removeTravelFromPendingList,
      showSnackMessage,
      travelApprovalStatus,
      travelToken,
    ],
  );

  const setSelectedServices = useCallback(
    (selectedServices: Readonly<ItineraryServiceModel>[]) => {
      setState((prev) => ({
        ...prev,
        selectedServices,
      }));
    },
    [],
  );

  useEffect(() => {
    if (!travelApproval) {
      return;
    }

    const travelApprovalModel = TravelApprovalModelFactory.create(
      travelApproval,
    );

    const travelApprovalAvailableOffersToReviewServices = travelApprovalModel.getAllAvailableOffersToReviewServices();

    const selectedServices = travelApprovalAvailableOffersToReviewServices.filter(
      (service) => !service.isApprovalDeclined(),
    );

    setState((prev) => ({
      ...prev,
      selectedServices,
    }));

    return;

    // void (async () => {
    //   const {
    //     offersAvailabilities
    //   } = await fetchOffersAvailabilitiesAndChanges(
    //     travelApprovalNotEmittedServices
    //   );

    //   setState(prev => ({
    //     ...prev,
    //     selectedServices: travelApprovalNotEmittedServices.filter(service => {
    //       const offerToken = service.getOfferToken();

    //       if (
    //         !offersAvailabilities.getOfferAvailabilityByOfferToken(offerToken)
    //       ) {
    //         return true;
    //       }

    //       const isAvailable = offersAvailabilities.isOfferAvailableByOfferToken(
    //         offerToken
    //       );

    //       return isAvailable;
    //     })
    //   }));
    // })();
  }, [
    // fetchOffersAvailabilitiesAndChanges,
    travelApproval,
  ]);

  return (
    <ApprovalReviewContext.Provider
      value={{
        ...state,
        approveApprovalRequest,
        denyApprovalRequest,
        setSelectedServices,
      }}
    >
      {children}
    </ApprovalReviewContext.Provider>
  );
};

export const useApprovalReview = useContextFactory(
  "ApprovalReviewContext",
  ApprovalReviewContext,
);
