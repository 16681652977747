import React from "react";

import { styles } from "./styles";

export const PurchaseAdvanceInDaysInput: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Antecedência de compra</span>
      <span css={styles.text}>
        Mínimo de <input css={styles.input} type="number" {...props} />
        <span> dia(s)</span>
      </span>
    </div>
  );
};
