import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";
import {
  ItineraryDocumentPendenciesDocuments,
  ItineraryPendenciesModel,
} from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import { DocumentTarget, DocumentType } from "~/apps/shared/constants/enums";

class DocumentationAccordionFormDefaultValuesCalculator {
  constructor(
    private readonly infoModel: ItineraryInfoModel,
    private readonly pendenciesModel: ItineraryPendenciesModel,
  ) {}

  public calculate({ target }: { target: DocumentTarget }) {
    const documentPendenciesModel = this.getDocumentPendenciesModel();
    const travelerToken = this.infoModel.getTravelerToken();

    if (documentPendenciesModel.isTravelerIdentified()) {
      target = documentPendenciesModel.getTravelerIdentification();
    }

    return documentPendenciesModel
      .getDocumentsPendingForTarget(target)
      .map((document) => {
        if (document.type === DocumentType.PASSPORT) {
          return {
            birthDate: null,
            documentNumber: "",
            expirationDate: null,
            firstName: "",
            issueDate: null,
            issuingCountry: "BR",
            lastName: "",
            nationality: "BR",
            residenceCountry: "BR",
            sex: "M",
            type: document.type,
            userToken: travelerToken,
          };
        }

        return {
          birthDate: null,
          documentNumber: "",
          expirationDate: null,
          firstName: "",
          issueDate: null,
          issuingCountry: null,
          lastName: "",
          nationality: null,
          residenceCountry: null,
          sex: "M",
          type: document.type,
          userToken: travelerToken,
        };
      });
  }

  private getDocumentPendenciesModel() {
    return this.pendenciesModel.pendencies.itineraryDocumentPendencies;
  }
}

class DocumentationAccordionPresenter {
  constructor(
    private readonly infoModel: ItineraryInfoModel,
    private readonly pendenciesModel: ItineraryPendenciesModel,
  ) {}

  public calculateDefaultValue({ target }: { target: DocumentTarget }) {
    return new DocumentationAccordionFormDefaultValuesCalculator(
      this.infoModel,
      this.pendenciesModel,
    ).calculate({
      target,
    });
  }

  private getDocumentPendenciesModel() {
    return this.pendenciesModel.pendencies.itineraryDocumentPendencies;
  }

  public getDocumentsPendingForTarget(target: DocumentTarget) {
    const documentPendenciesModel = this.getDocumentPendenciesModel();

    const documentsPendingForTarget = documentPendenciesModel.getDocumentsPendingForTarget(
      target,
    );

    const documentsPendingForTargetSortedByType = this.sortDocumentsByType(
      documentsPendingForTarget,
    );

    return documentsPendingForTargetSortedByType;
  }

  public isDocumentationRequired() {
    return this.pendenciesModel.isDocumentationRequired();
  }

  public getTravelerIdentification() {
    const documentPendenciesModel = this.getDocumentPendenciesModel();

    return documentPendenciesModel.getTravelerIdentification();
  }

  public isTravelerUnidentified() {
    const documentPendenciesModel = this.getDocumentPendenciesModel();

    return documentPendenciesModel.isTravelerUnidentified();
  }

  private sortDocumentsByType(documents: ItineraryDocumentPendenciesDocuments) {
    return documents.sort((a, b) => {
      if (a.type === DocumentType.CPF) {
        return -1;
      }

      if (b.type === DocumentType.CPF) {
        return 1;
      }

      if (a.type === DocumentType.RG) {
        return -1;
      }

      if (b.type === DocumentType.RG) {
        return 1;
      }

      return 0;
    });
  }

  public showDocumentTargetSwitch() {
    const documentPendenciesModel = this.getDocumentPendenciesModel();

    return (
      documentPendenciesModel.hasDifferentDocumentsPendingForDifferentTargets() &&
      documentPendenciesModel.isTravelerUnidentified()
    );
  }
}

export class DocumentationAccordionPresenterFactory {
  public static create(
    infoModel: ItineraryInfoModel,
    pendenciesModel: ItineraryPendenciesModel,
  ) {
    return new DocumentationAccordionPresenter(infoModel, pendenciesModel);
  }
}
