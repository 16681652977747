import { css as ecss } from "emotion";

export const styles = {
  content: ecss({
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    padding: "16px",
    width: "100%"
  }),
  header: ecss({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& > :first-child": {
      width: "100%"
    }
  }),
  item: ecss({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ":not(:last-child)": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
    }
  }),
  root: ecss({
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }),
  trigger: ecss({
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.75
    }
  })
};
