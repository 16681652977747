import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useItinerary } from "../../itinerary.context";
import { styles } from "./styles";

export const ItineraryHeaderCopyTravelTokenButton: React.FC = () => {
  const { travelToken } = useItinerary();

  const [copiedTravelToken, setCopiedTravelToken] = useState(false);

  const handleCopyTravelToken = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      await navigator.clipboard.writeText(travelToken);

      if (copiedTravelToken) {
        return;
      }

      setCopiedTravelToken(true);

      setTimeout(() => {
        setCopiedTravelToken(false);
      }, 2000);
    },
    [copiedTravelToken, travelToken],
  );

  return (
    <div css={styles.root}>
      <Tooltip
        content={copiedTravelToken ? "Copiado!" : "Copiar"}
        position="top"
      >
        <button
          css={styles.button}
          onClick={async (e) => {
            await handleCopyTravelToken(e);
          }}
        >
          <Icon
            size={16}
            use={copiedTravelToken ? "check" : "clipboard-document"}
          />
        </button>
      </Tooltip>
      <span>ID: {travelToken}</span>
    </div>
  );
};
