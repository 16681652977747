import { css as ecss } from "emotion";

export const styles = {
  root: ({ color, size }: { color?: string; size?: number }) =>
    ecss({
      alignItems: "center",
      aspectRatio: "1",
      ...(color && { color: `${color}` }),
      display: "flex",
      height: `${size || 24}px`,
      justifyContent: "center",
      transition: "all 0.15s ease-in-out",
      width: `${size || 24}px`
    })
};
