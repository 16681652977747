import * as yup from "yup";

import { CARD_OWNER_TYPES } from "~/constants";

import { SelectOption } from "~/schemas/common-fields.schema";

export const EDIT_CREDIT_CARD_SCHEMA = yup.object().shape({
  description: yup.string().required("Dê um apelido ao cartão"),
  ownerType: yup.string().oneOf(Object.values(CARD_OWNER_TYPES)).required(),
  billingProfile: SelectOption(),
  allowApprovers: yup.boolean().when("ownerType", {
    is: CARD_OWNER_TYPES.USER,
    then: yup.boolean().required()
  })
});
