import React from "react";
import { Box, Flex, Text } from "@toolkit";
import { Input } from "@shared/inputs";
import times from "lodash/times";
import MenuItem from "@material-ui/core/MenuItem";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { label: string };

const ItemGradeSelect = ({ label, ...other }: Props) => {
  return (
    <Box mb={["small"]}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text mr="medium" fontSize={[1]}>
          {label}:
        </Text>
        <Input {...other} select={true} style={{ width: 80 }}>
          {times(10, i => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Input>
      </Flex>
    </Box>
  );
};

export { ItemGradeSelect };
