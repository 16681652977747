/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Seat } from "./Seat";
import { UnavailableSeat } from "./UnavailableSeat";
import { css, jsx } from "@emotion/core";
import styled from "@shared/styled";
import { BusContainer } from "./BusContainer";
import maxBy from "lodash/maxBy";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import uuid from "uuid";

const getAxisYStyles = ({ position, theme }) => {
  const baseStyles = {
    "0": css`
      position: absolute;
      bottom: 0;

      @media screen and (max-width: ${theme.breakpoints[1]}) {
        left: 0;
        bottom: unset;
      }
    `,
    "1": css`
      position: absolute;
      bottom: 44px;

      @media screen and (max-width: ${theme.breakpoints[1]}) {
        left: 44px;
        bottom: unset;
      }
    `,
    "3": css`
      position: absolute;
      top: 44px;
      bottom: 100px;

      @media screen and (max-width: ${theme.breakpoints[1]}) {
        left: unset;
        bottom: unset;
        right: 44px;
      }
    `,
    "4": css`
      position: absolute;
      top: 0px;
      bottom: 100px;

      @media screen and (max-width: ${theme.breakpoints[1]}) {
        left: unset;
        bottom: unset;
        right: 0px;
      }
    `
  };

  return baseStyles[position.y];
};

const getAxisXStyle = ({ position, theme }) => css`
  position: absolute;
  left: ${position.x * 44}px;

  @media screen and (max-width: ${theme.breakpoints[1]}) {
    top: ${position.x * 44}px;
  }
`;

const SeatPositionDiv = styled.div`
  ${props => css`
    height: 32px;
    width: 36px;
    ${getAxisXStyle(props)}
    ${getAxisYStyles(props)}

    @media screen and (max-width: ${props.theme.breakpoints[1]}) {
      height: 36px;
      width: 32px;
    };
  `};
`;

const SeatsContainer = styled.div`
  ${props => css`
    position: relative;
    height: 190px;
    width: ${44 * (props.maxX + 1)}px;

    @media screen and (max-width: ${props.theme.breakpoints[1]}) {
      height: ${44 * (props.maxX + 1)}px;
      width: 190px;
    }
  `};
`;

const SeatPicker = ({
  seats,
  axisZ,
  maxSelection,
  selectedSeats,
  handleSelect
}) => {
  const [maxX, setMaxX] = useState(0);
  const selectedSeatsMap = selectedSeats.reduce((acc, seat) => {
    acc[seat.id] = seat;
    return acc;
  }, {});

  const onSeatClick = seat => () => {
    const selectedSize = size(selectedSeats);
    const isAlreadySelected = !!selectedSeatsMap[seat.id];
    const hasAchievedMaxLength = selectedSize === maxSelection;

    if (!isAlreadySelected && hasAchievedMaxLength) {
      return null;
    }

    if (!isAlreadySelected) {
      const updatedSeats = selectedSeats.concat(seat);
      handleSelect(updatedSeats);
    } else {
      const updatedSeats = selectedSeats.filter(item => item.id !== seat.id);
      handleSelect(updatedSeats);
    }
  };

  const renderSeats = () => {
    return seats
      .filter(seat => parseInt(seat.position.z, 10) === axisZ)
      .map(seat => {
        return (
          <SeatPositionDiv
            key={seat.id ? seat.id : uuid()}
            position={seat.position}
          >
            {seat.available ? (
              <Seat
                label={seat.name}
                selected={!!selectedSeatsMap[seat.id]}
                onClick={onSeatClick(seat)}
              />
            ) : (
              <UnavailableSeat />
            )}
          </SeatPositionDiv>
        );
      });
  };

  useEffect(
    () => {
      if (!isEmpty(seats)) {
        const maxXSeat = maxBy(seats, seat => parseInt(seat.position.x, 10));
        setMaxX(parseInt(maxXSeat.position.x, 10));
      }
    },
    [seats]
  );

  const containerWidth = 44 * (maxX + 1) + 52;

  return (
    <BusContainer zAxis={axisZ} width={`${containerWidth}px`}>
      <SeatsContainer maxX={maxX}>{renderSeats()}</SeatsContainer>
    </BusContainer>
  );
};

// SeatPicker.propTypes = {
//   seats: arrayOf(object),
//   selectedSeats: arrayOf(object),
//   axisZ: number,
//   maxSelection: number,
//   handleSelect: func,
//   handleRemove: func
// };

SeatPicker.defaultProps = {
  axisZ: 0,
  maxSelection: 1,
  selectedSeats: []
};

export { SeatPicker };
