import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  header: {
    root: css({}),
  },
  main: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "20px",
      fontWeight: 600,
      padding: "12px 0",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  }),
};
