import React from "react";
import { useTravelApprovalsContext } from "./TravelApprovals.context";
import { css } from "emotion";
import { TravelCardContainer } from "./TravelCard/TravelCard.container";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache
} from "react-virtualized";
import InfiniteScroll from "react-infinite-scroller";
import isEmpty from "lodash/isEmpty";
import { MEDIA_QUERIES } from "@constants";

const styles = {
  root: css({
    paddingTop: "2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingBottom: "4rem"
    }
  })
};

const TravelApprovalsList = () => {
  const {
    travels,
    pages,
    currentPage,
    status,
    loading,
    loadNextPage
  } = useTravelApprovalsContext();
  const hasNextPage = !loading && currentPage < pages;
  const rowCount = travels.length;

  const listMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 200
  });

  const rowRenderer = ({ index, key, style, parent }: any) => {
    const travel = travels[index];

    return (
      <CellMeasurer
        key={key}
        cache={listMeasureCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          <TravelCardContainer
            key={travel.token}
            travel={travel}
            status={status}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div className={styles.root}>
      {isEmpty(travels) && !loading ? (
        <p>Nenhuma aprovação encontrada</p>
      ) : null}
      <InfiniteScroll
        useWindow={true}
        pageStart={1}
        loadMore={loadNextPage}
        hasMore={hasNextPage}
        loader={
          <div key={0} className="loader">
            Loading ...
          </div>
        }
      >
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <List
              autoHeight={true}
              width={width}
              height={rowCount * 200}
              rowHeight={listMeasureCache.rowHeight}
              rowRenderer={rowRenderer}
              rowCount={rowCount}
              overscanRowCount={5}
              deferredMeasurementCache={listMeasureCache}
              style={{ outline: "none" }}
            />
          )}
        </AutoSizer>
      </InfiniteScroll>
    </div>
  );
};

export { TravelApprovalsList };
