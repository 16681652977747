import React, { useContext } from "react";
import { DeclineFormPresentational } from "./DeclineForm.presentational";
import { ReportApprovalReviewContext } from "../ReportApprovalReview.context";

const DeclineFormContainer: React.FC = () => {
  const {
    traveler,
    respondingDeny,
    declineMessage,
    isDeclineFormVisible,
    handleChangeDeclineMessage,
    handleCloseDeclineForm,
    handleDeclineRequest
  } = useContext(ReportApprovalReviewContext);

  return (
    <DeclineFormPresentational
      traveler={traveler!}
      loadingDeny={respondingDeny}
      declineMessage={declineMessage}
      isDeclineFormVisible={isDeclineFormVisible}
      handleChangeDeclineMessage={handleChangeDeclineMessage}
      handleCancelDeny={handleCloseDeclineForm}
      handleProcessDeny={handleDeclineRequest}
    />
  );
};

export { DeclineFormContainer as DeclineForm };
