import React, { useCallback } from "react";

import { Link } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { Button } from "@toolkit/v2";

import { useItineraryApproval } from "../../../itinerary-approval.context";
import { useItineraryInfo } from "../../../itinerary-info.context";
import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryServices } from "../../../itinerary-services.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { useItinerary } from "../../../itinerary.context";
import { styles } from "./styles";

type Props = {
  canCancelApprovalRequest?: boolean;
  canGoToApprovalReview?: boolean;
};

export const TravelPlanApprovalHistory: React.FC<Props> = ({
  canCancelApprovalRequest,
  canGoToApprovalReview,
}) => {
  const { user } = useUser();

  const {
    travelApprovalHistoryModel,
    travelApprovalStatusModel,
  } = useItineraryContainer();

  const { travelToken } = useItinerary();
  const {
    cancelApprovalProcess,
    isLoadingCancelOwnApprovalProcess,
  } = useItineraryApproval();
  const { fetchItineraryInfo } = useItineraryInfo();
  const { openApprovalHistoryDialog } = useItineraryScreen();
  const { fetchItineraryServices } = useItineraryServices();

  const handleCancelOwnApprovalProcess = useCallback(async () => {
    if (!travelApprovalHistoryModel) {
      return;
    }

    const lastestApprovalRecord = travelApprovalHistoryModel.getLatestApprovalRecord();

    if (!lastestApprovalRecord) {
      return;
    }

    await cancelApprovalProcess(
      lastestApprovalRecord.getApprovalRequestToken(),
    );

    await Promise.all([fetchItineraryInfo(), fetchItineraryServices()]);
  }, [
    cancelApprovalProcess,
    fetchItineraryInfo,
    fetchItineraryServices,
    travelApprovalHistoryModel,
  ]);

  if (!travelApprovalHistoryModel || !user) {
    return null;
  }

  if (!travelApprovalHistoryModel.hasRecords()) {
    return null;
  }

  const allCurrentApprovers = travelApprovalStatusModel
    ? travelApprovalStatusModel.getAllCurrentApprovers()
    : null;

  const isPendingApproval = travelApprovalHistoryModel.isPendingApproval();

  const lastestApprovalRecord = travelApprovalHistoryModel.getLatestApprovalRecord();

  const lastestApprovalRecordApprover = lastestApprovalRecord
    ? lastestApprovalRecord.getApprover()
    : null;
  const lastestApprovalRecordIsCanceled = lastestApprovalRecord
    ? lastestApprovalRecord.isCanceled()
    : null;
  const lastestApprovalRecordRequestMessage = lastestApprovalRecord
    ? lastestApprovalRecord.getRequestMessage()
    : null;
  const lastestApprovalRecordResponseMessage = lastestApprovalRecord
    ? lastestApprovalRecord.getResponseMessage()
    : null;

  const showCancelApprovalRequestButton =
    canCancelApprovalRequest && isPendingApproval;

  const showGoToApprovalReviewButton =
    canGoToApprovalReview && isPendingApproval;

  return (
    <div css={styles.root}>
      <span css={styles.title}>Última solicitação de aprovação:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        {allCurrentApprovers &&
        allCurrentApprovers.length > 0 &&
        isPendingApproval ? (
          <>
            <ul css={styles.section.approvers.stage.root}>
              <span css={styles.section.title}>
                Aprovador(es) do nível atual pendente:
              </span>
              {allCurrentApprovers.map((currentStageApprover) => (
                <li
                  css={styles.section.approvers.stage.approver.root}
                  key={currentStageApprover.email}
                >
                  <div css={styles.section.approvers.stage.approver.avatar}>
                    <Avatar name={currentStageApprover.first_name} />
                  </div>
                  <span css={styles.section.approvers.stage.approver.text}>
                    <strong css={styles.section.approvers.stage.approver.name}>
                      {currentStageApprover.first_name}{" "}
                      {currentStageApprover.last_name}
                    </strong>{" "}
                    &lt;
                    {currentStageApprover.email}&gt;
                  </span>
                </li>
              ))}
            </ul>
            <hr css={styles.divisor} />
          </>
        ) : null}
        {lastestApprovalRecord ? (
          <>
            <span css={styles.section.title}>
              Informações da última solicitação:
            </span>
            <span css={styles.section.status.text}>
              Status:{" "}
              <strong
                css={styles.section.status.status({
                  color: lastestApprovalRecord.getStatusColor(),
                })}
              >
                {lastestApprovalRecord.getFormattedStatusLabel()}.
              </strong>
            </span>
            {!lastestApprovalRecordIsCanceled &&
            lastestApprovalRecordRequestMessage ? (
              <span css={styles.section.message}>
                Justificativa enviada em{" "}
                {lastestApprovalRecord.getFormattedCreatedAt()}: &quot;
                <strong css={styles.section.strong}>
                  {lastestApprovalRecordRequestMessage}
                </strong>
                &quot;
              </span>
            ) : null}
            {!lastestApprovalRecordIsCanceled &&
            lastestApprovalRecordResponseMessage ? (
              <span css={styles.section.message}>
                Resposta recebida{" "}
                {lastestApprovalRecordApprover ? (
                  <>
                    de{" "}
                    <Tooltip
                      arrow
                      content={`${lastestApprovalRecordApprover.email}${
                        lastestApprovalRecordApprover.userToken ===
                        user.getUserToken()
                          ? " (você)"
                          : ""
                      }`}
                      position="top"
                    >
                      <strong css={styles.section.strong}>
                        {lastestApprovalRecordApprover.fullName}
                      </strong>
                    </Tooltip>
                  </>
                ) : null}
                {lastestApprovalRecord.getFormattedClosedAt()
                  ? ` em ${lastestApprovalRecord.getFormattedClosedAt()}`
                  : null}
                : &quot;
                <strong css={styles.section.strong}>
                  {lastestApprovalRecordResponseMessage}
                </strong>
                &quot;
              </span>
            ) : null}
          </>
        ) : null}
        {showCancelApprovalRequestButton || showGoToApprovalReviewButton ? (
          <div css={styles.section.buttons}>
            {showGoToApprovalReviewButton ? (
              <Link
                css={styles.section.approval}
                to={`/travels/${travelToken}/approval-review`}
              >
                <Icon size={16} use="thumbs-up" />
                <span>Ir para a aprovação</span>
              </Link>
            ) : null}
            {showCancelApprovalRequestButton ? (
              <Button
                css={styles.section.button}
                disabled={isLoadingCancelOwnApprovalProcess}
                fill="outlined"
                onClick={async () => {
                  await handleCancelOwnApprovalProcess();
                }}
                variant="pink"
              >
                <Icon size={16} use="x-circle-outline" />
                <span>Cancelar pedido de aprovação</span>
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
      <button
        css={styles.section.toggle}
        onClick={() => {
          openApprovalHistoryDialog();
        }}
      >
        Mostrar histórico de aprovações completo.
      </button>
    </div>
  );
};
