import React from "react";

import { Link } from "@reach/router";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryContainer } from "../../../itinerary/itinerary.container";
import { useItinerary } from "../../../itinerary/itinerary.context";
import { styles } from "./styles";

export const PaymentDenied: React.FC = () => {
  const { infoModel, travelApprovalHistoryModel } = useItineraryContainer();

  const { travelToken } = useItinerary();

  if (!travelApprovalHistoryModel) {
    return null;
  }

  const isPendingApproval = travelApprovalHistoryModel.isPendingApproval();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title}>
          {isPendingApproval
            ? "Viagem negada (solicitação declinada):"
            : "Viagem negada:"}
        </span>
      </div>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <div css={styles.section.declined.root}>
          <Icon css={styles.section.declined.icon} size={16} use="x" />
          <span css={styles.section.declined.message}>
            {isPendingApproval ? "Solicitação declinada. " : null}
            Um e-mail com sua resposta foi enviado
            {infoModel ? ` para ${infoModel.getBooker().name}` : null}.
          </span>
        </div>
        {isPendingApproval ? (
          <Link css={styles.section.declined.link} to="/approvals">
            Voltar para minhas aprovações
          </Link>
        ) : (
          <Link
            css={styles.section.declined.link}
            to={`/travels/${travelToken}/itinerary/travel-plan`}
          >
            Voltar para plano de viagem
          </Link>
        )}
      </div>
    </div>
  );
};
