import React, { useCallback, useState } from "react";

import { navigate } from "@reach/router";

import { Button } from "@toolkit/v2";

import { useItineraryContainer } from "../../../itinerary.container";
import { useItinerary } from "../../../itinerary.context";
import { styles } from "./styles";
import { useTravelPlanProceedButtonGuard } from "./travel-plan-proceed-button.hooks";

export const TravelPlanProceedButton: React.FC = () => {
  const { error, permissions } = useTravelPlanProceedButtonGuard();

  const { infoModel } = useItineraryContainer();

  const { travelToken } = useItinerary();
  // const { doesTravelHaveRepeatedOffers } = useItineraryScreen();

  const [isProceedingToPendencies, setIsProceedingToPendencies] = useState(
    false,
  );

  const handleProceedToPendencies = useCallback(async () => {
    // if (!services) {
    //   return;
    // }

    setIsProceedingToPendencies(true);

    // const travelHasRepeatedOffers = await doesTravelHaveRepeatedOffers(
    //   services.getAllServiceOfferTokens(),
    // );

    // if (travelHasRepeatedOffers) {
    //   setIsProceedingToPendencies(false);

    //   return;
    // }

    navigate(`/travels/${travelToken}/itinerary/pendencies`);

    setIsProceedingToPendencies(false);
  }, [
    // doesTravelHaveRepeatedOffers,
    // services,
    travelToken,
  ]);

  if (error) {
    return <TravelPlanProceedButtonError />;
  }

  if (!permissions.canProceedFromTravelPlan) {
    return null;
  }

  if (!infoModel) {
    return null;
  }

  return (
    <div css={styles.root}>
      <Button
        css={styles.button}
        disabled={!infoModel.getTravelName() || isProceedingToPendencies}
        onClick={async () => {
          await handleProceedToPendencies();
        }}
      >
        Prosseguir
      </Button>
      <span css={styles.message}>
        Fique tranquilo, você ainda não será cobrado.
      </span>
    </div>
  );
};

const TravelPlanProceedButtonError: React.FC = () => {
  return (
    <div css={styles.root}>
      <Button css={styles.button} disabled>
        Prosseguir
      </Button>
      <span css={styles.message}>
        Ocorreu um erro. Tente novamente mais tarde.
      </span>
    </div>
  );
};
