import React, { useMemo } from "react";

import { Dialog } from "~/apps/shared/components/dialog/dialog";
import { PhotoGallery } from "~/apps/shared/components/photo-gallery/photo-gallery";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

export const HotelResultPicturesDialog: React.FC = () => {
  const {
    dialogPictures,
    handleClosePicturesDialog,
    isPicturesDialogOpen,
  } = useHotelResult();

  const galleryPictures = useMemo(
    () =>
      dialogPictures.map((picture) => ({
        original: picture,
        thumbnail: picture,
      })),
    [dialogPictures],
  );

  return (
    <Dialog
      css={styles.root}
      onClose={handleClosePicturesDialog}
      open={isPicturesDialogOpen}
    >
      <PhotoGallery
        infinite={false}
        items={galleryPictures}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
      />
    </Dialog>
  );
};
