import React from "react";
import Responsive from "react-responsive";

import { RouteComponentProps } from "@reach/router";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";
import { LoadingOverlay } from "~/apps/shared/components/loading-overlay/loading-overlay";

import { Layout } from "../../components/layout/layout";
import {
  OfferSearcherProvider,
  useOfferSearcher,
} from "../../components/offer-searcher/offer-searcher.context";
import { PageTitle } from "../../components/page-title/page-title";
import { pageTitles } from "../../constants/page-titles";
import { useClientConfig } from "../../contexts/client-config.context";
import {
  NewTripProvider,
  OFFER_SEARCHER_TABS,
  useNewTrip,
} from "./new-trip.context";
import {
  NewTripOfferSearcher,
  NewTripOfferSearcherTabs,
} from "./offer-searcher/offer-searcher";
import { NewTripRecentFlights } from "./recent-flights/recent-flights";
import { NewTripRecentHotels } from "./recent-hotels/recent-hotels";
import { styles } from "./styles";

const Component: React.FC<RouteComponentProps> = () => {
  const {
    clientConfig,
    errorOnFetch: errorOnFetchClientConfig,
  } = useClientConfig();

  const { handleTabChange, selectedTab } = useNewTrip();
  const { isSubmitting } = useOfferSearcher();

  if (errorOnFetchClientConfig) {
    return (
      <ErrorPage
        message={errorOnFetchClientConfig.description}
        title={errorOnFetchClientConfig.title}
      />
    );
  }

  if (isSubmitting) {
    return <LoadingOverlay open={isSubmitting} />;
  }

  return (
    <>
      <PageTitle title={pageTitles.NEW_TRIP} />
      <div css={styles.root}>
        {clientConfig ? (
          <Responsive maxWidth="992px">
            <NewTripOfferSearcherTabs
              handleTabChange={handleTabChange}
              isBusesTabVisible={clientConfig.isBusesVisible()}
              isCarsTabVisible={clientConfig.isCarsVisible()}
              isHotelsTabVisible={clientConfig.isHotelsVisible()}
              isFlightsTabVisible={clientConfig.isFlightsVisible()}
              selectedTab={selectedTab}
            />
          </Responsive>
        ) : null}
        <div css={styles.banner} />
        <div css={styles.container}>
          <NewTripOfferSearcher />
          {selectedTab === OFFER_SEARCHER_TABS.FLIGHT ? (
            <NewTripRecentFlights />
          ) : null}
          {selectedTab === OFFER_SEARCHER_TABS.HOTEL ? (
            <NewTripRecentHotels />
          ) : null}
        </div>
      </div>
    </>
  );
};

export const NewTrip: React.FC<RouteComponentProps> = ({ ...props }) => {
  return (
    <NewTripProvider>
      <OfferSearcherProvider>
        <Layout css={styles.layout}>
          <Component {...props} />
        </Layout>
      </OfferSearcherProvider>
    </NewTripProvider>
  );
};
