import * as bookerTargetApi from "~/apps/corporate/apis/booking-target.api";
import * as flightApi from "~/apps/corporate/apis/flight.api";
import * as hotelApi from "~/apps/corporate/apis/hotel.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { BookingTarget } from "../../models/booking-target.model";
import { RecentFlightsSearch, RecentHotelsSearch } from "./new-trip.types";

export const getUserRecentFlights = async () => {
  const result: { data?: RecentFlightsSearch[]; error?: any } = {};

  try {
    result.data = await flightApi.getUserRecentFlights();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    }
  }

  return result;
};

export const getUserRecentHotels = async () => {
  const result: { data?: RecentHotelsSearch[]; error?: any } = {};

  try {
    result.data = await hotelApi.getUserRecentHotels();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR;
    }
  }

  return result;
};

export const searchBookingTargets = async (search: string) => {
  const result: { data?: BookingTarget[]; error?: Error } = {};

  try {
    result.data = await bookerTargetApi.searchTravelBookingTargets(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    }
  }

  return result;
};
