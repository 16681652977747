import React from "react";

import { ItineraryInfo } from "../../itinerary/itinerary-content/itinerary-info/itinerary-info";
import { ApprovalReviewDecision } from "./approval-review-decision/approval-review-decision";
import { ApprovalReviewPriceBreakdown } from "./approval-review-price-breakdown/approval-review-price-breakdown";
import { ApprovalReviewServicesCard } from "./approval-review-services-card/approval-review-services-card";
import { styles } from "./styles";

export const ApprovalReviewContent: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <ItineraryInfo
          canEditCategorization
          canEditTravelName
          canEditTravelTags
        />
        <ApprovalReviewServicesCard />
      </div>
      <div css={styles.right.root}>
        <ApprovalReviewPriceBreakdown />
        <ApprovalReviewDecision />
      </div>
    </div>
  );
};
