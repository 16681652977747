import React from "react";

import { Popover, withStyles } from "@material-ui/core";

import { UserAutoCompleteSelect } from "../../user-auto-complete-select/user-auto-complete-select";

const StyledPopover = withStyles({
  paper: {
    height: "16rem",
    overflow: "hidden",
  },
})(Popover);

interface IUserAutoCompletePopover {
  containerEl: React.RefObject<HTMLDivElement>;
  approversVisible: boolean;
  toggleApproversVisibility: () => void;
  actionAddApprover: (data: {
    userToken: string;
    email: string;
    fullName: string;
  }) => void;
  ruleIndex: number;
}

export const UserAutoCompletePopover = ({
  containerEl,
  approversVisible,
  toggleApproversVisibility,
  actionAddApprover,
  ruleIndex,
}: IUserAutoCompletePopover) => {
  return (
    <StyledPopover
      anchorEl={containerEl.current}
      open={approversVisible}
      onClose={toggleApproversVisibility}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <UserAutoCompleteSelect
        actionAddApprover={actionAddApprover}
        ruleIndex={ruleIndex}
      />
    </StyledPopover>
  );
};
