import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: {
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.white,
      border: "1px solid",
      borderColor: theme.colors.gray[500],
      borderRadius: "4px",
      bottom: "16px",
      color: theme.colors.gray[500],
      cursor: "pointer",
      display: "flex",
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      fontWeight: 500,
      gap: "8px",
      opacity: 0.9,
      padding: "4px 8px",
      position: "absolute",
      right: "16px",
      transition: "all 0.15s ease-in-out",
      whiteSpace: "nowrap",
      ":hover": {
        borderColor: theme.colors.gray[700],
        color: theme.colors.gray[700],
        opacity: 1,
      },
    }),
  },
  container: ({ expanded }: { expanded: boolean }) =>
    css({
      display: !expanded ? "flex" : "grid",
      height: !expanded ? "322px" : "auto",
      gap: "16px",
      gridTemplateColumns: !expanded ? "unset" : "1fr 1fr",
    }),
  gallery: {
    count: css({
      backgroundColor: theme.colors.gray[500],
      borderRadius: "8px",
      bottom: "24px",
      color: theme.colors.white,
      fontSize: "0.75rem",
      fontWeight: 500,
      padding: "8px 12px",
      position: "absolute",
      right: "24px",
    }),
    root: css({
      position: "relative",
      ".image-gallery": {
        "&-slides": {
          borderRadius: "0px !important",
        },
      },
    }),
  },
  left: {
    picture: css({
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      "@media (max-width: 640px)": {
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        borderTop: `1px inset ${theme.colors.gray[100]}`,
        height: "192px",
      },
    }),
    root: css({
      minWidth: "50%",
      width: "100%",
    }),
  },
  picture: css({
    height: "100%",
    maxHeight: "512px",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
  }),
  right: {
    picture: css({
      height: "153px",
      objectFit: "cover",
      objectPosition: "center",
      width: "254px",
    }),
    root: ({ expanded, length }: { expanded: boolean; length: number }) =>
      css({
        display: !expanded ? "grid" : "flex",
        flexDirection: !expanded ? "unset" : "column",
        gap: "16px",
        gridTemplateColumns: length >= 4 ? "254px 254px" : "unset",
        maxWidth: !expanded ? "50%" : "100%",
      }),
  },
  root: css({
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    margin: "16px 80px",
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 1280px)": {
      margin: "16px 24px",
    },
    "@media (max-width: 992px)": {
      margin: "16px",
    },
    "@media (max-width: 640px)": {
      borderRadius: "unset",
      margin: "unset",
    },
  }),
};
