import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";
import { removeSpecialCharacters } from "~/apps/shared/utils/remove-special-characters";

const buildAirportsData = ({
  location_list,
}: {
  location_list: {
    PlaceName: string;
  }[];
}) => {
  return location_list.map((airport) => {
    return {
      ...airport,
      label: airport.PlaceName,
    };
  });
};

const buildHotelsData = ({
  location_list,
}: {
  location_list: {
    name: string;
  }[];
}) => {
  return location_list.map((location) => {
    return {
      ...location,
      label: location.name,
    };
  });
};

export async function getAirportsSuggestions(search: string) {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: {
        search: removeSpecialCharacters(search),
      },
      timeout: 25000,
      url: `/booking/airports/search`,
    })
    .then(({ data }) => buildAirportsData(data));
}

export async function getBusSuggestions(search: string) {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/autosuggest/bus/${search}`,
    })
    .then(({ data }) =>
      data.location_list.map((location: any) => ({
        ...location,
        label: location.name,
      })),
    );
}

export async function getHotelsSuggestions(term: string) {
  return await api
    .request({
      headers: getAuthorizationHeader(),
      method: "GET",
      timeout: 25000,
      url: `/booking/autosuggest/hotel/${removeSpecialCharacters(term)}`,
    })
    .then(({ data }) => buildHotelsData(data));
}
