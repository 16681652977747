import React from "react";
import Skeleton from "react-loading-skeleton";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

export const HotelResultRules: React.FC = () => {
  const {
    checkinHour,
    checkoutHour,
    loading,
    loadingReceptionHours,
  } = useHotelResult();

  return (
    <div css={styles.root}>
      <h3 css={styles.title}>Regras do Hotel</h3>
      <div css={styles.rules.root}>
        <div css={styles.rules.rule.root}>
          <strong css={styles.rules.rule.label}>Check-in:</strong>{" "}
          {loading || loadingReceptionHours ? (
            <Skeleton height="20px" width="48px" />
          ) : checkinHour ? (
            checkinHour
          ) : (
            "Não disponível"
          )}
        </div>
        <div css={styles.rules.rule.root}>
          <strong css={styles.rules.rule.label}>Check-out:</strong>{" "}
          {loading || loadingReceptionHours ? (
            <Skeleton height="20px" width="48px" />
          ) : checkoutHour ? (
            checkoutHour
          ) : (
            "Não disponível"
          )}
        </div>
      </div>
    </div>
  );
};
