import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  controls: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "24px",
    }),
  },
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
  }),
  header: {
    right: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: "24px",
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: "24px",
      justifyContent: "space-between",
    }),
  },
  list: {
    item: {
      left: {
        name: css({
          color: theme.colors.gray[500],
          fontWeight: 600,
          lineHeight: "24px",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }),
      },
      right: {
        count: css({
          alignItems: "center",
          display: "flex",
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          gap: "8px",
          marginRight: "8px",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.gray,
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        gap: "8px",
        justifyContent: "space-between",
        padding: "8px 16px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  required: {
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      gap: "12px",
      padding: "13px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  }),
};
