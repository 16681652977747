import { FieldError, get } from "react-hook-form";

import { useFormContext } from "../form";

export const useFormError = (fieldName: string): FieldError | undefined => {
  const { errors } = useFormContext();

  const error = get(errors, fieldName);

  return error ? (error as FieldError) : undefined;
};
