import React from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

const styles = {
  input: (isDisabled?: boolean) =>
    css({
      display: "block",
      width: "100%",
      height: "3rem",
      borderRadius: "0.5rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      padding: "0 1rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      "&:hover": {
        border: isDisabled ? "" : `1px solid ${theme.colors.gray[300]}`,
        transition: "200ms"
      },
      "::placeholder": {
        color: `${theme.colors.gray[100]}`
      }
    })
};

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input: React.FC<IInputProps> = ({ disabled, ...props }) => {
  return (
    <input
      type="text"
      css={styles.input(disabled)}
      disabled={disabled}
      {...props}
    />
  );
};
