import React from "react";

import { Redirect, RouteComponentProps, Router } from "@reach/router";

import { Layout } from "../../components/layout/layout";
import { ScrollToTop } from "../../components/scroll-to-top/scroll-to-top";
import { ApprovalReview } from "./approval-review/approval-review";
import { Itinerary } from "./itinerary/itinerary";
import { Payment } from "./payment/payment";
import { Search } from "./search/search";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Outlet: React.FC = () => {
  return (
    <Router css={styles.router} primary={false}>
      <ApprovalReview path="/:travelToken/approval-review" />
      <Itinerary path="/:travelToken/itinerary/*" />
      <Payment path="/:travelToken/payment" />
      <Search path="/:travelToken/search/*" />
      <TravelRedirectToItinerary path="/:travelToken/*" />
      <Redirect from="*" noThrow to="/trips" />
    </Router>
  );
};

const TravelRedirectToItinerary: React.FC<Props> = () => {
  return <Redirect from="*" noThrow to="./itinerary" />;
};

export const Travels: React.FC<Props> = () => {
  return (
    <Layout css={styles.layout}>
      <ScrollToTop />
      <Outlet />
    </Layout>
  );
};
