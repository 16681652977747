import React from "react";

import { LongText } from "~/apps/shared/components/long-text/long-text";

import { styles } from "./styles";

type Props = {
  description: string;
};

export const HotelResultDescription: React.FC<Props> = ({ description }) => {
  return (
    <div css={styles.root}>
      <h3 css={styles.title}>Descrição do Hotel</h3>
      <LongText css={styles.text} expansible limit={300}>
        {description}
      </LongText>
    </div>
  );
};
