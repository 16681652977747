import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import {
  FlightFareDetails,
  FlightSearchInfo,
} from "~/apps/corporate/models/flight.model";
import {
  FlightListOffer,
  SelectedOffer,
} from "~/apps/corporate/models/offer.model";
import { Error } from "~/apps/shared/types";

import { ServiceType } from "@constants/enums";

export interface BaggageFilter {
  checked: boolean;
  label: string;
  value: BaggageFilterEnum;
}

export enum BaggageFilterEnum {
  inbound = "inbound",
  no_baggage = "no_baggage",
  outbound = "outbound",
}

export interface CarrierFareDetails {
  details: FlightFareDetails;
  family: string;
}

export interface CarrierFilter {
  checked: boolean;
  label: string;
  value: string;
}

export interface DurationFilter {
  defaultDurationRange: RangeFilter;
  durationRange: RangeFilter;
}

export interface ClassFilter {
  checked: boolean;
  label: string;
  value: string;
}

export type FlightConflictingInfo = {
  canCancelExistingPreReserve: boolean;
  error: boolean;
  flightOffer: SelectedOffer<ServiceType.FLIGHT> | null;
  isReservationInConflict: boolean;
};

// Context
//! Fields bellow with type `any` should be updated
export type State = {
  addingSituation: boolean;
  addServiceDrawer: {
    isOpen: boolean;
    type: string;
  };
  availableCarriers: CarrierFilter[];
  baggage: BaggageFilter[];
  conflictingInfo: FlightConflictingInfo;
  countryRestriction: CountryRestriction | null;
  durationFilter: {
    defaultDurationRange: RangeFilter;
    durationRange: RangeFilter;
  };
  errorOnFetch: Error | null;
  fareDetailsDrawerIsOpen: boolean;
  filtering: boolean;
  flightClasses: ClassFilter[];
  flightDetailsDrawerIsOpen: boolean;
  flightInfo: FlightSearchInfo | null;
  flights: FlightListOffer[];
  generalInfo: {
    airportPrices?: Record<string, number>;
    averagePrice?: number;
    bestRecommendedPrice?: number;
    carrierPrices?: Record<string, number>;
    cheapestPrice?: number;
    optimalPrice?: number;
    searchResultCount?: number;
    stopPrices?: Record<string, number>;
  };
  hasExpired: boolean;
  inboundFilter: {
    arrivalRange: RangeFilter;
    departureRange: RangeFilter;
  };
  isFakeLoading: boolean;
  isFiltersDrawerVisible: boolean;
  isLoading: boolean;
  isLoadingSelectedFlight: boolean;
  isOrderingDrawerVisible: boolean;
  isPolicyDialogOpen: boolean;
  isPolicyInfoVisible: boolean;
  isReserveCancelationDialogOpen: boolean;
  isReserveCancelLoading: boolean;
  onlyInPolicy: boolean;
  ordering: boolean;
  orderOptions: {
    displayAtOneWayTrips: boolean;
    isSelected: boolean;
    price?: number;
    title: string;
    type: string;
  }[];
  outboundFilter: {
    arrivalRange: RangeFilter;
    departureRange: RangeFilter;
  };
  pageTitle: string;
  planeLimitInfo: IPlaneLimitInfo;
  policy: {
    advance: number;
    allowedClasses: string[];
    maxValue: number;
    maxValueVisible?: number;
    optimalPrice?: {
      enabled: boolean;
      price: number;
    };
    priceExcess: number | null;
  };
  rewardPolicy: any;
  selectedClassOnly: boolean;
  selectedFlight: FlightListOffer | null;
  selectedOutOfPolicy: FlightListOffer | null;
  singleFlightFilter: SingleFlightFilter;
  stops: StopFilter[];
  travelerInfo: any;
  travelInfo: any;
  validUntil: Date | null;
  viewedOffers: Record<string, string>;
  visibleFlights: FlightListOffer[];
};

export interface IFlightFilters {
  availableCarriers: CarrierFilter[];
  baggage: BaggageFilter[];
  durationFilter: DurationFilter;
  flightClasses: ClassFilter[];
  inboundFilter: InboundFilter;
  onlyInPolicy: boolean;
  outboundFilter: OutboundFilter;
  selectedCabinClass: string;
  selectedClassOnly: boolean;
  singleFlightFilter: SingleFlightFilter;
  stops: StopFilter[];
}

export interface InboundFilter {
  arrivalRange: RangeFilter;
  departureRange: RangeFilter;
}

export interface IPlaneLimitInfo {
  clientLimit: number;
  isLimitExceeded: boolean;
  planeLimitExceededInfo: {
    inboundFlightCode: string;
    inboundLimitExceeded: boolean;
    outboundFlightCode: string;
    outboundLimitExceeded: boolean;
  };
}

export interface OutboundFilter {
  arrivalRange: RangeFilter;
  departureRange: RangeFilter;
}

export type RangeFilter = [number, number] | number[];

export interface SingleFlightFilter {
  inboundChecked: boolean;
  inboundFlightNumber: null | string;
  outboundChecked: boolean;
  outboundFlightNumber: null | string;
}

export interface StopFilter {
  checked: boolean;
  label: string;
  value: string;
}

export interface VisibleFlightsFilterInterface {
  getVisibleFlights(flights: FlightListOffer[]): FlightListOffer[];
}
