import { Box } from "@toolkit";
import { css } from "@styled-system/css";
import styled from "@shared/styled";

const Paper = styled(Box)(
  css({
    p: 24,
    backgroundColor: "white",
    boxShadow: "0 0 35px 0 rgba(154,161,171,.15)",
    display: "flex",
    flexDirection: "column"
  })
);

export { Paper };
