import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  departureOptions: {
    checked: boolean;
    id: string | number;
    label: string;
  }[];
  disabled: boolean;
  handleDepartureFilterChange: (
    e: React.ChangeEvent<any>,
    checked: boolean,
  ) => void;
};

export const DepartureTerminalFilter: React.FC<Props> = ({
  departureOptions,
  disabled,
  handleDepartureFilterChange,
}) => {
  if (departureOptions.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Terminal de Saída</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {departureOptions.map((departureOption) => (
          <CheckboxGroup.Item
            checked={departureOption.checked}
            disabled={disabled}
            id={departureOption.id.toString()}
            key={departureOption.id}
            name={departureOption.label}
            onChange={handleDepartureFilterChange}
            value={departureOption.checked}
          >
            <span css={styles.checkbox.group.item.label}>
              {departureOption.label}
            </span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
