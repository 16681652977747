import React, {
  useState,
  ChangeEvent,
  ComponentType,
  HTMLProps,
  FocusEvent
} from "react";
import Autocomplete from "react-autocomplete";
import useDebounce from "react-use/lib/useDebounce";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import uuid from "uuid";

import { defaultTheme } from "../../../assets/styles/theme";
import { Input } from "../inputs";
import { Row } from "../layout/Row";
import { ListItem } from "./ListItem";
import { NoResults } from "./NoResults";

interface ResultError {
  title: string;
  description: string;
}

interface Result {
  error?: ResultError;
  data?: any[];
}

interface Props {
  clearOnSelect?: boolean;
  inputProps?: any;
  menuStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  handleRemoveSelection?: (item: any) => void;
  handleSelect: (item: any) => void;
  customItemElement?: ComponentType<any>;
  customNoResultsElement?: ComponentType<any>;
  fetchItems: (search: string) => Promise<Result>;
  initialValue?: string;
}

interface State {
  inputValue: string;
  items: any[];
  loading: boolean;
  selectedItem: any;
}

const CustomAutocomplete = ({
  clearOnSelect = false,
  inputProps = {},
  menuStyle = {},
  wrapperStyle = {},
  initialValue,
  ...props
}: Props) => {
  const [state, setState] = useState<State>({
    inputValue: initialValue ? initialValue : "",
    items: [],
    loading: false,
    selectedItem: null
  });

  const { inputValue, items, loading } = state;
  const setSafeState = (stateValue: Partial<State>) => {
    setState(prevState => ({
      ...prevState,
      ...stateValue
    }));
  };

  const innerMenuStyle: React.CSSProperties = {
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    padding: "2px 0",
    fontSize: "90%",
    position: "absolute",
    overflow: "auto",
    left: "auto",
    top: "auto",
    maxHeight: 300,
    zIndex: 2,
    ...menuStyle
  };

  const innerWrapperStyle: React.CSSProperties = {
    display: "block",
    overflow: "visible",
    position: "relative",
    ...wrapperStyle
  };

  function renderInput({ ref, ...ownProps }: HTMLProps<HTMLInputElement>) {
    return (
      <Input
        {...ownProps}
        ref={ref}
        InputProps={{
          endAdornment: renderAdornments()
        }}
      />
    );
  }

  function onRemoveClick() {
    const { handleRemoveSelection } = props;
    const { selectedItem } = state;
    if (handleRemoveSelection) {
      handleRemoveSelection(selectedItem);
      clearInputAndItems();
    } else {
      clearInputAndItems();
    }
  }

  function renderAdornments() {
    return (
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        {loading && (
          <CircularProgress
            size={16}
            style={{ color: defaultTheme.subTextColor }}
          />
        )}
        {inputValue && (
          <CloseIcon
            onClick={onRemoveClick}
            style={{
              cursor: "pointer",
              color: defaultTheme.subTextColor,
              marginLeft: ".5rem"
            }}
          />
        )}
      </Row>
    );
  }

  function renderItem(item: any, isHighlighted: boolean) {
    if (!item.error) {
      const MenuItemComponent = props.customItemElement
        ? props.customItemElement
        : ListItem;

      return (
        <MenuItemComponent
          item={item}
          isHighlighted={isHighlighted}
          key={uuid()}
        />
      );
    } else if (item.error) {
      const NoResultsComponent = props.customNoResultsElement
        ? props.customNoResultsElement
        : NoResults;

      return <NoResultsComponent search={inputValue} key={item.error} />;
    }
  }

  async function fetchResultItems(search: string) {
    if (!search) {
      setSafeState({ items: [] });
    } else {
      setSafeState({ loading: true });

      const result: Result = await props.fetchItems(search);

      if (!result.error) {
        const noResultFounds = result.data!.length === 0;

        setSafeState({
          items: noResultFounds
            ? [{ error: "NO_RESULTS", label: "" }]
            : result.data,
          loading: false
        });
      } else {
        setSafeState({
          items: [{ error: result.error.description, label: "" }],
          loading: false
        });
      }
    }
  }

  function getItemValue(item: any) {
    return item.label;
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    if (value) {
      setSafeState({ inputValue: e.target.value });
    } else {
      clearInputAndItems();
    }
  }

  function handleSelect(val: string, item: any) {
    props.handleSelect(item);

    if (clearOnSelect) {
      clearInputAndItems();
    } else {
      setSafeState({
        inputValue: val,
        selectedItem: item
      });
    }
  }

  function clearInputAndItems() {
    setSafeState({
      inputValue: "",
      items: [],
      selectedItem: null
    });
  }

  useDebounce(
    () => {
      fetchResultItems(inputValue);
    },
    300,
    [inputValue]
  );

  return (
    <Autocomplete
      menuStyle={innerMenuStyle}
      getItemValue={getItemValue}
      wrapperStyle={innerWrapperStyle}
      renderInput={renderInput}
      inputProps={inputProps}
      items={items}
      renderItem={renderItem}
      value={inputValue}
      onChange={handleChange}
      onSelect={handleSelect}
    />
  );
};

export { CustomAutocomplete as Autocomplete };
