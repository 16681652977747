import { Coords } from "google-map-react";
import React, { useCallback } from "react";

import { RentalOption, CarSupplier } from "~/apps/corporate/models/car.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { MarkerSvg } from "~/apps/shared/components/location-marker/location-marker";
import { NoImageHotel } from "~/apps/shared/components/no-image-hotel/no-image-hotel";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

const supplierMarkerColorMap: {
  [key in CarSupplier]: {
    circle: string;
    exterior: string;
  };
} = {
  LOCALIZA: {
    circle: "#ffffff",
    exterior: "#01602a",
  },
  MOVIDA: {
    circle: "#404040",
    exterior: "#ff5000",
  },
};

type Props = Coords & {
  filters: {
    addressFilter: {
      address: string;
      lat: number;
      lng: number;
    } | null;
  };
  handleApplyAddressFilter: () => void;
  handleClearAddressFilter: () => void;
  hasManyAgencies: boolean;
  isVisible: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  rental: RentalOption;
};

export const RentalMarker: React.FC<Props> = ({
  filters,
  handleApplyAddressFilter,
  handleClearAddressFilter,
  hasManyAgencies,
  isVisible,
  onClick,
  onClose,
  rental,
}) => {
  const handleCloseCard = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();

      e.preventDefault();
      e.stopPropagation();
    },
    [onClose],
  );

  if (!rental.lat || !rental.lng) {
    return null;
  }

  return (
    <>
      <button css={styles.marker} onClick={onClick}>
        <MarkerSvg
          circle={supplierMarkerColorMap[rental.supplier].circle}
          exterior={supplierMarkerColorMap[rental.supplier].exterior}
        />
      </button>
      {isVisible ? (
        <div css={styles.card.root}>
          <Button
            css={styles.card.close}
            onClick={handleCloseCard}
            shape="icon"
          >
            <Icon size={16} use="x" />
          </Button>
          <div css={styles.card.top.root}>
            {rental.logo ? (
              <img
                alt={rental.name}
                css={styles.card.top.logo}
                src={rental.logo}
              />
            ) : (
              <NoImageHotel css={styles.card.top.logo} />
            )}
          </div>
          <div css={styles.card.center.root}>
            <span css={styles.card.center.address}>
              Endereço de retirada: {rental.address}
              {hasManyAgencies ? "- E outras locadoras nesse endereço." : null}
            </span>
          </div>
          {!filters.addressFilter ? (
            <Button
              css={styles.card.bottom.button}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleApplyAddressFilter();
              }}
            >
              Resultados do endereço
            </Button>
          ) : (
            <Button
              css={styles.card.bottom.button}
              fill="outlined"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleClearAddressFilter();
              }}
            >
              Limpar filtro
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};
