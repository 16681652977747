import React from "react";

import { UseFormMethods } from "./useForm";
import { FormField, FormLabel } from "./FormField";

const FormContext = React.createContext<UseFormMethods | undefined>(undefined);

type FormProps = {
  children: any;
  context: UseFormMethods;
  className?: string;
};

export function Form(props: FormProps) {
  const { context, children, ...rest } = props;

  return (
    <form {...rest} onSubmit={context.submitForm}>
      <FormContext.Provider value={context}>{children}</FormContext.Provider>
    </form>
  );
}

Form.Field = FormField;
Form.Label = FormLabel;

export function useFormContext() {
  const context = React.useContext(FormContext);

  if (!context) {
    throw new Error(
      "useFormContext só pode ser usado dentro de um FormProvider"
    );
  }

  return context;
}
