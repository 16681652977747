import { useEffect, useState } from "react";

import * as utils from "@helpers";

import { useLocation } from "./useLocation";

export function useParams() {
  const { location } = useLocation();

  const [params, setParams] = useState<any>(
    utils.getParamsFromLocation(location)
  );

  useEffect(() => {
    const updatedParams = utils.getParamsFromLocation(location);

    setParams(updatedParams);
  }, [location.search]);

  return params;
}
