import React, { useCallback } from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { CAR_POLICY_CATEGORY_TRANSLATION } from "~/apps/shared/constants";
import { CarCategory } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

const CATEGORIES_INFOS = [
  {
    category: CarCategory.BASIC,
    classes: [
      { examples: "Fiat Uno, Fiat Mobi, Reault Kwid", name: "Economicos" },
      {
        examples: "VW Gol 1.0, Hyundai HB20, Chevrolet Onix",
        name: "Compactos",
      },
      {
        examples: "Hyundai HB20S, Ford KA, VW Voyage",
        name: "Intermediários/Médios",
      },
    ],
  },
  {
    category: CarCategory.INTERMEDIATE,
    classes: [
      { examples: "Chevrolet Prisma, VW Gol 1.6", name: "Standard" },
      { examples: "Fiat Strada, Fiat Doblo, Chevrolet Spin", name: "Vans" },
      { examples: "Nissan Kicks, Jeep Renegade", name: "Intermediários elite" },
    ],
  },
  {
    category: CarCategory.ADVANCED,
    classes: [
      { examples: "Chevrolet Cruz, Fiat Cronos", name: "Grande/Pickup" },
      { examples: "Nissan Leaf", name: "Compactos elite" },
      { examples: "Peugeot Expert, Citroen Jumpy", name: "Vans comerciais" },
      { examples: "Fiat Toro, Peugeot 3008", name: "Premium" },
    ],
  },
  {
    category: CarCategory.SPECIAL,
    classes: [
      { examples: "Mercedes C180", name: "Luxuosos" },
      { examples: "Jeep Compass", name: "Especiais" },
    ],
  },
];

const CATEGORY_WEIGHT = {
  [CarCategory.BASIC]: 0,
  [CarCategory.INTERMEDIATE]: 1,
  [CarCategory.ADVANCED]: 2,
  [CarCategory.SPECIAL]: 3,
};

type Props = React.ComponentProps<typeof Dialog> &
  (
    | {
        maxCategory: CarCategory;
        showAll?: never;
      }
    | {
        maxCategory?: never;
        showAll: true;
      }
  );

export const CarCategoryExplanationDialog: React.FC<Props> = ({
  onClose,
  ...props
}) => {
  const categoryWeight = props.showAll
    ? CATEGORY_WEIGHT.SPECIAL
    : CATEGORY_WEIGHT[props.maxCategory];

  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown" | "none") => {
      if (!onClose) {
        return;
      }

      if (reason === "none") {
        onClose(event, "backdropClick");

        return;
      }

      onClose(event, reason);
    },
    [onClose],
  );

  const renderCategories = () => {
    return CATEGORIES_INFOS.map((categoryInfo) => {
      if (categoryWeight < CATEGORY_WEIGHT[categoryInfo.category]) {
        return null;
      }

      return (
        <CarCategoryExplanationDialogCategoryItem
          categoryClasses={categoryInfo.classes}
          categoryName={CAR_POLICY_CATEGORY_TRANSLATION[categoryInfo.category]}
          key={categoryInfo.category}
        />
      );
    });
  };

  return (
    <Dialog css={styles.root} onClose={handleClose} {...props}>
      <DialogHeader
        css={styles.header.root}
        icon="car"
        onClose={() => {
          handleClose({}, "none");
        }}
      />
      <DialogContent css={styles.content.root}>
        <div css={styles.content.top.root}>
          <DialogTitle>Categoria máxima de veículos.</DialogTitle>
          <span css={styles.content.top.message}>Entenda as categorias:</span>
        </div>
        <div css={styles.content.categories.root}>
          <p css={styles.content.categories.paragraph}>
            Com a política de categoria máxima, as ofertas de carro serão
            divididas em quatro categorias, definidas a seguir em ordem
            crescente de custo.
            <br />
            <br />
            Caso o veículo escolhido esteja em uma categoria igual ou abaixo da
            máxima escolhida, ele estará dentro da política.
            <br />
            <br />
            Abaixo estão{" "}
            {props.showAll
              ? "as categorias Smartrips"
              : "suas categorias disponíveis"}{" "}
            , suas classes e alguns exemplos de veiculos:
          </p>
          {renderCategories()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

type CarCategoryExplanationDialogCategoryItemProps = {
  categoryClasses: {
    examples: string;
    name: string;
  }[];
  categoryName: string;
};

const CarCategoryExplanationDialogCategoryItem: React.FC<CarCategoryExplanationDialogCategoryItemProps> = ({
  categoryClasses,
  categoryName,
}) => {
  return (
    <div css={styles.content.categories.category.root}>
      <span css={styles.content.categories.category.heading}>
        {categoryName}
      </span>
      <ul css={styles.content.categories.category.list.root}>
        {categoryClasses.map((categoryClass) => (
          <li
            css={styles.content.categories.category.list.item.root}
            key={categoryClass.name}
          >
            <span css={styles.content.categories.category.list.item.name}>
              {categoryClass.name}
            </span>
            <span>Ex: {categoryClass.examples}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
