import {
  GetUserProjectsResponse,
  ProjectItemResponse,
} from "../dtos/projects.dto";
import { UserProject } from "../models/project.model";
import { UserFactory as parseUser } from "./user.parser";

export const parseProjectItem = (dto: ProjectItemResponse) => {
  return {
    active: dto.active,
    clientToken: dto.client_token,
    createdAt: dto.created_at,
    name: dto.name,
    projectToken: dto.project_token,
    totalUsers: dto.total_users,
    updatedAt: dto.updated_at,
    users: dto.users.map((user) => parseUser(user)),
  };
};

export const parseUserProjects = (dto: GetUserProjectsResponse) => {
  return dto.map((userProjectDto) => {
    const userProject: UserProject = {
      active: userProjectDto.active,
      billingProfileToken: userProjectDto.billing_profile_token,
      clientToken: userProjectDto.client_token,
      name: userProjectDto.name,
      projectToken: userProjectDto.project_token,
    };

    return userProject;
  });
};
