import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";

import PageTitle from "~/components/shared/PageTitle";

import { ExcludeSSOConfigDialog } from "./exclude-sso-config-dialog";
import { IntegrationCard } from "./integration-card";
import {
  IntegrationsProvider,
  useIntegrationsContext,
  useIntegrationsActionsContext,
} from "./Integrations.context";
import { SSOConfigDialog } from "./sso-config-dialog";

const Integrations: React.FC = () => {
  const {
    ssoConfig,
    isSSOConfigDialogOpen,
    isDeleteSSOConfigDialogOpen,
    isGettingSSOConfig,
    isCreatingSSOConfig,
    isEditingSSOConfig,
    isDeletingSSOConfig,
  } = useIntegrationsContext();
  const {
    toggleSSOConfigDialogVisibility,
    toggleDeleteSSOConfigDialogVisibility,
    getSSOConfig,
    createSSOConfig,
    editSSOConfig,
    deleteSSOConfig,
  } = useIntegrationsActionsContext();

  useEffect(() => {
    getSSOConfig();
  }, []);

  return (
    <div>
      <PageTitle title="Integrações" />
      <h2 style={{ marginBottom: "1rem" }}>Integrações</h2>
      <ExcludeSSOConfigDialog
        isOpen={isDeleteSSOConfigDialogOpen}
        handleClose={toggleDeleteSSOConfigDialogVisibility}
        isLoading={isDeletingSSOConfig}
        handleDelete={deleteSSOConfig}
      />
      {isGettingSSOConfig ? null : (
        <SSOConfigDialog
          ssoConfig={ssoConfig}
          isOpen={isSSOConfigDialogOpen}
          handleClose={toggleSSOConfigDialogVisibility}
          isLoading={isCreatingSSOConfig || isEditingSSOConfig}
          onSubmit={ssoConfig ? editSSOConfig : createSSOConfig}
        />
      )}
      <IntegrationCard
        title="Single sign-on (SSO)"
        description="Segurança"
        text="O login único (SSO) adiciona uma camada extra de segurança à sua conta,
        permitindo que os usuários acessem a Smartrips com suas credenciais
        existentes."
        handleDeleteConfig={toggleDeleteSSOConfigDialogVisibility}
        isLoading={isGettingSSOConfig}
        isIntegrated={!!ssoConfig}
      />
    </div>
  );
};

type Props = RouteComponentProps;

const Container: React.FC<Props> = () => (
  <IntegrationsProvider>
    <Integrations />
  </IntegrationsProvider>
);

export { Container as Integrations };
