import { css } from "emotion";

import { defaultTheme } from "~/assets/styles/theme";

export const styles = {
  tab: css({
    fontSize: 14,
    padding: "6px 0",
    fontFamily: "'Open sans', sans-serif",
    textTransform: "none",
    letterSpacing: "0.04rem",
    minWidth: 100,
    minHeight: 38
  }),
  selected: css({
    fontWeight: "bold"
  }),
  indicator: css({
    backgroundColor: defaultTheme.primaryColor,
    height: 2,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  }),
  scroller: css({
    border: `.5px solid ${defaultTheme.cardBorderColor} !important`,
    borderRadius: 4,
    overflow: "visible",
    maxHeight: 38
  })
};
