import * as itineraryHelper from "~/apps/corporate/helpers/itinerary.helper";
import {
  ItineraryServicePresenter,
  ItineraryServicesGroupedByOfferStatus,
} from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesPresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";
import { OffersAvailabilitiesModel } from "~/apps/corporate/models/offer.model";
import { TravelPaymentModel } from "~/apps/corporate/models/travel.model";

class PaymentServicesCardPresenter {
  constructor(
    private readonly offersAvailabilitiesModel: OffersAvailabilitiesModel | null,
    private readonly travelPaymentModel: TravelPaymentModel,
  ) {}

  private getFrustratedPurchasesTabServices(
    services: ItineraryServicePresenter[],
  ) {
    return services.filter((s) => s.belongsToFrustratedPurchasesTab());
  }

  public getPresentationalServices() {
    const servicesModel = this.travelPaymentModel.getServicesModel();

    const servicesPresenter = ItineraryServicesPresenterFactory.create(
      servicesModel,
    );

    const services = servicesPresenter.getAllServices();

    const frustratedPurchasesServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.getFrustratedPurchasesTabServices(services),
    );

    const travelPlanServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      servicesPresenter.splitServicesByDateAndSetSuggestions(
        this.getTravelPlanTabServices(services),
      ),
    );

    const paymentServices = this.mergeFrustratedPurchasesAndTravelPlanServices(
      frustratedPurchasesServices,
      travelPlanServices,
    );

    const paymentDraftServices = paymentServices["DRAFT"];

    return paymentDraftServices;
  }

  private getTravelPlanTabServices(services: ItineraryServicePresenter[]) {
    return services.filter((s) => s.belongsToTravelPlanTab());
  }

  public getUnavailableServiceTokens() {
    if (!this.offersAvailabilitiesModel) {
      return [];
    }

    return this.offersAvailabilitiesModel.getUnavailableOfferTokens();
  }

  public hasPresentationalServices() {
    return this.getPresentationalServices().length > 0;
  }

  private mergeFrustratedPurchasesAndTravelPlanServices(
    frustratedPurchasesServices: ItineraryServicesGroupedByOfferStatus,
    travelPlanServices: ItineraryServicesGroupedByOfferStatus,
  ) {
    return Object.entries(travelPlanServices).reduce((prev, [k, v]) => {
      const frustratedPurchasesForKey =
        k in frustratedPurchasesServices
          ? frustratedPurchasesServices[
              k as keyof typeof frustratedPurchasesServices
            ]
          : [];

      prev[k as keyof typeof prev] = [...v, ...frustratedPurchasesForKey];

      return prev;
    }, {} as typeof travelPlanServices);
  }
}

export class PaymentServicesCardPresenterFactory {
  static create(
    offersAvailabilitiesModel: OffersAvailabilitiesModel | null,
    travelPaymentModel: TravelPaymentModel,
  ) {
    return new PaymentServicesCardPresenter(
      offersAvailabilitiesModel,
      travelPaymentModel,
    );
  }
}
