import forge from "node-forge";

export const encryptPassword = (
  password: string,
  encryptionToken: string,
  publicKey: string,
) => {
  const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
  const buffer = forge.util.createBuffer(password, "utf8");
  const bytes = buffer.getBytes();

  const encrypted = forgedPublicKey.encrypt(bytes, "RSA-OAEP");
  const b64Encoded = forge.util.encode64(encrypted);

  const passwordData = encryptionToken + "_" + b64Encoded;

  return passwordData;
};
