import React, { useContext } from "react";
import { FinancialContext } from "../Financial.context";
import { StringTMap } from "../../../types";
import { SmartripsInvoiceItem } from "../../../models/financial.model";
import { SMARTRIPS_INVOICE_TYPES } from "../../../constants";
import ArrowIcon from "@material-ui/icons/ArrowDownwardRounded";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";
import isEmpty from "lodash/isEmpty";

const styles = {
  root: css({
    width: "fit-content"
  }),
  downloadRow: css({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "1rem 0",
    textTransform: "none",
    textDecoration: "none",
    width: "fit-content"
  }),
  text: css({
    fontSize: "inherit",
    color: defaultTheme.primaryColor,
    marginLeft: ".5rem",
    ["&:hover"]: {
      color: "#2472f2"
    }
  }),
  arrowIcon: css({
    fontSize: 18,
    color: defaultTheme.textColor
  }),
  noInvoices: css({
    paddingLeft: ".75rem"
  })
};

interface DownloadLinkProps {
  label: string;
  downloadUrl: string;
}

const DownloadLink = ({ label, downloadUrl }: DownloadLinkProps) => (
  <div>
    <a className={styles.downloadRow} href={downloadUrl} download={true}>
      <ArrowIcon className={styles.arrowIcon} />
      <div className={styles.text}>
        <span>{label}</span>
      </div>
    </a>
  </div>
);

const SmartripsServicesList = () => {
  const { smartripsInvoices, loading } = useContext(FinancialContext);

  const invoicesMap: StringTMap<
    SmartripsInvoiceItem
  > = smartripsInvoices.reduce((acc: any, current) => {
    acc[current.type] = current;
    return acc;
  }, {});

  return (
    <div className={styles.root}>
      {!loading && isEmpty(smartripsInvoices) ? (
        <div className={styles.noInvoices}>
          <span>Nenhuma nota encontrada no período selecionado</span>
        </div>
      ) : null}
      {!!invoicesMap[SMARTRIPS_INVOICE_TYPES.FIXED_MONTHLY_FEE] ? (
        <DownloadLink
          label="Baixar Nota Mensalidade Plataforma"
          downloadUrl={invoicesMap[SMARTRIPS_INVOICE_TYPES.FIXED_MONTHLY_FEE].receiptFile}
        />
      ) : null}
      {!!invoicesMap[SMARTRIPS_INVOICE_TYPES.TRAVELS] ? (
        <DownloadLink
          label="Baixar Nota Modulo Viagens"
          downloadUrl={invoicesMap[SMARTRIPS_INVOICE_TYPES.TRAVELS].receiptFile}
        />
      ) : null}
      {!!invoicesMap[SMARTRIPS_INVOICE_TYPES.EXPENSES] ? (
        <DownloadLink
          label="Baixar Nota Modulo Relatórios de Despesas Smartrips"
          downloadUrl={
            invoicesMap[SMARTRIPS_INVOICE_TYPES.EXPENSES].receiptFile
          }
        />
      ) : null}
      {!!invoicesMap[SMARTRIPS_INVOICE_TYPES.REWARDS] ? (
        <DownloadLink
          label="Baixar Nota Modulo Redução de Gastos SmartPoints"
          downloadUrl={invoicesMap[SMARTRIPS_INVOICE_TYPES.REWARDS].receiptFile}
        />
      ) : null}
    </div>
  );
};

export { SmartripsServicesList };
