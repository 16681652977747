import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { darken } from "@material-ui/core";
import { Link } from "@reach/router";
import { css } from "@styled-system/css";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";
import { ScrollToTopOnMount } from "~/utils";

import { theme } from "@skin/v2";

import { Box } from "@toolkit";

import { APPROVAL_TYPES } from "~/constants";

import { ApprovalProcessListItem } from "@models/approval-process.model";

import { createUserApprovalFlows } from "~/apis/excel.api";

import BatchFileUploader from "~/components/shared/batch-file-uploader";

import AsyncData from "@components/shared/async-data";
import { ContainedButton } from "@components/shared/buttons/contained-button";
import CapabilitiesVisibility from "@components/shared/CapabilitiesVisibility";
import DeletionDialog from "@components/shared/dialogs/DeletionDialog";
import FilterableList from "@components/shared/filterable-list";
import { Row } from "@components/shared/layout/Row";
import Spinner from "@components/shared/Spinner";

import ApprovalCard from "./approval-card";
import { ApprovalExplanationDialog } from "./approval-explanation";
import * as ApprovalService from "./approval-processes.service";

const styles = {
  filtersRoot: css({
    display: "flex",
    gap: "1rem",
    marginBottom: "2rem",
  }),
  listContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "1.5rem",
  }),
  newProcessButton: css({
    backgroundColor: theme.colors.pink[500],
    height: "3rem",
    "&:hover": {
      backgroundColor: darken(theme.colors.pink[500], 0.2),
      transition: "200ms",
    },
  }),
  tooltipButton: css({
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    gap: "0.5rem",
    height: "1.5rem",
  }),
};

function sortData(data: ApprovalProcessListItem[]): ApprovalProcessListItem[] {
  return data.sort((approvalProcess) =>
    approvalProcess.type === APPROVAL_TYPES.COMPANY ? -1 : 1,
  );
}

function filterApprovalProcesses(
  _searchValue: string,
  data: ApprovalProcessListItem[],
) {
  const searchValue = _searchValue.toLowerCase();

  return data.filter(
    (dt) =>
      dt.name?.toLowerCase().includes(searchValue) ||
      (dt.targets?.length &&
        dt.targets.some((target) =>
          target.name?.toLowerCase().includes(searchValue),
        )),
  );
}

type ApprovalProcessesProps = any;

export const ApprovalProcesses: React.FC<ApprovalProcessesProps> = (props) => {
  const result = useQuery(
    "approval-processes",
    ApprovalService.loadApprovalRules,
  );

  return (
    <section>
      <AsyncData {...result}>
        <AsyncData.Loading>
          <Spinner visible />
        </AsyncData.Loading>
        <AsyncData.Success>
          {(data) => (
            <FilterableList<ApprovalProcessListItem>
              data={data}
              filterData={filterApprovalProcesses}
            >
              {(props) => (
                <>
                  <ApprovalProcessesHeader onFilter={props.onFilter} />
                  <ApprovalProcessesSuccess
                    data={sortData(props.filteredList)}
                  />
                </>
              )}
            </FilterableList>
          )}
        </AsyncData.Success>
      </AsyncData>

      {props.children}
    </section>
  );
};

type ApprovalProcessesHeaderProps = {
  onFilter: (value: string) => void;
};

const ApprovalProcessesHeader: React.FC<ApprovalProcessesHeaderProps> = ({
  onFilter,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onFilter(value);
  };

  return (
    <>
      <Row css={styles.filtersRoot}>
        <ScrollToTopOnMount />
        <SearchInput
          css={{
            flex: 1,
          }}
          onChange={handleChangeSearch}
          placeholder="Pesquisar fluxo"
        />
        <CapabilitiesVisibility capabilities={["approvals"]}>
          <Link
            style={{ textDecoration: "none" }}
            to="/configurations/trips/approval-processes/ranking"
          >
            <ContainedButton style={{ height: "100%" }}>
              Hierarquia de fluxos
            </ContainedButton>
          </Link>
        </CapabilitiesVisibility>
        <Box>
          <BatchFileUploader
            onDownloadModelClick={createUserApprovalFlows.onDownloadModelClick}
            onUpload={createUserApprovalFlows.onUpload}
            title="Cadastrar via excel"
          />
        </Box>
      </Row>
      <Row style={{ alignItems: "center", gap: "1rem" }}>
        <Link
          style={{ textDecoration: "none" }}
          to="/configurations/trips/approval-processes/create"
        >
          <ContainedButton css={styles.newProcessButton}>
            Novo processo
          </ContainedButton>
        </Link>
        <div css={styles.tooltipButton} onClick={() => setDialogOpen(true)}>
          <span style={{ lineHeight: "1.75rem" }}>Como funciona?</span>
          <Icon use="info" />
        </div>
        <ApprovalExplanationDialog
          isOpened={isDialogOpen}
          onCloseClick={() => setDialogOpen(false)}
        />
      </Row>
    </>
  );
};

type ApprovalProcessesSuccessProps = {
  data: ApprovalProcessListItem[];
};

const ApprovalProcessesSuccess: React.FC<ApprovalProcessesSuccessProps> = ({
  data,
}) => {
  const { showSnackMessage } = useApplication();

  const queryClient = useQueryClient();

  const [state, setState] = useState({
    isDeleteVisible: false,
    token: "",
  });

  function handleDeleteClick(token: string) {
    return () => {
      setState({
        isDeleteVisible: true,
        token,
      });
    };
  }

  async function handleDeletion() {
    const deleteRuleResponse = await ApprovalService.deleteRule(state.token);

    if (deleteRuleResponse.error) {
      const error = deleteRuleResponse.error;

      showSnackMessage(`${error.title}: ${error.description}`, "error");

      return;
    }

    void queryClient.refetchQueries("approval-processes");

    setState({
      isDeleteVisible: false,
      token: "",
    });

    showSnackMessage("Fluxo excluído com sucesso", "success");
  }

  return (
    <section css={styles.listContainer}>
      {data.map((rule) => (
        <ApprovalCard
          key={rule.token}
          name={rule.name}
          onDeleteClick={handleDeleteClick(rule.token)}
          targets={rule.targets}
          token={rule.token}
          type={rule.type}
        />
      ))}
      <DeletionDialog
        handleClose={() => setState({ isDeleteVisible: false, token: "" })}
        handleDeletion={handleDeletion}
        message="Deseja realmente excluir o fluxo de aprovação selecionado?"
        open={state.isDeleteVisible}
        title="Exclusão de fluxo de aprovação"
      />
    </section>
  );
};
