import React from "react";

import { AvatarGroup as MuiAvatarGroup } from "@material-ui/lab";

import { styles } from "./styles";

type Props = React.ComponentProps<typeof MuiAvatarGroup>;

export const AvatarGroup: React.FC<Props> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <MuiAvatarGroup {...props} />
    </div>
  );
};
