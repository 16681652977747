import React from "react";

import moment from "moment";

import { defaultTheme } from "@assets/styles/theme";

import { ApprovalListStatus } from "~/constants/enums";

import { APPROVAL_STATUS } from "@constants";

import * as dateHelper from "~/helpers/date.helpers";

import { TravelApproval } from "~/models/travel.model";

import { TravelCardPresentational } from "./TravelCard.presentational";

interface Props {
  travel: TravelApproval;
  status: ApprovalListStatus;
}

const TravelCardContainer = ({ travel, status }: Props) => {
  const IS_PENDING_TAB = status === ApprovalListStatus.PENDING;
  const IS_APPROVED_TAB = status === ApprovalListStatus.APPROVED;

  const hasBeenPurchased = travel.travelProps.allOffersEmitted;

  const formatedDate =
    moment(travel.rawStartTravel).format("DD MMM YYYY") +
    " - " +
    moment(travel.rawEndTravel).format("DD MMM YYYY");

  const requestPendingDateText = `Solicitada em ${dateHelper.formatDateAndHours(
    travel.approvalCreatedAt as string
  )} por ${travel.booker}`;

  const getTagInfo = () => {
    if (IS_PENDING_TAB) {
      return {
        text: "Aprovação pendente",
        color: defaultTheme.colorByStatus.pending
      };
    } else if (
      IS_APPROVED_TAB &&
      travel.isProcessable &&
      travel.approvalStatus === APPROVAL_STATUS.PENDING_APPROVAL_2
    ) {
      return {
        text: "Aprovação final pendente",
        color: defaultTheme.colorByStatus.pedendingFinalApproval
      };
    } else if (
      IS_APPROVED_TAB &&
      hasBeenPurchased &&
      travel.approvalStatus === APPROVAL_STATUS.APPROVED
    ) {
      return {
        text: "Viagem comprada",
        color: defaultTheme.colorByStatus.approved
      };
    } else if (IS_APPROVED_TAB && travel.travelProps.hasEmittingOffers) {
      return {
        text: "Processando pagamento",
        color: defaultTheme.colorByStatus.processing
      };
    } else {
      return {
        text: "",
        color: "#FFF"
      };
    }
  };

  const actionInfo = IS_PENDING_TAB
    ? {
        text: "Ir para aprovação",
        link: `/travels/${travel.token}/approval-review`
      }
    : {
        text: "Ir para a viagem",
        link: `/travels/${travel.token}/itinerary`
      };

  return (
    <TravelCardPresentational
      travelName={travel.name}
      travelToken={travel.token}
      travelerName={travel.traveler}
      tagInfo={getTagInfo()}
      actionInfo={actionInfo}
      formattedDate={formatedDate}
      approvalDateText={requestPendingDateText}
    />
  );
};

export { TravelCardContainer };
