export const pageTitleConstants = {
  BASE_TITLE: "smartrips",
  NEW_TRIP: "Nova viagem",
  TRIPS: "Viagens",
  DRAFT_TRIPS: "Viagens rascunho",
  SCHEDULED_TRIPS: "Viagens agendadas",
  ONGOING_TRIPS: "Viagens em andamento",
  CONCLUDED_TRIPS: "Viagens concluídas",
  TRAVELED_TRIPS: "Viagens finalizadas",
  PENDING_APPROVAL_TRIPS: "Viagens pendentes de aprovação",
  CONFIGURATION: "Configurações",
  USERS: "Usuários",
  NEW_USER: "Novo usuário",
  NEW_GUEST: "Novo convidado",
  EDIT_USER: "Editar usuário",
  EDIT_GUEST: "Editar convidado",
  GROUPS: "Grupos",
  COST_CENTERS: "Centros de custos",
  PAYMENT_METHODS: "Meios de pagamento",
  FORGOT_PASSWORD: "Esqueci minha senha",
  RESET_PASSWORD: "Nova senha",
  UPDATE_PASSWORD: "Alterar senha",
  ITINERARY: "Itinerário",
  APPROVAL_REVIEW: "Aprovação",
  SEARCH_FLIGHTS: "Pesquisando voos",
  SEARCH_HOTELS: "Pesquisando hotéis",
  EXPENSES: "Despesas",
  EXPENSE_REPORTS: "Relatórios",
  EXPENSE_REPORT: "Relatório",
  TRAVEL_APPROVALS: "Minhas aprovações de viagem",
  EXPENSE_APPROVAL_REVIEW: "Revisão de relatório",
  FINANCIAL: "Financeiro",
  EXPENSE_POLICY: "Políticas de Despesas",
  EXPENSE_CATEGORY: "Categorias de Despesas",
  ERROR: "Ops..."
};

export default pageTitleConstants;
