import React from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { styles } from "./styles";

export const TravelPlanApprovalHistoryDialog: React.FC = () => {
  const { travelApprovalHistoryModel } = useItineraryContainer();

  const {
    closeApprovalHistoryDialog,
    isApprovalHistoryDialogOpen,
  } = useItineraryScreen();

  if (!travelApprovalHistoryModel) {
    return null;
  }

  const records = travelApprovalHistoryModel.getRecords();

  return (
    <Dialog
      aria-labelledby="Compartilhar viagem"
      aria-describedby="Compartilhe o seu plano de viagem"
      css={styles.root}
      onClose={closeApprovalHistoryDialog}
      open={isApprovalHistoryDialogOpen}
    >
      <DialogHeader
        onClose={() => {
          closeApprovalHistoryDialog();
        }}
        icon="arrow-path"
      />
      <DialogContent>
        <DialogTitle>Histórico de aprovação.</DialogTitle>
        <span css={styles.message}>
          Últimos registros de aprovação encontrados:
        </span>
      </DialogContent>
      <div css={styles.records.root}>
        {records.length > 0 ? (
          records.map((record) => {
            const approver = record.getApprover();
            const formattedRequestDate = record.getFormattedCreatedAt();
            const requestMessage = record.getRequestMessage();

            return (
              <div
                css={styles.records.record.root}
                key={record.getApprovalRequestToken()}
              >
                <div css={styles.records.record.date}>
                  <Icon size={16} use="calendar" />
                  <span>{formattedRequestDate}</span>
                </div>
                {approver ? (
                  <span css={styles.records.record.approver.text}>
                    Aprovador:{" "}
                    <strong css={styles.records.record.approver.name}>
                      {approver.fullName}
                    </strong>
                  </span>
                ) : null}
                <span css={styles.records.record.status.text}>
                  Status:{" "}
                  <strong
                    css={styles.records.record.status.status({
                      color: record.getStatusColor(),
                    })}
                  >
                    {record.getFormattedStatusLabel()}.
                  </strong>
                </span>
                {requestMessage ? (
                  <span css={styles.records.record.request}>
                    Justificativa enviada em {formattedRequestDate}: &quot;
                    <strong>{requestMessage}</strong>
                    &quot;
                  </span>
                ) : null}
              </div>
            );
          })
        ) : (
          <span css={styles.records.empty}>
            Não há registros de aprovação encontrados.
          </span>
        )}
      </div>
    </Dialog>
  );
};
