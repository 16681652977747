import { localizaAdditionalsCodesMap } from "./localiza-additionals.constants";

type LocalizaAdditionalsKeys = keyof typeof localizaAdditionalsCodesMap;

export type AccessoryAdditionalsKeys = Extract<
  LocalizaAdditionalsKeys,
  "ASSENTO_DE_ELEVACAO" | "BEBE_CONFORTO" | "CADEIRA_DE_BEBE"
>;
export const accessoryAdditionalsKeys: AccessoryAdditionalsKeys[] = [
  "ASSENTO_DE_ELEVACAO",
  "BEBE_CONFORTO",
  "CADEIRA_DE_BEBE",
];

export type ProtectionAdditionalsKeys = Extract<
  LocalizaAdditionalsKeys,
  | "COBERTURA_PARA_TERCEIROS"
  | "PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS"
  | "PROTECAO_DO_CARRO"
  | "PROTECAO_TOTAL_AVARIAS"
>;
export const protectionAdditionalsKeys: ProtectionAdditionalsKeys[] = [
  "COBERTURA_PARA_TERCEIROS",
  "PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS",
  "PROTECAO_DO_CARRO",
  "PROTECAO_TOTAL_AVARIAS",
];

export type ServiceAdditionalsKeys = Extract<
  LocalizaAdditionalsKeys,
  | "COMPENSACAO_DE_CARBONO"
  | "CONDUTOR_ADICIONAL_ILIMITADO"
  | "LIMPEZA_GARANTIDA"
>;
export const serviceAdditionalsKeys: ServiceAdditionalsKeys[] = [
  "COMPENSACAO_DE_CARBONO",
  "CONDUTOR_ADICIONAL_ILIMITADO",
  "LIMPEZA_GARANTIDA",
];
