import React from "react";

import { Autocomplete } from "~/apps/shared/components/autocomplete/autocomplete";
import { Icon } from "~/apps/shared/components/icon/icon";
import { COUNTRY_LIST } from "~/apps/shared/constants/countries.constant";

import { styles } from "./styles";

const options = COUNTRY_LIST.map((option) => ({
  label: option.name,
  value: option.code,
}));

const renderInput: React.ComponentProps<typeof Autocomplete>["renderInput"] = ({
  value,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  const item = options.find((option) => option.label === value);

  return (
    <div css={styles.autocomplete.input.root({ isEmpty: !item })}>
      {item ? (
        <img
          alt={item.label}
          css={styles.autocomplete.item.flag}
          src={`/images/flags/${item.value.toLowerCase()}.svg`}
        />
      ) : null}
      <input css={styles.autocomplete.input.input} value={value} {...props} />
      <Icon css={styles.autocomplete.input.chevron} use="chevron-up" />
    </div>
  );
};

const renderItem: React.ComponentProps<typeof Autocomplete>["renderItem"] = (
  item,
  isHighlighted,
) => {
  if (item.error) {
    return (
      <li
        css={styles.autocomplete.item.root({
          isDisabled: true,
          isHighlighted: false,
        })}
      >
        Nenhum resultado.
      </li>
    );
  }

  return (
    <li css={styles.autocomplete.item.root({ isHighlighted })}>
      <img
        alt={item.label}
        css={styles.autocomplete.item.flag}
        src={`/images/flags/${item.value.toLowerCase()}.svg`}
      />
      <span>{item.label}</span>
    </li>
  );
};

export const CountrySelect: React.FC<
  Omit<React.ComponentPropsWithoutRef<typeof Autocomplete>, "options">
> = ({ ...props }) => {
  return (
    <Autocomplete
      css={styles.root}
      inputProps={{
        placeholder: "Selecione um país...",
      }}
      options={options}
      renderInput={renderInput}
      renderItem={renderItem}
      {...props}
    />
  );
};
