import React from "react";

import { styles } from "./styles";

export const Radio: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    variant: "blue" | "default" | "green" | "pink";
  }
> = ({ className, disabled, variant, ...props }) => {
  return (
    <label css={styles.root({ disabled })} className={className}>
      <input css={styles.input} disabled={disabled} type="radio" {...props} />
      <div css={styles.radio.outer({ checked: !!props.checked, variant })}>
        <span css={styles.radio.inner} />
      </div>
    </label>
  );
};
