import { useMemo } from "react";

import { ItineraryServiceModelFactory } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";

import { useItineraryScreen } from "../itinerary-screen.context";

export const useItineraryServiceOutOfPolicyDialogPresenter = () => {
  const {
    selectedServiceOutOfPolicyDialogService: service,
  } = useItineraryScreen();

  return useMemo(() => {
    if (!service) {
      return {
        servicePresenter: null,
      };
    }

    return {
      servicePresenter: ItineraryServicePresenterFactory.create(
        ItineraryServiceModelFactory.create(service),
      ),
    };
  }, [service]);
};
