import React, { useMemo, useEffect, useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { EXPENSES_TYPES, EXPENSES_CATEGORIES } from "@constants";

import * as expensesHelper from "@helpers/expense.helper";

import {
  ExpenseCategoriesOptions,
  ExpenseCategoryCamelCase,
} from "@dtos/expense-categories.dto";

import { Expense } from "@models/expense.model";

import { CustomError, ImageFile } from "~/types";

import { useSafeState } from "@hooks";

import * as expensesService from "./ExpenseDrawer.service";
import { FormValues } from "./ExpenseDrawer.types";

interface Actions {
  calculateRouteDistanceAndValue: (
    formData: FormValues,
    onFinish: (data: any) => void,
  ) => void;
  handleSaveExpense: (
    expenseFormValues: FormValues,
    onAfterSave?: () => void,
  ) => void;
  getExpenseCategoriesOptions: () => void;
  setSelectedExpense: (selectedExpense: Expense | null) => void;
}

type State = {
  calculatingRoute: boolean;
  expensesCategoriesOptions: ExpenseCategoriesOptions[];
  isKilometerExpensesAllowed: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  selectedExpense: Expense | null;
};

const initialState: State = {
  calculatingRoute: false,
  expensesCategoriesOptions: [],
  isKilometerExpensesAllowed: false,
  isLoading: false,
  isSubmitting: false,
  selectedExpense: null,
};

type ContextProps = Actions &
  State & {
    visibleExpenseCategoriesOptions: ExpenseCategoriesOptions[];
  };

export const ExpenseDrawerContext = React.createContext<ContextProps>(
  {} as ContextProps,
);

export const ExpenseDrawerProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const getExpenseCategoriesOptions = useCallback(async () => {
    setState({
      isLoading: true,
    });

    const [
      { data: expenseCategories, error: expenseCategoriesError },
      { data: kilometerPermission },
    ] = await Promise.all([
      expensesService.getExpenseCategoriesOptions(),
      expensesService.getKilometerExpenseCreationPermission(),
    ]);

    if (expenseCategoriesError) {
      setState({
        isLoading: false,
      });

      showSnackMessage(expenseCategoriesError.description, ALERT_TYPES.ERROR);

      return;
    }

    const options = expenseCategories!.map(
      (item: Partial<ExpenseCategoryCamelCase>) => ({
        expenseCategory: item.expenseCategory!,
        expenseCategoryToken: item.expenseCategoryToken!,
        name: item.name!,
      }),
    );

    setState({
      expensesCategoriesOptions: options,
      isKilometerExpensesAllowed: kilometerPermission,
      isLoading: false,
    });
  }, []);

  const setSelectedExpense = useCallback((selectedExpense: Expense | null) => {
    setState({ selectedExpense });
  }, []);

  const handleSaveExpense = (
    expenseFormValues: FormValues,
    onAfterSave?: () => void,
  ) => {
    if (state.selectedExpense) {
      void handleUpdateExpense(expenseFormValues, onAfterSave);
    } else {
      void handleCreateExpense(expenseFormValues, onAfterSave);
    }
  };

  const handleCreateExpense = async (
    expenseFormValues: FormValues,
    onAfterSave?: () => void,
  ) => {
    setState({
      isSubmitting: true,
    });

    let filename = "";

    if (expenseFormValues.expenseCategory === EXPENSES_TYPES.KILOMETER) {
      const {
        data: mapImage,
        error: mapImageError,
      } = await expensesService.getStaticMapReceipt(
        expenseFormValues.routeDirections!,
        expenseFormValues.distance!,
      );

      if (mapImageError) {
        setErrorState(mapImageError);

        return;
      }

      expenseFormValues.receipt = mapImage;
    }

    if (expenseFormValues.receipt) {
      const {
        data: temporaryFilename,
        error: filenameError,
      } = await uploadTemporaryImage(expenseFormValues.receipt!);

      if (filenameError) {
        setErrorState(filenameError);

        return;
      }

      filename = temporaryFilename!;
    }

    const requestData = expensesHelper.getExpenseCreationFormattedData(
      expenseFormValues,
      filename,
    );

    const {
      error: expenseCreationError,
    } = await expensesService.createNewExpense(requestData);

    if (expenseCreationError) {
      setErrorState(expenseCreationError);

      return;
    }

    setState({
      isSubmitting: false,
    });

    if (onAfterSave) {
      onAfterSave();
    }
  };

  const handleUpdateExpense = async (
    expenseFormValues: FormValues,
    onAfterSave?: () => void,
  ) => {
    setState({
      isSubmitting: true,
    });
    let filename = "";

    const hasLocationsChanged = expensesHelper.hasLocationChanged(
      expenseFormValues,
      state.selectedExpense!,
    );

    if (
      expenseFormValues.expenseCategory === EXPENSES_CATEGORIES.KILOMETER &&
      hasLocationsChanged
    ) {
      const {
        data: mapImage,
        error: mapImageError,
      } = await expensesService.getStaticMapReceipt(
        expenseFormValues.routeDirections!,
        expenseFormValues.distance!,
      );

      if (mapImageError) {
        setErrorState(mapImageError);

        return;
      }

      expenseFormValues.receipt = mapImage;
    }

    if (expenseFormValues.receipt) {
      const {
        data: temporaryFilename,
        error: filenameError,
      } = await uploadTemporaryImage(expenseFormValues.receipt!);

      if (filenameError) {
        setErrorState(filenameError);

        return;
      }

      filename = temporaryFilename!;
    }

    const requestData = expensesHelper.getExpenseCreationFormattedData(
      expenseFormValues,
      filename,
    );

    const { error: updateExpenseError } = await expensesService.updateExpense(
      requestData,
      state.selectedExpense!.expenseToken,
    );

    if (updateExpenseError) {
      setErrorState(updateExpenseError);

      return;
    }

    setState({
      isSubmitting: false,
    });

    if (onAfterSave) {
      onAfterSave();
    }
  };

  const uploadTemporaryImage = async (
    imageFile: ImageFile,
  ): Promise<{ data?: string; error?: CustomError }> => {
    const filename = expensesHelper.getTemporaryFilename(imageFile);

    const {
      data: uploadUrl,
      error: uploadUrlError,
    } = await expensesService.getImageUploadUrl(imageFile.type, filename);

    if (uploadUrlError) {
      return { error: uploadUrlError };
    }

    const { error: uploadImageError } = await expensesService.uploadImage(
      uploadUrl!,
      imageFile,
    );

    if (uploadImageError) {
      return { error: uploadImageError };
    }

    return { data: filename };
  };

  const calculateRouteDistanceAndValue = async (
    formData: FormValues,
    onFinish: (data: any) => void,
  ) => {
    setState({ calculatingRoute: true });

    const { originLocation, destinationLocation } = formData;

    const {
      data,
      error,
    } = await expensesService.getCalculatedRouteDistanceAndValue(
      originLocation!,
      destinationLocation!,
    );

    if (error) {
      setState({ calculatingRoute: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({ calculatingRoute: false });

    onFinish(data);
  };

  const setErrorState = (error: CustomError) => {
    setState({
      isLoading: false,
      isSubmitting: false,
    });

    showSnackMessage(error.description, ALERT_TYPES.ERROR);
  };

  const visibleExpenseCategoriesOptions = useMemo(() => {
    let expenseCategories = state.expensesCategoriesOptions;

    if (!state.isKilometerExpensesAllowed) {
      expenseCategories = expenseCategories.filter(
        (item) => item.expenseCategory !== EXPENSES_CATEGORIES.KILOMETER,
      );
    }

    return expenseCategories;
  }, [state.expensesCategoriesOptions, state.isKilometerExpensesAllowed]);

  useEffect(() => {
    void getExpenseCategoriesOptions();
  }, [getExpenseCategoriesOptions]);

  return (
    <ExpenseDrawerContext.Provider
      value={{
        ...state,
        calculateRouteDistanceAndValue,
        getExpenseCategoriesOptions,
        handleSaveExpense,
        setSelectedExpense,
        visibleExpenseCategoriesOptions,
      }}
    >
      {children}
    </ExpenseDrawerContext.Provider>
  );
};
