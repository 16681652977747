import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import moment from "moment";

import { styles } from "./styles";

type Props = {
  canceledAt?: string;
  canceledByUserName?: string;
  isCanceled: boolean;
};

export const TravelPlanPDFServiceCanceledBy: React.FC<Props> = ({
  canceledAt,
  canceledByUserName,
  isCanceled,
}) => {
  const badgeStyles = styles.badge();

  return (
    <View style={badgeStyles.root}>
      <PDFIcon size={14} use="person" />
      <View>
        <Text>
          {isCanceled ? "Cancelado" : "Cancelamento solicitado"}
          {canceledByUserName ? ` por: ${canceledByUserName}` : null}
          {canceledAt
            ? ` em ${moment(canceledAt).format("DD/MM/YYYY HH:mm")}`
            : null}
          .
        </Text>
      </View>
    </View>
  );
};
