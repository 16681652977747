import React from "react";
import { css } from "emotion";
import { navigate } from "@reach/router";
import { MEDIA_QUERIES } from "@constants";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Row, Column, Desktop } from "@shared/layout/index";
import { defaultTheme } from "@theme";

const styles = {
  root: css({
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    width: "100%",
    background: "#FFFFFF",
    position: "sticky",
    top: 0,
    zIndex: 1,
    color: defaultTheme.textColor,
    lineHeight: 1.5,
    letterSpacing: "0.04rem"
  }),
  container: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: ".75rem 4rem .75rem 1rem",
    width: "100%",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      padding: ".5rem 1rem"
    }
  }),
  reportInfoColumn: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: ".8em",
    fontSize: 13,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      alignItems: "flex-start"
    }
  }),
  name: css({
    fontWeight: "bold",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 14,
      lineHeight: "24px"
    }
  }),
  token: css({
    fontWeight: "bold",
    color: defaultTheme.subTextColor,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 10,
      lineHeight: "16px"
    }
  }),
  arrowContainer: css({
    padding: "0 .5rem",
    borderRight: `1px solid ${defaultTheme.cardBorderColor}`,
    justifyContent: "center",
    cursor: "pointer",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      padding: "0 2rem"
    }
  }),
  arrowIcon: css({
    color: defaultTheme.primaryColor
  }),
  pageName: css({
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
    color: defaultTheme.subTextColor
  }),
  backLabel: css({
    fontSize: 13,
    fontWeight: "bold",
    color: defaultTheme.primaryColor,
    paddingLeft: ".5rem"
  })
};

interface Props {
  pageName: string;
  token: string;
  name: string;
}

const SubNavbar = ({ pageName, token, name }: Props) => {
  const handleBackClick = () => {
    // Needs to be ignored since there isn`t a updated types for number as the `to` parameter
    // @ts-ignore
    navigate(-1);
  };

  return (
    <Row className={styles.root}>
      <Column className={styles.arrowContainer} onClick={handleBackClick}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon className={styles.arrowIcon} />
          <Desktop>
            <div className={styles.backLabel}>
              <span>Voltar</span>
            </div>
          </Desktop>
        </div>
      </Column>
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }}
      >
        <div className={styles.container}>
          <div className={styles.pageName}>
            <span>{pageName}</span>
          </div>
          <Row style={{ alignItems: "center" }}>
            <div className={styles.reportInfoColumn}>
              <span className={styles.name}>
                {name ? name : "Relatório sem nome"}
              </span>
              <span className={styles.token}>ID: {token}</span>
            </div>
          </Row>
        </div>
      </Row>
    </Row>
  );
};

export { SubNavbar };
