import { css } from "@styled-system/css";
import banner from "~/apps/shared/assets/new-trip/banner.jpeg";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    container: {
      root: css({
        alignItems: "center",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        height: "100%",
        justifyContent: "center",
        margin: "auto",
        maxWidth: "512px",
        padding: "32px",
        width: "100%",
        "@media screen and (max-width: calc(352px * 2 + 24px * 2))": {
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.gray[100]}`,
          height: "fit-content",
          maxWidth: "352px",
        },
        "@media screen and (max-width: calc(352px + 24px * 2))": {
          maxWidth: "unset",
        },
      }),
    },
    root: css({
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      padding: "24px",
      width: "100%",
      "@media screen and (max-width: calc(352px * 2 + 24px * 2))": {
        backgroundColor: theme.colors.background.gray,
      },
    }),
  },
  right: {
    banner: css({
      backgroundImage: `url(${banner})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      left: 0,
      right: 0,
      top: 0,
      width: "100%",
    }),
    root: css({
      backgroundColor: theme.colors.blue[500],
      display: "flex",
      width: "100%",
      "@media screen and (max-width: calc(352px * 2 + 24px * 2))": {
        display: "none",
      },
    }),
  },
  root: css({
    display: "flex",
    minHeight: "100dvh",
  }),
};
