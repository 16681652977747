import { AxiosResponse } from "axios";

import { getAuthorizationHeader, getClientToken } from "@helpers/user.helper";

import {
  ProjectItemResponse,
  ProjectCreateRequestBody,
  ProjectCreateResponseBody,
  ProjectEditionRequestBody,
  ProjectEditionResponseBody,
  GetUserProjectsResponseBody
} from "@dtos/projects.dto";

import { parseProjectItem } from "../parsers/projects.parser";
import { api } from "./interceptors";

export function listClientProjects() {
  return api
    .request({
      url: `/booking/client/${getClientToken()}/project`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<ProjectItemResponse[]>) =>
      data.map(project => parseProjectItem(project))
    );
}

export function getSingleProject(projectToken: string) {
  return api
    .request({
      url: `/booking/project/${projectToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<ProjectItemResponse>) =>
      parseProjectItem(data)
    );
}

export function toogleProjectActiveStatus(projectToken: string) {
  return api
    .request({
      url: `/booking/project/toggle/${projectToken}`,
      method: "PUT",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export function createProject(dto: ProjectCreateRequestBody) {
  return api
    .request({
      url: "/booking/project",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      ({ data }: AxiosResponse<ProjectCreateResponseBody>) => data.project_token
    );
}

export function editProject(
  dto: ProjectEditionRequestBody,
  projectToken: string
) {
  return api
    .request({
      url: `/booking/project/${projectToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<ProjectEditionResponseBody>) => data);
}

export function changeClientProjectEnablingState(
  projectState: string,
  clientConfigToken: string
) {
  return api
    .request({
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: {
        project_enabling_state: projectState
      }
    })
    .then(({ data }) => data);
}

export function getUserProjects(userToken: string) {
  return api
    .request({
      url: `/booking/users/${userToken}/projects`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<GetUserProjectsResponseBody[]>) =>
      data.map(project => ({
        projectToken: project.project_token,
        billingProfileToken: project.billing_profile_token,
        name: project.name,
        active: project.active
      }))
    );
}
