import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  additionals: {
    additionals: {
      additional: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
      root: css({
        display: "flex",
        flexWrap: "wrap",
        gap: "8px 24px",
      }),
    },
    root: css({
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      gap: "24px",
      paddingTop: "24px",
      width: "100%",
      "@media (max-width: 640px)": {
        padding: "24px 0 16px 0",
      },
    }),
  },
  car: {
    column: {
      feature: {
        gray: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
        green: css({
          alignItems: "center",
          color: theme.colors.green[500],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "8px 0",
      }),
    },
    features: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "8px 24px",
    }),
    image: css({
      height: "fit-content",
      maxWidth: "144px",
      objectFit: "contain",
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px 24px",
    }),
  },
  heading: {
    description: css({
      color: theme.colors.gray[100],
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: "4px 8px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "1.125rem",
      fontWeight: 700,
      whiteSpace: "nowrap",
    }),
  },
  location: {
    map: {
      label: css({
        fontSize: "0.875rem",
        textDecoration: "underline",
      }),
      root: css({
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[700],
        display: "flex",
        fontFamily: theme.fonts.inter,
        gap: "8px",
        paddingBottom: "8px",
        width: "fit-content",
        ":not(:disabled)": {
          cursor: "pointer",
        },
      }),
    },
    rental: {
      capitalize: css({
        textTransform: "capitalize",
      }),
      icon: css({
        color: theme.colors.gray[300],
        flexShrink: 0,
        transform: "translateY(1px)",
      }),
      root: css({
        display: "flex",
        gap: "8px",
      }),
      text: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
    },
    root: css({
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "24px 0 0 0",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "8px",
    width: "100%",
    "@media (max-width: 640px)": {
      padding: "8px",
    },
  }),
};
