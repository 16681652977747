import React, { useState } from "react";

import { navigate, RouteComponentProps } from "@reach/router";
import { AuthLayout } from "~/apps/corporate/components/auth-layout/auth-layout";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import * as passwordApi from "~/apps/shared/apis/password.api";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputPassword } from "~/apps/shared/components/input-password/input-password";
import { Logo } from "~/apps/shared/components/logo/logo";
import { PasswordMeter } from "~/apps/shared/components/password-meter/password-meter";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { Button, CircularSpinner } from "@toolkit/v2";

import {
  passwordRedefineFormSchema,
  PasswordRedefineFormSchema,
} from "./password-redefine-form.schema";
import { styles } from "./styles";

type Props = RouteComponentProps & {
  redefineToken?: string;
};

export const PasswordRedefine: React.FC<Props> = ({ redefineToken }) => {
  const [changedWithSuccess, setChangedWithSuccess] = useState<boolean | null>(
    null,
  );
  const [redefineError, setRedefineError] = useState<Error | null>(null);
  const [redefineMessage, setRedefineMessage] = useState<string | null>(null);

  const form = useForm<PasswordRedefineFormSchema>({
    defaultValues: {
      confirmPassword: "",
      password: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      if (!redefineToken) {
        return;
      }

      await passwordApi
        .redefinePassword(redefineToken, values.password)
        .then((response) => {
          if (!response || !response.success) {
            setRedefineError(ERROR.UNEXPECTED);

            return;
          }

          setChangedWithSuccess(true);
          setRedefineMessage(response.message);

          setTimeout(() => {
            navigate("/login");
          }, 5000);
        })
        .catch((error) => {
          setRedefineError(error);
        });
    },
    schema: passwordRedefineFormSchema,
  });

  return (
    <>
      <PageTitle title={pageTitles.RESET_PASSWORD} />
      <AuthLayout>
        <Logo showName />
        {changedWithSuccess ? (
          <div css={styles.success.root}>
            <span css={styles.success.title}>
              Senha redefinida com sucesso!
            </span>
            <p css={styles.success.text}>
              Você será redirecionada para a tela de login em alguns segundos.
            </p>
            <CircularSpinner />
          </div>
        ) : (
          <Form context={form} css={styles.form.root}>
            {!form.submitting && redefineError ? (
              <div css={styles.form.error}>
                <Icon use="x-circle-outline" />
                <span>{redefineError.description}</span>
              </div>
            ) : null}
            {!form.submitting && redefineMessage ? (
              <div css={styles.form.message}>
                <Icon use="info-outline" />
                <span>{redefineMessage}</span>
              </div>
            ) : null}
            <div css={styles.form.field.root}>
              <Form.Field
                as={
                  <InputPassword
                    aria-label="Nova senha"
                    id="password"
                    placeholder="Nova senha"
                  />
                }
                onChange={() => {
                  void form.trigger("password");
                }}
                name="password"
              />
              <InputErrorMessage css={styles.form.field.error}>
                {form.errors["password"]?.message}
              </InputErrorMessage>
            </div>
            <PasswordMeter password={form.watch("password")} />
            <div css={styles.form.field.root}>
              <Form.Field
                as={
                  <InputPassword
                    aria-label="Confirmar senha"
                    id="confirmPassword"
                    placeholder="Confirmar senha"
                  />
                }
                onChange={() => {
                  void form.trigger("confirmPassword");
                }}
                name="confirmPassword"
              />
              <InputErrorMessage css={styles.form.field.error}>
                {form.errors["confirmPassword"]?.message}
              </InputErrorMessage>
            </div>
            <Button
              css={styles.form.button}
              disabled={!form.formState.isValid || form.submitting}
              type="submit"
            >
              {!form.submitting ? "Criar nova senha" : "Criando..."}
            </Button>
          </Form>
        )}
      </AuthLayout>
    </>
  );
};
