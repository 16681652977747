import React, { useState, CSSProperties, useEffect, useRef } from "react";
import { SingleDatePicker, SingleDatePickerShape } from "react-dates";

import CalendarIcon from "@material-ui/icons/DateRange";
import { css } from "emotion";
import { Moment } from "moment";

import { theme } from "@skin/v2";

import { defaultTheme } from "../../../../assets/styles/theme";
import InputError from "../../../shared/inputs/InputError";

const styles = {
  root: css({
    alignItems: "center",
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    boxSizing: "border-box",
    display: "flex",
    height: 45,
    justifyContent: "center",
    padding: "7px 6px",
    ["@media (max-width: 400px)"]: {
      padding: "7px 0px",
    },
    ".Calendar": {},
    ".Date": {
      "&Input": {
        background: "unset",
        width: "100%",
        "&_input": {
          backgroundColor: "transparent",
          borderBottom: "none !important",
          color: theme.colors.gray[700],
          fontFamily: theme.fonts.inter,
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
          outline: "none",
          padding: "unset",
          width: "100%",
        },
      },
    },
    ".DayPicker": {
      borderRadius: "8px",
      overflow: "hidden",
      "&Navigation": {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 16px",
        top: "18px",
        "& > :nth-of-type(1)": {
          transform: "rotate(0deg)",
        },
        "& > :nth-of-type(2)": {
          transform: "rotate(0deg)",
        },
        "&_button": {
          alignItems: "center",
          aspectRatio: "1",
          borderRadius: "12px",
          color: theme.colors.gray[300],
          display: "flex",
          height: "24px",
          justifyContent: "center",
          padding: "4px",
          transition: "all 0.15s ease-in-out",
          width: "24px",
          ":hover": {
            color: theme.colors.gray[700],
          },
        },
      },
      "&_weekHeaders": {
        top: "-8px",
      },
    },
    ".SingleDatePicker": {
      width: "100%",
      "&_picker": {
        background: "transparent",
        top: "calc(100% + 16px) !important",
      },
      "&Input": {
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "8px",
        display: "flex",
        gap: "12px",
        height: "100%",
        padding: "0 6px",
        width: "100%",
        "&_calendarIcon": {
          margin: 0,
          padding: 0,
        },
      },
    },
  }),
  disabled: css({
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
  }),
  focused: css({
    border: `2px solid ${defaultTheme.primaryColor}`,
    padding: "6px 5px !important",
  }),
  error: css({
    border: `2px solid ${defaultTheme.secondaryColor}`,
    padding: "6px 5px !important",
  }),
  icon: css({
    fontSize: 16,
    color: defaultTheme.subTextColor,
  }),
};

interface Props {
  date: Moment | null;
  handleDateChange: (date: Moment | null) => void;
  placeholder: string;
  id: string;
  rootStyle?: CSSProperties;
  error?: boolean;
  errorMessage?: string;
  setTouched?: () => void;
  disabled?: boolean;
  isOutsideRange?: (day: Moment) => boolean;
}

interface State {
  focused: boolean;
}

const DateInput = ({
  date,
  handleDateChange,
  placeholder,
  id,
  rootStyle,
  error,
  errorMessage,
  setTouched,
  disabled = false,
  isOutsideRange,
}: Props) => {
  const [state, setState] = useState<State>({
    focused: false,
  });

  const setSafeState = (newState: Partial<State>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const usePrevious = (value: boolean) => {
    const ref = useRef<boolean>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevFocused = usePrevious(state.focused);

  useEffect(() => {
    if (setTouched && prevFocused && !state.focused) {
      setTouched!();
    }
  }, [state.focused]);

  const onFocusChange = ({ focused }: { focused: boolean | null }) => {
    setSafeState({ focused: !!focused });
  };

  const getSecondaryClass = () => {
    if (error) {
      return styles.error;
    } else if (state.focused) {
      return styles.focused;
    } else {
      return "";
    }
  };

  const singleDateProps: SingleDatePickerShape = {
    date,
    onDateChange: handleDateChange,
    focused: state.focused,
    onFocusChange,
    id,
    placeholder,
    disabled,
    noBorder: true,
    small: true,
    hideKeyboardShortcutsPanel: true,
    numberOfMonths: 1,
    customInputIcon: <CalendarIcon className={styles.icon} />,
    isOutsideRange: isOutsideRange ? isOutsideRange : () => false,
  };

  return (
    <div style={rootStyle}>
      <div
        className={`${styles.root} ${getSecondaryClass()} ${
          disabled ? styles.disabled : ""
        }`}
        id="date-input"
      >
        <SingleDatePicker {...singleDateProps} />
      </div>
      {error ? <InputError>{errorMessage}</InputError> : null}
    </div>
  );
};

export { DateInput };
