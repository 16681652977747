import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { RESERVE_STATUS } from "~/apps/shared/constants";
import moment from "moment";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  reservationReservedUntil: string | null;
  reservationStatus: string;
};

export const TravelPlanPDFServiceReservationInfo: React.FC<Props> = ({
  reservationReservedUntil,
  reservationStatus,
  ...props
}) => {
  const badgeStyles = styles.badge();

  const momentReservedUntil = reservationReservedUntil
    ? moment(reservationReservedUntil)
    : null;

  const now = moment();

  const isReserveExpired =
    !!momentReservedUntil && momentReservedUntil.isBefore(now);

  const reservedUntilFormatted =
    momentReservedUntil?.format("DD [de] MMMM [de] YYYY [às] HH[h]mm[min].") ||
    null;

  const showPreReservation =
    reservationStatus === RESERVE_STATUS.RESERVED && !isReserveExpired;
  const showPreReservationExpired =
    reservationStatus === RESERVE_STATUS.RESERVED && isReserveExpired;
  const showPreReservationFailed = reservationStatus === RESERVE_STATUS.FAILED;
  const showPreReservationPending =
    reservationStatus === RESERVE_STATUS.PENDING ||
    reservationStatus === RESERVE_STATUS.FINAL_PENDING;

  return (
    <>
      {showPreReservation ? (
        <View style={badgeStyles.root} {...props}>
          <PDFIcon size={14} use="check-circle" />
          <View>
            <Text>
              Voo pré-reservado (bilhete não emitido).
              {!!reservedUntilFormatted &&
                ` Expira em ${reservedUntilFormatted}`}
            </Text>
          </View>
        </View>
      ) : null}
      {showPreReservationExpired ? (
        <View style={badgeStyles.root} {...props}>
          <PDFIcon size={14} use="clock-outline" />
          <View>
            <Text>
              Pré-reserva expirada
              {reservedUntilFormatted ? ` em ${reservedUntilFormatted}` : "."}
            </Text>
          </View>
        </View>
      ) : null}
      {showPreReservationFailed ? (
        <View style={badgeStyles.root} {...props}>
          <PDFIcon size={14} use="x-circle-outline" />
          <View>
            <Text>Falha na pré-reserva.</Text>
          </View>
        </View>
      ) : null}
      {showPreReservationPending ? (
        <View style={badgeStyles.root} {...props}>
          <PDFIcon size={14} use="arrow-path" />
          <View>
            <Text>Pré-reservando voo.</Text>
          </View>
        </View>
      ) : null}
    </>
  );
};
