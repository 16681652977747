export enum EventPrivacy {
  INVITED_ONLY = "INVITED_ONLY",
  PUBLIC = "PUBLIC"
}
export enum EventInviteStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED"
}
export enum EventParticipantType {
  ORGANIZER = "ORGANIZER",
  TRAVELER = "TRAVELER"
}
export enum CompanyEventStatus {
  UPCOMING = "UPCOMING",
  IN_PROGRESS = "IN_PROGRESS",
  ENDED = "ENDED"
}
export enum ParticipationStatus {
  VIRTUAL_PENDING = "VIRTUAL_PENDING",
  REALLY_PENDING = "REALLY_PENDING",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED"
}
export enum BookerParticipantType {
  FOR_ALL = "FOR_ALL",
  SELF_ONLY = "SELF_ONLY",
  CANNOT_BOOK = "CANNOT_BOOK"
}

export const mapParticipantInviteStatus = (status: ParticipationStatus) => {
  return {
    [ParticipationStatus.ACCEPTED]: "Aceito",
    [ParticipationStatus.DECLINED]: "Recusado",
    [ParticipationStatus.REALLY_PENDING]: "Pendente",
    [ParticipationStatus.VIRTUAL_PENDING]: "Pendente"
  }[status];
};
