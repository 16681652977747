import { FLIGHT_ORDER_TYPES } from "~/apps/shared/constants";

export function formatMoreOptions(options: any[], oneway: boolean) {
  const visibleOptions = oneway
    ? options.filter((option) => option.displayAtOneWayTrips)
    : options;

  return visibleOptions.map((item) => {
    const [title, subtitle] = formatOptionName(item);

    return {
      ...item,
      title,
      subtitle,
    };
  });
}

export function formatOptionName(option: any) {
  if (!option) {
    return "";
  }

  const [title, subtitle]: [
    string | undefined,
    string | undefined,
  ] = option.title.split(" - ");

  let bound = "";
  if (subtitle === "Ida") {
    bound = ` (${option.bound.origin} - ${option.bound.destination})`;
  } else if (subtitle === "Volta") {
    bound = ` (${option.bound.destination} - ${option.bound.origin})`;
  }

  return [title || "", subtitle?.concat(bound) || ""];
}

export function getOrderPriceMap(flights: any[]) {
  const flightsCopy = flights.map((item) => ({
    price: item.price,
    durationMinutes: item.durationMinutes,
  }));

  const firstRecommendedPrice = flightsCopy.length
    ? orderFlightListByPriceAndDuration(flightsCopy)[0].price
    : 0;
  const firstCheapestPrice = flightsCopy.length
    ? orderFlightListByPrice(flightsCopy)[0].price
    : 0;
  const firstFastestPrice = flightsCopy.length
    ? orderFlightListByDuration(flightsCopy)[0].price
    : 0;

  return {
    [FLIGHT_ORDER_TYPES.RECOMMENDED]: firstRecommendedPrice,
    [FLIGHT_ORDER_TYPES.CHEAPEST]: firstCheapestPrice,
    [FLIGHT_ORDER_TYPES.FASTEST]: firstFastestPrice,
  };
}

export function orderFlightListByDuration(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.durationMinutes > b.durationMinutes) {
      return 1;
    }
    if (a.durationMinutes < b.durationMinutes) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByEarliestInboundArrival(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.inbound.arrivalHour > b.inbound.arrivalHour) {
      return 1;
    }
    if (a.inbound.arrivalHour < b.inbound.arrivalHour) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByEarliestInboundDeparture(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.inbound.departureHour > b.inbound.departureHour) {
      return 1;
    }
    if (a.inbound.departureHour < b.inbound.departureHour) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByEarliestOutboundArrival(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.outbound.arrivalHour > b.outbound.arrivalHour) {
      return 1;
    }
    if (a.outbound.arrivalHour < b.outbound.arrivalHour) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByEarliestOutboundDeparture(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.outbound.departureHour > b.outbound.departureHour) {
      return 1;
    }
    if (a.outbound.departureHour < b.outbound.departureHour) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByPrice(flights: any[]) {
  const orderedFlights = flights.sort((a, b) => {
    if (a.price > b.price) {
      return 1;
    }
    if (a.price < b.price) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}

export function orderFlightListByPriceAndDuration(
  flights: {
    durationMinutes: number;
    price: number;
  }[],
): any[] {
  const orderedFlights = flights.sort((a, b) => {
    if (a.price * a.durationMinutes > b.price * b.durationMinutes) {
      return 1;
    }
    if (a.price * a.durationMinutes < b.price * b.durationMinutes) {
      return -1;
    }
    return 0;
  });

  return orderedFlights;
}
