import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  card: {
    root: ({ isDisabled }: { isDisabled: boolean }) =>
      css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        cursor: "pointer",
        fontSize: "0.875rem",
        overflow: "hidden",
        padding: "16px",
        transition: "all 0.15s ease-in-out",
        ...(isDisabled && {
          cursor: "default",
        }),
        ":hover": {
          borderColor: theme.colors.gray[300],
          ...(isDisabled && {
            borderColor: theme.colors.gray[100],
          }),
        },
        "@media screen and (max-width: 640px)": {
          padding: "0 0 24px 0",
        },
      }),
  },
  dates: {
    date: {
      icon: css({
        color: theme.colors.gray[500],
        flexShrink: 0,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
      }),
      text: css({
        color: theme.colors.gray[700],
        fontSize: "0.75rem",
      }),
    },
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      flexWrap: "wrap",
      gap: "4px 8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
  },
  reservation: {
    icon: css({
      color: theme.colors.gray[500],
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "6px",
      display: "flex",
      gap: "8px",
      padding: "4px 16px",
      width: "fit-content",
    }),
    text: css({
      color: theme.colors.gray[700],
      fontSize: "0.75rem",
    }),
  },
  root: ({ isDisabled }: { isDisabled: boolean }) =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginLeft: "24px",
      ...(isDisabled && {
        opacity: 0.75,
      }),
    }),
};
