import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  avatar: css({
    minWidth: "32px !important",
    "& > :first-of-type": {
      flexShrink: 0,
      height: "32px !important",
      width: "32px !important",
    },
  }),
  info: {
    email: css({
      color: theme.colors.gray[500],
      fontSize: "0.75rem",
      fontWeight: 400,
      height: "fit-content",
    }),
    name: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      fontWeight: 600,
      height: "fit-content",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    }),
  },
  root: css({
    display: "flex",
    gap: "8px",
  }),
};
