import React, { useCallback, useEffect, useState } from "react";
import { FocusedInputShape } from "react-dates";
import { useMutation, useQueryClient } from "react-query";

import { FormControl, FormControlLabel, Radio } from "@material-ui/core";
import {
  DateRange as DateRangeIcon,
  Hotel as HotelIcon
} from "@material-ui/icons/";
import { navigate } from "@reach/router";
import moment, { Moment } from "moment";
import { theme } from "smartrips-skin";
import { Box, Button, Flex, Text } from "smartrips-toolkit";
import uuid from "uuid";
import * as yup from "yup";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import { EventPrivacy } from "~/models/event.model";

import { ResponsiveRangeDatePicker } from "~/components/shared/date-pickers";
import { Form, useForm } from "~/components/shared/form";
import { FormFieldAdditionalText } from "~/components/shared/form/FormField";
import { Input } from "~/components/shared/inputs";
import { Spacing } from "~/components/shared/layout";
import Layout from "~/components/shared/Layout";
import { GoogleLocationAutocomplete } from "~/components/shared/new-trip/GoogleLocationAutocomplete";
import PageTitle from "~/components/shared/PageTitle";
import RadioGroup from "~/components/shared/radio-group";

import { EventProvider, useEvent } from "../event/event.context";
import { useEvents } from "../events.context";
import { styles } from "./styles";

const validationSchema = yup.object({
  description: yup.string().nullable(),
  endDate: yup.string().required("Selecione uma data de término."),
  location: yup
    .object({
      city: yup.string().required("Selecione uma cidade."),
      country: yup.string().required("Selecione um país."),
      latitude: yup.number().nullable(),
      longitude: yup.number().nullable(),
      search: yup.string().nullable(),
      state: yup.string().required("Selecione um estado.")
    })
    .required(),
  name: yup.string().required("Dê um nome ao evento, por favor."),
  privacy: yup
    .mixed<EventPrivacy>()
    .oneOf([EventPrivacy.INVITED_ONLY, EventPrivacy.PUBLIC])
    .required()
    .default(EventPrivacy.INVITED_ONLY),
  startDate: yup.string().required("Selecione uma data de início."),
  token: yup.string().required()
});

export type EditEventForm = yup.InferType<typeof validationSchema>;

type Props = {
  token: string;
};

const Component: React.VFC = () => {
  const user = getUserFromLocalStorage();

  const { event, isLoading } = useEvent();
  const { editEvent } = useEvents();

  const queryClient = useQueryClient();

  const {
    mutateAsync: editEventMutation,
    isLoading: isLoadingEditEvent
  } = useMutation({
    mutationFn: async (values: EditEventForm) => {
      const result = await editEvent(values);

      if (!result) {
        return;
      }

      await queryClient.invalidateQueries(["events"]);

      return result;
    }
  });

  const onSubmit = useCallback(async (values: EditEventForm) => {
    const result = await editEventMutation({
      ...values,
      endDate: moment(values.endDate).add(1, "day").format("YYYY-MM-DD"),
      startDate: moment(values.startDate).add(1, "day").format("YYYY-MM-DD")
    });

    if (!result) {
      return;
    }

    navigate(`/events/${result.token}/overview`);
  }, []);

  const form = useForm({
    defaultValues: {
      description: null,
      endDate: "",
      location: {
        city: "",
        country: "",
        latitude: null,
        longitude: null,
        search: "",
        state: ""
      },
      name: "",
      privacy: EventPrivacy.INVITED_ONLY,
      startDate: "",
      token: ""
    } as EditEventForm,
    mode: "onChange",
    onSubmit,
    schema: validationSchema
  });

  const [rangeFocus, setRangeFocus] = useState<FocusedInputShape | null>(null);

  const handleChangeRangeDatePickerFocues = (
    focused: FocusedInputShape | null
  ) => setRangeFocus(focused);

  const handleLocationChange = useCallback(async (location: any) => {
    form.setValue(
      "location",
      !location
        ? null
        : {
            city: location.city,
            country: location.country,
            latitude: location.latitude(),
            longitude: location.longitude(),
            search: location.formattedAddress,
            state: location.state
          }
    );

    await form.trigger("location");
  }, []);

  const handlePrivacyChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setValue("privacy", e.target.value);

      await form.trigger("privacy");
    },
    []
  );

  const handleRangeDateChange = useCallback(
    async ({
      endDate,
      startDate
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      if (endDate) {
        form.setValue("endDate", moment(endDate).format("YYYY-MM-DD") || null);

        await form.trigger("endDate");
      }

      if (startDate) {
        form.setValue(
          "startDate",
          moment(startDate).format("YYYY-MM-DD") || null
        );

        await form.trigger("startDate");
      }
    },
    []
  );

  const navigateToEvent = useCallback(() => {
    if (!event) {
      navigate("/events");

      return;
    }

    navigate(`/events/${event.token}/overview`);
  }, [event, navigate]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!event) {
      navigate("/events");

      return;
    }

    const populateForm = () => {
      const populateLocation = () => {
        const geocodingService = new google.maps.Geocoder();

        geocodingService.geocode(
          {
            address: `${event.city}, ${event.state}, ${event.country}`
          },
          (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              return;
            }

            const location = results[0].geometry.location;
            const search = results[0].formatted_address;

            form.setValue("location", {
              city: event.city,
              country: event.country,
              latitude: location.lat(),
              longitude: location.lng(),
              search,
              state: event.state
            });
          }
        );
      };

      form.setValue("description", event.description);
      form.setValue("endDate", moment(event.end_date).format("YYYY-MM-DD"));

      populateLocation();

      form.setValue("name", event.name);
      form.setValue("privacy", event.privacy);
      form.setValue("startDate", moment(event.start_date).format("YYYY-MM-DD"));
      form.setValue("token", event.token);
    };

    populateForm();
  }, [event, isLoading, navigate]);

  if (!event) {
    return null;
  }

  return (
    <>
      <PageTitle title="Editar Evento" />
      <Layout>
        <Box css={styles.container} className="container">
          <div css={styles.root}>
            <div css={styles.header}>
              <Text fontSize={24} fontWeight="bold">
                Editar Evento
              </Text>
              <Text color={theme.colors.gray[2]} fontSize={14} lineHeight={1.5}>
                Faça as alterações necessárias ao evento criado e clique em
                salvar.
              </Text>
            </div>
            <Form css={styles.form.root} context={form}>
              <Form.Field
                as={
                  <Input
                    label="Nome*"
                    placeholder="Digite um nome para o evento"
                    type="text"
                  />
                }
                name="name"
              />
              <Form.Field
                as={
                  <Input
                    label="Descrição"
                    placeholder="Digite uma descrição para o evento..."
                    type="text"
                  />
                }
                name="description"
              />
              <Flex flexDirection="column">
                <Flex
                  css={styles.getDateStyles(
                    !!form.errors.startDate || !!form.errors.endDate
                  )}
                  id="new-trip-picker"
                >
                  <ResponsiveRangeDatePicker
                    datePickerProps={{
                      anchorDirection: "left",
                      customInputIcon: <DateRangeIcon />,
                      disableScroll: false,
                      endDate: !form.watch("endDate")
                        ? null
                        : moment(form.watch("endDate"), "YYYY-MM-DD"),
                      endDateId: uuid(),
                      endDatePlaceholderText: "Volta*",
                      focusedInput: rangeFocus,
                      hideKeyboardShortcutsPanel: true,
                      minimumNights: 0,
                      noBorder: true,
                      onDatesChange: handleRangeDateChange,
                      onFocusChange: handleChangeRangeDatePickerFocues,
                      startDate: !form.watch("startDate")
                        ? null
                        : moment(form.watch("startDate"), "YYYY-MM-DD"),
                      startDateId: uuid(),
                      startDatePlaceholderText: "Ida*"
                    }}
                  />
                </Flex>
                {form.errors.endDate || form.errors.startDate ? (
                  <FormFieldAdditionalText hasError>
                    {form.errors.endDate.message ||
                      form.errors.startDate.message}
                  </FormFieldAdditionalText>
                ) : null}
              </Flex>
              <Flex flexDirection="column">
                <GoogleLocationAutocomplete
                  handleSelect={handleLocationChange}
                  icon={HotelIcon}
                  inputProps={{
                    placeholder: "Endereço, cidade ou ponto de interesse...*"
                  }}
                  value={
                    form.watch("location") !== null
                      ? form.watch("location").search
                      : undefined
                  }
                />
                {form.errors.location &&
                Object.values(form.errors.location).length > 0 ? (
                  <FormFieldAdditionalText hasError>
                    {
                      (Object.values(form.errors.location)[0] as {
                        message: string;
                      }).message
                    }
                  </FormFieldAdditionalText>
                ) : null}
              </Flex>
              <FormControl style={{ width: "100%" }} component="fieldset">
                <RadioGroup
                  aria-label="privacy"
                  css={styles.form.radioGroup}
                  id="privacy"
                  name="privacy"
                  onChange={handlePrivacyChange}
                  value={form.watch("privacy")}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={
                      <Flex flexDirection="column">
                        <Text fontSize={14} fontWeight="bold">
                          Evento da Empresa
                        </Text>
                        <Text
                          css={{ color: theme.colors.gray[2] }}
                          fontSize={14}
                        >
                          {user
                            ? `Todos na ${user.clientName} poderão ver e ir ao evento.`
                            : `Todos na empresa poderão ver e ir ao evento.`}
                        </Text>
                      </Flex>
                    }
                    value={EventPrivacy.PUBLIC}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={
                      <Flex flexDirection="column">
                        <Text fontSize={14} fontWeight="bold">
                          Evento Privado
                        </Text>
                        <Text
                          css={{ color: theme.colors.gray[2] }}
                          fontSize={14}
                        >
                          Apenas convidados poderão ver e ir ao evento.
                        </Text>
                      </Flex>
                    }
                    value={EventPrivacy.INVITED_ONLY}
                  />
                </RadioGroup>
              </FormControl>
              <Flex alignItems="center">
                <Button
                  color="cancel"
                  css={styles.button}
                  disabled={isLoadingEditEvent}
                  onClick={navigateToEvent}
                  type="button"
                >
                  Cancelar
                </Button>
                <Spacing direction="left" space="1rem" />
                <Button
                  css={styles.button}
                  disabled={
                    form.formState.isSubmitting ||
                    !form.formState.isValid ||
                    isLoadingEditEvent
                  }
                  onClick={form.submitForm}
                  type="button"
                >
                  Salvar Evento
                </Button>
              </Flex>
            </Form>
          </div>
        </Box>
      </Layout>
    </>
  );
};

export const EditEvent: React.VFC<Props> = ({ token }) => {
  return (
    <EventProvider token={token}>
      <Component />
    </EventProvider>
  );
};
