import React, { ChangeEvent, useState, FocusEvent } from "react";

import CloseIcon from "@material-ui/icons/CloseOutlined";

import { defaultTheme } from "../../../assets/styles/theme";
import * as utils from "../../../helpers";
import { Input } from "./index";

interface Props {
  onChange: (value: number) => void;
  showClearButton?: boolean;
  formatOnNull?: boolean;
  currencyCode?: string;
  [key: string]: any;
}

const CurrencyInput = ({
  onChange,
  value,
  showClearButton,
  formatOnNull,
  currencyCode,
  ...inputProps
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.persist();

    if (inputProps.onFocus) {
      inputProps.onFocus(e);
    }

    setFocused(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
    setFocused(false);
  };

  const unmaskValue = (currencyValue: string) => {
    // In case of comma deletion, insert a new comma to mark decimals
    if (!currencyValue.includes(",")) {
      currencyValue = [
        currencyValue.slice(0, -2),
        ",",
        currencyValue.slice(-2)
      ].join("");
    }

    // remove special characters and return a float like string
    const floatString = currencyValue
      .replace(/(R\$|\u2000|[a-zA-Z]|\s)/g, "")
      .replace(/[`\-~!@#$%^&*()_|+=?;:'".<>{}[\]\\/]/g, "")
      .replace(/,/g, ".");

    const [integer, decimals] = floatString.split(".");
    if (!integer && !!decimals && decimals !== "0" && decimals.length < 2) {
      return String.prototype.concat(integer, ".0", decimals);
    }

    return floatString;
  };

  const maskToCurrency = (unmaskedValue: string) => {
    let maskedValue: number | null;
    const [integers, decimals] = unmaskedValue.split(".");

    // format the float-string to always make the last two characters
    // the decimals of the value
    if (decimals) {
      const splitValue = [integers, decimals].join("");

      const formattedDecimals = splitValue.slice(-2);
      const formattedIntegers = splitValue.substr(0, splitValue.length - 2);

      maskedValue = parseFloat(formattedIntegers + "." + formattedDecimals);
    } else {
      maskedValue = parseFloat(unmaskedValue);
    }

    return maskedValue;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = unmaskValue(e.target.value);
    const maskedValue = maskToCurrency(unmaskedValue);

    onChange(maskedValue);
  };

  const handleResetInput = () => {
    onChange(0);
  };

  const renderCloseIcon = () => {
    if (showClearButton && !!value) {
      return (
        <CloseIcon
          onClick={handleResetInput}
          style={{
            cursor: "pointer",
            color: defaultTheme.subTextColor,
            marginLeft: ".5rem"
          }}
        />
      );
    } else {
      return null;
    }
  };

  const shouldShrinkLabel = !!value || focused;
  const val =
    value || value === 0
      ? utils.toCurrency(parseFloat(value), 2, currencyCode)
      : formatOnNull
      ? utils.toCurrency(0, 2, currencyCode)
      : "";

  return (
    <Input
      {...inputProps}
      value={val}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputProps={{
        pattern: "^R\\$ (\\.[0-9]|[0-9])*,[0-9]{2}",
        type: "text"
      }}
      InputLabelProps={{
        shrink: shouldShrinkLabel
      }}
      InputProps={{
        endAdornment: renderCloseIcon()
      }}
    />
  );
};

export { CurrencyInput };
