import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import ErrorIcon from "@material-ui/icons/CancelOutlined";
import { css } from "@styled-system/css";

import { Box, Flex, Paragraph, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { ExpenseReportWithUser } from "@models/expense.model";

import { StContainedButton, StOutlinedButton } from "@shared/buttons";

const styles = {
  dialog: css({
    [".MuiDialog-paper"]: {
      margin: [10, 32],
      width: ["auto", 600]
    }
  }),
  button: css({
    ["&.MuiButton-root"]: {
      py: "small",
      px: 24
    }
  }),
  spinner: css({
    color: "primary"
  }),
  list: css({
    listStyle: "inside none disc",
    ["li"]: {
      pl: ["small", "medium"],
      py: 12
    }
  }),
  errorIcon: css({
    color: "cancel",
    fontSize: 18,
    mr: "small"
  })
};

interface IProps {
  isVisible: boolean;
  isSubmitting: boolean;
  erroredReports: ExpenseReportWithUser[];
  handleSignalPayment(): void;
  handleClose(): void;
}

const SignalPaymentDialog: React.FC<IProps> = ({
  isVisible,
  isSubmitting,
  erroredReports,
  handleSignalPayment,
  handleClose
}) => {
  return (
    <Dialog open={isVisible} onClose={handleClose} css={styles.dialog}>
      <Box py="medium" px={24}>
        <Text fontSize={[3, 4]} fontWeight="bold">
          Baixa de pagamento
        </Text>
      </Box>
      {isSubmitting && (
        <Flex
          flexDirection="column"
          alignItems="center"
          py="large"
          px={24}
          width="auto"
        >
          <CircularProgress size={32} css={styles.spinner} />
          <Box mt={12}>
            <Text fontSize={[2, 3]}>Processando</Text>
          </Box>
        </Flex>
      )}
      {!isSubmitting && !erroredReports.length && (
        <>
          <Box py={["medium", 24]} px={24} lineHeight={["16px", "21px"]}>
            <Text fontSize={[1, 2]}>
              Deseja realmente prosseguir com a baixa de pagamento dos
              relatórios selecionados?
            </Text>
          </Box>
          <Flex py="medium" px={24} backgroundColor="#F7F8FD">
            <StContainedButton
              onClick={handleSignalPayment}
              isFullWidth={false}
              css={styles.button}
            >
              Prosseguir
            </StContainedButton>
            <Box px="small" />
            <StOutlinedButton onClick={handleClose} css={styles.button}>
              Cancelar
            </StOutlinedButton>
          </Flex>
        </>
      )}
      {!isSubmitting && !!erroredReports.length && (
        <>
          <Box
            fontSize={[1, 2]}
            py={["medium", 24]}
            px={24}
            maxHeight={500}
            overflowY="auto"
          >
            <Paragraph
              color="cancel"
              fontWeight="bold"
              lineHeight={["16px", "21px"]}
              mb="small"
              display="flex"
              alignItems="center"
            >
              <ErrorIcon css={styles.errorIcon} />
              Ocorreu um erro no processamento
            </Paragraph>
            <Paragraph lineHeight={["16px", "21px"]} mb="small">
              Não conseguimos realizar a baixa dos seguintes relatórios:
            </Paragraph>
            <ul css={styles.list}>
              {erroredReports.map(report => (
                <li key={report.expenseReportToken}>
                  <Text color={defaultTheme.subTextColor}>
                    ID: {report.expenseReportToken} - {report.description}
                  </Text>
                </li>
              ))}
            </ul>
            <Paragraph
              lineHeight={["16px", "21px"]}
              mt="small"
              fontWeight="bold"
            >
              Entre em contato com nosso suporte.
            </Paragraph>
          </Box>
          <Box py="medium" px={24} backgroundColor="#F7F8FD">
            <StOutlinedButton onClick={handleClose} css={styles.button}>
              Fechar
            </StOutlinedButton>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export { SignalPaymentDialog };
