import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { HospitalityHotelDetails } from "~/apps/corporate/dtos/hotel.dto";
import { Icon } from "~/apps/shared/components/icon/icon";
import { StarRatingDisplay } from "~/apps/shared/components/star-rating-display/star-rating-display";
import { FAVORITE_TYPES } from "~/apps/shared/constants";

import { Button } from "@toolkit/v2";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

export const HotelResultInfo: React.FC = () => {
  const { hotelInfo, loading } = useHotelResult();

  return (
    <div css={styles.root}>
      {loading ? (
        <HotelResultHeaderHotelInfoSkeleton />
      ) : hotelInfo ? (
        <HotelResultHeaderHotelInfo hotelInfo={hotelInfo} />
      ) : null}
      {loading ? (
        <HotelResultFavoriteButtonSkeleton />
      ) : hotelInfo ? (
        <HotelResultFavoriteButton hotelInfo={hotelInfo} />
      ) : null}
    </div>
  );
};

type HotelResultFavoriteButtonProps = {
  hotelInfo: HospitalityHotelDetails;
};

const HotelResultFavoriteButton: React.FC<HotelResultFavoriteButtonProps> = ({
  hotelInfo,
}) => {
  const { openFavoriteDialog } = useHotelResult();

  const companyFavorite = hotelInfo.favorites.find(
    (favorite) => favorite.type === FAVORITE_TYPES.COMPANY,
  );

  const handleOpenFavoriteDialog = useCallback(() => {
    openFavoriteDialog();
  }, [openFavoriteDialog]);

  const userFavorite = hotelInfo.favorites.find(
    (favorite) => favorite.type === FAVORITE_TYPES.USER,
  );

  const isChecked = hotelInfo.favorites.length > 0;

  return (
    <Button
      css={styles.right.button}
      fill={isChecked ? "filled" : "outlined"}
      onClick={() => {
        handleOpenFavoriteDialog();
      }}
      variant={isChecked ? "pink" : "default"}
    >
      <Icon use={isChecked ? "heart" : "heart-outline"} />
      <span>
        {" "}
        {!!companyFavorite && !!userFavorite
          ? "Favorito"
          : companyFavorite
          ? "Empresa"
          : userFavorite
          ? "Pessoal"
          : "Favoritar"}
      </span>
    </Button>
  );
};

const HotelResultFavoriteButtonSkeleton: React.FC = () => {
  return (
    <Button
      css={styles.right.button}
      disabled
      fill="outlined"
      variant="default"
    >
      <Icon use="heart-outline" />
      <span>Favoritar</span>
    </Button>
  );
};

type HotelResultHeaderHotelInfoProps = {
  hotelInfo: HospitalityHotelDetails;
};

const HotelResultHeaderHotelInfo: React.FC<HotelResultHeaderHotelInfoProps> = ({
  hotelInfo: { address, name, stars },
}) => {
  return (
    <div css={styles.left.root}>
      <div css={styles.left.top.root}>
        <h1 css={styles.left.top.name}>{name}</h1>
        <StarRatingDisplay css={styles.left.top.stars} rating={stars} />
      </div>
      <div css={styles.left.bottom.root}>
        <Icon use="map-pin" />
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {address.toLowerCase()}
        </span>
      </div>
    </div>
  );
};

const HotelResultHeaderHotelInfoSkeleton: React.FC = () => {
  return (
    <div css={styles.left.root}>
      <div css={styles.left.top.root}>
        <Skeleton height="24px" width="384px" />
        <Skeleton height="20px" width="132px" />
      </div>
      <div css={styles.left.bottom.root}>
        <Skeleton height="24px" width="256px" />
      </div>
    </div>
  );
};
