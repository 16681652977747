import React, { createContext, useEffect, useState } from "react";

import { ServiceType } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import {
  FlightsSearch,
  HotelsSearch,
} from "../../components/offer-searcher/offer-searcher.types";
import { useUser } from "../../contexts/user.context";
import { BookingTarget } from "../../models/booking-target.model";
import * as newTripHelper from "./new-trip.helper";
import * as newTripService from "./new-trip.service";
import { RecentFlightsSearch, RecentHotelsSearch } from "./new-trip.types";

export const OFFER_SEARCHER_TABS = {
  BUS: "bus",
  CAR: "car",
  FLIGHT: "flight",
  HOTEL: "hotel",
} as const;

export type OfferSearcherTab = typeof OFFER_SEARCHER_TABS[keyof typeof OFFER_SEARCHER_TABS];

interface Actions {
  fetchRecentFlights: () => void;
  fetchRecentHotels: () => void;
  handlePopulateFlightsSearchWithRecent: (
    recentFlightSearch: RecentFlightsSearch,
  ) => void;
  handlePopulateHotelsSearchWithRecent: (
    recentHotelsearch: RecentHotelsSearch,
  ) => void;
  handleTabChange: (tab: OfferSearcherTab) => void;
}

type State = {
  defaultBookingTarget: BookingTarget | null;
  isLoadingDefaultBookingTarget: boolean;
  isLoadingRecentFlights: boolean;
  isLoadingRecentHotels: boolean;
  recentFlightsSearches: RecentFlightsSearch[];
  recentHotelsSearches: RecentHotelsSearch[];
  selectedSearch:
    | (
        | {
            data: FlightsSearch;
            type: ServiceType.FLIGHT;
          }
        | {
            data: HotelsSearch;
            type: ServiceType.HOTEL;
          }
      )
    | null;
  selectedTab: OfferSearcherTab;
  userDoesNotHaveRecentSearches: boolean;
};

const initialState: State = {
  defaultBookingTarget: null,
  isLoadingDefaultBookingTarget: false,
  isLoadingRecentFlights: false,
  isLoadingRecentHotels: false,
  recentFlightsSearches: [],
  recentHotelsSearches: [],
  selectedSearch: null,
  selectedTab: OFFER_SEARCHER_TABS.FLIGHT,
  userDoesNotHaveRecentSearches: true,
};

type ContextProps = Actions & State;

const NewTripContext = createContext<ContextProps>({
  ...initialState,
  fetchRecentFlights: async () => {
    return;
  },
  fetchRecentHotels: async () => {
    return;
  },
  handlePopulateFlightsSearchWithRecent: () => {
    return;
  },
  handlePopulateHotelsSearchWithRecent: () => {
    return;
  },
  handleTabChange: () => {
    return;
  },
});

export const NewTripProvider: React.FC = ({ children }) => {
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchDefaultBookingTargets = async () => {
    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingDefaultBookingTarget: true,
    }));

    const bookingTargets = await newTripService.searchBookingTargets("");

    if (bookingTargets.error || !bookingTargets.data) {
      setState((prev) => ({
        ...prev,
        isLoadingDefaultBookingTarget: false,
      }));

      return;
    }

    const defaultBookingTarget = bookingTargets.data.find(
      (bookingTarget) => bookingTarget.userToken === user.getUserToken(),
    );

    if (!defaultBookingTarget) {
      setState((prev) => ({
        ...prev,
        isLoadingDefaultBookingTarget: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      defaultBookingTarget,
      isLoadingDefaultBookingTarget: false,
    }));
  };

  const fetchRecentFlights = async () => {
    if (state.recentFlightsSearches.length > 0) {
      return;
    }

    setState((prev) => ({ ...prev, loading: true }));

    const userRecentFlights = await newTripService.getUserRecentFlights();

    if (userRecentFlights.error || !userRecentFlights.data) {
      setState((prevState) => ({ ...prevState, loading: false }));

      return;
    }

    const recentFlightsSearches = userRecentFlights.data;

    const userDoesNotHaveRecentSearches = !recentFlightsSearches.length;

    setState((prevState) => ({
      ...prevState,
      isLoadingRecentFlights: false,
      recentFlightsSearches,
      userDoesNotHaveRecentSearches,
    }));
  };

  const fetchRecentHotels = async () => {
    if (state.recentHotelsSearches.length > 0) {
      return;
    }

    setState((prev) => ({ ...prev, loading: true }));

    const userRecentHotels = await newTripService.getUserRecentHotels();

    if (userRecentHotels.error || !userRecentHotels.data) {
      setState((prevState) => ({ ...prevState, loading: false }));

      return;
    }

    const recentHotelsSearches = userRecentHotels.data;

    const userDoesNotHaveRecentSearches = !recentHotelsSearches.length;

    setState((prevState) => ({
      ...prevState,
      isLoadingRecentHotels: false,
      recentHotelsSearches,
      userDoesNotHaveRecentSearches,
    }));
  };

  const handlePopulateFlightsSearchWithRecent = (
    recentFlightSearch: RecentFlightsSearch,
  ) => {
    setState((prev) => ({
      ...prev,
      selectedSearch: {
        data: newTripHelper.buildFlightsSearch(recentFlightSearch),
        type: ServiceType.FLIGHT,
      },
    }));
  };

  const handlePopulateHotelsSearchWithRecent = (
    recentHotelsearch: RecentHotelsSearch,
  ) => {
    setState((prev) => ({
      ...prev,
      selectedSearch: {
        data: newTripHelper.buildHotelsSearch(recentHotelsearch),
        type: ServiceType.HOTEL,
      },
    }));
  };

  const handleTabChange = (tab: OfferSearcherTab) => {
    setState((prev) => ({
      ...prev,
      selectedTab: tab,
    }));
  };

  useEffect(() => {
    void fetchDefaultBookingTargets();
  }, []);

  return (
    <NewTripContext.Provider
      value={{
        ...state,
        fetchRecentFlights,
        fetchRecentHotels,
        handlePopulateFlightsSearchWithRecent,
        handlePopulateHotelsSearchWithRecent,
        handleTabChange,
      }}
    >
      {children}
    </NewTripContext.Provider>
  );
};

export const useNewTrip = useContextFactory("NewTripContext", NewTripContext);
