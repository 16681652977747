import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { styles } from "./styles";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ItinerayJourneyHeaderDeleteButton: React.FC<Props> = ({
  ...props
}) => {
  return (
    <Tooltip arrow content="Excluir oferta" position="top">
      <button css={styles.button} {...props}>
        <Icon size={14} use="trash" />
      </button>
    </Tooltip>
  );
};
