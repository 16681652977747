import React, { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Link } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CopyIconButtonWithTooltip } from "~/apps/shared/components/copy-icon-button-with-tooltip/copy-icon-button-with-tooltip";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { SERVICE_TYPES } from "~/apps/shared/constants";
import moment from "moment";

import { ICompleteTrip } from "../../trips.types";
import { TripCardBusOffer } from "./card-bus-offer/card-bus-offer";
import { TripCardCarOffer } from "./card-car-offer/card-car-offer";
import { TripCardFlightOffer } from "./card-flight-offer/card-flight-offer";
import { TripCardHotelOffer } from "./card-hotel-offer/card-hotel-offer";
import { TripCardTags } from "./card-tags/card-tags";
import { styles } from "./styles";

type Props = {
  allowDeletion?: boolean;
  selectTravelToCancel: (travel: ICompleteTrip) => void;
  travel: ICompleteTrip;
};

export const TripCard: React.FC<Props> = ({
  allowDeletion = true,
  selectTravelToCancel,
  travel,
}) => {
  const { user } = useUser();

  const getFormattedTripPeriod = useCallback(
    (mask: string) => {
      const { endDate, startDate } = travel;

      const formattedEndDate = moment(endDate).format(mask);
      const formattedStartDate = moment(startDate).format(mask);

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    [travel],
  );

  const handleDeleteTravel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      selectTravelToCancel(travel);
    },
    [selectTravelToCancel, travel],
  );

  const isDeleteOptionVisible = useMemo(() => {
    if (!allowDeletion || !travel.isDeletable) {
      return false;
    }

    if (!user) {
      return false;
    }

    return user.hasConfigurationsCapability();
  }, [allowDeletion, user, travel]);

  return (
    <Link
      css={styles.root}
      to={`/travels/${travel.travelId}/itinerary/travel-plan`}
    >
      <div css={styles.header.root}>
        <div css={styles.header.top.root}>
          <span css={styles.header.top.date}>
            {getFormattedTripPeriod("DD MMM YYYY")}
          </span>
          <div css={styles.header.top.right.root}>
            <span css={styles.header.top.right.traveler}>
              <strong>Viajante:</strong> {travel.traveler}
            </span>
            <div css={styles.header.top.right.actions}>
              {isDeleteOptionVisible ? (
                <Tooltip content="Deletar" position="top">
                  <button
                    css={styles.header.top.right.button}
                    onClick={handleDeleteTravel}
                  >
                    <Icon size={16} use="trash" />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
        <div css={styles.header.bottom.root}>
          {travel.name ? (
            <span css={styles.header.bottom.name}>{travel.name}</span>
          ) : (
            <span css={styles.header.bottom.placeholder}>-</span>
          )}
        </div>
      </div>
      <ul css={styles.offers}>
        {travel.tripOffers.length > 0 ? (
          travel.tripOffers.map((offer) => (
            <React.Fragment key={offer.offerId}>
              {offer.type === SERVICE_TYPES.bus ? (
                <TripCardBusOffer offer={offer} />
              ) : null}
              {offer.type === SERVICE_TYPES.car ? (
                <TripCardCarOffer offer={offer} />
              ) : null}
              {offer.type === SERVICE_TYPES.flight ? (
                <TripCardFlightOffer offer={offer} />
              ) : null}
              {offer.type === SERVICE_TYPES.hotel ? (
                <TripCardHotelOffer offer={offer} />
              ) : null}
            </React.Fragment>
          ))
        ) : (
          <div css={styles.empty}>Nenhuma oferta adicionada ainda...</div>
        )}
      </ul>
      <TripCardTags travelToken={travel.travelId} />
      <div css={styles.footer.root}>
        <div css={styles.footer.left.root}>
          <CopyIconButtonWithTooltip text={travel.travelId} />
          <span>ID: {travel.travelId}</span>
        </div>
        <div css={styles.footer.right.root({})}>Ir para a viagem</div>
      </div>
    </Link>
  );
};

export const TripCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <div css={styles.header.top.root}>
          <span css={styles.header.top.date}>
            <Skeleton height="14px" width="192px" />
          </span>
          <div css={styles.header.top.right.root}>
            <span css={styles.header.top.right.traveler}>
              <Skeleton height="14px" width="192px" />
            </span>
            <div css={styles.header.top.right.actions}>
              <Skeleton height="16px" width="16px" />
            </div>
          </div>
        </div>
        <div css={styles.header.bottom.root}>
          <Skeleton height="20px" width="256px" />
        </div>
      </div>
      <div css={styles.offers}>
        <Skeleton height="32px" width="384px" />
      </div>
      <div css={styles.footer.root}>
        <div css={styles.footer.left.root}>
          <button css={styles.footer.left.button} disabled>
            <Icon size={16} use="clipboard-document" />
          </button>
          <Skeleton height="14px" width="96px" />
        </div>
        <div
          css={styles.footer.right.root({
            disabled: true,
          })}
        >
          Ir para a viagem
        </div>
      </div>
    </div>
  );
};
