import React from "react";

import DoneIcon from "@material-ui/icons/DoneAllRounded";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

const styles = {
  icon: css({
    color: "success",
    fontSize: [70, 80]
  })
};

const SubmittedMessage = () => {
  return (
    <Box height={[500]} width="100%">
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Flex alignItems="center">
          <Box mr="medium">
            <DoneIcon css={styles.icon} />
          </Box>
          <Box>
            <Flex flexDirection="column">
              <Text
                fontSize={[24, 30]}
                color={defaultTheme.subTextColor}
                fontWeight="bold"
                mb="small"
              >
                Avaliação enviada!
              </Text>
              <Text fontSize={[1, 18]} color={defaultTheme.subTextColor}>
                Obrigado por nos dar seu feedback 😀
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export { SubmittedMessage };
