import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { OfferStatus } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

type Props = {
  freeCancelationUntil?: string;
  isCancelable?: boolean;
  message?: string;
  serviceStatus: OfferStatus;
};

export const ItineraryServiceCancelationUntilBadge: React.FC<Props> = ({
  freeCancelationUntil,
  isCancelable,
  message,
  serviceStatus,
}) => {
  const showMessage =
    serviceStatus !== OfferStatus.BOOKING_FAILED &&
    serviceStatus !== OfferStatus.CANCELED;

  if (!freeCancelationUntil || !isCancelable || !showMessage) {
    return null;
  }

  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="money-withdraw" />
      <span css={styles.text}>
        Cancelamento grátis até {freeCancelationUntil} {message ? message : ""}.
      </span>
    </div>
  );
};
