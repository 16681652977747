import { useEffect, useRef, useState } from "react";

import { useDebouncedCallback } from "~/apps/shared/hooks/use-debounced-callback";

type FilterableListProps<T> = {
  children: (props: FilterableListRenderProps<T>) => JSX.Element;
  data: T[];
  filterData: (searchValue: string, data: T[]) => T[];
};

type FilterableListRenderProps<T> = {
  filteredList: T[];
  onFilter: (value: string) => void;
  searchValue: string;
};

export function FilterableList<T = unknown>(props: FilterableListProps<T>) {
  const { data, filterData, children } = props;

  const [filteredList, setFilteredList] = useState<T[]>(data);
  const [searchValue, setSearchValue] = useState("");

  const _filterData = useRef(filterData);

  const onFilter = useDebouncedCallback((filter) => {
    setSearchValue(filter);

    if (filter) {
      const results = _filterData.current(filter, data);

      setFilteredList(results);

      return;
    }

    setFilteredList(data);
  }, 500);

  useEffect(() => {
    onFilter(searchValue);
  }, [onFilter, searchValue]);

  useEffect(() => {
    setFilteredList(data);
  }, [data]);

  return children({
    filteredList,
    onFilter,
    searchValue,
  });
}
