import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  baggage: {
    root: css({
      alignItems: "center",
      color: theme.colors.gray[300],
      display: "flex",
      gap: "4px",
    }),
    text: css({
      fontSize: "0.75rem",
      lineHeight: "15px",
      whiteSpace: "nowrap",
    }),
  },
  carrier: {
    image: css({
      borderRadius: "8px",
    }),
  },
  info: {
    duration: css({
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      whiteSpace: "nowrap",
    }),
    stops: ({ direct }: { direct: boolean }) =>
      css({
        color: direct ? theme.colors.green[500] : theme.colors.gray[300],
        fontSize: "0.75rem",
      }),
  },
  itinerary: {
    item: {
      bottom: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      diff: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        left: "calc(100% + 4px)",
        position: "absolute",
        top: "1px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        position: "relative",
      }),
      top: css({
        color: theme.colors.gray[700],
        fontSize: "1.125rem",
        fontWeight: 700,
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "16px",
      paddingRight: "16px",
      width: "fit-content",
    }),
    stops: {
      dot: css({
        backgroundColor: theme.colors.pink[500],
        borderRadius: "2px",
        maxHeight: "4px",
        maxWidth: "4px",
        minHeight: "4px",
        minWidth: "4px",
        transform: "translateY(-1.5px)",
      }),
      root: css({
        alignItems: "flex-start",
        backgroundColor: theme.colors.gray[300],
        display: "flex",
        height: "1px",
        justifyContent: "space-between",
        maxWidth: "96px",
        minWidth: "48px",
        position: "relative",
        width: "100%",
      }),
      stop: {
        code: css({
          color: theme.colors.gray[300],
          fontSize: "0.625rem",
          fontWeight: 600,
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          gap: "4px",
          padding: "8px",
          transform: "translateY(-8px)",
        }),
      },
    },
  },
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "16px 24px",
    flexWrap: "wrap",
  }),
};
