import { css } from "@styled-system/css";
import banner from "~/apps/shared/assets/new-trip/banner.jpeg";

import { theme } from "@skin/v2";

export const styles = {
  banner: css({
    backgroundImage: `url(${banner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderBottom: `1px solid ${theme.colors.gray[100]}`,
    height: "300px",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    zIndex: -1,
  }),
  container: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "24px",
    margin: "0 auto",
    maxWidth: "1440px",
    padding: "16px 80px 80px 80px",
    width: "100%",
    zIndex: 1,
    "@media screen and (max-width: 1280px)": {
      padding: "24px 24px 24px 24px",
    },
    "@media screen and (max-width: 992px)": {
      padding: "24px",
    },
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
  layout: css({
    backgroundColor: theme.colors.background.gray,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    width: "100%",
    zIndex: 0,
  }),
};
