import { useCallback, useEffect, useState } from "react";
import { CellMeasurerCache, List } from "react-virtualized";

import { logger } from "../utils/logger";

export const useUpdatedVirtualizedGrid = (
  cache: CellMeasurerCache,
  listRef: React.RefObject<List>,
) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const updateVirtualizedGrid = useCallback(() => {
    if (!listRef.current) {
      return;
    }

    cache.clearAll();
    listRef.current.forceUpdateGrid();
  }, [cache, listRef]);

  useEffect(() => {
    setTimeout(() => {
      updateVirtualizedGrid();

      logger.info("forced update on virtualized grid");
    });
  });

  useEffect(() => {
    const updateVirtualizedGridInterval = setInterval(() => {
      if (!listRef.current) {
        return;
      }

      const {
        props: { scrollTop },
      } = listRef.current;

      if (scrollTop === undefined) {
        return;
      }

      if (lastScrollTop === scrollTop) {
        return;
      }

      setLastScrollTop(scrollTop);
      updateVirtualizedGrid();

      logger.info("forced update on virtualized grid due to scroll difference");
    }, 1000);

    return () => {
      clearInterval(updateVirtualizedGridInterval);
    };
  }, [lastScrollTop, listRef, updateVirtualizedGrid]);
};
