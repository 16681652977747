import React, { useCallback, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  WindowScroller,
} from "react-virtualized";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";
import { NoResultsWarning } from "~/apps/shared/components/no-results-warning/no-results-warning";
import { Select } from "~/apps/shared/components/select/select";
import { HOTEL_SORT_OPTIONS } from "~/apps/shared/constants";
import { useUpdatedVirtualizedGrid } from "~/apps/shared/hooks/use-updated-virtualized-grid";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import { Option } from "~/apps/shared/types";

import { useHotels } from "../../hotels.context";
import { ContentFilters } from "../content-filters/content-filters";
import { HotelCard, HotelCardSkeleton } from "./hotel-card/hotel-card";
import { HotelFavoriteDialog } from "./hotel-favorite-dialog/hotel-favorite-dialog";
import { HotelOutOfPolicyDialog } from "./hotel-out-of-policy-dialog/hotel-out-of-policy-dialog";
import { styles } from "./styles";

const DEFAULT_SORT_FILTERS: Option<
  typeof HOTEL_SORT_OPTIONS[keyof typeof HOTEL_SORT_OPTIONS]
>[] = [
  {
    label: "Menor distância",
    value: "DISTANCE",
  },
  {
    label: "Menor preço",
    value: "PRICE",
  },
];

export const ContentList: React.FC = () => {
  const {
    debounceNameFilter,
    filtering,
    handleChangeSortOption,
    handleShowOutOfPolicyDialog,
    hotelNameFilter,
    hotels,
    isLoading,
    searchInfo,
    selectedHotelToFavourite,
    sortedBy,
    travelInfo,
    visibleHotels: { alternativeHotels, favouriteHotels },
  } = useHotels();

  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 1024, [windowWidth]);

  const [search, setSearch] = useState(hotelNameFilter || "");

  const cache = new CellMeasurerCache({
    fixedWidth: true,
  });

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);

      debounceNameFilter(value);
    },
    [debounceNameFilter, setSearch],
  );

  const handleSortChange = useCallback(
    (
      sortOption: typeof HOTEL_SORT_OPTIONS[keyof typeof HOTEL_SORT_OPTIONS],
    ) => {
      if (sortedBy === sortOption) {
        return;
      }

      handleChangeSortOption(sortOption);
    },
    [handleChangeSortOption, sortedBy],
  );

  const listRef = useRef<List>(null);

  const visibleHotels = useMemo(
    () => [...favouriteHotels, ...alternativeHotels],
    [alternativeHotels, favouriteHotels],
  );

  // eslint-disable-next-line react/display-name
  const renderHotel = (width: number): ListRowRenderer => ({
    index,
    key,
    parent,
    style,
  }) => {
    const hotel = visibleHotels[index];

    const shouldShowDivisory =
      favouriteHotels.length > 0 &&
      alternativeHotels.length > 0 &&
      index + 1 === favouriteHotels.length;

    const offersLink = `/travels/${travelInfo?.travelToken}/search/hotels/${hotel.hotelToken}/result/${searchInfo?.hotelToken}`;

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={{ ...style, width }}>
          <HotelCard
            handleShowOutOfPolicyDialog={handleShowOutOfPolicyDialog}
            hotel={hotel}
            offersLink={offersLink}
            totalGuests={searchInfo!.totalGuests}
          />
          {shouldShowDivisory ? (
            <div css={styles.list.groups.divisor}>
              <span>Hotéis alternativos:</span>
            </div>
          ) : null}
        </div>
      </CellMeasurer>
    );
  };

  const stats = useMemo(() => {
    const count = visibleHotels.length;

    return filtering
      ? "Filtrando..."
      : `${count} ${count === 1 ? "resultado" : "resultados"}${
          !isMobile ? " de hotel para sua pesquisa" : ""
        }`;
  }, [filtering, visibleHotels]);

  useUpdatedVirtualizedGrid(cache, listRef);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.controls.root}>
          <div css={styles.controls.search.root({ disabled: isLoading })}>
            <Icon
              css={styles.controls.search.icon}
              size={16}
              use="search-outline"
            />
            <Input
              css={styles.controls.search.input}
              disabled={isLoading}
              onChange={handleSearchChange}
              placeholder="Filtre por nome ou endereço..."
              value={search}
            />
          </div>
          <div css={styles.controls.select.root}>
            <Select
              css={styles.controls.select.select}
              isDisabled={isLoading}
              onChange={({ value }) => {
                handleSortChange(
                  value as typeof HOTEL_SORT_OPTIONS[keyof typeof HOTEL_SORT_OPTIONS],
                );
              }}
              options={DEFAULT_SORT_FILTERS}
              value={(() => {
                const sortedByFilter = DEFAULT_SORT_FILTERS.find(
                  (sortFilter) => sortFilter.value === sortedBy,
                );

                if (!sortedByFilter) {
                  return undefined;
                }

                // return {
                //   ...sortedByFilter,
                //   label: isMobile ? (
                //     <Icon
                //       css={styles.controls.select.icon}
                //       size={20}
                //       use="bars-3-bottom-right"
                //     />
                //   ) : (
                //     <>
                //       <span css={styles.controls.select.label}>
                //         Ordenar por:&nbsp;
                //       </span>
                //       {sortedByFilter.label}
                //     </>
                //   )
                // };

                return {
                  ...sortedByFilter,
                  label: (
                    <>
                      {isMobile ? (
                        <Icon
                          css={styles.controls.select.icon}
                          size={20}
                          use="bars-3-bottom-right"
                        />
                      ) : (
                        <span css={styles.controls.select.label}>
                          Ordenar por:&nbsp;
                        </span>
                      )}
                      {sortedByFilter.label}
                    </>
                  ),
                };
              })()}
            />
          </div>
        </div>
        <div css={styles.stats.root}>
          <span css={styles.stats.count}>
            {!isLoading ? (
              stats
            ) : (
              <Skeleton height="16px" width={!isMobile ? "288px" : "96px"} />
            )}
          </span>
          {!isMobile ? null : <ContentFilters isMobile={isMobile} />}
        </div>
        {!isLoading ? (
          <WindowScroller>
            {({ height, isScrolling, registerChild, scrollTop }) => (
              <div style={{ flex: "1 1 auto" }}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      {favouriteHotels.length > 0 ? (
                        <div css={styles.list.groups.divisor} style={{ width }}>
                          <span>Hotéis favoritos:</span>
                        </div>
                      ) : null}
                      <div ref={registerChild}>
                        <List
                          autoHeight
                          deferredMeasurementCache={cache}
                          height={height}
                          isScrolling={isScrolling}
                          noRowsRenderer={() => (
                            <div css={styles.empty.root}>
                              <NoResultsWarning
                                message={
                                  hotels.length === 0 ? (
                                    <>
                                      <p>
                                        Ops, parece que não encontramos nenhum
                                        hotel para a sua pesquisa em nossos
                                        fornecedores.
                                      </p>
                                      <p css={styles.empty.text}>
                                        Pedimos deculpa pelo transtorno. Por
                                        ora, recomendamos que entre em contato
                                        com nossa equipe de atendimento para que
                                        possamos encontrar um hotel para você.
                                      </p>
                                    </>
                                  ) : hotels.length > 0 &&
                                    visibleHotels.length === 0 ? (
                                    "Nenhum hotel encontrado para filtro o selecionado..."
                                  ) : null
                                }
                              />
                            </div>
                          )}
                          overscanRowCount={8}
                          ref={listRef}
                          rowCount={visibleHotels.length}
                          rowHeight={cache.rowHeight}
                          rowRenderer={renderHotel(width)}
                          scrollTop={scrollTop}
                          style={{ outline: "none" }}
                          width={width}
                        />
                      </div>
                    </div>
                  )}
                </AutoSizer>
              </div>
            )}
          </WindowScroller>
        ) : (
          Array(4)
            .fill(0)
            .map((_, index) => <HotelCardSkeleton key={index} />)
        )}
      </div>
      {selectedHotelToFavourite ? (
        <HotelFavoriteDialog hotel={selectedHotelToFavourite} />
      ) : null}
      <HotelOutOfPolicyDialog />
    </>
  );
};
