import { useMemo } from "react";

import { useItineraryContainer } from "../../../../itinerary.container";
import { DocumentationAccordionPresenterFactory } from "./documentation-accordion.presenter";

export const useDocumentationAccordionPresenter = () => {
  const { infoModel, pendenciesModel } = useItineraryContainer();

  return useMemo(
    () => ({
      documentationAccordionPresenter:
        infoModel && pendenciesModel
          ? DocumentationAccordionPresenterFactory.create(
              infoModel,
              pendenciesModel,
            )
          : null,
    }),
    [infoModel, pendenciesModel],
  );
};
