import GoogleMap from "google-map-react";
import React from "react";

import { MapTheme, MAP_THEMES } from "./styles";
export { MapTheme, MAP_THEMES } from "./styles";

type Props = {
  height: string | number;
  markers?: (React.JSX.Element | null)[];
  theme: MapTheme;
};

export const CustomMap: React.FC<Props> = ({ height, markers, theme }) => {
  return (
    <div
      style={{
        borderRadius: 4,
        overflow: "hidden",
        height,
        width: "100%",
      }}
    >
      <GoogleMap
        defaultCenter={{ lat: 0, lng: 0 }}
        defaultZoom={3}
        options={{
          mapTypeControl: false,
          maxZoom: 15,
          minZoom: 2,
          streetViewControl: false,
          styles: MAP_THEMES[theme],
        }}
      >
        {markers && markers.length > 0 ? markers : null}
      </GoogleMap>
    </div>
  );
};
