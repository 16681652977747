import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    }),
    warning: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
      textAlign: "center",
    }),
  },
  pickers: {
    picker: {
      legend: {
        item: {
          label: css({
            color: theme.colors.gray[700],
            fontSize: "0.75rem",
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "8px",
          }),
          square: ({ color }: { color: string }) =>
            css({
              backgroundColor: color,
              border: `1px solid ${theme.colors.gray[100]}`,
              borderRadius: "2px",
              color: theme.colors.gray[300],
              display: "grid",
              height: "12px",
              placeItems: "center",
              width: "12px",
            }),
        },
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
      },
      parts: {
        icon: css({
          color: theme.colors.gray[300],
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }),
    },
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  }),
  stop: {
    root: css({
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      zIndex: 0,
      "&::before": {
        backgroundColor: theme.colors.gray[100],
        content: '""',
        height: "1px",
        position: "absolute",
        width: "100%",
        zIndex: -1,
      },
    }),
    text: css({
      color: theme.colors.gray[300],
      backgroundColor: theme.colors.white,
      fontSize: "0.875rem",
      padding: "0 12px",
      textAlign: "center",
      zIndex: 1,
    }),
  },
};
