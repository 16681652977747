import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    bottom: {
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    top: {
      name: css({
        fontSize: "1.5rem",
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "16px",
        "@media (max-width: 1280px)": {
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "8px",
        },
      }),
      stars: css({
        color: theme.colors.blue[500],
      }),
    },
  },
  right: {
    button: css({
      padding: "8px 16px",
      "@media (max-width: 640px)": {
        display: "none",
      },
    }),
  },
  root: css({
    alignItems: "flex-start",
    display: "flex",
    gap: "24px",
    justifyContent: "space-between",
    padding: "24px 80px 0 80px",
    "@media (max-width: 1280px)": {
      padding: "24px 24px 0 24px",
    },
    "@media (max-width: 992px)": {
      padding: "16px 16px 0 16px",
    },
    "@media (max-width: 640px)": {
      padding: "16px",
    },
  }),
};
