import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  section: {
    approval: css({
      alignItems: "center",
      backgroundColor: theme.colors.pink[500],
      border: "1px solid",
      borderColor: theme.colors.pink[500],
      borderRadius: "8px",
      color: theme.colors.white,
      display: "flex",
      fontSize: "0.875rem",
      fontWeight: 500,
      gap: "12px",
      justifyContent: "center",
      padding: "14px 24px",
      textDecoration: "none",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        backgroundColor: theme.colors.pink[700],
        borderColor: theme.colors.pink[700],
      },
    }),
    approver: {
      name: css({
        fontWeight: 600,
      }),
      text: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
    },
    approvers: {
      stage: {
        approver: {
          avatar: css({
            minWidth: "32px !important",
            "& > :first-of-type": {
              flexShrink: 0,
              "& > :first-of-type": {
                height: "32px !important",
                width: "32px !important",
              },
            },
          }),
          name: css({
            fontWeight: 600,
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "8px",
          }),
          text: css({
            color: theme.colors.gray[700],
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }),
        title: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
    button: css({
      fontSize: "0.875rem",
      justifyContent: "center",
    }),
    buttons: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
    message: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      lineHeight: "1.125rem",
      wordBreak: "break-word",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    status: {
      status: ({ color }: { color: string }) =>
        css({
          color,
          fontSize: "0.875rem",
          fontWeight: 600,
        }),
      text: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
    },
    strong: css({
      fontWeight: 600,
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    toggle: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[500],
      fontFamily: theme.fonts.inter,
      fontSize: "0.75rem",
      fontWeight: 500,
      textDecoration: "underline",
      transition: "color 0.15s ease-in-out",
      width: "fit-content",
      ":hover": {
        color: theme.colors.gray[700],
      },
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
  },
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
