import React from "react";
import { TElementRef } from "../../../types";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Row = React.forwardRef(
  ({ children, style, ...rest }: Props, ref: TElementRef<HTMLDivElement>) => (
    <div
      {...rest}
      ref={ref}
      style={{
        ...style,
        display: "flex",
        flexDirection: "row"
      }}
    >
      {children}
    </div>
  )
);

export { Row };
