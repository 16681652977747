import React, { useCallback, useEffect, useMemo } from "react";
import { Control, useFieldArray } from "react-hook-form";

import { APPROVAL_PROCESS_TYPES } from "~/constants";

import { ApprovalProcessUsers } from "~/models/approval-process.model";

import { useFormContext, useFormError } from "~/components/shared/form";
import { FormFieldAdditionalText } from "~/components/shared/form/FormField";

import { ApproverAvatarGroup } from "../../../approver-avatar-group/approver-avatar-group";
import { styles } from "./styles";

type Props = {
  actionIndex: number;
  addStage: (value: { stage: number; users: ApprovalProcessUsers[] }) => void;
  control: Control;
  isSingleApprover?: boolean;
  isUniqueApprovalCreation?: boolean;
  removeStage: (index: number) => void;
  ruleIndex: number;
  stageFields: Array<{ stage?: number | null; users?: ApprovalProcessUsers[] }>;
  stageIndex: number;
};

export const ApprovalProcessStage: React.FC<Props> = ({
  actionIndex,
  addStage,
  control,
  isSingleApprover,
  isUniqueApprovalCreation,
  removeStage,
  ruleIndex,
  stageFields,
  stageIndex,
}) => {
  const { fields, append, remove } = useFieldArray<ApprovalProcessUsers>({
    control: control,
    name: `rules[${ruleIndex}].actions[${actionIndex}].stages[${stageIndex}].users`,
  });

  const { watch } = useFormContext();
  const ruleType = watch(`rules[${ruleIndex}].type`);

  const switchApproversAndRecipients = useCallback(() => {
    if (ruleType?.value === APPROVAL_PROCESS_TYPES.NOTIFY) {
      removeStage(1);

      return;
    }

    if (
      ruleType?.value === APPROVAL_PROCESS_TYPES.APPROVE &&
      stageFields.length === 1
    ) {
      addStage({ stage: 2, users: [] });
    }
  }, [addStage, removeStage, ruleType?.value, stageFields.length]);

  useEffect(() => {
    switchApproversAndRecipients();
  }, [switchApproversAndRecipients]);

  const actions = useMemo(() => {
    return {
      addNewApprover: ({
        email,
        fullName,
        userToken,
      }: {
        email: string;
        fullName: string;
        userToken: string;
      }) => {
        append({
          email,
          name: fullName,
          token: userToken,
        });
      },
      removeApprover: (index: number) => remove(index),
    };
  }, [append, remove]);

  const error = useFormError(
    `rules[${ruleIndex}].actions[${actionIndex}].stages[${stageIndex}].users`,
  );

  const isSingleApproval = isSingleApprover || isUniqueApprovalCreation;

  return (
    <div className={styles.approverStagesSpacing}>
      <label style={{ fontSize: "0.875rem", fontWeight: 600 }}>
        {ruleType?.value === APPROVAL_PROCESS_TYPES.NOTIFY
          ? "Destinatários"
          : `Aprovadores Nível ${stageIndex + 1}`}
        {stageIndex === 1 ? (
          <>
            <span
              style={{
                fontWeight: "400",
                color: "gray",
              }}
            >
              {` (Opcional)`}
            </span>
            {` :`}
          </>
        ) : (
          `:`
        )}
      </label>
      {error ? (
        <FormFieldAdditionalText
          className={styles.messageError}
          style={{ marginTop: "2.25rem" }}
          hasError
        >
          {error.message}
        </FormFieldAdditionalText>
      ) : null}
      {(isSingleApproval && stageIndex < 1) || !isSingleApproval ? (
        <ApproverAvatarGroup
          addApprover={actions.addNewApprover}
          fields={fields}
          isSingleApprover={isSingleApprover}
          isUniqueApprovalCreation={isUniqueApprovalCreation}
          removeApprover={actions.removeApprover}
          ruleIndex={ruleIndex}
        />
      ) : null}
    </div>
  );
};
