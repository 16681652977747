import { ReactEditor } from "slate-react";

import { LinkElement } from "~/@types/slate";
import { BaseEditor, Editor, Element, Range, Transforms } from "slate";

import { isURL } from "../../utils/is-url";

export const LIST_TYPES = ["bulleted-list", "numbered-list"];
export const TEXT_ALIGN_TYPES = ["center", "justify", "left", "right"];

export const insertLink = (editor: BaseEditor & ReactEditor, url: string) => {
  if (!editor.selection) {
    return;
  }

  wrapLink(editor, url);
};

export const isBlockButtonActive = (
  editor: BaseEditor & ReactEditor,
  format: string,
  blockType = "type",
) => {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        blockType in node &&
        node[blockType as keyof typeof node] === format,
    }),
  );

  return !!match;
};

export const isLinkButtonActive = (editor: BaseEditor & ReactEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) && Element.isElement(node) && node.type === "link",
  });

  return !!link;
};

export const isMarkButtonActive = (
  editor: BaseEditor & ReactEditor,
  format?: string,
) => {
  if (!format) {
    return false;
  }

  const marks = Editor.marks(editor);

  if (!marks) {
    return false;
  }

  if (!(format in marks)) {
    return false;
  }

  return marks[format as keyof typeof marks];
};

export const toggleBlock = (
  editor: BaseEditor & ReactEditor,
  format: string,
) => {
  const isActive = isBlockButtonActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      Element.isElement(node) &&
      LIST_TYPES.includes(node.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: Partial<Element> & {
    align?: string;
  };

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: (isActive
        ? "paragraph"
        : isList
        ? "list-item"
        : format) as Element["type"],
    };
  }

  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block = {
      children: [] as Element["children"],
      type: format,
    };

    Transforms.wrapNodes(editor, block as Element);
  }
};

export const toggleMark = (
  editor: BaseEditor & ReactEditor,
  format: string,
) => {
  const isActive = isMarkButtonActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const unwrapLink = (editor: BaseEditor & ReactEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) && Element.isElement(node) && node.type === "link",
  });
};

export const withInlines = (editor: BaseEditor & ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    ["link", "button"].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isURL(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isURL(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const wrapLink = (editor: BaseEditor & ReactEditor, url: string) => {
  if (isLinkButtonActive(editor)) {
    unwrapLink(editor);
  }

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = `https://${url}`;
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};
