import React, { MouseEvent } from "react";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutlineRounded";
import { css } from "@styled-system/css";
import { css as oldCss } from "emotion";
import { NumericDictionary } from "lodash";

import { Flex, Box, Text, Paragraph } from "@toolkit";

import { defaultTheme } from "@assets/styles/theme";
import { colors } from "@assets/styles/theme/constants";

import {
  EXPENSE_REPORTS_STATUS_TRANSLATION,
  MEDIA_QUERIES,
  EXPENSE_REPORT_TYPE_TRANSLATION
} from "@constants";

import * as utils from "@helpers";

import { ExpenseReportWithUser } from "@models/expense.model";

import ColoredTag from "@shared/ColoredTag";

import { CoinsTooltip } from "~/components/shared/coins-tooltip";

const styles = {
  root: css({
    flexGrow: 1,
    border: "1px solid",
    borderColor: "border",
    cursor: "pointer",
    transition: "box-shadow 200ms",
    mb: ["medium", "large"],
    "&:hover": {
      boxShadow: "0 4px 6px 0 rgba(211,222,233,0.6)"
    }
  }),
  boldText: css({
    fontSize: [1, 20],
    fontWeight: "bold",
    mb: ["small"],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "1.5rem"
  }),
  deleteButton: css({
    ml: "medium",
    padding: [0, 0, 0],
    borderRadius: 4,
    [".svg"]: {
      fontSize: 24,
      color: defaultTheme.subTextColor
    }
  })
};

const statusTagsStyles: NumericDictionary<string> = {
  "1": oldCss({
    color: `${colors.slateContrastGray} !important`,
    backgroundColor: `${colors.botticelli} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "2": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.successTextColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "3": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.secondaryColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "99": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${colors.brinkPink} !important`,
    wiminWidthdth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0
    }
  }),
  "100": oldCss({
    color: `${colors.white} !important`,
    backgroundColor: `${defaultTheme.primaryColor} !important`,
    minWidth: 85,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      minWidth: 0,
      width: 142
    }
  })
};

interface Props {
  report: ExpenseReportWithUser;
  totalPrice: number;
  formattedPeriod: string;
  allowRemoval: boolean;
  hasForeignCurrencyExpenses: boolean;
  onClick(): void;
  handleRemove?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const ReportItemPresentational = ({
  report,
  totalPrice,
  formattedPeriod,
  allowRemoval,
  hasForeignCurrencyExpenses,
  onClick,
  handleRemove
}: Props) => {
  return (
    <Box css={styles.root} onClick={onClick}>
      <Box width="max-content" p={["small", "16px 24px 0"]} display={"flex"}>
        <ColoredTag
          tagStyle={statusTagsStyles[report.status]}
          isUppercase={false}
        >
          {EXPENSE_REPORTS_STATUS_TRANSLATION[report.status]}
        </ColoredTag>
        <Paragraph
          fontSize={["12px"]}
          mb={["small", "medium"]}
          color={defaultTheme.subTextColor}
          letterSpacing={"0.04rem"}
          margin={"3px 0px 0px 7px"}
        >
          <b>Tipo: </b>
          {EXPENSE_REPORT_TYPE_TRANSLATION[report.reportType]}
        </Paragraph>
      </Box>
      <Flex
        py={["small", "medium"]}
        px={["small", 24]}
        justifyContent={"space-between"}
        alignItems="baseline"
      >
        <Flex flexDirection="column" maxWidth="50%">
          <Text fontSize={[0, 2]} mb={["small", "medium"]}>
            {formattedPeriod}
          </Text>
          <Text css={styles.boldText}>
            {report.description || "Relatório sem descrição"}
          </Text>
        </Flex>
        <Box textAlign="right" maxWidth="50%">
          <Paragraph fontSize={[0, 2]} mb={["small", "medium"]}>
            Viajante
          </Paragraph>
          <Text css={styles.boldText}>
            {report?.user?.fullName || "Usuário inativo"}
          </Text>
        </Box>
      </Flex>
      <Flex
        py={["small", "medium"]}
        px={["small", 24]}
        justifyContent={"space-between"}
        alignItems="center"
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius={0}
        backgroundColor="rgba(0, 0, 0, 0.02)"
      >
        <Text fontSize={[0, 2]}>
          <strong>ID do relatório:</strong> {report.expenseReportToken}
        </Text>
        <Flex alignItems="center">
          <Text fontSize={[0, 2]}>
            <strong>Valor total:</strong> {utils.toCurrency(totalPrice)}
          </Text>
          {allowRemoval && (
            <IconButton
              aria-label="Delete"
              onClick={handleRemove}
              css={styles.deleteButton}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export { ReportItemPresentational };
