import * as loyaltyProgramApi from "~/apps/corporate/apis/loyalty-program.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import {
  SaveLoyaltyProgramDto,
  SaveLoyaltyProgramRequestBody,
} from "../dtos/loyalty-program.dto";
import { LoyaltyProgram } from "../models/loyalty-program.model";

export const createLoyaltyProgram = async (data: SaveLoyaltyProgramDto) => {
  const result: { data?: LoyaltyProgram; error?: Error } = {};

  try {
    const saveLoyaltyProgramRequestBody: SaveLoyaltyProgramRequestBody = {
      code: data.code,
      name: data.name,
    };

    result.data = await loyaltyProgramApi.createLoyaltyProgram(
      saveLoyaltyProgramRequestBody,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR;
    }
  }
  return result;
};

export const editLoyaltyProgram = async (
  data: SaveLoyaltyProgramDto,
  loyaltyProgramToken: string,
) => {
  const result: { data?: LoyaltyProgram; error?: Error } = {};

  try {
    const saveLoyaltyProgramRequestBody: SaveLoyaltyProgramRequestBody = {
      code: data.code,
      name: data.name,
    };

    result.data = await loyaltyProgramApi.updateLoyaltyProgram(
      saveLoyaltyProgramRequestBody,
      loyaltyProgramToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR;
    }
  }

  return result;
};

export const getUserLoyaltyPrograms = async () => {
  const result: { data?: LoyaltyProgram[]; error?: Error } = {};

  try {
    result.data = await loyaltyProgramApi.getUserLoyaltyPrograms();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR;
    }
  }

  return result;
};

export const inactiveLoyaltyProgram = async (loyaltyProgramToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await loyaltyProgramApi.inactiveLoyaltyProgram(
      loyaltyProgramToken,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR;
    }
  }

  return result;
};
