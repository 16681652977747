import React, { useContext } from "react";

import { RouteComponentProps } from "@reach/router";

import { Box, Paragraph } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { StContainedButton } from "@shared/buttons";
import { Desktop } from "@shared/layout/Responsive";
import SpinnerPortal from "@shared/Spinner";

import PageTitle from "../../shared/PageTitle";
import { ExpensesContext, ExpensesProvider } from "../Expenses.context";
import { ReportSelectDrawer } from "../ReportSelectDrawer/ReportSelectDrawer";
import { NewReportDialog } from "../shared/NewReportDialog";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { AdvancedExpenseItem } from "./AdvancedExpenseItem";
import {
  AdvancedExpensesProvider,
  useAdvancedExpensesContext
} from "./AdvancedExpenses.context";
import { AdvancedExpense } from "./AdvancedExpenses.types";
import StatusDialog from "./AdvancedExpenseStatusDialog/StatusDialog";
import { AdvancedExpenseDrawer } from "./Form/AdvancedExpenseDrawer";

const AdvancedExpenses = (_: RouteComponentProps) => {
  const {
    visibleAdvancedExpenses,
    userHasApprovalRule,
    isLoading,
    fetchAdvancedExpenses,
    checkUserHasApprovalRule,
    handleSearch,
    handleOpenForm,
    handleOpenItemStatus,
    handleCreateReportWithAdvancedExpense,
    defineSelectedAdvancedExpense
  } = useAdvancedExpensesContext();

  const {
    isReportCreationDialogOpen,
    isSubmitingReportCreation,
    handleCloseReportCreationDialog,
    handleOpenReportListDrawer
  } = useContext(ExpensesContext);

  React.useEffect(() => {
    fetchAdvancedExpenses();
    checkUserHasApprovalRule();
  }, [fetchAdvancedExpenses, checkUserHasApprovalRule]);

  const addToReport = React.useCallback(
    (
      expenseAdvanceToken: string,
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      e.stopPropagation();
      defineSelectedAdvancedExpense(expenseAdvanceToken);
      handleOpenReportListDrawer();
    },
    []
  );

  return (
    <div>
      <SpinnerPortal visible={isLoading} />
      <Box>
        <PageTitle title="Meus adiantamentos" />
      </Box>
      <Box
        display={["block", "flex"]}
        mb="0.5rem"
        alignItems={["flex-start", "center"]}
      >
        <Box mr={["0.5rem", "1rem"]}>
          <StContainedButton
            onClick={handleOpenForm}
            isFullWidth={false}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            disabled={!userHasApprovalRule}
          >
            Solicitar adiantamento
          </StContainedButton>
        </Box>
        {userHasApprovalRule === false && (
          <Paragraph
            color={defaultTheme.subTextColor}
            fontSize={[11, 12]}
            mt={["0.5rem", 0]}
            lineHeight="body"
            maxWidth={["280px", "310px"]}
          >
            É necessário estar atrelado a um fluxo de aprovação de adiantamentos
            para solicitar um adiantamento.
          </Paragraph>
        )}
      </Box>
      <Desktop>
        <Box mt="1.5rem" mb="2rem" maxWidth={350}>
          <SearchFilterInput
            containerClasses=""
            placeholder="Buscar por descrição"
            onChange={handleSearch}
          />
        </Box>
      </Desktop>
      <Box>
        {visibleAdvancedExpenses.map((item: AdvancedExpense) => (
          <AdvancedExpenseItem
            item={item}
            key={item.expenseAdvanceToken}
            handleOpenDetails={handleOpenItemStatus}
            addToReport={addToReport}
          />
        ))}
      </Box>
      {!visibleAdvancedExpenses.length && isLoading === false ? (
        <Box fontSize={[12, 14]} lineHeight="body" mt={["2rem", "1rem"]}>
          Nenhum adiantamento pendente, utilize o botão acima para criar novos
          adiantamentos
        </Box>
      ) : null}
      <AdvancedExpenseDrawer />
      <StatusDialog />
      <ReportSelectDrawer type="advanced-expense" />
      <NewReportDialog
        open={isReportCreationDialogOpen}
        isSubmiting={isSubmitingReportCreation}
        handleClose={handleCloseReportCreationDialog}
        handleCreate={handleCreateReportWithAdvancedExpense}
      />
    </div>
  );
};

const Container = (props: RouteComponentProps) => (
  <ExpensesProvider>
    <AdvancedExpensesProvider>
      <AdvancedExpenses {...props} />
    </AdvancedExpensesProvider>
  </ExpensesProvider>
);

export { Container as AdvancedExpenses };
