import moment from "moment";

import { UserModel } from "../user.model";

export type ItineraryInfo = {
  administrativeInfo: {
    billingProfileToken: string | null;
    companyAreaToken: string | null;
    costCenterToken: string | null;
    projectToken: string | null;
  };
  booker: {
    email: string;
    name: string;
    phone: string | null;
    token: string;
  };
  eventToken?: string;
  flightPurposeToken: string | null;
  hasAnyManuallyAprovedOfferNotProcessed: boolean;
  hotelPurposeToken: string | null;
  isDeletable: boolean;
  isPossibleToAddOrAlterOffers: boolean;
  isProcessable: boolean;
  travelEnd: string;
  traveler: {
    email: string;
    name: string;
    phone: string | null;
    token: string;
  };
  travelName: string;
  travelStart: string;
  travelToken: string;
  tripPurposeToken: string | null;
};

export class ItineraryInfoModel {
  constructor(protected info: ItineraryInfo) {}

  public doesTravelStartAfterSendVoucherEmailReferenceDate() {
    const referenceDate = moment("2023-03-07T17:00:00");
    const travelStart = this.getTravelStart();

    return travelStart.isSameOrAfter(referenceDate);
  }

  public getBillingProfileToken() {
    return this.info.administrativeInfo.billingProfileToken;
  }

  public getBooker() {
    return this.info.booker;
  }

  public getCompanyAreaToken() {
    return this.info.administrativeInfo.companyAreaToken;
  }

  public getCostCenterToken() {
    return this.info.administrativeInfo.costCenterToken;
  }

  public getEventToken() {
    const eventToken = this.info.eventToken;

    if (!eventToken) {
      return null;
    }

    return eventToken;
  }

  public getFlightPurposeToken() {
    return this.info.flightPurposeToken;
  }

  public getHotelPurposeToken() {
    return this.info.hotelPurposeToken;
  }

  public getPeriod() {
    const endDate = moment(this.info.travelEnd).format("ddd, DD MMM YYYY");
    const startDate = moment(this.info.travelStart).format("ddd, DD MMM YYYY");

    return `${startDate} - ${endDate}`;
  }

  public getProjectToken() {
    return this.info.administrativeInfo.projectToken;
  }

  public getTravelEnd() {
    return moment(this.info.travelEnd);
  }

  public getTraveler() {
    return this.info.traveler;
  }

  public getTravelerToken() {
    return this.info.traveler.token;
  }

  public getTravelName() {
    return this.info.travelName ? this.info.travelName : null;
  }

  public getTravelStart() {
    return moment(this.info.travelStart);
  }

  public getTravelToken() {
    return this.info.travelToken;
  }

  public getTripPurposeToken() {
    return this.info.tripPurposeToken;
  }

  public isDeletable() {
    return this.info.isDeletable;
  }

  public isPossibleToAddOrAlterOffers() {
    return this.info.isPossibleToAddOrAlterOffers;
  }

  public isUserBooker(user: UserModel) {
    return this.info.booker.token === user.getUserToken();
  }

  public isUserTraveler(user: UserModel) {
    return this.info.traveler.token === user.getUserToken();
  }

  public setTravelName(travelName: string) {
    this.info.travelName = travelName;

    return this;
  }
}

export class ItineraryInfoModelFactory {
  public static create(info: ItineraryInfo) {
    return new ItineraryInfoModel(info);
  }
}
