import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { Bound } from "~/apps/corporate/models/flight.model";
import flightFallback from "~/apps/shared/assets/images/fallbacks/flight-fallback.png";
import { PDFIcon } from "~/apps/shared/components/pdf-icon/pdf-icon";
import { PDFRemoteImage } from "~/apps/shared/components/pdf-remote-image/pdf-remote-image";
import { formatFlightDuration } from "~/apps/shared/utils/format-flight-duration";

import { styles } from "./styles";

type Props = {
  arrival: {
    airportCode: string;
    time: string;
  };
  baggage?: {
    carryOn: {
      included: boolean;
      quantity: number;
      weight: {
        unit: string;
        value: number;
      };
    };
    checked: {
      included: boolean;
      quantity: number;
      weight: {
        unit: string;
        value: number;
      };
    };
  };
  carrier: {
    imageUrl: string;
    name: string;
  };
  connection?: {};
  daysDiff: number;
  departure: {
    airportCode: string;
    time: string;
  };
  duration: string;
  stops?: Bound["stops"];
};

export const TravelPlanPDFFlightSegment: React.FC<Props> = ({
  arrival,
  baggage,
  carrier,
  stops,
  daysDiff,
  departure,
  duration,
}) => {
  const baggageStyles = styles.baggage();
  const infoStyles = styles.info();
  const itemStyles = styles.item();
  const rootStyles = styles.root();
  const stopStyles = styles.stop();
  const stopsStyles = styles.stops();

  const includedBaggage = baggage
    ? baggage.carryOn.included
      ? baggage.carryOn
      : baggage.checked.included
      ? baggage.checked
      : null
    : null;

  return (
    <View style={rootStyles.root}>
      <PDFRemoteImage
        fallbackSrc={flightFallback}
        src={carrier.imageUrl}
        style={rootStyles.carrier}
      />
      <View style={itemStyles.container}>
        <View style={itemStyles.root}>
          <View style={itemStyles.top}>
            <Text>{departure.time}</Text>
          </View>
          <View style={itemStyles.bottom}>
            <Text>{departure.airportCode}</Text>
          </View>
        </View>
        <View style={stopsStyles.root}>
          <View style={stopsStyles.dot} />
          {stops
            ? stops.map((stop, index) => {
                const airportCode = stop.code;

                return (
                  <View style={stopStyles.root} key={index}>
                    <View style={stopsStyles.dot} />
                    <View style={stopStyles.code}>
                      <Text>{airportCode}</Text>
                    </View>
                  </View>
                );
              })
            : null}
          <View style={stopsStyles.dot} />
        </View>
        <View style={itemStyles.root}>
          <View style={itemStyles.top}>
            <Text>{arrival.time}</Text>
          </View>
          <View style={itemStyles.bottom}>
            {daysDiff > 0 ? <Text>+{daysDiff}</Text> : null}
          </View>
        </View>
      </View>
      <View style={infoStyles.root}>
        <View style={infoStyles.duration}>
          <Text>{formatFlightDuration(duration)}</Text>
        </View>
        <View style={infoStyles.stops}>
          {stops ? (
            <Text>
              {stops.length > 1
                ? `${stops.length} conexões`
                : `${stops.length} conexão`}
            </Text>
          ) : (
            <Text>Direto</Text>
          )}
        </View>
      </View>
      {includedBaggage ? (
        includedBaggage.included ? (
          <View style={baggageStyles.root}>
            <PDFIcon size={16} use="baggage" />
            <View style={baggageStyles.text}>
              <Text>
                {includedBaggage.quantity > 1 ? includedBaggage.quantity : 1}
              </Text>
            </View>
          </View>
        ) : null
      ) : null}
    </View>
  );
};
