import React from "react";

import { styles } from "./styles";

export const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ ...props }, ref) => {
  return <input css={styles.root} ref={ref} {...props} />;
});

Input.displayName = "Input";
