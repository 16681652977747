import React from "react";

import { navigate } from "@reach/router";

import { ErrorWarning } from "../error-warning/error-warning";
import { styles } from "./styles";

type Props = {
  navigateMessage?: string;
  navigateTo?: () => void;
  title: React.ReactNode;
} & (
  | {
      description: React.ReactNode;
      message?: never;
    }
  | {
      description?: never;
      message: React.ReactNode;
    }
);

export const ErrorPage: React.FC<Props> = ({
  navigateMessage = "Voltar",
  navigateTo = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigate(-1);
  },
  title,
  ...props
}) => {
  return (
    <div css={styles.root}>
      <ErrorWarning
        message={props.message ? props.message : props.description}
        navigateMessage={navigateMessage}
        navigateTo={navigateTo}
        title={title}
      />
    </div>
  );
};
