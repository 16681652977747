import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";

import { api } from "../../shared/services/api";
import { DownloadTravelInvoicesResponse } from "../dtos/financial.dto";
import * as financialParser from "../parsers/financial.parser";

export async function downloadTravelInvoices(travelToken: string) {
  return await api
    .request<DownloadTravelInvoicesResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/financial/travels/${travelToken}/download`,
    })
    .then(({ data }) => financialParser.parseDownloadTravelInvoices(data));
}
