import React from "react";

import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { scrollToElementById } from "~/apps/shared/utils/scroll-to-element-by-id";

import { Badge, Button } from "@toolkit/v2";

import { useItineraryApproval } from "../../itinerary/itinerary-approval.context";
import { useItineraryScreen } from "../../itinerary/itinerary-screen.context";
import { useItineraryServices } from "../../itinerary/itinerary-services.context";
import { useItineraryContainer } from "../../itinerary/itinerary.container";
import { usePaymentContainer } from "../payment.container";
import { usePayment } from "../payment.context";
import { styles } from "./styles";

export const PaymentActionDialog: React.FC = () => {
  const {
    offersAvailabilitiesModel,
    offersChangesModel,
  } = useItineraryContainer();
  const { travelPaymentModel } = usePaymentContainer();

  const { travelApprovalHistory } = useItineraryApproval();
  const {
    closePaymentActionDialog,
    isPaymentActionDialogOpen,
  } = useItineraryScreen();
  const { errorOnFetchOffersAvailabilities } = useItineraryServices();

  const { errorOnBook, isLoadingApprovalDeny } = usePayment();

  if (!travelApprovalHistory || !travelPaymentModel) {
    return null;
  }

  const travelPaymentServices = travelPaymentModel.getAllServices();

  if (errorOnBook) {
    return (
      <Dialog
        onClose={closePaymentActionDialog}
        open={isPaymentActionDialogOpen}
      >
        <DialogHeader
          icon="x"
          onClose={closePaymentActionDialog}
          variant="pink"
        />
        <DialogContent>
          <DialogTitle variant="pink">
            Erro ao processar o pagamento.
          </DialogTitle>
          <span css={styles.message}>
            {errorOnBook.description
              ? errorOnBook.description
              : "Ocorreu um erro ao processar o pagamento. Por favor, tente novamente ou entre em contato com o suporte."}
          </span>
        </DialogContent>
        <DialogFooter css={styles.footer}>
          <Button
            css={styles.button}
            onClick={() => {
              closePaymentActionDialog();
            }}
            fill="outlined"
            variant="pink"
          >
            Fechar
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  const hasOffersAvailabilitiesWithErrors = !!errorOnFetchOffersAvailabilities;
  const hasPriceChanges = offersChangesModel
    ? offersChangesModel.hasPriceChanges(travelPaymentServices)
    : false;
  const hasUnavailableOffers = offersAvailabilitiesModel
    ? offersAvailabilitiesModel.hasUnavailableOffers()
    : false;

  if (
    hasOffersAvailabilitiesWithErrors ||
    hasPriceChanges ||
    hasUnavailableOffers
  ) {
    const handleConfirm = () => {
      closePaymentActionDialog();

      scrollToElementById("payment-price-breakdown");
    };

    const offersAvailabilitiesWithErrorsTokens = errorOnFetchOffersAvailabilities
      ? Object.keys(errorOnFetchOffersAvailabilities)
      : [];
    const offersWithPriceChangesTokens = offersChangesModel
      ? offersChangesModel.getOffersWithPriceChangesTokens(
          travelPaymentServices,
        )
      : [];
    const unavailableOfferTokens = offersAvailabilitiesModel
      ? offersAvailabilitiesModel.getUnavailableOfferTokens()
      : [];

    return (
      <Dialog
        css={styles.root}
        onClose={closePaymentActionDialog}
        open={isPaymentActionDialogOpen}
      >
        <DialogHeader
          icon="x"
          onClose={closePaymentActionDialog}
          variant="pink"
        />
        <DialogContent>
          <DialogTitle variant="pink">Alerta de disponibilidade.</DialogTitle>
          <span css={styles.message}>
            Algumas ofertas precisam da sua atenção:
          </span>
          <div css={styles.content.offers.root}>
            {hasOffersAvailabilitiesWithErrors ? (
              <>
                <div css={styles.content.offers.offers.root}>
                  <span css={styles.content.offers.offers.title}>
                    <strong css={styles.strong}>
                      Não foi possível checar a disponibilidade{" "}
                    </strong>
                    das seguintes ofertas:
                  </span>
                  <ul css={styles.content.offers.offers.list.root}>
                    {offersAvailabilitiesWithErrorsTokens.map((offerToken) => {
                      const serviceModel = travelPaymentModel.getServiceByOfferToken(
                        offerToken,
                      );

                      if (!serviceModel) {
                        return null;
                      }

                      const servicesPresenter = ItineraryServicePresenterFactory.create(
                        serviceModel,
                      );

                      return (
                        <li
                          css={styles.content.offers.offers.list.item.root}
                          key={servicesPresenter.getOfferToken()}
                        >
                          <Icon size={16} use={servicesPresenter.getIcon()} />
                          <span>{servicesPresenter.getFormattedName()}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <Badge
                  css={styles.content.offers.badges.error.root}
                  variant="pink"
                >
                  <Icon
                    css={styles.content.offers.badges.error.icon}
                    size={16}
                    use="info-outline"
                  />
                  <span css={styles.content.offers.badges.error.text}>
                    Para ofertas com falha na checagem, você poderá continuar
                    com a sua compra, porém poderá haver alterações de
                    disponibilidade ou de preço das ofertas listadas acima.
                  </span>
                </Badge>
              </>
            ) : null}
            {hasUnavailableOffers ? (
              <>
                <div css={styles.content.offers.offers.root}>
                  <span css={styles.content.offers.offers.title}>
                    <strong css={styles.strong}>
                      Houve indisponibilidade{" "}
                    </strong>
                    das seguintes ofertas:
                  </span>
                  <ul css={styles.content.offers.offers.list.root}>
                    {unavailableOfferTokens.map((offerToken) => {
                      const serviceModel = travelPaymentModel.getServiceByOfferToken(
                        offerToken,
                      );

                      if (!serviceModel) {
                        return null;
                      }

                      const servicesPresenter = ItineraryServicePresenterFactory.create(
                        serviceModel,
                      );

                      return (
                        <li
                          css={styles.content.offers.offers.list.item.root}
                          key={servicesPresenter.getOfferToken()}
                        >
                          <Icon size={16} use={servicesPresenter.getIcon()} />
                          <span>{servicesPresenter.getFormattedName()}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <Badge css={styles.content.offers.badges.unavailability.root}>
                  <Icon
                    css={styles.content.offers.badges.unavailability.icon}
                    size={16}
                    use="info-outline"
                  />
                  <span css={styles.content.offers.badges.unavailability.text}>
                    Ofertas indisponíveis não serão enviadas para a compra.
                  </span>
                </Badge>
              </>
            ) : null}
            {hasPriceChanges ? (
              <>
                <div css={styles.content.offers.offers.root}>
                  <span css={styles.content.offers.offers.title}>
                    Houve{" "}
                    <strong css={styles.strong}>modificação de preço </strong>
                    das seguintes ofertas:
                  </span>
                  <ul css={styles.content.offers.offers.list.root}>
                    {offersWithPriceChangesTokens.map((offerToken) => {
                      const serviceModel = travelPaymentModel.getServiceByOfferToken(
                        offerToken,
                      );

                      if (!serviceModel) {
                        return null;
                      }

                      const servicesPresenter = ItineraryServicePresenterFactory.create(
                        serviceModel,
                      );

                      return (
                        <li
                          css={styles.content.offers.offers.list.item.root}
                          key={servicesPresenter.getOfferToken()}
                        >
                          <Icon size={16} use={servicesPresenter.getIcon()} />
                          <span>{servicesPresenter.getFormattedName()}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <Badge css={styles.content.offers.badges.changes.root}>
                  <Icon
                    css={styles.content.offers.badges.changes.icon}
                    size={16}
                    use="info-outline"
                  />
                  <span css={styles.content.offers.badges.changes.text}>
                    Verifique os novos preços e reavalie a compra conforme
                    necessário.
                  </span>
                </Badge>
              </>
            ) : null}
          </div>
        </DialogContent>
        <DialogFooter css={styles.footer}>
          <Button
            css={styles.button}
            disabled={isLoadingApprovalDeny}
            onClick={() => {
              handleConfirm();
            }}
            variant="pink"
          >
            Ok, entendi
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  return null;
};
