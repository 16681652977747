import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  amenities: {
    checked: boolean;
    label: string;
    value:
      | "breakfast"
      // | "air"
      | "gym"
      | "parking"
      | "reception"
      | "refund"
      | "restaurant"
      | "wifi";
  }[];
  handleChangeAmenitiesFilter: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

export const AmenitiesFilter: React.FC<Props> = ({
  amenities,
  handleChangeAmenitiesFilter,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Serviços</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {amenities.map((amenity) => (
          <CheckboxGroup.Item
            checked={amenity.checked}
            css={styles.checkbox.group.item}
            id={amenity.value}
            key={amenity.value}
            name={amenity.value}
            onChange={(e) => {
              handleChangeAmenitiesFilter(e as any, !amenity.checked);
            }}
            value={amenity.checked}
          >
            <span css={styles.text}>{amenity.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
