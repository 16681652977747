import React, { useCallback, useMemo, useState } from "react";

import { Select } from "~/apps/shared/components/select/select";
import { Option } from "~/apps/shared/types";
import moment, { Moment } from "moment";

import "moment/dist/locale/pt-br";
moment.locale("pt-BR");

import { styles } from "./styles";

const days = Array(31)
  .fill(null)
  .map((_, index) => index + 1)
  .map((day) => ({
    label: day.toString(),
    value: day,
  }));

const months = Array(12)
  .fill(null)
  .map((_, index) => index)
  .map((month) => ({
    label: moment().month(month).format("MMM"),
    value: month + 1,
  }));

const yearsForBithDate = Array(119)
  .fill(null)
  .map((_, index) => moment().add(-index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: parseInt(year, 10),
  }));

const yearsForExpiration = Array(11)
  .fill(null)
  .map((_, index) => moment().add(index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: parseInt(year, 10),
  }));

const yearsForIssue = Array(30)
  .fill(null)
  .map((_, index) => moment().add(-index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: parseInt(year, 10),
  }));

type Props = Pick<React.ComponentProps<typeof Select>, "onBlur"> & {
  label?: string;
  onChange?: (date: Moment) => void;
  type: "birthDate" | "expirationDate" | "issueDate" | null;
  value?: Moment;
};

export const ThreeWayDateSelect: React.FC<Props> = ({
  label = "Data",
  onBlur,
  onChange,
  type,
  value,
}) => {
  const [date, setDate] = useState({
    day: value ? parseInt(value.format("DD"), 10) : null,
    month: value ? parseInt(value.format("MM"), 10) : null,
    year: value ? parseInt(value.format("YYYY"), 10) : null,
  });

  const day = useMemo(() => {
    const day = days.find((day) => date.day && day.value === date.day);

    if (!day) {
      return undefined;
    }

    return {
      label: day.label,
      value: day.value.toString(),
    };
  }, [date]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!onBlur) {
      return;
    }

    onBlur(event);
  };

  const handleChangeDay = useCallback(
    ({ value }: Option<string>) => {
      const day = value;

      setDate((prev) => ({
        ...prev,
        day: parseInt(day, 10),
      }));

      if (!onChange) {
        return;
      }

      const formattedDate = moment(`${date.year}-${date.month}-${day}`);

      onChange(formattedDate);
    },
    [date, onChange],
  );

  const handleChangeMonth = useCallback(
    ({ value }: Option<string>) => {
      const month = value;

      setDate((prev) => ({
        ...prev,
        month: parseInt(month, 10),
      }));

      if (!onChange) {
        return;
      }

      const formattedDate = moment(`${date.year}-${month}-${date.day}`);

      onChange(formattedDate);
    },
    [date, onChange],
  );

  const handleChangeYear = useCallback(
    ({ value }: Option<string>) => {
      const year = value;

      setDate((prev) => ({
        ...prev,
        year: parseInt(year, 10),
      }));

      if (!onChange) {
        return;
      }

      const formattedDate = moment(`${year}-${date.month}-${date.day}`);

      onChange(formattedDate);
    },
    [date, onChange],
  );

  const month = useMemo(() => {
    const month = months.find(
      (month) => date.month && month.value === date.month,
    );

    if (!month) {
      return undefined;
    }

    return {
      label: month.label,
      value: month.value.toString(),
    };
  }, [date]);

  const years = useMemo(() => {
    switch (type) {
      case "birthDate":
        return yearsForBithDate;
      case "expirationDate":
        return yearsForExpiration;
      case "issueDate":
        return yearsForIssue;
      default:
        return [];
    }
  }, [type]);

  const year = useMemo(() => {
    const year = years.find((year) => date.year && year.value === date.year);

    if (!year) {
      return undefined;
    }

    return {
      label: year.label,
      value: year.value.toString(),
    };
  }, [date, years]);

  return (
    <div css={styles.root}>
      <span css={styles.select.label}>{label}</span>
      <div css={styles.select.root}>
        <Select
          css={styles.select.day}
          id="day"
          isSearchable
          name="day"
          onBlur={handleBlur}
          onChange={handleChangeDay}
          options={days}
          placeholder="DD"
          value={day}
        />
        <Select
          css={styles.select.month}
          id="month"
          isSearchable
          name="month"
          onBlur={handleBlur}
          onChange={handleChangeMonth}
          options={months}
          placeholder="MM"
          value={month}
        />
        <Select
          css={styles.select.year}
          id="year"
          isSearchable
          name="year"
          onBlur={handleBlur}
          onChange={handleChangeYear}
          options={years}
          placeholder="YYYY"
          value={year}
        />
      </div>
    </div>
  );
};
