import React, { createContext, useMemo } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";

import { ApprovableRule } from "@models/expense-approval.model";

import { useSafeState } from "@hooks";

import * as expenseApprovalService from "./ExpensesProcess.service";
import { Form } from "./ExpensesProcess.types";

interface Actions {
  deleteApprovableRule: () => void;
  handleCancelDelete: () => void;
  handleChangeSearch: (search: string) => void;
  handleCloseForm: () => void;
  handleOpenForm: () => void;
  handleSelectApprovalRuleToDelete: (approvalRule: ApprovableRule) => void;
  handleSelectApprovalRuleToEdit: (approvalRule: ApprovableRule) => void;
  handleSubmitForm: (formData: Form) => void;
  loadApprovals: () => void;
}

interface Selectors {
  visibleRules: ApprovableRule[];
}

interface State {
  approvalRules: ApprovableRule[];
  deleting: boolean;
  isFormVisible: boolean;
  loading: boolean;
  saving: boolean;
  searchText: string;
  selectedDeleteRule: ApprovableRule | null;
  selectedFormRule: ApprovableRule | null;
}

const initialState: State = {
  approvalRules: [],
  deleting: false,
  isFormVisible: false,
  loading: false,
  saving: false,
  searchText: "",
  selectedDeleteRule: null,
  selectedFormRule: null,
};

type ContextProps = Actions & Selectors & State;

export const ExpensesProcessContext = createContext<ContextProps>({
  ...initialState,
  deleteApprovableRule: () => null,
  handleCancelDelete: () => null,
  handleChangeSearch: () => null,
  handleCloseForm: () => null,
  handleOpenForm: () => null,
  handleSelectApprovalRuleToDelete: () => null,
  handleSelectApprovalRuleToEdit: () => null,
  handleSubmitForm: () => null,
  loadApprovals: () => null,
  visibleRules: [],
});

export const ExpensesProcessProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const loadApprovals = async () => {
    setState({ loading: true });

    const { data, error } = await expenseApprovalService.listApprovableRules();

    if (error) {
      setState({ loading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      approvalRules: data,
      loading: false,
    });
  };

  const handleOpenForm = () => setState({ isFormVisible: true });

  const handleCloseForm = () =>
    setState({ isFormVisible: false, selectedFormRule: null });

  const handleSubmitForm = (formData: Form) => {
    if (formData.token) {
      void editApprovableRule(formData);
    } else {
      void createApprovableRule(formData);
    }
  };

  const createApprovableRule = async (formData: Form) => {
    setState({ saving: true });

    const { data, error } = await expenseApprovalService.createApprovableRule(
      formData,
    );

    if (error) {
      setState({ saving: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      approvalRules: state.approvalRules.concat(data!),
      isFormVisible: false,
      saving: false,
      selectedFormRule: null,
    });

    showSnackMessage("Regra criada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const editApprovableRule = async (formData: Form) => {
    setState({ saving: true });

    const { data, error } = await expenseApprovalService.editApprovableRule(
      formData,
    );

    if (error) {
      setState({ saving: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const updatedApprovalRules = state.approvalRules.map((item) => {
      return item.approvableApprovalProcessToken ===
        data!.approvableApprovalProcessToken
        ? data!
        : item;
    });

    setState({
      approvalRules: updatedApprovalRules,
      isFormVisible: false,
      saving: false,
      selectedFormRule: null,
    });

    showSnackMessage("Regra editada com sucesso", ALERT_TYPES.SUCCESS);
  };

  const handleSelectApprovalRuleToEdit = (approvalRule: ApprovableRule) =>
    setState({ selectedFormRule: approvalRule, isFormVisible: true });

  const handleSelectApprovalRuleToDelete = (approvalRule: ApprovableRule) =>
    setState({ selectedDeleteRule: approvalRule });

  const handleCancelDelete = () => setState({ selectedDeleteRule: null });

  const deleteApprovableRule = async () => {
    const { selectedDeleteRule } = state;

    setState({ deleting: true });

    const { error } = await expenseApprovalService.deleteApprovableRule(
      selectedDeleteRule!.approvableApprovalProcessToken,
    );

    if (error) {
      setState({ deleting: false, selectedDeleteRule: null });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      approvalRules: state.approvalRules.filter(
        (item) =>
          item.approvableApprovalProcessToken !==
          selectedDeleteRule!.approvableApprovalProcessToken,
      ),
      deleting: false,
      selectedDeleteRule: null,
    });

    showSnackMessage("Regra excluída com sucesso", ALERT_TYPES.SUCCESS);
  };

  const handleChangeSearch = debounce(
    (search) => setState({ searchText: search }),
    300,
  );

  const visibleRules = useMemo(() => {
    const { searchText, approvalRules } = state;

    const sortedRules = approvalRules.sort((a, b) => {
      if (a.itemInfo.name < b.itemInfo.name) {
        return -1;
      }
      if (a.itemInfo.name > b.itemInfo.name) {
        return 1;
      }

      return 0;
    });

    return sortedRules.filter((rule) =>
      rule.itemInfo.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [state.searchText, state.approvalRules]);

  return (
    <ExpensesProcessContext.Provider
      value={{
        ...state,
        deleteApprovableRule,
        handleCancelDelete,
        handleChangeSearch,
        handleCloseForm,
        handleOpenForm,
        handleSelectApprovalRuleToDelete,
        handleSelectApprovalRuleToEdit,
        handleSubmitForm,
        loadApprovals,
        visibleRules,
      }}
    >
      {children}
    </ExpensesProcessContext.Provider>
  );
};
