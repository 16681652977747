import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import isEmpty from "lodash/isEmpty";

import { Flex, Text } from "@toolkit";
import { Button } from "@toolkit/v2";

import { ProjectsContext } from "../projects.context";
import { styles } from "./styles";

export const ProjectsList = () => {
  const {
    visibleProjects,
    openProjectEdition,
    activeProject,
    selectProjectToInactivate,
  } = React.useContext(ProjectsContext);

  const onItemClick = (project: any) => {
    openProjectEdition(project);
  };

  return (
    <Flex flexDirection="column" mt="1.5rem" style={{ gap: "0.5rem" }}>
      {isEmpty(visibleProjects) && (
        <Text fontSize={2}>Nenhum projeto encontrado</Text>
      )}
      {visibleProjects.map((project, index) => {
        return (
          <li
            css={styles.list.item.root}
            key={index}
            onClick={() => onItemClick(project)}
          >
            <div css={styles.list.item.left.root}>
              <span css={styles.list.item.left.name}>{project.name}</span>
            </div>
            <div css={styles.list.item.right.root}>
              <div css={styles.list.item.right.count}>
                {project.totalUsers}
                <Icon size={16} use="people" />
              </div>
              {project.active ? (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();

                    onItemClick(project);
                  }}
                  shape="icon"
                >
                  <Icon size={20} use="pencil" />
                </Button>
              ) : null}

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (project.active) {
                    selectProjectToInactivate(project);
                  } else {
                    activeProject(project);
                  }
                }}
                shape="icon"
                variant="pink"
              >
                <Icon
                  size={project.active ? 20 : 18}
                  use={project.active ? "trash" : "archive-box-arrow-up"}
                />
              </Button>
            </div>
          </li>
        );
      })}
    </Flex>
  );
};
