import React from "react";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const AlertBox: React.FC<Props> = ({ children, ...props }) => {
  return (
    <div css={styles.root} {...props}>
      {children}
    </div>
  );
};
