import { OfferStatus } from "./enums";

export const OFFER_STATUS_COLOR = {
  [OfferStatus.DRAFT]: "#808080",
  [OfferStatus.EMITTED]: "#2ac981",
  [OfferStatus.BOOKING_PROCESSING]: "#fa831b",
  [OfferStatus.CANCELED]: "#b24531",
  [OfferStatus.CANCELING]: "#fa831b",
  [OfferStatus.BOOKING_FAILED]: "#FF0000",
  [OfferStatus.APPROVAL_DECLINED]: "#FF0000"
};

export const OFFER_STATUS_TRANSLATED = {
  [OfferStatus.DRAFT]: "Rascunho",
  [OfferStatus.EMITTED]: "Emitida",
  [OfferStatus.BOOKING_PROCESSING]: "Emitindo",
  [OfferStatus.CANCELED]: "Cancelada",
  [OfferStatus.CANCELING]: "Cancelando",
  [OfferStatus.BOOKING_FAILED]: "Falha de emissão",
  [OfferStatus.APPROVAL_DECLINED]: "Aprovação negada"
};
