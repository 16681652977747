import React from "react";

import { CarItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryScreen } from "../../../../pages/travels/itinerary/itinerary-screen.context";
import { ItineraryCarSegment } from "../../../itinerary-car-segment/itinerary-car-segment";
import { ItineraryServiceCancelationInfoBadge } from "../../../itinerary-service-cancelation-info-badge/itinerary-service-cancelation-info-badge";
import { ItineraryServiceCancelationUntilBadge } from "../../../itinerary-service-cancelation-until-badge/itinerary-service-cancelation-until-badge";
import { ItineraryServiceCanceledByBadge } from "../../../itinerary-service-canceled-by-badge/itinerary-service-canceled-by-badge";
import { ItineraryServiceReservationCodeBadge } from "../../../itinerary-service-reservation-code-badge/itinerary-service-reservation-code-badge";
import { styles } from "./styles";

type Props = {
  isDisabled: boolean;
  priceInfo?: boolean;
  servicePresenter: CarItineraryServicePresenter;
};

export const ItineraryCarJourney: React.FC<Props> = ({
  isDisabled,
  priceInfo,
  servicePresenter,
}) => {
  const { openServiceDetailsDrawer } = useItineraryScreen();

  const bookingCode = servicePresenter.getBookingCode();
  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();

  return (
    <div
      css={styles.root({
        isDisabled,
      })}
    >
      {bookingCode && servicePresenter.showReservation() ? (
        <ItineraryServiceReservationCodeBadge reservationCode={bookingCode} />
      ) : null}
      <div
        css={styles.card.root({
          isDisabled,
        })}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          openServiceDetailsDrawer(servicePresenter.getService());
        }}
      >
        <ItineraryCarSegment
          contract={{
            features: servicePresenter.getContractFeatures(),
          }}
          diffDropOff={servicePresenter.hasDiffDropOff()}
          dropOff={servicePresenter.getDropOff()}
          model={{
            description: servicePresenter.getModel().description,
            features: servicePresenter.getModelFeatures(),
            image: servicePresenter.getModel().imageUrl,
            title: servicePresenter.getModel().title,
          }}
          pickUp={servicePresenter.getPickUp()}
          selectedAdditionals={servicePresenter.getAllSelectedAdditionals()}
        />
      </div>
      {priceInfo ? (
        <div css={styles.price.root}>
          <Icon css={styles.price.icon} size={16} use="dollar" />
          <span css={styles.price.text}>
            Preço total (taxas inclusas no preço):{" "}
            <strong>{toCurrency(servicePresenter.getPrice())}.</strong>
          </span>
        </div>
      ) : (
        <>
          {formattedFreeCancelationUntil ? (
            <ItineraryServiceCancelationUntilBadge
              freeCancelationUntil={formattedFreeCancelationUntil}
              isCancelable={servicePresenter.isCancelable()}
              serviceStatus={servicePresenter.getStatus()}
            />
          ) : null}
          {servicePresenter.isCanceled() ? (
            <ItineraryServiceCancelationInfoBadge
              cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
              cancelType={cancelInfo.type}
            />
          ) : null}
          {cancelInfo.showCanceledByMessage && (
            <ItineraryServiceCanceledByBadge
              canceledAt={cancelInfo.canceledAt}
              canceledByUserName={cancelInfo.canceledByUserName}
              isCanceled={cancelInfo.isCanceled}
            />
          )}
        </>
      )}
    </div>
  );
};
