import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Error } from "~/apps/shared/types";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  error: Error;
  onRefreshSeats: () => void;
};

export const BusCardRefreshSeatsWarning: React.FC<Props> = ({
  error,
  onRefreshSeats,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title}>{error.title}</span>
        <p css={styles.top.description}>{error.description}</p>
      </div>
      <Button css={styles.button} onClick={onRefreshSeats}>
        <Icon use="arrow-path" />
        Atualizar
      </Button>
    </div>
  );
};
