import React from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";

import { TagsProvider } from "../../configurations/views/company/tags/tags.context";
import { ItineraryContent } from "./itinerary-content/itinerary-content";
import { ItineraryHeader } from "./itinerary-header/itinerary-header";
import { ItineraryTravelCategorizationDialog } from "./itinerary-travel-categorization-dialog/itinerary-travel-categorization-dialog";
import { ItineraryTravelTagsDialog } from "./itinerary-travel-tags-dialog/itinerary-travel-tags-dialog";
import { ItineraryProvider } from "./itinerary.context";
import { styles } from "./styles";

type Props = RouteComponentProps<{
  travelToken: string;
}>;

const Component: React.FC<Props> = ({ travelToken }) => {
  if (!travelToken) {
    return null;
  }

  return (
    <>
      <PageTitle title={pageTitles.ITINERARY} />
      <div css={styles.root}>
        <ItineraryHeader />
        <ItineraryContent />
      </div>
      <ItineraryTravelCategorizationDialog />
      <ItineraryTravelTagsDialog />
    </>
  );
};

export const Itinerary: React.FC<Props> = ({ ...props }) => {
  const { travelToken } = props;

  if (!travelToken) {
    return null;
  }

  return (
    <TagsProvider>
      <ItineraryProvider travelToken={travelToken}>
        <Component {...props} />
      </ItineraryProvider>
    </TagsProvider>
  );
};
