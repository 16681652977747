import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  label: css({
    color: theme.colors.gray[500],
    fontSize: "0.875rem",
    fontWeight: 600,
  }),
  list: {
    item: ({ valid }: { valid: boolean }) =>
      css({
        color: valid ? theme.colors.green[500] : theme.colors.gray[500],
        fontSize: "0.875rem",
        lineHeight: "18px",
      }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      listStyle: "unset",
      paddingLeft: "20px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};
