import { useQuery } from "react-query";

import * as billingProfileApi from "~/apps/corporate/apis/billing-profile.api";

export const useClientBillingProfiles = (
  clientToken?: string,
  enabled?: boolean,
) => {
  const {
    data: billingProfiles,
    error: errorBillingProfiles,
    isLoading: isLoadingBillingProfiles,
  } = useQuery(
    ["clients", clientToken, "billing-profiles"],
    async () => {
      if (!clientToken) {
        return;
      }

      return await billingProfileApi.getClientBillingProfiles(clientToken);
    },
    {
      enabled: (enabled === undefined ? true : enabled) && !!clientToken,
    },
  );

  return {
    billingProfiles,
    errorBillingProfiles,
    isLoadingBillingProfiles,
  };
};
