import * as expensesApi from "@apis/expense.api";
import clientApi from "@apis/client.api";
import { ExpenseReportList } from "@models/expense.model";
import { CustomError } from "~/types";
import { ERROR } from "@constants";
import { UserModel } from "@models/user.model";

export async function getExpenseReportsList(
  page: number,
  status: string,
  search?: string
): Promise<{ data?: ExpenseReportList; error?: CustomError }> {
  const result: { data?: ExpenseReportList; error?: CustomError } = {};

  try {
    result.data = await expensesApi.listReportsByUser(page, status, search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    }
  }

  return result;
}

export async function getUserList(): Promise<{
  data?: UserModel[];
  error?: CustomError;
}> {
  const result: { data?: UserModel[]; error?: CustomError } = {};

  try {
    result.data = await clientApi.getUsersRequest();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_USER_LIST_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_USER_LIST_FETCH_ERROR;
    }
  }

  return result;
}

export async function createReport(
  formData: any
): Promise<{ data?: string; error?: CustomError }> {
  const result: { data?: string; error?: CustomError } = {};

  try {
    const requestDto = {
      description: formData.description,
      report_type: formData.reportType
    };
    result.data = await expensesApi.createNewReport(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_WITH_EXPENSES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_WITH_EXPENSES_ERROR;
    }
  }

  return result;
}

export async function removeExpenseReport(
  expenseReportToken: string
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await expensesApi.removeExpenseReport(expenseReportToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR;
    }
  }

  return result;
}

export async function removeAdvancesFromExpenseReport(
  expenseReportToken: string,
  expenseAdvanceTokens: string[]
): Promise<{ error?: CustomError }> {
  const result: { error?: CustomError } = {};

  try {
    await expensesApi.removeAdvancedExpenseFromReport(
      expenseAdvanceTokens,
      expenseReportToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR;
    }
  }

  return result;
}
