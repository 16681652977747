import React, {
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";

import { ClientConfigModel } from "@models/client.model";

import * as approvalsApi from "@apis/approvals.api";

import { CustomSnackbarOptions } from "~/components/shared/SnackBar";

import clientApi from "./apis/client.api";
import { shouldClearCache } from "./apps/shared/hooks/with-clear-cache";
import { getUserFromLocalStorage } from "./helpers/user.helper";
import { useInterval, useContextFactory } from "./hooks";
import { useSetUserSentryInfo } from "./hooks/useSetUserSentryInfo";

interface State {
  snack: {
    type: string;
    open: boolean;
    message: string;
    options?: CustomSnackbarOptions;
  };
  pendingTravelTokens: string[];
  pendingReportTokens: string[];
  pendingAdvancesTokens: string[];
  clientConfig: ClientConfigModel | null;
  isRefreshDialogVisible: boolean;
}

interface Actions {
  showSnackMessage: (
    message: string,
    type: string,
    options?: CustomSnackbarOptions,
  ) => void;
  handleCloseSnack: () => void;
  removeTravelFromPendingList: (tokenToRemove: string) => void;
  setTravelsPending: (pendingTravelTokens: string[]) => void;
  removeReportFromPendingList: (reportToken: string) => void;
  removeExpenseAdvanceFromPendingList: (expenseAdvanceToken: string) => void;
  handleUpdateBudgetsActive: (status: boolean) => void;
  handleUpdateVaultActive: (status: boolean) => void;
  handleUpdateCostCenterActive: (status: boolean) => void;
  handleUpdateCompanyAreaEnabled: (status: boolean) => void;
  handleUpdateCompanyAreaDisplay: (areaName: string) => void;
  handleUpdateCompanyBoardDisplay: (boardName: string) => void;
  handleUpdateTagsAdminOnly: (status: boolean) => void;
  handleUpdateTravelTagsRequired(status: boolean): void;
  handleUpdateProjectEnabled: (status: "off" | "optional" | "required") => void;
  loadGeneralData: () => void;
  showRefreshDialog(): void;
  closeRefreshDialog(): void;
}

interface Selectors {
  approvalsCount: number;
}

type ApplicationContextProps = State & Selectors & Actions;

const MINUTE_IN_MILLISECONDS = 1 * 60 * 1000;

const initialState = {
  clientConfig: null,
  pendingTravelTokens: [],
  pendingReportTokens: [],
  pendingAdvancesTokens: [],
  isRefreshDialogVisible: false,
  snack: {
    type: "",
    open: false,
    message: "",
  },
};

const ApplicationContext = React.createContext({} as ApplicationContextProps);

const useApplicationContext = useContextFactory(
  "HotelInfo",
  ApplicationContext,
);

const ApplicationProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<State>(initialState);

  const showSnackMessage = useCallback(
    (message: string, type: string, options?: CustomSnackbarOptions) => {
      setState((prevState: any) => {
        return { ...prevState, snack: { type, open: true, message, options } };
      });
    },
    [],
  );

  const handleCloseSnack = () => {
    setState((prevState: any) => {
      return {
        ...prevState,
        snack: { type: "", open: false, message: "", options: undefined },
      };
    });
  };

  const removeTravelFromPendingList = (tokenToRemove: string) => {
    const pendingTokens = state.pendingTravelTokens.filter(
      (token: string) => token !== tokenToRemove,
    );
    setState((prevState: any) => {
      return { ...prevState, pendingTravelTokens: pendingTokens };
    });
  };

  const setTravelsPending = (pendingTravelTokens: string[]) => {
    setState((prevState: any) => {
      return { ...prevState, pendingTravelTokens };
    });
  };

  const setReportsPending = (pendingReportTokens: string[]) => {
    setState((prevState: any) => {
      return { ...prevState, pendingReportTokens };
    });
  };

  const removeReportFromPendingList = (reportToken: string) => {
    const pendingTokens = state.pendingReportTokens.filter(
      (token) => token !== reportToken,
    );
    setReportsPending(pendingTokens);
  };

  const removeExpenseAdvanceFromPendingList = (expenseAdvanceToken: string) => {
    const pendingTokens = state.pendingAdvancesTokens.filter(
      (token) => token !== expenseAdvanceToken,
    );

    setState((prevState) => ({
      ...prevState,
      pendingAdvancesTokens: pendingTokens,
    }));
  };

  const loadPendingApprovals = () => {
    const user = getUserFromLocalStorage();

    if (user) {
      void approvalsApi.getApproverPendingApprovals().then((response) => {
        const { travels, expenseReports, expenseAdvances } = response;
        setState((prevState) => ({
          ...prevState,
          pendingTravelTokens: travels,
          pendingReportTokens: expenseReports,
          pendingAdvancesTokens: expenseAdvances,
        }));
      });
    }
  };

  useInterval(loadPendingApprovals, MINUTE_IN_MILLISECONDS);

  const approvalsCount = useMemo(() => {
    const {
      pendingTravelTokens,
      pendingReportTokens,
      pendingAdvancesTokens,
    } = state;

    return (
      pendingTravelTokens.length +
      pendingReportTokens.length +
      pendingAdvancesTokens.length
    );
  }, [
    state.pendingTravelTokens,
    state.pendingReportTokens,
    state.pendingAdvancesTokens,
  ]);

  const loadClientConfig = async () => {
    const user = getUserFromLocalStorage();

    if (user) {
      clientApi
        .getClientConfig()
        .then((config) =>
          setState((prevState: any) => ({
            ...prevState,
            clientConfig: config!,
          })),
        )
        .catch((error) => console.error(error));
    }
  };

  const loadGeneralData = useCallback(() => {
    void loadPendingApprovals();
    void loadClientConfig();
  }, []);

  // Version check

  const showRefreshDialog = useCallback(() => {
    setState((prevState) => ({ ...prevState, isRefreshDialogVisible: true }));
  }, []);

  const closeRefreshDialog = useCallback(() => {
    setState((prevState) => ({ ...prevState, isRefreshDialogVisible: false }));
  }, []);

  const checkForAppUpdates = useCallback(async () => {
    try {
      // should not check if dialog is already visible
      if (state.isRefreshDialogVisible) {
        return null;
      }

      if (await shouldClearCache()) {
        showRefreshDialog();
      }
    } catch (err) {
      console.error(err); // TODO: change console to a front-end log solution
    }
  }, [showRefreshDialog, state.isRefreshDialogVisible]);

  useInterval(checkForAppUpdates, MINUTE_IN_MILLISECONDS);

  // Client config setters

  const updateClientConfig = (config: Partial<ClientConfigModel>) => {
    setState((prevState) => ({
      ...prevState,
      clientConfig: Object.assign({}, prevState.clientConfig, config),
    }));
  };

  const handleUpdateBudgetsActive = (status: boolean) => {
    updateClientConfig({ budgetsActive: status });
  };

  const handleUpdateVaultActive = (status: boolean) => {
    updateClientConfig({ vaultActive: status });
  };

  const handleUpdateCostCenterActive = (status: boolean) => {
    updateClientConfig({ costCenterActive: status });
  };

  const handleUpdateCompanyAreaEnabled = (status: boolean) => {
    updateClientConfig({ companyAreaEnabled: status });
  };

  const handleUpdateCompanyAreaDisplay = (areaName: string) => {
    updateClientConfig({ companyAreaDisplay: areaName });
  };

  const handleUpdateCompanyBoardDisplay = (boardName: string) => {
    updateClientConfig({ companyBoardDisplay: boardName });
  };

  const handleUpdateTagsAdminOnly = (status: boolean) => {
    updateClientConfig({ tagsAdminOnly: status });
  };

  const handleUpdateTravelTagsRequired = (status: boolean) => {
    updateClientConfig({ travelTagsRequired: status });
  };

  const handleUpdateProjectEnabled = (
    status: "off" | "optional" | "required",
  ) => {
    updateClientConfig({ projectEnablingState: status });
  };

  useEffect(() => {
    loadGeneralData();
  }, [loadGeneralData]);

  // Allow sentry to use user data on entries
  useSetUserSentryInfo();

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        approvalsCount,
        showSnackMessage,
        handleCloseSnack,
        removeTravelFromPendingList,
        setTravelsPending,
        removeReportFromPendingList,
        removeExpenseAdvanceFromPendingList,
        loadGeneralData,
        handleUpdateBudgetsActive,
        handleUpdateVaultActive,
        handleUpdateCostCenterActive,
        handleUpdateCompanyAreaEnabled,
        handleUpdateCompanyAreaDisplay,
        handleUpdateCompanyBoardDisplay,
        handleUpdateTagsAdminOnly,
        handleUpdateTravelTagsRequired,
        handleUpdateProjectEnabled,
        showRefreshDialog,
        closeRefreshDialog,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContext, ApplicationProvider, useApplicationContext };
