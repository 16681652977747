import React, { useContext } from "react";

import Grid from "@material-ui/core/Grid";

import { Box } from "@toolkit";

import { toCurrency } from "@helpers";

import { Paper } from "../shared/Paper";
import { PaperTitle } from "../shared/PaperTitle";
import { SmallInfoCard } from "../shared/SmallInfoCard";
import { ServiceExpensesPieChart } from "./Charts/ServiceExpensesPieChart";
import { TotalTravelsChart } from "./Charts/TotalTravelsChart";
import { TravelPoliciesChart } from "./Charts/TravelPoliciesChart";
import { PanelContext } from "./panel.context";
import { SpentThroughtTime } from "./SpentThroughtTime";
import { TravelerSpentData } from "./TravelerSpentData";

const PanelContent: React.FC = () => {
  const {
    generalExpenses,
    chartData,
    travelerSpent,
    staticData: {
      totalTravels,
      travelInfo,
      travelPolicyAdoption,
      travelPolicyAdoptions,
    },
  } = useContext(PanelContext);

  const policyAdoptionPercentage =
    parseInt(travelPolicyAdoption.percentage, 10) || null;

  return (
    <Box pt={[24, 0]}>
      <Grid container spacing={3}>
        <Grid container item lg={3} xs={12}>
          <Grid item lg={12} md={6} xs={12}>
            <SmallInfoCard
              title="Total gasto"
              value={toCurrency(travelInfo.total)}
              my={[12, 12, 0]}
            />
          </Grid>
          <Grid item lg={12} md={6} xs={12}>
            <SmallInfoCard
              title="Viagens realizadas"
              value={travelInfo.quantity}
              my={[12, 12, 0]}
            />
          </Grid>
          <Grid item lg={12} md={6} xs={12}>
            <SmallInfoCard
              title="Ticket médio"
              value={toCurrency(travelInfo.average)}
              my={[12, 12, 0]}
            />
          </Grid>
          <Grid item lg={12} md={6} xs={12}>
            <SmallInfoCard
              title="Viagens fora da política"
              value={travelPolicyAdoption.outOfPolicy}
              mt={[12, 12, 0]}
            />
          </Grid>
        </Grid>
        <Grid item lg={9} xs={12}>
          <Paper>
            <PaperTitle>Gasto ao longo do tempo</PaperTitle>
            <SpentThroughtTime
              chartData={chartData}
              generalServices={generalExpenses}
            />
          </Paper>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Paper>
            <PaperTitle>Viagens ao longo do tempo</PaperTitle>
            <TotalTravelsChart data={travelPolicyAdoptions} />
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Paper>
            <PaperTitle>Gasto por serviço</PaperTitle>
            <ServiceExpensesPieChart generalExpenses={generalExpenses} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <PaperTitle>
              Adoção de política{" "}
              {policyAdoptionPercentage && `de ${policyAdoptionPercentage}%`}
            </PaperTitle>
            <TravelPoliciesChart data={travelPolicyAdoptions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <PaperTitle>Gasto por viajante</PaperTitle>
            <TravelerSpentData data={travelerSpent} />
          </Paper>
        </Grid>
      </Grid>
      <Box pb={88} />
    </Box>
  );
};

export { PanelContent };
