import { View } from "@react-pdf/renderer";
import React from "react";

import { CarItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";

import { TravelPlanPDFServiceCancelationInfo } from "../../travel-plan-pdf-service-cancelation-info/travel-plan-pdf-service-cancelation-info";
import { TravelPlanPDFServiceCancelationUntil } from "../../travel-plan-pdf-service-cancelation-until/travel-plan-pdf-service-cancelation-until";
import { TravelPlanPDFServiceCanceledBy } from "../../travel-plan-pdf-service-canceled-by/travel-plan-pdf-service-canceled-by";
import { TravelPlanPDFServiceReservationCode } from "../../travel-plan-pdf-service-reservation-code/travel-plan-pdf-service-reservation-code";
import { styles } from "./styles";
import { TravelPlanPDFCarSegment } from "./travel-plan-pdf-car-segment/travel-plan-pdf-car-segment";

type Props = {
  servicePresenter: CarItineraryServicePresenter;
};

export const TravelPlanPDFCarJourney: React.FC<Props> = ({
  servicePresenter,
}) => {
  const rootStyles = styles.root();

  const bookingCode = servicePresenter.getBookingCode();
  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();

  return (
    <View style={rootStyles.root}>
      {bookingCode && servicePresenter.showReservation() ? (
        <TravelPlanPDFServiceReservationCode reservationCode={bookingCode} />
      ) : null}
      <View style={rootStyles.card}>
        <TravelPlanPDFCarSegment
          contract={{
            features: servicePresenter.getContractFeatures(),
          }}
          diffDropOff={servicePresenter.hasDiffDropOff()}
          dropOff={servicePresenter.getDropOff()}
          model={{
            description: servicePresenter.getModel().description,
            features: servicePresenter.getModelFeatures(),
            image: servicePresenter.getModel().imageUrl,
            title: servicePresenter.getModel().title,
          }}
          pickUp={servicePresenter.getPickUp()}
          selectedAdditionals={servicePresenter.getAllSelectedAdditionals()}
        />
      </View>
      {formattedFreeCancelationUntil ? (
        <TravelPlanPDFServiceCancelationUntil
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <TravelPlanPDFServiceCancelationInfo
          cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
          cancelType={cancelInfo.type}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage && (
        <TravelPlanPDFServiceCanceledBy
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      )}
    </View>
  );
};
