import React from "react";

import { css } from "@styled-system/css";
import { COUNTRY_RESTRICTION_MESSAGES } from "~/apps/shared/constants/countries.constant";

import RadioGroup from "@components/shared/radio-group";

const styles = {
  root: css({
    marginTop: "-0.5rem",
  }),
};

const COUNTRY_RESTRICTION_MESSAGE_OPTIONS = Object.entries(
  COUNTRY_RESTRICTION_MESSAGES,
).reduce(
  (acc, [key, value]) => {
    acc.push({
      label: value,
      value: key,
    });

    return acc;
  },
  [] as {
    label: string;
    value: string;
  }[],
);

export const CountryRestrictionTypeRadioGroup: React.FC<
  React.ComponentPropsWithoutRef<typeof RadioGroup>
> = ({ ...props }) => {
  return (
    <RadioGroup {...props} css={styles.root}>
      {COUNTRY_RESTRICTION_MESSAGE_OPTIONS.map((restrictionType) => {
        return (
          <RadioGroup.Item
            key={restrictionType.value}
            value={restrictionType.value}
          >
            {restrictionType.label}
          </RadioGroup.Item>
        );
      })}
    </RadioGroup>
  );
};
