import React from "react";

import { Tooltip as MuiTooltip } from "@material-ui/core";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

type Props = Omit<
  React.ComponentProps<typeof MuiTooltip>,
  "children" | "content" | "placement" | "title"
> & {
  content: NonNullable<React.ReactNode>;
  position?: "bottom" | "left" | "right" | "top";
};

export const Tooltip: React.FC<Props> = ({
  children,
  content,
  position = "right",
  ...props
}) => {
  return (
    <MuiTooltip
      enterDelay={150}
      leaveDelay={150}
      placement={position}
      title={<div css={styles.root}>{content}</div>}
      {...props}
    >
      {children !== undefined ? (
        Array.isArray(children) ? (
          <div css={styles.children}>{children}</div>
        ) : (
          (children as any)
        )
      ) : (
        <div css={styles.fallback}>
          <Icon size={16} css={styles.icon} use="info-outline" />
        </div>
      )}
    </MuiTooltip>
  );
};
