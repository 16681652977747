import * as clientApi from "~/apps/corporate/apis/client.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { ClientBoss } from "../models/client.model";

export const getClientBosses = async (clientToken: string) => {
  const result: { data?: ClientBoss[]; error?: Error } = {};

  try {
    result.data = await clientApi.getClientBosses(clientToken);
  } catch (error) {
    result.error = ERROR.UNEXPECTED;
  }

  return result;
};
