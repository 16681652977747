import amex_logo from "@assets/images/card-brands/amex_logo.svg";
// import aura_logo from "@assets/images/card-brands/aura_logo.svg";
import diners_logo from "@assets/images/card-brands/diners_logo.svg";
// import discover_logo from "@assets/images/card-brands/discover_logo.svg";
import elo_logo from "@assets/images/card-brands/elo_logo.svg";
// import hipercard_logo from "@assets/images/card-brands/hipercard_logo.svg";
// import jcb_logo from "@assets/images/card-brands/jcb_logo.svg";
// import maestro_logo from "@assets/images/card-brands/maestro_logo.svg";
import mastercard_logo from "@assets/images/card-brands/mastercard_logo.svg";
import vcn_logo from "@assets/images/card-brands/vcn-logo.svg";
import visa_logo from "@assets/images/card-brands/visa_logo.svg";

export const CREDIT_CARD_MASKS = {
  12: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
  ],
  13: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    "",
  ],
  15: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  16: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  19: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const CVV_MASKS = {
  3: [/\d/, /\d/, /\d/],
  4: [/\d/, /\d/, /\d/, /\d/],
};

export const CARD_ISSUERS = [
  {
    brand: "mastercard",
    startsWith: /^(5[1-5]|2[2-7])/g,
    validationPattern: /^(5[1-5]|2[2-7])[0-9]{14}$/g,
    lengths: [16],
    cvvDigitsPattern: /^[0-9]{3}$/g,
    cvvDigits: 3,
    logo: mastercard_logo,
  },
  {
    brand: "visa",
    startsWith: /^4/g,
    validationPattern: /^4[0-9]{12}(?:[0-9]{3})?$/g,
    lengths: [13, 16],
    cvvDigitsPattern: /^[0-9]{3}$/g,
    cvvDigits: 3,
    logo: visa_logo,
  },
  {
    brand: "amex",
    startsWith: /^(34[0-8][0-9]{3}|349[0-8][0-9]{2}|3499[0-8][0-9]|34999[0-9]|37[0-8][0-9]{3}|379[0-8][0-9]{2}|3799[0-8][0-9]|37999[0-9])/g,
    validationPattern: /^(34[0-8][0-9]{3}|349[0-8][0-9]{2}|3499[0-8][0-9]|34999[0-9]|37[0-8][0-9]{3}|379[0-8][0-9]{2}|3799[0-8][0-9]|37999[0-9])[0-9]{9}$/g,
    // validationPattern: /^3[47][0-9]{13}$/g,
    lengths: [15],
    cvvDigitsPattern: /^[0-9]{4}$/g,
    cvvDigits: 4,
    logo: amex_logo,
  },
  {
    brand: "diners",
    startsWith: /^3(?:0[0-5]|[68][0-9])/g,
    validationPattern: /^3(?:0[0-5]|[68][0-9])[0-9]{13}$/g,
    lengths: [16],
    cvvDigitsPattern: /^[0-9]{3}$/g,
    cvvDigits: 3,
    logo: diners_logo,
  },
  {
    brand: "bank3",
    startsWith: /ABC/g,
    validationPattern: /ABC/g,
    lengths: [16],
    cvvDigitsPattern: /ABC/g,
    cvvDigits: 3,
    logo: vcn_logo,
  },
  {
    brand: "b2b",
    startsWith: /ABC/g,
    validationPattern: /ABC/g,
    lengths: [16],
    cvvDigitsPattern: /ABC/g,
    cvvDigits: 3,
    logo: vcn_logo,
  },
  {
    brand: "elo",
    startsWith: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/g,
    validationPattern: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/g,
    lengths: [16],
    cvvDigitsPattern: /^[0-9]{3}$/g,
    cvvDigits: 3,
    logo: elo_logo,
  },
  // {
  //   brand: "discover",
  //   startsWith: /^(6011|622|64|65)/g,
  //   validationPattern: /^(6011[0-9]{12}|622[0-9]{13}|6[4-5][0-9]{14})$/g,
  //   lengths: [16],
  //   cvvDigitsPattern: /^[0-9]{4}$/g,
  //   cvvDigits: 4,
  //   logo: discover_logo
  // },
  // {
  //   brand: "maestro",
  //   startsWith: /^(5018|5020|5038|6304|6759|6761|6763)/g,
  //   validationPattern: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/g,
  //   lengths: [12, 19],
  //   cvvDigitsPattern: /^[0-9]{3}/g,
  //   cvvDigits: 3,
  //   logo: maestro_logo
  // },
  // {
  //   brand: "aura",
  //   startsWith: /^50/g,
  //   validationPattern: /^50[0-9]{14}$/g,
  //   lengths: [16],
  //   cvvDigitsPattern: /^[0-9]{3}$/g,
  //   cvvDigits: 3,
  //   logo: aura_logo
  // },
  // {
  //   brand: "jcb",
  //   startsWith: /^35/g,
  //   validationPattern: /^35[0-9]{14}$/g,
  //   lengths: [16],
  //   cvvDigitsPattern: /^[0-9]{3}$/g,
  //   cvvDigits: 3,
  //   logo: jcb_logo
  // },
  // {
  //   brand: "hipercard",
  //   startsWith: /^(606282|637095|637568|637599|637609|637612|3841[046]0)/g,
  //   validationPattern: /^((606282|637095|637568|637599|637609|637612)[0-9]{10}([0-9]{3})?)|(3841(0|4|6)0[0-9]{13})$/,
  //   lengths: [16, 19],
  //   cvvDigitsPattern: /^[0-9]{3}$/g,
  //   cvvDigits: 3,
  //   logo: hipercard_logo
  // }
] as const;
