import React from "react";

import { Drawer as MuiDrawer } from "@material-ui/core";
import { Icon } from "~/apps/shared/components/icon/icon";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

export const Drawer: React.FC<
  React.ComponentPropsWithoutRef<typeof MuiDrawer>
> = ({ anchor = "right", children, ...props }) => {
  return (
    <MuiDrawer anchor={anchor} css={styles.root} {...props}>
      {children}
    </MuiDrawer>
  );
};

export const DrawerHeader: React.FC<
  React.HTMLAttributes<HTMLElement> & {
    onClose?: () => void;
  }
> = ({ children, onClose, ...props }) => {
  return (
    <header css={styles.header.root} {...props}>
      <div css={styles.header.title}>{children}</div>
      {onClose ? (
        <Button onClick={onClose} shape="icon">
          <Icon use="x" />
        </Button>
      ) : null}
    </header>
  );
};
