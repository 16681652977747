import React from "react";

import { Link } from "@reach/router";
import { css } from "@styled-system/css";
import isEmpty from "lodash/isEmpty";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { useUserReviewsContext } from "./UserReviews.context";

const styles = {
  linkDiv: css({
    position: "relative",
    [":hover"]: {
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    },
    ["a"]: {
      textDecoration: "none",
      fontSize: 1,
      color: "primary"
    },
    ["a.active"]: {
      fontWeight: "bold",
      ["::before"]: {
        content: "''",
        height: "46px",
        width: "2px",
        backgroundColor: "primary",
        position: "absolute",
        left: 0,
        top: 0,
        borderRadius: 1
      }
    }
  })
};

const ReviewLink = ({ to, label, hideBorder, count, disabled }) => {
  const getProps = inprops => ({
    className: inprops.isCurrent ? "active" : ""
  });

  return (
    <Box
      p="medium"
      borderTop={!hideBorder ? "1px solid" : ""}
      borderTopColor="border"
      borderRadius={0}
      css={styles.linkDiv}
    >
      {disabled ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color={defaultTheme.subTextColor}
          css={css({ cursor: "not-allowed" })}
        >
          <Text fontSize={1}>{label}</Text>
          <Text fontSize={1}>{count}</Text>
        </Flex>
      ) : (
        <Link to={to} getProps={getProps}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{label}</Text>
            <Text>{count}</Text>
          </Flex>
        </Link>
      )}
    </Box>
  );
};

const ReviewTabs = () => {
  const { doneReviews, pendingReviews } = useUserReviewsContext();

  return (
    <Box
      border="1px solid"
      borderColor="border"
      width={"100%"}
      height="max-content"
      maxWidth={["auto", 350]}
      mr={[0, "large"]}
      mb={["large", 0]}
    >
      <Flex flexDirection="column">
        <ReviewLink
          to=""
          label="Avaliações realizadas"
          count={doneReviews.length}
          hideBorder={true}
        />
        <ReviewLink
          to="pending-reviews"
          label="Avaliações pendentes"
          count={pendingReviews.length}
          disabled={isEmpty(pendingReviews)}
        />
      </Flex>
    </Box>
  );
};

export { ReviewTabs };
