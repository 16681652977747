import React from "react";
import { DateInput } from "../../shared/DateInput/DateInput";
import { Row } from "../../../shared/layout/Row";
import { ExpenseFormProps } from "../ExpenseDrawer.types";
import { Moment } from "moment";
import { css } from "emotion";
import { Input } from "../../../shared/inputs";
import CloseIcon from "@material-ui/icons/Close";
import { defaultTheme } from "../../../../assets/styles/theme";
import { LocationAutocomplete } from "@components/shared-logic/LocationAutocomplete/LocationAutocomplete";

const styles = {
  input: css({
    paddingBottom: "1.5rem"
  })
};

const HotelExpenseForm = ({
  values,
  errors,
  touched,
  loading,
  readOnly,
  handleBlur,
  setFieldValue,
  setFieldTouched
}: ExpenseFormProps) => {
  const handleSetDate = (fieldName: string) => (date: Moment | null) => {
    setFieldValue(fieldName, date);
  };

  const handleSelectHotelPlace = (hotelItem: any) => {
    if (hotelItem) {
      setFieldValue("hotel", {
        search: hotelItem.formattedAddress,
        latitude: hotelItem.latitude(),
        longitude: hotelItem.longitude()
      });
    } else {
      setFieldValue("hotel", null);
    }
  };

  const handleClearHotelPlace = () => {
    setFieldValue("hotel", null);
  };

  const checkinDateValidation = (day: Moment) => {
    if (!values.endDate) {
      return false;
    }

    return !day.isBefore(values.endDate!);
  };

  const checkoutDateValidation = (day: Moment) => {
    if (!values.startDate) {
      return false;
    }

    return !day.isAfter(values.startDate!);
  };

  const locationValue =
    values.hotel && values.hotel.search ? values.hotel.search : "";

  return (
    <div>
      {values.hotel || (values.hotel && readOnly) ? (
        <Input
          label="Local"
          value={locationValue}
          disabled={true}
          containerClasses={styles.input}
          InputProps={{
            endAdornment: (
              <CloseIcon
                onClick={handleClearHotelPlace}
                style={{
                  cursor: "pointer",
                  color: defaultTheme.subTextColor,
                  marginLeft: ".5rem"
                }}
              />
            )
          }}
        />
      ) : (
        <LocationAutocomplete
          value={locationValue}
          handleSelect={handleSelectHotelPlace}
          handleBlur={handleBlur}
          inputProps={{
            id: "hotel",
            name: "hotel",
            label: "Local",
            disabled: loading,
            containerClasses: styles.input,
            error: errors.hotel && touched.hotel,
            errorMessage: errors.hotel && touched.hotel && errors.hotel
          }}
        />
      )}
      <Row style={{ paddingBottom: "1.5rem " }}>
        <DateInput
          id="checkinDate"
          rootStyle={{ width: "75%" }}
          placeholder="Check-in"
          date={values.startDate}
          handleDateChange={handleSetDate("startDate")}
          error={Boolean(errors.startDate && touched.startDate)}
          errorMessage={errors.startDate}
          setTouched={setFieldTouched("startDate")}
          disabled={loading || readOnly}
          isOutsideRange={checkinDateValidation}
        />
        <div style={{ padding: "0 .75rem" }} />
        <DateInput
          id="checkoutDate"
          rootStyle={{ width: "75%" }}
          placeholder="Check-out"
          date={values.endDate}
          handleDateChange={handleSetDate("endDate")}
          error={Boolean(errors.endDate && touched.endDate)}
          errorMessage={errors.endDate}
          setTouched={setFieldTouched("endDate")}
          disabled={loading || readOnly}
          isOutsideRange={checkoutDateValidation}
        />
      </Row>
    </div>
  );
};

export { HotelExpenseForm };
