import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  addressFilter: {
    address: string;
    lat: number;
    lng: number;
  };
  handleClearFilter: () => void;
};

export const SidebarAddressFilter: React.FC<Props> = ({
  addressFilter,
  handleClearFilter,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <span css={styles.top.title}>Filtrando resultados pelo endereço:</span>
        <div css={styles.top.address.root}>
          <Icon size={16} use="map-pin" />
          <span css={styles.top.address.address}>
            {addressFilter.address.toLowerCase()}
          </span>
        </div>
      </div>
      <Button css={styles.button} fill="outlined" onClick={handleClearFilter}>
        Limpar filtro
      </Button>
    </div>
  );
};
