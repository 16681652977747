import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  input: {
    input: css({
      backgroundColor: "transparent",
      border: "none",
      fontFamily: theme.fonts.inter,
      padding: "12px 0",
      outline: "none",
      width: "100%",
    }),
    root: css({
      alignItems: "center",
      color: theme.colors.gray[700],
      display: "flex",
      gap: "12px",
      width: "inherit",
    }),
  },
  suggestions: {
    button: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[700],
      cursor: "pointer",
      display: "flex",
      padding: "12px 16px",
      transition: "all 0.15s ease-in-out",
      width: "100%",
      ":not(:disabled)": {
        ":hover": {
          backgroundColor: theme.colors.background.gray,
        },
      },
    }),
    google: css({
      height: "14px",
    }),
    item: css({
      color: theme.colors.gray[500],
      display: "flex",
      fontSize: "0.875rem",
      justifyContent: "flex-end",
      padding: "12px 16px",
    }),
    list: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      left: "0",
      listStyle: "none",
      overflow: "hidden",
      position: "absolute",
      right: "0",
      top: "calc(100% + 4px)",
      width: "100%",
      zIndex: 10,
    }),
    text: css({
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
  },
};
