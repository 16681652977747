import React from "react";

import { Redirect, Router } from "@reach/router";
import { Layout } from "~/apps/corporate/components/layout/layout";
import { CapabilityProtectedRoute } from "~/apps/shared/components/capability-protected-route/capability-protected-route";
import { capabilities } from "~/apps/shared/constants/enums";

import { ConfigurationsMenu } from "./configurations-menu/configurations-menu";
import { styles } from "./styles";
import { CompanyConfigurations } from "./views/company/company-configurations";
import { DevelopersConfigurations } from "./views/developers/developers-configurations";
import { ExpensesConfigurations } from "./views/expenses/expenses-configurations";
import { FinancialConfigurations } from "./views/financial/financial-configurations";
import { IntegrationsRoutes } from "./views/integrations/integrations-routes";
import { TripsConfigurations } from "./views/trips/trips-configurations";
import { UsersConfigurations } from "./views/users/users-configurations";

const Outlet: React.FC = () => {
  return (
    <Router css={styles.router} primary={false}>
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={CompanyConfigurations}
        path="/company/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.EXTERNAL_CREDENTIALS]}
        component={DevelopersConfigurations}
        path="/developers/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={ExpensesConfigurations}
        path="/expenses/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={FinancialConfigurations}
        path="/financial/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={IntegrationsRoutes}
        path="/integrations/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={TripsConfigurations}
        path="/trips/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.CONFIGURATIONS]}
        component={UsersConfigurations}
        path="/users/*"
      />
      <Redirect from="*" noThrow to="./trips" />
    </Router>
  );
};

export const Configurations: React.FC = () => {
  return (
    <Layout css={styles.layout}>
      <div css={styles.root}>
        <ConfigurationsMenu />
        <div css={styles.outlet.root}>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
};
