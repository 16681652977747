import React, { createContext } from "react";

import { AsyncDataProps } from "./async-data.types";

type AsyncDataContextProps = AsyncDataProps;

export const AsyncDataContext = createContext<AsyncDataContextProps>(
  {} as AsyncDataContextProps,
);

export const AsyncDataProvider: React.FC<AsyncDataContextProps> = ({
  children,
  ...props
}) => {
  return (
    <AsyncDataContext.Provider value={props}>
      {children}
    </AsyncDataContext.Provider>
  );
};
