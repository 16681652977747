import { AxiosResponse } from "axios";

import { getClientToken, getAuthorizationHeader } from "@helpers/user.helper";

import {
  PayOptionsByOffersResponseBody,
  BillingProfileResponseListItem,
  CreateBillingProfileRequestBody,
  CreateBillingProfileResponseBody
} from "@dtos/billing-profile.dto";

import * as billingProfileParser from "../parsers/billing-profile.parser";
import { api } from "./interceptors";

export function getPayOptionsByOffers(
  billingProfileToken: string,
  travelToken: string
): Promise<PayOptionsByOffersResponseBody> {
  return api
    .request<PayOptionsByOffersResponseBody>({
      url: `/booking/billing-profiles/${billingProfileToken}/travels/${travelToken}/pay-options`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export function getClientBillingProfileList() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/billing-profiles`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<BillingProfileResponseListItem[]>) =>
      data.map(itemData =>
        billingProfileParser.parseBillingProfileListItem(itemData)
      )
    );
}

export function getSingleBillingProfile(token: string) {
  return api
    .request({
      url: `/booking/billing-profiles/${token}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<BillingProfileResponseListItem>) =>
      billingProfileParser.parseBillingProfileListItem(data)
    );
}

export function createBillingProfile(dto: CreateBillingProfileRequestBody) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/billing-profiles`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      ({ data }: AxiosResponse<CreateBillingProfileResponseBody>) =>
        data.billing_profile_token
    );
}

export function updateBillingProfile(
  dto: CreateBillingProfileRequestBody,
  token: string
) {
  return api
    .request({
      url: `/booking/billing-profiles/${token}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => data);
}

export function deleteBillingProfile(token: string) {
  return api
    .request({
      url: `/booking/billing-profiles/${token}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export function getUserBillingProfileList(userToken: string) {
  return api
    .request({
      url: `/booking/users/${userToken}/billing-profiles`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }: AxiosResponse<BillingProfileResponseListItem[]>) =>
      data.map(itemData =>
        billingProfileParser.parseBillingProfileListItem(itemData)
      )
    );
}
