import * as yup from "yup";

export const passwordResetFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("Campo obrigatório.")
    .test("", "Email inválido.", (email) => {
      if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(email)) {
        return false;
      }

      return true;
    }),
});

export type PasswordResetFormSchema = yup.InferType<
  typeof passwordResetFormSchema
>;
