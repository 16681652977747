import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "8px 0",
    width: "100%",
  }),
  form: {
    input: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    select: {
      info: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
  },
  optional: css({
    color: theme.colors.gray[100],
    fontSize: "0.875rem",
  }),
  switch: {
    label: css({
      color: theme.colors.gray[700],
      cursor: "pointer",
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      gap: "12px",
      padding: "13px 16px",
      width: "fit-content",
    }),
  },
};
