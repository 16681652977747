import React, { useCallback } from "react";
import { connect } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import UserIcon from "@material-ui/icons/PersonAddRounded";
import { css } from "@styled-system/css";

import { Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { getUserFromLocalStorage } from "@helpers/user.helper";

import { IBookingTarget } from "~/models/booker-target.model";
import { Capabilities } from "~/models/user.model";

import Autocomplete from "@shared/new-trip/Autocomplete";

import { getBookingTargets } from "./NewTrip.service";

const styles = {
  root: css({
    border: "0",
    height: "24px",
    padding: "0",
    width: "fit-content"
  })
};

const sortByAllowed = (a: IBookingTarget, b: IBookingTarget) => {
  if (a.allowed > b.allowed) {
    return -1;
  }
  if (a.allowed < b.allowed) {
    return 1;
  }
  return 0;
};

type TargetItemProps = {
  isHighlighted: boolean;
  suggestion: IBookingTarget & { label: string; disabled: boolean };
};

const TargetItem: React.FC<TargetItemProps> = ({
  isHighlighted,
  suggestion
}) => {
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      disabled={suggestion.disabled}
    >
      <Flex flexDirection="column">
        <Text fontSize={2}>{suggestion.fullName}</Text>
        {suggestion.email ? (
          <Text fontSize={1} color={defaultTheme.subTextColor}>
            {suggestion.email}
          </Text>
        ) : null}
      </Flex>
    </MenuItem>
  );
};

type Props = {
  customGetBookingTargets?:
    | ((props: { search: string }) => Promise<IBookingTarget[]>)
    | null;
  error?: string;
  onChange(value: IBookingTarget): void;
  traveler: IBookingTarget | null;
  userCapabilities: Capabilities;
  version?: "v1" | "v2";
};

const TravelerAutocomplete: React.FC<Props> = ({
  customGetBookingTargets,
  error,
  onChange,
  traveler,
  userCapabilities,
  version = "v1"
}) => {
  // const getNoResultComponent = userCapabilities => {
  //   if (userCapabilities.configurations) {
  //     return <CreateUserShortcut />;
  //   }
  // };

  const value = traveler ? traveler.fullName : "";

  const loggedUser = getUserFromLocalStorage();

  const defaultSuggestions =
    loggedUser && userCapabilities?.selfSearch
      ? [
          {
            email: loggedUser.email,
            userToken: loggedUser.userToken,
            fullName: `${loggedUser.firstName} ${loggedUser.lastName}`,
            label: `${loggedUser.firstName} ${loggedUser.lastName}`,
            allowed: true
          }
        ]
      : [];

  const searchTargets = useCallback(
    async search => {
      if (customGetBookingTargets) {
        const result = await customGetBookingTargets({ search });

        return result
          .map(target => ({
            ...target,
            label: target.fullName,
            disabled: !target.allowed
          }))
          .sort(sortByAllowed);
      }

      const result = await getBookingTargets(search);

      const sortedResult = (result.data || [])
        .map(target => ({
          ...target,
          label: target.fullName,
          disabled: !target.allowed
        }))
        .sort(sortByAllowed);

      return sortedResult;
    },
    [customGetBookingTargets]
  );

  return (
    <Autocomplete
      defaultSuggestions={defaultSuggestions}
      inputProps={{
        ...(version === "v2" && { css: styles.root }),
        error,
        id: "travelerAutocomplete",
        placeholder: "Selecione o viajante"
      }}
      ResultItemComponent={TargetItem}
      search={searchTargets}
      shouldRenderSuggestions={() => true}
      updateParent={onChange}
      value={value}
    />
  );
};

const Container = connect((state: any) => ({
  userCapabilities: state.user.capabilities
}))(TravelerAutocomplete);

export { Container as TravelerAutocomplete };
