import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  message: {
    different: {
      list: css({
        marginLeft: "16px",
        listStyleType: "disc",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        fontSize: "0.875rem",
        gap: "8px",
        lineHeight: "1.25",
        marginTop: "16px",
      }),
      strong: css({
        fontWeight: 600,
      }),
    },
    root: css({
      color: theme.colors.gray[700],
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      lineHeight: "1.125",
    }),
  },
  strong: css({
    fontWeight: 600,
  }),
};
