import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.background.gray,
    color: theme.colors.gray[700],
    display: "flex",
    flexDirection: "row",
    fontSize: "0.875rem",
  }),
};
