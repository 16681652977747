import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  header: {
    button: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.pink[500],
      cursor: "pointer",
      fontFamily: theme.fonts.inter,
      fontSize: "0.875rem",
      fontWeight: 500,
      marginTop: "2px",
      transition: "color 0.15s ease-in-out",
      width: "fit-content",
      ":disabled": {
        opacity: 0.5,
      },
      ":hover": {
        color: theme.colors.pink[700],
      },
    }),
    root: css({
      display: "flex",
      justifyContent: "space-between",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      lineHeight: "1.25",
    }),
  },
  referral: {
    display: {
      button: css({
        flexShrink: 0,
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "16px",
        maxWidth: "fit-content",
        padding: "12px 16px",
      }),
      text: css({
        lineHeight: "20px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
    },
    label: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px",
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
};
