import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  item: {
    bottom: {
      button: css({
        justifyContent: "center",
        width: "100%",
      }),
      price: css({
        fontSize: "1.5rem",
        fontWeight: 600,
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }),
    },
    container: css({
      border: "1px solid",
      borderColor: theme.colors.gray[100],
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      transition: "border-color 0.15s ease-in-out",
      width: "384px",
      ":hover": {
        borderColor: theme.colors.pink[500],
      },
      "@media (max-width: 425px)": {
        maxWidth: "unset",
        width: "100%",
      },
    }),
    divisor: css({
      backgroundColor: theme.colors.gray[100],
      height: "1px",
      width: "100%",
    }),
    policy: {
      badge: {
        icon: css({
          "& > svg": {
            strokeWidth: 2,
          },
        }),
        root: css({
          backgroundColor: "#ffe8ab",
          borderColor: "#ffe8ab",
          borderRadius: "0 8px 0 8px",
          color: "#806300",
          fontWeight: 500,
          gap: "8px",
          padding: "4px 16px",
          ":hover": {
            backgroundColor: "#ffe8ab",
            borderColor: "#ffe8ab",
            color: "#806300",
          },
        }),
      },
      root: css({
        position: "absolute",
        right: 0,
        top: 0,
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "48px",
      height: "100%",
      padding: "48px 24px 24px 24px",
    }),
  },
  root: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    justifyContent: "center",
    padding: "0 24px",
  }),
};
