import * as yup from "yup";

import { removeBlankSpaces } from "~/helpers";

import {
  CardNumber,
  RequiredSelectOption
} from "~/schemas/common-fields.schema";

const cardCreationSchema = yup.object().shape({
  billingProfile: yup.mixed().when("enableToClient", {
    is: true,
    then: () =>
      RequiredSelectOption("Selecione o perfil de cobrança").required(),
    otherwise: schema => schema.notRequired().nullable()
  }),

  holderName: yup
    .string()
    .trim()
    .required("Preencha o nome do portador do cartão"),
  number: CardNumber().required("Número do cartão inválido"),
  expirationDate: yup
    .string()
    .test("validateExpirationDate", "Digite uma data válida", value => {
      if (value === undefined) {
        return true;
      }

      const [month, year] = removeBlankSpaces(value).split("/");

      if (!month && !year) {
        return false;
      }

      const fullYear = 2000 + Number(year);
      const today = new Date();

      const expectedDate = new Date(fullYear, month, 1);
      const currentDate = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );

      return expectedDate >= currentDate;
    })
    .required("Digite uma data válida"),
  issuer: yup.object().required(),
  cvv: yup
    .string()
    .test("validateCvv", "Digite um CVV válido", (value, context) => {
      if (value === undefined) {
        return true;
      }

      const cvvWithoutSpaces = removeBlankSpaces(value);

      if (!cvvWithoutSpaces) {
        return false;
      }

      if (!context.parent.issuer) {
        return false;
      }

      return cvvWithoutSpaces.match(context.parent.issuer.cvvDigitsPattern);
    })
    .required("Preencha o CVV"),
  description: yup.string().trim().required("Dê um apelido ao cartão"),
  enableToClient: yup.boolean(),
  allowApprovers: yup.boolean()
});

export default cardCreationSchema;
