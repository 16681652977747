import React, { useState, useCallback, forwardRef } from "react";

import { Container, Error } from "./styles";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  endIcon?: React.ComponentType;
  error?: string;
  startIcon?: React.ComponentType;
  version?: "v1" | "v2";
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = "",
      endIcon: EndIcon,
      error,
      onBlur,
      onFocus,
      startIcon: StartIcon,
      version = "v1",
      ...rest
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    }, []);

    const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(e);
      }
    }, []);

    return (
      <>
        <Container
          className={className}
          isErrored={!!error}
          isFocused={isFocused}
        >
          {StartIcon && <StartIcon />}
          <input
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
            {...rest}
          />

          {EndIcon && <EndIcon />}
          {version === "v1" ? <div className="indicator" /> : null}
        </Container>
        {!!error && <Error>{error}</Error>}
      </>
    );
  }
);

Input.displayName = "Input";

export { Input };
