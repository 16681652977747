import React, { useContext } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";

import { Box } from "@toolkit";
import { Switch } from "@toolkit/v2";

import { PROJECT_ENABLING_STATE } from "@constants";

import { ProjectsContext } from "../projects.context";
import { styles } from "./styles";

const STATE_OPTIONS = [
  {
    value: PROJECT_ENABLING_STATE.OPTIONAL,
    label:
      "Projetos serão opcionais ao reservar uma viagem ou solicitar aprovação",
  },
  {
    value: PROJECT_ENABLING_STATE.REQUIRED,
    label:
      "Projetos serão obrigatórios ao reservar uma viagem ou solicitar aprovação",
  },
];

export const ProjectStateSelect = () => {
  const { clientConfig } = useClientConfig();

  const { handleToggleProjectState, handleChangeProjectState } = useContext(
    ProjectsContext,
  );
  const projectState = clientConfig
    ? clientConfig.getProjectEnablingState()
    : "off";
  const isProjectsEnabled = projectState !== PROJECT_ENABLING_STATE.OFF;

  return (
    <Box>
      <label css={styles.switch.root}>
        <Switch
          active={isProjectsEnabled}
          onChange={handleToggleProjectState}
          variant="pink"
        />
        <span css={styles.switch.text}>Ativar função de projetos</span>
      </label>
      {isProjectsEnabled && (
        <Box css={styles.radioGroup.root}>
          <RadioGroup value={projectState} onChange={handleChangeProjectState}>
            {STATE_OPTIONS.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={
                  <Radio color="primary" css={styles.radioGroup.radio} />
                }
                label={<p css={styles.radioGroup.label}>{option.label}</p>}
              />
            ))}
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};
