import React from "react";

import { Bound } from "~/apps/corporate/models/flight.model";

import { FlightJourneyDetailsCollapsibleCard } from "../../../../../components/flight-journey-details-collapsible-card/flight-journey-details-collapsible-card";
import { styles } from "./styles";

const mapBoundToFlightJourneyDetailsCollapsibleCardProps = (bound: Bound) => {
  const props: React.ComponentProps<
    typeof FlightJourneyDetailsCollapsibleCard
  > = {
    arrival: {
      airportCode: bound.destination.code,
      airportName: bound.destination.name,
      date: bound.arrival.split("/").reverse().join("-"),
    },
    departure: {
      airportCode: bound.origin.code,
      airportName: bound.origin.name,
      date: bound.departure.split("/").reverse().join("-"),
    },
    isBaggageVisible: true,
    segments: bound.segments.map((segment, index) => ({
      arrival: {
        airportCode: segment.destination.code,
        airportName: segment.destination.name,
        date: segment.arrival.split("/").reverse().join("-"),
        time: segment.arrivalHour,
      },
      baggage: {
        carryOn: {
          included: !!bound.fareDetails?.carryOnLuggage?.pieces,
          quantity: bound.fareDetails?.carryOnLuggage?.pieces
            ? bound.fareDetails.carryOnLuggage.pieces
            : 0,
          weight: {
            unit: "kg",
            value: bound.fareDetails?.carryOnLuggage?.weight
              ? bound.fareDetails.carryOnLuggage.weight
              : 0,
          },
        },
        checked: {
          included: bound.baggage,
          quantity: bound.baggagePieces,
          weight: {
            unit: "kg",
            value: bound.baggageWeight ? bound.baggageWeight : 0,
          },
        },
      },
      cabinClass: segment.cabinClass,
      carrier: segment.carrier
        ? {
            imageUrl: segment.carrier.imageUrl,
            name: segment.carrier.name,
          }
        : undefined,
      connection: segment.stops?.[index]
        ? {
            isSameAirport: !segment.stops[index].airportChange,
            segmentIndex: index,
            waitingTime: segment.stops[index].durationHours,
          }
        : undefined,
      departure: {
        airportCode: segment.origin.code,
        airportName: segment.origin.name,
        date: segment.departure.split("/").reverse().join("-"),
        time: segment.departureHour,
      },
      duration: segment.durationHours,
      flightNumber: segment.flightNumber,
    })),
  };

  return props;
};

type Props = React.HTMLAttributes<HTMLDivElement> & {
  inbound?: Bound;
  outbound: Bound;
};

export const FlightDetails: React.FC<Props> = ({
  inbound,
  outbound,
  ...props
}) => {
  return (
    <div css={styles.root} {...props}>
      <div css={styles.section.root}>
        <span css={styles.section.title}>Detalhes da ida</span>
        <FlightJourneyDetailsCollapsibleCard
          {...mapBoundToFlightJourneyDetailsCollapsibleCardProps(outbound)}
        />
      </div>
      {inbound ? (
        <div css={styles.section.root}>
          <span css={styles.section.title}>Detalhes da volta</span>
          <FlightJourneyDetailsCollapsibleCard
            {...mapBoundToFlightJourneyDetailsCollapsibleCardProps(inbound)}
          />
        </div>
      ) : null}
    </div>
  );
};
