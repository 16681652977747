import Axios, { AxiosResponse } from "axios";
import mapValues from "lodash/mapValues";
import qs from "querystring";

import { AdvancedExpenseDto } from "@dtos/advanced-expense.dto";

import { S3_BASE_URL, STATIC_MAPS_URL } from "../constants";
import {
  CalculateRouteDistanceAndValueRequestBody,
  CalculateRouteDistanceAndValueResponseBody
} from "../dtos/expense-approval";
import {
  GetSignedUrlRequest,
  GetSignedUrlResponse,
  CreateFlightExpenseRequest,
  CreateHotelExpenseRequest,
  CreateGeneralExpenseRequest,
  CreateExpenseResponse,
  ExpenseReportReponse,
  UpdateReportExpensesRequest,
  ExpenseReportCreateRequest,
  ExpenseReportCreateReponse,
  ExpenseReportResponseItem,
  ExpenseReportFinishResponse,
  ExpenseReportFinishRequest,
  ExpenseReportSendApprovalRequestBody,
  ExpenseReportApproveResponseBody,
  ExpenseReportDeniedResponseBody,
  GetExpensesListResponse,
  GetUserExpenseApproverProcessesResponseBody,
  CreateKilometerExpenseRequest,
  CreateMealExpenseRequest,
  GetReportExpensesPoliciesResponseBody,
  GetKilometerCreationPermissionResponseBody,
  SignalMultipleReportPaymentsRequestBody,
  SignalMultipleReportPaymentsResponseBody,
  CancelReportApprovalResponseBody
} from "../dtos/expenses.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import {
  ExpenseReport,
  ExpenseReportApprovalStatus,
  ExpenseReportList,
  ExpensesList
} from "../models/expense.model";
import * as expensesParser from "../parsers/expense.parser";
import { ImageFile } from "../types";
import { api } from "./interceptors";

export function getFileUploadUrl(data: GetSignedUrlRequest): Promise<string> {
  return api
    .request({
      url: "/booking/expense/upload/presigned",
      method: "POST",
      headers: getAuthorizationHeader(),
      data
    })
    .then((response: AxiosResponse<GetSignedUrlResponse>) => response.data.url);
}

export function uploadImageFile(
  signedUrl: string,
  file: ImageFile
): Promise<any> {
  return Axios.put(signedUrl, file, {
    headers: {
      "Content-Type": file.type
    }
  });
}

export function getImageFile(imageUrl: string): Promise<ImageFile> {
  const filename = imageUrl.replace(S3_BASE_URL, "");
  const filetype = "image/" + filename.split(".").pop();

  return Axios({
    method: "GET",
    url: imageUrl,
    responseType: "blob"
  })
    .then(
      (response: AxiosResponse<Blob>) =>
        new File([response.data], filename, { type: filetype })
    )
    .then((image: File) => image as ImageFile);
}

export function getGoogleStaticMapImage(
  directions: string,
  params: any
): Promise<ImageFile> {
  const filename = "static-map";
  const filetype = "image/png";

  const requestParams = {
    ...params,
    path: "enc:".concat(directions)
  };

  const imageUrl = STATIC_MAPS_URL.concat("?", qs.stringify(requestParams));

  return Axios({
    method: "GET",
    url: imageUrl,
    responseType: "blob"
  })
    .then(
      (response: AxiosResponse<Blob>) =>
        new File([response.data], filename, { type: filetype })
    )
    .then((image: File) => image as ImageFile);
}

export function createNewExpense(
  expenseData:
    | CreateFlightExpenseRequest
    | CreateHotelExpenseRequest
    | CreateKilometerExpenseRequest
    | CreateMealExpenseRequest
    | CreateGeneralExpenseRequest
): Promise<CreateExpenseResponse> {
  return api
    .request({
      url: "/booking/expense",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: expenseData
    })
    .then((response: AxiosResponse<CreateExpenseResponse>) => response.data);
}

export function updateExpense(
  expenseData:
    | CreateFlightExpenseRequest
    | CreateHotelExpenseRequest
    | CreateKilometerExpenseRequest
    | CreateMealExpenseRequest
    | CreateGeneralExpenseRequest,
  expenseToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense/${expenseToken}`,
    method: "PUT",
    headers: getAuthorizationHeader(),
    data: expenseData
  });
}

export function listPendingExpenses(
  page: number,
  search = ""
): Promise<ExpensesList> {
  return api
    .request({
      url: `/booking/expense/list/${page}?search=${search}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<GetExpensesListResponse>) => {
      return {
        currentPage: response.data.current,
        totalPages: response.data.pages,
        expenses: response.data.expenses.map(expenseItem =>
          expensesParser.expenseFactory(expenseItem)
        )
      };
    });
}

export function deleteExpense(expenseToken: string): Promise<any> {
  return api.request({
    url: `/booking/expense/${expenseToken}`,
    method: "DELETE",
    headers: getAuthorizationHeader()
  });
}

export function listReportsByUser(
  page: number,
  status: string,
  search = ""
): Promise<ExpenseReportList> {
  return api
    .request({
      url: `/booking/expense/report/list/${page}/${status}?search=${search}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseReportReponse>) => {
      return {
        currentPage: response.data.current,
        totalPages: response.data.pages,
        expenseReports: response.data.expense_reports.map(expenseReportItem =>
          expensesParser.expenseReportFactory(expenseReportItem)
        )
      };
    });
}

export function listReportsFromOtherUsers(
  page: number,
  status: string,
  search = ""
): Promise<ExpenseReportList> {
  return api
    .request({
      url: `/booking/expenses/reports/others`,
      method: "GET",
      params: {
        page,
        status,
        search
      },
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseReportReponse>) => {
      return {
        currentPage: response.data.current,
        totalPages: response.data.pages,
        expenseReports: response.data.expense_reports.map(expenseReportItem =>
          expensesParser.expenseReportFactory(expenseReportItem)
        )
      };
    });
}

export function addExpensesToReport(
  requestData: UpdateReportExpensesRequest,
  expenseReportToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense/report/${expenseReportToken}/add`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data: requestData
  });
}

export function removeExpensesFromReport(
  requestData: UpdateReportExpensesRequest,
  expenseReportToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense-reports/${expenseReportToken}/remove-expense`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data: requestData
  });
}

export function removeAdvancedExpenseFromReport(
  advancedExpenseTokens: string[],
  expenseReportToken: string
): Promise<AdvancedExpenseDto> {
  return api
    .request<AdvancedExpenseDto>({
      url: `/booking/expense-reports/${expenseReportToken}/remove-advance`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: { expense_advance_tokens: advancedExpenseTokens }
    })
    .then(response => response.data);
}

export function createNewReport(
  requestData: ExpenseReportCreateRequest
): Promise<string> {
  return api
    .request({
      url: "/booking/expense/report",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then(
      (response: AxiosResponse<ExpenseReportCreateReponse>) =>
        response.data.expense_report_token
    );
}

export function getReportByToken(reportToken: string): Promise<ExpenseReport> {
  return api
    .request({
      url: `/booking/expense/report/${reportToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseReportResponseItem>) => {
      return expensesParser.expenseReportFactory(response.data);
    });
}

export function finishExpenseReport(
  reportToken: string,
  requestData: ExpenseReportFinishRequest
): Promise<ExpenseReportFinishResponse> {
  return api
    .request({
      url: `/booking/expense/report/${reportToken}/finish`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData
    })
    .then(
      (response: AxiosResponse<ExpenseReportFinishResponse>) => response.data
    );
}

export function updateExpesseReportDescription(
  description: string,
  expenseReportToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense/report/update_description/${expenseReportToken}`,
    method: "PUT",
    headers: getAuthorizationHeader(),
    data: {
      description
    }
  });
}

export function sendExpenseReportApprovalRequest(
  dto: ExpenseReportSendApprovalRequestBody
): Promise<any> {
  return api.request({
    url: "/booking/expense/approval/request",
    method: "POST",
    headers: getAuthorizationHeader(),
    data: dto
  });
}

export function sendExpenseReportApprovalResponse(
  dto: ExpenseReportApproveResponseBody | ExpenseReportDeniedResponseBody,
  approvalProcessToken: string
): Promise<any> {
  return api.request({
    url: `/booking/expense/approval/response/${approvalProcessToken}`,
    method: "POST",
    headers: getAuthorizationHeader(),
    data: dto
  });
}

export function getExpenseReportApprovalStatus(
  expenseReportToken: string
): Promise<ExpenseReportApprovalStatus> {
  return api
    .request({
      url: `/booking/expense/report/${expenseReportToken}/approval-status`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseReportApprovalStatus>) => {
      return response.data;
    });
}

export function listApproverReportsList(
  page: number,
  status: string,
  search = ""
): Promise<ExpenseReportList> {
  return api
    .request({
      url: `/booking/expense/report/approvals/${page}/${status}?search=${search}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<ExpenseReportReponse>) => ({
      currentPage: response.data.current,
      totalPages: response.data.pages,
      expenseReports: response.data.expense_reports.map(expenseReportItem =>
        expensesParser.expenseReportFactory(expenseReportItem)
      )
    }));
}

export function checkUserExpensesApprovalProcesses(): Promise<GetUserExpenseApproverProcessesResponseBody> {
  return api
    .request({
      url: "/booking/expense/approver/processes",
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(
      (response: AxiosResponse<GetUserExpenseApproverProcessesResponseBody>) =>
        response.data
    );
}

export function getCalculatedRouteDistanceAndValue(
  dto: CalculateRouteDistanceAndValueRequestBody
): Promise<CalculateRouteDistanceAndValueResponseBody> {
  return api
    .request({
      url: "/booking/expense/kilometer/distance",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(
      (response: AxiosResponse<CalculateRouteDistanceAndValueResponseBody>) =>
        response.data
    );
}

export function getUserPendingExpenseReports(): Promise<string[]> {
  return api
    .request({
      url: "/booking/expense/report/user/pending-approval",
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => data);
}

export function getReportExpensesPolicies(expenseReportToken: string) {
  return api
    .request({
      url: `/booking/expense/report/${expenseReportToken}/policies`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then((response: AxiosResponse<GetReportExpensesPoliciesResponseBody>) => {
      return mapValues(response.data, policy => ({
        maxValue: policy.max_value,
        outOfPolicy: policy.out_of_policy
      }));
    });
}

export function getKilometerExpensePermission() {
  return api
    .request({
      url: "/booking/expense/kilometer/permission",
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(
      (response: AxiosResponse<GetKilometerCreationPermissionResponseBody>) =>
        response.data
    );
}

export function signalMultipleReportPayments(
  dto: SignalMultipleReportPaymentsRequestBody
) {
  return api
    .request<SignalMultipleReportPaymentsResponseBody>({
      url: "/booking/expense-reports/signal-payment",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(response => response.data);
}

export function signalReportPayment(expenseReportToken: string) {
  return api
    .request({
      url: `/booking/expense-reports/${expenseReportToken}/signal-payment`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function cancelReportApprovalRequest(
  expenseReportToken: string
): Promise<CancelReportApprovalResponseBody> {
  return api
    .request<CancelReportApprovalResponseBody>({
      url: `/booking/expense-reports/${expenseReportToken}/cancel-request`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function removeExpenseReport(expenseReportToken: string): Promise<{}> {
  return api
    .request({
      url: `/booking/expense-reports/${expenseReportToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}
