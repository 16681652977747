const errorTypes = {
  ERROR_INVALID_CREDENTIALS: "Invalid user email or password",
  EMAIL_PASSWORD_INCORRECT: "EMAIL_PASSWORD_INCORRECT",
  ERROR_INVALID_INPUT: "ERROR_INVALID_INPUT",
  EMAIL_ALREADY_EXIST: "EMAIL_ALREADY_EXIST",
  ERROR_FINDING_USER: "ERROR_FINDING_USER",
  ERROR_FINDING_TRAVEL: "ERROR_FINDING_TRAVEL",
  ERROR_GETTING_DOCUMENT: "ERROR_GETTING_DOCUMENT",
  ERROR_USER_DOCUMENTS: "ERROR_USER_DOCUMENTS",
  ERROR_NOT_ALLOWED: "ERROR_NOT_ALLOWED",
  ERROR_BOOKING_OFFERS: "ERROR_BOOKING_OFFERS",
  ERROR_INVALID_CARD: "ERROR_INVALID_CARD",
  ERROR_INSUFFICIENT_FUNDS: "ERROR_INSUFFICIENT_FUNDS",
  PROJECT_ALREADY_EXISTS: "PROJECT_ALREADY_EXISTS",
  COST_CENTER_ALREADY_EXISTS: "COST_CENTER_ALREADY_EXISTS",
  ERROR_VAULT_LIMIT_ERROR: "ERROR_VAULT_LIMIT_ERROR",
  ERROR_BUDGET_LIMIT_ERROR: "ERROR_BUDGET_LIMIT_ERROR",
  UNEXPECTED_FUND_CHECK_ERROR: "UNEXPECTED_FUND_CHECK_ERROR",
  INSUFICCIENT_FUNDS_ERROR: "INSUFICCIENT_FUNDS_ERROR",
  INVALID_CREDIT_CARD: "INVALID_CREDIT_CARD",
  ERROR_FINDING_TRAVEL_CURRENT_VALUE: "ERROR_FINDING_TRAVEL_CURRENT_VALUE",
  EXPENSE_RULE_ALREADY_EXISTS: "EXPENSE_RULE_ALREADY_EXISTS",
  ERROR_PRIOR_BOOK_PROCESSING: "ERROR_PRIOR_BOOK_PROCESSING",
  ORDER_BOOKING_ERROR: "ORDER_BOOKING_ERROR"
};

export const AUTHENTICATION_ERRORS = {
  SSO_CONFIGS_NOT_FOUND: "No sso config found.",
  MISSING_SSO_CONFIGS: "User has no sso configuration for its client.",
  EXPIRED_SESSION: "Session has expired.",
  REVOKED_SESSION: "Login has been revoked.",
  SESSION_NOT_FOUND: "Session not found.",
  USER_WITHOUT_ACCESS: "User has no accesss to this authentication method.",
  AUTH_METHOD_NOT_ENABLED:
    "Client does not have this authentication method enabled."
};

export const AUTHENTICATION_ERRORS_MESSAGES = {
  [AUTHENTICATION_ERRORS.SSO_CONFIGS_NOT_FOUND]:
    "Nenhum configuração de sso encontrada",
  [AUTHENTICATION_ERRORS.EXPIRED_SESSION]:
    "Sessão expirada, realize um novo login para continuar",
  [AUTHENTICATION_ERRORS.REVOKED_SESSION]:
    "Acesso revogado, realize o login novamente",
  [AUTHENTICATION_ERRORS.USER_WITHOUT_ACCESS]:
    "Não foi possível atualizar a sessão atual, realize o login novamente"
};

export default errorTypes;
