import { RealTimeTravel } from "@models/analytics.model";

export const parseTravelsByCity = (travels: RealTimeTravel[]) => {
  return travels.reduce((prev, curr) => {
    if (!curr.latitude || !curr.longitude) {
      return prev;
    }

    if (!prev[curr.destinationCity]) {
      prev[curr.destinationCity] = [curr];
    } else {
      prev[curr.destinationCity].push(curr);
    }

    return prev;
  }, {} as any);
};
