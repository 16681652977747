import React, { useCallback, useRef } from "react";

import { useOfferSearcher } from "~/apps/corporate/components/offer-searcher/offer-searcher.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Icon, Icons } from "~/apps/shared/components/icon/icon";

import {
  OfferSearcherTab,
  OFFER_SEARCHER_TABS,
  useNewTrip,
} from "../new-trip.context";
import { NewTripOfferSearcherBusForm } from "./bus-form/bus-form";
import { NewTripOfferSearcherCarForm } from "./car-form/car-form";
import {
  NewTripOfferSearcherFlightForm,
  NewTripOfferSearcherFlightFormSkeleton,
} from "./flight-form/flight-form";
import { NewTripOfferSearcherHotelForm } from "./hotel-form/hotel-form";
import { NewTripOfferSearcherAlert } from "./offer-searcher-alert/offer-searcher-alert";
import { NewTripOfferSearcherMessage } from "./offer-searcher-message/offer-searcher-message";
import { styles } from "./styles";

export const NewTripOfferSearcher: React.FC = () => {
  const { clientConfig, isLoading: isLoadingClientConfig } = useClientConfig();
  const { handleTabChange, selectedTab } = useNewTrip();
  const {
    handleSearchBuses,
    handleSearchCars,
    handleSearchFlights,
    handleSearchHotels,
  } = useOfferSearcher();

  if (isLoadingClientConfig) {
    return <NewTripOfferSearcherSkeleton />;
  }

  if (!clientConfig) {
    return null;
  }

  return (
    <>
      <div css={styles.root}>
        <NewTripOfferSearcherTabs
          handleTabChange={handleTabChange}
          isBusesTabVisible={clientConfig.isBusesVisible()}
          isCarsTabVisible={clientConfig.isCarsVisible()}
          isHotelsTabVisible={clientConfig.isHotelsVisible()}
          isFlightsTabVisible={clientConfig.isFlightsVisible()}
          selectedTab={selectedTab}
        />
        <div css={styles.form.root}>
          {selectedTab === OFFER_SEARCHER_TABS.BUS ? (
            <NewTripOfferSearcherBusForm
              handleSearchBuses={handleSearchBuses}
            />
          ) : null}
          {selectedTab === OFFER_SEARCHER_TABS.CAR ? (
            <NewTripOfferSearcherCarForm handleSearchCars={handleSearchCars} />
          ) : null}
          {selectedTab === OFFER_SEARCHER_TABS.FLIGHT ? (
            <NewTripOfferSearcherFlightForm
              handleSearchFlights={handleSearchFlights}
            />
          ) : null}
          {selectedTab === OFFER_SEARCHER_TABS.HOTEL ? (
            <NewTripOfferSearcherHotelForm
              handleSearchHotels={handleSearchHotels}
            />
          ) : null}
        </div>
        <NewTripOfferSearcherMessage />
      </div>
      <NewTripOfferSearcherAlert />
    </>
  );
};

const NewTripOfferSearcherSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <NewTripOfferSearcherTabsSkeleton />
      <div css={styles.form.root}>
        <NewTripOfferSearcherFlightFormSkeleton />
      </div>
    </div>
  );
};

type NewTripOfferSearcherTabsProps = {
  handleTabChange: (tab: OfferSearcherTab) => void;
  isBusesTabVisible: boolean;
  isCarsTabVisible: boolean;
  isHotelsTabVisible: boolean;
  isFlightsTabVisible: boolean;
  selectedTab: OfferSearcherTab;
};

export const NewTripOfferSearcherTabs: React.FC<NewTripOfferSearcherTabsProps> = ({
  handleTabChange,
  isBusesTabVisible,
  isCarsTabVisible,
  isHotelsTabVisible,
  isFlightsTabVisible,
  selectedTab,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (tab: OfferSearcherTab, index: number) => {
      handleTabChange(tab);

      const scrollToTab = (index: number) => {
        if (!tabsRef.current) {
          return;
        }

        const tabsElements = tabsRef.current.children;

        const tabElement = tabsElements[index];

        tabElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      };

      scrollToTab(index);
    },
    [tabsRef],
  );

  const tabs: {
    icon: Icons;
    label: string;
    value: OfferSearcherTab;
    visible: boolean;
  }[] = [
    {
      icon: "airplane-taking-off",
      label: "Voos",
      value: OFFER_SEARCHER_TABS.FLIGHT,
      visible: isFlightsTabVisible,
    },
    {
      icon: "hotel",
      label: "Hotéis",
      value: OFFER_SEARCHER_TABS.HOTEL,
      visible: isHotelsTabVisible,
    },
    {
      icon: "car",
      label: "Carros",
      value: OFFER_SEARCHER_TABS.CAR,
      visible: isCarsTabVisible,
    },
    {
      icon: "bus",
      label: "Ônibus",
      value: OFFER_SEARCHER_TABS.BUS,
      visible: isBusesTabVisible,
    },
  ];

  return (
    <div css={styles.tabs.root} ref={tabsRef}>
      {tabs.map((tab, index) => {
        if (!tab.visible) {
          return null;
        }

        const active = selectedTab === tab.value;

        return (
          <button
            aria-label={tab.label}
            aria-selected={active}
            css={styles.tabs.tab({ active }).root}
            key={tab.value}
            onClick={() => {
              handleClick(tab.value, index);
            }}
            role="tab"
          >
            <Icon size={16} use={tab.icon} />
            <span css={styles.tabs.tab({ active }).label}>{tab.label}</span>
          </button>
        );
      })}
    </div>
  );
};

const NewTripOfferSearcherTabsSkeleton: React.FC = () => {
  const tabs: {
    icon: Icons;
    label: string;
    value: OfferSearcherTab;
    visible: boolean;
  }[] = [
    {
      icon: "airplane",
      label: "Voos",
      value: OFFER_SEARCHER_TABS.FLIGHT,
      visible: true,
    },
    {
      icon: "hotel",
      label: "Hotéis",
      value: OFFER_SEARCHER_TABS.HOTEL,
      visible: true,
    },
    {
      icon: "car",
      label: "Carros",
      value: OFFER_SEARCHER_TABS.CAR,
      visible: true,
    },
    {
      icon: "bus",
      label: "Ônibus",
      value: OFFER_SEARCHER_TABS.BUS,
      visible: true,
    },
  ];

  return (
    <div css={styles.tabs.root}>
      {tabs.map((tab, index) => {
        if (!tab.visible) {
          return null;
        }

        const active = index === 0;

        return (
          <button
            aria-label={tab.label}
            aria-selected={active}
            css={styles.tabs.tab({ active }).root}
            disabled
            key={tab.value}
            role="tab"
          >
            <Icon size={16} use={tab.icon} />
            <span css={styles.tabs.tab({ active }).label}>{tab.label}</span>
          </button>
        );
      })}
    </div>
  );
};
