import { StylesConfig } from "react-windowed-select";

import { css } from "@styled-system/css";
import { styles as selectStyles } from "~/apps/shared/components/select/styles";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    input: css({
      flex: 1,
    }),
    root: css({
      display: "flex",
      gap: "16px",
      justifyContent: "space-between",
      width: "100%",
    }),
    select: <StylesConfig>{
      ...selectStyles({ fill: "outlined", variant: "default" }),
      control: (baseStyles, props) => ({
        ...baseStyles,
        ...selectStyles({ fill: "outlined", variant: "default" }).control?.(
          baseStyles,
          props,
        ),
        height: "50px",
        maxWidth: "288px",
      }),
      singleValue: (baseStyles, props) => ({
        ...baseStyles,
        ...selectStyles({ fill: "outlined", variant: "default" }).singleValue?.(
          baseStyles,
          props,
        ),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginBottom: "16px",
    width: "100%",
  }),
  top: {
    right: {
      root: css({
        display: "flex",
        gap: "16px",
      }),
    },
    root: css({
      display: "flex",
      gap: "24px",
      justifyContent: "space-between",
      width: "100%",
    }),
  },
};
