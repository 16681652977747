import { useEffect, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ERROR } from "~/apps/shared/constants/errors";
import { PageError } from "~/apps/shared/types";

import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { useItineraryInfo } from "../itinerary/itinerary-info.context";
import { useItineraryServices } from "../itinerary/itinerary-services.context";
import { usePayment } from "./payment.context";

export const usePaymentGuard = () => {
  const { user } = useUser();

  const {
    errorOnFetchApprovalHistory,
    errorOnFetchApprovalStatus,
  } = useItineraryApproval();
  const { errorOnFetch: errorOnFetchItineraryInfo } = useItineraryInfo();
  const { errorOnFetchTravelPoliciesPerOffer } = useItineraryServices();
  const {
    errorOnFetch: errorOnFetchTravelPayment,
    errorOnFetchClientInfo,
    errorOnFetchUserPaymentPermission,
    fetchUserPaymentPermission,
  } = usePayment();

  const [enabled, setEnabled] = useState(false);
  const [error, setError] = useState<PageError | null>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    void (async () => {
      const userHasPaymentPermission = await fetchUserPaymentPermission(
        user.getUserToken(),
      );

      if (!userHasPaymentPermission) {
        setError({
          ...ERROR.UNAUTHORIZED_ACCESS_TO_PAYMENT,
          navigateMessage: "Minhas viagens",
          navigateTo: () => {
            navigate("/trips");
          },
        });

        return;
      }

      setEnabled(true);

      setError(null);
    })();
  }, [fetchUserPaymentPermission, user]);

  return {
    enabled,
    error:
      errorOnFetchClientInfo ||
      errorOnFetchApprovalHistory ||
      errorOnFetchApprovalStatus ||
      errorOnFetchItineraryInfo ||
      errorOnFetchTravelPayment ||
      errorOnFetchTravelPoliciesPerOffer ||
      errorOnFetchUserPaymentPermission ||
      error,
  };
};
