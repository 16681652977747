export enum SSOConfigProvider {
  OKTA = "OKTA",
  PING_ID = "PING_ID",
  MICROSOFT_AZURE = "MICROSOFT_AZURE",
  AUTH0 = "AUTH0",
  GOOGLE = "GOOGLE",
  CUSTOM = "CUSTOM"
}

export enum CarPolicyCategory {
  BASIC = "BASIC",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  SPECIAL = "SPECIAL"
}

export enum BasicCategory {
  Economy = "E",
  Compact = "C",
  Intermediate = "I"
}

export enum IntermediateCategory {
  Standard = "S",
  Mini = "M",
  IntermediateElite = "J"
}

export enum AdvancedCategory {
  Fullsize = "F",
  CompactElite = "D",
  MiniElite = "N",
  Premium = "P"
}

export enum SpecialCategory {
  Luxury = "L",
  LuxuryElite = "W",
  Special = "X"
}

export enum ServiceType {
  FLIGHT = "flight",
  HOTEL = "hotel",
  CAR = "car",
  BUS = "bus",
  INSURANCE = "insurance"
}

export enum PayOptions {
  BILLED = "BILLED",
  CREDIT_CARD = "CREDIT_CARD",
  CLIENT_CREDIT = "CLIENT_CREDIT", // when client uses his own credit card
  AGENCY_BILLED = "AGENCY_BILLED", // when we bill the client, but use our own credit card behind the scenes
  SUPPLIER_BILLED = "SUPPLIER_BILLED" // when we regularly bill the client
}

export enum TravelIntegrationOption {
  NONE = "NONE",
  TRIPACTIONS = "TRIPACTIONS"
}

export enum DocumentType {
  PASSPORT = "passport",
  CPF = "id",
  RG = "rg"
}

export enum GenderType {
  MALE = "M",
  FEMALE = "F"
}

export enum CountryRestrictionType {
  BLOCK_ALL = "block-all",
  BLOCK_ABROAD = "block-abroad",
  ALLOW_WITH_WARNING = "allow-with-warning"
}

export enum CountryRestrictionSeverity {
  INFO = "info",
  ERROR = "error",
  WARNING = "warning"
}

export enum Capabilities {
  BASIC = "basic",
  SELF_SEARCH = "selfSearch",
  SEARCH = "search",
  TRAVELS = "travels",
  REVIEWS = "reviews",
  APPROVALS = "approvals",
  EXPENSES = "expenses",
  CONFIGURATIONS = "configurations",
  FINANCIAL = "financial",
  ANALYTICS = "analytics",
  BATCH_ACTIONS = "batchActions",
  MANUAL_MANAGEMENT = "manualManagement",
  EXPENSE_FOR_OTHERS = "expenseForOthers",
  APPROVAL_ANY_TIME_FOR_OTHERS = "approvalAnyTimeForOthers",
  EXTERNAL_CREDENTIALS = "externalCredentials",
  EVENT_ORGANIZER = "eventOrganizer"
}

export enum BookingFeeOptions {
  TRAVEL_INDIVIDUALLY = "TRAVEL_INDIVIDUALLY",
  TRAVEL_MONTHLY = "TRAVEL_MONTHLY",
  OFFER_MONTHLY = "OFFER_MONTHLY",
  OFFER_INDIVIDUALLY = "OFFER_INDIVIDUALLY",
  FIXED_MONTHLY = "FIXED_MONTHLY",
  NONE = "NONE"
}

export enum BookingFeeStatus {
  PAID = "PAID",
  NONE = "NONE", // shouldnt exist
  FAILED = "FAILED",
  UNITIATED = "UNITIATED",
  PENDING = "PENDING"
}

export enum ApprovalProcessTypes {
  PROJECT = "PROJECT",
  USER = "USER",
  COST_CENTER = "COST-CENTER",
  AREA = "AREA",
  COMPANY = "COMPANY"
}

export enum CardOwnerType {
  client = "client",
  user = "user"
}

export enum CardServiceType {
  FLIGHT = "flight",
  HOTEL = "hotel",
  GENERAL = "general",
  CAR = "car",
  // TERRESTRIAL = "terrestrial",
  RIDE = "ride",
  BUS = "bus",
  OTHER = "other"
}

export enum BookingStatus {
  UNITIATED = "UNITIATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  DROPPED = "DROPPED",
  EMITTED = "EMITTED"
}

export enum CancelingStatus {
  UNITIATED = "UNITIATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export enum ChangeStatus {
  UNITIATED = "UNITIATED",
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export enum OfferStatus {
  APPROVAL_DECLINED = "APPROVAL_DECLINED",
  BOOKING_FAILED = "BOOKING_FAILED",
  DRAFT = "DRAFT",
  BOOKING_PROCESSING = "BOOKING_PROCESSING",
  CANCELING = "CANCELING",
  EMITTED = "EMITTED",
  CANCELED = "CANCELED"
}

export enum ChangeType {
  ALTERED = "ALTERED",
  PARTIALLY_ALTERED = "PARTIALLY_ALTERED"
}

export enum PayableService {
  FLIGHT = "flight",
  HOTEL = "hotel",
  BUS = "bus",
  CAR = "car",
  FEE = "fee"
}

export enum ClientBookingPhoneConfig {
  REQUIRED = "REQUIRED",
  OPTIONAL = "OPTIONAL",
  NOT_REQUIRED = "NOT_REQUIRED"
}

//Ideal colocar no SM-types
export enum ClientApprovalConfigType {
  REGULAR = "REGULAR",
  NAVAN_SINGLE_APPROVER = "NAVAN_SINGLE_APPROVER",
  BOSS_FLOW = "BOSS_FLOW"
}

export enum ApprovalPolicyCondition {
  ALL = "ALL",
  IN = "IN",
  OUT = "OUT"
}

export enum ApprovalTerritoryCondition {
  ALL = "ALL",
  NATIONAL = "NATIONAL",
  INTERNATIONAL = "INTERNATIONAL"
}

export enum ApprovalAction {
  NOTIFY = "NOTIFY",
  APPROVE = "APPROVE"
}
//-----

export enum ClientApprovalOption {
  REGULAR = "REGULAR",
  SINGLE = "SINGLE",
  SINGLE_APPROVAL = "SINGLE_APPROVAL",
  SINGLE_NOTIFICATION = "SINGLE_NOTIFICATION"
}

export enum UserBookingPhoneConfig {
  UNITIATED = "UNITIATED",
  NOT_REQUIRED = "NOT_REQUIRED"
}

export enum ApprovalListStatus {
  PENDING = "pending",
  APPROVED = "approved"
}

export enum ExpenseCategory {
  FLIGHT = "flight",
  APARTMENT = "apartment",
  HOTEL = "hotel",
  KILOMETER = "kilometer",
  RIDE_HEILING = "ride-heiling",
  BREAKFAST = "breakfast",
  LUNCH = "lunch",
  DINNER = "dinner",
  CAR = "car",
  BUS = "bus",
  FEE = "fee",
  EXPENSE_FEE = "expense-fee",
  SEAT = "seat",
  BAGGAGE = "baggage",
  HOTEL_ADDITIONAL = "hotel-additional",
  CAR_ADDITIONAL = "car-additional",
  INSURANCE = "insurance",
  TRANSPORT = "transport",
  CANCELING = "canceling",
  OTHER = "other",
  CUSTOM = "custom"
}

export enum CurrencyCode {
  BRL = "BRL",
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  ARS = "ARS",
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  AUD = "AUD",
  AZN = "AZN",
  BAM = "BAM",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BND = "BND",
  BOB = "BOB",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GNF = "GNF",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MOP = "MOP",
  MUR = "MUR",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SOS = "SOS",
  SYP = "SYP",
  THB = "THB",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  XAF = "XAF",
  XOF = "XOF",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL"
}

export enum CurrencySymbol {
  BRL = "R$",
  USD = "$",
  CAD = "C$",
  EUR = "€",
  ARS = "$",
  AED = "د.إ",
  AFN = "؋",
  ALL = "L",
  AMD = "֏",
  AUD = "A$",
  AZN = "₼",
  BAM = "KM",
  BDT = "৳",
  BGN = "лв",
  BHD = "ب.د",
  BIF = "₣",
  BND = "B$",
  BOB = "Bs.",
  BWP = "P",
  BYN = "Br",
  BZD = "BZ$",
  CDF = "₣",
  CHF = "CHF",
  CLP = "$",
  CNY = "¥",
  COP = "$",
  CRC = "₡",
  CVE = "$",
  CZK = "Kč",
  DJF = "₣",
  DKK = "kr",
  DOP = "RD$",
  DZD = "د.ج",
  EEK = "KR",
  EGP = "£",
  ERN = "Nfk",
  ETB = "Br",
  GBP = "£",
  GEL = "₾",
  GHS = "₵",
  GNF = "₣",
  GTQ = "Q",
  HKD = "HK$",
  HNL = "L",
  HRK = "kn",
  HUF = "Ft",
  IDR = "Rp",
  ILS = "₪",
  INR = "₹",
  IQD = "ع.د",
  IRR = "﷼",
  ISK = "kr",
  JMD = "J$",
  JOD = "د.ا",
  JPY = "¥",
  KES = "KSh",
  KHR = "៛",
  KMF = "₣",
  KRW = "₩",
  KWD = "د.ك",
  KZT = "₸",
  LBP = "ل.ل",
  LKR = "₨",
  LTL = "Lt",
  LVL = "Ls",
  LYD = "ل.د",
  MAD = "د.م.",
  MDL = "L",
  MGA = "Ar",
  MKD = "ден",
  MMK = "K",
  MOP = "MOP$",
  MUR = "₨",
  MXN = "$",
  MYR = "RM",
  MZN = "MT",
  NAD = "$",
  NGN = "₦",
  NIO = "C$",
  NOK = "kr",
  NPR = "₨",
  NZD = "NZ$",
  OMR = "ر.ع.",
  PAB = "B/.",
  PEN = "S/",
  PHP = "₱",
  PKR = "₨",
  PLN = "zł",
  PYG = "₲",
  QAR = "ر.ق",
  RON = "lei",
  RSD = "дин",
  RUB = "₽",
  RWF = "₣",
  SAR = "ر.س",
  SDG = "ج.س.",
  SEK = "kr",
  SGD = "S$",
  SOS = "S",
  SYP = "ل.س",
  THB = "฿",
  TND = "د.ت",
  TOP = "T$",
  TRY = "₺",
  TTD = "TT$",
  TWD = "NT$",
  TZS = "Sh",
  UAH = "₴",
  UGX = "Sh",
  UYU = "$",
  UZS = "лв",
  VEF = "Bs",
  VND = "₫",
  XAF = "₣",
  XOF = "₣",
  YER = "﷼",
  ZAR = "R",
  ZMW = "ZK",
  ZWL = "$"
}
