import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  input: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "4px",
    color: theme.colors.gray[700],
    height: "32px",
    margin: "0 4px",
    outline: "none",
    padding: "2px 2px 2px 8px",
    width: "64px",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  text: css({
    color: theme.colors.gray[300],
  }),
};
