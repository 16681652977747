import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { css } from "@styled-system/css";

import { Box, Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { EXPENSE_REPORT_TYPE } from "@constants";

const styles = {
  controlLabel: css({
    alignItems: "center",
    lineHeight: "body",
    color: "text",
    letterSpacing: "0.04rem",
    fontFamily: "'Open-sans', sans-serif",
    mr: 0,
    mb: "medium"
  }),
  radio: css({
    color: "border",
    ["&.Mui-checked"]: {
      color: "primary"
    }
  })
};

const REPORT_TYPES = [
  {
    value: EXPENSE_REPORT_TYPE.TRAVEL,
    title: "Relatório de viagens",
    text: "Relatório direcionado para despesas realizadas em viagem."
  },
  {
    value: EXPENSE_REPORT_TYPE.REGULAR,
    title: "Relatório geral",
    text:
      "Relatório destinado a despesas realizadas no dia-a-dia e não relacionadas a viagem."
  }
];

const RadioLabel = ({ title, text }) => {
  return (
    <Box>
      <Flex flexDirection="column">
        <Text fontSize={1}>{title}</Text>
        <Text fontSize={0} color={defaultTheme.subTextColor}>
          {text}
        </Text>
      </Flex>
    </Box>
  );
};

const ReportTypeSelect = props => {
  return (
    <RadioGroup {...props}>
      {REPORT_TYPES.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option.value}
          control={<Radio color="primary" css={styles.radio} />}
          label={<RadioLabel title={option.title} text={option.text} />}
          css={styles.controlLabel}
        />
      ))}
    </RadioGroup>
  );
};

export { ReportTypeSelect };
