import React, { useContext } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { css } from "@styled-system/css";

import { defaultTheme } from "@theme";

import { StOutlinedButton, StContainedButton } from "@shared/buttons";

import { ProjectsContext } from "../projects.context";

const styles = {
  dialogTitle: css({
    fontSize: 20,
    fontFamily: "inherit",
    lineHeight: 1.5,
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
  }),
  dialogActionsDiv: css({
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    margin: 0,
    padding: "1rem 0.5rem",
  }),
};

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export const InactivationDialog = () => {
  const {
    closeInactivationDialog,
    isInactiveDialogOpen,
    selectedToInactivate,
    inactiveProject,
  } = useContext(ProjectsContext);

  return (
    <Dialog
      open={isInactiveDialogOpen}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={closeInactivationDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <span css={styles.dialogTitle}>
          Deseja realmente arquivar o projeto{" "}
          {selectedToInactivate && selectedToInactivate.name}?
        </span>
      </DialogTitle>
      <DialogActions css={styles.dialogActionsDiv}>
        <StOutlinedButton onClick={closeInactivationDialog}>
          Cancelar
        </StOutlinedButton>
        <div>
          <StContainedButton onClick={inactiveProject} color="secondary">
            Arquivar
          </StContainedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};
