import { ERROR } from "@constants";
import * as advancedExpenseApi from "@apis/advanced-expense.api";
import * as expenseApprovalApi from "@apis/expense-approval.api";
import * as expensesApi from "@apis/expense.api";
import { CustomError } from "~/types";
import { AdvancedExpenseDetails } from "@models/advanced-expense.model";
import { AdvancedExpenseDto } from "@dtos/advanced-expense.dto";
import { AdvancedExpense } from "./AdvancedExpenses.types";

interface Result {
  data?: any[];
  error?: CustomError;
}

export async function fetchAdvancedExpenses(): Promise<Result> {
  const result: Result = {};

  try {
    result.data = await advancedExpenseApi.fetchAdvancedExpenses();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_ADVANCED_EXPENSE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_ADVANCED_EXPENSE_ERROR;
    }
  }
  return result;
}

export async function fetchUserApprovalRule(
  approvableType: string
): Promise<Result> {
  const result: Result = {};

  try {
    result.data = await expenseApprovalApi.fetchUserApprovalRule(
      approvableType
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR;
    }
  }

  return result;
}

export async function createAdvanceExpense(data: Partial<AdvancedExpenseDto>) {
  const result: { data?: AdvancedExpense; error?: CustomError } = {};

  try {
    result.data = await advancedExpenseApi.createAdvancedExpense(data);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_ADVANCED_EXPENSE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_ADVANCED_EXPENSE_ERROR;
    }
  }

  return result;
}

export async function fetchAdvancedExpenseDetails(
  selectedAdvancedExpenseToken: string
) {
  const result: { data?: AdvancedExpenseDetails; error?: CustomError } = {};

  try {
    result.data = await advancedExpenseApi.fetchAdvancedExpenseDetails(
      selectedAdvancedExpenseToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_ADVANCED_EXPENSE_DETAILS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_ADVANCED_EXPENSE_DETAILS_ERROR;
    }
  }

  return result;
}

export async function addAdvancedExpenseToReport(
  advancedExpenseToken: string,
  reportToken: string
) {
  const result: Result = {};

  try {
    result.data = await advancedExpenseApi.addAdvancedExpenseToReport(
      advancedExpenseToken,
      reportToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_VINCULATE_ADVANCE_EXPENSE_REPORT;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_VINCULATE_ADVANCE_EXPENSE_REPORT;
    }
  }

  return result;
}

export async function createReportWithAdvancedExpense(
  advancedExpenseToken: string,
  formData: { description: string; reportType: string }
): Promise<{ data?: string; error?: CustomError }> {
  const result: { data?: string; error?: CustomError } = {};

  try {
    const requestDto = {
      expense_advance_token: advancedExpenseToken,
      description: formData.description,
      report_type: formData.reportType
    };
    result.data = await expensesApi.createNewReport(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_ERROR;
    }
  }

  return result;
}
