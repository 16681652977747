import { useQuery } from "react-query";

import * as projectsApi from "~/apps/corporate/apis/projects.api";

export const useUserProjects = (userToken?: string, enabled?: boolean) => {
  const {
    data: projects,
    error: errorProjects,
    isLoading: isLoadingProjects,
  } = useQuery(
    ["users", userToken, "projects"],
    async () => {
      if (!userToken) {
        return;
      }

      return await projectsApi.getUserProjects(userToken);
    },
    {
      enabled: (enabled === undefined ? true : enabled) && !!userToken,
    },
  );

  return {
    projects,
    errorProjects,
    isLoadingProjects,
  };
};
