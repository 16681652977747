import React from "react";
import Button from "@material-ui/core/Button";
import { defaultTheme } from "../../../assets/styles/theme";
import { css } from "emotion";
import { theme } from "smartrips-skin";

const outlinedStyles = {
  root: css({
    textTransform: "none",
    textDecoration: "none",
    fontSize: "1rem",
    padding: ".5rem .5rem",
    fontWeight: "normal",
    lineHeight: "1.4em",
    letterSpacing: "normal",
    minHeight: 35,
    "&:disabled": {
      cursor: "not-allowed"
    },
    ["@media (max-width: 767px)"]: {
      minWidth: 100,
      fontSize: 12
    }
  }),
  outlined: css({
    backgroundColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: defaultTheme.cardBorderColor,
    transition: "all 0.3s ease",
    color: defaultTheme.textColor,
    "&:hover": {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor
    },
    "&:disabled": {
      cursor: "not-allowed"
    }
  }),
  outlinedPrimary: css({
    color: theme.colors.primary,
    borderColor: theme.colors.primary,
    backgroundColor: "#FFF",
    "&:hover": {
      color: "#FFF",
      backgroundColor: theme.colors.primary,
      borderColor: "#FFF"
    },
    "&:disabled": {
      cursor: "not-allowed"
    }
  }),
  label: css({
    lineHeight: "inherit",
    letterSpacing: "inherit"
  })
};

let StOutlinedButton = props => {
  const { children } = props;

  return (
    <Button variant="outlined" classes={outlinedStyles} {...props}>
      {children}
    </Button>
  );
};

export { StOutlinedButton };
