import React from "react";
import { AsyncDataType } from "~/components/shared/async-data/AsyncData.types";

const AsyncDataContext = React.createContext<AsyncDataType | undefined>(
  undefined
);

export function AsyncDataProvider(
  props: AsyncDataType & { children: React.ReactNode }
) {
  const { children, ...value } = props;

  return (
    <AsyncDataContext.Provider value={value}>
      {children}
    </AsyncDataContext.Provider>
  );
}

export function useAsyncDataContext() {
  const context = React.useContext(AsyncDataContext);

  if (!context) {
    throw new Error(
      "useAsyncDataContext só pode ser usado dentro de um AsyncDataProvider"
    );
  }

  return context;
}
