import React from "react";
import { css } from "emotion";
import Checkbox from "@material-ui/core/Checkbox";
import { defaultTheme } from "../../../assets/styles/theme";
import moment from "moment";
import * as utils from "../../../helpers";
import { HotelInvoiceItem } from "../../../models/financial.model";

const styles = {
  root: css({
    fontWeight: "normal",
    color: defaultTheme.subTextColor,
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    fontSize: 15,
    justifyContent: "space-between",
    width: "100%"
  }),
  checkboxDiv: css({
    marginRight: "1rem"
  }),
  checkbox: css({
    color: defaultTheme.cardBorderColor,
    padding: ".5rem",
    ["&:hover"]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor + "!important"
    }
  }),
  checkedCheckbox: css({
    color: `${defaultTheme.primaryColor} !important`
  }),
  largeColumn: css({
    width: "auto",
    textAlign: "left",
    padding: "1.5rem 0",
    fontWeight: "inherit"
  }),
  mediumColumn: css({
    width: "25%",
    textAlign: "right",
    padding: "1.5rem 0",
    fontWeight: "inherit"
  }),
  smallColumn: css({
    width: "15%",
    textAlign: "right",
    padding: "1.5rem 0 1.5rem 2.75rem",
    fontWeight: "inherit"
  }),
  valueColumn: css({
    width: "13%",
    textAlign: "right",
    padding: "1.5rem 0 1.5rem 2.75rem",
    fontWeight: "inherit"
  }),
  upperText: css({
    paddingBottom: ".5rem"
  })
};

interface Props {
  typeLabel: string;
  isSelected: boolean;
  hotelInvoice: HotelInvoiceItem;
  handleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const HotelItem = ({
  typeLabel,
  isSelected,
  hotelInvoice,
  handleSelect
}: Props) => {
  const { data, selectedOffer, traveler } = hotelInvoice;

  return (
    <tr className={styles.root}>
      <th className={styles.largeColumn}>
        <div style={{ display: "flex" }}>
          <div className={styles.checkboxDiv}>
            <Checkbox
              inputProps={{
                "aria-label": "Selecionar transação"
              }}
              checked={isSelected}
              onChange={handleSelect}
              classes={{
                root: styles.checkbox,
                checked: styles.checkedCheckbox
              }}
            />
          </div>
          <div>
            <div className={styles.upperText}>
              <span>Tipo da transação: {typeLabel}</span>
            </div>
            <div className={styles.upperText}>
              <span>
                <span style={{ textTransform: "capitalize" }}>
                  {selectedOffer.hotelName}
                </span>{" "}
                em <span>{data.hotelCity}</span>
              </span>
            </div>
            <div>
              <span>
                Código da reserva (localizador): {selectedOffer.operationalId}
              </span>
            </div>
          </div>
        </div>
      </th>
      <th className={styles.mediumColumn}>
        <div className={styles.upperText}>
          <span>Viajante: {traveler}</span>
        </div>
        <div>
          <span>ID da viagem: {selectedOffer.travelToken}</span>
        </div>
      </th>
      <th className={styles.smallColumn}>
        <div className={styles.upperText}>
          <span>Check-in</span>
        </div>
        <div>
          <span>{moment(data.hotelInitialDate).format("DD MMMM YYYY")}</span>
        </div>
      </th>
      <th className={styles.smallColumn}>
        <div className={styles.upperText}>
          <span>Check-out</span>
        </div>
        <div>
          <span>{moment(data.hotelEndDate).format("DD MMMM YYYY")}</span>
        </div>
      </th>
      <th className={styles.valueColumn}>
        <div className={styles.upperText}>
          <span>Valor</span>
        </div>
        <div>
          <span>{utils.toCurrency(selectedOffer.finalPrice)}</span>
        </div>
      </th>
    </tr>
  );
};

export { HotelItem };
