import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    ".MuiMenuItem": {
      "&-root": {
        color: theme.colors.gray[700],
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
      },
    },
  }),
};
