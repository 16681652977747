import React from "react";
import { components } from "react-select";
import AsyncAutoComplete from "react-select/async";

import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";
import isNumber from "lodash/isNumber";
import { Box, Flex, Text } from "smartrips-toolkit";
import uuid from "uuid";
import * as yup from "yup";

import { theme } from "@skin/v2";

import { Switch } from "@toolkit/v2";

import * as expenseApprovalHelper from "@helpers/expense-approval.helper";

import { ApprovableRule, TargetApproval } from "@models/expense-approval.model";
import { UserSearch } from "@models/user.model";

import DrawerHeader from "@shared/DrawerHeader";
import { Input } from "@shared/inputs";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { Form, useForm } from "@components/shared/form";

import { useAdvanceApprovalProcess } from "../advance-approval-process.context";
import * as expensesProcessService from "../advance-approval-process.service";
import { IForm, ApproverStage } from "../advance-approval-process.types";
import { SELECT_STYLES } from "../styles";
import { ApproverSelection } from "./ApproverSelection";

const styles = {
  drawer: {
    header: css({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
  },
  form: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1.5rem 1.5rem 0 1.5rem",
    height: "100%",
  }),
  label: css({
    display: "block",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.75rem",
    marginBottom: "0.5rem",
  }),
  switch: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      width: "fit-content",
      padding: "0.75rem 1rem",
      marginBottom: "1.5rem",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "0.5rem",
      cursor: "pointer",
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 600,
    }),
  },
  saveButton: css({
    display: "flex",
    justifyContent: "center",
    width: "8.5rem",
    height: "3rem",
  }),
};

const APPROVAL_LABELS = [
  "Primeira etapa: Aprovação de adiantamento",
  "Segunda etapa: Aprovação de adiantamento",
  "Terceira etapa: Aprovação de adiantamento",
  "Quarta etapa: Aprovação de adiantamento",
];

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.hasValue ? null : <Icon use="search" style={{ marginLeft: 4 }} />}
      {children}
    </components.Control>
  );
};

type FormContainerProps = {
  approvalRule: ApprovableRule | null;
};

export const FormContainer: React.FC<FormContainerProps> = ({
  approvalRule,
}) => {
  const {
    saving,
    handleSubmitForm,
    handleCloseForm,
  } = useAdvanceApprovalProcess();

  const buildDefaultValues = () => {
    if (!approvalRule) {
      return {
        target: null,
        rule: "all",
        expenseApprovers: [[]],
        paymentApprovers: [],
      };
    }

    const buildedApprovers = expenseApprovalHelper.formatApproversToForm(
      approvalRule.approvers,
    );

    return {
      token: approvalRule.approvableApprovalProcessToken,
      target: {
        label: approvalRule.itemInfo.name,
        name: approvalRule.itemInfo.name,
        token: approvalRule.itemInfo.token,
        type: approvalRule.type,
      },
      rule: approvalRule.rule,
      expenseApprovers: buildedApprovers.expenseApprovers,
      paymentApprovers: buildedApprovers.paymentApprovers,
    };
  };

  const context = useForm<IForm>({
    onSubmit: handleSubmitForm,
    defaultValues: buildDefaultValues(),
    schema: yup.object().shape({
      token: yup.string().nullable().optional(),
      target: yup
        .object()
        .shape({
          label: yup.string().required(),
          name: yup.string().required(),
          token: yup.string().required(),
          type: yup.string().required(),
        })
        .required(),
      rule: yup.string().required(),
      expenseApprovers: yup.array().min(1).required(),
      paymentApprovers: yup.array().min(1).required(),
    }),
  });

  const { setValue, watch } = context;

  const values = watch();

  const expenseApprovers = values.expenseApprovers as UserSearch[][];
  const paymentApprovers = values.paymentApprovers as UserSearch[];

  const isValid =
    values?.target &&
    values.expenseApprovers[0].length > 0 &&
    values.paymentApprovers.length > 0;

  const handleChangeTarget = (user: TargetApproval) => {
    setValue("target", user);
  };

  const isAlredyAdded = (approvers: UserSearch[], approver: UserSearch) =>
    !!approvers.find((item) => item.userToken === approver.userToken);

  const handleSelectApprover = (stage: ApproverStage, approver: UserSearch) => {
    if (isNumber(stage)) {
      if (!isAlredyAdded(expenseApprovers[stage], approver)) {
        const updatedApproverList = expenseApprovers.map((item, index) => {
          if (index === stage) {
            return item.concat(approver);
          }

          return item;
        });
        setValue("expenseApprovers", updatedApproverList);
      }
    } else {
      if (!isAlredyAdded(values.paymentApprovers, approver)) {
        setValue("paymentApprovers", values.paymentApprovers.concat(approver));
      }
    }
  };

  const handleRemoveApprover = (
    stage: ApproverStage,
    approverToken: string,
  ) => {
    if (isNumber(stage)) {
      const approvers = expenseApprovers[stage].filter(
        (item) => item.userToken !== approverToken,
      );
      const updatedApproverList = expenseApprovers.map((item, index) => {
        if (index === stage) {
          return approvers;
        }

        return item;
      });

      setValue("expenseApprovers", updatedApproverList);
    } else {
      setValue(
        "paymentApprovers",
        paymentApprovers.filter((item) => item.userToken !== approverToken),
      );
    }
  };

  const handleAddStage = () => {
    if (expenseApprovers.length < 4) {
      const newExpenseApprovers = expenseApprovers;
      newExpenseApprovers.push([]);

      setValue("expenseApprovers", newExpenseApprovers);
    }
  };

  const handleRemoveStage = (stage: number) => () => {
    const updatedExpenseApprovers = expenseApprovers.filter(
      (_, index) => index !== stage,
    );

    setValue("expenseApprovers", updatedExpenseApprovers);
  };

  const loadTargetUsers = async (search: string) => {
    return (await expensesProcessService.searchTargetUsers(search)).data;
  };

  const isEdition = !!approvalRule;
  const isSaving = saving;

  return (
    <>
      <DrawerHeader
        title={`${isEdition ? "Editar" : "Criar"} processo de aprovação`}
        handleClose={handleCloseForm}
        headerStyles={styles.drawer.header}
      />
      <Form context={context} css={styles.form}>
        <Box>
          <Box marginBottom="1rem">
            <Text css={styles.label}>Processo de aprovação para:</Text>
            {isEdition && values.target ? (
              <Input
                id="name"
                name="name"
                value={values.target.name}
                disabled={true}
                InputProps={{
                  style: { borderRadius: "0.5rem", height: "3rem" },
                }}
              />
            ) : (
              <AsyncAutoComplete
                placeholder="Buscar usuário"
                onChange={handleChangeTarget}
                loadOptions={loadTargetUsers}
                getOptionValue={(option) => option.token}
                getOptionLabel={(option) => option.name}
                isClearable
                noOptionsMessage={() =>
                  values?.target ? "" : "Nenhum usuário encontrado"
                }
                loadingMessage={() => "Carregando..."}
                components={{ Control }}
                styles={SELECT_STYLES}
              />
            )}
          </Box>
          <Box css={styles.switch.root}>
            <Switch active={true} variant="pink" disabled />
            <Text css={styles.switch.text}>
              Todos os adiantamentos precisam de aprovação
            </Text>
          </Box>

          {/* <FormControl style={{ width: "100%" }}>
          <RadioGroup
            id="rule"
            name="rule"
            aria-label="rule"
            value={values.rule}
            onChange={handleChange}
          >
            <FormControlLabel
              classes={{
                root: styles.controlLabelRoot
              }}
              control={
                <Radio
                  color="primary"
                  classes={{
                    root: styles.radioRoot,
                    checked: styles.radioChecked
                  }}
                />
              }
              label="Necessária aprovação para todos os relatórios"
              value={APPROVAL_RULES.ALL_NEED_APPROVAL}
            />
            <FormControlLabel
              classes={{
                root: styles.controlLabelRoot
              }}
              control={
                <Radio
                  color="primary"
                  disabled={true}
                  classes={{
                    root: styles.radioRoot,
                    checked: styles.radioChecked
                  }}
                />
              }
              label="Necessária aprovação relatórios fora da política (Em breve)"
              value={APPROVAL_RULES.OUT_POLICY_APPROVAL}
            />
          </RadioGroup>
        </FormControl> */}
          <Flex flexDirection="column" style={{ gap: "1rem" }}>
            {expenseApprovers.map((item, index) => (
              <ApproverSelection
                key={uuid()}
                stage={index}
                label={APPROVAL_LABELS[index]}
                approvers={item}
                handleSelect={handleSelectApprover}
                handleRemoveApprover={handleRemoveApprover}
                fetchApprover={expensesProcessService.searchUserByNameAndEmail}
                showRemoveStageButton={index !== 0}
                handleRemoveStage={handleRemoveStage(index)}
              />
            ))}
          </Flex>
          {expenseApprovers.length < 4 ? (
            <ContainedButton
              onClick={handleAddStage}
              style={{ height: "3rem", marginTop: "1rem" }}
              type="button"
            >
              Adicionar etapa
            </ContainedButton>
          ) : null}
          <ApproverSelection
            stage="payment"
            label="Etapa final: Confirmação de pagamento do adiantamento"
            approvers={values.paymentApprovers}
            handleSelect={handleSelectApprover}
            handleRemoveApprover={handleRemoveApprover}
            fetchApprover={expensesProcessService.searchUserByNameAndEmail}
          />
        </Box>
        <Flex
          style={{ alignSelf: "flex-end", gap: "1rem", padding: "1.5rem 0" }}
        >
          <ContainedButton
            loading={isSaving}
            disabled={isSaving || !isValid}
            onClick={context.submitForm}
            css={styles.saveButton}
          >
            Salvar
          </ContainedButton>

          <OutlinedButton onClick={handleCloseForm}>Cancelar</OutlinedButton>
        </Flex>
      </Form>
    </>
  );
};
