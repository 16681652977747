import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  handlePolicyToggle: () => void;
  onlyInPolicy: boolean;
};

export const PolicyFilter: React.FC<Props> = ({
  handlePolicyToggle,
  onlyInPolicy,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Política de Viagem</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        <CheckboxGroup.Item
          checked={onlyInPolicy}
          onChange={handlePolicyToggle}
          value="policy"
        >
          <span css={styles.text}>Dentro da política</span>
        </CheckboxGroup.Item>
      </CheckboxGroup>
    </div>
  );
};
