import React from "react";

import { ItineraryInfo } from "../../itinerary/itinerary-content/itinerary-info/itinerary-info";
import { usePayment } from "../payment.context";
import { PaymentDecision } from "./payment-decision/payment-decision";
import { PaymentDenied } from "./payment-denied/payment-denied";
import { PaymentPriceBreakdown } from "./payment-price-breakdown/payment-price-breakdown";
import { PaymentServicesCard } from "./payment-services-card/payment-services-card";
import { PaymentSucessful } from "./payment-successful/payment-successful";
import { styles } from "./styles";

export const PaymentContent: React.FC = () => {
  const { isBooked, isDenied } = usePayment();

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <ItineraryInfo />
        <PaymentServicesCard />
      </div>
      <div css={styles.right.root}>
        {isBooked ? (
          <PaymentSucessful />
        ) : isDenied ? (
          <PaymentDenied />
        ) : (
          <>
            <PaymentPriceBreakdown />
            <PaymentDecision />
          </>
        )}
      </div>
    </div>
  );
};
