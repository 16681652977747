import React, { useCallback } from "react";

import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import {
  useTravelsListDeletion,
  useTravelsListDeletionActions,
} from "../../trips.context";
import { styles } from "./styles";

export const TripDeletionDialog: React.FC = () => {
  const {
    isDeleting,
    isDeletionDialogVisible,
    selectedTravel,
  } = useTravelsListDeletion();
  const {
    handleCloseDeletionDialog,
    processTravelDeletion,
  } = useTravelsListDeletionActions();

  const handleClose = useCallback(() => {
    handleCloseDeletionDialog();
  }, [handleCloseDeletionDialog]);

  const handleConfirm = useCallback(async () => {
    await processTravelDeletion();
  }, [processTravelDeletion]);

  return (
    <AlertDialog
      confirmLabel="Excluir"
      isLoading={isDeleting}
      message={
        <span css={styles.message}>
          Deseja realmente excluir a viagem
          {selectedTravel && selectedTravel.name ? (
            <>
              {" "}
              <strong css={styles.strong}>
                &quot;{selectedTravel.name}&quot;
              </strong>
            </>
          ) : null}
          ?
        </span>
      }
      onClose={() => {
        handleClose();
      }}
      onConfirm={async () => {
        await handleConfirm();
      }}
      open={isDeletionDialogVisible}
      title="Excluir viagem."
    />
  );
};
