import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { Sheet } from "~/apps/shared/components/sheet/sheet";

import { Button } from "@toolkit/v2";

import { useCars } from "../../cars.context";
import { AirConditioningFilter } from "./air-conditioning-filter/air-conditioning-filter";
import { ClassesFilter } from "./classes-filter/classes-filter";
import { DoorsFilter } from "./doors-filter/doors-filter";
import { PolicyFilter } from "./policy-filter/policy-filter";
import { PriceFilter } from "./price-filter/price-filter";
import { RentalsFilter } from "./rentals-filter/rentals-filter";
import { styles } from "./styles";
import { TransmissionFilter } from "./transmission-filter/transmission-filter";

type Props = {
  isMobile?: boolean;
};

export const ContentFilters: React.FC<Props> = ({ isMobile }) => {
  const {
    filters,
    handleAirConditioningFilterChange,
    handleClassesFilterChange,
    handleDoorsFilterChange,
    handleInPolicyOnlyFilterChange,
    handlePriceFilterChange,
    handleRentalsFilterChange,
    handleTransmissionFilterChange,
    isLoading,
  } = useCars();

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleCloseSheet = useCallback(() => {
    setIsSheetOpen(false);
  }, []);

  const handleOpenSheet = useCallback(() => {
    setIsSheetOpen(true);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);

      return;
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        <Button
          css={styles.sheet.trigger.root}
          fill="outlined"
          onClick={handleOpenSheet}
        >
          <Icon
            css={styles.sheet.trigger.icon}
            size={20}
            use="adjustments-horizontal"
          />
          Filtrar resultados
        </Button>
        <Sheet
          anchor="bottom"
          disableSwipeToOpen
          onClose={handleCloseSheet}
          onOpen={handleOpenSheet}
          open={isSheetOpen}
        >
          <div css={styles.sheet.root}>
            <PermissionProtectedRender permissions={["travels.policies"]}>
              <PolicyFilter
                handleInPolicyOnlyFilterChange={handleInPolicyOnlyFilterChange}
                onlyInPolicy={filters.inPolicyOnly}
              />
            </PermissionProtectedRender>
            <AirConditioningFilter
              handleAirConditioningFilterChange={
                handleAirConditioningFilterChange
              }
              showAirConditioningOnly={filters.airConditioningOnly}
            />
            {filters.transmissionOptions.length > 0 ? (
              <TransmissionFilter
                handleTransmissionFilterChange={handleTransmissionFilterChange}
                transmissionOptions={filters.transmissionOptions}
              />
            ) : null}
            {filters.classesOptions.length > 0 ? (
              <ClassesFilter
                handleClassesFilterChange={handleClassesFilterChange}
                classOptions={filters.classesOptions}
              />
            ) : null}
            {filters.rentalsOptions.length > 0 ? (
              <RentalsFilter
                handleRentalsFilterChange={handleRentalsFilterChange}
                rentalOptions={filters.rentalsOptions}
              />
            ) : null}
            <PriceFilter
              disabled={isLoading}
              handleChangePriceRange={handlePriceFilterChange}
              priceRange={filters.priceRange}
              selectedRange={filters.selectedPriceRange}
            />
            {filters.doorsOptions.length > 0 ? (
              <DoorsFilter
                handleDoorsFilterChange={handleDoorsFilterChange}
                doorOptions={filters.doorsOptions}
              />
            ) : null}
          </div>
        </Sheet>
      </>
    );
  }

  return (
    <div css={styles.root}>
      <PermissionProtectedRender permissions={["travels.policies"]}>
        <PolicyFilter
          handleInPolicyOnlyFilterChange={handleInPolicyOnlyFilterChange}
          onlyInPolicy={filters.inPolicyOnly}
        />
      </PermissionProtectedRender>
      <AirConditioningFilter
        handleAirConditioningFilterChange={handleAirConditioningFilterChange}
        showAirConditioningOnly={filters.airConditioningOnly}
      />
      {filters.transmissionOptions.length > 0 ? (
        <TransmissionFilter
          handleTransmissionFilterChange={handleTransmissionFilterChange}
          transmissionOptions={filters.transmissionOptions}
        />
      ) : null}
      {filters.classesOptions.length > 0 ? (
        <ClassesFilter
          handleClassesFilterChange={handleClassesFilterChange}
          classOptions={filters.classesOptions}
        />
      ) : null}
      {filters.rentalsOptions.length > 0 ? (
        <RentalsFilter
          handleRentalsFilterChange={handleRentalsFilterChange}
          rentalOptions={filters.rentalsOptions}
        />
      ) : null}
      <PriceFilter
        disabled={isLoading}
        handleChangePriceRange={handlePriceFilterChange}
        priceRange={filters.priceRange}
        selectedRange={filters.selectedPriceRange}
      />
      <DoorsFilter
        handleDoorsFilterChange={handleDoorsFilterChange}
        doorOptions={filters.doorsOptions}
      />
    </div>
  );
};
