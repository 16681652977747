import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  info: () =>
    StyleSheet.create({
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 24,
        justifyContent: "space-between",
      },
    }),
  other: () =>
    StyleSheet.create({
      duration: {
        color: theme.colors.gray[700],
        fontSize: 11,
        fontWeight: 600 - 100,
      },
      name: {
        color: theme.colors.gray[700],
        fontSize: 11,
      },
      root: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        width: "40%",
      },
      seat: {
        color: theme.colors.gray[700],
        fontSize: 11,
      },
    }),
  root: () =>
    StyleSheet.create({
      image: {
        flexShrink: 0,
        maxHeight: 48,
        maxWidth: 144,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 24,
      },
    }),
  segment: () =>
    StyleSheet.create({
      place: {
        color: theme.colors.gray[700],
        fontSize: 11,
      },
      root: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
        paddingRight: 48,
      },
      time: {
        color: theme.colors.gray[700],
        fontSize: 11,
        fontWeight: 600 - 100,
        textAlign: "right",
      },
    }),
  segments: () =>
    StyleSheet.create({
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: "60%",
      },
    }),
};
