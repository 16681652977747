import React from "react";

import { Redirect, Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";

import { APIKeys } from "./api-keys/api-keys";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <APIKeys path="/api-keys" />
      <Redirect from="*" noThrow to="./api-keys" />
    </Router>
  );
};

export const DevelopersConfigurations: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
