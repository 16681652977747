import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};
