import { movidaAdditionalsCodesMap } from "./movida-additionals.constants";

type MovidaAdditionalsKeys = keyof typeof movidaAdditionalsCodesMap;

export type AccessoryAdditionalsKeys = Extract<
  MovidaAdditionalsKeys,
  "BEBE_CONFORTO" | "BOOSTER_ASSENTO_P_CRIANCAS" | "CADEIRA_DE_BEBE"
>;
export const accessoryAdditionalsKeys: AccessoryAdditionalsKeys[] = [
  "BEBE_CONFORTO",
  "BOOSTER_ASSENTO_P_CRIANCAS",
  "CADEIRA_DE_BEBE",
];

export type ProtectionAdditionalsKeys = Extract<
  MovidaAdditionalsKeys,
  | "PROTECAO_A_PNEUS_E_VIDROS_PLUS"
  | "PROTECAO_A_VIDROS"
  | "PROTECAO_BASICA"
  | "PROTECAO_PREMIUM"
  | "PROTECAO_SUPER"
>;
export const protectionAdditionalsKeys: ProtectionAdditionalsKeys[] = [
  "PROTECAO_A_PNEUS_E_VIDROS_PLUS",
  "PROTECAO_A_VIDROS",
  "PROTECAO_BASICA",
  "PROTECAO_PREMIUM",
  "PROTECAO_SUPER",
];

export type ServiceAdditionalsKeys = Extract<
  MovidaAdditionalsKeys,
  | "CARBON_FREE"
  | "CONDUTOR_ADICIONAL"
  | "LOCACAO_JOVEM_TODOS_OS_GRUPOS"
  | "RESPONSAVEL_FINANCEIRO"
>;
export const serviceAdditionalsKeys: ServiceAdditionalsKeys[] = [
  "CARBON_FREE",
  "CONDUTOR_ADICIONAL",
  "LOCACAO_JOVEM_TODOS_OS_GRUPOS",
  "RESPONSAVEL_FINANCEIRO",
];
