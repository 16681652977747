import React from "react";
import { ApprovalReportsList } from "./ApprovalReportsList/ApprovalReportsList";
import { Box, Flex } from "@toolkit";
import { StyledLink } from "../StyledLink";
import { Router } from "@reach/router";

const ExpenseReportApprovals = () => {
  return (
    <div>
      <Flex alignItems="center" mb={40}>
        <StyledLink to="">Aprovações pendentes</StyledLink>
        <StyledLink to="pending-payment">Pagamento pendente</StyledLink>
        <StyledLink to="paid">Relatórios pagos</StyledLink>
      </Flex>
      <Box>
        <Router>
          <ApprovalReportsList reportsStatusType="pending-approval" path="/" />
          <ApprovalReportsList
            reportsStatusType="pending-payment"
            path="/pending-payment"
          />
          <ApprovalReportsList reportsStatusType="paid" path="/paid" />
        </Router>
      </Box>
    </div>
  );
};

export { ExpenseReportApprovals };
