import React from "react";
import Skeleton from "react-loading-skeleton";

import { ItineraryPresentationalServices } from "~/apps/corporate/components/itinerary-presentational-services/itinerary-presentational-services";
import { OFFER_STATUS_COLOR } from "~/apps/shared/constants/offers.constants";

import {
  usePaymentServicesCardGuard,
  usePaymentServicesCardPresenter,
} from "./payment-services-card.hooks";
import { styles } from "./styles";

export const PaymentServicesCard: React.FC = () => {
  const { loading } = usePaymentServicesCardGuard();
  const { paymentServicesCardPresenter } = usePaymentServicesCardPresenter();

  if (loading) {
    return <PaymentServicesSkeleton />;
  }

  if (!paymentServicesCardPresenter) {
    return null;
  }

  const presentationalServices = paymentServicesCardPresenter.getPresentationalServices();
  const unavailableServiceTokens = paymentServicesCardPresenter.getUnavailableServiceTokens();

  return (
    <div css={styles.root}>
      <div css={styles.services.root}>
        {paymentServicesCardPresenter.hasPresentationalServices() ? (
          <ItineraryPresentationalServices
            color={OFFER_STATUS_COLOR.DRAFT}
            groups={presentationalServices}
            unavailableServiceTokens={unavailableServiceTokens}
          />
        ) : (
          <p css={styles.services.empty}>Nenhum serviço para prosseguir.</p>
        )}
      </div>
    </div>
  );
};

const PaymentServicesSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <hr css={styles.divisor} />
      <div css={styles.services.root}>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="14px" width="192px" />
          <Skeleton height="128px" width="100%" />
          <Skeleton height="128px" width="100%" />
        </div>
        <div css={styles.skeleton.section}>
          <Skeleton height="14px" width="96px" />
          <Skeleton height="128px" width="100%" />
        </div>
      </div>
    </div>
  );
};
