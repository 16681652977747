import React from "react";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

import { Flex, Box } from "smartrips-toolkit";

import Drawer from "@material-ui/core/Drawer";

import DrawerHeader from "@components/shared/DrawerHeader";

import {
  useBillingProfileFormActionsContext,
  useBillingProfileFormContext,
} from "../BillingProfiles.context";

import { DrawerSpinner } from "./DrawerSpinner";
import { BillingProfileForm as Form } from "./Form";

const styles = {
  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "4.25rem",
    backgroundColor: theme.colors.background.gray,
    paddingLeft: "0.5rem",
    fontSize: "1.125rem",
  }),
};

const DrawerContainer = () => {
  const {
    isFormOpen,
    isLoading,
    isSubmitting,
    selectedBillingProfile,
  } = useBillingProfileFormContext();
  const {
    handleCloseForm,
    handleSubmit,
  } = useBillingProfileFormActionsContext();

  return (
    <Drawer
      anchor="right"
      open={isFormOpen}
      onClose={handleCloseForm}
      PaperProps={{
        style: {
          width: 815,
        },
      }}
    >
      <Flex flexDirection="column" style={{ height: "100%" }}>
        <DrawerHeader
          title={
            selectedBillingProfile
              ? "Editando perfil de cobrança"
              : "Novo perfil de cobrança"
          }
          handleClose={handleCloseForm}
          headerStyles={styles.header}
        />
        <Box height="100%">
          {isLoading ? (
            <DrawerSpinner />
          ) : (
            <Form
              isSubmitting={isSubmitting}
              selectedProfile={selectedBillingProfile}
              onSubmit={handleSubmit}
              onClose={handleCloseForm}
            />
          )}
        </Box>
      </Flex>
    </Drawer>
  );
};

export { DrawerContainer as BillingProfileForm };
