const normalize = (w: string) => {
  return new String(w).normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

export const stringIncludes = (s1: string, s2: string) => {
  if (!s2) {
    return true;
  }

  return normalize(s1)
    .toLocaleLowerCase()
    .includes(normalize(s2).toLocaleLowerCase());
};
