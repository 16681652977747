import { BillingProfileResponseListItem } from "@dtos/billing-profile.dto";

import { BillingProfileListItem } from "~/models/billing-profile.model";

export function parseBillingProfileListItem(
  data: BillingProfileResponseListItem
): BillingProfileListItem {
  const parsedData: BillingProfileListItem = {
    billingProfileToken: data.billing_profile_token,
    city: data.billingProfileAddress?.city,
    cnpj: data.cnpj,
    complement: data.billingProfileAddress?.complement,
    createdAt: data.created_at,
    creditCard: data.credit_card_token,
    email: data.email,
    legalName: data.legal_name,
    name: data.name,
    neighborhood: data.billingProfileAddress?.neighborhood,
    number: data.billingProfileAddress?.number,
    phone: data.phone,
    state: data.billingProfileAddress?.state,
    street: data.billingProfileAddress?.street,
    zipCode: data.billingProfileAddress?.zip_code
  };

  return parsedData;
}
