import { css } from "@styled-system/css";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    width: "768px",
  }),
};
