import * as billingProfileApi from "@apis/billing-profile.api";
import {
  GetClientBillingProfilesResult,
  BillingProfileForm,
  GetSingleBillingProfileResult,
  CreateBillingProfileResult,
  UpdateBillingProfileResult,
  DeleteBillingProfileResult
} from "./BillingProfiles.types";
import { ERROR } from "@constants";

export async function getClientBillingProfilesList() {
  const result: GetClientBillingProfilesResult = {};

  try {
    result.data = await billingProfileApi.getClientBillingProfileList();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR;
    }
  }

  return result;
}

export async function getSingleBillingProfile(billingProfileToken: string) {
  const result: GetSingleBillingProfileResult = {};

  try {
    result.data = await billingProfileApi.getSingleBillingProfile(
      billingProfileToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_SINGLE_BILLING_PROFILE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_SINGLE_BILLING_PROFILE_ERROR;
    }
  }

  return result;
}

export async function createBillingProfile(formData: BillingProfileForm) {
  const result: CreateBillingProfileResult = {};

  try {
    const requestDto = buildBillingProfileCreationData(formData);
    result.data = await billingProfileApi.createBillingProfile(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_BILLING_PROFILE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_BILLING_PROFILE_ERROR;
    }
  }

  return result;
}

export async function updateBillingProfile(
  formData: BillingProfileForm,
  billingProfileToken: string
) {
  const result: UpdateBillingProfileResult = {};

  try {
    const requestDto = buildBillingProfileCreationData(formData);
    result.data = await billingProfileApi.updateBillingProfile(
      requestDto,
      billingProfileToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_BILLING_PROFILE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_BILLING_PROFILE_ERROR;
    }
  }

  return result;
}

export async function deleteBillingProfile(billingProfileToken: string) {
  const result: DeleteBillingProfileResult = {};

  try {
    result.data = await billingProfileApi.deleteBillingProfile(
      billingProfileToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DELETE_BILLING_PROFILE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DELETE_BILLING_PROFILE_ERROR;
    }
  }

  return result;
}

function buildBillingProfileCreationData(data: BillingProfileForm) {
  return {
    name: data.name,
    legal_name: data.legalName,
    phone: data.phone,
    cnpj: data.cnpj,
    zip_code: data.zipCode,
    street: data.street,
    number: data.number,
    neighborhood: data.neighborhood,
    complement: data.complement || undefined,
    city: data.city,
    state: data.state,
    email: data.email,
    credit_card_token: data.creditCard || undefined
  };
}
