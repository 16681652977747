import * as itineraryHelper from "~/apps/corporate/helpers/itinerary.helper";
import {
  ItineraryServicePresenterFactory,
  ItineraryServicesGroupedByOfferStatus,
} from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { OffersAvailabilitiesModel } from "~/apps/corporate/models/offer.model";
import { TravelApprovalModel } from "~/apps/corporate/models/travel.model";

import { ApprovalReviewServicesCardTabs } from "./approval-review-services-card";

class ApprovalReviewServicesCardPresenter {
  constructor(
    private readonly offersAvailabilitiesModel: OffersAvailabilitiesModel | null,
    private readonly travelApprovalModel: TravelApprovalModel,
  ) {}

  private getAllUnavailableOffersToReviewServices() {
    return this.travelApprovalModel
      .getAllOffersToReviewServices()
      .filter((service) => service.isUnavailable());
  }

  public getPresentationalServices() {
    const availableOffersToReviewServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.travelApprovalModel
        .getAllAvailableOffersToReviewServices()
        .map((service) => ItineraryServicePresenterFactory.create(service)),
    );

    const emittedServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.travelApprovalModel
        .getAllEmittedServices()
        .map((service) => ItineraryServicePresenterFactory.create(service)),
    );

    const unavailableOffersToReviewServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.getAllUnavailableOffersToReviewServices().map((service) =>
        ItineraryServicePresenterFactory.create(service),
      ),
    );

    return {
      "a-comprar": this.mergeAvailableAndUnavailableOffersToReviewServices(
        unavailableOffersToReviewServices,
        availableOffersToReviewServices,
      ),
      compras: emittedServices,
    };
  }

  public getPresentationalServicesCounts() {
    const presentationalServices = this.getPresentationalServices();

    return Object.entries(presentationalServices).reduce((prev, [k, v]) => {
      prev[k as ApprovalReviewServicesCardTabs] = Object.values(v).reduce(
        (prev, curr) =>
          prev + curr.reduce((prev, curr) => prev + curr.services.length, 0),
        0,
      );

      return prev;
    }, {} as Record<ApprovalReviewServicesCardTabs, number>);
  }

  public getUnavailableServiceTokens() {
    if (!this.offersAvailabilitiesModel) {
      return this.travelApprovalModel.getUnavailableServiceTokens();
    }

    return Array.from(
      new Set([
        ...this.offersAvailabilitiesModel.getUnavailableOfferTokens(),
        ...this.travelApprovalModel.getUnavailableServiceTokens(),
      ]),
    );
  }

  public hasEmittedServices() {
    return this.getPresentationalServicesCounts()["compras"] > 0;
  }

  private mergeAvailableAndUnavailableOffersToReviewServices(
    unavailableOffersToReviewServices: ItineraryServicesGroupedByOfferStatus,
    availableOffersToReviewServices: ItineraryServicesGroupedByOfferStatus,
  ) {
    return Object.entries(availableOffersToReviewServices).reduce(
      (prev, [k, v]) => {
        const frustratedPurchasesForKey =
          k in unavailableOffersToReviewServices
            ? unavailableOffersToReviewServices[
                k as keyof typeof unavailableOffersToReviewServices
              ]
            : [];

        prev[k as keyof typeof prev] = [...v, ...frustratedPurchasesForKey];

        return prev;
      },
      {} as typeof availableOffersToReviewServices,
    );
  }
}

export class ApprovalReviewServicesCardPresenterFactory {
  static create(
    offersAvailabilitiesModel: OffersAvailabilitiesModel | null,
    travelApprovalModel: TravelApprovalModel,
  ) {
    return new ApprovalReviewServicesCardPresenter(
      offersAvailabilitiesModel,
      travelApprovalModel,
    );
  }
}
