import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import {
  PolicyItem,
  PolicyForm,
  CreatePolicyDto,
  EditPolicyDto,
  PolicyTarget,
  SavePolicyTargetsDto,
} from "~/apps/corporate/models/policy.model";
import { api } from "~/apps/shared/services/api";
import { AxiosResponse } from "axios";

export async function createPolicy(
  createPolicyData: CreatePolicyDto,
): Promise<PolicyItem> {
  return await api
    .request({
      url: `/booking/clients/${getClientToken()}/policies`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: createPolicyData,
    })
    .then((response: AxiosResponse<any>) => {
      const {
        targetType,
        policyToken,
        description,
        targetCount,
      } = response.data;
      return {
        targetType,
        policyToken,
        description,
        targetCount,
      };
    });
}

export async function editPolicy(
  editPolicyData: EditPolicyDto,
): Promise<PolicyItem> {
  return await api
    .request({
      url: `/booking/policies/${editPolicyData.policy.policy_token}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: editPolicyData,
    })
    .then((response: AxiosResponse<PolicyItem>) => {
      return response.data;
    });
}

export async function deletePolicy(policyToken: string): Promise<any> {
  return await api
    .request({
      url: `/booking/policies/${policyToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });
}

export async function getPolicyDetailed(
  policy: PolicyItem,
): Promise<PolicyForm> {
  return await api
    .request({
      url: `/booking/policies/${policy.policyToken}`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<PolicyForm>) => {
      return response.data;
    });
}

export async function getPoliciesList(): Promise<PolicyItem[]> {
  return await api
    .request({
      url: `/booking/clients/${getClientToken()}/policies`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<PolicyItem[]>) => {
      return response.data;
    });
}

export async function getPolicyTargets(
  policy: PolicyItem,
): Promise<PolicyTarget[]> {
  return await api
    .request({
      url: `/booking/policies/${policy.policyToken}/targets`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<PolicyTarget[]>) => {
      return response.data;
    });
}

export async function savePolicyTargets(
  targetsData: SavePolicyTargetsDto,
): Promise<any> {
  return await api
    .request({
      url: `/booking/policies/${targetsData.policy_token}/targets`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: targetsData,
    })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });
}

export async function searchPolicyTargets(
  searchText: string,
): Promise<PolicyTarget[]> {
  return await api
    .request({
      url: `/booking/policies/targets/search/${searchText}`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then((response: AxiosResponse<PolicyTarget[]>) => {
      return response.data;
    });
}
