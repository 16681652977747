import React from "react";

import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";

import { Buses } from "./buses/buses";
import { CarResult } from "./cars/car-result/car-result";
import { Cars } from "./cars/cars";
import { Flights } from "./flights/flights";
import { HotelResult } from "./hotels/hotel-result/hotel-result";
import { Hotels } from "./hotels/hotels";
import { styles } from "./styles";

type Props = RouteComponentProps<{
  travelToken?: string;
}>;

const Outlet: React.FC<Props> = ({ travelToken }) => {
  return (
    <Router css={styles.router} primary={false}>
      <Buses path="/bus/:busToken" />
      <Cars path="/cars/:carToken" />
      <CarResult
        path="/cars/:carToken/:supplierOfferToken"
        travelToken={travelToken}
      />
      <Flights path="/flights/:flightToken" travelToken={travelToken} />
      <Hotels path="/hotels/:hotelToken" travelToken={travelToken} />
      <HotelResult
        path="/hotels/:hospitalityHotelToken/result/:searchHotelToken"
        travelToken={travelToken}
      />
      <Redirect from="*" noThrow to="/trips" />
    </Router>
  );
};

export const Search: React.FC<Props> = ({ ...props }) => {
  return (
    <>
      <ScrollToTop />
      <Outlet {...props} />
    </>
  );
};
