import * as costCenterApi from "@apis/cost-center.api";
import clientApi from "@apis/client.api";
import * as projectsApi from "@apis/projects.api";
import * as areasApi from "@apis/areas.api";
import * as billingProfileApi from "@apis/billing-profile.api";
import * as tagApi from "@apis/tag.api";
import * as expensesCategoryApi from "@apis/expenses-category.api";
import { Option } from "~/types";
import { CostCenterListItem } from "./performance/Performance.model";
import { CompanyAreaItem } from "@dtos/areas.dto";
import { UserCamelDto } from "@dtos/user.dto";
import { ClientTagsResponse } from "@dtos/tag.dto";
import { getClientPermissions } from "@helpers/user.helper";
import { ExpenseCategoryCamelCase } from "~/dtos/expense-categories.dto";

export async function loadCostCentersOptions(): Promise<Array<Option<string>>> {
  const {
    companySettings: { costCenters: costCentersPermission }
  } = getClientPermissions();

  return costCentersPermission
    ? costCenterApi.listCostCenters().then((items: CostCenterListItem[]) => {
        return items.map(item => ({
          label: item.name,
          value: item.token
        }));
      })
    : [];
}

export async function loadTravelerOptions(): Promise<Array<Option<string>>> {
  return clientApi.getUsers().then(users => {
    return users.map((user: UserCamelDto) => ({
      label: user.fullName,
      value: user.userToken
    }));
  });
}

export async function loadTagOptions(): Promise<Array<Option<string>>> {
  const {
    travels: { tags: tagsPermission }
  } = getClientPermissions();

  return tagsPermission
    ? tagApi.getClientTags().then(items => {
        return items.map((item: ClientTagsResponse) => ({
          label: item.tagName,
          value: item.tagToken
        }));
      })
    : [];
}

export async function loadProjectOptions(): Promise<Array<Option<string>>> {
  const {
    companySettings: { projects: projectsPermission }
  } = getClientPermissions();

  const projects = projectsPermission
    ? await projectsApi.listClientProjects()
    : [];
  return projects.map(item => ({
    label: item.name,
    value: item.projectToken
  }));
}

export async function loadCompanyAreaOptions(): Promise<Array<Option<string>>> {
  const {
    companySettings: { areas: areasPermission }
  } = getClientPermissions();

  const areas: CompanyAreaItem[] = areasPermission
    ? await areasApi.fetchClientAreas()
    : [];
  return areas.map(item => ({
    label: item.name,
    value: item.companyAreaToken
  }));
}

export async function loadBillingProfileOptions(): Promise<
  Array<Option<string>>
> {
  const billingProfiles = await billingProfileApi.getClientBillingProfileList();
  return billingProfiles.map(item => ({
    label: item.name,
    value: item.billingProfileToken
  }));
}

export async function loadExpenseCategoriesOptions(): Promise<
  ExpenseCategoryCamelCase[]
> {
  const expenseCategories = await expensesCategoryApi.getClientExpensesCategories();
  return expenseCategories;
}
