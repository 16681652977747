import React from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { CircularSpinner } from "@toolkit/v2";

import { useFlights } from "../flights.context";
import { styles } from "./styles";

export const FlightAvailabilityDialog: React.FC = () => {
  const { addingSituation } = useFlights();

  return (
    <Dialog disableRestoreFocus open={addingSituation}>
      <DialogHeader icon="airplane-taking-off" />
      <DialogContent>
        <DialogTitle>Checando disponibilidade.</DialogTitle>
        <span css={styles.message}>
          Estamos checando por alterações no preço ou disponibildade, por favor
          não feche a página.
        </span>
      </DialogContent>
      <CircularSpinner />
    </Dialog>
  );
};
