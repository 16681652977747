import { AxiosError, AxiosResponse } from "axios";

import { downloadFileFromUrl, downloadFile } from "~/helpers/download.helper";

import { getClientToken, getAuthorizationHeader } from "../helpers/user.helper";
import { api } from "./interceptors";

export type ExcelCreateError = {
  code: string;
  message: string;
  name: string;
  row: number;
};

function getFile(file: File): FormData {
  const formData = new FormData();
  formData.append("file", file);

  return formData;
}

function createBatchApiFactory(options: {
  url: () => string;
  modelFileDownloadUrl: string;
  modelFilename: string;
  originType: "url" | "api";
  uploadMethod: "POST" | "PUT";
}) {
  return {
    onUpload: async function onUpload(file: File) {
      return api
        .request({
          url: options.url(),
          method: options.uploadMethod,
          headers: getAuthorizationHeader(),
          data: getFile(file)
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError<ExcelCreateError>) => {
          throw error.response?.data;
        });
    },
    onDownloadModelClick: async function onDownloadModelClick() {
      if (options.originType === "api") {
        return api
          .request({
            url: `/booking/clients/${getClientToken()}${
              options.modelFileDownloadUrl
            }`,
            method: "GET",
            headers: {
              ...getAuthorizationHeader(),
              "Content-Type":
                "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"
            },
            responseType: "arraybuffer"
          })
          .then((response: AxiosResponse) => {
            downloadFile({
              data: response.data,
              filename: options.modelFilename
            });
          });
      }
      return api
        .request({
          url: options.modelFileDownloadUrl,
          method: "GET",
          headers: getAuthorizationHeader()
        })
        .then((response: AxiosResponse) => {
          downloadFileFromUrl({
            url: response.data,
            filename: options.modelFilename
          });
        });
    }
  };
}

export const costCenterBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/cost-center/excel`,
  modelFileDownloadUrl: "/booking/excel/download/costCenter",
  modelFilename: "cost-center.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const updateCostCentersBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/cost-centers/excel`,
  modelFileDownloadUrl: `/cost-centers/excel`,
  modelFilename: "cost-centers.xlsx",
  originType: "api",
  uploadMethod: "PUT"
});

export const companyAreaBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/company-area/excel`,
  modelFileDownloadUrl: "/booking/excel/download/companyArea",
  modelFilename: "company-area.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const updateCompanyAreasBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/company-area/excel`,
  modelFileDownloadUrl: `/company-area/excel`,
  modelFilename: "company-areas.xlsx",
  originType: "api",
  uploadMethod: "PUT"
});

export const boardBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/board/excel`,
  modelFileDownloadUrl: "/booking/excel/download/board",
  modelFilename: "board.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const tagBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/tag/excel`,
  modelFileDownloadUrl: "/booking/excel/download/tag",
  modelFilename: "tag.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const projectBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/project/excel`,
  modelFileDownloadUrl: "/booking/excel/download/project",
  modelFilename: "project.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const updateProjectsBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/projects/excel`,
  modelFileDownloadUrl: `/projects/excel`,
  modelFilename: "project-list.xlsx",
  originType: "api",
  uploadMethod: "PUT"
});

export const userBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/user/excel`,
  modelFileDownloadUrl: "/booking/excel/download/user",
  modelFilename: "user.model.xlsx",
  originType: "url",
  uploadMethod: "POST"
});

export const updateUsersBatchApi = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/users/excel`,
  modelFileDownloadUrl: `/users/excel/download`,
  modelFilename: "user-list.xlsx",
  originType: "api",
  uploadMethod: "PUT"
});

export const createUserApprovalFlows = createBatchApiFactory({
  url: () => `/booking/clients/${getClientToken()}/approval-flows/excel`,
  modelFilename: "userApprovalProcess.model.xlsx",
  modelFileDownloadUrl: "/booking/excel/download/approval-flows?type=USER",
  originType: "url",
  uploadMethod: "POST"
});
