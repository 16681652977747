import React from "react";

import { Drawer } from "~/apps/shared/components/drawer/drawer";

import { usePolicies } from "../travel-policies.context";
import { PolicyFormLoading } from "./policy-form-loading/policy-form-loading";
import { PolicyForm } from "./policy-form/policy-form";

export const PolicyDrawer: React.FC = () => {
  const {
    form,
    formVisible,
    loadingPolicyDetails,
    handleFormClose,
    saving: isSaving,
  } = usePolicies();

  return (
    <Drawer onClose={handleFormClose} open={!!formVisible}>
      {!loadingPolicyDetails ? (
        <PolicyForm form={form} isSaving={isSaving} />
      ) : (
        <PolicyFormLoading />
      )}
    </Drawer>
  );
};
