import React from "react";

import Collapse from "@material-ui/core/Collapse";
import { css } from "emotion";

import { MEDIA_QUERIES } from "@constants";

import * as utils from "@helpers";

import { AdvancedExpense } from "@models/advanced-expense.model";
import {
  Expense,
  ExpenseReportApprovalStatusApprover
} from "@models/expense.model";

import { defaultTheme } from "@theme";

import { StContainedButton } from "@shared/buttons";
import { Row } from "@shared/layout/Row";

import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";

import { ExpensesBreakdown } from "../../shared/ExpensesBreakdown";
import { ReportApprovalForm } from "../ApprovalForm";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0 0 4px 4px",
    backgroundColor: defaultTheme.applicationBackground,
    marginBottom: "2rem",
    width: "100%",
    color: defaultTheme.textColor,
    lineHeight: 1.5,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 0,
      marginBottom: 0
    }
  }),
  segment: css({
    padding: "1rem 2rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  lastSegment: css({
    padding: "1rem 2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  title: css({
    padding: "1rem 1.25rem",
    fontWeight: "bold",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    color: defaultTheme.secondaryColor
  }),
  subTitle: css({
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: "1rem"
  }),
  cardStrip: css({
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: "100%",
    height: ".75rem",
    backgroundColor: defaultTheme.secondaryColor,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      display: "none"
    }
  })
};

interface Props {
  approver: ExpenseReportApprovalStatusApprover;
  message: string | null;
  expenses: Expense[];
  advancedExpenses: AdvancedExpense[];
  totalPrice: number;
  isApprovalFormVisible: boolean;
  isSending: boolean;
  isSendButtonVisible: boolean;
  isSendButtonEnabled: boolean;
  handleSend: () => void;
}

const ApprovalDeniedCard = ({
  approver,
  message,
  expenses,
  advancedExpenses,
  totalPrice,
  isApprovalFormVisible,
  isSending,
  isSendButtonVisible,
  isSendButtonEnabled,
  handleSend
}: Props) => {
  return (
    <div>
      <div className={styles.cardStrip} />
      <div className={styles.root}>
        <div className={styles.title}>Pedido de aprovação negado</div>
        <section className={styles.segment}>
          <div>
            <div>
              <span className={styles.subTitle}>Quem recusou: </span>
              <div style={{ fontSize: 14 }}>{approver.fullName}</div>
            </div>
          </div>
        </section>
        {message ? (
          <section className={styles.segment}>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 14 }}>
                Mensagem:
              </span>
            </div>
            <div style={{ fontSize: 14 }}>
              <span>{message}</span>
            </div>
          </section>
        ) : null}
        <section className={styles.segment}>
          <div style={{ paddingBottom: "1.5rem" }}>
            <p style={{ fontWeight: "bold", fontSize: 15 }}>
              Serviços selecionados
            </p>
          </div>
          <div style={{ paddingBottom: "0.5rem" }}>
            <ExpensesBreakdown
              expensesData={expenses}
              advancedExpensesData={advancedExpenses}
              type="approval"
            >
              {(totalExpenseValue, approvedValue, totalAdvancedExpense) => (
                <div>
                  <div>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        color: defaultTheme.grays.dark
                      }}
                    >
                      <p>Total solicitado</p>
                      <p>{utils.toCurrency(totalExpenseValue)}</p>
                    </Row>
                  </div>
                  <div>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14
                      }}
                    >
                      <p>Total aprovado</p>
                      <p>{utils.toCurrency(approvedValue)}</p>
                    </Row>
                  </div>
                  <div>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        color: defaultTheme.reds.lightRed
                      }}
                    >
                      <p>Adiantamento</p>
                      <p>- {utils.toCurrency(totalAdvancedExpense)}</p>
                    </Row>
                  </div>
                </div>
              )}
            </ExpensesBreakdown>
          </div>
        </section>
        {isSendButtonVisible ? (
          <React.Fragment>
            <PermissionVisibility
              permissions={[
                "companySettings.costCenters",
                "companySettings.billingProfiles",
                "companySettings.areas",
                "companySettings.projects"
              ]}
              atLeastOne={true}
            >
              <Collapse in={isApprovalFormVisible}>
                <ReportApprovalForm />
              </Collapse>
            </PermissionVisibility>
            <div style={{ padding: "1.5rem 1.25rem" }}>
              <StContainedButton
                style={{
                  fontWeight: "600",
                  fontSize: 14
                }}
                loading={isSending}
                disabled={!isSendButtonEnabled || isSending}
                onClick={handleSend}
              >
                Enviar novamente para aprovação
              </StContainedButton>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export { ApprovalDeniedCard };
