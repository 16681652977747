import React, { useCallback } from "react";
import { DateRangePicker as ReactDatesRangeDatePicker } from "react-dates";

import { useWindowSize } from "~/apps/shared/hooks/use-window-size";
import uuid from "uuid";

import { Icon } from "../../icon/icon";
import { styles } from "./styles";

export const defaultNavNext = <Icon size={16} use="chevron-right" />;
export const defaultNavPrev = <Icon size={16} use="chevron-left" />;

const renderPortalRangePickerHeader = ({
  endDate,
  endDatePlaceholderText,
  onFocusChange,
  startDate,
  startDatePlaceholderText,
}: Pick<
  React.ComponentPropsWithoutRef<typeof RangeDatePicker>,
  | "endDate"
  | "endDatePlaceholderText"
  | "onFocusChange"
  | "startDate"
  | "startDatePlaceholderText"
>) =>
  // eslint-disable-next-line react/display-name
  () => {
    const formattedEndDate = endDate
      ? endDate.format("DD/MM/YYYY")
      : endDatePlaceholderText;
    const formattedStartDate = startDate
      ? startDate.format("DD/MM/YYYY")
      : startDatePlaceholderText;

    const setEndFocused = () => onFocusChange("endDate");
    const setStartFocused = () => onFocusChange("startDate");

    return (
      <div css={styles.vertical.header.root}>
        <Icon size={16} use="calendar" />
        <div css={styles.vertical.header.date.root} onClick={setStartFocused}>
          <span css={styles.vertical.header.date.text}>
            {formattedStartDate}
          </span>
        </div>
        <div css={styles.vertical.header.divisor}>—</div>
        <div css={styles.vertical.header.date.root} onClick={setEndFocused}>
          <span css={styles.vertical.header.date.text}>{formattedEndDate}</span>
        </div>
      </div>
    );
  };

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof ReactDatesRangeDatePicker>,
  "endDateId" | "startDateId" | "minimumNights"
> &
  Partial<
    Pick<
      React.ComponentPropsWithoutRef<typeof ReactDatesRangeDatePicker>,
      "endDateId" | "startDateId"
    >
  > & {
    allowSameDay?: boolean;
  };

export const RangeDatePicker: React.FC<Props> = ({
  allowSameDay,
  ...props
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 992;

  const getProps = useCallback(
    ({
      ...props
    }: Props): React.ComponentPropsWithoutRef<
      typeof ReactDatesRangeDatePicker
    > => {
      if (isMobile) {
        return {
          appendToBody: false,
          calendarInfoPosition: "top",
          endDateId: uuid(),
          hideKeyboardShortcutsPanel: true,
          minimumNights: allowSameDay ? 0 : undefined,
          navNext: defaultNavNext,
          navPrev: defaultNavPrev,
          orientation: "vertical",
          readOnly: true,
          renderCalendarInfo: renderPortalRangePickerHeader({
            endDate: props.endDate,
            endDatePlaceholderText: props.endDatePlaceholderText,
            onFocusChange: props.onFocusChange,
            startDate: props.startDate,
            startDatePlaceholderText: props.startDatePlaceholderText,
          }),
          startDateId: uuid(),
          withFullScreenPortal: true,
          ...props,
        };
      }

      return {
        endDateId: uuid(),
        hideKeyboardShortcutsPanel: true,
        minimumNights: allowSameDay ? 0 : undefined,
        navNext: defaultNavNext,
        navPrev: defaultNavPrev,
        noBorder: true,
        startDateId: uuid(),
        ...props,
      };
    },
    [isMobile],
  );

  return (
    <div css={styles.root}>
      <ReactDatesRangeDatePicker {...getProps(props)} />
    </div>
  );
};
