import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    root: css({
      alignItems: "center",
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      justifyContent: "center",
      padding: "24px",
      width: "100%",
      "@media (max-width: 992px)": {
        display: "none",
      },
    }),
    selection: {
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }),
      top: {
        classes: css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.pink[500],
          cursor: "pointer",
          fontFamily: theme.fonts.inter,
          fontWeight: 600,
          textDecoration: "underline",
          ":hover": {
            color: theme.colors.pink[700],
          },
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[300],
          display: "flex",
          fontSize: "0.875rem",
          gap: "8px",
        }),
      },
    },
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  }),
  top: {
    left: {
      root: css({
        alignItems: "center",
        display: "flex",
        padding: "24px",
        width: "100%",
        "@media (max-width: 640px)": {
          borderBottom: `1px solid ${theme.colors.gray[100]}`,
        },
      }),
    },
    right: {
      button: css({
        justifyContent: "center",
        padding: "14px 44.5px",
        width: "171px",
        "@media (max-width: 640px)": {
          width: "100%",
        },
      }),
      price: css({
        color: theme.colors.gray[700],
        fontSize: "1.5rem",
        fontWeight: 700,
      }),
      root: css({
        alignItems: "center",
        borderLeft: `1px solid ${theme.colors.gray[100]}`,
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        gap: "8px",
        justifyContent: "flex-end",
        padding: "24px",
        "@media (max-width: 640px)": {
          alignItems: "flex-start",
          borderLeft: "unset",
          gap: "12px",
        },
      }),
    },
    root: css({
      display: "flex",
      "@media (max-width: 640px)": {
        flexDirection: "column",
        marginBottom: "8px",
      },
    }),
  },
};
