import * as expensesApi from "../../apis/expense.api";
import { ExpenseReportList, ExpensesList } from "../../models/expense.model";
import { CustomError, ImageFile } from "../../types";
import { ERROR } from "../../constants";
import { GetUserExpenseApproverProcessesResponseBody } from "../../dtos/expenses.dto";

export async function getPendingExpenses(
  page: number,
  search?: string
): Promise<{
  data?: ExpensesList;
  error?: CustomError;
}> {
  const result: { data?: ExpensesList; error?: CustomError } = {};

  try {
    result.data = await expensesApi.listPendingExpenses(page, search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_LIST_ERROR;
    }
  }

  return result;
}

export async function deleteExpense(
  expenseToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  try {
    result.data = await expensesApi.deleteExpense(expenseToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSE_DELETE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSE_DELETE_ERROR;
    }
  }

  return result;
}

export async function listReportsByUser(
  page: number,
  status: string
): Promise<{ data?: ExpenseReportList; error?: CustomError }> {
  const result: { data?: ExpenseReportList; error?: CustomError } = {};

  try {
    result.data = await expensesApi.listReportsByUser(page, status);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR;
    }
  }

  return result;
}

export async function addExpensesToReport(
  expenseTokens: string[],
  expenseReportToken: string
): Promise<{ data?: any; error?: CustomError }> {
  const result: { data?: any; error?: CustomError } = {};

  const expenses = expenseTokens.map(token => ({ expense_token: token }));

  try {
    result.data = await expensesApi.addExpensesToReport(
      { expenses },
      expenseReportToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_EXPENSES_TO_REPORT_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_EXPENSES_TO_REPORT_ERROR;
    }
  }

  return result;
}

export async function createReportWithExpenses(
  expenseTokens: string[],
  formData: { description: string; reportType: string }
): Promise<{ data?: string; error?: CustomError }> {
  const result: { data?: string; error?: CustomError } = {};

  const expenses = expenseTokens.map(token => ({ expense_token: token }));

  try {
    const requestDto = {
      expenses,
      description: formData.description,
      report_type: formData.reportType
    };
    result.data = await expensesApi.createNewReport(requestDto);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REPORT_CREATE_ERROR;
    }
  }

  return result;
}

export async function getReceiptImageFile(
  imageUrl: string
): Promise<{ data?: ImageFile; error?: CustomError }> {
  const result: { data?: ImageFile; error?: CustomError } = {};

  try {
    result.data = await expensesApi.getImageFile(imageUrl);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR;
    }
  }

  return result;
}

export async function checkUserExpensesApprovalProcesses(): Promise<{
  data?: GetUserExpenseApproverProcessesResponseBody;
  error?: CustomError;
}> {
  const result: {
    data?: GetUserExpenseApproverProcessesResponseBody;
    error?: CustomError;
  } = {};

  try {
    result.data = await expensesApi.checkUserExpensesApprovalProcesses();
  } catch (error) {
    result.error = ERROR.UNEXPECTED_CHECK_USER_EXPENSES_PROCESSES_ERROR;
  }

  return result;
}
