import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import {
  useItineraryPriceBreakdownGuard,
  useItineraryPriceBreakdownPresenter,
} from "./itinerary-price-breakdown.hooks";
import { styles } from "./styles";

type Props = {
  showReservedServicesToggle?: boolean;
};

export const ItineraryPriceBreakdown: React.FC<Props> = ({
  showReservedServicesToggle,
}) => {
  const { error, loading } = useItineraryPriceBreakdownGuard();
  const {
    itineraryPriceBreakdownPresenter,
  } = useItineraryPriceBreakdownPresenter();

  const [showReservedServices, setShowReservedServices] = useState(false);

  if (error) {
    return <ItineraryPriceBreakdownError />;
  }

  if (loading) {
    return <ItineraryPriceBreakdownSkeleton />;
  }

  if (!itineraryPriceBreakdownPresenter) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        {itineraryPriceBreakdownPresenter.hasProcessableServices() ? (
          <ul css={styles.section.prices.root}>
            {itineraryPriceBreakdownPresenter
              .getProcessableServices()
              .map((servicePresenter) => (
                <li
                  css={styles.section.prices.price.root({})}
                  key={servicePresenter.getOfferToken()}
                >
                  <span css={styles.section.prices.price.label}>
                    {servicePresenter.getFormattedName()}:
                  </span>
                  <span css={styles.section.prices.price.value({})}>
                    {servicePresenter.getFormattedPrice()}
                  </span>
                </li>
              ))}
          </ul>
        ) : (
          <span css={styles.section.empty}>
            Não há ofertas para prosseguir. Revise as informações.
          </span>
        )}
        <div css={styles.section.prices.price.total}>
          <span css={styles.section.prices.price.label}>
            {itineraryPriceBreakdownPresenter.hasReservedServices()
              ? "Valor a pagar"
              : "Valor total da viagem"}
            :
          </span>
          <span css={styles.section.prices.price.value({})}>
            {itineraryPriceBreakdownPresenter.getFormattedProcessableServicesTotalPrice()}
          </span>
        </div>
        {itineraryPriceBreakdownPresenter.showConvertedPriceWarning() ? (
          <span css={styles.disclaimer}>
            * Esse preço foi convertido para mostrar o valor aproximado em R$.
            Você irá pagar cada oferta em sua respectiva moeda local. A taxa de
            câmbio pode variar antes de você confirmar o pagamento.
          </span>
        ) : null}
        {itineraryPriceBreakdownPresenter.hasReservedServices() ? (
          <>
            {showReservedServices ? (
              <>
                <hr css={styles.divisor} />
                <div css={styles.section.root}>
                  <ul css={styles.section.prices.root}>
                    {itineraryPriceBreakdownPresenter
                      .getReservedServices()
                      .map((servicePresenter) => (
                        <li
                          css={styles.section.prices.price.root({
                            isUnavailable: servicePresenter.isUnavailable(),
                          })}
                          key={servicePresenter.getOfferToken()}
                        >
                          <span css={styles.section.prices.price.label}>
                            {servicePresenter.getFormattedName()}
                          </span>
                          <span
                            css={styles.section.prices.price.value({
                              isReserved: true,
                            })}
                          >
                            {servicePresenter.getFormattedPrice()}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            ) : null}
            {showReservedServicesToggle ? (
              <button
                css={styles.section.toggle}
                onClick={() => {
                  setShowReservedServices(!showReservedServices);
                }}
              >
                {showReservedServices
                  ? "Ocultar serviços já reservados."
                  : "Mostrar serviços já reservados."}
              </button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const ItineraryPriceBreakdownError: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <span css={styles.error}>
          Ocorreu um erro. Tente novamente mais tarde.
        </span>
      </div>
    </div>
  );
};

const ItineraryPriceBreakdownSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Resumo de preços:</span>
      <hr css={styles.divisor} />
      <div css={styles.section.root}>
        <ul css={styles.section.prices.root}>
          <li css={styles.section.prices.price.root({})}>
            <Skeleton height="14px" width="192px" />
            <Skeleton height="14px" width="64px" />
          </li>
          <li css={styles.section.prices.price.root({})}>
            <Skeleton height="14px" width="144px" />
            <Skeleton height="14px" width="72px" />
          </li>
        </ul>
        <div css={styles.section.prices.price.total}>
          <span css={styles.section.prices.price.label}>
            <Skeleton height="14px" width="288px" />
          </span>
          <span css={styles.section.prices.price.value({})}>
            <Skeleton height="14px" width="48px" />
          </span>
        </div>
      </div>
    </div>
  );
};
