import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { BusItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";

import { TravelPlanPDFServiceCancelationInfo } from "../../travel-plan-pdf-service-cancelation-info/travel-plan-pdf-service-cancelation-info";
import { TravelPlanPDFServiceCancelationUntil } from "../../travel-plan-pdf-service-cancelation-until/travel-plan-pdf-service-cancelation-until";
import { TravelPlanPDFServiceCanceledBy } from "../../travel-plan-pdf-service-canceled-by/travel-plan-pdf-service-canceled-by";
import { TravelPlanPDFServiceReservationCode } from "../../travel-plan-pdf-service-reservation-code/travel-plan-pdf-service-reservation-code";
import { styles } from "./styles";
import { TravelPlanPDFBusSegment } from "./travel-plan-pdf-bus-segment/travel-plan-pdf-bus-segment";

type Props = {
  servicePresenter: BusItineraryServicePresenter;
};

export const TravelPlanPDFBusJourney: React.FC<Props> = ({
  servicePresenter,
}) => {
  const badgeStyles = styles.badge();
  const rootStyles = styles.root();

  const cancelInfo = servicePresenter.getCancelationInfo();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();
  const operationalId = servicePresenter.getOperationalId();
  const seats = servicePresenter.getAllSeats();

  return (
    <View style={rootStyles.root}>
      {servicePresenter.showReservation() && operationalId ? (
        <TravelPlanPDFServiceReservationCode reservationCode={operationalId} />
      ) : null}
      <View style={rootStyles.card}>
        {servicePresenter.getAllJourneys().map((journey, index) => (
          <React.Fragment key={journey.index}>
            {index !== 0 ? <hr style={rootStyles.divisor} /> : null}
            <View style={rootStyles.segments}>
              {journey.segments.map((segment) => (
                <TravelPlanPDFBusSegment
                  arrival={{
                    address: segment.arrival.terminal,
                    time: journey.arrival.time,
                  }}
                  busName={segment.busType}
                  carrierLogo={segment.carrier.imageUrl}
                  carrierName={segment.carrier.name}
                  departure={{
                    address: segment.departure.terminal,
                    time: journey.departure.time,
                  }}
                  duration={segment.duration}
                  key={segment.index}
                  seat={journey.seat}
                />
              ))}
            </View>
          </React.Fragment>
        ))}
      </View>
      {seats.length > 0 ? (
        <View style={badgeStyles.root}>
          <Icon size={14} use="bus" />
          <View>
            <Text>Assento(s) selecionado(s): {seats.join(", ")}.</Text>
          </View>
        </View>
      ) : null}
      <View style={badgeStyles.root}>
        <Icon size={14} use="ticket" />
        <View>
          <Text>
            {servicePresenter.isOneWay()
              ? "A passagem de ida deve ser impressa no local de embarque."
              : "As passagens de ida e de volta devem ser impressas no local de embarque."}
          </Text>
        </View>
      </View>
      {formattedFreeCancelationUntil ? (
        <TravelPlanPDFServiceCancelationUntil
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          message="(horário local da companhia)"
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <TravelPlanPDFServiceCancelationInfo
          cancelFee={cancelInfo.fee ? cancelInfo.fee.total : null}
          cancelType={cancelInfo.type}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage && (
        <TravelPlanPDFServiceCanceledBy
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      )}
    </View>
  );
};
