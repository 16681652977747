import { userCreationContants } from "../constants/user-creation.constant";
import { ROLES } from "../constants";

interface UserError {
  title: string;
  description: string;
}

export interface ReduxUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  documents: any[];
}

export interface IState {
  user: any;
  formVisible: boolean;
  saving: boolean;
  error: UserError;
}

interface Action {
  type: string;
  user: any;
  formVisible: boolean;
  saving: boolean;
  error: UserError;
}

const initialUser: any = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  documents: [],
  capabilities: null
};

const initialState: IState = {
  user: initialUser,
  formVisible: false,
  saving: false,
  error: {
    title: "",
    description: ""
  }
};

export function userCreationReducer(state = initialState, action: Action) {
  switch (action.type) {
    case userCreationContants.OPEN_FORM:
      return {
        ...state,
        formVisible: true
      };
    case userCreationContants.CLOSE_FORM:
      return {
        ...state,
        formVisible: false,
        user: initialUser
      };
    case userCreationContants.SET_USER_DATA:
      return {
        ...state,
        user: action.user
      };
    case userCreationContants.CREATION_REQUEST:
      return {
        ...state,
        saving: true
      };
    case userCreationContants.CREATION_SUCCESS:
      return {
        ...state,
        formVisible: false,
        saving: false,
        user: initialUser
      };
    case userCreationContants.CREATION_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error
      };
    default:
      return state;
  }
}
