import React, { useMemo } from "react";

import { FlightListOffer } from "~/apps/corporate/models/offer.model";
import { AlertBox } from "~/apps/shared/components/alert-box/alert-box";

import { FlightDetails } from "../../flight-details/flight-details";
import { useFlights } from "../../flights.context";
import { FlightSummary } from "../flight-summary/flight-summary";
import { styles } from "./styles";

type Props = {
  flight: FlightListOffer;
  handleShowOutPolicyInfo(): void;
  isAdding: boolean;
  onAddToTrip(): void;
  onReserveChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

export const FlightExtras: React.FC<Props> = ({
  flight,
  handleShowOutPolicyInfo,
  isAdding,
  onAddToTrip,
  onReserveChange,
}) => {
  const { planeLimitInfo } = useFlights();

  return (
    <div css={styles.root}>
      {planeLimitInfo.isLimitExceeded ? (
        <FlightExtrasPlaneLimitMessage />
      ) : null}
      <div css={styles.body.root}>
        <div css={styles.body.details.root}>
          <FlightDetails
            css={styles.body.details.details}
            inbound={flight.inbound}
            outbound={flight.outbound}
          />
        </div>
        <div css={styles.body.summary.root}>
          <span css={styles.body.summary.title}>Resumo do voo</span>
          <FlightSummary
            flight={flight}
            handleShowOutPolicyInfo={handleShowOutPolicyInfo}
            isAdding={isAdding}
            onAddToTrip={onAddToTrip}
            onReserveChange={onReserveChange}
            planeLimitExceeded={planeLimitInfo.isLimitExceeded}
          />
        </div>
      </div>
    </div>
  );
};

const FlightExtrasPlaneLimitMessage: React.FC = () => {
  const { planeLimitInfo } = useFlights();

  const planeLimitMessage = useMemo(() => {
    const {
      planeLimitExceededInfo: { inboundLimitExceeded, outboundLimitExceeded },
    } = planeLimitInfo;

    let stretchWithConflicEnd = "";
    let stretchWithConflictStart = "";

    if (inboundLimitExceeded && outboundLimitExceeded) {
      stretchWithConflictStart =
        "o trecho de ida e o trecho de volta selecionados já atingiram";
      stretchWithConflicEnd = "o trecho de ida e o trecho de volta";
    } else if (outboundLimitExceeded) {
      stretchWithConflicEnd = "o trecho de ida";
      stretchWithConflictStart = "o trecho de ida selecionado já atingiu";
    } else {
      stretchWithConflicEnd = "o trecho de volta";
      stretchWithConflictStart = "o trecho de volta selecionado já atingiu";
    }

    return `Sua empresa estabeleceu um limite máximo de ${planeLimitInfo.clientLimit} passageiro(s) em um
        mesmo avião. Registramos que ${stretchWithConflictStart} este limite, portanto recomendamos a seleção de outro voo com
        ${stretchWithConflicEnd} diferente para prosseguir com a compra.`;
  }, [planeLimitInfo]);

  return <AlertBox>{planeLimitMessage}</AlertBox>;
};
