import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { Capabilities } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

type Props = {
  allowCustomization: boolean;
  capabilities: Capabilities;
  onCapabilitiesChange: (event: React.ChangeEvent<{}>) => void;
};

export const UserFormCapabilitiesSelect: React.FC<Props> = ({
  allowCustomization,
  capabilities,
  onCapabilitiesChange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Configurações avançadas de permissão:</span>
      <CheckboxGroup css={styles.capabilities.checkbox.group}>
        <CheckboxGroup.Item
          checked={capabilities.expenses}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="expenses"
        >
          Solicitar reembolsos e adiantamentos
          <Tooltip
            arrow
            content="O usuário pode criar relatórios de despesas e solicitar reembolsos e adiantamentos."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.selfSearch}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="selfSearch"
        >
          Pesquisar e criar viagens para si mesmo
          <Tooltip
            arrow
            content="O usuário pode pesquisar e criar novas viagens para si."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.search}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="search"
        >
          Pesquisar e criar viagens para outros
          <Tooltip
            arrow
            content="O usuário pode pesquisar e criar novas viagens para outros."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.approvals}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="approvals"
        >
          Elegível como aprovador
          <Tooltip
            arrow
            content="O usuário pode ser vinculado como aprovador em processos de aprovação de viagem, reembolsos e adiantamentos."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.analytics}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="analytics"
        >
          Acesso aos dashboards e relatórios
          <Tooltip
            arrow
            content="O usuário tem acesso ao painel de analytics e relatórios da empresa."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.financial}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="financial"
        >
          Controles financeiros
          <Tooltip
            arrow
            content="O usuário tem acesso aos controles e relatórios financeiros da empresa."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.expenseForOthers}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="expenseForOthers"
        >
          Criar despesas para outros usuários
          <Tooltip
            arrow
            content="O usuário pode adicionar, remover e editar despesas dos outros usuários."
          />
        </CheckboxGroup.Item>
        <CheckboxGroup.Item
          checked={capabilities.configurations}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="configurations"
        >
          Gerenciar configurações da empresa
          <Tooltip
            arrow
            content="O usuário tem acesso às configurações da sua empresa, podendo cadastrar, editar e excluir usuários, dados gerenciais e processos."
          />
        </CheckboxGroup.Item>
        <PermissionProtectedRender permissions={["externalAPI"]}>
          <CheckboxGroup.Item
            checked={capabilities.externalCredentials}
            disabled={!allowCustomization}
            onChange={onCapabilitiesChange}
            name="externalCredentials"
          >
            Acesso à credenciais da API Externa
            <Tooltip
              arrow
              content="O usuário tem acesso à credenciais da API externa, podendo visualizá-las e redefini-las."
            />
          </CheckboxGroup.Item>
        </PermissionProtectedRender>
        <CheckboxGroup.Item
          checked={capabilities.eventOrganizer}
          disabled={!allowCustomization}
          onChange={onCapabilitiesChange}
          name="eventOrganizer"
        >
          Criar e organizar Eventos
          <Tooltip
            arrow
            content="O usuário pode criar novos eventos públicos ou privados, tornando-se organizador dos mesmos."
          />
        </CheckboxGroup.Item>
      </CheckboxGroup>
    </div>
  );
};
