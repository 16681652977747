import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    categories: {
      category: {
        heading: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
        list: {
          item: {
            name: css({
              fontWeight: 600,
            }),
            root: css({
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }),
          },
          root: css({
            color: theme.colors.gray[700],
            display: "flex",
            flexDirection: "column",
            fontSize: "0.875rem",
            gap: "8px",
            lineHeight: "1.125",
            listStyleType: "disc",
            marginLeft: "1.125rem",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "12px",
        }),
      },
      paragraph: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        lineHeight: "1.125",
      }),
      root: css({
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }),
    },
    root: css({
      gap: "24px",
    }),
    top: {
      message: css({
        color: theme.colors.gray[300],
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  header: {
    root: css({}),
  },
  root: css({
    ".MuiDialog-paper": {
      overflowY: "auto",
    },
  }),
};
