import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { theme } from "smartrips-skin";

export const styles = {
  container: css({
    margin: "0 auto",
    padding: "64px 16px",
    "@media (min-width: 768px)": {
      maxWidth: "calc(692px + 64px)",
      padding: "64px 32px",
    },
  }),
  form: {
    radioGroup: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    }),
  },
  getDateStyles: (error: boolean) =>
    css({
      borderBottomColor: error ? "cancel" : "border",
      borderColor: "border",
      borderRadius: 1,
      borderStyle: "solid",
      borderWidth: 1,
      height: 58,
      padding: "16px",
      transition: "all 0.2s",
      width: "100%",
      ["&:hover"]: {
        borderColor: darken(theme.colors.border, 0.2),
        borderBottomColor: error ? "cancel" : darken(theme.colors.border, 0.2),
      },
    }),
  header: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "fit-content",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "16px",
    margin: "0 0 64px 0",
  }),
};
