import React from "react";

import {
  Dialog as MuiDialog,
  Slide as MuiSlide,
  SlideProps as MuiSlideProps,
} from "@material-ui/core";

import { Icon, Icons } from "../icon/icon";
import { styles } from "./styles";

export const Dialog: React.FC<
  React.ComponentPropsWithoutRef<typeof MuiDialog>
> = ({ children, TransitionComponent = DialogTransition, ...props }) => {
  return (
    <MuiDialog
      css={styles.root}
      TransitionComponent={TransitionComponent}
      {...props}
    >
      {children}
    </MuiDialog>
  );
};

export const DialogFooter: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...props
}) => {
  return (
    <footer css={styles.footer.root} {...props}>
      {children}
    </footer>
  );
};

export const DialogContent: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...props
}) => {
  return (
    <div css={styles.content.root} {...props}>
      {children}
    </div>
  );
};

export const DialogHeader: React.FC<
  React.HTMLAttributes<HTMLElement> & {
    color?: string;
    disabled?: boolean;
    icon?: Icons;
    onClose?: () => void;
    variant?: "default" | "pink";
  }
> = ({ color, disabled, icon, onClose, variant, ...props }) => {
  return (
    <header css={styles.header.root} {...props}>
      {icon ? (
        <div css={styles.header.icon({ color, variant })}>
          <Icon size={18} use={icon} />
        </div>
      ) : null}
      {onClose ? (
        <button css={styles.header.close} disabled={disabled} onClick={onClose}>
          <Icon size={16} use="x" />
        </button>
      ) : null}
    </header>
  );
};

export const DialogTitle: React.FC<
  React.HTMLAttributes<HTMLSpanElement> & {
    variant?: "default" | "pink";
  }
> = ({ children, variant, ...props }) => {
  return (
    <span css={styles.title.root({ variant })} {...props}>
      {children}
    </span>
  );
};

export const DialogTransition = React.forwardRef<unknown, MuiSlideProps>(
  (props, ref) => <MuiSlide direction="down" ref={ref} {...props} />,
);

DialogTransition.displayName = "DialogTransition";
