import { useRef, useState } from "react";

import { AsyncDataType } from "./AsyncData.types";

type UseAsyncDataProps = (args?: any) => Promise<any>;

type UseAsyncDataResult<TData, TError> = [
  AsyncDataType<TData, TError>,
  (args?: any) => Promise<any>,
  () => void
];

const INITIAL_STATE = {
  isLoading: false,
  data: undefined,
  error: undefined,
  isError: false,
  isSuccess: false
};
export function useAsyncData<TData = any, TError = any>(
  query: UseAsyncDataProps
): UseAsyncDataResult<TData, TError> {
  const [state, setState] = useState<AsyncDataType<TData, TError>>(
    INITIAL_STATE
  );

  const resetState = useRef(() => setState(INITIAL_STATE));

  const queryFunction = useRef(async function (args?: any) {
    try {
      setState({
        isLoading: true,
        data: undefined,
        error: undefined,
        isError: false,
        isSuccess: false
      });

      const data = await query(args);

      setState({
        isLoading: false,
        data,
        isSuccess: true,
        error: undefined,
        isError: false
      });
    } catch (error) {
      setState({
        error: error as TError,
        isLoading: false,
        isSuccess: false,
        isError: true
      });

      // throw error;
    }
  });

  return [state, queryFunction.current, resetState.current];
}
