import React from "react";

import { Close as CloseIcon } from "@material-ui/icons";
import { theme } from "smartrips-skin";
import { Flex, Text } from "smartrips-toolkit";

import { EventInviteStatus } from "~/models/event.model";

import { Avatar } from "~/components/shared/avatar";
import { Select } from "~/components/shared/inputs";
import { Spacing } from "~/components/shared/layout";

import { styles } from "./styles";

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof Select>,
  "getOptionLabel" | "getOptionValue" | "isMulti" | "onChange" | "options"
> & {
  onChange: (value: any) => void;
  options: {
    email: string;
    invite_status?: EventInviteStatus;
    name: string;
    token: string;
  }[];
  value: any;
};

export const EventOverviewParticipantsCombobox: React.VFC<Props> = ({
  components,
  onChange,
  ...props
}) => {
  return (
    <Select
      components={{
        ...components,
        Option: components?.Option
          ? components.Option
          : ({ data, selectOption, ...rest }) => {
              const value = data as Props["options"][number];

              return (
                <div
                  aria-disabled={!!value.invite_status}
                  css={styles.option.root}
                  onClick={() => {
                    if (value.invite_status) {
                      return;
                    }

                    selectOption(data);
                  }}
                  {...rest}
                >
                  <Flex alignItems="center">
                    <Avatar css={styles.option.avatar} name={value.name} />
                    <Spacing direction="left" space="1rem" />
                    <Flex flexDirection="column">
                      <Text
                        color={theme.colors.gray[4]}
                        fontSize={14}
                        fontWeight="bold"
                      >
                        {value.name}
                      </Text>
                      <Spacing direction="vertical" space="2px" />
                      <Text
                        color={theme.colors.gray[2]}
                        fontSize={11}
                        fontWeight="medium"
                      >
                        {value.invite_status ? "Já convidado." : value.email}
                      </Text>
                    </Flex>
                  </Flex>
                </div>
              );
            },
        MultiValue: components?.MultiValue
          ? components.MultiValue
          : ({ data, selectOption, ...rest }) => {
              const value = data as {
                email: string;
                name: string;
                token: string;
              };

              return (
                <div css={styles.multiValue.root} {...rest}>
                  <Avatar css={styles.multiValue.avatar} name={value.name} />
                  <Text fontSize={14} fontWeight="bold">
                    {value.name}
                  </Text>
                  <button
                    css={styles.multiValue.removeButton}
                    onClick={() => {
                      selectOption(data);
                    }}
                    type="button"
                  >
                    <CloseIcon fontSize="small" />
                  </button>
                </div>
              );
            },
        ValueContainer: components?.ValueContainer
          ? components.ValueContainer
          : ({ ...rest }) => {
              return <div css={styles.multiValueContainer.root} {...rest} />;
            }
      }}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.token}
      isMulti
      onChange={(value: any) => {
        onChange(value);
      }}
      {...props}
    />
  );
};
