import React from "react";

import { RouteComponentProps } from "@reach/router";

import { FlightInfo } from "@components/analytics/flight-info/FlightInfo";

type Props = RouteComponentProps;

export const AnalyticsFlightInfo: React.FC<Props> = () => {
  return <FlightInfo />;
};
