import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    alignItems: "center",
    display: "flex",
    gap: "16px",
    justifyContent: "center",
  }),
  svg: ({ color, size }: { color: string; size: number }) =>
    css({
      alignItems: "center",
      aspectRatio: "1",
      ...(color && { color: `${color}` }),
      display: "flex",
      flexShrink: 0,
      height: `${size || 24}px`,
      justifyContent: "center",
      pointerEvents: "auto",
      width: `${size || 24}px`,
    }),
  text: css({
    color: theme.colors.gray[700],
    fontSize: "1.25rem",
    fontWeight: "bold",
    textDecoration: "none",
  }),
};
