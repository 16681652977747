import React from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilitiesLiterals } from "~/apps/shared/constants/enums";

type Props = {
  atLeastOne?: boolean;
  capabilities: CapabilitiesLiterals[];
};

export const CapabilityProtectedRender: React.FC<Props> = ({
  atLeastOne = false,
  capabilities = [],
  children,
}) => {
  const { user } = useUser();

  if (!user) {
    return null;
  }

  if (atLeastOne) {
    if (
      capabilities.length > 0 &&
      capabilities.some((capability) => user.hasCapability(capability))
    ) {
      return <>{children}</>;
    }
  }

  if (
    capabilities.length > 0 &&
    capabilities.every((capability) => user.hasCapability(capability))
  ) {
    return <>{children}</>;
  }

  return null;
};
