import React, { useState, useRef, useEffect } from "react";
import { FocusedInputShape } from "react-dates";

import { withFormik, FormikErrors, FormikProps } from "formik";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment";

import { IBookingTarget } from "~/models/booker-target.model";

import { FlightSearch, FlightSearchPlace } from "../NewTrip.types";
import { FlightForm } from "./FlightForm.presentational";
import { useRecentFlightsContext } from "./RecentFlights/RecentFlightsProvider";

interface FlightFields {
  startDate: Moment;
  endDate: Moment;
  type: "roundtrip" | "oneway";
  origin: any;
  destination: any;
  cabinClass: any;
  traveler: IBookingTarget;
}
interface FormContainerProps {
  onSubmit: (flightSearchData: FlightSearch) => void;
  searchFields: FlightFields;
  version: "v1" | "v2";
}

interface Props {
  onSubmit: (flightSearchData: FlightSearch) => void;
  version?: "v1" | "v2";
}

const FlightFormContainer = ({
  errors,
  handleChange,
  handleSubmit,
  setErrors,
  setFieldTouched,
  setFieldValue,
  values,
  version
}: FormikProps<FlightSearch> & {
  version: "v1" | "v2";
}) => {
  const [singleFocus, setSingleFocus] = useState<boolean>(false);
  const [rangeFocus, setRangeFocus] = useState<FocusedInputShape | null>(null);

  const originInputRef = useRef<HTMLInputElement | null>(null);
  const destinationInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (originInputRef.current) {
      originInputRef.current.focus();
    }
  }, []);

  const removeFieldError = (fieldName: string) => {
    const updatedErrors = reduce(
      errors,
      (acc: any, value, key) => {
        if (key !== fieldName) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    setErrors(updatedErrors);
  };

  const handleChangeSingleDatePickerFocus = ({
    focused
  }: {
    focused: boolean;
  }) => setSingleFocus(focused);

  const handleChangeRangeDatePickerFocues = (
    focused: FocusedInputShape | null
  ) => setRangeFocus(focused);

  const handleTabChange = (_: any, tab: any) => {
    setFieldValue("type", tab);
  };

  const handleSingleDateChange = (date: Moment | null) => {
    setFieldValue("startDate", date);
    setFieldTouched("startDate");

    if (date) {
      removeFieldError("startDate");
    }
  };

  const handleRangeDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setFieldValue("startDate", date.startDate);
    setFieldValue("endDate", date.endDate);
    setFieldTouched("startDate");
    setFieldTouched("endDate");

    if (date.startDate) {
      removeFieldError("startDate");
    }

    if (date.endDate) {
      removeFieldError("endDate");
    }
  };

  const shouldSelectDestinationInput = () => {
    const originInputEl = originInputRef.current;
    const destinationInputEl = destinationInputRef.current;
    const activeElement = document.activeElement;

    return Boolean(
      originInputEl &&
        destinationInputEl &&
        activeElement &&
        originInputEl.id !== activeElement.id
    );
  };

  const handleLocationChange = (type: "origin" | "destination") => (
    place: FlightSearchPlace
  ) => {
    setFieldValue(type, place);
    setFieldTouched(type);

    if (type === "origin" && place) {
      removeFieldError("origin");

      if (shouldSelectDestinationInput()) {
        destinationInputRef.current!.focus();
      }
    }

    if (type === "destination" && place) {
      removeFieldError("destination");
    }
  };

  const handleSwapLocations = () => {
    const { destination, origin } = values;

    setFieldValue("origin", destination);
    setFieldValue("destination", origin);

    removeFieldError("origin");
    removeFieldError("destination");
  };

  const handleChangeTraveler = (traveler: IBookingTarget) => {
    setFieldValue("traveler", traveler);
    setFieldTouched("traveler");

    if (traveler) {
      removeFieldError("traveler");
    }
  };

  const handleCabinChange = (cabinClass: any) => {
    setFieldValue("cabinClass", cabinClass);
    setFieldTouched("cabinClass");
  };

  return (
    <FlightForm
      destinationInputRef={destinationInputRef}
      errors={errors}
      handleCabinChange={handleCabinChange}
      handleChange={handleChange}
      handleChangeRangeDatePickerFocues={handleChangeRangeDatePickerFocues}
      handleChangeSingleDatePickerFocus={handleChangeSingleDatePickerFocus}
      handleChangeTraveler={handleChangeTraveler}
      handleLocationChange={handleLocationChange}
      handleRangeDateChange={handleRangeDateChange}
      handleSingleDateChange={handleSingleDateChange}
      handleSubmit={handleSubmit}
      handleSwapLocations={handleSwapLocations}
      handleTabChange={handleTabChange}
      originInputRef={originInputRef}
      rangeDatePickerFocus={rangeFocus}
      singleDatePickerFocus={singleFocus}
      values={values}
      version={version}
    />
  );
};

const FormikContainer = withFormik<FormContainerProps, FlightSearch>({
  mapPropsToValues: ({ searchFields }) => {
    return {
      startDate: searchFields.startDate,
      endDate: searchFields.endDate,
      type: searchFields.type,
      origin: searchFields.origin,
      destination: searchFields.destination,
      cabinClass: searchFields.cabinClass,
      traveler: searchFields.traveler
    };
  },
  validate: values => {
    const errors: FormikErrors<FlightSearch> = {};

    if (!values.startDate) {
      errors.startDate = "Preencha a data de ida";
    } else if (
      values.startDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    ) {
      errors.startDate = "Data inicial inferior a hoje";
    }

    if (!values.endDate && values.type === "roundtrip") {
      errors.endDate = "Preencha a data de volta";
    }

    if (!values.origin) {
      errors.origin = "Preencha seu local de origem";
    }

    if (!values.destination) {
      errors.destination = "Preencha seu local de destino";
    }

    if (!values.traveler) {
      errors.traveler = "Atribua um viajante";
    }

    return errors;
  },
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(FlightFormContainer);

const FlightFormWithRecentFlights = ({ onSubmit, version = "v1" }: Props) => {
  const { selectedSearch } = useRecentFlightsContext();

  return (
    <FormikContainer
      onSubmit={onSubmit}
      searchFields={selectedSearch}
      version={version}
    />
  );
};

export { FlightFormWithRecentFlights as FlightFormContainer };
