const nonLocalhostDomainRegExp = /^[^\s.]+\.\S{2,}$/;
const protocolAndDomainRegExp = /^(?:\w+:)?\/\/(\S+)$/;

export const isURL = (value: any) => {
  if (typeof value !== "string") {
    return false;
  }

  const match = value.match(protocolAndDomainRegExp);

  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];

  if (!everythingAfterProtocol) {
    return false;
  }

  if (nonLocalhostDomainRegExp.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
};
