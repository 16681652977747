import React, { createContext, useState, useContext } from "react";

import {
  AdvancedExpense,
  AdvancedExpenseRequest,
  AdvancedExpenseApprover
} from "@models/advanced-expense.model";
import * as service from "./ExpenseAdvanceApprovalReview.service";
import { useContextFactory } from "@hooks";
import { ApplicationContext } from "~/Context";
import {
  ALERT_TYPES,
  EXPENSES_APPROVAL_STAGES,
  ADVANCED_EXPENSE_STATUS
} from "@constants";
import { UserModel } from "@models/user.model";
import { getUserFromLocalStorage } from "~/helpers/user.helper";
import { navigate } from "@reach/router";
import uuid from "uuid";
import moment from "moment";

interface ApprovalReviewState {
  isLoading: boolean;
  isSubmitting: boolean;
  traveler: UserModel | null;
  expenseAdvance: AdvancedExpense | null;
  expenseAdvanceRequest: AdvancedExpenseRequest | null;
  expenseAdvanceApprovers: AdvancedExpenseApprover[];
  approvalStatus:
    | "PENDING_APPROVAL"
    | "PENDING_PAYMENT"
    | "APPROVED"
    | "PAID"
    | "DENIED";
}

interface ApprovalReviewActions {
  fetchData: (expenseAdvanceToken: string) => void;
  handleApprove: () => void;
  handleDeny: (message?: string) => () => void;
}

const ApprovalReviewContext = createContext({} as ApprovalReviewState);
const ApprovalReviewActionsContext = createContext({} as ApprovalReviewActions);

export const useExpenseAdvanceApprovalReviewContext: () => ApprovalReviewState = useContextFactory(
  "ExpenseAdvanceApprovalReviewContext",
  ApprovalReviewContext
);
export const useExpenseAdvanceApprovalReviewActionsContext: () => ApprovalReviewActions = useContextFactory(
  "ExpenseAdvanceApprovalReviewActionsContext",
  ApprovalReviewActionsContext
);

const initialApprovalReviewState: ApprovalReviewState = {
  isLoading: false,
  isSubmitting: false,
  traveler: null,
  expenseAdvance: null,
  expenseAdvanceRequest: null,
  expenseAdvanceApprovers: [],
  approvalStatus: "PENDING_APPROVAL"
};

const ExpenseAdvanceApprovalReviewProvider: React.FC = props => {
  const [approvalReviewState, setApprovalReviewState] = useState(
    initialApprovalReviewState
  );
  const { removeExpenseAdvanceFromPendingList, showSnackMessage } = useContext(
    ApplicationContext
  );

  const fetchData = async (expenseAdvanceToken: string) => {
    if (!expenseAdvanceToken) {
      return showSnackMessage(
        "Adiantamento de despesas não encontrado",
        ALERT_TYPES.ERROR
      );
    }

    setApprovalReviewState(prevState => ({ ...prevState, isLoading: true }));

    const [
      { data: expenseAdvanceData, error: expenseAdvanceError },
      { data: approversData, error: approversError }
    ] = await Promise.all([
      service.getExpenseAdvanceDetails(expenseAdvanceToken),
      service.getExepnseAdvanceApprovers(expenseAdvanceToken)
    ]);

    const basicError = expenseAdvanceError || approversError;
    if (basicError) {
      setApprovalReviewState(prevState => ({ ...prevState, isLoading: false }));
      return showSnackMessage(basicError.description, ALERT_TYPES.ERROR);
    }

    const isUserAllowed = checkIfUserIsAllowed(
      expenseAdvanceData!.expenseAdvanceRequest,
      approversData!
    );

    if (!isUserAllowed) {
      return (async () => {
        await navigate("/approvals/expenses-advance");
        showSnackMessage(
          "Usuário não possuí permissão para revisar essa solicitação na etapa atual.",
          ALERT_TYPES.ERROR
        );
      })();
    }

    const { data: traveler, error: travelerError } = await service.getUser(
      expenseAdvanceData!.expenseAdvance.travelerToken
    );

    if (travelerError) {
      setApprovalReviewState(prevState => ({ ...prevState, isLoading: false }));
      return showSnackMessage(travelerError.description, ALERT_TYPES.ERROR);
    }

    setApprovalReviewState(prevState => ({
      ...prevState,
      isLoading: false,
      traveler: traveler!,
      expenseAdvance: expenseAdvanceData!.expenseAdvance,
      expenseAdvanceRequest: expenseAdvanceData!.expenseAdvanceRequest,
      expenseAdvanceApprovers: expenseAdvanceData!.expenseAdvanceApprovers,
      approvalStatus: getInitialApprovalStatus(
        expenseAdvanceData!.expenseAdvance.status
      )
    }));
  };

  const handleApprove = async () => {
    setApprovalReviewState(prevState => ({ ...prevState, isSubmitting: true }));

    const {
      expenseAdvance,
      expenseAdvanceApprovers,
      expenseAdvanceRequest,
      approvalStatus
    } = approvalReviewState;

    const { error } = await service.approveExpenseAdvanceRequest(
      expenseAdvance!.expenseAdvanceToken,
      expenseAdvance!.requestedValue
    );

    if (error) {
      setApprovalReviewState(prevState => ({
        ...prevState,
        isSubmitting: false
      }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    const approverLine = buildApproverLine(
      expenseAdvanceRequest!.currentApprovalStage
    );

    const nextStage = getExpenseAdvanceApprovalNextStage(
      expenseAdvanceRequest!
    );

    removeExpenseAdvanceFromPendingList(expenseAdvance!.expenseAdvanceToken);

    setApprovalReviewState(prevState => ({
      ...prevState,
      isSubmitting: false,
      expenseAdvanceApprovers: expenseAdvanceApprovers.concat(approverLine),
      expenseAdvanceRequest: Object.assign({}, expenseAdvanceRequest, {
        currentApprovalStage: nextStage,
        closedAt:
          approvalStatus === "PENDING_PAYMENT"
            ? moment()
            : expenseAdvanceRequest!.closedAt
      }),
      approvalStatus: approvalStatus === "PENDING_PAYMENT" ? "PAID" : "APPROVED"
    }));
  };

  const handleDeny = (message?: string) => async () => {
    setApprovalReviewState(prevState => ({ ...prevState, isSubmitting: true }));

    const {
      expenseAdvance,
      expenseAdvanceRequest,
      expenseAdvanceApprovers
    } = approvalReviewState;

    const { error } = await service.denyExpenseAdvanceRequest(
      expenseAdvance!.expenseAdvanceToken,
      message
    );

    if (error) {
      setApprovalReviewState(prevState => ({
        ...prevState,
        isSubmitting: false
      }));
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    removeExpenseAdvanceFromPendingList(expenseAdvance!.expenseAdvanceToken);

    const approverLine = buildApproverLine(
      expenseAdvanceRequest!.currentApprovalStage
    );

    setApprovalReviewState(prevState => ({
      ...prevState,
      isSubmitting: false,
      expenseAdvance: Object.assign({}, expenseAdvance, {
        status: ADVANCED_EXPENSE_STATUS.DECLINED
      }),
      expenseAdvanceRequest: Object.assign({}, expenseAdvanceRequest, {
        status: ADVANCED_EXPENSE_STATUS.DECLINED,
        closedAt: moment()
      }),
      expenseAdvanceApprovers: expenseAdvanceApprovers.concat(approverLine),
      approvalStatus: "DENIED"
    }));
  };

  const checkIfUserIsAllowed = (
    expenseAdvanceRequest: AdvancedExpenseRequest,
    approvers: AdvancedExpenseApprover[]
  ) => {
    const loggedUser = getUserFromLocalStorage();

    if (!loggedUser) {
      return false;
    }

    const checkIsUserCurrentApprover = approvers.find(
      approver =>
        approver.userToken === loggedUser.userToken &&
        approver.stage === expenseAdvanceRequest.currentApprovalStage
    );

    return !!checkIsUserCurrentApprover;
  };

  // helper functions

  const getInitialApprovalStatus = (status: number) => {
    switch (status) {
      case ADVANCED_EXPENSE_STATUS.CLOSED:
        return "PAID";
      case ADVANCED_EXPENSE_STATUS.PENDING_APPROVAL:
        return "PENDING_APPROVAL";
      case ADVANCED_EXPENSE_STATUS.PENDING_PAYMENT:
        return "PENDING_PAYMENT";
      case ADVANCED_EXPENSE_STATUS.DECLINED:
        return "DENIED";
      default:
        return "APPROVED";
    }
  };

  const buildApproverLine = (stage: any) => {
    const loggedUser = getUserFromLocalStorage();

    const approverLine: AdvancedExpenseApprover = {
      stage,
      approvableApprovalRequestApproverToken: uuid(),
      repliedAt: moment(),
      userToken: loggedUser!.userToken,
      email: loggedUser!.email,
      firstName: loggedUser!.firstName,
      lastName: loggedUser!.lastName,
      role: loggedUser!.role,
      fullName: `${loggedUser!.firstName} ${loggedUser!.lastName}`
    };

    return approverLine;
  };

  const getExpenseAdvanceApprovalNextStage = (
    expenseAdvanceRequest: AdvancedExpenseRequest
  ) => {
    const { approvalStage, currentApprovalStage } = expenseAdvanceRequest;

    if (approvalStage === currentApprovalStage) {
      return EXPENSES_APPROVAL_STAGES.PAYMENT;
    } else if (currentApprovalStage === EXPENSES_APPROVAL_STAGES.PAYMENT) {
      return currentApprovalStage;
    } else {
      return currentApprovalStage + 1;
    }
  };

  return (
    <ApprovalReviewContext.Provider value={approvalReviewState}>
      <ApprovalReviewActionsContext.Provider
        value={{ fetchData, handleApprove, handleDeny }}
      >
        {props.children}
      </ApprovalReviewActionsContext.Provider>
    </ApprovalReviewContext.Provider>
  );
};

export { ExpenseAdvanceApprovalReviewProvider };
