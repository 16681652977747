import * as yup from "yup";

const approvalPriorityRankingSchema = yup.object().shape({
  priorityRanking: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
});

export default approvalPriorityRankingSchema;
