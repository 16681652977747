import * as analyticsApi from "~/apps/corporate/apis/analytics.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { PeriodParams, ReportType } from "./analytics-report-download.types";
import { getPeriodFilterText } from "./anayltics-report-download.helper";

export const downloadConciliationsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-conciliacão-" + getPeriodFilterText(params);

    await analyticsApi.downloadConciliationsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadCancelationsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-nao-viajados-" + getPeriodFilterText(params);

    await analyticsApi.downloadCancelationsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadFlightsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-voos-" + getPeriodFilterText(params);

    await analyticsApi.downloadFlightsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadFlightsPolicyReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName =
      "relatorio-adocao-politica-aerea-" + getPeriodFilterText(params);

    await analyticsApi.downloadFlightsPolicyReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadHotelsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-hoteis-" + getPeriodFilterText(params);

    await analyticsApi.downloadHotelsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadHotelsPolicyReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName =
      "relatorio-adoção-politica-hospedagem-" + getPeriodFilterText(params);

    await analyticsApi.downloadHotelsPolicyReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadCarsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName =
      "relatorio-adoção-politica-carros-" + getPeriodFilterText(params);

    await analyticsApi.downloadCarsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadCarsPolicyReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-aluguel-carros-" + getPeriodFilterText(params);

    await analyticsApi.downloadCarsPolictReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadBusesReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-onibus-" + getPeriodFilterText(params);

    await analyticsApi.downloadBusesReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadTravelersReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-gastos-viajante-" + getPeriodFilterText(params);

    await analyticsApi.downloadTravelersReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadExpensesReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName =
      "relatorio-reembolsadas-despesas-" + getPeriodFilterText(params);

    await analyticsApi.downloadExpensesReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadPendingExpensesReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName =
      "relatorio-pendentes-reembolso-" + getPeriodFilterText(params);

    await analyticsApi.downloadPendingExpensesReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadApprovalsReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-aprovacoes-" + getPeriodFilterText(params);

    await analyticsApi.downloadApprovalsReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadFlightChangesReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-alteracao-aereo-" + getPeriodFilterText(params);

    await analyticsApi.downloadFlightChangesReport(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const downloadTravelFeesReport = async (params: PeriodParams) => {
  const result: { error?: Error } = {};

  try {
    const fileName = "relatorio-fees-de-viagem-" + getPeriodFilterText(params);

    await analyticsApi.downloadTravelFees(fileName, params);
  } catch (error) {
    result.error = ERROR.FILE_DOWNLOAD_ERROR;
  }

  return result;
};

export const getReportDownloadMethod = (type: ReportType) => {
  switch (type) {
    case ReportType.APPROVAL:
      return downloadApprovalsReport;
    case ReportType.BUS:
      return downloadBusesReport;
    case ReportType.CANCELATION:
      return downloadCancelationsReport;
    case ReportType.CAR:
      return downloadCarsReport;
    case ReportType.CAR_POLICY:
      return downloadCarsPolicyReport;
    case ReportType.CONCILIATION:
      return downloadConciliationsReport;
    case ReportType.EXPENSE:
      return downloadExpensesReport;
    case ReportType.FLIGHT:
      return downloadFlightsReport;
    case ReportType.FLIGHT_POLICY:
      return downloadFlightsPolicyReport;
    case ReportType.FLIGHT_CHANGE:
      return downloadFlightChangesReport;
    case ReportType.HOTEL:
      return downloadHotelsReport;
    case ReportType.HOTEL_POLICY:
      return downloadHotelsPolicyReport;
    case ReportType.PENDING_EXPENSE:
      return downloadPendingExpensesReport;
    case ReportType.TRAVELER:
      return downloadTravelersReport;
    case ReportType.TRAVEL_FEE:
      return downloadTravelFeesReport;
    default:
      return null;
  }
};
