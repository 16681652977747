import {
  getAuthorizationHeader,
  getLoggedUserToken,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

import {
  AddTravelTagResponse,
  CreateTagResponse,
  GetClientTagsResponse,
  GetTravelTagsResponse,
  GetUserTravelTagsResponse,
  RemoveTravelTagResponse,
} from "../dtos/tags.dto";
import * as tagsParser from "../parsers/tags.parser";

export async function addTravelTag(tagToken: string, travelToken: string) {
  return await api
    .request<AddTravelTagResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/tags/${tagToken}/travel_tags`,
    })
    .then(({ data }) => data);
}

export async function archiveTag(tagToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/tags/${tagToken}/archive`,
  });
}

export async function createTag(tagName: string) {
  return await api
    .request<CreateTagResponse>({
      data: {
        client_token: getClientToken(),
        name: tagName,
        user_token: getLoggedUserToken(),
      },
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/tags`,
    })
    .then(({ data }) => tagsParser.parseTag(data));
}

export async function editTag(tagName: string, tagToken: string) {
  return await api
    .request<any>({
      data: { name: tagName },
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/tags/${tagToken}`,
    })
    .then(({ data }) => data);
}

export async function getAllClientTags() {
  return await api
    .request<any>({
      url: `/booking/clients/${getClientToken()}/tags`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

export async function getClientTags() {
  return await api
    .request<GetClientTagsResponse>({
      data: { client_token: getClientToken() },
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/tags/search`,
    })
    .then(({ data }) => tagsParser.parseClientTags(data));
}

export async function getClientTagOptions() {
  return await api
    .request<any>({
      url: `/booking/clients/${getClientToken()}/tag-options`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

export async function getTravelTags(travelToken: string) {
  return await api
    .request<GetTravelTagsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/tags`,
    })
    .then(({ data }) => tagsParser.parseTravelTags(data));
}

export async function getUserTravelTags() {
  return await api
    .request<GetUserTravelTagsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${getLoggedUserToken()}/tags`,
    })
    .then(({ data }) => data);
}

export async function removeTravelTag(travelTagToken: string) {
  return await api.request<RemoveTravelTagResponse>({
    headers: getAuthorizationHeader(),
    method: "DELETE",
    url: `/booking/${travelTagToken}/travel_tags`,
  });
}

export async function unarchiveTag(tagToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/tags/${tagToken}/unarchive`,
  });
}
