import React, { useCallback } from "react";

import { Drawer, DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Button } from "@toolkit/v2";

import { FlightDetails } from "../flight-details/flight-details";
import { useFlights } from "../flights.context";
import { styles } from "./styles";

export const FlightDetailsDrawer: React.FC = () => {
  const {
    flightDetailsDrawerIsOpen,
    handleCloseFlightDetailsDrawer,
    selectedFlight,
    selectFlight,
  } = useFlights();

  const handleSelectFlight = useCallback(() => {
    if (!selectedFlight) {
      return;
    }

    selectFlight(selectedFlight);

    handleCloseFlightDetailsDrawer();
  }, [handleCloseFlightDetailsDrawer, selectFlight, selectedFlight]);

  return (
    <Drawer
      disableRestoreFocus
      onClose={handleCloseFlightDetailsDrawer}
      open={flightDetailsDrawerIsOpen}
    >
      <div css={styles.root}>
        <DrawerHeader onClose={handleCloseFlightDetailsDrawer}>
          Detalhes do voo
        </DrawerHeader>
        {selectedFlight ? (
          <>
            <div css={styles.body.root}>
              <FlightDetails
                inbound={selectedFlight.inbound}
                outbound={selectedFlight.outbound}
              />
            </div>
            <footer css={styles.footer.root}>
              <span>{toCurrency(selectedFlight.price)}</span>
              <Button onClick={handleSelectFlight}>Ver mais</Button>
            </footer>
          </>
        ) : null}
      </div>
    </Drawer>
  );
};
