import React from "react";

import { FormControlLabel as MuiFormControlLabel } from "@material-ui/core";

import { Radio } from "../radio/radio";
import { styles } from "./styles";

export const RadioGroupItem: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof MuiFormControlLabel>,
    "control" | "label"
  > & {
    variant?: "blue" | "default" | "green" | "pink";
  }
> = ({ children, disabled, id, value, variant = "default", ...props }) => {
  return (
    <MuiFormControlLabel
      classes={{ ...styles.classes }}
      control={<Radio id={id} variant={variant} />}
      disabled={disabled}
      label={<span>{children}</span>}
      value={value}
      {...props}
    />
  );
};
