import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  date: css({
    color: theme.colors.gray[500],
    fontSize: "0.875rem",
    textTransform: "capitalize",
  }),
  groups: {
    group: {
      cards: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
      date: ({ shiftRight }: { shiftRight?: boolean }) =>
        css({
          backgroundColor: theme.colors.white,
          color: theme.colors.gray[500],
          fontSize: "0.875rem",
          padding: shiftRight ? "4px 0 4px 34px" : "4px 0",
          textTransform: "capitalize",
        }),
      root: css({
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }),
    },
    root: ({ shiftRight }: { shiftRight?: boolean }) =>
      css({
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        position: "relative",
        width: "100%",
        zIndex: 0,
        "&::before": {
          borderRight: `1px dashed ${theme.colors.gray[100]}`,
          bottom: 0,
          content: '""',
          height: "100%",
          left: shiftRight ? "42px" : "6px",
          margin: "auto",
          position: "absolute",
          top: 0,
          width: "1px",
          zIndex: -1,
        },
      }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  }),
  title: ({ color }: { color: string }) =>
    css({
      color: color ? color : theme.colors.gray[700],
      fontSize: "0.75rem",
      fontWeight: 600,
      textTransform: "uppercase",
    }),
};
