import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "@skin/v2";

export const styles = {
  logo: () =>
    StyleSheet.create({
      image: {
        borderRadius: "50%",
        height: 24,
        left: 2,
        position: "relative",
        top: 2,
        width: 24,
      },
      name: {
        fontWeight: 700 - 100,
      },
      root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 12,
        marginBottom: 48,
      },
    }),
  info: () =>
    StyleSheet.create({
      name: {
        color: theme.colors.gray[700],
        fontSize: 20,
        fontWeight: 700 - 100,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: 24,
      },
      title: {
        color: theme.colors.gray[700],
        fontSize: 12,
      },
    }),
  root: () =>
    StyleSheet.create({
      body: {
        display: "flex",
        flexDirection: "column",
      },
      count: {
        fontSize: 10,
      },
      header: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      root: {
        backgroundColor: theme.colors.white,
        display: "flex",
        flexDirection: "column",
        // fontFamily: "Inter",
        fontWeight: 400 - 100,
        paddingBottom: 48,
        paddingLeft: 48,
        paddingRight: 48,
        paddingTop: 48,
      },
    }),
  services: () =>
    StyleSheet.create({
      container: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
      },
      date: {
        color: theme.colors.gray[700],
        fontSize: 11,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginTop: 24,
      },
      services: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
      },
    }),
};
