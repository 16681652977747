import { useMemo } from "react";

import { useItineraryServices } from "../../itinerary-services.context";
import { useItineraryContainer } from "../../itinerary.container";
import { ItineraryPriceBreakdownPresenterFactory } from "./itinerary-price-breakdown.presenter";

export const useItineraryPriceBreakdownGuard = () => {
  const {
    errorOnFetch: errorOnFetchItineraryServices,
    isLoading: isLoadingItineraryServices,
  } = useItineraryServices();

  return {
    error: errorOnFetchItineraryServices,
    loading: isLoadingItineraryServices,
  };
};

export const useItineraryPriceBreakdownPresenter = () => {
  const { servicesPresenter } = useItineraryContainer();

  return useMemo(
    () => ({
      itineraryPriceBreakdownPresenter: servicesPresenter
        ? ItineraryPriceBreakdownPresenterFactory.create(servicesPresenter)
        : null,
    }),
    [servicesPresenter],
  );
};
