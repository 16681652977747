import React from "react";

import connectionError from "~/apps/shared/assets/images/errors/connection.png";

import { Button } from "@toolkit/v2";

import { ERROR } from "../../constants/errors";
import { styles } from "./styles";

const withTrailingPeriod = (node: React.ReactNode) =>
  typeof node === "string" ? (node.endsWith(".") ? node : `${node}.`) : node;

type Props = {
  message?: React.ReactNode;
  navigateMessage?: string;
  navigateTo?: () => void;
  title?: React.ReactNode;
};

export const ErrorWarning: React.FC<Props> = ({
  message = ERROR.UNEXPECTED.description,
  navigateMessage,
  navigateTo,
  title = ERROR.UNEXPECTED.title,
}) => {
  return (
    <div css={styles.root}>
      <img alt="" height={256} src={connectionError} width={256} />
      <div css={styles.message.root}>
        <span css={styles.message.title}>{withTrailingPeriod(title)}</span>
        <span css={styles.message.text}>{withTrailingPeriod(message)}</span>
      </div>
      {navigateMessage && navigateTo ? (
        <div css={styles.navigate.root}>
          <Button onClick={navigateTo} variant="pink">
            {navigateMessage}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
