import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { getAirlineWebSite } from "~/apps/corporate/helpers/flight.helper";
import {
  FlightFamilyOffer,
  FlightListOffer,
} from "~/apps/corporate/models/offer.model";
import { Drawer } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import { useDrawerTransition } from "~/apps/shared/hooks/use-drawer-transition";

import { useFlights } from "../flights.context";
import { FlightExtras } from "./flight-extras/flight-extras";
import {
  FlightFareList,
  FlightFareListSkeleton,
} from "./flight-fare-list/flight-fare-list";
import { styles } from "./styles";

type StepType = "fare" | "extras";

export const FlightFareDetailsDrawer: React.FC = () => {
  const {
    addingSituation,
    addOffer,
    changeFamilyFlight,
    closeFlightDetails,
    fareDetailsDrawerIsOpen,
    flightDetailsDrawerIsOpen,
    flightInfo,
    handleChangeReserve,
    handlePolicyDialogOpen,
    isLoadingSelectedFlight,
    selectedFlight,
  } = useFlights();

  const drawerRef = useRef<HTMLDivElement>(null);

  const isOpen = useMemo(
    () =>
      fareDetailsDrawerIsOpen && !flightDetailsDrawerIsOpen && !!selectedFlight,
    [fareDetailsDrawerIsOpen, flightDetailsDrawerIsOpen, selectedFlight],
  );

  const { isVisible, handleClose } = useDrawerTransition(
    isOpen,
    closeFlightDetails,
  );

  const [step, setStep] = useState<StepType>("fare");

  const handleSelectFare = useCallback(
    (fareFamily: FlightFamilyOffer) => () => {
      changeFamilyFlight(fareFamily);
      setStep("extras");

      if (!drawerRef.current) {
        return;
      }

      drawerRef.current.scrollTo(0, 0);
    },
    [changeFamilyFlight, drawerRef],
  );

  const handleBreadcrumbsChange = useCallback(
    (step: StepType) => () => {
      setStep(step);
    },
    [],
  );

  const handleShowOutPolicyInfo = useCallback(
    (flight: FlightListOffer) => () => {
      handlePolicyDialogOpen(flight);
    },
    [handlePolicyDialogOpen],
  );

  const outboundCarrierCode = useMemo(
    () => selectedFlight?.outbound.carrier?.code || null,
    [selectedFlight],
  );

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setStep("fare");
  }, [isOpen]);

  return (
    <Drawer
      anchor="left"
      disableRestoreFocus
      onClose={handleClose}
      open={isVisible}
      PaperProps={{
        ref: drawerRef,
      }}
    >
      <div css={styles.root}>
        <div css={styles.header.root}>
          <div css={styles.header.left.root}>
            {step !== "extras" ? (
              <>
                <button
                  css={styles.header.left.breadcrumb({
                    active: false,
                  })}
                  onClick={handleClose}
                >
                  Resultados
                </button>
                <Icon size={12} use="chevron-right" />
              </>
            ) : null}
            <button
              css={styles.header.left.breadcrumb({ active: step === "fare" })}
              onClick={handleBreadcrumbsChange("fare")}
            >
              Famílias de Tarifas
            </button>
            {step === "extras" ? (
              <>
                <Icon size={16} use="chevron-right" />
                <button
                  css={styles.header.left.breadcrumb({
                    active: true,
                  })}
                  onClick={handleBreadcrumbsChange("extras")}
                >
                  Detalhes
                </button>
              </>
            ) : null}
          </div>
          <div css={styles.header.right.root}>
            <button css={styles.header.right.button} onClick={handleClose}>
              <span>Voltar aos resultados</span>
              <Icon size={16} use="x" />
            </button>
          </div>
        </div>
        <div
          css={styles.body.root({
            center: step === "fare",
          })}
        >
          {step === "fare" ? (
            <div css={styles.body.heading.root}>
              <span css={styles.body.heading.title}>
                Deseja voar com conforto?
              </span>
              <span css={styles.body.heading.subtitle}>
                Selecione a tarifa certa pra você.
              </span>
            </div>
          ) : null}
          {isLoadingSelectedFlight ? (
            <FlightFareListSkeleton />
          ) : selectedFlight ? (
            <>
              {flightInfo && step === "fare" ? (
                <FlightFareList
                  flight={selectedFlight}
                  flightIsInternational={flightInfo.international}
                  onSelect={handleSelectFare}
                />
              ) : null}
              {step === "extras" ? (
                <FlightExtras
                  flight={selectedFlight}
                  handleShowOutPolicyInfo={handleShowOutPolicyInfo(
                    selectedFlight,
                  )}
                  isAdding={addingSituation}
                  onAddToTrip={addOffer}
                  onReserveChange={handleChangeReserve}
                />
              ) : null}
            </>
          ) : null}
          {outboundCarrierCode &&
          step === "fare" &&
          !isLoadingSelectedFlight &&
          getAirlineWebSite(outboundCarrierCode) ? (
            <div css={styles.body.carrier.root}>
              <span>Para mais detalhes, acesse o site da </span>
              <a
                css={styles.body.carrier.link}
                href={getAirlineWebSite(outboundCarrierCode)!.link}
                rel="noreferrer"
                target="_blank"
              >
                {getAirlineWebSite(outboundCarrierCode)!.name}.
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </Drawer>
  );
};
