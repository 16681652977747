import React, { useContext, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useBillingProfileContext } from "~/apps/corporate/pages/configurations/views/financial/billing-profiles/BillingProfiles.context";
import { CurrencyInput } from "~/apps/shared/components/currency-input/currency-input";
import { DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Select } from "~/apps/shared/components/select/select";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { Error } from "~/apps/shared/types";

import {
  BudgetActionType,
  BudgetResult,
  TimeframeType,
} from "@models/budget.model";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import Drawer from "@components/shared/drawer/Drawer";
import { Row } from "@components/shared/layout/Row";

import * as budgetApi from "../../../../../../../../apis/budget.api";
import { CostCentersContext } from "../../cost-centers/CostCenters.context";
import {
  MONTH_SELECT_VALUES,
  PERIOD_SELECT_VALUES,
  SEMESTER_SELECT_VALUES,
  TRIMESTER_SELECT_VALUES,
  YEAR_SELECT_VALUES,
} from "../budgets.utils";
import { useNewBudgetsContext } from "../newBudgets.context";
import {
  RegisterBudgetsFormSchema,
  registerBudgetsFormSchema,
} from "./register-budgets-form.schema";
import { styles } from "./styles";

interface IBudgetsProps {
  open: boolean;
  selectedBudget: BudgetResult | null;
  handleClose: () => void;
}

export const RegisterBudgetsComponent = ({
  selectedBudget,
  handleClose,
}: IBudgetsProps) => {
  const { showSnackMessage } = useApplication();

  const [registerError, setRegisterError] = useState<Error | null>(null);
  const [target, setTarget] = useState<string | null>(
    selectedBudget?.billing_profile_token
      ? "billingProfile"
      : selectedBudget?.cost_center_token
      ? "costCenter"
      : "billingProfile",
  );

  const [period, setPeriod] = useState<string | null>(
    selectedBudget ? selectedBudget.timeframe : null,
  );

  const { handleCloseForm, loadClientBudgets } = useNewBudgetsContext();

  const { billingProfiles } = useBillingProfileContext();

  const { costCenters } = useContext(CostCentersContext);

  const form = useForm<RegisterBudgetsFormSchema>({
    defaultValues: {
      cost_center_token: selectedBudget?.cost_center_token || null,
      billing_profile_token: selectedBudget?.billing_profile_token || null,
      timeframe: selectedBudget?.timeframe || TimeframeType.YEAR,
      year: selectedBudget?.year || new Date().getFullYear(),
      trimester: selectedBudget?.trimester || null,
      semester: selectedBudget?.semester || null,
      month: selectedBudget?.month || null,
      value: selectedBudget?.value || 0,
      action: selectedBudget?.action || BudgetActionType.NONE,
    },
    onSubmit: async () => {
      const values = form.getValues();

      if (selectedBudget) {
        await update(
          selectedBudget.budget_token,
          values.timeframe,
          values.year,
          values.value,
          values.action,
          values.cost_center_token,
          values.billing_profile_token,
          values.trimester,
          values.semester,
          values.month,
        );
      } else {
        await register(
          values.timeframe,
          values.year,
          values.value,
          values.action,
          values.cost_center_token,
          values.billing_profile_token,
          values.trimester,
          values.semester,
          values.month,
        );
      }
    },
    schema: registerBudgetsFormSchema,
  });

  const register = async (
    timeframe: TimeframeType,
    year: number,
    value: number,
    action: BudgetActionType,
    cost_center_token?: string,
    billing_profile_token?: string,
    trimester?: number,
    semester?: number,
    month?: number,
  ) => {
    setRegisterError(null);

    await budgetApi
      .registerBudget({
        cost_center_token,
        billing_profile_token,
        timeframe,
        year,
        trimester,
        semester,
        month,
        value,
        action,
      })
      .then(
        async (data) => {
          if (!data.budget_token) {
            setRegisterError(null);

            return;
          }
          loadClientBudgets();
          handleCloseForm();
          showSnackMessage("Orçamento cadastrado", ALERT_TYPES.SUCCESS);
        },
        () => {
          showSnackMessage(
            "Erro ao registrar orçamento, verifique se não é um orçamento existente",
            ALERT_TYPES.ERROR,
          );

          return;
        },
      );
  };

  const update = async (
    budget_token: string,
    timeframe: TimeframeType,
    year: number,
    value: number,
    action: BudgetActionType,
    cost_center_token?: string,
    billing_profile_token?: string,
    trimester?: number,
    semester?: number,
    month?: number,
  ) => {
    setRegisterError(null);

    await budgetApi
      .updateBudget(budget_token, {
        cost_center_token,
        billing_profile_token,
        timeframe,
        year,
        semester,
        trimester,
        month,
        action,
        value,
      })
      .then(
        async (data) => {
          if (!data.budget_token) {
            setRegisterError(null);

            return;
          }
          loadClientBudgets();
          handleCloseForm();
          showSnackMessage("Orçamento atualizado", ALERT_TYPES.SUCCESS);
        },
        () => {
          showSnackMessage(
            "Erro ao atualizar orçamento, verifique se não é um orçamento existente",
            ALERT_TYPES.ERROR,
          );

          return;
        },
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <DrawerHeader onClose={handleClose}>
        {selectedBudget ? "Editando orçamento" : "Cadastrar novo orçamento"}
      </DrawerHeader>
      <div>
        {!form.submitting && registerError ? (
          <div>
            <Icon use="x-circle-outline" />
            <span>{registerError.description}</span>
          </div>
        ) : null}
        <Form context={form} css={styles.form}>
          <div css={styles.body}>
            {!selectedBudget && (
              <div css={styles.inputDiv}>
                <div css={styles.inputLabel}>
                  <span>Selecionar alvo</span>
                </div>
                <Select
                  isDisabled={!!selectedBudget}
                  placeholder="Selecionar alvo do orçamento"
                  defaultValue={target}
                  options={[
                    {
                      label: "CNPJ",
                      value: "billingProfile",
                    },
                    { label: "Centro de Custo", value: "costCenter" },
                  ]}
                  onChange={(e) => setTarget(e.value)}
                />
              </div>
            )}
            <div css={styles.inputDiv}>
              {target === "costCenter" ? (
                <>
                  <div css={styles.inputLabel}>
                    <span>Selecionar centro de custo</span>
                  </div>

                  <Select
                    defaultValue={selectedBudget?.cost_center_token}
                    id="cost_center_token"
                    isDisabled={!!selectedBudget}
                    name="cost_center_token"
                    options={costCenters.map((costCenter) => ({
                      label: costCenter.name,
                      value: costCenter.token,
                    }))}
                    placeholder={
                      costCenters.find(
                        (cc) => cc.token === selectedBudget?.cost_center_token,
                      )?.name || "Selecionar centro de custo"
                    }
                    onChange={(e) => {
                      form.setValue("cost_center_token", e.value);
                      form.setValue("billing_profile_token", null);
                    }}
                    useSetDefaultValueIfOnlyOneOption
                  />
                  <InputErrorMessage>
                    {form.errors["cost_center_token"]?.message}
                  </InputErrorMessage>
                </>
              ) : (
                <>
                  <div css={styles.inputLabel}>
                    <span>Selecionar CNPJ</span>
                  </div>
                  <Select
                    defaultValue={selectedBudget?.billing_profile_token}
                    id="billing_profile_token"
                    isDisabled={!!selectedBudget}
                    name="billing_profile_token"
                    onChange={(e) => {
                      form.setValue("billing_profile_token", e.value);
                      form.setValue("cost_center_token", null);
                    }}
                    options={billingProfiles.map((costCenter) => ({
                      label: costCenter.name,
                      value: costCenter.billingProfileToken,
                    }))}
                    placeholder={
                      billingProfiles.find(
                        (bb) =>
                          bb.billingProfileToken ===
                          selectedBudget?.billing_profile_token,
                      )?.legalName || "Selecionar CNPJ"
                    }
                    useSetDefaultValueIfOnlyOneOption
                  />
                  <InputErrorMessage>
                    {form.errors["billing_profile_token"]?.message}
                  </InputErrorMessage>
                </>
              )}
            </div>
            <div css={styles.inputDiv}>
              <div css={styles.inputLabel}>
                <span>Selecionar período</span>
              </div>
              <Select
                defaultValue={
                  selectedBudget
                    ? {
                        label: PERIOD_SELECT_VALUES.find(
                          (e) => e.value === selectedBudget.timeframe,
                        )?.label,
                        value: selectedBudget.timeframe,
                      }
                    : undefined
                }
                id="timeframe"
                name="timeframe"
                onChange={(e) => {
                  setPeriod(e.value);
                  form.setValue("timeframe", e.value);
                  form.setValue("semester", null);
                  form.setValue("trimester", null);
                  form.setValue("month", null);
                }}
                options={PERIOD_SELECT_VALUES}
                placeholder="Período"
              />
              <InputErrorMessage>
                {form.errors["timeframe"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.inputDiv}>
              <div css={styles.inputLabel}>
                <span>Selecionar o ano</span>
              </div>
              <Select
                id="year"
                name="year"
                placeholder="Ano"
                defaultValue={
                  selectedBudget
                    ? {
                        label: YEAR_SELECT_VALUES.find(
                          (e) => e.value === selectedBudget.year,
                        )?.label,
                        value: selectedBudget.year,
                      }
                    : undefined
                }
                options={YEAR_SELECT_VALUES}
                onChange={(e) => {
                  form.setValue("year", e.value);
                }}
              />
              <InputErrorMessage>
                {form.errors["year"]?.message}
              </InputErrorMessage>
            </div>
            {period === "MONTH" && (
              <div css={styles.inputDiv}>
                <div css={styles.inputLabel}>
                  <span>Selecionar o mẽs</span>
                </div>
                <Select
                  id="month"
                  name="month"
                  placeholder="Mês"
                  defaultValue={
                    selectedBudget
                      ? {
                          label: MONTH_SELECT_VALUES.find(
                            (e) => e.value === selectedBudget.month,
                          )?.label,
                          value: selectedBudget.month,
                        }
                      : undefined
                  }
                  onChange={(e) => {
                    form.setValue("month", e.value);
                  }}
                  options={MONTH_SELECT_VALUES}
                />
                <InputErrorMessage>
                  {form.errors["month"]?.message}
                </InputErrorMessage>
              </div>
            )}
            {period === "SEMESTER" && (
              <div css={styles.inputDiv}>
                <div css={styles.inputLabel}>
                  <span>Selecionar o semestre</span>
                </div>
                <Select
                  id="semester"
                  name="semester"
                  placeholder="Semestre"
                  defaultValue={
                    selectedBudget
                      ? {
                          label: SEMESTER_SELECT_VALUES.find(
                            (e) => e.value === selectedBudget.semester,
                          )?.label,
                          value: selectedBudget.semester,
                        }
                      : undefined
                  }
                  onChange={(e) => {
                    form.setValue("semester", e.value);
                  }}
                  options={SEMESTER_SELECT_VALUES}
                />
                <InputErrorMessage>
                  {form.errors["semester"]?.message}
                </InputErrorMessage>
              </div>
            )}
            {period === "TRIMESTER" && (
              <div css={styles.inputDiv}>
                <div css={styles.inputLabel}>
                  <span>Selecionar o trimestre</span>
                </div>
                <Select
                  id="trimester"
                  name="trimester"
                  placeholder="Trimestre"
                  defaultValue={
                    selectedBudget
                      ? {
                          label: TRIMESTER_SELECT_VALUES.find(
                            (e) => e.value === selectedBudget.trimester,
                          )?.label,
                          value: selectedBudget.trimester,
                        }
                      : undefined
                  }
                  onChange={(e) => {
                    form.setValue("trimester", e.value);
                  }}
                  options={TRIMESTER_SELECT_VALUES}
                />
                <InputErrorMessage>
                  {form.errors["trimester"]?.message}
                </InputErrorMessage>
              </div>
            )}

            <div css={styles.inputDiv}>
              <div css={styles.inputLabel}>
                <span>Valor do orçamento (R$)</span>
              </div>
              <CurrencyInput
                css={styles.input.container}
                name="value"
                id="value"
                value={form.watch("value")}
                onChange={(value) => {
                  form.setValue("value", value);
                }}
              />
              <InputErrorMessage>
                {form.errors["value"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.inputDiv}>
              <div css={styles.inputLabel}>
                <span>Quando notificar</span>
              </div>
              <Select
                id="action"
                name="action"
                onChange={(e) => {
                  form.setValue("action", e.value);
                }}
                placeholder="Notificar"
                options={[
                  {
                    label: "Ao violar 1ª vez",
                    value: "NOTIFY_FIRST",
                  },
                  {
                    label: "A cada violação",
                    value: "NOTIFY_EACH",
                  },
                  {
                    label: "Não notificar",
                    value: "NONE",
                  },
                  {
                    label: "Prevenir",
                    value: "PREVENT",
                  },
                ]}
              />
              <InputErrorMessage>
                {form.errors["action"]?.message}
              </InputErrorMessage>
            </div>
          </div>
          <Row css={styles.buttonsDiv}>
            <ContainedButton
              type="submit"
              disabled={form.submitting}
              css={styles.saveButton}
            >
              Salvar
            </ContainedButton>
            <OutlinedButton
              type="button"
              onClick={handleClose}
              disabled={false}
            >
              Cancelar
            </OutlinedButton>
          </Row>
        </Form>
      </div>
      <hr />
    </div>
  );
};

export const RegisterBudgetsForm = (props: IBudgetsProps) => (
  <Drawer
    anchor="right"
    open={props.open}
    onClose={props.handleClose}
    PaperProps={{
      style: {
        width: 815,
      },
    }}
  >
    <RegisterBudgetsComponent {...props} />
  </Drawer>
);
