import {
  CustomFieldOptionsType,
  GetClientCustomFieldOptionsResponse,
  CustomFieldOptionsResponseDto,
  SubmitCustomFieldOptionsRequestBody,
} from "~/apps/corporate/dtos/custom-field-options.dto";
import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import { api } from "~/apps/shared/services/api";

export async function activateCustomFieldOption(
  customFieldOptionToken: string,
) {
  return api
    .request<GetClientCustomFieldOptionsResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `booking/custom-field-options/${customFieldOptionToken}/activate`,
    })
    .then(({ data }) => data);
}

export async function addCustomFieldOptions(
  data: SubmitCustomFieldOptionsRequestBody[],
) {
  return await api
    .request<GetClientCustomFieldOptionsResponse[]>({
      data,
      method: "POST",
      url: `booking/clients/${getClientToken()}/custom-fields`,
    })
    .then(({ data }) => data);
}

export async function getClientCustomFieldOptions(
  clientToken: string,
  type?: CustomFieldOptionsType,
) {
  return api
    .request<GetClientCustomFieldOptionsResponse[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: {
        type,
      },
      url: `booking/clients/${clientToken}/custom-field-options`,
    })
    .then(({ data }) => data);
}

export async function getCustomFields() {
  return api
    .request<CustomFieldOptionsResponseDto[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `booking/clients/${getClientToken()}/custom-fields`,
    })
    .then(({ data }) => data);
}

export async function inactivateCustomFieldOption(
  customFieldOptionToken: string,
) {
  return api
    .request<GetClientCustomFieldOptionsResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `booking/custom-field-options/${customFieldOptionToken}/inactivate`,
    })
    .then(({ data }) => data);
}

export async function updateCustomFieldOptions(
  customFieldOptionToken: string,
  data: SubmitCustomFieldOptionsRequestBody,
) {
  return await api
    .request<GetClientCustomFieldOptionsResponse>({
      data,
      method: "PUT",
      url: `booking/custom-field-options/${customFieldOptionToken}`,
    })
    .then(({ data }) => data);
}
