import { Image, View } from "@react-pdf/renderer";
import React from "react";

import starIcon from "~/apps/shared/assets/icons/star.png";

type Props = React.ComponentProps<typeof View> & {
  rating: number;
};

export const TravelPlanPDFHotelSegmentStarRatingDisplay: React.FC<Props> = ({
  rating,
  ...props
}) => {
  return (
    <View {...props}>
      {Array(rating)
        .fill(0)
        .map((_, index) => (
          <Image
            key={index}
            src={starIcon}
            style={{
              height: 10,
              width: 10,
            }}
          />
        ))}
    </View>
  );
};
