import React, { useCallback, useEffect, useRef, useState } from "react";

import { ItineraryPendenciesModel } from "~/apps/corporate/models/itinerary/itinerary-pendencies.model";
import { ItineraryServicesModel } from "~/apps/corporate/models/itinerary/itinerary-services.model";
import { OfferTraveler } from "~/apps/corporate/models/offer.model";

import { useItineraryApproval } from "../../../itinerary-approval.context";
import { useItineraryPendencies } from "../../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { PendenciesAccordionSkeleton } from "../pendencies-accordion/pendencies-accordion";
import { CategorizationAccordion } from "./categorization-accordion/categorization-accordion";
import { DocumentationAccordion } from "./documentation-accordion/documentation-accordion";
import { NotEnoughTravelersAccordion } from "./not-enough-travelers-accordion/not-enough-travelers-accordion";
import { OffersJustificationAccordion } from "./offers-justification-accordion/offers-justification-accordion";
import { TravelPurposesAccordion } from "./travel-purposes-accordion/travel-purposes-accordion";
import { TravelerPhoneAccordion } from "./traveler-phone-accordion/traveler-phone-accordion";

const accordions = [
  {
    component: CategorizationAccordion,
    id: "categorization",
    isVisible: (pendencies: ItineraryPendenciesModel) =>
      pendencies.isCategorizationPending(),
  },
  {
    component: DocumentationAccordion,
    id: "documentation",
    isVisible: (pendencies: ItineraryPendenciesModel) =>
      pendencies.isDocumentationPending(),
  },
  {
    component: OffersJustificationAccordion,
    id: "offers-justification",
    isVisible: (pendencies: ItineraryPendenciesModel) =>
      pendencies.isOffersJustificationPending(),
  },
  {
    component: TravelerPhoneAccordion,
    id: "traveler-phone",
    isVisible: (pendencies: ItineraryPendenciesModel) =>
      pendencies.isTravelerPhonePending(),
  },
  {
    component: TravelPurposesAccordion,
    id: "travel-purposes",
    isVisible: (pendencies: ItineraryPendenciesModel) =>
      pendencies.isTravelPurposePending(),
  },
  {
    component: NotEnoughTravelersAccordion,
    id: "not-enough-travelers",
    isVisible: (
      servicesModel: ItineraryServicesModel,
      offersTravelers: Record<string, OfferTraveler[]>,
    ) => !servicesModel.hasEnoughTravelers(offersTravelers),
  },
] as const;

export const PendenciesAccordions: React.FC = () => {
  const { pendenciesModel, servicesModel } = useItineraryContainer();

  const { fetchItineraryApprovalStatus } = useItineraryApproval();
  const { isLoading, offersTravelers } = useItineraryPendencies();
  const { isProceedingFromPendencies } = useItineraryScreen();

  const [activeAccordions, setActiveAccordions] = useState<
    typeof accordions | null
  >(null);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const hasSetActiveAccordions = useRef(false);

  const handleNext = useCallback(
    (id: string) => async () => {
      if (expandedIndex === null) {
        return;
      }

      setIsLoadingNext(true);

      if (id === "categorization") {
        await fetchItineraryApprovalStatus();
      }

      setExpandedIndex(expandedIndex + 1);
      setIsLoadingNext(false);
    },
    [expandedIndex, fetchItineraryApprovalStatus],
  );

  const toggleExpanded = useCallback(
    (index: number) => {
      if (expandedIndex === index) {
        setExpandedIndex(null);

        return;
      }

      setExpandedIndex(index);
    },
    [expandedIndex],
  );

  useEffect(() => {
    if (!pendenciesModel) {
      return;
    }

    if (hasSetActiveAccordions.current) {
      return;
    }

    hasSetActiveAccordions.current = true;

    setActiveAccordions(
      (accordions.filter((accordion) => {
        if (accordion.id === "not-enough-travelers") {
          if (!servicesModel || !offersTravelers) {
            return false;
          }

          return accordion.isVisible(servicesModel, offersTravelers);
        }

        return accordion.isVisible(pendenciesModel);
      }) as unknown) as typeof accordions,
    );
  }, [hasSetActiveAccordions, offersTravelers, pendenciesModel, servicesModel]);

  if (isLoading) {
    <PendenciesAccordionsSkeleton />;
  }

  if (!activeAccordions) {
    return null;
  }

  return (
    <>
      {activeAccordions.map((accordion, index) => {
        const Accordion = accordion.component;

        return (
          <Accordion
            index={index + 1}
            isExpanded={expandedIndex === index}
            isLoadingNext={isLoadingNext || isProceedingFromPendencies}
            key={index}
            next={handleNext(accordion.id)}
            toggleExpanded={() => {
              toggleExpanded(index);
            }}
          />
        );
      })}
    </>
  );
};

const PendenciesAccordionsSkeleton: React.FC = () => {
  return (
    <>
      <PendenciesAccordionSkeleton />
      <PendenciesAccordionSkeleton />
      <PendenciesAccordionSkeleton />
      <PendenciesAccordionSkeleton />
    </>
  );
};
