import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    bounds: {
      bound: {
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }),
        title: css({
          fontWeight: 600,
          lineHeight: "28px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }),
    },
    fares: {
      fare: {
        card: css({
          alignItems: "center",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "8px",
          color: theme.colors.pink[500],
          display: "flex",
          gap: "8px",
          padding: "24px",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          width: "calc(50% - 12px)",
          "@media (max-width: 1024px)": {
            width: "100%",
          },
        }),
        title: css({
          fontWeight: 600,
          lineHeight: "28px",
        }),
      },
      root: css({
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        "@media (max-width: 1024px)": {
          flexDirection: "column",
        },
      }),
    },
    root: css({
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "24px",
      margin: "24px 0",
      padding: "0 24px",
    }),
  },
  warning: {
    root: css({
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      padding: "0.75rem 1rem",
      border: "1px solid",
      borderRadius: "0.5rem",
      borderColor: "#DC2626",
      color: "#DC2626",
      fontSize: 14,
      lineHeight: "1.125rem",
    }),
    icon: css({
      fontSize: 24,
      color: "#DC2626",
    }),
  },
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "24px",
      position: "sticky",
      width: "100%",
    }),
    policy: css({
      color: theme.colors.pink[500],
      fontSize: "0.875rem",
      fontWeight: 600,
    }),
    price: css({
      fontSize: "1.125rem",
      fontWeight: 700,
    }),
    warning: css({
      color: theme.colors.gray[500],
      fontSize: "0.875rem",
    }),
  },
};
