import React, { useCallback, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { CircularSpinner } from "@toolkit/v2";

import { useItineraryServices } from "../../itinerary-services.context";
import { styles } from "./styles";

export const ItineraryHeaderSendVoucherEmailButton: React.FC = () => {
  const {
    isLoadingSendVoucherEmail,
    sendVoucherEmail,
  } = useItineraryServices();

  const [sentVoucherEmail, setSentVoucherEmail] = useState(false);

  const handleSendVoucherEmail = useCallback(async () => {
    const success = await sendVoucherEmail();

    if (!success) {
      return;
    }

    setSentVoucherEmail(true);
  }, [sendVoucherEmail]);

  return (
    <Tooltip
      content={
        sentVoucherEmail
          ? "Vouchers enviados por e-mail"
          : isLoadingSendVoucherEmail
          ? "Enviando vouchers por e-mail..."
          : "Enviar vouchers por e-mail"
      }
      position="top"
    >
      <span css={styles.span}>
        <button
          css={styles.button}
          disabled={sentVoucherEmail}
          onClick={async () => {
            await handleSendVoucherEmail();
          }}
        >
          {isLoadingSendVoucherEmail ? (
            <CircularSpinner size={16} />
          ) : (
            <Icon size={16} use={sentVoucherEmail ? "check" : "ticket"} />
          )}
        </button>
      </span>
    </Tooltip>
  );
};
