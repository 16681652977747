import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    overflow: "auto",
    width: "100%",
    "::-webkit-scrollbar": {
      display: "none",
    },
  }),
  tabs: {
    root: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      margin: "0 80px",
      overflow: "hidden",
      width: "fit-content",
      "@media (max-width: 1280px)": {
        margin: "0 24px",
      },
      "@media (max-width: 992px)": {
        margin: "0 16px",
      },
    }),
    tab: {
      button: css({
        border: "none",
        borderRadius: "0px",
      }),
      divider: css({
        backgroundColor: theme.colors.gray[100],
        height: "100%",
        width: "1px",
      }),
      root: css({
        display: "flex",
        whiteSpace: "nowrap",
      }),
    },
  },
};
