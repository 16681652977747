import React from "react";

import { Select } from "~/apps/shared/components/select/select";
import { Option } from "~/apps/shared/types";

import { styles } from "./styles";

export const AllowedInternationalFlightDurationClassSelect: React.FC<{
  internationalFlightsDuration: number;
  internationalFlightsDurationClass: string;
  internationalFlightsDurationClassOptions: React.ComponentPropsWithoutRef<
    typeof Select
  >["options"];
  onInternationalDurationChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onInternationalFlightsDurationClassChange: React.ComponentPropsWithoutRef<
    typeof Select
  >["onChange"];
}> = ({
  internationalFlightsDuration,
  internationalFlightsDurationClass,
  internationalFlightsDurationClassOptions,
  onInternationalDurationChange,
  onInternationalFlightsDurationClassChange,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>
        Classe permitida para voos com duração maior que{" "}
        <input
          css={styles.input}
          id="internationalFlightsDuration"
          name="internationalFlightsDuration"
          onChange={onInternationalDurationChange}
          type="number"
          value={internationalFlightsDuration}
        />{" "}
        horas.
      </span>
      <Select
        css={styles.select}
        onChange={onInternationalFlightsDurationClassChange}
        options={internationalFlightsDurationClassOptions}
        value={(internationalFlightsDurationClassOptions as Option<string>[]).find(
          (option) => option.value === internationalFlightsDurationClass,
        )}
      />
    </div>
  );
};
