import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Row } from "../../shared/layout/Row";
import CloseIcon from "@material-ui/icons/Close";
import { Input } from "../../shared/inputs";
import { StContainedButton } from "../../shared/buttons";
import { withFormik, FormikErrors, FormikProps } from "formik";
import { defaultTheme } from "../../../assets/styles/theme";
import { css } from "emotion";
import { EXPENSE_REPORT_TYPE } from "~/constants";
import { Box } from "@toolkit";
import { ReportTypeSelect } from "./ReportTypeSelect";

const styles = {
  dialogPaper: css({
    ["@media (max-width: 500px)"]: {
      margin: 20,
      width: "100%"
    },
    ["@media (min-width: 501px)"]: {
      width: "auto"
    }
  }),
  button: css({
    padding: ".5rem 1rem",
    ["@media (max-width: 767px)"]: {
      minWidth: 150
    }
  }),
  header: css({
    justifyContent: "space-between",
    marginBottom: "1.5rem",
    alignItems: "center",
    color: defaultTheme.textColor,
    fontWeight: "bold"
  }),
  closeIcon: css({
    fontSize: 28,
    color: defaultTheme.subTextColor,
    cursor: "pointer"
  }),
  inputContainer: css({
    width: "100%"
  })
};

interface Props {
  open: boolean;
  isSubmiting: boolean;
  handleCreate: (formData: Form) => void;
  handleClose: () => void;
}

interface Form {
  description: string;
  reportType: string;
}

type OwnProps = Props & FormikProps<Form>;

const NewReportDialog = ({
  isSubmiting,
  handleSubmit,
  handleClose,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isValid
}: OwnProps) => {
  return (
    <Box py={"medium"} px={["medium", "large"]}>
      <form onSubmit={handleSubmit}>
        <Row className={styles.header}>
          <div>Novo relatório</div>
          <div>
            <CloseIcon
              className={styles.closeIcon}
              onClick={isSubmiting ? undefined : handleClose}
            />
          </div>
        </Row>
        <Box mb={24}>
          <Input
            id="description"
            name="description"
            label="Descrição"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            containerClasses={styles.inputContainer}
            error={errors.description && touched.description}
            errorMessage={
              errors.description && touched.description && errors.description
            }
          />
        </Box>
        <Box mb="medium">
          <ReportTypeSelect
            id="reportType"
            name="reportType"
            value={values.reportType}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Row style={{ justifyContent: "center" }}>
          <StContainedButton
            type="submit"
            isFullWidth={false}
            className={styles.button}
            disabled={!isValid || isSubmiting}
            loading={isSubmiting}
          >
            Criar novo relatório
          </StContainedButton>
        </Row>
      </form>
    </Box>
  );
};

const FormikContainer = withFormik<Props, Form>({
  mapPropsToValues: () => ({
    description: "",
    reportType: EXPENSE_REPORT_TYPE.TRAVEL
  }),
  validate: values => {
    const error: FormikErrors<Form> = {};

    if (!values.description) {
      error.description = "Preencha a descrição do relatório";
    }

    return error;
  },
  handleSubmit: (values, { props }) => {
    props.handleCreate(values);
  }
})(NewReportDialog);

const DialogContainer = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      classes={{ paper: styles.dialogPaper }}
    >
      <FormikContainer {...props} />
    </Dialog>
  );
};

export { DialogContainer as NewReportDialog };
