import React, { useEffect, useMemo } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import debounce from "lodash/debounce";
import some from "lodash/some";
import moment from "moment";

import { FINANCIAL_TABS, FINANCIAL_TYPES } from "../../constants";
import * as financialHelper from "../../helpers/financial.helper";
import { useSafeState } from "../../hooks";
import {
  FlightInvoiceItem,
  HotelInvoiceItem,
  SmartripsInvoiceItem,
  CarInvoiceItem,
  BusInvoiceItem,
} from "../../models/financial.model";
import { UserModel } from "../../models/user.model";
import { StringTMap } from "../../types";
import * as financialService from "./Financial.service";

moment.locale("pt-br");

interface State {
  busInvoices: BusInvoiceItem[];
  carInvoices: CarInvoiceItem[];
  currentPage: number;
  donwloadingConciliation: boolean;
  downloadingInvoices: boolean;
  downloadingStatement: boolean;
  endDate: moment.Moment | null;
  flightInvoices: FlightInvoiceItem[];
  hotelInvoices: HotelInvoiceItem[];
  loading: boolean;
  rangeDateFilter: {
    endDateFilter: string | null;
    startDateFilter: string | null;
  };
  rangeFocus: string | null;
  searchFilter: string;
  selectedInvoices: StringTMap<boolean>;
  smartripsInvoices: SmartripsInvoiceItem[];
  startDate: moment.Moment | null;
  tagFilter: string;
  totalPages: number;
  users: UserModel[];
  visibleTab: number;
}

interface Actions {
  fetchInvoices: () => void;
  handleChangeRangeDatePickerFocus: (focused: string) => void;
  handleChangeSearchFilter: (search: string) => void;
  handleChangeSelectAll: () => void;
  handleChangeTagFilter: (tag: string) => void;
  handleDownloadConciliation: () => void;
  handleDownloadFinancialStatement: () => void;
  handleDownloadTransactionsInvoices: () => void;
  handleRangeDateChange: (date: moment.Moment | null) => void;
  handleSelectInvoice: (
    transactionToken: string,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTabChange: (tab: number) => () => void;
  loadNextInvoices: () => void;
}

interface Selectors {
  areAllInvoicesSelected: boolean;
  hasSomeInvoiceSelected: boolean;
}

type ContextProps = State & Actions & Selectors;

const TODAY = moment().endOf("day").format("YYYYMMDD");
const ONE_MONTH_AGO = moment()
  .subtract(1, "month")
  .startOf("day")
  .format("YYYYMMDD");

const initialState: State = {
  busInvoices: [],
  carInvoices: [],
  currentPage: 1,
  donwloadingConciliation: false,
  downloadingInvoices: false,
  downloadingStatement: false,
  endDate: moment().endOf("day"),
  flightInvoices: [],
  hotelInvoices: [],
  loading: false,
  rangeDateFilter: {
    endDateFilter: TODAY,
    startDateFilter: ONE_MONTH_AGO,
  },
  rangeFocus: null,
  searchFilter: "",
  selectedInvoices: {},
  smartripsInvoices: [],
  startDate: moment().subtract(1, "month"),
  tagFilter: "",
  totalPages: 1,
  users: [],
  visibleTab: FINANCIAL_TABS.FLIGHTS,
};

export const FinancialContext = React.createContext<ContextProps>({
  ...initialState,
  areAllInvoicesSelected: false,
  hasSomeInvoiceSelected: false,
  fetchInvoices: () => null,
  handleChangeRangeDatePickerFocus: () => null,
  handleChangeSearchFilter: () => null,
  handleChangeSelectAll: () => null,
  handleChangeTagFilter: () => null,
  handleDownloadConciliation: () => null,
  handleDownloadFinancialStatement: () => null,
  handleDownloadTransactionsInvoices: () => null,
  handleRangeDateChange: () => null,
  handleSelectInvoice: () => () => null,
  handleTabChange: () => () => null,
  loadNextInvoices: () => null,
});

export const FinancialProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const fetchInvoices = () => {
    const {
      rangeDateFilter: { startDateFilter, endDateFilter },
      searchFilter,
      tagFilter,
      visibleTab,
    } = state;
    const selectedType = financialHelper.getSelectedType(visibleTab);

    if (selectedType === FINANCIAL_TYPES.SMARTRIPS) {
      void fetchSmartripsInvoices(startDateFilter, endDateFilter);
    } else {
      void fetchServiceInvoices(
        selectedType,
        startDateFilter,
        endDateFilter,
        searchFilter,
        tagFilter !== "all" ? tagFilter : "",
      );
    }
  };

  const fetchServiceInvoices = async (
    type: string,
    startDate: string | null,
    endDate: string | null,
    search: string,
    tag: string,
  ) => {
    setState({ loading: true });

    const {
      data: invoicesData,
      error: invoicesError,
    } = await financialService.getServicesInvoiceList(
      startDate,
      endDate,
      type,
      1,
      search,
      tag,
    );

    if (invoicesError) {
      setState({ loading: false });

      showSnackMessage(invoicesError.description, ALERT_TYPES.ERROR);

      return;
    }

    if (type === FINANCIAL_TYPES.FLIGHT) {
      const serviceInvoices = invoicesData!.services as FlightInvoiceItem[];
      setState({
        currentPage: invoicesData!.current,
        flightInvoices: serviceInvoices,
        loading: false,
        selectedInvoices: {},
        totalPages: invoicesData!.pages,
      });
    } else if (type === FINANCIAL_TYPES.HOTEL) {
      const serviceInvoices = invoicesData!.services as HotelInvoiceItem[];
      setState({
        currentPage: invoicesData!.current,
        hotelInvoices: serviceInvoices,
        loading: false,
        selectedInvoices: {},
        totalPages: invoicesData!.pages,
      });
    } else if (type === FINANCIAL_TYPES.CAR) {
      const serviceInvoices = invoicesData!.services as CarInvoiceItem[];
      setState({
        carInvoices: serviceInvoices,
        currentPage: invoicesData!.current,
        loading: false,
        selectedInvoices: {},
        totalPages: invoicesData!.pages,
      });
    } else if (type === FINANCIAL_TYPES.BUS) {
      const serviceInvoices = invoicesData!.services as BusInvoiceItem[];
      setState({
        busInvoices: serviceInvoices,
        currentPage: invoicesData!.current,
        loading: false,
        selectedInvoices: {},
        totalPages: invoicesData!.pages,
      });
    }
  };

  const handleChangeRangeDatePickerFocus = (focused: string) => {
    setState({ rangeFocus: focused });
  };

  const handleRangeDateChange = (date: any) => {
    if (date.startDate && date.endDate) {
      setState({
        rangeDateFilter: {
          endDateFilter: date.endDate.format("YYYYMMDD"),
          startDateFilter: date.startDate.format("YYYYMMDD"),
        },
      });
    }

    setState({ startDate: date.startDate, endDate: date.endDate });
  };

  const fetchSmartripsInvoices = async (
    startDate: string | null,
    endDate: string | null,
  ) => {
    setState({ loading: true });

    const {
      data,
      error,
    } = await financialService.getSmartripsServicesInvoiceList(
      startDate,
      endDate,
    );

    if (error) {
      setState({ loading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      currentPage: 1,
      loading: false,
      selectedInvoices: {},
      smartripsInvoices: data!,
      totalPages: 1,
    });
  };

  const loadNextInvoices = async () => {
    setState({ loading: true });
    const {
      currentPage,
      rangeDateFilter: { startDateFilter, endDateFilter },
      searchFilter,
      tagFilter,
      visibleTab,
    } = state;
    const selectedType = financialHelper.getSelectedType(visibleTab);

    const {
      data: invoicesData,
      error: invoicesError,
    } = await financialService.getServicesInvoiceList(
      startDateFilter,
      endDateFilter,
      selectedType,
      currentPage + 1,
      searchFilter,
      tagFilter !== "all" ? tagFilter : "",
    );

    if (invoicesError) {
      setState({ loading: false });

      showSnackMessage(invoicesError.description, ALERT_TYPES.ERROR);

      return;
    }

    const invoicesList = {
      busInvoices: state.busInvoices,
      carInvoices: state.carInvoices,
      flightInvoices: state.flightInvoices,
      hotelInvoices: state.hotelInvoices,
    };

    if (selectedType === FINANCIAL_TYPES.FLIGHT) {
      const nextInvoices = invoicesData!.services as FlightInvoiceItem[];
      invoicesList.flightInvoices.push(...nextInvoices);
    } else if (selectedType === FINANCIAL_TYPES.HOTEL) {
      const nextInvoices = invoicesData!.services as HotelInvoiceItem[];
      invoicesList.hotelInvoices.push(...nextInvoices);
    } else if (selectedType === FINANCIAL_TYPES.CAR) {
      const nextInvoices = invoicesData!.services as CarInvoiceItem[];
      invoicesList.carInvoices.push(...nextInvoices);
    } else if (selectedType === FINANCIAL_TYPES.BUS) {
      const nextInvoices = invoicesData!.services as BusInvoiceItem[];
      invoicesList.busInvoices.push(...nextInvoices);
    }

    setState({
      busInvoices: invoicesList.busInvoices,
      carInvoices: invoicesList.carInvoices,
      currentPage: currentPage + 1,
      flightInvoices: invoicesList.flightInvoices,
      hotelInvoices: invoicesList.hotelInvoices,
      loading: false,
      totalPages: invoicesData!.pages,
    });
  };

  const handleTabChange = (tab: number) => () => {
    if (state.visibleTab !== tab) {
      setState({
        busInvoices: [],
        carInvoices: [],
        flightInvoices: [],
        hotelInvoices: [],
        searchFilter: "",
        selectedInvoices: {},
        smartripsInvoices: [],
        tagFilter: "",
        visibleTab: tab,
      });
    }
  };

  const handleChangeSelectAll = () => {
    const {
      busInvoices,
      carInvoices,
      flightInvoices,
      hotelInvoices,
      visibleTab,
    } = state;

    let updatedSelectedInvoices: StringTMap<boolean> = {};
    if (isSomeInvoiceSelected()) {
      updatedSelectedInvoices = {};
    } else if (visibleTab === FINANCIAL_TABS.FLIGHTS) {
      updatedSelectedInvoices = flightInvoices.reduce(
        (acc: StringTMap<boolean>, current) => {
          acc[current.selectedOffer.transactionToken] = true;
          return acc;
        },
        {},
      );
    } else if (visibleTab === FINANCIAL_TABS.HOTELS) {
      updatedSelectedInvoices = hotelInvoices.reduce(
        (acc: StringTMap<boolean>, current) => {
          acc[current.selectedOffer.transactionToken] = true;
          return acc;
        },
        {},
      );
    } else if (visibleTab === FINANCIAL_TABS.CARS) {
      updatedSelectedInvoices = carInvoices.reduce(
        (acc: StringTMap<boolean>, current) => {
          acc[current.selectedOffer.transactionToken] = true;
          return acc;
        },
        {},
      );
    } else if (visibleTab === FINANCIAL_TABS.BUSES) {
      updatedSelectedInvoices = busInvoices.reduce(
        (acc: StringTMap<boolean>, current) => {
          acc[current.selectedOffer.transactionToken] = true;
          return acc;
        },
        {},
      );
    }

    setState({
      selectedInvoices: updatedSelectedInvoices,
    });
  };

  const handleChangeSearchFilter = debounce(
    (search) => setState({ searchFilter: search }),
    300,
  );

  const handleChangeTagFilter = (tagToken: string) => {
    setState({ tagFilter: tagToken });
  };

  const handleSelectInvoice = (transactionToken: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState({
      selectedInvoices: Object.assign({}, state.selectedInvoices, {
        [transactionToken]: e.target.checked,
      }),
    });
  };

  const handleDownloadTransactionsInvoices = async () => {
    setState({ downloadingInvoices: true });

    const transactionTokens = Object.keys(state.selectedInvoices).reduce(
      (acc: string[], current: string) => {
        if (state.selectedInvoices[current]) {
          acc.push(current);
        }

        return acc;
      },
      [],
    );

    const { error } = await financialService.downloadTrasactionsInvoices(
      transactionTokens,
    );

    if (error) {
      setState({ downloadingInvoices: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({ downloadingInvoices: false });
  };

  const handleDownloadFinancialStatement = async () => {
    setState({ downloadingStatement: true });
    const {
      rangeDateFilter: { startDateFilter, endDateFilter },
      tagFilter,
    } = state;

    const { error } = await financialService.downloadFinancialStatement(
      startDateFilter,
      endDateFilter,
      tagFilter,
    );

    if (error) {
      setState({ downloadingStatement: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({ downloadingStatement: false });
  };

  const handleDownloadConciliation = async () => {
    setState({ donwloadingConciliation: true });
    const {
      rangeDateFilter: { startDateFilter, endDateFilter },
    } = state;

    const { error } = await financialService.downloadConciliationTable(
      startDateFilter,
      endDateFilter,
    );

    if (error) {
      setState({ donwloadingConciliation: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({ donwloadingConciliation: false });
  };

  const isSomeInvoiceSelected = () =>
    some(state.selectedInvoices, (invoice) => !!invoice);

  const hasSomeInvoiceSelected = useMemo(isSomeInvoiceSelected, [state]);

  const areAllInvoicesSelected = useMemo(() => {
    const {
      busInvoices,
      carInvoices,
      flightInvoices,
      hotelInvoices,
      selectedInvoices,
      visibleTab,
    } = state;

    let invoiceTokens: string[] = [];
    if (visibleTab === FINANCIAL_TABS.FLIGHTS) {
      invoiceTokens = flightInvoices.map(
        (invoice) => invoice.selectedOffer.transactionToken,
      );
    } else if (visibleTab === FINANCIAL_TABS.HOTELS) {
      invoiceTokens = hotelInvoices.map(
        (invoice) => invoice.selectedOffer.transactionToken,
      );
    } else if (visibleTab === FINANCIAL_TABS.CARS) {
      invoiceTokens = carInvoices.map(
        (invoice) => invoice.selectedOffer.transactionToken,
      );
    } else if (visibleTab === FINANCIAL_TABS.BUSES) {
      invoiceTokens = busInvoices.map(
        (invoice) => invoice.selectedOffer.transactionToken,
      );
    }

    return invoiceTokens.every(
      (invoiceToken) => !!selectedInvoices[invoiceToken],
    );
  }, [state]);

  useEffect(() => {
    fetchInvoices();
  }, [
    state.rangeDateFilter,
    state.searchFilter,
    state.tagFilter,
    state.visibleTab,
  ]);

  return (
    <FinancialContext.Provider
      value={{
        ...state,
        areAllInvoicesSelected,
        fetchInvoices,
        handleChangeRangeDatePickerFocus,
        handleChangeSearchFilter,
        handleChangeSelectAll,
        handleChangeTagFilter,
        handleDownloadConciliation,
        handleDownloadFinancialStatement,
        handleDownloadTransactionsInvoices,
        handleRangeDateChange,
        handleSelectInvoice,
        handleTabChange,
        hasSomeInvoiceSelected,
        loadNextInvoices,
      }}
    >
      {children}
    </FinancialContext.Provider>
  );
};
