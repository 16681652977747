import React from "react";
import DeletionDialog from "@shared/dialogs/DeletionDialog";
import {
  useHotelBookingConfirmationActionsContext,
  useHotelBookingConfirmationContext
} from "../HotelBookingConfirmation.context";

const HotelBookingConfirmationDenyDialog = () => {
  const {
    isDenyDialogVisible,
    isResponding
  } = useHotelBookingConfirmationContext();
  const {
    handleCloseDenyDialog,
    handleDenyHotelBooking
  } = useHotelBookingConfirmationActionsContext();

  return (
    <DeletionDialog
      open={isDenyDialogVisible}
      title="Negar reserva"
      message="Deseja realmente negar a reserva do quarto solicitado?"
      deleteButtonLabel={"Negar"}
      handleClose={handleCloseDenyDialog}
      handleDeletion={handleDenyHotelBooking}
      isDeleting={isResponding}
    />
  );
};

export { HotelBookingConfirmationDenyDialog };
