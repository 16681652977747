import * as yup from "yup";

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("Campo obrigatório.")
    .test("", "Email inválido.", (email) => {
      if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(email)) {
        return false;
      }

      return true;
    }),
  password: yup.string().required("Insira a senha."),
});

export type LoginFormSchema = yup.InferType<typeof loginFormSchema>;
