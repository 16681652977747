import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Line,
  ComposedChart,
  ResponsiveContainer
} from "recharts";
import { styles, tooltipStyles } from "../../common-styles";

const CustomizedAxisXTick = props => {
  const { x, y, stroke, payload } = props;
  const [periodName, percentage] = payload.value.split(":");

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {periodName}
      </text>
    </g>
  );
};

const CustomizedAxisYTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        textAnchor="middle"
        fill="#666"
        className={styles.tickText}
      >
        {payload.value}
      </text>
    </g>
  );
};

const TotalTravelsChart = ({ data }) => {
  const hasSomeDataToShow = data.some(item => !!item.total);

  if (!hasSomeDataToShow) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 330
        }}
      >
        <p>Nenhum dado a ser mostrado</p>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={330}>
      <ComposedChart
        data={data}
        margin={{ top: 40, right: 30, left: 0, bottom: 20 }}
      >
        <CartesianGrid
          strokeDasharray="5 10"
          vertical={false}
          horizontal={false}
        />
        <XAxis axisLine={true} dataKey="name" tick={<CustomizedAxisXTick />} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick />}
        />
        <Tooltip
          {...tooltipStyles}
          labelFormatter={name => {
            return name.split(":")[0];
          }}
        />
        {/* <Bar
          maxBarSize={32}
          name="Total de Viagens"
          dataKey="total"
          stackId="a"
          fill={colors.cornflowerBlue}
          stroke={colors.cornflowerBlue}
          strokeWidth={2}
        /> */}
        <Line
          name="Total"
          dataKey="total"
          activeDot={{ r: 8 }}
          stroke={"#194EC0"}
          fill={"#194EC0"}
          strokeWidth={4}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { TotalTravelsChart };
