import React from "react";

import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from "@material-ui/core";
import { useApplication } from "~/apps/corporate/contexts/application.context";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

export type SnackbarProps = MuiSnackbarProps;

export type SnackbarType = "success" | "error" | "info";

export const Snackbar: React.FC = () => {
  const { closeSnackbar, snackbar } = useApplication();

  const { message, open, options, type } = snackbar;

  return (
    <div
      css={styles.root({
        type,
      })}
    >
      <MuiSnackbar
        action={[
          <button
            css={styles.close}
            key="close"
            onClick={async () => {
              await closeSnackbar();
            }}
          >
            <Icon size={16} use="x" />
          </button>,
        ]}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        autoHideDuration={5000}
        message={<span css={styles.message}>{message}</span>}
        onClose={closeSnackbar}
        open={open}
        {...options}
      />
    </div>
  );
};
