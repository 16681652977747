import React from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";

import { styles } from "./styles";

type Props = {
  handleRentalsFilterChange: (event: any, checked: boolean) => void;
  rentalOptions: {
    checked: boolean;
    id: string;
    label: string;
  }[];
};

export const RentalsFilter: React.FC<Props> = ({
  handleRentalsFilterChange,
  rentalOptions,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.title}>Locadoras</span>
      <CheckboxGroup css={styles.checkbox.group.root}>
        {rentalOptions.map((rentalOption) => (
          <CheckboxGroup.Item
            aria-label={rentalOption.label}
            checked={rentalOption.checked}
            css={styles.checkbox.group.item}
            id={rentalOption.id}
            key={rentalOption.id}
            onChange={(e) => {
              handleRentalsFilterChange(e, !rentalOption.checked);
            }}
            value={rentalOption.checked}
          >
            <span css={styles.text}>{rentalOption.label}</span>
          </CheckboxGroup.Item>
        ))}
      </CheckboxGroup>
    </div>
  );
};
