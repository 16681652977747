import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  link: css({
    borderBottom: `1px solid ${theme.colors.pink[300]}`,
    color: theme.colors.pink[500],
    fontSize: "0.875rem",
    fontWeight: 600,
    textDecoration: "none",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      borderBottomColor: theme.colors.pink[500],
    },
  }),
  root: css({
    alignItems: "center",
    color: theme.colors.gray[500],
    display: "flex",
    gap: "12px",
  }),
  text: css({
    fontSize: "0.875rem",
  }),
};
