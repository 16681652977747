import { css } from "@styled-system/css";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "24px",
    padding: "0 32px",
    "@media (max-width: 768px)": {
      padding: "0"
    }
  })
};
