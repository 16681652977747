import React, { createContext } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import {
  CustomFieldOption,
  SubmitCustomFieldOptionsRequestBody,
} from "~/apps/corporate/dtos/custom-field-options.dto";
import { ALERT_TYPES } from "~/apps/shared/constants";

import { useSafeState, useContextFactory } from "@hooks";

import * as customFieldOptionService from "./custom-field-options.service";

interface State {
  creationBoxIsVisible: boolean;
  customFieldOptions: CustomFieldOption[];
  customFieldOptionToUpdate: CustomFieldOption;
  filterValue: string;
  isLoading: boolean;
  isSaving: boolean;
  newCustomFieldOptions: CustomFieldOption[] | null;
  updateBoxIsVisible: boolean;
}

interface Actions {
  addCustomFieldOptions: (
    customFieldOptions: SubmitCustomFieldOptionsRequestBody[],
  ) => Promise<void>;
  handleActivate: (customFieldOptionToken: string) => Promise<void>;
  handleCloseCreationBox: () => void;
  handleCloseUpdateBox: () => void;
  handleInactivate: (customFieldOptionToken: string) => Promise<void>;
  handleOpenCreationBox: () => void;
  handleOpenUpdateBox: (customFieldOption: CustomFieldOption) => void;
  handleTabChange: (value: string) => void;
  loadClientCustomFieldOptions: () => void;
  updateCustomFieldOption: (
    customFieldOptionToken: string,
    customFieldOption: SubmitCustomFieldOptionsRequestBody,
  ) => Promise<void>;
}

const initialState: State = {
  creationBoxIsVisible: false,
  customFieldOptions: [],
  customFieldOptionToUpdate: {} as CustomFieldOption,
  filterValue: "active",
  isLoading: false,
  isSaving: false,
  newCustomFieldOptions: null,
  updateBoxIsVisible: false,
};

type ContextProps = Actions & State;

const CustomFieldOptionContext = createContext({
  ...initialState,
} as ContextProps);

export const CustomFieldOptionProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useSafeState(initialState);

  const loadClientCustomFieldOptions = async () => {
    setState({
      isLoading: true,
    });

    const {
      data,
      error,
    } = await customFieldOptionService.getClientCustomFieldOptions();

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      customFieldOptions: data,
      isLoading: false,
    });
  };

  const addCustomFieldOptions = async (
    customFieldOptions: SubmitCustomFieldOptionsRequestBody[],
  ) => {
    setState({
      creationBoxIsVisible: false,
    });

    const { error } = await customFieldOptionService.addCustomFieldOption(
      customFieldOptions,
    );

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    await loadClientCustomFieldOptions();
  };

  const handleOpenCreationBox = () => setState({ creationBoxIsVisible: true });

  const handleCloseCreationBox = () =>
    setState({ creationBoxIsVisible: false });

  const handleActivate = async (customFieldOptionToken: string) => {
    setState({
      isLoading: true,
    });

    const { error } = await customFieldOptionService.activateCustomFieldOption(
      customFieldOptionToken,
    );

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      customFieldOptions: state.customFieldOptions.map((el) => {
        if (el.custom_field_option_token === customFieldOptionToken) {
          el.active = true;
          return el;
        }
        return el;
      }),
      isLoading: false,
    });
  };

  const handleInactivate = async (customFieldOptionToken: string) => {
    setState({
      isLoading: true,
    });

    const {
      error,
    } = await customFieldOptionService.inactivateCustomFieldOption(
      customFieldOptionToken,
    );

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      customFieldOptions: state.customFieldOptions.map((el) => {
        if (el.custom_field_option_token === customFieldOptionToken) {
          el.active = false;
          return el;
        }
        return el;
      }),
      isLoading: false,
    });
  };

  const handleOpenUpdateBox = (customFieldOption: CustomFieldOption) =>
    setState({
      customFieldOptionToUpdate: customFieldOption,
      updateBoxIsVisible: true,
    });

  const handleCloseUpdateBox = () => {
    setState({
      customFieldOptionToUpdate: {} as CustomFieldOption,
      updateBoxIsVisible: false,
    });
  };

  const handleTabChange = (value: string) => {
    setState({
      filterValue: value,
    });
  };

  const updateCustomFieldOption = async (
    customFieldOptionToken: string,
    customFieldOption: SubmitCustomFieldOptionsRequestBody,
  ) => {
    setState({
      creationBoxIsVisible: false,
    });

    const { error } = await customFieldOptionService.updateCustomFieldOption(
      customFieldOptionToken,
      customFieldOption,
    );

    if (error) {
      setState({ isLoading: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      updateBoxIsVisible: false,
    });

    await loadClientCustomFieldOptions();
  };

  return (
    <CustomFieldOptionContext.Provider
      value={{
        ...state,
        addCustomFieldOptions,
        handleActivate,
        handleCloseCreationBox,
        handleCloseUpdateBox,
        handleInactivate,
        handleOpenCreationBox,
        handleOpenUpdateBox,
        handleTabChange,
        loadClientCustomFieldOptions,
        updateCustomFieldOption,
      }}
    >
      {children}
    </CustomFieldOptionContext.Provider>
  );
};

export const useCustomFieldOptions: () => ContextProps = useContextFactory(
  "CustomFieldOptionContext",
  CustomFieldOptionContext,
);
