import React, { useCallback } from "react";
import { components, MultiValueGenericProps, OptionProps } from "react-select";
import AsyncAutocomplete from "react-select/async";

import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Form, useFormContext } from "~/apps/shared/components/form/form";
import { TargetItem } from "~/apps/shared/components/target-item/target-item";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { debounce } from "lodash";

import * as userService from "../../../users.service";
import { BookerTarget } from "../users-user-form.types";
import { styles } from "./styles";

const AutocompleteMultiValueLabel: React.FC<
  MultiValueGenericProps<BookerTarget>
> = ({ ...props }) => {
  const { data } = props;

  return (
    <components.MultiValueLabel {...props}>
      <div css={styles.label.root}>
        <Avatar css={styles.label.avatar} name={data.fullName} />
        <div css={styles.label.info.root}>
          <span css={styles.label.info.name}>{data.fullName}</span>
          {data.email ? (
            <span css={styles.label.info.email}>{data.email}</span>
          ) : null}
        </div>
      </div>
    </components.MultiValueLabel>
  );
};

const BookerTargetOption: React.FC<OptionProps<BookerTarget>> = ({
  ...props
}) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <TargetItem
        target={{
          email: data.email,
          fullName: data.fullName,
        }}
      />
    </components.Option>
  );
};

export const UserFormBookerTargets: React.FC = () => {
  const form = useFormContext();

  const userToken = form.watch("userToken", "");

  const handleSearchBookerTarget = useCallback(
    async (search: string) => {
      const bookerToken = userToken ? userToken : "";

      const searchBookerTargetsToAddResponse = await userService.searchBookerTargetsToAdd(
        search,
        bookerToken,
      );

      if (!searchBookerTargetsToAddResponse.data) {
        return [];
      }

      return searchBookerTargetsToAddResponse.data.filter(
        (bookerTarget) => bookerTarget.userToken !== userToken,
      );
    },
    [userToken],
  );

  const loadOptions = useCallback(
    debounce((inputText: string, callback: any) => {
      void handleSearchBookerTarget(inputText).then(callback);
    }, 500),
    [handleSearchBookerTarget],
  );

  const handleBookerTargetChange = useCallback((onChange) => {
    return (value: BookerTarget[]) => {
      onChange(value);
    };
  }, []);

  return (
    <div css={styles.root}>
      <div css={styles.title.root}>
        <span css={styles.title.text}>
          Viajantes para os quais este usuário pode solicitar (deixar em branco
          para todos):
        </span>
        <Tooltip
          arrow
          content="Selecione os viajantes que este usuário poderá solicitar viagens. Caso nenhum seja selecionado, ele poderá solicitar para todos."
        />
      </div>
      <Form.Field<BookerTarget[]>
        name="bookerTargets"
        render={({ onChange, value }) => (
          <AsyncAutocomplete
            components={{
              ...components,
              MultiValueLabel: AutocompleteMultiValueLabel,
              Option: BookerTargetOption,
            }}
            getOptionLabel={(option: BookerTarget) => option.fullName}
            getOptionValue={(option: BookerTarget) => option.userToken}
            isMulti
            loadingMessage={() => "Carregando..."}
            loadOptions={loadOptions}
            noOptionsMessage={() => "Busque viajantes..."}
            onChange={handleBookerTargetChange(onChange) as any}
            placeholder="Digite o nome ou email do usuário"
            styles={styles.select}
            value={value}
          />
        )}
      />
    </div>
  );
};
