import { useMemo } from "react";

import { useItineraryContainer } from "../../../../itinerary.container";
import { CategorizationAccordionPresenterFactory } from "./categorization-accordion.presenter";

export const useCategorizationAccordionPresenter = () => {
  const { infoModel, pendenciesModel } = useItineraryContainer();

  return useMemo(
    () => ({
      categorizationAccordionPresenter:
        infoModel && pendenciesModel
          ? CategorizationAccordionPresenterFactory.create(
              infoModel,
              pendenciesModel,
            )
          : null,
    }),
    [infoModel, pendenciesModel],
  );
};
