import React from "react";
import { useQuery } from "react-query";

import { getClientBillingProfilesList } from "~/apps/corporate/pages/configurations/views/financial/billing-profiles/BillingProfiles.service";
import { AsyncData } from "~/apps/shared/components/async-data/async-data";
import { Option } from "~/apps/shared/types";

import { BillingProfileListItem } from "~/models/billing-profile.model";

import { Select, SelectProps } from "@shared/inputs";

async function handleLoadBillingProfiles() {
  const result = await getClientBillingProfilesList();

  if (result.error) {
    throw result.error;
  }

  return result.data;
}

function buildOptions(options: BillingProfileListItem[]) {
  return options.map((option) => ({
    label: `${option.cnpj} - ${option.legalName}`,
    value: option.billingProfileToken,
  }));
}

function buildOptionsToCreditCard(options: BillingProfileListItem[]) {
  return options.map((option) => ({
    label: `${option.name} - ${option.cnpj}`,
    value: option.billingProfileToken,
  }));
}

type Props = SelectProps & {
  additionalOptions?: Option<any>[];
  disabled?: boolean;
  handleChange?: () => {};
  onCreditCardTab?: boolean;
};

export default function BillingProfileSelect(props: Props) {
  const { defaultValue, onCreditCardTab = false, ...rest } = props;
  const query = useQuery(["billing-profile"], handleLoadBillingProfiles);

  return (
    <AsyncData {...query}>
      <AsyncData.Loading>
        <Select placeholder="Carregando perfis de cobrança..." isDisabled />
      </AsyncData.Loading>
      <AsyncData.Success>
        {(data) => {
          let options;

          if (onCreditCardTab) {
            options = buildOptionsToCreditCard(data);
          } else {
            options = buildOptions(data);
          }

          if (props.additionalOptions) {
            options = [...props.additionalOptions, ...options];
          }

          return (
            <Select
              aria-label="Selecione o perfil de cobrança"
              defaultValue={
                defaultValue
                  ? options.find((option) => option.value === defaultValue)
                  : undefined
              }
              placeholder="Perfil de cobrança"
              options={options}
              {...rest}
            />
          );
        }}
      </AsyncData.Success>
      <AsyncData.Error>
        <Select placeholder="Carregando perfis de cobrança..." isDisabled />
      </AsyncData.Error>
    </AsyncData>
  );
}
