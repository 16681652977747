import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  ItineraryBusSegment,
  ItineraryBusSegmentSkeleton,
} from "~/apps/corporate/components/itinerary-bus-segment/itinerary-bus-segment";
import {
  BusTripDetailsContentSeat,
  TripConnection,
  TripPartSegment,
} from "~/apps/corporate/dtos/bus.dto";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Error } from "~/apps/shared/types";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Button, CircularSpinner } from "@toolkit/v2";

import { BusCardRefreshSeatsWarning } from "./bus-card-refresh-seats-warning/bus-card-refresh-seats-warning";
import { BusSeatSelection } from "./bus-seat-selection/bus-seat-selection";
import { styles } from "./styles";

type Props = {
  areAllSeatsSelected: boolean;
  arrival: TripPartSegment;
  departure: TripPartSegment;
  handleToggleCategoryExplanation: () => void;
  hasAvailability: boolean;
  isLoading: boolean;
  isOpen: boolean;
  loadingError?: Error;
  onProgressBooking: () => void;
  onRefreshSeats: () => Promise<void>;
  onSeatSelect: (
    busId: number,
    tripPartId: string,
  ) => (selectedSeats: BusTripDetailsContentSeat[]) => void;
  selectBus: () => Promise<void>;
  trip: TripConnection;
  tripParts: {
    id: string;
    seats: BusTripDetailsContentSeat[];
    selectedSeats: BusTripDetailsContentSeat[];
  }[];
  wereSeatsLoaded?: string;
};

export const BusCard: React.FC<Props> = ({
  areAllSeatsSelected,
  arrival,
  departure,
  handleToggleCategoryExplanation,
  hasAvailability,
  isLoading,
  isOpen,
  loadingError,
  onProgressBooking,
  onRefreshSeats,
  onSeatSelect,
  selectBus,
  trip,
  tripParts,
  wereSeatsLoaded,
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <div css={styles.top.left.root}>
          <ItineraryBusSegment
            arrival={{
              address: arrival.place.name,
              time: arrival.schedule.time,
            }}
            busName={trip.parts[0].bus.name}
            carrierLogo={trip.parts[0].travelCompany.logo}
            carrierName={trip.parts[0].travelCompany.name}
            departure={{
              address: departure.place.name,
              time: departure.schedule.time,
            }}
            duration={trip.duration}
          />
        </div>
        <div css={styles.top.right.root}>
          <span css={styles.top.right.price}>{toCurrency(trip.price)}</span>
          <Button
            css={styles.top.right.button}
            disabled={!hasAvailability || isLoading}
            onClick={async (e) => {
              e.stopPropagation();

              await selectBus();
            }}
            variant="pink"
          >
            {!hasAvailability
              ? "Esgotado"
              : isLoading
              ? "Carregando"
              : isOpen
              ? "Cancelar"
              : "Selecionar"}
          </Button>
        </div>
      </div>
      {isOpen ? (
        <div css={styles.bottom.root}>
          {isLoading ? <CircularSpinner /> : null}
          {!isLoading && !!loadingError ? (
            <BusCardRefreshSeatsWarning
              error={loadingError}
              onRefreshSeats={onRefreshSeats}
            />
          ) : null}
          {!isLoading && !loadingError && wereSeatsLoaded ? (
            <>
              <div css={styles.bottom.selection.root}>
                <div css={styles.bottom.selection.top.root}>
                  <Icon size={16} use="info-outline" />
                  <span>
                    Atenção, a classe dos assentos abaixo é{" "}
                    <strong>{trip.parts[0].bus.name}. </strong>
                  </span>
                  <button
                    css={styles.bottom.selection.top.classes}
                    onClick={handleToggleCategoryExplanation}
                  >
                    Entenda as classes
                  </button>
                </div>
                <BusSeatSelection
                  areAllSeatsSelected={areAllSeatsSelected}
                  onProgressBooking={onProgressBooking}
                  onSeatSelect={onSeatSelect}
                  trip={trip}
                  tripParts={tripParts}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const BusCardSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <div css={styles.top.left.root}>
          <ItineraryBusSegmentSkeleton />
        </div>
        <div css={styles.top.right.root}>
          <Skeleton height="24px" width="96px" />
          <Button css={styles.top.right.button} disabled variant="pink">
            Selecionar
          </Button>
        </div>
      </div>
    </div>
  );
};
