import React, { useCallback } from "react";

import { NotEnoughTravelersForm } from "~/apps/corporate/components/not-enough-travelers-form/not-enough-travelers-form";
import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";

import { useItineraryPendencies } from "../../../../itinerary-pendencies.context";
import { useItineraryContainer } from "../../../../itinerary.container";
import { PendenciesAccordion } from "../../pendencies-accordion/pendencies-accordion";

type Props = {
  index: number;
  isExpanded: boolean;
  isLoadingNext: boolean;
  next: () => void;
  toggleExpanded: () => void;
};

export const NotEnoughTravelersAccordion: React.FC<Props> = ({
  index,
  isExpanded,
  isLoadingNext,
  next,
  toggleExpanded,
}) => {
  const { servicesModel } = useItineraryContainer();

  const {
    offersTravelers,
    pendencies,
    saveNotEnoughTravelersAndProceed,
  } = useItineraryPendencies();

  const handleSubmit = useCallback(
    async (
      data: Record<
        string,
        {
          main: number;
          userToken: string;
        }[]
      >,
    ) => {
      if (!servicesModel) {
        return;
      }

      const success = await saveNotEnoughTravelersAndProceed(
        data,
        servicesModel,
      );

      if (!success) {
        return;
      }

      next();
    },
    [next, servicesModel, saveNotEnoughTravelersAndProceed],
  );

  const isResolved = pendencies
    ? pendencies.isNotEnoughTravelersResolved
    : false;

  if (!offersTravelers || !pendencies || !servicesModel) {
    return null;
  }

  const processableServicesWithPotentialMultipleTravelers = servicesModel.getProcessableServicesWithPotentialMultipleTravelers();

  return (
    <PendenciesAccordion
      description="Especifique os hóspedes dos seguintes hotéis:"
      isExpanded={isExpanded}
      isResolved={isResolved}
      title={`${index}. Viajantes pendentes.`}
      toggleExpanded={toggleExpanded}
    >
      <NotEnoughTravelersForm
        disabled={isLoadingNext}
        offersTravelers={offersTravelers}
        onSubmit={async ({ offersTravelers }) => {
          await handleSubmit(offersTravelers);
        }}
        services={processableServicesWithPotentialMultipleTravelers.map(
          (serviceModel) =>
            ItineraryServicePresenterFactory.create(serviceModel),
        )}
      />
    </PendenciesAccordion>
  );
};
