import React, { useContext } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Flex } from "smartrips-toolkit";

import { Button } from "@toolkit/v2";

import { CostCenterListItem } from "@models/cost-center.model";

import { Column } from "@components/shared/layout/Column";

import { CostCentersContext } from "../CostCenters.context";
import { styles } from "./styles";

interface ItemProps {
  data: CostCenterListItem;
  handleEdit: () => void;
  handleArchive: () => void;
}

const CostCenterItem = ({ data, handleEdit, handleArchive }: ItemProps) => (
  <li css={styles.list.item.root} onClick={handleEdit}>
    <div css={styles.list.item.left.root}>
      <span css={styles.list.item.left.name}>{data.name}</span>
    </div>
    <div css={styles.list.item.right.root}>
      <div css={styles.list.item.right.count}>
        {data.userCount}
        <Icon size={16} use="people" />
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();

          handleEdit();
        }}
        shape="icon"
      >
        <Icon size={20} use="pencil" />
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();

          handleArchive();
        }}
        shape="icon"
        variant="pink"
      >
        <Icon size={20} use="trash" />
      </Button>
    </div>
  </li>
);

export const CostCentersList = () => {
  const {
    costCenters,
    visibleCostCenters,
    handleOpenForm,
    handleArchiveCostCenter,
  } = useContext(CostCentersContext);

  function compareByLowerCaseName(
    itemA: CostCenterListItem,
    itemB: CostCenterListItem,
  ) {
    if (itemA.name.toLowerCase() < itemB.name.toLowerCase()) {
      return -1;
    }
    if (itemA.name.toLowerCase() > itemB.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  return (
    <div style={{ width: "100%" }}>
      <Column>
        {!!costCenters.length && !visibleCostCenters.length ? (
          <span css={styles.noItemFound}>
            Nenhum centro de custos encontrado
          </span>
        ) : null}
        {!costCenters.length ? (
          <span css={styles.noItemFound}>
            Nenhum centro de custos ativo cadastrado
          </span>
        ) : null}
        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          {visibleCostCenters
            .slice()
            .sort(compareByLowerCaseName)
            .map((costCenter: CostCenterListItem) => (
              <CostCenterItem
                key={costCenter.token}
                data={costCenter}
                handleEdit={handleOpenForm(costCenter.token)}
                handleArchive={handleArchiveCostCenter(costCenter)}
              />
            ))}
        </Flex>
      </Column>
    </div>
  );
};
