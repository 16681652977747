import React from "react";

import { Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";

import { ExpensePolicy } from "@components/configuration/expenses/expense-policy/ExpensePolicy";

import { AdvanceApprovalProcess } from "./advance-approval-process/advance-approval-process";
import { ExpensesCategories } from "./expenses-categories/expenses-categories";
import { ExpensesProcess } from "./expenses-process";

const Outlet: React.FC = () => {
  return (
    <Router primary={false}>
      <ExpensesProcess path="/" />
      <ExpensesCategories path="/expenses-categories" />
      <PermissionProtectedRoute
        component={ExpensePolicy}
        path="/expense-policy"
        permissions={["expenses.policies"]}
      />
      <PermissionProtectedRoute
        component={AdvanceApprovalProcess}
        path="/advance-approval-process"
        permissions={["expenses.advances"]}
      />
    </Router>
  );
};

export const ExpensesConfigurations: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
