import React from "react";

import { USER_ROLES } from "~/apps/shared/constants";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

const USER_ROLES_TRANSLATION: Record<
  string,
  {
    label: string;
    variant: React.ComponentProps<typeof Badge>["variant"];
  }
> = {
  [USER_ROLES.admin]: {
    variant: "pink",
    label: "Administrador",
  },
  [USER_ROLES.manager]: {
    label: "Gestor",
    variant: "blue",
  },
  [USER_ROLES.super]: {
    label: "Administrador",
    variant: "pink",
  },
};

type Props = {
  role: string;
};

export const UserListItemRoleBadge: React.FC<Props> = ({ role }) => {
  if (!role) {
    return null;
  }

  if (!(role in USER_ROLES_TRANSLATION)) {
    return null;
  }

  const { label, variant } = USER_ROLES_TRANSLATION[role];

  return (
    <Badge css={styles.root} variant={variant}>
      {label}
    </Badge>
  );
};
