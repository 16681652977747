import * as approvalProcessApi from "~/apps/corporate/apis/approval-process.api";
import * as clientApi from "~/apps/corporate/apis/client.api";
import * as travelApi from "~/apps/corporate/apis/travel.api";
import { SendApprovalResponseRequestBody } from "~/apps/corporate/dtos/approval-status.dto";
import { ClientInfo } from "~/apps/corporate/models/client.model";
import {
  TravelFlightCredits,
  TravelPayment,
} from "~/apps/corporate/models/travel.model";
import {
  BookTravelDto,
  parseBookTravelRequestBody,
} from "~/apps/corporate/parsers/travel.parser";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { v4 as uuid } from "uuid";

export const book = async (data: BookTravelDto, travelToken: string) => {
  const result: { error?: Error } = {};

  try {
    const bookTravelRequestBody = parseBookTravelRequestBody(data);

    await travelApi.bookTravel(bookTravelRequestBody, travelToken, uuid());
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400 && !!data.message) {
        result.error = {
          description: data.message,
          title: "Erro ao processar o pagamento",
        };
      } else {
        result.error = ERROR.UNEXPECTED;
      }
    } else {
      result.error = ERROR.UNEXPECTED;
    }
  }

  return result;
};

export const checkPaymentPermission = async (
  travelToken: string,
  userToken: string,
): Promise<
  Either<
    Error,
    {
      hasPermission: boolean;
    }
  >
> => {
  try {
    return success(
      await travelApi.checkPaymentPermission(travelToken, userToken),
    );
  } catch (error) {
    return failure(ERROR.UNEXPECTED_CHECK_PERMISSION_TO_PAY_ERROR);
  }
};

export const getClientInfo = async (): Promise<Either<Error, ClientInfo>> => {
  try {
    return success(await clientApi.getClientInfo());
  } catch (error) {
    return failure(ERROR.UNEXPECTED_GET_CLIENT_INFO);
  }
};

export const getTravelFlightCredits = async (
  travelToken: string,
): Promise<Either<Error, TravelFlightCredits>> => {
  try {
    return success(await travelApi.getTravelFlightCredits(travelToken));
  } catch (error) {
    return failure(ERROR.UNEXPECTED_GET_TRAVEL_PAYMENT);
  }
};

export const getTravelPayment = async (
  travelToken: string,
): Promise<Either<Error, TravelPayment>> => {
  try {
    return success(await travelApi.getTravelPayment(travelToken));
  } catch (error) {
    return failure(ERROR.UNEXPECTED_GET_TRAVEL_PAYMENT);
  }
};

export const sendApprovalResponse = async ({
  approvalRequestToken,
  responseMessage,
  shouldApprove,
}: {
  approvalRequestToken: string;
  responseMessage?: string;
  shouldApprove: boolean;
}) => {
  const result: { error?: Error } = {};

  try {
    const sendApprovalResponseRequestBody: SendApprovalResponseRequestBody = {
      response_message: responseMessage,
    };

    shouldApprove
      ? await approvalProcessApi.approveApprovalRequest(
          approvalRequestToken,
          sendApprovalResponseRequestBody,
        )
      : await approvalProcessApi.denyApprovalRequest(
          approvalRequestToken,
          sendApprovalResponseRequestBody,
        );
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        result.error = ERROR.NO_PERMISSION_APPROVAL_RESPONSE_ERROR;

        return result;
      }

      result.error = ERROR.UNEXPECTED_APPROVAL_RESPONSE_ERROR;

      return result;
    }

    if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;

      return result;
    }

    result.error = ERROR.UNEXPECTED_APPROVAL_RESPONSE_ERROR;
  }

  return result;
};
