import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  loading: css({
    color: theme.colors.white,
  }),
  root: css({
    height: "48px",
  }),
  success: css({
    marginRight: "0.4rem",
  }),
};
