import { css } from "@styled-system/css";
import { css as ecss } from "emotion";

import { theme } from "@skin/v2";

export const styles = {
  breadcrumb: {
    link: ({ active, disabled }: { active?: boolean; disabled?: boolean }) =>
      ecss({
        color: active ? theme.colors.gray[500] : theme.colors.gray[100],
        flexShrink: 0,
        fontSize: "0.875rem",
        fontWeight: disabled ? 400 : 500,
        textDecoration: active ? "underline" : "none",
        transition: "color 0.15s ease-in-out",
        "&:hover": {
          color: active
            ? theme.colors.gray[700]
            : disabled
            ? theme.colors.gray[300]
            : theme.colors.gray[700],
          textDecoration: disabled ? "unset" : "underline",
        },
      }),
    root: css({
      alignItems: "center",
      display: "flex",
      flexShrink: 0,
      gap: "12px",
    }),
  },
  root: css({
    alignItems: "center",
    color: theme.colors.gray[300],
    display: "flex",
    gap: "12px",
  }),
};
