import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    download: {
      button: {
        spinner: css({
          color: "white",
        }),
      },
    },
    guest: {
      buttons: {
        button: css({
          width: "fit-content",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
        }),
      },
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
      }),
    },
    root: css({
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
      justifyContent: "flex-start",
    }),
    user: {
      autocomplete: {
        item: {
          email: css({
            color: theme.colors.gray[300],
            fontSize: "0.75rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          root: ({
            isDisabled,
            isHighlighted,
          }: {
            isDisabled?: boolean;
            isHighlighted: boolean;
          }) =>
            css({
              backgroundColor: isHighlighted
                ? theme.colors.background.gray
                : "transparent",
              color: theme.colors.gray[500],
              cursor: isDisabled ? "default" : "pointer",
              display: "flex",
              flexDirection: "column",
              fontSize: "0.875rem",
              gap: "4px",
              padding: "12px 16px",
            }),
        },
      },
      buttons: {
        button: css({
          width: "fit-content",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
        }),
      },
      input: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        padding: "13px 16px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
      }),
    },
  },
  message: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
  }),
  root: css({
    ".MuiPaper-root": {
      overflow: "visible",
      "@media (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
};
