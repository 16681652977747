import { GetUserCompanyAreasResponse } from "../dtos/areas.dto";
import { UserArea } from "../models/area.model";
import * as userParser from "./user.parser";

export const AreaFactory = (
  dto: Partial<
    Record<
      | "active"
      | "area_type"
      | "client_token"
      | "company_area_token"
      | "name"
      | "parent_token"
      | "subAreas"
      | "users",
      any
    >
  >,
) => {
  const {
    active,
    area_type,
    client_token,
    company_area_token,
    name,
    parent_token,
    subAreas,
    users,
  } = dto;

  const result: Record<
    | "active"
    | "areaType"
    | "clientToken"
    | "companyAreaToken"
    | "label"
    | "name"
    | "parentToken"
    | "subAreasCount"
    | "usersCount",
    any
  > = {
    active: active,
    areaType: area_type,
    clientToken: client_token,
    companyAreaToken: company_area_token,
    label: name,
    name: name,
    parentToken: parent_token,
    subAreasCount: subAreas ? subAreas.length : 0,
    usersCount: users ? users.length : 0,
  };

  return result;
};

export const AreasFactory = (
  dtos: Partial<
    Record<
      | "active"
      | "area_type"
      | "client_token"
      | "company_area_token"
      | "name"
      | "parent_token"
      | "subAreas"
      | "users",
      any
    >
  >[],
) => {
  return dtos.map((dto) => AreaFactory(dto));
};

export const DetailedAreaFactory = (
  areaDto: Partial<
    Record<"company_area_token" | "name" | "subAreas" | "users", any>
  >,
) => {
  const { company_area_token, name, subAreas, users } = areaDto;

  const result: Record<
    "companyAreaToken" | "name" | "subAreas" | "users",
    any
  > = {
    companyAreaToken: company_area_token,
    name: name,
    subAreas: AreasFactory(subAreas),
    users: userParser.UsersArea(users),
  };

  return result;
};

export const parseUserCompanyAreas = (dto: GetUserCompanyAreasResponse) => {
  return dto.map((userCompanyAreaDto) => {
    const userArea: UserArea = {
      active: userCompanyAreaDto.active === 1,
      areaType: userCompanyAreaDto.area_type,
      clientToken: userCompanyAreaDto.client_token,
      companyAreaToken: userCompanyAreaDto.company_area_token,
      createdAt: userCompanyAreaDto.created_at,
      name: userCompanyAreaDto.name,
      parentToken: userCompanyAreaDto.parent_token,
      updatedAt: userCompanyAreaDto.updated_at,
    };

    return userArea;
  });
};
