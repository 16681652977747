import React, { useMemo } from "react";

import { useUserProfileDrawer } from "../../user-profile-drawer.context";
import { CreatePaymentMethod } from "./create-payment-method/create-payment-method";
import { EditPaymentMethod } from "./edit-payment-method/edit-payment-method";

type Props = {
  back: () => void;
};

export const UserProfileDrawerSavePaymentMethod: React.FC<Props> = ({
  back,
}) => {
  const { selectedPaymentMethod } = useUserProfileDrawer();

  const isEditing = useMemo(() => !!selectedPaymentMethod, [
    selectedPaymentMethod,
  ]);

  if (isEditing && selectedPaymentMethod) {
    return <EditPaymentMethod back={back} card={selectedPaymentMethod} />;
  }

  return <CreatePaymentMethod back={back} />;
};
