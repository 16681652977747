import React from "react";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  color?: string;
  showName?: boolean;
  size?: number;
};

export const Logo: React.FC<Props> = ({
  color = "#1A1A1A",
  showName = false,
  size = 32,
  ...props
}) => {
  return (
    <div css={styles.root} {...props}>
      <span css={styles.svg({ color, size })}>
        <LogoSVG />
      </span>
      {showName ? <span css={styles.text}>smartrips</span> : null}
    </div>
  );
};

const LogoSVG: React.FC = () => {
  return (
    <svg
      fill="none"
      height="40"
      role="img"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="currentColor"
      />
      <path
        d="M19.4502 6.46606L6.41296 27.9666H15.1714L20.2319 33.5304L24.898 27.9666H33.5862L19.4502 6.46606ZM20.1012 32.0082L16.4396 27.9206C17.1027 23.8184 17.7489 19.6752 18.3733 15.4932C18.7484 12.9932 19.1114 10.5053 19.4623 8.02947C20.8297 14.6606 22.1947 21.2918 23.562 27.923C22.4076 29.2855 21.2532 30.6481 20.1012 32.0106V32.0082Z"
        fill="white"
      />
    </svg>
  );
};
