import React from "react";
import Select, { Props } from "react-select";

import { defaultTheme } from "~/assets/styles/theme";

export interface IStyledReactSelectProps extends Props {
  optionsToBuild: Record<string, string>;
  error: boolean;
}

export const StyledReactSelect = (props: IStyledReactSelectProps) => {
  const buildOptions = (optionsToBuild: Record<string, string>) => {
    return Object.entries(optionsToBuild).map(([key, value]) => ({
      value: key,
      label: value
    }));
  };

  return (
    <Select
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          height: 45,
          cursor: "pointer",
          border: props?.error
            ? `2px solid ${defaultTheme.secondaryColor}`
            : "",
          "&:hover": {
            border: props?.error
              ? `2px solid ${defaultTheme.secondaryColor}`
              : ""
          },
          "&:focus-within": {
            border: props?.error
              ? `2px solid ${defaultTheme.secondaryColor}`
              : `2px solid ${defaultTheme.primaryColor}`
          },
          boxShadow: "none"
        }),
        option: baseStyles => ({
          ...baseStyles,
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          padding: ".75rem 1rem"
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          display: "flex",
          justifyContent: "space-between",
          overflow: "visible"
        }),
        menuList: baseStyles => ({
          ...baseStyles,
          maxHeight: "300px !important"
        })
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: defaultTheme.primaryColor
        }
      })}
      options={buildOptions(props.optionsToBuild)}
      {...props}
    />
  );
};
