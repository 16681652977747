import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  body: {
    root: css({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "24px",
      padding: "24px 24px 48px 24px",
      width: "100%",
      "@media (max-width: 640px)": {
        padding: "16px 16px 32px 16px",
      },
    }),
  },
  dialog: css({
    ["& .MuiDrawer-paper"]: {
      maxWidth: "512px",
      width: "100%",
    },
  }),
  header: {
    right: {
      button: css({
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[300],
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.fonts.inter,
        fontSize: "0.875rem",
        gap: "12px",
        transition: "color 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.gray[700],
        },
        "& > :first-of-type": {
          "@media (max-width: 425px)": {
            display: "none",
          },
        },
      }),
      root: css({}),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "24px",
      justifyContent: "space-between",
      padding: "24px",
      "@media (max-width: 640px)": {
        padding: "16px",
      },
    }),
    title: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  }),
};
