import React from "react";

import Drawer from "@material-ui/core/Drawer";
import { css } from "@styled-system/css";

import { ProjectsContext } from "../projects.context";
import { ProjectsForm } from "./ProjectsForm";

const styles = {
  drawer: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: [500, 815],
    },
  }),
};

const ProjectsFormDrawer = () => {
  const {
    isSubmitting,
    isFormOpen,
    selectedToEdition,
    saveProject,
    closeForm,
  } = React.useContext(ProjectsContext);

  return (
    <Drawer
      open={isFormOpen}
      anchor="right"
      onClose={closeForm}
      css={styles.drawer}
    >
      <ProjectsForm
        submitting={isSubmitting}
        selectedProject={selectedToEdition}
        onSubmit={saveProject}
        handleClose={closeForm}
      />
    </Drawer>
  );
};

export { ProjectsFormDrawer as ProjectsForm };
