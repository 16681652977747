import { CountryRestrictionDto } from "~/apps/corporate/dtos/country-restriction.dto";
import { CountryRestrictionType } from "~/apps/shared/constants/enums";
import { FormModeType } from "~/apps/shared/types";

import { CountryRestrictionFormData } from "./restriction-form.types";

export const parseDefaultValues = ({
  ...props
}:
  | {
      mode: Extract<FormModeType, "create">;
    }
  | {
      data: CountryRestrictionDto;
      mode: Extract<FormModeType, "edit">;
    }): CountryRestrictionFormData => {
  if (props.mode === "create") {
    return {
      country: { label: "", value: "" },
      countryRestrictionToken: "",
      message: [
        {
          children: [{ text: "" }],
        },
      ],
      restrictionType: CountryRestrictionType.BLOCK_ALL,
    };
  }

  const { data } = props;

  return {
    country: { label: data.country_name, value: data.country_iso_code },
    countryRestrictionToken: data.country_restriction_token,
    message: data.message_content ? JSON.parse(data.message_content) : "",
    restrictionType: data.restriction_type,
  };
};
