import React from "react";

import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { CSSProperties } from "@material-ui/styles";
import { css } from "@styled-system/css";

import { colors, defaultTheme } from "~/assets/styles/theme";

export enum AltertTypeEnum {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR"
}

const defaultStyles = {
  container: {
    display: "flex",
    padding: "1.3rem 1rem",
    border: "2px solid",
    borderRadius: 4,
    color: colors.black,
    marginBottom: "1rem"
  },
  icon: {
    width: "2rem",
    height: "2rem",
    marginRight: "1rem"
  }
};

const styles = {
  infoContainer: css({
    ...defaultStyles.container,
    backgroundColor: defaultTheme.messages.info.backgroundColor,
    borderColor: defaultTheme.messages.info.borderColor
  }),
  warningContainer: {
    ...defaultStyles.container,
    backgroundColor: defaultTheme.messages.warning.backgroundColor,
    borderColor: defaultTheme.messages.warning.borderColor
  },
  errorContainer: {
    ...defaultStyles.container,
    backgroundColor: defaultTheme.messages.error.backgroundColor,
    borderColor: defaultTheme.messages.error.borderColor
  },
  infoIcon: {
    ...defaultStyles.icon,
    color: defaultTheme.messages.info.borderColor
  },
  warningIcon: {
    ...defaultStyles.icon,
    color: defaultTheme.messages.warning.borderColor
  },
  errorIcon: css({
    ...defaultStyles.icon,
    color: defaultTheme.messages.error.borderColor
  })
};

const selectStyle = {
  [AltertTypeEnum.INFO]: {
    containerStyle: styles.infoContainer,
    iconStyle: styles.infoIcon
  },
  [AltertTypeEnum.WARNING]: {
    containerStyle: styles.warningContainer,
    iconStyle: styles.warningIcon
  },
  [AltertTypeEnum.ERROR]: {
    containerStyle: styles.errorContainer,
    iconStyle: styles.errorIcon
  }
};

interface IAltertBoxProps {
  type: AltertTypeEnum;
  text: string | React.ReactNode;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  iconStyle?: CSSProperties; // Material UI css properties
}

export const AltertBox: React.FC<IAltertBoxProps> = props => {
  const { containerStyle, iconStyle } = selectStyle[props.type];

  return (
    <div css={containerStyle} style={props.containerStyle}>
      {(() => {
        switch (props.type) {
          case AltertTypeEnum.INFO:
            return <InfoIcon css={iconStyle} style={props.iconStyle} />;
          case AltertTypeEnum.WARNING:
            return <WarningIcon css={iconStyle} style={props.iconStyle} />;
          case AltertTypeEnum.ERROR:
            return <ErrorIcon css={iconStyle} style={props.iconStyle} />;
          default:
            return null;
        }
      })()}
      <p
        style={{
          fontSize: "0.85rem",
          lineHeight: "1.05rem",
          ...props.textStyle
        }}
      >
        {props.text}
      </p>
    </div>
  );
};
