import React, { useMemo } from "react";
import * as utils from "@helpers";
import { useFlightInfoContext } from "../FlightInfo.context";
import { Paper } from "../../shared/Paper";
import { PaperTitle } from "../../shared/PaperTitle";
import { Flex, Box } from "@toolkit";
import { SpentByRouteTable } from "./SpentByRouteTable";
import { SpentByRouteChart } from "./SpentByRouteChart";
import orderBy from "lodash/orderBy";

const SpentByRoute = () => {
  const { spentByDestination } = useFlightInfoContext();
  const sortedData = useMemo(
    () => orderBy(spentByDestination, "total", "desc"),
    [spentByDestination]
  );
  const tableColumns = useMemo(
    () => [
      {
        Header: "Origem",
        accessor: "origin"
      },
      {
        Header: "Destino",
        accessor: "destination"
      },
      {
        Header: "Qtd.",
        accessor: "count"
      },
      {
        Header: "Total gasto",
        accessor: "total",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Gasto médio",
        accessor: "average",
        Cell: props => utils.toCurrency(props.value)
      }
    ],
    []
  );

  return (
    <Paper minHeight={250}>
      <PaperTitle>Gasto por rota</PaperTitle>
      <Flex
        flexDirection={["column-reverse", "column-reverse", "row"]}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box width={["100%", "100%", "66%"]} minHeight={[0, 0, 220]}>
          <SpentByRouteTable data={sortedData} columns={tableColumns} />
        </Box>
        <Box width={["100%", "100%", "33%"]} mb={[24, 24, 0]}>
          <SpentByRouteChart data={sortedData} />
        </Box>
      </Flex>
    </Paper>
  );
};

export { SpentByRoute };
