import {
  GetItineraryDocumentPendenciesResponse,
  GetItineraryInfoResponse,
  GetItineraryPendenciesResponse,
  GetItineraryServiceInfoResponse,
  GetItineraryServicesResponse,
} from "../dtos/itinerary.dto";
import { ItineraryInfo } from "../models/itinerary/itinerary-info.model";
import {
  ItineraryDocumentPendencies,
  ItineraryPendencies,
} from "../models/itinerary/itinerary-pendencies.model";
import { ItineraryServiceInfo } from "../models/itinerary/itinerary-service-info.model";
import { ItineraryServices } from "../models/itinerary/itinerary-services.model";

export const parseItineraryInfo = (dto: GetItineraryInfoResponse) => {
  const itineraryInfo: ItineraryInfo = {
    ...dto,
  };

  return itineraryInfo;
};

export const parseItineraryDocumentPendencies = (
  dto: GetItineraryDocumentPendenciesResponse,
) => {
  const itineraryDocumentPendencies: ItineraryDocumentPendencies = {
    ...dto,
  };

  return itineraryDocumentPendencies;
};

export const parseItineraryPendencies = (
  dto: GetItineraryPendenciesResponse,
) => {
  const itineraryPendencies: ItineraryPendencies = {
    ...dto,
  };

  return itineraryPendencies;
};

export const parseItineraryServiceInfo = (
  dto: GetItineraryServiceInfoResponse,
) => {
  const itineraryServiceInfo: ItineraryServiceInfo = {
    ...dto,
  };

  return itineraryServiceInfo;
};

export const parseItineraryServices = (dto: GetItineraryServicesResponse) => {
  const { bus = [], car = [], flight = [], hotel = [] } = dto;

  const itineraryServices: ItineraryServices = [
    ...bus,
    ...car,
    ...flight,
    ...hotel,
  ];

  return itineraryServices;
};
