import React from "react";
import { Controller } from "react-hook-form";

import moment, { Moment } from "moment";

import { useFormContext } from "~/components/shared/form";

import { DateInput } from "../../shared/DateInput/DateInput";

type ErrorMessage = string | undefined;

const AdvancedExpenseDateRangeInput: React.FC = () => {
  const { control, errors, setValue, clearErrors } = useFormContext();
  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);

  const handleChangeStartDate = (day: Moment | null): void => {
    if (errors.startDate) {
      clearErrors("startDate");
    }
    if (endDate && endDate!.isBefore(day!)) {
      const newEndDate = day!.clone().add(1, "day");
      setValue("endDate", newEndDate);
      setEndDate(newEndDate);
    }
    setValue("startDate", day);
    setStartDate(day);
  };

  const handleChangeEndDate = (day: Moment | null): void => {
    if (errors.endDate) {
      clearErrors("endDate");
    }
    setValue("endDate", day);
    setEndDate(day);
  };

  const isStartDateOutsideRange = (day: Moment) => day < moment();
  const isEndDateOutsideRange = (day: Moment) => day < startDate!;

  return (
    <>
      <Controller
        name="startDate"
        id="startDate"
        as={DateInput}
        date={startDate}
        control={control}
        placeholder="Ida"
        handleDateChange={handleChangeStartDate}
        error={Boolean(errors.startDate)}
        errorMessage={
          errors.startDate && (errors.startDate.message as ErrorMessage)
        }
        isOutsideRange={isStartDateOutsideRange}
        rootStyle={{ marginRight: "1rem" }}
      />
      <Controller
        name="endDate"
        id="endDate"
        as={DateInput}
        date={endDate}
        control={control}
        placeholder="Volta"
        handleDateChange={handleChangeEndDate}
        error={Boolean(errors.endDate)}
        errorMessage={
          errors.endDate && (errors.endDate.message as ErrorMessage)
        }
        isOutsideRange={isEndDateOutsideRange}
        disabled={!startDate}
      />
    </>
  );
};

export { AdvancedExpenseDateRangeInput };
