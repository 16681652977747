import React from "react";

import { Link } from "@reach/router";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { useWindowSize } from "../../hooks/use-window-size";
import { Icon } from "../icon/icon";
import { styles } from "./styles";

type Props = {
  routes: {
    name: string;
    url: string;
  }[];
};

export const Breadcrumbs: React.FC<Props> = ({ routes }) => {
  const { width: windowWidth } = useWindowSize();
  const { location } = useLocation();

  const activeRouteIndex = routes.findIndex((route) => {
    const isCurrent = location.pathname === route.url;
    const isPartiallyCurrent = location.pathname.startsWith(route.url);

    return isCurrent || isPartiallyCurrent;
  });

  return (
    <div css={styles.root}>
      {routes[activeRouteIndex] && windowWidth <= 640 ? (
        <span className={styles.breadcrumb.link({ disabled: true })}>
          {routes[activeRouteIndex].name}
        </span>
      ) : (
        routes.map((route, index) => {
          const isNavigable = index <= activeRouteIndex;

          return (
            <div css={styles.breadcrumb.root} key={index}>
              {index > 0 ? <Icon size={12} use="chevron-right" /> : null}
              {!isNavigable ? (
                <span className={styles.breadcrumb.link({ disabled: true })}>
                  {route.name}
                </span>
              ) : (
                <Link
                  getProps={({ isCurrent, isPartiallyCurrent }) => ({
                    className: styles.breadcrumb.link({
                      active: isCurrent || isPartiallyCurrent,
                    }),
                  })}
                  to={route.url}
                >
                  {route.name}
                </Link>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};
