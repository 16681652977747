import React, { useCallback } from "react";

import { HotelFavorite } from "~/apps/corporate/models/hotel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { FAVORITE_TYPES } from "~/apps/shared/constants";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  hotel: {
    favorites: HotelFavorite[];
  };
  openFavoriteDialog?: (hotel: any) => void;
};

export const HotelFavoriteButton: React.FC<Props> = ({
  hotel,
  openFavoriteDialog,
  ...props
}) => {
  const companyFavorite = hotel.favorites.find(
    (favorite) => favorite.type === FAVORITE_TYPES.COMPANY,
  );

  const handleOpenFavoriteDialog = useCallback(() => {
    if (!openFavoriteDialog) {
      return;
    }

    openFavoriteDialog(hotel);
  }, [hotel, openFavoriteDialog]);

  const isChecked = hotel.favorites.length > 0;

  const userFavorite = hotel.favorites.find(
    (favorite) => favorite.type === FAVORITE_TYPES.USER,
  );

  return (
    <button
      css={styles.root({ isChecked })}
      onClick={() => {
        handleOpenFavoriteDialog();
      }}
      {...props}
    >
      <Icon size={16} use={isChecked ? "heart" : "heart-outline"} />
      {!!companyFavorite && !!userFavorite
        ? "Favorito"
        : companyFavorite
        ? "Empresa"
        : userFavorite
        ? "Pessoal"
        : "Favoritar"}
    </button>
  );
};
