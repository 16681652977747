export * from "./constants";
import { colors } from "./constants";

const defaultTheme = {
  applicationBackground: colors.white,
  primaryColor: colors.mediumBlue,
  hoverPrimaryColor: colors.darkBlue,
  secondaryColor: colors.punch,
  tertiaryColor: colors.darkBlue,
  textColor: colors.ebony,
  subTextColor: colors.slateContrastGray,
  successTextColor: colors.jungleGreen,
  warningTextColor: colors.saffron,
  borderColor: colors.silver,
  hoveredBorderColor: colors.doveGray,
  backgroundColor: colors.concrete,
  cardBorderColor: colors.botticelli,
  cardSecondaryBackgroundColor: colors.linkWater,
  grays: {
    dark: colors.darkGray,
    light: colors.lightGray,
    veryLight: colors.veryLightGray
  },
  reds: {
    lightRed: colors.sunglo
  },
  colorByStatus: {
    pending: colors.burntSienna,
    approved: colors.fireBush,
    pedendingFinalApproval: colors.fireBush,
    processing_error: colors.redError,
    processing: colors.fireBush,
    canceled: "#b24531",
    processing_cancelation: "#cd5e49"
  },
  errorTextColor: colors.redError,
  messages: {
    warning: {
      color: colors.black,
      backgroundColor: "#ffe3c7",
      borderColor: "#fda101"
    },
    error: {
      color: colors.black,
      backgroundColor: "#ffe3e0",
      borderColor: "#f73625"
    },
    info: {
      color: colors.black,
      backgroundColor: "#c3ecff",
      borderColor: "#0076fe"
    }
  }
};

export { defaultTheme };
