import * as yup from "yup";
import { AnyObject } from "yup/lib/types";

import { ApprovalProcessTypes } from "~/constants/enums";

import {
  APPROVAL_TYPES,
  APPROVAL_PROCESS_TYPES,
  APPROVAL_PROCESS_TYPES_LABELS,
} from "@constants";

import { Option } from "~/types";

import { SelectOption } from "~/schemas/common-fields.schema";

function validateTerritoryAndPolicy(value: { value?: string; label?: string }) {
  return !!value && Boolean(value?.value);
}

function validateRules(value: { type: { value?: string; label?: string } }[]) {
  return value?.some(
    (rule) =>
      rule.type.label ===
        APPROVAL_PROCESS_TYPES_LABELS[APPROVAL_PROCESS_TYPES.APPROVE] &&
      rule.type.value === APPROVAL_PROCESS_TYPES.APPROVE,
  );
}

const approvalProcessSchema = yup.object().shape({
  token: yup.string(),
  name: yup.string().required("Digite um nome para o processo de aprovação"),
  type: SelectOption(),
  approvalNeeded: yup.boolean().required(),
  targets: yup
    .array()
    .of(
      yup.object().shape({
        token: yup.string(),
        name: yup.string(),
        type: yup.string(),
        label: yup.string(),
      }),
    )
    .typeError("Selecione ao menos uma entidade para o processo de aprovação")
    .when("type", {
      is: (type: Option<ApprovalProcessTypes>) => {
        if (type?.value === APPROVAL_TYPES.COMPANY) return true;
        return false;
      },
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .min(
            1,
            "Selecione ao menos uma entidade para o processo de aprovação",
          )
          .required(
            "Selecione ao menos uma entidade para o processo de aprovação",
          ),
    }),
  rules: yup
    .array()
    .of(
      yup.object().shape({
        type: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .required(),
        territory: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .required("Selecione a abrangência")
          .test(
            "validateTerritoryAndPolicy",
            "Selecione a abrangência",
            validateTerritoryAndPolicy,
          ),
        policy: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .required("Selecione a política")
          .test(
            "validateTerritoryAndPolicy",
            "Selecione a política",
            validateTerritoryAndPolicy,
          ),
        actions: yup
          .array()
          .of(
            yup.object().shape({
              stages: yup.array().of(
                yup.object().shape({
                  stage: yup.number().nullable(),
                  users: yup
                    .array()
                    .of(
                      yup.object().shape({
                        token: yup.string().required(),
                        name: yup.string().required(),
                        email: yup.string().required(),
                      }),
                    )
                    .when("stage", {
                      is: 1,
                      then: yup
                        .array()
                        .min(1, "Selecione um")
                        .required("Selecione um"),
                      otherwise: yup.array().notRequired(),
                    }),
                }),
              ),
            }),
          )
          .min(1)
          .required(),
      }),
    )
    .when("approvalNeeded", {
      is: true,
      then: yup
        .array()
        .min(1, "Adicione ao menos uma regra do tipo 'Solicitar aprovação'")
        .test(
          "containsApprovalType",
          "Adicione ao menos uma regra do tipo 'Solicitar aprovação'",
          validateRules as yup.TestFunction<any[] | undefined, AnyObject>,
        ),
      otherwise: yup.array().notRequired(),
    }),
});

export default approvalProcessSchema;
