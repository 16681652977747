import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";

import { HotelsEditSearchDialog } from "./edit-search-dialog/edit-search-dialog";
import { HotelsContent } from "./hotels-content/hotels-content";
import { HotelsHeader } from "./hotels-header/hotels-header";
import { HotelsProvider, useHotels } from "./hotels.context";
import { styles } from "./styles";

type Props = RouteComponentProps<
  {
    hotelToken?: string;
  } & {
    travelToken?: string;
  }
>;

const Component: React.FC<Props> = ({ hotelToken, travelToken }) => {
  const { loadHotelInfos, pageTitle } = useHotels();

  useEffect(() => {
    if (!hotelToken) {
      return;
    }

    loadHotelInfos(hotelToken);
  }, [hotelToken]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <div css={styles.root}>
        <HotelsHeader />
        <HotelsContent />
      </div>
      {travelToken ? (
        <HotelsEditSearchDialog travelToken={travelToken} />
      ) : null}
    </>
  );
};

export const Hotels: React.FC<Props> = ({ ...props }) => {
  return (
    <HotelsProvider>
      <Component {...props} />
    </HotelsProvider>
  );
};
