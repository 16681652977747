import { StyledReactSelect } from "~/components/shared/styled-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Text } from "smartrips-toolkit";

import { TRANSLATED_SSO_CONFIG_PROVIDER } from "~/constants";
import { SSOConfigProvider } from "~/constants/enums";
import { OptionType } from "~/constants/shared";

import { CompleteSSOConfig } from "~/models/sso-config.model";

import {
  StContainedButton,
  StOutlinedButton,
} from "~/components/shared/buttons";
import { Checkbox } from "~/components/shared/checkbox";
import { Input } from "~/components/shared/inputs";
import InputError from "~/components/shared/inputs/InputError";

import { CONFIG_SSO_VALIDATION } from "../validations";
import { styles } from "./styles";

export interface ISSOConfigData {
  provider: OptionType<SSOConfigProvider>;
  tenantID?: string;
  discoveryEndpoint?: string;
  clientId: string;
  clientSecret: string;
  ssoIsTheOnlyLoginMethod: boolean;
}

interface ISSOConfigDialogProps {
  ssoConfig: CompleteSSOConfig | null;
  isOpen: boolean;
  handleClose(): void;
  isLoading: boolean;
  onSubmit(data: ISSOConfigData): void;
}

export const SSOConfigDialog: React.FC<ISSOConfigDialogProps> = ({
  ssoConfig,
  isOpen,
  handleClose,
  onSubmit,
  isLoading,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
  } = useForm<ISSOConfigData>({
    resolver: yupResolver(CONFIG_SSO_VALIDATION),
  });

  const provider = watch("provider") || { value: ssoConfig?.provider };

  useEffect(() => {
    if (provider?.value !== ssoConfig?.provider) {
      setValue("discoveryEndpoint", "");
    }
  }, [provider]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableRestoreFocus
      fullWidth="sm"
      maxWidth="sm"
    >
      <div css={styles.titleContainer}>
        <h6 css={styles.title}>Single sign-on (SSO)</h6>
        <IconButton
          aria-label="Fechar"
          onClick={handleClose}
          style={{ marginRight: "0.25rem" }}
        >
          <CloseIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <DialogContent css={styles.mainContainer}>
        <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
          <Text fontSize={2} fontWeight="bold">
            Provedor
          </Text>
          <Controller
            as={
              <StyledReactSelect
                optionsToBuild={TRANSLATED_SSO_CONFIG_PROVIDER}
              />
            }
            id="provider"
            name="provider"
            control={control}
            placeholder="Selecione o provedor"
            error={!!errors.provider}
            defaultValue={
              ssoConfig
                ? {
                    label: TRANSLATED_SSO_CONFIG_PROVIDER[ssoConfig.provider],
                    value: ssoConfig.provider,
                  }
                : null
            }
          />
          {errors?.provider ? (
            <InputError style={{ marginTop: 0 }}>
              {errors?.provider?.message}
            </InputError>
          ) : null}
        </Box>
        {provider?.value === SSOConfigProvider.MICROSOFT_AZURE ? (
          <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text fontSize={2} fontWeight="bold">
              ID do Locatário
            </Text>
            <Controller
              as={<Input />}
              id="tenantID"
              name="tenantID"
              control={control}
              placeholder="Preencha o ID do locatário"
              error={!!errors.tenantID}
              errorMessage={errors.tenantID?.message}
              defaultValue={
                ssoConfig ? ssoConfig?.domain.slice(34).split("/")[0] : ""
              }
            />
          </Box>
        ) : null}
        {provider?.value &&
        provider?.value !== SSOConfigProvider.MICROSOFT_AZURE &&
        provider?.value !== SSOConfigProvider.GOOGLE ? (
          <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
            <Text fontSize={2} fontWeight="bold">
              Discovery Endpoint
            </Text>
            <Controller
              as={<Input />}
              id="discoveryEndpoint"
              name="discoveryEndpoint"
              control={control}
              placeholder="Preencha o discovery endpoint"
              error={!!errors.discoveryEndpoint}
              errorMessage={errors.discoveryEndpoint?.message}
              defaultValue={ssoConfig?.domain}
            />
          </Box>
        ) : null}
        <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
          <Text fontSize={2} fontWeight="bold">
            ID do Cliente
          </Text>
          <Controller
            as={<Input />}
            id="clientId"
            name="clientId"
            control={control}
            placeholder="Preencha o ID do cliente"
            error={!!errors.clientId}
            errorMessage={errors.clientId?.message}
            defaultValue={ssoConfig?.client_id}
          />
        </Box>
        <Box display="flex" flexDirection="column" style={{ gap: "0.5rem" }}>
          <Text fontSize={2} fontWeight="bold">
            Segredo do Cliente
          </Text>
          <Controller
            as={<Input />}
            id="clientSecret"
            name="clientSecret"
            control={control}
            placeholder="Preencha o segredo do cliente"
            error={!!errors.clientSecret}
            errorMessage={errors.clientSecret?.message}
            defaultValue={ssoConfig?.client_secret}
          />
        </Box>
        <Controller
          name="ssoIsTheOnlyLoginMethod"
          defaultValue={ssoConfig?.sso_is_the_only_login_method || false}
          control={control}
          render={(props) => (
            <label htmlFor="ssoIsTheOnlyLoginMethod" css={styles.checkboxLabel}>
              <Checkbox
                id="ssoIsTheOnlyLoginMethod"
                name="ssoIsTheOnlyLoginMethod"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
                style={{ padding: 0 }}
              />
              Tornar este SSO o único método de autenticação permitido para a
              empresa
            </label>
          )}
        />
        <Box display="flex" style={{ gap: "1rem", alignSelf: "end" }}>
          <StOutlinedButton
            style={{
              padding: ".5rem 2rem",
            }}
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancelar
          </StOutlinedButton>
          <StContainedButton
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </StContainedButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
