import { cardNumber } from "~/apps/shared/schemas/common-fields.schema";
import * as yup from "yup";

import { removeBlankSpaces } from "~/helpers";

export const createPaymentMethodSchema = yup.object().shape({
  allowApprovers: yup.boolean().nullable(true),
  cvv: yup
    .string()
    .test("validateCVV", "Digite um CVV válido.", (value, context) => {
      if (value === undefined) {
        return true;
      }

      const cvvWithoutSpaces = removeBlankSpaces(value);

      if (!cvvWithoutSpaces) {
        return false;
      }

      if (!context.parent.issuer) {
        return false;
      }

      return cvvWithoutSpaces.match(context.parent.issuer.cvvDigitsPattern);
    })
    .required("Preencha o CVV."),
  description: yup.string().trim().required("Dê um apelido ao cartão."),
  enableToBus: yup.boolean(),
  enableToCar: yup.boolean(),
  enableToClient: yup.boolean(),
  enableToFlight: yup.boolean(),
  enableToHotel: yup.boolean(),
  enableToOther: yup.boolean(),
  enableToRide: yup.boolean(),
  expirationDate: yup
    .string()
    .test("", "Digite uma data válida.", (expirationDate) => {
      if (expirationDate === undefined) {
        return true;
      }

      const [month, year] = removeBlankSpaces(expirationDate).split("/");

      if (!month && !year) {
        return false;
      }

      const fullYear = 2000 + Number(year);
      const today = new Date();

      const expectedDate = new Date(fullYear, month, 1);
      const currentDate = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1,
      );

      return expectedDate >= currentDate;
    })
    .required("Digite uma data válida."),
  holderName: yup
    .string()
    .trim()
    .required("Preencha o nome do portador do cartão."),
  issuer: yup
    .object()
    .shape({
      lengths: yup.array().of(yup.number()).required(),
    })
    .required(),
  number: cardNumber().required("Número do cartão inválido."),
});

export type CreatePaymentMethodSchema = yup.InferType<
  typeof createPaymentMethodSchema
>;
