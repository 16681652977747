import React from "react";
import { FocusedInputShape } from "react-dates";

import DateRangeIcon from "@material-ui/icons/DateRange";
import { css } from "@styled-system/css";
import { FormikErrors } from "formik";
import { Moment } from "moment";
import { darken } from "polished";
import { theme } from "smartrips-skin";
import uuid from "uuid";

import { Box, Flex } from "@toolkit";

import { BusSearchForm, BusAutosuggestItem } from "@models/bus.model";
import { UserModel } from "@models/user.model";

import { useFocusElementOnMount } from "@hooks";

import { BusLocationAutosuggest, BusTypeTabs } from "@shared/bus";
import { StContainedButton } from "@shared/buttons";
import {
  ResponsiveSingleDatePicker,
  ResponsiveRangeDatePicker
} from "@shared/date-pickers";
import { SwapButton } from "@shared/new-trip/SwapButton";

import { useOfferSearch } from "../offer-search";
import { TravelerAutocomplete } from "../TravelerAutocomplete";

const styles = {
  locationContainer: css({
    position: "relative"
  }),
  origin: css({
    borderRightWidth: ["1px", "0.5px"],
    borderRadius: [1, "4px 0 0 4px"]
  }),
  destination: css({
    borderLeftWidth: ["1px", "0.5px"],
    borderRadius: [1, "0 4px 4px 0"]
  }),
  swapButton: css({
    position: ["absolute", "relative"],
    right: [16, "initial"],
    top: [45, "initial"],
    zIndex: [1, 0]
  }),
  getDateStyles: (error: boolean) =>
    css({
      height: 58,
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border",
      borderBottomColor: error ? "cancel" : "border",
      borderRadius: 1,
      padding: "medium",
      transition: "all 0.2s",

      ["&:hover"]: {
        borderColor: darken(0.2, theme.colors.border),
        borderBottomColor: error ? "cancel" : darken(0.2, theme.colors.border)
      }
    }),
  button: css({
    py: "small",
    px: "medium",
    lineHeight: "24px"
  }),
  error: css({
    display: "block",
    fontSize: 0,
    color: "cancel",
    lineHeight: "16px",
    textAlign: "left"
  })
};

interface Props {
  values: BusSearchForm;
  errors: FormikErrors<BusSearchForm>;
  singleDatePickerFocus: boolean;
  rangeDatePickerFocus: FocusedInputShape | null;
  handleTabChange: (_: any, tab: any) => void;
  handleSingleDateChange: (date: Moment | null) => void;
  handleRangeDateChange: (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  handleLocationChange: (
    type: "origin" | "destination"
  ) => (place: BusAutosuggestItem) => void;
  handleSwapLocations(): void;
  handleSingleDateFocusChange: ({ focused }: { focused: boolean }) => void;
  handleRangeDateFocusChange: (focused: FocusedInputShape | null) => void;
  handleTravelerChange: (traveler: UserModel) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const BusFormPresentational = ({
  values,
  errors,
  singleDatePickerFocus,
  rangeDatePickerFocus,
  handleTabChange,
  handleSingleDateChange,
  handleRangeDateChange,
  handleLocationChange,
  handleSwapLocations,
  handleSingleDateFocusChange,
  handleRangeDateFocusChange,
  handleTravelerChange,
  handleSubmit
}: Props) => {
  useFocusElementOnMount("busOrigin");

  const { getBookingTargets } = useOfferSearch();

  return (
    <form onSubmit={handleSubmit}>
      <Box p={["20px 8px 16px", "large"]}>
        <div>
          <BusTypeTabs value={values.type} onChange={handleTabChange} />
        </div>
        <Flex
          mb="small"
          flexDirection={["column", "row"]}
          css={styles.locationContainer}
        >
          <BusLocationAutosuggest
            inputProps={{
              id: "busOrigin",
              placeholder: "Origem",
              error: errors.origin,
              css: styles.origin
            }}
            value={values.origin ? values.origin.label : ""}
            updateParent={handleLocationChange("origin")}
          />
          <SwapButton css={styles.swapButton} onClick={handleSwapLocations} />
          <Box display={["block", "none"]} height="8px" />
          <BusLocationAutosuggest
            inputProps={{
              id: "busdestination",
              placeholder: "Destino",
              error: errors.destination,
              css: styles.destination
            }}
            value={values.destination ? values.destination.label : ""}
            updateParent={handleLocationChange("destination")}
          />
        </Flex>
        <Flex flexDirection={["column", "row"]}>
          <Box width="100%" maxWidth={["100%", "50%"]} mb={["small", 0]}>
            <Flex
              alignItems="center"
              id="new-trip-picker"
              css={styles.getDateStyles(
                !!errors.departureDate || !!errors.returnDate
              )}
            >
              {values.type === "roundtrip" && (
                <ResponsiveRangeDatePicker
                  datePickerProps={{
                    startDate: values.departureDate,
                    startDateId: uuid(),
                    startDatePlaceholderText: "Ida",
                    endDate: values.returnDate,
                    endDateId: uuid(),
                    endDatePlaceholderText: "Volta",
                    minimumNights: 0,
                    onDatesChange: handleRangeDateChange,
                    focusedInput: rangeDatePickerFocus,
                    onFocusChange: handleRangeDateFocusChange,
                    disableScroll: false,
                    noBorder: true,
                    hideKeyboardShortcutsPanel: true,
                    anchorDirection: "left",
                    customInputIcon: <DateRangeIcon />
                  }}
                />
              )}
              {values.type === "oneway" && (
                <ResponsiveSingleDatePicker
                  datePickerProps={{
                    date: values.departureDate,
                    onDateChange: handleSingleDateChange,
                    placeholder: "Ida",
                    focused: singleDatePickerFocus,
                    onFocusChange: handleSingleDateFocusChange,
                    id: uuid(),
                    disabled: false,
                    noBorder: true,
                    hideKeyboardShortcutsPanel: true,
                    anchorDirection: "left",
                    customInputIcon: <DateRangeIcon />
                  }}
                />
              )}
            </Flex>
            {errors.departureDate ? (
              <span css={styles.error}>{errors.departureDate}</span>
            ) : null}
            {errors.returnDate && values.type === "roundtrip" ? (
              <span css={styles.error}>{errors.returnDate}</span>
            ) : null}
          </Box>
        </Flex>
      </Box>

      <Box
        p={["24px 8px 16px", "large"]}
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius="0 0 5px 5px"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box width="100%" maxWidth={["100%", 300]} mb={["medium", 0]}>
            <TravelerAutocomplete
              customGetBookingTargets={getBookingTargets}
              error={errors.traveler}
              onChange={handleTravelerChange}
              traveler={values.traveler}
            />
          </Box>
          <Box width="100%" maxWidth={["100%", 155.38]}>
            <StContainedButton size="small" type="submit" css={styles.button}>
              Pesquisar ônibus
            </StContainedButton>
          </Box>
        </Flex>
      </Box>
    </form>
  );
};

export { BusFormPresentational };
