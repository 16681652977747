import { ServiceType } from "~/apps/shared/constants/enums";

import {
  ClientBillingProfileDto,
  GetBillingProfilePayableCardsForTravelResponse,
  GetUserBillingProfilesResponse,
} from "../dtos/billing-profile.dto";
import {
  BillingProfile,
  UserBillingProfile,
} from "../models/billing-profile.model";
import { CreditCard } from "../models/credit-card.model";
import { VCN } from "../models/vcn.model";

export const parseBillingProfilePayableCardsForTravel = (
  dto: GetBillingProfilePayableCardsForTravelResponse,
) => {
  return Object.entries(dto).reduce(
    (prev, [k, v]) => {
      prev[k as ServiceType | "other"] = {
        creditCards: v.creditCards.map((c) => {
          const creditCard: CreditCard = {
            brand: c.brand,
            creditCardToken: c.credit_card_token,
            description: c.description,
            lastDigits: c.last_digits,
            payerType: c.payer_type,
          };

          return creditCard;
        }),
        vcns: v.vcns.map((v) => {
          const vcnServiceTypes: ServiceType[] = [];

          if (v.all_enabled) {
            vcnServiceTypes.push(ServiceType.BUS);
            vcnServiceTypes.push(ServiceType.CAR);
            vcnServiceTypes.push(ServiceType.FLIGHT);
            vcnServiceTypes.push(ServiceType.HOTEL);
            vcnServiceTypes.push(ServiceType.INSURANCE);
          }

          if (v.car_enabled) {
            vcnServiceTypes.push(ServiceType.CAR);
          }

          if (v.hotel_enabled) {
            vcnServiceTypes.push(ServiceType.HOTEL);
          }

          if (v.flight_enabled) {
            vcnServiceTypes.push(ServiceType.FLIGHT);
          }

          const vcn: VCN = {
            billingProfileToken: v.billing_profile_token,
            provider: v.provider,
            payerType: v.payer_type,
            serviceTypes: vcnServiceTypes,
            vcnToken: v.vcn_token,
          };

          return vcn;
        }),
      };

      return prev;
    },
    {} as Record<
      ServiceType | "other",
      {
        creditCards: CreditCard[];
        vcns: VCN[];
      }
    >,
  );
};

export const parseClientBillingProfile = (dto: ClientBillingProfileDto) => {
  const billingProfile: BillingProfile = {
    billingProfileToken: dto.billing_profile_token,
    city: dto.billingProfileAddress ? dto.billingProfileAddress.city : null,
    cnpj: dto.cnpj,
    complement: dto.billingProfileAddress
      ? dto.billingProfileAddress.complement
      : null,
    creditCard: dto.credit_card_token ? dto.credit_card_token : null,
    email: dto.email,
    legalName: dto.legal_name,
    name: dto.name,
    neighborhood: dto.billingProfileAddress
      ? dto.billingProfileAddress.neighborhood
      : null,
    number: dto.billingProfileAddress ? dto.billingProfileAddress.number : null,
    phone: dto.phone,
    state: dto.billingProfileAddress ? dto.billingProfileAddress.state : null,
    street: dto.billingProfileAddress ? dto.billingProfileAddress.street : null,
    zipCode: dto.billingProfileAddress
      ? dto.billingProfileAddress.zip_code
      : null,
  };

  return billingProfile;
};

export const parseUserBillingProfiles = (
  dto: GetUserBillingProfilesResponse,
) => {
  return dto.map((userBillingProfileDto) => {
    const userBillingProfile: UserBillingProfile = {
      billingProfileToken: userBillingProfileDto.billing_profile_token,
      city: null,
      client_token: userBillingProfileDto.client_token,
      cnpj: userBillingProfileDto.cnpj,
      complement: null,
      creditCard: userBillingProfileDto.credit_card_token
        ? userBillingProfileDto.credit_card_token
        : null,
      email: userBillingProfileDto.email,
      legalName: userBillingProfileDto.legal_name,
      name: userBillingProfileDto.name,
      neighborhood: null,
      number: null,
      phone: userBillingProfileDto.phone,
      state: null,
      street: null,
      zipCode: null,
    };

    return userBillingProfile;
  });
};
