import React, { useState, useMemo, MouseEvent } from "react";
import { FilterButtonPopover } from "@shared/filterButtonPopover/FilterButtonPopover";
import FormControl from "@material-ui/core/FormControl";
import { Option } from "~/types";
import { Select } from "@shared/inputs";
import { ActionMeta } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

interface State {
  anchorEl: React.ReactNode | null;
  isFilterSelected: boolean;
  billingProfileToken: string;
  wasFilterApplied: boolean;
}

const initialState: State = {
  anchorEl: null,
  isFilterSelected: false,
  billingProfileToken: "",
  wasFilterApplied: false
};

interface Props {
  options?: Array<Option<string>>;
  onApply: (billingProfileToken: string) => void;
  containerStyles?: React.CSSProperties;
}

const BillingProfileFilter: React.FC<Props> = ({
  options = [],
  onApply,
  containerStyles = {}
}) => {
  const [state, setState] = useState(initialState);

  const selectedBillingProfile = useMemo(() => {
    return state.billingProfileToken
      ? options.find(
          billingProfile => billingProfile.value === state.billingProfileToken
        )
      : null;
  }, [state.billingProfileToken, options]);

  const handleApply = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      isFilterSelected: !!state.billingProfileToken,
      wasFilterApplied: true
    }));
    onApply(state.billingProfileToken);
  };

  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      billingProfileToken: "",
      isFilterSelected: false,
      wasFilterApplied: false
    }));
    onApply("");
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { target } = e;
    setState(prevState => ({
      ...prevState,
      anchorEl: target,
      isFilterSelected: true
    }));
  };

  const handleChange = (option: OptionType | null, event: ActionMeta) => {
    setState(prevState => ({
      ...prevState,
      billingProfileToken: option && option.value ? option.value : "",
      isFilterSelected: true,
      wasFilterApplied: false
    }));
    if (event.action === "clear") {
      onApply("");
    }
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      anchorEl: null,
      billingProfileToken: state.wasFilterApplied
        ? state.billingProfileToken
        : "",
      isFilterSelected: state.wasFilterApplied ? state.wasFilterApplied : false
    }));
  };

  return (
    <FilterButtonPopover
      anchorEl={state.anchorEl}
      isFilterSelected={state.isFilterSelected}
      isOpen={!!state.anchorEl}
      applyEnabled
      clearEnabled
      showClearButton={!!state.billingProfileToken}
      onApplyClick={handleApply}
      onClearClick={handleClear}
      onButtonClick={handleClick}
      onPopoverClose={handleClose}
      unselectedButtonText={"Perfil de cobrança"}
      selectedButtonText={
        selectedBillingProfile
          ? selectedBillingProfile.label
          : "Perfil de Cobrança"
      }
      containerStyle={containerStyles}
    >
      <div>
        <FormControl style={{ width: "240px" }}>
          <div>
            <Select
              placeholder="Perfil de cobrança"
              value={selectedBillingProfile}
              options={options}
              onChange={handleChange}
            />
          </div>
        </FormControl>
      </div>
    </FilterButtonPopover>
  );
};

export default BillingProfileFilter;
