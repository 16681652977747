import React from "react";

import { getUserFromLocalStorage } from "@helpers/user.helper";

import { Capabilities } from "@models/user.model";

type Props = {
  atLeastOne?: boolean;
  capabilities: string[] | (keyof Capabilities)[];
};

const CapabilitiesVisibility: React.FC<Props> = ({
  atLeastOne = false,
  capabilities = [],
  children
}) => {
  const user = getUserFromLocalStorage();

  if (!user) {
    return null;
  }

  if (atLeastOne) {
    return capabilities.length > 0 &&
      capabilities.some(capability => user.capabilities[capability]) ? (
      <>{children}</>
    ) : null;
  }

  return capabilities.length > 0 &&
    capabilities.every(capability => user.capabilities[capability]) ? (
    <>{children}</>
  ) : null;
};

export default CapabilitiesVisibility;
