import React, { createContext } from "react";

import { navigate } from "@reach/router";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { useSafeState } from "~/apps/shared/hooks/use-safe-state";

import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { BookingTarget } from "../../models/booking-target.model";
import * as offerSearcherService from "./offer-searcher.service";
import {
  BusesSearch,
  CarsSearch,
  FlightsSearch,
  HotelsSearch,
} from "./offer-searcher.types";

interface Actions {
  handleSearchBuses: (busesSearch: BusesSearch) => Promise<void>;
  handleSearchCars: (carsSearch: CarsSearch) => Promise<void>;
  handleSearchFlights: (flightsSearch: FlightsSearch) => Promise<void>;
  handleSearchHotels: (hotelsSearch: HotelsSearch) => Promise<void>;
}

type State = {
  isSubmitting: boolean;
};

const initialState: State = {
  isSubmitting: false,
};

type ContextProps = Actions & State;

const OfferSearcherContext = createContext<ContextProps>({
  ...initialState,
  handleSearchBuses: async () => {
    return;
  },
  handleSearchCars: async () => {
    return;
  },
  handleSearchFlights: async () => {
    return;
  },
  handleSearchHotels: async () => {
    return;
  },
});

export const OfferSearcherProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useSafeState(initialState);

  const getTravelToken = async (createTravelData: BookingTarget) => {
    if (!user) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    const { data, error } = await offerSearcherService.createTravel(
      createTravelData,
      user,
    );

    if (error) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return null;
    }

    if (!data) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(ERROR.UNEXPECTED.description, ALERT_TYPES.ERROR);

      return null;
    }

    return data;
  };

  const handleSearchBuses = async (busesSearchData: BusesSearch) => {
    setState({ isSubmitting: true });

    const travelToken = busesSearchData.travelToken
      ? busesSearchData.travelToken
      : await getTravelToken(busesSearchData.traveler!);

    if (!travelToken) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { data, error } = await offerSearcherService.createBusesSearch(
      busesSearchData,
      travelToken,
    );

    if (error) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isSubmitting: false,
    });

    navigate(`/travels/${travelToken}/search/bus/${data.bus_token}`);
  };

  const handleSearchCars = async (carsSearchData: CarsSearch) => {
    setState({ isSubmitting: true });

    const travelToken = carsSearchData.travelToken
      ? carsSearchData.travelToken
      : await getTravelToken(carsSearchData.traveler!);

    if (!travelToken) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { data, error } = await offerSearcherService.createCarsSearch(
      carsSearchData,
      travelToken,
    );

    if (error) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(ERROR.UNEXPECTED.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isSubmitting: false,
    });
    navigate(`/travels/${travelToken}/search/cars/${data.car_token}`);
  };

  const handleSearchFlights = async (flightsSearch: FlightsSearch) => {
    setState({ isSubmitting: true });

    const travelToken = flightsSearch.travelToken
      ? flightsSearch.travelToken
      : await getTravelToken(flightsSearch.traveler!);

    if (!travelToken) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { data, error } = await offerSearcherService.createFlightsSearch(
      flightsSearch,
      travelToken,
    );

    if (error) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(ERROR.UNEXPECTED.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isSubmitting: false,
    });
    navigate(`/travels/${travelToken}/search/flights/${data.token}`);
  };

  const handleSearchHotels = async (hotelsSearch: HotelsSearch) => {
    setState({ isSubmitting: true });

    const travelToken = hotelsSearch.travelToken
      ? hotelsSearch.travelToken
      : await getTravelToken(hotelsSearch.traveler!);

    if (!travelToken) {
      const error = ERROR.UNEXPECTED;

      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { data, error } = await offerSearcherService.createHotelsSearch(
      hotelsSearch,
      travelToken,
    );

    if (error) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!data) {
      setState({
        isSubmitting: false,
      });

      showSnackMessage(ERROR.UNEXPECTED.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isSubmitting: false,
    });
    navigate(`/travels/${travelToken}/search/hotels/${data.token}`);
  };

  return (
    <OfferSearcherContext.Provider
      value={{
        ...state,
        handleSearchBuses,
        handleSearchCars,
        handleSearchFlights,
        handleSearchHotels,
      }}
    >
      {children}
    </OfferSearcherContext.Provider>
  );
};

export const useOfferSearcher = useContextFactory(
  "OfferSearcherContext",
  OfferSearcherContext,
);
