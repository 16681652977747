import React, { useState } from "react";
import { FocusedInputShape } from "react-dates";

import { withFormik, FormikErrors, FormikProps } from "formik";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment";

import { IBookingTarget } from "~/models/booker-target.model";

import { BusSearchForm, BusAutosuggestItem } from "../../../models/bus.model";
import { BusFormPresentational } from "./BusForm.presentational";

interface Props {
  onSubmit: (value: BusSearchForm) => void;
}

const BusFormContainer = ({
  values,
  errors,
  handleSubmit,
  setFieldValue,
  setErrors
}: FormikProps<BusSearchForm>) => {
  const [singleDateFocus, setSingleFocus] = useState(false);
  const [rangeDateFocus, setRangeFocus] = useState<FocusedInputShape | null>(
    null
  );

  const removeFieldError = (filedName: string) => {
    const updatedErrors = reduce(
      errors,
      (acc: any, value, key) => {
        if (key !== filedName) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    setErrors(updatedErrors);
  };

  const handleTabChange = (_: any, tab: any) => {
    setFieldValue("type", tab);
  };

  const handleSingleDateFocusChange = ({ focused }: { focused: boolean }) =>
    setSingleFocus(focused);

  const handleRangeDateFocusChange = (focused: FocusedInputShape | null) =>
    setRangeFocus(focused);

  const handleSingleDateChange = (date: Moment | null) => {
    setFieldValue("departureDate", date);

    if (date) {
      removeFieldError("departureDate");
    }
  };

  const handleRangeDateChange = (date: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setFieldValue("departureDate", date.startDate);
    setFieldValue("returnDate", date.endDate);

    if (date.startDate) {
      removeFieldError("departureDate");
    }

    if (date.endDate) {
      removeFieldError("returnDate");
    }
  };

  const handleLocationChange = (type: "origin" | "destination") => (
    place: BusAutosuggestItem
  ) => {
    setFieldValue(type, place);

    if (type === "origin" && place) {
      removeFieldError("origin");
    }

    if (type === "destination" && place) {
      removeFieldError("destination");
    }
  };

  const handleSwapLocations = () => {
    const { destination, origin } = values;

    setFieldValue("origin", destination);
    setFieldValue("destination", origin);

    removeFieldError("origin");
    removeFieldError("destination");
  };

  const handleTravelerChange = (traveler: IBookingTarget) => {
    setFieldValue("traveler", traveler);

    if (traveler) {
      removeFieldError("traveler");
    }
  };

  return (
    <BusFormPresentational
      values={values}
      errors={errors}
      singleDatePickerFocus={singleDateFocus}
      rangeDatePickerFocus={rangeDateFocus}
      handleTabChange={handleTabChange}
      handleSingleDateChange={handleSingleDateChange}
      handleRangeDateChange={handleRangeDateChange}
      handleSingleDateFocusChange={handleSingleDateFocusChange}
      handleRangeDateFocusChange={handleRangeDateFocusChange}
      handleLocationChange={handleLocationChange}
      handleSwapLocations={handleSwapLocations}
      handleTravelerChange={handleTravelerChange}
      handleSubmit={handleSubmit}
    />
  );
};

const FormikContainer = withFormik<Props, BusSearchForm>({
  mapPropsToValues: () => ({
    origin: null,
    destination: null,
    departureDate: null,
    returnDate: null,
    type: "roundtrip",
    traveler: null
  }),
  validate: values => {
    const errors: FormikErrors<BusSearchForm> = {};

    if (!values.origin) {
      errors.origin = "Preencha o local de origem";
    }

    if (!values.destination) {
      errors.destination = "Preencha o local de destino";
    }

    if (!values.departureDate) {
      errors.departureDate = "Preencha a data de ida";
    }

    if (!values.departureDate) {
      errors.departureDate = "Preencha a data de check-in";
    } else if (
      values.departureDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    ) {
      errors.departureDate = "Data inicial inferior a hoje";
    }

    if (!values.type && !values.returnDate) {
      errors.returnDate = "Preencha a data de volta";
    }

    if (!values.traveler) {
      errors.traveler = "Selecione um viajante";
    }

    return errors;
  },
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(BusFormContainer);

export { FormikContainer as BusFormContainer };
