import React, { useState } from "react";
import { useMutation } from "react-query";

import { Add as PlusIcon } from "@material-ui/icons";
import moment from "moment";
import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import { useEvents } from "~/components/events/events.context";
import { Avatar } from "~/components/shared/avatar";
import CircularSpinner from "~/components/shared/CircularSpinner";
import { Spacing } from "~/components/shared/layout";

import { useEvent } from "../../event.context";
import { EventOverviewUpdatesCreateDialog } from "./create-dialog";
import { styles } from "./styles";

export type EditEventUpdateForm = {
  event: {
    token: string;
  };
  update: {
    on_top: boolean;
    token: string;
  };
};

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventOverviewUpdates: React.VFC<Props> = ({ event }) => {
  const user = getUserFromLocalStorage();

  const { invalidateUpdates, isLoadingUpdates, updates } = useEvent();
  const { editEventUpdate } = useEvents();

  const {
    isLoading: isLoadingEditEventUpdate,
    mutateAsync: editEventUpdateMutation
  } = useMutation({
    mutationFn: async (form: EditEventUpdateForm) => {
      const result = await editEventUpdate(form);

      if (!result) {
        return;
      }

      await invalidateUpdates();

      return result;
    }
  });

  const [action, setAction] = useState<"edit" | "new" | null>(null);

  return (
    <>
      <div css={styles.root}>
        {isLoadingUpdates ? (
          <div css={styles.loader}>
            <CircularSpinner />
          </div>
        ) : (
          <>
            <div css={styles.header}>
              <Text fontSize="1.125rem" fontWeight="bold">
                Atualizações
              </Text>
              {event.viewer_info?.is_organizer ? (
                <Button
                  css={styles.button}
                  onClick={() => {
                    setAction("new");
                  }}
                >
                  <PlusIcon fontSize="small" />
                  Nova Atualização
                </Button>
              ) : null}
            </div>
            <ul css={styles.list}>
              {!updates || updates.length === 0 ? (
                <Text color={theme.colors.gray[3]} fontSize={14}>
                  Nenhuma atualização foi encontrada.
                </Text>
              ) : (
                updates.map(update => (
                  <li css={styles.update.root} key={update.token}>
                    <Avatar name={update.creator_name} />
                    <div css={styles.update.card}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text fontSize={14} fontWeight="bold">
                          {update.creator_name}
                        </Text>
                        <Flex alignItems="center">
                          {update.on_top ? (
                            <>
                              <Text
                                color={theme.colors.white}
                                css={styles.update.badge}
                                fontSize={12}
                                fontWeight="bold"
                              >
                                Fixado
                              </Text>
                              <Spacing direction="horizontal" space="8px" />
                            </>
                          ) : null}
                          <Text color={theme.colors.gray[4]} fontSize={12}>
                            {moment(update.created_at).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                      <Text color={theme.colors.gray[4]} fontSize={14}>
                        {update.text}
                      </Text>
                      {user?.userToken === update.created_by &&
                      event.viewer_info.is_organizer ? (
                        <Flex>
                          <button
                            css={styles.update.action}
                            disabled={isLoadingEditEventUpdate}
                            onClick={async () => {
                              await editEventUpdateMutation({
                                event: {
                                  token: event.token
                                },
                                update: {
                                  on_top: !update.on_top,
                                  token: update.token
                                }
                              });
                            }}
                          >
                            <Text
                              color={theme.colors.primary}
                              fontSize={12}
                              fontWeight="bold"
                            >
                              {update.on_top ? "Desafixar" : "Fixar"}
                            </Text>
                          </button>
                        </Flex>
                      ) : null}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </>
        )}
      </div>
      {action === "new" ? (
        <EventOverviewUpdatesCreateDialog
          event={event}
          onClose={() => {
            setAction(null);
          }}
          open
        />
      ) : null}
    </>
  );
};
