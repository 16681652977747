import { useEffect, useMemo, useState } from "react";

import { navigate } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ERROR } from "~/apps/shared/constants/errors";
import { PageError } from "~/apps/shared/types";

import { useItineraryContainer } from "../../itinerary.container";
import { ItineraryTravelPlanPresenterFactory } from "./itinerary-travel-plan.presenter";

const initialPermissions = {
  canAddOffers: false,
  canCancelApprovalRequest: false,
  canEditCategorization: false,
  canEditTravelName: false,
  canEditTravelTags: false,
  canGoToApprovalReview: false,
};

export const useItineraryTravelPlanGuard = () => {
  const { itineraryTravelPlanPresenter } = useItineraryTravelPlanPresenter();

  const [error, setError] = useState<PageError | null>(null);
  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (!itineraryTravelPlanPresenter) {
      return;
    }

    if (!itineraryTravelPlanPresenter.isUserAuthorizedToAccessTravelPlan()) {
      setError({
        ...ERROR.UNAUTHORIZED_ACCESS_TO_TRAVEL_PLAN,
        navigateMessage: "Minhas viagens",
        navigateTo: () => {
          navigate("/trips");
        },
      });

      return;
    }

    setError(null);

    setPermissions({
      canAddOffers: itineraryTravelPlanPresenter.canAddOffers(),
      canCancelApprovalRequest: itineraryTravelPlanPresenter.canCancelApprovalRequest(),
      canEditCategorization: itineraryTravelPlanPresenter.canEditCategorization(),
      canEditTravelName: itineraryTravelPlanPresenter.canEditTravelName(),
      canEditTravelTags: itineraryTravelPlanPresenter.canEditTravelTags(),
      canGoToApprovalReview: itineraryTravelPlanPresenter.canGoToApprovalReview(),
    });
  }, [itineraryTravelPlanPresenter]);

  return {
    error,
    permissions,
  };
};

const useItineraryTravelPlanPresenter = () => {
  const { infoModel, travelApprovalStatusModel } = useItineraryContainer();

  const { user: userModel } = useUser();

  return useMemo(
    () => ({
      itineraryTravelPlanPresenter:
        infoModel && userModel
          ? ItineraryTravelPlanPresenterFactory.create(
              infoModel,
              travelApprovalStatusModel,
              userModel,
            )
          : null,
    }),
    [infoModel, travelApprovalStatusModel, userModel],
  );
};
