import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    root: css({
      color: theme.colors.gray[700],
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    }),
  },
  footer: {
    root: css({
      display: "flex",
      justifyContent: "flex-end",
      gap: "16px",
    }),
  },
  header: {
    close: css({
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[300],
      cursor: "pointer",
      padding: "8px",
      transition: "color 0.15s ease-in-out",
      ":hover": {
        color: theme.colors.gray[700],
      },
    }),
    icon: ({
      color,
      variant,
    }: {
      color?: string;
      variant?: "default" | "pink";
    }) => {
      if (variant === "pink") {
        color = theme.colors.pink[500];
      }

      return css({
        alignItems: "center",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        transform: "translateX(12px) translateY(12px)",
        zIndex: 0,
        "& svg": {
          color: color ? color : theme.colors.gray[500],
        },
        "&:after": {
          backgroundColor: color ? color : theme.colors.gray[500],
          borderRadius: "50%",
          content: '""',
          height: "48px",
          opacity: 0.05,
          position: "absolute",
          width: "48px",
          zIndex: -1,
        },
        "&:before": {
          backgroundColor: color ? color : theme.colors.gray[500],
          borderRadius: "50%",
          content: '""',
          height: "32px",
          opacity: 0.1,
          position: "absolute",
          width: "32px",
          zIndex: -1,
        },
      });
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      minHeight: "48px",
      justifyContent: "space-between",
    }),
  },
  root: css({
    ".MuiDialog-container": {
      padding: "0 24px",
      "@media screen and (max-width: 768px)": {
        padding: "0 12px",
      },
    },
    ".MuiDialog-paper": {
      backgroundColor: "white",
      borderRadius: "8px",
      gap: "24px",
      margin: "0 auto",
      maxWidth: "512px",
      overflow: "visible",
      padding: "24px",
      width: "100%",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "8px",
        "&-thumb": {
          backgroundColor: theme.colors.gray[100],
        },
        "&-track": {
          backgroundColor: theme.colors.white,
        },
      },
    },
  }),
  title: {
    root: ({ variant }: { variant?: "default" | "pink" }) =>
      css({
        color:
          variant === "pink" ? theme.colors.pink[500] : theme.colors.gray[700],
        fontSize: "1.125rem",
        fontWeight: 700,
      }),
  },
};
