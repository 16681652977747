import React from "react";

import {
  DirectionsBus as BusIcon,
  DirectionsCar as CarIcon,
  Flight as FlightIcon,
  Hotel as HotelIcon
} from "@material-ui/icons/";
import { BookingStatus, ServiceType } from "sm-types/common";
import { theme } from "smartrips-skin";

const mapBookingStatusColor = (status: BookingStatus) =>
  (({
    DROPPED: theme.colors.cancel,
    EMITTED: theme.colors.success,
    FAILED: theme.colors.cancel,
    PENDING: theme.colors.gray[3],
    UNITIATED: theme.colors.gray[3]
  } as {
    [key in BookingStatus]: string;
  })[status]);

type Props = {
  status: BookingStatus;
  type: ServiceType;
};

export const OfferIconMap: React.VFC<Props> = ({ status, type }) => {
  if (type === ServiceType.BUS) {
    return (
      <BusIcon
        css={{
          color: mapBookingStatusColor(status)
        }}
        fontSize="small"
      />
    );
  }

  if (type === ServiceType.CAR) {
    return (
      <CarIcon
        css={{
          color: mapBookingStatusColor(status)
        }}
        fontSize="small"
      />
    );
  }

  if (type === ServiceType.FLIGHT) {
    return (
      <FlightIcon
        css={{
          color: mapBookingStatusColor(status)
        }}
        fontSize="small"
      />
    );
  }

  if (type === ServiceType.HOTEL) {
    return (
      <HotelIcon
        css={{
          color: mapBookingStatusColor(status)
        }}
        fontSize="small"
      />
    );
  }

  return null;
};
