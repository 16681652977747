import React from "react";

import { css } from "@styled-system/css";
import { AreaListItem } from "~/apps/corporate/models/area.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Flex } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { Column } from "@components/shared/layout/Column";

export const styles = {
  noItemFound: css({
    fontSize: 15,
    color: theme.colors.gray[500],
    paddingLeft: "1rem",
    paddingTop: "1rem",
  }),
  list: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    }),
    item: {
      left: {
        name: css({
          color: theme.colors.gray[500],
          fontWeight: 600,
          lineHeight: "1.5rem",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }),
      },
      right: {
        count: css({
          alignItems: "center",
          display: "flex",
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          gap: "0.5rem",
          marginRight: "0.5rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "0.5rem",
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.gray,
        borderRadius: "0.5rem",
        display: "flex",
        gap: "0.5rem",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        cursor: "pointer",
      }),
    },
  },
};

interface IBoardTableProps {
  boards: AreaListItem[];
  handleEdit: any;
  handleToggleArchive: (
    companyAreaToken: string,
    activeStatus: boolean,
  ) => Promise<void>;
}

export const BoardTable: React.FC<IBoardTableProps> = ({
  boards,
  handleEdit,
  handleToggleArchive,
}) => {
  const compareByLowerCaseName = (itemA: AreaListItem, itemB: AreaListItem) => {
    if (itemA.name.toLowerCase() < itemB.name.toLowerCase()) {
      return -1;
    }
    if (itemA.name.toLowerCase() > itemB.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  return (
    <div style={{ width: "100%", marginTop: "1.5rem" }}>
      <Column>
        {!boards.length ? (
          <span css={styles.noItemFound}>Nenhuma diretoria cadastrada</span>
        ) : null}
        <Flex flexDirection="column" style={{ gap: "0.5rem" }}>
          {boards
            .slice()
            .sort(compareByLowerCaseName)
            .map((board, index) => (
              <li
                css={styles.list.item.root}
                key={index}
                onClick={() => handleEdit(board.companyAreaToken)}
              >
                <div css={styles.list.item.left.root}>
                  <span css={styles.list.item.left.name}>{board.name}</span>
                </div>
                <div css={styles.list.item.right.root}>
                  <div css={styles.list.item.right.count}>
                    {board.subAreasCount}
                    <Icon size={16} use="people" />
                  </div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();

                      handleEdit(board.companyAreaToken);
                    }}
                    shape="icon"
                  >
                    <Icon size={20} use="pencil" />
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();

                      handleToggleArchive(
                        board.companyAreaToken,
                        !board.active,
                      );
                    }}
                    shape="icon"
                    variant="pink"
                  >
                    <Icon size={20} use="trash" />
                  </Button>
                </div>
              </li>
            ))}
        </Flex>
      </Column>
    </div>
  );
};
