import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    marginTop: "4px",
    width: "fit-content",
  }),
  item: {
    actions: css({
      padding: "8px 0",
    }),
    input: {
      container: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        outline: "none",
        padding: "6px 16px",
        position: "relative",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "::placeholder": {
          color: theme.colors.gray[300],
        },
      }),
      input: css({
        lineHeight: "24px",
        padding: "0",
      }),
      label: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        lineHeight: "18px",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      gap: "16px",
    }),
  },
  label: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
    lineHeight: "28px",
  }),
  list: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
};
