import { Image, Text, View } from "@react-pdf/renderer";
import React, { useMemo } from "react";

import breakfastIcon from "~/apps/shared/assets/icons/breakfast.png";
import closeIcon from "~/apps/shared/assets/icons/close.png";
import wifiIcon from "~/apps/shared/assets/icons/wifi.png";
import moment from "moment";

import { styles } from "./styles";

const Breakfast: React.FC<Props> = () => {
  const itemStyles = styles.item();

  return (
    <View style={itemStyles.root}>
      <Image
        src={breakfastIcon}
        style={{
          height: 10,
          marginLeft: 4,
          width: 10,
        }}
      />
      <View style={itemStyles.text}>
        <Text>Café da manhã incluso</Text>
      </View>
    </View>
  );
};

const Refund: React.FC<Props> = ({ cancelationUntil }) => {
  const itemStyles = styles.item();

  const cancelationText =
    cancelationUntil && cancelationUntil !== "any"
      ? "até " + moment(cancelationUntil).format("DD [de] MMMM [de] YYYY")
      : "";

  return (
    <View style={itemStyles.root}>
      <Image
        src={closeIcon}
        style={{
          height: 10,
          marginLeft: 4,
          width: 10,
        }}
      />
      <View style={itemStyles.text}>
        <Text>Cancelamento grátis {cancelationText}</Text>
      </View>
    </View>
  );
};

const Wifi: React.FC<Props> = () => {
  const itemStyles = styles.item();

  return (
    <View style={itemStyles.root}>
      <Image
        src={wifiIcon}
        style={{
          height: 10,
          marginLeft: 4,
          width: 10,
        }}
      />
      <View style={itemStyles.text}>
        <Text>Wifi</Text>
      </View>
    </View>
  );
};

type Props = React.ComponentProps<typeof View> & {
  breakfast?: boolean;
  cancel?: boolean;
  cancelationUntil?: string | null;
  wifi?: boolean;
};

export const TravelPlanPDFHotelSegmentAmenitiesIcons = ({
  ...props
}: Props) => {
  const { breakfast, cancel, wifi } = props;

  const rootStyles = styles.root();

  const amenities = useMemo(
    () => [
      { Component: Refund, id: "breakfast", value: cancel },
      { Component: Breakfast, id: "refund", value: breakfast },
      { Component: Wifi, id: "wifi", value: wifi },
    ],
    [breakfast, cancel, wifi],
  );

  const includedAmenities = useMemo(
    () => amenities.filter((item) => item.value),
    [amenities],
  );

  return (
    <View style={rootStyles.root} {...props}>
      {includedAmenities.map(({ Component, id }) => (
        <Component key={id} {...props} />
      ))}
    </View>
  );
};
