import React from "react";

import { CircularSpinner } from "@toolkit/v2";

import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const FullPageLoader: React.FC<Props> = ({ ...props }) => {
  return (
    <div css={styles.root} {...props}>
      <CircularSpinner />
    </div>
  );
};
