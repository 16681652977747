import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: {
    left: {
      icon: css({}),
      label: css({
        color: "inherit",
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "15px",
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "10px",
      }),
    },
    right: {
      icon: ({ isActive }: { isActive: boolean }) =>
        css({
          transform: isActive ? "rotate(0deg)" : "rotate(180deg)",
          transition: "all 0.15s ease-in-out",
        }),
    },
    root: ({ isActive }: { isActive: boolean }) =>
      css({
        alignItems: "center",
        backgroundColor: isActive ? theme.colors.gray[500] : "transparent",
        border: "none",
        borderRadius: "8px",
        color: isActive ? theme.colors.white : theme.colors.gray[300],
        cursor: "pointer",
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        outline: "none",
        padding: "12.5px 16px",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        ":hover": {
          backgroundColor: isActive
            ? theme.colors.gray[500]
            : theme.colors.background.gray,
        },
      }),
  },
  link: {
    left: {
      icon: css({}),
      label: css({
        color: "inherit",
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "15px",
        width: "100%",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "10px",
      }),
    },
    right: {
      icon: ({ isActive }: { isActive: boolean }) =>
        css({
          transform: isActive ? "rotate(0deg)" : "rotate(180deg)",
        }),
    },
    root: ({ hasIcon, isActive }: { hasIcon: boolean; isActive: boolean }) => ({
      alignItems: "center",
      backgroundColor: isActive
        ? hasIcon
          ? theme.colors.gray[700]
          : theme.colors.background.gray
        : "transparent",
      border: "none",
      borderRadius: "8px",
      color: isActive
        ? hasIcon
          ? theme.colors.white
          : theme.colors.gray[700]
        : theme.colors.gray[300],
      cursor: "pointer",
      display: "flex",
      gap: "10px",
      justifyContent: "space-between",
      outline: "none",
      padding: "12.5px 16px",
      textDecoration: "none",
      transition: "all 0.15s ease-in-out",
      width: "100%",
      ":hover": {
        backgroundColor: theme.colors.background.gray,
        color: theme.colors.gray[700],
      },
    }),
  },
  links: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "& > *": {
      paddingLeft: "calc(16px + 20px + 10px + 16px) !important",
    },
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};
