import React from "react";

import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@material-ui/core/Checkbox";
import CheckboxIcon from "@material-ui/icons/CheckBox";
import CheckboxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

type CheckboxProps = Omit<MuiCheckboxProps, "color" | "size"> & {
  color?: string;
  size?: "inherit" | "default" | "small" | "large";
};

export function Checkbox(props: CheckboxProps) {
  const { size = "inherit", color = "primary", ...rest } = props;

  return (
    <MuiCheckbox
      {...rest}
      inputProps={{
        "aria-label": props["aria-label"],
      }}
      style={{ borderRadius: "0.5rem" }}
      icon={<CheckboxOutlineBlankIcon fontSize={size} />}
      checkedIcon={
        <CheckboxIcon
          fontSize={size}
          style={{ color: color }}
          color={props.disabled ? "disabled" : "primary"}
        />
      }
    />
  );
}
