import React from "react";

import { NegotiatedFareBadge } from "~/apps/corporate/components/negotiated-fare-badge/negotiated-fare-badge";
import { SmApprovesBadge } from "~/apps/corporate/components/sm-approves-badge/sm-approves-badge";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { useItineraryServices } from "~/apps/corporate/pages/travels/itinerary/itinerary-services.context";
import { ErrorOnFetchOutOfPolicyBadge } from "~/apps/shared/components/error-on-fetch-out-of-policy-badge/error-on-fetch-out-of-policy-badge";
import { Icon } from "~/apps/shared/components/icon/icon";
import { OutOfPolicyBadge } from "~/apps/shared/components/out-of-policy-badge/out-of-policy-badge";

import { useItineraryScreen } from "../../../../pages/travels/itinerary/itinerary-screen.context";
import { ItinerayJourneyHeaderCancelServiceBadge } from "./itinerary-journey-header-cancel-service-badge/itinerary-journey-header-cancel-service-badge";
import { ItinerayJourneyHeaderChoiceContextBadge } from "./itinerary-journey-header-choice-context-badge/itinerary-journey-header-choice-context-badge";
import { ItineraryJourneyHeaderCopyTokenButton } from "./itinerary-journey-header-copy-token-button/itinerary-journey-header-copy-token-button";
import { ItinerayJourneyHeaderDeleteButton } from "./itinerary-journey-header-delete-button/itinerary-journey-header-delete-button";
import { ItinerayJourneyHeaderDeniedOnApprovalBadge } from "./itinerary-journey-header-denied-on-approval-badge/itinerary-journey-header-denied-on-approval-badge";
import { ItinerayJourneyHeaderOutdatedBadge } from "./itinerary-journey-header-outdated-badge/itinerary-journey-header-outdated-badge";
import { ItinerayJourneyHeaderUnavailableBadge } from "./itinerary-journey-header-unavailable-badge/itinerary-journey-header-unavailable-badge";
import { useItineraryJourneyHeaderPresenter } from "./itinerary-journey-header.hooks";
import { ItineraryServiceBookingFailed } from "./itinerary-service-booking-failed/itinerary-service-booking-failed";
import { styles } from "./styles";

type Props = {
  isApprovalReview?: boolean;
  isSelected?: boolean;
  isTravelPlan?: boolean;
  isUnavailable?: boolean;
  servicePresenter: ItineraryServicePresenter;
};

export const ItineraryJourneyHeader: React.FC<Props> = ({
  isApprovalReview,
  isSelected,
  isTravelPlan,
  isUnavailable,
  servicePresenter,
}) => {
  const { errorOnFetchTravelPoliciesPerOffer } = useItineraryServices();

  const {
    itineraryJourneyHeaderPresenter,
  } = useItineraryJourneyHeaderPresenter({ servicePresenter });

  const {
    openServiceCancelationAlertDialog,
    openServiceChoiceContextDialog,
    openServiceDeletionAlertDialog,
    openServiceOutOfPolicyDialog,
  } = useItineraryScreen();

  if (!itineraryJourneyHeaderPresenter) {
    return null;
  }

  const bookingFailedReason = itineraryJourneyHeaderPresenter.getBookingFailedReason();
  const changedPrice = itineraryJourneyHeaderPresenter.getChangedPrice();

  const showAsDenied = itineraryJourneyHeaderPresenter.showAsDenied({
    isApprovalReview,
    isSelected,
  });
  const showBookingFailedReasonBadge = itineraryJourneyHeaderPresenter.showBookingFailedReasonBadge();
  const showCancelServiceBadge = itineraryJourneyHeaderPresenter.showCancelServiceBadge(
    {
      isTravelPlan,
    },
  );
  const showChoiceContextBadge = itineraryJourneyHeaderPresenter.showChoiceContextBadge(
    {
      isApprovalReview,
      isUnavailable,
    },
  );
  const showCopyTokenButton = itineraryJourneyHeaderPresenter.showCopyOfferTokenButton();
  const showDeleteButton = itineraryJourneyHeaderPresenter.showDeleteButton({
    isTravelPlan,
  });
  const showDeniedOnApprovalBadge = itineraryJourneyHeaderPresenter.showDeniedOnApprovalBadge();
  const showErrorOnFetchOutOfPolicy = itineraryJourneyHeaderPresenter.showErrorOnFetchOutOfPolicy(
    { hasError: !!errorOnFetchTravelPoliciesPerOffer },
  );
  const showNegotiatedBadge = itineraryJourneyHeaderPresenter.showNegotiatedBadge();
  const showOutdatedBadge = itineraryJourneyHeaderPresenter.showOutdatedBadge();
  const showOutOfPolicyBadge = itineraryJourneyHeaderPresenter.showOutOfPolicyBadge();
  const showSmApprovesBadge = itineraryJourneyHeaderPresenter.showSmApprovesBadge(
    {
      isApprovalReview,
      isUnavailable,
    },
  );
  const showUnavailableBadge = itineraryJourneyHeaderPresenter.showUnavailableBadge();

  return (
    <div css={styles.root}>
      <div css={styles.top.root}>
        <ItinerarySegmentHeaderIcon
          isDenied={showAsDenied}
          isUnavailable={isUnavailable}
          servicePresenter={servicePresenter}
        />
        <ItinerarySegmentHeaderDescription
          isDenied={showAsDenied}
          isUnavailable={isUnavailable}
          servicePresenter={servicePresenter}
        />
        {showSmApprovesBadge ? <SmApprovesBadge /> : null}
        {showErrorOnFetchOutOfPolicy ? (
          <ErrorOnFetchOutOfPolicyBadge />
        ) : showOutOfPolicyBadge ? (
          <OutOfPolicyBadge
            onClick={() => {
              openServiceOutOfPolicyDialog(servicePresenter.getService());
            }}
          />
        ) : null}
        {showCopyTokenButton ? (
          <ItineraryJourneyHeaderCopyTokenButton
            offerToken={servicePresenter.getOfferToken()}
          />
        ) : null}
        {showDeleteButton ? (
          <ItinerayJourneyHeaderDeleteButton
            onClick={() => {
              openServiceDeletionAlertDialog(servicePresenter.getService());
            }}
          />
        ) : null}
      </div>
      {showCancelServiceBadge ||
      showNegotiatedBadge ||
      showChoiceContextBadge ? (
        <div css={styles.bottom.root}>
          {showNegotiatedBadge ? <NegotiatedFareBadge /> : null}
          {showCancelServiceBadge ? (
            <ItinerayJourneyHeaderCancelServiceBadge
              onClick={() => {
                openServiceCancelationAlertDialog(
                  servicePresenter.getService(),
                );
              }}
            />
          ) : null}
          {showChoiceContextBadge ? (
            <ItinerayJourneyHeaderChoiceContextBadge
              onClick={() => {
                openServiceChoiceContextDialog(servicePresenter.getService());
              }}
            />
          ) : null}
        </div>
      ) : null}
      {showBookingFailedReasonBadge ||
      showDeniedOnApprovalBadge ||
      showOutdatedBadge ||
      showUnavailableBadge ? (
        <div css={styles.bottom.root}>
          {showDeniedOnApprovalBadge ? (
            <ItinerayJourneyHeaderDeniedOnApprovalBadge />
          ) : null}
          {showOutdatedBadge ? <ItinerayJourneyHeaderOutdatedBadge /> : null}
          {showUnavailableBadge ? (
            <ItinerayJourneyHeaderUnavailableBadge />
          ) : null}
          {showBookingFailedReasonBadge ? (
            <ItineraryServiceBookingFailed
              bookingFailedReason={bookingFailedReason!}
              changedPrice={changedPrice}
              type={servicePresenter.getType()}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

type ItinerarySegmentHeaderDescriptionProps = React.HTMLAttributes<HTMLSpanElement> & {
  isDenied?: boolean;
  isUnavailable?: boolean;
  servicePresenter: ItineraryServicePresenter;
};

const ItinerarySegmentHeaderDescription: React.FC<ItinerarySegmentHeaderDescriptionProps> = ({
  isDenied,
  isUnavailable,
  servicePresenter,
  ...props
}) => {
  return (
    <span
      css={styles.description({
        isDenied,
        isUnavailable,
      })}
      {...props}
    >
      {isUnavailable ? "Indisponível: " : isDenied ? "Negado: " : null}
      {servicePresenter.getFormattedItineraryServiceHeaderName()}:
    </span>
  );
};

type ItinerarySegmentHeaderIconProps = {
  isDenied?: boolean;
  isUnavailable?: boolean;
  servicePresenter: ItineraryServicePresenter;
};

const ItinerarySegmentHeaderIcon: React.FC<ItinerarySegmentHeaderIconProps> = ({
  isDenied,
  isUnavailable,
  servicePresenter,
}) => {
  return (
    <div css={styles.icon({ isDenied, isUnavailable })}>
      <Icon size={16} use={servicePresenter.getIcon()} />
    </div>
  );
};
