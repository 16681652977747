import React, { createContext, useContext, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";

import * as hotelReviewService from "./HotelReview.service";
import { ReviewFormData } from "./HotelReview.types";

interface PageState {
  isSubmitting: boolean;
}

interface PageActions {
  handleFormSubmit: (
    offerToken: string,
    onSuccess?: any,
  ) => (formData: ReviewFormData) => void;
}

const initialPageState = {
  isSubmitting: false,
};

const HotelReviewContext = createContext({} as PageState);
const HotelReviewActionsContext = createContext({} as PageActions);

export const useHotelReviewActionsContext = () =>
  useContext(HotelReviewActionsContext);

export const useHotelReviewContext = () => useContext(HotelReviewContext);

export const HotelReviewProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [pageState, setPageState] = useState(initialPageState);

  const handleFormSubmit = (offerToken: string, onSuccess?: any) => async (
    formData: ReviewFormData,
  ) => {
    setPageState({ isSubmitting: true });

    const { data, error } = await hotelReviewService.submitHotelReview(
      offerToken,
      formData,
    );

    if (error) {
      setPageState({ isSubmitting: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (onSuccess) {
      onSuccess(data);
    }

    setPageState({ isSubmitting: false });

    showSnackMessage("Avaliação enviada com sucesso!", ALERT_TYPES.SUCCESS);
  };

  return (
    <HotelReviewContext.Provider value={pageState}>
      <HotelReviewActionsContext.Provider
        value={{
          handleFormSubmit,
        }}
      >
        {children}
      </HotelReviewActionsContext.Provider>
    </HotelReviewContext.Provider>
  );
};
