import React, { useCallback, useEffect, useState } from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { Sheet } from "~/apps/shared/components/sheet/sheet";

import { Button } from "@toolkit/v2";

import { useFlights } from "../../flights.context";
import { AirlinesFilter } from "./airlines-filter/airlines-filter";
import { BaggageFilter } from "./baggage-filter/baggage-filter";
import { DurationFilter } from "./duration-filter/duration-filter";
import { HourFilter } from "./hour-filter/hour-filter";
import { PolicyFilter } from "./policy-filter/policy-filter";
import { SelectedClassOnlyFilter } from "./selected-class-only-filter/selected-class-only-filter";
import { StopsFilter } from "./stops-filter/stops-filter";
import { styles } from "./styles";

const DEFAULT_HOURS_RANGE: [number, number] = [0, 24 * 3600];

type Props = {
  isMobile?: boolean;
};

export const ContentFilters: React.FC<Props> = ({ isMobile }) => {
  const {
    availableCarriers,
    baggage,
    durationFilter,
    flightInfo,
    generalInfo,
    handleBaggageToggle,
    handleBoundFilterChange,
    handleCarrierToggle,
    handleDurationFilterChange,
    handlePolicyToggle,
    handleSelectedClassOnlyToggle,
    handleStopToggle,
    inboundFilter,
    isLoading,
    onlyInPolicy,
    outboundFilter,
    selectedClassOnly,
    stops,
  } = useFlights();

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleCloseSheet = useCallback(() => {
    setIsSheetOpen(false);
  }, []);

  const handleOpenSheet = useCallback(() => {
    setIsSheetOpen(true);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);

      return;
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        <Button
          css={styles.sheet.trigger.root}
          fill="outlined"
          onClick={handleOpenSheet}
        >
          <Icon
            css={styles.sheet.trigger.icon}
            size={20}
            use="adjustments-horizontal"
          />
          Filtrar resultados
        </Button>
        <Sheet
          anchor="bottom"
          disableSwipeToOpen
          onClose={handleCloseSheet}
          onOpen={handleOpenSheet}
          open={isSheetOpen}
        >
          <div css={styles.sheet.root}>
            <PermissionProtectedRender permissions={["travels.policies"]}>
              <PolicyFilter
                handlePolicyToggle={handlePolicyToggle}
                onlyInPolicy={onlyInPolicy}
              />
            </PermissionProtectedRender>
            {flightInfo?.international ? (
              <SelectedClassOnlyFilter
                handleSelectedClassOnlyToggle={handleSelectedClassOnlyToggle}
                selectedClassOnly={selectedClassOnly}
              />
            ) : null}
            <StopsFilter
              handleStopToggle={handleStopToggle}
              loading={isLoading}
              stops={stops}
              stopsPriceMap={generalInfo.stopPrices}
            />
            <DurationFilter
              disabled={isLoading}
              durationFilter={durationFilter}
              handleDurationFilterChange={handleDurationFilterChange}
            />
            {flightInfo?.international ? (
              <BaggageFilter
                baggage={baggage}
                handleBaggageToggle={handleBaggageToggle}
              />
            ) : null}
            <AirlinesFilter
              availableCarriers={availableCarriers}
              carriersPriceMap={generalInfo.carrierPrices}
              handleCarrierToggle={handleCarrierToggle}
              loading={isLoading}
            />
            <HourFilter
              flightInfo={flightInfo}
              handleBoundFilterChange={handleBoundFilterChange}
              hoursRange={DEFAULT_HOURS_RANGE}
              inboundFilter={inboundFilter}
              loading={isLoading}
              outboundFilter={outboundFilter}
            />
          </div>
        </Sheet>
      </>
    );
  }

  return (
    <div css={styles.root}>
      <PermissionProtectedRender permissions={["travels.policies"]}>
        <PolicyFilter
          handlePolicyToggle={handlePolicyToggle}
          onlyInPolicy={onlyInPolicy}
        />
      </PermissionProtectedRender>
      {flightInfo?.international ? (
        <SelectedClassOnlyFilter
          handleSelectedClassOnlyToggle={handleSelectedClassOnlyToggle}
          selectedClassOnly={selectedClassOnly}
        />
      ) : null}
      <StopsFilter
        handleStopToggle={handleStopToggle}
        loading={isLoading}
        stops={stops}
        stopsPriceMap={generalInfo.stopPrices}
      />
      <DurationFilter
        disabled={isLoading}
        durationFilter={durationFilter}
        handleDurationFilterChange={handleDurationFilterChange}
      />
      {flightInfo?.international ? (
        <BaggageFilter
          baggage={baggage}
          handleBaggageToggle={handleBaggageToggle}
        />
      ) : null}
      <AirlinesFilter
        availableCarriers={availableCarriers}
        carriersPriceMap={generalInfo.carrierPrices}
        handleCarrierToggle={handleCarrierToggle}
        loading={isLoading}
      />
      <HourFilter
        flightInfo={flightInfo}
        handleBoundFilterChange={handleBoundFilterChange}
        hoursRange={DEFAULT_HOURS_RANGE}
        inboundFilter={inboundFilter}
        loading={isLoading}
        outboundFilter={outboundFilter}
      />
    </div>
  );
};
