import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  left: {
    avatar: css({
      borderRadius: "50%",
      minWidth: "32px !important",
      "& > :first-of-type": {
        flexShrink: 0,
        height: "32px !important",
        width: "32px !important",
      },
    }),
    info: {
      boss: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        lineHeight: "1.125rem",
      }),
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        lineHeight: "1.125rem",
      }),
      name: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 700,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
      }),
    },
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "12px",
    }),
  },
  right: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "24px",
    }),
  },
  root: css({
    alignItems: "center",
    backgroundColor: theme.colors.background.gray,
    borderRadius: "8px",
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    padding: "8px 16px",
    ":not([aria-disabled])": {
      cursor: "pointer",
    },
  }),
};
