import React, { ReactNode, useMemo, useEffect } from "react";

import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import isEmpty from "lodash/isEmpty";
import { Moment } from "moment";

import {
  EXPENSE_APPROVAL_STATUS,
  EXPENSE_REPORT_STATUS,
  PROJECT_ENABLING_STATE,
  EXPENSE_REPORT_TYPE,
} from "@constants";

import * as expensesHelper from "@helpers/expense.helper";
import { getUserFromLocalStorage } from "@helpers/user.helper";

import { Client } from "@models/client.model";
import { CostCenterListByUserItem } from "@models/cost-center.model";
import {
  ExpenseReport,
  Expense,
  ExpenseReportApprovalStatus,
  ExpensesPolicies,
} from "@models/expense.model";
import { UserModel } from "@models/user.model";

import { CustomError, ImageFile } from "~/types";

import { useContextFactory, useSafeState } from "@hooks";

import * as reportApprovaReviewService from "./ReportApprovalReview.service";
import { ApprovalResponseStatus } from "./ReportApprovalReview.types";

interface IAdministrativeItem {
  [key: string]: any;
  name: string;
}

interface Project {
  projectToken: string;
  billingProfileToken: string;
  name: string;
  active: boolean;
}

interface State {
  report: ExpenseReport | null;
  expensesData: Expense[];
  approvalStatus: ExpenseReportApprovalStatus | null;
  approvalResponseStatus: ApprovalResponseStatus;
  expensesPolicies: ExpensesPolicies;
  traveler: UserModel | null;
  clientInfo: Client | null;
  reportPeriod: {
    start: Moment;
    end: Moment;
  } | null;
  declineMessage: string;
  isDeclineFormVisible: boolean;
  isExpenseDrawerOpen: boolean;
  selectedExpenseToEdit: Expense | null;
  selectedReceiptToEdit: ImageFile | null;
  loading: boolean;
  respondingApprove: boolean;
  respondingDeny: boolean;
  selectedExpenseToShowPolicy: Expense | null;
  isDownloadingInvoices: boolean;
}

interface Actions {
  fetchReportData: (reportToken: string) => void;
  openExpenseDrawer: (selectedExpense?: Expense | null) => void;
  handleCloseExpenseDrawer: () => void;
  handleChangeExpenseApprovedValue: (
    expenseToken: string,
  ) => (approvedValue: number) => void;
  handleApproveRequest: () => void;
  handleDeclineRequest: () => void;
  handleOpenDeclineForm: () => void;
  handleCloseDeclineForm: () => void;
  handleChangeDeclineMessage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleShowExpenseOutOfPolicyInfo: (expense: Expense) => () => void;
  handleClosePolicyDialog: () => void;
  handleDownloadInvoices: () => void;
  onAfterSaveExpense: () => void;
}

type ContextProps = State & Actions;

interface ReportCategoryForm {
  areas: Array<{
    companyAreaToken: string;
    name: string;
  }>;
  allProjects: Project[];
  billingProfiles: Array<{
    billingProfileToken: string;
    name: string;
    cnpj: string;
  }>;
  costCenters: CostCenterListByUserItem[];
  selectedArea: string;
  selectedProject: string;
  selectedBillingProfile: string;
  selectedCostCenter: CostCenterListByUserItem | null;
  isCategoryFormVisible: boolean;
}

interface ReportCategoryFormActions {
  handleChangeCostCenter: (costCenter: CostCenterListByUserItem) => void;
  handleChangeData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isCostCenterNeeded: () => boolean;
  isCompanyAreaNeeded: () => boolean;
  isBillingProfileNeeded: () => boolean;
  isProjectNeeded: () => boolean;
  shouldCostCenterSelectBeVisible: () => boolean;
  shouldBillingProfileSelectBeVisible: () => boolean;
  shouldProjectSelectBeVisible: () => boolean;
}

interface ReportCategoryFormSelectors {
  isCostCenterLimitExtrapolated: boolean;
  canProcessApproval: boolean;
  projects: Project[];
  administrativeInfo: Record<string, IAdministrativeItem | undefined>;
}

const initialState: State = {
  report: null,
  expensesData: [],
  approvalStatus: null,
  approvalResponseStatus: "UNCHANGED",
  expensesPolicies: {},
  traveler: null,
  clientInfo: null,
  reportPeriod: null,
  declineMessage: "",
  isDeclineFormVisible: false,
  isExpenseDrawerOpen: false,
  selectedExpenseToEdit: null,
  selectedReceiptToEdit: null,
  loading: false,
  respondingApprove: false,
  respondingDeny: false,
  selectedExpenseToShowPolicy: null,
  isDownloadingInvoices: false,
};

const categoryFormInitialState: ReportCategoryForm = {
  areas: [],
  billingProfiles: [],
  allProjects: [],
  costCenters: [],
  selectedArea: "",
  selectedBillingProfile: "",
  selectedProject: "",
  selectedCostCenter: null,
  isCategoryFormVisible: false,
};

const ReportApprovalReviewContext = React.createContext<ContextProps>(
  {} as ContextProps,
);

const ReportCategoryFormContext = React.createContext(
  {} as ReportCategoryForm & ReportCategoryFormSelectors,
);
const ReportCategoryFormActionsContext = React.createContext(
  {} as ReportCategoryFormActions,
);

export const useReportCategoryFormContext = useContextFactory(
  "ReportCategoryFormContext",
  ReportCategoryFormContext,
);
export const useReportCategoryFormActionsContext = useContextFactory(
  "ReportCategoryFormActionsContext",
  ReportCategoryFormActionsContext,
);

const { Provider, Consumer } = ReportApprovalReviewContext;

const ReportApprovalReviewProvider = ({
  children,
}: {
  children: Element | ReactNode;
}) => {
  const { showSnackMessage, removeReportFromPendingList } = useApplication();
  const { clientConfig } = useClientConfig();
  const { user } = useUser();

  const [state, setState] = useSafeState<State>(initialState);
  const [categoryState, setCategoryState] = useSafeState(
    categoryFormInitialState,
  );

  const setErrorState = (error?: CustomError) => {
    if (error) {
      setState({
        loading: false,
        respondingApprove: false,
        respondingDeny: false,
      });
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }
  };

  const throwRedirectError = async (error?: CustomError) => {
    if (error) {
      await navigate("/expenses/reports");
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }
  };

  const fetchReportData = async (reportToken: string) => {
    if (!user) {
      return;
    }

    setState({ loading: true });

    const [
      { data: report, error: reportError },
      { data: approvalStatus, error: approvalStatusError },
      { data: policiesInfo, error: policiesError },
    ] = await Promise.all([
      reportApprovaReviewService.getReportByToken(reportToken),
      reportApprovaReviewService.getExpenseReportApprovalStatus(reportToken),
      reportApprovaReviewService.getReportExpensesPolicies(reportToken),
    ]);

    if (reportError || approvalStatusError || policiesError) {
      return throwRedirectError(
        reportError || approvalStatusError || policiesError,
      );
    }

    // checks if the user can acess page
    const accessError: CustomError = { title: "Sem acesso", description: "" };

    const currentStageApprovers = approvalStatus?.expenseApprovers.filter(
      (approver) => approver.stage === approvalStatus.stage,
    );

    const isUserAmongCurrentStageApprovers = currentStageApprovers?.some(
      (approver) => approver.userToken === user.getUserToken(),
    );

    if (!isUserAmongCurrentStageApprovers && !user.isMasterApprover()) {
      accessError.description =
        "Você não faz parte dos aprovadores do nível do relatório solicitado";

      return throwRedirectError(accessError);
    }

    const {
      data: traveler,
      error: travelerError,
    } = await reportApprovaReviewService.getTraveler(report!.travelerToken);

    if (travelerError) {
      return throwRedirectError(travelerError);
    }

    const [
      { data: areas, error: areasError },
      { data: billingProfiles, error: billingProfilesError },
      { data: allProjects, error: projectsError },
      { data: costCenters, error: costCentersError },
    ] = await Promise.all([
      reportApprovaReviewService.getUserAreas(report!.travelerToken),
      reportApprovaReviewService.getBillingProfiles(traveler!.userToken),
      reportApprovaReviewService.getUserProjects(report!.travelerToken),
      reportApprovaReviewService.getTravelerCostCenters(report!.travelerToken),
    ]);

    const categoryDataError =
      areasError || billingProfilesError || projectsError || costCentersError;
    if (categoryDataError) {
      return setErrorState(categoryDataError);
    }

    const { periodStart, periodEnd } = expensesHelper.calculateExpensesPeriod(
      report!.expenses,
    );

    const expensesData = expensesHelper.getExpensesWithUpdatedApprovedValues(
      approvalStatus!,
      report!.expenses,
    );

    setState({
      loading: false,
      report,
      expensesData,
      approvalStatus,
      expensesPolicies: policiesInfo,
      traveler,
      reportPeriod: {
        start: periodStart,
        end: periodEnd,
      },
    });

    setCategoryState({
      areas,
      billingProfiles,
      allProjects,
      costCenters,
    });
  };

  const openExpenseDrawer = async (selectedExpense: Expense | null = null) => {
    setState({ loading: true });
    let receiptFile: ImageFile | null = null;

    if (selectedExpense && selectedExpense.receiptFile) {
      const {
        data: receipt,
        error: receiptFileError,
      } = await reportApprovaReviewService.getReceiptImageFile(
        selectedExpense.receiptFile,
      );

      if (receiptFileError) {
        return setErrorState(receiptFileError);
      }
      receiptFile = receipt ? receipt : null;
    }

    setState({
      loading: false,
      isExpenseDrawerOpen: true,
      selectedExpenseToEdit: selectedExpense,
      selectedReceiptToEdit: receiptFile,
    });
  };

  const handleCloseExpenseDrawer = () => {
    setState({
      isExpenseDrawerOpen: false,
      selectedExpenseToEdit: null,
      selectedReceiptToEdit: null,
    });
  };

  const handleChangeExpenseApprovedValue = (expenseToken: string) => (
    approvedValue: number,
  ) => {
    setState({
      expensesData: state.expensesData.map((expense) => {
        if (expense.expenseToken === expenseToken) {
          expense.approvedValue = approvedValue;
        }

        return expense;
      }),
    });
  };

  const handleApproveRequest = () => {
    const { report, approvalStatus } = state;
    const { isCategoryFormVisible } = categoryState;
    const isSmartripsReport =
      !!report && report.reportType === EXPENSE_REPORT_TYPE.SMARTRIPS;
    const isPaymentStep =
      approvalStatus!.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT;

    const isSomeCategoryNeeded =
      isCompanyAreaNeeded() ||
      shouldCostCenterSelectBeVisible() ||
      shouldBillingProfileSelectBeVisible() ||
      shouldProjectSelectBeVisible();

    if (isSmartripsReport || !isSomeCategoryNeeded || isPaymentStep) {
      handleApproval();
    } else if (!isCategoryFormVisible) {
      setCategoryState({ isCategoryFormVisible: true });
    } else if (canProcessApproval) {
      handleApproval();
    }
  };

  const getUpdatedApprovalStatus = (
    oldApprovalStatus: ExpenseReportApprovalStatus,
    approved = true,
  ) => {
    const loggedUser = getUserFromLocalStorage();
    const updatedApprovalStatus = expensesHelper.insertNewApproverHistoryRow(
      oldApprovalStatus!,
      loggedUser!,
      approved,
    );

    return updatedApprovalStatus;
  };

  const handleApproval = () => {
    const { approvalStatus } = state;

    const isBeingPaid =
      approvalStatus!.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT;

    if (isBeingPaid) {
      void processSignalPayment();
    } else {
      void processApproval();
    }
  };

  const processApproval = async () => {
    setState({ respondingApprove: true });

    const { expensesData, approvalStatus, report } = state;
    const {
      selectedArea,
      selectedBillingProfile,
      selectedCostCenter,
      selectedProject,
    } = categoryState;
    const selectedCostCenterToken = selectedCostCenter
      ? selectedCostCenter.token
      : "";

    const {
      error,
    } = await reportApprovaReviewService.approveExpenseReportApprovalRequest(
      approvalStatus!.approvableApprovalRequestToken,
      expensesData,
      selectedArea,
      selectedBillingProfile,
      selectedCostCenterToken,
      selectedProject,
    );

    if (error) {
      return setErrorState(error);
    }

    const updatedApprovalStatus = getUpdatedApprovalStatus(approvalStatus!);

    setState({
      respondingApprove: false,
      approvalStatus: updatedApprovalStatus,
      approvalResponseStatus: "APPROVED",
    });
    showSnackMessage("Relatório aprovado com sucesso!", ALERT_TYPES.SUCCESS);

    removeReportFromPendingList(report!.expenseReportToken);
  };

  const processSignalPayment = async () => {
    setState({ respondingApprove: true });
    const { report, approvalStatus } = state;

    const { error } = await reportApprovaReviewService.signalReportPayment(
      report!.expenseReportToken,
    );

    if (error) {
      return setErrorState(error);
    }

    const updatedApprovalStatus = getUpdatedApprovalStatus(approvalStatus!);

    setState({
      respondingApprove: false,
      report: Object.assign({}, report, {
        status: EXPENSE_REPORT_STATUS.CLOSED,
      }),
      approvalStatus: Object.assign({}, updatedApprovalStatus, {
        status: EXPENSE_APPROVAL_STATUS.PAID,
      }),
      approvalResponseStatus: "PAID",
    });
    showSnackMessage("Relatório pago com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const handleDeclineRequest = async () => {
    setState({ respondingDeny: true });

    const { report, approvalStatus, declineMessage } = state;

    const {
      error,
    } = await reportApprovaReviewService.denyExpenseReportApprovalRequest(
      approvalStatus!.approvableApprovalRequestToken,
      declineMessage,
    );

    if (error) {
      return setErrorState(error);
    }

    const updatedApprovalStatus = getUpdatedApprovalStatus(
      approvalStatus!,
      false,
    );

    setState({
      respondingDeny: false,
      report: Object.assign({}, state.report, {
        status: EXPENSE_REPORT_STATUS.DECLINED,
      }),
      approvalStatus: Object.assign({}, updatedApprovalStatus, {
        status: EXPENSE_APPROVAL_STATUS.DECLINED,
      }),
      approvalResponseStatus: "DECLINED",
    });

    showSnackMessage("Relatório negado com sucesso", ALERT_TYPES.SUCCESS);
    removeReportFromPendingList(report!.expenseReportToken);
  };

  const handleOpenDeclineForm = () => {
    setState({ isDeclineFormVisible: true });
    setCategoryState({ isCategoryFormVisible: false });
  };

  const handleCloseDeclineForm = () =>
    setState({ isDeclineFormVisible: false });

  const handleChangeDeclineMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({
      declineMessage: e.currentTarget.value,
    });

  const handleShowExpenseOutOfPolicyInfo = (expense: Expense) => () => {
    setState({
      selectedExpenseToShowPolicy: expense,
    });
  };

  const handleClosePolicyDialog = () => {
    setState({ selectedExpenseToShowPolicy: null });
  };

  const handleDownloadInvoices = async () => {
    const { report, isDownloadingInvoices } = state;

    if (isDownloadingInvoices) {
      return;
    }

    setState({ isDownloadingInvoices: true });
    const {
      error: downloadError,
    } = await reportApprovaReviewService.downloadExpenseReportInvoices(
      report!.expenseReportToken,
    );

    if (downloadError) {
      showSnackMessage(downloadError.description, ALERT_TYPES.ERROR);
    }

    setState({ isDownloadingInvoices: false });
  };

  const onAfterSaveExpense = () => {
    setState({
      isExpenseDrawerOpen: false,
      selectedExpenseToEdit: null,
      selectedReceiptToEdit: null,
    });

    showSnackMessage("Despesa editada com sucesso", ALERT_TYPES.SUCCESS);
    updateExpenses();
  };

  const updateExpenses = async () => {
    setState({ loading: true });
    const { approvalStatus, report } = state;

    const {
      data: reportData,
      error,
    } = await reportApprovaReviewService.getReportByToken(
      report!.expenseReportToken,
    );

    if (error) {
      setState({ loading: false });
      return showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    const { periodStart, periodEnd } = expensesHelper.calculateExpensesPeriod(
      reportData!.expenses,
    );

    const expensesData = expensesHelper.getExpensesWithUpdatedApprovedValues(
      approvalStatus!,
      reportData!.expenses,
    );

    setState({
      loading: false,
      expensesData,
      reportPeriod: {
        start: periodStart,
        end: periodEnd,
      },
    });
  };

  // Category form state related

  const handleChangeCostCenter = (costCenter: CostCenterListByUserItem) => {
    setCategoryState({
      selectedCostCenter: costCenter,
    });
  };

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCategoryState({
      [name]: value,
    });
  };

  const isCostCenterNeeded = () => {
    const { report } = state;

    if (clientConfig && !clientConfig.isCompanyCostCenterEnabled()) {
      return false;
    }

    return (
      !!report &&
      (report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT)
    );
  };

  const shouldCostCenterSelectBeVisible = () => {
    return isCostCenterNeeded();
  };

  const isBillingProfileNeeded = () => {
    const { report } = state;

    return (
      !!report &&
      (report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT)
    );
  };

  const shouldBillingProfileSelectBeVisible = () => {
    const { billingProfiles } = categoryState;

    return isBillingProfileNeeded() && billingProfiles.length > 1;
  };

  const isCompanyAreaNeeded = () => {
    const { report } = state;

    if (clientConfig && !clientConfig.isCompanyAreaEnabled()) {
      return false;
    }

    return (
      !!report &&
      (report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT)
    );
  };

  const isProjectNeeded = () => {
    const { report } = state;

    if (!clientConfig) {
      return false;
    }

    const projectEnablingState = clientConfig.getProjectEnablingState();

    return (
      !!report &&
      (report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT) &&
      projectEnablingState === PROJECT_ENABLING_STATE.REQUIRED
    );
  };

  const shouldProjectSelectBeVisible = () => {
    const { report } = state;

    if (!clientConfig) {
      return false;
    }

    const projectEnablingState = clientConfig.getProjectEnablingState();

    return (
      !!report &&
      (report.status === EXPENSE_REPORT_STATUS.PENDING_APPROVAL ||
        report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT) &&
      projectEnablingState !== PROJECT_ENABLING_STATE.OFF
    );
  };

  const isCostCenterLimitExtrapolated = useMemo(() => {
    const { selectedCostCenter } = categoryState;

    return (
      !!selectedCostCenter &&
      (!!selectedCostCenter.budgetExtrapolated ||
        !!selectedCostCenter.vaultExtrapolated)
    );
  }, [categoryState.selectedCostCenter]);

  const projects = useMemo(() => {
    const { selectedBillingProfile, allProjects } = categoryState;

    return allProjects
      ? allProjects.filter((proj) => {
          return (
            proj.billingProfileToken === selectedBillingProfile ||
            !proj.billingProfileToken
          );
        })
      : [];
  }, [categoryState.selectedBillingProfile]);

  const canProcessApproval = useMemo(() => {
    const {
      selectedArea,
      selectedBillingProfile,
      selectedCostCenter,
      selectedProject,
    } = categoryState;

    const isCostCenterOk = isCostCenterNeeded()
      ? !!selectedCostCenter &&
        !selectedCostCenter.budgetExtrapolated &&
        !selectedCostCenter.vaultExtrapolated
      : true;
    const isAreaOk = isCompanyAreaNeeded() ? !!selectedArea : true;
    const isBillingProfileOk = isBillingProfileNeeded()
      ? !!selectedBillingProfile
      : true;
    const isProjectOk = isProjectNeeded() ? !!selectedProject : true;

    return isCostCenterOk && isAreaOk && isBillingProfileOk && isProjectOk;
  }, [
    categoryState.selectedArea,
    categoryState.selectedBillingProfile,
    categoryState.selectedCostCenter,
    categoryState.selectedProject,
  ]);

  const administrativeInfo = useMemo(() => {
    const {
      companyAreaToken,
      billingProfileToken,
      costCenterToken,
      projectToken,
    } = state.report || {};

    const { areas, billingProfiles, costCenters, allProjects } = categoryState;

    const companyArea = areas.find(
      (item) => item.companyAreaToken === companyAreaToken,
    );
    const billingProfile = billingProfiles.find(
      (item) => item.billingProfileToken === billingProfileToken,
    );
    const costCenter = costCenters.find(
      (item) => item.token === costCenterToken,
    );
    const project = allProjects.find(
      (item) => item.projectToken === projectToken,
    );

    return {
      companyArea,
      billingProfile,
      costCenter,
      project,
    };
  }, [
    state.report?.companyAreaToken,
    state.report?.billingProfileToken,
    state.report?.costCenterToken,
    state.report?.projectToken,
    categoryState.areas,
    categoryState.billingProfiles,
    categoryState.costCenters,
    categoryState.allProjects,
  ]);

  useEffect(() => {
    const { report, loading } = state;
    const hasLoaded = !loading && !!report;

    if (hasLoaded && !isEmpty(clientConfig)) {
      const {
        areas,
        billingProfiles,
        costCenters,
        allProjects,
      } = categoryState;

      if (!clientConfig) {
        return;
      }

      const companyAreaEnabled = clientConfig.isCompanyAreaEnabled();
      const costCenterActive = clientConfig.isCompanyCostCenterEnabled();
      const projectEnablingState = clientConfig.getProjectEnablingState();

      const activeProjects = allProjects.filter((project) => project.active);
      const isProjectRequired =
        projectEnablingState === PROJECT_ENABLING_STATE.REQUIRED;

      const selectedArea = expensesHelper.getSelectedCompanyArea(
        areas,
        report!,
        companyAreaEnabled,
      );
      const selectedBillingProfile = expensesHelper.getSelectedBillingProfile(
        billingProfiles,
        report!,
      );
      const selectedCostCenter = expensesHelper.getSelectedCostCenter(
        costCenters,
        report!,
        costCenterActive,
      );
      const selectedProject = expensesHelper.getSelectedProject(
        activeProjects,
        report!,
        isProjectRequired,
      );

      setCategoryState({
        selectedArea,
        selectedBillingProfile,
        selectedCostCenter,
        selectedProject,
      });
    }
  }, [
    categoryState.areas,
    categoryState.billingProfiles,
    categoryState.costCenters,
    categoryState.allProjects,
  ]);

  // if user changes billing profile, we reset the selected project
  useEffect(() => {
    const {
      allProjects,
      selectedBillingProfile,
      selectedProject: selectedProjectToken,
    } = categoryState;
    const selectedProject = allProjects.find(
      (proj) => proj.projectToken === selectedProjectToken,
    );

    const doesProjectBelongToBP =
      selectedBillingProfile &&
      selectedProject &&
      (selectedProject.billingProfileToken === selectedBillingProfile ||
        !selectedProject.billingProfileToken);

    if (!doesProjectBelongToBP) {
      setCategoryState({
        selectedProject: "",
      });
    }
  }, [categoryState.selectedBillingProfile]);

  return (
    <Provider
      value={{
        ...state,
        fetchReportData,
        openExpenseDrawer,
        handleCloseExpenseDrawer,
        handleChangeExpenseApprovedValue,
        handleApproveRequest,
        handleDeclineRequest,
        handleOpenDeclineForm,
        handleCloseDeclineForm,
        handleChangeDeclineMessage,
        handleShowExpenseOutOfPolicyInfo,
        handleClosePolicyDialog,
        handleDownloadInvoices,
        onAfterSaveExpense,
      }}
    >
      <ReportCategoryFormContext.Provider
        value={{
          ...categoryState,
          isCostCenterLimitExtrapolated,
          canProcessApproval,
          projects,
          administrativeInfo,
        }}
      >
        <ReportCategoryFormActionsContext.Provider
          value={{
            handleChangeCostCenter,
            handleChangeData,
            isCostCenterNeeded,
            isCompanyAreaNeeded,
            isBillingProfileNeeded,
            isProjectNeeded,
            shouldCostCenterSelectBeVisible,
            shouldBillingProfileSelectBeVisible,
            shouldProjectSelectBeVisible,
          }}
        >
          {children}
        </ReportCategoryFormActionsContext.Provider>
      </ReportCategoryFormContext.Provider>
    </Provider>
  );
};

export {
  ReportApprovalReviewContext,
  ReportApprovalReviewProvider,
  Consumer as ReportApprovalReviewConsumer,
};
