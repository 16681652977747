import React, { useMemo } from "react";
import * as utils from "@helpers";
import { useFlightInfoContext } from "../FlightInfo.context";
import { Paper } from "../../shared/Paper";
import { PaperTitle } from "../../shared/PaperTitle";
import { Flex, Box } from "@toolkit";
import { SpentByCompanyTable } from "./SpentByCompanyTable";
import { SpentByCompanyChart } from "./SpentByCompanyChart";
import orderBy from "lodash/orderBy";

const SpentByCompany = () => {
  const { spentByCompany } = useFlightInfoContext();
  const sortedData = useMemo(() => orderBy(spentByCompany, "total", "desc"), [
    spentByCompany
  ]);
  const tableColumns = useMemo(
    () => [
      {
        Header: "Companhia",
        accessor: "carrier",
        Cell: props => utils.capitalizeAllFirstLetters(props.value)
      },
      {
        Header: "Qtd.",
        accessor: "count"
      },
      {
        Header: "Total gasto",
        accessor: "total",
        Cell: props => utils.toCurrency(props.value)
      },
      {
        Header: "Gasto médio",
        accessor: "average",
        Cell: props => utils.toCurrency(props.value)
      }
    ],
    []
  );

  return (
    <Paper>
      <PaperTitle>Gasto por cia aérea</PaperTitle>
      <Flex
        flexDirection={["column-reverse", "column-reverse", "row"]}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box width={["100%", "100%", "66%"]} minHeight={[0, 0, 220]}>
          <SpentByCompanyTable data={sortedData} columns={tableColumns} />
        </Box>
        <Box width={["100%", "100%", "33%"]} mb={[24, 24, 0]}>
          <SpentByCompanyChart data={sortedData} />
        </Box>
      </Flex>
    </Paper>
  );
};

export { SpentByCompany };
