import { css } from "@styled-system/css";

import { MEDIA_QUERIES } from "~/constants";

export const styles = {
  tag: css({
    borderRadius: "16px",
    fontSize: "10px",
    fontWeight: 600,
    padding: "4px 8px",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      display: "none"
    }
  }),
  root: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
  })
};
