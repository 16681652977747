import moment from "moment";

import { PeriodParams } from "./analytics-report-download.types";

export const getPeriodFilterText = (periodParams: PeriodParams) => {
  const endDate = moment(
    new Date(periodParams.endYear, periodParams.endMonth - 1, 1),
  );
  const startDate = moment(
    new Date(periodParams.startYear, periodParams.startMonth - 1, 1),
  );

  return `${moment(startDate).format("MMM YYYY")} - ${moment(endDate).format(
    "MMM YYYY",
  )}`;
};
