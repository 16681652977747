import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDebounce } from "react-use/lib";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import {
  ALERT_TYPES,
  COMPANY_AREA_TYPES,
  DEFAULT_COMPANY_AREA_NAME,
} from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import * as areasService from "./areas.service";
import {
  AreaUser,
  FetchAreaResultDto,
  FetchClientAreaDto,
} from "./areas.types";

type State = {
  areas: FetchClientAreaDto[];
  drawerOpen: boolean;
  isLoading: boolean;
  searchInput: string;
  selectedArea:
    | (FetchAreaResultDto & {
        originalUsers: AreaUser[];
      })
    | null;
  selectedTab: "active" | "archived";
  visibleAreas: FetchClientAreaDto[];
};

const initialState: State = {
  areas: [],
  drawerOpen: false,
  isLoading: false,
  searchInput: "",
  selectedArea: null,
  selectedTab: "active",
  visibleAreas: [],
};

type AreasContextProps = State & {
  companyAreaDisplay: string;
  loadClientAreas: () => Promise<void>;
  toggleSelectedTab: () => void;
  handleCloseDrawer: () => void;
  handleOpenCreateArea: () => void;
  handleOpenEditArea: (companyAreaToken: string) => void;
  handleAddUserToArea: (user: AreaUser) => void;
  handleRemoveUserFromArea: (user: AreaUser) => void;
  handleSaveArea: (area: { areaName: string }) => void;
  handleChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleArchiveArea: (
    companyAreaToken: string,
    activeStatus: boolean,
  ) => void;
  handleToggleAreaRequired: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AreasContext = createContext({} as AreasContextProps);

export const AreasProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { clientConfig, handleUpdateCompanyAreaEnabled } = useClientConfig();

  const [state, setState] = useState(initialState);

  const companyAreaDisplay = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : DEFAULT_COMPANY_AREA_NAME;

  const isFirstRender = useRef(true);

  const loadClientAreas = useCallback(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data: areasData, error } = await areasService.fetchClientAreas();

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({ ...prev, isLoading: false }));

      return;
    }

    const areas = !areasData
      ? []
      : areasData.filter((area) => area.areaType === COMPANY_AREA_TYPES.AREA);

    setState((prev) => ({
      ...prev,
      areas,
      isLoading: false,
    }));
  }, [clientConfig, showSnackMessage]);

  const updateVisibleAreas = useCallback(() => {
    setState((prev) => ({
      ...prev,
      visibleAreas: prev.areas.filter(
        (area) =>
          area.name
            .toLocaleLowerCase()
            .includes(prev.searchInput.toLocaleLowerCase()) &&
          area.active === (prev.selectedTab === "active"),
      ),
    }));
  }, []);

  const toggleSelectedTab = useCallback(() => {
    setState((prev) => ({
      ...prev,
      selectedTab: prev.selectedTab === "active" ? "archived" : "active",
    }));

    updateVisibleAreas();
  }, [updateVisibleAreas]);

  const closeDrawerAndReset = useCallback(() => {
    setState((prev) => ({
      ...prev,
      drawerOpen: false,
      selectedArea: null,
    }));
  }, []);

  const handleCloseDrawer = useCallback(() => {
    closeDrawerAndReset();
  }, [closeDrawerAndReset]);

  const handleOpenCreateArea = useCallback(() => {
    setState((prev) => ({
      ...prev,
      drawerOpen: true,
    }));
  }, []);

  const handleOpenEditArea = useCallback(
    async (companyAreaToken: string) => {
      const { data, error } = await areasService.fetchArea(companyAreaToken);

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      if (!data) {
        showSnackMessage(
          `Erro ao buscar ${companyAreaDisplay}`,
          ALERT_TYPES.ERROR,
        );

        return;
      }

      setState((prev) => ({
        ...prev,
        drawerOpen: true,
        selectedArea: {
          originalUsers: data.users.sort((a, b) => {
            if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
              return -1;
            }
            if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          ...data,
        },
      }));
    },
    [clientConfig, companyAreaDisplay, showSnackMessage],
  );

  const handleToggleArchiveArea = useCallback(
    async (companyAreaToken: string, activeStatus: boolean) => {
      const { error } = await areasService.updateArea({
        active: activeStatus,
        companyAreaToken,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        areas: prev.areas.map((el) =>
          el.companyAreaToken !== companyAreaToken
            ? el
            : { ...el, active: activeStatus },
        ),
      }));
    },
    [showSnackMessage],
  );

  const handleAddUserToArea = useCallback(
    (user: AreaUser) => {
      const { selectedArea } = state;

      const users = selectedArea?.users || [];

      const userAlreadyIncluded = users.some(
        (el) => el.userToken === user.userToken,
      );

      if (userAlreadyIncluded) {
        showSnackMessage(
          `Usúario já adicionado na ${companyAreaDisplay}`,
          ALERT_TYPES.INFO,
        );

        return;
      }

      setState((prev) => ({
        ...prev,
        selectedArea: {
          ...prev.selectedArea!,
          users: [...users, user],
        },
      }));
    },
    [companyAreaDisplay, showSnackMessage, state],
  );

  const handleRemoveUserFromArea = useCallback(
    (user: AreaUser) => {
      const { selectedArea } = state;

      if (!selectedArea) {
        return;
      }

      const { users } = selectedArea;

      setState((prev) => ({
        ...prev,
        selectedArea: {
          ...prev.selectedArea!,
          users: users.filter((el) => el.userToken !== user.userToken),
        },
      }));
    },
    [state],
  );

  const createNewArea = useCallback(
    async (areaName: string) => {
      const { selectedArea } = state;

      const areaNameAlreadyExists = state.areas.some(
        (area) => area.name.toLowerCase() === areaName.toLowerCase(),
      );

      if (areaNameAlreadyExists) {
        showSnackMessage(
          `Nome de ${companyAreaDisplay} já existente.`,
          ALERT_TYPES.ERROR,
        );

        return;
      }

      const { data: newArea, error } = await areasService.createNewArea({
        area_type: COMPANY_AREA_TYPES.AREA,
        name: areaName,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      if (selectedArea && selectedArea.users.length > 0) {
        const { users } = selectedArea;

        await Promise.all(
          users.map((user) =>
            areasService.addUserToArea(
              user.userToken,
              newArea.companyAreaToken,
            ),
          ),
        );

        newArea.usersCount = users.length;
      }

      setState((prev) => ({
        ...prev,
        areas: [newArea, ...prev.areas],
      }));

      showSnackMessage(
        `${companyAreaDisplay} criada com sucesso`,
        ALERT_TYPES.SUCCESS,
      );
    },
    [companyAreaDisplay, showSnackMessage, state],
  );

  const updateArea = useCallback(
    async (areaName: string) => {
      const { selectedArea } = state;

      if (!selectedArea) {
        return;
      }

      const { companyAreaToken, name, originalUsers, users } = selectedArea;

      if (areaName !== name) {
        const { error } = await areasService.updateArea({
          companyAreaToken,
          name: areaName,
        });

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }
      }

      const usersToRemove = originalUsers.filter(
        (user) =>
          !users.some((el) => {
            return el.userToken === user.userToken;
          }),
      );

      const usersToAdd = users.filter(
        (user) => !originalUsers.some((el) => el.userToken === user.userToken),
      );

      if (usersToRemove.length) {
        const { error }: any = await Promise.all(
          usersToRemove.map((user) =>
            areasService.removeUserFromArea(user.userAreaToken),
          ),
        );

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }
      }

      if (usersToAdd.length) {
        const { error }: any = await Promise.all(
          usersToAdd.map((user) =>
            areasService.addUserToArea(user.userToken, companyAreaToken),
          ),
        );

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }
      }

      setState((prev) => ({
        ...prev,
        areas: prev.areas.map((area) => {
          return area.companyAreaToken !== companyAreaToken
            ? area
            : {
                ...area,
                name: areaName,
                usersCount:
                  area.usersCount - usersToRemove.length + usersToAdd.length,
              };
        }),
      }));

      showSnackMessage(
        `${companyAreaDisplay} atualizada com sucesso`,
        ALERT_TYPES.SUCCESS,
      );
    },
    [companyAreaDisplay, showSnackMessage, state],
  );

  const handleSaveArea = useCallback(
    async ({ areaName }: { areaName: string }) => {
      const { selectedArea } = state;

      if (!selectedArea || !selectedArea.companyAreaToken) {
        await createNewArea(areaName);
      } else {
        await updateArea(areaName);
      }

      closeDrawerAndReset();
    },
    [closeDrawerAndReset, createNewArea, state, updateArea],
  );

  const handleChangeSearchInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();

      setState((prev) => ({ ...prev, searchInput: e.target.value }));
    },
    [],
  );

  const handleToggleAreaRequired = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!clientConfig) {
        return;
      }

      handleUpdateCompanyAreaEnabled(e.target.checked);

      void areasService.updateCompanyAreaEnabled(
        clientConfig.getToken(),
        e.target.checked,
      );
    },
    [clientConfig, handleUpdateCompanyAreaEnabled],
  );

  useEffect(() => {
    updateVisibleAreas();
  }, [state.areas]);

  useDebounce(
    () => {
      if (!isFirstRender.current) {
        updateVisibleAreas();
      } else {
        isFirstRender.current = false;
      }
    },
    200,
    [state.searchInput],
  );

  return (
    <AreasContext.Provider
      value={{
        ...state,
        companyAreaDisplay,
        handleAddUserToArea,
        handleChangeSearchInput,
        handleCloseDrawer,
        handleOpenCreateArea,
        handleOpenEditArea,
        handleRemoveUserFromArea,
        handleSaveArea,
        handleToggleArchiveArea,
        handleToggleAreaRequired,
        loadClientAreas,
        toggleSelectedTab,
      }}
    >
      {children}
    </AreasContext.Provider>
  );
};

export const useAreas = useContextFactory("AreasContext", AreasContext);
