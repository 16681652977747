import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: theme.colors.background.gray,
    borderTop: `1px solid ${theme.colors.gray[100]}`,
    bottom: 0,
    display: "flex",
    gap: "16px",
    justifyContent: "flex-end",
    padding: "24px",
    position: "sticky",
    width: "100%",
    "@media screen and (max-width: 768px)": {
      padding: "16px",
    },
  }),
};
