import { ProjectItemResponse } from "../dtos/projects.dto";
import { User as parseUser } from "./user.parser";

export function parseProjectItem(data: ProjectItemResponse) {
  return {
    projectToken: data.project_token,
    clientToken: data.client_token,
    name: data.name,
    active: data.active,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    totalUsers: data.total_users,
    users: data.users.map(user => parseUser(user))
  };
}
