import * as yup from "yup";

const formSchema = yup.object().shape({
  satisfactionRate: yup
    .string()
    .required("Preecha o nível de satisfação com atendimento"),
  solved: yup
    .boolean()
    .typeError("Informe se o problema/solicitação foi resolvido")
    .required("Informe se o problema/solicitação foi resolvido"),
  userComment: yup.string()
});

export { formSchema };
