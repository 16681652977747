import React, { createContext, useState, useEffect, useRef } from "react";
import useDebounce from "react-use/lib/useDebounce";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { ALERT_TYPES, COMPANY_AREA_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import * as boardsService from "./boards.service";

interface Actions {
  closeDrawerAndReset: () => void;
  handleAddAreaToBoard: (area: any) => void;
  handleCreateBoard: () => void;
  handleOpenEditBoard: (companyAreaToken: string) => void;
  handleRemoveAreasFromBoard: (area: any) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loadClientBoards: () => void;
  handleSaveBoard: (data: { boardName: string }) => void;
  handleToggleArchiveBoard: (companyAreaToken: string, active: boolean) => void;
}

type State = {
  boards: any[];
  visibleBoards: any[];
  searchInput: string;
  drawerOpen: boolean;
  selectedBoard: any;
  isLoading: boolean;
};

const initialState: State = {
  boards: [],
  drawerOpen: false,
  isLoading: false,
  searchInput: "",
  selectedBoard: {
    originalSubAreas: [],
    subAreas: [],
  },
  visibleBoards: [],
};

type ContextProps = Actions & State;

const BoardContext = createContext({} as ContextProps);

export const BoardProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { clientConfig } = useClientConfig();

  const [state, setState] = useState(initialState);

  const isFirstRender = useRef(true);

  const companyAreaDisplay = clientConfig
    ? clientConfig.getCompanyAreaDisplay()
    : null;

  const companyBoardDisplay = clientConfig
    ? clientConfig.getCompanyBoardDisplay()
    : null;

  const loadClientBoards = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const { data: allAreas, error } = await boardsService.fetchClientBoards();

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return;
    }

    const boards = allAreas.filter(
      (area: any) => area.areaType === COMPANY_AREA_TYPES.BOARD && area.active,
    );

    setState((prevState) => ({
      ...prevState,
      boards,
      isLoading: false,
    }));
  };

  function compareByLowerCaseName(itemA: any, itemB: any) {
    if (itemA.name.toLowerCase() < itemB.name.toLowerCase()) {
      return -1;
    }
    if (itemA.name.toLowerCase() > itemB.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const handleOpenEditBoard = async (companyAreaToken: string) => {
    const { data: selectedBoard, error } = await boardsService.fetchBoard(
      companyAreaToken,
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);
      return;
    }

    setState((prevState) => ({
      ...prevState,
      drawerOpen: true,
      selectedBoard: {
        ...prevState.selectedBoard,
        originalSubAreas: selectedBoard.subAreas.sort(compareByLowerCaseName),
        ...selectedBoard,
      },
    }));
  };

  const handleToggleArchiveBoard = async (
    companyAreaToken: string,
    active: boolean,
  ) => {
    const { error } = await boardsService.updateBoard({
      active,
      companyAreaToken,
    });

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prevState) => ({
      ...prevState,
      boards: prevState.boards.map((el) =>
        el.companyAreaToken !== companyAreaToken ? el : { ...el, active },
      ),
    }));
  };

  const updateVisibleBoards = () => {
    setState((prevState) => ({
      ...prevState,
      visibleBoards: prevState.boards.filter(
        (board) =>
          board.name
            .toLocaleLowerCase()
            .includes(prevState.searchInput.toLocaleLowerCase()) &&
          board.active,
      ),
    }));
  };

  const closeDrawerAndReset = () => {
    setState((prevState) => ({
      ...prevState,
      drawerOpen: false,
      selectedBoard: {
        originalSubAreas: [],
        subAreas: [],
      },
    }));
  };

  const handleCreateBoard = () => {
    setState((prevState) => ({
      ...prevState,
      drawerOpen: true,
    }));
  };

  const handleAddAreaToBoard = (area: any) => {
    const { subAreas } = state.selectedBoard;

    const areaAlreadyIncluded = subAreas.some(
      (el: any) => el.companyAreaToken === area.companyAreaToken,
    );

    if (areaAlreadyIncluded) {
      showSnackMessage(
        `${companyAreaDisplay} já adicionada à ${companyBoardDisplay}`,
        ALERT_TYPES.SUCCESS,
      );

      return;
    }

    const areaAlreadyHasAnotherBoard = area.parentToken;
    if (areaAlreadyHasAnotherBoard) {
      showSnackMessage(
        `${companyAreaDisplay} já vinculada a outra ${companyBoardDisplay}`,
        ALERT_TYPES.SUCCESS,
      );
      return;
    }

    setState((prevState) => ({
      ...prevState,
      selectedBoard: {
        ...prevState.selectedBoard,
        subAreas: [...subAreas, area],
      },
    }));
  };

  const handleRemoveAreasFromBoard = (area: any) => {
    const { subAreas } = state.selectedBoard;

    setState((prevState) => ({
      ...prevState,
      selectedBoard: {
        ...prevState.selectedBoard,
        subAreas: subAreas.filter(
          (el: any) => el.companyAreaToken !== area.companyAreaToken,
        ),
      },
    }));
  };

  const createNewBoard = async (boardName: string) => {
    const { subAreas } = state.selectedBoard;

    const boardNameAlreadyExists = state.boards.some(
      (board) => board.name.toLowerCase() === boardName.toLowerCase(),
    );

    if (boardNameAlreadyExists) {
      showSnackMessage(
        `${companyBoardDisplay} com o mesmo nome já existente.`,
        ALERT_TYPES.ERROR,
      );

      return;
    }

    const { data: newBoard, error } = await boardsService.createNewBoard({
      area_type: COMPANY_AREA_TYPES.BOARD,
      name: boardName,
    });

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (subAreas.length) {
      await Promise.all(
        subAreas.map((area: any) =>
          boardsService.addAreaToBoard({
            companyAreaToken: area.companyAreaToken,
            parent_token: newBoard.companyAreaToken,
          }),
        ),
      );

      newBoard.subAreasCount = subAreas.length;
    }

    setState((prevState) => ({
      ...prevState,
      boards: [newBoard, ...prevState.boards],
    }));

    showSnackMessage(
      `${companyBoardDisplay} criada com sucesso`,
      ALERT_TYPES.SUCCESS,
    );
  };

  const updateBoard = async (boardName: string) => {
    const {
      companyAreaToken,
      name,
      originalSubAreas,
      subAreas,
    } = state.selectedBoard;

    if (boardName !== name) {
      const { error } = await boardsService.updateBoard({
        companyAreaToken,
        name: boardName,
      });

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }
    }

    const areasToRemove = originalSubAreas.filter(
      (area: any) =>
        !subAreas.some((el) => {
          return el.companyAreaToken === area.companyAreaToken;
        }),
    );

    const areasToAdd = subAreas.filter(
      (area: any) =>
        !originalSubAreas.some(
          (el) => el.companyAreaToken === area.companyAreaToken,
        ),
    );

    if (areasToRemove.length) {
      const { error } = await Promise.all(
        areasToRemove.map((area: any) =>
          boardsService.removeAreaFromBoard({
            companyAreaToken: area.companyAreaToken,
            parent_token: null,
          }),
        ),
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }
    }

    if (areasToAdd.length) {
      const { error } = await Promise.all(
        areasToAdd.map((area: any) =>
          boardsService.addAreaToBoard({
            companyAreaToken: area.companyAreaToken,
            parent_token: companyAreaToken,
          }),
        ),
      );

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }
    }

    setState((prevState) => ({
      ...prevState,
      boards: prevState.boards.map((board) => {
        return board.companyAreaToken !== companyAreaToken
          ? board
          : {
              ...board,
              name: boardName,
              subAreasCount:
                board.subAreasCount - areasToRemove.length + areasToAdd.length,
            };
      }),
    }));

    showSnackMessage(
      `${companyBoardDisplay} atualizada com sucesso`,
      ALERT_TYPES.SUCCESS,
    );
  };

  const handleSaveBoard = async ({ boardName }: any) => {
    const { companyAreaToken } = state.selectedBoard;

    if (!companyAreaToken) {
      await createNewBoard(boardName);
    } else {
      await updateBoard(boardName);
    }

    closeDrawerAndReset();
  };

  const handleSearch = (e) => {
    e.persist();

    setState((prevState) => ({ ...prevState, searchInput: e.target.value }));
  };

  useEffect(updateVisibleBoards, [state.boards]);

  useDebounce(
    () => {
      if (!isFirstRender.current) {
        updateVisibleBoards();
      } else {
        isFirstRender.current = false;
      }
    },
    200,
    [state.searchInput],
  );

  return (
    <BoardContext.Provider
      value={{
        ...state,
        closeDrawerAndReset,
        handleAddAreaToBoard,
        handleCreateBoard,
        handleOpenEditBoard,
        handleRemoveAreasFromBoard,
        handleSaveBoard,
        handleSearch,
        handleToggleArchiveBoard,
        loadClientBoards,
      }}
    >
      {children}
    </BoardContext.Provider>
  );
};

export const useBoards = useContextFactory("BoardContext", BoardContext);
