import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    fontSize: "0.875rem",
  }),
  content: {
    offers: {
      badges: {
        changes: {
          icon: css({}),
          root: css({
            backgroundColor: theme.colors.blue[300],
            borderColor: theme.colors.blue[300],
            color: theme.colors.gray[700],
            fontWeight: 500,
            gap: "16px",
            justifyContent: "flex-start",
            lineHeight: "1.125",
            padding: "16px 24px",
            width: "100%",
            ":hover": {
              backgroundColor: theme.colors.blue[300],
              borderColor: theme.colors.blue[300],
            },
          }),
          text: css({}),
        },
        error: {
          icon: css({}),
          root: css({
            backgroundColor: theme.colors.pink[300],
            borderColor: theme.colors.pink[300],
            color: theme.colors.gray[700],
            fontWeight: 500,
            gap: "16px",
            justifyContent: "flex-start",
            lineHeight: "1.125",
            padding: "16px 24px",
            width: "100%",
            ":hover": {
              backgroundColor: theme.colors.pink[300],
              borderColor: theme.colors.pink[300],
            },
          }),
          text: css({}),
        },
        unavailability: {
          icon: css({}),
          root: css({
            backgroundColor: "#ffe8ab",
            borderColor: "#ffe8ab",
            color: theme.colors.gray[700],
            fontWeight: 500,
            gap: "16px",
            justifyContent: "flex-start",
            lineHeight: "1.125",
            padding: "16px 24px",
            width: "100%",
            ":hover": {
              backgroundColor: "#ffe8ab",
              borderColor: "#ffe8ab",
            },
          }),
          text: css({}),
        },
      },
      offers: {
        list: {
          item: {
            root: css({
              alignItems: "center",
              color: theme.colors.gray[700],
              display: "flex",
              fontSize: "0.875rem",
              gap: "12px",
            }),
          },
          root: css({
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }),
        },
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
        title: css({
          fontSize: "0.875rem",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop: "16px",
      }),
      warning: {
        icon: css({}),
        root: css({
          backgroundColor: "#ffe8ab",
          borderColor: "#ffe8ab",
          color: "#806300",
          fontWeight: 500,
          gap: "16px",
          lineHeight: "1.125",
          padding: "16px",
          ":hover": {
            backgroundColor: "#ffe8ab",
            borderColor: "#ffe8ab",
          },
        }),
        text: css({}),
      },
    },
    root: css({}),
  },
  footer: css({
    flexWrap: "wrap-reverse",
    paddingTop: "24px",
  }),
  instructions: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    lineHeight: "1.25",
  }),
  message: css({
    color: theme.colors.gray[300],
    fontSize: "1rem",
    lineHeight: "1.25",
  }),
  offers: {
    offer: {
      name: css({
        color: theme.colors.gray[700],
      }),
      root: css({
        alignItems: "center",
        color: theme.colors.gray[700],
        display: "flex",
        gap: "12px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "16px 0 24px 0",
    }),
  },
  root: css({
    ".MuiPaper-root": {
      overflowY: "auto",
      "@media screen and (max-width: calc(512px + 12px * 2))": {
        minWidth: "unset",
        width: "100%",
      },
    },
  }),
  strong: css({
    fontWeight: 600,
  }),
};
