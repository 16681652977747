import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useItineraryScreen } from "../../itinerary-screen.context";
import { useItineraryHeaderShareTravelButtonGuard } from "./itinerary-header-share-travel-button.hooks";
import { styles } from "./styles";

export const ItineraryHeaderShareTravelButton: React.FC = () => {
  const { error, loading } = useItineraryHeaderShareTravelButtonGuard();

  const { openShareDialog } = useItineraryScreen();

  return (
    <Tooltip content="Compartilhar itinerário" position="top">
      <span css={styles.span}>
        <button
          css={styles.button}
          disabled={!!error || loading}
          onClick={() => {
            openShareDialog();
          }}
        >
          <Icon size={16} use="share-nodes" />
        </button>
      </span>
    </Tooltip>
  );
};
