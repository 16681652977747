import React from "react";
import { Box, Text } from "@toolkit";
import { css } from "@styled-system/css";

const sideTagStyle = css({
  display: "flex",
  alignItems: "center",
  position: "relative",
  ["::before"]: {
    content: "''",
    borderStyle: "solid",
    borderWidth: "14px 13px 14px 0",
    borderColor: "transparent",
    borderRightColor: "primary",
    width: 0,
    height: 0,
    position: "absolute",
    left: "-13px",
    top: 0
  }
});

const GRADES = [
  { minRange: 0, maxRange: 2, label: "Horrível" },
  { minRange: 2, maxRange: 4, label: "Ruim" },
  { minRange: 4, maxRange: 6, label: "Razoável" },
  { minRange: 6, maxRange: 8, label: "Muito bom" },
  { minRange: 8, maxRange: 10, label: "Excelente" }
];

const getGradeLabel = value => {
  if (!value && value !== 0) {
    return "Clique para dar uma nota";
  }

  const grade = GRADES.find(
    item => value >= item.minRange && value <= item.maxRange
  );

  if (grade) {
    return `Nota ${value} - ${grade.label}`;
  }

  return null;
};

const GradeSideTag = ({ value }) => {
  return (
    <Box
      py="6px"
      px="small"
      backgroundColor="primary"
      borderRadius={0}
      height={28}
      ml={20}
      css={sideTagStyle}
    >
      <Text color="white" fontWeight="bold" fontSize={1}>
        {getGradeLabel(value)}
      </Text>
    </Box>
  );
};

const topTagStyle = css({
  display: "flex",
  alignItems: "center",
  position: "relative",
  width: "max-content",
  ["::before"]: {
    content: "''",
    border: "4px solid",
    borderBottom: 0,
    borderColor: "transparent",
    borderTopColor: "primary",
    position: "absolute",
    bottom: "-4px",
    left: "50%",
    transform: "translateX(-50%)"
  }
});

const getMarginLeftPosition = value => {
  if (!value && value !== 0) {
    return "94px";
  }

  let marginLeft = (value / 0.5) * 15.45;
  if (value % 1 === 0.5) {
    marginLeft = marginLeft - 6;
  }

  return `${marginLeft}px`;
};

const GradeTopTag = ({ value }) => {
  return (
    <Box ml={"-8px"}>
      <Box
        py="small"
        px="12px"
        backgroundColor="primary"
        height={28}
        ml={getMarginLeftPosition(value)}
        mb={"small"}
        css={topTagStyle}
      >
        <Text color="white" fontWeight="bold" fontSize={1}>
          {value ? value : "Selecione uma nota"}
        </Text>
      </Box>
    </Box>
  );
};

export { GradeSideTag, GradeTopTag };
