import React, { createContext, useCallback, useState } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import * as apiKeysService from "./api-keys.service";

type State = {
  clientId: string;
  clientSecret: string;
  errorOnDownloadExternalApiDoc: Error | null;
  isLoadingClientExternalCredentials: boolean;
  isLoadingCreateClientExternalCredentials: boolean;
  isLoadingDownloadExternalApiDoc: boolean;
  isLoadingRemoveClientExternalCredentials: boolean;
  isLoadingResetClientExternalCredentials: boolean;
};

const initialState: State = {
  clientId: "",
  clientSecret: "",
  errorOnDownloadExternalApiDoc: null,
  isLoadingClientExternalCredentials: false,
  isLoadingCreateClientExternalCredentials: false,
  isLoadingDownloadExternalApiDoc: false,
  isLoadingRemoveClientExternalCredentials: false,
  isLoadingResetClientExternalCredentials: false,
};

const APIKeysContext = createContext({} as State);

interface Actions {
  createClientExternalCredentials: () => Promise<void>;
  downloadDocumentation: () => Promise<void>;
  loadClientExternalCredentials: () => Promise<void>;
  removeClientExternalCredentials: () => Promise<void>;
  resetClientExternalCredentials: () => Promise<void>;
}

const APIKeysActionsContext = createContext({} as Actions);

export const APIKeysProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const createClientExternalCredentials = async () => {
    try {
      setState((prev) => ({
        ...prev,
        isLoadingCreateClientExternalCredentials: true,
      }));

      await apiKeysService.resetClientExternalCredentials();

      await loadClientExternalCredentials();

      showSnackMessage("Nova chave gerada com sucesso", ALERT_TYPES.SUCCESS);
    } catch (e) {
      showSnackMessage(
        "Ocorreu um erro ao gerar uma nova chave",
        ALERT_TYPES.ERROR,
      );
    } finally {
      setState((prev) => ({
        ...prev,
        isLoadingCreateClientExternalCredentials: false,
      }));
    }
  };

  const downloadDocumentation = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      errorOnDownloadExternalApiDoc: null,
      isLoadingDownloadExternalApiDoc: true,
    }));

    const downloadExternalApiDocResponse = await apiKeysService.downloadExternalApiDoc();

    if (downloadExternalApiDocResponse.error) {
      const error = {
        description: "Ocorreu um erro ao fazer o download",
      };

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        errorOnDownloadExternalApiDoc: downloadExternalApiDocResponse.error!,
        isLoadingDownloadExternalApiDoc: false,
      }));

      return;
    }

    showSnackMessage("Download feito com sucesso", ALERT_TYPES.SUCCESS);

    setState((prev) => ({
      ...prev,
      isLoadingDownloadExternalApiDoc: false,
    }));
  }, [showSnackMessage]);

  const loadClientExternalCredentials = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingClientExternalCredentials: true,
    }));

    const getClientExternalCredentialsResponse = await apiKeysService.getClientExternalCredentials();

    if (
      getClientExternalCredentialsResponse.error ||
      !getClientExternalCredentialsResponse.data
    ) {
      const error = {
        description: "Ocorreu um erro ao buscar as credenciais externas",
      };

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingClientExternalCredentials: false,
      }));

      return;
    }

    const {
      clientId,
      clientSecret,
    } = getClientExternalCredentialsResponse.data;

    setState((prev) => ({
      ...prev,
      clientId,
      clientSecret,
      isLoadingClientExternalCredentials: false,
    }));
  }, [showSnackMessage]);

  const removeClientExternalCredentials = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingRemoveClientExternalCredentials: true,
    }));

    const removeClientExternalCredentialsResponse = await apiKeysService.removeClientExternalCredentials();

    if (removeClientExternalCredentialsResponse.error) {
      const error = {
        description: "Ocorreu um erro ao remover a chave",
      };

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingRemoveClientExternalCredentials: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      clientId: "",
      clientSecret: "",
    }));

    showSnackMessage("Chave removida com sucesso", ALERT_TYPES.SUCCESS);
  }, [showSnackMessage]);

  const resetClientExternalCredentials = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingResetClientExternalCredentials: true,
    }));

    const resetClientExternalCredentialsResponse = await apiKeysService.resetClientExternalCredentials();

    if (resetClientExternalCredentialsResponse.error) {
      const error = {
        description: "Ocorreu um erro ao resetar a chave",
      };

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setState((prev) => ({
        ...prev,
        isLoadingResetClientExternalCredentials: false,
      }));

      return;
    }

    await loadClientExternalCredentials();

    showSnackMessage("Chave recriada com sucesso", ALERT_TYPES.SUCCESS);
  }, [loadClientExternalCredentials, showSnackMessage]);

  return (
    <APIKeysContext.Provider value={state}>
      <APIKeysActionsContext.Provider
        value={{
          createClientExternalCredentials,
          downloadDocumentation,
          loadClientExternalCredentials,
          removeClientExternalCredentials,
          resetClientExternalCredentials,
        }}
      >
        {children}
      </APIKeysActionsContext.Provider>
    </APIKeysContext.Provider>
  );
};

export const useAPIKeys = useContextFactory("APIKeysContext", APIKeysContext);

export const useAPIKeysActions = useContextFactory(
  "APIKeysActionsContext",
  APIKeysActionsContext,
);
