import * as expenseAdvanceApi from "@apis/advanced-expense.api";
import * as expenseAdvanceApprovalsApi from "@apis/expense-advance-approvals";
import * as userApi from "@apis/user.api";
import { ERROR } from "@constants";

import {
  GetExpenseAdvanceDetailsResult,
  GetUserResult,
  GenericResult,
  GetExpenseAdvanceApproversResult
} from "./types";

export async function getExpenseAdvanceDetails(expenseAdvanceToken: string) {
  const result: GetExpenseAdvanceDetailsResult = {};

  try {
    result.data = await expenseAdvanceApi.fetchAdvancedExpenseDetails(
      expenseAdvanceToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR;
    }
  }

  return result;
}

export async function getExepnseAdvanceApprovers(expenseAdvanceToken: string) {
  const result: GetExpenseAdvanceApproversResult = {};

  try {
    result.data = await expenseAdvanceApprovalsApi.getExpenseAdvanceApprovers(
      expenseAdvanceToken
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVER_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVER_ERROR;
    }
  }

  return result;
}

export async function getUser(userToken: string) {
  const result: GetUserResult = {};

  try {
    result.data = await userApi.getUserRequest(userToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR;
    }
  }

  return result;
}

export async function approveExpenseAdvanceRequest(
  expenseAdvanceToken: string,
  approvedValue: number
) {
  const result: GenericResult = {};

  try {
    result.data = await expenseAdvanceApprovalsApi.approveExpenseAdvanceRequest(
      expenseAdvanceToken,
      { approved_value: approvedValue }
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_APPROVE_EXPENSE_ADVANCE_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_APPROVE_EXPENSE_ADVANCE_REQUEST_ERROR;
    }
  }

  return result;
}

export async function denyExpenseAdvanceRequest(
  expenseAdvanceToken: string,
  message?: string
) {
  const result: GenericResult = {};

  try {
    result.data = await expenseAdvanceApprovalsApi.denyExpenseAdvanceRequest(
      expenseAdvanceToken,
      { message: message ? message : undefined }
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DENY_EXPENSE_ADVANCE_REQUEST_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DENY_EXPENSE_ADVANCE_REQUEST_ERROR;
    }
  }

  return result;
}
