import { useQuery } from "react-query";

import * as agencyApi from "~/apps/corporate/apis/agency.api";

import { UserModel } from "../models/user.model";

export const useUserAgencyChatConfig = (user: UserModel | null) => {
  const agencyToken = user ? user.getAgencyToken() : null;

  const {
    data: chatConfig,
    error: errorOnFetchChatConfig,
    isLoading: isLoadingChatConfig,
  } = useQuery(
    ["chatConfig", agencyToken],
    async () => {
      if (!agencyToken) {
        return;
      }

      return await agencyApi.getChatConfig(agencyToken);
    },
    {
      enabled: !!agencyToken,
    },
  );

  return {
    chatConfig,
    errorOnFetchChatConfig,
    isLoadingChatConfig,
  };
};
