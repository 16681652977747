import { useState } from "react";

type Changes<T> = (prev: T) => T;

export function useSafeState<T>(
  initialState: T,
): [T, (changes: Partial<T> | Changes<T>) => void] {
  const [state, setState] = useState<T>(initialState);

  const setSafeState = (changes: Partial<T> | Changes<T>) => {
    if (typeof changes === "function") {
      setState((prev) => changes(prev));
    } else {
      setState((prev) => Object.assign({}, prev, changes));
    }
  };

  return [state, setSafeState];
}
