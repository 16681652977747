import React, { useCallback } from "react";

import { navigate } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";

import { Button } from "@toolkit/v2";

import { RestrictionDrawer } from "./restriction-drawer/restriction-drawer";
import { RestrictionsList } from "./restrictions-list/restrictions-list";
import { styles } from "./styles";

type Props = {
  token?: string;
};

export const CountryRestrictions: React.FC<Props> = ({ token }) => {
  const handleCreateNewRestriction = useCallback(() => {
    navigate("/configurations/trips/country-restrictions/create");
  }, []);

  return (
    <>
      <PageTitle title="Restrições de Viagem" />
      <div css={styles.root}>
        <div css={styles.header.root}>
          <Button onClick={handleCreateNewRestriction} variant="pink">
            Criar nova restrição
          </Button>
        </div>
        <div css={styles.main.root}>
          <h1 css={styles.main.title}>Restrições ativas</h1>
          <RestrictionsList />
        </div>
      </div>
      <RestrictionDrawer token={token} />
    </>
  );
};
