import React, { useEffect, useMemo } from "react";

import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { Icon } from "~/apps/shared/components/icon/icon";
import { TARGET_TYPES } from "~/apps/shared/constants";

import { Badge, Button, CircularSpinner } from "@toolkit/v2";

import { PolicyDeletionAlertDialog } from "./policy-deletion-alert-dialog/policy-deletion-alert-dialog";
import { PolicyDrawer } from "./policy-drawer/policy-drawer";
import { styles } from "./styles";
import { PoliciesProvider, usePolicies } from "./travel-policies.context";

export const Component: React.FC = () => {
  const {
    handleDeletionCancel,
    handleDeletionConfirm,
    isDeleteVisible,
    isDeleting,
    loading,
    loadPolicies,
    openNewPolicy,
    policies,
    selectPolicyForEdition,
    selectedPolicyItem,
    selectPolicyToDelete,
  } = usePolicies();

  useEffect(() => {
    loadPolicies();
  }, []);

  const selectedItemDescription = useMemo(
    () => (selectedPolicyItem ? selectedPolicyItem.description : ""),
    [selectedPolicyItem],
  );

  return (
    <>
      <PageTitle title="Políticas de Aprovação" />
      <div css={styles.root}>
        <div css={styles.header.root}>
          <Button onClick={openNewPolicy} variant="pink">
            Adicionar política
          </Button>
        </div>
        <div css={styles.main.root}>
          <h1 css={styles.main.title}>Políticas Cadastradas</h1>
          {loading ? (
            <div css={styles.main.loading.root}>
              <CircularSpinner size={16} />
              <span>Carregando...</span>
            </div>
          ) : policies.length === 0 ? (
            <span css={styles.main.empty}>Nenhuma política cadastrada.</span>
          ) : (
            <ul css={styles.main.list.root}>
              {policies.map((policy) => {
                const isDefault = policy.targetType === TARGET_TYPES.COMPANY;

                return (
                  <li
                    css={styles.main.list.item.root}
                    key={policy.policyToken}
                    onClick={() => {
                      selectPolicyForEdition(policy);
                    }}
                  >
                    <div css={styles.main.list.item.left.root}>
                      <div css={styles.main.list.item.left.text}>
                        <span css={styles.main.list.item.left.description}>
                          {policy.description}
                        </span>
                      </div>
                      {isDefault ? (
                        <Badge
                          css={styles.main.list.item.left.badge}
                          variant="green"
                        >
                          Padrão
                        </Badge>
                      ) : null}
                    </div>
                    <div css={styles.main.list.item.right.root}>
                      <div css={styles.main.list.item.right.count}>
                        {policy.targetType !== TARGET_TYPES.COMPANY ? (
                          <>
                            {policy.targetCount}
                            <Icon size={16} use="people" />
                          </>
                        ) : (
                          <>
                            <Icon size={16} use="building" />
                          </>
                        )}
                      </div>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();

                          selectPolicyForEdition(policy);
                        }}
                        shape="icon"
                      >
                        <Icon size={20} use="pencil" />
                      </Button>
                      {!isDefault ? (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();

                            selectPolicyToDelete(policy);
                          }}
                          shape="icon"
                          variant="pink"
                        >
                          <Icon size={20} use="trash" />
                        </Button>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <PolicyDeletionAlertDialog
        isLoading={isDeleting}
        message={
          "Deseja realmente excluir a política " +
          selectedItemDescription +
          " selecionada?"
        }
        onClose={handleDeletionCancel}
        onDelete={handleDeletionConfirm}
        open={isDeleteVisible}
        title="Excluir política"
      />
      <PolicyDrawer />
    </>
  );
};

export const TravelPolicies: React.FC = () => (
  <PoliciesProvider>
    <Component />
  </PoliciesProvider>
);
