import { css } from "@styled-system/css";

import { theme } from "@skin/v2";
import { darken } from "@material-ui/core";

export const styles = {
  message: {
    icon: css({
      color: "#1c72f4",
    }),
    root: css({
      alignItems: "center",
      backgroundColor: "#d9edff",
      border: "1px solid",
      borderColor: "#59b2ff",
      borderRadius: "0.375rem",
      display: "flex",
      gap: "0.75rem",
      padding: "0.75rem 1rem",
      width: "fit-content",
    }),
    text: css({
      fontSize: "0.875rem",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
  }),
  top: {
    button: css({
      width: "fit-content",
      height: "3rem",
      backgroundColor: theme.colors.pink[500],
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2),
      },
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
      marginBottom: "1.5rem",
    }),
  },
};
