export const evaluatePasswordParameters = (password: string) => {
  if (!password)
    return {
      hasLowercaseChar: false,
      hasMininumLength: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasUppercaseChar: false,
    };

  return {
    hasLowercaseChar: hasLowercaseChar(password),
    hasMininumLength: hasMininumLength(password),
    hasNumber: hasNumber(password),
    hasSpecialChar: hasSpecialChar(password),
    hasUppercaseChar: hasUppercaseChar(password),
  };
};

const hasLowercaseChar = (password: string) =>
  new RegExp(/[a-z]/).test(password);
const hasMininumLength = (password: string) => password.length >= 8;
const hasNumber = (password: string) => new RegExp(/[0-9]/).test(password);
const hasSpecialChar = (password: string) =>
  new RegExp(/[^A-Za-z0-9]/).test(password);
const hasUppercaseChar = (password: string) =>
  new RegExp(/[A-Z]/).test(password);

export const isValidPassword = (password: string) => {
  const validations = evaluatePasswordParameters(password);

  return Object.values(validations).every((value) => value === true);
};
