import { useEffect, useRef } from "react";
import { GroupBase, OptionsOrGroups } from "react-select";

import { Option } from "../../types";

export const useSetDefaultValueIfOnlyOneOption = ({
  enabled,
  onChange,
  options,
}: {
  enabled?: boolean;
  onChange: ((value: Option<string>) => void) | undefined;
  options: OptionsOrGroups<unknown, GroupBase<any>> | undefined;
}) => {
  const hasTriedToSetDefaultValueIfOnlyOneOption = useRef(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!onChange || !options) {
      return;
    }

    if (hasTriedToSetDefaultValueIfOnlyOneOption.current) {
      return;
    }

    hasTriedToSetDefaultValueIfOnlyOneOption.current = true;

    const hasNestedOptions = options.every(
      (option: any) => "options" in option,
    );

    if (hasNestedOptions) {
      const nestedOptionWithOnlyOneOption = options.filter(
        (nestedOption: any) => nestedOption.options.length === 1,
      );

      if (nestedOptionWithOnlyOneOption.length !== 1) {
        return;
      }

      onChange(
        (nestedOptionWithOnlyOneOption[0] as any).options[0] as Option<string>,
      );

      return;
    }

    if (options.length !== 1) {
      return;
    }

    onChange(options[0] as Option<string>);
  }, [enabled, hasTriedToSetDefaultValueIfOnlyOneOption, onChange, options]);
};
