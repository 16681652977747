import { OrderItemStatus } from "~/apps/shared/constants/enums";

export type ItineraryServiceInfo = {
  itemStatus: OrderItemStatus;
  paymentMethod?: "BALCONY" | "BILLED" | "CREDIT_CARD";
  creditCard?: {
    brand: string;
    description: string;
    lastDigits: string;
  };
  usedCredit?: number;
};

export class ItineraryServiceInfoModel {
  constructor(protected serviceInfo: ItineraryServiceInfo) {}

  public getCreditCard() {
    const creditCard = this.serviceInfo.creditCard;

    if (!creditCard) {
      return null;
    }

    return creditCard;
  }

  public getOrderStatus() {
    return this.serviceInfo.itemStatus;
  }

  public getPaymentMethod() {
    const paymentMethod = this.serviceInfo.paymentMethod;

    if (!paymentMethod) {
      return null;
    }

    return paymentMethod;
  }
}

export class ItineraryServiceInfoModelFactory {
  public static create(serviceInfo: ItineraryServiceInfo) {
    return new ItineraryServiceInfoModel(serviceInfo);
  }
}
