import React, { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";

import {
  ChevronRight as ChevronRightIcon,
  PersonAdd as PersonAddIcon,
  Search as SearchIcon
} from "@material-ui/icons/";
import { Link } from "@reach/router";
import moment from "moment";
import { BookingStatus } from "sm-types/common";
import {
  EventInviteStatus,
  EventParticipantType,
  GetUserCompanyEventDto,
  ListEventParticipantsDto
} from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Button, Flex, Text } from "smartrips-toolkit";

import { getUserFromLocalStorage } from "~/helpers/user.helper";

import {
  mapParticipantInviteStatus,
  ParticipationStatus
} from "~/models/event.model";

import { useEvents } from "~/components/events/events.context";
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger
} from "~/components/shared/accordion";
import { Avatar } from "~/components/shared/avatar";
import CircularSpinner from "~/components/shared/CircularSpinner";
import { Input } from "~/components/shared/inputs";
import { Spacing } from "~/components/shared/layout";
import { Tabs } from "~/components/shared/tabs";
import { CopyIdIcon } from "~/components/travels/copy-id-icon";

import { useEvent } from "../../event.context";
import { EventOverviewParticipantsInviteDialog } from "./invite-dialog";
import { OfferCardMap } from "./offer-card-map";
import { OfferIconMap } from "./offer-icon-map";
import { styles } from "./styles";

enum TravelStatus {
  DRAFT = "EM RASCUNHO",
  DROPPED_AND_FAILED = "CANCELADA OU MALSUCEDIDA",
  FULLY_BOOKED = "INTEGRALMENTE RESERVADA",
  NO_OFFERS = "SEM OFERTAS AINDA",
  PARTIALLY_BOOKED = "PARCIALMENTE RESERVADA"
}

const mapParticipantTravelStatus = (
  participant: ListEventParticipantsDto[number]
): string | null => {
  if (participant.invite_status === EventInviteStatus.DECLINED) {
    return null;
  }

  if (participant.invite_status === EventInviteStatus.PENDING) {
    return `CONVIDADO EM ${moment(participant.created_at)
      .format("DD MMM")
      .toUpperCase()}`;
  }

  if (!participant.travel_info) {
    return TravelStatus.NO_OFFERS;
  }

  const offers = participant.travel_info.offers;

  if (
    offers.every(offer =>
      [BookingStatus.DROPPED, BookingStatus.FAILED].includes(
        offer.booking_status
      )
    )
  ) {
    return TravelStatus.DROPPED_AND_FAILED;
  }

  const offersFilteredByDroppedAndFailed = offers.filter(
    offer =>
      ![BookingStatus.DROPPED, BookingStatus.FAILED].includes(
        offer.booking_status
      )
  );

  if (
    offersFilteredByDroppedAndFailed.every(
      offer => offer.booking_status === BookingStatus.EMITTED
    )
  ) {
    return TravelStatus.FULLY_BOOKED;
  }

  if (
    offersFilteredByDroppedAndFailed.some(
      offer => offer.booking_status === BookingStatus.EMITTED
    )
  ) {
    return TravelStatus.PARTIALLY_BOOKED;
  }

  return TravelStatus.DRAFT;
};

const defaultTabs: {
  label: string;
  value: ParticipationStatus | "PENDING";
}[] = [
  {
    label: mapParticipantInviteStatus(ParticipationStatus.ACCEPTED),
    value: ParticipationStatus.ACCEPTED
  },
  {
    label: mapParticipantInviteStatus(ParticipationStatus.DECLINED),
    value: ParticipationStatus.DECLINED
  },
  {
    label: "Pendente",
    value: "PENDING"
  }
] as const;

type Props = {
  event: GetUserCompanyEventDto;
  shouldRenderBooking: boolean;
};

export const EventOverviewParticipants: React.VFC<Props> = ({
  event,
  shouldRenderBooking
}) => {
  const user = getUserFromLocalStorage();

  const {
    participants,
    invalidateParticipants,
    isLoadingParticipants
  } = useEvent();
  const { disinviteEventParticipants, editEventParticipant } = useEvents();

  const { mutateAsync: disinviteEventParticipantsMutation } = useMutation({
    mutationFn: async ({
      participant_token
    }: {
      participant_token: string;
    }) => {
      const result = await disinviteEventParticipants({
        participant_token,
        token: event.token
      });

      if (!result.success) {
        return;
      }

      await invalidateParticipants();
    }
  });

  const { mutateAsync: editEventParticipantMutation } = useMutation({
    mutationFn: async ({
      data,
      token
    }: {
      data: Parameters<typeof editEventParticipant>[0]["data"];
      token: string;
    }) => {
      const result = await editEventParticipant({
        data,
        eventToken: event.token,
        token: token
      });

      if (!result) {
        return;
      }

      await invalidateParticipants();

      return result;
    }
  });

  const [dialog, setDialog] = useState<"invite" | null>(null);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState<typeof defaultTabs[number]["value"]>(
    ParticipationStatus.ACCEPTED
  );

  const filteredParticipants = useMemo(() => {
    if (!participants) {
      return [];
    }

    const filteredBySearch = participants.filter(eventParticipant =>
      eventParticipant.full_name.toLowerCase().includes(search.toLowerCase())
    );

    const filteredByTab = filteredBySearch.filter(eventParticipant =>
      eventParticipant.invite_status.includes(tab)
    );

    return filteredByTab;
  }, [participants, search, tab]);

  const handleEditEventParticipant = useCallback(
    async ({
      participant_type,
      token
    }: {
      participant_type: EventParticipantType;
      token: string;
    }) => {
      await editEventParticipantMutation({
        data: {
          participant_type
        },
        token
      });
    },
    [editEventParticipantMutation]
  );

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const hasAtLeastOneOrganizer = useMemo(() => {
    if (!participants) {
      return false;
    }

    const organizers = participants.filter(
      participant =>
        participant.participant_type === EventParticipantType.ORGANIZER
    );

    return organizers.length >= 1;
  }, [participants]);

  return (
    <>
      <div css={styles.root}>
        {isLoadingParticipants ? (
          <div css={styles.loader}>
            <CircularSpinner />
          </div>
        ) : (
          <>
            <div css={styles.header}>
              <Text fontSize="1.125rem" fontWeight="bold">
                Participantes
              </Text>
              {event.viewer_info.is_organizer ? (
                <Button
                  css={styles.button}
                  onClick={() => {
                    setDialog("invite");
                  }}
                >
                  <PersonAddIcon fontSize="small" />
                  Convidar
                </Button>
              ) : null}
            </div>
            <Input
              icon={
                <SearchIcon
                  css={{
                    color: theme.colors.gray[2]
                  }}
                  fontSize="small"
                />
              }
              InputProps={{
                inputRef: element => {
                  if (!element) {
                    return;
                  }

                  element.style.setProperty(
                    "padding",
                    "13.5px 14px 12.5px 0",
                    "important"
                  );
                }
              }}
              onChange={handleSearch}
              placeholder="Buscar por um participante..."
              value={search}
            />
            <Flex alignItems="center" justifyContent="space-between">
              <Tabs
                classes={{
                  ...styles.tabs
                }}
                onChange={(_, tab) => {
                  setTab(tab);
                }}
                value={tab}
              >
                {defaultTabs.map(tab => (
                  <Tabs.Item
                    classes={{
                      ...styles.tabs.tab
                    }}
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Flex>
            {filteredParticipants.length === 0 ? (
              <Text color={theme.colors.gray[3]} fontSize={14}>
                Nenhum participante foi encontrado.
              </Text>
            ) : (
              <Accordion css={styles.accordion.root}>
                {filteredParticipants.map(participant => {
                  const invite_status = participant.invite_status;
                  const offers = participant.travel_info?.offers;
                  const travel_token = participant.travel_info?.token;

                  const loggedUserIsOrganizer = event.viewer_info.is_organizer;

                  const participantIsLoggedUser =
                    participant.user_token === user?.userToken;

                  const participantHasAccepted =
                    invite_status === EventInviteStatus.ACCEPTED;

                  const participantHasDeclined =
                    invite_status === EventInviteStatus.DECLINED;

                  const participantIsOrganizer =
                    participant.participant_type ===
                    EventParticipantType.ORGANIZER;

                  const participantIsPending =
                    invite_status === EventInviteStatus.PENDING;

                  const participantIsTravelerAndCanBeOrganizer =
                    participant.participant_type ===
                      EventParticipantType.TRAVELER &&
                    participant.can_be_organizer;

                  const shouldMapOffers =
                    offers &&
                    offers.length > 0 &&
                    participantHasAccepted &&
                    travel_token;

                  const shouldRenderFooter =
                    !participantIsLoggedUser &&
                    (participantIsPending ||
                      (loggedUserIsOrganizer && participantHasAccepted));

                  const hasAccordionContent =
                    shouldMapOffers || shouldRenderFooter;

                  const isDisabled =
                    participantHasDeclined || !hasAccordionContent;

                  const travelStatus = mapParticipantTravelStatus(participant);

                  return (
                    <AccordionItem
                      id={participant.token}
                      key={participant.token}
                    >
                      {({ expanded }) => (
                        <>
                          <AccordionHeader>
                            <AccordionTrigger
                              css={styles.accordion.trigger}
                              disabled={isDisabled}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Flex alignItems="center">
                                  <Avatar name={participant.full_name} />
                                  <Spacing direction="horizontal" space="8px" />
                                  <Flex alignItems="center">
                                    <Flex
                                      alignItems="flex-start"
                                      flexDirection="column"
                                    >
                                      <Text
                                        color={theme.colors.gray[4]}
                                        fontSize={14}
                                        fontWeight="bold"
                                        textAlign="left"
                                      >
                                        {participant.full_name}
                                      </Text>
                                      <Spacing
                                        direction="vertical"
                                        space="2px"
                                      />
                                      {travelStatus ? (
                                        <Text
                                          color={theme.colors.gray[3]}
                                          fontSize={10}
                                        >
                                          {travelStatus}
                                        </Text>
                                      ) : null}
                                    </Flex>
                                    <Spacing
                                      direction="horizontal"
                                      space="8px"
                                    />
                                    {offers ? (
                                      <div css={styles.participant.icons}>
                                        {offers.map(offer => (
                                          <OfferIconMap
                                            key={offer.offer_token}
                                            status={offer.booking_status}
                                            type={offer.type}
                                          />
                                        ))}
                                      </div>
                                    ) : null}
                                  </Flex>
                                </Flex>
                                <Flex alignItems="center">
                                  {participant.participant_type ===
                                  EventParticipantType.ORGANIZER ? (
                                    <Text
                                      color={theme.colors.gray[4]}
                                      css={styles.participant.badge}
                                      fontSize={12}
                                      fontWeight="bold"
                                    >
                                      Organizador
                                    </Text>
                                  ) : null}
                                  <Spacing direction="left" space="1rem" />
                                  <ChevronRightIcon
                                    css={styles.participant.chevron({
                                      disabled: isDisabled,
                                      expanded
                                    })}
                                  />
                                </Flex>
                              </Flex>
                            </AccordionTrigger>
                          </AccordionHeader>
                          <AccordionContent css={styles.participant.card}>
                            {shouldMapOffers ? (
                              <div css={styles.participant.travel}>
                                <Text
                                  color={theme.colors.gray[3]}
                                  fontSize={12}
                                  padding="0 16px 0 16px"
                                >
                                  {moment(
                                    participant.travel_info?.startDate
                                  ).format("DD/MM/YYYY")}{" "}
                                  -{" "}
                                  {moment(
                                    participant.travel_info?.endDate
                                  ).format("DD/MM/YYYY")}
                                </Text>
                                <div
                                  css={{
                                    color: theme.colors.gray[4],
                                    textDecoration: "none"
                                  }}
                                >
                                  <ul css={styles.participant.offers}>
                                    {offers?.map(offer => (
                                      <li key={offer.offer_token}>
                                        <OfferCardMap {...offer} />
                                      </li>
                                    ))}
                                  </ul>
                                  {(participantIsLoggedUser ||
                                    loggedUserIsOrganizer) &&
                                  shouldRenderBooking ? (
                                    <div
                                      css={{
                                        padding: "16px 12px 8px 12px"
                                      }}
                                    >
                                      <a
                                        css={styles.participant.anchor}
                                        href="#booking"
                                      >
                                        <Text
                                          color={theme.colors.primary}
                                          fontSize={14}
                                          fontWeight="bold"
                                        >
                                          Continuar Reservando
                                        </Text>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                                <div css={styles.participant.footer.root}>
                                  <div css={styles.participant.footer.token}>
                                    <CopyIdIcon id={event.token} />
                                    <Text>
                                      ID: <strong>{event.token}</strong>
                                    </Text>
                                  </div>
                                  {participantIsLoggedUser ||
                                  loggedUserIsOrganizer ? (
                                    <Link
                                      css={styles.participant.footer.link}
                                      to={`/travels/${travel_token}/itinerary`}
                                    >
                                      Ir para a viagem
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                            {shouldRenderFooter ? (
                              <Flex
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                {participantIsPending ? (
                                  <button
                                    css={styles.participant.button}
                                    onClick={async () => {
                                      await disinviteEventParticipantsMutation({
                                        participant_token: participant.token
                                      });
                                    }}
                                  >
                                    <Text
                                      color={theme.colors.primary}
                                      fontSize={14}
                                      fontWeight="bold"
                                    >
                                      Desconvidar Participante
                                    </Text>
                                  </button>
                                ) : null}
                                {loggedUserIsOrganizer &&
                                participantHasAccepted &&
                                participantIsTravelerAndCanBeOrganizer ? (
                                  <>
                                    <Spacing
                                      direction="horizontal"
                                      space="8px"
                                    />
                                    {participantIsOrganizer ? (
                                      <button
                                        css={styles.participant.button}
                                        disabled={!hasAtLeastOneOrganizer}
                                        onClick={async () => {
                                          await handleEditEventParticipant({
                                            participant_type:
                                              EventParticipantType.TRAVELER,
                                            token: participant.token
                                          });
                                        }}
                                      >
                                        <Text
                                          color={theme.colors.primary}
                                          fontSize={14}
                                          fontWeight="bold"
                                        >
                                          Remover Status Organizador
                                        </Text>
                                      </button>
                                    ) : (
                                      <button
                                        css={styles.participant.button}
                                        onClick={async () => {
                                          await handleEditEventParticipant({
                                            participant_type:
                                              EventParticipantType.ORGANIZER,
                                            token: participant.token
                                          });
                                        }}
                                      >
                                        <Text
                                          color={theme.colors.primary}
                                          fontSize={14}
                                          fontWeight="bold"
                                        >
                                          Tornar Organizador
                                        </Text>
                                      </button>
                                    )}
                                  </>
                                ) : null}
                              </Flex>
                            ) : null}
                          </AccordionContent>
                        </>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            )}
          </>
        )}
      </div>
      {dialog && participants ? (
        <EventOverviewParticipantsInviteDialog
          event={event}
          onClose={() => {
            setDialog(null);
          }}
          open={dialog === "invite"}
          participants={participants}
        />
      ) : null}
    </>
  );
};
