import { css } from "@styled-system/css";

export const styles = {
  button: css({
    whiteSpace: "nowrap",
  }),
  item: css({
    ".MuiListItem-root": {},
  }),
};
