import moment from "moment";

import { getUserFromLocalStorage } from "./user.helper";

export function buildTravelData(traveler) {
  const travelData = {};
  const userToken = getUserFromLocalStorage().userToken;

  travelData.travel_name = "";
  travelData.booker_token = userToken;
  travelData.traveler_token = traveler.userToken;

  return travelData;
}

export function getDayHoursInterval(minutesInterval) {
  var hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minutesInterval) {
      hours.push(moment().set({ hour, minute }).format("HH:mm"));
    }
  }

  return hours;
}

export function getLocationInitials(str) {
  return str.split("(")[1].split(")")[0];
}
