import React from "react";

import { GetUserCompanyEventDto } from "sm-types/sm-company-events";
import { theme } from "smartrips-skin";
import { Flex, Text } from "smartrips-toolkit";

import { styles } from "./styles";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventOverviewAbout: React.VFC<Props> = ({ event }) => {
  return (
    <div css={styles.root}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="1.125rem" fontWeight="bold">
          Descrição
        </Text>
      </Flex>
      <Text color={theme.colors.gray[4]} fontSize={14}>
        {event.description || "Evento sem descrição."}
      </Text>
    </div>
  );
};
