import React, { useRef } from "react";
import ReactImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from "react-image-gallery";

import noImageAvailablePlaceholder from "~/apps/shared/assets/images/placeholders/no-image-available-placeholder.svg";

import { Button } from "@toolkit/v2";

import { Icon } from "../icon/icon";
import { styles } from "./styles";

const defaultRenderFullscreenButton = (
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  disabled: boolean,
) => {
  if (disabled) {
    return null;
  }

  return (
    <Button
      aria-label="Imagem anterior"
      css={styles.fullscreen}
      shape="icon"
      onClick={onClick}
    >
      <Icon use="fullscreen" />
    </Button>
  );
};

const defaultRenderLeftNav = (
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  disabled: boolean,
) => {
  if (disabled) {
    return null;
  }

  return (
    <Button
      aria-label="Imagem anterior"
      css={styles.nav.left}
      fill="outlined"
      shape="icon"
      onClick={onClick}
    >
      <Icon use="chevron-left" />
    </Button>
  );
};

const defaultRenderRightNav = (
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  disabled: boolean,
) => {
  if (disabled) {
    return null;
  }

  return (
    <Button
      aria-label="Próxima imagem"
      css={styles.nav.right}
      fill="outlined"
      shape="icon"
      onClick={onClick}
    >
      <Icon use="chevron-right" />
    </Button>
  );
};

type Props = ReactImageGalleryProps;

export const PhotoGallery: React.FC<Props> = ({
  items,
  renderFullscreenButton = defaultRenderFullscreenButton,
  renderLeftNav = defaultRenderLeftNav,
  renderRightNav = defaultRenderRightNav,
  ...props
}) => {
  const galleryRef = useRef<ReactImageGallery | null>(null);

  const customItems = items.reduce(
    (acc: ReactImageGalleryItem[], curr: ReactImageGalleryItem) => {
      curr.originalAlt = "Imagem do hotel";
      curr.thumbnailAlt = "Imagem do hotel";

      return acc.concat(curr);
    },
    [],
  );

  return (
    <ReactImageGallery
      defaultImage={noImageAvailablePlaceholder}
      items={customItems}
      ref={galleryRef}
      renderFullscreenButton={renderFullscreenButton}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      {...props}
    />
  );
};
