import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import { css } from "emotion";
import React from "react";

const styles = {
  container: css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  })
};

type FullpageLoaderProps = {
  className?: string;
};

export default function FullpageLoader(props: FullpageLoaderProps) {
  const { className } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <CircularProgress />
    </div>
  );
}
