import React from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { formatDisplayedPhone } from "~/apps/shared/utils/format-displayed-phone";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { styles } from "./styles";

export const UserProfileDrawerBasicInformationSection: React.FC = () => {
  const { user } = useUser();

  const { editBasicInformation } = useUserProfileDrawer();

  if (!user) {
    return null;
  }

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <span css={styles.header.title}>Informações básicas</span>
        <button
          css={styles.header.button}
          onClick={() => {
            editBasicInformation();
          }}
        >
          Editar
        </button>
      </div>
      <div css={styles.basic.info.root}>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>Nome:</span>
          <span css={styles.basic.info.row.value}>{user.getFullName()}</span>
        </div>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>E-mail:</span>
          <span css={styles.basic.info.row.value}>{user.getEmail()}</span>
        </div>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>Telefone:</span>
          <span css={styles.basic.info.row.value}>
            {formatDisplayedPhone(user.getPhone())}
          </span>
        </div>
      </div>
    </div>
  );
};
