import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { Column } from "../../shared/layout/Column";
import { css } from "emotion";
import { Desktop } from "../../shared/layout/Responsive";
import PageTitle from "../../shared/PageTitle";
import {
  OthersReportsListProvider,
  OthersReportsListContext
} from "./OthersReportsList.context";
import { ReportItem } from "../shared/ReportItem";
import InfiniteScroll from "react-infinite-scroller";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import SpinnerPortal from "../../shared/Spinner";
import { MEDIA_QUERIES } from "@constants";
import { FETCH_STATUS } from "@constants";

const styles = {
  root: css({
    width: "100%",
    ["@media (max-width: 991px)"]: {
      paddingBottom: "4rem"
    }
  }),
  inputContainer: css({
    width: 350
  }),
  noReports: css({
    paddingLeft: "1.5rem",
    paddingTop: ".5rem",
    fontSize: 18,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: ".5rem",
      paddingLeft: 0,
      fontSize: 16
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: ".5rem",
      paddingLeft: 0,
      fontSize: 15
    }
  })
};

const OthersReportsList = (_: RouteComponentProps) => {
  const {
    reports,
    status,
    loadNextReports,
    currentPage,
    totalPages,
    fetchReports,
    handleChangeSearch
  } = useContext(OthersReportsListContext);

  useEffect(() => {
    fetchReports();
  }, []);

  const hasNextPage =
    status !== FETCH_STATUS.FETCHING && currentPage < totalPages;

  return (
    <div className={styles.root}>
      <PageTitle title="Relatórios de outros" />
      <SpinnerPortal visible={status === FETCH_STATUS.FETCHING} />
      <div style={{ marginBottom: "2rem" }}>
        <Desktop>
          <SearchFilterInput
            containerClasses={styles.inputContainer}
            placeholder="Busca por usuário ou descrição"
            onChange={handleChangeSearch}
          />
        </Desktop>
      </div>
      <div>
        <Column>
          {!reports.length &&
          status !== FETCH_STATUS.FETCHING &&
          status !== FETCH_STATUS.IDLE ? (
            <div className={styles.noReports}>Nenhum relatório encontrado</div>
          ) : null}
          <InfiniteScroll
            pageStart={1}
            loadMore={loadNextReports}
            hasMore={hasNextPage}
            loader={
              <div key={0} className="loader">
                Carregando...
              </div>
            }
          >
            {reports.map(report => (
              <ReportItem report={report} key={report.expenseReportToken} />
            ))}
          </InfiniteScroll>
        </Column>
      </div>
    </div>
  );
};

const Container = (props: RouteComponentProps) => (
  <OthersReportsListProvider>
    <OthersReportsList {...props} />
  </OthersReportsListProvider>
);

export { Container as OthersReportsList };
