import { Text, View } from "@react-pdf/renderer";
import React from "react";

import busFallback from "~/apps/shared/assets/images/fallbacks/bus-fallback.png";
import { PDFRemoteImage } from "~/apps/shared/components/pdf-remote-image/pdf-remote-image";

import { styles } from "./styles";

type Props = {
  arrival: {
    address: string;
    time: string;
  };
  busName: string;
  carrierLogo: string;
  carrierName: string;
  departure: {
    address: string;
    time: string;
  };
  duration: string;
  seat?: string;
};

export const TravelPlanPDFBusSegment: React.FC<Props> = ({
  arrival,
  busName,
  carrierLogo,
  departure,
  duration,
  seat,
}) => {
  const infoStyles = styles.info();
  const otherStyles = styles.other();
  const rootStyles = styles.root();
  const segmentsStyles = styles.segments();

  return (
    <View style={rootStyles.root}>
      <PDFRemoteImage
        fallbackSrc={busFallback}
        src={carrierLogo}
        style={rootStyles.image}
      />
      <View style={infoStyles.root}>
        <View style={segmentsStyles.root}>
          <TravelPlanPDFBusSegmentInfo
            place={departure.address}
            time={departure.time}
          />
          <TravelPlanPDFBusSegmentInfo
            place={arrival.address}
            time={arrival.time}
          />
        </View>
        <View style={otherStyles.root}>
          <View style={otherStyles.duration}>
            <Text>{duration}</Text>
          </View>
          <View style={otherStyles.name}>
            <Text>{busName}</Text>
          </View>
          {seat ? (
            <View style={otherStyles.seat}>
              <Text>Assento: {seat}</Text>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

type TravelPlanPDFBusSegmentInfoProps = {
  place: string;
  time: string;
};

const TravelPlanPDFBusSegmentInfo: React.FC<TravelPlanPDFBusSegmentInfoProps> = ({
  place,
  time,
}) => {
  const segmentStyles = styles.segment();

  return (
    <View style={segmentStyles.root}>
      <View style={segmentStyles.time}>
        <Text>{time}</Text>
      </View>
      <View style={segmentStyles.place}>
        <Text>{place}</Text>
      </View>
    </View>
  );
};
