import React from "react";

import { darken } from "@material-ui/core";
import { css } from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";

import { theme } from "@skin/v2";

import { Flex } from "@toolkit";
import { Switch, Button } from "@toolkit/v2";

import { defaultTheme } from "~/assets/styles/theme";

import { ExpenseCategoryCamelCase } from "@dtos/expense-categories.dto";

const styles = {
  item: css({
    height: 55,
    alignItems: "center",
    color: defaultTheme.subTextColor,
    fontSize: 15,
    padding: "1rem .5rem 1rem 1rem",
    bg: "white",
    pl: "large",
    ["&:not(:last-child)"]: {
      borderBottom: `1px solid border`,
    },
    ["&:last-child"]: {
      borderRadius: "0 0 0.5rem 0.5rem",
    },
    borderTop: "1px solid",
    borderColor: "border",
  }),
  descriptionColumn: css({
    textAlign: "left",
  }),
  optionsColumn: css({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "1.5rem",
  }),
  separator: css({
    flex: "auto",
  }),
  fixedBadge: css({
    ml: "1rem",
    fontWeight: "bold",
    fontSize: 10,
    textTransform: "uppercase",
    lineHeight: "heading",
    padding: "0.3rem 0.5rem",
    borderRadius: "50px",
    color: "text",
    bg: "gray.0",
  }),
  button: {
    edit: css({
      width: 30,
      height: 30,
      borderRadius: "50%",
      backgroundColor: theme.colors.pink[500],
      border: "none",
      "&:hover": {
        backgroundColor: darken(theme.colors.pink[500], 0.2),
      },
    }),
    archive: css({
      width: 30,
      height: 30,
      borderRadius: "50%",
    }),
  },
};

interface Props {
  item: ExpenseCategoryCamelCase;
  handleToggleSwitch: (expenseCategory: ExpenseCategoryCamelCase) => void;
  handleEdit: (item: ExpenseCategoryCamelCase) => void;
  handleToggleArchive: (expenseCategory: ExpenseCategoryCamelCase) => void;
}

export const ExpenseSubCategoryListItem: React.FC<Props> = React.memo(
  ({ item, handleToggleSwitch, handleEdit, handleToggleArchive }) => {
    const handleSwitch = () => {
      handleToggleSwitch(item);
    };

    const handleEditItem = () => {
      handleEdit(item);
    };

    const handleArchive = () => {
      handleToggleArchive(item);
    };

    return (
      <Flex css={styles.item}>
        <div css={styles.descriptionColumn}>{item.name}</div>
        {item.default ? (
          <div css={styles.fixedBadge}>categoria fixa</div>
        ) : null}
        <div css={styles.separator} />
        <div css={styles.optionsColumn}>
          {item.default ? (
            <Switch
              active={item.active}
              onChange={handleSwitch}
              variant="pink"
            />
          ) : (
            <Flex style={{ gap: "0.5rem", alignItems: "center" }}>
              <Button
                onClick={handleEditItem}
                shape="icon"
                css={styles.button.edit}
              >
                <Icon use="pencil" style={{ width: 15, height: 15 }} />
              </Button>
              <Button
                fill="outlined"
                onClick={handleArchive}
                shape="icon"
                variant="pink"
                css={styles.button.archive}
              >
                <Icon
                  use={
                    item.active
                      ? "archive-box-arrow-down"
                      : "archive-box-arrow-up"
                  }
                  style={{ width: 15, height: 15 }}
                />
              </Button>
            </Flex>
          )}
        </div>
      </Flex>
    );
  },
);
