import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  empty: {
    root: css({
      display: "flex",
      justifyContent: "center",
      paddingTop: "calc(128px - 16px)",
      "@media (max-width: 1024px)": {
        paddingTop: "unset",
      },
    }),
    text: css({
      color: theme.colors.gray[300],
      fontWeight: 400,
    }),
  },
  header: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  }),
  stats: {
    count: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    root: css({
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    }),
  },
};
