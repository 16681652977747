import React, { useEffect } from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import { ErrorPage } from "~/apps/shared/components/error-page/error-page";

import { TagsProvider } from "../../configurations/views/company/tags/tags.context";
import { useItineraryApproval } from "../itinerary/itinerary-approval.context";
import { ItineraryServiceDetailsDrawer } from "../itinerary/itinerary-content/itinerary-service-details-drawer/itinerary-service-details-drawer";
import { useItineraryInfo } from "../itinerary/itinerary-info.context";
import { ItineraryServiceOutOfPolicyDialog } from "../itinerary/itinerary-service-out-of-policy-dialog/itinerary-service-out-of-policy-dialog";
import { useItineraryServices } from "../itinerary/itinerary-services.context";
import { ItineraryTravelRepeatedOffersDialog } from "../itinerary/itinerary-travel-repeated-offers-dialog/itinerary-travel-repeated-offers-dialog";
import { ItineraryProvider } from "../itinerary/itinerary.context";
import { PaymentActionDialog } from "./payment-action-dialog/payment-action-dialog";
import { PaymentContent } from "./payment-content/payment-content";
import { PaymentHeader } from "./payment-header/payment-header";
import { PaymentLoadingDialog } from "./payment-loading-dialog/payment-loading-dialog";
import { PaymentContainerProvider } from "./payment.container";
import { PaymentProvider, usePayment } from "./payment.context";
import { usePaymentGuard } from "./payment.hooks";
import { styles } from "./styles";

type Props = RouteComponentProps<{
  travelToken: string;
}>;

const Component: React.FC<Props> = () => {
  const { enabled, error } = usePaymentGuard();

  const {
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
  } = useItineraryApproval();
  const { fetchItineraryInfo } = useItineraryInfo();
  const { fetchTravelPoliciesPerOffer } = useItineraryServices();
  const {
    fetchClientInfo,
    fetchTravelFlightCredits,
    fetchTravelPayment,
  } = usePayment();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    void fetchClientInfo();
    void fetchItineraryApprovalHistory();
    void fetchItineraryApprovalStatus();
    void fetchItineraryInfo();
    void fetchTravelFlightCredits();
    void fetchTravelPayment();
    void fetchTravelPoliciesPerOffer();
  }, [
    enabled,
    fetchClientInfo,
    fetchItineraryApprovalHistory,
    fetchItineraryApprovalStatus,
    fetchItineraryInfo,
    fetchTravelFlightCredits,
    fetchTravelPayment,
    fetchTravelPoliciesPerOffer,
  ]);

  if (error) {
    return (
      <>
        <PageTitle title={pageTitles.PAYMENT} />
        <div css={styles.root}>
          <PaymentHeader />
          <ErrorPage {...error} />
        </div>
      </>
    );
  }

  return (
    <PaymentContainerProvider>
      <PageTitle title={pageTitles.PAYMENT} />
      <div css={styles.root}>
        <PaymentHeader />
        <PaymentContent />
      </div>
      <ItineraryServiceDetailsDrawer />
      <ItineraryServiceOutOfPolicyDialog />
      <ItineraryTravelRepeatedOffersDialog isPayment />
      <PaymentActionDialog />
      <PaymentLoadingDialog />
    </PaymentContainerProvider>
  );
};

export const Payment: React.FC<Props> = ({ ...props }) => {
  const { travelToken } = props;

  if (!travelToken) {
    return null;
  }

  return (
    <TagsProvider>
      <ItineraryProvider travelToken={travelToken}>
        <PaymentProvider>
          <Component {...props} />
        </PaymentProvider>
      </ItineraryProvider>
    </TagsProvider>
  );
};
