import React from "react";
import Skeleton from "react-loading-skeleton";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { CAR_POLICY_CATEGORY_TRANSLATION } from "~/apps/shared/constants";

import { useCars } from "../../../cars.context";
import { styles } from "./styles";

export const SidebarSummary: React.FC = () => {
  const { isLoading, policy, handleToggleCategoryExplanation } = useCars();

  return (
    <div css={styles.root}>
      <div css={styles.policy.root}>
        <span css={styles.policy.title}>Sua política</span>
        <div css={styles.policy.content.root}>
          <Icon size={16} use="car" />
          <span css={styles.policy.content.text}>
            Categoria máxima do veículo:{" "}
            {!isLoading
              ? policy && policy.maxCategory
                ? CAR_POLICY_CATEGORY_TRANSLATION[policy.maxCategory]
                : null
              : null}
          </span>
          {isLoading ? <Skeleton height="19.36px" width="64px" /> : null}
          <button
            css={styles.policy.content.button}
            onClick={() => {
              handleToggleCategoryExplanation();
            }}
          >
            <Tooltip arrow content="Entenda as categorias" position="top" />
          </button>
        </div>
      </div>
    </div>
  );
};
