import * as approvalProcessApi from "~/apps/corporate/apis/approval-process.api";
import * as bookingTargetApi from "~/apps/corporate/apis/booking-target.api";
import * as clientApi from "~/apps/corporate/apis/client.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import { GetUsersRequestParams } from "~/apps/corporate/dtos/client.dto";
import { ReenviteUserResponse } from "~/apps/corporate/dtos/user.dto";
import { BookingTargetToAdd } from "~/apps/corporate/models/booking-target.model";
import { User, UserModel } from "~/apps/corporate/models/user.model";
import * as userParser from "~/apps/corporate/parsers/user.parser";
import * as documentApi from "~/apps/shared/apis/documents.api";
import errorTypes, { ERROR } from "~/apps/shared/constants/errors";
import { Document } from "~/apps/shared/models/documents.model";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { formatServiceError } from "~/apps/shared/utils/errors";
import { USER_ERRORS } from "sm-types/errors";

import {
  CreateUserSchema,
  EditUserSchema,
} from "./users/users-user-form/users-user-form.schema";
import { BookerTarget } from "./users/users-user-form/users-user-form.types";

export const createUser = async (data: CreateUserSchema) => {
  const { approvalProcess } = data;

  try {
    const createUserRequest = userParser.parseCreateUserRequest(data);

    const user = await userApi.createUser(createUserRequest);

    if (approvalProcess && approvalProcess.token) {
      await approvalProcessApi.saveApprovalTarget({
        approvalToken: approvalProcess.token,
        targetToken: user.userToken,
      });
    }

    return user;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400) {
        if (data.type === errorTypes.ERROR_INVALID_INPUT) {
          throw { description: "Preenchimento inválido", title: "title" };
        }

        if (data.message === '"email" must be a valid email') {
          throw { description: "Email inválido", title: "Dados inválidos" };
        }
      }

      if (status === 403) {
        throw {
          description:
            "Você não possui permissão o suficiente para realizar essa ação",
          title: "Erro",
        };
      }

      if (status === 409) {
        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_ACTIVE_USER) {
          throw {
            description: "Já existe um usuário ativo cadastrado com este email",
            title: "title",
          };
        }

        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_INACTIVE_USER) {
          throw {
            description:
              "Já existe um usuário inativo cadastrado com este email. Reative o usuário caso queira utilizá-lo",
            title: "title",
          };
        }
      }

      throw { description: "Erro Inesperado", title: "title" };
    } else if (error.request) {
      throw {
        description: "Problemas de conectividade",
        title: "Erro",
      };
    } else {
      throw { description: "Erro Inesperado", title: "title" };
    }
  }
};

export const deleteUser = async (userToken: string) => {
  const result: { error?: Error } = {};

  try {
    await userApi.deleteUser(userToken);
  } catch (error) {
    result.error = error;
  }
  return result;
};

export const editUser = async (data: EditUserSchema) => {
  const { approvalProcess } = data;

  try {
    const editUserRequest = userParser.parseEditUserRequest(data);

    const user = await userApi.editUser(editUserRequest, data.userToken);

    if (approvalProcess && approvalProcess.token) {
      await approvalProcessApi.saveApprovalTarget({
        approvalToken: approvalProcess.token,
        targetToken: data.userToken,
      });
    }

    return user;
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;

      if (status === 400) {
        if (data.type === errorTypes.ERROR_INVALID_INPUT) {
          throw { description: "Preenchimento inválido", title: "title" };
        }

        if (data.message === '"email" must be a valid email') {
          throw { description: "Email inválido", title: "Dados inválidos" };
        }
      }

      if (status === 403) {
        throw {
          description:
            "Você não possui permissão o suficiente para realizar essa ação",
          title: "Erro",
        };
      }

      if (status === 409) {
        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_ACTIVE_USER) {
          throw {
            description: "Já existe um usuário ativo cadastrado com este email",
            title: "title",
          };
        }

        if (data.type === USER_ERRORS.GENERAL.EMAIL_TAKEN_BY_INACTIVE_USER) {
          throw {
            description:
              "Já existe um usuário inativo cadastrado com este email. Reative o usuário caso queira utilizá-lo",
            title: "title",
          };
        }
      }

      throw { description: "Erro Inesperado", title: "title" };
    } else if (error.request) {
      throw {
        description: "Problemas de conectividade",
        title: "Erro",
      };
    } else {
      throw { description: "Erro Inesperado", title: "title" };
    }
  }
};

export const getDocument = async (documentToken: string) => {
  const result: { data?: Document; error?: Error } = {};

  try {
    result.data = await documentApi.getDocument(documentToken);
  } catch (error) {
    result.error = error;
  }
  return result;
};

export const getUser = async (userToken: string) => {
  const result: {
    data?: {
      approvalProcess: any;
      bookerTargets: BookerTarget[];
      documents: Document[];
      user: UserModel;
    };
    error?: Error;
  } = {};

  try {
    const [
      approvalProcess,
      bookerTargets,
      documents,
      user,
    ] = await Promise.all([
      approvalProcessApi.searchApprovalProcesses({ userToken }),
      bookingTargetApi.getUserBookerTargets(userToken),
      documentApi.getUserDocuments(userToken),
      userApi.getUser(userToken),
    ]);

    result.data = {
      approvalProcess,
      bookerTargets,
      documents,
      user,
    };
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: {
        description: "Não foi possível recuperar os dados deste usuário",
        title: "Erro",
      },
    });
  }

  return result;
};

export const getUsers = async (
  params: GetUsersRequestParams,
): Promise<Either<Error, User[]>> => {
  try {
    return success(await clientApi.getUsersRequest(params));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
      }),
    );
  }
};

export const reenviteUser = async (
  userToken: string,
): Promise<Either<Error, ReenviteUserResponse>> => {
  try {
    return success(await userApi.reenviteUser(userToken));
  } catch (error) {
    return failure(formatServiceError({ error }));
  }
};

export const searchBookerTargetsToAdd = async (
  search: string,
  bookerToken: string,
) => {
  const result: { data?: BookingTargetToAdd[]; error?: Error } = {};

  try {
    result.data = await bookingTargetApi.searchBookerTargetsToAdd(
      search,
      bookerToken,
    );
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_BOOKER_TARGET_SEARCH_ERROR,
    });
  }

  return result;
};

export const unarchiveUser = async (userToken: string) => {
  const result: { error?: Error } = {};

  try {
    await userApi.unarchiveUser(userToken);
  } catch (error) {
    result.error = error;
  }
  return result;
};
