import { ApprovalProcessDto } from "../dtos/expense-approval";
import { ApprovableRule } from "../models/expense-approval.model";

export function approvableProcessFactory(
  approvalRule: ApprovalProcessDto
): ApprovableRule {
  return {
    id: approvalRule.id,
    expenseApprovalProcessToken: approvalRule.expense_approval_process_token,
    approvableApprovalProcessToken:
      approvalRule.approvable_approval_process_token,
    approvableType: approvalRule.approvable_type,
    itemToken: approvalRule.item_token,
    type: approvalRule.type,
    rule: approvalRule.rule,
    approvalStages: approvalRule.approval_stages,
    active: approvalRule.active,
    clientToken: approvalRule.client_token,
    createdAt: approvalRule.created_at,
    itemInfo: {
      name: approvalRule.item_info.name,
      token: approvalRule.item_info.token
    },
    approvers: approvalRule.approvers.map(approver => ({
      stage: approver.stage,
      userToken: approver.user_token,
      email: approver.email,
      firstName: approver.first_name,
      lastName: approver.last_name,
      fullName: approver.full_name,
      active: approver.active,
      role: approver.role,
      clientToken: approver.client_token
    }))
  };
}
