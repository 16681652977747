import * as sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import { BrowserTracing } from "@sentry/tracing";

import { App } from "./app";
import * as envUtils from "./apps/shared/utils/env";

import "./service-worker";

ReactDOM.render(<App />, document.getElementById("app"));

if (!envUtils.isDevEnv()) {
  sentry.init({
    debug: envUtils.isDevEnv(),
    dsn:
      "https://43b3ab1e48514aadb02b55407f25ec11@o4504482020261888.ingest.sentry.io/4504482023604224",
    environment: NODE_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
