import React from "react";
import Skeleton from "react-loading-skeleton";

import { HotelSearchInfo } from "~/apps/corporate/models/hotel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { Button } from "@toolkit/v2";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

type HotelResultHeaderProps = {
  toggleView?: () => void;
};

export const HotelResultHeader: React.FC<HotelResultHeaderProps> = ({
  toggleView,
}) => {
  const { loading, searchInfo } = useHotelResult();

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        {toggleView ? (
          <Button shape="icon" onClick={toggleView}>
            <Icon use="chevron-left" />
          </Button>
        ) : null}
      </div>
      <div css={styles.right.root}>
        {loading ? (
          <HotelResultHeaderSearchInfoSkeleton />
        ) : searchInfo ? (
          <HotelResultHeaderSearchInfo searchInfo={searchInfo} />
        ) : null}
      </div>{" "}
    </div>
  );
};

type HotelResultHeaderSearchInfoProps = {
  searchInfo: HotelSearchInfo;
};

const HotelResultHeaderSearchInfo: React.FC<HotelResultHeaderSearchInfoProps> = ({
  searchInfo: { hotelCity, hotelEndDate, hotelInitialDate, hotelState },
}) => {
  const travelEnd = moment(hotelEndDate).format("DD MMM");
  const travelStart = moment(hotelInitialDate).format("DD MMM");

  return (
    <>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Destino</span>
        <span css={styles.search.value}>
          {hotelCity}-{hotelState}
        </span>
      </div>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Check-in</span>
        <span css={styles.search.value}>{travelStart}</span>
      </div>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Check-out</span>
        <span css={styles.search.value}>{travelEnd}</span>
      </div>
    </>
  );
};

const HotelResultHeaderSearchInfoSkeleton: React.FC = () => {
  return (
    <>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Destino</span>
        <Skeleton height="24px" width="92px" />
      </div>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Check-in</span>
        <Skeleton height="24px" width="64px" />
      </div>
      <div css={styles.search.root}>
        <span css={styles.search.title}>Check-out</span>
        <Skeleton height="24px" width="64px" />
      </div>
    </>
  );
};
