import React, { useState } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { Error } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import { ALERT_TYPES } from "@constants";

import { BudgetResult } from "@models/budget.model";

import { useContextFactory } from "@hooks";

import * as budgetsService from "./budgets.service";

interface State {
  budgets: BudgetResult[];
  isLoading: boolean;
  isSubmitting: boolean;
  isArchiving: boolean;
  isToggleDialogOpen: boolean;
  filteredBudgets: BudgetResult[];
  snack: {
    open: boolean;
    type: string;
    message: string;
  };
  periodFilter: string;
  periodDetaildFilter: string | null;
  yearFilter: string;
  isBudgetAlertDialogOpen: boolean;
  budgetToDelete: string;
  isFormOpen: boolean;
  budgetToEdit: BudgetResult | null;
}

interface Actions {
  loadClientBudgets: () => void;
  handlePeriodFilter: (period: string) => void;
  handlePeriodDetaildFilter: (periodDetaild: string) => void;
  handleYearFilter: (year: string) => void;
  handleBudgetAlertDialog: (isOpen: boolean) => void;
  handleDeleteBudget: (budgetToken: string) => void;
  handleSelectedBudgetToDelete: (budgetToken: string) => void;
  handleOpenForm: (budgetToEditToken?: string) => void;
  handleCloseForm: () => void;
}

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

const initialState: State = {
  budgets: [],
  isLoading: false,
  isSubmitting: false,
  isArchiving: false,
  isToggleDialogOpen: false,
  periodFilter: "",
  filteredBudgets: [],
  snack: {
    open: false,
    type: "",
    message: "",
  },
  periodDetaildFilter: null,
  yearFilter: anoAtual.toString(),
  isBudgetAlertDialogOpen: false,
  budgetToDelete: "",
  isFormOpen: false,
  budgetToEdit: null,
};

type ContextProps = State & Actions;

const NewBudgetsContext = React.createContext({} as ContextProps);

export const useNewBudgetsContext: () => ContextProps = useContextFactory(
  "NewBudgetsContext",
  NewBudgetsContext,
);

const NewBudgetsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const { user } = useUser();

  const setSafeState = (newState: Partial<State>) =>
    setState((prevState) => Object.assign({}, prevState, newState));

  const setErrorState = (error: Error) =>
    setSafeState({
      isLoading: false,
      isSubmitting: false,
      isArchiving: false,
      snack: {
        open: true,
        type: ALERT_TYPES.ERROR,
        message: error.description,
      },
    });

  async function loadClientBudgets() {
    if (!user) {
      return;
    }

    const clientToken = user.getClientToken();

    if (!clientToken) {
      logger.error("clientToken not found");

      return;
    }

    setSafeState({ isLoading: true });

    const { data: budgets, error } = await budgetsService.listClientBudgets(
      clientToken,
    );

    if (error) {
      return setErrorState(error);
    }

    setSafeState({
      isLoading: false,
      budgets: budgets,
      filteredBudgets: budgets?.filter((budget: BudgetResult) => {
        return budget.year === Number(state.yearFilter);
      }),
    });
  }

  function handlePeriodFilter(period: string) {
    if (!period) {
      return setSafeState({
        periodFilter: "",
        filteredBudgets: state.budgets.filter((budget: BudgetResult) => {
          return budget.year === Number(state.yearFilter);
        }),
      });
    }

    const filteredBudgetsByPeriod = state.budgets.filter(
      (budget) =>
        budget.timeframe === period && budget.year === Number(state.yearFilter),
    );
    setSafeState({
      periodFilter: period,
      filteredBudgets: filteredBudgetsByPeriod,
      periodDetaildFilter: "",
    });
  }

  function handlePeriodDetaildFilter(periodDetaild: string) {
    if (!periodDetaild) {
      handlePeriodFilter(state.periodFilter || "");
      setSafeState({
        periodDetaildFilter: periodDetaild,
      });
      return;
    }

    let filteredBudgetsByPeriodDetaild: BudgetResult[] = [];

    if (state.periodFilter === "SEMESTER") {
      filteredBudgetsByPeriodDetaild = state.budgets.filter(
        (budget) =>
          budget.semester === Number(periodDetaild) &&
          budget.year === Number(state.yearFilter),
      );
    }

    if (state.periodFilter === "TRIMESTER") {
      filteredBudgetsByPeriodDetaild = state.budgets.filter(
        (budget) =>
          budget.trimester === Number(periodDetaild) &&
          budget.year === Number(state.yearFilter),
      );
    }

    if (state.periodFilter === "MONTH") {
      filteredBudgetsByPeriodDetaild = state.budgets.filter(
        (budget) =>
          budget.month === Number(periodDetaild) &&
          budget.year === Number(state.yearFilter),
      );
    }

    setSafeState({
      periodDetaildFilter: periodDetaild,
      filteredBudgets: filteredBudgetsByPeriodDetaild,
    });
  }

  function handleYearFilter(year: string) {
    const filteredBudgetsByYear = state.budgets.filter(
      (budget) => budget.year === Number(year),
    );

    setSafeState({
      yearFilter: year,
      filteredBudgets: filteredBudgetsByYear,
      periodDetaildFilter: "",
      periodFilter: "",
    });
  }

  function handleBudgetAlertDialog(isOpen: boolean) {
    setSafeState({ isBudgetAlertDialogOpen: isOpen });
  }

  async function handleDeleteBudget() {
    setSafeState({ isLoading: true });

    const { error } = await budgetsService.deleteClientBudget(
      state.budgetToDelete,
    );
    if (error) {
      return setErrorState(error);
    }

    await loadClientBudgets();

    setSafeState({ isBudgetAlertDialogOpen: false });
  }

  function handleSelectedBudgetToDelete(budgetToken: string) {
    setSafeState({ budgetToDelete: budgetToken });
  }

  function handleOpenForm(budgetToEditToken?: string) {
    if (budgetToEditToken) {
      const budgetToEdit = state.budgets.find(
        (budget) => budget.budget_token === budgetToEditToken,
      );
      return setSafeState({ isFormOpen: true, budgetToEdit: budgetToEdit });
    }

    return setSafeState({ isFormOpen: true });
  }

  function handleCloseForm() {
    setSafeState({ isFormOpen: false, budgetToEdit: null });
  }

  return (
    <NewBudgetsContext.Provider
      value={{
        ...state,
        loadClientBudgets,
        handlePeriodFilter,
        handlePeriodDetaildFilter,
        handleYearFilter,
        handleBudgetAlertDialog,
        handleDeleteBudget,
        handleSelectedBudgetToDelete,
        handleOpenForm,
        handleCloseForm,
      }}
    >
      {children}
    </NewBudgetsContext.Provider>
  );
};

export { NewBudgetsProvider };
