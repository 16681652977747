import React from "react";

import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "@styled-system/css";

import { Box, Flex } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { MonthPicker } from "../MonthPicker";

const styles = {
  drawer: css({
    ["& .MuiPaper-root"]: {
      position: "relative",
      width: "100%",
      maxWidth: 500
    }
  }),
  close: css({
    color: defaultTheme.subTextColor,
    fontSize: 32
  })
};

export interface IMonthDateRange {
  startSelectedMonth: number;
  startSelectedYear: number;
  endSelectedMonth: number;
  endSelectedYear: number;
}

interface IProps {
  isOpen: boolean;
  isClearEnabled?: boolean;
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  onRangeApply(data: IMonthDateRange): void;
  onClose(): void;
}

const MonthFilterDrawer: React.FC<IProps> = ({
  isOpen,
  isClearEnabled = false,
  startMonth,
  startYear,
  endMonth,
  endYear,
  onRangeApply,
  onClose
}) => {
  return (
    <Drawer open={isOpen} onClose={onClose} css={styles.drawer}>
      <Box
        py="small"
        position="absolute"
        top="8px"
        right="8px"
        onClick={onClose}
      >
        <CloseIcon css={styles.close} />
      </Box>
      <Flex py="xlarge" px={24} justifyContent="center">
        <MonthPicker
          isClearEnabled={isClearEnabled}
          onRangeApply={onRangeApply}
          startMonth={startMonth}
          startYear={startYear}
          endMonth={endMonth}
          endYear={endYear}
          orientation="vertical"
        />
      </Flex>
    </Drawer>
  );
};

export { MonthFilterDrawer };
