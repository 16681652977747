import React, {
  useEffect,
  useMemo,
  createContext,
  useRef,
  useCallback,
} from "react";
import { useSetState } from "react-use";

import { navigate } from "@reach/router";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { getAllFareDetailsMappedByCarrierCode } from "~/apps/corporate/helpers/flight.helper";
import {
  Bound,
  FareDetailsImproved,
} from "~/apps/corporate/models/flight.model";
import { FlightListOffer } from "~/apps/corporate/models/offer.model";
import { buildAddFlightOfferRequest } from "~/apps/corporate/parsers/flight.parser";
import {
  ALERT_TYPES,
  BOUND_RANGE_TYPES,
  BOUND_TYPES,
  FLIGHT_ORDER_TYPES,
  FLIGHT_ORDER_TYPES_TRANSLATOR,
  SERVICE_TYPES,
} from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { getAllowedClasses } from "~/apps/shared/utils/get-allowed-classes";
import moment from "moment";

import { VisibleFlightsFilter } from "./flights.filter";
import * as flightsHelper from "./flights.helper";
import * as flightService from "./flights.service";
import * as flightsService from "./flights.service";
import {
  BaggageFilter,
  BaggageFilterEnum,
  CarrierFilter,
  ClassFilter,
  State,
  StopFilter,
} from "./flights.types";

interface Actions {
  addOffer(): Promise<void>;
  buildAddServiceData(): any;
  changeFamilyFlight(familyOffer: any): any;
  closeFlightDetails(): void;
  fetchFlightsList(flightToken: string): Promise<void>;
  handleApplyFilters(filterState: any): void;
  handleBaggageToggle(toggledBaggage: any): void;
  handleBoundFilterChange(
    boundType: string,
    rangeType: string,
    range: number[],
  ): void;
  handleCancelReservation(offerToken: string): void;
  handleCarrierToggle(toggledCarrier: any): void;
  handleChangeReserve(e: React.ChangeEvent<HTMLInputElement>): Promise<void>;
  handleClassToggle(toggledItem: any): void;
  handleCloseEdition(): void;
  handleCloseFitlersDrawer(): void;
  handleCloseFlightDetailsDrawer(): void;
  handleCloseOrderingDrawer(): void;
  handleDurationFilterChange(maxRange: number): void;
  handleFlightOrderingClick(orderOption: string): void;
  handleOpenEdition(): void;
  handleOpenFitlersDrawer(): void;
  handleOpenFlightDetailsDrawer(): void;
  handleOpenOrderingDrawer(): void;
  handlePolicyDialogClose(): void;
  handlePolicyDialogOpen(flight: FlightListOffer): void;
  handlePolicyToggle(): void;
  handleReserveCancelationDialogClose(): void;
  handleSelectedClassOnlyToggle(): void;
  handleSetExpiration(expiration: any): void;
  handleSingleFlightFilter(
    flightType: "inbound" | "outbound",
    flightNumber: string,
  ): void;
  handleStopToggle(toggledStop: StopFilter): void;
  selectFlight(flight: FlightListOffer): void;
  setViewed(itineraryId: string): void;
  togglePolicyInfoVisibility(): void;
}

type ContextProps = State &
  Actions & {
    visibleFlights: FlightListOffer[];
  };

const initialState: State = {
  addingSituation: false,
  addServiceDrawer: {
    isOpen: false,
    type: SERVICE_TYPES.flight,
  },
  availableCarriers: [],
  baggage: [
    {
      checked: false,
      label: "Ida inclusa",
      value: BaggageFilterEnum.outbound,
    },
    {
      checked: false,
      label: "Volta inclusa",
      value: BaggageFilterEnum.inbound,
    },
    {
      checked: false,
      label: "Sem bagagem",
      value: BaggageFilterEnum.no_baggage,
    },
  ],
  conflictingInfo: {
    canCancelExistingPreReserve: false,
    error: false,
    flightOffer: null,
    isReservationInConflict: false,
  },
  countryRestriction: null,
  durationFilter: {
    defaultDurationRange: [0, 24 * 3600],
    durationRange: [0, 24 * 3600],
  },
  errorOnFetch: null,
  fareDetailsDrawerIsOpen: false,
  filtering: false,
  flightClasses: [
    { value: "economy", label: "Econômica", checked: false },
    { value: "premium_economy", label: "Econômica Premium", checked: false },
    { value: "business", label: "Business", checked: false },
    { value: "first", label: "Primeira Classe", checked: false },
  ],
  flightDetailsDrawerIsOpen: false,
  flightInfo: null,
  flights: [],
  generalInfo: {
    airportPrices: {},
    averagePrice: undefined,
    bestRecommendedPrice: undefined,
    carrierPrices: {},
    cheapestPrice: undefined,
    optimalPrice: undefined,
    searchResultCount: undefined,
    stopPrices: {},
  },
  hasExpired: false,
  inboundFilter: {
    arrivalRange: [0, 24 * 3600],
    departureRange: [0, 24 * 3600],
  },
  isFakeLoading: true,
  isFiltersDrawerVisible: false,
  isLoadingSelectedFlight: false,
  isOrderingDrawerVisible: false,
  isPolicyDialogOpen: false,
  isPolicyInfoVisible: false,
  isReserveCancelationDialogOpen: false,
  isReserveCancelLoading: false,
  isLoading: false,
  onlyInPolicy: false,
  ordering: false,
  orderOptions: [
    {
      displayAtOneWayTrips: true,
      isSelected: true,
      price: 0,
      title: FLIGHT_ORDER_TYPES_TRANSLATOR[FLIGHT_ORDER_TYPES.RECOMMENDED],
      type: FLIGHT_ORDER_TYPES.RECOMMENDED,
    },
    {
      displayAtOneWayTrips: true,
      isSelected: false,
      price: 0,
      title: FLIGHT_ORDER_TYPES_TRANSLATOR[FLIGHT_ORDER_TYPES.CHEAPEST],
      type: FLIGHT_ORDER_TYPES.CHEAPEST,
    },
    {
      displayAtOneWayTrips: true,
      isSelected: false,
      price: 0,
      title: FLIGHT_ORDER_TYPES_TRANSLATOR[FLIGHT_ORDER_TYPES.FASTEST],
      type: FLIGHT_ORDER_TYPES.FASTEST,
    },
    {
      displayAtOneWayTrips: true,
      isSelected: false,
      title:
        FLIGHT_ORDER_TYPES_TRANSLATOR[
          FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE
        ],
      type: FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE,
    },
    {
      displayAtOneWayTrips: true,
      isSelected: false,
      title:
        FLIGHT_ORDER_TYPES_TRANSLATOR[
          FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL
        ],
      type: FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL,
    },
    {
      displayAtOneWayTrips: false,
      isSelected: false,
      title:
        FLIGHT_ORDER_TYPES_TRANSLATOR[
          FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE
        ],
      type: FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE,
    },
    {
      displayAtOneWayTrips: false,
      isSelected: false,
      title:
        FLIGHT_ORDER_TYPES_TRANSLATOR[
          FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL
        ],
      type: FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL,
    },
  ],
  outboundFilter: {
    arrivalRange: [0, 24 * 3600],
    departureRange: [0, 24 * 3600],
  },
  pageTitle: pageTitles.SEARCH_FLIGHTS,
  planeLimitInfo: {
    clientLimit: 1000, // Valor padrão,
    isLimitExceeded: false,
    planeLimitExceededInfo: {
      inboundFlightCode: "",
      inboundLimitExceeded: false,
      outboundFlightCode: "",
      outboundLimitExceeded: false,
    },
  },
  policy: {
    advance: 0,
    allowedClasses: [],
    maxValueVisible: 0,
    optimalPrice: {
      enabled: false,
      price: 0,
    },
    maxValue: 0,
    priceExcess: 0,
  },
  rewardPolicy: {},
  selectedClassOnly: false,
  selectedFlight: null,
  selectedOutOfPolicy: null,
  singleFlightFilter: {
    inboundChecked: false,
    inboundFlightNumber: null,
    outboundChecked: false,
    outboundFlightNumber: null,
  },
  stops: [
    { value: "zero", label: "Direto", checked: false },
    { value: "one", label: "1 Conexão", checked: false },
    { value: "two", label: "2 ou mais", checked: false },
  ],
  travelerInfo: {},
  travelInfo: {},
  validUntil: null,
  viewedOffers: {},
  visibleFlights: [],
};

const FlightsContext = createContext<ContextProps>({
  ...initialState,
  addOffer: async () => undefined,
  buildAddServiceData: () => null,
  changeFamilyFlight: () => null,
  closeFlightDetails: () => null,
  fetchFlightsList: async () => undefined,
  handleApplyFilters: () => null,
  handleBaggageToggle: () => null,
  handleBoundFilterChange: () => null,
  handleCancelReservation: () => null,
  handleCarrierToggle: () => null,
  handleChangeReserve: async () => undefined,
  handleClassToggle: () => null,
  handleCloseEdition: () => null,
  handleCloseFitlersDrawer: () => null,
  handleCloseFlightDetailsDrawer: () => null,
  handleCloseOrderingDrawer: () => null,
  handleDurationFilterChange: () => null,
  handleFlightOrderingClick: () => null,
  handleOpenEdition: () => null,
  handleOpenFitlersDrawer: () => null,
  handleOpenFlightDetailsDrawer: () => null,
  handleOpenOrderingDrawer: () => null,
  handlePolicyDialogClose: () => null,
  handlePolicyDialogOpen: () => null,
  handlePolicyToggle: () => null,
  handleReserveCancelationDialogClose: () => null,
  handleSelectedClassOnlyToggle: () => null,
  handleSetExpiration: () => null,
  handleSingleFlightFilter: () => null,
  handleStopToggle: () => null,
  selectFlight: async () => undefined,
  setViewed: () => null,
  togglePolicyInfoVisibility: () => null,
});

export const FlightsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useSetState(initialState);

  const orderingSignal = useRef(0);

  const fetchFlightsList = useCallback(async (flightToken: string) => {
    setState({
      errorOnFetch: null,
      isLoading: true,
    });

    const [
      { error: flightsError, data: flightsData },
      { error: rewardPolicyError, data: rewardPolicyData },
    ] = await Promise.all([
      flightsService.getFlights(flightToken),
      flightsService.getClientRewardPolicy(),
    ]);

    if (flightsError) {
      setState({
        errorOnFetch: flightsError,
        isLoading: false,
      });

      return;
    }

    const {
      availableCarriers,
      countryRestriction,
      flightInfo,
      flights,
      generalInfo,
      policy,
      travelerInfo,
      travelInfo,
      validUntil,
    } = flightsData as any;

    const { durationRange } = getMinAndMaxDuration(flights);
    const orderPriceMap = flightsHelper.getOrderPriceMap(flights);

    setState({
      availableCarriers: availableCarriers.map((item: any) => ({
        checked: false,
        label: item.name,
        value: item.code,
      })),
      countryRestriction,
      durationFilter: {
        defaultDurationRange: durationRange,
        durationRange: durationRange,
      },
      flightInfo,
      flights,
      generalInfo,
      isPolicyDialogOpen: false,
      isLoading: false,
      orderOptions: initialState.orderOptions.map((option) => {
        return {
          ...option,
          bound: {
            destination: flightInfo.destinationLocale,
            origin: flightInfo.originLocale,
          },
          price: orderPriceMap[option.type as keyof typeof orderPriceMap] as
            | number
            | undefined,
        };
      }),
      pageTitle: `Resultados de ${flightInfo.originCity} a ${flightInfo.destinationCity}`,
      policy: {
        advance: policy ? policy.advance : 0,
        allowedClasses: policy ? getAllowedClasses(policy.class) : [],
        maxValueVisible: policy ? policy.maxValueVisible : undefined,
        optimalPrice: policy ? policy.optimalPrice : undefined,
        maxValue: policy ? policy.maxValue : undefined,
        priceExcess: policy ? policy.priceExcess : undefined,
      },
      rewardPolicy: rewardPolicyError ? {} : rewardPolicyData,
      travelerInfo,
      travelInfo,
      validUntil,
      visibleFlights: flights,
    });
  }, []);

  const handleBoundFilterChange = (
    boundType: string,
    rangeType: string,
    range: number[],
  ) => {
    if (boundType === BOUND_TYPES.outbound) {
      const currentBoundFilter = state.outboundFilter;
      switch (rangeType) {
        case BOUND_RANGE_TYPES.departure:
          setState({
            outboundFilter: { ...currentBoundFilter, departureRange: range },
          });
          break;
        case BOUND_RANGE_TYPES.arrival:
          setState({
            outboundFilter: { ...currentBoundFilter, arrivalRange: range },
          });
          break;
      }
    } else {
      const currentBoundFilter = state.inboundFilter;
      switch (rangeType) {
        case BOUND_RANGE_TYPES.departure:
          setState({
            inboundFilter: { ...currentBoundFilter, departureRange: range },
          });
          break;
        case BOUND_RANGE_TYPES.arrival:
          setState({
            inboundFilter: { ...currentBoundFilter, arrivalRange: range },
          });
          break;
      }
    }
  };

  const handleDurationFilterChange = (maxRange: number) => {
    const prevDurationFilter = state.durationFilter;
    setState({
      durationFilter: {
        ...prevDurationFilter,
        durationRange: [prevDurationFilter.defaultDurationRange[0], maxRange],
      },
    });
  };

  const handleSelectedClassOnlyToggle = () => {
    const prevValue = state.selectedClassOnly;
    setState({
      selectedClassOnly: !prevValue,
    });
  };

  const handleClassToggle = (toggledItem: ClassFilter) => {
    setState((prev: State) => ({
      ...prev,
      filtering: true,
      flightClasses: prev.flightClasses.map((item) => {
        return toggledItem.value === item.value
          ? { ...item, checked: !item.checked }
          : item;
      }),
    }));
  };

  const handleStopToggle = (toggledStop: StopFilter) => {
    setState((prev: State) => ({
      ...prev,
      filtering: true,
      stops: prev.stops.map((stop) => {
        return toggledStop.value === stop.value
          ? { ...stop, checked: !stop.checked }
          : stop;
      }),
    }));
  };

  const handleCarrierToggle = (toggledCarrier: CarrierFilter) => {
    setState((prev: State) => ({
      ...prev,
      availableCarriers: prev.availableCarriers.map((item) => {
        return toggledCarrier.value === item.value
          ? { ...item, checked: !item.checked }
          : item;
      }),
      filtering: true,
    }));
  };

  const handlePolicyToggle = () => {
    setState((prev: State) => ({
      ...prev,
      filtering: true,
      onlyInPolicy: !prev.onlyInPolicy,
    }));
  };

  const handleBaggageToggle = (toggledBaggage: BaggageFilter) => {
    setState((prev: State) => ({
      ...prev,
      baggage: prev.baggage.map((item) => {
        return toggledBaggage.value === item.value
          ? { ...item, checked: !item.checked }
          : item;
      }),
      filtering: true,
    }));
  };

  const handleOpenEdition = () => {
    const { addServiceDrawer } = state;

    setState({
      addServiceDrawer: Object.assign({}, addServiceDrawer, { isOpen: true }),
    });
  };

  const handleCloseEdition = () => {
    const { addServiceDrawer } = state;

    setState({
      addServiceDrawer: Object.assign({}, addServiceDrawer, { isOpen: false }),
    });
  };

  // #endregion

  // #region Flight Details Actions
  const getFareDetails = (bound: Bound, familyName: string) => {
    const { flightInfo } = state;

    if (!flightInfo) {
      return;
    }

    const allFareDetailsMappedByCarrierCode = getAllFareDetailsMappedByCarrierCode(
      flightInfo.international,
    );

    const formattedFamilyName = familyName
      .replace(/\s+/g, "")
      .toLocaleLowerCase();

    const fareFamilyDetailsInJson =
      bound.carrier && bound.carrier.code in allFareDetailsMappedByCarrierCode
        ? (allFareDetailsMappedByCarrierCode[bound.carrier.code][
            formattedFamilyName
          ] as FareDetailsImproved | undefined)
        : null;

    if (!fareFamilyDetailsInJson) {
      return {
        carryOnLuggage: {
          pieces: 0,
          weight: null,
        },
        checkedBaggage: {
          pieces: bound.baggagePieces,
          weight: null,
        },
      };
    }

    return {
      carryOnLuggage: fareFamilyDetailsInJson.carryOnLuggage,
      changeRule: fareFamilyDetailsInJson.changeRule,
      checkedBaggage: fareFamilyDetailsInJson.checkedBaggage,
      refundRule: fareFamilyDetailsInJson.refundRule,
    };
  };

  const getLegFamily = (
    bound: Bound | undefined,
    familyOfferBound: any,
    supplier: string,
  ) => {
    const result = bound
      ? Object.assign({}, bound, {
          supplier,
          fareDetails: getFareDetails(bound, familyOfferBound.family),
          family: familyOfferBound.family,
          segments: bound.segments.map((item, index) => {
            return Object.assign({}, item, {
              fareInfo: familyOfferBound.fares[index],
              baggage: familyOfferBound.baggage.available,
              baggageWeight: familyOfferBound.baggage.weight,
              baggagePieces: familyOfferBound.baggage.pieces,
            });
          }),
          baggage: familyOfferBound.baggage.available,
          baggageWeight: familyOfferBound.baggage.weight,
          baggagePieces: familyOfferBound.baggage.pieces,
        })
      : undefined;

    return result;
  };

  const changeFamilyFlight = (familyOffer: any) => {
    const { selectedFlight } = state;

    if (!selectedFlight) {
      return;
    }

    const updatedSelectedFlight = Object.assign({}, selectedFlight, {
      available:
        selectedFlight.available || selectedFlight.available === false
          ? selectedFlight.available
          : true,
      fareId: familyOffer.fareId,
      inbound: getLegFamily(
        selectedFlight.inbound,
        familyOffer.inbound,
        familyOffer.supplier,
      ),
      itineraryId: familyOffer.itineraryId,
      originalPrice: familyOffer.price,
      outbound: getLegFamily(
        selectedFlight.outbound,
        familyOffer.outbound,
        familyOffer.supplier,
      ),
      outOfPolicy: familyOffer.outOfPolicy,
      price: familyOffer.price,
      priceDetails: familyOffer.priceDetails,
      searchChannel: familyOffer.supplier,
      selectedItineraryId: familyOffer.itineraryId,
      sourceCode: familyOffer.sourceCode,
      supplier: familyOffer.supplier,
      travelIdentification: familyOffer.travelIdentification,
    });
    setState({ selectedFlight: updatedSelectedFlight });
  };

  const addOffer = async () => {
    const { addingSituation, flightInfo, generalInfo, selectedFlight } = state;

    if (!flightInfo || !selectedFlight) {
      return;
    }

    // to prevent clicks on multiple offers
    if (addingSituation) {
      return;
    }

    setState({
      addingSituation: true,
    });

    try {
      const addFlightOfferRequest = buildAddFlightOfferRequest({
        flight: selectedFlight,
        flightToken: flightInfo.flightToken,
        generalInfo,
        policyStatus: {
          ...state.policy,
          outOfPolicy: selectedFlight.outOfPolicy,
        },
      });

      const { data, error } = await flightService.addFlightOffer(
        addFlightOfferRequest,
      );

      if (error || !data) {
        setState({
          addingSituation: false,
        });

        showSnackMessage("Erro ao adicionar oferta de voo", ALERT_TYPES.ERROR);

        return;
      }

      const { available, hasPriceChanged, verifiedOffer } = data.availability;

      if (!available || hasPriceChanged) {
        setState({
          addingSituation: false,
          selectedFlight: {
            ...selectedFlight,
            available,
            originalPrice: selectedFlight.price,
            price: hasPriceChanged ? verifiedOffer.price : selectedFlight.price,
          },
        });

        return;
      }

      navigate(`/travels/${flightInfo.travelToken}/itinerary`);
      setState({ addingSituation: false });
    } catch (error) {
      setState({
        addingSituation: false,
        selectedFlight: null,
      });
      showSnackMessage(error?.description, ALERT_TYPES.ERROR);
    }
  };

  const selectFlight = async (flight: FlightListOffer) => {
    if (!user) {
      return;
    }

    const { conflictingInfo, flightInfo, planeLimitInfo, travelerInfo } = state;

    if (!flightInfo) {
      return;
    }

    setState({
      fareDetailsDrawerIsOpen: true,
      isLoadingSelectedFlight: true,
      selectedFlight: flight,
    });

    const checkReservationConflictResponse = await flightService.checkReservationConflict(
      {
        flightToken: flightInfo.flightToken,
        inboundCia: flight.inbound ? flight.inbound.carrier?.name : null,
        outboundCia: flight.outbound.carrier?.name,
        travelerToken: travelerInfo.userToken,
      },
    );

    const reservationConflict =
      checkReservationConflictResponse.data &&
      !checkReservationConflictResponse.error
        ? checkReservationConflictResponse.data
        : null;

    const newConflictingInfo = reservationConflict
      ? {
          canCancelExistingPreReserve:
            reservationConflict.canCancelConflictingReserve,
          error: false,
          flightOffer: reservationConflict.conflictingOffer,
          isReservationInConflict: !reservationConflict.canPreReserve,
        }
      : conflictingInfo;

    const checkPlaneClientLimitResponse = await flightService.checkPlaneClientLimit(
      {
        clientToken: user.getClientToken(),
        offerData: {
          inbound: flight.inbound ? flight.inbound : null,
          outbound: flight.outbound,
        },
      },
    );

    const newPlaneLimitInfo =
      checkPlaneClientLimitResponse.data && !checkPlaneClientLimitResponse.error
        ? checkPlaneClientLimitResponse.data
        : planeLimitInfo;

    const fareFamiliesResult = await flightsService.listFlightFareFamilies(
      flightInfo.flightToken,
      {
        search_id: flight.supplierSearchId,
        itinerary_id: String(flight.itineraryId),
      },
    );

    if (fareFamiliesResult.error) {
      setState({
        isLoadingSelectedFlight: false,
        selectedFlight: null,
        fareDetailsDrawerIsOpen: false,
      });

      return showSnackMessage(
        "Erro ao listar famílias tarifárias da oferta.",
        ALERT_TYPES.ERROR,
      );
    }

    setState({
      conflictingInfo: newConflictingInfo,
      isLoadingSelectedFlight: false,
      planeLimitInfo: newPlaneLimitInfo,
      selectedFlight: {
        ...flight,
        familyOffers: flight.familyOffers.length
          ? flight.familyOffers
          : fareFamiliesResult.data.fareFamilies,
        reserve: false,
      },
    });
  };

  const handleChangeReserve = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = e.target;
    const { conflictingInfo } = state;

    if (!checked) {
      setState((prev: State) => ({
        ...prev,
        selectedFlight: {
          ...prev.selectedFlight,
          reserve: checked,
        },
      }));

      return;
    }

    if (conflictingInfo.isReservationInConflict) {
      setState((prev: State) => ({
        ...prev,
        isReserveCancelationDialogOpen: true,
      }));

      return;
    }

    setState((prev: State) => ({
      ...prev,
      selectedFlight: {
        ...prev.selectedFlight,
        reserve: checked,
      },
    }));
  };
  // todo: watch
  const handleCancelReservation = async (offerToken: string) => {
    setState((prev: State) => ({
      ...prev,
      isReserveCancelLoading: true,
    }));

    const cancelationResponse = await flightService.cancelPreReserve({
      offerToken,
    });

    if (cancelationResponse.error) {
      setState((prev: State) => ({
        ...prev,
        conflictingInfo: {
          ...prev.conflictingInfo,
          error: true,
        },
        isReserveCancelLoading: false,
        selectedFlight: {
          ...prev.selectedFlight,
          reserve: false,
        },
      }));

      showSnackMessage("Erro ao cancelar pré-reserva", ALERT_TYPES.ERROR);

      return;
    }

    setState((prev: State) => ({
      ...prev,
      conflictingInfo: {
        ...prev.conflictingInfo,
        error: false,
      },
      isReserveCancelationDialogOpen: false,
      isReserveCancelLoading: false,
      selectedFlight: {
        ...prev.selectedFlight,
        reserve: true,
      },
    }));

    showSnackMessage("Pré-reserva cancelada com sucesso!", ALERT_TYPES.SUCCESS);
  };

  const handleReserveCancelationDialogClose = () => {
    const { isReserveCancelLoading } = state;
    if (isReserveCancelLoading) {
      return;
    }

    setState((prev: State) => ({
      ...prev,
      isReserveCancelationDialogOpen: false,
    }));
  };

  const closeFlightDetails = () => {
    // prevents drawen from closing if it is adding an offer
    if (state.addingSituation) {
      return;
    }

    setState({
      fareDetailsDrawerIsOpen: false,
      selectedFlight: null,
    });
  };

  // #endregion

  const handlePolicyDialogOpen = (flight: FlightListOffer) => {
    setState({
      isPolicyDialogOpen: true,
      selectedOutOfPolicy: flight,
    });
  };

  const handlePolicyDialogClose = () => {
    setState({
      isPolicyDialogOpen: false,
      selectedOutOfPolicy: null,
    });
  };

  const setViewed = (itineraryId: string) => {
    const { viewedOffers } = state;

    setState({
      viewedOffers: {
        ...viewedOffers,
        [itineraryId]: itineraryId,
      },
    });
  };

  const handleSingleFlightFilter = (
    flightType: "inbound" | "outbound",
    flightNumber: string,
  ) => {
    const { singleFlightFilter } = state;

    setState({
      singleFlightFilter: {
        ...singleFlightFilter,
        [`${flightType}FlightNumber`]:
          singleFlightFilter[`${flightType}FlightNumber` as const] !==
          flightNumber
            ? flightNumber
            : null,
        [`${flightType}Checked`]: !singleFlightFilter[
          `${flightType}Checked` as const
        ],
      },
    });
  };

  const handleApplyFilters = (filterState: any) => () => {
    setState({
      ...filterState,
      isFiltersDrawerVisible: false,
    });
  };

  const handleOpenFlightDetailsDrawer = () => {
    setState((prev: State) => ({
      ...prev,
      flightDetailsDrawerIsOpen: true,
    }));
  };

  const handleCloseFlightDetailsDrawer = () => {
    setState((prev: State) => ({
      ...prev,
      flightDetailsDrawerIsOpen: false,
      selectedFlight: null,
    }));
  };

  const handleOpenFitlersDrawer = () =>
    setState({ isFiltersDrawerVisible: true });

  const handleCloseFitlersDrawer = () =>
    setState({ isFiltersDrawerVisible: false });

  const handleOpenOrderingDrawer = () =>
    setState({ isOrderingDrawerVisible: true });

  const handleCloseOrderingDrawer = () =>
    setState({ isOrderingDrawerVisible: false });

  const togglePolicyInfoVisibility = () =>
    setState((prev: State) => ({
      ...prev,
      isPolicyInfoVisible: !prev.isPolicyInfoVisible,
    }));

  const handleFlightOrderingClick = (orderOption: string) => {
    const updatedOrderOptions = state.orderOptions.map((option) => ({
      ...option,
      isSelected: option.type === orderOption,
    }));

    setState({ orderOptions: updatedOrderOptions });
  };

  const buildAddServiceData = () => {
    const { flightInfo, travelInfo } = state;

    if (!flightInfo) {
      return;
    }

    const oneway = flightInfo.oneway;
    const travelToken = travelInfo.travelToken;

    const initialEditOrigin = {
      CityId: flightInfo.originCityId,
      CountryId: "",
      CountryName: flightInfo.originCountry,
      label: flightInfo.originName,
      PlaceId: flightInfo.originLocale,
      PlaceName: flightInfo.originName,
      RegionId: "",
    };

    const initialEditDestination = {
      CityId: flightInfo.destinationCityId,
      CountryId: "",
      CountryName: flightInfo.destinationCountry,
      label: flightInfo.destinationName,
      PlaceId: flightInfo.destinationLocale,
      PlaceName: flightInfo.destinationName,
      RegionId: "",
    };

    const initialStartDate = flightInfo.outboundFlightDate;
    const initialEndDate = flightInfo.inboundFlightDate;

    return {
      cabinClass: flightInfo.cabinClass,
      destination: initialEditDestination,
      endDate: initialEndDate ? moment(initialEndDate) : null,
      origin: initialEditOrigin,
      startDate: moment(initialStartDate),
      travelToken: travelToken,
      type: oneway ? "oneway" : "roundtrip",
    };
  };

  const handleSetExpiration = useCallback((expiration) => {
    setState({ hasExpired: expiration });
  }, []);

  /* Helpers */

  function getMinAndMaxDuration(flights: FlightListOffer[]) {
    const range = flights.reduce(
      (acc, current) => {
        const outboundDuration = current.outbound.durationMinutes * 60;

        const inboundDuration = current.inbound
          ? current.inbound.durationMinutes * 60
          : 0;

        if (acc.durationRange.every((item) => item === 0)) {
          acc.durationRange = [outboundDuration, outboundDuration];
        } else if (outboundDuration > acc.durationRange[1]) {
          acc.durationRange[1] = outboundDuration;
        } else if (outboundDuration < acc.durationRange[0]) {
          acc.durationRange[0] = outboundDuration;
        }

        if (inboundDuration && acc.durationRange.every((item) => item === 0)) {
          acc.durationRange = [inboundDuration, inboundDuration];
        } else if (inboundDuration && inboundDuration > acc.durationRange[1]) {
          acc.durationRange[1] = inboundDuration;
        } else if (inboundDuration && inboundDuration < acc.durationRange[0]) {
          acc.durationRange[0] = inboundDuration;
        }

        return acc;
      },
      {
        durationRange: [0, 0],
      },
    );

    const maxRangeMinutes = range.durationRange[1] / 60;
    if (maxRangeMinutes % 15 !== 0) {
      const diff = 15 - (maxRangeMinutes % 15);
      const updatedMaxRange = (maxRangeMinutes + diff) * 60;

      range.durationRange[1] = updatedMaxRange;
    }

    return range;
  }

  const visibleFlights = useMemo(() => {
    const {
      availableCarriers,
      baggage,
      durationFilter,
      filtering,
      flightClasses,
      flightInfo,
      flights,
      inboundFilter,
      onlyInPolicy,
      outboundFilter,
      selectedClassOnly,
      singleFlightFilter,
      stops,
    } = state;

    if (!flightInfo) {
      return [];
    }

    const visibleFlights = new VisibleFlightsFilter({
      availableCarriers,
      baggage,
      durationFilter,
      flightClasses,
      inboundFilter,
      onlyInPolicy,
      outboundFilter,
      selectedCabinClass: flightInfo.cabinClass,
      selectedClassOnly,
      singleFlightFilter,
      stops,
    }).getVisibleFlights(flights);

    if (filtering) {
      setState({ filtering: false });
    }

    return visibleFlights;
  }, [
    orderingSignal.current,
    state.availableCarriers,
    state.baggage,
    state.durationFilter,
    state.flightClasses,
    state.flightInfo,
    state.inboundFilter,
    state.onlyInPolicy,
    state.outboundFilter,
    state.selectedClassOnly,
    state.singleFlightFilter,
    state.stops,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setState({
        isFakeLoading: false,
      });
    }, 3000);
  }, []);

  useEffect(() => {
    setState({ ordering: true });

    const orderingType = state.orderOptions.find((option) => option.isSelected);
    let flights = state.flights;
    if (orderingType?.type === FLIGHT_ORDER_TYPES.RECOMMENDED) {
      flights = flightsHelper.orderFlightListByPriceAndDuration(state.flights);
    } else if (orderingType?.type === FLIGHT_ORDER_TYPES.CHEAPEST) {
      flights = flightsHelper.orderFlightListByPrice(state.flights);
    } else if (orderingType?.type === FLIGHT_ORDER_TYPES.FASTEST) {
      flights = flightsHelper.orderFlightListByDuration(state.flights);
    } else if (
      orderingType?.type === FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE
    ) {
      flights = flightsHelper.orderFlightListByEarliestOutboundDeparture(
        state.flights,
      );
    } else if (
      orderingType?.type === FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE
    ) {
      flights = flightsHelper.orderFlightListByEarliestInboundDeparture(
        state.flights,
      );
    } else if (
      orderingType?.type === FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL
    ) {
      flights = flightsHelper.orderFlightListByEarliestOutboundArrival(
        state.flights,
      );
    } else if (
      orderingType?.type === FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL
    ) {
      flights = flightsHelper.orderFlightListByEarliestInboundArrival(
        state.flights,
      );
    }

    setState({
      flights,
      ordering: false,
    });

    // bump ordering count
    orderingSignal.current += 1;
  }, [state.orderOptions]);

  return (
    <FlightsContext.Provider
      value={{
        ...state,
        addOffer,
        buildAddServiceData,
        changeFamilyFlight,
        closeFlightDetails,
        fetchFlightsList,
        handleApplyFilters,
        handleBaggageToggle,
        handleBoundFilterChange,
        handleCancelReservation,
        handleCarrierToggle,
        handleChangeReserve,
        handleClassToggle,
        handleCloseEdition,
        handleCloseFitlersDrawer,
        handleCloseFlightDetailsDrawer,
        handleCloseOrderingDrawer,
        handleDurationFilterChange,
        handleFlightOrderingClick,
        handleOpenEdition,
        handleOpenFitlersDrawer,
        handleOpenFlightDetailsDrawer,
        handleOpenOrderingDrawer,
        handlePolicyDialogClose,
        handlePolicyDialogOpen,
        handlePolicyToggle,
        handleReserveCancelationDialogClose,
        handleSelectedClassOnlyToggle,
        handleSetExpiration,
        handleSingleFlightFilter,
        handleStopToggle,
        selectFlight,
        setViewed,
        togglePolicyInfoVisibility,
        visibleFlights,
      }}
    >
      {children}
    </FlightsContext.Provider>
  );
};

export const useFlights = useContextFactory("FlightsContext", FlightsContext);
