import React, { useCallback, useState } from "react";

import BatchFileUploaderDialog from "@components/shared/batch-file-uploader/BatchFileUploaderDialog";

type BatchFileUploaderProps = {
  onDownloadModelClick: () => void;
  onSuccessUpload: () => Promise<unknown>;
  onUpload: (file: File) => Promise<void>;
};

export const BatchFileUploader = ({
  children,
  onDownloadModelClick,
  onSuccessUpload,
  onUpload,
}: BatchFileUploaderProps & {
  children:
    | React.ReactElement
    | (({ openDialog }: { openDialog: () => void }) => JSX.Element);
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const openDialog = useCallback(() => {
    setShowDialog(true);
  }, []);

  return (
    <>
      {typeof children === "function" ? children({ openDialog }) : children}
      {showDialog ? (
        <BatchFileUploaderDialog
          onClose={closeDialog}
          onDownloadModelClick={onDownloadModelClick}
          onSuccessUpload={onSuccessUpload}
          onUpload={onUpload}
          open={showDialog}
        />
      ) : null}
    </>
  );
};
