import React, { useCallback } from "react";

import { Button } from "@toolkit/v2";

import { useItineraryPendencies } from "../../../itinerary-pendencies.context";
import { useItineraryScreen } from "../../../itinerary-screen.context";
import { useItineraryContainer } from "../../../itinerary.container";
import { styles } from "./styles";

export const PendenciesProceedButton: React.FC = () => {
  const { pendenciesModel, servicesModel } = useItineraryContainer();

  const { offersTravelers } = useItineraryPendencies();
  const { proceedFromPendencies } = useItineraryScreen();

  const handleProceedToFinalization = useCallback(async () => {
    await proceedFromPendencies();
  }, [proceedFromPendencies]);

  const hasEnoughTravelers =
    offersTravelers && servicesModel
      ? servicesModel.hasEnoughTravelers(offersTravelers)
      : false;

  if (!pendenciesModel) {
    return null;
  }

  const hasBlockingPendencies = pendenciesModel.hasBlockingPendencies();

  return (
    <div css={styles.root}>
      <Button
        css={styles.button}
        disabled={hasBlockingPendencies || !hasEnoughTravelers}
        onClick={async () => {
          await handleProceedToFinalization();
        }}
      >
        Prosseguir
      </Button>
      <span css={styles.message}>
        Fique tranquilo, você ainda não será cobrado.
      </span>
    </div>
  );
};
