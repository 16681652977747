import React from "react";

import { CircularSpinner } from "@toolkit/v2";

import { styles } from "./styles";

export const PolicyFormLoading: React.FC = () => {
  return (
    <div css={styles.root}>
      <CircularSpinner />
    </div>
  );
};
