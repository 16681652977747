import React, { useCallback, useEffect, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import useDebounce from "react-use/lib/useDebounce";

import { theme } from "@skin/v2";

import { CircularSpinner } from "@toolkit/v2";

import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { Error } from "../../types";
import { Input } from "../input/input";
import { styles } from "./styles";

type Result = {
  data?: any[];
  error?: Error;
};

const defaultGetItemValue = (item: { label: string }) => {
  return item.label;
};

const defaultMenuStyle: React.CSSProperties = {
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.gray[100]}`,
  borderRadius: "8px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  maxHeight: "288px",
  left: "0",
  listStyle: "none",
  overflowY: "auto",
  position: "absolute",
  right: "0",
  top: "calc(100% + 4px)",
  width: "100%",
  zIndex: 10,
};

const defaultRenderInput = ({
  loading,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  customOnClick?: () => void;
  loading: boolean;
}): JSX.Element => {
  return (
    <div css={styles.input.root}>
      <Input css={styles.input.input} {...props} />
      {loading ? <CircularSpinner size={16} /> : null}
    </div>
  );
};

const defaultWrapperStyle: React.CSSProperties = {
  display: "block",
  overflow: "visible",
  position: "relative",
};

const defaultRenderItem = ({
  isHighlighted,
  item,
  ...props
}: React.HTMLAttributes<HTMLLIElement> & {
  isHighlighted: boolean;
  item: any;
}): JSX.Element => {
  if (item.error) {
    return (
      <li
        css={styles.menu.item({ isDisabled: true, isHighlighted: false })}
        key={0}
        {...props}
      >
        Nenhum resultado.
      </li>
    );
  }

  return (
    <li
      css={styles.menu.item({ isHighlighted })}
      key={JSON.stringify(item)}
      {...props}
    >
      {item.label}
    </li>
  );
};

export const AsyncAutocomplete: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof Autocomplete>,
    | "getItemValue"
    | "handleSelect"
    | "items"
    | "onChange"
    | "onSelect"
    | "renderInput"
    | "renderItem"
    | "value"
  > &
    Partial<
      Pick<React.ComponentPropsWithoutRef<typeof Autocomplete>, "getItemValue">
    > & {
      clearOnSelect?: boolean;
      debounce?: number;
      defaultItems?: any[];
      fetchItems: (search: string) => Promise<Result>;
      filterItems?: (items: any[]) => any[];
      onClear?: () => void;
      onSelect: (item: any) => void;
      openOnInputClick?: boolean;
      renderInput?: typeof defaultRenderInput;
      renderItem?: typeof defaultRenderItem;
    } & Pick<React.HTMLProps<HTMLDivElement>, "className"> &
    (
      | {
          defaultValue?: string;
          onChange?: never;
          value?: never;
        }
      | {
          defaultValue?: never;
          onChange?: (value: string) => void;
          value?: string | null;
        }
    )
> = ({
  clearOnSelect,
  debounce = 500,
  defaultItems,
  defaultValue = "",
  fetchItems,
  filterItems = (items) => items,
  getItemValue = defaultGetItemValue,
  menuStyle = defaultMenuStyle,
  onClear,
  onSelect,
  openOnInputClick,
  renderInput = defaultRenderInput,
  renderItem = defaultRenderItem,
  wrapperStyle = defaultWrapperStyle,
  ...props
}) => {
  const [items, setItems] = useState([{ error: "NO_RESULTS", label: "" }]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const isFirstRender = useRef<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);

  const clear = useCallback(() => {
    setItems([]);
    setLoading(false);
    setOpen(false);
    setValue("");

    if (onClear) {
      onClear();
    }
  }, [onClear]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (props.onChange) {
        props.onChange(value);
      }

      if (!value) {
        clear();

        return;
      }

      setOpen(true);
      setValue(value);
    },
    [clear, props.onChange],
  );

  const handleFetchItems = useCallback(async () => {
    if (!value) {
      setItems([]);

      return;
    }

    setLoading(true);

    const result: Result = await fetchItems(value);

    if (!result.error) {
      if (!result.data || result.data.length === 0) {
        setItems([{ error: "NO_RESULTS", label: "" }]);
        setLoading(false);

        return;
      }

      setItems(filterItems(result.data));
      setLoading(false);

      return;
    }

    setItems([{ error: result.error.description, label: "" }]);
    setLoading(false);
  }, [fetchItems, filterItems, value]);

  const handleSelect = useCallback(
    (value: string, item: any) => {
      onSelect(item);

      if (clearOnSelect) {
        clear();

        return;
      }

      setOpen(false);
      setValue(value);
    },
    [clearOnSelect, onSelect],
  );

  useDebounce(
    async () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;

        return;
      }

      await handleFetchItems();
    },
    debounce,
    [value],
  );

  useEffect(() => {
    if (props.value === undefined || props.value === value) {
      return;
    }

    if (!props.value) {
      setValue("");

      return;
    }

    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!defaultItems) {
      return;
    }

    setItems(defaultItems);
  }, [defaultItems]);

  useOnClickOutside(() => {
    setOpen(false);
  }, ref);

  return (
    <div className={props.className} ref={ref}>
      <Autocomplete
        {...props}
        getItemValue={getItemValue}
        items={items}
        menuStyle={menuStyle}
        onChange={handleChange}
        onSelect={handleSelect}
        open={props.open ? props.open : items.length > 0 && open}
        renderInput={(props) =>
          renderInput({
            loading,
            ...(openOnInputClick && {
              customOnClick: () => {
                setOpen(true);
              },
            }),
            ...props,
          })
        }
        renderItem={(item, isHighlighted) =>
          renderItem({
            item,
            isHighlighted,
          })
        }
        value={value}
        wrapperStyle={wrapperStyle}
      />
    </div>
  );
};
