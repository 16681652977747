import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Text
} from "recharts";
import { colors } from "@theme";
import { toCurrency } from "@helpers";
import { FLIGHT_CARRIERS_COLORS } from "~/constants";

const colorsArr = [
  colors.scooter,
  colors.jungleGreen,
  colors.cerulean,
  colors.saffron,
  colors.brinkPink,
  colors.purple,
  colors.fireBush,
  colors.sunglo,
  colors.dodgerBlue,
  colors.punch
];

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
    carrier
  } = props;

  return (
    <g>
      <Text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill={fill}
        width={160}
        style={{ textTransform: "capitalize", fontSize: 14 }}
      >
        {carrier}
      </Text>
      <Text
        x={cx}
        y={cy}
        dy={16}
        textAnchor="middle"
        fill="#999"
        width={160}
        style={{ fontSize: 12 }}
      >
        {`(${toCurrency(value)})`}
      </Text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const SpentByCompanyChart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  function onPieEnter(_, index) {
    setActiveIndex(index);
  }

  return (
    <ResponsiveContainer width="100%" height={220}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          innerRadius={80}
          dataKey="total"
          nameKey="carrier"
          onMouseEnter={onPieEnter}
        >
          {data.map((item, index) => (
            <Cell
              key={`cell-${index}`}
              fill={FLIGHT_CARRIERS_COLORS[item.carrier] || colorsArr[index]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { SpentByCompanyChart };
