import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  avatars: {
    add: css({
      backgroundColor: "white",
      border: `1px dashed ${theme.colors.gray[100]}`,
      borderRadius: "50%",
      color: theme.colors.gray[300],
      display: "grid",
      height: "36px",
      placeItems: "center",
      width: "36px",
      ":disabled": {
        opacity: 0.5,
      },
      ":not(:disabled)": {
        cursor: "pointer",
      },
    }),
    avatar: {
      avatar: ({ zIndex }: { zIndex: number }) =>
        css({
          height: "36px",
          width: "36px",
          zIndex,
        }),
      remove: ({ zIndex }: { zIndex: number }) =>
        css({
          backgroundColor: "white",
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "50%",
          color: theme.colors.gray[500],
          display: "grid",
          fontSize: "0.75rem",
          height: "16px",
          opacity: 0,
          placeItems: "center",
          position: "absolute",
          right: 0,
          top: 0,
          transition: "opacity 0.15s ease-in-out",
          width: "16px",
          zIndex: zIndex + 1,
          ":disabled": {
            opacity: 0.5,
          },
          ":not(:disabled)": {
            cursor: "pointer",
          },
        }),
      root: css({
        border: "none",
        position: "relative",
        "&:hover > :first-of-type": {
          opacity: 1,
        },
      }),
    },
    root: css({}),
  },
};
