import React from "react";
import { Input } from "@shared/inputs";
import MenuItem from "@material-ui/core/MenuItem";
import { Text } from "@toolkit";
import isEmpty from "lodash/isEmpty";
import { css } from "@styled-system/css";

const styles = {
  input: css({
    width: "100%"
  }),
  menuItem: css({
    width: "100%",

    ["span"]: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  })
};

interface Props {
  valueKey: string;
  labelKey: string;
  options: any[];
  [key: string]: any;
}

const GenericSelect = ({ valueKey, labelKey, options, ...rest }: Props) => {
  return (
    <Input {...rest} select={true} css={styles.input}>
      {options.map(option => (
        <MenuItem
          key={option[valueKey]}
          value={option[valueKey]}
          css={styles.menuItem}
        >
          <Text fontSize={[1, 2]}>{option[labelKey]}</Text>
        </MenuItem>
      ))}
      {isEmpty(options) && (
        <MenuItem value="" disabled={true} css={styles.menuItem}>
          <Text fontSize={[1, 2]}>Nenhuma opção encontrada</Text>
        </MenuItem>
      )}
    </Input>
  );
};

export { GenericSelect };
