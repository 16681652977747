import moment, { Moment } from "moment";

export const differenceInDays = (
  startDate: string | Moment,
  endDate: string | Moment,
) => {
  if (typeof endDate !== "string" && "diff" in endDate) {
    return endDate.diff(startDate, "days");
  }

  return moment(endDate).diff(moment(startDate), "days");
};
