import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  approvers: {
    stage: {
      approver: {
        avatar: css({
          minWidth: "32px !important",
          "& > :first-of-type": {
            flexShrink: 0,
            "& > :first-of-type": {
              height: "32px !important",
              width: "32px !important",
            },
          },
        }),
        name: css({
          fontWeight: 600,
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
          wordBreak: "break-all",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
        fontWeight: 600,
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
  },
  button: css({
    justifyContent: "center",
    width: "100%",
  }),
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    width: "100%",
  }),
  form: {
    justification: {
      info: css({
        color: theme.colors.gray[700],
        fontSize: "0.75rem",
        fontWeight: 700,
      }),
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
      textarea: css({
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        fontFamily: theme.fonts.inter,
        height: "148px",
        outline: "none",
        padding: "13px 16px",
        resize: "none",
        transition: "all 0.15s ease-in-out",
        width: "100%",
        "&:focus": {
          borderColor: theme.colors.gray[500],
        },
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
  },
  header: {
    description: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
      textAlign: "left",
      width: "fit-content",
    }),
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      fontFamily: theme.fonts.inter,
      gap: "4px",
      width: "100%",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
      width: "fit-content",
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "100%",
  }),
  title: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
};
