import React, { useContext } from "react";

import DeletionDialog from "@components/shared/dialogs/DeletionDialog";

import { CostCentersContext } from "../CostCenters.context";

const ArchiveDialog = () => {
  const {
    selectedToArchive,
    isArchiving,
    proccessArchiveCostCenter,
    handleCloseArchiveDialog,
  } = useContext(CostCentersContext);

  return (
    <DeletionDialog
      open={!!selectedToArchive}
      title="Arquivar centro de custos"
      deleteButtonLabel="Arquivar"
      handleClose={handleCloseArchiveDialog}
      handleDeletion={proccessArchiveCostCenter}
      isDeleting={isArchiving}
      message={
        <span>Deseja realmente arquivar o centro de custo selecionado?</span>
      }
    />
  );
};

export { ArchiveDialog };
