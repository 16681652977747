import React from "react";

import { Tab } from "@material-ui/core";

import { styles } from "./Tabs.styles";

const defaultClasses = {
  root: styles.tab,
  selected: styles.selected
};

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof Tab>,
  "label" | "value"
> & {
  label: string;
  value: any;
};

export default function TabsItem({ classes, label, value, ...props }: Props) {
  return (
    <Tab
      classes={{ ...defaultClasses, ...classes }}
      label={label}
      value={value}
      {...props}
    />
  );
}
