import * as areasApi from "~/apps/corporate/apis/areas.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

export const addAreaToBoard = async (area: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.updateArea(area);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_ADD_AREA_TO_BOARD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_ADD_AREA_TO_BOARD_ERROR;
    }
  }

  return result;
};

export const createNewBoard = async (board: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.createArea(board);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_BOARD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_BOARD_ERROR;
    }
  }

  return result;
};

export const fetchAreas = async (searchText: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.fetchAreasBySearchText(searchText);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR;
    }
  }

  return result;
};

export const fetchBoard = async (companyAreaToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.fetchArea(companyAreaToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_BOARD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_BOARD_ERROR;
    }
  }

  return result;
};

export const fetchClientBoards = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.fetchClientAreas();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_BOARDS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_FETCH_CLIENTS_BOARDS_ERROR;
    }
  }

  return result;
};

export const removeAreaFromBoard = async (area: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.updateArea(area);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_REMOVE_AREA_FROM_BOARD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_REMOVE_AREA_FROM_BOARD_ERROR;
    }
  }

  return result;
};

export const updateBoard = async (board: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.updateArea(board);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_BOARD_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_BOARD_ERROR;
    }
  }

  return result;
};

export const updateBoardDisplayNameFromClientConfig = async (
  clientConfigToken: string,
  boardName: string,
) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await areasApi.updateBoardDisplayNameFromClientConfig(
      clientConfigToken,
      boardName,
    );
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR;
    }
  }

  return result;
};
