import { css } from "@styled-system/css";

export const styles = {
  left: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "fit-content"
  }),
  root: css({
    display: "flex",
    justifyContent: "space-between",
    padding: "0 32px",
    "@media (max-width: 768px)": {
      padding: "0 16px"
    }
  })
};
