import {
  GetUserCostCentersResponse,
  CostCenterListResponseItem,
  CostCenterResponse,
} from "../dtos/cost-center-dto";
import {
  CostCenter,
  CostCenterListItem,
  UserCostCenter,
} from "../models/cost-center.model";
import * as userParser from "./user.parser";

export function parseCostCenter({
  active,
  client_token,
  cost_center_token,
  created_at,
  id,
  name,
  total_users,
  users,
}: CostCenterResponse): CostCenter {
  return {
    active,
    clientToken: client_token,
    createdAt: created_at,
    id,
    name,
    token: cost_center_token,
    userCount: total_users,
    users: users.map((user) => userParser.UserFactory(user)),
  };
}

export function parseUserCostCenters(dto: GetUserCostCentersResponse) {
  return dto.map((userCostCenterDto) => {
    const userCostCenter: UserCostCenter = {
      active: userCostCenterDto.active,
      clientToken: userCostCenterDto.client_token,
      createdAt: userCostCenterDto.created_at,
      id: userCostCenterDto.id,
      name: userCostCenterDto.name,
      token: userCostCenterDto.cost_center_token,
    };

    return userCostCenter;
  });
}

export function parseCostCenterListItem({
  active,
  client_token,
  cost_center_token,
  created_at,
  id,
  name,
  total_users,
}: CostCenterListResponseItem): CostCenterListItem {
  return {
    active,
    clientToken: client_token,
    createdAt: created_at,
    id,
    name,
    token: cost_center_token,
    userCount: total_users,
  };
}
