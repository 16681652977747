import React from "react";

import { css } from "@styled-system/css";
import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";

import { Button } from "@toolkit/v2";

import { boardBatchApi } from "~/apis/excel.api";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    marginTop: "1.5rem",
  }),
  button: css({
    height: "3rem",
  }),
  controls: {
    root: css({
      alignItems: "center",
      display: "flex",
      gap: "1.5rem",
    }),
  },
};

interface IBoardHeaderProps {
  boardName: string | null;
  handleBtnClick: () => void;
  searchInput: string;
  handleSearchOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBatchUploadSuccess: () => void;
}

export const BoardHeader: React.FC<IBoardHeaderProps> = ({
  boardName,
  handleBtnClick,
  searchInput,
  handleSearchOnChange,
  onBatchUploadSuccess,
}) => {
  return (
    <div css={styles.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleBtnClick} variant="pink" css={styles.button}>
          {`Adicionar ${boardName}`}
        </Button>
        <BatchFileUploader
          onBatchUpload={boardBatchApi.onUpload}
          onBatchDownloadModelClick={boardBatchApi.onDownloadModelClick}
          onBatchUploadSuccess={onBatchUploadSuccess}
        >
          {({ openDialog }) => (
            <Button fill="outlined" onClick={openDialog} css={styles.button}>
              <Icon use="cloud-arrow-up" />
              Cadastrar via excel
            </Button>
          )}
        </BatchFileUploader>
      </div>
      <SearchInput
        onChange={handleSearchOnChange}
        placeholder="Digite o nome ou setor do usuário"
      />
    </div>
  );
};
