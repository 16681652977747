import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { css } from "emotion";

import * as expensesHelper from "@helpers/expense.helper";

import { CoinsTooltip } from "~/components/shared/coins-tooltip";

import { defaultTheme } from "../../../../assets/styles/theme";
import * as utils from "../../../../helpers";
import { Expense } from "../../../../models/expense.model";
import { StOutlinedButton } from "../../../shared/buttons";
import { Column } from "../../../shared/layout/Column";
import { Row } from "../../../shared/layout/Row";
import { ExpenseTypeTag } from "../ExpenseTypeTag/ExpenseTypeTag";

const styles = {
  root: css({
    paddingTop: "1.5rem",
    paddingBottom: "1.0rem",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  middleExpense: css({
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
  }),
  checkboxRoot: css({
    color: defaultTheme.cardBorderColor,
    ["&:hover"]: {
      backgroundColor: defaultTheme.cardSecondaryBackgroundColor + "!important",
    },
  }),
  checkedCheckbox: css({
    color: `${defaultTheme.primaryColor} !important`,
  }),
  deleteIcon: css({
    color: defaultTheme.subTextColor,
    cursor: "pointer",
    fontSize: 24,
  }),
  dateInfo: css({
    color: defaultTheme.subTextColor,
    fontSize: 15,
    paddingBottom: ".5rem",
  }),
  boldText: css({
    color: defaultTheme.grays.dark,
    fontWeight: "bold",
  }),
  valueText: css({
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: defaultTheme.subTextColor,
    marginTop: "2px",
    fontSize: "14px",
  }),
  button: css({
    marginRight: "1rem",
    padding: ".25rem .5rem",
    fontSize: "14px !important",
    lineHeight: "24.5px",
  }),
};

interface Props {
  expense: Expense;
  containerStyle?: string;
  isLast?: boolean;
  isChecked: boolean;
  handleChange: (checked: boolean) => void;
  handleEdit: () => void;
  handleDelete: () => void;
}

const DesktopExpenseItem = ({
  expense,
  containerStyle = "",
  isLast = false,
  isChecked,
  handleChange,
  handleEdit,
  handleDelete,
}: Props) => {
  const innerHandleChange = (_: any, checked: boolean) => {
    handleChange(checked);
  };

  const convertedValue = expensesHelper.convertCurrencyValue(expense);

  const isForeignCurrencyExpense = expensesHelper.isForeignCurrencyExpense(
    expense,
  );

  return (
    <Row
      className={`${styles.root} ${
        isLast ? "" : styles.middleExpense
      } ${containerStyle} col-md-12`}
    >
      <Row>
        <div style={{ paddingRight: "2rem" }}>
          <Checkbox
            checked={isChecked}
            onChange={innerHandleChange}
            classes={{
              root: styles.checkboxRoot,
              checked: styles.checkedCheckbox,
            }}
            inputProps={{
              "aria-label": "Selecionar despesa",
            }}
          />
        </div>
        <Column>
          <div className={styles.dateInfo}>
            {expense.date.format("dddd, DD MMM YYYY")}
          </div>
          <div className={styles.boldText}>{expense.description}</div>
        </Column>
        {isForeignCurrencyExpense ? (
          <div style={{ marginLeft: "10px" }}>
            <CoinsTooltip
              placement="right"
              title="Despesa em moeda estrangeira."
              iconSize="14px"
            />
          </div>
        ) : null}
      </Row>
      <Row style={{ alignItems: "center" }}>
        <div style={{ marginRight: "1.5rem", alignItems: "center" }}>
          <ExpenseTypeTag expense={expense} />
        </div>
        <div style={{ marginRight: "1.5rem" }}>
          <div className={styles.boldText}>
            {utils.toCurrency(convertedValue)}
          </div>
          {isForeignCurrencyExpense ? (
            <div className={styles.valueText}>
              {`(${utils.toCurrency(expense.value, 2, expense.currency)})`}
            </div>
          ) : null}
        </div>
        <div style={{ alignItems: "center", display: "flex", gap: "1rem" }}>
          <StOutlinedButton
            className={styles.button}
            style={{ fontWeight: "normal" }}
            onClick={handleEdit}
          >
            Editar
          </StOutlinedButton>
          <DeleteIcon className={styles.deleteIcon} onClick={handleDelete} />
        </div>
      </Row>
    </Row>
  );
};

export { DesktopExpenseItem };
