import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  image: css({
    objectFit: "cover",
    objectPosition: "right",
  }),
  root: css({
    backgroundColor: theme.colors.background.gray,
    display: "flex",
    height: "100%",
    width: "100%",
  }),
};
