import React, { useContext } from "react";

import { Row } from "../../../../../../../../components/shared/layout/Row";
import { PaymentMethodsContext } from "../PaymentMethods.context";
import { CardItem } from "./card-item/CardItem.container";

export const CreditCardsList = () => {
  const { cards, handleDeleteCard, handleEditCard } = useContext(
    PaymentMethodsContext
  );

  return (
    <Row
      style={{
        width: "100%",
        flexWrap: "wrap",
        marginBottom: "-1rem"
      }}
    >
      {cards.map(card => (
        <CardItem
          key={card.token}
          card={card}
          handleDelete={handleDeleteCard}
          handleEdit={handleEditCard}
        />
      ))}
    </Row>
  );
};
