import React from "react";
import { geocodeByAddress } from "react-places-autocomplete";

import { HotelsSearch } from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { ItineraryServiceSuggestion } from "~/apps/corporate/models/itinerary/itinerary-service.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ServiceType } from "~/apps/shared/constants/enums";
import { extractDetails } from "~/apps/shared/helpers/google.helper";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  openAddServiceDrawer: (type: ServiceType, data: any) => void;
  suggestion: ItineraryServiceSuggestion;
};

export const ItineraryJourneySuggestion: React.FC<Props> = ({
  openAddServiceDrawer,
  suggestion,
}) => {
  if (suggestion.suggestionType === ServiceType.HOTEL) {
    return (
      <div css={styles.root}>
        <div css={styles.top.root}>
          <div css={styles.top.icon}>
            <Icon size={16} use="building" />
          </div>
          <span css={styles.top.description}>
            Adicionar hotel para {suggestion.location}:
          </span>
        </div>
        <div css={styles.body.root}>
          <div css={styles.body.card.root}>
            <div css={styles.body.card.dates.root}>
              <span css={styles.body.card.dates.date}>
                {suggestion.startDate.format("ddd, DD [de] MMM YYYY")}
              </span>
              {suggestion.endDate ? (
                <span css={styles.body.card.dates.date}>
                  - {suggestion.endDate.format("ddd, DD [de] MMM YYYY")}
                </span>
              ) : null}
            </div>
            <Button
              css={styles.body.card.button}
              onClick={async () => {
                const location = await geocodeByAddress(suggestion.location)
                  .then((results) => {
                    const firstResult = results[0];

                    const { formatted_address, place_id } = firstResult;

                    const details = extractDetails(firstResult);

                    return {
                      formattedAddress: formatted_address,
                      placeId: place_id,
                      ...details,
                    };
                  })
                  .catch(() => null);

                const data: Partial<HotelsSearch> = {
                  endDate: suggestion.endDate,
                  ...(location && {
                    location: {
                      latitude: location.latitude,
                      longitude: location.longitude,
                      search: location.formattedAddress,
                    } as HotelsSearch["location"],
                  }),
                  startDate: suggestion.startDate,
                };

                openAddServiceDrawer(ServiceType.HOTEL, data);
              }}
            >
              Buscar hotéis
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
