import { OfferFactory } from "~/parsers/offer.parser";

import { ERROR } from "../constants/";
import { buildFlightData } from "../helpers/flight.helper";
import {
  getAuthorizationHeader,
  getLoggedUserToken
} from "../helpers/user.helper";
import { FlightFactory } from "../parsers/travel.parser";
import { api } from "./interceptors";

export async function cancelPreReserve(offerData) {
  const response = await api.request({
    url: "/booking/flight/cancel-pre-reserve",
    method: "POST",
    data: offerData,
    headers: getAuthorizationHeader()
  });

  return response.data;
}

export async function checkReservationConflict(flightData) {
  try {
    const response = await api.request({
      url: "/booking/flight/check-reservation-conflict",
      method: "POST",
      data: flightData,
      headers: getAuthorizationHeader()
    });

    const data = {
      conflictingOffer: response.data.conflictingOffer
        ? OfferFactory(response.data.conflictingOffer)
        : null,
      canPreReserve: response.data.canPreReserve,
      canCancelConflictingReserve: response.data.canCancelConflictingReserve
    };

    return data;
  } catch (error) {
    return { error: true };
  }
}

export async function checkPlaneClientLimit(flightData) {
  try {
    const response = await api.request({
      url: "/booking/flight/check-plane-limit",
      method: "POST",
      data: flightData,
      headers: getAuthorizationHeader()
    });

    return response;
  } catch (error) {
    return { error: true };
  }
}

export function createNewFlight(flightData) {
  return api
    .request({
      url: `/booking/flight`,
      method: "POST",
      data: flightData,
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => FlightFactory(data));
}

export async function createNewFlightsInBatch(flightData) {
  const formattedData = buildFlightData(flightData);

  const requests = formattedData.map(data => createNewFlight(data));

  const flights = await Promise.all(requests);
  const tokens = flights.map(flight => ({ flight_token: flight.token }));

  return tokens;
}

function getCarriersFromFlight(flight) {
  const carriers = {};

  if (flight.inbound && flight.inbound.carrier) {
    carriers[flight.inbound.carrier.code] = true;
  }
  if (flight.outbound && flight.outbound.carrier) {
    carriers[flight.outbound.carrier.code] = true;
  }

  return carriers;
}

export function getFlights(flightToken) {
  return api
    .request({
      url: `/booking/flight/${flightToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader(),
      timeout: 1000 * 60 * 3
    })
    .then(response => {
      const { data } = response;
      const auxCarriers = data.availableCarriers;
      const auxFlights = data.flightList;
      return {
        flights: auxFlights.map(flight => {
          return { ...flight, carriers: getCarriersFromFlight(flight) };
        }),
        travelInfo: data.travelInfo,
        flightInfo: data.flightInfo,
        countryRestriction: data.countryRestriction,
        travelerInfo: data.travelerInfo,
        availableCarriers: Object.keys(auxCarriers)
          .map(key => auxCarriers[key])
          .sort((a, b) => a.name.localeCompare(b.name)),
        policy: data.policy,
        generalInfo: data.generalInfo,
        validUntil: data.searchValidUntil
      };
    })
    .catch(handleGetFlightsError);
}

export function aiportAutosuggest(search) {
  return api
    .request({
      url: `/booking/autosuggest/flight/${search}`,
      timeout: 25000,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => {
      return data.location_list.map(item => {
        const label = `(${item.PlaceId.split("-")[0]}) ${item.PlaceName} - ${
          item.CountryId.split("-")[0]
        }`;
        return {
          ...item,
          label
        };
      });
    });
}

function handleGetFlightsError(err) {
  if (err.code === "ECONNABORTED") {
    throw ERROR.TIMEOUT_FLIGHT_LIST_ERROR;
  } else {
    throw ERROR.UNEXPECTED_FLIGHT_LIST_ERROR;
  }
}

function handleError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  throw { title: "title", description: "Erro inesperado" };
}

export function addFlightOffer(requestDto) {
  return api
    .request({
      url: `/booking/offers/flight`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestDto
    })
    .catch(handleError);
}

export async function getUserRecentFlights() {
  return api
    .request({
      url: `/booking/users/${getLoggedUserToken()}/flights/recent`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export const flightService = {
  createNewFlight,
  createNewFlightsInBatch,
  getFlights,
  addFlightOffer,
  checkReservationConflict,
  cancelPreReserve,
  checkPlaneClientLimit
};

export default flightService;
