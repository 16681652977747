import React from "react";
import { Controller } from "react-hook-form";

import { css } from "@styled-system/css";
import { theme } from "@skin/v2";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { Box, Text, Flex } from "@toolkit";
import { Input } from "~/apps/shared/components/input/input";

import { BillingProfileListItem } from "@models/billing-profile.model";

import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { MaskedInput } from "@shared/inputs";

import { useForm } from "@components/shared/form";

import { BillingProfileForm as FormModel } from "../BillingProfiles.types";
import { CreditCardInput } from "./CreditCardInput";
import { PhoneInput } from "./PhoneInput";
import { formSchema } from "./validations";

const styles = {
  form: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  input: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    padding: "13px 16px",
    transition: "all 0.15s ease-in-out",
    width: "100%",
    "&:focus": {
      borderColor: theme.colors.gray[500],
    },
  }),
};

interface Props {
  isSubmitting: boolean;
  selectedProfile: BillingProfileListItem | null;
  onSubmit: (formData: FormModel) => void;
  onClose: () => void;
}

const BillingProfileForm = ({
  isSubmitting,
  selectedProfile,
  onSubmit,
  onClose,
}: Props) => {
  const { control, submitForm, errors } = useForm({
    onSubmit,
    schema: formSchema,
    defaultValues: {
      name: selectedProfile ? selectedProfile.name : "",
      legalName: selectedProfile ? selectedProfile.legalName : "",
      phone: selectedProfile ? selectedProfile.phone : "",
      cnpj: selectedProfile ? selectedProfile.cnpj : "",
      zipCode: selectedProfile ? selectedProfile.zipCode : "",
      street: selectedProfile ? selectedProfile.street : "",
      number: selectedProfile ? selectedProfile.number : "",
      neighborhood: selectedProfile ? selectedProfile.neighborhood : "",
      complement: selectedProfile ? selectedProfile.complement : null,
      city: selectedProfile ? selectedProfile.city : "",
      state: selectedProfile ? selectedProfile.state : "",
      email: selectedProfile ? selectedProfile.email : "",
      creditCard:
        selectedProfile && selectedProfile.creditCard
          ? selectedProfile.creditCard
          : "",
    },
  });

  return (
    <form onSubmit={submitForm} css={styles.form}>
      <Box p={[24]}>
        <Box>
          <Box mb="1rem">
            <Text fontSize={[18]} fontWeight="bold">
              Dados da empresa
            </Text>
          </Box>

          <Box width={375} mb="1rem">
            <Controller
              as={<MaskedInput mask="cnpj" placeholder="Digite o CNPJ" />}
              id="cnpj"
              name="cnpj"
              control={control}
              error={!!errors.cnpj}
              errorMessage={errors.cnpj && errors.cnpj.message}
            />
          </Box>

          <Flex style={{ gap: "1rem" }}>
            <Controller
              as={Input}
              id="legalName"
              name="legalName"
              control={control}
              placeholder="Digite a sua razão social"
              css={styles.input}
            />

            <Controller
              as={Input}
              id="name"
              name="name"
              control={control}
              placeholder="Nome fantasia"
              css={styles.input}
            />
          </Flex>
        </Box>

        <Box py="large">
          <Box mb="1rem">
            <Text fontSize={[18]} fontWeight="bold">
              Endereço
            </Text>
          </Box>
          <Flex mb="medium" style={{ gap: "1rem" }}>
            <Controller
              as={Input}
              id="street"
              name="street"
              control={control}
              placeholder="Digite o nome da rua"
              css={styles.input}
            />

            <Controller
              as={Input}
              id="number"
              name="number"
              control={control}
              placeholder="Digite o número"
              css={styles.input}
            />
          </Flex>
          <Flex mb="medium" style={{ gap: "1rem" }}>
            <Controller
              as={Input}
              id="neighborhood"
              name="neighborhood"
              control={control}
              placeholder="Digite o bairro"
              css={styles.input}
            />

            <Controller
              as={Input}
              id="complement"
              name="complement"
              control={control}
              placeholder="Digite o complemento"
              css={styles.input}
            />
          </Flex>
          <Flex flexDirection="column" style={{ gap: "1rem" }}>
            <Controller
              as={Input}
              id="zipCode"
              name="zipCode"
              control={control}
              placeholder="Digite o CEP"
              css={styles.input}
            />

            <Flex width="100%" style={{ gap: "1rem" }}>
              <Controller
                as={Input}
                id="city"
                name="city"
                control={control}
                placeholder="Digite a cidade"
                css={styles.input}
              />

              <Controller
                as={Input}
                id="state"
                name="state"
                control={control}
                placeholder="Digite o estado"
                css={styles.input}
              />
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Flex alignItems="center" mb="1rem">
            <Text fontSize={[18]} fontWeight="bold">
              Meio de pagamento
            </Text>
            <Tooltip
              title={
                "Esse cartão será usado para cobrança de taxas da smartrips e serviços adicionais como emissão de relatórios de despesas. A cobrança desses valores será feita sempre ao final do mês"
              }
              placement="right"
            >
              <HelpIcon />
            </Tooltip>
          </Flex>

          <Box width={375}>
            <Controller
              as={CreditCardInput}
              id="creditCard"
              name="creditCard"
              control={control}
              placeholder="Cartão de crédito"
              error={!!errors.creditCard}
              errorMessage={errors.creditCard && errors.creditCard.message}
            />
          </Box>
        </Box>
        <Box pt="large">
          <Box mb="1rem">
            <Text fontSize={[18]} fontWeight="bold">
              Contato
            </Text>
          </Box>
          <Flex mb="medium" width="100%" style={{ gap: "1rem" }}>
            <Box style={{ width: "50%" }}>
              <Controller
                as={PhoneInput}
                id="phone"
                name="phone"
                control={control}
                placeholder="Digite o telefone"
                error={!!errors.phone}
              />
            </Box>

            <Controller
              as={Input}
              id="email"
              name="email"
              control={control}
              placeholder="Digite o email"
              css={styles.input}
              style={{ width: "50%" }}
            />
          </Flex>
        </Box>
      </Box>

      <Flex
        alignItems="center"
        style={{ gap: "1rem" }}
        justifyContent="flex-end"
        px={24}
        mb="1.5rem"
      >
        <ContainedButton
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Salvar alterações
        </ContainedButton>
        <OutlinedButton
          onClick={onClose}
          disabled={isSubmitting}
          style={{ width: "12rem" }}
        >
          Cancelar
        </OutlinedButton>
      </Flex>
    </form>
  );
};

export { BillingProfileForm };
