import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: ({ isChecked }: { isChecked: boolean }) =>
    css({
      alignItems: "center",
      backgroundColor: isChecked ? theme.colors.pink[500] : theme.colors.white,
      border: "1px solid",
      borderColor: isChecked ? theme.colors.pink[500] : theme.colors.gray[100],
      borderRadius: "0 4px 4px 0",
      color: isChecked ? theme.colors.white : theme.colors.gray[500],
      cursor: "pointer",
      display: "flex",
      fontSize: "0.75rem",
      fontWeight: 600,
      gap: "8px",
      outline: "none",
      padding: "4px 12px",
      position: "relative",
      transition: "all 0.15s ease-in-out",
      zIndex: 0,
      ":hover": {
        borderColor: isChecked
          ? theme.colors.pink[700]
          : theme.colors.gray[300],
      },
    }),
};
