import * as reviewsApi from "@apis/reviews.api";
import { GetUserHotelReviewsResult } from "./UserReviews.types";
import { ERROR } from "@constants";

export async function getUserHotelReviews() {
  const result: GetUserHotelReviewsResult = {};

  try {
    result.data = await reviewsApi.getUserHotelReviews();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_USER_HOTEL_REVIEWS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_USER_HOTEL_REVIEWS_ERROR;
    }
  }

  return result;
}
