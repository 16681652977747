import React, { useState, useEffect, useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import moment from "moment";

import { useContextFactory } from "@hooks";

import {
  AdvanceRangeItem,
  IFilterParams,
  SpentThroughtTimeItem,
  AnalyticsOption,
} from "../shared/types";
import * as hotelInfoService from "./HotelInfo.service";
import {
  SpentByHotelItem,
  SpentByHotelDestinationItem,
} from "./HotelInfo.types";

interface IDateRange {
  endSelectedMonth: number;
  endSelectedYear: number;
  startSelectedMonth: number;
  startSelectedYear: number;
}

interface IHotelInfoState {
  advanceRange: AdvanceRangeItem[];
  averageSpent: number;
  generatedAt: Date | null;
  isLoading: boolean;
  spentByDestination: SpentByHotelDestinationItem[];
  spentByHotel: SpentByHotelItem[];
  spentThroughtTime: SpentThroughtTimeItem[];
  totalSpent: number;
}

interface IFilterOptions {
  billingProfileOptions: AnalyticsOption[];
  companyAreaOptions: AnalyticsOption[];
  costCenterOptions: AnalyticsOption[];
  projectOptions: AnalyticsOption[];
  tagOptions: AnalyticsOption[];
  travelerOptions: AnalyticsOption[];
}

interface ContextActions {
  onApplyFilters(appliedFilters: IFilterParams): void;
  onDateFilterChange(dateRange: IDateRange): void;
  onFilterChange(filterName: string): (optionValue: string) => void;
}

type IHotelInfoContext = {
  filterOptions: IFilterOptions;
  filters: IFilterParams;
  hotelInfo: IHotelInfoState;
} & ContextActions;

const initialHotelInfoState: IHotelInfoState = {
  advanceRange: [],
  averageSpent: 0,
  generatedAt: null,
  isLoading: false,
  spentByDestination: [],
  spentByHotel: [],
  spentThroughtTime: [],
  totalSpent: 0,
};

const initialFilterOptions: IFilterOptions = {
  billingProfileOptions: [],
  companyAreaOptions: [],
  costCenterOptions: [],
  projectOptions: [],
  tagOptions: [],
  travelerOptions: [],
};

const today = moment();
const initialFilters: IFilterParams = {
  billingProfileToken: "",
  companyAreaToken: "",
  costCenterToken: "",
  endMonth: 12,
  endYear: today.year(),
  projectToken: "",
  startMonth: 1,
  startYear: today.year(),
  tagToken: "",
  travelerToken: "",
};

const HotelInfoContext = React.createContext<IHotelInfoContext>(
  {} as IHotelInfoContext,
);

export const useHotelInfoContext = useContextFactory(
  "HotelInfo",
  HotelInfoContext,
);

export const HotelInfoProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();

  const [hotelInfo, setHotelInfo] = useState<IHotelInfoState>(
    initialHotelInfoState,
  );
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>(
    initialFilterOptions,
  );
  const [filters, setFilters] = useState<IFilterParams>(initialFilters);

  const loadFilterOptions = useCallback(async () => {
    setHotelInfo((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await hotelInfoService.getFilterOptionsResult();

    if (error) {
      setHotelInfo((prev) => ({ ...prev, isLoading: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setFilterOptions({
      billingProfileOptions: data.billingProfileOptions,
      companyAreaOptions: data.companyAreaOptions,
      costCenterOptions: data.costCenterOptions,
      projectOptions: data.projectOptions,
      tagOptions: data.tagOptions,
      travelerOptions: data.travelerOptions,
    });
    setHotelInfo((prev) => ({ ...prev, isLoading: false }));
  }, [showSnackMessage]);

  const loadHotelData = useCallback(async () => {
    setHotelInfo((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await hotelInfoService.getHotelInfo(filters);

    if (error) {
      setHotelInfo((prev) => ({ ...prev, isLoading: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setHotelInfo({
      advanceRange: data!.advanceRange,
      averageSpent: data!.spent.average,
      generatedAt: data!.generatedAt,
      isLoading: false,
      spentByDestination: data!.spentByDestination,
      spentByHotel: data!.spentByHotel,
      spentThroughtTime: data!.spentThroughtTime,
      totalSpent: data!.spent.total,
    });
  }, [filters, showSnackMessage]);

  function onDateFilterChange(dateRange: IDateRange) {
    setFilters((prev) => ({
      ...prev,
      endMonth: dateRange.endSelectedMonth + 1,
      endYear: dateRange.endSelectedYear,
      startMonth: dateRange.startSelectedMonth + 1,
      startYear: dateRange.startSelectedYear,
    }));
  }

  function onFilterChange(filterName: string) {
    return (optionValue: string) => {
      setFilters((prev) => ({
        ...prev,
        [filterName]: optionValue,
      }));
    };
  }

  function onApplyFilters(appliedFilters: IFilterParams) {
    setFilters((prev) => ({
      ...prev,
      ...appliedFilters,
    }));
  }

  useEffect(() => {
    void loadFilterOptions();
  }, [loadFilterOptions]);

  useEffect(() => {
    void loadHotelData();
  }, [filters, loadHotelData]);

  return (
    <HotelInfoContext.Provider
      value={{
        filterOptions,
        filters,
        hotelInfo,
        onApplyFilters,
        onDateFilterChange,
        onFilterChange,
      }}
    >
      {children}
    </HotelInfoContext.Provider>
  );
};
