import React from "react";
import { css } from "emotion";
import { defaultTheme } from "@assets/styles/theme";
import { MEDIA_QUERIES } from "@constants";
import {
  Expense,
  ExpenseReportApprovalStatusApprover
} from "@models/expense.model";
import * as utils from "@helpers";
import { Row } from "@shared/layout/Row";
import { AdvancedExpense } from "@models/advanced-expense.model";
import { ExpensesBreakdown } from "../../shared/ExpensesBreakdown";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: "0 0 4px 4px",
    backgroundColor: defaultTheme.applicationBackground,
    width: "100%",
    color: defaultTheme.textColor,
    lineHeight: 1.5,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 0,
      marginBottom: 0
    }
  }),
  segment: css({
    padding: "1rem 2rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  lastSegment: css({
    padding: "1rem 2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "1.5rem 1rem"
    }
  }),
  title: css({
    padding: "1rem 1.25rem",
    fontWeight: "bold",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    color: defaultTheme.successTextColor
  }),
  subTitle: css({
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: "1rem"
  }),
  cardStrip: css({
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: "100%",
    height: ".75rem",
    backgroundColor: defaultTheme.successTextColor,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      display: "none"
    }
  }),
  expenseLine: css({
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ".15rem",
    marginBottom: ".5rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    fontSize: 14
  })
};

interface Props {
  approver: ExpenseReportApprovalStatusApprover;
  expenses: Expense[];
  advancedExpenses: AdvancedExpense[];
  totalValue: number;
  totalApprovedValue: number;
}

const ReportPaidCard = ({
  approver,
  expenses,
  advancedExpenses,
  totalValue,
  totalApprovedValue
}: Props) => {
  return (
    <div>
      <div className={styles.cardStrip} />
      <div className={styles.root}>
        <div className={styles.title}>Relatório finalizado</div>
        <section className={styles.segment}>
          <div>
            <div>
              <span className={styles.subTitle}>Finalizado por: </span>
              <div style={{ fontSize: 14 }}>{approver.fullName}</div>
            </div>
          </div>
        </section>
        <section className={styles.lastSegment}>
          <div style={{ paddingBottom: "1.5rem" }}>
            <p style={{ fontWeight: "bold", fontSize: 15 }}>
              Serviços selecionados
            </p>
          </div>
          <div style={{ paddingBottom: "1.5rem" }}>
            <ExpensesBreakdown
              expensesData={expenses}
              advancedExpensesData={advancedExpenses}
              type={"approval"}
              firstColumnName={"Solicitado"}
              secondColumnName={"Aprovado"}
              showSecondColumn={true}
            >
              {(totalExpenseValue, approvedValue, totalAdvancedExpense) => (
                <div>
                  <div>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        color: defaultTheme.grays.dark
                      }}
                    >
                      <p>Total solicitado</p>
                      <p>{utils.toCurrency(totalExpenseValue)}</p>
                    </Row>
                  </div>
                  <div style={{ paddingTop: ".35rem" }}>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14
                      }}
                    >
                      <p>Total aprovado</p>
                      <p>{utils.toCurrency(approvedValue)}</p>
                    </Row>
                  </div>
                  <div style={{ paddingTop: ".35rem" }}>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        color: defaultTheme.reds.lightRed
                      }}
                    >
                      <p>Adiantamento</p>
                      <p>- {utils.toCurrency(totalAdvancedExpense)}</p>
                    </Row>
                  </div>
                  <div style={{ paddingTop: ".35rem" }}>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        fontWeight: "bold"
                      }}
                    >
                      <p>Total reembolsado</p>
                      <p>
                        {utils.toCurrency(approvedValue - totalAdvancedExpense)}
                      </p>
                    </Row>
                  </div>
                </div>
              )}
            </ExpensesBreakdown>
          </div>
        </section>
      </div>
    </div>
  );
};

export { ReportPaidCard };
