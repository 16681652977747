import React from "react";

import { Redirect, Router } from "@reach/router";
import { ScrollToTop } from "~/apps/corporate/components/scroll-to-top/scroll-to-top";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";

import { ApprovalPriorityRanking } from "./approval-processes/approval-priority-ranking";
import { ApprovalProcessFormContainer } from "./approval-processes/approval-process-form/approval-process-form";
import { ApprovalProcesses } from "./approval-processes/approval-processes";
import { CountryRestrictions } from "./country-restrictions/country-restrictions";
import { CustomFieldOptions } from "./custom-field-options/custom-field-options";
import { TravelPolicies } from "./travel-policies/travel-policies";

const Outlet: React.FC = () => {
  return (
    <Router primary={false} style={{ height: "100%" }}>
      <PermissionProtectedRoute
        component={TravelPolicies}
        permissions={["travels.policies"]}
        path="/"
        redirectTo="/configurations/trips/approval-processes"
      />
      <ApprovalProcesses path="/approval-processes" />
      <ApprovalPriorityRanking path="/approval-processes/ranking" />
      <ApprovalProcessFormContainer path="/approval-processes/create" />
      <ApprovalProcessFormContainer
        path="/approval-processes/:token/edit"
        isEditing
      />
      <PermissionProtectedRoute
        component={CountryRestrictions}
        permissions={["travels.countryRestrictions"]}
        path="country-restrictions/"
        redirectTo="/configurations/trips/approval-processes"
      />
      <PermissionProtectedRoute
        component={CountryRestrictions}
        permissions={["travels.countryRestrictions"]}
        path="country-restrictions/create"
        redirectTo="/configurations/trips/approval-processes"
      />
      <PermissionProtectedRoute
        component={CountryRestrictions}
        permissions={["travels.countryRestrictions"]}
        path="country-restrictions/:token/edit"
        redirectTo="/configurations/trips/approval-processes"
      />
      <PermissionProtectedRoute
        component={CountryRestrictions}
        permissions={["travels.countryRestrictions"]}
        path="country-restrictions/"
        redirectTo="/configurations/trips/approval-processes"
      />
      <CustomFieldOptions path="custom-field-options" />
      <Redirect from="*" noThrow to="." />
    </Router>
  );
};

export const TripsConfigurations: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};
