import React from "react";

import { Redirect, RouteComponentProps } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import {
  PermissionsKeysLiterals,
  PermissionsValuesLiterals,
} from "~/apps/shared/constants/enums";

type Props = RouteComponentProps & {
  atLeastOne?: boolean;
  component: React.ElementType;
  expects?: PermissionsValuesLiterals[];
  permissions: PermissionsKeysLiterals[];
  redirectTo?: string;
};

export const PermissionProtectedRoute: React.FC<Props> = ({
  atLeastOne = false,
  component: Component,
  expects,
  redirectTo,
  permissions,
  ...props
}) => {
  const { user } = useUser();

  if (!user) {
    return <Redirect noThrow to="/login" />;
  }

  if (atLeastOne) {
    if (
      permissions.some((permission, index) =>
        user.hasStrictPermission(permission, expects?.[index]),
      )
    ) {
      return <Component {...props} />;
    }
  }

  if (
    permissions.every((permission, index) =>
      user.hasStrictPermission(permission, expects?.[index]),
    )
  ) {
    return <Component {...props} />;
  }

  return (
    <Redirect to={redirectTo ? redirectTo : "/permission"} noThrow={true} />
  );
};
