import React from "react";

import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { css } from "emotion";

import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  container: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  targetName: css({
    fontSize: 16
  }),
  targetEmail: css({
    fontSize: 14,
    color: defaultTheme.subTextColor
  })
};

interface TargetItemProps extends MenuItemProps {
  item: {
    label: string;
    fullName: string;
    email: string;
  };
  isHighlighted?: boolean;
  endComplement?: React.ReactNode;
  onClick?: (event?: React.SyntheticEvent) => void;
}

export const TargetItem = React.forwardRef(function TargetItem(
  props: TargetItemProps,
  ref
) {
  const { item, isHighlighted, endComplement, ...rest } = props;

  return (
    <MenuItem
      style={{
        background: isHighlighted ? "lightgray" : "white"
      }}
      innerRef={ref}
      {...rest}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.targetName}>{item.fullName}</div>
          <div className={styles.targetEmail}>{item.email}</div>
        </div>
        <div>{endComplement}</div>
      </div>
    </MenuItem>
  );
});
