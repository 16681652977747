import React from "react";

import { CarSupplierAdditionalsPresentationMap } from "../car-result.helper";
import { CarResultIcon } from "../car-result.icons";

export const movidaAdditionalsCodesMap = {
  BEBE_CONFORTO: "7",
  BOOSTER_ASSENTO_P_CRIANCAS: "9",
  CADEIRA_DE_BEBE: "8",
  CARBON_FREE: "59",
  CONDUTOR_ADICIONAL: "CONDUTOR ADICIONAL",
  LOCACAO_JOVEM_TODOS_OS_GRUPOS: "94",
  PROTECAO_A_PNEUS_E_VIDROS_PLUS: "286",
  PROTECAO_A_VIDROS: "284",
  PROTECAO_BASICA: ["BAS", "PJBAS"],
  PROTECAO_PREMIUM: ["COM", "PJCOM"],
  PROTECAO_SUPER: ["SUP", "PJSUP"],
  RESPONSAVEL_FINANCEIRO: "97",
} as const;

export const movidaAdditionalsPresentationMap: CarSupplierAdditionalsPresentationMap<
  typeof movidaAdditionalsCodesMap
> = {
  BEBE_CONFORTO: {
    details:
      "A Movida oferece o serviço de bebê-conforto, necessário para bebês recém-nascidos com até 9 kg em carros. Lembrando que crianças com menos de um ano de idade devem estar em um bebê conforto voltado para trás! Para ter a garantia deste serviço, é necessário que a solicitação seja feita no ato da reserva.",
    icon: <CarResultIcon use="baby-seat" />,
  },
  BOOSTER_ASSENTO_P_CRIANCAS: {
    details:
      "Caso você precise transportar uma criança de quatro a dez anos de idade, é obrigatório o uso de dispositivo de retenção. Pensando nisso, a Movida disponibiliza o Booster para sua comodidade e tranquilidade.",
    icon: <CarResultIcon use="elevated-seat" />,
  },
  CADEIRA_DE_BEBE: {
    details:
      "Caso você precise transportar uma criança de até sete anos e meio de idade, é obrigatório o uso de dispositivo de retenção. Pensando nisso, a Movida possui cadeirinhas de bebê em suas locações.",
    icon: <CarResultIcon use="baby-seat" />,
  },
  CARBON_FREE: {
    details:
      "Seja consciente! Neutralize as emissões de CO2 geradas durante a sua locação",
    icon: <CarResultIcon use="sustainability" />,
  },
  CONDUTOR_ADICIONAL: {
    details:
      "O produto permite que até 3 condutores adicionais estejam habilitados a dirigir um veículo locado.Caso este produto não seja contratado, apenas o cliente responsável pela locação pode dirigir o veículo alugado.",
    icon: <CarResultIcon use="steering-wheel" />,
    rate: "daily-per",
  },
  LOCACAO_JOVEM_TODOS_OS_GRUPOS: {
    details:
      "A Movida oferece o serviço locação Jovem para você que tem idade superior a 19 anos e inferior a 21",
    icon: <CarResultIcon use="driver" />,
  },
  PROTECAO_A_PNEUS_E_VIDROS_PLUS: {
    details:
      "Proteção para vidros laterais, para-brisas e/ou vidro traseiro do veículo segurado, além de pneus, retrovisores externos, pisca-pisca dianteiro, faróis e lanternas. Nesta modalidade de proteção, o cliente não terá coparticipação. Proteção não aplicável para o grupo T (veículo blindado).",
    icon: <CarResultIcon use="van" />,
  },
  PROTECAO_A_VIDROS: {
    details:
      "Proteção para vidros laterais, para-brisas e/ou vidro traseiro do veículo segurado. Nesta modalidade de proteção, o cliente não terá coparticipação.Proteção não aplicável para o grupo T (veículo blindado).",
    icon: <CarResultIcon use="shield-crossed" />,
  },
  PROTECAO_BASICA: {
    details:
      "Proteção Básica (LDW): Proteção contra roubo, furto, incêndio, perda total, danos e/ou avarias causados exclusivamente ao veículo da MOVIDA.",
    icon: <CarResultIcon use="shield" />,
  },
  PROTECAO_PREMIUM: {
    details:
      "Proteção Premium (Proteção Básica-LDW + Redução de Coparticipação + Benefício MOVIDA: Proteção contra Terceiros-ALI, sem custo adicional): Nesta tarifa, o cliente terá direito a Proteção Básica-LDW (Proteção contra roubo, furto, incêndio, perda total, danos e/ou avarias causados exclusivamente ao veículo da MOVIDA), porém, na hipótese da ocorrência de Evento Adverso, o valor de sua coparticipação correspondente ao grupo de veículo locado, será reduzido, conforme indicado no quadro de tarifas. Cabe ao cliente, no caso de Evento Adverso, arcar com a coparticipação no correspondente grupo do VEÍCULO, conforme as condições estabelecidas no respectivo CONTRATO DE LOCAÇÃO. Nessa modalidade de Proteção, a MOVIDA ainda oferece como um benefício, sem qualquer custo adicional ao cliente, a proteção contra danos materiais e pessoais corporais causados à terceiros em razão de acidentes com veículos, até o limite dos valores estabelecidos no CONTRATO DE LOCAÇÃO.",
    icon: <CarResultIcon use="shield-check" />,
  },
  PROTECAO_SUPER: {
    details:
      "Super Proteção (Proteção Básica-LDW + Maior Redução de Coparticipação + Benefício MOVIDA: Proteção contra Terceiros e Ocupantes - ALI + PAI, sem custo adicional): Nesta tarifa, o cliente terá direito a Proteção Completa, porém o valor de sua coparticipação correspondente ao grupo de veículo, na hipótese de Evento Adverso, será ainda mais reduzida, conforme indicado no quadro de tarifas. Cabe ao cliente, no caso de Evento Adverso, arcar com a coparticipação no correspondente grupo do VEÍCULO, conforme as condições estabelecidas no respectivo CONTRATO DE LOCAÇÃO. Nesta modalidade de proteção, a MOVIDA ainda oferece como um benefício, sem qualquer custo adicional ao cliente, a proteção contra danos materiais e pessoais corporais causados a terceiros em razão de acidentes com veículos (ALI), bem como a proteção contra danos causados aos ocupantes do veículo MOVIDA (PAI), nos casos de morte ou invalidez permanente, procedentes de acidentes com o veículo locado, até o limite total estabelecido no respectivo campo do CONTRATO DE LOCAÇÃO e até o número máximo de ocupantes indicado para o tipo de veículo locado.",
    icon: <CarResultIcon use="shield-crossed" />,
  },
  RESPONSAVEL_FINANCEIRO: {
    icon: <CarResultIcon use="driver" />,
  },
} as const;
