import React from "react";

import List from "@material-ui/core/List";
import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

import { ExpenseCategoryCamelCase } from "@dtos/expense-categories.dto";

import { useExpensesCategories } from "../ExpensesCategories.context";
import { ExpenseSubCategoryListItem } from "./ExpenseSubCategoryListItem";

const styles = {
  emptyListWarning: css({
    bg: "white",
    color: defaultTheme.subTextColor,
    fontSize: 15,
    p: "1rem .5rem 1rem 1rem",
    pl: "large",
    borderTop: "1px solid",
    borderColor: "border",
    borderRadius: "0 0 0.5rem 0.5rem",
  }),
};

interface Props {
  subcategories: ExpenseCategoryCamelCase[];
  categoryFamily: string;
}

const ExpenseSubCategoryList: React.FC<Props> = ({
  subcategories,
  categoryFamily,
}) => {
  const { handleOpenEdit, handleToggleArchive } = useExpensesCategories();
  const categoriesToDisplay = subcategories.filter(
    (item: ExpenseCategoryCamelCase) => item.expenseFamily === categoryFamily,
  );

  if (categoriesToDisplay.length < 1) {
    return (
      <List>
        <p css={styles.emptyListWarning}>Nenhuma categoria</p>
      </List>
    );
  }

  return (
    <List>
      {categoriesToDisplay.map((category: ExpenseCategoryCamelCase) => {
        return (
          <ExpenseSubCategoryListItem
            item={category}
            key={category.expenseCategoryToken}
            handleEdit={handleOpenEdit}
            handleToggleArchive={handleToggleArchive}
            handleToggleSwitch={handleToggleArchive}
          />
        );
      })}
    </List>
  );
};

export { ExpenseSubCategoryList };
