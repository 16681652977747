import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  divisor: css({
    backgroundColor: theme.colors.gray[100],
    border: 0,
    height: "1px",
    margin: "0 auto",
    width: "100%",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px 12px 24px 12px",
  }),
  top: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
};
