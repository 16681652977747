import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    width: "fit-content",
  }),
  card: {
    body: {
      input: {
        label: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "24px",
      }),
    },
    footer: {
      button: css({
        fontSize: "0.875rem",
      }),
      root: css({
        display: "flex",
        gap: "16px",
        justifyContent: "end",
      }),
    },
    header: {
      root: css({
        backgroundColor: theme.colors.background.gray,
        borderBottom: `1px solid ${theme.colors.gray[100]}`,
        padding: "24px",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
    },
    root: css({
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      width: "100%",
    }),
  },
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    lineHeight: "1.125",
  }),
  error: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
    lineHeight: "1.125",
  }),
  loading: css({
    opacity: 0.5,
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  }),
  strong: css({
    fontWeight: 600,
  }),
  top: css({
    display: "flex",
    gap: "24px",
  }),
};
