import React, { useContext } from "react";

import DeletionDialog from "../../../../../../../components/shared/dialogs/DeletionDialog";
import { ExpensesProcessContext } from "./ExpensesProcess.context";

const ApprovalRuleDeletionDialog = () => {
  const {
    deleting,
    selectedDeleteRule,
    handleCancelDelete,
    deleteApprovableRule,
  } = useContext(ExpensesProcessContext);

  return (
    <DeletionDialog
      open={!!selectedDeleteRule}
      title="Exclusão de regra"
      message="Deseja realmente excluir a regra de aprovação selecionada?"
      handleClose={handleCancelDelete}
      handleDeletion={deleteApprovableRule}
      isDeleting={deleting}
    />
  );
};

export { ApprovalRuleDeletionDialog };
