import React from "react";

import Collapse from "@material-ui/core/Collapse";
import { css } from "emotion";

import { defaultTheme } from "@assets/styles/theme";
import { colors } from "@assets/styles/theme/constants";

import { MEDIA_QUERIES } from "@constants";

import * as utils from "@helpers";

import { AdvancedExpense } from "@models/advanced-expense.model";
import { Expense } from "@models/expense.model";

import { StContainedButton } from "@shared/buttons";
import { Column } from "@shared/layout/Column";
import { Row } from "@shared/layout/Row";

import PermissionVisibility from "@components/shared/permissions/PermissionVisibility";

import { ExpensesBreakdown } from "../../shared/ExpensesBreakdown";
import { ReportApprovalForm } from "../ApprovalForm";

const styles = {
  root: css({
    border: `1px solid ${defaultTheme.cardBorderColor}`,
    borderRadius: 4,
    [MEDIA_QUERIES.desktopBreakpoint]: {
      marginBottom: "1rem"
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      borderRadius: 0,
      border: "none",
      borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
    }
  }),
  segment: css({
    padding: "1.5rem 1.25rem",
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`
  }),
  titleSection: css({
    padding: "1.5rem 1.25rem",
    fontWeight: "bold",
    backgroundColor: defaultTheme.cardSecondaryBackgroundColor,
    borderBottom: `1px solid ${defaultTheme.cardBorderColor}`,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      backgroundColor: colors.white
    }
  })
};

interface Props {
  expenses: Expense[];
  advancedExpenses: AdvancedExpense[];
  totalPrice: number;
  isSending: boolean;
  isSendButtonEnabled: boolean;
  isSendButtonVisible: boolean;
  isApprovalFormVisible: boolean;
  handleSend: () => void;
  isReportOpen?: boolean;
}

const InfoSideCard = ({
  expenses,
  advancedExpenses,
  totalPrice,
  isSending = false,
  isSendButtonEnabled,
  isSendButtonVisible,
  isApprovalFormVisible,
  handleSend,
  isReportOpen = false
}: Props) => {
  return (
    <Column className={styles.root}>
      <section className={styles.titleSection}>
        <span>Resumo da solicitação</span>
      </section>
      <section className={styles.segment}>
        <div style={{ paddingBottom: "1.5rem" }}>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>
            Serviços selecionados
          </p>
        </div>
        <div style={{ paddingBottom: "0.5rem" }}>
          <ExpensesBreakdown
            expensesData={expenses}
            advancedExpensesData={advancedExpenses}
            type="approval"
            firstColumnName={isReportOpen ? "A solicitar" : "Solicitado"}
            secondColumnName={"Aprovado"}
            showSecondColumn={false}
          >
            {(totalExpenseValue, approvedValue, totalAdvancedExpense) => (
              <div>
                <div style={{ marginBottom: "0.75rem" }}>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      fontSize: 14,
                      color: defaultTheme.grays.dark
                    }}
                  >
                    <p>Total reembolsável</p>
                    <p>{utils.toCurrency(totalExpenseValue)}</p>
                  </Row>
                </div>
                <div style={{ marginBottom: "0.75rem" }}>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      fontSize: 14,
                      color: defaultTheme.reds.lightRed
                    }}
                  >
                    <p>Adiantamento</p>
                    <p>- {utils.toCurrency(totalAdvancedExpense)}</p>
                  </Row>
                </div>
                <div>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                  >
                    <p>Previsão de reembolso</p>
                    <p>
                      {utils.toCurrency(
                        totalExpenseValue - totalAdvancedExpense
                      )}
                    </p>
                  </Row>
                </div>
              </div>
            )}
          </ExpensesBreakdown>
        </div>
      </section>
      {isSendButtonVisible ? (
        <>
          <PermissionVisibility
            permissions={[
              "companySettings.costCenters",
              "companySettings.billingProfiles",
              "companySettings.areas",
              "companySettings.projects"
            ]}
            atLeastOne={true}
          >
            <Collapse in={isApprovalFormVisible}>
              <ReportApprovalForm />
            </Collapse>
          </PermissionVisibility>
          <div style={{ padding: "1.5rem 1.25rem" }}>
            <StContainedButton
              style={{
                fontWeight: "600",
                fontSize: 14
              }}
              loading={isSending}
              disabled={!isSendButtonEnabled || isSending}
              onClick={handleSend}
            >
              Enviar relatório para aprovação
            </StContainedButton>
          </div>
        </>
      ) : null}
    </Column>
  );
};

export { InfoSideCard };
