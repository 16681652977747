import { css } from "@styled-system/css";

export const styles = {
  root: css({
    ".MuiAvatarGroup": {
      "&-avatar": {
        border: "0",
      },
      "&-root": {},
    },
  }),
};
