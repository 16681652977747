import React, { useMemo, useState } from "react";
import {
  SubmitHandler,
  useForm as useHookForm,
  UseFormMethods as UseHookFormMethods,
  UseFormOptions,
} from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type UseHookMethodsOverride = Omit<UseHookFormMethods, "handleSubmit">;

export type UseFormMethods = UseHookMethodsOverride & {
  submitForm: (event?: React.SyntheticEvent) => Promise<void>;
  submitting: boolean;
};

type UseFormProps<T> = UseFormOptions & {
  onSubmit: SubmitHandler<T>;
  onError?: (error: unknown, context: UseHookMethodsOverride) => void;
  schema: yup.AnyObjectSchema;
  disabled?: boolean;
};

export function useForm<T = unknown>(props: UseFormProps<T>): UseFormMethods {
  const { onSubmit, onError, schema, disabled } = props;

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, ...formContext } = useHookForm<
    yup.Asserts<typeof schema>
  >({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(schema),
    ...props,
  });

  const context: UseFormMethods = useMemo(() => {
    return {
      ...formContext,
      submitting,
      disabled: disabled || submitting,
      submitForm: async function submitForm(event?: React.SyntheticEvent) {
        try {
          event?.preventDefault();

          setSubmitting(true);

          await handleSubmit(onSubmit)(event);
        } catch (error) {
          if (onError) {
            return onError(error, formContext);
          }

          throw error;
        } finally {
          setSubmitting(false);
        }
      },
    };
  }, [disabled, formContext, handleSubmit, onError, onSubmit, submitting]);

  return context;
}
