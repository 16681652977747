import React from "react";

import { Select } from "~/apps/shared/components/select/select";

import { styles } from "./styles";

export const DefaultInternationalFlightClassSelect: React.FC<
  React.ComponentPropsWithoutRef<typeof Select>
> = ({ ...props }) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Classe padrão para todos os voos:</span>
      <Select css={styles.select} {...props} />
    </div>
  );
};
