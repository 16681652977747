// import errorTypes from "../constants/errors";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import { api } from "./interceptors";

export function getTravelerAvailableCredits(travelerToken) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/credits/users/${travelerToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function getClientCredits() {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/credits`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}
