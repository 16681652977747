import React from "react";

import { navigate } from "@reach/router";

import { Button } from "@toolkit/v2";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../dialog/dialog";
import { styles } from "./styles";

type Props = React.ComponentPropsWithoutRef<typeof Dialog> & {
  handleNavigeToNewTrip?: () => void;
  handleRefresh?: () => void;
  hideRefreshButton?: boolean;
};

export const SearchExpirationDialog: React.FC<Props> = ({
  handleNavigeToNewTrip = () => {
    navigate("/");
  },
  handleRefresh = () => {
    window.location.reload();
  },
  hideRefreshButton = false,
  ...props
}) => {
  return (
    <Dialog css={styles.root} {...props}>
      <DialogHeader icon="clock-outline" variant="pink" />
      <DialogContent css={styles.content.root}>
        <div css={styles.content.top.root}>
          <DialogTitle variant="pink">Sua pesquisa expirou.</DialogTitle>
          <p css={styles.content.top.message}>
            Os preços e disponibilidade para sua pesquisa podem ter mudado nos
            últimos 15 minutos.
          </p>
        </div>
      </DialogContent>
      <DialogFooter css={styles.footer.root}>
        <Button fill="outlined" onClick={handleNavigeToNewTrip}>
          Nova busca
        </Button>
        {!hideRefreshButton ? (
          <Button onClick={handleRefresh}>Atualizar busca</Button>
        ) : null}
      </DialogFooter>
    </Dialog>
  );
};
