import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    code: css({
      color: theme.colors.gray[700],
      fontSize: "0.875rem",
    }),
    name: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    }),
  },
  top: {
    button: css({
      backgroundColor: "transparent",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "4px",
      color: theme.colors.gray[300],
      cursor: "pointer",
      display: "grid",
      flexShrink: 0,
      height: "32px",
      placeItems: "center",
      transition: "all 0.15s ease-in-out",
      width: "32px",
      "&:hover": {
        borderColor: theme.colors.gray[300],
        color: theme.colors.gray[700],
      },
    }),
    image: css({
      objectFit: "contain",
      width: "fit-content",
    }),
    root: css({
      display: "flex",
      justifyContent: "space-between",
      gap: "12px",
    }),
  },
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "24px",
    width: "calc(50% - 8px)",
    "@media screen and (max-width: 768px)": {
      width: "100%",
    },
  }),
};
