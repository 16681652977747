import React from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";

import { theme } from "@skin/v2";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

export const HotelResultRoomAmenitiesDialog: React.FC = () => {
  const {
    handleCloseRoomAmenitiesDialog,
    isRoomAmenitiesDialogOpen,
    roomAmenities,
  } = useHotelResult();

  return (
    <Dialog
      open={isRoomAmenitiesDialogOpen}
      onClose={handleCloseRoomAmenitiesDialog}
    >
      <DialogHeader
        color={theme.colors.green[500]}
        icon="hotel-outline"
        onClose={handleCloseRoomAmenitiesDialog}
      />
      <DialogTitle>Amenidades do Quarto</DialogTitle>
      <DialogContent css={styles.root}>
        <ul css={styles.body.root}>
          {roomAmenities.map((amenity) => (
            <li css={styles.body.amenity.root} key={amenity}>
              <Icon css={styles.body.amenity.icon} size={16} use="check" />
              {amenity}
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
};
