import React from "react";

import { OfferSearcherDialog } from "~/apps/corporate/components/offer-searcher-dialog/offer-search-dialog";
import { HotelSearch } from "~/apps/corporate/components/offer-searcher-dialog/offer-search-dialog.types";
import { ServiceType } from "~/apps/shared/constants/enums";
import moment from "moment";

import { useHotels } from "../hotels.context";

type Props = {
  travelToken: string;
};

export const HotelsEditSearchDialog: React.FC<Props> = ({ travelToken }) => {
  const { handleCloseEditDrawer, isEditing, searchInfo } = useHotels();

  const initialEditDestination = searchInfo
    ? {
        latitude: Number(searchInfo.hotelLatitude),
        longitude: Number(searchInfo.hotelLongitude),
        search: searchInfo.hotelSearch,
      }
    : null;

  const data: HotelSearch = {
    endDate: searchInfo ? moment(searchInfo.hotelEndDate) : null,
    location: searchInfo ? initialEditDestination : null,
    startDate: searchInfo ? moment(searchInfo.hotelInitialDate) : null,
    totalGuests: searchInfo ? searchInfo.totalGuests : 1,
    travelToken,
  };

  return (
    <OfferSearcherDialog
      data={data}
      onClose={handleCloseEditDrawer}
      open={isEditing}
      travelToken={travelToken}
      title="Editar busca"
      type={ServiceType.HOTEL}
    />
  );
};
