import { css } from "@styled-system/css";
import { css as ecss } from "emotion";
import { darken } from "polished";
import { theme } from "smartrips-skin";

import { defaultTheme } from "~/assets/styles/theme";

import { MEDIA_QUERIES } from "~/constants";

export const styles = {
  message: {
    icon: css({
      color: "primary",
      fontSize: 24,
      mr: "small"
    }),
    link: css({
      color: "primary",
      pl: "xsmall",
      textDecoration: "none",
      transition: "all 0.3s ease",
      ["&:hover"]: {
        color: darken(0.2, theme.colors.primary)
      }
    }),
    root: css({
      border: "1px solid",
      borderColor: defaultTheme.cardBorderColor,
      borderRadius: "0 !important",
      borderTop: "none",
      padding: "12px 24px"
    })
  },
  form: ecss({
    backgroundColor: theme.colors.white,
    borderColor: defaultTheme.cardBorderColor,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }),
  root: ecss({
    margin: "0 auto",
    maxWidth: 900,
    padding: "2.5rem 2.5rem 0 2.5rem",
    width: "100%",
    "& > :last-child": {
      borderBottomLeftRadius: "4px !important",
      borderBottomRightRadius: "4px !important"
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: "2rem 0 0 0"
    },
    [MEDIA_QUERIES.desktopBreakpoint]: {
      padding: "2.5rem 0 0 0",
      width: "80%"
    }
  }),
  skeleton: {
    body: css({
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      borderLeft: "1px solid #eee",
      borderRight: "1px solid #eee",
      borderTop: "1px solid #eee",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      p: ["20px 8px 16px", "large"]
    }),
    footer: css({
      border: "1px solid #eee",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      height: "7.75rem",
      p: ["24px 8px 16px", "large"],
      [MEDIA_QUERIES.mobileBreakpoint]: {
        height: "9.75rem"
      }
    }),
    root: css({
      paddingTop: "2.5rem",
      width: "100%",
      [MEDIA_QUERIES.mobileBreakpoint]: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        paddingTop: "2rem"
      },
      [MEDIA_QUERIES.desktopBreakpoint]: {
        width: "80%"
      }
    }),
    tabs: css({
      borderBottom: "1px solid",
      borderBottomColor: theme.colors.white,
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      borderLeft: "1px solid #eee",
      borderRight: "1px solid #eee",
      borderTop: "1px solid #eee",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      marginBottom: "-1.5px",
      padding: 10,
      width: ["30%", "25%"]
    })
  },
  tabs: {
    container: ecss({
      justifyContent: "center",
      position: "relative"
    }),
    root: ecss({
      backgroundColor: "#FAFBFF",
      opacity: 1,
      minHeight: "40px",
      overflow: "visible"
    }),
    tab: {
      first: ecss({
        borderRadius: "4px 0 0 0",
        borderRight: "none !important"
      }),
      indicator: ecss({
        height: "0px",
        width: "0px"
      }),
      last: ecss({
        borderRadius: "0 4px 0 0"
      }),
      middle: ecss({
        borderRadius: 0,
        borderRight: "none !important"
      }),
      root: ecss({
        borderBottom: "none",
        borderColor: defaultTheme.cardBorderColor,
        borderStyle: "solid",
        borderWidth: 1,
        fontFamily: "'Open sans', sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        minHeight: "43px",
        minWidth: "75px",
        paddingBottom: "0.1rem",
        textTransform: "none"
      }),
      selected: ecss({
        overflow: "visible",
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: defaultTheme.cardBorderColor,
        borderBottom: "none",
        [`&:after`]: {
          content: "''",
          height: 2,
          width: "100%",
          position: "absolute",
          bottom: "-2px",
          backgroundColor: theme.colors.white
        }
      }),
      scroller: ecss({
        overflow: "visible !important"
      }),
      single: ecss({
        borderRadius: "4px 4px 0 0"
      })
    }
  }
};
