import React, { useCallback, useMemo, useState } from "react";

import { canUserCreateTag } from "~/apps/corporate/components/travel-tags/travel-tags-creatable-select/travel-tags-creatable-select.helper";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";
import { CreatableSelect } from "~/apps/shared/components/creatable-select/creatable-select";
import { Option } from "~/apps/shared/types";

type Props = {
  placeholder?: string;
  travelToken: string;
};

export const TravelTagsCreatableSelect: React.FC<Props> = ({
  placeholder,
  travelToken,
}) => {
  const { clientConfig } = useClientConfig();
  const { addTravelTag, travelsTags, tagOptions } = useTags();
  const { user } = useUser();

  const [newTagName, setNewTagName] = useState("");

  const handleAddTravelTag = useCallback(
    async (newTag: Option<string>) => {
      await addTravelTag(
        {
          ...newTag,
          isNewOption: false,
        },
        travelToken,
      );
    },
    [addTravelTag, travelToken],
  );

  const handleChange = useCallback(
    (newTagName: string) => {
      setNewTagName(newTagName);
    },
    [setNewTagName],
  );

  const handleCreateTag = useCallback(
    async (tag: string) => {
      await addTravelTag({ isNewOption: true, label: tag }, travelToken);
    },
    [addTravelTag, travelToken],
  );

  const newTagAlreadyExists = useMemo(
    () => tagOptions.some((tagOption) => tagOption.tagName === newTagName),
    [newTagName, tagOptions],
  );

  if (!travelsTags) {
    return null;
  }

  if (!(travelToken in travelsTags)) {
    return null;
  }

  return (
    <CreatableSelect
      error={newTagAlreadyExists}
      formatCreateLabel={(inputValue) =>
        newTagAlreadyExists
          ? `A etiqueta ${inputValue} já existe...`
          : `Criar nova etiqueta "${inputValue}"...`
      }
      inputValue={newTagName}
      isValidNewOption={(newTagName) => {
        if (!clientConfig || !user) {
          return false;
        }

        const tagsAreCreatedByAdminsOnly = clientConfig.tagsAreCreatedByAdminsOnly();

        return canUserCreateTag(newTagName, tagsAreCreatedByAdminsOnly, user);
      }}
      onChange={async (newTag) => {
        await handleAddTravelTag(newTag as Option<string>);
      }}
      onCreateOption={handleCreateTag}
      onInputChange={handleChange}
      options={tagOptions
        .filter(
          (tagOption) =>
            !travelsTags[travelToken]!.some(
              (selectedTag) => selectedTag.tagToken === tagOption.tagToken,
            ),
        )
        .map((tagOption) => ({
          label: tagOption.tagName,
          value: tagOption.tagToken,
        }))}
      placeholder={
        placeholder ? placeholder : "Selecione ou crie uma etiqueta..."
      }
      value={null}
    />
  );
};
