export const formatDisplayedPhone = (phone?: string | null) => {
  if (!phone) {
    return "";
  }

  const cleanedPhone = phone
    .replace("+55", "")
    .replace(/\D/g, "")
    .replace(/^0+/, "");

  if (cleanedPhone.length === 11) {
    return `(${cleanedPhone.slice(0, 2)}) ${cleanedPhone.slice(
      2,
      7,
    )}-${cleanedPhone.slice(7)}`;
  }

  if (cleanedPhone.length === 10) {
    return `(${cleanedPhone.slice(0, 2)}) ${cleanedPhone.slice(
      2,
      6,
    )}-${cleanedPhone.slice(6)}`;
  }

  if (cleanedPhone.length === 9) {
    return `${cleanedPhone.slice(0, 5)}-${cleanedPhone.slice(5)}`;
  }

  if (cleanedPhone.length === 8) {
    return `${cleanedPhone.slice(0, 4)}-${cleanedPhone.slice(4)}`;
  }

  return phone;
};
