// import { CONTINENTS } from "@constants";

export function extractDetails(result) {
  let details = {};

  details.latitude = result.geometry.location.lat;
  details.longitude = result.geometry.location.lng;
  details.city = extractCity(result.address_components);
  details.state = extractArea(result.address_components, "state");
  details.country = extractArea(result.address_components, "country");
  // details.continent = CONTINENTS[details.country];
  return details;
}

function extractCity(addressComponents) {
  const possibleTypes = {
    administrative_area_level_2: true,
    administrative_area_level_3: true,
    locality: true,
    archipelago: true,
    establishment: true,
    natural_feature: true
  };

  const cityComponent = addressComponents.find(component => {
    return component.types.some(type => possibleTypes[type]);
  });

  return cityComponent ? cityComponent["long_name"] : "";
}

function extractArea(addressComponents, type) {
  const sizes = {
    city: "long_name",
    state: "short_name",
    country: "short_name"
  };

  const types = {
    city: "administrative_area_level_2",
    state: "administrative_area_level_1",
    country: "country"
  };

  let area = extractAreaFromGoogle(addressComponents)(types[type]);

  if (!area[0]) {
    types.city = "locality";
    area = extractAreaFromGoogle(addressComponents)(types[type]);
  }

  return area.length ? area[0][sizes[type]] : "";
}

function extractAreaFromGoogle(result) {
  return function (type) {
    let filt = result.filter(function (address) {
      if (address.types.join().indexOf(type) > -1) {
        return address;
      }

      return false;
    });

    return filt;
  };
}
