import React from "react";

import { TextField as MuiTextField } from "@material-ui/core";

import { styles } from "./styles";

export const TextField: React.FC<
  React.ComponentPropsWithoutRef<typeof MuiTextField>
> = ({ children, value, ...props }) => {
  return (
    <MuiTextField css={styles.root} value={value ? value : ""} {...props}>
      {children}
    </MuiTextField>
  );
};
