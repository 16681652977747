import React, { ReactNode, useMemo, useEffect, createContext } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import isEmpty from "lodash/isEmpty";
import { Moment } from "moment";

import {
  EXPENSE_REPORT_STATUS,
  PROJECT_ENABLING_STATE,
  EXPENSE_REPORT_TYPE,
  EXPENSE_STATUS,
  EXPENSES_CATEGORIES,
  EXPENSE_APPROVAL_STATUS,
} from "@constants";

import * as expensesHelper from "@helpers/expense.helper";
import { getUserFromLocalStorage } from "@helpers/user.helper";

import { AdvancedExpense } from "@models/advanced-expense.model";
import { CostCenterListByUserItem } from "@models/cost-center.model";
import {
  ExpenseReport,
  Expense,
  ExpenseReportApprovalStatus,
  ExpensesPolicies,
} from "@models/expense.model";
import { UserModel } from "@models/user.model";

import { ImageFile } from "~/types";

import { useSafeState } from "@hooks";

import * as reportService from "./Report.service";

interface Actions {
  fetchReport: (reportToken: string) => void;
  handleSendReport: () => void;
  handleExpenseRemoval: (
    selectedExpense: Expense | AdvancedExpense,
    expenseType?: State["expenseToDeleteType"],
  ) => () => void;
  handleCloseDeletionDialog: () => void;
  proccessExpenseRemoval: () => void;
  processAdvancedExpenseRemoval: () => void;
  openExpenseDrawer: (selectedExpense?: Expense | null) => void;
  handleCloseExpenseDrawer: () => void;
  onAfterSave: () => void;
  updateDescription: (description: string) => void;
  handleChangeCostCenter: (costCenter: CostCenterListByUserItem) => void;
  handleOutOfPolicyClick: (expense: Expense) => () => void;
  handleClosePolicyDialog: () => void;
  handleDownloadInvoices: () => void;
  handleCancelApprovalRequest(): void;
}

type Selectors = {
  administrativeInfo: Record<
    string,
    | (Record<string, any> & {
        name: string;
      })
    | undefined
  >;
  isCostCenterLimitExtrapolated: boolean;
  isLoggedUserTraveler: boolean;
  reportApprovedTotalPrice: number;
  reportPeriod: {
    end: Moment;
    start: Moment;
  } | null;
  reportRefundablePrice: number;
  reportTotalPrice: number;
};

type State = {
  expensesPolicies: ExpensesPolicies;
  expenseToDeleteType: "expense" | "expense-advance" | null;
  isDownloadingInvoices: boolean;
  isExpenseDrawerOpen: boolean;
  isProcessingReviewCancel: boolean;
  isSendingToApproval: boolean;
  loading: boolean;
  proccessingDelete: boolean;
  report: ExpenseReport | null;
  reportApprovalStatus: ExpenseReportApprovalStatus | null;
  selectedExpenseToDelete: Expense | AdvancedExpense | null;
  selectedExpenseToEdit: Expense | null;
  selectedExpenseToShowPolicy: Expense | null;
  selectedReceiptToEdit: ImageFile | null;
  traveler: UserModel | null;
};

const initialState: State = {
  expensesPolicies: {},
  expenseToDeleteType: null,
  isDownloadingInvoices: false,
  isExpenseDrawerOpen: false,
  isProcessingReviewCancel: false,
  isSendingToApproval: false,
  loading: false,
  proccessingDelete: false,
  report: null,
  reportApprovalStatus: null,
  selectedExpenseToDelete: null,
  selectedExpenseToEdit: null,
  selectedExpenseToShowPolicy: null,
  selectedReceiptToEdit: null,
  traveler: null,
};

type ContextProps = State & Selectors & Actions;

type Project = {
  active: boolean;
  billingProfileToken: string;
  name: string;
  projectToken: string;
};

type ReportApprovalFormState = {
  allProjects: Project[];
  areas: Array<{
    companyAreaToken: string;
    name: string;
  }>;
  billingProfiles: Array<{
    billingProfileToken: string;
    cnpj: string;
    name: string;
  }>;
  costCenters: CostCenterListByUserItem[];
  isApprovalFormVisible: boolean;
  selectedArea: string;
  selectedBillingProfile: string;
  selectedCostCenter: CostCenterListByUserItem | null;
  selectedProject: string;
};

const approvalFormInitialState: ReportApprovalFormState = {
  allProjects: [],
  areas: [],
  billingProfiles: [],
  costCenters: [],
  isApprovalFormVisible: false,
  selectedArea: "",
  selectedBillingProfile: "",
  selectedCostCenter: null,
  selectedProject: "",
};

interface ReportApprovalFormActions {
  handleChangeCostCenter: (costCenter: CostCenterListByUserItem) => void;
  handleChangeData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isBillingProfileNeeded: () => boolean;
  isCompanyAreaNeeded: () => boolean;
  isCostCenterNeeded: () => boolean;
  isProjectNeeded: () => boolean;
  shouldBillingProfileSelectBeVisible: () => boolean;
  shouldCostCenterSelectBeVisible: () => boolean;
  shouldProjectSelectBeVisible: () => boolean;
}

interface ReportApprovalSelectors {
  canSubmitReportToApproval: boolean;
  isCostCenterLimitExtrapolated: boolean;
  projects: Project[];
}

export const ReportContext = createContext<ContextProps>({} as ContextProps);

const ReportApprovalFormContext = createContext(
  {} as ReportApprovalFormState & ReportApprovalSelectors,
);
const ReportApprovalFormActionsContext = createContext(
  {} as ReportApprovalFormActions,
);

export const useReportApprovalFormContext = useContextFactory(
  "ReportApprovalFormContext",
  ReportApprovalFormContext,
);
export const useReportApprovalFormActionsContext = useContextFactory(
  "ReportApprovalFormActionsContext",
  ReportApprovalFormActionsContext,
);

export const ReportProvider = ({
  children,
}: {
  children: Element | ReactNode;
}) => {
  const { showSnackMessage } = useApplication();
  const { clientConfig } = useClientConfig();

  const [approvalFormState, setApprovalFormState] = useSafeState(
    approvalFormInitialState,
  );
  const [state, setState] = useSafeState(initialState);

  const setErrorState = (error: Error) => {
    setState({
      isSendingToApproval: false,
      loading: false,
      proccessingDelete: false,
    });

    showSnackMessage(error.description, ALERT_TYPES.ERROR);
  };

  const fetchReport = async (reportToken: string) => {
    setState({
      loading: true,
    });

    let traveler = null;

    const [
      getExpenseReportApprovalStatusResponse,
      getReportByTokenResponse,
      getReportExpensesPoliciesResponse,
    ] = await Promise.all([
      reportService.getExpenseReportApprovalStatus(reportToken),
      reportService.getReportByToken(reportToken),
      reportService.getReportExpensesPolicies(reportToken),
    ]);

    let error =
      getReportByTokenResponse.error ||
      getExpenseReportApprovalStatusResponse.error ||
      getReportExpensesPoliciesResponse.error;

    if (error) {
      setErrorState(error);

      return;
    }

    const expenseReportApprovalStatus =
      getExpenseReportApprovalStatusResponse.data;
    const report = getReportByTokenResponse.data;
    const reportExpensesPolicies = getReportExpensesPoliciesResponse.data;

    const getTravelerResponse = await reportService.getTraveler(
      report!.travelerToken,
    );

    if (getTravelerResponse.error) {
      const error = getTravelerResponse.error;

      setErrorState(error);

      return;
    }

    traveler = getTravelerResponse.data;

    const [
      getBillingProfilesResponse,
      getTravelerCostCentersResponse,
      getUserAreasResponse,
      getUserProjectsResponse,
    ] = await Promise.all([
      reportService.getBillingProfiles(traveler!.userToken),
      reportService.getTravelerCostCenters(report!.travelerToken),
      reportService.getUserAreas(report!.travelerToken),
      reportService.getUserProjects(report!.travelerToken),
    ]);

    error =
      getBillingProfilesResponse.error ||
      getTravelerCostCentersResponse.error ||
      getUserAreasResponse.error ||
      getUserProjectsResponse.error;

    if (error) {
      setErrorState(error);

      return;
    }

    setState({
      expensesPolicies: reportExpensesPolicies,
      loading: false,
      report,
      reportApprovalStatus: expenseReportApprovalStatus,
      traveler,
    });

    setApprovalFormState({
      allProjects: getUserProjectsResponse.data,
      areas: getUserAreasResponse.data,
      billingProfiles: getBillingProfilesResponse.data,
      costCenters: getTravelerCostCentersResponse.data,
    });
  };

  const handleSendReport = () => {
    const { isApprovalFormVisible } = approvalFormState;
    const { report } = state;

    const isSmartripsReport =
      !!report && report.reportType === EXPENSE_REPORT_TYPE.SMARTRIPS;

    const isSomeCategoryNeeded =
      isCompanyAreaNeeded() ||
      shouldBillingProfileSelectBeVisible() ||
      shouldCostCenterSelectBeVisible() ||
      shouldProjectSelectBeVisible();

    if (isSmartripsReport || !isSomeCategoryNeeded) {
      void processSendReportApprovalRequest();

      return;
    }

    if (!isApprovalFormVisible) {
      setApprovalFormState({
        isApprovalFormVisible: true,
      });

      return;
    }

    if (canSubmitReportToApproval) {
      void processSendReportApprovalRequest();
    }
  };

  const processSendReportApprovalRequest = async () => {
    const {
      selectedArea,
      selectedBillingProfile,
      selectedCostCenter,
      selectedProject,
    } = approvalFormState;
    const { report } = state;

    const costCenterToken = selectedCostCenter
      ? selectedCostCenter.token
      : null;

    setState({
      isSendingToApproval: true,
    });

    const { error } = await reportService.sendExpenseReportApprovalRequest(
      report!.expenseReportToken,
      costCenterToken,
      selectedArea,
      selectedBillingProfile,
      selectedProject,
    );

    if (error) {
      setErrorState(error);

      return;
    }

    setState({
      isSendingToApproval: false,
      report: Object.assign({}, state.report, {
        status: EXPENSE_REPORT_STATUS.PENDING_APPROVAL,
      }),
    });

    showSnackMessage(
      "Relatório enviado para aprovação com sucesso",
      ALERT_TYPES.SUCCESS,
    );
  };

  const handleCancelApprovalRequest = async () => {
    const { expenseReportToken } = state.report!;

    setState({
      isProcessingReviewCancel: true,
    });

    const { error } = await reportService.cancelReportApprovalRequest(
      expenseReportToken,
    );

    if (error) {
      setState({ isProcessingReviewCancel: false });

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState({
      isProcessingReviewCancel: false,
      report: Object.assign({}, state.report, {
        status: EXPENSE_REPORT_STATUS.OPEN,
      }),
      reportApprovalStatus: Object.assign({}, state.reportApprovalStatus, {
        status: EXPENSE_APPROVAL_STATUS.NEED_APPROVAL,
      }),
    });

    showSnackMessage(
      "Pedido de aprovação cancelado com sucesso.",
      ALERT_TYPES.SUCCESS,
    );
  };

  const handleExpenseRemoval = (
    selectedExpense: Expense | AdvancedExpense,
    type = "expense" as State["expenseToDeleteType"],
  ) => () => {
    setState({
      expenseToDeleteType: type as State["expenseToDeleteType"],
      selectedExpenseToDelete: selectedExpense,
    });
  };

  const handleCloseDeletionDialog = () => {
    setState({
      selectedExpenseToDelete: null,
    });
  };

  const proccessExpenseRemoval = async () => {
    setState({
      proccessingDelete: true,
    });

    const selectedExpenseToDelete = state.selectedExpenseToDelete as Expense;

    const removeExpenseResponse = await reportService.removeExpense(
      selectedExpenseToDelete!,
      state.report!.expenseReportToken,
    );

    if (removeExpenseResponse.error) {
      const error = removeExpenseResponse.error;

      setErrorState(error);

      return;
    }

    const updatedExpenses = state.report!.expenses.filter(
      (expense) =>
        expense.expenseToken !== selectedExpenseToDelete!.expenseToken,
    );

    setState({
      proccessingDelete: false,
      report: Object.assign({}, state.report, { expenses: updatedExpenses }),
      selectedExpenseToDelete: null,
    });

    showSnackMessage("Despesa removida com sucesso", ALERT_TYPES.SUCCESS);
  };

  const processAdvancedExpenseRemoval = async () => {
    const selectedExpenseToDelete = state.selectedExpenseToDelete as AdvancedExpense;

    setState({
      proccessingDelete: true,
    });

    const removeAdvancedExpenseResponse = await reportService.removeAdvancedExpense(
      selectedExpenseToDelete!,
      state.report!.expenseReportToken,
    );

    if (removeAdvancedExpenseResponse.error) {
      const error = removeAdvancedExpenseResponse.error;

      setErrorState(error);

      return;
    }

    const updatedAdvances = state.report?.advancedExpenses.filter(
      (ad) =>
        ad.expenseAdvanceToken !== selectedExpenseToDelete.expenseAdvanceToken,
    );

    setState({
      proccessingDelete: false,
      report: Object.assign({}, state.report, {
        advancedExpenses: updatedAdvances,
      }),
      selectedExpenseToDelete: null,
    });
  };

  const openExpenseDrawer = async (selectedExpense: Expense | null = null) => {
    let receiptFile: ImageFile | null = null;

    setState({
      loading: true,
    });

    if (selectedExpense && selectedExpense.receiptFile) {
      const getReceiptImageFileResponse = await reportService.getReceiptImageFile(
        selectedExpense.receiptFile,
      );

      if (getReceiptImageFileResponse.error) {
        const error = getReceiptImageFileResponse.error;

        setErrorState(error);

        return;
      }

      const receiptImageFile = getReceiptImageFileResponse.data;

      receiptFile = receiptImageFile ? receiptImageFile : null;
    }

    setState({
      isExpenseDrawerOpen: true,
      loading: false,
      selectedExpenseToEdit: selectedExpense,
      selectedReceiptToEdit: receiptFile,
    });
  };

  const handleCloseExpenseDrawer = () => {
    setState({
      isExpenseDrawerOpen: false,
      selectedExpenseToEdit: null,
      selectedReceiptToEdit: null,
    });
  };

  const onAfterSave = () => {
    setState({
      isExpenseDrawerOpen: false,
      selectedExpenseToEdit: null,
      selectedReceiptToEdit: null,
    });

    const message = state.selectedExpenseToEdit
      ? "Despesa editada com sucesso"
      : "Despesa criada com sucesso";

    showSnackMessage(message, ALERT_TYPES.SUCCESS);

    void updateExpenses();
  };

  const updateExpenses = async () => {
    setState({
      loading: true,
    });

    const [
      getReportByTokenResponse,
      getReportExpensesPoliciesResponse,
    ] = await Promise.all([
      reportService.getReportByToken(state.report!.expenseReportToken),
      reportService.getReportExpensesPolicies(state.report!.expenseReportToken),
    ]);

    const error =
      getReportByTokenResponse.error || getReportExpensesPoliciesResponse.error;

    if (error) {
      setErrorState(error);

      return;
    }

    const report = getReportByTokenResponse.data;
    const reportExpensesPolicies = getReportExpensesPoliciesResponse.data;

    setState({
      expensesPolicies: reportExpensesPolicies,
      loading: false,
      report: Object.assign({}, state.report, {
        expenses: report!.expenses,
      }),
    });
  };

  const updateDescription = async (description: string) => {
    const updateExpenseReportDescriptionResponse = await reportService.updateExpenseReportDescription(
      description,
      state.report!.expenseReportToken,
    );

    if (updateExpenseReportDescriptionResponse.error) {
      const error = updateExpenseReportDescriptionResponse.error;

      setErrorState(error);

      return;
    }

    setState({
      report: Object.assign({}, state.report, { description }),
    });
  };

  const handleOutOfPolicyClick = (expense: Expense) => () => {
    setState({
      selectedExpenseToShowPolicy: expense,
    });
  };

  const handleClosePolicyDialog = () => {
    setState({
      selectedExpenseToShowPolicy: null,
    });
  };

  const handleDownloadInvoices = async () => {
    const { isDownloadingInvoices, report } = state;

    if (isDownloadingInvoices) {
      return;
    }

    setState({
      isDownloadingInvoices: true,
    });

    const downloadExpenseReportInvoicesResponse = await reportService.downloadExpenseReportInvoices(
      report!.expenseReportToken,
    );

    if (downloadExpenseReportInvoicesResponse.error) {
      const error = downloadExpenseReportInvoicesResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setState({
      isDownloadingInvoices: false,
    });
  };

  const reportTotalPrice = useMemo(() => {
    const { report } = state;

    if (report) {
      const totalExpenses = report.expenses.reduce((sum, current) => {
        if (!current.canceled) {
          return sum + current.value;
        }
        return sum;
      }, 0);

      const advancedExpensesTotal = report.advancedExpenses.reduce(
        (sum, curr) => {
          const value = curr.approvedValue || curr.requestedValue;
          return sum + value;
        },
        0,
      );

      return totalExpenses - advancedExpensesTotal;
    }

    return 0;
  }, [state.report]);

  const reportRefundablePrice = useMemo(() => {
    const { report } = state;

    if (report) {
      const totalExpenses = report.expenses.reduce((sum, current) => {
        if (
          !current.canceled &&
          current.status !== EXPENSE_STATUS.CREATED_AUTO
        ) {
          return sum + current.value;
        }
        return sum;
      }, 0);

      const advancedExpensesTotal = report.advancedExpenses.reduce(
        (sum, curr) => {
          const value = curr.approvedValue || curr.requestedValue;
          return sum + value;
        },
        0,
      );

      return totalExpenses - advancedExpensesTotal;
    }

    return 0;
  }, [state.report]);

  const reportApprovedTotalPrice = useMemo(() => {
    const { report } = state;

    if (report) {
      return report.expenses.reduce((sum, current) => {
        const approvedValue =
          current.approvedValue &&
          current.expenseCategory !== EXPENSES_CATEGORIES.EXPENSE_FEE
            ? current.approvedValue
            : 0;
        return sum + approvedValue;
      }, 0);
    }

    return 0;
  }, [state.report]);

  const reportPeriod = useMemo(() => {
    const { report } = state;

    if (report) {
      const {
        periodStart: start,
        periodEnd: end,
      } = expensesHelper.calculateExpensesPeriod(report.expenses);

      return { start, end };
    }

    return null;
  }, [state.report]);

  const isLoggedUserTraveler = useMemo(() => {
    const loggedUser = getUserFromLocalStorage();

    if (isEmpty(loggedUser) || isEmpty(state.traveler)) {
      return false;
    }

    return loggedUser!.userToken === state.traveler!.userToken;
  }, [state.traveler]);

  // Approval form state related

  const handleChangeCostCenter = (costCenter: CostCenterListByUserItem) => {
    setApprovalFormState({
      selectedCostCenter: costCenter,
    });
  };

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setApprovalFormState({
      [name]: value,
    });
  };

  const isReportOpenOrDeclined = useMemo(() => {
    const { report } = state;

    if (!report || !report.status) {
      return false;
    }

    return (
      report.status === EXPENSE_REPORT_STATUS.OPEN ||
      report.status === EXPENSE_REPORT_STATUS.DECLINED
    );
  }, [state.report]);

  const isCostCenterNeeded = () => {
    const { report } = state;

    if (clientConfig && !clientConfig.isCompanyCostCenterEnabled()) {
      return false;
    }

    return (
      !!report &&
      report.reportType !== EXPENSE_REPORT_TYPE.SMARTRIPS &&
      isReportOpenOrDeclined
    );
  };

  const shouldCostCenterSelectBeVisible = () => {
    return isCostCenterNeeded();
  };

  const isBillingProfileNeeded = () => {
    const { report } = state;

    return (
      !!report &&
      report.reportType !== EXPENSE_REPORT_TYPE.SMARTRIPS &&
      isReportOpenOrDeclined
    );
  };

  const shouldBillingProfileSelectBeVisible = () => {
    const { billingProfiles } = approvalFormState;

    return isBillingProfileNeeded() && billingProfiles.length > 1;
  };

  const isCompanyAreaNeeded = () => {
    const { report } = state;

    if (clientConfig && !clientConfig.isCompanyAreaEnabled()) {
      return false;
    }

    return (
      !!report &&
      report.reportType !== EXPENSE_REPORT_TYPE.SMARTRIPS &&
      isReportOpenOrDeclined
    );
  };

  const isProjectNeeded = () => {
    if (!clientConfig) {
      return false;
    }

    const { report } = state;

    const projectEnablingState = clientConfig.getProjectEnablingState();

    return (
      !!report &&
      report.reportType !== EXPENSE_REPORT_TYPE.SMARTRIPS &&
      isReportOpenOrDeclined &&
      projectEnablingState === PROJECT_ENABLING_STATE.REQUIRED
    );
  };

  const shouldProjectSelectBeVisible = () => {
    if (!clientConfig) {
      return false;
    }

    const { report } = state;

    return (
      !!report &&
      report.reportType !== EXPENSE_REPORT_TYPE.SMARTRIPS &&
      isReportOpenOrDeclined &&
      clientConfig.isCompanyProjectEnabled()
    );
  };

  const isCostCenterLimitExtrapolated = useMemo(() => {
    const { selectedCostCenter } = approvalFormState;

    return (
      !!selectedCostCenter &&
      (!!selectedCostCenter.budgetExtrapolated ||
        !!selectedCostCenter.vaultExtrapolated)
    );
  }, [approvalFormState.selectedCostCenter]);

  const projects = useMemo(() => {
    const { allProjects, selectedBillingProfile } = approvalFormState;

    return allProjects
      ? allProjects.filter((proj) => {
          return (
            proj.billingProfileToken === selectedBillingProfile ||
            !proj.billingProfileToken
          );
        })
      : [];
  }, [approvalFormState.selectedBillingProfile]);

  const canSubmitReportToApproval = useMemo(() => {
    const {
      selectedArea,
      selectedBillingProfile,
      selectedCostCenter,
      selectedProject,
    } = approvalFormState;

    const isCostCenterOk = isCostCenterNeeded()
      ? !!selectedCostCenter &&
        !selectedCostCenter.budgetExtrapolated &&
        !selectedCostCenter.vaultExtrapolated
      : true;
    const isAreaOk = isCompanyAreaNeeded() ? !!selectedArea : true;
    const isBillingProfileOk = isBillingProfileNeeded()
      ? !!selectedBillingProfile
      : true;
    const isProjectOk = isProjectNeeded() ? !!selectedProject : true;

    return isCostCenterOk && isAreaOk && isBillingProfileOk && isProjectOk;
  }, [
    approvalFormState.selectedArea,
    approvalFormState.selectedBillingProfile,
    approvalFormState.selectedCostCenter,
    approvalFormState.selectedProject,
  ]);

  const administrativeInfo = useMemo(() => {
    const {
      companyAreaToken,
      billingProfileToken,
      costCenterToken,
      projectToken,
    } = state.report || {};

    const {
      areas,
      billingProfiles,
      costCenters,
      allProjects,
    } = approvalFormState;

    const companyArea = areas.find(
      (item) => item.companyAreaToken === companyAreaToken,
    );
    const billingProfile = billingProfiles.find(
      (item) => item.billingProfileToken === billingProfileToken,
    );
    const costCenter = costCenters.find(
      (item) => item.token === costCenterToken,
    );
    const project = allProjects.find(
      (item) => item.projectToken === projectToken,
    );

    return {
      companyArea,
      billingProfile,
      costCenter,
      project,
    };
  }, [
    state.report?.companyAreaToken,
    state.report?.billingProfileToken,
    state.report?.costCenterToken,
    state.report?.projectToken,
    approvalFormState.areas,
    approvalFormState.billingProfiles,
    approvalFormState.costCenters,
    approvalFormState.allProjects,
  ]);

  useEffect(() => {
    const { report, loading } = state;
    const hasLoaded = !loading && !!report;

    if (hasLoaded && !!clientConfig) {
      const {
        areas,
        billingProfiles,
        costCenters,
        allProjects,
      } = approvalFormState;

      const companyAreaEnabled = clientConfig.isCompanyAreaEnabled();
      const costCenterEnabled = clientConfig.isCompanyCostCenterEnabled();
      const projectEnablingState = clientConfig.getProjectEnablingState();

      const activeProjects = allProjects.filter((project) => project.active);
      const isProjectRequired =
        projectEnablingState === PROJECT_ENABLING_STATE.REQUIRED;

      const selectedArea = expensesHelper.getSelectedCompanyArea(
        areas,
        report!,
        companyAreaEnabled,
      );
      const selectedBillingProfile = expensesHelper.getSelectedBillingProfile(
        billingProfiles,
        report!,
      );
      const selectedCostCenter = expensesHelper.getSelectedCostCenter(
        costCenters,
        report!,
        costCenterEnabled,
      );
      const selectedProject = expensesHelper.getSelectedProject(
        activeProjects,
        report!,
        isProjectRequired,
      );

      setApprovalFormState({
        selectedArea,
        selectedBillingProfile,
        selectedCostCenter,
        selectedProject,
      });
    }
  }, [
    approvalFormState.allProjects,
    approvalFormState.areas,
    approvalFormState.billingProfiles,
    approvalFormState.costCenters,
  ]);

  // if user changes billing profile, we reset the selected project
  useEffect(() => {
    const {
      allProjects,
      selectedBillingProfile,
      selectedProject: selectedProjectToken,
    } = approvalFormState;

    const selectedProject = allProjects.find(
      (proj) => proj.projectToken === selectedProjectToken,
    );

    const doesProjectBelongToBP =
      selectedBillingProfile &&
      selectedProject &&
      (selectedProject.billingProfileToken === selectedBillingProfile ||
        !selectedProject.billingProfileToken);

    if (!doesProjectBelongToBP) {
      setApprovalFormState({
        selectedProject: "",
      });
    }
  }, [approvalFormState.selectedBillingProfile]);

  return (
    <ReportContext.Provider
      value={{
        ...state,
        administrativeInfo,
        fetchReport,
        handleCancelApprovalRequest,
        handleChangeCostCenter,
        handleCloseDeletionDialog,
        handleCloseExpenseDrawer,
        handleClosePolicyDialog,
        handleDownloadInvoices,
        handleExpenseRemoval,
        handleOutOfPolicyClick,
        handleSendReport,
        isCostCenterLimitExtrapolated,
        isLoggedUserTraveler,
        onAfterSave,
        openExpenseDrawer,
        proccessExpenseRemoval,
        processAdvancedExpenseRemoval,
        reportApprovedTotalPrice,
        reportPeriod,
        reportRefundablePrice,
        reportTotalPrice,
        updateDescription,
      }}
    >
      <ReportApprovalFormContext.Provider
        value={{
          ...approvalFormState,
          canSubmitReportToApproval,
          isCostCenterLimitExtrapolated,
          projects,
        }}
      >
        <ReportApprovalFormActionsContext.Provider
          value={{
            handleChangeCostCenter,
            handleChangeData,
            isBillingProfileNeeded,
            isCompanyAreaNeeded,
            isCostCenterNeeded,
            isProjectNeeded,
            shouldBillingProfileSelectBeVisible,
            shouldCostCenterSelectBeVisible,
            shouldProjectSelectBeVisible,
          }}
        >
          {children}
        </ReportApprovalFormActionsContext.Provider>
      </ReportApprovalFormContext.Provider>
    </ReportContext.Provider>
  );
};
