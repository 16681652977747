import React, { useCallback, useState } from "react";

import { navigate } from "@reach/router";
import { BillingProfileSelect } from "~/apps/corporate/components/billing-profile-select/billing-profile-select";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import { GetUsersRequestParams } from "~/apps/corporate/dtos/client.dto";
import {
  updateUsersBatchApi,
  userBatchApi,
} from "~/apps/shared/apis/excel.api";
import { BatchFileUploader } from "~/apps/shared/components/batch-file-uploader/batch-file-uploader";
import { Filters } from "~/apps/shared/components/filters/filters";
import { Icon } from "~/apps/shared/components/icon/icon";
import { SearchInput } from "~/apps/shared/components/search-input/search-input";
import { SelectButton } from "~/apps/shared/components/select-button/select-button";

import { Button, CircularSpinner } from "@toolkit/v2";

import { styles } from "./styles";

const onCreateGuestClick = () => {
  navigate("/configurations/users/create?type=guest");
};

const onCreateUserClick = () => {
  navigate("/configurations/users/create?type=user");
};

type Props = {
  onFilter?: (value: {
    field: keyof GetUsersRequestParams;
    value: string;
  }) => void;
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSuccessUpload: () => Promise<void>;
  selectedBillingProfile?: string;
  selectedTab: "active" | "inactive";
};

export const UsersHeader: React.FC<Props> = ({
  onFilter,
  onSearch,
  onSuccessUpload,
  selectedBillingProfile,
  selectedTab,
}) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const handleExport = useCallback(async () => {
    setIsLoadingExport(true);

    await updateUsersBatchApi.onDownloadModelClick();

    setIsLoadingExport(false);
  }, []);

  const handleFilter: typeof onFilter = useCallback(
    (value) => {
      if (!onFilter) {
        return;
      }

      onFilter(value);

      return;
    },
    [onFilter],
  );

  return (
    <>
      <PageTitle title={pageTitles.USERS} />
      <div css={styles.root}>
        <div css={styles.top.root}>
          <SelectButton
            actions={[
              { name: "Adicionar usuário padrão", action: onCreateUserClick },
              { name: "Adicionar visitante", action: onCreateGuestClick },
            ]}
            variant="pink"
          >
            Adicionar usuário
          </SelectButton>
          <div css={styles.top.right.root}>
            <Button
              disabled={isLoadingExport}
              fill="outlined"
              onClick={async () => {
                await handleExport();
              }}
            >
              {isLoadingExport ? (
                <CircularSpinner size={20} />
              ) : (
                <Icon size={20} use="cloud-arrow-down" />
              )}
              Exportar
            </Button>
            <BatchFileUploader
              onDownloadModelClick={updateUsersBatchApi.onDownloadModelClick}
              onSuccessUpload={async () => await onSuccessUpload()}
              onUpload={updateUsersBatchApi.onUpload}
            >
              {({ openDialog }) => (
                <Button fill="outlined" onClick={openDialog}>
                  <Icon size={20} use="cloud-arrow-up" />
                  Atualizar
                </Button>
              )}
            </BatchFileUploader>
            <BatchFileUploader
              onDownloadModelClick={userBatchApi.onDownloadModelClick}
              onSuccessUpload={async () => await onSuccessUpload()}
              onUpload={userBatchApi.onUpload}
            >
              {({ openDialog }) => (
                <Button fill="outlined" onClick={openDialog}>
                  <Icon size={20} use="cloud-arrow-up" />
                  Cadastrar
                </Button>
              )}
            </BatchFileUploader>
          </div>
        </div>
        <div css={styles.bottom.root}>
          <SearchInput
            css={styles.bottom.input}
            onChange={onSearch}
            placeholder="Digite o nome ou setor do usuário..."
          />
          <BillingProfileSelect
            defaultValue={selectedBillingProfile}
            isClearable
            onChange={(value: any) =>
              handleFilter({
                field: "billingProfileToken",
                value: value?.value,
              })
            }
            styles={styles.bottom.select}
          />
          <Filters
            onChange={(value) => {
              handleFilter({
                field: "visibility",
                value,
              });
            }}
            options={[
              { value: "active", label: "Ativos" },
              { value: "inactive", label: "Arquivados" },
            ]}
            value={selectedTab}
          />
        </div>
      </div>
    </>
  );
};
