import React from "react";
import { Box, Text, Paragraph, Flex } from "@toolkit";
import { StContainedButton } from "~/components/shared/buttons";
import { formatCurrency } from "@helpers";
import { css } from "@styled-system/css";

import {
  useExpenseAdvanceApprovalReviewContext,
  useExpenseAdvanceApprovalReviewActionsContext
} from "../../ExpenseAdvanceApprovalReview.context";

const styles = {
  button: css({
    py: "small",
    px: 24,
    fontSize: 1,
    maxHeight: 38
  })
};

const PaymentCard = () => {
  const {
    expenseAdvance,
    isSubmitting
  } = useExpenseAdvanceApprovalReviewContext();
  const { handleApprove } = useExpenseAdvanceApprovalReviewActionsContext();

  return (
    <Box
      width="100%"
      maxWidth={["auto", 431.58]}
      height="fit-content"
      border="1px solid"
      borderColor="border"
    >
      <Box p={[16, 24]} backgroundColor="#F7F8FD">
        <Text fontSize={[1, 2]} fontWeight="bold">
          Resumo da solicitação
        </Text>
      </Box>

      <Box
        p={[16, 24]}
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius={0}
      >
        <Paragraph fontSize={[1, 2]} fontWeight="bold" mb="medium">
          Adiantamento
        </Paragraph>

        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize={[1, 2]} fontWeight="bold">
            Valor
          </Text>
          <Text fontSize={[1, 2]}>
            {formatCurrency(
              expenseAdvance!.requestedValue,
              expenseAdvance!.currency
            )}
          </Text>
        </Flex>
      </Box>
      <Flex
        p={[16, 24]}
        alignItems="center"
        justifyContent="center"
        borderTop="1px solid"
        borderTopColor="border"
        borderRadius={0}
      >
        <StContainedButton
          css={styles.button}
          onClick={handleApprove}
          disabled={isSubmitting}
        >
          Pagar adiantamento
        </StContainedButton>
      </Flex>
    </Box>
  );
};

export { PaymentCard };
