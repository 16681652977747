import React from "react";

import { css } from "@styled-system/css";
import PropTypes from "prop-types";

import { Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import Utils from "@helpers";

import { Paper } from "./Paper";

const styles = {
  card: css({
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem 1.5rem",
    height: "100%"
  }),
  titleContainer: css({
    display: "flex",
    alignItems: "flex-start"
  }),
  title: css({
    fontSize: [1, 1, 2],
    fontWeight: "bold",
    color: defaultTheme.subTextColor,
    pb: "medium",
    lineHeight: "body",
    textTransform: "uppercase"
  }),
  subtitle: css({
    alignSelf: "center",
    fontSize: 14,
    pl: "small",
    lineHeight: "body",
    color: defaultTheme.subTextColor,
    mb: "1rem",
    fontWeight: "bold"
  })
};

const DetailsCard = ({ title, subtitle, customTotalName, total, children }) => (
  <Paper css={styles.card}>
    <div css={styles.titleContainer}>
      <p css={styles.title}>{title}</p>
      <p css={styles.subtitle}>{subtitle}</p>
      <Flex
        flexDirection="column"
        ml="auto"
        width="180px"
        pl="large"
        alignItems="flex-end"
        pt="small"
        color={defaultTheme.subTextColor}
      >
        <Text fontWeight="bold" fontSize={1}>
          {customTotalName || "Total"}
        </Text>
        <Text mt="4px" fontWeight="bold" fontSize={[1, 2, 2]}>
          {Utils.toCurrency(total)}
        </Text>
      </Flex>
    </div>
    <div>{children}</div>
  </Paper>
);

DetailsCard.defaultProps = {
  total: 0
};

DetailsCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.number
};

export { DetailsCard };
