import React, { useMemo, useState } from "react";

import { getAirportsSuggestions } from "~/apps/corporate/apis/autosuggest.api";
import { CABIN_CLASSES_MAP } from "~/apps/corporate/components/offer-searcher/flight-form/flight-form.constants";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";

import { Button, CircularSpinner } from "@toolkit/v2";

import { AsyncAutocomplete } from "../../../../shared/components/async-autocomplete/async-autocomplete";
import { DatePicker } from "../../../../shared/components/date-picker/date-picker";
import { Form, useForm } from "../../../../shared/components/form/form";
import { Icon } from "../../../../shared/components/icon/icon";
import { InputErrorMessage } from "../../../../shared/components/input-error-message/input-error-message";
import { Select } from "../../../../shared/components/select/select";
import {
  flightFormSchema,
  FlightFormSchema,
} from "../../offer-searcher/flight-form/flight-form.schema";
import { useOfferSearcher } from "../../offer-searcher/offer-searcher.context";
import { FlightsSearch } from "../../offer-searcher/offer-searcher.types";
import { styles } from "./styles";

type Props = {
  data: Partial<FlightsSearch> | null;
  onClose: () => void;
  travelToken: string;
};

export const OfferSearcherDialogFlightForm: React.FC<Props> = ({
  data,
  onClose,
  travelToken,
}) => {
  const { clientConfig } = useClientConfig();
  const { handleSearchFlights } = useOfferSearcher();

  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);

  const form = useForm<FlightFormSchema>({
    defaultValues: data
      ? {
          cabinClass: data.cabinClass ? data.cabinClass : "economy",
          destination: data.destination ? data.destination : null,
          endDate: data.endDate ? data.endDate : null,
          origin: data.origin ? data.origin : null,
          startDate: data.startDate ? data.startDate : null,
          type: data.type ? data.type : "roundtrip",
        }
      : {
          cabinClass: "economy",
          destination: null,
          endDate: null,
          origin: null,
          startDate: null,
          type: "roundtrip",
        },
    onSubmit: async () => {
      const values = form.getValues();

      await handleSearchFlights({
        cabinClass: values.cabinClass,
        destination: values.destination,
        endDate: values.endDate,
        origin: values.origin,
        startDate: values.startDate,
        travelToken,
        type: values.type,
      });

      onClose();
    },
    schema: flightFormSchema,
  });

  const clientFlightAllowedCabinClasses = clientConfig
    ? clientConfig.getFlightAllowedCabinClasses()
    : null;

  const cabinClassOptions = useMemo(() => {
    if (!clientFlightAllowedCabinClasses) {
      return Object.values(CABIN_CLASSES_MAP).map((cabinClass) => ({
        label: cabinClass.name,
        value: cabinClass.value,
      }));
    }

    const clientFlightAllowedCabinClassesOptions = clientFlightAllowedCabinClasses.map(
      (cabinClass) => CABIN_CLASSES_MAP[cabinClass],
    );

    return clientFlightAllowedCabinClassesOptions.map(
      (clientFlightAllowedCabinClass) => ({
        label: clientFlightAllowedCabinClass.name,
        value: clientFlightAllowedCabinClass.value,
      }),
    );
  }, [clientFlightAllowedCabinClasses]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.center.root}>
        <div css={styles.center.control}>
          <div css={styles.center.type.root}>
            <label css={styles.center.type.radio}>
              <Checkbox
                checked={form.watch("type") === "oneway"}
                onChange={() => {
                  form.setValue("type", "oneway");

                  if (form.watch("type") === "oneway") {
                    form.clearErrors("endDate");
                  }
                }}
                variant="pink"
              />
              <span css={styles.center.type.label}>Só ida</span>
            </label>
            <label css={styles.center.type.radio}>
              <Checkbox
                checked={form.watch("type") === "roundtrip"}
                onChange={() => {
                  form.setValue("type", "roundtrip");
                }}
                variant="pink"
              />
              <span css={styles.center.type.label}>Ida e volta</span>
            </label>
          </div>
          <InputErrorMessage>{form.errors["type"]?.message}</InputErrorMessage>
        </div>
        <div css={styles.center.bounds}>
          <div css={styles.center.control}>
            <span css={styles.center.label}>Origem</span>
            <AsyncAutocomplete
              css={styles.center.input.input}
              defaultValue={form.watch("origin")?.label}
              fetchItems={async (search) => {
                try {
                  return {
                    data: await getAirportsSuggestions(search),
                  };
                } catch (error) {
                  return {
                    error,
                  };
                }
              }}
              inputProps={{
                placeholder: "Digite o local de origem...",
              }}
              onSelect={(location) => {
                if (!location) {
                  form.setValue("origin", null);

                  return;
                }

                form.setValue("origin", location);
              }}
              renderInput={({ loading, ...props }) => (
                <div css={styles.location.input.root}>
                  <Icon size={20} use="airplane-taking-off" />
                  <input css={styles.location.input.input} {...props} />
                  {loading ? <CircularSpinner size={16} /> : null}
                </div>
              )}
              renderItem={({ isHighlighted, item, ...props }) => {
                if (item.error) {
                  return (
                    <li
                      css={styles.location.suggestion.item}
                      key={0}
                      {...props}
                    >
                      Nenhum local encontrado
                    </li>
                  );
                }

                return (
                  <li
                    css={styles.location.suggestion.button({ isHighlighted })}
                    key={JSON.stringify(item)}
                    {...props}
                  >
                    {item.label}
                  </li>
                );
              }}
            />
            <InputErrorMessage>
              {form.errors["origin"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.center.control}>
            <span css={styles.center.label}>Destino</span>
            <AsyncAutocomplete
              css={styles.center.input.input}
              defaultValue={form.watch("destination")?.label}
              fetchItems={async (search) => {
                try {
                  return {
                    data: await getAirportsSuggestions(search),
                  };
                } catch (error) {
                  return {
                    error,
                  };
                }
              }}
              inputProps={{
                placeholder: "Digite o local de destino...",
              }}
              onSelect={(location) => {
                if (!location) {
                  form.setValue("destination", null);

                  return;
                }

                form.setValue("destination", location);
              }}
              renderInput={({ loading, ...props }) => (
                <div css={styles.location.input.root}>
                  <Icon size={20} use="airplane-landing" />
                  <input css={styles.location.input.input} {...props} />
                  {loading ? <CircularSpinner size={16} /> : null}
                </div>
              )}
              renderItem={({ isHighlighted, item, ...props }) => {
                if (item.error) {
                  return (
                    <li
                      css={styles.location.suggestion.item}
                      key={0}
                      {...props}
                    >
                      Nenhum local encontrado
                    </li>
                  );
                }

                return (
                  <li
                    css={styles.location.suggestion.button({ isHighlighted })}
                    key={JSON.stringify(item)}
                    {...props}
                  >
                    {item.label}
                  </li>
                );
              }}
            />
            <InputErrorMessage>
              {form.errors["destination"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.center.control}>
          <span css={styles.center.label}>
            {form.watch("type") === "oneway" ? "Partida" : "Ida - Volta"}
          </span>
          <div css={styles.center.dates}>
            {form.watch("type") === "oneway" ? (
              <DatePicker
                date={form.watch("startDate")}
                focused={rangeDateFocus === "startDate"}
                id="startDate"
                onDateChange={(date) => {
                  form.setValue("startDate", date);

                  if (date) {
                    form.clearErrors("startDate");
                  }
                }}
                onFocusChange={({ focused }) => {
                  setRangeFocus(focused ? "startDate" : null);
                }}
                placeholder="Ida"
                range={false}
                renderCalendarInfo={undefined}
              />
            ) : (
              <DatePicker
                allowSameDay
                customArrowIcon={<Icon size={16} use="calendar" />}
                endDate={form.watch("endDate")}
                endDatePlaceholderText="volta"
                focusedInput={rangeDateFocus}
                onDatesChange={(date) => {
                  form.setValue("startDate", date.startDate);

                  if (date.startDate) {
                    form.clearErrors("startDate");
                  }

                  form.setValue("endDate", date.endDate);

                  if (date.endDate) {
                    form.clearErrors("endDate");
                  }
                }}
                onFocusChange={(focused) => {
                  setRangeFocus(focused);
                }}
                range
                renderCalendarInfo={undefined}
                startDate={form.watch("startDate")}
                startDatePlaceholderText="Ida"
              />
            )}
          </div>
          <InputErrorMessage>
            {form.errors["startDate"]?.message ||
              form.errors["endDate"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.center.control}>
          <span css={styles.center.label}>Classe</span>
          <Select
            css={styles.center.cabin}
            id="cabinClass"
            name="cabinClass"
            onChange={({ value }) => {
              form.setValue("cabinClass", value);
            }}
            options={cabinClassOptions}
            placeholder="Selecione a categoria"
            value={cabinClassOptions.find(
              (cabinClass) => cabinClass.value === form.watch("cabinClass"),
            )}
          />
          <InputErrorMessage>
            {form.errors["cabinClass"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.bottom.root}>
        <Button css={styles.bottom.button} variant="pink">
          Pesquisar voos
        </Button>
      </div>
    </Form>
  );
};
