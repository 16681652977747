import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use/lib";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import {
  ALERT_TYPES,
  DEFAULT_COMPANY_AREA_NAME,
} from "~/apps/shared/constants";

import { useAreas } from "../areas.context";
import * as areasService from "../areas.service";
import { styles } from "./styles";

export const AreasCustomName: React.FC = () => {
  const { showSnackMessage } = useApplication();
  const {
    clientConfig,
    handleUpdateCompanyAreaNameDisplay,
  } = useClientConfig();

  const { companyAreaDisplay } = useAreas();

  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState("");

  const isFirstRender = useRef(true);

  const nameAlreadyDerivedFromClientConfig = useRef(false);

  const clientConfigToken = clientConfig ? clientConfig.getToken() : null;

  const handleBlur = () => {
    if (name.length < 2) {
      return setError("O nome deve ter ao menos 2 caracteres.");
    }

    if (name.length > 16) {
      return setError("O nome deve ter no máximo 16 caracteres.");
    }

    setError(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setName(e.target.value);
  };

  useDebounce(
    async () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;

        return;
      }

      if (!name.length) {
        return;
      }

      if (clientConfigToken) {
        const { error } =
          name.length < 2 || name.length > 16
            ? await areasService.updateAreaDisplayName(
                clientConfigToken,
                DEFAULT_COMPANY_AREA_NAME,
              )
            : await areasService.updateAreaDisplayName(clientConfigToken, name);

        if (error) {
          showSnackMessage(error.description, ALERT_TYPES.ERROR);
        }
      }

      name.length < 2 || name.length > 16
        ? handleUpdateCompanyAreaNameDisplay(DEFAULT_COMPANY_AREA_NAME)
        : handleUpdateCompanyAreaNameDisplay(name);
    },
    300,
    [name],
  );

  useEffect(() => {
    if (companyAreaDisplay && !nameAlreadyDerivedFromClientConfig.current) {
      setName(companyAreaDisplay);

      nameAlreadyDerivedFromClientConfig.current = true;
    }
  }, [companyAreaDisplay]);

  return (
    <div css={styles.root}>
      <span css={styles.label}>Nome personalizado</span>
      <Input
        css={styles.input}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Escolha um nome para essa área"
        value={name ? name : ""}
      />
      {error ? <InputErrorMessage>{error}</InputErrorMessage> : null}
    </div>
  );
};
