import React, { useEffect, useContext, useMemo } from "react";

import ProfileIcon from "@material-ui/icons/AccountCircle";
import { RouteComponentProps } from "@reach/router";
import clsx from "classnames";
import { css } from "emotion";
import { Moment } from "moment";
import { theme } from "smartrips-skin";

import {
  MEDIA_QUERIES,
  EXPENSE_STATUS,
  EXPENSE_REPORT_STATUS,
  EXPENSE_APPROVAL_STATUS,
  EXPENSES_CATEGORIES,
} from "@constants";
import PAGE_TITLES from "@constants/page-titles.constants";

import * as expensesHelper from "@helpers/expense.helper";

import { Expense } from "@models/expense.model";

import { defaultTheme } from "@theme";

import { ExpenseOutOfPolicyDialog } from "@shared/expense-policy-dialogs/ExpenseOutOfPolicyDialog";
import Layout from "@shared/Layout";
import { Column, Row } from "@shared/layout/index";
import { Desktop, TabletAndMobile } from "@shared/layout/Responsive";
import PageTitle from "@shared/PageTitle";
import SpinnerPortal from "@shared/Spinner";

import { AdministrativeInfo } from "~/components/shared/administrative-info";

import { ExpenseDrawer } from "../ExpenseDrawer/ExpenseDrawer";
import { AdvancedExpenseItem } from "../shared/AdvancedExpenseItem/AdvancedExpenseItem";
import { ApprovalHistoryContainer } from "../shared/ApprovalHistory/ApprovalHistory.container";
import { CategoriesBreakdown } from "../shared/CategoriesBreakdown";
import { ExpensesListByDate } from "../shared/ExpensesListByDate";
import { InvoicesDownload } from "../shared/InvoicesDownload";
import { ReportSubNavbar } from "../shared/ReportSubNavbar";
import { ExpenseItem } from "./ExpenseItem/ExpenseItem.container";
import {
  ReportApprovalReviewContext,
  ReportApprovalReviewProvider,
  useReportCategoryFormContext,
} from "./ReportApprovalReview.context";
import { ApprovalAnsweredCard } from "./SideCards/ApprovalAnsweredCard";
import { InfoSideCard } from "./SideCards/InfoSideCard";
import { PaymentCard } from "./SideCards/PaymentCard";

const styles = {
  container: css({
    width: "100%",
    paddingRight: "16px",
    paddingLeft: "16px",
    marginRight: "auto",
    marginLeft: "auto",
    ["@media (min-width: 1200px)"]: {
      maxWidth: 1250,
    },
  }),
  root: css({
    padding: "2.5rem 0 3.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      padding: ".5rem 0 3.5rem",
    },
  }),
  rowContainer: css({
    display: "flex",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      flexDirection: "column-reverse",
      paddingBottom: "2.5rem",
    },
  }),
  mainCard: css({
    [MEDIA_QUERIES.desktopBreakpoint]: {
      border: `1px solid ${defaultTheme.cardBorderColor}`,
      borderRadius: 4,
      padding: "3rem 4.5rem 2rem 4.5rem",
      width: 740,
      height: "fit-content",
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "1.5rem 0 0 0",
      width: "100%",
      maxWidth: "100%",
    },
  }),
  sideCard: css({
    [MEDIA_QUERIES.desktopBreakpoint]: {
      maxWidth: 463.59,
      width: "100%",
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      padding: "0 !important",
    },
  }),
  buttonsRow: css({
    ["@media (max-width: 395px)"]: {
      justifyContent: "space-between",
    },
  }),
  button: css({
    padding: ".5rem 1.5rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      minWidth: "150",
    },
  }),
  addToReportButton: css({
    padding: ".5rem 1.5rem",
    ["@media (min-width: 396px)"]: {
      marginLeft: "2rem",
    },
  }),
  travelInfo: css({
    paddingBottom: "4rem",
    justifyContent: "center",
    alignItems: "center",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingBottom: "1.5rem",
    },
  }),
  reportDescription: css({
    fontSize: 28,
    fontWeight: "bold",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      paddingTop: 26,
    },
    [MEDIA_QUERIES.tabletBreakpoint]: {
      fontSize: 24,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 20,
    },
  }),
  period: css({
    [MEDIA_QUERIES.tabletBreakpoint]: {
      fontSize: 16,
      paddingBottom: ".5rem",
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 12,
      paddingBottom: ".5rem",
    },
  }),
  travelerInfo: css({
    paddingBottom: "2.5rem",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 16,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 14,
    },
  }),
  travelerAvatar: css({
    paddingRight: "1rem",
    ["& svg"]: {
      height: 45,
      width: 45,
      color: theme.colors.secondary,
    },
  }),
  noExpenses: css({
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: defaultTheme.textColor,
    [MEDIA_QUERIES.tabletBreakpoint]: {
      paddingTop: "2rem",
      fontSize: 14,
    },
    [MEDIA_QUERIES.mobileBreakpoint]: {
      paddingTop: "2rem",
      fontSize: 13,
    },
  }),
  avatarDiv: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [MEDIA_QUERIES.tabletBreakpoint]: {
      lineHeight: "20px",
    },
  }),
  pageTitle: css({
    fontSize: 24,
    fontWeight: "bolder",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    paddingTop: "2.5rem",
  }),
  expensesListByDate: css({
    margin: "1.5rem 0 0rem 2rem",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      margin: 0,
    },
  }),
};

interface Props {
  reportToken: string;
}

const ReportApprovalReview = ({
  reportToken,
  ...rest
}: RouteComponentProps<Props>) => {
  const {
    report,
    expensesData,
    approvalStatus,
    approvalResponseStatus,
    expensesPolicies,
    traveler,
    reportPeriod,
    loading,
    respondingApprove,
    respondingDeny,
    isDownloadingInvoices,
    fetchReportData,
    isDeclineFormVisible,
    isExpenseDrawerOpen,
    selectedExpenseToEdit,
    selectedReceiptToEdit,
    selectedExpenseToShowPolicy,
    openExpenseDrawer,
    handleCloseExpenseDrawer,
    handleChangeExpenseApprovedValue,
    handleApproveRequest,
    handleOpenDeclineForm,
    handleShowExpenseOutOfPolicyInfo,
    handleClosePolicyDialog,
    handleDownloadInvoices,
    onAfterSaveExpense,
  } = useContext(ReportApprovalReviewContext);
  const {
    isCategoryFormVisible,
    canProcessApproval,
    administrativeInfo,
  } = useReportCategoryFormContext();

  const { state } = rest.location as any;
  const origin = state && state.origin ? state.origin : null;

  useEffect(() => {
    if (reportToken) {
      fetchReportData(reportToken);
    }
  }, []);

  const handleEditExpense = (selectedExpense: Expense) => () =>
    openExpenseDrawer(selectedExpense);

  const getFormattedReportPeriod = (start: Moment, end: Moment) => {
    return (
      start.format("ddd, DD MMM YYYY") + " - " + end.format("ddd, DD MMM YYYY")
    );
  };

  const haveAllExpensesBeenAssignedValidValue = useMemo(() => {
    const activeExpenses = expensesData.filter(
      (expense) =>
        expense.status === EXPENSE_STATUS.CREATED_MANUAL && !expense.canceled,
    );

    const doesAllExpensesHaveApprovedValues = activeExpenses.every(
      (expense) =>
        (!!expense.approvedValue || expense.approvedValue === 0) &&
        expense.approvedValue <= expensesHelper.convertCurrencyValue(expense),
    );

    const atLeastOneApprovedExpense = activeExpenses.some(
      (expense) => !!expense.approvedValue && expense.approvedValue > 0,
    );

    return doesAllExpensesHaveApprovedValues && atLeastOneApprovedExpense;
  }, [expensesData]);

  const canEditSelectExpense = useMemo(() => {
    return (
      !!selectedExpenseToEdit &&
      selectedExpenseToEdit.status === EXPENSE_STATUS.CREATED_MANUAL &&
      !!approvalStatus &&
      approvalStatus.status !== EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT &&
      approvalStatus.status !== EXPENSE_APPROVAL_STATUS.PAID
    );
  }, [selectedExpenseToEdit, approvalStatus]);

  const getTitle = () => {
    if (
      approvalStatus &&
      approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL
    ) {
      return "Pedido de aprovação";
    } else if (
      approvalStatus &&
      approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT
    ) {
      return "Pagamento de despesas";
    } else if (
      approvalStatus &&
      approvalStatus.status === EXPENSE_APPROVAL_STATUS.PAID
    ) {
      return "Relatório pago";
    }
  };

  const canEditApprovedValues =
    !!approvalStatus &&
    approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL &&
    approvalResponseStatus === "UNCHANGED";

  const canApproveReport =
    (haveAllExpensesBeenAssignedValidValue &&
      (!respondingApprove || !respondingDeny) &&
      !isCategoryFormVisible) ||
    (haveAllExpensesBeenAssignedValidValue &&
      (!respondingApprove || !respondingDeny) &&
      canProcessApproval &&
      isCategoryFormVisible &&
      approvalStatus?.status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL);

  const expensesMapByDate = useMemo(() => {
    const visibleExpenses = expensesData.filter(
      (ex) => ex.expenseCategory !== EXPENSES_CATEGORIES.FEE,
    );
    const sortedExpenses = expensesHelper.sortExpensesByDate(visibleExpenses);
    return expensesHelper.getExpensesMapByDate(sortedExpenses);
  }, [expensesData]);

  if (report && approvalStatus) {
    const renderAppropriateSideCard = () => {
      if (
        approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL &&
        approvalResponseStatus === "UNCHANGED"
      ) {
        return (
          <>
            <InfoSideCard
              expenses={expensesData}
              advancedExpenses={report.advancedExpenses}
              showDeclineForm={isDeclineFormVisible}
              showCategoryForm={isCategoryFormVisible}
              loadingApprove={respondingApprove}
              loadingDeny={respondingDeny}
              isApproveEnabled={canApproveReport}
              handleApprove={handleApproveRequest}
              handleDeny={handleOpenDeclineForm}
            />
            <CategoriesBreakdown
              expenses={expensesData}
              showBothColumns={true}
            />
          </>
        );
      } else if (
        approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_PAYMENT &&
        approvalResponseStatus === "UNCHANGED"
      ) {
        return (
          <>
            <PaymentCard
              expenses={expensesData}
              advancedExpenses={report.advancedExpenses}
              loading={respondingApprove}
              isApproveEnabled={canApproveReport}
              showCategoryForm={isCategoryFormVisible}
              showDeclineForm={isDeclineFormVisible}
              handleApprove={handleApproveRequest}
              handleDeny={handleOpenDeclineForm}
            />
            <CategoriesBreakdown
              expenses={expensesData}
              showBothColumns={true}
            />
          </>
        );
      } else if (
        approvalStatus.status === EXPENSE_APPROVAL_STATUS.PENDING_APPROVAL &&
        approvalResponseStatus === "APPROVED"
      ) {
        return (
          <>
            <ApprovalAnsweredCard
              traveler={traveler!}
              type={approvalResponseStatus}
            />
            <CategoriesBreakdown
              expenses={expensesData}
              showBothColumns={true}
              secondColumnName={"Aprovado"}
            />
          </>
        );
      } else if (
        approvalStatus.status === EXPENSE_APPROVAL_STATUS.PAID &&
        report.status === EXPENSE_REPORT_STATUS.CLOSED &&
        approvalResponseStatus === "PAID"
      ) {
        return (
          <>
            <ApprovalAnsweredCard
              traveler={traveler!}
              type={approvalResponseStatus}
            />
            <CategoriesBreakdown
              expenses={expensesData}
              showBothColumns={true}
              secondColumnName={"Aprovado"}
            />
          </>
        );
      } else if (
        approvalStatus.status === EXPENSE_APPROVAL_STATUS.DECLINED &&
        report.status === EXPENSE_REPORT_STATUS.DECLINED &&
        approvalResponseStatus === "DECLINED"
      ) {
        return (
          <>
            <ApprovalAnsweredCard
              traveler={traveler!}
              type={approvalResponseStatus}
            />
            <CategoriesBreakdown expenses={expensesData} />
          </>
        );
      }
      // else if (
      //   approvalStatus.status === EXPENSE_APPROVAL_STATUS.PAID &&
      //   report.status === EXPENSE_REPORT_STATUS.CLOSED &&
      //   approvalResponseStatus === "UNCHANGED"
      // ) {
      //   return <PaidReportCard expenses={expensesData} />;
      // }
    };

    return (
      <Layout>
        <PageTitle
          title={
            PAGE_TITLES.EXPENSE_APPROVAL_REVIEW + " - " + report.description
          }
        />
        <ReportSubNavbar
          pageName="Relatório de despesa"
          reportToken={report.expenseReportToken}
          reportName={report.description}
          origin={origin}
        />
        <div className={styles.container}>
          <Desktop>
            <div className={styles.pageTitle}>
              <span>{getTitle()}</span>
            </div>
          </Desktop>
          <div className={styles.root}>
            <div className={styles.rowContainer}>
              <Column className={`${styles.mainCard}`}>
                <Column className={styles.travelInfo}>
                  <Desktop>
                    <p>
                      {getFormattedReportPeriod(
                        reportPeriod!.start,
                        reportPeriod!.end,
                      )}
                    </p>
                    <p className={styles.reportDescription}>
                      {report.description}
                    </p>
                  </Desktop>
                  <TabletAndMobile>
                    <p className={styles.period}>
                      {report.expenses.length > 0
                        ? getFormattedReportPeriod(
                            reportPeriod!.start,
                            reportPeriod!.end,
                          )
                        : null}
                    </p>
                    <p className={styles.reportDescription}>
                      {report.description}
                    </p>
                  </TabletAndMobile>
                </Column>
                {traveler ? (
                  <Row className={styles.travelerInfo}>
                    <Desktop>
                      <div className={styles.travelerAvatar}>
                        <ProfileIcon />
                      </div>
                    </Desktop>
                    <div className={styles.avatarDiv}>
                      <p>Viajante</p>
                      <p style={{ fontWeight: "bold" }}>{traveler.fullName}</p>
                    </div>
                  </Row>
                ) : null}
                <Column>
                  {!report.expenses.length &&
                  !report.advancedExpenses.length ? (
                    <Row className={styles.noExpenses}>
                      Nenhuma despesa adicionada, <br /> utilize do botão acima
                      para adicionar despesas ao seu relatório
                    </Row>
                  ) : null}
                  {report.advancedExpenses.map((advancedExpense) => (
                    <AdvancedExpenseItem
                      key={advancedExpense.expenseAdvanceToken}
                      advancedExpense={advancedExpense}
                      isRemovable={false}
                    />
                  ))}
                  {report.expenses.length ? (
                    <ExpensesListByDate expensesMap={expensesMapByDate}>
                      {(expense) => (
                        <div className={styles.expensesListByDate}>
                          <ExpenseItem
                            key={expense.expenseToken}
                            expense={expense}
                            policyInfo={expensesPolicies[expense.expenseToken]}
                            editable={canEditApprovedValues}
                            onClick={handleEditExpense(expense)}
                            onInputChange={handleChangeExpenseApprovedValue}
                            handleOpenPolicyInfo={
                              handleShowExpenseOutOfPolicyInfo
                            }
                          />
                        </div>
                      )}
                    </ExpensesListByDate>
                  ) : null}
                </Column>
              </Column>
              <Desktop>
                <div style={{ padding: "0 1rem" }} />
              </Desktop>
              <Column className={clsx("col-md-4", styles.sideCard)}>
                {renderAppropriateSideCard()}
                {report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT && (
                  <AdministrativeInfo
                    companyArea={administrativeInfo.companyArea}
                    billingProfile={administrativeInfo.billingProfile}
                    costCenter={administrativeInfo.costCenter}
                    project={administrativeInfo.project}
                    boxProps={{
                      mb: [0, "large"],
                    }}
                  />
                )}
                {(report.status === EXPENSE_REPORT_STATUS.PENDING_PAYMENT ||
                  report.status === EXPENSE_REPORT_STATUS.CLOSED) && (
                  <InvoicesDownload
                    isDownloading={isDownloadingInvoices}
                    handleDownload={handleDownloadInvoices}
                  />
                )}
                <ApprovalHistoryContainer approvalStatus={approvalStatus} />
              </Column>
              <ExpenseDrawer
                open={isExpenseDrawerOpen}
                readOnly={!canEditSelectExpense}
                selectedExpense={selectedExpenseToEdit}
                selectedReceipt={selectedReceiptToEdit}
                reportToken={report.expenseReportToken}
                onAfterSave={onAfterSaveExpense}
                handleClose={handleCloseExpenseDrawer}
              />
              <ExpenseOutOfPolicyDialog
                open={!!selectedExpenseToShowPolicy}
                expense={selectedExpenseToShowPolicy}
                policies={expensesPolicies}
                onClose={handleClosePolicyDialog}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <PageTitle title={PAGE_TITLES.EXPENSE_REPORT} />
        <SpinnerPortal visible={loading} />
        <ReportSubNavbar
          pageName="Relatório de despesa"
          reportToken={""}
          reportName={""}
          origin={origin}
        />
        <div className={styles.container} />
      </Layout>
    );
  }
};

const Container = (props: RouteComponentProps<Props>) => (
  <ReportApprovalReviewProvider>
    <ReportApprovalReview {...props} />
  </ReportApprovalReviewProvider>
);

export { Container as ReportApprovalReview };
