import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    justifyContent: "center",
    width: "100%",
  }),
  message: css({
    color: theme.colors.gray[300],
    fontSize: "0.75rem",
  }),
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  }),
};
