import React from "react";

import { Avatar } from "../avatar-group/avatar/avatar";
import { styles } from "./styles";

type Props = {
  target: {
    email: string;
    fullName: string;
  };
};

export const TargetItem: React.FC<Props> = ({ target }) => {
  return (
    <div css={styles.root}>
      <Avatar css={styles.avatar} name={target.fullName} />
      <div css={styles.info.root}>
        <div css={styles.info.name}>{target.fullName}</div>
        <div css={styles.info.email}>{target.email}</div>
      </div>
    </div>
  );
};
