import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Control, useFieldArray } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { components, OptionProps } from "react-select";
import AsyncAutocomplete from "react-select/async";
import { MultiValueGenericProps } from "react-select/src/components/MultiValue";

import { WarningOutlined } from "@material-ui/icons";
import { navigate } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ScrollToTopOnMount } from "~/utils";

import { Flex, Text } from "@toolkit";

import { ApprovalProcessTypes } from "~/constants/enums";

import {
  APPROVAL_POLICY_LABELS,
  APPROVAL_RULES_TERRITORIES_LABELS,
  APPROVAL_TYPES,
  APPROVAL_PROCESS_TYPES_LABELS,
  APPROVAL_PROCESS_TYPES,
} from "@constants";

import { sortByField } from "~/helpers";

import { ApprovalProcessDto } from "@dtos/approval-process.dto";

import {
  ApprovalProcessFormType,
  ApprovalProcessRulesType,
  ApprovalTarget,
  Rule,
} from "@models/approval-process.model";

import { Option } from "~/types";

import { TargetItem } from "~/components/configuration/shared/TargetItem";
import { Divider } from "~/components/shared/Divider";

import AsyncData from "@components/shared/async-data";
import {
  ContainedButton,
  IContainedButtonProps,
} from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";
import { Form, useForm, useFormContext } from "@components/shared/form";
import { FormFieldAdditionalText } from "@components/shared/form/FormField";
import FullpageLoader from "@components/shared/fullpage-loader";
import { Input as NewInput } from "@components/shared/input";
import { OutlinedSelect } from "@components/shared/outlined-select";
import RadioGroup from "@components/shared/radio-group";

import approvalProcessSchema from "../approval-process.schema";
import {
  searchRuleTarget,
  createRule,
  editRule,
  getSingleApproverToken,
  loadApprovalRule,
} from "../approval-processes.service";
import { GetApprovalProcessResult } from "../approval-processes.types";
import { ApprovalProcessApproveRule } from "./approval-process-rule/approval-process-approve-rule/approval-process-approve-rule";
import { ApprovalProcessNotifyRule } from "./approval-process-rule/approval-process-notify-rule/approval-process-notify-rule";
import { APPROVAL_PROCESS_TYPES_OPTIONS } from "./approval-process-rule/approval-process-options/approval-process-options";
import { asyncAutoCompleteStyles, styles } from "./styles";

const DEFAULT_APPROVAL_TYPES_OPTIONS = [
  {
    label: "Usuário",
    value: APPROVAL_TYPES.USER,
  },
  {
    label: "Projeto",
    value: APPROVAL_TYPES.PROJECT,
  },
  {
    label: "Centro de custo",
    value: APPROVAL_TYPES.COST_CENTER,
  },
  {
    label: "Área",
    value: APPROVAL_TYPES.AREA,
  },
];

const DEFAULT_APPROVAL_TYPES_OPTIONS_MAP: Record<
  string,
  Option<ApprovalProcessTypes>
> = DEFAULT_APPROVAL_TYPES_OPTIONS.reduce((acc: any, curr) => {
  acc[curr.value] = curr;
  return acc;
}, {});

function handleSearchTarget(type: string) {
  return async function (query: string) {
    const response = await searchRuleTarget(query, type);

    return response.data;
  };
}

async function loadApprovalProcess(token?: string) {
  if (!token) {
    return { data: {} } as GetApprovalProcessResult;
  }

  return await loadApprovalRule(token);
}

const sortByName = sortByField("name");

// Map backend format to form expected format
function mapDefaultValues(data: ApprovalProcessDto): ApprovalProcessFormType {
  let allRules;
  let rules: Rule[] = [];

  if (data.rules) {
    allRules = data.rules.reduce(
      (acc: Record<string, Rule[]>, current) => {
        const approvalRules = current.actions.filter(
          (action) => action.type === APPROVAL_PROCESS_TYPES.APPROVE,
        );
        if (approvalRules.length) {
          acc.approvalRules.push({
            actions: approvalRules,
            policy: current.policy,
            territory: current.territory,
            type: current.type,
          });
        }

        const notifyRules = current.actions.filter(
          (action) => action.type === APPROVAL_PROCESS_TYPES.NOTIFY,
        );

        if (notifyRules.length) {
          acc.notificationRules.push({
            actions: notifyRules,
            policy: current.policy,
            territory: current.territory,
            type: current.type,
          });
        }

        return acc;
      },
      {
        approvalRules: [],
        notificationRules: [],
      },
    );

    rules = [...allRules.approvalRules, ...allRules.notificationRules];
  }

  const mappedData: ApprovalProcessFormType = {
    ...data,
    approvalNeeded:
      typeof data.approvalNeeded !== "undefined" ? data.approvalNeeded : true,
    rules: rules.map((rule) => {
      const types = [...new Set(rule.actions.map((action) => action.type))];
      const actions = types.map((type) => {
        const currentActions = rule.actions.filter(
          (action) => action.type === type,
        );

        const allStages = [
          ...new Set(currentActions.map((action) => action.stage)),
        ];

        const stages = allStages.map((stage) => {
          const users = currentActions.find((action) => action.stage === stage)
            ?.users;

          return {
            stage,
            users: users?.map((user) => ({
              token: user.token,
              name: user.fullName,
              email: user.email,
            })),
          };
        });

        if (allStages.length === 1 && type === APPROVAL_PROCESS_TYPES.APPROVE) {
          stages[1] = { stage: 2, users: [] };
        }

        return {
          type,
          stages,
        };
      });

      return {
        type: {
          label:
            rule.actions[0]?.type &&
            rule.actions[0].type in APPROVAL_PROCESS_TYPES_LABELS
              ? APPROVAL_PROCESS_TYPES_LABELS[rule.actions[0].type]
              : "",
          value: rule.actions[0]?.type,
        },
        policy: {
          label: APPROVAL_POLICY_LABELS[rule.policy],
          value: rule.policy,
        },
        territory: {
          label: APPROVAL_RULES_TERRITORIES_LABELS[rule.territory],
          value: rule.territory,
        },
        actions,
      } as ApprovalProcessRulesType;
    }),
    targets: data.targets ? data.targets.sort(sortByName) : [],
    type:
      data.type in DEFAULT_APPROVAL_TYPES_OPTIONS_MAP
        ? DEFAULT_APPROVAL_TYPES_OPTIONS_MAP[data.type]
        : {
            label: "Empresa",
            value: ApprovalProcessTypes.COMPANY,
          },
  };

  return mappedData;
}

const handleClose = (event?: SyntheticEvent) => {
  event?.preventDefault();
  navigate("/configurations/trips/approval-processes");
};

interface IApprovalProcessFormContainerProps {
  path?: string;
  token?: string;
  isEditing?: boolean;
}

export function ApprovalProcessFormContainer(
  props: IApprovalProcessFormContainerProps,
) {
  const { token } = props;

  const queryInfo = useQuery(["approval-processes", token], () =>
    loadApprovalProcess(token),
  );

  return (
    <div style={{ height: "100%" }}>
      <ScrollToTopOnMount />
      <AsyncData {...queryInfo}>
        <AsyncData.Success>
          {(data) => <ApprovalProcessForm {...props} defaultValue={data} />}
        </AsyncData.Success>
        <AsyncData.Loading>
          <FullpageLoader css={styles.loader} />
        </AsyncData.Loading>
      </AsyncData>
    </div>
  );
}

interface IApprovalProcessFormProps {
  isEditing?: boolean;
  token?: string;
  defaultValue: ApprovalProcessDto;
}

function ApprovalProcessForm(props: IApprovalProcessFormProps): JSX.Element {
  const { defaultValue, isEditing } = props;

  const queryClient = useQueryClient();

  const { showSnackMessage } = useApplication();
  const { clientConfig } = useClientConfig();

  const defaultTargets = useRef(defaultValue.targets);

  const isSingleApproverExist = !!props.defaultValue.sinlgeApproverToken;

  const isUniqueApprovalFlow = clientConfig
    ? clientConfig.isUniqueApproverFlow()
    : false;

  const isUniqueApprovalCreation = !isEditing && isUniqueApprovalFlow;

  const isUniqueApprovalProcess =
    isSingleApproverExist || isUniqueApprovalCreation;

  const context = useForm<ApprovalProcessFormType>({
    defaultValues: mapDefaultValues(defaultValue),
    schema: approvalProcessSchema,
    mode: "onChange",
    onSubmit: async function onSubmit(data) {
      const method = isEditing ? editRule : createRule;

      const singleApproverToken = isUniqueApprovalProcess
        ? getSingleApproverToken(data)
        : null;

      const response = await method({ ...data, singleApproverToken });

      if (response.error) {
        showSnackMessage(
          `${response.error.title}: ${response.error.description}`,
          "error",
        );

        return;
      }

      void queryClient.refetchQueries("approval-processes");

      showSnackMessage("Regra salva com sucesso!", "success");

      handleClose();
    },
  });

  const watchBasicFieldList = ["type", "targets", "approvalNeeded", "rules"];
  const singleApprovalProcessEmailField =
    "rules[0].actions[0].stages[0].users[0].email";

  const watchFieldList = isUniqueApprovalCreation
    ? [...watchBasicFieldList, singleApprovalProcessEmailField]
    : watchBasicFieldList;

  const watchFields = context.watch(watchFieldList);

  if (isUniqueApprovalCreation) {
    const singleApprovalProcessEmail = context.getValues(
      singleApprovalProcessEmailField,
    );
    context.setValue("name", singleApprovalProcessEmail);
  }

  useEffect(() => {
    if (isUniqueApprovalCreation) {
      context.setValue("type", DEFAULT_APPROVAL_TYPES_OPTIONS_MAP.USER);
    }
  }, [isUniqueApprovalCreation]);

  const handleOnTargetAdd = useCallback(
    (onChange) => {
      return (value: ApprovalTarget[]) => {
        const lastValue = value.length > 0 ? value[value.length - 1] : null;

        const targets = [
          ...(defaultTargets.current || []),
          ...(watchFields.targets || []),
        ];

        if (
          lastValue?.alreadyUsed &&
          !targets.find((target) => target.token === lastValue.token)
        ) {
          // Manually update value
          onChange(value.filter((x) => x.token !== lastValue.token));

          showSnackMessage(
            "Não é possível utilizar esse alvo pois ele já está em uso em outro Processo de Aprovação",
            "error",
          );

          return;
        }

        const sortedValue = value.sort(sortByName);

        onChange(sortedValue);
      };
    },
    [showSnackMessage, watchFields.targets],
  );

  return (
    <Form css={styles.formContainer} context={context}>
      <div>
        <div css={styles.titleContainer}>
          {isEditing ? (
            <h5 css={styles.title}>Editar processo de aprovação</h5>
          ) : (
            <h5 css={styles.title}>Criar processo de aprovação</h5>
          )}
        </div>
        <Divider />
        <div css={styles.contentContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {isUniqueApprovalCreation ? (
              <div css={styles.warningMessageContainerTop}>
                <h5 css={styles.messageCardTitle}>
                  Sua empresa aceita apenas fluxos simplificados de um único
                  aprovador!
                </h5>
                <p css={styles.warningMessage}>
                  Fluxos deste tipo são atualizados diariamente por meio das
                  APIs de integração. Por conta disso, os alvos escolhidos podem
                  ser alterados de forma automática pela integração.
                </p>
              </div>
            ) : null}
            {isSingleApproverExist ? (
              <div css={styles.warningMessageContainerTop}>
                <h5 css={styles.messageCardTitle}>
                  Este é um fluxo simplificado de um único aprovador
                </h5>
                <p css={styles.warningMessage}>
                  Fluxos deste tipo são atualizados diariamente por meio das
                  APIs de integração. Por conta disso, os alvos escolhidos podem
                  ser alterados de forma automática pela integração.
                </p>
              </div>
            ) : null}
            <Form.Field
              name="name"
              render={({ value, onChange }) => (
                <>
                  <label css={styles.label}>Nome do processo:</label>
                  <NewInput
                    placeholder={
                      isUniqueApprovalCreation
                        ? "Preenchimento automático"
                        : "Digite o nome que deseja atribuir"
                    }
                    value={value}
                    onChange={onChange}
                    disabled={isUniqueApprovalProcess}
                  />
                </>
              )}
            />

            {defaultValue.type !== APPROVAL_TYPES.COMPANY && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Form.Field<Option<ApprovalProcessTypes>>
                  name="type"
                  render={({ value, onChange }) => (
                    <>
                      <label css={styles.label}>
                        {watchFields.approvalNeeded
                          ? "Processo de aprovação para:"
                          : "Nenhuma aprovação necessária para:"}
                      </label>
                      <OutlinedSelect
                        aria-label="Selecione o Usuário, projeto, centro de custo ou área"
                        value={DEFAULT_APPROVAL_TYPES_OPTIONS_MAP[value.value]}
                        defaultValue={
                          DEFAULT_APPROVAL_TYPES_OPTIONS_MAP[value.value]
                        }
                        onChange={(option: any) => {
                          onChange(option);
                          context.setValue("targets", []);
                        }}
                        options={DEFAULT_APPROVAL_TYPES_OPTIONS}
                        placeholder="Selecione um atributo"
                        isDisabled={isUniqueApprovalProcess}
                      />
                    </>
                  )}
                />

                <Form.Field<ApprovalTarget>
                  additionalInformation={
                    isSingleApproverExist ? (
                      <></>
                    ) : (
                      <span style={{ marginLeft: "-1rem" }}>
                        Você pode adicionar mais de um alvo
                      </span>
                    )
                  }
                  name="targets"
                  render={({ value, onChange }) => (
                    <>
                      <label css={styles.label} style={{ marginTop: "1rem" }}>
                        {`Digite o nome do usuário${
                          isUniqueApprovalCreation
                            ? ""
                            : ", projeto, centro de custo ou área"
                        }`}
                      </label>
                      <AsyncAutocomplete<ApprovalTarget>
                        aria-label="Nome do usuário, projeto, centro de custo ou área"
                        isMulti
                        components={{
                          Option: AutocompleteTargetOption,
                          MultiValueLabel: AutocompleteMultiValueLabel,
                        }}
                        placeholder={`Nome do usuário${
                          isUniqueApprovalCreation
                            ? ""
                            : ", projeto, centro de custo ou área"
                        }`}
                        noOptionsMessage={() => ""}
                        isDisabled={
                          !watchFields.type?.value || isSingleApproverExist
                        }
                        onChange={handleOnTargetAdd(onChange)}
                        value={value}
                        loadOptions={handleSearchTarget(
                          watchFields.type?.value,
                        )}
                        getOptionValue={(option) => option.token}
                        getOptionLabel={(option) => option.name}
                        styles={asyncAutoCompleteStyles}
                      />
                    </>
                  )}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <Form.Field<boolean>
              name="approvalNeeded"
              render={({ value, onChange }) => (
                <RadioGroup
                  value={value}
                  onChange={({ target: { value } }) => {
                    onChange(value === "false" ? false : Boolean(value));
                    context.setValue("rules", []);
                    context.clearErrors();
                  }}
                >
                  <label css={styles.label}>
                    A aprovação é necessária no momento da reserva?
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      marginLeft: "0.875rem",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <RadioGroup.Item
                      value={true}
                      disabled={isUniqueApprovalProcess}
                    >
                      Sim
                    </RadioGroup.Item>
                    <RadioGroup.Item
                      value={false}
                      disabled={isUniqueApprovalProcess}
                    >
                      Não
                    </RadioGroup.Item>
                  </div>
                </RadioGroup>
              )}
            />

            <div style={{ marginBottom: "-1rem" }}>
              <label css={styles.label}>
                {watchFields.approvalNeeded
                  ? "Quem deve aprovar suas viagens?"
                  : "Notificações"}
                {!watchFields.approvalNeeded && (
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      color: "gray",
                      marginLeft: "0.5rem",
                    }}
                  >
                    Opcional
                  </span>
                )}
              </label>
              {isSingleApproverExist ? (
                ""
              ) : (
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    color: "gray",
                    marginTop: "-0.125rem",
                  }}
                >
                  {watchFields.approvalNeeded
                    ? `Adicione regras de aprovação para definir quem deve aprovar
                viagens ou receber notificações por e-mail sobre elas.`
                    : `Adicione regras de notificação para que outras pessoas saibam
                quando esses viajantes reservam viagens.`}
                </p>
              )}
            </div>

            <ApprovalProcessRules
              isUniqueApprovalCreation={isUniqueApprovalCreation}
              isSingleApprover={isSingleApproverExist}
              control={context.control}
              watchFields={watchFields}
            />
          </div>
        </div>
      </div>
      <div css={styles.footerContainer}>
        <ContainedButton
          disabled={context.submitting}
          loading={context.submitting}
          onClick={context.submitForm}
        >
          Salvar alterações
        </ContainedButton>
        <OutlinedButton style={{ width: "11.25rem" }} onClick={handleClose}>
          Cancelar
        </OutlinedButton>
      </div>
    </Form>
  );
}

function AutocompleteTargetOption(props: OptionProps<ApprovalTarget>) {
  const { data, selectOption } = props;

  return (
    <TargetItem
      item={{ email: data.email, label: data.label, fullName: data.name }}
      onClick={() => selectOption(data)}
      endComplement={
        data.alreadyUsed ? (
          <Text color="orange.0">
            <Flex alignItems="center">
              <WarningOutlined color="inherit" />
              <Text color="inherit" marginLeft={1}>
                Alvo já está em uso
              </Text>
            </Flex>
          </Text>
        ) : null
      }
      {...props}
    />
  );
}

function AutocompleteMultiValueLabel(
  props: MultiValueGenericProps<ApprovalTarget>,
) {
  const { data } = props;

  return (
    <components.MultiValueLabel {...props}>
      <p>{data.name}</p>
    </components.MultiValueLabel>
  );
}

interface IApprovalProcessRules {
  control: Control;
  isSingleApprover: boolean;
  isUniqueApprovalCreation: boolean;
  watchFields: {
    rules: ApprovalProcessRulesType[];
    approvalNeeded: boolean;
  };
}

function ApprovalProcessRules({
  control,
  watchFields,
  isSingleApprover,
  isUniqueApprovalCreation,
}: IApprovalProcessRules) {
  const { errors, clearErrors } = useFormContext();

  const { fields, append, remove } = useFieldArray<ApprovalProcessRulesType>({
    name: "rules",
    control: control,
  });

  const isNoRule = fields.length === 0;

  const isCreattionWithUniqueApprovalAndNoRule =
    isUniqueApprovalCreation && isNoRule;

  const selectedOption = isUniqueApprovalCreation
    ? isCreattionWithUniqueApprovalAndNoRule
    : !isSingleApprover;

  const actions = useMemo(() => {
    return {
      appendNewRule: (type: string) => {
        clearErrors("rules");
        append({
          actions: [
            {
              stages: [
                { stage: 1, users: [] },
                { stage: 2, users: [] },
              ],
            },
          ],
          type:
            type === APPROVAL_PROCESS_TYPES.APPROVE
              ? APPROVAL_PROCESS_TYPES_OPTIONS[0]
              : APPROVAL_PROCESS_TYPES_OPTIONS[1],
        });
      },
      removeRule: (index: number) => remove(index),
    };
  }, [append, remove]);

  return (
    <div>
      {fields.map((field, index) => {
        const updatedRule: ApprovalProcessRulesType = watchFields.rules[index];
        return updatedRule.type.value === APPROVAL_PROCESS_TYPES.APPROVE ? (
          <ApprovalProcessApproveRule
            isSingleApprover={isSingleApprover}
            isUniqueApprovalCreation={isUniqueApprovalCreation}
            key={field.id}
            field={field}
            ruleIndex={index}
            control={control}
            errors={errors}
            removeRule={actions.removeRule}
            watchFields={watchFields}
          />
        ) : (
          <ApprovalProcessNotifyRule
            key={field.id}
            field={field}
            ruleIndex={index}
            control={control}
            errors={errors}
            removeRule={actions.removeRule}
            watchFields={watchFields}
          />
        );
      })}

      {watchFields.approvalNeeded && selectedOption ? (
        <AddNewRuleButton
          onClick={() => actions.appendNewRule(APPROVAL_PROCESS_TYPES.APPROVE)}
        >
          Adicionar regra de aprovação
        </AddNewRuleButton>
      ) : null}

      {!watchFields.approvalNeeded && selectedOption ? (
        <AddNewRuleButton
          onClick={() => actions.appendNewRule(APPROVAL_PROCESS_TYPES.NOTIFY)}
        >
          Adicionar regra de notificação
        </AddNewRuleButton>
      ) : null}

      {errors.rules && (
        <FormFieldAdditionalText style={{ marginTop: "-0.5rem" }} hasError>
          {errors.rules.message}
        </FormFieldAdditionalText>
      )}

      {watchFields.approvalNeeded && (
        <div css={styles.warningMessageContainer}>
          <Icon use="info" />
          <p css={styles.warningMessage}>
            Cenários não contemplados não passarão pelo fluxo padrão da empresa.
            Isto é, o viajante poderá realizar a viagem sem necessidade de
            aprovação.
          </p>
        </div>
      )}
    </div>
  );
}

function AddNewRuleButton(props: IContainedButtonProps) {
  const { children, ...rest } = props;

  return (
    <ContainedButton
      style={{ height: "3rem", margin: "0.5rem 0 1rem 0" }}
      {...rest}
    >
      {children}
    </ContainedButton>
  );
}
