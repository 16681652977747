import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { Badge } from "@toolkit/v2";

import { styles } from "./styles";

export const ItinerayJourneyHeaderDeniedOnApprovalBadge: React.FC = () => {
  return (
    <Badge css={styles.root}>
      <Icon css={styles.icon} size={16} use="thumbs-down" />
      Aprovação negada.
    </Badge>
  );
};
