import React from "react";
import { IoMdClose } from "react-icons/io";

import { css } from "@styled-system/css";
import {
  Dialog,
  DialogTransition,
} from "~/apps/shared/components/dialog/dialog";
import { Flex, Text } from "smartrips-toolkit";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { getClientToken } from "@helpers/user.helper";

import { TravelCustomField } from "@dtos/client.dto";

import { Input } from "@shared/inputs";

import { useCustomFieldOptions } from "../custom-field-options.context";

const styles = {
  root: css({
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
  }),
  dialog: css({
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      height: "250px",
    },
  }),
  button: css({
    width: "fit-content",
    height: "3rem",
    backgroundColor: theme.colors.black[500],
    "&:hover": {
      backgroundColor: theme.colors.black[300],
    },
  }),
  innerSecondary: css({
    display: "flex",
    gap: "1.5rem",
    justifyContent: "space-between",
    flexDirection: "column",
  }),
  closeIcon: css({
    cursor: "pointer",
  }),
};

const UpdateBox = () => {
  const clientToken = getClientToken();

  const {
    handleCloseUpdateBox,
    updateBoxIsVisible,
    updateCustomFieldOption,
    customFieldOptionToUpdate,
  } = useCustomFieldOptions();

  const [customFieldOption, setCustomFieldOption] = React.useState({
    client_token: clientToken,
    name: "",
    travel_custom_field: TravelCustomField.TRIP_PURPOSE,
    active: true,
  });

  React.useEffect(() => {
    setCustomFieldOption({
      client_token: clientToken,
      name: customFieldOptionToUpdate.name,
      travel_custom_field: customFieldOptionToUpdate.travel_custom_field,
      active: customFieldOptionToUpdate.active,
    });
  }, [customFieldOptionToUpdate]);

  return (
    <Dialog
      open={updateBoxIsVisible}
      TransitionComponent={DialogTransition}
      onClose={handleCloseUpdateBox}
      css={styles.dialog}
    >
      <Flex css={styles.root}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text fontWeight={"bold"} lineHeight="body">
            Editar
          </Text>
          <IoMdClose
            size={20}
            css={styles.closeIcon}
            onClick={handleCloseUpdateBox}
          />
        </Flex>
        <Flex css={styles.innerSecondary}>
          <Input
            id="aaTagInput"
            name="addTagInput"
            placeholder="Nome da personalização"
            value={customFieldOption.name}
            onChange={(e) =>
              setCustomFieldOption({
                ...customFieldOption,
                name: e.target.value,
              })
            }
            InputProps={{
              style: { borderRadius: "0.5rem", height: "3rem" },
            }}
          />
          <Flex alignSelf={"flex-end"}>
            <Button
              disabled={customFieldOption.name === ""}
              onClick={() =>
                updateCustomFieldOption(
                  customFieldOptionToUpdate.custom_field_option_token,
                  customFieldOption,
                )
              }
              css={styles.button}
            >
              Salvar
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default UpdateBox;
