import React from "react";

import { SidebarHelpButton } from "../../../help-button/help-button";
import { ContentFilters } from "../content-filters/content-filters";
import { styles } from "./styles";

export const ContentSidebar: React.FC = () => {
  return (
    <div css={styles.root}>
      <ContentFilters />
      <SidebarHelpButton />
    </div>
  );
};
