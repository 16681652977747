import React from "react";
import MaterialDialog from "@material-ui/core/Dialog";
import { Box, Paragraph, Flex } from "@toolkit";
import { StContainedButton, StOutlinedButton } from "@shared/buttons";
import * as utils from "@helpers";
import { css } from "@styled-system/css";

const styles = {
  dialogRoot: css({
    [".MuiBackdrop-root"]: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    }
  }),
  button: css({
    py: "small",
    px: "medium"
  })
};

const SuggestionSelection = ({
  suggestionValue,
  handleAcceptSuggestion,
  handleCancel
}) => {
  return (
    <Box p={[24]} textAlign="center">
      <Paragraph fontSize={[1, 2]} lineHeight={["16px", "21px"]}>
        Identificamos o valor de{" "}
        <strong>{utils.toCurrency(suggestionValue)}</strong> no comprovante
        enviado, deseja auto preencher o campo de valor gasto com o mesmo?
      </Paragraph>
      <Box mt={["medium"]}>
        <Flex alignItems="center" justifyContent="center">
          <StContainedButton
            isFullWidth={false}
            onClick={handleAcceptSuggestion}
            css={styles.button}
          >
            Aceitar sugestão
          </StContainedButton>
          <Box width={16} />
          <StOutlinedButton onClick={handleCancel} css={styles.button}>
            Cancelar
          </StOutlinedButton>
        </Flex>
      </Box>
    </Box>
  );
};

const Dialog = ({
  isOpen,
  suggestionValue,
  container,
  handleAcceptSuggestion,
  handleCloseDialog
}) => {
  return (
    <MaterialDialog
      open={isOpen}
      onClose={handleCloseDialog}
      container={container}
      css={styles.dialogRoot}
      style={{ position: "absolute" }}
    >
      <SuggestionSelection
        suggestionValue={suggestionValue}
        handleAcceptSuggestion={handleAcceptSuggestion}
        handleCancel={handleCloseDialog}
      />
    </MaterialDialog>
  );
};

export { Dialog as SuggestionDialog };
