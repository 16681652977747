import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import "./index.css";

export const styles = {
  button: {
    icon: css({}),
    root: ({ active }: { active: boolean }) =>
      css({
        alignItems: "center",
        background: "none",
        border: "none",
        borderBottom: "2px solid",
        borderBottomColor: active ? theme.colors.pink[500] : "transparent",
        color: active ? theme.colors.gray[700] : theme.colors.gray[300],
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.fonts.inter,
        gap: "6px",
        padding: "22px 16px",
        transform: "translateY(1px)",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          borderBottomColor: theme.colors.pink[500],
          color: theme.colors.gray[700],
        },
      }),
  },
  menu: {
    container: css({
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      margin: "0 auto",
      maxWidth: "1074px",
    }),
    links: {
      link: css({
        color: theme.colors.gray[500],
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.pink[500],
        },
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }),
    },
    overlay: ({ open }: { open: boolean }) =>
      css({
        backgroundColor: "rgba(255, 255, 255, 0.25)",
        display: open ? "block" : "none",
        height: "100dvh",
        left: "0",
        pointerEvents: "none",
        position: "fixed",
        top: "0",
        width: "100dvw",
        zIndex: -1,
      }),
    root: ({ open }: { open: boolean }) =>
      css({
        animation: open
          ? "scaleVerticallyDownwards 0.15s ease-in-out forwards"
          : "scaleVerticallyUpwards 0s ease-in-out forwards",
        backgroundColor: theme.colors.white,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
        left: "0",
        padding: "32px 64px 128px 64px",
        position: "absolute",
        right: "0",
        top: "calc(100% + 1px)",
        width: "100%",
        zIndex: 1,
        "& > *": {
          pointerEvents: open ? "auto" : "none",
        },
      }),
    section: {
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 500,
        textDecoration: "none",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          color: theme.colors.pink[500],
        },
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }),
    },
  },
};
