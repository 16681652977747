import React from "react";
import Autosuggest from "@shared/new-trip/Autocomplete";
import { getBusAutosuggestResults } from "../../../apis/bus.api";
import BusIcon from "@material-ui/icons/DirectionsBusRounded";

const BusLocationAutosuggest = props => {
  const { inputProps, ...other } = props;

  return (
    <Autosuggest
      {...other}
      inputProps={inputProps}
      search={getBusAutosuggestResults}
      icon={BusIcon}
    />
  );
};

export { BusLocationAutosuggest };
