import React, { useState } from "react";
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from "slate-react";

import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  LinkOffOutlined,
  LinkOutlined,
} from "@material-ui/icons";
import { createEditor, Descendant } from "slate";

import {
  AddLinkButton,
  BlockButton,
  Element,
  Leaf,
  MarkButton,
  RemoveLinkButton,
  Toolbar,
} from "./rich-text-editor-components/rich-text-editor-components";
import { withInlines } from "./rich-text-editor.helper";
import { styles } from "./styles";

const DEFAULT_VALUE: Descendant[] = [
  {
    children: [{ text: "" }],
    type: "paragraph",
  },
];

type RichTextEditorProps = React.ComponentProps<typeof Editable> &
  Omit<React.ComponentProps<typeof Slate>, "value" | "editor" | "children"> & {
    value?: Descendant[];
  };

const renderElement = (props: RenderElementProps) => <Element {...props} />;
const renderLeaf = (props: RenderLeafProps) => <Leaf {...props} />;

export const RichTextEditor: React.FC<RichTextEditorProps> & {
  Readonly: typeof RichTextEditorReadonly;
} = ({ onChange, value, ...props }) => {
  const [editor] = useState(() => withInlines(withReact(createEditor())));

  return (
    <Slate editor={editor} onChange={onChange} value={value || DEFAULT_VALUE}>
      <div css={styles.root}>
        <Toolbar>
          <MarkButton format="bold" icon={<FormatBold />} title="Negrito" />
          <MarkButton format="italic" icon={<FormatItalic />} title="Italico" />
          <MarkButton
            format="underline"
            title="Sublinhado"
            icon={<FormatUnderlined />}
          />
          <AddLinkButton icon={<LinkOutlined />} title="Inserir link" />
          <RemoveLinkButton icon={<LinkOffOutlined />} title="Remover link" />
          <BlockButton
            format="numbered-list"
            icon={<FormatListNumbered />}
            title="Inserir lista enumerada"
          />
          <BlockButton
            format="bulleted-list"
            icon={<FormatListBulleted />}
            title="Inserir lista marcada"
          />
          <BlockButton
            format="left"
            icon={<FormatAlignLeft />}
            title="Alinhar à esquerda"
          />
          <BlockButton
            format="center"
            icon={<FormatAlignCenter />}
            title="Alinhar ao centro"
          />
          <BlockButton
            format="right"
            icon={<FormatAlignRight />}
            title="Alinhar à direita"
          />
          <BlockButton
            format="justify"
            icon={<FormatAlignJustify />}
            title="Espaçar conteudo"
          />
        </Toolbar>
        <Editable
          autoFocus
          css={styles.editor}
          spellCheck
          {...props}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </div>
    </Slate>
  );
};

type RichTextEditorReadonlyProps = Omit<RichTextEditorProps, "onChange">;

const RichTextEditorReadonly: React.FC<RichTextEditorReadonlyProps> = ({
  value,
  ...props
}) => {
  const [editor] = useState(() => withInlines(withReact(createEditor())));

  // https://github.com/ianstormtaylor/slate/pull/4540
  editor.children = value ? value : DEFAULT_VALUE;

  return (
    <Slate editor={editor} value={value || DEFAULT_VALUE}>
      <Editable
        autoFocus
        spellCheck
        {...props}
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

RichTextEditor.Readonly = RichTextEditorReadonly;
