import React from "react";
import { css } from "emotion";
import {
  ExpensePolicyForm,
  FlightPolicyRule,
  HotelPolicyRule,
  ExpensePolicyTarget,
} from "../ExpensePolicy.types";
import {
  FormikErrors,
  FieldArray,
  FieldArrayRenderProps,
  FormikTouched,
} from "formik";
import { AirportAutosuggest } from "../../../../../models/autosuggest.model";
import { defaultTheme } from "../../../../../assets/styles/theme";
import { Input, CurrencyInput } from "../../../../shared/inputs";
import { FormSection } from "../FormSections";
import { Row } from "../../../../shared/layout/Row";
import { AirportAutosuggest as AirportAutocomplete } from "./inputs/AirportAutosuggest";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { MenuItem } from "@material-ui/core";
import { LocationAutocomplete } from "../../../../shared-logic/LocationAutocomplete/LocationAutocomplete";
import { TargetsAutosuggestion } from "./inputs/TargetsAutosuggestion";
import { TargetRow } from "../../../shared/TargetRow";
import isEmpty from "lodash/isEmpty";
import { TARGET_TYPES } from "../../../../../constants";
import { ContainedButton } from "@components/shared/buttons/contained-button";
import { OutlinedButton } from "@components/shared/buttons/outlined-button";

const styles = {
  body: css({
    padding: "2rem 2rem 4rem 2rem",
  }),
  inputLabel: css({
    paddingBottom: ".5rem",
    color: defaultTheme.textColor,
    opacity: 0.7,
    fontFamily: "inherit",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    fontWeight: "bold",
  }),
  textInput: css({
    width: 350,
    marginRight: "2.5rem",
  }),
  inputOutlineStyle: css({
    paddingLeft: "0 !important",
  }),
  subSectionTitle: css({
    fontSize: 18,
    color: defaultTheme.textColor,
    fontWeight: "bold",
  }),
  fieldLabel: css({
    paddingBottom: ".75rem",
    color: defaultTheme.textColor,
    opacity: 0.7,
    fontWeight: "bold",
    fontFamily: "inherit",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    fontSize: 16,
  }),
  fieldDiv: css({
    paddingBottom: "2rem",
  }),
  clickableSpan: css({
    color: defaultTheme.primaryColor,
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 14,
  }),
  airportInput: css({
    width: 300,
  }),
  airportSelect: css({
    width: 125,
  }),
  cityInput: css({
    width: 292.8,
    marginRight: "2.5rem",
  }),
  nightCostInput: css({
    width: 180.2,
    marginRight: "2.5rem",
  }),
  optionsRow: css({
    padding: "1rem 2rem",
    justifyContent: "flex-end",
    alignItems: "center",
  }),
  errorWarning: css({
    color: defaultTheme.secondaryColor,
    fontSize: 14,
    fontWeight: "bold",
  }),
};

interface Props {
  values: ExpensePolicyForm;
  touched: FormikTouched<ExpensePolicyForm>;
  errors: FormikErrors<ExpensePolicyForm>;
  isSubmitting: boolean;
  isValid: boolean;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  handleCurrencyChange: (fieldName: string) => (value: number) => void;
  handleChangeAirport: (
    fieldName: string,
    segment: "origin" | "destination",
    previousData: FlightPolicyRule,
  ) => (data: AirportAutosuggest) => void;
  handleChangeHotel: (
    fieldName: string,
    previousData: HotelPolicyRule,
  ) => (data: any) => void;
  handleAddFlightRule: (arrayHelpers: any, nationalType: string) => () => void;
  handleAddHotelRule: (arrayHelpers: any, nationalType: string) => () => void;
  handleRuleRemoval: (arrayHelpers: any, index: number) => () => void;
  handleSelectTarget: (target: ExpensePolicyTarget) => void;
  handleRemoveTarget: (targetToken: string) => () => void;
  handleSubmit: () => void;
  handleFormClose: () => void;
}

const PolicyFormPresentational = ({
  values,
  touched,
  errors,
  isSubmitting,
  isValid,
  handleChange,
  handleBlur,
  handleCurrencyChange,
  handleChangeAirport,
  handleChangeHotel,
  handleAddFlightRule,
  handleAddHotelRule,
  handleRuleRemoval,
  handleSelectTarget,
  handleRemoveTarget,
  handleSubmit,
  handleFormClose,
}: Props) => {
  const renderFlightRules = (
    rules: FlightPolicyRule[],
    nationalType: string,
  ) => (arrayHelpers: FieldArrayRenderProps) => {
    return (
      <div>
        {rules.map((rule: FlightPolicyRule, index: number) => {
          const itemName = `${arrayHelpers.name}[${index}]`;

          return (
            <div
              style={{
                marginBottom: "2.5rem",
                display: "flex",
                alignItems: "center",
              }}
              key={index}
            >
              <div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <div style={{ marginRight: "1.5rem" }}>
                    <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                      <label className={styles.inputLabel}>
                        Aeroporto de origem
                      </label>
                    </div>
                    <AirportAutocomplete
                      handleSelect={handleChangeAirport(
                        itemName,
                        "origin",
                        rule,
                      )}
                      inputProps={{
                        id: `${itemName}.originName`,
                        name: `${itemName}.originName`,
                        placeholder: "Ex.: SDU, CGH, CWB, ...",
                        containerClasses: styles.airportInput,
                        onBlur: handleBlur,
                        icon: (
                          <FlightTakeoffIcon
                            style={{
                              fontSize: 20,
                              color: defaultTheme.subTextColor,
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                      <label className={styles.inputLabel}>
                        Aeroporto de destino
                      </label>
                    </div>
                    <AirportAutocomplete
                      handleSelect={handleChangeAirport(
                        itemName,
                        "destination",
                        rule,
                      )}
                      inputProps={{
                        id: `${itemName}.destinationName`,
                        name: `${itemName}.destinationName`,
                        placeholder: "Ex.: SDU, CGH, CWB, ...",
                        containerClasses: styles.airportInput,
                        onBlur: handleBlur,
                        icon: (
                          <FlightLandIcon
                            style={{
                              fontSize: 20,
                              color: defaultTheme.subTextColor,
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <div style={{ marginRight: "1.5rem" }}>
                    <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                      <label className={styles.inputLabel}>Tipo</label>
                    </div>
                    <Input
                      id={`${itemName}.routeType`}
                      name={`${itemName}.routeType`}
                      value={rule.routeType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select={true}
                      containerClasses={styles.airportSelect}
                    >
                      <MenuItem value="roundtrip">Ida e volta</MenuItem>
                      <MenuItem value="oneway">Só ida</MenuItem>
                    </Input>
                  </div>
                  <div>
                    <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                      <label className={styles.inputLabel}>Valor máximo</label>
                    </div>
                    <CurrencyInput
                      id={`${itemName}.maxValue`}
                      name={`${itemName}.maxValue`}
                      placeholder="Ex.: R$ 1000,00"
                      value={rule.maxValue}
                      onChange={handleCurrencyChange(`${itemName}.maxValue`)}
                      onBlur={handleBlur}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "2rem", cursor: "pointer" }}>
                <div onClick={handleRuleRemoval(arrayHelpers, index)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <span
            className={styles.clickableSpan}
            onClick={handleAddFlightRule(arrayHelpers, nationalType)}
          >
            Adicionar rota
          </span>
        </div>
      </div>
    );
  };

  const renderHotelRules = (rules: HotelPolicyRule[], nationalType: string) => (
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    return (
      <div>
        {rules.map((rule: HotelPolicyRule, index: number) => {
          const fieldName = arrayHelpers.name as
            | "nationalHotelRules"
            | "internationalHotelRules";
          const itemName = `${fieldName}[${index}]`;
          const error = errors[fieldName];
          const isTouched = touched[fieldName];

          return (
            <div
              style={{
                marginBottom: "1.5rem",
                display: "flex",
                alignItems: "center",
              }}
              key={index}
            >
              <div>
                <div style={{ display: "flex" }}>
                  <div>
                    <LocationAutocomplete
                      handleSelect={handleChangeHotel(itemName, rule)}
                      handleBlur={handleBlur}
                      inputProps={{
                        id: `${itemName}.locationName`,
                        name: `${itemName}.locationName`,
                        label: "Local (cidade)",
                        containerClasses: styles.cityInput,
                        error: Boolean(
                          error &&
                            error[index] &&
                            error[index]!.locationName &&
                            isTouched &&
                            isTouched[index] &&
                            isTouched[index]!.locationName,
                        ),
                        errorMessage:
                          error &&
                          error[index] &&
                          error[index]!.locationName &&
                          isTouched &&
                          isTouched[index] &&
                          isTouched[index]!.locationName
                            ? error[index]!.locationName
                            : "",
                      }}
                      value={rule.locationName}
                      locationType="(cities)"
                    />
                  </div>
                  <div>
                    <CurrencyInput
                      id={`${itemName}.maxValue`}
                      name={`${itemName}.maxValue`}
                      placeholder="Ex.: R$ 1000,00"
                      value={rule.maxValue}
                      onChange={handleCurrencyChange(`${itemName}.maxValue`)}
                      onBlur={handleBlur}
                      containerClasses={styles.nightCostInput}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                      error={Boolean(
                        error &&
                          error[index] &&
                          error[index]!.maxValue &&
                          isTouched &&
                          isTouched[index] &&
                          isTouched[index]!.maxValue,
                      )}
                      errorMessage={
                        error &&
                        error[index] &&
                        error[index]!.maxValue &&
                        isTouched &&
                        isTouched[index] &&
                        isTouched[index]!.maxValue
                          ? error[index]!.maxValue
                          : ""
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleRuleRemoval(arrayHelpers, index)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <span
            className={styles.clickableSpan}
            onClick={handleAddHotelRule(arrayHelpers, nationalType)}
          >
            Adicionar cidade
          </span>
        </div>
      </div>
    );
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <div className={styles.body}>
        <div style={{ paddingBottom: "1rem" }}>
          <div style={{ opacity: 1, paddingBottom: "1rem" }}>
            <label className={styles.inputLabel}>Nome da política</label>
          </div>
          <Input
            id="description"
            name="description"
            placeholder="Ex.: Básica, Premium ..."
            className={styles.textInput}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            notchedOutlineStyle={styles.inputOutlineStyle}
            error={Boolean(errors.description && touched.description)}
            errorMessage={
              errors.description && touched.description && errors.description
            }
          />
        </div>
        <FormSection title="Regras para reembolso de despesas nacionais">
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Alimentação</span>
            </div>
            <div style={{ padding: "0 0 2rem 1rem" }}>
              <div className={styles.fieldLabel}>
                Valor máximo reembolsável por refeição
              </div>
              <div style={{ paddingLeft: "1rem", paddingTop: ".5rem" }}>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Café da manhã</label>
                  </div>
                  <CurrencyInput
                    id="nationalBreakfastMaxValue"
                    name="nationalBreakfastMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.nationalBreakfastMaxValue}
                    onChange={handleCurrencyChange("nationalBreakfastMaxValue")}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Almoço</label>
                  </div>
                  <CurrencyInput
                    id="nationalLunchMaxValue"
                    name="nationalLunchMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.nationalLunchMaxValue}
                    onChange={handleCurrencyChange("nationalLunchMaxValue")}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Jantar</label>
                  </div>
                  <CurrencyInput
                    id="nationalDinnerMaxValue"
                    name="nationalDinnerMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.nationalDinnerMaxValue}
                    onChange={handleCurrencyChange("nationalDinnerMaxValue")}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Quilometragem</span>
            </div>
            <div style={{ padding: "0 0 2rem 1rem" }}>
              <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                <label className={styles.inputLabel}>
                  Valor pago por Km percorrido
                </label>
              </div>
              <CurrencyInput
                id="nationalMileageRate"
                name="nationalMileageRate"
                placeholder="Ex.: R$ 1000,00"
                className={styles.textInput}
                value={values.nationalMileageRate}
                onChange={handleCurrencyChange("nationalMileageRate")}
                onBlur={handleBlur}
                notchedOutlineStyle={styles.inputOutlineStyle}
              />
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Aéreo</span>
            </div>
            <div style={{ padding: "0 0 2rem 1rem" }}>
              <div>
                <div className={styles.fieldDiv}>
                  <div className={styles.fieldLabel}>Preço máximo por vôo</div>
                  <div>
                    <CurrencyInput
                      id="nationalFlightMaxValue"
                      name="nationalFlightMaxValue"
                      placeholder="Ex.: R$ 1000,00"
                      className={styles.textInput}
                      value={values.nationalFlightMaxValue}
                      onChange={handleCurrencyChange("nationalFlightMaxValue")}
                      onBlur={handleBlur}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                    />
                  </div>
                </div>
              </div>
              {/* Regras de aéreo */}
              {/* <div className={styles.fieldDiv}>
                <div className={styles.fieldLabel}>
                  Política de valor máximo
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <FieldArray
                    name="nationalFlightRules"
                    render={renderFlightRules(
                      values.nationalFlightRules,
                      "national"
                    )}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Hospedagem</span>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <div>
                <div className={styles.fieldDiv}>
                  <div className={styles.fieldLabel}>
                    Preço máximo por hotel
                  </div>
                  <div>
                    <CurrencyInput
                      id="nationalHotelMaxValue"
                      name="nationalHotelMaxValue"
                      placeholder="Ex.: R$ 1000,00"
                      className={styles.textInput}
                      value={values.nationalHotelMaxValue}
                      onChange={handleCurrencyChange("nationalHotelMaxValue")}
                      onBlur={handleBlur}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.fieldDiv}>
                <div className={styles.fieldLabel}>
                  Política de valor máximo
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <div style={{ display: "flex", paddingBottom: ".75rem" }}>
                    <div className={styles.cityInput}>
                      <span className={styles.inputLabel}>Cidade</span>
                    </div>
                    <div className={styles.nightCostInput}>
                      <span className={styles.inputLabel}>Diária máxima</span>
                    </div>
                  </div>
                  <FieldArray
                    name="nationalHotelRules"
                    render={renderHotelRules(
                      values.nationalHotelRules,
                      "national",
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </FormSection>
        <FormSection title="Regras para reembolso de despesas internacionais">
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Alimentação</span>
            </div>
            <div style={{ padding: "0 0 2rem 1rem" }}>
              <div className={styles.fieldLabel}>
                Valor máximo reembolsável por refeição
              </div>
              <div style={{ paddingLeft: "1rem", paddingTop: ".5rem" }}>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Café da manhã</label>
                  </div>
                  <CurrencyInput
                    id="internationalBreakfastMaxValue"
                    name="internationalBreakfastMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.internationalBreakfastMaxValue}
                    onChange={handleCurrencyChange(
                      "internationalBreakfastMaxValue",
                    )}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Almoço</label>
                  </div>
                  <CurrencyInput
                    id="internationalLunchMaxValue"
                    name="internationalLunchMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.internationalLunchMaxValue}
                    onChange={handleCurrencyChange(
                      "internationalLunchMaxValue",
                    )}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
                <div style={{ paddingBottom: "1rem" }}>
                  <div style={{ opacity: 1, paddingBottom: "1rem" }}>
                    <label className={styles.inputLabel}>Jantar</label>
                  </div>
                  <CurrencyInput
                    id="internationalDinnerMaxValue"
                    name="internationalDinnerMaxValue"
                    placeholder="Ex.: R$ 1000,00"
                    className={styles.textInput}
                    value={values.internationalDinnerMaxValue}
                    onChange={handleCurrencyChange(
                      "internationalDinnerMaxValue",
                    )}
                    onBlur={handleBlur}
                    notchedOutlineStyle={styles.inputOutlineStyle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Aéreo</span>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <div>
                <div className={styles.fieldDiv}>
                  <div className={styles.fieldLabel}>Preço máximo por vôo</div>
                  <div>
                    <CurrencyInput
                      id="internationalFlightMaxValue"
                      name="internationalFlightMaxValue"
                      placeholder="Ex.: R$ 1000,00"
                      className={styles.textInput}
                      value={values.internationalFlightMaxValue}
                      onChange={handleCurrencyChange(
                        "internationalFlightMaxValue",
                      )}
                      onBlur={handleBlur}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "1rem " }}>
              <span className={styles.subSectionTitle}>Hospedagem</span>
            </div>
            <div style={{ padding: "0 0 2rem 1rem" }}>
              <div>
                <div className={styles.fieldDiv}>
                  <div className={styles.fieldLabel}>
                    Preço máximo por hotel
                  </div>
                  <div>
                    <CurrencyInput
                      id="internationalHotelMaxValue"
                      name="internationalHotelMaxValue"
                      placeholder="Ex.: R$ 1000,00"
                      className={styles.textInput}
                      value={values.internationalHotelMaxValue}
                      onChange={handleCurrencyChange(
                        "internationalHotelMaxValue",
                      )}
                      onBlur={handleBlur}
                      notchedOutlineStyle={styles.inputOutlineStyle}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.fieldDiv}>
                <div className={styles.fieldLabel}>
                  Política de valor máximo
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <div style={{ display: "flex", paddingBottom: ".75rem" }}>
                    <div className={styles.cityInput}>
                      <span className={styles.inputLabel}>Cidade</span>
                    </div>
                    <div className={styles.nightCostInput}>
                      <span className={styles.inputLabel}>Diária máxima</span>
                    </div>
                  </div>
                  <FieldArray
                    name="internationalHotelRules"
                    render={renderHotelRules(
                      values.internationalHotelRules,
                      "international",
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </FormSection>
        {values.targetType && values.targetType !== TARGET_TYPES.COMPANY ? (
          <FormSection title="Usuários participantes" defaultCollapsed={false}>
            <div style={{ opacity: 1, paddingBottom: "1rem" }}>
              <label className={styles.inputLabel}>Adicionar usuários</label>
            </div>
            <TargetsAutosuggestion handleSelect={handleSelectTarget} />
            <div style={{ marginTop: "1rem" }}>
              {isEmpty(values.targets) ? (
                <span>Nenhum usuário participando desta política</span>
              ) : null}
              {values.targets.map((target, index) => {
                const isLastItem = values.targets.length === index + 1;

                return (
                  <TargetRow
                    key={target.userToken}
                    name={target.fullName}
                    email={target.email}
                    onDelete={handleRemoveTarget(target.userToken)}
                    hasBorder={!isLastItem}
                  />
                );
              })}
            </div>
          </FormSection>
        ) : null}
      </div>
      <footer>
        <Row className={styles.optionsRow}>
          <div>
            {!isEmpty(errors.nationalHotelRules) ||
            !isEmpty(errors.internationalHotelRules) ? (
              <span className={styles.errorWarning}>
                Você possuí regras de hospedagem não preenchidas em seu
                formulário
              </span>
            ) : null}
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <ContainedButton
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              type="submit"
            >
              Salvar
            </ContainedButton>
            <OutlinedButton
              type="button"
              onClick={handleFormClose}
              disabled={isSubmitting}
            >
              Cancelar
            </OutlinedButton>
          </div>
        </Row>
      </footer>
    </form>
  );
};

export { PolicyFormPresentational };
