import React from "react";

import Drawer from "@material-ui/core/Drawer";
import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

import { Box, Flex } from "@toolkit";

import DrawerHeader from "@shared/DrawerHeader";

import { CategoryForm } from "./category-form/category-form";
import { useExpensesCategories } from "./ExpensesCategories.context";

const styles = {
  drawer: {
    root: css({
      [".MuiDrawer-paper"]: {
        width: "100%",
        maxWidth: [500, 815],
      },
    }),
    header: css({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "4.25rem",
      backgroundColor: theme.colors.background.gray,
      paddingLeft: "0.5rem",
      fontSize: "1.125rem",
    }),
  },
};

export const CategoryDrawer = () => {
  const {
    formOpen,
    handleCloseForm,
    selectedCategory,
  } = useExpensesCategories();

  return (
    <Drawer
      anchor="right"
      css={styles.drawer.root}
      onClose={handleCloseForm}
      open={formOpen}
    >
      <Flex flexDirection="column" height="100%">
        <DrawerHeader
          handleClose={handleCloseForm}
          headerStyles={styles.drawer.header}
          title={
            selectedCategory.name
              ? `Editar ${selectedCategory.name}`
              : `Criar nova categoria de despesa`
          }
        />
        <Box flexGrow={1}>
          <CategoryForm />
        </Box>
      </Flex>
    </Drawer>
  );
};
