import {
  parseRealTimeTravels,
  parseFlightsInfo,
  parseHotelInfo
} from "~/parsers/analytics.parser";
import { AxiosResponse } from "axios";

import {
  GetGeneralStaticDataDto,
  GetGeneralStaticDataResponseDto,
  GetBudgetsPerformanceDataRequestDto,
  AnalyticsParamsDto,
  SpentByTravelerItemDto,
  RealTimeTravelDto,
  RealTimeTravelRequestDto,
  GetFlightInfoDto,
  GetHotelInfoDto,
  GetGeneralTransactedDataDto,
  GetGeneralTransactedDataResponseDto
} from "../dtos/analytics.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  GeneralStaticData,
  BudgetsPerformanceData,
  PlannedSpentDto,
  PlannedSpentData,
  ValueDto,
  ValueData,
  Savings,
  Expenses,
  Wastes,
  AlterData,
  CancelFee,
  GeneralTransactedData
} from "../models/analytics.model";
import { api } from "./interceptors";

const MONTH_MAP: { [key: number]: string } = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez"
};

// export function getGeneralExpenseData(
//   dto: GetGeneralExpenseDataDto
// ): Promise<GeneralExpenseData> {
//   return api
//     .request({
//       url: `/booking/clients/${getClientToken()}/analytics/expenses`,
//       method: "POST",
//       headers: getAuthorizationHeader(),
//       data: dto
//     })
//     .then(({ data }: AxiosResponse<GetGeneralExpenseDataResponseDto>) => {
//       return {
//         generalExpenses: data.generalExpenses,
//         types: data.types,
//         chartData: data.chartData.map(item => ({
//           name: MONTH_MAP[item.month] + "/" + item.year.toString().slice(2, 4),
//           apartment: item.apartment ? item.apartment : null,
//           baggage: item.baggage ? item.baggage : null,
//           breakfast: item.breakfast ? item.breakfast : null,
//           bus: item.bus ? item.bus : null,
//           canceling: item.canceling ? item.canceling : null,
//           car: item.car ? item.car : null,
//           custom: item.custom ? item.custom : null,
//           dinner: item.dinner ? item.dinner : null,
//           fee: item.fee ? item.fee : null,
//           "expense-fee": item["expense-fee"] || null,
//           flight: item.flight ? item.flight : null,
//           hotel: item.hotel ? item.hotel : null,
//           "hotel-additional": item["hotel-additional"]
//             ? item["hotel-additional"]
//             : null,
//           insurance: item.insurance ? item.insurance : null,
//           kilometer: item.kilometer ? item.kilometer : null,
//           lunch: item.lunch ? item.lunch : null,
//           other: item.other ? item.other : null,
//           "ride-hailing": item["ride-hailing"] ? item["ride-hailing"] : null,
//           seat: item.seat ? item.seat : null,
//           transport: item.transport ? item.transport : null,
//           total: item.total
//         }))
//       };
//     });
// }

export function getGeneralTransactedData(
  dto: GetGeneralTransactedDataDto
): Promise<GeneralTransactedData> {
  return api
    .request<GetGeneralTransactedDataResponseDto>({
      url: `/booking/clients/${getClientToken()}/analytics/transacted`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => {
      return {
        generalExpenses: data.generalExpenses,
        types: data.types,
        chartData: data.chartData.map(item => ({
          name: MONTH_MAP[item.month] + "/" + item.year.toString().slice(2, 4),
          bus: item.bus ? item.bus : null,
          car: item.car ? item.car : null,
          flight: item.flight ? item.flight : null,
          hotel: item.hotel ? item.hotel : null,
          insurance: item.insurance ? item.insurance : null,
          total: item.total
        }))
      };
    });
}

export function getGeneralStaticData(
  dto: GetGeneralStaticDataDto
): Promise<GeneralStaticData> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/general-info`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<GetGeneralStaticDataResponseDto>) => {
      return {
        travelPolicyAdoption: data.travelPolicyAdoption,
        totalPriceTravels: data.totalPriceTravels,
        totalTravels: data.totalTravels,
        averageAdvance: data.averageAdvance,
        travelInfo: data.travelInfo,
        travelPolicyAdoptions: data.travelPolicyAdoptions.map(item => {
          return {
            name:
              MONTH_MAP[item.month] +
              "/" +
              item.year.toString().slice(2, 4) +
              ":" +
              item.percentage +
              "%",
            month: item.month,
            year: item.year,
            outOfPolicy: item.outOfPolicy,
            inPolicy: item.inPolicy,
            noPolicy: item.noPolicy,
            percentage: item.percentage,
            total: item.total
          };
        }),
        generatedAt: data.generatedAt
      };
    });
}

export async function getBudgetsPerformance(
  dto: GetBudgetsPerformanceDataRequestDto
): Promise<BudgetsPerformanceData> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/budgets`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<BudgetsPerformanceData>) => data);
}

export async function getPlannedSpentRequest(
  dto: AnalyticsParamsDto
): Promise<PlannedSpentData> {
  const mapDtoToData = (item: ValueDto): ValueData => {
    return {
      name: MONTH_MAP[item.month] + "/" + item.year.toString().slice(2, 4),
      month: item.month,
      year: item.year,
      total: item.total
    };
  };

  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/planned-spent`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<PlannedSpentDto>) => {
      return {
        spentValues: data.spentValues.map(mapDtoToData),
        plannedValues: data.plannedValues.map(mapDtoToData)
      };
    });
}

export async function getSavings(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/policy-economy`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<Savings[]>) => {
      return data.map(item => ({
        ...item,
        name: MONTH_MAP[item.months] + "/" + item.years.toString().slice(2, 4)
      }));
    });
}

export async function getWastes(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/policy-potential-economy`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<Wastes[]>) => {
      return data.map(item => ({
        ...item,
        name: MONTH_MAP[item.months] + "/" + item.years.toString().slice(2, 4)
      }));
    });
}

export async function getTotalSpents(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/expense-type-spent`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<Expenses[]>) => {
      return data;
    });
}

export async function getAlterFee(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/alter-fee`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<AlterData[]>) => {
      return data;
    });
}

export async function getAlterDifference(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/alter-difference`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<AlterData[]>) => {
      return data;
    });
}

export async function getCancelFee(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/cancel-fee`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<CancelFee>) => {
      return data;
    });
}

export async function getSpentByTraveler(dto: AnalyticsParamsDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/traveler-spent`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<SpentByTravelerItemDto[]>) =>
      data.map(item => ({
        travelerToken: item.traveler_token,
        traveler: item.traveler,
        totalSpent: item.total_spent,
        minimunSpent: item.minimun_spent,
        averageSpent: item.average_spent
      }))
    );
}

export async function getRealTimeTravelsData(dto: RealTimeTravelRequestDto) {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/ongoing`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }: AxiosResponse<RealTimeTravelDto[]>) =>
      data.map(travel => parseRealTimeTravels(travel))
    );
}

export async function getFlightsAnalyticsInfo(dto: AnalyticsParamsDto) {
  return api
    .request<GetFlightInfoDto>({
      url: `/booking/clients/${getClientToken()}/analytics/flight-info`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => parseFlightsInfo(data));
}

export async function getHotelsAnalyticsInfo(dto: AnalyticsParamsDto) {
  return api
    .request<GetHotelInfoDto>({
      url: `/booking/clients/${getClientToken()}/analytics/hotel-info`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: dto
    })
    .then(({ data }) => parseHotelInfo(data));
}

//Reports
export function downloadConciliationsReport(fileName: string, config: any) {
  const path = "conciliations";

  return basicReportRequest(fileName, config, path);
}

export function downloadCancelationsReport(fileName: string, config: any) {
  const path = "cancelations";

  return basicReportRequest(fileName, config, path);
}

export function downloadFlightsReport(fileName: string, config: any) {
  const path = "flights";

  return basicReportRequest(fileName, config, path);
}

export function downloadFlightsPolicyReport(fileName: string, config: any) {
  const path = "flights-policy";

  return basicReportRequest(fileName, config, path);
}

export function downloadHotelsReport(fileName: string, config: any) {
  const path = "hotels";

  return basicReportRequest(fileName, config, path);
}

export function downloadHotelsPolicyReport(fileName: string, config: any) {
  const path = "hotels-policy";

  return basicReportRequest(fileName, config, path);
}

export function downloadCarsReport(fileName: string, config: any) {
  const path = "cars";

  return basicReportRequest(fileName, config, path);
}

export function downloadCarsPolictReport(fileName: string, config: any) {
  const path = "cars-policy";

  return basicReportRequest(fileName, config, path);
}

export function downloadBusesReport(fileName: string, config: any) {
  const path = "buses";

  return basicReportRequest(fileName, config, path);
}

export function downloadTravelersReport(fileName: string, config: any) {
  const path = "travelers";

  return basicReportRequest(fileName, config, path);
}

export function downloadExpensesReport(fileName: string, config: any) {
  const path = "expenses";

  return basicReportRequest(fileName, config, path);
}

export function downloadPendingExpensesReport(fileName: string, config: any) {
  const path = "pending-expenses";

  return basicReportRequest(fileName, config, path);
}
export function downloadFlightChangesReport(fileName: string, config: any) {
  const path = "flight-changes";

  return basicReportRequest(fileName, config, path);
}

export function downloadApprovalsReport(fileName: string, config: any) {
  const path = "approvals";

  return basicReportRequest(fileName, config, path);
}

export function downloadTravelFees(fileName: string, config: any) {
  const path = "travel-fees";

  return basicReportRequest(fileName, config, path);
}

async function basicReportRequest(
  fileName: string,
  config: any,
  path: string
): Promise<void> {
  return api
    .request({
      url: `/booking/clients/${getClientToken()}/analytics/table/${path}`,
      method: "POST",
      responseType: "blob",
      headers: getAuthorizationHeader(),
      data: config
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
}
