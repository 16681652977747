import React from "react";

import { FormGroup as MuiFormGroup } from "@material-ui/core";

import { CheckboxGroupItem } from "./checkbox-group-item/checkbox-group-item";
import { styles } from "./styles";

export const CheckboxGroup: React.FC<
  React.ComponentPropsWithoutRef<typeof MuiFormGroup>
> & {
  Item: typeof CheckboxGroupItem;
} = ({ children, id, ...props }) => {
  return (
    <MuiFormGroup classes={{ ...styles.classes }} id={id} {...props}>
      {children}
    </MuiFormGroup>
  );
};

CheckboxGroup.Item = CheckboxGroupItem;
