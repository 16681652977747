import React, { useCallback } from "react";

import { ItineraryServicePresenterFactory } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { Badge } from "@toolkit/v2";

import { usePaymentContainer } from "../../payment/payment.container";
import { useItineraryScreen } from "../itinerary-screen.context";
import { useItineraryContainer } from "../itinerary.container";
import { styles } from "./styles";

type Props = {
  isPayment?: boolean;
};

export const ItineraryTravelRepeatedOffersDialog: React.FC<Props> = ({
  isPayment,
}) => {
  const {
    servicesPresenter,
    travelRepeatedOffersModel,
  } = useItineraryContainer();
  const { travelPaymentModel } = usePaymentContainer();

  const {
    closeTravelRepeatedOffersDialog,
    isTravelRepeatedOffersDialogOpen,
  } = useItineraryScreen();

  const getServiceByOfferToken = useCallback(
    (offerToken: string) => {
      if (isPayment) {
        if (!travelPaymentModel) {
          return null;
        }

        const serviceModel = travelPaymentModel.getServiceByOfferToken(
          offerToken,
        );

        if (!serviceModel) {
          return null;
        }

        return ItineraryServicePresenterFactory.create(serviceModel);
      }

      if (!servicesPresenter) {
        return null;
      }

      return servicesPresenter.getServiceByOfferToken(offerToken);
    },
    [isPayment, servicesPresenter, travelPaymentModel],
  );

  if (!travelRepeatedOffersModel) {
    return null;
  }

  return (
    <Dialog
      aria-labelledby="Ofertas repetidas."
      aria-describedby="Uma ou mais ofertas já foram compradas em outra viagem para o mesmo destino e na mesma data em nome deste viajante."
      css={styles.root}
      onClose={closeTravelRepeatedOffersDialog}
      open={isTravelRepeatedOffersDialogOpen}
    >
      <DialogHeader
        onClose={closeTravelRepeatedOffersDialog}
        icon="x-circle-outline"
      />
      <DialogContent>
        <DialogTitle>Ofertas repetidas.</DialogTitle>
        <span css={styles.message}>
          Uma ou mais ofertas já foram compradas em outra viagem para o mesmo
          destino e na mesma data em nome deste viajante.
        </span>
        <div css={styles.content.offers.root}>
          <Badge css={styles.content.offers.warning.root}>
            <Icon
              css={styles.content.offers.warning.icon}
              size={16}
              use="info-outline"
            />
            <span css={styles.content.offers.warning.text}>
              Por favor, retire as ofertas repetidas para prosseguir.
            </span>
          </Badge>
          <div css={styles.content.offers.offers.root}>
            <span css={styles.content.offers.offers.title}>Ofertas:</span>
            <ul css={styles.content.offers.offers.list.root}>
              {travelRepeatedOffersModel
                .getRepeatedOffers()
                .map((repeatedOffer) => {
                  const service = getServiceByOfferToken(
                    repeatedOffer.offerToken,
                  );

                  if (!service) {
                    return null;
                  }

                  return (
                    <li
                      css={styles.content.offers.offers.list.item.root}
                      key={repeatedOffer.offerToken}
                    >
                      <span>{service.getFormattedName()}:</span>{" "}
                      <Tooltip
                        arrow
                        content="Abir o itinerário em uma nova guia."
                        position="top"
                      >
                        <a
                          css={styles.content.offers.offers.list.item.link}
                          href={`/travels/${repeatedOffer.travelToken}/itinerary/travel-plan`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {repeatedOffer.travelToken};
                        </a>
                      </Tooltip>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
