import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DrawerHeader from "@shared/DrawerHeader";
import { Box } from "@toolkit";
import {
  useHotelReviewContext,
  useHotelReviewActionsContext,
  HotelReviewProvider
} from "./HotelReview.context";
import { HotelReviewForm } from "./Form";
import { css } from "@styled-system/css";

const styles = {
  drawer: css({
    [".MuiDrawer-paper"]: {
      width: "100%",
      maxWidth: [500, 600]
    }
  })
};

const HotelReviewDrawer = ({ offerToken, onSuccess, onClose }) => {
  const { isSubmitting } = useHotelReviewContext();
  const { handleFormSubmit } = useHotelReviewActionsContext();

  return (
    <Box>
      <DrawerHeader title="Avaliação de hotel" handleClose={onClose} />
      <Box p={["medium", "large"]}>
        <HotelReviewForm
          isSubmitting={isSubmitting}
          onSubmit={handleFormSubmit(offerToken, onSuccess)}
          onClose={onClose}
        />
      </Box>
    </Box>
  );
};

const Container = ({ isOpen, onClose, ...other }) => {
  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" css={styles.drawer}>
      <HotelReviewProvider>
        <HotelReviewDrawer {...other} onClose={onClose} />
      </HotelReviewProvider>
    </Drawer>
  );
};

export { Container as HotelReviewDrawer };
