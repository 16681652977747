import React from "react";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core/";
import { css } from "@styled-system/css";

import { defaultTheme } from "~/assets/styles/theme";

import * as utils from "@helpers";

const styles = {
  table: css({
    px: "1rem",
    my: "1rem"
  }),
  creditRow: css({
    py: "medium",
    "& .MuiTableCell-root": {
      px: 0,
      fontSize: [0, 1, 1],
      color: defaultTheme.subTextColor,
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        borderBottom: "none",
        pb: "0.2rem"
      }
    },
    "&:last-of-type .MuiTableCell-root": {
      border: "none"
    }
  })
};

const CreditsTable = ({ creditsPerCompany, totalCredits }) => {
  return (
    <Table aria-label="tabela de créditos" css={styles.table}>
      <TableHead>
        <TableRow css={styles.creditRow}>
          <TableCell>Cia aérea</TableCell>
          <TableCell align="right">Créditos (R$)</TableCell>
          <TableCell align="right">Créditos (%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {creditsPerCompany.map((credit, i) => (
          <TableRow key={credit.operational_id} css={styles.creditRow}>
            <TableCell>
              {utils.stringWithCapitalLetters(credit.provider)}
            </TableCell>
            <TableCell align="right">
              {utils.toCurrency(credit.value)}
            </TableCell>
            <TableCell align="right">
              {utils.toPercentage(credit.value, totalCredits)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { CreditsTable };
