import {
  BookingStatus,
  CancelingStatus,
  ServiceType,
  ChangeStatus,
} from "~/apps/shared/constants/enums";

import { BusOffer, BusService } from "./bus.model";
import { CarOffer, CarService } from "./car.model";
import { FlightOffer, FlightService } from "./flight.model";
import { HotelOffer, HotelService } from "./hotel.model";
import { ItineraryServiceModel } from "./itinerary/itinerary-service.model";

export type BoundFamily = {
  baggage?: {
    available: boolean;
    pieces: number;
    weight: number;
  };
  family: string;
  fares: {
    base: string;
    class: string;
    flightNumber: string;
  }[];
};

export type FlightFamilyOffer = {
  fareId: string;
  inbound?: BoundFamily;
  itineraryId: string;
  mainOfferOutOfPolicy: boolean;
  outbound: BoundFamily;
  outOfPolicy: boolean;
  price: number;
  priceDetails: {
    total: number;
    totalFare: number;
    totalTaxes: number;
    totalAdditional: number;
  };
  travelIdentification: string;
};

export type FlightListOffer = FlightOffer & {
  allowedClass: string;
  available?: boolean;
  carriers: Record<string, boolean>;
  diffAirports: boolean;
  durationMinutes: number;
  familyOffers: FlightFamilyOffer[];
  fareId: string;
  optimalPrice: {
    enabled: boolean;
    price: number;
  };
  originalPrice?: number;
  outOfPolicy: boolean;
  recommendedIndex: number;
  reserve: boolean;
  smartripsOutOfPolicy: boolean;
};

export type HotelListOffer = {
  availability?: {
    roomsLeft: number;
  };
  cancelation?: {
    free: boolean;
    freeUntil?: string;
  };
  extraAmenities: string[];
  hotelToken: string;
  isCompanyNegotiated: boolean;
  isNegotiated: boolean;
  nightlyPrice: IPrice;
  outOfPolicy: boolean;
  payment: {
    payDirectInHotel: boolean; //  quando não tem tá vindo falso;
    prePayment: boolean;
    isRefundable: boolean;
    signalDeposit: boolean;
  };
  points: number;
  popularAmenities: IHotelRoomPopularAmenities;
  price: IPrice;
  roomId: string;
  roomName: string;
  roomPictures: string[];
  supplier: string;
  taxes: {
    included: boolean;
    price: IPrice;
  };
};

export type IHotelRoomPopularAmenities = {
  breakfast: boolean;
  refund: boolean;
  wifi: boolean;
};

export type IPrice = {
  currency: string;
  displayValue: string;
  value: number;
};

export type OfferAvailability = {
  availability: boolean;
  currentPrice: number;
  isSamePrice: boolean;
  offerToken: string;
  selectedPrice: number;
};

export class OfferAvailabilityModel {
  constructor(private readonly offerAvailability: OfferAvailability) {}

  public getCurrentPrice() {
    return this.offerAvailability.currentPrice;
  }

  public getOfferAvailability() {
    return this.offerAvailability;
  }

  public getOfferToken() {
    return this.offerAvailability.offerToken;
  }

  public hasChanges(services: ItineraryServiceModel[]) {
    const service = services.find(
      (service) => service.getOfferToken() === this.getOfferToken(),
    );

    if (!service) {
      return false;
    }

    if (this.isUnavailable()) {
      return true;
    }

    if (this.getCurrentPrice() !== service.getPrice()) {
      return true;
    }

    return false;
  }

  public hasPriceChanges(services: ItineraryServiceModel[]) {
    const service = services.find(
      (service) => service.getOfferToken() === this.getOfferToken(),
    );

    if (!service) {
      return false;
    }

    if (this.getCurrentPrice() !== service.getPrice()) {
      return true;
    }

    return false;
  }

  public isAvailable() {
    return this.offerAvailability.availability;
  }

  public isPriceChangePositive(serviceModel: ItineraryServiceModel) {
    return this.getCurrentPrice() > serviceModel.getPrice();
  }

  public isUnavailable() {
    return !this.isAvailable();
  }
}

class OfferAvailabilityModelFactory {
  public static create(offerAvailability: OfferAvailability) {
    return new OfferAvailabilityModel(offerAvailability);
  }
}

// ---

export type OffersAvailabilities = Record<string, OfferAvailability>;

export class OffersAvailabilitiesModel {
  constructor(
    private readonly offersAvailabilities: Record<
      string,
      OfferAvailabilityModel
    >,
  ) {}

  public areAllOffersUnavailable() {
    return (
      this.getCount() > 0 &&
      Object.values(this.offersAvailabilities).every((offerAvailability) =>
        offerAvailability.isUnavailable(),
      )
    );
  }

  public getAllOffersAvailabilities() {
    return this.offersAvailabilities;
  }

  public getCount() {
    return Object.keys(this.getAllOffersAvailabilities()).length;
  }

  public getOfferAvailabilityByOfferToken(offerToken: string) {
    if (!(offerToken in this.offersAvailabilities)) {
      return null;
    }

    return this.offersAvailabilities[offerToken];
  }

  public getUnavailableOfferTokens() {
    return Object.values(this.offersAvailabilities)
      .filter((offerAvailability) => offerAvailability.isUnavailable())
      .map((offerAvailability) => offerAvailability.getOfferToken());
  }

  public hasAvailableOffers() {
    return this.getCount() > 0 && !this.areAllOffersUnavailable();
  }

  public hasUnavailableOffers() {
    return this.getUnavailableOfferTokens().length > 0;
  }

  public isOfferAvailableByOfferToken(offerToken: string) {
    const offerAvailability = this.getOfferAvailabilityByOfferToken(offerToken);

    if (!offerAvailability) {
      return false;
    }

    return offerAvailability.isAvailable();
  }

  public isOfferUnavailableByOfferToken(offerToken: string) {
    const offerAvailability = this.getOfferAvailabilityByOfferToken(offerToken);

    if (!offerAvailability) {
      return false;
    }

    return offerAvailability.isUnavailable();
  }
}

export class OffersAvailabilitiesModelFactory {
  public static create(offersAvailabilities: OffersAvailabilities) {
    return new OffersAvailabilitiesModel(
      Object.entries(offersAvailabilities).reduce((prev, [k, v]) => {
        prev[k] = OfferAvailabilityModelFactory.create(v);

        return prev;
      }, {} as Record<string, OfferAvailabilityModel>),
    );
  }
}

// ---

export type OffersChanges = OffersAvailabilities;

export class OffersChangesModel {
  constructor(
    private readonly offersChanges: Record<string, OfferAvailabilityModel>,
  ) {}

  public areAllOffersWithPriceChanges(services: ItineraryServiceModel[]) {
    return (
      this.getCount() > 0 &&
      Object.values(this.offersChanges).every((offerAvailability) =>
        offerAvailability.hasPriceChanges(services),
      )
    );
  }

  private getCount() {
    return Object.keys(this.offersChanges).length;
  }

  public getOfferChangesByOfferToken(offerToken: string) {
    if (!(offerToken in this.offersChanges)) {
      return null;
    }

    return this.offersChanges[offerToken];
  }

  public getOffersWithPriceChangesTokens(services: ItineraryServiceModel[]) {
    return Object.values(this.offersChanges)
      .filter((offerAvailability) =>
        offerAvailability.hasPriceChanges(services),
      )
      .map((offerAvailability) => offerAvailability.getOfferToken());
  }

  public hasOffersChanges() {
    return this.getCount() > 0 && Object.keys(this.offersChanges).length > 0;
  }

  public hasPriceChanges(services: ItineraryServiceModel[]) {
    return (
      this.getCount() > 0 &&
      Object.values(this.offersChanges).some((offerAvailability) =>
        offerAvailability.hasPriceChanges(services),
      )
    );
  }
}

export class OffersChangesModelFactory {
  public static create(offersChanges: OffersChanges) {
    return new OffersChangesModel(
      Object.entries(offersChanges).reduce((prev, [k, v]) => {
        prev[k] = OfferAvailabilityModelFactory.create(v);

        return prev;
      }, {} as Record<string, OfferAvailabilityModel>),
    );
  }
}

export type OfferService =
  | BusService
  | CarService
  | FlightService
  | HotelService;

export type OfferTraveler = {
  email: string;
  fullName: string;
  main: number;
  travelerOfferToken: string;
  userToken: string;
};

export type RepeatedOffer = {
  isRepeated: boolean;
  offerToken: string;
  travelToken: string;
};

export type RepeatedOffers = RepeatedOffer[];

export class RepeatedOffersModel {
  constructor(private readonly repeatedOffers: RepeatedOffers) {}

  public getRepeatedOffers() {
    return this.repeatedOffers.filter(
      (repeatedOffer) => repeatedOffer.isRepeated,
    );
  }

  public hasRepeatedOffers() {
    return this.getRepeatedOffers().length > 0;
  }
}

export class RepeatedOffersModelFactory {
  public static create(repeatedOffers: RepeatedOffers) {
    return new RepeatedOffersModel(repeatedOffers);
  }
}

export type SelectedOffer<T = ServiceType> = {
  active: boolean;
  availability?: any;
  approvalStatus: number;
  bookedAt: string;
  bookingCode: string;
  bookingFailedReason: string | null;
  bookingStatus: BookingStatus;
  cancelingStatus: CancelingStatus;
  cancelationUntil: string;
  canceledAt: string | null;
  canceledBy: string | null;
  cancelFee: string | null;
  cancelType: string | null;
  changedAt: string | null;
  changedPrice: number | null;
  changeFee?: number;
  changeStatus: ChangeStatus;
  cheapestPrice: number;
  checkinStatus: number | null;
  choiceJustification: string;
  createdAt: string;
  creditCardBrand?: string;
  creditCardDescription?: string;
  creditCardLastDigits?: string;
  denied?: boolean;
  deniedOnApproval: number;
  eMainOutOfPolicy: boolean;
  eOutOfPolicy: boolean;
  finalPrice: number;
  hasNegotiation: boolean;
  id: number;
  inboundCarrier: string | null;
  inboundFareFamily: string | null;
  inboundSeat: string | null;
  inboundTripIds: string | null;
  itemToken: string;
  offer: T extends ServiceType.BUS
    ? BusOffer
    : T extends ServiceType.CAR
    ? CarOffer
    : T extends ServiceType.FLIGHT
    ? FlightOffer
    : T extends ServiceType.HOTEL
    ? HotelOffer
    : never;
  offerToken: string;
  operationalId: string;
  originalPrice: number;
  outboundCarrier: string | null;
  outboundFareFamily: string | null;
  outboundSeat: string | null;
  outboundTripIds: string | null;
  price: number;
  reserveStatus: BookingStatus;
  reservedUntil?: string;
  searchMetadata: any;
  status: number;
  stretchType?: string;
  totalGuests?: number;
  travelToken: string;
  type: T;
  usedCredit?: number;
};
