import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  list: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    }),
    item: {
      left: {
        name: css({
          color: theme.colors.gray[500],
          fontWeight: 600,
          lineHeight: "1.5rem",
        }),
        root: css({
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }),
      },
      right: {
        count: css({
          alignItems: "center",
          display: "flex",
          color: theme.colors.gray[300],
          fontSize: "0.75rem",
          gap: "0.5rem",
          marginRight: "0.5rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "0.5rem",
        }),
      },
      root: css({
        alignItems: "center",
        backgroundColor: theme.colors.background.gray,
        borderRadius: "0.5rem",
        display: "flex",
        gap: "0.5rem",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        cursor: "pointer",
      }),
    },
  },
};
