// import { RadioClassKey, StyledComponentProps } from "@material-ui/core";
// import { TouchRippleClassKey } from "@material-ui/core/ButtonBase/TouchRipple";
// import { css as ecss } from "emotion";

import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

// const getColors = ({
//   variant
// }: {
//   variant: "blue" | "default" | "green" | "pink";
// }) => {
//   switch (variant) {
//     default:
//       return {
//         checked: ecss({
//           ["&.Mui-checked"]: {
//             color: theme.colors.gray[500],
//             transform: "scale(75%)",
//             "&:hover": {
//               backgroundColor: theme.colors.background.gray
//             }
//           }
//         }),
//         root: ecss({
//           padding: "8px 4px 8px 8px",
//           transform: "scale(75%)",
//           "&:hover": {
//             backgroundColor: theme.colors.background.gray
//           }
//         })
//       };
//   }
// };

// export const styles = {
//   classes: ({ variant }: { variant: "blue" | "default" | "green" | "pink" }) =>
//     <StyledComponentProps<RadioClassKey>["classes"]>{
//       ...getColors({ variant })
//     },
//   ripple: <Partial<Record<TouchRippleClassKey, string>>>{
//     root: ecss({
//       display: "none"
//     })
//   }
// };

const getColors = ({
  checked,
  variant,
}: {
  checked: boolean;
  variant: "blue" | "default" | "green" | "pink";
}) => {
  switch (variant) {
    case "pink": {
      return {
        backgroundColor: checked ? theme.colors.pink[500] : theme.colors.white,
        borderColor: checked ? theme.colors.pink[500] : theme.colors.gray[500],
        color: checked ? theme.colors.white : theme.colors.pink[700],
        ":hover": {
          backgroundColor: checked
            ? theme.colors.pink[700]
            : theme.colors.white,
          borderColor: checked
            ? theme.colors.pink[700]
            : theme.colors.gray[700],
        },
      };
    }
    default: {
      return {
        backgroundColor: checked ? theme.colors.gray[500] : theme.colors.white,
        borderColor: checked ? theme.colors.gray[500] : theme.colors.gray[500],
        color: checked ? theme.colors.white : theme.colors.gray[700],
        ":hover": {
          backgroundColor: checked
            ? theme.colors.gray[700]
            : theme.colors.white,
          borderColor: theme.colors.gray[700],
        },
      };
    }
  }
};

export const styles = {
  radio: {
    inner: css({
      backgroundColor: theme.colors.white,
      borderRadius: "50%",
      height: "50%",
      width: "50%",
    }),
    outer: ({
      checked,
      variant,
    }: {
      checked: boolean;
      variant: "blue" | "default" | "green" | "pink";
    }) =>
      css({
        alignItems: "center",
        border: "1px solid",
        borderRadius: "50%",
        display: "flex",
        flexShrink: 0,
        height: "18px",
        justifyContent: "center",
        width: "18px",
        ...getColors({ checked, variant }),
      }),
  },
  icon: ({ checked }: { checked: boolean }) =>
    css({
      opacity: checked ? 1 : 0,
      "& > svg": {
        strokeWidth: "3px",
      },
    }),
  input: css({
    height: "0",
    width: "0",
    opacity: "0",
    zIndex: -1,
  }),
  root: ({ disabled = false }: { disabled?: boolean }) =>
    css({
      cursor: "pointer",
      display: "flex",
      ...(disabled && {
        cursor: "default",
        opacity: 0.5,
      }),
    }),
};
