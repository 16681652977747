import React, { useCallback } from "react";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

export const SidebarHelpButton: React.FC = () => {
  const onClick = useCallback(() => {
    const octachat = window.octadesk?.chat;

    if (!octachat) {
      return;
    }

    window.octadesk.chat.show();
    window.octadesk.chat.showApp();
    window.octadesk.chat.hideButton();
  }, []);

  return (
    <div css={styles.root}>
      <span css={styles.title}>Não encontrou o que queria?</span>
      <span css={styles.text}>
        Não se preocupe! Temos uma equipe de especialistas pronto para te
        atender.
      </span>
      <Button css={styles.button} fill="outlined" onClick={onClick}>
        Falar com um especialista
      </Button>
    </div>
  );
};
