import React from "react";

import { CarSupplierAdditionalsPresentationMap } from "../car-result.helper";
import { CarResultIcon } from "../car-result.icons";

export const localizaAdditionalsCodesMap = {
  ASSENTO_DE_ELEVACAO: "ASSENTO_ELEVACAO",
  BEBE_CONFORTO: "BEBE_CONFORTO",
  CADEIRA_DE_BEBE: "CADEIRA_BEBE",
  COBERTURA_PARA_TERCEIROS: "SEGURO_TERCEIRO",
  COMPENSACAO_DE_CARBONO: "CARBON_FREE",
  CONDUTOR_ADICIONAL_ILIMITADO: "CONDUTOR_ADICIONAL_ILIMITADO",
  CONDUTOR_JOVEM: "",
  LIMPEZA_GARANTIDA: "LAVAGEM_ANTECIPADA",
  PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS: "PROTECAO_VIDRO_PNEUS",
  PROTECAO_DO_CARRO: "PROTECAO_CARRO",
  PROTECAO_TOTAL_AVARIAS: "COPARTICIPACAO_REDUZIDA",
} as const;

export const localizaAdditionalsPresentationMap: CarSupplierAdditionalsPresentationMap<
  typeof localizaAdditionalsCodesMap
> = {
  ASSENTO_DE_ELEVACAO: {
    icon: <CarResultIcon use="elevated-seat" />,
  },
  BEBE_CONFORTO: {
    icon: <CarResultIcon use="car-seat" />,
  },
  CADEIRA_DE_BEBE: {
    icon: <CarResultIcon use="baby-seat" />,
  },
  COBERTURA_PARA_TERCEIROS: {
    icon: <CarResultIcon use="giving-hands" />,
  },
  COMPENSACAO_DE_CARBONO: {
    details: "Compense os gases de efeito estufa emitidos durante sua locação.",
    icon: <CarResultIcon use="sustainability" />,
  },
  CONDUTOR_ADICIONAL_ILIMITADO: {
    details:
      "Vai precisar que outros motoristas conduzam o veículo? Com o Condutor Adicional Ilimitado, outros condutores habilitados e indicados por você em seu contrato, podem aproveitar a experiência com você.",
    icon: <CarResultIcon use="steering-wheel" />,
  },
  CONDUTOR_JOVEM: {
    details: "Se você tem entre 18 e 20 anos adicione para fazer sua reserva.",
    icon: <CarResultIcon use="driver" />,
  },
  LIMPEZA_GARANTIDA: {
    details:
      "Adquira a Limpeza Garantida para ter mais praticidade na sua locação, e não se preocupe em lavar o carro para a devolução. Mas lembre-se, em caso de sujeira excessiva poderá ser necessário realizar uma lavagem especial.",
    icon: <CarResultIcon use="van-cleaning" />,
    rate: "onetime",
  },
  PROTECAO_DE_VIDROS_PREMIUM_E_PNEUS: {
    details:
      "Essa proteção te deixa seguro com relação a danos aos vidros laterais, para-brisas, vidro traseiro, retrovisores, faróis, lanternas e/ou pneus do veículo segurado. Dessa forma, se um desses itens sofrer avarias, você tem isenção e não paga nada. Não há cobertura para roubo ou furto bem como para terceiros.",
    icon: <CarResultIcon use="van" />,
  },
  PROTECAO_DO_CARRO: {
    icon: <CarResultIcon use="shield-check" />,
    shortDetails: "proteção parcial para avarias e roubo do carro",
  },
  PROTECAO_TOTAL_AVARIAS: {
    details:
      "Para você ter a tranquilidade total, vá de Proteção Total Avarias! Com esta proteção adicional a proteção do carro e ao seguro de terceiros quando contratado, você conta com a cobertura do carro, vidros e pneus, e ainda tem 100% de isenção dos custos pré fixados de limite de danos em caso de acidente.",
    icon: <CarResultIcon use="shield-crossed" />,
  },
};
