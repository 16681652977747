import { useQuery } from "react-query";

import * as eventsApi from "~/apps/corporate/apis/events.api";

export const useCompanyEvent = (eventToken?: string, enabled?: boolean) => {
  const {
    data: event,
    error: errorEvent,
    isLoading: isLoadingEvent,
  } = useQuery(
    ["event", eventToken],
    async () => {
      if (!eventToken) {
        return;
      }

      return await eventsApi.getEvent({
        token: eventToken,
      });
    },
    {
      enabled: (enabled === undefined ? true : enabled) && !!eventToken,
    },
  );

  return {
    errorEvent,
    event,
    isLoadingEvent,
  };
};
