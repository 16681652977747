import { useItineraryApproval } from "../../../itinerary/itinerary-approval.context";
import { useItineraryServices } from "../../../itinerary/itinerary-services.context";

export const useApprovalReviewDecisionGuard = () => {
  const {
    isLoading: isLoadingTravelApproval,
    isLoadingApprovalHistory,
    isLoadingApprovalStatus,
  } = useItineraryApproval();
  const {
    isLoading: isLoadingItineraryServices,
    isLoadingTravelPoliciesPerOffer,
  } = useItineraryServices();

  return {
    loading:
      isLoadingApprovalHistory ||
      isLoadingApprovalStatus ||
      isLoadingItineraryServices ||
      isLoadingTravelApproval ||
      isLoadingTravelPoliciesPerOffer,
  };
};
