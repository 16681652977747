import { useEffect } from "react";

import { useLocation } from "~/apps/shared/hooks/use-location";

export const ScrollToTop: React.FC = () => {
  const {
    location: { pathname },
  } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
