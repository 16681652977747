import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px",
    width: "100%",
  }),
  section: {
    list: {
      item: {
        root: css({
          alignItems: "center",
          color: theme.colors.gray[300],
          display: "flex",
          gap: "8px",
        }),
        text: css({
          fontSize: "0.875rem",
          lineHeight: "19.36px",
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    title: css({
      color: theme.colors.gray[700],
      fontWeight: 600,
    }),
  },
};
