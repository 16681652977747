import React from "react";

import { CARD_OWNER_TYPES } from "@constants";

import { useContextFactory } from "~/hooks";

import { Form, useForm } from "~/components/shared/form";

import * as utils from "../../../helpers";
import * as cardCreationService from "./CardCreation.service";
import {
  CardCreationContextProps,
  CardCreationContextType,
  CardCreationFormData
} from "./CardCreation.types";
import cardCreationSchema from "./form/CardCreationForm.schema";

const CardCreationContext = React.createContext<CardCreationContextType>({});

const useCardCreationContext = useContextFactory<CardCreationContextType>(
  "CardCreation",
  CardCreationContext
);

function CardCreationProvider(props: CardCreationContextProps) {
  const { onError, onAfterSave, children } = props;

  async function buildData(data: CardCreationFormData) {
    const {
      data: encryptionData,
      error: encryptionError
    } = await cardCreationService.getEncryptionDataResult();

    if (encryptionError) {
      throw encryptionError;
    }

    const cardDataToEncrypt = {
      holderName: data.holderName,
      number: data.number,
      expirationDate: data.expirationDate,
      cvv: data.cvv.trim(),
      brand: data.issuer!.brand
    };

    const cardHash = utils.encryptData(
      cardDataToEncrypt,
      encryptionData!.searchToken,
      encryptionData!.publicKey
    );

    if (props.defaultOwnerType === CARD_OWNER_TYPES.CLIENT) {
      return {
        cardHash,
        description: data.description,
        billingProfileToken: data.billingProfile
          ? data.billingProfile?.value
          : null,
        encryptionToken: encryptionData!.searchToken,
        serviceTypes: {
          flight: data.enableToFlight,
          hotel: data.enableToHotel,
          car: data.enableToCar,
          bus: data.enableToBus,
          ride: data.enableToRide,
          other: data.enableToOther
        }
      };
    } else {
      return {
        cardHash,
        description: data.description,
        encryptionToken: encryptionData!.searchToken,
        allowApprovers: data.allowApprovers,
        serviceTypes: {
          flight: data.enableToFlight,
          hotel: data.enableToHotel,
          car: data.enableToCar,
          bus: data.enableToBus,
          ride: data.enableToRide,
          other: data.enableToOther
        }
      };
    }
  }

  async function onSubmit(data: CardCreationFormData) {
    const buildedData = await buildData(data);

    const cardType =
      props.defaultOwnerType === CARD_OWNER_TYPES.CLIENT
        ? cardCreationService.CorporateCardType.PUBLIC
        : cardCreationService.CorporateCardType.INDIVIDUAL;

    const {
      data: createdCard,
      error: cardError
    } = await cardCreationService.createCorporateCard(buildedData, cardType);

    if (cardError) {
      throw cardError;
    }

    if (onAfterSave) {
      onAfterSave(createdCard!);
    }
  }

  const context = useForm({
    onSubmit,
    onError,
    schema: cardCreationSchema,
    defaultValues: {
      holderName: "",
      number: "",
      expirationDate: "",
      cvv: "",
      issuer: null,
      description: "",
      enableToOther: false,
      enableToCar: false,
      enableToHotel: false,
      enableToFlight: false,
      enableToRide: false,
      enableToBus: false,
      allowApprovers: false,
      enableToClient: props.defaultOwnerType === CARD_OWNER_TYPES.CLIENT
    }
  });

  return (
    <CardCreationContext.Provider value={context}>
      <Form context={context}>{children}</Form>
    </CardCreationContext.Provider>
  );
}

export { CardCreationProvider, useCardCreationContext };
