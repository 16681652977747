import React from "react";

import InfoIcon from "@material-ui/icons/Info";
import { css } from "@styled-system/css";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { defaultTheme } from "~/assets/styles/theme";

import { formatDateAndHours } from "~/helpers/date.helpers";

const styles = {
  message: css({
    color: defaultTheme.subTextColor,
    fontSize: "0.85rem",
    marginTop: "5px",
  }),
  box: css({ display: "flex" }),
};

interface IProps {
  updatedAt: Date;
}

const LastUpdatedMessage: React.FC<IProps> = ({ updatedAt }) => {
  return (
    <div css={styles.box}>
      <p css={styles.message}>
        {`atualizado ${formatDateAndHours(updatedAt)}`}
      </p>
      <Tooltip
        arrow
        content="Todas as informações exibidas nesta página são atualizadas diariamente. Portanto, compras efetuadas no mesmo dia podem não ser contabilizadas, dependendo do horário da última atualização."
        position="right"
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};

export { LastUpdatedMessage };
