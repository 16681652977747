import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  empty: css({
    color: theme.colors.gray[300],
    fontSize: "0.875rem",
  }),
  label: css({
    color: theme.colors.gray[700],
    fontSize: "0.875rem",
  }),
  list: {
    item: {
      left: {
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
        square: ({ color }: { color: string }) =>
          css({
            backgroundColor: color,
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: "2px",
            color: theme.colors.gray[300],
            display: "grid",
            height: "12px",
            placeItems: "center",
            width: "12px",
          }),
        text: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
      },
      right: {
        button: css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.pink[500],
          cursor: "pointer",
          transition: "color .15s ease-in-out",
          "&:hover": {
            color: theme.colors.pink[700],
          },
        }),
        price: css({
          color: theme.colors.gray[700],
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "8px",
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "24px",
      }),
    },
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      padding: "8px",
    }),
  },
  root: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};
