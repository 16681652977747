import React, { useMemo, useState, useEffect } from "react";

import { CircularProgress, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ErrorIconOutlined from "@material-ui/icons/ErrorOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import { css } from "emotion";
import { Flex, Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import { DATE_FORMATS, formatDate } from "~/helpers/date.helpers";

import { CostCenter } from "~/models/cost-center.model";

import AsyncData, { useAsyncData } from "~/components/shared/async-data";
import {
  StContainedButton,
  StOutlinedButton
} from "~/components/shared/buttons";
import BaseDialog from "~/components/shared/dialogs/BaseDialog";
import IconButton from "~/components/shared/icon-button";
import { Spacing } from "~/components/shared/layout";

type BatchFileUploaderDialogProps = {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<void>;
  onSuccessUpload?: () => Promise<unknown>;
  onDownloadModelClick: () => void;
};

const styles = {
  downloadModelText: css({
    display: "inline-block",
    fontSize: 14,
    color: defaultTheme.primaryColor,
    cursor: "pointer"
  }),
  container: css({
    width: "37.5rem",
    aspectRatio: "4 / 3"
  }),
  box: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100% - 5rem)",
    margin: "0 auto",
    padding: "0 1rem"
  }),
  uploadBox: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: defaultTheme.grays.veryLight,
    cursor: "pointer",
    marginBottom: "1rem"
  }),
  errorBox: css({
    padding: "1rem",
    background: "#ffc7c2"
  }),
  successBox: css({
    padding: "1rem",
    background: "#e5fff4"
  }),
  input: css({
    display: "none"
  }),
  actions: css({
    marginTop: "auto"
  }),
  errorList: css({
    height: "100%",
    overflowY: "auto"
  })
};

export default function BatchFileUploaderDialog(
  props: BatchFileUploaderDialogProps
) {
  const { onSuccessUpload } = props;

  const [showStatus, setShowStatus] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const [status, onUpload, reset] = useAsyncData(props.onUpload);

  const actions = useMemo(() => {
    return {
      openStatus: () => setShowStatus(true),
      closeStatus: () => {
        setShowStatus(false);

        reset();
      },
      onUpload: async () => {
        setShowStatus(true);

        await onUpload(file!);
      }
    };
  }, [reset, onUpload, file]);

  return (
    <BaseDialog title="Upload de excel" className={styles.container} {...props}>
      <section className={styles.box}>
        {!showStatus ? (
          <BatchFileUploaderUploadArea file={file} onFileSelect={setFile} />
        ) : (
          <BatchFileUploaderStatus
            {...status}
            onSuccessUpload={onSuccessUpload}
            onCloseErrors={() => setShowStatus(false)}
          />
        )}
        <BatchFileUploaderActions
          onDownloadModelClick={props.onDownloadModelClick}
          buttons={
            <>
              {showStatus ? (
                <>
                  <StOutlinedButton onClick={actions.closeStatus}>
                    Voltar
                  </StOutlinedButton>
                  <Spacing type="margin" direction="left" space={2} />
                </>
              ) : null}
              <StContainedButton
                isFullWidth={false}
                disabled={!file || status.isLoading || status.isSuccess}
                onClick={actions.onUpload}
              >
                Fazer upload
              </StContainedButton>
            </>
          }
        />
      </section>
    </BaseDialog>
  );
}

type BatchFileUploaderActionsProps = {
  buttons: React.ReactNode;
  onDownloadModelClick: () => void;
};

function BatchFileUploaderActions(props: BatchFileUploaderActionsProps) {
  return (
    <Flex justifyContent="space-between" marginTop="auto">
      <Flex alignItems="center" onClick={props.onDownloadModelClick}>
        <Text className={styles.downloadModelText}>Baixar arquivo modelo</Text>
        <Spacing direction="left" space={1} />
        <GetAppIcon color="primary" fontSize="small" />
      </Flex>
      <Flex>{props.buttons}</Flex>
    </Flex>
  );
}

type BatchFileUploaderUploadAreaProps = {
  file?: File;
  onFileSelect: (file?: File) => void;
};

function BatchFileUploaderUploadArea(props: BatchFileUploaderUploadAreaProps) {
  if (props.file) {
    return <BatchFileUploaderFile {...props} />;
  }

  return (
    <label htmlFor="file" className={styles.uploadBox}>
      <input
        id="file"
        className={styles.input}
        type="file"
        accept=".xlsx"
        onChange={event => {
          if (event.target.files?.length) {
            props.onFileSelect(event.target.files[0]);
          }
        }}
      />
      <Text fontWeight="bold" textAlign="center">
        Clique aqui para escolher o arquivo (.xlsx)
      </Text>
    </label>
  );
}

type BatchFileUploaderFileProps = {
  file?: File;
  onFileSelect: (file?: File) => void;
};

function BatchFileUploaderFile(props: BatchFileUploaderFileProps) {
  return (
    <section className={styles.uploadBox}>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Text as="p" textAlign="center" fontWeight="bold">
              Nome do arquivo
            </Text>
          </Grid>
          <Grid item xs>
            <Text as="p" textAlign="center" fontWeight="bold">
              Última modificação
            </Text>
          </Grid>
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid item xs>
            <Text as="p" textAlign="center">
              {props.file?.name}
            </Text>
          </Grid>
          <Grid item xs>
            <Flex justifyContent="center" alignItems="center">
              <Text as="p">
                {formatDate(props.file!.lastModified, DATE_FORMATS.DATE)}
              </Text>
              <Spacing type="margin" direction="right" space={1} />
              <IconButton
                icon={<CloseIcon />}
                onClick={() => props.onFileSelect(undefined)}
              />
            </Flex>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

type BatchFileUploaderStatusProps = {
  onCloseErrors: () => void;
  onSuccessUpload?: () => Promise<unknown>;
  error?: Array<{ message: string; row: number }>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

function BatchFileUploaderStatus(props: BatchFileUploaderStatusProps) {
  return (
    <AsyncData {...props}>
      <AsyncData.Loading>
        <Flex justifyContent="center" alignItems="center">
          <CircularProgress />
        </Flex>
      </AsyncData.Loading>
      <AsyncData.Error>
        <BatchFileUploaderError
          error={props.error}
          onCloseErrors={props.onCloseErrors}
        />
      </AsyncData.Error>
      <AsyncData.Success>
        {data => (
          <BatchFileUploaderSuccess
            data={data}
            onSuccessUpload={props.onSuccessUpload}
          />
        )}
      </AsyncData.Success>
    </AsyncData>
  );
}

type BatchFileUploaderSuccessProps = {
  data: Array<CostCenter>;
  onSuccessUpload?: () => Promise<unknown>;
};

function BatchFileUploaderSuccess(props: BatchFileUploaderSuccessProps) {
  const { onSuccessUpload } = props;

  useEffect(() => {
    if (onSuccessUpload) {
      void onSuccessUpload();
    }
  }, [onSuccessUpload]);

  return (
    <header className={styles.successBox}>
      <Flex justifyContent="center" alignItems="center">
        <Text color={defaultTheme.successTextColor}>
          <CheckCircleIcon color="inherit" fontSize="large" />
        </Text>
        <Spacing direction="left" space={2} />
        <Text fontWeight="bold" textAlign="center">
          {props.data.length} registros criados com sucesso!
        </Text>
      </Flex>
    </header>
  );
}

function BatchFileUploaderError(
  props: Pick<BatchFileUploaderStatusProps, "error" | "onCloseErrors">
) {
  return (
    <>
      <header className={styles.errorBox}>
        <Flex alignItems="center">
          <ErrorIconOutlined color="error" fontSize="large" />
          <Spacing direction="left" space={2} />
          <Text fontWeight="bold">
            Houve um ou mais erros ao processar o arquivo. Por favor, corrija os
            erros para procedermos com o cadastro.
          </Text>
        </Flex>
      </header>
      <Spacing type="margin" direction="top" space={6} />
      <ul className={styles.errorList}>
        {Array.isArray(props.error) ? (
          props.error.map(error => (
            <li key={error.message}>
              <Spacing type="margin" direction="top" space={2} />
              <Grid container>
                <Grid item xs={2}>
                  <Text color="error" fontWeight="bold">
                    Linha {error.row}
                  </Text>
                  <Spacing direction="right" space={2} />
                </Grid>
                <Grid item>
                  <Text>{error.message}</Text>
                </Grid>
              </Grid>
            </li>
          ))
        ) : (
          <li>
            <Text color="error" fontWeight="bold">
              Erro desconhecido
            </Text>
          </li>
        )}
      </ul>
    </>
  );
}
