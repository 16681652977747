import React from "react";

import { User } from "~/apps/corporate/models/user.model";
import { Icon } from "~/apps/shared/components/icon/icon";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

type Props = {
  onUserDelete: () => void;
  onUserEdit: (userToken: string, guest?: boolean | null) => () => void;
  onUserUnarchive: (user: User) => Promise<void>;
  user: User;
  visibility?: "active" | "inactive";
};

export const UserListItemActions: React.FC<Props> = ({
  onUserDelete,
  onUserEdit,
  onUserUnarchive,
  user,
  visibility,
}) => {
  return (
    <div css={styles.root}>
      {visibility === "active" ? (
        <>
          <Button
            onClick={() => {
              onUserEdit(user.userToken);
            }}
            shape="icon"
          >
            <Icon size={18} use="pencil" />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              onUserDelete();
            }}
            shape="icon"
            variant="pink"
          >
            <Icon size={18} use="trash" />
          </Button>
        </>
      ) : (
        <Button
          onClick={async (e) => {
            e.stopPropagation();

            await onUserUnarchive(user);
          }}
          shape="icon"
          variant="pink"
        >
          <Icon size={18} use="archive-box-arrow-up" />
        </Button>
      )}
    </div>
  );
};

export const UserListItemActionsSkeleton: React.FC = () => {
  return (
    <div css={styles.root}>
      <Button disabled shape="icon">
        <Icon size={18} use="pencil" />
      </Button>
      <Button disabled shape="icon" variant="pink">
        <Icon size={18} use="trash" />
      </Button>
    </div>
  );
};
