import React from "react";

import {
  ArrowForward as ArrowForwardIcon,
  DirectionsBus as BusIcon,
  DirectionsCar as CarIcon,
  Flight as FlightIcon,
  Hotel as HotelIcon
} from "@material-ui/icons/";
import moment from "moment";
import { ServiceType } from "sm-types/common";
import { OfferInfo } from "sm-types/sm-company-events";
import { Flex, Text } from "smartrips-toolkit";

import {
  OFFER_STATUS_COLOR,
  OFFER_STATUS_TRANSLATED
} from "~/constants/offer.constant";

import { Spacing } from "~/components/shared/layout";

import { OfferCardTagsMap } from "../offer-card-tags-map";
import { styles } from "./styles";

type Props = OfferInfo;

export const OfferCardMap: React.VFC<Props> = ({
  offer,
  offer_status,
  tag_status,
  type
}) => {
  if (type === ServiceType.BUS) {
    return (
      <div css={styles.root}>
        <BusIcon css={styles.icon} fontSize="small" />
        <Flex flexDirection="column">
          <div css={styles.heading}>
            <Text>{offer.returnTrip ? "Ida e volta" : "Só ida"} de </Text>
            <Text fontWeight="bold">{offer.departureTrip.from}</Text>
            <ArrowForwardIcon
              css={{
                backgroundColor: "white"
              }}
              fontSize="small"
            />
            <Text fontWeight="bold">{offer.departureTrip.to}</Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <div css={styles.description}>
            <Text
              css={{
                textTransform: "uppercase"
              }}
              color={OFFER_STATUS_COLOR[offer_status]}
              fontSize={12}
              fontWeight="bold"
            >
              {OFFER_STATUS_TRANSLATED[offer_status]}
            </Text>
            <Text fontSize={11}>
              {moment(offer.departureTrip.departure).format("DD MMM YYYY")}
              {offer.returnTrip &&
                ` - ${moment(offer.returnTrip.departure).format(
                  "DD MMM YYYY"
                )}`}
            </Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <OfferCardTagsMap tags={tag_status} />
        </Flex>
      </div>
    );
  }

  if (type === ServiceType.CAR) {
    const rentDays = Number(
      moment(offer.dropoff.date)
        .subtract(offer.pickup.date)
        .subtract(1, "day")
        .format("D")
    );

    return (
      <div css={styles.root}>
        <CarIcon css={styles.icon} fontSize="small" />
        <Flex flexDirection="column">
          <div css={styles.heading}>
            <Text>
              {rentDays} {Number(rentDays) > 1 ? "dias" : "dia"} de aluguel com
            </Text>{" "}
            <Text fontWeight="bold">{offer.carDetails.modelDescription}</Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <div css={styles.description}>
            <Text
              css={{
                textTransform: "uppercase"
              }}
              color={OFFER_STATUS_COLOR[offer_status]}
              fontSize={12}
              fontWeight="bold"
            >
              {OFFER_STATUS_TRANSLATED[offer_status]}
            </Text>
            <Text fontSize={11}>
              {moment(offer.pickup.date).format("DD MMM YYYY")} -{" "}
              {moment(offer.dropoff.date).format("DD MMM YYYY")}
            </Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <OfferCardTagsMap tags={tag_status} />
        </Flex>
      </div>
    );
  }

  if (type === ServiceType.FLIGHT) {
    return (
      <div css={styles.root}>
        <FlightIcon css={styles.icon} fontSize="small" />
        <Flex flexDirection="column">
          <div css={styles.heading}>
            <Text>{offer.inbound ? "Ida e volta" : "Só ida"} de </Text>
            <Text fontWeight="bold">{offer.outbound.origin.code}</Text>
            <Text>{offer.outbound.origin.name}</Text>
            <ArrowForwardIcon
              css={{
                backgroundColor: "white"
              }}
              fontSize="small"
            />
            <Text fontWeight="bold">{offer.outbound.destination.code}</Text>
            <Text>{offer.outbound.destination.name}</Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <div css={styles.description}>
            <Text
              css={{
                textTransform: "uppercase"
              }}
              color={OFFER_STATUS_COLOR[offer_status]}
              fontSize={12}
              fontWeight="bold"
            >
              {OFFER_STATUS_TRANSLATED[offer_status]}
            </Text>
            <Text fontSize={11}>
              {moment(offer.outbound.departureTextDate).format("DD MMM YYYY")}
              {offer.inbound &&
                ` - ${moment(offer.inbound.departureTextDate).format(
                  "DD MMM YYYY"
                )}`}
            </Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <OfferCardTagsMap tags={tag_status} />
        </Flex>
      </div>
    );
  }

  if (type === ServiceType.HOTEL) {
    const nights = Number(
      moment(offer.checkout).subtract(offer.checkin).format("D")
    );

    return (
      <div css={styles.root}>
        <HotelIcon css={styles.icon} fontSize="small" />
        <Flex flexDirection="column">
          <div css={styles.heading}>
            <Text>
              {nights} {nights > 1 ? "noites" : "noite"} em
            </Text>
            <Text fontWeight="bold">{offer.name}</Text>
            <Text>em</Text>
            <Text fontWeight="bold">{offer.city}</Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <div css={styles.description}>
            <Text
              css={{
                textTransform: "uppercase"
              }}
              color={OFFER_STATUS_COLOR[offer_status]}
              fontSize={12}
              fontWeight="bold"
            >
              {OFFER_STATUS_TRANSLATED[offer_status]}
            </Text>
            <Text fontSize={11}>
              {moment(offer.checkin).add(1, "day").format("DD MMM YYYY")} -{" "}
              {moment(offer.checkout).add(1, "day").format("DD MMM YYYY")}
            </Text>
          </div>
          <Spacing direction="vertical" space="4px" />
          <OfferCardTagsMap tags={tag_status} />
        </Flex>
      </div>
    );
  }

  return null;
};
