import React from "react";

import { Avatar as MaterialUIAvatar } from "@material-ui/core";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (name: string) => {
  return {
    children: `${name[0]}${name[1]}`.toUpperCase()
  };
};

type Props =
  | React.ComponentPropsWithoutRef<typeof MaterialUIAvatar>
  | {
      name: string;
    };

export const Avatar: React.VFC<Props> = props => {
  if ("name" in props) {
    const { name, ...rest } = props;

    return (
      <MaterialUIAvatar
        css={{
          backgroundColor: stringToColor(name),
          fontSize: ".75rem",
          fontWeight: "bold"
        }}
        {...stringAvatar(name)}
        {...rest}
      />
    );
  }

  return <Avatar {...props} />;
};
