import { CountryRestrictionType } from "~/apps/shared/constants/enums";
import { requiredSelectOption } from "~/apps/shared/schemas/common-fields.schema";
import { Node } from "slate";
import * as yup from "yup";

const countryRestrictionSchema = yup.object().shape({
  country: requiredSelectOption("Selecione um país."),
  countryRestrictionToken: yup.string(),
  message: yup
    .mixed()
    .required("Digite uma mensagem de aviso.")
    .test("", "Digite uma mensagem de aviso.", (message) => {
      if (message === undefined) {
        return true;
      }

      if (!Array.isArray(message)) {
        return false;
      }

      // https://docs.slatejs.org/concepts/10-serializing
      const deserializedContent = message.map((n) => Node.string(n)).join("\n");

      return !!deserializedContent;
    }),
  restrictionType: yup
    .string()
    .oneOf(Object.values(CountryRestrictionType))
    .required("Selecione um tipo de restrição."),
});

export default countryRestrictionSchema;
