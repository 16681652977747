import React from "react";

import { useTags } from "~/apps/corporate/pages/configurations/views/company/tags/tags.context";
import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  travelToken: string;
};

export const TravelTagsRemovableBadges: React.FC<Props> = ({ travelToken }) => {
  const { removeTravelTag, travelsTags } = useTags();

  if (!travelsTags) {
    return null;
  }

  if (!(travelToken in travelsTags)) {
    return null;
  }

  return travelsTags[travelToken]!.length > 0 ? (
    <div css={styles.root}>
      {travelsTags[travelToken]!.map((tag) => (
        <div css={styles.tag.root} key={tag.tagToken}>
          <span>{tag.tagName}</span>
          <button
            css={styles.tag.clear}
            onClick={() => removeTravelTag(tag.travelTagToken)}
            type="button"
          >
            <Icon size={16} use="x" />
          </button>
        </div>
      ))}
    </div>
  ) : null;
};
