import React from "react";

import { Collapse as MuiCollapse } from "@material-ui/core";

type Props = {
  collapsedHeight?: number;
  expanded: boolean;
};

export const Collapse: React.FC<Props> = ({
  children,
  collapsedHeight,
  expanded,
}) => {
  return (
    <MuiCollapse collapsedHeight={collapsedHeight} in={expanded}>
      <>{children}</>
    </MuiCollapse>
  );
};
