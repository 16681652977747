import React from "react";

import { FlightItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { Icon } from "~/apps/shared/components/icon/icon";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { useItineraryScreen } from "../../../../pages/travels/itinerary/itinerary-screen.context";
import { ItineraryFlightSegment } from "../../../itinerary-flight-segment/itinerary-flight-segment";
import { ItineraryServiceCancelationInfoBadge } from "../../../itinerary-service-cancelation-info-badge/itinerary-service-cancelation-info-badge";
import { ItineraryServiceCancelationUntilBadge } from "../../../itinerary-service-cancelation-until-badge/itinerary-service-cancelation-until-badge";
import { ItineraryServiceCanceledByBadge } from "../../../itinerary-service-canceled-by-badge/itinerary-service-canceled-by-badge";
import { ItineraryServiceReservationCodeBadge } from "../../../itinerary-service-reservation-code-badge/itinerary-service-reservation-code-badge";
import { ItineraryServiceReservationInfoBadge } from "../../../itinerary-service-reservation-info-badge/itinerary-service-reservation-info-badge";
import { styles } from "./styles";

type Props = {
  isDisabled: boolean;
  servicePresenter: FlightItineraryServicePresenter;
};

export const ItineraryFlightJourney: React.FC<Props> = ({
  isDisabled,
  servicePresenter,
}) => {
  const { openServiceDetailsDrawer } = useItineraryScreen();

  const bookingCode = servicePresenter.getBookingCode();
  const cancelInfo = servicePresenter.getCancelationInfo();
  const changeFee = servicePresenter.getChangeFee();
  const formattedFreeCancelationUntil = servicePresenter.getFormattedFreeCancelationUntil();
  const operationalId = servicePresenter.getOperationalId();

  const reservationReservedUntil = servicePresenter.getReservationReservedUntil();
  const reservationStatus = servicePresenter.getReservationStatus();

  return (
    <div
      css={styles.root({
        isDisabled,
      })}
    >
      {servicePresenter.showReservation() && operationalId ? (
        <ItineraryServiceReservationCodeBadge
          additionalCode={bookingCode}
          additionalLabel="Bilhete"
          reservationCode={operationalId}
          reservationLabel="Localizador"
        />
      ) : null}
      <div
        css={styles.card.root({
          isDisabled,
        })}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          openServiceDetailsDrawer(servicePresenter.getService());
        }}
      >
        {servicePresenter.getPresentationJourneys().map((journey, index) => {
          const firstSegment = journey.segments[0];
          const lastSegment = journey.segments[journey.segments.length - 1];

          const arrival = lastSegment.arrival;
          const baggage = firstSegment.baggage;
          const carrier = firstSegment.carrier;
          const departure = firstSegment.departure;
          const duration = journey.duration;

          const hasStops = journey.segments.length > 1;

          return (
            <React.Fragment key={journey.index}>
              {index !== 0 ? <hr css={styles.card.divisor} /> : null}
              <ItineraryFlightSegment
                arrival={arrival}
                baggage={baggage}
                carrier={carrier}
                daysDiff={servicePresenter.getJourneyDaysDiff({
                  arrival,
                  departure,
                })}
                departure={departure}
                duration={duration}
                stops={
                  hasStops
                    ? journey.segments
                        .filter((segment) => !!segment.connection)
                        .map((segment) => ({
                          code: segment.arrival.airportCode,
                          durationHours: segment.duration,
                          name: segment.arrival.airportName,
                        }))
                    : undefined
                }
              />
            </React.Fragment>
          );
        })}
      </div>
      {servicePresenter.isBookingUnitiated() &&
      !servicePresenter.isReservationUnitiated() ? (
        <ItineraryServiceReservationInfoBadge
          reservationReservedUntil={reservationReservedUntil}
          reservationStatus={reservationStatus}
        />
      ) : null}
      {servicePresenter.isCanceled() ? (
        <ItineraryServiceCancelationInfoBadge
          cancelFee={
            changeFee
              ? changeFee.total
              : cancelInfo.fee
              ? cancelInfo.fee.total
              : null
          }
          cancelType={cancelInfo.type}
          isChanged={servicePresenter.isChanged()}
        />
      ) : null}
      {cancelInfo.showCanceledByMessage ? (
        <ItineraryServiceCanceledByBadge
          canceledAt={cancelInfo.canceledAt}
          canceledByUserName={cancelInfo.canceledByUserName}
          isCanceled={cancelInfo.isCanceled}
        />
      ) : null}
      {!servicePresenter.isCanceled() && servicePresenter.hasUsedCredits() ? (
        <div css={styles.credit.root}>
          <Icon css={styles.credit.icon} size={16} use="dollar" />
          <span css={styles.credit.text}>
            Crédito utilizado:{" "}
            {`${toCurrency(servicePresenter.getUsedCredit())}`}.
          </span>
        </div>
      ) : null}
      {formattedFreeCancelationUntil ? (
        <ItineraryServiceCancelationUntilBadge
          freeCancelationUntil={formattedFreeCancelationUntil}
          isCancelable={servicePresenter.isCancelable()}
          message="(horário local da companhia)"
          serviceStatus={servicePresenter.getStatus()}
        />
      ) : null}
    </div>
  );
};
