import React from "react";

import { RouteComponentProps } from "@reach/router";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";

import { ReportDownloadProvider } from "./analytics-report-download.context";
import { ReportDownloadActions } from "./report-download-actions/report-download-actions";
import { ReportDownloadList } from "./report-download-list/report-download-list";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Component: React.FC<Props> = () => {
  return (
    <>
      <PageTitle title="Analytics - Download de Relatórios" />
      <div css={styles.root}>
        <div css={styles.top.root}>
          <span css={styles.top.title}>Download de relatórios</span>
          <hr css={styles.divisor} />
        </div>
        <ReportDownloadActions />
        <ReportDownloadList />
      </div>
    </>
  );
};

export const AnalyticsReportDownload: React.FC<Props> = ({ ...props }) => {
  return (
    <ReportDownloadProvider>
      <Component {...props} />
    </ReportDownloadProvider>
  );
};
