import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  count: css({
    color: theme.colors.gray[700],
    fontWeight: 600,
  }),
  list: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
};
