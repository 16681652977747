import React, { CSSProperties } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "@styled-system/css";

import { defaultTheme } from "@assets/styles/theme";

import { Column } from "@components/shared/layout/Column";

const styles = {
  root: css({
    height: 250,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  }),
  circularProgress: css({
    color: defaultTheme.primaryColor,
  }),
};

const LoadingSpinner = ({
  rootStyle = {},
  circularStyle = {},
  circularThickness,
  circularSize,
}: {
  rootStyle?: CSSProperties;
  circularStyle?: CSSProperties;
  circularThickness?: number;
  circularSize?: number;
}) => (
  <Column css={styles.root} style={rootStyle}>
    <CircularProgress
      size={circularSize ? circularSize : 80}
      thickness={circularThickness ? circularThickness : 1.5}
      css={styles.circularProgress}
      style={circularStyle}
    />
  </Column>
);

export { LoadingSpinner };
