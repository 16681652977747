import React, { useCallback, useEffect } from "react";

import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import { useItineraryScreen } from "../../../itinerary-screen.context";
import { styles } from "./styles";
import { useTravelPlanServiceDeletionAlertDialogPresenter } from "./travel-plan-service-deletion-alert-dialog.hooks";

export const TravelPlanServiceDeletionAlertDialog: React.FC = () => {
  const {
    servicePresenter,
  } = useTravelPlanServiceDeletionAlertDialogPresenter();

  const {
    closeServiceDeletionAlertDialog,
    errorOnFetchDeleteService,
    deleteService,
    isLoadingDeleteService,
    isServiceDeletionAlertDialogOpen,
  } = useItineraryScreen();

  const handleClose = useCallback(() => {
    closeServiceDeletionAlertDialog();
  }, [closeServiceDeletionAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!servicePresenter) {
      closeServiceDeletionAlertDialog();

      return;
    }

    await deleteService(servicePresenter.getOfferToken());
  }, [closeServiceDeletionAlertDialog, deleteService, servicePresenter]);

  useEffect(() => {
    if (!errorOnFetchDeleteService) {
      return;
    }

    handleClose();
  }, [errorOnFetchDeleteService, handleClose]);

  return (
    <AlertDialog
      confirmLabel="Excluir"
      isLoading={isLoadingDeleteService}
      message={
        <TravelPlanServiceDeletionAlertDialogMessage
          servicePresenter={servicePresenter}
        />
      }
      onClose={() => {
        handleClose();
      }}
      onConfirm={async () => {
        await handleConfirm();
      }}
      open={isServiceDeletionAlertDialogOpen}
      title="Excluir oferta."
    />
  );
};

type TravelPlanServiceDeletionAlertDialogMessageProps = {
  servicePresenter: ItineraryServicePresenter | null;
};

const TravelPlanServiceDeletionAlertDialogMessage: React.FC<TravelPlanServiceDeletionAlertDialogMessageProps> = ({
  servicePresenter,
}) => {
  if (!servicePresenter) {
    return <>Deseja realmente excluir a oferta?</>;
  }

  if (servicePresenter.isBusService() && !servicePresenter.isOneWay()) {
    const differentJourneys = servicePresenter
      .getAllJourneys()
      .filter((journey) => {
        if (!servicePresenter.presentationJourneys) {
          return false;
        }

        if (
          servicePresenter.presentationJourneys.find(
            (presentationJourney) =>
              presentationJourney.index === journey.index,
          )
        ) {
          return false;
        }

        return true;
      });

    return (
      <div css={styles.message.root}>
        <span>
          Deseja realmente excluir{" "}
          <strong css={styles.strong}>
            {servicePresenter.getFormattedName()}?
          </strong>
        </span>
        {differentJourneys.length > 0 ? (
          <div css={styles.message.different.root}>
            <span>
              Ao excluir este serviço, as seguintes jornadas complementares
              também serão excluídas de sua viagem:
            </span>
            <ul css={styles.message.different.list}>
              {differentJourneys.map((journey) => (
                <li key={journey.index}>
                  <strong css={styles.message.different.strong}>
                    {servicePresenter.getFormattedJourneyName(journey)};
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }

  if (servicePresenter.isFlightService() && !servicePresenter.isOneWay()) {
    const differentJourneys = servicePresenter
      .getAllJourneys()
      .filter((journey) => {
        if (!servicePresenter.presentationJourneys) {
          return false;
        }

        if (
          servicePresenter.presentationJourneys.find(
            (presentationJourney) =>
              presentationJourney.index === journey.index,
          )
        ) {
          return false;
        }

        return true;
      });

    return (
      <div css={styles.message.root}>
        <span>
          Deseja realmente excluir{" "}
          <strong css={styles.strong}>
            {servicePresenter.getFormattedName()}?
          </strong>
        </span>
        {differentJourneys.length > 0 ? (
          <div css={styles.message.different.root}>
            <span>
              Ao excluir este serviço, as seguintes jornadas complementares
              também serão excluídas de sua viagem:
            </span>
            <ul css={styles.message.different.list}>
              {differentJourneys.map((journey) => (
                <li key={journey.index}>
                  <strong css={styles.message.different.strong}>
                    {servicePresenter.getFormattedJourneyName(journey)};
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <span>
        Deseja realmente excluir{" "}
        <strong css={styles.strong}>
          {servicePresenter.getFormattedName()}
        </strong>
        ?
      </span>
    </>
  );
};
