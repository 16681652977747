import React, { ReactNode } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { css } from "emotion";
import { defaultTheme } from "../../../assets/styles/theme";

const styles = {
  dialogPaper: css({
    ["@media (max-width: 767px)"]: {
      margin: 15
    }
  }),
  dialogTitle: css({
    ["@media (max-width: 767px)"]: {
      padding: "1rem"
    }
  }),
  dialogContent: css({
    ["@media (max-width: 767px)"]: {
      padding: "0 1rem 1rem"
    }
  }),
  title: css({
    fontSize: 20,
    fontFamily: "'Open sans', sans-serif",
    lineHeight: "1.5",
    letterSpacing: "0.04rem",
    color: defaultTheme.textColor,
    ["@media (max-width: 767px)"]: {
      fontSize: 16,
      lineHeight: "21px",
      maxWidth: "90%"
    }
  }),
  icon: css({
    color: defaultTheme.textColor
  }),
  closeIcon: css({
    position: "absolute",
    top: 0,
    right: 0
  })
};

interface Props {
  open: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
}

const ExpensePolicyBaseDialog = ({ open, title, children, onClose }: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    classes={{ paper: styles.dialogPaper }}
  >
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={onClose}
        className={styles.closeIcon}
        aria-label="Fechar"
      >
        <CloseIcon className={styles.icon} />
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
        <span className={styles.title}>{title}</span>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>{children}</DialogContent>
    </div>
  </Dialog>
);

export { ExpensePolicyBaseDialog };
