import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  right: {
    bottom: {
      link: ({ disabled }: { disabled: boolean }) =>
        css({
          alignItems: "center",
          backgroundColor: theme.colors.pink[500],
          border: "1px solid",
          borderColor: theme.colors.pink[500],
          borderRadius: "8px",
          color: theme.colors.white,
          display: "flex",
          fontWeight: 500,
          height: "46px",
          justifyContent: "center",
          opacity: !disabled ? 1 : 0.75,
          padding: "14px 44.5px",
          textDecoration: "none",
          transition: "all 0.15s ease-in-out",
          width: "100%",
          ":hover": {
            backgroundColor: theme.colors.pink[700],
            borderColor: theme.colors.pink[700],
          },
          "@media (max-width: 425px)": {
            textAlign: "center",
            width: "100%",
          },
        }),
      price: {
        label: css({
          color: theme.colors.gray[300],
          fontSize: "0.875rem",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          "@media (max-width: 640px)": {
            alignItems: "flex-start",
          },
        }),
        value: css({
          color: theme.colors.gray[700],
          fontSize: "1.5rem",
          fontWeight: 700,
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }),
    },
    root: css({
      alignItems: "center",
      borderLeft: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      gap: "24px",
      justifyContent: "space-between",
      minWidth: "192px",
      paddingLeft: "16px",
      "@media (max-width: 640px)": {
        alignItems: "flex-start",
        borderLeft: "unset",
        borderTop: `1px solid ${theme.colors.gray[100]}`,
        paddingLeft: "unset",
        padding: "24px 8px 8px 8px",
      },
    }),
    top: {
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        "@media (max-width: 640px)": {
          alignItems: "flex-start",
        },
      }),
    },
  },
  root: css({
    backgroundColor: theme.colors.background.white,
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    gap: "0 24px",
    padding: "16px",
    marginBottom: "16px",
    overflow: "hidden",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  }),
};
