import React, { useState } from "react";

import { Link, RouteComponentProps } from "@reach/router";
import { AuthLayout } from "~/apps/corporate/components/auth-layout/auth-layout";
import { PageTitle } from "~/apps/corporate/components/page-title/page-title";
import { pageTitles } from "~/apps/corporate/constants/page-titles";
import * as passwordApi from "~/apps/shared/apis/password.api";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Logo } from "~/apps/shared/components/logo/logo";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { Button } from "@toolkit/v2";

import {
  passwordResetFormSchema,
  PasswordResetFormSchema,
} from "./password-reset-form.schema";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const PasswordReset: React.FC<Props> = () => {
  const [resetError, setResetError] = useState<Error | null>(null);
  const [resetMessage, setResetMessage] = useState<string | null>(null);

  const form = useForm<PasswordResetFormSchema>({
    defaultValues: {
      email: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      await passwordApi
        .resetPassword(values.email)
        .then((response) => {
          if (!response || !response.success) {
            setResetError(ERROR.UNEXPECTED);

            return;
          }

          setResetMessage(response.message);
        })
        .catch((error) => {
          setResetError(error);
        });
    },
    schema: passwordResetFormSchema,
  });

  return (
    <>
      <PageTitle title={pageTitles.FORGOT_PASSWORD} />
      <AuthLayout>
        <Logo showName />
        <Form context={form} css={styles.form.root}>
          {!form.submitting && resetError ? (
            <div css={styles.form.error}>
              <Icon use="x-circle-outline" />
              <span>{resetError.description}</span>
            </div>
          ) : null}
          {!form.submitting && resetMessage ? (
            <div css={styles.form.message}>
              <Icon use="info-outline" />
              <span>{resetMessage}</span>
            </div>
          ) : null}
          <div css={styles.form.field.root}>
            <Form.Field
              as={
                <Input
                  aria-label="Email"
                  id="email"
                  placeholder="Email"
                  type="email"
                />
              }
              css={styles.form.field.input}
              onChange={() => {
                void form.trigger("email");
              }}
              name="email"
            />
            <InputErrorMessage css={styles.form.field.error}>
              {form.errors["email"]?.message}
            </InputErrorMessage>
          </div>
          <Button
            css={styles.form.button}
            disabled={!form.formState.isValid || form.submitting}
            type="submit"
          >
            {!form.submitting ? "Recuperar Senha" : "Recuperando..."}
          </Button>
        </Form>
        <hr css={styles.divisor} />
        <div css={styles.footer.root}>
          <Link css={styles.footer.sso} to="/login">
            Voltar
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};
