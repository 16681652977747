import React from "react";

import { isEmptyNullOrUndefined } from "~/apps/shared/utils/is-empty-null-or-undefined";
import { toCurrency } from "~/apps/shared/utils/to-currency";

import { Switch } from "@toolkit/v2";

import { styles } from "./styles";

const getPriceExcessHelpText = (margin?: string | null) => {
  let result;

  const marginValueNumber = isEmptyNullOrUndefined(margin)
    ? null
    : parseFloat(margin!);

  if (isEmptyNullOrUndefined(marginValueNumber)) {
    result = "Sem limite relativo ao menor preço.";
  } else if (marginValueNumber === 0) {
    result =
      "Preço máximo permitido do voo será igual ao da oferta de menor preço.";
  } else {
    result = `Preço máximo permitido do voo será de ${toCurrency(
      1000 * (1 + parseFloat((marginValueNumber! / 100).toFixed(2))),
    )} para um menor preço de ${toCurrency(1000)}.`;
  }

  return result;
};

export const FlightPriceExcessSwitch: React.FC<{
  name: string;
  onPriceExcessToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPriceExcessChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  priceExcess?: string | null;
  priceExcessActive: boolean;
}> = ({
  name,
  onPriceExcessToggle,
  onPriceExcessChange,
  priceExcess,
  priceExcessActive,
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Política de menor preço</span>
      <label css={styles.container.root}>
        <Switch
          active={priceExcessActive}
          name={`${name}Active`}
          onChange={onPriceExcessToggle}
          variant="pink"
        />
        <span css={styles.container.text}>Ativar política de menor preço</span>
      </label>
      <p css={styles.info}>
        Margem relativa ao menor preço encontrado na busca:{" "}
        <input
          css={styles.input}
          disabled={!priceExcessActive}
          id="FlightPriceExcess"
          name={name}
          onChange={onPriceExcessChange}
          pattern="[0-9]{10}"
          type="text"
          value={priceExcess || ""}
        />{" "}
        %
        <br />
        {getPriceExcessHelpText(priceExcess)}
      </p>
    </div>
  );
};
