import React, { useRef, useState } from "react";

import { Button } from "@toolkit/v2";

import { Icon } from "../icon/icon";
import { Menu } from "../menu/menu";
import { MenuItem } from "../menu/menu-item/menu-item";
import { styles } from "./styles";

type Props = {
  actions: {
    action: () => void;
    name: string;
  }[];
} & React.ComponentProps<typeof Button>;

export const SelectButton: React.FC<Props> = ({
  actions,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event?: React.MouseEvent<Document, MouseEvent>) => {
    if (!anchorRef.current) {
      return;
    }

    if (event && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div ref={anchorRef}>
        <Button
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          css={styles.button}
          onClick={() => {
            handleOpen();
          }}
          {...props}
        >
          {children}
          <Icon size={20} use="simple-arrow-white" />
        </Button>
      </div>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        css={styles.item}
        getContentAnchorEl={null}
        onClose={() => {
          handleClose();
        }}
        open={open}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.name}
            onClick={() => {
              action.action();

              setOpen(false);
            }}
          >
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
