import React, { useMemo } from "react";

import { GetUserCompanyEventDto } from "sm-types/sm-company-events";

import {
  BookerParticipantType,
  ParticipationStatus
} from "~/models/event.model";

import { EventOverviewAbout } from "./about";
import { EventOverviewBooking } from "./booking";
import { EventOverviewParticipants } from "./participants";
import { styles } from "./styles";
import { EventOverviewUpdates } from "./updates";

type Props = {
  event: GetUserCompanyEventDto;
};

export const EventOverview: React.VFC<Props> = ({ event }) => {
  const shouldRenderBooking = useMemo(() => {
    const { viewer_info } = event;

    if (viewer_info.booker_type === BookerParticipantType.CANNOT_BOOK) {
      return false;
    }

    if (viewer_info.participation_status !== ParticipationStatus.ACCEPTED) {
      return false;
    }

    if (viewer_info.is_organizer) {
      return true;
    }

    return true;
  }, [event]);

  return (
    <div css={styles.root}>
      <EventOverviewAbout event={event} />
      {shouldRenderBooking ? <EventOverviewBooking event={event} /> : null}
      <EventOverviewParticipants
        event={event}
        shouldRenderBooking={shouldRenderBooking}
      />
      <EventOverviewUpdates event={event} />
    </div>
  );
};
