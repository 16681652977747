import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  content: {
    root: css({
      gap: "24px",
      padding: "24px",
    }),
    terms: {
      heading: css({
        color: "black",
        fontSize: "1.3rem",
        fontWeight: "bold",
        margin: "1rem 0",
      }),
      list: css({
        listStyle: "inside",
        marginLeft: "1rem",
        "& > li": {
          marginBottom: "0.5rem",
        },
      }),
      root: css({
        color: theme.colors.gray[700],
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }),
      subheading: css({
        color: "black",
        fontSize: "1.2rem",
        fontWeight: "bold",
        margin: "1rem 0",
        textTransform: "uppercase",
      }),
    },
    top: {
      message: css({
        color: theme.colors.gray[300],
        lineHeight: "1.25",
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
    },
  },
  footer: {
    button: css({
      justifyContent: "center",
      width: "100%",
    }),
    form: {
      checkbox: {
        label: css({
          color: theme.colors.gray[700],
          cursor: "pointer",
          fontSize: "0.875rem",
          fontWeight: 600,
          marginLeft: "8px",
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          width: "fit-content",
          "& > :nth-child(2)": {
            cursor: "pointer",
          },
        }),
      },
      root: css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
      }),
    },
    root: css({
      backgroundColor: theme.colors.white,
      bottom: 0,
      padding: "24px",
      position: "sticky",
      width: "100%",
    }),
  },
  header: {
    root: css({
      padding: "24px",
    }),
  },
  root: css({
    ".MuiDialog-paper": {
      overflowY: "auto",
      padding: "0",
    },
  }),
};
