import React, { useCallback } from "react";
import { SingleDatePicker as ReactDatesSingleDatePicker } from "react-dates";

import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { Icon } from "../../icon/icon";
import { styles } from "./styles";

const defaultNavNext = <Icon size={16} use="chevron-right" />;

const defaultNavPrev = <Icon size={16} use="chevron-left" />;

const renderPortalSinglePickerHeader = ({
  date,
  placeholder,
  onFocusChange,
}: Pick<
  React.ComponentPropsWithoutRef<typeof SingleDatePicker>,
  "date" | "placeholder" | "onFocusChange"
>) =>
  // eslint-disable-next-line react/display-name
  () => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : placeholder;

    const setFocused = () =>
      onFocusChange({
        focused: true,
      });

    return (
      <div css={styles.vertical.header.root}>
        <Icon size={16} use="calendar" />
        <div css={styles.vertical.header.date.root} onClick={setFocused}>
          <span css={styles.vertical.header.date.text}>{formattedDate}</span>
        </div>
      </div>
    );
  };

type Props = React.ComponentPropsWithoutRef<typeof ReactDatesSingleDatePicker>;

export const SingleDatePicker: React.FC<Props> = ({ ...props }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 992;

  const getProps = useCallback(
    ({
      ...props
    }: Props): React.ComponentPropsWithoutRef<
      typeof ReactDatesSingleDatePicker
    > => {
      if (isMobile) {
        return {
          appendToBody: false,
          calendarInfoPosition: "top",
          hideKeyboardShortcutsPanel: true,
          navNext: defaultNavNext,
          navPrev: defaultNavPrev,
          orientation: "vertical",
          readOnly: true,
          renderCalendarInfo: renderPortalSinglePickerHeader({
            date: props.date,
            placeholder: props.placeholder,
            onFocusChange: props.onFocusChange,
          }),
          withFullScreenPortal: true,
          ...props,
        };
      }

      return {
        hideKeyboardShortcutsPanel: true,
        navNext: defaultNavNext,
        navPrev: defaultNavPrev,
        noBorder: true,
        ...props,
      };
    },
    [isMobile],
  );

  return (
    <div css={styles.root}>
      <ReactDatesSingleDatePicker {...getProps(props)} />
    </div>
  );
};
