import React from "react";
import StarRatingComponent from "react-star-rating-component";

import { Icon } from "~/apps/shared/components/icon/icon";

import { theme } from "@skin/v2";

import { styles } from "./styles";

export const AllowedCategoryStarRating: React.FC<
  React.ComponentPropsWithoutRef<typeof StarRatingComponent> & {
    onClear: () => void;
  }
> = ({ onClear, ...props }) => {
  return (
    <div css={styles.root}>
      <span css={styles.label}>Categoria permitida</span>
      <div css={styles.container}>
        <StarRatingComponent
          emptyStarColor={theme.colors.gray[100]}
          renderStarIcon={() => <Icon use="star" />}
          starColor={theme.colors.pink[500]}
          starCount={5}
          {...props}
        />
        <button css={styles.clear} onClick={onClear}>
          Limpar categoria
        </button>
      </div>
    </div>
  );
};
