import { css } from "@styled-system/css";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    margin: "24px 0",
    padding: "0 24px",
    width: "100%",
  }),
  section: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    }),
    title: css({
      fontWeight: 600,
      lineHeight: "28px",
    }),
  },
};
