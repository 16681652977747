import React, { useEffect, useState } from "react";

import { CheckboxGroup } from "~/apps/shared/components/checkbox-group/checkbox-group";
import { Collapse } from "~/apps/shared/components/collapse/collapse";
import { Icon } from "~/apps/shared/components/icon/icon";
import { RadioGroup } from "~/apps/shared/components/radio-group/radio-group";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { USER_ROLES } from "~/apps/shared/constants";
import { Capabilities } from "~/apps/shared/constants/enums";
import { Badge } from "smartrips-toolkit/lib/v2";

import { UserFormCapabilitiesSelect } from "../user-form-capabilities-select/user-form-capabilities-select";
import { styles } from "./styles";

type Props = {
  capabilities: Capabilities;
  onCapabilitiesChange: (capabilities: Capabilities) => void;
  onRoleChange: (role: string) => void;
  role: string;
};

export const UserFormPermissionsSelect: React.FC<Props> = ({
  capabilities,
  onCapabilitiesChange,
  onRoleChange,
  role,
}) => {
  const [isCapabilitiesExpanded, setIsCapabilitiesExpanded] = useState(false);

  const handleCapabilityChange = (event: React.ChangeEvent<{}>) => {
    const { checked, name } = event.target as any;

    onCapabilitiesChange({
      ...capabilities,
      [name]: checked,
    });
  };

  const handleRoleChange = (event: React.ChangeEvent<{}>) => {
    const { value } = event.target as any;

    onRoleChange(value);
  };

  useEffect(() => {
    if (role !== USER_ROLES.customized) {
      return;
    }

    setIsCapabilitiesExpanded(true);
  }, [role]);

  return (
    <div css={styles.root}>
      <RadioGroup css={styles.role.radio.group}>
        <RadioGroup.Item
          checked={role === USER_ROLES.admin}
          onChange={handleRoleChange}
          value={USER_ROLES.admin}
        >
          <div css={styles.role.radio.item}>Administrador</div>
        </RadioGroup.Item>
        <RadioGroup.Item
          checked={role === USER_ROLES.manager}
          onChange={handleRoleChange}
          value={USER_ROLES.manager}
        >
          <div css={styles.role.radio.item}>Gestor</div>
        </RadioGroup.Item>
        <RadioGroup.Item
          checked={role === USER_ROLES.user}
          onChange={handleRoleChange}
          value={USER_ROLES.user}
        >
          <div css={styles.role.radio.item}>
            Viajante e solicitante
            <Badge css={styles.role.radio.badge}>Padrão</Badge>
          </div>
        </RadioGroup.Item>
        <RadioGroup.Item
          checked={role === USER_ROLES.self_traveler}
          onChange={handleRoleChange}
          value={USER_ROLES.self_traveler}
        >
          <div css={styles.role.radio.item}>
            Viajante com permissao para solicitar para si mesmo
          </div>
        </RadioGroup.Item>
        <RadioGroup.Item
          checked={role === USER_ROLES.traveler}
          onChange={handleRoleChange}
          value={USER_ROLES.traveler}
        >
          <div css={styles.role.radio.item}>Viajante que somente visualiza</div>
        </RadioGroup.Item>{" "}
        <RadioGroup.Item
          checked={role === USER_ROLES.customized}
          onChange={handleRoleChange}
          value={USER_ROLES.customized}
        >
          <div css={styles.role.radio.item}>
            <span>Viajante customizável</span>
            <Badge css={styles.role.radio.badge} variant="pink">
              Novo
            </Badge>
          </div>
        </RadioGroup.Item>
      </RadioGroup>
      {role ? (
        <button
          css={styles.capabilities.toggle}
          onClick={() => {
            setIsCapabilitiesExpanded((prev) => !prev);
          }}
          type="button"
        >
          {!isCapabilitiesExpanded ? (
            <>
              <Icon size={16} use="chevron-down" />
              Exibir configurações avançadas de permissão
            </>
          ) : (
            <>
              <Icon size={16} use="chevron-up" />
              Esconder configurações avançadas de permissão
            </>
          )}
        </button>
      ) : null}
      <Collapse expanded={isCapabilitiesExpanded}>
        <UserFormCapabilitiesSelect
          allowCustomization={role === USER_ROLES.customized}
          capabilities={capabilities}
          onCapabilitiesChange={handleCapabilityChange}
        />
      </Collapse>
      <hr css={styles.divisor} />
      <CheckboxGroup css={styles.capabilities.master}>
        <CheckboxGroup.Item
          checked={capabilities.approvalAnyTimeForOthers}
          onChange={handleCapabilityChange}
          name="approvalAnyTimeForOthers"
        >
          Elegível como aprovador Master
          <Tooltip
            arrow
            content="O usuário pode acessar qualquer viagem pendente de aprovação e substituir qualquer aprovador, mesmo não sendo o aprovador original da viagem em questão."
          />
        </CheckboxGroup.Item>
      </CheckboxGroup>
    </div>
  );
};
