import * as itineraryHelper from "~/apps/corporate/helpers/itinerary.helper";
import { ItineraryServicePresenter } from "~/apps/corporate/models/itinerary/itinerary-service.presenter";
import { ItineraryServicesPresenter } from "~/apps/corporate/models/itinerary/itinerary-services.presenter";

class ApprovalRequestServicesCardPresenter {
  constructor(private readonly servicesPresenter: ItineraryServicesPresenter) {}

  public getPresentationalServices() {
    const services = this.servicesPresenter
      .getAllServices()
      .filter((servicePresenter) => servicePresenter.isProcessable());

    const travelPlanServices = itineraryHelper.getPresentationServicesGroupedByOfferStatus(
      this.servicesPresenter.splitServicesByDateAndSetSuggestions(
        this.getTravelPlanTabServices(services),
      ),
    );

    const travelPlanDraftServices = travelPlanServices["DRAFT"];

    return travelPlanDraftServices;
  }

  private getTravelPlanTabServices(services: ItineraryServicePresenter[]) {
    return services.filter((s) => s.belongsToTravelPlanTab());
  }

  public hasPresentationalServices() {
    return this.getPresentationalServices().length > 0;
  }
}

export class ApprovalRequestServicesCardPresenterFactory {
  public static create(servicesPresenter: ItineraryServicesPresenter) {
    return new ApprovalRequestServicesCardPresenter(servicesPresenter);
  }
}
