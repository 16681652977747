import { PendingExpenseAdvanceItem } from "~/dtos/expense-advance-approval.dto";
import moment from "moment";

export function parsePendingExpenseAdvanceItem(
  data: PendingExpenseAdvanceItem
) {
  return {
    requesterFirstName: data.requester_first_name,
    requesterLastName: data.requester_last_name,
    requesterFullName: `${data.requester_first_name} ${
      data.requester_last_name
    }`,
    expenseAdvanceToken: data.expense_advance_token,
    travelerToken: data.traveler_token,
    requesterToken: data.requester_token,
    name: data.name,
    justification: data.justification,
    requestedValue: data.requested_value,
    approvedValue: data.approved_value,
    travelStart: moment(data.travel_start),
    travelEnd: moment(data.travel_end),
    currency: data.currency,
    status: data.status,
    createdAt: moment(data.created_at),
    updatedAt: moment(data.updated_at)
  };
}
