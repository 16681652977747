import React from "react";

import { navigate } from "@reach/router";
import { useItinerary } from "~/apps/corporate/pages/travels/itinerary/itinerary.context";
import { Avatar } from "~/apps/shared/components/avatar-group/avatar/avatar";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";

import { Button } from "@toolkit/v2";

import { useItineraryApproval } from "../../../itinerary-approval.context";
import { useItineraryContainer } from "../../../itinerary.container";
import {
  approvalRequestSchema,
  ApprovalRequestSchema,
} from "./approval-request-justification.schema";
import { styles } from "./styles";

export const ApprovalRequestJustification: React.FC = () => {
  const {
    // servicesModel,
    travelApprovalStatusModel,
  } = useItineraryContainer();

  const { travelToken } = useItinerary();
  const {
    createApprovalRequest,
    isLoadingCreateApprovalRequest,
  } = useItineraryApproval();
  // const { doesTravelHaveRepeatedOffers } = useItineraryScreen();

  const form = useForm<ApprovalRequestSchema>({
    defaultValues: {
      justification: "",
    },
    onSubmit: async () => {
      // if (!servicesModel) {
      //   return;
      // }

      // const travelHasRepeatedOffers = await doesTravelHaveRepeatedOffers(
      //   servicesModel.getAllServiceOfferTokens(),
      // );

      // if (travelHasRepeatedOffers) {
      //   return;
      // }

      const values = form.getValues();

      const success = await createApprovalRequest(values.justification);

      if (!success) {
        return;
      }

      navigate(`/travels/${travelToken}/itinerary/travel-plan`);
    },
    schema: approvalRequestSchema,
  });

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <span css={styles.header.title}>Essa viagem precisa ser aprovada.</span>
        <span css={styles.header.description}>
          Envie a sua solicitação para os aprovadores responsáveis.
        </span>
      </div>
      {travelApprovalStatusModel &&
      travelApprovalStatusModel.getAllNextApprovers().length > 0 ? (
        <>
          <hr css={styles.divisor} />
          <div css={styles.approvers.root}>
            {travelApprovalStatusModel.getStageOneApprovers().length > 0 ? (
              <ul css={styles.approvers.stage.root}>
                <span css={styles.approvers.stage.title}>
                  Sua solicitação será enviada para os aprovadores:
                </span>
                {travelApprovalStatusModel
                  .getStageOneApprovers()
                  .map((firstStageApprover) => (
                    <ApprovalRequestJustificationApprover
                      email={firstStageApprover.email}
                      firstName={firstStageApprover.first_name}
                      key={firstStageApprover.email}
                      lastName={firstStageApprover.last_name}
                    />
                  ))}
              </ul>
            ) : null}
            {travelApprovalStatusModel.getStageTwoApprovers().length > 0 ? (
              <>
                <hr css={styles.divisor} />
                <ul css={styles.approvers.stage.root}>
                  <span css={styles.approvers.stage.title}>
                    Aprovadores finais:
                  </span>
                  {travelApprovalStatusModel
                    .getStageTwoApprovers()
                    .map((lastStageApprover) => (
                      <ApprovalRequestJustificationApprover
                        email={lastStageApprover.email}
                        firstName={lastStageApprover.first_name}
                        key={lastStageApprover.email}
                        lastName={lastStageApprover.last_name}
                      />
                    ))}
                </ul>
              </>
            ) : null}
          </div>
        </>
      ) : null}
      <hr css={styles.divisor} />
      <Form css={styles.form.root} context={form}>
        <div css={styles.form.justification.root}>
          <span css={styles.form.justification.label}>Justificativa</span>
          <Form.Field
            name="justification"
            render={({ onChange, value }) => (
              <>
                <textarea
                  css={styles.form.justification.textarea}
                  maxLength={250}
                  onChange={onChange}
                  placeholder="Digite a justificativa para a compra das ofertas..."
                  value={value}
                />
                {form.errors["justification"]?.message ? (
                  <InputErrorMessage>
                    {form.errors["justification"].message}
                  </InputErrorMessage>
                ) : (
                  <span css={styles.form.justification.info}>
                    Máximo de 250 caracteres ({value.length}/250)
                  </span>
                )}
              </>
            )}
          />
        </div>
        <Button
          css={styles.button}
          disabled={
            form.formState.isSubmitting || isLoadingCreateApprovalRequest
          }
          variant="pink"
        >
          Enviar pedido de aprovação
        </Button>
      </Form>
    </div>
  );
};

type ApprovalRequestJustificationApproverProps = {
  email: string;
  firstName: string;
  lastName: string;
};

const ApprovalRequestJustificationApprover: React.FC<ApprovalRequestJustificationApproverProps> = ({
  email,
  firstName,
  lastName,
}) => {
  return (
    <li css={styles.approvers.stage.approver.root}>
      <div css={styles.approvers.stage.approver.avatar}>
        <Avatar name={firstName} />
      </div>
      <span css={styles.approvers.stage.approver.text}>
        <strong css={styles.approvers.stage.approver.name}>
          {firstName} {lastName}
        </strong>{" "}
        &lt;
        {email}&gt;
      </span>
    </li>
  );
};
