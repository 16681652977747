import React, { useCallback, useMemo } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { HospitalityHotelDetails } from "~/apps/corporate/dtos/hotel.dto";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";
import { Icon } from "~/apps/shared/components/icon/icon";
import { FAVORITE_TYPES } from "~/apps/shared/constants";

import { useHotelResult } from "../hotel-result.context";
import { styles } from "./styles";

type Props = {
  hotel: HospitalityHotelDetails;
};

export const HotelResultFavoriteDialog: React.FC<Props> = ({ hotel }) => {
  const { user } = useUser();

  const {
    closeFavoriteDialog,
    isFavoriteDialogOpen,
    isLoadingFavorite,
    removeHotelFavorite,
    setHotelFavorite,
  } = useHotelResult();

  const canUserToggleCompanyFavorite = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.hasConfigurationsCapability() && user.hasSearchCapability();
  }, [user]);

  const companyFavorite = useMemo(
    () =>
      hotel.favorites.find(
        (favorite) => favorite.type === FAVORITE_TYPES.COMPANY,
      ),
    [hotel],
  );

  const userFavorite = useMemo(
    () =>
      hotel.favorites.find((favorite) => favorite.type === FAVORITE_TYPES.USER),
    [hotel],
  );

  const handleToggleCompanyFavorite = useCallback(async () => {
    if (!canUserToggleCompanyFavorite) {
      return;
    }

    if (!companyFavorite) {
      await setHotelFavorite(hotel, "c");

      return;
    }

    await removeHotelFavorite(companyFavorite.token);
  }, [
    canUserToggleCompanyFavorite,
    companyFavorite,
    hotel,
    removeHotelFavorite,
    setHotelFavorite,
  ]);

  const handleToggleUserFavorite = useCallback(async () => {
    if (!userFavorite) {
      await setHotelFavorite(hotel, "u");

      return;
    }

    await removeHotelFavorite(userFavorite.token);
  }, [hotel, removeHotelFavorite, setHotelFavorite, userFavorite]);

  return (
    <Dialog
      keepMounted
      onClose={closeFavoriteDialog}
      open={isFavoriteDialogOpen}
    >
      <DialogHeader
        icon="heart-outline"
        onClose={closeFavoriteDialog}
        variant="pink"
      />
      <DialogContent css={styles.content.root}>
        <DialogTitle variant="pink">Adicionar aos favoritos.</DialogTitle>
        <div css={styles.content.buttons.root}>
          <button
            css={styles.content.buttons.button.root}
            disabled={isLoadingFavorite}
            onClick={async () => {
              await handleToggleUserFavorite();
            }}
          >
            <Icon
              css={styles.content.buttons.button.icon({
                isChecked: !!userFavorite,
              })}
              use={userFavorite ? "heart" : "heart-outline"}
            />
            <div css={styles.content.buttons.button.text.root}>
              <span css={styles.content.buttons.button.text.top}>
                {userFavorite ? "Adicionado" : "Adicionar"} aos favoritos
                pessoais.
              </span>
              <span css={styles.content.buttons.button.text.bottom}>
                Favoritos pessoais serão visiveis somente por você em suas
                pesquisas.
              </span>
            </div>
          </button>
          <button
            css={styles.content.buttons.button.root}
            disabled={!canUserToggleCompanyFavorite || isLoadingFavorite}
            onClick={async () => {
              await handleToggleCompanyFavorite();
            }}
          >
            <Icon
              css={styles.content.buttons.button.icon({
                isChecked: !!companyFavorite,
              })}
              use={companyFavorite ? "heart" : "heart-outline"}
            />
            <div css={styles.content.buttons.button.text.root}>
              <span css={styles.content.buttons.button.text.top}>
                {companyFavorite ? "Adicionado" : "Adicionar"} aos favoritos da
                empresa.
              </span>
              <span css={styles.content.buttons.button.text.bottom}>
                Favoritos da empresa serão visíveis a todos os usuários.
              </span>
            </div>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
