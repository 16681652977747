import {
  GetApproverPendingApprovalsResponse,
  GetTravelApprovalHistoryResponse,
} from "~/apps/corporate/dtos/approvals.dto";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";

import { api } from "../../shared/services/api";
import * as approvalsParser from "../parsers/approvals.parser";

export async function getApproverPendingApprovals() {
  return await api
    .request<GetApproverPendingApprovalsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: "/booking/approval-requests/pending",
    })
    .then(({ data }) => data);
}

export async function getTravelApprovalHistory(travelToken: string) {
  return await api
    .request<GetTravelApprovalHistoryResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/approval-history`,
    })
    .then(({ data }) => approvalsParser.parseTravelApprovalHistory(data));
}
