import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  center: {
    control: {
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        "@media screen and (max-width: 425px)": {
          flex: "unset",
          minWidth: "unset",
          width: "100%",
        },
      }),
    },
    left: {
      location: {
        container: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "calc(57px / 2)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }),
        input: {
          input: css({
            width: "100%",
            ".Mui": {
              "&FormLabel-root": {
                color: theme.colors.gray[100],
                fontFamily: theme.fonts.inter,
                "&.Mui-focused": {},
              },
              "&InputBase-input": {
                fontFamily: theme.fonts.inter,
                padding: "26px 16px 12px 12px",
                textOverflow: "ellipsis",
              },
              "&InputLabel-outlined": {
                "&.MuiInputLabel-shrink": {
                  color: theme.colors.gray[700],
                  fontWeight: 700,
                  transform: "translate(12px, 12px) scale(0.75)",
                },
              },
              "&OutlinedInput-notchedOutline": {
                border: "none !important",
              },
            },
          }),
          loading: css({
            flexShrink: 0,
          }),
          root: css({
            alignItems: "center",
            borderRadius: "8px",
            color: theme.colors.gray[700],
            display: "flex",
            paddingLeft: "16px",
            width: "100%",
          }),
        },
        location: css({
          padding: "8px 16px 12px 12px",
        }),
        root: css({
          position: "relative",
          width: "100%",
          "& > :first-of-type": {
            gap: "0",
          },
        }),
      },
      root: css({
        display: "flex",
        width: "100%",
      }),
    },
    right: {
      dates: {
        arrow: {
          divisor: css({
            color: theme.colors.gray[100],
          }),
          root: css({
            alignItems: "center",
            display: "flex",
            gap: "12px",
          }),
        },
        container: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "calc(57px / 2)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }),
        root: css({
          ".Date": {
            "&Input": {
              "&_input": {
                padding: "18.5px 0 18.5px 0 !important",
              },
            },
            "&RangePicker": {
              "&Input": {
                border: "none !important",
                borderRadius: "0 !important",
                padding: "0 16px !important",
              },
            },
          },
          ".SingleDatePicker": {
            "&Input": {
              border: "none !important",
              borderRadius: "0 !important",
              padding: "0 16px !important",
            },
          },
        }),
      },
      root: css({
        alignItems: "flex-start",
        display: "flex",
        gap: "8px",
        "@media screen and (max-width: 768px)": {
          width: "100%",
        },
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      gap: "8px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        flexDirection: "column",
      },
    }),
  },
  button: css({
    alignItems: "center",
    aspectRatio: 1,
    borderRadius: "50%",
    color: theme.colors.white,
    display: "flex",
    flexShrink: 0,
    height: "57px",
    justifyContent: "center",
    width: "57px",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  top: {
    control: {
      error: css({
        padding: "0 16px 12px 16px",
      }),
      guests: {
        button: css({
          alignItems: "center",
          aspectRatio: 1,
          borderRadius: "50%",
          display: "flex",
          height: "24px",
          justifyContent: "center",
          padding: "0",
          transition: "all 0.15s ease-in-out",
          width: "24px",
          "& path": {
            strokeWidth: "2.5px",
          },
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          gap: "8px",
        }),
        value: css({
          fontWeight: 600,
          minWidth: "12px",
          textAlign: "center",
        }),
      },
      label: css({
        color: theme.colors.gray[700],
        flexShrink: 0,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "8px",
        display: "flex",
        gap: "4px",
        padding: "10px 16px 10px 16px",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          borderColor: theme.colors.gray[100],
        },
        "@media screen and (max-width: 768px)": {
          minWidth: "unset",
          width: "100%",
        },
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    }),
  },
};
