import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  bottom: {
    button: css({
      justifyContent: "center",
    }),
    change: {
      root: css({
        alignItems: "center",
        color: theme.colors.gray[300],
        display: "flex",
        fontSize: "0.875rem",
        gap: "8px",
      }),
    },
    policy: {
      badge: {
        icon: css({
          "& > svg": {
            strokeWidth: 2,
          },
        }),
        root: css({
          backgroundColor: "#ffe8ab",
          borderColor: "#ffe8ab",
          color: "#806300",
          cursor: "pointer",
          fontWeight: 500,
          gap: "8px",
          padding: "4px 16px",
          ":hover": {
            backgroundColor: "#e5d099",
            borderColor: "#e5d099",
            color: "#735900",
          },
        }),
      },
    },
    pricing: {
      price: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        justifyContent: "space-between",
      }),
      text: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
    },
    reserve: {
      label: css({
        color: theme.colors.gray[700],
        fontSize: "0.875rem",
      }),
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
        width: "fit-content",
        "& > :nth-of-type(1)": {
          cursor: "pointer",
        },
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      paddingTop: "24px",
    }),
  },
  center: {
    root: css({
      borderBottom: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "24px 0",
    }),
    section: {
      content: {
        fee: css({
          color: theme.colors.pink[500],
          fontSize: "0.75rem",
          fontWeight: 600,
        }),
        free: css({
          color: theme.colors.green[500],
          fontWeight: 600,
        }),
        root: css({
          alignItems: "center",
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
          padding: "4px 0",
        }),
        price: css({
          color: theme.colors.gray[700],
          fontWeight: 600,
          whiteSpace: "nowrap",
        }),
        text: css({
          color: theme.colors.gray[300],
          "@media (max-width: 640px)": {
            fontSize: "0.875rem",
          },
        }),
      },
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }),
      title: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
    },
  },
  root: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "24px",
  }),
  top: {
    date: css({
      color: theme.colors.gray[300],
      fontSize: "0.875rem",
      textTransform: "capitalize",
    }),
    root: css({
      borderBottom: `1px solid ${theme.colors.gray[100]}`,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      paddingBottom: "24px",
    }),
  },
};
