import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  editor: css({
    minHeight: "200px",
    padding: "16px",
  }),
  root: css({
    border: `1px solid ${theme.colors.gray[100]}`,
    borderRadius: "8px",
  }),
};
