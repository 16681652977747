import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";

import { styles } from "./styles";

type Props = {
  width: string;
  zAxis: number;
};

export const BusContainer: React.FC<Props> = ({ children, width, zAxis }) => {
  return (
    <div css={styles.root({ width })}>
      <div css={styles.front.root}>
        <div css={styles.front.bumpers["1"]} />
        <div css={styles.front.bumpers["2"]} />
        <div css={styles.front.bumpers["3"]} />
        <div css={styles.front.lights.left} />
        <div css={styles.front.lights.right} />
        {zAxis === 0 ? (
          <Icon css={styles.wheel} size={32} use="steering-wheel" />
        ) : null}
      </div>
      {children}
    </div>
  );
};
