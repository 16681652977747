import React from "react";

import { OutlinedInputProps } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ExpandMore from "@material-ui/icons/ExpandMore";
import cx from "classnames";
import { css } from "emotion";

import commonStyles from "./common-styles";
import InputError from "./InputError";

const iconStyle = css({
  top: "calc(50% - 12px)",
  right: 5,
  color: "rgba(0, 0, 0, 0.54)",
  position: "absolute",
  pointerEvents: "none"
});

export type InputProps = TextFieldProps & {
  underlineHighlight?: boolean;
  containerClasses?: string;
  error?: boolean;
  icon?: React.ReactNode;
  errorMessage?: string;
  notchedOutlineStyle?: string | Record<string, unknown>;
  errorStyle?: string | Record<string, unknown>;
  rootStyle?: string | Record<string, unknown>;
  errorClasses?: string;
  inputClassName?: string;
};

export const Input = React.forwardRef(function Input(props: InputProps, ref) {
  const {
    icon,
    error = false,
    errorMessage,
    containerClasses = "",
    underlineHighlight = false,
    children,
    notchedOutlineStyle,
    InputProps,
    inputProps,
    InputLabelProps,
    SelectProps,
    errorStyle,
    errorClasses,
    inputClassName,
    rootStyle,
    ...rest
  } = props;

  return (
    <div className={cx(commonStyles.containerStyle, containerClasses)}>
      <TextField
        {...rest}
        inputRef={ref}
        variant="outlined"
        error={error}
        InputProps={{
          ...InputProps,
          classes: {
            ...commonStyles.inputBaseStyles,
            root: cx(rootStyle, commonStyles.inputBaseStyles.root),
            notchedOutline: cx(
              commonStyles.notchedOutlineStyle,
              notchedOutlineStyle
            ),
            focused: underlineHighlight
              ? error
                ? commonStyles.notchedOutlineFocused
                : commonStyles.underlineFocused
              : commonStyles.notchedOutlineFocused,
            error: underlineHighlight
              ? commonStyles.underlineErrored
              : commonStyles.error
          },
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : null
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: commonStyles.inputLabelStyles
        }}
        inputProps={{
          ...inputProps,
          className: cx(commonStyles.inputStyles, inputClassName)
        }}
        SelectProps={{
          ...SelectProps,
          IconComponent: function Icon() {
            return <ExpandMore className={iconStyle} />;
          }
        }}
      >
        {children}
      </TextField>
      {errorMessage ? (
        <InputError style={errorStyle} className={errorClasses}>
          {errorMessage}
        </InputError>
      ) : null}
    </div>
  );
});
