import * as yup from "yup";

import { DOCUMENT_TYPES } from "~/constants";
import { CARD_ISSUERS } from "~/constants/credit-card.constant";
import { GenderType } from "~/constants/enums";

import { formatPhone, removeBlankSpaces } from "~/helpers";
import { validateCpf, validateRg } from "~/helpers/document.helper";

export function Email() {
  return yup.string().email("Digite um email válido");
}

export function Phone() {
  return yup
    .string()
    .test("validatePhone", "Digite um telefone válido", value => {
      if (!value) {
        return true;
      }

      const formattedPhone = formatPhone(value);

      return formattedPhone && !(formattedPhone.length < 11);
    });
}

export function Capabilities() {
  return yup.object().shape({
    basic: yup.bool(),
    search: yup.bool(),
    selfSearch: yup.bool(),
    reviews: yup.bool(),
    approvals: yup.bool(),
    expenses: yup.bool(),
    configurations: yup.bool(),
    eventOrganizer: yup.bool(),
    financial: yup.bool(),
    analytics: yup.bool(),
    batchActions: yup.bool(),
    manualManagement: yup.bool(),
    expenseForOthers: yup.bool(),
    approvalAnyTimeForOthers: yup.bool(),
    externalCredentials: yup.bool()
  });
}

export function Document() {
  return yup.object().shape({
    birthDate: yup.string().nullable(), //"1994-11-05",
    createdAt: yup.string().nullable(), //"2021-01-22T16:47:45.000Z",
    documentToken: yup.string().nullable(), //"b2b8f2e0-5cea-11eb-b5b9-3d7a51c8aca9",
    issueDate: yup.date().nullable(),
    issuingCountry: yup.string().nullable(), //"BR",
    nationality: yup.string().nullable(), //"BR",
    residenceCountry: yup.string().nullable(),
    sex: yup.string().oneOf(Object.values(GenderType)), //"M",
    updatedAt: yup.date().nullable(), //"2021-01-22T16:47:45.000Z",
    userToken: yup.string().required(), //"047e4d60-5128-11eb-80d2-bf28910f299a",
    type: yup.string().oneOf(Object.values(DOCUMENT_TYPES)).required(),
    token: yup.string().nullable(),
    firstName: yup.string().required("Digite o nome presente no documento"),
    lastName: yup.string().required("Digite o sobrenome presente no documento"),
    documentNumber: yup.string().required("Digite o número do document"),
    expirationDate: yup.string().nullable()
  });
}

export function RG() {
  return yup.string().test("validateRg", "Digite um RG válido", value => {
    if (!value) {
      return false;
    }

    return validateRg(value);
  });
}

export function CPF() {
  return yup.string().test("validateCpf", "Digite um CPF válido", value => {
    if (!value) {
      return false;
    }

    return validateCpf(value);
  });
}

export function CardNumber() {
  return yup
    .string()
    .test("validateCardNumber", "Número do cartão inválido", value => {
      if (value === undefined) {
        return true;
      }

      const numberWithoutSpaces = removeBlankSpaces(value);

      if (!numberWithoutSpaces) {
        return false;
      }

      const issuer = CARD_ISSUERS.find(
        item => !!numberWithoutSpaces.match(item.startsWith)
      );

      return issuer && numberWithoutSpaces.match(issuer.validationPattern);
    });
}

export function RequiredSelectOption(requiredMessage: string) {
  return yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .test("validateOption", requiredMessage, value => {
      return !!value?.label && !!value?.value;
    });
}

export function SelectOption() {
  return yup.object().shape({
    label: yup.string(),
    value: yup.string()
  });
}
