import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    gap: "16px",
    marginBottom: "16px",
    maxWidth: "384px",
    width: "384px",
    "@media (max-width: 1440px)": {
      width: "fit-content",
    },
  }),
};
