import { css } from "@styled-system/css";

import { Text } from "@toolkit";

import { defaultTheme } from "~/assets/styles/theme";

import styled from "@shared/styled";

const PaperTitle = styled(Text)(
  css({
    fontSize: [1, 2],
    fontWeight: "bold",
    color: defaultTheme.subTextColor,
    textTransform: "uppercase",
    display: "block",
    mb: 24
  })
);

export { PaperTitle };
